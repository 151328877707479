import { CalendricalType, IRecurringDeliveriesSettings } from '~/client/graph'

import ProjectDateStore from '../stores/ui/ProjectDate.store'
import BaseModel from './BaseModel'

export const MAX_RECURRING_MONTHS = 2

export default class RecurringDeliveriesSettings
  extends BaseModel<IRecurringDeliveriesSettings>
  implements IRecurringDeliveriesSettings
{
  public static fromDto(
    dto: IRecurringDeliveriesSettings,
  ): RecurringDeliveriesSettings {
    return new RecurringDeliveriesSettings(
      dto.id,
      dto.projectId,
      dto.frequency,
      dto.frequencyType,
      dto.endDate,
      dto.selectedDays,
      dto.createdAt,
      dto.updatedAt,
    )
  }

  public static createNew(
    projectId: string,
    projectDateStore: ProjectDateStore,
    endDate?: Date | number,
  ): RecurringDeliveriesSettings {
    const { addMonths, endOfDay } = projectDateStore

    return new RecurringDeliveriesSettings(
      null,
      projectId,
      1,
      CalendricalType.Week,
      endOfDay(
        addMonths(endDate || Date.now(), MAX_RECURRING_MONTHS),
      ).getTime(),
    )
  }

  public constructor(
    public id: string,
    public projectId: string,
    public frequency: number,
    public frequencyType: CalendricalType,
    public endDate: number,
    public selectedDays?: number[],
    public createdAt: number = 0,
    public updatedAt: number = 0,
  ) {
    super(id)
    this.setCreatedAt(createdAt)
    this.setUpdatedAt(updatedAt)
  }
}
