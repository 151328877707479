import * as React from 'react'

import Konva from 'konva'
import { observer } from 'mobx-react'
import { Path } from 'react-konva'

import ThemeMode from '../../utils/ThemeModeManager'
import KonvaWorkflowBasePin from './KonvaWorkflowBasePin'

import Colors from '~/client/src/shared/theme.module.scss'

const ANNOUNCEMENT_ICON_PATH_DATA =
  'M5.285,3.43847312 L5.285,7.45097312 L3.7475,7.45097312 L4.4975,10.5759731 C4.5475,10.7884731 4.385,11.0009731 4.16,11.0009731 L4.16,11.0009731 L3.1975,11.0134731 C3.035,11.0134731 2.8975,10.9134731 2.86,10.7509731 L2.86,10.7509731 L2.06,7.43847312 L1.935,7.43847312 C1.31,7.43847312 0.81,6.95097312 0.785,6.33847312 L0.785,6.33847312 L0.4225,6.33847312 C0.2225,6.33847312 0.06,6.17597312 0.06,5.97597312 L0.06,5.97597312 L0.06,4.90097312 C0.06,4.70097312 0.2225,4.53847312 0.4225,4.53847312 L0.4225,4.53847312 L0.7975,4.53847312 C0.81,3.92597312 1.31,3.43847312 1.935,3.43847312 L1.935,3.43847312 L5.285,3.43847312 Z M9.0725,1.12597312 C9.51,0.788473124 10.135,1.10097312 10.135,1.65097312 L10.135,1.65097312 L10.135,9.22597312 C10.135,9.77597312 9.51,10.0884731 9.0725,9.75097312 L9.0725,9.75097312 L6.06,7.45097312 L6.06,3.43847312 Z M11.285,4.32597312 C11.6475,4.32597312 11.935,4.62597312 11.935,4.98847312 L11.935,4.98847312 L11.935,5.90097312 C11.935,6.26347312 11.6475,6.55097312 11.285,6.55097312 L11.285,6.55097312 L10.9225,6.55097312 L10.9225,4.32597312 Z'

const ICON_OFFSET = { x: 4, y: 4 }
const { getHEXColor } = ThemeMode
interface IProps {
  text: string
  isSelected: boolean
  isCanceled: boolean
  shouldRenderCircle?: boolean
  x: number
  y: number
  onClick: () => void
  onTouchEnd: () => void
}

@observer
export default class KonvaWorkflowAnnouncementPin extends React.Component<
  IProps & Konva.NodeConfig
> {
  public render() {
    const {
      shouldRenderCircle,
      text,
      isSelected,
      x,
      y,
      onClick,
      onTouchEnd,
      ...rest
    } = this.props

    return (
      <KonvaWorkflowBasePin
        {...rest}
        color={getHEXColor(Colors.error50)}
        text={text}
        textColor={getHEXColor(Colors.neutral100)}
        isSelected={isSelected}
        shouldRenderCircle={shouldRenderCircle}
        x={x}
        y={y}
        onClick={onClick}
        onTouchEnd={onTouchEnd}
      >
        <Path
          data={ANNOUNCEMENT_ICON_PATH_DATA}
          fill={getHEXColor(Colors.neutral100)}
          x={ICON_OFFSET.x}
          y={ICON_OFFSET.y}
        />
      </KonvaWorkflowBasePin>
    )
  }
}
