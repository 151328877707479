// US format
export class DateTimeDisplayFormat {
  public readonly separator: string = ' '
  public readonly partSeparator: string = ', '
  public readonly slashSeparator: string = '/'

  public readonly time: string = 'hh:mm a'
  public readonly shortTime: string = 'h:mm aaa'
  public readonly weekdayShort: string = 'eeeee'
  public readonly weekday: string = 'eee'
  public readonly month: string = 'MMM'
  public readonly monthAsNum: string = 'MM'
  public readonly year: string = 'yyyy'
  public readonly dayOfMonth: string = 'dd'

  public get slashedMonthAndDay(): string {
    return this.monthAsNum + this.slashSeparator + this.dayOfMonth
  }

  public get slashedMonthDayAndYear(): string {
    return this.slashedMonthAndDay + this.slashSeparator + this.year
  }

  public get monthAndDay(): string {
    return this.month + this.separator + this.dayOfMonth
  }

  public get monthDayAndYear(): string {
    return this.monthAndDay + this.partSeparator + this.year
  }

  public get monthAndYear(): string {
    return this.month + this.separator + this.year
  }

  public get weekdayMonthDayAndYear(): string {
    return this.weekday + this.partSeparator + this.monthDayAndYear
  }

  public get weekdayMonthAndDay(): string {
    return this.weekday + this.partSeparator + this.monthAndDay
  }

  public get monthDayAndTime(): string {
    return this.monthAndDay + this.partSeparator + this.time
  }

  public get monthDayYearAndTime(): string {
    return this.monthDayAndYear + this.separator + this.time
  }

  public get weekdayMonthDayAndTime(): string {
    return this.weekday + this.partSeparator + this.monthDayAndTime
  }

  public get weekdayMonthDayYearAndTime(): string {
    return this.weekday + this.partSeparator + this.monthDayYearAndTime
  }

  public get intervalSameMonthStartDate(): string {
    return this.monthAndDay
  }

  public get intervalSameMonthEndDate(): string {
    return this.dayOfMonth
  }
}

export class InternationalDateTimeDisplayFormat extends DateTimeDisplayFormat {
  public readonly partSeparator: string = ' '
  public readonly time: string = 'HH:mm'
  public readonly shortTime: string = 'H:mm'

  public get slashedMonthAndDay(): string {
    return this.dayOfMonth + this.slashSeparator + this.monthAsNum
  }

  public get monthAndDay(): string {
    return this.dayOfMonth + this.separator + this.month
  }

  public get intervalSameMonthStartDate(): string {
    return this.dayOfMonth
  }

  public get intervalSameMonthEndDate(): string {
    return this.monthAndDay
  }
}
