import { action, computed, observable } from 'mobx'

import { IWhiteListItem } from '~/client/graph'
import {
  DeleteWhiteListItemsDocument,
  GetWhiteListItemsByCompanyDocument,
  GetWhiteListItemsByProjectDocument,
  SaveWhiteListItemDocument,
} from '~/client/graph/operations/generated/WhiteList.generated'

import Guard from '../../utils/Guard'
import { mapFiltered, reduceByCategories } from '../../utils/util'
import EventsStore from '../EventStore/Events.store'
import GraphExecutorStore from './GraphExecutor.store'

export default class WhiteListItemsStore {
  @observable public sourceMap: Map<string, IWhiteListItem> = new Map()

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly graphExecutorStore: GraphExecutorStore,
  ) {
    Guard.requireAll({
      eventsStore,
      graphExecutorStore,
    })
  }

  @computed
  public get list(): IWhiteListItem[] {
    return [...this.sourceMap.values()]
  }

  @computed
  public get byCompanies(): { [companyId: string]: IWhiteListItem[] } {
    return reduceByCategories(this.list, i => [i.companyId, i])
  }

  public getItemsByCompanyId = (companyId: string): IWhiteListItem[] => {
    return this.byCompanies[companyId] || []
  }

  public getById = (id: string): IWhiteListItem => {
    if (id) {
      return this.sourceMap.get(id)
    }
  }

  public receiveList(projects: IWhiteListItem[], replaceMode: boolean = true) {
    if (replaceMode) {
      this.clear()
    }

    projects.forEach(this.setOne)
  }

  @action.bound
  public setOne(dto: IWhiteListItem) {
    this.sourceMap.set(dto.id, dto)
  }

  @action.bound
  public clear() {
    this.sourceMap.clear()
  }

  @action.bound
  public async requestAllItems() {
    const res = await this.graphExecutorStore.query(
      GetWhiteListItemsByProjectDocument,
      {
        projectId: this.eventsStore.appState.activeProject.id,
      },
    )

    if (res.data) {
      this.receiveList(res.data.whiteListItems.data)
    }
  }

  @action.bound
  public async requestItemsByCompany(companyId: string) {
    const res = await this.graphExecutorStore.query(
      GetWhiteListItemsByCompanyDocument,
      {
        companyId,
      },
    )

    if (res.data) {
      this.receiveList(res.data.whiteListItems.data, false)
    }
  }

  @action.bound
  public async deleteItems(items: IWhiteListItem[]) {
    if (!items.length) {
      return
    }

    const res = await this.graphExecutorStore.mutate(
      DeleteWhiteListItemsDocument,
      {
        ids: mapFiltered(items, i => i.id),
      },
    )

    if (res?.data?.deleteManyWhiteListItems) {
      items.forEach(i => this.sourceMap.delete(i.id))
    } else {
      throw res.error
    }
  }

  @action.bound
  public async addItem(domainName: string, companyId?: string) {
    if (!domainName.length) {
      return
    }

    const projectId = this.eventsStore.appState.activeProject.id

    const res = await this.graphExecutorStore.mutate(
      SaveWhiteListItemDocument,
      {
        item: {
          id: null,
          projectId,
          domainName,
          companyId: companyId || null,
        } as IWhiteListItem,
      },
    )

    if (res?.data?.saveWhiteListItem) {
      const { saveWhiteListItem: item } = res.data
      this.sourceMap.set(item.id, item)
    } else {
      throw res.error
    }
  }
}
