import { action, computed, observable } from 'mobx'

export default class PermittedCompaniesPickerModalStore {
  @observable public selectedCompanyIds: Set<string> = new Set()

  public constructor(
    private readonly onPermit: (permittedCompanyIds: string[]) => void,
    permittedCompanyIds: string[],
  ) {
    this.init(permittedCompanyIds)
  }

  @action.bound
  public init(permittedCompanyIds: string[]) {
    this.selectedCompanyIds.clear()

    permittedCompanyIds.forEach(company => {
      this.selectedCompanyIds.add(company)
    })
  }

  @action.bound
  public toggleCompanySelection(companyId: string) {
    if (this.selectedCompanyIds.has(companyId)) {
      this.selectedCompanyIds.delete(companyId)
    } else {
      this.selectedCompanyIds.add(companyId)
    }
  }

  @action.bound
  public permitCompanies() {
    this.onPermit(this.selectedCompanyIdsArray)
  }

  @action.bound
  public resetRestriction() {
    this.onPermit([])
  }

  @computed
  public get selectedCompanyIdsArray(): string[] {
    return Array.from(this.selectedCompanyIds.values())
  }
}
