import {
  ActivityStatus,
  CategoryName,
  IActivityListCustomFilter,
  IRecentlyUpdatedSavedFilter,
} from '~/client/graph'
import {
  DeleteActivityCustomFilterDocument,
  SaveActivityCustomFilterDocument,
} from '~/client/graph/operations/generated/CustomActivityFilters.generated'
import CustomActivityListFilter, {
  ActivityFilterMap,
} from '~/client/src/shared/types/CustomActivityListFilter'

import BaseCustomListFiltersStore from '../BaseCustomListFilters.store'

export default class CustomActivityListFiltersStore extends BaseCustomListFiltersStore<
  CustomActivityListFilter,
  IActivityListCustomFilter
> {
  protected readonly saveMutationDoc = SaveActivityCustomFilterDocument
  protected readonly deleteMutationDoc = DeleteActivityCustomFilterDocument

  public get byId(): Map<string, CustomActivityListFilter> {
    return this.eventsStore.appState.customActivityListFilters
  }

  public getInstanceFromDto(
    dto: IActivityListCustomFilter,
  ): CustomActivityListFilter {
    return CustomActivityListFilter.fromDto(dto)
  }

  public createFromValues(
    filtersByFilterType: ActivityFilterMap,
    isPublic: boolean,
    appliedCategories: CategoryName[] = null,
    recentlyUpdatedSavedFilter: IRecentlyUpdatedSavedFilter = null,
    activityStatusFilter: ActivityStatus = null,
  ): CustomActivityListFilter {
    return new CustomActivityListFilter(
      null,
      '',
      isPublic,
      this.activeProjectId,
      this.activeUserId,
      filtersByFilterType,
      appliedCategories,
      recentlyUpdatedSavedFilter,
      activityStatusFilter,
    )
  }
}
