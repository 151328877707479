import { observable } from 'mobx'

import {
  IAttachment,
  IChecklistAnswerItem,
  IFormMaterial,
  IMeasure,
  IPermitFieldInterface,
  ISiteLocation,
  ITableRowPermitField,
  PermitFieldType,
} from '~/client/graph'

import IPermitFieldDto from './IPermitFieldDto'

export default class PermitField implements IPermitFieldInterface {
  public static fromDto(
    dto: IPermitFieldDto,
    shouldSkipTable?: boolean,
  ): PermitField {
    return new PermitField(
      dto.fieldId,
      dto.type,
      dto.attachmentValues,
      dto.checklistValues,
      dto.dateTimeValues,
      dto.locationValues,
      dto.measureValues,
      dto.stringValues,
      dto.objectIdValues,
      shouldSkipTable ? [] : dto.tableValues,
      dto.materialValues,
    )
  }

  @observable public fieldId: string
  @observable public type: PermitFieldType
  @observable public values: any[]

  public constructor(
    fieldId: string,
    type: PermitFieldType,
    attachmentValues?: IAttachment[],
    checklistValues?: IChecklistAnswerItem[],
    dateTimeValues?: number[],
    locationValues?: ISiteLocation[],
    measureValues?: IMeasure[],
    stringValues?: string[],
    objectIdValues?: string[],
    tableValues?: ITableRowPermitField[],
    materialValues?: IFormMaterial[],
  ) {
    this.fieldId = fieldId
    this.type = type

    this.values = [
      ...(attachmentValues || []),
      ...(checklistValues || []),
      ...(dateTimeValues || []),
      ...(locationValues || []),
      ...(measureValues || []),
      ...(stringValues || []),
      ...(objectIdValues || []),
      ...(tableValues?.map(tableRow =>
        tableRow.cells.map(f => PermitField.fromDto(f, true)),
      ) || []),
      ...(materialValues || []),
    ]
  }
}
