import React from 'react'

import * as Icons from '~/client/src/shared/components/Icons'

import './PhotoThumbnail.scss'

export default class PhotoThumbnail extends React.Component {
  public render() {
    return (
      <div className="thumbnail-container full-width full-height">
        <Icons.PhotoLoading className="ma-auto" />
      </div>
    )
  }
}
