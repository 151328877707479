import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import ResponsibilitiesPanel from '~/client/src/desktop/components/ResponsibilitiesPanel/ResponsibilitiesPanel'
import DeliveryActionTypes from '~/client/src/desktop/enums/DeliveryActionTypes'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import DeliveryDetails from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import DeliveryAssignmentsStore from '~/client/src/shared/stores/domain/DeliveryAssignments.store'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import DesktopDeliveryViewStore from '../../Deliveries.store'
import DeliveriesListStore from '../DeliveriesList/DeliveriesList.store'
import DeliveryActionSideBase from './components/DeliveryActionSideBase'
import DeliveryBulkEditor from './components/DeliveryBulkEditor/DeliveryBulkEditor'
import DeliveryBulkUpdater from './components/DeliveryBulkUpdater/DeliveryBulkUpdater'

// localization: translated

interface IProps {
  desktopDeliveryViewStore: DesktopDeliveryViewStore
  deliveriesListStore: DeliveriesListStore
  state: DesktopInitialState

  deliveryAssignmentsStore?: DeliveryAssignmentsStore
  common?: DesktopCommonStore
}

interface IRouteParams {
  deliveryId?: string
}

@inject('deliveryAssignmentsStore', 'common')
@observer
class DeliveryActionSideWrapper extends React.Component<
  IProps & RouteComponentProps<IRouteParams>
> {
  private get requestedDeliveryId(): string {
    return this.props.match.params.deliveryId
  }

  public constructor(props: IProps & RouteComponentProps<IRouteParams>) {
    super(props)

    this.props.desktopDeliveryViewStore.prepareViewWhenFollowingDirectLink(
      this.requestedDeliveryId,
    )
  }

  public render() {
    const { activeActionType } = this.props.desktopDeliveryViewStore

    if (!activeActionType) {
      return null
    }

    const {
      state,
      common,
      desktopDeliveryViewStore,
      deliveriesListStore,
      deliveryAssignmentsStore,
    } = this.props

    const { hideDeliveryDetails, resetActionType } = desktopDeliveryViewStore

    let title = ''
    let content = null
    let shouldShowHeader = true

    switch (activeActionType) {
      case DeliveryActionTypes.NEW:
      case DeliveryActionTypes.VIEW:
        shouldShowHeader = false
        content = (
          <DeliveryDetails
            initProjectId={state.activeProject.id}
            backClicked={hideDeliveryDetails}
            FileInputType={DesktopFileInput}
            displayedDeliveryId={this.requestedDeliveryId}
            onCreationCb={common._displayDeliveryDetailsView}
          />
        )
        break

      case DeliveryActionTypes.UPDATE:
        title = Localization.translator.updateDeliveryStatus
        content = (
          <DeliveryBulkUpdater
            deliveriesListStore={deliveriesListStore}
            onUpdated={this.handleUpdate}
          />
        )
        break
      case DeliveryActionTypes.ASSIGN:
        shouldShowHeader = false
        content = (
          <ResponsibilitiesPanel
            selectedEntitiesIds={this.selectedDeliveriesIds}
            domainStore={deliveryAssignmentsStore}
            onClose={resetActionType}
          />
        )
        break

      case DeliveryActionTypes.EDIT:
        title = Localization.translator.bulkEdit
        content = (
          <DeliveryBulkEditor
            selectedDeliveriesIds={this.selectedDeliveriesIds}
            onUpdated={this.handleUpdate}
          />
        )
    }

    return (
      <DeliveryActionSideBase
        store={desktopDeliveryViewStore}
        title={title}
        subTitle={EMPTY_STRING}
        content={content}
        shouldShowHeader={shouldShowHeader}
      />
    )
  }

  @computed
  private get selectedDeliveriesIds(): string[] {
    return this.props.deliveriesListStore.selectedInstances.map(d => d.id)
  }

  private handleUpdate = () => {
    const { deliveriesListStore, desktopDeliveryViewStore } = this.props
    deliveriesListStore.resetSelection()
    desktopDeliveryViewStore.resetActionType()
  }
}

export default withRouter(DeliveryActionSideWrapper)
