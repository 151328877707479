import { FilterType } from '~/client/graph'

import ActivityDataFieldId from '../enums/ActivityDataFieldId'

const activityFieldIdToFilterTypeMap = {
  [ActivityDataFieldId.BUILDING_ID]: FilterType.Building,
  [ActivityDataFieldId.LEVEL_ID]: FilterType.Level,
  [ActivityDataFieldId.ZONE_ID]: FilterType.Zone,
  [ActivityDataFieldId.COMPANY_ID]: FilterType.Company,
}

export default activityFieldIdToFilterTypeMap
