import * as React from 'react'

import IBasicProps from '~/client/src/shared/interfaces/IBasicProps'

import InfoIconWithTooltip from './components/InfoIconWithTooltip/InfoIconWithTooltip'

interface IHeaderProps {
  title: string
  tooltipContent?: string | JSX.Element
  className?: string
}

export function Wrapper({ children, className = '' }: IBasicProps) {
  return (
    <section
      className={`col full-height full-width no-outline-container ${className}`}
    >
      {children}
    </section>
  )
}

export function Header(props: IHeaderProps) {
  const { title, tooltipContent, className = 'row pb20' } = props

  return (
    <header className={className}>
      <h1 className="text huge bold no-grow no-white-space-wrap mr8">
        {title}
      </h1>
      {tooltipContent && (
        <InfoIconWithTooltip tooltipContent={tooltipContent} />
      )}
    </header>
  )
}
