import { ICalendar, ICalendarData } from '~/client/graph'
import Guard from '~/client/src/shared/utils/Guard'

export default class SimpleCalendarDto {
  public static fromDto(dto: ICalendar) {
    const calendarData = {
      days: dto.calendarData.days || [],
      exceptions: dto.calendarData.exceptions || [],
    }

    return new SimpleCalendarDto(
      dto.id,
      dto.isDefault,
      dto.name,
      dto.parentId,
      dto.dateLastChanged,
      dto.type,
      dto.workHoursPerDay,
      dto.workHoursPerWeek,
      dto.workHoursPerMonth,
      dto.workHoursPerYear,
      dto.isPersonalCalendar,
      calendarData,
    )
  }

  public constructor(
    public readonly id: string,
    public readonly defaultFlag: boolean,
    public readonly name: string,
    public readonly parentId: string,
    public readonly dateLastChanged: string,
    public readonly type: string,
    public readonly workHoursPerDay: number,
    public readonly worksHourPerWeek: number,
    public readonly workHoursPerMonth: number,
    public readonly worksHourPerYear: number,
    public readonly personalCalendar: boolean,
    public readonly calendarData: ICalendarData,
  ) {
    Guard.requireAll({
      id,
      calendarData,
    })
  }
}
