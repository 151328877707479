import * as React from 'react'

import { Switch } from '@blueprintjs/core'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { CalendricalType } from '~/client/graph'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IDeliveryControl from '~/client/src/shared/models/IDeliveryControl'
import RecurringDeliveriesSettings from '~/client/src/shared/models/RecurringDeliveriesSettings'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { recurringDeliveryTypes } from '~/client/src/shared/utils/CalendricalTypeUtils'

import StruxhubInput from '../../../StruxhubInputs/StruxhubInput'
import StruxhubSelect from '../../../StruxhubInputs/StruxhubSelect'
import StruxhubTextValueSelector from '../../../StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'
import WeekdaysSelector from '../../../WeekdaysSelector/WeekdaysSelector'
import DeliveryDetailsStore, {
  MIN_RECURRING_FREQUENCY,
} from '../../DeliveryDetails.store'
import DeliveryRecurringOptionsStore from './DeliveryRecurringOptions.store'

// localization: translated

interface IProps {
  onChange: (fieldId: FieldIds, value: RecurringDeliveriesSettings) => void
  store: DeliveryDetailsStore

  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
export default class DeliveryRecurringOptions extends React.Component<
  IDeliveryControl & IProps
> {
  private readonly store: DeliveryRecurringOptionsStore = null

  public constructor(props: IDeliveryControl & IProps) {
    super(props)

    this.store = new DeliveryRecurringOptionsStore(
      props.projectDateStore,
      props.store,
      props.onChange,
    )
  }

  public componentDidMount() {
    this.store.init(this.props.value)
  }

  public componentDidUpdate(prevProps: IDeliveryControl & IProps) {
    const { value } = this.props

    if (prevProps.value !== value) {
      this.store.init(value)
    }
  }

  public render() {
    const {
      isChanged,
      store: { isCreateModeActive, showRecurringDateModal },
    } = this.props
    const {
      recurringSetting,
      maxFrequency,
      daysToRepeat,
      endDateValue,
      changeDayToRepeat,
    } = this.store

    return (
      <div
        className={classList({
          'col mx8 my8 pa10 brada8 text large': true,
          'ba-dashed-palette-grey': !isChanged,
          'ba-dashed-palette-red': isChanged,
        })}
      >
        <div className="row my5">
          <span>{Localization.translator.repeatDelivery}</span>
          {isCreateModeActive && (
            <Switch
              className="primary-blue-switch bp3-align-right no-outline-container no-grow"
              checked={!!recurringSetting}
              onChange={this.onRepeatToggle}
            />
          )}
        </div>
        {!!recurringSetting && (
          <div className="col">
            <div className="mb4 row x-between y-center no-outline-container">
              <span className="mr5 text large capitalize">
                {Localization.translator.every_plural}
              </span>
              <div className="row">
                <StruxhubInput
                  type="number"
                  max={maxFrequency}
                  min={MIN_RECURRING_FREQUENCY}
                  value={recurringSetting.frequency?.toString()}
                  onChange={this.changeFrequency}
                  isMinimalisticMode={true}
                />
                <div className="mr8 no-grow" />
                <StruxhubSelect
                  isMinimalisticMode={true}
                  value={recurringSetting.frequencyType}
                  onChange={this.changeFrequencyType}
                >
                  {recurringDeliveryTypes.map(frequencyType => (
                    <option key={frequencyType} value={frequencyType}>
                      {this.getFrequencyTypeLabel(frequencyType)}
                    </option>
                  ))}
                </StruxhubSelect>
              </div>
            </div>
            {recurringSetting.frequencyType === CalendricalType.Week && (
              <div className="row y-center no-outline-container">
                <span>{Localization.translator.repeatOn}</span>
                <WeekdaysSelector
                  className="py10"
                  selectedDayNumbers={daysToRepeat}
                  onClick={changeDayToRepeat}
                />
              </div>
            )}
            <div className="mb4 row y-center no-outline-container">
              <span className="mr5">{Localization.translator.endsOn}</span>
              <StruxhubTextValueSelector
                value={endDateValue}
                onClick={showRecurringDateModal}
                isMinimalisticMode={true}
              />
            </div>
            {isChanged && (
              <span className="text large red center">
                {Localization.translator.changed}
              </span>
            )}
          </div>
        )}
      </div>
    )
  }

  private onRepeatToggle = () => {
    if (!this.props.isDisabled) {
      this.store.toggleRecurring()
    }
  }

  private changeFrequency = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFrequency = event.target.valueAsNumber || MIN_RECURRING_FREQUENCY
    this.store.changeFrequency(newFrequency)
  }

  private changeFrequencyType = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newFrequencyType = event.target.value as CalendricalType
    this.store.changeFrequencyType(newFrequencyType)
  }

  private getFrequencyTypeLabel = (frequencyType: CalendricalType): string => {
    const { recurringSetting } = this.store

    switch (frequencyType) {
      case CalendricalType.Day:
        return Localization.translator.xDays(recurringSetting?.frequency)
      case CalendricalType.Week:
        return Localization.translator.xWeeks(recurringSetting?.frequency)
      case CalendricalType.Month:
        return Localization.translator.xMonths(recurringSetting?.frequency)
    }
  }
}
