import EventStyle from '../types/EventStyle'

export default class EventStyleCssAdapter {
  public constructor(private eventStyle: EventStyle) {}

  public getStyles() {
    const {
      textColor: color,
      backgroundColor,
      border,
      outline,
    } = this.eventStyle.styles

    const styles: React.CSSProperties = {
      color,
      backgroundColor,
    }

    if (border?.thickness) {
      styles.border = `${border.thickness}px ${border.strokeStyle} ${border.color}`
    }

    if (outline?.thickness) {
      styles.outline = `${outline.thickness}px ${outline.strokeStyle} ${outline.color}`
    }

    return styles
  }
}
