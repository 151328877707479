import { ContentObjectStatus } from '~/client/graph'

import Localization from '../../shared/localization/LocalizationManager'
import ContentObjectModel from './ContentObjectModel'

export default class ScheduleComment extends ContentObjectModel<null> {
  public static none: ScheduleComment
  public static CreateNullScheduleComment(): ScheduleComment {
    const sc = new ScheduleComment('')
    sc.author = ''
    sc.status = ContentObjectStatus.Open
    sc.threadId = ''

    return sc
  }

  public static getScheduleCommentCaption(): string {
    return Localization.translator.scheduleComment
  }

  public static getOpenedCaption(): string {
    return Localization.translator.scheduleCommentOpened.toUpperCase()
  }
}

ScheduleComment.none = ScheduleComment.CreateNullScheduleComment()
