import * as React from 'react'

import { Icon, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { classList } from 'react-classlist-helper'

import { DeliveryStatus } from '~/client/graph'

import Localization from '../../localization/LocalizationManager'
import Delivery from '../../models/Delivery'
import ActionBarBottomMenu from '../ActionBarBottomMenu/ActivityBarBottomMenu'
import DeliveryDetailsStore from '../DeliveryDetails/DeliveryDetails.store'
import DeliveryStatusLabel from '../DeliveryStatusLabel/DeliveryStatusLabel'

import './DeliveryStatusSelector.scss'

// translated

interface IProps {
  onClose: () => void
  deliveryDetailsStore: DeliveryDetailsStore
  displayedDelivery: Delivery
  isMenuPopupShown: boolean
  selectedStatus: DeliveryStatus

  setSelectedStatus: (status: DeliveryStatus) => void
}

const hiddenStatuses = [
  DeliveryStatus.Deleted,
  DeliveryStatus.Delivering,
  DeliveryStatus.Paused,
  DeliveryStatus.OnHold,
]

export default class DeliveryStatusSelector extends React.Component<IProps> {
  public componentDidUpdate(prevProps: IProps) {
    const { displayedDelivery, setSelectedStatus } = this.props
    if (
      prevProps.displayedDelivery &&
      prevProps.displayedDelivery.id !== displayedDelivery.id
    ) {
      setSelectedStatus(displayedDelivery.status)
    }
  }

  public componentDidMount() {
    const { displayedDelivery, setSelectedStatus } = this.props
    if (displayedDelivery) {
      setSelectedStatus(displayedDelivery.status)
    }
  }

  public render() {
    const { isMenuPopupShown } = this.props

    return (
      <>
        <ActionBarBottomMenu isOpen={isMenuPopupShown}>
          <div className="delivery-status-selector">
            <div className="col bt-palette-brand-lighter full-height scrollable">
              {Object.values(DeliveryStatus)
                .filter(ds => !hiddenStatuses.includes(ds))
                .map(deliveryStatus => this.renderStatus(deliveryStatus))}
            </div>
          </div>
        </ActionBarBottomMenu>
        <div className="delivery-status-selector-footer pa10">
          <div
            onClick={this.onSelectClick}
            className={classList({
              'action-button': true,
              'inactive-element': this.isSelectButtonDisabled,
            })}
          >
            {Localization.translator.select}
          </div>
        </div>
      </>
    )
  }

  private get isSelectButtonDisabled(): boolean {
    const {
      displayedDelivery: { status },
      selectedStatus,
      deliveryDetailsStore: { isAnyDialogOpened, isSubmitButtonEnabled },
    } = this.props

    return selectedStatus === DeliveryStatus.Changed
      ? !isSubmitButtonEnabled || isAnyDialogOpened
      : selectedStatus === status
  }

  private renderStatus = (status: DeliveryStatus): JSX.Element => {
    return (
      <div
        className={classList({
          'row x-between bb-palette-brand-lighter pa10': true,
          'inactive-element': this.isDisabled(status),
        })}
        onClick={this.selectStatus.bind(this, status)}
        key={status}
      >
        <DeliveryStatusLabel status={status} className="no-grow" />
        {this.props.selectedStatus === status && (
          <Icon icon={IconNames.TICK} intent={Intent.PRIMARY} />
        )}
      </div>
    )
  }

  @action.bound
  private selectStatus(status: DeliveryStatus) {
    const { deliveryDetailsStore, setSelectedStatus } = this.props

    if (status === DeliveryStatus.Canceled) {
      deliveryDetailsStore.toggleCancelConfirmModal()
    } else {
      setSelectedStatus(status)
    }
  }

  private isDisabled = (status: DeliveryStatus) => {
    const {
      isDenyButtonEnabled,
      isSubmitButtonEnabled,
      canCancelDelivery,
      getActionFromStatus,
      isAnyDialogOpened,
    } = this.props.deliveryDetailsStore

    switch (status) {
      case DeliveryStatus.Denied:
        return !isDenyButtonEnabled
      case DeliveryStatus.Changed:
        return !isSubmitButtonEnabled
      case DeliveryStatus.Canceled:
        return !canCancelDelivery
      default:
        return (
          getActionFromStatus(status).isDisabled ||
          isAnyDialogOpened ||
          isSubmitButtonEnabled
        )
    }
  }

  private onSelectClick = () => {
    const {
      selectedStatus,
      deliveryDetailsStore: { onButtonClick, getActionFromStatus },
    } = this.props

    if (selectedStatus === DeliveryStatus.Changed) {
      return onButtonClick(
        this.setNewDeliveryStatus,
        getActionFromStatus(selectedStatus).actionTitle,
      )
    }

    this.setNewDeliveryStatus()
  }

  private setNewDeliveryStatus = () => {
    const { selectedStatus, deliveryDetailsStore, onClose } = this.props
    if (selectedStatus === DeliveryStatus.Changed) {
      deliveryDetailsStore.changeDelivery()
    } else {
      deliveryDetailsStore.setNewDeliveryStatus(selectedStatus)
    }

    onClose()
  }
}
