import * as React from 'react'

import * as Icons from './Icons'

interface IProps {
  label: string
}

export default class DeliveryIconLabel extends React.Component<IProps> {
  public render() {
    return (
      <>
        <Icons.Delivery />
        <div className="ml5">{this.props.label}</div>
      </>
    )
  }
}
