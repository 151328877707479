import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { LocationType } from '~/client/graph'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import * as Icons from '~/client/src/shared/components/Icons'
import LocationsSelectorByLBS from '~/client/src/shared/components/SitemapAttributeSelector/LocationsSelectorByLBS'
import LocationsSelectorByLocationType from '~/client/src/shared/components/SitemapAttributeSelector/LocationsSelectorByLocationType'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import { NOOP } from '~/client/src/shared/utils/noop'
import smoothScrollTo from '~/client/src/shared/utils/smoothScrollTo'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import './LocationPicker.scss'

interface IProps {
  className?: string
  buttonsClassName?: string

  title?: string
  applyButtonTitle?: string
  allowedObjectIds?: string[]
  restrictedObjectIds?: string[]
  restrictedObjectTypes?: LocationType[]
  selectedIds?: string[]
  isSingleSelectionMode?: boolean
  resetDefaultsTitle?: string
  isTitleHidden?: boolean
  shouldScrollToSelected?: boolean
  isCompanyRestrictionEnabled?: boolean
  companyIds?: string[]
  canBypassCompanyRestriction?: boolean

  firstRowText?: string
  firstRowValue?: boolean
  firstRowAction?: () => void
  onChange: (selectedIds: string[]) => void
  onApplyChanges: () => void
  onResetSelection?: () => void
  onSingleSelect?: (attr: LocationBase) => void
  onClose?: () => void
  setLocationPickerState?: (isShown: boolean) => void
  isLocationViewFirst?: boolean

  companiesStore?: CompaniesStore
}

enum ViewType {
  LocationType = 'location-type',
  LBS = 'lbs',
}

const update = 'Update'
const locationType = 'Location type'
const lbs = 'LBS'
const resetDefaults = 'Reset defaults'
const objectsSelected = (count: number) =>
  `${count} object${count !== 1 ? 's' : ''} selected`
const reservedFor = 'Reserved for'

const RESET_ICON_SIZE = 18
const ANIMATION_TIME = 0.3

@inject('companiesStore')
@observer
export default class LocationPicker extends React.Component<IProps> {
  public static defaultProps = {
    className: EMPTY_STRING,
    buttonsClassName: EMPTY_STRING,
    title: EMPTY_STRING,
    applyButtonTitle: update,
    restrictedObjectTypes: [],
    selectedIds: [],
    onClose: NOOP,
    onSingleSelect: NOOP,
  }
  @observable private viewType: ViewType = ViewType.LocationType

  private scrollContainerRef: HTMLDivElement
  private elementToScroll: HTMLDivElement
  private resetBtnRef: HTMLDivElement = null
  private resetBtnTimer: any = null

  public componentDidMount() {
    if (this.props.isLocationViewFirst) {
      this.viewType = ViewType.LocationType
    }
    if (this.props.setLocationPickerState) {
      this.props.setLocationPickerState(true)
    }
    Promise.resolve().then(() => {
      this.scrollToSelectedElement()
    })
  }

  public componentWillUnmount() {
    if (this.props.setLocationPickerState) {
      this.props.setLocationPickerState(false)
    }
  }

  public render() {
    const { className } = this.props

    return (
      <div className={`location-picker scrollable col ${className}`}>
        {this.renderHeader()}
        {this.renderViewSelector()}
        {this.renderActionsRow()}
        <div
          className="scrollable checkboxes-container"
          ref={this.setContainerRef}
        >
          {this.renderCheckboxes()}
        </div>
      </div>
    )
  }

  private renderHeader = () => {
    const {
      onClose,
      onApplyChanges,
      title,
      applyButtonTitle,
      buttonsClassName,
      isTitleHidden,
    } = this.props

    if (isTitleHidden) return null

    return (
      <div className="no-grow row pa12">
        <Icons.Cross
          className={classList({
            'cross-icon no-grow pointer mx10': true,
            [buttonsClassName]: !!buttonsClassName,
          })}
          onClick={onClose}
        />
        <div className="bold large text center">{title}</div>
        <BaseActionButton
          isEnabled={true}
          className={classList({
            'scale-blue-theme': true,
            [buttonsClassName]: !!buttonsClassName,
          })}
          title={applyButtonTitle}
          onClick={onApplyChanges}
        />
      </div>
    )
  }

  private renderViewSelector() {
    const { isLocationViewFirst } = this.props
    const firstView: JSX.Element = isLocationViewFirst
      ? this.renderLocationView()
      : this.renderLBSView()
    const secondView: JSX.Element = isLocationViewFirst
      ? this.renderLBSView()
      : this.renderLocationView()
    return (
      <div className="row view-selector pa12">
        <Icon icon={IconNames.BOX} className="text light mr8" />
        {firstView}
        {secondView}
      </div>
    )
  }

  private renderLocationView() {
    return (
      <button
        className={classList({
          'ba-none brada4 mr8 px10 py8 pointer no-grow no-white-space-wrap':
            true,
          active: this.viewType === ViewType.LocationType,
        })}
        onClick={this.showItemsByLocationType}
      >
        <Icons.Location className="no-grow" />
        <div className="pl8">{locationType}</div>
      </button>
    )
  }

  private renderLBSView() {
    return (
      <button
        className={classList({
          'ba-none brada4 mr8 px10 py8 pointer no-grow no-white-space-wrap':
            true,
          active: this.viewType === ViewType.LBS,
        })}
        onClick={this.showItemsByLBS}
      >
        <Icons.Hierarchy className="no-grow" />
        <div className="pl8">{lbs}</div>
      </button>
    )
  }

  private renderActionsRow = () => {
    const { resetDefaultsTitle, selectedIds, onResetSelection } = this.props

    return (
      <div className="row y-center py5 px12">
        <div className="text grey-light large">
          {objectsSelected(selectedIds?.length)}
        </div>
        {!!onResetSelection && (
          <div
            className="text right blue-highlight large bold pointer reset-btn indication-click no-outline"
            ref={this.setResetRef}
            tabIndex={-1}
            onClick={this.onResetClick}
          >
            <Icon icon={IconNames.RESET} iconSize={RESET_ICON_SIZE} />
            <span className="pl4">{resetDefaultsTitle || resetDefaults}</span>
          </div>
        )}
      </div>
    )
  }

  private renderCheckboxes() {
    const {
      onChange,
      onSingleSelect,
      isSingleSelectionMode,
      selectedIds,
      restrictedObjectTypes,
      allowedObjectIds,
      restrictedObjectIds,
      firstRowText,
      firstRowAction,
      firstRowValue,
    } = this.props

    switch (this.viewType) {
      case ViewType.LBS:
        return (
          <LocationsSelectorByLBS
            isSingleSelectionMode={isSingleSelectionMode}
            allowedObjectIds={allowedObjectIds}
            restrictedObjectIds={restrictedObjectIds}
            selectedIds={selectedIds}
            restrictedObjectTypes={restrictedObjectTypes}
            hasCompanyRestriction={this.hasCompanyRestriction}
            renderReservedForMessage={this.renderReservedForMessage}
            onChanged={onChange}
            onSingleSelect={onSingleSelect}
            firstRowText={firstRowText}
            firstRowValue={firstRowValue}
            firstRowAction={firstRowAction}
          />
        )
      case ViewType.LocationType:
        return (
          <LocationsSelectorByLocationType
            isSingleSelectionMode={isSingleSelectionMode}
            allowedObjectIds={allowedObjectIds}
            restrictedObjectIds={restrictedObjectIds}
            selectedIds={selectedIds}
            restrictedObjectTypes={restrictedObjectTypes}
            hasCompanyRestriction={this.hasCompanyRestriction}
            renderReservedForMessage={this.renderReservedForMessage}
            onChanged={onChange}
            onSingleSelect={onSingleSelect}
            firstRowText={firstRowText}
            firstRowValue={firstRowValue}
            firstRowAction={firstRowAction}
            setItemRef={this.setItemRef}
          />
        )
    }
  }

  private showItemsByLocationType = () => {
    this.viewType = ViewType.LocationType
    this.scrollToTop()
  }

  private showItemsByLBS = () => {
    this.viewType = ViewType.LBS
    this.scrollToTop()
  }

  private setResetRef = (ref: HTMLDivElement) => {
    this.resetBtnRef = ref
  }

  private onResetClick = () => {
    clearTimeout(this.resetBtnTimer)

    this.resetBtnTimer = setTimeout(() => this.resetBtnRef?.blur(), 1000)

    this.props.onResetSelection?.()
  }

  private setContainerRef = (element: HTMLDivElement) => {
    this.scrollContainerRef = element
  }

  private setItemRef = (id: string, element: HTMLDivElement) => {
    const { selectedIds, shouldScrollToSelected } = this.props

    if (shouldScrollToSelected && selectedIds?.[0] === id) {
      this.elementToScroll = element
    }
  }

  private scrollToTop = () => {
    this.scrollContainerRef?.scrollTo(0, 0)
  }

  private scrollToSelectedElement = () => {
    const { selectedIds, shouldScrollToSelected } = this.props

    if (shouldScrollToSelected && selectedIds?.length && this.elementToScroll) {
      const parentRect = this.scrollContainerRef.getBoundingClientRect()
      const clientRec = this.elementToScroll.getBoundingClientRect()

      const scrollToNum =
        clientRec.top - parentRect.top + this.scrollContainerRef.scrollTop

      smoothScrollTo(this.scrollContainerRef, scrollToNum, 0, ANIMATION_TIME)
    }
  }

  private hasCompanyRestriction = (location: LocationBase): boolean => {
    const {
      isCompanyRestrictionEnabled,
      canBypassCompanyRestriction,
      companyIds,
    } = this.props

    return (
      isCompanyRestrictionEnabled &&
      !canBypassCompanyRestriction &&
      location.hasRestrictionForCompanies(companyIds)
    )
  }

  private renderReservedForMessage = (location: LocationBase): JSX.Element => {
    const { isCompanyRestrictionEnabled, companyIds, companiesStore } =
      this.props

    if (
      !isCompanyRestrictionEnabled ||
      !location.hasRestrictionForCompanies(companyIds)
    ) {
      return null
    }

    const permittedCompanies = location
      .getAllPermittedCompaniesNames(companiesStore)
      .join(', ')

    return (
      <span className="pl5 text large orange">
        {`${reservedFor}: ${permittedCompanies}`}
      </span>
    )
  }
}
