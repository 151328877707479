import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { observer } from 'mobx-react'
import VerificationInput from 'react-verification-input'

import Localization from '../../../localization/LocalizationManager'
import BaseCompactPopup from '../../BaseCompactPopup/BaseCompactPopup'
import { Loader } from '../../Loader'

interface IProps {
  phoneNumber: string
  isOpen: boolean
  errorMessage: string
  codeLength: number

  isCodeBeingVerified: boolean
  isCodeBeingResent: boolean

  onHide: () => void
  onCodeInputChange: (value: string) => void
  onResendCode: () => void
}

@observer
export default class CodeVerificationPopup extends React.Component<IProps> {
  public render() {
    const { onHide, isOpen } = this.props

    return (
      <BaseCompactPopup
        containerClassName="x-center"
        childrenClassName="col px16 py2 otp-input-container bg-white"
        title={Localization.translator.phoneVerification}
        titleClassName="text header bold center"
        isShown={isOpen}
        onHide={onHide}
        customLeftIcon={
          <Icon
            className="pointer mr12 no-grow"
            icon="chevron-left"
            onClick={onHide}
          />
        }
      >
        {this.renderContent()}
      </BaseCompactPopup>
    )
  }

  private renderContent() {
    if (!this.props.isOpen) {
      return null
    }

    if (this.props.isCodeBeingVerified) {
      return <Loader hint={Localization.translator.codeVerifying} />
    }

    if (this.props.isCodeBeingResent) {
      return <Loader hint={Localization.translator.codeResending} />
    }

    const {
      phoneNumber,
      onResendCode,
      errorMessage,
      codeLength,
      onCodeInputChange,
    } = this.props

    return (
      <>
        <VerificationInput
          classNames={{
            container: 'ma-x-auto',
            character: 'brada4',
          }}
          length={codeLength}
          autoFocus={true}
          placeholder=""
          validChars="0-9"
          inputProps={{
            autoComplete: 'one-time-code',
            inputMode: 'numeric',
          }}
          onChange={onCodeInputChange}
        />

        <label className="text center light lp05 mt14">
          {Localization.translator.enterTheXDigitCodeSentTo(codeLength)}{' '}
          <strong>{phoneNumber}</strong>
        </label>

        <div className="text right py24">
          <a
            className="text extra-large bold blue-brand"
            onClick={onResendCode}
          >
            {Localization.translator.resendCode}
          </a>
        </div>

        <label className="text large center red bold mt14">
          {errorMessage}
        </label>
      </>
    )
  }
}
