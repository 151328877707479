import { action, computed, observable } from 'mobx'

import { IReportTemplate } from '~/client/graph'
import HeaderBarStore from '~/client/src/desktop/components/HeaderBar/HeaderBar.store'
import {
  IModeSettings,
  getModeSettings,
} from '~/client/src/desktop/components/HeaderBar/components/ViewModeToggle/ViewModeToggle'
import DeliveryViewPeriodMode from '~/client/src/desktop/enums/DeliveryViewPeriodMode'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { getReportFileTypeLabel } from '~/client/src/shared/enums/ReportFileTypes'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { LOAD_REPORT_TEMPLATE } from '~/client/src/shared/stores/EventStore/eventConstants'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { DELIVERIES_REPORT_TEMPLATE_ID } from '~/client/src/shared/utils/usefulStrings'

import DesktopDeliveryViewStore from '../../Deliveries.store'

// localization: translated

export default class DeliveriesHeaderBarStore extends HeaderBarStore {
  @observable public isDatePickerModeSelectorOpen: boolean = false
  @observable public isMapViewDisabled: boolean = false

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly desktopDeliveryViewStore: DesktopDeliveryViewStore,
    private readonly projectDateStore: ProjectDateStore,
    isMapViewDisabled: boolean,
  ) {
    super(eventsStore.appState)
    this.isMapViewDisabled = isMapViewDisabled
    this.viewState = eventsStore.appState.deliveriesList
  }

  public get isMyDeliveriesState(): boolean {
    return this.desktopDeliveryViewStore.isMyDeliveriesOnly
  }

  public get forceCloseMap() {
    const { fieldsMap } = this.state.deliveryFilters
    return Object.keys(fieldsMap).reduce((map, filterType) => {
      map[filterType] = this.openedPopup && this.openedPopup !== filterType
      return map
    }, {})
  }

  public get activeActionType() {
    return this.desktopDeliveryViewStore.activeActionType
  }

  @computed
  public get viewModes(): IModeSettings[] {
    return [
      getModeSettings(ViewModes.Calendar),
      getModeSettings(ViewModes.List),
      getModeSettings(ViewModes.Map, this.isMapViewDisabled),
    ]
  }

  public loadReportTemplate = () => {
    this.eventsStore.dispatch(
      LOAD_REPORT_TEMPLATE,
      DELIVERIES_REPORT_TEMPLATE_ID,
      this.reportTemplateReceived,
    )
  }

  private reportTemplateReceived = (reportTemplate: IReportTemplate) => {
    this.additionalReportFiles = reportTemplate.files.map((file, i) => ({
      value: file.fileId,
      name: file.name,
      format: file.fileType,
      label: getReportFileTypeLabel(file.fileId),
      isChecked: i === 0,
    }))
  }

  @computed
  public get datePickerCaption() {
    const { startDate, endDate, calendarMode } = this.desktopDeliveryViewStore
    const { getWeekdayMonthAndDayToDisplay, getDateIntervalToDisplay } =
      this.projectDateStore

    switch (calendarMode) {
      case DeliveryViewPeriodMode.FullPeriod:
        return Localization.translator.full_period

      case DeliveryViewPeriodMode.DayPeriod:
        return getWeekdayMonthAndDayToDisplay(startDate)

      default:
        return getDateIntervalToDisplay(startDate, endDate)
    }
  }

  public setInitialDateValue() {
    this.desktopDeliveryViewStore.setInitialDateValue()
  }

  @action.bound
  public toggleIsMyDeliveriesState() {
    this.desktopDeliveryViewStore.isMyDeliveriesOnly =
      !this.desktopDeliveryViewStore.isMyDeliveriesOnly
  }

  @action.bound
  public toggleDatePickerModeSelector() {
    this.isDatePickerModeSelectorOpen = !this.isDatePickerModeSelectorOpen
  }
}
