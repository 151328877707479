import { CountriesType } from '../LanguageTreeType'

const countries: CountriesType = {
  Afghanistan: 'Afganistán',
  'Åland Islands': 'Islas Åland',
  Albania: 'Albania',
  Algeria: 'Argelia',
  'American Samoa': 'Samoa Americana',
  Andorra: 'Andorra',
  Angola: 'Angola',
  Anguilla: 'Anguila',
  Antarctica: 'Antártida',
  'Antigua and Barbuda': 'Antigua y Barbuda',
  Argentina: 'Argentina',
  Armenia: 'Armenia',
  Aruba: 'Aruba',
  Australia: 'Australia',
  Austria: 'Austria',
  Azerbaijan: 'Azerbaiyán',
  Bahamas: 'Bahamas',
  Bahrain: 'Baréin',
  Bangladesh: 'Bangladés',
  Barbados: 'Barbados',
  Belarus: 'Bielorrusia',
  Belgium: 'Bélgica',
  Belize: 'Belice',
  Benin: 'Benín',
  Bermuda: 'Bermudas',
  Bhutan: 'Bután',
  Bolivia: 'Bolivia',
  'Bosnia and Herzegovina': 'Bosnia y Herzegovina',
  Botswana: 'Botsuana',
  'Bouvet Island': 'Isla Bouvet',
  Brazil: 'Brasil',
  'British Indian Ocean Territory': 'Territorio Británico del Océano Índico',
  'Brunei Darussalam': 'Brunéi',
  Bulgaria: 'Bulgaria',
  'Burkina Faso': 'Burkina Faso',
  Burundi: 'Burundi',
  Cambodia: 'Camboya',
  Cameroon: 'Camerún',
  Canada: 'Canadá',
  'Cape Verde': 'Cabo Verde',
  'Cayman Islands': 'Islas Caimán',
  'Central African Republic': 'República Centroafricana',
  Chad: 'Chad',
  Chile: 'Chile',
  China: 'China',
  'Christmas Island': 'Isla de Navidad',
  'Cocos (Keeling) Islands': 'Islas Cocos (Keeling)',
  Colombia: 'Colombia',
  Comoros: 'Comoras',
  Congo: 'Congo',
  'Congo, Democratic Republic': 'Congo, República Democrática',
  'Cook Islands': 'Islas Cook',
  'Costa Rica': 'Costa Rica',
  "Cote D'Ivoire": 'Costa de Marfil',
  Croatia: 'Croacia',
  Cuba: 'Cuba',
  Cyprus: 'Chipre',
  'Czech Republic': 'República Checa',
  Denmark: 'Dinamarca',
  Djibouti: 'Yibuti',
  Dominica: 'Dominica',
  'Dominican Republic': 'República Dominicana',
  Ecuador: 'Ecuador',
  Egypt: 'Egipto',
  'El Salvador': 'El Salvador',
  'Equatorial Guinea': 'Guinea Ecuatorial',
  Eritrea: 'Eritrea',
  Estonia: 'Estonia',
  Ethiopia: 'Etiopía',
  'Falkland Islands (Malvinas)': 'Islas Malvinas',
  'Faroe Islands': 'Islas Feroe',
  Fiji: 'Fiyi',
  Finland: 'Finlandia',
  France: 'Francia',
  'French Guiana': 'Guayana Francesa',
  'French Polynesia': 'Polinesia Francesa',
  'French Southern Territories': 'Territorios Australes Franceses',
  Gabon: 'Gabón',
  Gambia: 'Gambia',
  Georgia: 'Georgia',
  Germany: 'Alemania',
  Ghana: 'Ghana',
  Gibraltar: 'Gibraltar',
  Greece: 'Grecia',
  Greenland: 'Groenlandia',
  Grenada: 'Granada',
  Guadeloupe: 'Guadalupe',
  Guam: 'Guam',
  Guatemala: 'Guatemala',
  Guernsey: 'Guernsey',
  Guinea: 'Guinea',
  'Guinea-Bissau': 'Guinea-Bisáu',
  Guyana: 'Guyana',
  Haiti: 'Haití',
  'Heard Island and Mcdonald Islands': 'Islas Heard y McDonald',
  'Holy See (Vatican City State)': 'Santa Sede (Ciudad del Vaticano)',
  Honduras: 'Honduras',
  'Hong Kong': 'Hong Kong',
  Hungary: 'Hungría',
  Iceland: 'Islandia',
  India: 'India',
  Indonesia: 'Indonesia',
  Iran: 'Irán',
  Iraq: 'Irak',
  Ireland: 'Irlanda',
  'Isle of Man': 'Isla de Man',
  Israel: 'Israel',
  Italy: 'Italia',
  Jamaica: 'Jamaica',
  Japan: 'Japón',
  Jersey: 'Jersey',
  Jordan: 'Jordania',
  Kazakhstan: 'Kazajistán',
  Kenya: 'Kenia',
  Kiribati: 'Kiribati',
  'Korea (North)': 'Corea del Norte',
  'Korea (South)': 'Corea del Sur',
  Kosovo: 'Kosovo',
  Kuwait: 'Kuwait',
  Kyrgyzstan: 'Kirguistán',
  Laos: 'Laos',
  Latvia: 'Letonia',
  Lebanon: 'Líbano',
  Lesotho: 'Lesoto',
  Liberia: 'Liberia',
  'Libyan Arab Jamahiriya': 'Libia',
  Liechtenstein: 'Liechtenstein',
  Lithuania: 'Lituania',
  Luxembourg: 'Luxemburgo',
  Macao: 'Macao',
  Macedonia: 'Macedonia',
  Madagascar: 'Madagascar',
  Malawi: 'Malaui',
  Malaysia: 'Malasia',
  Maldives: 'Maldivas',
  Mali: 'Malí',
  Malta: 'Malta',
  'Marshall Islands': 'Islas Marshall',
  Martinique: 'Martinica',
  Mauritania: 'Mauritania',
  Mauritius: 'Mauricio',
  Mayotte: 'Mayotte',
  Mexico: 'México',
  Micronesia: 'Micronesia',
  Moldova: 'Moldavia, República de',
  Monaco: 'Mónaco',
  Mongolia: 'Mongolia',
  Montenegro: 'Montenegro',
  Montserrat: 'Montserrat',
  Morocco: 'Marruecos',
  Mozambique: 'Mozambique',
  Myanmar: 'Myanmar',
  Namibia: 'Namibia',
  Nauru: 'Nauru',
  Nepal: 'Nepal',
  Netherlands: 'Países Bajos',
  'Netherlands Antilles': 'Antillas Neerlandesas',
  'New Caledonia': 'Nueva Caledonia',
  'New Zealand': 'Nueva Zelanda',
  Nicaragua: 'Nicaragua',
  Niger: 'Níger',
  Nigeria: 'Nigeria',
  Niue: 'Niue',
  'Norfolk Island': 'Isla Norfolk',
  'Northern Mariana Islands': 'Islas Marianas del Norte',
  Norway: 'Noruega',
  Oman: 'Omán',
  Pakistan: 'Pakistán',
  Palau: 'Palaos',
  'Palestinian Territory, Occupied': 'Territorio Palestino, Ocupado',
  Panama: 'Panamá',
  'Papua New Guinea': 'Papúa Nueva Guinea',
  Paraguay: 'Paraguay',
  Peru: 'Perú',
  Philippines: 'Filipinas',
  Pitcairn: 'Pitcairn',
  Poland: 'Polonia',
  Portugal: 'Portugal',
  'Puerto Rico': 'Puerto Rico',
  Qatar: 'Catar',
  Reunion: 'Reunión',
  Romania: 'Rumania',
  'Russian Federation': 'Federación Rusa',
  Rwanda: 'Ruanda',
  'Saint Helena': 'Santa Elena',
  'Saint Kitts and Nevis': 'San Cristóbal y Nieves',
  'Saint Lucia': 'Santa Lucía',
  'Saint Pierre and Miquelon': 'San Pedro y Miquelón',
  'Saint Vincent and the Grenadines': 'San Vicente y las Granadinas',
  Samoa: 'Samoa',
  'San Marino': 'San Marino',
  'Sao Tome and Principe': 'Santo Tomé y Príncipe',
  'Saudi Arabia': 'Arabia Saudí',
  Senegal: 'Senegal',
  Serbia: 'Serbia',
  Seychelles: 'Seychelles',
  'Sierra Leone': 'Sierra Leona',
  Singapore: 'Singapur',
  Slovakia: 'Eslovaquia',
  Slovenia: 'Eslovenia',
  'Solomon Islands': 'Islas Salomón',
  Somalia: 'Somalia',
  'South Africa': 'Sudáfrica',
  'South Georgia and the South Sandwich Islands':
    'Islas Georgias del Sur y Sandwich del Sur',
  Spain: 'España',
  'Sri Lanka': 'Sri Lanka',
  Sudan: 'Sudán',
  Suriname: 'Surinam',
  'Svalbard and Jan Mayen': 'Svalbard y Jan Mayen',
  Swaziland: 'Suazilandia',
  Sweden: 'Suecia',
  Switzerland: 'Suiza',
  'Syrian Arab Republic': 'República Árabe Siria',
  'Taiwan, Province of China': 'Taiwán, Provincia de China',
  Tajikistan: 'Tayikistán',
  Tanzania: 'Tanzania, República Unida de',
  Thailand: 'Tailandia',
  'Timor-Leste': 'Timor Oriental',
  Togo: 'Togo',
  Tokelau: 'Tokelau',
  Tonga: 'Tonga',
  'Trinidad and Tobago': 'Trinidad y Tobago',
  Tunisia: 'Túnez',
  Turkey: 'Turquía',
  Turkmenistan: 'Turkmenistán',
  'Turks and Caicos Islands': 'Islas Turcas y Caicos',
  Tuvalu: 'Tuvalu',
  Uganda: 'Uganda',
  Ukraine: 'Ucrania',
  'United Arab Emirates': 'Emiratos Árabes Unidos',
  'United Kingdom': 'Reino Unido',
  'United States': 'Estados Unidos',
  'United States Minor Outlying Islands':
    'Islas menores alejadas de los Estados Unidos',
  Uruguay: 'Uruguay',
  Uzbekistan: 'Uzbekistán',
  Vanuatu: 'Vanuatu',
  Venezuela: 'Venezuela, República Bolivariana de',
  'Viet Nam': 'Vietnam',
  'Virgin Islands, British': 'Islas Vírgenes Británicas',
  'Virgin Islands, U.S.': 'Islas Vírgenes de los Estados Unidos',
  'Wallis and Futuna': 'Wallis y Futuna',
  'Western Sahara': 'Sahara Occidental',
  Yemen: 'Yemen',
  Zambia: 'Zambia',
  Zimbabwe: 'Zimbabue',
}

export default countries
