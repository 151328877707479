import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

import DeliveriesTableWithCollapsibleColumnsStore from '../DeliveriesCollapsibleColumnsTable.store'

// localization: no display text to translate

interface IProps {
  tableWidth: number
  store: DeliveriesTableWithCollapsibleColumnsStore
  bookingSectionLeftOffset: number
  durationSectionLeftOffset: number
  locationsSectionLeftOffset: number
  equipmentSectionLeftOffset: number
  materialsSectionLeftOffset: number
  vendorSectionLeftOffset: number
  shouldHideLocationSectionToggle?: boolean
  shouldHideEquipmentSectionToggle?: boolean
  shouldHideMaterialSectionToggle?: boolean
  shouldHideVendorSectionToggle?: boolean
}

const SCROLL_WIDTH = 16

@observer
export default class DeliveriesCollapsibleButtonsBar extends React.Component<IProps> {
  public render() {
    const {
      store,
      tableWidth,
      shouldHideLocationSectionToggle,
      shouldHideEquipmentSectionToggle,
      shouldHideMaterialSectionToggle,
      shouldHideVendorSectionToggle,
      bookingSectionLeftOffset,
      durationSectionLeftOffset,
      locationsSectionLeftOffset,
      equipmentSectionLeftOffset,
      materialsSectionLeftOffset,
      vendorSectionLeftOffset,
    } = this.props

    const {
      shouldCollapseDuration,
      shouldCollapseLocations,
      shouldCollapseEquipment,
      shouldCollapseMaterials,
      shouldCollapseVendor,
      toggleDurationCollapseState,
      toggleLocationsCollapseState,
      toggleEquipmentCollapseState,
      toggleMaterialsCollapseState,
      toggleVendorCollapseState,
    } = store

    const vendorSectionOffset = shouldCollapseVendor
      ? vendorSectionLeftOffset
      : vendorSectionLeftOffset - SCROLL_WIDTH

    return (
      <div
        className="collapse-buttons-bar absolute no-outline-container"
        style={{ width: tableWidth }}
      >
        <div
          className="absolute h20"
          style={{ left: bookingSectionLeftOffset }}
        >
          <div
            className={classList({
              'collapse-btn': true,
              'section-collapsed': shouldCollapseDuration,
            })}
            onClick={toggleDurationCollapseState}
            style={{ left: durationSectionLeftOffset }}
          >
            <div className="arrow-right">
              <Icons.OffloadingEquipmentMenuRight />
            </div>
            <div className="arrow-left">
              <Icons.OffloadingEquipmentMenuLeft />
            </div>
          </div>
          {!shouldHideLocationSectionToggle && (
            <div
              className={classList({
                'collapse-btn': true,
                'section-collapsed': shouldCollapseLocations,
              })}
              onClick={toggleLocationsCollapseState}
              style={{ left: locationsSectionLeftOffset }}
            >
              <div className="arrow-right">
                <Icons.OffloadingEquipmentMenuRight />
              </div>
              <div className="arrow-left">
                <Icons.OffloadingEquipmentMenuLeft />
              </div>
            </div>
          )}
          {!shouldHideEquipmentSectionToggle && (
            <div
              className={classList({
                'collapse-btn': true,
                'section-collapsed': shouldCollapseEquipment,
              })}
              onClick={toggleEquipmentCollapseState}
              style={{ left: equipmentSectionLeftOffset }}
            >
              <div className="arrow-right">
                <Icons.OffloadingEquipmentMenuRight />
              </div>
              <div className="arrow-left">
                <Icons.OffloadingEquipmentMenuLeft />
              </div>
            </div>
          )}
          {!shouldHideMaterialSectionToggle && (
            <div
              className={classList({
                'collapse-btn': true,
                'section-collapsed': shouldCollapseMaterials,
              })}
              onClick={toggleMaterialsCollapseState}
              style={{ left: materialsSectionLeftOffset }}
            >
              <div className="arrow-right">
                <Icons.OffloadingEquipmentMenuRight />
              </div>
              <div className="arrow-left">
                <Icons.OffloadingEquipmentMenuLeft />
              </div>
            </div>
          )}
          {!shouldHideVendorSectionToggle && (
            <div
              className={classList({
                'collapse-btn': true,
                'section-collapsed': shouldCollapseVendor,
              })}
              onClick={toggleVendorCollapseState}
              style={{ left: vendorSectionOffset }}
            >
              <div className="arrow-right">
                <Icons.OffloadingEquipmentMenuRight />
              </div>
              <div className="arrow-left">
                <Icons.OffloadingEquipmentMenuLeft />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
