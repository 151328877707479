import * as React from 'react'

import { observer } from 'mobx-react'

import { IDateCell } from '~/client/src/desktop/views/Deliveries/components/DeliveriesList/DeliveriesList.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import DotIndicator from './components/DotIndicator/DotIndicator'

// localization: translated

@observer
export default class DeliveryDateCell extends React.Component<IDateCell> {
  public render() {
    const { dates, isActive } = this.props

    return (
      <div className="row">
        <div className="col y-center">
          {dates.map((date, i) => (
            <div className="row" key={i} title={date}>
              {date}
            </div>
          ))}
        </div>
        <div className="col y-center no-grow">
          <div>
            <DotIndicator isActive={isActive} />
          </div>
          {dates.length > 1 && (
            <div>{Localization.translator.a_actualFlag}</div>
          )}
        </div>
      </div>
    )
  }
}
