import * as createUuid from 'uuid/v1'

import { IFileRequisites, UploadingType } from '~/client/graph'

import UploadFileCommand from '../../commands/UploadFile/UploadFileCommand'
import GraphExecutorStore from './GraphExecutor.store'

export class FileUploadingStore {
  public constructor(private graphExecutorStore: GraphExecutorStore) {}

  public async uploadFile(
    file: Blob,
    fileType: UploadingType,
    fileName?: string,
  ): Promise<IFileRequisites[]> {
    if (!fileName) {
      fileName = createUuid()
    }

    const isFilePublic =
      fileType === UploadingType.Image ||
      fileType === UploadingType.Pdf ||
      fileType === UploadingType.Avatar ||
      fileType === UploadingType.MsExcel ||
      fileType === UploadingType.MsWord ||
      fileType === UploadingType.MsPowerPoint

    const command = new UploadFileCommand(
      file,
      this.graphExecutorStore,
      fileName,
      fileType,
      isFilePublic,
    )

    return command.execute()
  }
}
