import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import DeliveryAssociationStatus from '~/client/src/shared/components/DeliveryAssociationStatus/DeliveryAssociationStatus'
import DeliveryStatusLabel from '~/client/src/shared/components/DeliveryStatusLabel/DeliveryStatusLabel'
import * as Icons from '~/client/src/shared/components/Icons'
import { LWFCRowData } from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import { Loader } from '~/client/src/shared/components/Loader'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import UsernameFromUid from '~/client/src/shared/components/UsernameFromUid'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'
import { UPDATE_DELIVERY_MATERIALS } from '~/client/src/shared/stores/EventStore/eventConstants'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'

import { NO_VALUE } from '../../../../../shared/utils/usefulStrings'
import DeliveriesListStore, {
  DataKeys,
  IDateCell,
} from './DeliveriesList.store'
import DeliveriesCollapsibleColumnsTable from './components/DeliveriesCollapsibleColumnsTable/DeliveriesCollapsibleColumnsTable'
import DeliveryDateCell from './components/DeliveryDateCell/DeliveryDateCell'
import DeliveryMaterialsCell from './components/DeliveryMaterialsCell/DeliveryMaterialsCell'

// localization: translated

interface IProps {
  store: DeliveriesListStore
  state?: DesktopInitialState
}

const LOADER_SIZE = 20

const statusCellRenderer = (status?: DeliveryStatus) =>
  status ? (
    <DeliveryStatusLabel status={status} />
  ) : (
    <Loader size={LOADER_SIZE} className="no-flex" />
  )

const attrCellRenderer = value =>
  value ? (
    <SitemapAttributeTag
      dataObject={value}
      shouldShowAsTag={true}
      contentContainerClassName="text-ellipsis py2"
    >
      <span title={value.name} className="text large">
        {value.name}
      </span>
    </SitemapAttributeTag>
  ) : (
    <>{Localization.translator.unspecified}</>
  )

const columnKeyToCellRenderer = {
  [DataKeys.ON_SITE_CONTACTS]: values => {
    if (!Array.isArray(values)) return values || NO_VALUE

    return values?.length
      ? values.map((value, index) => (
          <React.Fragment key={index}>
            {index > 0 && ', '}
            <UsernameFromUid userId={value} />
          </React.Fragment>
        ))
      : NO_VALUE
  },
  [DataKeys.STATUS]: statusCellRenderer,

  [DataKeys.GATE]: attrCellRenderer,
  [DataKeys.ZONE]: attrCellRenderer,
  [DataKeys.ROUTE]: attrCellRenderer,
  [DataKeys.BUILDING]: attrCellRenderer,
  [DataKeys.LEVEL]: attrCellRenderer,
  [DataKeys.AREA]: attrCellRenderer,

  [DataKeys.DATE]: ({ dates, isActive }: IDateCell) => (
    <DeliveryDateCell dates={dates} isActive={isActive} />
  ),
  [DataKeys.DURATION]: ({ dates }: IDateCell) => (
    <DeliveryDateCell dates={dates} />
  ),
  [DataKeys.BOOKING_ID]: (
    delivery: Delivery,
    companiesStore: CompaniesStore,
  ) => (
    <>
      <DeliveryAssociationStatus deliveryId={delivery.id} className="no-grow" />
      {delivery.isFromConcreteDirect && (
        <Icons.ConcreteDirect className="row no-grow ml10" />
      )}
      <span className="text-ellipsis ml10">
        {delivery.codeToDisplay(companiesStore)}
        {delivery.recurringSettingId && (
          <Icons.Repeat className="inline-block no-outline no-grow ml8 pt4" />
        )}
      </span>
    </>
  ),
  [DataKeys.MATERIALS_SECTION]: ({
    id,
    onAddClick,
  }: {
    id: string
    onAddClick: (deliveryId: string, shouldAddMaterial?: boolean) => void
  }) => (
    <DeliveryMaterialsCell deliveryId={id} onAddMaterialClick={onAddClick} />
  ),
}

@inject('state')
@observer
export default class DeliveriesList extends React.Component<IProps> {
  public componentDidMount() {
    this.props.store.handleMount()
  }

  public componentWillUnmount() {
    this.props.store.handleUnmount()
  }

  public render() {
    const {
      columns,
      columnsWidthState,
      selectAll,
      sortState,
      groupingKey,
      resetSelection,
      selectAllTitle,
      handleColumnSort,
      collapsedCategories,
      toggleCategoryCollapsing,
      rows,
      toggleCategory,
      scrollToRow,
    } = this.props.store

    return (
      <>
        {this.renderConfirmSubscriptionDialog()}
        <div className="deliveries-list-container">
          <div className="row table-holder">
            <DeliveriesCollapsibleColumnsTable
              rows={rows}
              groupingKey={groupingKey}
              sortedColumnKey={sortState.columnKey}
              sortingOrder={sortState.order}
              columns={columns}
              columnsWidthState={columnsWidthState}
              selectAll={selectAll}
              resetAll={resetSelection}
              onColumnSort={handleColumnSort}
              selectAllTitle={selectAllTitle}
              onCategoryCheckboxToggle={toggleCategory}
              onCellClick={this.handleCellClick}
              getCellClassNames={this.getCellClassNames}
              collapsedCategories={collapsedCategories}
              columnKeyToCellRenderer={columnKeyToCellRenderer}
              onCategoryCollapsingToggle={toggleCategoryCollapsing}
              scrollToRow={scrollToRow}
            />
          </div>
        </div>
      </>
    )
  }

  @action.bound
  private handleCellClick(rowData: LWFCRowData, columnKey: string) {
    if (DataKeys.MATERIALS_SECTION === columnKey) {
      return
    }

    const deliveryId = rowData[DataKeys.ID]
    const { loading } = this.props.state
    if (loading.get(UPDATE_DELIVERY_MATERIALS + deliveryId)) {
      return
    }

    const { toggleRowCheckbox, selectRow } = this.props.store

    if (DataKeys.CHECKBOX === columnKey) {
      return toggleRowCheckbox(deliveryId)
    }

    selectRow(deliveryId)
  }

  private getCellClassNames = (columnKey: string, rowData: LWFCRowData) => {
    const status: DeliveryStatus = rowData && rowData[DataKeys.STATUS]

    return {
      done:
        status === DeliveryStatus.Done ||
        status === DeliveryStatus.IncompleteDone,
      updating: !status && !!rowData,
      'no-padding': DataKeys.BOOKING_ID === columnKey,
    }
  }

  private renderConfirmSubscriptionDialog() {
    const {
      shouldSubscriptionConfirmModalShow,
      closeSubscriptionConfirmModal,
      subscriptionConfirmMessage,
      handleChangeAssociations,
      subscriptionConfirmButtonText,
    } = this.props.store

    return (
      <ConfirmDialog
        isOpen={shouldSubscriptionConfirmModalShow}
        onCancelClicked={closeSubscriptionConfirmModal}
        onDoneClicked={handleChangeAssociations}
        doneTitle={subscriptionConfirmButtonText}
      >
        <div className="text large pre-line">{subscriptionConfirmMessage}</div>
      </ConfirmDialog>
    )
  }
}
