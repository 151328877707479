import { IConstraint, LocationType } from '~/client/graph'
import {
  ConstraintsByProjectIdDocument,
  DeleteManyConstraintsDocument,
  SaveManyConstraintsDocument,
} from '~/client/graph/operations/generated/Constraint.generated'

import LocationBase from '../../models/LocationObjects/LocationBase'
import {
  areArraysEqual,
  copyObjectArray,
  copyObjectDeep,
} from '../../utils/util'
import InitialState from '../InitialState'
import GraphExecutorStore from './GraphExecutor.store'
import LocationAttributesStore from './LocationAttributes.store'

const CONSTRAINT_KEY_BY_TYPE = {
  [LocationType.Gate]: 'restrictedGates',
  [LocationType.Route]: 'restrictedRoutes',
  [LocationType.OffloadingEquipment]: 'restrictedEquipment',
  [LocationType.Level]: 'restrictedLevels',
  [LocationType.Area]: 'restrictedAreas',
  [LocationType.InteriorDoor]: 'restrictedInteriorDoors',
  [LocationType.InteriorPath]: 'restrictedInteriorPaths',
  [LocationType.Staging]: 'restrictedStagings',
}
export default class SyncRestrictionsStore {
  public constructor(
    private state: InitialState,
    private locationAttributesStore: LocationAttributesStore,
    private graphExecutorStore: GraphExecutorStore,
  ) {}

  public async updateRestrictionsForItem(item: LocationBase) {
    const restrictions = await this.getConstraints()
    const repo = new ConstraintsRepo(restrictions)

    const children = this.getAllChildren(item)

    children.forEach(child => {
      const parents = this.getAllParents(child)
      const buildingParents = parents.filter(
        p => p.type === LocationType.Building,
      )
      const zoneParent = parents.find(p => p.type === LocationType.Zone)

      switch (child.type) {
        case LocationType.Zone:
          if (!buildingParents.length) {
            return
          }
          const existingBuildingConstraints = repo.findAll(
            c =>
              c.zoneId === child.id &&
              !!buildingParents.find(b => c.buildingId === b.id),
          )

          repo.deleteByPredicate(
            c =>
              !c.isExplicit &&
              c.zoneId === child.id &&
              !existingBuildingConstraints.includes(c),
          )

          buildingParents.forEach(building => {
            const buildingConstraint = existingBuildingConstraints.find(
              c => c.buildingId === building.id,
            )
            if (buildingConstraint) {
              return
            }
            const newConstraint = this.generateConstraintForBuildingAndZone(
              building,
              child,
            )
            repo.create(newConstraint)
          })
          return
        case LocationType.Route:
        case LocationType.Gate:
        case LocationType.OffloadingEquipment:
        case LocationType.Level:
        case LocationType.Area:
          repo.constraints.forEach(constraint => {
            if (constraint.isExplicit) {
              return
            }

            const shouldAddToRestriction =
              (buildingParents.length &&
                !buildingParents.find(b => b.id === constraint.buildingId)) ||
              (zoneParent && zoneParent.id !== constraint.zoneId)

            const key = CONSTRAINT_KEY_BY_TYPE[child.type]
            const isInList = constraint[key].includes(child.id)
            if (shouldAddToRestriction && !isInList) {
              constraint[key].push(child.id)
            }
            if (!shouldAddToRestriction && isInList) {
              constraint[key] = constraint[key].filter(id => id !== child.id)
            }
          })
          return
      }
    })

    repo.proceed(this.graphExecutorStore)
  }

  private generateConstraintForBuildingAndZone(
    building: LocationBase,
    zone: LocationBase,
  ): IConstraint {
    const restrictedGates = this.locationAttributesStore.gatesStore.list
      .filter(dto => !this.isAttributeAllowed(dto, building.id, zone.id))
      .map(({ id }) => id)
    const restrictedRoutes = this.locationAttributesStore.routesStore.list
      .filter(dto => !this.isAttributeAllowed(dto, building.id, zone.id))
      .map(({ id }) => id)
    const restrictedEquipment =
      this.locationAttributesStore.offloadingEquipmentsStore.list
        .filter(dto => !this.isAttributeAllowed(dto, building.id, zone.id))
        .map(({ id }) => id)
    const restrictedLevels = this.locationAttributesStore.levelsStore.list
      .filter(dto => !this.isAttributeAllowed(dto, building.id, zone.id))
      .map(({ id }) => id)
    const restrictedAreas = this.locationAttributesStore.areasStore.list
      .filter(dto => !this.isAttributeAllowed(dto, building.id, zone.id))
      .map(({ id }) => id)
    const restrictedInteriorDoors =
      this.locationAttributesStore.interiorDoorsStore.list
        .filter(dto => !this.isAttributeAllowed(dto, building.id, zone.id))
        .map(({ id }) => id)
    const restrictedInteriorPaths =
      this.locationAttributesStore.interiorPathsStore.list
        .filter(dto => !this.isAttributeAllowed(dto, building.id, zone.id))
        .map(({ id }) => id)
    const restrictedStagings = this.locationAttributesStore.stagingsStore.list
      .filter(dto => !this.isAttributeAllowed(dto, building.id, zone.id))
      .map(({ id }) => id)
    return {
      id: null,
      projectId: this.state.activeProject.id,
      buildingId: building.id,
      zoneId: zone.id,
      restrictedGates,
      restrictedRoutes,
      restrictedEquipment,
      restrictedLevels,
      restrictedAreas,
      restrictedInteriorDoors,
      restrictedInteriorPaths,
      restrictedStagings,
      isExplicit: false,
      createdAt: undefined,
      updatedAt: undefined,
    }
  }

  private isAttributeAllowed = (
    item: LocationBase,
    buildingId: string,
    zoneId: string,
  ) => {
    if (!item.hasParent) {
      return true
    }
    const parent = this.attributes.find(a => item.isParent(a))
    const isParentAllowed =
      (parent.type !== LocationType.Building || parent.id === buildingId) &&
      (parent.type !== LocationType.Zone || parent.id === zoneId)

    if (!isParentAllowed) {
      return false
    }

    return this.isAttributeAllowed(parent, buildingId, zoneId)
  }

  private async getConstraints(): Promise<IConstraint[]> {
    const { data } = await this.graphExecutorStore.query(
      ConstraintsByProjectIdDocument,
      {
        projectId: this.state.activeProject.id,
      },
    )

    return data.constraints.data || []
  }

  private getAllParents(item: LocationBase): LocationBase[] {
    const directParent = this.attributes.find(i => item.isParent(i))

    if (!directParent) {
      return []
    }

    return [directParent, ...this.getAllParents(directParent)]
  }

  private getAllChildren(item: LocationBase): LocationBase[] {
    const directChildren = this.attributes.filter(i => i.isParent(item))

    const res = [item]
    directChildren.forEach(child => {
      res.push(...this.getAllChildren(child))
    })

    return res
  }

  private get attributes(): LocationBase[] {
    return this.locationAttributesStore.allAttributes
  }
}

class ConstraintsRepo {
  private initialConstraints: IConstraint[] = []
  public constraints: IConstraint[] = []

  public constructor(constraints: IConstraint[]) {
    this.initialConstraints = copyObjectArray(constraints)
    this.constraints = (constraints || []).map(c => copyObjectDeep(c))
  }

  public findAll(predicate: (c: IConstraint) => boolean) {
    return this.constraints.filter(predicate)
  }

  public create(constraint: IConstraint) {
    this.constraints.push(constraint)
  }

  public delete(constraint: IConstraint) {
    this.constraints = this.constraints.filter(c => c !== constraint)
  }

  public deleteByPredicate(predicate: (c: IConstraint) => boolean) {
    this.constraints = this.constraints.filter(c => !predicate(c))
  }

  public proceed(graphExecutorStore: GraphExecutorStore) {
    const restrictionsToDeleteIds = this.initialConstraints
      .filter(ic => !this.constraints.find(c => c.id === ic.id))
      .map(({ id }) => id)
    const restrictionsToUpdate = this.constraints
      .filter(c => !c.id || this.isConstraintChanged(c))
      .map(c => {
        delete c.updatedAt
        delete c.createdAt
        return c
      })

    if (restrictionsToDeleteIds.length) {
      graphExecutorStore.mutate(DeleteManyConstraintsDocument, {
        ids: restrictionsToDeleteIds,
      })
    }
    if (restrictionsToUpdate.length) {
      graphExecutorStore.mutate(SaveManyConstraintsDocument, {
        constraints: restrictionsToUpdate,
      })
    }
  }

  private isConstraintChanged(c: IConstraint) {
    const initialConstraint = this.initialConstraints.find(ic => ic.id === c.id)
    if (!initialConstraint) {
      return true
    }

    return (
      !areArraysEqual(c.restrictedRoutes, initialConstraint.restrictedRoutes) ||
      !areArraysEqual(c.restrictedGates, initialConstraint.restrictedGates) ||
      !areArraysEqual(
        c.restrictedEquipment,
        initialConstraint.restrictedEquipment,
      ) ||
      !areArraysEqual(c.restrictedLevels, initialConstraint.restrictedLevels) ||
      !areArraysEqual(c.restrictedAreas, initialConstraint.restrictedAreas) ||
      !areArraysEqual(
        c.restrictedInteriorDoors,
        initialConstraint.restrictedInteriorDoors,
      ) ||
      !areArraysEqual(
        c.restrictedInteriorPaths,
        initialConstraint.restrictedInteriorPaths,
      ) ||
      !areArraysEqual(
        c.restrictedStagings,
        initialConstraint.restrictedStagings,
      )
    )
  }
}
