import { observable } from 'mobx'

import {
  IOperationRule,
  IOperationRuleInput,
  OperationSubjectType,
  OperationType,
} from '~/client/graph'
import BaseModel from '~/client/src/shared/models/BaseModel'
import { AndOrOperator } from '~/client/src/shared/models/LogicOperation'

import { TagType } from '../enums/TagType'

const SEPARATOR = ` ${AndOrOperator.OR} `

export default class OperationRule
  extends BaseModel<IOperationRule>
  implements IOperationRule
{
  public static NEW_ID = 'new-id'
  public static ASSIGNMENT_PLACEHOLDER = ''

  public static fromDto(dto: IOperationRule) {
    return new OperationRule(
      dto.expression,
      dto.operation,
      dto.operationSubject,
      dto.projectId,
      dto.assignExpression,
      dto.id,
      dto.createdAt,
      dto.updatedAt,
    )
  }

  public static toDto(rule: OperationRule): IOperationRuleInput {
    return {
      id: rule.isExisting ? rule.id : null,
      projectId: rule.projectId,
      expression: rule.expression,
      assignExpression: rule.assignExpression,
      operation: rule.operation,
      operationSubject: rule.operationSubject,
      createdAt: rule.createdAt,
      updatedAt: rule.updatedAt,
    }
  }

  public static makeTagExpressionString(
    tagType: TagType | string,
    tagId: string,
  ): string {
    return `[${tagType}][${tagId}]`
  }

  @observable public assignmentsAsSubexpressions: string[] = [
    OperationRule.ASSIGNMENT_PLACEHOLDER,
  ]

  public constructor(
    public expression: string,
    public operation: OperationType,
    public operationSubject: OperationSubjectType,
    public projectId: string,
    public assignExpression: string = OperationRule.ASSIGNMENT_PLACEHOLDER,
    id: string = '',
    createdAt: number = 0,
    updatedAt: number = 0,
  ) {
    super(id)

    this.assignmentsAsSubexpressions = (
      assignExpression || OperationRule.ASSIGNMENT_PLACEHOLDER
    ).split(SEPARATOR)

    this.setCreatedAt(createdAt)
    this.setUpdatedAt(updatedAt)
  }

  public get isExisting(): boolean {
    return !!this.id && this.id !== OperationRule.NEW_ID
  }

  public setExpression(expression: string) {
    if (expression) {
      this.expression = expression
    }
  }

  public updateAssignmentExpressions() {
    this.assignExpression = this.assignmentsAsSubexpressions
      .filter(exp => !!exp)
      .join(SEPARATOR)
  }
}
