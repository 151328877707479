import * as React from 'react'

import { Popover, PopoverPosition, PopperModifiers } from '@blueprintjs/core'
import { inject, observer } from 'mobx-react'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DynamicOverflowList from '~/client/src/shared/components/DynamicOverflowList/DynamicOverflowList'
import { Loader } from '~/client/src/shared/components/Loader'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryUnitsStore from '~/client/src/shared/stores/domain/DeliveryUnits.store'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import DeliveryMaterialsCellStore, {
  ADDITIONAL_TAGS_WIDTH,
  MATERIAL_TAGS_COUNT,
} from './DeliveryMaterialsCell.store'
import DeliveryMaterialsCellModal from './components/DeliveryMaterialsCellModal'

import './DeliveryMaterialsCell.scss'

// localization: no display text to translate

interface IProps {
  deliveryId: string
  onAddMaterialClick: (deliveryId: string, shouldAddMaterial?: boolean) => void

  materialsStore?: MaterialsStore
  materialCategoryStore?: MaterialCategoryStore
  eventsStore?: DesktopEventStore
  deliveriesStore?: DeliveriesStore
  deliveryUnitsStore?: DeliveryUnitsStore
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: {
    enabled: true,
    boundariesElement: 'window',
  },
  hide: { enabled: false },
  computeStyle: { gpuAcceleration: false },
}

@inject(
  'materialsStore',
  'materialCategoryStore',
  'eventsStore',
  'deliveriesStore',
  'deliveryUnitsStore',
)
@observer
export default class DeliveryMaterialsCell extends React.Component<IProps> {
  private readonly store: DeliveryMaterialsCellStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new DeliveryMaterialsCellStore(
      props.deliveryId,
      props.materialsStore,
      props.materialCategoryStore,
      props.eventsStore,
      props.deliveriesStore,
      props.deliveryUnitsStore,
    )
  }

  public componentDidUpdate(prevProps: IProps) {
    const { deliveryId } = this.props
    if (deliveryId !== prevProps.deliveryId) {
      this.store.init(deliveryId)
    }
  }

  public render() {
    const { isLoading, deliveryMaterials } = this.store

    if (isLoading) {
      return <Loader />
    }

    if (!deliveryMaterials?.length) {
      return NO_VALUE
    }

    const { deliveryId, onAddMaterialClick } = this.props

    return (
      <Popover
        className="full-height full-width pointer"
        targetClassName="full-height full-width"
        popoverClassName="delivery-materials-cell-modal"
        position={PopoverPosition.BOTTOM_LEFT}
        canEscapeKeyClose={false}
        boundary="window"
        modifiers={popoverPopperModifiers}
        content={
          <DeliveryMaterialsCellModal
            deliveryId={deliveryId}
            materials={deliveryMaterials}
            onAddMaterialClick={onAddMaterialClick}
            store={this.store}
          />
        }
      >
        <DynamicOverflowList
          items={this.deliveryMaterialTags}
          rowsCount={MATERIAL_TAGS_COUNT}
          additionalWidth={ADDITIONAL_TAGS_WIDTH}
          allowRecalculationForItems={true}
        >
          {({ visibleElements, overflowElements, containerRefSetter }) => {
            return (
              <div
                className="full-height full-width pointer"
                ref={containerRefSetter}
              >
                {visibleElements?.length ? visibleElements : NO_VALUE}
                {!!overflowElements?.length && (
                  <div className="inline-block">...</div>
                )}
              </div>
            )
          }}
        </DynamicOverflowList>
      </Popover>
    )
  }

  private get deliveryMaterialTags(): JSX.Element[] {
    return this.store.deliveryMaterialsLabels.map((mat, idx) => (
      <SitemapAttributeTag
        key={idx}
        shouldShowAsTag={true}
        className="mx2 material-tag"
        contentContainerClassName="text-ellipsis py2"
      >
        <span title={mat} className="text large">
          {mat}
        </span>
      </SitemapAttributeTag>
    ))
  }
}
