import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { selectors } from 'react-virtualized-tree'

import { ActivitiesTreeNodeTypes } from '~/client/src/shared/enums/ActivitiesTreeNodeTypes'
import { IActivitiesTreeNode } from '~/client/src/shared/models/IActivitiesTreeNode'
import { IDeliveryTreeNode } from '~/client/src/shared/models/IDeliveryTreeNode'

import FileInputBase from '../../../FileInput/FileInput'
import TableSeparators from '../../../TableSeparators'

const { getNodeRenderOptions, updateNode } = selectors

const LEVEL_OFFSET = 18
const { COMPLETED_ACTIVITY_CONTAINER, NEXT_ACTIVITY_CONTAINER } =
  ActivitiesTreeNodeTypes

const { CARET_DOWN, CARET_RIGHT } = IconNames

interface IProps {
  node: IActivitiesTreeNode | IDeliveryTreeNode
  index: string | number
  measure: () => void
  onChange: (node: any) => void
  startViewDate: Date
  className?: string
  changeContainerExpandState?: (id: string, value: boolean) => void
  isContainerUpdateEnabled?: boolean
  FileInputType: typeof FileInputBase
  shouldRenderSeparators?: boolean
}

export default class CustomNodeRenderer extends React.Component<IProps> {
  public componentDidMount() {
    this.props.measure()
  }

  public render() {
    const {
      className = '',
      children,
      shouldRenderSeparators,
      node,
    } = this.props
    const level = node.level - 1

    return (
      <div className={`row content ${className}`} onClick={this.handleChange}>
        {shouldRenderSeparators && (
          <TableSeparators
            level={level}
            className="no-grow br-palette-grey full-height"
            marginLeft={LEVEL_OFFSET}
          />
        )}
        {this.handle}
        {children}
      </div>
    )
  }

  private get handle(): JSX.Element {
    const { node } = this.props

    const { hasChildren, isExpanded } = getNodeRenderOptions(node)
    const isSubNode =
      node.nodeType === NEXT_ACTIVITY_CONTAINER ||
      node.nodeType === COMPLETED_ACTIVITY_CONTAINER

    if (!isSubNode && !hasChildren) {
      return null
    }

    return (
      <Icon
        icon={isExpanded ? CARET_DOWN : CARET_RIGHT}
        className="ml10 mr10 no-grow handle-icon"
      />
    )
  }

  @action.bound
  private handleChange() {
    const { onChange, node, index } = this.props
    const { isExpanded, hasChildren } = getNodeRenderOptions(node)

    const isSubNode =
      node.nodeType === NEXT_ACTIVITY_CONTAINER ||
      node.nodeType === COMPLETED_ACTIVITY_CONTAINER

    if (!isSubNode && !hasChildren) {
      return
    }

    if (isSubNode) {
      node.onClick()
    }

    onChange({ ...updateNode(node, { expanded: !isExpanded }), index })
  }
}
