import MaturixStrengthUnit from '../../enums/MaturixStrengthUnit'
import MaturixTemperatureUnit from '../../enums/MaturixTemperatureUnit'
import { TagType } from '../../enums/TagType'
import { getBandTitleByTagType } from '../../utils/TagHelper'
import { brandName, brandShortName } from '../brand'
import countries from './countries'
import timezones from './timezones'

const ending = (x: number, single: string = '', plural: string = 's') =>
  x === 1 ? single : plural

const deliveries = (x: number) => `Deliver${ending(x, 'y', 'ies')}`
const plans = (x: number) => `Plan${ending(x, '', 's')}`
const workflows = (x: number) => `Workflow${ending(x, '', 's')}`
const users = (x: number) => `User${ending(x, '', 's')}`
const activities = (x: number) => `Activit${ending(x, 'y', 'ies')}`
const forms = (x: number) => `Form${ending(x, '', 's')}`
const announcements = (x: number) => `Announcement${ending(x, '', 's')}`
const sensors = (x: number) => `Sensor${ending(x, '', 's')}`
const closures = (x: number) => `Closure${ending(x, '', 's')}`

const xHours = (x: number) => `${x} hour${ending(x)}`
const xUsers = (x: number) => `${x} user${ending(x)}`
const xFiles = (x: number) => `${x} file${ending(x)}`
const xDates = (x: number) => `${x} Date${ending(x)}`
const xForms = (x: number) => `${x} Form${ending(x)}`

const EnglishTree = {
  _brandName: brandName,
  _brandShortName: brandShortName,

  avatar: 'Avatar',
  ok: 'OK',
  cancel: 'Cancel',
  open: 'Open',
  opened: 'Opened',
  close: 'Close',
  closed: 'Closed',
  contacts: 'Contacts',
  loading: 'Loading',
  language: 'Language',
  notSpecified: 'Not specified',
  default: 'Default',
  done: 'Done',
  save: 'Save',
  apply: 'Apply',
  edit_verb: 'Edit',
  merge_verb: 'Merge',
  link_verb: 'Link',
  re_link_verb: 'Re-link',
  unlink_verb: 'Unlink',
  unlinking: 'Unlinking',
  delete: 'Delete',
  initials: 'Initials',
  invite: 'Invite',
  search: 'Search',
  selectAll_people: 'Select All',
  selectAll_items: 'Select All',
  selectNone_people: 'Select None',
  selectNone_items: 'Select None',
  clearAll_items: 'Clear All',
  clear: 'Clear',
  uploading: 'Uploading',
  noResults: 'No results',
  add: 'Add',
  update_verb: 'Update',
  updating: 'Updating',
  error: 'Error',
  goBack: 'Go back',
  confirm: 'Confirm',
  select: 'Select',
  selected: 'Selected',
  start_verb: 'Start',
  finish_verb: 'Finish',
  followers: 'Followers',
  view_verb: 'View',
  download: 'Download',
  downloadingStatus: {
    starting: 'Starting',
    fetching: 'Fetching',
    compiling: 'Compiling',
    generating: 'Generating',
    downloading: 'Downloading',
  },
  searchCompany: 'Search company',
  permit: 'Permit',
  permitAll: 'Permit All',
  thereIsNoCompanies: 'There is no companies',
  thereIsNoObjectOfSelectedType: 'There is no object of selected type',
  restrict: 'Restrict',
  restrictAccess: 'Restrict access',
  allCompanies: 'All Companies',
  addCompany: 'Add Company',
  addHours: 'Add hours',
  addNumber: 'Add number',
  addDuration: 'Add duration',
  closureHistory: 'Closure History',
  addClosureDate: 'Add closure date',
  addSiteAnnouncement: 'Add site announcement',
  locationClosure: 'Location closure',
  ifDeliveryHasTheFollowingTags: 'If delivery has the following tags',
  ifEntityHasTheFollowingTags: (entityName: string) =>
    `If ${entityName} has the following tags`,
  thenDoAction: 'Then do action',
  toUserTeamRoleOrCompany: 'To user, team, role or company',
  addRule: 'Add rule',
  tag: 'Tag',
  shouldDeleteRule: 'Should delete rule',
  searchForOrSelectTag: 'Search for or select tag',
  addTag: 'Add tag',
  addUserTeamCompany: 'Add User, Team, Company',
  tagCreation: 'Tag Creation',
  deliveryStatus: 'Delivery status',
  sendNotification: 'Send notification',
  notificationRules: 'Notification rules',
  mention: 'Mention',
  gotToDirectory: 'Go to directory',
  goToProjectHome: 'Go to Project Home',

  daily: 'Daily',
  monSat: 'Mon-Sat',
  d_daysShort: 'd',
  yesterday: 'Yesterday',
  today: 'Today',
  tomorrow: 'Tomorrow',

  loggingIn: 'Logging in',
  loggingOut: 'Logging out',
  loginFailed: 'Login failed',
  login: 'Login',
  logInWith: 'Log in with',
  logo: 'Logo',
  procoreLogin: 'Procore Login',
  changePassword: 'Change Password',
  forgotPassword: 'Forgot password?',
  reset: 'Reset',
  resetPassword: 'Reset Password',
  logOut: 'Logout',

  hideAll: 'Hide all',
  hideGroup: 'Hide group',
  hideObject: 'Hide object',

  showAll: 'Show all',
  showGroup: 'Show group',
  showObject: 'Show object',

  contactHelpAtMail: (email: string) => 'Contact help at ' + email,
  contactHelp: 'Contact Help',

  userNotFound: 'User not found',
  noUserMatchingYourSearch: 'No user matching your search',
  allUsersAlreadyAssigned: 'All users already assigned',
  noTagMatchingYourSearch: 'No tag matching your search',

  emailCannotBeEmpty: 'Email cannot be empty',
  enterYourEmailBelowAndStruxHubWillSendAResetLink: `Enter your email below and ${brandName} will send a reset link`,
  pleaseOpenTheLinkFromTheLetterAgain:
    'Please open the link from the letter again',
  passwordResetLinkIsSentToMailbox: 'Password reset link is sent to mailbox',
  invalidEmailError: 'The email address is badly formatted.',
  userNotFoundError:
    'There is no user record corresponding to this identifier. The user may have been deleted.',
  enterANewPasswordBelow: 'Enter a new password below',
  setYourPassword: 'Set your password',
  tooManyUnsuccessfulLoginAttempts: 'Too many unsuccessful login attempts.',
  passwordErrors: {
    weakPassword:
      'Passwords must be at least 8 characters, Upper and lower case' +
      ', a number, and a special character (!@#$%&)',
    doNotMatch: 'Passwords do not match',
    empty: 'Password cannot be empty',
    invalid: 'The password is invalid or the user does not have a password',
  },

  chat: 'Chat',
  activities: 'Activities',
  deliveries: 'Deliveries',
  closures: 'Closures',
  sensors: 'Sensors',
  logistics: 'Logistics',
  logisticObject: 'Logistic Object',
  site: 'Site',
  siteNews: 'Site news',
  home: 'Home',
  siteLogistics: 'Site Logistics',
  materials: 'Materials',
  photos: 'Photos',
  analytics: 'Analytics',
  analyticsSetting: 'Analytics setting',
  showInReports: 'Show in Reports',
  dashboards: 'Dashboards',
  reports: 'Reports',
  filedToGenerateReport: 'Failed to generate report',
  objectProperties: 'Object properties',
  notifications: 'Notifications',
  userSettings: 'User Settings',
  projectSettings: 'Project Settings',
  settings: 'Settings',
  messages: 'Messages',

  signIn: 'Sign In',
  signUp: 'Sign Up',
  signUpTo: 'Sign up to',
  signedInAs: 'Signed in as',
  account: 'Account',
  profile: 'Profile',
  editProfile: 'Edit profile',
  notificationPreferences: 'Notification preferences',
  administration: 'Administration',
  projectDetails: 'Project details',
  integrations: 'Integrations',
  userDirectory: 'User directory',
  team: 'Team',
  teams: 'Teams',
  defaultTeams: 'Default teams',
  presentations: 'Presentations',
  tracker: 'Tracker',
  uploadSchedule: 'Upload Schedule',
  filterBy: 'Filter by',
  filter: 'Filter',
  filters: 'Filters',
  createNewFilter: 'Create New Filter',
  addScheduleData: 'Add schedule data',
  editScheduleData: 'Edit schedule data',
  struxhubObject: `${brandName} object`,
  selectObject: 'Select object',
  selectWorkForm: 'Select work form',
  hierarchies: 'Hierarchies',
  dataToSchedule: 'Data to Schedule',
  sitemaps: 'Sitemaps',
  searchUserOrGroup: 'Search user or group',
  tool: 'Tool',
  workflow: 'Workflow',
  workflows: 'Workflows',
  sitemapSetup: 'Sitemap setup',
  templateUpdating: 'Template Updating',

  publishTo: 'Publish to',
  geoReferencing: 'Geo Referencing',
  tags: 'Tags',
  permittedCompanies: 'Permitted companies',
  associatedForms: 'Associated Forms',
  associatedAnnouncements: 'Associated Announcements',
  hoursOfOperation: 'Hours of operation',
  dateClosures: 'Date closures',
  maxBookingDuration: 'Max booking duration',
  maxOverlappingBookings: 'Max overlapping bookings',
  object: 'Object',
  objects: 'Objects',
  objectName: 'Object Name',
  objectNestedUnder: 'Object Nested Under',
  iconColor: 'Icon Color',
  image: 'Image',
  groupBy: 'Group by',
  basemap: 'Basemap',
  createNewView: 'Create New View',
  createNewTag: 'Create New Tag',
  viewBasemap: 'View basemap',
  viewBookings: 'View bookings',
  thereIsNoInstanceInTheSelectedCategory:
    'There is no instance in the selected category',

  name: 'Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  phoneNumber: 'Phone',
  globalRole: 'Global Role',
  employeeIDNumber: 'Employee ID Number',
  company: 'Company',
  companyDirectory: 'Company directory',
  common: 'Common',
  myCompany: 'My company',
  companyType: 'Company Type',
  companyName: 'Company Name',
  user: 'User',
  users: 'Users',
  userTags: 'User tags',
  allUsers: 'All Users',
  role: 'Role',
  projectRole: 'Project Role',
  projectRoles: 'Project Roles',
  projectTrades: 'Project Trades',
  address: 'Address',
  email_noun: 'Email',
  phone: 'Phone',
  phoneVerification: 'Phone verification',
  call_verb: 'Call',
  email_verb: 'Email',
  sendDirectMessageInStruxHub: `Send direct message in ${brandName}`,
  password: 'Password',
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  confirmNewPassword: 'Confirm New Password',
  group: 'Group',
  reportsTo: 'Reports To',
  reportOf: (type: string) => `${type} report`,
  createReport: 'Create Report',
  deliveryReport: 'Delivery Report',
  formReport: 'Form Report',
  materialReport: 'Material Report',
  aReportWillBeGeneratedWithTheAboveFilters:
    'A report will be generated with the above filters',
  aReportWillBeSentToYourEmailShortly:
    'A report will be sent to your email shortly',
  reportDetails: 'Report Details',
  officeField: 'Office/Field',
  accountType: 'Account Type',
  status: 'Status',
  monitoringStatus: 'Monitoring Status',
  castStatus: 'Cast Status',
  levelsToClose: 'Levels to close',
  isDockMaster: 'Is Dock Master',
  isInspector: 'Is Inspector',
  unknownUser: 'Unknown User',
  unknownDeletedObject: 'Unknown (Deleted) Object',
  inviteStatus: 'Invite Status',
  inviteStatusValues: {
    Not_Invited: 'Not Invited',
    Pending: 'Pending',
    Accepted: 'Accepted',
    // service
    Not_Added: 'Not Added',
    Added: 'Added',
    Requested: 'Requested',
    Not_Requested: 'Not Requested',
  },
  accountPosition: 'Account Position',
  accountPositions: {
    office: 'Office',
    field: 'Field',
    management: 'Management',
  },
  projectAccessTypeValues: {
    admin: 'Admin',
    member: 'Member',
    owner: 'Owner',
  },
  modalInfoSections: {
    personalInformation: 'Personal information',
    companyInformation: 'Company information',
    projectInformation: 'Project information',
    additionalInformation: 'Additional information',
    workflowPermissions: 'Workflow permissions',
    companyContactInfo: 'Company Contact Info',
    legalInfo: 'Legal info',
    companyAddress: 'Company address',
    users: 'Users',
    qrCodeAccessAndBadges: 'QR Code access and badges',
    globalInfo: `Global info (${brandShortName} Level)`,
  },
  projectWorkflows: {
    deliveryBooking: 'Delivery Booking',
    deliveryInspection: 'Delivery Inspection',
    scheduleComment: 'Schedule Comment',
    formsMaster: 'Forms Master',
    scanMaster: 'Scan Master',
    viewSchedule: 'View schedule',
    scheduleUpdating: 'Update schedule',
    scheduleUploading: 'Upload schedule',
    scheduleNotes: 'Notes on schedule',
  },
  permissions: {
    none: 'None',
    readOnly: 'Read-only',
    standard: 'Standard',
    master: 'Master',
  },
  userProfileRoleNames: {
    admin: 'Site Admin',
    owner: 'Owner',
    docMaster: 'Doc Master',
    inspector: 'Inspector',
    presentationUser: 'Presentation User',
    formsMaster: 'Forms Master',
    scheduleViewing: 'View schedule',
    scheduleUpdating: 'Update schedule',
    scheduleUploading: 'Upload schedule',
    scheduleNotes: 'Notes on schedule',
  },
  companyTypes: {
    GeneralContractorPrimary: 'General Contractor (Primary)',
    GeneralContractorSecondary: 'General Contractor (Secondary)',
    Owner: 'Owner',
    SubContractor: 'Subcontractor',
    TieredSubContractor: 'Tiered Subcontractor',
  },
  saveProfile: 'Save profile',
  notificationSettings: 'Notification Settings',
  announcementNotifications: 'Announcement notifications',
  deliveryNotifications: 'Delivery notifications',
  scheduleNotifications: 'Schedule Notifications',
  trackerNotifications: 'Tracker Notifications',
  logisticsNotifications: 'Logistics Notifications',
  formsNotifications: 'Forms Notifications',
  formNotifications: 'Form notifications',
  scannerNotifications: 'Scanner notifications',
  iWouldLikeToReceiveDeliveryTickets:
    'I would like to receive scheduled Delivery tickets',
  iWouldLikeReceiveMy: 'I would like to receive my',
  inApp: 'In-App',
  sms: 'SMS',
  bySms: 'By SMS',
  byEmail: 'By email',
  successfullyUpdated: 'Successfully updated',
  wrongPhoneNumberFormat: 'Wrong phone number format',
  userProfileDescriptions: {
    name: `Your name will be used when you communicate with other team members in ${brandName}.`,
    projectRoles:
      'Your role in project is used to ensure that the notifications and the workflows you are involved in are pertinent. ' +
      'Further customization is possible in Preferences in Settings.',
    notifications:
      `${brandName} apps will send you notifications for work that you are a team member of. ` +
      'These notifications appear In-App next to the bell icon on the top right of the page, ' +
      'if you have entered your phone number in your profile through SMS messages sent to your phone, ' +
      'and some summaries of notifications may be sent to your email address. ' +
      'You can de-select any of these options below to no longer receive notifications by that method.',
  },
  whitelistedDomains: 'Whitelisted Domains',

  projectAdminSummary: 'Project Admin Summary',
  lastUpdated: 'Last Updated',
  dateByName: (date: string, name: string) => date + ' by ' + name,
  uploadProjectSchedule: 'Upload Project Schedule',
  fileUploaded: 'File Uploaded: ',
  totalActivities: 'Total Activities',
  totalUsers: 'Total Users',
  uploadProcurementPlan: 'Upload Procurement Plan',
  bpAssignedToCompanies: (amount: number, totalAmount: number) =>
    amount + ' of ' + totalAmount + ' bid packages assigned to companies.',
  downloadCurrentValuesAsCsv: (toLink: (aText: string) => any) => [
    toLink('Download'),
    ' current values as a csv file.',
  ],
  uploadTeamList: 'Upload Team List',
  currentTeamMembers: (amount: number) => amount + ' current team members.',
  emptyTagTypes: (tags: TagType[]) =>
    `Some of the required Type Tags (${tags
      .map(tag => getBandTitleByTagType(tag))
      .toString()}) are empty for certain companies. Default values will be set for them.`,
  adminSummaryDescriptions: {
    projectDetails:
      'Define the name and location or your project so that you easily find it in your Project selector',
    projectSchedule: `Upload your updated Master Schedule from P6 as frequently as you want. Don’t worry — you won’t lose any data in ${brandName}.`,
    procurementPlan:
      'Upload your updated Procurement plan as you bring on more subcontractors to the Project ' +
      'and refine your expected values for each bid package.',
    teamList:
      'Upload your Project Team list as new team members join the Project. ' +
      'Including optional information such as internal Department/Team affiliation facilitates ' +
      'the resource assignments to Activities.',
  },

  project: 'Project',
  notificationsRulesSetupDescriptions: {
    helperText1: (entityType: string) =>
      'A rule will send notifications to a set of users for all future ' +
      entityType +
      ' with a set of designated tags',
    helperText2:
      'Tags can be used alone or combined with boolean operators. For example:',
  },

  selectProject: 'Select Project',
  selectBookingTime: 'Select booking time',
  newProject: 'New Project',
  projectName: 'Project Name',
  projectCode: 'Project Code',
  projectLocation: 'Project Location',
  projectLogo: 'Project Logo',
  streetAddress: 'Street Address',
  city: 'City',
  state_region: 'State',
  zipCode: 'Zip Code',
  country: 'Country',
  projectTimeSettingsAndCalendar: 'Project time & calendar settings',
  QRCodes: 'QR codes',
  scanStations: 'Scan stations',
  projectAdmins: 'Project Admins',
  setTheTimeZoneForThisProject: 'Set the time zone for this project',
  projectTimeZone: 'Project time zone',
  dateFormat: 'Date format',
  maturixProjectName: 'Maturix Project name',
  maturixTemperatureUnit: 'Maturix Temperature unit',
  maturixStrengthUnit: 'Maturix Strength unit',
  maturixStations: 'Maturix Stations',
  undefinedSection: 'Undefined section',
  setTheDateTimeFormatForThisProject:
    'Set the date time format for this project',
  localTimezone: 'Local timezone',
  us: 'US',
  international: 'International',
  defaultWorkingDays: 'Default working days',
  customProjectClosures: 'Custom Project Closures',
  holidaysAndSiteClosures: 'Holidays & site closures',
  addCustomProjectClosure: 'Add Custom Project Closure',
  forTheFuture: 'For the Future',
  projectType: 'Project Type',
  apps: 'Apps',
  admins: 'Admins',
  theListOfProjectsAdministrators: "The list of project's administrators",
  addNew: 'Add new',
  addNewAdmin: 'Add new Admin',
  addNewContact: 'Add new Contact',
  defaultWorkingHours: 'Default working hours',

  struxHubHome: `${brandName} Home`,
  struxHubForms: `${brandName} Forms`,
  bookDemo: 'Book demo',
  app: 'App',
  myAndAppWithEmptySpaces: 'My    App',
  showInNavigation: 'Show in navigation',
  setAsDefaultLandingPage: 'Set as default landing page',
  projectDetailsDescriptions: {
    projectNameError:
      'Name should not be empty and should contain only letters, numbers, spaces, _ , -',
    projectCodeError:
      'Project Code cannot be empty and should not contain any special characters. Max length for Project code is 5 characters',
    changeProjectLocationWarning:
      'WARNING: Your Project Time Zone has been set differently ' +
      'to the time zone at your Project Location',
  },
  integrationsSetupDescriptions: {
    invalidToken: 'Invalid Token',
  },
  countryValues: countries,
  timezoneValues: timezones,
  timezoneWithOffsets: (name: string, offsets: string[]) =>
    `${name} (GMT ${offsets.join('/')})`,

  addCustomProjectClosures: 'Add Custom Project Closures',
  closureName: 'Closure Name',
  closureStartDate: 'Closure Start Date',
  closureEndDate: 'Closure End Date',

  loadingTeamMembers: 'Loading Team Members',
  loadingMonitoringData: 'Loading Monitoring Data',
  xTeamMembersSelected: (x: number) => `${x} Team member${ending(x)} selected`,
  xCompaniesSelected: (x: number) =>
    `${x} Compan${ending(x, 'y', 'ies')} selected`,
  importExportTeamMembers: 'Import/Export team members',
  teamMembersDropdownStates: {
    downloadTeamMembers: 'Download team members .csv',
    uploadTeamMembers: 'Upload team members .csv',
    viewCompanyDirectory: 'View company directory',
    connectToProcore: 'Connect to Procore team directory',
  },
  selectNotInvited: 'Select Not Invited',
  selectStatusRequested: 'Select STATUS Requested',
  setNotInvited: 'Set Not Invited',
  addNewProjectMember: 'Add new project member',
  uploadYourUpdatedProjectMemberListCsvFile:
    'Upload your updated Project Member list csv file.',
  orDragYourFileHere: 'Or drag your file here.',
  uploadYourScheduleFileInP6XerOrExcelXlsxXlsmFormat:
    'Upload your Schedule file in P6 (.xer) or Excel (.xlsx, .xlsm) format.',
  xIsUploaded: (file: string) => `${file} is uploaded`,
  sendInviteLinkToUser: 'Send Invite Link to User',
  addUser: 'Add User',
  editUsers: (x: number) => `Edit User${ending(x)}`,
  userValidationErrors: {
    firstNameRequired: 'First name is required',
    lastNameRequired: 'Last name is required',
    emailRequired: 'Email is required',
    invalidEmail: 'Email is not valid',
    invalidPhone: 'Phone number is not valid',
    emailIsTaken: 'Email is already taken by another project member',
    phoneIsTaken: 'Phone is already taken by another project member',
    memberWithSuchEmailIsAlreadyAdded:
      'A user with such email is already added to the project',
    memberWithSuchPhoneNumberIsAlreadyAdded:
      'A user with such phone number is already added to the project',
    companyRequired: 'Company is required',
  },

  companyValidationErrors: {
    nameRequired: 'Company name is required',
    emailRequired: 'Business email is required',
    invalidEmail: 'Email is not valid',
    invalidPhone: 'Business phone number is not valid',
    emailIsTaken: 'Email is already taken by another company',
    somethingFilledIncorrectly: 'Something is filled incorrectly',
    companyTypeIsRequired: 'Company type is required',
    codeRequired: 'Code is required',
    codeIncorrectLength: 'Code length should be between 3 and 5 symbols',
    codeIsNotUnique:
      'A company with this code already exists, change the value of the code field to make it unique',
  },

  companyLinkingErrors: {
    anErrorOccurredWhileLinking: 'An error occurred while linking',
    anErrorOccurredWhileUnlinking: 'An error occurred while unlinking',
    anErrorOccurredWhileFetchingGlobalStorage:
      'An error occurred while fetching global storage',
  },

  companyMerging: {
    confirmation: (sourceCompaniesText: string, targetCompanyText: string) =>
      `Do you confirm the merging of ${sourceCompaniesText} into ${targetCompanyText}?`,
  },

  somethingWentWrongDuringAPIInteraction:
    'Something went wrong during API interaction',

  confirmDeactivationOfTheFollowingXCompanies: (x: number) =>
    `Confirm deactivation of the following ${x} compan${ending(
      x,
      'y',
      'ies',
    )}?`,
  confirmActivationOfTheFollowingXCompanies: (x: number) =>
    `Confirm activation of the following ${x} compan${ending(x, 'y', 'ies')}?`,
  confirmDeletionOfTheFollowingXAnnouncements: (x: number) =>
    `Confirm deletion of the following ${x} announcement${ending(x)}?`,
  confirmDeletionOfTheFollowingXProjectMembers: (x: number) =>
    `Confirm deletion of the following ${x} project member${ending(x)}?`,
  confirmInvitationOfTheFollowingXProjectMembers: (x: number) =>
    `Confirm invitation of the following ${x} project member${ending(x)}?`,
  confirmSettingAsNotInvitedOfTheFollowingXProjectMembers: (x: number) =>
    `Confirm setting as not invited of the following ${x} project member${ending(
      x,
    )}?`,
  confirmInvitation: 'Confirm Invitation',
  confirmSettingUsersAsNotInvited: 'Confirm setting users as not invited',
  confirmDeletion: 'Confirm deletion',
  confirmActivation: 'Confirm activation',
  confirmDeactivation: 'Confirm Deactivation',
  confirmDeletionOfTheFollowingXPlannedManpower: (x: number) =>
    `Confirm deletion of the following ${x} planned manpower?`,
  confirmDeletionOfTheFollowingXBidPackages: (x: number) =>
    `Confirm deletion of the following ${x} bid package${ending(x)}?`,
  confirmDeletionOfActivities: (x: number) =>
    `Are you sure you want to delete ${x} activit${ending(x, 'y', 'ies')}?`,

  selectProcoreProject: 'Select Procore project',
  selectProcoreCompany: 'Select Procore company',
  noUnit: 'No unit',
  noCompanies: 'No companies',
  noDirectoryAccess: 'No directory access',
  xUsers,
  xUsersShowing: (x: number) => `${x} user${ending(x)} showing`,
  xTeams: (x: number) => `${x} team${ending(x)}`,
  addXUsers: (x: number) => {
    if (!x) {
      return 'Add'
    }
    // 'Add 1 user' or 'Add x users'
    return `Add ${x} user${ending(x)}`
  },
  xFiles,

  multiLevelScreen: 'Multi-Level Screen',
  levelsViewsWithZoneSummary: 'Levels views with zone summary',
  levelsViewsWithCompanySummary: 'Levels views with company summary',
  gantt: 'Gantt',
  presentationMode: 'Presentation mode',
  play: 'Play',
  stop: 'Stop',
  turnOn: 'Turn On',
  presentationScreens: 'Presentation Screens',
  xMinutes: (x: number) => `${x} minute${ending(x)}`,
  order_noun: 'Order',
  order_number: (x: number) => {
    switch (x) {
      case 1:
        return '1st'
      case 2:
        return '2nd'
      case 3:
        return '3rd'
      default:
        return `${x}th`
    }
  },
  display: 'Display',
  presentationModeDescriptions: {
    logisticsEntity: 'Open Announcements',
    multiLevelScreen:
      'Displays activity and manpower performance of actuals vs. planned ' +
      'for all the levels of the building. Scrolls to display all levels',
    levelsViewsWithZoneSummary:
      'Cycles through each level of the building displaying ' +
      'the aggregate activity and manpower performance for each zone. ' +
      'Scrolls to display data for each zone.',
    levelsViewsWithCompanySummary:
      'Cycles through each level of the building displaying ' +
      'the aggregate company performance. Scrolls to display data for each company.',
    gantt:
      'Displays the project gantt view for the current look-ahead. ' +
      'Scrolls to display all activities. ',
    deliveries: 'Displays the weekly delivery calendar for the current week.',
    logistics: 'Displays the logistic map.',
    confirmText:
      'Presentation mode will log you out and set this device to playback. To ' +
      'exit presentation mode go to Settings. You will need re-log in once you ' +
      'exit presentation mode.',
    header:
      'Turning on Presentation Mode will log you out, and log this device on ' +
      'in a presentation only mode.',
    periodOfInactivity:
      'Presentation will automatically run after a period of inactivity of',
    stop:
      'Stopping presentation mode will exit you back to a log in screen ' +
      'where you can log into your projects.',
  },

  include: 'Include',
  formConfiguration: 'Form configuration',
  saveConfiguration: 'Save configuration',
  preTaskChecklist: 'Pre-task Checklist',
  requireDailyInspections: 'Require daily inspections',
  inspectionFrequency: 'Inspection frequency',
  deadLineForRecordingAnInspection: 'Deadline for recording an inspection',
  checklist: 'Checklist',
  instructions: 'Instructions',
  xOptions: (x: number) => `${x} option${ending(x)}`,
  NA: 'N/A',
  remove: 'Remove',
  typeOfList: 'Type of List',
  addAdditionalItem: 'Add additional item',
  enterInstruction: 'Enter Instruction',
  enterTheXDigitCodeSentTo: (x: number) => `Enter the ${x} digit code sent to`,
  areYouSureWantToDeleteInstructionItem:
    'Are you sure want to remove instruction item?',
  instructionListTypes: {
    enumerated: 'Enumerated',
    bulleted: 'Bulleted',
    none: 'None',
  },
  fieldStates: {
    mandatory: 'Mandatory',
    optional: 'Optional',
    hidden: 'Hidden',
  },

  revertingProjectData: 'Reverting Project Data',
  loadingUpdatingProject: 'Loading/Updating Project',
  downloadProjectDataCsv: 'Download project data .csv',
  exploreYourScheduleInStruxHub: `Explore your schedule in ${brandName}`,
  scheduleIsNotUploaded: 'Schedule is not uploaded',
  gettingXerProjects: 'Getting xer projects',
  gettingExcelSheets: 'Getting excel sheets',
  parsingTheScheduleFileStarted: 'Parsing the Schedule file started',
  readingScheduleFile: 'Reading schedule file',
  extractingScheduleData: 'Extracting schedule data',
  xEntriesInTheFile: (x: number) =>
    `${x} entr${ending(x, 'y', 'ies')} in the file`,
  savingScheduleDataCollections: 'Saving schedule data collections',
  xOfYEntriesSaved: (x: number, y: number) =>
    `${x} of ${y} entr${ending(y, 'y', 'ies')} saved`,
  recalculatingStatusUpdates: 'Recalculating status updates',
  savingSchedule: 'Saving schedule',
  uploadingScheduleFailedAt: 'Uploading schedule failed at',
  uploadingScheduleDone: 'Uploading schedule done',
  seeGanttInStruxHub: `See Gantt in ${brandName}`,
  noActivitiesFound: 'No activities found',
  continueToNextStep: 'Continue to next step',
  thereAreNoProjectsInProvidedFile: 'There are no projects in provided file',
  thereAreNoInstancesToDisplay: (instancesName: string = 'Instances') =>
    `There are no ${instancesName.toLowerCase()} to display`,
  scheduleUpdateSummary: 'Schedule Update Summary',
  current: 'Current',
  upload_noun: 'Upload',
  fileName: 'File name',
  dateUploaded: 'Date Uploaded',
  uploadScheduleDescriptions: {
    warning: (delta: number) =>
      `The ${delta} ${activities(delta)} ` +
      `that ${ending(delta, 'is', 'are')} not present in the Updated schedule` +
      `will be removed from view. All content associated with ` +
      `th${ending(delta, 'is', 'ese')} ${delta} ` +
      `${activities(delta)} will be deleted ` +
      `from ${brandName} as well.`,
    chooseProject:
      'The current P6 xer file contains data from multiple Projects. ' +
      'Please select the Project to import. The data in this Project should ' +
      'correspond to those data contained in other sources ' +
      '(e.g. Procurement Plan, Team Member list).',
    chooseSheet:
      'The current files contains multiple sheets. ' +
      'Please select the sheet to import.',
    mergeData: 'Update current schedule.',
    mapFields: 'Please select which field will be used for each column',
  },
  activityId: 'Activity ID',
  activityUniqueId: 'Activity Unique ID',
  activityName: 'Activity Name',
  activityCode: 'Activity Code',
  activityStatus: 'Activity Status',
  activityPercentComplete: 'Activity Percent Complete',
  plannedActivityStart: 'Planned Activity Start',
  plannedActivityFinish: 'Planned Activity Finish',
  actualActivityStart: 'Actual Activity Start',
  actualActivityFinish: 'Actual Activity Finish',
  resourceId: 'Resource ID',
  resourceName: 'Resource Name',
  trade: 'Trade',
  assignee: 'Assignee',
  primarySafetyHazard: 'Primary Safety Hazard',
  secondarySafetyHazard: 'Secondary Safety Hazard',
  categoryOfVariance: 'Category Of Variance',
  categoryOfVarianceOpened: 'Category Of Variance Opened',
  activityCodeTypes: 'Activity Code Types',
  resources: 'Resources',
  uploadAnother: 'Upload Another',
  next: 'Next',
  saveAndViewUpdatedGantt: 'Save and View Updated Gantt',
  lastUpload: 'Last upload',
  companies: 'Companies',
  targetCompany: 'Target Company',
  activitiesWithTheFollowingActivityCodesAreExcluded:
    'Activities with the following activity codes are excluded',
  activityCodesSelected: 'Activity Codes selected',
  scheduleRestoringConfirmation: 'Schedule Restoring Confirmation',
  doYouConfirmRestoringFileFromDate: (file: string, wrappedDate) => [
    `Do you confirm restoring ${file} from `,
    wrappedDate,
    '?',
  ],
  doYouWantToSaveChanges: 'Do you want to save changes?',
  doYouAlreadyHaveAnAccount: 'Do you already have an account?',
  uploadHistory: 'Upload History',
  restore: 'Restore',
  chooseProject: 'Choose Project',
  chooseSheet: 'Choose Sheet',
  mapFields: 'Map Fields',
  xActivities: (x: number) => `${x} ${activities(x)}`,
  xHours,
  xLocations: (x: number) => `${x} Location${ending(x)}`,
  xColumns: (x: number) => `${x} Column${ending(x)}`,
  cancelUpload: 'Cancel Upload',
  selectProjectMembersLinkedToX: (x: string, isPlural: boolean) =>
    `Select Project Member${isPlural ? 's' : ''} linked to ${x}`,
  selectActivityCodesLinkedToX: (x: string, isPlural: boolean) =>
    `Select Activity Code${isPlural ? 's' : ''} linked to ${x}`,
  selectionOf1ProjectMemberIsPermitted: `Selection of 1 Project Member is permitted.`,
  selectionOf1ActivityCodeIsPermitted: `Selection of 1 Activity Code is permitted.`,
  multipleSelectionsArePermitted: `Multiple selections are permitted.`,
  excludeSectionsOfTheScheduleOptional:
    'Exclude sections of the schedule (optional)',
  activityFilterDialogDescriptions: {
    hiddenCodes:
      'If you wish to hide certain sections of your schedule, ' +
      'check the activity codes that correspond to the activities that you want to hide.',
  },
  only: 'Only',
  noCompany: 'No company',
  noMessage: 'No message',
  submit_verb: 'Submit',
  showAllXActivities: (x: number) => `Show All ${x} ${activities(x)}`,
  showXOfYActivities: (x: number, y: number) =>
    `Show ${x} of ${y} ${activities(x)}`,
  verification: 'Verification',
  xActivitiesAdded: (x: number, wrappedX) => [
    wrappedX,
    ` ${activities(x)} added.`,
  ],
  seeDetails: 'See details',
  xTypeActivityCodesAdded: (x: number, wrappedX, type: string) => [
    wrappedX,
    `${type} Activity Code${ending(x)} added.`,
  ],

  activityFilters: 'Activity filters',
  scheduleFilters: 'Schedule filters',
  customFilter: 'Custom filter',
  filterNameCannotBeEmpty: 'Filter name cannot be empty',
  suchFilterNameIsAlreadyTaken: 'Such filter name is already taken',
  struxHubData: `${brandName} Data`,
  scheduleData: 'Schedule Data',
  members: 'Members',
  projectMembers: 'Project Members',
  activityCodes: 'Activity Codes',
  projectMembersSelected: 'Project Members selected',
  renameFilterOptional: 'Rename Filter (optional)',
  filterName: 'Filter name',
  xCompaniesIdentified: (x: number) =>
    `${x} Compan${ending(x, 'y', 'ies')} identified`,
  xCompaniesShowing: (x: number) =>
    `${x} Compan${ending(x, 'y', 'ies')} showing`,
  xCompanies: (x: number) => `${x} compan${ending(x, 'y', 'ies')}`,
  activityCodeSelected: 'Activity Code selected',
  responsibleUser: 'Responsible User',
  responsibilityCode: 'Responsibility Code',
  responsibilityDescription: 'Responsibility Description',
  contractN: 'Contract #',
  plannedValueS: 'Planned Value ($)',
  uploadAnUpdatedXlsxFile: 'Upload an updated xlsx file',
  downloadCurrentListAsXlsxFile: 'Download current list as xlsx file',
  addNewEntry: 'Add new entry',
  addNewUser: 'Add new user',
  previous: 'Previous',
  activityFiltersDescriptions: {
    confirmDeletionOfX: (x: string) =>
      `Are you sure you want to delete ${x} filter?`,
    title:
      `${brandName} transforms your activity codes and P6 data into convenient ` +
      'filters for your teams to easily find their activities on web and ' +
      'mobile. You can configure these filters below and update them at any time.',
    selectProjectMembers:
      'Select the Project Member(s) you want to include in this filter. ' +
      'Multiple selections are permitted.',
    selectActivityCodes:
      'Select the Activity Code(s) you want to include in this filter. ' +
      'Multiple selections are permitted.',
    company:
      'This filter allows users to select ' +
      'the Companies that are responsible for Activities in your Project.',
    level:
      'This filter allows users to select ' +
      'the elevation levels of your building.',
    zone:
      'This filter allows users to select ' +
      'the different zones of each level of the building.',
    activityCode:
      'This filter allows users to select ' +
      'additional codes of Activities that you want to track.',
    projectMember:
      'This filter allows users to select ' +
      'project members that you want to track.',
    associateCompanies:
      'Please indicate how you are associating Companies ' +
      'to your Activities in the schedule.',
    resourceCompanies:
      'Company names are in the Resource field in the .xer file. ' +
      'Note: this is the default value if you have a resource-loaded schedule.',
    bpCompanies:
      'Company names are in a separate xlsx file and ' +
      'associated with Activities via an Activity code.',
    bpStep1:
      'Select the Activity code type in your .xer file ' +
      'that is associated with the responsible Companies.',
    bpStep2:
      'Upload an excel file containing at least the following 2 column headers ' +
      'Responsibility Code and Company.',
  },

  trackingHierarchy: 'Tracking Hierarchy',
  defaultHierarchies: 'Default Hierarchies',
  defineBandsEnPermissionsForYourTrackingHierarchy:
    'Define bands & permissions for your Tracking Hierarchy',
  setupDefaultHierarchiesForDifferentViews:
    'Setup default hierarchies for different views',
  selectBands: 'Select bands',
  selectControlBand: 'Select Control Band',
  band: 'Band',
  addBand: 'Add Band',
  activityUpdatesOn: 'Activity updates on',
  activityUpdatesOff: 'Activity updates off',
  view_noun: 'View',
  wbs: 'WBS', // work breakdown structure
  webGantt: 'Web Gantt',
  webSitemap: 'Web Sitemap',
  mobile: 'Mobile',
  tablet: 'Tablet',
  hierarchyPreview: 'Hierarchy Preview',
  unassigned: 'Unassigned',
  photo: 'Photo',
  completed: 'Completed',
  startEarly: 'Start Early',
  PComplete: '% Complete',
  unassignedActivity: 'Unassigned activity',
  trackingHierarchyDescriptions: {
    title:
      'Setup your Tracking Hierarchy below to start tracking your schedule ' +
      'by your Field Teams, by your Subcontractors, or by both.',
  },
  editCompany: 'Edit Company',
  addDataToSchedule: 'Add data to schedule',
  plannedCompanyManpower: 'Planned Company Manpower',
  dataToScheduleDescriptions: {
    title:
      `With ${brandName}, you can quickly add data to ` +
      'your schedule by importing data in excel files',
  },

  formField: 'Form field',
  showField_question: 'Show field?',
  mandatory_question: 'Mandatory?',
  fieldShown: 'Field shown',
  mandatory: 'Mandatory',
  addNewListElement: 'Add new list element',
  formPreview: 'Form preview',

  setThresholdForDDays: 'Set Threshold for ∆ Days',
  setThresholdForDManpower: 'Set Threshold for ∆ Manpower',
  onTrack: 'On-Track',
  watch: 'Watch',
  behind: 'Behind',
  dDays: '∆ Days',
  days: 'Days',
  percent: 'Percent',
  number: 'Number',
  daysBehind: 'Days Behind',
  daysAhead: 'Days Ahead',
  XBehind: '% Behind',
  XAhead: '% Ahead',
  under: 'Under',
  over: 'Over',
  XUnder: '% Under',
  XOver: '% Over',
  dManpower: '∆ Manpower',
  sitemapsDescriptions: {
    title:
      `With ${brandName}, you can easily link your Schedule with your sitemaps. ` +
      'Below you can configure the threshold values for the ∆ Days and ' +
      '∆ Manpower metrics that are used to visualize progress on the Schedule ' +
      'and current Manpower, respectively.',
    configure: 'Configure Status Thresholds for Schedule & Manpower',
  },

  inbox: 'Inbox',
  unread_2form: 'Unread',
  read_2form: 'Read',
  archived: 'Archived',
  requested: 'Requested',
  changed: 'Changed',
  scheduled: 'Scheduled',
  onSite: 'On Site',
  denied: 'Denied',
  passedInspection: 'Passed Inspection',
  failedInspection: 'Failed Inspection',
  done_plural: 'Done',
  flags: 'Flags',
  formSubmitted: 'Form submitted',
  formAccepted: 'Form accepted',
  formFinished: 'Form finished',
  formClosed: 'Form closed',
  formChanged: 'Form changed',
  formDenied: 'Form denied',
  formReadyToInspect: 'Form ready to inspect',
  formActivated: 'Form activated',
  formReviewed: 'Form reviewed',
  formOnSite: 'Form on-site',
  formFailed: 'Form failed',
  formPassed: 'Form passed',
  formBicInspected: 'Form BIC Inspected',
  formDeleted: 'Form deleted',
  formFollowed: 'Form followed',
  rfis: 'RFIs',
  scheduleComments: 'Schedule comments',
  onTime: 'On-Time',
  late: 'Late',
  performance: 'Performance',
  parameters: 'Parameters',
  onTimeWindowHr: 'On-time Window (hr)',
  showLess: 'Show less',
  showMore: 'Show more',
  all_items: 'All',
  none: 'None',
  unArchive: 'Un-Archive',
  archive: 'Archive',
  read: 'Read',
  unread: 'Unread',
  wwpColoring: 'WWP Coloring',
  useCSIMaterialCategories: 'Use CSI Material Categories', // Construction Specifications Institute
  noNotificationsPassedFilters: 'No Notifications passed filters',
  replyToActivityFlag: 'Reply to Activity Flag',
  activityFlag: 'Activity Flag',
  replyToActivityRfi: 'Reply to Activity RFI',
  activityRfi: 'Activity RFI',
  replyToScheduleComment: 'Reply to Schedule comment',
  replyToCategoryOfVariance: 'Reply to Category Of Variance',
  replyToSafetyHazard: 'Reply to Safety Hazard',
  scheduleComment: 'Schedule comment',
  activityUpdate: 'Activity Update',
  scheduleUpdate: 'Schedule Update',
  safetyHazard: 'Safety Hazard',
  safetyHazardOpened: 'Safety Hazard Opened',
  bulkEdit: 'Bulk Edit',
  bulkUpdate: 'Bulk Update',
  replyToActivityUpdate: 'Reply to Activity Update',
  activityFollowed: 'Activity Followed',
  deliveryFollowed: 'Delivery Followed',
  replyToScheduleUpdate: 'Reply to Schedule Update',
  replyToBulkUpdate: 'Reply to Bulk Update',
  deliveryRequested: 'Delivery requested',
  deliveryRequestedByLocationClosure: (id: string) =>
    `Delivery (${id}) has been affected by a Location Closure and must be re-scheduled`,
  deliveryScheduled: 'Delivery scheduled',
  deliveryOnSite: 'Delivery on site',
  deliveryPassedInspection: 'Delivery passed inspection',
  deliveryFailedInspection: 'Delivery failed inspection',
  deliveryInspected: 'Delivery Inspected',
  deliveryDone: 'Delivery done',
  deliveryIncompletelyDone: 'Delivery incompletely done',
  deliveryUpdated: 'Delivery updated',
  deliveryDeleted: 'Delivery deleted',
  deliveryDeletedBy: (id: string, user: string, company: string) =>
    `Delivery (${id}) has been deleted by ${user} at ${company}`,
  deliveryDenied: 'Delivery denied',
  deliveryCanceled: 'Delivery Canceled',
  deliveryOnHold: 'Delivery on hold',
  deliveryPaused: 'Delivery paused',
  updateMaterials: 'Update materials',
  materialsLoading: 'Materials are loading',
  xMaterialsShowing: (count: number) => `${count} Materials are showing`,
  planned: 'Planned',
  actual: 'Actual',
  scannerDeactivated: 'Scanner deactivated',
  scheduleUpdated: 'Schedule updated',
  passed: 'Passed',
  failed: 'Failed',
  scheduling: 'Scheduling',
  schedule: 'Schedule',
  schedule_noun: 'Schedule',
  pass_verb: 'Pass',
  deny_verb: 'Deny',
  change_verb: 'Change',
  accept: 'Accept',
  fail: 'Fail',
  incompletelyDone: 'Incompletely done',
  deleted: 'Deleted',
  deactivated: 'Deactivated',
  activate: 'Activate',
  deactivate: 'Deactivate',
  canceled: 'Canceled',
  siteNotReady: 'Site not ready',
  materialProblem: 'Material problem',
  laborIssue: 'Labor issue',
  qualityIssue: 'Quality issue',
  clarificationNeeded: 'Clarification needed',
  incorrectSpecification: 'Incorrect specification',
  materialQuestion: 'Material question',
  superintendentNeeded: 'Superintendent needed',
  plusXMore: (x: number) => `+ ${x} more`,
  submittalInReview: 'Submittal in review',
  submittalComplete: 'Submittal complete',
  inFabrication: 'In fabrication',
  fabricationComplete: 'Fabrication complete',
  inStorage: 'In storage',
  installed: 'Installed',
  delivery: 'Delivery',
  newRequest: 'New request',
  deleteDelivery: 'Delete Delivery',
  yesDeleteDelivery: 'Yes, delete delivery',
  cancelDelivery: 'Cancel Delivery',
  yesCancelDelivery: 'Yes, cancel delivery',
  changeDate: 'Change Date',
  yesMoveTheDate: 'Yes, move the date',
  deliveryRequestIsNotValid: 'Delivery Request is not Valid',
  datePassedDeadline: 'Date Passed Deadline',
  requestAnyway: 'Request anyway',
  requestAccessTo: 'Request access to',
  thisRequestHasPassedTheDeadline: 'This Request has passed the Deadline',
  noDeadline: 'No Deadline',
  twentyFourHours: '24 hours',
  fortyEightHours: '48 hours',
  seventyTwoHours: '72 hours',
  fourDays: '4 days',
  fiveDays: '5 days',
  oneWeek: '1 week',
  twoWeeks: '2 weeks',
  dateAndTime: 'Date & Time',
  startDate: 'Start Date',
  finishDate: 'Finish Date',
  addFinishDate: 'Add finish date',
  removeFinish: 'Remove finish',
  startTime: 'Start time',
  endTime: 'End time',
  selectTime: 'Select time',
  location: 'Location',
  locationFrom: 'Location From',
  locationTo: 'Location To',
  formType: 'Form Type',
  contact: 'Contact',
  offloadingEquipment: 'Offloading Equipment',
  vehicles: 'Vehicles',
  material: 'Material',
  materialDeliveryLocation: 'Material delivery location',
  materialsCategory: 'Materials category',
  inspectionRequired: 'Inspection Required',
  supplierAndDriver: 'Supplier & Driver',
  anySpecialInstructions: 'Any special instructions?',
  deliverySitemap: 'Delivery Sitemap',
  gate: 'Gate',
  area: 'Area',
  fromToDelivery: 'From-To Delivery',
  workArea: 'Work Area',
  installationZone: 'Installation Zone',
  deliveryVehicleType: 'Delivery Vehicle Type',
  numberOfVehicles: 'Number of Vehicles',
  numberOfVehicleLicensePlate: 'Vehicle Model & Lic.#',
  equipment: 'Equipment',
  sharedResources: 'Shared Resources',
  units: 'Units',
  inspector: 'Inspector',
  vendorEmails: 'Vendor emails',
  driverMobilePhoneNumbers: 'Driver(s) mobile phone number(s)',
  specialInstructions: 'Special Instructions',
  linkedActivity: 'Linked Activity',
  customActionButton: 'Custom Action Button',
  building: 'Building',
  route: 'Route',
  staging: 'Staging',
  interiorPath: 'Internal Path',
  interiorDoor: 'Internal Door',
  bookingDate: 'Booking Date',
  requesterCompany: 'Requester Company',
  duration: 'Duration',
  onsiteContact: 'On-site Contact',
  onsiteContacts: 'On-site Contacts',
  category: 'Category',
  materialNote: 'Material Note',
  unnamedMaterial: 'Unnamed material',
  required: 'Required',
  optional: 'Optional',
  multipleSelection: 'Multiple selection',
  adding: 'Adding',
  findOrAddNewEntry: 'Find or Add new entry',
  findEntry: 'Find entry',
  yes: 'Yes',
  no: 'No',
  unscheduledDelivery: 'Unscheduled Delivery',
  uploadASitemapToShowGatesAndAreas: 'Upload a sitemap to show Gates and Areas',
  uploadAViewToShowGatesAndAreas: 'Upload a view to show Gates and Areas',
  uploadASitemapToShowBuilding: 'Upload a sitemap to show Building',
  buildingAccessMap: 'Building Access Map',
  uploadASitemapToShowGates: 'Upload a sitemap to show Gates',
  gateAccessMap: 'Gate Access Map',
  uploadASitemapToShowEquipment: 'Upload a sitemap to show Equipment',
  equipmentAccessMap: 'Equipment Access Map',
  zoneAccessMap: 'Zone Access Map',
  uploadASitemapToShowAreas: 'Upload a sitemap to show Areas',
  routeAccessMap: 'Route Access Map',
  uploadASitemapToShowRoute: 'Upload a sitemap to show Route',
  deliveryWorkflow: 'Delivery Workflow',
  workflowMenu: 'Workflow Menu',
  addANoteToThisDelivery: 'Add a note to this Delivery',
  send: 'Send',
  deliveryDescriptions: {
    confirmDelete:
      'Deleting this delivery will remove the delivery ' +
      'and all associated notes and data from the project.',
    confirmDelete2: 'Do this only if the Delivery Request was a mistake.',
    confirmCancel: `Canceled deliveries will remain on the calendar but be marked as 'Canceled'.`,
    confirmCancel2: 'Would you like to Cancel this delivery?',
    dateChange: (days: number) =>
      `Requests must be ${days} working ` +
      `day${ending(days)} ` +
      `in Advance from the date and time you are requesting`,
    nonWorkTimeDateChange: (days: number) =>
      `Please move this Delivery. Requests must be ${days} working ` +
      `day${ending(days)} ` +
      `in Advance from the date and time you are requesting and on a work date-time`,
    maximumDeliveriesAreScheduled:
      'Maximum number of deliveries are already scheduled for this location',
  },
  loginInfoPageDescriptions: {
    letsGetLoggedIntoStruxHub: `Lets get logged into ${brandName}`,
    resendMyInvitationLink: 'Resend my invitation link',
    expiredInvitation:
      'Your invitation was sent several days ago, is old and for security reasons, we need to resend you a new link.',
    newInvitationInfo: (hours: number) =>
      `The new link will be good for ${xHours(
        hours,
      )}, and you should get it in your inbox immediately when you hit the Resend button.`,
    ifYouHaveQuestionsOrNeedSupport: 'If you have questions or need support',
    youCanAlwaysReachUsAt: 'You can always reach us at',
    inviteTokenHasInvalidFormat: 'Invite token has invalid format',
    inviteResendingConfirmation:
      'Your invite has been re-sent. Go check your email and follow that link',
    sorryForSecurity: 'Sorry for the run around, but you know... security.',
  },

  assignResponsibilitiesConfirmation: 'Assign Responsibilities Confirmation',
  assignResponsibilities: 'Assign Responsibilities',
  assignToActivities: 'Assign to Activities',
  groupByTeam: 'Group by Team',
  noTeam: '[no team]',
  hashActivities: '# Activities',
  rfi: 'RFI',
  flag: 'Flag',
  reply: 'Reply',
  unspecifiedType: '(Unspecified type)',
  contractsOrRevisions: 'Contracts/Revisions',
  priorWorkNotComplete: 'Prior Work Not Complete',
  informationNotAvailable: 'Information Not Available',
  unclearTaskDescription: 'Unclear Task Description',
  staffNotAvailable: 'Staff Not Available',
  materialsNotAvailable: 'Materials Not Available',
  noInformationAvailableOnThisMonitoring:
    'No information is available on this monitoring',
  conditionsOfSatisfactions: 'Conditions of Satisfactions',
  taskSequenceChange: 'Task Sequence Change',
  incorrectTimeEstimate: 'Incorrect Time Estimate',
  offProjectDemands: 'Off Project Demands',
  unforeseenConditions: 'Unforeseen Conditions',
  weather: 'Weather',
  elevatedWork: 'Elevated Work',
  materialHandling: 'Material Handling',
  groundDistribution: 'Ground Distribution',
  craneOrHeavyLift: 'Crane / Heavy Lift',
  confinedSpace: 'Confined Space',
  mobileEquipment: 'Mobile Equipment',
  deliver: 'Deliver',
  loto: 'LOTO',
  hotWork: 'Hot Work',
  hazardousMaterial: 'Hazardous Material',
  highVoltage: 'High Voltage',
  highDecibels: 'High Decibels',
  lifting: 'Lifting',
  closeQuarters: 'Close Quarters (<6ft)',
  rfiOpened: 'RFI Opened',
  flagOpened: 'Flag Opened',
  scheduleCommentOpened: 'Schedule comment opened',
  addANote: 'Add a note',
  start_noun: 'Start',
  end_noun: 'End',
  forDate: (date: string) => `For ${date}`,
  addANoteToThisStatusUpdate: 'Add a note to this Status Update',
  chooseADeliveryTime: 'Choose a delivery time',
  allZones: 'All Zones',
  allEquipment: 'All Equipment',
  selectDate: 'Select date',
  selectField: 'Select field',
  selectDateAndTime: 'Select date & time',
  jumpToDate: 'Jump to date',
  jumpToMap: 'Jump to map',
  newDelivery: 'New Delivery',
  newForm: 'New Form',
  pending: 'Pending',
  confirmChange: 'Confirm Change',
  okToProceed: 'OK to proceed?',
  proceed: 'Proceed',
  actualStart: 'Actual Start',
  updated: 'Updated',
  approved: 'Approved',
  missing: 'Missing',
  actualFinish: 'Actual Finish',
  xDaysUpdated: (x: number) => `${x} Days Updated!`,
  copyToMissing: 'Copy to Missing',
  copy: 'Copy',
  copiedToClipboard: 'Copied to clipboard',
  copyLinkToDelivery: 'Copy link to delivery',
  checkInUnscheduledDelivery: 'Check-in unscheduled delivery',
  paste: 'Paste',
  estimateMissing: 'Estimate Missing',
  activityDetails: 'Activity Details',
  assigned: 'Assigned',
  followed: 'Followed',
  following: 'Following',
  notFollowing: 'Not following',
  predecessors: 'Predecessors',
  successors: 'Successors',
  viewTeam: 'View team',
  dates: 'Dates',
  plannedStart: 'Planned Start',
  plannedEnd: 'Planned End',
  actualEnd: 'Actual End',
  activityLog: 'Activity Log',
  when: 'When',
  date: 'Date',
  time: 'Time',
  where: 'Where',
  min_shortMinutes: 'min',
  hr_shortHours: 'hr',
  requester: 'Requester',
  subscriber: 'Subscriber',
  onsiteContactPerson: 'On-site Contact Person',
  onsiteContactPersons: 'On-site Contact Persons',
  vehicle: 'Vehicle',
  quantity: 'Quantity',
  inspection: 'Inspection',
  thisDeliveryWasNotScheduledInAdvance:
    'This delivery was not scheduled in advance',
  thisDeliveryWasScheduledForNonWorkTime:
    'This delivery was scheduled for a non-work day or time',
  sitemap: 'Sitemap',
  flagAnIssue: 'Flag an issue',
  openAnRfi: 'Open an RFI',
  requestADelivery: 'Request a Delivery',
  reason: 'Reason',
  addReasonForFlag: 'Add reason for flag',
  addReasonForRfi: 'Add reason for RFI',
  addReasonForCategoryOfVariance: 'Add reason for Category Of Variance',
  addReasonForSafetyHazard: 'Add reason for Safety Hazard',
  addANoteToThisActivityLog: 'Add a note to this Activity log',
  uploadingImage: 'Uploading Image',
  ganttDescriptions: {
    assignXUsersToYActivities: (x: number, y: number) =>
      `Assigning the following ${x} Team member${ending(x)} to ` +
      `${y} selected ${activities(y)}`,
  },
  actualDatesDescriptions: {
    newStartDateAfterOld: (
      newDate: string,
      oldDate: string,
      wrapper: (x: string) => any,
    ) => [
      'Making ',
      wrapper(newDate),
      ' the Actual Start will unmark ',
      wrapper(oldDate),
      ` as the Actual Start and will ignore all updates before ${newDate}.`,
    ],
    newStartDateBeforeOld: (
      newDate: string,
      oldDate: string,
      wrapper: (x: string) => any,
    ) => [
      'Making ',
      wrapper(newDate),
      ' the Actual Start will unmark ',
      wrapper(oldDate),
      ' as the Actual Start and will preserve all associated data.',
    ],
    newEndDateAfterOld: (
      newDate: string,
      oldDate: string,
      wrapper: (x: string) => any,
    ) => [
      'Making ',
      wrapper(newDate),
      ' the Actual Finish will unmark ',
      wrapper(oldDate),
      ' as the Actual Finish and will ignore its % Complete value.',
    ],
    newEndDateBeforeOld: (
      newDate: string,
      oldDate: string,
      wrapper: (x: string) => any,
    ) => [
      'Making ',
      wrapper(newDate),
      ' the Actual Finish will unmark ',
      wrapper(oldDate),
      ` as the Actual Finish and will ignore all updates after ${newDate}.`,
    ],
  },

  offloadingEquipmentMaps: (x: number) =>
    `Offloading equipment map${ending(x)}`,

  restrictedXLocations: (x: number) => `Restricted ${x} location${ending(x)}`,
  allLocationObjectsDisplayOnForm: 'All location objects display on form',
  restrictLocations: 'Restrict locations',
  selectRestrictedLocations: 'Select Restricted Locations',
  restrictedXEquipments: (x: number) => `Restricted ${x} equipment${ending(x)}`,
  allEquipmentObjectsDisplayOnForm: 'All equipment objects display on form',
  restrictEquipments: 'Restrict equipments',
  selectRestrictedEquipments: 'Select Restricted Equipments',

  level: 'Level',
  zone: 'Zone',
  siteLocation: 'Site location',
  verticalObject: 'Vertical object',
  allIcons: 'All icons',
  manpower: 'Manpower',
  manPower: 'Manpower',
  description: 'Description',
  code: 'Code',
  codeVerifying: 'Code is being verified',
  codeResending: 'Code is being resent',
  resendCode: 'Resend Code',
  contract: 'Contract',
  plannedValue: 'Planned Value',
  bidStatus: 'Bid Status',

  street: 'Street',
  satellite: 'Satellite',
  layers: 'Layers',
  showAllProjectsMarkers: "Show all projects' markers",
  useMapAnimations: 'Use map animations',
  terrain: 'Terrain',
  transit: 'Transit',
  traffic: 'Traffic',
  more: 'More',
  mapType: 'Map Type',
  mapDetails: 'Map Details',
  showPlan: 'Show Plan',
  showWorkflows: 'Show Workflows',

  version: 'Version',
  revision: 'Revision',
  build: 'Build',

  viewName: 'View name',
  viewImage: 'View image',

  deliveryIdNotProvided: 'Delivery ID not provided',
  sendingDeliveryTicket: 'Sending delivery ticket to driver',
  ticketSuccessfullySent: 'Ticket successfully sent',
  failedToSendTicket: 'Failed to send ticket',
  invalidDeliveryTicket: 'Invalid delivery ticket',
  deliveryDetails: 'Delivery Details',
  onSiteLogistics: 'On-site Logistics',

  showAtEntryGate: 'Show at Entry Gate',
  showAtX: (location: string) => `Show at ${location}`,
  deliveryTicket: 'Delivery Ticket',
  hasDelivery: 'has delivery',
  duplicate_verb: 'Duplicate',
  createFromToDelivery:
    'Create From-To delivery. Current delivery should be marked ≥ [Onsite]',
  duplicateCurrentDelivery: 'Duplicate current delivery',
  unsubscribeToDelivery: 'Unsubscribe to delivery',
  subscribeToDelivery: 'Subscribe to delivery',

  requiredFieldCannotBeEmpty: 'Required field cannot be empty',
  fieldShouldBeUnique: 'Field should be unique',
  pleaseCorrectErrors: 'Please correct errors',
  pleaseFillAllFields: 'Please fill all fields',

  companiesUpdated: 'Companies updated',
  companyAdded: 'Company added',

  forms: 'Forms',
  formWorkflows: 'Form workflows',
  formTypes: 'Form types',

  deliveryWorkflows: 'Delivery workflows',
  deliveryLocations: 'Delivery locations',
  deliveryCards: 'Delivery cards',

  scheduleUpload: 'Schedule upload',
  scheduleColumns: 'Schedule columns',
  struxhubLabs: `${brandName} labs`,

  mapSetup: 'Map setup',

  mobileConfiguration: 'Mobile configuration',
  additionalDataUpload: 'Additional data upload',
  onTimeThresholds: 'On-time thresholds',
  qrScannersSetup: 'QR Code scanners setup',

  businessPhone: 'Business phone',
  businessEmail: 'Business email',
  responsibles: 'Responsibles',
  responsibleContact: 'Responsible contact',
  dba: 'DBA',
  licenseNumber: 'License number',

  deliveryId: 'Delivery ID',
  bookingTime: 'Booking time',
  enterVerificationCodeFromSMS: 'Enter verification code from SMS',
  getDirections: 'Get directions',

  userInviteMessages: {
    oneUserWasInvitedToProject: (userName: string) =>
      `${userName} was invited to the project`,
    manyUsersWereInvitedToProject: (count: number) =>
      `${xUsers(count)} were invited to the project`,
  },

  userEmailLinking: {
    linkYourEmailAndSetPassword: 'Link your Email and set Password',
    weakPassword: 'Weak password. Check the valid format',
    emailHasBeenLinkedSuccessfully: 'Email has been linked successfully',
  },

  thereIsNoProject:
    'There is no project available for your user account. Please, contact administrator',

  repeatOn: 'Repeat on',

  operatorValues: {
    isNot: 'IS NOT',
    is: 'IS',
    and: 'AND',
    or: 'OR',
    for: 'FOR',
    assign: 'ASSIGN',
    if: 'IF',
  },

  anyLocation: 'Any location',
  anyCompany: 'Any company',
  selectCompany: 'Select company',
  xObjectsSelected: (x: number) => `${x} object${ending(x)} selected`,
  selectLocation: 'Select location',
  approval: 'Approval',
  firewatch: 'Firewatch',
  noRules: 'No Rules',
  rule: 'Rule',

  xOfY: (x: number, y: number) => `${x} of ${y}`,
  options: 'Options',
  selectActivityGroups: 'Select Activity Groups',
  numberOfBands: 'Number of Bands',
  lbs: 'LBS', // location breakdown structure
  workBreakdownStructureWbs: 'Work breakdown structure (WBS)',
  locationBreakdownStructureLbs: 'Location breakdown structure (LBS)',
  groupByOptions: 'Group by options',
  showEmptyBandsLevels: 'Show empty bands levels',
  customGroupCreation: 'Custom Group Creation',
  bandX: (x: number) => `Band ${x}`,
  custom: 'Custom',
  groupByX: (x: string) => `Group by: ${x}`,

  bookingId: 'Booking ID',
  responsibleCompany: 'Responsible Company',
  bookingDateEnTimeOffset: (offset: string) =>
    `Booking Date & Time (${offset})`,
  durHmm_durationShort: 'Dur. (h:mm)',
  locationStatus: 'Location Status',
  closureStart: 'Closure Start',
  closureFinish: 'Closure Finish',
  permittedCompany: 'Permitted Company',
  closureWorkflow: 'Closure Workflow',
  updateForm: 'Update Form',
  formStatus: 'Form Status',
  formStart: 'Form Start',
  formFinish: 'Form Finish',
  materialName: 'Material Name',
  productCategory: 'Product Category',
  deliveryMaterialNotes: 'Delivery Material Notes',
  vendor: 'Vendor',
  quantityOnsite: 'Quantity On-site',
  storageArea: 'Storage Area',
  requiredOnsiteDate: 'Required On-site Date',
  nextDelivery: 'Next Delivery',
  deliveryBookingDate: 'Delivery Booking Date',
  bookedQuantity: 'Booked quantity',
  recordLastUpdated: 'Record Last Updated',
  numObjects: '# Objects',
  showOn: 'Show on',
  noShow: 'No Show',
  moved: 'Moved',
  otherReason: 'Other Reason',
  mostPertinentDefault: 'Most pertinent (default)',
  zones: 'Zones',
  gates: 'Gates',
  routes: 'Routes',
  areas: 'Areas',
  levels: 'Levels',
  totalX: (x: number) => `Total ${x}`,
  deliveriesEnEquipmentNeedsPerHour: 'Deliveries & Equipment Needs per hour',
  resetFilters: 'Reset Filters',
  saved_activities: 'Saved',
  since: 'Since',
  lastP6Update: 'Last P6 update',
  lastWeek: 'Last week',
  lastMonth: 'Last month',
  last6Week: 'Last 6 week',
  dateRange: 'Date range',
  dateRangeForRecentlyUpdatedActivities:
    'Date range for recently updated activities',
  myTasks: 'My Tasks',
  recentlyUpdated_activities: 'Recently Updated',
  activitiesWithSpecificStatus: 'Activities with specific status',
  actualizedFromSchedule_activities: 'Actualized from Schedule',
  activitiesWithOpenScheduleComment: 'Activities with open Schedule Comment',
  notCompleted_activities: 'Not Completed',
  criticalPathActivities: 'Critical path Activities',
  flaggedActivities: 'Flagged Activities',
  lateActivities: 'Late Activities',
  activitiesWithOpenRFI: 'Activities with open RFI',
  activitiesWithDeliveries: 'Activities with Deliveries',
  activityStatusValues: {
    NotStarted: 'Not started',
    InProgress: 'In progress',
    Completed: 'Completed',
    DueToday: 'Due today',
    DueTomorrow: 'Due tomorrow',
    LateFinish: 'Late finish',
    LateStart: 'Late start',
    StartsToday: 'Starts today',
    Done: 'Done',
    Incomplete: 'Incomplete',
  },
  numberOfActivities: 'Number of Activities',
  seeXActivities: (x: number) => `See ${x} ${activities(x)}`,
  type: 'Type',
  assigners: 'Assigners',
  items: 'Items',
  projectMember: 'Project member',
  disableFilter: 'Disable filter',
  totalDeliveries: 'Total Deliveries',
  totalSitemaps: 'Total Sitemaps',
  totalObjects: 'Total Objects',
  totalForms: 'Total Forms',
  totalReports: 'Total Reports',
  totalClosures: 'Total Closures',
  totalSensors: 'Total Sensors',
  seeXUsers: (x: number) => `See ${xUsers(x)}`,
  totalLogistics: 'Total Workflows',
  totalMaterials: 'Total Materials',
  seeXDeliveries: (x: number) => `See ${x} ${deliveries(x)}`,
  xPlans: (x: number) => `${x} ${plans(x)}`,
  seeXObjects: (x: number) => `See ${x} Object${ending(x)}`,
  seeXSitemaps: (x: number) => `See ${x} Sitemap${ending(x)}`,
  seeXForms: (x: number) => `See ${xForms(x)}`,
  seeXClosures: (x: number) => `See ${x} Closure${ending(x)}`,
  seeXSensors: (x: number) => `See ${x} Sensor${ending(x)}`,
  seeXLogistics: (x: number) => `See ${x} Workflow${ending(x)}`,
  seeXMaterials: (x: number) => `See ${x} Material${ending(x)}`,
  seeXReports: (x: number) => `See ${x} Report${ending(x)}`,
  currentLookahead: 'Current lookahead',
  fullProject: 'Full Project',
  numberOfDeliveries: 'Number of Deliveries',
  numberOfWorkflows: 'Number of Workflows',
  savedFilters: 'Saved Filters',
  saved_deliveries: 'Saved',
  xDeliveries: (x: number) => `${x} ${deliveries(x)}`,

  xWorkflows: (x: number) => `${x} ${workflows(x)}`,
  savedActivitySets: 'Saved Activity Sets',
  thereAreNoSavedActivityPresets: 'There are no saved activity presets',
  xForms,
  xFilters: (x: number) => `${x} filter${ending(x)}`,
  thereAreNoSavedFilters: 'There are no saved filters',
  makePublic: 'Make Public',
  workPackageName: 'Work package name',
  selectedFilters: 'Selected filters',
  share: 'Share',
  saveCurrentFilters: 'Save current filters',
  saveANewCustomFilter: 'Save a new custom filter',
  editACustomFilter: 'Edit a custom filter',

  oneDay: 'One Day',
  oneDayDefaultToday: 'One Day Default Today',
  week: 'Week',
  fixedWeek: 'Fixed Week',
  fixedTwoWeeks: 'Fixed 2 Weeks',
  fixedThreeWeeks: 'Fixed 3 Weeks',
  threeWeeks: '3 Weeks',
  fourWeeks: '4 Weeks',
  fixedFourWeeks: 'Fixed 4 Weeks',
  month: 'Month',
  fixedSixWeeks: 'Fixed 6 Weeks',
  sixWeeks: '6 Weeks',
  threeMonth: '3 Months',
  year: 'Year',
  other: 'Other',

  xDateToYDate: (xDate: string, yDate: string) => `${xDate} to ${yDate}`,
  assignUsersOrCategories: 'Assign users or categories',
  searchUserCompanyTeamOrRole: 'Search User, Company, Team or Role',
  successfullyUpdatedExistingAssignments:
    'Successfully updated existing assignments',
  successfullyUpdatedExistingAssignmentsByApp: (app: string) =>
    `Successfully updated existing ${app} assignments`,
  successfullyDeletedAssignments: 'Successfully deleted assignments',
  successfullyDeletedAssignmentsByApp: (app: string) =>
    `Successfully deleted ${app} assignments`,
  newAnnouncement: 'New announcement',
  editingAnnouncement: 'Editing announcement',
  announcements: 'Announcements',
  announcementHasBeenCreated: 'Announcement has been created',
  announcementDateArrived: 'Announcement date has arrived',
  announcementNotFound: 'Announcement not found',

  approve_verb: 'Approve',
  readyToInspect: 'Ready to inspect',
  toInspect: 'To inspect',
  notInUse: 'Not in use',
  everyXDaysInspection: (x: number) => `Every ${x} day${ending(x)} inspection`,
  everyXWeeksInspection: (x: number) =>
    `Every ${x} week${ending(x)} inspection`,
  everyXMonthsInspection: (x: number) =>
    `Every ${x} month${ending(x)} inspection`,
  addInspection: 'Add Inspection',
  finish_noun: 'Finish',
  legend: 'Legend',
  startFinishState: 'Start/Finish state',
  intermediateState: 'Intermediate state',
  action: 'Action',
  ballInCourtStep: 'Ball-in-court step',
  active: 'Active',
  struxhubActivityX: (code: string) => `${brandName} Activity ${code}`,
  openActivity: 'Open Activity',
  openDelivery: 'Open Delivery',
  loadingPresentationMode: 'Loading Presentation Mode',

  xOfYSelected: (x: number, y: number) => `${x} of ${y} selected`,
  xDeliveriesShowing: (x: number) => `${x} ${deliveries(x)} showing`,
  assign_verb: 'Assign',
  subscribe_verb: 'Subscribe',
  new: 'New',
  completedTotal: 'Completed/Total',
  searchByBookingIdOrSubscribers: 'Search by Booking ID or Subscribers',
  my: 'My',
  me: 'Me',
  full_period: 'Full',
  day: 'Day',
  calendarView: 'Calendar View',
  ganttView: 'Gantt View',
  listView: 'List View',
  mapView: 'Map View',
  dur_duration: 'Dur',
  loc_locations: 'Loc',
  equip_equipment: 'Equip',
  mat_materials: 'Mat',
  vend_vendor: 'Vend',
  a_actualFlag: 'A',
  unspecified: 'Unspecified',

  yesUnsubscribe: 'Yes, unsubscribe',
  deliveriesListDescriptions: {
    subscribeConfirmMassage: (
      dUnsubscribedCount: number,
      dSelectedCount: number,
    ) =>
      `Subscribe to [${dUnsubscribedCount}] unsubscribed ` +
      `${deliveries(dUnsubscribedCount)} ` +
      `of [${dSelectedCount}] selected ` +
      `${deliveries(dSelectedCount)}?`,

    unsubscribeConfirmMessage: (dSelectedCount: number) =>
      `You have already subscribed to all of [${dSelectedCount}] ` +
      `selected ${deliveries(dSelectedCount)}.\n\n` +
      'Perhaps, do you want to unsubscribe?',
  },
  logisticsListDescriptions: {
    subscribeConfirmMassage: (
      dUnsubscribedCount: number,
      dSelectedCount: number,
    ) =>
      `Subscribe to [${dUnsubscribedCount}] unsubscribed ` +
      `${workflows(dUnsubscribedCount)} ` +
      `of [${dSelectedCount}] selected ` +
      `${workflows(dSelectedCount)}?`,
    unsubscribeConfirmMessage: (dSelectedCount: number) =>
      `You have already subscribed to all of [${dSelectedCount}] ` +
      `selected ${workflows(dSelectedCount)}.\n\n` +
      'Perhaps, do you want to unsubscribe?',
    areYouWantToDelete:
      'Are you sure that you want to delete all of these selected workflows?',
    deletionConfirmMsg: (selectedCount: number) =>
      `You selected - ${selectedCount} ${workflows(selectedCount)}`,
  },
  sitemapsDataIsLoading: 'Sitemaps Data is loading',
  editingConfirmation: 'Editing Confirmation',
  selectDataColumn: 'Select data column',
  addFieldForEditing: 'Add field for editing',
  deliveryBulkEditorDescriptions: {
    warningMessagePart1:
      'None of the selected deliveries can be  ' +
      'changed according to your account permissions',
    warningMessagePart2: 'Make another selection or contact administrator',
  },
  failedToEdit: 'Failed to edit',
  editedXFieldsOnYDeliveries: (x: number, y: number) =>
    `Edited ${x} Field${ending(x)} on ${y} ${deliveries(y)}`,
  editXDeliveries_question: (x: number) => `Edit ${x} ${deliveries(x)}?`,
  onlyXOfYSelectedDeliveriesCanBeChanged: (x: number, y: number) =>
    `Only [${x}] of [${y}] selected ${deliveries(x)} ` +
    'can be changed, proceed?',
  editXFieldsOnYDeliveries: (x: number, y: number) =>
    `Edit ${x} Field${ending(x)} on ${y} ${deliveries(y)}`,
  updateXDeliveries: (x: number) => `Update ${x} ${deliveries(x)}`,
  failedToUpdate: 'Failed to update',
  statusUpdated: 'Status updated',
  statusUpdatedOnXDeliveries: (x: number) =>
    `Status updated on ${x} ${deliveries(x)}`,
  updateDeliveryStatus: 'Update Delivery Status',
  loadingDeliveriesData: 'Loading deliveries data',
  loadingFormsData: 'Loading Forms data',
  loadingLogisticsData: 'Loading Logistics data',
  form: 'Form',
  log: 'Log',

  weekOfEndDate: 'Week Of End Date',
  companySummary: 'Company Summary',
  percentDone: '% Done',
  percentOnTime: '% On time',
  trucks: 'Trucks',
  avgTrucksPerDelivery: 'Avg. trucks / Delivery',
  deliveryGantt: 'Delivery Gantt',
  projectSummary: 'Project Summary',
  completionRatePercent: 'Completion Rate (%)',
  completionRate: 'Completion Rate',
  onTimeRatePercent: 'On-Time Rate (%)',
  onTimeRate: 'On-Time Rate',
  unplanned: 'Unplanned',
  unplannedRate: 'Unplanned Rate',
  onTimeStart: 'On-Time Start',
  lateOnTime: 'Late/On-Time',
  allDay: 'All day',
  weekly: 'Weekly',
  monthly: 'Monthly',
  onTimeLate: 'On-Time/Late',
  earnedDuration: 'Earned Duration',
  show_verb: 'Show',
  showXMore: (x: number) => `Show ${x} more`,
  showActive: 'Show active',
  loadingProjectData: 'Loading Project Data',
  updateToday: 'Update today',
  critical: 'Critical',
  deltaDays: '∆ Days',
  sumSlip: '∑ Slip',
  openIssues: 'Open issues',
  plannedToday: 'Planned today',
  actualToday: 'Actual today',
  deltaManpowerToday: '∆ Man. today',
  deltaManpowerTrend: '∆ Man. trend',
  soon: 'Soon',
  numberOfPicks: 'Number of picks',
  repeatDelivery: 'Repeat delivery',
  every_plural: 'Every',
  endsOn: 'Ends on',
  thisDelivery: 'This delivery',
  thisAndFutureDeliveries: 'This and future deliveries',
  allDeliveries: 'All deliveries',
  everyXDays: (x: number) => `Every ${x} day${ending(x)}`,
  everyXWeeks: (x: number) => `Every ${x} week${ending(x)}`,
  everyXMonths: (x: number) => `Every ${x} month${ending(x)}`,
  xDays: (x: number) => `day${ending(x)}`,
  xWeeks: (x: number) => `week${ending(x)}`,
  xMonths: (x: number) => `month${ending(x)}`,
  xMaterial: (x: number) => `Material #${x}`,
  addMaterial: 'Add material',
  forecast: 'Forecast',
  multipleEntries: {
    add_x: (x: string) => `Add ${x}`,
    paste: 'You can paste multiple entries',
    typeOrPaste: 'You can type or paste multiple entries',
    repeatedValue: 'Duplicate entry',
  },

  locationControls: 'Location controls',
  addDailyClosure: 'Add daily closure',

  searchCompanyOrCompanyType: 'Search company or company type',

  newActivity: 'New activity',
  cast: 'Cast',
  castName: 'Cast Name',
  monitoringName: 'Monitoring Name',
  deviceId: 'Device ID',
  device: 'Device',
  lastMaturity: 'Last maturity',
  lastReading: 'Last reading',
  elapsedTime: 'Elapsed time (hh:mm)',
  maxElapsedTime: 'Max elapsed time (min)',
  lastTemperature: (temperatureUnit: MaturixTemperatureUnit) =>
    `Last temperature (${EnglishTree.temperatureUnit(temperatureUnit)})`,
  lastStrength: (strengthUnit: MaturixStrengthUnit) =>
    `Last strength (${EnglishTree.strengthUnit(strengthUnit)})`,
  temperatureWithUnit: (temperatureUnit: MaturixTemperatureUnit) =>
    `Temperature (${EnglishTree.temperatureUnit(temperatureUnit)})`,
  strengthWithUnit: (strengthUnit: MaturixStrengthUnit) =>
    `Strength (${EnglishTree.strengthUnit(strengthUnit)})`,
  temperatureUnit: (temperatureUnit: MaturixTemperatureUnit): string => {
    switch (temperatureUnit) {
      case MaturixTemperatureUnit.CELCIUS:
        return 'Celsius'
      case MaturixTemperatureUnit.FAHRENHEIT:
        return 'Fahrenheit'
    }
  },
  strengthUnit: (strengthUnit: MaturixStrengthUnit): string => {
    switch (strengthUnit) {
      case MaturixStrengthUnit.KGPERCMSQ:
        return 'kg/cm²'
      case MaturixStrengthUnit.MPA:
        return 'MPa'
      case MaturixStrengthUnit.NPERMMSQ:
        return 'N/mm²'
      case MaturixStrengthUnit.PSI:
        return 'PSI'
    }
  },

  unconfirmed: 'Unconfirmed',
  confirmed: 'Confirmed',
  delivering: 'Delivering',
  paused: 'Paused',
  onHold: 'On hold',

  ticketed: 'Ticketed',
  recalled: 'Recalled',
  enRoute: 'En-route',
  pouring: 'Pouring',
  delivered: 'Delivered',

  orderSummary: 'Order summary',
  payloadSummary: 'Payload summary',

  orderStatus: 'Order status',
  totalVolume: 'Total volume',
  deliveryLocation: 'Delivery location',
  mixType: 'Mix type',
  slump: 'Slump',
  spacingOrUnloadTime: 'Spacing or unload time',
  pouringMode: 'Pouring mode',
  elementsToPour: 'Elements to pour',
  extraCharge: 'Extra charge',
  orderedBy: 'Ordered by',

  load: 'Load',
  total: 'Total',
  timeOnSite: 'Time on site',
  ticket: 'Ticket',
  volumeLoaded: 'Volume loaded',
  totalOrderVolume: 'Total order volume',
  additive: 'Additive',
  jobTimes: 'Job times',
  startLoad: 'Start load',
  beginPouring: 'Begin pouring',
  washing: 'Washing',
  totalTimeOnSite: 'Total time on site',
  inXHoursAndXMin: (hours: number, minutes: number) =>
    `In ${hours}h ${minutes}min`,
  xHoursAndXMinLate: (hours: number, minutes: number) =>
    `${hours}h ${minutes}min late`,
  inXMin: (minutes: number) => `In ${minutes} min`,
  xMinLate: (minutes: number) => `${minutes} min late`,
  isAboutToArrive: 'Is about to arrive',

  projected_time: 'Projected',
  recurringDelivery: 'Recurring',
  endingXDate: (date: string) => `Ending ${date}`,
  isQuestionMandatory: 'Is question mandatory?',
  remainingDuration: 'Remaining duration',
  baseUrl: 'Base Url',
  siteId: 'Site ID',
  isActive: 'Is Active',
  syncStartDate: 'Synchronization start date',
  notUniqueActivityCodeMessage: (code: string) =>
    `The activity with the code [${code}] already exists. Try another value`,
  activityWasUpdated: 'Following activity was updated',
  activityUpdated: 'Activity updated',
  editXFieldsOnYActivities: (x: number, y: number) =>
    `Edit ${x} Field${ending(x)} on ${y} ${activities(y)}`,
  editedXFieldsOnYActivities: (x: number, y: number) =>
    `Edited ${x} Field${ending(x)} on ${y} ${activities(y)}`,
  editXActivities_question: (x: number) => `Edit ${x} ${activities(x)}?`,
  onlyXOfYSelectedActivitiesCanBeChanged: (x: number, y: number) =>
    `Only [${x}] of [${y}] selected ${activities(x)} ` +
    'can be changed, proceed?',
  p6Viewer: 'P6 Viewer',
  scannerApp: 'Scanner App',
  activitySetName: 'Activity set name',
  editActivitySet: 'Edit an activity set',
  includeXFile: (ext: string) => `Include a .${ext} file`,
  includeDetailedEachForm: 'Include a detailed view of each form',
  usersSubscribedTo: {
    deliveries: (uCount: number, iCount: number) =>
      `Subscribed ${uCount} ${users(uCount)} to ${iCount} ${deliveries(
        iCount,
      )}`,
    activities: (uCount: number, iCount: number) =>
      `Subscribed ${uCount} ${users(uCount)} to ${iCount} ${activities(
        iCount,
      )}`,
    announcements: (uCount: number, iCount: number) =>
      `Subscribed ${uCount} ${users(uCount)} to ${iCount} ${announcements(
        iCount,
      )}`,
    forms: (uCount: number, iCount: number) =>
      `Subscribed ${uCount} ${users(uCount)} to ${iCount} ${forms(iCount)}`,
    sensors: (uCount: number, iCount: number) =>
      `Subscribed ${uCount} ${users(uCount)} to ${iCount} ${sensors(iCount)}`,
    closures: (uCount: number, iCount: number) =>
      `Subscribed ${uCount} ${users(uCount)} to ${iCount} ${closures(iCount)}`,
  },
  usersUnSubscribedFrom: {
    deliveries: (uCount: number, iCount: number) =>
      `Unsubscribed ${uCount} ${users(uCount)} to ${iCount} ${deliveries(
        iCount,
      )}`,
    activities: (uCount: number, iCount: number) =>
      `Unsubscribed ${uCount} ${users(uCount)} to ${iCount} ${activities(
        iCount,
      )}`,
    announcements: (uCount: number, iCount: number) =>
      `Unsubscribed ${uCount} ${users(uCount)} to ${iCount} ${announcements(
        iCount,
      )}`,
    forms: (uCount: number, iCount: number) =>
      `Unsubscribed ${uCount} ${users(uCount)} to ${iCount} ${forms(iCount)}`,
    sensors: (uCount: number, iCount: number) =>
      `Unsubscribed ${uCount} ${users(uCount)} to ${iCount} ${sensors(iCount)}`,
    closures: (uCount: number, iCount: number) =>
      `Unsubscribed ${uCount} ${users(uCount)} to ${iCount} ${closures(
        iCount,
      )}`,
  },
  scannerName: 'Scanner name',
  scanMaster: 'Scan master',
  scanStationReport: 'Scan Station Report',
  materialTrackingReport: 'Material Tracking Report',
  truckSize: 'Truck size',
  cancelationReason: 'Cancelation Reason',
  responsibleCompanies: 'Responsible companies',
  noDataAvailable: 'No data available',
  last7Days: 'Last 7 days',
  last30Days: 'Last 30 days',
  last120Days: 'Last 120 days',
  last365Days: 'Last 365 days',
  entireProject: 'Entire project',
  frequencyAndPeriod: 'Frequency and period',
  activityDataLinking: {
    disableLinkingQuestion: (field: string) =>
      `Linking to external '${field}' will be disabled. Do you want to proceed?`,
    enableLinkingQuestion: (fieldValue: string, externalFieldValue: string) =>
      `Do you want to link '${fieldValue}' to external '${externalFieldValue}'? This may affect other activities.`,
    linkingIsDisabled: 'Data linking is disabled',
    linkingIsEnabled: 'Data linking is enabled',
    linkingDisabled: `Data linking disabled`,
    linkingEnabled: `Data linking enabled`,
    editWarning: `Linking changes might impact other activities`,
    bulkEditWarning: `Data linking will be disabled for editable fields`,
  },

  materialsUpload: 'Materials upload',
  plannedQuantity: 'Planned quantity',

  materialsUploading: {
    uploadMaterials: 'Upload materials',
    goToTheMaterialsPage: 'Go to the Materials page',
    uploadYourMaterialsFile:
      'Upload your Materials file in Excel (.xlsx, .xlsm) format.',
    thereAreNoSheetsError:
      'There are no sheets in the provided Excel file or an error occurred while reading the file',
    materialCategoryLvl1: 'Material Category (Level 1)',
    materialLvl2: 'Material (Level 2)',
    materialDescription: 'Material Description',
    materialPlannedQuantity: 'Material Planned Quantity',
    startingTheUpload: 'Starting the upload',
    readingMaterialsFile: 'Reading materials file',
    extractingMaterialsData: 'Extracting materials data',
    removingOldData: 'Removing old data',
    savingMaterialsData: 'Saving materials data',
    finishingUpload: 'Finishing upload',
    uploadOfMaterialsFailedAt: 'Uploading of materials is failed at',
    uploadWasSuccessful: 'Upload was successful',
    pleaseSelectSheetToUpload: (isMultiple: boolean) =>
      `${
        isMultiple ? 'The current file contains multiple sheets. ' : ''
      }Please select a sheet from the file to import.`,
    makeSureLocationNamesUnique:
      'Make sure location names are unique in StruxHub',
  },

  materialsUploadHistory: {
    currentMaterials: 'Current materials',
    projectUsesDefaultMaterials: 'The project uses default materials',
    latestUpload: 'Latest upload',
    materialsRestoringConfirmation: 'Materials Restoring Confirmation',
    totalMaterialCategoriesAndMaterials:
      'Total material categories and materials',
    restoreDefaultMaterials: 'Restore default materials',
    doYouConfirmRestoreToDefaults:
      'Do you confirm restoring to the default materials?',
    restoredSuccessfully: 'Restored successfully',
    failedToRestore: 'Failed to restore',
  },

  materialsUploadConfirm: {
    confirmDataMerge: 'Confirm data merge',
    confirmDescription1:
      'Uploading this file will update all changed values for existing materials and create new records for new materials.',
    confirmDescription2:
      'If you want to delete all existing materials data and replace it with the data in the file upload,',
    confirmDescription3: 'click here',
    replaceDescription:
      'Do you really want to delete all existing materials data and replace it with the data in the file upload?',
  },

  collapseAll: 'Collapse all',
  expandAll: 'Expand all',

  myUserProfile: 'My user profile',
  addTeammates: 'Add Teammates',
  addSummaryColumn: 'Add summary column',
  configureColumns: 'Configure columns',
  summaryColumns: 'Summary columns',
  receipt: 'Receipt',
  summary: 'Summary',

  remainingQuantity: 'Remaining quantity',

  materialsInDeliveryFormWarning: {
    onlyXMaterialsForForm: (x: number) =>
      `Currently only ${x} material${ending(x, '', 's')} ${ending(
        x,
        'is',
        'are',
      )} enabled for one Delivery Form. If this is a limitation please contact`,
    andWeCanTalk: 'and we can talk',
  },

  deliverySuccessfullyCreated: 'Delivery successfully created',

  xDates,

  materialDescription: 'Material Description',
  thisGroupAlreadyExists: 'This group already exists',

  upload: 'Upload',
  totalBooked: 'Total booked',
  totalDelivered: 'Total delivered',
  doneDeliveries: 'Done deliveries',

  xVendors: (x: number) => `${x} Vendor${ending(x)}`,
  thisWeek: 'This week',
  thisMonth: 'This month',

  youCannotChangeDelivery:
    'You cannot change a delivery while it is in this status',
  plannedInstallLocation: 'Planned install location',
  plannedDeliveryLocation: 'Planned delivery location',
  currentLocation: 'Current location',
  procurementID: 'Procurement ID',
  id_short: 'ID',
  readyToInstallQuantity: 'Ready to install quantity',
  totalOnSiteQuantity: 'Total on-site quantity',
  toMoveQuantity: 'To move quantity',

  formMaterialSubFields: {
    materialHasQuantityAndLocation:
      'Material has Quantity and Location fields which you can also add/edit below.',
    clickToEnableQuantity: 'Click here if you want to enable Quantity.',
    clickToEnableLocation: 'Click here if you want to enable Location.',
  },
  newFormType: 'New Form type',
  materialTransferId: 'Material transfer ID',
  materialTransferDate: 'Material transfer date',
  newTransfer: 'New transfer',
  doneTransfers: 'Done transfers',
  totalTransferred: 'Total transferred',
  xTransfers: (x: number) => `${x} Transfer${ending(x)}`,

  youCanSelectMultipleOptions: 'You can select multiple options',
  updateStatus: 'Update status',

  review_verb: 'Review',
  reviewed: 'Reviewed',
  bicInspected: 'BIC Inspected',
  activated: 'Activated',
  finished: 'Finished',
  submitted: 'Submitted',
  passAndClose: 'Pass & Close',
  formIsDoneOrDenied: (isDenied: boolean) =>
    `Form is ${isDenied ? 'denied' : 'done'}`,
  stopped: 'Stopped',
  request_noun: 'Request',
  review_noun: 'Review',
  bicInspection: 'BIC Inspection',
  recurringInspection: 'Recurring inspection',
  notifyUserIfWorkflowHasTags: 'Notify user if workflow has the following tags',
  thisWorkflowStepCanBePerformed:
    'This workflow step can be performed by the following users',
  xRules: (x: number) => `${x} rule${ending(x)}`,
  step: 'Step',
  bicUser: 'BIC user',
  formSection: 'Form section',
  actions: 'Actions',
  frequency: 'Frequency',
  deadline: 'Deadline',
  automaticStart: 'Automatic start',
  addStep: 'Add step',

  workflowConfDescr: {
    mustContainApproval:
      'Workflow with a Request step must contain an Approval step',
    cannotBeTheLastStep: (stepName: string) =>
      `${stepName} can't be the last step, please add another one or remove this one.`,
    workflowStartsOnDate: 'Workflow starts automatically on start date.',
    automaticallyStart: 'Automatically start workflow on start date',
    replaceWithStep: (stepType: string): string =>
      `Replace with ${stepType} step`,
    replaceWithStart: 'Replace with Start step',
    deleteWorkflowStep: 'Delete workflow step',
    removeStepDialogMsg: 'Do you want to remove this workflow step?',
    toastUnsavedMsg: 'The changes you made have been reset',
    recurringHasConstr: 'Recurring Inspection step has constraints:',
    toggleDialogMsg: (isManualStart: boolean): string =>
      `Change it to an ${
        isManualStart ? 'Manual Start' : 'Automatic Start'
      } step?`,
    selectStepMsg: 'Select a workflow step to add/modify fields',
  },
  workflowConfTooltips: {
    bicBlockFirstLine: `One can assign one or more users to Ball-in-Court workflow steps.`,
    bicBlockSecondLine: `If you want to assign these users to specific scopes of work,
  define a rule based on the desired set of tags.`,
  },
  workflowTableText: {
    seeDescr: 'See step descriptions',
    stepCategory: 'Workflow step category',
    workflowStep: 'Workflow step',
    constraints: 'Constraints',
    bicText: 'Can specify ball-in-court user?',
    multipleText: 'Can have multiple instances in workflow?',
    submissionDescr:
      'Initiating workflow step to submit a form that does not require a subsequent approval.',
    submissionConstr: 'Can only initiate a workflow.',
    requestDescr: 'Initiating workflow step to request an approval.',
    requestConstr:
      'Can only initiate a workflow. Must have a subsequent Approval step.',
    reviewDescr:
      'General advancement step of a workflow that can be used for pre-approvals.',
    approvalDescr:
      'Approval of a workflow request that can be assigned to a specific user(s).',
    approvalConstr: 'Must have a preceding Request step.',
    startDescr: 'Start of a workflow initiated by clicking a «Start» button.',
    autoStartDescr:
      'Start of a workflow that is automatically initiated on the workflow start date.',
    onSiteDescr: 'Used to indicate the on-site arrival of a delivery vehicle.',
    readyToInspectDescr:
      'Intermediate workflow step used to notify inspectors.',
    inspectionDescr: 'General inspection step.',
    bicInspectionDescr:
      'Inspection step that can be assigned to a specific user(s).',
    recurringDescr: 'Recurring inspections at a specified frequency.',
    recurringConstr:
      'Must be directly preceded by either a Start or Automatic start step.',
    finishDescr: 'Completion of a workflow marked by clicking a «Done» button.',
  },
  workflowSteps: 'Workflow steps',
  workflowDiagram: 'Workflow diagram',
  workflowStepConfigurator: 'Workflow step configurator',
  restoreToLastSavedState: 'Restore to last saved state',
  endOfTheDay: 'End of the day',
  everyX_withoutNum: (x: number) => `Every${ending(x, '', '')}`,
  submission: 'Submission',
  initiation: 'Initiation',

  deleteForm: 'Delete form',
  formDeletionDialogDescr: {
    confirmDelete: `Deleting this form will remove the form
      and all associated notes and data from the project.`,
    confirmDelete2: 'Do this only if the Form Request was a mistake.',
  },
  emailPdfReport: 'Email PDF report',
  unsubscribe: 'Unsubscribe',
  followFormMenuDescr: {
    toReceive: 'To receive workflow notifications',
    toStop: 'To stop receiving workflow notifications',
  },
  formDeleteMenuDescr: {
    isDone: 'The form is Done, you cannot delete it',
    onlyFor: 'Only available to admins, form masters, requesters, assignees',
  },
  configureTableColumns: 'Configure table columns',
  searchProperties: 'Search properties',
  shownInTable: 'Shown in table',
  hiddenInTable: 'Hidden in table',
  freezeColumns: 'Freeze columns above',
  restoreDefaults: 'Restore defaults',
  announcementFollowed: 'Announcement followed',
  successfullyDeletedForms: (x: number) =>
    `Successfully deleted ${x} ${forms(x)}`,
  failedToDelete: 'Failed to delete',
  cannotDeleteDoneForms: 'Cannot delete Done forms',
  getMobileNativeApp: 'Get the app',
  getMobileNativeApp_long: 'Get the StruxHub mobile app',
  getAppOnGoogle: 'Get it on Google Play',
  getAppOnApple: 'Download on the App Store',
  oneTimePopup: {
    title: 'Download our new mobile app!',
    body: 'Receive real-time updates and get more work done in the field with StruxHub.',
    dismiss: 'Ok understood!',
  },

  exitScanner: 'Exit Scanner',
  deleteScanner: 'Delete Scanner',
  editScannerText: 'Edit Scanner',
  shouldDeleteScanner: 'Should delete scanner',
  activateScanner: 'Activate scanner',
  allowedUsers: 'Allowed Users',
  badgeDescription: 'Badge description',
  makeItTimed:
    "Make this a 'Timed' scanner for users. " +
    'Scan-Start : Scan-Stop = Total Time',
  limitAccess: "Limit access to specified 'Badged' Users, Teams or Companies",
  endRideConfirm: 'Are you sure you want to end the ride?',
  changeModeConfirm: 'Are you sure you want to change the ride mode?',
  isScannerTimedByDay: 'Timer automatically ENDS at close of work day',
  endRide: 'End Ride',
  scanTo: 'Scan to',
  totalPassengers: 'Total Passengers',
  scannerActive: 'Scanner Active',
  pauseScanner: 'Pause Scanner',
  resumeScanner: 'Resume Scanner',
  endRideForAll: 'End ride for all',
  workflowCategory: 'Workflow category',
  workflowName: 'Workflow name',
  undo: 'Undo',
  redo: 'Redo',
  icon: 'Icon',
  showInApp: 'Show in App',
  blockRequestsForNonWorkTimes: 'Block requests for non-work times',
  general: 'General',
  steps: 'Steps',
  views: 'Views',
  confirmation: 'Confirmation',
  deletionOfDefaultWorkflows:
    'Deletion of default or material workflows is prohibited',
  theFollowingEntitiesWereUpdated: 'The following entities were updated',
  globeViews: 'Globe Views',
  whiteboards: 'Whiteboards',
  useMapLabels: 'Use map labels',
  notifiedUsers: 'Notified users',
  selectNotificationUsers:
    'Select users that will receive a notification after completion of this step',
  wouldYouEnableNotificationsForSteps:
    'Would you like to enable notifications for this field across all workflow steps?',
}

export default EnglishTree
