import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import { getChartColor } from '~/client/src/desktop/stores/stateUtils'
import Activity from '~/client/src/shared/models/Activity'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import ProgressColoredValue from '../../ActivityNameCell/ProgressColoredValue'

interface IProps {
  activity: Activity
  company: string

  state?: DesktopInitialState
  projectDateStore?: ProjectDateStore
}

@inject('state', 'projectDateStore')
@observer
export default class ActivityPercentCompleteCell extends React.Component<IProps> {
  @computed
  private get deltaDays(): number {
    const { activity, company, projectDateStore } = this.props
    return activity.getDeltaDays(projectDateStore, company)
  }

  public render() {
    const { activity, company, projectDateStore, state } = this.props
    const isGanttViewMode = state.activityList.viewMode === ViewModes.Gantt

    const { percentComplete, manpower } =
      activity.getStatusUpdateForCompany(company)
    const didStartOrDidPlanToStart =
      activity.didStartOrDidPlanToStart(projectDateStore)
    const color = getChartColor(this.deltaDays, manpower, state)

    return (
      <div
        className={classList({
          'text cell-wrapper px10 pointer': true,
          'bb-cool-grey': !isGanttViewMode,
        })}
      >
        {didStartOrDidPlanToStart && (
          <div className="row colored-val">
            {percentComplete + '%'}
            <ProgressColoredValue value={this.deltaDays} style={color} />
          </div>
        )}
      </div>
    )
  }
}
