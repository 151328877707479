import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IPresentationSettingsFieldsFragment = Pick<
  Types.IPresentationSettings,
  'id' | 'inactivityDuration' | 'projectId' | 'updatedAt' | 'updatedBy'
> & {
  screens?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<
          Types.IScreen,
          | 'duration'
          | 'isShown'
          | 'key'
          | 'order'
          | 'shouldShowEntity'
          | 'entityDuration'
          | 'entityKey'
        >
      >
    >
  >
}

export type IGetPresentationSettingsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetPresentationSettingsQuery = {
  presentationSettings?: Types.Maybe<{
    data: Array<IPresentationSettingsFieldsFragment>
  }>
}

export type ISavePresentationSettingsMutationVariables = Types.Exact<{
  presentationSettings: Types.IPresentationSettingsInput
}>

export type ISavePresentationSettingsMutation = {
  savePresentationSettings?: Types.Maybe<IPresentationSettingsFieldsFragment>
}

export type IListenToPresentationSettingsSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToPresentationSettingsSubscription = {
  presentationSettings?: Types.Maybe<
    Pick<Types.IPresentationSettingsChangeEvent, 'id'> & {
      item?: Types.Maybe<IPresentationSettingsFieldsFragment>
    }
  >
}

export const PresentationSettingsFieldsFragmentDoc = gql`
  fragment PresentationSettingsFields on PresentationSettings {
    id
    inactivityDuration
    screens {
      duration
      isShown
      key
      order
      shouldShowEntity
      entityDuration
      entityKey
    }
    projectId
    updatedAt
    updatedBy
  }
`
export const GetPresentationSettingsDocument = gql`
  query GetPresentationSettings($projectId: ObjectId!) {
    presentationSettings(projectId: $projectId, limit: 1) {
      data {
        ...PresentationSettingsFields
      }
    }
  }
  ${PresentationSettingsFieldsFragmentDoc}
`

/**
 * __useGetPresentationSettingsQuery__
 *
 * To run a query within a React component, call `useGetPresentationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPresentationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPresentationSettingsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetPresentationSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetPresentationSettingsQuery,
    IGetPresentationSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetPresentationSettingsQuery,
    IGetPresentationSettingsQueryVariables
  >(GetPresentationSettingsDocument, options)
}
export function useGetPresentationSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetPresentationSettingsQuery,
    IGetPresentationSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetPresentationSettingsQuery,
    IGetPresentationSettingsQueryVariables
  >(GetPresentationSettingsDocument, options)
}
export type GetPresentationSettingsQueryHookResult = ReturnType<
  typeof useGetPresentationSettingsQuery
>
export type GetPresentationSettingsLazyQueryHookResult = ReturnType<
  typeof useGetPresentationSettingsLazyQuery
>
export type GetPresentationSettingsQueryResult = Apollo.QueryResult<
  IGetPresentationSettingsQuery,
  IGetPresentationSettingsQueryVariables
>
export const SavePresentationSettingsDocument = gql`
  mutation SavePresentationSettings(
    $presentationSettings: PresentationSettingsInput!
  ) {
    savePresentationSettings(presentationSettings: $presentationSettings) {
      ...PresentationSettingsFields
    }
  }
  ${PresentationSettingsFieldsFragmentDoc}
`
export type ISavePresentationSettingsMutationFn = Apollo.MutationFunction<
  ISavePresentationSettingsMutation,
  ISavePresentationSettingsMutationVariables
>

/**
 * __useSavePresentationSettingsMutation__
 *
 * To run a mutation, you first call `useSavePresentationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePresentationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePresentationSettingsMutation, { data, loading, error }] = useSavePresentationSettingsMutation({
 *   variables: {
 *      presentationSettings: // value for 'presentationSettings'
 *   },
 * });
 */
export function useSavePresentationSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISavePresentationSettingsMutation,
    ISavePresentationSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISavePresentationSettingsMutation,
    ISavePresentationSettingsMutationVariables
  >(SavePresentationSettingsDocument, options)
}
export type SavePresentationSettingsMutationHookResult = ReturnType<
  typeof useSavePresentationSettingsMutation
>
export type SavePresentationSettingsMutationResult =
  Apollo.MutationResult<ISavePresentationSettingsMutation>
export type SavePresentationSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    ISavePresentationSettingsMutation,
    ISavePresentationSettingsMutationVariables
  >
export const ListenToPresentationSettingsDocument = gql`
  subscription ListenToPresentationSettings($projectId: ObjectId!) {
    presentationSettings(projectId: $projectId) {
      id
      item {
        ...PresentationSettingsFields
      }
    }
  }
  ${PresentationSettingsFieldsFragmentDoc}
`

/**
 * __useListenToPresentationSettingsSubscription__
 *
 * To run a query within a React component, call `useListenToPresentationSettingsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToPresentationSettingsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToPresentationSettingsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToPresentationSettingsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToPresentationSettingsSubscription,
    IListenToPresentationSettingsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToPresentationSettingsSubscription,
    IListenToPresentationSettingsSubscriptionVariables
  >(ListenToPresentationSettingsDocument, options)
}
export type ListenToPresentationSettingsSubscriptionHookResult = ReturnType<
  typeof useListenToPresentationSettingsSubscription
>
export type ListenToPresentationSettingsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToPresentationSettingsSubscription>
