import * as React from 'react'

import Konva from 'konva'
import { observer } from 'mobx-react'
import { Group, Rect, Text } from 'react-konva'

import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import Colors from '~/client/src/shared/theme.module.scss'

const BORDER_WIDTH = 2

const TEXT_PADDING_X = 4
const TEXT_PADDING_Y = 2
const REFERENCED_Y_OFFSET = 20
const { getHEXColor } = ThemeMode

interface IProps {
  text: string
  fontSize: number
  isTextBoxShown: boolean
  color?: string
  cornerRadius?: number
  fontStyle?: string
}

@observer
export default class KonvaSimpleTextBox extends React.Component<
  IProps & Konva.NodeConfig
> {
  private textBox: Konva.Rect = null
  private text: Konva.Text = null
  private group: Konva.Group = null

  public componentDidMount() {
    this.setTextBoxSizes()
  }

  public componentDidUpdate() {
    this.setTextBoxSizes()
  }

  public render() {
    const {
      text,
      color,
      fontSize,
      isTextBoxShown,
      cornerRadius,
      fontStyle,
      ...rest
    } = this.props

    return (
      <Group {...rest} ref={this.setGroup}>
        {isTextBoxShown && (
          <Rect
            fill={getHEXColor(Colors.neutral100)}
            stroke={getHEXColor(color || Colors.neutral0)}
            strokeWidth={BORDER_WIDTH}
            ref={this.setTextBox}
            cornerRadius={cornerRadius}
          />
        )}
        <Text
          text={text}
          y={TEXT_PADDING_Y}
          x={TEXT_PADDING_X}
          fontSize={fontSize}
          stroke={getHEXColor(Colors.neutral100)}
          fontStyle={fontStyle}
          ref={this.setText}
        />
        <Text
          text={text}
          y={TEXT_PADDING_Y}
          x={TEXT_PADDING_X}
          fontSize={fontSize}
          fill={getHEXColor(color || Colors.neutral0)}
          fontStyle={fontStyle}
        />
      </Group>
    )
  }

  private setTextBoxSizes() {
    if (!this.text) {
      return
    }

    const width = this.text.width() + TEXT_PADDING_X * 2
    const height = this.text.height() + TEXT_PADDING_Y * 2

    if (this.textBox) {
      this.textBox.width(width)
      this.textBox.height(height)
    }

    if (this.group) {
      this.group.offsetX(width / 2)
      this.group.offsetY(height / 2 - REFERENCED_Y_OFFSET)
    }
  }

  private setTextBox = (textBox: Konva.Rect) => {
    this.textBox = textBox
  }

  private setText = (text: Konva.Text) => {
    this.text = text
  }

  private setGroup = (group: Konva.Group) => {
    this.group = group
  }
}
