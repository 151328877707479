import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import { VGap } from '~/client/src/shared/components/Layout'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import DesktopCalendarStore from '../DesktopCalendar.store'
import DesktopCalendarColumn from '../DesktopCalendarColumn'
import DesktopCalendarTotalBarItem from '../DesktopCalendarTotalBarItem'

// localization: translated

export interface IProps {
  store: DesktopCalendarStore
  setHorizontalTotalBarRef(ref: HTMLDivElement): void
}

@observer
export default class HorizontalTotalBar extends React.Component<IProps> {
  public render() {
    const {
      shouldShowFullOffloadingEquipmentBar,
      toggleOffloadingEquipmentBar,
    } = this.props.store
    return (
      <div
        className={classList({
          collapsed: !shouldShowFullOffloadingEquipmentBar,
          'desktop-calendar-view-horizontal-total-bar no-grow row bt-light-grey relative':
            true,
        })}
      >
        {this.renderLeftColumn()}
        <div className="collapse-menu">
          {shouldShowFullOffloadingEquipmentBar ? (
            <Icons.OffloadingEquipmentMenuDown
              onClick={toggleOffloadingEquipmentBar}
            />
          ) : (
            <Icons.OffloadingEquipmentMenuUp
              onClick={toggleOffloadingEquipmentBar}
            />
          )}
        </div>
        {this.renderContent()}
      </div>
    )
  }

  private renderLeftColumn() {
    const {
      visibleTotalBarItems,
      shouldShowFullOffloadingEquipmentBar,
      events,
    } = this.props.store

    return (
      <div
        className={classList({
          'no-grow desktop-calendar-view-horizontal-total-bar-left-column':
            true,
        })}
      >
        <div className="full-height">
          <div className="text bold large">
            {Localization.translator.totalX(events.length)}
          </div>
          {shouldShowFullOffloadingEquipmentBar && (
            <>
              {this.renderUpArrow()}
              {visibleTotalBarItems.map((item, idx) => (
                <div key={idx} className="text bold total-item">
                  {item.title}
                </div>
              ))}
              {this.renderDownArrow()}
            </>
          )}
        </div>
      </div>
    )
  }

  private renderUpArrow() {
    const { currentTotalBarPage, setPrevTotalPage } = this.props.store
    if (currentTotalBarPage <= 0) {
      return <VGap size="16" />
    }

    return (
      <div className="no-grow text center">
        <Icon
          className="arrow-icon"
          icon={IconNames.CHEVRON_UP}
          onClick={setPrevTotalPage}
        />
      </div>
    )
  }

  private renderDownArrow() {
    const { currentTotalBarPage, setNextTotalPage, maxTotalPage } =
      this.props.store
    if (currentTotalBarPage >= maxTotalPage) {
      return <VGap size="16" />
    }

    return (
      <div className="no-grow text center">
        <Icon
          className="arrow-icon"
          icon={IconNames.CHEVRON_DOWN}
          onClick={setNextTotalPage}
        />
      </div>
    )
  }

  private renderContent() {
    const { calendarColumns } = this.props.store

    return (
      <div
        className="desktop-calendar-view-horizontal-total-bar-content row relative"
        ref={this.props.setHorizontalTotalBarRef}
      >
        {calendarColumns.map(this.renderTotalColumn)}
      </div>
    )
  }

  private renderTotalColumn = (
    calendarColumn: DesktopCalendarColumn,
    rowIndex: number,
  ) => {
    const {
      visibleTotalBarItems,
      shouldShowFullOffloadingEquipmentBar,
      isWorkingDay,
    } = this.props.store
    const totalColumnEventsQty = this.getEventsQtyByFilters(calendarColumn)

    return (
      <div
        className={classList({
          'x-axis-column-width col full-height bl-light-grey row y-start': true,
          'bg-palette-brand-lightest': !isWorkingDay(calendarColumn.date),
        })}
        key={rowIndex}
      >
        <div className="full-height col x-center">
          <div className="text bold large">
            <span>{totalColumnEventsQty}</span>
          </div>

          <VGap size="16" />

          {shouldShowFullOffloadingEquipmentBar &&
            visibleTotalBarItems.map((item, idx) => (
              <div key={idx} className="text total-item">
                {this.getEventsQtyByFilters(calendarColumn, item)}
              </div>
            ))}

          <VGap size="16" />
        </div>
      </div>
    )
  }

  private getEventsQtyByFilters(
    column: DesktopCalendarColumn,
    totalBarItem?: DesktopCalendarTotalBarItem,
  ) {
    return this.props.store.events.filter(event => {
      return (
        column.isEventDisplayedOnColumn(event) &&
        (!totalBarItem || totalBarItem.isEventRelatedTo(event))
      )
    }).length
  }
}
