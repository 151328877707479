import { action, observable } from 'mobx'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import { REQUEST_RESET_PASSWORD } from '~/client/src/shared/stores/EventStore/eventConstants'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import Guard from '~/client/src/shared/utils/Guard'

const MESSAGE_DURATION = 3000

export default class ResetPasswordStore {
  @observable public isLoading: boolean
  @observable public resetMessage: string

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly onInvite: () => void,
  ) {
    Guard.requireAll({
      eventsStore,
      userProjectsStore,
    })
  }

  public getActionTitle = (user: User): string => {
    return this.hasUserAcceptedInvite(user)
      ? Localization.translator.resetPassword
      : Localization.translator.sendInviteLinkToUser
  }

  public isActionActiveForUser = (user: User): boolean => {
    if (this.hasUserAcceptedInvite(user)) {
      return !!user.email
    }

    return !!user.email || !!user.phoneNumber
  }

  @action.bound
  public resetPasswordForUserOrInvite(user: User) {
    if (this.hasUserAcceptedInvite(user)) {
      this.isLoading = true
      this.eventsStore.dispatch(
        REQUEST_RESET_PASSWORD,
        user.email,
        this.onResetLinkSent,
        this.onResetLinkSent,
      )
    } else {
      this.onInvite()
    }
  }

  public hasUserAcceptedInvite(user: User): boolean {
    return UserProject.isAccepted(user, this.userProjectsStore)
  }

  @action.bound
  private onResetLinkSent(msg: string) {
    this.resetMessage =
      msg || Localization.translator.passwordResetLinkIsSentToMailbox
    this.isLoading = false

    setTimeout(() => {
      this.resetMessage = null
    }, MESSAGE_DURATION)
  }
}
