import * as React from 'react'

import { observer } from 'mobx-react'

import Logistics from '../Logistics/Logistics'

@observer
export default class Forms extends React.Component {
  public render() {
    return <Logistics isPermitOnly={true} />
  }
}
