import { observable } from 'mobx'

export default class DateInterval {
  @observable public startDate: Date
  @observable public endDate: Date

  public constructor(
    startDate: Date,
    endDate: Date,
    private projectDateStore: any,
  ) {
    this.startDate = startDate
    this.endDate = endDate
  }

  public setStartDate(date: Date) {
    this.startDate = date
  }

  public setEndDate(date: Date) {
    this.endDate = date
  }

  public isIntersect(other: DateInterval) {
    return areIntervalTimesIntersects(this, other)
  }

  public isInHour(date: Date, hour: number) {
    const { setHours } = this.projectDateStore
    const hourStart = setHours(date, hour, 0)
    const hourEnd = setHours(date, hour + 1, 0)
    return this.isIntersect(
      new DateInterval(hourStart, hourEnd, this.projectDateStore),
    )
  }
}

function areIntervalTimesIntersects(
  {
    startDate: startADate,
    endDate: endADate,
  }: { startDate: Date; endDate: Date },
  {
    startDate: startBDate,
    endDate: endBDate,
  }: { startDate: Date; endDate: Date },
) {
  const startATime = startADate.getTime()
  const endATime = endADate.getTime()
  const startBTime = startBDate.getTime()
  const endBTime = endBDate.getTime()

  return (
    (endATime > startBTime && startATime < endBTime) ||
    (endBTime > startATime && startBTime < endATime)
  )
}
