enum SideBarState {
  Default = 'default',
  Flag = 'flag',
  Rfi = 'rfi',
  CategoryOfVariance = 'category-of-variance',
  SafetyHazard = 'safety-hazard',
  Photo = 'photo',
  DeliveryCalendar = 'delivery-calendar',
  DeliveryDetails = 'delivery-details',
  StatusUpdateMessages = 'statusUpdateMessages',
  BulkStatusUpdate = 'bulk-status-update',
  ScheduleComment = 'schedule-comment',
}

export default SideBarState
