import * as React from 'react'

import './EntryLeftCol.scss'

export default function EntryLeftCol({ children, customClass = '' }) {
  return (
    <div className={'activity-log-entry-left-col col no-grow ' + customClass}>
      {children}
    </div>
  )
}
