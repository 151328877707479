import * as React from 'react'

import { inject, observer } from 'mobx-react'

import UsernameFromUid from '~/client/src/shared/components/UsernameFromUid'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'

import BaseNotification from '../../models/Notification'

interface INotificationAuthor {
  notification: BaseNotification
  showCompany?: boolean
  statusUpdatesStore?: StatusUpdatesStore
}

@inject('statusUpdatesStore')
@observer
export default class NotificationAuthor extends React.Component<INotificationAuthor> {
  public render() {
    const { notification, showCompany } = this.props

    return (
      <UsernameFromUid
        userId={notification.authorId}
        showCompany={showCompany}
      />
    )
  }
}
