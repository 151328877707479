import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopActivityDetails from '~/client/src/desktop/components/ActivityDetails/ActivityDetails'
import PresentationModeStore from '~/client/src/desktop/stores/ui/PresentationMode.store'
import DeliveryDetails from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'
import UnsafeSitePermitCreationForm from '~/client/src/shared/components/SitePermitCreationForm/SitePermitCreationForm'
import UnsafeSitePermitViewForm from '~/client/src/shared/components/SitePermitCreationForm/SitePermitViewForm'
import Announcement from '~/client/src/shared/models/Announcement'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import AnnouncementEditionForm from '../../../components/AnnouncementEditionForm/AnnouncementEditionForm'
import DesktopFileInput from '../../../components/FileInput/DesktopFileInput'
import DesktopInitialState from '../../../stores/DesktopInitialState'
import DeliveryActionSideBase from '../../Deliveries/components/DeliveryActionSideWrapper/components/DeliveryActionSideBase'
import LogisticsStore from '../Logistics.store'
import AnnouncementViewDialog from './AnnouncementViewDialog/AnnouncementViewDialog'
import DesktopLogisticsCalendarStore from './LogisticsCalendar/LogisticsCalendar.store'

const SitePermitCreationForm = withErrorBoundary(UnsafeSitePermitCreationForm)
const SitePermitViewForm = withErrorBoundary(UnsafeSitePermitViewForm)

interface IProps {
  logisticsStore: LogisticsStore
  logisticsCalendarStore: DesktopLogisticsCalendarStore

  state?: DesktopInitialState
  deliveryDetailsStore?: DeliveryDetailsStore
  activitiesStore?: ActivitiesStore
  presentationModeStore?: PresentationModeStore
}

@inject(
  'state',
  'deliveryDetailsStore',
  'activitiesStore',
  'presentationModeStore',
)
@observer
export default class LogisticsDialogs extends React.Component<IProps> {
  public componentDidMount() {
    if (this.props.state.isPresentationMode) return

    this.openSitePermitFromURL()
  }

  public render() {
    const {
      logisticsStore,
      logisticsCalendarStore: { calendarEventDates },
      state,
      deliveryDetailsStore: { displayedDelivery },
      activitiesStore: { selectedActivity },
    } = this.props

    const {
      isAnnouncementCreationActive,
      isAnnouncementDialogDisplayed,
      isPermitCreationActive,
      isPermitDialogDisplayed,
      isDateMoveConfirmModalOpen,
      displayedAnnouncement,
      displayedPermit,
      hideAnnouncementCreationForm,
      hidePermitCreationForm,
      hidePermitDialog,
      hideDeliverySideView,
      hideActivitySideView,
      orderedAnnouncementsOnSelectedDate,
      hideDateMoveConfirmModal,
    } = logisticsStore

    const shouldScroll: boolean = !!state.currentPresentationPage?.entityKey
    const maxScrollTime: number = state.currentPresentationPage?.entityDuration

    return (
      <>
        {isAnnouncementDialogDisplayed && (
          <AnnouncementViewDialog
            announcements={orderedAnnouncementsOnSelectedDate}
            displayedAnnouncement={displayedAnnouncement}
            onClose={this.hideAnnouncementDialog}
            onEdit={this.showCreationAndEditionAnnouncementForm}
            onDelete={this.showRemoveDialog}
            shouldScroll={shouldScroll}
            maxScrollTime={maxScrollTime}
          />
        )}
        {isAnnouncementCreationActive && (
          <AnnouncementEditionForm
            onClose={hideAnnouncementCreationForm}
            displayedAnnouncement={displayedAnnouncement}
          />
        )}
        {isPermitCreationActive && (
          <div className="permit-side-view">
            <SitePermitCreationForm
              close={hidePermitCreationForm}
              FileInputType={DesktopFileInput}
              calendarEventDates={calendarEventDates}
            />
          </div>
        )}
        {isPermitDialogDisplayed && (
          <div className="permit-side-view">
            <SitePermitViewForm
              permitToShow={displayedPermit}
              close={hidePermitDialog}
              FileInputType={DesktopFileInput}
              calendarEventDates={calendarEventDates}
              isDateMoveConfirmModalOpen={isDateMoveConfirmModalOpen}
              hideDateMoveConfirmModal={hideDateMoveConfirmModal}
            />
          </div>
        )}
        {displayedDelivery && (
          <DeliveryActionSideBase
            title={EMPTY_STRING}
            content={
              <DeliveryDetails
                initProjectId={state.activeProject.id}
                FileInputType={DesktopFileInput}
                backClicked={hideDeliverySideView}
                displayedDeliveryId={displayedDelivery?.id}
              />
            }
            shouldShowHeader={false}
          />
        )}
        {selectedActivity?.code && (
          <div className="gant-side-bar opened">
            <DesktopActivityDetails
              activityId={selectedActivity.code}
              backClicked={hideActivitySideView}
            />
          </div>
        )}
      </>
    )
  }

  private openSitePermitFromURL() {
    const { logisticsStore } = this.props
    const { applyPageFromQueryParams, hideAllDialogs } = logisticsStore

    hideAllDialogs()
    applyPageFromQueryParams()
  }

  private hideAnnouncementDialog = () => {
    this.props.presentationModeStore.cancelPresentationEntity()
    this.props.logisticsStore.hideAnnouncementDialog()
  }

  private showCreationAndEditionAnnouncementForm = (
    announcement?: Announcement,
  ) => {
    this.props.presentationModeStore.cancelPresentationEntity()
    this.props.logisticsStore.showCreationAndEditionAnnouncementForm(
      announcement,
    )
  }

  private showRemoveDialog = (announcement: Announcement) => {
    this.props.presentationModeStore.cancelPresentationEntity()
    this.props.logisticsStore.showRemoveDialog(announcement)
  }
}
