import * as React from 'react'

import { inject, observer } from 'mobx-react'

import BaseEntityAssociationStatus from '~/client/src/shared/components/BaseEntityAssociationStatus/BaseEntityAssociationStatus'
import DeliveryAssignmentsStore from '~/client/src/shared/stores/domain/DeliveryAssignments.store'
import DeliveryFollowingsStore from '~/client/src/shared/stores/domain/DeliveryFollowings.store'

// translated

interface IProps {
  deliveryId: string
  className?: string
  isBigStarMode?: boolean

  deliveryFollowingsStore?: DeliveryFollowingsStore
  deliveryAssignmentsStore?: DeliveryAssignmentsStore
}

@inject('deliveryAssignmentsStore', 'deliveryFollowingsStore')
@observer
export default class DeliveryAssociationStatus extends React.Component<IProps> {
  public render() {
    const {
      className,
      deliveryId,
      deliveryFollowingsStore,
      deliveryAssignmentsStore,
      isBigStarMode,
    } = this.props

    return (
      <BaseEntityAssociationStatus
        isBigStarMode={isBigStarMode}
        className={className}
        entityId={deliveryId}
        domainAssignmentsStore={deliveryAssignmentsStore}
        domainFollowingsStore={deliveryFollowingsStore}
      />
    )
  }
}
