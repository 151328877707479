import ProjectDateStore from '../stores/ui/ProjectDate.store'

export default class Day {
  private static isoStringCache = {}
  public static Empty = new Day(null, null)

  public static fromMs(ms: number, projectDateStore: ProjectDateStore): Day {
    return new Day(new Date(ms), projectDateStore)
  }

  public static fromIsoString(
    date: string,
    projectDateStore: ProjectDateStore,
  ): Day {
    if (!date) {
      return Day.Empty
    }
    if (!this.isoStringCache[date]) {
      this.isoStringCache[date] = new Day(
        projectDateStore.fromIsoString(date),
        projectDateStore,
      )
    }

    return this.isoStringCache[date]
  }

  public date: Date
  public year: number
  public month: number
  public shortMonthName: string
  public day: number
  public ms: number
  public formatedDate: string
  public monthAndDay: string

  public constructor(date: Date, projectDateStore: ProjectDateStore) {
    this.date = date
    if (date) {
      const [year, monthNum, day] = projectDateStore.getYearMonthDay(date)
      this.year = year
      this.month = monthNum
      this.shortMonthName = projectDateStore.getMonthToDisplay(date)
      this.day = day
      this.ms = date.getTime()
      this.formatedDate = projectDateStore.getMonthDayAndYearToDisplay(date)
      this.monthAndDay = projectDateStore.getMonthAndDayToDisplay(date)
    } else {
      this.year = NaN
      this.month = NaN
      this.day = NaN
      this.ms = NaN
    }
  }
}
