import { computed } from 'mobx'

import ClosureStatus from '~/client/src/shared/enums/ClosureStatus'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import AnnouncementsStore from '~/client/src/shared/stores/domain/Announcements.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import ProjectDateStore, {
  areIntervalTimesIntersects,
} from '~/client/src/shared/stores/ui/ProjectDate.store'

import Activity from '../../models/Activity'
import Announcement from '../../models/Announcement'
import Closure from '../../models/Closure'
import Delivery from '../../models/Delivery'
import ILogisticItem, { LogisticItemApp } from '../../models/ILogisticItem'
import IMaturixCastMonitoringLocationPair from '../../models/IMaturixCastMonitoringLocationPair'
import LocationBase from '../../models/LocationObjects/LocationBase'
import SitePermit from '../../models/Permit'
import { NO_VALUE } from '../../utils/usefulStrings'
import EventsStore from '../EventStore/Events.store'
import { IFilters } from '../InitialState'
import ActivityFollowingsStore from '../domain/ActivityFollowings.store'
import AnnouncementFollowingsStore from '../domain/AnnouncementFollowings.store'
import CastFollowingsStore from '../domain/CastFollowings.store'
import ClosureFollowingsStore from '../domain/ClosureFollowings.store'
import ClosuresStore from '../domain/Closures.store'
import CompaniesStore from '../domain/Companies.store'
import DeliveryFollowingsStore from '../domain/DeliveryFollowings.store'
import GlobeViewsStore from '../domain/GlobeViews.store'
import LocationAttributesStore from '../domain/LocationAttributes.store'
import MaturixCastsStore from '../domain/MaturixStores/MaturixCasts.store'
import PermitTypesStore from '../domain/PermitTypes.store'
import SitePermitFollowingsStore from '../domain/SitePermitFollowings.store'
import SitemapsStore from '../domain/Sitemaps.store'

export default abstract class BaseLogisticsStore {
  public constructor(
    protected readonly announcementsStore: AnnouncementsStore,
    protected readonly sitePermitsStore: SitePermitsStore,
    protected readonly projectDateStore: ProjectDateStore,
    protected readonly deliveriesStore: DeliveriesStore,
    protected readonly activitiesStore: ActivitiesStore,
    protected readonly statusUpdatesStore: StatusUpdatesStore,
    protected readonly eventsStore: EventsStore,
    protected readonly sitemapsStore: SitemapsStore,
    protected readonly globeViewsStore: GlobeViewsStore,
    protected readonly maturixCastsStore: MaturixCastsStore,
    protected readonly deliveryFollowingsStore: DeliveryFollowingsStore,
    protected readonly sitePermitFollowingsStore: SitePermitFollowingsStore,
    protected readonly activityFollowingsStore: ActivityFollowingsStore,
    protected readonly closureFollowingsStore: ClosureFollowingsStore,
    protected readonly announcementFollowingsStore: AnnouncementFollowingsStore,
    protected readonly castFollowingsStore: CastFollowingsStore,
    protected readonly permitTypesStore: PermitTypesStore,
    protected readonly locationAttributesStore: LocationAttributesStore,
    protected readonly companiesStore: CompaniesStore,
    protected readonly closuresStore: ClosuresStore,
    protected readonly isPermitOnly?: boolean,
  ) {}

  public abstract get filters(): IFilters

  protected abstract get isCalendarViewMode(): boolean

  @computed
  public get isMapViewDisabled(): boolean {
    // until we receive all the data, we cannot switch the default view mode
    if (
      !this.sitemapsStore.isDataReceived ||
      !this.globeViewsStore.isDataReceived
    ) {
      return false
    }

    const isAppConfigurationReceived = this.isPermitOnly
      ? this.state.forms.isDataReceived
      : this.state.logistics.isDataReceived

    if (!isAppConfigurationReceived) {
      return false
    }

    const isThereAppAssociatedMap = this.isPermitOnly
      ? this.state.isThereFormsAssociatedMap
      : this.state.isThereLogisticsAssociatedMap

    return !isThereAppAssociatedMap
  }

  @computed
  public get allLogistics(): ILogisticItem[] {
    if (this.isPermitOnly) {
      return this.convertEntitiesToLogistics(this.allForms)
    }

    return this.convertEntitiesToLogistics(
      this.allForms,
      this.allClosures,
      this.allSensors,
      this.allAnnouncements,
      this.allDeliveries,
      this.allActivities,
    )
  }

  public convertFormsToLogistics = (forms: SitePermit[]): ILogisticItem[] => {
    return forms.map<ILogisticItem>(form => ({
      id: form.id,
      entityId: form.id,
      app: LogisticItemApp.FORM,
      name: form.getCaption(this.permitTypesStore),
      locations: this.getLocationsFromIds(form.locations.map(l => l.id)),
      equipment: this.getLocationsFromIds(form.equipment.map(l => l.id)),
      status: form.status,
      isStatusLate: form.isLate,
      workflowStepLevel: form.workflowStepLevel,
      companyIds: form.companyIds || [],
      startDate: form.startDate,
      type: form.getTypeOfPermitType(this.permitTypesStore),
      endDate: form.endDate,
      responsibleContactIds: form.requesterIds || [],
    }))
  }

  public convertEntitiesToLogistics = (
    forms: SitePermit[],
    closures: Closure[] = [],
    sensors: IMaturixCastMonitoringLocationPair[] = [],
    announcements: Announcement[] = [],
    deliveries: Delivery[] = [],
    activities: Activity[] = [],
  ): ILogisticItem[] => {
    const _forms = forms.map<ILogisticItem>(form => ({
      id: form.id,
      entityId: form.id,
      app: LogisticItemApp.FORM,
      type: form.getTypeOfPermitType(this.permitTypesStore),
      name: form.getCaption(this.permitTypesStore),
      locations: this.getLocationsFromIds(form.locations.map(l => l.id)),
      equipment: this.getLocationsFromIds(form.equipment.map(l => l.id)),
      status: form.status,
      isStatusLate: form.isLate,
      workflowStepLevel: form.workflowStepLevel,
      companyIds: form.companyIds || [],
      startDate: form.startDate,
      endDate: form.endDate,
      responsibleContactIds: form.requesterIds || [],
    }))

    if (this.isPermitOnly) {
      return _forms
    }

    const _closures = closures
      .map<ILogisticItem>(closure => {
        const location: LocationBase =
          this.closuresStore.getLocationByClosure(closure)

        if (!location) return

        let companyIds: string[] = []
        let requesterIds: string[] = []

        if (closure.associatedPermitId) {
          const associatedPermit = this.sitePermitsStore.getFormById(
            closure.associatedPermitId,
          )
          companyIds = associatedPermit?.companyIds || []
          requesterIds = associatedPermit?.requesterIds || []
        }

        const isOpened = closure.isOpenByDate(this.projectDateStore, new Date())

        return {
          id: closure.id,
          app: LogisticItemApp.CLOSURE,
          entityId: closure.id,
          name: NO_VALUE,
          locations: [location],
          equipment: [],
          status: isOpened ? ClosureStatus.OPENED : ClosureStatus.CLOSED,
          isOpened,
          companyIds: companyIds,
          startDate: closure.closureInterval.startDate,
          endDate: closure.closureInterval.endDate,
          responsibleContactIds: requesterIds,
        }
      })
      .filter(c => !!c)

    const _sensors = sensors.map<ILogisticItem>(sensor => ({
      id: sensor.id,
      app: LogisticItemApp.SENSOR,
      name: sensor.monitoring.castName,
      entityId: sensor.castId,
      companyIds: [],
      responsibleContactIds: [],
      locations: sensor.location ? [sensor.location] : [],
      equipment: [],
      status: `${sensor.monitoring.status
        .at(0)
        .toUpperCase()}${sensor.monitoring.status.slice(1)}`,
      startDate: sensor.monitoring.lastReading,
      endDate: sensor.monitoring.lastReading,
    }))

    const _announcements = announcements.map<ILogisticItem>(announcement => {
      const location = this.getLocationById(announcement.location?.id)
      return {
        id: announcement.id,
        app: LogisticItemApp.ANNOUNCEMENT,
        name: announcement.getDisplayedName(),
        entityId: announcement.id,
        locations: location ? [location] : [],
        equipment: [],
        status: null,
        companyIds: [],
        startDate: announcement.startDate,
        endDate: announcement.endDate,
        responsibleContactIds: [],
      }
    })

    const _deliveries = deliveries.map<ILogisticItem>(delivery => ({
      id: delivery.id,
      entityId: delivery.id,
      app: LogisticItemApp.DELIVERY,
      name: delivery.codeToDisplay(this.companiesStore),
      locations: this.getDeliveryLocations(delivery),
      equipment: this.getLocationsFromIds(delivery.offloadingEquipmentIds),
      status: delivery.status,
      companyIds: delivery.company ? [delivery.company] : [],
      startDate: delivery.startDate,
      endDate: delivery.endDate,
      responsibleContactIds: delivery.onSiteContactPersonIds,
    }))

    const _activities = activities.map<ILogisticItem>(activity => ({
      id: activity.id,
      app: LogisticItemApp.SCHEDULE,
      name: activity.name,
      entityId: activity.id,
      locations: this.getLocationsFromIds(activity.locations.map(l => l.id)),
      equipment: [],
      status: activity.status,
      companyIds: activity.assignedCompanyId
        ? [activity.assignedCompanyId]
        : [],
      startDate: activity.plannedStartDate?.getTime(),
      endDate: activity.plannedEndDate?.getTime(),
      responsibleContactIds: activity.requesterId ? [activity.requesterId] : [],
    }))

    return [
      ..._forms,
      ..._closures,
      ..._sensors,
      ..._announcements,
      ..._deliveries,
      ..._activities,
    ]
  }

  @computed
  public get logisticItemsInPeriodInterval(): ILogisticItem[] {
    return this.convertEntitiesToLogistics(
      this.formsInPeriodInterval,
      this.closuresInPeriodInterval,
      this.sensorsInPeriodInterval,
      this.announcementsInPeriodInterval,
      this.deliveriesInPeriodInterval,
      this.activitiesInPeriodInterval,
    )
  }

  @computed
  public get allForms(): SitePermit[] {
    return this.sitePermitsStore.list
  }

  @computed
  public get allAnnouncements(): Announcement[] {
    return this.announcementsStore.list
  }

  @computed
  public get orderedAnnouncementsOnSelectedDate(): Announcement[] {
    const { isSameDay } = this.projectDateStore

    const announcements = this.announcementsStore.getAnnouncementsForDay(
      this.startDate,
    )

    return announcements.sort((a, b) => {
      const selectedDateAOrder = a.orders.find(o =>
        isSameDay(o.date, this.startDate),
      )
      const selectedDateBOrder = b.orders.find(o =>
        isSameDay(o.date, this.startDate),
      )
      const aOrder = selectedDateAOrder
        ? selectedDateAOrder.order
        : announcements.length
      const bOrder = selectedDateBOrder
        ? selectedDateBOrder.order
        : announcements.length

      return aOrder - bOrder
    })
  }

  @computed
  public get allActivities(): Activity[] {
    return this.activitiesStore.list
  }

  @computed
  public get closuresInPeriodInterval(): Closure[] {
    const { startOfDay, endOfDay } = this.projectDateStore
    const startDate = startOfDay(this.startDate)
    const endDate = endOfDay(this.endDate)

    return this.allClosures.filter(closure =>
      closure.areWithinSelectedRange(this.projectDateStore, startDate, endDate),
    )
  }

  @computed
  public get sensorsInPeriodInterval(): IMaturixCastMonitoringLocationPair[] {
    const { startOfDay, endOfDay } = this.projectDateStore
    const startDate = startOfDay(this.startDate)
    const endDate = endOfDay(this.endDate)

    return this.allSensors.filter(sensor =>
      this.isSensorWithinDateRange(
        sensor.monitoring.lastReading,
        sensor.monitoring.lastReading,
        startDate,
        endDate,
      ),
    )
  }

  @computed
  public get allClosures(): Closure[] {
    return this.closuresStore.list
  }

  @computed
  public get allSensors(): IMaturixCastMonitoringLocationPair[] {
    return this.maturixCastsStore.monitoringLocationPairs
  }

  @computed
  public get allDeliveries(): Delivery[] {
    return this.deliveriesStore.availableDeliveries
  }

  @computed
  public get deliveriesInPeriodInterval(): Delivery[] {
    return this.allDeliveries.filter(delivery =>
      delivery.isScheduledWithinRange(this.startDate, this.endDate),
    )
  }

  @computed
  public get formsByCreationDate(): SitePermit[] {
    const { startOfDay, endOfDay } = this.projectDateStore
    const startDate = startOfDay(this.startDate)
    const endDate = endOfDay(this.endDate)

    return this.allForms
      .filter(form => form.isScheduledWithinRange(startDate, endDate))
      .sort((f1, f2) => f2.createdAt - f1.createdAt)
  }

  @computed
  public get formsInPeriodInterval(): SitePermit[] {
    const { startOfDay, endOfDay } = this.projectDateStore
    const startDate = startOfDay(this.startDate)
    const endDate = endOfDay(this.endDate)

    return this.allForms.filter(form =>
      form.isScheduledWithinRange(startDate, endDate),
    )
  }

  @computed
  public get activitiesInPeriodInterval(): Activity[] {
    const { startOfDay, endOfDay } = this.projectDateStore
    const startDate = startOfDay(this.startDate)
    const endDate = endOfDay(this.endDate)

    return this.allActivities.filter(activity =>
      activity.isPlannedWithinRange(startDate, endDate),
    )
  }

  @computed
  public get announcementsInPeriodInterval(): Announcement[] {
    const { startOfDay, endOfDay } = this.projectDateStore
    const startDate = startOfDay(this.startDate)
    const endDate = endOfDay(this.endDate)

    return this.allAnnouncements.filter(announcement =>
      announcement.isScheduledWithinRange(startDate, endDate),
    )
  }

  public getFormById = (id: string): SitePermit => {
    return this.sitePermitsStore.getFormById(id)
  }

  public get startDate(): Date {
    return new Date()
  }

  public get endDate(): Date {
    return new Date()
  }

  public get state() {
    return this.eventsStore.appState
  }

  public get isCreationAvailable(): boolean {
    const { isFormsDisabled, userActiveProjectSettings } =
      this.eventsStore.appState

    return !isFormsDisabled || userActiveProjectSettings?.isAdmin
  }

  protected getLocationById = (id: string): LocationBase => {
    return this.locationAttributesStore?.getById(id)
  }

  protected getDeliveryLocations = (delivery: Delivery): LocationBase[] => {
    const locations = []
    const deliveryLocationsKeys = [
      'building',
      'level',
      'area',
      'zone',
      'route',
      'gate',
    ]

    deliveryLocationsKeys.forEach(locationType => {
      const location = this.getLocationById(delivery[locationType])
      if (location) {
        locations.push(location)
      }
    })

    return locations
  }

  private isSensorWithinDateRange = (
    sensorStartDate: Date | number,
    sensorEndDate: Date | number,
    selectedStartDate: Date,
    selectedEndDate: Date,
  ): boolean => {
    return areIntervalTimesIntersects(
      {
        startDate: new Date(sensorStartDate),
        endDate: new Date(sensorEndDate),
      },
      {
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      },
    )
  }

  protected getLocationsFromIds = (ids: string[]): LocationBase[] => {
    return (
      ids.reduce((list, id) => {
        const equipment = this.getLocationById(id)
        if (equipment) {
          list.push(equipment)
        }
        return list
      }, []) || []
    )
  }
}
