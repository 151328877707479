import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import {
  PresentationScreenEntityKey,
  PresentationScreenKey,
} from '~/client/graph'
import DraggableGrid from '~/client/src/desktop/components/DraggableGrid/DraggableGrid'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import PresentationModeStore from '~/client/src/desktop/stores/ui/PresentationMode.store'
import { Loader } from '~/client/src/shared/components/Loader'
import NumberSelector from '~/client/src/shared/components/NumberSelector/NumberSelector'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'

import PresentationSetUpStore from './PresentationSetUp.store'
import ConfirmationPresentationDialog from './components/ConfirmationPresentationDialog'
import PresentationLastUpdated from './components/PresentationLastUpdated'
import PresentationModeHeader from './components/PresentationModeHeader'
import PresentationScreenComponent from './components/PresentationScreen'

import './PresentationMode.scss'

export interface IProps {
  state?: DesktopInitialState
  eventsStore?: DesktopEventStore
  presentationModeStore?: PresentationModeStore
}

const SCREEN_DATA_MAP = {
  [PresentationScreenKey.Gantt]: {
    titleTranslatorKey: KnownTranslatorKeys.schedule,
    descriptionTranslatorKey:
      KnownTranslatorKeys.presentationModeDescriptions_gantt,
    imageSrc: '/static/images/gantt-screen.png',
  },
  [PresentationScreenKey.Deliveries]: {
    titleTranslatorKey: KnownTranslatorKeys.deliveries,
    descriptionTranslatorKey:
      KnownTranslatorKeys.presentationModeDescriptions_deliveries,
    imageSrc: '/static/images/deliveries-screen.png',
  },
  [PresentationScreenKey.Logistics]: {
    titleTranslatorKey: KnownTranslatorKeys.home,
    descriptionTranslatorKey:
      KnownTranslatorKeys.presentationModeDescriptions_logistics,
    descriptionEntityTranslatorKey:
      KnownTranslatorKeys.presentationModeDescriptions_logisticsEntity,
    imageSrc: '/static/images/logistics-screen.png',
    isEntityTogglingEnabled: true,
    entityType: PresentationScreenEntityKey.Announcement,
  },
  [PresentationScreenKey.Forms]: {
    titleTranslatorKey: KnownTranslatorKeys.forms,
    descriptionTranslatorKey: KnownTranslatorKeys.forms,
    imageSrc: '/static/images/logistics-screen.png',
  },
}

@inject('state', 'eventsStore', 'presentationModeStore')
@observer
export default class PresentationMode extends React.Component<IProps> {
  private presentationSetUpStore: PresentationSetUpStore

  public constructor(props: IProps) {
    super(props)
    this.presentationSetUpStore = new PresentationSetUpStore(
      props.state,
      props.eventsStore,
      props.presentationModeStore,
    )
  }

  public render() {
    const { onItemOrderChanged, onInactivityDurationChanged } =
      this.presentationSetUpStore
    const { isLoading } = this.props.state
    const { inactivityDuration } = this.props.state.presentationSettings

    return (
      <div
        className={classList({
          pa30: true,
          'full-height': isLoading,
        })}
      >
        <ConfirmationPresentationDialog store={this.presentationSetUpStore} />
        <PresentationModeHeader
          store={this.presentationSetUpStore}
          isLoading={isLoading}
        />
        {isLoading ? (
          <Loader hint={Localization.translator.loading} />
        ) : (
          <>
            <div className="row mb10">
              <div className="text extra-large bold">
                {Localization.translator.presentationScreens}
              </div>
              <div>
                <PresentationLastUpdated />
              </div>
            </div>
            <div className="mb20">
              <div className="inline-block mr5 text large light">
                {
                  Localization.translator.presentationModeDescriptions
                    .periodOfInactivity
                }
              </div>
              <NumberSelector
                value={inactivityDuration}
                min={1}
                onChange={onInactivityDurationChanged}
                numberFormatter={this.getMinutesNumberString}
              />
            </div>
            <DraggableGrid
              items={this.screenComponents}
              onDragEnd={onItemOrderChanged}
            />
          </>
        )}
      </div>
    )
  }

  @computed
  private get screenComponents() {
    return this.presentationSetUpStore.availableScreens.map(screen => (
      <PresentationScreenComponent
        screen={screen}
        store={this.presentationSetUpStore}
        {...SCREEN_DATA_MAP[screen.key]}
      />
    ))
  }

  private getMinutesNumberString(value: number) {
    return Localization.translator.xMinutes(value)
  }
}
