export const NO_VALUE = '–'
export const MULTIPLICATION = '×'
export const NO_SPECIFIED = 'Unspecified'
export const EMPTY_DATE_VALUE = '(-)'
export const EMPTY_STRING = ''
export const BULLET = '•'
export const PERIOD = '.'
export const INTERPUNCT = '·'
export const NONE_COUNT_LABEL = 'None'
export const NEW_DELIVERY_PATH_KEYWORD = 'new'
export const LOCATION_SEPARATOR = '|'
export const QR_CODE_LOGO_SRC =
  '/static/icons/strux-hub-dark-with-background.svg'
export const FORMS_REPORT_TEMPLATE_ID = 'forms_list_report_api'
export const DELIVERIES_REPORT_TEMPLATE_ID = 'deliveries_list_report_api'
