import * as React from 'react'

import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import Level from '~/client/src/shared/models/LocationObjects/Level'

const ICON_SIZE = 20
const LEVEL_SEPARATOR = ', '

interface IProps {
  levels: Level[]
}

export default class LevelsList extends React.Component<IProps> {
  public render() {
    const { levels } = this.props
    if (!levels.length) {
      return null
    }

    return (
      <SitemapAttributeTag
        shouldShowAsTag={false}
        dataObject={levels[0]}
        iconSize={ICON_SIZE}
        contentContainerClassName="text-ellipsis"
      >
        <div className="text extra-large ellipsis parent-tag">
          {levels.map(l => l.name).join(LEVEL_SEPARATOR)}
        </div>
      </SitemapAttributeTag>
    )
  }
}
