import { action, computed, observable } from 'mobx'

import { EMPTY_STRING } from '../../../utils/usefulStrings'

import Colors from '~/client/src/shared/theme.module.scss'

export default class StruxhubTagsInputStore {
  @observable public values: string[] = []
  @observable public isActive: boolean = false
  @observable public inputValue: string = ''
  @observable public selectedColor: string = Colors.neutral0

  public constructor(
    private readonly onChange: (newValues: string[]) => void,
    private readonly isSingleSelectionMode: boolean = false,
    values: string[],
  ) {
    this.init(values)
  }

  public init(values: string[]) {
    this.values = values || []
    this.clearInput()
  }

  public get shouldShowInput(): boolean {
    return this.isSingleSelectionMode ? !this.isThereSelectedValue : true
  }

  @action.bound
  public activate() {
    this.isActive = true
  }

  @action.bound
  public deactivate() {
    this.isActive = false
  }

  @action.bound
  public toggleActivation() {
    this.isActive = !this.isActive
  }

  @action.bound
  public changeInputValue(newValue: string) {
    this.inputValue = newValue
  }

  @action.bound
  public deleteValue(value: string) {
    const newValues = this.values.filter(v => v !== value)
    this.change(newValues)
  }

  @action.bound
  public setCurrentValues() {
    this.change(
      this.inputValue ? [...this.values, this.inputValue] : this.values,
    )
  }

  @action.bound
  private async change(newValues: string[]) {
    this.onChange(newValues)
  }

  @computed
  private get isThereSelectedValue(): boolean {
    return !!this.values.length
  }

  @action.bound
  private clearInput() {
    this.inputValue = EMPTY_STRING
  }
}
