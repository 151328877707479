import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '../../shared/localization/LocalizationManager'
import { UNASSIGNED_FILTER_VALUE } from '../../shared/utils/ZoneLevelLocationConstants'
import { EMPTY_STRING } from '../../shared/utils/usefulStrings'

// localization: translated

interface IProps {
  name: string
  count?: number
  hideCountLabel?: boolean
  style?: React.CSSProperties
  className?: string
}

@observer
export default class FilterLabel extends React.Component<IProps> {
  public static defaultProps = {
    style: {},
    hideCountLabel: false,
    className: EMPTY_STRING,
  }

  public render() {
    const { name, count, className, style, hideCountLabel } = this.props

    const isDisabled = !count

    return (
      <span className={`ml10 filter-label ${className}`} style={style}>
        <span
          className={classList({
            'filter-label-title': true,
            disabled: isDisabled,
          })}
        >
          {name === UNASSIGNED_FILTER_VALUE
            ? Localization.translator.unassigned
            : name}
        </span>
        {!isDisabled && !hideCountLabel && (
          <span className="filter-label-count">({count})</span>
        )}
      </span>
    )
  }
}
