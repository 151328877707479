import { SaveZonesDocument } from '~/client/graph/operations/generated/LocationType.generated'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import Zone from '~/client/src/shared/models/LocationObjects/Zone'
import ILocationAttributeDto from '~/client/src/shared/types/ILocationAttributeDto'

import LocationAttributesStoreBase from './LocationAttributesBase.store'

export default class ZonesStore extends LocationAttributesStoreBase<Zone> {
  protected saveMutation = SaveZonesDocument
  protected graphName = 'zones'
  protected defaultIconName = MapViewLocationIcon.Zone

  public get byId() {
    const { zones } = this.eventsStore.appState
    return zones
  }

  protected fromDto(dto: ILocationAttributeDto): Zone {
    return Zone.fromDto(dto)
  }
}
