import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import BaseStruxhubInput, { ISharedProps } from './BaseStruxhubInput'
import CaretDownSelectorIcon from './StruxhubSelector/CaretDownSelectorIcon'

// localization: no text to translate

interface IProps extends ISharedProps {
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void

  name?: string
}

@observer
export default class StruxhubSelect extends React.Component<IProps> {
  public render() {
    const { name, value, isRequired, isDisabled, children } = this.props

    return (
      <BaseStruxhubInput {...this.props}>
        {isValueInvalid => (
          <div className="row bare-select-wrapper">
            <select
              className={classList({
                'pointer row y-center h40 bg-palette-brand-lightest no-outline brada4 py7 pl8 pr24 text ellipsis extra-large capitalize full-width':
                  true,
                'ba-palette-brand-lighter': !isValueInvalid,
                'ba-red': isValueInvalid,
              })}
              name={name}
              value={value}
              onChange={this.onChange}
              required={isRequired}
              disabled={isDisabled}
            >
              {children}
            </select>
            <CaretDownSelectorIcon />
          </div>
        )}
      </BaseStruxhubInput>
    )
  }

  private onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { onChange, isDisabled, isReadOnly } = this.props
    if (!isReadOnly && !isDisabled) {
      onChange?.(event)
    }
  }
}
