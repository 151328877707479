import * as React from 'react'

import { observer } from 'mobx-react'

import { IPermitTypeField } from '~/client/graph'
import { getBullet } from '~/client/src/shared/utils/PermitInstructionsHelper'

interface IProps {
  typeField: IPermitTypeField
}

const PermitFormInstructionsField = observer(({ typeField }: IProps) => {
  const { list, listType } = typeField?.instructions || {}

  return (
    <div className="px12">
      {(list || []).map((instruction, i) => (
        <div key={i} className="row mb15">
          <span className="text large bold blue-highlight no-grow mr12 as-start">
            {getBullet(listType, i + 1)}
          </span>
          <span className="text large">{instruction}</span>
        </div>
      ))}
    </div>
  )
})

export default PermitFormInstructionsField
