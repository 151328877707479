import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import Linkify from 'react-linkify'

interface IProps {
  value?: string
  linkClassName?: string
}

@observer
export default class Linkifier extends React.Component<IProps> {
  public render() {
    const { value, children } = this.props

    return (
      <Linkify componentDecorator={this.renderLinks}>
        {value || children}
      </Linkify>
    )
  }

  private renderLinks = (
    decoratedHref: string,
    decoratedText: string,
    key: number,
  ): JSX.Element => {
    const { linkClassName } = this.props

    return (
      <a
        className={classList({
          pointer: true,
          [linkClassName]: !!linkClassName,
        })}
        target="_blank"
        href={decoratedHref}
        key={key}
        onClick={this.stopPropagation}
      >
        {decoratedText}
      </a>
    )
  }

  private stopPropagation = (event: React.SyntheticEvent) => {
    event?.stopPropagation()
  }
}
