import { observable } from 'mobx'

import ILocationDto from '~/client/src/shared/types/ILocationDto'

import LocationAttributeBase from './LocationAttributeBase'

export interface ILocationBaseWithDimensions extends ILocationDto {
  maxHeight?: number
  maxWidth?: number
  maxWeight?: number
}

export default abstract class LocationBaseWithDimensions<
  T extends LocationBaseWithDimensions<T> = any,
> extends LocationAttributeBase<LocationBaseWithDimensions> {
  @observable public maxHeight: number
  @observable public maxWidth: number
  @observable public maxWeight: number

  public isEqual(dto: T) {
    return (
      super.isEqual(dto) &&
      this.maxHeight === dto?.maxHeight &&
      this.maxWidth === dto?.maxWidth &&
      this.maxWeight === dto?.maxWeight
    )
  }

  public copy(): T {
    const item = super.copy()

    item.maxHeight = this.maxHeight
    item.maxWidth = this.maxWidth
    item.maxWeight = this.maxWeight

    return item as T
  }

  public getDto(): any {
    const dto: ILocationBaseWithDimensions = super.getDto()

    dto.maxHeight = this.maxHeight
    dto.maxWidth = this.maxWidth
    dto.maxWeight = this.maxWeight

    return dto
  }
}
