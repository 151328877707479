import { LocationType } from '~/client/graph'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ILocationDto from '~/client/src/shared/types/ILocationDto'

import FieldIds from '../../enums/DeliveryFieldIds'
import LocationAttributeBase from './LocationAttributeBase'

export default class Staging extends LocationAttributeBase<Staging> {
  public static fromDto(dto: ILocationDto) {
    return LocationAttributeBase.factory(
      Staging,
      dto,
      MapViewLocationIcon.Staging,
    )
  }

  public type = LocationType.Staging
  protected relatedFieldId = FieldIds.STAGING
  protected model = Staging
}
