import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ISitePermitBicInspectionFieldsFragment = Pick<
  Types.ISitePermitBicInspection,
  | 'createdAt'
  | 'updatedAt'
  | 'id'
  | 'permitId'
  | 'projectId'
  | 'userId'
  | 'isPassed'
>

export type IGetSitePermitBicInspectionListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  permitId?: Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IGetSitePermitBicInspectionListQuery = {
  sitePermitBicInspections?: Types.Maybe<{
    data: Array<ISitePermitBicInspectionFieldsFragment>
  }>
}

export type IListenToSitePermitBicInspectionSubscriptionVariables =
  Types.Exact<{
    projectId: Types.Scalars['ObjectId']
    permitId?: Types.Maybe<Types.Scalars['ObjectId']>
  }>

export type IListenToSitePermitBicInspectionSubscription = {
  sitePermitBicInspection?: Types.Maybe<
    Pick<Types.ISitePermitBicInspectionChangeEvent, 'id'> & {
      item?: Types.Maybe<ISitePermitBicInspectionFieldsFragment>
    }
  >
}

export type ISaveSitePermitBicInspectionMutationVariables = Types.Exact<{
  sitePermitBicInspection: Types.ISitePermitBicInspectionInput
}>

export type ISaveSitePermitBicInspectionMutation = {
  saveSitePermitBicInspection?: Types.Maybe<ISitePermitBicInspectionFieldsFragment>
}

export const SitePermitBicInspectionFieldsFragmentDoc = gql`
  fragment SitePermitBicInspectionFields on SitePermitBicInspection {
    createdAt
    updatedAt
    id
    permitId
    projectId
    userId
    isPassed
  }
`
export const GetSitePermitBicInspectionListDocument = gql`
  query GetSitePermitBicInspectionList(
    $projectId: ObjectId!
    $permitId: ObjectId
  ) {
    sitePermitBicInspections(
      limit: 1000000
      projectId: $projectId
      permitId: $permitId
    ) {
      data {
        ...SitePermitBicInspectionFields
      }
    }
  }
  ${SitePermitBicInspectionFieldsFragmentDoc}
`

/**
 * __useGetSitePermitBicInspectionListQuery__
 *
 * To run a query within a React component, call `useGetSitePermitBicInspectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitePermitBicInspectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitePermitBicInspectionListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      permitId: // value for 'permitId'
 *   },
 * });
 */
export function useGetSitePermitBicInspectionListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetSitePermitBicInspectionListQuery,
    IGetSitePermitBicInspectionListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetSitePermitBicInspectionListQuery,
    IGetSitePermitBicInspectionListQueryVariables
  >(GetSitePermitBicInspectionListDocument, options)
}
export function useGetSitePermitBicInspectionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetSitePermitBicInspectionListQuery,
    IGetSitePermitBicInspectionListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetSitePermitBicInspectionListQuery,
    IGetSitePermitBicInspectionListQueryVariables
  >(GetSitePermitBicInspectionListDocument, options)
}
export type GetSitePermitBicInspectionListQueryHookResult = ReturnType<
  typeof useGetSitePermitBicInspectionListQuery
>
export type GetSitePermitBicInspectionListLazyQueryHookResult = ReturnType<
  typeof useGetSitePermitBicInspectionListLazyQuery
>
export type GetSitePermitBicInspectionListQueryResult = Apollo.QueryResult<
  IGetSitePermitBicInspectionListQuery,
  IGetSitePermitBicInspectionListQueryVariables
>
export const ListenToSitePermitBicInspectionDocument = gql`
  subscription ListenToSitePermitBicInspection(
    $projectId: ObjectId!
    $permitId: ObjectId
  ) {
    sitePermitBicInspection(projectId: $projectId, permitId: $permitId) {
      item {
        ...SitePermitBicInspectionFields
      }
      id
    }
  }
  ${SitePermitBicInspectionFieldsFragmentDoc}
`

/**
 * __useListenToSitePermitBicInspectionSubscription__
 *
 * To run a query within a React component, call `useListenToSitePermitBicInspectionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToSitePermitBicInspectionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToSitePermitBicInspectionSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      permitId: // value for 'permitId'
 *   },
 * });
 */
export function useListenToSitePermitBicInspectionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToSitePermitBicInspectionSubscription,
    IListenToSitePermitBicInspectionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToSitePermitBicInspectionSubscription,
    IListenToSitePermitBicInspectionSubscriptionVariables
  >(ListenToSitePermitBicInspectionDocument, options)
}
export type ListenToSitePermitBicInspectionSubscriptionHookResult = ReturnType<
  typeof useListenToSitePermitBicInspectionSubscription
>
export type ListenToSitePermitBicInspectionSubscriptionResult =
  Apollo.SubscriptionResult<IListenToSitePermitBicInspectionSubscription>
export const SaveSitePermitBicInspectionDocument = gql`
  mutation SaveSitePermitBicInspection(
    $sitePermitBicInspection: SitePermitBicInspectionInput!
  ) {
    saveSitePermitBicInspection(
      sitePermitBicInspection: $sitePermitBicInspection
    ) {
      ...SitePermitBicInspectionFields
    }
  }
  ${SitePermitBicInspectionFieldsFragmentDoc}
`
export type ISaveSitePermitBicInspectionMutationFn = Apollo.MutationFunction<
  ISaveSitePermitBicInspectionMutation,
  ISaveSitePermitBicInspectionMutationVariables
>

/**
 * __useSaveSitePermitBicInspectionMutation__
 *
 * To run a mutation, you first call `useSaveSitePermitBicInspectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSitePermitBicInspectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSitePermitBicInspectionMutation, { data, loading, error }] = useSaveSitePermitBicInspectionMutation({
 *   variables: {
 *      sitePermitBicInspection: // value for 'sitePermitBicInspection'
 *   },
 * });
 */
export function useSaveSitePermitBicInspectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveSitePermitBicInspectionMutation,
    ISaveSitePermitBicInspectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveSitePermitBicInspectionMutation,
    ISaveSitePermitBicInspectionMutationVariables
  >(SaveSitePermitBicInspectionDocument, options)
}
export type SaveSitePermitBicInspectionMutationHookResult = ReturnType<
  typeof useSaveSitePermitBicInspectionMutation
>
export type SaveSitePermitBicInspectionMutationResult =
  Apollo.MutationResult<ISaveSitePermitBicInspectionMutation>
export type SaveSitePermitBicInspectionMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveSitePermitBicInspectionMutation,
    ISaveSitePermitBicInspectionMutationVariables
  >
