import * as React from 'react'

import { Spinner } from '@blueprintjs/core'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IDeliveryStatusChange } from '~/client/graph'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'
import getDeliveryCopyWithNewStatus from '~/client/src/shared/utils/getDeliveryCopyWithNewStatus'

import BaseNotification from '../../models/Notification'
import InitialState from '../../stores/InitialState'
import DeliveryStatusChangesStore from '../../stores/domain/DeliveryStatusChanges.store'
import DeliveryStatusIcon from '../DeliveryIconByStatus'

import './DeliveryNotificationActions.scss'

// translated

export interface IProps {
  notification: BaseNotification
  eventsStore?: EventsStore
  isViewButtonHidden?: boolean
  deliveriesStore?: DeliveriesStore
  common?: CommonStore
  deliveryStatusChangesStore?: DeliveryStatusChangesStore
}

@inject(
  'eventsStore',
  'deliveriesStore',
  'common',
  'deliveryStatusChangesStore',
)
@observer
export default class DeliveryNotificationActions extends React.Component<IProps> {
  @observable private statusChanges: IDeliveryStatusChange[] = []

  public componentDidMount(): void {
    this.setStatusChanges()
  }

  private get appState(): InitialState {
    return this.props.eventsStore.appState
  }

  private get delivery(): Delivery {
    const { entityId } = this.props.notification
    return this.props.deliveriesStore.byId.get(entityId)
  }

  private get isLoading(): boolean {
    return (
      this.appState.loading.get(
        `${e.LOAD_DELIVERY_STATUS_CHANGES}-${this.delivery.id}`,
      ) || this.appState.loading.get(this.delivery.id)
    )
  }

  public render(): JSX.Element {
    return (
      <div className="notification-delivery-actions row no-grow">
        {this.isLoading ? (
          <Spinner size={14} className="mr15" />
        ) : (
          <div className="row">{this.renderStatesLine()}</div>
        )}
        {this.renderActionButtons()}
      </div>
    )
  }

  private get actionButtonCaption(): string {
    switch (this.delivery.nextStatus) {
      case DeliveryStatus.Scheduled:
        return Localization.translator.schedule
      case DeliveryStatus.OnSite:
        return Localization.translator.onSite
      case DeliveryStatus.PassedInspection:
        return Localization.translator.pass_verb
      case DeliveryStatus.IncompleteDone:
      case DeliveryStatus.Done:
        return Localization.translator.done
    }
  }

  private renderActionButtons(): JSX.Element {
    if (this.delivery && this.delivery.status === DeliveryStatus.OnSite) {
      return (
        <div className="row">
          <div
            onClick={this.changeDeliveryStatus.bind(
              this,
              DeliveryStatus.PassedInspection,
            )}
            className={classList({
              'btn btn-primary-blue': true,
              loading: this.isLoading,
            })}
          >
            {Localization.translator.passed}
            {this.isLoading && <Spinner size={14} />}
          </div>
          <div
            onClick={this.changeDeliveryStatus.bind(
              this,
              DeliveryStatus.FailedInspection,
            )}
            className={classList({
              'btn btn-primary-blue': true,
              loading: this.isLoading,
            })}
          >
            {Localization.translator.failed}
            {this.isLoading && <Spinner size={14} />}
          </div>
        </div>
      )
    }

    return (
      this.delivery &&
      this.delivery.canUserChangeStatus(
        this.delivery.nextStatus,
        this.appState.userActiveProjectSettings,
      ) && (
        <div
          onClick={this.changeDeliveryStatus.bind(this, null)}
          className={classList({
            'btn btn-primary-blue': true,
            loading: this.isLoading,
          })}
        >
          {this.actionButtonCaption}
          {this.isLoading && <Spinner size={14} />}
        </div>
      )
    )
  }

  private renderStatesLine(): JSX.Element[] {
    return this.deliveryStatusChanges.map((status, index) => {
      return (
        <React.Fragment key={index}>
          <DeliveryStatusIcon status={status} />
          {this.shouldAddLine(index) && this.statusSeparator()}
        </React.Fragment>
      )
    })
  }

  private shouldAddLine = (index: number): boolean => {
    if (index !== this.deliveryStatusChanges.length - 1) {
      return true
    }
    return this.delivery.canUserChangeStatus(
      this.delivery.nextStatus,
      this.appState.userActiveProjectSettings,
    )
  }

  @computed
  private get deliveryStatusChanges(): DeliveryStatus[] {
    if (this.statusChanges.length) {
      const statusChangesSet = new Set(
        this.statusChanges.reverse().map(statusChange => statusChange.status),
      )

      return [...statusChangesSet].reverse()
    }
    return this.delivery ? [this.delivery.status] : []
  }

  private statusSeparator() {
    return <div className="w25 ml10 mr10 status-separator" />
  }

  @action.bound
  private changeDeliveryStatus(
    nextDeliveryStatus: DeliveryStatus,
    event: React.MouseEvent<HTMLElement>,
  ): void {
    event.stopPropagation()
    event.preventDefault()

    const nextStatus = nextDeliveryStatus || this.delivery.nextStatus

    if (
      !this.isLoading &&
      this.delivery &&
      this.delivery.canUserChangeStatus(
        nextStatus,
        this.appState.userActiveProjectSettings,
      )
    ) {
      this.props.deliveriesStore.updateDelivery(
        getDeliveryCopyWithNewStatus(this.delivery, nextStatus),
        null,
        true,
      )
    }
  }

  private setStatusChanges(): void {
    const {
      deliveryStatusChangesStore,
      notification: { entityId },
    } = this.props

    deliveryStatusChangesStore
      .loadDeliveriesStatusChanges(entityId)
      .then(() => {
        this.statusChanges = deliveryStatusChangesStore
          .getStatusChangesForDelivery(entityId)
          .slice()
      })
  }
}
