import { EffectMap } from '~/client/src/shared/stores/EventStore/BaseEvents.store'
import EffectsProcessor from '~/client/src/shared/stores/EventStore/EffectsProcessors/EffectsProcessor'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import EventTypes from '~/client/src/shared/stores/EventStore/eventTypes'

import DesktopInitialState from '../DesktopInitialState'

export type DesktopEventHandler = (
  state: DesktopInitialState,
  ...args: any[]
) => void | EffectMap

export default class DesktopEventsStore extends EventsStore {
  public constructor(
    public effectProcessor: EffectsProcessor,
    public appState: DesktopInitialState,
  ) {
    super(effectProcessor, appState)
  }

  public dispatch(type: EventTypes, ...args: any[]) {
    return super.dispatch.call(this, type, ...args)
  }

  public on(type: EventTypes, handler: DesktopEventHandler) {
    return super.on.call(this, type, handler)
  }
}
