import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

interface IProps {
  itemsCount: number
  color: string
  fillColor: string
  icon: JSX.Element
  onClick?: () => void
  isSelected?: boolean
  left?: number
  right?: number
}

const PIN_PATH_DATA =
  'M10 0C4.485 0 0 4.485 0 10c0 3.94 2.079 6.756 4.238 9.434 2.16 2.677 4.404 5.254 5.147 8.818.053.298.312.515.615.515s.562-.217.615-.515c.743-3.564 2.987-6.141 5.147-8.818C17.922 16.756 20 13.94 20 10c0-5.515-4.485-10-10-10z'

@observer
export default class GlobeViewBasePin extends React.Component<IProps> {
  public render() {
    const {
      itemsCount,
      color,
      fillColor,
      onClick,
      icon,
      isSelected,
      left,
      right,
    } = this.props

    return (
      <div
        className="globe-location absolute col x-center y-center"
        style={{
          bottom: '26px',
          left: left,
          right: right,
        }}
      >
        {itemsCount > 1 && (
          <div
            onClick={onClick}
            style={{
              backgroundColor: fillColor,
              width: 25,
              height: 25,
              bottom: isSelected ? 73 : 68,
              border: `1px solid ${color}`,
              borderRadius: '50%',
            }}
          >
            <div
              className="text header br-rounded row x-center y-center"
              style={{ color: color }}
            >
              {itemsCount}
            </div>
          </div>
        )}
        <div
          onClick={onClick}
          className={classList({
            'row x-center y-center': true,
            'selected-location': isSelected,
          })}
        >
          <svg
            className="globe-pin"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 30"
          >
            <g
              fill={fillColor}
              stroke={color}
              strokeWidth="1px"
              fillRule="evenodd"
              style={{ border: '1px solid white' }}
            >
              <path d={PIN_PATH_DATA} />
            </g>
          </svg>
          {icon}
        </div>
      </div>
    )
  }
}
