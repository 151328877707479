import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'

import { IHistoryItem } from '~/client/graph'
import ProjectSetUpPageStore from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPage.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import Guard from '~/client/src/shared/utils/Guard'
import { ToastTheme, showToast } from '~/client/src/shared/utils/toaster'

const LAST_PAGE_UPDATE_KEY = 'materials'

export default class MaterialsUploadHistoryStore {
  @observable public shouldShowRestoreModal = false
  @observable private selectedRestoreId: string = null

  public constructor(
    private readonly projectSetUpPageStore: ProjectSetUpPageStore,
    private readonly materialsStore: MaterialsStore,
  ) {
    Guard.requireAll({
      projectSetUpPageStore,
      materialsStore,
    })
  }

  public get currentMaterialsUploadId(): string {
    return this.projectSetUpPageStore.currentProject.materialsUploadId
  }

  public get currentMaterialsItem(): IHistoryItem {
    if (
      this.latestMaterialsUpdate?.materialsUploadId ===
      this.currentMaterialsUploadId
    ) {
      return this.latestMaterialsUpdate
    }

    return this.materialsHistory?.find(
      mh => mh.materialsUploadId === this.currentMaterialsUploadId,
    )
  }

  public get materialsHistory(): IHistoryItem[] {
    return this.projectSetUpPageStore.materialsHistory
  }

  public get latestMaterialsUpdate(): IHistoryItem {
    return this.projectSetUpPageStore.getLastPageUpdate(LAST_PAGE_UPDATE_KEY)
  }

  public get historyItemToRestore(): IHistoryItem {
    if (
      this.latestMaterialsUpdate?.materialsUploadId === this.selectedRestoreId
    ) {
      return this.latestMaterialsUpdate
    }

    return this.materialsHistory.find(
      mh => mh.materialsUploadId === this.selectedRestoreId,
    )
  }

  @action.bound
  public showRestoreMaterialsModal(materialsUploadId?: string) {
    this.shouldShowRestoreModal = true
    this.selectedRestoreId = materialsUploadId
  }

  @action.bound
  public hideRestoreMaterialsModal() {
    this.shouldShowRestoreModal = false
    this.selectedRestoreId = null
  }

  public restorePreviousMaterials = async () => {
    const isRestoreSuccessful = await this.materialsStore.restoreMaterials(
      this.selectedRestoreId,
    )
    this.hideRestoreMaterialsModal()
    this.showToastMessage(!isRestoreSuccessful)
  }

  private showToastMessage = (isError: boolean) => {
    showToast(
      isError
        ? Localization.translator.materialsUploadHistory.failedToRestore
        : Localization.translator.materialsUploadHistory.restoredSuccessfully,
      isError ? ToastTheme.ERROR : ToastTheme.SUCCESS,
      isError ? IconNames.ERROR : IconNames.TICK_CIRCLE,
    )
  }
}
