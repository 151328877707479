import * as React from 'react'

import Konva from 'konva'
import { observer } from 'mobx-react'
import { Circle, Group, Line, Path, Rect, Text } from 'react-konva'

import ThemeMode from '../../utils/ThemeModeManager'

import Colors from '~/client/src/shared/theme.module.scss'

const PIN_PATH_DATA =
  'M10 0C4.485 0 0 4.485 0 10c0 3.94 2.079 6.756 4.238 9.434 2.16 2.677 4.404 5.254 5.147 8.818.053.298.312.515.615.515s.562-.217.615-.515c.743-3.564 2.987-6.141 5.147-8.818C17.922 16.756 20 13.94 20 10c0-5.515-4.485-10-10-10z'
const PIN_HEIGHT = 29
const PIN_WIDTH = 20

const PIN_STROKE_WIDTH = 0.6
const PIN_SHADOW_BLUR = 5
const PIN_SHADOW_OFFSET = { x: 0, y: 0 }
const PIN_SHADOW_OPACITY = 0.3
const PIN_SELECTED_SCALE = 1.3
const PIN_DEFAULT_SCALE = 1
const PIN_SLASH_POINTS = [0, 20, 20, 0]

const OFFSET_X = -10
const OFFSET_Y = 10
const TEXT_FONT_SIZE = 14
const ELLIPSE_OFFSET_Y = 18
const ELLIPSE_HEIGHT = 15
const ELLIPSE_WIDTH = 11

const SHADOW_BLUR = 5
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3
const TEXT_OFFSET_Y = 2
const STROKE_WIDTH = 1
const TEXT_OFFSET_X = -4
const RADIUS = 10
const { getHEXColor } = ThemeMode

interface IProps {
  text: string
  color: string
  textColor: string
  isSelected: boolean
  isCanceled: boolean
  shouldRenderCircle?: boolean
}

@observer
export default class KonvaWorkflowBasePin extends React.Component<
  IProps & Konva.NodeConfig
> {
  private text: Konva.Text = null
  private textStroke: Konva.Text = null

  public componentDidMount() {
    this.setTextBoxSizes()
  }

  public componentDidUpdate() {
    this.setTextBoxSizes()
  }

  public render() {
    const {
      shouldRenderCircle: shouldRenderHolder,
      text,
      isSelected,
      isCanceled,
      children,
      ...rest
    } = this.props

    const scaleNumber = isSelected ? PIN_SELECTED_SCALE : PIN_DEFAULT_SCALE
    const shouldRenderEllipse = shouldRenderHolder && text?.length > 1
    const shouldRenderCircle = shouldRenderHolder && text?.length < 2

    return (
      <Group
        {...rest}
        scaleX={scaleNumber}
        scaleY={scaleNumber}
        offsetX={PIN_WIDTH / 2}
        offsetY={PIN_HEIGHT * 1.5}
        isSelected={isSelected}
      >
        {isCanceled && (
          <Line
            points={PIN_SLASH_POINTS}
            strokeWidth={STROKE_WIDTH}
            stroke={getHEXColor(Colors.neutral100)}
          />
        )}
        {shouldRenderCircle && (
          <Circle
            radius={RADIUS}
            fill={this.fillColor}
            stroke={getHEXColor(Colors.neutral100)}
            strokeWidth={STROKE_WIDTH}
            shadowColor={getHEXColor(Colors.neutral0)}
            shadowBlur={SHADOW_BLUR}
            shadowOffset={SHADOW_OFFSET}
            shadowOpacity={SHADOW_OPACITY}
            offsetX={OFFSET_X}
            offsetY={OFFSET_Y}
          />
        )}
        {shouldRenderEllipse && (
          <Rect
            fill={this.fillColor}
            stroke={getHEXColor(Colors.neutral100)}
            strokeWidth={STROKE_WIDTH}
            shadowColor={getHEXColor(Colors.neutral0)}
            shadowBlur={SHADOW_BLUR}
            shadowOffset={SHADOW_OFFSET}
            shadowOpacity={SHADOW_OPACITY}
            offsetY={ELLIPSE_OFFSET_Y}
            height={ELLIPSE_HEIGHT}
            width={ELLIPSE_WIDTH * text.length}
            cornerRadius={RADIUS}
            offsetX={text.length / 2}
          />
        )}
        {text && this.displayedText}
        <Path
          data={PIN_PATH_DATA}
          fill={this.fillColor}
          shadowColor={getHEXColor(Colors.neutral0)}
          shadowBlur={PIN_SHADOW_BLUR}
          shadowOffset={PIN_SHADOW_OFFSET}
          shadowOpacity={PIN_SHADOW_OPACITY}
          stroke={getHEXColor(Colors.neutral100)}
          strokeWidth={PIN_STROKE_WIDTH}
        />
        {children}
      </Group>
    )
  }

  private setTextBoxSizes() {
    if (!this.text) {
      return
    }
    const width = this.text.width() - PIN_WIDTH / 2
    const height = this.text.height() + PIN_HEIGHT / 2

    this.text.offsetY(height / 2 + TEXT_OFFSET_Y)
    this.text.offsetX(width / 2 + TEXT_OFFSET_X)
    if (this.textStroke) {
      this.textStroke.offsetY(height / 2 + TEXT_OFFSET_Y)
      this.textStroke.offsetX(width / 2 + TEXT_OFFSET_X)
    }
  }

  private setText = (text: Konva.Text) => {
    this.text = text
  }

  private get displayedText() {
    const { shouldRenderCircle: shouldRenderHolder, text } = this.props

    if (!shouldRenderHolder) {
      return null
    }

    // TODO: Find another way around rather than putting 2 elements
    return (
      <>
        <Text
          ref={this.setText}
          text={text}
          fontSize={TEXT_FONT_SIZE}
          fontStyle="bold"
          fill={getHEXColor(this.textColor)}
        />
      </>
    )
  }

  private get fillColor(): string {
    const { isSelected, color } = this.props
    return getHEXColor(isSelected ? Colors.error50 : color)
  }

  private get textColor(): string {
    const { isSelected, shouldRenderCircle, textColor } = this.props

    if (shouldRenderCircle) {
      return isSelected ? Colors.neutral100 : textColor
    }

    return this.fillColor
  }
}
