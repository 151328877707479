import { IUser } from '~/client/graph'
import { AvatarInputData } from '~/client/src/shared/components/AvatarInput/AvatarInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import AddEditItemDialogStore from '~/client/src/shared/stores/ui/AddEditItemDialog.store'
import { IServiceUserDto } from '~/client/src/shared/types/UserDto'

import UserFieldId from '../../enums/UserFieldId'
import KnownTranslatorKeys from '../../localization/knownTranslatorKeys'
import { getFormattedPhoneNumberForSubmission } from '../../utils/phoneNumberHelpers'
import { VALID_EMAIL_PATTERN } from '../../utils/regExpPatterns'
import UserProjectsStore from '../domain/UserProjects.store'

export default abstract class BaseAddEditMemberDialogStore<
  IFormUserFields,
> extends AddEditItemDialogStore<User, IFormUserFields> {
  protected avatarFieldId: string = UserFieldId.AvatarUrl

  public constructor(
    protected readonly userProjectsStore: UserProjectsStore,
    usersToEdit: User[],
    allUsers: User[],
    resetErrorMessage?: () => void,
    addTitleGetterKey?: KnownTranslatorKeys,
  ) {
    super(
      usersToEdit,
      allUsers,
      addTitleGetterKey,
      KnownTranslatorKeys.editUsers,
      resetErrorMessage,
    )
  }

  public get avatarUrlFieldValue(): AvatarInputData {
    const currentUserDto = {
      email: this.getFieldValueById(UserFieldId.Email),
      firstName: this.getFieldValueById(UserFieldId.FirstName),
      lastName: this.getFieldValueById(UserFieldId.LastName),
      initials: this.getFieldValueById(UserFieldId.Initials),
    } as IUser

    return {
      url: this.getFieldValueById(UserFieldId.AvatarUrl),
      initials: User.getInitialsToDisplay(currentUserDto),
    }
  }

  public setFieldsToItem(user: IServiceUserDto, fields: any) {
    this.setFieldsToUser(user, fields)

    user.userProjectSettings = this.getUpdatedProjectSettings(
      user as IServiceUserDto,
      fields,
    ).toDto()
  }

  protected validateField(
    form: any,
    fieldId: string,
    fieldValue: any,
  ): boolean {
    switch (fieldId) {
      case UserFieldId.Email:
        return VALID_EMAIL_PATTERN.test(fieldValue)
      case UserFieldId.PhoneNumber:
        return form.isPhoneValid
      default:
        return true
    }
  }

  protected setError(form: any, fieldId: string, isEmpty: boolean = false) {
    form.invalidFields.set(fieldId, true)

    const {
      userValidationErrors: {
        emailRequired,
        invalidEmail,
        invalidPhone,
        firstNameRequired,
        lastNameRequired,
        companyRequired,
      },
      pleaseCorrectErrors,
    } = Localization.translator

    let error = pleaseCorrectErrors
    switch (fieldId) {
      case UserFieldId.Email:
        error = isEmpty ? emailRequired : invalidEmail
        break
      case UserFieldId.PhoneNumber:
        error = invalidPhone
        break
      case UserFieldId.FirstName:
        error = firstNameRequired
        break
      case UserFieldId.LastName:
        error = lastNameRequired
        break
      case UserFieldId.Company:
        error = companyRequired
        break
    }

    form.error = error
  }

  protected getUpdatedProjectSettings(
    user: IServiceUserDto,
    fields: any,
  ): UserProject {
    const userProjectSettings: UserProject = this.userProjectsStore
      .getByUser(user)
      .getCopy() as UserProject

    userProjectSettings.companyId = fields[UserFieldId.Company]

    userProjectSettings.roles = fields[UserFieldId.Roles]
    userProjectSettings.trades = fields[UserFieldId.Trades]
    userProjectSettings.teams = fields[UserFieldId.Teams]
    userProjectSettings.defaultTeams = fields[UserFieldId.DefaultTeams]
    userProjectSettings.associatedCodes = fields[UserFieldId.AssignedCodes]

    return userProjectSettings
  }

  private setFieldsToUser(user: User, fields: any) {
    fields.phoneNumber = getFormattedPhoneNumberForSubmission(
      fields.phoneNumber,
    )

    Object.assign(user, fields)
  }
}
