import React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import InitialState from '~/client/src/shared/stores/InitialState'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

import ProjectMembersStore from '../../../stores/domain/ProjectMembers.store'
import {
  ICustomMention,
  MentionModalSection,
  MentionModalSectionType,
} from '../ActionBarInputWithMentions.store'

interface IMentionRowProps {
  entry: ICustomMention
  onMount: () => void
  onUnmount: () => void

  state?: InitialState
  tagsStore?: TagsStore
  projectMembersStore?: ProjectMembersStore
}

@inject('state', 'tagsStore', 'projectMembersStore')
@observer
export default class MentionRow extends React.Component<IMentionRowProps> {
  public UNSAFE_componentWillMount() {
    this.props.onMount()
  }

  public componentDidUpdate() {
    this.props.onMount()
  }

  public componentWillUnmount() {
    this.props.onUnmount()
  }

  public render() {
    const { type, display, section, instanceId, isFirstInSection } =
      this.props.entry

    let content
    switch (type) {
      case TagType.Team:
        content = this.renderTeamRow(instanceId, TagType.Team)
        break

      case TagType.DefaultTeam:
        content = this.renderTeamRow(instanceId, TagType.DefaultTeam)
        break

      case TagType.User:
        content = this.renderUserRow(instanceId)
        break

      default:
        content = display
    }

    return (
      <>
        {isFirstInSection && this.renderSectionHeader(section)}
        <div
          className={classList({
            'row mention-option py10 px12 text large': true,
            'bt-palette-brand-lighter': !isFirstInSection,
          })}
        >
          {content}
        </div>
      </>
    )
  }

  private renderSectionHeader({
    sectionType,
    sectionId,
  }: MentionModalSection): JSX.Element {
    let headerContent
    switch (sectionType) {
      case MentionModalSectionType.Followers:
        headerContent = Localization.translator.followers
        break

      case MentionModalSectionType.Teams:
        headerContent = Localization.translator.teams
        break

      case MentionModalSectionType.UsersByCompanies:
        headerContent = this.renderCompanyHeaderRow(sectionId)
    }

    return (
      <div
        className={classList({
          'row mention-option px12 text large bold by-light-input-border': true,
          'header-suggestion-row': true,
        })}
        onClick={this.preventSelection}
      >
        {headerContent}
      </div>
    )
  }

  private renderTeamRow(teamId: string, type: TagType): JSX.Element {
    const { tagStoreByTagTypeMap, usersByRelatedTagIdMap } =
      this.props.tagsStore

    const tag = tagStoreByTagTypeMap[type].getInstanceById(teamId)

    const usersAmount = (usersByRelatedTagIdMap[tag.id] || []).length

    return (
      <>
        <SitemapAttributeTag
          dataObject={tag as LocationAttributeBase}
          shouldShowAsTag={false}
        >
          <span>{tag.name}</span>
        </SitemapAttributeTag>
        <span className="text light no-grow nowrap">
          ({Localization.translator.xUsers(usersAmount)})
        </span>
      </>
    )
  }

  private renderUserRow(userId: string): JSX.Element {
    const user = this.props.projectMembersStore.getById(userId)

    return <UserProfilePreview user={user} />
  }

  private renderCompanyHeaderRow(companyId: string) {
    const store = this.props.tagsStore.tagStoreByTagTypeMap[TagType.Company]
    const tag = store.getInstanceById(companyId)

    return (
      <SitemapAttributeTag
        dataObject={tag as LocationAttributeBase}
        contentContainerClassName="text white large"
        shouldShowAsTag={false}
      >
        <span>{tag.name}</span>
      </SitemapAttributeTag>
    )
  }

  private preventSelection = e => {
    e.stopPropagation()
  }
}
