import * as React from 'react'

import { observer } from 'mobx-react'

import { ContentObjectStatus } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import ScheduleComment from '~/client/src/shared/models/ScheduleComment'

interface IProps {
  scheduleComment: ScheduleComment
}

@observer
export default class ScheduleCommentIcon extends React.Component<IProps> {
  public render() {
    const { scheduleComment = { status: '' } } = this.props
    const { status } = scheduleComment
    let icon

    switch (status) {
      case ContentObjectStatus.Open:
        icon = <Icons.DateChangeBlue />
        break

      case ContentObjectStatus.Closed:
      default:
        icon = <Icons.CalendarGrey />
        break
    }

    return <div style={{ marginTop: '-2px' }}>{icon}</div>
  }
}
