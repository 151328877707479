import * as React from 'react'

import { Icon, IconName } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import ILocationTransferPair from '~/client/src/shared/models/ILocationTransferPair'

interface IProps {
  transferPairs: ILocationTransferPair[]
  plannedDeliveryLocationId: string
  plannedInstallLocationId: string
}

const DEFAULT_ICON_SIZE = 16

@observer
export default class CurrentLocationStatusIcon extends React.Component<IProps> {
  public static defaultProps = {
    transferPairs: [],
  }

  public render() {
    if (this.isTransfering) {
      return (
        <Icons.MaterialTransferForm className="text primary mx5 form-id-icon-large no-grow" />
      )
    }

    return (
      <Icon
        icon={this.iconName}
        size={DEFAULT_ICON_SIZE}
        className={classList({
          'bp3-icon mx5 text': true,
          'success-green': this.isInstallIcon,
          yellow: !this.isInstallIcon && this.isDeliveryIcon,
          red: !this.isInstallIcon && !this.isDeliveryIcon,
        })}
      />
    )
  }

  private get isTransfering(): boolean {
    return this.props.transferPairs.some(p => p.materialTransfers.length)
  }

  private get isInstallIcon(): boolean {
    const {
      transferPairs,
      plannedDeliveryLocationId,
      plannedInstallLocationId,
    } = this.props
    return (
      (!plannedInstallLocationId && !plannedDeliveryLocationId) ||
      transferPairs.every(tp => tp.location.id === plannedInstallLocationId)
    )
  }

  private get isDeliveryIcon(): boolean {
    const {
      transferPairs,
      plannedDeliveryLocationId,
      plannedInstallLocationId,
    } = this.props

    if (!plannedDeliveryLocationId) {
      return true
    }
    if (plannedInstallLocationId) {
      return transferPairs.every(
        tp =>
          tp.location.id === plannedDeliveryLocationId ||
          tp.location.id === plannedInstallLocationId,
      )
    }
    return transferPairs.every(
      tp => tp.location.id === plannedDeliveryLocationId,
    )
  }

  private get iconName(): IconName {
    if (this.isInstallIcon) {
      return IconNames.TICK
    }
    if (this.isDeliveryIcon) {
      return IconNames.MINUS
    }
    return IconNames.CROSS
  }
}
