import {
  IScreen,
  PresentationScreenEntityKey,
  PresentationScreenKey,
} from '~/client/graph'

export const defaultPresentationScreens = [
  PresentationScreenKey.Gantt,
  PresentationScreenKey.Deliveries,
  PresentationScreenKey.Logistics,
  PresentationScreenKey.Forms,
].map((key, idx) =>
  key === PresentationScreenKey.Logistics
    ? {
        key,
        order: idx + 1,
        duration: 30,
        isShown: true,
        shouldShowEntity: false,
        entityKey: PresentationScreenEntityKey.Announcement,
        entityDuration: 15,
      }
    : ({
        key,
        order: idx + 1,
        duration: 30,
        isShown: true,
      } as IScreen),
)

export const trackerRelatedScreens = new Set([PresentationScreenKey.Gantt])

export const deliveryRelatedScreens = new Set([
  PresentationScreenKey.Deliveries,
])

export const logisticsRelatedScreens = new Set([
  PresentationScreenKey.Logistics,
])

export const formsRelatedScreens = new Set([PresentationScreenKey.Forms])
