import { IMonitoringInfo, MonitoringStatus } from '~/client/graph'

export class MaturixMonitoring {
  public static fromDto(dto: IMonitoringInfo): MaturixMonitoring {
    return new MaturixMonitoring(
      dto.monitoringId,
      dto.monitoringName,
      dto.status,
      dto.deviceId,
    )
  }

  public constructor(
    public monitoringId: string,
    public monitoringName: string,
    public status?: MonitoringStatus,
    public deviceId?: string,
  ) {}
}
