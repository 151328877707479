import React from 'react'

import { inject, observer } from 'mobx-react'

import AnalyticsSettingsStore from '~/client/src/desktop/stores/domain/AnalyticsSettings.store'
import DeliveryDetails from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails'
import * as Layout from '~/client/src/shared/components/Layout'
import { Loader } from '~/client/src/shared/components/Loader'
import SitePermitViewForm from '~/client/src/shared/components/SitePermitCreationForm/SitePermitViewForm'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveryStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryAssignmentsStore from '~/client/src/shared/stores/domain/DeliveryAssignments.store'
import DeliveryVehicleTypeStore from '~/client/src/shared/stores/domain/DeliveryVehicleTypes.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialStore from '~/client/src/shared/stores/domain/Materials.store'
import PermitInspectionChangesStore from '~/client/src/shared/stores/domain/PermitInspectionChanges.store'
import PermitInspectionsStore from '~/client/src/shared/stores/domain/PermitInspections.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ScanHistoriesStore from '~/client/src/shared/stores/domain/ScanHistories.store'
import SitePermitStore from '~/client/src/shared/stores/domain/SitePermits.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import DesktopFileInput from '../../components/FileInput/DesktopFileInput'
import DesktopInitialState from '../../stores/DesktopInitialState'
import DesktopEventStore from '../../stores/EventStore/DesktopEvents.store'
import DeliveryActionSideBase from '../Deliveries/components/DeliveryActionSideWrapper/components/DeliveryActionSideBase'
import ReportsStore from './Reports.store'
import ScanStationReportsDialog from './components/ReportsDialog/ScanStationReportsDialog'
import ReportsDropdown from './components/ReportsDropdown/ReportsDropdown'
import ReportsHeaderBar from './components/ReportsHeader/ReportsHeader'
import DeliveryReportList from './components/ReportsList/DeliveryReport/DeliveryReportList'
import DeliveryReportListStore from './components/ReportsList/DeliveryReport/DeliveryReportList.store'
import FormReportList from './components/ReportsList/FormReportList/FormReportList'
import FormReportListStore from './components/ReportsList/FormReportList/FormReportList.store'
import ScanStationList from './components/ReportsList/ScanStation/ScanStationList'
import ScanStationListStore from './components/ReportsList/ScanStation/ScanStationList.store'

import './Reports.scss'

interface IProps {
  state?: DesktopInitialState
  eventsStore?: DesktopEventStore
  scanHistoriesStore?: ScanHistoriesStore
  companiesStore?: CompaniesStore
  projectMembersStore?: ProjectMembersStore
  userProjectsStore?: UserProjectsStore
  projectDateStore?: ProjectDateStore
  sitePermitsStore?: SitePermitStore
  permitTypesStore?: PermitTypesStore
  analyticsSettingsStore?: AnalyticsSettingsStore
  deliveriesStore?: DeliveryStore
  materialCategoryStore?: MaterialCategoryStore
  deliveryVehicleTypesStore?: DeliveryVehicleTypeStore
  materialsStore?: MaterialStore
  locationAttributesStore?: LocationAttributesStore
  permitInspectionsStore?: PermitInspectionsStore
  permitInspectionChangesStore?: PermitInspectionChangesStore
  deliveryAssignmentsStore?: DeliveryAssignmentsStore
}

@inject(
  'state',
  'eventsStore',
  'scanHistoriesStore',
  'companiesStore',
  'projectMembersStore',
  'userProjectsStore',
  'projectDateStore',
  'permitTypesStore',
  'sitePermitsStore',
  'analyticsSettingsStore',
  'deliveriesStore',
  'materialCategoryStore',
  'deliveryVehicleTypesStore',
  'materialsStore',
  'locationAttributesStore',
  'permitInspectionsStore',
  'permitInspectionChangesStore',
  'deliveryAssignmentsStore',
)
@observer
export default class Reports extends React.Component<IProps> {
  private store: ReportsStore = null
  private scanStationListStore: ScanStationListStore = null
  private formReportListStore: FormReportListStore = null
  private deliveryReportListStore: DeliveryReportListStore = null

  public constructor(props: IProps) {
    super(props)
    this.store = new ReportsStore(
      props.eventsStore,
      props.scanHistoriesStore,
      props.sitePermitsStore,
      props.permitTypesStore,
      props.analyticsSettingsStore,
      props.deliveriesStore,
      props.projectDateStore,
    )
    this.scanStationListStore = new ScanStationListStore(
      props.state,
      this.store,
      props.companiesStore,
      props.projectMembersStore,
      props.userProjectsStore,
      props.projectDateStore,
    )
    this.formReportListStore = new FormReportListStore(
      props.state,
      this.store,
      props.companiesStore,
      props.permitTypesStore,
      props.projectDateStore,
      props.permitInspectionsStore,
      props.permitInspectionChangesStore,
      props.projectMembersStore,
      props.locationAttributesStore,
    )
    this.deliveryReportListStore = new DeliveryReportListStore(
      props.state,
      this.store,
      props.companiesStore,
      props.projectDateStore,
      props.locationAttributesStore,
      props.deliveryVehicleTypesStore,
      props.materialsStore,
      props.projectMembersStore,
      props.userProjectsStore,
      props.materialCategoryStore,
      props.deliveryAssignmentsStore,
    )
  }

  public render() {
    if (!this.store.isDataPrepared) return <Loader />

    const {
      isScanStationDialogDisplayed,
      isFormReportDialogDisplayed,
      isDeliveryReportDialogDisplayed,
      selectedFormReport,
      selectedDeliveryReport,
      closeDeliveryReports,
      closeFormReports,
    } = this.store

    return (
      <Layout.View className="overflow-hidden">
        <Layout.Header className="reports-header">
          <div className="row x-between">
            <ReportsDropdown store={this.store} />
          </div>
          <ReportsHeaderBar
            reportsStore={this.store}
            scanStationListStore={this.scanStationListStore}
            formReportListStore={this.formReportListStore}
            deliveryReportListStore={this.deliveryReportListStore}
          />
        </Layout.Header>
        <Layout.Content>
          <div className="relative-block overflow-hidden multi-grid-container">
            <div className="reports-list-container full-height">
              {isScanStationDialogDisplayed && (
                <div className="report-side-view absolute full-height bg-white">
                  <ScanStationReportsDialog store={this.store} />
                </div>
              )}
              {isFormReportDialogDisplayed && (
                <div className="report-side-view absolute full-height bg-white">
                  <SitePermitViewForm
                    permitToShow={selectedFormReport}
                    close={closeFormReports}
                    FileInputType={DesktopFileInput}
                  />
                </div>
              )}
              {isDeliveryReportDialogDisplayed && (
                <DeliveryActionSideBase
                  title={EMPTY_STRING}
                  content={
                    <DeliveryDetails
                      initProjectId={this.props.state.activeProject.id}
                      FileInputType={DesktopFileInput}
                      backClicked={closeDeliveryReports}
                      displayedDeliveryId={selectedDeliveryReport?.id}
                    />
                  }
                  shouldShowHeader={false}
                />
              )}
              <div className="row table-holder">{this.content}</div>
            </div>
          </div>
        </Layout.Content>
      </Layout.View>
    )
  }

  private get content() {
    const { isScanStation, isFormReport, isDeliveryReport } = this.store
    return (
      <>
        {isScanStation && <ScanStationList store={this.scanStationListStore} />}
        {isFormReport && <FormReportList store={this.formReportListStore} />}
        {isDeliveryReport && (
          <DeliveryReportList store={this.deliveryReportListStore} />
        )}
      </>
    )
  }
}
