import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'

import Dimmer from '~/client/src/shared/components/Dimmer'
import MenuCloser from '~/client/src/shared/components/MenuCloser'

import DraggableBar, { ViewMode } from '../DraggableBar/DraggableBar'

import './CompactActionsMenuModal.scss'

const DRAGGABLE_LIST_DEFAULT_HEIGHT = 400
// localization: translated

interface IProps {
  isShown: boolean
  onHide(): void

  headerText?: string
  footerText?: string
  ignoredOutsideElementId?: string
}

@observer
export default class CompactActionsMenuModal extends React.Component<IProps> {
  @observable public viewMode: ViewMode = ViewMode.Mixed

  public render() {
    const { isShown, onHide, ignoredOutsideElementId, children } = this.props

    if (!isShown) return null

    return (
      <MenuCloser isOpen closeMenu={onHide} excludeId={ignoredOutsideElementId}>
        <Dimmer shown onClick={onHide} />
        <DraggableBar
          height={DRAGGABLE_LIST_DEFAULT_HEIGHT}
          bottomOffset={DRAGGABLE_LIST_DEFAULT_HEIGHT}
          viewMode={this.viewMode}
          selectViewMode={this.selectViewMode}
          className="relative"
        >
          <div className="compact-actions-menu-modal absolute overflow-hidden col">
            <div className="col content overflow-hidden">
              <div
                className="overflow-y-auto bg-white pointer"
                onClick={onHide}
              >
                {children}
              </div>
            </div>
          </div>
        </DraggableBar>
      </MenuCloser>
    )
  }

  @action.bound
  private selectViewMode(mode: ViewMode) {
    if (this.viewMode !== mode) {
      this.viewMode = mode
    }
    if (this.viewMode === ViewMode.Closed) {
      this.props.onHide()
    }
  }
}
