enum DeliveryControlTypes {
  TEXT = 'text',
  DATE = 'date',
  TIME = 'time',
  TEL = 'tel',
  EMAIL = 'email',
  NUMBER = 'number',
  TOGGLE = 'toggle',
  SELECT = 'select',
  MULTI_COMBOBOX = 'multi-combobox',
  IMAGE = 'image',
  CHECKBOX = 'checkbox',
  TEXTAREA = 'textarea',
  LINKIFIED_TEXT_AREA = 'linkified-textarea',
  LABEL = 'label',
  LOCATION_LABEL = 'location-label',
  TOGGLE_BUTTON = 'toggle-button',
  USER_SELECT = 'user-select',
  COMPANY_SELECT = 'company-select',
  WEATHER_FORECAST = 'weather-forecast',
}

export default DeliveryControlTypes
