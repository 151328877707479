import * as React from 'react'

import Konva from 'konva'
import { observer } from 'mobx-react'
import { Path } from 'react-konva'

import ThemeMode from '../../utils/ThemeModeManager'
import KonvaWorkflowBasePin from './KonvaWorkflowBasePin'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_PATH_DATA =
  'M14.5667 6.49533H11.5222C11.2833 6.49533 11.0889 6.79144 11.0889 6.96644V8.96644H8.91111V5.457C8.91111 5.14033 8.71722 4.99922 8.47778 4.99922H5.39722C5.15778 4.99978 5 5.28311 5 5.45756V14.4848C5 14.7103 5.18167 14.9998 5.39722 14.9998H14.5667C14.8056 14.9998 15 14.6598 15 14.4848V6.96644C15 6.79144 14.8056 6.49533 14.5667 6.49533ZM8.04444 9.28311V14.3664H5.86667V5.63311H8.04444V9.28311ZM11.0889 14.3664H8.91111V9.59978H11.0889V14.3664V14.3664ZM14.1333 14.3664H11.9556V7.12922H14.1333V14.3664Z'
const ICON_OFFSET = { x: 0, y: 0 }
const { getHEXColor } = ThemeMode

interface IProps {
  text: string
  isSelected: boolean
  isCanceled: boolean
  shouldRenderCircle?: boolean
  x: number
  y: number
  onClick: () => void
  onTouchEnd: () => void
}

@observer
export default class KonvaWorkflowActivityPin extends React.Component<
  IProps & Konva.NodeConfig
> {
  public render() {
    const {
      shouldRenderCircle,
      text,
      isSelected,
      x,
      y,
      onClick,
      onTouchEnd,
      ...rest
    } = this.props

    return (
      <KonvaWorkflowBasePin
        {...rest}
        color={getHEXColor(Colors.primary40)}
        text={text}
        textColor={getHEXColor(Colors.neutral100)}
        isSelected={isSelected}
        shouldRenderCircle={shouldRenderCircle}
        x={x}
        y={y}
        onClick={onClick}
        onTouchEnd={onTouchEnd}
      >
        <Path
          data={ICON_PATH_DATA}
          fill={getHEXColor(Colors.neutral100)}
          x={ICON_OFFSET.x}
          y={ICON_OFFSET.y}
        />
      </KonvaWorkflowBasePin>
    )
  }
}
