import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import User from '~/client/src/shared/models/User'

interface IProps {
  user: User

  onRemoveClick(userId: string): void
  onUserProfileClick(userId: string): void
}

const removeFromMembersList = 'Remove from members list'
const viewUserProfile = 'View user profile'

@observer
export default class UserCard extends React.Component<IProps> {
  @observable private shouldShowActionSelector = false

  public render() {
    const { user } = this.props

    return (
      <div className="bg-white brada4 user-card relative">
        <div className="modal pa10 overflow-y-auto">
          <UserProfilePreview user={user} />
          <MenuCloser
            className="no-grow"
            closeMenu={this.hideActionSelector}
            isOpen={this.shouldShowActionSelector}
          >
            <Icon
              className={classList({
                'pointer text blue-highlight ml4 action-btn absolute': true,
                selected: this.shouldShowActionSelector,
              })}
              icon={IconNames.MORE}
              onClick={this.toggleActionSelector}
            />
            {this.renderActionSelector()}
          </MenuCloser>
        </div>
      </div>
    )
  }

  private renderActionSelector(): JSX.Element {
    if (!this.shouldShowActionSelector) {
      return null
    }

    const { user, onRemoveClick, onUserProfileClick } = this.props

    return (
      <div className="col absolute full-width bg-white beautiful-shadow brada4 action-selector">
        <div
          className="row highlighted-hover pa10 pointer"
          onClick={onRemoveClick.bind(this, user.id)}
        >
          <Icon
            className="bg-grey-scale-light br-rounded pointer text white mr4"
            icon={IconNames.SMALL_CROSS}
          />
          <div>{removeFromMembersList}</div>
        </div>
        <div
          className="row highlighted-hover pa10 pointer"
          onClick={onUserProfileClick.bind(this, user.id)}
        >
          <Icons.User className="user-icon no-grow mr4" />
          <div>{viewUserProfile}</div>
        </div>
      </div>
    )
  }

  @action.bound
  private toggleActionSelector() {
    this.shouldShowActionSelector = !this.shouldShowActionSelector
  }

  @action.bound
  private hideActionSelector() {
    this.shouldShowActionSelector = false
  }
}
