import { observable, runInAction } from 'mobx'

import { ConfigurationByNameDocument } from '~/client/graph/operations/generated/Configurations.generated'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'

interface IChartConfiguration {
  baseUrl: string
  chartIds: {
    entireProject: string | null
    monthly: string
    topLevelMetrics: string | null
    weekly: string
  }
  name: string
  title: string
}

interface IInsightChartsConfiguration {
  NewResponsibleCompaniesCharts: IChartConfiguration
  ResponsibleCompaniesCharts: IChartConfiguration
  averageDaysRequestedBeforeDeliveryCharts: IChartConfiguration
  deliveriesCharts: IChartConfiguration
  lateCancellationsCharts: IChartConfiguration
  responsibleChart: IChartConfiguration
}

const configurationName = 'insight.charts'

export default class DashboardIntegrationStore {
  @observable public configuration: IInsightChartsConfiguration = null

  public constructor(private readonly graphExecutorStore: GraphExecutorStore) {
    this.fetchConfiguration()
  }

  public async fetchConfiguration() {
    const params = {
      name: configurationName,
    }

    const { data } = await this.graphExecutorStore.query(
      ConfigurationByNameDocument,
      params,
    )

    try {
      // eslint-disable-next-line no-var
      var parsedData = JSON.parse(data?.configuration?.value)
    } catch {
      console.warn(`Incorrect configuration for '${configurationName}'`)
      parsedData = null
    }
    runInAction(() => {
      this.configuration = parsedData?.charts?.reduce((acc, item) => {
        acc[item.name] = item
        return acc
      }, {})
    })
  }
}
