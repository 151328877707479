import Localization from '../localization/LocalizationManager'
import { NO_VALUE } from '../utils/usefulStrings'

export enum ReportFileTypes {
  LIST_AS_TABLE = 'list_as_table',
  LIST_OF_SINGLE = 'list_of_single',
  CSV_LIST_REPORT = 'csv_list_report',
}

export const getReportFileTypeLabel = (fieldId: string) => {
  switch (fieldId) {
    case ReportFileTypes.LIST_AS_TABLE:
      return Localization.translator.includeXFile('pdf')
    case ReportFileTypes.LIST_OF_SINGLE:
      return Localization.translator.includeDetailedEachForm
    case ReportFileTypes.CSV_LIST_REPORT:
      return Localization.translator.includeXFile('csv')
    default:
      return NO_VALUE
  }
}
