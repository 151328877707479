import * as React from 'react'

import { observer } from 'mobx-react'

import { WorkflowStepType } from '~/client/graph'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import {
  isAfter,
  isWithinRange,
} from '~/client/src/shared/stores/ui/ProjectDate.store'

import SitePermitCreationFormStore from '../../../SitePermitCreationForm.store'

interface IProps {
  store: SitePermitCreationFormStore
}

@observer
export default class FormAutoActivationLabel extends React.Component<IProps> {
  public render() {
    if (!this.isShown) return null

    return (
      <div className="row py5">
        <span className="text center large blue-highlight bold lp0155">
          {Localization.translator.workflowConfDescr.workflowStartsOnDate}
        </span>
      </div>
    )
  }

  private get isShown(): boolean {
    const { existingPermit, template } = this.props.store
    const {
      currentWorkflowStepId,
      isAutoActivationEnabled,
      startDate,
      endDate,
      isApproved,
    } = existingPermit

    const now = Date.now()
    if (
      !isAutoActivationEnabled ||
      isAfter(now, endDate) ||
      isWithinRange(now, startDate, endDate)
    ) {
      return false
    }

    if (!template.hasStartStep) return isApproved

    const currentStepType = template.stepsWithoutRecurring.find(
      s => s.id === currentWorkflowStepId,
    )?.type
    return currentStepType === WorkflowStepType.Start
  }
}
