import * as React from 'react'

import { observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'
import Delivery from '~/client/src/shared/models/Delivery'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'

import DeliveryStatusLabel from '../../DeliveryStatusLabel/DeliveryStatusLabel'
import CompactHeaderBar from '../CompactHeaderBar'

interface IProps {
  delivery: Delivery
  isConcreteDirectOrder: boolean
  onCloseClick: () => void
  companiesStore: CompaniesStore
  showMenuPopup: () => void
}

const notSpecified = 'Not specified'
const newDelivery = 'New delivery'
const deleted = 'Deleted'

@observer
export default class DeliveryCompactHeaderBar extends React.Component<IProps> {
  public render() {
    const { delivery, onCloseClick } = this.props

    return (
      <CompactHeaderBar
        titleCaption={this.deliveryTitleCaption}
        startDate={delivery?.startDate}
        endDate={delivery?.endDate}
        titleIconEl={this.titleIcon}
        isCreationMode={!delivery}
        renderStatus={this.deliveryStatusRenderer}
        onCloseClick={onCloseClick}
      />
    )
  }

  private deliveryStatusRenderer = (className: string): JSX.Element => {
    const { delivery, showMenuPopup } = this.props

    if (delivery.isDeleted) {
      return (
        <div className={`${className} ml5 brada3 px5 ba-red text red`}>
          {deleted}
        </div>
      )
    }

    return (
      <DeliveryStatusLabel
        className={`${className} ml5`}
        status={delivery.status}
        cancellationReason={delivery.cancellationReason}
        showMenuPopup={showMenuPopup}
      />
    )
  }

  private get deliveryTitleCaption(): string {
    const { delivery, companiesStore } = this.props

    return delivery
      ? `${delivery.codeToDisplay(companiesStore) || notSpecified}`
      : newDelivery
  }

  private get titleIcon(): JSX.Element {
    return this.props.isConcreteDirectOrder ? (
      <Icons.ConcreteDirect />
    ) : (
      <Icons.DeliveryHeaderBar />
    )
  }
}
