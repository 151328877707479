import { DocumentNode } from 'graphql'
import { action } from 'mobx'

import Command from '~/client/src/shared/commands/Command'
import InitialState from '~/client/src/shared/stores/InitialState'

import EffectProcessor from './EffectsProcessors/EffectsProcessor'
import Flow from './EffectsProcessors/FlowProcessor/Flow'
import IWorkerEffect from './EffectsProcessors/WorkerProcessor/IWorkerEffect'
import EventContext from './EventContext'
import { REPORT_ERROR } from './eventConstants'
import EventTypes from './eventTypes'

export interface IEvent extends Array<EventTypes | any> {
  0: EventTypes
  1?: any
  2?: any
  3?: any
  4?: any
  5?: any
  6?: any
  7?: any
}

export interface IHttpEffect {
  url: string
  method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH'
  mode?: RequestMode
  body?: any
  headers?: any
  onSuccess?: IEvent
  onError?: IEvent
  blob?: boolean
}

export interface IGraphQueryEffect {
  query: DocumentNode
  variables?: any

  onSuccess?: IEvent
  onError?: IEvent
}

export interface IGraphMutationEffect {
  mutation: DocumentNode
  variables?: any

  onSuccess?: IEvent
  onError?: IEvent
}

export interface IGraphSubscriptionEffect {
  query: DocumentNode
  variables?: any

  onSuccess?: IEvent
  onData?: IEvent
  onError?: IEvent
  listenMany?: boolean
}

export type EffectMap = {
  dispatchN?: IEvent[]
  dispatch?: IEvent
  cmd?: {
    cmd: Command
    onSuccess: IEvent
    onError: IEvent
  }
  http?: IHttpEffect
  graphQuery?: IGraphQueryEffect
  graphSubscription?: IGraphSubscriptionEffect
  graphMutation?: IGraphMutationEffect
  flow?: Flow
  worker?: IWorkerEffect
}

export type EventHandler = (
  state: InitialState,
  ...args: any[]
) => void | EffectMap

export default class BaseEventsStore {
  private eventMap: { [key: string]: EventHandler } = {}
  private postEventCallbacks = []
  private errorHandlersCallStack = []

  public constructor(
    public effectProcessor: EffectProcessor,
    public appState: InitialState,
  ) {}

  public on(name: EventTypes, handler: EventHandler) {
    this.eventMap[`${name}`] = handler
  }

  @action
  public dispatch(type: EventTypes, ...args: any[]) {
    if (!type) {
      return Promise.resolve()
    }

    const context = new EventContext({}, [type, ...args])
    const typeString = `${type}`
    const handler = this.eventMap[typeString]

    if (!handler) {
      throw new Error(
        `No handler found for event type ${type} with args ${args}`,
      )
    }

    window.performance.mark(`SH_START_${typeString}`)
    try {
      // TODO investigate performance benefits of running handlers in actions. Seems promising.
      context.effects = handler(this.appState, ...args)
      const response = this.effectProcessor.process(
        this,
        this.appState,
        context.effects,
        typeString,
      )
      this.notifyPostEventCallbacks(context)
      this.cleanErrorHandlersCallStack()
      window.performance.mark(`SH_FINISH_${typeString}`)
      window.performance.measure(
        `SH_${typeString}`,
        `SH_START_${typeString}`,
        `SH_FINISH_${typeString}`,
      )
      return Promise.resolve(response)
    } catch (error) {
      console.error(error)
      if (!this.isInfinityLoopDetected()) {
        context.error = error
        this.logErrorHandlersNextTick(type, error)
        this.handleError(context)
      }
      this.cleanErrorHandlersCallStack()
      return Promise.resolve()
    }
  }

  @action
  public dispatchN(events: any[][]) {
    // @ts-ignore: use array as tuple intentionally
    return Promise.all(events.map(event => this.dispatch(...event)))
  }

  public addPostEventCallback(cb: (ctx: EventContext) => any): () => void {
    if (!cb) {
      return
    }
    this.postEventCallbacks.push(cb)
    return () => {
      this.postEventCallbacks = this.postEventCallbacks.filter(
        callback => callback !== cb,
      )
    }
  }

  public terminateGraphSubscriptions() {
    this.effectProcessor.terminateGraphSubscriptions()
  }

  public terminateGraphSubscription(eventName: string) {
    this.effectProcessor.terminateGraphSubscription(eventName)
  }

  private notifyPostEventCallbacks(eventContext: EventContext) {
    this.postEventCallbacks.forEach(cb => cb(eventContext))
  }

  private handleError(eventContext: EventContext) {
    const { error, event } = eventContext
    const [type] = event

    this.dispatch(REPORT_ERROR, type, error)
  }

  private cleanErrorHandlersCallStack() {
    this.errorHandlersCallStack = []
  }

  private isInfinityLoopDetected() {
    const MAX_THE_SAME_ERRORS_STACK_SIZE = 10

    if (MAX_THE_SAME_ERRORS_STACK_SIZE > this.errorHandlersCallStack.length) {
      return false
    }

    const typeSet = new Set()
    const errorSet = new Set()

    this.errorHandlersCallStack
      .slice(-MAX_THE_SAME_ERRORS_STACK_SIZE)
      .forEach(item => {
        const { eventType, error } = item
        const errorType = `${error.name || ''}//${error.message || ''}`
        typeSet.add(eventType)
        errorSet.add(errorType)
      })

    const isTypeTheSame = typeSet.size === 1
    const isErrorTheSame = errorSet.size === 1
    return isTypeTheSame && isErrorTheSame
  }

  private logErrorHandlersNextTick(eventType: EventTypes, error: any) {
    this.errorHandlersCallStack.push({
      eventType,
      error,
    })
  }
}
