import { observable } from 'mobx'

import {
  IDefaultPermitType,
  IInspectionOptions,
  IPermitType,
  IWorkflowStep,
  WorkflowStepType,
} from '~/client/graph/types.generated'

import {
  FormCategoryType,
  getFormCategoryTypeDisplayName,
} from '../enums/FormCategoryType'
import Localization from '../localization/LocalizationManager'
import { EMPTY_OBJECT_ID } from '../utils/GraphExecutor'
import { NO_VALUE } from '../utils/usefulStrings'
import { copyObjectDeep } from '../utils/util'
import BaseModel from './BaseModel'

export enum DefaultPermitType {
  Hotwork = 'hotwork',
  Ladder = 'ladder',
  EnergizedRoom = 'energized',
  Aerial = 'aerial',
  FireExtinguisherInspection = 'fireExtinguisher',
  SurfacePenetration = 'surfacePenetration',
  InteriorConcretePenetration = 'interiorConcretePenetration',
  OffRoadHeavyEquipment = 'offRoadHeavyEquipment',
  EmissionForm = 'emission',
  MaterialTransfer = 'materialTransfer',
}

export enum InstructionListType {
  Enumerated = 'enumerated',
  Bulleted = 'bulleted',
  None = 'none',
}

export function getInstructionListTypeTranslate(value: InstructionListType) {
  switch (value) {
    case InstructionListType.Bulleted:
      return Localization.translator.instructionListTypes.bulleted
    case InstructionListType.Enumerated:
      return Localization.translator.instructionListTypes.enumerated
    case InstructionListType.None:
      return Localization.translator.instructionListTypes.none
    default:
      throw new Error(value + ' is unhandled')
  }
}

const CUSTOM_FORM_TYPE_INDICATOR = '_'

export default class PermitType
  extends BaseModel<IPermitType>
  implements IPermitType
{
  public static fromDefault(dto: IDefaultPermitType): PermitType {
    return new PermitType(
      dto.id,
      dto.type,
      dto.name,
      null,
      dto.isEnabled,
      null,
      dto.orderIndex,
      dto.workflowSteps,
      dto.isAutoActivationEnabled,
      dto.inspectionOptions,
      null,
      false,
      dto.createdAt,
      dto.updatedAt,
    )
  }

  public static fromDto(dto: IPermitType): PermitType {
    return new PermitType(
      dto.id,
      dto.type,
      dto.name,
      dto.projectId,
      dto.isEnabled,
      dto.basedOn,
      dto.orderIndex,
      dto.workflowSteps,
      dto.isAutoActivationEnabled,
      dto.inspectionOptions,
      dto.shouldBlockOnNonWorkTimes,
      dto.isDeleted,
      dto.createdAt,
      dto.updatedAt,
    )
  }

  @observable public type: string
  @observable public name: string
  @observable public projectId: string
  @observable public isEnabled: boolean
  @observable public basedOn: string
  @observable public orderIndex: number
  @observable public workflowSteps: IWorkflowStep[]
  @observable public isAutoActivationEnabled: boolean
  @observable public inspectionOptions?: IInspectionOptions
  @observable public shouldBlockOnNonWorkTimes: boolean
  @observable public isDeleted?: boolean
  @observable public createdAt: number
  @observable public updatedAt: number

  public constructor(
    id: string,
    type: string,
    name: string,
    projectId: string,
    isEnabled: boolean,
    basedOn: string,
    orderIndex: number,
    workflowSteps: IWorkflowStep[],
    isAutoActivationEnabled: boolean,
    inspectionOptions?: IInspectionOptions,
    shouldBlockOnNonWorkTimes?: boolean,
    isDeleted?: boolean,
    createdAt: number = 0,
    updatedAt: number = 0,
  ) {
    super(id)
    this.setCreatedAt(createdAt)
    this.setUpdatedAt(updatedAt)

    this.type = type
    this.name = name
    this.projectId = projectId
    this.isEnabled = isEnabled
    this.basedOn = basedOn
    this.orderIndex = orderIndex || 0
    this.workflowSteps = workflowSteps || []
    this.isAutoActivationEnabled = isAutoActivationEnabled
    this.inspectionOptions = inspectionOptions
    this.shouldBlockOnNonWorkTimes = shouldBlockOnNonWorkTimes || false
    this.isDeleted = isDeleted
    this.createdAt = createdAt || 0
    this.updatedAt = updatedAt || 0
  }

  // available within all Projects per Tenant
  public get isDefault(): boolean {
    return !this.projectId
  }

  public get isInspectionType(): boolean {
    return !!this.inspectionOptions
  }

  public get inspectionFrequency(): number {
    if (!this.isInspectionType) {
      return 0
    }

    return this.inspectionOptions.inspectionFrequency
  }

  public get hasStartStep(): boolean {
    return this.workflowSteps.some(s => s.type === WorkflowStepType.Start)
  }

  public get hasBicInspectionStep(): boolean {
    return this.workflowSteps.some(
      s => s.type === WorkflowStepType.BicInspection,
    )
  }

  public get hasApprovalStep(): boolean {
    return this.workflowSteps.some(s => s.type === WorkflowStepType.Approval)
  }

  public get isApprovalLast(): boolean {
    return this.lastStep?.type === WorkflowStepType.Approval
  }

  public get isEmissionFormType(): boolean {
    return this.type.includes(DefaultPermitType.EmissionForm)
  }

  public get isMaterialTransfer(): boolean {
    return this.type === DefaultPermitType.MaterialTransfer
  }

  public get isCustom(): boolean {
    return this.type.includes(CUSTOM_FORM_TYPE_INDICATOR)
  }

  public get initialStep(): IWorkflowStep {
    return this.workflowSteps[0]
  }

  public get lastStep(): IWorkflowStep {
    return this.workflowSteps[this.workflowSteps.length - 1]
  }

  public get stepsWithoutRecurring(): IWorkflowStep[] {
    return this.workflowSteps.filter(
      s => s.type !== WorkflowStepType.RecurringInspection,
    )
  }

  public get recurringInspectionStep(): IWorkflowStep {
    return this.workflowSteps.find(
      s => s.type === WorkflowStepType.RecurringInspection,
    )
  }

  public get workflowCategory(): FormCategoryType {
    if (this.isCustom) {
      return FormCategoryType.CUSTOM
    }
    if (this.isMaterialTransfer) {
      return FormCategoryType.MATERIALS
    }
    return FormCategoryType.DEFAULT
  }

  public get workflowCategoryToDisplay(): string {
    return getFormCategoryTypeDisplayName(this.workflowCategory)
  }

  public isLastStep = (workflowStepId: string): boolean => {
    return this.lastStep?.id === workflowStepId
  }

  public getStepRuleIds = (workflowStepId: string): string[] => {
    return (
      this.workflowSteps.find(s => s.id === workflowStepId)?.workflowRuleIds ||
      []
    )
  }

  public getFormattedUpdatedAt = (
    formatDateFn: (date: Date | number) => string,
  ): string => {
    return !this.isDefault && this.updatedAt
      ? formatDateFn(this.updatedAt)
      : NO_VALUE
  }

  public getDeepCopy(): PermitType {
    const dtoCopy = copyObjectDeep<IPermitType>(this)
    return PermitType.fromDto(dtoCopy)
  }

  public getDto(): PermitType {
    const dto = this.getDeepCopy()
    /**
     * * Since default permit types don't have a projectId
     * * we need to set it as EMPTY_OBJECT_ID before saving, because
     * * IPermitTypeInput doesn't allow null or empty values
     */
    if (this.isDefault) {
      dto.projectId = EMPTY_OBJECT_ID
    }

    delete dto.isDeleted

    return dto
  }
}
