import { enumToList } from '~/client/src/shared/utils/converters'

enum Frequency {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

enum Period {
  Week = 7,
  Month = 30,
  Month4 = 120,
  Year = 365,
  EntireProject = Infinity,
}

export const periodsList = enumToList(Period)

export { Frequency, Period }
