import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IActivityFollowingFieldsFragment = Pick<
  Types.IActivityFollowing,
  'id' | 'projectId' | 'entityId' | 'userId' | 'createdAt' | 'updatedAt'
>

export type IGetActivityFollowingListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetActivityFollowingListQuery = {
  activityFollowings?: Types.Maybe<{
    data: Array<IActivityFollowingFieldsFragment>
  }>
}

export type IListenToActivityFollowingSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToActivityFollowingSubscription = {
  activityFollowing?: Types.Maybe<
    Pick<Types.IActivityFollowingChangeEvent, 'id'> & {
      item?: Types.Maybe<IActivityFollowingFieldsFragment>
    }
  >
}

export type ISaveManyActivityFollowingsMutationVariables = Types.Exact<{
  activityFollowings:
    | Array<Types.Maybe<Types.IActivityFollowingInput>>
    | Types.Maybe<Types.IActivityFollowingInput>
}>

export type ISaveManyActivityFollowingsMutation = {
  saveManyActivityFollowings?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IActivityFollowing, 'id'>>>
  >
}

export type IDeleteManyActivityFollowingsMutationVariables = Types.Exact<{
  activityFollowingIds:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteManyActivityFollowingsMutation = Pick<
  Types.IMutation,
  'deleteManyActivityFollowings'
>

export const ActivityFollowingFieldsFragmentDoc = gql`
  fragment ActivityFollowingFields on ActivityFollowing {
    id
    projectId
    entityId
    userId
    createdAt
    updatedAt
  }
`
export const GetActivityFollowingListDocument = gql`
  query GetActivityFollowingList($projectId: ObjectId!) {
    activityFollowings(limit: 1000000, projectId: $projectId) {
      data {
        ...ActivityFollowingFields
      }
    }
  }
  ${ActivityFollowingFieldsFragmentDoc}
`

/**
 * __useGetActivityFollowingListQuery__
 *
 * To run a query within a React component, call `useGetActivityFollowingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityFollowingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityFollowingListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetActivityFollowingListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetActivityFollowingListQuery,
    IGetActivityFollowingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetActivityFollowingListQuery,
    IGetActivityFollowingListQueryVariables
  >(GetActivityFollowingListDocument, options)
}
export function useGetActivityFollowingListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetActivityFollowingListQuery,
    IGetActivityFollowingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetActivityFollowingListQuery,
    IGetActivityFollowingListQueryVariables
  >(GetActivityFollowingListDocument, options)
}
export type GetActivityFollowingListQueryHookResult = ReturnType<
  typeof useGetActivityFollowingListQuery
>
export type GetActivityFollowingListLazyQueryHookResult = ReturnType<
  typeof useGetActivityFollowingListLazyQuery
>
export type GetActivityFollowingListQueryResult = Apollo.QueryResult<
  IGetActivityFollowingListQuery,
  IGetActivityFollowingListQueryVariables
>
export const ListenToActivityFollowingDocument = gql`
  subscription ListenToActivityFollowing($projectId: ObjectId!) {
    activityFollowing(projectId: $projectId) {
      id
      item {
        ...ActivityFollowingFields
      }
    }
  }
  ${ActivityFollowingFieldsFragmentDoc}
`

/**
 * __useListenToActivityFollowingSubscription__
 *
 * To run a query within a React component, call `useListenToActivityFollowingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToActivityFollowingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToActivityFollowingSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToActivityFollowingSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToActivityFollowingSubscription,
    IListenToActivityFollowingSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToActivityFollowingSubscription,
    IListenToActivityFollowingSubscriptionVariables
  >(ListenToActivityFollowingDocument, options)
}
export type ListenToActivityFollowingSubscriptionHookResult = ReturnType<
  typeof useListenToActivityFollowingSubscription
>
export type ListenToActivityFollowingSubscriptionResult =
  Apollo.SubscriptionResult<IListenToActivityFollowingSubscription>
export const SaveManyActivityFollowingsDocument = gql`
  mutation SaveManyActivityFollowings(
    $activityFollowings: [ActivityFollowingInput]!
  ) {
    saveManyActivityFollowings(activityFollowings: $activityFollowings) {
      id
    }
  }
`
export type ISaveManyActivityFollowingsMutationFn = Apollo.MutationFunction<
  ISaveManyActivityFollowingsMutation,
  ISaveManyActivityFollowingsMutationVariables
>

/**
 * __useSaveManyActivityFollowingsMutation__
 *
 * To run a mutation, you first call `useSaveManyActivityFollowingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyActivityFollowingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyActivityFollowingsMutation, { data, loading, error }] = useSaveManyActivityFollowingsMutation({
 *   variables: {
 *      activityFollowings: // value for 'activityFollowings'
 *   },
 * });
 */
export function useSaveManyActivityFollowingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyActivityFollowingsMutation,
    ISaveManyActivityFollowingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyActivityFollowingsMutation,
    ISaveManyActivityFollowingsMutationVariables
  >(SaveManyActivityFollowingsDocument, options)
}
export type SaveManyActivityFollowingsMutationHookResult = ReturnType<
  typeof useSaveManyActivityFollowingsMutation
>
export type SaveManyActivityFollowingsMutationResult =
  Apollo.MutationResult<ISaveManyActivityFollowingsMutation>
export type SaveManyActivityFollowingsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveManyActivityFollowingsMutation,
    ISaveManyActivityFollowingsMutationVariables
  >
export const DeleteManyActivityFollowingsDocument = gql`
  mutation DeleteManyActivityFollowings($activityFollowingIds: [ObjectId]!) {
    deleteManyActivityFollowings(ids: $activityFollowingIds)
  }
`
export type IDeleteManyActivityFollowingsMutationFn = Apollo.MutationFunction<
  IDeleteManyActivityFollowingsMutation,
  IDeleteManyActivityFollowingsMutationVariables
>

/**
 * __useDeleteManyActivityFollowingsMutation__
 *
 * To run a mutation, you first call `useDeleteManyActivityFollowingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyActivityFollowingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyActivityFollowingsMutation, { data, loading, error }] = useDeleteManyActivityFollowingsMutation({
 *   variables: {
 *      activityFollowingIds: // value for 'activityFollowingIds'
 *   },
 * });
 */
export function useDeleteManyActivityFollowingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteManyActivityFollowingsMutation,
    IDeleteManyActivityFollowingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteManyActivityFollowingsMutation,
    IDeleteManyActivityFollowingsMutationVariables
  >(DeleteManyActivityFollowingsDocument, options)
}
export type DeleteManyActivityFollowingsMutationHookResult = ReturnType<
  typeof useDeleteManyActivityFollowingsMutation
>
export type DeleteManyActivityFollowingsMutationResult =
  Apollo.MutationResult<IDeleteManyActivityFollowingsMutation>
export type DeleteManyActivityFollowingsMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteManyActivityFollowingsMutation,
    IDeleteManyActivityFollowingsMutationVariables
  >
