import * as React from 'react'

import {
  Classes,
  Icon,
  Popover,
  PopoverPosition,
  PopperModifiers,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import SelectWrapper from '~/client/src/shared/components/SelectWrapper/SelectWrapper'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { enumToList } from '~/client/src/shared/utils/converters'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

// localization: translated

interface IProps<T> {
  items: T[]
  newItemIcon: JSX.Element
  newItemText: string
  onNewItemClick(): void
  renderActiveItems(items: T[]): JSX.Element
  renderDoneItems(items: T[]): JSX.Element
  doneFilter(item: T): boolean
  dateRangeFilter(item: T, startDate: Date, endDate: Date): boolean

  onModalClose?(): void

  isNewItemBtnHidden?: boolean
  isNewItemBtnDisabled?: boolean
  isNewValueShown?: boolean
  isTotalDoneValueShown?: boolean
  isDateRangeShown?: boolean

  doneTableText?: string
  getTotalDoneValue?(doneItems: T[]): React.ReactText

  projectDateStore?: ProjectDateStore
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: {
    enabled: true,
    padding: 5,
    boundariesElement: 'window',
  },
  hide: { enabled: true },
  arrow: { enabled: false },
  computeStyle: { gpuAcceleration: false },
}

enum DataDateRange {
  ALL = 'All',
  THIS_WEEK = 'This week',
  THIS_MONTH = 'This month',
}
const dataDateRangeList = enumToList(DataDateRange)
const getDateRangeCaption = (range: DataDateRange): string => {
  switch (range) {
    case DataDateRange.ALL:
      return Localization.translator.all_items
    case DataDateRange.THIS_WEEK:
      return Localization.translator.thisWeek
    case DataDateRange.THIS_MONTH:
      return Localization.translator.thisMonth
  }
}

const DEFAULT_ICON_SIZE = 16

@inject('projectDateStore')
@observer
export default class BaseDataCell<T> extends React.Component<IProps<T>> {
  @observable private dataDateRange = DataDateRange.ALL
  @observable private areDoneItemsShown = false

  public render() {
    if (!this.props.items?.length) {
      return this.noValueRenderer
    }

    return (
      <Popover
        popoverClassName="beautiful-shadow form-cell-modal"
        targetClassName="full-width"
        position={PopoverPosition.BOTTOM}
        modifiers={popoverPopperModifiers}
        content={this.itemsDataTable}
        onClosed={this.resetAllValues}
      >
        {this.props.children}
      </Popover>
    )
  }

  private get noValueRenderer(): JSX.Element {
    const { isNewValueShown, onNewItemClick } = this.props
    if (!isNewValueShown) {
      return <span>{NO_VALUE}</span>
    }

    return (
      <div onClick={this.stopPropagation}>
        <div
          className="row underline-hover form-cell-value pointer h48 no-select"
          onClick={onNewItemClick}
        >
          <Icons.Plus className="form-cell-add-icon relative no-flex mr5" />
          {Localization.translator.new}
        </div>
      </div>
    )
  }

  private get itemsDataTable(): JSX.Element {
    const {
      renderActiveItems,
      onNewItemClick,
      isDateRangeShown,
      newItemIcon,
      newItemText,
      isNewItemBtnHidden,
      isNewItemBtnDisabled,
    } = this.props

    return (
      <div className="py10 px10 col">
        <div className="row x-between h36 m5 pl10">
          {!isNewItemBtnHidden && (
            <div
              className={classList({
                'row no-grow text large blue-highlight pointer underline-hover':
                  true,
                [Classes.POPOVER_DISMISS]: true,
                'inactive-element': isNewItemBtnDisabled,
              })}
              onClick={onNewItemClick}
            >
              {newItemIcon}
              <span className="mr5 no-grow nowrap">{newItemText}</span>
            </div>
          )}
          {isDateRangeShown && (
            <SelectWrapper className="no-grow mr10">
              <select
                value={this.dataDateRange}
                onChange={this.changeDateRange}
                className="pl12 pr25 py6 ba-none no-outline bg-inherit text large light no-grow pointer"
              >
                {dataDateRangeList.map(range => (
                  <option key={range} value={range}>
                    {getDateRangeCaption(range)}
                  </option>
                ))}
              </select>
            </SelectWrapper>
          )}
        </div>
        {renderActiveItems(this.activeItems)}
        {this.doneItemsTable}
      </div>
    )
  }

  private get doneItemsTable(): JSX.Element {
    if (!this.doneItems.length) {
      return null
    }

    const {
      renderDoneItems,
      isTotalDoneValueShown,
      getTotalDoneValue,
      doneTableText,
    } = this.props
    const toggleIcon = this.areDoneItemsShown
      ? IconNames.CHEVRON_DOWN
      : IconNames.CHEVRON_RIGHT

    return (
      <>
        <div
          className={classList({
            'row h36 px10 pointer no-select': true,
            mt15: !!this.activeItems.length,
          })}
          onClick={this.toggleDoneItems}
        >
          <div className="no-flex mr50 mw300 text large faded">
            <Icon className="mr5" icon={toggleIcon} size={DEFAULT_ICON_SIZE} />
            {doneTableText} ({this.doneItems.length})
          </div>
          {isTotalDoneValueShown && !this.areDoneItemsShown && (
            <>
              <div className="mw80 ml30 no-flex text large faded end">
                {getTotalDoneValue(this.doneItems)}
              </div>
              <div />
            </>
          )}
        </div>
        {this.areDoneItemsShown && renderDoneItems(this.doneItems)}
      </>
    )
  }

  private get activeItems(): T[] {
    return this.filteredItems.filter(i => !this.props.doneFilter(i))
  }

  private get doneItems(): T[] {
    return this.filteredItems.filter(this.props.doneFilter)
  }

  @computed
  private get filteredItems(): T[] {
    const { items, projectDateStore, dateRangeFilter } = this.props
    const { startOfWeek, endOfWeek, startOfMonth, endOfMonth } =
      projectDateStore

    const dateNow = Date.now()
    let startDate: Date, endDate: Date

    switch (this.dataDateRange) {
      case DataDateRange.ALL:
        return items
      case DataDateRange.THIS_WEEK:
        startDate = startOfWeek(dateNow)
        endDate = endOfWeek(dateNow)
        return items.filter(i => dateRangeFilter(i, startDate, endDate))
      case DataDateRange.THIS_MONTH:
        startDate = startOfMonth(dateNow)
        endDate = endOfMonth(dateNow)
        return items.filter(i => dateRangeFilter(i, startDate, endDate))
      default:
        return []
    }
  }

  private stopPropagation = (event: React.SyntheticEvent) => {
    event?.stopPropagation()
    event?.preventDefault()
  }

  @action.bound
  private changeDateRange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.dataDateRange = event.target.value as DataDateRange
  }

  @action.bound
  private toggleDoneItems() {
    this.areDoneItemsShown = !this.areDoneItemsShown
  }

  @action.bound
  private resetAllValues() {
    this.dataDateRange = DataDateRange.ALL
    this.areDoneItemsShown = false
    this.props.onModalClose?.()
  }
}
