import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

interface IProps {
  headerClassName: string
  headerTitle: string
  entitiesCount: number
  isSectionExpanded: boolean
  onCollapseClick: () => void
  onHeaderClick: () => void
  headerIcon: (props: { className: string }) => JSX.Element
}

const { CARET_DOWN, CARET_RIGHT } = IconNames

const ICON_SIZE = 12

@observer
export default class LogisticsSideBarContent extends React.Component<IProps> {
  public render() {
    const {
      headerClassName,
      headerTitle,
      entitiesCount,
      isSectionExpanded,
      headerIcon,
      onCollapseClick,
      onHeaderClick,
      children,
    } = this.props
    const icon = isSectionExpanded ? CARET_DOWN : CARET_RIGHT
    const shouldShowContent = !!entitiesCount && isSectionExpanded

    return (
      <>
        <div
          className={classList({
            'logistics-side-bar-header sticky bg-grey-scale-light row px12 y-center':
              true,
            [headerClassName]: !!headerClassName,
          })}
        >
          <Icon
            className="mr10 text white pointer no-select"
            icon={icon}
            onClick={onCollapseClick}
            size={ICON_SIZE}
          />
          {headerIcon({ className: 'side-bar-section-icon row no-grow' })}
          <div
            className="text white header pl8 pointer"
            onClick={onHeaderClick}
          >
            {headerTitle}
          </div>
          {!!entitiesCount && (
            <div className="text small-header white no-grow mx5">
              {entitiesCount}
            </div>
          )}
        </div>
        {shouldShowContent && children}
      </>
    )
  }
}
