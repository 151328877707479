import { EMPTY_STRING } from '../../utils/usefulStrings'
import { IDeliveryControlOption } from '../IDeliveryControl'
import DeliveryControlSearchPredicateBase from './DeliveryControlSearchPredicateBase'

export default class ActivitiesDeliveryControlSearchPredicate extends DeliveryControlSearchPredicateBase {
  protected testCondition(
    option: IDeliveryControlOption,
    searchCriteria: string,
  ) {
    if (super.testCondition(option, searchCriteria)) return true

    return (option.extraFields?.code || EMPTY_STRING)
      ?.toLowerCase()
      .includes(searchCriteria?.toLowerCase() || EMPTY_STRING)
  }
}
