import * as React from 'react'

import { Icon, Intent, ProgressBar, Spinner } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { UploadMaterialsStatus } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import AlertDialog from '~/client/src/shared/components/AlertDialog/AlertDialog'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import DndArea from '~/client/src/shared/components/DndArea/DndArea'
import { Loader } from '~/client/src/shared/components/Loader'
import UploadButton from '~/client/src/shared/components/UploadButton/UploadButton'
import FileType from '~/client/src/shared/enums/FileType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { FileUploadingStore } from '~/client/src/shared/stores/domain/FileUploading.store'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'

import ProjectSetUpPageStore from '../../ProjectSetUpPage.store'
import MaterialsUploadStore from './MaterialsUpload.store'
import ChooseMaterialsSheetDialog from './components/ChooseMaterialsSheetDialog'
import MapMaterialsFieldsDialog from './components/MapMaterialsFieldsDialog'
import MaterialsUploadConfirmDialog from './components/MaterialsUploadConfirmDialog'
import MaterialsUploadHistory from './components/MaterialsUploadHistory/MaterialsUploadHistory'
import MaterialsUploadHistoryStore from './components/MaterialsUploadHistory/MaterialsUploadHistory.store'
import ReplaceExistingMaterialsDialog from './components/ReplaceExistingMaterialsDialog'

// localization: translated

interface IProps {
  projectSetUpPageStore: ProjectSetUpPageStore

  common?: DesktopCommonStore
  eventsStore?: DesktopEventStore
  graphExecutorStore?: GraphExecutorStore
  fileUploadingStore?: FileUploadingStore
  materialsStore?: MaterialsStore
}

const UPLOAD_ICON_SIZE = 14
const DIALOG_BACK_ICON_SIZE = 18
const SPINNER_SIZE = 20
const ACCEPTED_EXCEL_EXTENSIONS = '.xlsx,.xlsm'

@inject(
  'common',
  'eventsStore',
  'graphExecutorStore',
  'fileUploadingStore',
  'materialsStore',
)
@observer
export default class MaterialsUpload extends React.Component<IProps> {
  private readonly store: MaterialsUploadStore
  private readonly materialsUploadHistoryStore: MaterialsUploadHistoryStore

  public constructor(props: IProps) {
    super(props)

    this.store = new MaterialsUploadStore(
      props.common,
      props.eventsStore,
      props.graphExecutorStore,
      props.fileUploadingStore,
      props.materialsStore,
    )

    this.materialsUploadHistoryStore = new MaterialsUploadHistoryStore(
      props.projectSetUpPageStore,
      props.materialsStore,
    )

    this.store.resetInitialValues()
  }

  public componentDidMount() {
    this.store.loadAndListenToUploadProgress()
  }

  public componentWillUnmount() {
    this.store.terminateSubscription()
  }

  public render() {
    const {
      isLoadingOrUpdating,
      isUploadFailedOrDone,
      navigateToMaterials,
      uploadFile,
    } = this.store

    if (isLoadingOrUpdating) {
      return <Loader hint={Localization.translator.loadingUpdatingProject} />
    }

    return (
      <div className="full-height col ma-auto mw590">
        {this.dialogs}

        <div className="pa12 bb-light-grey mb15 row text bold header uppercase">
          {Localization.translator.materialsUploading.uploadMaterials}
        </div>
        <div
          className={classList({
            pointer: isUploadFailedOrDone,
            'unclickable-element': !isUploadFailedOrDone,
          })}
        >
          <DndArea
            accept={ACCEPTED_EXCEL_EXTENSIONS}
            fileType={FileType.ProjectMaterials}
            onDrop={uploadFile}
            title={this.uploadStatusElement}
          />
        </div>
        <div className="row x-center y-center my20">
          <BaseActionButton
            className="primary-theme-inverted mw300"
            isEnabled
            isGrow
            isAnimated
            title={
              Localization.translator.materialsUploading.goToTheMaterialsPage
            }
            onClick={navigateToMaterials}
          />
        </div>

        <MaterialsUploadHistory
          isUploadingInProcess={!isUploadFailedOrDone}
          materialsUploadHistoryStore={this.materialsUploadHistoryStore}
        />
      </div>
    )
  }

  private get dialogs(): JSX.Element {
    const { isErrorDialogShown, hideErrorDialog } = this.store
    return (
      <>
        <AlertDialog isOpen={isErrorDialogShown} onClose={hideErrorDialog}>
          {Localization.translator.materialsUploading.thereAreNoSheetsError}
        </AlertDialog>
        <ChooseMaterialsSheetDialog store={this.store} />
        <MapMaterialsFieldsDialog
          store={this.store}
          titleRenderer={this.dialogTitleRenderer}
        />
        <MaterialsUploadConfirmDialog
          store={this.store}
          titleRenderer={this.dialogTitleRenderer}
        />
        <ReplaceExistingMaterialsDialog
          store={this.store}
          titleRenderer={this.dialogTitleRenderer}
        />
      </>
    )
  }

  private get uploadStatusElement(): JSX.Element {
    const {
      uploadStatus,
      uploadingProgressPercentage,
      uploadMaterialsProgress,
      isUploadFailedOrDone,
    } = this.store

    const isFailed =
      uploadMaterialsProgress?.status === UploadMaterialsStatus.Failed

    if (isUploadFailedOrDone) {
      return (
        <>
          <UploadButton
            text={
              Localization.translator.materialsUploading.uploadYourMaterialsFile
            }
          />
          <div className="row">
            <span className="text large">
              {Localization.translator.orDragYourFileHere}
            </span>
          </div>
          {!!uploadMaterialsProgress && (
            <div className="row">
              <div className="text extra-large my24 center full-width primary-blue">
                {uploadStatus}
              </div>
              <Icon
                icon={isFailed ? IconNames.ERROR : IconNames.TICK_CIRCLE}
                iconSize={UPLOAD_ICON_SIZE}
                className="mx10"
              />
            </div>
          )}
        </>
      )
    }

    return (
      <div className="mw400">
        <ProgressBar
          intent={Intent.SUCCESS}
          value={uploadingProgressPercentage}
        />
        <div className="row x-center y-center">
          <div className="text extra-large my24 center full-width primary-blue no-grow nowrap">
            {uploadStatus}
          </div>
          <Spinner
            intent={Intent.PRIMARY}
            size={SPINNER_SIZE}
            className="mx10 no-grow"
          />
        </div>
      </div>
    )
  }

  private dialogTitleRenderer = (title: string): JSX.Element => {
    return (
      <div className="row">
        <div
          className="row text large blue-highlight underline-hover no-grow mr10 pointer"
          onClick={this.store.openPreviousDialog}
        >
          <Icon
            icon={IconNames.CHEVRON_LEFT}
            size={DIALOG_BACK_ICON_SIZE}
            className="text blue-highlight no-grow ma0"
          />
          {Localization.translator.goBack}
        </div>
        <div className="row x-center mw300">{title}</div>
      </div>
    )
  }
}
