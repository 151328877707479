import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IOperationRuleFieldsFragment = Pick<
  Types.IOperationRule,
  | 'id'
  | 'expression'
  | 'operation'
  | 'operationSubject'
  | 'assignExpression'
  | 'projectId'
  | 'createdAt'
  | 'updatedAt'
>

export type IOperationRulesByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IOperationRulesByProjectIdQuery = {
  operationRules?: Types.Maybe<{ data: Array<IOperationRuleFieldsFragment> }>
}

export type IListenOperationRuleByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenOperationRuleByProjectIdSubscription = {
  operationRule?: Types.Maybe<
    Pick<Types.IOperationRuleChangeEvent, 'id'> & {
      item?: Types.Maybe<IOperationRuleFieldsFragment>
    }
  >
}

export type ISaveManyOperationRulesMutationVariables = Types.Exact<{
  operationRules:
    | Array<Types.Maybe<Types.IOperationRuleInput>>
    | Types.Maybe<Types.IOperationRuleInput>
}>

export type ISaveManyOperationRulesMutation = {
  saveManyOperationRules?: Types.Maybe<
    Array<Types.Maybe<IOperationRuleFieldsFragment>>
  >
}

export type IDeleteManyOperationRulesMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteManyOperationRulesMutation = Pick<
  Types.IMutation,
  'deleteManyOperationRules'
>

export const OperationRuleFieldsFragmentDoc = gql`
  fragment OperationRuleFields on OperationRule {
    id
    expression
    operation
    operationSubject
    assignExpression
    projectId
    createdAt
    updatedAt
  }
`
export const OperationRulesByProjectIdDocument = gql`
  query OperationRulesByProjectId($projectId: ObjectId!) {
    operationRules(projectId: $projectId, limit: 1000000) {
      data {
        ...OperationRuleFields
      }
    }
  }
  ${OperationRuleFieldsFragmentDoc}
`

/**
 * __useOperationRulesByProjectIdQuery__
 *
 * To run a query within a React component, call `useOperationRulesByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationRulesByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationRulesByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useOperationRulesByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IOperationRulesByProjectIdQuery,
    IOperationRulesByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IOperationRulesByProjectIdQuery,
    IOperationRulesByProjectIdQueryVariables
  >(OperationRulesByProjectIdDocument, options)
}
export function useOperationRulesByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IOperationRulesByProjectIdQuery,
    IOperationRulesByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IOperationRulesByProjectIdQuery,
    IOperationRulesByProjectIdQueryVariables
  >(OperationRulesByProjectIdDocument, options)
}
export type OperationRulesByProjectIdQueryHookResult = ReturnType<
  typeof useOperationRulesByProjectIdQuery
>
export type OperationRulesByProjectIdLazyQueryHookResult = ReturnType<
  typeof useOperationRulesByProjectIdLazyQuery
>
export type OperationRulesByProjectIdQueryResult = Apollo.QueryResult<
  IOperationRulesByProjectIdQuery,
  IOperationRulesByProjectIdQueryVariables
>
export const ListenOperationRuleByProjectIdDocument = gql`
  subscription ListenOperationRuleByProjectId($projectId: ObjectId!) {
    operationRule(projectId: $projectId) {
      id
      item {
        ...OperationRuleFields
      }
    }
  }
  ${OperationRuleFieldsFragmentDoc}
`

/**
 * __useListenOperationRuleByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenOperationRuleByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenOperationRuleByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenOperationRuleByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenOperationRuleByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenOperationRuleByProjectIdSubscription,
    IListenOperationRuleByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenOperationRuleByProjectIdSubscription,
    IListenOperationRuleByProjectIdSubscriptionVariables
  >(ListenOperationRuleByProjectIdDocument, options)
}
export type ListenOperationRuleByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenOperationRuleByProjectIdSubscription
>
export type ListenOperationRuleByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenOperationRuleByProjectIdSubscription>
export const SaveManyOperationRulesDocument = gql`
  mutation SaveManyOperationRules($operationRules: [OperationRuleInput]!) {
    saveManyOperationRules(operationRules: $operationRules) {
      ...OperationRuleFields
    }
  }
  ${OperationRuleFieldsFragmentDoc}
`
export type ISaveManyOperationRulesMutationFn = Apollo.MutationFunction<
  ISaveManyOperationRulesMutation,
  ISaveManyOperationRulesMutationVariables
>

/**
 * __useSaveManyOperationRulesMutation__
 *
 * To run a mutation, you first call `useSaveManyOperationRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyOperationRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyOperationRulesMutation, { data, loading, error }] = useSaveManyOperationRulesMutation({
 *   variables: {
 *      operationRules: // value for 'operationRules'
 *   },
 * });
 */
export function useSaveManyOperationRulesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyOperationRulesMutation,
    ISaveManyOperationRulesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyOperationRulesMutation,
    ISaveManyOperationRulesMutationVariables
  >(SaveManyOperationRulesDocument, options)
}
export type SaveManyOperationRulesMutationHookResult = ReturnType<
  typeof useSaveManyOperationRulesMutation
>
export type SaveManyOperationRulesMutationResult =
  Apollo.MutationResult<ISaveManyOperationRulesMutation>
export type SaveManyOperationRulesMutationOptions = Apollo.BaseMutationOptions<
  ISaveManyOperationRulesMutation,
  ISaveManyOperationRulesMutationVariables
>
export const DeleteManyOperationRulesDocument = gql`
  mutation DeleteManyOperationRules($ids: [ObjectId]!) {
    deleteManyOperationRules(ids: $ids)
  }
`
export type IDeleteManyOperationRulesMutationFn = Apollo.MutationFunction<
  IDeleteManyOperationRulesMutation,
  IDeleteManyOperationRulesMutationVariables
>

/**
 * __useDeleteManyOperationRulesMutation__
 *
 * To run a mutation, you first call `useDeleteManyOperationRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyOperationRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyOperationRulesMutation, { data, loading, error }] = useDeleteManyOperationRulesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteManyOperationRulesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteManyOperationRulesMutation,
    IDeleteManyOperationRulesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteManyOperationRulesMutation,
    IDeleteManyOperationRulesMutationVariables
  >(DeleteManyOperationRulesDocument, options)
}
export type DeleteManyOperationRulesMutationHookResult = ReturnType<
  typeof useDeleteManyOperationRulesMutation
>
export type DeleteManyOperationRulesMutationResult =
  Apollo.MutationResult<IDeleteManyOperationRulesMutation>
export type DeleteManyOperationRulesMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteManyOperationRulesMutation,
    IDeleteManyOperationRulesMutationVariables
  >
