import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IClosureFollowingFieldsFragment = Pick<
  Types.IClosureFollowing,
  'id' | 'projectId' | 'entityId' | 'userId' | 'createdAt' | 'updatedAt'
>

export type IGetClosureFollowingListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetClosureFollowingListQuery = {
  closureFollowings?: Types.Maybe<{
    data: Array<IClosureFollowingFieldsFragment>
  }>
}

export type IListenToClosureFollowingSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToClosureFollowingSubscription = {
  closureFollowing?: Types.Maybe<
    Pick<Types.IClosureFollowingChangeEvent, 'id'> & {
      item?: Types.Maybe<IClosureFollowingFieldsFragment>
    }
  >
}

export type ISaveManyClosureFollowingsMutationVariables = Types.Exact<{
  closureFollowings:
    | Array<Types.Maybe<Types.IClosureFollowingInput>>
    | Types.Maybe<Types.IClosureFollowingInput>
}>

export type ISaveManyClosureFollowingsMutation = {
  saveManyClosureFollowings?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IClosureFollowing, 'id'>>>
  >
}

export type IDeleteManyClosureFollowingsMutationVariables = Types.Exact<{
  closureFollowingIds:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteManyClosureFollowingsMutation = Pick<
  Types.IMutation,
  'deleteManyClosureFollowings'
>

export const ClosureFollowingFieldsFragmentDoc = gql`
  fragment ClosureFollowingFields on ClosureFollowing {
    id
    projectId
    entityId
    userId
    createdAt
    updatedAt
  }
`
export const GetClosureFollowingListDocument = gql`
  query GetClosureFollowingList($projectId: ObjectId!) {
    closureFollowings(limit: 1000000, projectId: $projectId) {
      data {
        ...ClosureFollowingFields
      }
    }
  }
  ${ClosureFollowingFieldsFragmentDoc}
`

/**
 * __useGetClosureFollowingListQuery__
 *
 * To run a query within a React component, call `useGetClosureFollowingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClosureFollowingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClosureFollowingListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetClosureFollowingListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetClosureFollowingListQuery,
    IGetClosureFollowingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetClosureFollowingListQuery,
    IGetClosureFollowingListQueryVariables
  >(GetClosureFollowingListDocument, options)
}
export function useGetClosureFollowingListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetClosureFollowingListQuery,
    IGetClosureFollowingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetClosureFollowingListQuery,
    IGetClosureFollowingListQueryVariables
  >(GetClosureFollowingListDocument, options)
}
export type GetClosureFollowingListQueryHookResult = ReturnType<
  typeof useGetClosureFollowingListQuery
>
export type GetClosureFollowingListLazyQueryHookResult = ReturnType<
  typeof useGetClosureFollowingListLazyQuery
>
export type GetClosureFollowingListQueryResult = Apollo.QueryResult<
  IGetClosureFollowingListQuery,
  IGetClosureFollowingListQueryVariables
>
export const ListenToClosureFollowingDocument = gql`
  subscription ListenToClosureFollowing($projectId: ObjectId!) {
    closureFollowing(projectId: $projectId) {
      id
      item {
        ...ClosureFollowingFields
      }
    }
  }
  ${ClosureFollowingFieldsFragmentDoc}
`

/**
 * __useListenToClosureFollowingSubscription__
 *
 * To run a query within a React component, call `useListenToClosureFollowingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToClosureFollowingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToClosureFollowingSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToClosureFollowingSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToClosureFollowingSubscription,
    IListenToClosureFollowingSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToClosureFollowingSubscription,
    IListenToClosureFollowingSubscriptionVariables
  >(ListenToClosureFollowingDocument, options)
}
export type ListenToClosureFollowingSubscriptionHookResult = ReturnType<
  typeof useListenToClosureFollowingSubscription
>
export type ListenToClosureFollowingSubscriptionResult =
  Apollo.SubscriptionResult<IListenToClosureFollowingSubscription>
export const SaveManyClosureFollowingsDocument = gql`
  mutation SaveManyClosureFollowings(
    $closureFollowings: [ClosureFollowingInput]!
  ) {
    saveManyClosureFollowings(closureFollowings: $closureFollowings) {
      id
    }
  }
`
export type ISaveManyClosureFollowingsMutationFn = Apollo.MutationFunction<
  ISaveManyClosureFollowingsMutation,
  ISaveManyClosureFollowingsMutationVariables
>

/**
 * __useSaveManyClosureFollowingsMutation__
 *
 * To run a mutation, you first call `useSaveManyClosureFollowingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyClosureFollowingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyClosureFollowingsMutation, { data, loading, error }] = useSaveManyClosureFollowingsMutation({
 *   variables: {
 *      closureFollowings: // value for 'closureFollowings'
 *   },
 * });
 */
export function useSaveManyClosureFollowingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyClosureFollowingsMutation,
    ISaveManyClosureFollowingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyClosureFollowingsMutation,
    ISaveManyClosureFollowingsMutationVariables
  >(SaveManyClosureFollowingsDocument, options)
}
export type SaveManyClosureFollowingsMutationHookResult = ReturnType<
  typeof useSaveManyClosureFollowingsMutation
>
export type SaveManyClosureFollowingsMutationResult =
  Apollo.MutationResult<ISaveManyClosureFollowingsMutation>
export type SaveManyClosureFollowingsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveManyClosureFollowingsMutation,
    ISaveManyClosureFollowingsMutationVariables
  >
export const DeleteManyClosureFollowingsDocument = gql`
  mutation DeleteManyClosureFollowings($closureFollowingIds: [ObjectId]!) {
    deleteManyClosureFollowings(ids: $closureFollowingIds)
  }
`
export type IDeleteManyClosureFollowingsMutationFn = Apollo.MutationFunction<
  IDeleteManyClosureFollowingsMutation,
  IDeleteManyClosureFollowingsMutationVariables
>

/**
 * __useDeleteManyClosureFollowingsMutation__
 *
 * To run a mutation, you first call `useDeleteManyClosureFollowingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyClosureFollowingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyClosureFollowingsMutation, { data, loading, error }] = useDeleteManyClosureFollowingsMutation({
 *   variables: {
 *      closureFollowingIds: // value for 'closureFollowingIds'
 *   },
 * });
 */
export function useDeleteManyClosureFollowingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteManyClosureFollowingsMutation,
    IDeleteManyClosureFollowingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteManyClosureFollowingsMutation,
    IDeleteManyClosureFollowingsMutationVariables
  >(DeleteManyClosureFollowingsDocument, options)
}
export type DeleteManyClosureFollowingsMutationHookResult = ReturnType<
  typeof useDeleteManyClosureFollowingsMutation
>
export type DeleteManyClosureFollowingsMutationResult =
  Apollo.MutationResult<IDeleteManyClosureFollowingsMutation>
export type DeleteManyClosureFollowingsMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteManyClosureFollowingsMutation,
    IDeleteManyClosureFollowingsMutationVariables
  >
