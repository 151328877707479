import Localization from '../localization/LocalizationManager'

enum DeliveryPinLocationOption {
  mostPertinent = 'Most pertinent (default)',
  building = 'Building',
  zone = 'Zones',
  gate = 'Gates',
  route = 'Routes',
  equipment = 'Offloading Equipment',
  area = 'Areas',
  level = 'Levels',
  staging = 'Stagings',
  interiorDoor = 'Interior Routes',
  interiorPath = 'Interior Paths',
}

export const getDeliveryPinLocationOptionDisplayName = (
  option: DeliveryPinLocationOption,
) => {
  switch (option) {
    case DeliveryPinLocationOption.mostPertinent:
      return Localization.translator.mostPertinentDefault
    case DeliveryPinLocationOption.building:
      return Localization.translator.building
    case DeliveryPinLocationOption.zone:
      return Localization.translator.zones
    case DeliveryPinLocationOption.gate:
      return Localization.translator.gates
    case DeliveryPinLocationOption.route:
      return Localization.translator.routes
    case DeliveryPinLocationOption.equipment:
      return Localization.translator.offloadingEquipment
    case DeliveryPinLocationOption.area:
      return Localization.translator.areas
    case DeliveryPinLocationOption.level:
      return Localization.translator.levels
    case DeliveryPinLocationOption.interiorPath:
      return Localization.translator.interiorPath
    case DeliveryPinLocationOption.interiorDoor:
      return Localization.translator.interiorDoor
    case DeliveryPinLocationOption.staging:
      return Localization.translator.staging
    default:
      throw new Error(option + ' is not handled')
  }
}

export default DeliveryPinLocationOption
