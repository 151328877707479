import { DefaultCompanyType } from '~/client/graph'

import Localization from '../localization/LocalizationManager'

export default function getCompanyTypeTranslate(value: string) {
  switch (value) {
    case DefaultCompanyType.GeneralContractorPrimary:
      return Localization.translator.companyTypes.GeneralContractorPrimary
    case DefaultCompanyType.GeneralContractorSecondary:
      return Localization.translator.companyTypes.GeneralContractorSecondary
    case DefaultCompanyType.Owner:
      return Localization.translator.companyTypes.Owner
    case DefaultCompanyType.SubContractor:
      return Localization.translator.companyTypes.SubContractor
    case DefaultCompanyType.TieredSubContractor:
      return Localization.translator.companyTypes.TieredSubContractor
    default:
      return value
  }
}
