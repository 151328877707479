import * as React from 'react'

import { action } from 'mobx'
import { observer } from 'mobx-react'

import User from '~/client/src/shared/models/User'

import Localization from '../../localization/LocalizationManager'
import { ITag } from '../../models/Tag'
import BaseActionButton from '../BaseActionButton/BaseActionButton'
import UsersDirectory from '../UsersDirectory/UsersDirectory'

interface IProps {
  appliedUsers: User[]
  onApply: (selectedUserIds: string[]) => void
  onClose: () => void
}

@observer
export default class AllowedUsersPicker extends React.Component<IProps> {
  private selectedUserIds: string[] = []

  public constructor(props: IProps) {
    super(props)

    this.selectedUserIds = props.appliedUsers.map(u => u.id)
  }

  public render() {
    const { appliedUsers, onClose } = this.props

    return (
      <UsersDirectory
        shouldUseAllProjectMembers
        isSelectionMode
        selectedUsers={appliedUsers}
        onItemSelect={this.selectUser}
        onHeaderCloseClick={onClose}
        applyButton={
          <BaseActionButton
            isEnabled={true}
            className="scale-blue-theme"
            title={Localization.translator.apply}
            onClick={this.handleApply}
          />
        }
      />
    )
  }

  public selectUser = (userTags: ITag[]) => {
    this.selectedUserIds = userTags.map(ut => ut.id)
  }

  @action.bound
  private handleApply() {
    this.props.onApply(this.selectedUserIds)
  }
}
