import { action, computed } from 'mobx'

import { UNASSIGNED_FILTER_OPTION } from '~/client/src/shared/components/Deliveries/DeliveriesView.store'
import { LocalStorageKey } from '~/client/src/shared/enums/LocalStorageKey'
import ScanStationFilterType, {
  getScanStationFilterTypeCaption,
  scanStationFilterTypes,
} from '~/client/src/shared/enums/ScanStationFilterType'
import IScannerHistoryPair from '~/client/src/shared/models/IScannerHistoryPair'
import UserProject from '~/client/src/shared/models/UserProject'
import { RESET_ALL_FILTERS } from '~/client/src/shared/stores/EventStore/eventConstants'

import BaseReportsFilterStore, { ISourceMap } from './BaseReportsFilter.store'
import ReportsFilterStore from './ReportsFilter.store'

export default class ScanStationFilterStore extends ReportsFilterStore {
  protected get availableInstances(): IScannerHistoryPair[] {
    return this.instancesStore.allScanStationHistory
  }

  @computed
  public get filterStoresByTypeMap(): {
    [filterType: string]: BaseReportsFilterStore
  } {
    const map: { [filterType: string]: BaseReportsFilterStore } = {}

    scanStationFilterTypes.forEach(filterType => {
      const { appState } = this.eventsStore

      map[filterType] = new BaseReportsFilterStore(
        filterType,
        appState,
        this.sourceMapByFilterTypeMap[filterType],
        this.instancesListStore,
        this.onShowChanged,
        appState.scanStationFilters.fieldsMap,
        getScanStationFilterTypeCaption,
        this.getOptionName,
        LocalStorageKey.ScanStationReportFilter,
      )
    })
    return map
  }

  @action.bound
  public resetAllFilters() {
    scanStationFilterTypes.forEach(type =>
      localStorage.removeItem(
        `${this.eventsStore.appState.activeProject.id}_${LocalStorageKey.ScanStationReportFilter}_${type}`,
      ),
    )
    this.eventsStore.dispatch(RESET_ALL_FILTERS)
  }

  @computed
  protected get sourceMapByFilterTypeMap(): {
    [filterType: string]: ISourceMap
  } {
    const maps = scanStationFilterTypes.reduce((acc, filterType) => {
      acc[filterType] = this.getDefaultSourceMapByType(filterType)
      return acc
    }, {})

    this.availableInstances.forEach(instance => {
      Object.keys(maps).forEach(filterType => {
        const map = maps[filterType]
        let optionIds: string[] = []

        switch (filterType) {
          case ScanStationFilterType.COMPANY:
            const user = this.projectMembersStore.getById(
              instance.history.userId,
            )
            if (user) {
              const companyId = UserProject.getCompanyId(
                user,
                this.userProjectsStore,
              )
              optionIds = [companyId || UNASSIGNED_FILTER_OPTION]
            } else {
              optionIds = [UNASSIGNED_FILTER_OPTION]
            }
            break
          case ScanStationFilterType.SCANNER:
            optionIds = [instance.history.scannerId || UNASSIGNED_FILTER_OPTION]
            break
        }

        optionIds.forEach(optionId => {
          if (!map[optionId]) {
            optionId = UNASSIGNED_FILTER_OPTION
          }

          map[optionId].push(instance.id)
        })
      })
    })
    return maps
  }

  protected getDefaultSourceMapByType(type: ScanStationFilterType) {
    let sourceList: string[] = []

    switch (type) {
      case ScanStationFilterType.SCANNER:
        sourceList = [
          ...this.scannersStore.scannersList.map(scanner => scanner.id),
          UNASSIGNED_FILTER_OPTION,
        ]
        break
      case ScanStationFilterType.COMPANY:
        sourceList = [
          ...this.companiesStore.availableSortedCompanies.map(c => c.id),
          UNASSIGNED_FILTER_OPTION,
        ]
        break
    }

    return sourceList.reduce((acc, optionId) => {
      acc[optionId] = []
      return acc
    }, {})
  }

  protected fieldsMap() {
    return this.eventsStore.appState.scanStationFilters.fieldsMap
  }
}
