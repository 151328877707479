import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ProjectDateStore, {
  isAfter,
  isBefore,
  isBetween,
} from '~/client/src/shared/stores/ui/ProjectDate.store'

import './CommonDatePicker.scss'

export interface CommonDatePickerProps {
  monthDate?: Date
  dateClickHandler: (event: Event, date: Date) => void
  shouldDateBeDisabled?: (date: number | Date) => boolean
  customRenderDayOfMonth?: (dayDate: Date, dayOfWeek?: number) => JSX.Element
  maxDate?: Date
  minDate?: Date
  isOneDayMode?: boolean
  startDate?: Date
  endDate?: Date
  withoutHeader?: boolean
}

interface IProps {
  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
export default class CommonDatePicker extends React.Component<
  CommonDatePickerProps & IProps
> {
  public render() {
    return (
      <div className="dp-container">
        {!this.props.withoutHeader && this.renderWeekDaysHeader()}
        {this.renderMonthDates()}
      </div>
    )
  }

  private onDateClick(event: Event, date: Date) {
    const {
      minDate,
      maxDate,
      projectDateStore: { isSameDay },
    } = this.props
    if (
      this.props.shouldDateBeDisabled &&
      this.props.shouldDateBeDisabled(date)
    ) {
      return
    }

    if (minDate && !isSameDay(date, minDate) && isBefore(date, minDate)) {
      return this.props.dateClickHandler(event, minDate)
    }
    if (maxDate && !isSameDay(date, minDate) && isAfter(date, maxDate)) {
      return this.props.dateClickHandler(event, maxDate)
    }
    return this.props.dateClickHandler(event, date)
  }

  private isDisabledDate(date) {
    const { minDate, maxDate, projectDateStore } = this.props
    const { isSameDay } = projectDateStore

    if (this.props.shouldDateBeDisabled) {
      return this.props.shouldDateBeDisabled(date)
    }

    const isBeforeMinDate =
      minDate && !isSameDay(new Date(), date) && isBefore(date, minDate)
    const isAfterMaxDate =
      maxDate && !isSameDay(new Date(), date) && isAfter(date, maxDate)

    return isBeforeMinDate || isAfterMaxDate
  }

  private renderDayOfMonth(dayDate: Date) {
    const {
      projectDateStore,
      startDate: startD,
      endDate: endD,
      isOneDayMode,
    } = this.props
    const { getDayOfMonthToDisplay, startOfDay, endOfDay, isToday, isSameDay } =
      projectDateStore

    const day = dayDate ? getDayOfMonthToDisplay(dayDate) : ''

    const startDate = startD && startOfDay(startD)
    const endDate = startD && endOfDay(endD)

    const isOneDayCell = isSameDay(startDate, endDate)
    const isSameDayCell = isOneDayMode && isSameDay(startDate, dayDate)
    const isStartRangeCell = !isOneDayMode && isSameDay(startDate, dayDate)
    const isEndRangeCell = !isOneDayMode && isSameDay(endDate, dayDate)
    const isBetweenRange =
      !isOneDayMode &&
      isBetween(startDate, endDate, dayDate) &&
      !isStartRangeCell &&
      !isEndRangeCell

    return (
      <div
        className={classList({
          'p-cell row x-center relative text large bold lp05 mt5': true,
          'p-range-start': isStartRangeCell && !isOneDayCell,
          'p-range-end': isEndRangeCell && !isOneDayCell,
          'p-range-between': isBetweenRange,
          'p-highlight': isSameDayCell || isStartRangeCell || isEndRangeCell,
          'p-today': isToday(dayDate),
        })}
      >
        {day}
      </div>
    )
  }

  private renderCellWrapper(dayDate: Date, dayOfWeek: number) {
    const {
      projectDateStore: { isSameMonth, isWorkingDay },
      customRenderDayOfMonth,
      monthDate,
    } = this.props
    const clickHandler = e => this.onDateClick?.(e, dayDate)
    return (
      <div
        className={classList({
          'p-cell-wrapper bb-light-grey': true,
          'p-disabled-date': this.isDisabledDate(dayDate),
          'p-non-working-date': !isWorkingDay(dayDate),
          opacity4: !isSameMonth(dayDate, monthDate),
        })}
        key={(dayDate || 'empty') + '-' + dayDate}
        onClick={clickHandler}
      >
        {' '}
        {customRenderDayOfMonth
          ? customRenderDayOfMonth(dayDate, dayOfWeek)
          : this.renderDayOfMonth(dayDate)}
      </div>
    )
  }

  private renderWeekDates(weekDate: Date) {
    const {
      projectDateStore: {
        startOfWeek,
        endOfWeek,
        addDays,
        getMonthDayAndYearToDisplay,
      },
    } = this.props
    const weekStart = startOfWeek(weekDate)
    const weekEnd = endOfWeek(weekDate)

    const weekDays: Date[] = []
    let nextDay = weekStart
    while (weekDays.length < 7) {
      weekDays.push(nextDay)
      nextDay = addDays(nextDay, 1)
    }

    return (
      <div className="row x-center" key={getMonthDayAndYearToDisplay(weekEnd)}>
        {weekDays.map((day, i) => this.renderCellWrapper(day, i))}
      </div>
    )
  }

  private renderMonthDates() {
    const { monthDate, projectDateStore } = this.props
    const convertedMonthDate = monthDate ?? new Date()

    const monthStart = projectDateStore.startOfMonth(convertedMonthDate)
    const weekStarts: Date[] = []

    let weekStart = monthStart

    while (projectDateStore.isSameMonth(weekStart, convertedMonthDate)) {
      weekStarts.push(weekStart)

      weekStart = projectDateStore.addWeeks(weekStart, 1)

      if (!projectDateStore.isSunday(weekStart)) {
        weekStart = projectDateStore.startOfWeek(weekStart)
      }
    }

    return (
      <div className="p-datepicker y-center">
        {weekStarts.map(ws => this.renderWeekDates(ws))}
      </div>
    )
  }

  private renderWeekDaysHeader() {
    const { weekdayNamesList } = this.props.projectDateStore
    return (
      <div className="dp-week-days row y-center bb-palette-grey">
        {weekdayNamesList.map((day, i) => {
          return (
            <span key={i} className="text large center bold faded">
              {day}
            </span>
          )
        })}
      </div>
    )
  }
}
