import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import SectionButton, { ISavedFiltersSection } from './components/SectionButton'

import './SavedFiltersActionBar.scss'

// localization: translated

interface ISavedFiltersActionBarProps {
  selectedCustomFilterId: string
  appliedCustomFilterId: string
  selectedActivityPresetId?: string
  appliedActivityPresetId?: string
  editCustomFilter: () => void
  editActivityPreset?: () => void
  deleteCustomFilter: () => void
  deleteActivityPreset?: () => void
  onSaveFiltersClicked: () => void
}

@observer
export default class SavedFiltersActionBar extends React.Component<ISavedFiltersActionBarProps> {
  private sections: ISavedFiltersSection[] = [
    {
      getLabel: () => Localization.translator.edit_verb,
      icon: <Icon icon={IconNames.ANNOTATION} />,
      onClick: this.clickOnEdit,
    },
    {
      getLabel: () => Localization.translator.share,
      icon: <Icon icon={IconNames.SHARE} />,
      onClick: this.clickOnShare,
      isDisabled: true,
    },
    {
      getLabel: () => Localization.translator.delete,
      icon: <Icon icon={IconNames.TRASH} />,
      onClick: this.clickOnDelete,
    },
  ]

  public render() {
    const isBarDisabled =
      !this.isCustomFilterSelected && !this.isActivityPresetSelected

    return (
      <div className="row saved-filters-action-bar-wrapper px10 mt5 mb10">
        {this.sections.map((section, index) => (
          <SectionButton
            key={index}
            isDisabled={isBarDisabled}
            section={section}
          />
        ))}
        <div
          className={classList({
            'row save-section px5-right pointer': true,
          })}
          onClick={this.clickOnSave}
        >
          <Icon className="mr5" icon={IconNames.ADD} />
          {Localization.translator.saveCurrentFilters}
        </div>
      </div>
    )
  }

  public get isCustomFilterSelected() {
    const {
      selectedCustomFilterId,
      selectedActivityPresetId,
      appliedCustomFilterId,
    } = this.props

    return (
      selectedCustomFilterId ||
      (!selectedActivityPresetId && appliedCustomFilterId)
    )
  }

  public get isActivityPresetSelected() {
    const {
      selectedActivityPresetId,
      selectedCustomFilterId,
      appliedActivityPresetId,
    } = this.props

    return (
      selectedActivityPresetId ||
      (!selectedCustomFilterId && appliedActivityPresetId)
    )
  }

  @action.bound
  private clickOnEdit() {
    const { editCustomFilter, editActivityPreset } = this.props

    if (this.isCustomFilterSelected) {
      editCustomFilter()
    }

    if (this.isActivityPresetSelected) {
      editActivityPreset()
    }
  }

  @action.bound
  private clickOnShare() {
    // TODO: Implement logic to share
  }

  @action.bound
  private clickOnDelete() {
    const { deleteCustomFilter, deleteActivityPreset } = this.props

    if (this.isCustomFilterSelected) {
      deleteCustomFilter()
    }

    if (this.isActivityPresetSelected) {
      deleteActivityPreset()
    }
  }

  @action.bound
  private clickOnSave() {
    this.props.onSaveFiltersClicked()
  }
}
