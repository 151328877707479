import { ISitePermitStatusChange, SitePermitStatus } from '~/client/graph'
import Thread from '~/client/src/shared/models/Thread'
import Guard from '~/client/src/shared/utils/Guard'

export default class PermitStatusChange {
  public static fromDto(dto: ISitePermitStatusChange) {
    return new PermitStatusChange(
      dto.id,
      dto.permitId,
      dto.projectId,
      dto.userId,
      dto.status as SitePermitStatus,
      dto.workflowStepLevel,
      dto.createdAt,
      dto.threadId,
    )
  }

  public constructor(
    public id: string,
    public readonly permitId: string,
    public readonly projectId: string,
    public readonly userId: string,
    public readonly status: SitePermitStatus,
    public readonly workflowStepLevel: number,
    public readonly createdAt: number,
    public threadId: string = null,
  ) {
    Guard.requireAll({
      permitId,
      projectId,
      status,
      workflowStepLevel,
      createdAt,
    })
  }

  public setThread(thread: Thread) {
    if (thread) {
      this.threadId = thread.id
    }
  }
}
