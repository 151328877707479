import * as React from 'react'

import { observer } from 'mobx-react'

import { IAttachment } from '~/client/graph'

import FileAttachment from '../../WorkflowCard/FileAttachment'

interface IAttachmentsContainerProps {
  attachments: IAttachment[]
}

@observer
export default class AttachmentsContainer extends React.Component<IAttachmentsContainerProps> {
  public render() {
    const { attachments } = this.props

    if (!attachments?.length) {
      return null
    }

    return (
      <div className="col">
        {attachments.map(attachment => {
          return <FileAttachment key={attachment.url} attachment={attachment} />
        })}
      </div>
    )
  }
}
