import { action } from 'mobx'

import {
  IOperationRule,
  OperationSubjectType,
  OperationType,
} from '~/client/graph'
import { SquareBracket } from '~/client/src/shared/enums/Brackets'
import { TagType } from '~/client/src/shared/enums/TagType'
import OperationRule from '~/client/src/shared/models/OperationRule'
import SitePermit from '~/client/src/shared/models/Permit'
import User from '~/client/src/shared/models/User'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import OperationRulesStore from '~/client/src/shared/stores/domain/OperationRules.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ExpressionParser from '~/client/src/shared/utils/ExpressionParser/ExpressionParser'
import { TAG_REG_EXP } from '~/client/src/shared/utils/regExpPatterns'

const TAG_START_SYMBOL = SquareBracket.LEFT
export const PERMIT_TYPE_TAG = 'permitType'

export default class PermitBallInCourtStore {
  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly operationRulesStore: OperationRulesStore,
    private readonly projectMembersStore: ProjectMembersStore,
  ) {}

  @action.bound
  public addUserToRule(
    rule: OperationRule,
    userId: string,
    callbackFn?: (rules: IOperationRule[]) => void,
  ) {
    const newUserExpression = OperationRule.makeTagExpressionString(
      TagType.User,
      userId,
    )

    if (!rule.assignmentsAsSubexpressions.includes(newUserExpression)) {
      rule.assignmentsAsSubexpressions.push(newUserExpression)
      rule.updateAssignmentExpressions()

      this.operationRulesStore.saveMany([rule], callbackFn)
    }
  }

  @action.bound
  public removeUserFromRule(rule: OperationRule, userId: string) {
    if (!userId || !rule?.assignmentsAsSubexpressions?.length) {
      return
    }

    const tagExpression = OperationRule.makeTagExpressionString(
      TagType.User,
      userId,
    )
    const { assignmentsAsSubexpressions } = rule
    const expressionIdx = rule.assignmentsAsSubexpressions.findIndex(
      v => v === tagExpression,
    )

    if (expressionIdx !== -1) {
      assignmentsAsSubexpressions.splice(expressionIdx, 1)
      if (!assignmentsAsSubexpressions.length) {
        assignmentsAsSubexpressions.push(OperationRule.ASSIGNMENT_PLACEHOLDER)
      }
      rule.updateAssignmentExpressions()

      this.operationRulesStore.saveMany([rule])
    }
  }

  public isUserAllowedByBicRules = (
    userId: string,
    permit: SitePermit,
    ruleIds: string[],
  ): boolean => {
    const rulesByUser = this.getBicRulesByUserId(
      this.getPermitBicRulesByIds(ruleIds),
      userId,
    )

    const { locationsWithEquipment, companyIds } = permit

    return rulesByUser.some(rule => {
      const areLocationTagsValid =
        !locationsWithEquipment?.length ||
        locationsWithEquipment.some(l =>
          ExpressionParser.isLocationTagValidForBicRule(
            l.id,
            l.type,
            rule.expression,
          ),
        )

      const areCompanyTagsValid =
        !companyIds?.length ||
        companyIds.some(id =>
          ExpressionParser.isCompanyTagValidForBicRule(id, rule.expression),
        )

      return areLocationTagsValid && areCompanyTagsValid
    })
  }

  public getUsersFromAssignment = (assignmentExpressions: string[]): User[] => {
    if (!assignmentExpressions?.length) {
      return []
    }

    return assignmentExpressions
      .reduce((users, element) => {
        if (!element.includes(TAG_START_SYMBOL)) {
          return users
        }

        const userDto = this.retrieveUser(element)
        return [...users, userDto]
      }, [])
      .filter(u => !!u)
  }

  public getPermitBicRulesByIds = (ids: string[]): OperationRule[] => {
    if (!ids?.length) {
      return []
    }

    return ids
      .map(ruleId => this.operationRulesStore.getRuleById(ruleId))
      .filter(rule => rule?.operationSubject === OperationSubjectType.PermitBic)
  }

  public getNewOperationRule = (permitTypeId: string): OperationRule => {
    return new OperationRule(
      OperationRule.makeTagExpressionString(PERMIT_TYPE_TAG, permitTypeId),
      OperationType.Notification,
      OperationSubjectType.PermitBic,
      this.eventsStore.appState.activeProject.id,
      OperationRule.ASSIGNMENT_PLACEHOLDER,
      OperationRule.NEW_ID,
      Date.now(),
    )
  }

  private retrieveUser = (expressionString: string): User => {
    const [, tagValue] = expressionString.match(TAG_REG_EXP)

    return this.projectMembersStore.getById(tagValue)
  }

  private getBicRulesByUserId = (
    rules: OperationRule[],
    userId: string,
  ): OperationRule[] => {
    return rules.filter(rule => {
      return this.getUsersFromAssignment(
        rule?.assignmentsAsSubexpressions,
      ).some(u => u.id === userId)
    })
  }
}
