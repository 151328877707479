import { action, computed, observable } from 'mobx'

import { IMaturixCast } from '~/client/graph'

import IMaturixCastMonitoring from '../../../models/IMaturixCastMonitoring'
import IMaturixCastMonitoringLocationPair from '../../../models/IMaturixCastMonitoringLocationPair'
import LocationBase from '../../../models/LocationObjects/LocationBase'
import MaturixCast from '../../../models/MaturixCast'
import LevelsStore from '../Levels.store'
import LocationIntegrationsStore from '../LocationIntegrations.store'

export default class MaturixCastsStore {
  @observable public isDataReceived = false
  @observable public casts: MaturixCast[] = []

  public constructor(
    private levelsStore: LevelsStore,
    private locationIntegrationsStore: LocationIntegrationsStore,
  ) {}

  @action.bound
  public receiveList(dtos?: IMaturixCast[]) {
    this.isDataReceived = true
    this.clearCasts()
    this.casts = (dtos || []).map((dto: IMaturixCast) =>
      MaturixCast.fromDto(dto),
    )
  }

  @computed
  public get allSitemapAttributes(): LocationBase[] {
    return [...this.levelsStore.listWithDeletedItems]
  }

  @computed
  public get monitoringLocationPairs(): IMaturixCastMonitoringLocationPair[] {
    return this.monitorings.map(monitoring => ({
      id: monitoring.monitoringId,
      monitoring: monitoring,
      location: this.allSitemapAttributes.find(
        item =>
          this.locationIntegrationsStore.maturixStationsList.find(
            item => item.metadata?.MonitoringId === monitoring.monitoringId,
          )?.parent?.parentId === item.id,
      ),
      castId: monitoring.castId,
    }))
  }

  @computed
  public get monitorings(): IMaturixCastMonitoring[] {
    const monitorings = []
    this.casts.forEach(cast =>
      cast.monitorings.forEach(monitoringLastData =>
        monitorings.push({
          ...monitoringLastData.monitoring,
          castId: cast.castId,
          castName: cast.castName,
          castStatus: cast.status,
          lastReading: monitoringLastData?.latestData?.timestamp,
          lastTemperature: monitoringLastData?.latestData?.temperature,
          lastStrength: monitoringLastData?.latestData?.strength,
          lastMaturity: monitoringLastData?.latestData?.maturity,
        }),
      ),
    )
    return monitorings
  }

  @action.bound
  public clearCasts = () => {
    this.casts = []
  }
}
