import * as React from 'react'

import { inject, observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

// translated

interface IProps {
  contentObject: any
  slackBarStore?: SlackBarStore
}

@inject('slackBarStore')
@observer
export default class CloseContentObjectButton extends React.Component<IProps> {
  public render() {
    return (
      <button
        className="item-button close-btn"
        onClick={this.closeContentObject}
      >
        {Localization.translator.close}
        <img src="/static/icons/flag.svg" className="close-button-img" />
      </button>
    )
  }

  private closeContentObject = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    const { slackBarStore, contentObject } = this.props
    slackBarStore.closeContentObject(contentObject)
  }
}
