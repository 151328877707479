import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import User from '~/client/src/shared/models/User'

import Localization from '../../../localization/LocalizationManager'
import { ITag } from '../../../models/Tag'
import AnnouncementAssignmentsStore from '../../../stores/domain/AnnouncementAssignments.store'
import BaseActionButton from '../../BaseActionButton/BaseActionButton'
import UsersDirectory from '../../UsersDirectory/UsersDirectory'

interface IProps {
  appliedUsers: User[]
  onApply: (selectedUserIds: string[], isAllMode: boolean) => void
  onClose: () => void
  isAllMode: boolean

  announcementAssignmentsStore?: AnnouncementAssignmentsStore
}

@inject('announcementAssignmentsStore')
@observer
export default class AnnouncementUserPicker extends React.Component<IProps> {
  private isAllMode: boolean = true
  private selectedUserIds: string[] = []

  public constructor(props: IProps) {
    super(props)

    this.isAllMode = props.isAllMode
    this.selectedUserIds = this.isAllMode
      ? []
      : props.appliedUsers.map(u => u.id)
  }

  public render() {
    const { appliedUsers, onClose } = this.props

    return (
      <div className="absolute full-width full-height bg-white z-index-100 site-permit-creation-form-content">
        <UsersDirectory
          shouldUseAllProjectMembers
          isSelectionMode
          shouldShowSelectAll
          selectedUsers={appliedUsers}
          onItemSelect={this.selectUser}
          onHeaderCloseClick={onClose}
          applyButton={
            <BaseActionButton
              isEnabled={true}
              className="scale-blue-theme"
              title={Localization.translator.apply}
              onClick={this.handleApply}
            />
          }
        />
      </div>
    )
  }

  public selectUser = (userTags: ITag[]) => {
    this.selectedUserIds = userTags.map(ut => ut.id)
    this.setMode()
  }

  private setMode() {
    this.isAllMode =
      this.selectedUserIds.length ===
      this.props.announcementAssignmentsStore.allProjectMembers.length
  }

  @action.bound
  private handleApply() {
    this.props.onApply(this.selectedUserIds, this.isAllMode)
  }
}
