import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IProjectMaterialOptionsFieldsFragment = Pick<
  Types.IProjectMaterialOptions,
  | 'id'
  | 'hiddenCategoryIds'
  | 'hiddenSubCategoryIds'
  | 'isCSIDisabled'
  | 'isCSISubCategoriesDisabled'
  | 'projectId'
  | 'createdAt'
  | 'updatedAt'
>

export type IGetProjectMaterialOptionsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetProjectMaterialOptionsQuery = {
  projectMaterialOptions?: Types.Maybe<{
    data: Array<IProjectMaterialOptionsFieldsFragment>
  }>
}

export type ISaveProjectMaterialOptionsMutationVariables = Types.Exact<{
  projectMaterialOptions: Types.IProjectMaterialOptionsInput
}>

export type ISaveProjectMaterialOptionsMutation = {
  saveProjectMaterialOptions?: Types.Maybe<IProjectMaterialOptionsFieldsFragment>
}

export type IListenToProjectMaterialOptionsSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToProjectMaterialOptionsSubscription = {
  projectMaterialOptions?: Types.Maybe<
    Pick<Types.IProjectMaterialOptionsChangeEvent, 'id'> & {
      item?: Types.Maybe<IProjectMaterialOptionsFieldsFragment>
    }
  >
}

export const ProjectMaterialOptionsFieldsFragmentDoc = gql`
  fragment ProjectMaterialOptionsFields on ProjectMaterialOptions {
    id
    hiddenCategoryIds
    hiddenSubCategoryIds
    isCSIDisabled
    isCSISubCategoriesDisabled
    projectId
    createdAt
    updatedAt
  }
`
export const GetProjectMaterialOptionsDocument = gql`
  query GetProjectMaterialOptions($projectId: ObjectId!) {
    projectMaterialOptions(projectId: $projectId, limit: 1) {
      data {
        ...ProjectMaterialOptionsFields
      }
    }
  }
  ${ProjectMaterialOptionsFieldsFragmentDoc}
`

/**
 * __useGetProjectMaterialOptionsQuery__
 *
 * To run a query within a React component, call `useGetProjectMaterialOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectMaterialOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectMaterialOptionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectMaterialOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetProjectMaterialOptionsQuery,
    IGetProjectMaterialOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetProjectMaterialOptionsQuery,
    IGetProjectMaterialOptionsQueryVariables
  >(GetProjectMaterialOptionsDocument, options)
}
export function useGetProjectMaterialOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetProjectMaterialOptionsQuery,
    IGetProjectMaterialOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetProjectMaterialOptionsQuery,
    IGetProjectMaterialOptionsQueryVariables
  >(GetProjectMaterialOptionsDocument, options)
}
export type GetProjectMaterialOptionsQueryHookResult = ReturnType<
  typeof useGetProjectMaterialOptionsQuery
>
export type GetProjectMaterialOptionsLazyQueryHookResult = ReturnType<
  typeof useGetProjectMaterialOptionsLazyQuery
>
export type GetProjectMaterialOptionsQueryResult = Apollo.QueryResult<
  IGetProjectMaterialOptionsQuery,
  IGetProjectMaterialOptionsQueryVariables
>
export const SaveProjectMaterialOptionsDocument = gql`
  mutation SaveProjectMaterialOptions(
    $projectMaterialOptions: ProjectMaterialOptionsInput!
  ) {
    saveProjectMaterialOptions(
      projectMaterialOptions: $projectMaterialOptions
    ) {
      ...ProjectMaterialOptionsFields
    }
  }
  ${ProjectMaterialOptionsFieldsFragmentDoc}
`
export type ISaveProjectMaterialOptionsMutationFn = Apollo.MutationFunction<
  ISaveProjectMaterialOptionsMutation,
  ISaveProjectMaterialOptionsMutationVariables
>

/**
 * __useSaveProjectMaterialOptionsMutation__
 *
 * To run a mutation, you first call `useSaveProjectMaterialOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProjectMaterialOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProjectMaterialOptionsMutation, { data, loading, error }] = useSaveProjectMaterialOptionsMutation({
 *   variables: {
 *      projectMaterialOptions: // value for 'projectMaterialOptions'
 *   },
 * });
 */
export function useSaveProjectMaterialOptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveProjectMaterialOptionsMutation,
    ISaveProjectMaterialOptionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveProjectMaterialOptionsMutation,
    ISaveProjectMaterialOptionsMutationVariables
  >(SaveProjectMaterialOptionsDocument, options)
}
export type SaveProjectMaterialOptionsMutationHookResult = ReturnType<
  typeof useSaveProjectMaterialOptionsMutation
>
export type SaveProjectMaterialOptionsMutationResult =
  Apollo.MutationResult<ISaveProjectMaterialOptionsMutation>
export type SaveProjectMaterialOptionsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveProjectMaterialOptionsMutation,
    ISaveProjectMaterialOptionsMutationVariables
  >
export const ListenToProjectMaterialOptionsDocument = gql`
  subscription ListenToProjectMaterialOptions($projectId: ObjectId!) {
    projectMaterialOptions(projectId: $projectId) {
      id
      item {
        ...ProjectMaterialOptionsFields
      }
    }
  }
  ${ProjectMaterialOptionsFieldsFragmentDoc}
`

/**
 * __useListenToProjectMaterialOptionsSubscription__
 *
 * To run a query within a React component, call `useListenToProjectMaterialOptionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToProjectMaterialOptionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToProjectMaterialOptionsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToProjectMaterialOptionsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToProjectMaterialOptionsSubscription,
    IListenToProjectMaterialOptionsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToProjectMaterialOptionsSubscription,
    IListenToProjectMaterialOptionsSubscriptionVariables
  >(ListenToProjectMaterialOptionsDocument, options)
}
export type ListenToProjectMaterialOptionsSubscriptionHookResult = ReturnType<
  typeof useListenToProjectMaterialOptionsSubscription
>
export type ListenToProjectMaterialOptionsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToProjectMaterialOptionsSubscription>
