import { action, computed, observable } from 'mobx'

import { IDeliveryStatusChange, NotificationType } from '~/client/graph'
import { NotificationsByProjectIdDocument } from '~/client/graph/operations/generated/Notifications.generated'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import InitialState, {
  NotificationFilters,
} from '~/client/src/shared/stores/InitialState'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import {
  isActivityChangedType,
  isDeliveryCanceled,
  isDeliveryDelivering,
  isDeliveryDenied,
  isDeliveryDone,
  isDeliveryFailedInspection,
  isDeliveryOnHold,
  isDeliveryOnsite,
  isDeliveryPassedInspection,
  isDeliveryPaused,
  isDeliveryRequested,
  isDeliveryScheduled,
  isDeliveryType,
  isDeliveryUpdated,
  isFlagType,
  isFollowingType,
  isPermitAcceptedType,
  isPermitActivatedType,
  isPermitBicInspectedType,
  isPermitChangedType,
  isPermitClosedType,
  isPermitDeletedType,
  isPermitDeniedType,
  isPermitFailedType,
  isPermitFinishedType,
  isPermitOnSiteType,
  isPermitPassedType,
  isPermitReviewedType,
  isPermitSubmittedType,
  isPermitToInspectType,
  isPermitType,
  isRFIType,
  isScannerDeactivatedType,
  isScheduleCommentType,
} from '~/client/src/shared/types/NotificationTypes'
import Guard from '~/client/src/shared/utils/Guard'

import Localization from '../../localization/LocalizationManager'
import BaseNotification from '../../models/Notification'
import { NOOP } from '../../utils/noop'
import { areArraysEqual } from '../../utils/util'
import EventContext from '../EventStore/EventContext'
import EventsStore from '../EventStore/Events.store'
import {
  LOAD_NOTIFICATIONS,
  NOTIFICATION_COUNT_UPDATED,
  REQUEST_ERROR,
} from '../EventStore/eventConstants'
import ProjectDateStore, { MILLISECONDS_IN_HOUR } from '../ui/ProjectDate.store'
import DeliveriesStore from './Deliveries.store'
import DeliveryStatusChangesStore from './DeliveryStatusChanges.store'
import GraphExecutorStore from './GraphExecutor.store'

export interface INotificationListRow {
  title?: string
  date?: Date
  notification?: BaseNotification
}

interface INotificationsByDay {
  day: Date
  notifications: BaseNotification[]
}

export enum Statuses {
  PENDING,
  LOADING,
  LOADED,
  ERROR,
}

const SORT_KEY = 'createdAt'

export const ALL_DROPDOWN_OPTIONS = [
  NotificationFilters.READ,
  NotificationFilters.UNREAD,
  NotificationFilters.ARCHIVED,
]
export default class NotificationsStore {
  public notifications = observable(new Map<string, BaseNotification>())
  public selectedNotifications = observable(new Map<string, boolean>())
  @observable public displayedNotification: BaseNotification = null
  @observable public isCompaniesBarExpanded: boolean = false
  @observable public selectedCompanies: string[] = []
  @observable public notificationsStatusFilters: NotificationFilters[] = [
    NotificationFilters.INBOX,
  ]
  @observable public onTimeWindow: number = 1.5
  public unsubscribeFromNotifications = null
  private state: InitialState

  public constructor(
    private eventsStore: EventsStore,
    private deliveriesStore: DeliveriesStore,
    private deliveryStatusChangesStore: DeliveryStatusChangesStore,
    private readonly companiesStore: CompaniesStore,
    private readonly graphExecutorStore: GraphExecutorStore,
    private readonly projectDateStore: ProjectDateStore,
    notificationsStatusFilters: NotificationFilters[] = [
      NotificationFilters.INBOX,
    ],
    private readonly onNotificationsReceivedCb: () => void = NOOP,
  ) {
    Guard.requireAll({
      eventsStore,
      deliveriesStore,
      deliveryStatusChangesStore,
      graphExecutorStore,
    })
    this.state = eventsStore.appState
    this.notificationsStatusFilters = notificationsStatusFilters

    this.loadAndListenToNotifications()
  }

  @action
  public async loadAndListenToNotifications() {
    this.state.loading.set(LOAD_NOTIFICATIONS, true)
    const res = await this.graphExecutorStore.query(
      NotificationsByProjectIdDocument,
      { projectId: this.state.activeProject.id },
    )
    if (res.error) {
      return this.eventsStore.dispatch(REQUEST_ERROR, LOAD_NOTIFICATIONS)
    }

    if (res.data.notifications) {
      const notificationsMap = (res.data.notifications.data || []).reduce(
        (map, n) => {
          map[n.id] = BaseNotification.fromDto(n)
          return map
        },
        {},
      )

      this.notifications.merge(notificationsMap)
    }

    this.onNotificationsReceivedCb?.()
    this.state.loading.set(LOAD_NOTIFICATIONS, false)

    this.unsubscribeFromNotifications = this.eventsStore.addPostEventCallback(
      (ctx: EventContext) => {
        const [eventType, data] = ctx.event
        if (eventType === NOTIFICATION_COUNT_UPDATED) {
          data.forEach(({ notification }) => {
            if (notification.item) {
              this.notifications.set(
                notification.id,
                BaseNotification.fromDto(notification.item),
              )
            } else {
              this.notifications.delete(notification.id)
            }
          })

          this.syncUnreadNotificationsCountWithAppState()
        }
      },
    )
  }

  @action.bound
  public disposeNotifications() {
    this.syncUnreadNotificationsCountWithAppState()
    this.unsubscribeFromNotifications?.()
    this.notifications.clear()
  }

  @action.bound
  public setOnTimeWindow(onTimeWindow: number) {
    this.onTimeWindow = onTimeWindow
  }

  @computed
  public get filteredNotifications(): BaseNotification[] {
    return this.filteredNotificationsByCompanies(this.filterNotificationsByType)
  }

  public get isLoading() {
    return this.state.loading.get(LOAD_NOTIFICATIONS)
  }

  public get companies() {
    return [...this.companiesStore.allCompanies.map(c => c.id), null]
  }

  public getCompanyNameById = (companyId: string) => {
    return this.companiesStore.getCompanyNameById(companyId)
  }

  public toggleCompaniesBar = () => {
    this.isCompaniesBarExpanded = !this.isCompaniesBarExpanded
  }

  public setCompanyFilterValue = (companies: string[]) => {
    if (!areArraysEqual(this.selectedCompanies, companies)) {
      this.selectedCompanies = companies
    }
  }

  @computed
  public get all(): BaseNotification[] {
    return Array.from(this.notifications.values())
  }

  @computed
  public get filteredLatestForEachEntityFromActiveProject(): BaseNotification[] {
    return this.filteredNotificationsByCompanies(
      this.latestForEachEntityFromActiveProject,
      true,
    )
  }

  public getLatestByEntityId(entityId?: string): BaseNotification {
    if (!entityId) {
      return null
    }
    return (
      this.latestForEachEntityFromActiveProject.filter(
        n => n.entityId === entityId,
      )[0] || null
    )
  }

  @computed
  public get latestForEachEntityFromActiveProject(): BaseNotification[] {
    // group by entityId
    const byEntityId = this.all.reduce((grouped, notification) => {
      const { entityId } = notification
      grouped[entityId] = grouped[entityId] || []
      grouped[entityId].push(notification)

      return grouped
    }, {} as { [id: string]: BaseNotification[] })

    Object.keys(byEntityId).forEach(id => {
      byEntityId[id].sort(descending(SORT_KEY))
    })

    const notifications = []
    // only show the first (latest notification for each group)
    Object.keys(byEntityId).forEach(groupName => {
      const notificationsForEntity = byEntityId[groupName]
      const [head] = notificationsForEntity
      notifications.push(head)
    })

    // order by createdAt
    const duplicatesIds = this.getStatusUpdatesDuplicatesIds(notifications)

    return notifications
      .filter(n => !duplicatesIds.includes(n.id))
      .sort(descending(SORT_KEY))
  }

  @computed
  public get unreadNotificationsCount(): number {
    return this.filteredLatestForEachEntityFromActiveProject.filter(
      n => !n.wasRead && !n.isArchived,
    ).length
  }

  @computed
  public get rows(): INotificationListRow[] {
    const readRows = this.generateRowsGroup(
      Localization.translator.read_2form,
      true,
    )
    const unreadRows = this.generateRowsGroup(
      Localization.translator.unread_2form,
      false,
    )

    return [...unreadRows, ...readRows]
  }

  @action
  private syncUnreadNotificationsCountWithAppState() {
    this.state.unreadNotificationsCount = this.unreadNotificationsCount
  }

  private getStatusUpdatesDuplicatesIds(notifications: BaseNotification[]) {
    const statusUpdatesNotifications = notifications
      .filter(
        notification =>
          notification.type === NotificationType.StatusUpdateCreated,
      )
      .sort(descending(SORT_KEY))

    const statusUpdatesNotificationsIds = statusUpdatesNotifications.map(
      notification => notification.activityObjectId,
    )

    return statusUpdatesNotifications
      .filter(
        (notification, index) =>
          statusUpdatesNotificationsIds.indexOf(
            notification.activityObjectId,
          ) !== index,
      )
      .map(notification => notification.id)
  }

  private filteredNotificationsByCompanies(
    notifications: BaseNotification[],
    all: boolean = false,
  ): BaseNotification[] {
    return notifications.filter(n => {
      if (all || !isDeliveryType(n.type) || isFollowingType(n.type)) {
        return true
      }

      const entity = this.deliveriesStore.byId.get(n.entityId)
      return entity && this.selectedCompanies.includes(entity.company)
    })
  }

  private get filterNotificationsByType(): BaseNotification[] {
    const filters = this.notificationsStatusFilters
    if (!filters.length) {
      return this.latestForEachEntityFromActiveProject
    }

    return this.latestForEachEntityFromActiveProject.filter(n => {
      switch (true) {
        case filters === ALL_DROPDOWN_OPTIONS:
          return true

        case filters.length > 1 &&
          filters.every(filter => ALL_DROPDOWN_OPTIONS.includes(filter)):
          let result = false
          if (filters.includes(NotificationFilters.READ)) {
            result = result || (!n.isArchived && n.wasRead)
          }

          if (filters.includes(NotificationFilters.UNREAD)) {
            result = result || (!n.isArchived && !n.wasRead)
          }

          if (filters.includes(NotificationFilters.ARCHIVED)) {
            result = result || n.isArchived
          }

          return result

        case filters.includes(NotificationFilters.INBOX):
          return !n.isArchived

        case filters.includes(NotificationFilters.UNREAD):
          return !n.isArchived && !n.wasRead

        case filters.includes(NotificationFilters.READ):
          return !n.isArchived && n.wasRead

        case filters.includes(NotificationFilters.ARCHIVED):
          return n.isArchived

        case filters.includes(NotificationFilters.FLAGS):
          return isFlagType(n.type)

        case filters.includes(NotificationFilters.RFIS):
          return isRFIType(n.type)

        case filters.includes(NotificationFilters.SCHEDULE_COMMENTS):
          return isScheduleCommentType(n.type)

        case filters.includes(NotificationFilters.ACTIVITY_CHANGED):
          return isActivityChangedType(n.type)

        case filters.includes(NotificationFilters.FORMS):
          return isPermitType(n.type)

        case filters.includes(NotificationFilters.DELIVERIES):
          return isDeliveryType(n.type)

        case filters.includes(NotificationFilters.DELIVERIES_DONE):
          return isDeliveryDone(n.type)

        case filters.includes(NotificationFilters.DELIVERIES_LATE):
          return this.isLateDelivery(n)

        case filters.includes(NotificationFilters.DELIVERIES_ON_TIME):
          return this.isDeliveryOntime(n)

        case filters.includes(NotificationFilters.DELIVERIES_ON_SITE):
          return isDeliveryOnsite(n.type)

        case filters.includes(NotificationFilters.DELIVERIES_REQUESTED):
          return isDeliveryRequested(n.type)

        case filters.includes(NotificationFilters.DELIVERIES_DENIED):
          return isDeliveryDenied(n.type)

        case filters.includes(NotificationFilters.DELIVERIES_CANCELED):
          return isDeliveryCanceled(n.type)

        case filters.includes(NotificationFilters.DELIVERIES_ON_HOLD):
          return isDeliveryOnHold(n.type)

        case filters.includes(NotificationFilters.DELIVERIES_DELIVERING):
          return isDeliveryDelivering(n.type)

        case filters.includes(NotificationFilters.DELIVERIES_PAUSED):
          return isDeliveryPaused(n.type)

        case filters.includes(NotificationFilters.DELIVERIES_CHANGED):
          return isDeliveryUpdated(n.type)

        case filters.includes(NotificationFilters.DELIVERIES_SCHEDULED):
          return isDeliveryScheduled(n.type)

        case filters.includes(NotificationFilters.DELIVERIES_PASSED_INSPECTION):
          return isDeliveryPassedInspection(n.type)

        case filters.includes(NotificationFilters.DELIVERIES_FAILED_INSPECTION):
          return isDeliveryFailedInspection(n.type)

        case filters.includes(NotificationFilters.FOLLOWED):
          return isFollowingType(n.type)

        case filters.includes(NotificationFilters.FORM_CLOSED):
          return isPermitClosedType(n.type)

        case filters.includes(NotificationFilters.FORM_ACCEPTED):
          return isPermitAcceptedType(n.type)

        case filters.includes(NotificationFilters.FORM_FINISHED):
          return isPermitFinishedType(n.type)

        case filters.includes(NotificationFilters.FORM_SUBMITTED):
          return isPermitSubmittedType(n.type)

        case filters.includes(NotificationFilters.FORM_CHANGED):
          return isPermitChangedType(n.type)

        case filters.includes(NotificationFilters.FORM_DENIED):
          return isPermitDeniedType(n.type)

        case filters.includes(NotificationFilters.FORM_TO_INSPECT):
          return isPermitToInspectType(n.type)

        case filters.includes(NotificationFilters.FORM_ACTIVATED):
          return isPermitActivatedType(n.type)

        case filters.includes(NotificationFilters.FORM_REVIEWED):
          return isPermitReviewedType(n.type)

        case filters.includes(NotificationFilters.FORM_ON_SITE):
          return isPermitOnSiteType(n.type)

        case filters.includes(NotificationFilters.FORM_FAILED):
          return isPermitFailedType(n.type)

        case filters.includes(NotificationFilters.FORM_PASSED):
          return isPermitPassedType(n.type)

        case filters.includes(NotificationFilters.FORM_BIC_INSPECTED):
          return isPermitBicInspectedType(n.type)

        case filters.includes(NotificationFilters.FORM_DELETED):
          return isPermitDeletedType(n.type)

        case filters.includes(NotificationFilters.SCANNER_DEACTIVATED):
          return isScannerDeactivatedType(n.type)

        default:
          return !n.isArchived
      }
    })
  }

  private isDeliveryOntime(notification: BaseNotification): boolean {
    if (this.deliveryOnsiteStatusChange(notification)) {
      return this.isOnTimeDelivery(notification)
    }
    return false
  }

  private isLateDelivery(notification: BaseNotification): boolean {
    if (this.deliveryOnsiteStatusChange(notification)) {
      return !this.isOnTimeDelivery(notification)
    }
    return false
  }

  private isOnTimeDelivery(notification: BaseNotification): boolean {
    if (isDeliveryType(notification.type)) {
      const delivery = this.deliveriesStore.byId.get(notification.entityId)
      const onsiteStatusChange = this.deliveryOnsiteStatusChange(notification)
      return (
        delivery &&
        Math.abs(delivery.startDate - onsiteStatusChange.createdAt) <=
          this.onTimeWindow * MILLISECONDS_IN_HOUR
      )
    }
    return false
  }

  private deliveryOnsiteStatusChange(
    notification: BaseNotification,
  ): IDeliveryStatusChange {
    return this.deliveryStatusChangesStore.statusChanges.find(statusChange => {
      if (
        statusChange.deliveryId === notification.entityId &&
        statusChange.status === DeliveryStatus.OnSite
      ) {
        return true
      }
    })
  }

  private generateRowsGroup = (
    title: string,
    isRead: boolean,
  ): INotificationListRow[] => {
    const rows: INotificationListRow[] = []

    this.notificationsByDays(isRead).forEach(({ day, notifications }) => {
      rows.push({ date: day })

      notifications.forEach(notification => rows.push({ notification }))
    })

    if (rows.length) {
      rows.unshift({ title })
    }

    return rows
  }

  private notificationsByDays = (isRead?: boolean): INotificationsByDay[] => {
    const { startOfDay, isSameDay } = this.projectDateStore
    const notificationsByDays: INotificationsByDay[] = []
    const filteredNotifications = this.filteredNotifications.filter(n => {
      return isRead ? n.wasRead : !n.wasRead
    })

    filteredNotifications.forEach(notification => {
      const day = startOfDay(notification.createdAt)
      const notificationsByDay = notificationsByDays.find(notificationByDay =>
        isSameDay(notificationByDay.day, day),
      )

      if (!notificationsByDay) {
        notificationsByDays.push({
          day,
          notifications: [notification],
        })
      } else {
        notificationsByDay.notifications.push(notification)
      }
    })
    return notificationsByDays
  }
}

function descending(key: string) {
  return (a, b) => b[key] - a[key]
}
