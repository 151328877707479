import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import CommonThread from '~/client/src/shared/components/CommonThread/CommonThread'
import * as Icons from '~/client/src/shared/components/Icons'
import Message from '~/client/src/shared/models/Message'
import Photo from '~/client/src/shared/models/Photo'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'

import FileInputBase from '../../../FileInput/FileInput'

import './CommentsList.scss'

interface IProps {
  photo: Photo
  showImages?: boolean
  hideThreadIfNoMessages?: boolean
  showFooterLine?: boolean
  messagesStore?: MessagesStore
  FileInputType: typeof FileInputBase
}

@inject('messagesStore')
@observer
class CommentsList extends React.Component<IProps> {
  @computed
  private get messages(): Message[] {
    const { photo } = this.props
    const photoMessage = this.props.messagesStore.list.find(
      message => message.photoId === photo.id,
    )

    if (!photoMessage) {
      return []
    }

    return this.props.messagesStore.list.filter(
      m => m.threadId === photoMessage.threadId,
    )
  }

  private get hideThread(): boolean {
    const { hideThreadIfNoMessages } = this.props

    if (this.messages.length > 1) {
      return false
    }

    return hideThreadIfNoMessages
  }

  public render() {
    const { photo, showImages, showFooterLine, FileInputType } = this.props

    return (
      <CommonThread
        isPhotoThread={true}
        showImages={showImages}
        hideThread={this.hideThread}
        showFooterLine={showFooterLine}
        messages={this.messages}
        threadIcon={<Icons.PhotoCamera className="icon-blue" />}
        threadClass={'photo-thread'}
        caption=""
        model={photo}
        FileInputType={FileInputType}
      />
    )
  }
}

export default CommentsList
