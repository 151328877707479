import * as React from 'react'

import { observer } from 'mobx-react'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import BaseCompactPopup from '~/client/src/shared/components/BaseCompactPopup/BaseCompactPopup'

import SitePermitCreationFormStore from '../../SitePermitCreationForm.store'
import PermitFormFieldWrapper from '../PermitFormFieldWrapper'

import './InspectionPopupForm.scss'

interface IProps {
  store: SitePermitCreationFormStore

  isReadOnly?: boolean
}

const notInUse = 'Not in use'
const useLog = 'Use log'
const inspection = 'Inspection'
const inspectionHasNoFields = (isEmissionType: boolean) =>
  `${isEmissionType ? 'Use log' : 'Inspection'} has no fields`
const submit = 'Submit'
const submitInspection = 'Submit inspection'
const _updateInspection = 'Update inspection'

@observer
export default class InspectionPopupForm extends React.Component<IProps> {
  public render() {
    const { store, isReadOnly } = this.props
    const {
      inspectionFieldsStore,
      isFormEditAllowedForUser,
      isBeforeDeadlineEnds,
      template,
      shouldShowInspectionPopup,
    } = store
    const { deselectInspection, availableWorkflowSteps } = inspectionFieldsStore

    const isInspectionReadOnly =
      isReadOnly ||
      !isBeforeDeadlineEnds ||
      !isFormEditAllowedForUser ||
      !template?.isInspectionType

    return (
      <BaseCompactPopup
        isShown={shouldShowInspectionPopup}
        title={template?.isEmissionFormType ? useLog : inspection}
        containerClassName="inspection-popup-form"
        childrenClassName="col overflow-hidden"
        titleClassName="text center small-header bold two-line-text-ellipsis"
        onHide={deselectInspection}
      >
        {shouldShowInspectionPopup && (
          <>
            <div className="permit-form-fields full-height col overflow-y-auto">
              <div className="col fields-section px16 py10">
                {!availableWorkflowSteps.length && (
                  <span className="text primary extra-large bold row x-center py20">
                    {inspectionHasNoFields(template?.isEmissionFormType)}
                  </span>
                )}
              </div>
              {availableWorkflowSteps.map(step => (
                <div key={step.id} className="col py10">
                  <div className="col fields-section px16">
                    {step.fields.map(typeField => (
                      <PermitFormFieldWrapper
                        key={typeField.id}
                        typeField={typeField}
                        store={store}
                        fieldsStore={inspectionFieldsStore}
                        isViewMode={isInspectionReadOnly}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {!isInspectionReadOnly && this.actionButtons}
          </>
        )}
      </BaseCompactPopup>
    )
  }

  private get actionButtons(): JSX.Element {
    const {
      editablePermit,
      inspectionFieldsStore,
      isBeforeDeadlineEnds,
      isFormEditAllowedForUser,
      isEmissionFormType,
      addInspection,
      updateInspection,
      addNotInUseInspection,
    } = this.props.store
    const {
      selectedInspection,
      isNewInspection,
      areAllFieldsValid,
      areRequiredFieldsChanged,
      areOptionalFieldsChanged,
    } = inspectionFieldsStore

    if (
      !isBeforeDeadlineEnds ||
      !selectedInspection ||
      !editablePermit ||
      editablePermit.isDoneOrDenied
    ) {
      return null
    }

    if (isNewInspection) {
      return (
        <div className="row x-center y-center bg-palette-brand-lightest bt-light-grey pa20">
          <BaseActionButton
            isActive={false}
            isEnabled={true}
            className="mr10 ellipsis gray-theme"
            isGrow={true}
            title={notInUse}
            onClick={addNotInUseInspection}
          />
          <BaseActionButton
            isActive={false}
            isEnabled={areAllFieldsValid}
            className="ellipsis primary-theme"
            isGrow={true}
            title={isEmissionFormType ? submit : submitInspection}
            onClick={addInspection}
          />
        </div>
      )
    }

    if (isFormEditAllowedForUser) {
      return (
        <div className="row bg-palette-brand-lightest bt-light-grey pa20">
          <BaseActionButton
            isActive={false}
            isEnabled={
              (areRequiredFieldsChanged || areOptionalFieldsChanged) &&
              areAllFieldsValid
            }
            className="ellipsis primary-theme-inverted"
            isGrow={true}
            title={_updateInspection}
            onClick={updateInspection}
          />
        </div>
      )
    }

    return null
  }
}
