import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import './NavigationArrows.scss'

const { CHEVRON_RIGHT, CHEVRON_LEFT } = IconNames

interface INavigationArrows {
  shouldShowLeftArrow: boolean
  scrollToStart: () => void
  scrollToEnd: () => void
}

export default class NavigationArrows extends React.PureComponent<INavigationArrows> {
  public render() {
    const { shouldShowLeftArrow, scrollToStart, scrollToEnd } = this.props

    const icon = shouldShowLeftArrow ? CHEVRON_LEFT : CHEVRON_RIGHT
    const handler = shouldShowLeftArrow ? scrollToStart : scrollToEnd

    return (
      <Icon
        iconSize={20}
        icon={icon}
        className="nav pointer"
        onClick={handler}
      />
    )
  }
}
