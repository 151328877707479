import React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { ChromePicker } from 'react-color'

import './ColorPalette.scss'

interface IProps {
  value: string
  onChange(color: string): void
  onClose(): void
}

interface IColorPickerColor {
  hex: string
}

@observer
export default class ColorPalette extends React.Component<IProps> {
  public render() {
    const { onChange, value, onClose } = this.props
    return (
      <div className="color-palette-container">
        <div className="py8 row">
          <span className="text bold large">{`Choose a color`}</span>
          <Icon
            className="colors-container-cross pointer"
            icon={IconNames.CROSS}
            onClick={onClose}
          />
        </div>
        <ChromePicker
          disableAlpha={true}
          color={value}
          onChangeComplete={(color: IColorPickerColor) => onChange(color?.hex)}
        />
      </div>
    )
  }
}
