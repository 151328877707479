import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IContact } from '../../../services/ChatService/types'
import Avatar from '../../Avatar/Avatar'

import './ChatMembers.scss'

interface IProps {
  selectedMembers: IContact[]

  onExclude?: (memberId: string) => void
  nonExcludableUserIds?: string[]
  isMemberOnline: (memberId: string) => boolean

  shouldWrap?: boolean
  className?: string
}

@observer
export default class ChatMembers extends React.Component<IProps> {
  public static defaultProps = {
    nonExcludableUserIds: [],
  }

  public render() {
    const { selectedMembers } = this.props

    if (!selectedMembers.length) {
      return null
    }

    const {
      shouldWrap,
      onExclude,
      className,
      nonExcludableUserIds,
      isMemberOnline,
    } = this.props

    return (
      <div
        className={classList({
          'row selected-members-list-container': true,
          [className]: !!className,
          'overflow-x-auto': !shouldWrap,
          wrap: shouldWrap,
        })}
      >
        {selectedMembers.map(m => {
          const isNonExcludableUser = nonExcludableUserIds.includes(m.id)

          return (
            <div key={m.id} className="col x-center relative member-holder py5">
              <Avatar
                src={m.avatarUrl}
                initials={m.initials}
                isOnline={isMemberOnline && isMemberOnline(m.id)}
              />
              <div
                className={classList({
                  'text large': true,
                  'bold blue': isNonExcludableUser,
                })}
              >
                {m.firstName}
              </div>
              {!!onExclude && !nonExcludableUserIds.includes(m.id) && (
                <span
                  onClick={onExclude.bind(null, m.id)}
                  className="cross-badge row x-center pointer"
                >
                  <Icon icon={IconNames.CROSS} />
                </span>
              )}
            </div>
          )
        })}
      </div>
    )
  }
}
