import { action, computed, observable } from 'mobx'

import { DeliveryListFilterType } from '~/client/graph'
import DeliveriesViewStore from '~/client/src/shared/components/Deliveries/DeliveriesView.store'
import DeliveryStatus, {
  formatStatusToDisplay,
} from '~/client/src/shared/constants/DeliveryStatus'
import Company from '~/client/src/shared/models/Company'
import DeliveriesTreeNode from '~/client/src/shared/models/DeliveryTreeNode'
import IDeliveriesTreeNodeSource from '~/client/src/shared/models/IDeliveriesTreeNodeSource'
import IDeliveryTreeItemData from '~/client/src/shared/models/IDeliveryTreeItemData'
import { IDeliveryTreeData } from '~/client/src/shared/stores/EventStore/EffectsProcessors/WorkerProcessor/handlers/getDeliveryTree'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import {
  CALCULATE_DELIVERY_TREE,
  INIT_APP,
  INIT_APP_2,
  INIT_APP_3,
  LISTEN_AND_LOAD_DELIVERIES,
  TRIGGER_CALCULATE_DELIVERY_TREE,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import InitialState from '~/client/src/shared/stores/InitialState'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryVehicleTypesStore from '~/client/src/shared/stores/domain/DeliveryVehicleTypes.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import { ITreeContainer } from '~/client/src/shared/stores/ui/BaseActivityList.store'
import CompactDeliveryListStore from '~/client/src/shared/stores/ui/CompactDeliveryList.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import MobXMap from '~/client/src/shared/types/MobXMap'
import getUserName from '~/client/src/shared/utils/GetUserName'
import {
  UNASSIGNED,
  UNASSIGNED_FILTER_VALUE,
} from '~/client/src/shared/utils/ZoneLevelLocationConstants'

import { IDeliveryTreeNode } from '../../models/IDeliveryTreeNode'
import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'

export default class DeliveryListComponentStore extends CompactDeliveryListStore {
  private containerStatusState = new MobXMap<boolean>()
  private deliveriesTreeSource: {
    tree: IDeliveriesTreeNodeSource[]
  } = observable.object({ tree: [] }, null, { deep: false })

  public get currentStartDate() {
    return this.state.delivery.compactView.activeDate
  }

  public get currentEndDate() {
    return this.state.delivery.compactView.activeEndDate
  }

  public constructor(
    protected eventsStore: EventsStore,
    private readonly deliveriesStore: DeliveriesStore,
    projectDateStore: ProjectDateStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly deliveryVehicleTypesStore: DeliveryVehicleTypesStore,
    private readonly materialCategoryStore: MaterialCategoryStore,
    private readonly materialsStore: MaterialsStore,
    private readonly companiesStore: CompaniesStore,
    private readonly deliveriesViewStore: DeliveriesViewStore,
    private readonly projectMembersStore: ProjectMembersStore,
  ) {
    super(eventsStore, projectDateStore)
  }

  public onTreeRequest = (eventContext: EventContext) => {
    const [eventType] = eventContext.event
    if (eventType === TRIGGER_CALCULATE_DELIVERY_TREE) {
      this.calculateDeliveryTree()
    }
  }

  private get state(): InitialState {
    return this.eventsStore.appState
  }

  public get isLoading() {
    return [INIT_APP, INIT_APP_2, INIT_APP_3, LISTEN_AND_LOAD_DELIVERIES].some(
      key => this.state.loading.get(key),
    )
  }

  @computed
  public get isTreeLoading() {
    return this.state.loading.get(CALCULATE_DELIVERY_TREE)
  }

  private get deliveryTreeData(): IDeliveryTreeData {
    return {
      deliveries: this.treeDeliveriesDto,
      orderedTreeContainers: this.orderedTreeContainers,
      containersMap: this.containersByTypeMap,
      timezoneId: this.projectDateStore.timezoneId,
      dateTimeFormat: this.state.activeProject.dateTimeFormat,
    }
  }

  private get treeDeliveriesDto(): { [id: string]: IDeliveryTreeItemData } {
    const dtos = this.deliveriesViewStore.currentViewDeliveries.reduce(
      (
        map,
        {
          id,
          startDate,
          endDate,
          building,
          zone,
          gate,
          company,
          route,
          status,
          vendor,
          onSiteContactPersonIds,
          truckSize,
          materialIds,
        },
      ) => {
        map[id] = {} as IDeliveryTreeItemData

        map[id].id = id
        map[id].startDate = startDate
        map[id].endDate = endDate

        map[id].company = company
        map[id].onSiteContacts = onSiteContactPersonIds

        map[id].building = building
        map[id].zone = zone
        map[id].gate = gate
        map[id].route = route

        map[id].status = status

        map[id].vendor = vendor
        map[id].vehicleType = truckSize
        map[id].materialCategory = this.getDeliveryMaterialCategory(materialIds)

        return map
      },
      {},
    )

    return dtos
  }

  private get orderedTreeContainers(): DeliveryListFilterType[] {
    const { selectedGroupByOption } = this.deliveriesViewStore

    if ((selectedGroupByOption as any) === DeliveryListFilterType.Lbs) {
      return [
        DeliveryListFilterType.Building,
        DeliveryListFilterType.Gate,
        DeliveryListFilterType.Zone,
      ]
    }
    return [selectedGroupByOption as any]
  }

  @computed
  public get containersByTypeMap(): { [key: string]: ITreeContainer[] } {
    const map: { [key: string]: ITreeContainer[] } = {}

    this.orderedTreeContainers.forEach(type => {
      map[type] = this.getContainersByType(type)
    })

    return map
  }

  public getContainersByType = (type): ITreeContainer[] => {
    let commonStoreItems = []

    switch (type) {
      case DeliveryListFilterType.Company:
        commonStoreItems = this.allDeliveriesCompanies.map(({ id, name }) => ({
          name,
          id,
        }))
        break
      case DeliveryListFilterType.Status:
        commonStoreItems = Object.values(DeliveryStatus).map(status => {
          return {
            name: formatStatusToDisplay(status),
            id: status,
          }
        })
        break
      case DeliveryListFilterType.OnSiteContact:
        commonStoreItems = this.projectMembersStore.list.map(user => ({
          name: getUserName(user),
          id: user.id,
        }))
        break
      case DeliveryListFilterType.Vendor:
        commonStoreItems = Array.from(
          new Set([
            ...this.deliveriesViewStore.deliveryFilterCollection.map(
              ({ vendor }) => vendor,
            ),
          ]),
        ).map(vendor => {
          return {
            name: vendor,
            id: vendor,
          }
        })
        break
      case DeliveryListFilterType.Building:
        commonStoreItems = this.locationAttributesStore.buildingsStore.list
        break
      case DeliveryListFilterType.Gate:
        commonStoreItems = this.locationAttributesStore.gatesStore.list
        break
      case DeliveryListFilterType.Zone:
        commonStoreItems = this.locationAttributesStore.zonesStore.list
        break
      case DeliveryListFilterType.MaterialsCategory:
        commonStoreItems = this.materialCategoryStore.allCategories
        break
      case DeliveryListFilterType.Route:
        commonStoreItems = this.locationAttributesStore.routesStore.list
        break
      case DeliveryListFilterType.VehicleType:
        commonStoreItems = this.deliveryVehicleTypesStore.listWithoutDeletedData
        break
      case DeliveryListFilterType.None:
        return []
    }

    return [
      ...commonStoreItems.map(({ name, id }) => ({
        name,
        type,
        codeIds: [id],
      })),
      {
        name: UNASSIGNED,
        type,
        codeIds: [UNASSIGNED_FILTER_VALUE],
      },
    ]
  }

  @action.bound
  public calculateDeliveryTree() {
    if (!this.deliveriesStore.isDataReceived) {
      return
    }

    this.eventsStore.dispatch(
      CALCULATE_DELIVERY_TREE,
      this.deliveryTreeData,
      this.setDeliveryTree,
    )
  }

  @action.bound
  public changeContainerExpandState(id: string, value: boolean) {
    this.containerStatusState.set(id, value)
  }

  @action.bound
  public setDeliveryTree(tree: IDeliveriesTreeNodeSource[]) {
    this.deliveriesTreeSource.tree = tree
  }

  @computed
  public get deliveriesTree(): IDeliveryTreeNode[] {
    return this.getTreeNodesWithState(this.deliveriesTreeSource.tree || [])
  }

  private getTreeNodesWithState(
    source: IDeliveriesTreeNodeSource[],
  ): IDeliveryTreeNode[] {
    return source
      .map(sourceNode => {
        const { id, nodeType, children: childrenSource, entityId } = sourceNode
        const state = {
          expanded: this.isNodeExpanded(id, nodeType),
          isStatusExpanded: this.containerStatusState.get(id),
        }
        const entity = entityId && this.deliveriesStore.byId.get(entityId)
        const children =
          childrenSource && this.getTreeNodesWithState(childrenSource)

        if (children && !children.length) {
          return null
        }

        return DeliveriesTreeNode.fromSource(sourceNode, {
          state,
          children,
          entity,
        })
      })
      .filter(node => node)
  }

  private getDeliveryMaterialCategory(materialIds: string[]): string {
    const categories = this.materialsStore.getByIds(materialIds)
    return categories?.filter(c => c).join(', ')
  }

  private get allDeliveriesCompanies(): Company[] {
    return [
      ...new Set(
        this.deliveriesStore.list
          .map(delivery => this.companiesStore.getCompanyById(delivery.company))
          .filter(c => !!c),
      ),
    ]
  }
}
