import * as React from 'react'

import { computed } from 'mobx'
import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import CustomActivityListFilter from '~/client/src/shared/types/CustomActivityListFilter'
import CustomDeliveryListFilter from '~/client/src/shared/types/CustomDeliveryListFilter'

import '../SavedFilters.scss'

// localization: translated

export interface IProps {
  customFilter: CustomActivityListFilter | CustomDeliveryListFilter
}

@observer
export default class FilterLabel extends React.Component<IProps> {
  public render() {
    const { customFilter } = this.props
    return (
      <>
        {customFilter.name}
        <span className="text extra-large light ml5">
          ({Localization.translator.xFilters(this.filtersCount)})
        </span>
      </>
    )
  }

  @computed
  private get filtersCount(): number {
    const { customFilter } = this.props
    if (!customFilter) {
      return 0
    }

    return Object.values(customFilter.filtersByFilterType).reduce(
      (sum, codes) => sum + codes?.values?.length,
      0,
    )
  }
}
