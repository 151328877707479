import { TagIconType } from '~/client/src/shared/enums/TagIcon'
import { TagType } from '~/client/src/shared/enums/TagType'
import BaseModel from '~/client/src/shared/models/BaseModel'

import Colors from '~/client/src/shared/theme.module.scss'

export interface ITag {
  id: string
  name: string
  color: string
  iconName: TagIconType
  type: TagType
}

export default class Tag extends BaseModel<ITag> implements ITag {
  public static getUnknown(id: string, tagType: string) {
    return new Tag(id, '', Colors.neutral60, null, tagType as TagType)
  }

  public constructor(
    public id: string,
    public name: string,
    public color: string,
    public iconName: TagIconType,
    public type: TagType,
  ) {
    super(id)
  }
}
