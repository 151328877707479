import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { fromPromise } from 'mobx-utils'

import { IThread, StatusUpdateType } from '~/client/graph'
import CategoryOfVarianceThread from '~/client/src/shared/components/CategoryOfVarianceEntry/CategoryOfVarianceThread'
import DaySeparator from '~/client/src/shared/components/DaySeparator'
import FlagThread from '~/client/src/shared/components/FlagEntry/FlagThread'
import CommentsList from '~/client/src/shared/components/PhotoDetails/components/CommentsList/CommentsList'
import RfiThread from '~/client/src/shared/components/RfiEntry/RfiThread'
import SafetyHazardThread from '~/client/src/shared/components/SafetyHazardEntry/SafetyHazardThread'
import ScheduleCommentThread from '~/client/src/shared/components/ScheduleCommentEntry/ScheduleCommentThread'
import StatusUpdateThread from '~/client/src/shared/components/StatusUpdateEntry/StatusUpdateThread'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import Activity from '~/client/src/shared/models/Activity'
import CategoryOfVariance from '~/client/src/shared/models/CategoryOfVariance'
import Delivery from '~/client/src/shared/models/Delivery'
import Flag from '~/client/src/shared/models/Flag'
import LogEntry, { LogEntryTypes } from '~/client/src/shared/models/LogEntry'
import Message from '~/client/src/shared/models/Message'
import Photo from '~/client/src/shared/models/Photo'
import Rfi from '~/client/src/shared/models/Rfi'
import SafetyHazard from '~/client/src/shared/models/SafetyHazard'
import ScheduleComment from '~/client/src/shared/models/ScheduleComment'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import Thread from '~/client/src/shared/models/Thread'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import CategoriesOfVarianceStore from '~/client/src/shared/stores/domain/CategoriesOfVariance.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import PhotosStore from '~/client/src/shared/stores/domain/Photos.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import SafetyHazardsStore from '~/client/src/shared/stores/domain/SafetyHazards.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import ThreadsStore from '~/client/src/shared/stores/domain/ThreadsStore/Threads.store'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import CommonThread from '../../../CommonThread/CommonThread'
import DeliveryEntry from '../../../DeliveryEntry/DeliveryEntry'
import FileInputBase from '../../../FileInput/FileInput'

import './ActivityLog.scss'

// translated

interface IActivityLogProps {
  common?: CommonStore
  threadsStore?: ThreadsStore
  activitiesStore?: ActivitiesStore
  messagesStore?: MessagesStore
  flagsStore?: FlagsStore
  photosStore?: PhotosStore
  statusUpdatesStore?: StatusUpdatesStore
  deliveriesStore?: DeliveriesStore
  rfisStore?: RfisStore
  categoriesOfVarianceStore?: CategoriesOfVarianceStore
  safetyHazardsStore?: SafetyHazardsStore
  scheduleCommentsStore?: ScheduleCommentsStore
  projectDateStore?: ProjectDateStore
  FileInputType: typeof FileInputBase
  shouldPreventScrollToBottom?: boolean
}
@inject(
  'activitiesStore',
  'messagesStore',
  'threadsStore',
  'flagsStore',
  'photosStore',
  'common',
  'statusUpdatesStore',
  'rfisStore',
  'categoriesOfVarianceStore',
  'safetyHazardsStore',
  'deliveriesStore',
  'scheduleCommentsStore',
  'projectDateStore',
)
@observer
export default class ActivityLog extends React.Component<IActivityLogProps> {
  private lastNote: any

  private threadValue: Thread | IThread

  private get selectedActivity(): Activity {
    return this.props.activitiesStore.selectedActivity
  }

  @computed
  private get threadObservable() {
    return fromPromise(
      (async () => {
        return await this.props.threadsStore.findOrCreateActivityThread(
          this.selectedActivity,
        )
      })(),
    )
  }

  @computed
  private get thread() {
    return this.threadObservable.case({
      pending: () => this.threadValue,
      rejected: () => this.threadValue,
      fulfilled: v => (this.threadValue = v),
    })
  }

  @computed
  private get messages() {
    const thread = this.thread
    if (!thread) {
      return []
    }

    return this.props.messagesStore.list
      .filter(m => m.threadId === thread.id && !m.photoId)
      .map(LogEntry.fromMessage)
  }

  @computed
  private get photos() {
    return this.props.photosStore.list
      .filter(photo => photo.belongsToActivity(this.selectedActivity))
      .map(LogEntry.fromPhoto)
  }

  @computed
  private get flags() {
    return this.props.flagsStore.list
      .filter(f => f.belongsToActivity(this.selectedActivity))
      .map(LogEntry.fromFlag)
  }

  @computed
  private get statusUpdates() {
    const statusUpdates =
      this.props.statusUpdatesStore.getAllStatusesUpdatesByActivity(
        this.selectedActivity,
        true,
      )

    const displayedStatusUpdates = statusUpdates.filter((su, idx) => {
      const isFirstStatus = !idx
      if (isFirstStatus) {
        return true
      }

      if (su.type === StatusUpdateType.Change) {
        return true
      }

      const prevStatus = statusUpdates[idx - 1]
      return (
        !this.props.projectDateStore.isSameDay(
          su.dateFor,
          prevStatus.dateFor,
        ) || su.isFromP6
      )
    })

    return displayedStatusUpdates.map(LogEntry.fromStatusUpdate)
  }

  @computed
  private get rfi() {
    return this.props.rfisStore.list
      .filter(f => f.belongsToActivity(this.selectedActivity))
      .map(LogEntry.fromRfi)
  }

  @computed
  private get categoryOfVariance() {
    return this.props.categoriesOfVarianceStore.list
      .filter(f => f.belongsToActivity(this.selectedActivity))
      .map(LogEntry.fromCategoryOfVariance)
  }

  @computed
  private get safetyHazard() {
    return this.props.safetyHazardsStore.list
      .filter(f => f.belongsToActivity(this.selectedActivity))
      .map(LogEntry.fromSafetyHazard)
  }

  @computed
  private get scheduleComments() {
    return this.props.scheduleCommentsStore.list
      .filter(sc => sc.belongsToActivity(this.selectedActivity))
      .map(LogEntry.fromScheduleComment)
  }

  @computed
  private get deliveries() {
    return this.props.deliveriesStore.list
      .filter(
        d =>
          d.activityId === this.selectedActivity.code &&
          d.status !== DeliveryStatus.Deleted,
      )
      .map(LogEntry.fromDelivery)
  }

  @computed
  private get log() {
    if (!this.selectedActivity || !this.thread) {
      return []
    }

    return [
      ...this.messages,
      ...this.flags,
      ...this.photos,
      ...this.statusUpdates,
      ...this.rfi,
      ...this.categoryOfVariance,
      ...this.safetyHazard,
      ...this.scheduleComments,
      ...this.deliveries,
    ].sort((a, b) => {
      return a.timestamp - b.timestamp
    })
  }

  public componentDidUpdate() {
    this.scrollToLastNote()
  }

  public scrollToLastNote() {
    if (
      !this.lastNote ||
      !this.thread ||
      this.props.shouldPreventScrollToBottom
    ) {
      return
    }

    this.props.common.awaitTransition.then(() => {
      setTimeout(() => {
        this.lastNote.scrollIntoView({ behavior: 'smooth' })
      }, 200)
    })
  }

  public setLastNote = el => {
    if (el) {
      this.lastNote = el
    }
  }

  public renderEntry({ type, data }) {
    const { FileInputType } = this.props
    switch (type) {
      case LogEntryTypes.FLAG:
        const flag = data as Flag
        return (
          <FlagThread flag={flag} key={flag.id} FileInputType={FileInputType} />
        )

      case LogEntryTypes.MESSAGE:
        const message = data as Message
        return (
          <div key={message.id} className="pb18">
            <CommonThread
              showImages={true}
              messages={[message]}
              threadIcon={null}
              threadClass="photo-thread"
              caption={this.selectedActivity.id}
              model={this.selectedActivity}
              shouldHideFooterCircle={true}
              shouldHideFooter={true}
              showFooterLine={false}
              hideThread={true}
              isDeliveryColumn={true}
              FileInputType={FileInputType}
            />
          </div>
        )

      case LogEntryTypes.IMAGE:
        const photo = data as Photo
        return (
          <CommentsList
            hideThreadIfNoMessages={true}
            showImages={true}
            key={data.id}
            photo={photo}
            FileInputType={FileInputType}
          />
        )

      case LogEntryTypes.STATUS_UPDATE:
        const statusUpdate = data as StatusUpdate
        return (
          <StatusUpdateThread
            statusUpdate={statusUpdate}
            key={statusUpdate.id}
            hideButtons={true}
            FileInputType={FileInputType}
          />
        )

      case LogEntryTypes.RFI:
        const rfi = data as Rfi
        return (
          <RfiThread rfi={rfi} key={rfi.id} FileInputType={FileInputType} />
        )

      case LogEntryTypes.CATEGORY_OF_VARIANCE:
        const categoryOfVariance = data as CategoryOfVariance
        return (
          <CategoryOfVarianceThread
            categoryOfVariance={categoryOfVariance}
            key={categoryOfVariance.id}
            FileInputType={FileInputType}
          />
        )

      case LogEntryTypes.SAFETY_HAZARD:
        const safetyHazard = data as SafetyHazard
        return (
          <SafetyHazardThread
            safetyHazard={safetyHazard}
            key={safetyHazard.id}
            FileInputType={FileInputType}
          />
        )

      case LogEntryTypes.SCHEDULE_COMMENT:
        const sc = data as ScheduleComment
        return (
          <ScheduleCommentThread
            scheduleComment={sc}
            key={sc.id}
            FileInputType={FileInputType}
          />
        )

      case LogEntryTypes.DELIVERY:
        const delivery = data as Delivery

        return (
          <DeliveryEntry delivery={delivery} FileInputType={FileInputType} />
        )
    }
  }

  public renderLog() {
    const { isSameDay, getWeekdayMonthAndDayToDisplay } =
      this.props.projectDateStore

    return this.log.map((entry, index, array) => {
      const prevEntry = array[index - 1]
      const prevEntryDate = prevEntry && new Date(prevEntry.timestamp)
      const currEntryDate = new Date(entry.timestamp)
      const isFirstEntry = index === 0
      const shouldRenderSeparator =
        isFirstEntry || !isSameDay(currEntryDate, prevEntryDate)

      return (
        <React.Fragment key={index}>
          {shouldRenderSeparator && (
            <DaySeparator
              shouldUseLine={true}
              date={currEntryDate}
              customDateTimeFormatter={getWeekdayMonthAndDayToDisplay}
            />
          )}
          {this.renderEntry(entry)}
        </React.Fragment>
      )
    })
  }

  public render() {
    return (
      <div className="activity-log">
        <ul className="activity-log-list px15">
          {this.renderLog()}
          <li ref={this.setLastNote} />
        </ul>
      </div>
    )
  }
}
