export function enumToList<T>(enumeration: { [key: string]: T }): T[] {
  // https://stackoverflow.com/a/65285392/2599881
  return Object.keys(enumeration)
    .filter(key => isNaN(Number(key)))
    .map(key => enumeration[key])
}

export function listToMap<T>(
  list: T[],
  getKey: (item?: T) => string = (i: T) => i.toString(),
  getValue: (item?: T) => any = () => true,
) {
  return list.reduce((map, item) => {
    map[getKey(item)] = getValue(item)
    return map
  }, {})
}

export function objectToMap(obj: any) {
  return new Map(Object.entries(obj))
}

export function mapGroupedToArray<T, K>(map: Map<T, K[]>): K[] {
  const newArray: K[] = []

  map.forEach(values => {
    newArray.push(...values)
  })

  return newArray
}

export function mapToList<T, K>(
  map: { [key: string]: T },
  include: (key: string, value: T) => boolean = () => true,
  getValue: (key: string, value: T) => K = (key: string) => key as any,
) {
  return Object.entries(map).reduce((res, [key, value]) => {
    if (include(key, value)) {
      res.push(getValue(key, value))
    }
    return res
  }, [])
}
