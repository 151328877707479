import { observable } from 'mobx'

export default class UIFilterInfo {
  @observable public isFilterShown: boolean = false
  @observable public isFilterActive: boolean = false
  @observable public initialFilterOptions = new Map<string, string[]>()
  @observable public selectedFilterOptions = new Map<string, string[]>()
  @observable public appliedFilterOptions: string[] = []
  public isInitialized: boolean = false

  public clear() {
    this.isFilterShown = false
    this.isFilterActive = false
    this.appliedFilterOptions = []
    this.selectedFilterOptions.clear()
    this.initialFilterOptions.clear()
    this.isInitialized = false
  }
}
