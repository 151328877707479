import * as React from 'react'

import IBasicProps from '~/client/src/shared/interfaces/IBasicProps'

import './index.scss'

export function View({ children, className = '' }: IBasicProps) {
  return (
    <div className={`layout-view-root col full-height ${className}`}>
      {children}
    </div>
  )
}

export function Header({ children, className = '' }: IBasicProps) {
  return <div className={`layout-header ${className}`}>{children}</div>
}

export function Footer({ children, className = '' }: IBasicProps) {
  return <div className={`layout-footer ${className}`}>{children}</div>
}

interface IContentProps extends IBasicProps {
  scrollable?: boolean
  setRef?: (ref: any) => void
}

export function Content({
  children,
  //TODO: There are a lot of places where we still pass true for scrollable prop explicitly. It would be great to get rid of such type of passing
  scrollable = true,
  className = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setRef = ref => null,
}: IContentProps) {
  let classList = 'layout-content col'

  if (scrollable) {
    classList += ' scrollable'
  }

  if (className) {
    classList += ` ${className}`
  }

  return (
    <div className={classList} ref={setRef}>
      {children}
    </div>
  )
}

interface IHBoxProps extends IBasicProps {
  style?: any
  gap?: number
  width?: 'auto' | 'min' | 'content'
}

export function HBox({ children, style = {}, className = '' }: IHBoxProps) {
  return (
    <div className={`layout-hbox ${className}`} style={{ ...style }}>
      {children}
    </div>
  )
}

interface IVBoxProps extends IBasicProps {
  gap?: number
  onClick?: (e: React.SyntheticEvent<HTMLDivElement>) => any
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const noOp = () => {
  return
}

export function VBox({
  children,
  className = '',
  onClick = noOp,
  onMouseEnter = noOp,
  onMouseLeave = noOp,
}: IVBoxProps) {
  return (
    <div
      className={`layout-vbox ${className}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  )
}

interface IBoxProps extends IBasicProps {
  size: 'none' | number
  style?: any
}

export function Box({
  size = 'none',
  children,
  className = '',
  style = {},
}: IBoxProps) {
  return (
    <div
      className={`${className}`}
      style={{
        flex: size,
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export function HGap({ size = '5' }) {
  return (
    <div style={{ paddingRight: `${size}px`, height: 1 }} className="no-grow" />
  )
}

export function VGap({ size = '5' }) {
  return (
    <div style={{ paddingBottom: `${size}px`, width: 1 }} className="no-grow" />
  )
}

export class Thickness {
  public static all(value: string | number) {
    return new Thickness(value, value, value, value)
  }

  public static symmetric(
    horizontal: number | string,
    vertical: number | string,
  ) {
    return new Thickness(vertical, horizontal, vertical, horizontal)
  }

  public static vertical(top: number | string, bottom?: number | string) {
    return new Thickness(top, 0, bottom || top, 0)
  }

  public static horizontal(thickness: number | string) {
    return new Thickness(0, thickness, 0, thickness)
  }

  public static top(thickness: number | string) {
    return new Thickness(thickness, 0, 0, 0)
  }

  public static right(thickness: number | string) {
    return new Thickness(0, thickness, 0, 0)
  }

  public static bottom(thickness: number | string) {
    return new Thickness(0, 0, thickness, 0)
  }

  public static left(thickness: number | string) {
    return new Thickness(0, 0, 0, thickness)
  }

  public static fromTRBL(
    top: number | string,
    right: number | string,
    bottom: number | string,
    left: number | string,
  ) {
    return new Thickness(top, right, bottom, left)
  }

  private constructor(
    public top: number | string,
    public right: number | string,
    public bottom: number | string,
    public left: number | string,
  ) {}
}

export function Padding({
  padding,
  children,
}: {
  padding: Thickness
  children: any
}) {
  return (
    <div
      style={{
        paddingTop: padding.top,
        paddingRight: padding.right,
        paddingBottom: padding.bottom,
        paddingLeft: padding.left,
      }}
    >
      {children}
    </div>
  )
}
