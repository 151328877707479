import { action, computed, observable } from 'mobx'

import {
  CalendricalType,
  IFormMaterial,
  IMaterialProcurementData,
  IPermitTypeField,
  ISitePermit,
  LocationType,
  PermitFieldType,
  UploadingType,
} from '~/client/graph'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import PermitType from '~/client/src/shared/models/PermitType'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import PermitInspectionFieldsStore from '~/client/src/shared/stores/ui/PermitFieldsStores/PermitInspectionFields.store'
import PermitWorkflowFieldsStore from '~/client/src/shared/stores/ui/PermitFieldsStores/PermitWorkflowFields.store'

import {
  companyRestrictionFieldTypes,
  locationPermitFieldTypes,
} from '../../constants/PermitFieldTypeConstants'
import { getWorkflowStepLevel } from '../../constants/formStatusesTags'
import Localization from '../../localization/LocalizationManager'
import IPermitFieldDto from '../../models/IPermitFieldDto'
import LocationBase from '../../models/LocationObjects/LocationBase'
import Material from '../../models/Material'
import SitePermit from '../../models/Permit'
import PermitBicInspection from '../../models/PermitBicInspection'
import PermitInspection from '../../models/PermitInspection'
import PermitStatusChange from '../../models/PermitStatusChange'
import UserProject from '../../models/UserProject'
import EventContext from '../../stores/EventStore/EventContext'
import EventsStore from '../../stores/EventStore/Events.store'
import CompaniesStore from '../../stores/domain/Companies.store'
import { FileUploadingStore } from '../../stores/domain/FileUploading.store'
import LocationAttributesStore from '../../stores/domain/LocationAttributes.store'
import MaterialCategoriesStore from '../../stores/domain/MaterialCategories.store'
import MaterialsStore from '../../stores/domain/Materials.store'
import PermitBicInspectionsStore from '../../stores/domain/PermitBicInspections.store'
import PermitInspectionChangesStore from '../../stores/domain/PermitInspectionChanges.store'
import PermitInspectionsStore from '../../stores/domain/PermitInspections.store'
import PermitStatusChangesStore from '../../stores/domain/PermitStatusChanges.store'
import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'
import UserProjectsStore from '../../stores/domain/UserProjects.store'
import ProjectDateStore, {
  isAfter,
  isBefore,
} from '../../stores/ui/ProjectDate.store'
import formatBytes from '../../utils/formatBytes'
import { EMPTY_STRING, NO_VALUE } from '../../utils/usefulStrings'
import { copyObject } from '../../utils/util'
import CalendarPlaceholderStore from '../CalendarDayView/components/CalendarPlaceholder.store'
import PermitTypeIcon from '../PermitTypeIcon/PermitTypeIcon'
import { ITabObject } from '../Tabs/Tabs'

const updateEvents = [
  e.SAVE_SITE_PERMITS,
  e.ADD_SITE_PERMIT_INSPECTION,
  e.UPDATE_SITE_PERMIT_INSPECTION,
  e.SAVE_SITE_PERMIT_BIC_INSPECTION,
  e.ADD_SITE_PERMIT_INSPECTION,
  e.MESSAGE_UPDATED,
  e.SAVE_MESSAGE,
  e.SAVE_SITE_PERMIT_FOLLOWINGS,
  e.DELETE_SITE_PERMIT_FOLLOWINGS,
]

const updateSuccessEvents = [
  e.SAVE_SITE_PERMITS_SUCCESS,
  e.MESSAGE_UPDATED,
  e.ADD_SITE_PERMIT_INSPECTION_SUCCESS,
  e.UPDATE_SITE_PERMIT_INSPECTION_SUCCESS,
  e.SAVE_SITE_PERMIT_BIC_INSPECTION_SUCCESS,
]

const logLoadingEvents = [
  e.LOAD_AND_LISTEN_TO_SITE_PERMIT_STATUS_CHANGES,
  e.LOAD_AND_LISTEN_TO_SITE_PERMIT_BIC_INSPECTIONS,
]

export interface IInspectionData {
  date: Date
  inspection: PermitInspection
}

export enum FormDropdownType {
  NONE,
  DEFAULT,
  MATERIAL,
  PROCUREMENT_ID,
  FORM_TYPE,
}

export interface IFormDropdownOptionCard {
  title: string
  value: string
  isSearchable?: boolean
  hasBoldFont?: boolean
  location?: LocationBase
}

export interface IFormDropdownOption {
  title: string
  value: string
  cardOptions?: IFormDropdownOptionCard[]
  icon?: JSX.Element
  isSelected?: boolean
}

export enum FormDetailsTab {
  FORM = 'form',
  LOG = 'log',
}

export const pdfFolder = '/pdfs'
export const imageFolder = '/images'

const MAX_INSPECTIONS_COUNT = 4
const MAX_MONTHS_VALUE = 12
const MAX_WEEKS_VALUE = 52
const MAX_DAYS_VALUE = 365
const MAX_DATE_RANGE_YEARS = 2
const LOADING_LOG_DELAY_MS = 700

export default class SitePermitCreationFormStore {
  @observable public isAttachmentUploading: boolean = false
  @observable public isNonWorkTimesModalOpened: boolean = false
  @observable public shouldShowNonWorkLabel: boolean = false
  @observable public shouldBlockSubmitButton: boolean = false
  @observable public isTypeSelectorShown: boolean
  @observable public activeDetailsTab: FormDetailsTab = FormDetailsTab.FORM
  @observable public isReportDownloading = false
  @observable public isDeleteConfirmModalOpen = false

  @observable public modalSearchValue: string = null

  @observable public selectedField: IPermitTypeField
  @observable public selectedFieldIndex: number
  @observable public selectedTableId: string
  @observable public selectedTableRowIndex: number
  @observable public selectedSubField: IPermitTypeField
  @observable public selectedFieldDropdownType: FormDropdownType =
    FormDropdownType.NONE
  @observable public selectedLocationType: LocationType
  @observable public selectedOnChangeHandler: <T>(newValue: T) => void

  @observable private scrollToBottomFn: () => void = null

  public readonly workflowFieldsStore: PermitWorkflowFieldsStore
  public readonly inspectionFieldsStore: PermitInspectionFieldsStore

  private logLoadingTimeoutId: NodeJS.Timeout

  @computed
  public get isPermitUpdating(): boolean {
    return updateEvents.some(ev => this.state.loading.get(ev))
  }

  @computed
  public get isLoading(): boolean {
    return (
      this.isPermitUpdating ||
      this.isAttachmentUploading ||
      this.isReportDownloading
    )
  }

  @computed
  public get isLogDataLoading(): boolean {
    return (
      !this.permitStatusChangesStore?.isDataReceived ||
      !this.permitBicInspectionsStore?.isDataReceived ||
      logLoadingEvents.some(ev => this.state.loading.get(ev))
    )
  }

  @computed
  public get editablePermit(): SitePermit {
    return this.workflowFieldsStore.editableEntity
  }

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly fileUploadingStore: FileUploadingStore,
    protected readonly permitTypesStore: PermitTypesStore,
    private readonly permitBicInspectionsStore: PermitBicInspectionsStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly companiesStore: CompaniesStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly materialsStore: MaterialsStore,
    private readonly materialCategoriesStore: MaterialCategoriesStore,
    private readonly sitePermitsStore?: SitePermitsStore,
    private readonly permitStatusChangesStore?: PermitStatusChangesStore,
    private readonly permitInspectionsStore?: PermitInspectionsStore,
    private readonly permitInspectionChangesStore?: PermitInspectionChangesStore,
    private readonly calendarPlaceholderStore?: CalendarPlaceholderStore,
  ) {
    this.workflowFieldsStore = new PermitWorkflowFieldsStore(
      this.eventsStore,
      this.projectDateStore,
      this.permitTypesStore,
      this.companiesStore,
      this.sitePermitsStore,
    )
    this.inspectionFieldsStore = new PermitInspectionFieldsStore(
      this.eventsStore,
      this.workflowFieldsStore,
    )
  }

  @computed
  public get shouldShowInspectionPopup(): boolean {
    return (
      !!this.inspectionFieldsStore.selectedInspection &&
      !!this.editablePermit.id
    )
  }

  @computed
  public get bicInspections(): PermitBicInspection[] {
    return this.permitBicInspectionsStore.getBicInspectionsForPermit(
      this.editablePermit?.id,
    )
  }

  @computed
  public get statusChanges(): PermitStatusChange[] {
    return this.permitStatusChangesStore.getStatusChangesForPermit(
      this.editablePermit?.id,
    )
  }

  @computed
  public get isLastStep(): boolean {
    return this.template?.isLastStep(this.existingPermit.currentWorkflowStepId)
  }

  public getLatestInspectionChangeByDate = (
    date: number | Date,
  ): PermitInspection => {
    const { isSameDay } = this.projectDateStore
    const { id: permitId } = this.editablePermit
    const inspection = this.permitInspectionsStore
      .getInspectionsForPermit(permitId)
      .find(insp => isSameDay(insp.inspectionDate, date))

    if (!inspection) {
      return null
    }

    const latestChange =
      this.permitInspectionChangesStore.getLatestChangeForInspection(
        inspection.id,
      )

    return latestChange?.toInspection(permitId) || inspection
  }

  @computed
  public get permitInspectionData(): IInspectionData[] {
    if (!this.isInspectionPermit) {
      return []
    }

    const { isSameDay, startOfDay } = this.projectDateStore
    const { id, endDate, getAllInspectionDates } = this.editablePermit

    const now = Date.now()
    const endDateToUse = isAfter(endDate, now) ? now : endDate
    const inspectionDates = getAllInspectionDates(
      this.permitTypesStore,
      this.projectDateStore,
      endDateToUse,
    )
    const permitInspections =
      this.permitInspectionsStore.getInspectionsForPermit(id)
    const existingInspectionDates = permitInspections.map(pi =>
      startOfDay(pi.inspectionDate),
    )
    const allDates = inspectionDates
      .concat(existingInspectionDates)
      .sort((a, b) => a.getTime() - b.getTime())

    return allDates.reduce((list, dt) => {
      if (list.find(inspd => isSameDay(inspd.date, dt))) {
        return list
      }

      const inspection = permitInspections.find(({ inspectionDate }) =>
        isSameDay(inspectionDate, dt),
      )

      if (!inspection) {
        list.push({ date: dt, inspection: null })
        return list
      }

      const latest =
        this.permitInspectionChangesStore.getLatestChangeForInspection(
          inspection.id,
        )

      list.push({
        date: dt,
        inspection: latest?.toInspection(inspection.permitId) || inspection,
      })
      return list
    }, [] as IInspectionData[])
  }

  public get permitTypes(): PermitType[] {
    return this.permitTypesStore.enabledTypes
  }

  public get isInspectionPermit(): boolean {
    return this.template?.isInspectionType
  }

  public get inspectionOptions() {
    return this.template?.inspectionOptions
  }

  public get inspectionFrequency(): number {
    return this.editablePermit?.getInspectionFrequency(this.template)
  }

  public get inspectionFrequencyType() {
    return this.editablePermit?.getInspectionFrequencyType(this.template)
  }

  public get isEmissionFormType(): boolean {
    return this.template?.isEmissionFormType
  }

  public get selectedDaysToRepeat(): number[] {
    return this.editablePermit?.getSelectedDaysToRepeat(this.template)
  }

  public get shouldShowDaysToRepeat(): boolean {
    return this.inspectionFrequencyType === CalendricalType.Week
  }

  public get isBeforeDeadlineEnds(): boolean {
    if (!this.isInspectionPermit || this.editablePermit.isDoneOrDenied) {
      return false
    }

    if (this.isAdminOrFormsMaster) {
      return true
    }

    const { addHours, startOfDay } = this.projectDateStore
    const { deadlineTime } = this.inspectionOptions

    const now = Date.now()
    const deadlineDate = addHours(startOfDay(now), deadlineTime)

    return isBefore(now, deadlineDate)
  }

  public get isNewInspectionAllowed(): boolean {
    if (!this.isInspectionPermit || this.editablePermit.isDoneOrDenied) {
      return false
    }

    return this.isTodayInspectionDay && this.isBeforeDeadlineEnds
  }

  public get isInspectionDeadlineEnded(): boolean {
    if (!this.isInspectionPermit || this.editablePermit.isDoneOrDenied) {
      return false
    }

    return this.isTodayInspectionDay && !this.isBeforeDeadlineEnds
  }

  public get isTodayInspectionDay(): boolean {
    if (!this.isInspectionPermit || this.editablePermit.isDoneOrDenied) {
      return false
    }

    return this.projectDateStore.isSameDay(
      this.currentOrPreviousInspectionDate,
      Date.now(),
    )
  }

  public get currentOrPreviousInspectionDate(): Date {
    if (!this.isInspectionPermit || this.editablePermit.isDoneOrDenied) {
      return null
    }

    return this.editablePermit.getLatestInspectionDate(
      this.permitTypesStore,
      this.projectDateStore,
    )
  }

  public get nextInspectionDates(): Date[] {
    if (!this.isInspectionPermit || this.editablePermit.isDoneOrDenied) {
      return null
    }

    const now = new Date()

    return this.editablePermit
      .getAllInspectionDates(this.permitTypesStore, this.projectDateStore)
      .filter(dt => dt > now)
      .filter((_, i) => i < MAX_INSPECTIONS_COUNT)
  }

  public get shouldBlockOnNonWorkTimes(): boolean {
    return this.template?.shouldBlockOnNonWorkTimes
  }

  public get isLastInspectionDay(): boolean {
    return this.workflowFieldsStore.isLastInspectionDay
  }

  public get template(): PermitType {
    return this.workflowFieldsStore.template
  }

  @action.bound
  public setScrollToBottomFn(fn: () => void) {
    this.scrollToBottomFn = fn
  }

  @action.bound
  public scrollToBottom() {
    this.scrollToBottomFn?.()
  }

  public get isFormEditAllowedForUser(): boolean {
    return (
      this.isAdminOrFormsMaster ||
      this.existingPermit?.isRequesterOrAssignee(this.state.user.id)
    )
  }

  public get isPermitDatesFieldSelected(): boolean {
    return this.selectedField?.type === PermitFieldType.StartFinishDates
  }

  public get selectedFieldValues(): any[] {
    const fieldsStore = this.shouldShowInspectionPopup
      ? this.inspectionFieldsStore
      : this.workflowFieldsStore

    return (
      fieldsStore.getFieldValues(
        this.selectedField?.id,
        this.selectedTableId,
        this.selectedTableRowIndex,
      ) || []
    )
  }

  @action.bound
  public setDefaultSitePermit(
    permitToEditId?: string,
    typeId?: string,
    isLastStep?: boolean,
    prepopulatedFields?: IPermitFieldDto[],
  ) {
    this.workflowFieldsStore.setDefaultSitePermit(
      permitToEditId,
      typeId,
      isLastStep,
      prepopulatedFields,
    )
    if (!permitToEditId) {
      this.inspectionFieldsStore.setEmptyInspection()
    }
  }

  @action.bound
  public setSelectedField(
    typeField: IPermitTypeField,
    index?: number,
    tableId?: string,
    tableRowIndex?: number,
  ) {
    this.selectedField = typeField
    this.selectedFieldIndex = index

    this.selectedTableId = tableId
    this.selectedTableRowIndex = tableRowIndex
  }

  @action.bound
  public resetSelectedField() {
    this.selectedField = null
    this.selectedFieldIndex = null
    this.selectedTableId = null
    this.selectedTableRowIndex = null

    this.setSelectedSubField(null)
    this.setSelectedFieldDropdownType(FormDropdownType.NONE)
    this.setSelectedLocationType(null)
    this.setSelectedOnChangeHandler(null)
  }

  @action.bound
  public setSelectedSubField(typeSubField: IPermitTypeField) {
    this.selectedSubField = typeSubField
  }

  @action.bound
  public setSelectedFieldDropdownType(dropdownType: FormDropdownType) {
    this.selectedFieldDropdownType = dropdownType || FormDropdownType.NONE
  }

  @action.bound
  public setSelectedLocationType(locationType: LocationType) {
    this.selectedLocationType = locationType
  }

  @action.bound
  public setSelectedOnChangeHandler(onChangeHandler: <T>(newValue: T) => void) {
    this.selectedOnChangeHandler = onChangeHandler
  }

  @action.bound
  public changeSelectedFieldValue<T>(newValue: T, shouldSkipReset?: boolean) {
    const fieldsStore = this.shouldShowInspectionPopup
      ? this.inspectionFieldsStore
      : this.workflowFieldsStore

    if (this.selectedOnChangeHandler) {
      this.selectedOnChangeHandler(newValue)
    } else if (this.selectedTableId) {
      fieldsStore.changeTableFieldValue(
        this.selectedTableId,
        this.selectedTableRowIndex,
        this.selectedField,
        this.selectedFieldIndex,
        newValue,
      )
    } else {
      fieldsStore.changeFieldValue(
        this.selectedField,
        this.selectedFieldIndex,
        newValue,
      )
    }

    if (!shouldSkipReset) {
      this.resetSelectedField()
    }
  }

  @action.bound
  public updateSearchValue(newValue: string) {
    this.modalSearchValue = newValue
  }

  @action.bound
  public applyDates(newStartDate: Date, newEndDate: Date, isAllDay?: boolean) {
    if (!newStartDate || !newEndDate) return

    const isAllDayPermit = !!isAllDay
    this.editablePermit.startDate = newStartDate.getTime()
    this.editablePermit.endDate = newEndDate.getTime()
    this.editablePermit.isAllDay = isAllDayPermit

    if (this.calendarPlaceholderStore.eventPlaceholder) {
      this.calendarPlaceholderStore.setEventPlaceholderTime(
        newStartDate,
        newEndDate,
        isAllDayPermit,
      )
    }

    if (this.isPermitInsideWorkTime) {
      this.hideNonWorkTimeLabel()
      this.unblockSubmitButton()
    }

    this.openNonWorkTimeModalIfNeed()
  }

  @action.bound
  public applySpecificInspectionFrequency(newFrequency: number) {
    if (!this.isInspectionPermit) {
      return
    }

    this.createInspectionOptionsIfNotExists()

    const { specificInspectionOptions: options } = this.editablePermit

    options.inspectionFrequency =
      newFrequency > this.inspectionMaxNumber
        ? this.inspectionMaxNumber
        : newFrequency
  }

  @action.bound
  public applySpecificInspectionFrequencyType(
    newFrequencyType: CalendricalType,
  ) {
    if (!this.isInspectionPermit) {
      return
    }

    this.createInspectionOptionsIfNotExists()

    const { specificInspectionOptions: options } = this.editablePermit

    options.inspectionFrequencyType = newFrequencyType

    if (options.inspectionFrequency > this.inspectionMaxNumber) {
      return this.applySpecificInspectionFrequency(this.inspectionMaxNumber)
    }
  }

  @action.bound
  public changeDayToRepeat(dayNumber: number) {
    if (!this.isInspectionPermit) {
      return
    }

    this.createInspectionOptionsIfNotExists()

    const { specificInspectionOptions } = this.editablePermit

    if (!specificInspectionOptions.selectedDaysToRepeat) {
      specificInspectionOptions.selectedDaysToRepeat = []
    }

    const index = specificInspectionOptions.selectedDaysToRepeat.findIndex(
      day => day === dayNumber,
    )
    if (index === -1) {
      specificInspectionOptions.selectedDaysToRepeat.push(dayNumber)
    } else {
      specificInspectionOptions.selectedDaysToRepeat.splice(index, 1)
    }
  }

  @action.bound
  public setIsReportDownloading() {
    this.isReportDownloading = true
  }

  @action.bound
  public resetIsReportDownloading() {
    this.isReportDownloading = false
  }

  @action.bound
  public toggleDeleteConfirmModal() {
    this.isDeleteConfirmModalOpen = !this.isDeleteConfirmModalOpen
  }

  public onPermitTypeChanged = (typeId: string) => {
    if (!this.editablePermit.id) {
      this.inspectionFieldsStore.deselectInspection()
      this.setDefaultSitePermit(null, typeId)
      const { startDate, endDate, isAllDay } =
        this.calendarPlaceholderStore.eventPlaceholder || {}
      this.applyDates(startDate, endDate, isAllDay)
    }
  }

  public uploadAttachment = async (
    file: File,
    typeField: IPermitTypeField,
    fieldsStore: IPermitFieldsStore,
  ) => {
    if (!file) {
      return
    }

    const fileType = !file.name.endsWith('.pdf')
      ? UploadingType.Image
      : UploadingType.Pdf

    this.setAttachmentUploading()

    const [result] = await this.fileUploadingStore.uploadFile(
      file,
      fileType,
      file.name,
    )

    fieldsStore.addAttachmentValue(
      {
        fileName: file.name,
        size: formatBytes(file.size),
        url: result.fileURL,
      },
      typeField,
    )

    this.resetAttachmentUploading()
  }

  @computed
  public get isPermitValid(): boolean {
    return (
      this.workflowFieldsStore.areAllFieldsValid &&
      !this.shouldBlockSubmitButton
    )
  }

  @computed
  public get isChangedPermitValid(): boolean {
    return (
      !this.shouldBlockSubmitButton &&
      this.workflowFieldsStore.areChangedFieldsValid
    )
  }

  @action.bound
  public createPermit() {
    this.setNextWorkflowStep()

    this.editablePermit.fields = this.editablePermit.fields.filter(f =>
      this.workflowFieldsStore.fields.some(pf => pf.id === f.fieldId),
    )

    this.eventsStore.dispatch(e.SAVE_SITE_PERMITS, [this.editablePermit])
  }

  @action.bound
  public updatePermit(callbackFn?: (forms: ISitePermit[]) => void) {
    this.setWorkflowStepLevel()
    this.setNextWorkflowStep()

    const { fields, resetValidationState } = this.workflowFieldsStore

    this.editablePermit.fields = this.editablePermit.fields.filter(f =>
      fields.some(pf => pf.id === f.fieldId),
    )
    resetValidationState()

    this.eventsStore.dispatch(
      e.SAVE_SITE_PERMITS,
      [this.editablePermit],
      callbackFn,
    )
  }

  @action.bound
  public addInspection() {
    const { editableEntity, deselectInspection } = this.inspectionFieldsStore
    editableEntity.fields = editableEntity.fields.filter(f =>
      this.inspectionFieldsStore.fields.some(({ id }) => id === f.fieldId),
    )

    this.eventsStore.dispatch(e.ADD_SITE_PERMIT_INSPECTION, editableEntity)
    deselectInspection()
  }

  @action.bound
  public updateInspection() {
    const { selectedInspection, deselectInspection } =
      this.inspectionFieldsStore
    selectedInspection.fields = selectedInspection.fields.filter(f =>
      this.inspectionFieldsStore.fields.some(({ id }) => id === f.fieldId),
    )

    this.eventsStore.dispatch(
      e.UPDATE_SITE_PERMIT_INSPECTION,
      selectedInspection,
    )
    deselectInspection()
  }

  @action.bound
  public addNotInUseInspection() {
    const { emptyInspection, deselectInspection } = this.inspectionFieldsStore
    this.eventsStore.dispatch(e.ADD_SITE_PERMIT_INSPECTION, emptyInspection)
    deselectInspection()
  }

  @action.bound
  public addBicInspection(isPassed: boolean) {
    if (!this.template?.hasBicInspectionStep) {
      return
    }

    const {
      user,
      activeProject: { id: projectId },
    } = this.state

    const newBicInspection = new PermitBicInspection(
      null,
      this.editablePermit.id,
      projectId,
      user.id,
      0,
      isPassed,
    )

    this.permitBicInspectionsStore.saveBicInspection(newBicInspection)
  }

  @action.bound
  public deleteForm() {
    this.sitePermitsStore.deleteForms([this.existingPermit.id])

    this.isDeleteConfirmModalOpen = false
  }

  @action.bound
  public openNonWorkTimeModalIfNeed() {
    this.isNonWorkTimesModalOpened =
      !this.shouldShowNonWorkLabel &&
      !this.isPermitInsideWorkTime &&
      !this.isAdminOrFormsMaster
  }

  @action.bound
  public closeNonWorkTimeModal() {
    this.isNonWorkTimesModalOpened = false
  }

  @action.bound
  public markPermitAsNonWorkTimeIfNeed() {
    if (this.editablePermit?.id && !this.isPermitInsideWorkTime) {
      return this.shouldBlockOnNonWorkTimes && !this.isAdminOrFormsMaster
        ? this.blockSubmitAndShowNonWorkLabel()
        : this.showNonWorkTimeLabel()
    }

    this.openNonWorkTimeModalIfNeed()
  }

  @action.bound
  public showNonWorkTimeLabel() {
    this.shouldShowNonWorkLabel = true
  }

  @action.bound
  public hideNonWorkTimeLabel() {
    this.shouldShowNonWorkLabel = false
  }

  @action.bound
  public blockSubmitButton() {
    this.shouldBlockSubmitButton = true
  }

  @action.bound
  public unblockSubmitButton() {
    this.shouldBlockSubmitButton = false
  }

  @action.bound
  public blockSubmitAndShowNonWorkLabel() {
    this.blockSubmitButton()
    this.showNonWorkTimeLabel()
  }

  @action.bound
  public showFormTypeSelector() {
    this.isTypeSelectorShown = true
    this.selectedFieldDropdownType = FormDropdownType.FORM_TYPE
  }

  @action.bound
  public hideFormTypeSelector() {
    this.isTypeSelectorShown = false
    this.selectedFieldDropdownType = FormDropdownType.NONE
  }

  public onPermitUpdated = (eventContext: EventContext) => {
    const [eventType] = eventContext.event

    if (updateSuccessEvents.includes(eventType)) {
      Promise.resolve().then(() => this.scrollToBottom())
    }
  }

  private get state() {
    return this.eventsStore.appState
  }

  @action.bound
  public deselectUsersIfNeeded() {
    const selectedCompanies = this.editablePermit.companyIds
      .map(id => this.companiesStore.getCompanyById(id)?.id)
      .filter(id => id)

    const assigneeFields = this.editablePermit.fields?.filter(
      f => f.type === PermitFieldType.Assignee,
    )

    assigneeFields.forEach(field => {
      field.values = field.values.filter(userId => {
        const user = this.projectMembersStore.getById(userId)
        return (
          !user ||
          selectedCompanies.includes(
            UserProject.getCompanyId(user, this.userProjectsStore),
          )
        )
      })
    })
  }

  @action.bound
  public resetLocationsAndEquipmentIfNeed() {
    const { fields, companyIds } = this.editablePermit

    const locationAndEquipmentFields = fields.filter(({ type }) =>
      companyRestrictionFieldTypes.includes(type),
    )

    locationAndEquipmentFields.forEach(field => {
      field.values = field.values.filter(
        l =>
          !this.locationAttributesStore
            .getById(l?.id)
            ?.hasRestrictionForCompanies(companyIds),
      )
    })
  }

  @action.bound
  private setAttachmentUploading() {
    this.isAttachmentUploading = true
  }

  @action.bound
  private resetAttachmentUploading() {
    this.isAttachmentUploading = false
  }

  public get isAssigneeShown(): boolean {
    return this.template?.initialStep?.fields?.find(
      f => f.type === PermitFieldType.Assignee,
    )?.isShown
  }

  @computed
  public get existingPermit(): SitePermit {
    return this.workflowFieldsStore.existingEntity
  }

  private get isPermitInsideWorkTime(): boolean {
    if (!this.editablePermit) {
      return true
    }

    const { startDate, endDate } = this.editablePermit

    if (!startDate || !endDate) {
      return true
    }

    return this.projectDateStore.isInsideWorkingDaysAndHours(startDate, endDate)
  }

  @action.bound
  private createInspectionOptionsIfNotExists() {
    if (!this.editablePermit.specificInspectionOptions) {
      this.editablePermit.specificInspectionOptions = copyObject(
        this.inspectionOptions,
      )
    }
  }

  @action.bound
  private setWorkflowStepLevel() {
    if (this.workflowFieldsStore.areRequiredFieldsChanged) {
      return this.editablePermit.setWorkflowStepLevel(
        getWorkflowStepLevel(
          this.template.initialStep.id,
          this.template.stepsWithoutRecurring,
        ),
      )
    }
    if (this.workflowFieldsStore.areOptionalFieldsChanged) {
      return this.editablePermit.setWorkflowStepLevel(
        this.existingPermit.workflowStepLevel,
      )
    }

    this.editablePermit.setWorkflowStepLevel(
      getWorkflowStepLevel(
        this.editablePermit.currentWorkflowStepId,
        this.template.stepsWithoutRecurring,
      ),
    )
  }

  @action.bound
  private setNextWorkflowStep() {
    if (this.editablePermit.isDenied) {
      return
    }

    if (this.workflowFieldsStore.areRequiredFieldsChanged) {
      this.editablePermit.setWorkflowStepId(this.template.initialStep.id)
    } else if (this.workflowFieldsStore.areOptionalFieldsChanged) {
      this.editablePermit.setWorkflowStepId(
        this.existingPermit.currentWorkflowStepId,
      )
      return
    }

    const { stepsWithoutRecurring } = this.template

    const currentStepIndex = stepsWithoutRecurring.findIndex(
      s => s.id === this.editablePermit.currentWorkflowStepId,
    )

    if (currentStepIndex === -1) {
      return
    }

    const nextStepIndex =
      currentStepIndex + 1 >= stepsWithoutRecurring.length
        ? stepsWithoutRecurring.length - 1
        : currentStepIndex + 1

    this.editablePermit.setWorkflowStepId(
      stepsWithoutRecurring[nextStepIndex].id,
    )
  }

  public get selectedPermitCompanyIds(): string[] {
    return this.editablePermit?.companyIds || []
  }

  @computed
  public get isAnyDialogOpened(): boolean {
    return !!this.selectedField || this.isNonWorkTimesModalOpened
  }

  public get isAdminOrFormsMaster(): boolean {
    return this.state.userActiveProjectSettings.isAdminOrFormsMaster
  }

  @computed
  public get inspectionMaxNumber(): number {
    const { specificInspectionOptions } = this.editablePermit

    switch (specificInspectionOptions?.inspectionFrequencyType) {
      case CalendricalType.Day:
        return MAX_DAYS_VALUE
      case CalendricalType.Week:
        return MAX_WEEKS_VALUE
      case CalendricalType.Month:
        return MAX_MONTHS_VALUE
    }
  }

  public get isDatePickerDisplayed(): boolean {
    return (
      this.selectedField?.type === PermitFieldType.StartFinishDates ||
      this.selectedField?.type === PermitFieldType.AdditionalDate
    )
  }

  public get isLocationPickerDisplayed(): boolean {
    return locationPermitFieldTypes.includes(
      this.selectedSubField?.type || this.selectedField?.type,
    )
  }

  public get isCompanyPickerDisplayed(): boolean {
    return this.selectedField?.type === PermitFieldType.Company
  }

  public get isRequesterPickerDisplayed(): boolean {
    return this.selectedField?.type === PermitFieldType.Requester
  }

  public get isAssignedToPickerDisplayed(): boolean {
    return this.selectedField?.type === PermitFieldType.Assignee
  }

  public get isSubscriberPickerDisplayed(): boolean {
    return this.selectedField?.type === PermitFieldType.Subscriber
  }

  public get isInfoUserPickerDisplayed(): boolean {
    return this.selectedField?.type === PermitFieldType.User
  }

  public get isUserPickerDisplayed(): boolean {
    return (
      this.isRequesterPickerDisplayed ||
      this.isAssignedToPickerDisplayed ||
      this.isSubscriberPickerDisplayed ||
      this.isInfoUserPickerDisplayed
    )
  }

  public get datePickerStart(): number {
    const { startDate } = this.editablePermit

    if (this.isPermitDatesFieldSelected) {
      return startDate
    }

    return this.selectedFieldValues[this.selectedFieldIndex] || Date.now()
  }

  public get datePickerEnd(): number {
    return this.isPermitDatesFieldSelected
      ? this.editablePermit.endDate
      : this.datePickerStart
  }

  public get isAllDay(): boolean {
    return this.editablePermit.isAllDay
  }

  @action.bound
  public toggleAllDay() {
    const { startDate: editableStartDate, endDate: editableEndDate } =
      this.editablePermit

    const { setHours, projectStartHourMinutes, projectEndHourMinutes } =
      this.projectDateStore

    const newIsAllDay = !this.editablePermit.isAllDay

    if (!newIsAllDay) {
      this.applyDates(
        new Date(editableStartDate),
        new Date(editableEndDate),
        newIsAllDay,
      )
      return
    }

    const [startHours, startMinutes] = projectStartHourMinutes
    const [endHours, endMinutes] = projectEndHourMinutes

    const startDate = setHours(
      new Date(editableStartDate),
      startHours,
      startMinutes,
    )
    const endDate = setHours(new Date(editableStartDate), endHours, endMinutes)

    this.applyDates(startDate, endDate, newIsAllDay)
  }

  public get isOneDayMode(): boolean {
    return !this.isPermitDatesFieldSelected
  }

  public get maxDateRangeInYears(): number {
    return this.isPermitDatesFieldSelected ? MAX_DATE_RANGE_YEARS : 0
  }

  public get shouldShowTimePicker(): boolean {
    return this.isPermitDatesFieldSelected
  }

  public get dropdownModalOptions(): IFormDropdownOption[] {
    if (this.selectedFieldDropdownType === FormDropdownType.NONE) {
      return []
    }

    const options = this.dropdownOptionsByType
    if (!this.isTypeSelectorShown) {
      options.unshift({ title: NO_VALUE, value: EMPTY_STRING })
    }

    return options.filter(opt => {
      const searchKey = this.modalSearchValue?.toLowerCase() || EMPTY_STRING
      return (
        opt.title?.toLowerCase()?.includes(searchKey) ||
        opt.cardOptions?.some(
          v => v.isSearchable && v.value?.toLowerCase().includes(searchKey),
        )
      )
    })
  }

  private get dropdownOptionsByType(): IFormDropdownOption[] {
    switch (this.selectedFieldDropdownType) {
      case FormDropdownType.DEFAULT:
        return (
          this.selectedField?.selectOptions?.map(v => ({
            title: v,
            value: v,
          })) || []
        )
      case FormDropdownType.MATERIAL:
        return this.dropdownMaterialOptions
      case FormDropdownType.PROCUREMENT_ID:
        return this.dropdownProcurementOptions
      case FormDropdownType.FORM_TYPE:
        return this.permitTypes.map(type => ({
          title: type.name,
          value: type.id,
          icon: PermitTypeIcon({
            permitType: type.type,
            className: 'row mr10 no-grow',
          }),
        }))

      default:
        return []
    }
  }

  private get dropdownMaterialOptions(): IFormDropdownOption[] {
    return this.materialsStore.list.reduce((list, material) => {
      const category = this.materialCategoriesStore.getInstanceById(
        material.categoryId,
      )

      if (!category?.name || !material.productName) {
        return list
      }

      list.push({
        title: material.productName,
        value: material.id,
        cardOptions: [
          {
            title: this.selectedField.caption + ' L1',
            value: category.name,
            isSearchable: true,
          },
          {
            title: this.selectedField.caption + ' L2',
            value: material.productName,
            hasBoldFont: true,
            isSearchable: true,
          },
        ],
      })
      return list
    }, [] as IFormDropdownOption[])
  }

  private get dropdownProcurementOptions(): IFormDropdownOption[] {
    const { list, getInstanceById: getMaterialById } = this.materialsStore

    const formMaterial = this.selectedFieldValues[
      this.selectedFieldIndex
    ] as IFormMaterial

    const material = getMaterialById(formMaterial?.materialId)

    return list.reduce((res, m) => {
      if (material?.hasProcurementIds && m.id !== material.id) {
        return res
      }

      m.procurementDataList?.forEach(pr =>
        res.push({
          value: pr.procurementId,
          title: pr.procurementId,
          cardOptions: this.getProcurementCardOptions(pr, m),
        }),
      )
      return res
    }, [] as IFormDropdownOption[])
  }

  private getProcurementCardOptions = (
    procurementData: IMaterialProcurementData,
    material: Material,
  ): IFormDropdownOptionCard[] => {
    const installLocation = this.locationAttributesStore.getById(
      procurementData.installLocationId,
    )
    const deliveryLocation = this.locationAttributesStore.getById(
      procurementData.deliveryLocationId,
    )

    return [
      {
        title: Localization.translator.procurementID,
        value: procurementData.procurementId,
        hasBoldFont: true,
      },
      {
        title: this.selectedField.caption + ' L1',
        value: this.materialCategoriesStore.getCategoryNameById(
          material.categoryId,
        ),
      },
      {
        title: this.selectedField.caption + ' L2',
        value: material.productName,
      },
      {
        title: Localization.translator.description,
        value: procurementData.description,
      },
      {
        title: Localization.translator.plannedInstallLocation,
        value: installLocation?.name,
        location: installLocation,
        isSearchable: true,
      },
      {
        title: Localization.translator.plannedDeliveryLocation,
        value: deliveryLocation?.name,
        location: deliveryLocation,
      },
      {
        title: Localization.translator.plannedQuantity,
        value: procurementData.plannedQuantity?.toString(),
      },
    ]
  }

  public get formDetailTabs(): Array<ITabObject<FormDetailsTab>> {
    return [
      {
        title: Localization.translator.form,
        page: FormDetailsTab.FORM,
      },
      {
        title: Localization.translator.log,
        page: FormDetailsTab.LOG,
      },
    ]
  }

  @action.bound
  public switchActiveTab(newActiveTab: FormDetailsTab) {
    this.activeDetailsTab = newActiveTab

    Promise.resolve().then(() => this.scrollToBottom())
  }

  public loadFormLogData = () => {
    this.logLoadingTimeoutId = setTimeout(async () => {
      await Promise.all([
        this.permitStatusChangesStore.loadAndListenStatusChangesByFormId(
          this.existingPermit.id,
        ),
        this.permitBicInspectionsStore.loadAndListenBicInspectionsByFormId(
          this.existingPermit.id,
        ),
      ])
      if (!this.logLoadingTimeoutId) {
        Promise.resolve().then(() => this.clearFormLogData())
      }
    }, LOADING_LOG_DELAY_MS)
  }

  public clearFormLogData = () => {
    clearTimeout(this.logLoadingTimeoutId)
    this.logLoadingTimeoutId = null

    this.permitStatusChangesStore.dontListenToStatusChanges()
    this.permitBicInspectionsStore.dontListenToBicInspections()
  }
}
