import * as React from 'react'

import {
  Classes,
  Icon,
  Popover,
  PopperModifiers,
  Position,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import {
  IsNotOperator,
  LogicalOperator,
  getOperatorDisplayText,
} from '~/client/src/shared/models/LogicOperation'

// localization: translated

interface IProps {
  selectedOperator: LogicalOperator
  logicOperatorsList?: LogicalOperator[]
  className?: string
  isSelectable?: boolean
  isDisabled?: boolean

  onChange?: (newOperator: IsNotOperator) => void
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: {
    enabled: false,
  },
  hide: { enabled: false },
  computeStyle: { gpuAcceleration: false },
  arrow: { enabled: false },
}

@observer
export default class CategoryLogicalOperator extends React.Component<IProps> {
  public render() {
    const { className, selectedOperator, isSelectable } = this.props

    if (!selectedOperator) {
      return null
    }

    return (
      <Popover
        className="vertical-align-middle no-grow"
        autoFocus={false}
        enforceFocus={false}
        captureDismiss
        disabled={!isSelectable}
        usePortal={false}
        minimal
        position={Position.BOTTOM}
        modifiers={popoverPopperModifiers}
        content={this.renderOperatorSelectionDialog()}
      >
        <div
          className={classList({
            'row no-grow px5 h28 brada4 ba-light-input-border text large monospace nowrap':
              true,
            pointer: isSelectable,
            'unclickable-element': !isSelectable,
            [className]: !!className,
          })}
        >
          <span>{getOperatorDisplayText(selectedOperator)}</span>
          {isSelectable && (
            <Icon icon={IconNames.CARET_DOWN} className="ml5 text light" />
          )}
        </div>
      </Popover>
    )
  }

  private renderOperatorSelectionDialog(): JSX.Element {
    const { isSelectable, selectedOperator, onChange, logicOperatorsList } =
      this.props

    if (!logicOperatorsList?.length || !isSelectable) {
      return null
    }

    return (
      <div className="col mw90">
        {logicOperatorsList.map((operator, index) => {
          const isLastElement = index === logicOperatorsList.length - 1
          const isSelected = selectedOperator === operator

          return (
            <div
              key={operator}
              className={classList({
                'row x-between text large monospace pa10': true,
                'bb-palette-brand-lighter': !isLastElement,
                'blue-highlight unclickable-element': isSelected,
                pointer: !isSelected,
                [Classes.POPOVER_DISMISS]: true,
              })}
              onClick={onChange?.bind(null, operator)}
            >
              {getOperatorDisplayText(operator)}
              {isSelected && <Icons.CheckPaletteBlue className="no-flex" />}
            </div>
          )
        })}
      </div>
    )
  }
}
