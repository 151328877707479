import { computed } from 'mobx'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import Activity from '~/client/src/shared/models/Activity'
import ActivityCode from '~/client/src/shared/models/ActivityCode'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import LocationsStore from '~/client/src/shared/stores/domain/Locations.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import Guard from '~/client/src/shared/utils/Guard'

const companiesNumber = 4
const alphabeticSymbols = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const NO_COMPANY = ' - '
const EMPTY_CELL_VALUE = ''

export default class DownloadActivitiesButtonStore {
  private activityProps = []

  public constructor(
    private readonly activitiesStore: ActivitiesStore,
    private readonly statusUpdatesStore: StatusUpdatesStore,
    private readonly locationStore: LocationsStore,
    private readonly state: DesktopInitialState,
    private readonly projectDateStore: ProjectDateStore,
    private readonly projectMembersStore: ProjectMembersStore,
  ) {
    Guard.requireAll({
      statusUpdatesStore,
      locationStore,
      state,
    })

    const companyRows = []
    const percentCompleteRows = []
    const userAndTimeStampRows = []
    Array(companiesNumber)
      .fill('')
      .forEach((num, index) => {
        companyRows.push({ title: 'Company', index })
        percentCompleteRows.push({ title: '% Complete', index })
        userAndTimeStampRows.push({ title: 'User', index })
        userAndTimeStampRows.push({ title: 'Date & Timestamp', index })
      })
    this.activityProps = [
      { title: 'Status Report Date' },
      { title: 'Activity ID', name: 'code' },
      { title: 'Activity Name', name: 'name' },
      { title: 'Level' },
      { title: 'Zone' },
    ]
    this.activityProps.push(...companyRows)
    this.activityProps.push(
      { title: 'Planned Manpower' },
      { title: 'Actual Manpower' },
      { title: 'Aggregate % Complete' },
    )
    this.activityProps.push(...percentCompleteRows)
    this.activityProps.push(...userAndTimeStampRows)
    this.activityProps.push(
      { title: 'Planned Start Date', name: 'plannedStartDay' },
      { title: 'Planned End Date', name: 'plannedEndDay' },
      { title: 'Planned Duration', name: 'plannedDuration' },
      { title: 'Actual Start Date', name: 'actualStartDay' },
      { title: 'Actual End Date', name: 'actualEndDay' },
      { title: 'Earned Duration' },
      { title: 'Remaining Duration', name: 'remainingDuration' },
    )
  }

  @computed
  public get allActivitiesAsCsv(): string {
    const rows = []
    const { levelCodeIds, zoneCodeIds } = this.locationStore
    const header = this.activityProps
      .map(({ title, index }) => {
        return (
          title + (!!index || index === 0 ? ' ' + alphabeticSymbols[index] : '')
        )
      })
      .join(',')
    rows.push(header)

    this.activitiesStore.allDoneInP6Activities.forEach(activity => {
      const { percentComplete } = activity.commonStatusUpdate
      const earnedDuration = (percentComplete * activity.plannedDuration) / 100

      const { companies } = activity
      const companiesStatusesUpdate = this.getCompaniesStatusesUpdates(
        companies,
        activity,
      )

      const { codes } = activity
      const today = new Date()
      const levelCodes = codes.filter(c => levelCodeIds.includes(c.id))
      const zoneCodes = codes.filter(c => zoneCodeIds.includes(c.id))

      const row = this.activityProps
        .map(({ name, title, index }) => {
          if (name) {
            return this.getEscapedStringValue(activity[name])
          }

          switch (title) {
            case 'Status Report Date':
              return this.projectDateStore.getDashedFormattedDate(today)

            case 'Level':
              return this.getEscapedStringValue(
                ActivityCode.getLocationTagLabel(levelCodes),
              )

            case 'Zone':
              return this.getEscapedStringValue(
                ActivityCode.getLocationTagLabel(zoneCodes),
              )

            case 'Company':
              return this.getCompany(index, companies)

            case 'Actual Manpower':
              return activity.commonStatusUpdate.manpower

            case 'Aggregate % Complete':
              return percentComplete

            case 'Date & Timestamp':
              return this.getDateAndTimestamp(
                companiesStatusesUpdate[index],
                companies[index],
              )

            case 'User':
              return this.getStatusUpdateAuthor(companiesStatusesUpdate[index])

            case 'Earned Duration':
              return earnedDuration

            case '% Complete':
              return this.getPercentComplete(
                companiesStatusesUpdate[index],
                companies[index],
              )
          }
        })
        .join(',')
      rows.push(row)
    })

    return rows.join('\n')
  }

  private getCompaniesStatusesUpdates(
    companies: string[],
    activity: Activity,
  ): StatusUpdate[] {
    const companiesStatusUpdate = []
    companies.forEach(company => {
      companiesStatusUpdate.push(
        this.statusUpdatesStore.getLastStatusUpdateByActivityAndCompany(
          activity,
          company,
        ),
      )
    })
    return companiesStatusUpdate
  }

  private getDateAndTimestamp(
    companyStatusUpdate: StatusUpdate,
    company: string,
  ): string {
    if (!companyStatusUpdate) {
      return EMPTY_CELL_VALUE
    }
    const companyStatusUpdateData =
      companyStatusUpdate.findCompanyStatus(company)
    return companyStatusUpdateData
      ? this.projectDateStore.getDashedFormattedDate(
          companyStatusUpdateData.updatedAt,
        )
      : EMPTY_CELL_VALUE
  }

  private getStatusUpdateAuthor(companyStatusUpdate: StatusUpdate): string {
    if (!companyStatusUpdate || !companyStatusUpdate.authorId) {
      return EMPTY_CELL_VALUE
    }

    const user = this.projectMembersStore.getById(companyStatusUpdate.authorId)

    return user?.email || ''
  }

  private getCompany(index: number, companies: string[]): string {
    const emptySymbol = index === 0 ? NO_COMPANY : EMPTY_CELL_VALUE
    return this.getEscapedStringValue(companies[index] || emptySymbol)
  }

  private getPercentComplete(
    companyStatusUpdate: StatusUpdate,
    company: string,
  ): string {
    if (!companyStatusUpdate) {
      return EMPTY_CELL_VALUE
    }

    const companyStatusUpdateData =
      companyStatusUpdate.findCompanyStatus(company)
    const percentComplete = companyStatusUpdateData
      ? companyStatusUpdateData.percentComplete
      : EMPTY_CELL_VALUE
    return this.getEscapedStringValue(percentComplete)
  }

  private getEscapedStringValue(value: any) {
    if (
      typeof value === 'string' &&
      (value.includes(',') || value.includes('"'))
    ) {
      return '"' + value.replace(/"/g, '""') + '"'
    }

    return value
  }
}
