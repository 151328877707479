import AssociationItem from '~/client/src/shared/models/AssociationItem/AssociationItem'
import IFollowingsStore from '~/client/src/shared/models/IFollowingsStore'

import Localization from '../../localization/LocalizationManager'
import BaseFollowingsStore from '../BaseFollowings.store'
import * as e from '../EventStore/eventConstants'

export default class DeliveryFollowingsStore
  extends BaseFollowingsStore
  implements IFollowingsStore
{
  protected readonly saveEventName = e.SAVE_DELIVERY_FOLLOWINGS
  protected readonly deleteEventName = e.DELETE_DELIVERY_FOLLOWINGS

  protected get byId(): Map<string, AssociationItem> {
    return this.eventsStore.appState.deliveryFollowings
  }

  protected getSubscribeMessage(
    usersCount: number,
    entitiesCount: number,
  ): string {
    return Localization.translator.usersSubscribedTo.deliveries(
      usersCount,
      entitiesCount,
    )
  }

  protected getUnsubscribeMessage(
    usersCount: number,
    entitiesCount: number,
  ): string {
    return Localization.translator.usersUnSubscribedFrom.deliveries(
      usersCount,
      entitiesCount,
    )
  }
}
