import {
  IDeliveryFilter,
  IDeliveryListCustomFilter,
  IDeliveryListCustomFilterInput,
} from '~/client/graph'

export default class CustomDeliveryListFilter
  implements IDeliveryListCustomFilter
{
  public static fromDto(dto: IDeliveryListCustomFilter) {
    const filtersByFilterType = dto.filtersByFilterType.map(
      ({ type, values }) => ({
        type,
        values: values || [],
      }),
    )

    return new CustomDeliveryListFilter(
      dto.id,
      dto.name,
      dto.isPublic,
      dto.projectId,
      dto.userId,
      dto.defaultForUsers,
      filtersByFilterType,
    )
  }

  public constructor(
    public id: string,
    public name: string,
    public isPublic: boolean,
    public readonly projectId: string,
    public readonly userId: string,
    public readonly defaultForUsers: string[],
    public readonly filtersByFilterType: IDeliveryFilter[] = [],
  ) {}

  public toInput(): IDeliveryListCustomFilterInput {
    return this
  }
}
