import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import IBasicProps from '~/client/src/shared/interfaces/IBasicProps'

const RIGHT_PADDING = 10

export default function SelectWrapper({ children, className }: IBasicProps) {
  return (
    <div className={`row bare-select-wrapper relative ${className || ''}`}>
      {children}
      <Icon
        style={{ right: RIGHT_PADDING }}
        icon={IconNames.CARET_DOWN}
        className="text light absolute unclickable-element"
      />
    </div>
  )
}
