import { action, computed, observable } from 'mobx'

import {
  IWorkflowStep,
  PermitFieldType,
  WorkflowStepType,
} from '~/client/graph'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import PermitInspection from '~/client/src/shared/models/PermitInspection'
import PermitType from '~/client/src/shared/models/PermitType'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'

import PermitFieldsBaseStore, {
  IPermitFormField,
} from './PermitFieldsBase.store'
import PermitWorkflowFieldsStore from './PermitWorkflowFields.store'

export default class PermitInspectionFieldsStore
  extends PermitFieldsBaseStore
  implements IPermitFieldsStore
{
  @observable public isNewInspection: boolean = false
  @observable public inspectionDate: Date = null
  @observable public selectedInspection: PermitInspection = null
  @observable public originalInspection: PermitInspection = null

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly permitWorkflowFieldsStore: PermitWorkflowFieldsStore,
  ) {
    super(eventsStore.appState)
  }

  @computed
  public get editableEntity(): PermitInspection {
    return this.selectedInspection
  }

  @computed
  public get existingEntity() {
    return this.originalInspection
  }

  @computed
  public get emptyInspection(): PermitInspection {
    const { id: userId } = this.eventsStore.appState.user
    const { id: projectId } = this.eventsStore.appState.activeProject

    return new PermitInspection(
      null,
      this.permitWorkflowFieldsStore.editablePermitId,
      projectId,
      userId,
      null,
      [],
      this.inspectionDate?.getTime(),
    )
  }

  protected get canIgnoreValidation(): boolean {
    return false
  }

  @computed
  protected get fieldIdsToIgnore(): string[] {
    return []
  }

  @computed
  public get previousStepsFields(): IPermitFormField[] {
    return this.fields
  }

  @computed
  public get fields(): IPermitFormField[] {
    const { fields } = this.template?.recurringInspectionStep || {}

    return fields?.reduce((fieldsList, typeField) => {
      if (!typeField.isShown) {
        return fieldsList
      }

      fieldsList.push(this.getFormFieldModel(typeField))

      if (typeField.type === PermitFieldType.Table) {
        fieldsList.push(...(this.getTableFieldModels(typeField) || []))
      }

      this.getAvailableConditionalFields(typeField).forEach(f => {
        fieldsList.push(this.getFormFieldModel(f))
        if (f.type === PermitFieldType.Table) {
          fieldsList.push(...(this.getTableFieldModels(f) || []))
        }
      })

      return fieldsList
    }, [] as IPermitFormField[])
  }

  @computed
  protected get workflowSteps(): IWorkflowStep[] {
    if (!this.template) {
      return []
    }
    return this.template.workflowSteps.filter(
      s => s.type === WorkflowStepType.RecurringInspection,
    )
  }

  @action.bound
  public selectInspection(inspection: PermitInspection, date: Date) {
    if (!this.permitWorkflowFieldsStore.editableEntity?.id) {
      return
    }

    this.selectedInspection = inspection?.permitId
      ? inspection.copy()
      : this.emptyInspection

    if (!this.selectedInspection.inspectionDate) {
      this.selectedInspection.inspectionDate = date.getTime()
    }

    if (inspection?.id) {
      this.originalInspection = inspection
    }

    this.isNewInspection = !inspection
    this.inspectionDate = date
  }

  @action.bound
  public setEmptyInspection() {
    this.selectedInspection = this.emptyInspection
  }

  @action.bound
  public deselectInspection() {
    this.isNewInspection = false
    this.inspectionDate = null
    this.selectedInspection = null
    this.originalInspection = null
  }

  @computed
  private get template(): PermitType {
    return this.permitWorkflowFieldsStore.template
  }
}
