import Level from '../models/LocationObjects/Level'
import LocationBase from '../models/LocationObjects/LocationBase'
import { getFloorFromLevel } from './floorFromLevelName'

export const sortAttributes = (a: LocationBase, b: LocationBase) =>
  a.name?.localeCompare(b.name)

export const sortLevels = (a: Level, b: Level) => {
  return getFloorFromLevel(b?.name) - getFloorFromLevel(a?.name)
}
