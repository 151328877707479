import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'

// localization: translated

interface IProps {
  label: string
  isRequired: boolean
  value: string

  isValid?: boolean
  hasShortWidth?: boolean
  isRequiredTextHidden?: boolean
  shouldLineUpLabel?: boolean
}

@observer
export default class StruxhubInputLabel extends React.PureComponent<IProps> {
  public render() {
    const {
      label,
      isRequired,
      hasShortWidth,
      value,
      isValid,
      isRequiredTextHidden,
      shouldLineUpLabel,
    } = this.props
    const { required, optional } = Localization.translator
    const requiredTxt = isRequired ? required : optional
    const requiredMsg = hasShortWidth
      ? `${requiredTxt.substring(0, 3)}.`
      : requiredTxt

    return (
      <div
        className={classList({
          'row nowrap x-between': true,
          px8: !shouldLineUpLabel,
        })}
      >
        <span
          className={classList({
            'text left large line-extra-large bold text-ellipsis': true,
            red: !!value && !isValid,
            light: !isRequired ? isValid : !!value && isValid,
            'blue-highlight': isRequired && !value,
          })}
          title={label}
        >
          {label}
        </span>
        {!isRequiredTextHidden && (
          <span className="text light italic line-16 no-grow ml5">
            {requiredMsg}
          </span>
        )}
      </div>
    )
  }
}
