import { action, computed, observable } from 'mobx'

import {
  IActivityFollowingInput,
  IDeliveryFollowingInput,
} from '~/client/graph'
import AssociationItem from '~/client/src/shared/models/AssociationItem/AssociationItem'
import IFollowingsStore from '~/client/src/shared/models/IFollowingsStore'
import IAssociationDto from '~/client/src/shared/types/IAssociationDto'
import Guard from '~/client/src/shared/utils/Guard'

import User from '../models/User'
import {
  handleErrorChangeAssociation,
  handleSuccessChangeAssociation,
} from '../utils/entityAssociationHelper'
import EventsStore from './EventStore/Events.store'
import EventTypes from './EventStore/eventTypes'

const failedToSubscribe = 'Failed to subscribe'
const failedToUnsubscribe = 'Failed to unsubscribe'

export default abstract class BaseFollowingsStore implements IFollowingsStore {
  @observable public isDataReceived: boolean = false

  protected abstract readonly byId: Map<string, AssociationItem>
  protected abstract readonly saveEventName: EventTypes
  protected abstract readonly deleteEventName: EventTypes

  protected abstract getSubscribeMessage(
    usersCount: number,
    entitiesCount: number,
  ): string

  protected abstract getUnsubscribeMessage(
    usersCount: number,
    entitiesCount: number,
  ): string

  public constructor(protected readonly eventsStore: EventsStore) {
    Guard.requireAll({ eventsStore })
  }

  @computed
  public get list(): AssociationItem[] {
    return Array.from(this.byId.values())
  }

  protected get activeUser(): User {
    const { user } = this.eventsStore.appState
    return user
  }

  @action.bound
  public clearList() {
    this.isDataReceived = false
    this.byId.clear()
  }

  @action.bound
  public receiveList(dtos: IAssociationDto[]) {
    this.clearList()
    this.updateList(dtos)

    this.isDataReceived = true
  }

  @action.bound
  public receiveOne(id: string, dto: IAssociationDto) {
    if (dto) {
      this.byId.set(dto.id, AssociationItem.fromDto(dto))
    } else {
      this.byId.delete(id)
    }
  }

  @action.bound
  public updateList(dtos: IAssociationDto[]) {
    if (!dtos || !dtos.length) {
      return
    }

    dtos.forEach(dto => {
      const item = AssociationItem.fromDto(dto)
      this.byId.set(item.id, item)
    })
  }

  public toggleEntityFollowing = (entityId: string) => {
    const isFollowed = this.isEntityFollowed(entityId)

    if (isFollowed) {
      return this.unfollowEntities([entityId])
    }

    return this.followEntities([entityId])
  }

  public followEntities = (entityIds: string[], userIds?: string[]) => {
    const { id: projectId } = this.eventsStore.appState.activeProject

    if (!userIds?.length) {
      userIds = [this.activeUser.id]
    }

    const followingDtos = this.getFollowingDtos(entityIds, userIds, projectId)

    if (!followingDtos.length) {
      return
    }

    this.eventsStore.dispatch(
      this.saveEventName,
      followingDtos,
      this.successSubscribeCallback.bind(
        null,
        userIds.length,
        entityIds.length,
      ),
      this.errorSubscribeCallback,
    )
  }

  public unfollowEntities = (entityIds: string[], userIds?: string[]) => {
    if (!userIds?.length) {
      userIds = [this.activeUser.id]
    }

    const followingIds: string[] = this.list
      .filter(
        df => userIds.includes(df.userId) && entityIds.includes(df.entityId),
      )
      .map(({ id }) => id)

    if (!followingIds.length) {
      return
    }

    this.eventsStore.dispatch(
      this.deleteEventName,
      followingIds,
      this.successUnsubscribeCallback.bind(
        null,
        userIds.length,
        entityIds.length,
      ),
      this.errorUnsubscribeCallback,
    )
  }

  public getEntityFollowersIds = (entityId: string): string[] => {
    return this.list.filter(f => f.entityId === entityId).map(f => f.userId)
  }

  public isEntityFollowed = (entityId: string, userId?: string): boolean => {
    if (userId) {
      return this.list.some(f => f.entityId === entityId && f.userId === userId)
    }

    return this.list.some(
      f => f.entityId === entityId && this.activeUser.id === f.userId,
    )
  }

  protected successSubscribeCallback = (
    usersCount: number,
    entitiesCount: number,
  ) => {
    return handleSuccessChangeAssociation(
      this.getSubscribeMessage(usersCount, entitiesCount),
    )
  }

  protected successUnsubscribeCallback = (
    usersCount: number,
    entitiesCount: number,
  ) => {
    return handleSuccessChangeAssociation(
      this.getUnsubscribeMessage(usersCount, entitiesCount),
    )
  }

  protected errorSubscribeCallback = () => {
    return handleErrorChangeAssociation(failedToSubscribe)
  }

  protected errorUnsubscribeCallback = () => {
    return handleErrorChangeAssociation(failedToUnsubscribe)
  }

  private getFollowingDtos = (
    entityIds: string[],
    userIds: string[],
    projectId: string,
  ): IDeliveryFollowingInput[] | IActivityFollowingInput[] => {
    return entityIds.reduce(
      (list, entityId) => [
        ...list,
        ...this.createFollowingDtos(entityId, userIds, projectId),
      ],
      [],
    )
  }

  private createFollowingDtos = (
    entityId: string,
    userIds: string[],
    projectId: string,
  ) => {
    if (!userIds?.length) {
      return []
    }

    return userIds.map(userId => {
      return {
        userId,
        projectId,
        entityId,
      }
    })
  }
}
