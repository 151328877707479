import * as React from 'react'

import { observer } from 'mobx-react'

import CompactDeliveriesList from '~/client/src/shared/components/CompactDeliveriesList/CompactDeliveriesList'
import { Content, View } from '~/client/src/shared/components/Layout'

import DeliveriesBySitemapListStore from '../../DeliveriesBySitemapList.store'
import DeliveriesMapStore from '../../DeliveriesMap.store'

import './DeliveriesMapSidebar.scss'

// localization: no display text to translate

interface IProps {
  listStore: DeliveriesBySitemapListStore
  mapStore: DeliveriesMapStore
}

@observer
export default class DeliveriesMapSidebar extends React.Component<IProps> {
  public render() {
    const { mapStore, listStore } = this.props

    const { resetAllFilters, activeDeliveryId, highlightedDeliveriesIds } =
      mapStore

    const {
      scrollToIndex,
      selectDelivery,
      compactListRows,
      groupingKey,
      collapsedCategories,
      toggleCategoryCollapsing,
    } = listStore

    return (
      <View className="deliveries-map-sidebar br-light-cool-grey no-outline-container">
        <Content className="bt-light-cool-grey" scrollable={false}>
          <CompactDeliveriesList
            rows={compactListRows.slice()}
            onCardClick={selectDelivery}
            groupingKey={groupingKey}
            scrollToIndex={scrollToIndex}
            activeDeliveryId={activeDeliveryId}
            onFilterResetClick={resetAllFilters}
            highlightedIds={highlightedDeliveriesIds}
            collapsedCategories={collapsedCategories}
            onCategoryCollapsingToggle={toggleCategoryCollapsing}
          />
        </Content>
      </View>
    )
  }
}
