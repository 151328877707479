import * as React from 'react'

import { inject } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ProjectDateStore from '../stores/ui/ProjectDate.store'

interface IProps {
  date: Date
  projectDateStore?: ProjectDateStore
  isToday?: boolean
  leftOffset?: string
  bottomOffset?: string
  shouldHideBorder?: boolean
  shouldUseBlueColor?: boolean
  shouldUseLine?: boolean
  customDateTimeFormatter?: (date: Date) => string
  className?: string
}

@inject('projectDateStore')
export default class DaySeparator extends React.Component<IProps> {
  public render() {
    const {
      date,
      projectDateStore,
      leftOffset,
      bottomOffset,
      shouldHideBorder,
      shouldUseBlueColor,
      customDateTimeFormatter,
      shouldUseLine,
      className,
    } = this.props

    const dateLabel = customDateTimeFormatter
      ? customDateTimeFormatter(date)
      : projectDateStore.getPronounDateString(date)
    return (
      <div
        className={classList({
          center: !leftOffset,
          'text bt-light-grey': !shouldHideBorder,
          row: shouldUseLine,
          [className]: !!className,
        })}
        style={{
          paddingTop: 18,
          paddingBottom: bottomOffset || 18,
          paddingLeft: leftOffset || 0,
        }}
      >
        {this.renderLine()}
        <span
          className={classList({
            'text large bold': true,
            'px16 no-grow no-white-space-wrap': shouldUseLine,
            'dark-blue': shouldUseBlueColor,
            orange: projectDateStore.isToday(date),
          })}
        >
          {dateLabel}
        </span>
        {this.renderLine()}
      </div>
    )
  }

  private renderLine() {
    return this.props.shouldUseLine && <div className="bt-light-grey" />
  }
}
