import {
  DeleteManyClosureAssignmentsDocument,
  SaveManyClosureAssignmentsDocument,
} from '~/client/graph/operations/generated/ClosureAssignments.generated'

import BaseAssignmentsStore from '../BaseAssignments.store'
import EventStore from '../EventStore/Events.store'
import TagsStore from './Tags.store'

export default class ClosureAssignmentsStore extends BaseAssignmentsStore {
  public constructor(eventsStore: EventStore, tagsStore: TagsStore) {
    super(
      eventsStore,
      tagsStore,
      SaveManyClosureAssignmentsDocument,
      DeleteManyClosureAssignmentsDocument,
    )
  }

  public get byId() {
    return this.eventsStore.appState.closureAssignments
  }
}
