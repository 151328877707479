import { action, computed } from 'mobx'

import { FilterType } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import BaseActivityFilterStore from '~/client/src/desktop/stores/ui/BaseActivityFilter.store'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import { RESET_ALL_FILTERS } from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivityAssignmentsStore from '~/client/src/shared/stores/domain/ActivityAssignments.store'
import ActivityCodeTypesStore from '~/client/src/shared/stores/domain/ActivityCodeTypes.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ResourcesStore from '~/client/src/shared/stores/domain/Resources.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import CompanyFilterStore from '../CompanyFilter/CompanyFilter.store'

// localization: no display text to translate

export default class LocationFilterStore {
  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly desktopActivityListStore: DesktopActivityListStore,
    private readonly activityCodeTypesStore: ActivityCodeTypesStore,
    private readonly activityFiltersStore: ActivityFiltersStore,
    private readonly activityAssignmentsStore: ActivityAssignmentsStore,
    private readonly resourcesStore: ResourcesStore,
    private readonly companiesStore: CompaniesStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly onShowChanged: (
      isShown: boolean,
      filterType: string,
    ) => void,
    private readonly onFilterClickHandler?: () => void,
  ) {}

  private get state() {
    return this.eventsStore.appState
  }

  @action.bound
  public resetAllFilters() {
    this.eventsStore.dispatch(RESET_ALL_FILTERS)
  }

  @computed
  public get filterStoresByTypeMap(): {
    [filterType: string]: BaseActivityFilterStore
  } {
    const map: { [filterType: string]: BaseActivityFilterStore } = {}
    // use activityFiltersSettings to keep the same order
    const settings = this.state.activityFiltersSettings.filterInfoMap
    const { locationsMap } = this.state.filters
    Object.keys(settings).forEach(filterType => {
      if (!locationsMap[filterType]) {
        return
      }

      if (filterType === FilterType.Company) {
        map[filterType] = new CompanyFilterStore(
          this.state,
          this.desktopActivityListStore,
          this.activityFiltersStore,
          this.resourcesStore,
          this.companiesStore,
          this.userProjectsStore,
          this.onShowChanged,
          this.onFilterClickHandler,
        )
      } else {
        map[filterType] = new BaseActivityFilterStore(
          filterType,
          this.state,
          this.desktopActivityListStore,
          this.activityCodeTypesStore,
          this.activityFiltersStore,
          this.activityAssignmentsStore,
          this.companiesStore,
          this.userProjectsStore,
          this.onShowChanged,
          this.onFilterClickHandler,
        )
      }
    })

    return map
  }
}
