import * as React from 'react'

import { classList } from 'react-classlist-helper'

import Spinner from '~/client/src/shared/components/Spinner/Spinner'

interface ILoader {
  hint?: string
  size?: number
  className?: string
  spinnerClassName?: string
}

const DEFAULT_SPINNER_SIZE = 25

export class Loader extends React.Component<ILoader> {
  public static defaultProps = {
    className: 'full-height col x-center y-center',
    spinnerClassName: '',
    size: DEFAULT_SPINNER_SIZE,
    hint: '',
  }

  public render() {
    const { hint, size, className, spinnerClassName } = this.props

    return (
      <div className={className}>
        <Spinner
          size={size}
          className={classList({
            loader: true,
            [spinnerClassName]: !!spinnerClassName,
          })}
        />
        {hint}
      </div>
    )
  }
}
