import { enumToList } from '../utils/converters'

enum PermitChecklistConditionalKey {
  AllYes = 'All Yes (Pass)',
  AllNo = 'All No (N/A, Fail)',
}

export default PermitChecklistConditionalKey

export const permitChecklistCondKeyList = enumToList(
  PermitChecklistConditionalKey,
)
