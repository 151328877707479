import * as React from 'react'

import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ClockComponent from '~/client/src/shared/components/Clock'
import DateTimeFormat from '~/client/src/shared/enums/DateTimeFormat'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import { ISharedProps } from '../BaseStruxhubInput'
import BaseStruxhubSelector from './BaseStruxhubSelector'

import './StruxhubDateFormatSelector.scss'

// localization: translated

interface IProps extends ISharedProps {
  onChange: (value: DateTimeFormat) => void

  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
export default class StruxhubDateFormatSelector extends React.Component<IProps> {
  @observable public isOptionsListOpen: boolean = false

  private wrapperRef: React.RefObject<HTMLDivElement> = React.createRef()

  public componentDidMount(): void {
    document.addEventListener('click', this.handleClickOutside)
  }

  public componentWillUnmount(): void {
    document.removeEventListener('click', this.handleClickOutside)
  }

  public render() {
    const { label, value } = this.props

    return (
      <div className="relative full-width" ref={this.wrapperRef}>
        <BaseStruxhubSelector onClick={this.toggleOptions}>
          {this.dateFormats[value] || label}
          <div
            className={classList({
              'date-format-select-options bg-white hidden absolute full-width ba-palette-brand-lighter brada4':
                true,
              open: this.isOptionsListOpen,
            })}
          >
            {Object.keys(this.dateFormats).map(dateFormat => (
              <div
                className="date-format-select-option pointer py2 pl8 pr24 text extra-large"
                key={dateFormat}
                onClick={this.handleSelect.bind(this, dateFormat)}
              >
                {this.dateFormats[dateFormat]}
              </div>
            ))}
          </div>
        </BaseStruxhubSelector>
      </div>
    )
  }

  @computed
  public get dateFormats(): Record<DateTimeFormat, React.ReactNode> {
    const { usFormat, internationalFormat } = this.props.projectDateStore

    return {
      [DateTimeFormat.US]: (
        <>
          {Localization.translator.us} (
          <ClockComponent format={usFormat.weekdayMonthDayYearAndTime} />)
        </>
      ),
      [DateTimeFormat.International]: (
        <>
          {Localization.translator.international} (
          <ClockComponent
            format={internationalFormat.weekdayMonthDayYearAndTime}
          />
          )
        </>
      ),
    }
  }

  @action.bound
  public toggleOptions = () => {
    this.isOptionsListOpen = !this.isOptionsListOpen
  }

  @action.bound
  public handleClickOutside = e => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
      this.isOptionsListOpen = false
    }
  }

  @action.bound
  public handleSelect = (value: DateTimeFormat) => {
    this.isOptionsListOpen = false

    this.props.onChange(value)
  }
}
