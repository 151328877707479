import * as React from 'react'

import NotificationDetailsViewStore from '~/client/src/desktop/views/NotificationDetails/NotificationDetailsView.store'
import { HeaderBar } from '~/client/src/shared/components/HeaderBar'
import {
  Content,
  Footer,
  Header,
  View,
} from '~/client/src/shared/components/Layout'

interface INotificationDetailsBaseProps {
  store?: NotificationDetailsViewStore
  backClicked?: () => void
}

class NotificationDetailsBase<
  T extends INotificationDetailsBaseProps,
> extends React.Component<T> {
  public render() {
    return (
      <View>
        <Header>{this.renderHeader()}</Header>
        {this.viewChild}
        <Content scrollable={true}>{this.content}</Content>
        <Footer>{this.footer}</Footer>
      </View>
    )
  }

  private renderHeader() {
    const { backClicked } = this.props

    if (!this.title) {
      return
    }

    return (
      <HeaderBar>
        <img
          className="no-grow pr20"
          src="/static/icons/back-arrow.svg"
          alt="BACK"
          onClick={backClicked}
        />
        {this.title}
      </HeaderBar>
    )
  }

  protected get title(): React.ReactElement<any> {
    throw Error('Title getter shold be overwritten')
  }

  protected get content(): React.ReactElement<any> {
    throw Error('Content getter shold be overwritten')
  }

  protected get footer(): React.ReactElement<any> {
    throw Error('Footer getter shold be overwritten')
  }

  protected get viewChild(): React.ReactElement<any> {
    return null
  }
}

export default NotificationDetailsBase
