import * as React from 'react'

import { action, computed, observable } from 'mobx'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import {
  CONFIRM_RESET_PASSWORD,
  REQUEST_RESET_PASSWORD,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import Guard from '~/client/src/shared/utils/Guard'

import EventsStore from '../../stores/EventStore/Events.store'
import isWeakPassword from '../../utils/isWeakPassword'

export enum PasswordResetInputNames {
  EMAIL = 'email',
  PASSWORD = 'password',
  REPASSWORD = 'repassword',
}

export default class PasswordResetViewStore {
  @observable
  public inputs = new Map<string, string>([
    [PasswordResetInputNames.EMAIL, ''],
    [PasswordResetInputNames.PASSWORD, ''],
    [PasswordResetInputNames.REPASSWORD, ''],
  ])
  @observable private errorMessage = ''
  @observable private successMessage = ''

  private resetCode = ''

  public constructor(private readonly eventsStore: EventsStore) {
    Guard.requireAll({ eventsStore })
  }

  @computed
  public get error() {
    return this.errorMessage
  }

  @computed
  public get success() {
    return this.successMessage
  }

  @action.bound
  public init(resetCode: string = '', email: string = '') {
    this.resetCode = resetCode
    this.inputs.set(PasswordResetInputNames.EMAIL, email)
  }

  @action.bound
  public reset() {
    this.resetCode = ''
    this.clearAllInputs()
    this.clearAllMessages()
  }

  @action.bound
  public confirmPasswordReset(e) {
    e.preventDefault()
    this.clearAllMessages()

    const { PASSWORD, REPASSWORD } = PasswordResetInputNames
    const password = this.getValueByInputName(PASSWORD)
    const repassword = this.getValueByInputName(REPASSWORD)

    if (!password) {
      this.setErrorMessage(Localization.translator.passwordErrors.empty)
      return
    }

    if (isWeakPassword(password)) {
      this.setErrorMessage(Localization.translator.passwordErrors.weakPassword)
      return
    }

    if (password !== repassword) {
      this.setErrorMessage(Localization.translator.passwordErrors.doNotMatch)
      return
    }

    this.eventsStore.dispatch(
      CONFIRM_RESET_PASSWORD,
      this.resetCode,
      password,
      this.setErrorMessage,
    )
  }

  @action.bound
  public requestPasswordReset(e) {
    e.preventDefault()
    this.clearAllMessages()

    const { EMAIL } = PasswordResetInputNames
    const email = this.getValueByInputName(EMAIL)

    if (!email) {
      this.setErrorMessage(Localization.translator.emailCannotBeEmpty)
      return
    }

    this.eventsStore.dispatch(
      REQUEST_RESET_PASSWORD,
      email,
      this.setSuccessMessage,
      this.setErrorMessage,
    )
  }

  @action.bound
  public setErrorMessage(errorMessage: string) {
    this.errorMessage = errorMessage
  }

  @action.bound
  public clearErrorMessage() {
    this.errorMessage = ''
  }

  @action.bound
  public setSuccessMessage(successMessage: string) {
    this.successMessage =
      successMessage || Localization.translator.passwordResetLinkIsSentToMailbox
  }

  @action.bound
  public clearSuccessMessage() {
    this.successMessage = ''
  }

  @action.bound
  public clearAllMessages() {
    this.successMessage = ''
    this.errorMessage = ''
  }

  @action.bound
  public clearAllInputs() {
    Array.from(this.inputs.keys()).forEach(inputName => {
      this.inputs.set(inputName, '')
    })
  }

  @action.bound
  public inputValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault()

    const { name, value } = e.target
    this.inputs.set(name, value)
  }

  @action.bound
  public inputValueReset(name: PasswordResetInputNames) {
    this.inputs.set(name, '')
  }

  public getValueByInputName = (name: PasswordResetInputNames): string => {
    return this.inputs.get(name)
  }

  public getResetCode() {
    return this.resetCode
  }
}
