import * as React from 'react'

import { observer } from 'mobx-react'

import { LocationType } from '~/client/graph'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'

import MapViewItemsSetupStore from '../../stores/MapViewItemsSetup.store'
import PropertySelect from './PropertySelect'

interface IProps {
  item: MapViewItemBase
  store: MapViewItemsSetupStore
}

const ICON_SIZE = 16

const {
  Logistics,
  Bathroom,
  Break,
  Dumpster,
  Elevator,
  Entrance,
  HandWash,
  Medical,
  MeetingPoint,
  Parking,
  Smoking,
  Stairs,
  Shaft,
  Temperature,
  Tent,
  Walkway,
  ElectricalRoom,
  Crane,
  Equipment,
  Hoist,
  AerialLift,
  Gradall,
  ForkLift,
  Trailer,
} = MapViewLocationIcon

const ALLOWED_SHAPES_BY_TYPE = {
  [LocationType.LogisticsObject]: [
    Logistics,
    Bathroom,
    Break,
    Dumpster,
    Entrance,
    HandWash,
    Medical,
    MeetingPoint,
    Parking,
    Smoking,
    Temperature,
    Tent,
    Walkway,
    ElectricalRoom,
    Trailer,
  ],
  [LocationType.VerticalObject]: [Elevator, Stairs, Shaft],
  [LocationType.OffloadingEquipment]: [
    Equipment,
    Crane,
    Hoist,
    AerialLift,
    Gradall,
    ForkLift,
  ],
}

const OPTION_BY_SHAPE = {
  [Logistics]: <TagIcon.LogisticsObject size={ICON_SIZE} />,
  [Bathroom]: <TagIcon.Bathroom size={ICON_SIZE} />,
  [Break]: <TagIcon.Break size={ICON_SIZE} />,
  [Dumpster]: <TagIcon.Dumpster size={ICON_SIZE} />,
  [Elevator]: <TagIcon.Elevator size={ICON_SIZE} />,
  [Entrance]: <TagIcon.Entrance size={ICON_SIZE} />,
  [HandWash]: <TagIcon.HandWash size={ICON_SIZE} />,
  [Medical]: <TagIcon.Medical size={ICON_SIZE} />,
  [MeetingPoint]: <TagIcon.MeetingPoint size={ICON_SIZE} />,
  [Parking]: <TagIcon.Parking size={ICON_SIZE} />,
  [Smoking]: <TagIcon.Smoking size={ICON_SIZE} />,
  [Stairs]: <TagIcon.Stairs size={ICON_SIZE} />,
  [Shaft]: <TagIcon.Shaft size={ICON_SIZE} />,
  [Temperature]: <TagIcon.Temperature size={ICON_SIZE} />,
  [Tent]: <TagIcon.Tent size={ICON_SIZE} />,
  [Walkway]: <TagIcon.Walkway size={ICON_SIZE} />,
  [ElectricalRoom]: <TagIcon.ElectricalRoom size={ICON_SIZE} />,
  [Crane]: <TagIcon.Crane size={ICON_SIZE} />,
  [Hoist]: <TagIcon.Hoist size={ICON_SIZE} />,
  [AerialLift]: <TagIcon.AerialLift size={ICON_SIZE} />,
  [Gradall]: <TagIcon.Gradall size={ICON_SIZE} />,
  [ForkLift]: <TagIcon.ForkLift size={ICON_SIZE} />,
  [Trailer]: <TagIcon.Trailer size={ICON_SIZE} />,
  [Equipment]: <TagIcon.Equipment size={ICON_SIZE} />,
}

@observer
export default class IconSelector extends React.Component<IProps> {
  public render() {
    const { iconName, dataObject } = this.props.item
    const allowedIcons = ALLOWED_SHAPES_BY_TYPE[dataObject && dataObject.type]

    if (!allowedIcons) {
      return null
    }
    const options = [
      ...allowedIcons.map(value => ({
        value,
        label: OPTION_BY_SHAPE[value],
      })),
    ]
    return (
      <PropertySelect
        options={options}
        value={iconName}
        className="inline-selector"
        onChange={this.onIconChange}
      />
    )
  }

  private onIconChange = (iconName: MapViewLocationIcon) => {
    const { item, store } = this.props
    store.deselectMapViewItemDrawnPart()
    item.updateIcon(iconName)
    store.addCurrentStateToHistory()
  }
}
