import { observable } from 'mobx'

import {
  IActivityAssignment,
  IAnnouncementAssignment,
  ICastAssignment,
  IDeliveryAssignment,
  IRecipients,
  ISitePermitAssignment,
  RecipientType,
} from '~/client/graph'
import { TagType } from '~/client/src/shared/enums/TagType'
import BaseModel from '~/client/src/shared/models/BaseModel'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

interface AssignmentRecipient extends IRecipients {
  type: RecipientType | TagType | any
}

export type IAssignment =
  | IActivityAssignment
  | IDeliveryAssignment
  | IAnnouncementAssignment
  | ISitePermitAssignment
  | ICastAssignment

export default class Assignment extends BaseModel<IAssignment> {
  public static draftId: string = 'draft-id'

  public static fromDto(dto: any) {
    return new Assignment(
      dto.id,
      dto.entityId,
      dto.projectId,
      dto.recipients,
      dto?.all,
    )
  }

  public static getDraft() {
    return new Assignment(Assignment.draftId)
  }

  @observable public recipients: AssignmentRecipient[] = []
  @observable public all: boolean

  public constructor(
    public id: string,
    public entityId: string = EMPTY_STRING,
    public projectId: string = EMPTY_STRING,
    recipients: IRecipients[] = [],
    all?: boolean,
  ) {
    super(id)
    this.recipients = recipients.map(r => ({
      ids: r.ids,
      type: r.type.toString() as RecipientType,
    }))
    this.all = all
  }

  public get isDraft(): boolean {
    return !this.id || this.id === Assignment.draftId
  }

  public getRecipientsByType = (type: RecipientType | TagType): string[] => {
    const recipientsObj = this.recipients.find(r => r.type === type)
    return recipientsObj ? recipientsObj.ids.slice() : []
  }

  public getDto(entityId?: string): IAssignment {
    return {
      id: this.isDraft ? undefined : this.id,
      entityId: entityId || this.entityId,
      all: this.all,
      projectId: this.projectId,
      recipients: this.recipients.map(r => ({
        ids: r.ids,
        type: r.type.toString() as RecipientType,
      })),
    }
  }
}
