import { action } from 'mobx'

import { IInstructions } from '~/client/graph'
import { InstructionListType } from '~/client/src/shared/models/PermitType'

import BaseInstructionsConfiguratorStore from '../BaseInstructionsConfigurator/BaseInstructionsConfigurator.store'

export default class InstructionsStore extends BaseInstructionsConfiguratorStore<string> {
  public setInstructions({ listType, list }: IInstructions) {
    this.instructions = {
      listType: listType || InstructionListType.Enumerated,
      list: list?.slice() || [],
    }
  }

  public getNewInstructionItem(): string {
    return this.newInstructionText
  }

  @action.bound
  public updateInstructionTextByIndex(text: string, index: number) {
    this.instructions.list[index] = text
  }
}
