import { action, observable } from 'mobx'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import { AndOrOperator } from '~/client/src/shared/models/LogicOperation'
import OperationRule from '~/client/src/shared/models/OperationRule'

import SizeMeasureStore from '../../SizeMeasure.store'

export default class RuleRowStore {
  @observable public isHovering: boolean = false
  @observable public rule: OperationRule = null
  private readonly sizeMeasureStore: SizeMeasureStore

  public constructor(
    private readonly state: DesktopInitialState,
    private readonly onRemoveRule: (rule: OperationRule) => void,
    private readonly onAddAssignmentPlaceholderRow: (
      rule: OperationRule,
    ) => void,
    private readonly onSaveChanges: (
      rule: OperationRule,
      shouldSendRequest?: boolean,
    ) => void,
    measureSize: () => void,
    rule: OperationRule,
  ) {
    this.sizeMeasureStore = new SizeMeasureStore(measureSize)
    this.reinit(rule)
  }

  @action.bound
  public reinit(rule: OperationRule) {
    this.rule = rule
    this.sizeMeasureStore.enableMeasure()
  }

  @action.bound
  public handleMouseEnter() {
    this.isHovering = true
    this.sizeMeasureStore.enableMeasure()
  }

  @action.bound
  public handleMouseLeave() {
    this.isHovering = false
    this.sizeMeasureStore.enableMeasure()
  }

  @action.bound
  public removeRule() {
    this.onRemoveRule(this.rule)
  }

  @action.bound
  public addAssignmentPlaceholderRow() {
    this.onAddAssignmentPlaceholderRow(this.rule)
  }

  @action.bound
  public selectAssigneeSubexpression(
    subexpression: string,
    updatedSubexpression: string,
  ) {
    const { assignmentsAsSubexpressions } = this.rule

    // to support only unique subexpressions
    if (assignmentsAsSubexpressions.includes(updatedSubexpression)) {
      return this.onSaveChanges(this.rule, false)
    }

    if (!subexpression) {
      assignmentsAsSubexpressions.splice(
        assignmentsAsSubexpressions.length - 1,
        1,
        updatedSubexpression,
      )
    } else {
      const subexpressionIndex =
        assignmentsAsSubexpressions.indexOf(subexpression)
      assignmentsAsSubexpressions.splice(
        subexpressionIndex,
        1,
        updatedSubexpression,
      )
    }

    this.rule.updateAssignmentExpressions()
    this.onSaveChanges(this.rule)
    this.sizeMeasureStore.enableMeasure()
  }

  @action.bound
  public removeAssigneeSubexpression(subexpression: string) {
    if (!subexpression) {
      return this.onSaveChanges(this.rule, false)
    }

    const { assignmentsAsSubexpressions } = this.rule

    const removingSubexpressionIndex =
      assignmentsAsSubexpressions.indexOf(subexpression)

    assignmentsAsSubexpressions.splice(removingSubexpressionIndex, 1)

    if (!assignmentsAsSubexpressions.length) {
      assignmentsAsSubexpressions.push(OperationRule.ASSIGNMENT_PLACEHOLDER)
    }

    this.rule.updateAssignmentExpressions()
    this.onSaveChanges(this.rule)
    this.sizeMeasureStore.enableMeasure()
  }

  @action.bound
  public changeExpression(expression: string) {
    if (this.rule.expression !== expression) {
      this.rule.setExpression(expression)
      this.onSaveChanges(this.rule)
    }
    this.sizeMeasureStore.enableMeasure()
  }

  @action.bound
  public clickOnExpressionPlusControl(
    operation: AndOrOperator,
    defaultHandler: (operation: AndOrOperator) => void,
  ) {
    if (operation === AndOrOperator.AND) {
      return defaultHandler(operation)
    }

    this.addAssignmentPlaceholderRow()
    this.sizeMeasureStore.enableMeasure()
  }

  public measureSize() {
    this.sizeMeasureStore.measureSize()
  }
}
