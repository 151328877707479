import * as React from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import './ExpandableContainer.scss'

const more = 'more'
const less = 'less'

interface IProps {
  isExpansionAllowed?: boolean
  className?: string
}

@observer
export default class WorkflowCardExpandableContainer extends React.Component<IProps> {
  @observable private isCollapsed = true
  @observable private isTogglerShown = true
  private contentContainer: HTMLDivElement

  public render() {
    const { children, isExpansionAllowed, className } = this.props

    return (
      <div
        className={classList({
          'pt5 text large pl25 expandable-text relative': true,
          collapsed: this.isCollapsed,
          [className]: !!className,
        })}
        ref={this.setContentContainer}
      >
        {children}
        {this.isTogglerShown && (
          <span
            className={classList({
              'more-less-toggler bg-white text large bold primary-blue': true,
              'is-active': isExpansionAllowed,
            })}
            onClick={this.toggleText}
          >
            {this.isCollapsed ? '...' + more : less}
          </span>
        )}
      </div>
    )
  }

  public componentDidMount() {
    this.setTogglerVisibility()
  }

  public componentDidUpdate() {
    this.setTogglerVisibility()
  }

  private setTogglerVisibility() {
    if (this.contentContainer) {
      const { scrollHeight, offsetHeight } = this.contentContainer
      this.isTogglerShown = !this.isCollapsed || scrollHeight > offsetHeight
    }
  }

  private setContentContainer = (container: HTMLDivElement) => {
    this.contentContainer = container
  }

  private toggleText = (event: React.MouseEvent) => {
    if (!this.props.isExpansionAllowed) {
      return
    }

    event.stopPropagation()
    this.isCollapsed = !this.isCollapsed
  }
}
