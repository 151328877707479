import { enUS, es, fr, uk } from 'date-fns/locale'
import { computed, observable } from 'mobx'

import Config from '~/client/src/shared/Config'
import { enumToList } from '~/client/src/shared/utils/converters'

import Language from './Language'
import EnglishTree from './english/tree'
import FrenchTree from './french/tree'
import KnownTranslatorKeys from './knownTranslatorKeys'
import SpanishTree from './spanish/tree'
import UkrainianTree from './ukrainian/tree'

const defaultLanguage = Language.english

const STORAGE_KEY = 'language'

export default class Localization {
  @observable public static currentLanguage: Language = defaultLanguage

  @computed
  public static get translator() {
    switch (Localization.currentLanguage) {
      case Language.english:
        return EnglishTree

      case Language.ukrainian:
        return UkrainianTree

      case Language.spanish:
        return SpanishTree

      case Language.french:
        return FrenchTree

      default:
        return EnglishTree
    }
  }

  public static get dateLocale() {
    switch (Localization.currentLanguage) {
      case Language.english:
        return enUS

      case Language.ukrainian:
        return uk

      case Language.spanish:
        return es

      case Language.french:
        return fr

      default:
        return enUS
    }
  }

  public static init() {
    const language = localStorage.getItem(STORAGE_KEY) as Language
    Localization.currentLanguage = language || defaultLanguage
  }

  public static setLanguage(language: Language) {
    Localization.currentLanguage = language
    localStorage.setItem(STORAGE_KEY, language)
  }

  public static getLanguageOptions(): Language[] {
    const options = enumToList(Language)

    if (Config.ENVIRONMENT.toLowerCase() === 'development') {
      return options
    }

    // We should not show Ukrainian in the QA, PROD environments for now according to thr Chris directions
    return options.filter(l => l !== Language.ukrainian)
  }

  public static getText(path: KnownTranslatorKeys, ...args: any[]): string {
    if (!path) {
      return
    }

    const subpaths = path.split('.')
    let value: any = Localization.translator
    do {
      value = value[subpaths.shift()]
      if (!value) {
        throw new Error('No such string in language tree: ' + path)
      }
    } while (subpaths.length)

    if (typeof value === 'function') {
      return value(...args)
    }

    return value
  }

  public static get shouldShowLanguageSelector() {
    // TODO: provide logic to hide it from AdminTool for some specific tenant
    return true
  }
}
