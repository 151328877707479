import * as React from 'react'

import { observer } from 'mobx-react'

import { ContentObjectStatus } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import Rfi from '~/client/src/shared/models/Rfi'

import './RfiIcon.scss'

interface IProps {
  rfi: Rfi
}

@observer
export default class RfiIcon extends React.Component<IProps> {
  public render() {
    const { rfi = { status: '' } } = this.props
    const { status } = rfi
    let icon

    switch (status) {
      case ContentObjectStatus.Open:
        icon = <Icons.Rfi />
        break
      case ContentObjectStatus.Closed:
        icon = <Icons.Rfi className="rfi-icon-grey" />
        break
      default:
        icon = <Icons.Rfi className="rfi-icon-grey" />
        break
    }

    return <div>{icon}</div>
  }
}
