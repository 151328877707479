import * as React from 'react'

import { action, computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { RouteComponentProps, withRouter } from 'react-router'

import { ContentObjectStatus } from '~/client/graph'
import CommonThread from '~/client/src/shared/components/CommonThread/CommonThread'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ScheduleComment from '~/client/src/shared/models/ScheduleComment'
import InitialState from '~/client/src/shared/stores/InitialState'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'

import CommonStore from '../../stores/ui/Common.store'
import CloseContentObjectButton from '../ContentObject/CloseContentObjectButton'
import ReplyToContentObjectButton from '../ContentObject/ReplyToContentObjectButton'
import FileInputBase from '../FileInput/FileInput'
import ScheduleCommentIcon from '../ScheduleCommentIcon'

// translated

interface IScheduleCommentEntryProps {
  scheduleComment: ScheduleComment
  messagesStore?: MessagesStore
  hideButtons?: boolean
  state?: InitialState
  common?: CommonStore
  FileInputType: typeof FileInputBase
}

@inject('messagesStore', 'state', 'common')
@observer
class ScheduleCommentThread extends React.Component<
  IScheduleCommentEntryProps & RouteComponentProps<any>
> {
  public renderButtons(scheduleComment: ScheduleComment) {
    const { userActiveProjectSettings } = this.props.state
    return (
      <div
        className={classList({
          'row item-buttons-container': true,
          'inactive-element': userActiveProjectSettings?.isPresentationUser,
        })}
      >
        <ReplyToContentObjectButton
          contentObject={scheduleComment}
          onClick={this.displayView}
        />
        <CloseContentObjectButton contentObject={scheduleComment} />
      </div>
    )
  }

  @computed
  private get messages() {
    return this.props.messagesStore.list.filter(
      m => m.threadId === this.props.scheduleComment.threadId,
    )
  }

  public render() {
    const { scheduleComment, hideButtons, FileInputType } = this.props
    const isScheduleCommentClosed =
      scheduleComment.status === ContentObjectStatus.Closed
    const buttonsElement = hideButtons
      ? null
      : this.renderButtons(scheduleComment)

    return (
      <CommonThread
        showImages={true}
        hideThread={false}
        showFooterLine={true}
        messages={this.messages}
        buttonsElement={buttonsElement}
        threadIcon={<ScheduleCommentIcon scheduleComment={scheduleComment} />}
        threadClass={isScheduleCommentClosed ? '' : ' schedule-comment-thread'}
        caption={Localization.translator.scheduleComment}
        model={scheduleComment}
        customPhotoClick={this.displayView}
        FileInputType={FileInputType}
      />
    )
  }

  @action.bound
  private displayView = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    const { common, scheduleComment } = this.props
    common.displayScheduleCommentView(scheduleComment)
  }
}

export default withRouter(ScheduleCommentThread)
