import * as React from 'react'

import { Button, Classes, Dialog } from '@blueprintjs/core'
import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import './ConfirmDialog.scss'

interface IProps {
  isOpen: boolean
  onCancelClicked: () => void
  onDoneClicked: () => void
  title?: string
  doneTitle?: string
  cancelTitle?: string
  question?: string
  loading?: boolean
  className?: string
  portalClassName?: string
}

@observer
export default class ConfirmDialog extends React.Component<IProps> {
  public render() {
    const {
      isOpen,
      loading,
      title,
      question,
      onDoneClicked,
      onCancelClicked,
      doneTitle,
      cancelTitle,
      children,
      className = '',
      portalClassName,
    } = this.props

    return (
      <Dialog
        isOpen={isOpen}
        portalClassName={portalClassName}
        title={(title || Localization.translator.confirm).toUpperCase()}
        isCloseButtonShown={true}
        canOutsideClickClose={false}
        onClose={onCancelClicked}
        className={`confirm-dialog ${className}`}
      >
        <div className={Classes.DIALOG_BODY}>
          <div className="confirm-dialog-question">{question}</div>
          <div>{children}</div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text={cancelTitle || Localization.translator.cancel}
              className="confirm-dialog-btn-cancel"
              onClick={onCancelClicked}
            />
            <Button
              text={doneTitle || Localization.translator.done}
              className="confirm-dialog-btn"
              onClick={onDoneClicked}
              loading={loading}
            />
          </div>
        </div>
      </Dialog>
    )
  }
}
