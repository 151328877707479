import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IHistoryItemFieldsFragment = Pick<
  Types.IHistoryItem,
  'fileName' | 'scheduleId' | 'materialsUploadId' | 'totalCount' | 'updatedAt'
> & {
  by?: Types.Maybe<
    Pick<Types.IUpdaterInfo, 'id' | 'email' | 'firstName' | 'lastName'>
  >
}

export type IProjectHistoryFieldsFragment = Pick<
  Types.IProjectHistory,
  'id' | 'projectId'
> & {
  lastUpdated: Array<
    Types.Maybe<
      Pick<Types.ILastUpdatedItem, 'pageName'> & {
        updateInfo: {
          schedule?: Types.Maybe<
            Pick<Types.ISchedule, 'activityQuantity' | 'companyQuantity'>
          >
        } & IHistoryItemFieldsFragment
      }
    >
  >
  scheduleHistory?: Types.Maybe<
    Array<
      Types.Maybe<
        {
          schedule?: Types.Maybe<
            Pick<Types.ISchedule, 'activityQuantity' | 'companyQuantity'>
          >
        } & IHistoryItemFieldsFragment
      >
    >
  >
  materialsHistory?: Types.Maybe<Array<Types.Maybe<IHistoryItemFieldsFragment>>>
}

export type IGetProjectHistoryQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetProjectHistoryQuery = {
  projectHistories?: Types.Maybe<{ data: Array<IProjectHistoryFieldsFragment> }>
}

export type ISaveProjectHistoryMutationVariables = Types.Exact<{
  projectHistory: Types.IProjectHistoryInput
}>

export type ISaveProjectHistoryMutation = {
  saveProjectHistory?: Types.Maybe<IProjectHistoryFieldsFragment>
}

export type IListenToProjectHistorySubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToProjectHistorySubscription = {
  projectHistory?: Types.Maybe<
    Pick<Types.IProjectHistoryChangeEvent, 'id'> & {
      item?: Types.Maybe<IProjectHistoryFieldsFragment>
    }
  >
}

export const HistoryItemFieldsFragmentDoc = gql`
  fragment HistoryItemFields on HistoryItem {
    fileName
    scheduleId
    materialsUploadId
    totalCount
    updatedAt
    by {
      id
      email
      firstName
      lastName
    }
  }
`
export const ProjectHistoryFieldsFragmentDoc = gql`
  fragment ProjectHistoryFields on ProjectHistory {
    id
    lastUpdated {
      pageName
      updateInfo {
        ...HistoryItemFields
        schedule {
          activityQuantity
          companyQuantity
        }
      }
    }
    scheduleHistory {
      ...HistoryItemFields
      schedule {
        activityQuantity
        companyQuantity
      }
    }
    materialsHistory {
      ...HistoryItemFields
    }
    projectId
  }
  ${HistoryItemFieldsFragmentDoc}
`
export const GetProjectHistoryDocument = gql`
  query GetProjectHistory($projectId: ObjectId!) {
    projectHistories(projectId: $projectId, limit: 1) {
      data {
        ...ProjectHistoryFields
      }
    }
  }
  ${ProjectHistoryFieldsFragmentDoc}
`

/**
 * __useGetProjectHistoryQuery__
 *
 * To run a query within a React component, call `useGetProjectHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectHistoryQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetProjectHistoryQuery,
    IGetProjectHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetProjectHistoryQuery,
    IGetProjectHistoryQueryVariables
  >(GetProjectHistoryDocument, options)
}
export function useGetProjectHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetProjectHistoryQuery,
    IGetProjectHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetProjectHistoryQuery,
    IGetProjectHistoryQueryVariables
  >(GetProjectHistoryDocument, options)
}
export type GetProjectHistoryQueryHookResult = ReturnType<
  typeof useGetProjectHistoryQuery
>
export type GetProjectHistoryLazyQueryHookResult = ReturnType<
  typeof useGetProjectHistoryLazyQuery
>
export type GetProjectHistoryQueryResult = Apollo.QueryResult<
  IGetProjectHistoryQuery,
  IGetProjectHistoryQueryVariables
>
export const SaveProjectHistoryDocument = gql`
  mutation SaveProjectHistory($projectHistory: ProjectHistoryInput!) {
    saveProjectHistory(projectHistory: $projectHistory) {
      ...ProjectHistoryFields
    }
  }
  ${ProjectHistoryFieldsFragmentDoc}
`
export type ISaveProjectHistoryMutationFn = Apollo.MutationFunction<
  ISaveProjectHistoryMutation,
  ISaveProjectHistoryMutationVariables
>

/**
 * __useSaveProjectHistoryMutation__
 *
 * To run a mutation, you first call `useSaveProjectHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProjectHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProjectHistoryMutation, { data, loading, error }] = useSaveProjectHistoryMutation({
 *   variables: {
 *      projectHistory: // value for 'projectHistory'
 *   },
 * });
 */
export function useSaveProjectHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveProjectHistoryMutation,
    ISaveProjectHistoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveProjectHistoryMutation,
    ISaveProjectHistoryMutationVariables
  >(SaveProjectHistoryDocument, options)
}
export type SaveProjectHistoryMutationHookResult = ReturnType<
  typeof useSaveProjectHistoryMutation
>
export type SaveProjectHistoryMutationResult =
  Apollo.MutationResult<ISaveProjectHistoryMutation>
export type SaveProjectHistoryMutationOptions = Apollo.BaseMutationOptions<
  ISaveProjectHistoryMutation,
  ISaveProjectHistoryMutationVariables
>
export const ListenToProjectHistoryDocument = gql`
  subscription ListenToProjectHistory($projectId: ObjectId!) {
    projectHistory(projectId: $projectId) {
      id
      item {
        ...ProjectHistoryFields
      }
    }
  }
  ${ProjectHistoryFieldsFragmentDoc}
`

/**
 * __useListenToProjectHistorySubscription__
 *
 * To run a query within a React component, call `useListenToProjectHistorySubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToProjectHistorySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToProjectHistorySubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToProjectHistorySubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToProjectHistorySubscription,
    IListenToProjectHistorySubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToProjectHistorySubscription,
    IListenToProjectHistorySubscriptionVariables
  >(ListenToProjectHistoryDocument, options)
}
export type ListenToProjectHistorySubscriptionHookResult = ReturnType<
  typeof useListenToProjectHistorySubscription
>
export type ListenToProjectHistorySubscriptionResult =
  Apollo.SubscriptionResult<IListenToProjectHistorySubscription>
