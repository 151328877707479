import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IMessageFieldsFragment = Pick<
  Types.IMessage,
  | 'activityId'
  | 'photoId'
  | 'id'
  | 'text'
  | 'threadId'
  | 'userId'
  | 'createdAt'
  | 'updatedAt'
  | 'projectId'
> & {
  readBy?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IReadBy, 'isRead' | 'userId'>>>
  >
  attachments?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IAttachment, 'fileName' | 'size' | 'url'>>>
  >
}

export type IMessagesByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IMessagesByProjectIdQuery = {
  messages?: Types.Maybe<{ data: Array<IMessageFieldsFragment> }>
}

export type IListenMessagesByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenMessagesByProjectIdSubscription = {
  message?: Types.Maybe<
    Pick<Types.IMessageChangeEvent, 'id'> & {
      item?: Types.Maybe<IMessageFieldsFragment>
    }
  >
}

export type ISaveMessageMutationVariables = Types.Exact<{
  message: Types.IMessageInput
}>

export type ISaveMessageMutation = {
  saveMessage?: Types.Maybe<IMessageFieldsFragment>
}

export type ISaveManyMessagesMutationVariables = Types.Exact<{
  messages:
    | Array<Types.Maybe<Types.IMessageInput>>
    | Types.Maybe<Types.IMessageInput>
}>

export type ISaveManyMessagesMutation = {
  saveManyMessages?: Types.Maybe<Array<Types.Maybe<Pick<Types.IMessage, 'id'>>>>
}

export const MessageFieldsFragmentDoc = gql`
  fragment MessageFields on Message {
    activityId
    photoId
    id
    readBy {
      isRead
      userId
    }
    text
    threadId
    userId
    createdAt
    updatedAt
    attachments {
      fileName
      size
      url
    }
    projectId
  }
`
export const MessagesByProjectIdDocument = gql`
  query MessagesByProjectId($projectId: ObjectId!) {
    messages(limit: 1000000, projectId: $projectId) {
      data {
        ...MessageFields
      }
    }
  }
  ${MessageFieldsFragmentDoc}
`

/**
 * __useMessagesByProjectIdQuery__
 *
 * To run a query within a React component, call `useMessagesByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useMessagesByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IMessagesByProjectIdQuery,
    IMessagesByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IMessagesByProjectIdQuery,
    IMessagesByProjectIdQueryVariables
  >(MessagesByProjectIdDocument, options)
}
export function useMessagesByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IMessagesByProjectIdQuery,
    IMessagesByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IMessagesByProjectIdQuery,
    IMessagesByProjectIdQueryVariables
  >(MessagesByProjectIdDocument, options)
}
export type MessagesByProjectIdQueryHookResult = ReturnType<
  typeof useMessagesByProjectIdQuery
>
export type MessagesByProjectIdLazyQueryHookResult = ReturnType<
  typeof useMessagesByProjectIdLazyQuery
>
export type MessagesByProjectIdQueryResult = Apollo.QueryResult<
  IMessagesByProjectIdQuery,
  IMessagesByProjectIdQueryVariables
>
export const ListenMessagesByProjectIdDocument = gql`
  subscription ListenMessagesByProjectId($projectId: ObjectId!) {
    message(projectId: $projectId) {
      id
      item {
        ...MessageFields
      }
    }
  }
  ${MessageFieldsFragmentDoc}
`

/**
 * __useListenMessagesByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenMessagesByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenMessagesByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenMessagesByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenMessagesByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenMessagesByProjectIdSubscription,
    IListenMessagesByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenMessagesByProjectIdSubscription,
    IListenMessagesByProjectIdSubscriptionVariables
  >(ListenMessagesByProjectIdDocument, options)
}
export type ListenMessagesByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenMessagesByProjectIdSubscription
>
export type ListenMessagesByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenMessagesByProjectIdSubscription>
export const SaveMessageDocument = gql`
  mutation SaveMessage($message: MessageInput!) {
    saveMessage(message: $message) {
      ...MessageFields
    }
  }
  ${MessageFieldsFragmentDoc}
`
export type ISaveMessageMutationFn = Apollo.MutationFunction<
  ISaveMessageMutation,
  ISaveMessageMutationVariables
>

/**
 * __useSaveMessageMutation__
 *
 * To run a mutation, you first call `useSaveMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMessageMutation, { data, loading, error }] = useSaveMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSaveMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveMessageMutation,
    ISaveMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveMessageMutation,
    ISaveMessageMutationVariables
  >(SaveMessageDocument, options)
}
export type SaveMessageMutationHookResult = ReturnType<
  typeof useSaveMessageMutation
>
export type SaveMessageMutationResult =
  Apollo.MutationResult<ISaveMessageMutation>
export type SaveMessageMutationOptions = Apollo.BaseMutationOptions<
  ISaveMessageMutation,
  ISaveMessageMutationVariables
>
export const SaveManyMessagesDocument = gql`
  mutation SaveManyMessages($messages: [MessageInput]!) {
    saveManyMessages(messages: $messages) {
      id
    }
  }
`
export type ISaveManyMessagesMutationFn = Apollo.MutationFunction<
  ISaveManyMessagesMutation,
  ISaveManyMessagesMutationVariables
>

/**
 * __useSaveManyMessagesMutation__
 *
 * To run a mutation, you first call `useSaveManyMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyMessagesMutation, { data, loading, error }] = useSaveManyMessagesMutation({
 *   variables: {
 *      messages: // value for 'messages'
 *   },
 * });
 */
export function useSaveManyMessagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyMessagesMutation,
    ISaveManyMessagesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyMessagesMutation,
    ISaveManyMessagesMutationVariables
  >(SaveManyMessagesDocument, options)
}
export type SaveManyMessagesMutationHookResult = ReturnType<
  typeof useSaveManyMessagesMutation
>
export type SaveManyMessagesMutationResult =
  Apollo.MutationResult<ISaveManyMessagesMutation>
export type SaveManyMessagesMutationOptions = Apollo.BaseMutationOptions<
  ISaveManyMessagesMutation,
  ISaveManyMessagesMutationVariables
>
