import * as React from 'react'

import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'

import DesktopCalendarColumn, {
  DesktopCalendarColumnType,
} from '../DesktopCalendarColumn'
import AttributeTitle from './AttributeTitle'
import DateTitle from './DateTitle'

// localization: translated

interface IProps {
  calendarColumn: DesktopCalendarColumn
}

@observer
export default class ColumnTitle extends React.Component<IProps> {
  public render() {
    const { type, title } = this.props.calendarColumn
    let columnTitle: JSX.Element
    switch (type) {
      case DesktopCalendarColumnType.Attribute:
        const attribute = title as LocationAttributeBase
        columnTitle = <AttributeTitle attribute={attribute} />
        break
      case DesktopCalendarColumnType.Date:
        const date = title as Date
        columnTitle = <DateTitle date={date} />
        break
      case DesktopCalendarColumnType.Unassigned:
        columnTitle = (
          <div className="text extra-large uppercase center">
            {Localization.translator.unassigned}
          </div>
        )
        break
    }

    return (
      <div className="x-axis-column-width col full-height y-end">
        {columnTitle}
      </div>
    )
  }
}
