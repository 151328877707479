import { IActivityLocation, LocationType } from '~/client/graph'

export default class ActivityCodeLocationRelationship {
  public static fromDto(dto: IActivityLocation) {
    return new ActivityCodeLocationRelationship(
      dto.id,
      dto.activityCodeId,
      dto.locationObjectId,
      dto.locationObjectType,
      dto.projectId,
    )
  }

  public constructor(
    public readonly id: string,
    public readonly activityCodeId: string,
    public locationObjectId: string,
    public locationObjectType: LocationType,
    public readonly projectId: string,
  ) {}
}
