import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IDeliveryAssignmentFieldsFragment = Pick<
  Types.IDeliveryAssignment,
  'id' | 'projectId' | 'entityId' | 'createdAt' | 'updatedAt'
> & { recipients: Array<Types.Maybe<Pick<Types.IRecipients, 'ids' | 'type'>>> }

export type IGetDeliveryAssignmentListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetDeliveryAssignmentListQuery = {
  deliveryAssignments?: Types.Maybe<{
    data: Array<IDeliveryAssignmentFieldsFragment>
  }>
}

export type ISaveManyDeliveryAssignmentsMutationVariables = Types.Exact<{
  assignments:
    | Array<Types.Maybe<Types.IDeliveryAssignmentInput>>
    | Types.Maybe<Types.IDeliveryAssignmentInput>
}>

export type ISaveManyDeliveryAssignmentsMutation = {
  saveManyDeliveryAssignments?: Types.Maybe<
    Array<Types.Maybe<IDeliveryAssignmentFieldsFragment>>
  >
}

export type IDeleteManyDeliveryAssignmentsMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteManyDeliveryAssignmentsMutation = Pick<
  Types.IMutation,
  'deleteManyDeliveryAssignments'
>

export type IListenToDeliveryAssignmentsSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToDeliveryAssignmentsSubscription = {
  deliveryAssignment?: Types.Maybe<
    Pick<Types.IDeliveryAssignmentChangeEvent, 'id'> & {
      item?: Types.Maybe<IDeliveryAssignmentFieldsFragment>
    }
  >
}

export const DeliveryAssignmentFieldsFragmentDoc = gql`
  fragment DeliveryAssignmentFields on DeliveryAssignment {
    id
    projectId
    entityId
    recipients {
      ids
      type
    }
    createdAt
    updatedAt
  }
`
export const GetDeliveryAssignmentListDocument = gql`
  query GetDeliveryAssignmentList($projectId: ObjectId!) {
    deliveryAssignments(limit: 1000000, projectId: $projectId) {
      data {
        ...DeliveryAssignmentFields
      }
    }
  }
  ${DeliveryAssignmentFieldsFragmentDoc}
`

/**
 * __useGetDeliveryAssignmentListQuery__
 *
 * To run a query within a React component, call `useGetDeliveryAssignmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryAssignmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryAssignmentListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetDeliveryAssignmentListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetDeliveryAssignmentListQuery,
    IGetDeliveryAssignmentListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetDeliveryAssignmentListQuery,
    IGetDeliveryAssignmentListQueryVariables
  >(GetDeliveryAssignmentListDocument, options)
}
export function useGetDeliveryAssignmentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetDeliveryAssignmentListQuery,
    IGetDeliveryAssignmentListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetDeliveryAssignmentListQuery,
    IGetDeliveryAssignmentListQueryVariables
  >(GetDeliveryAssignmentListDocument, options)
}
export type GetDeliveryAssignmentListQueryHookResult = ReturnType<
  typeof useGetDeliveryAssignmentListQuery
>
export type GetDeliveryAssignmentListLazyQueryHookResult = ReturnType<
  typeof useGetDeliveryAssignmentListLazyQuery
>
export type GetDeliveryAssignmentListQueryResult = Apollo.QueryResult<
  IGetDeliveryAssignmentListQuery,
  IGetDeliveryAssignmentListQueryVariables
>
export const SaveManyDeliveryAssignmentsDocument = gql`
  mutation SaveManyDeliveryAssignments(
    $assignments: [DeliveryAssignmentInput]!
  ) {
    saveManyDeliveryAssignments(deliveryAssignments: $assignments) {
      ...DeliveryAssignmentFields
    }
  }
  ${DeliveryAssignmentFieldsFragmentDoc}
`
export type ISaveManyDeliveryAssignmentsMutationFn = Apollo.MutationFunction<
  ISaveManyDeliveryAssignmentsMutation,
  ISaveManyDeliveryAssignmentsMutationVariables
>

/**
 * __useSaveManyDeliveryAssignmentsMutation__
 *
 * To run a mutation, you first call `useSaveManyDeliveryAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyDeliveryAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyDeliveryAssignmentsMutation, { data, loading, error }] = useSaveManyDeliveryAssignmentsMutation({
 *   variables: {
 *      assignments: // value for 'assignments'
 *   },
 * });
 */
export function useSaveManyDeliveryAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyDeliveryAssignmentsMutation,
    ISaveManyDeliveryAssignmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyDeliveryAssignmentsMutation,
    ISaveManyDeliveryAssignmentsMutationVariables
  >(SaveManyDeliveryAssignmentsDocument, options)
}
export type SaveManyDeliveryAssignmentsMutationHookResult = ReturnType<
  typeof useSaveManyDeliveryAssignmentsMutation
>
export type SaveManyDeliveryAssignmentsMutationResult =
  Apollo.MutationResult<ISaveManyDeliveryAssignmentsMutation>
export type SaveManyDeliveryAssignmentsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveManyDeliveryAssignmentsMutation,
    ISaveManyDeliveryAssignmentsMutationVariables
  >
export const DeleteManyDeliveryAssignmentsDocument = gql`
  mutation DeleteManyDeliveryAssignments($ids: [ObjectId]!) {
    deleteManyDeliveryAssignments(ids: $ids)
  }
`
export type IDeleteManyDeliveryAssignmentsMutationFn = Apollo.MutationFunction<
  IDeleteManyDeliveryAssignmentsMutation,
  IDeleteManyDeliveryAssignmentsMutationVariables
>

/**
 * __useDeleteManyDeliveryAssignmentsMutation__
 *
 * To run a mutation, you first call `useDeleteManyDeliveryAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyDeliveryAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyDeliveryAssignmentsMutation, { data, loading, error }] = useDeleteManyDeliveryAssignmentsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteManyDeliveryAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteManyDeliveryAssignmentsMutation,
    IDeleteManyDeliveryAssignmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteManyDeliveryAssignmentsMutation,
    IDeleteManyDeliveryAssignmentsMutationVariables
  >(DeleteManyDeliveryAssignmentsDocument, options)
}
export type DeleteManyDeliveryAssignmentsMutationHookResult = ReturnType<
  typeof useDeleteManyDeliveryAssignmentsMutation
>
export type DeleteManyDeliveryAssignmentsMutationResult =
  Apollo.MutationResult<IDeleteManyDeliveryAssignmentsMutation>
export type DeleteManyDeliveryAssignmentsMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteManyDeliveryAssignmentsMutation,
    IDeleteManyDeliveryAssignmentsMutationVariables
  >
export const ListenToDeliveryAssignmentsDocument = gql`
  subscription ListenToDeliveryAssignments($projectId: ObjectId!) {
    deliveryAssignment(projectId: $projectId) {
      id
      item {
        ...DeliveryAssignmentFields
      }
    }
  }
  ${DeliveryAssignmentFieldsFragmentDoc}
`

/**
 * __useListenToDeliveryAssignmentsSubscription__
 *
 * To run a query within a React component, call `useListenToDeliveryAssignmentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToDeliveryAssignmentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToDeliveryAssignmentsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToDeliveryAssignmentsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToDeliveryAssignmentsSubscription,
    IListenToDeliveryAssignmentsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToDeliveryAssignmentsSubscription,
    IListenToDeliveryAssignmentsSubscriptionVariables
  >(ListenToDeliveryAssignmentsDocument, options)
}
export type ListenToDeliveryAssignmentsSubscriptionHookResult = ReturnType<
  typeof useListenToDeliveryAssignmentsSubscription
>
export type ListenToDeliveryAssignmentsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToDeliveryAssignmentsSubscription>
