import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IPhotoFieldsFragment = Pick<
  Types.IPhoto,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'projectId'
  | 'activityObjectId'
  | 'author'
  | 'messageId'
  | 'thumbUrl'
  | 'url'
>

export type IPhotosByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IPhotosByProjectIdQuery = {
  photos?: Types.Maybe<{ data: Array<IPhotoFieldsFragment> }>
}

export type IListenPhotosByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenPhotosByProjectIdSubscription = {
  photo?: Types.Maybe<
    Pick<Types.IPhotoChangeEvent, 'id'> & {
      item?: Types.Maybe<IPhotoFieldsFragment>
    }
  >
}

export type ISavePhotoMutationVariables = Types.Exact<{
  photo: Types.IPhotoInput
}>

export type ISavePhotoMutation = {
  savePhoto?: Types.Maybe<Pick<Types.IPhoto, 'id'>>
}

export const PhotoFieldsFragmentDoc = gql`
  fragment PhotoFields on Photo {
    id
    createdAt
    updatedAt
    projectId
    activityObjectId
    author
    messageId
    thumbUrl
    url
  }
`
export const PhotosByProjectIdDocument = gql`
  query PhotosByProjectId($projectId: ObjectId!) {
    photos(limit: 1000000, projectId: $projectId) {
      data {
        ...PhotoFields
      }
    }
  }
  ${PhotoFieldsFragmentDoc}
`

/**
 * __usePhotosByProjectIdQuery__
 *
 * To run a query within a React component, call `usePhotosByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhotosByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhotosByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function usePhotosByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IPhotosByProjectIdQuery,
    IPhotosByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IPhotosByProjectIdQuery,
    IPhotosByProjectIdQueryVariables
  >(PhotosByProjectIdDocument, options)
}
export function usePhotosByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IPhotosByProjectIdQuery,
    IPhotosByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IPhotosByProjectIdQuery,
    IPhotosByProjectIdQueryVariables
  >(PhotosByProjectIdDocument, options)
}
export type PhotosByProjectIdQueryHookResult = ReturnType<
  typeof usePhotosByProjectIdQuery
>
export type PhotosByProjectIdLazyQueryHookResult = ReturnType<
  typeof usePhotosByProjectIdLazyQuery
>
export type PhotosByProjectIdQueryResult = Apollo.QueryResult<
  IPhotosByProjectIdQuery,
  IPhotosByProjectIdQueryVariables
>
export const ListenPhotosByProjectIdDocument = gql`
  subscription ListenPhotosByProjectId($projectId: ObjectId!) {
    photo(projectId: $projectId) {
      id
      item {
        ...PhotoFields
      }
    }
  }
  ${PhotoFieldsFragmentDoc}
`

/**
 * __useListenPhotosByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenPhotosByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenPhotosByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenPhotosByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenPhotosByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenPhotosByProjectIdSubscription,
    IListenPhotosByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenPhotosByProjectIdSubscription,
    IListenPhotosByProjectIdSubscriptionVariables
  >(ListenPhotosByProjectIdDocument, options)
}
export type ListenPhotosByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenPhotosByProjectIdSubscription
>
export type ListenPhotosByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenPhotosByProjectIdSubscription>
export const SavePhotoDocument = gql`
  mutation SavePhoto($photo: PhotoInput!) {
    savePhoto(photo: $photo) {
      id
    }
  }
`
export type ISavePhotoMutationFn = Apollo.MutationFunction<
  ISavePhotoMutation,
  ISavePhotoMutationVariables
>

/**
 * __useSavePhotoMutation__
 *
 * To run a mutation, you first call `useSavePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePhotoMutation, { data, loading, error }] = useSavePhotoMutation({
 *   variables: {
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useSavePhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISavePhotoMutation,
    ISavePhotoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISavePhotoMutation, ISavePhotoMutationVariables>(
    SavePhotoDocument,
    options,
  )
}
export type SavePhotoMutationHookResult = ReturnType<
  typeof useSavePhotoMutation
>
export type SavePhotoMutationResult = Apollo.MutationResult<ISavePhotoMutation>
export type SavePhotoMutationOptions = Apollo.BaseMutationOptions<
  ISavePhotoMutation,
  ISavePhotoMutationVariables
>
