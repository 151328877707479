import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IPermitTypeFieldsFragmentFragment = Pick<
  Types.IPermitTypeField,
  | 'id'
  | 'parentId'
  | 'type'
  | 'caption'
  | 'isMandatory'
  | 'isShown'
  | 'isMultiple'
  | 'isRouting'
  | 'canEditIsShown'
  | 'canEditIsMandatory'
  | 'selectOptions'
> & {
  restrictedOptions?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IRestrictedOption, 'id' | 'type'>>>
  >
  instructions?: Types.Maybe<Pick<Types.IInstructions, 'list' | 'listType'>>
  checklist?: Types.Maybe<
    Pick<Types.IPermitTypeChecklist, 'listType'> & {
      list?: Types.Maybe<
        Array<
          Types.Maybe<
            Pick<Types.IChecklistItem, 'id' | 'text' | 'questionnaireType'>
          >
        >
      >
    }
  >
}

export type IGetDefaultPermitTypesQueryVariables = Types.Exact<{
  limit?: Types.Maybe<Types.Scalars['Int']>
}>

export type IGetDefaultPermitTypesQuery = {
  defaultPermitTypes?: Types.Maybe<{
    data: Array<
      Pick<
        Types.IDefaultPermitType,
        | 'id'
        | 'orderIndex'
        | 'name'
        | 'isEnabled'
        | 'type'
        | 'isAutoActivationEnabled'
        | 'createdAt'
        | 'updatedAt'
      > & {
        inspectionOptions?: Types.Maybe<
          Pick<
            Types.IInspectionOptions,
            | 'deadlineTime'
            | 'inspectionFrequency'
            | 'inspectionFrequencyType'
            | 'selectedDaysToRepeat'
          >
        >
        workflowSteps?: Types.Maybe<
          Array<
            Types.Maybe<
              Pick<
                Types.IWorkflowStep,
                'id' | 'type' | 'workflowRuleIds' | 'notificationFieldIds'
              > & {
                fields: Array<Types.Maybe<IPermitTypeFieldsFragmentFragment>>
                conditionalFields: Array<
                  Types.Maybe<
                    Pick<Types.IConditionalField, 'fieldId' | 'key'> & {
                      values: Array<
                        Types.Maybe<IPermitTypeFieldsFragmentFragment>
                      >
                    }
                  >
                >
              }
            >
          >
        >
      }
    >
  }>
}

export const PermitTypeFieldsFragmentFragmentDoc = gql`
  fragment PermitTypeFieldsFragment on PermitTypeField {
    id
    parentId
    type
    caption
    isMandatory
    isShown
    isMultiple
    isRouting
    canEditIsShown
    canEditIsMandatory
    restrictedOptions {
      id
      type
    }
    selectOptions
    instructions {
      list
      listType
    }
    checklist {
      list {
        id
        text
        questionnaireType
      }
      listType
    }
  }
`
export const GetDefaultPermitTypesDocument = gql`
  query GetDefaultPermitTypes($limit: Int) {
    defaultPermitTypes(limit: $limit) {
      data {
        id
        orderIndex
        name
        isEnabled
        inspectionOptions {
          deadlineTime
          inspectionFrequency
          inspectionFrequencyType
          selectedDaysToRepeat
        }
        type
        isAutoActivationEnabled
        workflowSteps {
          id
          type
          fields {
            ...PermitTypeFieldsFragment
          }
          conditionalFields {
            fieldId
            key
            values {
              ...PermitTypeFieldsFragment
            }
          }
          workflowRuleIds
          notificationFieldIds
        }
        createdAt
        updatedAt
      }
    }
  }
  ${PermitTypeFieldsFragmentFragmentDoc}
`

/**
 * __useGetDefaultPermitTypesQuery__
 *
 * To run a query within a React component, call `useGetDefaultPermitTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultPermitTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultPermitTypesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDefaultPermitTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IGetDefaultPermitTypesQuery,
    IGetDefaultPermitTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetDefaultPermitTypesQuery,
    IGetDefaultPermitTypesQueryVariables
  >(GetDefaultPermitTypesDocument, options)
}
export function useGetDefaultPermitTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetDefaultPermitTypesQuery,
    IGetDefaultPermitTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetDefaultPermitTypesQuery,
    IGetDefaultPermitTypesQueryVariables
  >(GetDefaultPermitTypesDocument, options)
}
export type GetDefaultPermitTypesQueryHookResult = ReturnType<
  typeof useGetDefaultPermitTypesQuery
>
export type GetDefaultPermitTypesLazyQueryHookResult = ReturnType<
  typeof useGetDefaultPermitTypesLazyQuery
>
export type GetDefaultPermitTypesQueryResult = Apollo.QueryResult<
  IGetDefaultPermitTypesQuery,
  IGetDefaultPermitTypesQueryVariables
>
