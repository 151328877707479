import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Linkifier from '~/client/src/shared/components/Linkifier/Linkifier'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import BaseStruxhubInput, { ISharedProps } from './BaseStruxhubInput'

// localization: no text to translate

interface IProps extends ISharedProps {
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void

  id?: string
  placeholder?: string
  defaultValue?: string
  isLinkified?: boolean
  maxLength?: number
  autoFocus?: boolean
  onFocus?: () => void
  onBlur?: () => void
  onKeyPress?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void
  rows?: number
}

const BASE_ROW_HEIGHT = 25

@observer
export default class StruxhubTextArea extends React.Component<IProps> {
  @observable private isEditMode: boolean = false
  private textAreaElement: HTMLTextAreaElement

  public componentDidMount() {
    this.autoResizeTextArea()
  }

  public componentDidUpdate() {
    this.autoResizeTextArea()
  }

  public render() {
    return (
      <BaseStruxhubInput {...this.props}>
        {(isValueInvalid, isInFocus, onFocus, onBlur) => {
          if (!this.props.isLinkified) {
            return this.renderTextArea(
              isValueInvalid,
              isInFocus,
              onFocus,
              onBlur,
            )
          }

          return (
            <MenuCloser
              closeMenu={this.disableEditMode}
              isOpen={this.isEditMode}
            >
              <div onClick={this.enableEditMode}>
                {this.renderValue(isValueInvalid, isInFocus, onFocus, onBlur)}
              </div>
            </MenuCloser>
          )
        }}
      </BaseStruxhubInput>
    )
  }

  private renderValue(
    isValueInvalid: boolean,
    isInFocus: boolean,
    onFocus: () => void,
    onBlur: () => void,
  ): JSX.Element {
    const { value } = this.props

    if (this.isEditMode) {
      return this.renderTextArea(isValueInvalid, isInFocus, onFocus, onBlur)
    }

    return (
      <div
        className={classList({
          'row y-start bg-palette-brand-lightest no-outline brada4 px8 py7 min-height66 full-width overflow-hidden text line-24 extra-large word-break-all':
            true,
          'ba-palette-brand-lighter': !isValueInvalid,
          'ba-red': isValueInvalid,
        })}
      >
        <Linkifier value={value} />
      </div>
    )
  }

  private renderTextArea(
    isValueInvalid: boolean,
    isInFocus: boolean,
    onFocus: () => void,
    onBlur: () => void,
  ): JSX.Element {
    const {
      id,
      value,
      defaultValue,
      placeholder,
      isRequired,
      isDisabled,
      isReadOnly,
      isLinkified,
      maxLength,
      onChange,
      autoFocus,
      onKeyPress,
      rows,
    } = this.props

    return (
      <textarea
        id={id}
        className={classList({
          'row bg-palette-brand-lightest no-outline brada4 px8 py7 min-height66 no-resize full-width text line-24 extra-large word-break-all':
            true,
          'overflow-hidden': !rows,
          'overflow-y-scroll': !!rows,
          'ba-light-blue': isInFocus && !isValueInvalid,
          'ba-palette-brand-lighter': !isInFocus && !isValueInvalid,
          'ba-red': isValueInvalid,
        })}
        ref={this.setRef}
        autoFocus={isLinkified || autoFocus}
        onFocus={this.onFocus.bind(this, onFocus)}
        onBlur={this.onBlur.bind(this, onBlur)}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        maxLength={maxLength}
        required={isRequired}
        onChange={onChange}
        disabled={isDisabled}
        readOnly={isReadOnly}
        rows={rows}
      />
    )
  }

  @action.bound
  private enableEditMode() {
    const { isDisabled, isReadOnly } = this.props

    if (!isDisabled && !isReadOnly) {
      this.isEditMode = true
    }
  }

  @action.bound
  private disableEditMode() {
    this.isEditMode = false
  }

  private onFocus(
    onFocus: () => void,
    e: React.FocusEvent<HTMLTextAreaElement>,
  ) {
    const currentValue = e.target.value
    e.target.value = EMPTY_STRING
    e.target.value = currentValue

    onFocus?.()
    Promise.resolve().then(() => this.autoResizeTextArea())
    this.props.onFocus?.()
  }

  private onBlur(onBlur: () => void) {
    onBlur?.()
    this.props.onBlur?.()
  }

  private setRef = (ref: HTMLTextAreaElement) => {
    this.textAreaElement = ref
  }

  private autoResizeTextArea = () => {
    const { rows } = this.props
    if (!this.textAreaElement) {
      return
    }

    const scrollHeight = this.textAreaElement.scrollHeight + 2
    this.textAreaElement.style.height = 'auto'
    this.textAreaElement.style.height = `${
      rows ? Math.min(rows * BASE_ROW_HEIGHT, scrollHeight) : scrollHeight
    }px`
  }
}
