import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import Activity from '~/client/src/shared/models/Activity'
import { IActivitiesTreeNode } from '~/client/src/shared/models/IActivitiesTreeNode'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import HierarchyConfigurationStore from '~/client/src/shared/stores/domain/HierarchyConfiguration.store'
import LocationsStore from '~/client/src/shared/stores/domain/Locations.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import ActivityListPreviewStore from '../../ProjectSetUp/components/TrackingHierarchy/components/ActivitiesTreePreview/ActivityListPreviewStore'
import LogisticsStore from '../Logistics.store'

export default class LogisticsSideBarActivities extends ActivityListPreviewStore {
  public constructor(
    protected activitiesStore: ActivitiesStore,
    protected eventsStore: DesktopEventStore,
    protected activityFiltersStore: ActivityFiltersStore,
    protected hierarchyConfigurationStore: HierarchyConfigurationStore,
    protected locationStore: LocationsStore,
    protected projectDateStore: ProjectDateStore,
    protected companiesStore: CompaniesStore,
    private logisticsStore: LogisticsStore,
  ) {
    super(
      activitiesStore,
      eventsStore,
      activityFiltersStore,
      hierarchyConfigurationStore,
      locationStore,
      projectDateStore,
      companiesStore,
    )
  }

  public getActivitiesTreeForDate = (
    activities: Activity[],
  ): IActivitiesTreeNode[] => {
    if (!activities.length) {
      return []
    }

    const activitiesTreeNodes = this.getTreeNodesPreview(
      [],
      activities,
      [],
      false,
    )

    return activitiesTreeNodes.map(an => {
      an.onClick = this.logisticsStore.displayActivitySideView.bind(
        null,
        an.entity?.code,
      )
      return an
    })
  }
}
