import { observable } from 'mobx'

import CDConfigurationFormFieldId from '~/client/src/shared/enums/ConcreteDirectConfigurationFormFieldId'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import MaturixProjectsStore from '~/client/src/shared/stores/domain/MaturixStores/MaturixProjects.store'

export default class IntegrationsSetUpStore {
  protected requiredConcreteDirectFieldIds = [
    CDConfigurationFormFieldId.CDBaseURL,
  ]
  @observable public maturixToken: string = ''

  public constructor(
    private readonly maturixProjectsStore: MaturixProjectsStore,
  ) {}

  public onProjectUpdate = (eventContext: EventContext) => {
    const [eventType] = eventContext.event

    if (eventType === e.GET_MATURIX_CONFIGURATION_SUCCESS) {
      this.setIntegrationDataValues()
    }
  }

  public setIntegrationDataValues() {
    this.maturixToken = this.maturixProjectsStore.maturixToken ?? ''
  }
}
