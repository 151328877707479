import * as React from 'react'

import htmlReactParser, {
  DomElement,
  HTMLReactParserOptions,
} from 'html-react-parser'
import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Announcement from '../../../models/Announcement'
import EventsStore from '../../../stores/EventStore/Events.store'
import { SHOW_FULLSCREEN_PREVIEW } from '../../../stores/EventStore/eventConstants'
import VideoPlayer from '../../VideoPlayer/VideoPlayer'
import WorkflowCardExpandableContainer from '../../WorkflowCard/ExpandableContainer'
import {
  IMAGE_GALLERY_BLOT_NAME,
  VIDEO_BLOT_NAME,
} from './AnnouncementTextEditor'

interface IProps {
  announcement: Announcement
  className?: string
  isContentExpanded?: boolean

  onMount?: () => void
  eventsStore?: EventsStore
}

@inject('eventsStore')
@observer
export default class AnnouncementParsedContent extends React.Component<IProps> {
  public componentDidMount(): void {
    this.props.onMount?.()
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.announcement.id !== this.props.announcement.id) {
      this.props.onMount?.()
    }
  }
  public render() {
    const { isContentExpanded, className } = this.props
    if (isContentExpanded) {
      return (
        <div
          className={classList({
            'content-section px10': true,
            [className]: !!className,
          })}
          onClick={this.handleContentClick}
        >
          {this.announcementContent}
        </div>
      )
    }

    return (
      <WorkflowCardExpandableContainer
        className={classList({
          'content-section': true,
          [className]: !!className,
        })}
      >
        {this.announcementContent}
      </WorkflowCardExpandableContainer>
    )
  }

  private handleContentClick = e => {
    const { announcement, eventsStore } = this.props
    if (e.target?.tagName?.toLowerCase() === 'img') {
      e.stopPropagation()
      const index = announcement.contentImages.findIndex(
        im => im.fileUrl === e.target.getAttribute('src'),
      )

      eventsStore.dispatch(
        SHOW_FULLSCREEN_PREVIEW,
        announcement.contentImages,
        index,
      )
    }
  }

  @computed
  private get announcementContent(): JSX.Element | JSX.Element[] {
    const options: HTMLReactParserOptions = {
      replace: (element: DomElement) => {
        if (element.attribs?.class === IMAGE_GALLERY_BLOT_NAME) {
          delete element.attribs.contenteditable
          return element
        }

        if (element.attribs?.class === VIDEO_BLOT_NAME) {
          const src = element.attribs['data-video-src']
          return (
            <div data-video-src={src} className={VIDEO_BLOT_NAME}>
              <VideoPlayer src={src} />
            </div>
          )
        }
      },
    }

    return htmlReactParser(this.props.announcement.content, options)
  }
}
