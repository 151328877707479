import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import CompactPreviewMenu from '~/client/src/shared/components/CompactPreviewMenu/CompactPreviewMenu'
import AvatarType from '~/client/src/shared/enums/AvatarType'
import { TagType } from '~/client/src/shared/enums/TagType'
import Company from '~/client/src/shared/models/Company'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

import Avatar, { AvatarSize } from '../Avatar/Avatar'
import SitemapAttributeTag from '../SitemapAttributeTag/SitemapAttributeTag'

import './CompanyProfilePreview.scss'

interface IProps {
  company: Company

  className?: string
  avatarSize?: AvatarSize
  isPhoneHidden?: boolean
  isMenuHidden?: boolean
  shouldShowCompanyType?: boolean
  shouldUseExtraLargeFont?: boolean
  onShowMenu?: (email: string, phone: string) => void
  onHideMenu?: () => void

  tagsStore?: TagsStore
}

const MAX_TAGS_IN_ROW = 2

@inject('tagsStore')
@observer
export default class CompanyProfilePreview extends React.Component<IProps> {
  @observable private isMoreMenuShown: boolean = false

  public static defaultProps = {
    shouldShowCompanyType: true,
  }

  public render() {
    const {
      company: { avatarUrl, name, businessEmail, businessPhone },
      avatarSize,
      className,
      isPhoneHidden,
      isMenuHidden,
      shouldShowCompanyType,
      shouldUseExtraLargeFont,
    } = this.props

    const shouldShowPhoneIcon = !isPhoneHidden && !!businessPhone
    const shouldShowMenuIcon = (businessEmail || businessPhone) && !isMenuHidden

    return (
      <div
        className={classList({
          'company-profile-preview row y-center overflow-hidden': true,
          [className]: !!className,
        })}
      >
        <div className="no-grow">
          <Avatar
            src={avatarUrl}
            size={avatarSize}
            avatarType={AvatarType.Company}
          />
        </div>
        <div className="col pt2 pl8 overflow-hidden">
          <div
            className={classList({
              'text name-row ellipsis': true,
              large: !shouldUseExtraLargeFont,
              'extra-large': shouldUseExtraLargeFont,
            })}
          >
            {name}
          </div>
          {shouldShowCompanyType && this.renderCompanyType()}
        </div>
        {shouldShowPhoneIcon && (
          <a
            className="no-grow px10"
            href={'tel:' + businessPhone}
            onClick={this.stopPropagation}
          >
            <Icon icon={IconNames.PHONE} iconSize={Icon.SIZE_STANDARD} />
          </a>
        )}
        {shouldShowMenuIcon && (
          <div className="no-grow px10 pointer" onClick={this.showMoreMenu}>
            <Icon icon={IconNames.MORE} />
          </div>
        )}
        {this.isMoreMenuShown && (
          <CompactPreviewMenu
            phoneNumber={businessPhone}
            emailValue={businessEmail}
            shouldHideDirectMessage={true}
            onHide={this.hideMoreMenu}
          />
        )}
      </div>
    )
  }

  private renderCompanyType(): JSX.Element {
    const { company, tagsStore } = this.props
    const store = tagsStore.tagStoreByTagTypeMap[TagType.CompanyType]
    const tags = company.typeTags.map(tag => store.getInstanceById(tag))

    const title = tags.map(({ name }) => name).join(', ')

    const restCount = tags.length - MAX_TAGS_IN_ROW
    const isSingleMode = tags.length === 1

    return (
      <div
        title={title}
        className={classList({
          'row pt2 tags-cell-container': true,
          singular: isSingleMode,
          plural: !isSingleMode,
        })}
      >
        {tags.splice(0, MAX_TAGS_IN_ROW).map(tag => (
          <span key={tag.id + tag.type} className="mr4 no-grow tag-cell">
            <SitemapAttributeTag
              dataObject={tag as LocationAttributeBase}
              contentContainerClassName="text-ellipsis py2"
              shouldShowAsTag={true}
            >
              <span>{tag.name}</span>
            </SitemapAttributeTag>
          </span>
        ))}
        <div className="count-container">
          {restCount > 0 && `(+${restCount})`}
        </div>
      </div>
    )
  }

  private stopPropagation(ev: React.MouseEvent<any>) {
    ev.stopPropagation()
  }

  private showMoreMenu = (ev: React.MouseEvent<any>) => {
    ev.stopPropagation()

    const {
      onShowMenu,
      company: { businessEmail, businessPhone },
    } = this.props

    if (onShowMenu) {
      return onShowMenu(businessEmail, businessPhone)
    }

    this.isMoreMenuShown = true
  }

  private hideMoreMenu = () => {
    const { onHideMenu } = this.props

    if (onHideMenu) {
      return onHideMenu()
    }

    this.isMoreMenuShown = false
  }
}
