import ReactGA from 'react-ga4'

import { IAppConfig } from './Config'

export default class App {
  public constructor(public config: IAppConfig) {}

  public configure() {
    this.configureAnalytics()
  }

  private configureAnalytics() {
    if (!this.config.GOOGLE_ANALYTICS_ID) {
      return console.log(
        'No Google Analytics id found. Starting without analytics',
      )
    }

    // @ts-ignore - window.ReactNativeWebView is injected when running embedded in mobile
    const appMode = window.ReactNativeWebView ? 'Embedded web' : 'Web'

    ReactGA.initialize([
      {
        trackingId: this.config.GOOGLE_ANALYTICS_ID,
        gaOptions: {
          tenantId: this.config.TENANT_ID,
          appMode,
        },
        gtagOptions: {
          tenantId: this.config.TENANT_ID,
          appMode,
        },
      },
    ])
  }
}
