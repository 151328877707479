import {
  ActivityStatus,
  CategoryName,
  IActivityListCustomFilter,
  IActivityListCustomFilterInput,
  IRecentlyUpdatedSavedFilter,
} from '~/client/graph'

import { UNASSIGNED_FILTER_VALUE } from '../../shared/utils/ZoneLevelLocationConstants'

export type ActivityFilterMap = { [key: string]: string[] }

export default class CustomActivityListFilter {
  public static defaultCustomCompanyFilterId = 'customCompanyFilterId'

  public static fromDto(dto: IActivityListCustomFilter) {
    const filtersByFilterType = {}
    dto.filtersByFilterType?.forEach(
      f => (filtersByFilterType[f.type] = f.values),
    )

    return new CustomActivityListFilter(
      dto.id,
      dto.name,
      dto.isPublic,
      dto.projectId,
      dto.userId,
      filtersByFilterType,
      dto.appliedCategories,
      dto.recentlyUpdatedSavedFilter,
      dto.statusFilterMode,
    )
  }

  public constructor(
    public id: string,
    public name: string,
    public isPublic: boolean,
    public readonly projectId: string,
    public readonly userId: string,
    public readonly filtersByFilterType: ActivityFilterMap = {},
    public readonly appliedCategories?: CategoryName[],
    public readonly recentlyUpdatedSavedFilter?: IRecentlyUpdatedSavedFilter,
    public readonly statusFilterMode?: ActivityStatus,
  ) {}

  public toInput(): IActivityListCustomFilterInput {
    const filtersByFilterType = Object.keys(this.filtersByFilterType).map(
      type => ({
        type,
        values:
          this.filtersByFilterType[type]?.filter(
            f => !!f || f === UNASSIGNED_FILTER_VALUE,
          ) || [],
      }),
    )

    return { ...this, filtersByFilterType }
  }
}
