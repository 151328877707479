/**
 * At a high level there are 3 phases of a materials life:
 * during the MATERIALS phase (when the material is being fabricated),
 * the DELIVERY phase (when the material is transported to the site),
 * and the ACTIVITY phase (when the material is being installed).
 */
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'

import Localization from '../../shared/localization/LocalizationManager'
import Delivery from '../models/Delivery'

enum MaterialSpecificStatus {
  // MATERIALS phase
  SUBMITTAL_IN_REVIEW = 'submittal-in-review',
  SUBMITTAL_COMPLETE = 'submittal-complete',
  IN_FABRICATION = 'in-fabrication',
  FABRICATION_COMPLETE = 'fabrication-complete',

  // DELIVERY phase (extended by DeliveryStatus)

  // ACTIVITY phase
  IN_STORAGE = 'in-storage',
  INSTALLED = 'installed',
}

export function getMaterialStatusDisplayName(status: string) {
  switch (status) {
    case MaterialSpecificStatus.SUBMITTAL_IN_REVIEW:
      return Localization.translator.submittalInReview
    case MaterialSpecificStatus.SUBMITTAL_COMPLETE:
      return Localization.translator.submittalComplete
    case MaterialSpecificStatus.IN_FABRICATION:
      return Localization.translator.inFabrication
    case MaterialSpecificStatus.FABRICATION_COMPLETE:
      return Localization.translator.fabricationComplete
    case MaterialSpecificStatus.IN_STORAGE:
      return Localization.translator.inStorage
    case MaterialSpecificStatus.INSTALLED:
      return Localization.translator.installed
    default:
      return Delivery.getStatusDisplayName(status)
  }
}

export type MaterialStatus = MaterialSpecificStatus | DeliveryStatus
export const MaterialStatus = { ...MaterialSpecificStatus, ...DeliveryStatus }
