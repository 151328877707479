import * as React from 'react'

import { computed } from 'mobx'
import { inject } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Activity from '~/client/src/shared/models/Activity'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import {
  DELETE_STATUS_UPDATE_FOR_DATE,
  UPDATE_ACTIVITY_COMPANIES_STATUSES,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import {
  UNASSIGNED,
  UNASSIGNED_FILTER_VALUE,
} from '~/client/src/shared/utils/ZoneLevelLocationConstants'

import ProjectDateStore, { isAfter } from '../../../stores/ui/ProjectDate.store'
import ActivitiesTreeStore from '../../ActivitiesTree/ActivitiesTree.store'
import StatusUpdateInputBase from './StatusUpdateInputBase'

const CLEAR_VALUE = -1

export interface IProps {
  commonStatusUpdate: StatusUpdate
  currentViewDate: Date
  company: string
  activity: Activity
  eventsStore?: EventsStore
  activitiesTreeStore: ActivitiesTreeStore
  projectDateStore?: ProjectDateStore
}

@inject('eventsStore', 'projectDateStore')
export default class ManpowerStatusUpdate extends React.Component<IProps> {
  private get openStatusMap(): Map<string, boolean> {
    return this.props.activitiesTreeStore.manpowerInputOpenStatus
  }

  private get loadingStatusMap(): Map<string, boolean> {
    return this.props.activitiesTreeStore.manpowerInputLoadingStatus
  }

  private get valueStatusMap(): Map<string, number> {
    return this.props.activitiesTreeStore.manpowerInputValueStatus
  }

  private get activityKey(): string {
    const { activity, company } = this.props
    return `${activity.code}-${company}`
  }

  @computed
  private get currentValue() {
    const { commonStatusUpdate, company, projectDateStore } = this.props
    const companyStatus = commonStatusUpdate.getCompanyStatus(
      company,
      projectDateStore.isToday,
    )

    return companyStatus.manpower || 0
  }

  @computed
  private get didValueUpdate() {
    const { commonStatusUpdate, company, currentViewDate, projectDateStore } =
      this.props
    const { dateFor, didUpdateByCompany } = commonStatusUpdate

    if (!projectDateStore.isSameDay(new Date(+dateFor), currentViewDate)) {
      return false
    }
    const shouldShowAsUpdated = company
      ? didUpdateByCompany(company, projectDateStore.isSameDay, false, true)
      : true
    return shouldShowAsUpdated
  }

  @computed
  private get isActivityNotStarted(): boolean {
    const { activity, currentViewDate, projectDateStore } = this.props
    const plannedStartDate = activity.dates.planned.start.date
    return (
      !activity.didStart &&
      isAfter(plannedStartDate, currentViewDate) &&
      !projectDateStore.isSameDay(
        activity.dates.planned.start.date,
        currentViewDate,
      )
    )
  }

  private get didActivityFinish(): boolean {
    const { activity, commonStatusUpdate } = this.props
    return (
      activity.didFinish &&
      commonStatusUpdate.percentComplete === StatusUpdate.MAX_PERCENT
    )
  }

  @computed
  private get lastUpdateTime() {
    const { commonStatusUpdate, company, projectDateStore } = this.props
    const companyStatus = commonStatusUpdate.getCompanyStatus(
      company,
      projectDateStore.isToday,
    )
    return projectDateStore.getTimeToDisplay(companyStatus.manpowerUpdatedAt)
  }

  private get leftCaption() {
    if (this.didActivityFinish) {
      return (
        <div className="text extra-large primary-blue">
          {Localization.translator.completed}
        </div>
      )
    }

    if (this.didValueUpdate) {
      return <div className="text light large">{this.lastUpdateTime}</div>
    }

    if (this.isActivityNotStarted) {
      return (
        <div className="text light extra-large">
          {Localization.translator.startEarly}
        </div>
      )
    }

    return (
      <div className="text red extra-large">
        {Localization.translator.manpower}
      </div>
    )
  }

  private get icon() {
    if (this.didValueUpdate || this.didActivityFinish) {
      return <Icons.ResourcesBlue className="col" />
    }
    return <Icons.ResourcesGray className="col" />
  }

  private get submitTitle() {
    const { activity } = this.props

    if (!activity.didStart) {
      return (
        <div className="actual-button">
          {Localization.translator.start_verb}
        </div>
      )
    }
    return Localization.translator.ok
  }

  public render() {
    return (
      <StatusUpdateInputBase
        value={this.currentValue}
        didUpdate={this.didValueUpdate}
        leftCaption={this.leftCaption}
        icon={this.icon}
        onChange={this.updateManpower}
        openStatusMap={this.openStatusMap}
        loadingStatusMap={this.loadingStatusMap}
        valueStatusMap={this.valueStatusMap}
        activityKey={this.activityKey}
        didStart={!this.isActivityNotStarted}
        didFinish={this.didActivityFinish}
        minValue={this.didValueUpdate ? CLEAR_VALUE : 0}
        valueRender={this.valueRender}
        submitTitle={this.submitTitle}
      />
    )
  }

  private valueRender(value: number) {
    switch (value) {
      case CLEAR_VALUE:
        return Localization.translator.clear
      default:
        return value
    }
  }

  private updateManpower = (manpower: number) => {
    const { company, activity, currentViewDate, eventsStore } = this.props

    const companyValue =
      company === UNASSIGNED ? UNASSIGNED_FILTER_VALUE : company

    if (manpower === CLEAR_VALUE) {
      eventsStore.dispatch(
        DELETE_STATUS_UPDATE_FOR_DATE,
        activity.code,
        companyValue,
        currentViewDate.getTime(),
      )
      return
    }

    eventsStore.dispatch(
      UPDATE_ACTIVITY_COMPANIES_STATUSES,
      activity.code,
      Date.now(),
      [companyValue],
      null,
      manpower,
    )
  }
}
