import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import { Period } from '../Constants/Enums'

export function mergeObjects(data: any): any {
  const mergedObject: any = {}
  const { documents, fields } = data

  const document = documents[0]

  for (const key in document) {
    if (key.startsWith('value_series_')) {
      const index = key.replace('value_series_', '')
      const fieldKey = String(fields[`value_series_${index}`]).toLowerCase()

      mergedObject[fieldKey] = document[key]
    } else {
      mergedObject[key] = document[key]
    }
  }

  return mergedObject
}

export const chartFilters = (
  { startOfDay, endOfDay, addDays }: ProjectDateStore,
  selectedPeriod: Period,
  projectName: string,
  companyName: string,
) => {
  const projectFilter = { ProjectName: projectName }
  const companyFilter = { Company: companyName }
  if (selectedPeriod === Period.EntireProject) {
    return companyName ? { ...projectFilter, ...companyFilter } : projectFilter
  }

  const now = new Date()

  const startDate = startOfDay(addDays(now, -selectedPeriod))
  const endDate = endOfDay(now)

  const Filter = {
    ...projectFilter,
    StartDate: {
      $gte: startDate,
      $lt: endDate,
    },
  }

  return companyName ? { ...Filter, ...companyFilter } : Filter
}

export const getClassNameByDelta = (delta: number, lessIsGood?: boolean) => {
  if (delta === 0) {
    return 'grey'
  } else if ((!lessIsGood && delta > 0) || (lessIsGood && delta < 0)) {
    return 'blue'
  } else {
    return 'red'
  }
}

export function formatTopMetricsValues(value: number | string): number {
  const formattedNumber = Number(Number(value).toFixed(2))
  return formattedNumber
}
