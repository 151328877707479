import React from 'react'

import { action, observable } from 'mobx'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import { NOOP } from '../../utils/noop'
import BaseActionButton from '../BaseActionButton/BaseActionButton'
import AvatarInputStore from './AvatarInput.store'

const editAvatar = 'Edit Avatar'
const BORDER_SIZE = 20
const BORDER_FILL_COLOR = [0, 0, 0, 0.5]
const MIN_SCALE = 1
const MAX_SCALE = 5

interface IPoint {
  x: number
  y: number
}

interface IProps {
  store: AvatarInputStore
  image: string
  onLoadFailure: any
}

interface IImage {
  x: number
  y: number
  resource: any
  height: number
  width: number
}

interface IState {
  width: number
  height: number
  image: IImage
}

const pixelRatio =
  typeof window !== 'undefined' && window.devicePixelRatio
    ? window.devicePixelRatio
    : 1

const defaultEmptyImage: IImage = {
  x: 0.5,
  y: 0.5,
  resource: null,
  height: 0,
  width: 0,
}

class AvatarEditModal extends React.Component<IProps> {
  public static defaultProps: IProps = {
    store: null,
    image: '',
    onLoadFailure: NOOP,
  }

  public state: IState = {
    width: 200,
    height: 200,
    image: defaultEmptyImage,
  }

  private canvas: HTMLCanvasElement = null
  @observable private lastDist: number = 0
  @observable private lastCenter: IPoint = null
  @observable private position: IPoint = { x: 0, y: 0 }
  @observable private scale: number = 1
  @observable private isDragActive: boolean = false
  @observable private isZoomActive: boolean = false
  @observable private my: number = null
  @observable private mx: number = null

  public constructor(props: IProps) {
    super(props)
    this.canvas = null
  }

  public componentDidMount() {
    const context = this.canvas.getContext('2d')
    if (this.props.image) {
      this.loadImage(this.props.image)
    }
    this.paint(context)

    if (!document) {
      return
    }

    const thirdArgument = this.isPassiveSupported() ? { passive: false } : false
    document.addEventListener('touchmove', this.handleTouchMove, thirdArgument)
    document.addEventListener('touchend', this.handleTouchEnd, thirdArgument)
    document.addEventListener('mousemove', this.handleTouchMove, thirdArgument)
    document.addEventListener('mouseup', this.handleTouchEnd, thirdArgument)
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      (this.props.image && this.props.image !== prevProps.image) ||
      this.state.width !== prevState.width ||
      this.state.height !== prevState.height
    ) {
      this.loadImage(this.props.image)
    } else if (!this.state.image && prevState.image !== defaultEmptyImage) {
      this.clearImage()
    }

    if (this.canvas) {
      const context = this.canvas.getContext('2d')
      context.clearRect(0, 0, this.canvas.width, this.canvas.height)
      this.paint(context)
      this.paintImage(context, this.state.image)
    }
  }

  public componentWillUnmount() {
    if (document) {
      document.removeEventListener('touchmove', this.handleTouchMove, false)
      document.removeEventListener('touchend', this.handleTouchEnd, false)
      document.removeEventListener('mousemove', this.handleTouchMove, false)
      document.removeEventListener('mouseup', this.handleTouchEnd, false)
    }
  }

  public render() {
    const { width, height } = this.getCanvasSize()

    return (
      <div className="avatar-edit-modal-popup ba-light-grey bg-white brada4 ma-auto pa10">
        <div className="row mb10">
          <div className="row x-end">
            <div className="text small-header px12 no-white-space-wrap">
              {editAvatar}
            </div>
            <BaseActionButton
              title={Localization.translator.cancel}
              className="inverse-scale-blue-theme"
              isEnabled={true}
              onClick={this.props.store.closeAvatarEditModal}
            />
          </div>
        </div>

        <div className="canvas-container">
          <canvas
            ref={this.setCanvas}
            width={width * pixelRatio}
            height={height * pixelRatio}
            onTouchMove={this.handleTouchMove}
            onTouchEnd={this.handleTouchEnd}
            onTouchStart={this.handleTouchStart}
          />
        </div>
        <BaseActionButton
          isGrow={true}
          title={Localization.translator.save}
          className="scale-blue-theme full-width mt10"
          isEnabled={true}
          onClick={this.handleSave}
        />
      </div>
    )
  }

  public handleTouchStart = e => {
    e = e || window.event
    // if e is a touch event, preventDefault keeps
    // corresponding mouse events from also being fired
    // later.
    e.preventDefault()

    if (!e.touches || !e.touches.length) {
      return
    }

    if (e.touches && e.touches.length === 1) {
      this.isDragActive = true
      this.mx = null
      this.my = null
    } else {
      this.isZoomActive = true
    }
  }

  public handleTouchMove = e => {
    e = e || window.event
    e.preventDefault() // stop scrolling on iOS Safari
    if (this.isDragActive) {
      this.panImage(e)
    }
    if (this.isZoomActive) {
      this.zoomImage(e)
    }
  }

  public handleTouchEnd = () => {
    if (this.isDragActive) {
      this.isDragActive = false
    }

    if (this.isZoomActive) {
      this.isZoomActive = false
      this.lastDist = null
      this.lastCenter = null
    }
  }

  private handleSave = () => {
    const canvas = this.getImage()
    canvas.toBlob(blob => {
      const file = this.blobToFile(blob, 'filename')
      this.props.store.change(file)
      this.props.store.isAvatarEditModalShown = false
    })
  }

  private handleImageReady = (image: IImage) => {
    const imageState = this.getInitialSize(image.width, image.height)
    imageState.resource = image
    imageState.x = 0.5
    imageState.y = 0.5
    this.isDragActive = false
    this.isZoomActive = false
    this.setState({ image: imageState })
  }

  private setCanvas = (canvas: HTMLCanvasElement) => {
    this.canvas = canvas
  }

  private blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob
    b.lastModifiedDate = new Date()
    b.name = fileName
    return theBlob as File
  }

  private panImage(e: any) {
    const mousePositionX = e.touches ? e.touches[0].pageX : e.clientX
    const mousePositionY = e.touches ? e.touches[0].pageY : e.clientY

    if (this.mx && this.my) {
      const width = this.state.image.width * this.scale
      const height = this.state.image.height * this.scale

      const { x: lastX, y: lastY } = this.getCroppingRect()

      const x = lastX * width + this.mx - mousePositionX
      const y = lastY * height + this.my - mousePositionY

      const relativeWidth = (1 / this.scale) * this.getXScale()
      const relativeHeight = (1 / this.scale) * this.getYScale()

      const position = {
        x: x / width + relativeWidth / 2,
        y: y / height + relativeHeight / 2,
      }

      this.position = position

      const image = {
        ...this.state.image,
        ...position,
      }

      this.setState({ image })
    }

    this.mx = mousePositionX
    this.my = mousePositionY
  }

  @action.bound
  private zoomImage(e: any) {
    e.preventDefault()

    const position1 = { x: e.touches[0].pageX, y: e.touches[0].pageY }
    const position2 = { x: e.touches[1].pageX, y: e.touches[1].pageY }
    const distance = this.getDistance(position1, position2)

    if (this.lastDist && distance && distance !== this.lastDist) {
      this.scale += (distance - this.lastDist) / 100
      if (this.scale > MAX_SCALE) {
        this.scale = MAX_SCALE
      } else if (this.scale < MIN_SCALE) {
        this.scale = MIN_SCALE
      }

      // Change position using the center point between the two fingers
      const center = this.getCenter(position1, position2)
      const newPosition = this.getNewPosition(center.x, center.y, this.scale)

      this.position = newPosition
    }

    // Save data for the next move
    this.lastCenter = position1
    this.lastDist = distance
  }

  private getNewPosition = (x: number, y: number, scale: number): IPoint => {
    if (scale === 1) {
      return { x: 0, y: 0 }
    }

    if (scale > this.scale) {
      // Get container coordinates
      const rect = this.getCroppingRect()

      // Retrieve rectangle dimensions and mouse position
      const center = { x: rect.width / 2, y: rect.height / 2 }
      const relative = {
        x: x - rect.x - window.pageXOffset,
        y: y - rect.y - window.pageYOffset,
      }

      // If we are zooming down, we must try to center to mouse position
      const [absX, absY] = [
        (center.x - relative.x) / this.scale,
        (center.y - relative.y) / this.scale,
      ]
      const ratio = scale - this.scale
      return {
        x: this.position.x + absX * ratio,
        y: this.position.y + absY * ratio,
      }
    } else {
      // If we are zooming down, we shall re-center the element
      return {
        x: (this.position.x * (scale - 1)) / (this.scale - 1),
        y: (this.position.y * (scale - 1)) / (this.scale - 1),
      }
    }
  }

  private paint(context: CanvasRenderingContext2D) {
    context.save()
    context.scale(pixelRatio, pixelRatio)
    context.translate(0, 0)
    context.fillStyle = 'rgba(' + BORDER_FILL_COLOR.slice(0, 4).join(',') + ')'

    const { width, height } = this.getCanvasSize()

    context.beginPath()
    // inner rectangle
    context.rect(BORDER_SIZE, BORDER_SIZE, this.state.width, this.state.height)
    // outer rect, drawn "counterclockwise"
    context.rect(width, 0, -width, height)
    context.fill('evenodd')

    context.restore()
  }

  private getCanvasSize() {
    const { width, height } = this.state

    return {
      width: width + BORDER_SIZE * 2,
      height: height + BORDER_SIZE * 2,
    }
  }

  private getImage() {
    // get relative coordinates (0 to 1)
    const cropRect = this.getCroppingRect()
    const image = this.state.image

    // get actual pixel coordinates
    cropRect.x *= image.resource.width
    cropRect.y *= image.resource.height
    cropRect.width *= image.resource.width
    cropRect.height *= image.resource.height

    const canvas = document.createElement('canvas')

    canvas.width = cropRect.width
    canvas.height = cropRect.height

    // draw the full-size image at the correct position,
    // the image gets truncated to the size of the canvas.
    const context = canvas.getContext('2d')

    context.translate(canvas.width / 2, canvas.height / 2)
    context.translate(-(canvas.width / 2), -(canvas.height / 2))

    context.drawImage(image.resource, -cropRect.x, -cropRect.y)

    return canvas
  }

  private getXScale() {
    const canvasAspect = this.state.width / this.state.height
    const imageAspect = this.state.image.width / this.state.image.height

    return Math.min(1, canvasAspect / imageAspect)
  }

  private getYScale() {
    const canvasAspect = this.state.height / this.state.width
    const imageAspect = this.state.image.height / this.state.image.width

    return Math.min(1, canvasAspect / imageAspect)
  }

  private getCroppingRect() {
    const position = this.position || {
      x: this.state.image.x,
      y: this.state.image.y,
    }
    const width = (1 / this.scale) * this.getXScale()
    const height = (1 / this.scale) * this.getYScale()

    const croppingRect = {
      x: position.x - width / 2,
      y: position.y - height / 2,
      width,
      height,
    }

    let xMin = 0
    let xMax = 1 - croppingRect.width
    let yMin = 0
    let yMax = 1 - croppingRect.height

    // If the cropping rect is larger than the image, then we need to change
    // our maxima & minima for x & y to allow the image to appear anywhere up
    // to the very edge of the cropping rect.
    const isLargerThanImage = width > 1 || height > 1

    if (isLargerThanImage) {
      xMin = -croppingRect.width
      xMax = 1
      yMin = -croppingRect.height
      yMax = 1
    }

    return {
      ...croppingRect,
      x: Math.max(xMin, Math.min(croppingRect.x, xMax)),
      y: Math.max(yMin, Math.min(croppingRect.y, yMax)),
    }
  }

  private getInitialSize(width: number, height: number) {
    let imgHeight: number
    let imgWidth: number

    const canvasRatio = this.state.height / this.state.width
    const imageRatio = height / width

    if (canvasRatio > imageRatio) {
      imgHeight = this.state.height
      imgWidth = imgHeight / imageRatio
    } else {
      imgWidth = this.state.width
      imgHeight = imgWidth * imageRatio
    }

    return {
      height: imgHeight,
      width: imgWidth,
    } as IImage
  }

  private clearImage = () => {
    const context = this.canvas.getContext('2d')
    context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    this.setState({
      image: defaultEmptyImage,
    })
  }

  private paintImage(
    context: CanvasRenderingContext2D,
    image: IImage,
    scaleFactor: number = pixelRatio,
  ) {
    if (!image.resource) {
      return
    }
    const position = this.calculatePosition(image)

    context.save()

    context.scale(scaleFactor, scaleFactor)

    context.globalCompositeOperation = 'destination-over'
    context.drawImage(
      image.resource,
      position.dx,
      position.dy,
      position.dWidth,
      position.dHeight,
    )

    context.restore()
  }

  private calculatePosition(image: IImage) {
    image = image || this.state.image

    const croppingRect = this.getCroppingRect()

    const dWidth = image.width * this.scale
    const dHeight = image.height * this.scale

    const dx = -croppingRect.x * dWidth + BORDER_SIZE
    const dy = -croppingRect.y * dHeight + BORDER_SIZE

    return {
      dx,
      dy,
      dWidth,
      dHeight,
    }
  }

  private loadImage(image: string) {
    this.loadImageURL(image)
      .then(this.handleImageReady)
      .catch(this.props.onLoadFailure)
  }

  private loadImageURL(imageURL: string) {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.onload = () => resolve(image)
      image.onerror = reject
      image.crossOrigin = 'anonymous'
      image.src = imageURL
    })
  }

  private getDistance(point1: IPoint, point2: IPoint) {
    return Math.sqrt(
      Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2),
    )
  }

  private getCenter(point1: IPoint, point2: IPoint) {
    return {
      x: (point1.x + point2.x) / 2,
      y: (point1.y + point2.y) / 2,
    }
  }

  private isPassiveSupported = () => {
    let passiveSupported = false
    try {
      const options = Object.defineProperty({}, 'passive', {
        get() {
          passiveSupported = true
        },
      })

      // @ts-ignore: TODO: double-check this functionality
      window.addEventListener('test', options, options)
      // @ts-ignore: TODO: double-check this functionality
      window.removeEventListener('test', options, options)
    } catch (err) {
      passiveSupported = false
    }
    return passiveSupported
  }
}

export default AvatarEditModal
