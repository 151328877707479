export default {
  samoa: 'Samoa Standard Time: SST',
  niue: 'Niue Time: NUT',

  hawaiiAleuntian: 'Hawaii-Aleutian Standard Time: HST',
  cookIsland: 'Cook Island Time: CKT',
  tahiti: 'Tahiti Time: TAHT',

  marquesas: 'Marquesas Time: MART',

  alaska: 'Alaska Standard Time: AKST',
  gambier: 'Gambier Time: GAMT',

  pacific: 'Pacific Standard Time: PST',
  pitcairn: 'Pitcairn Standard Time: PST',

  mountain: 'Mountain Standard Time: MST',
  mexicanPacific: 'Mexican Pacific Standard Time',

  centralStandard: 'Central Standard Time: CST',
  centralMexicoStandard: 'Central Standard Time (Mexico): CST',
  galapagos: 'Galapagos Time: GALT',
  easterIsland: 'Easter Island Standard Time: EAST',

  acre: 'Acre Time: ACT',
  ecuador: 'Ecuador Time: ECT',
  peru: 'Peru Time: PET',
  easternStandard: 'Eastern Standard Time: EST',
  colombia: 'Colombia Time: COT',
  cuba: 'Cuba Standard Time: CST',

  guyana: 'Guyana Time: GYT',
  bolivia: 'Bolivia Time: BOT',
  amazon: 'Amazon Time: AMT',
  venezuela: 'Venezuela Standard Time: VET',
  atlantic: 'Atlantic Standard Time: AST',
  chile: 'Chile Standard Time: CLT',

  newfoundland: 'Newfoundland Standard Time: NST',

  pierre: 'St. Pierre & Miquelon Standard Time: PMST',
  uruguay: 'Uruguay Time: UYT',
  falkland: 'Falkland Island Time: FKT',
  paraguay: 'Paraguay Time: PYT',
  brasilia: 'Brasília Time: BRT',
  suriname: 'Suriname Time: SRT',
  argentina: 'Argentina Time: ART',
  frenchGuiana: 'French Guiana Time: GFT',
  westGreenland: 'West Greenland Time: WGT(GMT -3)',
  rothera: 'Rothera Time: ROTT',
  palmer: 'Palmer Time',
  puntaArenas: 'Punta Arenas Time',

  southGeorgia: 'South Georgia Time: GST',
  fernandoDeNoronha: 'Fernando de Noronha Time: FNT',

  capeVerde: 'Cape Verde Time: CVT',
  azores: 'Azores Standard Time: AZOT',
  eastGreenland: 'East Greenland Time: EGT',

  coordinatedUniversal: 'Coordinated Universal Time: UTC',
  greenwichMean: 'Greenwich Mean Time: GMT',
  greenwichMeanSaoTome: 'Greenwich Mean Time (Sao Tome): GMT',
  westernEuropean: 'Western European Time: WET',
  westernEuropeanAfrica: 'Western European Time (Africa): WET',

  westAfrica: 'West Africa Time: WAT',
  centralEuropean: 'Central European Time: CET',

  centralAfricaTime: 'Central Africa Time: CAT',
  southAfrica: 'South Africa Standard Time',
  easternEuropean: 'Eastern European Time: EET',
  easternEuropeanBeirutGaza: 'Eastern European Time (Beirut, Gaza): EET',
  easternEuropeanDamascusAmman: 'Eastern European Time (Damascus, Amman): EET',
  israel: 'Israel Standard Time: IST',

  eastAfrica: 'East Africa Time: EAT',
  turkey: 'Turkey Time: TRT',
  arabian: 'Arabian Standard Time: AST',
  moscow: 'Moscow Standard Time: MSK',
  syowa: 'Syowa Time',
  volgograd: 'Volgograd Standard Time',

  iran: 'Iran Standard Time: IRST',

  gulf: 'Gulf Standard Time: GST',
  azerbaijan: 'Azerbaijan Time: AZT',
  armenia: 'Armenia Time: AMT',
  astrakhan: 'Astrakhan Time',
  ulyanovsk: 'Ulyanovsk Time',
  samara: 'Samara Time: SAMT',
  georgia: 'Georgia Standard Time',
  mauritius: 'Mauritius Time: MUT',
  seychelles: 'Seychelles Time: SCT',
  reunion: 'Reunion Time: RET',

  afghanistan: 'Afghanistan Time: AFT',

  yekaterinburg: 'Yekaterinburg Time: YEKT',
  pakistan: 'Pakistan Standard Time: PKT',
  tajikistan: 'Tajikistan Time',
  uzbekistan: 'Uzbekistan Standard Time',
  westKazakhstan: 'West Kazakhstan Time',
  turkmenistan: 'Turkmenistan Time: TMT',
  frenchSouthern: 'French Southern and Antarctic Time: TFT',
  maldives: 'Maldives Time: MVT',
  mawson: 'Mawson Time: MAWT',

  india: 'India Standard Time: IST',

  nepal: 'Nepal Time: NPT',

  bhutan: 'Bhutan Time: BTT',
  omsk: 'Omsk Standard Time: OMST',
  kyrgyzstan: 'Kyrgyzstan Time: KGT',
  bangladesh: 'Bangladesh Standard Time: BST',
  indianOcean: 'Indian Ocean Time: IOT',
  vostok: 'Vostok Time: VOST',
  urumqiKashgar: 'Urumqi/Kashgar Time',
  eastKazakhstan: 'East Kazakhstan Time',

  myanmar: 'Myanmar Time: MMT',
  cocosIsland: 'Cocos Islands Time: CCT',

  westernIndonesia: 'Western Indonesia Time: WIB',
  indochina: 'Indochina Time: ICT',
  hovd: 'Hovd Time: HOVT',
  krasnoyarsk: 'Krasnoyarsk Time: KRAT',
  novosibirsk: 'Novosibirsk Standard Time',
  christmasIsland: 'Christmas Island Time: CXT',
  davis: 'Davis Time: DAVT',
  barnaulTomsk: 'Barnaul/Tomsk Time',

  irkutsk: 'Irkutsk Time: IRKT',
  hongKong: 'Hong Kong Time: HKT',
  singapore: 'Singapore Time: SGT',
  malaysia: 'Malaysia Time: MYT',
  australianWestern: 'Australian Western Standard Time: AWST',
  chinaStandard: 'China Standard Time: CST',
  centralIndonesia: 'Central Indonesia Time: WITA',
  philippine: 'Philippine Time: PHT',
  bruneiDarussalam: 'Brunei Darussalam Time: BNT',
  taipei: 'Taipei Standard Time',
  ulaanbaatar: 'Ulaanbaatar Standard Time',

  pyongyang: 'Pyongyang Time: PYT',

  australianCentralWestern: 'Australian Central Western Standard Time: ACWST',

  easternIndonesia: 'Eastern Indonesia Time: WIT',
  japan: 'Japan Standard Time: JST',
  korea: 'Korea Standard Time: KST',
  eastTimor: 'East Timor Time: TLT',
  yakutsk: 'Yakutsk Time: YAKT',
  palau: 'Palau Time: PWT',

  australianCentralStandard: 'Australian Central Standard Time: ACST',

  australianEasternStandard: 'Australian Eastern Standard Time: AEST',
  vladivostok: 'Vladivostok Time: VLAT',
  chamorro: 'Chamorro Standard Time: ChST',
  papuaNewGuinea: 'Papua New Guinea Time: PGT',
  chuuk: 'Chuuk Time',
  dumontdUrville: 'Dumont-d’Urville Time',

  lordHowe: 'Lord Howe Standard Time: LHST',

  magadan: 'Magadan Time: MAGT',
  solomonIslands: 'Solomon Islands Time: SBT',
  kosrae: 'Kosrae Time: KOST',
  ponhpei: 'Pohnpei Standard Time: PONT',
  newCaledonia: 'New Caledonia Time: NCT',
  norfolk: 'Norfolk Time: NFT',
  vanuatu: 'Vanuatu Time: VUT',
  casey: 'Casey Time: CAST',
  srednekolymskBougainville: 'Srednekolymsk/Bougainville Time',
  sakhalin: 'Sakhalin Standard Time',

  marshallIslands: 'Marshall Islands Time: MHT',
  petropavlovskKamchatski: 'Petropavlovsk-Kamchatski Time: PETT',
  tuvalu: 'Tuvalu Time: TVT',
  gilbertIslands: 'Gilbert Islands Time: GILT',
  nauru: 'Nauru Time: NRT',
  wakeIsland: 'Wake Island Time: WAKT',
  wallisAndFutuna: 'Wallis and Futuna Time: WFT',
  anadyr: 'Anadyr Standard Time',
  fiji: 'Fiji Standard Time: FJT',
  newZealand: 'New Zealand Standard Time: NZST',

  chatham: 'Chatham Standard Time: CHAST',

  phoenixIslands: 'Phoenix Islands Time: PHOT',
  tokelau: 'Tokelau Time: TKT',
  tonga: 'Tonga Time: TOT',
  apia: 'Apia Standard Time',

  lineIslands: 'Line Islands Time: LINT',
}
