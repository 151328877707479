import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IGlobeViewFieldsFragment = Pick<
  Types.IGlobeView,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'version'
  | 'projectId'
  | 'name'
  | 'altitude'
  | 'pitch'
  | 'zoom'
  | 'isProjectOverviewGlobe'
  | 'filledImage'
  | 'bearing'
  | 'isOrientationLocked'
  | 'isSatelliteMode'
  | 'isTrafficShown'
> & {
  sitemaps?: Types.Maybe<
    Array<
      Types.Maybe<Pick<Types.IGlobeViewSitemapData, 'sitemapId' | 'isHidden'>>
    >
  >
  bounds?: Types.Maybe<{
    ne?: Types.Maybe<Pick<Types.ILatLng, 'lat' | 'lng'>>
    sw?: Types.Maybe<Pick<Types.ILatLng, 'lat' | 'lng'>>
  }>
  center?: Types.Maybe<Pick<Types.ILatLng, 'lat' | 'lng'>>
  geoCorners?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.IGeoJson2DGeographicCoordinates, 'latitude' | 'longitude'>
      >
    >
  >
}

export type IGlobeViewItemsFieldsFragment = Pick<
  Types.IGlobeViewSpecificItemData,
  'id' | 'sitemapItemId' | 'globeViewId' | 'projectId' | 'isHidden'
> & {
  icon?: Types.Maybe<Pick<Types.IGlobeViewPin, 'isHidden'>>
  label?: Types.Maybe<
    Pick<
      Types.IGlobeViewTextBox,
      'fontSize' | 'isHidden' | 'isTextBoxDisplayed' | 'color'
    >
  >
  shape?: Types.Maybe<
    | Pick<
        Types.IGlobeViewCircle,
        | 'fillColor'
        | 'fillOpacity'
        | 'lineColor'
        | 'lineWidth'
        | 'type'
        | 'isDisplayed'
      >
    | Pick<
        Types.IGlobeViewPolyline,
        | 'arrowPosition'
        | 'isClosed'
        | 'fillColor'
        | 'fillOpacity'
        | 'lineColor'
        | 'lineWidth'
        | 'type'
        | 'isDisplayed'
      >
    | Pick<
        Types.IGlobeViewRectangle,
        | 'fillColor'
        | 'fillOpacity'
        | 'lineColor'
        | 'lineWidth'
        | 'type'
        | 'isDisplayed'
      >
  >
}

export type IGlobeViewsByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGlobeViewsByProjectIdQuery = {
  globeViews?: Types.Maybe<{
    data: Array<
      {
        items?: Types.Maybe<Array<Types.Maybe<IGlobeViewItemsFieldsFragment>>>
      } & IGlobeViewFieldsFragment
    >
  }>
}

export type IListenGlobeViewsByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenGlobeViewsByProjectIdSubscription = {
  globeView?: Types.Maybe<
    Pick<Types.IGlobeViewChangeEvent, 'id'> & {
      item?: Types.Maybe<IGlobeViewFieldsFragment>
    }
  >
}

export type IListenGlobeViewItemsDataByProjectIdSubscriptionVariables =
  Types.Exact<{
    projectId: Types.Scalars['ObjectId']
  }>

export type IListenGlobeViewItemsDataByProjectIdSubscription = {
  globeViewSpecificItemData?: Types.Maybe<
    Pick<Types.IGlobeViewSpecificItemDataChangeEvent, 'id'> & {
      item?: Types.Maybe<IGlobeViewItemsFieldsFragment>
    }
  >
}

export type ISaveGlobeViewMutationVariables = Types.Exact<{
  globeView: Types.IGlobeViewInput
}>

export type ISaveGlobeViewMutation = {
  saveGlobeView?: Types.Maybe<Pick<Types.IGlobeView, 'id'>>
}

export type ISaveGlobeViewSpecificItemDataMutationVariables = Types.Exact<{
  polylines?: Types.Maybe<
    | Array<Types.Maybe<Types.IGlobeViewSpecificItemDataPolylineInput>>
    | Types.Maybe<Types.IGlobeViewSpecificItemDataPolylineInput>
  >
  rectangles?: Types.Maybe<
    | Array<Types.Maybe<Types.IGlobeViewSpecificItemDataRectangleInput>>
    | Types.Maybe<Types.IGlobeViewSpecificItemDataRectangleInput>
  >
  circles?: Types.Maybe<
    | Array<Types.Maybe<Types.IGlobeViewSpecificItemDataCircleInput>>
    | Types.Maybe<Types.IGlobeViewSpecificItemDataCircleInput>
  >
}>

export type ISaveGlobeViewSpecificItemDataMutation = {
  saveGlobeViewSpecificItemData?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IGlobeViewSpecificItemData, 'id'>>>
  >
}

export type IDeleteGlobeViewSpecificItemDataMutationVariables = Types.Exact<{
  globeViewItemId: Types.Scalars['ObjectId']
}>

export type IDeleteGlobeViewSpecificItemDataMutation = Pick<
  Types.IMutation,
  'deleteGlobeViewSpecificItemData'
>

export type IDeleteGlobeViewMutationVariables = Types.Exact<{
  id: Types.Scalars['ObjectId']
}>

export type IDeleteGlobeViewMutation = Pick<Types.IMutation, 'deleteGlobeView'>

export const GlobeViewFieldsFragmentDoc = gql`
  fragment GlobeViewFields on GlobeView {
    id
    createdAt
    updatedAt
    version
    projectId
    name
    altitude
    pitch
    zoom
    isProjectOverviewGlobe
    filledImage
    bearing
    sitemaps {
      sitemapId
      isHidden
    }
    bounds {
      ne {
        lat
        lng
      }
      sw {
        lat
        lng
      }
    }
    center {
      lat
      lng
    }
    geoCorners {
      latitude
      longitude
    }
    isOrientationLocked
    isSatelliteMode
    isTrafficShown
  }
`
export const GlobeViewItemsFieldsFragmentDoc = gql`
  fragment GlobeViewItemsFields on GlobeViewSpecificItemData {
    id
    sitemapItemId
    globeViewId
    projectId
    isHidden
    icon {
      isHidden
    }
    label {
      fontSize
      isHidden
      isTextBoxDisplayed
      color
    }
    shape {
      fillColor
      fillOpacity
      lineColor
      lineWidth
      type
      isDisplayed
      ... on GlobeViewPolyline {
        arrowPosition
        isClosed
      }
    }
  }
`
export const GlobeViewsByProjectIdDocument = gql`
  query GlobeViewsByProjectId($projectId: ObjectId!) {
    globeViews(projectId: $projectId, limit: 1000000) {
      data {
        ...GlobeViewFields
        items {
          ...GlobeViewItemsFields
        }
      }
    }
  }
  ${GlobeViewFieldsFragmentDoc}
  ${GlobeViewItemsFieldsFragmentDoc}
`

/**
 * __useGlobeViewsByProjectIdQuery__
 *
 * To run a query within a React component, call `useGlobeViewsByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobeViewsByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobeViewsByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGlobeViewsByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGlobeViewsByProjectIdQuery,
    IGlobeViewsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGlobeViewsByProjectIdQuery,
    IGlobeViewsByProjectIdQueryVariables
  >(GlobeViewsByProjectIdDocument, options)
}
export function useGlobeViewsByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGlobeViewsByProjectIdQuery,
    IGlobeViewsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGlobeViewsByProjectIdQuery,
    IGlobeViewsByProjectIdQueryVariables
  >(GlobeViewsByProjectIdDocument, options)
}
export type GlobeViewsByProjectIdQueryHookResult = ReturnType<
  typeof useGlobeViewsByProjectIdQuery
>
export type GlobeViewsByProjectIdLazyQueryHookResult = ReturnType<
  typeof useGlobeViewsByProjectIdLazyQuery
>
export type GlobeViewsByProjectIdQueryResult = Apollo.QueryResult<
  IGlobeViewsByProjectIdQuery,
  IGlobeViewsByProjectIdQueryVariables
>
export const ListenGlobeViewsByProjectIdDocument = gql`
  subscription ListenGlobeViewsByProjectId($projectId: ObjectId!) {
    globeView(projectId: $projectId) {
      id
      item {
        ...GlobeViewFields
      }
    }
  }
  ${GlobeViewFieldsFragmentDoc}
`

/**
 * __useListenGlobeViewsByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenGlobeViewsByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenGlobeViewsByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenGlobeViewsByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenGlobeViewsByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenGlobeViewsByProjectIdSubscription,
    IListenGlobeViewsByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenGlobeViewsByProjectIdSubscription,
    IListenGlobeViewsByProjectIdSubscriptionVariables
  >(ListenGlobeViewsByProjectIdDocument, options)
}
export type ListenGlobeViewsByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenGlobeViewsByProjectIdSubscription
>
export type ListenGlobeViewsByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenGlobeViewsByProjectIdSubscription>
export const ListenGlobeViewItemsDataByProjectIdDocument = gql`
  subscription ListenGlobeViewItemsDataByProjectId($projectId: ObjectId!) {
    globeViewSpecificItemData(projectId: $projectId) {
      id
      item {
        ...GlobeViewItemsFields
      }
    }
  }
  ${GlobeViewItemsFieldsFragmentDoc}
`

/**
 * __useListenGlobeViewItemsDataByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenGlobeViewItemsDataByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenGlobeViewItemsDataByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenGlobeViewItemsDataByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenGlobeViewItemsDataByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenGlobeViewItemsDataByProjectIdSubscription,
    IListenGlobeViewItemsDataByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenGlobeViewItemsDataByProjectIdSubscription,
    IListenGlobeViewItemsDataByProjectIdSubscriptionVariables
  >(ListenGlobeViewItemsDataByProjectIdDocument, options)
}
export type ListenGlobeViewItemsDataByProjectIdSubscriptionHookResult =
  ReturnType<typeof useListenGlobeViewItemsDataByProjectIdSubscription>
export type ListenGlobeViewItemsDataByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenGlobeViewItemsDataByProjectIdSubscription>
export const SaveGlobeViewDocument = gql`
  mutation SaveGlobeView($globeView: GlobeViewInput!) {
    saveGlobeView(globeView: $globeView) {
      id
    }
  }
`
export type ISaveGlobeViewMutationFn = Apollo.MutationFunction<
  ISaveGlobeViewMutation,
  ISaveGlobeViewMutationVariables
>

/**
 * __useSaveGlobeViewMutation__
 *
 * To run a mutation, you first call `useSaveGlobeViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGlobeViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGlobeViewMutation, { data, loading, error }] = useSaveGlobeViewMutation({
 *   variables: {
 *      globeView: // value for 'globeView'
 *   },
 * });
 */
export function useSaveGlobeViewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveGlobeViewMutation,
    ISaveGlobeViewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveGlobeViewMutation,
    ISaveGlobeViewMutationVariables
  >(SaveGlobeViewDocument, options)
}
export type SaveGlobeViewMutationHookResult = ReturnType<
  typeof useSaveGlobeViewMutation
>
export type SaveGlobeViewMutationResult =
  Apollo.MutationResult<ISaveGlobeViewMutation>
export type SaveGlobeViewMutationOptions = Apollo.BaseMutationOptions<
  ISaveGlobeViewMutation,
  ISaveGlobeViewMutationVariables
>
export const SaveGlobeViewSpecificItemDataDocument = gql`
  mutation SaveGlobeViewSpecificItemData(
    $polylines: [GlobeViewSpecificItemDataPolylineInput]
    $rectangles: [GlobeViewSpecificItemDataRectangleInput]
    $circles: [GlobeViewSpecificItemDataCircleInput]
  ) {
    saveGlobeViewSpecificItemData(
      polylines: $polylines
      rectangles: $rectangles
      circles: $circles
    ) {
      id
    }
  }
`
export type ISaveGlobeViewSpecificItemDataMutationFn = Apollo.MutationFunction<
  ISaveGlobeViewSpecificItemDataMutation,
  ISaveGlobeViewSpecificItemDataMutationVariables
>

/**
 * __useSaveGlobeViewSpecificItemDataMutation__
 *
 * To run a mutation, you first call `useSaveGlobeViewSpecificItemDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGlobeViewSpecificItemDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGlobeViewSpecificItemDataMutation, { data, loading, error }] = useSaveGlobeViewSpecificItemDataMutation({
 *   variables: {
 *      polylines: // value for 'polylines'
 *      rectangles: // value for 'rectangles'
 *      circles: // value for 'circles'
 *   },
 * });
 */
export function useSaveGlobeViewSpecificItemDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveGlobeViewSpecificItemDataMutation,
    ISaveGlobeViewSpecificItemDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveGlobeViewSpecificItemDataMutation,
    ISaveGlobeViewSpecificItemDataMutationVariables
  >(SaveGlobeViewSpecificItemDataDocument, options)
}
export type SaveGlobeViewSpecificItemDataMutationHookResult = ReturnType<
  typeof useSaveGlobeViewSpecificItemDataMutation
>
export type SaveGlobeViewSpecificItemDataMutationResult =
  Apollo.MutationResult<ISaveGlobeViewSpecificItemDataMutation>
export type SaveGlobeViewSpecificItemDataMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveGlobeViewSpecificItemDataMutation,
    ISaveGlobeViewSpecificItemDataMutationVariables
  >
export const DeleteGlobeViewSpecificItemDataDocument = gql`
  mutation DeleteGlobeViewSpecificItemData($globeViewItemId: ObjectId!) {
    deleteGlobeViewSpecificItemData(id: $globeViewItemId)
  }
`
export type IDeleteGlobeViewSpecificItemDataMutationFn =
  Apollo.MutationFunction<
    IDeleteGlobeViewSpecificItemDataMutation,
    IDeleteGlobeViewSpecificItemDataMutationVariables
  >

/**
 * __useDeleteGlobeViewSpecificItemDataMutation__
 *
 * To run a mutation, you first call `useDeleteGlobeViewSpecificItemDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGlobeViewSpecificItemDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGlobeViewSpecificItemDataMutation, { data, loading, error }] = useDeleteGlobeViewSpecificItemDataMutation({
 *   variables: {
 *      globeViewItemId: // value for 'globeViewItemId'
 *   },
 * });
 */
export function useDeleteGlobeViewSpecificItemDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteGlobeViewSpecificItemDataMutation,
    IDeleteGlobeViewSpecificItemDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteGlobeViewSpecificItemDataMutation,
    IDeleteGlobeViewSpecificItemDataMutationVariables
  >(DeleteGlobeViewSpecificItemDataDocument, options)
}
export type DeleteGlobeViewSpecificItemDataMutationHookResult = ReturnType<
  typeof useDeleteGlobeViewSpecificItemDataMutation
>
export type DeleteGlobeViewSpecificItemDataMutationResult =
  Apollo.MutationResult<IDeleteGlobeViewSpecificItemDataMutation>
export type DeleteGlobeViewSpecificItemDataMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteGlobeViewSpecificItemDataMutation,
    IDeleteGlobeViewSpecificItemDataMutationVariables
  >
export const DeleteGlobeViewDocument = gql`
  mutation DeleteGlobeView($id: ObjectId!) {
    deleteGlobeView(id: $id)
  }
`
export type IDeleteGlobeViewMutationFn = Apollo.MutationFunction<
  IDeleteGlobeViewMutation,
  IDeleteGlobeViewMutationVariables
>

/**
 * __useDeleteGlobeViewMutation__
 *
 * To run a mutation, you first call `useDeleteGlobeViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGlobeViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGlobeViewMutation, { data, loading, error }] = useDeleteGlobeViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGlobeViewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteGlobeViewMutation,
    IDeleteGlobeViewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteGlobeViewMutation,
    IDeleteGlobeViewMutationVariables
  >(DeleteGlobeViewDocument, options)
}
export type DeleteGlobeViewMutationHookResult = ReturnType<
  typeof useDeleteGlobeViewMutation
>
export type DeleteGlobeViewMutationResult =
  Apollo.MutationResult<IDeleteGlobeViewMutation>
export type DeleteGlobeViewMutationOptions = Apollo.BaseMutationOptions<
  IDeleteGlobeViewMutation,
  IDeleteGlobeViewMutationVariables
>
