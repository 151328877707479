import * as React from 'react'

import { observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import PasswordResetView from '~/client/src/shared/components/PasswordResetView/PasswordResetView'

import './PasswordResetViewBaseDesktop.scss'

interface IRouteParams {
  resetCode?: string
}

@observer
class PasswordResetViewBaseDesktop extends React.Component<
  RouteComponentProps<IRouteParams>
> {
  public render() {
    const { location, match } = this.props

    return (
      <div className="row full-height reset-password-desktop-container x-center y-center">
        <PasswordResetView
          resetCode={match.params.resetCode}
          email={location.state?.email}
        />
      </div>
    )
  }
}

export default withRouter(PasswordResetViewBaseDesktop)
