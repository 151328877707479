import { computed, observable } from 'mobx'

import {
  IAnnouncement,
  IAnnouncementInput,
  IAnnouncementOrder,
  IAnnouncementRelationship,
  IAttachment,
  ISiteLocation,
} from '~/client/graph'

import FileType from '../enums/FileType'
import IFilePreviewProperties from '../interfaces/IFilePreviewProperties'
import ProjectDateStore, {
  areIntervalTimesIntersects,
} from '../stores/ui/ProjectDate.store'
import { copyObject, copyObjectArray } from '../utils/util'
import BaseModel from './BaseModel'

export default class Announcement
  extends BaseModel<IAnnouncement>
  implements IAnnouncement
{
  public static fromDto(
    dto: IAnnouncement,
    projectDateStore: ProjectDateStore,
  ) {
    return new Announcement(
      projectDateStore,
      dto.id,
      dto.projectId,
      dto.startDate,
      dto.endDate,
      dto.orders,
      dto.title,
      dto.content,
      dto.isClosure,
      dto.attachments,
      dto.location,
      dto.dateToAppear,
      dto.createdAt,
      dto.updatedAt,
      dto.relationship,
    )
  }

  public id: string
  public projectId: string
  @observable public startDate: number
  @observable public endDate: number
  @observable public location?: ISiteLocation
  @observable public attachments: IAttachment[]
  @observable public orders: IAnnouncementOrder[]
  @observable public title: string
  @observable public content: string
  @observable public isClosure: boolean
  public dateToAppear?: number
  public relationship?: IAnnouncementRelationship

  public constructor(
    private projectDateStore: ProjectDateStore,
    id: string,
    projectId: string,
    startDate: number,
    endDate: number,
    orders: IAnnouncementOrder[],
    title: string,
    content: string,
    isClosure: boolean,
    attachments: IAttachment[] = [],
    location?: ISiteLocation,
    dateToAppear?: number,
    createdAt: number = 0,
    updatedAt: number = 0,
    relationship?: IAnnouncementRelationship,
  ) {
    super(id)

    this.projectId = projectId
    this.startDate = startDate
    this.endDate = endDate
    this.orders = orders
    this.title = title
    this.content = content
    this.isClosure = isClosure
    this.attachments = attachments
    this.location = location
    this.dateToAppear = dateToAppear

    this.setCreatedAt(createdAt)
    this.setUpdatedAt(updatedAt)

    this.relationship = relationship
  }

  public getDisplayedName = (isShortened?: boolean): string => {
    let name: string
    if (this.title) {
      if (!isShortened) {
        return this.title
      }

      name = this.title
    } else {
      const div = document.createElement('div')
      div.innerHTML = this.content
      document.body.appendChild(div)
      name = div.innerText.split('\n')[0]
      document.body.removeChild(div)
    }

    return name.length <= 60 ? name : name.substring(0, 59) + '...'
  }

  public copy(): Announcement {
    return new Announcement(
      this.projectDateStore,
      this.id,
      this.projectId,
      this.startDate,
      this.endDate,
      copyObjectArray(this.orders),
      this.title,
      this.content,
      this.isClosure,
      copyObjectArray(this.attachments),
      this.location && copyObject(this.location),
      this.dateToAppear,
      this.createdAt,
      this.updatedAt,
      this.relationship,
    )
  }

  public getDto(): IAnnouncementInput {
    return {
      id: this.id,
      projectId: this.projectId,
      startDate: this.startDate,
      endDate: this.endDate,
      orders: this.orders.map(o => ({
        order: o.order,
        date: o.date,
      })),
      title: this.title,
      content: this.content,
      isClosure: this.isClosure,
      attachments: this.attachments.map(({ fileName, size, url }) => ({
        fileName,
        size,
        url,
      })),
      location: {
        id: this.location.id,
        type: this.location.type,
        levels: this.location.levels,
      },
      dateToAppear: this.dateToAppear,
      relationship: this.relationship,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    }
  }

  public get datesLabel(): string {
    return this.projectDateStore.getDateIntervalToDisplay(
      this.startDate,
      this.endDate,
    )
  }

  @computed
  public get contentImages(): IFilePreviewProperties[] {
    const div = document.createElement('div')
    div.innerHTML = this.content
    const images = Array.from(div.getElementsByTagName('img')).map(im => ({
      fileType: FileType.Image,
      fileUrl: im.getAttribute('src'),
      fileName: im.getAttribute('alt'),
    }))

    div.remove()
    return images
  }

  public isScheduledWithinRange = (startDate: Date, endDate: Date): boolean => {
    return areIntervalTimesIntersects(
      {
        startDate: new Date(this.startDate),
        endDate: new Date(this.endDate),
      },
      {
        startDate,
        endDate,
      },
    )
  }
}
