import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DeliveryDateLabel from '~/client/src/shared/components/DeliveryDateLabel/DeliveryDateLabel'
import DeliveryFieldIcon from '~/client/src/shared/components/DeliveryFieldIcon/DeliveryFieldIcon'
import DeliveryStatusLabel from '~/client/src/shared/components/DeliveryStatusLabel/DeliveryStatusLabel'
import * as Icons from '~/client/src/shared/components/Icons'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Delivery from '~/client/src/shared/models/Delivery'
import DeliveryCardFieldsConfigStore, {
  ICardAttribute,
} from '~/client/src/shared/stores/ui/DeliveryCardFieldsConfig.store'

import BaseExpandableWorkflowCard, {
  IBaseWorkflowCardProps,
  workflowCardInjects,
} from '../BaseExpandableWorkflowCard'

interface IProps extends IBaseWorkflowCardProps {
  delivery: Delivery

  shouldUseMockValues?: boolean

  onClick?: (delivery: Delivery) => void
}

@inject(...workflowCardInjects)
@observer
export default class DeliveryWorkflowCard extends BaseExpandableWorkflowCard<IProps> {
  private readonly cardFieldsStore: DeliveryCardFieldsConfigStore

  public constructor(props: IProps) {
    super(props)

    this.cardFieldsStore = new DeliveryCardFieldsConfigStore(
      props.delivery,
      props.state,
      props.projectDateStore,
      props.locationAttributesStore,
      props.deliveryVehicleTypesStore,
      props.offloadingEquipmentsStore,
      props.companiesStore,
      props.materialsStore,
      props.projectMembersStore,
      props.materialCategoryStore,
    )
  }

  public componentDidUpdate(prevProps: IProps) {
    const { delivery } = this.props

    if (delivery !== prevProps.delivery) {
      this.cardFieldsStore.init(delivery)
      this.store.resetIsExpanded()
    }
  }

  protected get icon(): JSX.Element {
    if (this.props.delivery.isFromConcreteDirect) {
      return (
        <div className="delivery-concrete-direct-icon row x-center y-center no-flex">
          <Icons.ConcreteDirect className="no-grow" />
        </div>
      )
    }

    return (
      <div className="delivery-workflow-card-icon bg-dark br-rounded text white row x-center y-center no-flex">
        <Icons.DeliveryFilled className="no-grow" />
      </div>
    )
  }

  protected get titleValues() {
    return (
      <>
        {this.companyField}
        {this.bookingTimeAndStatusField}
      </>
    )
  }

  protected onClick = (ev: React.SyntheticEvent) => {
    ev.stopPropagation()

    const { delivery, onClick } = this.props
    onClick?.(delivery)
  }

  protected get attributeElements(): JSX.Element[] {
    const { isFromConcreteDirect } = this.props.delivery

    return this.cardAttributeValues.map((cardAttribute, index) => (
      <React.Fragment key={cardAttribute.fieldId}>
        {this.renderAttributeField(
          {
            ...cardAttribute,
          },
          this.cardAttributeValues.length - 1 !== index,
          isFromConcreteDirect,
        )}
      </React.Fragment>
    ))
  }

  private renderAttributeField = (
    cardAttribute: ICardAttribute,
    shouldAddSeparator: boolean,
    isFromConcreteDirect?: boolean,
  ): JSX.Element => {
    const { fieldId, fieldValue, color } = cardAttribute

    return (
      <div className="row no-flex-children my2 text large">
        <div
          className={classList({
            h16: true,
            'no-fill': !!color,
          })}
          style={{ color }}
        >
          <DeliveryFieldIcon
            fieldId={fieldId}
            isConcreteDirect={isFromConcreteDirect}
          />
        </div>
        <span style={{ color }}>{fieldValue}</span>
        {shouldAddSeparator && <span className="text light mx4">•</span>}
      </div>
    )
  }

  private get companyField(): JSX.Element {
    const { shouldUseMockValues } = this.props
    const { titleCardFields, getFieldValueById } = this.cardFieldsStore

    const hasCompany = titleCardFields.some(
      cf => cf.fieldId === FieldIds.COMPANY,
    )
    const hasStatus = titleCardFields.some(cf => cf.fieldId === FieldIds.STATUS)
    const deliveryStatus = getFieldValueById(
      FieldIds.STATUS,
      shouldUseMockValues,
    )

    const companyName = getFieldValueById(FieldIds.COMPANY, shouldUseMockValues)
    const bookingId = getFieldValueById(FieldIds.ID, shouldUseMockValues)
    const shouldShowStatus = hasStatus && !!deliveryStatus

    if (!hasCompany || !companyName) {
      return null
    }

    const { delivery } = this.props

    return (
      <div className="row y-stretch x-between no-flex-children mb4 overflow-hidden">
        <span className="col row delivery-company-title text extra-large bold ellipsis">
          {companyName}
          <span className="col ml5 text light pa2 pt4">({bookingId})</span>
        </span>
        {shouldShowStatus && (
          <DeliveryStatusLabel
            status={delivery.status}
            cancellationReason={delivery.cancellationReason}
            className="col ml8 no-grow"
          />
        )}
      </div>
    )
  }

  private get bookingTimeAndStatusField(): JSX.Element {
    const { shouldUseMockValues } = this.props
    const { titleCardFields, getFieldValueById } = this.cardFieldsStore

    const hasBookingTime = titleCardFields.some(
      cf => cf.fieldId === FieldIds.BOOKING_TIME,
    )

    const bookingTime = getFieldValueById(
      FieldIds.BOOKING_TIME,
      shouldUseMockValues,
    )
    const shouldShowTime = hasBookingTime && !!bookingTime

    if (!shouldShowTime) {
      return null
    }

    const { delivery } = this.props

    return (
      <div className="row x-between mb4">
        {shouldShowTime && (
          <DeliveryDateLabel
            delivery={delivery}
            className="text-ellipsis mr4"
          />
        )}
      </div>
    )
  }

  @computed
  private get cardAttributeValues(): ICardAttribute[] {
    return this.cardFieldsStore.getCardAttributeValues(
      this.props.shouldUseMockValues,
    )
  }
}
