import { computed } from 'mobx'

import { FilterType } from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import BaseActivityFilterStore from '~/client/src/desktop/stores/ui/BaseActivityFilter.store'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ResourcesStore from '~/client/src/shared/stores/domain/Resources.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import { IRootOption } from '~/client/src/shared/stores/ui/SuperFilter.store'
import Guard from '~/client/src/shared/utils/Guard'

// localization: translated

export default class CompanyFilterStore extends BaseActivityFilterStore {
  public constructor(
    state: DesktopInitialState,
    desktopActivityListStore: DesktopActivityListStore,
    activityFiltersStore: ActivityFiltersStore,
    private readonly resourcesStore: ResourcesStore,
    companiesStore: CompaniesStore,
    userProjectsStore: UserProjectsStore,
    onShowChanged: (isShown: boolean, filterType: string) => void,
    protected readonly onClickHandler?: () => void,
  ) {
    super(
      FilterType.Company,
      state,
      desktopActivityListStore,
      null,
      activityFiltersStore,
      null,
      companiesStore,
      userProjectsStore,
      onShowChanged,
      onClickHandler,
    )
    Guard.requireAll({
      state,
      desktopActivityListStore,
      activityFiltersStore,
      resourcesStore,
    })
  }

  public get shortNameCaption() {
    return ''
  }

  public get nameCaption() {
    return Localization.translator.company
  }

  @computed
  public get optionsTree(): IRootOption[] {
    const allAssignedOptionIds = []

    const options = this.companiesStore.allCompanies.map(company => {
      const activitiesIds =
        this.activityFiltersStore.activitiesCodesByCompanyMap[company.id] || []
      allAssignedOptionIds.push(...activitiesIds)

      return { id: company.id, name: company.name, instancesIds: activitiesIds }
    })

    const optionsTree: IRootOption[] = []

    if (options.length) {
      optionsTree.push({
        id: 'company',
        name: Localization.translator.company,
        options,
      })
    }

    this.addUnassignedOption(optionsTree, allAssignedOptionIds)
    return optionsTree
  }
}
