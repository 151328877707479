import * as React from 'react'

import { Circle, Group, Path } from 'react-konva'

import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import { LEVEL_PATH } from '../LocationPaths'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_SIZE = 20

const SHADOW_BLUR = 2
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3

const ICON_OFFSET_X = 0
const ICON_OFFSET_Y = 4
const STROKE_WIDTH = 1

const PATH_SCALE_X = -0.8
const PATH_SCALE_Y = 0.8
const { getHEXColor } = ThemeMode

const KonvaLevelIcon = ({ color, children, ...rest }) => {
  const halfSize = ICON_SIZE / 2
  return (
    <Group {...rest} offsetX={halfSize} offsetY={halfSize}>
      {children}
      <Circle
        radius={halfSize}
        x={halfSize}
        y={halfSize}
        fill={getHEXColor(color)}
        stroke={getHEXColor(Colors.neutral100)}
        strokeWidth={STROKE_WIDTH}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
      <Path
        data={LEVEL_PATH}
        fill={getHEXColor(Colors.neutral100)}
        x={ICON_OFFSET_X}
        y={ICON_OFFSET_Y}
        offsetX={ICON_SIZE}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
        scaleY={PATH_SCALE_Y}
        scaleX={PATH_SCALE_X}
      />
    </Group>
  )
}

export default KonvaLevelIcon
