import * as React from 'react'

import { action } from 'mobx'
import { observer } from 'mobx-react'
import { AutoSizer, List } from 'react-virtualized'

import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import { ITag } from '~/client/src/shared/models/Tag'

// translated

interface IProps {
  tags: ITag[]
  onTagClick: (tag: ITag) => void
}

export const TAG_ROW_HEIGHT = 50

@observer
export default class TagsList extends React.Component<IProps> {
  public static noRowsRenderer() {
    return (
      <div className="row full-height px12">
        {Localization.translator.noTagMatchingYourSearch}...
      </div>
    )
  }

  public render() {
    const { tags } = this.props

    return (
      <AutoSizer>
        {({ width, height }) => (
          <List
            data={tags}
            width={width}
            height={height}
            className="tags-list"
            rowCount={tags.length}
            scrollToAlignment="start"
            rowRenderer={this.renderRow}
            rowHeight={TAG_ROW_HEIGHT}
            noRowsRenderer={TagsList.noRowsRenderer}
          />
        )}
      </AutoSizer>
    )
  }

  public renderRow = ({ key, style, index }: any) => {
    const props = { key, style }
    const tag = this.props.tags[index]

    return (
      <div
        {...props}
        className="row px10 pointer"
        onClick={this.handleRowClick.bind(this, tag)}
      >
        <span>
          <SitemapAttributeTag
            shouldShowAsTag={true}
            contentContainerClassName="text-ellipsis py2"
            dataObject={tag as LocationAttributeBase}
          >
            <span title={tag.name} className="text large">
              {tag.name}
            </span>
          </SitemapAttributeTag>
        </span>
      </div>
    )
  }

  @action.bound
  private handleRowClick(
    tag: LocationAttributeBase,
    e: React.MouseEvent<HTMLDivElement>,
  ) {
    e.stopPropagation()
    this.props.onTagClick(tag)
  }
}
