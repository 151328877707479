import { computed } from 'mobx'

import { ISiteLocation, LocationType } from '~/client/graph'

import LocationBase from '../../models/LocationObjects/LocationBase'
import AreasStore from './Areas.store'
import BuildingsStore from './Buildings.store'
import GatesStore from './Gates.store'
import InteriorDoorsStore from './InteriorDoors.store'
import InteriorPathsStore from './InteriorPaths.store'
import LevelsStore from './Levels.store'
import LocationBaseStore from './LocationBase.store'
import LocationIntegrationsStore from './LocationIntegrations.store'
import LogisticsObjectsStore from './LogisticsObjects.store'
import OffloadingEquipmentsStore from './OffloadingEquipments.store'
import RoutesStore from './Routes.store'
import StagingsStore from './Stagings.store'
import VerticalObjectsStore from './VerticalObjects.store'
import ZonesStore from './Zones.store'

// Purpose of this store is easier injecting all location
// attribute related stores
export default class LocationAttributesStore {
  public constructor(
    public readonly gatesStore: GatesStore,
    public readonly zonesStore: ZonesStore,
    public readonly buildingsStore: BuildingsStore,
    public readonly routesStore: RoutesStore,
    public readonly offloadingEquipmentsStore: OffloadingEquipmentsStore,
    public readonly stagingsStore: StagingsStore,
    public readonly interiorDoorsStore: InteriorDoorsStore,
    public readonly interiorPathsStore: InteriorPathsStore,
    public readonly levelsStore: LevelsStore,
    public readonly areasStore: AreasStore,
    public readonly logisticsObjectsStore: LogisticsObjectsStore,
    public readonly locationIntegrationsStore: LocationIntegrationsStore,
    public readonly verticalObjectsStore: VerticalObjectsStore,

    private readonly storeByLocationTypeMap = {
      [LocationType.Gate]: gatesStore,
      [LocationType.Zone]: zonesStore,
      [LocationType.Building]: buildingsStore,
      [LocationType.Route]: routesStore,
      [LocationType.OffloadingEquipment]: offloadingEquipmentsStore,
      [LocationType.Staging]: stagingsStore,
      [LocationType.InteriorDoor]: interiorDoorsStore,
      [LocationType.InteriorPath]: interiorPathsStore,
      [LocationType.Level]: levelsStore,
      [LocationType.Area]: areasStore,
      [LocationType.LogisticsObject]: logisticsObjectsStore,
      [LocationType.VerticalObject]: verticalObjectsStore,
      [LocationType.Integration]: locationIntegrationsStore,
    },
  ) {}

  @computed
  public get allAttributes(): LocationBase[] {
    return [
      ...this.buildingsStore.list,
      ...this.zonesStore.list,
      ...this.gatesStore.list,
      ...this.routesStore.list,
      ...this.offloadingEquipmentsStore.list,
      ...this.stagingsStore.list,
      ...this.interiorDoorsStore.list,
      ...this.interiorPathsStore.list,
      ...this.levelsStore.list,
      ...this.areasStore.list,
      ...this.logisticsObjectsStore.list,
      ...this.locationIntegrationsStore.list,
      ...this.verticalObjectsStore.list,
    ]
  }

  public getSortedAllAttributes(
    sortAttributes: (a: LocationBase, b: LocationBase) => number,
  ) {
    return [
      ...this.buildingsStore.list.sort(sortAttributes),
      ...this.zonesStore.list.sort(sortAttributes),
      ...this.gatesStore.list.sort(sortAttributes),
      ...this.routesStore.list.sort(sortAttributes),
      ...this.offloadingEquipmentsStore.list.sort(sortAttributes),
      ...this.stagingsStore.list.sort(sortAttributes),
      ...this.interiorDoorsStore.list.sort(sortAttributes),
      ...this.interiorPathsStore.list.sort(sortAttributes),
      ...this.levelsStore.list.sort(sortAttributes),
      ...this.areasStore.list.sort(sortAttributes),
      ...this.logisticsObjectsStore.list.sort(sortAttributes),
      ...this.locationIntegrationsStore.list.sort(sortAttributes),
      ...this.verticalObjectsStore.list.sort(sortAttributes),
    ]
  }

  @computed
  public get hierarchyAllAttributesMap(): {
    [key: string]: LocationBase
  } {
    return this.allAttributes.reduce((acc, item) => {
      acc[item.id] = item
      return acc
    }, {})
  }

  @computed
  public get allLocationsWithoutEquipment() {
    return [
      ...this.buildingsStore.list,
      ...this.zonesStore.list,
      ...this.gatesStore.list,
      ...this.routesStore.list,
      ...this.stagingsStore.list,
      ...this.interiorDoorsStore.list,
      ...this.interiorPathsStore.list,
      ...this.levelsStore.list,
      ...this.areasStore.list,
      ...this.logisticsObjectsStore.list,
      ...this.locationIntegrationsStore.list,
      ...this.verticalObjectsStore.list,
    ]
  }

  @computed
  public get allAttributesWithDeletedItems(): LocationBase[] {
    return [
      ...this.buildingsStore.listWithDeletedItems,
      ...this.zonesStore.listWithDeletedItems,
      ...this.gatesStore.listWithDeletedItems,
      ...this.routesStore.listWithDeletedItems,
      ...this.offloadingEquipmentsStore.listWithDeletedItems,
      ...this.stagingsStore.listWithDeletedItems,
      ...this.interiorDoorsStore.listWithDeletedItems,
      ...this.interiorPathsStore.listWithDeletedItems,
      ...this.levelsStore.listWithDeletedItems,
      ...this.areasStore.listWithDeletedItems,
      ...this.logisticsObjectsStore.listWithDeletedItems,
      ...this.locationIntegrationsStore.listWithDeletedItems,
      ...this.verticalObjectsStore.listWithDeletedItems,
    ]
  }

  @computed
  public get attributesWithDeletedWithoutEquipment(): LocationBase[] {
    return [
      ...this.buildingsStore.listWithDeletedItems,
      ...this.zonesStore.listWithDeletedItems,
      ...this.gatesStore.listWithDeletedItems,
      ...this.routesStore.listWithDeletedItems,
      ...this.stagingsStore.listWithDeletedItems,
      ...this.interiorDoorsStore.listWithDeletedItems,
      ...this.interiorPathsStore.listWithDeletedItems,
      ...this.levelsStore.listWithDeletedItems,
      ...this.areasStore.listWithDeletedItems,
      ...this.logisticsObjectsStore.listWithDeletedItems,
      ...this.locationIntegrationsStore.listWithDeletedItems,
      ...this.verticalObjectsStore.listWithDeletedItems,
    ]
  }

  @computed
  public get isDataReceived() {
    return (
      this.buildingsStore.isDataReceived &&
      this.zonesStore.isDataReceived &&
      this.gatesStore.isDataReceived &&
      this.routesStore.isDataReceived &&
      this.offloadingEquipmentsStore.isDataReceived &&
      this.stagingsStore.isDataReceived &&
      this.interiorDoorsStore.isDataReceived &&
      this.interiorPathsStore.isDataReceived &&
      this.levelsStore.isDataReceived &&
      this.areasStore.isDataReceived &&
      this.logisticsObjectsStore.isDataReceived &&
      this.locationIntegrationsStore.isDataReceived &&
      this.verticalObjectsStore.isDataReceived
    )
  }

  public getById = (id: string) => {
    const stores = [
      this.buildingsStore,
      this.zonesStore,
      this.gatesStore,
      this.routesStore,
      this.offloadingEquipmentsStore,
      this.stagingsStore,
      this.interiorDoorsStore,
      this.interiorPathsStore,
      this.levelsStore,
      this.areasStore,
      this.logisticsObjectsStore,
      this.locationIntegrationsStore,
      this.verticalObjectsStore,
    ]

    let obj: LocationBase
    stores.find(store => (obj = store.byId.get(id)))
    return obj
  }

  public getLocationBySiteLocationObj(siteLocation: ISiteLocation) {
    if (!siteLocation) return null

    return this.getStoreByLocationType(siteLocation.type).getInstanceById(
      siteLocation.id,
    )
  }

  private getStoreByLocationType(
    locationType: LocationType,
  ): LocationBaseStore<LocationBase> {
    const store = this.storeByLocationTypeMap[locationType]

    if (store) {
      return store
    } else {
      throw new Error(`Store for type ["${locationType}"] is not defined`)
    }
  }
}
