import { ContentObjectStatus, IContentObject } from '~/client/graph'

import Localization from '../../shared/localization/LocalizationManager'
import ContentObjectModel from './ContentObjectModel'

export enum SafetyHazardTypes {
  NULL = 'NULL',
  ELEVATED_WORK = 'ELEVATED_WORK',
  MATERIAL_HANDLING = 'MATERIAL_HANDLING',
  GROUND_DISTRIBUTION = 'GROUND_DISTRIBUTION',
  CRANE_OR_HEAVY_LIFT = 'CRANE_OR_HEAVY_LIFT',
  CONFINED_SPACE = 'CONFINED_SPACE',
  MOBILE_EQUIPMENT = 'MOBILE_EQUIPMENT',
  DELIVER = 'DELIVER',
  LOTO = 'LOTO',
  HOT_WORK = 'HOT_WORK',
  HAZARDOUS_MATERIAL = 'HAZARDOUS_MATERIAL',
  HIGH_VOLTAGE = 'HIGH_VOLTAGE',
  HIGH_DECIBELS = 'HIGH_DECIBELS',
  LIFTING = 'LIFTING',
  CLOSE_QUARTERS = 'CLOSE_QUARTERS',
}

export default class SafetyHazard extends ContentObjectModel<SafetyHazardTypes> {
  public static none: SafetyHazard
  public static CreateNullSafetyHazard(): SafetyHazard {
    const f = new SafetyHazard('')
    f.author = ''
    f.status = ContentObjectStatus.Open
    f.threadId = ''

    return f
  }

  public static getSafetyHazardTypeCaption(type: SafetyHazardTypes): string {
    const caption =
      Localization.translator.safetyHazard +
      ': ' +
      SafetyHazard.getDisplayType(type)
    return caption.toUpperCase()
  }

  public static getOpenedCaption(type: SafetyHazardTypes): string {
    const caption =
      Localization.translator.safetyHazardOpened +
      ': ' +
      SafetyHazard.getDisplayType(type)
    return caption.toUpperCase()
  }

  public static getDisplayType(type: SafetyHazardTypes): string {
    switch (type) {
      case SafetyHazardTypes.NULL:
        return Localization.translator.unspecifiedType
      case SafetyHazardTypes.ELEVATED_WORK:
        return Localization.translator.elevatedWork
      case SafetyHazardTypes.MATERIAL_HANDLING:
        return Localization.translator.materialHandling
      case SafetyHazardTypes.GROUND_DISTRIBUTION:
        return Localization.translator.groundDistribution
      case SafetyHazardTypes.CRANE_OR_HEAVY_LIFT:
        return Localization.translator.craneOrHeavyLift
      case SafetyHazardTypes.CONFINED_SPACE:
        return Localization.translator.confinedSpace
      case SafetyHazardTypes.MOBILE_EQUIPMENT:
        return Localization.translator.mobileEquipment
      case SafetyHazardTypes.DELIVER:
        return Localization.translator.deliver
      case SafetyHazardTypes.LOTO:
        return Localization.translator.loto
      case SafetyHazardTypes.HOT_WORK:
        return Localization.translator.hotWork
      case SafetyHazardTypes.HAZARDOUS_MATERIAL:
        return Localization.translator.hazardousMaterial
      case SafetyHazardTypes.HIGH_VOLTAGE:
        return Localization.translator.highVoltage
      case SafetyHazardTypes.HIGH_DECIBELS:
        return Localization.translator.highDecibels
      case SafetyHazardTypes.LIFTING:
        return Localization.translator.lifting
      case SafetyHazardTypes.CLOSE_QUARTERS:
        return Localization.translator.closeQuarters
    }
  }

  public constructor(id: string, dto?: IContentObject) {
    super(id, dto)
    this.type = dto ? (dto.type as SafetyHazardTypes) : SafetyHazardTypes.NULL
  }
}

SafetyHazard.none = SafetyHazard.CreateNullSafetyHazard()
