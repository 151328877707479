import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import { ISiteLocation } from '~/client/graph'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import { Loader } from '~/client/src/shared/components/Loader'
import WorkflowCardLocationLabel from '~/client/src/shared/components/WorkflowCard/LocationLabel'
import Scanner from '~/client/src/shared/models/Scanner'
import ScannersStore from '~/client/src/shared/stores/domain/Scanners.store'

import CommonStore from '../../../stores/ui/Common.store'
import QrCodesStore from '../QRCodes.store'
import QRCodesViewEditForm from './QRCodesViewEditForm/QRCodesViewEditForm'

interface IProps {
  store: QrCodesStore

  isFullscreenAllowed: boolean

  common?: CommonStore
  scannersStore?: ScannersStore
}

@inject('common', 'scannersStore')
@observer
export default class QRCodesContent extends React.Component<IProps> {
  public componentDidMount(): void {
    this.props.common.hideNavBar()
  }

  public render(): JSX.Element {
    const { store, scannersStore, isFullscreenAllowed } = this.props

    if (store.isLoading && !store.isActive) {
      return <Loader />
    }

    if (store.scanner) {
      return (
        <QRCodesViewEditForm
          store={store}
          isFullscreenAllowed={isFullscreenAllowed}
        />
      )
    }

    return (
      <div className="col relative">
        <div className="row pa10">
          <BaseActionButton
            title="Create new"
            className="secondary-blue-theme bold"
            isEnabled={true}
            onClick={store.createNewScanner}
          />
        </div>
        {this.renderScannersList(scannersStore.scannersList)}
      </div>
    )
  }

  private renderScannersList(scanners: Scanner[]): JSX.Element {
    return (
      <div className="col scrollable qr-items-list">
        {scanners.map(scanner => this.renderScannerListItem(scanner))}
      </div>
    )
  }

  private renderScannerListItem(scanner: Scanner): JSX.Element {
    const { selectScanner } = this.props.store
    const locations: ISiteLocation = scanner.isRealLocationRelated &&
      scanner.location && {
        id: scanner.location.id,
        type: scanner.location.type,
      }

    return (
      <div
        key={scanner.id}
        className="pa12 bb-light-grey qr-small-holder text large overflow-hidden"
        onClick={selectScanner.bind(null, scanner)}
      >
        <div className="col">
          <div className="row pt5 title-row pb10">
            <Icon
              icon={IconNames.SEARCH_TEMPLATE}
              className="no-grow mr4 main-icon announcement-icon"
            />
            <span className="bold extra-large inline-block text title-text vertical-align-text-bottom">
              {scanner.name}
            </span>
            {locations && (
              <div className="pt5 nowrap pl20">
                <WorkflowCardLocationLabel
                  locations={[locations]}
                  isOneColor={false}
                  shouldShowAsTag={true}
                />
              </div>
            )}
          </div>
          <span className="large inline-block text title-text vertical-align-text-bottom">
            {scanner.badgeName}
          </span>
        </div>
      </div>
    )
  }
}
