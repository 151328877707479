import Delta from 'quill-delta'

import {
  VALID_VIMEO_VIDEO_PATTERN,
  VALID_YOUTUBE_VIDEO_PATTERN,
} from './regExpPatterns'

export default class QuillVideoParser {
  private quill: any
  private options: any

  public constructor(quill, options) {
    this.quill = quill
    this.options = options

    quill.on('text-change', this.update.bind(this))
    this.update()
  }

  private update(delta?: any) {
    const ops = delta?.ops
    if (!ops || ops.length < 1 || ops.length > 2) {
      return
    }

    const link = ops.filter(o => o?.attributes?.link && o?.insert)?.[0]?.insert

    if (!link) {
      return
    }

    const isVideoLink = [
      VALID_YOUTUBE_VIDEO_PATTERN,
      VALID_VIMEO_VIDEO_PATTERN,
    ].some(p => p.test(link))

    if (!isVideoLink) {
      return
    }

    const { index } = this.quill.getSelection(true)

    const newDelta = new Delta()
      .retain(index)
      .insert({ video: link })
      .delete(link.length)

    this.quill.updateContents(newDelta)
  }
}
