import { ObservableMap, action, computed, observable } from 'mobx'

import * as e from '~/client/src/shared/stores/EventStore/eventConstants'

import { ALLOWED_SITEMAP_ITEMS_WITHOUT_DATA_OBJECT } from '../components/MapBoxEditor/MapBoxViewer.store'
import MapViewItemBase from '../components/SitemapHelpers/models/MapViewItemBase'
import MapViewItemFactory from '../components/SitemapHelpers/models/MapViewItemFactory'
import GlobeView from '../models/GlobeView'
import EventsStore from './EventStore/Events.store'
import InitialState from './InitialState'
import GlobeViewsStore from './domain/GlobeViews.store'
import LocationAttributesStore from './domain/LocationAttributes.store'
import SitemapItemsStore from './domain/SitemapItems.store'

export const UNTITLED = 'Untitled'
export const DEFAULT_NAME = 'New Globe'

export default class GlobeViewControlStore {
  @observable public selectedGlobeViewId: string = null

  public constructor(
    protected readonly eventsStore: EventsStore,
    protected readonly globeViewsStore: GlobeViewsStore,
    protected readonly sitemapItemsStore: SitemapItemsStore,
    protected readonly locationAttributesStore: LocationAttributesStore,
  ) {}

  public get appState(): InitialState {
    return this.eventsStore.appState
  }

  @computed
  public get selectedGlobeViewItems(): MapViewItemBase[] {
    const items = []
    this.selectedGlobeView?.items?.forEach(item => {
      const sitemapItem = this.sitemapItemsStore.byId.get(item.sitemapItemId)

      const dataObject =
        this.locationAttributesStore.hierarchyAllAttributesMap[
          sitemapItem?.assignedId
        ]
      if (
        !dataObject &&
        sitemapItem &&
        ALLOWED_SITEMAP_ITEMS_WITHOUT_DATA_OBJECT.includes(sitemapItem.type)
      ) {
        const sItem = MapViewItemFactory.fromGlobeItem(
          null,
          sitemapItem.copy(),
          this.selectedGlobeView,
        )

        items.push(sItem)
      } else if (dataObject && sitemapItem) {
        const sItem = MapViewItemFactory.fromGlobeItem(
          dataObject.copy(),
          sitemapItem.copy(),
          this.selectedGlobeView,
        )

        items.push(sItem)
      } else {
        return
      }
    })

    return items
  }

  public selectInitialGlobe = (globe?: GlobeView): void => {
    this.selectGlobe(globe)
  }

  public get isLoading(): boolean {
    return (
      this.appState.loading.get(e.SAVE_GLOBE_VIEW) ||
      this.appState.loading.get(e.DELETE_GLOBE_VIEW)
    )
  }

  @computed
  public get allGlobeViews(): GlobeView[] {
    return this.globeViewsStore.list.slice()
  }

  @computed
  public get globeViewsMap(): ObservableMap<string, GlobeView> {
    return this.globeViewsStore.byId
  }

  @computed
  public get selectedGlobeView(): GlobeView {
    return this.globeViewsStore.byId.get(this.selectedGlobeViewId)
  }

  @computed
  public get globeViewsList(): GlobeView[] {
    return this.globeViewsStore.list
  }

  @action.bound
  public selectGlobeById(globeId: string): void {
    const globe = this.globeViewsStore.byId.get(globeId)
    this.selectGlobe(globe)
  }

  @action.bound
  public selectGlobe(globe: GlobeView): void {
    this.deselectGlobe()
    if (!globe) {
      return
    }

    this.selectedGlobeViewId = globe.id
  }

  @action.bound
  public deselectGlobe(): void {
    this.selectedGlobeViewId = null
  }
}
