import Localization from '~/client/src/shared/localization/LocalizationManager'

import ConcreteDirectPayloadStatus from '../enums/ConcreteDirectPayloadStatus'

export default function formatPayloadStatusToDisplay(
  status: ConcreteDirectPayloadStatus,
): string {
  switch (status) {
    case ConcreteDirectPayloadStatus.Ticketed:
      return Localization.translator.ticketed
    case ConcreteDirectPayloadStatus.Scheduled:
      return Localization.translator.scheduled
    case ConcreteDirectPayloadStatus.Recalled:
      return Localization.translator.recalled
    case ConcreteDirectPayloadStatus.Cancelled:
      return Localization.translator.canceled
    case ConcreteDirectPayloadStatus.EnRoute:
      return Localization.translator.enRoute
    case ConcreteDirectPayloadStatus.OnSite:
      return Localization.translator.onSite
    case ConcreteDirectPayloadStatus.Loading:
      return Localization.translator.loading
    case ConcreteDirectPayloadStatus.Pouring:
      return Localization.translator.pouring
    case ConcreteDirectPayloadStatus.Delivered:
      return Localization.translator.delivered
  }
}
