import { action, computed } from 'mobx'

import { DeliveryFilterType, DeliveryStatus } from '~/client/graph'
import { UNASSIGNED_FILTER_OPTION } from '~/client/src/shared/components/Deliveries/DeliveriesView.store'
import { deliveryFilterTypes } from '~/client/src/shared/enums/DeliveryFilterType'
import { LocalStorageKey } from '~/client/src/shared/enums/LocalStorageKey'
import { getDeliveryFilterTypeDisplayName } from '~/client/src/shared/localization/enumDisplayTexts'
import Delivery from '~/client/src/shared/models/Delivery'
import { RESET_ALL_FILTERS } from '~/client/src/shared/stores/EventStore/eventConstants'
import { enumToList } from '~/client/src/shared/utils/converters'

import BaseReportsFilterStore, { ISourceMap } from './BaseReportsFilter.store'
import ReportsFilterStore from './ReportsFilter.store'

const EXCLUDED_STATUSES = [DeliveryStatus.Deleted]

export default class DeliveryReportFilterStore extends ReportsFilterStore {
  protected get availableInstances(): Delivery[] {
    return this.instancesStore.allDeliveries
  }

  @computed
  public get filterStoresByTypeMap(): {
    [filterType: string]: BaseReportsFilterStore
  } {
    const map: { [filterType: string]: BaseReportsFilterStore } = {}

    deliveryFilterTypes.forEach(filterType => {
      const { appState } = this.eventsStore

      map[filterType] = new BaseReportsFilterStore(
        filterType,
        appState,
        this.sourceMapByFilterTypeMap[filterType],
        this.instancesListStore,
        this.onShowChanged,
        this.fieldsMap(),
        getDeliveryFilterTypeDisplayName,
        this.getOptionName,
        LocalStorageKey.DeliveryReportReportFilter,
      )
    })
    return map
  }

  @action.bound
  public resetAllFilters() {
    deliveryFilterTypes.forEach(type =>
      localStorage.removeItem(
        `${this.eventsStore.appState.activeProject.id}_${LocalStorageKey.DeliveryReportReportFilter}_${type}`,
      ),
    )
    this.eventsStore.dispatch(RESET_ALL_FILTERS)
  }

  @computed
  protected get sourceMapByFilterTypeMap(): {
    [filterType: string]: ISourceMap
  } {
    const maps = deliveryFilterTypes.reduce((acc, filterType) => {
      acc[filterType] = this.getDefaultSourceMapByType(filterType)
      return acc
    }, {})

    this.availableInstances.forEach(instance => {
      Object.keys(maps).forEach(filterType => {
        const map = maps[filterType]
        const optionIds = this.getOptionsIdsByFilterType(instance, filterType)

        optionIds.forEach(optionId => {
          map[optionId]?.push(instance.id)
        })
      })
    })
    return maps
  }

  public getOptionsIdsByFilterType(
    delivery: Delivery,
    filterType: string,
  ): string[] {
    switch (filterType) {
      case DeliveryFilterType.Company:
        return [delivery.company || UNASSIGNED_FILTER_OPTION]
      case DeliveryFilterType.Status:
        return [delivery.status]
    }
  }

  protected getDefaultSourceMapByType(type: DeliveryFilterType) {
    let sourceList: string[] = []

    switch (type) {
      case DeliveryFilterType.Status:
        sourceList = enumToList(DeliveryStatus).filter(
          s => !EXCLUDED_STATUSES.includes(s),
        )
        break
      case DeliveryFilterType.Company:
        sourceList = [
          ...this.companiesStore.allCompaniesIds,
          UNASSIGNED_FILTER_OPTION,
        ]
        break
    }

    return sourceList.reduce((acc, optionId) => {
      acc[optionId] = []
      return acc
    }, {})
  }

  protected fieldsMap() {
    return this.eventsStore.appState.deliveryReportFilters.fieldsMap
  }
}
