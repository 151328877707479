import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import './Tabs.scss'

export interface ITabObject<T> {
  title: string
  page: T
  isHidden?: boolean
  isDisabled?: boolean
  icon?: JSX.Element
  className?: string
}

interface IProps<T> {
  tabObjects: Array<ITabObject<T>>
  activeTabId: T
  onTabClick: (tabId: T) => void
  className?: string
  tabTextClassName?: string
  tabClassName?: string
  isFlexible?: boolean
}

@observer
export default class Tabs<T> extends React.Component<IProps<T>> {
  public static defaultProps = {
    tabTextClassName: 'text center extra-large ellipsis px5',
  }

  public render() {
    const {
      tabClassName,
      tabObjects,
      activeTabId,
      className,
      onTabClick,
      tabTextClassName,
      isFlexible,
    } = this.props

    return (
      <div
        className={classList({
          'row tabs-container': true,
          [className]: !!className,
        })}
      >
        {tabObjects
          .filter(tab => !tab.isHidden)
          .map(({ title, page, icon, className, isDisabled }, idx) => {
            return (
              <div
                key={idx}
                className={classList({
                  'row x-center y-center pointer': true,
                  'no-flex': !isFlexible,
                  'inactive-element': isDisabled,
                  active: page === activeTabId,
                  [tabClassName]: !!tabClassName,
                  [className]: !!className,
                })}
                onClick={onTabClick.bind(this, page)}
              >
                {icon}
                <span className={tabTextClassName}>{title}</span>
              </div>
            )
          })}
      </div>
    )
  }
}
