import { action, observable } from 'mobx'
import { Socket } from 'socket.io-client'

import ChatService from '../../services/ChatService/Chat.service'
import ChatEvent from '../../services/ChatService/events'
import { IChat } from '../../services/ChatService/types'
import Guard from '../../utils/Guard'

export default class ChatStore {
  @observable public shouldShowChatCreationMenu: boolean = false
  @observable public selectedInboxItem: IChat = null

  @observable public onlineContacts: Set<string> = new Set()

  public constructor(private readonly chatService: ChatService) {
    Guard.requireAll({ chatService })

    this.registerListeners()
  }

  public async init() {
    this.onlineContacts = new Set(await this.chatService.getOnlineContacts())
  }

  @action.bound
  public showChatCreationMenu() {
    this.shouldShowChatCreationMenu = true
  }

  @action.bound
  public hideChatCreationMenu() {
    this.shouldShowChatCreationMenu = false
  }

  @action.bound
  public handleChatOpenCb(item: IChat) {
    this.selectInboxItem(item)
    this.hideChatCreationMenu()
  }

  @action.bound
  public selectInboxItem(item: IChat) {
    this.selectedInboxItem = item
  }

  @action.bound
  public async resetChannel() {
    this.selectedInboxItem = null
  }

  public removeListeners() {
    this.listenersMatrix.forEach(listener => this.socket.off(...listener))
  }

  private registerListeners() {
    this.listenersMatrix.forEach(listener => this.socket.on(...listener))
  }

  private onContactJoined = (userId: string) => {
    this.onlineContacts.add(userId)
  }

  private onContactLeft = (userId: string) => {
    this.onlineContacts.delete(userId)
  }

  private get listenersMatrix(): [string, (value: any) => void][] {
    return [
      [ChatEvent.ContactJoined, this.onContactJoined],
      [ChatEvent.ContactLeft, this.onContactLeft],
    ]
  }

  private get socket(): Socket {
    return this.chatService.socket
  }
}
