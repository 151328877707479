/**
 * [values] represent the sort coefficient for convenience
 * DEFAULT = 2 - to diff to ASC in comparison statement
 */
enum SortOrder {
  ASC = 1,
  DESC = -1,
  DEFAULT = 2,
}

export default SortOrder
