import * as React from 'react'

import DesktopCalendarColumn from '../DesktopCalendarColumn'
import ColumnTitle from './ColumnTitle'

// localization: no display text to translate

interface IProps {
  calendarColumns: DesktopCalendarColumn[]
  setRef(ref: HTMLDivElement): void
}

export default class CalendarHeader extends React.Component<IProps> {
  public render() {
    const { setRef, calendarColumns } = this.props

    return (
      <div className="day-labels-container pb10 row" ref={setRef}>
        {calendarColumns.map((column, idx) => (
          <ColumnTitle key={idx} calendarColumn={column} />
        ))}
      </div>
    )
  }
}
