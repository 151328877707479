export enum DeliveryStatusListView {
  Canceled = 'Canceled',
  Requested = 'Requested',
  Changed = 'Changed',
  Scheduled = 'Scheduled',
  OnSite = 'OnSite',
  Inspection = 'Inspection',
  IncompleteDone = 'IncompleteDone',
  Done = 'Done',
  Deleted = 'Deleted',
  Denied = 'Denied',
  Delivering = 'Delivering',
  Paused = 'Paused',
  OnHold = 'OnHold',
}
