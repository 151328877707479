import { ContentObjectStatus, IContentObject } from '~/client/graph'

import Localization from '../../shared/localization/LocalizationManager'
import ContentObjectModel from './ContentObjectModel'

export enum flagTypes {
  NULL = 'NULL',
  SITE_NOT_READY = 'SITE_NOT_READY',
  MATERIAL_PROBLEM = 'MATERIAL_PROBLEM',
  LABOR_ISSUE = 'LABOR_ISSUE',
  QUALITY_ISSUE = 'QUALITY_ISSUE',
}

export default class Flag extends ContentObjectModel<flagTypes> {
  public static none: Flag
  public static CreateNullFlag(): Flag {
    const f = new Flag('')
    f.author = ''
    f.status = ContentObjectStatus.Open
    f.threadId = ''

    return f
  }

  public static getFlagTypeCaption(type: flagTypes): string {
    const caption =
      Localization.translator.flag + ': ' + Flag.getDisplayType(type)
    return caption.toUpperCase()
  }

  public static getOpenedCaption(type: flagTypes): string {
    const caption =
      Localization.translator.flagOpened + ': ' + Flag.getDisplayType(type)
    return caption.toUpperCase()
  }

  public static getDisplayType(type: flagTypes): string {
    switch (type) {
      case flagTypes.NULL:
        return Localization.translator.unspecifiedType
      case flagTypes.SITE_NOT_READY:
        return Localization.translator.siteNotReady
      case flagTypes.MATERIAL_PROBLEM:
        return Localization.translator.materialProblem
      case flagTypes.LABOR_ISSUE:
        return Localization.translator.laborIssue
      case flagTypes.QUALITY_ISSUE:
        return Localization.translator.qualityIssue
    }
  }

  public constructor(id: string, dto?: IContentObject) {
    super(id, dto)
    this.type = dto ? (dto.type as flagTypes) : flagTypes.NULL
  }
}

Flag.none = Flag.CreateNullFlag()
