import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IFieldsActivityFiltersSettingsFragment = Pick<
  Types.IActivityFiltersSettings,
  'id' | 'hiddenActivityCodeIds' | 'hiddenActivityCodeTypesIds'
> & {
  filterInfos?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<
          Types.IFilterInfo,
          | 'name'
          | 'canBeDisabled'
          | 'caption'
          | 'codeTypeIds'
          | 'hiddenCodeIds'
          | 'index'
          | 'isDisabled'
          | 'isRemovable'
          | 'sourceType'
        >
      >
    >
  >
}

export type IActivityFiltersSettingsByScheduleIdQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars['ObjectId']
}>

export type IActivityFiltersSettingsByScheduleIdQuery = {
  activityFiltersSettings?: Types.Maybe<IFieldsActivityFiltersSettingsFragment>
}

export type ISaveActivityFiltersSettingsMutationVariables = Types.Exact<{
  activityFiltersSettings: Types.IActivityFiltersSettingsInput
}>

export type ISaveActivityFiltersSettingsMutation = {
  saveActivityFiltersSettings?: Types.Maybe<
    Pick<Types.IActivityFiltersSettings, 'id'>
  >
}

export const FieldsActivityFiltersSettingsFragmentDoc = gql`
  fragment FieldsActivityFiltersSettings on ActivityFiltersSettings {
    id
    hiddenActivityCodeIds
    hiddenActivityCodeTypesIds
    filterInfos {
      name
      canBeDisabled
      caption
      codeTypeIds
      hiddenCodeIds
      index
      isDisabled
      isRemovable
      sourceType
    }
  }
`
export const ActivityFiltersSettingsByScheduleIdDocument = gql`
  query ActivityFiltersSettingsByScheduleId($scheduleId: ObjectId!) {
    activityFiltersSettings(scheduleId: $scheduleId) {
      ...FieldsActivityFiltersSettings
    }
  }
  ${FieldsActivityFiltersSettingsFragmentDoc}
`

/**
 * __useActivityFiltersSettingsByScheduleIdQuery__
 *
 * To run a query within a React component, call `useActivityFiltersSettingsByScheduleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityFiltersSettingsByScheduleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityFiltersSettingsByScheduleIdQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useActivityFiltersSettingsByScheduleIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IActivityFiltersSettingsByScheduleIdQuery,
    IActivityFiltersSettingsByScheduleIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IActivityFiltersSettingsByScheduleIdQuery,
    IActivityFiltersSettingsByScheduleIdQueryVariables
  >(ActivityFiltersSettingsByScheduleIdDocument, options)
}
export function useActivityFiltersSettingsByScheduleIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IActivityFiltersSettingsByScheduleIdQuery,
    IActivityFiltersSettingsByScheduleIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IActivityFiltersSettingsByScheduleIdQuery,
    IActivityFiltersSettingsByScheduleIdQueryVariables
  >(ActivityFiltersSettingsByScheduleIdDocument, options)
}
export type ActivityFiltersSettingsByScheduleIdQueryHookResult = ReturnType<
  typeof useActivityFiltersSettingsByScheduleIdQuery
>
export type ActivityFiltersSettingsByScheduleIdLazyQueryHookResult = ReturnType<
  typeof useActivityFiltersSettingsByScheduleIdLazyQuery
>
export type ActivityFiltersSettingsByScheduleIdQueryResult = Apollo.QueryResult<
  IActivityFiltersSettingsByScheduleIdQuery,
  IActivityFiltersSettingsByScheduleIdQueryVariables
>
export const SaveActivityFiltersSettingsDocument = gql`
  mutation SaveActivityFiltersSettings(
    $activityFiltersSettings: ActivityFiltersSettingsInput!
  ) {
    saveActivityFiltersSettings(
      activityFiltersSettings: $activityFiltersSettings
    ) {
      id
    }
  }
`
export type ISaveActivityFiltersSettingsMutationFn = Apollo.MutationFunction<
  ISaveActivityFiltersSettingsMutation,
  ISaveActivityFiltersSettingsMutationVariables
>

/**
 * __useSaveActivityFiltersSettingsMutation__
 *
 * To run a mutation, you first call `useSaveActivityFiltersSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveActivityFiltersSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveActivityFiltersSettingsMutation, { data, loading, error }] = useSaveActivityFiltersSettingsMutation({
 *   variables: {
 *      activityFiltersSettings: // value for 'activityFiltersSettings'
 *   },
 * });
 */
export function useSaveActivityFiltersSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveActivityFiltersSettingsMutation,
    ISaveActivityFiltersSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveActivityFiltersSettingsMutation,
    ISaveActivityFiltersSettingsMutationVariables
  >(SaveActivityFiltersSettingsDocument, options)
}
export type SaveActivityFiltersSettingsMutationHookResult = ReturnType<
  typeof useSaveActivityFiltersSettingsMutation
>
export type SaveActivityFiltersSettingsMutationResult =
  Apollo.MutationResult<ISaveActivityFiltersSettingsMutation>
export type SaveActivityFiltersSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveActivityFiltersSettingsMutation,
    ISaveActivityFiltersSettingsMutationVariables
  >
