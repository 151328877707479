import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import GlobeView from '~/client/src/shared/models/GlobeView'
import Sitemap from '~/client/src/shared/models/Sitemap'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { SAVE_DELIVERY_CONFIGURATIONS } from '~/client/src/shared/stores/EventStore/eventConstants'
import GlobeViewControlStore from '~/client/src/shared/stores/GlobeViewControl.store'
import InitialState from '~/client/src/shared/stores/InitialState'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import FileInputBase from '../../../FileInput/FileInput'
import MapBoxViewerStore from '../../../MapBoxEditor/MapBoxViewer.store'
import MapViewsGallery from '../../../SitemapsGallery/MapViewsGallery'
import DeliveriesViewStore from '../../DeliveriesView.store'
import DeliveriesMapViewStore from './DeliveriesMapView.store'
import MobileDeliveryMapView from './components/MobileDeliveryMapView'

export interface IProps {
  currentDate: Date
  deliveriesViewStore: DeliveriesViewStore
  FileInputType: typeof FileInputBase

  state?: InitialState
  sitemapsStore?: SitemapsStore
  sitemapItemsStore?: SitemapItemsStore
  locationAttributesStore?: LocationAttributesStore

  globeViewsStore?: GlobeViewsStore
  eventsStore?: EventsStore
  userProjectsStore?: UserProjectsStore
  basemapsStore?: BasemapsStore

  isCompactMode?: boolean
  tagsStore?: TagsStore
}

@inject(
  'locationAttributesStore',
  'sitemapItemsStore',
  'sitemapsStore',
  'state',
  'globeViewsStore',
  'eventsStore',
  'userProjectsStore',
  'basemapsStore',
  'tagsStore',
)
@observer
export default class DeliveriesMapView extends React.Component<IProps> {
  private textboxesCache: ICanvasImageCache = {}
  private readonly mapBoxViewerStore: MapBoxViewerStore = null
  @observable public isLayersMenuShown: boolean = false
  public readonly globeViewControlStore: GlobeViewControlStore

  public constructor(props: IProps) {
    super(props)

    this.globeViewControlStore = new GlobeViewControlStore(
      props.eventsStore,
      props.globeViewsStore,
      props.sitemapItemsStore,
      props.locationAttributesStore,
    )

    this.mapBoxViewerStore = new MapBoxViewerStore(
      props.sitemapsStore,
      props.locationAttributesStore,
      props.state,
      this.globeViewControlStore,
      props.basemapsStore,
      null,
    )

    const config = props.state.deliveriesMapIdsList
    const isGlobeMode = !!config?.[0]?.globeViewId
    if (isGlobeMode) {
      const globe = props.globeViewsStore.byId.get(config?.[0]?.globeViewId)
      this.globeViewControlStore.selectGlobe(globe)
    } else {
      const whiteboard = props.sitemapsStore.byId.get(config?.[0]?.sitemapId)
      this.store.setSitemapId(whiteboard?.id)
      this.mapBoxViewerStore.setViewportFromAddress()
    }
  }

  public componentDidMount() {
    this.store.resetDisplayedDelivery()
  }

  public componentDidUpdate(prevProps: IProps) {
    const { currentDate } = this.props

    if (prevProps.currentDate !== currentDate) {
      this.store.resetDisplayedDelivery()
    }
  }

  public componentWillUnmount() {
    this.textboxesCache = {}
  }

  public render() {
    const { FileInputType, currentDate, state } = this.props

    return (
      <div className="scrollable col overflow-hidden">
        <div className="full-height relative">
          <MapViewsGallery
            isHideButtonDisplayed={false}
            mapIds={state.deliveriesMapIdsList}
            selectedGlobeViewId={
              this.globeViewControlStore.selectedGlobeView?.id
            }
            selectedWhiteboardId={this.store.currentSitemapId}
            renderMapViewComponent={this.renderMapView}
            shouldDisableZoom={true}
            eventName={SAVE_DELIVERY_CONFIGURATIONS}
            areArrowsLeftSided={true}
            shouldDisableSwap={true}
            shouldUseFullHeight={true}
            selectGlobe={this.selectGlobeAndCloseModals}
            selectSitemap={this.selectSitemapAndCloseModals}
            FileInputType={FileInputType}
            currentDate={currentDate}
            toggleLayersMenu={this.toggleLayersMenu}
            isCompactMode={this.props.isCompactMode}
            mapBoxViewerStore={this.mapBoxViewerStore}
          />
        </div>
      </div>
    )
  }

  private toggleLayersMenu = () => {
    this.isLayersMenuShown = !this.isLayersMenuShown
  }

  private renderMapView = (): JSX.Element => {
    return (
      <div className="full-width full-height">
        <MobileDeliveryMapView
          mapStore={this.store}
          textboxesCache={this.textboxesCache}
          isCompactMode={this.props.isCompactMode}
          mapBoxViewerStore={this.mapBoxViewerStore}
          globe={this.globeViewControlStore.selectedGlobeView}
          sitemapId={this.store.currentSitemapId}
          isLayersMenuShown={this.isLayersMenuShown}
          toggleLayersMenu={this.toggleLayersMenu}
        />
      </div>
    )
  }

  private get store(): DeliveriesMapViewStore {
    return this.props.deliveriesViewStore.mapStore
  }

  @action.bound
  private selectGlobeAndCloseModals(globe: GlobeView) {
    this.store.deselectSitemap()
    this.globeViewControlStore.selectGlobe(globe)
    this.mapBoxViewerStore.setViewportFromAddress()

    this.store.resetDisplayedDelivery()
  }

  @action.bound
  private selectSitemapAndCloseModals(sitemap: Sitemap) {
    this.globeViewControlStore.deselectGlobe()
    this.store.setSitemapId(sitemap.id)

    this.mapBoxViewerStore.setViewportFromAddress()

    this.store.resetDisplayedDelivery()
  }
}
