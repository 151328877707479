import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import DeliveryGroupingOption from '~/client/src/shared/enums/DeliveryGroupingOption'

const deliveryGroupingToFieldIdMap = {
  [DeliveryGroupingOption[FieldIds.COMPANY]]: FieldIds.COMPANY,
  [DeliveryGroupingOption[FieldIds.STATUS]]: FieldIds.STATUS,
  [DeliveryGroupingOption[FieldIds.DATE]]: FieldIds.DATE,

  [DeliveryGroupingOption[FieldIds.LOCATION]]: FieldIds.LOCATION,
  [DeliveryGroupingOption[FieldIds.BUILDING]]: FieldIds.BUILDING,
  [DeliveryGroupingOption[FieldIds.ZONE]]: FieldIds.ZONE,
  [DeliveryGroupingOption[FieldIds.LEVEL]]: FieldIds.LEVEL,
  [DeliveryGroupingOption[FieldIds.AREA]]: FieldIds.AREA,

  [DeliveryGroupingOption[FieldIds.GATE]]: FieldIds.GATE,
  [DeliveryGroupingOption[FieldIds.ROUTE]]: FieldIds.ROUTE,
  [DeliveryGroupingOption[FieldIds.OFFLOADING_EQUIPMENT]]:
    FieldIds.OFFLOADING_EQUIPMENT,
  [DeliveryGroupingOption[FieldIds.TRUCK_SIZE]]: FieldIds.TRUCK_SIZE,
  [DeliveryGroupingOption[FieldIds.MATERIAL]]: FieldIds.MATERIAL,

  [DeliveryGroupingOption[FieldIds.ON_SITE_CONTACTS]]:
    FieldIds.ON_SITE_CONTACTS,
  [DeliveryGroupingOption[FieldIds.VENDOR]]: FieldIds.VENDOR,

  [DeliveryGroupingOption[FieldIds.DURATION]]: FieldIds.DURATION,
  [DeliveryGroupingOption[FieldIds.CANCELATION_REASON]]:
    FieldIds.CANCELATION_REASON,
  [DeliveryGroupingOption[FieldIds.INSPECTION_SECTION]]:
    FieldIds.INSPECTION_SECTION,
}

export default deliveryGroupingToFieldIdMap
