import { FilterType } from '~/client/graph'

import ActivityGroupingType from '../../shared/enums/ActivityGroupingType'

const GroupingOption = {
  None: 'none',
  Wbs: 'wbs',
  Lbs: 'lbs',
  Building: FilterType.Building,
  Level: FilterType.Level,
  Zone: FilterType.Zone,
  Company: FilterType.Company,
  Equipment: ActivityGroupingType.Equipment,
  VerticalObject: ActivityGroupingType.VerticalObject,
  Route: ActivityGroupingType.Route,
  Gate: ActivityGroupingType.Gate,
}

export default GroupingOption
