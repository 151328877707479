import * as React from 'react'

import InlineSVG from 'svg-inline-react'

import LoaderIcon from '~/client/static/icons/loader-icon.svg'

import './Spinner.scss'

interface IProps {
  size?: number
  className?: string
}

export default class Spinner extends React.Component<IProps> {
  public static defaultProps: IProps = {
    size: 26,
    className: '',
  }

  public render() {
    const { size, className } = this.props
    return (
      <InlineSVG
        style={{
          width: size,
          height: size,
        }}
        className={`logo-spinner ${className}`}
        src={LoaderIcon}
      />
    )
  }
}
