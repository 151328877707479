enum InfoCode {
  EXPIRED_INVITE_TOKEN = '401',
  INVALID_INVITE_TOKEN = '400',

  ACCOUNT_CREATED = 'account-created',
  ACCOUNT_CREATED_AND_INVITED = 'account-created-invited',
  ACCESS_TO_PROJECT_REQUESTED = 'access-to-project-requested',
}

export default InfoCode
