import { IPhoto } from '~/client/graph'
import ActivityOwnedEntity from '~/client/src/shared/models/ActivityOwnedEntity'

import User from '../User'

export default class Photo extends ActivityOwnedEntity<IPhoto> {
  public id: string
  public url: string
  public thumbUrl: string
  public author: string
  public messageId: string
  public projectId: string

  public setAuthor(user: User) {
    if (user) {
      this.author = user.id
    }
  }
}
