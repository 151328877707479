import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IAnnouncementFollowingFieldsFragment = Pick<
  Types.IAnnouncementFollowing,
  'id' | 'projectId' | 'entityId' | 'userId' | 'createdAt' | 'updatedAt'
>

export type IGetAnnouncementFollowingListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetAnnouncementFollowingListQuery = {
  announcementFollowings?: Types.Maybe<{
    data: Array<IAnnouncementFollowingFieldsFragment>
  }>
}

export type IListenToAnnouncementFollowingSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToAnnouncementFollowingSubscription = {
  announcementFollowing?: Types.Maybe<
    Pick<Types.IAnnouncementFollowingChangeEvent, 'id'> & {
      item?: Types.Maybe<IAnnouncementFollowingFieldsFragment>
    }
  >
}

export type ISaveManyAnnouncementFollowingsMutationVariables = Types.Exact<{
  announcementFollowings:
    | Array<Types.Maybe<Types.IAnnouncementFollowingInput>>
    | Types.Maybe<Types.IAnnouncementFollowingInput>
}>

export type ISaveManyAnnouncementFollowingsMutation = {
  saveManyAnnouncementFollowings?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IAnnouncementFollowing, 'id'>>>
  >
}

export type IDeleteManyAnnouncementFollowingsMutationVariables = Types.Exact<{
  announcementFollowingIds:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteManyAnnouncementFollowingsMutation = Pick<
  Types.IMutation,
  'deleteManyAnnouncementFollowings'
>

export const AnnouncementFollowingFieldsFragmentDoc = gql`
  fragment AnnouncementFollowingFields on AnnouncementFollowing {
    id
    projectId
    entityId
    userId
    createdAt
    updatedAt
  }
`
export const GetAnnouncementFollowingListDocument = gql`
  query GetAnnouncementFollowingList($projectId: ObjectId!) {
    announcementFollowings(limit: 1000000, projectId: $projectId) {
      data {
        ...AnnouncementFollowingFields
      }
    }
  }
  ${AnnouncementFollowingFieldsFragmentDoc}
`

/**
 * __useGetAnnouncementFollowingListQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementFollowingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementFollowingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementFollowingListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetAnnouncementFollowingListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetAnnouncementFollowingListQuery,
    IGetAnnouncementFollowingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetAnnouncementFollowingListQuery,
    IGetAnnouncementFollowingListQueryVariables
  >(GetAnnouncementFollowingListDocument, options)
}
export function useGetAnnouncementFollowingListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetAnnouncementFollowingListQuery,
    IGetAnnouncementFollowingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetAnnouncementFollowingListQuery,
    IGetAnnouncementFollowingListQueryVariables
  >(GetAnnouncementFollowingListDocument, options)
}
export type GetAnnouncementFollowingListQueryHookResult = ReturnType<
  typeof useGetAnnouncementFollowingListQuery
>
export type GetAnnouncementFollowingListLazyQueryHookResult = ReturnType<
  typeof useGetAnnouncementFollowingListLazyQuery
>
export type GetAnnouncementFollowingListQueryResult = Apollo.QueryResult<
  IGetAnnouncementFollowingListQuery,
  IGetAnnouncementFollowingListQueryVariables
>
export const ListenToAnnouncementFollowingDocument = gql`
  subscription ListenToAnnouncementFollowing($projectId: ObjectId!) {
    announcementFollowing(projectId: $projectId) {
      id
      item {
        ...AnnouncementFollowingFields
      }
    }
  }
  ${AnnouncementFollowingFieldsFragmentDoc}
`

/**
 * __useListenToAnnouncementFollowingSubscription__
 *
 * To run a query within a React component, call `useListenToAnnouncementFollowingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToAnnouncementFollowingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToAnnouncementFollowingSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToAnnouncementFollowingSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToAnnouncementFollowingSubscription,
    IListenToAnnouncementFollowingSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToAnnouncementFollowingSubscription,
    IListenToAnnouncementFollowingSubscriptionVariables
  >(ListenToAnnouncementFollowingDocument, options)
}
export type ListenToAnnouncementFollowingSubscriptionHookResult = ReturnType<
  typeof useListenToAnnouncementFollowingSubscription
>
export type ListenToAnnouncementFollowingSubscriptionResult =
  Apollo.SubscriptionResult<IListenToAnnouncementFollowingSubscription>
export const SaveManyAnnouncementFollowingsDocument = gql`
  mutation SaveManyAnnouncementFollowings(
    $announcementFollowings: [AnnouncementFollowingInput]!
  ) {
    saveManyAnnouncementFollowings(
      announcementFollowings: $announcementFollowings
    ) {
      id
    }
  }
`
export type ISaveManyAnnouncementFollowingsMutationFn = Apollo.MutationFunction<
  ISaveManyAnnouncementFollowingsMutation,
  ISaveManyAnnouncementFollowingsMutationVariables
>

/**
 * __useSaveManyAnnouncementFollowingsMutation__
 *
 * To run a mutation, you first call `useSaveManyAnnouncementFollowingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyAnnouncementFollowingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyAnnouncementFollowingsMutation, { data, loading, error }] = useSaveManyAnnouncementFollowingsMutation({
 *   variables: {
 *      announcementFollowings: // value for 'announcementFollowings'
 *   },
 * });
 */
export function useSaveManyAnnouncementFollowingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyAnnouncementFollowingsMutation,
    ISaveManyAnnouncementFollowingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyAnnouncementFollowingsMutation,
    ISaveManyAnnouncementFollowingsMutationVariables
  >(SaveManyAnnouncementFollowingsDocument, options)
}
export type SaveManyAnnouncementFollowingsMutationHookResult = ReturnType<
  typeof useSaveManyAnnouncementFollowingsMutation
>
export type SaveManyAnnouncementFollowingsMutationResult =
  Apollo.MutationResult<ISaveManyAnnouncementFollowingsMutation>
export type SaveManyAnnouncementFollowingsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveManyAnnouncementFollowingsMutation,
    ISaveManyAnnouncementFollowingsMutationVariables
  >
export const DeleteManyAnnouncementFollowingsDocument = gql`
  mutation DeleteManyAnnouncementFollowings(
    $announcementFollowingIds: [ObjectId]!
  ) {
    deleteManyAnnouncementFollowings(ids: $announcementFollowingIds)
  }
`
export type IDeleteManyAnnouncementFollowingsMutationFn =
  Apollo.MutationFunction<
    IDeleteManyAnnouncementFollowingsMutation,
    IDeleteManyAnnouncementFollowingsMutationVariables
  >

/**
 * __useDeleteManyAnnouncementFollowingsMutation__
 *
 * To run a mutation, you first call `useDeleteManyAnnouncementFollowingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyAnnouncementFollowingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyAnnouncementFollowingsMutation, { data, loading, error }] = useDeleteManyAnnouncementFollowingsMutation({
 *   variables: {
 *      announcementFollowingIds: // value for 'announcementFollowingIds'
 *   },
 * });
 */
export function useDeleteManyAnnouncementFollowingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteManyAnnouncementFollowingsMutation,
    IDeleteManyAnnouncementFollowingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteManyAnnouncementFollowingsMutation,
    IDeleteManyAnnouncementFollowingsMutationVariables
  >(DeleteManyAnnouncementFollowingsDocument, options)
}
export type DeleteManyAnnouncementFollowingsMutationHookResult = ReturnType<
  typeof useDeleteManyAnnouncementFollowingsMutation
>
export type DeleteManyAnnouncementFollowingsMutationResult =
  Apollo.MutationResult<IDeleteManyAnnouncementFollowingsMutation>
export type DeleteManyAnnouncementFollowingsMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteManyAnnouncementFollowingsMutation,
    IDeleteManyAnnouncementFollowingsMutationVariables
  >
