import { enumToList } from '~/client/src/shared/utils/converters'

import Localization from '../localization/LocalizationManager'

export enum FormTypeFilterType {
  WORKFLOW_CATEGORY = 'workflow-category',
  APP = 'app',
}

export const getFormTypeFilterTypeCaption = (type: FormTypeFilterType) => {
  switch (type) {
    case FormTypeFilterType.WORKFLOW_CATEGORY:
      return Localization.translator.workflowCategory
    case FormTypeFilterType.APP:
      return Localization.translator.app
    default:
      throw new Error(type + ' is unhandled')
  }
}

export const formTypeFilterTypes = enumToList(FormTypeFilterType)
