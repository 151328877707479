enum MaturixStrengthUnit {
  MPA = 'mpa',
  NPERMMSQ = 'npermmsq',
  PSI = 'psi',
  KGPERCMSQ = 'kgpercmsq',
}

export enum MaturixStrengthUnitResponse {
  MPA = 'MPa',
  NPERMMSQ = 'N/mm²',
  PSI = 'psi',
  KGPERCMSQ = 'kg/cm²',
}

export function convertStrengthUnitFromResponce(
  unit: MaturixStrengthUnitResponse,
): MaturixStrengthUnit {
  switch (unit) {
    case MaturixStrengthUnitResponse.MPA:
      return MaturixStrengthUnit.MPA
    case MaturixStrengthUnitResponse.NPERMMSQ:
      return MaturixStrengthUnit.NPERMMSQ
    case MaturixStrengthUnitResponse.PSI:
      return MaturixStrengthUnit.PSI
    case MaturixStrengthUnitResponse.KGPERCMSQ:
      return MaturixStrengthUnit.KGPERCMSQ
  }
}

export default MaturixStrengthUnit
