import * as React from 'react'

import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import StruxhubInput from '../../../StruxhubInputs/StruxhubInput'
import { PasswordResetInputNames } from '../../PasswordResetView.store'

interface IProps {
  onConfirmPasswordReset: (e) => void
  inputValueReset: (name: PasswordResetInputNames) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  getValue: (name: PasswordResetInputNames) => string
}

@observer
export default class ConfirmResetPasswordForm extends React.Component<IProps> {
  public render() {
    const { onConfirmPasswordReset, inputValueReset, onChange, getValue } =
      this.props
    const { PASSWORD, REPASSWORD } = PasswordResetInputNames

    return (
      <form onSubmit={onConfirmPasswordReset}>
        <div className="text large primary-blue py20">
          {Localization.translator.enterANewPasswordBelow}
        </div>
        <StruxhubInput
          type="password"
          name={PASSWORD}
          label={Localization.translator.newPassword}
          isRequiredTextHidden={true}
          isRequired={true}
          value={getValue(PASSWORD)}
          onChange={onChange}
          onValueReset={inputValueReset.bind(null, PASSWORD)}
          autoComplete="new-password"
        />
        <StruxhubInput
          type="password"
          name={REPASSWORD}
          label={Localization.translator.confirmNewPassword}
          isRequiredTextHidden={true}
          isRequired={true}
          value={getValue(REPASSWORD)}
          onChange={onChange}
          onValueReset={inputValueReset.bind(null, REPASSWORD)}
          autoComplete="new-password"
        />
        <button className="full-width bg-blue ba-none brada4 pa10 mt10">
          <span className="text white extra-large">
            {Localization.translator.changePassword}
          </span>
        </button>
      </form>
    )
  }
}
