import { action, observable } from 'mobx'

export default class DynamicOverflowListStore {
  @observable public shouldRecalculate: boolean = false
  @observable public numberOfVisibleElements: number = Infinity

  @action.bound
  public enableRecalculation() {
    if (this.shouldRecalculate) {
      return
    }

    this.shouldRecalculate = true
    this.resetNumberOfVisibleElements()
  }

  @action.bound
  public disableRecalculation() {
    this.shouldRecalculate = false
  }

  @action.bound
  public setNumberOfVisibleElements(visibleElementsCount: number) {
    this.numberOfVisibleElements = visibleElementsCount
  }

  @action.bound
  public resetNumberOfVisibleElements() {
    this.numberOfVisibleElements = Infinity
  }
}
