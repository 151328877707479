import * as React from 'react'

import { inject } from 'mobx-react'
import Tree from 'react-virtualized-tree'

import FeatureFlag from '~/client/src/shared/components/FeatureFlag'
import FlaggedFeatures from '~/client/src/shared/enums/FlaggedFeatures'
import { IActivitiesTreeNode } from '~/client/src/shared/models/IActivitiesTreeNode'

import EventContext from '../../stores/EventStore/EventContext'
import EventsStore from '../../stores/EventStore/Events.store'
import { RESET_INPUT_STATUSES } from '../../stores/EventStore/eventConstants'
import CompactActivityListStore from '../../stores/ui/CompactActivityList.store'
import FileInputBase from '../FileInput/FileInput'
import ActivitiesTreeStore from './ActivitiesTree.store'
import ActivitiesTreeNode from './components/ActivitiesTreeNode/ActivitiesTreeNode'
import CustomNodeRenderer from './components/CustomNodeRenderer/CustomNodeRenderer'

import './ActivitiesTree.scss'

interface IActivitiesTree {
  nodes: IActivitiesTreeNode[]
  viewDate: Date
  className?: string
  changeContainerExpandState?: (id: string, value: boolean) => void
  compactActivityListStore: CompactActivityListStore
  eventsStore?: EventsStore
  CustomNodeWrapperType
  LocationContainerNodeType
  FileInputType: typeof FileInputBase
}

@inject('eventsStore')
export default class ActivitiesTree extends React.Component<IActivitiesTree> {
  public static defaultProps = {
    className: 'activity-list activities-tree',
  }

  private activitiesTreeStore: ActivitiesTreeStore
  private clearPostEventCallback: () => void

  public constructor(props: IActivitiesTree) {
    super(props)

    this.activitiesTreeStore = new ActivitiesTreeStore(
      props.compactActivityListStore,
    )

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.onResetRequest,
    )
  }

  public componentWillUnmount() {
    if (this.clearPostEventCallback) {
      this.clearPostEventCallback()
    }
  }

  public render() {
    return (
      <FeatureFlag
        features={[FlaggedFeatures.CONTAINER_UPDATES]}
        defaultValues={[false]}
        render={this.renderFlaggedContent}
      />
    )
  }

  private onResetRequest = (eventContext: EventContext) => {
    const [eventType] = eventContext.event
    if (eventType === RESET_INPUT_STATUSES) {
      this.activitiesTreeStore.resetInputStatuses()
    }
  }

  private renderFlaggedContent = ([isEnable]: boolean[]) => {
    const {
      nodes,
      viewDate,
      className,
      CustomNodeWrapperType,
      LocationContainerNodeType,
      changeContainerExpandState,
      FileInputType,
    } = this.props

    return (
      <div className={className}>
        <Tree
          nodes={nodes}
          onChange={this.activitiesTreeStore.handleChangeTree}
        >
          {({ style, node: nativeNode, ...rest }) => {
            const node = nativeNode as IActivitiesTreeNode // as extended node

            return (
              <CustomNodeWrapperType
                style={style}
                nodeType={node.nodeType}
                level={node.level}
              >
                <CustomNodeRenderer
                  node={node}
                  changeContainerExpandState={changeContainerExpandState}
                  startViewDate={viewDate}
                  isContainerUpdateEnabled={isEnable}
                  FileInputType={FileInputType}
                  {...rest}
                >
                  <ActivitiesTreeNode
                    node={node}
                    currentViewDate={viewDate}
                    activitiesTreeStore={this.activitiesTreeStore}
                    LocationContainerNodeType={LocationContainerNodeType}
                  />
                </CustomNodeRenderer>
              </CustomNodeWrapperType>
            )
          }}
        </Tree>
      </div>
    )
  }
}
