import { action, computed, observable } from 'mobx'

import {
  ISitemap,
  ISitemapInput,
  ISitemapSpecificItemData,
} from '~/client/graph'
import SitemapType from '~/client/src/shared/enums/SitemapType'
import Sitemap from '~/client/src/shared/models/Sitemap'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import Guard from '~/client/src/shared/utils/Guard'

import * as e from '../EventStore/eventConstants'
import { DELETE_SITEMAP, SAVE_SITEMAP } from '../EventStore/eventConstants'

export default class SitemapsStore {
  @observable public isDataReceived = false

  public constructor(private readonly eventsStore: EventsStore) {
    Guard.requireAll({
      eventsStore,
    })
  }

  @computed
  public get list(): Sitemap[] {
    return Array.from(this.byId.values())
  }

  @computed
  public get whiteboards(): Sitemap[] {
    return this.list.filter(dto => !dto.isReferenced)
  }

  @computed
  public get plans(): Sitemap[] {
    return this.list.filter(dto => dto.isReferenced)
  }

  @computed
  public get defaultSitemaps(): Sitemap[] {
    return this.list.filter(dto => dto.type === SitemapType.Default)
  }

  @computed
  public get gateSitemaps(): Sitemap[] {
    return this.list.filter(dto => dto.type === SitemapType.Gate)
  }

  @computed
  public get zoneSitemaps(): Sitemap[] {
    return this.list.filter(dto => dto.type === SitemapType.Zone)
  }

  public get byId() {
    const { sitemaps } = this.eventsStore.appState
    return sitemaps
  }

  @action.bound
  public clearList() {
    this.isDataReceived = false
    this.byId.clear()
  }

  @action.bound
  public receiveList(list: ISitemap[]) {
    this.clearList()

    list.forEach(dto => {
      const sitemap = Sitemap.fromDto(dto)
      this.byId.set(sitemap.id, sitemap)
    })

    this.isDataReceived = true
  }

  @action.bound
  public receiveOne(id: string, dto: ISitemap) {
    if (dto) {
      const existingSitemap = this.byId.get(dto.id)
      dto.items = existingSitemap?.items
      this.byId.set(dto.id, Sitemap.fromDto(dto))
    } else {
      this.byId.delete(id)
    }
  }

  @action.bound
  public receiveOneItem(id: string, dto: ISitemapSpecificItemData) {
    if (dto) {
      const sitemap = this.byId.get(dto.sitemapId)
      sitemap.setItemDisplayData(dto.sitemapItemId, dto)
      this.byId.set(dto.sitemapId, sitemap)
    } else {
      const sitemap = this.getSitemapByItemId(id)
      if (sitemap) {
        sitemap.removeItemDisplayData(id)
        this.byId.set(sitemap.id, sitemap)
      }
    }
  }

  private getSitemapByItemId(id: string) {
    const globeViews = Array.from(this.byId, ([, value]) => value)
    return globeViews.find(g => g.items.some(i => i.id === id))
  }

  @action.bound
  public deleteSitemapItemData(
    itemId: string,
    callbackFn?: (id: string) => void,
    shouldApplyChangesBeforeSave?: boolean,
    avoidSaving?: boolean,
  ) {
    if (!avoidSaving) {
      this.eventsStore.dispatch(
        e.DELETE_GLOBE_VIEW_ITEM_DATA,
        itemId,
        callbackFn,
      )
    }
  }

  @action.bound
  public saveSitemapItemData(
    items: ISitemapSpecificItemData[],
    callbackFn?: (id: string) => void,
    shouldApplyChangesBeforeSave?: boolean,
    avoidSaving?: boolean,
  ) {
    const { getPolylineItemDtos, getRectangleItemDtos, getCircleItemDtos } =
      Sitemap

    if (shouldApplyChangesBeforeSave) {
      const sitemap = this.byId.get(items[0]?.sitemapId)
      items.forEach(i => sitemap.setItemDisplayData(i.sitemapItemId, i))
      this.byId.set(sitemap.id, sitemap.getFullCopy())
    }

    if (!avoidSaving) {
      this.eventsStore.dispatch(
        e.SAVE_SITEMAP_ITEM_DATA,
        getPolylineItemDtos(items),
        getRectangleItemDtos(items),
        getCircleItemDtos(items),
        callbackFn,
      )
    }
  }

  @action.bound
  public save(
    sitemap: Sitemap,
    callbackFn?: (id: string) => void,
    shouldApplyChangesBeforeSave?: boolean,
    avoidSaving?: boolean,
    width?: number,
    height?: number,
  ) {
    const { id: projectId } = this.eventsStore.appState.activeProject

    const sitemapInput: ISitemapInput = {
      id: sitemap.id,
      name: sitemap.name,
      basemapId: sitemap.basemapId,
      type: sitemap.type,
      filledImage: sitemap.filledImage,
      itemsFilledImage: sitemap.itemsFilledImage,
      isLabelsShown: sitemap.isLabelsShown,
      projectId: sitemap.projectId || projectId,
      isProjectOverviewMap: sitemap.isProjectOverviewMap,
      bounds: sitemap.bounds,
      zoom: sitemap.zoom,
      pitch: sitemap.pitch,
      center: sitemap.center,
      bearing: sitemap.bearing,
      width: width || sitemap.width,
      height: height || sitemap.height,
      geoCorners: sitemap.geoCorners,
    }

    if (shouldApplyChangesBeforeSave && sitemap.id) {
      this.byId.set(sitemap.id, sitemap.getFullCopy())
    }

    if (!avoidSaving) {
      this.eventsStore.dispatch(SAVE_SITEMAP, sitemapInput, callbackFn)
    }
  }

  @action.bound
  public removeOne(sitemapId: string, callbackFn?: () => void) {
    if (!this.byId.has(sitemapId)) {
      return
    }

    this.byId.delete(sitemapId)

    this.eventsStore.dispatch(DELETE_SITEMAP, sitemapId, callbackFn)
  }
}
