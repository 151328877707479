import * as React from 'react'

import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import StruxhubPhoneInput from '~/client/src/shared/components/StruxhubInputs/StruxhubPhoneInput'
import StruxhubSelect from '~/client/src/shared/components/StruxhubInputs/StruxhubSelect'
import StruxhubTagsInput from '~/client/src/shared/components/StruxhubInputs/StruxhubTagsInput/StruxhubTagsInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import { FieldTypes, IUserDetailsField } from '../Profile.store'

export default class ProfileFormFieldWrapper extends React.Component<IUserDetailsField> {
  public render() {
    const { type } = this.props

    let control: JSX.Element
    switch (type) {
      case FieldTypes.SELECT:
        control = this.renderSelect()
        break
      case FieldTypes.EMAIL:
        control = this.renderEmail()
        break
      case FieldTypes.PASSWORD:
        control = this.renderPassword()
        break
      case FieldTypes.PHONE:
        control = this.renderPhone()
        break
      case FieldTypes.TAGS:
        control = this.renderTags()
        break
      default:
        control = this.renderInput()
        break
    }

    return control
  }

  private renderPassword(): JSX.Element {
    const {
      nameTranslatorKey,
      isValid,
      isRequired,
      additionalTextTranslatorKey,
      value,
    } = this.props

    return (
      <StruxhubInput
        type="password"
        label={Localization.getText(nameTranslatorKey)}
        isRequiredTextHidden={true}
        isRequired={isRequired}
        isValid={isValid}
        onChange={this.handleChange}
        value={value as string}
        additionalHelperText={
          !!additionalTextTranslatorKey &&
          Localization.getText(additionalTextTranslatorKey)
        }
        autoComplete="new-password"
      />
    )
  }

  private renderSelect(): JSX.Element {
    const {
      nameTranslatorKey,
      additionalTextTranslatorKey,
      value: selectedValue,
      getOptions,
      isRequired,
      isValid,
    } = this.props

    return (
      <StruxhubSelect
        label={Localization.getText(nameTranslatorKey)}
        isRequired={isRequired}
        isValid={isValid}
        value={selectedValue as string}
        onChange={this.handleChange}
        additionalHelperText={
          !!additionalTextTranslatorKey &&
          Localization.getText(additionalTextTranslatorKey)
        }
      >
        {getOptions().map(({ name, value, disabled }) => (
          <option key={value} value={value} disabled={disabled}>
            {name}
          </option>
        ))}
      </StruxhubSelect>
    )
  }

  private renderEmail(): JSX.Element {
    const { nameTranslatorKey, value, onButtonClick } = this.props

    return (
      <div className="row y-center full-width">
        <StruxhubInput
          label={Localization.getText(nameTranslatorKey)}
          isRequiredTextHidden={true}
          isDisabled={true}
          value={value as string}
          onChange={null}
        />
        <span
          onClick={onButtonClick}
          className="text bold underline blue-highlight large pointer no-grow no-white-space-wrap ml15"
        >
          {value
            ? Localization.translator.change_verb
            : Localization.translator.userEmailLinking
                .linkYourEmailAndSetPassword}
        </span>
      </div>
    )
  }

  private renderInput(): JSX.Element {
    const {
      nameTranslatorKey,
      additionalTextTranslatorKey,
      isRequired,
      isValid,
      value,
    } = this.props

    return (
      <StruxhubInput
        label={Localization.getText(nameTranslatorKey)}
        isRequired={isRequired}
        isValid={isValid}
        isRequiredTextHidden={true}
        value={value as string}
        onChange={this.handleChange}
        onValueReset={this.handleValueReset}
        additionalHelperText={
          !!additionalTextTranslatorKey &&
          Localization.getText(additionalTextTranslatorKey)
        }
      />
    )
  }

  private renderPhone(): JSX.Element {
    const {
      nameTranslatorKey,
      onChange,
      value,
      isRequired,
      isValid,
      additionalTextTranslatorKey,
    } = this.props

    return (
      <StruxhubPhoneInput
        label={Localization.getText(nameTranslatorKey)}
        isRequired={isRequired}
        isValid={isValid}
        isRequiredTextHidden={true}
        onChange={onChange}
        value={(value as string) || ''}
        validationMessage={Localization.translator.wrongPhoneNumberFormat}
        additionalHelperText={
          !!additionalTextTranslatorKey &&
          Localization.getText(additionalTextTranslatorKey)
        }
      />
    )
  }

  private renderTags(): JSX.Element {
    const { id, value, isRequired, isValid, nameTranslatorKey, onChange } =
      this.props

    return (
      <StruxhubTagsInput
        label={Localization.getText(nameTranslatorKey)}
        isRequired={isRequired}
        isValid={isValid}
        isRequiredTextHidden={true}
        isTagCreationAllowed={true}
        onChange={onChange}
        tagType={id as any}
        values={value as string[]}
      />
    )
  }

  private handleChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    this.props.onChange(target.value)
  }

  private handleValueReset = () => {
    this.props.onChange(EMPTY_STRING)
  }
}
