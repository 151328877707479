import { DocumentNode } from 'graphql'
import { computed } from 'mobx'

import {
  DeleteDeliveryVehicleTypesDocument,
  GetDeliveryVehicleTypesDocument,
  ListenToDeliveryVehicleTypesDocument,
  SaveDeliveryVehicleTypesDocument,
} from '~/client/graph/operations/generated/DeliveryVehicleTypes.generated'

import CommonDeliveryAttributeDto from '../../types/CommonDeliveryAttributeDto'
import CommonDeliveryAttributesStore from './CommonDeliveryAttributes.store'

export default class DeliveryVehicleTypesStore extends CommonDeliveryAttributesStore {
  protected readonly collectionKey: string = 'deliveryVehicleTypes'

  protected readonly loadQuery: DocumentNode = GetDeliveryVehicleTypesDocument
  protected readonly subscriptionQuery: DocumentNode =
    ListenToDeliveryVehicleTypesDocument
  protected readonly saveMutation: DocumentNode =
    SaveDeliveryVehicleTypesDocument
  protected readonly deleteMutation: DocumentNode =
    DeleteDeliveryVehicleTypesDocument

  @computed
  public get list(): CommonDeliveryAttributeDto[] {
    return this.listWithoutDeletedData.filter(
      entry => !entry.isFromConcreteDirect,
    )
  }

  @computed
  public get listWithoutDeletedData(): CommonDeliveryAttributeDto[] {
    return this.allItems.filter(entry => !entry.isDeleted)
  }

  public get byId(): Map<string, CommonDeliveryAttributeDto> {
    return this.eventsStore.appState.deliveryVehicleTypes
  }

  @computed
  private get allItems(): CommonDeliveryAttributeDto[] {
    return Array.from(this.byId.values())
  }
}
