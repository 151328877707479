import { action, computed, observable } from 'mobx'

import { IAssociatedCode, IUser, IUserProject } from '~/client/graph'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'

import User from '../../models/User'
import UserProject from '../../models/UserProject'
import Guard from '../../utils/Guard'
import EventsStore from '../EventStore/Events.store'

export default class UserProjectsStore {
  @observable private sourceMap: Map<string, UserProject> = new Map()

  public constructor(private readonly eventsStore: EventsStore) {
    Guard.requireAll({ eventsStore })
  }

  private get appState() {
    return this.eventsStore.appState
  }

  public get isDataReceived(): boolean {
    return !this.appState.loading.get(e.LOAD_AND_LISTEN_TO_USER_PROJECTS)
  }

  public hasUserAccessToActiveProject = (user: User): boolean => {
    if (!user || user.isHidden) {
      return false
    }

    const userProjectRel = this.sourceMap.get(user.id)
    return !!userProjectRel && !userProjectRel.isDeleted
  }

  @computed
  public get list(): UserProject[] {
    return [...this.sourceMap.values()]
  }

  public getById = (id: string): UserProject => {
    return this.list.find(uP => uP.id === id)
  }

  public getByUser = ({ id }: IUser): UserProject => {
    return (
      this.sourceMap.get(id) ||
      new UserProject(null, this.appState.activeProject.id, id)
    )
  }

  @action.bound
  public receiveList(dtos: IUserProject[]) {
    this.clearList()

    dtos.forEach(dto => {
      const userProjectSettings = UserProject.fromDto(dto)
      this.sourceMap.set(dto.userId, userProjectSettings)
    })
  }

  @action.bound
  public receiveOne(userId: string, dto: IUserProject) {
    if (dto) {
      this.sourceMap.set(userId, UserProject.fromDto(dto))
    } else {
      this.sourceMap.delete(userId)
    }
  }

  @action.bound
  public clearList() {
    this.sourceMap.clear()
  }

  @action.bound
  public save(userProjects: IUserProject[]): Promise<IUserProject[]> {
    return new Promise((resolve, reject) =>
      this.eventsStore.dispatch(
        e.SAVE_USER_PROJECTS,
        userProjects,
        resolve,
        reject,
      ),
    )
  }

  public get codesToUserProjectIdsMap(): { [associatedCode: string]: string } {
    return this.list.reduce((map, userProject) => {
      userProject.associatedCodes.forEach(code => {
        map[code.id] = userProject.id
      })

      return map
    }, {})
  }

  // if code.id is free => assign to the userProject
  // if code.id is assigned to current userProject => unAssign from the userProject
  // if code.id is assigned to another userProject => unAssign from another userProject and assign to the current
  public setCode(userProject: UserProject, code: IAssociatedCode) {
    const currentUserProjectId = this.codesToUserProjectIdsMap[code.id]

    if (!currentUserProjectId) {
      userProject.setAssociatedCodes(code)
      this.save([userProject.toDto()])
    } else if (currentUserProjectId === userProject.id) {
      userProject.unsetAssociatedCodes(code)
      this.save([userProject.toDto()])
    } else {
      const previouslyAssignedUserProject = this.getById(currentUserProjectId)
      previouslyAssignedUserProject.unsetAssociatedCodes(code)
      userProject.setAssociatedCodes(code)
      this.save([previouslyAssignedUserProject.toDto(), userProject.toDto()])
    }
  }
}
