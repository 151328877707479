import { LocationType } from '~/client/graph'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ILocationAttributeDto from '~/client/src/shared/types/ILocationAttributeDto'

import LocationAttributeBase from './LocationAttributeBase'

export default class Zone extends LocationAttributeBase<Zone> {
  public static fromDto(dto: ILocationAttributeDto) {
    return LocationAttributeBase.factory(Zone, dto, MapViewLocationIcon.Zone)
  }

  public type = LocationType.Zone
  protected relatedFieldId = FieldIds.ZONE
  protected model = Zone
}
