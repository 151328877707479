import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ILocationsByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type ILocationsByProjectIdQuery = {
  locations?: Types.Maybe<{
    data: Array<
      | (Pick<
          Types.IArea,
          | 'id'
          | 'projectId'
          | 'type'
          | 'name'
          | 'color'
          | 'iconName'
          | 'permittedCompanies'
          | 'maxOverlappingDeliveries'
          | 'maxBookingDuration'
          | 'code'
          | 'assignedSitemaps'
          | 'assignedGlobes'
          | 'isDeleted'
          | 'createdAt'
          | 'updatedAt'
        > & {
          operatingIntervals?: Types.Maybe<
            Array<
              Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
            >
          >
          parent?: Types.Maybe<
            Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
          >
        })
      | (Pick<
          Types.IBuilding,
          | 'levelsOrder'
          | 'groundLevel'
          | 'isGroundIndexZero'
          | 'skipLevelIndexTemplate'
          | 'levelBaseName'
          | 'id'
          | 'projectId'
          | 'type'
          | 'name'
          | 'color'
          | 'iconName'
          | 'permittedCompanies'
          | 'maxOverlappingDeliveries'
          | 'maxBookingDuration'
          | 'code'
          | 'assignedSitemaps'
          | 'assignedGlobes'
          | 'isDeleted'
          | 'createdAt'
          | 'updatedAt'
        > & {
          operatingIntervals?: Types.Maybe<
            Array<
              Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
            >
          >
          parent?: Types.Maybe<
            Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
          >
        })
      | (Pick<
          Types.IGate,
          | 'id'
          | 'projectId'
          | 'type'
          | 'name'
          | 'color'
          | 'iconName'
          | 'permittedCompanies'
          | 'maxOverlappingDeliveries'
          | 'maxBookingDuration'
          | 'code'
          | 'assignedSitemaps'
          | 'assignedGlobes'
          | 'isDeleted'
          | 'createdAt'
          | 'updatedAt'
        > & {
          operatingIntervals?: Types.Maybe<
            Array<
              Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
            >
          >
          parent?: Types.Maybe<
            Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
          >
        })
      | (Pick<
          Types.IIntegration,
          | 'id'
          | 'projectId'
          | 'type'
          | 'name'
          | 'color'
          | 'iconName'
          | 'permittedCompanies'
          | 'maxOverlappingDeliveries'
          | 'maxBookingDuration'
          | 'code'
          | 'assignedSitemaps'
          | 'assignedGlobes'
          | 'isDeleted'
          | 'createdAt'
          | 'updatedAt'
        > & {
          metadata?: Types.Maybe<
            Array<Types.Maybe<Pick<Types.IStringPair, 'key' | 'value'>>>
          >
          operatingIntervals?: Types.Maybe<
            Array<
              Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
            >
          >
          parent?: Types.Maybe<
            Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
          >
        })
      | (Pick<
          Types.IInteriorDoor,
          | 'id'
          | 'projectId'
          | 'type'
          | 'name'
          | 'color'
          | 'iconName'
          | 'permittedCompanies'
          | 'maxOverlappingDeliveries'
          | 'maxBookingDuration'
          | 'code'
          | 'assignedSitemaps'
          | 'assignedGlobes'
          | 'isDeleted'
          | 'createdAt'
          | 'updatedAt'
        > & {
          operatingIntervals?: Types.Maybe<
            Array<
              Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
            >
          >
          parent?: Types.Maybe<
            Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
          >
        })
      | (Pick<
          Types.IInteriorPath,
          | 'id'
          | 'projectId'
          | 'type'
          | 'name'
          | 'color'
          | 'iconName'
          | 'permittedCompanies'
          | 'maxOverlappingDeliveries'
          | 'maxBookingDuration'
          | 'code'
          | 'assignedSitemaps'
          | 'assignedGlobes'
          | 'isDeleted'
          | 'createdAt'
          | 'updatedAt'
        > & {
          operatingIntervals?: Types.Maybe<
            Array<
              Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
            >
          >
          parent?: Types.Maybe<
            Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
          >
        })
      | (Pick<
          Types.ILevel,
          | 'isNameImplicit'
          | 'id'
          | 'projectId'
          | 'type'
          | 'name'
          | 'color'
          | 'iconName'
          | 'permittedCompanies'
          | 'maxOverlappingDeliveries'
          | 'maxBookingDuration'
          | 'code'
          | 'assignedSitemaps'
          | 'assignedGlobes'
          | 'isDeleted'
          | 'createdAt'
          | 'updatedAt'
        > & {
          operatingIntervals?: Types.Maybe<
            Array<
              Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
            >
          >
          parent?: Types.Maybe<
            Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
          >
        })
      | (Pick<
          Types.ILogisticsObject,
          | 'id'
          | 'projectId'
          | 'type'
          | 'name'
          | 'color'
          | 'iconName'
          | 'permittedCompanies'
          | 'maxOverlappingDeliveries'
          | 'maxBookingDuration'
          | 'code'
          | 'assignedSitemaps'
          | 'assignedGlobes'
          | 'isDeleted'
          | 'createdAt'
          | 'updatedAt'
        > & {
          operatingIntervals?: Types.Maybe<
            Array<
              Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
            >
          >
          parent?: Types.Maybe<
            Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
          >
        })
      | (Pick<
          Types.IOffloadingEquipment,
          | 'accessibleLevels'
          | 'id'
          | 'projectId'
          | 'type'
          | 'name'
          | 'color'
          | 'iconName'
          | 'permittedCompanies'
          | 'maxOverlappingDeliveries'
          | 'maxBookingDuration'
          | 'code'
          | 'assignedSitemaps'
          | 'assignedGlobes'
          | 'isDeleted'
          | 'createdAt'
          | 'updatedAt'
        > & {
          operatingIntervals?: Types.Maybe<
            Array<
              Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
            >
          >
          parent?: Types.Maybe<
            Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
          >
        })
      | (Pick<
          Types.IRoute,
          | 'id'
          | 'projectId'
          | 'type'
          | 'name'
          | 'color'
          | 'iconName'
          | 'permittedCompanies'
          | 'maxOverlappingDeliveries'
          | 'maxBookingDuration'
          | 'code'
          | 'assignedSitemaps'
          | 'assignedGlobes'
          | 'isDeleted'
          | 'createdAt'
          | 'updatedAt'
        > & {
          operatingIntervals?: Types.Maybe<
            Array<
              Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
            >
          >
          parent?: Types.Maybe<
            Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
          >
        })
      | (Pick<
          Types.IStaging,
          | 'id'
          | 'projectId'
          | 'type'
          | 'name'
          | 'color'
          | 'iconName'
          | 'permittedCompanies'
          | 'maxOverlappingDeliveries'
          | 'maxBookingDuration'
          | 'code'
          | 'assignedSitemaps'
          | 'assignedGlobes'
          | 'isDeleted'
          | 'createdAt'
          | 'updatedAt'
        > & {
          operatingIntervals?: Types.Maybe<
            Array<
              Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
            >
          >
          parent?: Types.Maybe<
            Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
          >
        })
      | (Pick<
          Types.IVerticalObject,
          | 'accessibleLevels'
          | 'id'
          | 'projectId'
          | 'type'
          | 'name'
          | 'color'
          | 'iconName'
          | 'permittedCompanies'
          | 'maxOverlappingDeliveries'
          | 'maxBookingDuration'
          | 'code'
          | 'assignedSitemaps'
          | 'assignedGlobes'
          | 'isDeleted'
          | 'createdAt'
          | 'updatedAt'
        > & {
          levelsClosures?: Types.Maybe<
            Array<
              Types.Maybe<
                Pick<Types.ILevelsClosure, 'levels'> & {
                  interval?: Types.Maybe<
                    Pick<Types.IDateInterval, 'startDate' | 'endDate'>
                  >
                }
              >
            >
          >
          operatingIntervals?: Types.Maybe<
            Array<
              Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
            >
          >
          parent?: Types.Maybe<
            Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
          >
        })
      | (Pick<
          Types.IZone,
          | 'id'
          | 'projectId'
          | 'type'
          | 'name'
          | 'color'
          | 'iconName'
          | 'permittedCompanies'
          | 'maxOverlappingDeliveries'
          | 'maxBookingDuration'
          | 'code'
          | 'assignedSitemaps'
          | 'assignedGlobes'
          | 'isDeleted'
          | 'createdAt'
          | 'updatedAt'
        > & {
          operatingIntervals?: Types.Maybe<
            Array<
              Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
            >
          >
          parent?: Types.Maybe<
            Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
          >
        })
    >
  }>
}

export type ISaveEquipmentsMutationVariables = Types.Exact<{
  equipments:
    | Array<Types.Maybe<Types.IOffloadingEquipmentInput>>
    | Types.Maybe<Types.IOffloadingEquipmentInput>
}>

export type ISaveEquipmentsMutation = {
  saveManyOffloadingEquipments?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IOffloadingEquipment, 'id'>>>
  >
}

export type ISaveLevelsMutationVariables = Types.Exact<{
  levels: Array<Types.Maybe<Types.ILevelInput>> | Types.Maybe<Types.ILevelInput>
}>

export type ISaveLevelsMutation = {
  saveManyLevels?: Types.Maybe<Array<Types.Maybe<Pick<Types.ILevel, 'id'>>>>
}

export type ISaveVerticalObjectsMutationVariables = Types.Exact<{
  verticalObjects:
    | Array<Types.Maybe<Types.IVerticalObjectInput>>
    | Types.Maybe<Types.IVerticalObjectInput>
}>

export type ISaveVerticalObjectsMutation = {
  saveManyVerticalObjects?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IVerticalObject, 'id'>>>
  >
}

export type ISaveBuildingsMutationVariables = Types.Exact<{
  buildings:
    | Array<Types.Maybe<Types.IBuildingInput>>
    | Types.Maybe<Types.IBuildingInput>
}>

export type ISaveBuildingsMutation = {
  saveManyBuildings?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IBuilding, 'id'>>>
  >
}

export type ISaveZonesMutationVariables = Types.Exact<{
  zones: Array<Types.Maybe<Types.IZoneInput>> | Types.Maybe<Types.IZoneInput>
}>

export type ISaveZonesMutation = {
  saveManyZones?: Types.Maybe<Array<Types.Maybe<Pick<Types.IZone, 'id'>>>>
}

export type ISaveGatesMutationVariables = Types.Exact<{
  gates: Array<Types.Maybe<Types.IGateInput>> | Types.Maybe<Types.IGateInput>
}>

export type ISaveGatesMutation = {
  saveManyGates?: Types.Maybe<Array<Types.Maybe<Pick<Types.IGate, 'id'>>>>
}

export type ISaveRoutesMutationVariables = Types.Exact<{
  routes: Array<Types.Maybe<Types.IRouteInput>> | Types.Maybe<Types.IRouteInput>
}>

export type ISaveRoutesMutation = {
  saveManyRoutes?: Types.Maybe<Array<Types.Maybe<Pick<Types.IRoute, 'id'>>>>
}

export type ISaveAreasMutationVariables = Types.Exact<{
  areas: Array<Types.Maybe<Types.IAreaInput>> | Types.Maybe<Types.IAreaInput>
}>

export type ISaveAreasMutation = {
  saveManyAreas?: Types.Maybe<Array<Types.Maybe<Pick<Types.IArea, 'id'>>>>
}

export type ISaveLogisticsObjectsMutationVariables = Types.Exact<{
  logisticsObjects:
    | Array<Types.Maybe<Types.ILogisticsObjectInput>>
    | Types.Maybe<Types.ILogisticsObjectInput>
}>

export type ISaveLogisticsObjectsMutation = {
  saveManyLogisticsObjects?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.ILogisticsObject, 'id'>>>
  >
}

export type ISaveIntegrationsMutationVariables = Types.Exact<{
  integrations:
    | Array<Types.Maybe<Types.IIntegrationInput>>
    | Types.Maybe<Types.IIntegrationInput>
}>

export type ISaveIntegrationsMutation = {
  saveManyIntegrations?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IIntegration, 'id'>>>
  >
}

export type ISaveStagingsMutationVariables = Types.Exact<{
  stagings:
    | Array<Types.Maybe<Types.IStagingInput>>
    | Types.Maybe<Types.IStagingInput>
}>

export type ISaveStagingsMutation = {
  saveManyStagings?: Types.Maybe<Array<Types.Maybe<Pick<Types.IStaging, 'id'>>>>
}

export type ISaveInteriorDoorsMutationVariables = Types.Exact<{
  interiorDoors:
    | Array<Types.Maybe<Types.IInteriorDoorInput>>
    | Types.Maybe<Types.IInteriorDoorInput>
}>

export type ISaveInteriorDoorsMutation = {
  saveManyInteriorDoors?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IInteriorDoor, 'id'>>>
  >
}

export type ISaveInteriorPathsMutationVariables = Types.Exact<{
  interiorPaths:
    | Array<Types.Maybe<Types.IInteriorPathInput>>
    | Types.Maybe<Types.IInteriorPathInput>
}>

export type ISaveInteriorPathsMutation = {
  saveManyInteriorPaths?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IInteriorPath, 'id'>>>
  >
}

export type IDeleteLocationsMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteLocationsMutation = Pick<
  Types.IMutation,
  'deleteManyLocations'
>

export type IListenLocationsByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenLocationsByProjectIdSubscription = {
  location?: Types.Maybe<
    Pick<Types.ILocationChangeEvent, 'id'> & {
      item?: Types.Maybe<
        | (Pick<
            Types.IArea,
            | 'id'
            | 'projectId'
            | 'type'
            | 'name'
            | 'color'
            | 'iconName'
            | 'permittedCompanies'
            | 'maxOverlappingDeliveries'
            | 'maxBookingDuration'
            | 'code'
            | 'assignedSitemaps'
            | 'assignedGlobes'
            | 'isDeleted'
            | 'createdAt'
            | 'updatedAt'
          > & {
            operatingIntervals?: Types.Maybe<
              Array<
                Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
              >
            >
            parent?: Types.Maybe<
              Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
            >
          })
        | (Pick<
            Types.IBuilding,
            | 'levelsOrder'
            | 'groundLevel'
            | 'isGroundIndexZero'
            | 'skipLevelIndexTemplate'
            | 'levelBaseName'
            | 'id'
            | 'projectId'
            | 'type'
            | 'name'
            | 'color'
            | 'iconName'
            | 'permittedCompanies'
            | 'maxOverlappingDeliveries'
            | 'maxBookingDuration'
            | 'code'
            | 'assignedSitemaps'
            | 'assignedGlobes'
            | 'isDeleted'
            | 'createdAt'
            | 'updatedAt'
          > & {
            operatingIntervals?: Types.Maybe<
              Array<
                Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
              >
            >
            parent?: Types.Maybe<
              Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
            >
          })
        | (Pick<
            Types.IGate,
            | 'id'
            | 'projectId'
            | 'type'
            | 'name'
            | 'color'
            | 'iconName'
            | 'permittedCompanies'
            | 'maxOverlappingDeliveries'
            | 'maxBookingDuration'
            | 'code'
            | 'assignedSitemaps'
            | 'assignedGlobes'
            | 'isDeleted'
            | 'createdAt'
            | 'updatedAt'
          > & {
            operatingIntervals?: Types.Maybe<
              Array<
                Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
              >
            >
            parent?: Types.Maybe<
              Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
            >
          })
        | (Pick<
            Types.IIntegration,
            | 'id'
            | 'projectId'
            | 'type'
            | 'name'
            | 'color'
            | 'iconName'
            | 'permittedCompanies'
            | 'maxOverlappingDeliveries'
            | 'maxBookingDuration'
            | 'code'
            | 'assignedSitemaps'
            | 'assignedGlobes'
            | 'isDeleted'
            | 'createdAt'
            | 'updatedAt'
          > & {
            metadata?: Types.Maybe<
              Array<Types.Maybe<Pick<Types.IStringPair, 'key' | 'value'>>>
            >
            operatingIntervals?: Types.Maybe<
              Array<
                Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
              >
            >
            parent?: Types.Maybe<
              Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
            >
          })
        | (Pick<
            Types.IInteriorDoor,
            | 'maxHeight'
            | 'maxWidth'
            | 'maxWeight'
            | 'id'
            | 'projectId'
            | 'type'
            | 'name'
            | 'color'
            | 'iconName'
            | 'permittedCompanies'
            | 'maxOverlappingDeliveries'
            | 'maxBookingDuration'
            | 'code'
            | 'assignedSitemaps'
            | 'assignedGlobes'
            | 'isDeleted'
            | 'createdAt'
            | 'updatedAt'
          > & {
            operatingIntervals?: Types.Maybe<
              Array<
                Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
              >
            >
            parent?: Types.Maybe<
              Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
            >
          })
        | (Pick<
            Types.IInteriorPath,
            | 'maxHeight'
            | 'maxWidth'
            | 'maxWeight'
            | 'id'
            | 'projectId'
            | 'type'
            | 'name'
            | 'color'
            | 'iconName'
            | 'permittedCompanies'
            | 'maxOverlappingDeliveries'
            | 'maxBookingDuration'
            | 'code'
            | 'assignedSitemaps'
            | 'assignedGlobes'
            | 'isDeleted'
            | 'createdAt'
            | 'updatedAt'
          > & {
            operatingIntervals?: Types.Maybe<
              Array<
                Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
              >
            >
            parent?: Types.Maybe<
              Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
            >
          })
        | (Pick<
            Types.ILevel,
            | 'isNameImplicit'
            | 'id'
            | 'projectId'
            | 'type'
            | 'name'
            | 'color'
            | 'iconName'
            | 'permittedCompanies'
            | 'maxOverlappingDeliveries'
            | 'maxBookingDuration'
            | 'code'
            | 'assignedSitemaps'
            | 'assignedGlobes'
            | 'isDeleted'
            | 'createdAt'
            | 'updatedAt'
          > & {
            operatingIntervals?: Types.Maybe<
              Array<
                Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
              >
            >
            parent?: Types.Maybe<
              Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
            >
          })
        | (Pick<
            Types.ILogisticsObject,
            | 'id'
            | 'projectId'
            | 'type'
            | 'name'
            | 'color'
            | 'iconName'
            | 'permittedCompanies'
            | 'maxOverlappingDeliveries'
            | 'maxBookingDuration'
            | 'code'
            | 'assignedSitemaps'
            | 'assignedGlobes'
            | 'isDeleted'
            | 'createdAt'
            | 'updatedAt'
          > & {
            operatingIntervals?: Types.Maybe<
              Array<
                Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
              >
            >
            parent?: Types.Maybe<
              Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
            >
          })
        | (Pick<
            Types.IOffloadingEquipment,
            | 'accessibleLevels'
            | 'id'
            | 'projectId'
            | 'type'
            | 'name'
            | 'color'
            | 'iconName'
            | 'permittedCompanies'
            | 'maxOverlappingDeliveries'
            | 'maxBookingDuration'
            | 'code'
            | 'assignedSitemaps'
            | 'assignedGlobes'
            | 'isDeleted'
            | 'createdAt'
            | 'updatedAt'
          > & {
            operatingIntervals?: Types.Maybe<
              Array<
                Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
              >
            >
            parent?: Types.Maybe<
              Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
            >
          })
        | (Pick<
            Types.IRoute,
            | 'id'
            | 'projectId'
            | 'type'
            | 'name'
            | 'color'
            | 'iconName'
            | 'permittedCompanies'
            | 'maxOverlappingDeliveries'
            | 'maxBookingDuration'
            | 'code'
            | 'assignedSitemaps'
            | 'assignedGlobes'
            | 'isDeleted'
            | 'createdAt'
            | 'updatedAt'
          > & {
            operatingIntervals?: Types.Maybe<
              Array<
                Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
              >
            >
            parent?: Types.Maybe<
              Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
            >
          })
        | (Pick<
            Types.IStaging,
            | 'id'
            | 'projectId'
            | 'type'
            | 'name'
            | 'color'
            | 'iconName'
            | 'permittedCompanies'
            | 'maxOverlappingDeliveries'
            | 'maxBookingDuration'
            | 'code'
            | 'assignedSitemaps'
            | 'assignedGlobes'
            | 'isDeleted'
            | 'createdAt'
            | 'updatedAt'
          > & {
            operatingIntervals?: Types.Maybe<
              Array<
                Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
              >
            >
            parent?: Types.Maybe<
              Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
            >
          })
        | (Pick<
            Types.IVerticalObject,
            | 'accessibleLevels'
            | 'id'
            | 'projectId'
            | 'type'
            | 'name'
            | 'color'
            | 'iconName'
            | 'permittedCompanies'
            | 'maxOverlappingDeliveries'
            | 'maxBookingDuration'
            | 'code'
            | 'assignedSitemaps'
            | 'assignedGlobes'
            | 'isDeleted'
            | 'createdAt'
            | 'updatedAt'
          > & {
            levelsClosures?: Types.Maybe<
              Array<
                Types.Maybe<
                  Pick<Types.ILevelsClosure, 'levels'> & {
                    interval?: Types.Maybe<
                      Pick<Types.IDateInterval, 'startDate' | 'endDate'>
                    >
                  }
                >
              >
            >
            operatingIntervals?: Types.Maybe<
              Array<
                Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
              >
            >
            parent?: Types.Maybe<
              Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
            >
          })
        | (Pick<
            Types.IZone,
            | 'id'
            | 'projectId'
            | 'type'
            | 'name'
            | 'color'
            | 'iconName'
            | 'permittedCompanies'
            | 'maxOverlappingDeliveries'
            | 'maxBookingDuration'
            | 'code'
            | 'assignedSitemaps'
            | 'assignedGlobes'
            | 'isDeleted'
            | 'createdAt'
            | 'updatedAt'
          > & {
            operatingIntervals?: Types.Maybe<
              Array<
                Types.Maybe<Pick<Types.IDateInterval, 'startDate' | 'endDate'>>
              >
            >
            parent?: Types.Maybe<
              Pick<Types.IDeliveryHierarchyParent, 'parentType' | 'parentId'>
            >
          })
      >
    }
  >
}

export const LocationsByProjectIdDocument = gql`
  query LocationsByProjectId($projectId: ObjectId!) {
    locations(
      projectId: $projectId
      offset: 0
      limit: 10000000
      sort: { by: "id", order: ASCENDING }
    ) {
      data {
        id
        projectId
        type
        name
        operatingIntervals {
          startDate
          endDate
        }
        color
        iconName
        permittedCompanies
        maxOverlappingDeliveries
        maxBookingDuration
        code
        assignedSitemaps
        assignedGlobes
        isDeleted
        parent {
          parentType
          parentId
        }
        ... on Integration {
          metadata {
            key
            value
          }
        }
        ... on Level {
          isNameImplicit
        }
        ... on Building {
          levelsOrder
          groundLevel
          isGroundIndexZero
          skipLevelIndexTemplate
          levelBaseName
        }
        ... on VerticalObject {
          accessibleLevels
          levelsClosures {
            levels
            interval {
              startDate
              endDate
            }
          }
        }
        ... on OffloadingEquipment {
          accessibleLevels
        }
        createdAt
        updatedAt
      }
    }
  }
`

/**
 * __useLocationsByProjectIdQuery__
 *
 * To run a query within a React component, call `useLocationsByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useLocationsByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ILocationsByProjectIdQuery,
    ILocationsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ILocationsByProjectIdQuery,
    ILocationsByProjectIdQueryVariables
  >(LocationsByProjectIdDocument, options)
}
export function useLocationsByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ILocationsByProjectIdQuery,
    ILocationsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ILocationsByProjectIdQuery,
    ILocationsByProjectIdQueryVariables
  >(LocationsByProjectIdDocument, options)
}
export type LocationsByProjectIdQueryHookResult = ReturnType<
  typeof useLocationsByProjectIdQuery
>
export type LocationsByProjectIdLazyQueryHookResult = ReturnType<
  typeof useLocationsByProjectIdLazyQuery
>
export type LocationsByProjectIdQueryResult = Apollo.QueryResult<
  ILocationsByProjectIdQuery,
  ILocationsByProjectIdQueryVariables
>
export const SaveEquipmentsDocument = gql`
  mutation SaveEquipments($equipments: [OffloadingEquipmentInput]!) {
    saveManyOffloadingEquipments(offloadingEquipments: $equipments) {
      id
    }
  }
`
export type ISaveEquipmentsMutationFn = Apollo.MutationFunction<
  ISaveEquipmentsMutation,
  ISaveEquipmentsMutationVariables
>

/**
 * __useSaveEquipmentsMutation__
 *
 * To run a mutation, you first call `useSaveEquipmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEquipmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEquipmentsMutation, { data, loading, error }] = useSaveEquipmentsMutation({
 *   variables: {
 *      equipments: // value for 'equipments'
 *   },
 * });
 */
export function useSaveEquipmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveEquipmentsMutation,
    ISaveEquipmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveEquipmentsMutation,
    ISaveEquipmentsMutationVariables
  >(SaveEquipmentsDocument, options)
}
export type SaveEquipmentsMutationHookResult = ReturnType<
  typeof useSaveEquipmentsMutation
>
export type SaveEquipmentsMutationResult =
  Apollo.MutationResult<ISaveEquipmentsMutation>
export type SaveEquipmentsMutationOptions = Apollo.BaseMutationOptions<
  ISaveEquipmentsMutation,
  ISaveEquipmentsMutationVariables
>
export const SaveLevelsDocument = gql`
  mutation SaveLevels($levels: [LevelInput]!) {
    saveManyLevels(levels: $levels) {
      id
    }
  }
`
export type ISaveLevelsMutationFn = Apollo.MutationFunction<
  ISaveLevelsMutation,
  ISaveLevelsMutationVariables
>

/**
 * __useSaveLevelsMutation__
 *
 * To run a mutation, you first call `useSaveLevelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLevelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLevelsMutation, { data, loading, error }] = useSaveLevelsMutation({
 *   variables: {
 *      levels: // value for 'levels'
 *   },
 * });
 */
export function useSaveLevelsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveLevelsMutation,
    ISaveLevelsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISaveLevelsMutation, ISaveLevelsMutationVariables>(
    SaveLevelsDocument,
    options,
  )
}
export type SaveLevelsMutationHookResult = ReturnType<
  typeof useSaveLevelsMutation
>
export type SaveLevelsMutationResult =
  Apollo.MutationResult<ISaveLevelsMutation>
export type SaveLevelsMutationOptions = Apollo.BaseMutationOptions<
  ISaveLevelsMutation,
  ISaveLevelsMutationVariables
>
export const SaveVerticalObjectsDocument = gql`
  mutation SaveVerticalObjects($verticalObjects: [VerticalObjectInput]!) {
    saveManyVerticalObjects(verticalObjects: $verticalObjects) {
      id
    }
  }
`
export type ISaveVerticalObjectsMutationFn = Apollo.MutationFunction<
  ISaveVerticalObjectsMutation,
  ISaveVerticalObjectsMutationVariables
>

/**
 * __useSaveVerticalObjectsMutation__
 *
 * To run a mutation, you first call `useSaveVerticalObjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveVerticalObjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveVerticalObjectsMutation, { data, loading, error }] = useSaveVerticalObjectsMutation({
 *   variables: {
 *      verticalObjects: // value for 'verticalObjects'
 *   },
 * });
 */
export function useSaveVerticalObjectsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveVerticalObjectsMutation,
    ISaveVerticalObjectsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveVerticalObjectsMutation,
    ISaveVerticalObjectsMutationVariables
  >(SaveVerticalObjectsDocument, options)
}
export type SaveVerticalObjectsMutationHookResult = ReturnType<
  typeof useSaveVerticalObjectsMutation
>
export type SaveVerticalObjectsMutationResult =
  Apollo.MutationResult<ISaveVerticalObjectsMutation>
export type SaveVerticalObjectsMutationOptions = Apollo.BaseMutationOptions<
  ISaveVerticalObjectsMutation,
  ISaveVerticalObjectsMutationVariables
>
export const SaveBuildingsDocument = gql`
  mutation SaveBuildings($buildings: [BuildingInput]!) {
    saveManyBuildings(buildings: $buildings) {
      id
    }
  }
`
export type ISaveBuildingsMutationFn = Apollo.MutationFunction<
  ISaveBuildingsMutation,
  ISaveBuildingsMutationVariables
>

/**
 * __useSaveBuildingsMutation__
 *
 * To run a mutation, you first call `useSaveBuildingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBuildingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBuildingsMutation, { data, loading, error }] = useSaveBuildingsMutation({
 *   variables: {
 *      buildings: // value for 'buildings'
 *   },
 * });
 */
export function useSaveBuildingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveBuildingsMutation,
    ISaveBuildingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveBuildingsMutation,
    ISaveBuildingsMutationVariables
  >(SaveBuildingsDocument, options)
}
export type SaveBuildingsMutationHookResult = ReturnType<
  typeof useSaveBuildingsMutation
>
export type SaveBuildingsMutationResult =
  Apollo.MutationResult<ISaveBuildingsMutation>
export type SaveBuildingsMutationOptions = Apollo.BaseMutationOptions<
  ISaveBuildingsMutation,
  ISaveBuildingsMutationVariables
>
export const SaveZonesDocument = gql`
  mutation SaveZones($zones: [ZoneInput]!) {
    saveManyZones(zones: $zones) {
      id
    }
  }
`
export type ISaveZonesMutationFn = Apollo.MutationFunction<
  ISaveZonesMutation,
  ISaveZonesMutationVariables
>

/**
 * __useSaveZonesMutation__
 *
 * To run a mutation, you first call `useSaveZonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveZonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveZonesMutation, { data, loading, error }] = useSaveZonesMutation({
 *   variables: {
 *      zones: // value for 'zones'
 *   },
 * });
 */
export function useSaveZonesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveZonesMutation,
    ISaveZonesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISaveZonesMutation, ISaveZonesMutationVariables>(
    SaveZonesDocument,
    options,
  )
}
export type SaveZonesMutationHookResult = ReturnType<
  typeof useSaveZonesMutation
>
export type SaveZonesMutationResult = Apollo.MutationResult<ISaveZonesMutation>
export type SaveZonesMutationOptions = Apollo.BaseMutationOptions<
  ISaveZonesMutation,
  ISaveZonesMutationVariables
>
export const SaveGatesDocument = gql`
  mutation SaveGates($gates: [GateInput]!) {
    saveManyGates(gates: $gates) {
      id
    }
  }
`
export type ISaveGatesMutationFn = Apollo.MutationFunction<
  ISaveGatesMutation,
  ISaveGatesMutationVariables
>

/**
 * __useSaveGatesMutation__
 *
 * To run a mutation, you first call `useSaveGatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGatesMutation, { data, loading, error }] = useSaveGatesMutation({
 *   variables: {
 *      gates: // value for 'gates'
 *   },
 * });
 */
export function useSaveGatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveGatesMutation,
    ISaveGatesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISaveGatesMutation, ISaveGatesMutationVariables>(
    SaveGatesDocument,
    options,
  )
}
export type SaveGatesMutationHookResult = ReturnType<
  typeof useSaveGatesMutation
>
export type SaveGatesMutationResult = Apollo.MutationResult<ISaveGatesMutation>
export type SaveGatesMutationOptions = Apollo.BaseMutationOptions<
  ISaveGatesMutation,
  ISaveGatesMutationVariables
>
export const SaveRoutesDocument = gql`
  mutation SaveRoutes($routes: [RouteInput]!) {
    saveManyRoutes(routes: $routes) {
      id
    }
  }
`
export type ISaveRoutesMutationFn = Apollo.MutationFunction<
  ISaveRoutesMutation,
  ISaveRoutesMutationVariables
>

/**
 * __useSaveRoutesMutation__
 *
 * To run a mutation, you first call `useSaveRoutesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveRoutesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveRoutesMutation, { data, loading, error }] = useSaveRoutesMutation({
 *   variables: {
 *      routes: // value for 'routes'
 *   },
 * });
 */
export function useSaveRoutesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveRoutesMutation,
    ISaveRoutesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISaveRoutesMutation, ISaveRoutesMutationVariables>(
    SaveRoutesDocument,
    options,
  )
}
export type SaveRoutesMutationHookResult = ReturnType<
  typeof useSaveRoutesMutation
>
export type SaveRoutesMutationResult =
  Apollo.MutationResult<ISaveRoutesMutation>
export type SaveRoutesMutationOptions = Apollo.BaseMutationOptions<
  ISaveRoutesMutation,
  ISaveRoutesMutationVariables
>
export const SaveAreasDocument = gql`
  mutation SaveAreas($areas: [AreaInput]!) {
    saveManyAreas(areas: $areas) {
      id
    }
  }
`
export type ISaveAreasMutationFn = Apollo.MutationFunction<
  ISaveAreasMutation,
  ISaveAreasMutationVariables
>

/**
 * __useSaveAreasMutation__
 *
 * To run a mutation, you first call `useSaveAreasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAreasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAreasMutation, { data, loading, error }] = useSaveAreasMutation({
 *   variables: {
 *      areas: // value for 'areas'
 *   },
 * });
 */
export function useSaveAreasMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveAreasMutation,
    ISaveAreasMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISaveAreasMutation, ISaveAreasMutationVariables>(
    SaveAreasDocument,
    options,
  )
}
export type SaveAreasMutationHookResult = ReturnType<
  typeof useSaveAreasMutation
>
export type SaveAreasMutationResult = Apollo.MutationResult<ISaveAreasMutation>
export type SaveAreasMutationOptions = Apollo.BaseMutationOptions<
  ISaveAreasMutation,
  ISaveAreasMutationVariables
>
export const SaveLogisticsObjectsDocument = gql`
  mutation SaveLogisticsObjects($logisticsObjects: [LogisticsObjectInput]!) {
    saveManyLogisticsObjects(logisticsObjects: $logisticsObjects) {
      id
    }
  }
`
export type ISaveLogisticsObjectsMutationFn = Apollo.MutationFunction<
  ISaveLogisticsObjectsMutation,
  ISaveLogisticsObjectsMutationVariables
>

/**
 * __useSaveLogisticsObjectsMutation__
 *
 * To run a mutation, you first call `useSaveLogisticsObjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLogisticsObjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLogisticsObjectsMutation, { data, loading, error }] = useSaveLogisticsObjectsMutation({
 *   variables: {
 *      logisticsObjects: // value for 'logisticsObjects'
 *   },
 * });
 */
export function useSaveLogisticsObjectsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveLogisticsObjectsMutation,
    ISaveLogisticsObjectsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveLogisticsObjectsMutation,
    ISaveLogisticsObjectsMutationVariables
  >(SaveLogisticsObjectsDocument, options)
}
export type SaveLogisticsObjectsMutationHookResult = ReturnType<
  typeof useSaveLogisticsObjectsMutation
>
export type SaveLogisticsObjectsMutationResult =
  Apollo.MutationResult<ISaveLogisticsObjectsMutation>
export type SaveLogisticsObjectsMutationOptions = Apollo.BaseMutationOptions<
  ISaveLogisticsObjectsMutation,
  ISaveLogisticsObjectsMutationVariables
>
export const SaveIntegrationsDocument = gql`
  mutation SaveIntegrations($integrations: [IntegrationInput]!) {
    saveManyIntegrations(integrations: $integrations) {
      id
    }
  }
`
export type ISaveIntegrationsMutationFn = Apollo.MutationFunction<
  ISaveIntegrationsMutation,
  ISaveIntegrationsMutationVariables
>

/**
 * __useSaveIntegrationsMutation__
 *
 * To run a mutation, you first call `useSaveIntegrationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveIntegrationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveIntegrationsMutation, { data, loading, error }] = useSaveIntegrationsMutation({
 *   variables: {
 *      integrations: // value for 'integrations'
 *   },
 * });
 */
export function useSaveIntegrationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveIntegrationsMutation,
    ISaveIntegrationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveIntegrationsMutation,
    ISaveIntegrationsMutationVariables
  >(SaveIntegrationsDocument, options)
}
export type SaveIntegrationsMutationHookResult = ReturnType<
  typeof useSaveIntegrationsMutation
>
export type SaveIntegrationsMutationResult =
  Apollo.MutationResult<ISaveIntegrationsMutation>
export type SaveIntegrationsMutationOptions = Apollo.BaseMutationOptions<
  ISaveIntegrationsMutation,
  ISaveIntegrationsMutationVariables
>
export const SaveStagingsDocument = gql`
  mutation SaveStagings($stagings: [StagingInput]!) {
    saveManyStagings(stagings: $stagings) {
      id
    }
  }
`
export type ISaveStagingsMutationFn = Apollo.MutationFunction<
  ISaveStagingsMutation,
  ISaveStagingsMutationVariables
>

/**
 * __useSaveStagingsMutation__
 *
 * To run a mutation, you first call `useSaveStagingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveStagingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveStagingsMutation, { data, loading, error }] = useSaveStagingsMutation({
 *   variables: {
 *      stagings: // value for 'stagings'
 *   },
 * });
 */
export function useSaveStagingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveStagingsMutation,
    ISaveStagingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveStagingsMutation,
    ISaveStagingsMutationVariables
  >(SaveStagingsDocument, options)
}
export type SaveStagingsMutationHookResult = ReturnType<
  typeof useSaveStagingsMutation
>
export type SaveStagingsMutationResult =
  Apollo.MutationResult<ISaveStagingsMutation>
export type SaveStagingsMutationOptions = Apollo.BaseMutationOptions<
  ISaveStagingsMutation,
  ISaveStagingsMutationVariables
>
export const SaveInteriorDoorsDocument = gql`
  mutation SaveInteriorDoors($interiorDoors: [InteriorDoorInput]!) {
    saveManyInteriorDoors(interiorDoors: $interiorDoors) {
      id
    }
  }
`
export type ISaveInteriorDoorsMutationFn = Apollo.MutationFunction<
  ISaveInteriorDoorsMutation,
  ISaveInteriorDoorsMutationVariables
>

/**
 * __useSaveInteriorDoorsMutation__
 *
 * To run a mutation, you first call `useSaveInteriorDoorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveInteriorDoorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveInteriorDoorsMutation, { data, loading, error }] = useSaveInteriorDoorsMutation({
 *   variables: {
 *      interiorDoors: // value for 'interiorDoors'
 *   },
 * });
 */
export function useSaveInteriorDoorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveInteriorDoorsMutation,
    ISaveInteriorDoorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveInteriorDoorsMutation,
    ISaveInteriorDoorsMutationVariables
  >(SaveInteriorDoorsDocument, options)
}
export type SaveInteriorDoorsMutationHookResult = ReturnType<
  typeof useSaveInteriorDoorsMutation
>
export type SaveInteriorDoorsMutationResult =
  Apollo.MutationResult<ISaveInteriorDoorsMutation>
export type SaveInteriorDoorsMutationOptions = Apollo.BaseMutationOptions<
  ISaveInteriorDoorsMutation,
  ISaveInteriorDoorsMutationVariables
>
export const SaveInteriorPathsDocument = gql`
  mutation SaveInteriorPaths($interiorPaths: [InteriorPathInput]!) {
    saveManyInteriorPaths(interiorPaths: $interiorPaths) {
      id
    }
  }
`
export type ISaveInteriorPathsMutationFn = Apollo.MutationFunction<
  ISaveInteriorPathsMutation,
  ISaveInteriorPathsMutationVariables
>

/**
 * __useSaveInteriorPathsMutation__
 *
 * To run a mutation, you first call `useSaveInteriorPathsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveInteriorPathsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveInteriorPathsMutation, { data, loading, error }] = useSaveInteriorPathsMutation({
 *   variables: {
 *      interiorPaths: // value for 'interiorPaths'
 *   },
 * });
 */
export function useSaveInteriorPathsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveInteriorPathsMutation,
    ISaveInteriorPathsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveInteriorPathsMutation,
    ISaveInteriorPathsMutationVariables
  >(SaveInteriorPathsDocument, options)
}
export type SaveInteriorPathsMutationHookResult = ReturnType<
  typeof useSaveInteriorPathsMutation
>
export type SaveInteriorPathsMutationResult =
  Apollo.MutationResult<ISaveInteriorPathsMutation>
export type SaveInteriorPathsMutationOptions = Apollo.BaseMutationOptions<
  ISaveInteriorPathsMutation,
  ISaveInteriorPathsMutationVariables
>
export const DeleteLocationsDocument = gql`
  mutation DeleteLocations($ids: [ObjectId]!) {
    deleteManyLocations(ids: $ids)
  }
`
export type IDeleteLocationsMutationFn = Apollo.MutationFunction<
  IDeleteLocationsMutation,
  IDeleteLocationsMutationVariables
>

/**
 * __useDeleteLocationsMutation__
 *
 * To run a mutation, you first call `useDeleteLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationsMutation, { data, loading, error }] = useDeleteLocationsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteLocationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteLocationsMutation,
    IDeleteLocationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteLocationsMutation,
    IDeleteLocationsMutationVariables
  >(DeleteLocationsDocument, options)
}
export type DeleteLocationsMutationHookResult = ReturnType<
  typeof useDeleteLocationsMutation
>
export type DeleteLocationsMutationResult =
  Apollo.MutationResult<IDeleteLocationsMutation>
export type DeleteLocationsMutationOptions = Apollo.BaseMutationOptions<
  IDeleteLocationsMutation,
  IDeleteLocationsMutationVariables
>
export const ListenLocationsByProjectIdDocument = gql`
  subscription ListenLocationsByProjectId($projectId: ObjectId!) {
    location(projectId: $projectId) {
      id
      item {
        id
        projectId
        type
        name
        operatingIntervals {
          startDate
          endDate
        }
        color
        iconName
        permittedCompanies
        maxOverlappingDeliveries
        maxBookingDuration
        code
        assignedSitemaps
        assignedGlobes
        isDeleted
        parent {
          parentType
          parentId
        }
        ... on Integration {
          metadata {
            key
            value
          }
        }
        ... on Level {
          isNameImplicit
        }
        ... on Building {
          levelsOrder
          groundLevel
          isGroundIndexZero
          skipLevelIndexTemplate
          levelBaseName
        }
        ... on VerticalObject {
          accessibleLevels
          levelsClosures {
            levels
            interval {
              startDate
              endDate
            }
          }
        }
        ... on OffloadingEquipment {
          accessibleLevels
        }
        ... on InteriorDoor {
          maxHeight
          maxWidth
          maxWeight
        }
        ... on InteriorPath {
          maxHeight
          maxWidth
          maxWeight
        }
        createdAt
        updatedAt
      }
    }
  }
`

/**
 * __useListenLocationsByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenLocationsByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenLocationsByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenLocationsByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenLocationsByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenLocationsByProjectIdSubscription,
    IListenLocationsByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenLocationsByProjectIdSubscription,
    IListenLocationsByProjectIdSubscriptionVariables
  >(ListenLocationsByProjectIdDocument, options)
}
export type ListenLocationsByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenLocationsByProjectIdSubscription
>
export type ListenLocationsByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenLocationsByProjectIdSubscription>
