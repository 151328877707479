import { enumToList } from '~/client/src/shared/utils/converters'

import Localization from '../localization/LocalizationManager'

export enum FormCategoryType {
  DEFAULT = 'Default',
  MATERIALS = 'Materials',
  CUSTOM = 'Custom',
}

export function getFormCategoryTypeDisplayName(
  formCategoryType: FormCategoryType,
): string {
  switch (formCategoryType) {
    case FormCategoryType.DEFAULT:
      return Localization.translator.default
    case FormCategoryType.MATERIALS:
      return Localization.translator.materials
    case FormCategoryType.CUSTOM:
      return Localization.translator.custom
    default:
      throw new Error(formCategoryType + ' is unhandled')
  }
}

export const formCategoryTypeList = enumToList(FormCategoryType)
