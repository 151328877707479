import * as React from 'react'

import { MenuItem } from '@blueprintjs/core'
import { ItemRenderer, Select } from '@blueprintjs/select'

import * as Icons from '~/client/src/shared/components/Icons'

export interface IPropertySelectOption {
  label: React.ReactNode
  value: any
}

export interface IProps {
  options: IPropertySelectOption[]
  value: any
  className?: string
  onChange(value: any): void
}

export default class PropertySelect extends React.Component<IProps> {
  public render() {
    const { options, value, className = '' } = this.props
    const selectedOption = options.find(o => o.value === value)

    return (
      <Select
        items={options}
        itemRenderer={this.renderOption}
        onItemSelect={this.onItemSelect}
        className={`property-select pa8 ${className}`}
        popoverProps={{
          popoverClassName: `property-select-popover ${className}`,
          minimal: true,
        }}
        filterable={false}
      >
        <div className="row y-center pointer">
          <div>{selectedOption && selectedOption.label}</div>
          <Icons.DownArrow className="no-grow select-arrow-down" />
        </div>
      </Select>
    )
  }

  private renderOption: ItemRenderer<IPropertySelectOption> = (
    option,
    { handleClick, modifiers },
  ) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={option.value}
        onClick={handleClick}
        text={option.label}
      />
    )
  }

  private onItemSelect = (option: IPropertySelectOption) => {
    const { onChange } = this.props
    onChange(option.value)
  }
}
