import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { Swipeable } from 'react-swipeable'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'

import { DeliveryStatus, NotificationType } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import FileType from '~/client/src/shared/enums/FileType'

import { formatStatusToDisplay } from '../../constants/DeliveryStatus'
import Keys from '../../enums/Keys'
import IFilePreviewProperties from '../../interfaces/IFilePreviewProperties'
import EventsStore from '../../stores/EventStore/Events.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import {
  isFlagType,
  isRFIType,
  isScheduleCommentType,
  isStatusUpdateType,
} from '../../types/NotificationTypes'
import PdfDocument from '../PdfDocument/PdfDocument'
import PinchZoomPan from '../SitemapsGallery/PinchZoomPan'
import UsernameFromUid from '../UsernameFromUid'
import FileFullscreenPreviewStore, {
  IFullscreenPreviewComment,
  MAX_SCALE,
  MIN_SCALE,
} from './FileFullscreenPreview.store'

import './FileFullscreenPreview.scss'

const MAX_PERCENT = 100
const CARDS_OFFSET = 12
const PDF_LOADER_SIZE = 50

interface IProps {
  files: IFilePreviewProperties[]
  fileIndex: number
  onClose: () => void
  comments?: IFullscreenPreviewComment[]
  setFileIndex?: (index: number) => void
  isMobile?: boolean
  className?: string
  showToolbars?: boolean

  eventsStore?: EventsStore
  projectDateStore?: ProjectDateStore
}

@inject('eventsStore', 'projectDateStore')
@observer
export default class FileFullscreenPreview extends React.Component<IProps> {
  @observable private swipeableImageIndex: number = null
  private store: FileFullscreenPreviewStore = null
  private fullscreenPreviewRef: HTMLDivElement

  public constructor(props: IProps) {
    super(props)

    this.store = new FileFullscreenPreviewStore(props.eventsStore)
    if (props.isMobile) {
      this.swipeableImageIndex = props.fileIndex
    }
  }

  private get currentFile() {
    return this.props.files[this.swipeableImageIndex ?? this.props.fileIndex]
  }

  private get currentComment() {
    return this.props.comments?.[this.props.fileIndex]
  }

  public componentDidMount() {
    this.fullscreenPreviewRef?.focus()
    this.store.updateFileUrls(this.currentFile.fileUrl)
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (
      prevProps.files !== this.props.files ||
      prevProps.fileIndex !== this.props.fileIndex
    ) {
      this.store.updateFileUrls(this.currentFile.fileUrl)
    }
  }

  public render() {
    const { className } = this.props
    return (
      <div
        className={classList({
          'file-fullscreen-preview-container z-index-120 full-width full-height fixed':
            true,
          [className]: !!className,
        })}
        onClick={this.stopPropagation}
        ref={ref => {
          this.fullscreenPreviewRef = ref
        }}
        tabIndex={0}
        onKeyDown={this.handleKeyDown}
      >
        <div className="file-fullscreen-preview col">
          {this.renderToolbar()}
          {this.renderPreview()}
          <div className="bottom-panel-holder full-width">
            {this.currentComment && this.renderComments()}
          </div>
        </div>
      </div>
    )
  }

  private onClose = (event: React.MouseEvent) => {
    event.stopPropagation()
    this.props.onClose()
  }

  private renderToolbar() {
    const { isMobile } = this.props
    const { fileType, fileName } = this.currentFile
    const {
      scaleIn,
      scaleToFit,
      scaleOut,
      openPrevPage,
      openNextPage,
      blobUrl,
      pagesLabel,
      toolbarWidth,
    } = this.store

    switch (fileType) {
      case FileType.Image:
        return (
          <div className="top-menu-image-holder pa5 row x-between">
            <Icons.Cross
              className="cross-icon pa10 no-grow pointer"
              onClick={this.onClose}
            />
            {fileName &&
              (isMobile ? (
                <div className="text white large ellipsis">{fileName}</div>
              ) : (
                <>
                  <Icons.Photo
                    className="image-icon pa10 no-grow pointer"
                    onClick={this.onClose}
                  />
                  <div className="text white large">{fileName}</div>
                </>
              ))}
            <div className="row x-end no-grow">
              <a
                href={blobUrl}
                target="_blank"
                download={fileName}
                className="no-grow m5"
              >
                <Icons.CloudDownload className="toolbar-icon pa10" />
              </a>
              <Icons.Print
                className={classList({
                  'toolbar-icon no-grow pa10': true,
                  mr50: !isMobile,
                })}
                onClick={this.printImage}
              />
            </div>
          </div>
        )
      case FileType.Pdf:
        return (
          <div className="top-menu-pdf-holder pa5 row x-center">
            <div className="row" style={{ maxWidth: toolbarWidth }}>
              <div className="row">
                <Icon
                  className="toolbar-icon bg-dark brada3 my5 mx5 no-grow pointer"
                  icon={IconNames.ZOOM_IN}
                  onClick={scaleIn}
                />
                <Icon
                  className="toolbar-icon bg-dark brada3 my5 mx15 no-grow pointer"
                  icon={IconNames.ZOOM_TO_FIT}
                  onClick={scaleToFit}
                />
                <Icon
                  className="toolbar-icon bg-dark brada3 my5 mx5 no-grow pointer"
                  icon={IconNames.ZOOM_OUT}
                  onClick={scaleOut}
                />
              </div>

              <div className="row mx10 x-center">
                <Icon
                  className="toolbar-icon bg-dark brada3 m5 no-grow pointer"
                  icon={IconNames.CHEVRON_LEFT}
                  onClick={openPrevPage}
                />
                <div className="no-grow text white large">{pagesLabel}</div>
                <Icon
                  className="toolbar-icon bg-dark brada3 m5 no-grow pointer mr10"
                  icon={IconNames.CHEVRON_RIGHT}
                  onClick={openNextPage}
                />
              </div>
              <div className="row x-end">
                <a
                  href={blobUrl}
                  target="_blank"
                  download={fileName}
                  className="no-grow m5"
                >
                  <Icon
                    className="toolbar-icon no-grow download-button"
                    icon={IconNames.DOWNLOAD}
                  />
                </a>
                <Icons.Cross
                  className="bg-dark brada3 cross-icon m5 no-grow pointer"
                  onClick={this.onClose}
                />
              </div>
            </div>
          </div>
        )
    }
  }

  private renderPreview() {
    const { isMobile } = this.props
    const { fileType } = this.currentFile
    const {
      onScaleChanged,
      onDocumentLoadSuccess,
      pageNumber,
      url,
      scale,
      setHolderRef,
      onPageLoad,
    } = this.store
    switch (fileType) {
      case FileType.Image:
        if (isMobile) {
          return this.renderSwipeableImages()
        }
        return this.renderImagePreview()

      case FileType.Pdf:
        return (
          <PinchZoomPan
            maxScale={MAX_SCALE}
            minScale={MIN_SCALE}
            position="center"
            doubleTapBehavior="zoom"
            onScaleChanged={onScaleChanged}
            initialScale={scale}
            zoomButtons={false}
          >
            <div ref={setHolderRef} className="zoom-box-pdf-holder">
              <PdfDocument
                file={url}
                pageNumber={pageNumber}
                loaderSize={PDF_LOADER_SIZE}
                scale={scale}
                onLoadSuccess={onDocumentLoadSuccess}
                onPageLoad={onPageLoad}
              />
            </div>
          </PinchZoomPan>
        )

      default:
        throw new Error('Cannot preview file: Unhandled file type')
    }
  }

  private renderImagePreview() {
    const { url } = this.store
    return (
      <div
        key={url}
        className="full-height full-width no-flex-children row x-center"
      >
        {this.shouldRenderNavigationArrows && this.renderNavigationArrows()}
        <TransformWrapper minScale={MIN_SCALE} maxScale={MAX_SCALE}>
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <TransformComponent>
                <img className="img-viewer no-select" src={url} />
              </TransformComponent>
              <div className="bottom-panel-holder full-width">
                <div className="row x-center">
                  <div className="bottom-menu row no-grow pa2">
                    <Icon
                      className="bottom-toolbar-icon pa10 no-grow pointer"
                      icon={IconNames.ZOOM_IN}
                      onClick={() => zoomIn()}
                    />
                    <Icon
                      className="bottom-toolbar-icon pa10 no-grow pointer"
                      icon={IconNames.ZOOM_TO_FIT}
                      onClick={() => resetTransform()}
                    />
                    <Icon
                      className="bottom-toolbar-icon pa10 no-grow pointer"
                      icon={IconNames.ZOOM_OUT}
                      onClick={() => zoomOut()}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </TransformWrapper>
      </div>
    )
  }

  private get shouldRenderNavigationArrows() {
    return this.props.setFileIndex && this.props.files.length > 1
  }
  private stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  @action.bound
  private goToPreviousFile = () => {
    const { files, fileIndex, setFileIndex } = this.props
    if (fileIndex) {
      setFileIndex(fileIndex - 1)
    } else {
      setFileIndex(files.length - 1)
    }
  }

  @action.bound
  private goToNextFile = () => {
    const { files, fileIndex, setFileIndex } = this.props
    if (fileIndex === files.length - 1) {
      setFileIndex(0)
    } else {
      setFileIndex(fileIndex + 1)
    }
  }

  private swipeRight = () => {
    const { files } = this.props
    if (this.swipeableImageIndex) {
      this.swipeableImageIndex--
    } else {
      this.swipeableImageIndex = files.length - 1
    }
  }

  private swipeLeft = () => {
    const { files } = this.props
    if (this.swipeableImageIndex === files.length - 1) {
      this.swipeableImageIndex = 0
    } else {
      this.swipeableImageIndex++
    }
  }

  private renderSwipeableImages() {
    const { files } = this.props

    const percentTranslate = MAX_PERCENT * this.swipeableImageIndex
    const pixelTranslate = this.swipeableImageIndex * CARDS_OFFSET
    return (
      <Swipeable
        onSwipedRight={this.swipeRight}
        onSwipedLeft={this.swipeLeft}
        trackMouse={true}
        className="full-width full-height overflow-hidden"
      >
        <div
          className="swipeable-image-container nowrap py10 full-height"
          style={{
            transform: `translateX(calc(-${percentTranslate}% - ${pixelTranslate}px))`,
          }}
        >
          {files.map((file, index) => {
            return (
              <div
                key={index}
                className="swipeable-image inline-block mr12 full-height"
              >
                <img
                  className="img-viewer swipeable no-select"
                  src={file.fileUrl}
                />
              </div>
            )
          })}
        </div>
      </Swipeable>
    )
  }

  private renderNavigationArrows() {
    return (
      <>
        <div className="navigation-icon-wrapper-left full-height col absolute">
          <Icons.ArrowBack
            className="navigation-icon pa10 ml20"
            onClick={this.goToPreviousFile}
          />
        </div>
        <div className="navigation-icon-wrapper-right full-height col absolute">
          <Icons.ArrowForward
            className="navigation-icon pa10 mr20"
            onClick={this.goToNextFile}
          />
        </div>
      </>
    )
  }

  private renderComments() {
    const {
      timestamp,
      companyName,
      deliveryStatus,
      authorId,
      text,
      activityEntityType,
      photoDetails,
    } = this.currentComment
    const { getMonthDayAndTimeToDisplay } = this.props.projectDateStore

    return (
      <div className="row bg-white comment-holder ma-x-auto pa15">
        <div className="col y-between full-height small-col">
          <div className="text header bold">
            <div>{companyName}</div>
            <div>{getMonthDayAndTimeToDisplay(timestamp)}</div>
            <div className="row w-fit-content">
              {photoDetails.map((photoDetail, index) => {
                return (
                  <div
                    key={photoDetail + index}
                    className="no-white-space-wrap pr20"
                  >
                    {photoDetail}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="text header">
            {deliveryStatus && this.getDeliveryStatusText(deliveryStatus)}
            {activityEntityType &&
              this.getActivityStatusText(activityEntityType)}
          </div>
        </div>
        <div className="col full-height">
          <div className="text header bold">
            <UsernameFromUid userId={authorId} showCompany={true} />
          </div>
          <div className="text header">{text}</div>
        </div>
      </div>
    )
  }

  private handleKeyDown = e => {
    if (!this.shouldRenderNavigationArrows) {
      return
    }
    switch (e.key) {
      case Keys.ArrowLeft:
        this.goToPreviousFile()
        break
      case Keys.ArrowRight:
        this.goToNextFile()
        break
    }
  }

  @action.bound
  private printImage() {
    const { fileUrl } = this.currentFile
    const printFrame = document.createElement('iframe')
    printFrame.setAttribute(
      'style',
      'visibility: hidden; height: 0; width: 0; position: absolute; border: 0',
    )
    printFrame.setAttribute('id', fileUrl)
    const imageElement = document.createElement('img')
    imageElement.setAttribute(
      'style',
      'height: calc(100% - 20px);  width: 100%; position: absolute; object-fit: contain;',
    )
    imageElement.src = fileUrl
    const imageWrapper = document.createElement('div')

    imageWrapper.appendChild(imageElement)
    document.getElementsByTagName('body')[0].appendChild(printFrame)
    printFrame.contentWindow.print()

    const printDocument =
      printFrame.contentWindow?.document || printFrame.contentDocument
    printDocument.body.appendChild(imageWrapper)
  }

  private getActivityStatusText(status: NotificationType) {
    let icon
    let text

    switch (true) {
      case isFlagType(status):
        icon = <Icons.Flag className="icon-red" />
        text = 'Flag'
        break
      case isRFIType(status):
        icon = <Icons.Rfi className="icon-red" />
        text = 'RFI'
        break
      case isScheduleCommentType(status):
        icon = <Icons.DateChangeBlue className="small-col" />
        text = 'Schedule Comment'
        break
      case isStatusUpdateType(status):
        icon = <Icons.StatusUpdate className="small-col" />
        text = 'Status Update'
        break
      default:
        return null
    }
    return this.getStatus(icon, text)
  }

  private getDeliveryStatusText(status: DeliveryStatus): JSX.Element {
    let icon: JSX.Element

    switch (status) {
      case DeliveryStatus.IncompleteDone:
      case DeliveryStatus.Done:
        icon = <Icons.DeliveryDone className="small-col" />
        break
      case DeliveryStatus.OnSite:
      case DeliveryStatus.Delivering:
      case DeliveryStatus.OnHold:
        icon = <Icons.DeliveryOnsite className="small-col" />
        break
      case DeliveryStatus.PassedInspection:
        icon = <Icons.DeliveryPassedInspection className="small-col" />
        break
      case DeliveryStatus.Requested:
        icon = <Icons.DeliveryRequested className="small-col" />
        break
      case DeliveryStatus.Scheduled:
        icon = <Icons.DeliveryAccepted className="small-col" />
        break
      case DeliveryStatus.Changed:
        icon = <Icons.DeliveryAccepted className="small-col" />
        break
      case DeliveryStatus.Denied:
      case DeliveryStatus.FailedInspection:
      case DeliveryStatus.Paused:
        icon = <Icons.DeliveryFailedInspection className="small-col" />
        break
      case DeliveryStatus.Canceled:
        icon = <Icons.DeliveryAccepted className="small-col" />
        break
      default:
        return null
    }
    return this.getStatus(icon, formatStatusToDisplay(status))
  }

  private getStatus(icon: JSX.Element, text: string) {
    return (
      <div className="row">
        {icon}
        <div>{text}</div>
      </div>
    )
  }
}
