import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import FieldIds from '../../enums/DeliveryFieldIds'
import * as Icons from '../Icons'
import * as TagIcon from '../TagIcon'

import './DeliveryFieldIcon.scss'

const { TICK, MANUALLY_ENTERED_DATA } = IconNames

interface IProps {
  fieldId: FieldIds | string
  isConcreteDirect?: boolean
}

const FIELD_CLASS_NAME = 'delivery-field-icon with-color no-grow'
const FIELD_CLASS_NAME_WITHOUT_COLOR = 'delivery-field-icon no-grow'
const CONCRETE_MATERIAL_ID = 'ConcreteDirectMaterial'

const deliveryFieldIdToIconMap: { [key: string]: JSX.Element } = {
  [FieldIds.ID]: <Icons.IdIcon className={FIELD_CLASS_NAME} />,
  [FieldIds.COMPANY]: <Icons.Team className={FIELD_CLASS_NAME} />,
  [FieldIds.BOOKING_TIME]: <Icons.Clock className={FIELD_CLASS_NAME} />,
  [FieldIds.STATUS]: <Icon icon={TICK} className={FIELD_CLASS_NAME} />,
  [FieldIds.BUILDING]: <TagIcon.Building className={FIELD_CLASS_NAME} />,
  [FieldIds.ZONE]: <TagIcon.Zone className={FIELD_CLASS_NAME} />,
  [FieldIds.OFFLOADING_EQUIPMENT]: (
    <TagIcon.Equipment className={FIELD_CLASS_NAME} />
  ),
  [FieldIds.GATE]: (
    <TagIcon.Gate className={`${FIELD_CLASS_NAME_WITHOUT_COLOR} gate`} />
  ),
  [FieldIds.ROUTE]: <TagIcon.Route className={FIELD_CLASS_NAME} />,
  [FieldIds.LEVEL]: <TagIcon.Level className={FIELD_CLASS_NAME} />,
  [FieldIds.AREA]: <TagIcon.Area className={FIELD_CLASS_NAME} />,
  [FieldIds.MATERIAL_CATEGORY]: <Icons.Material className={FIELD_CLASS_NAME} />,
  [FieldIds.MATERIAL_NOTE]: (
    <Icon icon={MANUALLY_ENTERED_DATA} className={FIELD_CLASS_NAME} />
  ),
  [FieldIds.VENDOR]: <Icons.Vendor className={FIELD_CLASS_NAME} />,
  [FieldIds.TRUCK_SIZE]: <Icons.Truck className={FIELD_CLASS_NAME} />,
  [FieldIds.DRIVER_PHONE_NUMBERS]: (
    <Icons.Mobile className={FIELD_CLASS_NAME} />
  ),
  [FieldIds.ON_SITE_CONTACTS]: (
    <Icons.BallInCourt className={FIELD_CLASS_NAME} />
  ),
  [CONCRETE_MATERIAL_ID]: (
    <Icons.ConcreteDirect className={FIELD_CLASS_NAME_WITHOUT_COLOR} />
  ),
}

export default function DeliveryFieldIcon({
  fieldId,
  isConcreteDirect,
}: IProps) {
  if (fieldId === FieldIds.MATERIAL_CATEGORY && isConcreteDirect) {
    return deliveryFieldIdToIconMap[CONCRETE_MATERIAL_ID]
  }

  return deliveryFieldIdToIconMap[fieldId] || null
}
