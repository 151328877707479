import { IFilters } from '~/client/src/shared/stores/InitialState'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import BaseLogisticsMapStore from '~/client/src/shared/stores/ui/BaseLogisticsMap.store'

import LogisticsStore from '../../Logistics.store'

export default class LogisticsMapStore extends BaseLogisticsMapStore {
  public constructor(
    private readonly desktopStore: LogisticsStore,
    companiesStore: CompaniesStore,
    deliveriesStore: DeliveriesStore,
    sitePermitsStore: SitePermitsStore,
    activitiesStore: ActivitiesStore,
  ) {
    super(
      desktopStore,
      companiesStore,
      deliveriesStore,
      sitePermitsStore,
      activitiesStore,
    )
  }

  protected get filter(): IFilters {
    return this.desktopStore.filters
  }
}
