import React, { PureComponent } from 'react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

interface IProps {
  className?: string
}

export default class EmptyState extends PureComponent<IProps> {
  public render() {
    return (
      <div
        className={`row x-center full-height pa30 bg-palette-brand-lighter ${this.props.className}`}
      >
        <span className="bg-cool-grey text center grey pa10 brada3 bold">
          {Localization.translator.noDataAvailable.toLocaleUpperCase()}
        </span>
      </div>
    )
  }
}
