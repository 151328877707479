import * as React from 'react'

import { classList } from 'react-classlist-helper'

interface IProps {
  width?: number | string
  rightOffset?: number
  isLastStep?: boolean
}

export default function WorkflowDiagramChangeLine({
  width,
  rightOffset,
  isLastStep,
}: IProps) {
  return (
    <div
      className={classList({
        'absolute no-flex change-line': true,
        'last-step': isLastStep,
      })}
      style={{ width: width || '100%', right: rightOffset }}
    />
  )
}
