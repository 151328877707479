import * as React from 'react'

import { observer } from 'mobx-react'

import DeliveryControlTypes from '~/client/src/shared/enums/DeliveryControlTypes'
import IDeliveryControl, {
  IDeliveryControlOption,
} from '~/client/src/shared/models/IDeliveryControl'
import { sortCaseInsensitive } from '~/client/src/shared/utils/collections'

import SitemapAttributeIconComponent from '../../../../SitemapAttributeTag/SitemapAttributeIcon'
import StruxhubAttributeSelector from '../../../../StruxhubInputs/StruxhubSelector/StruxhubAttributeSelector'
import StruxhubCompanySelector from '../../../../StruxhubInputs/StruxhubSelector/StruxhubCompanySelector'
import StruxhubTextValueSelector from '../../../../StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'
import StruxhubUserSelector from '../../../../StruxhubInputs/StruxhubSelector/StruxhubUserSelector'

interface IProps extends IDeliveryControl {
  label?: string
  isLabelHidden?: boolean
  isHelperTextCondensed?: boolean
}

const iconProps = {
  className: 'selected-option-icon no-grow mr8 text light',
}

@observer
export default class DeliveryFormSelectorWrapper extends React.Component<IProps> {
  public render() {
    const { label, hints, isRequired, inline } = this.props

    if (this.isCompanySelect) {
      return (
        <StruxhubCompanySelector
          {...this.props}
          label={label || hints[0]}
          isRequired={isRequired}
          hasShortWidth={!!inline}
          onClick={this.onClick}
          value={this.value}
        />
      )
    }

    if (this.isUserSelect) {
      return (
        <StruxhubUserSelector
          {...this.props}
          label={label || hints[0]}
          isRequired={isRequired}
          hasShortWidth={!!inline}
          onClick={this.onClick}
          value={this.value}
        />
      )
    }

    if (this.isAttributeValue) {
      return (
        <StruxhubAttributeSelector
          {...this.props}
          label={label || hints[0]}
          isRequired={isRequired}
          hasShortWidth={!!inline}
          onClick={this.onClick}
          value={this.value}
        />
      )
    }

    return (
      <StruxhubTextValueSelector
        {...this.props}
        label={label || hints[0]}
        isRequired={isRequired}
        hasShortWidth={!!inline}
        onClick={this.onClick}
        value={this.value}
        customIconElement={this.selectedOptionIcon}
      />
    )
  }

  private get value() {
    const { value } = this.props

    if (this.isCompanySelect || this.isUserSelect) {
      return value
    }
    if (this.isAttributeValue) {
      return this.selectedOption.value
    }

    return this.simpleTextValue
  }

  private get selectedOption(): IDeliveryControlOption {
    const { value, options } = this.props
    return options?.find(option => option.value === value)
  }

  private get isAttributeValue(): boolean {
    return !!this.selectedOption?.relatedAttribute
  }

  private get simpleTextValue(): string {
    const { value, options } = this.props

    if (!options) {
      return value
    }

    const selectedOptionsTitles = options
      .filter(o => o.value === value)
      .map(o => o.title)

    const sortedTitles = sortCaseInsensitive(selectedOptionsTitles).join(', ')

    return sortedTitles
  }

  private get selectedOptionIcon(): JSX.Element {
    const { value, getOptionsIcon } = this.props

    if (this.isAttributeValue) {
      return (
        <SitemapAttributeIconComponent
          customProps={{ className: 'no-grow mr8' }}
          dataObject={this.selectedOption?.relatedAttribute}
        />
      )
    }

    if (!!value && getOptionsIcon) {
      return getOptionsIcon(iconProps)
    }

    return null
  }

  private get isMultiSelection(): boolean {
    return this.props.type === DeliveryControlTypes.MULTI_COMBOBOX
  }

  private get isUserSelect(): boolean {
    return this.props.type === DeliveryControlTypes.USER_SELECT
  }

  private get isCompanySelect(): boolean {
    return this.props.type === DeliveryControlTypes.COMPANY_SELECT
  }

  private onClick = () => {
    const { isDisabled, isReadOnly, id, index, onClick } = this.props

    if (isDisabled || isReadOnly) {
      return
    }

    onClick?.(id, index)
  }
}
