import * as React from 'react'

import { observer } from 'mobx-react'

import CompactCompaniesDirectory from '~/client/src/shared/components/CompactCompaniesDirectory/CompactCompaniesDirectory'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import PermittedCompaniesPickerModalStore from './PermittedCompaniesPickerModal.store'

import './PermittedCompaniesPickerModal.scss'

// localization: translated

interface IProps {
  permittedCompanies: string[]
  onClose: () => void
  onPermit: (permittedCompanies: string[]) => void
}

@observer
export default class PermittedCompaniesPickerModal extends React.Component<IProps> {
  private readonly store: PermittedCompaniesPickerModalStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new PermittedCompaniesPickerModalStore(
      props.onPermit,
      props.permittedCompanies,
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { permittedCompanies } = this.props
    if (prevProps.permittedCompanies !== permittedCompanies) {
      this.store.init(permittedCompanies)
    }
  }

  public render() {
    const { onClose } = this.props
    const {
      selectedCompanyIdsArray,
      permitCompanies,
      resetRestriction,
      toggleCompanySelection,
    } = this.store
    const { permitAll, permit } = Localization.translator

    return (
      <MenuCloser closeMenu={onClose}>
        <div className="col absolute permitted-companies-container beautiful-shadow brada4">
          <CompactCompaniesDirectory
            className="relative"
            isPhoneHidden={true}
            isSelectionMode={true}
            selectedCompanyIds={selectedCompanyIdsArray}
            onHeaderCloseClick={onClose}
            onCompanyAddClick={toggleCompanySelection}
            onCompanyRemoveClick={toggleCompanySelection}
          />
          <footer className="row bt-palette-brand-lighter">
            <button
              className="text large bold blue pointer nowrap"
              onClick={resetRestriction}
            >
              {permitAll}
            </button>
            <button
              className="text large bold white pointer"
              onClick={permitCompanies}
            >
              {permit}
            </button>
          </footer>
        </div>
      </MenuCloser>
    )
  }
}
