import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { IPermitTypeField } from '~/client/graph'
import StruxhubUserSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubUserSelector'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'

import SitePermitCreationFormStore from '../../SitePermitCreationForm.store'
import PermitBaseFormField from './PermitBaseFormField'
import PermitFieldRemoveIcon from './PermitFieldRemoveIcon'

interface IProps {
  typeField: IPermitTypeField
  isViewMode: boolean

  store: SitePermitCreationFormStore
  fieldsStore: IPermitFieldsStore

  tableId?: string
  tableRowIndex?: number

  projectMembersStore?: ProjectMembersStore
}

@inject('projectMembersStore')
@observer
export default class PermitFormUserField extends React.Component<IProps> {
  public render() {
    const {
      isViewMode,
      typeField,
      fieldsStore,
      projectMembersStore,
      tableId,
      tableRowIndex,
    } = this.props
    const { caption, isMandatory, isMultiple } = typeField
    const { isFieldChanged } = fieldsStore

    return (
      <PermitBaseFormField
        typeField={typeField}
        isViewMode={isViewMode}
        viewModeElements={this.userProfilePreviews}
        onAddFieldClick={this.addNewFieldValue}
      >
        {this.fieldValues.map((id, index) => {
          const value = !projectMembersStore.hasById(id) ? '' : id

          const shouldShowRemoveBtn = !!index || !!value

          return (
            <div key={`${id}_${index}`} className="row y-center">
              <StruxhubUserSelector
                className="overflow-hidden"
                label={caption}
                isRequired={isMandatory}
                isChanged={isFieldChanged(
                  typeField.id,
                  index,
                  tableId,
                  tableRowIndex,
                )}
                isLabelHidden={!!index}
                isHelperTextCondensed={isMultiple}
                onClick={this.onOpenUserModal.bind(this, index)}
                value={id}
              />
              {isMultiple && shouldShowRemoveBtn && (
                <PermitFieldRemoveIcon
                  index={index}
                  onClick={this.removeValue}
                />
              )}
            </div>
          )
        })}
      </PermitBaseFormField>
    )
  }

  private get userProfilePreviews(): JSX.Element[] {
    const { projectMembersStore } = this.props

    return this.fieldValues
      .filter(id => projectMembersStore.hasById(id))
      .map(id => {
        const user = projectMembersStore.getById(id)
        return <UserProfilePreview key={user.id} user={user} />
      })
  }

  private get fieldValues(): string[] {
    const { typeField, fieldsStore, tableId, tableRowIndex } = this.props
    const values = fieldsStore.getFieldValues<string>(
      typeField.id,
      tableId,
      tableRowIndex,
    )

    return values?.length ? values : ['']
  }

  private onOpenUserModal(fieldIndex: number) {
    const { store, typeField, tableId, tableRowIndex } = this.props
    store.setSelectedField(typeField, fieldIndex, tableId, tableRowIndex)
  }

  private addNewFieldValue = () => {
    if (!this.isTableField) {
      const { fieldsStore, typeField } = this.props
      fieldsStore.addNewFieldValue(typeField, '')
    }
  }

  private removeValue = (index: number) => {
    if (!this.isTableField) {
      const { fieldsStore, typeField } = this.props
      fieldsStore.removeFieldValue(typeField.id, index)
    }
  }

  private get isTableField(): boolean {
    return !!this.props.tableId
  }
}
