import * as React from 'react'

import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import './DraggableGrid.scss'

// localization: no display text to translate

export interface IProps {
  items: React.ReactNode[]
  onDragEnd(oldIndex: number, newIndex: number): void
}

const SortableItem = SortableElement(({ item }) => (
  <div className="sortable-item">{item}</div>
))

const SortableList = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((item, index) => (
        <SortableItem key={`item-${index}`} index={index} item={item} />
      ))}
    </div>
  )
})

export default class DraggableGrid extends React.Component<IProps> {
  public render() {
    const { items } = this.props
    return (
      <SortableList
        items={items}
        onSortEnd={this.onSortEnd}
        axis="xy"
        distance={2}
      />
    )
  }

  private onSortEnd = ({ oldIndex, newIndex }) => {
    const { onDragEnd } = this.props
    onDragEnd(oldIndex, newIndex)
  }
}
