import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import AlertDialog from '~/client/src/shared/components/AlertDialog/AlertDialog'
import { Loader } from '~/client/src/shared/components/Loader'
import FileType from '~/client/src/shared/enums/FileType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityCodeTypesStore from '~/client/src/shared/stores/domain/ActivityCodeTypes.store'
import ActivityCodesStore from '~/client/src/shared/stores/domain/ActivityCodes.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import { FileUploadingStore } from '~/client/src/shared/stores/domain/FileUploading.store'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import DndArea from '../../../../../shared/components/DndArea/DndArea'
import ProjectSetUpPageStore from '../../ProjectSetUpPage.store'
import ActivityFilterSetupDialog from '../ActivityFilters/components/ActivityFilterSetupDialog'
import ActivityFilterSetupDialogStore from '../ActivityFilters/components/ActivityFilterSetupDialog.store'
import ProjectScheduleUploadStore, {
  ScheduleStatus,
} from './ProjectScheduleUpload.store'
import ChooseProjectDialog from './components/ChooseProjectDialog/ChooseProjectDialog'
import ChooseSheetDialog from './components/ChooseSheetDialog/ChooseSheetDialog'
import DownloadActivitiesButton from './components/DownloadActivitiesButton'
import MapFieldsDialog from './components/MapFieldsDialog/MapFieldsDialog'
import ScheduleUpdatesSummary from './components/ScheduleUpdatesSummary/ScheduleUpdatesSummary'
import ScheduleUploadButtonAndStatus from './components/ScheduleUploadButtonAndStatus'
import ScheduleUploadHistory from './components/ScheduleUploadHistory/ScheduleUploadHistory'
import ScheduleUploadHistoryStore from './components/ScheduleUploadHistory/ScheduleUploadHistory.store'
import VerificationBlock from './components/VerificationBlock'

interface IProps {
  common?: DesktopCommonStore
  activitiesStore?: ActivitiesStore
  activityFiltersStore?: ActivityFiltersStore
  eventsStore?: DesktopEventStore
  projectSetUpPageStore: ProjectSetUpPageStore
  activityCodeTypesStore?: ActivityCodeTypesStore
  activityCodesStore?: ActivityCodesStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  graphExecutorStore?: GraphExecutorStore
  fileUploadingStore?: FileUploadingStore
}

@inject(
  'common',
  'activitiesStore',
  'activityFiltersStore',
  'eventsStore',
  'activityCodeTypesStore',
  'activityCodesStore',
  'userProjectsStore',
  'projectMembersStore',
  'graphExecutorStore',
  'fileUploadingStore',
)
@observer
export default class ProjectScheduleUpload extends React.Component<IProps> {
  private readonly store: ProjectScheduleUploadStore
  private readonly scheduleUploadHistoryStore: ScheduleUploadHistoryStore
  private readonly dialogStore: ActivityFilterSetupDialogStore

  public constructor(props: IProps) {
    super(props)

    this.scheduleUploadHistoryStore = new ScheduleUploadHistoryStore(
      props.projectSetUpPageStore,
      props.eventsStore,
      props.common,
      props.activityCodeTypesStore,
    )

    this.dialogStore = new ActivityFilterSetupDialogStore(
      props.activityFiltersStore,
      props.eventsStore,
      props.activitiesStore,
      props.activityCodeTypesStore,
      props.activityCodesStore,
      props.userProjectsStore,
      props.projectMembersStore,
    )
    this.store = new ProjectScheduleUploadStore(
      props.common,
      props.activityFiltersStore,
      props.eventsStore,
      props.projectSetUpPageStore,
      this.scheduleUploadHistoryStore,
      this.dialogStore,
      props.activityCodesStore,
      props.graphExecutorStore,
      props.fileUploadingStore,
    )
    this.store.resetInitialValue()
  }

  public componentWillUnmount() {
    this.store.stopListeningToScheduleUpload()
  }

  public render() {
    const {
      ganttViewStatus,
      isNoProjectsErrorDialogShown,
      hideErrorDialog,
      onHiddenActivityCodesClosed,
      isScheduleRestoring,
      isProjectLoadingOrUpdating,
    } = this.store

    if (isScheduleRestoring || isProjectLoadingOrUpdating) {
      return this.loader
    }

    return (
      <form className="setup-form setup-form-fixed-width">
        <div className="setup-form-header bb-light-grey mb15 row text uppercase">
          <div>{Localization.translator.uploadSchedule}</div>
          <DownloadActivitiesButton />
        </div>
        <div className="upload-schedule-container">
          <DndArea
            fileType={FileType.ProjectSchedule}
            onDrop={this.store.uploadFile}
          />
        </div>
        <ScheduleUploadButtonAndStatus
          projectScheduleUploadStore={this.store}
        />

        <AlertDialog
          isOpen={isNoProjectsErrorDialogShown}
          onClose={hideErrorDialog}
        >
          {Localization.translator.thereAreNoProjectsInProvidedFile}
        </AlertDialog>
        <ScheduleUpdatesSummary
          scheduleUploadHistoryStore={this.scheduleUploadHistoryStore}
          projectScheduleUploadStore={this.store}
        />
        <ScheduleUploadHistory
          scheduleUploadHistoryStore={this.scheduleUploadHistoryStore}
          projectScheduleUploadStore={this.store}
        />
        <ChooseProjectDialog projectScheduleUploadStore={this.store} />
        <ChooseSheetDialog projectScheduleUploadStore={this.store} />
        <MapFieldsDialog store={this.store} />
        <ActivityFilterSetupDialog
          // saving happens in onHiddenActivityCodesClosed
          shouldSkipSavingDuringApplying={true}
          onDialogClosed={onHiddenActivityCodesClosed}
          store={this.dialogStore}
        />

        {ganttViewStatus === ScheduleStatus.Ready && (
          <VerificationBlock projectScheduleUploadStore={this.store} />
        )}
      </form>
    )
  }

  private get loader() {
    const hint = this.store.isScheduleRestoring
      ? Localization.translator.revertingProjectData
      : Localization.translator.loadingUpdatingProject

    return <Loader hint={hint} />
  }
}
