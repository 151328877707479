import { action, computed, observable } from 'mobx'

import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'

const ACTIVITY_NAME_COLUMN_WIDTH = 1000
const LIST_ACTIVITY_NAME_COLUMN_WIDTH = 895
const PROGRESS_COLUMN_WIDTH = 160
const COMPANY_COLUMN_WIDTH = 160
const ASSIGNEE_COLUMN_WIDTH = 170
const COLLAPSED_SECTION_WIDTH = 19
const SCHEDULE_SECTION_WIDTH = 280
const DURATION_SECTION_WIDTH = 250
const GANTT_SCHEDULE_SECTION_WIDTH = 385
const COMMENTS_SECTION_WIDTH = 350
const EXPANDED_SECTION_OFFSET = 5
const COLLAPSED_SECTION_OFFSET = -4

export default class TableOffsetsColumnCollapseStore {
  @observable public shouldUseDefaultCollapseStateForProgress: boolean = true
  @observable public shouldCollapseAssignee: boolean = false
  @observable public shouldCollapseDuration: boolean = false
  @observable public shouldCollapsePlannedSchedule: boolean = false
  @observable public shouldCollapseActualSchedule: boolean = false
  @observable public shouldCollapseComments: boolean = false

  public constructor(
    private readonly activityFiltersStore: ActivityFiltersStore,
    private readonly state: DesktopInitialState,
  ) {}

  @action.bound
  public toggleProgressBarCollapseState() {
    this.shouldUseDefaultCollapseStateForProgress =
      !this.shouldUseDefaultCollapseStateForProgress
  }

  @action.bound
  public toggleAssigneeBarCollapseState() {
    this.shouldCollapseAssignee = !this.shouldCollapseAssignee
  }

  @action.bound
  public toggleDurationBarCollapseState() {
    this.shouldCollapseDuration = !this.shouldCollapseDuration
  }

  @action.bound
  public togglePlannedScheduleBarCollapseState() {
    this.shouldCollapsePlannedSchedule = !this.shouldCollapsePlannedSchedule
  }

  @action.bound
  public toggleActualScheduleBarCollapseState() {
    this.shouldCollapseActualSchedule = !this.shouldCollapseActualSchedule
  }

  @action.bound
  public toggleCommentsCollapseState() {
    this.shouldCollapseComments = !this.shouldCollapseComments
  }

  public get assigneeSectionOffset(): number {
    return this.shouldCollapseAssignee
      ? COLLAPSED_SECTION_OFFSET
      : EXPANDED_SECTION_OFFSET
  }

  public get durationSectionOffset(): number {
    let leftOffset = this.shouldCollapseDuration
      ? COLLAPSED_SECTION_OFFSET
      : EXPANDED_SECTION_OFFSET
    leftOffset += this.shouldCollapseAssignee
      ? COLLAPSED_SECTION_WIDTH - 1
      : this.assigneeSectionWidth

    return leftOffset
  }

  public get plannedScheduleSectionOffset(): number {
    let leftOffset = this.shouldCollapsePlannedSchedule
      ? COLLAPSED_SECTION_OFFSET
      : EXPANDED_SECTION_OFFSET
    leftOffset += this.shouldCollapseAssignee
      ? COLLAPSED_SECTION_WIDTH - 1
      : this.assigneeSectionWidth
    leftOffset += this.shouldCollapseDuration
      ? COLLAPSED_SECTION_WIDTH - 1
      : DURATION_SECTION_WIDTH

    return leftOffset
  }

  public get actualScheduleSectionOffset(): number {
    let leftOffset = this.shouldCollapseActualSchedule
      ? COLLAPSED_SECTION_OFFSET
      : EXPANDED_SECTION_OFFSET
    leftOffset += this.shouldCollapseAssignee
      ? COLLAPSED_SECTION_WIDTH - 1
      : this.assigneeSectionWidth
    leftOffset += this.shouldCollapseDuration
      ? COLLAPSED_SECTION_WIDTH - 1
      : DURATION_SECTION_WIDTH
    leftOffset += this.shouldCollapsePlannedSchedule
      ? COLLAPSED_SECTION_WIDTH - 1
      : SCHEDULE_SECTION_WIDTH

    return leftOffset
  }

  public get commentsSectionOffset(): number {
    let leftOffset = this.shouldCollapseComments
      ? COLLAPSED_SECTION_OFFSET
      : EXPANDED_SECTION_OFFSET
    leftOffset += this.shouldCollapseAssignee
      ? COLLAPSED_SECTION_WIDTH - 1
      : this.assigneeSectionWidth
    leftOffset += this.shouldCollapseDuration
      ? COLLAPSED_SECTION_WIDTH - 1
      : DURATION_SECTION_WIDTH
    leftOffset += this.shouldCollapsePlannedSchedule
      ? COLLAPSED_SECTION_WIDTH - 1
      : SCHEDULE_SECTION_WIDTH
    leftOffset += this.shouldCollapseActualSchedule
      ? COLLAPSED_SECTION_WIDTH - 1
      : SCHEDULE_SECTION_WIDTH

    return leftOffset
  }

  public get assigneeArrowOffset(): number {
    return this.shouldCollapseAssignee
      ? COLLAPSED_SECTION_WIDTH
      : this.assigneeSectionWidth
  }

  public get durationArrowOffset(): number {
    let leftOffset = this.assigneeArrowOffset
    leftOffset += this.shouldCollapseDuration
      ? COLLAPSED_SECTION_WIDTH
      : DURATION_SECTION_WIDTH

    return leftOffset
  }

  public get plannedScheduleArrowOffset(): number {
    let leftOffset = this.durationArrowOffset
    leftOffset += this.shouldCollapsePlannedSchedule
      ? COLLAPSED_SECTION_WIDTH
      : SCHEDULE_SECTION_WIDTH

    return leftOffset
  }

  public get actualScheduleArrowOffset(): number {
    let leftOffset = this.plannedScheduleArrowOffset
    leftOffset += this.shouldCollapseActualSchedule
      ? COLLAPSED_SECTION_WIDTH
      : SCHEDULE_SECTION_WIDTH

    return leftOffset
  }

  public get commentsArrowOffset(): number {
    let leftOffset = this.actualScheduleArrowOffset
    leftOffset += this.shouldCollapseComments
      ? COLLAPSED_SECTION_WIDTH
      : COMMENTS_SECTION_WIDTH

    return leftOffset
  }

  private get assigneeSectionWidth(): number {
    return ASSIGNEE_COLUMN_WIDTH
  }

  @computed
  public get collapsedSectionsWidth(): number {
    const sections = [
      this.shouldCollapseAssignee,
      this.shouldCollapseDuration,
      this.shouldCollapsePlannedSchedule,
      this.shouldCollapseActualSchedule,
      this.shouldCollapseComments,
    ]

    return sections.reduce((sum, isCollapsed, currentIndex) => {
      if (isCollapsed) {
        sum += COLLAPSED_SECTION_WIDTH
      }
      if (currentIndex === sections.length - 1 && isCollapsed) {
        sum += COLLAPSED_SECTION_WIDTH
      }
      return sum
    }, 0)
  }

  @computed
  public get activityNameSectionWidth(): number {
    const { areCompaniesEnabled } = this.activityFiltersStore

    const companiesColWidth = areCompaniesEnabled ? 0 : COMPANY_COLUMN_WIDTH

    if (!this.shouldUseDefaultCollapseStateForProgress) {
      return this.state.activityList.viewMode === ViewModes.Gantt
        ? ACTIVITY_NAME_COLUMN_WIDTH -
            GANTT_SCHEDULE_SECTION_WIDTH -
            COLLAPSED_SECTION_WIDTH
        : LIST_ACTIVITY_NAME_COLUMN_WIDTH -
            PROGRESS_COLUMN_WIDTH -
            companiesColWidth +
            COLLAPSED_SECTION_WIDTH
    }

    return this.state.activityList.viewMode === ViewModes.Gantt
      ? ACTIVITY_NAME_COLUMN_WIDTH
      : LIST_ACTIVITY_NAME_COLUMN_WIDTH - companiesColWidth
  }
}
