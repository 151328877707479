import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ILogisticsConfigurationsFieldsFragment = Pick<
  Types.ILogisticsConfigurations,
  'id' | 'projectId'
> & {
  maps?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.IOrderedSitemap, 'globeViewId' | 'sitemapId' | 'order'>
      >
    >
  >
}

export type IGetLogisticsConfigurationsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetLogisticsConfigurationsQuery = {
  logisticsConfigurations?: Types.Maybe<{
    data: Array<ILogisticsConfigurationsFieldsFragment>
  }>
}

export type ISaveLogisticsConfigurationsMutationVariables = Types.Exact<{
  logisticsConfigurations: Types.ILogisticsConfigurationsInput
}>

export type ISaveLogisticsConfigurationsMutation = {
  saveLogisticsConfigurations?: Types.Maybe<ILogisticsConfigurationsFieldsFragment>
}

export type IListenToLogisticsConfigurationsSubscriptionVariables =
  Types.Exact<{
    projectId: Types.Scalars['ObjectId']
  }>

export type IListenToLogisticsConfigurationsSubscription = {
  logisticsConfigurations?: Types.Maybe<
    Pick<Types.ILogisticsConfigurationsChangeEvent, 'id'> & {
      item?: Types.Maybe<ILogisticsConfigurationsFieldsFragment>
    }
  >
}

export const LogisticsConfigurationsFieldsFragmentDoc = gql`
  fragment LogisticsConfigurationsFields on LogisticsConfigurations {
    id
    maps {
      globeViewId
      sitemapId
      order
    }
    projectId
  }
`
export const GetLogisticsConfigurationsDocument = gql`
  query GetLogisticsConfigurations($projectId: ObjectId!) {
    logisticsConfigurations(projectId: $projectId, limit: 1) {
      data {
        ...LogisticsConfigurationsFields
      }
    }
  }
  ${LogisticsConfigurationsFieldsFragmentDoc}
`

/**
 * __useGetLogisticsConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetLogisticsConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLogisticsConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLogisticsConfigurationsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetLogisticsConfigurationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetLogisticsConfigurationsQuery,
    IGetLogisticsConfigurationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetLogisticsConfigurationsQuery,
    IGetLogisticsConfigurationsQueryVariables
  >(GetLogisticsConfigurationsDocument, options)
}
export function useGetLogisticsConfigurationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetLogisticsConfigurationsQuery,
    IGetLogisticsConfigurationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetLogisticsConfigurationsQuery,
    IGetLogisticsConfigurationsQueryVariables
  >(GetLogisticsConfigurationsDocument, options)
}
export type GetLogisticsConfigurationsQueryHookResult = ReturnType<
  typeof useGetLogisticsConfigurationsQuery
>
export type GetLogisticsConfigurationsLazyQueryHookResult = ReturnType<
  typeof useGetLogisticsConfigurationsLazyQuery
>
export type GetLogisticsConfigurationsQueryResult = Apollo.QueryResult<
  IGetLogisticsConfigurationsQuery,
  IGetLogisticsConfigurationsQueryVariables
>
export const SaveLogisticsConfigurationsDocument = gql`
  mutation SaveLogisticsConfigurations(
    $logisticsConfigurations: LogisticsConfigurationsInput!
  ) {
    saveLogisticsConfigurations(
      logisticsConfigurations: $logisticsConfigurations
    ) {
      ...LogisticsConfigurationsFields
    }
  }
  ${LogisticsConfigurationsFieldsFragmentDoc}
`
export type ISaveLogisticsConfigurationsMutationFn = Apollo.MutationFunction<
  ISaveLogisticsConfigurationsMutation,
  ISaveLogisticsConfigurationsMutationVariables
>

/**
 * __useSaveLogisticsConfigurationsMutation__
 *
 * To run a mutation, you first call `useSaveLogisticsConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLogisticsConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLogisticsConfigurationsMutation, { data, loading, error }] = useSaveLogisticsConfigurationsMutation({
 *   variables: {
 *      logisticsConfigurations: // value for 'logisticsConfigurations'
 *   },
 * });
 */
export function useSaveLogisticsConfigurationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveLogisticsConfigurationsMutation,
    ISaveLogisticsConfigurationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveLogisticsConfigurationsMutation,
    ISaveLogisticsConfigurationsMutationVariables
  >(SaveLogisticsConfigurationsDocument, options)
}
export type SaveLogisticsConfigurationsMutationHookResult = ReturnType<
  typeof useSaveLogisticsConfigurationsMutation
>
export type SaveLogisticsConfigurationsMutationResult =
  Apollo.MutationResult<ISaveLogisticsConfigurationsMutation>
export type SaveLogisticsConfigurationsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveLogisticsConfigurationsMutation,
    ISaveLogisticsConfigurationsMutationVariables
  >
export const ListenToLogisticsConfigurationsDocument = gql`
  subscription ListenToLogisticsConfigurations($projectId: ObjectId!) {
    logisticsConfigurations(projectId: $projectId) {
      id
      item {
        ...LogisticsConfigurationsFields
      }
    }
  }
  ${LogisticsConfigurationsFieldsFragmentDoc}
`

/**
 * __useListenToLogisticsConfigurationsSubscription__
 *
 * To run a query within a React component, call `useListenToLogisticsConfigurationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToLogisticsConfigurationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToLogisticsConfigurationsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToLogisticsConfigurationsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToLogisticsConfigurationsSubscription,
    IListenToLogisticsConfigurationsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToLogisticsConfigurationsSubscription,
    IListenToLogisticsConfigurationsSubscriptionVariables
  >(ListenToLogisticsConfigurationsDocument, options)
}
export type ListenToLogisticsConfigurationsSubscriptionHookResult = ReturnType<
  typeof useListenToLogisticsConfigurationsSubscription
>
export type ListenToLogisticsConfigurationsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToLogisticsConfigurationsSubscription>
