import { IAnnouncement } from '~/client/graph'

import Announcement from '../../models/Announcement'
import EventsStore from '../EventStore/Events.store'
import BaseStoreWithById from '../baseStores/BaseWithById.store'
import ProjectDateStore from '../ui/ProjectDate.store'

export default class AnnouncementsStore extends BaseStoreWithById<
  Announcement,
  IAnnouncement
> {
  public constructor(
    private eventsStore: EventsStore,
    private projectDateStore: ProjectDateStore,
  ) {
    super(Announcement)
  }

  public getAnnouncementsForDay = (date: Date | number) => {
    const { isWithinDateInterval } = this.projectDateStore
    return this.list.filter(ann =>
      isWithinDateInterval(ann.startDate, ann.endDate, date),
    )
  }

  public getRelatedActiveClosureAnnouncements = (
    attributeId: string,
  ): Announcement[] => {
    return this.list.filter(announcement => {
      return (
        announcement.location?.id === attributeId &&
        announcement.isClosure &&
        this.projectDateStore.isWithinDateInterval(
          announcement.startDate,
          announcement.endDate,
          new Date(),
        )
      )
    })
  }

  public getRelatedAnnouncements = (attributeId: string): Announcement[] => {
    return this.list.filter(announcement => {
      return announcement.location.id === attributeId
    })
  }

  public getClosedIntervals = (attributeId: string) => {
    return this.list.filter(announcement => {
      const isRelatedAnnouncement = announcement.location?.id === attributeId
      return isRelatedAnnouncement && announcement.isClosure
    })
  }

  public get byId() {
    return this.eventsStore.appState.announcements
  }

  public clearList() {
    this.isDataReceived = false
    const { announcements } = this.eventsStore.appState
    announcements.clear()
  }

  public updateFromList(list: IAnnouncement[]) {
    const { announcements } = this.eventsStore.appState

    list.forEach(dto => {
      const announcement = Announcement.fromDto(dto, this.projectDateStore)
      announcements.set(announcement.id, announcement)
    })
  }

  public receiveOne(id: string, dto: IAnnouncement) {
    if (dto) {
      this.updateOne(id, dto)
    } else {
      this.byId.delete(id)
    }
  }

  public isDeliveryAttributeClosed(attributeId?: string) {
    return (
      attributeId &&
      this.list.some(announcement => {
        const isRelatedAnnouncement = announcement.location?.id === attributeId
        return (
          isRelatedAnnouncement &&
          announcement.isClosure &&
          this.projectDateStore.isWithinDateInterval(
            announcement.startDate,
            announcement.endDate,
            new Date(),
          )
        )
      })
    )
  }

  protected createAnInstance(id: string, dto: any) {
    return Announcement.fromDto(dto, this.projectDateStore)
  }
}
