import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import { TwoMonthsDatePickerMode } from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import BaseEntityAssociationStatus from '~/client/src/shared/components/BaseEntityAssociationStatus/BaseEntityAssociationStatus'
import Checkbox from '~/client/src/shared/components/Checkbox'
import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import DeliveryStatusLabel from '~/client/src/shared/components/DeliveryStatusLabel/DeliveryStatusLabel'
import * as Icons from '~/client/src/shared/components/Icons'
import OpeningIndicatorLabel from '~/client/src/shared/components/OpeningIndicatorLabel/OpeningIndicatorLabel'
import UsernameFromUid from '~/client/src/shared/components/UsernameFromUid'
import WorkflowCardStatus from '~/client/src/shared/components/WorkflowCard/Status'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import {
  getActivityStatusDisplayName,
  getMonitoringStatusDisplayName,
} from '~/client/src/shared/localization/enumDisplayTexts'
import { LogisticItemApp } from '~/client/src/shared/models/ILogisticItem'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import User from '~/client/src/shared/models/User'
import ActivityAssignmentsStore from '~/client/src/shared/stores/domain/ActivityAssignments.store'
import ActivityFollowingsStore from '~/client/src/shared/stores/domain/ActivityFollowings.store'
import AnnouncementAssignmentsStore from '~/client/src/shared/stores/domain/AnnouncementAssignments.store'
import AnnouncementFollowingsStore from '~/client/src/shared/stores/domain/AnnouncementFollowings.store'
import CastAssignmentsStore from '~/client/src/shared/stores/domain/CastAssignments.store'
import CastFollowingsStore from '~/client/src/shared/stores/domain/CastFollowings.store'
import ClosureAssignmentsStore from '~/client/src/shared/stores/domain/ClosureAssignments.store'
import ClosureFollowingsStore from '~/client/src/shared/stores/domain/ClosureFollowings.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveryAssignmentsStore from '~/client/src/shared/stores/domain/DeliveryAssignments.store'
import DeliveryFollowingsStore from '~/client/src/shared/stores/domain/DeliveryFollowings.store'
import SitePermitAssignmentsStore from '~/client/src/shared/stores/domain/SitePermitAssignments.store'
import SitePermitFollowingsStore from '~/client/src/shared/stores/domain/SitePermitFollowings.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { logisticsDataKeys } from '~/client/src/shared/types/LogisticsDataKeys'
import { NOOP } from '~/client/src/shared/utils/noop'
import { BULLET, NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import LogisticsStore from '../../Logistics.store'
import LogisticsListStore from './LogisticsList.store'
import LogisticsListTable from './components/LogisticsListTable/LogisticsListTable'
import LogisticsListLocationCell from './components/LogisticsListTable/components/LogisticsListLocationCell'

const { opened, closed } = Localization.translator

interface IProps {
  logisticsStore: LogisticsStore
  store: LogisticsListStore

  state?: DesktopInitialState
  tagsStore?: TagsStore
  companiesStore?: CompaniesStore
  closureFollowingsStore?: ClosureFollowingsStore
  closureAssignmentsStore?: ClosureAssignmentsStore
  deliveryAssignmentsStore?: DeliveryAssignmentsStore
  activityAssignmentsStore?: ActivityAssignmentsStore
  deliveryFollowingsStore?: DeliveryFollowingsStore
  activityFollowingsStore?: ActivityFollowingsStore
  sitePermitAssignmentsStore?: SitePermitAssignmentsStore
  sitePermitFollowingsStore?: SitePermitFollowingsStore
  announcementAssignmentsStore?: AnnouncementAssignmentsStore
  announcementFollowingsStore?: AnnouncementFollowingsStore
  castFollowingsStore?: CastFollowingsStore
  castAssignmentsStore?: CastAssignmentsStore
  projectDateStore?: ProjectDateStore
}

const PADDING_TOP_HEIGHT = 5
const PADDING_BOTTOM_HEIGHT = 5
const DEFAULT_ITEMS_ROW_HEIGHT = 40
const DEFAULT_CATEGORY_ROW_HEIGHT = 32

@inject(
  'state',
  'tagsStore',
  'companiesStore',
  'closureFollowingsStore',
  'closureAssignmentsStore',
  'deliveryAssignmentsStore',
  'activityAssignmentsStore',
  'deliveryFollowingsStore',
  'activityFollowingsStore',
  'sitePermitAssignmentsStore',
  'sitePermitFollowingsStore',
  'castFollowingsStore',
  'castAssignmentsStore',
  'announcementAssignmentsStore',
  'announcementFollowingsStore',
  'projectDateStore',
)
@observer
export default class LogisticsList extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props)

    this.props.logisticsStore.changeDatePickerMode(
      TwoMonthsDatePickerMode.ONE_DAY_DEFAULT_TODAY,
    )
  }

  public render() {
    const {
      columns,
      columnsWidthState,
      collapsedCategories,
      groupingKey,
      toggleCategoryCollapsing,
      sortState,
      onRowClick,
      toggleCategory,
      handleColumnSort,
      rows,
    } = this.props.store

    return (
      <>
        {this.renderConfirmSubscriptionDialog()}
        {this.deletionConfirmDialog}
        <div className="relative row table-holder permits-list">
          <LogisticsListTable
            rows={rows}
            groupingKey={groupingKey}
            sortedColumnKey={sortState.columnKey}
            sortingOrder={sortState.order}
            columns={columns}
            columnsWidthState={columnsWidthState}
            collapsedCategories={collapsedCategories}
            columnKeyToCellRenderer={this.columnRenderer}
            onCategoryCollapsingToggle={toggleCategoryCollapsing}
            rowHeightGetter={this.getRowHeight}
            onCellClick={onRowClick}
            onColumnSort={handleColumnSort}
            onCategoryCheckboxToggle={toggleCategory}
          />
        </div>
      </>
    )
  }

  private renderConfirmSubscriptionDialog() {
    const {
      shouldSubscriptionConfirmModalShow,
      closeSubscriptionConfirmModal,
      subscriptionConfirmMessage,
      handleChangeAssociations,
      subscriptionConfirmButtonText,
    } = this.props.store

    return (
      <ConfirmDialog
        isOpen={shouldSubscriptionConfirmModalShow}
        onCancelClicked={closeSubscriptionConfirmModal}
        onDoneClicked={handleChangeAssociations}
        doneTitle={subscriptionConfirmButtonText}
      >
        <div className="text large pre-line">{subscriptionConfirmMessage}</div>
      </ConfirmDialog>
    )
  }

  private get deletionConfirmDialog(): JSX.Element {
    const {
      isDeletionConfirmModalShown,
      closeDeletionConfirmModal,
      deletionConfirmMessage,
      deleteForms,
      isUpdating,
    } = this.props.store

    return (
      <ConfirmDialog
        isOpen={isDeletionConfirmModalShown}
        loading={isUpdating}
        onCancelClicked={isUpdating ? NOOP : closeDeletionConfirmModal}
        onDoneClicked={deleteForms}
        doneTitle={`${Localization.translator.yes}, ${Localization.translator.delete}`}
      >
        <div className="text large red pre-line pb10">
          {Localization.translator.logisticsListDescriptions.areYouWantToDelete}
          <br />
          <br />
          {BULLET} {deletionConfirmMessage}
        </div>
      </ConfirmDialog>
    )
  }

  private get columnRenderer() {
    return {
      [logisticsDataKeys.CHECKBOX]: value => <Checkbox isChecked={value} />,
      [logisticsDataKeys.LOGISTIC_APP]: this.renderAppColumn,
      [logisticsDataKeys.LOGISTIC_NAME]: this.renderStringColumn,
      [logisticsDataKeys.LOGISTIC_STATUS]: this.renderLogisticStatus,
      [logisticsDataKeys.LOGISTIC_LOCATION]: this.renderLocationColumns,
      [logisticsDataKeys.LOGISTIC_COMPANY]: this.renderCompanyColumn,
      [logisticsDataKeys.LOGISTIC_DATE]: this.renderDateColumn,
      [logisticsDataKeys.LOGISTIC_RESPONSIBLE]:
        this.renderResponsibleContactColumn,
    }
  }

  private renderResponsibleContactColumn(users: User[]): JSX.Element {
    if (!users.length) {
      return <div>{NO_VALUE}</div>
    }

    return (
      <div className="col">
        {users.map(user => (
          <UsernameFromUid userId={user.id} isClickable key={user.id} />
        ))}
      </div>
    )
  }

  private renderCompanyColumn = (companyNames: string[]): JSX.Element => {
    if (!companyNames.length) {
      return <div>{NO_VALUE}</div>
    }

    return (
      <div className="row no-flex-children nowrap text-ellipsis">
        <div className="row mr4 text-ellipsis" title={companyNames.join(', ')}>
          <Icons.Team className="no-grow mr4 company-icon" />
          <div className="text-ellipsis">{companyNames.join(', ')}</div>
        </div>
      </div>
    )
  }

  private renderLogisticStatus = (payload: {
    app: LogisticItemApp
    status?: any
    workflowStepLevel?: number
    isLate?: boolean
    isOpened?: boolean
  }) => {
    switch (payload.app) {
      case LogisticItemApp.FORM:
        return (
          <div className="row no-flex-children">
            <WorkflowCardStatus
              status={payload.status}
              workflowStepLevel={payload.workflowStepLevel}
              isLate={payload.isLate}
            />
          </div>
        )
      case LogisticItemApp.CLOSURE:
        return (
          <div className="row no-flex-children">
            <OpeningIndicatorLabel
              closeLabel={closed}
              openLabel={opened}
              isOpen={payload.isOpened}
            />
          </div>
        )
      case LogisticItemApp.SENSOR:
        return this.renderStringColumn(
          getMonitoringStatusDisplayName(payload.status),
        )
      case LogisticItemApp.DELIVERY:
        return <DeliveryStatusLabel status={payload.status} />
      case LogisticItemApp.SCHEDULE:
        return this.renderStringColumn(
          getActivityStatusDisplayName(payload.status),
        )
      default:
        return this.renderStringColumn(payload.status)
    }
  }

  private get logisticFollowingsStoreByApp() {
    return {
      [LogisticItemApp.FORM]: this.props.sitePermitFollowingsStore,
      [LogisticItemApp.DELIVERY]: this.props.deliveryFollowingsStore,
      [LogisticItemApp.ANNOUNCEMENT]: this.props.announcementFollowingsStore,
      [LogisticItemApp.SCHEDULE]: this.props.activityFollowingsStore,
      [LogisticItemApp.SENSOR]: this.props.castFollowingsStore,
      [LogisticItemApp.CLOSURE]: this.props.closureFollowingsStore,
    }
  }

  private get logisticAssignmentsStoreByApp() {
    return {
      [LogisticItemApp.FORM]: this.props.sitePermitAssignmentsStore,
      [LogisticItemApp.DELIVERY]: this.props.deliveryAssignmentsStore,
      [LogisticItemApp.SCHEDULE]: this.props.activityAssignmentsStore,
      [LogisticItemApp.ANNOUNCEMENT]: this.props.announcementAssignmentsStore,
      [LogisticItemApp.SENSOR]: this.props.castAssignmentsStore,
      [LogisticItemApp.CLOSURE]: this.props.closureAssignmentsStore,
    }
  }

  @action.bound
  private renderAppColumn(payload: {
    app: LogisticItemApp
    logisticId: string
  }): JSX.Element {
    return (
      <div className="row">
        <BaseEntityAssociationStatus
          className="no-grow ml12 mr20"
          entityId={payload.logisticId}
          domainAssignmentsStore={
            this.logisticAssignmentsStoreByApp[payload.app]
          }
          domainFollowingsStore={this.logisticFollowingsStoreByApp[payload.app]}
        />
        {this.renderStringColumn(payload.app)}
      </div>
    )
  }

  // Separate styles for different cells will be added later

  private renderStringColumn(value: string): JSX.Element {
    return (
      <div className="text-ellipsis" title={value || NO_VALUE}>
        {value || NO_VALUE}
      </div>
    )
  }

  @action.bound
  private renderDateColumn(payload: {
    startDate: number
    endDate: number
  }): JSX.Element {
    const { getWeekdayMonthDayYearAndTimeToDisplay } =
      this.props.projectDateStore
    const startDateString = getWeekdayMonthDayYearAndTimeToDisplay(
      payload.startDate,
    )
    const endDateString = getWeekdayMonthDayYearAndTimeToDisplay(
      payload.endDate,
    )
    return (
      <div className="row">
        <div className="col y-center">
          <div className="row" title={`${startDateString} - ${endDateString}`}>
            {startDateString || NO_VALUE}
            {' - '}
            <br />
            {endDateString}
          </div>
        </div>
      </div>
    )
  }

  private renderLocationColumns = (payload: {
    isBreadcrumb: boolean
    locations: LocationBase[]
  }): JSX.Element => {
    return (
      <LogisticsListLocationCell
        isBreadcrumb={payload?.isBreadcrumb}
        locations={payload?.locations}
      />
    )
  }

  private getRowHeight = ({ index }: { index: number }): number => {
    const { store } = this.props

    const row = store.rows[index]

    if (!row) {
      return 0
    }

    if (row.category) {
      return DEFAULT_CATEGORY_ROW_HEIGHT
    }

    return PADDING_TOP_HEIGHT + PADDING_BOTTOM_HEIGHT + DEFAULT_ITEMS_ROW_HEIGHT
  }
}
