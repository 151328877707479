import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IHierarchyConfigurationsFieldsFragment = Pick<
  Types.IHierarchyConfigurations,
  'id' | 'projectId' | 'canGCUpdateActivity' | 'bandsHierarchyOrder'
> & {
  viewsToHierarchyModes?: Types.Maybe<
    Pick<Types.IViewTypes, 'mobile' | 'tablet' | 'webSitemap' | 'webGantt'>
  >
}

export type IGetHierarchyConfigurationsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetHierarchyConfigurationsQuery = {
  hierarchyConfigurations?: Types.Maybe<{
    data: Array<IHierarchyConfigurationsFieldsFragment>
  }>
}

export type ISaveHierarchyConfigurationsMutationVariables = Types.Exact<{
  hierarchyConfigurations: Types.IHierarchyConfigurationsInput
}>

export type ISaveHierarchyConfigurationsMutation = {
  saveHierarchyConfigurations?: Types.Maybe<IHierarchyConfigurationsFieldsFragment>
}

export const HierarchyConfigurationsFieldsFragmentDoc = gql`
  fragment HierarchyConfigurationsFields on HierarchyConfigurations {
    id
    projectId
    canGCUpdateActivity
    bandsHierarchyOrder
    viewsToHierarchyModes {
      mobile
      tablet
      webSitemap
      webGantt
    }
  }
`
export const GetHierarchyConfigurationsDocument = gql`
  query GetHierarchyConfigurations($projectId: ObjectId!) {
    hierarchyConfigurations(limit: 1000000, projectId: $projectId) {
      data {
        ...HierarchyConfigurationsFields
      }
    }
  }
  ${HierarchyConfigurationsFieldsFragmentDoc}
`

/**
 * __useGetHierarchyConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetHierarchyConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHierarchyConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHierarchyConfigurationsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetHierarchyConfigurationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetHierarchyConfigurationsQuery,
    IGetHierarchyConfigurationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetHierarchyConfigurationsQuery,
    IGetHierarchyConfigurationsQueryVariables
  >(GetHierarchyConfigurationsDocument, options)
}
export function useGetHierarchyConfigurationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetHierarchyConfigurationsQuery,
    IGetHierarchyConfigurationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetHierarchyConfigurationsQuery,
    IGetHierarchyConfigurationsQueryVariables
  >(GetHierarchyConfigurationsDocument, options)
}
export type GetHierarchyConfigurationsQueryHookResult = ReturnType<
  typeof useGetHierarchyConfigurationsQuery
>
export type GetHierarchyConfigurationsLazyQueryHookResult = ReturnType<
  typeof useGetHierarchyConfigurationsLazyQuery
>
export type GetHierarchyConfigurationsQueryResult = Apollo.QueryResult<
  IGetHierarchyConfigurationsQuery,
  IGetHierarchyConfigurationsQueryVariables
>
export const SaveHierarchyConfigurationsDocument = gql`
  mutation SaveHierarchyConfigurations(
    $hierarchyConfigurations: HierarchyConfigurationsInput!
  ) {
    saveHierarchyConfigurations(
      hierarchyConfigurations: $hierarchyConfigurations
    ) {
      ...HierarchyConfigurationsFields
    }
  }
  ${HierarchyConfigurationsFieldsFragmentDoc}
`
export type ISaveHierarchyConfigurationsMutationFn = Apollo.MutationFunction<
  ISaveHierarchyConfigurationsMutation,
  ISaveHierarchyConfigurationsMutationVariables
>

/**
 * __useSaveHierarchyConfigurationsMutation__
 *
 * To run a mutation, you first call `useSaveHierarchyConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveHierarchyConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveHierarchyConfigurationsMutation, { data, loading, error }] = useSaveHierarchyConfigurationsMutation({
 *   variables: {
 *      hierarchyConfigurations: // value for 'hierarchyConfigurations'
 *   },
 * });
 */
export function useSaveHierarchyConfigurationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveHierarchyConfigurationsMutation,
    ISaveHierarchyConfigurationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveHierarchyConfigurationsMutation,
    ISaveHierarchyConfigurationsMutationVariables
  >(SaveHierarchyConfigurationsDocument, options)
}
export type SaveHierarchyConfigurationsMutationHookResult = ReturnType<
  typeof useSaveHierarchyConfigurationsMutation
>
export type SaveHierarchyConfigurationsMutationResult =
  Apollo.MutationResult<ISaveHierarchyConfigurationsMutation>
export type SaveHierarchyConfigurationsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveHierarchyConfigurationsMutation,
    ISaveHierarchyConfigurationsMutationVariables
  >
