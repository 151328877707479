import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList, toggleClass } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import User from '~/client/src/shared/models/User'

import './ResponsibleToggler.scss'

interface IProps {
  members: User[]
  responsibleContactIds: string[]
  onChange: (newResponsibleContactIds: string[]) => void
}

// translated

@observer
export default class ResponsibleToggler extends React.Component<IProps> {
  public static defaultProps = {
    members: [],
    responsibleContactIds: [],
  }

  @observable private isEditMode: boolean = false
  @observable private currentResponsibleContactIds: string[] = []
  private readonly initialResponsibleContactIds: string[] = []

  public constructor(props: IProps) {
    super(props)

    const { responsibleContactIds } = props

    this.initialResponsibleContactIds = responsibleContactIds.slice()
    this.currentResponsibleContactIds = responsibleContactIds.slice()
  }

  public render() {
    if (!this.companyMembers.length) {
      return null
    }

    const areThereResponsibleMembers =
      !!this.currentResponsibleContactIds.length

    return (
      <div
        className={classList({
          'col responsible-toggler-container': true,
          editing: this.isEditMode,
        })}
      >
        <div className="row ml28 pb10 relative">
          {areThereResponsibleMembers && (
            <span className="text large light">
              {Localization.translator.responsibleContact}
            </span>
          )}
          <span
            className={classList({
              'text large bold blue-highlight no-grow pointer absolute-right':
                true,
              'blue-highlight': !this.isEditMode,
              light: this.isEditMode,
            })}
            onClick={this.isEditMode ? this.resetToInit : this.toggleMode}
          >
            {this.isEditMode
              ? Localization.translator.reset
              : Localization.translator.edit_verb}
          </span>
        </div>

        {this.responsibleMembers.map(member => (
          <div className="row py4" key={member.id}>
            <Icons.FilledStar
              onClick={this.toggleResponsibleContactById.bind(this, member.id)}
              className={classList({
                'row mr12 no-grow responsible-icon': true,
                pointer: this.isEditMode,
              })}
            />
            <UserProfilePreview key={member.id} user={member} />
          </div>
        ))}

        {areThereResponsibleMembers && (
          <div className="ma12 ba-none bb-light-grey as-stretch" />
        )}

        <div className="scrollable members-list">
          {this.otherMembers.map(member => (
            <div className="row py4" key={member.id}>
              {this.isEditMode && (
                <Icons.UnfilledStar
                  onClick={this.toggleResponsibleContactById.bind(
                    this,
                    member.id,
                  )}
                  className={classList({
                    'row mr12 no-grow unresponsible-icon': true,
                    pointer: this.isEditMode,
                  })}
                />
              )}
              <UserProfilePreview
                className={toggleClass('ml28', !this.isEditMode)}
                key={member.id}
                user={member}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }

  private get companyMembers(): User[] {
    return this.props.members
  }

  private get responsibleMembers(): User[] {
    return this.companyMembers.filter(u =>
      this.currentResponsibleContactIds.includes(u.id),
    )
  }

  private get otherMembers(): User[] {
    return this.companyMembers.filter(
      u => !this.currentResponsibleContactIds.includes(u.id),
    )
  }

  @action.bound
  private toggleMode() {
    this.isEditMode = !this.isEditMode
  }

  @action.bound
  private toggleResponsibleContactById(id: string) {
    if (!this.isEditMode) {
      return
    }

    const index = this.currentResponsibleContactIds.indexOf(id)

    if (index === -1) {
      this.currentResponsibleContactIds.push(id)
    } else {
      this.currentResponsibleContactIds.splice(index, 1)
    }

    this.handleChange()
  }

  @action.bound
  private resetToInit() {
    this.currentResponsibleContactIds =
      this.initialResponsibleContactIds.slice()
    this.props.onChange(this.initialResponsibleContactIds)
    this.isEditMode = false
  }

  @action.bound
  private handleChange() {
    this.props.onChange(this.currentResponsibleContactIds.slice())
  }
}
