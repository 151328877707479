import * as React from 'react'

export interface IProps {
  selectedActivitiesCount: number
  filteredActivitiesCount: number
}

export default class ActivitiesCount extends React.Component<IProps> {
  public render() {
    const { selectedActivitiesCount, filteredActivitiesCount } = this.props

    let text = ''
    if (!selectedActivitiesCount) {
      text = `${filteredActivitiesCount} Activities Showing`
    } else {
      text = `${selectedActivitiesCount} of ${filteredActivitiesCount} Selected`
    }
    return <div className="text large light m5">{text}</div>
  }
}
