import Delivery from '~/client/src/shared/models/Delivery'
import BaseActionBarStore from '~/client/src/shared/stores/ui/BaseActionBar.store'

import BaseFollowingsStore from '../BaseFollowings.store'
import EventsStore from '../EventStore/Events.store'
import DeliveriesStore from '../domain/Deliveries.store'
import { FileUploadingStore } from '../domain/FileUploading.store'
import MessagesStore from '../domain/MessagesStore/Messages.store'
import PhotosStore from '../domain/Photos.store'
import TagsStore from '../domain/Tags.store'
import ThreadsStore from '../domain/ThreadsStore/Threads.store'
import UserProjectsStore from '../domain/UserProjects.store'

export default class DeliveryActionBarStore extends BaseActionBarStore {
  public constructor(
    private readonly deliveriesStore: DeliveriesStore,
    eventsStore: EventsStore,
    threadsStore: ThreadsStore,
    messagesStore: MessagesStore,
    photosStore: PhotosStore,
    fileUploadingStore: FileUploadingStore,
    userProjectsStore: UserProjectsStore,
    tagsStore: TagsStore,
    baseFollowingStore?: BaseFollowingsStore,
  ) {
    super(
      eventsStore,
      threadsStore,
      messagesStore,
      photosStore,
      fileUploadingStore,
      userProjectsStore,
      tagsStore,
      baseFollowingStore,
    )
  }

  protected updateThreadEntity(threadEntity: Delivery) {
    this.deliveriesStore.updateDelivery(threadEntity, null, false)
  }
}
