import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IWeatherForecastFieldsFragment = Pick<
  Types.IWeatherForecast,
  'id' | 'createdAt' | 'updatedAt' | 'projectId' | 'forecastDate'
> & {
  openWeatherForecast: Pick<
    Types.IOpenWeatherForecast,
    'forecastTime' | 'probabilityOfPrecipitation'
  > & {
    mainParams: Pick<
      Types.IForecastMainParams,
      | 'temperature'
      | 'feelsLikeTemp'
      | 'minTemperature'
      | 'maxTemperature'
      | 'pressure'
      | 'humidity'
    >
    conditionParams: Array<
      Types.Maybe<
        Pick<Types.IForecastConditionParams, 'description' | 'iconId'>
      >
    >
    windParams: Pick<Types.IForecastWindParams, 'speed' | 'degrees'>
  }
}

export type IGetWeatherForecastsRangeQueryVariables = Types.Exact<{
  forecastRangeRequest: Types.IWeatherForecastRangeInput
}>

export type IGetWeatherForecastsRangeQuery = {
  weatherForecasts?: Types.Maybe<
    Array<Types.Maybe<IWeatherForecastFieldsFragment>>
  >
}

export type IListenWeatherForecastsByProjectIdSubscriptionVariables =
  Types.Exact<{
    projectId: Types.Scalars['ObjectId']
  }>

export type IListenWeatherForecastsByProjectIdSubscription = {
  weatherForecast?: Types.Maybe<
    Pick<Types.IWeatherForecastChangeEvent, 'id'> & {
      item?: Types.Maybe<IWeatherForecastFieldsFragment>
    }
  >
}

export const WeatherForecastFieldsFragmentDoc = gql`
  fragment WeatherForecastFields on WeatherForecast {
    id
    createdAt
    updatedAt
    projectId
    forecastDate
    openWeatherForecast {
      forecastTime
      mainParams {
        temperature
        feelsLikeTemp
        minTemperature
        maxTemperature
        pressure
        humidity
      }
      conditionParams {
        description
        iconId
      }
      windParams {
        speed
        degrees
      }
      probabilityOfPrecipitation
    }
  }
`
export const GetWeatherForecastsRangeDocument = gql`
  query GetWeatherForecastsRange(
    $forecastRangeRequest: WeatherForecastRangeInput!
  ) {
    weatherForecasts(weatherForecasts: $forecastRangeRequest) {
      ...WeatherForecastFields
    }
  }
  ${WeatherForecastFieldsFragmentDoc}
`

/**
 * __useGetWeatherForecastsRangeQuery__
 *
 * To run a query within a React component, call `useGetWeatherForecastsRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeatherForecastsRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeatherForecastsRangeQuery({
 *   variables: {
 *      forecastRangeRequest: // value for 'forecastRangeRequest'
 *   },
 * });
 */
export function useGetWeatherForecastsRangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetWeatherForecastsRangeQuery,
    IGetWeatherForecastsRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetWeatherForecastsRangeQuery,
    IGetWeatherForecastsRangeQueryVariables
  >(GetWeatherForecastsRangeDocument, options)
}
export function useGetWeatherForecastsRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetWeatherForecastsRangeQuery,
    IGetWeatherForecastsRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetWeatherForecastsRangeQuery,
    IGetWeatherForecastsRangeQueryVariables
  >(GetWeatherForecastsRangeDocument, options)
}
export type GetWeatherForecastsRangeQueryHookResult = ReturnType<
  typeof useGetWeatherForecastsRangeQuery
>
export type GetWeatherForecastsRangeLazyQueryHookResult = ReturnType<
  typeof useGetWeatherForecastsRangeLazyQuery
>
export type GetWeatherForecastsRangeQueryResult = Apollo.QueryResult<
  IGetWeatherForecastsRangeQuery,
  IGetWeatherForecastsRangeQueryVariables
>
export const ListenWeatherForecastsByProjectIdDocument = gql`
  subscription ListenWeatherForecastsByProjectId($projectId: ObjectId!) {
    weatherForecast(projectId: $projectId) {
      id
      item {
        ...WeatherForecastFields
      }
    }
  }
  ${WeatherForecastFieldsFragmentDoc}
`

/**
 * __useListenWeatherForecastsByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenWeatherForecastsByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenWeatherForecastsByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenWeatherForecastsByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenWeatherForecastsByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenWeatherForecastsByProjectIdSubscription,
    IListenWeatherForecastsByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenWeatherForecastsByProjectIdSubscription,
    IListenWeatherForecastsByProjectIdSubscriptionVariables
  >(ListenWeatherForecastsByProjectIdDocument, options)
}
export type ListenWeatherForecastsByProjectIdSubscriptionHookResult =
  ReturnType<typeof useListenWeatherForecastsByProjectIdSubscription>
export type ListenWeatherForecastsByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenWeatherForecastsByProjectIdSubscription>
