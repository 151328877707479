import * as React from 'react'

import { computed } from 'mobx'
import { inject } from 'mobx-react'

import './ContactHelp.scss'

import InitialState from '~/client/src/shared/stores/InitialState'

interface IProps {
  mailto: string
  hint: string
  subject?: string
  state?: InitialState
  textOrientation?: string
  className?: string
}

const emailStartingText = 'info'
const emailDomain = '@struxhub.com'

@inject('state')
export default class ContactHelp extends React.Component<IProps> {
  public render() {
    const {
      hint,
      subject,
      textOrientation = 'left',
      className = 'default',
    } = this.props

    return (
      <div className={`contact-help-container row ${className}`}>
        <a
          className={`text extra-large primary-blue ${textOrientation}`}
          href={`mailto:${this.email}${subject ? `?subject=${subject}` : ''}`}
        >
          {hint}
        </a>
      </div>
    )
  }

  @computed
  private get email(): string {
    const { activeProject } = this.props.state

    const project = activeProject
      ? '+' + activeProject.name.replace(/\s/g, '_')
      : ''
    return (emailStartingText + project + emailDomain)
      .replace(/\s/g, '')
      .toLocaleLowerCase()
  }
}
