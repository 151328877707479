import * as React from 'react'

import * as Icons from '~/client/src/shared/components/Icons'

import './PurpleConfirm.scss'

export interface IProps {
  title: string
  actionTitle: string
  isShown: boolean
  className?: string
  onHide(): void
  onApply(): void
}

export default class PurpleConfirm extends React.Component<IProps> {
  public render() {
    const {
      title,
      actionTitle,
      isShown,
      className,
      children,
      onHide,
      onApply,
    } = this.props

    return (
      isShown && (
        <div className={`purple-confirm ${className || ''}`}>
          <div className="purple-confirm-header">{title}</div>
          <Icons.Cross onClick={onHide} className="purple-confirm-cross" />
          <div className="purple-confirm-body">{children}</div>
          <div className="row x-center no-flex-children">
            <button onClick={onApply}>{actionTitle}</button>
          </div>
        </div>
      )
    )
  }
}
