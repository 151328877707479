import ScanStationGroupingOption from '~/client/src/shared/enums/ScanStationGroupingOption'

import FilterInfo from '../../../shared/stores/substates/FilterInfo'

export default class ScanStationFiltersSettingState {
  public desktopFilterMap: { [key: string]: FilterInfo } = {
    [ScanStationGroupingOption.DAY]: new FilterInfo(
      ScanStationGroupingOption.DAY,
      false,
    ),
    [ScanStationGroupingOption.WEEK]: new FilterInfo(
      ScanStationGroupingOption.WEEK,
      false,
    ),
    [ScanStationGroupingOption.MONTH]: new FilterInfo(
      ScanStationGroupingOption.MONTH,
      false,
    ),
    [ScanStationGroupingOption.COMPANY]: new FilterInfo(
      ScanStationGroupingOption.COMPANY,
      false,
    ),
    [ScanStationGroupingOption.SCANNER]: new FilterInfo(
      ScanStationGroupingOption.SCANNER,
      false,
    ),
  }
}
