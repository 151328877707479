import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { IInspectionOptions, IWorkflowStep } from '~/client/graph'
import PermitWorkflowDiagram from '~/client/src/desktop/components/WorkflowDiagrams/PermitWorkflowDiagram/PermitWorkflowDiagram'
import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import OperationRulesStore from '~/client/src/shared/stores/domain/OperationRules.store'
import PermitBallInCourtStore from '~/client/src/shared/stores/ui/PermitBallInCourt.store'
import { BULLET } from '~/client/src/shared/utils/usefulStrings'

import FormWorkflowSteps from '../FormWorkflowSteps'
import WorkflowStore from './Workflow.store'
import WorkflowStepConfigurator from './components/WorkflowStepConfigurator/WorkflowStepConfigurator'
import WorkflowStepDescriptionsDialog from './components/WorkflowStepDescriptionsDialog'

// localization: translated

interface IProps {
  formTypeId: string
  workflowSteps: IWorkflowStep[]
  inspectionOptions: IInspectionOptions
  isAutoActivationEnabled: boolean
  isUpdating: boolean
  isWorkflowChanged: boolean

  onWorkflowSettingsChange(
    workflowSteps: IWorkflowStep[],
    inspectionOptions: IInspectionOptions,
    isAutoActivationEnabled: boolean,
    isImmediateSave?: boolean,
  ): void
  onStepClick(stepId: string): void

  permitBallInCourtStore: PermitBallInCourtStore

  operationRulesStore?: OperationRulesStore
}

@inject('operationRulesStore')
@observer
export default class Workflow extends React.Component<IProps> {
  private readonly store: WorkflowStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new WorkflowStore(
      props.operationRulesStore,
      props.permitBallInCourtStore,
      props.formTypeId,
      props.workflowSteps,
      props.inspectionOptions,
      props.isAutoActivationEnabled,
      props.onWorkflowSettingsChange,
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      formTypeId,
      workflowSteps,
      inspectionOptions,
      isAutoActivationEnabled,
    } = this.props
    if (
      formTypeId !== prevProps.formTypeId ||
      workflowSteps.length !== prevProps.workflowSteps.length ||
      workflowSteps !== prevProps.workflowSteps ||
      inspectionOptions !== prevProps.inspectionOptions ||
      isAutoActivationEnabled !== prevProps.isAutoActivationEnabled
    ) {
      this.store.init(
        formTypeId,
        workflowSteps,
        inspectionOptions,
        isAutoActivationEnabled,
      )
    }
  }

  public render() {
    const {
      isUpdating,
      isWorkflowChanged,
      onStepClick,
      permitBallInCourtStore,
    } = this.props
    const {
      getUsersFromExpressions,
      duplicateRule,
      tryToRemoveRule,
      addOrDeleteUserFromRule,
      changeRuleExpression,
      showNewRuleRow,
      workflowSteps,
      addNewWorkflowStep,
      replaceWorkflowStep,
      showDeleteStepModal,
      changeInspectionOptions,
      inspectionOptions,
      isStepDescrDialogShown,
      showStepDescrDialog,
      hideStepDescrDialog,
      permitTypeId,
      isAutoActivationEnabled,
      toggleAutoActivationState,
      changeNotificationFieldIds,
    } = this.store

    return (
      <div className="col permit-type-workflow ml12 mb40">
        {this.deleteRuleDialog}
        {this.deleteStepDialog}
        {this.activationToggleDialog}

        <span className="row mb20 no-white-space-wrap text extra-large bold line-24 no-grow">
          {Localization.translator.workflowSteps}
        </span>

        <div className="row overflow-auto">
          <div className="row x-center">
            <FormWorkflowSteps workflowSteps={workflowSteps} />
          </div>
        </div>

        <span className="row mt20 no-white-space-wrap text extra-large bold line-24 no-grow">
          {Localization.translator.workflowDiagram}
        </span>

        <div className="row overflow-auto">
          <div className="py20 row x-center">
            <PermitWorkflowDiagram
              workflowSteps={workflowSteps}
              isAutoActivationEnabled={isAutoActivationEnabled}
              inspectionFrequency={inspectionOptions?.inspectionFrequency}
              inspectionFrequencyType={
                inspectionOptions?.inspectionFrequencyType
              }
            />
          </div>
        </div>

        <div className="col my10 py10 relative bg-white">
          <div className="row relative">
            <span className="text extra-large bold line-24 no-grow nowrap">
              {Localization.translator.workflowStepConfigurator}
            </span>

            <div
              className="text large bold blue-highlight line-extra-large no-grow nowrap pointer mx15"
              onClick={showStepDescrDialog}
            >
              {Localization.translator.workflowTableText.seeDescr}
            </div>
            <WorkflowStepDescriptionsDialog
              isOpened={isStepDescrDialogShown}
              hideDialog={hideStepDescrDialog}
            />
            <div />
          </div>
        </div>

        <div className="row">
          <div className="py20 row x-center">
            <WorkflowStepConfigurator
              formTypeId={permitTypeId}
              workflowSteps={workflowSteps}
              inspectionOptions={inspectionOptions}
              isUpdating={isUpdating}
              isAutoActivationEnabled={isAutoActivationEnabled}
              isWorkflowChanged={isWorkflowChanged}
              onAddStep={addNewWorkflowStep}
              onReplaceStep={replaceWorkflowStep}
              onRemoveStep={showDeleteStepModal}
              onInspectionChange={changeInspectionOptions}
              onFormSectionClick={onStepClick}
              onAutoActivationChange={toggleAutoActivationState}
              getUsersFromAssignment={getUsersFromExpressions}
              onRemoveRuleClick={tryToRemoveRule}
              onDuplicateRuleClick={duplicateRule}
              onChangeUserForRule={addOrDeleteUserFromRule}
              onRuleExpressionChange={changeRuleExpression}
              onAddNewRuleClick={showNewRuleRow}
              onNotificationFieldsChange={changeNotificationFieldIds}
              permitBallInCourtStore={permitBallInCourtStore}
            />
          </div>
        </div>
      </div>
    )
  }

  private get deleteRuleDialog(): JSX.Element {
    const { removingRuleId, resetRemovingRuleId, performRemoveRule } =
      this.store

    return (
      <ConfirmDialog
        portalClassName="z-index-120"
        isOpen={!!removingRuleId}
        onCancelClicked={resetRemovingRuleId}
        onDoneClicked={performRemoveRule}
        doneTitle={Localization.translator.delete}
      >
        <div className="text large pre-line">
          {Localization.translator.shouldDeleteRule}?
        </div>
      </ConfirmDialog>
    )
  }

  private get deleteStepDialog(): JSX.Element {
    const {
      isDeleteStepModalShown,
      hideDeleteStepModal,
      confirmRemoveWorkflowStep,
    } = this.store

    return (
      <ConfirmDialog
        portalClassName="z-index-120"
        isOpen={isDeleteStepModalShown}
        onCancelClicked={hideDeleteStepModal}
        onDoneClicked={confirmRemoveWorkflowStep}
        doneTitle={Localization.translator.delete}
      >
        <div className="text large pre-line">
          {Localization.translator.workflowConfDescr.removeStepDialogMsg}
        </div>
      </ConfirmDialog>
    )
  }

  private get activationToggleDialog(): JSX.Element {
    const {
      isAutoToggleDialogShown,
      isAutoActivationEnabled,
      hideAllDialogs,
      confirmAutoActivationChange,
    } = this.store

    return (
      <ConfirmDialog
        portalClassName="z-index-120"
        isOpen={isAutoToggleDialogShown}
        onCancelClicked={hideAllDialogs}
        onDoneClicked={confirmAutoActivationChange}
        doneTitle={Localization.translator.change_verb}
      >
        <div className="text large red pre-line">
          {Localization.translator.workflowConfDescr.recurringHasConstr}
          <br />
          <br />
          {BULLET} {Localization.translator.workflowTableText.recurringConstr}
          <br />
          <br />
        </div>
        <div className="text large pre-line">
          {Localization.translator.workflowConfDescr.toggleDialogMsg(
            isAutoActivationEnabled,
          )}
        </div>
      </ConfirmDialog>
    )
  }
}
