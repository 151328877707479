import * as React from 'react'

import { action, computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { RouteComponentProps, withRouter } from 'react-router'

import { ContentObjectStatus } from '~/client/graph'
import CommonThread from '~/client/src/shared/components/CommonThread/CommonThread'
import CloseContentObjectButton from '~/client/src/shared/components/ContentObject/CloseContentObjectButton'
import ReplyToContentObjectButton from '~/client/src/shared/components/ContentObject/ReplyToContentObjectButton'
import FlagIcon from '~/client/src/shared/components/FlagIcon'
import Flag from '~/client/src/shared/models/Flag'
import InitialState from '~/client/src/shared/stores/InitialState'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'

import CommonStore from '../../stores/ui/Common.store'
import FileInputBase from '../FileInput/FileInput'

interface IFlagEntryProps {
  flag: Flag
  messagesStore?: MessagesStore
  hideButtons?: boolean
  state?: InitialState
  common?: CommonStore
  FileInputType: typeof FileInputBase
}

@inject('messagesStore', 'state', 'common')
@observer
class FlagThread extends React.Component<
  IFlagEntryProps & RouteComponentProps<any>
> {
  private renderButtons(flag: Flag) {
    const { userActiveProjectSettings } = this.props.state
    return (
      <div
        className={classList({
          'row item-buttons-container': true,
          'inactive-element': userActiveProjectSettings?.isPresentationUser,
        })}
      >
        <ReplyToContentObjectButton
          contentObject={flag}
          onClick={this.displayFlagView}
        />
        <CloseContentObjectButton contentObject={flag} />
      </div>
    )
  }

  @computed
  private get messages() {
    return this.props.messagesStore.list.filter(
      m => m.threadId === this.props.flag.threadId,
    )
  }

  public render() {
    const { flag, hideButtons, FileInputType } = this.props
    const isFlagClosed = flag.status === ContentObjectStatus.Closed
    const buttonsElement = hideButtons ? null : this.renderButtons(flag)

    return (
      <CommonThread
        showImages={true}
        hideThread={false}
        showFooterLine={true}
        messages={this.messages}
        buttonsElement={buttonsElement}
        threadIcon={<FlagIcon flag={flag} />}
        threadClass={isFlagClosed ? '' : ' flag-thread'}
        caption={Flag.getFlagTypeCaption(flag.type)}
        model={flag}
        customPhotoClick={this.displayFlagView}
        FileInputType={FileInputType}
      />
    )
  }

  @action.bound
  private displayFlagView = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    const { common, flag } = this.props
    common.displayFlagView(flag)
  }
}

export default withRouter(FlagThread)
