import * as React from 'react'

import { observer } from 'mobx-react'

import { ContentObjectStatus } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import CategoryOfVariance from '~/client/src/shared/models/CategoryOfVariance'

interface IProps {
  categoryOfVariance: CategoryOfVariance
}

@observer
export default class CategoryOfVarianceIcon extends React.Component<IProps> {
  public render() {
    const { categoryOfVariance = { status: '' } } = this.props
    const { status } = categoryOfVariance
    let icon

    switch (status) {
      case ContentObjectStatus.Open:
        icon = <Icons.Variance className="category-of-variance-icon" />
        break
      case ContentObjectStatus.Closed:
      default:
        icon = <Icons.Variance className="category-of-variance-icon inactive" />
        break
    }

    return <div style={{ marginTop: '-2px' }}>{icon}</div>
  }
}
