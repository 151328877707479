import { action, observable } from 'mobx'

import { IMaterialConfiguration } from '~/client/graph'
import { SaveMaterialConfigurationDocument } from '~/client/graph/operations/generated/MaterialConfiguration.generated'

import { SAVE_MATERIAL_CONFIGURATION } from '../EventStore/eventConstants'
import InitialState from '../InitialState'
import GraphExecutorStore from './GraphExecutor.store'

export default class MaterialConfigurationStore {
  @observable public configuration: IMaterialConfiguration

  public constructor(
    private readonly state: InitialState,
    private readonly graphExecutorStore: GraphExecutorStore,
  ) {
    this.resetToDefault()
  }

  @action.bound
  public resetToDefault() {
    this.configuration = {
      maps: [],
      projectId: this.state.activeProject.id,
    }
  }

  @action.bound
  public async save(newConfig: IMaterialConfiguration) {
    this.state.loading.set(SAVE_MATERIAL_CONFIGURATION, true)

    Object.assign(this.configuration, newConfig)

    await this.graphExecutorStore.mutate(SaveMaterialConfigurationDocument, {
      materialConfiguration: this.configuration,
    })

    this.state.loading.set(SAVE_MATERIAL_CONFIGURATION, false)
  }

  @action.bound
  public updateMaterialConfiguration(
    materialConfiguration: IMaterialConfiguration,
  ) {
    this.resetToDefault()

    if (materialConfiguration) {
      this.configuration = materialConfiguration
    }
  }
}
