import { observable } from 'mobx'

import { HSLToHex } from './HSLtoHex'
import isTestEnvironment from './environment'

export enum ThemeModes {
  dark = 'dark-mode',
  light = 'light-mode',
}
const defaultMode = ThemeModes.light

const THEME_MODE_STORAGE_KEY = 'theme-mode'

export default class ThemeMode {
  @observable public static currentMode: ThemeModes = defaultMode

  public static init() {
    const theme = localStorage.getItem(THEME_MODE_STORAGE_KEY) as ThemeModes
    ThemeMode.setMode(theme || defaultMode)
  }

  public static get isDarkMode(): boolean {
    return ThemeMode.currentMode === ThemeModes.dark
  }

  public static toggleMode() {
    ThemeMode.setMode(ThemeMode.isDarkMode ? ThemeModes.light : ThemeModes.dark)
  }

  public static get shouldShowThemeSelector(): boolean {
    return isTestEnvironment()
  }

  // konva objects only accept HEX colors
  public static getHEXColor(colorStrings: string) {
    if (colorStrings?.startsWith('hsla')) {
      const [h, s, l] = colorStrings
        .split(',')
        .map(colorValue => Number(colorValue.replace(/\D/g, '')))

      return HSLToHex({ h, s, l })
    }

    return colorStrings
  }

  public static setMode(mode: ThemeModes) {
    if (
      (mode !== ThemeModes.dark && mode !== ThemeModes.light) ||
      mode === ThemeMode.currentMode
    ) {
      return
    }

    ThemeMode.currentMode = mode
    localStorage.setItem(THEME_MODE_STORAGE_KEY, mode)
    if (ThemeMode.isDarkMode) {
      document.body.classList.add(ThemeModes.dark)
      document.body.classList.remove(ThemeModes.light)
    } else {
      document.body.classList.add(ThemeModes.light)
      document.body.classList.remove(ThemeModes.dark)
    }
  }
}
