import * as React from 'react'

import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import SelectedFilterItemsList from '../../../../ActivityFilters/components/SelectedFilterItemsList'
import ProjectScheduleUploadStore from '../../../ProjectScheduleUpload.store'

interface IProps {
  projectScheduleUploadStore: ProjectScheduleUploadStore
}

@observer
export default class HiddenActivityCodesInfo extends React.Component<IProps> {
  public render() {
    const {
      configuredHiddenActivityCodeNames,
      showHiddenActivityCodesConfiguration,
    } = this.props.projectScheduleUploadStore

    return (
      <div className="pt10 text large row">
        <div>
          <SelectedFilterItemsList
            names={configuredHiddenActivityCodeNames}
            title={
              Localization.translator
                .activitiesWithTheFollowingActivityCodesAreExcluded + ':'
            }
          />
        </div>
        <div className="no-grow">
          <button
            type="button"
            onClick={showHiddenActivityCodesConfiguration}
            className="upload-schedule-white-btn"
          >
            {Localization.translator.edit_verb}
          </button>
        </div>
      </div>
    )
  }
}
