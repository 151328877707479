import * as React from 'react'

import { observer } from 'mobx-react'

import PhotoContainer from '~/client/src/shared/components/CommonThread/components/PhotoContainer'
import PhotoThumbnail from '~/client/src/shared/components/PhotoThumbnail/PhotoThumbnail'
import Timestamp from '~/client/src/shared/components/Timestamp'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Photo from '~/client/src/shared/models/Photo'
import User from '~/client/src/shared/models/User'

import { EMPTY_STRING } from '../../../utils/usefulStrings'
import UserProfilePreview from '../../UserProfilePreview/UserProfilePreview'
import AttachmentsContainer from './AttachmentsContainer'
import { IThreadEntryProps } from './CommonThreadEntry'

// localization: translated

interface IProps {
  user: User
  entryPhoto: Photo
}

@observer
export default class FormColumn extends React.Component<
  IProps & IThreadEntryProps
> {
  public render() {
    const {
      user,
      entryPhoto,

      FileInputType,
      isLastEntryInLog,
      isPhotoThread,
      attachments,
      customPhotoClick,
      actionBarStore,

      message,
    } = this.props
    const { text, isInfoHidden, createdAt } = message

    const shouldShowThumbnail =
      actionBarStore?.isImageUploading && isLastEntryInLog

    return (
      <div className="col">
        <div className="row x-between no-flex-children-direct">
          <UserProfilePreview
            user={user}
            briefInfoClassName="medium"
            isBriefInfoHidden={isInfoHidden}
          />
          <Timestamp time={createdAt} />
        </div>
        <div className="pl40">
          <div className="row">
            <div
              className={
                'text large mt4 line-large' + (text ? '' : ' italic faded')
              }
            >
              {text || `[${Localization.translator.noMessage}]`}
            </div>
          </div>
          {shouldShowThumbnail ? (
            <PhotoThumbnail />
          ) : (
            <PhotoContainer
              customPhotoClick={customPhotoClick}
              photo={entryPhoto}
              isPhotoThread={isPhotoThread}
              caption={EMPTY_STRING}
              FileInputType={FileInputType}
            />
          )}
          <AttachmentsContainer attachments={attachments} />
        </div>
      </div>
    )
  }
}
