import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'

import ActivityNameHeader from '../../../ActivityNameHeader'
import ActivityStatusHeader from '../../../ActivityStatusHeader'
import ActivityGanttOrListViewStore from '../../ActivityGanttOrListView.store'
import TableOffsetsColumnCollapseStore from '../../TableOffsetsColumnCollapse.store'
import BulkActionBar from '../BulkActionBar/BulkActionBar'

interface IGanttChartHeaderProps {
  store: ActivityGanttOrListViewStore
  tableOffsetsColumnCollapseStore: TableOffsetsColumnCollapseStore
  activityListStore: DesktopActivityListStore
  headerWidth: number
  state?: DesktopInitialState
}

@inject('state')
@observer
export default class GanttChartHeader extends React.Component<IGanttChartHeaderProps> {
  public render() {
    const {
      store,
      state: { isStatusUpdateManpowerDisabled },
      headerWidth,
      activityListStore,
      tableOffsetsColumnCollapseStore,
    } = this.props

    const {
      sortColumn,
      isSortingOrderDesc,
      currentSortingOrder,
      sortingDataKey,
      areCompaniesEnabled,
    } = store

    return (
      <div className="col gantt-header-container">
        <div className="header-row row">
          <div className="overflow-hidden full-width gant-activity-col text bold bb-light-cool-grey br-light-cool-grey gantt-row-header">
            <ActivityNameHeader
              className="br-light-grey"
              sortKey={sortingDataKey}
              currentSortingOrder={currentSortingOrder}
              isSortingDesc={isSortingOrderDesc}
              onSort={sortColumn}
              hideCompany={!areCompaniesEnabled}
            />
            <ActivityStatusHeader
              isManpowerEnabled={!isStatusUpdateManpowerDisabled}
              currentSortingOrder={currentSortingOrder}
              sortKey={sortingDataKey}
              isSortingDesc={isSortingOrderDesc}
              onSort={sortColumn}
              headerWidth={headerWidth}
            />
          </div>
        </div>
        <div className="bulk-action-row row">
          <div className="full-width gant-activity-col br-light-cool-grey" />
          <div className="bulk-action-bar-container">
            <BulkActionBar
              activityListStore={activityListStore}
              store={store}
              tableOffsetsColumnCollapseStore={tableOffsetsColumnCollapseStore}
            />
          </div>
        </div>
      </div>
    )
  }
}
