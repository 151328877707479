import * as React from 'react'

import { observer } from 'mobx-react'

import { IPermitTypeField } from '~/client/graph'
import StruxhubTextArea from '~/client/src/shared/components/StruxhubInputs/StruxhubTextArea'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'

import PermitBaseFormField from './PermitBaseFormField'
import PermitFieldRemoveIcon from './PermitFieldRemoveIcon'

interface IProps {
  typeField: IPermitTypeField
  isViewMode: boolean

  fieldsStore: IPermitFieldsStore

  tableId?: string
  tableRowIndex?: number
}

@observer
export default class PermitFormTextAreaField extends React.Component<IProps> {
  public render() {
    const { isViewMode, typeField, fieldsStore, tableId, tableRowIndex } =
      this.props
    const { caption, isMandatory, isMultiple } = typeField
    const { isFieldChanged } = fieldsStore

    return (
      <PermitBaseFormField
        typeField={typeField}
        isViewMode={isViewMode}
        viewModeElements={this.viewModeElements}
        onAddFieldClick={this.addNewFieldValue}
      >
        {this.fieldValues.map((value, index) => {
          const shouldShowRemoveBtn = !!index || !!value

          return (
            <div key={index} className="row y-center">
              <StruxhubTextArea
                id={`${typeField.id}_${index}_${tableRowIndex || 0}`}
                className="overflow-hidden"
                label={caption}
                isRequired={isMandatory}
                isChanged={isFieldChanged(
                  typeField.id,
                  index,
                  tableId,
                  tableRowIndex,
                )}
                isLabelHidden={!!index}
                isHelperTextCondensed={isMultiple}
                value={value}
                onChange={this.onValueChange.bind(this, index)}
              />
              {isMultiple && shouldShowRemoveBtn && (
                <PermitFieldRemoveIcon
                  index={index}
                  onClick={this.removeValue}
                />
              )}
            </div>
          )
        })}
      </PermitBaseFormField>
    )
  }

  private get viewModeElements(): JSX.Element[] {
    return this.fieldValues
      .filter(p => p)
      .map((value, idx) => (
        <div key={idx} className="text large word-break-all">
          {value}
        </div>
      ))
  }

  private get fieldValues(): string[] {
    const { typeField, fieldsStore, tableId, tableRowIndex } = this.props
    const values = fieldsStore.getFieldValues<string>(
      typeField.id,
      tableId,
      tableRowIndex,
    )

    return values?.length ? values : ['']
  }

  private onValueChange(
    fieldIndex: number,
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) {
    const { fieldsStore, typeField, tableId, tableRowIndex } = this.props
    const { changeFieldValue, changeTableFieldValue } = fieldsStore

    if (this.isTableField) {
      changeTableFieldValue(
        tableId,
        tableRowIndex,
        typeField,
        fieldIndex,
        event.target.value,
      )
      return
    }

    changeFieldValue(typeField, fieldIndex, event.target.value)
  }

  private addNewFieldValue = () => {
    if (!this.isTableField) {
      const { fieldsStore, typeField } = this.props
      fieldsStore.addNewFieldValue(typeField, '')
    }
  }

  private removeValue = (index: number) => {
    if (!this.isTableField) {
      const { fieldsStore, typeField } = this.props
      fieldsStore.removeFieldValue(typeField.id, index)
    }
  }

  private get isTableField(): boolean {
    return !!this.props.tableId
  }
}
