import { action, computed, observable } from 'mobx'
import { Socket } from 'socket.io-client'
import * as createUuid from 'uuid/v1'

import ChatService from '../../../services/ChatService/Chat.service'
import ChatEvent from '../../../services/ChatService/events'
import {
  IChat,
  IContact,
  IMessage,
  IMessageReceivedEvent,
} from '../../../services/ChatService/types'

export default class ChatLogStore {
  @observable private messages: IMessage[] = []

  @observable public externalContactsMap: Map<string, IContact> = new Map()
  @observable public onlineContacts: Set<string> = null

  @observable public isLoading: boolean = false

  @observable public shouldShowChatDetails: boolean = false
  @observable public areMembersBeingLoaded: boolean = false

  @observable public newMessageText: string = ''

  @observable public brokenMessageIds: Set<string> = new Set()

  public constructor(
    private readonly chatService: ChatService,
    public chat: IChat,
    private onMessageCb: () => void,
    onlineContacts: Set<string> = new Set(),
    private onBackClick: () => void,
  ) {
    this.onlineContacts = onlineContacts
    this.registerListeners()
  }

  @computed
  public get onlineMemberIds(): string[] {
    return this.chat.memberIds.filter(mid => this.onlineContacts.has(mid))
  }

  @computed
  public get sortedMessages(): IMessage[] {
    return this.messages.slice().sort((m1, m2) => m1.createdAt - m2.createdAt)
  }

  public get isDraftChat(): boolean {
    return !this.chat.id
  }

  public async init() {
    this.loadMembers()

    if (this.isDraftChat) {
      return
    }

    this.isLoading = true

    try {
      await this.loadMessages()
    } finally {
      this.isLoading = false
    }

    this.setLastReadMessageOnInit()
  }

  private async loadMessages() {
    this.messages = await this.chatService.getMessagesByChannel(this.chat.id)
  }

  private async loadMembers() {
    this.areMembersBeingLoaded = true
    try {
      const members = await this.chatService.getContactByIds(
        this.chat.memberIds,
      )
      this.externalContactsMap = new Map(members.map(m => [m.id, m]))
    } finally {
      this.areMembersBeingLoaded = false
    }
  }

  private async setLastReadMessageOnInit() {
    const unreadMessagesCount =
      this.chat.unreadMessagesCount ||
      (await this.chatService.getUnreadMessagesCountByChannel(this.chat.id))

    const otherAuthorMessages = this.messages.filter(
      m => m.authorId !== this.activeUserId,
    )
    const lastMessage = otherAuthorMessages[otherAuthorMessages.length - 1]

    this.chatService.setLastReadMessageOnChannel(
      lastMessage,
      unreadMessagesCount,
    )
  }

  @action.bound
  public async sendMessage(text: string) {
    if (!text) {
      return
    }

    const draftMessage = this.getDraftMessage(text)

    this.messages.push(draftMessage)

    try {
      if (this.isDraftChat) {
        await this.createChat()
      }

      const messageId = await this.chatService.sendMessageToChannel(
        this.chat.id,
        draftMessage.text,
        draftMessage.fakeId,
      )

      const message = this.messages.find(m => m.fakeId === draftMessage.fakeId)
      if (message) {
        message.id = messageId
      }
    } catch {
      this.brokenMessageIds.add(draftMessage.fakeId)
    }
  }

  public isContactOnline(contactId: string): boolean {
    return this.onlineContacts.has(contactId)
  }

  public removeListeners() {
    this.listenersMatrix.forEach(listener => this.socket.off(...listener))
  }

  private registerListeners() {
    this.listenersMatrix.forEach(listener => this.socket.on(...listener))
  }

  private onMessageReceived = async ({ message }: IMessageReceivedEvent) => {
    if (
      message.channelId !== this.chat.id ||
      (message.authorId === this.activeUserId &&
        this.messages.some(m => m.fakeId === message.fakeId))
    ) {
      return
    }

    this.messages.push(message)
    this.onMessageCb()

    this.chatService.setLastReadMessageOnChannel(message)
  }

  private onChatUpdated = (chat: IChat) => {
    if (this.chat.id !== chat.id) {
      return
    }

    if (chat.memberIds.includes(this.activeUserId)) {
      Object.assign(this.chat, chat)
    } else {
      this.onBackClick()
    }
  }

  private get listenersMatrix(): [string, (value: any) => void][] {
    return [
      [ChatEvent.MessageReceived, this.onMessageReceived],
      [ChatEvent.ChatUpdated, this.onChatUpdated],
    ]
  }

  public get activeUserId(): string {
    return this.chatService.userId
  }

  private get socket(): Socket {
    return this.chatService.socket
  }

  private getDraftMessage(text: string): IMessage {
    return {
      id: null,
      text,
      createdAt: Date.now(),
      authorId: this.activeUserId,
      channelId: this.chat.id,
      fakeId: createUuid(),
    }
  }

  @action
  private async createChat() {
    const chatId = await this.chatService.createChat(
      this.chat.isGroup ? this.chat.name : '',
      this.chat.isGroup,
      this.chat.memberIds,
    )

    this.chat.id = chatId
  }

  @action.bound
  public showChatDetails() {
    this.shouldShowChatDetails = true
  }

  @action.bound
  public hideChatDetails() {
    this.shouldShowChatDetails = false
  }

  @action.bound
  public handleEditSuccess(updatedChat: IChat) {
    Object.assign(this.chat, updatedChat)
    this.hideChatDetails()
  }

  public get isChatCreator(): boolean {
    return this.activeUserId === this.chat.createdBy
  }
}
