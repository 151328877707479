import { EMPTY_STRING } from '../../utils/usefulStrings'
import { IDeliveryControlOption } from '../IDeliveryControl'
import { ISearchPredicate } from './ISearchPredicate'

export default class DeliveryControlSearchPredicateBase
  implements ISearchPredicate
{
  public test(option: IDeliveryControlOption, searchCriteria: string): boolean {
    if (!this.testPreConditions(option, searchCriteria)) {
      return false
    }

    return this.testCondition(option, searchCriteria)
  }

  protected testCondition(
    option: IDeliveryControlOption,
    searchCriteria: string,
  ) {
    const { title, cardValues } = option
    const searchKey = searchCriteria?.toLowerCase() || EMPTY_STRING

    return (
      title?.toLowerCase().includes(searchKey) ||
      cardValues?.some(
        v => v.isSearchable && v.value?.toLowerCase().includes(searchKey),
      )
    )
  }

  private testPreConditions(
    option: IDeliveryControlOption,
    searchCriteria: string,
  ): boolean {
    const { hidden, isCategoryLabel } = option

    if (hidden) return false

    if (searchCriteria && isCategoryLabel) return false

    return true
  }
}
