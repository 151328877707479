import React from 'react'

import { observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'

import ThemeMode from '../../../../utils/ThemeModeManager'
import GlobeViewBasePin from './GlobeViewBasePin'

import Colors from '~/client/src/shared/theme.module.scss'

interface IProps {
  itemsCount: number
  isDone: boolean
  isAssigned: boolean
  isSelected: boolean
  isCanceled: boolean
  onClick?: () => void
}

@observer
export default class GlobeViewAnnouncementMapPin extends React.Component<IProps> {
  public render() {
    const { itemsCount, onClick, isSelected } = this.props
    const fillColor = ThemeMode.getHEXColor(Colors.error50)
    return (
      <GlobeViewBasePin
        onClick={onClick}
        itemsCount={itemsCount}
        color={this.iconColor}
        fillColor={fillColor}
        isSelected={isSelected}
        icon={
          <Icons.Announcement
            style={{ fill: this.iconColor }}
            className="globe-pin-image row x-center y-center globe-announcement-icon"
          />
        }
      />
    )
  }

  private get iconColor(): string {
    const { isAssigned, isSelected, isDone, isCanceled } = this.props

    return ThemeMode.getHEXColor(
      isAssigned && isSelected && !isDone && !isCanceled
        ? Colors.neutral0
        : Colors.neutral100,
    )
  }
}
