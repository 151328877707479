import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { QRCode } from 'react-qrcode-logo'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import * as Icons from '~/client/src/shared/components/Icons'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import { TagType } from '~/client/src/shared/enums/TagType'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import {
  SCAN_CODE_SEPARATOR,
  ScanCodeTypes,
} from '~/client/src/shared/models/Scanner'
import { ITag } from '~/client/src/shared/models/Tag'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'
import { NOOP } from '~/client/src/shared/utils/noop'
import { QR_CODE_LOGO_SRC } from '~/client/src/shared/utils/usefulStrings'

import { NEW_TAG_ID } from '../../../TagsDirectory.store'
import TagsDirectoryListRowStore from './TagsDirectoryListRow.store'
import MembersListSelector from './components/MembersListSelector/MembersListSelector'
import TagCellEditor from './components/TagCellEditor/TagCellEditor'
import UserCard from './components/UserCard/UserCard'

import Colors from '~/client/src/shared/theme.module.scss'

interface IProps {
  tag: ITag
  users: User[]

  shouldDisableTagEditing?: boolean

  state?: DesktopInitialState
  companiesStore?: CompaniesStore
  userProjectsStore?: UserProjectsStore

  onTagSave?(tag: ITag): void
  onTagDelete?(tagId: string): void
  onChangeTagForUser(
    userId: string,
    tag: ITag,
    shouldRemoveTagFromUser?: boolean,
  ): void
  onUserProfileClick(userId: string): void
  saveUsers(): void
}

interface ICompanyGroup {
  company: string
  users: User[]
}

const ICON_SIZE = 16
const noMembers = 'No members'
const members = 'Members'
const noCompany = 'No company'

@inject('state', 'companiesStore', 'userProjectsStore')
@observer
export default class TagsDirectoryListRow extends React.PureComponent<IProps> {
  private readonly store: TagsDirectoryListRowStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new TagsDirectoryListRowStore()
  }

  public render() {
    const { handleMouseEnter, handleMouseLeave } = this.store

    return (
      <div
        className="row x-start y-start full-height"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {this.renderDeleteIcon()}
        {this.renderTagColumn()}
        {this.renderMembersColumn()}
      </div>
    )
  }

  private renderDeleteIcon(): JSX.Element {
    const { shouldDisableTagEditing, tag, onTagDelete } = this.props

    if (!this.store.isHovering || shouldDisableTagEditing) {
      return null
    }

    return (
      <span
        className="row delete-tag-icon-container absolute pointer py15 pr20"
        onClick={onTagDelete?.bind(this, tag?.id)}
      >
        <Icons.Delete className="no-grow" />
      </span>
    )
  }

  private renderTagColumn(): JSX.Element {
    const {
      isHovering,
      isTagEditModeEnabled,
      toggleTagEditingMode,
      disableTagEditingMode,
    } = this.store

    const { shouldDisableTagEditing, tag, onTagSave } = this.props

    const onDisableEditingMode = shouldDisableTagEditing
      ? NOOP
      : disableTagEditingMode
    const onToggleEditingMode = shouldDisableTagEditing
      ? NOOP
      : toggleTagEditingMode
    const onSave = shouldDisableTagEditing ? NOOP : onTagSave
    const isTeamTag = tag.type === TagType.Team

    return (
      <MenuCloser
        className={classList({
          'row tags-directory-tag-column pl20 pointer': true,
          'bg-palette-brand-lightest': isHovering,
          'unclickable-element': shouldDisableTagEditing,
          'team-row': isTeamTag,
        })}
        closeMenu={onDisableEditingMode}
        isOpen={isTagEditModeEnabled}
      >
        <div
          className="row relative full-height full-width"
          onClick={onToggleEditingMode}
        >
          <div className="editable-tag-field relative full-width pr10 col">
            {!isTagEditModeEnabled && (
              <SitemapAttributeTag
                dataObject={tag as LocationBase}
                shouldShowAsTag={true}
                contentContainerClassName="text-ellipsis py2"
                iconSize={ICON_SIZE}
              >
                <span title={tag?.name} className="text large">
                  {tag?.name}
                </span>
              </SitemapAttributeTag>
            )}
            {isTagEditModeEnabled && (
              <TagCellEditor tag={tag} onSave={onSave} />
            )}
            {isTeamTag && (
              <div className="col x-center my20">
                <QRCode
                  value={this.tagQRCode}
                  logoImage={QR_CODE_LOGO_SRC}
                  size={200}
                  fgColor={ThemeMode.getHEXColor(Colors.neutral0)}
                />
              </div>
            )}
          </div>
        </div>
      </MenuCloser>
    )
  }

  private get tagQRCode(): string {
    return `${ScanCodeTypes.team}${SCAN_CODE_SEPARATOR}${this.props.tag?.id}`
  }

  private renderMembersColumn(): JSX.Element {
    const {
      shouldShowMemberSelector,
      toggleMemberSelector,
      hideMemberSelector,
    } = this.store

    const { tag, users, saveUsers } = this.props

    const isTagNew = tag.id === NEW_TAG_ID
    const isTeamTag = tag.type === TagType.Team
    const areMembersExisting = !!users?.length

    return (
      <div className="col tags-directory-members-column">
        <MenuCloser
          className="row full-height relative pointer"
          closeMenu={hideMemberSelector}
          isOpen={shouldShowMemberSelector}
        >
          <div
            className={classList({
              'row amount-members': true,
              'inactive-element': isTagNew,
              'team-row': isTeamTag,
            })}
            onClick={toggleMemberSelector}
          >
            <span className="text large mx5">
              {areMembersExisting ? `${users.length} ${members}` : noMembers}
            </span>
          </div>
          {shouldShowMemberSelector && (
            <MembersListSelector
              users={users}
              onAddTagToUser={this.onAddTagToUser}
              onRemoveTagFromUser={this.onRemoveTagFromUser}
              saveUsers={saveUsers}
            />
          )}
        </MenuCloser>
        {areMembersExisting && this.renderMembers()}
      </div>
    )
  }

  private renderMembers(): JSX.Element[] {
    const { toggleMemberSelector } = this.store
    const { onUserProfileClick } = this.props

    return this.usersGroupedByCompany.map(({ company, users }) => {
      return (
        <div className="col mb10" key={company}>
          <div className="row bg-palette-brand-lightest ba-light-input-border px12 py4">
            <div className="w-fit-content text large bold ellipsis">
              {company}
            </div>
            <div className="ml4 text large bold no-grow">{`(${users.length})`}</div>
            <Icon
              className="ml10 pointer"
              icon={IconNames.PLUS}
              onClick={toggleMemberSelector}
            />
          </div>
          <div className="row y-start overflow-auto tag-directory-band-users-row">
            {users.map(user => {
              return (
                <div
                  key={user.id}
                  className="no-grow m5"
                  onClick={this.stopPropagation}
                >
                  <UserCard
                    user={user}
                    onRemoveClick={this.removeTagFromUserImmediately}
                    onUserProfileClick={onUserProfileClick}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )
    })
  }

  private onAddTagToUser = (userId: string) => {
    const { tag, onChangeTagForUser } = this.props

    onChangeTagForUser(userId, tag)
  }

  private onRemoveTagFromUser = (userId: string) => {
    const { tag, onChangeTagForUser } = this.props

    onChangeTagForUser(userId, tag, true)
  }

  private removeTagFromUserImmediately = (userId: string) => {
    this.onRemoveTagFromUser(userId)
    this.props.saveUsers()
  }

  private stopPropagation = (evt: React.SyntheticEvent) => {
    evt?.stopPropagation()
  }

  @computed
  private get usersGroupedByCompany(): ICompanyGroup[] {
    const { users, companiesStore, userProjectsStore } = this.props

    const usersByCompaniesMap = users.reduce((map, user) => {
      const company =
        UserProject.getCompanyName(user, userProjectsStore, companiesStore) ||
        noCompany

      if (!map[company]) {
        map[company] = []
      }

      map[company].push(user)

      return map
    }, {} as { [company: string]: User[] })

    const fullList = Object.keys(usersByCompaniesMap)
      .sort()
      .reduce((list, company) => {
        const usersUsersByCompanies = usersByCompaniesMap[company]
        list.push({ company, users: usersUsersByCompanies })
        return list
      }, [] as ICompanyGroup[])

    return fullList
  }
}
