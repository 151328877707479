import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { classList } from 'react-classlist-helper'

// localization: no display text to translate

export enum DiagramLineType {
  Default = 'line',
  Vertical = 'vertical-line',
  VerticalLong = 'vertical-long-line',
  VerticalLeftLine = 'vertical-left-line',
  VerticalLeftLineLong = 'vertical-left-line-long',
}

const { CARET_RIGHT, CARET_DOWN } = IconNames

const verticalTypes = [
  DiagramLineType.Vertical,
  DiagramLineType.VerticalLeftLine,
  DiagramLineType.VerticalLong,
  DiagramLineType.VerticalLeftLineLong,
]

interface IProps {
  diagramLineType?: DiagramLineType
  className?: string
  shouldIncludeArrow?: boolean
  top?: number
  left?: number
}

export default function WorkflowDiagramLine({
  className,
  shouldIncludeArrow,
  diagramLineType = DiagramLineType.Default,
  top,
  left,
}: IProps): JSX.Element {
  const isVerticalLine = verticalTypes.includes(diagramLineType)
  const arrowIcon = isVerticalLine ? CARET_DOWN : CARET_RIGHT

  return (
    <div
      className={classList({
        'row x-end y-center': diagramLineType === DiagramLineType.Default,
        'col x-center y-end': isVerticalLine,
        [className]: !!className,
      })}
      style={{ top, left }}
    >
      <div className={diagramLineType} />
      {shouldIncludeArrow && (
        <Icon
          className={classList({
            absolute: true,
            'vertical-arrow': isVerticalLine,
            arrow: !isVerticalLine,
          })}
          icon={arrowIcon}
        />
      )}
    </div>
  )
}
