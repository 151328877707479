import * as React from 'react'

import { observer } from 'mobx-react'

import { IDeliveryConfigurations } from '~/client/graph'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import CompactConfirmDialog from '../../../CompactConfirmDialog/CompactConfirmDialog'

// translated

export interface IProps {
  isShown: boolean
  onHide: () => void
  onApply: () => void
  deliveryConfigurations: IDeliveryConfigurations
}

@observer
export default class DeliveryChangeDateConfirm extends React.Component<IProps> {
  public static defaultProps = {
    isBlockMode: false,
  }

  public render() {
    const { isShown, onHide, onApply, deliveryConfigurations } = this.props

    const { shouldBlockLateRequesting = false, deadlineInterval } =
      deliveryConfigurations

    let title: string
    let applyButtonText: string
    let message: string
    if (shouldBlockLateRequesting) {
      title = Localization.translator.changeDate
      applyButtonText = Localization.translator.yesMoveTheDate
      message = Localization.translator.deliveryRequestIsNotValid
    } else {
      title = Localization.translator.datePassedDeadline
      applyButtonText = Localization.translator.requestAnyway
      message = Localization.translator.thisRequestHasPassedTheDeadline
    }

    return (
      <CompactConfirmDialog
        title={title}
        isShown={isShown}
        onHide={onHide}
        onApply={onApply}
        applyButtonText={applyButtonText}
        canOutsideClickClose={false}
      >
        <>
          <div className="text extra-large pb5">{message}</div>
          <div className="text extra-large">
            {Localization.translator.deliveryDescriptions.dateChange(
              deadlineInterval,
            )}
          </div>
        </>
      </CompactConfirmDialog>
    )
  }
}
