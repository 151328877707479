import * as React from 'react'

import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import ProjectScheduleUploadStore from '../ProjectScheduleUpload.store'

export interface IProps {
  projectScheduleUploadStore: ProjectScheduleUploadStore
}

@observer
export default class VerificationBlock extends React.Component<IProps> {
  public render() {
    const { uploadScheduleResult } = this.props.projectScheduleUploadStore
    if (!uploadScheduleResult) {
      return null
    }

    const {
      schedule: { activityQuantity },
      compareData: { activityCodeTypesForCompare },
    } = this.props.projectScheduleUploadStore.uploadScheduleResult

    return (
      <>
        <div className="setup-form-header bb-light-grey mb15">
          {Localization.translator.verification}
        </div>
        <div>
          <div className="upload-schedule-verification-row">
            <div className="check-icon" />
            {...Localization.translator.xActivitiesAdded(
              activityQuantity,
              <span
                key={activityQuantity}
                className="upload-schedule-verification-count"
              >
                {activityQuantity}
              </span>,
            )}
            <span className="upload-schedule-link upload-schedule-verification-details">
              {Localization.translator.seeDetails}
            </span>
          </div>
          {activityCodeTypesForCompare.map(({ categoryName, uploadCount }) => {
            if (!uploadCount) {
              return null
            }

            return (
              <div
                key={categoryName}
                className="upload-schedule-verification-row"
              >
                <div className="check-icon" />
                {...Localization.translator.xTypeActivityCodesAdded(
                  uploadCount,
                  <span
                    key={categoryName}
                    className="upload-schedule-verification-count"
                  >
                    {uploadCount}
                  </span>,
                  categoryName,
                )}
                <span className="upload-schedule-link upload-schedule-verification-details">
                  {Localization.translator.seeDetails}
                </span>
              </div>
            )
          })}
        </div>
      </>
    )
  }
}
