import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { LocationType } from '~/client/graph'
import Checkbox from '~/client/src/shared/components/Checkbox'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import InitialState from '~/client/src/shared/stores/InitialState'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'

import TagsStore from '../../stores/domain/Tags.store'
import { sortAttributes } from '../../utils/sortingFunctions'
import { HIERARCHY_SEPARATOR } from '../HierarchyChains'
import { HGap } from '../Layout'
import Location from './Location'

const ICON_SIZE = 16

interface ILocationType {
  title: string
  icon: JSX.Element
  type: LocationType
  items: LocationBase[]
}

interface IProps {
  selectedIds: string[]
  onChanged(selectedIds: string[]): void
  setItemRef(id: string, element: HTMLDivElement): void
  hasCompanyRestriction(location: LocationBase): boolean
  renderReservedForMessage(location: LocationBase): JSX.Element

  allowedObjectIds?: string[]
  restrictedObjectIds?: string[]
  restrictedObjectTypes?: LocationType[]
  isSingleSelectionMode?: boolean
  firstRowText?: string
  firstRowValue?: boolean
  firstRowAction?: () => void
  onSingleSelect?(attr: LocationBase): void

  state?: InitialState
  locationAttributesStore?: LocationAttributesStore
  tagsStore?: TagsStore
}
const informationPoints = 'Information Points'

@inject('state', 'locationAttributesStore', 'tagsStore')
@observer
export default class LocationsSelectorByLocationType extends React.Component<IProps> {
  @observable private attributesExpandState = new Map<string, boolean>()

  public componentDidMount() {
    Object.values(LocationType).forEach(type => {
      this.attributesExpandState.set(type, true)
    })
  }

  public render() {
    const {
      isSingleSelectionMode,
      firstRowText,
      firstRowAction,
      firstRowValue,
      setItemRef,
      hasCompanyRestriction,
      renderReservedForMessage,
    } = this.props

    return (
      <>
        {firstRowText && (
          <div
            className="bt-palette-grey bb-palette-grey bg-palette-brand-lightest px12 py8 row y-center y-start row px12 py8"
            key={firstRowText}
          >
            <Checkbox
              className={classList({ checked: firstRowValue })}
              isChecked={firstRowValue}
              onClick={firstRowAction}
            />
            <div className="row y-center pointer overflow-hidden">
              <div className="overflow-hidden">{firstRowText}</div>
            </div>
          </div>
        )}
        {this.locationTypes.map(({ items, type, icon, title }, idx) => {
          const isSelected = this.areAllAttributesSelected(items)
          const isExpanded = this.attributesExpandState.get(type)
          const iconName = isExpanded
            ? IconNames.CARET_DOWN
            : IconNames.CARET_RIGHT

          return (
            <div key={idx}>
              <div className="bt-palette-grey bb-palette-grey bg-palette-brand-lightest px12 py8 row y-center">
                <div className="no-grow">
                  {!isSingleSelectionMode && (
                    <Checkbox
                      className={classList({ checked: isSelected })}
                      isChecked={isSelected}
                      onClick={this.toggleMany.bind(this, items)}
                    />
                  )}
                </div>
                <div
                  className="row pointer"
                  onClick={this.toggleAttributeSection.bind(this, type)}
                >
                  <div className="no-grow">
                    <Icon icon={iconName} className="no-grow" />
                  </div>
                  <div className="no-grow pr4">{icon}</div>
                  <div className="text bold extra-large">{title}</div>
                  <div className="no-grow pr4 text large">{items.length}</div>
                </div>
              </div>
              {isExpanded &&
                items.map(dto => {
                  const isItemSelected = this.isAttributeSelected(dto)
                  return (
                    <div
                      className="y-start row px12 py8"
                      key={dto.id}
                      ref={setItemRef.bind(null, dto.id)}
                    >
                      <div className="no-grow">
                        {!hasCompanyRestriction(dto) ? (
                          <Checkbox
                            className={classList({ checked: isItemSelected })}
                            isChecked={isItemSelected}
                            onClick={this.toggleCheckbox.bind(this, dto)}
                          />
                        ) : (
                          <HGap size="28" />
                        )}
                      </div>
                      <div className="col">
                        <div className="overflow-hidden">
                          <Location dto={dto} />
                        </div>
                        {renderReservedForMessage(dto)}
                      </div>
                    </div>
                  )
                })}
            </div>
          )
        })}
      </>
    )
  }

  @computed
  private get locationTypes(): ILocationType[] {
    const {
      state,
      locationAttributesStore,
      restrictedObjectTypes = [],
    } = this.props
    const { tagStoreByTagTypeMap } = this.props.tagsStore

    return [
      {
        title: state.getDeliveryFieldName(FieldIds.BUILDING),
        icon: <TagIcon.Building size={ICON_SIZE} />,
        items: this.filterItemsByIds(
          locationAttributesStore.buildingsStore.list.sort(sortAttributes),
        ),
        type: LocationType.Building,
      },
      {
        title: Localization.translator.level,
        icon: <TagIcon.Level size={ICON_SIZE} />,
        items: this.filterItemsByIds(locationAttributesStore.levelsStore.list),
        type: LocationType.Level,
      },
      {
        title: Localization.translator.area,
        icon: <TagIcon.Area size={ICON_SIZE} />,
        items: this.filterItemsByIds(
          locationAttributesStore.areasStore.list,
        ).sort(sortAttributes),
        type: LocationType.Area,
      },
      {
        title: state.getDeliveryFieldName(FieldIds.ZONE),
        icon: <TagIcon.Zone size={ICON_SIZE} />,
        items: this.filterItemsByIds(
          locationAttributesStore.zonesStore.list,
        ).sort(sortAttributes),
        type: LocationType.Zone,
      },
      {
        title: state.getDeliveryFieldName(FieldIds.STAGING),
        icon: <TagIcon.Staging size={ICON_SIZE} />,
        items: this.filterItemsByIds(
          locationAttributesStore.stagingsStore.list,
        ).sort(sortAttributes),
        type: LocationType.Staging,
      },
      {
        title: state.getDeliveryFieldName(FieldIds.OFFLOADING_EQUIPMENT),
        icon: <TagIcon.Equipment size={ICON_SIZE} />,
        items: this.filterItemsByIds(
          locationAttributesStore.offloadingEquipmentsStore.list,
        ).sort(sortAttributes),
        type: LocationType.OffloadingEquipment,
      },
      {
        title: state.getDeliveryFieldName(FieldIds.ROUTE),
        icon: <TagIcon.Route size={ICON_SIZE} />,
        items: this.filterItemsByIds(
          locationAttributesStore.routesStore.list,
        ).sort(sortAttributes),
        type: LocationType.Route,
      },
      {
        title: state.getDeliveryFieldName(FieldIds.GATE),
        icon: <TagIcon.Gate size={ICON_SIZE} />,
        items: this.filterItemsByIds(
          locationAttributesStore.gatesStore.list,
        ).sort(sortAttributes),
        type: LocationType.Gate,
      },
      {
        title: state.getDeliveryFieldName(FieldIds.INTERIOR_DOOR),
        icon: <TagIcon.InteriorDoor size={ICON_SIZE} />,
        items: this.filterItemsByIds(
          locationAttributesStore.interiorDoorsStore.list,
        ).sort(sortAttributes),
        type: LocationType.InteriorDoor,
      },
      {
        title: state.getDeliveryFieldName(FieldIds.INTERIOR_PATH),
        icon: <TagIcon.InteriorPath size={ICON_SIZE} />,
        items: this.filterItemsByIds(
          locationAttributesStore.interiorPathsStore.list,
        ).sort(sortAttributes),
        type: LocationType.InteriorPath,
      },
      {
        title: informationPoints,
        icon: <TagIcon.LogisticsObject size={ICON_SIZE} />,
        items: this.filterItemsByIds(
          locationAttributesStore.logisticsObjectsStore.list,
        ).sort(sortAttributes),
        type: LocationType.LogisticsObject,
      },
      {
        title: state.getDeliveryFieldName(FieldIds.VERTICAL_OBJECT),
        icon: <TagIcon.Stairs size={ICON_SIZE} />,
        items: this.filterItemsByIds(
          locationAttributesStore.verticalObjectsStore.list,
        ).sort(sortAttributes),
        type: LocationType.VerticalObject,
      },
    ]
      .filter(l => !restrictedObjectTypes.includes(l.type) && l.items.length)
      .map(l => {
        l.items = l.items.sort((a, b) => {
          const aH = a
            .getHierarchyChains(tagStoreByTagTypeMap)
            .reverse()
            .join(HIERARCHY_SEPARATOR)
          const bH = b
            .getHierarchyChains(tagStoreByTagTypeMap)
            .reverse()
            .join(HIERARCHY_SEPARATOR)

          return aH.localeCompare(bH)
        })
        return l
      })
  }

  private areAllAttributesSelected(attributes: LocationBase[]) {
    return attributes.every(a => this.isAttributeSelected(a))
  }

  private isAttributeSelected(attribute: LocationBase) {
    return this.props.selectedIds.includes(attribute.id)
  }

  private toggleCheckbox(dto: LocationBase) {
    const { selectedIds, onChanged, isSingleSelectionMode, onSingleSelect } =
      this.props

    const isItemSelected = this.isAttributeSelected(dto)

    if (isSingleSelectionMode) {
      return onSingleSelect(!isItemSelected && dto)
    }

    if (isItemSelected) {
      onChanged(selectedIds.filter(id => id !== dto.id))
    } else {
      onChanged([...selectedIds, dto.id])
    }
  }

  private toggleMany(items: LocationBase[]) {
    const { selectedIds, hasCompanyRestriction, onChanged } = this.props
    const isSelected = this.areAllAttributesSelected(items)
    const itemIds = items
      .filter(item => !hasCompanyRestriction(item))
      .map(({ id }) => id)

    if (isSelected) {
      onChanged(selectedIds.filter(id => !itemIds.includes(id)))
    } else {
      onChanged(Array.from(new Set([...selectedIds, ...itemIds])))
    }
  }

  private filterItemsByIds = (items: LocationBase[]): LocationBase[] => {
    const { allowedObjectIds, restrictedObjectIds } = this.props

    const itemsWithoutRestrictedObjs = items.filter(
      ({ id }) => !restrictedObjectIds?.includes(id),
    )

    if (allowedObjectIds?.length) {
      return itemsWithoutRestrictedObjs.filter(({ id }) =>
        allowedObjectIds.includes(id),
      )
    }

    return itemsWithoutRestrictedObjs
  }

  private toggleAttributeSection = (attribute: string) => {
    const currentState = this.attributesExpandState.get(attribute)
    this.attributesExpandState.set(attribute, !currentState)
  }
}
