import React from 'react'

import { FileInput, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DndArea from '~/client/src/shared/components/DndArea/DndArea'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import FileType from '~/client/src/shared/enums/FileType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import MapViewSetUpStore, {
  ACCEPTED_SITEMAP_EXTENSIONS,
} from '../../../MapViewSetUp.store'

export interface IProps {
  store: MapViewSetUpStore
}

const BASEMAP_TITLE_WIDTH = 240
const BASEMAP_TITLE_HEIGHT = 30

const EXTENSIONS_SEPARATOR = ','
const withThisOne = '... with this one.'
const replacePlan = 'Replace this plan'
const dragMapHere = 'Drag map here'
const acceptedExtensions = '(.pdf, .png, .tif accepted)'
const selectPage = 'Select page'
const browseYourHardDrive = 'Browse your hard drive'
const planUploaded = 'Plan uploaded'
const uploadingPlan = 'Uploading plan'

@observer
export default class UpdateSitemapDialog extends React.Component<IProps> {
  public render(): JSX.Element {
    const { selectedBasemap } = this.props.store.sitemapsSetupStore
    const {
      isUploading,
      basemap,
      toggleEditMenu,
      setSitemapBasemap,
      setPdfOrUpdateBasemap,
      hideEditMenu,
    } = this.props.store.sitemapControlStore

    const isSaveButtonActive = !isUploading && !!basemap
    return (
      <MenuCloser
        closeMenu={toggleEditMenu}
        className="col absolute tooltip-popup brada10"
      >
        <div className="row px20 pt20 mb20">
          <div className="col">
            <div className="text large bold">{replacePlan}</div>
            <div>
              <DesktopFileInput
                id={selectedBasemap.id}
                name=""
                value={selectedBasemap.source}
                isReadonly={true}
                textClassName="hint"
                shouldHideIconAndOutline={true}
                shouldHideName={true}
              />
            </div>
            <div
              style={{
                height: BASEMAP_TITLE_HEIGHT,
                maxWidth: BASEMAP_TITLE_WIDTH,
              }}
              className="text large text-ellipsis"
            >
              {selectedBasemap.fileName}
            </div>
          </div>
          <div className="col y-center no-grow mx10">
            <Icon icon={IconNames.ARROW_RIGHT} />
          </div>
          <div className="col">
            <div className="text large bold">{withThisOne}</div>
            {!basemap ? (
              <div className="general-map-setup-dnd-area">
                <DndArea
                  fileType={FileType.Image}
                  accept={ACCEPTED_SITEMAP_EXTENSIONS}
                  title={this.title}
                  onDrop={setSitemapBasemap}
                  className="upload-schedule-dnd-container col mt20 x-center y-center"
                />
                {this.renderBrowseComputerLine()}
              </div>
            ) : (
              <>
                <DesktopFileInput
                  id={basemap.id}
                  name=""
                  value={basemap.source}
                  isReadonly={true}
                  textClassName="hint"
                  shouldHideIconAndOutline={true}
                />
                {this.renderBrowseComputerLine()}
              </>
            )}
          </div>
        </div>
        {this.renderActionBottomButtons(
          Localization.translator.clear,
          this.submitTitle,
          hideEditMenu,
          setPdfOrUpdateBasemap,
          isSaveButtonActive,
        )}
      </MenuCloser>
    )
  }

  private get submitTitle(): string {
    return this.props.store.sitemapControlStore.isPdfTypeBasemapSelected
      ? selectPage
      : Localization.translator.save
  }

  private renderActionBottomButtons(
    cancelText: string,
    applyText: string,
    cancelAction: () => void,
    applyAction: () => void,
    isApplyActive: boolean,
  ): JSX.Element {
    return (
      <div className="row bt-light-input-border">
        <div className="bt-input-border row x-around dialog-footer">
          <div
            className="text large ml20 mr10 row x-center y-center pointer action-button mt5 mb5"
            onClick={cancelAction}
          >
            {cancelText}
          </div>
          <div
            className={classList({
              'bg-blue-bright brada5 text white large mr20 ml10 row x-center y-center pointer action-button mt5 mb5':
                true,
              'inactive-element unclickable-element': !isApplyActive,
            })}
            onClick={applyAction}
          >
            {this.props.store.sitemapControlStore.isUploading ? (
              <Loader />
            ) : (
              applyText
            )}
          </div>
        </div>
      </div>
    )
  }

  private get title(): string | JSX.Element {
    if (this.props.store.sitemapControlStore.isUploading) {
      return <Loader hint={uploadingPlan} />
    }

    if (this.props.store.sitemapControlStore.sitemapPdfFile) {
      return planUploaded
    }

    return (
      <div className="col text large no-grow x-center">
        <span>
          <Icons.Upload className="no-grow" /> {dragMapHere}
        </span>
        <span>{acceptedExtensions}</span>
      </div>
    )
  }

  private renderBrowseComputerLine = (): JSX.Element => {
    return (
      <div>
        <FileInput
          className="top-controls-upload-csv-button tooltip-menu-option"
          disabled={false}
          inputProps={{
            onChange:
              this.props.store.sitemapControlStore.setSitemapBasemapFromBrowse,
            accept: ACCEPTED_SITEMAP_EXTENSIONS.join(EXTENSIONS_SEPARATOR),
            value: EMPTY_STRING,
          }}
          text={
            <span className="row text tooltip-menu-option px10 large pointer light-blue bold x-end">
              {!this.props.store.sitemapControlStore.basemap
                ? browseYourHardDrive
                : Localization.translator.change_verb}
            </span>
          }
        />
      </div>
    )
  }
}
