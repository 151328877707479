import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IStatusFieldsFragment = Pick<
  Types.ISitePermitStatusChange,
  | 'createdAt'
  | 'id'
  | 'permitId'
  | 'projectId'
  | 'status'
  | 'workflowStepLevel'
  | 'threadId'
  | 'updatedAt'
  | 'userId'
>

export type IGetSitePermitStatusChangeListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  permitId?: Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IGetSitePermitStatusChangeListQuery = {
  sitePermitStatusChanges?: Types.Maybe<{ data: Array<IStatusFieldsFragment> }>
}

export type IListenToSitePermitStatusChangeSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  permitId?: Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IListenToSitePermitStatusChangeSubscription = {
  sitePermitStatusChange?: Types.Maybe<
    Pick<Types.ISitePermitStatusChangeChangeEvent, 'id'> & {
      item?: Types.Maybe<IStatusFieldsFragment>
    }
  >
}

export const StatusFieldsFragmentDoc = gql`
  fragment StatusFields on SitePermitStatusChange {
    createdAt
    id
    permitId
    projectId
    status
    workflowStepLevel
    threadId
    updatedAt
    userId
  }
`
export const GetSitePermitStatusChangeListDocument = gql`
  query GetSitePermitStatusChangeList(
    $projectId: ObjectId!
    $permitId: ObjectId
  ) {
    sitePermitStatusChanges(
      limit: 1000000
      projectId: $projectId
      permitId: $permitId
    ) {
      data {
        ...StatusFields
      }
    }
  }
  ${StatusFieldsFragmentDoc}
`

/**
 * __useGetSitePermitStatusChangeListQuery__
 *
 * To run a query within a React component, call `useGetSitePermitStatusChangeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitePermitStatusChangeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitePermitStatusChangeListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      permitId: // value for 'permitId'
 *   },
 * });
 */
export function useGetSitePermitStatusChangeListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetSitePermitStatusChangeListQuery,
    IGetSitePermitStatusChangeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetSitePermitStatusChangeListQuery,
    IGetSitePermitStatusChangeListQueryVariables
  >(GetSitePermitStatusChangeListDocument, options)
}
export function useGetSitePermitStatusChangeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetSitePermitStatusChangeListQuery,
    IGetSitePermitStatusChangeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetSitePermitStatusChangeListQuery,
    IGetSitePermitStatusChangeListQueryVariables
  >(GetSitePermitStatusChangeListDocument, options)
}
export type GetSitePermitStatusChangeListQueryHookResult = ReturnType<
  typeof useGetSitePermitStatusChangeListQuery
>
export type GetSitePermitStatusChangeListLazyQueryHookResult = ReturnType<
  typeof useGetSitePermitStatusChangeListLazyQuery
>
export type GetSitePermitStatusChangeListQueryResult = Apollo.QueryResult<
  IGetSitePermitStatusChangeListQuery,
  IGetSitePermitStatusChangeListQueryVariables
>
export const ListenToSitePermitStatusChangeDocument = gql`
  subscription ListenToSitePermitStatusChange(
    $projectId: ObjectId!
    $permitId: ObjectId
  ) {
    sitePermitStatusChange(projectId: $projectId, permitId: $permitId) {
      id
      item {
        ...StatusFields
      }
    }
  }
  ${StatusFieldsFragmentDoc}
`

/**
 * __useListenToSitePermitStatusChangeSubscription__
 *
 * To run a query within a React component, call `useListenToSitePermitStatusChangeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToSitePermitStatusChangeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToSitePermitStatusChangeSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      permitId: // value for 'permitId'
 *   },
 * });
 */
export function useListenToSitePermitStatusChangeSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToSitePermitStatusChangeSubscription,
    IListenToSitePermitStatusChangeSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToSitePermitStatusChangeSubscription,
    IListenToSitePermitStatusChangeSubscriptionVariables
  >(ListenToSitePermitStatusChangeDocument, options)
}
export type ListenToSitePermitStatusChangeSubscriptionHookResult = ReturnType<
  typeof useListenToSitePermitStatusChangeSubscription
>
export type ListenToSitePermitStatusChangeSubscriptionResult =
  Apollo.SubscriptionResult<IListenToSitePermitStatusChangeSubscription>
