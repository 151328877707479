import * as React from 'react'

import { inject, observer } from 'mobx-react'

import FlaggedFeatures from '~/client/src/shared/enums/FlaggedFeatures'
import BaseEventStore from '~/client/src/shared/stores/EventStore/BaseEvents.store'
import { GET_PROJECT_CONFIG } from '~/client/src/shared/stores/EventStore/eventConstants'

interface IProps {
  features: FlaggedFeatures[]
  defaultValues: boolean[]
  render: (flag: boolean[]) => React.ComponentType | React.ReactNode
  eventsStore?: BaseEventStore
}

@inject('eventsStore')
@observer
export default class FeatureFlag extends React.Component<IProps> {
  public componentDidMount() {
    this.props.eventsStore.dispatch(GET_PROJECT_CONFIG)
  }

  public render() {
    const { render, defaultValues, eventsStore, features } = this.props
    const { featureFlags } = eventsStore.appState

    return render(
      features.map((f, index) =>
        typeof featureFlags[f] === 'undefined'
          ? defaultValues[index]
          : featureFlags[f],
      ),
    )
  }
}
