import { action } from 'mobx'

import { IDeliveryTreeNode } from '../../models/IDeliveryTreeNode'
import CompactDeliveryListStore from '../../stores/ui/CompactDeliveryList.store'

export default class DeliveriesTreeStore {
  public constructor(
    private readonly compactDeliveryListStore: CompactDeliveryListStore,
  ) {}

  @action.bound
  public handleChangeTree(tree: IDeliveryTreeNode[]) {
    Object.values(tree).forEach((node: IDeliveryTreeNode) => {
      this.changeNode(node)
    })
    this.compactDeliveryListStore.resetForceCollapse()
  }

  private changeNode(node: IDeliveryTreeNode) {
    const {
      id,
      children,
      state: { expanded: isExpanded },
    } = node

    if (!children || !children.length) {
      return
    }
    const stringId = id.toString()
    const currentValue =
      this.compactDeliveryListStore.nodesState.get(stringId) || false
    if (currentValue !== isExpanded) {
      this.compactDeliveryListStore.nodesState.set(stringId, isExpanded)
    }
    Object.values(children).forEach((subNode: IDeliveryTreeNode) => {
      this.changeNode(subNode)
    })
  }
}
