import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import { ONE_MINUTE_HEIGHT_PX } from '~/client/src/shared/stores/ui/CalendarView.store'
import ProjectDateStore, {
  MINUTES_IN_HOUR,
} from '~/client/src/shared/stores/ui/ProjectDate.store'

import { NOOP } from '../../../utils/noop'

export interface IProps {
  activeDate?: Date
  hour: number
  timeInterval: number
  rowsNumber: number
  onEmptyCellTouchStart?: (date: Date, event?: React.SyntheticEvent) => void
  isNonWorkingDay: boolean
  shouldHandleMouseClick?: boolean

  projectDateStore?: ProjectDateStore
}

interface IRow {
  className: string
  onRowTouchStart?: (event: React.SyntheticEvent) => void
  onRowMouseDown?: (event: React.SyntheticEvent) => void
}

interface INonWorkTimeStyles {
  height: number
  marginTop: number
  zIndex: number
}

@inject('projectDateStore')
@observer
export default class HourSpace extends React.Component<IProps> {
  private get rowStyle() {
    return { height: ONE_MINUTE_HEIGHT_PX * this.props.timeInterval, zIndex: 2 }
  }

  @computed
  private get minutesRows(): IRow[] {
    const { rowsNumber, timeInterval, shouldHandleMouseClick } = this.props

    return Array.from(Array(rowsNumber)).map((v, i) => {
      const onTouchHandler = this.onRowTouchStart.bind(this, i * timeInterval)

      const row = {
        element: null,
        onRowTouchStart: !shouldHandleMouseClick ? onTouchHandler : NOOP,
        onRowMouseDown: shouldHandleMouseClick ? onTouchHandler : NOOP,
        className:
          i + 1 < rowsNumber ? 'bb-light-grey-dashed' : 'bb-light-grey',
      }

      return row
    })
  }

  public render() {
    return (
      <div className="col">
        {this.minutesRows.map((row, index) => {
          return (
            <div
              key={index}
              className={row.className}
              onTouchStart={row.onRowTouchStart}
              onMouseDown={row.onRowMouseDown}
              style={this.rowStyle}
            />
          )
        })}
        {this.renderNonWorkTimeBackground()}
      </div>
    )
  }

  private renderNonWorkTimeBackground(): JSX.Element {
    const { projectEndHourMinutes } = this.props.projectDateStore
    const [, projectEndMinutes] = projectEndHourMinutes

    return (
      <>
        <div
          className="absolute bg-palette-brand-lightest full-width"
          style={this.backgroundStyle}
        />
        {this.shouldShowSecondBackground && (
          <div
            className="absolute bg-palette-brand-lightest full-width"
            style={this.getStyle(
              MINUTES_IN_HOUR - projectEndMinutes,
              projectEndMinutes,
            )}
          />
        )}
      </>
    )
  }

  private onRowTouchStart(minutes: number, event: any) {
    const {
      onEmptyCellTouchStart,
      hour,
      activeDate,
      projectDateStore: { setHours },
    } = this.props

    if (onEmptyCellTouchStart) {
      const data = setHours(activeDate || null, hour, minutes)
      onEmptyCellTouchStart(data, event)
    }
  }

  private get isProjectStartsAndEndsAtHour(): boolean {
    const {
      hour,
      projectDateStore: { projectStartHourMinutes, projectEndHourMinutes },
    } = this.props

    const [projectStartHour] = projectStartHourMinutes
    const [projectEndHour] = projectEndHourMinutes

    return hour === projectStartHour && hour === projectEndHour
  }

  private get shouldShowSecondBackground(): boolean {
    const {
      isNonWorkingDay,
      projectDateStore: {
        isProjectStartsLaterThanEnds,
        areProjectStartEndTimesTheSame,
      },
    } = this.props

    return (
      !isNonWorkingDay &&
      !areProjectStartEndTimesTheSame &&
      !isProjectStartsLaterThanEnds &&
      this.isProjectStartsAndEndsAtHour
    )
  }

  private get backgroundStyle(): INonWorkTimeStyles {
    const { hour, isNonWorkingDay, activeDate, projectDateStore } = this.props
    const {
      setHours,
      areTimesInsideWorkingHours,
      areProjectStartEndTimesTheSame,
      isProjectStartsLaterThanEnds,
      projectStartHourMinutes,
      projectEndHourMinutes,
    } = projectDateStore

    if (isNonWorkingDay) {
      return this.getStyle(MINUTES_IN_HOUR)
    }

    const nowDate = new Date()
    const startDate = setHours(activeDate || nowDate, hour, 0)
    const endDate = setHours(activeDate || nowDate, hour + 1, 0)

    if (
      areProjectStartEndTimesTheSame ||
      (areTimesInsideWorkingHours(startDate, endDate) &&
        !this.isProjectStartsAndEndsAtHour)
    ) {
      return null
    }

    const [projectStartHour, projectStartMinutes] = projectStartHourMinutes
    const [projectEndHour, projectEndMinutes] = projectEndHourMinutes

    if (this.isProjectStartsAndEndsAtHour) {
      const height = isProjectStartsLaterThanEnds
        ? projectStartMinutes - projectEndMinutes
        : projectStartMinutes

      const marginTop = isProjectStartsLaterThanEnds && projectEndMinutes

      return this.getStyle(height, marginTop)
    }

    switch (hour) {
      case projectStartHour:
        return this.getStyle(projectStartMinutes)
      case projectEndHour:
        return this.getStyle(
          MINUTES_IN_HOUR - projectEndMinutes,
          projectEndMinutes,
        )
    }

    return this.getStyle(MINUTES_IN_HOUR)
  }

  private getStyle = (
    height: number,
    marginTop?: number,
  ): INonWorkTimeStyles => {
    return {
      height,
      marginTop,
      zIndex: 1,
    }
  }
}
