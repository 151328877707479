import { computed, observable } from 'mobx'

import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import { ChartDay } from '~/client/src/shared/models/ChartDay'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import ActivityGanttOrListViewStore from '../../ActivityGanttOrListView.store'

const ROW_SCROLLING_DURATION = 500
const MAX_PERCENT = 100

export default class SimpleGanttChartViewStore {
  @observable public isAnimating: boolean = false

  public constructor(
    private readonly ganttOrListStore: ActivityGanttOrListViewStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly activityListStore: DesktopActivityListStore,
    private readonly statusUpdateStore: StatusUpdatesStore,
  ) {}

  @computed
  public get days(): ChartDay[] {
    const { startDate } = this.ganttOrListStore
    const { addDays, startOfDay } = this.projectDateStore

    return Array(this.ganttOrListStore.displayingDaysCount)
      .fill(0)
      .map((_, i) => {
        const currentDay = addDays(startOfDay(startDate), i)
        const ppc = this.calcPPCForDay(currentDay)

        return new ChartDay(currentDay, this.projectDateStore, ppc)
      })
  }

  public calcPPCForDay(dayDate: Date): number {
    const { isSaturday, startOfWeek } = this.projectDateStore

    if (!isSaturday(dayDate)) {
      return null
    }

    const { activitiesInChartInterval } = this.activityListStore

    const weeklyActs = activitiesInChartInterval.filter(a =>
      a.isActiveOrPlannedBetween(
        startOfWeek(dayDate),
        dayDate,
        this.projectDateStore,
        this.statusUpdateStore,
      ),
    )

    const weeklyActsCount = weeklyActs.length

    if (!weeklyActsCount) {
      return null
    }

    const doneWeeklyActsCount = weeklyActs.filter(a =>
      a.hasDoneOnTimeStatus(this.projectDateStore),
    ).length

    return Math.ceil((doneWeeklyActsCount / weeklyActsCount) * MAX_PERCENT)
  }

  public scrollToBottom() {
    const maxScrollToIndex =
      this.ganttOrListStore.expandedOrderedTableRowsList.length - 1
    if (maxScrollToIndex <= 0) {
      return
    }
    const startMs = Date.now()
    this.isAnimating = true
    this.ganttOrListStore.tableScrollToIndex = 0

    this.updateScrollingValue(startMs, maxScrollToIndex)
  }

  public stopAnimating() {
    this.isAnimating = false
  }

  private updateScrollingValue(startMs: number, target: number) {
    const animationDuration = target * ROW_SCROLLING_DURATION
    requestAnimationFrame(() => {
      if (!this.isAnimating) {
        return
      }
      const passedMs = Date.now() - startMs
      if (passedMs >= animationDuration) {
        this.ganttOrListStore.tableScrollToIndex = target
        this.isAnimating = false
        return
      }

      this.ganttOrListStore.tableScrollToIndex =
        (passedMs / animationDuration) * target
      this.updateScrollingValue(startMs, target)
    })
  }
}
