import * as React from 'react'

import { Icon, IconSize } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import TwoMonthsDatePickerStore, {
  TwoMonthsDatePickerMode,
  getTwoMonthsDatePickerModeDisplayName,
} from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import FilterHandleButton from '../../../../shared/components/Filters/components/FilterHandleButton/FilterHandleButton'
import DateSelectorStore, { IDateFilters } from './DateSelector.store'
import GeneralDatePicker from './GeneralDatePicker/GeneralDatePicker'

// localization: translated

export interface IDateSelectorProps {
  filters: IDateFilters
  projectsStore: ProjectsStore
  projectDateStore: ProjectDateStore
  mode: TwoMonthsDatePickerMode
  shouldRenderDateModes?: boolean
  datePickerModes?: TwoMonthsDatePickerMode[]
  onShowChanged?: (isShown: boolean) => void
  forceClose?: boolean
  shouldEnableFullProjectMode?: boolean
  shouldUseOtherDatesForFullProjectMode?: boolean
  shouldUseRelativePosition?: boolean
  shouldShowTodayButton?: boolean
  onOptionModeClick?: (mode: TwoMonthsDatePickerMode) => void
  shouldHideTopButtons?: boolean

  customDatePickerViewRenderer?: (
    store: DateSelectorStore,
    datePickerStore: TwoMonthsDatePickerStore,
  ) => JSX.Element
}

@observer
export default class DateSelector<
  T extends IDateSelectorProps,
> extends React.Component<T> {
  public static defaultProps = {
    shouldUseRelativePosition: true,
  }

  protected readonly store: DateSelectorStore
  protected readonly datePickerStore: TwoMonthsDatePickerStore

  public constructor(props: T) {
    super(props)

    this.datePickerStore = new TwoMonthsDatePickerStore(
      props.projectDateStore,
      props.onShowChanged,
    )
    this.store = new DateSelectorStore(
      this.datePickerStore,
      props.filters,
      props.projectsStore,
      props.projectDateStore,
      props.shouldUseOtherDatesForFullProjectMode,
    )
  }

  public componentDidMount() {
    this.store.setInitialMode(this.props.mode)
  }

  public componentDidUpdate(prevProps: IDateSelectorProps) {
    if (this.props.forceClose) {
      this.datePickerStore.isShown = false
    }

    if (this.props.mode !== prevProps.mode) {
      this.store.setInitialMode(this.props.mode)
    }
  }

  public render() {
    const {
      shouldEnableFullProjectMode,
      shouldUseOtherDatesForFullProjectMode,
      shouldRenderDateModes,
      shouldShowTodayButton,
      shouldUseRelativePosition,
      shouldHideTopButtons,
    } = this.props

    return (
      <div
        className={classList({
          'row calendar-control x-end': true,
          relative: shouldUseRelativePosition,
        })}
      >
        {shouldShowTodayButton && this.renderTodayButton()}
        {this.renderDatePickerView()}
        {shouldRenderDateModes && this.renderDatePickerStates()}
        <GeneralDatePicker
          datePickerStore={this.datePickerStore}
          shouldEnableFullProjectMode={shouldEnableFullProjectMode}
          shouldUseOtherDatesForFullProjectMode={
            shouldUseOtherDatesForFullProjectMode
          }
          shouldHideTopButtons={shouldHideTopButtons}
        />
      </div>
    )
  }

  protected renderDatePickerView() {
    if (this.props.customDatePickerViewRenderer) {
      return this.props.customDatePickerViewRenderer(
        this.store,
        this.datePickerStore,
      )
    }

    const { filters } = this.props
    const { isDateFilterActive } = filters
    const { isShown } = this.datePickerStore
    const {
      isCurrentActive,
      canChangeDates,
      datesButtonCaption,
      moveDatesBack,
      moveDatesForward,
      toggleDatePicker,
    } = this.store

    return (
      <>
        <div className="no-grow pl15 pr5">
          {canChangeDates && (
            <Icon
              className="gant-left-navigation pointer left-navigation"
              size={IconSize.LARGE}
              icon={IconNames.CARET_LEFT}
              onClick={moveDatesBack}
            />
          )}
        </div>

        <FilterHandleButton
          className={classList({
            'row no-grow no-flex-children date-handle-button text grey-light large':
              true,
            'zone-map-view-calendar default-cursor': !canChangeDates,
            'ba-palette-grey': !isCurrentActive,
            'current-lookahead ba-palette-secondary-orange secondary-orange':
              isCurrentActive,
          })}
          onClick={toggleDatePicker}
          isSelected={isShown}
          isActive={isDateFilterActive}
          hasIndent={false}
        >
          <span>{datesButtonCaption}</span>
        </FilterHandleButton>

        <div className="no-grow pl5 pr15">
          {canChangeDates && (
            <Icon
              className="pointer right-navigation"
              size={IconSize.LARGE}
              icon={IconNames.CARET_RIGHT}
              onClick={moveDatesForward}
            />
          )}
        </div>
      </>
    )
  }

  private renderTodayButton() {
    return (
      <div
        className="row x-center y-center btn-filter gant-header-btn no-flex gant-header-btn-outline ml15 mr5"
        onClick={this.store.setDateToToday}
      >
        <span>{Localization.translator.today}</span>
      </div>
    )
  }

  private renderDatePickerStates() {
    const { datePickerModes, shouldUseRelativePosition } = this.props
    const {
      shouldShowDatePickerOptions,
      datePickerStates,
      datePickerMode,
      toggleDatePickerModesList,
    } = this.store

    const icon = shouldShowDatePickerOptions
      ? IconNames.CARET_UP
      : IconNames.CARET_DOWN

    const datePickerOptions = datePickerModes || datePickerStates

    return (
      <div
        className={classList({
          'no-grow ml5': true,
          relative: shouldUseRelativePosition,
        })}
      >
        {shouldShowDatePickerOptions && (
          <div className="date-picker-options-holder mw120 absolute ba-palette-grey bg-white brada4">
            {datePickerOptions.map((mode, index) => {
              return (
                <span
                  key={index}
                  className={classList({
                    'pointer row x-center y-center text large bg-white option':
                      true,
                    active: datePickerMode === mode,
                  })}
                  onClick={this.onPickerModeClick.bind(this, mode)}
                >
                  {getTwoMonthsDatePickerModeDisplayName(mode)}
                </span>
              )
            })}
          </div>
        )}
        <div
          className={classList({
            'mw120 large pl10 text capitalize relative row x-center y-center':
              true,
            'gant-header-btn no-flex gant-header-btn-outline ba-palette-grey':
              true,
          })}
          onClick={toggleDatePickerModesList}
        >
          {getTwoMonthsDatePickerModeDisplayName(datePickerMode)}
          <Icon icon={icon} iconSize={16} className="mx5" />
        </div>
      </div>
    )
  }

  private onPickerModeClick = (
    mode: TwoMonthsDatePickerMode,
    event: React.MouseEvent,
  ) => {
    if (event) {
      event.stopPropagation()
    }

    this.store.setDatePickerMode(mode)

    const { onOptionModeClick } = this.props

    if (onOptionModeClick) {
      onOptionModeClick(mode)
    }
  }
}
