import * as React from 'react'

import { observer } from 'mobx-react'

import PhotoContainer from '~/client/src/shared/components/CommonThread/components/PhotoContainer'
import * as Icons from '~/client/src/shared/components/Icons'
import PhotoThumbnail from '~/client/src/shared/components/PhotoThumbnail/PhotoThumbnail'
import Timestamp from '~/client/src/shared/components/Timestamp'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Photo from '~/client/src/shared/models/Photo'
import User from '~/client/src/shared/models/User'

import { IDeliveryThreadMessage } from '../../DeliveryLog/DeliveryLog'
import DeliveryStatusLabel from '../../DeliveryStatusLabel/DeliveryStatusLabel'
import UserProfilePreview from '../../UserProfilePreview/UserProfilePreview'
import AttachmentsContainer from './AttachmentsContainer'
import { IThreadEntryProps } from './CommonThreadEntry'

// localization: translated

interface IProps {
  user: User
  entryPhoto: Photo
  isActivityColumn?: boolean
}

@observer
export default class DeliveryColumn extends React.Component<
  IProps & IThreadEntryProps & IDeliveryThreadMessage
> {
  public render() {
    const {
      user,
      entryPhoto,
      isActivityColumn,

      FileInputType,
      isLastEntryInLog,
      isPhotoThread,
      isInfoHidden,
      attachments,
      customPhotoClick,
      actionBarStore,

      createdAt,
      status,
      text,
    } = this.props

    const shouldShowThumbnail =
      actionBarStore?.isImageUploading && isLastEntryInLog

    return (
      <div className="col pl16">
        <div className="row x-between no-flex-children-direct">
          <UserProfilePreview
            user={user}
            briefInfoClassName="medium"
            isBriefInfoHidden={isInfoHidden}
          />
          <Timestamp time={createdAt} />
        </div>
        <div className="pl40">
          {status && (
            <div className="row no-flex-children">
              <span className="text light bold lp05 mr10">
                {Localization.translator.statusUpdated}
              </span>
              <Icons.ArrowNext className="row mr10" />
              <DeliveryStatusLabel status={status} />
            </div>
          )}
          {isActivityColumn && (
            <div className="row no-flex-children">
              <span className="text light font-w-600 lp05 mr10">
                {Localization.translator.activityWasUpdated}
              </span>
            </div>
          )}
          <div className="row">
            <div
              className={
                'text large mb5 line-large' + (text ? '' : ' italic faded')
              }
            >
              {text || `[${Localization.translator.noMessage}]`}
            </div>
          </div>
          {shouldShowThumbnail ? (
            <PhotoThumbnail />
          ) : (
            <PhotoContainer
              customPhotoClick={customPhotoClick}
              photo={entryPhoto}
              isPhotoThread={isPhotoThread}
              caption=""
              FileInputType={FileInputType}
            />
          )}
          <AttachmentsContainer attachments={attachments} />
        </div>
      </div>
    )
  }
}
