import React from 'react'

import InlineSVG from 'svg-inline-react'

import CompanyIconSrc from '~/client/static/icons/company.svg'
import StatusDoneIconSrc from '~/client/static/icons/done.svg'
import StatusFailedIconSrc from '~/client/static/icons/fail.svg'
import FootballHelmetIconSrc from '~/client/static/icons/football_helmet.svg'
import InteriorDoorSrc from '~/client/static/icons/interior-door.svg'
import InteriorPathSrc from '~/client/static/icons/interior-path.svg'
import StatusOnSiteIconSrc from '~/client/static/icons/on-site.svg'
import StatusPassedIconSrc from '~/client/static/icons/pass.svg'
import StatusRequestedIconSrc from '~/client/static/icons/requested.svg'
import StatusScheduledIconSrc from '~/client/static/icons/scheduled.svg'
import MaturixStationSrc from '~/client/static/icons/sensor.svg'
import StagingSrc from '~/client/static/icons/staging.svg'
import AreaSrc from '~/client/static/icons/tag-icons/area.svg'
import BathroomSrc from '~/client/static/icons/tag-icons/bathroom.svg'
import BreakSrc from '~/client/static/icons/tag-icons/break.svg'
import BuildingIconSrc from '~/client/static/icons/tag-icons/building.svg'
import CanteenSrc from '~/client/static/icons/tag-icons/canteen.svg'
import CircleIconSrc from '~/client/static/icons/tag-icons/circle.svg'
import CraneSrc from '~/client/static/icons/tag-icons/crane.svg'
import DefaultTeamSrc from '~/client/static/icons/tag-icons/default-team.svg'
import DumpsterSrc from '~/client/static/icons/tag-icons/dumpster.svg'
import ElectricalRoomSrc from '~/client/static/icons/tag-icons/electrical-room.svg'
import ElevatorSrc from '~/client/static/icons/tag-icons/elevator.svg'
import GateIconSrc from '~/client/static/icons/tag-icons/entry-gate.svg'
import EntranceSrc from '~/client/static/icons/tag-icons/entry.svg'
import EquipmentIconSrc from '~/client/static/icons/tag-icons/equipment.svg'
import ForkLiftSrc from '~/client/static/icons/tag-icons/fork lift.svg'
import GradallSrc from '~/client/static/icons/tag-icons/gradall.svg'
import HandWashSrc from '~/client/static/icons/tag-icons/hand-wash.svg'
import HoistSrc from '~/client/static/icons/tag-icons/hoist.svg'
import LevelSrc from '~/client/static/icons/tag-icons/level.svg'
import LineIconSrc from '~/client/static/icons/tag-icons/line.svg'
import LogisticsObjectSrc from '~/client/static/icons/tag-icons/location.svg'
import MedicalSrc from '~/client/static/icons/tag-icons/medical.svg'
import MeetingPointSrc from '~/client/static/icons/tag-icons/meeting-point.svg'
import ZoneIconSrc from '~/client/static/icons/tag-icons/offloading-zone.svg'
import ParkingSrc from '~/client/static/icons/tag-icons/parking.svg'
import RouteIconSrc from '~/client/static/icons/tag-icons/path.svg'
import PolygonIconSrc from '~/client/static/icons/tag-icons/polygon.svg'
import RectangleIconSrc from '~/client/static/icons/tag-icons/rectangle.svg'
import AerialLiftSrc from '~/client/static/icons/tag-icons/scissor lift.svg'
import ShaftSrc from '~/client/static/icons/tag-icons/shaft.svg'
import SmokingSrc from '~/client/static/icons/tag-icons/smoking.svg'
import StairsSrc from '~/client/static/icons/tag-icons/stairs.svg'
import TemperatureSrc from '~/client/static/icons/tag-icons/temperature.svg'
import TentSrc from '~/client/static/icons/tag-icons/tent.svg'
import TextIconSrc from '~/client/static/icons/tag-icons/text.svg'
import ToiletSrc from '~/client/static/icons/tag-icons/toilet.svg'
import WalkwaySrc from '~/client/static/icons/tag-icons/walkway.svg'
import TradeSrc from '~/client/static/icons/trade.svg'
import TrailerIconSrc from '~/client/static/icons/trailer.svg'
import UserIconSrc from '~/client/static/icons/user.svg'
import WrenchIconSrc from '~/client/static/icons/wrench.svg'

interface IProps {
  color?: string
  size?: number

  [key: string]: any
}

const DEFAULT_SIZE = 12
const DEFAULT_COLOR = 'currentColor'

export function ForkLift(props: IProps) {
  return renderInlineSvg(ForkLiftSrc, {
    ...props,
    stroke: props.color || DEFAULT_COLOR,
    className: (props.className || '') + ' stroke-icon',
  })
}

export function Hoist(props: IProps) {
  return renderInlineSvg(HoistSrc, {
    ...props,
    stroke: props.color || DEFAULT_COLOR,
    className: (props.className || '') + ' stroke-icon',
  })
}

export function AerialLift(props: IProps) {
  return renderInlineSvg(AerialLiftSrc, props)
}

export function Gradall(props: IProps) {
  return renderInlineSvg(GradallSrc, {
    ...props,
    stroke: props.color || DEFAULT_COLOR,
    className: (props.className || '') + ' stroke-icon',
  })
}

export function Toilet(props: IProps) {
  return renderInlineSvg(ToiletSrc, props)
}

export function Canteen(props: IProps) {
  return renderInlineSvg(CanteenSrc, props)
}

export function Building(props: IProps) {
  return renderInlineSvg(BuildingIconSrc, props)
}

export function Level(props: IProps) {
  return renderInlineSvg(LevelSrc, props)
}

export function Area(props: IProps) {
  return renderInlineSvg(AreaSrc, props)
}

export function Bathroom(props: IProps) {
  return renderInlineSvg(BathroomSrc, props)
}

export function Break(props: IProps) {
  return renderInlineSvg(BreakSrc, props)
}

export function Dumpster(props: IProps) {
  return renderInlineSvg(DumpsterSrc, props)
}

export function Elevator(props: IProps) {
  return renderInlineSvg(ElevatorSrc, props)
}

export function Entrance(props: IProps) {
  return renderInlineSvg(EntranceSrc, props)
}

export function HandWash(props: IProps) {
  return renderInlineSvg(HandWashSrc, props)
}

export function Medical(props: IProps) {
  return renderInlineSvg(MedicalSrc, props)
}

export function MeetingPoint(props: IProps) {
  return renderInlineSvg(MeetingPointSrc, props)
}

export function Parking(props: IProps) {
  return renderInlineSvg(ParkingSrc, props)
}

export function Smoking(props: IProps) {
  return renderInlineSvg(SmokingSrc, props)
}

export function Stairs(props: IProps) {
  return renderInlineSvg(StairsSrc, props)
}

export function Shaft(props: IProps) {
  return renderInlineSvg(ShaftSrc, props)
}

export function Temperature(props: IProps) {
  return renderInlineSvg(TemperatureSrc, props)
}

export function Tent(props: IProps) {
  return renderInlineSvg(TentSrc, props)
}

export function Walkway(props: IProps) {
  return renderInlineSvg(WalkwaySrc, props)
}

export function Gate(props: IProps) {
  return renderInlineSvg(GateIconSrc, {
    ...props,
    stroke: props.color || DEFAULT_COLOR,
    className: (props.className || '') + ' stroke-icon',
  })
}

export function Route(props: IProps) {
  return renderInlineSvg(RouteIconSrc, props)
}

export function Equipment(props: IProps) {
  return renderInlineSvg(EquipmentIconSrc, props)
}

export function Zone(props: IProps) {
  return renderInlineSvg(ZoneIconSrc, props)
}

export function LogisticsObject(props: IProps) {
  return renderInlineSvg(LogisticsObjectSrc, props)
}

export function Polygon(props: IProps) {
  return renderInlineSvg(PolygonIconSrc, {
    ...props,
    stroke: props.color || DEFAULT_COLOR,
    className: (props.className || '') + ' stroke-icon',
  })
}

export function Circle(props: IProps) {
  return renderInlineSvg(CircleIconSrc, props)
}

export function Rectangle(props: IProps) {
  return renderInlineSvg(RectangleIconSrc, props)
}

export function Crane(props: IProps) {
  return renderInlineSvg(CraneSrc, props)
}

export function Line(props: IProps) {
  return renderInlineSvg(LineIconSrc, props)
}

export function Text(props: IProps) {
  return renderInlineSvg(TextIconSrc, props)
}

export function StatusScheduled(props: IProps) {
  return renderInlineSvg(StatusScheduledIconSrc, props)
}

export function StatusOnSite(props: IProps) {
  return renderInlineSvg(StatusOnSiteIconSrc, props)
}

export function StatusRequested(props: IProps) {
  return renderInlineSvg(StatusRequestedIconSrc, props)
}

export function StatusPassed(props: IProps) {
  return renderInlineSvg(StatusPassedIconSrc, props)
}

export function StatusFailed(props: IProps) {
  return renderInlineSvg(StatusFailedIconSrc, props)
}

export function StatusDone(props: IProps) {
  return renderInlineSvg(StatusDoneIconSrc, props)
}

export function Company(props: IProps) {
  return renderInlineSvg(CompanyIconSrc, props)
}

export function Team(props: IProps) {
  return renderInlineSvg(FootballHelmetIconSrc, props)
}

export function Trade(props: IProps) {
  return renderInlineSvg(TradeSrc, props)
}

export function Role(props: IProps) {
  return renderInlineSvg(WrenchIconSrc, props)
}

export function User(props: IProps) {
  return renderInlineSvg(UserIconSrc, props)
}

export function ElectricalRoom(props: IProps) {
  return renderInlineSvg(ElectricalRoomSrc, props)
}

export function DefaultTeam(props: IProps) {
  return renderInlineSvg(DefaultTeamSrc, props)
}

export function MaturixStation(props: IProps) {
  return renderInlineSvg(MaturixStationSrc, props)
}

export function Staging(props: IProps) {
  return renderInlineSvg(StagingSrc, props)
}

export function InteriorDoor(props: IProps) {
  return renderInlineSvg(InteriorDoorSrc, props)
}

export function InteriorPath(props: IProps) {
  return renderInlineSvg(InteriorPathSrc, props)
}

export function Trailer(props) {
  return renderInlineSvg(TrailerIconSrc, props)
}

function renderInlineSvg(src: any, props: IProps): JSX.Element {
  const { color = DEFAULT_COLOR, size = DEFAULT_SIZE, stroke, ...rest } = props

  return (
    <InlineSVG
      {...rest}
      src={src}
      style={{
        color,
        stroke,
        fill: color,
        width: size,
        height: size,
        display: 'flex',
        flexBasis: size,
      }}
    />
  )
}
