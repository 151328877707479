import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import DeliveryGroupingOption from '~/client/src/shared/enums/DeliveryGroupingOption'
import { DeliveryLocationType } from '~/client/src/shared/types/IHierarchyParent'

const deliveryGroupingKeyToLocationTypeMap = {
  [DeliveryGroupingOption[FieldIds.ZONE]]: DeliveryLocationType.Zone,
  [DeliveryGroupingOption[FieldIds.GATE]]: DeliveryLocationType.Gate,
  [DeliveryGroupingOption[FieldIds.BUILDING]]: DeliveryLocationType.Building,
  [DeliveryGroupingOption[FieldIds.ROUTE]]: DeliveryLocationType.Route,
  [DeliveryGroupingOption[FieldIds.OFFLOADING_EQUIPMENT]]:
    DeliveryLocationType.Equipment,
  [DeliveryGroupingOption[FieldIds.LEVEL]]: DeliveryLocationType.Level,
}

export default deliveryGroupingKeyToLocationTypeMap
