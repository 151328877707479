import React from 'react'

import ThreadIndicators from '~/client/src/shared/components/ThreadIndicators/ThreadIndicators'
import { IActivitiesTreeNode } from '~/client/src/shared/models/IActivitiesTreeNode'

// localization: no display text to translate

interface IDesktopLocationContainerNodeProps {
  node: IActivitiesTreeNode
}

export default class DesktopLocationContainerNode extends React.Component<IDesktopLocationContainerNodeProps> {
  public render() {
    const {
      name,
      state: { expanded: isExpanded },
      totalEntitiesAmount,
      updatedActivitiesAmount,
      updatedActivitiesAmountWithManpower,
      ...rest
    } = this.props.node

    return (
      <>
        <div className="text extra large white bold">{name}</div>
        <ThreadIndicators
          totalAmount={totalEntitiesAmount}
          actualAmount={updatedActivitiesAmount}
          onlyStatusUpdate={isExpanded}
          updatedActivitiesAmountWithManpower={
            updatedActivitiesAmountWithManpower
          }
          {...rest}
        />
      </>
    )
  }
}
