import { action, computed, observable, when } from 'mobx'

import {
  AnnouncementRelationType,
  IAnnouncementAssignmentInput,
  IAnnouncementOrder,
  IAnnouncementWithAssignmentInput,
  IAttachment,
  ISiteLocation,
} from '~/client/graph'

import Announcement from '../../models/Announcement'
import Assignment from '../../models/Assignment/Assignment'
import User from '../../models/User'
import EventsStore from '../../stores/EventStore/Events.store'
import * as e from '../../stores/EventStore/eventConstants'
import AnnouncementAssignmentsStore from '../../stores/domain/AnnouncementAssignments.store'
import AnnouncementsStore from '../../stores/domain/Announcements.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import { areObjectArraysEqual, copyObjectArray } from '../../utils/util'

const INITIAL_DATE_DIFF = 1
const EMPTY_STRING = ''

export default class AnnouncementEditionFormStore {
  @observable public isLocationPickerDisplayed: boolean = false
  @observable public editableAnnouncement: Announcement
  @observable public editableAnnouncementAssignment: Assignment
  @observable public isDatePickerDisplayed: boolean = false
  @observable public isOrderSetUpDisplayed: boolean = false
  @observable public isUserPickerDisplayed: boolean = false
  @observable public announcementOrderMap: {
    [key: string]: IAnnouncementOrder[]
  } = {}
  @observable public isEditMode: boolean = false

  private pristineAnnouncement

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly announcementsStore: AnnouncementsStore,
    private readonly announcementAssignmentsStore: AnnouncementAssignmentsStore,
    private readonly displayedAnnouncement?: Announcement,
  ) {
    this.pristineAnnouncement = this.displayedAnnouncement?.getDto()
    this.setDefaultAnnouncement(this.displayedAnnouncement)
    when(
      () => this.announcementsStore.isDataReceived,
      this.resetAnnouncementOrdersToDefault,
    )
  }

  @computed
  public get isAnnouncementValid() {
    const { startDate, endDate, location, title } = this.editableAnnouncement
    const { all, recipients } = this.editableAnnouncementAssignment

    return (
      location &&
      startDate &&
      endDate &&
      title &&
      (all || !!recipients?.some(r => r.ids?.length))
    )
  }

  @computed
  public get areAnnouncementsOrdersChanged(): boolean {
    return this.announcementsStore.list.some(
      n => !areObjectArraysEqual(n.orders, this.announcementOrderMap[n.id]),
    )
  }

  @computed
  public get appliedUsers(): User[] {
    return this.announcementAssignmentsStore.extractUsersFromAssignment(
      this.editableAnnouncementAssignment,
    )
  }

  @action.bound
  public toggleLocationPickerState() {
    this.isLocationPickerDisplayed = !this.isLocationPickerDisplayed
  }

  @action.bound
  public setDefaultAnnouncement(displayedAnnouncement?: Announcement) {
    this.isEditMode = !!displayedAnnouncement

    const { startOfDay, endOfDay, addDays } = this.projectDateStore

    const startDate = startOfDay(new Date()).getTime()
    const endDate = endOfDay(addDays(startDate, INITIAL_DATE_DIFF)).getTime()

    const orders = [
      {
        date: startDate,
        order: this.getAnnouncementsCountForDay(startDate) + 1,
      },
      {
        date: endDate,
        order: this.getAnnouncementsCountForDay(endDate) + 1,
      },
    ]

    this.editableAnnouncement =
      displayedAnnouncement ||
      new Announcement(
        this.projectDateStore,
        null,
        this.state.activeProject.id,
        startDate,
        endDate,
        orders,
        EMPTY_STRING,
        EMPTY_STRING,
        false,
      )

    const displayedAnnouncementAssignment =
      displayedAnnouncement &&
      this.announcementAssignmentsStore.getAssignmentByEntityId(
        displayedAnnouncement.id,
      )

    this.editableAnnouncementAssignment =
      displayedAnnouncementAssignment ||
      new Assignment(
        Assignment.draftId,
        null,
        this.state.activeProject.id,
        [],
        true,
      )
  }

  @action.bound
  public resetAnnouncementOrdersToDefault() {
    this.announcementOrderMap = {}
    this.announcementsStore.list.forEach(n => {
      this.announcementOrderMap[n.id] = copyObjectArray(n.orders)
    })
  }

  @action.bound
  public openDatePicker() {
    this.isDatePickerDisplayed = true
  }

  @action.bound
  public applyUsers(selectedUserIds: string[], isAllMode: boolean) {
    // According to discussion with Chris, only user selection makes sense within the current UI
    // To support others RecipientTypes we have to revisit UI
    this.editableAnnouncementAssignment =
      this.announcementAssignmentsStore.createAssignmentForUsers(
        this.editableAnnouncementAssignment.id,
        selectedUserIds,
        this.editableAnnouncement.id,
        isAllMode,
      )

    this.hideUserPicker()
  }

  @action.bound
  public openUserPicker = () => {
    this.isUserPickerDisplayed = true
  }

  @action.bound
  public hideUserPicker() {
    this.isUserPickerDisplayed = false
  }

  @action.bound
  public hideDatePicker() {
    this.isDatePickerDisplayed = false
  }

  @action.bound
  public openOrderSetUp() {
    this.isOrderSetUpDisplayed = true
  }

  @action.bound
  public hideOrderSetUp() {
    this.isOrderSetUpDisplayed = false
  }

  @action.bound
  public applyDates(startDate: Date, endDate: Date) {
    const { isSameDay, startOfDay, endOfDay, getDaysInInterval } =
      this.projectDateStore

    this.editableAnnouncement.startDate = startOfDay(startDate).getTime()
    this.editableAnnouncement.endDate = endOfDay(endDate).getTime()

    const rangeDates = getDaysInInterval(startDate, endDate)
    const newOrders = []
    const { orders } = this.editableAnnouncement
    rangeDates.forEach(date => {
      let order = orders.find(o => isSameDay(o.date, date))
      if (!order) {
        order = {
          date: startOfDay(date).getTime(),
          order: this.getAnnouncementsCountForDay(date) + 1,
        }
      }
      newOrders.push(order)
    })
    this.editableAnnouncement.orders = newOrders
  }

  @action.bound
  public applyLocation(location: ISiteLocation) {
    this.editableAnnouncement.location = location
  }

  @action.bound
  public applyContent(content: string) {
    this.editableAnnouncement.content = content
  }

  @action.bound
  public applyTitle(title: string) {
    this.editableAnnouncement.title = title
  }

  @action.bound
  public applyRelationship(
    type: AnnouncementRelationType,
    foreignKeyId: string,
  ) {
    this.editableAnnouncement.relationship = { type, foreignKeyId }
  }

  @action.bound
  public addAttachment(attachment: IAttachment) {
    this.editableAnnouncement.attachments.push(attachment)
  }

  @action.bound
  public removeAttachment(attachment: IAttachment) {
    const { attachments } = this.editableAnnouncement
    const idx = attachments.indexOf(attachment)
    if (idx > -1) {
      attachments.splice(idx, 1)
    }
  }

  @action.bound
  public submitForm() {
    const dtos: IAnnouncementWithAssignmentInput[] = [
      {
        announcement: this.editableAnnouncement.getDto(),
        assignment:
          this.editableAnnouncementAssignment.getDto() as IAnnouncementAssignmentInput,
      },
    ]

    this.announcementsStore.list.forEach(n => {
      const updatedOrders = this.announcementOrderMap[n.id]

      if (!areObjectArraysEqual(n.orders, updatedOrders)) {
        const isEditableAnnouncement = n.id === this.editableAnnouncement.id
        const announcementDto = isEditableAnnouncement
          ? dtos[0].announcement
          : n.getDto()

        announcementDto.orders = updatedOrders

        if (!isEditableAnnouncement) {
          dtos.push({ announcement: announcementDto })
        }
      }
    })

    this.eventsStore.dispatch(e.SAVE_ANNOUNCEMENTS, dtos)
  }

  @action.bound
  public resetCurrentChanges() {
    if (!this.pristineAnnouncement) {
      return
    }

    this.announcementsStore.receiveOne(
      this.pristineAnnouncement.id,
      this.pristineAnnouncement,
    )
  }

  public getAnnouncementsCountForDay = (date: Date | number) => {
    return this.getExistingAnnouncementsForDay(date).length
  }

  public getExistingAnnouncementsForDay = (date: Date | number) => {
    return this.announcementsStore.getAnnouncementsForDay(date)
  }

  public getExistingAndEditableAnnouncementsForDay = (date: Date | number) => {
    const { isWithinDateInterval, isSameDay } = this.projectDateStore

    const dateAnnouncements = this.getExistingAnnouncementsForDay(date).filter(
      n => n.id !== this.editableAnnouncement.id,
    )

    if (
      isWithinDateInterval(
        this.editableAnnouncement.startDate,
        this.editableAnnouncement.endDate,
        date,
      )
    ) {
      dateAnnouncements.push(this.editableAnnouncement)
    }

    return dateAnnouncements.sort((a, b) => {
      const aOrders = this.announcementOrderMap[a.id] || a.orders
      const bOrders = this.announcementOrderMap[b.id] || b.orders
      const selectedDateAOrder = aOrders.find(o => isSameDay(o.date, date))
      const selectedDateBOrder = bOrders.find(o => isSameDay(o.date, date))

      const aOrder = selectedDateAOrder
        ? selectedDateAOrder.order
        : dateAnnouncements.length
      const bOrder = selectedDateBOrder
        ? selectedDateBOrder.order
        : dateAnnouncements.length

      return aOrder - bOrder
    })
  }

  private get state() {
    return this.eventsStore.appState
  }
}
