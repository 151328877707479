import * as React from 'react'

import { Icon, Radio } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'

import { FilterType } from '~/client/graph'

const { CARET_UP, CARET_DOWN, TRASH } = IconNames

interface IBandConfigurationRow {
  index: number
  bandType: FilterType
  onChange: (index: number, isUpward: boolean) => void
  onRemove: (index: number) => void
  typeToCaptionMap: any
}

@observer
export default class BandConfigurationRow extends React.Component<IBandConfigurationRow> {
  public render() {
    const { bandType, index, onChange, onRemove, typeToCaptionMap } = this.props

    return (
      <div className="row bb-light-grey theme-radio-group configurator-row">
        <div className="text large configurator-row-handle index">
          {index + 1}
        </div>
        <div className="col configurator-row-handle change-order pr15">
          <Icon
            icon={CARET_UP}
            className="pointer"
            onClick={onChange.bind(this, index, true)}
          />
          <Icon
            icon={CARET_DOWN}
            className="pointer"
            onClick={onChange.bind(this, index, false)}
          />
        </div>
        <div className="text large center capitalize configurator-row-band-name">
          {typeToCaptionMap[bandType]}
        </div>
        {/* TODO: provide functionality to these radio controls */}
        <div className="text large center">
          <Radio disabled={true} />
        </div>
        <div className="text large center">
          <Radio disabled={true} />
        </div>
        <div className="col configurator-row-handle deletion pl15">
          <Icon
            icon={TRASH}
            className="pointer"
            onClick={onRemove.bind(this, index)}
          />
        </div>
      </div>
    )
  }
}
