import * as React from 'react'

interface IProps {
  style?: any
}

interface IState {
  isTouched: boolean
}

export default class TouchableOpacity extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)
    this.state = {
      isTouched: false,
    }
  }

  public render() {
    const styles = Object.assign({}, this.props.style || {}, {
      opacity: this.state.isTouched ? 0.5 : 1,
    })

    return (
      <div
        onTouchStart={this.onTouchStart}
        onTouchEnd={this.onTouchEnd}
        style={styles}
      >
        {this.props.children}
      </div>
    )
  }

  private onTouchStart = () => {
    this.setState({ isTouched: true })
  }

  private onTouchEnd = () => {
    this.setState({ isTouched: false })
  }
}
