import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

import Localization from '../../localization/LocalizationManager'
import Delivery from '../../models/Delivery'
import IMessage from '../../models/Message/IMessage'
import InitialState from '../../stores/InitialState'
import CompaniesStore from '../../stores/domain/Companies.store'
import CommonStore from '../../stores/ui/Common.store'
import CommonThread from '../CommonThread/CommonThread'
import { DeliveryPageMode } from '../Deliveries/DeliveriesView.store'
import DeliveryDetailsStore from '../DeliveryDetails/DeliveryDetails.store'
import DeliverySummary from '../DeliverySummary/DeliverySummary'
import FileInputBase from '../FileInput/FileInput'

interface IProps {
  delivery: Delivery
  FileInputType: typeof FileInputBase

  state?: InitialState
  common?: CommonStore
  companiesStore?: CompaniesStore
  deliveryDetailsStore?: DeliveryDetailsStore
}

@inject('state', 'companiesStore', 'deliveryDetailsStore', 'common')
@observer
export default class DeliveryEntry extends React.Component<IProps> {
  public render() {
    const { delivery, companiesStore, FileInputType } = this.props

    return (
      <CommonThread
        model={delivery}
        showImages={true}
        hideThread={false}
        showFooterLine={true}
        messages={[this.message]}
        buttonsElement={this.renderButtons()}
        threadIcon={<Icons.Delivery />}
        threadClass="photo-thread"
        caption={`Delivery: ${delivery.codeToDisplay(companiesStore)}`}
        customPhotoClick={this.displayDeliveryView}
        FileInputType={FileInputType}
      />
    )
  }

  private get message(): IMessage {
    const { delivery, FileInputType } = this.props

    return {
      id: delivery.id,
      userId: delivery.userId,
      createdAt: delivery.createdAt,
      text: (
        <DeliverySummary delivery={delivery} FileInputType={FileInputType} />
      ),
    }
  }

  @action.bound
  private displayDeliveryView = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()

    const { delivery, deliveryDetailsStore, common } = this.props
    deliveryDetailsStore.setViewMode(delivery)
    common.displayDeliveryDetailsView(delivery.id)
  }

  private renderButtons(): JSX.Element {
    const { userActiveProjectSettings } = this.props.state

    return (
      <div className="row item-buttons-container">
        <button
          className={classList({
            'row x-center item-button reply-btn': true,
            'inactive-element': userActiveProjectSettings?.isPresentationUser,
          })}
          onClick={this.displayDeliveryView}
        >
          {Localization.translator.reply}
        </button>
        <button
          className="row x-center item-button view-btn"
          onClick={this.displayCalendarView}
        >
          {Localization.translator.view_verb}
          <Icons.CalendarGrey className="row" />
        </button>
      </div>
    )
  }

  @action.bound
  private displayCalendarView = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    const { delivery, common, state } = this.props

    state.delivery.compactView.highlightedDelivery = null
    state.delivery.compactView.mode = DeliveryPageMode.VIEW_FROM_ACTIVITY
    state.delivery.compactView.activeDate = new Date(delivery.startDate)
    state.delivery.compactView.resetViewMode()
    common.openDeliveryView()
  }
}
