import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ICdOrderFieldsFragment = Pick<
  Types.IConcreteDirectOrder,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'projectId'
  | 'concreteDirectId'
  | 'deliveryId'
  | 'startDate'
  | 'localStartDate'
  | 'externalId'
  | 'timeZone'
  | 'orderReference'
  | 'orderByPhone'
  | 'orderByName'
  | 'accountingReference'
  | 'status'
  | 'progressPercent'
  | 'jobsiteId'
  | 'timeWindow'
  | 'sentToExternalBackend'
  | 'systemId'
  | 'costEstimationCalculated'
  | 'pricingPlantId'
  | 'printBatchWeights'
  | 'isPaperless'
  | 'isReviewNeeded'
  | 'isReservation'
  | 'deliveryInstructions'
  | 'isManualDispatchAllowed'
  | 'isWaitingForDispatchSystem'
> & {
  location?: Types.Maybe<
    Pick<Types.IGeoJson2DGeographicCoordinates, 'latitude' | 'longitude'>
  >
  address?: Types.Maybe<
    Pick<
      Types.IConcreteDirectAddress,
      'line' | 'city' | 'state' | 'zipCode' | 'country'
    >
  >
  orderItems?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<
          Types.IConcreteDirectOrderItem,
          | 'id'
          | 'externalId'
          | 'quantity'
          | 'isExactQuantity'
          | 'start'
          | 'localStart'
          | 'spacingInMinutes'
          | 'qtyPerHour'
          | 'rateType'
          | 'description'
          | 'pourMethod'
          | 'pourElement'
          | 'extendedPourElement'
          | 'slump'
          | 'payloadSize'
          | 'plantId'
          | 'jobsiteDistanceInMeters'
          | 'travelTimeInMinutes'
          | 'progressPercent'
          | 'itemId'
          | 'deliveredQuantity'
          | 'pouredQuantity'
          | 'holdQty'
          | 'currentTicketedQuantity'
          | 'currentBatchedQuantity'
          | 'actualSpacingInMinutes'
          | 'actualQtyPerHour'
        > & {
          item?: Types.Maybe<
            Pick<
              Types.IConcreteDirectItem,
              | 'id'
              | 'description'
              | 'status'
              | 'type'
              | 'unityOfMeasure'
              | 'externalId'
              | 'systemId'
              | 'defaultSlump'
            >
          >
          orderItemAdditions?: Types.Maybe<
            Array<
              Types.Maybe<
                Pick<
                  Types.IConcreteDirectOrderItemAddition,
                  'id' | 'externalId' | 'quantity'
                > & {
                  item?: Types.Maybe<
                    Pick<
                      Types.IConcreteDirectItem,
                      | 'id'
                      | 'description'
                      | 'status'
                      | 'type'
                      | 'unityOfMeasure'
                      | 'externalId'
                    >
                  >
                }
              >
            >
          >
        }
      >
    >
  >
  orderCostEstimations?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<
          Types.IConcreteDirectOrderCostEstimation,
          | 'id'
          | 'plantId'
          | 'travelTimeInMinutes'
          | 'calculatedDriveCost'
          | 'calculatedDriveCostCurrency'
        >
      >
    >
  >
}

export type IGetConcreteDirectOrderByIdQueryVariables = Types.Exact<{
  cdId: Types.Scalars['Guid']
}>

export type IGetConcreteDirectOrderByIdQuery = {
  concreteDirectOrder?: Types.Maybe<ICdOrderFieldsFragment>
}

export type IListenCdOrderByIdSubscriptionVariables = Types.Exact<{
  id: Types.Scalars['ObjectId']
}>

export type IListenCdOrderByIdSubscription = {
  concreteDirectOrder?: Types.Maybe<
    Pick<Types.IConcreteDirectOrderChangeEvent, 'id'> & {
      item?: Types.Maybe<ICdOrderFieldsFragment>
    }
  >
}

export const CdOrderFieldsFragmentDoc = gql`
  fragment CdOrderFields on ConcreteDirectOrder {
    id
    createdAt
    updatedAt
    projectId
    concreteDirectId
    deliveryId
    startDate
    localStartDate
    externalId
    timeZone
    location {
      latitude
      longitude
    }
    address {
      line
      city
      state
      zipCode
      country
    }
    orderItems {
      id
      externalId
      quantity
      isExactQuantity
      start
      localStart
      spacingInMinutes
      qtyPerHour
      rateType
      description
      pourMethod
      pourElement
      extendedPourElement
      slump
      payloadSize
      plantId
      jobsiteDistanceInMeters
      travelTimeInMinutes
      progressPercent
      itemId
      item {
        id
        description
        status
        type
        unityOfMeasure
        externalId
        systemId
        defaultSlump
      }
      deliveredQuantity
      pouredQuantity
      holdQty
      currentTicketedQuantity
      currentBatchedQuantity
      actualSpacingInMinutes
      actualQtyPerHour
      orderItemAdditions {
        id
        externalId
        quantity
        item {
          id
          description
          status
          type
          unityOfMeasure
          externalId
        }
      }
    }
    orderReference
    orderByPhone
    orderByName
    accountingReference
    status
    progressPercent
    jobsiteId
    timeWindow
    sentToExternalBackend
    systemId
    orderCostEstimations {
      id
      plantId
      travelTimeInMinutes
      calculatedDriveCost
      calculatedDriveCostCurrency
    }
    costEstimationCalculated
    pricingPlantId
    printBatchWeights
    isPaperless
    isReviewNeeded
    isReservation
    deliveryInstructions
    isManualDispatchAllowed
    isWaitingForDispatchSystem
  }
`
export const GetConcreteDirectOrderByIdDocument = gql`
  query GetConcreteDirectOrderById($cdId: Guid!) {
    concreteDirectOrder(cdId: $cdId) {
      ...CdOrderFields
    }
  }
  ${CdOrderFieldsFragmentDoc}
`

/**
 * __useGetConcreteDirectOrderByIdQuery__
 *
 * To run a query within a React component, call `useGetConcreteDirectOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConcreteDirectOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConcreteDirectOrderByIdQuery({
 *   variables: {
 *      cdId: // value for 'cdId'
 *   },
 * });
 */
export function useGetConcreteDirectOrderByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetConcreteDirectOrderByIdQuery,
    IGetConcreteDirectOrderByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetConcreteDirectOrderByIdQuery,
    IGetConcreteDirectOrderByIdQueryVariables
  >(GetConcreteDirectOrderByIdDocument, options)
}
export function useGetConcreteDirectOrderByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetConcreteDirectOrderByIdQuery,
    IGetConcreteDirectOrderByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetConcreteDirectOrderByIdQuery,
    IGetConcreteDirectOrderByIdQueryVariables
  >(GetConcreteDirectOrderByIdDocument, options)
}
export type GetConcreteDirectOrderByIdQueryHookResult = ReturnType<
  typeof useGetConcreteDirectOrderByIdQuery
>
export type GetConcreteDirectOrderByIdLazyQueryHookResult = ReturnType<
  typeof useGetConcreteDirectOrderByIdLazyQuery
>
export type GetConcreteDirectOrderByIdQueryResult = Apollo.QueryResult<
  IGetConcreteDirectOrderByIdQuery,
  IGetConcreteDirectOrderByIdQueryVariables
>
export const ListenCdOrderByIdDocument = gql`
  subscription ListenCdOrderById($id: ObjectId!) {
    concreteDirectOrder(id: $id) {
      id
      item {
        ...CdOrderFields
      }
    }
  }
  ${CdOrderFieldsFragmentDoc}
`

/**
 * __useListenCdOrderByIdSubscription__
 *
 * To run a query within a React component, call `useListenCdOrderByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenCdOrderByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenCdOrderByIdSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListenCdOrderByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenCdOrderByIdSubscription,
    IListenCdOrderByIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenCdOrderByIdSubscription,
    IListenCdOrderByIdSubscriptionVariables
  >(ListenCdOrderByIdDocument, options)
}
export type ListenCdOrderByIdSubscriptionHookResult = ReturnType<
  typeof useListenCdOrderByIdSubscription
>
export type ListenCdOrderByIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenCdOrderByIdSubscription>
