import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ITeamFieldsFragment = Pick<
  Types.ITeam,
  'id' | 'createdAt' | 'updatedAt' | 'projectId' | 'name' | 'color'
>

export type ITeamsByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type ITeamsByProjectIdQuery = {
  teams?: Types.Maybe<{ data: Array<ITeamFieldsFragment> }>
}

export type IListenTeamsByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenTeamsByProjectIdSubscription = {
  team?: Types.Maybe<
    Pick<Types.ITeamChangeEvent, 'id'> & {
      item?: Types.Maybe<ITeamFieldsFragment>
    }
  >
}

export type ISaveTeamMutationVariables = Types.Exact<{
  team: Types.ITeamInput
}>

export type ISaveTeamMutation = {
  saveTeam?: Types.Maybe<Pick<Types.ITeam, 'id'>>
}

export type IDeleteTeamMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ObjectId']
}>

export type IDeleteTeamMutation = Pick<Types.IMutation, 'deleteTeam'>

export const TeamFieldsFragmentDoc = gql`
  fragment TeamFields on Team {
    id
    createdAt
    updatedAt
    projectId
    name
    color
  }
`
export const TeamsByProjectIdDocument = gql`
  query TeamsByProjectId($projectId: ObjectId!) {
    teams(projectId: $projectId, limit: 1000000) {
      data {
        ...TeamFields
      }
    }
  }
  ${TeamFieldsFragmentDoc}
`

/**
 * __useTeamsByProjectIdQuery__
 *
 * To run a query within a React component, call `useTeamsByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useTeamsByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ITeamsByProjectIdQuery,
    ITeamsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ITeamsByProjectIdQuery,
    ITeamsByProjectIdQueryVariables
  >(TeamsByProjectIdDocument, options)
}
export function useTeamsByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ITeamsByProjectIdQuery,
    ITeamsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ITeamsByProjectIdQuery,
    ITeamsByProjectIdQueryVariables
  >(TeamsByProjectIdDocument, options)
}
export type TeamsByProjectIdQueryHookResult = ReturnType<
  typeof useTeamsByProjectIdQuery
>
export type TeamsByProjectIdLazyQueryHookResult = ReturnType<
  typeof useTeamsByProjectIdLazyQuery
>
export type TeamsByProjectIdQueryResult = Apollo.QueryResult<
  ITeamsByProjectIdQuery,
  ITeamsByProjectIdQueryVariables
>
export const ListenTeamsByProjectIdDocument = gql`
  subscription ListenTeamsByProjectId($projectId: ObjectId!) {
    team(projectId: $projectId) {
      id
      item {
        ...TeamFields
      }
    }
  }
  ${TeamFieldsFragmentDoc}
`

/**
 * __useListenTeamsByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenTeamsByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenTeamsByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenTeamsByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenTeamsByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenTeamsByProjectIdSubscription,
    IListenTeamsByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenTeamsByProjectIdSubscription,
    IListenTeamsByProjectIdSubscriptionVariables
  >(ListenTeamsByProjectIdDocument, options)
}
export type ListenTeamsByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenTeamsByProjectIdSubscription
>
export type ListenTeamsByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenTeamsByProjectIdSubscription>
export const SaveTeamDocument = gql`
  mutation SaveTeam($team: TeamInput!) {
    saveTeam(team: $team) {
      id
    }
  }
`
export type ISaveTeamMutationFn = Apollo.MutationFunction<
  ISaveTeamMutation,
  ISaveTeamMutationVariables
>

/**
 * __useSaveTeamMutation__
 *
 * To run a mutation, you first call `useSaveTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTeamMutation, { data, loading, error }] = useSaveTeamMutation({
 *   variables: {
 *      team: // value for 'team'
 *   },
 * });
 */
export function useSaveTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveTeamMutation,
    ISaveTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISaveTeamMutation, ISaveTeamMutationVariables>(
    SaveTeamDocument,
    options,
  )
}
export type SaveTeamMutationHookResult = ReturnType<typeof useSaveTeamMutation>
export type SaveTeamMutationResult = Apollo.MutationResult<ISaveTeamMutation>
export type SaveTeamMutationOptions = Apollo.BaseMutationOptions<
  ISaveTeamMutation,
  ISaveTeamMutationVariables
>
export const DeleteTeamDocument = gql`
  mutation DeleteTeam($teamId: ObjectId!) {
    deleteTeam(id: $teamId)
  }
`
export type IDeleteTeamMutationFn = Apollo.MutationFunction<
  IDeleteTeamMutation,
  IDeleteTeamMutationVariables
>

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDeleteTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteTeamMutation,
    IDeleteTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IDeleteTeamMutation, IDeleteTeamMutationVariables>(
    DeleteTeamDocument,
    options,
  )
}
export type DeleteTeamMutationHookResult = ReturnType<
  typeof useDeleteTeamMutation
>
export type DeleteTeamMutationResult =
  Apollo.MutationResult<IDeleteTeamMutation>
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<
  IDeleteTeamMutation,
  IDeleteTeamMutationVariables
>
