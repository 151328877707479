import * as React from 'react'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import './NewDeliveryActionButton.scss'

// localization: translated

interface IProps {
  isActive: boolean
  onClick: () => void
  className?: string
}

export default class NewDeliveryActionButton extends React.PureComponent<IProps> {
  public render() {
    return (
      <BaseActionButton
        id="new-delivery-action"
        {...this.props}
        isEnabled={true}
        title={Localization.translator.newDelivery}
        icon={<Icons.Truck />}
      />
    )
  }
}
