import * as React from 'react'

import { Button, Classes, Dialog } from '@blueprintjs/core'
import { observer } from 'mobx-react'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import FormFieldWrapper from '~/client/src/shared/components/FormFieldWrapper/FormFieldWrapper'
import { Loader } from '~/client/src/shared/components/Loader'
import AvatarType from '~/client/src/shared/enums/AvatarType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import AddEditItemDialogStore, {
  IAddEditDialogField,
} from '~/client/src/shared/stores/ui/AddEditItemDialog.store'

import './BaseAddEditDialogWrapper.scss'

// localization: translated

interface IProps {
  isLoading: boolean
  errorInfo: string
  store: AddEditItemDialogStore<any, any>
  customFooterRenderer?: () => JSX.Element
}

@observer
export default class BaseAddEditDialogWrapper extends React.Component<IProps> {
  public static renderFields(
    fields: IAddEditDialogField[],
    avatarType: AvatarType,
  ): JSX.Element[] {
    return fields.map(field => (
      <FormFieldWrapper key={field.id} field={field} avatarType={avatarType} />
    ))
  }

  public static renderErrorInfo(errorInfo: string): JSX.Element {
    return (
      errorInfo && (
        <div className="text center large red bold">* {errorInfo}</div>
      )
    )
  }

  public render() {
    const { children, store } = this.props

    const {
      preSubmit,
      submit,
      isMultiMode,
      handleCloseConfirmModal,
      handlePreClose,
      dialogCaption,
      okActionCaption,
      canFormsBeSubmitted,
      shouldShowConfirmModal,
      confirmationMessage,
      confirmationDoneBtnTitle,
    } = store

    return (
      <Dialog
        isOpen={true}
        onClose={handlePreClose}
        className="base-add-edit-wrapper relative brada4"
      >
        {this.renderLoader()}
        <ConfirmDialog
          doneTitle={confirmationDoneBtnTitle}
          cancelTitle={Localization.translator.no}
          isOpen={shouldShowConfirmModal}
          onCancelClicked={handleCloseConfirmModal}
          onDoneClicked={submit}
        >
          {confirmationMessage}
        </ConfirmDialog>
        <div className={`${Classes.DIALOG_HEADER} row px24 py12`}>
          <button
            aria-label={Localization.translator.close}
            onClick={handlePreClose}
            className="bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross no-grow"
          />

          <div className="text small-header px12 no-white-space-wrap">
            {dialogCaption}
          </div>

          {isMultiMode && this.renderItemNavigation()}

          <BaseActionButton
            className="scale-blue-theme bold"
            isAnimated={true}
            title={okActionCaption}
            onClick={preSubmit}
            isEnabled={canFormsBeSubmitted}
          />
        </div>

        <div className={`${Classes.DIALOG_BODY} px24`}>{children}</div>

        <div className={`${Classes.DIALOG_FOOTER} px24 pt4`}>
          {this.renderFooter()}
        </div>
      </Dialog>
    )
  }

  public renderItemNavigation(): JSX.Element {
    const { goToPreviousItem, goToNextItem, navigationTitle } = this.props.store

    return (
      <div className="row x-center absolute navigation-wrapper">
        <div className="no-grow no-white-space-wrap">
          <Button
            icon="arrow-left"
            className="arrow-btn"
            onClick={goToPreviousItem}
          />
          <span className="px12">{navigationTitle}</span>
          <Button
            icon="arrow-right"
            className="arrow-btn"
            onClick={goToNextItem}
          />
        </div>
      </div>
    )
  }

  private renderLoader(): JSX.Element {
    return (
      this.props.isLoading && (
        <div className="full-height full-width absolute modal-overlay">
          <Loader />
        </div>
      )
    )
  }

  private renderFooter() {
    const { customFooterRenderer, errorInfo } = this.props
    return customFooterRenderer
      ? customFooterRenderer()
      : BaseAddEditDialogWrapper.renderErrorInfo(errorInfo)
  }
}
