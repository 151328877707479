import { computed } from 'mobx'

import { FilterType } from '~/client/graph'

import ActivityCode from '../../models/ActivityCode'
import IBuilding from '../../models/IBuilding'
import ILevel from '../../models/ILevel'
import IZone from '../../models/IZone'
import {
  ALL_BUILDINGS,
  ALL_LEVELS,
  ALL_ZONES,
  UNASSIGNED,
  UNASSIGNED_FILTER_VALUE,
} from '../../utils/ZoneLevelLocationConstants'
import ActivityFiltersStore from './ActivityFilters.store'

export default class LocationsStore {
  public constructor(private activityFiltersStore: ActivityFiltersStore) {}

  @computed
  public get levels(): ILevel[] {
    const levelCodes = this.getCodesByFilterType(FilterType.Level)
    const levelNames = []
    levelCodes.forEach(code => levelNames.push(...code.name.split(',')))

    // when changing the order, remember to update realLevels
    const levelOptions = [
      ALL_LEVELS,
      ...Array.from(new Set(levelNames)),
      UNASSIGNED,
    ]

    const zones = this.createZonesForLevel()

    return levelOptions.map(option => {
      return {
        levelName: option,
        zones,
        codeIds: this.getLevelCodeIds(levelCodes, option),
      }
    })
  }

  @computed
  public get buildings(): IBuilding[] {
    const buildingCodes = this.getCodesByFilterType(FilterType.Building)
    const buildingNames = []
    buildingCodes.forEach(code => buildingNames.push(...code.name.split(',')))

    // when changing the order, remember to update realBuildings
    const buildingOptions = [
      ALL_BUILDINGS,
      ...Array.from(new Set(buildingNames)),
      UNASSIGNED,
    ]

    return buildingOptions.map(option => {
      return {
        buldingName: option,
        levels: this.levels,
        codeIds: this.getBuildingCodeIds(buildingCodes, option),
      }
    })
  }

  public get realLevels() {
    // without ALL
    return this.levels.slice(1)
  }

  public get realZones() {
    // without ALL
    return this.allZones.slice(1)
  }

  @computed
  public get allZones(): IZone[] {
    const zoneCodes = this.getCodesByFilterType(FilterType.Zone)
    const levelZones = []
    this.levels.forEach(({ zones }) => {
      zones.forEach(zone => {
        if (!levelZones.includes(zone)) {
          levelZones.push(zone)
        }
      })
    })

    return [
      {
        zoneName: ALL_ZONES,
        codeIds: [...zoneCodes.map(({ id }) => id), UNASSIGNED_FILTER_VALUE],
      },
      ...levelZones,
    ]
  }

  @computed
  public get levelCodeIds(): string[] {
    let allLevelCodeIds = []
    this.levels.forEach(({ codeIds }) => {
      allLevelCodeIds = allLevelCodeIds.concat(codeIds.slice())
    })
    return Array.from(new Set(allLevelCodeIds))
  }

  @computed
  public get buildingCodeIds(): string[] {
    let allBuildingCodeIds = []
    this.buildings.forEach(({ codeIds }) => {
      allBuildingCodeIds = allBuildingCodeIds.concat(codeIds.slice())
    })
    return Array.from(new Set(allBuildingCodeIds))
  }

  @computed
  public get zoneCodeIds(): string[] {
    let allZoneCodeIds = []
    this.allZones.forEach(({ codeIds }) => {
      allZoneCodeIds = allZoneCodeIds.concat(codeIds.slice())
    })
    return Array.from(new Set(allZoneCodeIds))
  }

  public getCodesByFilter(filterName: string): ActivityCode[] {
    return this.activityFiltersStore.getCodesByFilterType(filterName)
  }

  private createZonesForLevel(): IZone[] {
    const zoneCodes = this.getCodesByFilterType(FilterType.Zone)
    const zoneNames = []
    zoneCodes.forEach(code => zoneNames.push(...code.name.split(',')))

    const zoneOptions = [...Array.from(new Set(zoneNames)), UNASSIGNED]

    return zoneOptions.map((option, index) => {
      return {
        zoneName: option,
        number: index + 1,
        codeIds: this.getZoneCodeIds(zoneCodes, option),
      }
    })
  }

  private getZoneCodeIds(codes: ActivityCode[], zoneName: string) {
    let zoneCodeIds = []
    if (zoneName === ALL_ZONES) {
      zoneCodeIds = codes.map(({ id }) => id)
      zoneCodeIds.push(UNASSIGNED_FILTER_VALUE)
    } else if (zoneName === UNASSIGNED) {
      zoneCodeIds.push(UNASSIGNED_FILTER_VALUE)
    } else {
      const zoneCodes = codes.filter(({ name }) =>
        name.split(',').includes(zoneName),
      )
      zoneCodeIds.push(...zoneCodes.map(code => code.id))
    }

    return zoneCodeIds
  }

  private getLevelCodeIds(codes: ActivityCode[], levelName: string) {
    let levelCodeIds = []
    if (levelName === ALL_LEVELS) {
      levelCodeIds = codes.map(({ id }) => id)
      levelCodeIds.push(UNASSIGNED_FILTER_VALUE)
    } else if (levelName === UNASSIGNED) {
      levelCodeIds.push(UNASSIGNED_FILTER_VALUE)
    } else {
      const levelCodes = codes.filter(({ name }) =>
        name.split(',').includes(levelName),
      )
      levelCodeIds.push(...levelCodes.map(code => code.id))
    }

    return levelCodeIds
  }

  private getBuildingCodeIds(codes: ActivityCode[], buildingName: string) {
    let buildingCodeIds = []
    if (buildingName === ALL_BUILDINGS) {
      buildingCodeIds = codes.map(({ id }) => id)
      buildingCodeIds.push(UNASSIGNED_FILTER_VALUE)
    } else if (buildingName === UNASSIGNED) {
      buildingCodeIds.push(UNASSIGNED_FILTER_VALUE)
    } else {
      const buildingCodes = codes.filter(({ name }) =>
        name.split(',').includes(buildingName),
      )
      buildingCodeIds.push(...buildingCodes.map(code => code.id))
    }

    return buildingCodeIds
  }

  private getCodesByFilterType = (filterType: string) => {
    return this.activityFiltersStore.getCodesByFilterType(filterType)
  }
}
