import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IDeliveryFollowingFieldsFragment = Pick<
  Types.IDeliveryFollowing,
  'id' | 'projectId' | 'entityId' | 'userId' | 'createdAt' | 'updatedAt'
>

export type IGetDeliveryFollowingListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetDeliveryFollowingListQuery = {
  deliveryFollowings?: Types.Maybe<{
    data: Array<IDeliveryFollowingFieldsFragment>
  }>
}

export type IListenToDeliveryFollowingSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToDeliveryFollowingSubscription = {
  deliveryFollowing?: Types.Maybe<
    Pick<Types.IDeliveryFollowingChangeEvent, 'id'> & {
      item?: Types.Maybe<IDeliveryFollowingFieldsFragment>
    }
  >
}

export type ISaveManyDeliveryFollowingsMutationVariables = Types.Exact<{
  deliveryFollowings:
    | Array<Types.Maybe<Types.IDeliveryFollowingInput>>
    | Types.Maybe<Types.IDeliveryFollowingInput>
}>

export type ISaveManyDeliveryFollowingsMutation = {
  saveManyDeliveryFollowings?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IDeliveryFollowing, 'id'>>>
  >
}

export type IDeleteManyDeliveryFollowingsMutationVariables = Types.Exact<{
  deliveryFollowingIds:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteManyDeliveryFollowingsMutation = Pick<
  Types.IMutation,
  'deleteManyDeliveryFollowings'
>

export const DeliveryFollowingFieldsFragmentDoc = gql`
  fragment DeliveryFollowingFields on DeliveryFollowing {
    id
    projectId
    entityId
    userId
    createdAt
    updatedAt
  }
`
export const GetDeliveryFollowingListDocument = gql`
  query GetDeliveryFollowingList($projectId: ObjectId!) {
    deliveryFollowings(limit: 1000000, projectId: $projectId) {
      data {
        ...DeliveryFollowingFields
      }
    }
  }
  ${DeliveryFollowingFieldsFragmentDoc}
`

/**
 * __useGetDeliveryFollowingListQuery__
 *
 * To run a query within a React component, call `useGetDeliveryFollowingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryFollowingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryFollowingListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetDeliveryFollowingListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetDeliveryFollowingListQuery,
    IGetDeliveryFollowingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetDeliveryFollowingListQuery,
    IGetDeliveryFollowingListQueryVariables
  >(GetDeliveryFollowingListDocument, options)
}
export function useGetDeliveryFollowingListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetDeliveryFollowingListQuery,
    IGetDeliveryFollowingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetDeliveryFollowingListQuery,
    IGetDeliveryFollowingListQueryVariables
  >(GetDeliveryFollowingListDocument, options)
}
export type GetDeliveryFollowingListQueryHookResult = ReturnType<
  typeof useGetDeliveryFollowingListQuery
>
export type GetDeliveryFollowingListLazyQueryHookResult = ReturnType<
  typeof useGetDeliveryFollowingListLazyQuery
>
export type GetDeliveryFollowingListQueryResult = Apollo.QueryResult<
  IGetDeliveryFollowingListQuery,
  IGetDeliveryFollowingListQueryVariables
>
export const ListenToDeliveryFollowingDocument = gql`
  subscription ListenToDeliveryFollowing($projectId: ObjectId!) {
    deliveryFollowing(projectId: $projectId) {
      id
      item {
        ...DeliveryFollowingFields
      }
    }
  }
  ${DeliveryFollowingFieldsFragmentDoc}
`

/**
 * __useListenToDeliveryFollowingSubscription__
 *
 * To run a query within a React component, call `useListenToDeliveryFollowingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToDeliveryFollowingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToDeliveryFollowingSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToDeliveryFollowingSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToDeliveryFollowingSubscription,
    IListenToDeliveryFollowingSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToDeliveryFollowingSubscription,
    IListenToDeliveryFollowingSubscriptionVariables
  >(ListenToDeliveryFollowingDocument, options)
}
export type ListenToDeliveryFollowingSubscriptionHookResult = ReturnType<
  typeof useListenToDeliveryFollowingSubscription
>
export type ListenToDeliveryFollowingSubscriptionResult =
  Apollo.SubscriptionResult<IListenToDeliveryFollowingSubscription>
export const SaveManyDeliveryFollowingsDocument = gql`
  mutation SaveManyDeliveryFollowings(
    $deliveryFollowings: [DeliveryFollowingInput]!
  ) {
    saveManyDeliveryFollowings(deliveryFollowings: $deliveryFollowings) {
      id
    }
  }
`
export type ISaveManyDeliveryFollowingsMutationFn = Apollo.MutationFunction<
  ISaveManyDeliveryFollowingsMutation,
  ISaveManyDeliveryFollowingsMutationVariables
>

/**
 * __useSaveManyDeliveryFollowingsMutation__
 *
 * To run a mutation, you first call `useSaveManyDeliveryFollowingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyDeliveryFollowingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyDeliveryFollowingsMutation, { data, loading, error }] = useSaveManyDeliveryFollowingsMutation({
 *   variables: {
 *      deliveryFollowings: // value for 'deliveryFollowings'
 *   },
 * });
 */
export function useSaveManyDeliveryFollowingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyDeliveryFollowingsMutation,
    ISaveManyDeliveryFollowingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyDeliveryFollowingsMutation,
    ISaveManyDeliveryFollowingsMutationVariables
  >(SaveManyDeliveryFollowingsDocument, options)
}
export type SaveManyDeliveryFollowingsMutationHookResult = ReturnType<
  typeof useSaveManyDeliveryFollowingsMutation
>
export type SaveManyDeliveryFollowingsMutationResult =
  Apollo.MutationResult<ISaveManyDeliveryFollowingsMutation>
export type SaveManyDeliveryFollowingsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveManyDeliveryFollowingsMutation,
    ISaveManyDeliveryFollowingsMutationVariables
  >
export const DeleteManyDeliveryFollowingsDocument = gql`
  mutation DeleteManyDeliveryFollowings($deliveryFollowingIds: [ObjectId]!) {
    deleteManyDeliveryFollowings(ids: $deliveryFollowingIds)
  }
`
export type IDeleteManyDeliveryFollowingsMutationFn = Apollo.MutationFunction<
  IDeleteManyDeliveryFollowingsMutation,
  IDeleteManyDeliveryFollowingsMutationVariables
>

/**
 * __useDeleteManyDeliveryFollowingsMutation__
 *
 * To run a mutation, you first call `useDeleteManyDeliveryFollowingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyDeliveryFollowingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyDeliveryFollowingsMutation, { data, loading, error }] = useDeleteManyDeliveryFollowingsMutation({
 *   variables: {
 *      deliveryFollowingIds: // value for 'deliveryFollowingIds'
 *   },
 * });
 */
export function useDeleteManyDeliveryFollowingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteManyDeliveryFollowingsMutation,
    IDeleteManyDeliveryFollowingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteManyDeliveryFollowingsMutation,
    IDeleteManyDeliveryFollowingsMutationVariables
  >(DeleteManyDeliveryFollowingsDocument, options)
}
export type DeleteManyDeliveryFollowingsMutationHookResult = ReturnType<
  typeof useDeleteManyDeliveryFollowingsMutation
>
export type DeleteManyDeliveryFollowingsMutationResult =
  Apollo.MutationResult<IDeleteManyDeliveryFollowingsMutation>
export type DeleteManyDeliveryFollowingsMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteManyDeliveryFollowingsMutation,
    IDeleteManyDeliveryFollowingsMutationVariables
  >
