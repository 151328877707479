import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

import './GeoreferencingLabel.scss'

export interface IProps {
  isReferenced: boolean
  className?: string
  isCompact?: boolean
}

const georeferenced = 'Georeferenced'
const nonGeoreferenced = 'Non-georeferenced'

@observer
export default class GeoreferencingLabel extends React.Component<IProps> {
  public render() {
    const { isReferenced, className, isCompact } = this.props

    return (
      <div
        className={classList({
          'row georeferencing-label': true,
          className: !!className,
          'x-between': !isCompact,
          'x-end': isCompact,
        })}
      >
        <div
          className={classList({
            'georeferenced-label no-grow row brada5 w-max-content': true,
            referenced: isReferenced,
            'not-referenced': !isReferenced,
          })}
        >
          {isReferenced ? (
            <Icons.Referenced className="no-grow geo-icon" />
          ) : (
            <Icons.NotReferenced className="no-grow geo-icon" />
          )}
          {!isCompact && (
            <span>{isReferenced ? georeferenced : nonGeoreferenced}</span>
          )}
        </div>
      </div>
    )
  }
}
