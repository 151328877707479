import { observable } from 'mobx'

import {
  IPosition,
  ISitemapRectangle,
  SitemapItemShapeType,
} from '~/client/graph'

import { areObjectsEqual } from '../../../utils/util'
import { IBoundingBox } from './MapViewItemBase'
import { SitemapShapeProperties } from './SitemapItemProperties'

const DEFAULT_IS_DISPLAYED = true
const DEFAULT_LINE_WIDTH = 3
const DEFAULT_FILL_OPACITY = 0.2

const DEFAULT_POSITION = { x: 40, y: 40 }
export const DEFAULT_RECT_WIDTH = 20
export const DEFAULT_RECT_HEIGHT = 20
export const DEFAULT_RECT_SIZE_REFERENCED = 80

const DEFAULT_ROTATION = 0

const MAX_PERCENT = 100
const DEGREE_IN_RADIANS = Math.PI / 180

export default class SitemapRectangleProperties implements ISitemapRectangle {
  public type: SitemapItemShapeType = SitemapItemShapeType.Rectangle
  @observable public lineWidth: number
  @observable public fillColor: string
  @observable public fillOpacity: number
  @observable public position: IPosition
  @observable public width: number
  @observable public height: number
  @observable public lineColor: string
  @observable public rotation: number
  @observable public isDisplayed: boolean

  public constructor(
    lineWidth?: number,
    lineColor?: string,
    fillColor?: string,
    fillOpacity?: number,
    position?: IPosition,
    width?: number,
    height?: number,
    rotation?: number,
    isDisplayed?: boolean,
  ) {
    this.lineWidth = lineWidth || DEFAULT_LINE_WIDTH
    this.lineColor = lineColor
    this.fillColor = fillColor
    this.fillOpacity =
      typeof fillOpacity === 'number' ? fillOpacity : DEFAULT_FILL_OPACITY
    this.position = position || DEFAULT_POSITION
    this.width = width || DEFAULT_RECT_WIDTH
    this.height = height || DEFAULT_RECT_HEIGHT
    this.rotation = rotation || DEFAULT_ROTATION
    this.isDisplayed =
      typeof isDisplayed === 'boolean' ? isDisplayed : DEFAULT_IS_DISPLAYED
  }

  public isEqual(properties: SitemapShapeProperties) {
    return areObjectsEqual(this, properties)
  }

  public getBoundingBox(
    containerWidth: number,
    containerHeight: number,
    mapBearing?: number,
  ): IBoundingBox {
    if (!this.isValid()) {
      return null
    }

    const cos = Math.cos((this.rotation - mapBearing || 0) * DEGREE_IN_RADIANS)
    const sin = Math.sin((this.rotation - mapBearing || 0) * DEGREE_IN_RADIANS)

    const width = (this.width * containerWidth) / MAX_PERCENT
    const height = (this.height * containerHeight) / MAX_PERCENT
    const positionX = (this.position.x * containerWidth) / MAX_PERCENT
    const positionY = (this.position.y * containerHeight) / MAX_PERCENT

    const points = [
      { x: 0, y: 0 },
      { x: width, y: 0 },
      { x: width, y: height },
      { x: 0, y: height },
    ].map(({ x, y }) => {
      return {
        x: x * cos - y * sin + positionX,
        y: x * sin + y * cos + positionY,
      }
    })

    const minX = Math.min(...points.map(p => p.x))
    const maxX = Math.max(...points.map(p => p.x))
    const minY = Math.min(...points.map(p => p.y))
    const maxY = Math.max(...points.map(p => p.y))

    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    }
  }

  public toggleIsDisplayed() {
    this.isDisplayed = !this.isDisplayed
  }

  public isValid(): boolean {
    return !!this.position && !!this.width && !!this.height
  }

  public setLineWidth(width: number) {
    this.lineWidth = width
  }

  public setLineColor(color: string) {
    this.lineColor = color
  }

  public setFillColor(color: string) {
    this.fillColor = color
  }

  public setFillOpacity(opacity: number) {
    this.fillOpacity = opacity
  }

  public setPosition(position: IPosition) {
    this.position = position
  }

  public setWidth(width: number) {
    this.width = width
  }

  public setHeight(height: number) {
    this.height = height
  }

  public calculateIconPosition(): IPosition {
    if (!this.isValid()) {
      return null
    }

    return {
      x: this.position.x + this.width / 2,
      y: this.position.y + this.height / 2,
    }
  }

  public move(x: number, y: number): IPosition[] {
    this.position = {
      x: this.position.x + x,
      y: this.position.y + y,
    }
    return [this.position]
  }

  public scale(originPoint: IPosition, scaleX: number, scaleY: number) {
    const topCornerX =
      scaleX * (this.position.x - originPoint.x) + originPoint.x
    const topCornerY =
      scaleY * (this.position.y - originPoint.y) + originPoint.y
    const bottomCornerX =
      scaleX * (this.position.x + this.width - originPoint.x) + originPoint.x
    const bottomCornerY =
      scaleY * (this.position.y + this.height - originPoint.y) + originPoint.y
    this.position = {
      x: Math.min(topCornerX, bottomCornerX),
      y: Math.min(topCornerY, bottomCornerY),
    }
    this.width = Math.abs(topCornerX - bottomCornerX)
    this.height = Math.abs(topCornerY - bottomCornerY)
  }

  public rotate(center: IPosition, angle: number) {
    let { x, y } = this.position

    x -= center.x
    y -= center.y

    const cos = Math.cos(angle * DEGREE_IN_RADIANS)
    const sin = Math.sin(angle * DEGREE_IN_RADIANS)

    const newX = x * cos - y * sin
    const newY = x * sin + y * cos

    this.position = {
      x: newX + center.x,
      y: newY + center.y,
    }
    this.rotation += angle
  }

  public isPositionEqual(position: IPosition) {
    if (!this.position) {
      return !position
    }
    return (
      position &&
      this.position.x === position.x &&
      this.position.y === position.y
    )
  }

  public isDisplayDataEqual(rect: ISitemapRectangle) {
    return (
      this.isDisplayed ===
        (typeof rect.isDisplayed === 'boolean'
          ? rect.isDisplayed
          : DEFAULT_IS_DISPLAYED) &&
      this.type === rect.type &&
      this.lineWidth === rect.lineWidth &&
      this.lineColor === rect.lineColor &&
      this.fillColor === rect.fillColor &&
      this.fillOpacity === rect.fillOpacity &&
      this.rotation === rect.rotation &&
      this.width === rect.width &&
      this.height === rect.height &&
      this.isPositionEqual(rect.position)
    )
  }

  public getDisplayData(): ISitemapRectangle {
    return {
      type: this.type,
      lineWidth: this.lineWidth,
      lineColor: this.lineColor,
      fillColor: this.fillColor,
      fillOpacity: this.fillOpacity,
      width: this.width,
      height: this.height,
      rotation: this.rotation,
      position: {
        x: this.position.x,
        y: this.position.y,
      },
      isDisplayed: this.isDisplayed,
    }
  }

  public copy(): SitemapRectangleProperties {
    return new SitemapRectangleProperties(
      this.lineWidth,
      this.lineColor,
      this.fillColor,
      this.fillOpacity,
      {
        x: this.position.x,
        y: this.position.y,
      },
      this.width,
      this.height,
      this.rotation,
      this.isDisplayed,
    )
  }
}
