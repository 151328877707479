import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import EventsStore from '../../stores/EventStore/Events.store'
import ActivitiesStore from '../../stores/domain/Activities.store'
import ProjectsStore from '../../stores/domain/Projects.store'
import StatusUpdatesStore from '../../stores/domain/StatusUpdates.store'
import CommonStore from '../../stores/ui/Common.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import BulkStatusUpdateStore from './BulkStatusUpdate.store'
import ActualDateButton from './components/ActualDateButton'
import ActualDatesChangeConfirm from './components/ActualDatesChangeConfirm'
import BulkManpowerUpdate from './components/BulkManpowerUpdate'
import BulkPercentCompleteUpdate from './components/BulkPercentCompleteUpdate'
import BulkRemainingDurationUpdate from './components/BulkRemainingDurationUpdate'
import BulkStatusUpdateCalendar from './components/BulkStatusUpdateCalendar'

import './BulkStatusUpdate.scss'

// translated

interface IRouteProps {
  activityId: string
  company: string
}

interface IProps {
  common?: CommonStore
  activityId?: string
  company?: string
  eventsStore?: EventsStore
  activitiesStore?: ActivitiesStore
  statusUpdatesStore?: StatusUpdatesStore
  projectsStore?: ProjectsStore
  projectDateStore?: ProjectDateStore
}

@inject(
  'common',
  'eventsStore',
  'activitiesStore',
  'statusUpdatesStore',
  'projectsStore',
  'projectDateStore',
)
@observer
class BulkStatusUpdate extends React.Component<
  IProps & RouteComponentProps<IRouteProps>
> {
  private readonly store: BulkStatusUpdateStore

  public constructor(props: IProps & RouteComponentProps<IRouteProps>) {
    super(props)

    this.store = new BulkStatusUpdateStore(
      props.eventsStore,
      props.activitiesStore,
      props.statusUpdatesStore,
      props.projectsStore,
      props.projectDateStore,
    )
  }

  public UNSAFE_componentWillMount() {
    this.updateSelectedActivity()
  }

  public componentDidUpdate() {
    this.updateSelectedActivity()
  }

  public render() {
    const {
      activity,
      selectedDate,
      companyName: company,
      commonStatusUpdateOnSelectedDate,
      isLoading,
      isActualStartButtonActive,
      isActualFinishButtonActive,
      isActualStartButtonDisabled,
      isActualFinishButtonDisabled,
      onActualStartButtonClicked,
      onActualFinishButtonClicked,
      closeConfirmModal,
      isConfirmModalShown,
      isActualStartSettingSelected,
      isActualFinishSettingSelected,
      actualStartDate,
      actualEndDate,
    } = this.store

    if (!activity) {
      return null
    }

    if (isLoading) {
      return <Loader />
    }

    const { eventsStore, projectDateStore } = this.props
    const { getPronoun, getMonthAndDayToDisplayWithCheck } = projectDateStore
    const { isStatusUpdateManpowerDisabled } = eventsStore.appState

    return (
      <div className="bulk-status-update col full-height">
        <ActualDatesChangeConfirm
          isShown={isConfirmModalShown}
          onApply={this.applyUpdate}
          onHide={closeConfirmModal}
          isActualStartSettingSelected={isActualStartSettingSelected}
          isActualFinishSettingSelected={isActualFinishSettingSelected}
          selectedDate={selectedDate}
          actualStartDate={actualStartDate}
          actualEndDate={actualEndDate}
        />
        <div className="row py10 px20 bb-light-grey">
          <Icons.BackArrow className="no-grow" onClick={this.unMountPage} />
          <div className="text bold ellipsis extra-large pl10">
            {activity.name}
          </div>
          <Icons.Cross className="no-grow" onClick={this.closePage} />
        </div>
        <div className="col scroll-area">
          <div>
            <BulkStatusUpdateCalendar store={this.store} />
          </div>
          <div className="row y-center py10 px20">
            <div className="no-grow">
              <ActualDateButton
                isActive={isActualStartButtonActive}
                isDisabled={isActualStartButtonDisabled}
                onClick={onActualStartButtonClicked}
              >
                {Localization.translator.actualStart}
              </ActualDateButton>
            </div>
            <div className="row x-end text bold extra-large">
              {getMonthAndDayToDisplayWithCheck(selectedDate)}
            </div>
            <div className="row x-start pl5 text uppercase">
              {getPronoun(selectedDate)}
            </div>
            <div className="no-grow">
              <ActualDateButton
                isActive={isActualFinishButtonActive}
                isDisabled={isActualFinishButtonDisabled}
                onClick={onActualFinishButtonClicked}
              >
                {Localization.translator.actualFinish}
              </ActualDateButton>
            </div>
          </div>
          <div className="px20">
            {!isStatusUpdateManpowerDisabled && (
              <BulkManpowerUpdate
                date={selectedDate}
                activity={activity}
                company={company}
                commonStatusUpdate={commonStatusUpdateOnSelectedDate}
                store={this.store}
              />
            )}
            <BulkPercentCompleteUpdate
              date={selectedDate}
              activity={activity}
              company={company}
              commonStatusUpdate={commonStatusUpdateOnSelectedDate}
              store={this.store}
            />
            <BulkRemainingDurationUpdate
              date={selectedDate}
              activity={activity}
              company={company}
              commonStatusUpdate={commonStatusUpdateOnSelectedDate}
              store={this.store}
            />
          </div>
        </div>
      </div>
    )
  }

  private applyUpdate = () => {
    const { applyConfirmAction } = this.store
    if (applyConfirmAction) {
      applyConfirmAction()
    }
  }

  private setFromProps({
    activityId,
    company,
  }: {
    activityId?: string
    company?: string
  }) {
    if (!activityId && !company) {
      return false
    }

    this.store.reinit(activityId, company)
    return true
  }

  private unMountPage = () => {
    const { common } = this.props
    common.toActivityDetails()
  }

  private closePage = () => {
    const { common } = this.props
    common.displayActivityList()
  }

  private updateSelectedActivity() {
    const { match } = this.props
    const setFromRoute = match && this.setFromProps(match.params)
    if (!setFromRoute) {
      this.setFromProps(this.props)
    }
  }
}

export default withRouter(BulkStatusUpdate)
