import * as React from 'react'

import { inject, observer } from 'mobx-react'

import BaseGroupingOptions from '~/client/src/desktop/components/BaseGroupingOptions/BaseGroupingOptions'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopDeliveryViewStore from '~/client/src/desktop/views/Deliveries/Deliveries.store'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'

import DeliveryGroupingOptionsStore from './DeliveryGroupingOptions.store'

// localization: no display text to translate

interface IProps {
  onShowChanged: (isShown: boolean) => void
  onChanged: () => void
  forceClose: boolean
  desktopDeliveryViewStore: DesktopDeliveryViewStore
  eventsStore?: DesktopEventStore
  CustomHandleButton?: typeof BaseActionButton
  shouldHideBandCreation?: boolean
}

@inject('eventsStore')
@observer
export default class DeliveryGroupingOptions extends React.Component<IProps> {
  private readonly store: DeliveryGroupingOptionsStore

  public constructor(props: IProps) {
    super(props)

    this.store = new DeliveryGroupingOptionsStore(
      props.eventsStore,
      props.desktopDeliveryViewStore,
      props.onShowChanged,
      props.onChanged,
    )
  }

  public render() {
    const { forceClose, CustomHandleButton, shouldHideBandCreation } =
      this.props

    return (
      <BaseGroupingOptions
        store={this.store}
        forceClose={forceClose}
        CustomHandleButton={CustomHandleButton}
        shouldHideBandCreation={shouldHideBandCreation}
      />
    )
  }
}
