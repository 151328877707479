import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ICastAssignmentFieldsFragment = Pick<
  Types.ICastAssignment,
  'id' | 'projectId' | 'entityId' | 'createdAt' | 'updatedAt'
> & { recipients: Array<Types.Maybe<Pick<Types.IRecipients, 'ids' | 'type'>>> }

export type IGetCastAssignmentListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetCastAssignmentListQuery = {
  castAssignments?: Types.Maybe<{ data: Array<ICastAssignmentFieldsFragment> }>
}

export type ISaveManyCastAssignmentsMutationVariables = Types.Exact<{
  assignments:
    | Array<Types.Maybe<Types.ICastAssignmentInput>>
    | Types.Maybe<Types.ICastAssignmentInput>
}>

export type ISaveManyCastAssignmentsMutation = {
  saveManyCastAssignments?: Types.Maybe<
    Array<Types.Maybe<ICastAssignmentFieldsFragment>>
  >
}

export type IDeleteManyCastAssignmentsMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteManyCastAssignmentsMutation = Pick<
  Types.IMutation,
  'deleteManyCastAssignments'
>

export const CastAssignmentFieldsFragmentDoc = gql`
  fragment CastAssignmentFields on CastAssignment {
    id
    projectId
    entityId
    recipients {
      ids
      type
    }
    createdAt
    updatedAt
  }
`
export const GetCastAssignmentListDocument = gql`
  query GetCastAssignmentList($projectId: ObjectId!) {
    castAssignments(limit: 1000000, projectId: $projectId) {
      data {
        ...CastAssignmentFields
      }
    }
  }
  ${CastAssignmentFieldsFragmentDoc}
`

/**
 * __useGetCastAssignmentListQuery__
 *
 * To run a query within a React component, call `useGetCastAssignmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCastAssignmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCastAssignmentListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetCastAssignmentListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetCastAssignmentListQuery,
    IGetCastAssignmentListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetCastAssignmentListQuery,
    IGetCastAssignmentListQueryVariables
  >(GetCastAssignmentListDocument, options)
}
export function useGetCastAssignmentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetCastAssignmentListQuery,
    IGetCastAssignmentListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetCastAssignmentListQuery,
    IGetCastAssignmentListQueryVariables
  >(GetCastAssignmentListDocument, options)
}
export type GetCastAssignmentListQueryHookResult = ReturnType<
  typeof useGetCastAssignmentListQuery
>
export type GetCastAssignmentListLazyQueryHookResult = ReturnType<
  typeof useGetCastAssignmentListLazyQuery
>
export type GetCastAssignmentListQueryResult = Apollo.QueryResult<
  IGetCastAssignmentListQuery,
  IGetCastAssignmentListQueryVariables
>
export const SaveManyCastAssignmentsDocument = gql`
  mutation SaveManyCastAssignments($assignments: [CastAssignmentInput]!) {
    saveManyCastAssignments(castAssignments: $assignments) {
      ...CastAssignmentFields
    }
  }
  ${CastAssignmentFieldsFragmentDoc}
`
export type ISaveManyCastAssignmentsMutationFn = Apollo.MutationFunction<
  ISaveManyCastAssignmentsMutation,
  ISaveManyCastAssignmentsMutationVariables
>

/**
 * __useSaveManyCastAssignmentsMutation__
 *
 * To run a mutation, you first call `useSaveManyCastAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyCastAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyCastAssignmentsMutation, { data, loading, error }] = useSaveManyCastAssignmentsMutation({
 *   variables: {
 *      assignments: // value for 'assignments'
 *   },
 * });
 */
export function useSaveManyCastAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyCastAssignmentsMutation,
    ISaveManyCastAssignmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyCastAssignmentsMutation,
    ISaveManyCastAssignmentsMutationVariables
  >(SaveManyCastAssignmentsDocument, options)
}
export type SaveManyCastAssignmentsMutationHookResult = ReturnType<
  typeof useSaveManyCastAssignmentsMutation
>
export type SaveManyCastAssignmentsMutationResult =
  Apollo.MutationResult<ISaveManyCastAssignmentsMutation>
export type SaveManyCastAssignmentsMutationOptions = Apollo.BaseMutationOptions<
  ISaveManyCastAssignmentsMutation,
  ISaveManyCastAssignmentsMutationVariables
>
export const DeleteManyCastAssignmentsDocument = gql`
  mutation DeleteManyCastAssignments($ids: [ObjectId]!) {
    deleteManyCastAssignments(ids: $ids)
  }
`
export type IDeleteManyCastAssignmentsMutationFn = Apollo.MutationFunction<
  IDeleteManyCastAssignmentsMutation,
  IDeleteManyCastAssignmentsMutationVariables
>

/**
 * __useDeleteManyCastAssignmentsMutation__
 *
 * To run a mutation, you first call `useDeleteManyCastAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyCastAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyCastAssignmentsMutation, { data, loading, error }] = useDeleteManyCastAssignmentsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteManyCastAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteManyCastAssignmentsMutation,
    IDeleteManyCastAssignmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteManyCastAssignmentsMutation,
    IDeleteManyCastAssignmentsMutationVariables
  >(DeleteManyCastAssignmentsDocument, options)
}
export type DeleteManyCastAssignmentsMutationHookResult = ReturnType<
  typeof useDeleteManyCastAssignmentsMutation
>
export type DeleteManyCastAssignmentsMutationResult =
  Apollo.MutationResult<IDeleteManyCastAssignmentsMutation>
export type DeleteManyCastAssignmentsMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteManyCastAssignmentsMutation,
    IDeleteManyCastAssignmentsMutationVariables
  >
