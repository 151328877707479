import * as React from 'react'

import { KonvaEventObject } from 'konva/types/Node'
import { observer } from 'mobx-react'
import { Group } from 'react-konva'

import { IPosition, SitemapItemShapeType } from '~/client/graph'
import BoundingBox from '~/client/src/shared/components/SitemapHelpers/components/drawnParts/BoundingBox'
import SitemapIcon from '~/client/src/shared/components/SitemapHelpers/components/drawnParts/SitemapIcon'
import SitemapLabel from '~/client/src/shared/components/SitemapHelpers/components/drawnParts/SitemapLabel'
import SitemapShape from '~/client/src/shared/components/SitemapHelpers/components/drawnParts/SitemapShape'
import SitemapCircleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapCircleProperties'
import MapViewItemDrawnPart from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemDrawnPart'
import SitemapRectangleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapRectangleProperties'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'

import MapViewItemsSetupStore from '../../stores/MapViewItemsSetup.store'
import SitemapsSetupStore from '../../stores/SitemapsSetup.store'
import BoundsEditor from './BoundsEditor'
import CircleEditor from './CircleEditor'
import PolyLineEditor from './PolyLineEditor'
import RectangleEditor from './RectangleEditor'

interface IMovableItem {
  move(x: number, y: number): IPosition[]
}

const MAX_PERCENT = 100
const ZERO = 0

interface IProps {
  containerWidth: number
  containerHeight: number
  store: MapViewItemsSetupStore
  textboxesCache?: ICanvasImageCache
  sitemapsSetupStore: SitemapsSetupStore
}

@observer
export default class SitemapEditableItem extends React.Component<IProps> {
  public render() {
    const {
      sitemapsSetupStore: { selectedSitemap },
      store: { selectedMapViewItem, selectedMapViewItemDrawnPart },
    } = this.props
    if (
      !selectedSitemap ||
      !selectedMapViewItem ||
      selectedMapViewItem.isRichTextBox
    ) {
      return null
    }

    if (!selectedMapViewItemDrawnPart || !selectedMapViewItem.isDisplayed) {
      return this.renderItem()
    }

    switch (selectedMapViewItemDrawnPart) {
      case MapViewItemDrawnPart.Icon:
        return (
          <>
            {this.renderShape()}
            {this.renderLabel()}
            {this.renderEditableIcon()}
          </>
        )
      case MapViewItemDrawnPart.Label:
        return (
          <>
            {this.renderShape()}
            {this.renderIcon()}
            {this.renderEditableLabel()}
          </>
        )
      case MapViewItemDrawnPart.Shape:
        return (
          <>
            {this.renderLabel()}
            {this.renderIcon()}
            {this.renderEditableShape()}
          </>
        )
    }
  }

  private renderIcon = () => {
    const {
      containerHeight,
      containerWidth,
      store: { selectedMapViewItem },
    } = this.props

    return (
      <SitemapIcon
        item={selectedMapViewItem}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        onDblClick={this.selectDrawnPartEditing.bind(
          this,
          MapViewItemDrawnPart.Icon,
        )}
        onClick={this.onDrawnPartClick}
      />
    )
  }

  private renderLabel = () => {
    const { containerHeight, containerWidth, textboxesCache } = this.props
    const { selectedMapViewItem } = this.props.store

    return (
      <SitemapLabel
        item={selectedMapViewItem}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        onDblClick={this.selectDrawnPartEditing.bind(
          this,
          MapViewItemDrawnPart.Label,
        )}
        onClick={this.onDrawnPartClick}
        textboxesCache={textboxesCache}
      />
    )
  }

  private renderShape = () => {
    const { containerHeight, containerWidth } = this.props
    const { selectedMapViewItem } = this.props.store

    return (
      <SitemapShape
        item={selectedMapViewItem}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        onDblClick={this.selectDrawnPartEditing.bind(
          this,
          MapViewItemDrawnPart.Shape,
        )}
        onClick={this.onDrawnPartClick}
        isStaticSize={true}
      />
    )
  }

  private onDrawnPartClick = (event: KonvaEventObject<MouseEvent>) => {
    event.cancelBubble = true
  }

  private selectDrawnPartEditing(
    part: MapViewItemDrawnPart,
    event: KonvaEventObject<MouseEvent>,
  ) {
    event.cancelBubble = true
    const { selectSitemapItemDrawnPart } = this.props.store
    selectSitemapItemDrawnPart(part)
  }

  private renderItem() {
    const { selectedMapViewItem } = this.props.store
    const { containerHeight, containerWidth } = this.props

    return (
      <Group
        draggable={true}
        onMouseDown={this.startEditing}
        onMouseUp={this.cancelEditing}
        onDragEnd={this.onItemDragEnd.bind(
          this,
          selectedMapViewItem.sitemapItemProperties,
        )}
      >
        {this.renderItemBoundingBox()}
        {this.renderShape()}
        {this.renderLabel()}
        {this.renderIcon()}
        <BoundsEditor
          item={selectedMapViewItem}
          containerHeight={containerHeight}
          containerWidth={containerWidth}
        />
      </Group>
    )
  }

  private renderItemBoundingBox() {
    const { selectedMapViewItem } = this.props.store
    const { containerHeight, containerWidth } = this.props

    const boundingBox =
      selectedMapViewItem.sitemapItemProperties.getBoundingBox(
        containerWidth,
        containerHeight,
        selectedMapViewItem.iconName,
        selectedMapViewItem.name,
      )

    return <BoundingBox boundingBox={boundingBox} isStaticSize={true} />
  }

  private renderEditableIcon = () => {
    const { containerHeight, containerWidth } = this.props
    const { selectedMapViewItem } = this.props.store
    const {
      sitemapItemProperties: { iconProperties },
      iconName,
    } = selectedMapViewItem

    const boundingBox = iconProperties.getBoundingBox(
      iconName,
      containerWidth,
      containerHeight,
    )

    return (
      <Group
        draggable={true}
        onMouseDown={this.startEditing}
        onMouseUp={this.cancelEditing}
        onDragEnd={this.onItemDragEnd.bind(this, iconProperties)}
      >
        <BoundingBox boundingBox={boundingBox} isStaticSize={true} />
        <SitemapIcon
          item={selectedMapViewItem}
          containerHeight={containerHeight}
          containerWidth={containerWidth}
        />
      </Group>
    )
  }

  private renderEditableLabel = () => {
    const { containerHeight, containerWidth, textboxesCache } = this.props
    const { selectedMapViewItem } = this.props.store
    const {
      sitemapItemProperties: { labelProperties },
      name,
    } = selectedMapViewItem

    const boundingBox = labelProperties.getBoundingBox(
      name,
      containerWidth,
      containerHeight,
    )

    return (
      <Group
        draggable={true}
        onDragEnd={this.onItemDragEnd.bind(this, labelProperties)}
        onMouseDown={this.startEditing}
        onMouseUp={this.cancelEditing}
      >
        <BoundingBox boundingBox={boundingBox} isStaticSize={true} />
        <SitemapLabel
          item={selectedMapViewItem}
          containerHeight={containerHeight}
          containerWidth={containerWidth}
          textboxesCache={textboxesCache}
        />
      </Group>
    )
  }

  private renderEditableShape = () => {
    const { containerHeight, containerWidth } = this.props
    const { selectedMapViewItem } = this.props.store
    const { shapeProperties } = selectedMapViewItem.sitemapItemProperties

    const boundingBox = shapeProperties.getBoundingBox(
      containerWidth,
      containerHeight,
    )
    return (
      <Group
        draggable={true}
        onDragEnd={this.onItemDragEnd.bind(this, shapeProperties)}
        onMouseDown={this.startEditing}
        onMouseUp={this.cancelEditing}
      >
        <BoundingBox boundingBox={boundingBox} isStaticSize={true} />
        <SitemapShape
          item={selectedMapViewItem}
          containerHeight={containerHeight}
          containerWidth={containerWidth}
          isStaticSize={true}
        />
        {this.renderShapeEditor()}
      </Group>
    )
  }

  private renderShapeEditor() {
    const { containerHeight, containerWidth, store } = this.props
    const { shapeProperties } = store.selectedMapViewItem.sitemapItemProperties

    switch (shapeProperties.type) {
      case SitemapItemShapeType.Circle:
        return (
          <CircleEditor
            store={store}
            circle={shapeProperties as SitemapCircleProperties}
            containerHeight={containerHeight}
            containerWidth={containerWidth}
            onModifyStart={this.startEditing}
            onModifyEnd={this.cancelEditing}
          />
        )
      case SitemapItemShapeType.Polyline:
        return (
          <PolyLineEditor
            store={store}
            item={store.selectedMapViewItem}
            containerHeight={containerHeight}
            containerWidth={containerWidth}
            onModifyStart={this.startEditing}
            onModifyEnd={this.cancelEditing}
          />
        )
      case SitemapItemShapeType.Rectangle:
        return (
          <RectangleEditor
            store={store}
            rectangle={shapeProperties as SitemapRectangleProperties}
            containerHeight={containerHeight}
            containerWidth={containerWidth}
            onModifyStart={this.startEditing}
            onModifyEnd={this.cancelEditing}
          />
        )
    }
  }

  private startEditing = () => {
    //TODO: FIX (MOVE TO SITEMAP EDITOR STORE)
    // this.props.mapBoxViewerStore.isEditableItemInDragMode = true
  }

  private cancelEditing = () => {
    //TODO: FIX (MOVE TO SITEMAP EDITOR STORE)
    // this.props.mapBoxViewerStore.isEditableItemInDragMode = false
  }

  private onItemDragEnd = (
    item: IMovableItem,
    event: KonvaEventObject<DragEvent>,
  ) => {
    //TODO: FIX (MOVE TO SITEMAP EDITOR STORE)
    const { containerWidth, containerHeight, store } = this.props
    const { currentTarget } = event
    item.move(
      (currentTarget.x() / containerWidth) * MAX_PERCENT,
      (currentTarget.y() / containerHeight) * MAX_PERCENT,
    )
    currentTarget.x(ZERO)
    currentTarget.y(ZERO)

    store.addCurrentStateToHistory()
  }
}
