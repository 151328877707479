import { action, computed } from 'mobx'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import ObjectFilterType, {
  objectFilterTypes,
} from '~/client/src/shared/enums/ObjectsFilterType'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { SAVE_DELIVERY_ATTRIBUTES } from '~/client/src/shared/stores/EventStore/eventConstants'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'

import ObjectPropertiesSetupStore from '../../../../ObjectPropertiesSetup.store'
import BaseObjectPropertiesFilter, {
  ISourceMap,
} from './BaseObjectPropertiesFilter.store'

export default class ObjectPropertiesFilterStore {
  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly objectSetupStore: ObjectPropertiesSetupStore,
    private readonly companiesStore: CompaniesStore,
    protected readonly onShowChanged: (
      isShown: boolean,
      filterType: string,
    ) => void,
  ) {}

  private get availableAttributes(): LocationBase[] {
    return this.objectSetupStore.sitemapAttributes
  }

  @computed
  private get allCompaniesIds(): string[] {
    return this.companiesStore.allCompaniesIds
  }

  @computed
  public get filterStoresByTypeMap(): {
    [filterType: string]: BaseObjectPropertiesFilter
  } {
    const map: { [filterType: string]: BaseObjectPropertiesFilter } = {}

    objectFilterTypes.forEach(filterType => {
      const shouldHideCount = filterType === ObjectFilterType.Company

      map[filterType] = new BaseObjectPropertiesFilter(
        filterType,
        this.eventsStore.appState,
        this.sourceMapByFilterTypeMap[filterType],
        this.objectSetupStore,
        this.onShowChanged,
        shouldHideCount,
        this.getOptionName,
      )
    })

    return map
  }

  @computed
  private get sourceMapByFilterTypeMap(): { [filterType: string]: ISourceMap } {
    const maps = objectFilterTypes.reduce((acc, filterType) => {
      acc[filterType] = this.getDefaultSourceMapByType(filterType)
      return acc
    }, {})

    this.availableAttributes.forEach(attr => {
      Object.keys(maps).forEach(filterType => {
        if (filterType === ObjectFilterType.Company) {
          const map = maps[filterType]

          if (!attr.permittedCompanies || !attr.permittedCompanies.length) {
            this.allCompaniesIds.forEach(cId => map[cId].push(attr.id))
          } else {
            attr.permittedCompanies.forEach(comp => map[comp].push(attr.id))
          }
        }
      })
    })

    return maps
  }

  @action.bound
  public onObjectUpdated(eventContext: EventContext) {
    const [eventType] = eventContext.event

    if (SAVE_DELIVERY_ATTRIBUTES === eventType) {
      this.syncFilters()
    }
  }

  @action
  public syncFilters() {
    const { fieldsMap } = this.eventsStore.appState.objectFilters

    Object.keys(fieldsMap).forEach(filterType => {
      const filter = fieldsMap[filterType]
      const sourceMap = this.sourceMapByFilterTypeMap[filterType]

      switch (filterType) {
        case ObjectFilterType.Company:
          return this.updateCompanyFilterState(filter, sourceMap)
      }
    })
  }

  private updateCompanyFilterState(
    filter: UIFilterInfo,
    sourceMap: ISourceMap,
  ) {
    let appliedFilterOptions = []

    filter.initialFilterOptions.forEach((_, optionKey) => {
      const objectIds = sourceMap[optionKey]

      filter.selectedFilterOptions.set(optionKey, objectIds)
      filter.initialFilterOptions.set(optionKey, objectIds)

      appliedFilterOptions = appliedFilterOptions.concat(objectIds)
    })

    filter.appliedFilterOptions = appliedFilterOptions
  }

  private getDefaultSourceMapByType(type: ObjectFilterType) {
    let sourceList: string[] = []

    switch (type) {
      case ObjectFilterType.Company:
        sourceList = this.allCompaniesIds
        break
    }

    return sourceList.reduce((acc, optionId) => {
      acc[optionId] = []
      return acc
    }, {})
  }

  protected getOptionName = (option: string, filterType: string): string => {
    switch (filterType) {
      case ObjectFilterType.Company:
        return this.companiesStore.getCompanyNameById(option)

      default:
        return option
    }
  }
}
