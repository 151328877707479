export default abstract class BaseModel<TDto> {
  public id: string
  public createdAt = 0
  public updatedAt = 0

  public constructor(id?: string) {
    this.setId(id)
  }

  public setId(id: string): void {
    this.id = id
  }

  public getId(): string {
    return this.id
  }

  public setCreatedAt(ms: number): void {
    if (ms < 0) {
      return
    }
    this.createdAt = ms
  }

  public getCreatedAt(): number {
    return this.createdAt
  }

  public setUpdatedAt(ms: number): void {
    if (ms < 0) {
      return
    }
    this.updatedAt = ms
  }

  public getUpdatedAt(): number {
    return this.updatedAt
  }

  public updateFromJson(json: TDto): void {
    Object.assign(this, json)
  }

  public get asJson(): TDto {
    const json = JSON.parse(JSON.stringify(this))
    delete json.store
    return json
  }

  public isSameAs(other: BaseModel<TDto>) {
    if (!other) {
      return false
    }

    return this.id === other.id
  }

  public getCopy(): BaseModel<TDto> {
    // @ts-ignore: use constructor
    const copy = new this.constructor(this.id)
    copy.updateFromJson(this.asJson)
    return copy
  }
}
