import { action, computed, observable } from 'mobx'

import {
  ActivityStatus,
  CategoryName,
  FilterType,
  IRecentlyUpdatedSavedFilter,
  RecentlyUpdatedMode,
} from '~/client/graph'
import { CustomFilterDialogModes } from '~/client/src/shared/enums/CustomFilterDialogModes'
import ActivityPreset from '~/client/src/shared/models/ActivityPreset'
import {
  RESET_ALL_FILTERS,
  SET_FILTER_SELECTION,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivityCodesStore from '~/client/src/shared/stores/domain/ActivityCodes.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import ActivityPresetsStore from '~/client/src/shared/stores/domain/ActivityPresets.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import CustomActivityListFiltersStore from '~/client/src/shared/stores/domain/CustomActivityListFilters.store'
import CustomActivityListFilter from '~/client/src/shared/types/CustomActivityListFilter'
import {
  UNASSIGNED,
  UNASSIGNED_FILTER_VALUE,
} from '~/client/src/shared/utils/ZoneLevelLocationConstants'

import { IDesktopFilters } from '../../../stores/DesktopInitialState'
import DesktopEventStore from '../../../stores/EventStore/DesktopEvents.store'
import { categoryOptions } from '../CategoryFilter/CategoryFilter'
import CategoryFilterStore from '../CategoryFilter/CategoryFilter.store'
import RecentlyUpdatedStore from '../CategoryFilter/components/RecentlyUpdated/RecentlyUpdated.store'
import DesktopSavedFiltersStore from '../SavedFilters/DesktopSavedFilters.store'

// localization: no display text to translate

export default class DesktopActivitiesSavedFiltersStore extends DesktopSavedFiltersStore {
  @observable public editablePresetName: string = ''
  @observable public customFilterInstance: CustomActivityListFilter = null
  @observable public presetInstance: ActivityPreset = null

  public constructor(
    private readonly customActivityListFiltersStore: CustomActivityListFiltersStore,
    private readonly activityFiltersStore: ActivityFiltersStore,
    protected readonly eventsStore: DesktopEventStore,
    private readonly activityCodesStore: ActivityCodesStore,
    private readonly activityPresetsStore: ActivityPresetsStore,
    private readonly companiesStore: CompaniesStore,
    private readonly categoryFilterStore: CategoryFilterStore,
    private readonly recentlyUpdatedStore: RecentlyUpdatedStore,
    protected readonly onShowChanged?: (isShown: boolean) => void,
  ) {
    super(eventsStore, onShowChanged)
  }

  protected get enabledFilterTypes() {
    return this.activityFiltersStore.enabledFilterTypes
  }

  private get filters(): IDesktopFilters {
    return this.eventsStore.appState.filters
  }

  private get appliedCategories(): CategoryName[] {
    return this.categoryFilterStore.selectedCategories || []
  }

  private get recentlyUpdatedSavedFilter(): IRecentlyUpdatedSavedFilter {
    if (!this.appliedCategories.includes(CategoryName.RecentlyUpdated)) {
      return null
    }
    const mode = this.filters.selectedRecentlyUpdatedMode
    const hasDates = mode !== RecentlyUpdatedMode.LastP6Update
    const startDate = hasDates ? this.filters.recentlyUpdatedStartDate : null
    const endDate = hasDates ? this.filters.recentlyUpdatedEndDate : null
    return {
      mode,
      startDate,
      endDate,
    }
  }

  private get activityStatusFilter(): ActivityStatus {
    return this.filters.selectedActivityStatusMode
  }

  public get customFilters(): CustomActivityListFilter[] {
    return this.customActivityListFiltersStore.list
  }

  public get selectedCustomFilter(): CustomActivityListFilter {
    const filterId = this.selectedCustomFilterId || this.appliedCustomFilterId
    return this.customActivityListFiltersStore.byId.get(filterId)
  }

  public get selectedActivityPreset(): ActivityPreset {
    const filterId =
      this.selectedActivityPresetId ||
      this.eventsStore.appState.appliedActivityPresetId
    return this.activityPresetsStore.getById(filterId)
  }

  @computed
  public get filtersCount(): number {
    if (!this.customFilterInstance) {
      return 0
    }

    const { filtersByFilterType } = this.customFilterInstance
    return Object.values(filtersByFilterType).reduce(
      (sum, codes) => sum + codes.length,
      0,
    )
  }

  @computed
  public get filtersDescriptions(): string[] {
    const { byId: codesMap } = this.activityCodesStore

    if (!this.customFilterInstance) {
      return []
    }
    const { filtersByFilterType, appliedCategories } = this.customFilterInstance

    const filtersDescriptions = Object.keys(filtersByFilterType)
      .filter(filterType => {
        const values = filtersByFilterType[filterType]
        if (values.length) {
          return values[0].length
        }
      })
      .map(filterType => {
        const values = filtersByFilterType[filterType]
        if (filterType === FilterType.Company) {
          return values
            .map(id => this.companiesStore.getCompanyNameById(id))
            .join(', ')
        }

        return values.map(id => codesMap.get(id)?.name || '').join(', ')
      })

    if (appliedCategories && appliedCategories.length) {
      filtersDescriptions.push(
        appliedCategories
          .map(value =>
            categoryOptions
              .find(option => option.id === value)
              .getDisplayName(),
          )
          .join(', '),
      )
    }
    return filtersDescriptions
  }

  @action.bound
  public deleteCustomFilter() {
    if (!this.selectedCustomFilter) {
      return
    }

    this.customActivityListFiltersStore.removeOne(this.selectedCustomFilter.id)
    if (this.selectedCustomFilter.id === this.appliedCustomFilterId) {
      this.resetCustomFilter()
      this.resetAllFilters()
    }

    this.closeSavedFilters()
  }

  @action.bound
  public deleteActivityPreset() {
    if (!this.selectedActivityPreset) {
      return
    }

    this.activityPresetsStore.removeOne(this.selectedActivityPreset.id)

    if (
      this.selectedActivityPreset.id ===
      this.eventsStore.appState.appliedActivityPresetId
    ) {
      this.resetActivityPreset()
      this.resetAllFilters()
    }

    this.closeSavedFilters()
  }

  @action.bound
  public selectActivityPreset(event: any) {
    this.selectedActivityPresetId = event.target.value
    this.selectedCustomFilterId = null
  }

  @action.bound
  public selectActivityPresetById(id: string) {
    this.selectedActivityPresetId = id
    this.selectedCustomFilterId = null
  }

  @action.bound
  public onActivityPresetNameChange(event) {
    this.editablePresetName = event.target.value || ''
  }

  @action.bound
  public resetActivityPreset() {
    this.selectedActivityPresetId = null
    this.eventsStore.appState.appliedActivityPresetId = null
    this.closeSavedFilters()
  }

  @action.bound
  public editCustomFilter() {
    if (!this.selectedCustomFilter) {
      return
    }
    this.customFilterInstance = this.selectedCustomFilter
    this.editableFilterName = this.selectedCustomFilter.name
    this.editableFilterIsPublic = this.selectedCustomFilter.isPublic
    this.filterDialogMode = CustomFilterDialogModes.Edit
    this.shouldSaveFilterDialogShow = true
  }

  @action.bound
  public editActivityPreset() {
    if (!this.selectedActivityPreset) {
      return
    }

    this.presetInstance = this.selectedActivityPreset
    this.editablePresetName = this.selectedActivityPreset.name
    this.shouldActivityPresetDialogShow = true
  }

  @action.bound
  public saveCustomFilter() {
    this.customFilterInstance.name = this.editableFilterName
    this.customFilterInstance.isPublic = this.editableFilterIsPublic
    this.customActivityListFiltersStore.saveOne(this.customFilterInstance)
    this.closeSavedFilters()
  }

  @action.bound
  public saveActivityPreset() {
    this.presetInstance.name = this.editablePresetName
    this.activityPresetsStore.editOne(
      this.presetInstance.id,
      this.presetInstance.name,
      this.presetInstance.activities,
    )
    this.closeSavedFilters()
  }

  @action.bound
  public toggleActivityPresetDialog() {
    this.shouldActivityPresetDialogShow = !this.shouldActivityPresetDialogShow
  }

  @action.bound
  public onSaveFiltersClicked() {
    const codeFilterValuesByTypeMap: Map<string, string[]> = new Map()

    this.enabledFilterTypes.forEach(filterType => {
      codeFilterValuesByTypeMap.set(
        filterType,
        this.getSelectedFilterValues(filterType),
      )
    })
    this.createCustomFilterInstance(
      codeFilterValuesByTypeMap,
      this.appliedCategories,
      this.recentlyUpdatedSavedFilter,
      this.activityStatusFilter,
    )
    this.showSaveFilterDialog()
  }

  @action.bound
  public applyCustomFilter() {
    const {
      filtersByFilterType,
      recentlyUpdatedSavedFilter,
      appliedCategories = [],
      statusFilterMode,
    } = this.selectedCustomFilter

    Object.keys(filtersByFilterType).forEach(filterType => {
      const filterValues = this.retrieveFormattedFilterValues(
        filtersByFilterType[filterType].slice(),
        filterType,
      )

      this.eventsStore.dispatch(
        SET_FILTER_SELECTION,
        filterType,
        filterValues,
        filterType,
      )
    })

    this.categoryFilterStore.selectedCategories = appliedCategories
    if (recentlyUpdatedSavedFilter) {
      const { mode, startDate, endDate } = recentlyUpdatedSavedFilter
      this.recentlyUpdatedStore.applySelectorDateMode(
        mode,
        startDate && new Date(startDate),
        endDate && new Date(endDate),
      )
    }
    if (statusFilterMode) {
      this.eventsStore.appState.filters.selectedActivityStatusMode =
        statusFilterMode
    }
    this.categoryFilterStore.onApplyClick()
    this.eventsStore.appState.filters.appliedCustomFilterId =
      this.selectedCustomFilterId
    this.closeSavedFilters()
  }

  @action.bound
  public applyActivityPreset() {
    this.eventsStore.dispatch(RESET_ALL_FILTERS)
    this.eventsStore.appState.appliedActivityPresetId =
      this.selectedActivityPresetId
    this.closeSavedFilters()
  }

  public getSelectedFilterValues(filterType: string) {
    const { selectedFilterOptions } = this.filters.locationsMap[filterType]

    const values = Array.from(selectedFilterOptions.keys())
    const index = values.findIndex(
      element =>
        element === UNASSIGNED || element === `unassigned-${filterType}`,
    )

    if (index === -1) {
      return values
    }

    values[index] = UNASSIGNED_FILTER_VALUE
    return values
  }

  protected createCustomFilterInstance(
    codeFilterValuesByTypeMap: Map<string, string[]>,
    appliedCategories?: CategoryName[],
    recentlyUpdatedSavedFilter?: IRecentlyUpdatedSavedFilter,
    activityStatusFilter?: ActivityStatus,
  ) {
    const codesByType = {}
    codeFilterValuesByTypeMap.forEach(
      (codeIds, typeId) => (codesByType[typeId] = codeIds),
    )

    this.customFilterInstance =
      this.customActivityListFiltersStore.createFromValues(
        codesByType,
        this.editableFilterIsPublic,
        appliedCategories,
        recentlyUpdatedSavedFilter,
        activityStatusFilter,
      )
  }
}
