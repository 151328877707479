import * as React from 'react'

import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import WorkflowDiagramChangeLine from '../../WorkflowDiagramWrapper/components/WorkflowDiagramChangeLine'
import WorkflowDiagramLine, {
  DiagramLineType,
} from '../../WorkflowDiagramWrapper/components/WorkflowDiagramLine'
import {
  ActionState,
  BallInCourtState,
  IntermediateState,
  TerminationState,
} from '../../WorkflowDiagramWrapper/components/WorkflowDiagramState'

// localization: translated

export interface IBaseStepDiagramProps {
  isAutoActivationEnabled?: boolean
  isLastStep?: boolean
  includeChangeAction?: boolean
  hasStartStep?: boolean
  intermediateStateText?: string
  shouldSkipState?: boolean
  bottomBlockActionText?: string
  bottomBlockTerminationText?: string
}

const CHANGE_LINE_OFFSET = -24
const CHANGE_LINE_WIDTH = 12

@observer
export default class BaseStepDiagram extends React.Component<IBaseStepDiagramProps> {
  public render() {
    const { isLastStep, intermediateStateText, shouldSkipState, children } =
      this.props

    return (
      <div className="row relative">
        {this.changeActionBlock}

        {children}

        {isLastStep ? (
          <TerminationState content={Localization.translator.done} />
        ) : (
          <>
            {!shouldSkipState && (
              <IntermediateState content={intermediateStateText} />
            )}
            <WorkflowDiagramLine />
          </>
        )}
      </div>
    )
  }

  private get changeActionBlock(): JSX.Element {
    const {
      includeChangeAction,
      isLastStep,
      bottomBlockActionText,
      bottomBlockTerminationText,
    } = this.props

    if (!includeChangeAction) return null

    return (
      <>
        <WorkflowDiagramChangeLine
          width={isLastStep && CHANGE_LINE_WIDTH}
          isLastStep={isLastStep}
        />
        <div className="col y-center x-center">
          <WorkflowDiagramLine
            diagramLineType={DiagramLineType.VerticalLeftLine}
            className="absolute"
            top={CHANGE_LINE_OFFSET}
            left={CHANGE_LINE_OFFSET}
          />
          <ActionState
            className="col absolute x-center change-action-state"
            content={Localization.translator.change_verb}
          />
          <div className="absolute change-action-line-container">
            <WorkflowDiagramLine diagramLineType={DiagramLineType.Vertical} />
          </div>

          <BallInCourtState className="ml2" />

          {bottomBlockActionText && (
            <div className="col absolute x-center bottom-step-block">
              <WorkflowDiagramLine diagramLineType={DiagramLineType.Vertical} />
              <ActionState content={bottomBlockActionText} />

              {bottomBlockTerminationText && (
                <>
                  <WorkflowDiagramLine
                    diagramLineType={DiagramLineType.Vertical}
                    shouldIncludeArrow
                  />
                  <TerminationState content={bottomBlockTerminationText} />
                </>
              )}
            </div>
          )}
        </div>
        <WorkflowDiagramLine />
      </>
    )
  }
}
