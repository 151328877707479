import { action } from 'mobx'

import AssociationItem from '~/client/src/shared/models/AssociationItem/AssociationItem'
import IFollowingsStore from '~/client/src/shared/models/IFollowingsStore'
import BaseFollowingsStore from '~/client/src/shared/stores/BaseFollowings.store'

import Localization from '../../localization/LocalizationManager'
import * as e from '../EventStore/eventConstants'

export default class ActivityFollowingsStore
  extends BaseFollowingsStore
  implements IFollowingsStore
{
  protected readonly saveEventName = e.SAVE_ACTIVITY_FOLLOWINGS
  protected readonly deleteEventName = e.DELETE_ACTIVITY_FOLLOWINGS

  protected get byId(): Map<string, AssociationItem> {
    return this.eventsStore.appState.activityFollowings
  }

  protected getSubscribeMessage(usersCount: number, entitiesCount: number) {
    return Localization.translator.usersSubscribedTo.activities(
      usersCount,
      entitiesCount,
    )
  }

  protected getUnsubscribeMessage(usersCount: number, entitiesCount: number) {
    return Localization.translator.usersUnSubscribedFrom.activities(
      usersCount,
      entitiesCount,
    )
  }

  @action.bound
  public toggleBulkFollowings(activityP6Codes: string[]) {
    const unfollowedActivitiesIds = activityP6Codes.filter(
      actId => !this.isEntityFollowed(actId),
    )

    if (unfollowedActivitiesIds.length) {
      return this.followEntities(unfollowedActivitiesIds)
    }

    return this.unfollowEntities(activityP6Codes)
  }
}
