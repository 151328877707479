import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IDeliveryControl from '~/client/src/shared/models/IDeliveryControl'

import BaseActionButton from '../../../BaseActionButton/BaseActionButton'
import ConcreteDirectOrderSummary from '../../../ConcreteDirectSummary/ConcreteDirectOrderSummary/ConcreteDirectOrderSummary'
import FileInputBase from '../../../FileInput/FileInput'
import DeliveryDetailsStore, { getFieldId } from '../../DeliveryDetails.store'
import DeliveryFormFieldWrapper from '../DeliveryFormFieldWrapper/DeliveryFormFieldWrapper'

// localization: translated

interface IProps {
  FileInputType: typeof FileInputBase
  materialFields: IDeliveryControl[]

  store: DeliveryDetailsStore
}

@observer
export default class DeliveryMaterialControls extends React.Component<IProps> {
  public render() {
    const { store, FileInputType, materialFields } = this.props
    const {
      setSelectedFieldId,
      onFieldValueChange,
      increaseMaterialsSectionsCount,
      handleMaterialSectionClick,
      canIncreaseMaterialsCount,
      selectedConcreteDirectOrder,
      canRemoveMaterials,
    } = store

    if (!materialFields?.length) {
      return null
    }

    return (
      <>
        {materialFields.map(field => {
          const isTitleField = field.id === FieldIds.MATERIALS_SECTION
          const key = getFieldId(field.id, field.index)

          if (isTitleField) {
            return (
              <div key={key} className="x-between pa10 row">
                <div
                  className={classList({
                    'text huge bold': true,
                    'grey-lighter': field.isRemoved,
                  })}
                >
                  {Localization.translator.xMaterial(field.index)}
                </div>
                <div
                  className="no-grow pointer"
                  onClick={handleMaterialSectionClick.bind(null, field.index)}
                >
                  {field.isRemoved ? (
                    <BaseActionButton
                      className="gray-theme"
                      title={Localization.translator.restore}
                      isEnabled
                    />
                  ) : (
                    canRemoveMaterials && <Icons.CrossGrey className="row" />
                  )}
                </div>
              </div>
            )
          }

          return (
            <DeliveryFormFieldWrapper
              key={key}
              {...field}
              onChange={onFieldValueChange}
              onSelectClick={setSelectedFieldId}
              FileInputType={FileInputType}
            />
          )
        })}
        {canIncreaseMaterialsCount && (
          <div className="row nowrap x-end no-select px8">
            <div
              className="no-grow py6 px10 mb10 ba-light-input-border pointer text large bold light brada4"
              onClick={increaseMaterialsSectionsCount}
            >
              + {Localization.translator.addMaterial}
            </div>
          </div>
        )}
        <ConcreteDirectOrderSummary
          className="input-wrapper col pa8"
          concreteDirectOrder={selectedConcreteDirectOrder}
        />
      </>
    )
  }
}
