import * as turf from '@turf/turf'
import { action, computed, observable } from 'mobx'
import { LngLat } from 'react-map-gl'

import {
  IGeoJson2DGeographicCoordinates,
  ISitemapItemShapeCoordinates,
  SitemapItemShapeType,
} from '~/client/graph'

export default class RectangleShapeCoordinates
  implements ISitemapItemShapeCoordinates
{
  public type: SitemapItemShapeType = SitemapItemShapeType.Rectangle
  @observable public coordinates: IGeoJson2DGeographicCoordinates[]
  @observable public isClosed: boolean

  public constructor(
    coordinates: IGeoJson2DGeographicCoordinates[],
    isClosed: boolean,
  ) {
    this.coordinates = coordinates
    this.isClosed = isClosed
  }

  public getBoundingBoxFeature(
    properties: GeoJSON.GeoJsonProperties,
  ): GeoJSON.Feature {
    return {
      type: 'Feature',
      properties,
      geometry: {
        type: 'Polygon',
        coordinates: [this.boundingBoxFeatureCoords],
      },
    }
  }

  public getBoundingBoxPointsFeatures(
    properties: GeoJSON.GeoJsonProperties,
  ): GeoJSON.Feature[] {
    return this.boundingBoxFeatureCoords.slice(0, -1).map((c, idx) => ({
      type: 'Feature',
      properties: {
        idx,
        ...properties,
      },
      geometry: {
        type: 'Point',
        coordinates: c,
      },
    }))
  }

  @computed
  private get boundingBoxFeatureCoords(): number[][] {
    const { minLng, minLat, maxLng, maxLat } = this.minMaxValues
    return [
      [minLng, maxLat],
      [maxLng, maxLat],
      [maxLng, minLat],
      [minLng, minLat],
      [minLng, maxLat],
    ]
  }

  @computed
  private get minMaxValues() {
    let maxLng = this.shapeFeatureCoordinates[0][0]
    let maxLat = this.shapeFeatureCoordinates[0][1]
    let minLng = this.shapeFeatureCoordinates[0][0]
    let minLat = this.shapeFeatureCoordinates[0][1]
    this.shapeFeatureCoordinates.forEach(c => {
      if (c[0] > maxLng) {
        maxLng = c[0]
      }
      if (c[1] > maxLat) {
        maxLat = c[1]
      }
      if (c[0] < minLng) {
        minLng = c[0]
      }
      if (c[1] < minLat) {
        minLat = c[1]
      }
    })
    return { minLng, minLat, maxLng, maxLat }
  }

  @computed
  public get centerPoint() {
    const { minLng, minLat, maxLng, maxLat } = this.minMaxValues
    return [minLng + (maxLng - minLng) / 2, minLat + (maxLat - minLat) / 2]
  }

  public getShapePolygonFeature(
    properties: GeoJSON.GeoJsonProperties,
  ): GeoJSON.Feature<GeoJSON.Polygon> {
    return {
      type: 'Feature',
      properties,
      geometry: {
        type: 'Polygon',
        coordinates: [this.shapeFeatureCoordinates],
      },
    }
  }

  public getShapeLineStringFeature(
    properties: GeoJSON.GeoJsonProperties,
  ): GeoJSON.Feature<GeoJSON.LineString> {
    return {
      type: 'Feature',
      properties,
      geometry: {
        type: 'LineString',
        coordinates: this.shapeFeatureCoordinates,
      },
    }
  }

  public moveShapePoint(index: number, moveCoords: LngLat): void {
    const newPoint = {
      lat: moveCoords.lat,
      lng: moveCoords.lng,
    } as LngLat
    const pointDiff = {
      lat: moveCoords.lat - this.coordinates[index].latitude,
      lng: moveCoords.lng - this.coordinates[index].longitude,
    } as LngLat

    const oppositeIndex = (index + 2) % 4
    const adjustedIndex1 = (index + 1) % 4
    const adjustedIndex2 = (index + 3) % 4

    const oppositePoint = {
      lat: this.coordinates[oppositeIndex].latitude,
      lng: this.coordinates[oppositeIndex].longitude,
    } as LngLat
    const adjustedPoint1 = {
      lat: this.coordinates[adjustedIndex1].latitude,
      lng: this.coordinates[adjustedIndex1].longitude,
    } as LngLat
    const adjustedPoint2 = {
      lat: this.coordinates[adjustedIndex2].latitude,
      lng: this.coordinates[adjustedIndex2].longitude,
    } as LngLat
    const adjustedPointM1 = {
      lat: this.coordinates[adjustedIndex1].latitude + pointDiff.lat,
      lng: this.coordinates[adjustedIndex1].longitude + pointDiff.lng,
    } as LngLat
    const adjustedPointM2 = {
      lat: this.coordinates[adjustedIndex2].latitude + pointDiff.lat,
      lng: this.coordinates[adjustedIndex2].longitude + pointDiff.lng,
    } as LngLat

    const int1 = this.findIntersection(
      [newPoint, adjustedPointM1],
      [oppositePoint, adjustedPoint1],
    )
    const int2 = this.findIntersection(
      [newPoint, adjustedPointM2],
      [oppositePoint, adjustedPoint2],
    )

    this.coordinates[index] = {
      latitude: moveCoords.lat,
      longitude: moveCoords.lng,
    }
    this.coordinates[adjustedIndex1] = {
      latitude: int1.lat,
      longitude: int1.lng,
    }
    this.coordinates[adjustedIndex2] = {
      latitude: int2.lat,
      longitude: int2.lng,
    }
  }

  @action.bound
  private getLineEquation(p1: LngLat, p2: LngLat): [number | null, number] {
    if (p1.lng === p2.lng) {
      // Vertical line
      return [null, p1.lng] // Return null slope and the x coordinate
    }

    const slope = (p2.lat - p1.lat) / (p2.lng - p1.lng)
    const intercept = p1.lat - slope * p1.lng
    return [slope, intercept]
  }

  @action.bound
  private findIntersection(
    line1: [LngLat, LngLat],
    line2: [LngLat, LngLat],
  ): LngLat | null {
    const [p1, p2] = line1
    const [p3, p4] = line2

    const [m1, b1] = this.getLineEquation(p1, p2)
    const [m2, b2] = this.getLineEquation(p3, p4)

    if (m1 === null && m2 === null) {
      // Both lines are vertical and at the same x-coordinate
      if (p1.lng === p3.lng) {
        return {
          lng: p1.lng,
          lat: (Math.max(p1.lat, p2.lat) + Math.min(p3.lat, p4.lat)) / 2,
        } as LngLat // Same line
      }
      return null // Parallel vertical lines
    }

    if (m1 === null) {
      // Line 1 is vertical
      const x = p1.lng
      const y = m2 * x + b2
      return { lng: x, lat: y } as LngLat
    }

    if (m2 === null) {
      // Line 2 is vertical
      const x = p3.lng
      const y = m1 * x + b1
      return { lng: x, lat: y } as LngLat
    }

    // For non-vertical lines
    if (m1 === m2) {
      return null // Parallel lines
    }

    const x = (b2 - b1) / (m1 - m2)
    const y = m1 * x + b1

    return { lng: x, lat: y } as LngLat
  }

  public rotate(startPoint: LngLat, endPoint: LngLat) {
    const normPoint = [this.centerPoint[0], this.centerPoint[1] - 0.01]
    const startAngle = turf.angle(
      [startPoint.lng, startPoint.lat],
      this.centerPoint,
      normPoint,
    )
    const endAngle = turf.angle(
      [endPoint.lng, endPoint.lat],
      this.centerPoint,
      normPoint,
    )
    const newShape = turf.transformRotate(
      this.getShapePolygonFeature({}),
      endAngle - startAngle,
    )
    this.coordinates = (newShape.geometry.coordinates as number[][][])[0].map(
      c => ({ latitude: c[1], longitude: c[0] }),
    )
  }

  @computed
  public get shapeFeatureCoordinates() {
    return [...this.coordinates.slice(0, 4), this.coordinates[0]]?.map(c => [
      c.longitude,
      c.latitude,
    ])
  }

  @computed
  public get shapeEditorPointCoordinates() {
    return this.shapeFeatureCoordinates.slice(0, 4)
  }

  public copy() {
    return new RectangleShapeCoordinates(this.coordinates, this.isClosed)
  }
}
