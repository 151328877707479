import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import Activity from '~/client/src/shared/models/Activity'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

interface IProps {
  activity: Activity
  dataKey: string
  common?: DesktopCommonStore
  state?: DesktopInitialState
  projectDateStore?: ProjectDateStore
  statusUpdatesStore?: StatusUpdatesStore
}

@inject('common', 'state', 'projectDateStore', 'statusUpdatesStore')
@observer
export default class ActivityDateCell extends React.Component<IProps> {
  public render() {
    const { activity, dataKey, projectDateStore, statusUpdatesStore } =
      this.props

    const key = dataKey.split('.').pop()
    const activityDate = activity[key](projectDateStore, statusUpdatesStore)
    const date = projectDateStore.isValidDate(activityDate) ? activityDate : ''

    return (
      <div
        className="text cell-wrapper px10 bb-cool-grey pointer"
        onClick={this.openBulkUpdateView}
      >
        {date ? <div>{date}</div> : <div className="status brada10">+</div>}
      </div>
    )
  }

  @action.bound
  public openBulkUpdateView() {
    const { activity, common } = this.props
    const { userActiveProjectSettings, activityList } = this.props.state
    const { selectedCompany } = activityList

    const { isActivityUpdateAvailable } = userActiveProjectSettings

    if (isActivityUpdateAvailable) {
      common.displayBulkStatusUpdateView(activity, selectedCompany)
    }
  }
}
