import * as React from 'react'

import TreeNode from './TreeNode'

import './Tree.scss'

interface IProps {
  content: ITreeNode[]
  onNodeExpand(node: ITreeNode): void
  onNodeChecked(node: ITreeNode): void
}

export interface ITreeNode {
  component: React.ReactElement<any>
  isExpanded?: boolean
  children?: ITreeNode[]
  parent?: ITreeNode
  isCheckboxDisplayed?: boolean
  isChecked?: boolean
  level: number
  dataId: string
  hasOnly?: boolean
  onOnlyClicked?
}

export default class Tree extends React.Component<IProps> {
  public render() {
    const { content, onNodeExpand, onNodeChecked } = this.props

    return (
      <div className="tree">
        {content.map((node, idx) => (
          <TreeNode
            node={node}
            key={idx}
            onNodeExpand={onNodeExpand}
            onNodeChecked={onNodeChecked}
          />
        ))}
      </div>
    )
  }
}
