import * as React from 'react'

import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import DesktopActivitiesSavedFiltersStore from '../../../ActivitiesSavedFilters/DesktopActivitiesSavedFilters.store'

// localization: translated

interface IProps {
  desktopSavedFiltersStore: DesktopActivitiesSavedFiltersStore
}

export default class ActivityPresetHeader extends React.Component<IProps> {
  public render() {
    const { toggleActivityPresetDialog } = this.props.desktopSavedFiltersStore

    return (
      <div className="row px10 mt5 mb10">
        <div className="row pl5" onClick={toggleActivityPresetDialog}>
          <div className="row no-grow pointer">
            <Icons.BackArrow className="row" />
          </div>
          <div className="ml10 text extra-large">
            {Localization.translator.editActivitySet}
          </div>
        </div>
      </div>
    )
  }
}
