import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import User from '~/client/src/shared/models/User'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import getUserName from '~/client/src/shared/utils/GetUserName'

export interface IProps {
  state?: DesktopInitialState
  projectDateStore?: ProjectDateStore
  projectMembersStore?: ProjectMembersStore
}

@inject('state', 'projectDateStore', 'projectMembersStore')
@observer
export default class PresentationLastUpdated extends React.Component<IProps> {
  public render() {
    return <div className="text light large right">{this.changedString}</div>
  }

  private get changedString() {
    let result = Localization.translator.lastUpdated + ': '
    if (this.changingDate && !this.changedBy) {
      result += ' - '
      return result
    }

    let date = ''
    const {
      projectDateStore: { getMonthDayYearAndTimeToDisplay },
    } = this.props
    if (this.changingDate) {
      date = getMonthDayYearAndTimeToDisplay(this.changingDate)
    }

    if (this.changedBy) {
      const userName = getUserName(this.changedBy)
      result += Localization.translator.dateByName(date, userName)
    } else {
      result += date
    }

    return result
  }

  private get changingDate(): Date {
    const { updatedAt } = this.props.state.presentationSettings
    if (updatedAt) {
      return new Date(updatedAt)
    }
  }

  private get changedBy(): User {
    const {
      state: { presentationSettings },
      projectMembersStore,
    } = this.props

    return projectMembersStore.getById(presentationSettings.updatedBy)
  }
}
