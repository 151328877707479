import * as React from 'react'

import { classList } from 'react-classlist-helper'

import './OpeningIndicatorLabel.scss'

interface IProps {
  isOpen: boolean
  openLabel: string
  closeLabel: string
}

export default class OpeningIndicatorLabel extends React.PureComponent<IProps> {
  public render() {
    return (
      <label
        className={classList({
          'opening-indicator text large px4 py2 brada3': true,
          open: this.props.isOpen,
        })}
      >
        {this.labelText}
      </label>
    )
  }

  private get labelText(): string {
    const { closeLabel, openLabel, isOpen } = this.props
    return isOpen ? openLabel : closeLabel
  }
}
