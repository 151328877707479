import * as React from 'react'

import { Icon, Intent, Switch } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import { LocationType } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import * as TagIcon from '~/client/src/shared/components/TagIcon'

import Localization from '../../../localization/LocalizationManager'
import { ICollectionItem } from '../../../stores/domain/Calendars.store'
import { UNASSIGNED } from '../../../utils/ZoneLevelLocationConstants'
import MenuCloser from '../../MenuCloser'
import SitemapAttributeTag from '../../SitemapAttributeTag/SitemapAttributeTag'

import Colors from '~/client/src/shared/theme.module.scss'

import './CalendarGroupByMenu.scss'

interface IProps {
  selectedType: LocationType
  onTypeChange: (type: LocationType) => void
  areEmptyGroupsHidden: boolean
  toggleHideEmpty: () => void
  filteredAttributes?: ICollectionItem[]
  updateSortedCollection?: (collection: ICollectionItem[]) => void
}

const hideEmptyGroups = 'Hide empty groups'
const visibleGroups = 'Visible Groups'
const none = 'None'

const SortableItem = SortableElement(({ item }) => (
  <div className="sortable-item full pa5">{item}</div>
))

const SortableList = SortableContainer(({ items, areEmptyGroupsHidden }) => {
  return (
    <div
      className={classList({
        col: true,
        'inactive-element': !areEmptyGroupsHidden,
      })}
    >
      {items.map((item, index) => (
        <SortableItem key={`item-${index}`} index={index} item={item} />
      ))}
    </div>
  )
})

interface IAttributeItem {
  attributeType: LocationType
  icon: JSX.Element
}

const ATTRIBUTES_TO_SHOW: IAttributeItem[] = [
  {
    attributeType: LocationType.Zone,
    icon: <TagIcon.Zone color={Colors.neutral70} className="no-grow mr5" />,
  },
  {
    attributeType: LocationType.Building,
    icon: <TagIcon.Building color={Colors.neutral70} className="no-grow mr5" />,
  },
  {
    attributeType: LocationType.Area,
    icon: <TagIcon.Area color={Colors.neutral70} className="no-grow mr5" />,
  },
  {
    attributeType: LocationType.Gate,
    icon: <TagIcon.Gate color={Colors.neutral70} className="no-grow mr5" />,
  },
  {
    attributeType: LocationType.Level,
    icon: <TagIcon.Level color={Colors.neutral70} className="no-grow mr5" />,
  },
  {
    attributeType: LocationType.Route,
    icon: <TagIcon.Route color={Colors.neutral70} className="no-grow mr5" />,
  },
]

@inject('projectDateStore')
@observer
export default class CalendarGroupByMenu extends React.Component<IProps> {
  @observable private isMenuOpen: boolean = false
  public render() {
    const { selectedType, areEmptyGroupsHidden, toggleHideEmpty } = this.props

    const selectedAttribute = ATTRIBUTES_TO_SHOW.find(
      attribute => attribute.attributeType === selectedType,
    )

    return (
      <div className="col py10 group-by-menu relative modal-shadow">
        <div className="row px10">
          <div className="text large lp035">
            {Localization.translator.groupBy}
          </div>
          <div
            onClick={this.toggleSelect}
            className="row px10 py5 text large relative bg-light-cool-grey pointer"
          >
            {selectedAttribute.icon}
            <div>{selectedAttribute.attributeType}</div>
            <Icon icon={IconNames.CARET_DOWN} className="no-grow" />
            {this.isMenuOpen && (
              <MenuCloser
                closeMenu={this.toggleSelect}
                className="attribute-type-select py5"
              >
                {ATTRIBUTES_TO_SHOW.map(this.renderOption)}
              </MenuCloser>
            )}
          </div>
        </div>
        <div className="row pa10 bb-light-input-border">
          <div className="text large lp035">{hideEmptyGroups}</div>
          <Switch
            className="primary-blue-switch bp3-align-right no-outline-container mr25"
            checked={areEmptyGroupsHidden}
            onChange={toggleHideEmpty}
          />
        </div>
        {this.renderVisibleGroups()}
      </div>
    )
  }

  private renderOption = (
    { attributeType, icon }: IAttributeItem,
    index: number,
  ) => {
    const isSelected = this.props.selectedType === attributeType
    return (
      <div
        className={classList({
          'row px10 py5 text large relative': true,
          'unclickable-element': isSelected,
          pointer: !isSelected,
          'bb-light-cool-grey': index !== ATTRIBUTES_TO_SHOW.length - 1,
        })}
        key={attributeType as string}
        onClick={this.onChange.bind(null, attributeType)}
      >
        {icon}
        <div>{attributeType}</div>
        {isSelected && (
          <Icon
            icon={IconNames.TICK}
            intent={Intent.PRIMARY}
            className="no-grow"
          />
        )}
      </div>
    )
  }

  private toggleSelect = () => {
    this.isMenuOpen = !this.isMenuOpen
  }

  private renderVisibleGroups() {
    const { areEmptyGroupsHidden } = this.props

    return (
      <div className="col pa10 attributes-list relative">
        <div className="row">{visibleGroups}</div>
        <div className="col overflow-auto full-height sortable-list-holder">
          <SortableList
            items={this.items}
            onSortEnd={this.onSortEnd}
            axis="y"
            distance={2}
            areEmptyGroupsHidden={areEmptyGroupsHidden}
          />
        </div>
      </div>
    )
  }

  private get items() {
    const { filteredAttributes = [] } = this.props

    return filteredAttributes.map(({ item, isHidden }, index) => {
      return (
        <div key={item?.id || UNASSIGNED} className="row">
          <Icon
            className="dragging-trigger no-grow"
            icon={IconNames.DRAG_HANDLE_VERTICAL}
          />
          <div>
            {item ? (
              <SitemapAttributeTag shouldShowAsTag={true} dataObject={item}>
                <div className="text large ellipsis">{item.name}</div>
              </SitemapAttributeTag>
            ) : (
              <div className="text large ellipsis">{none}</div>
            )}
          </div>
          <div
            className="icon-wrapper no-grow pointer"
            onClick={this.toggleItemVisibility.bind(null, index)}
          >
            {isHidden ? <Icons.EyeHide /> : <Icons.EyeView />}
          </div>
        </div>
      )
    })
  }

  private onChange = (attributeType: LocationType) => {
    this.props.onTypeChange(attributeType)
  }

  private onSortEnd = ({ oldIndex, newIndex }) => {
    const { filteredAttributes, updateSortedCollection } = this.props
    const collection = filteredAttributes.slice()
    const firstElement = collection[oldIndex]
    const secondElement = collection[newIndex]

    collection[newIndex] = firstElement
    collection[oldIndex] = secondElement

    updateSortedCollection(collection)
  }

  private toggleItemVisibility = (index: number) => {
    const { filteredAttributes, updateSortedCollection } = this.props

    const collection = filteredAttributes.slice()
    collection[index] = {
      item: collection[index].item,
      isHidden: !collection[index].isHidden,
    }
    updateSortedCollection(collection)
  }
}
