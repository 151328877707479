import { action, computed, observable } from 'mobx'

import { TagType } from '~/client/src/shared/enums/TagType'
import Company from '~/client/src/shared/models/Company'
import { ITag } from '~/client/src/shared/models/Tag'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { getBandIconNameByTagType } from '~/client/src/shared/utils/TagHelper'
import { NO_SPECIFIED } from '~/client/src/shared/utils/usefulStrings'

import getSortIndexByCompanyType from '../../constants/companyType'
import { copyArray } from '../../utils/util'

const searchRelatedTags = [TagType.Company, TagType.CompanyType]

export default class CompactCompaniesDirectoryStore {
  @observable public _searchQuery: string = ''
  @observable public pivotalSearchTag: ITag = null

  @observable private selectedCompanyIds: string[]

  public constructor(
    private readonly tagsStore: TagsStore,
    private readonly companiesStore: CompaniesStore,
    selectedCompanyIds: string[],
    private readonly companyPredicate: (company: Company) => boolean,
  ) {
    this.init(selectedCompanyIds)
  }

  @action.bound
  public init(selectedCompanyIds: string[]) {
    this.selectedCompanyIds = copyArray(selectedCompanyIds || [])
  }

  @computed
  public get filteredCompaniesByGroupedBands(): {
    [bandId: string]: Company[]
  } {
    return [...this.sortedBands, this.unspecifiedBand].reduce(
      (acc, { id: bandId }) => {
        const companies = this.getCompaniesByBandId(bandId)

        if (companies.length) {
          acc[bandId] = companies
        }

        return acc
      },
      {},
    )
  }

  @computed
  private get sortedBands(): ITag[] {
    const bands =
      this.tagsStore.tagListsWithDefaultsTagsMap[TagType.CompanyType].slice()

    return bands.sort(
      (a, b) =>
        getSortIndexByCompanyType(a.name) - getSortIndexByCompanyType(b.name),
    )
  }

  @computed
  public get searchResByTypes(): { [tagType: string]: ITag[] | Company[] } {
    const { tagListsWithDefaultsTagsMap } = this.tagsStore

    return searchRelatedTags.reduce((acc, tagType) => {
      let filteredItems = []

      if (tagType === TagType.Company) {
        filteredItems = this.companies.filter(this.filterCompanyBySearchQuery)
      } else {
        const tags = tagListsWithDefaultsTagsMap[tagType] || []
        filteredItems = tags.filter(this.filterTagBySearchQuery)
      }

      if (filteredItems.length) {
        acc[tagType] = filteredItems
      }

      return acc
    }, {})
  }

  @action.bound
  public changeSearchQuery(newSearchQuery: string) {
    this._searchQuery = newSearchQuery
  }

  @action.bound
  public resetSearch() {
    this.resetSearchQuery()
    this.resetPivotalSearchTag()
  }

  @action.bound
  public resetSearchQuery() {
    this._searchQuery = ''
  }

  @action.bound
  public resetPivotalSearchTag() {
    this.pivotalSearchTag = null
  }

  @action.bound
  public handleOnBandClick(tag: ITag) {
    this.pivotalSearchTag = { ...tag }
    this.resetSearchQuery()
  }

  public getBandItemsCount = (band: ITag): number => {
    return this.getBandItems(band).length
  }

  public getBand = (id: string): ITag => {
    const { tagStoreByTagTypeMap } = this.tagsStore

    return id === this.unspecifiedBandId
      ? this.unspecifiedBand
      : tagStoreByTagTypeMap[TagType.CompanyType].getInstanceById(id)
  }

  public getBandItems = (band: ITag): Company[] => {
    const companies = this.getCompaniesByBandId(band.id)

    if (!this.pivotalSearchTag) {
      return companies
    }

    return companies.filter(this.filterCompanyBySearchQuery)
  }

  public get searchQuery(): string {
    return this._searchQuery.toLowerCase().trim()
  }

  public get isSearchMode(): boolean {
    return !!this.searchQuery && !this.pivotalSearchTag
  }

  public get isDefaultMode(): boolean {
    return !this.searchQuery && !this.pivotalSearchTag
  }

  public get isSingleBandMode(): boolean {
    return !!this.pivotalSearchTag
  }

  @computed
  public get totalCompaniesCount(): number {
    return Object.keys(this.filteredCompaniesByGroupedBands).reduce(
      (acc, bandId) =>
        acc + this.filteredCompaniesByGroupedBands[bandId].length,
      0,
    )
  }

  private get companies(): Company[] {
    const companies = this.companiesStore.getAvailableCompsWithPrevSelectedOnes(
      this.selectedCompanyIds,
    )

    return this.companyPredicate
      ? companies.filter(this.companyPredicate)
      : companies
  }

  private filterCompanyBySearchQuery = (company: Company): boolean => {
    const { businessEmail, name, typeTags } = company
    const typeTagNames = this.companiesStore
      .getCompanyTypeTagsByIds(typeTags)
      .map(tag => tag.value)
      .join()

    return [typeTagNames, businessEmail, name].some(str =>
      str?.toLowerCase().includes(this.searchQuery),
    )
  }

  private filterTagBySearchQuery = ({ name }: ITag): boolean => {
    return name.toLowerCase().includes(this.searchQuery)
  }

  public get unspecifiedBand(): ITag {
    return {
      id: this.unspecifiedBandId,
      type: TagType.CompanyType,
      name: `[${NO_SPECIFIED}]`,
      iconName: getBandIconNameByTagType(TagType.CompanyType),
      color: '',
    }
  }

  private get unspecifiedBandId(): string {
    return TagsStore.getUnspecifiedTagIdByTagType(TagType.CompanyType)
  }

  private getCompaniesByBandId = (bandId: string): Company[] => {
    const companies = this.companies.filter(c => c.typeTags.includes(bandId))

    return companies.sort((a, b) => a.name.localeCompare(b.name))
  }
}
