import * as React from 'react'

import { classList } from 'react-classlist-helper'

interface ITextProps {
  text?: string
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  bold: boolean
  className?: string
  children?: any
}

/**
 * Deprecated in favor of ~/client/src/shared/components/Text.tsx
 */
export default function Text({
  size = 'm',
  bold = false,
  className = '',
  children = null,
}: ITextProps) {
  return (
    <div
      className={classList({
        text: true,
        'extra-small': size === 'xs',
        small: size === 's',
        large: size === 'l',
        'extra-large': (size = 'xl'),
        bold: bold === true,
        'no-bold': bold !== true,
        [className]: className !== '',
      })}
    >
      {children}
    </div>
  )
}
