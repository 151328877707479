import { action, computed } from 'mobx'

import BaseModel from '~/client/src/shared/models/BaseModel'
import MobXMap from '~/client/src/shared/types/MobXMap'

import BaseStore from './Base.store'

export default abstract class BaseStoreWithById<
  T extends BaseModel<TDto>,
  TDto,
> extends BaseStore<T, TDto> {
  @computed
  public get list(): T[] {
    return Array.from(this.byId.values)
  }

  public abstract get byId(): MobXMap<T>

  @action
  public setOne(id: string, stxModel: T) {
    this.byId.set(id, stxModel)
  }

  @action
  public updateOne(id: string, dto: TDto): T {
    let localInstance = this.byId.get(id)

    if (!localInstance) {
      localInstance = this.createAnInstance(id, dto)
      this.setOne(id, localInstance)
    } else {
      localInstance.updateFromJson(dto)
    }

    return localInstance
  }

  @action
  protected internalClearList() {
    this.isDataReceived = false
    this.byId.clear()
  }
}
