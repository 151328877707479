import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { AutoSizer, List } from 'react-virtualized'

import ProjectDateStore from '../../../stores/ui/ProjectDate.store'
import CommonDatePicker, { CommonDatePickerProps } from '../CommonDatePicker'

import './InfinityCalendar.scss'

// translated

export interface IProps {
  months: Date[]
  projectDateStore?: ProjectDateStore
  height?: number
  monthHeight?: number
}

const calendarParams = {
  MONTH_HEIGHT: 280,
  PRELOAD_MONTHS_COUNT: 3,
  WEEK_CAPTION_HEIGHT: 45,
}

@inject('projectDateStore')
@observer
export default class InfinityCalendar extends React.Component<
  CommonDatePickerProps & IProps
> {
  public render() {
    const {
      months,
      height: fixedHeight,
      monthHeight,
      projectDateStore: { weekdaysShortNamesList, isSameMonth },
    } = this.props

    const scrollToIndex = months.findIndex(month =>
      isSameMonth(month, new Date()),
    )

    return (
      <div className="infinity-calendar">
        <div className="weekdays row bb-palette-grey py5 mb5 mx10">
          {weekdaysShortNamesList.map((day, index) => (
            <div key={index} className="text center grey-light">
              {day}
            </div>
          ))}
        </div>
        <AutoSizer disableHeight={!!fixedHeight}>
          {({ width, height }) => (
            <List
              height={
                (fixedHeight || height) - calendarParams.WEEK_CAPTION_HEIGHT
              }
              overscanRowCount={calendarParams.PRELOAD_MONTHS_COUNT}
              rowCount={months.length}
              rowHeight={monthHeight || calendarParams.MONTH_HEIGHT}
              rowRenderer={this.calendarRenderer}
              scrollToIndex={scrollToIndex}
              scrollToAlignment="start"
              width={width}
              data={months}
            />
          )}
        </AutoSizer>
      </div>
    )
  }

  private calendarRenderer = ({ index, key, style }) => {
    const month = this.props.months[index]
    const { getMonthAndYearToDisplay } = this.props.projectDateStore
    return (
      <div
        className="infinity-calendar-cell bb-palette-grey"
        key={key}
        style={style}
      >
        <div className="text bold huge pl10 mb5 month-label">
          {getMonthAndYearToDisplay(month)}
        </div>
        <CommonDatePicker {...this.props} monthDate={month} withoutHeader />
      </div>
    )
  }
}
