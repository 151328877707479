import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ILocationTransferPair from '~/client/src/shared/models/ILocationTransferPair'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import { NOOP } from '~/client/src/shared/utils/noop'

import { ILocationCellData } from '../MaterialsList.store'
import BaseDataCell from './BaseDataCell/BaseDataCell'
import CurrentLocationCellTable from './CurrentLocationCellTable'
import CurrentLocationStatusIcon from './CurrentLocationStatusIcon'

// localization: translated

interface IProps {
  locationCellData: ILocationCellData
  isTransferEnabled: boolean
  onNewTransferClick(
    viewModelId: string,
    selectedLocationsMap: Map<string, ILocationTransferPair>,
  ): void
  onTransferIdClick(transferId: string): void
  locationRenderer(location: LocationBase): JSX.Element
}

const FALSE = () => false

@observer
export default class CurrentLocationCell extends React.Component<IProps> {
  public static defaultProps = {
    locationCellData: {},
  }

  @observable private readonly selectedLocationsMap = new Map<
    string,
    ILocationTransferPair
  >()

  public render() {
    const { isTransferEnabled, locationCellData } = this.props
    return (
      <BaseDataCell<ILocationTransferPair>
        items={this.transferPairs}
        newItemIcon={
          <Icons.MaterialTransferForm className="row relative no-flex mr5" />
        }
        newItemText={Localization.translator.newTransfer}
        onNewItemClick={this.onNewTransferClick}
        renderActiveItems={this.renderLocationsTable}
        renderDoneItems={NOOP}
        doneFilter={FALSE}
        dateRangeFilter={NOOP}
        isNewItemBtnHidden={!isTransferEnabled}
        isNewItemBtnDisabled={!this.selectedLocationsMap.size}
        onModalClose={this.clearSelection}
      >
        <div className="row current-location-cell-value pointer h48 no-select underline-hover">
          <div className="text-ellipsis">{this.cellLabel}</div>
          <CurrentLocationStatusIcon
            transferPairs={locationCellData.locationTransferPairs}
            plannedDeliveryLocationId={
              locationCellData.plannedDeliveryLocationId
            }
            plannedInstallLocationId={locationCellData.plannedInstallLocationId}
          />
          <Icons.DownArrow
            className="bp3-icon ml5 icon-w11 icon-grey"
            element="span"
          />
        </div>
      </BaseDataCell>
    )
  }

  private get cellLabel(): string | JSX.Element {
    const { locationRenderer, locationCellData } = this.props
    const { locationTransferPairs } = locationCellData

    if (locationTransferPairs.length > 1) {
      return Localization.translator.xLocations(locationTransferPairs.length)
    }

    return locationRenderer(locationTransferPairs[0]?.location)
  }

  private renderLocationsTable = (
    transferPairs: ILocationTransferPair[],
  ): JSX.Element => {
    const {
      locationCellData,
      locationRenderer,
      onTransferIdClick,
      isTransferEnabled,
    } = this.props
    return (
      <CurrentLocationCellTable
        isTransferEnabled={isTransferEnabled}
        plannedDeliveryLocationId={locationCellData.plannedDeliveryLocationId}
        plannedInstallLocationId={locationCellData.plannedInstallLocationId}
        transferPairs={transferPairs}
        selectedLocationsMap={this.selectedLocationsMap}
        locationRenderer={locationRenderer}
        onTransferIdClick={onTransferIdClick}
      />
    )
  }

  private get transferPairs(): ILocationTransferPair[] {
    return this.props.locationCellData.locationTransferPairs.map(tp => ({
      ...tp,
      isSelected: this.selectedLocationsMap.has(tp.location.id),
    }))
  }

  private onNewTransferClick = () => {
    if (this.selectedLocationsMap.size) {
      const { onNewTransferClick, locationCellData } = this.props
      onNewTransferClick(locationCellData.id, this.selectedLocationsMap)
    }
  }

  @action.bound
  private clearSelection() {
    this.selectedLocationsMap.clear()
  }
}
