import * as React from 'react'

import { observer } from 'mobx-react'

import { IConcreteDirectPayload } from '~/client/graph'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ConcreteDirectOrder from '~/client/src/shared/models/ConcreteDirectOrder'

import ConcreteDirectPayloadTicket from './components/ConcreteDirectPayloadTicket/ConcreteDirectPayloadTicket'

// localization: translated

interface IProps {
  orderPayloads: IConcreteDirectPayload[]
  concreteDirectOrder: ConcreteDirectOrder

  className?: string
  isPayloadDetailsHidden?: boolean
}

@observer
export default class ConcreteDirectPayloadSummary extends React.Component<IProps> {
  public static defaultProps = {
    className: '',
  }

  public render() {
    const { orderPayloads, concreteDirectOrder, className } = this.props

    if (!orderPayloads) {
      return null
    }

    return (
      <div className={className}>
        <div className="row text bold line-16 bb-light-grey">
          <div className="py8 pr8 w32 no-flex">#</div>
          <div className="pa8 w48 no-flex ellipsis">
            {Localization.translator.load}
          </div>
          <div className="pa8 w48 no-flex ellipsis">
            {Localization.translator.total}
          </div>
          <div className="pa8 mw100 ellipsis">
            {Localization.translator.status}
          </div>
          <div className="py8 pl8 ellipsis">
            {Localization.translator.timeOnSite}
          </div>
        </div>
        {orderPayloads.map((payload, index) => (
          <ConcreteDirectPayloadTicket
            key={payload.id}
            payload={payload}
            payloadNumber={index + 1}
            concreteDirectOrder={concreteDirectOrder}
          />
        ))}
      </div>
    )
  }
}
