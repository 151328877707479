export function getRowsFromGroup(rowIndex: number, rows: any[], level: number) {
  const sameLevelGroupIndex =
    rows
      .slice(rowIndex)
      .findIndex(
        rowData =>
          rowData.level <= level && (rowData.category || rowData.isWbs),
      ) + 1

  return sameLevelGroupIndex
    ? rows.slice(rowIndex, rowIndex + sameLevelGroupIndex)
    : rows.slice(rowIndex)
}
