import { IWorkflowsCustomFilter, IWorkflowsFilter } from '~/client/graph'
import {
  DeleteWorkflowCustomFilterDocument,
  SaveWorkflowCustomFilterDocument,
} from '~/client/graph/operations/generated/CustomWorkflowsFilter.generated'

import CustomWorkflowsFilter from '../../types/CustomWorkflowFilter'
import BaseCustomListFiltersStore from '../BaseCustomListFilters.store'

export default class CustomWorkflowsListFiltersStore extends BaseCustomListFiltersStore<
  CustomWorkflowsFilter,
  IWorkflowsCustomFilter
> {
  protected readonly saveMutationDoc = SaveWorkflowCustomFilterDocument
  protected readonly deleteMutationDoc = DeleteWorkflowCustomFilterDocument

  public get byId(): Map<string, CustomWorkflowsFilter> {
    return this.eventsStore.appState.customWorkflowListFilters
  }

  public getInstanceFromDto(
    dto: IWorkflowsCustomFilter,
  ): CustomWorkflowsFilter {
    return CustomWorkflowsFilter.fromDto(dto)
  }

  public createFromValues(
    filtersByFilterType: IWorkflowsFilter[],
    isPublic: boolean,
    defaultForUsers: string[],
  ): CustomWorkflowsFilter {
    return new CustomWorkflowsFilter(
      null,
      '',
      isPublic,
      this.activeProjectId,
      this.activeUserId,
      defaultForUsers,
      filtersByFilterType,
    )
  }
}
