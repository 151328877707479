import * as React from 'react'

import { Radio } from '@blueprintjs/core'
import { computed } from 'mobx'
import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'

import SitemapAttributeTag from '../../../SitemapAttributeTag/SitemapAttributeTag'
import DeliveryDetailsStore from '../../DeliveryDetails.store'
import DeliveryConfirm from './DeliveryConfirm'

// localization: translated

export interface IProps {
  title: string
  isShown: boolean
  onApply: () => void
  store: DeliveryDetailsStore
}

@observer
export default class DeliveryRecurringEditConfirm extends React.Component<IProps> {
  public render() {
    const { isShown, title, store } = this.props
    const {
      clearRecurringRelatedIds,
      setRecurringRelatedIds,
      recurringRelatedIds,
    } = store
    const {
      change_verb,
      ok,
      deliveryDescriptions,
      thisDelivery,
      thisAndFutureDeliveries,
      allDeliveries,
    } = Localization.translator

    return (
      <DeliveryConfirm
        title={title || change_verb}
        isShown={isShown}
        onHide={this.onHide}
        onApply={this.onApply}
        applyButtonText={ok}
        isApplyDisabled={this.isApplyBtnDisabled}
      >
        <div className="col">
          {this.isThisDeliveryOptionShown && (
            <Radio
              checked={!recurringRelatedIds?.length}
              onChange={clearRecurringRelatedIds}
            >
              {thisDelivery}
            </Radio>
          )}
          <Radio
            checked={this.isThisAndFutureSelected}
            onChange={setRecurringRelatedIds.bind(null, false)}
          >
            {thisAndFutureDeliveries}
          </Radio>
          {this.areAllOptionsShown && (
            <Radio
              checked={this.areAllDeliveriesSelected}
              onChange={setRecurringRelatedIds.bind(null, true)}
            >
              {allDeliveries}
            </Radio>
          )}
        </div>
        {!!this.overlappedAttrByRecurringDeliveries && (
          <div className="col x-center no-grow">
            <span className="text bold large red py5">
              {deliveryDescriptions.maximumDeliveriesAreScheduled}:
            </span>
            <SitemapAttributeTag
              shouldShowAsTag={true}
              dataObject={this.overlappedAttrByRecurringDeliveries}
            >
              <span
                title={this.overlappedAttrByRecurringDeliveries.name}
                className="text large"
              >
                {this.overlappedAttrByRecurringDeliveries.name}
              </span>
            </SitemapAttributeTag>
          </div>
        )}
      </DeliveryConfirm>
    )
  }

  private onHide = () => {
    const { hideEditRecurringDialog, clearRecurringRelatedIds } =
      this.props.store

    hideEditRecurringDialog()
    clearRecurringRelatedIds()
  }

  private onApply = () => {
    if (!this.isAnyOptionChecked || this.isApplyBtnDisabled) {
      return
    }

    const {
      onApply,
      store: { hideEditRecurringDialog },
    } = this.props

    onApply()
    hideEditRecurringDialog()
  }

  @computed
  private get isThisDeliveryOptionShown(): boolean {
    return !this.props.store.isRecurringOptionsChanged
  }

  @computed
  private get isThisAndFutureSelected(): boolean {
    return this.props.store.areFollowingRecurringsSelected
  }

  @computed
  private get areAllOptionsShown(): boolean {
    const { followingRecurringDeliveries, allRecurringDeliveries } =
      this.props.store

    return allRecurringDeliveries.length !== followingRecurringDeliveries.length
  }

  @computed
  private get areAllDeliveriesSelected(): boolean {
    const { recurringRelatedIds, allRecurringDeliveries } = this.props.store

    return (
      !!allRecurringDeliveries?.length &&
      recurringRelatedIds?.length === allRecurringDeliveries.length
    )
  }

  @computed
  private get isAnyOptionChecked(): boolean {
    return (
      (this.isThisDeliveryOptionShown &&
        !this.props.store.recurringRelatedIds?.length) ||
      this.isThisAndFutureSelected ||
      (this.areAllOptionsShown && this.areAllDeliveriesSelected)
    )
  }

  @computed
  private get overlappedAttrByRecurringDeliveries(): LocationAttributeBase {
    if (!this.isThisAndFutureSelected && !this.areAllDeliveriesSelected) {
      return null
    }

    return this.props.store.overlappedAttrByRecurringDeliveries
  }

  @computed
  private get isApplyBtnDisabled(): boolean {
    return (
      !this.isAnyOptionChecked || !!this.overlappedAttrByRecurringDeliveries
    )
  }
}
