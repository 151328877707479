import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { toggleClass } from 'react-classlist-helper'
import { RouteComponentProps, withRouter } from 'react-router'

import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import {
  Content,
  Footer,
  Header,
  View,
} from '~/client/src/shared/components/Layout'
import PhotoDetailsBase from '~/client/src/shared/components/PhotoDetails/PhotoDetails'
import ActionBar from '~/client/src/shared/components/PhotoDetails/components/ActionBar/ActionBar'
import CommentsList from '~/client/src/shared/components/PhotoDetails/components/CommentsList/CommentsList'
import ImageContainer from '~/client/src/shared/components/PhotoDetails/components/ImageContainer/ImageContainer'
import LandscapeImage from '~/client/src/shared/components/PhotoDetails/components/LandscapeImage/LandscapeImage'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import PhotosStore from '~/client/src/shared/stores/domain/Photos.store'

import DesktopDetailsHeader from '../DetailsHeader/DesktopDetailsHeader'

// localization: translated

interface IProps {
  activitiesStore?: ActivitiesStore
  photosStore?: PhotosStore
  messagesStore?: MessagesStore
  state?: DesktopInitialState
  match?: any
  photoId?: string
}

@inject('activitiesStore', 'common', 'messagesStore', 'photosStore', 'state')
@observer
class DesktopPhotoDetails extends PhotoDetailsBase<
  IProps & RouteComponentProps
> {
  public componentDidMount() {
    if (window.innerWidth > window.innerHeight) {
      this.isPortrait = false
    }

    window.addEventListener('orientationchange', this.handleLayout)
  }

  public componentWillUnmount() {
    window.removeEventListener('orientationchange', this.handleLayout)
  }

  public render() {
    if (!this.photo || !this.activity || !this.caption) {
      return (
        <div className="activity-details-view">
          <div className="text bold large center pa20 bg-light-cool-grey">
            {Localization.translator.loading}...
          </div>
        </div>
      )
    }
    const { userActiveProjectSettings } = this.props.state

    const caption =
      `${Localization.translator.photo}: ` +
      `${this.caption.text} | ${this.activity.code}`
    if (this.isPortrait || this.props.state.sideBarState) {
      return (
        <View>
          <Header>
            <DesktopDetailsHeader
              caption={caption}
              backClicked={this.backClicked}
            />
          </Header>
          <Content scrollable={true}>
            <div className="activity-log">
              <ImageContainer src={this.photo.url} />
              <CommentsList
                photo={this.photo}
                showFooterLine={true}
                FileInputType={DesktopFileInput}
              />
            </div>
          </Content>
          <Footer
            className={toggleClass(
              'inactive-element',
              userActiveProjectSettings?.isPresentationUser,
            )}
          >
            <ActionBar photo={this.photo} />
          </Footer>
        </View>
      )
    } else {
      return (
        <View>
          <LandscapeImage src={this.photo.url} />
        </View>
      )
    }
  }
}

export default withRouter(DesktopPhotoDetails)
