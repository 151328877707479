import * as React from 'react'

import {
  MaterialStatus,
  getMaterialStatusDisplayName,
} from '~/client/src/shared/enums/MaterialStatus'

import './MaterialStatusIndicator.scss'

// translated

interface IMaterialStatusIndicator {
  status: MaterialStatus
}

export default class MaterialStatusIndicator extends React.PureComponent<IMaterialStatusIndicator> {
  public render() {
    const { status } = this.props

    return (
      <span className={`text large capitalize status-indicator ${status}`}>
        {getMaterialStatusDisplayName(status)}
      </span>
    )
  }
}
