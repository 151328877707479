import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IActivityAssignmentFieldsFragment = Pick<
  Types.IActivityAssignment,
  'id' | 'projectId' | 'entityId' | 'isDeleted' | 'createdAt' | 'updatedAt'
> & { recipients: Array<Types.Maybe<Pick<Types.IRecipients, 'ids' | 'type'>>> }

export type IGetActivityAssignmentListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetActivityAssignmentListQuery = {
  activityAssignments?: Types.Maybe<{
    data: Array<IActivityAssignmentFieldsFragment>
  }>
}

export type ISaveManyActivityAssignmentsMutationVariables = Types.Exact<{
  assignments:
    | Array<Types.Maybe<Types.IActivityAssignmentInput>>
    | Types.Maybe<Types.IActivityAssignmentInput>
}>

export type ISaveManyActivityAssignmentsMutation = {
  saveManyActivityAssignments?: Types.Maybe<
    Array<Types.Maybe<IActivityAssignmentFieldsFragment>>
  >
}

export type IDeleteManyActivityAssignmentsMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteManyActivityAssignmentsMutation = Pick<
  Types.IMutation,
  'deleteManyActivityAssignments'
>

export type IListenToActivityAssignmentsSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToActivityAssignmentsSubscription = {
  activityAssignment?: Types.Maybe<
    Pick<Types.IActivityAssignmentChangeEvent, 'id'> & {
      item?: Types.Maybe<IActivityAssignmentFieldsFragment>
    }
  >
}

export const ActivityAssignmentFieldsFragmentDoc = gql`
  fragment ActivityAssignmentFields on ActivityAssignment {
    id
    projectId
    entityId
    recipients {
      ids
      type
    }
    isDeleted
    createdAt
    updatedAt
  }
`
export const GetActivityAssignmentListDocument = gql`
  query GetActivityAssignmentList($projectId: ObjectId!) {
    activityAssignments(limit: 1000000, projectId: $projectId) {
      data {
        ...ActivityAssignmentFields
      }
    }
  }
  ${ActivityAssignmentFieldsFragmentDoc}
`

/**
 * __useGetActivityAssignmentListQuery__
 *
 * To run a query within a React component, call `useGetActivityAssignmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityAssignmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityAssignmentListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetActivityAssignmentListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetActivityAssignmentListQuery,
    IGetActivityAssignmentListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetActivityAssignmentListQuery,
    IGetActivityAssignmentListQueryVariables
  >(GetActivityAssignmentListDocument, options)
}
export function useGetActivityAssignmentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetActivityAssignmentListQuery,
    IGetActivityAssignmentListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetActivityAssignmentListQuery,
    IGetActivityAssignmentListQueryVariables
  >(GetActivityAssignmentListDocument, options)
}
export type GetActivityAssignmentListQueryHookResult = ReturnType<
  typeof useGetActivityAssignmentListQuery
>
export type GetActivityAssignmentListLazyQueryHookResult = ReturnType<
  typeof useGetActivityAssignmentListLazyQuery
>
export type GetActivityAssignmentListQueryResult = Apollo.QueryResult<
  IGetActivityAssignmentListQuery,
  IGetActivityAssignmentListQueryVariables
>
export const SaveManyActivityAssignmentsDocument = gql`
  mutation SaveManyActivityAssignments(
    $assignments: [ActivityAssignmentInput]!
  ) {
    saveManyActivityAssignments(activityAssignments: $assignments) {
      ...ActivityAssignmentFields
    }
  }
  ${ActivityAssignmentFieldsFragmentDoc}
`
export type ISaveManyActivityAssignmentsMutationFn = Apollo.MutationFunction<
  ISaveManyActivityAssignmentsMutation,
  ISaveManyActivityAssignmentsMutationVariables
>

/**
 * __useSaveManyActivityAssignmentsMutation__
 *
 * To run a mutation, you first call `useSaveManyActivityAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyActivityAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyActivityAssignmentsMutation, { data, loading, error }] = useSaveManyActivityAssignmentsMutation({
 *   variables: {
 *      assignments: // value for 'assignments'
 *   },
 * });
 */
export function useSaveManyActivityAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyActivityAssignmentsMutation,
    ISaveManyActivityAssignmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyActivityAssignmentsMutation,
    ISaveManyActivityAssignmentsMutationVariables
  >(SaveManyActivityAssignmentsDocument, options)
}
export type SaveManyActivityAssignmentsMutationHookResult = ReturnType<
  typeof useSaveManyActivityAssignmentsMutation
>
export type SaveManyActivityAssignmentsMutationResult =
  Apollo.MutationResult<ISaveManyActivityAssignmentsMutation>
export type SaveManyActivityAssignmentsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveManyActivityAssignmentsMutation,
    ISaveManyActivityAssignmentsMutationVariables
  >
export const DeleteManyActivityAssignmentsDocument = gql`
  mutation DeleteManyActivityAssignments($ids: [ObjectId]!) {
    deleteManyActivityAssignments(ids: $ids)
  }
`
export type IDeleteManyActivityAssignmentsMutationFn = Apollo.MutationFunction<
  IDeleteManyActivityAssignmentsMutation,
  IDeleteManyActivityAssignmentsMutationVariables
>

/**
 * __useDeleteManyActivityAssignmentsMutation__
 *
 * To run a mutation, you first call `useDeleteManyActivityAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyActivityAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyActivityAssignmentsMutation, { data, loading, error }] = useDeleteManyActivityAssignmentsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteManyActivityAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteManyActivityAssignmentsMutation,
    IDeleteManyActivityAssignmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteManyActivityAssignmentsMutation,
    IDeleteManyActivityAssignmentsMutationVariables
  >(DeleteManyActivityAssignmentsDocument, options)
}
export type DeleteManyActivityAssignmentsMutationHookResult = ReturnType<
  typeof useDeleteManyActivityAssignmentsMutation
>
export type DeleteManyActivityAssignmentsMutationResult =
  Apollo.MutationResult<IDeleteManyActivityAssignmentsMutation>
export type DeleteManyActivityAssignmentsMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteManyActivityAssignmentsMutation,
    IDeleteManyActivityAssignmentsMutationVariables
  >
export const ListenToActivityAssignmentsDocument = gql`
  subscription ListenToActivityAssignments($projectId: ObjectId!) {
    activityAssignment(projectId: $projectId) {
      id
      item {
        ...ActivityAssignmentFields
      }
    }
  }
  ${ActivityAssignmentFieldsFragmentDoc}
`

/**
 * __useListenToActivityAssignmentsSubscription__
 *
 * To run a query within a React component, call `useListenToActivityAssignmentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToActivityAssignmentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToActivityAssignmentsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToActivityAssignmentsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToActivityAssignmentsSubscription,
    IListenToActivityAssignmentsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToActivityAssignmentsSubscription,
    IListenToActivityAssignmentsSubscriptionVariables
  >(ListenToActivityAssignmentsDocument, options)
}
export type ListenToActivityAssignmentsSubscriptionHookResult = ReturnType<
  typeof useListenToActivityAssignmentsSubscription
>
export type ListenToActivityAssignmentsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToActivityAssignmentsSubscription>
