import { DefaultCompanyType } from '~/client/graph'

import Company from '../models/Company'
import ICompanyTypeTag from '../models/ICompanyTagType'

const companyTypeToSortIndexMap = {
  [DefaultCompanyType.GeneralContractorPrimary]: 1,
  [DefaultCompanyType.GeneralContractorSecondary]: 2,
  [DefaultCompanyType.Owner]: 3,
  [DefaultCompanyType.SubContractor]: 4,
  [DefaultCompanyType.TieredSubContractor]: 5,
  [DefaultCompanyType.Vendor]: 6,
}

const lastSortIndex = Object.keys(companyTypeToSortIndexMap).length + 1

function getHighestCompanyTypeTagSortIndex(
  company: Company,
  getCompanyTypeTagsByIds: (typeTags: string[]) => ICompanyTypeTag[],
) {
  const companyTypeTags = company
    ? getCompanyTypeTagsByIds(company.typeTags)
    : []
  const sortedCompanyTypeTags = companyTypeTags
    .map(tag => getSortIndexByCompanyType(tag.value))
    .sort((a, b) => a - b)

  return sortedCompanyTypeTags?.[0] || lastSortIndex
}

export function sortCompaniesByTypeAndNamePredicate(
  companyAId: string,
  companyBId: string,
  getCompanyById: (companyId: string) => Company,
  getCompanyTypeTagsByIds: (typeTags: string[]) => ICompanyTypeTag[],
) {
  const companyA = getCompanyById(companyAId)
  const companyB = getCompanyById(companyBId)

  const typeA = getHighestCompanyTypeTagSortIndex(
    companyA,
    getCompanyTypeTagsByIds,
  )
  const typeB = getHighestCompanyTypeTagSortIndex(
    companyB,
    getCompanyTypeTagsByIds,
  )

  const nameA = companyA?.name || ''
  const nameB = companyB?.name || ''

  return typeA - typeB || nameA.toLowerCase().localeCompare(nameB.toLowerCase())
}

export default function getSortIndexByCompanyType(
  cType: DefaultCompanyType | string,
) {
  return companyTypeToSortIndexMap[cType] || lastSortIndex
}

export function getSortIndexForVendorField(companyType: string) {
  switch (companyType) {
    case DefaultCompanyType.Vendor:
      return 1
    case DefaultCompanyType.GeneralContractorPrimary:
      return 2
    case DefaultCompanyType.GeneralContractorSecondary:
      return 3
    case DefaultCompanyType.Owner:
      return 4
    case DefaultCompanyType.SubContractor:
      return 5
    case DefaultCompanyType.TieredSubContractor:
      return 6
    default:
      return 7
  }
}
