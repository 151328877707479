import { action, computed, observable } from 'mobx'

import { DeliveryFilterType, IDeliveryFilter } from '~/client/graph'
import { UNASSIGNED_FILTER_OPTION } from '~/client/src/shared/components/Deliveries/DeliveriesView.store'
import DeliveryStatus, {
  formatStatusToDisplay,
} from '~/client/src/shared/constants/DeliveryStatus'
import {
  deliveryFilterTypes,
  extendedDeliveryFilterTypes,
  groupedExtendedDeliveryFilterTypes,
} from '~/client/src/shared/enums/DeliveryFilterType'
import Delivery from '~/client/src/shared/models/Delivery'
import BaseDeliveryFilterStore, {
  ISourceMap,
} from '~/client/src/shared/stores/BaseDeliveryFilter.store'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import {
  DELIVERY_RECEIVED,
  LOAD_DELIVERIES_SUCCESS,
  RESET_ALL_FILTERS,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryAssignmentsStore from '~/client/src/shared/stores/domain/DeliveryAssignments.store'
import DeliveryFollowingsStore from '~/client/src/shared/stores/domain/DeliveryFollowings.store'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'
import { enumToList } from '~/client/src/shared/utils/converters'

import { TagType } from '../../enums/TagType'
import getUserName from '../../utils/GetUserName'
import CompaniesStore from '../domain/Companies.store'
import LocationAttributesStore from '../domain/LocationAttributes.store'
import ProjectMembersStore from '../domain/ProjectMembers.store'

const EXCLUDED_STATUSES = [DeliveryStatus.Deleted]

export default class DeliveryFilterStore {
  @observable private shouldUseAllFilterTypes: boolean = false

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly deliveriesStore: DeliveriesStore,
    shouldUseAllFilterTypes: boolean,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly deliveryFollowingsStore: DeliveryFollowingsStore,
    private readonly deliveryAssignmentsStore: DeliveryAssignmentsStore,
    private readonly companiesStore: CompaniesStore,
    private readonly projectMembersStore: ProjectMembersStore,
    protected readonly getFilteredCollectionExcludeFilter?: (
      excludedFilters?: string[],
    ) => Delivery[],
    protected readonly onShowChanged?: (
      isShown: boolean,
      filterType: string,
    ) => void,
    private readonly onFilterClickHandler?: () => void,
  ) {
    this.setShouldUseAllFilterTypes(shouldUseAllFilterTypes)
  }

  private get availableDeliveries() {
    return this.deliveriesStore.availableDeliveries
  }

  public get filterStoresByTypeMap(): {
    [filterType: string]: BaseDeliveryFilterStore
  } {
    const map: { [filterType: string]: BaseDeliveryFilterStore } = {}

    this.filterTypes.forEach(filterType => {
      map[filterType] = new BaseDeliveryFilterStore(
        filterType,
        this.eventsStore.appState,
        this.sourceMapByFilterTypeMap[filterType],
        this.getFilteredCollectionExcludeFilter,
        this.onShowChanged,
        this.formatOptionKey,
        true,
        this.onFilterClickHandler,
      )
    })

    return map
  }

  public get filtersGroups(): BaseDeliveryFilterStore[][] {
    return Object.values(this.groupedFilterStoresByTypeMap)
  }

  public setFiltersValues = () => {
    const { deliveriesSettings } = this.appState.userActiveProjectSettings

    this.filtersGroups.forEach(groupedFilters => {
      groupedFilters.forEach(filters => {
        filters.filter.isInitialized = true
        filters.selectedOptions.clear()

        if (deliveriesSettings?.selectedFilters) {
          const activeFilter = deliveriesSettings.selectedFilters.find(
            selectedFilter => selectedFilter.type === filters.type,
          )
          filters.selectOptionsByIds(activeFilter?.values || [])
          filters.clickOnApply()
        }
      })
    })
  }

  public get selectedFilters(): IDeliveryFilter[] {
    const selectedFilters: IDeliveryFilter[] = []
    this.filtersGroups.forEach(groupedFilters => {
      groupedFilters.forEach(filters => {
        selectedFilters.push({
          type: filters.type as DeliveryFilterType,
          values: Array.from(filters.selectedOptions.keys()),
        })
      })
    })
    return selectedFilters
  }

  @computed
  public get groupedFilterStoresByTypeMap(): {
    [filterType: string]: BaseDeliveryFilterStore[]
  } {
    const map: { [filterType: string]: BaseDeliveryFilterStore[] } = {}

    groupedExtendedDeliveryFilterTypes.forEach(filterType => {
      let filters: DeliveryFilterType[] = []
      switch (filterType) {
        case DeliveryFilterType.Locations:
          filters = [
            DeliveryFilterType.Building,
            DeliveryFilterType.Zone,
            DeliveryFilterType.Level,
            DeliveryFilterType.Area,
            DeliveryFilterType.Gate,
            DeliveryFilterType.Route,
          ]
          break
        case DeliveryFilterType.Assignee:
          filters = [DeliveryFilterType.Assigners, DeliveryFilterType.Followers]
          break
        default:
          filters = [filterType]
          break
      }
      map[filterType] = filters.map(filter => {
        return new BaseDeliveryFilterStore(
          filter,
          this.eventsStore.appState,
          this.sourceMapByFilterTypeMap[filter],
          this.getFilteredCollectionExcludeFilter,
          this.onShowChanged,
          this.formatOptionKey,
          true,
        )
      })
    })

    return map
  }

  @computed
  private get sourceMapByFilterTypeMap(): { [filterType: string]: ISourceMap } {
    const maps = this.filterTypes.reduce((acc, filterType) => {
      acc[filterType] = this.getDefaultSourceMapByType(filterType)
      return acc
    }, {})

    this.availableDeliveries.forEach(delivery => {
      Object.keys(maps).forEach(filterType => {
        const map = maps[filterType]
        const optionIds = this.getOptionsIdsByFilterType(delivery, filterType)

        optionIds.forEach(optionId => {
          map[optionId]?.push(delivery.id)
        })
      })
    })

    return maps
  }

  public getOptionsIdsByFilterType(
    {
      id,
      gate,
      zone,
      route,
      building,
      level,
      area,
      status,
      company,
      offloadingEquipments,
      interiorPath,
      interiorDoor,
      staging,
    }: Delivery,
    filterType: string,
  ): string[] {
    switch (filterType) {
      case DeliveryFilterType.Gate:
        return [
          this.getExistingOptionId(
            this.locationAttributesStore.gatesStore,
            gate,
          ),
        ]
      case DeliveryFilterType.Zone:
        return [
          this.getExistingOptionId(
            this.locationAttributesStore.zonesStore,
            zone,
          ),
        ]
      case DeliveryFilterType.Route:
        return [
          this.getExistingOptionId(
            this.locationAttributesStore.routesStore,
            route,
          ),
        ]
      case DeliveryFilterType.Building:
        return [
          this.getExistingOptionId(
            this.locationAttributesStore.buildingsStore,
            building,
          ),
        ]
      case DeliveryFilterType.Level:
        return [
          this.getExistingOptionId(
            this.locationAttributesStore.levelsStore,
            level,
          ),
        ]
      case DeliveryFilterType.Area:
        return [
          this.getExistingOptionId(
            this.locationAttributesStore.areasStore,
            area,
          ),
        ]
      case DeliveryFilterType.InteriorDoor:
        return [
          this.getExistingOptionId(
            this.locationAttributesStore.interiorDoorsStore,
            interiorDoor,
          ),
        ]
      case DeliveryFilterType.InteriorPath:
        return [
          this.getExistingOptionId(
            this.locationAttributesStore.interiorPathsStore,
            interiorPath,
          ),
        ]
      case DeliveryFilterType.Staging:
        return [
          this.getExistingOptionId(
            this.locationAttributesStore.stagingsStore,
            staging,
          ),
        ]
      case DeliveryFilterType.Equipment:
        return !offloadingEquipments.length
          ? [UNASSIGNED_FILTER_OPTION]
          : offloadingEquipments.map(oe =>
              this.getExistingOptionId(
                this.locationAttributesStore.offloadingEquipmentsStore,
                oe.id,
              ),
            )
      case DeliveryFilterType.Company:
        return [company || UNASSIGNED_FILTER_OPTION]
      case DeliveryFilterType.Status:
        return [status]
      case DeliveryFilterType.Assigners:
        const assignment =
          this.deliveryAssignmentsStore.getAssignmentByEntityId(id)
        const userIds = assignment
          ? assignment.getRecipientsByType(TagType.User)
          : []
        return userIds.length ? userIds : [UNASSIGNED_FILTER_OPTION]
      case DeliveryFilterType.Followers:
        const followings =
          this.deliveryFollowingsStore.getEntityFollowersIds(id)
        return followings.length ? followings : [UNASSIGNED_FILTER_OPTION]
    }
  }

  @action.bound
  public setShouldUseAllFilterTypes(value: boolean) {
    this.shouldUseAllFilterTypes = value
  }

  @action.bound
  public resetAllFilters() {
    this.eventsStore.dispatch(RESET_ALL_FILTERS)
  }

  @action.bound
  public onDeliveriesReceived(eventContext: EventContext) {
    const [eventType] = eventContext.event

    if ([DELIVERY_RECEIVED, LOAD_DELIVERIES_SUCCESS].includes(eventType)) {
      this.syncFilters()
    }
  }

  @action.bound
  public syncFilters() {
    const { fieldsMap } = this.appState.deliveryFilters

    Object.keys(fieldsMap)
      .filter(filterType =>
        this.filterTypes.includes(filterType as DeliveryFilterType),
      )
      .forEach(filterType => {
        const filter = fieldsMap[filterType]
        const sourceMap = this.sourceMapByFilterTypeMap[filterType]
        this.updateCommonFilterState(filter, sourceMap)
      })
  }

  private formatOptionKey = (optionKey: string, filterType: string) => {
    let store
    switch (filterType) {
      case DeliveryFilterType.Status:
        return formatStatusToDisplay(optionKey)
      case DeliveryFilterType.Zone:
        store = this.locationAttributesStore.zonesStore
        break
      case DeliveryFilterType.Gate:
        store = this.locationAttributesStore.gatesStore
        break
      case DeliveryFilterType.Building:
        store = this.locationAttributesStore.buildingsStore
        break
      case DeliveryFilterType.Route:
        store = this.locationAttributesStore.routesStore
        break
      case DeliveryFilterType.Level:
        store = this.locationAttributesStore.levelsStore
        break
      case DeliveryFilterType.Area:
        store = this.locationAttributesStore.areasStore
        break
      case DeliveryFilterType.Equipment:
        store = this.locationAttributesStore.offloadingEquipmentsStore
        break
      case DeliveryFilterType.Assigners:
      case DeliveryFilterType.Followers:
        const user = this.projectMembersStore.getById(optionKey)
        return user ? getUserName(user) : UNASSIGNED_FILTER_OPTION
      case DeliveryFilterType.Company:
        return this.companiesStore.getCompanyNameById(
          optionKey,
          UNASSIGNED_FILTER_OPTION,
        )
      default:
        return optionKey
    }

    const instance = store.getInstanceById(optionKey)
    return (instance && instance.name) || UNASSIGNED_FILTER_OPTION
  }

  @action.bound
  private updateCommonFilterState(filter: UIFilterInfo, sourceMap: ISourceMap) {
    let appliedFilterOptions = []

    filter.initialFilterOptions.forEach((_, optionKey) => {
      const deliveriesIds = sourceMap[optionKey]

      filter.selectedFilterOptions.set(optionKey, deliveriesIds)
      filter.initialFilterOptions.set(optionKey, deliveriesIds)

      appliedFilterOptions = appliedFilterOptions.concat(deliveriesIds)
    })

    filter.appliedFilterOptions = appliedFilterOptions
  }

  private getDefaultSourceMapByType(type: DeliveryFilterType) {
    let sourceList: string[] = []

    switch (type) {
      case DeliveryFilterType.Status:
        sourceList = enumToList(DeliveryStatus).filter(
          s => !EXCLUDED_STATUSES.includes(s),
        )
        break
      case DeliveryFilterType.Company:
        sourceList = [
          ...this.companiesStore.allCompaniesIds,
          UNASSIGNED_FILTER_OPTION,
        ]
        break
      case DeliveryFilterType.Building:
        sourceList = this.getSourceList(
          this.locationAttributesStore.buildingsStore.list,
        )
        break
      case DeliveryFilterType.Zone:
        sourceList = this.getSourceList(
          this.locationAttributesStore.zonesStore.list,
        )
        break
      case DeliveryFilterType.Gate:
        sourceList = this.getSourceList(
          this.locationAttributesStore.gatesStore.list,
        )
        break
      case DeliveryFilterType.Route:
        sourceList = this.getSourceList(
          this.locationAttributesStore.routesStore.list,
        )
        break
      case DeliveryFilterType.Level:
        sourceList = this.getSourceList(
          this.locationAttributesStore.levelsStore.list,
        )
        break
      case DeliveryFilterType.Area:
        sourceList = this.getSourceList(
          this.locationAttributesStore.areasStore.list,
        )
        break
      case DeliveryFilterType.Assigners:
      case DeliveryFilterType.Followers:
        sourceList = this.getSourceList(this.projectMembersStore.list)
        break
      case DeliveryFilterType.Equipment:
        sourceList = this.getSourceList(
          this.locationAttributesStore.offloadingEquipmentsStore.list,
        )
        break
    }

    return sourceList.reduce((acc, optionId) => {
      acc[optionId] = []
      return acc
    }, {})
  }

  private getSourceList(list: any[]): string[] {
    return [...list.map(b => b.id), UNASSIGNED_FILTER_OPTION]
  }

  private get appState() {
    return this.eventsStore.appState
  }

  private get filterTypes() {
    return this.shouldUseAllFilterTypes
      ? extendedDeliveryFilterTypes
      : deliveryFilterTypes
  }

  private getExistingOptionId = (source: any, objectId: string) => {
    if (!objectId) return UNASSIGNED_FILTER_OPTION

    return this.getSourceList(source.list).includes(objectId)
      ? objectId
      : UNASSIGNED_FILTER_OPTION
  }
}
