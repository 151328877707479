import * as React from 'react'

import { Spinner } from '@blueprintjs/core'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IGenerateReportCommandInput, IReportFileInput } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { DOWNLOAD_REPORT_FILE } from '~/client/src/shared/stores/EventStore/eventConstants'
import { ToastTheme, showToast } from '~/client/src/shared/utils/toaster'

import './BaseReportButton.scss'

// localization: no display text to translate

export interface IReportInfo {
  templateId: string
  projectId: string
  dateFrom: number
  dateTo: number
  isFullTime: boolean
  timezoneId: string
  title: string
  data: any
  files: IReportFileInput[]
}

interface IProps {
  title: string
  getReportInfo: any
  className: string
  onClick?: () => void
  shouldPreventCreation?: boolean
  eventsStore?: DesktopEventStore
  shouldHideIcon?: boolean
  shouldInvertColors?: boolean
  isEnabled?: boolean
}

const SPINNER_SIZE = 25

@inject('eventsStore')
@observer
export default class BaseReportButton extends React.Component<IProps> {
  @observable private isDownloading: boolean = false

  public render() {
    const { className, title, isEnabled, shouldPreventCreation } = this.props

    return (
      <BaseActionButton
        className={classList({
          'primary-theme': true,
          [className || '']: true,
          'no-hover': this.isDownloading,
        })}
        isEnabled={isEnabled || shouldPreventCreation}
        title={title}
        icon={this.icon}
        onClick={this.downloadReport}
        withAutoIconSize={true}
      />
    )
  }

  @action.bound
  public downloadReport() {
    const { onClick, getReportInfo, shouldPreventCreation } = this.props
    if (this.isDownloading) {
      return
    }

    if (onClick && shouldPreventCreation) {
      onClick()
    }

    if (!shouldPreventCreation) {
      this.isDownloading = true

      const {
        templateId,
        data,
        projectId,
        dateFrom,
        dateTo,
        timezoneId,
        title,
        files,
        isFullTime,
      } = getReportInfo()

      const generateReportInput: IGenerateReportCommandInput = {
        templateId,
        files,
        title,
        dataJSON: JSON.stringify(data),
        projectId: projectId,
        dateTo,
        dateFrom,
        timezoneId,
        sendEmail: true,
        isFullTime,
        additionalRecipientIds: [],
      }

      this.props.eventsStore.dispatch(
        DOWNLOAD_REPORT_FILE,
        generateReportInput,
        this.onDownloaded,
      )
    }
  }

  @action.bound
  private onDownloaded() {
    this.isDownloading = false
    this.props.onClick?.()
    showToast(
      Localization.translator.aReportWillBeSentToYourEmailShortly,
      ToastTheme.SUCCESS,
    )
  }

  private get icon(): JSX.Element {
    if (this.isDownloading) {
      return <Spinner size={SPINNER_SIZE} className="loader pr25" />
    }
    if (this.props.shouldHideIcon) {
      return null
    }
    return <Icons.Pdf />
  }
}
