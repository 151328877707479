import * as React from 'react'

import { observer } from 'mobx-react'

import ProjectSetUpPageStore from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPage.store'
import DeliveryRequestSetUp from '~/client/src/desktop/views/ProjectSetUp/components/ProjectWorkflowsSetUp/components/DeliveryRequestSetUp/DeliveryRequestSetUp'

import { Header, Wrapper } from '../SetupPageLayout/SetupPageLayout'
import DeliveryDetailsPreview from './components/DeliveryDetailsPreview'

import './DeliveryWorkflows.scss'

const deliveryFormConfiguration = 'Delivery Form Configuration'

interface IProjectWorkflowSetUp {
  projectSetUpPageStore: ProjectSetUpPageStore
}

@observer
export default class ProjectWorkflowsSetUp extends React.Component<IProjectWorkflowSetUp> {
  public render() {
    return (
      <div className="row full-height">
        <Wrapper className="overflow-auto">
          <Header title={deliveryFormConfiguration} />
          <DeliveryRequestSetUp projectSetUpPageStore={this.store} />
        </Wrapper>
        <DeliveryDetailsPreview shouldListenToConstaints={true} />
      </div>
    )
  }

  private get store() {
    return this.props.projectSetUpPageStore
  }
}
