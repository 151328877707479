import { action, observable } from 'mobx'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'

enum PopupFilterType {
  Search = 'Search',
  Icon = 'Icon',
}

export default class ObjectPropertiesFilterPanelStore {
  @observable private openedPopup: string = null

  public constructor(private readonly state: DesktopInitialState) {}

  public get forceCloseMap() {
    const { fieldsMap } = this.state.objectFilters
    return Object.keys(fieldsMap).reduce((map, filterType) => {
      map[filterType] = this.openedPopup && this.openedPopup !== filterType
      return map
    }, {})
  }

  @action.bound
  public onShowSearchChanged(isShown: boolean) {
    this.onShowChanged(isShown, PopupFilterType.Search)
  }

  @action.bound
  public onShowIconChanged(isShown: boolean) {
    this.onShowChanged(isShown, PopupFilterType.Icon)
  }

  @action.bound
  public onShowChanged(isShown: boolean, popupType: string) {
    if (isShown) {
      this.openedPopup = popupType
    } else {
      this.openedPopup = null
    }
  }

  public get shouldCloseSearchPopup(): boolean {
    return this.openedPopup && this.openedPopup !== PopupFilterType.Search
  }

  public get shouldCloseIconPopup(): boolean {
    return this.openedPopup && this.openedPopup !== PopupFilterType.Icon
  }
}
