import { computed } from 'mobx'

import {
  IAssociatedCode,
  IGlobesSetupSettings,
  IUserProject,
  InviteStatus,
  ProjectAccessType,
} from '~/client/graph'
import {
  IDeliveriesSettings,
  ISitemapsSetupSettings,
  IUserNotificationsSettings,
  getDefaultDeliveriesSettings,
  getDefaultUserNotificationsSettings,
} from '~/client/src/shared/enums/ProjectSpecificUserProps'
import { TagType } from '~/client/src/shared/enums/TagType'
import BaseModel from '~/client/src/shared/models/BaseModel'
import { ITag } from '~/client/src/shared/models/Tag'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectRolesStore from '~/client/src/shared/stores/domain/ProjectRoles.store'

import { ProjectResponsibilityTypes } from '../constants/ProjectRoles'
import { InviteStatusCaption } from '../enums/InviteStatusCaption'
import UserProjectsStore from '../stores/domain/UserProjects.store'
import { ActivityFilterMap } from '../types/CustomActivityListFilter'
import User from './User'

export default class UserProject extends BaseModel<IUserProject> {
  public static fromDto(dto: IUserProject): UserProject {
    if (!dto) {
      return null
    }
    const { lastMobileActivityFilters } = dto

    const lastMobileActivityFiltersMap =
      lastMobileActivityFilters?.reduce(
        (_acc, { filterType, values }) =>
          Object.assign(_acc, { [filterType]: values }),
        {},
      ) || {}

    return new UserProject(
      dto.id,
      dto.projectId,
      dto.userId,
      dto.inviteStatus,
      dto.accessType as ProjectAccessType,
      dto.isDeleted,
      dto.companyId,
      dto.roles,
      dto.trades,
      dto.teams,
      dto.defaultTeams,
      dto.deliveryNotifications,
      dto.announcementNotifications,
      dto.activityNotifications,
      dto.permitNotifications,
      dto.scannerNotifications,
      dto.activitiesDatePickerMode,
      dto.deliveriesSettings,
      dto.sitemapsSetupSettings,
      dto.globesSetupSettings,
      dto.responsibilities as ProjectResponsibilityTypes[],
      lastMobileActivityFiltersMap,
      dto.associatedCodes,
    )
  }

  public static isDocMaster = (
    user: User,
    userProjectsStore: UserProjectsStore,
  ): boolean => {
    const userProject = userProjectsStore.getByUser(user)
    return userProject?.hasResponsibility(ProjectResponsibilityTypes.DocMaster)
  }

  public static isInspector = (
    user: User,
    userProjectsStore: UserProjectsStore,
  ): boolean => {
    const userProject = userProjectsStore.getByUser(user)
    return userProject?.hasResponsibility(ProjectResponsibilityTypes.Inspector)
  }

  public static isActivityViewAvailable = (
    user: User,
    userProjectsStore: UserProjectsStore,
  ): boolean => {
    const userProject = userProjectsStore.getByUser(user)
    return userProject?.hasResponsibility(
      ProjectResponsibilityTypes.ActivityView,
    )
  }

  public static isActivityUpdateAvailable = (
    user: User,
    userProjectsStore: UserProjectsStore,
  ): boolean => {
    const userProject = userProjectsStore.getByUser(user)
    return userProject?.hasResponsibility(
      ProjectResponsibilityTypes.ActivityUpdate,
    )
  }

  public static isActivityUploadAvailable = (
    user: User,
    userProjectsStore: UserProjectsStore,
  ): boolean => {
    const userProject = userProjectsStore.getByUser(user)
    return userProject?.hasResponsibility(
      ProjectResponsibilityTypes.ActivityUpload,
    )
  }

  public static isActivityNotesAvailable = (
    user: User,
    userProjectsStore: UserProjectsStore,
  ): boolean => {
    const userProject = userProjectsStore.getByUser(user)
    return userProject?.hasResponsibility(
      ProjectResponsibilityTypes.ActivityNote,
    )
  }

  public static isAdmin = (
    user: User,
    userProjectsStore: UserProjectsStore,
  ): boolean => {
    const userProject = userProjectsStore.getByUser(user)
    return userProject?.isAdmin
  }

  public static isPresentationUser = (
    user: User,
    userProjectsStore: UserProjectsStore,
  ): boolean => {
    const userProject = userProjectsStore.getByUser(user)
    return userProject?.hasResponsibility(
      ProjectResponsibilityTypes.PresentationUser,
    )
  }

  public static getCompanyId(
    user: User,
    userProjectsStore: UserProjectsStore,
  ): string {
    return userProjectsStore.getByUser(user)?.companyId || null
  }

  public static getCompanyName(
    user: User,
    userProjectsStore: UserProjectsStore,
    companiesStore: CompaniesStore,
    placeholder?: string,
  ): string {
    const companyId = UserProject.getCompanyId(user, userProjectsStore)
    return companiesStore.getCompanyNameById(companyId, placeholder)
  }

  public static getTeamsIds(
    user: User,
    userProjectsStore: UserProjectsStore,
  ): string[] {
    return userProjectsStore.getByUser(user)?.teams || []
  }

  public static getDefaultTeamsIds(
    user: User,
    userProjectsStore: UserProjectsStore,
  ): string[] {
    return userProjectsStore.getByUser(user)?.defaultTeams || []
  }

  public static getTradesIds(
    user: User,
    userProjectsStore: UserProjectsStore,
  ): string[] {
    return userProjectsStore.getByUser(user)?.trades || []
  }

  public static getRolesIds(
    user: User,
    userProjectsStore: UserProjectsStore,
    withGlobalRole: boolean = false,
  ): string[] {
    const projectRolesIds = userProjectsStore.getByUser(user)?.roles || []

    if (withGlobalRole && user.globalRole) {
      return [user.globalRole, ...projectRolesIds]
    }

    return projectRolesIds
  }

  public static getAllRolesAsString(
    user: User,
    userProjectsStore: UserProjectsStore,
    { getInstancesAsStringByIds }: ProjectRolesStore,
  ): string {
    const projectRolesIds = UserProject.getRolesIds(user, userProjectsStore)

    const globalRole = user.globalRole || ''
    const projectSpecificRolesString =
      getInstancesAsStringByIds(projectRolesIds)

    if (!projectSpecificRolesString) {
      return globalRole
    }

    const globalRolePortion = globalRole ? `${globalRole}, ` : ''

    return globalRolePortion + projectSpecificRolesString
  }

  public static hasTeamTag(
    user: User,
    tagId: string,
    userProjectsStore: UserProjectsStore,
  ): boolean {
    return UserProject.getTeamsIds(user, userProjectsStore).includes(tagId)
  }

  public static hasDefaultTeamTag(
    user: User,
    tagId: string,
    userProjectsStore: UserProjectsStore,
  ): boolean {
    return UserProject.getDefaultTeamsIds(user, userProjectsStore).includes(
      tagId,
    )
  }

  public static hasRoleTag(
    user: User,
    tagId: string,
    userProjectsStore: UserProjectsStore,
  ): boolean {
    return UserProject.getRolesIds(user, userProjectsStore, true).includes(
      tagId,
    )
  }

  public static hasCompanyTag(
    user: User,
    tagId: string,
    userProjectsStore: UserProjectsStore,
  ): boolean {
    return UserProject.getCompanyId(user, userProjectsStore) === tagId
  }

  public static hasAccountTypeTag(
    user: User,
    tagId: string,
    userProjectsStore: UserProjectsStore,
  ): boolean {
    return UserProject.getAccessType(user, userProjectsStore) === tagId
  }

  public static hasTradeTag(
    user: User,
    tagId: string,
    userProjectsStore: UserProjectsStore,
  ): boolean {
    return UserProject.getTradesIds(user, userProjectsStore).includes(tagId)
  }

  public static getInviteStatus(
    user: User,
    userProjectsStore: UserProjectsStore,
  ): InviteStatus {
    const userProject = userProjectsStore.getByUser(user)
    return userProject?.inviteStatus || InviteStatus.NotInvited
  }

  public static isAccepted(
    user: User,
    userProjectsStore: UserProjectsStore,
  ): boolean {
    return (
      UserProject.getInviteStatus(user, userProjectsStore) ===
      InviteStatus.Accepted
    )
  }

  public static isNotInvited(
    user: User,
    userProjectsStore: UserProjectsStore,
  ): boolean {
    return (
      UserProject.getInviteStatus(user, userProjectsStore) ===
      InviteStatus.NotInvited
    )
  }

  public static getAccessType(
    user: User,
    userProjectsStore: UserProjectsStore,
  ): ProjectAccessType {
    const userProject = userProjectsStore.getByUser(user)
    return userProject?.accessType || ProjectAccessType.Member
  }

  public static getInviteStatusCaption(
    user: User,
    userProjectsStore: UserProjectsStore,
  ): InviteStatusCaption {
    return this.getInviteStatus(user, userProjectsStore)
  }

  public static isDeleted(
    user: User,
    userProjectsStore: UserProjectsStore,
  ): boolean {
    const userProject = userProjectsStore.getByUser(user)
    return userProject?.isDeleted
  }

  public static getUserTagsIdsByType(
    user: User,
    categoryId: string,
    userProjectsStore: UserProjectsStore,
  ): string[] {
    switch (categoryId) {
      case TagType.Team:
        return UserProject.getTeamsIds(user, userProjectsStore)

      case TagType.DefaultTeam:
        return UserProject.getDefaultTeamsIds(user, userProjectsStore)

      case TagType.Role:
        return UserProject.getRolesIds(user, userProjectsStore)

      case TagType.AccountType:
        return [UserProject.getAccessType(user, userProjectsStore)]

      case TagType.Company:
        return [UserProject.getCompanyId(user, userProjectsStore)]

      case TagType.Trade:
        return UserProject.getTradesIds(user, userProjectsStore)
    }
  }

  public static setTagToUser(
    user: User,
    { type: tagType, id: tagId }: ITag,
    userProjectsStore: UserProjectsStore,
  ) {
    const ids = this.getUserTagsIdsByType(user, tagType, userProjectsStore)

    if (ids.includes(tagId)) {
      return
    }

    const userProject = userProjectsStore.getByUser(user)

    switch (tagType) {
      case TagType.Role:
      case TagType.Team:
      case TagType.Trade:
      case TagType.DefaultTeam:
        userProject[tagType] = [...ids, tagId]
        break

      case TagType.AccountType:
        userProject.accessType = tagId as ProjectAccessType
        break

      case TagType.Company:
        userProject.companyId = tagId
    }
  }

  public static resetTagFromUser(
    user: User,
    { type: tagType, id: tagId }: ITag,
    userProjectsStore: UserProjectsStore,
  ) {
    const ids = this.getUserTagsIdsByType(user, tagType, userProjectsStore)

    if (!ids.includes(tagId)) {
      return
    }

    const userProject = userProjectsStore.getByUser(user)

    switch (tagType) {
      case TagType.Role:
      case TagType.Team:
      case TagType.Trade:
      case TagType.DefaultTeam:
        userProject[tagType] = ids.filter(id => id !== tagId)
        break

      case TagType.Company:
        userProject.companyId = null
    }
  }

  public static getDefaultSettingsForJustCreatedProject(
    userId: string,
    projectId: string,
  ): UserProject {
    return new UserProject(
      null,
      projectId,
      userId,
      InviteStatus.Accepted,
      ProjectAccessType.Admin,
    )
  }

  public constructor(
    id: string,
    public projectId: string,
    public userId: string,

    public inviteStatus: InviteStatus = InviteStatus.NotInvited,

    public accessType: ProjectAccessType = ProjectAccessType.Member,
    public isDeleted: boolean = false,

    public companyId: string = null,

    public roles: string[] = [],
    public trades: string[] = [],
    public teams: string[] = [],
    public defaultTeams: string[] = [],

    public deliveryNotifications: IUserNotificationsSettings = getDefaultUserNotificationsSettings(),
    public announcementNotifications: IUserNotificationsSettings = getDefaultUserNotificationsSettings(),
    public activityNotifications: IUserNotificationsSettings = getDefaultUserNotificationsSettings(),
    public permitNotifications: IUserNotificationsSettings = getDefaultUserNotificationsSettings(),
    public scannerNotifications: IUserNotificationsSettings = getDefaultUserNotificationsSettings(),

    public activitiesDatePickerMode: string = '',

    public deliveriesSettings: IDeliveriesSettings = getDefaultDeliveriesSettings(),
    public sitemapsSetupSettings: ISitemapsSetupSettings = {},
    public globesSetupSettings: IGlobesSetupSettings = {},

    public responsibilities: ProjectResponsibilityTypes[] = [],
    public lastMobileActivityFilters: ActivityFilterMap = {},

    public associatedCodes?: IAssociatedCode[],
  ) {
    super(id)

    this.associatedCodes = this.associatedCodes || []

    this.prepareLastActivityFilters()
  }

  public toDto(): IUserProject {
    const mobileActivityFilters = Object.keys(
      this.lastMobileActivityFilters,
    ).map(filterType => {
      const values = this.lastMobileActivityFilters[filterType]
      return { filterType, values }
    })

    return Object.assign(this.asJson, {
      lastMobileActivityFilters: mobileActivityFilters,
    })
  }

  public setAssociatedCodes = (code: IAssociatedCode): void => {
    if (!this.associatedCodesIds.includes(code.id)) {
      this.associatedCodes = [...this.associatedCodes, code]
    }
  }

  @computed
  public get associatedCodesIds(): string[] {
    return this.associatedCodes.map(c => c.id)
  }

  public unsetAssociatedCodes = (code: IAssociatedCode): void => {
    this.associatedCodes = this.associatedCodes.filter(
      userCode => userCode.id !== code.id,
    )
  }

  public setSoftDeletion() {
    this.isDeleted = true
  }

  public setInviteStatus(inviteStatus: InviteStatus) {
    this.inviteStatus = inviteStatus
  }

  public resetInvitation() {
    this.inviteStatus = InviteStatus.NotInvited
  }

  public addResponsibility(newResponsibility: ProjectResponsibilityTypes) {
    if (!this.responsibilities.includes(newResponsibility)) {
      this.responsibilities = [...this.responsibilities, newResponsibility]
    }
  }

  public removeResponsibility(
    responsibilityToRemove: ProjectResponsibilityTypes,
  ) {
    this.responsibilities = this.responsibilities.filter(
      r => r !== responsibilityToRemove,
    )
  }

  public hasResponsibility = (
    responsibility: ProjectResponsibilityTypes,
  ): boolean => {
    return this.responsibilities.includes(responsibility)
  }

  public get isDocMaster(): boolean {
    return this.hasResponsibility(ProjectResponsibilityTypes.DocMaster)
  }

  public get isInspector(): boolean {
    return this.hasResponsibility(ProjectResponsibilityTypes.Inspector)
  }

  public get isFormsMaster(): boolean {
    return this.hasResponsibility(ProjectResponsibilityTypes.FormsMaster)
  }

  public get isScanMaster(): boolean {
    return this.hasResponsibility(ProjectResponsibilityTypes.ScanMaster)
  }

  public get hasScanAccess(): boolean {
    return (
      this.hasResponsibility(ProjectResponsibilityTypes.ScanMaster) ||
      this.isAdmin
    )
  }

  public get hasActivityViewResponsibility(): boolean {
    return this.hasResponsibility(ProjectResponsibilityTypes.ActivityView)
  }

  public get hasActivityUpdateResponsibility(): boolean {
    return this.hasResponsibility(ProjectResponsibilityTypes.ActivityUpdate)
  }

  public get hasActivityUploadResponsibility(): boolean {
    return this.hasResponsibility(ProjectResponsibilityTypes.ActivityUpload)
  }

  public get hasActivityNotesResponsibility(): boolean {
    return this.hasResponsibility(ProjectResponsibilityTypes.ActivityNote)
  }

  public get isActivityViewAvailable(): boolean {
    return (
      this.hasActivityViewResponsibility ||
      this.isAdmin ||
      this.isPresentationUser
    )
  }

  public get isActivityUpdateAvailable(): boolean {
    return this.hasActivityUpdateResponsibility || this.isAdmin
  }

  public get isActivityUploadAvailable(): boolean {
    return this.hasActivityUploadResponsibility || this.isAdmin
  }

  public get isActivityNotesAvailable(): boolean {
    return this.hasActivityNotesResponsibility || this.isAdmin
  }

  public get isOwner(): boolean {
    return this.accessType === ProjectAccessType.Owner
  }

  public get isAdmin(): boolean {
    return (
      this.accessType === ProjectAccessType.Admin ||
      this.accessType === ProjectAccessType.Owner
    )
  }

  @computed
  public get isAdminOrFormsMaster(): boolean {
    return this.isAdmin || this.isFormsMaster
  }

  public get isSuperUser(): boolean {
    return this.isAdmin || this.isDocMaster
  }

  public get isPresentationUser(): boolean {
    return this.hasResponsibility(ProjectResponsibilityTypes.PresentationUser)
  }

  public get isNotInvited(): boolean {
    return this.inviteStatus === InviteStatus.NotInvited
  }

  public get isPending(): boolean {
    return this.inviteStatus === InviteStatus.Pending
  }

  public lastMobileActivityFiltersForProject(): ActivityFilterMap {
    return this.lastMobileActivityFilters || null
  }

  private prepareLastActivityFilters() {
    if (!this.lastMobileActivityFilters) {
      return
    }

    Object.keys(this.lastMobileActivityFilters).forEach(filterType => {
      const filter = this.lastMobileActivityFilters[filterType]
      if (!Array.isArray(filter)) {
        this.lastMobileActivityFilters[filterType] = Object.values(filter || {})
      }
    })
  }
}
