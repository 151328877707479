import * as React from 'react'

import Konva from 'konva'
import { Group, Line } from 'react-konva'

import { SitemapLineArrowPosition } from '~/client/graph'

import KonvaLineArrows from './KonvaLineArrows'

interface IProps {
  points: number[]
  lineWidth: number
  color: string
  fillColor: string
  fillOpacity: number
  isClosed: boolean
  arrowPosition: SitemapLineArrowPosition
}

export default class KonvaPolyLine extends React.Component<
  IProps & Konva.NodeConfig
> {
  public render() {
    const {
      points,
      color,
      lineWidth,
      fillColor,
      fillOpacity,
      isClosed,
      arrowPosition,
      ...rest
    } = this.props
    return (
      <Group {...rest}>
        {isClosed && (
          <Line
            points={points}
            fill={fillColor}
            opacity={fillOpacity}
            closed={true}
          />
        )}
        <Line
          points={points}
          strokeWidth={lineWidth}
          stroke={color}
          closed={isClosed}
        />
        {!isClosed && (
          <KonvaLineArrows
            points={points}
            width={lineWidth}
            color={color}
            arrowPosition={arrowPosition}
          />
        )}
      </Group>
    )
  }
}
