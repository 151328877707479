import { action, computed, observable } from 'mobx'

import { FilterType } from '~/client/graph'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import UserProject from '~/client/src/shared/models/UserProject'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { UPDATE_ACTIVITY_FILTERS_SETTINGS } from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityCodeTypesStore from '~/client/src/shared/stores/domain/ActivityCodeTypes.store'
import ActivityCodesStore from '~/client/src/shared/stores/domain/ActivityCodes.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

export enum ActivityFilterSetupDialogType {
  ActivityCodes = 'Activity Code',
  HiddenActivityCodes = 'Hidden Activity Code',
  ProjectMembers = 'Project Member',
}

export default class ActivityFilterSetupDialogStore {
  @observable public isActivityFilterSetupDialogShown: boolean = false
  @observable
  public activityFilterSetupDialogType: ActivityFilterSetupDialogType
  @observable public activityFilterSetupDialogSubtype: string

  @observable public selectedFilterDialogCategoryIds: string[] = []
  @observable public selectedFilterDialogItemIds: string[] = []
  @observable public expandedDialogNodes: string[] = []

  public isSingleSelectMode: boolean = false
  public onApply: () => void = null

  public constructor(
    private activityFiltersStore: ActivityFiltersStore,
    private eventsStore: EventsStore,
    private activitiesStore: ActivitiesStore,
    private activityCodeTypesStore: ActivityCodeTypesStore,
    private activityCodesStore: ActivityCodesStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly projectMembersStore: ProjectMembersStore,
  ) {}

  @action.bound
  public getFilter(name: string) {
    const { filterInfoMap } = this.activityFiltersStore.settings
    return filterInfoMap[name]
  }

  @computed
  public get submitCaption() {
    if (
      this.activityFilterSetupDialogType !==
      ActivityFilterSetupDialogType.HiddenActivityCodes
    ) {
      return Localization.translator.submit_verb
    }

    const allActivitiesQty = this.activitiesStore.list.length
    if (!this.selectedFilterDialogItemIds.length) {
      return Localization.translator.showAllXActivities(allActivitiesQty)
    }

    const hiddenActivitiesIds = this.selectedFilterDialogItemIds.reduce(
      (value, codeId) => {
        const codeActivityIds =
          this.activityCodesStore.codesToActivitiesMap[codeId]
        return value.concat(codeActivityIds)
      },
      [],
    )

    const visibleActivityQty = this.activitiesStore.list.filter(
      a => !hiddenActivitiesIds.includes(a.code),
    ).length

    return Localization.translator.showXOfYActivities(
      visibleActivityQty,
      allActivitiesQty,
    )
  }

  @action.bound
  public hideActivityFilterSetupDialog() {
    this.isActivityFilterSetupDialogShown = false
    this.activityFilterSetupDialogType = null
    this.activityFilterSetupDialogSubtype = null
    this.selectedFilterDialogCategoryIds = []
    this.selectedFilterDialogItemIds = []
    this.expandedDialogNodes = []
  }

  @action.bound
  public applyActivityFilterSetupDialog(
    shouldSkipSavingDuringApplying: boolean,
  ) {
    // converting selected activity codes into hidden for saving database space
    const selectedTypeIds = this.selectedFilterDialogCategoryIds
    const selectedCodeIds = this.selectedFilterDialogItemIds

    switch (this.activityFilterSetupDialogType) {
      case ActivityFilterSetupDialogType.ActivityCodes:
        const codeFilter =
          this.activityFiltersStore.settings.filterInfoMap[
            this.activityFilterSetupDialogSubtype
          ]
        codeFilter.codeTypeIds = selectedTypeIds

        const hiddenActivityCodeIds = this.activityCodesStore.list
          .filter(
            ({ id, activityCodeTypeId }) =>
              selectedTypeIds.includes(activityCodeTypeId) &&
              !selectedCodeIds.includes(id),
          )
          .map(code => code.id)
        codeFilter.hiddenCodeIds = hiddenActivityCodeIds
        break

      case ActivityFilterSetupDialogType.ProjectMembers:
        const userFilter =
          this.activityFiltersStore.settings.filterInfoMap[
            this.activityFilterSetupDialogSubtype
          ]
        userFilter.codeTypeIds = selectedTypeIds

        const hiddenMemberIds = this.projectMembersStore.list
          .filter(
            user =>
              selectedTypeIds.includes(
                UserProject.getCompanyId(user, this.userProjectsStore),
              ) && !selectedCodeIds.includes(user.id),
          )
          .map(user => user.id)
        userFilter.hiddenCodeIds = hiddenMemberIds
        break

      case ActivityFilterSetupDialogType.HiddenActivityCodes:
        this.activityFiltersStore.settings.hiddenActivityCodeTypesIds =
          selectedTypeIds
        this.activityFiltersStore.settings.hiddenActivityCodeIds =
          selectedCodeIds
        break
    }

    if (!shouldSkipSavingDuringApplying) {
      this.eventsStore.dispatch(UPDATE_ACTIVITY_FILTERS_SETTINGS)
    }

    if (this.onApply) {
      this.onApply()
      this.onApply = null
    }

    const filterType = this.activityFilterSetupDialogSubtype as FilterType
    if (
      this.activityFilterSetupDialogType ===
        ActivityFilterSetupDialogType.ActivityCodes &&
      [FilterType.Building, FilterType.Level, FilterType.Zone].includes(
        filterType,
      )
    ) {
      this.activityFiltersStore.setDefaultActivityLocationRelationships(
        filterType,
      )
    }
    this.hideActivityFilterSetupDialog()
  }

  @action.bound
  public expandNode(dataId: string) {
    if (this.expandedDialogNodes.includes(dataId)) {
      this.expandedDialogNodes = this.expandedDialogNodes.filter(
        node => node !== dataId,
      )
    } else {
      this.expandedDialogNodes.push(dataId)
    }
  }

  @action.bound
  public setSelectedFilterDialogCategory(dataId: string, value: boolean) {
    if (this.isSingleSelectMode) {
      this.selectedFilterDialogCategoryIds = value ? [dataId] : []
      return
    }

    if (value && !this.selectedFilterDialogCategoryIds.includes(dataId)) {
      this.selectedFilterDialogCategoryIds.push(dataId)
    }

    if (!value) {
      this.selectedFilterDialogCategoryIds =
        this.selectedFilterDialogCategoryIds.filter(node => node !== dataId)
    }
  }

  @action.bound
  public setSelectedFilterDialogItem(dataId: string, value: boolean) {
    if (value && !this.selectedFilterDialogItemIds.includes(dataId)) {
      this.selectedFilterDialogItemIds.push(dataId)
    }

    if (!value) {
      this.selectedFilterDialogItemIds =
        this.selectedFilterDialogItemIds.filter(node => node !== dataId)
    }
  }

  public get activityCodeTypes() {
    return this.activityCodeTypesStore.list
  }

  public get activityCodesTree() {
    return this.activityCodeTypesStore.tree
  }

  public get activeProjectId(): string {
    return this.eventsStore.appState.activeProject.id
  }

  public get hiddenActivityCodeIds() {
    if (
      this.activityFilterSetupDialogType ===
      ActivityFilterSetupDialogType.HiddenActivityCodes
    ) {
      return []
    }
    return this.activityFiltersStore.settings.hiddenActivityCodeIds || []
  }
}
