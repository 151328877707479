import { action, computed, observable } from 'mobx'

import DeliveryDetailsStore, {
  TimeFieldTypes,
} from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import RecurringDeliveriesSettings, {
  MAX_RECURRING_MONTHS,
} from '~/client/src/shared/models/RecurringDeliveriesSettings'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import { getDefaultStartAndFinishDates } from '../../../CommonDatePicker/CommonDatePickerHelper'

export default class DeliverySelectDateModalStore {
  @observable public deliveriesCountByDate: Map<number, number> = new Map()
  @observable public selectedMonth: Date = null
  @observable public startDate: Date = null
  @observable public endDate: Date = null

  @observable public isShowMonthPicker: boolean
  @observable public isShowTimePicker: boolean
  @observable public isShowToggleDatePopup: boolean
  @observable public timeFieldId: TimeFieldTypes

  @observable public isOneDayMode: boolean = true

  public constructor(
    private readonly projectDateStore: ProjectDateStore,
    private readonly deliveryDetailsStore: DeliveryDetailsStore,
  ) {
    this.onInitMonth()
  }

  @action.bound
  public hideToggleDatePopup() {
    this.isShowToggleDatePopup = false
  }

  @action.bound
  public showToggleDatePopup() {
    this.isShowToggleDatePopup = true
  }

  @computed
  public get activeFieldTime() {
    const { startTime, endTime } = this.deliveryDetailsStore.selectedTimes
    return this.timeFieldId === FieldIds.START_TIME ? startTime : endTime
  }

  @action.bound
  public onApplyTimePickerSelection(time: string) {
    this.deliveryDetailsStore.onFieldValueChange(this.timeFieldId, time)
    this.hideTimePicker()
  }

  @action.bound
  public showTimePicker(type: TimeFieldTypes) {
    this.timeFieldId = type
    this.isShowTimePicker = true
  }

  @action.bound
  public hideTimePicker() {
    this.isShowTimePicker = false
  }

  @action.bound
  public showMonthPicker() {
    this.isShowMonthPicker = true
  }

  @action.bound
  public hideMonthPicker() {
    this.isShowMonthPicker = false
  }

  @action.bound
  public toggleSelectionMode() {
    this.isOneDayMode = !this.isOneDayMode
    this.hideToggleDatePopup()

    if (this.isOneDayMode) {
      this.endDate = this.startDate
    } else {
      this.endDate = undefined
    }
  }

  @action.bound
  public prevMonth() {
    this.updateCurrentMonth(
      this.projectDateStore.addMonths(this.selectedMonth, -1),
    )
  }

  @action.bound
  public nextMonth() {
    this.updateCurrentMonth(
      this.projectDateStore.addMonths(this.selectedMonth, 1),
    )
  }

  @action.bound
  public updateCurrentMonth(month: Date) {
    this.selectedMonth = month
  }

  @action.bound
  public onInitMonth() {
    this.updateCurrentMonth(
      this.projectDateStore.convertToProjectDate(Date.now()),
    )
  }

  @action.bound
  public onInitDates(startDate: Date | number, endDate: Date | number) {
    const { convertFromProjectDate } = this.projectDateStore
    this.startDate = startDate && convertFromProjectDate(startDate)
    this.endDate = endDate && convertFromProjectDate(endDate)
  }

  @action.bound
  public onDateClick(event: Event, date: Date) {
    this.setDates(date)
  }

  @action.bound
  private setDates(date: Date) {
    const newDates = getDefaultStartAndFinishDates(
      date,
      this.startDate,
      this.endDate,
      this.isOneDayMode,
    )
    this.startDate = newDates.startDate
    this.endDate = newDates.endDate
  }

  @action.bound
  public onRecurringInitialDateChange(date: Date | number) {
    this.startDate = this.projectDateStore.endOfDay(date)
    this.endDate = this.startDate

    this.startDate &&
      this.deliveryDetailsStore.selectCalendarPeriod(this.startDate)
  }

  @action.bound
  public onCleanup() {
    this.hideTimePicker()
    this.deliveriesCountByDate = new Map()
    this.onInitMonth()
  }

  @action.bound
  public selectDayMode(value: boolean) {
    this.isOneDayMode = value
  }

  public saveRecurringEndDate = () => {
    const settingsCopy =
      this.recurringSettings.getCopy() as RecurringDeliveriesSettings
    settingsCopy.endDate = this.projectDateStore
      .endOfDay(this.startDate)
      .getTime()

    this.deliveryDetailsStore.onFieldValueChange(
      FieldIds.RECURRING_OPTIONS,
      settingsCopy,
    )
  }

  @computed
  public get minRecurringEndDate(): Date {
    const { selectedDates } = this.deliveryDetailsStore
    const { addDays, startOfDay, convertFromProjectDate } =
      this.projectDateStore

    return addDays(
      convertFromProjectDate(startOfDay(selectedDates?.endDate || new Date())),
      1,
    )
  }

  @computed
  public get maxRecurringEndDate(): Date {
    const { selectedDates } = this.deliveryDetailsStore
    const { addMonths, convertFromProjectDate } = this.projectDateStore

    return addMonths(
      convertFromProjectDate(selectedDates?.endDate || Date.now()),
      MAX_RECURRING_MONTHS,
    )
  }

  private get recurringSettings(): RecurringDeliveriesSettings {
    return this.deliveryDetailsStore.fields.find(
      ({ id }) => id === FieldIds.RECURRING_OPTIONS,
    )?.value
  }
}
