import FormReportGroupingOption from '~/client/src/shared/enums/FormReportGroupingOption'

import FilterInfo from '../../../shared/stores/substates/FilterInfo'

export default class FormReportFiltersSettingState {
  public desktopFilterMap: { [key: string]: FilterInfo } = {
    [FormReportGroupingOption.COMPANY]: new FilterInfo(
      FormReportGroupingOption.COMPANY,
      false,
    ),
    [FormReportGroupingOption.DAY]: new FilterInfo(
      FormReportGroupingOption.DAY,
      false,
    ),
    [FormReportGroupingOption.WEEK]: new FilterInfo(
      FormReportGroupingOption.WEEK,
      false,
    ),
    [FormReportGroupingOption.MONTH]: new FilterInfo(
      FormReportGroupingOption.MONTH,
      false,
    ),
  }
}
