import { DeliveryStatus } from '~/client/graph/types.generated'
import { ILWFCRow } from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import DeliveryGroupingOption from '~/client/src/shared/enums/DeliveryGroupingOption'
import { DeliveryStatusListView } from '~/client/src/shared/enums/DeliveryStatusListView'
import {
  UNASSIGNED,
  UNASSIGNED_FILTER_VALUE,
} from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import { NO_SPECIFIED } from '~/client/src/shared/utils/usefulStrings'

import BaseListStore, { ITreeNodeObj } from './BaseList.store'

const unassignedValues = [UNASSIGNED, NO_SPECIFIED]

export default abstract class BaseMultiBandListStore<
  T,
> extends BaseListStore<T> {
  public abstract get rows(): ILWFCRow[]

  protected toBandTreeNodeRows(
    bands?: string[],
    parentObjects?: T[],
    parentName?: string,
    levelNum?: number,
    shouldAddEmptyRow?: boolean,
    sortTreeObjs = this.distinctAndSortTreeObjs,
  ): ILWFCRow[] {
    const currentBand = bands[0]
    const subBands = bands.slice(1)

    const rows: ILWFCRow[] = []

    if (shouldAddEmptyRow) {
      rows.push({ data: {} })
    }

    let treeNodeObjs = this.getTreeNodeObjsByBand(currentBand)
    const objectMap = this.bandObjectMap[currentBand]

    treeNodeObjs = sortTreeObjs(treeNodeObjs)

    treeNodeObjs.forEach(({ name = UNASSIGNED_FILTER_VALUE, id, object }) => {
      let bandObjects = objectMap[id] || []

      if (bandObjects.length) {
        if (parentObjects) {
          bandObjects = bandObjects.filter(o => parentObjects.includes(o))
        }

        let children: ILWFCRow[]
        const categoryId = parentName + id

        const filteredSubBands = this.filterNodeSubBands(name, subBands)

        if (filteredSubBands.length) {
          children = this.toBandTreeNodeRows(
            filteredSubBands,
            bandObjects,
            categoryId,
            levelNum + 1,
            false,
            sortTreeObjs,
          )
        } else {
          children = this.toRows(bandObjects, null, levelNum + 1)
          if (this.shouldSort) {
            children.sort(this.compareRows)
          }
        }

        if (bandObjects.length > 0) {
          rows.push(
            this.createTreeNodeCategoryRow(
              categoryId,
              name,
              object,
              levelNum,
              bandObjects,
            ),
          )

          const isExpanded = !this.collapsedCategories.get(categoryId)
          if (isExpanded) {
            rows.push(...children)
          }
        }
      }
    })

    return rows
  }

  private distinctAndSortTreeObjs = (treeNodeObjs: ITreeNodeObj[]) => {
    const map = new Map<string, ITreeNodeObj>()

    treeNodeObjs.forEach(obj => map.set(obj.id, obj))

    return Array.from(map.values()).sort((a, b) => {
      if (unassignedValues.includes(a.id)) {
        return 1
      }
      if (unassignedValues.includes(b.id)) {
        return -1
      }

      return a.name?.localeCompare(b.name)
    })
  }

  protected filterNodeSubBands(name: string, nodeSubBands: string[]): string[] {
    if (name != DeliveryStatus.Canceled) {
      nodeSubBands = nodeSubBands.filter(
        band => band != DeliveryGroupingOption[FieldIds.CANCELATION_REASON],
      )
    }

    if (name != DeliveryStatusListView.Inspection) {
      nodeSubBands = nodeSubBands.filter(
        band => band != DeliveryGroupingOption[FieldIds.INSPECTION_SECTION],
      )
    }

    return nodeSubBands
  }

  protected abstract getTreeNodeObjsByBand(band: string): ITreeNodeObj[]
}
