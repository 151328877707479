import * as React from 'react'

import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

// localization: translated

export interface IProps {
  onReset: () => void
}

@observer
export default class DesktopActivityPresetsSelectorHeader extends React.Component<IProps> {
  public render() {
    return (
      <>
        <div className="text extra-large bold">
          {Localization.translator.savedActivitySets}
        </div>
        <div className="text extra-large bold">
          {Localization.translator.numberOfActivities}
        </div>
      </>
    )
  }
}
