import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'

import {
  IColumnConfiguration,
  IMaterialConfiguration,
  IMaterialProcurementData,
  ISiteLocation,
  ISubscription,
  LocationType,
  PermitFieldType,
} from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import {
  CATEGORY_ROW_HEIGHT,
  ITreeNodeObj,
} from '~/client/src/desktop/stores/ui/BaseList.store'
import BaseMultiBandListStore from '~/client/src/desktop/stores/ui/BaseMultiBandList.store'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import {
  BasicDataKeys,
  ILWFCCategory,
  ILWFCColumn,
  ILWFCRow,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import {
  MaterialStatus,
  getMaterialStatusDisplayName,
} from '~/client/src/shared/enums/MaterialStatus'
import MaterialsGroupingOption from '~/client/src/shared/enums/MaterialsGroupingOption'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'
import ILocationTransferPair from '~/client/src/shared/models/ILocationTransferPair'
import { IMaterialViewModel } from '~/client/src/shared/models/IMaterialViewModel'
import IPermitFieldDto from '~/client/src/shared/models/IPermitFieldDto'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import Material from '~/client/src/shared/models/Material'
import SitePermit from '~/client/src/shared/models/Permit'
import PermitType from '~/client/src/shared/models/PermitType'
import {
  ACTIVATE_PROJECT,
  LOAD_AND_LISTEN_TO_MATERIALS,
  LOAD_AND_LISTEN_TO_MATERIAL_CATEGORIES,
  LOAD_AND_LISTEN_TO_MATERIAL_CONFIGURATION,
  SAVE_MATERIAL,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialConfigurationStore from '~/client/src/shared/stores/domain/MaterialConfiguration.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import MaterialLocationMap from '~/client/src/shared/types/MaterialLocationMap'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import {
  groupingAttributeMapper,
  groupingCommonMapper,
} from '~/client/src/shared/utils/mappers'
import { ToastTheme, showToast } from '~/client/src/shared/utils/toaster'
import {
  EMPTY_STRING,
  LOCATION_SEPARATOR,
  NO_SPECIFIED,
  NO_VALUE,
} from '~/client/src/shared/utils/usefulStrings'
import { copyObjectArray, getFixedNum } from '~/client/src/shared/utils/util'

import { MAX_SELECTED_MATERIALS_FOR_FORM } from '../../Materials'

const MATERIAL_DATA_ROW_HEIGHT = 65

export interface IFormCellData {
  id: string
  materialId: string
  procurementId: string
  relatedDeliveries?: Delivery[]
  transferForms?: SitePermit[]
  itemsCount: number
}

export interface ILocationCellData {
  id: string
  plannedInstallLocationId: string
  plannedDeliveryLocationId: string
  locationTransferPairs?: ILocationTransferPair[]
}

export interface IEditableCellModel {
  viewModelId: string
  value: string
  onChange(newValue: any, viewModelId: string): void
  isNumber?: boolean
}

enum SpecificDataKeys {
  NAME = 'name',
  STATUS = 'status',
  PROCUREMENT_ID = 'procurement-id',
  PLANNED_INSTALL_LOCATION = 'planned-install-location',
  PLANNED_DELIVERY_LOCATION = 'planned-delivery-location',
  VENDOR = 'vendor',
  TOTAL_ON_SITE_QUANTITY = 'total-on-site-quantity',
  CURRENT_MATERIAL_LOCATION = 'current-material-location',
  REQUIRED_ON_SITE_DATE = 'required-on-site-date',
  NEXT_DELIVERY = 'next-delivery',
  NEXT_DELIVERY_BOOKING_DATE = 'next-delivery-booking-date',
  BOOKED_QUANTITY = 'booked-quantity',
  RECORDED_LAST_UPDATED = 'recorded-last-updated',
  PRODUCT_CATEGORY = 'product-category',
  RESPONSIBLE_COMPANY = 'responsible-company',
  MATERIAL_NOTE = 'material-note',

  PLANNED_QUANTITY = 'planned-quantity',
  DESCRIPTION = 'description',
  REMAINING_QUANTITY = 'remaining-quantity',
  PERCENT_COMPLETE = 'percent-complete',
  READY_TO_INSTALL_QUANTITY = 'ready-to-install-quantity',
  TO_MOVE_QUANTITY = 'to-move-quantity',

  MATERIAL_TRANSFER_ID = 'material-transfer-id',
  MATERIAL_TRANSFER_DATE = 'material-transfer-date',
}

export const DataKeys = { ...BasicDataKeys, ...SpecificDataKeys }

const FILTER_KEYS = ['id']
const unassignedValues = [UNASSIGNED, NO_SPECIFIED, EMPTY_STRING, NO_VALUE]

const FIXED_COLUMN_KEYS = [
  DataKeys.CHECKBOX,
  DataKeys.PROCUREMENT_ID,
  DataKeys.PRODUCT_CATEGORY,
  DataKeys.NAME,
  DataKeys.DESCRIPTION,
] as string[]

const getRemainingQuantity = (
  movedQuantity: number,
  plannedQuantity: number,
): number => {
  const resultValue =
    movedQuantity < plannedQuantity ? plannedQuantity - movedQuantity : 0
  return getFixedNum(resultValue)
}

const getPercentComplete = (
  readyToInstallQty: number,
  plannedQuantity: number,
): number => {
  return plannedQuantity > 0
    ? Math.round(readyToInstallQty / (plannedQuantity / 100))
    : 0
}

function reduceByMaterialProp<T>(
  arr: T[],
  itemPropMapper: (item: T) => string,
): Map<string, ITreeNodeObj> {
  return (arr || []).reduce((map, item) => {
    const value = itemPropMapper(item)
    if (!map.has(value)) {
      map.set(value, groupingCommonMapper(value))
    }
    return map
  }, new Map<string, ITreeNodeObj>([[UNASSIGNED, groupingCommonMapper(UNASSIGNED)]]))
}

function getDeliveryInfoProps(
  deliveries: Delivery[],
  predicate: (d: Delivery) => string,
) {
  const filteredDeliveries = deliveries.filter(predicate)
  return {
    relatedDeliveries: filteredDeliveries,
    itemsCount: filteredDeliveries.reduce(
      (set, delivery) => set.add(predicate(delivery)),
      new Set<string>(),
    ).size,
  }
}

export default class MaterialsListStore extends BaseMultiBandListStore<IMaterialViewModel> {
  private readonly collator = new Intl.Collator([], {
    numeric: true,
    sensitivity: 'accent',
  })

  @observable public selectedTransferForm: SitePermit
  @observable public transferFormTypeId: string
  @observable public transferFormFields: IPermitFieldDto[]

  @observable public isTransferCreationOpened = false
  @observable public isDeliveryFormOpened = false
  @observable public isWarningDialogOpened = false
  @observable public isWrongStatusDialogOpened = false

  @observable private existingMaterialsCount: number

  public constructor(
    private readonly state: DesktopInitialState,
    private readonly materialsStore: MaterialsStore,
    private readonly materialCategoryStore: MaterialCategoryStore,
    private readonly materialConfigurationStore: MaterialConfigurationStore,
    private readonly deliveriesStore: DeliveriesStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly companiesStore: CompaniesStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly tagsStore: TagsStore,
    private readonly deliveryDetailsStore: DeliveryDetailsStore,
    private readonly sitePermitsStore: SitePermitsStore,
    private readonly permitTypesStore: PermitTypesStore,
  ) {
    super(state.materialFilters, () => materialsStore.viewModels, FILTER_KEYS)
  }

  @observable
  public columnsWidthState = new Map<string, number>([
    [DataKeys.CHECKBOX, 40],
    [DataKeys.PROCUREMENT_ID, 120],
    [DataKeys.NAME, 200],
    [DataKeys.PRODUCT_CATEGORY, 200],
    [DataKeys.DESCRIPTION, 200],
    [DataKeys.PLANNED_INSTALL_LOCATION, 200],
    [DataKeys.PLANNED_DELIVERY_LOCATION, 200],
    [DataKeys.CURRENT_MATERIAL_LOCATION, 200],
    [DataKeys.RESPONSIBLE_COMPANY, 260],
    [DataKeys.VENDOR, 260],
    [DataKeys.STATUS, 150],
    [DataKeys.PLANNED_QUANTITY, 100],
    [DataKeys.READY_TO_INSTALL_QUANTITY, 100],
    [DataKeys.PERCENT_COMPLETE, 110],
    [DataKeys.REMAINING_QUANTITY, 100],
    [DataKeys.TOTAL_ON_SITE_QUANTITY, 100],
    [DataKeys.TO_MOVE_QUANTITY, 100],
    [DataKeys.NEXT_DELIVERY, 190],
    [DataKeys.NEXT_DELIVERY_BOOKING_DATE, 205],
    [DataKeys.BOOKED_QUANTITY, 100],
    [DataKeys.MATERIAL_TRANSFER_ID, 190],
    [DataKeys.MATERIAL_TRANSFER_DATE, 205],
    [DataKeys.MATERIAL_NOTE, 200],
    [DataKeys.RECORDED_LAST_UPDATED, 200],
  ])

  @computed
  public get defaultColumns(): ILWFCColumn[] {
    const columns: ILWFCColumn[] = [
      {
        dataKey: DataKeys.CHECKBOX,
      },
      {
        dataKey: DataKeys.PROCUREMENT_ID,
        label: Localization.translator.id_short,
      },
      {
        label: Localization.translator.productCategory,
        dataKey: DataKeys.PRODUCT_CATEGORY,
      },
      {
        label: Localization.translator.materialName,
        dataKey: DataKeys.NAME,
      },
      {
        label: Localization.translator.description,
        dataKey: DataKeys.DESCRIPTION,
      },
      {
        label: Localization.translator.plannedInstallLocation,
        dataKey: DataKeys.PLANNED_INSTALL_LOCATION,
      },
      {
        label: Localization.translator.plannedDeliveryLocation,
        dataKey: DataKeys.PLANNED_DELIVERY_LOCATION,
      },
      {
        label: Localization.translator.currentLocation,
        dataKey: DataKeys.CURRENT_MATERIAL_LOCATION,
      },
      {
        label: Localization.translator.responsibleCompany,
        dataKey: DataKeys.RESPONSIBLE_COMPANY,
      },
      {
        label: Localization.translator.vendor,
        dataKey: DataKeys.VENDOR,
      },
      {
        label: Localization.translator.status,
        dataKey: DataKeys.STATUS,
      },
      {
        label: Localization.translator.plannedQuantity,
        dataKey: DataKeys.PLANNED_QUANTITY,
      },
      {
        label: Localization.translator.readyToInstallQuantity,
        dataKey: DataKeys.READY_TO_INSTALL_QUANTITY,
      },
      {
        label: Localization.translator.PComplete,
        dataKey: DataKeys.PERCENT_COMPLETE,
      },
      {
        label: Localization.translator.remainingQuantity,
        dataKey: DataKeys.REMAINING_QUANTITY,
      },
      {
        label: Localization.translator.totalOnSiteQuantity,
        dataKey: DataKeys.TOTAL_ON_SITE_QUANTITY,
      },
      {
        label: Localization.translator.toMoveQuantity,
        dataKey: DataKeys.TO_MOVE_QUANTITY,
      },
      {
        label: Localization.translator.nextDelivery,
        dataKey: DataKeys.NEXT_DELIVERY,
      },
      {
        label: Localization.translator.deliveryBookingDate,
        dataKey: DataKeys.NEXT_DELIVERY_BOOKING_DATE,
      },
      {
        label: Localization.translator.bookedQuantity,
        dataKey: DataKeys.BOOKED_QUANTITY,
      },
      {
        label: Localization.translator.materialTransferId,
        dataKey: DataKeys.MATERIAL_TRANSFER_ID,
      },
      {
        label: Localization.translator.materialTransferDate,
        dataKey: DataKeys.MATERIAL_TRANSFER_DATE,
      },
      {
        label: Localization.translator.deliveryMaterialNotes,
        dataKey: DataKeys.MATERIAL_NOTE,
      },
      {
        label: Localization.translator.recordLastUpdated,
        dataKey: DataKeys.RECORDED_LAST_UPDATED,
        isMonospace: true,
      },
    ]

    return columns.map(column => ({
      ...column,
      width: this.columnsWidthState.get(column.dataKey),
      isHidden: false,
      isFixed: FIXED_COLUMN_KEYS.includes(column.dataKey),
    }))
  }

  @computed
  public get columns(): ILWFCColumn[] {
    const { columnsConfig } = this.materialConfiguration

    if (!columnsConfig?.length) {
      return this.defaultColumns
    }

    const mappedConfigCols = columnsConfig.reduce((list, columnSorted) => {
      const column = this.defaultColumns.find(
        _ => _.dataKey == columnSorted.columnId,
      )
      if (column) {
        list.push({
          ...column,
          width: this.columnsWidthState.get(column.dataKey),
          isHidden: columnSorted.isHidden,
          isFixed: columnSorted.isFixed,
        })
      }
      return list
    }, [] as ILWFCColumn[])

    const absentColumns = this.defaultColumns.filter(
      col => !mappedConfigCols.some(config => config.dataKey === col.dataKey),
    )
    absentColumns.forEach(col =>
      mappedConfigCols.push({ ...col, isFixed: false }),
    )

    return mappedConfigCols
  }

  @computed
  public get fixedColumnsCount() {
    return this.columns.filter(c => c.isFixed).length
  }

  private get materialConfiguration(): IMaterialConfiguration {
    return this.materialConfigurationStore.configuration
  }

  @action.bound
  public resetColumnsConfiguration() {
    this.materialConfiguration.columnsConfig = this.mapColumnsToConfig(
      this.defaultColumns,
    )

    this.saveMaterialColumnConfiguration()
  }

  @action.bound
  public updateColumnsConfiguration = (collection: ILWFCColumn[]) => {
    this.materialConfiguration.columnsConfig =
      this.mapColumnsToConfig(collection)

    this.saveMaterialColumnConfiguration()
  }

  public get isDataPrepared(): boolean {
    return !this.isLoading && this.isDataReceived
  }

  public get isUpdating(): boolean {
    return this.state.loading.get(SAVE_MATERIAL)
  }

  public changeDescription = (newValue: string, viewModelId: string) => {
    const { material, hasProcurementId, procurementData } =
      this.getMaterialDataForUpdate(viewModelId)

    if (hasProcurementId) {
      if (!procurementData || procurementData.description === newValue) {
        return
      }

      procurementData.description = newValue

      this.materialsStore.save(
        material,
        this.showUpdateToast.bind(null, true),
        this.showUpdateToast,
      )
      return
    }

    if (material.description !== newValue) {
      material.description = newValue

      this.materialsStore.save(
        material,
        this.showUpdateToast.bind(null, true),
        this.showUpdateToast,
      )
    }
  }

  public changeMaterialQuantity = (newValue: number, viewModelId: string) => {
    const { material, hasProcurementId, procurementData } =
      this.getMaterialDataForUpdate(viewModelId)

    if (hasProcurementId) {
      return this.changeProcurementQuantity(material, procurementData, newValue)
    }

    this.changeTotalQuantity(material, newValue)
  }

  private mapColumnsToConfig(columns: ILWFCColumn[]): IColumnConfiguration[] {
    return columns.map(column => ({
      columnId: column.dataKey,
      isHidden: column.isHidden,
      isFixed: column.isFixed,
    }))
  }

  private changeProcurementQuantity = (
    material: Material,
    procurementData: IMaterialProcurementData,
    newValue: number,
  ) => {
    if (!procurementData || procurementData.plannedQuantity === newValue) {
      return
    }

    material.plannedQuantity -= procurementData.plannedQuantity
    procurementData.plannedQuantity = newValue
    material.plannedQuantity += newValue

    this.materialsStore.save(
      material,
      this.showUpdateToast.bind(null, true),
      this.showUpdateToast,
    )
  }

  private changeTotalQuantity = (material: Material, newValue: number) => {
    if (material.plannedQuantity === newValue) {
      return
    }

    material.plannedQuantity = newValue

    this.materialsStore.save(
      material,
      this.showUpdateToast.bind(null, true),
      this.showUpdateToast,
    )
  }

  private getMaterialDataForUpdate = (viewModelId: string) => {
    const viewModel = this.filteredCollection.find(vm => vm.id === viewModelId)
    if (!viewModel) {
      return
    }

    const { material, procurementId, installLocation } = viewModel
    const model = Material.fromDto(material, this.deliveriesStore)
    model.procurementDataList = copyObjectArray(model.procurementDataList || [])

    const procurementData =
      (procurementId || installLocation?.id) &&
      model.procurementDataList.find(pr =>
        procurementId
          ? pr.procurementId === procurementId
          : pr.installLocationId === installLocation?.id,
      )

    return {
      material: model,
      hasProcurementId: !!procurementId,
      procurementData,
    }
  }

  private saveMaterialColumnConfiguration = () => {
    this.materialConfigurationStore.save({
      columnsConfig: this.materialConfiguration.columnsConfig,
    } as IMaterialConfiguration)
  }

  @computed
  public get materialCategoriesOptions() {
    const options = this.materialCategoryStore.list?.map(item => ({
      value: item.id,
      title: item.name || EMPTY_STRING,
      isDisabled: false,
    }))
    return options || []
  }

  private get isLoading() {
    const { loading } = this.state

    return (
      loading.get(ACTIVATE_PROJECT) ||
      loading.get(LOAD_AND_LISTEN_TO_MATERIALS) ||
      loading.get(LOAD_AND_LISTEN_TO_MATERIAL_CATEGORIES) ||
      loading.get(LOAD_AND_LISTEN_TO_MATERIAL_CONFIGURATION)
    )
  }

  private get isDataReceived(): boolean {
    return [
      this.deliveriesStore,
      this.locationAttributesStore,
      this.sitePermitsStore,
      this.permitTypesStore,
    ].every(s => s.isDataReceived)
  }

  private getLocationById = (id: string): LocationBase => {
    return this.locationAttributesStore.getById(id)
  }

  private getLocationBySiteLocationObj = (
    location: ISiteLocation,
  ): LocationBase => {
    return this.locationAttributesStore.getLocationBySiteLocationObj(location)
  }

  public getCurrentLocationFormattedIds = (
    viewModel: IMaterialViewModel,
  ): string[] => {
    const { [DataKeys.CURRENT_MATERIAL_LOCATION]: currentLocationPairs } =
      this.prepareInfoForMaterial(viewModel)
    const locationIds = currentLocationPairs?.map(
      ({ location }) => `${location.id}${LOCATION_SEPARATOR}${location.type}`,
    )
    return locationIds?.length ? Array.from(locationIds) : [UNASSIGNED]
  }

  public get transferFormType(): PermitType {
    return this.permitTypesStore.enabledTypes.find(t => t.isMaterialTransfer)
  }

  @computed
  public get rows(): ILWFCRow[] {
    return this.toBandTreeNodeRows(
      this.state.filters.selectedMaterialBandsOption.bands,
      null,
      EMPTY_STRING,
      0,
      true,
      this.sortTreeObjs,
    )
  }

  @computed
  protected get categoryToInstancesMap() {
    const map = {}

    this.filteredCollection.forEach(viewModel => {
      const categoryIds = this.getCategoryIds(viewModel, this.groupingKey) || []
      if (!categoryIds.length) {
        categoryIds.push(UNASSIGNED)
      }

      categoryIds.forEach(id => {
        const formattedCategoryId = id || UNASSIGNED

        if (map[formattedCategoryId]) {
          map[formattedCategoryId].push(viewModel)
        } else {
          map[formattedCategoryId] = [viewModel]
        }
      })
    })
    return map
  }

  private getCategoryIds(
    viewModel: IMaterialViewModel,
    band: string,
  ): string[] {
    const {
      installLocation,
      deliveryLocation,
      relatedDeliveries,
      status,
      material,
      description,
    } = viewModel
    const hasDeliveries = relatedDeliveries?.length

    switch (band) {
      case MaterialsGroupingOption.COMPANY:
        return [this.getCompanyNamesByMaterial(viewModel) || UNASSIGNED]
      case MaterialsGroupingOption.STATUS:
        return [getMaterialStatusDisplayName(status) || UNASSIGNED]

      case MaterialsGroupingOption.MATERIAL_CATEGORY:
        return [
          this.materialCategoryStore.getCategoryNameById(material.categoryId) ||
            UNASSIGNED,
        ]
      case MaterialsGroupingOption.MATERIAL:
        return [material.productName || UNASSIGNED]
      case MaterialsGroupingOption.MATERIAL_DESCRIPTION:
        return [description || UNASSIGNED]

      case MaterialsGroupingOption.BUILDING:
      case MaterialsGroupingOption.LEVEL:
      case MaterialsGroupingOption.ZONE:
      case MaterialsGroupingOption.AREA:
      case MaterialsGroupingOption.GATE:
      case MaterialsGroupingOption.ROUTE:
      case MaterialsGroupingOption.EQUIPMENT:
      case MaterialsGroupingOption.LOGISTICS_OBJECT:
      case MaterialsGroupingOption.VERTICAL_OBJECT:
      case MaterialsGroupingOption.INTERIOR_PATH:
      case MaterialsGroupingOption.INTERIOR_DOOR:
      case MaterialsGroupingOption.STAGING:
        return this.getLocationFormattedIds(installLocation, band)

      case MaterialsGroupingOption.PLANNED_INSTALL_LOCATION:
        return [
          !installLocation?.id
            ? UNASSIGNED
            : `${installLocation.id}${LOCATION_SEPARATOR}${installLocation.type}`,
        ]
      case MaterialsGroupingOption.PLANNED_DELIVERY_LOCATION:
        return [
          !deliveryLocation?.id
            ? UNASSIGNED
            : `${deliveryLocation.id}${LOCATION_SEPARATOR}${deliveryLocation.type}`,
        ]

      case MaterialsGroupingOption.CURRENT_LOCATION:
        return this.getCurrentLocationFormattedIds(viewModel)
      case MaterialsGroupingOption.DATE:
        return hasDeliveries
          ? relatedDeliveries.map(d => d.startDate?.toString() || UNASSIGNED)
          : [UNASSIGNED]
      case MaterialsGroupingOption.VENDOR:
        return [this.getVendorNamesByMaterial(viewModel) || UNASSIGNED]
      case MaterialsGroupingOption.NONE:
        return [MaterialsGroupingOption.NONE]
      default:
        break
    }
  }

  @action.bound
  public openDeliveryForm() {
    this.isDeliveryFormOpened = true
  }

  @action.bound
  public hideAllOpenedForms() {
    this.isDeliveryFormOpened = false
    this.isTransferCreationOpened = false
    this.selectedTransferForm = null
    this.transferFormTypeId = null
    this.transferFormFields = null
  }

  @action.bound
  public toggleWarningDialog() {
    this.isWarningDialogOpened = !this.isWarningDialogOpened
  }

  @action.bound
  public toggleWrongStatusDialog() {
    this.isWrongStatusDialogOpened = !this.isWrongStatusDialogOpened
  }

  @action.bound
  public openNewTransferForm(
    viewModelId: string,
    selectedLocationsMap: Map<string, ILocationTransferPair>,
  ) {
    this.hideAllOpenedForms()

    const viewModel = this.filteredCollection.find(vm => vm.id === viewModelId)

    if (!this.transferFormType || !viewModel) {
      return
    }

    const locationToTypeField = this.transferFormType.initialStep.fields.find(
      f => f.type === PermitFieldType.Location,
    )
    const materialTypeField = this.transferFormType.initialStep.fields.find(
      f => f.type === PermitFieldType.Material,
    )

    this.transferFormTypeId = this.transferFormType.id
    this.transferFormFields = []
    const moveToLocation =
      viewModel.installLocation || viewModel.deliveryLocation

    if (
      locationToTypeField &&
      moveToLocation &&
      moveToLocation.type !== LocationType.OffloadingEquipment
    ) {
      this.transferFormFields.push({
        fieldId: locationToTypeField.id,
        type: locationToTypeField.type,
        locationValues: [
          {
            id: moveToLocation.id,
            type: moveToLocation.type,
          },
        ],
      })
    }
    if (materialTypeField) {
      this.transferFormFields.push({
        fieldId: materialTypeField.id,
        type: materialTypeField.type,
        materialValues: [...selectedLocationsMap.values()].map(pair => ({
          materialId: viewModel.material.id,
          procurementId: viewModel.procurementId,
          quantity: pair.quantity?.toString(),
          locationId: pair.location?.id,
        })),
      })
    }

    this.isTransferCreationOpened = true
  }

  @action.bound
  public openExistingTransfer(transferId: string) {
    this.hideAllOpenedForms()

    const transferForm = this.sitePermitsStore.getFormById(transferId)

    if (transferForm) {
      this.selectedTransferForm = transferForm
    }
  }

  protected getTreeNodeObjsByBand(currentBand: string): ITreeNodeObj[] {
    const { getMonthDayAndTimeToDisplay } = this.projectDateStore

    switch (currentBand) {
      case MaterialsGroupingOption.NONE:
        return [
          {
            id: MaterialsGroupingOption.NONE,
            name: MaterialsGroupingOption.NONE,
          },
        ]
      case MaterialsGroupingOption.COMPANY:
        return [
          ...this.filteredCollection
            .flatMap(viewModel => this.getCompanyNamesByMaterial(viewModel))
            .map(groupingCommonMapper),
          groupingCommonMapper(UNASSIGNED),
        ]

      case MaterialsGroupingOption.STATUS:
        return Object.values(MaterialStatus).map(status => {
          return groupingCommonMapper(getMaterialStatusDisplayName(status))
        })

      case MaterialsGroupingOption.VENDOR:
        return [
          ...this.filteredCollection
            .flatMap(viewModel => this.getVendorNamesByMaterial(viewModel))
            .map(groupingCommonMapper),
          groupingCommonMapper(UNASSIGNED),
        ]

      case MaterialsGroupingOption.DATE:
        return [
          ...this.filteredCollection
            .flatMap(({ relatedDeliveries }) =>
              relatedDeliveries.map(d => d.startDate),
            )
            .map(date => {
              return {
                name: getMonthDayAndTimeToDisplay(date) || UNASSIGNED,
                id: date.toString() || UNASSIGNED,
              }
            }),
          groupingCommonMapper(UNASSIGNED),
        ]

      case MaterialsGroupingOption.MATERIAL_CATEGORY:
        return Array.from(
          reduceByMaterialProp(
            this.materialCategoryStore.list,
            category => category.name,
          ).values(),
        )
      case MaterialsGroupingOption.MATERIAL:
        return Array.from(
          reduceByMaterialProp(
            this.materialsStore.list,
            material => material.productName,
          ).values(),
        )
      case MaterialsGroupingOption.MATERIAL_DESCRIPTION:
        return Array.from(
          reduceByMaterialProp(
            this.filteredCollection,
            viewModel => viewModel.description,
          ).values(),
        )

      case MaterialsGroupingOption.BUILDING:
        return [
          ...this.locationAttributesStore.buildingsStore.listWithDeletedItems.map(
            groupingAttributeMapper,
          ),
          groupingCommonMapper(UNASSIGNED),
        ]
      case MaterialsGroupingOption.LEVEL:
        return [
          ...this.locationAttributesStore.levelsStore.listWithDeletedItems.map(
            groupingAttributeMapper,
          ),
          groupingCommonMapper(UNASSIGNED),
        ]
      case MaterialsGroupingOption.ZONE:
        return [
          ...this.locationAttributesStore.zonesStore.listWithDeletedItems.map(
            groupingAttributeMapper,
          ),
          groupingCommonMapper(UNASSIGNED),
        ]
      case MaterialsGroupingOption.AREA:
        return [
          ...this.locationAttributesStore.areasStore.listWithDeletedItems.map(
            groupingAttributeMapper,
          ),
          groupingCommonMapper(UNASSIGNED),
        ]
      case MaterialsGroupingOption.GATE:
        return [
          ...this.locationAttributesStore.gatesStore.listWithDeletedItems.map(
            groupingAttributeMapper,
          ),
          groupingCommonMapper(UNASSIGNED),
        ]
      case MaterialsGroupingOption.ROUTE:
        return [
          ...this.locationAttributesStore.routesStore.listWithDeletedItems.map(
            groupingAttributeMapper,
          ),
          groupingCommonMapper(UNASSIGNED),
        ]
      case MaterialsGroupingOption.EQUIPMENT:
        return [
          ...this.locationAttributesStore.offloadingEquipmentsStore.listWithDeletedItems.map(
            groupingAttributeMapper,
          ),
          groupingCommonMapper(UNASSIGNED),
        ]
      case MaterialsGroupingOption.LOGISTICS_OBJECT:
        return [
          ...this.locationAttributesStore.logisticsObjectsStore.listWithDeletedItems.map(
            groupingAttributeMapper,
          ),
          groupingCommonMapper(UNASSIGNED),
        ]
      case MaterialsGroupingOption.VERTICAL_OBJECT:
        return [
          ...this.locationAttributesStore.verticalObjectsStore.listWithDeletedItems.map(
            groupingAttributeMapper,
          ),
          groupingCommonMapper(UNASSIGNED),
        ]
      case MaterialsGroupingOption.INTERIOR_PATH:
        return [
          ...this.locationAttributesStore.interiorPathsStore.listWithDeletedItems.map(
            groupingAttributeMapper,
          ),
          groupingCommonMapper(UNASSIGNED),
        ]
      case MaterialsGroupingOption.INTERIOR_DOOR:
        return [
          ...this.locationAttributesStore.interiorDoorsStore.listWithDeletedItems.map(
            groupingAttributeMapper,
          ),
          groupingCommonMapper(UNASSIGNED),
        ]
      case MaterialsGroupingOption.STAGING:
        return [
          ...this.locationAttributesStore.stagingsStore.listWithDeletedItems.map(
            groupingAttributeMapper,
          ),
          groupingCommonMapper(UNASSIGNED),
        ]
      case MaterialsGroupingOption.CURRENT_LOCATION:
      case MaterialsGroupingOption.PLANNED_INSTALL_LOCATION:
      case MaterialsGroupingOption.PLANNED_DELIVERY_LOCATION:
        return [
          ...this.locationAttributesStore.allAttributesWithDeletedItems.map(
            groupingAttributeMapper,
          ),
          groupingCommonMapper(UNASSIGNED),
        ]

      default:
        return []
    }
  }

  @computed
  protected get bandObjectMap(): {
    [bandType: string]: { [groupName: string]: IMaterialViewModel[] }
  } {
    const map = {}

    Object.values(MaterialsGroupingOption).forEach(band => {
      const bandMap = (map[band] = {})

      this.filteredCollection.forEach(viewModel => {
        const groupNames: string[] = this.getCategoryIds(viewModel, band) || []
        if (!groupNames.length) {
          groupNames.push(UNASSIGNED)
        }

        groupNames.forEach(group => {
          const groupName = group || UNASSIGNED

          if (!bandMap[groupName]) {
            bandMap[groupName] = []
          }
          bandMap[groupName].push(viewModel)
        })
      })
    })

    return map
  }

  protected toRows(
    viewModels: IMaterialViewModel[],
    _company?: string,
    level?: number,
  ) {
    return viewModels.map(viewModel => {
      const {
        id: viewModelId,
        procurementId,
        material,
        installLocation,
        deliveryLocation,
        description,
        quantity,
        status,
        relatedDeliveries,
        transferForms,
      } = viewModel
      const { getMonthDayYearAndTimeToDisplay } = this.projectDateStore
      const {
        [DataKeys.CURRENT_MATERIAL_LOCATION]: currentLocationPairs,
        [DataKeys.READY_TO_INSTALL_QUANTITY]: readyToInstallQuantity,
        [DataKeys.TOTAL_ON_SITE_QUANTITY]: onSiteQuantity,
        [DataKeys.BOOKED_QUANTITY]: bookedQuantity,
        [DataKeys.MATERIAL_NOTE]: materialNotes,
      } = this.prepareInfoForMaterial(viewModel)

      const deliveryCellData: IFormCellData = {
        id: viewModelId,
        procurementId,
        materialId: material.id,
        relatedDeliveries,
        itemsCount: relatedDeliveries.filter(d => !d.isOnSiteOrInspected)
          .length,
      }
      const dateCellData: IFormCellData = {
        id: viewModelId,
        procurementId,
        materialId: material.id,
        relatedDeliveries,
        itemsCount: relatedDeliveries.reduce(
          (set, d) => (d.isOnSiteOrInspected ? set : set.add(d.startDate)),
          new Set<number>(),
        ).size,
      }
      const vendorCellData: IFormCellData = {
        id: viewModelId,
        procurementId,
        materialId: material.id,
        ...getDeliveryInfoProps(relatedDeliveries, d => d.vendor),
      }
      const currentLocationCellData: ILocationCellData = {
        id: viewModelId,
        plannedInstallLocationId: installLocation?.id,
        plannedDeliveryLocationId: deliveryLocation?.id,
        locationTransferPairs: currentLocationPairs,
      }
      const companyCellData: IFormCellData = {
        id: viewModelId,
        procurementId,
        materialId: material.id,
        ...getDeliveryInfoProps(relatedDeliveries, d => d.company),
      }
      const transferIdCellData: IFormCellData = {
        id: viewModelId,
        procurementId,
        materialId: material.id,
        transferForms,
        itemsCount: transferForms.filter(d => !d.isDone).length,
      }
      const transferDateCellData: IFormCellData = {
        id: viewModelId,
        procurementId,
        materialId: material.id,
        transferForms,
        itemsCount: transferForms.reduce(
          (set, d) => (d.isDone ? set : set.add(d.startDate)),
          new Set<number>(),
        ).size,
      }

      const plannedQuantity = getFixedNum(quantity || 0)
      const formattedOnSiteQuantity = getFixedNum(onSiteQuantity)
      const formattedReadyToInstallQty = getFixedNum(readyToInstallQuantity)

      const data = {
        [DataKeys.ID]: viewModelId,
        [DataKeys.CHECKBOX]: this.selection.get(viewModelId),
        [DataKeys.PROCUREMENT_ID]: procurementId || NO_VALUE,
        [DataKeys.PRODUCT_CATEGORY]:
          this.materialCategoryStore.getCategoryNameById(material.categoryId) ||
          NO_VALUE,
        [DataKeys.NAME]: material.productName || NO_VALUE,
        [DataKeys.DESCRIPTION]: {
          viewModelId,
          value: description || NO_VALUE,
          onChange: this.changeDescription,
        } as IEditableCellModel,
        [DataKeys.PLANNED_INSTALL_LOCATION]: installLocation,
        [DataKeys.PLANNED_DELIVERY_LOCATION]: deliveryLocation,
        [DataKeys.CURRENT_MATERIAL_LOCATION]: currentLocationCellData,
        [DataKeys.RESPONSIBLE_COMPANY]: companyCellData,
        [DataKeys.VENDOR]: vendorCellData,
        [DataKeys.STATUS]: status,
        [DataKeys.PLANNED_QUANTITY]: {
          viewModelId,
          value: plannedQuantity.toString(),
          onChange: this.changeMaterialQuantity,
          isNumber: true,
        } as IEditableCellModel,
        [DataKeys.READY_TO_INSTALL_QUANTITY]: formattedReadyToInstallQty,
        [DataKeys.PERCENT_COMPLETE]: `${getPercentComplete(
          formattedReadyToInstallQty,
          plannedQuantity,
        )}%`,
        [DataKeys.REMAINING_QUANTITY]: getRemainingQuantity(
          formattedReadyToInstallQty,
          plannedQuantity,
        ),
        [DataKeys.TOTAL_ON_SITE_QUANTITY]: formattedOnSiteQuantity,
        [DataKeys.TO_MOVE_QUANTITY]: getRemainingQuantity(
          formattedReadyToInstallQty,
          formattedOnSiteQuantity,
        ),
        [DataKeys.NEXT_DELIVERY]: deliveryCellData,
        [DataKeys.NEXT_DELIVERY_BOOKING_DATE]: dateCellData,
        [DataKeys.BOOKED_QUANTITY]: bookedQuantity,
        [DataKeys.MATERIAL_TRANSFER_ID]: transferIdCellData,
        [DataKeys.MATERIAL_TRANSFER_DATE]: transferDateCellData,
        [DataKeys.MATERIAL_NOTE]: materialNotes.join(', ') || NO_VALUE,
        [DataKeys.RECORDED_LAST_UPDATED]: material.updatedAt
          ? getMonthDayYearAndTimeToDisplay(material.updatedAt)
          : NO_VALUE,
      }

      return { data, height: MATERIAL_DATA_ROW_HEIGHT, level }
    })
  }

  protected createCategoryRow(categoryId: string, count: number): ILWFCRow {
    const categoryInstances = this.categoryToInstancesMap[categoryId]

    const category: ILWFCCategory = {
      categoryId,
      shortCategoryLabel: this.getCategoryLabelById(categoryId),
      categoryLabel: `${this.getCategoryLabelById(categoryId)} (${count})`,
      isChecked: categoryInstances.every(
        inst =>
          !this.canSelectInstance(inst) || this.selection.get(inst[this.idKey]),
      ),
    }

    return { category, data: {}, height: CATEGORY_ROW_HEIGHT, level: 0 }
  }

  private prepareInfoForMaterial(viewModel: IMaterialViewModel) {
    const {
      material,
      procurementId,
      deliveryLocation,
      installLocation,
      relatedDeliveries,
      transferForms,
    } = viewModel

    const materialLocationMap = new MaterialLocationMap()

    const data = relatedDeliveries.reduce(
      (acc, delivery) => {
        const { isOnSiteOrInspected, getMaterialsByIds } = delivery
        const materialsBy = getMaterialsByIds(material.id, procurementId)

        materialsBy.forEach(material => {
          const location = this.getLocationById(material.locationId)
          if (location && isOnSiteOrInspected) {
            materialLocationMap.increaseQuantity(location, material.quantity)
          }

          if (
            material.note &&
            !acc[DataKeys.MATERIAL_NOTE].includes(material.note)
          ) {
            acc[DataKeys.MATERIAL_NOTE].push(material.note)
          }

          if (isOnSiteOrInspected) {
            acc[DataKeys.TOTAL_ON_SITE_QUANTITY] += material.quantity
          } else {
            acc[DataKeys.BOOKED_QUANTITY] += material.quantity
          }
        })

        return acc
      },
      {
        [DataKeys.CURRENT_MATERIAL_LOCATION]: [] as ILocationTransferPair[],
        [DataKeys.VENDOR]: EMPTY_STRING,
        [DataKeys.READY_TO_INSTALL_QUANTITY]: 0,
        [DataKeys.TOTAL_ON_SITE_QUANTITY]: 0,
        [DataKeys.BOOKED_QUANTITY]: 0,
        [DataKeys.MATERIAL_NOTE]: [],
        [DataKeys.RESPONSIBLE_COMPANY]: EMPTY_STRING,
      },
    )

    this.fillDataFromForms(materialLocationMap, transferForms, viewModel)

    const currentLocations = [...materialLocationMap.values()]
    data[DataKeys.CURRENT_MATERIAL_LOCATION] = currentLocations
    data[DataKeys.READY_TO_INSTALL_QUANTITY] = currentLocations.reduce(
      (quantity, currentLocation) => {
        if (
          !deliveryLocation?.id ||
          deliveryLocation.id === currentLocation.location?.id ||
          (installLocation?.id &&
            installLocation.id === currentLocation.location?.id)
        ) {
          quantity += currentLocation.quantity
        }
        return quantity
      },
      0,
    )
    const onSiteQty = data[DataKeys.TOTAL_ON_SITE_QUANTITY]
    const installQty = data[DataKeys.READY_TO_INSTALL_QUANTITY]
    data[DataKeys.TOTAL_ON_SITE_QUANTITY] =
      installQty > onSiteQty ? installQty : onSiteQty

    data[DataKeys.RESPONSIBLE_COMPANY] =
      this.getCompanyNamesByMaterial(viewModel) || EMPTY_STRING
    data[DataKeys.VENDOR] =
      this.getVendorNamesByMaterial(viewModel) || EMPTY_STRING

    return data
  }

  private fillDataFromForms = (
    materialLocationMap: MaterialLocationMap,
    transferForms: SitePermit[],
    viewModel: IMaterialViewModel,
  ) => {
    const { material, procurementId } = viewModel
    transferForms.forEach(form => {
      const locationTo = this.getLocationBySiteLocationObj(form.locations[0])

      form.getMaterialsByIds(material.id, procurementId).forEach(mat => {
        const currentLocation = this.getLocationById(mat?.locationId)
        const materialQty = Number(mat?.quantity) || 0
        if (!form.isDone) {
          return materialLocationMap.addForm(form, materialQty, currentLocation)
        }
        materialLocationMap.increaseQuantity(locationTo, materialQty)
        materialLocationMap.decreaseQuantity(currentLocation?.id, materialQty)
      })
    })
  }

  protected compareRows = (
    { data: aRowData }: ILWFCRow,
    { data: bRowData }: ILWFCRow,
  ): number => {
    const { columnKey, order } = this.sortState

    const v1 = this.getSortingValue(aRowData, columnKey)
    const v2 = this.getSortingValue(bRowData, columnKey)

    if (typeof v1 === 'string' && typeof v2 === 'string') {
      if (unassignedValues.includes(v1)) {
        return 1
      }
      if (unassignedValues.includes(v2)) {
        return -1
      }
      return this.collator.compare(v1, v2) * order
    }

    if (typeof v1 === 'number' && typeof v2 === 'number') {
      return (v1 - v2) * order
    }

    return 0
  }

  private getSortingValue(rowData: LWFCRowData, columnKey: string) {
    switch (columnKey) {
      case DataKeys.DESCRIPTION:
      case DataKeys.PLANNED_QUANTITY:
        return rowData[columnKey]?.value || EMPTY_STRING
      case DataKeys.STATUS:
        return getMaterialStatusDisplayName(rowData[columnKey])

      case DataKeys.PLANNED_INSTALL_LOCATION:
        return rowData[columnKey]?.name || EMPTY_STRING
      case DataKeys.PLANNED_DELIVERY_LOCATION:
        return rowData[columnKey]?.name || EMPTY_STRING

      case DataKeys.NEXT_DELIVERY:
        const deliveryCellData = rowData[columnKey] as IFormCellData
        return deliveryCellData.itemsCount > 1
          ? deliveryCellData.itemsCount.toString()
          : deliveryCellData.relatedDeliveries[0]?.codeToDisplay(
              this.companiesStore,
            ) || EMPTY_STRING

      case DataKeys.NEXT_DELIVERY_BOOKING_DATE:
        const dateCellData = rowData[columnKey] as IFormCellData
        return dateCellData.itemsCount > 1
          ? dateCellData.itemsCount.toString()
          : dateCellData.relatedDeliveries[0]?.startDate?.toString() ||
              EMPTY_STRING

      case DataKeys.MATERIAL_TRANSFER_ID:
        const transferIdCellData = rowData[columnKey] as IFormCellData
        return transferIdCellData.itemsCount > 1
          ? transferIdCellData.itemsCount.toString()
          : transferIdCellData.transferForms[0]?.code || EMPTY_STRING

      case DataKeys.MATERIAL_TRANSFER_DATE:
        const transferDateCellData = rowData[columnKey] as IFormCellData
        return transferDateCellData.itemsCount > 1
          ? transferDateCellData.itemsCount.toString()
          : transferDateCellData.transferForms[0]?.startDate?.toString() ||
              EMPTY_STRING

      case DataKeys.CURRENT_MATERIAL_LOCATION:
        const locationCellData = rowData[columnKey] as ILocationCellData
        return locationCellData.locationTransferPairs?.length > 1
          ? locationCellData.locationTransferPairs?.length.toString()
          : locationCellData.locationTransferPairs[0]?.location?.name ||
              EMPTY_STRING

      case DataKeys.VENDOR:
        const vendorCellData = rowData[columnKey] as IFormCellData
        return vendorCellData.itemsCount > 1
          ? vendorCellData.itemsCount.toString()
          : vendorCellData.relatedDeliveries[0]?.vendor || EMPTY_STRING

      case DataKeys.RESPONSIBLE_COMPANY:
        const companyCellData = rowData[columnKey] as IFormCellData
        return companyCellData.itemsCount > 1
          ? companyCellData.itemsCount.toString()
          : this.companiesStore.getCompanyNameById(
              companyCellData.relatedDeliveries[0]?.company,
              EMPTY_STRING,
            )

      default:
        const value = rowData[columnKey]
        return value === NO_SPECIFIED ? EMPTY_STRING : value
    }
  }

  protected searchFiltering = (viewModel: IMaterialViewModel) => {
    const {
      material,
      installLocation,
      deliveryLocation,
      status,
      procurementId,
      description,
    } = viewModel
    const key = this.filter.searchKey.toLowerCase()
    const categoryName = this.materialCategoryStore.getCategoryNameById(
      material.categoryId,
    )

    const {
      [DataKeys.VENDOR]: vendors,
      [DataKeys.CURRENT_MATERIAL_LOCATION]: currentLocations,
      [DataKeys.RESPONSIBLE_COMPANY]: companyNames,
    } = this.prepareInfoForMaterial(viewModel)

    const entityValues: string[] = [
      procurementId,
      companyNames,
      vendors,
      ...currentLocations.map(p => p.location.name),
      installLocation?.name,
      deliveryLocation?.name,
      material.productName,
      description,
      categoryName,
      getMaterialStatusDisplayName(status),
    ]
    return entityValues.some(value => value?.toLowerCase()?.includes(key))
  }

  private getLocationFormattedIds = (
    location: LocationBase,
    locationType?: string,
  ): string[] => {
    if (!location?.id) {
      return [UNASSIGNED]
    }

    const locations = [
      location,
      ...location.getHierarchyChainObjs(this.tagsStore.tagStoreByTagTypeMap),
    ].filter(l => l.id && (!locationType || l.type === locationType))

    const locationIds = locations.reduce((set, loc) => {
      set.add(`${loc.id}${LOCATION_SEPARATOR}${loc.type}`)
      return set
    }, new Set<string>())

    return locationIds.size ? Array.from(locationIds) : [UNASSIGNED]
  }

  private getCompanyNamesByMaterial = (
    viewModel: IMaterialViewModel,
  ): string => {
    const companiesMap = (viewModel?.relatedDeliveries || []).reduce(
      (map, delivery) => {
        const company = this.companiesStore.getCompanyById(delivery.company)
        if (company?.id && !map.has(company.id)) {
          map.set(company.id, company.name)
        }
        return map
      },
      new Map<string, string>(),
    )

    return [...companiesMap.values()].join(', ')
  }

  private getVendorNamesByMaterial = (
    viewModel: IMaterialViewModel,
  ): string => {
    const companiesMap = (viewModel?.relatedDeliveries || []).reduce(
      (map, delivery) => {
        const vendor = this.companiesStore.getCompanyById(delivery.vendor)
        if (vendor?.id && !map.has(vendor.id)) {
          map.set(vendor.id, vendor.name)
        }
        return map
      },
      new Map<string, string>(),
    )

    return [...companiesMap.values()].join(', ')
  }

  private sortTreeObjs = (treeNodeObjs: ITreeNodeObj[]) => {
    const map = new Map<string, ITreeNodeObj>()

    treeNodeObjs.forEach(obj => map.set(obj.id, obj))

    return Array.from(map.values()).sort((a, b) => {
      if (unassignedValues.includes(a.id)) {
        return 1
      }
      if (unassignedValues.includes(b.id)) {
        return -1
      }

      return this.collator.compare(a.name, b.name)
    })
  }

  public get areMaterialsHiddenInDelivery(): boolean {
    const {
      projectMaterialOptions,
      delivery: { hiddenFields },
    } = this.state

    const areMaterialFieldsHidden =
      hiddenFields[FieldIds.MATERIALS_SECTION] ||
      (projectMaterialOptions?.isCSIDisabled &&
        projectMaterialOptions?.isCSISubCategoriesDisabled)

    return areMaterialFieldsHidden
  }

  public get isNewDeliveryBtnHidden(): boolean {
    return this.areMaterialsHiddenInDelivery || !this.isSomeRowSelected
  }

  public get isNewDeliveryBtnActive(): boolean {
    return (
      this.isDeliveryFormOpened && this.deliveryDetailsStore.isCreateModeActive
    )
  }

  public get canAddMaterialsToDelivery(): boolean {
    return this.selectedInstancesCount <= MAX_SELECTED_MATERIALS_FOR_FORM
  }

  public openNewFormAndInitIfNeed = () => {
    this.deliveryDetailsStore.setNewDeliveryMode()
    this.openDeliveryForm()
    this.setMaterialsForDelivery()
  }

  @action.bound
  public openExistingDelivery(deliveryId: string) {
    this.resetSelection()
    this.openDeliveryForm()

    const existingDelivery = this.deliveriesStore.byId.get(deliveryId)

    this.deliveryDetailsStore.setViewMode(existingDelivery)
    this.existingMaterialsCount = existingDelivery.materials?.length || 0
  }

  @action.bound
  public resetValuesForOpenedDelivery(
    subscriptionChangeEvents: ISubscription[],
  ) {
    const deliveryChangeEvent = subscriptionChangeEvents?.find(
      d => d.delivery.id === this.deliveryDetailsStore.displayedDelivery?.id,
    )?.delivery

    if (deliveryChangeEvent) {
      this.resetSelection()
      this.existingMaterialsCount =
        deliveryChangeEvent.item?.materials?.length || 0
    }
  }

  public openNewFormFromCell = (viewModelId: string) => {
    const isSelected = this.selection.has(viewModelId)
    const selectionsCount = this.selectedInstancesCount + Number(!isSelected)
    const { setNewDeliveryMode, isViewModeActive } = this.deliveryDetailsStore

    if (isViewModeActive) {
      setNewDeliveryMode()
    }

    if (!this.isDeliveryFormOpened && this.shouldShowWarning(selectionsCount)) {
      return this.toggleWarningDialog()
    }

    if (!isSelected) {
      this.onCheckboxClick(viewModelId)
    }

    if (
      this.canAddMaterialsToDelivery &&
      (!this.isDeliveryFormOpened || isViewModeActive)
    ) {
      this.openNewFormAndInitIfNeed()
    }
  }

  public onCheckboxClick = (viewModelId: string) => {
    const selectionsCount =
      this.selectedInstancesCount + Number(!this.selection.has(viewModelId))

    this.selectMaterial(
      selectionsCount,
      this.toggleInstance.bind(null, viewModelId),
    )
  }

  public onSelectAll = () => {
    this.selectMaterial(this.displayedCount, this.selectAll)
  }

  public onResetAllSelections = () => {
    this.selectMaterial(0, this.resetSelection)
  }

  private selectMaterial = (selectionsCount: number, selectFn: () => void) => {
    if (this.isDeliveryFormOpened && this.shouldShowWarning(selectionsCount)) {
      return this.toggleWarningDialog()
    }

    if (this.isDeliveryFormOpened && !this.canChangeExistingDelivery) {
      return this.toggleWrongStatusDialog()
    }

    selectFn()

    this.setMaterialsForDelivery()
  }

  private setMaterialsForDelivery = () => {
    if (!this.isDeliveryFormOpened || !this.canAddMaterialsToDelivery) {
      return
    }

    const { updateDeliveryWithMaterials, isViewModeActive } =
      this.deliveryDetailsStore

    // Should materialIds be unique in this array?
    const deliveryMaterials = this.selectedInstances.map(viewModel => ({
      materialId: viewModel.material.id,
      procurementId: viewModel.procurementId,
      locationId: viewModel.deliveryLocation?.id,
    }))

    updateDeliveryWithMaterials(
      deliveryMaterials,
      isViewModeActive && this.existingMaterialsCount,
    )
  }

  private shouldShowWarning = (selectionsCount: number): boolean => {
    const { displayedDelivery, isViewModeActive } = this.deliveryDetailsStore
    return (
      (isViewModeActive
        ? displayedDelivery.materials.length + selectionsCount
        : selectionsCount) > MAX_SELECTED_MATERIALS_FOR_FORM
    )
  }

  private get canChangeExistingDelivery(): boolean {
    const { displayedDelivery, isViewModeActive } = this.deliveryDetailsStore
    return !isViewModeActive || displayedDelivery?.canChange
  }

  private showUpdateToast(isSuccess?: boolean) {
    showToast(
      isSuccess
        ? Localization.translator.successfullyUpdated
        : Localization.translator.failedToUpdate,
      isSuccess ? ToastTheme.SUCCESS : ToastTheme.ERROR,
      isSuccess ? IconNames.TICK_CIRCLE : IconNames.ERROR,
    )
  }
}
