import { action, computed, observable } from 'mobx'

import { DeliveryFilterType, IDeliveryFilter } from '~/client/graph'
import { CustomFilterDialogModes } from '~/client/src/shared/enums/CustomFilterDialogModes'
import {
  deliveryFilterTypes,
  extendedDeliveryFilterTypes,
} from '~/client/src/shared/enums/DeliveryFilterType'
import Delivery from '~/client/src/shared/models/Delivery'
import CustomDeliveryListFiltersStore from '~/client/src/shared/stores/domain/CustomDeliveryListFilters.store'
import CustomDeliveryListFilter from '~/client/src/shared/types/CustomDeliveryListFilter'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'

import DeliveryFilterStore from '../../../../shared/stores/ui/DeliveryFilter.store'
import DesktopEventStore from '../../../stores/EventStore/DesktopEvents.store'
import DesktopDeliveryViewStore from '../../../views/Deliveries/Deliveries.store'
import DesktopSavedFiltersStore from '../SavedFilters/DesktopSavedFilters.store'

// localization: no display text to translate

export default class DesktopDeliverySavedFiltersStore extends DesktopSavedFiltersStore {
  @observable public customFilterInstance: CustomDeliveryListFilter = null

  public constructor(
    private readonly deliveryFilterStore: DeliveryFilterStore,
    private readonly desktopDeliveryViewStore: DesktopDeliveryViewStore,
    private readonly customDeliveryListFiltersStore: CustomDeliveryListFiltersStore,
    protected readonly eventsStore: DesktopEventStore,
    protected readonly onShowChanged?: (isShown: boolean) => void,
  ) {
    super(eventsStore, onShowChanged)
  }

  @computed
  private get enabledFilterTypes() {
    const { filterInfoMap } = this.eventsStore.appState.deliveryFiltersSettings
    return Object.keys(filterInfoMap)
  }

  public get customFilters(): CustomDeliveryListFilter[] {
    return this.customDeliveryListFiltersStore.list
  }

  public get selectedCustomFilter(): CustomDeliveryListFilter {
    const filterId = this.selectedCustomFilterId || this.appliedCustomFilterId
    return this.customDeliveryListFiltersStore.byId.get(filterId)
  }

  public getDeliveryCountByCustomFilter = (
    filter?: CustomDeliveryListFilter,
  ) => {
    const selectedFilter = filter || this.customFilterInstance
    return this.deliveriesInSelectedFilters(selectedFilter.filtersByFilterType)
      .length
  }

  @computed
  public get filtersCount(): number {
    if (!this.customFilterInstance) {
      return 0
    }

    const { filtersByFilterType: deliveryFiltersByFilterType } =
      this.customFilterInstance
    return deliveryFiltersByFilterType.reduce((sum, filters) => {
      if (!filters.values) {
        return sum
      }
      return sum + filters.values.length
    }, 0)
  }

  @computed
  public get filtersDescriptions(): string[] {
    if (!this.customFilterInstance) {
      return []
    }
    const { filtersByFilterType: deliveryFiltersByFilterType } =
      this.customFilterInstance

    const filtersDescriptions = []
    deliveryFiltersByFilterType
      .filter(filter => {
        return filter.values && filter.values.length
      })
      .map(filter => {
        filtersDescriptions.push(
          (
            this.getSelectedFilterValues(filter.type, filter.values, true) || []
          ).join(', '),
        )
      })
    return filtersDescriptions
  }

  public deleteCustomFilter = () => {
    if (!this.selectedCustomFilter) {
      return
    }

    this.customDeliveryListFiltersStore.removeOne(this.selectedCustomFilter.id)
    if (this.selectedCustomFilter.id === this.appliedCustomFilterId) {
      this.resetCustomFilter()
      this.resetAllFilters()
    }

    this.closeSavedFilters()
  }

  @action.bound
  public editCustomFilter() {
    if (!this.selectedCustomFilter) {
      return
    }
    this.customFilterInstance = this.selectedCustomFilter
    this.editableFilterName = this.selectedCustomFilter.name
    this.editableFilterIsPublic = this.selectedCustomFilter.isPublic
    this.filterDialogMode = CustomFilterDialogModes.Edit
    this.shouldSaveFilterDialogShow = true
  }

  @action.bound
  public saveCustomFilter() {
    this.customFilterInstance.name = this.editableFilterName
    this.customFilterInstance.isPublic = this.editableFilterIsPublic

    this.customDeliveryListFiltersStore.saveOne(this.customFilterInstance)
    this.closeSavedFilters()
  }

  @action.bound
  public onSaveFiltersClicked() {
    const codeFilterValuesByTypeMap: Map<string, string[]> = new Map()

    this.enabledFilterTypes.forEach(filterType => {
      codeFilterValuesByTypeMap.set(
        filterType,
        this.getSelectedFilterValues(filterType),
      )
    })
    this.createCustomFilterInstance(codeFilterValuesByTypeMap)
    this.showSaveFilterDialog()
  }

  @action.bound
  public applyCustomFilter() {
    const { filtersByFilterType: deliveryFiltersByFilterType } =
      this.selectedCustomFilter

    deliveryFiltersByFilterType.forEach(({ type, values = [] }) => {
      if (this.isFilterTypeRelevantForViewMode(type)) {
        this.applyFilterValuesForDeliveryList(type, values)
      }
    })

    this.deliveryFilterStore.syncFilters()
    this.closeSavedFilters()
    this.eventsStore.appState.filters.appliedCustomFilterId =
      this.selectedCustomFilterId
  }

  public getSelectedFilterValues(
    filterType: string,
    filterValues?: string[],
    isDescription?: boolean,
  ): string[] {
    const { fieldsMap } = this.eventsStore.appState.deliveryFilters

    if (filterType === DeliveryFilterType.Status) {
      return this.getFilterValuesFromDeliveryList(filterType)
    }
    const values =
      filterValues ||
      Array.from(fieldsMap[filterType].selectedFilterOptions.keys())

    return !isDescription
      ? values
      : this.desktopDeliveryViewStore.getFilterValueDescription(
          filterType,
          values,
        )
  }

  protected createCustomFilterInstance(
    codeFilterValuesByTypeMap: Map<string, string[]>,
  ) {
    const codesByType: IDeliveryFilter[] = []
    codeFilterValuesByTypeMap.forEach((codeIds, typeId) => {
      codesByType.push({
        values: (codeIds || []).map(code => code || UNASSIGNED),
        type: typeId as DeliveryFilterType,
      })
    })
    this.customFilterInstance =
      this.customDeliveryListFiltersStore.createFromValues(
        codesByType,
        this.editableFilterIsPublic,
        [],
      )
  }

  private getFilterValuesFromDeliveryList(
    filterType: DeliveryFilterType,
  ): string[] {
    if (this.selectedCustomFilter) {
      return this.selectedCustomFilter.filtersByFilterType.find(
        value => value.type === filterType,
      ).values
    }
    return Array.from(
      this.eventsStore.appState.deliveryFilters.fieldsMap[
        filterType
      ].selectedFilterOptions.keys(),
    )
  }

  private applyFilterValuesForDeliveryList(
    type: DeliveryFilterType,
    values: string[] = [],
  ): void {
    const filterStore = this.deliveryFilterStore.filterStoresByTypeMap[type]
    filterStore.clickOnSelectAll()
    Array.from(filterStore.filter.selectedFilterOptions.keys()).forEach(
      option => {
        if (values && !values.includes(option)) {
          filterStore.selectedOptions.delete(option)
        }
      },
    )
    filterStore.clickOnApply()
  }

  private deliveriesInSelectedFilters = (
    deliveryFiltersByFilterType?: IDeliveryFilter[],
  ) => {
    const { availableDeliveries } = this.desktopDeliveryViewStore
    const relevantFilters = deliveryFiltersByFilterType.filter(fieldType =>
      this.isFilterTypeRelevantForViewMode(fieldType.type),
    )

    return availableDeliveries.filter(delivery => {
      return relevantFilters.every(filterType =>
        this.isDeliveryInFilterType(delivery, filterType),
      )
    })
  }

  private isDeliveryInFilterType = (
    delivery: Delivery,
    { type, values }: IDeliveryFilter,
  ) => {
    const {
      isZoneUnassigned,
      isAreaUnassigned,
      isOffloadingEquipmentUnassigned,
      isLevelUnassigned,
      isBuildingUnassigned,
      isGateUnassigned,
      isRouteUnassigned,
    } = this.desktopDeliveryViewStore

    switch (type) {
      case DeliveryFilterType.Status:
        if (!values.includes(delivery.status)) {
          return false
        }
        break
      case DeliveryFilterType.Company:
        if (!values.includes(delivery.company)) {
          return false
        }
        break
      case DeliveryFilterType.Zone:
        if (
          !values.includes(delivery.zone) &&
          !(values.includes(UNASSIGNED) && isZoneUnassigned(delivery))
        ) {
          return false
        }
        break
      case DeliveryFilterType.Building:
        if (
          !values.includes(delivery.building) &&
          !(values.includes(UNASSIGNED) && isBuildingUnassigned(delivery))
        ) {
          return false
        }
        break
      case DeliveryFilterType.Equipment:
        if (
          !values.some(equipment =>
            equipment === UNASSIGNED
              ? isOffloadingEquipmentUnassigned(delivery)
              : delivery.isOffloadingEquipmentId(equipment),
          )
        ) {
          return false
        }
        break
      case DeliveryFilterType.Gate:
        if (
          !values.includes(delivery.gate) &&
          !(values.includes(UNASSIGNED) && isGateUnassigned(delivery))
        ) {
          return false
        }
        break
      case DeliveryFilterType.Route:
        if (
          !values.includes(delivery.route) &&
          !(values.includes(UNASSIGNED) && isRouteUnassigned(delivery))
        ) {
          return false
        }
        break
      case DeliveryFilterType.Level:
        if (
          !values.includes(delivery.level) &&
          !(values.includes(UNASSIGNED) && isLevelUnassigned(delivery))
        ) {
          return false
        }
        break
      case DeliveryFilterType.Area:
        if (
          !values.includes(delivery.area) &&
          !(values.includes(UNASSIGNED) && isAreaUnassigned(delivery))
        ) {
          return false
        }
        break
      case DeliveryFilterType.Staging:
        if (
          !values.includes(delivery.staging) &&
          !(values.includes(UNASSIGNED) && isAreaUnassigned(delivery))
        ) {
          return false
        }
        break
      case DeliveryFilterType.InteriorDoor:
        if (
          !values.includes(delivery.interiorDoor) &&
          !(values.includes(UNASSIGNED) && isAreaUnassigned(delivery))
        ) {
          return false
        }
        break
      case DeliveryFilterType.InteriorPath:
        if (
          !values.includes(delivery.interiorPath) &&
          !(values.includes(UNASSIGNED) && isAreaUnassigned(delivery))
        ) {
          return false
        }
        break
    }

    return true
  }

  private isFilterTypeRelevantForViewMode(type: DeliveryFilterType): boolean {
    if (this.desktopDeliveryViewStore.isCalendarViewMode) {
      return extendedDeliveryFilterTypes.includes(type)
    }

    return deliveryFilterTypes.includes(type)
  }
}
