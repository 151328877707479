import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized'

import Location from '~/client/src/shared/components/SitemapAttributeSelector/Location'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import { IFormDropdownOption } from '../../../../../SitePermitCreationForm.store'

// localization: translated

interface IProps {
  dropdownOptions: IFormDropdownOption[]
  cellMeasurerCache: CellMeasurerCache
  scrollToIndex: number
  isMultiple: boolean

  onOptionClick: (newValue: string) => void
}

const LOCATION_ICON_SIZE = 16
const OVERSCAN_ROW_COUNT = 6

@observer
export default class FormDropdownOptionsList extends React.Component<IProps> {
  private listRef: List

  private static renderNoElementsMessage(): JSX.Element {
    return (
      <div className="text center extra-large pa20">
        {Localization.translator.noResults}
      </div>
    )
  }

  public componentDidMount() {
    const { scrollToIndex } = this.props

    /* 
      react-virtualized has a bug with calling scrollToRow method, 
      it doesn't wait until rows are rendered, and for large lists
      it can scroll partially, the easiest workaround for this is calling it twice.
      
      Some details about the issue - https://github.com/bvaughn/react-virtualized/issues/995
    */
    this.scrollToSelectedRow(scrollToIndex)
    this.scrollToSelectedRow(scrollToIndex)
  }

  public componentDidUpdate(prevProps: IProps) {
    const { dropdownOptions } = this.props

    if (
      dropdownOptions !== prevProps.dropdownOptions ||
      dropdownOptions.length !== prevProps.dropdownOptions.length
    ) {
      this.listRef.recomputeRowHeights()
      this.listRef.forceUpdateGrid()
    }
  }

  public render() {
    const { dropdownOptions, cellMeasurerCache, isMultiple } = this.props

    return (
      <div className="col">
        {isMultiple && (
          <div className="text blue-highlight center py5 bb-light-input-border bg-palette-brand-lightest">
            <Icon icon={IconNames.INFO_SIGN} className="mr5" />
            {Localization.translator.youCanSelectMultipleOptions}
          </div>
        )}
        <div className="virtualized-list-smart-wrapper">
          <AutoSizer>
            {({ width, height }) => (
              <List
                deferredMeasurementCache={cellMeasurerCache}
                ref={this.setListRef}
                width={width}
                height={height}
                rowCount={dropdownOptions.length}
                overscanRowCount={OVERSCAN_ROW_COUNT}
                rowHeight={cellMeasurerCache.rowHeight}
                rowRenderer={this.renderRow}
                noRowsRenderer={FormDropdownOptionsList.renderNoElementsMessage}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    )
  }

  private renderRow = ({ key, style, parent, index }: any): JSX.Element => {
    const option = this.props.dropdownOptions[index]

    if (!option) return null

    return (
      <CellMeasurer
        cache={this.props.cellMeasurerCache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <div ref={registerChild} style={style} className="col">
            {this.renderModalOption(option)}
          </div>
        )}
      </CellMeasurer>
    )
  }

  private renderModalOption = (option: IFormDropdownOption): JSX.Element => {
    if (option.cardOptions?.length) {
      return this.renderCardOption(option)
    }
    return this.renderDefaultOption(option)
  }

  private renderDefaultOption = (option: IFormDropdownOption): JSX.Element => {
    const { onOptionClick } = this.props

    return (
      <div
        onClick={onOptionClick.bind(null, option.value)}
        className={classList({
          'select-option col y-center bb-light-input-border px16 py4 pointer':
            true,
          selected: !!option.isSelected,
        })}
      >
        <div className="row">
          {option.icon}
          <span className="ellipsis" title={option.title || NO_VALUE}>
            {option.title || NO_VALUE}
          </span>
        </div>
      </div>
    )
  }

  private renderCardOption = (option: IFormDropdownOption): JSX.Element => {
    const { onOptionClick } = this.props

    return (
      <div
        onClick={onOptionClick.bind(null, option.value)}
        className={classList({
          'select-option col y-center bb-light-input-border px16 py4 pointer':
            true,
          selected: !!option.isSelected,
        })}
      >
        {option.cardOptions.map((cardOpt, idx) => (
          <div
            key={`${cardOpt.value}|${idx}`}
            className="row x-between y-start py4"
          >
            <span
              className="card-title text large light ellipsis nowrap no-flex"
              title={cardOpt.title}
            >
              {cardOpt.title}
            </span>
            <span
              className={classList({
                row: !!cardOpt.location,
                'text large ellipsis flex-unset': true,
                bold: cardOpt.hasBoldFont,
              })}
              title={cardOpt.value}
            >
              {cardOpt.location ? (
                <Location
                  tagClassName="card-location"
                  contentClassName="text large"
                  dto={cardOpt.location}
                  iconSize={LOCATION_ICON_SIZE}
                  isParentChainHidden
                />
              ) : (
                cardOpt.value || NO_VALUE
              )}
            </span>
          </div>
        ))}
      </div>
    )
  }

  private scrollToSelectedRow = (scrollToIndex: number) => {
    if (scrollToIndex !== -1) {
      Promise.resolve().then(() => {
        this.listRef?.scrollToRow(scrollToIndex)
      })
    }
  }

  private setListRef = (listRef: List) => {
    this.listRef = listRef
  }
}
