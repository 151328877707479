import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IPermitTypeField } from '~/client/graph'
import PermitFieldIcon from '~/client/src/shared/components/PermitFieldIcon/PermitFieldIcon'

import Checkbox from '../../../Checkbox'
import SitePermitCreationFormStore from '../../SitePermitCreationForm.store'

interface IProps {
  typeField: IPermitTypeField
  store: SitePermitCreationFormStore
  isViewMode: boolean
}

const closesAllSelectedLocations = 'Closes all selected locations'

@observer
export default class PermitFormCloseLocationsField extends React.Component<IProps> {
  public render() {
    const {
      isViewMode,
      store: { editablePermit, workflowFieldsStore },
      typeField: { caption, type },
    } = this.props

    if (!workflowFieldsStore.isRequestToCloseShown) return null

    return (
      <div
        className={classList({
          'row no-grow y-center closure-selector': true,
          'unclickable-element': isViewMode,
          mb15: !isViewMode,
        })}
      >
        {isViewMode && <PermitFieldIcon fieldName={type} />}
        <div className="row pr6">
          <Checkbox
            className={classList({
              'site-permit-checkbox ba-none pa4 no-grow': true,
              checked: editablePermit.isClosure,
            })}
            isChecked={editablePermit.isClosure}
            onClick={this.onIsClosureChange}
          />
          <div className="col">
            <span>{caption}</span>
            <span className="text small grey">
              ({closesAllSelectedLocations})
            </span>
          </div>
        </div>
      </div>
    )
  }

  private onIsClosureChange = () => {
    const { editablePermit } = this.props.store
    editablePermit.isClosure = !editablePermit.isClosure
  }
}
