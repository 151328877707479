import React, { useEffect, useRef, useState } from 'react'

import mapboxgl, { LngLat, LngLatBounds } from 'mapbox-gl'
import { classList } from 'react-classlist-helper'

import { IProjectAddressInput } from '~/client/graph'
import {
  getMapBoxPlaceFromCoordinates,
  getMapBoxUrl,
  mapboxFeatureToAddress,
} from '~/client/src/shared/components/MapBoxEditor/MapBoxViewer.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { toAddressBoundsInput } from '~/client/src/shared/utils/Address'

const MAPBOX_STREET_LAYER = 'streets-v11'
const MAPBOX_SATELLITE_LAYER = 'satellite-v9'

const LNG_LAT_DISCREPANCY_METERS = 1

export async function getMapBoxPlaces(
  query: string,
  token: string,
): Promise<GeoJSON.FeatureCollection> {
  const resp = await fetch(getMapBoxUrl(query, token, true))
  return await resp.json()
}

export function areLngLatEqual(a: LngLat, b: LngLat) {
  if (!a || !b) {
    return !a === !b
  }

  return a.distanceTo(b) < LNG_LAT_DISCREPANCY_METERS
}

export function areLngLatBoundsEqual(a: LngLatBounds, b: LngLatBounds) {
  if (!a || !b) {
    return !a === !b
  }

  return (
    areLngLatEqual(a.getNorthEast(), b.getNorthEast()) &&
    areLngLatEqual(a.getSouthWest(), b.getSouthWest())
  )
}

interface IMapBoxViewProps {
  className?: string
  bearing: number
  bounds: LngLatBounds
  center: LngLat
  zoom: number
  pitch: number

  onAddressChanged?: (address: IProjectAddressInput) => void
  onDataChanged?: (
    bearing: number,
    bounds: LngLatBounds,
    center: LngLat,
    zoom: number,
    pitch: number,
  ) => void
  defaultStyle?: string
  setDefaultStyle?: (style: string) => void
}

export function MapBoxView(props: IMapBoxViewProps) {
  const { className } = props
  const mapContainer = useRef(null)

  const [map, setMap] = useState<mapboxgl.Map>()
  const [marker, setMarker] = useState<mapboxgl.Marker>()

  const [basemap, setBasemap] = useState(
    props.defaultStyle || MAPBOX_STREET_LAYER,
  )

  async function getUpdatedAddress(
    theMap: mapboxgl.Map,
    theMarker: mapboxgl.Marker,
    shouldAutoFocus: boolean,
  ) {
    const coords = theMarker.getLngLat()
    const item = await getMapBoxPlaceFromCoordinates(
      coords,
      mapboxgl.accessToken,
    )
    const addr = mapboxFeatureToAddress(item)
    if (shouldAutoFocus) {
      theMap.fitBounds([
        [addr.bounds.sw.lng, addr.bounds.sw.lat],
        [addr.bounds.ne.lng, addr.bounds.ne.lat],
      ])
    }
    addr.bearing = theMap.getBearing()
    addr.bounds = toAddressBoundsInput(theMap.getBounds())
    addr.center = coords

    props.onAddressChanged?.(addr)
  }

  useEffect(() => {
    const theMap = new mapboxgl.Map({
      container: mapContainer.current,
      bounds: props.bounds,
      pitchWithRotate: false,
      style: `mapbox://styles/mapbox/${basemap}`,
    })

    theMap.addControl(new mapboxgl.NavigationControl())
    theMap.addControl(new mapboxgl.GeolocateControl())

    theMap.setBearing(props.bearing)

    const theMarker = new mapboxgl.Marker()
      .setDraggable(true)
      .setLngLat(props.center)
      .addTo(theMap)

    theMarker.on('dragend', () => {
      getUpdatedAddress(theMap, theMarker, false)
    })

    const onDataChanged = () => {
      let changed = false

      if (props.bearing !== theMap.getBearing()) {
        changed = true
      }

      if (!areLngLatBoundsEqual(props.bounds, theMap.getBounds())) {
        changed = true
      }

      if (!areLngLatEqual(props.center, theMarker.getLngLat())) {
        changed = true
      }

      if (props.onDataChanged && changed) {
        props.onDataChanged(
          theMap.getBearing(),
          theMap.getBounds(),
          theMarker.getLngLat(),
          theMap.getZoom(),
          theMap.getPitch(),
        )
      }
    }

    theMap.on('moveend', onDataChanged)
    theMap.on('rotateend', onDataChanged)
    theMap.on('zoomend', onDataChanged)

    setMap(theMap)
    setMarker(theMarker)

    return function cleanup() {
      theMap.remove()
    }
  }, [mapContainer])

  useEffect(() => {
    if (!map) {
      return
    }

    map.setStyle(`mapbox://styles/mapbox/${basemap}`)
  }, [basemap])

  useEffect(() => {
    if (!map) {
      return
    }

    if (map.getBearing() !== props.bearing) {
      map.setBearing(props.bearing)
    }

    const mapBounds = props.bounds
    if (
      !areLngLatBoundsEqual(map.getBounds(), mapBounds) &&
      mapBounds._ne &&
      mapBounds._sw
    ) {
      map.fitBounds(mapBounds)
    }

    if (!areLngLatEqual(marker.getLngLat(), props.center)) {
      marker.setLngLat(props.center)
      getUpdatedAddress(map, marker, true)
    }
  }, [props])

  const setStreetBaseMap = () => {
    if (props.defaultStyle) {
      props.setDefaultStyle(MAPBOX_STREET_LAYER)
    }
    setBasemap(MAPBOX_STREET_LAYER)
  }
  const setSatelliteBaseMap = () => {
    if (props.defaultStyle) {
      props.setDefaultStyle(MAPBOX_SATELLITE_LAYER)
    }
    setBasemap(MAPBOX_SATELLITE_LAYER)
  }

  return (
    <div
      className={classList({
        'setup-form-map': true,
        [className]: !!className,
      })}
    >
      <div ref={mapContainer} className="setup-form-map-container" />
      <div className="setup-form-map-buttons">
        <button
          className={classList({ active: basemap === MAPBOX_STREET_LAYER })}
          onClick={setStreetBaseMap}
        >
          {Localization.translator.street}
        </button>
        <button
          className={classList({ active: basemap !== MAPBOX_STREET_LAYER })}
          onClick={setSatelliteBaseMap}
        >
          {Localization.translator.satellite}
        </button>
      </div>
    </div>
  )
}
