import * as React from 'react'

import { observer } from 'mobx-react'

import UsersDirectory from '~/client/src/shared/components/UsersDirectory/UsersDirectory'
import User from '~/client/src/shared/models/User'

interface IProps {
  users: User[]

  onAddTagToUser(userId: string): void
  onRemoveTagFromUser(userId: string): void
  saveUsers(): void
}

@observer
export default class MembersListSelector extends React.Component<IProps> {
  public componentWillUnmount() {
    this.props.saveUsers()
  }

  public render() {
    const { users, onAddTagToUser, onRemoveTagFromUser } = this.props

    return (
      <div
        className="members-list-selector col absolute"
        onClick={this.stopPropagation}
      >
        <UsersDirectory
          selectedUsers={users}
          shouldHideFilters={true}
          shouldUseGroupByModal={true}
          isSelectionMode={true}
          shouldSelectOnlyUsers={true}
          onUserAddClick={onAddTagToUser}
          onUserRemoveClick={onRemoveTagFromUser}
          className="scrollable beautiful-shadow pt10 brada4"
        />
      </div>
    )
  }

  private stopPropagation = (evt: React.SyntheticEvent) => {
    evt?.stopPropagation()
  }
}
