import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import PermitType from '~/client/src/shared/models/PermitType'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import { NOOP } from '~/client/src/shared/utils/noop'
import { ToastTheme, showToast } from '~/client/src/shared/utils/toaster'

import PreviewPermitFormStore from './PreviewPermitForm.store'

export default class PermitsSetUpStore {
  @observable private activePermitTypeId: string = null
  @observable public isNewFormTypeDialogShown: boolean = false

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly permitTypesStore: PermitTypesStore,
    private readonly previewPermitStore: PreviewPermitFormStore,
  ) {}

  public get isReceived(): boolean {
    return this.permitTypesStore.isDataReceived
  }

  public get isUpdating(): boolean {
    const { loading } = this.appState
    return (
      loading.get(e.SAVE_PERMIT_TYPES) ||
      loading.get(e.REMOVE_PERMIT_TYPES) ||
      loading.get(e.SAVE_MANY_OPERATION_RULES) ||
      loading.get(e.DELETE_MANY_OPERATION_RULES)
    )
  }

  public get permitTypes(): PermitType[] {
    return this.permitTypesStore.actualTypes
  }

  private get appState(): DesktopInitialState {
    return this.eventsStore.appState
  }

  @computed
  public get activePermitType(): PermitType {
    return this.permitTypesStore.getPermitTypeById(this.activePermitTypeId)
  }

  @action.bound
  public showNewFormTypeDialog() {
    this.isNewFormTypeDialogShown = true
  }

  @action.bound
  public hideNewFormTypeDialog() {
    this.isNewFormTypeDialogShown = false
  }

  public createCustomPermitType = (name: string, basedOnType: PermitType) => {
    if (!name?.trim() || !basedOnType) {
      return
    }

    this.hideNewFormTypeDialog()

    this.permitTypesStore.createCustomType(name, basedOnType)
  }

  public removeCustomPermitTypes = (typeIds: string[]) => {
    this.permitTypesStore.deleteTypes(typeIds)
  }

  public saveFormType = (updatedFormType: PermitType) => {
    this.changePermitType(
      this.activePermitType,
      updatedFormType,
      this.showSuccessMsg,
    )
  }

  @action.bound
  public changeShowInApp(fTypeId: string) {
    const updatingPermitType = this.permitTypesStore.getPermitTypeById(fTypeId)
    this.changePermitType(updatingPermitType, {
      isEnabled: !updatingPermitType.isEnabled,
    })
  }

  @action.bound
  public setActivePermitTypeId(
    activePermitTypeId: string = this.permitTypes[0]?.id,
  ) {
    this.activePermitTypeId = activePermitTypeId

    this.previewPermitStore.setDefaultSitePermit(null, activePermitTypeId, true)
  }

  @action.bound
  public resetSelectedFormType() {
    this.activePermitTypeId = null
  }

  @action.bound
  public showSuccessMsg([{ id }]: PermitType[]) {
    this.setActivePermitTypeId(id)
    showToast(
      Localization.translator.successfullyUpdated,
      ToastTheme.SUCCESS,
      IconNames.TICK_CIRCLE,
    )
  }

  public resetSelectedFormIfNeed = () => {
    if (this.activePermitType?.isDeleted) {
      this.resetSelectedFormType()
    }
  }

  @action.bound
  private changePermitType(
    updatingPermitType: PermitType,
    updatedFields: any,
    postAction: (permitTypes: PermitType[]) => void = NOOP,
  ) {
    const permitType: PermitType = Object.assign(
      updatingPermitType.getCopy(),
      updatedFields,
    )

    this.permitTypesStore.saveMany([permitType], postAction)
  }
}
