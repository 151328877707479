import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import './ActionPicker.scss'

// translated

export default function ActionPicker() {
  return (
    <div className="action-picker-container row px10 py8 brada4 not-allowed">
      <div>{Localization.translator.sendNotification}</div>
      <Icon className="row no-grow" icon={IconNames.CARET_DOWN} />
    </div>
  )
}
