import * as React from 'react'

import { Group, Path, Rect } from 'react-konva'

import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import { MATURIX_PATH } from '../LocationPaths'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_HEIGHT = 15
const ICON_WIDTH = 15

const ICON_OFFSET_X = -6
const ICON_OFFSET_Y = 10.2
const SHADOW_BLUR = 2
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3
const ICON_SCALE = 0.55

const CORNER_RADIUS = 3

const STROKE_WIDTH = 1
const { getHEXColor } = ThemeMode

const KonvaMaturixMonitoringIcon = ({ color, ...rest }) => {
  return (
    <Group {...rest} offsetX={ICON_WIDTH / 2} offsetY={ICON_HEIGHT / 2}>
      <Rect
        cornerRadius={CORNER_RADIUS}
        x={-(ICON_WIDTH / 2)}
        y={ICON_HEIGHT / 2}
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
        fill={getHEXColor(color)}
        stroke={getHEXColor(Colors.neutral100)}
        strokeWidth={STROKE_WIDTH}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
      <Path
        data={MATURIX_PATH}
        fill={getHEXColor(Colors.neutral100)}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
        x={ICON_OFFSET_X}
        y={ICON_OFFSET_Y}
        scaleX={ICON_SCALE}
        scaleY={ICON_SCALE}
      />
    </Group>
  )
}

export default KonvaMaturixMonitoringIcon
