import * as React from 'react'

import { inject, observer } from 'mobx-react'

import AnnouncementEditionFormStore from '~/client/src/shared/components/AnnouncementEditionForm/AnnouncementEditionForm.store'
import AnnouncementEditionFormContent from '~/client/src/shared/components/AnnouncementEditionForm/AnnouncementEditionFormContent'
import AnnouncementEditionFormDetails from '~/client/src/shared/components/AnnouncementEditionForm/AnnouncementEditionFormDetails'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Announcement from '~/client/src/shared/models/Announcement'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import AnnouncementAssignmentsStore from '~/client/src/shared/stores/domain/AnnouncementAssignments.store'
import AnnouncementsStore from '~/client/src/shared/stores/domain/Announcements.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import './AnnouncementEditionForm.scss'

// localization: translated

interface IProps {
  displayedAnnouncement?: Announcement
  initialTitle?: string
  initialLocation?: LocationBase
  selectedDate?: Date

  projectDateStore?: ProjectDateStore
  announcementsStore?: AnnouncementsStore
  announcementAssignmentsStore?: AnnouncementAssignmentsStore
  eventsStore?: EventsStore
  projectMembersStore?: ProjectMembersStore

  onClose(): void
}

@inject(
  'projectDateStore',
  'eventsStore',
  'announcementsStore',
  'announcementAssignmentsStore',
  'projectMembersStore',
)
@observer
export default class AnnouncementEditionForm extends React.Component<IProps> {
  private store: AnnouncementEditionFormStore

  private get headerText() {
    return this.store.isEditMode
      ? Localization.translator.editingAnnouncement
      : Localization.translator.newAnnouncement
  }

  public constructor(props: IProps) {
    super(props)

    this.store = new AnnouncementEditionFormStore(
      props.eventsStore,
      props.projectDateStore,
      props.announcementsStore,
      props.announcementAssignmentsStore,
      props.displayedAnnouncement,
    )

    if (props.initialLocation) {
      this.store.editableAnnouncement.location = {
        id: props.initialLocation.id,
        type: props.initialLocation.type,
      }
    }

    if (props.initialTitle) {
      this.store.editableAnnouncement.title = props.initialTitle
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { initialTitle, initialLocation, displayedAnnouncement } = this.props
    const {
      setDefaultAnnouncement,
      resetAnnouncementOrdersToDefault,
      editableAnnouncement,
    } = this.store

    if (displayedAnnouncement !== prevProps.displayedAnnouncement) {
      setDefaultAnnouncement(displayedAnnouncement)
      resetAnnouncementOrdersToDefault()
    }

    if (initialTitle !== prevProps.initialTitle) {
      editableAnnouncement.title = initialTitle
    }

    if (initialLocation !== prevProps.initialLocation) {
      editableAnnouncement.location = {
        id: initialLocation.id,
        type: initialLocation.type,
      }
    }
  }

  public render() {
    const { onClose, selectedDate } = this.props

    return (
      <div className="announcement-creation-form-container full-height full-width col">
        <div className="row y-center bb-light-grey py4 no-grow">
          <Icons.Cross className="no-grow cross-icon px20" onClick={onClose} />
          <div className="text bold small-header">{this.headerText}</div>
        </div>
        <div className="row announcement-creation-form-content">
          <AnnouncementEditionFormContent
            store={this.store}
            isScrollable={true}
          />

          <div className="col side-bar announcement-creation-form-details full-height bl-light-grey">
            <div className="scrollable ios-scrolling-container full-height col">
              <AnnouncementEditionFormDetails
                selectedDate={selectedDate}
                store={this.store}
                onClose={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
