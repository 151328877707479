import DeliveryStatus, {
  formatStatusToDisplay,
} from '~/client/src/shared/constants/DeliveryStatus'
import { TagType } from '~/client/src/shared/enums/TagType'
import Tag, { ITag } from '~/client/src/shared/models/Tag'
import { enumToList } from '~/client/src/shared/utils/converters'

import Colors from '~/client/src/shared/theme.module.scss'

const colorByDeliveryStatusMap = {
  [DeliveryStatus.Requested]: Colors.primary60,
  [DeliveryStatus.Changed]: Colors.primary60,
  [DeliveryStatus.Scheduled]: Colors.primary20,
  [DeliveryStatus.Paused]: Colors.error50,
  [DeliveryStatus.Delivering]: Colors.secondary40,
  [DeliveryStatus.OnHold]: Colors.warning40,
  [DeliveryStatus.OnSite]: Colors.secondary40,
  [DeliveryStatus.PassedInspection]: Colors.success40,
  [DeliveryStatus.Denied]: Colors.error50,
  [DeliveryStatus.FailedInspection]: Colors.error50,
  [DeliveryStatus.Done]: Colors.neutral0,
  [DeliveryStatus.IncompleteDone]: Colors.neutral0,
  [DeliveryStatus.Canceled]: Colors.slate50,
}

export const getDeliveryStatusAsTag = (
  status: DeliveryStatus,
  reason?: string,
): ITag => {
  return new Tag(
    status,
    formatStatusToDisplay(status, reason),
    colorByDeliveryStatusMap[status],
    status,
    TagType.Status,
  )
}

export const getDeliveryStatusesAsTags = (): ITag[] => {
  return enumToList(DeliveryStatus)
    .filter(s => s !== DeliveryStatus.Deleted)
    .map(
      status =>
        new Tag(
          status,
          formatStatusToDisplay(status),
          colorByDeliveryStatusMap[status],
          status,
          TagType.Status,
        ),
    )
}
