import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { Loader } from '~/client/src/shared/components/Loader'

import { Header, Wrapper } from '../SetupPageLayout/SetupPageLayout'
import DeliveryCardsSetUpStore from './DeliveryCardsSetUp.store'
import CardFieldsConfiguration from './components/CardFieldsConfiguration/CardFieldsConfiguration'
import DeliveryCardPreview from './components/DeliveryCardPreview/DeliveryCardPreview'

import './DeliveryCardsSetUp.scss'

interface IProps {
  eventsStore?: DesktopEventStore
}

const deliveryCards = 'Delivery Cards'
const cardProperties = 'Card Properties'
const restoreDefaults = 'Restore defaults'
const description =
  'Select & order the properties you want to show in the delivery cards on the calendar and map views.'

const RESTORE_ICON_SIZE = 16
const LOADER_SIZE = 40
const LOADER_LOADING_HINT = 'Loading...'
const LOADER_UPDATING_HINT = 'Updating...'

@inject('eventsStore')
@observer
export default class DeliveryCardsSetUp extends React.Component<IProps> {
  private readonly store: DeliveryCardsSetUpStore

  public constructor(props: IProps) {
    super(props)

    this.store = new DeliveryCardsSetUpStore(props.eventsStore)
  }

  public render() {
    const {
      isLoading,
      isUpdating,
      isDefaultConfig,
      timeInterval,
      hourRowsNumber,
      resetToDefaultConfiguration,
    } = this.store

    if (isLoading) {
      return this.renderLoader(LOADER_LOADING_HINT)
    }

    return (
      <div className="row full-height full-width no-outline-container delivery-cards-setup">
        {isUpdating && (
          <div className="absolute full-height full-width">
            <div className="full-height full-width absolute modal-overlay">
              {this.renderLoader(LOADER_UPDATING_HINT)}
            </div>
          </div>
        )}
        <Wrapper className="delivery-cards-section overflow-auto pb20">
          <div className="col pl20">
            <Header title={deliveryCards} />
            <h2 className="text header bold light no-grow no-white-space-wrap">
              {cardProperties}
            </h2>
            <div className="text large light line-extra-large lp035 pr20">
              {description}
            </div>
            <div className="row x-end">
              <div
                className={classList({
                  'm20 text large bold w-fit-content': true,
                  'pointer blue-highlight indication-click': !isDefaultConfig,
                  'grey inactive-element': isDefaultConfig,
                })}
                onClick={resetToDefaultConfiguration}
              >
                <Icon icon={IconNames.HISTORY} iconSize={RESTORE_ICON_SIZE} />
                <span className="pl4">{restoreDefaults}</span>
              </div>
            </div>
            <CardFieldsConfiguration store={this.store} />
          </div>
        </Wrapper>
        <DeliveryCardPreview
          timeInterval={timeInterval}
          hourRowsNumber={hourRowsNumber}
        />
      </div>
    )
  }

  private renderLoader(hint: string): JSX.Element {
    return (
      <Loader
        className="full-height col x-center y-center"
        size={LOADER_SIZE}
        hint={hint}
      />
    )
  }
}
