import { LocationType } from '~/client/graph'

import ActivityDataFieldId from '../enums/ActivityDataFieldId'

const locationTypeToActivityLocationFieldIdMap = {
  [LocationType.OffloadingEquipment]: ActivityDataFieldId.EQUIPMENT_ID,
  [LocationType.VerticalObject]: ActivityDataFieldId.VERTICAL_OBJECT_ID,
  [LocationType.Building]: ActivityDataFieldId.BUILDING_ID,
  [LocationType.Zone]: ActivityDataFieldId.ZONE_ID,
  [LocationType.Level]: ActivityDataFieldId.LEVEL_ID,
  [LocationType.Area]: ActivityDataFieldId.AREA_ID,
  [LocationType.Gate]: ActivityDataFieldId.GATE_ID,
  [LocationType.Route]: ActivityDataFieldId.ROUTE_ID,
}

export default locationTypeToActivityLocationFieldIdMap
