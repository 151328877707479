import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { DeliveryFilterType } from '~/client/graph'
import Checkbox from '~/client/src/shared/components/Checkbox'
import FilterOption from '~/client/src/shared/components/Filters/FilterUtils/FilterOption/FilterOption'
import { Loader } from '~/client/src/shared/components/Loader'
import { FilterSourceType } from '~/client/src/shared/stores/substates/ActivityFilterInfo'
import SuperFilterStore from '~/client/src/shared/stores/ui/SuperFilter.store'

import './CompactSuperFilter.scss'

interface ISuperFilter {
  filterStores: SuperFilterStore[]
  getFilteredOptionsCount: (deliveriesIds: string[]) => number
  shouldRenderHeader?: boolean
  optionFormatter?: (option: string) => JSX.Element
  storeToIconMap: { [filterType: string]: JSX.Element }
  customIconRender?: (option: string) => JSX.Element
}

@observer
export default class CompactSuperFilter extends React.Component<ISuperFilter> {
  public componentDidMount() {
    this.stores.forEach(f => f.clearNotPresentOptions())
  }

  public render() {
    const { shouldRenderHeader } = this.props
    const { isShown, isLoading } = this.stores[0]

    if (!isShown) {
      return
    }

    if (isLoading) {
      return <Loader />
    }

    return this.stores.map(store => {
      return (
        <div
          key={store.filterCaption}
          className={classList({
            'mobile-super-store col': true,
            'short row': store.isLoading || store.isEmpty,
          })}
        >
          {shouldRenderHeader && this.renderStoreHeader(store)}
          {store.isLoading ? <Loader /> : this.renderFilterArea(store)}
        </div>
      )
    })
  }

  private get stores(): SuperFilterStore[] {
    return this.props.filterStores
  }

  private renderStoreHeader(store: SuperFilterStore) {
    const {
      clickOnSelectAll,
      shortNameCaption,
      nameCaption,
      areAllOptionsSelected,
    } = store

    return (
      <div className="row super-store-header text large capitalize bold pl10">
        <Checkbox
          isChecked={areAllOptionsSelected}
          onClick={clickOnSelectAll}
        />
        <div className="row py10">
          {this.props.storeToIconMap[store.type]}
          {shortNameCaption && (
            <div className="filter-header-hint code">{shortNameCaption}</div>
          )}
          <div className="filter-header-hint desk">{nameCaption}</div>
        </div>
      </div>
    )
  }

  private filter(store: SuperFilterStore) {
    const { isDisabled } = store

    const className = 'bb-light-grey' + (isDisabled ? ' inactive-element' : '')
    return (
      <>
        <div className={'filter-content pt10 pb10 ' + className}>
          {this.renderOptionsTree(store)}
        </div>
      </>
    )
  }

  private renderFilterArea(store: SuperFilterStore) {
    return !store.isEmpty ? this.filter(store) : this.notification
  }

  private renderOptionsTree(store: SuperFilterStore) {
    const { optionFormatter, storeToIconMap, customIconRender } = this.props
    const {
      optionsTree,
      changeRootOption,
      isRootOptionChecked,
      selectedOptions,
      changeOption,
      filterSourceType,
      type,
    } = store

    const showRootOptions = filterSourceType === FilterSourceType.ProjectMember

    return optionsTree.map(rootOption => {
      const options = rootOption.options
        .sort(
          (a, b) =>
            this.getCroppedAmount(b.instancesIds) -
            this.getCroppedAmount(a.instancesIds),
        )
        .map((option, index) => {
          const { id, shortName, name, instancesIds } = option
          const instanceIcon = customIconRender
            ? customIconRender(id)
            : type !== DeliveryFilterType.Status && storeToIconMap[type]

          return (
            <FilterOption
              key={index}
              id={id}
              code={shortName}
              description={name}
              instancesIds={instancesIds}
              selectedOptions={selectedOptions}
              changeOption={changeOption}
              croppedAmount={this.getCroppedAmount(instancesIds)}
              className="option-row"
              instanceIcon={instanceIcon}
              shouldShowOnlyCroppedAmount={true}
              optionFormatter={optionFormatter}
              shouldRowBeClickable={true}
            />
          )
        })

      return (
        <div className="filter-section" key={rootOption.id}>
          {showRootOptions && (
            <div className="filter-section-title">
              <Checkbox
                label={rootOption.name}
                onClick={changeRootOption.bind(rootOption.options)}
                isChecked={isRootOptionChecked(rootOption.options)}
              />
            </div>
          )}
          {options}
        </div>
      )
    })
  }

  private notification(store: SuperFilterStore) {
    return (
      <div className="filter-notification center">{this.message(store)}</div>
    )
  }

  private message({ filterCaption }: SuperFilterStore) {
    return 'You have to configure filter ' + filterCaption
  }

  private getCroppedAmount = (instancesIds: string[]) => {
    return this.props.getFilteredOptionsCount(instancesIds)
  }
}
