import * as React from 'react'

import Activity from '~/client/src/shared/models/Activity'

interface IProps {
  activity: Activity
  dataKey: string
}

export default class ActivityValue extends React.Component<IProps> {
  public render() {
    const { activity, dataKey } = this.props
    const key = dataKey.split('.').pop()
    return (
      <div className="text cell-wrapper px10 bb-cool-grey">
        <div className="hidable-content">{activity[key] || ' - '}</div>
      </div>
    )
  }
}
