import { action, observable } from 'mobx'

import { IRestrictedOption } from '~/client/graph'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { copyObjectArray } from '~/client/src/shared/utils/util'

export default class LocationRestrictionsStore {
  @observable public shouldShowSelector: boolean = false

  @observable public restrictedLocations: IRestrictedOption[] = []
  @observable public restrictedLocationsIds: string[] = []
  @observable public onChange: (
    restrictedLocations: IRestrictedOption[],
  ) => void

  public constructor(
    private readonly tagsStore: TagsStore,
    restrictedLocations: IRestrictedOption[] = [],
    onChange: (restrictedLocations: IRestrictedOption[]) => void,
  ) {
    this.initRestrictedLocations(restrictedLocations)
    this.initOnChange(onChange)
  }

  @action.bound
  public initRestrictedLocations(restrictedLocations: IRestrictedOption[]) {
    this.restrictedLocations = copyObjectArray(restrictedLocations || [])
    this.restrictedLocationsIds = this.restrictedLocations.map(({ id }) => id)
  }

  @action.bound
  public initOnChange(
    onChange: (restrictedLocations: IRestrictedOption[]) => void,
  ) {
    this.onChange = onChange
  }

  @action.bound
  public handleChange(restrictedIds: string[]) {
    this.restrictedLocationsIds = restrictedIds
  }

  @action.bound
  public handleApplyChanges() {
    this.shouldShowSelector = false

    this.restrictedLocations = []

    this.restrictedLocationsIds.forEach(id => {
      const tag = this.tagsStore.getTagById(id)

      if (tag) {
        this.restrictedLocations.push({
          id: tag.id,
          type: tag.type,
        })
      }
    })

    this.onChange(this.restrictedLocations)
  }

  @action.bound
  public handleResetSelection() {
    this.restrictedLocationsIds = []
  }

  @action.bound
  public showSelector() {
    this.shouldShowSelector = true
  }

  @action.bound
  public hideSelector() {
    this.shouldShowSelector = false
    this.restrictedLocationsIds = this.restrictedLocations.map(({ id }) => id)
  }
}
