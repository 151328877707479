import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { toggleClass } from 'react-classlist-helper'

import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import ContentObjectViewBase from '~/client/src/shared/components/ContentObject/ContentObjectViewBase'
import DaySeparator from '~/client/src/shared/components/DaySeparator'
import FlagThread from '~/client/src/shared/components/FlagEntry/FlagThread'
import FlagIcon from '~/client/src/shared/components/FlagIcon'
import {
  Content,
  Footer,
  Header,
  View,
} from '~/client/src/shared/components/Layout'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

// translated

interface IProps {
  contentObjectsStore?: FlagsStore
  activitiesStore?: ActivitiesStore
  slackBarStore?: SlackBarStore
  state?: DesktopInitialState
  contentObjectId?: string
  goBack: () => void
}

@inject(({ activitiesStore, flagsStore, slackBarStore, state }) => {
  return {
    activitiesStore,
    contentObjectsStore: flagsStore,
    slackBarStore,
    state,
  }
})
@observer
export default class DesktopFlagView extends ContentObjectViewBase<IProps> {
  protected className: string = 'flag'

  public render() {
    const { userActiveProjectSettings } = this.props.state
    return (
      <View>
        <Header>{this.header}</Header>
        {this.imagePreview}
        <Content scrollable={true}>{this.content}</Content>
        <Footer
          className={toggleClass(
            'inactive-element',
            userActiveProjectSettings?.isPresentationUser,
          )}
        >
          {this.footer}
        </Footer>
      </View>
    )
  }

  public get content() {
    const currEntryDate = new Date(this.contentObject.createdAt)

    return (
      <div className="activity-log">
        <DaySeparator date={currEntryDate} />
        <FlagThread
          flag={this.contentObject}
          hideButtons={true}
          FileInputType={DesktopFileInput}
        />
      </div>
    )
  }

  @action.bound
  public goBack() {
    this.props.goBack()
  }

  @action.bound
  public goToActivityDetails() {
    this.props.goBack()
  }

  protected caption = () => Localization.translator.flag
  protected replyToCaption = () => Localization.translator.replyToActivityFlag

  protected getIcon(): React.ReactNode {
    return <FlagIcon flag={this.contentObject} />
  }
}
