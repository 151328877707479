import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

interface IProps {
  weekDefinition: Date
  selectedDate: Date
  onDateChanged?: (date: Date) => void
  store: ProjectDateStore
}

@observer
export default class WeekRow extends React.Component<IProps> {
  public render() {
    const { store, selectedDate } = this.props
    return (
      <div className="row week-days-row pb4 full-width">
        {this.weekDays.map(day => (
          <div
            className="col x-center"
            key={+day}
            onClick={this.onDateClicked.bind(this, day)}
          >
            <div className="text light center py4">
              {store.getWeekdayToDisplay(day)}
            </div>
            <div
              className={classList({
                'week-day': true,
                today: store.isToday(day),
                selected: store.isSameDay(day, selectedDate),
              })}
            >
              {store.getDayOfMonthToDisplay(day)}
            </div>
          </div>
        ))}
      </div>
    )
  }

  private get weekDays(): Date[] {
    const { weekDefinition, store } = this.props
    return store.getWeekDays(weekDefinition)
  }

  private onDateClicked(date: Date) {
    if (this.props.onDateChanged) {
      this.props.onDateChanged(date)
    }
  }
}
