import { action, observable } from 'mobx'

import { IProjectClosedInterval } from '~/client/graph'
import TwoMonthsDatePickerStore, {
  ITwoMonthsDatePickerOptions,
} from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import Project from '~/client/src/shared/models/Project'

interface IClosuresDatePickerStoreOptions extends ITwoMonthsDatePickerOptions {
  activeProject?: Project
  onRemove?: (item: Project, interval: IProjectClosedInterval) => void
}

export default class ClosuresDatePickerStore extends TwoMonthsDatePickerStore {
  @observable public intevalName: string = ''
  @observable public closedIntervals
  @observable public project: Project
  public onRemove

  protected onApplyHandler: (
    startDate: Date,
    endDate: Date,
    intervalName?: string,
  ) => void

  public showWithOptions(options: IClosuresDatePickerStoreOptions) {
    const { onRemove, activeProject } = options
    this.project = activeProject
    this.onRemove = onRemove

    super.showWithOptions(options)
  }

  @action.bound
  public onIntevalNameChange(event) {
    this.intevalName = event.target.value
  }

  public applySelectedDates() {
    if (this.onApplyHandler) {
      this.onApplyHandler(this.startDate, this.endDate, this.intevalName)
    }
  }
}
