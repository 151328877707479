import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IRecurringDeliveriesSettingsFieldsFragment = Pick<
  Types.IRecurringDeliveriesSettings,
  | 'id'
  | 'projectId'
  | 'frequency'
  | 'frequencyType'
  | 'endDate'
  | 'selectedDays'
>

export type IGetRecurringDeliveriesSettingsByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ObjectId']
}>

export type IGetRecurringDeliveriesSettingsByIdQuery = {
  recurringDeliveriesSettings?: Types.Maybe<IRecurringDeliveriesSettingsFieldsFragment>
}

export type IListenToRecurringDeliveriesSettingsSubscriptionVariables =
  Types.Exact<{
    projectId: Types.Scalars['ObjectId']
  }>

export type IListenToRecurringDeliveriesSettingsSubscription = {
  recurringDeliveriesSettings?: Types.Maybe<
    Pick<Types.IRecurringDeliveriesSettingsChangeEvent, 'id'> & {
      item?: Types.Maybe<IRecurringDeliveriesSettingsFieldsFragment>
    }
  >
}

export const RecurringDeliveriesSettingsFieldsFragmentDoc = gql`
  fragment RecurringDeliveriesSettingsFields on RecurringDeliveriesSettings {
    id
    projectId
    frequency
    frequencyType
    endDate
    selectedDays
  }
`
export const GetRecurringDeliveriesSettingsByIdDocument = gql`
  query GetRecurringDeliveriesSettingsById($id: ObjectId!) {
    recurringDeliveriesSettings(id: $id) {
      ...RecurringDeliveriesSettingsFields
    }
  }
  ${RecurringDeliveriesSettingsFieldsFragmentDoc}
`

/**
 * __useGetRecurringDeliveriesSettingsByIdQuery__
 *
 * To run a query within a React component, call `useGetRecurringDeliveriesSettingsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringDeliveriesSettingsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringDeliveriesSettingsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRecurringDeliveriesSettingsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetRecurringDeliveriesSettingsByIdQuery,
    IGetRecurringDeliveriesSettingsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetRecurringDeliveriesSettingsByIdQuery,
    IGetRecurringDeliveriesSettingsByIdQueryVariables
  >(GetRecurringDeliveriesSettingsByIdDocument, options)
}
export function useGetRecurringDeliveriesSettingsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetRecurringDeliveriesSettingsByIdQuery,
    IGetRecurringDeliveriesSettingsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetRecurringDeliveriesSettingsByIdQuery,
    IGetRecurringDeliveriesSettingsByIdQueryVariables
  >(GetRecurringDeliveriesSettingsByIdDocument, options)
}
export type GetRecurringDeliveriesSettingsByIdQueryHookResult = ReturnType<
  typeof useGetRecurringDeliveriesSettingsByIdQuery
>
export type GetRecurringDeliveriesSettingsByIdLazyQueryHookResult = ReturnType<
  typeof useGetRecurringDeliveriesSettingsByIdLazyQuery
>
export type GetRecurringDeliveriesSettingsByIdQueryResult = Apollo.QueryResult<
  IGetRecurringDeliveriesSettingsByIdQuery,
  IGetRecurringDeliveriesSettingsByIdQueryVariables
>
export const ListenToRecurringDeliveriesSettingsDocument = gql`
  subscription ListenToRecurringDeliveriesSettings($projectId: ObjectId!) {
    recurringDeliveriesSettings(projectId: $projectId) {
      id
      item {
        ...RecurringDeliveriesSettingsFields
      }
    }
  }
  ${RecurringDeliveriesSettingsFieldsFragmentDoc}
`

/**
 * __useListenToRecurringDeliveriesSettingsSubscription__
 *
 * To run a query within a React component, call `useListenToRecurringDeliveriesSettingsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToRecurringDeliveriesSettingsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToRecurringDeliveriesSettingsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToRecurringDeliveriesSettingsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToRecurringDeliveriesSettingsSubscription,
    IListenToRecurringDeliveriesSettingsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToRecurringDeliveriesSettingsSubscription,
    IListenToRecurringDeliveriesSettingsSubscriptionVariables
  >(ListenToRecurringDeliveriesSettingsDocument, options)
}
export type ListenToRecurringDeliveriesSettingsSubscriptionHookResult =
  ReturnType<typeof useListenToRecurringDeliveriesSettingsSubscription>
export type ListenToRecurringDeliveriesSettingsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToRecurringDeliveriesSettingsSubscription>
