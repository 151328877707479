import { action, computed, observable } from 'mobx'

import { ISitePermitStatusChange } from '~/client/graph'
import PermitStatusChange from '~/client/src/shared/models/PermitStatusChange'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'

export default class PermitStatusChangesStore {
  @observable public isDataReceived = false

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly graphExecutorStore: GraphExecutorStore,
  ) {}

  @computed
  public get list() {
    return Array.from(this.byId.values())
  }

  public get byId() {
    return this.eventsStore.appState.permitStatusChanges
  }

  public loadAndListenStatusChangesByFormId = (
    formId: string,
  ): Promise<void> => {
    return new Promise(resolve => {
      this.eventsStore.dispatch(
        e.LOAD_AND_LISTEN_TO_SITE_PERMIT_STATUS_CHANGES,
        formId,
        resolve,
      )
    })
  }

  public dontListenToStatusChanges = () => {
    this.graphExecutorStore.terminateSubscription(
      e.LISTEN_TO_SITE_PERMIT_STATUS_CHANGES,
    )
    this.clearList()
  }

  @action.bound
  public receiveList(list: ISitePermitStatusChange[]) {
    this.clearList()

    this.updateFromList(list)

    this.isDataReceived = true
  }

  private updateFromList(list: ISitePermitStatusChange[]) {
    list.forEach(dto => this.receiveOne(dto.id, dto))
  }

  @action.bound
  public receiveOne(id: string, dto: ISitePermitStatusChange) {
    if (dto) {
      const statusChange = PermitStatusChange.fromDto(dto)
      this.byId.set(statusChange.id, statusChange)
    } else {
      this.byId.delete(id)
    }
  }

  public getStatusChangesForPermit = (
    permitId: string,
  ): PermitStatusChange[] => {
    return this.list.filter(sc => sc.permitId === permitId)
  }

  @action.bound
  private clearList() {
    this.byId.clear()
    this.isDataReceived = false
  }
}
