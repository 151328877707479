import { observable } from 'mobx'

import {
  IGlobeViewPolyline,
  SitemapItemShapeType,
  SitemapLineArrowPosition,
} from '~/client/graph'

import { areObjectsEqual } from '../../../utils/util'
import { GlobeViewShapeProperties } from './GlobeViewItemProperties'

const DEFAULT_IS_DISPLAYED = true
const DEFAULT_LINE_WIDTH = 3
const DEFAULT_FILL_OPACITY = 0.2

export default class GlobeViewPolyLineProperties implements IGlobeViewPolyline {
  public type: SitemapItemShapeType = SitemapItemShapeType.Polyline
  @observable public lineWidth: number
  @observable public fillColor: string
  @observable public fillOpacity: number
  @observable public isClosed: boolean
  @observable public arrowPosition: SitemapLineArrowPosition
  @observable public lineColor: string
  @observable public isDisplayed: boolean

  public constructor(
    lineWidth?: number,
    lineColor?: string,
    fillColor?: string,
    fillOpacity?: number,
    isClosed?: boolean,
    arrowPosition?: SitemapLineArrowPosition,
    isDisplayed?: boolean,
  ) {
    this.lineWidth = lineWidth || DEFAULT_LINE_WIDTH
    this.lineColor = lineColor
    this.fillColor = fillColor
    this.fillOpacity =
      typeof fillOpacity === 'number' ? fillOpacity : DEFAULT_FILL_OPACITY
    this.isClosed = !!isClosed
    this.arrowPosition = arrowPosition || SitemapLineArrowPosition.None
    this.isDisplayed =
      typeof isDisplayed === 'boolean' ? isDisplayed : DEFAULT_IS_DISPLAYED
  }

  public isEqual(properties: GlobeViewShapeProperties) {
    return areObjectsEqual(this, properties)
  }

  public toggleIsDisplayed() {
    this.isDisplayed = !this.isDisplayed
  }

  public isValid(): boolean {
    return true
  }

  public setLineWidth(width: number) {
    this.lineWidth = width
  }

  public setLineColor(color: string) {
    this.lineColor = color
  }

  public setFillColor(color: string) {
    this.fillColor = color
  }

  public setFillOpacity(opacity: number) {
    this.fillOpacity = opacity
  }

  public toggleIsClosed() {
    if (!this.isClosed) {
      this.closeShape()
    } else {
      this.isClosed = false
    }
  }

  public closeShape() {
    this.isClosed = true
  }

  public setArrowPosition(arrowPosition: SitemapLineArrowPosition) {
    this.arrowPosition = arrowPosition
  }

  public isDisplayDataEqual(poly: IGlobeViewPolyline) {
    if (
      this.isDisplayed !==
        (typeof poly.isDisplayed === 'boolean'
          ? poly.isDisplayed
          : DEFAULT_IS_DISPLAYED) ||
      this.type !== poly.type ||
      this.lineWidth !== poly.lineWidth ||
      this.lineColor !== poly.lineColor ||
      this.fillColor !== poly.fillColor ||
      this.fillOpacity !== poly.fillOpacity ||
      this.isClosed !== poly.isClosed ||
      this.arrowPosition !== poly.arrowPosition
    ) {
      return false
    }
    return true
  }

  public getDisplayData(): IGlobeViewPolyline {
    return {
      type: this.type,
      lineWidth: this.lineWidth,
      lineColor: this.lineColor,
      fillColor: this.fillColor,
      fillOpacity: this.fillOpacity,
      isClosed: this.isClosed,
      arrowPosition: this.arrowPosition,
      isDisplayed: this.isDisplayed,
    }
  }

  public copy(): GlobeViewPolyLineProperties {
    return new GlobeViewPolyLineProperties(
      this.lineWidth,
      this.lineColor,
      this.fillColor,
      this.fillOpacity,
      this.isClosed,
      this.arrowPosition,
      this.isDisplayed,
    )
  }
}
