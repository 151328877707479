import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import { LocationType } from '~/client/graph'
import DeliveryWorkflowDatePicker from '~/client/src/desktop/components/DeliveryWorkflowDatePicker/DeliveryWorkflowDatePicker'
import TwoMonthsDatePickerStore from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import ClosuresStore from '~/client/src/shared/stores/domain/Closures.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import ObjectPropertiesStore from './ObjectProperties.store'
import ObjectsList from './components/ObjectsList/ObjectsList'
import ObjectsListStore from './components/ObjectsList/ObjectsList.store'

// translated

interface IProps {
  objectTypeId: LocationType

  eventsStore?: DesktopEventStore
  tagsStore?: TagsStore
  projectDateStore?: ProjectDateStore
  locationAttributesStore?: LocationAttributesStore
  sitePermitsStore?: SitePermitsStore
  companiesStore?: CompaniesStore
  closuresStore?: ClosuresStore
}

@inject(
  'eventsStore',
  'tagsStore',
  'projectDateStore',
  'locationAttributesStore',
  'sitePermitsStore',
  'companiesStore',
  'closuresStore',
)
@observer
export default class ObjectProperties extends React.Component<IProps> {
  private readonly datePickerStore: TwoMonthsDatePickerStore = null
  private readonly store: ObjectPropertiesStore = null
  private stores: { [key: string]: ObjectsListStore } = {}

  public constructor(props: IProps) {
    super(props)

    this.datePickerStore = new TwoMonthsDatePickerStore(props.projectDateStore)
    this.store = new ObjectPropertiesStore(props.tagsStore)

    Object.keys(this.store.objectsListMap).forEach(key => {
      this.stores[key] = new ObjectsListStore(
        props.eventsStore.appState,
        props.tagsStore,
        this.objects,
        props.locationAttributesStore.buildingsStore,
        props.projectDateStore,
        props.sitePermitsStore,
        props.companiesStore,
        props.closuresStore,
        key as LocationType,
      )
    })
  }

  public render() {
    return (
      <>
        <DeliveryWorkflowDatePicker store={this.datePickerStore} />

        <div className="virtualized-list-smart-wrapper objects-list">
          <ObjectsList
            data={this.store.objectsListMap}
            rows={this.listStore.tableRowList}
            store={this.listStore}
            datePickerStore={this.datePickerStore}
            columnsCount={this.columnsCount}
            fixedColumnsCount={1}
          />
        </div>
      </>
    )
  }

  @computed
  private get listStore(): ObjectsListStore {
    return this.stores[this.props.objectTypeId]
  }

  private objects = () => {
    const { objectTypeId } = this.props
    return this.store.objectsListMap[objectTypeId]
  }

  private get columnsCount(): number {
    return this.listStore.columnKeys.length
  }
}
