import { action, observable } from 'mobx'

import { IConstraint, ISubscription } from '~/client/graph'
import {
  ConstraintsByProjectIdDocument,
  ListenConstraintsByProjectIdDocument,
} from '~/client/graph/operations/generated/Constraint.generated'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import Guard from '~/client/src/shared/utils/Guard'

import GraphExecutorStore from './GraphExecutor.store'

export default class ConstraintsStore {
  @observable public isLoading = false
  @observable public constraints: IConstraint[] = []

  public readonly constraintsSubscriptionId = 'constraints'

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly graphExecutorStore: GraphExecutorStore,
  ) {
    Guard.requireAll({
      eventsStore,
    })
  }

  @action.bound
  public async loadConstraints() {
    this.isLoading = true

    const { activeProject } = this.eventsStore.appState
    const { data } = await this.graphExecutorStore.query(
      ConstraintsByProjectIdDocument,
      { projectId: activeProject.id },
    )

    this.constraints = data.constraints.data

    this.isLoading = false
  }

  public listenToConstraints = (additionalCheckAction?: () => void) => {
    const { activeProject } = this.eventsStore.appState
    this.graphExecutorStore.subscribe(
      ListenConstraintsByProjectIdDocument,
      { projectId: activeProject.id },
      false,
      this.constraintsSubscriptionId,
      data => {
        const { id, item } = (data as ISubscription).constraint
        this.constraints = this.constraints.filter(c => c.id !== id)

        if (item) {
          this.constraints.push(item)
        }
        if (additionalCheckAction) {
          additionalCheckAction()
        }
      },
    )
  }

  public dontListenToConstraints = () => {
    this.graphExecutorStore.terminateSubscription(
      this.constraintsSubscriptionId,
    )
  }
}
