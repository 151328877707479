import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { QRCode } from 'react-qrcode-logo'

import FileFullscreenPreview from '~/client/src/shared/components/FileFullscreenPreview/FileFullscreenPreview'
import * as Icons from '~/client/src/shared/components/Icons'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { SHOW_FULLSCREEN_PREVIEW } from '~/client/src/shared/stores/EventStore/eventConstants'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import DesktopEventStore from '../../stores/EventStore/DesktopEvents.store'
import DesktopFullscreenPreviewStore from './DesktopFullscreenPreview.store'

import Colors from '~/client/src/shared/theme.module.scss'

import './DesktopFullscreenPreview.scss'

// localization: no display text to translate

interface IDesktopFullScreenPreviewProps {
  eventsStore?: DesktopEventStore
}

@inject('eventsStore')
@observer
export default class DesktopFullScreenPreview extends React.Component<IDesktopFullScreenPreviewProps> {
  private readonly store: DesktopFullscreenPreviewStore
  private readonly clearPostEventCallback: () => void

  public constructor(props: IDesktopFullScreenPreviewProps) {
    super(props)

    this.store = new DesktopFullscreenPreviewStore()
    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.onShowFullscreenRequest,
    )
  }

  public componentWillUnmount() {
    this.clearPostEventCallback?.()
  }

  public render() {
    const {
      allFiles,
      currentFileIndex,
      hideFullscreenPreview,
      shouldShowFullscreenImagePreview,
      allComments,
      qrCodeLink,
      setFileIndex,
    } = this.store

    if (!shouldShowFullscreenImagePreview) {
      return null
    }

    if (qrCodeLink) {
      return this.qrCodePreview
    }

    return (
      <FileFullscreenPreview
        files={allFiles}
        comments={allComments}
        fileIndex={currentFileIndex}
        onClose={hideFullscreenPreview}
        setFileIndex={setFileIndex}
      />
    )
  }

  private get qrCodePreview() {
    const { hideFullscreenPreview, qrCodeLink } = this.store
    return (
      <div className="fullscreen full-height qr-holder z-index-120">
        {qrCodeLink && (
          <QRCode
            value={qrCodeLink}
            logoImage="/static/icons/strux-hub-dark-with-background.svg"
            size={800}
            fgColor={ThemeMode.getHEXColor(Colors.neutral0)}
          />
        )}
        <button
          onClick={hideFullscreenPreview}
          type="button"
          className="fullscreen-button ba-none no-background no-outline pa5"
        >
          <Icons.Cross />
        </button>
      </div>
    )
  }

  private onShowFullscreenRequest = (eventContext: EventContext) => {
    const [eventType, files, currentFileIndex, comments, qrCodeLink] =
      eventContext.event

    if (eventType === SHOW_FULLSCREEN_PREVIEW) {
      this.store.showFullscreenPreview(
        files,
        currentFileIndex,
        comments,
        qrCodeLink,
      )
    }
  }
}
