import * as React from 'react'

import { action } from 'mobx'
import { observer } from 'mobx-react'

import SubmitButton from '~/client/src/desktop/views/ProjectSetUp/components/SubmitButton'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import ProjectScheduleUploadStore, {
  ScheduleStatus,
} from '../ProjectScheduleUpload.store'

const SUBMIT_BUTTON_WIDTH = 300
interface IProps {
  projectScheduleUploadStore: ProjectScheduleUploadStore
}

@observer
export default class ScheduleUploadButtonAndStatus extends React.Component<IProps> {
  public render() {
    const {
      ganttViewStatus,
      submitButtonTitle,
      canNavigateNextStep,
      navigateToNextStep,
    } = this.props.projectScheduleUploadStore

    const isParsing = [
      ScheduleStatus.GettingXerProjects,
      ScheduleStatus.GettingExcelSheets,
      ScheduleStatus.UploadingSelectedProject,
    ].includes(ganttViewStatus)
    const hint = isParsing
      ? submitButtonTitle
      : Localization.translator.exploreYourScheduleInStruxHub

    return (
      <>
        <SubmitButton
          text={submitButtonTitle}
          width={SUBMIT_BUTTON_WIDTH}
          clickHandler={this.navigateToGantt}
          disabled={ganttViewStatus !== ScheduleStatus.Ready}
          loading={isParsing}
        />
        <span className="upload-schedule-gantt-note">{hint}</span>
        {canNavigateNextStep && (
          <div
            onClick={navigateToNextStep}
            className="upload-schedule-link upload-schedule-continue"
          >
            {Localization.translator.continueToNextStep}
          </div>
        )}
      </>
    )
  }

  @action.bound
  private navigateToGantt(event) {
    event.preventDefault()
    this.props.projectScheduleUploadStore.navigateToGantt()
  }
}
