import * as React from 'react'

import { KonvaEventObject } from 'konva/types/Node'
import { observer } from 'mobx-react'
import { Rect } from 'react-konva'

import { LocationType } from '~/client/graph'
import GlobeViewControlStore from '~/client/src/shared/stores/GlobeViewControl.store'
import {
  MAX_RELATIVE_POSITION,
  sitemapMousePointTo,
  validatePosition,
} from '~/client/src/shared/utils/SitemapCalculationHelpers'

import MapViewItemsSetupStore from '../../stores/MapViewItemsSetup.store'
import SitemapsSetupStore from '../../stores/SitemapsSetup.store'

const DEFAULT_CURSOR = 'default'
const ADD_ITEM_CURSOR = 'copy'

const {
  Building,
  Zone,
  Gate,
  Route,
  OffloadingEquipment,
  Level,
  Area,
  Staging,
  InteriorDoor,
  InteriorPath,
  LogisticsObject,
} = LocationType
const CURSOR_BY_OBJECT_TYPE = {
  [Building]: 'url(/static/cursors/building.png) 12 16, copy',
  [Zone]: 'url(/static/cursors/offloading-zone.svg) 12 12, copy',
  [OffloadingEquipment]: 'url(/static/cursors/equipment.png) 6 6, copy',
  [Gate]: 'url(/static/cursors/gate.png) 16 18, copy',
  [Route]: 'url(/static/cursors/route.png) 16 16, copy',
  [Level]: 'url(/static/cursors/level.png) 12 12, copy',
  [Area]: 'url(/static/cursors/area.png) 12 12, copy',
  [Staging]: 'url(/static/cursors/staging.svg) 12 12, copy',
  [InteriorDoor]: 'url(/static/cursors/interior-door.svg) 12 12, copy',
  [InteriorPath]: 'url(/static/cursors/interior-path.svg) 12 12, copy',
  [LogisticsObject]: 'url(/static/cursors/zone.png) 12 12, copy',
}

const T_LETTER = 'url(/static/icons/t-letter-icon.svg) 12 12, copy'

interface IProps {
  containerWidth: number
  containerHeight: number
  store?: MapViewItemsSetupStore
  globeViewControlStore?: GlobeViewControlStore
  sitemapsSetupStore?: SitemapsSetupStore

  isGlobeView?: boolean
}

@observer
export default class CreateNewItemArea extends React.Component<IProps> {
  public render() {
    const { containerHeight, containerWidth, store, isGlobeView } = this.props

    if (
      !store?.creatableAttributeType &&
      !store?.isTextStickerCreationActive &&
      !isGlobeView
    ) {
      return null
    }

    return (
      <Rect
        x={0}
        y={0}
        width={containerWidth}
        height={containerHeight}
        onMouseEnter={this.handleAreaMouseEnter}
        onMouseLeave={this.handleAreaMouseLeave}
        onClick={this.onAreaClick}
        id="create-new-area"
      />
    )
  }

  private handleAreaMouseEnter = () => {
    const { creatableAttributeType, isTextStickerCreationActive } =
      this.props.store
    if (isTextStickerCreationActive) {
      document.body.style.cursor = T_LETTER
    } else {
      document.body.style.cursor =
        CURSOR_BY_OBJECT_TYPE[creatableAttributeType] || ADD_ITEM_CURSOR
    }
  }

  private handleAreaMouseLeave = () => {
    document.body.style.cursor = DEFAULT_CURSOR
  }

  private onAreaClick = (event: KonvaEventObject<MouseEvent>) => {
    const { containerWidth, containerHeight, store } = this.props

    const stage = event.currentTarget.getStage()

    if (!containerWidth || !containerHeight) {
      return
    }

    const mousePointTo = sitemapMousePointTo(
      stage,
      containerWidth,
      containerHeight,
    )
    const relativePosition = validatePosition({
      x: (mousePointTo.x / containerWidth) * MAX_RELATIVE_POSITION,
      y: (mousePointTo.y / containerHeight) * MAX_RELATIVE_POSITION,
    })

    store.createAttributeInPosition(relativePosition)
    document.body.style.cursor = DEFAULT_CURSOR
  }
}
