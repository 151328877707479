export default function capitalize(text: string) {
  return text && text[0].toUpperCase() + text.slice(1)
}

export function capitalizeEachWord(text: string) {
  if (!text) {
    return text
  }

  return text
    .split(' ')
    .map(word => capitalize(word))
    .join(' ')
}
