import * as React from 'react'

import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import {
  ActionState,
  BallInCourtState,
  IntermediateState,
  TerminationState,
} from './components/WorkflowDiagramState'

import './WorkflowDiagramWrapper.scss'

// localization: translated

interface IProps {
  className?: string
}

@observer
export default class WorkflowDiagramWrapper extends React.Component<IProps> {
  public render() {
    const { className, children } = this.props

    return (
      <div
        className={`workflow-diagram-wrapper no-grow ${
          className || EMPTY_STRING
        }`}
      >
        <div className="row y-center nowrap py15 pr20">
          <span className="no-grow text small bold uppercase lp15 mr30">
            {Localization.translator.legend}:
          </span>
          <TerminationState
            className="mr8"
            content={Localization.translator.startFinishState}
          />
          <IntermediateState
            className="mr15"
            content={Localization.translator.intermediateState}
          />
          <ActionState
            className="mr15"
            content={Localization.translator.action}
          />
          <div className="row no-grow x-start y-center">
            <BallInCourtState className="mr8" />
            <span className="text large">
              {Localization.translator.ballInCourtStep}
            </span>
          </div>
        </div>

        <div className="workflow-diagram brada8 bg-palette-brand-lightest nowrap">
          <div className="col py20 px15 no-flex no-flex-children">
            {children}
          </div>
        </div>
      </div>
    )
  }
}
