import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IActivityPresetFieldsFragment = Pick<
  Types.IActivityPreset,
  'id' | 'projectId' | 'name' | 'activities' | 'userId'
>

export type IGetActivityPresetListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  userId: Types.Scalars['ObjectId']
}>

export type IGetActivityPresetListQuery = {
  activityPresets?: Types.Maybe<{ data: Array<IActivityPresetFieldsFragment> }>
}

export type IListenToActivityPresetSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  userId: Types.Scalars['ObjectId']
}>

export type IListenToActivityPresetSubscription = {
  activityPreset?: Types.Maybe<
    Pick<Types.IActivityPresetChangeEvent, 'id'> & {
      item?: Types.Maybe<IActivityPresetFieldsFragment>
    }
  >
}

export type ISaveActivityPresetMutationVariables = Types.Exact<{
  activityPreset: Types.IActivityPresetInput
}>

export type ISaveActivityPresetMutation = {
  saveActivityPreset?: Types.Maybe<IActivityPresetFieldsFragment>
}

export type IDeleteActivityPresetMutationVariables = Types.Exact<{
  presetId: Types.Scalars['ObjectId']
}>

export type IDeleteActivityPresetMutation = Pick<
  Types.IMutation,
  'deleteActivityPreset'
>

export const ActivityPresetFieldsFragmentDoc = gql`
  fragment ActivityPresetFields on ActivityPreset {
    id
    projectId
    name
    activities
    userId
  }
`
export const GetActivityPresetListDocument = gql`
  query GetActivityPresetList($projectId: ObjectId!, $userId: ObjectId!) {
    activityPresets(limit: 1000000, projectId: $projectId, userId: $userId) {
      data {
        ...ActivityPresetFields
      }
    }
  }
  ${ActivityPresetFieldsFragmentDoc}
`

/**
 * __useGetActivityPresetListQuery__
 *
 * To run a query within a React component, call `useGetActivityPresetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityPresetListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityPresetListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetActivityPresetListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetActivityPresetListQuery,
    IGetActivityPresetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetActivityPresetListQuery,
    IGetActivityPresetListQueryVariables
  >(GetActivityPresetListDocument, options)
}
export function useGetActivityPresetListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetActivityPresetListQuery,
    IGetActivityPresetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetActivityPresetListQuery,
    IGetActivityPresetListQueryVariables
  >(GetActivityPresetListDocument, options)
}
export type GetActivityPresetListQueryHookResult = ReturnType<
  typeof useGetActivityPresetListQuery
>
export type GetActivityPresetListLazyQueryHookResult = ReturnType<
  typeof useGetActivityPresetListLazyQuery
>
export type GetActivityPresetListQueryResult = Apollo.QueryResult<
  IGetActivityPresetListQuery,
  IGetActivityPresetListQueryVariables
>
export const ListenToActivityPresetDocument = gql`
  subscription ListenToActivityPreset(
    $projectId: ObjectId!
    $userId: ObjectId!
  ) {
    activityPreset(projectId: $projectId, userId: $userId) {
      id
      item {
        ...ActivityPresetFields
      }
    }
  }
  ${ActivityPresetFieldsFragmentDoc}
`

/**
 * __useListenToActivityPresetSubscription__
 *
 * To run a query within a React component, call `useListenToActivityPresetSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToActivityPresetSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToActivityPresetSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useListenToActivityPresetSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToActivityPresetSubscription,
    IListenToActivityPresetSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToActivityPresetSubscription,
    IListenToActivityPresetSubscriptionVariables
  >(ListenToActivityPresetDocument, options)
}
export type ListenToActivityPresetSubscriptionHookResult = ReturnType<
  typeof useListenToActivityPresetSubscription
>
export type ListenToActivityPresetSubscriptionResult =
  Apollo.SubscriptionResult<IListenToActivityPresetSubscription>
export const SaveActivityPresetDocument = gql`
  mutation SaveActivityPreset($activityPreset: ActivityPresetInput!) {
    saveActivityPreset(activityPreset: $activityPreset) {
      ...ActivityPresetFields
    }
  }
  ${ActivityPresetFieldsFragmentDoc}
`
export type ISaveActivityPresetMutationFn = Apollo.MutationFunction<
  ISaveActivityPresetMutation,
  ISaveActivityPresetMutationVariables
>

/**
 * __useSaveActivityPresetMutation__
 *
 * To run a mutation, you first call `useSaveActivityPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveActivityPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveActivityPresetMutation, { data, loading, error }] = useSaveActivityPresetMutation({
 *   variables: {
 *      activityPreset: // value for 'activityPreset'
 *   },
 * });
 */
export function useSaveActivityPresetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveActivityPresetMutation,
    ISaveActivityPresetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveActivityPresetMutation,
    ISaveActivityPresetMutationVariables
  >(SaveActivityPresetDocument, options)
}
export type SaveActivityPresetMutationHookResult = ReturnType<
  typeof useSaveActivityPresetMutation
>
export type SaveActivityPresetMutationResult =
  Apollo.MutationResult<ISaveActivityPresetMutation>
export type SaveActivityPresetMutationOptions = Apollo.BaseMutationOptions<
  ISaveActivityPresetMutation,
  ISaveActivityPresetMutationVariables
>
export const DeleteActivityPresetDocument = gql`
  mutation DeleteActivityPreset($presetId: ObjectId!) {
    deleteActivityPreset(id: $presetId)
  }
`
export type IDeleteActivityPresetMutationFn = Apollo.MutationFunction<
  IDeleteActivityPresetMutation,
  IDeleteActivityPresetMutationVariables
>

/**
 * __useDeleteActivityPresetMutation__
 *
 * To run a mutation, you first call `useDeleteActivityPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityPresetMutation, { data, loading, error }] = useDeleteActivityPresetMutation({
 *   variables: {
 *      presetId: // value for 'presetId'
 *   },
 * });
 */
export function useDeleteActivityPresetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteActivityPresetMutation,
    IDeleteActivityPresetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteActivityPresetMutation,
    IDeleteActivityPresetMutationVariables
  >(DeleteActivityPresetDocument, options)
}
export type DeleteActivityPresetMutationHookResult = ReturnType<
  typeof useDeleteActivityPresetMutation
>
export type DeleteActivityPresetMutationResult =
  Apollo.MutationResult<IDeleteActivityPresetMutation>
export type DeleteActivityPresetMutationOptions = Apollo.BaseMutationOptions<
  IDeleteActivityPresetMutation,
  IDeleteActivityPresetMutationVariables
>
