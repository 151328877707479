import * as React from 'react'

import { ISchedule } from '~/client/graph'
import Localization from '~/client/src/shared/localization/LocalizationManager'

export interface IProps {
  filename: string
  schedule: ISchedule
}

export default class ScheduleDescription extends React.Component<IProps> {
  public render() {
    const { filename, schedule } = this.props
    return (
      <div>
        <div>{filename}</div>
        {schedule ? (
          <>
            <div>
              {Localization.translator.totalActivities}:{' '}
              {schedule.activityQuantity}
            </div>
            {!!schedule.companyQuantity && (
              <div>
                {Localization.translator.companies}: {schedule.companyQuantity}
              </div>
            )}
          </>
        ) : (
          '...'
        )}
      </div>
    )
  }
}
