import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import { CalendricalType, IDeliveryStatusChange } from '~/client/graph'
import CommonThread from '~/client/src/shared/components/CommonThread/CommonThread'
import PhotoContainer from '~/client/src/shared/components/CommonThread/components/PhotoContainer'
import DeliveryDetailsStore, {
  FIELDS_AND_NOTE_SEPARATOR,
  FIELD_LINES_SEPARATOR,
  FIELD_VALUES_SEPARATOR,
} from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import WeatherWidget from '~/client/src/shared/components/WeatherWidget/WeatherWidget'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'
import IMessage from '~/client/src/shared/models/Message/IMessage'
import InitialState from '~/client/src/shared/stores/InitialState'
import DeliveryStatusChangesStore from '~/client/src/shared/stores/domain/DeliveryStatusChanges.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import PhotosStore from '~/client/src/shared/stores/domain/Photos.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'

import FieldIds from '../../enums/DeliveryFieldIds'
import IDeliveryControl from '../../models/IDeliveryControl'
import User from '../../models/User'
import WeatherForecastsStore from '../../stores/domain/WeatherForecasts.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import DaySeparator from '../DaySeparator'
import FileInputBase from '../FileInput/FileInput'

// translated

interface IDeliveryEntryProps {
  delivery: Delivery
  messagesStore?: MessagesStore
  photosStore?: PhotosStore
  state?: InitialState
  deliveryStatusChangesStore?: DeliveryStatusChangesStore
  projectDateStore?: ProjectDateStore
  FileInputType: typeof FileInputBase
  weatherForecastsStore?: WeatherForecastsStore
  projectMembersStore?: ProjectMembersStore
  deliveryDetailsStore?: DeliveryDetailsStore
}

interface IMessagesByDay {
  day: Date
  messages: IMessage[]
}

export interface IDeliveryThreadMessage extends IMessage {
  status?: DeliveryStatus
  cancellationReason?: string
}

@inject(
  'state',
  'photosStore',
  'messagesStore',
  'deliveryStatusChangesStore',
  'projectDateStore',
  'weatherForecastsStore',
  'projectMembersStore',
  'deliveryDetailsStore',
)
@observer
class DeliveryLog extends React.Component<
  IDeliveryEntryProps & RouteComponentProps<any>
> {
  public componentDidMount() {
    if (this.needWeatherForecast()) {
      this.props.weatherForecastsStore.fetchWeeklyForecast(
        this.props.delivery.startDate,
      )
    }
  }

  @computed
  private get messages(): IDeliveryThreadMessage[] {
    const { threadId } = this.props.delivery

    const messages = this.props.messagesStore.list

    const allMessages = [
      ...this.statusChangeMessages,
      ...messages.filter(m => m.threadId === threadId),
    ]

    if (this.needWeatherForecast()) {
      allMessages.push(this.weatherMessage)
    }

    return allMessages.sort((a, b) => a.createdAt - b.createdAt)
  }

  private get weatherMessage(): IMessage {
    const { createdAt, startDate, id } = this.props.delivery

    return {
      id,
      userId: User.service.id,
      createdAt: createdAt,
      text: this.renderWeatherWidget(startDate),
      isInfoHidden: true,
    }
  }

  @computed
  private get statusChangeMessages(): IDeliveryThreadMessage[] {
    const { cancellationReason } = this.props.delivery

    return this.deliveryStatusChanges.map(
      ({ id, userId, createdAt, threadId, status }) => ({
        id,
        userId,
        createdAt,
        text: this.getTextByStatusChange(threadId, status),
        status,
        cancellationReason,
      }),
    )
  }

  @computed
  private get deliveryStatusChanges(): IDeliveryStatusChange[] {
    const { deliveryStatusChangesStore, delivery } = this.props
    const statusChanges =
      deliveryStatusChangesStore.getStatusChangesForDelivery(delivery.id)

    if (statusChanges.length) {
      return statusChanges
    }

    const { createdAt, userId, status } = delivery
    return [
      {
        createdAt,
        userId,
        status,
      },
    ] as IDeliveryStatusChange[]
  }

  public render() {
    const { delivery, FileInputType } = this.props

    const {
      isSameDay,
      getWeekdayMonthAndDayToDisplay,
      getMonthAndDayToDisplay,
    } = this.props.projectDateStore

    const recurringOptions = this.props.deliveryDetailsStore.fields.find(
      field => field.id === FieldIds.RECURRING_OPTIONS,
    )

    return (
      <div>
        {this.messagesByDays.map((messageByDay, i) => {
          return (
            <div key={i}>
              <DaySeparator
                shouldUseLine={true}
                date={messageByDay.day}
                customDateTimeFormatter={getWeekdayMonthAndDayToDisplay}
                bottomOffset={delivery.recurringSettingId && '10px'}
              />
              {delivery.recurringSettingId && recurringOptions?.value && (
                <div className="row x-between px16 mb10">
                  <span className="text gray center">
                    {Localization.translator.recurringDelivery}
                  </span>
                  <span className="text gray center">
                    {this.getFrequencyText(recurringOptions)}
                  </span>
                  <span className="text gray center">
                    {Localization.translator.endingXDate(
                      getMonthAndDayToDisplay(recurringOptions.value.endDate),
                    )}
                  </span>
                </div>
              )}
              {messageByDay.messages.map((message, index) => {
                if (!isSameDay(messageByDay.day, message.createdAt)) {
                  return
                }

                const isLastMessage = index === messageByDay.messages.length - 1

                return (
                  <CommonThread
                    key={index}
                    showImages={true}
                    messages={[message]}
                    threadIcon={null}
                    threadClass="photo-thread"
                    caption={this.getCaption(message.id)}
                    model={delivery}
                    shouldHideFooterCircle={true}
                    shouldHideFooter={true}
                    showFooterLine={false}
                    isDeliveryColumn={true}
                    FileInputType={FileInputType}
                    isLastThreadInLog={isLastMessage}
                  />
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }

  public getFrequencyText = (recurringOptions: IDeliveryControl) => {
    switch (recurringOptions.value.frequencyType) {
      case CalendricalType.Day:
        return Localization.translator.everyXDays(
          recurringOptions.value.frequency,
        )
      case CalendricalType.Week:
        return Localization.translator.everyXWeeks(
          recurringOptions.value.frequency,
        )
      case CalendricalType.Month:
        return Localization.translator.everyXMonths(
          recurringOptions.value.frequency,
        )
      default:
        return recurringOptions.value.frequency
    }
  }

  private get messagesByDays(): IMessagesByDay[] {
    const messagesByDays: IMessagesByDay[] = []

    const { startOfDay, isSameDay } = this.props.projectDateStore
    this.messages.forEach(message => {
      const day = startOfDay(message.createdAt)
      const messagesByDay = messagesByDays.find(messageByDay =>
        isSameDay(messageByDay.day, day),
      )

      if (!messagesByDay) {
        messagesByDays.push({
          day,
          messages: [message],
        })
      } else {
        messagesByDay.messages.push(message)
      }
    })
    return messagesByDays
  }

  private getPhotoByStatusChangeMessage(messageId: string) {
    return this.props.photosStore.list.find(p => p.messageId === messageId)
  }

  private getTextByStatusChange(threadId: string, status: DeliveryStatus) {
    const message = this.props.messagesStore.list.find(
      m => m.threadId === threadId,
    )

    if (!message || !threadId) {
      return <div className="col mb10" />
    }

    return (
      <div className="col">
        {this.renderTextMessage(status, message.text)}
        <PhotoContainer
          caption=""
          FileInputType={this.props.FileInputType}
          photo={this.getPhotoByStatusChangeMessage(message.id)}
        />
      </div>
    )
  }

  private renderTextMessage = (
    status: DeliveryStatus,
    message: string,
  ): JSX.Element | string => {
    if (status === DeliveryStatus.Canceled) {
      return Localization.translator.reason + ': ' + message
    }

    if (status === DeliveryStatus.Changed) {
      return this.renderChangedMessage(message)
    }

    return message
  }

  private renderChangedMessage = (message: string): JSX.Element | string => {
    if (
      !message?.includes(FIELDS_AND_NOTE_SEPARATOR) &&
      !message?.includes(FIELD_VALUES_SEPARATOR)
    ) {
      return message
    }

    const [fieldLines, messageNote] = message.split(FIELDS_AND_NOTE_SEPARATOR)
    const changedFieldLines = fieldLines.split(FIELD_LINES_SEPARATOR)

    return (
      <>
        {changedFieldLines.map((fieldLine, index) => {
          const [fieldName, oldValue, newValue] = fieldLine.split(
            FIELD_VALUES_SEPARATOR,
          )
          const nameWithOldValue = `${fieldName}: ${oldValue}`

          return (
            <span
              key={index}
              className="changed-status-message inline-block vertical-align-middle nowrap text grey large italic text-ellipsis"
            >
              <span
                className="changed-status-message-old-value text-ellipsis inline-block vertical-align-bottom"
                title={nameWithOldValue}
              >
                {nameWithOldValue}&nbsp;
              </span>
              <span>→&nbsp;</span>
              <span title={newValue} className="text large red">
                {newValue}&nbsp;
              </span>
            </span>
          )
        })}
        {messageNote}
      </>
    )
  }

  private getCaption(id: string): string {
    const statusChange = this.deliveryStatusChanges.find(
      status => status.id === id,
    )

    return statusChange
      ? Delivery.getStatusDisplayName(statusChange.status)
      : ''
  }

  private renderWeatherWidget(forecastDate: Date | number) {
    const { isLoading, weatherUnits, hasProjectLocation, getForecastForDay } =
      this.props.weatherForecastsStore

    const forecast = getForecastForDay(forecastDate)

    return (
      <WeatherWidget
        isLoading={isLoading}
        hasProjectLocation={hasProjectLocation}
        todayForecast={forecast}
        projectWeatherUnits={weatherUnits}
      />
    )
  }

  private needWeatherForecast() {
    const {
      delivery: { startDate },
      projectDateStore: { isToday, isBeforeToday },
    } = this.props

    return isToday(startDate) || isBeforeToday(startDate)
  }
}

export default withRouter(DeliveryLog)
