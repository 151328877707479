import { action } from 'mobx'

import { ITradeInput } from '~/client/graph'

import { TagIconType } from '../../enums/TagIcon'
import { TagType } from '../../enums/TagType'
import { ITag } from '../../models/Tag'
import BaseTagsStore from '../BaseTags.store'
import {
  DELETE_PROJECT_TRADE,
  SAVE_PROJECT_TRADE,
} from '../EventStore/eventConstants'

export default class ProjectTradesStore extends BaseTagsStore {
  protected readonly iconName: TagIconType = TagIconType.Trade
  protected readonly tagType: TagType = TagType.Trade

  public get byId(): Map<string, ITag> {
    return this.eventsStore.appState.projectTrades
  }

  @action.bound
  public save(tag: ITag, callback?: (id: string) => void) {
    const trade: ITradeInput = {
      id: tag.id,
      name: tag.name,
      color: tag.color,
      projectId: this.eventsStore.appState.activeProject.id,
    }

    this.eventsStore.dispatch(SAVE_PROJECT_TRADE, trade, callback)
  }

  @action.bound
  public delete(treadId: string, callback?: () => void) {
    this.eventsStore.dispatch(DELETE_PROJECT_TRADE, treadId, callback)
  }
}
