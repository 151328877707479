import { action, computed } from 'mobx'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { UNASSIGNED_FILTER_OPTION } from '~/client/src/shared/components/Deliveries/DeliveriesView.store'
import {
  MaterialStatus,
  getMaterialStatusDisplayName,
} from '~/client/src/shared/enums/MaterialStatus'
import MaterialsFilterType, {
  materialsFilterTypes,
} from '~/client/src/shared/enums/MaterialsFilterType'
import { IMaterialViewModel } from '~/client/src/shared/models/IMaterialViewModel'
import { RESET_ALL_FILTERS } from '~/client/src/shared/stores/EventStore/eventConstants'
import AreasStore from '~/client/src/shared/stores/domain/Areas.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LevelsStore from '~/client/src/shared/stores/domain/Levels.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import LogisticsObjectsStore from '~/client/src/shared/stores/domain/LogisticsObjects.store'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import VerticalObjectsStore from '~/client/src/shared/stores/domain/VerticalObjects.store'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'
import { enumToList } from '~/client/src/shared/utils/converters'
import { LOCATION_SEPARATOR } from '~/client/src/shared/utils/usefulStrings'

import MaterialsListStore from '../../../MaterialsList/MaterialsList.store'
import BaseMaterialsFilterStore, {
  ISourceMap,
} from './BaseMaterialsFilter.store'

export default class MaterialsFilterStore {
  public constructor(
    protected readonly eventsStore: DesktopEventStore,
    protected readonly instancesStore: MaterialsStore,
    protected readonly instancesListStore: MaterialsListStore,
    protected readonly onShowChanged: (
      isShown: boolean,
      filterType: string,
    ) => void,
    protected readonly locationAttributesStore: LocationAttributesStore,
    protected readonly levelsStore: LevelsStore,
    protected readonly areasStore: AreasStore,
    protected readonly logisticsObjectsStore: LogisticsObjectsStore,
    protected readonly verticalObjectsStore: VerticalObjectsStore,
    protected readonly companiesStore: CompaniesStore,
    protected readonly tagsStore: TagsStore,
    protected readonly materialCategoryStore: MaterialCategoryStore,
    private readonly onFilterClickHandler?: () => void,
  ) {}

  protected get availableInstances(): IMaterialViewModel[] {
    return this.instancesStore.viewModels
  }

  @computed
  public get filterStoresByTypeMap(): {
    [filterType: string]: BaseMaterialsFilterStore
  } {
    const map: { [filterType: string]: BaseMaterialsFilterStore } = {}

    materialsFilterTypes.forEach(filterType => {
      const { appState } = this.eventsStore

      map[filterType] = new BaseMaterialsFilterStore(
        filterType,
        appState,
        this.sourceMapByFilterTypeMap[filterType],
        this.instancesListStore,
        this.onShowChanged,
        appState.materialFilters.fieldsMap,
        this.getOptionName,
        this.onFilterClickHandler,
      )
    })
    return map
  }

  @computed
  protected get sourceMapByFilterTypeMap(): {
    [filterType: string]: ISourceMap
  } {
    const maps = materialsFilterTypes.reduce((acc, filterType) => {
      acc[filterType] = this.getDefaultSourceMapByType(filterType)
      return acc
    }, {})

    this.availableInstances.forEach(viewModel => {
      const {
        id: viewModelId,
        material,
        relatedDeliveries,
        installLocation,
        deliveryLocation,
        status,
      } = viewModel

      materialsFilterTypes.forEach(filterType => {
        const map = maps[filterType]
        let optionIds: string[] = []

        switch (filterType) {
          case MaterialsFilterType.STATUS:
            optionIds = [status || UNASSIGNED_FILTER_OPTION]
            break
          case MaterialsFilterType.PLANNED_INSTALL_LOCATION:
            optionIds = [
              installLocation?.id
                ? `${installLocation.id}${LOCATION_SEPARATOR}${installLocation.type}`
                : UNASSIGNED_FILTER_OPTION,
            ]
            break
          case MaterialsFilterType.PLANNED_DELIVERY_LOCATION:
            optionIds = [
              deliveryLocation?.id
                ? `${deliveryLocation.id}${LOCATION_SEPARATOR}${deliveryLocation.type}`
                : UNASSIGNED_FILTER_OPTION,
            ]
            break
          case MaterialsFilterType.CURRENT_LOCATION:
            optionIds =
              this.instancesListStore.getCurrentLocationFormattedIds(viewModel)
            break
          case MaterialsFilterType.COMPANY:
            const companyIdsSet = new Set<string>(
              relatedDeliveries?.map(d => d.company) || [],
            )
            optionIds = companyIdsSet.size
              ? [...companyIdsSet]
              : [UNASSIGNED_FILTER_OPTION]
            break
          case MaterialsFilterType.MATERIAL:
            optionIds = [material.categoryId || UNASSIGNED_FILTER_OPTION]
            break
        }

        optionIds.forEach(optionId => {
          if (!map[optionId]) {
            optionId = UNASSIGNED_FILTER_OPTION
          }

          map[optionId].push(viewModelId)
        })
      })
    })
    return maps
  }

  protected getDefaultSourceMapByType(type: MaterialsFilterType) {
    let sourceList: string[] = []

    switch (type) {
      case MaterialsFilterType.STATUS:
        sourceList = enumToList(MaterialStatus)
        break
      case MaterialsFilterType.PLANNED_INSTALL_LOCATION:
        sourceList = Array.from(
          this.availableInstances.reduce((acc, { installLocation }) => {
            if (installLocation?.id) {
              acc.add(
                `${installLocation.id}${LOCATION_SEPARATOR}${installLocation.type}`,
              )
            }
            return acc
          }, new Set([UNASSIGNED_FILTER_OPTION])),
        )
        break
      case MaterialsFilterType.PLANNED_DELIVERY_LOCATION:
        sourceList = Array.from(
          this.availableInstances.reduce((acc, { deliveryLocation }) => {
            if (deliveryLocation?.id) {
              acc.add(
                `${deliveryLocation.id}${LOCATION_SEPARATOR}${deliveryLocation.type}`,
              )
            }
            return acc
          }, new Set([UNASSIGNED_FILTER_OPTION])),
        )
        break
      case MaterialsFilterType.CURRENT_LOCATION:
        sourceList = Array.from(
          this.locationAttributesStore.allAttributes.reduce((acc, location) => {
            acc.add(`${location.id}${LOCATION_SEPARATOR}${location.type}`)
            return acc
          }, new Set([UNASSIGNED_FILTER_OPTION])),
        )
        break
      case MaterialsFilterType.MATERIAL:
        sourceList = [
          ...this.materialCategoryStore.list.map(c => c.id),
          UNASSIGNED_FILTER_OPTION,
        ]
        break
      case MaterialsFilterType.COMPANY:
        sourceList = [
          ...this.companiesStore.allCompaniesIds,
          UNASSIGNED_FILTER_OPTION,
        ]
        break
    }

    return sourceList.reduce((acc, optionId) => {
      acc[optionId] = []
      return acc
    }, {})
  }

  protected fieldsMap() {
    return this.eventsStore.appState.materialFilters.fieldsMap
  }

  @action.bound
  public resetAllFilters() {
    this.eventsStore.dispatch(RESET_ALL_FILTERS)
  }

  @action
  public syncFilters() {
    Object.keys(this.fieldsMap).forEach(filterType => {
      const filter = this.fieldsMap[filterType]
      const sourceMap = this.sourceMapByFilterTypeMap[filterType]

      switch (filterType) {
        case MaterialsFilterType.STATUS:
          return this.updateStatusFilterState(filter, sourceMap)
      }
    })
  }

  protected updateStatusFilterState(
    filter: UIFilterInfo,
    sourceMap: ISourceMap,
  ) {
    let appliedFilterOptions = []

    filter.initialFilterOptions.forEach((_, optionKey) => {
      const instancesIds = sourceMap[optionKey]

      filter.selectedFilterOptions.set(optionKey, instancesIds)
      filter.initialFilterOptions.set(optionKey, instancesIds)

      appliedFilterOptions = appliedFilterOptions.concat(instancesIds)
    })

    filter.appliedFilterOptions = appliedFilterOptions
  }

  private getOptionName = (option: string, filterType: string): string => {
    switch (filterType) {
      case MaterialsFilterType.PLANNED_INSTALL_LOCATION:
      case MaterialsFilterType.PLANNED_DELIVERY_LOCATION:
      case MaterialsFilterType.CURRENT_LOCATION:
        return this.getLocationOptionName(option)
      case MaterialsFilterType.COMPANY:
        return this.getCompanyOptionName(option)
      case MaterialsFilterType.MATERIAL:
        return this.getMaterialCategoryOptionName(option)
      case MaterialsFilterType.STATUS:
        return getMaterialStatusDisplayName(option)

      default:
        return option
    }
  }

  private getLocationOptionName = (option: string): string => {
    const [attributeId, attributeType] = option.split(LOCATION_SEPARATOR)
    const store = this.tagsStore.tagStoreByTagTypeMap[attributeType]

    return store?.byId.get(attributeId)?.name || option
  }

  private getCompanyOptionName = (option: string): string => {
    return this.companiesStore.getCompanyNameById(
      option,
      UNASSIGNED_FILTER_OPTION,
    )
  }

  private getMaterialCategoryOptionName = (option: string): string => {
    return (
      this.materialCategoryStore.getCategoryNameById(option) ||
      UNASSIGNED_FILTER_OPTION
    )
  }
}
