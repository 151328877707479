import { observable } from 'mobx'

import { FilterType, IActivityFiltersSettings } from '~/client/graph'

import ActivityFilterInfo from './ActivityFilterInfo'

export default class ActivityFiltersSettingState {
  public filterInfoMap: { [key: string]: ActivityFilterInfo } = {
    [FilterType.Building]: new ActivityFilterInfo(FilterType.Building, false),
    [FilterType.Level]: new ActivityFilterInfo(FilterType.Level, false),
    [FilterType.Zone]: new ActivityFilterInfo(FilterType.Zone, false),
    [FilterType.Company]: new ActivityFilterInfo(FilterType.Company, false),
  }

  @observable public id: string
  @observable public hiddenActivityCodeTypesIds: string[] = []
  @observable public hiddenActivityCodeIds: string[] = []

  public updateFromDto({
    id,
    filterInfos,
    hiddenActivityCodeIds,
    hiddenActivityCodeTypesIds,
  }: IActivityFiltersSettings) {
    this.id = id
    this.hiddenActivityCodeIds = hiddenActivityCodeIds
    this.hiddenActivityCodeTypesIds = hiddenActivityCodeTypesIds

    filterInfos.forEach(filterInfo => {
      const { name } = filterInfo

      let filter = this.filterInfoMap[name]
      if (!filter) {
        filter = this.filterInfoMap[name] = new ActivityFilterInfo(name, true)
      }

      filter.setFromSource(filterInfo)
    })
  }

  public toDto(): IActivityFiltersSettings {
    const dto: any = JSON.parse(JSON.stringify(this))
    delete dto.filterInfoMap

    dto.filterInfos = Object.values(this.filterInfoMap)

    return dto
  }
}
