import { action, computed, observable } from 'mobx'

import BaseTagSelectionStore from '~/client/src/desktop/stores/ui/BaseTagSelection.store'
import { CheckState } from '~/client/src/shared/components/Checkbox'
import { TagType } from '~/client/src/shared/enums/TagType'
import Company from '~/client/src/shared/models/Company'
import { ITag } from '~/client/src/shared/models/Tag'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

export default class BulkEditCompaniesSideBarStore extends BaseTagSelectionStore {
  @observable public selectedCompanies: Company[] = []
  @observable public isConfirmationModalOpened = false
  public tagsWithErrors: TagType[] = []

  public constructor(
    private readonly tagsStore: TagsStore,
    private readonly selectedProjectMembers: Company[] = [],
    private readonly saveCompanies: (items: Company[]) => void,
    private readonly availableCategories: TagType[],
    private readonly requiredTagTypes: TagType[] = [],
  ) {
    super()
    this.init(selectedProjectMembers)
  }

  public init(selectedCompanies: Company[] = []) {
    this.selectedCompanies = selectedCompanies
    this.tagsWithErrors = []
  }

  @computed
  public get srcAsTags(): ITag[] {
    const allTagIds = []

    this.selectedCompanies.forEach(u => {
      this.availableCategories.forEach(type => {
        allTagIds.push(...this.getTagsByCompanyAndType(u, type))
      })
    })

    return [...new Set(allTagIds)]
  }

  public getInstanceCheckState = ({
    type: tagType,
    id: tagId,
  }: ITag): CheckState => {
    const doEveryInstancesHaveTag = this.selectedCompanies.every(m => {
      const ids = this.getCompanyTagsIdsByType(m, tagType)
      return ids?.includes(tagId)
    })

    return doEveryInstancesHaveTag
      ? CheckState.CHECKED
      : CheckState.PARTIALLY_CHECKED
  }

  @action.bound
  public openConfirmationModal() {
    this.isConfirmationModalOpened = true
  }

  @action.bound
  public closeConfirmationModal() {
    this.tagsWithErrors = []
    this.isConfirmationModalOpened = false
  }

  @action.bound
  public onConfirm() {
    this.saveCompanies(this.selectedCompanies)

    super.handleApply()
    this.closeConfirmationModal()
  }

  @action.bound
  public handleApply() {
    this.selectedCompanies.forEach(company => {
      this.tagsForAdding.forEach(tag => this.setTagToCompany(company, tag))
      this.tagsForDeletion.forEach(tag =>
        this.removeTagFromCompany(company, tag),
      )
    })

    this.requiredTagTypes.forEach(type => {
      const hasErrors = this.selectedCompanies.some(company => {
        const ids = this.getCompanyTagsIdsByType(company, type)
        return !ids?.length
      })
      if (hasErrors && !this.tagsWithErrors.includes(type)) {
        this.tagsWithErrors.push(type)
      }
    })

    if (this.tagsWithErrors.length) {
      this.openConfirmationModal()
    } else {
      this.saveCompanies(this.selectedCompanies)

      super.handleApply()
    }
  }

  private getTagsByCompanyAndType = (
    company: Company,
    tagType: TagType,
  ): ITag[] => {
    return this.tagsStore.getTagsByIds(
      tagType,
      this.getCompanyTagsIdsByType(company, tagType),
    )
  }

  private getCompanyTagsIdsByType(company: Company, tagType: TagType) {
    switch (tagType) {
      case TagType.CompanyType:
        return company.typeTags
      case TagType.Role:
        return company.roleIds
      case TagType.Trade:
        return company.tradeIds
    }
  }

  private setTagToCompany(company: Company, tag: ITag) {
    switch (tag.type) {
      case TagType.CompanyType:
        if (!company.typeTags.includes(tag.id)) {
          company.typeTags.push(tag.id)
        }
        break
      case TagType.Role:
        if (!company.roleIds.includes(tag.id)) {
          company.roleIds.push(tag.id)
        }
        break
      case TagType.Trade:
        if (!company.tradeIds.includes(tag.id)) {
          company.tradeIds.push(tag.id)
        }
    }
  }

  private removeTagFromCompany(company: Company, tag: ITag) {
    switch (tag.type) {
      case TagType.Role:
        const roleIndex = company.roleIds.indexOf(tag.id)
        roleIndex !== -1 && company.roleIds.splice(roleIndex, 1)
        break
      case TagType.Trade:
        const tradeIndex = company.tradeIds.indexOf(tag.id)
        tradeIndex !== -1 && company.tradeIds.splice(tradeIndex, 1)
        break
      case TagType.CompanyType:
        const typeIndex = company.typeTags.indexOf(tag.id)
        typeIndex !== -1 && company.typeTags.splice(typeIndex, 1)
        break
    }
  }
}
