import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'
import {
  CommonPermitFieldsFragmentFragmentDoc,
  ICommonPermitFieldsFragment_AttachmentPermitField_Fragment,
  ICommonPermitFieldsFragment_ChecklistAnswerItemPermitField_Fragment,
  ICommonPermitFieldsFragment_DateTimePermitField_Fragment,
  ICommonPermitFieldsFragment_LocationPermitField_Fragment,
  ICommonPermitFieldsFragment_MaterialFormField_Fragment,
  ICommonPermitFieldsFragment_MeasurePermitField_Fragment,
  ICommonPermitFieldsFragment_ObjectIdPermitField_Fragment,
  ICommonPermitFieldsFragment_StringPermitField_Fragment,
  ICommonPermitFieldsFragment_TablePermitField_Fragment,
} from './SitePermits.generated'

const defaultOptions = {}
export type IInspectionFieldsFragment = Pick<
  Types.ISitePermitInspection,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'projectId'
  | 'permitId'
  | 'userId'
  | 'inspectionDate'
> & {
  fields?: Types.Maybe<
    Array<
      Types.Maybe<
        | ICommonPermitFieldsFragment_AttachmentPermitField_Fragment
        | ICommonPermitFieldsFragment_ChecklistAnswerItemPermitField_Fragment
        | ICommonPermitFieldsFragment_DateTimePermitField_Fragment
        | ICommonPermitFieldsFragment_LocationPermitField_Fragment
        | ICommonPermitFieldsFragment_MaterialFormField_Fragment
        | ICommonPermitFieldsFragment_MeasurePermitField_Fragment
        | ICommonPermitFieldsFragment_ObjectIdPermitField_Fragment
        | ICommonPermitFieldsFragment_StringPermitField_Fragment
        | ({
            tableValues?: Types.Maybe<
              Array<
                Types.Maybe<{
                  cells?: Types.Maybe<
                    Array<
                      Types.Maybe<
                        | ICommonPermitFieldsFragment_AttachmentPermitField_Fragment
                        | ICommonPermitFieldsFragment_ChecklistAnswerItemPermitField_Fragment
                        | ICommonPermitFieldsFragment_DateTimePermitField_Fragment
                        | ICommonPermitFieldsFragment_LocationPermitField_Fragment
                        | ICommonPermitFieldsFragment_MaterialFormField_Fragment
                        | ICommonPermitFieldsFragment_MeasurePermitField_Fragment
                        | ICommonPermitFieldsFragment_ObjectIdPermitField_Fragment
                        | ICommonPermitFieldsFragment_StringPermitField_Fragment
                        | ICommonPermitFieldsFragment_TablePermitField_Fragment
                      >
                    >
                  >
                }>
              >
            >
          } & ICommonPermitFieldsFragment_TablePermitField_Fragment)
      >
    >
  >
}

export type IInspectionChangeFieldsFragment = Pick<
  Types.ISitePermitInspectionChange,
  'id' | 'createdAt' | 'updatedAt' | 'projectId' | 'inspectionId' | 'userId'
> & {
  fields?: Types.Maybe<
    Array<
      Types.Maybe<
        | ICommonPermitFieldsFragment_AttachmentPermitField_Fragment
        | ICommonPermitFieldsFragment_ChecklistAnswerItemPermitField_Fragment
        | ICommonPermitFieldsFragment_DateTimePermitField_Fragment
        | ICommonPermitFieldsFragment_LocationPermitField_Fragment
        | ICommonPermitFieldsFragment_MaterialFormField_Fragment
        | ICommonPermitFieldsFragment_MeasurePermitField_Fragment
        | ICommonPermitFieldsFragment_ObjectIdPermitField_Fragment
        | ICommonPermitFieldsFragment_StringPermitField_Fragment
        | ({
            tableValues?: Types.Maybe<
              Array<
                Types.Maybe<{
                  cells?: Types.Maybe<
                    Array<
                      Types.Maybe<
                        | ICommonPermitFieldsFragment_AttachmentPermitField_Fragment
                        | ICommonPermitFieldsFragment_ChecklistAnswerItemPermitField_Fragment
                        | ICommonPermitFieldsFragment_DateTimePermitField_Fragment
                        | ICommonPermitFieldsFragment_LocationPermitField_Fragment
                        | ICommonPermitFieldsFragment_MaterialFormField_Fragment
                        | ICommonPermitFieldsFragment_MeasurePermitField_Fragment
                        | ICommonPermitFieldsFragment_ObjectIdPermitField_Fragment
                        | ICommonPermitFieldsFragment_StringPermitField_Fragment
                        | ICommonPermitFieldsFragment_TablePermitField_Fragment
                      >
                    >
                  >
                }>
              >
            >
          } & ICommonPermitFieldsFragment_TablePermitField_Fragment)
      >
    >
  >
}

export type IGetSitePermitInspectionListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetSitePermitInspectionListQuery = {
  sitePermitInspections?: Types.Maybe<{
    data: Array<IInspectionFieldsFragment>
  }>
}

export type IGetSitePermitInspectionChangeListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetSitePermitInspectionChangeListQuery = {
  sitePermitInspectionChanges?: Types.Maybe<{
    data: Array<IInspectionChangeFieldsFragment>
  }>
}

export type IAddSitePermitInspectionMutationVariables = Types.Exact<{
  inspection: Types.ISitePermitInspectionInput
  fields: Types.IPermitFieldsInput
}>

export type IAddSitePermitInspectionMutation = {
  addSitePermitInspection?: Types.Maybe<IInspectionFieldsFragment>
}

export type IAddSitePermitInspectionChangeMutationVariables = Types.Exact<{
  inspectionChange: Types.ISitePermitInspectionInput
  fields: Types.IPermitFieldsInput
}>

export type IAddSitePermitInspectionChangeMutation = {
  addSitePermitInspectionChange?: Types.Maybe<IInspectionChangeFieldsFragment>
}

export type IListenToSitePermitInspectionSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToSitePermitInspectionSubscription = {
  sitePermitInspection?: Types.Maybe<
    Pick<Types.ISitePermitInspectionChangeEvent, 'id'> & {
      item?: Types.Maybe<IInspectionFieldsFragment>
    }
  >
}

export type IListenToSitePermitInspectionChangeSubscriptionVariables =
  Types.Exact<{
    projectId: Types.Scalars['ObjectId']
  }>

export type IListenToSitePermitInspectionChangeSubscription = {
  sitePermitInspectionChange?: Types.Maybe<
    Pick<Types.ISitePermitInspectionChangeChangeEvent, 'id'> & {
      item?: Types.Maybe<IInspectionChangeFieldsFragment>
    }
  >
}

export const InspectionFieldsFragmentDoc = gql`
  fragment InspectionFields on SitePermitInspection {
    id
    createdAt
    updatedAt
    projectId
    permitId
    userId
    inspectionDate
    fields {
      ...CommonPermitFieldsFragment
      ... on TablePermitField {
        tableValues {
          cells {
            ...CommonPermitFieldsFragment
          }
        }
      }
    }
  }
  ${CommonPermitFieldsFragmentFragmentDoc}
`
export const InspectionChangeFieldsFragmentDoc = gql`
  fragment InspectionChangeFields on SitePermitInspectionChange {
    id
    createdAt
    updatedAt
    projectId
    inspectionId
    userId
    fields {
      ...CommonPermitFieldsFragment
      ... on TablePermitField {
        tableValues {
          cells {
            ...CommonPermitFieldsFragment
          }
        }
      }
    }
  }
  ${CommonPermitFieldsFragmentFragmentDoc}
`
export const GetSitePermitInspectionListDocument = gql`
  query GetSitePermitInspectionList($projectId: ObjectId!) {
    sitePermitInspections(limit: 1000000, projectId: $projectId) {
      data {
        ...InspectionFields
      }
    }
  }
  ${InspectionFieldsFragmentDoc}
`

/**
 * __useGetSitePermitInspectionListQuery__
 *
 * To run a query within a React component, call `useGetSitePermitInspectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitePermitInspectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitePermitInspectionListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetSitePermitInspectionListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetSitePermitInspectionListQuery,
    IGetSitePermitInspectionListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetSitePermitInspectionListQuery,
    IGetSitePermitInspectionListQueryVariables
  >(GetSitePermitInspectionListDocument, options)
}
export function useGetSitePermitInspectionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetSitePermitInspectionListQuery,
    IGetSitePermitInspectionListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetSitePermitInspectionListQuery,
    IGetSitePermitInspectionListQueryVariables
  >(GetSitePermitInspectionListDocument, options)
}
export type GetSitePermitInspectionListQueryHookResult = ReturnType<
  typeof useGetSitePermitInspectionListQuery
>
export type GetSitePermitInspectionListLazyQueryHookResult = ReturnType<
  typeof useGetSitePermitInspectionListLazyQuery
>
export type GetSitePermitInspectionListQueryResult = Apollo.QueryResult<
  IGetSitePermitInspectionListQuery,
  IGetSitePermitInspectionListQueryVariables
>
export const GetSitePermitInspectionChangeListDocument = gql`
  query GetSitePermitInspectionChangeList($projectId: ObjectId!) {
    sitePermitInspectionChanges(limit: 1000000, projectId: $projectId) {
      data {
        ...InspectionChangeFields
      }
    }
  }
  ${InspectionChangeFieldsFragmentDoc}
`

/**
 * __useGetSitePermitInspectionChangeListQuery__
 *
 * To run a query within a React component, call `useGetSitePermitInspectionChangeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitePermitInspectionChangeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitePermitInspectionChangeListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetSitePermitInspectionChangeListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetSitePermitInspectionChangeListQuery,
    IGetSitePermitInspectionChangeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetSitePermitInspectionChangeListQuery,
    IGetSitePermitInspectionChangeListQueryVariables
  >(GetSitePermitInspectionChangeListDocument, options)
}
export function useGetSitePermitInspectionChangeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetSitePermitInspectionChangeListQuery,
    IGetSitePermitInspectionChangeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetSitePermitInspectionChangeListQuery,
    IGetSitePermitInspectionChangeListQueryVariables
  >(GetSitePermitInspectionChangeListDocument, options)
}
export type GetSitePermitInspectionChangeListQueryHookResult = ReturnType<
  typeof useGetSitePermitInspectionChangeListQuery
>
export type GetSitePermitInspectionChangeListLazyQueryHookResult = ReturnType<
  typeof useGetSitePermitInspectionChangeListLazyQuery
>
export type GetSitePermitInspectionChangeListQueryResult = Apollo.QueryResult<
  IGetSitePermitInspectionChangeListQuery,
  IGetSitePermitInspectionChangeListQueryVariables
>
export const AddSitePermitInspectionDocument = gql`
  mutation AddSitePermitInspection(
    $inspection: SitePermitInspectionInput!
    $fields: PermitFieldsInput!
  ) {
    addSitePermitInspection(inspection: $inspection, fields: $fields) {
      ...InspectionFields
    }
  }
  ${InspectionFieldsFragmentDoc}
`
export type IAddSitePermitInspectionMutationFn = Apollo.MutationFunction<
  IAddSitePermitInspectionMutation,
  IAddSitePermitInspectionMutationVariables
>

/**
 * __useAddSitePermitInspectionMutation__
 *
 * To run a mutation, you first call `useAddSitePermitInspectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSitePermitInspectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSitePermitInspectionMutation, { data, loading, error }] = useAddSitePermitInspectionMutation({
 *   variables: {
 *      inspection: // value for 'inspection'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useAddSitePermitInspectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IAddSitePermitInspectionMutation,
    IAddSitePermitInspectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IAddSitePermitInspectionMutation,
    IAddSitePermitInspectionMutationVariables
  >(AddSitePermitInspectionDocument, options)
}
export type AddSitePermitInspectionMutationHookResult = ReturnType<
  typeof useAddSitePermitInspectionMutation
>
export type AddSitePermitInspectionMutationResult =
  Apollo.MutationResult<IAddSitePermitInspectionMutation>
export type AddSitePermitInspectionMutationOptions = Apollo.BaseMutationOptions<
  IAddSitePermitInspectionMutation,
  IAddSitePermitInspectionMutationVariables
>
export const AddSitePermitInspectionChangeDocument = gql`
  mutation AddSitePermitInspectionChange(
    $inspectionChange: SitePermitInspectionInput!
    $fields: PermitFieldsInput!
  ) {
    addSitePermitInspectionChange(
      inspectionChange: $inspectionChange
      fields: $fields
    ) {
      ...InspectionChangeFields
    }
  }
  ${InspectionChangeFieldsFragmentDoc}
`
export type IAddSitePermitInspectionChangeMutationFn = Apollo.MutationFunction<
  IAddSitePermitInspectionChangeMutation,
  IAddSitePermitInspectionChangeMutationVariables
>

/**
 * __useAddSitePermitInspectionChangeMutation__
 *
 * To run a mutation, you first call `useAddSitePermitInspectionChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSitePermitInspectionChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSitePermitInspectionChangeMutation, { data, loading, error }] = useAddSitePermitInspectionChangeMutation({
 *   variables: {
 *      inspectionChange: // value for 'inspectionChange'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useAddSitePermitInspectionChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IAddSitePermitInspectionChangeMutation,
    IAddSitePermitInspectionChangeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IAddSitePermitInspectionChangeMutation,
    IAddSitePermitInspectionChangeMutationVariables
  >(AddSitePermitInspectionChangeDocument, options)
}
export type AddSitePermitInspectionChangeMutationHookResult = ReturnType<
  typeof useAddSitePermitInspectionChangeMutation
>
export type AddSitePermitInspectionChangeMutationResult =
  Apollo.MutationResult<IAddSitePermitInspectionChangeMutation>
export type AddSitePermitInspectionChangeMutationOptions =
  Apollo.BaseMutationOptions<
    IAddSitePermitInspectionChangeMutation,
    IAddSitePermitInspectionChangeMutationVariables
  >
export const ListenToSitePermitInspectionDocument = gql`
  subscription ListenToSitePermitInspection($projectId: ObjectId!) {
    sitePermitInspection(projectId: $projectId) {
      id
      item {
        ...InspectionFields
      }
    }
  }
  ${InspectionFieldsFragmentDoc}
`

/**
 * __useListenToSitePermitInspectionSubscription__
 *
 * To run a query within a React component, call `useListenToSitePermitInspectionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToSitePermitInspectionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToSitePermitInspectionSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToSitePermitInspectionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToSitePermitInspectionSubscription,
    IListenToSitePermitInspectionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToSitePermitInspectionSubscription,
    IListenToSitePermitInspectionSubscriptionVariables
  >(ListenToSitePermitInspectionDocument, options)
}
export type ListenToSitePermitInspectionSubscriptionHookResult = ReturnType<
  typeof useListenToSitePermitInspectionSubscription
>
export type ListenToSitePermitInspectionSubscriptionResult =
  Apollo.SubscriptionResult<IListenToSitePermitInspectionSubscription>
export const ListenToSitePermitInspectionChangeDocument = gql`
  subscription ListenToSitePermitInspectionChange($projectId: ObjectId!) {
    sitePermitInspectionChange(projectId: $projectId) {
      id
      item {
        ...InspectionChangeFields
      }
    }
  }
  ${InspectionChangeFieldsFragmentDoc}
`

/**
 * __useListenToSitePermitInspectionChangeSubscription__
 *
 * To run a query within a React component, call `useListenToSitePermitInspectionChangeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToSitePermitInspectionChangeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToSitePermitInspectionChangeSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToSitePermitInspectionChangeSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToSitePermitInspectionChangeSubscription,
    IListenToSitePermitInspectionChangeSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToSitePermitInspectionChangeSubscription,
    IListenToSitePermitInspectionChangeSubscriptionVariables
  >(ListenToSitePermitInspectionChangeDocument, options)
}
export type ListenToSitePermitInspectionChangeSubscriptionHookResult =
  ReturnType<typeof useListenToSitePermitInspectionChangeSubscription>
export type ListenToSitePermitInspectionChangeSubscriptionResult =
  Apollo.SubscriptionResult<IListenToSitePermitInspectionChangeSubscription>
