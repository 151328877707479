import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IAnalyticsSettingFieldsFragment = Pick<
  Types.IAnalyticsSettings,
  'projectId' | 'id'
> & {
  fields?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<
          Types.IAnalyticSettingsField,
          'id' | 'name' | 'type' | 'isEnabled'
        > & {
          permitTypeFields?: Types.Maybe<
            Array<
              Types.Maybe<
                Pick<
                  Types.IPermitTypeFieldReference,
                  'permitTypeFieldId' | 'checkListItemId'
                >
              >
            >
          >
          summaryFields?: Types.Maybe<
            Array<Types.Maybe<Pick<Types.ISummaryField, 'name' | 'fieldId'>>>
          >
        }
      >
    >
  >
}

export type IGetAnalyticsSettingByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetAnalyticsSettingByProjectIdQuery = {
  analyticsSettings?: Types.Maybe<IAnalyticsSettingFieldsFragment>
}

export type ISaveAnalyticsSettingMutationVariables = Types.Exact<{
  analyticsSettings: Types.IAnalyticsSettingsInput
}>

export type ISaveAnalyticsSettingMutation = {
  saveAnalyticsSettings?: Types.Maybe<IAnalyticsSettingFieldsFragment>
}

export type IListenAnalyticsSettingByProjectAndUserIdSubscriptionVariables =
  Types.Exact<{
    projectId: Types.Scalars['ObjectId']
  }>

export type IListenAnalyticsSettingByProjectAndUserIdSubscription = {
  analyticsSettings?: Types.Maybe<
    Pick<Types.IAnalyticsSettingsChangeEvent, 'id'> & {
      item?: Types.Maybe<IAnalyticsSettingFieldsFragment>
    }
  >
}

export const AnalyticsSettingFieldsFragmentDoc = gql`
  fragment AnalyticsSettingFields on AnalyticsSettings {
    projectId
    id
    fields {
      id
      name
      type
      isEnabled
      permitTypeFields {
        permitTypeFieldId
        checkListItemId
      }
      summaryFields {
        name
        fieldId
      }
    }
  }
`
export const GetAnalyticsSettingByProjectIdDocument = gql`
  query GetAnalyticsSettingByProjectId($projectId: ObjectId!) {
    analyticsSettings(projectId: $projectId) {
      ...AnalyticsSettingFields
    }
  }
  ${AnalyticsSettingFieldsFragmentDoc}
`

/**
 * __useGetAnalyticsSettingByProjectIdQuery__
 *
 * To run a query within a React component, call `useGetAnalyticsSettingByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyticsSettingByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyticsSettingByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetAnalyticsSettingByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetAnalyticsSettingByProjectIdQuery,
    IGetAnalyticsSettingByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetAnalyticsSettingByProjectIdQuery,
    IGetAnalyticsSettingByProjectIdQueryVariables
  >(GetAnalyticsSettingByProjectIdDocument, options)
}
export function useGetAnalyticsSettingByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetAnalyticsSettingByProjectIdQuery,
    IGetAnalyticsSettingByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetAnalyticsSettingByProjectIdQuery,
    IGetAnalyticsSettingByProjectIdQueryVariables
  >(GetAnalyticsSettingByProjectIdDocument, options)
}
export type GetAnalyticsSettingByProjectIdQueryHookResult = ReturnType<
  typeof useGetAnalyticsSettingByProjectIdQuery
>
export type GetAnalyticsSettingByProjectIdLazyQueryHookResult = ReturnType<
  typeof useGetAnalyticsSettingByProjectIdLazyQuery
>
export type GetAnalyticsSettingByProjectIdQueryResult = Apollo.QueryResult<
  IGetAnalyticsSettingByProjectIdQuery,
  IGetAnalyticsSettingByProjectIdQueryVariables
>
export const SaveAnalyticsSettingDocument = gql`
  mutation SaveAnalyticsSetting($analyticsSettings: AnalyticsSettingsInput!) {
    saveAnalyticsSettings(analyticsSettings: $analyticsSettings) {
      ...AnalyticsSettingFields
    }
  }
  ${AnalyticsSettingFieldsFragmentDoc}
`
export type ISaveAnalyticsSettingMutationFn = Apollo.MutationFunction<
  ISaveAnalyticsSettingMutation,
  ISaveAnalyticsSettingMutationVariables
>

/**
 * __useSaveAnalyticsSettingMutation__
 *
 * To run a mutation, you first call `useSaveAnalyticsSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAnalyticsSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAnalyticsSettingMutation, { data, loading, error }] = useSaveAnalyticsSettingMutation({
 *   variables: {
 *      analyticsSettings: // value for 'analyticsSettings'
 *   },
 * });
 */
export function useSaveAnalyticsSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveAnalyticsSettingMutation,
    ISaveAnalyticsSettingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveAnalyticsSettingMutation,
    ISaveAnalyticsSettingMutationVariables
  >(SaveAnalyticsSettingDocument, options)
}
export type SaveAnalyticsSettingMutationHookResult = ReturnType<
  typeof useSaveAnalyticsSettingMutation
>
export type SaveAnalyticsSettingMutationResult =
  Apollo.MutationResult<ISaveAnalyticsSettingMutation>
export type SaveAnalyticsSettingMutationOptions = Apollo.BaseMutationOptions<
  ISaveAnalyticsSettingMutation,
  ISaveAnalyticsSettingMutationVariables
>
export const ListenAnalyticsSettingByProjectAndUserIdDocument = gql`
  subscription ListenAnalyticsSettingByProjectAndUserId($projectId: ObjectId!) {
    analyticsSettings(projectId: $projectId) {
      id
      item {
        ...AnalyticsSettingFields
      }
    }
  }
  ${AnalyticsSettingFieldsFragmentDoc}
`

/**
 * __useListenAnalyticsSettingByProjectAndUserIdSubscription__
 *
 * To run a query within a React component, call `useListenAnalyticsSettingByProjectAndUserIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenAnalyticsSettingByProjectAndUserIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenAnalyticsSettingByProjectAndUserIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenAnalyticsSettingByProjectAndUserIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenAnalyticsSettingByProjectAndUserIdSubscription,
    IListenAnalyticsSettingByProjectAndUserIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenAnalyticsSettingByProjectAndUserIdSubscription,
    IListenAnalyticsSettingByProjectAndUserIdSubscriptionVariables
  >(ListenAnalyticsSettingByProjectAndUserIdDocument, options)
}
export type ListenAnalyticsSettingByProjectAndUserIdSubscriptionHookResult =
  ReturnType<typeof useListenAnalyticsSettingByProjectAndUserIdSubscription>
export type ListenAnalyticsSettingByProjectAndUserIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenAnalyticsSettingByProjectAndUserIdSubscription>
