import { action, observable } from 'mobx'

import AuthenticationStore from '../../stores/domain/Authentication.store'
import CommonStore from '../../stores/ui/Common.store'
import { secondsToFullHours } from '../../stores/ui/ProjectDate.store'
import { getTokenPayload } from '../../utils/JwtHelper'

export default class ExpiredInviteKeyInfo {
  @observable public wasInviteResent: boolean = false
  @observable public isLoading: boolean = false

  public constructor(
    private readonly expiredInviteKey: string,
    private readonly auth: AuthenticationStore,
    private readonly common: CommonStore,
  ) {}

  public get durationOfInviteKeyInHours(): number {
    try {
      const { exp, iat } = getTokenPayload(this.expiredInviteKey)
      // eslint-disable-next-line no-var
      var durationInHours = secondsToFullHours(exp - iat)
    } catch {
      /* empty */
    }

    return durationInHours || 0
  }

  @action.bound
  public async handleResendInvitation() {
    this.isLoading = true

    try {
      await this.auth.resendInviteKey(this.expiredInviteKey)
    } catch (e) {
      return alert(e)
    } finally {
      this.isLoading = false
    }

    this.wasInviteResent = true
  }

  public handleClose = () => {
    this.common.displayLoginView()
  }
}
