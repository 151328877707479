import { computed } from 'mobx'

import { IDelivery, IMutation } from '~/client/graph'
import Activity from '~/client/src/shared/models/Activity'
import Delivery from '~/client/src/shared/models/Delivery'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'

import RecurringDeliveriesSettings from '../../models/RecurringDeliveriesSettings'
import CommonDeliveryAttributeDto from '../../types/CommonDeliveryAttributeDto'
import EventsStore from '../EventStore/Events.store'
import BaseStoreWithById from '../baseStores/BaseWithById.store'
import ProjectDateStore from '../ui/ProjectDate.store'
import DeliveryUnitsStore from './DeliveryUnits.store'

export default class DeliveriesStore extends BaseStoreWithById<
  Delivery,
  IDelivery
> {
  public constructor(
    private eventsStore: EventsStore,
    private readonly deliveryUnitsStore: DeliveryUnitsStore,
  ) {
    super(Delivery)
  }

  public get byId() {
    return this.eventsStore.appState.deliveries
  }

  @computed
  public get availableDeliveries(): Delivery[] {
    return this.list.filter(({ isDeleted }) => !isDeleted)
  }

  @computed
  public get materialUnitsMap(): {
    [materialId: string]: CommonDeliveryAttributeDto
  } {
    return this.availableDeliveries
      .filter(d => d.materials)
      .sort((a, b) => a.updatedAt - b.updatedAt)
      .reduce((map, delivery) => {
        delivery.materialIds.forEach(mId => {
          const materials = delivery.materialsMap[mId] || []
          const existingUnit = this.deliveryUnitsStore.getInstanceById(
            materials[materials.length - 1]?.unitId,
          )
          if (existingUnit?.id) {
            map[mId] = existingUnit
          }
        })
        return map
      }, {})
  }

  public doesActivityHaveDeliveryPerDate(
    activity: Activity,
    currentViewDate: Date,
    projectDateStore: ProjectDateStore,
  ): boolean {
    return this.list.some(
      delivery =>
        delivery.activityId === activity.code &&
        !delivery.isDeleted &&
        projectDateStore.isSameDay(delivery.startDate, currentViewDate),
    )
  }

  public getLatestDeliveryByProjectAndUser(
    projectId: string,
    userId: string,
  ): Delivery {
    return this.availableDeliveries
      .filter(
        delivery =>
          delivery.projectId === projectId && delivery.userId === userId,
      )
      .sort((a, b) => b.createdAt - a.createdAt)[0]
  }

  public async createDelivery(
    delivery: Delivery,
    recurringSetting?: RecurringDeliveriesSettings,
  ): Promise<string> {
    if (recurringSetting) {
      return this.createRecurringDelivery(delivery, recurringSetting)
    }

    const { createDelivery: createdDelivery } = await new Promise<IMutation>(
      (res, rej) =>
        this.eventsStore.dispatch(e.CREATE_DELIVERY, delivery, res, rej),
    )

    this.eventsStore.dispatch(e.COLLECT_TELEMETRY, {
      event: {
        eventCategory: 'deliveries',
        eventAction: 'Create',
        eventLabel: `Delivery - ${createdDelivery.id}`,
        eventValue: 1,
      },
    })

    return createdDelivery.id
  }

  public async updateDelivery(
    delivery: Delivery,
    message: any,
    shouldAddStatusChange: boolean,
    recurringSettings?: RecurringDeliveriesSettings,
    relatedRecurringIds?: string[],
  ) {
    if (
      delivery.recurringSettingId &&
      !!recurringSettings &&
      !!relatedRecurringIds?.length
    ) {
      return this.updateRecurringDelivery(
        delivery,
        message,
        recurringSettings,
        relatedRecurringIds,
        shouldAddStatusChange,
      )
    }

    await new Promise((res, rej) =>
      this.eventsStore.dispatch(e.UPDATE_DELIVERY, delivery, message, res, rej),
    )

    if (shouldAddStatusChange) {
      this.eventsStore.dispatchN([
        [e.CHANGE_DELIVERY_STATUS_SUCCESS],
        [
          e.COLLECT_TELEMETRY,
          {
            event: {
              eventCategory: 'deliveries',
              eventAction: `Change status - ${delivery.status}`,
              eventLabel: `Delivery - ${delivery.id}`,
              eventValue: 1,
            },
          },
        ],
      ])
    }
  }

  private async createRecurringDelivery(
    delivery: Delivery,
    recurringSettings: RecurringDeliveriesSettings,
  ): Promise<string> {
    const {
      createRecurringDeliveries: recurringDeliveries,
    }: {
      createRecurringDeliveries: IDelivery[]
    } = await new Promise((res, rej) =>
      this.eventsStore.dispatch(
        e.CREATE_RECURRING_DELIVERIES,
        delivery,
        recurringSettings,
        res,
        rej,
      ),
    )

    this.eventsStore.dispatch(e.COLLECT_TELEMETRY, {
      event: {
        eventCategory: 'deliveries',
        eventAction: 'Create',
        eventLabel: `Recurring deliveries - ${recurringDeliveries
          .map(d => d.id)
          .join(', ')}`,
        eventValue: 1,
      },
    })

    return (
      recurringDeliveries?.find(d => d.startDate === delivery.startDate)?.id ||
      recurringDeliveries?.[0]?.id
    )
  }

  private async updateRecurringDelivery(
    delivery: Delivery,
    message: any,
    recurringSettings: RecurringDeliveriesSettings,
    relatedRecurringIds: string[],
    shouldAddStatusChange: boolean,
  ) {
    const {
      updateRecurringDeliveries: recurringDeliveries,
    }: {
      updateRecurringDeliveries: IDelivery[]
    } = await new Promise((res, rej) =>
      this.eventsStore.dispatch(
        e.UPDATE_RECURRING_DELIVERIES,
        delivery,
        message,
        recurringSettings,
        relatedRecurringIds,
        res,
        rej,
      ),
    )

    if (shouldAddStatusChange) {
      this.eventsStore.dispatchN([
        [e.CHANGE_DELIVERY_STATUS_SUCCESS],
        [
          e.COLLECT_TELEMETRY,
          {
            event: {
              eventCategory: 'deliveries',
              eventAction: `Change status - ${delivery.status}`,
              eventLabel: `Recurring deliveries - ${recurringDeliveries
                .map(d => d.id)
                .join(', ')}`,
              eventValue: 1,
            },
          },
        ],
      ])
    }
  }
}
