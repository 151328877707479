import * as React from 'react'

import { action } from 'mobx'
import { observer } from 'mobx-react'

import DesktopDeliveryViewStore from '~/client/src/desktop/views/Deliveries/Deliveries.store'
import * as Icons from '~/client/src/shared/components/Icons'
import { NOOP } from '~/client/src/shared/utils/noop'

// localization: no display text to translate

export interface IProps {
  store?: DesktopDeliveryViewStore
  title: string
  content: JSX.Element
  handleClose?: () => void
  subTitle?: string
  shouldShowHeader?: boolean
}

@observer
export default class DeliveryActionSideBase extends React.Component<IProps> {
  public static defaultProps = {
    subTittle: '',
    handleClose: NOOP,
    shouldShowHeader: true,
  }

  public render() {
    const { title, subTitle, content, shouldShowHeader } = this.props

    return (
      <div className="desktop-deliveries-details col">
        {shouldShowHeader && (
          <div className="row pa10 pl20 bb-light-grey">
            <div className="no-grow col y-center" onClick={this.handleClose}>
              <Icons.RightBlueArrow className="row pointer" />
            </div>
            <div className="col ml10">
              <div className="text primary-blue extra-large bold uppercase">
                {title}
              </div>
              <div className="text light large bold uppercase">{subTitle}</div>
            </div>
          </div>
        )}
        <div className="full-height overflow-hidden">{content}</div>
      </div>
    )
  }

  @action.bound
  private handleClose() {
    const { store, handleClose } = this.props
    if (store) {
      store.resetActionType()
    }
    handleClose()
  }
}
