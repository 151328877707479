import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import TwoMonthsDatePicker from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker'
import TwoMonthsDatePickerStore from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import * as Icons from '~/client/src/shared/components/Icons'
import { HGap } from '~/client/src/shared/components/Layout'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectDateStore, {
  isBefore,
} from '~/client/src/shared/stores/ui/ProjectDate.store'

import './DeliveryWorkflowDatePicker.scss'

// localization: translated

export interface IProps {
  store: TwoMonthsDatePickerStore
  projectDateStore?: ProjectDateStore
  clearAndClose?: () => void
}

@inject('projectDateStore')
@observer
export default class DeliveryWorkflowDatePicker extends React.Component<IProps> {
  public render() {
    const { store } = this.props
    const { shouldHideCalendar, isShown } = store
    const className = `delivery-workflow-date-picker ma-auto sticky ${
      shouldHideCalendar ? 'time-only' : ''
    }`
    return (
      <div
        className={classList({
          'delivery-workflow-date-picker-container': true,
          shown: isShown,
        })}
      >
        <TwoMonthsDatePicker store={store} className={className}>
          {this.renderDateInputs()}
        </TwoMonthsDatePicker>
      </div>
    )
  }

  private renderDateInputs() {
    const {
      store: { startDate, endDate, shouldHideCalendar },
      clearAndClose,
      projectDateStore: { getDashedFormattedDate, getISOTime },
    } = this.props
    return (
      <div className="row pb20 y-start">
        {!!clearAndClose && (
          <Icons.Cross className="no-grow row" onClick={clearAndClose} />
        )}
        <div className="row">
          {!shouldHideCalendar && (
            <>
              <StruxhubInput
                type="date"
                id="start-date"
                value={startDate ? getDashedFormattedDate(startDate) : ''}
                isReadOnly={true}
                isRequiredTextHidden={true}
                label={Localization.translator.closureStartDate}
                onChange={undefined}
              />
              <HGap size="20" />
            </>
          )}
          <StruxhubInput
            type="time"
            id="start-time"
            isRequiredTextHidden={true}
            value={startDate ? getISOTime(startDate) : ''}
            label={Localization.translator.startTime}
            onChange={this.onStartTimeChange}
          />
        </div>
        <HGap size="70" />
        <div className="row">
          {!shouldHideCalendar && (
            <>
              <StruxhubInput
                type="date"
                id="end-date"
                value={endDate ? getDashedFormattedDate(endDate) : ''}
                isReadOnly={true}
                isRequiredTextHidden={true}
                label={Localization.translator.closureEndDate}
                onChange={undefined}
              />
              <HGap size="20" />
            </>
          )}
          <StruxhubInput
            type="time"
            id="end-time"
            isRequiredTextHidden={true}
            value={endDate ? getISOTime(endDate) : ''}
            label={Localization.translator.endTime}
            onChange={this.onEndTimeChange}
          />
        </div>
      </div>
    )
  }

  @action.bound
  private onStartTimeChange(event) {
    const { store } = this.props
    if (!store.startDate) {
      return
    }

    const {
      getDashedFormattedDate,
      combineISODateTime,
      isValidDate,
      addMinutes,
    } = this.props.projectDateStore
    const newStartDate = combineISODateTime(
      getDashedFormattedDate(store.startDate),
      event.target.value,
    )
    if (isValidDate(newStartDate)) {
      store.startDate = newStartDate
      if (
        !store.shouldHideCalendar &&
        !isBefore(store.startDate, store.endDate)
      ) {
        store.endDate = addMinutes(store.startDate, 1)
      }
    }
  }

  @action.bound
  private onEndTimeChange(event) {
    const { store } = this.props
    if (!store.endDate) {
      return
    }

    const {
      getDashedFormattedDate,
      combineISODateTime,
      isValidDate,
      addMinutes,
    } = this.props.projectDateStore
    const newEndDate = combineISODateTime(
      getDashedFormattedDate(store.endDate),
      event.target.value,
    )

    if (isValidDate(newEndDate)) {
      store.endDate = newEndDate
      if (
        !store.shouldHideCalendar &&
        !isBefore(store.startDate, store.endDate)
      ) {
        store.startDate = addMinutes(store.endDate, -1)
      }
    }
  }
}
