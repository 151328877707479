import * as React from 'react'

import * as Icons from '~/client/src/shared/components/Icons'

import './ChatIconWithBadge.scss'

interface IProps {
  count: number
  className?: string
}

export default function ChatIconWithBadge({ count, className }: IProps) {
  return (
    <div className={`relative chat-badge-container ${className}`}>
      <Icons.Chat />
      {!!count && (
        <span className="row x-center absolute text small badge px4">
          {count}
        </span>
      )}
    </div>
  )
}
