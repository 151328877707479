import { computed, observable } from 'mobx'

import { IActivityLocation, LocationType } from '~/client/graph'

import ActivityCodeLocationRelationship from '../../models/ActivityCodeLocationRelationship'
import EventsStore from '../EventStore/Events.store'
import {
  DELETE_ACTIVITY_CODE_LOCATION_RELATIONSHIP,
  SAVE_ACTIVITY_CODE_LOCATION_RELATIONSHIP,
} from '../EventStore/eventConstants'

export default class ActivityCodeLocationRelationshipsStore {
  @observable public isDataReceived = false

  public constructor(private eventsStore: EventsStore) {}

  @computed
  public get list(): ActivityCodeLocationRelationship[] {
    const { activityCodeLocationRelationships } = this.eventsStore.appState
    return Array.from(activityCodeLocationRelationships.values())
  }

  public get byId() {
    const { activityCodeLocationRelationships } = this.eventsStore.appState
    return activityCodeLocationRelationships
  }

  public clearList() {
    this.isDataReceived = false
    const { activityCodeLocationRelationships } = this.eventsStore.appState
    activityCodeLocationRelationships.clear()
  }

  public receiveList(list: IActivityLocation[]) {
    this.isDataReceived = false
    const { activityCodeLocationRelationships } = this.eventsStore.appState
    activityCodeLocationRelationships.clear()

    list.forEach(dto => {
      const rel = ActivityCodeLocationRelationship.fromDto(dto)
      activityCodeLocationRelationships.set(rel.id, rel)
    })

    this.isDataReceived = true
  }

  public receiveOne(id: string, dto: IActivityLocation) {
    const { activityCodeLocationRelationships } = this.eventsStore.appState
    if (dto) {
      const rel = ActivityCodeLocationRelationship.fromDto(dto)
      activityCodeLocationRelationships.set(rel.id, rel)
    } else {
      activityCodeLocationRelationships.delete(id)
    }
  }

  public createRelationship(
    activityCodeId: string,
    locationObjectId: string,
    locationObjectType: LocationType,
  ) {
    const dto = this.getRelationshipDto(
      activityCodeId,
      locationObjectId,
      locationObjectType,
    )

    this.saveRelationship(dto)
  }
  public getRelationshipDto(
    activityCodeId: string,
    locationObjectId: string,
    locationObjectType: LocationType,
  ) {
    return new ActivityCodeLocationRelationship(
      null,
      activityCodeId,
      locationObjectId,
      locationObjectType,
      this.eventsStore.appState.activeProject.id,
    )
  }

  public saveRelationship(dto: ActivityCodeLocationRelationship) {
    this.eventsStore.dispatch(SAVE_ACTIVITY_CODE_LOCATION_RELATIONSHIP, dto)
  }

  public removeRelationship(rel: ActivityCodeLocationRelationship) {
    this.eventsStore.dispatch(
      DELETE_ACTIVITY_CODE_LOCATION_RELATIONSHIP,
      rel.id,
    )
  }
}
