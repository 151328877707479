import * as React from 'react'

import { classList } from 'react-classlist-helper'

import { IMergeResult } from '~/client/graph'
import Localization from '~/client/src/shared/localization/LocalizationManager'

interface IProps {
  mergeResult: IMergeResult
}

// localization: untranslated

export default function MergingSummary({ mergeResult }: IProps): JSX.Element {
  return (
    <>
      <span className="mt20">
        {Localization.translator.theFollowingEntitiesWereUpdated}:
      </span>

      {renderMergeResultRow(
        mergeResult.deliveriesUpdates,
        Localization.translator.xDeliveries(mergeResult.deliveriesUpdates),
        true,
      )}
      {renderMergeResultRow(
        mergeResult.activitiesUpdates,
        Localization.translator.xActivities(mergeResult.activitiesUpdates),
      )}
      {renderMergeResultRow(
        mergeResult.sitePermitsUpdates,
        Localization.translator.xForms(mergeResult.sitePermitsUpdates),
      )}
      {renderMergeResultRow(
        mergeResult.locationsUpdates,
        Localization.translator.xLocations(mergeResult.locationsUpdates),
      )}

      {/* TODO: Localize these rows */}
      {renderMergeResultRow(
        mergeResult.operationRulesUpdates,
        `${mergeResult.operationRulesUpdates} Operation rules`,
      )}
      {renderMergeResultRow(
        mergeResult.userProjectsUpdates,
        `${mergeResult.userProjectsUpdates} User Project Items`,
      )}
      {renderMergeResultRow(
        mergeResult.whiteListsUpdates,
        `${mergeResult.whiteListsUpdates} Whitelist Items`,
      )}
      {renderMergeResultRow(
        mergeResult.companyRelationshipsUpdates,
        `${mergeResult.companyRelationshipsUpdates} Company Relationships`,
      )}

      {renderMergeResultRow(
        mergeResult.activityAssignmentsUpdates,
        `${mergeResult.activityAssignmentsUpdates} Activity Assignments`,
        true,
      )}
      {renderMergeResultRow(
        mergeResult.deliveryAssignmentsUpdates,
        `${mergeResult.deliveryAssignmentsUpdates} Delivery Assignments`,
      )}
      {renderMergeResultRow(
        mergeResult.sitePermitAssignmentsUpdates,
        `${mergeResult.sitePermitAssignmentsUpdates} Form Assignments`,
      )}
      {renderMergeResultRow(
        mergeResult.announcementAssignmentsUpdates,
        `${mergeResult.announcementAssignmentsUpdates} Announcement Assignments`,
      )}
      {renderMergeResultRow(
        mergeResult.castAssignmentsUpdates,
        `${mergeResult.castAssignmentsUpdates} Cast Assignments`,
      )}
      {renderMergeResultRow(
        mergeResult.closureAssignmentsUpdates,
        `${mergeResult.closureAssignmentsUpdates} Closure Assignments`,
      )}

      {renderMergeResultRow(
        mergeResult.activityListCustomFiltersUpdates,
        `${mergeResult.activityListCustomFiltersUpdates} Activity List Custom Filters`,
        true,
      )}
      {renderMergeResultRow(
        mergeResult.deliveryListCustomFiltersUpdates,
        `${mergeResult.deliveryListCustomFiltersUpdates} Delivery List Custom Filters`,
      )}
      {renderMergeResultRow(
        mergeResult.workflowsCustomFiltersUpdates,
        `${mergeResult.workflowsCustomFiltersUpdates} Workflows Custom Filters`,
      )}
    </>
  )
}

function renderMergeResultRow(
  countOfUpdatedEntities: number,
  text: string = '',
  newParagraph?: boolean,
): JSX.Element {
  return (
    !!countOfUpdatedEntities && (
      <span
        className={classList({
          'pl10 text large capitalize': true,
          mt5: newParagraph,
        })}
      >
        {text}
      </span>
    )
  )
}
