import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import AuthenticationStore from '~/client/src/shared/stores/domain/Authentication.store'

import EventsStore from '../../stores/EventStore/Events.store'
import { Loader } from '../Loader'
import StruxhubInput from '../StruxhubInputs/StruxhubInput'
import SavePasswordStore from './SavePassword.store'

import './SavePassword.scss'

const ENTER_CODE = 13
interface ISavePassword {
  className?: string

  auth?: AuthenticationStore
  eventsStore?: EventsStore
}

@inject('auth', 'eventsStore')
@observer
export default class SavePassword extends React.Component<ISavePassword> {
  private savePasswordStore: SavePasswordStore

  public constructor(props: ISavePassword) {
    super(props)

    this.savePasswordStore = new SavePasswordStore(
      props.auth,
      props.eventsStore,
    )
  }

  public render() {
    if (this.savePasswordStore.shouldShowLoader) {
      return <Loader />
    }

    const {
      password,
      confirmationPassword,
      passwordChangeError,
      handleSetPassword,
    } = this.savePasswordStore

    return (
      <>
        <div className="popup-title mb20">
          {Localization.translator.setYourPassword}
        </div>
        <StruxhubInput
          type="password"
          label={Localization.translator.password}
          isRequiredTextHidden={true}
          isRequired={true}
          value={password || ''}
          onChange={this.onPasswordChange}
        />
        <StruxhubInput
          type="password"
          label={Localization.translator.confirmNewPassword}
          isRequiredTextHidden={true}
          isRequired={true}
          value={confirmationPassword || ''}
          onChange={this.onConfirmationPasswordChange}
          onKeyPress={this.handleKeyPress}
        />
        {this.renderPasswordHint()}
        <div className="buttons row">
          <button className="pointer" onClick={this.clear}>
            {Localization.translator.clear}
          </button>
          <button
            className={classList({
              mr20: true,
              pointer: !passwordChangeError,
              'inactive-element': !!passwordChangeError,
            })}
            disabled={!!passwordChangeError}
            onClick={handleSetPassword}
          >
            {Localization.translator.save}
          </button>
        </div>
      </>
    )
  }

  private renderPasswordHint(): JSX.Element {
    const { password, passwordChangeError } = this.savePasswordStore
    if (!password) {
      return (
        <span className="text light">
          {Localization.translator.passwordErrors.weakPassword}
        </span>
      )
    }

    return <span className="text red">{passwordChangeError}</span>
  }

  private onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.savePasswordStore.password = e.currentTarget.value
    this.savePasswordStore.resetError()
  }

  private onConfirmationPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.savePasswordStore.confirmationPassword = e.currentTarget.value
    this.savePasswordStore.resetError()
  }

  private clear = () => {
    this.savePasswordStore.password = ''
    this.savePasswordStore.confirmationPassword = ''
    this.savePasswordStore.resetError()
  }

  private handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { passwordChangeError, handleSetPassword } = this.savePasswordStore

    if (e.charCode === ENTER_CODE && !passwordChangeError) {
      handleSetPassword()
    }
  }
}
