import * as React from 'react'

import { observer } from 'mobx-react'

import { Content, View } from '~/client/src/shared/components/Layout'
import SignUp from '~/client/src/shared/components/SignUp/SignUp'

@observer
export default class SignUpView extends React.Component {
  public render() {
    return (
      <View className="pa20">
        <Content scrollable={true} className="w500 ma-x-auto py30">
          <SignUp />
        </Content>
      </View>
    )
  }
}
