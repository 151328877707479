import * as React from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import { LocationType } from '~/client/graph'
import LocationPicker from '~/client/src/shared/components/LocationPicker/LocationPicker'
import DeliveryRestrictionsStore, {
  IRestrictionViewModel,
} from '~/client/src/shared/stores/domain/DeliveryRestrictions.store'

const selectPermittedLocations = 'Select permitted locations'

interface IProps {
  store: DeliveryRestrictionsStore
  restriction: IRestrictionViewModel
}

const restrictedObjectTypes = [
  LocationType.Building,
  LocationType.Zone,
  LocationType.LogisticsObject,
  LocationType.VerticalObject,
]

@observer
export default class RestrictionModal extends React.Component<IProps> {
  @observable private allowedIds: string[] = []

  public constructor(props: IProps) {
    super(props)

    this.allowedIds = [
      ...props.restriction.routes.map(({ id }) => id),
      ...props.restriction.gates.map(({ id }) => id),
      ...props.restriction.equipment.map(({ id }) => id),
      ...props.restriction.levels.map(({ id }) => id),
      ...props.restriction.areas.map(({ id }) => id),
      ...props.restriction.stagings.map(({ id }) => id),
      ...props.restriction.interiorDoors.map(({ id }) => id),
      ...props.restriction.interiorPaths.map(({ id }) => id),
    ]
  }

  public render() {
    return (
      <LocationPicker
        className="restriction-modal absolute"
        title={selectPermittedLocations}
        onClose={this.props.store.hideRestrictionModal}
        selectedIds={this.allowedIds}
        restrictedObjectTypes={restrictedObjectTypes}
        onChange={this.onAllowedIdsChanged}
        onApplyChanges={this.updateRestriction}
        onResetSelection={this.resetDefaultAllowedIds}
        isLocationViewFirst={true}
      />
    )
  }

  private updateRestriction = () => {
    const { store, restriction } = this.props
    store.updateRestriction(restriction, this.allowedIds)
  }

  private resetDefaultAllowedIds = () => {
    this.allowedIds = this.props.store.getDefaultAllowedIds()
  }

  private onAllowedIdsChanged = (allowedIds: string[]) => {
    this.allowedIds = allowedIds
  }
}
