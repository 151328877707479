import * as React from 'react'

import { IReactionDisposer, action, computed, observable, reaction } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import Draggable, { DraggableEvent } from 'react-draggable'
import { ScrollSync } from 'react-scroll-sync'
import { AutoSizer, Column, Table } from 'react-virtualized'

import { PresentationScreenKey } from '~/client/graph'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import IWbsRow from '~/client/src/desktop/interfaces/IWbsRow'
import DesktopInitialState, {
  IBandOption,
} from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import ActivityNameCell from '~/client/src/desktop/views/SimpleGanttView/components/ActivityGanttOrListView/components/ActivityNameCell/ActivityNameCell'
import ActivityDaysCell from '~/client/src/desktop/views/SimpleGanttView/components/ActivityGanttOrListView/components/SimpleGantChart/ActivityDaysCell'
import SimpleGanttChartViewStore from '~/client/src/desktop/views/SimpleGanttView/components/ActivityGanttOrListView/components/SimpleGantChart/SimpleGanttChartViewStore'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import Activity from '~/client/src/shared/models/Activity'
import WbsTreeNode from '~/client/src/shared/models/WbsTreeNode'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import {
  ACTIVITY_UPDATED,
  RESET_ALL_FILTERS,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import CustomRowRender from '../../../CustomRowRender/CustomRowRender'
import ActivityGanttOrListViewStore from '../../ActivityGanttOrListView.store'
import TableOffsetsColumnCollapseStore from '../../TableOffsetsColumnCollapse.store'
import WBStextCell from '../ActivityListView/components/WBStext'
import GanttChartHeader from './GanttChartHeader'
import GanttChartScrollableHeader from './GanttChartScrollableHeader'

import './GanttChartTable.scss'

interface IGanttChartTableProps {
  rows: IWbsRow[]
  activityListStore: DesktopActivityListStore
  state?: DesktopInitialState
  eventsStore?: DesktopEventStore
  projectDateStore?: ProjectDateStore
  store: ActivityGanttOrListViewStore
  tableOffsetsColumnCollapseStore: TableOffsetsColumnCollapseStore
  selectedBandsOption?: IBandOption
  statusUpdatesStore?: StatusUpdatesStore
}

const TABLE_SIZE = {
  WIDTH: 1867,
  HEIGHT: 900,
  HEADER_HEIGHT: 60,
  COMMON_ROW_HEIGHT: 48,
  HEADER_ROW_HEIGHT: 30,
}

const NAME_MIN_WIDTH = 1000
const DAYS_MIN_WIDTH = 400
const DAYS_DEFAULT_WIDTH = 855
const CELL_WIDTH = 160

enum Keys {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
}

const NAME_DATA_KEY = 'name'
const DAYS_DATA_KEY = 'days'
const navigationKeys = new Set([Keys.ArrowDown, Keys.ArrowUp])

@inject('state', 'eventsStore', 'projectDateStore', 'statusUpdatesStore')
@observer
export default class GanttChartTable extends React.Component<IGanttChartTableProps> {
  private readonly clearPostEventCallback: () => void
  @observable private nameHeaderWidth: number = NAME_MIN_WIDTH
  @observable private daysHeaderWidth: number = DAYS_DEFAULT_WIDTH
  private tableRef: Table = null
  private readonly simpleGanttChartViewStore: SimpleGanttChartViewStore
  private disposePresentationReaction: IReactionDisposer
  private readonly columns = [
    {
      dataKey: NAME_DATA_KEY,
      className: 'br-light-cool-grey',
      flexShrink: 0,
      flexGrow: 0,
    },
    {
      dataKey: DAYS_DATA_KEY,
      className: 'gant-chart-col',
      flexShrink: 20,
      flexGrow: 1,
    },
  ]

  public constructor(props: IGanttChartTableProps) {
    super(props)

    this.simpleGanttChartViewStore = new SimpleGanttChartViewStore(
      props.store,
      props.projectDateStore,
      props.activityListStore,
      props.statusUpdatesStore,
    )

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.onActivityUpdated,
    )
  }

  public UNSAFE_componentWillReceiveProps(newProps: IGanttChartTableProps) {
    if (this.props.selectedBandsOption !== newProps.selectedBandsOption) {
      this.recomputeGrid()
    }
  }

  public componentDidUpdate() {
    this.recomputeGrid()
  }

  public UNSAFE_componentWillMount() {
    this.props.activityListStore.setDatesIfHasSelectedActivity()
    this.updateScrollPosition()

    const { state, eventsStore } = this.props

    this.props.activityListStore.showActivityDetails()

    if (!state.userActiveProjectSettings?.isPresentationUser) {
      return
    }

    this.disposePresentationReaction = reaction(
      () => state.currentPresentationPage,
      page => {
        if (page && page.type === PresentationScreenKey.Gantt) {
          eventsStore.dispatch(RESET_ALL_FILTERS)
          this.simpleGanttChartViewStore.scrollToBottom()
        } else {
          this.simpleGanttChartViewStore.stopAnimating()
        }
      },
      { fireImmediately: true },
    )
  }

  public componentWillUnmount() {
    this.disposePresentationReaction?.()
    this.simpleGanttChartViewStore.stopAnimating()
    this.clearPostEventCallback()
  }

  public render() {
    const { tableScrollToIndex, handleRowClick } = this.props.store

    const { shouldUseDefaultCollapseStateForProgress } =
      this.props.tableOffsetsColumnCollapseStore

    return (
      <div
        className={classList({
          'gant-chart-content': true,
          'default-progress': shouldUseDefaultCollapseStateForProgress,
          'not-default-progress': !shouldUseDefaultCollapseStateForProgress,
        })}
        tabIndex={-1}
        onKeyDown={this.handleKeyDown}
      >
        <ScrollSync>
          <AutoSizer
            className="full-height full-width"
            defaultHeight={TABLE_SIZE.HEIGHT}
          >
            {({ height }) => (
              <Table
                ref={this.setListRef}
                width={TABLE_SIZE.WIDTH}
                height={height}
                headerHeight={TABLE_SIZE.HEADER_HEIGHT}
                rowHeight={this.getRowHeight}
                rowCount={this.props.rows.length}
                rowGetter={this.rowGetter}
                scrollToIndex={tableScrollToIndex}
                scrollToAlignment="center"
                onRowClick={handleRowClick}
                rowRenderer={this.rowRenderer}
              >
                {this.columns.map((column, index) => {
                  const minWidth = !index ? NAME_MIN_WIDTH : DAYS_MIN_WIDTH
                  return (
                    <Column
                      key={index}
                      width={this.getColumnWidth(index)}
                      columnMinWidth={minWidth}
                      {...column}
                      cellRenderer={this.getCell}
                      headerRenderer={this.headerRenderer}
                    />
                  )
                })}
              </Table>
            )}
          </AutoSizer>
        </ScrollSync>
      </div>
    )
  }

  private getColumnWidth = (columnIndex: number): number => {
    switch (true) {
      case !columnIndex && this.nameHeaderWidth < NAME_MIN_WIDTH:
        return NAME_MIN_WIDTH
      case !columnIndex:
        return this.nameHeaderWidth
      case this.daysHeaderWidth < DAYS_MIN_WIDTH:
        return DAYS_MIN_WIDTH
      default:
        return this.daysHeaderWidth
    }
  }

  @action.bound
  private setListRef(ref: Table) {
    this.tableRef = ref
  }

  private recomputeGrid() {
    this.tableRef.recomputeGridSize()
  }

  private headerRenderer = ({ dataKey }): JSX.Element => {
    const { store, activityListStore, tableOffsetsColumnCollapseStore } =
      this.props
    if (dataKey === NAME_DATA_KEY) {
      return (
        <GanttChartHeader
          store={store}
          activityListStore={activityListStore}
          tableOffsetsColumnCollapseStore={tableOffsetsColumnCollapseStore}
          headerWidth={this.nameHeaderWidth}
        />
      )
    }

    return (
      <div className="row relative">
        <Draggable
          axis="x"
          defaultClassName="DragHandle"
          defaultClassNameDragging="DragHandleActive"
          onStop={this.onStop}
          position={{
            x: 0,
            y: 0,
          }}
        >
          <div className="resize-cursor resize-cursor-holder" />
        </Draggable>
        <GanttChartScrollableHeader
          store={store}
          simpleGanttChartViewStore={this.simpleGanttChartViewStore}
          activityListStore={activityListStore}
        />
      </div>
    )
  }

  @action.bound
  private onStop(_: DraggableEvent, { x }) {
    const cellsMoved = Math.ceil(Math.abs(x) / CELL_WIDTH)
    const swipeValue = cellsMoved * (x < 0 ? -CELL_WIDTH : CELL_WIDTH)
    let newNameHeaderWidth = this.nameHeaderWidth + swipeValue
    let newDaysHeaderWidth = this.daysHeaderWidth - swipeValue

    if (newNameHeaderWidth < NAME_MIN_WIDTH) {
      newDaysHeaderWidth -= NAME_MIN_WIDTH - newNameHeaderWidth
      newNameHeaderWidth = NAME_MIN_WIDTH
    }
    if (newDaysHeaderWidth < DAYS_MIN_WIDTH) {
      newNameHeaderWidth -= DAYS_MIN_WIDTH - newDaysHeaderWidth
      newDaysHeaderWidth = DAYS_MIN_WIDTH
    }

    this.nameHeaderWidth = newNameHeaderWidth
    this.daysHeaderWidth = newDaysHeaderWidth

    // recomputeGridSize doesn't work on column's width change so manual update is needed
    this.forceUpdate()
  }

  private getRowHeight = ({ index }): number => {
    const row = this.props.rows[index]
    return row && row.isWbs
      ? TABLE_SIZE.HEADER_ROW_HEIGHT
      : TABLE_SIZE.COMMON_ROW_HEIGHT
  }

  @action.bound
  private handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    const { viewMode } = this.props.state.activityList
    if (viewMode === ViewModes.List || !navigationKeys.has(e.key as Keys)) {
      return
    }

    e.preventDefault()

    const { selectedActivity, setSelection } = this.props.activityListStore

    let index = 0
    if (!selectedActivity) {
      const defaultActivity: Activity = this.sortedActivities[index]
      setSelection([defaultActivity.code])
      return
    }

    index = this.sortedActivities.findIndex(
      a => a.code === selectedActivity.code,
    )

    switch (e.key) {
      case Keys.ArrowUp:
        if (index > 0) {
          index--
        }
        break
      case Keys.ArrowDown:
        if (index < this.sortedActivities.length - 1) {
          index++
        }
        break
    }

    const newSelectedActivity = this.sortedActivities[index]
    setSelection([newSelectedActivity.code])
  }

  @computed
  private get sortedActivities(): Activity[] {
    const { filteredActivities, wbsTreeNodes } = this.props.store

    let sortedActivities = [] as Activity[]

    const retrieveActivitiesFromTreeNode = (node: WbsTreeNode) => {
      sortedActivities = sortedActivities.concat(
        filteredActivities
          .filter(a => a.wbs === node.wbs.id)
          .sort(
            (a1, a2) => a1.dates.planned.start.ms - a2.dates.planned.start.ms,
          ),
      )

      if (node.children.length > 0) {
        node.children.forEach(child => {
          retrieveActivitiesFromTreeNode(child)
        })
      }
    }

    retrieveActivitiesFromTreeNode(wbsTreeNodes[0])

    return sortedActivities
  }

  private rowGetter = ({ index }: { index: number }): IWbsRow => {
    return this.props.rows[index]
  }

  private toggleWbs = (name: string) => {
    this.props.store.toggleWbsGroup(name)
    this.recomputeGrid()
  }

  private getCell = ({
    dataKey,
    rowData,
  }: {
    dataKey: string
    rowData: IWbsRow
  }): JSX.Element => {
    const {
      isWbs,
      level,
      activity,
      isExpanded,
      name,
      caption,
      company,
      wbsId,
      location,
    } = rowData
    const { store, activityListStore } = this.props
    const {
      toggleActivitySelection,
      isActivitySelected,
      getActivitiesFromGroup,
    } = store

    if (dataKey === NAME_DATA_KEY) {
      if (isWbs) {
        return (
          <WBStextCell
            name={name}
            location={location}
            caption={caption}
            level={level}
            isExpanded={isExpanded}
            collapseWbs={this.toggleWbs}
            toggleEntitySelection={toggleActivitySelection}
            isEntitySelected={isActivitySelected}
            groupActivities={getActivitiesFromGroup(wbsId, level)}
          />
        )
      }

      return (
        <ActivityNameCell
          level={level}
          activity={activity}
          company={company}
          store={store}
          firstCellWidth={this.nameHeaderWidth}
        />
      )
    }

    if (dataKey === DAYS_DATA_KEY) {
      return (
        <ActivityDaysCell
          activity={activity}
          company={company}
          store={store}
          activityListStore={activityListStore}
          simpleGanttChartViewStore={this.simpleGanttChartViewStore}
          isActivityRow={!isWbs}
        />
      )
    }

    return null
  }

  private getRowClassName = (index: number): string => {
    if (index === -1) {
      return 'header-row row no-outline'
    }

    const { isWbs } = this.rowGetter({ index })

    return classList({
      'row no-outline': true,
      'activity-info-cell': !isWbs,
    })
  }

  private rowRenderer = props => {
    const { index, key } = props

    props.rowKey = key
    return (
      <CustomRowRender
        store={this.props.store}
        customClassName={this.getRowClassName(index)}
        {...props}
      />
    )
  }

  private updateScrollPosition = () => {
    this.props.store.updateScrollValue()
  }

  public onActivityUpdated = (eventContext: EventContext) => {
    const [eventType] = eventContext.event

    if (eventType === ACTIVITY_UPDATED) {
      this.recomputeGrid()
    }
  }
}
