import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IDeliveryTicketFieldsFragment = Pick<
  Types.IDeliveryTicketResponse,
  | 'deliveryCode'
  | 'status'
  | 'address'
  | 'area'
  | 'building'
  | 'zone'
  | 'level'
  | 'gate'
  | 'route'
  | 'date'
  | 'driverPhoneNumbers'
  | 'equipments'
  | 'inspectorName'
  | 'inspectorCompany'
  | 'inspectorContacts'
  | 'projectName'
  | 'requesterCompany'
  | 'sitemapUrls'
  | 'globeViewUrls'
  | 'timeRange'
  | 'vehicle'
  | 'customActionText'
  | 'customActionButtonText'
  | 'customActionUrl'
  | 'isCustomActionIncluded'
> & {
  materials?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<
          Types.IDeliveryTicketMaterial,
          'name' | 'category' | 'quantity' | 'unit' | 'location' | 'note'
        >
      >
    >
  >
  onSiteContacts?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.IOnSiteContactResponse, 'name' | 'company' | 'contact'>
      >
    >
  >
  displayNames?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IDisplayName, 'key' | 'name'>>>
  >
}

export type IGetDeliveryTicketQueryVariables = Types.Exact<{
  deliveryId: Types.Scalars['ObjectId']
}>

export type IGetDeliveryTicketQuery = {
  getDeliveryTicket?: Types.Maybe<IDeliveryTicketFieldsFragment>
}

export type ISendSmsDeliveryTicketMutationVariables = Types.Exact<{
  deliveryId: Types.Scalars['String']
}>

export type ISendSmsDeliveryTicketMutation = Pick<
  Types.IMutation,
  'sendSmsDeliveryTicket'
>

export const DeliveryTicketFieldsFragmentDoc = gql`
  fragment DeliveryTicketFields on DeliveryTicketResponse {
    deliveryCode
    status
    address
    area
    building
    zone
    level
    gate
    route
    date
    driverPhoneNumbers
    equipments
    materials {
      name
      category
      quantity
      unit
      location
      note
    }
    onSiteContacts {
      name
      company
      contact
    }
    inspectorName
    inspectorCompany
    inspectorContacts
    projectName
    requesterCompany
    sitemapUrls
    globeViewUrls
    timeRange
    vehicle
    customActionText
    customActionButtonText
    customActionUrl
    isCustomActionIncluded
    displayNames {
      key
      name
    }
  }
`
export const GetDeliveryTicketDocument = gql`
  query GetDeliveryTicket($deliveryId: ObjectId!) {
    getDeliveryTicket(deliveryId: $deliveryId) {
      ...DeliveryTicketFields
    }
  }
  ${DeliveryTicketFieldsFragmentDoc}
`

/**
 * __useGetDeliveryTicketQuery__
 *
 * To run a query within a React component, call `useGetDeliveryTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryTicketQuery({
 *   variables: {
 *      deliveryId: // value for 'deliveryId'
 *   },
 * });
 */
export function useGetDeliveryTicketQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetDeliveryTicketQuery,
    IGetDeliveryTicketQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetDeliveryTicketQuery,
    IGetDeliveryTicketQueryVariables
  >(GetDeliveryTicketDocument, options)
}
export function useGetDeliveryTicketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetDeliveryTicketQuery,
    IGetDeliveryTicketQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetDeliveryTicketQuery,
    IGetDeliveryTicketQueryVariables
  >(GetDeliveryTicketDocument, options)
}
export type GetDeliveryTicketQueryHookResult = ReturnType<
  typeof useGetDeliveryTicketQuery
>
export type GetDeliveryTicketLazyQueryHookResult = ReturnType<
  typeof useGetDeliveryTicketLazyQuery
>
export type GetDeliveryTicketQueryResult = Apollo.QueryResult<
  IGetDeliveryTicketQuery,
  IGetDeliveryTicketQueryVariables
>
export const SendSmsDeliveryTicketDocument = gql`
  mutation SendSmsDeliveryTicket($deliveryId: String!) {
    sendSmsDeliveryTicket(deliveryId: $deliveryId)
  }
`
export type ISendSmsDeliveryTicketMutationFn = Apollo.MutationFunction<
  ISendSmsDeliveryTicketMutation,
  ISendSmsDeliveryTicketMutationVariables
>

/**
 * __useSendSmsDeliveryTicketMutation__
 *
 * To run a mutation, you first call `useSendSmsDeliveryTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsDeliveryTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSmsDeliveryTicketMutation, { data, loading, error }] = useSendSmsDeliveryTicketMutation({
 *   variables: {
 *      deliveryId: // value for 'deliveryId'
 *   },
 * });
 */
export function useSendSmsDeliveryTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISendSmsDeliveryTicketMutation,
    ISendSmsDeliveryTicketMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISendSmsDeliveryTicketMutation,
    ISendSmsDeliveryTicketMutationVariables
  >(SendSmsDeliveryTicketDocument, options)
}
export type SendSmsDeliveryTicketMutationHookResult = ReturnType<
  typeof useSendSmsDeliveryTicketMutation
>
export type SendSmsDeliveryTicketMutationResult =
  Apollo.MutationResult<ISendSmsDeliveryTicketMutation>
export type SendSmsDeliveryTicketMutationOptions = Apollo.BaseMutationOptions<
  ISendSmsDeliveryTicketMutation,
  ISendSmsDeliveryTicketMutationVariables
>
