import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

export default class ObjectPropertiesStore {
  public constructor(private readonly tagsStore: TagsStore) {}

  public get objectsListMap(): { [tagKey: string]: LocationAttributeBase[] } {
    return Object.keys(this.tagsStore.tagListsByTagTypeMap).reduce(
      (acc, tagKey) => {
        acc[tagKey] = this.tagsStore.tagListsByTagTypeMap[tagKey]
        return acc
      },
      {},
    )
  }
}
