export default class Awaiter {
  private list: Array<Promise<any>> = []

  public add(promise: Promise<any>) {
    this.list.push(promise)
    promise.finally(() => {
      const i = this.list.indexOf(promise)
      this.list.splice(i, 1)
    })
  }

  public ready() {
    return Promise.all(this.list)
  }
}
