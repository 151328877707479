import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IGetSignedUploadUrlQueryVariables = Types.Exact<{
  getSignedUploadUrl: Types.IGenerateSignedUploadUrlInput
}>

export type IGetSignedUploadUrlQuery = {
  getSignedUploadUrl?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<
          Types.IFileRequisites,
          'filePath' | 'signedUrl' | 'fileURL' | 'contentType'
        >
      >
    >
  >
}

export const GetSignedUploadUrlDocument = gql`
  query GetSignedUploadUrl($getSignedUploadUrl: GenerateSignedUploadUrlInput!) {
    getSignedUploadUrl(getSignedUploadUrl: $getSignedUploadUrl) {
      filePath
      signedUrl
      fileURL
      contentType
    }
  }
`

/**
 * __useGetSignedUploadUrlQuery__
 *
 * To run a query within a React component, call `useGetSignedUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedUploadUrlQuery({
 *   variables: {
 *      getSignedUploadUrl: // value for 'getSignedUploadUrl'
 *   },
 * });
 */
export function useGetSignedUploadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetSignedUploadUrlQuery,
    IGetSignedUploadUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetSignedUploadUrlQuery,
    IGetSignedUploadUrlQueryVariables
  >(GetSignedUploadUrlDocument, options)
}
export function useGetSignedUploadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetSignedUploadUrlQuery,
    IGetSignedUploadUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetSignedUploadUrlQuery,
    IGetSignedUploadUrlQueryVariables
  >(GetSignedUploadUrlDocument, options)
}
export type GetSignedUploadUrlQueryHookResult = ReturnType<
  typeof useGetSignedUploadUrlQuery
>
export type GetSignedUploadUrlLazyQueryHookResult = ReturnType<
  typeof useGetSignedUploadUrlLazyQuery
>
export type GetSignedUploadUrlQueryResult = Apollo.QueryResult<
  IGetSignedUploadUrlQuery,
  IGetSignedUploadUrlQueryVariables
>
