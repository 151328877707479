import { IDeliveryCardField } from '~/client/graph'

import FieldIds from '../enums/DeliveryFieldIds'

const DEFAULT_DELIVERY_CARD_CONFIG: IDeliveryCardField[] = [
  { fieldId: FieldIds.COMPANY, isHidden: false },
  { fieldId: FieldIds.BOOKING_TIME, isHidden: false },
  { fieldId: FieldIds.STATUS, isHidden: false },
  { fieldId: FieldIds.BUILDING, isHidden: false },
  { fieldId: FieldIds.ZONE, isHidden: false },
  { fieldId: FieldIds.OFFLOADING_EQUIPMENT, isHidden: false },
  { fieldId: FieldIds.GATE, isHidden: false },
  { fieldId: FieldIds.ROUTE, isHidden: false },
  { fieldId: FieldIds.LEVEL, isHidden: false },
  { fieldId: FieldIds.AREA, isHidden: false },
  { fieldId: FieldIds.MATERIAL_CATEGORY, isHidden: false },
  { fieldId: FieldIds.MATERIAL_NOTE, isHidden: true },
  { fieldId: FieldIds.VENDOR, isHidden: false },
  { fieldId: FieldIds.TRUCK_SIZE, isHidden: false },
  { fieldId: FieldIds.DRIVER_PHONE_NUMBERS, isHidden: true },
  { fieldId: FieldIds.ON_SITE_CONTACTS, isHidden: false },
]

export default DEFAULT_DELIVERY_CARD_CONFIG
