import './LandscapeImage.scss'

import * as React from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

interface IProps {
  src?: string
}

@observer
class LandscapeImage extends React.Component<IProps> {
  @observable private computed = false
  @observable private height = window.innerHeight
  @observable private width = window.innerWidth

  public constructor(props) {
    super(props)
  }

  public handleLoad = e => {
    const offsetHeight = e.target.offsetHeight
    const offsetWidth = e.target.offsetWidth
    if (offsetHeight / offsetWidth > window.innerHeight / window.innerWidth) {
      this.computed = true
      this.height = window.innerHeight
      this.width = Math.round((window.innerHeight * offsetWidth) / offsetHeight)
    } else {
      this.computed = true
      this.height = Math.round((window.innerWidth * offsetHeight) / offsetWidth)
      this.width = window.innerWidth
    }
  }

  public render() {
    const { src } = this.props

    return (
      <div className="landscape-container">
        <img
          style={{
            height: this.computed ? this.height + 'px' : '',
            visibility: this.computed ? 'visible' : 'hidden',
            width: this.computed ? this.width + 'px' : '',
          }}
          className="align-landscape"
          onLoad={this.handleLoad}
          src={src}
        />
      </div>
    )
  }
}

export default LandscapeImage
