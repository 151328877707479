import { action, computed } from 'mobx'

import {
  CategoryName,
  FilterType,
  IRecentlyUpdatedSavedFilter,
} from '~/client/graph'
import { ActivitiesTreeNodeTypes } from '~/client/src/shared/enums/ActivitiesTreeNodeTypes'
import Activity from '~/client/src/shared/models/Activity'
import ActivityPreset from '~/client/src/shared/models/ActivityPreset'
import InitialState, {
  ISharedFilters,
} from '~/client/src/shared/stores/InitialState'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityAssignmentsStore from '~/client/src/shared/stores/domain/ActivityAssignments.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import ActivityFollowingsStore from '~/client/src/shared/stores/domain/ActivityFollowings.store'
import ActivityPresetsStore from '~/client/src/shared/stores/domain/ActivityPresets.store'
import CustomActivityListFilter from '~/client/src/shared/types/CustomActivityListFilter'
import Guard from '~/client/src/shared/utils/Guard'

import ActivityDetailsStore from '../../components/ActivityDetails/ActivityDetails.store'
import { AND_CATEGORIES } from '../../enums/CategoryNames'
import CategoriesOfVarianceStore from '../domain/CategoriesOfVariance.store'
import DeliveriesStore from '../domain/Deliveries.store'
import FlagsStore from '../domain/Flags.store'
import RfisStore from '../domain/Rfis.store'
import SafetyHazardsStore from '../domain/SafetyHazards.store'
import ScheduleCommentsStore from '../domain/ScheduleComments.store'
import StatusUpdatesStore from '../domain/StatusUpdates.store'
import ProjectDateStore from './ProjectDate.store'

const { ZONE_CONTAINER, COMPANY_CONTAINER, LEVEL_CONTAINER } =
  ActivitiesTreeNodeTypes

export const DEFAULT_LOADED_LIMIT = 5
export const SHOW_MORE_NODE_PREFIX = '$-show-more'
export const SUB_NODE_PREFIX = '$-sub-node'

export const NODE_TYPE_MAP = {
  [FilterType.Level]: LEVEL_CONTAINER,
  [FilterType.Zone]: ZONE_CONTAINER,
  [FilterType.Company]: COMPANY_CONTAINER,
}

export interface ICodesFilter {
  [key: string]: string[]
}

export interface ITreeContainer {
  name: string
  type: string
  codeIds?: string[]
  companyId?: string
}

export default abstract class BaseActivityListStore {
  protected today = new Date()

  public constructor(
    protected readonly state: InitialState,
    protected readonly activitiesStore: ActivitiesStore,
    protected readonly activityPresetsStore: ActivityPresetsStore,
    protected readonly activityFiltersStore: ActivityFiltersStore,
    protected readonly activityAssignmentsStore: ActivityAssignmentsStore,
    protected readonly activityFollowingsStore: ActivityFollowingsStore,
    protected readonly activityDetailsStore: ActivityDetailsStore,
    protected readonly projectDateStore: ProjectDateStore,
    protected readonly statusUpdatesStore: StatusUpdatesStore,
    private readonly rfisStore: RfisStore,
    private readonly flagsStore: FlagsStore,
    private readonly scheduleCommentsStore: ScheduleCommentsStore,
    private readonly categoriesOfVarianceStore: CategoriesOfVarianceStore,
    private readonly safetyHazardsStore: SafetyHazardsStore,
    private readonly deliveriesStore: DeliveriesStore,
  ) {
    Guard.requireAll({
      state,
      activitiesStore,
      activityPresetsStore,
      activityFiltersStore,
    })
  }

  public getCodesByFilterType = (filterType: string) => {
    return this.activityFiltersStore.getCodesByFilterType(filterType)
  }

  public get filteredActivities(): Activity[] {
    return []
  }

  @computed
  public get appliedPreset(): ActivityPreset {
    return this.activityPresetsStore.getById(this.state.appliedActivityPresetId)
  }

  @computed
  public get appliedPresetActivities(): Activity[] {
    if (!this.appliedPreset) {
      return []
    }

    const presetActivityCodes = Object.values(
      this.appliedPreset.activities || {},
    )

    return this.activitiesStore.list.filter(a =>
      presetActivityCodes.includes(a.code),
    )
  }

  @computed
  public get userAssociatedActivitiesIds(): string[] {
    return Array.from(
      new Set([
        ...this.userAssignedActivitiesIds,
        ...this.userFollowedActivitiesIds,
      ]),
    )
  }

  @computed
  public get userAssignedActivitiesIds(): string[] {
    const { user } = this.state

    if (!user) {
      return []
    }

    return this.activityAssignmentsStore.getAssignedEntitiesByUserId(user.id)
  }

  @computed
  public get userFollowedActivitiesIds(): string[] {
    return this.activityFollowingsStore.list.map(({ entityId }) => entityId)
  }

  public buildCategoryFilteringMatrix(
    categoryFilters: CategoryName[],
  ): CategoryName[][] {
    return AND_CATEGORIES.map(orCategories => {
      return [...categoryFilters.filter(c => orCategories.includes(c))]
    }).filter(categories => categories.length)
  }

  public filterActivitiesWithCategoryFilters = (
    activities: Activity[],
    categoriesMatrix: any,
    filter?: CustomActivityListFilter,
  ): Activity[] => {
    if (!categoriesMatrix.length) {
      return activities
    }

    let categoryActivities: Activity[] = []

    categoriesMatrix.forEach((orFilters, index) => {
      const orFiltersActivities = []
      orFilters.forEach(category => {
        const activitiesByCategoryFilter = this.getActivitiesFilteredByCategory(
          category,
          activities,
          filter,
        )
        orFiltersActivities.push(...activitiesByCategoryFilter)
      })

      if (index === 0) {
        categoryActivities = orFiltersActivities
      } else {
        categoryActivities = this.getActivityArraysIntersection(
          categoryActivities,
          orFiltersActivities,
        )
      }
    })

    return Array.from(new Set(categoryActivities))
  }

  protected abstract getRecentlyUpdatedActivities(
    activities: Activity[],
    filter?: CustomActivityListFilter,
  ): Activity[]

  @action.bound
  protected getActivitiesFilteredByCategory(
    category: CategoryName,
    activities: Activity[],
    filter?: CustomActivityListFilter,
  ): Activity[] {
    switch (category) {
      case CategoryName.UserAssociated:
        return this.getAssociatedActivities(activities)

      case CategoryName.Critical:
        return this.getActivitiesWithCriticalPath(activities)

      case CategoryName.Deliveries:
        return this.getActivitiesWithDeliveries(activities)

      case CategoryName.Flagged:
        return this.getActivitiesWithFlag(activities)

      case CategoryName.Late:
        return this.getLateActivities(activities)

      case CategoryName.Rfi:
        return this.getActivitiesWithRFI(activities)

      case CategoryName.ScheduleComment:
        return this.getActivitiesWithScheduleComment(activities)

      case CategoryName.CategoryOfVariance:
        return this.getActivitiesWithCategoryOfVariance(activities)

      case CategoryName.SafetyHazard:
        return this.getActivitiesWithSafetyHazard(activities)

      case CategoryName.RecentlyUpdated:
        return this.getRecentlyUpdatedActivities(activities, filter)

      case CategoryName.NotCompleted:
        return this.getNotCompletedActivities(activities)

      case CategoryName.ActualizedFromSchedule:
        return this.getActualizedFromSchedule(activities)

      case CategoryName.Status:
        return this.getActivitiesWithSpecificStatus(activities, filter)
    }
  }

  protected getActivityArraysIntersection(
    firstArray: Activity[],
    secondArray: Activity[],
  ): Activity[] {
    return firstArray.filter(activity => secondArray.includes(activity))
  }

  protected abstract getActivitiesWithSpecificStatus(
    activities: Activity[],
    filter?: CustomActivityListFilter,
  ): Activity[]

  protected getRecentlyUpdatedFilter(
    filters: ISharedFilters,
    filter: CustomActivityListFilter,
  ): IRecentlyUpdatedSavedFilter {
    if (filter?.recentlyUpdatedSavedFilter) {
      return filter.recentlyUpdatedSavedFilter
    }

    return {
      mode: filters.selectedRecentlyUpdatedMode,
      startDate: filters.recentlyUpdatedStartDate,
      endDate: filters.recentlyUpdatedEndDate,
    }
  }

  private getAssociatedActivities(activities: Activity[]): Activity[] {
    return activities.filter(a =>
      this.userAssociatedActivitiesIds.includes(a.code),
    )
  }

  private getActivitiesWithCriticalPath(activities: Activity[]): Activity[] {
    return activities.filter(activity => activity.isOnCriticalPath)
  }

  private getActivitiesWithFlag(activities: Activity[]): Activity[] {
    return activities.filter(activity =>
      this.flagsStore.doesActivityHaveContentObject(activity),
    )
  }

  private getActivitiesWithRFI(activities: Activity[]): Activity[] {
    return activities.filter(activity =>
      this.rfisStore.doesActivityHaveContentObject(activity),
    )
  }

  private getActivitiesWithScheduleComment(activities: Activity[]): Activity[] {
    return activities.filter(activity =>
      this.scheduleCommentsStore.doesActivityHaveContentObject(activity),
    )
  }

  private getActivitiesWithCategoryOfVariance(
    activities: Activity[],
  ): Activity[] {
    return activities.filter(activity =>
      this.categoriesOfVarianceStore.doesActivityHaveContentObject(activity),
    )
  }

  private getActivitiesWithSafetyHazard(activities: Activity[]): Activity[] {
    return activities.filter(activity =>
      this.safetyHazardsStore.doesActivityHaveContentObject(activity),
    )
  }

  private getActivitiesWithDeliveries(activities: Activity[]): Activity[] {
    const { availableDeliveries } = this.deliveriesStore
    const allActivitiesIdsWithDeliveries = availableDeliveries.map(
      delivery => delivery.activityId,
    )
    return activities.filter(activity =>
      allActivitiesIdsWithDeliveries.includes(activity.code),
    )
  }

  private getLateActivities(activities: Activity[]): Activity[] {
    return activities.filter(activity => {
      const { days } = activity.dates.planned.duration
      let date = activity.plannedStartDate
      if (!activity.didStartOrDidPlanToStart(this.projectDateStore)) {
        return false
      }
      const earnedDuration = Math.round(
        days * (activity.commonStatusUpdate.percentComplete / 100),
      )
      date = this.projectDateStore.addDays(date, earnedDuration - 1)
      return this.projectDateStore.countDaysToDate(date, this.today) < 0
    })
  }

  private getNotCompletedActivities(activities: Activity[]): Activity[] {
    return activities.filter(activity => !activity.didFinish)
  }

  private getActualizedFromSchedule(activities: Activity[]): Activity[] {
    return activities.filter(
      activity => activity.p6ActualDates.end || activity.p6ActualDates.start,
    )
  }
}
