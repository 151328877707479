import React, { Component } from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'
import * as Layout from '~/client/src/shared/components/Layout'
import StruxhubSelect from '~/client/src/shared/components/StruxhubInputs/StruxhubSelect'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import { optionsFrequency, optionsPeriod } from './Constants/Constants'
import { Frequency, Period, periodsList } from './Constants/Enums'
import DashboardIntegrationStore from './Service/DashboardIntegration.store'
import ChartGroup from './components/ChartGroup'

interface IProps {
  graphExecutorStore?: GraphExecutorStore
  companiesStore?: CompaniesStore
  projectDateStore?: ProjectDateStore
}
interface PeriodGetFormatData {
  value: number
  unit: string
}

const periods: Record<string, PeriodGetFormatData> = {
  [Period.Week]: { value: 7, unit: Localization.translator.days },
  [Period.Month]: { value: 30, unit: Localization.translator.days },
  [Period.Month4]: { value: 120, unit: Localization.translator.days },
  [Period.Year]: { value: 365, unit: Localization.translator.days },
}

const getDefaultPeriod: () => PeriodGetFormatData = () => ({
  value: 365,
  unit: Localization.translator.days,
})

@inject('graphExecutorStore', 'companiesStore', 'projectDateStore')
@observer
export default class Dashboard extends Component<IProps> {
  private dashboardIntegrationStore: DashboardIntegrationStore = null

  @observable
  public selectedFrequency: Frequency = Frequency.WEEKLY

  @observable
  public selectedPeriod: Period = Period.Year

  @observable
  public selectedCompanyId: string = ''

  public constructor(props: IProps) {
    super(props)

    this.dashboardIntegrationStore = new DashboardIntegrationStore(
      this.props.graphExecutorStore,
    )
  }

  @action.bound
  private handleOptionChangeSelectedFrequency(
    event: React.ChangeEvent<HTMLSelectElement>,
  ) {
    this.selectedFrequency = event.target.value as Frequency
  }

  @action.bound
  private handleOptionChangeSelectedPeriod(
    event: React.ChangeEvent<HTMLSelectElement>,
  ) {
    const value = Number(event.target.value)
    if (periodsList.includes(value)) {
      this.selectedPeriod = value as Period
    } else {
      this.selectedPeriod = Period.Year
    }
  }

  @action.bound
  private handleOptionChangeCompanies(
    event: React.ChangeEvent<HTMLSelectElement>,
  ) {
    this.selectedCompanyId = event.target.value as string
  }

  private getDateRange(selectedPeriod: Period): string {
    if (selectedPeriod === Infinity) {
      return Localization.translator.entireProject
    }

    const { projectDateStore } = this.props

    const now = new Date()
    const { value } = periods[selectedPeriod] || getDefaultPeriod()

    const startDate = projectDateStore.addDays(now, -value)

    const startDateString = projectDateStore.getDashedFormattedDate(startDate)
    const nowDateString = projectDateStore.getDashedFormattedDate(now)

    return `${startDateString} - ${nowDateString}`
  }

  public render() {
    const dashboardData = this.dashboardIntegrationStore?.configuration
    const formattedDateRange = this.getDateRange(this.selectedPeriod)
    const optionsCompany = this.props.companiesStore.getCompaniesAsOptions()

    return (
      <Layout.View className="desktop-deliveries overflow-hidden y-baseline">
        <div className="col no-flex mb4 h100">
          <h4 className="ml10 mb4">
            {Localization.translator.frequencyAndPeriod}
          </h4>
          <div className="row w-fit-content">
            <StruxhubSelect
              isMinimalisticMode={true}
              value={this.selectedFrequency}
              onChange={this.handleOptionChangeSelectedFrequency}
              className="mr10 ml10"
            >
              {optionsFrequency.map(option => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </StruxhubSelect>
            <StruxhubSelect
              isMinimalisticMode={true}
              value={this.selectedPeriod.toString()}
              onChange={this.handleOptionChangeSelectedPeriod}
              className="mr10"
            >
              {optionsPeriod.map(option => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </StruxhubSelect>
            <StruxhubSelect
              isMinimalisticMode={true}
              value={this.selectedCompanyId}
              onChange={this.handleOptionChangeCompanies}
              className="mr10"
            >
              <>
                <option key={0} value="">
                  {Localization.translator.selectCompany}
                </option>
                {optionsCompany.map(option => (
                  <option key={option.value} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </>
            </StruxhubSelect>
            <div className="ml10 w500 h40 row x-center pa10 brada3 bg-transparent">
              <Icons.Calendar className="no-grow" />
              <span className="text large bold primary ml4">
                {formattedDateRange}
              </span>
            </div>
          </div>
        </div>

        <Layout.Content>
          {dashboardData && (
            <ChartGroup
              dashboardData={dashboardData}
              selectedCompany={this.selectedCompanyId}
              selectedFrequency={this.selectedFrequency}
              selectedPeriod={this.selectedPeriod}
            />
          )}
        </Layout.Content>
      </Layout.View>
    )
  }
}
