import { action, computed, observable } from 'mobx'

import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'

export default class HierarchyNode {
  @observable public isItemToggleHovering: boolean = false
  public nodeId: string = null
  public isSite: boolean = false
  public children: HierarchyNode[] = []
  public nodeLevel: number = 0
  public name: string

  public constructor(
    private expandState: Map<string, boolean>,
    public level: number,
    public item?: MapViewItemBase,
    public parent: HierarchyNode = null,
    public nodeName?: string,
  ) {
    this.isSite = !item
    this.name = item?.name
    this.nodeId = nodeName || item.id
    this.nodeLevel = level
    if (parent) {
      this.nodeId = parent.nodeId + this.nodeId
    }
  }

  public get lastNodeOfHighlightedGroupId(): string {
    if (!this.isGroupToggleHovering) {
      return ''
    }

    return this.highlightedGroupNodes[this.highlightedGroupNodes.length - 1]
      .nodeId
  }

  public get isGroupToggleHovering(): boolean {
    return this.isItemToggleHovering || this.parent?.isItemToggleHovering
  }

  public get isCollapsed(): boolean {
    return this.expandState.get(this.nodeId)
  }

  public get isHidden(): boolean {
    return this.parent?.isHidden || this.expandState.get(this.parent?.nodeId)
  }

  public changeCollapsedState = (): void => {
    const currentState = this.expandState.get(this.nodeId)
    if (!currentState) {
      this.expandState.set(this.nodeId, true)
    } else {
      this.expandState.delete(this.nodeId)
    }
  }

  public get hasChildren(): boolean {
    return !!this.children.length
  }

  public get childrenCount(): number {
    return this.children.length
  }

  @computed
  public get highlightedGroupNodes(): HierarchyNode[] {
    if (this.parent?.isGroupToggleHovering) {
      return this.parent.highlightedGroupNodes
    }

    return [this as HierarchyNode].concat(
      ...this.children.map(n => this.getGroupNodes(n)),
    )
  }

  @computed
  public get groupNodes(): HierarchyNode[] {
    return [this as HierarchyNode].concat(
      ...this.children.map(n => this.getGroupNodes(n)),
    )
  }

  private getGroupNodes = (node: HierarchyNode): HierarchyNode[] => {
    const groupNodes = [node]

    if (!node.hasChildren) {
      return groupNodes
    }

    return groupNodes.concat(...node.children.map(n => this.getGroupNodes(n)))
  }

  @action.bound
  public setGroupToggleHovering(): void {
    this.isItemToggleHovering = true
  }

  @action.bound
  public resetGroupToggleHovering(): void {
    this.isItemToggleHovering = false
  }
}
