import { InviteStatus } from '~/client/graph'

import Localization from '../localization/LocalizationManager'

enum InviteCaption {
  Added = 'Added',
  NotAdded = 'Not_Added',
  NotRequested = 'Not_Requested',
  Requested = 'Requested',
}

export type InviteStatusCaption = InviteStatus | InviteCaption

export const InviteStatusCaption = { ...InviteStatus, ...InviteCaption }

export function getInviteStatusTranslate(value: InviteStatusCaption) {
  switch (value) {
    case InviteStatusCaption.Accepted:
      return Localization.translator.inviteStatusValues.Accepted
    case InviteStatusCaption.Added:
      return Localization.translator.inviteStatusValues.Added
    case InviteStatusCaption.NotAdded:
      return Localization.translator.inviteStatusValues.Not_Added
    case InviteStatusCaption.NotInvited:
      return Localization.translator.inviteStatusValues.Not_Invited
    case InviteStatusCaption.Pending:
      return Localization.translator.inviteStatusValues.Pending
    case InviteStatusCaption.Requested:
      return Localization.translator.inviteStatusValues.Requested
    case InviteStatusCaption.NotRequested:
      return Localization.translator.inviteStatusValues.Not_Requested
    default:
      throw new Error(value + ' is unhandled')
  }
}
