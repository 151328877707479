import { enumToList } from '~/client/src/shared/utils/converters'

import Localization from '../../shared/localization/LocalizationManager'

enum DeliveryViewPeriodMode {
  TodayPeriod = 'Today',
  DayPeriod = 'Day',
  WeekPeriod = 'Week',
  MonthPeriod = 'Month',
  FullPeriod = 'Full',
}

export const getDeliveryViewPeriodModeDisplayName = (
  mode: DeliveryViewPeriodMode,
) => {
  switch (mode) {
    case DeliveryViewPeriodMode.TodayPeriod:
      return Localization.translator.today
    case DeliveryViewPeriodMode.DayPeriod:
      return Localization.translator.day
    case DeliveryViewPeriodMode.WeekPeriod:
      return Localization.translator.week
    case DeliveryViewPeriodMode.MonthPeriod:
      return Localization.translator.month
    case DeliveryViewPeriodMode.FullPeriod:
      return Localization.translator.full_period
  }
}

export const deliveryViewPeriodModes = enumToList(DeliveryViewPeriodMode)

export default DeliveryViewPeriodMode
