import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Company from '~/client/src/shared/models/Company'
import Delivery from '~/client/src/shared/models/Delivery'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import { DataKeys, IFormCellData } from '../MaterialsList.store'
import BaseDataCell from './BaseDataCell/BaseDataCell'
import DeliveryDataCellTable from './DeliveryDataCellTable'

// localization: translated

interface IProps {
  dataKey: string
  deliveryCellData: IFormCellData
  onNewDeliveryClick(viewModelId: string): void
  onIdClick(deliveryId: string): void
  locationRenderer(location: LocationBase): JSX.Element
  companyRenderer(company: Company): JSX.Element

  isNewBtnAvailable?: boolean

  companiesStore?: CompaniesStore
  projectDateStore?: ProjectDateStore
}

const deliveryCellColumnsIds = [
  DataKeys.NEXT_DELIVERY_BOOKING_DATE,
  DataKeys.PLANNED_QUANTITY,
  DataKeys.STATUS,
  DataKeys.CURRENT_MATERIAL_LOCATION,
]
const deliveryAndDateIds: string[] = [
  DataKeys.NEXT_DELIVERY,
  DataKeys.NEXT_DELIVERY_BOOKING_DATE,
]

const isDeliveryDone = (delivery: Delivery): boolean =>
  delivery.isOnSiteOrInspected
const isDeliveryInRange = (
  delivery: Delivery,
  startDate: Date,
  endDate: Date,
): boolean => delivery.isScheduledWithinRange(startDate, endDate)

@inject('companiesStore', 'projectDateStore')
@observer
export default class DeliveryDataCell extends React.Component<IProps> {
  public static defaultProps = {
    deliveryCellData: {},
  }

  public render() {
    const { relatedDeliveries, itemsCount } = this.props.deliveryCellData

    return (
      <BaseDataCell<Delivery>
        items={relatedDeliveries}
        newItemIcon={
          <Icons.Delivery className="form-modal-add-icon relative no-flex mr5" />
        }
        newItemText={Localization.translator.newDelivery}
        onNewItemClick={this.onNewDeliveryClick}
        renderActiveItems={this.renderActiveDeliveriesTable}
        renderDoneItems={this.renderDoneDeliveriesTable}
        doneFilter={isDeliveryDone}
        dateRangeFilter={isDeliveryInRange}
        isNewValueShown={this.props.isNewBtnAvailable && this.isDeliveryCell}
        isTotalDoneValueShown={this.isDeliveryCell}
        isDateRangeShown={this.isDeliveryOrDateCell}
        doneTableText={Localization.translator.doneDeliveries}
        getTotalDoneValue={this.getTotalDeliveredQuantity}
      >
        <div
          className={classList({
            'row pointer h48 no-select': true,
            'underline-hover': !!itemsCount,
          })}
        >
          <div className="text-ellipsis">{this.cellLabel}</div>
          <Icons.DownArrow
            className="bp3-icon ml5 icon-w11 icon-grey"
            element="span"
          />
        </div>
      </BaseDataCell>
    )
  }

  private get cellLabel(): string | JSX.Element {
    const { itemsCount } = this.props.deliveryCellData

    if (!itemsCount) {
      return NO_VALUE
    }
    return itemsCount > 1 ? this.xItemsLabel : this.oneItemLabel
  }

  private get xItemsLabel(): string {
    const {
      deliveryCellData: { itemsCount },
      dataKey,
    } = this.props
    switch (dataKey) {
      case DataKeys.NEXT_DELIVERY:
        return Localization.translator.xDeliveries(itemsCount)
      case DataKeys.NEXT_DELIVERY_BOOKING_DATE:
        return Localization.translator.xDates(itemsCount)
      case DataKeys.VENDOR:
        return Localization.translator.xVendors(itemsCount)
      case DataKeys.RESPONSIBLE_COMPANY:
        return Localization.translator.xCompanies(itemsCount)
      default:
        return null
    }
  }

  private get oneItemLabel(): JSX.Element | string {
    const {
      companiesStore,
      projectDateStore: { getMonthDayYearAndTimeToDisplay },
      dataKey,
      companyRenderer,
    } = this.props

    const delivery = this.allActiveDeliveries[0]
    switch (dataKey) {
      case DataKeys.NEXT_DELIVERY:
        return delivery.codeToDisplay(companiesStore)
      case DataKeys.NEXT_DELIVERY_BOOKING_DATE:
        return getMonthDayYearAndTimeToDisplay(delivery.startDate)
      case DataKeys.VENDOR:
        return companyRenderer(companiesStore.getCompanyById(delivery.vendor))
      case DataKeys.RESPONSIBLE_COMPANY:
        return companyRenderer(companiesStore.getCompanyById(delivery.company))
      default:
        return null
    }
  }

  private renderActiveDeliveriesTable = (
    activeDeliveries: Delivery[],
  ): JSX.Element => {
    const { deliveryCellData, locationRenderer, companyRenderer, onIdClick } =
      this.props
    return (
      <DeliveryDataCellTable
        isFooterHidden={!this.isDeliveryCell}
        shownColumnIds={this.shownColumnIds}
        deliveries={activeDeliveries}
        procurementId={deliveryCellData.procurementId}
        materialId={deliveryCellData.materialId}
        footerTitle={Localization.translator.totalBooked}
        locationRenderer={locationRenderer}
        companyRenderer={companyRenderer}
        onIdClick={onIdClick}
      />
    )
  }

  private renderDoneDeliveriesTable = (
    doneDeliveries: Delivery[],
  ): JSX.Element => {
    const { deliveryCellData, locationRenderer, companyRenderer, onIdClick } =
      this.props

    return (
      <DeliveryDataCellTable
        isHeaderHidden={true}
        isFooterHidden={!this.isDeliveryCell}
        shownColumnIds={this.shownColumnIds}
        deliveries={doneDeliveries}
        procurementId={deliveryCellData.procurementId}
        materialId={deliveryCellData.materialId}
        footerTitle={Localization.translator.totalDelivered}
        locationRenderer={locationRenderer}
        companyRenderer={companyRenderer}
        onIdClick={onIdClick}
      />
    )
  }

  private get allActiveDeliveries(): Delivery[] {
    const { relatedDeliveries } = this.props.deliveryCellData
    return this.isDeliveryOrDateCell
      ? relatedDeliveries.filter(d => !d.isOnSiteOrInspected)
      : relatedDeliveries
  }

  private getTotalDeliveredQuantity = (
    doneDeliveries: Delivery[],
  ): React.ReactText => {
    const { procurementId, materialId } = this.props.deliveryCellData
    return doneDeliveries.reduce(
      (sum, del) =>
        (sum +=
          (procurementId
            ? del.procurementQuantitiesMap[procurementId]
            : del.materialQuantitiesMap[materialId]) || 0),
      0,
    )
  }

  private get shownColumnIds(): string[] {
    return this.isDeliveryCell ? deliveryCellColumnsIds : [this.props.dataKey]
  }

  private get isDeliveryCell(): boolean {
    return this.props.dataKey === DataKeys.NEXT_DELIVERY
  }

  private get isDeliveryOrDateCell(): boolean {
    return deliveryAndDateIds.includes(this.props.dataKey)
  }

  private onNewDeliveryClick = () => {
    const { onNewDeliveryClick, deliveryCellData } = this.props
    onNewDeliveryClick(deliveryCellData.id)
  }
}
