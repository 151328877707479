import * as React from 'react'

import { Circle, Group, Path } from 'react-konva'

import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import { AREA_PATH } from '../LocationPaths'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_HEIGHT = 20
const ICON_WIDTH = 20

const ICON_OFFSET_X = 4
const ICON_OFFSET_Y = 3
const SHADOW_BLUR = 2
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3
const ICON_SCALE = 0.8

const STROKE_WIDTH = 1
const { getHEXColor } = ThemeMode

const KonvaAreaIcon = ({ color, ...rest }) => {
  const halfSize = ICON_HEIGHT / 2
  return (
    <Group {...rest} offsetX={ICON_WIDTH / 2} offsetY={ICON_HEIGHT / 2}>
      <Circle
        radius={halfSize}
        x={halfSize}
        y={halfSize}
        fill={getHEXColor(color)}
        stroke={getHEXColor(Colors.neutral100)}
        strokeWidth={STROKE_WIDTH}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
      <Path
        data={AREA_PATH}
        fill={getHEXColor(Colors.neutral100)}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
        x={ICON_OFFSET_X}
        y={ICON_OFFSET_Y}
        scaleX={ICON_SCALE}
        scaleY={ICON_SCALE}
      />
    </Group>
  )
}

export default KonvaAreaIcon
