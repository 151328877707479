import { action, computed } from 'mobx'

import { LogisticsFilterType } from '~/client/src/shared/enums/LogisticsFilterType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import ILogisticItem from '~/client/src/shared/models/ILogisticItem'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { RESET_ALL_FILTERS } from '~/client/src/shared/stores/EventStore/eventConstants'
import InitialState from '~/client/src/shared/stores/InitialState'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'
import SuperFilterStore, {
  IRootOption,
} from '~/client/src/shared/stores/ui/SuperFilter.store'
import Guard from '~/client/src/shared/utils/Guard'

import BaseLogisticsList from './BaseLogisticsLists.store'
import BaseLogisticsMapStore from './ui/BaseLogisticsMap.store'

export type ISourceMap = { [optionId: string]: string[] }

export default class BaseLogisticsFilterStore extends SuperFilterStore {
  public get totalHint(): string {
    return Localization.translator.totalLogistics
  }

  public constructor(
    public readonly type: LogisticsFilterType,
    protected readonly state: InitialState,
    protected readonly sourceMap: ISourceMap,
    protected readonly logisticsInstanceStore:
      | BaseLogisticsList
      | BaseLogisticsMapStore,
    protected readonly onShowChanged: (isShown: boolean, type: string) => void,
    protected readonly fieldsMap: { [filterType: string]: UIFilterInfo },
    getTypeCaption: (type: string) => string,
    private readonly getOptionName?: (
      optionId: string,
      filterType: string,
    ) => string,
    private readonly isMobile: boolean = false,
    protected readonly onClickHandler?: () => void,
  ) {
    super(
      fieldsMap[type],
      type,
      state,
      KnownTranslatorKeys.seeXLogistics,
      getTypeCaption,
      onShowChanged,
      false,
      onClickHandler,
    )

    Guard.requireAll({ type, state, sourceMap, logisticsInstanceStore })
  }

  @action.bound
  public onFilterActionRequest(eventContext: EventContext) {
    const [eventType] = eventContext.event

    switch (eventType) {
      case RESET_ALL_FILTERS:
        return this.setInitialFilterValues()
    }
  }

  @computed
  public get isActive(): boolean {
    return (
      !this.isLoading &&
      (!this.isMobile || this.selectedOptions.size) &&
      this.allOptionIds.some(optionId => !this.selectedOptions.get(optionId))
    )
  }

  @action
  public setInitialFilterValues() {
    if (!this.filter) {
      return
    }

    this.isMobile ? this.clickOnDeselectAll() : this.clickOnSelectAll()
    this.clickOnApply()
  }

  @computed
  public get optionsTree(): IRootOption[] {
    const options = Object.keys(this.sourceMap)

    return [
      {
        id: this.type,
        name: this.typeCaption,
        options: options.map(option => ({
          id: option,
          name: this.getOptionName?.(option, this.type) || option,
          instancesIds: this.sourceMap[option],
        })),
      },
    ]
  }

  @action.bound
  public clickOnApply() {
    this.handleApply()
    this.onClickHandler?.()
  }

  public filterOptionInstances = (ids: string[]) => {
    return (this.allFilteredInstances as Array<ILogisticItem>).filter(c =>
      ids.includes(c.id),
    )
  }

  @computed
  private get allFilteredInstances(): unknown[] {
    const { getFilteredCollectionExcludeFilter } = this.logisticsInstanceStore
    return getFilteredCollectionExcludeFilter([this.type])
  }
}
