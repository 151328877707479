import * as turf from '@turf/turf'
import html2canvas from 'html2canvas'
import mapboxgl, { LngLat, LngLatLike, MapMouseEvent } from 'mapbox-gl'
import { action, computed, observable } from 'mobx'
import { MapRef, ViewState } from 'react-map-gl'
import WebMercatorViewport from 'viewport-mercator-project'

import {
  IGeoJson2DGeographicCoordinates,
  IGeoJson2DGeographicCoordinatesInput,
  IMutation,
  IProjectAddress,
  IProjectAddressInput,
  LocationType,
} from '~/client/graph'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import MapViewItemType from '~/client/src/shared/enums/MapViewItemType'
import Sitemap from '~/client/src/shared/models/Sitemap'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import InitialState from '~/client/src/shared/stores/InitialState'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import {
  NORTH_HEADING,
  toAddressBoundsInput,
} from '~/client/src/shared/utils/Address'

import { IMapBoxFeature } from '../../interfaces/IMapBoxFeature'
import Language from '../../localization/Language'
import Localization from '../../localization/LocalizationManager'
import Basemap from '../../models/Basemap'
import GlobeView from '../../models/GlobeView'
import EventsStore from '../../stores/EventStore/Events.store'
import GlobeViewControlStore from '../../stores/GlobeViewControl.store'
import BasemapsStore from '../../stores/domain/Basemaps.store'
import LocationAttributesStore from '../../stores/domain/LocationAttributes.store'
import {
  SCALED_IMAGE_PIXEL_RATE,
  SCALED_IMAGE_QUALITY,
  SCALED_IMAGE_TYPE,
} from '../BaseMapView/BaseMapView'
import {
  ITEM_EDITOR_SHAPE_FILL_LAYER,
  SHAPE_ARROW_LINE_LAYER,
  SHAPE_FILL_LAYER,
  SHAPE_LINE_LAYER,
} from './MapBoxViewer'

export enum Projection {
  globe = 'globe',
  mercator = 'mercator',
}
export interface ISitemapWithBasemap {
  sitemap: Sitemap
  basemap: Basemap
}
const MAPBOX_COUNTRY = 'country'
const MAPBOX_POSTCODE = 'postcode'
const MAPBOX_PLACE = 'place'
const MAPBOX_REGION = 'region'
export const BASE_ZOOM_LEVEL = 14
export const PROJECT_MARKER_ZOOM_LEVEL = 10
export const MAX_ZOOM_LEVEL = 20
export const MAX_PITCH_LEVEL = 60
export const MAX_ITEM_ZOOM_LEVEL = MAX_ZOOM_LEVEL + 0.1
const DEFAULT_ZOOM_VALUE = 18
const ZOOM_RATE = 1 / 25
const WHEEL_ZOOM_RATE = 1 / 100
const MAPBOX_BASE_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'
const MAPBOX_TERRAIN_URL = 'mapbox://mapbox.terrain-rgb'
const DEFAULT_RADIUS_METERS = 100
const FLYOUT_DURATION = 2500
const ANIMATIONS_STORAGE_KEY = 'should-use-map-animations'
const MAP_LABELS_STORAGE_KEY = 'should-hide-map-labels'
const TERRAIN_SOURCE_ID = 'terrain'

enum MapboxLanguageCodes {
  en = 'en',
  fr = 'fr',
  es = 'es',
}

export enum BaseMapStyle {
  STREET_STYLE_CODE = 'mapbox://styles/mapbox/standard',
  SATELLITE_STYLE_CODE = 'mapbox://styles/mapbox/standard-satellite',
}

export async function getMapBoxPlaceFromBareCoordinates(
  lng: number,
  lat: number,
  token: string,
): Promise<IMapBoxFeature> {
  const resp = await fetch(getMapBoxUrl(`${lng},${lat}`, token, false))
  const data: GeoJSON.FeatureCollection = await resp.json()
  return data.features[0] as IMapBoxFeature
}

export const getMapBoxUrl = (
  query: string,
  token: string,
  addTypes: boolean,
): string => {
  return `${MAPBOX_BASE_URL}${query}.json?access_token=${token}${
    addTypes ? '&types=address,place' : ''
  }`
}

export async function getMapBoxPlaceFromCoordinates(
  coords: LngLat,
  token: string,
): Promise<IMapBoxFeature> {
  const resp = await fetch(
    getMapBoxUrl(`${coords.lng},${coords.lat}`, token, false),
  )
  const data: GeoJSON.FeatureCollection = await resp.json()
  return data.features[0] as IMapBoxFeature
}

export async function getAddressFromCoords(
  lat: number,
  lng: number,
): Promise<IMapBoxFeature> {
  const lngtLat = new LngLat(lng, lat)
  return await getMapBoxPlaceFromCoordinates(lngtLat, mapboxgl.accessToken)
}

export function mapboxFeatureToAddress(
  item: IMapBoxFeature,
): IProjectAddressInput {
  if (!item?.context) {
    const worldCenter = new LngLat(0, 0)

    return {
      address: '',
      city: '',
      state: '',
      zipcode: '',
      country: '',
      bounds: toAddressBoundsInput(worldCenter.toBounds(DEFAULT_RADIUS_METERS)),
      center: worldCenter,
      bearing: 0,
      projectId: null,
    }
  }

  const { context } = item

  const countryProp = context.find(i => i.id.startsWith(MAPBOX_COUNTRY))
  const postCodeProp = context.find(i => i.id.startsWith(MAPBOX_POSTCODE))
  const placeProp = context.find(i => i.id.startsWith(MAPBOX_PLACE))
  const regionProp = context.find(i => i.id.startsWith(MAPBOX_REGION))
  const state = regionProp
    ? regionProp.short_code
      ? regionProp.short_code.split('-')[1]
      : regionProp.text
    : ''

  const [lng, lat] = item.center
  const center = new LngLat(lng, lat)

  return {
    address: item.place_name?.split(',')[0] || '',
    city: placeProp?.text || '',
    state,
    zipcode: postCodeProp?.text || '',
    country: countryProp?.text || '',
    bounds: toAddressBoundsInput(center.toBounds(DEFAULT_RADIUS_METERS)),
    center,
    bearing: 0,
    projectId: null,
  }
}

export const ALLOWED_SITEMAP_ITEMS_WITHOUT_DATA_OBJECT = [
  MapViewItemType.Line,
  MapViewItemType.TextBox,
]

const STORAGE_TRUE_VALUE = 'true'
const TRAFFIC_STORAGE_KEY = 'traffic-key'
const BASE_MAP_STORAGE_KEY = 'base-map-key'

enum ZOOM_LEVEL {
  PROJECT_MARKER = 'project_marker',
  BUILDINGS = 'buildings',
  ALL_LOCATIONS = 'all_locations',
}

export default class MapBoxViewerStore {
  @observable public projection: Projection = Projection.mercator
  @observable public isLoaded: boolean = false
  @observable public isStyleLoaded: boolean = false
  @observable public viewport: ViewState = {
    latitude: 0,
    longitude: 0,
    zoom: 0,
    bearing: NORTH_HEADING,
    pitch: 0,
    padding: null,
  }
  @observable public baseMap: BaseMapStyle = BaseMapStyle.STREET_STYLE_CODE
  @observable public mapRef: MapRef
  @observable public height: number = 0
  @observable public width: number = 0
  @observable public offsetX: number = 0
  @observable public offsetY: number = 0
  @observable public isLabelsHidden = false

  //map events props
  @observable public mapCursor = null
  @observable public selectedMarkerItemId: string = null
  @observable public hoveredShapeItemId: string = null
  @observable public hoveredMarkerItemId: string = null
  @observable public hoveredSitemapId: string = null

  // rewrite to get all the items on the map
  @observable public isTrafficShown: boolean = false
  @observable public is3DModeActive: boolean = false
  @observable public isTerrainShown: boolean = false
  @observable public isMoreMenuShown: boolean = false
  @observable public shouldShowAdditionalProjectMarkers: boolean = false
  @observable public manuallyTriggeredProjectMarker: boolean = false
  @observable public shouldUseMapAnimations: boolean = false

  public constructor(
    private readonly sitemapsStore: SitemapsStore,
    protected readonly locationAttributesStore: LocationAttributesStore,
    private readonly state: InitialState,
    public readonly globeViewControlStore: GlobeViewControlStore,
    public readonly basemapsStore: BasemapsStore,
    private readonly eventsStore?: EventsStore,
    public readonly isEditorMode?: boolean,
    public readonly selectedSitemapItem?: () => MapViewItemBase,
    public readonly selectSitemapItem?: (item: MapViewItemBase) => void,
  ) {
    this.setDefaultValues()
  }

  @action.bound
  public toggleProjection(): void {
    this.projection =
      this.projection === Projection.globe
        ? Projection.mercator
        : Projection.globe
  }

  @action.bound
  public toggleTerrain(): void {
    if (!this.mapRef.getMap().getSource(TERRAIN_SOURCE_ID)) {
      this.mapRef.getMap().addSource(TERRAIN_SOURCE_ID, {
        type: 'raster-dem',
        url: MAPBOX_TERRAIN_URL,
      })
    }
    if (this.isTerrainShown) {
      this.mapRef
        .getMap()
        .setTerrain({ source: TERRAIN_SOURCE_ID, exaggeration: 0 })
    } else {
      this.mapRef
        .getMap()
        .setTerrain({ source: TERRAIN_SOURCE_ID, exaggeration: 1.5 })
    }
    this.isTerrainShown = !this.isTerrainShown
  }

  @action.bound
  public toggle3DMode(): void {
    if (this.is3DModeActive) {
      this.mapRef.getMap().setConfigProperty('basemap', 'show3dObjects', false)
    } else {
      this.mapRef.getMap().setConfigProperty('basemap', 'show3dObjects', true)
    }
    this.is3DModeActive = !this.is3DModeActive
  }

  public get isSatelliteActive(): boolean {
    return this.baseMap === BaseMapStyle.SATELLITE_STYLE_CODE
  }

  public get isStreetsActive(): boolean {
    return this.baseMap === BaseMapStyle.STREET_STYLE_CODE
  }

  @action.bound
  public toggleMapAnimations(): void {
    if (this.shouldUseMapAnimations) {
      localStorage.removeItem(ANIMATIONS_STORAGE_KEY)
    } else {
      localStorage.setItem(ANIMATIONS_STORAGE_KEY, STORAGE_TRUE_VALUE)
    }
    this.shouldUseMapAnimations = !this.shouldUseMapAnimations
  }

  public get selectedGlobe(): GlobeView {
    return this.globeViewControlStore.selectedGlobeView
  }

  public isMarkerHighlighted(itemId: string): boolean {
    return this.hoveredMarkerItemId
      ? this.hoveredMarkerItemId === itemId
      : this.hoveredShapeItemId === itemId
  }

  @action.bound
  public setDefaultValues(): void {
    this.isStyleLoaded = false
    if (this.selectedGlobe) {
      this.setStyleFormGlobe()
    } else {
      const style = this.baseMap
      this.baseMap =
        (localStorage.getItem(BASE_MAP_STORAGE_KEY) as BaseMapStyle) ||
        BaseMapStyle.STREET_STYLE_CODE
      this.isTrafficShown = !!localStorage.getItem(TRAFFIC_STORAGE_KEY)
      if (style !== this.baseMap) {
        this.isStyleLoaded = false
      }
    }
    this.shouldUseMapAnimations = !!localStorage.getItem(ANIMATIONS_STORAGE_KEY)
    this.isLabelsHidden = !!localStorage.getItem(MAP_LABELS_STORAGE_KEY)
  }

  private setMapLabels(): void {
    this.mapRef
      .getMap()
      .setConfigProperty('basemap', 'showPlaceLabels', !this.isLabelsHidden)
      .setConfigProperty(
        'basemap',
        'showPointOfInterestLabels',
        !this.isLabelsHidden,
      )
  }

  @action.bound
  public toggleLabels(): void {
    this.isLabelsHidden = !this.isLabelsHidden
    if (this.isLabelsHidden) {
      localStorage.setItem(MAP_LABELS_STORAGE_KEY, STORAGE_TRUE_VALUE)
    } else {
      localStorage.removeItem(MAP_LABELS_STORAGE_KEY)
    }
    this.setMapLabels()
  }

  @action.bound
  public toggleAdditionalMarkers(): void {
    this.shouldShowAdditionalProjectMarkers =
      !this.shouldShowAdditionalProjectMarkers
  }

  @action.bound
  public toggleMoreMenu(event?: React.SyntheticEvent<HTMLDivElement>): void {
    event?.stopPropagation?.()
    this.isMoreMenuShown = !this.isMoreMenuShown
  }

  @action.bound
  public hideMoreMenu(event?: React.SyntheticEvent<HTMLDivElement>): void {
    event?.stopPropagation?.()
    if (this.isMoreMenuShown) {
      this.isMoreMenuShown = false
    }
  }

  private shouldBeDisplayed(item: MapViewItemBase): boolean {
    switch (this.zoomLevel) {
      case ZOOM_LEVEL.PROJECT_MARKER:
        return false
      case ZOOM_LEVEL.BUILDINGS:
        return (
          item.dataObject &&
          (item.dataObject.type === LocationType.Building ||
            item.dataObject.type === LocationType.Route)
        )
      case ZOOM_LEVEL.ALL_LOCATIONS:
        return true
    }
  }

  @computed
  private get zoomLevel(): ZOOM_LEVEL {
    const { zoom } = this.viewport
    switch (true) {
      case zoom <= PROJECT_MARKER_ZOOM_LEVEL:
        return ZOOM_LEVEL.PROJECT_MARKER
      case zoom > PROJECT_MARKER_ZOOM_LEVEL && zoom < BASE_ZOOM_LEVEL:
        return ZOOM_LEVEL.BUILDINGS
      case zoom >= BASE_ZOOM_LEVEL:
        return ZOOM_LEVEL.ALL_LOCATIONS
    }
  }

  @computed
  public get shouldShowProjectMarker(): boolean {
    return this.zoomLevel === ZOOM_LEVEL.PROJECT_MARKER
  }

  @computed
  public get globeCorners(): IGeoJson2DGeographicCoordinatesInput[] {
    return this.currentGlobeViewGeoCorners?.map(bound => {
      return {
        longitude: bound[0],
        latitude: bound[1],
      } as IGeoJson2DGeographicCoordinatesInput
    })
  }

  @computed
  public get currentGlobeViewGeoCorners(): number[][] {
    const map = this.mapRef?.getMap?.()

    if (!map) {
      return
    }
    const width: number = this.width || map.transform.width
    const height: number = this.height || map.transform.height
    const offsetX: number = this.offsetX || 0
    const offsetY: number = this.offsetY || 0

    const newPoint1 = map.unproject([offsetX, height + offsetY])
    const newPoint2 = map.unproject([offsetX + width, height + offsetY])
    const newPoint3 = map.unproject([offsetX + width, offsetY])
    const newPoint4 = map.unproject([offsetX, offsetY])

    return [
      [newPoint4.lng, newPoint4.lat],
      [newPoint3.lng, newPoint3.lat],
      [newPoint2.lng, newPoint2.lat],
      [newPoint1.lng, newPoint1.lat],
    ]
  }

  @action.bound
  public setZeroPitch(): void {
    this.viewport.pitch = 0
  }

  @action.bound
  public setDefaultMapMode(): void {
    this.setDefaultValues()
  }

  @action.bound
  public setViewport(viewport: ViewState): void {
    if (!this.isLoaded) {
      return
    }
    this.viewport = viewport
  }

  @action.bound
  public onResize(): void {
    if (this.mapRef) {
      this.mapRef.resize()
    }
  }

  @action.bound
  public updateLanguage(): void {
    if (this.mapRef) {
      this.mapRef
        .getMap()
        .setLayoutProperty('country-label', 'text-field', [
          'get',
          `name_${this.language}`,
        ])
    }
  }

  @action.bound
  public setViewportFromItem(item: MapViewItemBase): void {
    if (
      !this.isLoaded ||
      !item?.sitemapItem?.coordinates ||
      !item.isDisplayed
    ) {
      return
    }
    const { latitude, longitude } = item.sitemapItem.coordinates
    const flyOutDistance = turf.distance(
      [longitude, latitude],
      [this.viewport.longitude, this.viewport.latitude],
    )
    const animationTime = 2000 + flyOutDistance / 2
    this.flyOutAnimation(
      [longitude, latitude],
      this.viewport.zoom < BASE_ZOOM_LEVEL
        ? BASE_ZOOM_LEVEL
        : this.viewport.zoom,
      animationTime,
    )
    if (!this.shouldUseMapAnimations) {
      this.viewport.latitude = latitude
      this.viewport.longitude = longitude
      if (this.viewport.zoom < BASE_ZOOM_LEVEL) {
        this.viewport.zoom = BASE_ZOOM_LEVEL
      }
    }
  }

  @action.bound
  public setViewportFromPlan(plan: Sitemap): void {
    if (
      !this.isLoaded ||
      !plan.isReferenced ||
      !this.selectedGlobe.sitemapsMap[plan.id]
    ) {
      return
    }
    const {
      center: { lat, lng },
      zoom,
    } = plan

    const flyOutDistance = turf.distance(
      [lng, lat],
      [this.viewport.longitude, this.viewport.latitude],
    )
    const animationTime = 2000 + flyOutDistance / 2
    this.flyOutAnimation([lng, lat], zoom, animationTime)

    this.viewport.latitude = lat
    this.viewport.longitude = lng
    this.viewport.zoom = zoom
  }

  // remove mapbox info on unmount
  @action.bound
  public resetMapboxInfo(): void {
    this.viewport = {
      latitude: 0,
      longitude: 0,
      zoom: 0,
      bearing: NORTH_HEADING,
      pitch: 0,
      padding: null,
    }

    this.setDefaultValues()
  }

  // zoom of the current sitemap mapbox calculated on bounds
  private getSitemapZoom(sitemap: Sitemap): number {
    if (!sitemap) {
      return null
    }
    const { zoom, bounds } = sitemap

    if (!this.shouldShowProjectMarker) {
      return new WebMercatorViewport({
        width: this.width,
        height: this.height,
      }).fitBounds(
        [
          [bounds.sw.lng, bounds.ne.lat],
          [bounds.ne.lng, bounds.sw.lat],
        ],
        {},
      ).zoom
    }

    return zoom
  }

  public get isResetDisabled(): boolean {
    if (!this.selectedGlobe) {
      return true
    }
    const globeStyle = this.selectedGlobe.isSatelliteMode
      ? BaseMapStyle.SATELLITE_STYLE_CODE
      : BaseMapStyle.STREET_STYLE_CODE
    const isStyleTheSame =
      this.baseMap === globeStyle &&
      this.isTrafficShown === this.selectedGlobe.isTrafficShown
    const isViewportTheSame =
      this.viewport.bearing.toFixed(4) ===
        this.selectedGlobe.bearing.toFixed(4) &&
      this.viewport.latitude.toFixed(4) ===
        this.selectedGlobe.center.lat.toFixed(4) &&
      this.viewport.longitude.toFixed(4) ===
        this.selectedGlobe.center.lng.toFixed(4) &&
      this.viewport.zoom.toFixed(4) === this.selectedGlobe.zoom.toFixed(4) &&
      this.viewport.pitch === this.selectedGlobe.pitch
    return isViewportTheSame && isStyleTheSame
  }

  @action.bound
  public setViewportFromAddress(
    sitemap?: Sitemap,
    isFullSizeImage?: boolean,
  ): void {
    const { projectAddress } = this.state

    const zoom = isFullSizeImage
      ? this.getSitemapZoom(sitemap)
      : sitemap?.zoom ??
        this.viewInfo?.zoom ??
        projectAddress.zoom ??
        DEFAULT_ZOOM_VALUE
    const longitude =
      sitemap?.center?.lng ??
      this.viewInfo?.longitude ??
      projectAddress.center?.lng ??
      0
    const latitude =
      sitemap?.center?.lat ??
      this.viewInfo?.latitude ??
      projectAddress.center?.lat ??
      0

    this.flyOutAnimation([longitude, latitude], zoom)
    this.viewport.zoom = zoom
    this.viewport.pitch =
      sitemap?.pitch ?? this.viewInfo?.pitch ?? projectAddress.pitch ?? 0
    this.viewport.bearing =
      sitemap?.bearing ?? this.viewInfo?.bearing ?? projectAddress.bearing ?? 0
    this.viewport.latitude = latitude
    this.viewport.longitude = longitude

    if (this.selectedGlobe) {
      this.setStyleFormGlobe()
    }
  }

  private setStyleFormGlobe(): void {
    const oldStyle = this.baseMap
    this.baseMap = this.selectedGlobe.isSatelliteMode
      ? BaseMapStyle.SATELLITE_STYLE_CODE
      : BaseMapStyle.STREET_STYLE_CODE
    this.isTrafficShown = this.selectedGlobe.isTrafficShown
    if (oldStyle !== this.baseMap) {
      this.isStyleLoaded = false
    }
  }

  @action.bound
  public setViewportProp(propValue: number, propName: string): void {
    this.viewport[propName] = propValue
  }

  @action.bound
  public setRefForProjectMap(ref: MapRef): void {
    if (ref) {
      this.mapRef = ref
      this.mapRef.getMap().on('load', () => {
        this.mapRef.getMap().scrollZoom.setZoomRate(ZOOM_RATE)
        this.mapRef.getMap().scrollZoom.setWheelZoomRate(WHEEL_ZOOM_RATE)
        this.isLoaded = true
      })
      this.mapRef.getMap().on('style.load', () => {
        this.isStyleLoaded = true
        if (this.mapRef.getMap().getConfig('basemap')) {
          this.mapRef
            .getMap()
            .setConfigProperty('basemap', 'show3dObjects', false)
          this.setMapLabels()
        }
      })
    }
  }

  @action.bound
  public toggleTraffic(): void {
    this.isTrafficShown = !this.isTrafficShown
    if (this.isTrafficShown) {
      localStorage.setItem(TRAFFIC_STORAGE_KEY, STORAGE_TRUE_VALUE)
    } else {
      localStorage.removeItem(TRAFFIC_STORAGE_KEY)
    }
  }

  @action.bound
  public removeRefs(): void {
    this.mapRef = null
  }

  @action.bound
  public toggleMapStyle(event: React.SyntheticEvent<HTMLDivElement>): void {
    event.stopPropagation()
    this.isStyleLoaded = false
    this.baseMap =
      this.baseMap === BaseMapStyle.STREET_STYLE_CODE
        ? BaseMapStyle.SATELLITE_STYLE_CODE
        : BaseMapStyle.STREET_STYLE_CODE

    this.isTrafficShown = false
    this.is3DModeActive = false
    this.isTerrainShown = false
    if (!this.selectedGlobe || !this.globeViewControlStore) {
      localStorage.setItem(BASE_MAP_STORAGE_KEY, this.baseMap)
      localStorage.removeItem(TRAFFIC_STORAGE_KEY)
    }
  }

  public getDataURL(): string {
    if (!this.mapRef) {
      return null
    }

    const stage = this.mapRef.getMap().getCanvas()
    const dataUrlConfig = {
      mimeType: 'image/jpeg',
      quality: SCALED_IMAGE_QUALITY,
      pixelRatio: SCALED_IMAGE_PIXEL_RATE,
      width: stage.width,
      height: stage.height,
      x: stage.offsetLeft,
      y: stage.offsetTop,
    }
    return stage.toDataURL(Object.assign(dataUrlConfig))
  }

  @action.bound
  public onAddressChanged(projectAddress: IProjectAddressInput): void {
    this.saveAddress(projectAddress)
  }

  @computed
  public get sitemapWithBasemapsOnGlobe(): ISitemapWithBasemap[] {
    return (
      this.globeMaps.map(s => ({
        sitemap: s,
        basemap: this.basemapsStore.byId.get(s.basemapId),
      })) || []
    )
  }

  @computed
  public get sitemapOnGlobeMap(): { [mapId: string]: boolean[] } {
    return this.globeMaps.reduce((map, sitemap) => {
      map[sitemap.id] = true
      return map
    }, {})
  }

  @computed
  public get sitemapsMap(): { [mapId: string]: ISitemapWithBasemap } {
    return this.sitemapsStore.list.reduce((map, sitemap) => {
      map[sitemap.id] = {
        sitemap,
        basemap: this.basemapsStore.byId.get(sitemap.basemapId),
      }
      return map
    }, {})
  }

  // sitemaps to show as additional on the 'GLOBE'
  @computed
  private get globeMaps(): Sitemap[] {
    return this.selectedGlobe
      ? this.selectedGlobe.sitemaps
          .filter(sitemap => !!this.sitemapsStore.byId.get(sitemap.sitemapId))
          .map(sitemap => this.sitemapsStore.byId.get(sitemap.sitemapId)) || []
      : []
  }

  public getItemCoordinates(
    x: number,
    y: number,
  ): IGeoJson2DGeographicCoordinates {
    if (!this.mapRef?.getMap?.()) {
      return null
    }
    const newPoint = this.mapRef?.getMap().unproject([x, y])

    return {
      longitude: newPoint.lng,
      latitude: newPoint.lat,
    }
  }

  @computed
  public get displayedGlobeViewItems(): MapViewItemBase[] {
    return this.globeViewControlStore.selectedGlobeViewItems.filter(
      i => i.isDisplayed && this.shouldBeDisplayed(i),
    )
  }

  public get allGlobeViewItems(): MapViewItemBase[] {
    return this.globeViewControlStore.selectedGlobeViewItems
  }

  @action.bound
  public async createMapImage(): Promise<string> {
    const config = {
      mimeType: SCALED_IMAGE_TYPE,
      quality: SCALED_IMAGE_QUALITY,
      pixelRatio: SCALED_IMAGE_PIXEL_RATE,
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    }
    const mapboxContainer = this.mapRef.getMap().getCanvasContainer()

    return await html2canvas(mapboxContainer).then(canvas => {
      return canvas.toDataURL(Object.assign(config))
    })
  }

  public imagesPositionsMap(): { [attrId: string]: number[][] } {
    const currentMap = this.mapRef?.getMap?.()
    if (!currentMap) {
      return
    }

    return this.globeMaps
      .filter(item => item.geoCorners?.length)
      .reduce((map, item) => {
        map[item.id] = [
          [item.geoCorners[0].longitude, item.geoCorners[0].latitude],
          [item.geoCorners[1].longitude, item.geoCorners[1].latitude],
          [item.geoCorners[2].longitude, item.geoCorners[2].latitude],
          [item.geoCorners[3].longitude, item.geoCorners[3].latitude],
        ]
        return map
      }, {})
  }

  @action.bound
  public onMapClick(e: MapMouseEvent): void {
    const selectedMapViewItem =
      this.selectedSitemapItem && this.selectedSitemapItem()
    let wasItemSelected = false
    if (e?.features?.length) {
      switch (e.features[0]?.layer?.id) {
        case SHAPE_FILL_LAYER:
        case SHAPE_LINE_LAYER:
          const itemId = e?.features[0]?.properties?.itemId
          if (itemId && itemId !== selectedMapViewItem?.id) {
            this.selectSitemapItem?.(
              this.displayedGlobeViewItems.find(
                i => i.id === e?.features[0]?.properties?.itemId,
              ),
            )
            wasItemSelected = true
          }
          break
        case ITEM_EDITOR_SHAPE_FILL_LAYER:
          break
      }
    }
    if (!wasItemSelected && selectedMapViewItem && !this.selectedMarkerItemId) {
      this.selectSitemapItem(null)
    }
    this.selectedMarkerItemId = null
  }

  @action.bound
  public onMouseMove(e: MapMouseEvent): void {
    this.mapCursor = e?.features?.length ? 'pointer' : null

    const shapeFeature = e.features?.find(f =>
      [SHAPE_FILL_LAYER, SHAPE_LINE_LAYER, SHAPE_ARROW_LINE_LAYER].includes(
        f?.layer?.id,
      ),
    )

    if (shapeFeature) {
      this.hoveredShapeItemId = shapeFeature.properties?.itemId || null
    } else {
      this.hoveredShapeItemId = null
    }
  }

  private saveAddress(projectAddress: IProjectAddress): Promise<IMutation> {
    return new Promise((resolve, reject) => {
      const { id: projectAddressId } = this.state.projectAddress
      const { id: projectId } = this.state.activeProject
      this.eventsStore.dispatch(
        e.SAVE_PROJECT_ADDRESSES,
        [
          Object.assign(projectAddress, {
            id: projectAddressId,
            projectId,
          }),
        ],
        resolve,
        reject,
      )
    })
  }

  @action.bound
  private flyOutAnimation(
    center: LngLatLike,
    zoom: number,
    duration?: number,
  ): void {
    if (this.shouldUseMapAnimations && this.mapRef && center) {
      this.mapRef.flyTo({
        center,
        essential: true,
        zoom,
        duration: duration || FLYOUT_DURATION,
      })
    }
  }

  public get viewInfo(): ViewState {
    const globe = this.selectedGlobe
    const { projectAddress } = this.state

    if (globe) {
      if (!globe.id) {
        return {
          bearing: projectAddress.bearing,
          zoom: projectAddress.zoom,
          pitch: projectAddress.pitch,
          latitude: projectAddress?.center?.lat,
          longitude: projectAddress?.center?.lng,
          padding: null,
        }
      }
      return {
        bearing: globe.bearing,
        zoom: globe.zoom,
        pitch: globe.pitch,
        latitude: globe?.center?.lat,
        longitude: globe?.center?.lng,
        padding: null,
      }
    }
  }

  private get language(): string {
    switch (Localization.currentLanguage) {
      case Language.english:
      case Language.ukrainian:
        return MapboxLanguageCodes.en
      case Language.french:
        return MapboxLanguageCodes.fr
      case Language.spanish:
        return MapboxLanguageCodes.es
    }
  }
}
