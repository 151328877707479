import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList, toggleClass } from 'react-classlist-helper'

import {
  IConditionalField,
  IPermitTypeChecklist,
  IPermitTypeField,
  IRestrictedOption,
  PermitFieldType,
} from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import SelectWrapper from '~/client/src/shared/components/SelectWrapper/SelectWrapper'
import {
  conditionalPermitFields,
  nonConditionalPermitFieldTypes,
} from '~/client/src/shared/constants/PermitFieldTypeConstants'
import {
  MAX_CONDITIONAL_DEPTH_LEVEL,
  MAX_CONDITIONAL_FIELDS,
} from '~/client/src/shared/constants/permitTypeFieldsConstants'
import { permitChecklistCondKeyList } from '~/client/src/shared/enums/PermitChecklistConditionalKey'
import QuestionnaireType from '~/client/src/shared/enums/QuestionnaireType'
import { getQuestionnaireOptionsByType } from '~/client/src/shared/utils/PermitInstructionsHelper'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import FieldConfigurator from '../../FieldConfigurator/FieldConfigurator'
import FormFieldsModal from '../../FormFieldsModal'

interface IProps {
  field: IPermitTypeField
  allConditionalFields: IConditionalField[]

  addNewConditionalField(
    fieldId: string,
    key: string,
    index: number,
    fieldType: PermitFieldType,
  ): void
  updateConditionalField(
    fields: IPermitTypeField[],
    index: number,
    field: IPermitTypeField,
  ): void
  updateConditionalChecklist(
    fields: IPermitTypeField[],
    index: number,
    field: IPermitTypeField,
    checklist: IPermitTypeChecklist,
  ): void
  updateConditionalRestrictions(
    fields: IPermitTypeField[],
    index: number,
    field: IPermitTypeField,
    restrictedLocations: IRestrictedOption[],
  ): void
  removeConditionalField(
    fieldId: string,
    key: string,
    conditionalFieldId: string,
  ): void

  depthLevel?: number

  tableConfiguratorRenderer?(field: IPermitTypeField): JSX.Element
}

interface IConditionalSelectOption {
  title: string
  value: string
}

const addFieldsFor = 'Add fields for'
const addConditionalFieldLevel = (level: number) =>
  `+ Add conditional field ${level > 1 ? `(level ${level})` : ''}`

@observer
export default class ConditionalFieldsConfigurator extends React.Component<IProps> {
  public static defaultProps = {
    depthLevel: 1,
  }

  @observable private selectedValue: string = EMPTY_STRING

  public componentDidMount() {
    this.initSelectedValue()
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { field } = this.props

    if (field !== prevProps.field && !this.isSelectedValueInOptions) {
      this.initSelectedValue()
    }
  }

  public render() {
    const {
      field,
      addNewConditionalField,
      updateConditionalField,
      updateConditionalChecklist,
      updateConditionalRestrictions,
      removeConditionalField,
      depthLevel,
      tableConfiguratorRenderer,
      allConditionalFields,
    } = this.props

    if (!field || !this.selectOptions?.length) {
      return null
    }

    return (
      <div
        className={classList({
          'pb12 px20': true,
          ml10: depthLevel === 1,
          'ml25 mr8': depthLevel > 1,
        })}
      >
        <div className="row y-center mw200 pb12">
          <span className="mr5 no-flex">{addFieldsFor}</span>
          <SelectWrapper className="no-grow">
            <select
              className="bg-palette-brand-lightest brada4 ba-none pl12 pr24 py6 pointer"
              value={this.selectedValue}
              onChange={this.updateSelectedValue}
            >
              <option value={EMPTY_STRING} className="text large" />
              {this.selectOptions?.map((opt, index) => (
                <option key={index} value={opt.value} className="text large">
                  {opt.title}
                </option>
              ))}
            </select>
          </SelectWrapper>
        </div>
        <div
          className={toggleClass(
            'ba-dashed-palette-grey brada8',
            !!this.selectedValue && !!this.conditionalFields?.length,
          )}
        >
          {this.conditionalFields.map((conditionalField, idx) => (
            <div key={conditionalField.id}>
              <div className="row x-center y-center conditional-field-row">
                {this.isAddingFieldAllowed && (
                  <div className="mw20 mx4 row x-start y-center">
                    <FormFieldsModal
                      addNewField={this.addNewConditionalField.bind(null, idx)}
                      restrictedFields={this.restrictedFields}
                    >
                      <Icons.Plus className="forms-conditional-add-icon pointer relative no-grow" />
                    </FormFieldsModal>
                  </div>
                )}
                <FieldConfigurator
                  field={conditionalField}
                  isMultipleToggleHidden={true}
                  onChange={this.updateConditionalField.bind(null, idx)}
                  onChecklistUpdate={this.updateConditionalChecklist.bind(
                    null,
                    idx,
                  )}
                  onLocationRestrictionsUpdate={this.updateConditionalRestrictions.bind(
                    this,
                    idx,
                  )}
                  tableConfiguratorRenderer={tableConfiguratorRenderer}
                />
                <Icons.CrossGrey
                  className="forms-conditional-del-icon text light pointer mx4 no-grow opacity5 row x-center y-center"
                  onMouseDown={this.removeConditionalField.bind(
                    null,
                    conditionalField,
                  )}
                />
              </div>
              {this.isInnerConfiguratorShown(conditionalField) && (
                <ConditionalFieldsConfigurator
                  field={conditionalField}
                  allConditionalFields={allConditionalFields}
                  depthLevel={depthLevel + 1}
                  addNewConditionalField={addNewConditionalField}
                  updateConditionalField={updateConditionalField}
                  updateConditionalChecklist={updateConditionalChecklist}
                  updateConditionalRestrictions={updateConditionalRestrictions}
                  removeConditionalField={removeConditionalField}
                  tableConfiguratorRenderer={tableConfiguratorRenderer}
                />
              )}
            </div>
          ))}
        </div>
        {!!this.selectedValue && !this.conditionalFields?.length && (
          <FormFieldsModal
            addNewField={this.addNewConditionalField.bind(null, -1)}
            restrictedFields={this.restrictedFields}
          >
            <span className="pointer text large blue-highlight px10 ml15">
              {addConditionalFieldLevel(depthLevel)}
            </span>
          </FormFieldsModal>
        )}
      </div>
    )
  }

  private get restrictedFields(): PermitFieldType[] {
    return this.props.depthLevel === MAX_CONDITIONAL_DEPTH_LEVEL
      ? nonConditionalPermitFieldTypes.concat(PermitFieldType.Table)
      : nonConditionalPermitFieldTypes
  }

  private get selectOptions(): IConditionalSelectOption[] {
    const { type, checklist, selectOptions } = this.props.field

    switch (type) {
      case PermitFieldType.Select:
        return (selectOptions || []).map(v => ({ title: v, value: v }))
      case PermitFieldType.Checklist:
        return permitChecklistCondKeyList.map(v => ({ title: v, value: v }))
      case PermitFieldType.Question:
        return getQuestionnaireOptionsByType(
          checklist.list?.[0]?.questionnaireType as QuestionnaireType,
        ).map(opt => ({ title: opt.title, value: opt.key }))
    }
  }

  private get isSelectedValueInOptions(): boolean {
    return (
      !!this.selectedValue &&
      this.selectOptions.some(opt => opt.value === this.selectedValue)
    )
  }

  private get conditionalFields(): IPermitTypeField[] {
    const { allConditionalFields, field } = this.props

    const conditionalFields = allConditionalFields
      ?.filter(cf => cf.fieldId === field.id)
      .find(cf => cf.key === this.selectedValue)

    if (!this.selectedValue || !conditionalFields) {
      return []
    }

    return conditionalFields.values
  }

  private get isAddingFieldAllowed(): boolean {
    return this.conditionalFields.length < MAX_CONDITIONAL_FIELDS
  }

  private isInnerConfiguratorShown = ({
    isMultiple,
    type,
  }: IPermitTypeField): boolean => {
    return (
      !isMultiple &&
      conditionalPermitFields.includes(type) &&
      this.props.depthLevel < MAX_CONDITIONAL_DEPTH_LEVEL
    )
  }

  @action.bound
  private updateSelectedValue(event: React.ChangeEvent<HTMLSelectElement>) {
    this.selectedValue = event.target.value
  }

  private addNewConditionalField = (
    index: number,
    fieldType: PermitFieldType,
  ) => {
    if (!this.isAddingFieldAllowed) {
      return
    }

    const { addNewConditionalField, field } = this.props
    addNewConditionalField(field.id, this.selectedValue, index, fieldType)
  }

  private updateConditionalField = (index: number, field: IPermitTypeField) => {
    this.props.updateConditionalField(this.conditionalFields, index, field)
  }

  private updateConditionalChecklist = (
    index: number,
    field: IPermitTypeField,
    checklist: IPermitTypeChecklist,
  ) => {
    this.props.updateConditionalChecklist(
      this.conditionalFields,
      index,
      field,
      checklist,
    )
  }

  private updateConditionalRestrictions(
    index: number,
    field: IPermitTypeField,
    restrictedLocations: IRestrictedOption[],
  ) {
    this.props.updateConditionalRestrictions(
      this.conditionalFields,
      index,
      field,
      restrictedLocations,
    )
  }

  private removeConditionalField = (conditionalField: IPermitTypeField) => {
    const { removeConditionalField, field } = this.props
    removeConditionalField(field.id, this.selectedValue, conditionalField.id)
  }

  @action.bound
  private initSelectedValue() {
    this.selectedValue = EMPTY_STRING
  }
}
