import { WorkflowsFilterType } from '~/client/graph'

export const workflowsFilterTypes = [
  WorkflowsFilterType.Location,
  WorkflowsFilterType.Equipment,
  WorkflowsFilterType.Status,
  WorkflowsFilterType.Company,
  WorkflowsFilterType.ResponsibleContact,
  WorkflowsFilterType.Type,
]
