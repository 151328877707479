import { IMaturixProject } from '~/client/graph'

export default class MaturixProject {
  public static fromDto(dto: IMaturixProject): MaturixProject {
    return new MaturixProject(
      dto.id,
      dto.projectName,
      dto.projectDescription,
      dto.startTime,
      dto.status,
      dto.endTime,
    )
  }

  public constructor(
    public id: string,
    public projectName: string,
    public projectDescription?: string,
    public startTime?: number,
    public status?: string,
    public endTime?: number,
  ) {}
}
