import { action, observable } from 'mobx'

import { DeliveryListFilterType, IPosition, LocationType } from '~/client/graph'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import deliveryGroupingKeyToLocationTypeMap from '~/client/src/shared/constants/deliveryGroupingKeyToLocationTypeMap'
import Delivery from '~/client/src/shared/models/Delivery'
import User from '~/client/src/shared/models/User'
import {
  DeliveryLocationType,
  lbsBands,
  toDeliveryLocationType,
} from '~/client/src/shared/types/IHierarchyParent'

import DeliveryPinLocationOption from '../../enums/DeliveryPinLocationOption'
import { defaultDeliveriesPreferences } from '../../interfaces/IDeliveriesViewPreferences'
import IUserPreferences from '../../interfaces/IUserPreferences'
import InitialState from '../InitialState'
import CompaniesStore from '../domain/Companies.store'

const MAX_PERCENT = 100

export default class DeliverySitemapPinStore {
  @observable public selectedPinOption: DeliveryPinLocationOption =
    DeliveryPinLocationOption.mostPertinent

  public constructor(private readonly state: InitialState) {
    this.loadPinLocationOption()
  }

  @action.bound
  public selectShowOnOption(option: DeliveryPinLocationOption) {
    this.selectedPinOption = option

    this.savePinLocationOption()
  }

  @action.bound
  public selectDefaultShowOnOption() {
    this.selectShowOnOption(DeliveryPinLocationOption.mostPertinent)
  }

  public getItemsByPinLocationOption = (
    displayedItems: MapViewItemBase[],
  ): MapViewItemBase[] => {
    return displayedItems
      .filter(item => item.dataObject)
      .sort((a, b) => {
        const aNodeType = a.dataObject.type
        const bNodeType = b.dataObject.type

        if (
          this.selectedPinOption === DeliveryPinLocationOption.mostPertinent
        ) {
          const aIndex = lbsBands.indexOf(aNodeType)
          const bIndex = lbsBands.indexOf(bNodeType)

          return aIndex - bIndex
        }

        let selectedNodeType
        switch (this.selectedPinOption) {
          case DeliveryPinLocationOption.equipment:
            selectedNodeType = LocationType.OffloadingEquipment
            break
          case DeliveryPinLocationOption.gate:
            selectedNodeType = LocationType.Gate
            break
          case DeliveryPinLocationOption.route:
            selectedNodeType = LocationType.Route
            break
          case DeliveryPinLocationOption.zone:
            selectedNodeType = LocationType.Zone
            break
          case DeliveryPinLocationOption.area:
            selectedNodeType = LocationType.Area
            break
          case DeliveryPinLocationOption.building:
            selectedNodeType = LocationType.Building
            break
          case DeliveryPinLocationOption.level:
            selectedNodeType = LocationType.Level
            break
          case DeliveryPinLocationOption.staging:
            selectedNodeType = LocationType.Staging
            break
          case DeliveryPinLocationOption.interiorPath:
            selectedNodeType = LocationType.InteriorPath
            break
          case DeliveryPinLocationOption.interiorDoor:
            selectedNodeType = LocationType.InteriorDoor
            break
        }

        if (aNodeType === selectedNodeType && bNodeType !== selectedNodeType) {
          return -1
        }

        if (bNodeType === selectedNodeType && aNodeType !== selectedNodeType) {
          return 1
        }

        const aLBSIndex = lbsBands.indexOf(aNodeType)
        const bLBSIndex = lbsBands.indexOf(bNodeType)

        return aLBSIndex - bLBSIndex
      })
  }

  public getPinPosition = (
    sitemapItem: MapViewItemBase,
    cWidth: number,
    cHeight: number,
  ): IPosition => {
    const position = this.getPositionByDisplayedProperties(sitemapItem)
    const x = (cWidth * (position?.x || 0)) / MAX_PERCENT
    const y = (cHeight * (position?.y || 0)) / MAX_PERCENT

    return { x, y }
  }

  public getPinLabel = (
    relatedDeliveries: Delivery[],
    companiesStore: CompaniesStore,
  ): string => {
    if (relatedDeliveries.length === 1) {
      return relatedDeliveries[0].codeToDisplay(companiesStore)
    }
    return relatedDeliveries.length.toString()
  }

  public shouldRenderCircle = (relatedDeliveries: Delivery[]): boolean => {
    return relatedDeliveries.length > 1
  }

  public shouldShowPin = (
    item: MapViewItemBase,
    deliveries: Delivery[],
    groupingKey?: string,
    useMobileGroupingTypes?: boolean,
  ): boolean => {
    if (
      !this.isSitemapItemSupported(item) ||
      !deliveries?.length ||
      !this.isSitemapItemDisplayed(item)
    ) {
      return false
    }

    if (!groupingKey) {
      return true
    }

    const groupingLocationType = useMobileGroupingTypes
      ? this.mobileGroupingTypeMap[groupingKey]
      : deliveryGroupingKeyToLocationTypeMap[groupingKey]

    const nodeType = toDeliveryLocationType(item.dataObject.type)

    return groupingLocationType
      ? nodeType === groupingLocationType
      : nodeType === DeliveryLocationType.Zone
  }

  public isSitemapItemSupported = (item: MapViewItemBase): boolean => {
    return !item.isDataLess
  }

  public getPositionByDisplayedProperties = (
    sitemapItem: MapViewItemBase,
  ): IPosition => {
    const { iconProperties, labelProperties, shapeProperties } =
      sitemapItem.sitemapItemProperties

    if (this.isSitemapItemIconDisplayed(sitemapItem)) {
      return iconProperties.position
    }
    if (this.isSitemapItemLabelDisplayed(sitemapItem)) {
      return labelProperties.position
    }
    if (this.isSitemapItemShapeDisplayed(sitemapItem)) {
      return shapeProperties.calculateIconPosition()
    }

    // if none of the Properties are displayed, let's return the position of Icon as default
    return iconProperties?.position
  }

  public shouldShowPinAsDone = (deliveries: Delivery[]): boolean => {
    return deliveries?.every(d => d.isDone || d.isDenied)
  }

  public shouldShowPinAsCanceled = (deliveries: Delivery[]): boolean => {
    return deliveries?.every(d => d.isCanceled)
  }

  public shouldShowPinAsAssigned = (
    deliveries: Delivery[],
    user: User,
  ): boolean => {
    return deliveries.some(d => d.isAssociatedWithUser(user))
  }

  private isSitemapItemLabelDisplayed = (
    sitemapItem: MapViewItemBase,
  ): boolean => {
    const { labelProperties } = sitemapItem.sitemapItemProperties
    return labelProperties && labelProperties.isDisplayed
  }

  private isSitemapItemIconDisplayed = (
    sitemapItem: MapViewItemBase,
  ): boolean => {
    const { iconProperties } = sitemapItem.sitemapItemProperties
    return iconProperties && iconProperties.isDisplayed
  }

  private isSitemapItemShapeDisplayed = (
    sitemapItem: MapViewItemBase,
  ): boolean => {
    const { shapeProperties } = sitemapItem.sitemapItemProperties
    return shapeProperties && shapeProperties.isDisplayed
  }

  private isSitemapItemDisplayed = (sitemapItem: MapViewItemBase): boolean => {
    return (
      this.isSitemapItemIconDisplayed(sitemapItem) ||
      this.isSitemapItemLabelDisplayed(sitemapItem) ||
      this.isSitemapItemShapeDisplayed(sitemapItem)
    )
  }

  private get mobileGroupingTypeMap() {
    return {
      [DeliveryListFilterType.Building]: DeliveryLocationType.Building,
      [DeliveryListFilterType.Gate]: DeliveryLocationType.Gate,
      [DeliveryListFilterType.Zone]: DeliveryLocationType.Zone,
      [DeliveryListFilterType.Route]: DeliveryLocationType.Route,
    }
  }

  @action.bound
  private loadPinLocationOption() {
    const { user } = this.state

    if (!user) {
      return
    }

    const userPrefs: IUserPreferences = {
      deliveriesViewPreferences: defaultDeliveriesPreferences,
    }

    try {
      Object.assign(userPrefs, JSON.parse(localStorage.getItem(user.id)))
    } finally {
      this.selectedPinOption =
        userPrefs.deliveriesViewPreferences.pinLocationOption
    }
  }

  @action.bound
  private savePinLocationOption() {
    const { user } = this.state

    if (!user) {
      return
    }

    const userPrefs: IUserPreferences = {
      deliveriesViewPreferences: defaultDeliveriesPreferences,
    }

    try {
      Object.assign(userPrefs, JSON.parse(localStorage.getItem(user.id)))
    } finally {
      userPrefs.deliveriesViewPreferences.pinLocationOption =
        this.selectedPinOption
    }

    localStorage.setItem(user.id, JSON.stringify(userPrefs))
  }
}
