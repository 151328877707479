import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IGetMaturixProjectsQueryVariables = Types.Exact<{
  struxHubProjectId: Types.Scalars['ObjectId']
}>

export type IGetMaturixProjectsQuery = {
  getMaturixProjects?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<
          Types.IMaturixProject,
          | 'id'
          | 'projectName'
          | 'projectDescription'
          | 'startTime'
          | 'status'
          | 'endTime'
        >
      >
    >
  >
}

export const GetMaturixProjectsDocument = gql`
  query GetMaturixProjects($struxHubProjectId: ObjectId!) {
    getMaturixProjects(struxHubProjectId: $struxHubProjectId) {
      id
      projectName
      projectDescription
      startTime
      status
      endTime
    }
  }
`

/**
 * __useGetMaturixProjectsQuery__
 *
 * To run a query within a React component, call `useGetMaturixProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaturixProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaturixProjectsQuery({
 *   variables: {
 *      struxHubProjectId: // value for 'struxHubProjectId'
 *   },
 * });
 */
export function useGetMaturixProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetMaturixProjectsQuery,
    IGetMaturixProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetMaturixProjectsQuery,
    IGetMaturixProjectsQueryVariables
  >(GetMaturixProjectsDocument, options)
}
export function useGetMaturixProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetMaturixProjectsQuery,
    IGetMaturixProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetMaturixProjectsQuery,
    IGetMaturixProjectsQueryVariables
  >(GetMaturixProjectsDocument, options)
}
export type GetMaturixProjectsQueryHookResult = ReturnType<
  typeof useGetMaturixProjectsQuery
>
export type GetMaturixProjectsLazyQueryHookResult = ReturnType<
  typeof useGetMaturixProjectsLazyQuery
>
export type GetMaturixProjectsQueryResult = Apollo.QueryResult<
  IGetMaturixProjectsQuery,
  IGetMaturixProjectsQueryVariables
>
