import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'

import { Cross } from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { padWithZero } from '~/client/src/shared/stores/ui/ProjectDate.store'
import { range } from '~/client/src/shared/utils/util'

import CircularSelectControl from './CircularSelectControl'

// localization: translated

export interface IProps {
  onApply: (selectedDate: string) => void
  onHide: () => void

  title?: string
  defaultTime?: Date
}

const hoursRange: string[] = range(12, num => padWithZero(num + 1))
const minutesRange: string[] = range(60, num => padWithZero(num))
const timeFormats = ['AM', 'PM']

@observer
export default class TimePicker extends React.Component<IProps> {
  @observable private hours: string
  @observable private minutes: string
  @observable private timeFormat: string

  public componentDidMount() {
    this.initDefaultValues()
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.defaultTime !== this.props.defaultTime) {
      this.initDefaultValues()
    }
  }

  public render() {
    const { title, onHide } = this.props

    const defaultHoursIndex = hoursRange.findIndex(x => x === this.hours)
    const defaultMinutesIndex = minutesRange.findIndex(x => x === this.minutes)
    const defaultTimeFormatIndex = timeFormats.findIndex(
      x => x === this.timeFormat,
    )

    return (
      <div className="timepicker-wrapper absolute-block bt-light-grey bg-white bradt8 px15">
        <div onClick={onHide} className="py10 row">
          <Cross className="cross-icon no-grow lp015" />
          {title && (
            <span className="text center large bold mr30">{title}</span>
          )}
        </div>
        <div className="timepicker row no-flex-children x-center my10">
          <CircularSelectControl
            items={hoursRange}
            defaultItemIndex={defaultHoursIndex}
            onChange={this.setHours}
          />
          <CircularSelectControl
            items={minutesRange}
            defaultItemIndex={defaultMinutesIndex}
            onChange={this.setMinutes}
          />
          <CircularSelectControl
            items={timeFormats}
            defaultItemIndex={defaultTimeFormatIndex}
            onChange={this.setTimeFormat}
          />
          <div className="choice-line absolute bg-palette-brand-lighter brada8 h40 opacity5 inactive-element" />
        </div>
        <div className="row py12">
          <button
            onClick={this.onApplySelection}
            className="save-button h40 ba-transparent brada4 text white large bold lp015 center pointer"
          >
            {Localization.translator.select}
          </button>
        </div>
      </div>
    )
  }

  @action.bound
  private onApplySelection() {
    let hours = this.hours
    if (hours === '12') {
      hours = '00'
    }
    if (this.timeFormat === timeFormats[1]) {
      hours = (Number.parseInt(hours, 10) + 12).toString()
    }

    this.props.onApply(
      `${padWithZero(Number(hours))}:${padWithZero(Number(this.minutes))}`,
    )
  }

  @action.bound
  private initDefaultValues() {
    const { defaultTime } = this.props
    if (!defaultTime) {
      this.hours = hoursRange[0]
      this.minutes = minutesRange[0]
      this.timeFormat = timeFormats[0]
      return
    }

    let defaultHour = Number(padWithZero(defaultTime.getHours()))
    this.timeFormat = timeFormats[defaultHour < 12 ? 0 : 1]

    if (!defaultHour) {
      defaultHour = 12
    }

    this.hours = padWithZero(defaultHour <= 12 ? defaultHour : defaultHour - 12)
    this.minutes = padWithZero(defaultTime.getMinutes())
  }

  @action.bound
  private setHours(value: string) {
    this.hours = value
  }

  @action.bound
  private setMinutes(value: string) {
    this.minutes = value
  }

  @action.bound
  private setTimeFormat(value: string) {
    this.timeFormat = value
  }
}
