import React from 'react'

import { observer } from 'mobx-react'

interface IProps {
  isAnyPopupOpened?: boolean
}

@observer
export default class OpacityBackground extends React.Component<IProps> {
  public render() {
    return this.props.isAnyPopupOpened ? (
      <div className="filter-container-background" />
    ) : (
      <div className="no-grow" />
    )
  }
}
