import ts from '@mapbox/timespace'
import { Moment } from 'moment-timezone'

import Localization from '../localization/LocalizationManager'

interface ITimezone {
  offsets: string[]
  getLabel: () => string
  tzCode: string
  similarTimezones?: string[]
}

export const defaultTimezoneId = 'America/Los_Angeles'

export const timezones: ITimezone[] = [
  {
    offsets: ['-11:00'],
    getLabel: () => Localization.translator.timezoneValues.niue, // no dst
    tzCode: 'Pacific/Niue',
  },
  {
    offsets: ['-11:00'],
    getLabel: () => Localization.translator.timezoneValues.samoa, // no dst
    tzCode: 'Pacific/Pago_Pago',
    similarTimezones: ['Pacific/Midway'],
  },

  {
    offsets: ['-10:00'],
    getLabel: () => Localization.translator.timezoneValues.hawaiiAleuntian, // no dst
    tzCode: 'Pacific/Honolulu',
    similarTimezones: ['Pacific/Johnston'],
  },
  {
    offsets: ['-10:00', '-9:00'],
    getLabel: () => Localization.translator.timezoneValues.hawaiiAleuntian, // has dst, Hawaii-Aleutian Time (Adak)
    tzCode: 'America/Adak',
  },
  {
    offsets: ['-10:00'],
    getLabel: () => Localization.translator.timezoneValues.cookIsland, // no dst
    tzCode: 'Pacific/Rarotonga',
  },
  {
    offsets: ['-10:00'],
    getLabel: () => Localization.translator.timezoneValues.tahiti, // no dst
    tzCode: 'Pacific/Tahiti',
  },

  {
    offsets: ['-9:30'],
    getLabel: () => Localization.translator.timezoneValues.marquesas, // no dst
    tzCode: 'Pacific/Marquesas',
  },

  {
    offsets: ['-9:00', '-8:00'],
    getLabel: () => Localization.translator.timezoneValues.alaska, // has dst
    tzCode: 'America/Anchorage',
    similarTimezones: [
      'America/Nome',
      'America/Juneau',
      'America/Yakutat',
      'America/Sitka',
    ],
  },
  {
    offsets: ['-9:00'],
    getLabel: () => Localization.translator.timezoneValues.gambier, // no dst
    tzCode: 'Pacific/Gambier',
  },

  {
    offsets: ['-8:00', '-7:00'],
    getLabel: () => Localization.translator.timezoneValues.pacific, // has dst
    tzCode: 'America/Los_Angeles',
    similarTimezones: [
      'America/Tijuana',
      'America/Vancouver',
      'America/Whitehorse',
    ],
  },
  {
    offsets: ['-8:00'],
    getLabel: () => Localization.translator.timezoneValues.pitcairn, // no dst
    tzCode: 'Pacific/Pitcairn',
  },

  {
    offsets: ['-7:00'],
    getLabel: () => Localization.translator.timezoneValues.mountain, // no dst
    tzCode: 'America/Phoenix',
    similarTimezones: ['America/Fort_Nelson', 'America/Dawson_Creek'],
  },
  {
    offsets: ['-7:00', '-6:00'],
    getLabel: () => Localization.translator.timezoneValues.mountain, // has dst
    tzCode: 'America/Denver',
    similarTimezones: [
      'America/Boise',
      'America/Cambridge_Bay',
      'America/Ojinaga',
      'America/Yellowknife',
      'America/Edmonton',
    ],
  },
  {
    offsets: ['-7:00'],
    getLabel: () => Localization.translator.timezoneValues.mexicanPacific, // no dst
    tzCode: 'America/Hermosillo',
  },
  {
    offsets: ['-7:00', '-6:00'],
    getLabel: () => Localization.translator.timezoneValues.mexicanPacific, // has dst
    tzCode: 'America/Mazatlan',
    similarTimezones: ['America/Chihuahua'],
  },

  {
    offsets: ['-6:00'],
    getLabel: () => Localization.translator.timezoneValues.centralStandard, // no dst
    tzCode: 'America/Belize',
    similarTimezones: [
      'America/Guatemala',
      'America/Tegucigalpa',
      'America/Regina',
      'America/Managua',
      'America/Costa_Rica',
      'America/El_Salvador',
    ],
  },
  {
    offsets: ['-6:00'],
    getLabel: () => Localization.translator.timezoneValues.galapagos, // no dst
    tzCode: 'Pacific/Galapagos',
  },
  {
    offsets: ['-6:00', '-5:00'],
    getLabel: () => Localization.translator.timezoneValues.centralStandard, // has dst
    tzCode: 'America/Chicago',
    similarTimezones: [
      'America/Winnipeg',
      'America/Rankin_Inlet',
      'America/Matamoros',
      'America/Resolute',
    ],
  },
  {
    offsets: ['-6:00', '-5:00'],
    getLabel: () =>
      Localization.translator.timezoneValues.centralMexicoStandard, // has dst
    tzCode: 'America/Mexico_City',
    similarTimezones: ['America/Merida', 'America/Monterrey'],
  },
  {
    offsets: ['-6:00', '-5:00'],
    getLabel: () => Localization.translator.timezoneValues.easterIsland,
    tzCode: 'Pacific/Easter',
  },

  {
    offsets: ['-5:00'],
    getLabel: () => Localization.translator.timezoneValues.acre, // no dst
    tzCode: 'America/Rio_Branco',
    similarTimezones: ['America/Eirunepe'],
  },
  {
    offsets: ['-5:00'],
    getLabel: () => Localization.translator.timezoneValues.colombia, // no dst
    tzCode: 'America/Bogota',
  },
  {
    offsets: ['-5:00'],
    getLabel: () => Localization.translator.timezoneValues.easternStandard, // no dst
    tzCode: 'America/Cancun',
    similarTimezones: [
      'America/Atikokan',
      'America/Panama',
      'America/Cayman',
      'America/Coral_Harbour',
      'America/Jamaica',
    ],
  },
  {
    offsets: ['-5:00'],
    getLabel: () => Localization.translator.timezoneValues.ecuador, // no dst
    tzCode: 'America/Guayaquil',
  },
  {
    offsets: ['-5:00'],
    getLabel: () => Localization.translator.timezoneValues.peru, // no dst
    tzCode: 'America/Lima',
  },
  {
    offsets: ['-5:00', '-4:00'],
    getLabel: () => Localization.translator.timezoneValues.easternStandard, // has dst
    tzCode: 'America/New_York',
    similarTimezones: [
      'America/Toronto',
      'America/Grand_Turk',
      'America/Montreal',
      'America/Detroit',
      'America/Indiana/Indianapolis',
      'America/Iqaluit',
      'America/Pangnirtung',
      'America/Nassau',
      'America/Port-au-Prince',
    ],
  },
  {
    offsets: ['-5:00', '-4:00'],
    getLabel: () => Localization.translator.timezoneValues.cuba, // has dst
    tzCode: 'America/Havana',
  },

  {
    offsets: ['-4:00'],
    getLabel: () => Localization.translator.timezoneValues.amazon, // no dst
    tzCode: 'America/Boa_Vista',
    similarTimezones: ['America/Manaus', 'America/Porto_Velho'],
  },
  {
    offsets: ['-4:00'],
    getLabel: () => Localization.translator.timezoneValues.atlantic, // no dst
    tzCode: 'America/Puerto_Rico',
    similarTimezones: [
      'America/Tortola',
      'America/Anguilla',
      'America/St_Kitts',
      'America/Antigua',
      'America/Aruba',
      'America/Barbados',
      'America/Blanc-Sablon',
      'America/Port_of_Spain',
      'America/Guadeloupe',
      'America/Martinique',
      'America/St_Vincent',
      'America/Grenada',
      'America/St_Thomas',
      'America/Santo_Domingo',
      'America/Curacao',
    ],
  },
  {
    offsets: ['-4:00'],
    getLabel: () => Localization.translator.timezoneValues.bolivia, // no dst
    tzCode: 'America/La_Paz',
  },
  {
    offsets: ['-4:00'],
    getLabel: () => Localization.translator.timezoneValues.guyana, // no dst
    tzCode: 'America/Guyana',
  },
  {
    offsets: ['-4:00'],
    getLabel: () => Localization.translator.timezoneValues.venezuela, // no dst
    tzCode: 'America/Caracas',
  },
  {
    offsets: ['-4:00', '-3:00'],
    getLabel: () => Localization.translator.timezoneValues.atlantic, // has dst
    tzCode: 'America/Goose_Bay',
    similarTimezones: [
      'America/Halifax',
      'America/Glace_Bay',
      'America/Thule',
      'America/Moncton',
      'Atlantic/Bermuda',
    ],
  },
  {
    offsets: ['-4:00', '-3:00'],
    getLabel: () => Localization.translator.timezoneValues.amazon, // has dst
    tzCode: 'America/Campo_Grande',
    similarTimezones: ['America/Cuiaba'],
  },
  {
    offsets: ['-4:00', '-3:00'],
    getLabel: () => Localization.translator.timezoneValues.chile, // has dst
    tzCode: 'America/Santiago',
  },
  {
    offsets: ['-4:00', '-3:00'],
    getLabel: () => Localization.translator.timezoneValues.paraguay, // has dst
    tzCode: 'America/Asuncion',
  },

  {
    offsets: ['-3:30', '-2:30'],
    getLabel: () => Localization.translator.timezoneValues.newfoundland, // has dst
    tzCode: 'America/St_Johns',
  },

  {
    offsets: ['-3:00'],
    getLabel: () => Localization.translator.timezoneValues.argentina, // no dst
    tzCode: 'America/Argentina/Buenos_Aires',
    similarTimezones: [
      'America/Argentina/Cordoba',
      'America/Argentina/Salta',
      'America/Argentina/Catamarca',
      'America/Argentina/Tucuman',
      'America/Argentina/La_Rioja',
      'America/Argentina/San_Luis',
      'America/Argentina/Jujuy',
      'America/Argentina/San_Juan',
      'America/Argentina/Mendoza',
      'America/Argentina/Rio_Gallegos',
      'America/Argentina/Ushuaia',
    ],
  },
  {
    offsets: ['-3:00'],
    getLabel: () => Localization.translator.timezoneValues.brasilia, // no dst
    tzCode: 'America/Araguaina',
    similarTimezones: [
      'America/Belem',
      'America/Fortaleza',
      'America/Bahia',
      'America/Maceio',
      'America/Santarem',
      'America/Recife',
    ],
  },
  {
    offsets: ['-3:00'],
    getLabel: () => Localization.translator.timezoneValues.falkland, // no dst
    tzCode: 'Atlantic/Stanley',
  },
  {
    offsets: ['-3:00'],
    getLabel: () => Localization.translator.timezoneValues.frenchGuiana, // no dst
    tzCode: 'America/Cayenne',
  },
  {
    offsets: ['-3:00'],
    getLabel: () => Localization.translator.timezoneValues.palmer, // no dst
    tzCode: 'Antarctica/Palmer',
  },
  {
    offsets: ['-3:00'],
    getLabel: () => Localization.translator.timezoneValues.puntaArenas, // no dst,
    tzCode: 'America/Punta_Arenas',
  },
  {
    offsets: ['-3:00'],
    getLabel: () => Localization.translator.timezoneValues.rothera, // no dst,
    tzCode: 'Antarctica/Rothera',
  },
  {
    offsets: ['-3:00'],
    getLabel: () => Localization.translator.timezoneValues.suriname, // no dst,
    tzCode: 'America/Paramaribo',
  },
  {
    offsets: ['-3:00'],
    getLabel: () => Localization.translator.timezoneValues.uruguay, // no dst,
    tzCode: 'America/Montevideo',
  },
  {
    offsets: ['-3:00', '-2:00'],
    getLabel: () => Localization.translator.timezoneValues.pierre, // has dst,
    tzCode: 'America/Miquelon',
  },
  {
    offsets: ['-3:00', '-2:00'],
    getLabel: () => Localization.translator.timezoneValues.westGreenland, // has dst,
    tzCode: 'America/Godthab',
  },
  {
    offsets: ['-3:00', '-2:00'],
    getLabel: () => Localization.translator.timezoneValues.brasilia, // has dst
    tzCode: 'America/Sao_Paulo',
  },

  {
    offsets: ['-2:00'],
    getLabel: () => Localization.translator.timezoneValues.fernandoDeNoronha, // no dst
    tzCode: 'America/Noronha',
  },
  {
    offsets: ['-2:00'],
    getLabel: () => Localization.translator.timezoneValues.southGeorgia, // no dst
    tzCode: 'Atlantic/South_Georgia',
  },

  {
    offsets: ['-1:00', '0:00'],
    getLabel: () => Localization.translator.timezoneValues.azores, // has dst
    tzCode: 'Atlantic/Azores',
  },
  {
    offsets: ['-1:00'],
    getLabel: () => Localization.translator.timezoneValues.capeVerde, // no dst
    tzCode: 'Atlantic/Cape_Verde',
  },
  {
    offsets: ['-1:00', '0:00'],
    getLabel: () => Localization.translator.timezoneValues.eastGreenland, // has dst
    tzCode: 'America/Scoresbysund',
  },

  {
    offsets: ['0:00'],
    getLabel: () => Localization.translator.timezoneValues.coordinatedUniversal, // no dst
    tzCode: 'Etc/GMT',
  },
  {
    offsets: ['0:00'],
    getLabel: () => Localization.translator.timezoneValues.greenwichMean, // no dst
    tzCode: 'Africa/Abidjan',
    similarTimezones: [
      'Africa/Bamako',
      'Africa/Ouagadougou',
      'Africa/Accra',
      'Africa/Conakry',
      'Africa/Monrovia',
      'Africa/Lome',
      'Africa/Nouakchott',
      'Africa/Dakar',
      'Africa/Bissau',
      'Africa/Freetown',
      'America/Danmarkshavn',
      'Atlantic/Reykjavik',
      'Atlantic/St_Helena',
    ],
  },
  {
    offsets: ['0:00', '1:00'],
    getLabel: () => Localization.translator.timezoneValues.greenwichMean, // has dst
    tzCode: 'Europe/London',
    similarTimezones: ['Europe/Isle_of_Man', 'Europe/Dublin'],
  },
  {
    offsets: ['0:00', '1:00'],
    getLabel: () => Localization.translator.timezoneValues.greenwichMeanSaoTome, // has dst
    tzCode: 'Africa/Sao_Tome',
  },
  {
    offsets: ['0:00', '1:00'],
    getLabel: () => Localization.translator.timezoneValues.westernEuropean, // has dst
    tzCode: 'Europe/Lisbon',
    similarTimezones: ['Atlantic/Canary', 'Atlantic/Faroe', 'Atlantic/Madeira'],
  },
  {
    offsets: ['0:00', '1:00'],
    getLabel: () =>
      Localization.translator.timezoneValues.westernEuropeanAfrica, // has dst
    tzCode: 'Africa/Casablanca',
    similarTimezones: ['Africa/El_Aaiun'],
  },

  {
    offsets: ['1:00'],
    getLabel: () => Localization.translator.timezoneValues.centralEuropean, // no dst
    tzCode: 'Africa/Algiers',
    similarTimezones: ['Africa/Tunis'],
  },
  {
    offsets: ['1:00'],
    getLabel: () => Localization.translator.timezoneValues.westAfrica, // no dst
    tzCode: 'Africa/Niamey',
    similarTimezones: [
      'Africa/Bangui',
      'Africa/Ndjamena',
      'Africa/Douala',
      'Africa/Brazzaville',
      'Africa/Kinshasa',
      'Africa/Libreville',
      'Africa/Lagos',
      'Africa/Malabo',
      'Africa/Luanda',
      'Africa/Porto-Novo',
    ],
  },
  {
    offsets: ['1:00', '2:00'],
    getLabel: () => Localization.translator.timezoneValues.centralEuropean, // has dst
    tzCode: 'Europe/Rome',
    similarTimezones: [
      'Europe/Madrid',
      'Arctic/Longyearbyen',
      'Europe/Amsterdam',
      'Europe/Berlin',
      'Europe/Brussels',
      'Europe/Paris',
      'Europe/Skopje',
      'Europe/Tirane',
      'Europe/Belgrade',
      'Europe/Budapest',
      'Europe/Sarajevo',
      'Europe/Copenhagen',
      'Europe/Warsaw',
      'Europe/Prague',
      'Europe/Zurich',
      'Europe/Vienna',
      'Europe/Bratislava',
      'Europe/Ljubljana',
      'Europe/Zagreb',
      'Europe/Stockholm',
      'Europe/Oslo',
      'Europe/Malta',
      'Europe/Podgorica',
      'Europe/Andorra',
      'Africa/Ceuta',
      'Europe/Gibraltar',
      'Europe/Luxembourg',
      'Europe/Monaco',
    ],
  },

  {
    offsets: ['2:00'],
    getLabel: () => Localization.translator.timezoneValues.centralAfricaTime, // no dst
    tzCode: 'Africa/Khartoum',
    similarTimezones: [
      'Africa/Lubumbashi',
      'Africa/Lusaka',
      'Africa/Blantyre',
      'Africa/Maputo',
      'Africa/Kigali',
      'Africa/Bujumbura',
      'Africa/Gaborone',
      'Africa/Windhoek',
      'Africa/Harare',
    ],
  },
  {
    offsets: ['2:00', '3:00'],
    getLabel: () => Localization.translator.timezoneValues.centralAfricaTime, // has dst
    tzCode: 'Africa/Juba',
  },
  {
    offsets: ['2:00'],
    getLabel: () => Localization.translator.timezoneValues.southAfrica, // no dst
    tzCode: 'Africa/Johannesburg',
    similarTimezones: ['Africa/Mbabane', 'Africa/Maseru'],
  },
  {
    offsets: ['2:00', '3:00'],
    getLabel: () => Localization.translator.timezoneValues.israel, // has dst
    tzCode: 'Asia/Jerusalem',
  },
  {
    offsets: ['2:00'],
    getLabel: () => Localization.translator.timezoneValues.easternEuropean, // no dst
    tzCode: 'Africa/Tripoli',
    similarTimezones: ['Africa/Cairo', 'Europe/Kaliningrad'],
  },
  {
    offsets: ['2:00', '3:00'],
    getLabel: () => Localization.translator.timezoneValues.easternEuropean, // has dst
    tzCode: 'Europe/Kiev',
    similarTimezones: [
      'Europe/Simferopol',
      'Asia/Nicosia',
      'Europe/Athens',
      'Europe/Sofia',
      'Europe/Uzhgorod',
      'Europe/Bucharest',
      'Europe/Chisinau',
      'Europe/Helsinki',
      'Europe/Mariehamn',
      'Europe/Tallinn',
      'Europe/Vilnius',
      'Europe/Zaporozhye',
      'Europe/Riga',
    ],
  },
  {
    offsets: ['2:00', '3:00'],
    getLabel: () =>
      Localization.translator.timezoneValues.easternEuropeanBeirutGaza, // has dst
    tzCode: 'Asia/Beirut',
    similarTimezones: ['Asia/Gaza'],
  },
  {
    offsets: ['2:00', '3:00'],
    getLabel: () =>
      Localization.translator.timezoneValues.easternEuropeanDamascusAmman, // has dst
    tzCode: 'Asia/Damascus',
    similarTimezones: ['Asia/Amman'],
  },

  {
    offsets: ['3:00'],
    getLabel: () => Localization.translator.timezoneValues.moscow, // no dst
    tzCode: 'Europe/Moscow',
    similarTimezones: ['Europe/Kirov', 'Europe/Minsk'],
  },
  {
    offsets: ['3:00'],
    getLabel: () => Localization.translator.timezoneValues.arabian, // no dst
    tzCode: 'Asia/Aden',
    similarTimezones: [
      'Asia/Riyadh',
      'Asia/Baghdad',
      'Asia/Qatar',
      'Asia/Kuwait',
    ],
  },
  {
    offsets: ['3:00'],
    getLabel: () => Localization.translator.timezoneValues.eastAfrica, // no dst
    tzCode: 'Africa/Addis_Ababa',
    similarTimezones: [
      'Africa/Asmara',
      'Africa/Djibouti',
      'Africa/Mogadishu',
      'Africa/Nairobi',
      'Africa/Dar_es_Salaam',
      'Africa/Kampala',
      'Indian/Antananarivo',
      'Indian/Comoro',
      'Indian/Mayotte',
    ],
  },
  {
    offsets: ['3:00'],
    getLabel: () => Localization.translator.timezoneValues.turkey, // no dst
    tzCode: 'Europe/Istanbul',
  },
  {
    offsets: ['3:00'],
    getLabel: () => Localization.translator.timezoneValues.syowa, // no dst
    tzCode: 'Antarctica/Syowa',
  },
  {
    offsets: ['3:00', '4:00'],
    getLabel: () => Localization.translator.timezoneValues.volgograd, // has dst
    tzCode: 'Europe/Volgograd',
  },

  {
    offsets: ['3:30', '4:30'],
    getLabel: () => Localization.translator.timezoneValues.iran, // has dst
    tzCode: 'Asia/Tehran',
  },

  {
    offsets: ['4:00'],
    getLabel: () => Localization.translator.timezoneValues.armenia, // no dst
    tzCode: 'Asia/Yerevan',
  },
  {
    offsets: ['4:00'],
    getLabel: () => Localization.translator.timezoneValues.azerbaijan, // no dst
    tzCode: 'Asia/Baku',
  },
  {
    offsets: ['4:00'],
    getLabel: () => Localization.translator.timezoneValues.astrakhan, // no dst
    tzCode: 'Europe/Astrakhan',
  },
  {
    offsets: ['4:00'],
    getLabel: () => Localization.translator.timezoneValues.ulyanovsk, // no dst
    tzCode: 'Europe/Ulyanovsk',
  },
  {
    offsets: ['4:00'],
    getLabel: () => Localization.translator.timezoneValues.georgia, // no dst
    tzCode: 'Asia/Tbilisi',
  },
  {
    offsets: ['4:00'],
    getLabel: () => Localization.translator.timezoneValues.gulf, // no dst
    tzCode: 'Asia/Dubai',
    similarTimezones: ['Asia/Muscat'],
  },
  {
    offsets: ['4:00'],
    getLabel: () => Localization.translator.timezoneValues.mauritius, // no dst
    tzCode: 'Indian/Mauritius',
  },
  {
    offsets: ['4:00'],
    getLabel: () => Localization.translator.timezoneValues.reunion, // no dst
    tzCode: 'Indian/Reunion',
  },
  {
    offsets: ['4:00'],
    getLabel: () => Localization.translator.timezoneValues.samara, // no dst
    tzCode: 'Europe/Samara',
  },
  {
    offsets: ['4:00'],
    getLabel: () => Localization.translator.timezoneValues.seychelles, // no dst
    tzCode: 'Indian/Mahe',
  },

  {
    offsets: ['4:30'],
    getLabel: () => Localization.translator.timezoneValues.afghanistan, // no dst
    tzCode: 'Asia/Kabul',
  },

  {
    offsets: ['5:00'],
    getLabel: () => Localization.translator.timezoneValues.frenchSouthern, // no dst
    tzCode: 'Indian/Kerguelen',
  },
  {
    offsets: ['5:00'],
    getLabel: () => Localization.translator.timezoneValues.maldives, // no dst
    tzCode: 'Indian/Maldives',
  },
  {
    offsets: ['5:00'],
    getLabel: () => Localization.translator.timezoneValues.mawson, // no dst
    tzCode: 'Antarctica/Mawson',
  },
  {
    offsets: ['5:00'],
    getLabel: () => Localization.translator.timezoneValues.pakistan, // no dst
    tzCode: 'Asia/Karachi',
  },
  {
    offsets: ['5:00'],
    getLabel: () => Localization.translator.timezoneValues.tajikistan, // no dst
    tzCode: 'Asia/Dushanbe',
  },
  {
    offsets: ['5:00'],
    getLabel: () => Localization.translator.timezoneValues.turkmenistan, // no dst
    tzCode: 'Asia/Ashgabat',
  },
  {
    offsets: ['5:00'],
    getLabel: () => Localization.translator.timezoneValues.uzbekistan, // no dst
    tzCode: 'Asia/Tashkent',
    similarTimezones: ['Asia/Samarkand'],
  },
  {
    offsets: ['5:00'],
    getLabel: () => Localization.translator.timezoneValues.westKazakhstan, // no dst
    tzCode: 'Asia/Aqtobe',
    similarTimezones: ['Asia/Aqtau', 'Asia/Oral'],
  },
  {
    offsets: ['5:00', '6:00'],
    getLabel: () => Localization.translator.timezoneValues.westKazakhstan, // has dst
    tzCode: 'Asia/Qyzylorda',
  },
  {
    offsets: ['5:00'],
    getLabel: () => Localization.translator.timezoneValues.yekaterinburg, // no dst
    tzCode: 'Asia/Yekaterinburg',
  },

  {
    offsets: ['5:30'],
    getLabel: () => Localization.translator.timezoneValues.india, // no dst
    tzCode: 'Asia/Kolkata',
    similarTimezones: ['Asia/Colombo'],
  },

  {
    offsets: ['5:45'],
    getLabel: () => Localization.translator.timezoneValues.nepal, // no dst
    tzCode: 'Asia/Kathmandu',
  },

  {
    offsets: ['6:00'],
    getLabel: () => Localization.translator.timezoneValues.vostok, // no dst
    tzCode: 'Antarctica/Vostok',
  },
  {
    offsets: ['6:00'],
    getLabel: () => Localization.translator.timezoneValues.omsk, // no dst
    tzCode: 'Asia/Omsk',
  },
  {
    offsets: ['6:00'],
    getLabel: () => Localization.translator.timezoneValues.kyrgyzstan, // no dst
    tzCode: 'Asia/Bishkek',
  },
  {
    offsets: ['6:00'],
    getLabel: () => Localization.translator.timezoneValues.indianOcean, // no dst
    tzCode: 'Indian/Chagos',
  },
  {
    offsets: ['6:00'],
    getLabel: () => Localization.translator.timezoneValues.urumqiKashgar, // no dst
    tzCode: 'Asia/Urumqi',
    similarTimezones: ['Asia/Kashgar'],
  },
  {
    offsets: ['6:00'],
    getLabel: () => Localization.translator.timezoneValues.eastKazakhstan, // no dst
    tzCode: 'Asia/Almaty',
  },
  {
    offsets: ['6:00'],
    getLabel: () => Localization.translator.timezoneValues.bhutan, // no dst
    tzCode: 'Asia/Thimphu',
  },
  {
    offsets: ['6:00'],
    getLabel: () => Localization.translator.timezoneValues.bangladesh, // no dst
    tzCode: 'Asia/Dhaka',
  },

  {
    offsets: ['6:30'],
    getLabel: () => Localization.translator.timezoneValues.cocosIsland, // no dst
    tzCode: 'Indian/Cocos',
  },
  {
    offsets: ['6:30'],
    getLabel: () => Localization.translator.timezoneValues.myanmar, // no dst
    tzCode: 'Asia/Yangon',
    similarTimezones: ['Asia/Rangoon'],
  },

  {
    offsets: ['7:00'],
    getLabel: () => Localization.translator.timezoneValues.christmasIsland, // no dst
    tzCode: 'Indian/Christmas',
  },
  {
    offsets: ['7:00'],
    getLabel: () => Localization.translator.timezoneValues.davis, // no dst
    tzCode: 'Antarctica/Davis',
  },
  {
    offsets: ['7:00'],
    getLabel: () => Localization.translator.timezoneValues.barnaulTomsk, // no dst
    tzCode: 'Asia/Barnaul',
    similarTimezones: ['Asia/Tomsk'],
  },
  {
    offsets: ['7:00'],
    getLabel: () => Localization.translator.timezoneValues.hovd, // no dst
    tzCode: 'Asia/Hovd',
  },
  {
    offsets: ['7:00'],
    getLabel: () => Localization.translator.timezoneValues.indochina, // no dst
    tzCode: 'Asia/Bangkok',
    similarTimezones: [
      'Asia/Vientiane',
      'Asia/Phnom_Penh',
      'Asia/Ho_Chi_Minh',
      'Asia/Saigon',
    ],
  },
  {
    offsets: ['7:00'],
    getLabel: () => Localization.translator.timezoneValues.krasnoyarsk, // no dst
    tzCode: 'Asia/Krasnoyarsk',
    similarTimezones: ['Asia/Novokuznetsk'],
  },
  {
    offsets: ['7:00'],
    getLabel: () => Localization.translator.timezoneValues.novosibirsk, // no dst
    tzCode: 'Asia/Novosibirsk',
  },
  {
    offsets: ['7:00'],
    getLabel: () => Localization.translator.timezoneValues.westernIndonesia, // no dst
    tzCode: 'Asia/Jakarta',
    similarTimezones: ['Asia/Pontianak'],
  },

  {
    offsets: ['8:00'],
    getLabel: () => Localization.translator.timezoneValues.australianWestern, // no dst
    tzCode: 'Australia/Perth',
  },
  {
    offsets: ['8:00'],
    getLabel: () => Localization.translator.timezoneValues.bruneiDarussalam, // no dst
    tzCode: 'Asia/Brunei',
  },
  {
    offsets: ['8:00'],
    getLabel: () => Localization.translator.timezoneValues.centralIndonesia, // no dst
    tzCode: 'Asia/Makassar',
  },
  {
    offsets: ['8:00'],
    getLabel: () => Localization.translator.timezoneValues.chinaStandard, // no dst
    tzCode: 'Asia/Shanghai',
    similarTimezones: ['Asia/Harbin', 'Asia/Chongqing', 'Asia/Macau'],
  },
  {
    offsets: ['8:00'],
    getLabel: () => Localization.translator.timezoneValues.hongKong, // no dst
    tzCode: 'Asia/Hong_Kong',
  },
  {
    offsets: ['8:00'],
    getLabel: () => Localization.translator.timezoneValues.irkutsk, // no dst
    tzCode: 'Asia/Irkutsk',
  },
  {
    offsets: ['8:00'],
    getLabel: () => Localization.translator.timezoneValues.malaysia, // no dst
    tzCode: 'Asia/Kuala_Lumpur',
    similarTimezones: ['Asia/Kuching'],
  },
  {
    offsets: ['8:00'],
    getLabel: () => Localization.translator.timezoneValues.philippine, // no dst
    tzCode: 'Asia/Manila',
  },
  {
    offsets: ['8:00'],
    getLabel: () => Localization.translator.timezoneValues.singapore, // no dst
    tzCode: 'Asia/Singapore',
  },
  {
    offsets: ['8:00'],
    getLabel: () => Localization.translator.timezoneValues.taipei, // no dst
    tzCode: 'Asia/Taipei',
  },
  {
    offsets: ['8:00'],
    getLabel: () => Localization.translator.timezoneValues.ulaanbaatar, // no dst
    tzCode: 'Asia/Ulaanbaatar',
    similarTimezones: ['Asia/Choibalsan'],
  },

  {
    offsets: ['8:30', '9:00'],
    getLabel: () => Localization.translator.timezoneValues.pyongyang, // has dst
    tzCode: 'Asia/Pyongyang',
  },

  {
    offsets: ['8:45'],
    getLabel: () =>
      Localization.translator.timezoneValues.australianCentralWestern, // no dst
    tzCode: 'Australia/Eucla',
  },

  {
    offsets: ['9:00'],
    getLabel: () => Localization.translator.timezoneValues.eastTimor, // no dst
    tzCode: 'Asia/Dili',
  },
  {
    offsets: ['9:00'],
    getLabel: () => Localization.translator.timezoneValues.easternIndonesia, // no dst
    tzCode: 'Asia/Jayapura',
  },
  {
    offsets: ['9:00'],
    getLabel: () => Localization.translator.timezoneValues.japan, // no dst
    tzCode: 'Asia/Tokyo',
  },
  {
    offsets: ['9:00'],
    getLabel: () => Localization.translator.timezoneValues.korea, // no dst
    tzCode: 'Asia/Seoul',
  },
  {
    offsets: ['9:00'],
    getLabel: () => Localization.translator.timezoneValues.palau, // no dst
    tzCode: 'Pacific/Palau',
  },
  {
    offsets: ['9:00'],
    getLabel: () => Localization.translator.timezoneValues.yakutsk, // no dst
    tzCode: 'Asia/Yakutsk',
    similarTimezones: ['Asia/Chita', 'Asia/Khandyga'],
  },

  {
    offsets: ['9:30'],
    getLabel: () =>
      Localization.translator.timezoneValues.australianCentralStandard, // no dst
    tzCode: 'Australia/Darwin',
  },
  {
    offsets: ['9:30', '10:30'],
    getLabel: () =>
      Localization.translator.timezoneValues.australianCentralStandard, // has dst
    tzCode: 'Australia/Adelaide',
  },

  {
    offsets: ['10:00'],
    getLabel: () =>
      Localization.translator.timezoneValues.australianEasternStandard, // no dst
    tzCode: 'Australia/Brisbane',
  },
  {
    offsets: ['10:00'],
    getLabel: () => Localization.translator.timezoneValues.chamorro, // no dst
    tzCode: 'Pacific/Guam',
    similarTimezones: ['Pacific/Saipan'],
  },
  {
    offsets: ['10:00'],
    getLabel: () => Localization.translator.timezoneValues.chuuk, // no dst
    tzCode: 'Pacific/Chuuk',
    similarTimezones: ['Pacific/Yap'],
  },
  {
    offsets: ['10:00'],
    getLabel: () => Localization.translator.timezoneValues.dumontdUrville, // no dst
    tzCode: 'Antarctica/DumontDUrville',
  },
  {
    offsets: ['10:00'],
    getLabel: () => Localization.translator.timezoneValues.papuaNewGuinea, // no dst
    tzCode: 'Pacific/Port_Moresby',
  },
  {
    offsets: ['10:00'],
    getLabel: () => Localization.translator.timezoneValues.vladivostok, // no dst
    tzCode: 'Asia/Vladivostok',
    similarTimezones: ['Asia/Ust-Nera'],
  },
  {
    offsets: ['10:00', '11:00'],
    getLabel: () =>
      Localization.translator.timezoneValues.australianEasternStandard, // has dst
    tzCode: 'Australia/Sydney',
    similarTimezones: [
      'Antarctica/Macquarie',
      'Australia/Melbourne',
      'Australia/Currie',
      'Australia/Hobart',
    ],
  },

  {
    offsets: ['10:30', '11:00'],
    getLabel: () => Localization.translator.timezoneValues.lordHowe, // has dst
    tzCode: 'Australia/Lord_Howe',
  },

  {
    offsets: ['11:00'],
    getLabel: () =>
      Localization.translator.timezoneValues.srednekolymskBougainville, // no dst
    tzCode: 'Asia/Srednekolymsk',
    similarTimezones: ['Pacific/Bougainville'],
  },
  {
    offsets: ['11:00'],
    getLabel: () => Localization.translator.timezoneValues.kosrae, // no dst
    tzCode: 'Pacific/Kosrae',
  },
  {
    offsets: ['11:00'],
    getLabel: () => Localization.translator.timezoneValues.magadan, // no dst
    tzCode: 'Asia/Magadan',
  },
  {
    offsets: ['11:00'],
    getLabel: () => Localization.translator.timezoneValues.newCaledonia, // no dst
    tzCode: 'Pacific/Noumea',
  },
  {
    offsets: ['11:00'],
    getLabel: () => Localization.translator.timezoneValues.ponhpei, // no dst
    tzCode: 'Pacific/Pohnpei',
  },
  {
    offsets: ['11:00'],
    getLabel: () => Localization.translator.timezoneValues.sakhalin, // no dst
    tzCode: 'Asia/Sakhalin',
  },
  {
    offsets: ['11:00'],
    getLabel: () => Localization.translator.timezoneValues.solomonIslands, // no dst
    tzCode: 'Pacific/Guadalcanal',
  },
  {
    offsets: ['11:00'],
    getLabel: () => Localization.translator.timezoneValues.vanuatu, // no dst
    tzCode: 'Pacific/Efate',
  },
  {
    offsets: ['11:00'],
    getLabel: () => Localization.translator.timezoneValues.casey, // no dst
    tzCode: 'Antarctica/Casey',
  },
  {
    offsets: ['11:00', '12:00'],
    getLabel: () => Localization.translator.timezoneValues.norfolk, // has dst
    tzCode: 'Pacific/Norfolk',
  },

  {
    offsets: ['12:00'],
    getLabel: () => Localization.translator.timezoneValues.anadyr, // no dst
    tzCode: 'Asia/Anadyr',
  },
  {
    offsets: ['12:00'],
    getLabel: () => Localization.translator.timezoneValues.gilbertIslands, // no dst
    tzCode: 'Pacific/Tarawa',
  },
  {
    offsets: ['12:00'],
    getLabel: () => Localization.translator.timezoneValues.marshallIslands, // no dst
    tzCode: 'Pacific/Kwajalein',
    similarTimezones: ['Pacific/Majuro'],
  },
  {
    offsets: ['12:00'],
    getLabel: () => Localization.translator.timezoneValues.nauru, // no dst
    tzCode: 'Pacific/Nauru',
  },
  {
    offsets: ['12:00'],
    getLabel: () =>
      Localization.translator.timezoneValues.petropavlovskKamchatski, // no dst
    tzCode: 'Asia/Kamchatka',
  },
  {
    offsets: ['12:00'],
    getLabel: () => Localization.translator.timezoneValues.tuvalu, // no dst
    tzCode: 'Pacific/Funafuti',
  },
  {
    offsets: ['12:00'],
    getLabel: () => Localization.translator.timezoneValues.wakeIsland, // no dst
    tzCode: 'Pacific/Wake',
  },
  {
    offsets: ['12:00'],
    getLabel: () => Localization.translator.timezoneValues.wallisAndFutuna, // no dst
    tzCode: 'Pacific/Wallis',
  },
  {
    offsets: ['12:00', '13:00'],
    getLabel: () => Localization.translator.timezoneValues.fiji, // has dst
    tzCode: 'Pacific/Fiji',
  },
  {
    offsets: ['12:00', '13:00'],
    getLabel: () => Localization.translator.timezoneValues.newZealand, // has dst
    tzCode: 'Pacific/Auckland',
  },

  {
    offsets: ['12:45', '13:45'],
    getLabel: () => Localization.translator.timezoneValues.chatham, // has dst
    tzCode: 'Pacific/Chatham',
  },

  {
    offsets: ['13:00'],
    getLabel: () => Localization.translator.timezoneValues.phoenixIslands, // no dst
    tzCode: 'Pacific/Enderbury',
  },
  {
    offsets: ['13:00'],
    getLabel: () => Localization.translator.timezoneValues.tokelau, // no dst
    tzCode: 'Pacific/Fakaofo',
  },
  {
    offsets: ['13:00'],
    getLabel: () => Localization.translator.timezoneValues.tonga, // no dst
    tzCode: 'Pacific/Tongatapu',
  },
  {
    offsets: ['13:00', '14:00'],
    getLabel: () => Localization.translator.timezoneValues.apia, // has dst
    tzCode: 'Pacific/Apia',
  },

  {
    offsets: ['14:00'],
    getLabel: () => Localization.translator.timezoneValues.lineIslands, // no dst
    tzCode: 'Pacific/Kiritimati',
  },
]

const defaultTimezone = timezones.find(t => t.tzCode === defaultTimezoneId)

export function getTimezoneById(timezoneId?: string): ITimezone {
  if (timezoneId) {
    return (
      timezones.find(
        t =>
          t.tzCode === timezoneId || t.similarTimezones?.includes(timezoneId),
      ) || defaultTimezone
    )
  }

  return defaultTimezone
}

export function getTimezoneName(timezone: ITimezone) {
  return Localization.translator.timezoneWithOffsets(
    timezone.getLabel(),
    timezone.offsets,
  )
}

export function getTimezoneShortLabel(timezoneId: string) {
  const timezone = getTimezoneById(timezoneId)
  const label = timezone.getLabel()
  const shortLabel = label.split(': ')[1]
  return shortLabel || label
}

export function getTimezoneIdByCoordinates(lng: number, lat: number) {
  const point = [lng, lat]
  const currentTimezone: Moment = ts.getFuzzyLocalTimeFromPoint(
    Date.now(),
    point,
  )
  return currentTimezone?.tz()
}
