import * as React from 'react'

import ReactPlayer from 'react-player'

interface IProps {
  src: string
}

export default function VideoPlayer({ src }: IProps) {
  return <ReactPlayer url={src} controls />
}
