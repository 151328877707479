import { action, observable } from 'mobx'

import { IAttachment, IThread, UploadingType } from '~/client/graph'
import { TagType } from '~/client/src/shared/enums/TagType'
import Activity from '~/client/src/shared/models/Activity'
import Delivery from '~/client/src/shared/models/Delivery'
import Message from '~/client/src/shared/models/Message'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import { ITag } from '~/client/src/shared/models/Tag'
import Thread from '~/client/src/shared/models/Thread'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import PhotosStore from '~/client/src/shared/stores/domain/Photos.store'
import ThreadsStore from '~/client/src/shared/stores/domain/ThreadsStore/Threads.store'
import Guard from '~/client/src/shared/utils/Guard'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import { MENTION_CHAR } from '../../components/ActionBarInputWIthMentions/ActionBarInputWithMentions.store'
import { ViewMode } from '../../components/DraggableBar/DraggableBar'
import ContentObjectModel from '../../models/ContentObjectModel'
import SitePermit from '../../models/Permit'
import formatBytes from '../../utils/formatBytes'
import BaseFollowingsStore from '../BaseFollowings.store'
import { FileUploadingStore } from '../domain/FileUploading.store'
import TagsStore from '../domain/Tags.store'
import UserProjectsStore from '../domain/UserProjects.store'

export default abstract class BaseActionBarStore {
  @observable public fileImage: File = null
  @observable public isImageUploading: boolean = false
  @observable public isFileUploading: boolean = false
  @observable public draftMessage = EMPTY_STRING
  @observable public shouldShowPreview = false
  @observable public uploadProgress: number = 0
  @observable public isMentionActionActive: boolean = false
  @observable public isMenuPopupShown: boolean = false
  @observable public file: File = null
  @observable public viewMode: ViewMode = ViewMode.Mixed
  @observable public isEmojiOpened: boolean = false
  @observable public isPlusMenuShown: boolean = false

  public mentionTags: ITag[] = []
  public decodedDraftMessage: string = EMPTY_STRING

  public promptForImageRequested = false
  @observable public previewImage: string

  protected _entityId: string = ''

  public constructor(
    protected eventsStore: EventsStore,
    protected threadsStore: ThreadsStore,
    protected messagesStore: MessagesStore,
    protected photosStore: PhotosStore,
    protected fileUploadingStore: FileUploadingStore,
    protected userProjectsStore: UserProjectsStore,
    protected tagsStore: TagsStore,
    protected baseFollowingStore?: BaseFollowingsStore,
  ) {
    Guard.requireAll({
      eventsStore,
      threadsStore,
      messagesStore,
      photosStore,
      fileUploadingStore,
      tagsStore,
    })
  }

  public get entityId(): string {
    return this._entityId
  }

  public set entityId(entityId: string) {
    this._entityId = entityId
  }

  @action.bound
  public setDraftMessage = (
    value: string,
    plainText: string,
    mentionTags: ITag[],
  ) => {
    this.draftMessage = value
    this.decodedDraftMessage = plainText
    this.mentionTags = [...new Set(mentionTags || [])]
  }

  @action.bound
  public hidePreview() {
    this.shouldShowPreview = false
    this.resetPreviewImage()
  }

  @action.bound
  public triggerMentionActionActivation() {
    this.draftMessage += this.draftMessage ? ` ${MENTION_CHAR}` : MENTION_CHAR
  }

  @action.bound
  public activateMentionAction() {
    this.isMentionActionActive = true
  }

  @action.bound
  public deactivateMentionAction() {
    this.isMentionActionActive = false
  }

  @action.bound
  public fullReset() {
    this.reset()
    this.closeImagePreview()
    this.resetPreviewImage()
  }

  public get fileImageCopy() {
    return this.getFileCopy(this.fileImage)
  }

  public get isEmptyMessage(): boolean {
    return !this.draftMessage && !this.fileImage
  }

  public setPreviewImageFromInput(fileInput: HTMLInputElement): any {
    this.fileImage = fileInput.files[0]
    if (!this.fileImage) {
      return
    }

    const reader = new FileReader()

    reader.readAsDataURL(this.fileImage)

    reader.onload = () => {
      this.previewImage = reader.result as string
      this.shouldShowPreview = true
    }
  }

  @action.bound
  public resetPreviewImage() {
    this.previewImage = null
    this.fileImage = null
  }

  @action.bound
  public resetFile() {
    this.file = null
  }

  @action.bound
  public hideMenuPopup() {
    this.isMenuPopupShown = false
  }

  public selectViewMode = (mode: ViewMode) => {
    if (this.viewMode !== mode) {
      this.viewMode = mode
    }
    if (this.viewMode === ViewMode.Closed) {
      this.hideMenuPopup()
    }
  }

  @action.bound
  public showMenuPopup() {
    this.viewMode = ViewMode.Mixed
    this.isMenuPopupShown = true
  }

  public async replyToThread(
    threadEntity:
      | ContentObjectModel<any>
      | Message
      | Delivery
      | StatusUpdate
      | SitePermit,
  ) {
    const fileImage = this.getFileCopy(this.fileImage)
    const file = this.getFileCopy(this.file)

    this.closeImagePreview()
    this.subscribeMentionedUsers()

    const text = (this.decodedDraftMessage || this.draftMessage).trim()
    if (!this.fileImage && !text.length && !file) {
      return
    }

    this.reset()

    let shouldUpdateEntity = false
    if (!threadEntity.threadId) {
      const thread = await this.threadsStore.create()
      threadEntity.setThread(thread)
      shouldUpdateEntity = true
    }

    await this.postMessageWithAttachments(
      text,
      threadEntity.threadId,
      fileImage,
      null,
      file,
    )
    if (shouldUpdateEntity) {
      await this.updateThreadEntity(threadEntity)
    }
  }

  public closeImagePreview() {
    setTimeout(() => {
      this.shouldShowPreview = false
    }, 500)
  }

  public reset(): void {
    this.draftMessage = ''
    this.decodedDraftMessage = ''
  }

  public subscribeMentionedUsers() {
    if (!this.mentionTags.length) {
      return
    }

    const userIds = []

    this.mentionTags.forEach(({ type: type, id }) => {
      if (type === TagType.User) {
        userIds.push(id)
      } else {
        userIds.push(...this.tagsStore.getUsersIdsByTagIds([id]))
      }
    })

    this.baseFollowingStore?.followEntities([this.entityId], userIds)

    this.mentionTags = []
  }

  public uploadAttachment = async ({ target }: any) => {
    const fileToUpload: File = target.files[0]
    target.value = ''
    this.file = fileToUpload
  }

  protected getFileCopy = (file: File) => {
    return file ? new File([file], file.name) : null
  }

  protected async postMessageWithAttachments(
    text: string,
    thread: Thread | IThread | string,
    fileImage: File,
    activity: Activity = null,
    file: File = null,
  ) {
    const {
      activeProject: { id: projectId },
    } = this.eventsStore.appState
    let attachments: IAttachment[] = null

    if (!fileImage && !file) {
      return await this.messagesStore.post(text || '', thread)
    }

    if (file) {
      this.file = null
      this.isFileUploading = true
      const fileType = this.getFileType(file)

      const [result] = await this.fileUploadingStore.uploadFile(
        file,
        fileType,
        file.name,
      )
      this.isFileUploading = false

      attachments = [
        {
          fileName: file.name,
          size: formatBytes(file.size),
          url: result.fileURL,
        },
      ]
    }

    if (fileImage) {
      this.resetPreviewImage()
      const messageDto = await this.messagesStore.post(
        text || '',
        thread,
        attachments,
      )
      const message = new Message(messageDto.id)
      message.updateFromJson(messageDto)
      this.isImageUploading = true
      const photoId = await this.photosStore.post(
        activity,
        message,
        fileImage,
        projectId,
      )
      this.isImageUploading = false

      message.photoId = photoId
      return this.messagesStore.save(message)
    } else {
      return await this.messagesStore.post(text || '', thread, attachments)
    }
  }

  private getFileType(file: File) {
    switch (true) {
      case file.name.endsWith('.docx'):
        return UploadingType.MsWord
      case file.name.endsWith('.pptx'):
        return UploadingType.MsPowerPoint
      case file.name.endsWith('.xlsx'):
        return UploadingType.MsExcel
      case file.name.endsWith('.pdf'):
        return UploadingType.Pdf
      default:
        return UploadingType.Image
    }
  }

  protected abstract updateThreadEntity(
    threadEntity:
      | ContentObjectModel<any>
      | Message
      | Delivery
      | StatusUpdate
      | SitePermit,
  )
}
