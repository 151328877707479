import { enumToList } from '~/client/src/shared/utils/converters'

enum ViewTypes {
  webGantt = 'webGantt',
  webSitemap = 'webSitemap',
  mobile = 'mobile',
  tablet = 'tablet',
}

export const viewTypesList = enumToList(ViewTypes)

export default ViewTypes
