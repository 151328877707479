import * as React from 'react'

import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import InfinityCalendar from '~/client/src/shared/components/CommonDatePicker/InfinityCalendar/InfinityCalendar'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import ProjectDateStore, {
  isAfter,
  isWithinRange,
} from '../../../stores/ui/ProjectDate.store'
import BulkStatusUpdateStore, {
  ALL_COMPANIES,
  StatusUpdateDayStatus,
} from '../BulkStatusUpdate.store'

// translated

const CALENDAR_HEIGHT = 350

interface IProps {
  store: BulkStatusUpdateStore
  projectDateStore?: ProjectDateStore
}

const MONTH_HEIGHT = 350

@inject('projectDateStore')
@observer
export default class BulkStatusUpdateCalendar extends React.Component<IProps> {
  private companiesRow: HTMLDivElement
  private companiesTabs: HTMLDivElement[] = []
  @observable private selectedTabWidth: number = 0
  @observable private companiesBarOffset: number = 0
  @observable private selectedTabOffset: number = 0

  public componentDidUpdate() {
    this.moveSelectedTabToFocus()
  }

  private dateTemplate = (dayDate: Date) => {
    const {
      plannedStartDate,
      plannedEndDate,
      actualStartDate,
      actualEndDate,
      selectedDate,
      getStatusOnDate,
      statusInfoStartDate,
      statusInfoEndDate,
    } = this.props.store

    const { getDayOfMonthToDisplay, isToday, isSameDay, isValidDate } =
      this.props.projectDateStore

    let plannedRangeClassName = ''
    if (
      isValidDate(plannedStartDate) &&
      isValidDate(plannedEndDate) &&
      isWithinRange(dayDate, plannedStartDate, plannedEndDate)
    ) {
      plannedRangeClassName += ' planned-range'
    }
    if (isValidDate(plannedStartDate) && isSameDay(dayDate, plannedStartDate)) {
      plannedRangeClassName += ' planned-range-start'
    }
    if (isValidDate(plannedEndDate) && isSameDay(dayDate, plannedEndDate)) {
      plannedRangeClassName += ' planned-range-end'
    }

    let icon
    let className =
      'date-cell col x-center y-center relative overflow-hidden full-height'

    if (isToday(dayDate)) {
      className += ' p-today'
    }
    if (isSameDay(selectedDate, dayDate)) {
      className += ' selected-date'
    }
    if (
      (isValidDate(actualStartDate) && isSameDay(actualStartDate, dayDate)) ||
      (isValidDate(actualEndDate) && isSameDay(actualEndDate, dayDate))
    ) {
      className += ' actual-date'
    }
    if (this.props.store.isDateApproved(dayDate)) {
      icon = <Icons.CheckFillGreen className="day-status-icon" />
    }
    const shouldShowStatusInfo =
      isAfter(statusInfoEndDate, statusInfoStartDate) &&
      (isWithinRange(dayDate, statusInfoStartDate, statusInfoEndDate) ||
        isSameDay(dayDate, statusInfoStartDate) ||
        isSameDay(dayDate, statusInfoEndDate))

    if (shouldShowStatusInfo && !icon) {
      const dayStatus = getStatusOnDate(dayDate)
      switch (dayStatus) {
        case StatusUpdateDayStatus.Missing:
          icon = <Icons.CheckEmptyRed className="day-status-icon" />
          className += ' missing'
          break
        case StatusUpdateDayStatus.Partial:
          icon = <Icons.CheckEmptyBlue className="day-status-icon" />
          className += ' missing'
          break
        case StatusUpdateDayStatus.Updated:
          icon = <Icons.CheckFillBlue className="day-status-icon" />
          className += ' updated'
          break
      }
    }

    const day = dayDate ? getDayOfMonthToDisplay(dayDate) : ''

    return (
      <div className={className}>
        {day}
        {plannedRangeClassName && <span className={plannedRangeClassName} />}
        {shouldShowStatusInfo && icon}
      </div>
    )
  }

  public render() {
    const {
      selectedDate,
      activityCompanies,
      selectedCompanyIndex,
      isCompanyActive,
    } = this.props.store

    return (
      <div className="bulk-status-update-calendar x-scroll-hidden bb-light-grey">
        <div
          ref={ref => (this.companiesRow = ref)}
          className="relative row bulk-status-update-calendar-companies"
          style={{ left: this.companiesBarOffset }}
        >
          <div
            className="bulk-status-update-calendar-companies-tab full-height row x-center y-center text large bb2-primary-blue primary-blue bold selected"
            style={{
              left: this.selectedTabOffset,
              width: this.selectedTabWidth,
            }}
          />
          {activityCompanies.map((company, index) => {
            return (
              <div
                key={company}
                ref={ref => (this.companiesTabs[index] = ref)}
                className={classList({
                  'bulk-status-update-calendar-companies-tab single-company full-height row x-center y-center text large bb-light-grey':
                    true,
                  'active bb2-primary-blue primary-blue bold':
                    isCompanyActive(company),
                })}
                onClick={this.selectCompany.bind(this, company)}
              >
                {company === ALL_COMPANIES
                  ? Localization.translator.all_items
                  : company}
              </div>
            )
          })}
        </div>
        <div className="row bulk-status-update-calendar-legend pa10">
          <div className="row x-center">
            <div className="no-grow relative planned-range-icon-container">
              <span className="planned-range-icon" />
            </div>
            <div className="no-grow text pl5">
              {Localization.translator.planned}
            </div>
          </div>
          <div className="row x-center">
            <div className="no-grow">
              <span className="actual-dates-icon inline-block" />
            </div>
            <div className="no-grow text pl5">
              {Localization.translator.actual}
            </div>
          </div>
          <div className="row x-center">
            <Icons.CheckFillBlue className="no-grow" />
            <div className="no-grow text pl5 primary-blue">
              {Localization.translator.updated}
            </div>
          </div>
          <div className="row x-center">
            <Icons.CheckFillGreen className="no-grow" />
            <div className="no-grow text pl5 light-green">
              {Localization.translator.approved}
            </div>
          </div>
          <div className="row x-center">
            <Icons.CheckEmptyRed className="no-grow" />
            <div className="no-grow text pl5 red">
              {Localization.translator.missing}
            </div>
          </div>
        </div>
        <div className="row relative x-scroll-hidden calendars-scroller">
          {activityCompanies.map((company, index) => {
            const leftOffset = (index - selectedCompanyIndex) * 100 + '%'
            return (
              <div
                className="calendar-holder full-width"
                style={{ left: leftOffset }}
                key={company}
              >
                <InfinityCalendar
                  months={this.displayedMonths}
                  maxDate={new Date()}
                  monthDate={selectedDate}
                  dateClickHandler={this.onDateChange}
                  height={CALENDAR_HEIGHT}
                  monthHeight={MONTH_HEIGHT}
                  customRenderDayOfMonth={this.dateTemplate}
                  isOneDayMode
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  private moveSelectedTabToFocus() {
    if (!this.companiesRow || !this.companiesTabs.length) {
      this.companiesBarOffset = 0
      this.selectedTabWidth = 0
      this.selectedTabOffset = 0
      return
    }

    const { selectedCompanyIndex } = this.props.store

    let sumWidthOfSelectedTabs = 0
    let selectedTabLeftOffset = 0
    const parentWidth = this.companiesRow.clientWidth
    const selectedTab = this.companiesTabs[selectedCompanyIndex]

    this.companiesTabs.forEach((tab, index) => {
      if (!tab) {
        return
      }
      if (index < selectedCompanyIndex) {
        selectedTabLeftOffset += tab.clientWidth
      }
      if (index < selectedCompanyIndex + 1) {
        sumWidthOfSelectedTabs += tab.clientWidth
      }
      if (index === selectedCompanyIndex + 1) {
        sumWidthOfSelectedTabs += tab.clientWidth / 2
      }
    })

    this.selectedTabWidth = selectedTab ? selectedTab.clientWidth : 0
    this.selectedTabOffset = selectedTabLeftOffset
    this.companiesBarOffset =
      parentWidth < sumWidthOfSelectedTabs
        ? parentWidth - sumWidthOfSelectedTabs
        : 0
  }

  private get displayedMonths(): Date[] {
    const { calendarStartDate, calendarEndDate } = this.props.store
    const now = new Date()
    if (isAfter(calendarStartDate, now)) {
      return [now]
    }
    const monthFrom = calendarStartDate
    const { addMonths, differenceInCalendarMonths } =
      this.props.projectDateStore
    const monthCount = differenceInCalendarMonths(calendarEndDate, monthFrom)

    const month = []
    for (let i = 0; i <= monthCount; i++) {
      month.push(addMonths(monthFrom, i))
    }
    return month
  }

  private onDateChange = (e, date: Date) => {
    const { store, projectDateStore } = this.props
    store.selectDate(projectDateStore.convertToProjectDate(date))
  }

  private selectCompany(companyName: string) {
    this.props.store.selectCompany(companyName)
  }
}
