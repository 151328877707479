import * as React from 'react'

import { inject } from 'mobx-react'
import Tree from 'react-virtualized-tree'
import tinycolor from 'tinycolor2'

import { DeliveryTreeNodeTypes } from '../../enums/DeliveryTreeNodeTypes'
import Delivery from '../../models/Delivery'
import { IDeliveryTreeNode } from '../../models/IDeliveryTreeNode'
import { IEntityTreeNode } from '../../models/IEntityTreeNode'
import EventsStore from '../../stores/EventStore/Events.store'
import CompactDeliveryListStore from '../../stores/ui/CompactDeliveryList.store'
import ThemeMode from '../../utils/ThemeModeManager'
import CustomNodeRenderer from '../ActivitiesTree/components/CustomNodeRenderer/CustomNodeRenderer'
import FileInputBase from '../FileInput/FileInput'
import DeliveriesTreeStore from './DeliveryTree.store'
import DeliveriesTreeNode from './DeliveryTreeNode'

import Colors from '~/client/src/shared/theme.module.scss'

import './DeliveryTree.scss'

const LEVEL_COLOR_CHANGE_COEFFICIENT = 25
const COLOR_CHANGE_THRESHOLD = 2
const { neutral0, neutral100 } = Colors

interface IDeliveriesTree {
  nodes: IDeliveryTreeNode[]
  startViewDate: Date
  changeContainerExpandState?: (id: string, value: boolean) => void
  compactDeliveryListStore: CompactDeliveryListStore
  CustomNodeWrapperType
  LocationContainerNodeType
  FileInputType: typeof FileInputBase

  className?: string
  eventsStore?: EventsStore
}

@inject('eventsStore')
export default class DeliveriesTree extends React.Component<IDeliveriesTree> {
  public static defaultProps = {
    className: 'delivery-tree',
  }
  private deliveriesTreeStore: DeliveriesTreeStore

  public constructor(props: IDeliveriesTree) {
    super(props)

    this.deliveriesTreeStore = new DeliveriesTreeStore(
      props.compactDeliveryListStore,
    )
  }

  public render() {
    const {
      nodes,
      startViewDate,
      className,
      CustomNodeWrapperType,
      LocationContainerNodeType,
      changeContainerExpandState,
      FileInputType,
    } = this.props

    return (
      <div className={className}>
        <Tree
          nodes={nodes}
          onChange={this.deliveriesTreeStore.handleChangeTree}
        >
          {({ style, node: nativeNode, measure, ...rest }) => {
            const node = nativeNode as IEntityTreeNode<
              DeliveryTreeNodeTypes,
              Delivery
            >

            const nodeStyle = this.getNodeStyle(node, style)
            const shouldRenderSeparators =
              node.nodeType !== DeliveryTreeNodeTypes.DELIVERY && !!node.level
            const defaultClassName =
              node.nodeType === DeliveryTreeNodeTypes.DELIVERY
                ? 'row'
                : 'row level-tree-node'

            return (
              <CustomNodeWrapperType
                style={nodeStyle}
                nodeType={node.nodeType}
                level={node.level}
                defaultClassName={defaultClassName}
              >
                <CustomNodeRenderer
                  node={node}
                  changeContainerExpandState={changeContainerExpandState}
                  startViewDate={startViewDate}
                  FileInputType={FileInputType}
                  className="full-height full-width"
                  shouldRenderSeparators={shouldRenderSeparators}
                  measure={measure}
                  {...rest}
                >
                  <DeliveriesTreeNode
                    node={node}
                    LocationContainerNodeType={LocationContainerNodeType}
                    measureSizeFn={measure}
                  />
                </CustomNodeRenderer>
              </CustomNodeWrapperType>
            )
          }}
        </Tree>
      </div>
    )
  }

  private getNodeStyle(
    { level }: IDeliveryTreeNode,
    style: React.CSSProperties,
  ): React.CSSProperties {
    const backgroundColor = tinycolor(ThemeMode.getHEXColor(neutral0))
      .brighten((level - 1) * LEVEL_COLOR_CHANGE_COEFFICIENT)
      .toString()
    const color = level < COLOR_CHANGE_THRESHOLD ? neutral100 : neutral0

    return Object.assign(style, { backgroundColor, color })
  }
}
