import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IWorkflowStep, WorkflowStepType } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import { getWorkflowStepLevel } from '~/client/src/shared/constants/formStatusesTags'
import { getWorkflowStepDisplayName } from '~/client/src/shared/localization/enumDisplayTexts'

interface IProps {
  workflowSteps: IWorkflowStep[]

  selectedStepId?: string
  onStepClick?(stepId: string): void
}

@observer
export default class FormWorkflowSteps extends React.Component<IProps> {
  public render() {
    const { selectedStepId, workflowSteps, onStepClick } = this.props

    return (
      <div className="px50 mt10 mb40 row">
        {workflowSteps.map(({ id, type, fields }, index) => (
          <div key={id} className="row no-grow">
            {!!index && (
              <Icons.WorkflowArrowRight className="no-outline row no-grow" />
            )}
            <div className="col x-center no-grow relative text large nowrap">
              <div
                className={classList({
                  'bg-cool-grey brada4 px10 py5 pointer ba2-transparent row y-center':
                    true,
                  'ba2-slate-light-hover': selectedStepId !== id,
                  'ba2-group-highlight': selectedStepId === id,
                })}
                onClick={onStepClick?.bind(null, id)}
              >
                {`${index + 1}. ${getWorkflowStepDisplayName(
                  type,
                  getWorkflowStepLevel(id, workflowSteps),
                )}`}
                {type === WorkflowStepType.RecurringInspection && (
                  <Icons.RecurringEvent className="no-outline row no-grow ml4" />
                )}
              </div>
              {!!fields.length && (
                <Icons.Docs className="absolute-top pt2 mt30 no-outline row no-grow" />
              )}
            </div>
          </div>
        ))}
      </div>
    )
  }
}
