import * as React from 'react'

import { inject } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

interface IProps {
  delivery: Delivery
  className?: string
  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
export default class DeliveryDateLabel extends React.PureComponent<IProps> {
  public static defaultProps = {
    className: EMPTY_STRING,
  }

  public render() {
    const { className } = this.props

    return (
      <div
        className={classList({
          row: true,
          [className]: !!className,
        })}
      >
        {this.dateCaption}
        {this.timeCaption}
      </div>
    )
  }

  private get dateCaption(): JSX.Element {
    const {
      projectDateStore: {
        getWeekdayMonthAndDayToDisplay,
        getPronoun,
        isValidDate,
        countDaysToDate,
      },
    } = this.props

    if (!isValidDate(this.startDate)) {
      return null
    }

    let additionalClasses: string = null
    let dateString: string = null

    const daysToDate = countDaysToDate(Date.now(), this.startDate)

    switch (daysToDate) {
      case 0:
        additionalClasses = 'secondary-orange'
        dateString = getPronoun(this.startDate)
        break
      case 1:
        additionalClasses = 'grey-light'
        dateString = getPronoun(this.startDate)
        break
      default:
        additionalClasses = 'grey-light'
        dateString = getWeekdayMonthAndDayToDisplay(this.startDate)
        break
    }

    return (
      <span
        className={classList({
          'text large bold no-grow no-flex ellipsis': true,
          [additionalClasses]: !!additionalClasses,
        })}
        title={dateString}
      >
        {dateString}
      </span>
    )
  }

  private get timeCaption(): JSX.Element {
    const {
      projectDateStore: { countDaysToDate, getShortTimeIntervalPerDay },
    } = this.props

    let timeIntervalString = getShortTimeIntervalPerDay(
      this.startDate,
      this.endDate,
    ).replace(/ /g, EMPTY_STRING)

    const daysDiff = countDaysToDate(this.startDate, this.endDate)

    if (daysDiff) {
      timeIntervalString = `${timeIntervalString} (+${daysDiff}${Localization.translator.d_daysShort})`
    }

    return (
      <span
        className="text large bold ellipsis grey-light lowercase"
        title={timeIntervalString}
      >
        , {timeIntervalString}
      </span>
    )
  }

  private get startDate(): number {
    return this.delivery && this.delivery.startDate
  }

  private get endDate(): number {
    return this.delivery && this.delivery.endDate
  }

  private get delivery(): Delivery {
    return this.props.delivery
  }
}
