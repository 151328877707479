import { action, computed, observable } from 'mobx'

import { IScannerInput, ISiteLocation } from '~/client/graph'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import Scanner from '~/client/src/shared/models/Scanner'
import { QRResponse } from '~/client/src/shared/models/User'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ScannersStore from '~/client/src/shared/stores/domain/Scanners.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

export default class QrCodesStore {
  @observable public isDeleting: boolean = false
  @observable public isRideModeModalShown: boolean = false
  @observable public isScannerDeleteModalShown: boolean = false
  @observable public isEndConfirmShown: boolean = false
  @observable public isOpenScanner: boolean = false
  @observable public isTimedScanner: boolean = false
  @observable public isTimedByDay: boolean = true
  @observable public isActive: boolean = false
  @observable public name: string = ''
  @observable public maxElapsedTime: number = null
  @observable public locationName: string = ''
  @observable public badgeName: string = ''
  @observable public location: ISiteLocation = {
    id: null,
  }
  @observable public isLocationPickerShown: boolean = false
  @observable public isUserDirectoryShown: boolean = false
  @observable public response: QRResponse = QRResponse.notActivated
  @observable public isRealLocationRelated: boolean = true
  @observable public newScanner: Scanner = null
  @observable public selectedScanner: Scanner = null
  @observable public isFullScreenMode: boolean = false

  public constructor(
    private eventsStore: EventsStore,
    private scannersStore: ScannersStore,
    private projectMembersStore: ProjectMembersStore,
    private userProjectsStore: UserProjectsStore,
  ) {}

  public get className(): string {
    switch (this.response) {
      case QRResponse.allowed:
        return 'bg-palette-green'
      case QRResponse.notAllowed:
        return 'bg-red'
      default:
        return ''
    }
  }

  public toggleFullscreenMode = (): void => {
    this.isFullScreenMode = !this.isFullScreenMode
  }

  public toggleEndRideConfirm = (): void => {
    this.isEndConfirmShown = !this.isEndConfirmShown
  }

  public toggleRideModeModalShown = (): void => {
    this.isRideModeModalShown = !this.isRideModeModalShown
  }

  public get isViewMode(): boolean {
    return !!this.selectedScanner
  }

  public toggleIsOpenScanner = (): void => {
    this.isOpenScanner = !this.isOpenScanner
  }

  public toggleIsTimedScanner = (): void => {
    this.isTimedScanner = !this.isTimedScanner
  }

  @action.bound
  public toggleIsTimedByDay() {
    this.isTimedByDay = !this.isTimedByDay
  }

  public getUserIndex = (scanner: Scanner, qRText: string): number => {
    return scanner.allowedUsers.findIndex(userId => {
      const userCodes = this.userCodesMap[userId]
      return userCodes?.includes(qRText) ?? -1
    })
  }

  public performDelete = async (): Promise<void> => {
    this.isDeleting = true
    await this.scannersStore.delete(
      this.selectedScanner.id,
      this.onCreateCallback,
    )
    this.isScannerDeleteModalShown = false
    this.isDeleting = false
  }

  public toggleDeleteModal = (): void => {
    this.isScannerDeleteModalShown = !this.isScannerDeleteModalShown
  }

  public get isLoading(): boolean {
    const { loading } = this.eventsStore.appState

    if (this.isActive) {
      return false
    }

    return (
      [
        e.LOAD_SCANNERS,
        e.LOAD_AND_LISTEN_TO_SCANNERS,
        e.LOAD_SCAN_HISTORIES,
        e.LOAD_AND_LISTEN_TO_SCAN_HISTORIES,
      ].some(event => loading.get(event)) || !this.scannersStore.isDataReceived
    )
  }

  public get isSaveButtonEnabled(): boolean {
    return !!this.newScanner && !!this.badgeName && !!this.name
  }

  public onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.name = event.target.value
  }

  @action.bound
  public onMaxElapsedTimeChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.maxElapsedTime = event.target.valueAsNumber
  }

  public toggleLocationPickerState = (): void => {
    this.isLocationPickerShown = !this.isLocationPickerShown
  }

  public initiateViewForm = (scanner: Scanner): void => {
    this.name = scanner.name
    this.badgeName = scanner.badgeName
    this.maxElapsedTime = scanner.maxElapsedTime
    this.locationName = scanner.locationName
    this.isOpenScanner = scanner.isOpenScanner || false
    this.isTimedScanner = scanner.isTimedScanner || false
    this.isTimedByDay = scanner.isTimedByDay || true
    this.location = scanner.location
    this.isActive = scanner.isActive || false
  }

  public onBNameChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.badgeName = event.target.value
  }

  public selectScanner = (scanner: Scanner): void => {
    this.selectedScanner = scanner
    this.isRealLocationRelated = true
  }

  @action.bound
  public onApplyAllowedUsers(appliedUsersIds: string[] = []) {
    this.newScanner.allowedUsers = appliedUsersIds
    this.toggleUserDirectory()
  }

  public onScannerLocationToggle = (): void => {
    if (this.newScanner) {
      this.newScanner.isRealLocationRelated =
        !this.newScanner.isRealLocationRelated
      this.isRealLocationRelated = !this.isRealLocationRelated
    } else {
      this.selectedScanner.isRealLocationRelated =
        !this.selectedScanner.isRealLocationRelated
      this.isRealLocationRelated = !this.isRealLocationRelated
    }
  }

  public onScannerPropChange = (propName: string, event): void => {
    if (this.newScanner) {
      this.newScanner[propName] = event.target.value
    } else {
      this.selectedScanner[propName] = event.target.value
    }
  }

  public createNewScanner = (): void => {
    const { id } = this.eventsStore.appState.activeProject
    this.name = null
    this.badgeName = null
    this.maxElapsedTime = null
    this.locationName = null
    this.isOpenScanner = false
    this.isTimedScanner = false
    this.isTimedByDay = true
    this.isActive = false
    this.location = {
      id: null,
    }
    this.newScanner = new Scanner(null, id)
  }

  public editScanner = (): void => {
    const { id } = this.eventsStore.appState.activeProject
    this.name = this.selectedScanner.name
    this.badgeName = this.selectedScanner.badgeName
    this.maxElapsedTime = this.selectedScanner.maxElapsedTime
    this.locationName = this.selectedScanner.locationName
    this.isOpenScanner = this.selectedScanner.isOpenScanner
    this.isTimedScanner = this.selectedScanner.isTimedScanner
    this.isTimedByDay = this.selectedScanner.isTimedByDay
    this.location = this.selectedScanner.location
    this.newScanner = new Scanner(this.selectedScanner.id, id)
    this.newScanner.allowedUsers = this.selectedScanner.allowedUsers
    this.newScanner.associatedCodes = this.selectedScanner.associatedCodes
    this.isActive = false
    this.selectedScanner = null
  }

  public onLNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.locationName = event.target.value
  }

  public onSingleSelect = (locationAttr: LocationBase<any>): void => {
    if (locationAttr) {
      this.location = {
        id: locationAttr.id,
        type: locationAttr.type,
      }
    }
  }

  @action.bound
  public toggleUserDirectory(): void {
    this.isUserDirectoryShown = !this.isUserDirectoryShown
  }

  public onCreateCallback = (): void => {
    this.newScanner = null
    this.selectedScanner = null
    this.response = QRResponse.notActivated
  }

  public activateScanner = (): void => {
    this.isActive = true
    const scanner: IScannerInput = {
      ...this.scanner.getDto(),
      isActive: true,
    }
    this.scannersStore.save(scanner)
  }

  public deactivateScanner = (): void => {
    const scanner: IScannerInput = {
      ...this.scanner.getDto(),
      isActive: false,
    }
    this.scannersStore.save(scanner)
  }

  public onSaveClick = (): void => {
    const scanner: IScannerInput = {
      ...this.scanner.getDto(),
      name: this.name,
      locationName: this.locationName,
      badgeName: this.badgeName,
      maxElapsedTime: this.maxElapsedTime,
      location: this.location,
      isOpenScanner: this.isOpenScanner,
      isTimedScanner: this.isTimedScanner,
      isTimedByDay: this.isTimedByDay,
      isActive: false,
    }
    this.scannersStore.save(scanner, this.onCreateCallback)
  }

  public get scanner(): Scanner {
    return this.newScanner || this.selectedScanner
  }

  @computed
  private get userCodesMap(): { [userId: string]: string[] } {
    return this.projectMembersStore.list.reduce((map, user) => {
      const userProject = this.userProjectsStore.getByUser(user)
      const codes = userProject.associatedCodes?.map(code => code.id) || []
      map[user.id] = [user.id, ...codes]
      return map
    }, {})
  }
}
