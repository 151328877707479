import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import CompactDatePicker from '~/client/src/shared/components/CompactDatePicker/CompactDatePicker'
import InitialState from '~/client/src/shared/stores/InitialState'

import ProjectDateStore, { isBetween } from '../stores/ui/ProjectDate.store'

// translated

interface IDayBarProps {
  shouldIconRender?: boolean
  className?: string
  arrowsHeight?: number
  isArrowsHidden?: boolean
  prefix?: React.ReactNode
  postfix?: React.ReactNode
  currentStartDate: Date
  currentEndDate?: Date
  projectDateStore?: ProjectDateStore
  isOneDayMode?: boolean
  setDate?: (startDate: Date, endDate?: Date) => void
  hasItemsOnDate?: (date: Date | number) => boolean
  state?: InitialState
  leftClicked?: () => void
  rightClicked?: () => void
  setDatePickerState?: (isShown: boolean) => void

  shouldRenderChevron?: boolean
}

@inject('projectDateStore', 'state')
@observer
export default class DayBar extends React.Component<IDayBarProps> {
  public static defaultProps = {
    className: '',
  }

  @observable private shouldShowDatePicker: boolean = false

  private get currentDateString() {
    const {
      currentStartDate,
      currentEndDate,
      projectDateStore: {
        getDateIntervalToDisplay,
        getMonthDayAndYearToDisplay,
      },
      isOneDayMode,
    } = this.props
    return !isOneDayMode && currentEndDate
      ? getDateIntervalToDisplay(currentStartDate, currentEndDate)
      : getMonthDayAndYearToDisplay(currentStartDate)
  }

  public render() {
    if (this.props.state.activeProject.isNone) {
      return <div />
    }

    const {
      leftClicked,
      rightClicked,
      shouldIconRender,
      className,
      isArrowsHidden,
      arrowsHeight,
      currentEndDate,
      currentStartDate,
      prefix,
      postfix,
      projectDateStore: { isToday, startOfDay, endOfDay },
      isOneDayMode,
      hasItemsOnDate,
      shouldRenderChevron,
    } = this.props

    const isTodayInRange = currentEndDate
      ? isBetween(
          startOfDay(currentStartDate),
          endOfDay(currentEndDate),
          new Date(),
        )
      : isToday(currentStartDate)

    return (
      <div className={`row bg-white day-bar ${className}`}>
        {this.shouldShowDatePicker && (
          <CompactDatePicker
            initStartDate={currentStartDate}
            initEndDate={!isOneDayMode && currentEndDate}
            onHide={this.hideDatePicker}
            onDateChange={this.setPickedDate}
            isOneDayMode={isOneDayMode}
            hasItemsOnDate={hasItemsOnDate}
          />
        )}
        {prefix}
        {!isArrowsHidden && (
          <div
            className="col no-grow px20 indication-click"
            onClick={leftClicked}
          >
            <img
              src="/static/icons/arrow-left.svg"
              style={{
                height: arrowsHeight + 'px' || 'auto',
              }}
            />
          </div>
        )}
        <div
          className={classList({
            'ml10 row no-flex-children x-center text extra-large extra-bold':
              true,
            orange: isTodayInRange,
          })}
        >
          <span
            onClick={this.showDatePicker}
            className="full-width row y-center "
          >
            <div>{this.currentDateString}</div>
            {shouldIconRender && (
              <img className="ml10" src="/static/icons/calendar.svg" />
            )}
            {shouldRenderChevron && (
              <Icon
                icon={IconNames.CARET_DOWN}
                iconSize={14}
                className="day-bar-chevron ml10"
              />
            )}
          </span>
        </div>
        {!isArrowsHidden && (
          <div
            className="col no-grow px20 indication-click"
            onClick={rightClicked}
          >
            <img
              src="/static/icons/arrow-right.svg"
              style={{
                transform: 'rotate(180deg)',
                height: arrowsHeight + 'px' || 'auto',
              }}
            />
          </div>
        )}
        {postfix}
      </div>
    )
  }

  @action.bound
  private showDatePicker() {
    this.shouldShowDatePicker = true
    this.setDatePickerState()
  }

  @action.bound
  private hideDatePicker() {
    this.shouldShowDatePicker = false
    this.setDatePickerState()
  }

  @action.bound
  private setPickedDate(startDate: Date, endDate: Date) {
    this.hideDatePicker()
    this.props.setDate(startDate, endDate)
  }

  private setDatePickerState = () => {
    const { setDatePickerState } = this.props
    if (setDatePickerState) {
      setDatePickerState(this.shouldShowDatePicker)
    }
  }
}
