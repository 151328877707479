import { action, observable } from 'mobx'

export default class StruxhubBareEditableInputStore {
  @observable public draftValue: string
  @observable public isInEditingMode: boolean = false

  public constructor(
    value: string,
    private readonly onChange: (newValue: string) => void,
  ) {
    this.setDraftValue(value)
  }

  @action.bound
  public setDraftValue(value: string) {
    this.draftValue = value
    this.exitFromEditingMode()
  }

  @action.bound
  public setToEditingMode() {
    this.isInEditingMode = true
  }

  @action.bound
  public exitFromEditingMode() {
    this.isInEditingMode = false
  }

  @action.bound
  public updateDraftValue(newValue: string) {
    this.draftValue = newValue
  }

  @action.bound
  public applyChange() {
    if (!this.draftValue?.trim()) {
      return
    }

    if (this.isInEditingMode) {
      this.exitFromEditingMode()
      this.onChange(this.draftValue)
    }
  }
}
