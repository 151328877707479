import { action, computed, observable } from 'mobx'

import { IActivityResourceRelationship, IResource } from '~/client/graph'
import Resource from '~/client/src/shared/models/Resource'
import InitialState from '~/client/src/shared/stores/InitialState'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'

export default class ResourcesStore {
  public maps = observable.object(
    {
      resourceActivities: {},
      activityResources: {},
    },
    null,
    { deep: false },
  )

  public constructor(
    private state: InitialState,
    private readonly activitiesStore: ActivitiesStore,
  ) {}

  public get byId() {
    return this.state.resources
  }

  @computed
  public get list() {
    return Array.from(this.state.resources.values())
  }

  @action.bound
  public clearList() {
    this.state.resources.clear()
  }

  @action.bound
  public clearStoreAndReceiveJson(
    resources: IResource[],
    relationships: IActivityResourceRelationship[],
  ) {
    this.clearList()
    Object.values(resources || {}).forEach(dto => {
      const instance = Resource.fromDto(dto)
      this.byId.set(instance.id, instance)
    })

    this.setMaps(relationships)
  }

  public updateOne(id: string, dto: IResource) {
    if (dto) {
      const instance = Resource.fromDto(dto)
      this.byId.set(id, instance)
    } else {
      this.byId.delete(id)
    }
  }

  @action
  public updateOneRelationship(
    id: string,
    { activityP6Code, resourceId, isDeleted }: IActivityResourceRelationship,
  ) {
    const { activityResources, resourceActivities } = this.maps

    if (isDeleted) {
      resourceActivities[resourceId] = resourceActivities[resourceId].filter(
        code => code != activityP6Code,
      )
      activityResources[activityP6Code] = activityResources[
        activityP6Code
      ].filter(id => id != resourceId)
      return
    }

    if (!resourceActivities[resourceId]) {
      resourceActivities[resourceId] = []
    }
    if (!activityResources[activityP6Code]) {
      activityResources[activityP6Code] = []
    }
    resourceActivities[resourceId].push(activityP6Code)
    activityResources[activityP6Code].push(resourceId)
  }

  public getResourceByActivityId(activityId: string): Resource {
    const resourceIds = this.maps.activityResources[activityId] || []
    return resourceIds.map(id => this.byId.get(id)).filter(r => r)[0]
  }

  private setMaps(relationships: IActivityResourceRelationship[]) {
    const resourceActivities = {}
    const activityResources = {}

    Object.values(relationships || {}).forEach(
      ({ activityP6Code, resourceId, isDeleted }) => {
        if (isDeleted) {
          return
        }

        if (!resourceActivities[resourceId]) {
          resourceActivities[resourceId] = []
        }
        if (!activityResources[activityP6Code]) {
          activityResources[activityP6Code] = []
        }
        resourceActivities[resourceId].push(activityP6Code)
        activityResources[activityP6Code].push(resourceId)
      },
    )

    // this logic is added for existing not reloaded schedules
    this.activitiesStore.list.forEach(({ code, resourceId }) => {
      if (!resourceId) {
        return
      }

      if (!resourceActivities[resourceId]) {
        resourceActivities[resourceId] = []
      }
      if (!activityResources[code]) {
        activityResources[code] = []
      }
      if (!resourceActivities[resourceId].includes(code)) {
        resourceActivities[resourceId].push(code)
      }
      if (!activityResources[code].includes(resourceId)) {
        activityResources[code].push(resourceId)
      }
    })

    this.maps.activityResources = activityResources
    this.maps.resourceActivities = resourceActivities
  }
}
