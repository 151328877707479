import * as React from 'react'

import { observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'

interface IProps {
  index?: number
  onClick(index?: number): void
}

@observer
export default class PermitFieldRemoveIcon extends React.Component<IProps> {
  public render() {
    return (
      <div className="pl5 no-grow">
        <Icons.CrossGrey
          className="pointer text light no-grow opacity5 row x-center y-center"
          onMouseDown={this.onRemoveClick}
        />
      </div>
    )
  }

  private onRemoveClick = () => {
    const { index, onClick } = this.props
    onClick(index)
  }
}
