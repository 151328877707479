import React from 'react'

interface ITableSeparatorsProps {
  className: string
  level: number
  marginLeft?: number
}

export default class TableSeparators extends React.Component<ITableSeparatorsProps> {
  public render() {
    const { level, className, marginLeft } = this.props
    return Array(level)
      .fill(null)
      .map((l, index) => {
        return (
          <div
            key={index}
            style={{ borderWidth: 2, marginLeft }}
            className={className}
          />
        )
      })
  }
}
