import { computed } from 'mobx'

import {
  IMaterialProcurementData,
  IProjectMaterial,
  IStringPair,
} from '~/client/graph'
import metadataKeys from '~/client/src/shared/constants/metadataKeys'
import { MaterialStatus } from '~/client/src/shared/enums/MaterialStatus'
import Delivery from '~/client/src/shared/models/Delivery'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import Guard from '~/client/src/shared/utils/Guard'

export default class Material {
  public static fromDto(
    dto: IProjectMaterial,
    deliveriesStore: DeliveriesStore,
  ) {
    return new Material(
      dto.id,
      dto.productName,
      dto.categoryId,
      dto.projectId,
      dto.materialsUploadId,
      dto.description,
      dto.plannedQuantity,
      dto.procurementDataList,
      dto.createdAt,
      dto.updatedAt,
      deliveriesStore,
      dto.metadata,
    )
  }

  private readonly metadataMap = new Map<string, string>()

  public constructor(
    public id: string,
    public productName: string,
    public categoryId: string,
    public projectId: string,
    public materialsUploadId: string,
    public description: string,
    public plannedQuantity: number,
    public procurementDataList: IMaterialProcurementData[],
    public createdAt: number,
    public updatedAt: number,
    private deliveriesStore: DeliveriesStore,
    metadata?: IStringPair[],
  ) {
    Guard.requireAll({ productName, categoryId })

    metadata?.forEach(m => this.metadataMap.set(m.key, m.value))
  }

  @computed
  public get relatedDeliveries(): Delivery[] {
    return this.deliveriesStore.availableDeliveries
      .filter(
        delivery =>
          !delivery.isCanceled &&
          !delivery.isDenied &&
          this.id in delivery.materialsMap,
      )
      .sort((d1, d2) => Math.abs(d1.startDate) - Math.abs(d2.startDate))
  }

  @computed
  public get status(): MaterialStatus {
    const nearestDelivery = this.relatedDeliveries[0]
    return nearestDelivery
      ? nearestDelivery.status
      : MaterialStatus.IN_FABRICATION
  }

  public get isFromConcreteDirect(): boolean {
    const { integrationKey, cdIntegrationValue } = metadataKeys

    if (this.metadataMap.has(integrationKey)) {
      return this.metadataMap.get(integrationKey) === cdIntegrationValue
    }
  }

  public get hasProcurementIds(): boolean {
    return this.procurementDataList?.some(pr => pr.procurementId)
  }

  public includesProcurementId = (procurementId: string): boolean => {
    return this.procurementDataList?.some(
      pr => pr.procurementId === procurementId,
    )
  }

  public getProcurementDataById = (
    procurementId: string,
  ): IMaterialProcurementData => {
    return this.procurementDataList?.find(
      pr => pr.procurementId === procurementId,
    )
  }
}
