import { action, computed } from 'mobx'

import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import InitialState from '~/client/src/shared/stores/InitialState'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'

import Localization from '../../localization/LocalizationManager'
import KnownTranslatorKeys from '../../localization/knownTranslatorKeys'

export interface IRootOption {
  id: string
  name: string
  options: IOption[]
}

export interface IOption {
  id: string
  name: string
  shortName?: string
  instancesIds: string[]
}

export default abstract class SuperFilterStore {
  public constructor(
    public readonly filter: UIFilterInfo,
    public readonly type: string,
    protected readonly state: InitialState,
    private readonly seeXItemsTranslatorKey: KnownTranslatorKeys,
    private readonly getTypeCaption: (type: string) => string,
    protected readonly onShowChanged?: (
      isShown: boolean,
      filterType: string,
    ) => void,
    public readonly shouldHideCount?: boolean,
    protected readonly onClickHandler?: () => void,
  ) {}

  public get isLoading(): boolean {
    return this.state.isLoading
  }

  public get isShown(): boolean {
    return this.filter.isFilterShown
  }

  public abstract get optionsTree(): IRootOption[]

  public get selectedOptions() {
    return this.filter.selectedFilterOptions
  }

  public get isEmpty(): boolean {
    return !this.isLoading && !this.optionsTree.length
  }

  protected get unassignedOptionId() {
    return 'unassigned-' + this.type
  }

  @computed
  public get isActive(): boolean {
    return (
      !this.isLoading &&
      this.allOptionIds.some(optionId => !this.selectedOptions.get(optionId))
    )
  }

  @computed
  public get allOptionIds(): string[] {
    return this.optionsTree.reduce(
      (res, rootOption) => res.concat(rootOption.options.map(o => o.id)),
      [],
    )
  }

  @computed
  public get areAllOptionsSelected(): boolean {
    return this.allOptionIds.every(optionId =>
      this.selectedOptions.get(optionId),
    )
  }

  public abstract get totalHint(): string

  public get isDisabled(): boolean {
    return false
  }

  public get filterSourceType(): string {
    return null
  }

  public clearNotPresentOptions() {
    return
  }

  public get filterCaption() {
    return this.typeCaption
  }

  public abstract filterOptionInstances(instancesIds: string[])

  public abstract onFilterActionRequest(eventContext: EventContext)

  @action.bound
  public toggle() {
    this.filter.selectedFilterOptions = new Map(
      this.filter.initialFilterOptions,
    )
    this.setIsShown(!this.filter.isFilterShown)
    this.onClickHandler?.()
  }

  @action.bound
  public setIsShown(isShown: boolean) {
    this.filter.isFilterShown = isShown
    if (this.onShowChanged) {
      this.onShowChanged(this.isShown, this.type)
    }
  }

  @computed
  public get appliedOptions(): string[] {
    const appliedOptions: string[] = []

    this.selectedOptions.forEach(options => {
      if (options?.length) {
        appliedOptions.push(...options)
      }
    })
    return appliedOptions
  }

  @computed
  public get appliedOptionsNames(): string[] {
    return Array.from(this.selectedOptions.keys()).map(optionId => {
      const rootOption = this.optionsTree.find(ro =>
        ro.options.some(o => o.id === optionId),
      )

      const option = rootOption.options.find(o => o.id === optionId)
      return option.shortName || option.name
    })
  }

  @action.bound
  public changeOption(id: string, instancesIds: string[]) {
    const isChecked = !!this.selectedOptions.get(id)

    if (isChecked) {
      this.selectedOptions.delete(id)
    } else {
      this.selectedOptions.set(id, instancesIds)
    }

    this.onClickHandler?.()
  }

  @action.bound
  public changeRootOption(options: IOption[]) {
    const isChecked = this.isRootOptionChecked(options)

    options.forEach(option => {
      if (isChecked) {
        this.selectedOptions.delete(option.id)
      } else {
        this.selectedOptions.set(option.id, option.instancesIds)
      }
    })

    this.onClickHandler?.()
  }

  @action.bound
  public selectOptionsByIds(ids: string[] = []) {
    this.optionsTree.forEach(rootOption => {
      rootOption.options.forEach(option => {
        if (ids?.includes(option.id)) {
          this.selectedOptions.set(option.id, option.instancesIds)
        } else {
          this.selectedOptions.delete(option.id)
        }
      })
    })

    this.onClickHandler?.()
  }

  @action.bound
  public clickOnSelectAll() {
    this.optionsTree.forEach(rootOption => {
      rootOption.options.forEach(option => {
        this.selectedOptions.set(option.id, option.instancesIds)
      })
    })

    this.onClickHandler?.()
  }

  @action.bound
  public clickOnDeselectAll() {
    this.selectedOptions.clear()

    this.onClickHandler?.()
  }

  @action.bound
  public clickOnOnly(id: string, instancesIds: string[]) {
    this.selectedOptions.clear()
    this.selectedOptions.set(id, instancesIds)

    this.onClickHandler?.()
  }

  @action.bound
  public clickOnCancel() {
    this.filter.selectedFilterOptions = this.filter.initialFilterOptions
    this.setIsShown(false)

    this.onClickHandler?.()
  }

  public isRootOptionChecked = (options): boolean => {
    return options.every(c => this.selectedOptions.get(c.id))
  }

  public abstract clickOnApply(): void

  @action.bound
  public handleApply() {
    this.filter.isFilterActive = this.isActive

    this.filter.appliedFilterOptions = this.appliedOptions
    this.filter.initialFilterOptions = this.filter.selectedFilterOptions

    this.setIsShown(false)
  }

  @action
  public setInitialFilterValues() {
    if (!this.filter) {
      return
    }

    this.clickOnSelectAll()
    this.clickOnApply()
  }

  @computed
  public get selectedInstancesAmount() {
    const selectedInstances = []
    this.selectedOptions.forEach(ids => {
      if (ids?.length) {
        selectedInstances.push(
          ...ids.filter(id => !selectedInstances.includes(id)),
        )
      }
    })

    return this.filterOptionInstances(selectedInstances).length
  }

  public get handleButtonCaption(): string {
    return this.getHandleButtonCaption()
  }

  public get applyButtonLabel() {
    switch (true) {
      case !this.selectedOptions.size:
        return Localization.translator.disableFilter

      default:
        return this.shouldHideCount
          ? Localization.translator.apply
          : Localization.getText(
              this.seeXItemsTranslatorKey,
              this.selectedInstancesAmount,
            )
    }
  }

  protected getHandleButtonCaption(): string {
    if (!this.isActive) {
      return this.filterCaption
    }

    const selectedOptionsAmount = this.selectedOptions.size
    switch (true) {
      case selectedOptionsAmount > 1:
        return `${this.filterCaption} (${selectedOptionsAmount})`
      case selectedOptionsAmount === 1:
        return this.getSingleModeCaption()
      default:
        return this.filterCaption
    }
  }

  public get nameCaption(): string {
    return ''
  }

  public get shortNameCaption(): string {
    return this.typeCaption
  }

  public get typeCaption(): string {
    return this.getTypeCaption(this.type)
  }

  private getSingleModeCaption() {
    const optionId = this.selectedOptions.keys().next().value
    const rootOption = this.optionsTree.find(ro =>
      ro.options.some(o => o.id === optionId),
    )

    const option = rootOption.options.find(o => o.id === optionId)
    const optionCaption = option.shortName || option.name

    return `${rootOption.name}: ${optionCaption}`
  }
}
