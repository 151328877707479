import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import { IUserProject } from '~/client/graph'
import TagsSelector from '~/client/src/desktop/components/TagsSelector/TagsSelector'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import {
  Content,
  Footer,
  Header,
  View,
} from '~/client/src/shared/components/Layout'
import { Loader } from '~/client/src/shared/components/Loader'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import User from '~/client/src/shared/models/User'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import BulkEditMembersSideBarStore from './BulkEditMembersSideBar.store'

import Colors from '~/client/src/shared/theme.module.scss'

import './BulkEditMembersSideBar.scss'

export interface IProps {
  selectedProjectMembers: User[]
  onClose: () => void
  bulkEditTags: (items: IUserProject[]) => void
  loading: boolean
  errorMessage?: string

  toggleInviteDialog: (isShow: boolean) => void
  toggleDeleteDialog: (isShow: boolean) => void
  hasProjectMembersToInvite: boolean
  tagsStore?: TagsStore
  userProjectsStore?: UserProjectsStore
}

const availableTypes = [
  TagType.Team,
  TagType.Role,
  TagType.AccountType,
  TagType.Company,
]

@inject('tagsStore', 'userProjectsStore')
@observer
export default class BulkEditMembersSideBar extends React.Component<IProps> {
  private readonly store: BulkEditMembersSideBarStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new BulkEditMembersSideBarStore(
      props.tagsStore,
      props.selectedProjectMembers,
      props.bulkEditTags,
      availableTypes,
      props.userProjectsStore,
    )
  }

  public componentDidMount() {
    const { onClose, selectedProjectMembers } = this.props

    if (!selectedProjectMembers.length) {
      onClose()
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { onClose, selectedProjectMembers } = this.props

    if (!selectedProjectMembers.length) {
      onClose()
    }

    if (selectedProjectMembers !== prevProps.selectedProjectMembers) {
      this.store.init(selectedProjectMembers)
    }
  }

  public render() {
    const content = this.props.loading
      ? this.renderLoader()
      : this.renderContent()

    return (
      <View className="bulk-edit-members-side-bar absolute bl-palette-brand-lighter bg-white">
        {content}
      </View>
    )
  }

  private renderContent(): JSX.Element {
    const { onClose, errorMessage } = this.props
    const { changeSelection, defaultSelection, handleApply, canApply } =
      this.store

    return (
      <>
        <Header className="col pa10 px20 bb-light-grey no-grow">
          <div className="row">
            <Icon
              icon={IconNames.CROSS}
              className="no-grow pointer mr12"
              color={Colors.neutral60}
              onClick={onClose}
            />
            <span className="text bold small-header">
              {Localization.translator.edit_verb}
            </span>
            <BaseActionButton
              title={Localization.translator.apply}
              className="scale-blue-theme bold"
              isEnabled={canApply}
              onClick={handleApply}
            />
          </div>
          <div className="text large primary py5">
            {this.selectedProjectMembersLabel}
          </div>
        </Header>
        <Content className="px20">
          <TagsSelector
            onChange={changeSelection}
            availableTypes={availableTypes}
            initSelection={defaultSelection}
            radioTagTypes={[TagType.Company, TagType.AccountType]}
            separatedCategories={[TagType.Company]}
          />
          {this.renderActionButons()}
        </Content>
        <Footer>
          <div className="text center large red bold pb24">{errorMessage}</div>
        </Footer>
      </>
    )
  }

  private renderActionButons() {
    const {
      toggleDeleteDialog,
      toggleInviteDialog,
      hasProjectMembersToInvite,
    } = this.props
    return (
      <div className="col">
        <div className="bt-palette-brand-lighter mb10" />
        <BaseActionButton
          icon={<Icon icon={IconNames.TRASH} />}
          className="inverse-scale-blue-theme mw90 mb10"
          key="delete"
          isEnabled={true}
          isActive={true}
          shouldShowBorder={false}
          title={Localization.translator.delete}
          onClick={toggleDeleteDialog}
        />
        <BaseActionButton
          icon={<Icon icon={IconNames.ADD} />}
          className="inverse-scale-blue-theme mw90"
          key="invite"
          isEnabled={hasProjectMembersToInvite}
          isActive={true}
          shouldShowBorder={false}
          title={Localization.translator.invite}
          onClick={toggleInviteDialog}
        />
      </div>
    )
  }

  private renderLoader(): JSX.Element {
    return (
      <div className="full-height full-width absolute modal-overlay">
        <Loader />
      </div>
    )
  }

  private get selectedProjectMembersLabel(): string {
    return Localization.translator.xTeamMembersSelected(
      this.props.selectedProjectMembers.length,
    )
  }
}
