import * as React from 'react'

import { observer } from 'mobx-react'

import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import Awaiter from '~/client/src/shared/utils/Awaiter'

import KonvaSimpleTextBox from '../konvaElements/KonvaSimpleTextBox'
import KonvaTextBox from '../konvaElements/KonvaTextBox'

interface IProps {
  item: MapViewItemBase
  containerWidth: number
  containerHeight: number
  textboxesAwaiter?: Awaiter
  textboxesCache?: ICanvasImageCache
}

const MAX_PERCENT = 100

@observer
export default class SitemapLabel extends React.Component<IProps & any> {
  public render() {
    const {
      containerHeight,
      containerWidth,
      item,
      textboxesAwaiter,
      textboxesCache,
      ...rest
    } = this.props
    const {
      name,
      sitemapItemProperties: { labelProperties },
      color,
      isDisplayed,
    } = item
    if (
      !labelProperties ||
      !labelProperties.isDisplayed ||
      !name ||
      !isDisplayed
    ) {
      return null
    }

    if (!item.isRichTextBox) {
      return (
        <KonvaSimpleTextBox
          text={name}
          fontSize={labelProperties.fontSize}
          fontStyle="bold"
          color={color}
          isTextBoxShown={labelProperties.isTextBoxDisplayed}
          x={(containerWidth * labelProperties.position.x) / MAX_PERCENT}
          y={(containerHeight * labelProperties.position.y) / MAX_PERCENT}
          {...rest}
        />
      )
    }

    return (
      <KonvaTextBox
        text={name}
        fontSize={labelProperties.fontSize}
        fontStyle="bold"
        color={color}
        textColor={labelProperties.color}
        isRichTextBox={item.isRichTextBox}
        isTextBoxShown={labelProperties.isTextBoxDisplayed}
        x={(containerWidth * labelProperties.position.x) / MAX_PERCENT}
        y={(containerHeight * labelProperties.position.y) / MAX_PERCENT}
        textboxesAwaiter={textboxesAwaiter}
        textboxesCache={textboxesCache}
        {...rest}
      />
    )
  }
}
