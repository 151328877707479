import * as React from 'react'

import { Colors, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

import PermitInspection from '../../../models/PermitInspection'
import EventsStore from '../../../stores/EventStore/Events.store'
import ProjectDateStore from '../../../stores/ui/ProjectDate.store'
import SitePermitCreationFormStore from '../SitePermitCreationForm.store'

interface IProps {
  store: SitePermitCreationFormStore

  projectDateStore?: ProjectDateStore
  eventsStore?: EventsStore
}

const notInspected = 'Not inspected'
const _inspected = 'Inspected'
const addInspection = 'Add inspection'
const notUsed = 'Not used'
const deadlineTimeEnded = 'Deadline time ended'
const inspection = 'Inspection'
const nonWorkday = 'Non workday'
const useLog = 'Use log'

@inject('projectDateStore', 'eventsStore')
@observer
export default class PermitInspectionsSection extends React.Component<IProps> {
  public render() {
    const { store } = this.props
    const {
      editablePermit: { id },
      permitInspectionData,
      isInspectionPermit,
      isEmissionFormType,
    } = store

    if (!id || !isInspectionPermit) {
      return null
    }

    return permitInspectionData.map((inspectionData, index) =>
      this.renderInspectionSectionHeader(
        isEmissionFormType ? useLog : inspection,
        index,
        inspectionData.inspection,
        inspectionData.date,
      ),
    )
  }

  private renderInspectionSectionHeader(
    text: string,
    index: number,
    permitInspection: PermitInspection,
    date: Date,
  ): JSX.Element {
    const { store, projectDateStore, eventsStore } = this.props
    const { selectInspection } = store.inspectionFieldsStore
    const { isToday, getWeekdayNumber } = projectDateStore
    const { projectWorkingDaysMap } = eventsStore.appState.activeProject

    const isNotInspected = !permitInspection
    const isInspectionAdded = !!permitInspection?.fields?.length
    const isWorkday = projectWorkingDaysMap[getWeekdayNumber(date)]

    return (
      <div
        key={index}
        className={classList({
          'row text py3 px16 bt-palette-brand-lighter h32 pointer': true,
          'bg-pink': !isInspectionAdded && !isToday(date) && isWorkday,
          'bg-palette-brand-lightest': isInspectionAdded || !isWorkday,
        })}
        onClick={selectInspection.bind(this, permitInspection, date)}
      >
        <div className="row">
          <Icon icon={IconNames.CARET_RIGHT} color={Colors.BLACK} />
          <Icons.Checklist className="mr8 inspection-icon no-grow dark" />
          <span className="row text uppercase lp15 small">
            {`${text} #${index + 1}`}
          </span>
        </div>
        <div className="row">
          {this.renderInspectionSectionHeaderText(
            date,
            isNotInspected,
            isInspectionAdded,
            isWorkday,
          )}
        </div>
      </div>
    )
  }

  private renderInspectionSectionHeaderText(
    date: Date,
    isNotInspected: boolean,
    isInspectionAdded: boolean,
    isWorkday: boolean,
  ) {
    const { projectDateStore, store } = this.props
    const {
      isBeforeDeadlineEnds,
      editablePermit: { isDoneOrDenied, isDeleted },
    } = store

    switch (true) {
      case isInspectionAdded:
        return (
          <>
            <span className="text center">{_inspected}</span>
            <span className="w-fit-content">
              {projectDateStore.getPronounOrMonthAndDayString(date)}
            </span>
          </>
        )
      case !isWorkday:
        return (
          <>
            <span className="text center grey-light">{nonWorkday}</span>
            <span className="w-fit-content">
              {projectDateStore.getPronounOrMonthAndDayString(date)}
            </span>
          </>
        )
      case isNotInspected:
        return (
          <>
            {projectDateStore.isToday(date) && !isDoneOrDenied && !isDeleted ? (
              <div className="text center palette-blue">
                {isBeforeDeadlineEnds ? addInspection : deadlineTimeEnded}
              </div>
            ) : (
              <div className="text center red">{notInspected}</div>
            )}
            <span className="w-fit-content">
              {projectDateStore.getPronounOrMonthAndDayString(date)}
            </span>
          </>
        )
      default:
        return (
          <>
            <span className="text center grey-light">{notUsed}</span>
            <span className="w-fit-content">
              {projectDateStore.getPronounOrMonthAndDayString(date)}
            </span>
          </>
        )
    }
  }
}
