import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DeliveryStatusLabel from '~/client/src/shared/components/DeliveryStatusLabel/DeliveryStatusLabel'
import * as Icons from '~/client/src/shared/components/Icons'
import { ILWFCColumn } from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Company from '~/client/src/shared/models/Company'
import Delivery from '~/client/src/shared/models/Delivery'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import { DataKeys } from '../MaterialsList.store'
import BaseDataCellTable from './BaseDataCell/BaseDataCellTable'

// localization: translated

interface IProps {
  procurementId: string
  materialId: string
  deliveries: Delivery[]
  onIdClick(deliveryId: string): void

  footerTitle?: string
  shownColumnIds?: string[]
  isHeaderHidden?: boolean
  isFooterHidden?: boolean
  locationRenderer?(location: LocationBase): JSX.Element
  companyRenderer?(company: Company): JSX.Element

  projectDateStore?: ProjectDateStore
  companiesStore?: CompaniesStore
  locationAttributesStore?: LocationAttributesStore
}

const ROW_HEIGHT = 40

@inject('projectDateStore', 'companiesStore', 'locationAttributesStore')
@observer
export default class DeliveryDataCellTable extends React.Component<IProps> {
  public render() {
    const { isHeaderHidden, isFooterHidden } = this.props
    return (
      <BaseDataCellTable<Delivery>
        items={this.props.deliveries}
        columns={this.columns}
        getRowHeightByItem={this.getRowHeightByDelivery}
        getCellContentByDataKey={this.getCellContentByDataKey}
        getFooterTitleByDataKey={this.getFooterTitleByDataKey}
        onIdClick={this.onIdClick}
        iconElement={
          <Icons.Delivery className="form-id-icon relative no-flex mr5" />
        }
        isHeaderHidden={isHeaderHidden}
        isFooterHidden={isFooterHidden}
      />
    )
  }

  private getCellContentByDataKey = (
    dataKey: string,
    delivery: Delivery,
  ): JSX.Element => {
    const {
      procurementId,
      materialId,
      companiesStore,
      projectDateStore: { getMonthDayYearAndTimeToDisplay },
      companyRenderer,
    } = this.props
    switch (dataKey) {
      case DataKeys.ID:
        return <>{delivery.codeToDisplay(companiesStore)}</>
      case DataKeys.NEXT_DELIVERY_BOOKING_DATE:
        return (
          <span className="text-ellipsis">
            {getMonthDayYearAndTimeToDisplay(delivery.startDate)}
          </span>
        )
      case DataKeys.PLANNED_QUANTITY:
        return (
          <span className="text large end ellipsis">
            {(procurementId
              ? delivery.procurementQuantitiesMap[procurementId]
              : delivery.materialQuantitiesMap[materialId]) || 0}
          </span>
        )
      case DataKeys.STATUS:
        return (
          <DeliveryStatusLabel
            className="delivery-status overflow-hidden"
            status={delivery.status}
          />
        )
      case DataKeys.CURRENT_MATERIAL_LOCATION:
        return this.renderCurrentLocations(delivery)
      case DataKeys.VENDOR:
        return companyRenderer(companiesStore.getCompanyById(delivery.vendor))
      case DataKeys.RESPONSIBLE_COMPANY:
        return companyRenderer(companiesStore.getCompanyById(delivery.company))
      default:
        return null
    }
  }

  private renderCurrentLocations = (delivery: Delivery): JSX.Element => {
    const {
      procurementId,
      materialId,
      locationAttributesStore: { getById },
      locationRenderer,
    } = this.props

    const locationIds = delivery.getMaterialLocationIds(
      materialId,
      procurementId,
    )

    if (!locationIds.length) {
      return locationRenderer(null)
    }

    return (
      <div className="col overflow-hidden">
        {locationIds.map(id => (
          <div key={id} className="row py2">
            {locationRenderer(getById(id))}
          </div>
        ))}
      </div>
    )
  }

  private getFooterTitleByDataKey = (dataKey: string) => {
    const { footerTitle, deliveries, materialId, procurementId } = this.props

    switch (dataKey) {
      case DataKeys.ID:
        return footerTitle
      case DataKeys.PLANNED_QUANTITY:
        return deliveries.reduce(
          (sum, del) =>
            (sum +=
              (procurementId
                ? del.procurementQuantitiesMap[procurementId]
                : del.materialQuantitiesMap[materialId]) || 0),
          0,
        )
      default:
        return null
    }
  }

  private get columns(): ILWFCColumn[] {
    return [
      {
        label: Localization.translator.deliveryId,
        dataKey: DataKeys.ID,
        width: 180,
      },
      {
        label: Localization.translator.deliveryBookingDate,
        dataKey: DataKeys.NEXT_DELIVERY_BOOKING_DATE,
        width: 180,
      },
      {
        label: Localization.translator.quantity,
        dataKey: DataKeys.PLANNED_QUANTITY,
        width: 80,
      },
      {
        label: Localization.translator.deliveryStatus,
        dataKey: DataKeys.STATUS,
        width: 110,
      },
      {
        label: Localization.translator.currentLocation,
        dataKey: DataKeys.CURRENT_MATERIAL_LOCATION,
        width: 160,
      },
      {
        label: Localization.translator.vendor,
        dataKey: DataKeys.VENDOR,
        width: 200,
      },
      {
        label: Localization.translator.responsibleCompany,
        dataKey: DataKeys.RESPONSIBLE_COMPANY,
        width: 210,
      },
    ].filter(
      ({ dataKey }) =>
        dataKey === DataKeys.ID || this.props.shownColumnIds.includes(dataKey),
    )
  }

  private getRowHeightByDelivery = (delivery: Delivery): number => {
    if (!delivery) {
      return 0
    }

    const { procurementId, materialId, shownColumnIds } = this.props
    if (!shownColumnIds.includes(DataKeys.CURRENT_MATERIAL_LOCATION)) {
      return ROW_HEIGHT
    }

    const rowNumbers = delivery.getMaterialLocationIds(
      materialId,
      procurementId,
    ).length

    return (rowNumbers || 1) * ROW_HEIGHT
  }

  private onIdClick = (delivery: Delivery) => {
    this.props.onIdClick(delivery.id)
  }
}
