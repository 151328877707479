import { SaveAreasDocument } from '~/client/graph/operations/generated/LocationType.generated'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ILocationDto from '~/client/src/shared/types/ILocationDto'

import Area from '../../models/LocationObjects/Area'
import LocationStoreBase from './LocationBase.store'

export default class AreasStore extends LocationStoreBase<Area> {
  protected saveMutation = SaveAreasDocument
  protected graphName = 'areas'
  protected defaultIconName = MapViewLocationIcon.Area

  public get byId() {
    return this.eventsStore.appState.areas
  }

  protected fromDto(dto: ILocationDto): Area {
    return Area.fromDto(dto)
  }
}
