import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'

export const deliveryFieldIdToModelPropertyNameMap = {
  [FieldIds.ZONE]: 'zone',
  [FieldIds.GATE]: 'gate',
  [FieldIds.DATE]: 'startDate',
  [FieldIds.END_DATE]: 'endDate',
  [FieldIds.START_TIME]: 'startDate',
  [FieldIds.END_TIME]: 'endDate',
  [FieldIds.REQUESTER]: 'userId',
  [FieldIds.COMPANY]: 'company',
  [FieldIds.ACTIVITY_ID]: 'activityId',
  [FieldIds.IS_INSPECTION_REQUIRED]: 'isInspectionRequired',
  [FieldIds.DRIVER_PHONE_NUMBERS]: 'driverPhoneNumbers',
  [FieldIds.VENDOR]: 'vendor',
  [FieldIds.VENDOR_EMAILS]: 'vendorEmails',
  [FieldIds.TRUCK_SIZE]: 'truckSize',
  [FieldIds.TRUCK_LICENSE_PLATE]: 'truckLicensePlate',
  [FieldIds.TRUCK_NUMBER]: 'truckNumber',
  [FieldIds.OFFLOADING_EQUIPMENT]: 'offloadingEquipments',
  [FieldIds.NUMBER_OF_EQUIPMENT_PICKS]: 'numberOfEquipmentPicks',
  [FieldIds.SITE_MAPS]: 'sitemapUrls',
  [FieldIds.ON_SITE_CONTACTS]: 'onSiteContactPersonIds',
  [FieldIds.LATE_REQUEST]: 'isLateRequest',
  [FieldIds.UNSCHEDULED_REQUEST]: 'isUnscheduledRequest',
  [FieldIds.INSTALLATION_ZONE]: 'installationZone',
  [FieldIds.INSPECTOR]: 'inspector',
  [FieldIds.NOTE]: 'note',
  [FieldIds.MATERIAL]: 'materials',
  [FieldIds.BUILDING]: 'building',
  [FieldIds.LEVEL]: 'level',
  [FieldIds.AREA]: 'area',
  [FieldIds.ROUTE]: 'route',
}
