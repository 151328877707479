import * as React from 'react'

import { observer } from 'mobx-react'
import { toggleClass } from 'react-classlist-helper'

import {
  IConditionalField,
  IPermitTypeField,
  IRestrictedOption,
  PermitFieldType,
} from '~/client/graph'
import { FORM_MATERIAL_FIELDS_KEY } from '~/client/src/shared/constants/permitTypeFieldsConstants'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { NOOP } from '~/client/src/shared/utils/noop'

import FieldConfigurator from '../../FieldConfigurator/FieldConfigurator'

// localization: translated

interface IProps {
  field: IPermitTypeField
  allConditionalFields: IConditionalField[]

  addMaterialSubField(
    fieldId: string,
    key: string,
    index: number,
    fieldType: PermitFieldType,
    customFieldName?: string,
  ): void
  updateMaterialSubField(
    fields: IPermitTypeField[],
    index: number,
    field: IPermitTypeField,
  ): void
  updateLocationRestrictions(
    fields: IPermitTypeField[],
    index: number,
    field: IPermitTypeField,
    restrictions: IRestrictedOption[],
  ): void
}

const QUANTITY_FIELD_DEFAULT_NAME = 'Quantity'
const LOCATION_FIELD_DEFAULT_NAME = 'Location'

@observer
export default class MaterialSubFieldsConfigurator extends React.Component<IProps> {
  public render() {
    return (
      <div className="pb12 px20 ml10">
        <div className="col px10 mb10">
          {!this.alreadyHasQuantity && (
            <span
              className="pointer text large blue-highlight underline-hover mb5"
              onClick={this.addQuantitySubField}
            >
              {
                Localization.translator.formMaterialSubFields
                  .clickToEnableQuantity
              }
            </span>
          )}
          {!this.alreadyHasLocation && (
            <span
              className="pointer text large blue-highlight underline-hover mb5"
              onClick={this.addLocationSubField}
            >
              {
                Localization.translator.formMaterialSubFields
                  .clickToEnableLocation
              }
            </span>
          )}
        </div>
        <div
          className={toggleClass(
            'ba-dashed-palette-grey brada8',
            !!this.materialSubFields.length,
          )}
        >
          {this.materialSubFields.map((field, idx) => (
            <div key={field.id}>
              <div className="row x-center y-center table-field-row">
                <div className="mw20 mx4 row x-start y-center" />
                <FieldConfigurator
                  field={field}
                  isMultipleToggleHidden={true}
                  onChange={this.updateMaterialSubField.bind(null, idx)}
                  onChecklistUpdate={NOOP}
                  onLocationRestrictionsUpdate={this.updateLocationRestrictions.bind(
                    null,
                    idx,
                  )}
                />
                <div className="mw20 mx2" />
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  private get materialSubFields(): IPermitTypeField[] {
    const { allConditionalFields, field } = this.props

    const conditionalField = allConditionalFields?.find(
      cf => cf.fieldId === field.id && cf.key === FORM_MATERIAL_FIELDS_KEY,
    )

    return conditionalField?.values || []
  }

  private get alreadyHasQuantity(): boolean {
    return this.materialSubFields.some(
      f => f.type === PermitFieldType.InputNumber,
    )
  }

  private get alreadyHasLocation(): boolean {
    return this.materialSubFields.some(
      f => f.type === PermitFieldType.LocationInformational,
    )
  }

  private updateMaterialSubField = (index: number, field: IPermitTypeField) => {
    this.props.updateMaterialSubField(this.materialSubFields, index, field)
  }

  private addQuantitySubField = () => {
    if (this.alreadyHasQuantity) {
      return
    }

    this.addMaterialSubField(
      -1,
      PermitFieldType.InputNumber,
      QUANTITY_FIELD_DEFAULT_NAME,
    )
  }

  private addLocationSubField = () => {
    if (this.alreadyHasLocation) {
      return
    }

    this.addMaterialSubField(
      0,
      PermitFieldType.LocationInformational,
      LOCATION_FIELD_DEFAULT_NAME,
    )
  }

  private addMaterialSubField = (
    index: number,
    fieldType: PermitFieldType,
    caption: string,
  ) => {
    const { addMaterialSubField, field } = this.props
    addMaterialSubField(
      field.id,
      FORM_MATERIAL_FIELDS_KEY,
      index,
      fieldType,
      caption,
    )
  }

  private updateLocationRestrictions = (
    index: number,
    field: IPermitTypeField,
    restrictions: IRestrictedOption[],
  ) => {
    this.props.updateLocationRestrictions(
      this.materialSubFields,
      index,
      field,
      restrictions,
    )
  }
}
