import * as React from 'react'

export class HeaderBar extends React.Component<{
  children?: any | void
}> {
  public render() {
    return (
      <div className="row pa20 bb-light-grey bg-white">
        {this.props.children}
      </div>
    )
  }
}
