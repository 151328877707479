import * as React from 'react'

import { inject, observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import BaseSignUpStore from '../../stores/ui/BaseSignUp.store'
import CommonStore from '../../stores/ui/Common.store'
import BaseActionButton from '../BaseActionButton/BaseActionButton'
import FormFieldWrapper from '../FormFieldWrapper/FormFieldWrapper'
import { Content, Footer, Header, View } from '../Layout'

import './BaseSignUp.scss'

interface IProps {
  store: BaseSignUpStore
  header?: JSX.Element

  common?: CommonStore
}

const formId = 'sign-up-form'

// translated

@inject('common')
@observer
export default class BaseSignUp extends React.Component<IProps> {
  private get store(): BaseSignUpStore {
    return this.props.store
  }

  public componentDidMount() {
    this.store.requestProjectName()
  }

  public render() {
    const { header } = this.props
    const { fields, isLoading, errorMessage } = this.store

    return (
      <View className="sign-up-container">
        <Header>
          <div className="col x-center mb20">{header}</div>
        </Header>
        <Content scrollable={true}>
          <form
            id={formId}
            className="col mb20"
            onSubmit={this.handleFormSubmit}
          >
            {fields.map(field => (
              <FormFieldWrapper
                key={field.id}
                field={field}
                className="no-flex"
              />
            ))}
            {this.renderBlockWithLoginLink()}
          </form>
        </Content>
        <Footer>
          <div className="col">
            {this.renderError(errorMessage)}
            <BaseActionButton
              formId={formId}
              className="submit-btn scale-blue-theme py24"
              title={Localization.translator.submit_verb}
              isEnabled={true}
              isLoading={isLoading}
            />
          </div>
        </Footer>
      </View>
    )
  }

  private renderBlockWithLoginLink(): JSX.Element {
    return (
      <div className="row text extra-large mt30 px10">
        <span>{Localization.translator.doYouAlreadyHaveAnAccount}</span>
        <a
          className="text extra-large bold blue-brand underline no-white-space-wrap no-grow"
          onClick={this.props.common.displayLoginView}
        >
          {Localization.translator.signIn}
        </a>
      </div>
    )
  }

  private renderError(error: string): JSX.Element {
    return error && <span className="text large red bold">* {error}</span>
  }

  private handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    this.store.handleSubmit()
  }
}
