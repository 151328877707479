import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { FilterType } from '~/client/graph'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import Keys from '~/client/src/shared/enums/Keys'

import ActivityFiltersViewStore from '../ActivityFiltersView.store'

export interface IProps {
  name: FilterType
  store: ActivityFiltersViewStore
}

const ICON_SIZE = 16
const ICON_BY_FILTER_TYPE = {
  [FilterType.Building]: <TagIcon.Building size={ICON_SIZE} />,
  [FilterType.Level]: <TagIcon.Level size={ICON_SIZE} />,
  [FilterType.Zone]: <TagIcon.Level size={ICON_SIZE} />,
  [FilterType.Company]: <TagIcon.Level size={ICON_SIZE} />,
}

@observer
export default class FilterTabCaption extends React.Component<IProps> {
  @observable private draftCaption: string = null

  @computed
  private get filter() {
    const { name, store } = this.props
    return store.getFilter(name)
  }

  private get caption() {
    return this.filter.getCaption()
  }

  private get icon() {
    const { name } = this.props
    return ICON_BY_FILTER_TYPE[name]
  }

  private get canRemove() {
    return this.filter.isRemovable
  }

  private get canDisable() {
    return this.filter.canBeDisabled
  }

  private get isDisabled() {
    return this.filter.isDisabled
  }

  public render() {
    const { name, store } = this.props
    const { activeTabFilterName, isFilterSetupCompleted } = store

    return (
      <div
        className={classList({
          'filter-name-tab-caption nowrap py10 row y-center x-center full-height relative':
            true,
          active: activeTabFilterName === name,
          disabled: this.isDisabled,
          set: isFilterSetupCompleted(name),
        })}
        onClick={this.selectTab}
      >
        {this.draftCaption !== null && (
          <input
            className="edit-caption-input absolute full-width full-height"
            value={this.draftCaption}
            onChange={this.changeNewCaption}
            onBlur={this.saveFilterName}
            onKeyPress={this.handleOnKeyPress}
            autoFocus={true}
          />
        )}
        <div className="no-grow mr4">{this.icon}</div>
        {this.caption}
        {this.canDisable && (
          <Icon
            icon={this.isDisabled ? IconNames.EYE_OFF : IconNames.EYE_OPEN}
            className="ml20 pt2"
            onClick={this.toggleFilter}
          />
        )}
        {this.canRemove && (
          <Icon
            icon={IconNames.TRASH}
            className="ml20"
            onClick={this.confirmRemove}
          />
        )}
      </div>
    )
  }

  @action.bound
  private handleOnKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === Keys.Enter) {
      this.saveFilterName()
    }
  }

  @action.bound
  private selectTab() {
    const { name, store } = this.props
    if (store.activeTabFilterName === name) {
      this.showEditCaptionInput()
      return
    }
    store.activeTabFilterName = name
  }

  private showEditCaptionInput() {
    if (!this.draftCaption) {
      this.draftCaption = this.caption
    }
  }

  private changeNewCaption = (event: any) => {
    this.draftCaption = event.target.value
  }

  @action.bound
  private saveFilterName() {
    const { name, store } = this.props
    if (this.canSaveCaption) {
      store.renameFilter(name, this.draftCaption)
    }
    this.draftCaption = null
  }

  private get canSaveCaption() {
    return (
      (this.draftCaption.trim() !== this.filter.getCaption() ||
        this.draftCaption.trim() === this.filter.getKnownCaption()) &&
      !this.newCaptionError
    )
  }

  private get newCaptionError() {
    const { name, store } = this.props
    return store.getNewCaptionError(name, this.draftCaption)
  }

  @action.bound
  private confirmRemove(e) {
    e.stopPropagation()

    const { store, name } = this.props
    store.showConfirmDeleteFilterDialog(name)
  }

  @action.bound
  private toggleFilter(e) {
    e.stopPropagation()

    const { store, name } = this.props
    store.toggleFilter(name)
  }
}
