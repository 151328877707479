import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'

import Localization from '../../../localization/LocalizationManager'
import StruxhubInput from '../../StruxhubInputs/StruxhubInput'

interface IProps {
  onSubmit: (email: string, password: string) => void
  onResetPassword: (email: string) => void
}

@observer
export default class EmailPasswordForm extends React.Component<IProps> {
  @observable private email: string = ''
  @observable private password: string = ''

  public render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <StruxhubInput
          type="email"
          name="email"
          label={Localization.translator.email_noun}
          isRequiredTextHidden={true}
          isRequired={true}
          value={this.email}
          onChange={this.handleEmailChange}
          onValueReset={this.handleEmailReset}
          autoComplete="username email"
        />
        <StruxhubInput
          type="password"
          name="password"
          label={Localization.translator.password}
          isRequiredTextHidden={true}
          isRequired={true}
          value={this.password}
          onChange={this.handlePasswordChange}
          onValueReset={this.handlePasswordReset}
          autoComplete="current-password"
        />

        <div className="text right pt10 pb24">
          <a
            className="text extra-large bold blue-brand"
            onClick={() => this.props.onResetPassword(this.email)}
          >
            {Localization.translator.forgotPassword}
          </a>
        </div>

        <button className="full-width bg-color-blue-brand ba-none brada4 pa10">
          <span className="text white bold large lp015">
            {Localization.translator.signIn}
          </span>
        </button>
      </form>
    )
  }

  @action.bound
  private handleEmailChange(evt: React.SyntheticEvent<HTMLInputElement>) {
    this.email = evt.currentTarget.value
  }

  @action.bound
  private handlePasswordChange(evt: React.SyntheticEvent<HTMLInputElement>) {
    this.password = evt.currentTarget.value
  }

  private handleSubmit = (evt: React.SyntheticEvent<HTMLFormElement>) => {
    evt.preventDefault()
    this.props.onSubmit(this.email, this.password)
  }

  @action.bound
  private handleEmailReset() {
    this.email = ''
  }

  @action.bound
  private handlePasswordReset() {
    this.password = ''
  }
}
