import { IContentObject } from '~/client/graph'
import Rfi, { rfiTypes } from '~/client/src/shared/models/Rfi'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'

import EventsStore from '../EventStore/Events.store'
import ContentObjectsStore from './ContentObjects.store'

export default class RfisStore extends ContentObjectsStore<rfiTypes, Rfi> {
  public constructor(messagesStore: MessagesStore, eventsStore: EventsStore) {
    super(messagesStore, eventsStore)
  }

  public get byId() {
    return this.eventsStore.appState.rfis
  }

  protected createAnInstance(id: string, dto?: IContentObject) {
    return new Rfi(id, dto)
  }
}
