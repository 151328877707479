import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IDefaultTeamFieldsFragment = Pick<
  Types.IDefaultTeam,
  'id' | 'createdAt' | 'updatedAt' | 'name' | 'color'
>

export type IDefaultTeamsQueryVariables = Types.Exact<{
  limit?: Types.Maybe<Types.Scalars['Int']>
}>

export type IDefaultTeamsQuery = {
  defaultTeams?: Types.Maybe<{ data: Array<IDefaultTeamFieldsFragment> }>
}

export const DefaultTeamFieldsFragmentDoc = gql`
  fragment DefaultTeamFields on DefaultTeam {
    id
    createdAt
    updatedAt
    name
    color
  }
`
export const DefaultTeamsDocument = gql`
  query DefaultTeams($limit: Int) {
    defaultTeams(limit: $limit) {
      data {
        ...DefaultTeamFields
      }
    }
  }
  ${DefaultTeamFieldsFragmentDoc}
`

/**
 * __useDefaultTeamsQuery__
 *
 * To run a query within a React component, call `useDefaultTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultTeamsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDefaultTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IDefaultTeamsQuery,
    IDefaultTeamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IDefaultTeamsQuery, IDefaultTeamsQueryVariables>(
    DefaultTeamsDocument,
    options,
  )
}
export function useDefaultTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IDefaultTeamsQuery,
    IDefaultTeamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IDefaultTeamsQuery, IDefaultTeamsQueryVariables>(
    DefaultTeamsDocument,
    options,
  )
}
export type DefaultTeamsQueryHookResult = ReturnType<
  typeof useDefaultTeamsQuery
>
export type DefaultTeamsLazyQueryHookResult = ReturnType<
  typeof useDefaultTeamsLazyQuery
>
export type DefaultTeamsQueryResult = Apollo.QueryResult<
  IDefaultTeamsQuery,
  IDefaultTeamsQueryVariables
>
