import * as React from 'react'

import CompactCompaniesDirectory from '~/client/src/shared/components/CompactCompaniesDirectory/CompactCompaniesDirectory'
import Company from '~/client/src/shared/models/Company'

import SitePermitCreationFormStore from '../../../../../SitePermitCreationForm.store'

// localization: no text to translate

interface IProps {
  store: SitePermitCreationFormStore
}

export default class FormCompanyPicker extends React.Component<IProps> {
  public render() {
    return (
      <CompactCompaniesDirectory
        isPhoneHidden
        onCompanyRowClick={this.onCompanyRowClick}
        companyPredicate={this.companyPredicate}
      />
    )
  }

  private companyPredicate = (company: Company): boolean => {
    const { store } = this.props

    const { isCompanyPickerDisplayed, editablePermit } = store

    return isCompanyPickerDisplayed
      ? !editablePermit?.companyIds.includes(company.id)
      : true
  }

  private onCompanyRowClick = ({ id }: Company) => {
    const {
      changeSelectedFieldValue,
      deselectUsersIfNeeded,
      resetLocationsAndEquipmentIfNeed,
    } = this.props.store

    changeSelectedFieldValue(id)
    deselectUsersIfNeeded()
    resetLocationsAndEquipmentIfNeed()
  }
}
