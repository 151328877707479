import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import * as Icons from '~/client/src/shared/components/Icons'
import OperationRule from '~/client/src/shared/models/OperationRule'

import { NotificationSetupModes } from '../../../../NotificationsSetup'
import BaseNotificationRulesStore from '../../BaseNotificationRules.store'
import ActionPicker from '../ActionPicker/ActionPicker'
import ExpressionControl from '../ExpressionControl/ExpressionControl'
import RuleRowStore from './RuleRow.store'

import './RuleRow.scss'

// translated

interface IProps {
  rule: OperationRule
  onRemoveRule: (rule: OperationRule) => void
  onAddAssignmentPlaceholderRow: (rule: OperationRule) => void
  mode: NotificationSetupModes
  onSaveChanges: (rule: OperationRule, shouldSendRequest?: boolean) => void

  measureSize: () => void

  notificationRulesStore: BaseNotificationRulesStore
  state?: DesktopInitialState
}

@inject('state')
@observer
export default class RuleRow extends React.PureComponent<IProps> {
  private readonly store: RuleRowStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new RuleRowStore(
      props.state,
      props.onRemoveRule,
      props.onAddAssignmentPlaceholderRow,
      props.onSaveChanges,
      props.measureSize,
      props.rule,
    )
  }

  public componentDidUpdate() {
    const { rule } = this.props

    if (rule !== this.store.rule) {
      this.store.reinit(rule)
      return
    }

    this.store.measureSize()
  }

  public componentDidMount() {
    if (this.props.measureSize) {
      this.props.measureSize()
    }
  }

  public render() {
    const {
      isHovering,
      rule: { assignmentsAsSubexpressions },
      handleMouseEnter,
      handleMouseLeave,
    } = this.store

    return (
      <div
        className="rule-row-container row x-start align-start"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {this.renderTrashIcon(isHovering)}
        {this.renderExpressionControl()}

        <div className="col no-grow">
          {assignmentsAsSubexpressions.map((expression, i) => {
            const isFirstSubLine = i === 0
            const shouldShowDeleteIcon =
              assignmentsAsSubexpressions.length > 1 || !!expression

            return (
              <div
                key={expression}
                className="row full-height align-start actions-users-container"
              >
                {this.renderActionPicker(isFirstSubLine)}
                {this.renderAssignmentExpression(
                  expression,
                  shouldShowDeleteIcon,
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  private renderTrashIcon(isHovering: boolean): JSX.Element {
    if (!isHovering) {
      return null
    }

    return (
      <span className="row delete-rule-icon-container absolute pointer py20 pr20">
        <Icons.Delete className="no-grow" onClick={this.store.removeRule} />
      </span>
    )
  }

  private renderExpressionControl(): JSX.Element {
    const { rule, changeExpression } = this.store
    const { measureSize, notificationRulesStore, mode } = this.props
    const availableTags = notificationRulesStore.getAvailableTags(
      rule.operationSubject,
    )

    return (
      <div className="row x-start align-start relative">
        <ExpressionControl
          expression={rule.expression}
          subject={rule.operationSubject}
          onChange={changeExpression}
          shouldContainsTagsSelectorHandler={true}
          availableTags={availableTags}
          isElementsRemovable={true}
          measureSize={measureSize}
          mode={mode}
        />
      </div>
    )
  }

  private renderActionPicker(isFirstSubLine: boolean): JSX.Element {
    return (
      <div className="row no-grow full-height px12 action-picker-col">
        {isFirstSubLine && <ActionPicker />}
      </div>
    )
  }

  private renderAssignmentExpression(
    expression: string,
    shouldShowDeleteIcon: boolean,
  ) {
    const {
      selectAssigneeSubexpression,
      removeAssigneeSubexpression,
      clickOnExpressionPlusControl,
    } = this.store
    const { measureSize, notificationRulesStore } = this.props

    return (
      <div className="row no-grow full-height relative user-picker-col">
        <ExpressionControl
          expression={expression}
          isElementsRemovable={true}
          shouldContainsDeleteIcon={shouldShowDeleteIcon}
          shouldUseTagsSelector={true}
          availableTags={notificationRulesStore.userRelatedTags}
          onDeleteExpression={removeAssigneeSubexpression}
          onChange={selectAssigneeSubexpression.bind(null, expression)}
          onPlusControlCustomClick={clickOnExpressionPlusControl}
          measureSize={measureSize}
        />
      </div>
    )
  }
}
