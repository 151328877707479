import * as React from 'react'

import { classList } from 'react-classlist-helper'

import './UploadButton.scss'

interface IProps {
  text: string
  withLeftMargin?: boolean
}

export default class UploadButton extends React.PureComponent<IProps> {
  public render() {
    const { text, withLeftMargin = true } = this.props

    return (
      <div className="row">
        <div className="upload-schedule-link row no-grow">
          <span className="upload-button-icon" />
        </div>
        <span
          className={classList({
            'w-max-content text large': true,
            ml5: withLeftMargin,
          })}
        >
          {text}
        </span>
      </div>
    )
  }
}
