import { action, observable } from 'mobx'

import {
  IMutation,
  ISheetData,
  ISheetDataInput,
  ISubscription,
  IUploadMaterialsProgress,
  UploadMaterialsStatus,
  UploadingType,
} from '~/client/graph'
import { GetMaterialsExcelSheetsDocument } from '~/client/graph/operations/generated/Materials.generated'
import {
  GetCurrentMaterialsUploadProgressDocument,
  ListenToMaterialsUploadProgressDocument,
} from '~/client/graph/operations/generated/UploadMaterialsProgress.generated'
import desktopRoutes from '~/client/src/desktop/constants/desktopRoutes'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import { FileUploadingStore } from '~/client/src/shared/stores/domain/FileUploading.store'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import Guard from '~/client/src/shared/utils/Guard'
import { getPercentCompleteFromRange } from '~/client/src/shared/utils/util'

// localization: translated

const EXCEL_FILE_PATTERN = /\.xls[xm]$/
const WELL_KNOW_SHEET_NAME = 'StruxHub_Export'
const UPLOAD_SUBSCRIPTION_KEY = 'listen-to-materials-upload-progress'

type FileData = {
  name: string
  path: string
  content?: string
}

enum MaterialFileColumnKey {
  PROCUREMENT_ID = 'ProcurementId',
  MATERIAL_CATEGORY = 'MaterialCategory',
  MATERIAL = 'Material',
  DESCRIPTION = 'Description',
  PLANNED_QUANTITY = 'PlannedQuantity',
  INSTALL_LOCATION = 'InstallLocation',
  DELIVERY_LOCATION = 'DeliveryLocation',
}

export default class MaterialsUploadStore {
  @observable public isChooseSheetDialogShown = false
  @observable public isMapFieldsDialogShown = false
  @observable public isErrorDialogShown = false
  @observable public isUploadConfirmDialogShown = false
  @observable public isReplaceDataDialogShown = false

  @observable public uploadedSheets: ISheetData[] = []
  @observable public chosenSheetName: string = null
  @observable public sheetColumns: string[] = []
  @observable public columnMap: any = {}
  @observable public uploadMaterialsProgress: IUploadMaterialsProgress

  @observable private isLoading = false

  private fileData: FileData = null

  public readonly fields = [
    {
      name: MaterialFileColumnKey.PROCUREMENT_ID,
      isRequired: true,
      caption: Localization.translator.procurementID,
    },
    {
      name: MaterialFileColumnKey.MATERIAL_CATEGORY,
      isRequired: true,
      caption: Localization.translator.materialsUploading.materialCategoryLvl1,
    },
    {
      name: MaterialFileColumnKey.MATERIAL,
      isRequired: true,
      caption: Localization.translator.materialsUploading.materialLvl2,
    },
    {
      name: MaterialFileColumnKey.DESCRIPTION,
      caption: Localization.translator.description,
    },
    {
      name: MaterialFileColumnKey.PLANNED_QUANTITY,
      caption: Localization.translator.plannedQuantity,
    },
    {
      name: MaterialFileColumnKey.INSTALL_LOCATION,
      caption: Localization.translator.plannedInstallLocation,
      isFirstLocation: true,
    },
    {
      name: MaterialFileColumnKey.DELIVERY_LOCATION,
      caption: Localization.translator.plannedDeliveryLocation,
    },
  ]

  public constructor(
    private readonly common: DesktopCommonStore,
    private readonly eventsStore: DesktopEventStore,
    private readonly graphExecutorStore: GraphExecutorStore,
    private readonly fileUploadingStore: FileUploadingStore,
    private readonly materialsStore: MaterialsStore,
  ) {
    Guard.requireAll({
      common,
      eventsStore,
      graphExecutorStore,
      fileUploadingStore,
    })
  }

  @action.bound
  public async loadAndListenToUploadProgress() {
    const { id: projectId } = this.eventsStore.appState.activeProject

    this.isLoading = true

    await this.graphExecutorStore.subscribe(
      ListenToMaterialsUploadProgressDocument,
      { projectId },
      false,
      UPLOAD_SUBSCRIPTION_KEY,
      ({ uploadMaterialsProgress: { item } }: ISubscription) => {
        this.setUploadMaterialsProgress(item)
      },
    )

    const queryResult = await this.graphExecutorStore.query(
      GetCurrentMaterialsUploadProgressDocument,
      { projectId },
    )

    this.setUploadMaterialsProgress(
      queryResult?.data?.getCurrentMaterialUploadProgress,
    )

    this.isLoading = false
  }

  public terminateSubscription = () => {
    this.graphExecutorStore.terminateSubscription(UPLOAD_SUBSCRIPTION_KEY)
  }

  public getSheetColumnsAsOptions = (fieldName: string): string[] => {
    const columnsValues = Object.values<string>(this.columnMap)
    return (
      this.selectedSheet?.columns?.filter(
        c => c === this.columnMap[fieldName] || !columnsValues.includes(c),
      ) || []
    )
  }

  @action.bound
  public resetInitialValues() {
    this.closeAllDialogs()

    this.chosenSheetName = null
    this.columnMap = {}
    this.sheetColumns = []
    this.uploadedSheets = []

    this.uploadMaterialsProgress = null
  }

  @action.bound
  public hideErrorDialog() {
    this.isErrorDialogShown = false
  }

  @action
  public uploadFile = async (file: File) => {
    this.resetInitialValues()

    this.uploadMaterialsProgress = {
      failReason: Localization.translator.gettingExcelSheets,
    } as IUploadMaterialsProgress

    const [result] = await this.fileUploadingStore.uploadFile(
      file,
      UploadingType.Material,
      file.name,
    )
    this.handleUploadedFile(file, result.filePath)
  }

  @action.bound
  public showChooseSheetDialog(sheets: ISheetData[]) {
    const wellKnownSheet = sheets.find(s => s.name === WELL_KNOW_SHEET_NAME)
    this.uploadedSheets = wellKnownSheet ? [wellKnownSheet] : sheets

    if (this.uploadedSheets.length === 1) {
      this.chosenSheetName = this.uploadedSheets[0].name
    }

    this.isChooseSheetDialogShown = true
  }

  @action.bound
  public chooseSheetName(name: string) {
    this.chosenSheetName = name
  }

  @action.bound
  public applyChooseSheetDialog() {
    if (!this.chosenSheetName) {
      return
    }

    this.sheetColumns = this.uploadedSheets.find(
      s => s.name === this.chosenSheetName,
    ).columns

    this.columnMap = this.fields.reduce((map, { name }) => {
      if (this.sheetColumns.includes(name)) {
        map[name] = name
      }
      return map
    }, {})

    this.isChooseSheetDialogShown = false
    this.isMapFieldsDialogShown = true
  }

  @action.bound
  public addColumnMap(fieldName: string, fieldNameToMap: string) {
    if (fieldNameToMap) {
      this.columnMap[fieldName] = fieldNameToMap
    } else {
      delete this.columnMap[fieldName]
    }
  }

  @action.bound
  public closeAllDialogs() {
    this.isChooseSheetDialogShown = false
    this.isMapFieldsDialogShown = false
    this.isUploadConfirmDialogShown = false
    this.isReplaceDataDialogShown = false
  }

  @action.bound
  public openPreviousDialog() {
    if (this.isMapFieldsDialogShown) {
      this.closeAllDialogs()
      this.isChooseSheetDialogShown = true
    } else if (this.isUploadConfirmDialogShown) {
      this.closeAllDialogs()
      this.isMapFieldsDialogShown = true
    } else if (this.isReplaceDataDialogShown) {
      this.closeAllDialogs()
      this.isUploadConfirmDialogShown = true
    }
  }

  @action.bound
  public openUploadConfigDialog() {
    this.isMapFieldsDialogShown = false
    this.isUploadConfirmDialogShown = true
  }

  @action.bound
  public openReplaceDataDialog() {
    this.isUploadConfirmDialogShown = false
    this.isReplaceDataDialogShown = true
  }

  @action.bound
  public async uploadMergeMaterialsExcel() {
    this.isUploadConfirmDialogShown = false

    await this.uploadMaterialsExcel(true)
  }

  @action.bound
  public async uploadNewMaterialsExcel() {
    this.isReplaceDataDialogShown = false

    await this.uploadMaterialsExcel(false)
  }

  @action.bound
  private async uploadMaterialsExcel(shouldUpdateExistingData: boolean) {
    const sheetData: ISheetDataInput = {
      name: this.chosenSheetName,
      columns: Object.values(this.columnMap),
      columnsToMapTo: Object.keys(this.columnMap),
    }

    const result = await this.materialsStore.uploadMaterialsExcel(
      this.fileData.path,
      this.fileData.name,
      sheetData,
      shouldUpdateExistingData,
    )

    this.onFileSent(result?.data)
  }

  @action
  private async handleUploadedFile(file: File, filePath: string) {
    this.fileData = { path: filePath, name: file.name }

    if (!EXCEL_FILE_PATTERN.test(this.fileData.name)) {
      return
    }

    try {
      const sheets = await this.getExcelFileSheet()
      this.showChooseSheetDialog(sheets)
    } catch {
      this.isErrorDialogShown = true
      return this.resetInitialValues()
    }
  }

  @action.bound
  private setUploadMaterialsProgress(progress: IUploadMaterialsProgress) {
    this.uploadMaterialsProgress = progress
  }

  public navigateToMaterials = () => {
    this.common._displayView(desktopRoutes.MATERIALS)
  }

  public get isMapDialogBtnEnabled(): boolean {
    return !!this.selectedSheet && this.areRequiredFieldsMapped
  }

  public get selectedSheet(): ISheetData {
    return this.uploadedSheets.find(s => s.name === this.chosenSheetName)
  }

  public get isLoadingOrUpdating(): boolean {
    const { loading } = this.eventsStore.appState
    return (
      loading.get(e.ACTIVATE_PROJECT) ||
      loading.get(e.LOAD_AND_LISTEN_TO_MATERIAL_CATEGORIES) ||
      loading.get(e.LOAD_AND_LISTEN_TO_MATERIALS) ||
      loading.get(e.SAVE_PROJECT) ||
      this.materialsStore.isRestoring ||
      this.isLoading
    )
  }

  public get areRequiredFieldsMapped(): boolean {
    return this.fields
      .filter(f => f.isRequired)
      .every(f => this.columnMap[f.name])
  }

  public get isUploadFailedOrDone(): boolean {
    if (!this.uploadMaterialsProgress) {
      return true
    }
    return [UploadMaterialsStatus.Done, UploadMaterialsStatus.Failed].includes(
      this.uploadMaterialsProgress.status,
    )
  }

  public get uploadStatus(): string {
    if (!this.uploadMaterialsProgress) {
      return ''
    }

    const { status, failedStep, itemsCount, savedItemsCount, failReason } =
      this.uploadMaterialsProgress

    if (!status) {
      return failReason
    }

    return this.getUploadProgressDescription(
      status,
      failedStep,
      itemsCount,
      savedItemsCount,
      failReason,
    )
  }

  public get uploadingProgressPercentage(): number {
    if (!this.uploadMaterialsProgress) {
      return 0.1
    }

    const { status, itemsCount, savedItemsCount } = this.uploadMaterialsProgress

    switch (status) {
      case UploadMaterialsStatus.Starting:
        return 0.1
      case UploadMaterialsStatus.ReadingFile:
        return 0.18
      case UploadMaterialsStatus.ExtractingData:
        return 0.25
      case UploadMaterialsStatus.RemovingOldData:
        return 0.3
      case UploadMaterialsStatus.SavingMaterials:
        return getPercentCompleteFromRange(
          0.4,
          0.9,
          savedItemsCount,
          itemsCount,
        )
      case UploadMaterialsStatus.Finishing:
        return 0.96
      case UploadMaterialsStatus.Done:
        return 1
      case UploadMaterialsStatus.Failed:
        return 1
      default:
        return 0.03
    }
  }

  private getUploadProgressDescription = (
    status: UploadMaterialsStatus,
    failedStep?: UploadMaterialsStatus,
    allItemsCount?: number,
    savedItemsCount?: number,
    failReason?: string,
  ): string => {
    switch (status) {
      case UploadMaterialsStatus.Starting:
        return Localization.translator.materialsUploading.startingTheUpload
      case UploadMaterialsStatus.ReadingFile:
        return Localization.translator.materialsUploading.readingMaterialsFile
      case UploadMaterialsStatus.ExtractingData:
        return (
          Localization.translator.materialsUploading.extractingMaterialsData +
          (allItemsCount
            ? ': ' + Localization.translator.xEntriesInTheFile(allItemsCount)
            : '')
        )
      case UploadMaterialsStatus.RemovingOldData:
        return Localization.translator.materialsUploading.removingOldData
      case UploadMaterialsStatus.SavingMaterials:
        return (
          Localization.translator.materialsUploading.savingMaterialsData +
          (allItemsCount
            ? ': ' +
              Localization.translator.xOfYEntriesSaved(
                savedItemsCount,
                allItemsCount,
              )
            : '')
        )
      case UploadMaterialsStatus.Finishing:
        return Localization.translator.materialsUploading.finishingUpload
      case UploadMaterialsStatus.Failed:
        console.error(failReason)
        return (
          Localization.translator.materialsUploading.uploadOfMaterialsFailedAt +
          ': ' +
          this.getUploadProgressDescription(failedStep)
        )
      case UploadMaterialsStatus.Done:
        return Localization.translator.materialsUploading.uploadWasSuccessful
    }
  }

  private async getExcelFileSheet(): Promise<ISheetData[]> {
    const result = await this.graphExecutorStore.query(
      GetMaterialsExcelSheetsDocument,
      {
        fullFilePath: this.fileData.path,
      },
    )

    if (result.error) {
      return
    }

    return result.data.getMaterialsExcelSheets
  }

  @action.bound
  private onFileSent({ uploadMaterialsExcel }: IMutation) {
    if (uploadMaterialsExcel) {
      this.resetInitialValues()

      this.uploadMaterialsProgress = {
        failReason: uploadMaterialsExcel,
      } as IUploadMaterialsProgress
    }
  }
}
