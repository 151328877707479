import { action } from 'mobx'

import { IQuery } from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import PresentationModeStore from '~/client/src/desktop/stores/ui/PresentationMode.store'
import { IAppConfig } from '~/client/src/shared/Config'
import ProjectStatus from '~/client/src/shared/models/ProjectStatus'
import Guard from '~/client/src/shared/utils/Guard'

import ApiAuthService from '../../shared/services/ApiAuthService'
import FirebaseAuthService from '../../shared/services/FirebaseAuthService'
import ProcoreService from '../../shared/services/ProcoreService'
import RootStore from '../../shared/stores/Root.store'
import SyncRestrictionsStore from '../../shared/stores/domain/SyncRestrictions.store'
import GraphExecutor from '../../shared/utils/GraphExecutor'
import AnalyticsSettingsStore from './domain/AnalyticsSettings.store'

export default class DesktopRootStore extends RootStore {
  public common: DesktopCommonStore
  public presentationModeStore: PresentationModeStore
  public syncRestrictionsStore: SyncRestrictionsStore
  public analyticsSettingsStore: AnalyticsSettingsStore

  public constructor(
    configuration: IAppConfig,
    public state: DesktopInitialState,
    public eventsStore: DesktopEventStore,
    firebaseAuthService: FirebaseAuthService,
    apiAuthService: ApiAuthService,
    procoreService: ProcoreService,
    graphExecutor: GraphExecutor,
    isTestEnv: boolean,
  ) {
    super(
      state,
      configuration,
      eventsStore,
      DesktopCommonStore,
      firebaseAuthService,
      apiAuthService,
      procoreService,
      graphExecutor,
      isTestEnv,
    )

    Guard.requireAll({
      state,
      firebaseAuthService,
      apiAuthService,
      eventsStore,
    })

    this.presentationModeStore = new PresentationModeStore(
      this.state,
      this.common,
    )

    this.syncRestrictionsStore = new SyncRestrictionsStore(
      this.state,
      this.locationAttributesStore,
      this.graphExecutorStore,
    )

    this.analyticsSettingsStore = new AnalyticsSettingsStore(this.eventsStore)
  }

  @action
  public clearOutProjectData() {
    this.clearOutScheduleData()
    this.statusUpdatesStore.clearList()
    this.messagesStore.clearList()
    this.photosStore.clearList()
    this.activityCodeLocationRelationshipsStore.clearList()
    this.basemapsStore.clearList()
    this.sitemapsStore.clearList()
    this.sitemapItemsStore.clearList()
    this.deliveriesStore.clearList()
    this.zonesStore.clearList()
    this.gatesStore.clearList()
    this.offloadingEquipmentsStore.clearList()
    this.buildingsStore.clearList()
    this.routesStore.clearList()
    this.deliveryVehicleTypesStore.clearList()
    this.operationRulesStore.clearList()
    this.areasStore.clearList()
    this.levelsStore.clearList()
    this.locationIntegrationsStore.clearList()
    this.logisticsObjectsStore.clearList()
    this.sitePermitsStore.clearList()
    this.projectTeamsStore.clearList()
    this.projectRolesStore.clearList()
    this.weatherForecastsStore.clearData()
    this.recurringDeliveriesSettingsStore.clearData()
    this.concreteDirectOrdersStore.clearData()
    this.concreteDirectPayloadsStore.clearData()
    this.materialCategoryStore.clearList()
    this.materialsStore.clearList()
    this.analyticsSettingsStore.clearData()
  }

  @action
  public setProjectDataFromSchedule(schedule: IQuery) {
    super.setProjectDataFromSchedule(schedule)

    this.activitiesStore.selection = ''
    this.activitiesStore.multiSelection = []

    const { activities } = schedule
    if (activities.length) {
      const status = ProjectStatus.fromActivities(
        activities,
        this.activitiesStore,
      )
      this.projectsStore.setStatus(this.state.activeProject.id, status)
    }
  }

  public initAllFilters() {
    this.state.appliedCategoryFilters = []
  }

  public clearAllFilters() {
    const filters = [
      this.state.deliveryFilters,
      this.state.logisticsFilters,
      this.state.materialFilters,
      this.state.objectFilters,
      this.state.teamMembersFilters,
      this.state.companiesFilters,
      this.state.sitemapFilters,
      this.state.formsFilters,
    ]

    filters.forEach(({ fieldsMap }) => {
      if (fieldsMap) {
        Object.values(fieldsMap).forEach(field => field.clear())
      }
    })

    this.state.deliveryRequesterCompaniesFromLastSync = []
  }
}
