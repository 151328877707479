import * as React from 'react'

import { Switch } from '@blueprintjs/core'
import { observer } from 'mobx-react'

import { IScreen, PresentationScreenEntityKey } from '~/client/graph'
import Checkbox from '~/client/src/shared/components/Checkbox'
import NumberSelector from '~/client/src/shared/components/NumberSelector/NumberSelector'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import { padWithZero } from '~/client/src/shared/stores/ui/ProjectDate.store'

import PresentationSetUpStore from '../PresentationSetUp.store'

export interface IProps {
  screen: IScreen
  titleTranslatorKey: KnownTranslatorKeys
  descriptionTranslatorKey: KnownTranslatorKeys
  imageSrc: string
  store: PresentationSetUpStore

  isEntityTogglingEnabled?: boolean
  entityType?: PresentationScreenEntityKey
  descriptionEntityTranslatorKey?: KnownTranslatorKeys
}

const SECONDS_IN_MINUTE = 60

const DURATION = {
  MIN: 15,
  STEP: 15,
}
const ORDER_MIN_VALUE = 1

@observer
export default class PresentationScreenComponent extends React.Component<IProps> {
  public render() {
    const {
      imageSrc,
      titleTranslatorKey,
      descriptionTranslatorKey,
      screen,
      isEntityTogglingEnabled,
    } = this.props
    const { isShown, duration, order } = screen
    const { availableScreensCount: screensCount } = this.props.store

    return (
      <div className="presentation-screen">
        <img src={imageSrc} />
        <div className="text large light pt10">
          <Checkbox
            isChecked={isShown}
            className="grey-checkbox"
            onClick={this.onVisibilityChanged}
          />
          <span>{Localization.getText(titleTranslatorKey)}</span>
        </div>
        <div className="text large light pt10">
          {Localization.getText(descriptionTranslatorKey)}
        </div>
        {isShown && (
          <div className="pt10">
            <div className="inline-block mr20">
              <div className="text large light inline-block mr5">
                {Localization.translator.order_noun}
              </div>
              <NumberSelector
                value={order}
                min={ORDER_MIN_VALUE}
                max={screensCount}
                numberFormatter={this.getOrderNumberString}
                onChange={this.onOrderChanged}
              />
            </div>
            <div className="inline-block mr20">
              <div className="text large light inline-block mr5">
                {Localization.translator.display}
              </div>
              <NumberSelector
                value={duration}
                min={DURATION.MIN}
                step={DURATION.STEP}
                numberFormatter={this.getDurationString}
                onChange={this.onDurationChanged}
              />
            </div>
          </div>
        )}
        {isEntityTogglingEnabled && this.renderEntityToggling()}
      </div>
    )
  }

  private renderEntityToggling = () => {
    const {
      descriptionEntityTranslatorKey,
      screen: { shouldShowEntity, entityDuration },
    } = this.props

    return (
      <div className="row pt10 y-start">
        <Switch
          checked={shouldShowEntity}
          onChange={this.toggleShowingEntity}
          className="no-grow primary-blue-switch bp3-align-right no-outline-container"
        />
        <div className="ml5 text large light inline-block pt2">
          {Localization.getText(descriptionEntityTranslatorKey)}
        </div>
        {shouldShowEntity && (
          <div className="ml10 pt2">
            <div className="inline-block mr20">
              <div className="text large light inline-block mr5">
                {Localization.translator.display}
              </div>
              <NumberSelector
                value={entityDuration || DURATION.MIN}
                min={DURATION.MIN}
                step={DURATION.STEP}
                numberFormatter={this.getDurationString}
                onChange={this.onEntityDurationChanged}
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  private toggleShowingEntity = () => {
    const { store, screen } = this.props
    store.onItemEntityVisibilityChanged(screen.key, !screen.shouldShowEntity)
  }

  private onVisibilityChanged = () => {
    const { store, screen } = this.props
    store.onItemVisibilityChanged(screen.key, !screen.isShown)
  }

  private onEntityDurationChanged = (newValue: number) => {
    const { store, screen } = this.props
    store.onItemEntityDurationChanged(screen.key, newValue)
  }

  private onDurationChanged = (newValue: number) => {
    const { store, screen } = this.props
    store.onItemDurationChanged(screen.key, newValue)
  }

  private onOrderChanged = (newValue: number) => {
    const { store, screen } = this.props
    store.onItemOrderChanged(screen.order - 1, newValue - 1)
  }

  private getDurationString(value: number) {
    const minutesCount = Math.floor(value / SECONDS_IN_MINUTE)
    const secondsCount = value % SECONDS_IN_MINUTE
    const secondsString = padWithZero(secondsCount)
    return `${minutesCount}:${secondsString}`
  }

  private getOrderNumberString(value: number) {
    return Localization.translator.order_number(value)
  }
}
