import * as React from 'react'

import { classList } from 'react-classlist-helper'

interface IProps {
  value: number
  smallerValue?: number
  negativeLabel: string
  positiveLabel: string
  isNegativeArrowShown?: boolean
  isMidArrowShown?: boolean
  isPositiveArrowShown?: boolean
  areColorsInverted?: boolean
}

export default class ThresholdsChart extends React.Component<IProps> {
  public render() {
    const {
      negativeLabel,
      positiveLabel,
      areColorsInverted,
      isNegativeArrowShown,
      isMidArrowShown,
      isPositiveArrowShown,
      value,
      smallerValue = 0,
    } = this.props

    const cellWidthPercent = 100 / (this.range.length + 1)

    const rangeAreaPercent = 100 - cellWidthPercent * 2

    const minRangeValue = this.range[0]
    const maxRangeValue = this.range[this.range.length - 1]
    const rangeDelta = maxRangeValue - minRangeValue
    const normalizedValue = value - minRangeValue

    const valuePosition =
      (normalizedValue / rangeDelta) * rangeAreaPercent + cellWidthPercent

    const normalizedSmallerValue = smallerValue - minRangeValue

    const smallerValuePosition =
      (normalizedSmallerValue / rangeDelta) * rangeAreaPercent +
      cellWidthPercent

    return (
      <div className="thresholds-chart mt20">
        <div className="row text light">
          <div className="row x-start pl10">{negativeLabel}</div>
          <div className="row x-end pr10">{positiveLabel}</div>
        </div>
        <div className="thresholds-chart-container">
          <div className="line" />
          {this.range.map((r, idx) => (
            <div
              className="delimiter"
              key={idx}
              style={{ left: `${(idx + 1) * cellWidthPercent}%` }}
            />
          ))}
          {isPositiveArrowShown && (
            <div
              className={classList({
                'positive-arrow': true,
                red: areColorsInverted,
              })}
              style={{ left: `${valuePosition}%` }}
            />
          )}
          {isMidArrowShown && (
            <div
              className="mid-arrow"
              style={{
                left: `${smallerValuePosition}%`,
                right: `${100 - valuePosition}%`,
              }}
            />
          )}
          {isNegativeArrowShown && (
            <div
              className={classList({
                'negative-arrow': true,
                green: areColorsInverted,
              })}
              style={{ right: `${100 - valuePosition}%` }}
            />
          )}
        </div>
        <div
          className="row text"
          style={{ padding: `5px ${cellWidthPercent / 2}%` }}
        >
          {this.range.map((label, idx) => (
            <div className="row x-center" key={idx}>
              {label}
            </div>
          ))}
        </div>
      </div>
    )
  }

  private get range() {
    const base = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]
    const { value, smallerValue = 0 } = this.props
    const coef =
      Math.ceil(Math.max(Math.abs(value), Math.abs(smallerValue)) / 5) || 1
    return base.map(p => p * coef)
  }
}
