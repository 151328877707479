import {
  IGenerateReportCommandInput,
  ISiteLocation,
} from '~/client/graph/types.generated'
import { IAppConfig } from '~/client/src/shared/Config'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import SitePermit from '~/client/src/shared/models/Permit'
import Sitemap from '~/client/src/shared/models/Sitemap'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { DOWNLOAD_REPORT_FILE } from '~/client/src/shared/stores/EventStore/eventConstants'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

const FORM_TEMPLATE_ID = 'form_view_report'

export default class FormReportGenerationStore {
  public constructor(
    private readonly projectDateStore: ProjectDateStore,
    private readonly eventsStore: EventsStore,
    private readonly configuration: IAppConfig,
    private readonly sitemapItemsStore: SitemapItemsStore,
    private readonly sitemapsStore: SitemapsStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly permitTypesStore: PermitTypesStore,
  ) {}

  public generateAndSendReport = (
    form: SitePermit,
    additionalRecipientIds?: string[],
    callbackFn?: () => void,
  ) => {
    const { getClientTimezoneId, getMonthAndDayToDisplay } =
      this.projectDateStore
    const { activeProject } = this.eventsStore.appState

    const formTypeName = form.getNameOfPermitType(this.permitTypesStore)
    const now = Date.now()
    const data = {
      timezoneId: getClientTimezoneId(),
      dateTimeFormat: activeProject.dateTimeFormat,
      projectId: activeProject.id,
      materialsUploadId: activeProject.materialsUploadId,
      logoUrl: activeProject.logoUrl,
      permit: form.getReportDto(),
      overviewMaps: this.getSitemapImagesForReport(form.locationsWithEquipment),
      qrCodeLink: SitePermit.getDirectLinkToInstance(
        activeProject.code,
        form.id,
      ),
    }
    const filename = `${this.configuration.TENANT_ID}_${
      activeProject.name
    }_${getMonthAndDayToDisplay(now)}${Localization.translator.reportOf(
      formTypeName,
    )}`

    const generateReportInput: IGenerateReportCommandInput = {
      templateId: FORM_TEMPLATE_ID,
      files: [{ fileId: '', fileName: `${filename}.pdf` }],
      title: Localization.translator.reportOf(formTypeName),
      dataJSON: JSON.stringify(data),
      projectId: activeProject.id,
      dateTo: now,
      dateFrom: now,
      timezoneId: data.timezoneId,
      sendEmail: true,
      isFullTime: false,
      additionalRecipientIds: additionalRecipientIds.filter(uId =>
        this.projectMembersStore.hasUserAccessToActiveProjectById(uId),
      ),
    }

    this.eventsStore.dispatch(
      DOWNLOAD_REPORT_FILE,
      generateReportInput,
      callbackFn,
    )
  }

  private getSitemapImagesForReport(
    selectedLocations: ISiteLocation[],
  ): string[] {
    const selectedSitemapItems = this.sitemapItemsStore.list.filter(item =>
      selectedLocations?.some(({ id }) => id === item.assignedId),
    )

    return this.formsSitemaps
      .filter(s => {
        if (s.isProjectOverviewMap) {
          return true
        }

        const assignedAttributesIds: string[] = this.getAssignedAttributesIds(
          s.id,
        )
        return selectedSitemapItems.some(item =>
          assignedAttributesIds.includes(item.assignedId),
        )
      })
      .map(s => s.filledImage)
  }

  private getAssignedAttributesIds(sitemapId: string): string[] {
    const attributes = this.attributesBySitemaps[sitemapId]
    if (attributes) {
      return attributes
    }

    const { firstBuilding } = this.locationAttributesStore.buildingsStore
    return firstBuilding ? [firstBuilding.id] : []
  }

  private get formsSitemaps(): Sitemap[] {
    const { maps } = this.eventsStore.appState.logistics.configurations
    return this.sitemapsStore.list.filter(s =>
      maps.some(map => map.sitemapId === s.id),
    )
  }

  private get attributesBySitemaps(): { [sitemapId: string]: string[] } {
    return this.locationAttributesStore.allAttributes.reduce(
      (map, attribute) => {
        if (attribute) {
          this.formsSitemaps.forEach(sitemap => {
            if (attribute.isSitemapAssigned(sitemap.id)) {
              if (map[sitemap.id]) {
                map[sitemap.id].push(attribute.id)
              } else {
                map[sitemap.id] = [attribute.id]
              }
            }
          })
        }
        return map
      },
      {},
    )
  }
}
