import { action, computed, observable } from 'mobx'

import HeaderBarStore from '~/client/src/desktop/components/HeaderBar/HeaderBar.store'
import {
  IModeSettings,
  getModeSettings,
} from '~/client/src/desktop/components/HeaderBar/components/ViewModeToggle/ViewModeToggle'
import { TwoMonthsDatePickerMode } from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import ActivitiesDatePickerModes from '~/client/src/shared/enums/ActivitiesDatePickerModes'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

export default class ActivitiesHeaderBarStore extends HeaderBarStore {
  @observable public isDatePickerModeSelectorOpen: boolean = false

  public constructor(
    protected readonly state: DesktopInitialState,
    private readonly activityListStore: DesktopActivityListStore,
    private readonly eventsStore: DesktopEventStore,
    private readonly userProjectsStore: UserProjectsStore,
  ) {
    super(state)
    this.viewState = state.activityList
  }

  @action.bound
  public setActivitiesDatePickerMode(mode: ActivitiesDatePickerModes) {
    this.userActiveProjectSettings.activitiesDatePickerMode = mode

    this.activityListStore.recalculateDaysToAdd()
    this.userProjectsStore.save([this.userActiveProjectSettings.toDto()])
  }

  @action.bound
  public toggleDatePickerModeSelector() {
    this.isDatePickerModeSelectorOpen = !this.isDatePickerModeSelectorOpen
  }

  public get userActiveProjectSettings() {
    return this.state.userActiveProjectSettings
  }

  public get forceLocationCloseMap() {
    const { locationsMap } = this.state.filters
    return Object.keys(locationsMap).reduce((map, filterType) => {
      map[filterType] = this.openedPopup && this.openedPopup !== filterType
      return map
    }, {})
  }

  @computed
  public get viewModes(): IModeSettings[] {
    return [
      getModeSettings(ViewModes.Gantt),
      getModeSettings(ViewModes.List),
      getModeSettings(ViewModes.Map, this.isMapViewDisabled),
    ]
  }

  private get isMapViewDisabled(): boolean {
    const { activitiesSitemaps, activitiesGlobes } = this.activityListStore
    return !activitiesSitemaps?.length && !activitiesGlobes?.length
  }

  public setInitialDateValue() {
    this.activityListStore.setInitialDateValue()
  }

  public get shouldEnableFullProjectMode(): boolean {
    return this.state.activityList.viewMode === ViewModes.List
  }

  public get selectedDatePickerMode() {
    return (
      this.userActiveProjectSettings.activitiesDatePickerMode ||
      ActivitiesDatePickerModes.threeWeeks
    )
  }

  public get twoMonthsDatePickerMode() {
    switch (this.selectedDatePickerMode) {
      case ActivitiesDatePickerModes.week:
        return TwoMonthsDatePickerMode.FIXED_WEEK
      case ActivitiesDatePickerModes.twoWeeks:
        return TwoMonthsDatePickerMode.FIXED_TWO_WEEKS
      case ActivitiesDatePickerModes.threeWeeks:
        return TwoMonthsDatePickerMode.FIXED_THREE_WEEKS
      case ActivitiesDatePickerModes.fourWeeks:
        return TwoMonthsDatePickerMode.FIXED_FOUR_WEEKS
      case ActivitiesDatePickerModes.sixWeeks:
        return TwoMonthsDatePickerMode.FIXED_SIX_WEEKS
    }
  }
}
