import { action, computed } from 'mobx'

import {
  FilterType,
  IActivityFiltersSettings,
  LocationType,
} from '~/client/graph'
import { SaveManyActivityLocationDocument } from '~/client/graph/operations/generated/ActivityLocation.generated'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { UPDATE_ACTIVITY_FILTERS_SETTINGS } from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivityCodesStore from '~/client/src/shared/stores/domain/ActivityCodes.store'
import ResourcesStore from '~/client/src/shared/stores/domain/Resources.store'
import ActivityFiltersSettingState from '~/client/src/shared/stores/substates/ActivityFiltersSettingState'
import { sortCaseInsensitive } from '~/client/src/shared/utils/collections'

import Company from '../../models/Company'
import LocationBase from '../../models/LocationObjects/LocationBase'
import User from '../../models/User'
import UserProject from '../../models/UserProject'
import { UNASSIGNED_FILTER_VALUE } from '../../utils/ZoneLevelLocationConstants'
import capitalize from '../../utils/capitalizeText'
import ActivitiesStore from './Activities.store'
import ActivityCodeLocationRelationshipsStore from './ActivityCodeLocationRelationships.store'
import ActivityCodeTypesStore from './ActivityCodeTypes.store'
import ActivityCompanyRelationshipsStore from './ActivityCompanyRelationships.store'
import BuildingsStore from './Buildings.store'
import CompaniesStore from './Companies.store'
import GraphExecutorStore from './GraphExecutor.store'
import LevelsStore from './Levels.store'
import ProjectMembersStore from './ProjectMembers.store'
import UserProjectsStore from './UserProjects.store'
import ZonesStore from './Zones.store'

const FILTER_TYPES_TO_SET_DEFAULT_VALUES = [
  FilterType.Building,
  FilterType.Level,
  FilterType.Zone,
]

export default class ActivityFiltersStore {
  public get settings() {
    return this.eventsStore.appState.activityFiltersSettings
  }

  @computed
  public get filterInfoList() {
    const { filterInfoMap } = this.settings
    return Object.values(filterInfoMap)
  }

  @computed
  public get allCompanies() {
    const companyNameFiled = 'name'

    // Get names
    const allCompanyNames = this.resourcesStore.maps.resourceActivities
      ? Object.keys(this.resourcesStore.maps.resourceActivities).map(id => {
          const item = this.resourcesStore.byId.get(id)
          return item ? item[companyNameFiled] : null
        })
      : []

    return sortCaseInsensitive(
      Array.from(new Set(allCompanyNames.filter(name => !!name))),
    )
  }

  public get companiesFromScheduleCount() {
    return this.resourcesStore.list.length
  }

  @computed
  public get enabledFilterTypes() {
    const { filterInfoMap } = this.settings
    return Object.keys(filterInfoMap).filter(type => {
      return !filterInfoMap[type].isDisabled
    })
  }

  @computed
  public get enabledLocationFilterTypes(): string[] {
    return this.enabledFilterTypes.filter(t =>
      this.locationsFilterTypeList.includes(t),
    )
  }

  public get allBands() {
    return this.enabledFilterTypes.filter(type => {
      return this.settings.filterInfoMap[type]
    })
  }

  public get areCompaniesEnabled(): boolean {
    return this.enabledFilterTypes.includes(FilterType.Company)
  }

  @computed
  public get activityCodeFilterTypes() {
    return this.enabledFilterTypes.filter(type => type !== FilterType.Company)
  }

  @computed
  public get allFilterTypes() {
    const { filterInfoMap } = this.settings
    return Object.keys(filterInfoMap)
  }

  @computed
  private get codesByFilterTypeMap() {
    const map = {}
    this.allFilterTypes.forEach(filterType => {
      const { codeTypeIds, hiddenCodeIds } =
        this.settings.filterInfoMap[filterType]
      const codes = this.activityCodesStore.list.filter(code => {
        return (
          codeTypeIds.includes(code.activityCodeTypeId) &&
          !hiddenCodeIds.includes(code.id)
        )
      })

      map[filterType] = codes
    })

    return map
  }

  @computed
  private get projectMembersByFilterTypeMap() {
    const map = {}
    this.allFilterTypes.forEach(filterType => {
      const { codeTypeIds, hiddenCodeIds } =
        this.settings.filterInfoMap[filterType]
      const members = this.projectMembersStore.list.filter(user => {
        return (
          codeTypeIds.includes(
            UserProject.getCompanyId(user, this.userProjectsStore),
          ) && !hiddenCodeIds.includes(user.id)
        )
      })

      map[filterType] = members
    })

    return map
  }

  @computed
  private get codesByFilterTypeCodeTypeIdMap() {
    const map = {}
    this.allFilterTypes.forEach(filterType => {
      map[filterType] = {}
      const { codeTypeIds } = this.settings.filterInfoMap[filterType]
      codeTypeIds.forEach(codeTypeId => {
        const codes = this.codesByFilterTypeMap[filterType].filter(
          code => codeTypeId === code.activityCodeTypeId,
        )

        map[filterType][codeTypeId] = codes
      })
    })

    return map
  }

  @computed
  public get activitiesCodesByCompanyMap() {
    const map = {}
    const increment = (companyId: string, activityCode: string) => {
      if (!map[companyId]) {
        map[companyId] = []
      }
      map[companyId].push(activityCode)
    }

    this.activitiesStore.fullList.forEach(activity => {
      if (!activity.company) {
        increment(UNASSIGNED_FILTER_VALUE, activity.code)
      } else {
        increment(activity.company.id, activity.code)
      }
    })

    return map
  }

  public constructor(
    private eventsStore: EventsStore,
    private activityCodesStore: ActivityCodesStore,
    private activityCodeTypesStore: ActivityCodeTypesStore,
    private resourcesStore: ResourcesStore,
    private activityCompanyRelationshipsStore: ActivityCompanyRelationshipsStore,
    private activityLocationsStore: ActivityCodeLocationRelationshipsStore,
    private activitiesStore: ActivitiesStore,
    private buildingsStore: BuildingsStore,
    private levelsStore: LevelsStore,
    private zonesStore: ZonesStore,
    private companiesStore: CompaniesStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly graphExecutorStore: GraphExecutorStore,
  ) {}

  public setDefaultActivityCodeFilters() {
    const { filterInfoMap } = this.settings

    FILTER_TYPES_TO_SET_DEFAULT_VALUES.forEach(filterType => {
      const filter = filterInfoMap[filterType]

      const hadFilterSetUp =
        filter.codeTypeIds.length || filter.hiddenCodeIds.length
      const defaultCodeType = this.activityCodeTypesStore.list.find(
        type =>
          type.name === filter.getCaption() ||
          type.name === capitalize(filter.name),
      )

      if (!hadFilterSetUp && defaultCodeType) {
        filter.codeTypeIds = [defaultCodeType.id]
      }
    })

    this.eventsStore.dispatch(UPDATE_ACTIVITY_FILTERS_SETTINGS)
  }

  public async setDefaultActivityLocationRelationships(
    filterTypeOnly?: FilterType,
    locationOnly?: LocationBase,
  ) {
    const existingRelationships = this.activityLocationsStore.list
    const relationshipsToCreate = []

    FILTER_TYPES_TO_SET_DEFAULT_VALUES.forEach(filterType => {
      if (filterTypeOnly && filterTypeOnly !== filterType) {
        return
      }

      const codes = this.getCodesByFilterType(filterType)

      let locationType: LocationType
      let locations: LocationBase[] = []
      switch (filterType) {
        case FilterType.Building:
          locationType = LocationType.Building
          locations = this.buildingsStore.list
          break
        case FilterType.Level:
          locationType = LocationType.Level
          locations = this.levelsStore.list
          break
        case FilterType.Zone:
          locationType = LocationType.Zone
          locations = this.zonesStore.list
          break
        default:
          return
      }

      if (locationOnly && locationOnly.id) {
        locations = [locationOnly]
      }

      codes.forEach(code => {
        locations.forEach(location => {
          const canBeAssigned =
            (code.name &&
              location.name.trim().toLowerCase() ===
                code.name.trim().toLowerCase()) ||
            (code.shortName &&
              location.name.trim().toLowerCase() ===
                code.shortName.trim().toLowerCase())

          if (!canBeAssigned) {
            return
          }

          const doesRelationshipExist = existingRelationships.find(
            r =>
              r.activityCodeId === code.id &&
              r.locationObjectId === location.id,
          )

          if (doesRelationshipExist) {
            return
          }

          const dto = this.activityLocationsStore.getRelationshipDto(
            code.id,
            location.id,
            locationType,
          )
          relationshipsToCreate.push(dto)
        })
      })
    })

    if (relationshipsToCreate.length) {
      await this.graphExecutorStore.mutate(SaveManyActivityLocationDocument, {
        activityLocations: relationshipsToCreate,
      })
    }
  }

  public removeCustomFilter(filterType: string) {
    delete this.settings.filterInfoMap[filterType]
    this.eventsStore.dispatch(UPDATE_ACTIVITY_FILTERS_SETTINGS)
  }

  @action
  public receiveSettings(settings: IActivityFiltersSettings) {
    const { appState } = this.eventsStore
    appState.activityFiltersSettings = new ActivityFiltersSettingState()

    if (settings) {
      appState.activityFiltersSettings.updateFromDto(settings)
    }
  }

  public getCodesByFilterType = (filterType: string) => {
    return this.codesByFilterTypeMap[filterType] || []
  }

  public getCodesByTypeIdMapByFilter = (filterType: string) => {
    return this.codesByFilterTypeCodeTypeIdMap[filterType]
  }

  public getProjectMembersByFilterType = (filterType: string): User[] => {
    return this.projectMembersByFilterTypeMap[filterType]
  }

  public getActivityLinkedCompany(code: string): Company {
    const externalResourceId =
      this.resourcesStore.getResourceByActivityId(code)?.id
    const linkedCompanyId =
      this.activityCompanyRelationshipsStore.getCompanyIdByResourceId(
        externalResourceId,
      )

    return this.companiesStore.getCompanyById(linkedCompanyId, true)
  }

  private get locationsFilterTypeList(): string[] {
    return [FilterType.Building, FilterType.Level, FilterType.Zone]
  }
}
