import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import { IAttachment } from '~/client/graph'
import PhotoContainer from '~/client/src/shared/components/CommonThread/components/PhotoContainer'
import ActivityLogEntry from '~/client/src/shared/components/Entry/Entry'
import EntryLeftCol from '~/client/src/shared/components/Entry/EntryLeftCol'
import PhotoThumbnail from '~/client/src/shared/components/PhotoThumbnail/PhotoThumbnail'
import Timestamp from '~/client/src/shared/components/Timestamp'
import UsernameFromUid from '~/client/src/shared/components/UsernameFromUid'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IMessage from '~/client/src/shared/models/Message/IMessage'
import Photo from '~/client/src/shared/models/Photo'
import PhotosStore from '~/client/src/shared/stores/domain/Photos.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import BaseActionBarStore from '~/client/src/shared/stores/ui/BaseActionBar.store'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

import MessagesStore from '../../../stores/domain/MessagesStore/Messages.store'
import CommonStore from '../../../stores/ui/Common.store'
import FileInputBase from '../../FileInput/FileInput'
import AttachmentsContainer from './AttachmentsContainer'
import DeliveryColumn from './DeliveryColumn'
import FormColumn from './FormColumn'

// translated

export interface IThreadEntryProps {
  message: IMessage
  threadClass: string
  showImages: boolean
  hideThread: boolean
  attachments?: IAttachment[]
  rightAlign?: boolean
  replyCaption?: string
  isPhotoThread?: boolean
  photosStore?: PhotosStore
  messagesStore?: MessagesStore
  customPhotoClick: (e: React.MouseEvent<HTMLElement>, url?: string) => void
  FileInputType: typeof FileInputBase
  isLastEntryInLog?: boolean
  actionBarStore?: BaseActionBarStore
  slackBarStore?: SlackBarStore
  projectMembersStore?: ProjectMembersStore
  shouldUseFullSize?: boolean
  isDeliveryColumn?: boolean
  isActivityColumn?: boolean
  isFormColumn?: boolean
  common?: CommonStore
}

@inject(
  'photosStore',
  'slackBarStore',
  'common',
  'messagesStore',
  'projectMembersStore',
)
@observer
export default class CommonThreadEntryComponent extends React.Component<IThreadEntryProps> {
  private readonly actionBarStore: BaseActionBarStore = null

  public constructor(props: IThreadEntryProps) {
    super(props)

    this.actionBarStore = props.actionBarStore || props.slackBarStore
  }

  @computed
  public get entryPhoto(): Photo {
    const { showImages, message } = this.props

    if (showImages) {
      return this.props.photosStore.list.find(p => p.messageId === message.id)
    }
  }

  @computed
  public get attachments(): IAttachment[] {
    const { message, messagesStore } = this.props

    return messagesStore.byId.get(message.id)?.attachments
  }

  public get user() {
    return this.props.projectMembersStore.getById(this.props.message.userId)
  }

  public renderLeftColumn(message: IMessage) {
    return (
      <div className="relative-block thread-line-centred">
        <div className="text white-back left light absolute-block">
          <Timestamp time={message.createdAt} />
        </div>
        <div className="thread-vertical-line" />
      </div>
    )
  }

  public renderRightColumn(message: IMessage) {
    const {
      customPhotoClick,
      replyCaption,
      isPhotoThread,
      FileInputType,
      isLastEntryInLog,
    } = this.props
    const { isImageUploading } = this.actionBarStore

    const shouldShowThumbnail = isImageUploading && isLastEntryInLog

    return (
      <>
        {replyCaption && (
          <div className="text bold primary-blue uppercase mb5 mt20 line-large">
            {replyCaption}
          </div>
        )}
        <div className="text large bold mb5 line-large">
          <UsernameFromUid showCompany={true} userId={message.userId} />
        </div>
        <div>
          {shouldShowThumbnail ? (
            <PhotoThumbnail />
          ) : (
            <PhotoContainer
              customPhotoClick={customPhotoClick}
              photo={this.entryPhoto}
              isPhotoThread={isPhotoThread}
              caption=""
              FileInputType={FileInputType}
            />
          )}
          <AttachmentsContainer attachments={this.attachments} />
          <div className="row">
            <div
              className={
                'text large mb5 line-large' +
                (message.text ? '' : ' italic faded')
              }
            >
              {message.text || `[${Localization.translator.noMessage}]`}
            </div>
          </div>
        </div>
      </>
    )
  }

  private renderDeliveryColumn(message: IMessage) {
    return (
      <DeliveryColumn
        {...message}
        {...this.props}
        user={this.user}
        entryPhoto={this.entryPhoto}
      />
    )
  }

  private renderDoubleColumns(message: IMessage) {
    return (
      <>
        {!this.props.rightAlign && (
          <EntryLeftCol>{this.renderLeftColumn(message)}</EntryLeftCol>
        )}
        <div className="col">{this.renderRightColumn(message)}</div>
        {this.props.rightAlign && (
          <EntryLeftCol>{this.renderLeftColumn(message)}</EntryLeftCol>
        )}
      </>
    )
  }

  public render() {
    const { hideThread, threadClass, common } = this.props
    let { customPhotoClick } = this.props

    if (!customPhotoClick && this.entryPhoto) {
      customPhotoClick = () => common.displayPhotoView(this.entryPhoto)
    }

    return (
      <ActivityLogEntry
        customClass={
          'y-stretch ' + (hideThread ? 'invisible-thread' : threadClass)
        }
        paddingBottom={+0}
      >
        {this.threadColumns}
      </ActivityLogEntry>
    )
  }

  private get threadColumns(): JSX.Element {
    const { message, isDeliveryColumn, isActivityColumn, isFormColumn } =
      this.props

    if (isFormColumn) {
      return (
        <FormColumn
          {...this.props}
          user={this.user}
          entryPhoto={this.entryPhoto}
          attachments={this.attachments}
        />
      )
    }
    if (isDeliveryColumn || isActivityColumn) {
      return this.renderDeliveryColumn(message)
    }
    return this.renderDoubleColumns(message)
  }
}
