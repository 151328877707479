import { IActivityCodeRelationship } from '~/client/graph'
import BaseModel from '~/client/src/shared/models/BaseModel'
import Guard from '~/client/src/shared/utils/Guard'

export default class ActivityCodeRelationship extends BaseModel<IActivityCodeRelationship> {
  public static fromDto(dto: IActivityCodeRelationship) {
    return new ActivityCodeRelationship(
      dto.id,
      dto.activityCodeTypeId,
      dto.activityP6Code,
      dto.activityCodeId,
      dto.scheduleId,
      dto.xerEntityId,
      dto.isDeleted,
    )
  }

  public constructor(
    public readonly id: string,
    public readonly activityCodeTypeId: string,
    public readonly activityId: string,
    public readonly activityTypeId: string,
    public readonly scheduleId: string,
    public readonly xerEntityId: string = null,
    public readonly isDeleted: boolean = false,
  ) {
    super(id)
    Guard.againstUndefined(id, 'id')
  }
}
