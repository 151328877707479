import * as React from 'react'

import { observable } from 'mobx'

import InitialState from '~/client/src/shared/stores/InitialState'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import PhotosStore from '~/client/src/shared/stores/domain/Photos.store'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'

interface IProps {
  activitiesStore?: ActivitiesStore
  photosStore?: PhotosStore
  messagesStore?: MessagesStore
  common?: CommonStore
  state?: InitialState
  match?: any
  photoId?: string
}

export default class PhotoDetailsBase<
  T extends IProps,
> extends React.Component<T> {
  @observable protected isPortrait = true

  protected get photo() {
    const photoId = this.props.match.params.photoId || this.props.photoId

    return this.props.photosStore.byId.get(photoId)
  }

  protected get activity() {
    if (this.photo) {
      return this.props.activitiesStore.byId.get(
        this.photo.getActivityObjectId(),
      )
    }
  }

  protected get caption() {
    if (this.photo) {
      return this.props.messagesStore.byId.get(this.photo.messageId)
    }
  }

  public handleLayout = () => {
    this.isPortrait = !this.isPortrait
  }

  public backClicked = () => {
    this.props.common.toActivityDetails()
  }
}
