import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import { AnnouncementRelationType } from '~/client/graph'
import AnnouncementListItem from '~/client/src/shared/components/AnnouncementListItem'
import * as Icons from '~/client/src/shared/components/Icons'
import Announcement from '~/client/src/shared/models/Announcement'
import SitePermit from '~/client/src/shared/models/Permit'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { areArraysEqual } from '~/client/src/shared/utils/util'

import { getCorrectModalOffsetPosition } from '../../utils/SitemapCalculationHelpers'
import PermitTypeIcon from '../PermitTypeIcon/PermitTypeIcon'

import './CompactAnnouncementCard.scss'

interface IProps {
  announcements: Announcement[]
  topOffset: number
  leftOffset: number
  deleteAnnouncement: (announcement: Announcement) => void
  showAnnouncement: (announcement: Announcement) => void
  containerRef: HTMLElement

  openPermit: (permit: SitePermit) => void
  sitePermitsStore?: SitePermitsStore
  projectDateStore?: ProjectDateStore
  permitTypesStore?: PermitTypesStore
}

const MODAL_OFFSET_Y = 80
const MODAL_OFFSET_X = 40
const siteAnnouncement = 'Site announcement'

@inject('projectDateStore', 'sitePermitsStore', 'permitTypesStore')
@observer
export default class CompactAnnouncementCard extends React.Component<IProps> {
  @observable private selectedIndex: number = 0
  private modalRef: HTMLDivElement = null
  @observable private left: number = 0
  @observable private top: number = 0

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (
      !areArraysEqual(this.props.announcements, prevProps.announcements) ||
      prevProps.leftOffset !== this.props.leftOffset ||
      prevProps.topOffset !== this.props.topOffset
    ) {
      this.setInitPosition()
    }
  }
  public componentDidMount() {
    this.setInitPosition()
  }

  public render() {
    const { showAnnouncement } = this.props

    return (
      <div
        ref={this.setRef}
        style={{ left: this.left, top: this.top }}
        className="compact-announcement-holder absolute bg-white brada10 z-index-1"
      >
        <div className="compact-announcement col relative">
          {this.renderHeader()}
          <AnnouncementListItem
            key={this.selectedAnnouncement.id}
            announcement={this.selectedAnnouncement}
            onClick={showAnnouncement}
            className="workflow-card text large pa10 scrollable ios-scrolling-container"
            isMapCard={true}
            shouldShowPdfPreview={true}
            isContentExpanded={true}
          />
        </div>
        {this.renderRelatedPermit()}
      </div>
    )
  }

  private renderHeader() {
    const {
      showAnnouncement,
      projectDateStore: { getPronounWithMonthDayAndTimeString },
    } = this.props
    const { dateToAppear, startDate } = this.selectedAnnouncement

    return (
      <div className="compact-announcement-header row pa12">
        <Icons.Maximize
          className="header-icon no-grow pointer mr10"
          onClick={showAnnouncement.bind(null, this.selectedAnnouncement)}
        />
        <div className="row x-center no-grow">
          <div className="bold large text no-white-space-wrap mr15">
            {siteAnnouncement}
          </div>
          {this.renderItemsNavigation()}
        </div>
        <div className="row no-grow">
          <div className="header no-white-space-wrap mr15">
            {getPronounWithMonthDayAndTimeString(dateToAppear || startDate)}
          </div>
        </div>
      </div>
    )
  }

  private get selectedAnnouncement(): Announcement {
    return this.props.announcements[this.selectedIndex]
  }

  @action.bound
  private selectPrevAnnouncement = () => {
    const { announcements } = this.props
    if (this.selectedIndex) {
      this.selectedIndex--
    } else {
      this.selectedIndex = announcements.length - 1
    }
  }

  @action.bound
  private selectNextAnnouncement = () => {
    const { announcements } = this.props
    if (this.selectedIndex === announcements.length - 1) {
      this.selectedIndex = 0
    } else {
      this.selectedIndex++
    }
  }

  private setInitPosition() {
    const {
      containerRef: { offsetWidth, offsetHeight },
      leftOffset,
      topOffset,
    } = this.props
    const { offsetWidth: modalWidth, offsetHeight: modalHeight } = this.modalRef

    const top =
      topOffset + MODAL_OFFSET_Y / 2 ||
      offsetHeight - modalHeight - MODAL_OFFSET_Y
    const left =
      leftOffset - modalWidth / 2 || offsetWidth - modalWidth - MODAL_OFFSET_X

    const { x, y } = getCorrectModalOffsetPosition(
      left,
      top,
      offsetWidth,
      offsetHeight,
      modalWidth,
      modalHeight,
    )
    this.left = x
    this.top = y
  }

  private get isMultiMode(): boolean {
    const { announcements } = this.props
    return announcements.length > 1
  }

  private renderRelatedPermit() {
    const { openPermit, sitePermitsStore, permitTypesStore } = this.props
    const { relationship } = this.selectedAnnouncement

    if (relationship?.type === AnnouncementRelationType.Permit) {
      const permit = sitePermitsStore.getFormById(relationship.foreignKeyId)

      if (!permit || permit.isDeleted) {
        return null
      }

      return (
        <div
          className="related-permit row pa10"
          onClick={openPermit.bind(this, permit)}
        >
          <PermitTypeIcon
            permitType={permit.getTypeOfPermitType(permitTypesStore)}
            className="no-grow mr4 main-icon"
          />
          <span className="inline-block text-ellipsis title-text">
            {permit.getCaption(permitTypesStore)}
          </span>
          <Icon icon={IconNames.CHEVRON_RIGHT} />
        </div>
      )
    }
  }

  private setRef = (ref: HTMLDivElement) => {
    this.modalRef = ref
  }

  private renderItemsNavigation(): JSX.Element {
    if (!this.isMultiMode) {
      return
    }

    return (
      <div className="no-grow no-white-space-wrap navigation-wrapper">
        <Icons.ArrowBack
          className="navigation-icon"
          onClick={this.selectPrevAnnouncement}
        />
        <span className="navigation-title">{this.navigationTitle}</span>
        <Icons.ArrowForward
          className="navigation-icon"
          onClick={this.selectNextAnnouncement}
        />
      </div>
    )
  }

  private get navigationTitle(): string {
    return `${this.selectedIndex + 1} / ${this.props.announcements.length}`
  }
}
