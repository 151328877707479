import { action, computed, observable } from 'mobx'

import {
  IQuery,
  IUserProject,
  InviteStatus,
  ProjectAccessType,
} from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import { IProcoreCompanyWithProjectsAndUsers } from '~/client/src/shared/services/ProcoreService'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ProjectRolesStore from '~/client/src/shared/stores/domain/ProjectRoles.store'
import ProjectTeamsStore from '~/client/src/shared/stores/domain/ProjectTeams.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import { IServiceUserDto } from '~/client/src/shared/types/UserDto'
import canSendInviteToUser from '~/client/src/shared/utils/canSendInviteToUser'
import { ToastTheme, showToast } from '~/client/src/shared/utils/toaster'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'
import {
  downloadFile,
  readFileAsTextAsync,
} from '~/client/src/shared/utils/util'

import ProjectSetUpPageStore from '../../ProjectSetUpPage.store'
import ProjectMembersListStore from './ProjectMembers/ProjectMembersList.store'

export enum UploadingState {
  NotUploaded = 0,
  Uploading = 1,
  Failed = 2,
  Ready = 3,
}

export default class DesktopUserDirectoryStore {
  @observable public isDropdownOpen: boolean = false
  @observable public uploadingState: UploadingState = UploadingState.NotUploaded
  @observable public errorMessage: string = ''
  @observable public isAlertOpen: boolean = false
  @observable public lastUpdatedUserId: string = null
  @observable public newProjectMember: IServiceUserDto = null

  @observable
  public procoreCompanies: IProcoreCompanyWithProjectsAndUsers[] = null

  @observable public dialogs = {
    shouldShowProcoreDirectory: false,
    shouldShowConfirmInviteDialog: false,
    shouldShowConfirmSetAsNotInvitedDialog: false,
    shouldShowMemberDetails: false,
    shouldShowBulkEditMembers: false,
    shouldShowConfirmDeleteDialog: false,
  }

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly projectSetUpPageStore: ProjectSetUpPageStore,
    private readonly projectRolesStore: ProjectRolesStore,
    private readonly projectTeamsStore: ProjectTeamsStore,
    private readonly companiesStore: CompaniesStore,
    private readonly membersListStore: ProjectMembersListStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly projectMembersStore: ProjectMembersStore,
  ) {}

  @action.bound
  public toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen
  }

  @action.bound
  public setLastUpdatedUserId(lastUpdatedUserId: string) {
    this.lastUpdatedUserId = lastUpdatedUserId || null
  }

  @action.bound
  public changeUploadingState(state: UploadingState) {
    this.uploadingState = state
  }

  @action.bound
  public setErrorMessage(error?: Error) {
    this.errorMessage =
      error?.message ||
      Localization.translator.somethingWentWrongDuringAPIInteraction
  }

  @action.bound
  public resetErrorMessage() {
    this.errorMessage = EMPTY_STRING
  }

  @action.bound
  public openAlert() {
    this.isAlertOpen = true
  }

  @action.bound
  public closeAlert() {
    this.isAlertOpen = false
    this.resetErrorMessage()
  }

  @action.bound
  public toggleConfirmInviteDialog(isShow: boolean) {
    this.dialogs.shouldShowConfirmInviteDialog = isShow
    this.membersListStore.resetHiddenSelection()
  }

  @action.bound
  public showConfirmInviteDialog() {
    this.dialogs.shouldShowConfirmInviteDialog = true
  }

  @action.bound
  public hideConfirmInviteDialog() {
    this.dialogs.shouldShowConfirmInviteDialog = false
  }

  @action.bound
  public showAddMemberDialog(companyId: string) {
    const { id: activeProjectId } = this.state.activeProject

    this.newProjectMember = Object.assign(new User(null, null), {
      userProjectSettings: new UserProject(
        null,
        activeProjectId,
        null,
        InviteStatus.NotInvited,
        ProjectAccessType.Member,
        false,
        this.membersListStore.getValidCompanyId(companyId),
      ).toDto(),
    }).asJson as IServiceUserDto

    this.dialogs.shouldShowMemberDetails = true
    this.dialogs.shouldShowBulkEditMembers = false
  }

  @action.bound
  public handleEditMember() {
    this.resetNewProjectMember()
    this.dialogs.shouldShowMemberDetails = true
    this.dialogs.shouldShowBulkEditMembers = false
  }

  @action.bound
  public resetNewProjectMember() {
    this.newProjectMember = null
  }

  @action.bound
  public async handleInviteMembersFromList(users: User[]) {
    this.setLastUpdatedUserId(users[users.length - 1]?.id)
    this.membersListStore.resetHiddenSelection()

    try {
      // eslint-disable-next-line no-var
      var isOk = await this.projectMembersStore.inviteMembers(users)
    } catch (e) {
      this.setErrorMessage(e)
      return this.openAlert()
    } finally {
      this.hideConfirmInviteDialog()
    }

    if (isOk) {
      this.handleSuccessfulInvitation(users)
    } else {
      this.setErrorMessage()
      this.openAlert()
    }
  }

  @action.bound
  public async handleInviteMemberFromEditDialog(
    user: User,
    callback: () => void,
  ) {
    try {
      // eslint-disable-next-line no-var
      var isOk = await this.projectMembersStore.inviteMembers([user])
    } catch (e) {
      // !it is displayed on the edit dialog (not global page alert)
      return this.setErrorMessage(e)
    } finally {
      callback()
    }

    if (isOk) {
      this.handleSuccessfulInvitation([user])
    } else {
      this.setErrorMessage()
      this.openAlert()
    }
  }

  @action.bound
  public async setMembersAsNotInvited(users: User[]) {
    this.setLastUpdatedUserId(users[users.length - 1]?.id)

    try {
      await this.projectMembersStore.resetInvitation(users)
    } catch (e) {
      this.setErrorMessage(e)
      this.openAlert()
    } finally {
      this.toggleConfirmSetAsNotInvitedDialog(false)
    }
  }

  @action.bound
  public async deleteMembers(users: User[]) {
    this.setLastUpdatedUserId(null)
    await this.projectMembersStore.deleteMembers(users)
    this.dialogs.shouldShowConfirmDeleteDialog = false
  }

  @action.bound
  public async addMembersFromProcore(users: IServiceUserDto[]) {
    try {
      await this.projectMembersStore.saveMembers(users)
      this.dialogs.shouldShowProcoreDirectory = false
    } catch (e) {
      // TODO: provide UI
      alert(e)
    }
  }

  @action.bound
  public async saveMembers(
    items: IServiceUserDto[],
    lastUpdatedMemberId?: string,
  ) {
    this.setLastUpdatedUserId(
      lastUpdatedMemberId || items[items.length - 1]?.id,
    )

    try {
      await this.projectMembersStore.saveMembers(items)
      this.hideAddMemberDialog()
    } catch (e) {
      this.setErrorMessage(e)
    }
  }

  @action.bound
  public async updateMembersSettings(items: IUserProject[]) {
    const user = this.projectMembersStore.getById(
      items[items.length - 1]?.userId,
    )
    this.setLastUpdatedUserId(user.id)

    try {
      await this.userProjectsStore.save(items)
      this.dialogs.shouldShowBulkEditMembers = false
    } catch (e) {
      this.setErrorMessage(e)
    }
  }

  @action.bound
  public hideAddMemberDialog() {
    this.resetErrorMessage()
    this.dialogs.shouldShowMemberDetails = false
    this.membersListStore.resetHiddenSelection()
  }

  @action.bound
  public getUsersFromProcore() {
    this.toggleDropdown()

    return this.eventsStore.dispatch(
      e.GET_USERS_FROM_PROCORE,
      this.onGetProcoreProjectsSuccess,
      this.onGetProcoreProjectsError,
    )
  }

  @action.bound
  public closeProcoreDirectory() {
    this.dialogs.shouldShowProcoreDirectory = false
  }

  @action.bound
  public toggleConfirmSetAsNotInvitedDialog(isShow: boolean) {
    this.dialogs.shouldShowConfirmSetAsNotInvitedDialog = isShow
  }

  @action.bound
  public toggleConfirmDeleteDialog(isShow: boolean) {
    this.dialogs.shouldShowConfirmDeleteDialog = isShow
  }

  @action.bound
  public hideBulkEditMembersSideBar() {
    this.resetErrorMessage()
    this.dialogs.shouldShowBulkEditMembers = false
  }

  @action.bound
  public showBulkEditMembersSideBar() {
    this.dialogs.shouldShowBulkEditMembers = true
  }

  @action.bound
  public async uploadProjectMembers(file: File) {
    const fileContent = await readFileAsTextAsync(file)

    return this.eventsStore.dispatch(
      e.UPLOAD_PROJECT_MEMBERS,
      fileContent,
      this.onUploadProjectMembersSuccess.bind(this, file.name),
      this.onUploadProjectMembersError,
    )
  }

  @action.bound
  public downloadProjectMembers() {
    this.eventsStore.dispatch(
      e.DOWNLOAD_PROJECT_MEMBERS,
      this.handleCSVDownloadSuccess,
    )
  }

  @action.bound
  private onUploadProjectMembersSuccess(fileName: string) {
    this.projectSetUpPageStore
      .setCurrentPageAsCompletedOnProject(fileName)
      .then(() => this.changeUploadingState(UploadingState.Ready))
      .then(() => this.toggleDropdown())
  }

  @action.bound
  private onUploadProjectMembersError(error: string) {
    this.changeUploadingState(UploadingState.Failed)
    this.setErrorMessage(new Error(error))
    this.openAlert()
    this.toggleDropdown()
  }

  @action.bound
  private onGetProcoreProjectsSuccess(
    procoreCompanies: IProcoreCompanyWithProjectsAndUsers[],
  ) {
    this.state.loading.set(e.GET_USERS_FROM_PROCORE, false)
    this.procoreCompanies = procoreCompanies
    this.dialogs.shouldShowProcoreDirectory = true
  }

  @action.bound
  private onGetProcoreProjectsError(error: string) {
    this.state.loading.set(e.GET_USERS_FROM_PROCORE, false)
    this.setErrorMessage(new Error(error))
  }

  @action.bound
  private handleCSVDownloadSuccess({ getCsvUsers: fileContent }: IQuery) {
    const csvFile = new Blob([fileContent], {
      type: 'text/csv',
    })

    const csvFileUrl = window.URL.createObjectURL(csvFile)
    downloadFile(this.csvFilename, csvFileUrl)
    window.URL.revokeObjectURL(csvFileUrl)

    this.toggleDropdown()
  }

  private get csvFilename(): string {
    const { name } = this.state.activeProject
    return `${Localization.translator.projectMembers}-${name}.csv`
  }

  @computed
  public get usersToEdit(): IServiceUserDto[] {
    if (this.newProjectMember) {
      return [this.newProjectMember]
    }

    return this.selectedMembersToEdit
      .map(m => m.asJson as IServiceUserDto)
      .reverse()
  }

  @computed
  public get isUploadingState(): boolean {
    return this.uploadingState === UploadingState.Uploading
  }

  @computed
  public get membersToInvite(): User[] {
    return this.membersListStore.selectedInstances.filter(member =>
      canSendInviteToUser(member, this.userProjectsStore),
    )
  }

  @computed
  public get membersToInviteFromHiddenSelection(): User[] {
    const { userWithOpenDropDown } = this.membersListStore
    if (
      userWithOpenDropDown &&
      canSendInviteToUser(userWithOpenDropDown, this.userProjectsStore)
    ) {
      return [userWithOpenDropDown]
    }

    return []
  }

  @computed
  public get membersToSetAsNotInvited(): User[] {
    const { userWithOpenDropDown } = this.membersListStore
    if (
      userWithOpenDropDown &&
      UserProject.isAccepted(userWithOpenDropDown, this.userProjectsStore)
    ) {
      return [userWithOpenDropDown]
    }

    return []
  }

  public get selectedMembers(): User[] {
    return this.membersListStore.selectedInstancesIds
      .map(id => this.projectMembersStore.getById(id))
      .filter(m => !!m)
  }

  public get selectedMembersToEdit(): User[] {
    return this.membersListStore.hiddenSelectedInstancesIds
      .map(id => this.projectMembersStore.getById(id))
      .filter(m => !!m)
  }

  private handleSuccessfulInvitation(users: User[]) {
    const { manyUsersWereInvitedToProject, oneUserWasInvitedToProject } =
      Localization.translator.userInviteMessages

    const toastMessage =
      users.length > 1
        ? manyUsersWereInvitedToProject(users.length)
        : oneUserWasInvitedToProject(users[0].fullName)

    showToast(toastMessage, ToastTheme.SUCCESS)
  }

  private get state(): DesktopInitialState {
    return this.eventsStore.appState
  }
}
