import Localization from '../../shared/localization/LocalizationManager'

export const NO_DEADLINE_OPTION = -1
export const deadlineOptions = [NO_DEADLINE_OPTION, 1, 2, 3, 4, 5, 7, 14]

export function getDeadlineOptionText(option: number) {
  switch (option) {
    case NO_DEADLINE_OPTION:
      return Localization.translator.noDeadline
    case 1:
      return Localization.translator.twentyFourHours
    case 2:
      return Localization.translator.fortyEightHours
    case 3:
      return Localization.translator.seventyTwoHours
    case 4:
      return Localization.translator.fourDays
    case 5:
      return Localization.translator.fiveDays
    case 7:
      return Localization.translator.oneWeek
    case 14:
      return Localization.translator.twoWeeks
  }
}
