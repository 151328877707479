import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { TagIconType } from '../../../enums/TagIcon'
import LocationAttributeBase from '../../../models/LocationObjects/LocationAttributeBase'
import SitemapAttributeTag from '../../SitemapAttributeTag/SitemapAttributeTag'
import { ISharedProps } from '../BaseStruxhubInput'
import BaseStruxhubSelector from './BaseStruxhubSelector'

// localization: no text to translate

type TagValue = {
  name: string
  color?: string
  iconName?: string
}

interface IProps extends ISharedProps {
  onClick?: () => void
  values?: TagValue[]
  iconName?: TagIconType
  searchKey?: string
  shouldUseDefaultColor?: boolean
  isShownAsTag?: boolean

  customIconElement?: JSX.Element
}

@observer
export default class StruxhubTagValueSelector extends React.Component<IProps> {
  public render() {
    const {
      values,
      customIconElement,
      searchKey,
      shouldUseDefaultColor,
      isShownAsTag,
    } = this.props

    return (
      <BaseStruxhubSelector
        {...this.props}
        value={values?.length && '1'}
        selectorClassName="min-height40"
      >
        <>
          {customIconElement}
          <div className="row h-auto">
            <div className="row wrap row-gap-4">
              {values.map((value, index) => (
                <span
                  key={value.name + index}
                  title={value.name}
                  className={classList({
                    'tag-wrapper col no-grow mr4': true,
                    outlined: searchKey && value.name?.includes(searchKey),
                  })}
                >
                  <SitemapAttributeTag
                    className="no-flex px8"
                    contentContainerClassName={classList({
                      'text extra-large text-ellipsis line-24': true,
                      'blue-brand': !value.color && !shouldUseDefaultColor,
                    })}
                    dataObject={
                      (value.color || shouldUseDefaultColor) &&
                      ({
                        type: null,
                        iconName: value.iconName,
                        color: value.color,
                      } as LocationAttributeBase)
                    }
                    shouldShowAsTag={!!value.color || isShownAsTag}
                  >
                    {value.name}
                  </SitemapAttributeTag>
                </span>
              ))}
            </div>
          </div>
        </>
      </BaseStruxhubSelector>
    )
  }
}
