import * as React from 'react'

import { observer } from 'mobx-react'

import BaseGroupingOptions from '~/client/src/desktop/components/BaseGroupingOptions/BaseGroupingOptions'

import FormTypesGroupingOptionsStore from './FormTypesGroupingOptions.store'

// localization: no text to translate

interface IProps {
  store: FormTypesGroupingOptionsStore
  forceClose: boolean
}

@observer
export default class FormTypesGroupingOptions extends React.Component<IProps> {
  public render() {
    const { forceClose, store } = this.props

    return (
      <BaseGroupingOptions
        store={store}
        forceClose={forceClose}
        hasIndent={false}
        shouldHideBandCreation
      />
    )
  }
}
