import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import DeliveryConfirm from './DeliveryConfirm'
import ReasonOptions from './ReasonOptions'

// localization: translated

export interface IProps {
  isShown: boolean
  isReasonHidden: boolean
  isReasonMandatory: boolean
  onHide: () => void
  onApply: (reason?: string) => void
}

@observer
export default class DeliveryCancelConfirm extends React.Component<IProps> {
  @observable private reason: string

  public render() {
    const { isShown, onHide, isReasonHidden } = this.props

    return (
      <DeliveryConfirm
        title={Localization.translator.cancelDelivery}
        isShown={isShown}
        onHide={onHide}
        onApply={this.onApply}
        applyButtonText={Localization.translator.yesCancelDelivery}
        cancelButtonText={Localization.translator.no}
        isApplyDisabled={this.isApplyDisabled}
      >
        {Localization.translator.deliveryDescriptions.confirmCancel}
        <br />
        {Localization.translator.deliveryDescriptions.confirmCancel2}
        {!isReasonHidden && (
          <>
            <span className="row mt15">
              {`${Localization.translator.reason} (${this.reasonMandatoryOptionalTitle}):`}
            </span>
            <ReasonOptions onChange={this.setReason} />
          </>
        )}
      </DeliveryConfirm>
    )
  }

  @action.bound
  private setReason(reason: string) {
    this.reason = reason
  }

  private onApply = () => {
    this.props.onApply(this.reason)
  }

  private get isApplyDisabled(): boolean {
    return this.props.isReasonMandatory && !this.reason
  }

  private get reasonMandatoryOptionalTitle(): string {
    return this.props.isReasonMandatory
      ? Localization.translator.mandatory
      : Localization.translator.optional
  }
}
