import * as React from 'react'

import { observer } from 'mobx-react'

import { SitemapItemShapeType } from '~/client/graph'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import MapViewItemDrawnPart from '~/client/src/shared/components/SitemapHelpers/models/SitemapItemDrawnPart'
import * as TagIcon from '~/client/src/shared/components/TagIcon'

import MapViewItemsSetupStore from '../../stores/MapViewItemsSetup.store'
import PropertySelect from './PropertySelect'

interface IProps {
  item: MapViewItemBase
  store: MapViewItemsSetupStore

  setInitValues?: () => void
}

const { Circle, Rectangle, Polyline } = SitemapItemShapeType
const OPTION_BY_SHAPE = {
  [Circle]: <TagIcon.Circle size={16} />,
  [Rectangle]: <TagIcon.Rectangle size={16} />,
  [Polyline]: <TagIcon.Polygon size={16} />,
}

const NONE_VALUE = null
const none = 'None'

@observer
export default class ShapeSelector extends React.Component<IProps> {
  public render() {
    const {
      store: { getAllowedShapesForType, isGlobeMode },
      item: {
        sitemapItemProperties: { shapeProperties },
        dataObject,
        sitemapItem,
      },
    } = this.props

    const selectedValue = isGlobeMode
      ? sitemapItem?.shapeCoordinates?.type
      : shapeProperties?.type
    const allowedShapes = getAllowedShapesForType(dataObject.type)

    const options = [
      { value: NONE_VALUE, label: none },
      ...allowedShapes.map(value => ({
        value,
        label: OPTION_BY_SHAPE[value],
      })),
    ]
    return (
      <PropertySelect
        options={options}
        value={selectedValue || NONE_VALUE}
        className="inline-selector"
        onChange={this.onShapeChange}
      />
    )
  }

  private onShapeChange = (shapeType: SitemapItemShapeType) => {
    const { item, store, setInitValues } = this.props
    store.deselectMapViewItemDrawnPart()
    item.updateShape(shapeType, store.isGlobeMode)
    if (shapeType === SitemapItemShapeType.Circle && !store.isGlobeMode) {
      setInitValues?.()
    }
    store.selectSitemapItemDrawnPart(MapViewItemDrawnPart.Shape)
    store.addCurrentStateToHistory()
  }
}
