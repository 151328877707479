import InitialState from '~/client/src/shared/stores/InitialState'

import ApiHelper, { IResponseData } from '../../../utils/ApiHelper'
import BaseEventStore, {
  EffectMap,
  IEvent,
  IHttpEffect,
} from '../BaseEvents.store'
import { COLLECT_TELEMETRY, LOGOUT } from '../eventConstants'
import FlowProcessor from './FlowProcessor/FlowProcessor'
import GraphProcessor from './GraphProcessors/GraphProcessor'
import WorkerProcessor from './WorkerProcessor/WorkerProcessor'

export default class EffectsProcessor {
  public constructor(
    private readonly getValidToken: () => Promise<string>,
    private flowProcessor: FlowProcessor,
    private workerProcessor: WorkerProcessor,
    private graphQueryProcessor: GraphProcessor,
  ) {}

  public async process(
    store: BaseEventStore,
    state: InitialState,
    effects: EffectMap | void,
    eventName: string,
  ) {
    if (!effects) {
      return
    }
    const startProcessingMs = performance.now()
    const {
      dispatch,
      dispatchN,
      cmd,
      http,
      flow,
      worker,
      graphQuery,
      graphSubscription,
      graphMutation,
    } = effects

    this.workerProcessor.process(store, worker)
    this.flowProcessor.process(store, flow)
    await this.handleDispatchNEffect(store, dispatchN)
    await this.handleDispatchEffect(store, dispatch)
    await this.handleCmdEffect(store, cmd)

    if (
      !state.isUnauthorizedMode &&
      (graphQuery || graphSubscription || graphMutation) &&
      !(await this.getValidToken())
    ) {
      if (!state.loading.get(LOGOUT)) {
        store.dispatch(LOGOUT)
      }
      return
    }

    this.graphQueryProcessor.processQuery(store, graphQuery)
    this.graphQueryProcessor.processSubscription(
      store,
      graphSubscription,
      eventName,
    )
    this.graphQueryProcessor.processMutation(store, graphMutation)
    const httpResult = await this.handleHttpEffect(store, http)

    const endProcessingMs = performance.now()
    const processingDuration = endProcessingMs - startProcessingMs

    this.collectTimingTelemetry(eventName, processingDuration, store, effects)

    return httpResult
  }

  public terminateGraphSubscriptions() {
    this.graphQueryProcessor.terminateAllSubscriptions()
  }

  public terminateGraphSubscription(eventName: string) {
    this.graphQueryProcessor.terminateSubscription(eventName)
  }

  private collectTimingTelemetry(
    timingVar: string,
    timingValue: number,
    store: BaseEventStore,
    effects: EffectMap | void,
  ) {
    if (
      !effects ||
      effects.dispatch ||
      effects.dispatchN ||
      timingVar === COLLECT_TELEMETRY
    ) {
      return
    }
    store.dispatch(COLLECT_TELEMETRY, {
      timing: {
        timingCategory: 'Requests',
        timingVar,
        timingValue,
      },
    })
  }

  private handleDispatchEffect(store, event: IEvent) {
    if (!event) {
      return Promise.resolve()
    }
    return store.dispatch(...event)
  }

  private handleDispatchNEffect(store: BaseEventStore, events: IEvent[]) {
    if (!events) {
      return Promise.resolve()
    }

    return Promise.all(
      events.map(event => this.handleDispatchEffect(store, event)),
    )
  }

  private handleCmdEffect(store, effect) {
    if (!effect) {
      return
    }

    return Promise.resolve(effect.cmd.execute()).then(
      result => store.dispatch(...effect.onSuccess, result),
      error => store.dispatch(...effect.onError, error),
    )
  }

  private async handleHttpEffect(store, effect: IHttpEffect) {
    if (!effect) {
      return
    }

    const { onSuccess, onError } = effect

    const { isSuccess, data } = await this.request(effect)

    const eventType = isSuccess ? onSuccess : onError
    const event = data && isSuccess ? [...eventType, data] : [...eventType]
    const [type, ...params] = event
    store.dispatch(type, ...params)
  }

  private async request(effect: IHttpEffect): Promise<IResponseData> {
    const { url = '', method, mode = 'cors', body, headers = {}, blob } = effect

    if (blob) {
      const blobResponse = await fetch(url)
      return {
        data: await blobResponse.blob(),
        isSuccess: blobResponse.ok,
        status: blobResponse.status,
      }
    }

    try {
      // eslint-disable-next-line no-var
      var response = await fetch(url, {
        method,
        headers,
        mode,
        body: JSON.stringify(body),
      })
    } catch {
      return { isSuccess: false, status: 0 }
    }

    return ApiHelper.toResponseData(response)
  }
}
