import { action, computed, observable } from 'mobx'

import { RecentlyUpdatedMode } from '~/client/graph'
import TwoMonthsDatePickerStore from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import Guard from '~/client/src/shared/utils/Guard'

// localization: translated

export default class RecentlyUpdatedStore {
  @observable public shouldExpandDateSelector: boolean = false
  @observable public shouldExpandCustomRangeSelector: boolean = false
  public datePickerStore: TwoMonthsDatePickerStore
  public constructor(
    private readonly state: DesktopInitialState,
    private readonly forceUpdateCategoriesFilter: () => void,
    private readonly projectDateStore: ProjectDateStore,
  ) {
    this.datePickerStore = new TwoMonthsDatePickerStore(projectDateStore)
    Guard.requireAll({
      state,
      forceUpdateCategoriesFilter,
      projectDateStore,
    })
  }

  public get selectedSelectorDateTitle(): string {
    const { selectedRecentlyUpdatedMode } = this.state.filters
    const isCustomMode =
      selectedRecentlyUpdatedMode === RecentlyUpdatedMode.DateRange ||
      this.isDatePickerShown
    return isCustomMode
      ? this.datesButtonCaption
      : this.getRecentlyUpdatedModeDisplayText(selectedRecentlyUpdatedMode)
  }

  public getRecentlyUpdatedModeDisplayText(mode: RecentlyUpdatedMode) {
    switch (mode) {
      case RecentlyUpdatedMode.LastP6Update:
        return Localization.translator.lastP6Update
      case RecentlyUpdatedMode.Today:
        return Localization.translator.today
      case RecentlyUpdatedMode.LastWeek:
        return Localization.translator.lastWeek
      case RecentlyUpdatedMode.LastMonth:
        return Localization.translator.lastMonth
      case RecentlyUpdatedMode.Last6Week:
        return Localization.translator.last6Week
      case RecentlyUpdatedMode.DateRange:
        return Localization.translator.dateRange
    }
  }

  public get datesButtonCaption(): string {
    const {
      recentlyUpdatedStartDate: startDate,
      recentlyUpdatedEndDate: endDate,
    } = this.state.filters

    if (this.isDatePickerShown || !startDate) {
      return Localization.translator.dates
    }

    return this.projectDateStore.getDateIntervalToDisplay(startDate, endDate)
  }

  @computed
  public get isDatePickerShown(): boolean {
    return this.datePickerStore.isShown
  }

  @computed
  public get startPickerDate(): Date {
    return this.datePickerStore.startDate
  }

  @computed
  public get endPickerDate(): Date {
    const { endDate, startDate } = this.datePickerStore
    return endDate || startDate
  }

  @action.bound
  public toggleDateSelector() {
    if (this.shouldExpandDateSelector) {
      this.datePickerStore.hide()
    }
    this.shouldExpandDateSelector = !this.shouldExpandDateSelector
  }

  @action.bound
  public setSelectedSelectorDateMode(mode: RecentlyUpdatedMode) {
    if (mode === RecentlyUpdatedMode.DateRange) {
      this.toggleDateSelector()
      this.showDatePicker()
      return
    }
    this.applySelectorDateMode(mode)
  }

  @action.bound
  public applySelectorDateMode(
    mode: RecentlyUpdatedMode,
    startDate?: Date,
    endDate?: Date,
  ) {
    this.state.filters.selectedRecentlyUpdatedMode = mode
    this.setDates(startDate, endDate)
    this.forceUpdateCategoriesFilter()
  }

  @action.bound
  public showDatePicker() {
    this.datePickerStore.showWithOptions({
      titleTranslatorKey:
        KnownTranslatorKeys.dateRangeForRecentlyUpdatedActivities,
      initialRange: {
        startDate: this.startPickerDate,
        endDate: this.endPickerDate,
      },
      handler: this.applyCustomRange.bind(this),
    })
  }

  @action.bound
  public setDates(startDate?: Date, endDate?: Date) {
    const {
      endOfDay,
      startOfWeek,
      startOfMonth,
      startOfDay,
      addWeeks,
      addMonths,
    } = this.projectDateStore
    const newEndDate = endOfDay(endDate || new Date())

    let newStartDate: Date
    const { selectedRecentlyUpdatedMode } = this.state.filters
    switch (selectedRecentlyUpdatedMode) {
      case RecentlyUpdatedMode.LastWeek:
        newStartDate = startOfWeek(addWeeks(newEndDate, -1))
        break
      case RecentlyUpdatedMode.LastMonth:
        newStartDate = startOfMonth(addMonths(newEndDate, -1))
        break
      case RecentlyUpdatedMode.Last6Week:
        newStartDate = startOfWeek(addWeeks(newEndDate, -6))
        break
      default:
        newStartDate = startDate
          ? startOfDay(startDate)
          : startOfDay(new Date())
        break
    }
    this.state.filters.recentlyUpdatedStartDate = newStartDate
    this.state.filters.recentlyUpdatedEndDate = newEndDate
  }

  @action.bound
  private applyCustomRange(startDate?: Date, endDate?: Date) {
    this.state.filters.selectedRecentlyUpdatedMode =
      RecentlyUpdatedMode.DateRange
    this.setDates(startDate, endDate)
    this.toggleDateSelector()
  }
}
