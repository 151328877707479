import { IMaturixCast } from '~/client/graph'

import capitalize from '../utils/capitalizeText'
import { IMaturixMonitoringLatestData } from './IMaturixMonitoringLatestData'
import { MaturixData } from './MaturixData'
import { MaturixMonitoring } from './MaturixMonitoring'

export default class MaturixCast {
  public static fromDto(dto: IMaturixCast): MaturixCast {
    return new MaturixCast(
      dto.castId,
      dto.castName,
      dto.maturixProjectId,
      (dto.monitorings || []).map(
        (monitoringDto: IMaturixMonitoringLatestData) => ({
          monitoring: MaturixMonitoring.fromDto(monitoringDto.monitoring),
          latestData: monitoringDto?.latestData
            ? MaturixData.fromDto(monitoringDto.latestData)
            : null,
        }),
      ),
      dto.projectName,
      capitalize(dto.status),
    )
  }

  public constructor(
    public castId: string,
    public castName: string,
    public maturixProjectId: string,
    public monitorings?: IMaturixMonitoringLatestData[],
    public projectName?: string,
    public status?: string,
  ) {}
}
