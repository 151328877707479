import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import './WeekdaysSelector.scss'

interface IProps {
  selectedDayNumbers: number[]
  onClick: (dayNumber: number) => void

  className?: string

  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
export default class WeekdaysSelector extends React.Component<IProps> {
  public render() {
    const { selectedDayNumbers, onClick, className } = this.props

    return (
      <div
        className={classList({
          'weekdays-selector row no-grow no-flex-children': true,
          [className]: !!className,
        })}
      >
        {this.weekdaysShortNamesList.map((day, index) => {
          const dayNumber = ++index
          const isSelected = selectedDayNumbers?.includes(dayNumber)
          const isNonWorkingDay = !this.isWorkingDay(dayNumber)

          return (
            <div
              key={dayNumber}
              className={classList({
                'mx5 repeat-on-day-col row x-center y-center text small bold line-24 pointer':
                  true,
                'white bg-palette-blue-scale': isSelected && !isNonWorkingDay,
                'white bg-red': isSelected && isNonWorkingDay,
                'bg-palette-brand-lightest ba-palette-brand-lighter':
                  !isSelected,
                red: !isSelected && isNonWorkingDay,
                opacity7: isNonWorkingDay,
              })}
              onClick={onClick.bind(null, dayNumber)}
            >
              {day}
            </div>
          )
        })}
      </div>
    )
  }

  private isWorkingDay = (dayNumber: number): boolean => {
    return this.props.projectDateStore.workingDaysMap[dayNumber]
  }

  private get projectDateStore(): ProjectDateStore {
    return this.props.projectDateStore
  }

  private get weekdaysShortNamesList(): string[] {
    return this.projectDateStore.weekdaysShortNamesList
  }
}
