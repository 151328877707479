import * as React from 'react'

import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import CompactConfirmDialog from '../CompactConfirmDialog/CompactConfirmDialog'

export interface IProps {
  title: string
  isShown: boolean
  onHide: () => void
  onApply: () => void
}

@observer
export default class EntityDateMoveConfirm extends React.Component<IProps> {
  public render() {
    const { isShown, title, onHide, onApply } = this.props

    return (
      <CompactConfirmDialog
        title={title}
        isShown={isShown}
        onHide={onHide}
        onApply={onApply}
        applyButtonText={Localization.translator.change_verb}
        canOutsideClickClose={false}
      />
    )
  }
}
