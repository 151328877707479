import * as React from 'react'

import { RecaptchaVerifier } from 'firebase/auth'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import Localization from '../../../localization/LocalizationManager'
import AuthenticationStore from '../../../stores/domain/Authentication.store'
import { getFormattedPhoneNumberForSubmission } from '../../../utils/phoneNumberHelpers'
import StruxhubPhoneInput from '../../StruxhubInputs/StruxhubPhoneInput'

interface IProps {
  onSubmit: (
    phoneNumber: string,
    recaptchaVerifier: RecaptchaVerifier,
    recaptchaWidgetId: number,
  ) => void
  setSubmitBtnRef: (ref: HTMLButtonElement) => void

  auth?: AuthenticationStore
}

const recaptchaBtnId = 'sign-in-with-phone-button'

@inject('auth')
@observer
export default class PhoneNumberForm extends React.Component<IProps> {
  @observable private phoneNumber: string = ''

  private recaptchaVerifier: RecaptchaVerifier = null
  private recaptchaWidgetId: number = null
  private submitBtnRef: HTMLButtonElement = null

  public componentDidMount() {
    this.recaptchaVerifier = new RecaptchaVerifier(
      recaptchaBtnId,
      {
        size: 'invisible',
        callback: () => {
          this.props.onSubmit(
            getFormattedPhoneNumberForSubmission(this.phoneNumber),
            this.recaptchaVerifier,
            this.recaptchaWidgetId,
          )
        },
      },
      this.props.auth.firebaseAuth,
    )

    this.recaptchaVerifier.render().then(widgetId => {
      this.recaptchaWidgetId = widgetId
    })
  }

  public render() {
    return (
      <form onSubmit={this.handleFormSubmit}>
        <StruxhubPhoneInput
          label={Localization.translator.phoneNumber}
          isRequiredTextHidden={true}
          isRequired={true}
          value={this.phoneNumber}
          onChange={this.handlePhoneNumberChange}
        />

        <button
          ref={this.setSubmitBtnRef}
          type="button"
          id={recaptchaBtnId}
          className="full-width bg-color-blue-brand ba-none brada4 pa10 mt30"
        >
          <span className="text white bold large lp015">Sign In with SMS</span>
        </button>
      </form>
    )
  }

  @action.bound
  private handlePhoneNumberChange(phoneNumber: string) {
    this.phoneNumber = phoneNumber
  }

  private handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    this.submitBtnRef.click()
  }

  private setSubmitBtnRef = (ref: HTMLButtonElement) => {
    this.submitBtnRef = ref
    this.props.setSubmitBtnRef(ref)
  }
}
