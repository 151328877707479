import ApiHelper from '~/client/src/shared/utils/ApiHelper'

import { GET_USERS_FROM_PROCORE } from '../stores/EventStore/eventConstants'
import InitialState from '../stores/InitialState'

interface IToken {
  _t: string
  AccessToken: string
  TokenType: string
  ExpiresIn: number
  RefreshToken: string
  CreatedAt: number
}

export interface IProcoreUser {
  id: string
  businessPhone: string
  email: string
  firstName: string
  jobTitle: string
  lastName: string
  mobilePhone: string
  name: string
  struxhubProjects: string[]
  vendor?: {
    name: string
  }
}

export interface IProcoreProjectWithUsers {
  id: string
  display_name: string
  users?: IProcoreUser[]
}

export interface IProcoreCompanyWithProjectsAndUsers {
  name: string
  projects: IProcoreProjectWithUsers[]
}

const PROCORE_BASE_URL = `${location.protocol}//${location.host}`

export default class ProcoreService {
  private token: IToken = null

  private get tokenUrl() {
    return PROCORE_BASE_URL + '/procore/Auth/token'
  }

  private get inviteKeyUrl() {
    return PROCORE_BASE_URL + '/procore/Auth/invitekey'
  }

  private get allProjectsUrl() {
    return PROCORE_BASE_URL + '/procore/Project/all'
  }

  private get usersByProjectsUrl() {
    return PROCORE_BASE_URL + '/procore/Project/users'
  }

  public constructor(private state: InitialState) {}

  public async login() {
    this.token = null
    const { RefreshToken } = await this.getToken()
    return this.getInviteKeyFromProcoreToken(RefreshToken)
  }

  public async getPopulatedCompanies() {
    const { AccessToken, RefreshToken } = await this.getToken()

    this.state.loading.set(GET_USERS_FROM_PROCORE, true)
    const companies = await ApiHelper.fetchJson(this.allProjectsUrl, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Code: RefreshToken,
        Token: AccessToken,
      },
    })

    const projectIds = []
    companies.forEach(company => {
      company.projects = company.projects || []
      projectIds.push(...company.projects.map(p => p.id))
    })

    const usersByProjects = await this.getUsersForProjects(projectIds)

    companies.forEach(company => {
      company.projects.forEach(project => {
        const keyValPair = usersByProjects.find(({ key }) => key === project.id)
        project.users = keyValPair && keyValPair.value
      })
    })

    return companies
  }

  private async getUsersForProjects(projectIds: string[]) {
    const { AccessToken, RefreshToken } = await this.getToken()

    const urlParams = '?' + projectIds.map(id => 'projectsids=' + id).join('&')
    return ApiHelper.fetchJson(this.usersByProjectsUrl + urlParams, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Code: RefreshToken,
        Token: AccessToken,
      },
    })
  }

  private async getToken(): Promise<IToken> {
    return (
      this.token ||
      (this.token = await new Promise(resolve => {
        let popup: Window = null

        const onMessage = ({ data: { data, message } }) => {
          if (message === 'procoreToken') {
            window.removeEventListener('message', onMessage)
            popup.close()
            resolve(data)
          }
        }

        window.removeEventListener('message', onMessage)
        window.addEventListener('message', onMessage)

        popup = window.open(this.tokenUrl, 'Procore', 'height=600,width=400')
      }))
    )
  }

  private getInviteKeyFromProcoreToken(code: string) {
    return ApiHelper.fetchJson(this.inviteKeyUrl, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Code: code,
      },
    })
  }
}
