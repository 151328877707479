import Wbs from '~/client/src/shared/models/Wbs'
import Guard from '~/client/src/shared/utils/Guard'

export default class WbsTreeNode {
  public startDate: Date

  public constructor(public wbs: Wbs, public children: WbsTreeNode[] = []) {
    Guard.againstUndefined(wbs, 'wbs')
    Guard.againstUndefined(children, 'children')
  }
}
