import { computed } from 'mobx'

import * as e from '~/client/src/shared/stores/EventStore/eventConstants'

import InitialState from '../InitialState'

export default class RoutesStore {
  public constructor(private readonly state: InitialState) {}

  @computed
  public get areProjectTypesLoading(): boolean {
    const loadingChain = [
      e.INIT_AUTH_USER,
      e.INIT_APP,
      e.INIT_APP_2,
      e.LOAD_AND_LISTEN_TO_PROJECT_TYPE_OPTIONS,
    ]

    return loadingChain.some(ev => this.state.loading.get(ev))
  }
}
