import { isBefore } from 'date-fns'
import { action } from 'mobx'

import { IScanHistory } from '~/client/graph'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'

import IScannerHistoryPair from '../../models/IScannerHistoryPair'
import ScanHistory from '../../models/ScanHistory'
import InitialState from '../InitialState'
import ScannersStore from './Scanners.store'

export default class ScanHistoriesStore {
  public constructor(
    private eventsStore: EventsStore,
    private scannersStore: ScannersStore,
  ) {}

  public get isDataReceived(): boolean {
    return !this.appState.loading.get(e.LOAD_AND_LISTEN_TO_SCAN_HISTORIES)
  }

  // map for efficient start/end ride tracking
  public get historiesByScannerAndUser(): {
    [scannerId: string]: { [userId: string]: ScanHistory }
  } {
    return this.list.reduce((map, scanHistory) => {
      if (!map[scanHistory.scannerId]) {
        map[scanHistory.scannerId] = {
          [scanHistory.userId]: scanHistory,
        }
      } else if (!map[scanHistory.scannerId][scanHistory.userId]) {
        map[scanHistory.scannerId] = {
          ...map[scanHistory.scannerId],
          [scanHistory.userId]: scanHistory,
        }
      } else {
        const lastScanHistory: ScanHistory = isBefore(
          scanHistory.date,
          map[scanHistory.scannerId][scanHistory.userId].date,
        )
          ? map[scanHistory.scannerId][scanHistory.userId]
          : scanHistory

        map[scanHistory.scannerId] = {
          ...map[scanHistory.scannerId],
          [scanHistory.userId]: lastScanHistory,
        }
      }

      return map
    }, {} as { [scannerId: string]: { [userId: string]: ScanHistory } })
  }

  public get historiesByScanners(): { [scannerId: string]: ScanHistory[] } {
    return this.list.reduce((map, history) => {
      if (map[history.scannerId]) {
        map[history.scannerId] = [...map[history.scannerId], history]
      } else {
        map[history.scannerId] = [history]
      }
      return map
    }, {} as { [scannerId: string]: ScanHistory[] })
  }

  public get historiesByScannerPairs(): IScannerHistoryPair[] {
    return this.list.map(history => {
      const scanner = this.scannersStore.getScannerById(history.scannerId)
      return {
        id: scanner ? scanner.id + history.id : history.id,
        history,
        scanner,
      }
    })
  }

  public get list(): ScanHistory[] {
    return Array.from(this.byId.values())
  }

  public get byId(): Map<string, ScanHistory> {
    return this.appState.scanHistories
  }

  @action.bound
  public save(sc: IScanHistory, callback?: (id: string) => void): void {
    this.saveMany([sc]).then(([c]) => callback?.(c.id))
  }

  @action.bound
  public saveMany(scanners: IScanHistory[]): Promise<IScanHistory[]> {
    return new Promise((resolve, reject) =>
      this.eventsStore.dispatch(
        e.SAVE_SCAN_HISTORIES,
        scanners,
        resolve,
        reject,
      ),
    )
  }

  public clearList(): void {
    this.byId.clear()
  }

  public updateFromList(list: IScanHistory[]): void {
    list.forEach(dto => {
      const history = ScanHistory.fromDto(dto)
      this.byId.set(history.id, history)
    })
  }

  public receiveOne(id: string, dto: IScanHistory): void {
    if (dto) {
      const history = ScanHistory.fromDto(dto)
      this.byId.set(id, history)
    }
  }

  public receiveList(dtos: IScanHistory[]): void {
    this.clearList()

    dtos.forEach(dto => {
      const history = ScanHistory.fromDto(dto)
      this.byId.set(history.id, history)
    })
  }

  protected createAnInstance(id: string, dto: any): ScanHistory {
    return ScanHistory.fromDto(dto)
  }

  private get appState(): InitialState {
    return this.eventsStore.appState
  }
}
