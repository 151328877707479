import * as React from 'react'

import { classList } from 'react-classlist-helper'

import './DotIndicator.scss'

// localization: no display text to translate

interface IProps {
  isActive: boolean
}

export default class DotIndicator extends React.PureComponent<IProps> {
  public render() {
    return (
      <span
        className={classList({
          'dot-indicator br-rounded inline-block': true,
          active: this.props.isActive,
        })}
      />
    )
  }
}
