import { enumToList } from '~/client/src/shared/utils/converters'

import Localization from '../localization/LocalizationManager'

enum SitemapFilterType {
  Tags = 'Tags',
  Items = 'Items',
}

export const sitemapFilterTypes = enumToList(SitemapFilterType)

export const getSitemapFilterTypeDisplayName = (type: SitemapFilterType) => {
  switch (type) {
    case SitemapFilterType.Tags:
      return Localization.translator.tags
    case SitemapFilterType.Items:
      return Localization.translator.items
    default:
      throw new Error(type + ' is unhandled')
  }
}

export default SitemapFilterType
