import { CountriesType } from '../LanguageTreeType'

const countries: CountriesType = {
  Afghanistan: 'Афганістан',
  'Åland Islands': 'Оландські острови',
  Albania: 'Албанія',
  Algeria: 'Алжир',
  'American Samoa': 'Американське Самоа',
  Andorra: 'Андорра',
  Angola: 'Ангола',
  Anguilla: 'Ангілья',
  Antarctica: 'Антарктида',
  'Antigua and Barbuda': 'Антигуа і Барбуда',
  Argentina: 'Аргентина',
  Armenia: 'Вірменія',
  Aruba: 'Аруба',
  Australia: 'Австралія',
  Austria: 'Австрія',
  Azerbaijan: 'Азербайджан',
  Bahamas: 'Багами',
  Bahrain: 'Бахрейн',
  Bangladesh: 'Бангладеш',
  Barbados: 'Барбадос',
  Belarus: 'Білорусь',
  Belgium: 'Бельгія',
  Belize: 'Беліз',
  Benin: 'Бенін',
  Bermuda: 'Бермуди',
  Bhutan: 'Бутан',
  Bolivia: 'Болівія',
  'Bosnia and Herzegovina': 'Боснія і Герцеговина',
  Botswana: 'Ботсвана',
  'Bouvet Island': 'Острів Буве',
  Brazil: 'Бразилія',
  'British Indian Ocean Territory': 'Британська територія Індійського океану',
  'Brunei Darussalam': 'Бруней-Даруссалам',
  Bulgaria: 'Болгарія',
  'Burkina Faso': 'Буркіна-Фасо',
  Burundi: 'Бурунді',
  Cambodia: 'Камбоджа',
  Cameroon: 'Камерун',
  Canada: 'Канада',
  'Cape Verde': 'Кабо-Верде',
  'Cayman Islands': 'Кайманові острови',
  'Central African Republic': 'Центральноафриканська республіка',
  Chad: 'Чад',
  Chile: 'Чилі',
  China: 'Китай',
  'Christmas Island': 'Острів Різдва',
  'Cocos (Keeling) Islands': 'Кокосові (Кілінгські) острови',
  Colombia: 'Колумбія',
  Comoros: 'Коморські острови',
  Congo: 'Конго',
  'Congo, Democratic Republic': 'Конго, Демократична республіка',
  'Cook Islands': 'Острови Кука',
  'Costa Rica': 'Коста-Ріка',
  "Cote D'Ivoire": "Кот-д'Івуар",
  Croatia: 'Хорватія',
  Cuba: 'Куба',
  Cyprus: 'Кіпр',
  'Czech Republic': 'Чехія',
  Denmark: 'Данія',
  Djibouti: 'Джибуті',
  Dominica: 'Домініка',
  'Dominican Republic': 'Домініканська Республіка',
  Ecuador: 'Еквадор',
  Egypt: 'Єгипет',
  'El Salvador': 'Сальвадор',
  'Equatorial Guinea': 'Екваторіальна Гвінея',
  Eritrea: 'Еритрея',
  Estonia: 'Естонія',
  Ethiopia: 'Ефіопія',
  'Falkland Islands (Malvinas)': 'Фолклендські острови (Мальвіни)',
  'Faroe Islands': 'Фарерські острови',
  Fiji: 'Фіджі',
  Finland: 'Фінляндія',
  France: 'Франція',
  'French Guiana': 'Французька Гвіана',
  'French Polynesia': 'Французька Полінезія',
  'French Southern Territories': 'Французькі південні території',
  Gabon: 'Габон',
  Gambia: 'Гамбія',
  Georgia: 'Грузія',
  Germany: 'Німеччина',
  Ghana: 'Гана',
  Gibraltar: 'Гібралтар',
  Greece: 'Греція',
  Greenland: 'Гренландія',
  Grenada: 'Гренада',
  Guadeloupe: 'Гваделупа',
  Guam: 'Гуам',
  Guatemala: 'Гватемала',
  Guernsey: 'Гернсі',
  Guinea: 'Гвінея',
  'Guinea-Bissau': 'Гвінея-Бісау',
  Guyana: 'Гайана',
  Haiti: 'Гаїті',
  'Heard Island and Mcdonald Islands': 'Острів Херда і Макдональдські острови',
  'Holy See (Vatican City State)': 'Святий Престол (міська держава Ватикан)',
  Honduras: 'Гондурас',
  'Hong Kong': 'Гонконг',
  Hungary: 'Угорщина',
  Iceland: 'Ісландія',
  India: 'Індія',
  Indonesia: 'Індонезія',
  Iran: 'Іран',
  Iraq: 'Ірак',
  Ireland: 'Ірландія',
  'Isle of Man': 'Острів Мен',
  Israel: 'Ізраїль',
  Italy: 'Італія',
  Jamaica: 'Ямайка',
  Japan: 'Японія',
  Jersey: 'Джерсі',
  Jordan: 'Йорданія',
  Kazakhstan: 'Казахстан',
  Kenya: 'Кенія',
  Kiribati: 'Кірібаті',
  'Korea (North)': 'Корея (Північ)',
  'Korea (South)': 'Корея (Південь)',
  Kosovo: 'Косово',
  Kuwait: 'Кувейт',
  Kyrgyzstan: 'Киргизстан',
  Laos: 'Лаос',
  Latvia: 'Латвія',
  Lebanon: 'Ліван',
  Lesotho: 'Лесото',
  Liberia: 'Ліберія',
  'Libyan Arab Jamahiriya': 'Лівійська арабська Джамахірія',
  Liechtenstein: 'Ліхтенштейн',
  Lithuania: 'Литва',
  Luxembourg: 'Люксембург',
  Macao: 'Макао',
  Macedonia: 'Македонія',
  Madagascar: 'Мадагаскар',
  Malawi: 'Малаві',
  Malaysia: 'Малайзія',
  Maldives: 'Мальдіви',
  Mali: 'Малі',
  Malta: 'Мальта',
  'Marshall Islands': 'Маршаллові острови',
  Martinique: 'Мартініка',
  Mauritania: 'Мавританія',
  Mauritius: 'Маврикій',
  Mayotte: 'Майотта',
  Mexico: 'Мексика',
  Micronesia: 'Мікронезія',
  Moldova: 'Молдова',
  Monaco: 'Монако',
  Mongolia: 'Монголія',
  Montserrat: 'Монтсеррат',
  Morocco: 'Марокко',
  Mozambique: 'Мозамбік',
  Myanmar: "М'янма",
  Namibia: 'Намібія',
  Nauru: 'Науру',
  Nepal: 'Непал',
  Netherlands: 'Нідерланди',
  'Netherlands Antilles': 'Нідерландські Антильські острови',
  'New Caledonia': 'Нова Каледонія',
  'New Zealand': 'Нова Зеландія',
  Nicaragua: 'Нікарагуа',
  Niger: 'Нігер',
  Nigeria: 'Нігерія',
  Niue: 'Ніуе',
  'Norfolk Island': 'Острів Норфолк',
  'Northern Mariana Islands': 'Північні Маріанські острови',
  Norway: 'Норвегія',
  Oman: 'Оман',
  Pakistan: 'Пакистан',
  Palau: 'Палау',
  'Palestinian Territory, Occupied': 'Палестинська територія, окупована',
  Panama: 'Панама',
  'Papua New Guinea': 'Папуа-Нова Гвінея',
  Paraguay: 'Парагвай',
  Peru: 'Перу',
  Philippines: 'Філіппіни',
  Pitcairn: 'Піткерн',
  Poland: 'Польща',
  Portugal: 'Португалія',
  'Puerto Rico': 'Пуерто-Рико',
  Qatar: 'Катар',
  Reunion: "Возз'єднання",
  Romania: 'Румунія',
  'Russian Federation': 'Російська Федерація',
  Rwanda: 'Руанда',
  'Saint Helena': 'Свята Єлена',
  'Saint Kitts and Nevis': 'Сент-Кітс і Невіс',
  'Saint Lucia': 'Сент-Люсія',
  'Saint Pierre and Miquelon': "Сен-П'єр і Мікелон",
  'Saint Vincent and the Grenadines': 'Сент-Вінсент і Гренадини',
  Samoa: 'Самоа',
  'San Marino': 'Сан-Марино',
  'Sao Tome and Principe': 'Сан-Томе і Принсіпі',
  'Saudi Arabia': 'Саудівська Аравія',
  Senegal: 'Сенегал',
  Serbia: 'Сербія',
  Montenegro: 'Чорногорія',
  Seychelles: 'Сейшельські острови',
  'Sierra Leone': 'Сьєрра-Леоне',
  Singapore: 'Сінгапур',
  Slovakia: 'Словаччина',
  Slovenia: 'Словенія',
  'Solomon Islands': 'Соломонові острови',
  Somalia: 'Сомалі',
  'South Africa': 'Південна Африка',
  'South Georgia and the South Sandwich Islands':
    'Південна Джорджія та Південні Сандвічеві острови',
  Spain: 'Іспанія',
  'Sri Lanka': 'Шрі Ланка',
  Sudan: 'Судан',
  Suriname: 'Суринам',
  'Svalbard and Jan Mayen': 'Шпицберген та Ян Майен',
  Swaziland: 'Свазіленд',
  Sweden: 'Швеція',
  Switzerland: 'Швейцарія',
  'Syrian Arab Republic': 'Сирійська Арабська Республіка',
  'Taiwan, Province of China': 'Тайвань, провінція Китаю',
  Tajikistan: 'Таджикистан',
  Tanzania: 'Танзанія',
  Thailand: 'Таїланд',
  'Timor-Leste': 'Тимор-Лешти',
  Togo: 'Йти',
  Tokelau: 'Токелау',
  Tonga: 'Тонга',
  'Trinidad and Tobago': 'Тринідад і Тобаго',
  Tunisia: 'Туніс',
  Turkey: 'Туреччина',
  Turkmenistan: 'Туркменістан',
  'Turks and Caicos Islands': 'Острови Теркс і Кайкос',
  Tuvalu: 'Тувалу',
  Uganda: 'Уганда',
  Ukraine: 'Україна',
  'United Arab Emirates': "Об'єднані Арабські Емірати",
  'United Kingdom': "Об'єднане Королівство",
  'United States': 'Сполучені Штати',
  'United States Minor Outlying Islands':
    'Малі віддалені острови Сполучених Штатів',
  Uruguay: 'Уругвай',
  Uzbekistan: 'Узбекистан',
  Vanuatu: 'Вануату',
  Venezuela: 'Венесуела',
  'Viet Nam': "В'єтнам",
  'Virgin Islands, British': 'Віргінські острови, британські',
  'Virgin Islands, U.S.': 'Віргінські острови, США',
  'Wallis and Futuna': 'Уолліс і Футуна',
  'Western Sahara': 'Західна Сахара',
  Yemen: 'Ємен',
  Zambia: 'Замбія',
  Zimbabwe: 'Зімбабве',
}

export default countries
