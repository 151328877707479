import { observable } from 'mobx'

import {
  IGlobeViewSpecificItemData,
  ISitemapSpecificItemData,
  SitemapItemShapeType,
} from '~/client/graph'
import MapViewItemType from '~/client/src/shared/enums/MapViewItemType'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import Sitemap from '~/client/src/shared/models/Sitemap'
import SitemapItem from '~/client/src/shared/models/SitemapItem'
import IHierarchyParent from '~/client/src/shared/types/IHierarchyParent'

import GlobeView from '../../../models/GlobeView'
import HierarchyNode from '../../../models/HierarchyNode'
import { LocationIntegrationType } from '../../../models/LocationObjects/LocationIntegration'
import { FORBIDDEN_SITE_NAME } from '../../../stores/domain/LocationBase.store'
import CircleShapeCoordinates from './CircleShapeCoordinates'
import GlobeViewCircleProperties from './GlobeViewCircleProperties'
import GlobeViewItemProperties from './GlobeViewItemProperties'
import GlobeViewPolyLineProperties from './GlobeViewPolyLineProperties'
import GlobeViewRectangleProperties from './GlobeViewRectangleProperties'
import MapViewItemFactory from './MapViewItemFactory'
import PolyLineShapeCoordinates from './PolyLineShapeCoordinates'
import RectangleShapeCoordinates from './RectangleShapeCoordinates'
import SitemapCircleProperties from './SitemapCircleProperties'
import SitemapItemProperties from './SitemapItemProperties'
import SitemapPolyLineProperties from './SitemapPolyLineProperties'
import SitemapRectangleProperties from './SitemapRectangleProperties'

export interface IBoundingBox {
  x: number
  y: number
  width: number
  height: number
}

export const CAPTION_MAP = {
  [MapViewItemType.Line]: 'Line',
  [MapViewItemType.TextBox]: 'Text Box',
}

const COORDINATE_NORMALIZATION = 0.0005

export default class MapViewItemBase {
  @observable public dataObject: LocationBase
  @observable public sitemapItem: SitemapItem
  @observable public isDisplayed: boolean
  @observable public hoveredNode: HierarchyNode = null
  @observable public sitemapItemProperties?: SitemapItemProperties
  @observable public globeViewItemProperties?: GlobeViewItemProperties

  public constructor(
    dataObject: LocationBase,
    sitemapItem: SitemapItem,
    isDisplayed: boolean,
    sitemapItemProperties?: SitemapItemProperties,
    globeViewItemProperties?: GlobeViewItemProperties,
  ) {
    this.dataObject = dataObject || null
    this.sitemapItem = sitemapItem
    this.isDisplayed = isDisplayed
    this.sitemapItemProperties =
      sitemapItemProperties || new SitemapItemProperties()
    this.globeViewItemProperties =
      globeViewItemProperties || new GlobeViewItemProperties()

    this.createRequiredProperties()
  }

  public get id(): string {
    return this.dataSource.id
  }

  public get typeCaption() {
    return CAPTION_MAP[this.sitemapItem.type]
  }

  public get isDataLess() {
    return !this.dataObject
  }

  public isEqual(item: MapViewItemBase, isGlobeMode: boolean) {
    return (
      ((this.isDataLess && item.isDataLess) ||
        this.dataObject?.isEqual(item?.dataObject)) &&
      this.sitemapItem.isEqual(item?.sitemapItem) &&
      this.isDisplayed === item.isDisplayed &&
      (isGlobeMode
        ? this.globeViewItemProperties.isEqual(item.globeViewItemProperties)
        : this.sitemapItemProperties.isEqual(item.sitemapItemProperties))
    )
  }

  public isDisplayDataEqual(
    {
      sitemapItemId,
      isHidden,
      icon,
      label,
      shape,
    }: ISitemapSpecificItemData | IGlobeViewSpecificItemData,
    isGlobeMode: boolean,
  ) {
    const arePartsEqual = [
      [
        icon,
        isGlobeMode
          ? this.globeViewItemProperties.iconProperties
          : this.sitemapItemProperties.iconProperties,
      ],
      [
        label,
        isGlobeMode
          ? this.globeViewItemProperties.labelProperties
          : this.sitemapItemProperties.labelProperties,
      ],
      [
        shape,
        isGlobeMode
          ? this.globeViewItemProperties.shapeProperties
          : this.sitemapItemProperties.shapeProperties,
      ],
    ].every(([data, properties]: any) => {
      if (data) {
        return properties && properties.isDisplayDataEqual(data)
      }
      return !properties
    })

    return (
      sitemapItemId === this.sitemapItem.id &&
      !isHidden === this.isDisplayed &&
      arePartsEqual
    )
  }

  public getDisplayData(isGlobeMode: boolean) {
    return isGlobeMode
      ? this.getGlobeViewDisplayData()
      : this.getSitemapDisplayData()
  }

  public getSitemapDisplayData(): any {
    return {
      sitemapItemId: this.sitemapItem.id,
      projectId: this.sitemapItem.projectId,
      isHidden: !this.isDisplayed,
      icon:
        this.sitemapItemProperties.iconProperties &&
        this.sitemapItemProperties.iconProperties.getDisplayData(),
      label:
        this.sitemapItemProperties.labelProperties &&
        this.sitemapItemProperties.labelProperties.getDisplayData(),
      shape:
        this.sitemapItemProperties.shapeProperties &&
        this.sitemapItemProperties.shapeProperties.getDisplayData(),
    }
  }

  public getGlobeViewDisplayData(): any {
    return {
      sitemapItemId: this.sitemapItem.id,
      projectId: this.sitemapItem.projectId,
      isHidden: !this.isDisplayed,
      icon:
        this.globeViewItemProperties.iconProperties &&
        this.globeViewItemProperties.iconProperties.getDisplayData(),
      label:
        this.globeViewItemProperties.labelProperties &&
        this.globeViewItemProperties.labelProperties.getDisplayData(),
      shape:
        this.globeViewItemProperties.shapeProperties &&
        this.globeViewItemProperties.shapeProperties.getDisplayData(),
    }
  }

  public setShapesFromItem(item: MapViewItemBase) {
    this.sitemapItemProperties.iconProperties =
      item.sitemapItemProperties.iconProperties
    this.sitemapItemProperties.labelProperties =
      item.sitemapItemProperties.labelProperties
    this.sitemapItemProperties.shapeProperties =
      item.sitemapItemProperties.shapeProperties

    this.globeViewItemProperties.iconProperties =
      item.globeViewItemProperties.iconProperties
    this.globeViewItemProperties.labelProperties =
      item.globeViewItemProperties.labelProperties
    this.globeViewItemProperties.shapeProperties =
      item.globeViewItemProperties.shapeProperties
  }

  public display(): MapViewItemBase {
    this.isDisplayed = true
    return this
  }

  public hide(): MapViewItemBase {
    this.isDisplayed = false
    return this
  }

  public isValid(isGlobeMode: boolean) {
    return isGlobeMode ? this.isValidGlobeViewItem() : this.isValidSitemapItem()
  }

  public isValidGlobeViewItem(): boolean {
    return (
      Boolean(this.name && this.color) &&
      this.name !== FORBIDDEN_SITE_NAME &&
      [
        this.globeViewItemProperties.iconProperties,
        this.globeViewItemProperties.labelProperties,
        this.globeViewItemProperties.shapeProperties,
      ].every(p => !p || p.isValid())
    )
  }

  public isValidSitemapItem(): boolean {
    return (
      Boolean(this.name && this.color) &&
      this.name !== FORBIDDEN_SITE_NAME &&
      [
        this.sitemapItemProperties.iconProperties,
        this.sitemapItemProperties.labelProperties,
        this.sitemapItemProperties.shapeProperties,
      ].every(p => !p || p.isValid())
    )
  }

  public fillDataBeforeDisplayingFromGlobes(
    globes: GlobeView[],
  ): MapViewItemBase {
    const globeWithItem = globes.find(s =>
      s.hasDisplayData(this.sitemapItem.id),
    )

    if (!globeWithItem) {
      return this
    }

    const sitemapData = globeWithItem.getItemDisplayData(this.sitemapItem.id)
    const shape = MapViewItemFactory.getGlobeViewShapeProperties(
      sitemapData.shape,
      this.color,
    )
    const icon = MapViewItemFactory.getGlobeViewIconProperties(sitemapData.icon)
    const label = MapViewItemFactory.getGlobeViewLabelProperties(
      sitemapData.label,
    )

    this.globeViewItemProperties.iconProperties = icon
    this.globeViewItemProperties.labelProperties = label
    this.globeViewItemProperties.shapeProperties = shape

    this.createRequiredProperties()

    return this
  }

  public fillDataBeforeDisplayingFromSitemaps(
    sitemaps: Sitemap[],
  ): MapViewItemBase {
    const sitemapWithItem = sitemaps.find(s =>
      s.hasDisplayData(this.sitemapItem.id),
    )

    if (!sitemapWithItem) {
      return this
    }

    const sitemapData = sitemapWithItem.getItemDisplayData(this.sitemapItem.id)
    const shape = MapViewItemFactory.getSitemapShapeProperties(
      sitemapData.shape,
      this.color,
    )
    const icon = MapViewItemFactory.getSitemapIconProperties(
      sitemapData.icon,
      shape,
    )
    const label = MapViewItemFactory.getSitemapLabelProperties(
      sitemapData.label,
      icon,
    )

    this.sitemapItemProperties.iconProperties = icon
    this.sitemapItemProperties.labelProperties = label
    this.sitemapItemProperties.shapeProperties = shape

    this.createRequiredProperties()

    return this
  }

  public get colorValues(): string[] {
    const colors = [this.color]
    if (this.sitemapItemProperties?.shapeProperties) {
      colors.push(this.sitemapItemProperties.shapeProperties.fillColor)
      colors.push(this.sitemapItemProperties.shapeProperties.lineColor)
    }
    if (this.globeViewItemProperties?.shapeProperties) {
      colors.push(this.globeViewItemProperties.shapeProperties.fillColor)
      colors.push(this.globeViewItemProperties.shapeProperties.lineColor)
    }
    return colors
  }

  public get iconName() {
    return this.dataSource.iconName
  }

  public get name(): string {
    return this.dataSource.name
  }

  public get isRichTextBox(): boolean {
    return this.sitemapItem.type === MapViewItemType.TextBox
  }

  public get displayName(): string {
    if (!this.isRichTextBox) {
      return this.name
    }

    const div = document.createElement('div')
    div.innerHTML = this.name
    return div.innerText
  }

  public setName(name: string): MapViewItemBase {
    this.dataSource.name = name
    return this
  }

  public get parent(): IHierarchyParent {
    return this.dataSource.parent
  }

  public get hasParent(): boolean {
    return !!this.parent
  }

  public isParent(item: MapViewItemBase) {
    if (!this.hasParent || !item.dataObject) {
      return false
    }
    return (
      this.parent.parentId === item.dataObject.id &&
      this.parent.parentType === item.dataObject.type
    )
  }

  public setParent(parent: IHierarchyParent) {
    this.dataSource.parent = parent
  }

  public get color(): string {
    return this.dataSource.color
  }

  public setColor(color: string): MapViewItemBase {
    this.dataSource.color = color
    return this
  }

  public setAllColors(color: string): MapViewItemBase {
    this.dataSource.color = color
    if (this.sitemapItemProperties.shapeProperties) {
      this.sitemapItemProperties.shapeProperties.setFillColor(color)
      this.sitemapItemProperties.shapeProperties.setLineColor(color)
    }
    if (this.globeViewItemProperties.shapeProperties) {
      this.globeViewItemProperties.shapeProperties.setFillColor(color)
      this.globeViewItemProperties.shapeProperties.setLineColor(color)
    }
    return this
  }

  public updateIcon(iconName: MapViewLocationIcon) {
    this.dataSource.iconName = iconName
  }

  public updateShape(type: SitemapItemShapeType, isGlobeMode: boolean) {
    isGlobeMode
      ? this.updateGlobeViewShape(type)
      : this.updateSitemapShape(type)
  }

  public updateSitemapShape(type: SitemapItemShapeType) {
    if (!type) {
      this.sitemapItemProperties.shapeProperties = null
      return
    }
    if (
      this.sitemapItemProperties.shapeProperties &&
      this.sitemapItemProperties.shapeProperties.type === type
    ) {
      return
    }

    const prevShapeData: any = this.sitemapItemProperties.shapeProperties || {}
    switch (type) {
      case SitemapItemShapeType.Polyline:
        this.sitemapItemProperties.shapeProperties =
          new SitemapPolyLineProperties(
            prevShapeData.lineWidth,
            prevShapeData.lineColor || this.color,
            prevShapeData.fillColor || this.color,
            prevShapeData.fillOpacity,
          )
        break
      case SitemapItemShapeType.Circle:
        this.sitemapItemProperties.shapeProperties =
          new SitemapCircleProperties(
            prevShapeData.lineWidth,
            prevShapeData.lineColor || this.color,
            prevShapeData.fillColor || this.color,
            prevShapeData.fillOpacity,
          )
        break
      case SitemapItemShapeType.Rectangle:
        this.sitemapItemProperties.shapeProperties =
          new SitemapRectangleProperties(
            prevShapeData.lineWidth,
            prevShapeData.lineColor || this.color,
            prevShapeData.fillColor || this.color,
            prevShapeData.fillOpacity,
            null,
          )
        break
    }
  }

  public updateGlobeViewShape(type: SitemapItemShapeType) {
    if (!type) {
      this.globeViewItemProperties.shapeProperties = null
      this.sitemapItem.shapeCoordinates = null
      return
    }
    const isShapeCoordinatesChanged = !(
      this.sitemapItem.shapeCoordinates?.type === type
    )
    if (
      this.sitemapItem.shapeCoordinates &&
      !isShapeCoordinatesChanged &&
      this.globeViewItemProperties.shapeProperties?.type === type
    ) {
      return
    }

    const prevShapeData: any =
      this.globeViewItemProperties.shapeProperties || {}
    switch (type) {
      case SitemapItemShapeType.Polyline:
        this.globeViewItemProperties.shapeProperties =
          new GlobeViewPolyLineProperties(
            prevShapeData.lineWidth,
            prevShapeData.lineColor || this.color,
            prevShapeData.fillColor || this.color,
            prevShapeData.fillOpacity,
          )
        isShapeCoordinatesChanged &&
          (this.sitemapItem.shapeCoordinates = new PolyLineShapeCoordinates(
            [],
            false,
          ))
        break
      case SitemapItemShapeType.Circle:
        this.globeViewItemProperties.shapeProperties =
          new GlobeViewCircleProperties(
            prevShapeData.lineWidth,
            prevShapeData.lineColor || this.color,
            prevShapeData.fillColor || this.color,
            prevShapeData.fillOpacity,
          )
        isShapeCoordinatesChanged &&
          (this.sitemapItem.shapeCoordinates = new CircleShapeCoordinates(
            [
              Object.assign({}, this.sitemapItem.coordinates),
              {
                latitude: this.sitemapItem.coordinates.latitude,
                longitude: this.sitemapItem.coordinates.longitude - 0.001,
              },
            ],
            true,
            null,
            null,
          ))
        break
      case SitemapItemShapeType.Rectangle:
        this.globeViewItemProperties.shapeProperties =
          new GlobeViewRectangleProperties(
            prevShapeData.lineWidth,
            prevShapeData.lineColor || this.color,
            prevShapeData.fillColor || this.color,
            prevShapeData.fillOpacity,
            null,
          )
        isShapeCoordinatesChanged &&
          (this.sitemapItem.shapeCoordinates = new RectangleShapeCoordinates(
            [
              {
                latitude:
                  this.sitemapItem.coordinates.latitude +
                  COORDINATE_NORMALIZATION,
                longitude:
                  this.sitemapItem.coordinates.longitude -
                  COORDINATE_NORMALIZATION,
              },
              {
                latitude:
                  this.sitemapItem.coordinates.latitude +
                  COORDINATE_NORMALIZATION,
                longitude:
                  this.sitemapItem.coordinates.longitude +
                  COORDINATE_NORMALIZATION,
              },
              {
                latitude:
                  this.sitemapItem.coordinates.latitude -
                  COORDINATE_NORMALIZATION,
                longitude:
                  this.sitemapItem.coordinates.longitude +
                  COORDINATE_NORMALIZATION,
              },
              {
                latitude:
                  this.sitemapItem.coordinates.latitude -
                  COORDINATE_NORMALIZATION,
                longitude:
                  this.sitemapItem.coordinates.longitude -
                  COORDINATE_NORMALIZATION,
              },
              {
                latitude:
                  this.sitemapItem.coordinates.latitude +
                  COORDINATE_NORMALIZATION,
                longitude:
                  this.sitemapItem.coordinates.longitude -
                  COORDINATE_NORMALIZATION,
              },
            ],
            true,
          ))
        break
    }
  }

  public copy(): MapViewItemBase {
    return new MapViewItemBase(
      this.dataObject && this.dataObject.copy(),
      this.sitemapItem && this.sitemapItem.copy(),
      this.isDisplayed,
      this.sitemapItemProperties && this.sitemapItemProperties.copy(),
      this.globeViewItemProperties && this.globeViewItemProperties.copy(),
    )
  }

  protected get dataSource(): LocationBase | SitemapItem {
    return this.dataObject || this.sitemapItem
  }

  private createRequiredProperties() {
    if (this.isDataLess) {
      return
    }

    this.sitemapItemProperties?.createRequiredProperties?.(
      this.dataObject?.is(LocationIntegrationType.MaturixStation),
    )
    this.globeViewItemProperties?.createRequiredProperties?.(
      this.dataObject?.is(LocationIntegrationType.MaturixStation),
    )
  }
}
