import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IDeliveryStatusChangeFieldsFragment = Pick<
  Types.IDeliveryStatusChange,
  | 'id'
  | 'projectId'
  | 'deliveryId'
  | 'status'
  | 'userId'
  | 'threadId'
  | 'createdAt'
  | 'updatedAt'
>

export type IGetDeliveryStatusChangesListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  deliveryId?: Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IGetDeliveryStatusChangesListQuery = {
  deliveryStatusChanges?: Types.Maybe<{
    data: Array<IDeliveryStatusChangeFieldsFragment>
  }>
}

export type IListenToDeliveryStatusChangesSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToDeliveryStatusChangesSubscription = {
  deliveryStatusChange?: Types.Maybe<
    Pick<Types.IDeliveryStatusChangeChangeEvent, 'id'> & {
      item?: Types.Maybe<IDeliveryStatusChangeFieldsFragment>
    }
  >
}

export const DeliveryStatusChangeFieldsFragmentDoc = gql`
  fragment DeliveryStatusChangeFields on DeliveryStatusChange {
    id
    projectId
    deliveryId
    status
    userId
    threadId
    createdAt
    updatedAt
  }
`
export const GetDeliveryStatusChangesListDocument = gql`
  query GetDeliveryStatusChangesList(
    $projectId: ObjectId!
    $deliveryId: ObjectId
  ) {
    deliveryStatusChanges(
      limit: 1000000
      projectId: $projectId
      deliveryId: $deliveryId
    ) {
      data {
        ...DeliveryStatusChangeFields
      }
    }
  }
  ${DeliveryStatusChangeFieldsFragmentDoc}
`

/**
 * __useGetDeliveryStatusChangesListQuery__
 *
 * To run a query within a React component, call `useGetDeliveryStatusChangesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryStatusChangesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryStatusChangesListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      deliveryId: // value for 'deliveryId'
 *   },
 * });
 */
export function useGetDeliveryStatusChangesListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetDeliveryStatusChangesListQuery,
    IGetDeliveryStatusChangesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetDeliveryStatusChangesListQuery,
    IGetDeliveryStatusChangesListQueryVariables
  >(GetDeliveryStatusChangesListDocument, options)
}
export function useGetDeliveryStatusChangesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetDeliveryStatusChangesListQuery,
    IGetDeliveryStatusChangesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetDeliveryStatusChangesListQuery,
    IGetDeliveryStatusChangesListQueryVariables
  >(GetDeliveryStatusChangesListDocument, options)
}
export type GetDeliveryStatusChangesListQueryHookResult = ReturnType<
  typeof useGetDeliveryStatusChangesListQuery
>
export type GetDeliveryStatusChangesListLazyQueryHookResult = ReturnType<
  typeof useGetDeliveryStatusChangesListLazyQuery
>
export type GetDeliveryStatusChangesListQueryResult = Apollo.QueryResult<
  IGetDeliveryStatusChangesListQuery,
  IGetDeliveryStatusChangesListQueryVariables
>
export const ListenToDeliveryStatusChangesDocument = gql`
  subscription ListenToDeliveryStatusChanges($projectId: ObjectId!) {
    deliveryStatusChange(projectId: $projectId) {
      id
      item {
        ...DeliveryStatusChangeFields
      }
    }
  }
  ${DeliveryStatusChangeFieldsFragmentDoc}
`

/**
 * __useListenToDeliveryStatusChangesSubscription__
 *
 * To run a query within a React component, call `useListenToDeliveryStatusChangesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToDeliveryStatusChangesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToDeliveryStatusChangesSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToDeliveryStatusChangesSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToDeliveryStatusChangesSubscription,
    IListenToDeliveryStatusChangesSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToDeliveryStatusChangesSubscription,
    IListenToDeliveryStatusChangesSubscriptionVariables
  >(ListenToDeliveryStatusChangesDocument, options)
}
export type ListenToDeliveryStatusChangesSubscriptionHookResult = ReturnType<
  typeof useListenToDeliveryStatusChangesSubscription
>
export type ListenToDeliveryStatusChangesSubscriptionResult =
  Apollo.SubscriptionResult<IListenToDeliveryStatusChangesSubscription>
