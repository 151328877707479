import { TimezoneTreeType } from '../LanguageTreeType'

const timezones: TimezoneTreeType = {
  samoa: 'Стандартний час у Самоа: SST',
  niue: 'Час у Ніуе: NUT',

  hawaiiAleuntian: 'Гавайсько-Алеутський стандартний час: HST',
  cookIsland: 'Час на Островах Кука: CKT',
  tahiti: 'Час у Гаїті: TAHT',

  marquesas: 'Час на Маркізьких островах: MART',

  alaska: 'Стандартний час у Алясці: AKST',
  gambier: "Час на Островах Гамб'є: GAMT",

  pacific: 'Стандартний тихоокеанський час: PST',
  pitcairn: 'Піткернський стандартний час: PST',

  mountain: 'Стандартний гірський час: MST',
  mexicanPacific: 'Мексиканський стандартний тихоокеанський час',

  centralStandard: 'Централький стандартний час: CST',
  centralMexicoStandard: 'Централький стандартний час (Мексика): CST',
  galapagos: 'Час на Галапагоських островів: GALT',
  easterIsland: 'Стандартний час на Острові Пасхи: EAST',

  acre: 'Час у штаті Акрі: ACT',
  ecuador: 'Еквадорскьий час: ECT',
  peru: 'Перуанський час: PET',
  easternStandard: 'Східний стандартний час: EST',
  colombia: 'Колумбійський час: COT',
  cuba: 'Стандартний час на Кубі: CST',

  guyana: 'Гаянський час: GYT',
  bolivia: 'Болівійський час: BOT',
  amazon: 'Амазонський час: AMT',
  venezuela: 'Венесуельський стандартний час: VET',
  atlantic: 'Атлантичний стандартний час: AST',
  chile: 'Стардартний час у Чилі: CLT',

  newfoundland: 'Стандартний час на острові Ньюфаундленд: NST',

  pierre: "Стандартний час на островах Сен-П'єр і Мікелон: PMST",
  uruguay: 'Уругвайський час: UYT',
  falkland: 'Час на Фолклендських Островах: FKT',
  paraguay: 'Парагвайський час: PYT',
  brasilia: 'Бразильський час: BRT',
  suriname: 'Час у Суринамі: SRT',
  argentina: 'Аргентинський час: ART',
  frenchGuiana: 'Час у французькій Гвіані: GFT',
  westGreenland: 'Час у західній Гренландії: WGT(GMT -3)',
  rothera: 'Час станції Ротера: ROTT',
  palmer: 'Пальмерський час',
  puntaArenas: 'Час у Пунта-Аренас',

  southGeorgia: 'Час у південній Джорджії: GST',
  fernandoDeNoronha: 'Час у Фернанду-ді-Нороньї: FNT',

  capeVerde: 'Час у Кабо-Верде: CVT',
  azores: 'Азорський стандартний час: AZOT',
  eastGreenland: 'Час у східній Гренландії: EGT',

  coordinatedUniversal: 'Всесвітній координований час: UTC',
  greenwichMean: 'Середній час за Гринвічем: GMT',
  greenwichMeanSaoTome: 'Середній час за Гринвічем (Сан-Томе): GMT',
  westernEuropean: 'Західноєвропейський час: WET',
  westernEuropeanAfrica: 'Західноєвропейський час (Африка): WET',

  westAfrica: 'Західноафриканський час: WAT',
  centralEuropean: 'Центральноєвропейський час: CET',

  centralAfricaTime: 'Центральноафриканський час: CAT',
  southAfrica: 'Південноафриканський стандартний час',
  easternEuropean: 'Східноєвропейський час: EET',
  easternEuropeanBeirutGaza: 'Східноєвропейський час (Бейрут, Газа): EET',
  easternEuropeanDamascusAmman: 'Східноєвропейський час (Дамаск, Амман): EET',
  israel: 'Ізраїльський стандартний час: IST',

  eastAfrica: 'Східноафриканський час: EAT',
  turkey: 'Турецький час: TRT',
  arabian: 'Аравійський стандартний час: AST',
  moscow: 'Московьский стандартний час: MSK+00',
  syowa: 'Час на станції Сева',
  volgograd: 'Волгоградський стандартний час',

  iran: 'Іранський стандартний час: IRST',

  gulf: 'Стандартний час Перської затоки: GST',
  azerbaijan: 'Азербайжанський час: AZT',
  armenia: 'Арменський час: AMT',
  astrakhan: 'Астраханський час',
  ulyanovsk: 'Ульяновський час',
  samara: 'Самарський час: SAMT',
  georgia: 'Грузинський стандартний час',
  mauritius: 'Час у Республіці Маврикій: MUT',
  seychelles: 'Час на Сейшельський стровах: SCT',
  reunion: 'Час на строві Реюньйон: RET',

  afghanistan: 'Афганістанський час: AFT',

  yekaterinburg: 'Єкатеринбурзький час: YEKT',
  pakistan: 'Пакістанський стандартний час: PKT',
  tajikistan: 'Таджикистанський час',
  uzbekistan: 'Стандартний час Узбекистану',
  westKazakhstan: 'Західно-Казахстанський час',
  turkmenistan: 'Туркменістанський час: TMT',
  frenchSouthern: 'Французький південний та антарктичний час: TFT',
  maldives: 'Час на Мальдівських островах: MVT',
  mawson: 'Час станції Моусон: MAWT',

  india: 'Індійський стандартний час: IST',

  nepal: 'Непальський час: NPT',

  bhutan: 'Бутанський час: BTT',
  omsk: 'Омський стандартний час: OMST',
  kyrgyzstan: 'Киргизський час: KGT',
  bangladesh: 'Бангладеш стандартний час: BST',
  indianOcean: 'Час в Індійському океані: IOT',
  vostok: "Час на станції 'Восток': VOST",
  urumqiKashgar: 'Урумчі/Кашгарський час',
  eastKazakhstan: 'Східно-Казахстанський час',

  myanmar: "М'янмський час: MMT",
  cocosIsland: 'Час на Остріві Кокосe: CCT',

  westernIndonesia: 'Західноіндонезійський час: WIB',
  indochina: 'Час у французькому Індокитаї: ICT',
  hovd: 'Ховдський час: HOVT',
  krasnoyarsk: 'Красноярський час: KRAT',
  novosibirsk: 'Новосибірський стандартний час',
  christmasIsland: 'Час на Острові Різдва: CXT',
  davis: 'Час у Дейвісі: DAVT',
  barnaulTomsk: 'Барнаульський/Томський час',

  irkutsk: 'Іркутський час: IRKT',
  hongKong: 'Час у Гонгконзі: HKT',
  singapore: 'Час у Сінгапурі: SGT',
  malaysia: 'Малазійський час: MYT',
  australianWestern: 'Західноастралійський стандартний час: AWST',
  chinaStandard: 'Китайський стандартний час: CST',
  centralIndonesia: 'Центральноіндонезійський час: WITA',
  philippine: 'Філіпінський час: PHT',
  bruneiDarussalam: 'Час у Бруней Даруссаламі: BNT',
  taipei: 'Тайбейський стандартний час',
  ulaanbaatar: 'Стандартний час Улан-Батора',

  pyongyang: 'Час у Пхеньяні: PYT',

  australianCentralWestern:
    'Австралійський західно-центральний стандартний час: ACWST',

  easternIndonesia: 'Східноіндонезійський час: WIT',
  japan: 'Японський стандартний час: JST',
  korea: 'Корейський стандартний час: KST',
  eastTimor: 'Час у Східному Тиморі: TLT',
  yakutsk: 'Якутський час: YAKT',
  palau: 'Час у Палау: PWT',

  australianCentralStandard: 'Центральноавсталійський стандартний час: ACST',

  australianEasternStandard: 'Східноавстралійський стандартний час: AEST',
  vladivostok: 'Владивосточний час: VLAT',
  chamorro: 'Чаморрський стандартний час: ChST',
  papuaNewGuinea: 'Час у Папуа Новій Гвінеї: PGT',
  chuuk: 'Час Чуука',
  dumontdUrville: 'Час Дюмон-д’Юрвіль',

  lordHowe: 'Стандратний час на острові Лорд-Гав: LHST',

  magadan: 'Магаданський час: MAGT',
  solomonIslands: 'Час на Соломоновий островах: SBT',
  kosrae: 'Час у Косрае(Кошаї): KOST',
  ponhpei: 'Понпейський стандартний час: PONT',
  newCaledonia: 'Час у Новії Каледонії: NCT',
  norfolk: 'Час у графстві Норфолк: NFT',
  vanuatu: 'Час у Вануату: VUT',
  casey: 'Час Кейсі: CAST',
  srednekolymskBougainville: 'Середньоколимськ/Бугенвільський час',
  sakhalin: 'Сахалінський стандартний час',

  marshallIslands: 'Час на Маршалових Островах: MHT',
  petropavlovskKamchatski: 'Час на Камчатці: PETT',
  tuvalu: 'Час у Тувалу: TVT',
  gilbertIslands: 'Час на Островах Гілберта: GILT',
  nauru: 'Час Республіки Науру: NRT',
  wakeIsland: 'Час на острові Вейк: WAKT',
  wallisAndFutuna: 'Час Волліса і Футуни: WFT',
  anadyr: 'Анадирський стандартний час',
  fiji: 'Стандартний час Фіджі: FJT',
  newZealand: 'Новозеландський стандартний час: NZST',

  chatham: 'Чатемський стандартний час: CHAST',

  phoenixIslands: 'Час на островах Фенікс: PHOT',
  tokelau: 'Час у Токелау: TKT',
  tonga: 'Час у Королівстві Тонґа: TOT',
  apia: 'Стандартний час у Апії',

  lineIslands: 'Час на Островах Лайн: LINT',
}

export default timezones
