import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ICdPayloadFieldsFragment = Pick<
  Types.IConcreteDirectPayload,
  | 'id'
  | 'payloadId'
  | 'transportedByVehicleId'
  | 'siteId'
  | 'orderId'
  | 'orderItemId'
  | 'quantity'
  | 'status'
  | 'permittedStatuses'
  | 'hasESignature'
  | 'ticketReference'
  | 'eta'
  | 'etaQuality'
  | 'etaCalculatedAt'
  | 'cumulativeQuantity'
  | 'lastUpdatedAt'
  | 'isReviewed'
  | 'supportsAdditives'
  | 'createdAt'
  | 'eSignatureMissing'
  | 'deliveredAt'
  | 'slump'
  | 'isRetro'
  | 'isRedirect'
  | 'totalNoOfTests'
  | 'totalNoOfTestsCompleted'
  | 'timeOnSite'
  | 'deliveredQuantity'
  | 'returnedQuantity'
> & {
  payloadEvent?: Types.Maybe<
    Pick<
      Types.IConcreteDirectPayloadEvent,
      | 'typedTime'
      | 'batchStartTime'
      | 'batchEndTime'
      | 'departureTime'
      | 'departureTimeQuality'
      | 'arrivalTime'
      | 'pouringStartTime'
      | 'toPlantTime'
      | 'washTime'
    >
  >
  waterContent?: Types.Maybe<
    Pick<
      Types.IConcreteDirectWaterContent,
      | 'waterCementRatioDesign'
      | 'waterCementRatioActual'
      | 'designWater'
      | 'designWaterUOM'
      | 'actualWater'
      | 'actualWaterUOM'
      | 'waterToAdd'
      | 'waterToAddUOM'
    >
  >
}

export type IGetConcreteDirectPayloadsByOrderIdQueryVariables = Types.Exact<{
  cdOrderId: Types.Scalars['Guid']
}>

export type IGetConcreteDirectPayloadsByOrderIdQuery = {
  concreteDirectPayloads?: Types.Maybe<{
    data: Array<ICdPayloadFieldsFragment>
  }>
}

export type IListenCdPayloadByOrderIdSubscriptionVariables = Types.Exact<{
  cdOrderId: Types.Scalars['Guid']
}>

export type IListenCdPayloadByOrderIdSubscription = {
  concreteDirectPayload?: Types.Maybe<
    Pick<Types.IConcreteDirectPayloadChangeEvent, 'id'> & {
      item?: Types.Maybe<ICdPayloadFieldsFragment>
    }
  >
}

export const CdPayloadFieldsFragmentDoc = gql`
  fragment CdPayloadFields on ConcreteDirectPayload {
    id
    payloadId
    transportedByVehicleId
    siteId
    orderId
    orderItemId
    quantity
    status
    permittedStatuses
    hasESignature
    ticketReference
    eta
    etaQuality
    etaCalculatedAt
    payloadEvent {
      typedTime
      batchStartTime
      batchEndTime
      departureTime
      departureTimeQuality
      arrivalTime
      pouringStartTime
      toPlantTime
      washTime
    }
    cumulativeQuantity
    lastUpdatedAt
    isReviewed
    supportsAdditives
    createdAt
    eSignatureMissing
    deliveredAt
    slump
    isRetro
    isRedirect
    totalNoOfTests
    totalNoOfTestsCompleted
    timeOnSite
    deliveredQuantity
    returnedQuantity
    waterContent {
      waterCementRatioDesign
      waterCementRatioActual
      designWater
      designWaterUOM
      actualWater
      actualWaterUOM
      waterToAdd
      waterToAddUOM
    }
  }
`
export const GetConcreteDirectPayloadsByOrderIdDocument = gql`
  query GetConcreteDirectPayloadsByOrderId($cdOrderId: Guid!) {
    concreteDirectPayloads(cdOrderId: $cdOrderId, limit: 1000000) {
      data {
        ...CdPayloadFields
      }
    }
  }
  ${CdPayloadFieldsFragmentDoc}
`

/**
 * __useGetConcreteDirectPayloadsByOrderIdQuery__
 *
 * To run a query within a React component, call `useGetConcreteDirectPayloadsByOrderIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConcreteDirectPayloadsByOrderIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConcreteDirectPayloadsByOrderIdQuery({
 *   variables: {
 *      cdOrderId: // value for 'cdOrderId'
 *   },
 * });
 */
export function useGetConcreteDirectPayloadsByOrderIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetConcreteDirectPayloadsByOrderIdQuery,
    IGetConcreteDirectPayloadsByOrderIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetConcreteDirectPayloadsByOrderIdQuery,
    IGetConcreteDirectPayloadsByOrderIdQueryVariables
  >(GetConcreteDirectPayloadsByOrderIdDocument, options)
}
export function useGetConcreteDirectPayloadsByOrderIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetConcreteDirectPayloadsByOrderIdQuery,
    IGetConcreteDirectPayloadsByOrderIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetConcreteDirectPayloadsByOrderIdQuery,
    IGetConcreteDirectPayloadsByOrderIdQueryVariables
  >(GetConcreteDirectPayloadsByOrderIdDocument, options)
}
export type GetConcreteDirectPayloadsByOrderIdQueryHookResult = ReturnType<
  typeof useGetConcreteDirectPayloadsByOrderIdQuery
>
export type GetConcreteDirectPayloadsByOrderIdLazyQueryHookResult = ReturnType<
  typeof useGetConcreteDirectPayloadsByOrderIdLazyQuery
>
export type GetConcreteDirectPayloadsByOrderIdQueryResult = Apollo.QueryResult<
  IGetConcreteDirectPayloadsByOrderIdQuery,
  IGetConcreteDirectPayloadsByOrderIdQueryVariables
>
export const ListenCdPayloadByOrderIdDocument = gql`
  subscription ListenCdPayloadByOrderId($cdOrderId: Guid!) {
    concreteDirectPayload(cdOrderId: $cdOrderId) {
      id
      item {
        ...CdPayloadFields
      }
    }
  }
  ${CdPayloadFieldsFragmentDoc}
`

/**
 * __useListenCdPayloadByOrderIdSubscription__
 *
 * To run a query within a React component, call `useListenCdPayloadByOrderIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenCdPayloadByOrderIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenCdPayloadByOrderIdSubscription({
 *   variables: {
 *      cdOrderId: // value for 'cdOrderId'
 *   },
 * });
 */
export function useListenCdPayloadByOrderIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenCdPayloadByOrderIdSubscription,
    IListenCdPayloadByOrderIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenCdPayloadByOrderIdSubscription,
    IListenCdPayloadByOrderIdSubscriptionVariables
  >(ListenCdPayloadByOrderIdDocument, options)
}
export type ListenCdPayloadByOrderIdSubscriptionHookResult = ReturnType<
  typeof useListenCdPayloadByOrderIdSubscription
>
export type ListenCdPayloadByOrderIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenCdPayloadByOrderIdSubscription>
