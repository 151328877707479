import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { NavigationItem } from '../TagsSelector.store'

import Colors from '~/client/src/shared/theme.module.scss'

// localization: no display text to translate

interface IProps {
  navigationChain: NavigationItem[]
  onBackClick: () => void
  onItemClick: (index: number) => void
}

export function BreadCrumbs({
  navigationChain,
  onBackClick,
  onItemClick,
}: IProps) {
  return (
    <div className="row text large light py8">
      <Icon
        className="pointer mr12 no-grow"
        icon={IconNames.CARET_LEFT}
        color={Colors.neutral60}
        onClick={onBackClick}
      />
      <div>
        {navigationChain.map(({ itemId, categoryId, title }, index) => (
          <React.Fragment key={itemId || categoryId}>
            {index > 0 && <span className="mx4">/</span>}
            <span className="pointer" onClick={onItemClick.bind(null, index)}>
              {title}
            </span>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
