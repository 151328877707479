import { ActivitiesTreeNodeTypes } from '~/client/src/shared/enums/ActivitiesTreeNodeTypes'
import Activity from '~/client/src/shared/models/Activity'
import { IActivitiesTreeNode } from '~/client/src/shared/models/IActivitiesTreeNode'

import IActivitiesTreeNodeSource from './IActivitiesTreeNodeSource'

export default class ActivitiesTreeNode implements IActivitiesTreeNode {
  public static fromSource(source: IActivitiesTreeNodeSource, stateData: any) {
    const node = new ActivitiesTreeNode(source.id, source.name, source.nodeType)
    return Object.assign(node, source, stateData)
  }

  public deepness = null
  public parents = null

  public state?: any
  public entity?: Activity
  public hasRfi?: boolean
  public hasFlag?: boolean
  public hasScheduleComment?: boolean
  public hasCategoryOfVariance?: boolean
  public hasSafetyHazard?: boolean
  public hasDelivery?: boolean
  public hasCriticalPath?: boolean
  public totalEntitiesAmount?: number
  public updatedActivitiesAmount?: number
  public updatedActivitiesAmountWithManpower?: number
  public totalManpower?: number
  public sectionCompanyName?: string
  public children?: IActivitiesTreeNode[]
  public level?: number = 1
  public targetPercent?: number = 0
  public startDate?: Date = null
  public endDate?: Date = null
  public container?: { [key: string]: string } = null
  public onClick?: () => void
  public onTopBarClick?: () => void

  public constructor(
    public id: number | string,
    public name: string,
    public nodeType: ActivitiesTreeNodeTypes,
    dto: any = {},
  ) {
    this.entity = dto.entity || null
    this.state = dto.state || {}
    this.hasRfi = !!dto.hasRfi
    this.hasScheduleComment = !!dto.hasScheduleComment
    this.hasCategoryOfVariance = !!dto.hasCategoryOfVariance
    this.hasSafetyHazard = !!dto.hasSafetyHazard
    this.hasFlag = !!dto.hasFlag
    this.hasDelivery = !!dto.hasDelivery
    this.hasCriticalPath = !!dto.hasCriticalPath
    this.totalEntitiesAmount = dto.totalEntitiesAmount || 0
    this.updatedActivitiesAmount = dto.updatedActivitiesAmount || 0
    this.updatedActivitiesAmountWithManpower =
      dto.updatedActivitiesAmountWithManpower || 0
    this.totalManpower = dto.totalManpower
    this.children = dto.children || []
    this.container = dto.container
    this.level = dto.level
    this.startDate = dto.startDate || null
    this.endDate = dto.endDate || null
    this.targetPercent = dto.targetPercent || 0
    this.onClick = dto.onClick
    this.sectionCompanyName = dto.sectionCompanyName
    this.onTopBarClick = dto.onTopBarClick
  }
}
