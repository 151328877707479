import { action } from 'mobx'

import { IUserRoleInput } from '~/client/graph'
import { TagIconType } from '~/client/src/shared/enums/TagIcon'
import { TagType } from '~/client/src/shared/enums/TagType'
import BaseTagsStore from '~/client/src/shared/stores/BaseTags.store'

import { ITag } from '../../models/Tag'
import {
  DELETE_PROJECT_ROLE,
  SAVE_PROJECT_ROLE,
} from '../EventStore/eventConstants'

export default class ProjectRolesStore extends BaseTagsStore {
  protected readonly iconName: TagIconType = TagIconType.Role
  protected readonly tagType: TagType = TagType.Role

  public get byId() {
    return this.eventsStore.appState.projectRoles
  }

  @action.bound
  public save(tag: ITag, callback?: (id: string) => void) {
    const userRole: IUserRoleInput = {
      id: tag.id,
      name: tag.name,
      color: tag.color,
      projectId: this.eventsStore.appState.activeProject.id,
    }

    this.eventsStore.dispatch(SAVE_PROJECT_ROLE, userRole, callback)
  }

  @action.bound
  public delete(userRoleId: string, callback?: () => void) {
    this.eventsStore.dispatch(DELETE_PROJECT_ROLE, userRoleId, callback)
  }
}
