import {
  DeleteManyDeliveryAssignmentsDocument,
  SaveManyDeliveryAssignmentsDocument,
} from '~/client/graph/operations/generated/DeliveryAssignments.generated'

import BaseAssignmentsStore from '../BaseAssignments.store'
import EventsStore from '../EventStore/Events.store'
import TagsStore from './Tags.store'

export default class DeliveryAssignmentsStore extends BaseAssignmentsStore {
  public constructor(eventsStore: EventsStore, tagsStore: TagsStore) {
    super(
      eventsStore,
      tagsStore,
      SaveManyDeliveryAssignmentsDocument,
      DeleteManyDeliveryAssignmentsDocument,
    )
  }

  public get byId() {
    return this.eventsStore.appState.deliveryAssignments
  }
}
