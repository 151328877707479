import * as React from 'react'

import { Icon, IconSize } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import Dimmer from '~/client/src/shared/components/Dimmer'
import * as Icons from '~/client/src/shared/components/Icons'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import CommonDatePicker from '../../../CommonDatePicker/CommonDatePicker'
import MonthPicker from '../../../CommonDatePicker/MonthPicker/MonthPicker'
import StruxhubInput from '../../../StruxhubInputs/StruxhubInput'
import StruxhubTextValueSelector from '../../../StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'
import DeliverySelectDateModalStore from './DeliverySelectDateModal.store'
import TimePicker from './components/TimePicker'

import './DeliverySelectDateModal.scss'

// localization: translated

interface IProps {
  isShown: boolean
  onHide: () => void

  initStartDate?: number
  initEndDate?: number
  isRecurringDateMode?: boolean

  projectDateStore?: ProjectDateStore
  deliveryDetailsStore: DeliveryDetailsStore
}

@inject('projectDateStore')
@observer
export default class DeliverySelectDateModal extends React.Component<IProps> {
  private readonly store: DeliverySelectDateModalStore = null
  public constructor(props: IProps) {
    super(props)

    this.store = new DeliverySelectDateModalStore(
      props.projectDateStore,
      props.deliveryDetailsStore,
    )
  }

  public componentDidMount() {
    const {
      isRecurringDateMode,
      initStartDate,
      initEndDate,
      deliveryDetailsStore,
    } = this.props
    const { isExpandedSelectDateMode, selectedDates } = deliveryDetailsStore
    const {
      selectDayMode,
      onInitDates,
      onRecurringInitialDateChange,
      updateCurrentMonth,
    } = this.store
    const { startDate, endDate } = selectedDates || {}

    selectDayMode(isRecurringDateMode || !isExpandedSelectDateMode)

    if (isRecurringDateMode) {
      return onRecurringInitialDateChange(initStartDate)
    }

    onInitDates(initStartDate || startDate, initEndDate || endDate)

    if (startDate) {
      updateCurrentMonth(startDate)
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      initStartDate,
      initEndDate,
      isRecurringDateMode,
      isShown,
      projectDateStore,
      deliveryDetailsStore,
    } = this.props
    const {
      selectDayMode,
      onCleanup,
      onInitDates,
      onRecurringInitialDateChange,
      updateCurrentMonth,
    } = this.store

    if (
      prevProps.initStartDate !== initStartDate ||
      prevProps.initEndDate !== initEndDate
    ) {
      selectDayMode(projectDateStore.isSameDay(initStartDate, initEndDate))
    }

    if (!isShown && isShown !== prevProps.isShown) {
      onCleanup()
    }

    if (isShown && isShown !== prevProps.isShown) {
      const { startDate, endDate } = deliveryDetailsStore.selectedDates || {}

      if (isRecurringDateMode) {
        return onRecurringInitialDateChange(initStartDate)
      }
      if (startDate) {
        updateCurrentMonth(startDate)
      }

      onInitDates(startDate || initStartDate, endDate || initEndDate)
    }
  }

  public render() {
    const { isShown } = this.props

    return (
      <>
        <Dimmer shown={isShown} onClick={this.onHide} />
        <div
          className={classList({
            'select-date-modal bradt8': true,
            shown: isShown,
          })}
        >
          <div className="row y-center px10 pt10 pb5">
            <div className="no-grow pointer" onClick={this.onHide}>
              <Icons.Cross className="cross-icon" />
            </div>
            <div className="text center large bold lp015 pr25">
              {Localization.translator.selectDateAndTime}
            </div>
          </div>
          {this.renderCalendar()}
          {this.controlSection}
          <div className="col mx15 py12">
            <button
              onClick={this.onSaveDate}
              className="save-button h40 ba-transparent brada4 text white large bold lp015 center pointer"
            >
              {Localization.translator.save}
            </button>
          </div>
        </div>
      </>
    )
  }

  private renderFieldSeparator() {
    return <i className="no-flex text bold large mx15">{'–'}</i>
  }

  private renderCalendar(): JSX.Element {
    const {
      projectDateStore: { getMonthAndYearToDisplay, weekdayNamesList },
      isRecurringDateMode,
    } = this.props
    const { isOneDayMode, startDate, endDate } = this.store

    const {
      selectedMonth,
      isShowMonthPicker,
      prevMonth,
      nextMonth,
      onDateClick,
      showMonthPicker,
      hideMonthPicker,
      updateCurrentMonth,
      maxRecurringEndDate,
      minRecurringEndDate,
    } = this.store

    return (
      <>
        <div className="weekdays row bb-palette-grey py5 mb5 px16">
          {weekdayNamesList.map((day, index) => (
            <div
              key={index}
              className="text center grey-light uppercase bold small line-16 lp15 opacity6"
            >
              {day}
            </div>
          ))}
        </div>
        <div className="row px16 mb10 mt15">
          <div>
            <div className="month-label" onClick={showMonthPicker}>
              <span className="text bold extra-large line-extra-large lp015 primary">
                {getMonthAndYearToDisplay(selectedMonth)}
              </span>
              <Icon
                className="caret-icon"
                size={IconSize.STANDARD}
                icon={IconNames.CARET_DOWN}
              />
            </div>
            <MonthPicker
              shown={isShowMonthPicker}
              value={selectedMonth}
              onHide={hideMonthPicker}
              onChange={updateCurrentMonth}
            />
          </div>
          <div className="row x-end no-flex-children">
            <Icons.ArrowBack
              onClick={prevMonth}
              className="month-navigation pointer mr5"
            />
            <Icons.ArrowForward
              onClick={nextMonth}
              className="month-navigation pointer mr5"
            />
          </div>
        </div>
        <CommonDatePicker
          monthDate={selectedMonth}
          dateClickHandler={onDateClick}
          startDate={startDate}
          endDate={endDate}
          isOneDayMode={isOneDayMode}
          maxDate={isRecurringDateMode && maxRecurringEndDate}
          minDate={isRecurringDateMode && minRecurringEndDate}
          withoutHeader
        />
      </>
    )
  }

  private get controlSection(): JSX.Element {
    const { isRecurringDateMode, deliveryDetailsStore, projectDateStore } =
      this.props
    const {
      getMonthDayAndYearToDisplay,
      convertToProjectDate,
      getTimeToDisplay,
    } = projectDateStore
    const { startTime, endTime } = deliveryDetailsStore.selectedTimes

    const {
      endDate,
      startDate,
      isOneDayMode,
      activeFieldTime,
      isShowTimePicker,
      isShowToggleDatePopup,
      hideTimePicker,
      hideToggleDatePopup,
      showTimePicker,
      showToggleDatePopup,
      onApplyTimePickerSelection,
    } = this.store

    if (isRecurringDateMode) {
      return null
    }

    return (
      <div className="control-section bg-white">
        <div className="row">
          <div className="row flex-grow2 ml15 mr50">
            <StruxhubInput
              isMinimalisticMode={true}
              isReadOnly={true}
              value={
                startDate
                  ? getMonthDayAndYearToDisplay(startDate)
                  : Localization.translator.startDate
              }
            />
            {!isOneDayMode && (
              <>
                {this.renderFieldSeparator()}
                <StruxhubInput
                  isMinimalisticMode={true}
                  isReadOnly={true}
                  value={
                    endDate
                      ? getMonthDayAndYearToDisplay(endDate)
                      : Localization.translator.finishDate
                  }
                />
              </>
            )}
          </div>
          <div
            className="absolute-right pointer mx15"
            onClick={showToggleDatePopup}
          >
            <Icons.ThreeDots />
            {isShowToggleDatePopup && (
              <MenuCloser closeMenu={hideToggleDatePopup}>
                <div
                  className="selection-button row no-flex-children absolute bg-white ba-palette-brand-lightest px20 cursor brada8"
                  onClick={this.onToggleSelectionMode}
                >
                  <Icons.CalendarSelectedGrey className="mr20" />
                  <span className="text extra-large lp035">
                    {isOneDayMode
                      ? Localization.translator.addFinishDate
                      : Localization.translator.removeFinish}
                  </span>
                </div>
              </MenuCloser>
            )}
          </div>
        </div>
        <div className="row ml15 mr50">
          <StruxhubTextValueSelector
            isMinimalisticMode={true}
            onClick={() => showTimePicker(FieldIds.START_TIME)}
            value={
              startTime
                ? getTimeToDisplay(convertToProjectDate(startTime))
                : Localization.translator.startTime
            }
          />
          {this.renderFieldSeparator()}
          <StruxhubTextValueSelector
            isMinimalisticMode={true}
            onClick={() => showTimePicker(FieldIds.END_TIME)}
            value={
              endTime
                ? getTimeToDisplay(convertToProjectDate(endTime))
                : Localization.translator.endTime
            }
          />
        </div>
        {isShowTimePicker && (
          <>
            <Dimmer shown={isShowTimePicker} onClick={hideTimePicker} />
            <TimePicker
              defaultTime={activeFieldTime}
              onApply={onApplyTimePickerSelection}
              onHide={hideTimePicker}
              title={Localization.translator.selectTime}
            />
          </>
        )}
      </div>
    )
  }

  @action.bound
  private onSaveDate() {
    const { isRecurringDateMode, projectDateStore, deliveryDetailsStore } =
      this.props
    const { getDashedFormattedDate } = projectDateStore
    const { onFieldValueChange } = deliveryDetailsStore
    const { startDate, endDate, saveRecurringEndDate } = this.store

    if (isRecurringDateMode) {
      saveRecurringEndDate()
    } else {
      onFieldValueChange(FieldIds.DATE, getDashedFormattedDate(startDate))
      onFieldValueChange(FieldIds.END_DATE, getDashedFormattedDate(endDate))
    }

    this.onHide()
  }

  @action.bound
  private onHide() {
    this.store.hideTimePicker()
    this.props.onHide()
  }

  private onToggleSelectionMode = (event: React.MouseEvent) => {
    event.stopPropagation()
    this.store.toggleSelectionMode()
  }
}
