import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IConstraintsByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IConstraintsByProjectIdQuery = {
  constraints?: Types.Maybe<{
    data: Array<
      Pick<
        Types.IConstraint,
        | 'id'
        | 'projectId'
        | 'buildingId'
        | 'zoneId'
        | 'restrictedRoutes'
        | 'restrictedGates'
        | 'restrictedEquipment'
        | 'restrictedLevels'
        | 'restrictedAreas'
        | 'restrictedStagings'
        | 'restrictedInteriorPaths'
        | 'restrictedInteriorDoors'
        | 'isExplicit'
        | 'createdAt'
        | 'updatedAt'
      >
    >
  }>
}

export type ISaveManyConstraintsMutationVariables = Types.Exact<{
  constraints:
    | Array<Types.Maybe<Types.IConstraintInput>>
    | Types.Maybe<Types.IConstraintInput>
}>

export type ISaveManyConstraintsMutation = {
  saveManyConstraints?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IConstraint, 'id'>>>
  >
}

export type IDeleteManyConstraintsMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteManyConstraintsMutation = Pick<
  Types.IMutation,
  'deleteManyConstraints'
>

export type IListenConstraintsByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenConstraintsByProjectIdSubscription = {
  constraint?: Types.Maybe<
    Pick<Types.IConstraintChangeEvent, 'id'> & {
      item?: Types.Maybe<
        Pick<
          Types.IConstraint,
          | 'id'
          | 'projectId'
          | 'buildingId'
          | 'zoneId'
          | 'restrictedRoutes'
          | 'restrictedGates'
          | 'restrictedEquipment'
          | 'restrictedLevels'
          | 'restrictedAreas'
          | 'restrictedStagings'
          | 'restrictedInteriorPaths'
          | 'restrictedInteriorDoors'
          | 'isExplicit'
          | 'createdAt'
          | 'updatedAt'
        >
      >
    }
  >
}

export const ConstraintsByProjectIdDocument = gql`
  query ConstraintsByProjectId($projectId: ObjectId!) {
    constraints(
      projectId: $projectId
      offset: 0
      limit: 1000000
      sort: { by: "id", order: ASCENDING }
    ) {
      data {
        id
        projectId
        buildingId
        zoneId
        restrictedRoutes
        restrictedGates
        restrictedEquipment
        restrictedLevels
        restrictedAreas
        restrictedStagings
        restrictedInteriorPaths
        restrictedInteriorDoors
        isExplicit
        createdAt
        updatedAt
      }
    }
  }
`

/**
 * __useConstraintsByProjectIdQuery__
 *
 * To run a query within a React component, call `useConstraintsByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstraintsByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstraintsByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useConstraintsByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IConstraintsByProjectIdQuery,
    IConstraintsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IConstraintsByProjectIdQuery,
    IConstraintsByProjectIdQueryVariables
  >(ConstraintsByProjectIdDocument, options)
}
export function useConstraintsByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IConstraintsByProjectIdQuery,
    IConstraintsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IConstraintsByProjectIdQuery,
    IConstraintsByProjectIdQueryVariables
  >(ConstraintsByProjectIdDocument, options)
}
export type ConstraintsByProjectIdQueryHookResult = ReturnType<
  typeof useConstraintsByProjectIdQuery
>
export type ConstraintsByProjectIdLazyQueryHookResult = ReturnType<
  typeof useConstraintsByProjectIdLazyQuery
>
export type ConstraintsByProjectIdQueryResult = Apollo.QueryResult<
  IConstraintsByProjectIdQuery,
  IConstraintsByProjectIdQueryVariables
>
export const SaveManyConstraintsDocument = gql`
  mutation SaveManyConstraints($constraints: [ConstraintInput]!) {
    saveManyConstraints(constraints: $constraints) {
      id
    }
  }
`
export type ISaveManyConstraintsMutationFn = Apollo.MutationFunction<
  ISaveManyConstraintsMutation,
  ISaveManyConstraintsMutationVariables
>

/**
 * __useSaveManyConstraintsMutation__
 *
 * To run a mutation, you first call `useSaveManyConstraintsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyConstraintsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyConstraintsMutation, { data, loading, error }] = useSaveManyConstraintsMutation({
 *   variables: {
 *      constraints: // value for 'constraints'
 *   },
 * });
 */
export function useSaveManyConstraintsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyConstraintsMutation,
    ISaveManyConstraintsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyConstraintsMutation,
    ISaveManyConstraintsMutationVariables
  >(SaveManyConstraintsDocument, options)
}
export type SaveManyConstraintsMutationHookResult = ReturnType<
  typeof useSaveManyConstraintsMutation
>
export type SaveManyConstraintsMutationResult =
  Apollo.MutationResult<ISaveManyConstraintsMutation>
export type SaveManyConstraintsMutationOptions = Apollo.BaseMutationOptions<
  ISaveManyConstraintsMutation,
  ISaveManyConstraintsMutationVariables
>
export const DeleteManyConstraintsDocument = gql`
  mutation DeleteManyConstraints($ids: [ObjectId]!) {
    deleteManyConstraints(ids: $ids)
  }
`
export type IDeleteManyConstraintsMutationFn = Apollo.MutationFunction<
  IDeleteManyConstraintsMutation,
  IDeleteManyConstraintsMutationVariables
>

/**
 * __useDeleteManyConstraintsMutation__
 *
 * To run a mutation, you first call `useDeleteManyConstraintsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyConstraintsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyConstraintsMutation, { data, loading, error }] = useDeleteManyConstraintsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteManyConstraintsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteManyConstraintsMutation,
    IDeleteManyConstraintsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteManyConstraintsMutation,
    IDeleteManyConstraintsMutationVariables
  >(DeleteManyConstraintsDocument, options)
}
export type DeleteManyConstraintsMutationHookResult = ReturnType<
  typeof useDeleteManyConstraintsMutation
>
export type DeleteManyConstraintsMutationResult =
  Apollo.MutationResult<IDeleteManyConstraintsMutation>
export type DeleteManyConstraintsMutationOptions = Apollo.BaseMutationOptions<
  IDeleteManyConstraintsMutation,
  IDeleteManyConstraintsMutationVariables
>
export const ListenConstraintsByProjectIdDocument = gql`
  subscription ListenConstraintsByProjectId($projectId: ObjectId!) {
    constraint(projectId: $projectId) {
      id
      item {
        id
        projectId
        buildingId
        zoneId
        restrictedRoutes
        restrictedGates
        restrictedEquipment
        restrictedLevels
        restrictedAreas
        restrictedStagings
        restrictedInteriorPaths
        restrictedInteriorDoors
        isExplicit
        createdAt
        updatedAt
      }
    }
  }
`

/**
 * __useListenConstraintsByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenConstraintsByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenConstraintsByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenConstraintsByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenConstraintsByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenConstraintsByProjectIdSubscription,
    IListenConstraintsByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenConstraintsByProjectIdSubscription,
    IListenConstraintsByProjectIdSubscriptionVariables
  >(ListenConstraintsByProjectIdDocument, options)
}
export type ListenConstraintsByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenConstraintsByProjectIdSubscription
>
export type ListenConstraintsByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenConstraintsByProjectIdSubscription>
