import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IDeliveryVehicleTypeFieldsFragment = Pick<
  Types.IDeliveryVehicleType,
  'id' | 'projectId' | 'name' | 'isDeleted'
> & {
  metadata?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IStringPair, 'key' | 'value'>>>
  >
}

export type IGetDeliveryVehicleTypesQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetDeliveryVehicleTypesQuery = {
  deliveryVehicleTypes?: Types.Maybe<{
    data: Array<IDeliveryVehicleTypeFieldsFragment>
  }>
}

export type IListenToDeliveryVehicleTypesSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToDeliveryVehicleTypesSubscription = {
  deliveryVehicleType?: Types.Maybe<
    Pick<Types.IDeliveryVehicleTypeChangeEvent, 'id'> & {
      item?: Types.Maybe<IDeliveryVehicleTypeFieldsFragment>
    }
  >
}

export type ISaveDeliveryVehicleTypesMutationVariables = Types.Exact<{
  attrs:
    | Array<Types.Maybe<Types.IDeliveryVehicleTypeInput>>
    | Types.Maybe<Types.IDeliveryVehicleTypeInput>
}>

export type ISaveDeliveryVehicleTypesMutation = {
  saveManyDeliveryVehicleTypes?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IDeliveryVehicleType, 'id'>>>
  >
}

export type IDeleteDeliveryVehicleTypesMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteDeliveryVehicleTypesMutation = Pick<
  Types.IMutation,
  'softDeleteManyVehicleTypes'
>

export const DeliveryVehicleTypeFieldsFragmentDoc = gql`
  fragment DeliveryVehicleTypeFields on DeliveryVehicleType {
    id
    projectId
    name
    isDeleted
    metadata {
      key
      value
    }
  }
`
export const GetDeliveryVehicleTypesDocument = gql`
  query GetDeliveryVehicleTypes($projectId: ObjectId!) {
    deliveryVehicleTypes(limit: 1000000, projectId: $projectId) {
      data {
        ...DeliveryVehicleTypeFields
      }
    }
  }
  ${DeliveryVehicleTypeFieldsFragmentDoc}
`

/**
 * __useGetDeliveryVehicleTypesQuery__
 *
 * To run a query within a React component, call `useGetDeliveryVehicleTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryVehicleTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryVehicleTypesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetDeliveryVehicleTypesQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetDeliveryVehicleTypesQuery,
    IGetDeliveryVehicleTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetDeliveryVehicleTypesQuery,
    IGetDeliveryVehicleTypesQueryVariables
  >(GetDeliveryVehicleTypesDocument, options)
}
export function useGetDeliveryVehicleTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetDeliveryVehicleTypesQuery,
    IGetDeliveryVehicleTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetDeliveryVehicleTypesQuery,
    IGetDeliveryVehicleTypesQueryVariables
  >(GetDeliveryVehicleTypesDocument, options)
}
export type GetDeliveryVehicleTypesQueryHookResult = ReturnType<
  typeof useGetDeliveryVehicleTypesQuery
>
export type GetDeliveryVehicleTypesLazyQueryHookResult = ReturnType<
  typeof useGetDeliveryVehicleTypesLazyQuery
>
export type GetDeliveryVehicleTypesQueryResult = Apollo.QueryResult<
  IGetDeliveryVehicleTypesQuery,
  IGetDeliveryVehicleTypesQueryVariables
>
export const ListenToDeliveryVehicleTypesDocument = gql`
  subscription ListenToDeliveryVehicleTypes($projectId: ObjectId!) {
    deliveryVehicleType(projectId: $projectId) {
      id
      item {
        ...DeliveryVehicleTypeFields
      }
    }
  }
  ${DeliveryVehicleTypeFieldsFragmentDoc}
`

/**
 * __useListenToDeliveryVehicleTypesSubscription__
 *
 * To run a query within a React component, call `useListenToDeliveryVehicleTypesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToDeliveryVehicleTypesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToDeliveryVehicleTypesSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToDeliveryVehicleTypesSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToDeliveryVehicleTypesSubscription,
    IListenToDeliveryVehicleTypesSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToDeliveryVehicleTypesSubscription,
    IListenToDeliveryVehicleTypesSubscriptionVariables
  >(ListenToDeliveryVehicleTypesDocument, options)
}
export type ListenToDeliveryVehicleTypesSubscriptionHookResult = ReturnType<
  typeof useListenToDeliveryVehicleTypesSubscription
>
export type ListenToDeliveryVehicleTypesSubscriptionResult =
  Apollo.SubscriptionResult<IListenToDeliveryVehicleTypesSubscription>
export const SaveDeliveryVehicleTypesDocument = gql`
  mutation saveDeliveryVehicleTypes($attrs: [DeliveryVehicleTypeInput]!) {
    saveManyDeliveryVehicleTypes(deliveryVehicleTypes: $attrs) {
      id
    }
  }
`
export type ISaveDeliveryVehicleTypesMutationFn = Apollo.MutationFunction<
  ISaveDeliveryVehicleTypesMutation,
  ISaveDeliveryVehicleTypesMutationVariables
>

/**
 * __useSaveDeliveryVehicleTypesMutation__
 *
 * To run a mutation, you first call `useSaveDeliveryVehicleTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDeliveryVehicleTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDeliveryVehicleTypesMutation, { data, loading, error }] = useSaveDeliveryVehicleTypesMutation({
 *   variables: {
 *      attrs: // value for 'attrs'
 *   },
 * });
 */
export function useSaveDeliveryVehicleTypesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveDeliveryVehicleTypesMutation,
    ISaveDeliveryVehicleTypesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveDeliveryVehicleTypesMutation,
    ISaveDeliveryVehicleTypesMutationVariables
  >(SaveDeliveryVehicleTypesDocument, options)
}
export type SaveDeliveryVehicleTypesMutationHookResult = ReturnType<
  typeof useSaveDeliveryVehicleTypesMutation
>
export type SaveDeliveryVehicleTypesMutationResult =
  Apollo.MutationResult<ISaveDeliveryVehicleTypesMutation>
export type SaveDeliveryVehicleTypesMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveDeliveryVehicleTypesMutation,
    ISaveDeliveryVehicleTypesMutationVariables
  >
export const DeleteDeliveryVehicleTypesDocument = gql`
  mutation DeleteDeliveryVehicleTypes($ids: [ObjectId]!) {
    softDeleteManyVehicleTypes(ids: $ids)
  }
`
export type IDeleteDeliveryVehicleTypesMutationFn = Apollo.MutationFunction<
  IDeleteDeliveryVehicleTypesMutation,
  IDeleteDeliveryVehicleTypesMutationVariables
>

/**
 * __useDeleteDeliveryVehicleTypesMutation__
 *
 * To run a mutation, you first call `useDeleteDeliveryVehicleTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeliveryVehicleTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeliveryVehicleTypesMutation, { data, loading, error }] = useDeleteDeliveryVehicleTypesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteDeliveryVehicleTypesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteDeliveryVehicleTypesMutation,
    IDeleteDeliveryVehicleTypesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteDeliveryVehicleTypesMutation,
    IDeleteDeliveryVehicleTypesMutationVariables
  >(DeleteDeliveryVehicleTypesDocument, options)
}
export type DeleteDeliveryVehicleTypesMutationHookResult = ReturnType<
  typeof useDeleteDeliveryVehicleTypesMutation
>
export type DeleteDeliveryVehicleTypesMutationResult =
  Apollo.MutationResult<IDeleteDeliveryVehicleTypesMutation>
export type DeleteDeliveryVehicleTypesMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteDeliveryVehicleTypesMutation,
    IDeleteDeliveryVehicleTypesMutationVariables
  >
