import { action, observable } from 'mobx'

import MobXMap from '~/client/src/shared/types/MobXMap'

import { DeliveryTreeNodeTypes } from '../../enums/DeliveryTreeNodeTypes'
import EventsStore from '../EventStore/Events.store'
import ProjectDateStore from './ProjectDate.store'

const { ZONE_CONTAINER, BUILDING_CONTAINER, GATE_CONTAINER } =
  DeliveryTreeNodeTypes

export default class CompactDeliveryListStore {
  @observable public expandType: DeliveryTreeNodeTypes = null
  @observable public shouldExpandAllNodes: boolean = true
  public defaultStateMap = {
    [BUILDING_CONTAINER]: false,
    [GATE_CONTAINER]: false,
    [ZONE_CONTAINER]: false,
  }
  public nodesState = new MobXMap<boolean>()

  public constructor(
    protected readonly eventsStore: EventsStore,
    protected readonly projectDateStore: ProjectDateStore,
  ) {}

  public setExpandType = (expandType: DeliveryTreeNodeTypes) => {
    this.expandType = expandType
  }

  public isNodeExpanded = (nodeId: string, nodeType: DeliveryTreeNodeTypes) => {
    if (this.shouldExpandAllNodes) {
      return true
    }
    switch (this.expandType) {
      case BUILDING_CONTAINER:
        return nodeType === BUILDING_CONTAINER
      case ZONE_CONTAINER:
        return nodeType === BUILDING_CONTAINER || nodeType === ZONE_CONTAINER
      case GATE_CONTAINER:
        return (
          nodeType === GATE_CONTAINER ||
          nodeType === BUILDING_CONTAINER ||
          nodeType === ZONE_CONTAINER
        )
      default:
        break
    }

    const userInteraction = this.nodesState.get(nodeId)
    return userInteraction !== undefined
      ? userInteraction
      : this.defaultStateMap[nodeType]
  }

  @action.bound
  public resetForceCollapse() {
    this.expandType = null
    this.shouldExpandAllNodes = false
  }
}
