import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import Keys from '~/client/src/shared/enums/Keys'
import User from '~/client/src/shared/models/User'

import './MemberSearchList.scss'

interface IProps {
  value: string
  membersList: User[]
  appliedMembers: User[]
  onClear: () => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onApply: (member: User) => void
  onCancel: (member: User) => void
}

const navigationKeys = new Set<string>([
  Keys.ArrowDown,
  Keys.ArrowUp,
  Keys.Enter,
])

const searchUsers = 'Search Users'

@observer
export default class MemberSearchList extends React.Component<IProps> {
  @observable private pointer = 0

  public render() {
    const { value, onClear } = this.props

    return (
      <>
        {this.renderAppliedMembers()}
        <div className="form-setup-search-input">
          <StruxhubInput
            label={searchUsers}
            isRequiredTextHidden={true}
            value={value}
            onChange={this.handleChange}
            onValueReset={onClear}
            onKeyDown={this.handleKeyDown}
          />
        </div>

        {this.renderSearchResult()}
      </>
    )
  }

  private renderSearchResult() {
    const { membersList } = this.props

    if (!membersList?.length) {
      return null
    }

    return (
      <div
        className="form-setup-search-input-result ba-light-grey bg-palette-brand-lightest"
        onKeyDown={this.handleKeyDown}
        tabIndex={0}
      >
        {membersList.map((user, index) => {
          return (
            <div
              key={index}
              className={classList({
                'result-row row y-center pa10 pointer': true,
                current: index === this.pointer,
              })}
              onClick={this.handleApply.bind(this, user)}
              onMouseOver={this.handleMouseOver.bind(this, index)}
            >
              <UserProfilePreview user={user} />
            </div>
          )
        })}
      </div>
    )
  }

  private renderAppliedMembers() {
    const { appliedMembers, onCancel } = this.props

    if (!appliedMembers?.length) {
      return
    }

    return (
      <div className="form-setup-result-list">
        {appliedMembers.map((member, index) => {
          return (
            <div className="result-row row pt7 mx10" key={index}>
              <UserProfilePreview user={member} />
              <Icon
                className="bg-grey-scale-light br-rounded pointer text white ml10"
                icon={IconNames.SMALL_CROSS}
                onClick={onCancel.bind(this, member)}
              />
            </div>
          )
        })}
      </div>
    )
  }

  private handleApply = (user: User) => {
    this.props.onApply(user)
  }

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props

    onChange(event)
    this.pointer = 0
  }

  private handleKeyDown = (event: React.KeyboardEvent) => {
    if (!navigationKeys.has(event?.key)) {
      return
    }

    event?.preventDefault()

    const { membersList } = this.props

    switch (event?.key) {
      case Keys.ArrowUp:
        if (this.pointer > 0) {
          this.pointer--
        }
        break
      case Keys.ArrowDown:
        if (this.pointer < membersList.length - 1) {
          this.pointer++
        }
        break
      case Keys.Enter:
        this.handleApply(membersList[this.pointer])
    }
  }

  private handleMouseOver = (index: number) => {
    this.pointer = index
  }
}
