import { action, computed } from 'mobx'

import { ProjectAccessType } from '~/client/graph'
import { projectAccessTypesList } from '~/client/src/shared/constants/ProjectRoles'
import { InviteStatusCaption } from '~/client/src/shared/enums/InviteStatusCaption'
import MemberFilterType, {
  memberFilterTypes,
} from '~/client/src/shared/enums/MemberFilterType'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import SuperFilterStore from '~/client/src/shared/stores/ui/SuperFilter.store'

import BaseMemberFilterStore from '../../components/MemberFilters/BaseMemberFilter.store'
import DesktopEventStore from '../EventStore/DesktopEvents.store'

export class MemberFiltersStore {
  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly getFilteredCollectionExcludeFilter?: (
      excludedFilters?: string[],
    ) => User[],
  ) {}

  public get filterTypes(): MemberFilterType[] {
    return memberFilterTypes
  }

  private getDefaultSourceMapByType(type: MemberFilterType) {
    let sourceList: string[] = []

    switch (type) {
      case MemberFilterType.AccountType:
        sourceList = projectAccessTypesList.filter(
          pat => pat !== ProjectAccessType.Owner,
        )
        break
      case MemberFilterType.InviteStatus:
        sourceList = Object.values(InviteStatusCaption).filter(
          ic =>
            ic !== InviteStatusCaption.Added &&
            ic !== InviteStatusCaption.NotAdded,
        )
    }

    return sourceList.reduce((acc, optionId) => {
      acc[optionId] = []
      return acc
    }, {})
  }

  public getOptionIdByFilterType(
    member: User,
    filterType: MemberFilterType,
  ): string {
    switch (filterType) {
      case MemberFilterType.AccountType:
        return UserProject.getAccessType(member, this.userProjectsStore)
      case MemberFilterType.InviteStatus:
        return UserProject.getInviteStatusCaption(
          member,
          this.userProjectsStore,
        )
    }
  }

  @computed
  private get sourceMapByFilterTypeMap(): {
    [filterType: string]: { [optionId: string]: string[] }
  } {
    const maps = this.filterTypes.reduce((acc, filterType) => {
      acc[filterType] = this.getDefaultSourceMapByType(filterType)
      return acc
    }, {})

    this.projectMembersStore.list.forEach(member => {
      this.filterTypes.forEach(filterType => {
        const optionId = this.getOptionIdByFilterType(member, filterType)
        maps[filterType][optionId]?.push(member.id)
      })
    })

    return maps
  }

  public get filterStoresByTypeMap(): {
    [filterType: string]: SuperFilterStore
  } {
    const map: { [filterType: string]: SuperFilterStore } = {}

    this.filterTypes.forEach(filterType => {
      map[filterType] = new BaseMemberFilterStore(
        filterType,
        this.eventsStore.appState,
        this.sourceMapByFilterTypeMap[filterType],
        this.getFilteredCollectionExcludeFilter,
        this.hideOtherModals.bind(null, filterType),
      )
    })

    return map
  }

  @action.bound
  public hideOtherModals(filterTypeForException?: MemberFilterType) {
    Object.values(this.filterStoresByTypeMap).forEach(store => {
      if (store.type !== filterTypeForException) {
        store.setIsShown(false)
      }
    })
  }
}
