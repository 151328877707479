import * as React from 'react'

import * as Icons from '~/client/src/shared/components/Icons'

import IBasicProps from '../../interfaces/IBasicProps'

import Colors from '~/client/src/shared/theme.module.scss'

interface IProps extends IBasicProps {
  isFromCD: boolean
  color?: string
}

export function DeliveryIcon({
  isFromCD: isFromConcreteDirect,
  color = Colors.primary20,
  className = 'row',
}: IProps) {
  return isFromConcreteDirect ? (
    <Icons.ConcreteDirect className={className} />
  ) : (
    <Icons.DeliveryFilled className={className} style={{ color }} />
  )
}
