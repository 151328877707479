import { observable } from 'mobx'

import { IGlobeViewRectangle, SitemapItemShapeType } from '~/client/graph'

import { areObjectsEqual } from '../../../utils/util'
import { GlobeViewShapeProperties } from './GlobeViewItemProperties'

const DEFAULT_IS_DISPLAYED = true
const DEFAULT_LINE_WIDTH = 3
const DEFAULT_FILL_OPACITY = 0.2

export const DEFAULT_RECT_WIDTH = 20
export const DEFAULT_RECT_HEIGHT = 20
export const DEFAULT_RECT_SIZE_REFERENCED = 80

export default class GlobeViewRectangleProperties
  implements IGlobeViewRectangle
{
  public type: SitemapItemShapeType = SitemapItemShapeType.Rectangle
  @observable public lineWidth: number
  @observable public fillColor: string
  @observable public fillOpacity: number
  @observable public lineColor: string
  @observable public isDisplayed: boolean

  public constructor(
    lineWidth?: number,
    lineColor?: string,
    fillColor?: string,
    fillOpacity?: number,
    isDisplayed?: boolean,
  ) {
    this.lineWidth = lineWidth || DEFAULT_LINE_WIDTH
    this.lineColor = lineColor
    this.fillColor = fillColor
    this.fillOpacity =
      typeof fillOpacity === 'number' ? fillOpacity : DEFAULT_FILL_OPACITY
    this.isDisplayed =
      typeof isDisplayed === 'boolean' ? isDisplayed : DEFAULT_IS_DISPLAYED
  }

  public isEqual(properties: GlobeViewShapeProperties) {
    return areObjectsEqual(this, properties)
  }

  public toggleIsDisplayed() {
    this.isDisplayed = !this.isDisplayed
  }

  public isValid(): boolean {
    return true
  }

  public setLineWidth(width: number) {
    this.lineWidth = width
  }

  public setLineColor(color: string) {
    this.lineColor = color
  }

  public setFillColor(color: string) {
    this.fillColor = color
  }

  public setFillOpacity(opacity: number) {
    this.fillOpacity = opacity
  }

  public isDisplayDataEqual(rect: IGlobeViewRectangle) {
    return (
      this.isDisplayed ===
        (typeof rect.isDisplayed === 'boolean'
          ? rect.isDisplayed
          : DEFAULT_IS_DISPLAYED) &&
      this.type === rect.type &&
      this.lineWidth === rect.lineWidth &&
      this.lineColor === rect.lineColor &&
      this.fillColor === rect.fillColor &&
      this.fillOpacity === rect.fillOpacity
    )
  }

  public getDisplayData(): IGlobeViewRectangle {
    return {
      type: this.type,
      lineWidth: this.lineWidth,
      lineColor: this.lineColor,
      fillColor: this.fillColor,
      fillOpacity: this.fillOpacity,
      isDisplayed: this.isDisplayed,
    }
  }

  public copy(): GlobeViewRectangleProperties {
    return new GlobeViewRectangleProperties(
      this.lineWidth,
      this.lineColor,
      this.fillColor,
      this.fillOpacity,
      this.isDisplayed,
    )
  }
}
