import { DeliveryFilterType } from '~/client/graph'
import DeliveryGroupingOption from '~/client/src/shared/enums/DeliveryGroupingOption'

import FieldIds from '../../../shared/enums/DeliveryFieldIds'
import FilterInfo from '../../../shared/stores/substates/FilterInfo'

export default class DeliveryFiltersSettingState {
  public filterInfoMap: { [key: string]: FilterInfo } = {
    [DeliveryFilterType.Zone]: new FilterInfo(DeliveryFilterType.Zone, false),
    [DeliveryFilterType.Route]: new FilterInfo(DeliveryFilterType.Route, false),
    [DeliveryFilterType.Building]: new FilterInfo(
      DeliveryFilterType.Building,
      false,
    ),
    [DeliveryFilterType.Company]: new FilterInfo(
      DeliveryFilterType.Company,
      false,
    ),
    [DeliveryFilterType.Status]: new FilterInfo(
      DeliveryFilterType.Status,
      false,
    ),
    [DeliveryFilterType.Gate]: new FilterInfo(DeliveryFilterType.Gate, false),
    [DeliveryFilterType.Equipment]: new FilterInfo(
      DeliveryFilterType.Equipment,
      false,
    ),
    [DeliveryFilterType.Level]: new FilterInfo(DeliveryFilterType.Level, false),
    [DeliveryFilterType.Area]: new FilterInfo(DeliveryFilterType.Area, false),
  }

  public desktopFilterMap: { [key: string]: FilterInfo } = {
    [DeliveryGroupingOption[FieldIds.COMPANY]]: new FilterInfo(
      DeliveryGroupingOption[FieldIds.COMPANY],
      false,
    ),
    [DeliveryGroupingOption[FieldIds.STATUS]]: new FilterInfo(
      DeliveryGroupingOption[FieldIds.STATUS],
      false,
    ),
    [DeliveryGroupingOption[FieldIds.DATE]]: new FilterInfo(
      DeliveryGroupingOption[FieldIds.DATE],
      false,
    ),

    [DeliveryGroupingOption[FieldIds.BUILDING]]: new FilterInfo(
      DeliveryGroupingOption[FieldIds.BUILDING],
      false,
    ),
    [DeliveryGroupingOption[FieldIds.ZONE]]: new FilterInfo(
      DeliveryGroupingOption[FieldIds.ZONE],
      false,
    ),
    [DeliveryGroupingOption[FieldIds.LEVEL]]: new FilterInfo(
      DeliveryGroupingOption[FieldIds.LEVEL],
      false,
    ),
    [DeliveryGroupingOption[FieldIds.AREA]]: new FilterInfo(
      DeliveryGroupingOption[FieldIds.AREA],
      false,
    ),

    [DeliveryGroupingOption[FieldIds.GATE]]: new FilterInfo(
      DeliveryGroupingOption[FieldIds.GATE],
      false,
    ),
    [DeliveryGroupingOption[FieldIds.ROUTE]]: new FilterInfo(
      DeliveryGroupingOption[FieldIds.ROUTE],
      false,
    ),
    [DeliveryGroupingOption[FieldIds.OFFLOADING_EQUIPMENT]]: new FilterInfo(
      DeliveryGroupingOption[FieldIds.OFFLOADING_EQUIPMENT],
      false,
    ),
    [DeliveryGroupingOption[FieldIds.TRUCK_SIZE]]: new FilterInfo(
      DeliveryGroupingOption[FieldIds.TRUCK_SIZE],
      false,
    ),
    [DeliveryGroupingOption[FieldIds.MATERIAL]]: new FilterInfo(
      DeliveryGroupingOption[FieldIds.MATERIAL],
      false,
    ),

    [DeliveryGroupingOption[FieldIds.ON_SITE_CONTACTS]]: new FilterInfo(
      DeliveryGroupingOption[FieldIds.ON_SITE_CONTACTS],
      false,
    ),

    [DeliveryGroupingOption[FieldIds.VENDOR]]: new FilterInfo(
      DeliveryGroupingOption[FieldIds.VENDOR],
      false,
    ),

    [DeliveryGroupingOption[FieldIds.DURATION]]: new FilterInfo(
      DeliveryGroupingOption[FieldIds.DURATION],
      false,
    ),
  }
}
