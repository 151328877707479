import Localization from '../localization/LocalizationManager'

enum AnalyticsReportsType {
  FORM_REPORT = 'FormReport',
  SCAN_STATION_REPORT = 'ScanStationReport',
  DELIVERY_REPORT = 'DeliveryReport',
  MATERIAL_TRACKING_REPORT = 'MaterialTrackingReport',
}

export const analyticsReportsTypeLabel = {
  [AnalyticsReportsType.SCAN_STATION_REPORT]:
    Localization.translator.scanStationReport,
  [AnalyticsReportsType.DELIVERY_REPORT]:
    Localization.translator.deliveryReport,
  [AnalyticsReportsType.MATERIAL_TRACKING_REPORT]:
    Localization.translator.materialTrackingReport,
}

export default AnalyticsReportsType
