import * as React from 'react'

import { Classes, Dialog } from '@blueprintjs/core'
import { observer } from 'mobx-react'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import MaterialsUploadStore from '../MaterialsUpload.store'

// localization: translated

interface IProps {
  store: MaterialsUploadStore
  titleRenderer(title: string): JSX.Element
}

@observer
export default class ReplaceExistingMaterialsDialog extends React.Component<IProps> {
  public render() {
    const { store, titleRenderer } = this.props
    const {
      isReplaceDataDialogShown,
      resetInitialValues,
      uploadNewMaterialsExcel,
    } = store

    const bodyClassName = `${Classes.DIALOG_BODY} relative px16`
    const footerClassName = `${Classes.DIALOG_FOOTER} ma0 py10 px30 bt-light-input-border row x-center`

    return (
      <Dialog
        isOpen={isReplaceDataDialogShown}
        title={titleRenderer(Localization.translator.confirmDeletion)}
        isCloseButtonShown={false}
        canOutsideClickClose={false}
        className="bg-white ba-light-input-border pb5"
      >
        <div className={bodyClassName}>
          <div className="text large light">
            {Localization.translator.materialsUploadConfirm.replaceDescription}
          </div>
        </div>
        <div className={footerClassName}>
          <BaseActionButton
            className="gray-theme mx5"
            title={Localization.translator.cancel}
            isEnabled
            isGrow
            isAnimated
            onClick={resetInitialValues}
          />
          <BaseActionButton
            className="red-theme mx5"
            title={Localization.translator.delete}
            isEnabled
            isGrow
            isAnimated
            onClick={uploadNewMaterialsExcel}
          />
        </div>
      </Dialog>
    )
  }
}
