import * as turf from '@turf/turf'
import { computed, observable } from 'mobx'
import { LngLat } from 'react-map-gl'

import {
  IGeoJson2DGeographicCoordinates,
  ISitemapItemShapeCoordinates,
  SitemapItemShapeType,
} from '~/client/graph'

import { UNITS_KILOMETERS } from '../../MapBoxEditor/MapBoxViewer'

export default class CircleShapeCoordinates
  implements ISitemapItemShapeCoordinates
{
  public type: SitemapItemShapeType = SitemapItemShapeType.Circle
  @observable public coordinates: IGeoJson2DGeographicCoordinates[]
  @observable public isClosed: boolean
  @observable public divisionEndAngle: number = null
  @observable public divisionStartAngle: number = null

  public constructor(
    coordinates: IGeoJson2DGeographicCoordinates[],
    isClosed: boolean,
    divisionEndAngle?: number,
    divisionStartAngle?: number,
  ) {
    this.coordinates = coordinates
    this.isClosed = isClosed
    this.divisionEndAngle = divisionEndAngle
    this.divisionStartAngle = divisionStartAngle
  }

  public getBoundingBoxFeature(
    properties: GeoJSON.GeoJsonProperties,
  ): GeoJSON.Feature {
    return {
      type: 'Feature',
      properties,
      geometry: {
        type: 'Polygon',
        coordinates: [this.boundingBoxFeatureCoords],
      },
    }
  }

  public getBoundingBoxPointsFeatures(
    properties: GeoJSON.GeoJsonProperties,
  ): GeoJSON.Feature[] {
    return this.boundingBoxFeatureCoords.map((c, idx) => ({
      type: 'Feature',
      properties: {
        idx,
        ...properties,
      },
      geometry: {
        type: 'Point',
        coordinates: c,
      },
    }))
  }

  @computed
  private get boundingBoxFeatureCoords(): number[][] {
    let maxLng = this.shapeFeatureCoordinates[0][0]
    let maxLtd = this.shapeFeatureCoordinates[0][1]
    let minLng = this.shapeFeatureCoordinates[0][0]
    let minLtd = this.shapeFeatureCoordinates[0][1]
    this.shapeFeatureCoordinates.forEach(c => {
      if (c[0] > maxLng) {
        maxLng = c[0]
      }
      if (c[1] > maxLtd) {
        maxLtd = c[1]
      }
      if (c[0] < minLng) {
        minLng = c[0]
      }
      if (c[1] < minLtd) {
        minLtd = c[1]
      }
    })
    return [
      [minLng, maxLtd],
      [maxLng, maxLtd],
      [maxLng, minLtd],
      [minLng, minLtd],
      [minLng, maxLtd],
    ]
  }

  public getShapePolygonFeature(
    properties: GeoJSON.GeoJsonProperties,
  ): GeoJSON.Feature<GeoJSON.Polygon> {
    return {
      type: 'Feature',
      properties,
      geometry: {
        type: 'Polygon',
        coordinates: [this.shapeFeatureCoordinates],
      },
    }
  }

  public getShapeLineStringFeature(
    properties: GeoJSON.GeoJsonProperties,
  ): GeoJSON.Feature<GeoJSON.LineString> {
    return {
      type: 'Feature',
      properties,
      geometry: {
        type: 'LineString',
        coordinates: this.shapeFeatureCoordinates,
      },
    }
  }

  public moveShapePoint(idx: number, moveCoords: LngLat): void {
    if (this.isDivided) {
      const normPoint = [
        this.centerPoint.longitude,
        this.centerPoint.latitude - 0.01,
      ]
      let newAngle = turf.angle(
        [moveCoords.lng, moveCoords.lat],
        [this.centerPoint.longitude, this.centerPoint.latitude],
        normPoint,
      )
      if (newAngle > 180) {
        newAngle = newAngle - 360
      }
      switch (idx) {
        case 0:
          this.divisionStartAngle = newAngle
          break
        case 1:
          this.divisionEndAngle = newAngle
          break
      }
    } else {
      this.coordinates[1] = {
        longitude: moveCoords.lng,
        latitude: moveCoords.lat,
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public rotate(startPoint: LngLat, endPoint: LngLat): void {
    return
  }

  @computed
  public get centerPoint(): IGeoJson2DGeographicCoordinates {
    return this.coordinates[0]
  }

  @computed
  public get shapeFeatureCoordinates(): number[][] {
    const options = {
      units: UNITS_KILOMETERS,
      steps:
        this.divisionEndAngle !== null && this.divisionStartAngle !== null
          ? 360
          : 80,
      divisionEndAngle: this.divisionEndAngle,
      divisionStartAngle: this.divisionStartAngle,
    }

    return this.circle(
      [this.coordinates[0].longitude, this.coordinates[0].latitude],
      turf.distance(
        [this.coordinates[0].longitude, this.coordinates[0].latitude],
        [this.coordinates[1].longitude, this.coordinates[1].latitude],
        { units: UNITS_KILOMETERS },
      ) || 1,
      options,
    )
  }

  public getRadius(units: turf.Units = 'kilometers'): number {
    return turf.distance(
      [this.coordinates[0].longitude, this.coordinates[0].latitude],
      [this.coordinates[1].longitude, this.coordinates[1].latitude],
      { units },
    )
  }

  @computed
  public get shapeEditorPointCoordinates(): number[][] {
    if (this.isDivided) {
      const radius = this.getRadius()
      const startAnglePoint = turf.destination(
        [this.centerPoint.longitude, this.centerPoint.latitude],
        radius,
        this.divisionStartAngle + 180,
        { units: 'kilometers' },
      ).geometry.coordinates
      const endAnglePoint = turf.destination(
        [this.centerPoint.longitude, this.centerPoint.latitude],
        radius,
        this.divisionEndAngle + 180,
        { units: 'kilometers' },
      ).geometry.coordinates
      return [startAnglePoint, endAnglePoint]
    } else {
      return [
        this.shapeFeatureCoordinates.filter(
          c =>
            c[0] !== this.centerPoint.longitude &&
            c[1] !== this.centerPoint.latitude,
        )[0],
      ]
    }
  }

  @computed
  public get isDivided(): boolean {
    return !this.isClosed
  }

  private circle(
    center: number[],
    radius: number,
    options: {
      steps?: number
      units?: turf.Units
      divisionStartAngle?: number
      divisionEndAngle?: number
    } = {},
  ): number[][] {
    // default params
    const steps = options.steps || 64

    const divisionStartAngle =
      options?.divisionStartAngle !== null
        ? options.divisionStartAngle + 180
        : null
    const divisionEndAngle =
      options?.divisionEndAngle !== null
        ? options?.divisionEndAngle + 180
        : null
    // main

    const coordinates = []
    for (let i = 0; i < steps; i++) {
      const angle = (i * 360) / steps
      const newCoords = turf.destination(center, radius, angle, {
        units: options.units,
      }).geometry.coordinates
      if (divisionStartAngle !== null && divisionEndAngle !== null) {
        if (
          divisionStartAngle <= divisionEndAngle
            ? angle <= divisionEndAngle && angle >= divisionStartAngle
            : angle > divisionStartAngle || angle < divisionEndAngle
        ) {
          coordinates.push(newCoords)
        } else {
          if (
            !coordinates[coordinates.length - 1] ||
            coordinates[coordinates.length - 1][0] !==
              this.centerPoint.longitude ||
            coordinates[coordinates.length - 1][1] !== this.centerPoint.latitude
          ) {
            coordinates.push([
              this.centerPoint.longitude,
              this.centerPoint.latitude,
            ])
          }
        }
      } else {
        coordinates.push(newCoords)
      }
    }
    coordinates.push(coordinates[0])

    return coordinates
  }

  public copy(): CircleShapeCoordinates {
    return new CircleShapeCoordinates(
      this.coordinates,
      this.isClosed,
      this.divisionEndAngle,
      this.divisionStartAngle,
    )
  }
}
