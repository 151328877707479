import { observable } from 'mobx'

import { IDeliveryStatusChange, ISubscription } from '~/client/graph'
import {
  GetDeliveryStatusChangesListDocument,
  ListenToDeliveryStatusChangesDocument,
} from '~/client/graph/operations/generated/DeliveryStatusChange.generated'
import Delivery from '~/client/src/shared/models/Delivery'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'

import Guard from '../../utils/Guard'
import { NEW_DELIVERY_PATH_KEYWORD } from '../../utils/usefulStrings'
import * as e from '../EventStore/eventConstants'
import GraphExecutorStore from './GraphExecutor.store'

export default class DeliveryStatusChangesStore {
  public readonly deliveryStatusChangesSubscriptionId = 'deliveryStatusChanges'
  @observable public isDataReceived = false
  @observable public statusChanges: IDeliveryStatusChange[] = []

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly graphExecutorStore: GraphExecutorStore,
  ) {
    Guard.requireAll({
      eventsStore,
    })
  }

  public receiveList(
    list: IDeliveryStatusChange[],
    availableDeliveries?: Delivery[],
  ) {
    this.isDataReceived = false
    this.statusChanges = list
    if (availableDeliveries?.length) {
      this.updateLastStatus(availableDeliveries)
    }
    this.isDataReceived = true
  }

  public getStatusChangesForDelivery = (deliveryId: string) => {
    return this.statusChanges
      .filter(sc => sc.deliveryId === deliveryId)
      .sort((a, b) => a.createdAt - b.createdAt)
  }

  public loadDeliveriesStatusChanges = async (deliveryId?: string) => {
    if (!deliveryId || deliveryId === NEW_DELIVERY_PATH_KEYWORD) {
      return
    }

    const { loading, activeProject } = this.eventsStore.appState

    loading.set(e.LOAD_DELIVERY_STATUS_CHANGES, true)
    loading.set(`${e.LOAD_DELIVERY_STATUS_CHANGES}-${deliveryId}`, true)
    const { data } = await this.graphExecutorStore.query(
      GetDeliveryStatusChangesListDocument,
      {
        projectId: activeProject.id,
        deliveryId,
      },
    )

    loading.set(`${e.LOAD_DELIVERY_STATUS_CHANGES}-${deliveryId}`, false)
    loading.set(e.LOAD_DELIVERY_STATUS_CHANGES, false)
    this.receiveList(data.deliveryStatusChanges.data)
  }

  public listenToDeliveryStatusChanges = () => {
    const { activeProject } = this.eventsStore.appState
    this.graphExecutorStore.subscribe(
      ListenToDeliveryStatusChangesDocument,
      { projectId: activeProject.id },
      false,
      this.deliveryStatusChangesSubscriptionId,
      data => {
        const { deliveryStatusChange } = data as ISubscription

        if (!deliveryStatusChange) {
          return
        }

        this.receiveOne(deliveryStatusChange.id, deliveryStatusChange.item)
      },
    )
  }

  public receiveOne(id: string, dto: IDeliveryStatusChange) {
    if (dto) {
      this.statusChanges.push(dto)
    } else {
      this.statusChanges.filter(c => c.id !== id)
    }
  }

  public dontListenToDeliveryStatusChanges = () => {
    this.graphExecutorStore.terminateSubscription(
      this.deliveryStatusChangesSubscriptionId,
    )
  }

  private updateLastStatus(availableDeliveries: Delivery[]) {
    availableDeliveries.forEach(delivery => {
      const statusChanges = this.getStatusChangesForDelivery(delivery.id)
      if (!statusChanges?.length) {
        return
      }

      const lastStatus = statusChanges[statusChanges.length - 1].status
      delivery.status = lastStatus
    })
  }
}
