import { ContentObjectStatus, IContentObject } from '~/client/graph'

import Localization from '../../shared/localization/LocalizationManager'
import ContentObjectModel from './ContentObjectModel'

export enum CategoryOfVarianceTypes {
  NULL = 'NULL',
  CONTRACTS_OR_REVISIONS = 'CONTRACTS_OR_REVISIONS',
  PRIOR_WORK_NOT_COMPLETE = 'PRIOR_WORK_NOT_COMPLETE',
  INFORMATION_NOT_AVAILABLE = 'INFORMATION_NOT_AVAILABLE',
  UNCLEAR_TASK_DESCRIPTION = 'UNCLEAR_TASK_DESCRIPTION',
  STAFF_NOT_AVAILABLE = 'STAFF_NOT_AVAILABLE',
  MATERIALS_NOT_AVAILABLE = 'MATERIALS_NOT_AVAILABLE',
  CONDITIONS_OF_SATISFACTIONS = 'CONDITIONS_OF_SATISFACTIONS',
  TASK_SEQUENCE_CHANGE = 'TASK_SEQUENCE_CHANGE',
  INCORRECT_TIME_ESTIMATE = 'INCORRECT_TIME_ESTIMATE',
  OFF_PROJECT_DEMANDS = 'OFF_PROJECT_DEMANDS',
  UNFORESEEN_CONDITIONS = 'UNFORESEEN_CONDITIONS',
  WEATHER = 'WEATHER',
}

export default class CategoryOfVariance extends ContentObjectModel<CategoryOfVarianceTypes> {
  public static none: CategoryOfVariance
  public static CreateNullCategoryOfVariance(): CategoryOfVariance {
    const f = new CategoryOfVariance('')
    f.author = ''
    f.status = ContentObjectStatus.Open
    f.threadId = ''

    return f
  }

  public static getCategoryOfVarianceTypeCaption(
    type: CategoryOfVarianceTypes,
  ): string {
    const caption =
      Localization.translator.categoryOfVariance +
      ': ' +
      CategoryOfVariance.getDisplayType(type)
    return caption.toUpperCase()
  }

  public static getOpenedCaption(type: CategoryOfVarianceTypes): string {
    const caption =
      Localization.translator.categoryOfVarianceOpened +
      ': ' +
      CategoryOfVariance.getDisplayType(type)
    return caption.toUpperCase()
  }

  public static getDisplayType(type: CategoryOfVarianceTypes): string {
    switch (type) {
      case CategoryOfVarianceTypes.NULL:
        return Localization.translator.unspecifiedType
      case CategoryOfVarianceTypes.CONTRACTS_OR_REVISIONS:
        return Localization.translator.contractsOrRevisions
      case CategoryOfVarianceTypes.PRIOR_WORK_NOT_COMPLETE:
        return Localization.translator.priorWorkNotComplete
      case CategoryOfVarianceTypes.INFORMATION_NOT_AVAILABLE:
        return Localization.translator.informationNotAvailable
      case CategoryOfVarianceTypes.UNCLEAR_TASK_DESCRIPTION:
        return Localization.translator.unclearTaskDescription
      case CategoryOfVarianceTypes.STAFF_NOT_AVAILABLE:
        return Localization.translator.staffNotAvailable
      case CategoryOfVarianceTypes.MATERIALS_NOT_AVAILABLE:
        return Localization.translator.materialsNotAvailable
      case CategoryOfVarianceTypes.CONDITIONS_OF_SATISFACTIONS:
        return Localization.translator.conditionsOfSatisfactions
      case CategoryOfVarianceTypes.TASK_SEQUENCE_CHANGE:
        return Localization.translator.taskSequenceChange
      case CategoryOfVarianceTypes.INCORRECT_TIME_ESTIMATE:
        return Localization.translator.incorrectTimeEstimate
      case CategoryOfVarianceTypes.OFF_PROJECT_DEMANDS:
        return Localization.translator.offProjectDemands
      case CategoryOfVarianceTypes.UNFORESEEN_CONDITIONS:
        return Localization.translator.unforeseenConditions
      case CategoryOfVarianceTypes.WEATHER:
        return Localization.translator.weather
    }
  }

  public constructor(id: string, dto?: IContentObject) {
    super(id, dto)
    this.type = dto
      ? (dto.type as CategoryOfVarianceTypes)
      : CategoryOfVarianceTypes.NULL
  }
}

export const CategoryOfVarianceTypesList = [
  CategoryOfVarianceTypes.CONTRACTS_OR_REVISIONS,
  CategoryOfVarianceTypes.PRIOR_WORK_NOT_COMPLETE,
  CategoryOfVarianceTypes.INFORMATION_NOT_AVAILABLE,
  CategoryOfVarianceTypes.UNCLEAR_TASK_DESCRIPTION,
  CategoryOfVarianceTypes.STAFF_NOT_AVAILABLE,
  CategoryOfVarianceTypes.MATERIALS_NOT_AVAILABLE,
  CategoryOfVarianceTypes.CONDITIONS_OF_SATISFACTIONS,
  CategoryOfVarianceTypes.TASK_SEQUENCE_CHANGE,
  CategoryOfVarianceTypes.INCORRECT_TIME_ESTIMATE,
  CategoryOfVarianceTypes.OFF_PROJECT_DEMANDS,
  CategoryOfVarianceTypes.UNFORESEEN_CONDITIONS,
  CategoryOfVarianceTypes.WEATHER,
]

CategoryOfVariance.none = CategoryOfVariance.CreateNullCategoryOfVariance()
