import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { DeliveryFilterType } from '~/client/graph'
import CompactSuperFilter from '~/client/src/shared/components/Filters/CompactSuperFilter'
import SuperFilterHandleButton from '~/client/src/shared/components/Filters/SuperFilter/components/SuperFilterHandleButton/SuperFilterHandleButton'
import * as Icons from '~/client/src/shared/components/Icons'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'
import BaseDeliveryFilterStore from '~/client/src/shared/stores/BaseDeliveryFilter.store'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import DeliveryFilterStore from '~/client/src/shared/stores/ui/DeliveryFilter.store'
import { NOOP } from '~/client/src/shared/utils/noop'

import Checkbox from '../../../Checkbox'
import DeliveryStatusLabel from '../../../DeliveryStatusLabel/DeliveryStatusLabel'

import './CompactDeliveryFilter.scss'

const clearXFilters = 'Clear {x} filters'
interface IProps {
  store: DeliveryFilterStore
  onClose: () => void
  onApply: () => void
  isShown: boolean
  selectedOptionsCount: number
  isMyCompanyFilterActive: boolean
  toggleMyCompany: () => void
  toggleFollowing: () => void
  isFollowingFilterActive: boolean
  filteredCollection: Delivery[]
  eventsStore?: EventsStore
}

const STORE_TO_ICON_MAP = {
  [DeliveryFilterType.Locations]: (
    <Icons.Location className="no-grow mr10 item-icon" />
  ),
  [DeliveryFilterType.Company]: (
    <Icons.CompanyCompact className="no-grow mr10 item-icon" />
  ),
  [DeliveryFilterType.Status]: (
    <Icon icon={IconNames.TICK} className="no-grow mr10 item-icon" />
  ),
  [DeliveryFilterType.Gate]: (
    <TagIcon.Gate className="no-grow mr10 item-icon" />
  ),
  [DeliveryFilterType.Zone]: (
    <TagIcon.Zone className="no-grow mr10 item-icon" />
  ),
  [DeliveryFilterType.Building]: (
    <TagIcon.Building className="no-grow mr10 item-icon" />
  ),
  [DeliveryFilterType.Route]: (
    <TagIcon.Route className="no-grow mr10 item-icon" />
  ),
  [DeliveryFilterType.Level]: (
    <TagIcon.Level className="no-grow mr10 item-icon" />
  ),
  [DeliveryFilterType.Area]: (
    <TagIcon.Area className="no-grow mr10 item-icon" />
  ),
  [DeliveryFilterType.Followers]: (
    <Icons.User className="no-grow mr10 item-icon" />
  ),
  [DeliveryFilterType.Assigners]: (
    <Icons.User className="no-grow mr10 item-icon" />
  ),
  [DeliveryFilterType.Assignee]: (
    <Icons.User className="no-grow mr10 item-icon" />
  ),
}

@inject('eventsStore')
@observer
export default class CompactDeliveryFilter extends React.Component<IProps> {
  private readonly clearPostEventCallback: () => void = NOOP
  @observable private isOptionsShown: boolean = false
  public constructor(props: IProps) {
    super(props)

    Object.keys(this.storesMap).forEach(filterType => {
      const stores = this.storesMap[filterType]
      stores.forEach(store => {
        store.clickOnDeselectAll()
        store.clickOnApply()
      })
    })

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      props.store.onDeliveriesReceived,
    )
  }

  public componentWillUnmount() {
    this.clearPostEventCallback()
  }

  public render() {
    const { isShown, onClose } = this.props
    return (
      <>
        <div
          onClick={onClose}
          className={classList({
            dimmer: true,
            shown: isShown,
          })}
        />
        <div
          className={classList({
            'col filter-holder bb-light-grey': true,
            shown: isShown,
          })}
        >
          {this.renderHeader()}
          {this.renderResetButton()}
          <div className="scrollable">{this.renderContent()}</div>
          {this.renderAdditionalFilters()}
        </div>
      </>
    )
  }

  private renderResetButton() {
    const { selectedOptionsCount } = this.props

    if (!selectedOptionsCount || this.isOptionsShown) {
      return
    }

    return (
      <div
        className="filter-wrapper no-grow bb-light-grey bt-light-grey filter-handle-button-holder col y-center pl10 text large blue-highlight"
        onClick={this.resetFilters}
      >
        {clearXFilters.replace('{x}', selectedOptionsCount.toString())}
      </div>
    )
  }

  private renderHeader() {
    const { selectedOptionsCount, onClose } = this.props
    return (
      <div className="row pa10">
        <div className="col no-grow">
          {this.isOptionsShown ? (
            <Icon
              icon={IconNames.CHEVRON_LEFT}
              onClick={this.handleBackClick}
            />
          ) : (
            <Icons.Cross
              className="cross-icon no-grow row y-center"
              onClick={onClose}
            />
          )}
        </div>
        <div className="col x-center text large bold">{this.headerLabel}</div>
        <div
          className={classList({
            'col x-center text large bold no-grow compact-select-button px10 py5 brada4 mw-fit-content':
              true,
            'inactive-element': !selectedOptionsCount,
          })}
          onClick={this.applyAllFilters}
        >
          {`${Localization.translator.select} ${selectedOptionsCount || ''}`}
        </div>
      </div>
    )
  }

  private get headerLabel(): string {
    let headerLabel = Localization.translator.filters
    Object.keys(this.storesMap).find(filterType => {
      const stores = this.storesMap[filterType]
      if (stores[0].isShown) {
        headerLabel = `${Localization.translator.filterBy} ${filterType}`
        return
      }
    })

    return headerLabel
  }

  private renderContent() {
    return Object.keys(this.storesMap).map(filterType => {
      const stores = this.storesMap[filterType]
      const shouldRenderPopups = this.isOptionsShown && stores[0].isShown
      const selectedOptionsCount = stores.reduce((sum, store) => {
        const size = store?.selectedOptions?.size
        return size ? sum + size : sum
      }, 0)

      return (
        <React.Fragment key={filterType}>
          {!this.isOptionsShown && (
            <SuperFilterHandleButton
              filterStore={stores[0]}
              onToggle={this.onToggle.bind(this, stores)}
              className="no-grow bb-light-grey filter-handle-button-holder col y-center pr10"
            >
              {STORE_TO_ICON_MAP[filterType]}
              <span
                className={classList({
                  'no-grow text extra-large bold capitalize': true,
                  'w-fit-content mr-auto': !selectedOptionsCount,
                })}
              >
                {filterType}
              </span>
              {!!selectedOptionsCount && (
                <div className="ml10 no-grow text extra-large compact-select-button px5 brada4 w-fit-content mr-auto">
                  {selectedOptionsCount}
                </div>
              )}
              <Icon icon={IconNames.CHEVRON_RIGHT} iconSize={16} />
            </SuperFilterHandleButton>
          )}
          {shouldRenderPopups && this.renderPopups(stores, filterType)}
        </React.Fragment>
      )
    })
  }

  private renderAdditionalFilters() {
    const {
      isMyCompanyFilterActive,
      toggleMyCompany,
      toggleFollowing,
      isFollowingFilterActive,
    } = this.props
    const iconClassName = 'mx5 delivery-list-header-filter-icon no-grow'
    const filterClassName =
      'pl15 filter-wrapper no-grow bb-light-grey filter-handle-button-holder y-center row'

    if (this.isOptionsShown) {
      return
    }

    return (
      <>
        <div
          className={classList({
            [filterClassName]: true,
            'mt30 bt-light-grey': true,
            active: isFollowingFilterActive,
          })}
          onClick={toggleFollowing}
        >
          <Checkbox isChecked={isFollowingFilterActive} />
          <Icons.UnfilledStarInactive className={iconClassName} />
          {Localization.translator.following}
        </div>
        <div
          className={classList({
            [filterClassName]: true,
            active: isMyCompanyFilterActive,
          })}
          onClick={toggleMyCompany}
        >
          <Checkbox isChecked={isMyCompanyFilterActive} />
          <Icons.CompanyCompact className={iconClassName} />
          {Localization.translator.myCompany}
        </div>
      </>
    )
  }

  private renderPopups(stores: BaseDeliveryFilterStore[], filterType: string) {
    return (
      <CompactSuperFilter
        filterStores={stores}
        storeToIconMap={STORE_TO_ICON_MAP}
        getFilteredOptionsCount={this.getFilteredOptionsCount}
        shouldRenderHeader={stores.length > 1}
        optionFormatter={
          filterType === DeliveryFilterType.Status && this.optionFormatter
        }
      />
    )
  }

  private applyAllFilters = () => {
    if (this.isOptionsShown) {
      this.handleBackClick()
    }
    this.props.onClose()
  }

  private optionFormatter(option: string) {
    return (
      <DeliveryStatusLabel
        status={option as DeliveryStatus}
        className="no-grow"
      />
    )
  }

  private resetFilters = () => {
    Object.keys(this.storesMap).map(filterType => {
      const stores = this.storesMap[filterType]
      stores.forEach(store => {
        store.clickOnDeselectAll()
        store.clickOnApply()
      })
    })

    this.props.onClose()
    this.props.onApply()
  }

  private handleBackClick = () => {
    Object.keys(this.storesMap).map(filterType => {
      const stores = this.storesMap[filterType]
      stores.forEach(store => store.clickOnApply())
    })

    this.props.onApply()
    this.onToggle()
  }

  private onToggle = (stores?: BaseDeliveryFilterStore[]) => {
    this.isOptionsShown = !this.isOptionsShown
    if (stores?.length) {
      stores.forEach(store => store.toggle())
    }
  }

  private get storesMap() {
    return this.props.store.groupedFilterStoresByTypeMap
  }

  private getFilteredOptionsCount = (deliveriesIds: string[]): number => {
    return this.props.filteredCollection.filter(d =>
      deliveriesIds.includes(d.id),
    ).length
  }
}
