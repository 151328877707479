import { action, observable } from 'mobx'

import { CheckIsProjectCodeUniqueDocument } from '~/client/graph/operations/generated/Project.generated'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'

export default class ProjectCreationStore {
  @observable public isProjectCodeBeingChecked: boolean = false

  public constructor(private readonly graphExecutorStore: GraphExecutorStore) {}

  @action
  public async checkProjectCodeUniqueness(projectCode): Promise<boolean> {
    this.isProjectCodeBeingChecked = true

    try {
      // eslint-disable-next-line no-var
      var {
        data: { checkIsProjectCodeUnique: isUnique },
      } = await this.graphExecutorStore.query(
        CheckIsProjectCodeUniqueDocument,
        {
          projectCode,
        },
      )
    } finally {
      this.isProjectCodeBeingChecked = false
    }

    return isUnique
  }
}
