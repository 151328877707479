import { action, computed } from 'mobx'

import {
  ActivityStatus,
  DeliveryStatus,
  MonitoringStatus,
  SitePermitStatus,
} from '~/client/graph'
import { LogisticsFilterType } from '~/client/src/shared/enums/LogisticsFilterType'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { RESET_ALL_FILTERS } from '~/client/src/shared/stores/EventStore/eventConstants'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { LOCATION_SEPARATOR } from '~/client/src/shared/utils/usefulStrings'

import { UNASSIGNED_FILTER_OPTION } from '../components/Deliveries/DeliveriesView.store'
import { formatStatusToDisplay } from '../constants/DeliveryStatus'
import ClosureStatus from '../enums/ClosureStatus'
import { TagType } from '../enums/TagType'
import {
  getActivityStatusDisplayName,
  getClosureStatusDisplayName,
  getFormStatusDisplayName,
  getMonitoringStatusDisplayName,
} from '../localization/enumDisplayTexts'
import LocationBase from '../models/LocationObjects/LocationBase'
import { UNKNOWN_COMPANY_NAME, UNKNOWN_USER_NAME } from '../models/User'
import { UNASSIGNED } from '../utils/ZoneLevelLocationConstants'
import BaseLogisticsFilterStore, {
  ISourceMap,
} from './BaseLogisticsFilter.store'
import UIFilterInfo from './substates/UIFilterInfo'
import BaseLogisticsStore from './ui/BaseLogistics.store'

export default abstract class LogisticsFilterStore {
  public constructor(
    protected readonly eventsStore: EventsStore,
    protected readonly logisticsStore: BaseLogisticsStore,
    protected readonly onShowChanged: (
      isShown: boolean,
      filterType: string,
    ) => void,
    protected readonly locationAttributesStore: LocationAttributesStore,
    protected readonly permitTypesStore: PermitTypesStore,
    protected readonly tagsStore: TagsStore,
    protected readonly isPermitsOnly?: boolean,
  ) {}

  protected abstract get availableInstances(): any[]

  public abstract get filterStoresByTypeMap(): {
    [filterType: string]: BaseLogisticsFilterStore
  }

  protected abstract get sourceMapByFilterTypeMap(): {
    [filterType: string]: ISourceMap
  }

  @action.bound
  public resetAllFilters() {
    this.eventsStore.dispatch(RESET_ALL_FILTERS)
  }

  protected abstract getDefaultSourceMapByType(type: LogisticsFilterType)

  protected getOptionName = (option: string, filterType: string): string => {
    switch (filterType) {
      case LogisticsFilterType.Location:
      case LogisticsFilterType.Equipment:
        return this.getLocationOptionName(option)
      case LogisticsFilterType.Company:
        return this.getCompanyOptionName(option)
      case LogisticsFilterType.Type:
        return this.getTypeOptionName(option)
      case LogisticsFilterType.ResponsibleContact:
        return this.getUserOptionName(option)
      case LogisticsFilterType.Status:
        return this.getStatusOptionName(option)
      default:
        return option
    }
  }

  protected getLocationOptionName = (option: string): string => {
    const [attributeType, attributeId] = option.split(LOCATION_SEPARATOR)
    const store = this.tagsStore.tagStoreByTagTypeMap[attributeType]

    return store?.byId.get(attributeId)?.name || option
  }

  protected getTypeOptionName = (option: string): string => {
    const permitType = this.permitTypesStore.getLastUpdatedTypeByType(option)
    return permitType?.name || option
  }

  protected getUserOptionName = (option: string): string => {
    return (
      this.tagsStore.getUserTagInstanceById(option)?.name || UNKNOWN_USER_NAME
    )
  }

  protected getStatusOptionName = (option: string): string => {
    if (Object.values(SitePermitStatus).includes(option as SitePermitStatus)) {
      return getFormStatusDisplayName(option as SitePermitStatus)
    }
    if (Object.values(ActivityStatus).includes(option as ActivityStatus)) {
      return getActivityStatusDisplayName(option as ActivityStatus)
    }
    if (Object.values(DeliveryStatus).includes(option as DeliveryStatus)) {
      return formatStatusToDisplay(option)
    }
    if (Object.values(ClosureStatus).includes(option as ClosureStatus)) {
      return getClosureStatusDisplayName(option as ClosureStatus)
    }
    if (Object.values(MonitoringStatus).includes(option as MonitoringStatus)) {
      return getMonitoringStatusDisplayName(option as MonitoringStatus)
    }
    return UNASSIGNED
  }

  protected getCompanyOptionName = (option: string): string => {
    return (
      this.tagsStore.getTag(TagType.Company, option)?.name ||
      UNKNOWN_COMPANY_NAME
    )
  }

  @action.bound
  public syncFilters() {
    Object.keys(this.fieldsMap).forEach(filterType => {
      const filter = this.fieldsMap[filterType]
      const sourceMap = this.sourceMapByFilterTypeMap[filterType]
      this.updateCommonFilterState(filter, sourceMap)
    })
  }

  @action.bound
  private updateCommonFilterState(filter: UIFilterInfo, sourceMap: ISourceMap) {
    let appliedFilterOptions = []

    filter.initialFilterOptions.forEach((_, optionKey) => {
      const deliveriesIds = sourceMap[optionKey]

      filter.selectedFilterOptions.set(optionKey, deliveriesIds)
      filter.initialFilterOptions.set(optionKey, deliveriesIds)

      appliedFilterOptions = appliedFilterOptions.concat(deliveriesIds)
    })

    filter.appliedFilterOptions = appliedFilterOptions
  }

  protected abstract fieldsMap()

  protected getLocationValues = (locations: LocationBase[]): string[] => {
    return locations?.length
      ? locations.map(l => `${l.type}${LOCATION_SEPARATOR}${l.id}`)
      : [UNASSIGNED_FILTER_OPTION]
  }

  @computed
  public get selectedOptionsCount(): number {
    return Object.values(this.filterStoresByTypeMap)
      .map(value => value.selectedOptions.size)
      .reduce((a, c) => a + c)
  }
}
