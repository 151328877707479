import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList, toggleClass } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

import DeliveryControlTypes from '../../../enums/DeliveryControlTypes'
import DeliveryDetailsSections, {
  DeliveryDetailsSectionsInfo,
  DeliveryDetailsSectionsList,
} from '../../../enums/DeliveryDetailsSections'
import FieldIds from '../../../enums/DeliveryFieldIds'
import Keys from '../../../enums/Keys'
import Localization from '../../../localization/LocalizationManager'
import IDeliveryControl from '../../../models/IDeliveryControl'
import InitialState from '../../../stores/InitialState'
import ActivitiesStore, {
  getActivityLoadEventName,
} from '../../../stores/domain/Activities.store'
import {
  getFormattedPhoneNumberForSubmission,
  isPhoneNumber,
} from '../../../utils/phoneNumberHelpers'
import { isPrimitive } from '../../../utils/util'
import FileInputBase from '../../FileInput/FileInput'
import DeliveryDetailsStore, {
  MAX_MULTI_VALUE_COUNT,
} from '../DeliveryDetails.store'
import DeliveryFormFieldWrapper from './DeliveryFormFieldWrapper/DeliveryFormFieldWrapper'
import DeliveryMaterialControls from './DeliveryMaterialControls/DeliveryMaterialControls'
import DeliveryRecurringOptions from './DeliveryRecurringOptions/DeliveryRecurringOptions'

interface IProps {
  formId: string
  FileInputType: typeof FileInputBase
  store: DeliveryDetailsStore
  shouldDisableInnerForm: boolean
  state?: InitialState
  activitiesStore?: ActivitiesStore
}

type RenderFieldProps = {
  index: number | undefined
  value: any
  key: string
  isLabelHidden: boolean
  isHelperTextCondensed?: boolean
  additionalHelperText?: string
  isValid?: boolean
  validationMessage?: string
}

@inject('state', 'activitiesStore')
@observer
export default class DeliveryForm extends React.Component<IProps> {
  @observable private lastDirtyField: string = ''
  @observable private lastBlurField: string = ''
  private multiValueAddFieldRefs = new Map<
    string,
    React.RefObject<HTMLDivElement>
  >()
  private blurTimeout: { event: NodeJS.Timeout; fieldId: FieldIds }

  public componentDidMount() {
    this.requestLinkedActivity()
  }

  public componentDidUpdate() {
    const { store } = this.props
    if (
      !!store.selectedField ||
      (!!this.lastDirtyField &&
        !!this.lastBlurField &&
        this.lastDirtyField === this.lastBlurField)
    ) {
      const currentFocus = document.activeElement
      switch (currentFocus.tagName) {
        // Do not disrupt the user if they are going to another field
        case 'INPUT':
        case 'TEXTAREA':
        case 'SELECT':
        case 'BUTTON':
        case 'A':
          // Unless they are going to the next field
          if (store.selectedField && currentFocus.id !== store.selectedField.id)
            return

        // Focus on the add field if it is visible
        // eslint-disable-next-line no-fallthrough
        default:
          const ref = this.multiValueAddFieldRefs.get(
            store.selectedField?.id || this.lastDirtyField,
          )
          if (ref?.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' })
            ref.current.querySelector('input')?.focus()
            if (!store.selectedField) {
              this.lastDirtyField = this.lastBlurField = ''
            }
          }
      }
    }

    this.requestLinkedActivity()
  }

  public render() {
    const { formId, shouldDisableInnerForm } = this.props
    return (
      <form
        id={formId}
        className={toggleClass(
          'unclickable-element opacity7',
          shouldDisableInnerForm,
        )}
      >
        {DeliveryDetailsSectionsList.map((section, index) =>
          this.renderSection(section, index),
        )}
      </form>
    )
  }

  private renderSection(section: DeliveryDetailsSections, index: number) {
    const { fields, getFieldName } = this.props.store

    const { getCustomNameId, getTitle } = DeliveryDetailsSectionsInfo[section]
    const sectionTitle = getCustomNameId
      ? getFieldName(getCustomNameId())
      : getTitle()
    const sectionFields = fields.filter(field => field.section === section)
    const className =
      section === DeliveryDetailsSections.SUPPLIER
        ? 'supplier-section relative'
        : ''

    if (!sectionFields.length) {
      return
    }

    return (
      <div key={index} className={`px8 ${className}`}>
        {!!sectionTitle && (
          <div className="row section-title">
            <div className="bt-light-grey" />
            <div className="no-grow text lp15 uppercase light no-white-space-wrap bold-font py4 px16">
              {sectionTitle}
            </div>
            <div className="bt-light-grey" />
          </div>
        )}
        {this.renderControls(section, sectionFields)}
      </div>
    )
  }

  private renderControls = (
    section: DeliveryDetailsSections,
    sectionFields: IDeliveryControl[],
  ): JSX.Element | JSX.Element[] => {
    const { FileInputType, store } = this.props
    const {
      isInspectionRequired,
      shouldShowUnscheduledControl,
      onFieldValueChange,
      setSelectedFieldId,
    } = store

    const isMaterialSection = section === DeliveryDetailsSections.MATERIALS

    if (isMaterialSection) {
      return (
        <DeliveryMaterialControls
          FileInputType={FileInputType}
          store={store}
          materialFields={sectionFields}
        />
      )
    }

    return sectionFields.map((field, keyIndex) => {
      if (field.id === FieldIds.INSPECTOR && !isInspectionRequired) {
        return
      }

      if (
        field.id === FieldIds.UNSCHEDULED_REQUEST &&
        !shouldShowUnscheduledControl
      ) {
        return
      }

      if (field.type === DeliveryControlTypes.LABEL && !field.value) {
        return
      }

      if (field.id === FieldIds.RECURRING_OPTIONS) {
        return (
          <DeliveryRecurringOptions
            key={field.id}
            {...field}
            onChange={onFieldValueChange}
            store={store}
          />
        )
      }

      if (field.isMultiValueInput && Array.isArray(field.value)) {
        return this.renderMultiValueControls(field, keyIndex)
      }

      return (
        <DeliveryFormFieldWrapper
          key={keyIndex}
          {...field}
          onChange={onFieldValueChange}
          onSelectClick={setSelectedFieldId}
          FileInputType={FileInputType}
          deliveryDetailsStore={store}
        />
      )
    })
  }

  private renderMultiValueControls(
    field: IDeliveryControl,
    fieldIndex: number,
  ) {
    const { FileInputType, store } = this.props
    const {
      setSelectedFieldId,
      clearSelectedField,
      onFieldValueChange,
      onMultivalueFieldBlur,
      isFieldValid,
      splitValues,
      initialArrayValues,
      displayedDelivery,
      selectedField,
    } = store

    const initialValues = new Set(initialArrayValues.get(field.id))

    const isChanged = (value: unknown, index: number) => {
      if (!displayedDelivery) return false

      if (isPrimitive(value)) return index !== -1 && !initialValues.has(value)

      if (isPhoneNumber(value)) {
        const formattedPhoneNumber = getFormattedPhoneNumberForSubmission(
          value.phoneNumber,
        )
        return index !== -1 && !initialValues.has(formattedPhoneNumber)
      }

      return false
    }

    const isAddButtonEnabled =
      isFieldValid(field.id, field.value[-1]) ||
      (!!field.value[-1] && splitValues(field.id, field.value[-1]).length >= 1)

    const handleChange = (id: FieldIds, value: any, index?: number) => {
      this.lastDirtyField = id
      onFieldValueChange(id, value, index)
    }

    // Sets a timeout to let a focus on another field get there first
    const handleBlur = (id: FieldIds, value: any, index?: number) => {
      const fieldValuesBeforeBlur = field.value.length
      onMultivalueFieldBlur(id, value, index)
      const fieldValuesAfterBlur = field.value.length
      if (fieldValuesAfterBlur <= fieldValuesBeforeBlur) clearSelectedField()
      this.blurTimeout = {
        event: setTimeout(() => {
          this.lastBlurField = id
        }),
        fieldId: id,
      }
    }

    const handleFocus = (index: number) => {
      // Clear the blur timeout so that the field doesn't scroll into view
      // Let the blur event fire if it was in another field
      if (this.blurTimeout?.fieldId === field.id) {
        clearTimeout(this.blurTimeout.event)
        delete this.blurTimeout
      }
      this.lastBlurField = this.lastDirtyField = ''
      setSelectedFieldId(field.id, index)
    }

    // Sets a timeout to let the regular onChange event fire first
    const handlePaste = (id: FieldIds, value: any, index?: number) => {
      setTimeout(() => {
        this.lastDirtyField = this.lastBlurField = id
        onMultivalueFieldBlur(id, value, index)
      })
    }

    const handleRemoveClick = (event, entryIndex) => {
      event.preventDefault()
      handleChange(field.id, undefined, entryIndex)
    }

    const handleSelectFieldClick = (event, entryIndex) => {
      this.lastBlurField = this.lastDirtyField = ''
      setSelectedFieldId(field.id, entryIndex)
    }

    const isMultipleEquipmentAllowedField =
      field.id !== FieldIds.OFFLOADING_EQUIPMENT ||
      this.props.store.isMultipleEquipmentAllowed

    const handleAddClick = event => {
      event.preventDefault()
      switch (field.id) {
        case FieldIds.ON_SITE_CONTACTS:
        case FieldIds.OFFLOADING_EQUIPMENT:
          handleSelectFieldClick(event, field.id)
          break
        case FieldIds.VENDOR_EMAILS:
        case FieldIds.DRIVER_PHONE_NUMBERS:
          this.lastDirtyField = field.id
          handleBlur(field.id, field.value[-1], undefined)
      }
    }

    const canAddButtonBeDisplayed =
      !field.isReadOnly &&
      field.value.length > 0 &&
      field.value.length < MAX_MULTI_VALUE_COUNT &&
      isMultipleEquipmentAllowedField

    const shouldAddFieldBeDisplayed = () => {
      // If there are no values, show one empty field
      if (field.value.length === 0) return true

      // If this is the currently selected field and the index is -1 (the temp field) or null (the only field), show it
      if (
        selectedField &&
        field.id === selectedField.id &&
        (selectedField.index === -1 || selectedField.index === null)
      )
        return true

      // If the temp field is left with some (probably invalid) value, show it
      if (field.value[-1]) return true

      return false
    }

    const ref = React.createRef<HTMLDivElement>()
    this.multiValueAddFieldRefs.set(field.id, ref)

    const renderField = ({
      index,
      value,
      key,
      isLabelHidden,
      isHelperTextCondensed = false,
      additionalHelperText,
      isValid,
      validationMessage,
    }: RenderFieldProps) => (
      <DeliveryFormFieldWrapper
        {...field}
        isChanged={isChanged(value, index)}
        key={key}
        isValid={isValid ?? isFieldValid(field.id, value)} // Use the local isValid if provided
        validationMessage={validationMessage ?? field.validationMessage} // Use the local validationMessage if provided
        value={value || ''}
        isLabelHidden={isLabelHidden}
        isHelperTextCondensed={isHelperTextCondensed}
        onChange={(id, val, idx) =>
          // Use the local index if the event doesn't provide one
          handleChange(id, val, idx ?? index)
        }
        onBlur={() => {
          handleBlur(field.id, value, index)
        }}
        onKeyDown={event => {
          if (event.key === Keys.Enter) {
            handleBlur(field.id, value, index)
          }
        }}
        onPaste={event =>
          handlePaste(
            field.id,
            event.clipboardData.getData('text') || '',
            index,
          )
        }
        onFocus={() => handleFocus(index)}
        onSelectClick={event => handleSelectFieldClick(event, index)}
        FileInputType={FileInputType}
        additionalHelperText={
          additionalHelperText || field.additionalHelperText
        }
      />
    )

    const isCurrentValueRepeated =
      DeliveryDetailsStore.isMultiFieldValuePresent(
        field.value,
        field.value[-1],
      )

    return (
      <div key={`${field.id}.${fieldIndex}`} className="multi-value-input">
        {field.value.length > 0 &&
          field.value.map((entry, entryIndex) => {
            return (
              <div
                className="row full-width"
                key={`${field.id}.${fieldIndex}[${entryIndex}]`}
              >
                <div
                  className={classList({
                    'col relative': true,
                    'overflow-hidden': field.type !== DeliveryControlTypes.TEL,
                    'multi-phone-number-input':
                      field.type === DeliveryControlTypes.TEL,
                  })}
                >
                  {renderField({
                    index: entryIndex,
                    value: entry,
                    key: `${field.id}.${fieldIndex}[${entryIndex}]`,
                    isLabelHidden: entryIndex > 0,
                    isHelperTextCondensed: field.value.length !== 0,
                  })}
                </div>
                {!field.isReadOnly && isMultipleEquipmentAllowedField && (
                  <div className="no-grow">
                    <button
                      onClick={event => handleRemoveClick(event, entryIndex)}
                      className={classList({
                        'no-background no-border-color no-outline pointer w32':
                          true,
                        mt5: !!entryIndex,
                        mt25: !entryIndex,
                      })}
                    >
                      <Icons.CrossGrey />
                    </button>
                  </div>
                )}
              </div>
            )
          })}
        {/* One more field to take new entries */}
        {(shouldAddFieldBeDisplayed() && (
          <div
            className="row full-width mb15"
            ref={this.multiValueAddFieldRefs.get(field.id)}
          >
            <div
              className={classList({
                'col relative full-width': true,
                'multi-phone-number-input':
                  field.type === DeliveryControlTypes.TEL &&
                  (!!field.value[-1] || store.selectedField?.id === field.id),
              })}
            >
              {renderField({
                index: -1,
                value: field.value[-1],
                key: `${field.id}.${fieldIndex}+${field.value.length}`,
                isLabelHidden: field.value.length > 0,
                isHelperTextCondensed: true,
                additionalHelperText:
                  store.selectedField?.id === field.id &&
                  (field.type === DeliveryControlTypes.TEL
                    ? Localization.translator.multipleEntries.paste
                    : Localization.translator.multipleEntries.typeOrPaste),
                isValid: isCurrentValueRepeated ? false : undefined,
                validationMessage: isCurrentValueRepeated
                  ? Localization.translator.multipleEntries.repeatedValue
                  : undefined,
              })}
            </div>
            <div className="no-grow">
              {(field.value[-1] && (
                <button
                  disabled={!isAddButtonEnabled}
                  onClick={handleAddClick}
                  className={classList({
                    'ba-light-blue blue-highlight brada4 pa5 pointer text mb20 w32':
                      true,
                    mt20: field.value.length === 0,
                    pointer: isAddButtonEnabled,
                    'inactive-element': !isAddButtonEnabled,
                  })}
                >
                  Ok
                </button>
              )) ||
                (store.selectedField?.id === field.id && (
                  <button
                    className={classList({
                      'no-background no-border-color no-outline pointer w32':
                        true,
                      mb10: !!field.value.length,
                      mt10: !field.value.length,
                    })}
                  >
                    <Icons.CrossGrey />
                  </button>
                ))}
            </div>
          </div>
        )) ||
          (canAddButtonBeDisplayed && (
            <div className="row nowrap x-end no-select px16">
              <div className="mw300 text end ellipsis no-grow px10 py6 mb10 mr20">
                <a
                  onClick={() => {
                    setSelectedFieldId(field.id, -1)
                  }}
                  className="text primary underline"
                >
                  +{' '}
                  {Localization.translator.multipleEntries.add_x(
                    field.defaultHint || field.hints[0],
                  )}
                </a>
              </div>
            </div>
          ))}
      </div>
    )
  }

  @action
  private requestLinkedActivity() {
    const { state, store, activitiesStore } = this.props

    const activityCode = store.displayedDelivery?.activityId
    const isFieldHidden = !!state.delivery.hiddenFields[FieldIds.ACTIVITY_ID]

    if (!activityCode || !state.activeScheduleId || isFieldHidden) return

    const isActivityBeingLoaded = state.loading.get(
      getActivityLoadEventName(activityCode),
    )

    if (isActivityBeingLoaded || activitiesStore.byId.get(activityCode)) return

    activitiesStore.requestActivityByCode(state.activeScheduleId, activityCode)
  }
}
