import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { Loader } from '~/client/src/shared/components/Loader'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import InitialState from '~/client/src/shared/stores/InitialState'

export interface IProps {
  value: number
  targetValue?: number
  minValue?: number
  maxValue?: number
  step?: number
  didUpdate?: boolean
  didStart?: boolean
  didFinish?: boolean
  state?: InitialState
  openStatusMap: Map<string, boolean>
  loadingStatusMap: Map<string, boolean>
  valueStatusMap: Map<string, number>
  activityKey: string
  leftCaption: React.ReactNode
  icon: React.ReactNode
  submitTitle?: React.ReactNode
  valueRender?: (value: number) => React.ReactNode
  onInputClosed?: () => void
  onInputValueChanged?: (value: number) => void
  onChange(newValue: number)
}

const VALUE_WIDTH = 40
const MAX_SCROLL_EVENTS_DIFFERENCE = 500
const CHECK_MARK_DISPLAY_DURATION_MS = 1000

@inject('state')
@observer
export default class StatusUpdateInputBase extends React.Component<IProps> {
  public static defaultProps = {
    minValue: 0,
    maxValue: 100,
    step: 1,
    valueRender: value => value,
  }
  @observable private scrollWidth: number = 0
  @observable private isUpdateDone: boolean = false

  private scrollContainer: HTMLDivElement
  private scrollTimer

  @computed
  private get value(): number {
    const { valueStatusMap, activityKey, value } = this.props
    const updatedValue = valueStatusMap.get(activityKey)
    return typeof updatedValue === 'number' && this.isOpened
      ? updatedValue
      : value
  }

  private set value(value: number) {
    const { valueStatusMap, activityKey, onInputValueChanged } = this.props
    valueStatusMap.set(activityKey, value)
    if (onInputValueChanged) {
      onInputValueChanged(value)
    }
  }

  private get isOpened(): boolean {
    const { openStatusMap, activityKey } = this.props
    return openStatusMap.get(activityKey)
  }

  private set isOpened(value: boolean) {
    const { openStatusMap, activityKey, onInputClosed } = this.props
    openStatusMap.set(activityKey, value)
    if (!value && onInputClosed) {
      onInputClosed()
    }
  }

  @computed
  private get isLoading(): boolean {
    const { loadingStatusMap, activityKey } = this.props
    return loadingStatusMap.get(activityKey)
  }

  private set isLoading(value: boolean) {
    const { loadingStatusMap, activityKey } = this.props
    loadingStatusMap.set(activityKey, value)
  }

  public componentDidMount() {
    if (this.scrollContainer) {
      this.scrollContainer.scrollLeft =
        VALUE_WIDTH * this.values.indexOf(this.value)
    }
  }

  public componentDidUpdate() {
    const { value, didUpdate } = this.props
    const isValueUpdated = value === this.value && this.isLoading && didUpdate
    const isValueCleared = this.value < 0 && !didUpdate
    if (isValueUpdated || isValueCleared) {
      this.isLoading = false
      this.isUpdateDone = true

      setTimeout(() => {
        this.isUpdateDone = false
        this.closeSelector()
      }, CHECK_MARK_DISPLAY_DURATION_MS)
    }
  }

  public render() {
    const {
      leftCaption,
      icon,
      targetValue,
      didUpdate,
      didStart,
      didFinish,
      valueRender,
    } = this.props
    return (
      <div
        className={classList({
          'row status-update-input': true,
          opened: this.isOpened,
        })}
      >
        <div
          className={classList({
            'status-update-input-triangle': true,
            gold: this.isOpened,
          })}
        />
        {!this.isOpened && (
          <div className="row py5 y-center" onClick={this.openSelector}>
            <div>{leftCaption}</div>
            <div
              className={classList({
                'status-update-input-value no-select displayed': true,
                selected: didUpdate || didFinish,
              })}
            >
              {(typeof didStart !== 'boolean' || didStart) && this.value}
            </div>
            <div className="row x-end">
              {!!targetValue && (
                <div className="col x-center pr5 no-grow">
                  <div className="status-update-input-target-value">
                    {targetValue}
                  </div>
                  <div className="status-update-input-target-dot" />
                </div>
              )}
            </div>
            <div className="no-grow">{icon}</div>
          </div>
        )}
        <div
          className="status-update-input-close-button"
          onClick={this.closeSelector}
        >
          <Icon icon={IconNames.SMALL_CROSS} />
        </div>
        <div
          className="status-update-input-apply-button"
          onClick={this.applyNewValue}
        >
          {this.buttonContent}
        </div>
        {this.isOpened && (
          <div className="row">
            <div className="status-update-input-values-container">
              <div
                className="status-update-input-scroll-container"
                ref={this.setScrollContainerRef}
                onScroll={this.onValuesScroll}
                style={{ width: this.scrollWidth }}
              >
                <div
                  className="inline-block"
                  style={{ width: this.scrollOffset }}
                />
                {this.values.map(value => (
                  <div
                    className={classList({
                      'status-update-input-value no-select': true,
                      'text-node': typeof valueRender(value) === 'string',
                      selected: value === this.value,
                      target: value === targetValue,
                    })}
                    onClick={this.onValueClicked.bind(this, value)}
                    key={value}
                  >
                    {valueRender(value)}
                    {value === targetValue && (
                      <div className="status-update-input-target-dot" />
                    )}
                  </div>
                ))}
                <div
                  className="inline-block"
                  style={{ width: this.scrollOffset }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  private get buttonContent() {
    if (this.isUpdateDone) {
      return <Icon className="fading-animation" icon={IconNames.SMALL_TICK} />
    }
    if (this.isLoading) {
      return <Loader className="fading-animation" size={20} />
    }
    return this.props.submitTitle || Localization.translator.ok
  }

  private get scrollOffset() {
    return (this.scrollWidth - VALUE_WIDTH) / 2
  }

  private setScrollContainerRef = (ref: HTMLDivElement) => {
    this.scrollContainer = ref
    if (ref) {
      this.scrollWidth = ref.parentElement.clientWidth
      ref.scrollLeft = VALUE_WIDTH * this.values.indexOf(this.value)
    }
  }

  private onValueClicked(value: number) {
    this.value = value
    if (!this.scrollContainer) {
      return
    }
    const valueIdx = this.values.indexOf(value)
    const nextPos = valueIdx * VALUE_WIDTH
    this.scrollContainer.scrollLeft = nextPos
  }

  private normalizeScrollValue() {
    if (!this.scrollContainer) {
      return
    }
    const scrollLeft = this.scrollContainer.scrollLeft
    const nextPos = Math.round(scrollLeft / VALUE_WIDTH) * VALUE_WIDTH
    this.scrollContainer.scrollLeft = nextPos
  }

  @action.bound
  private onValuesScroll() {
    if (this.scrollTimer) {
      clearTimeout(this.scrollTimer)
      this.scrollTimer = null
    }

    const scrollLeft = this.scrollContainer.scrollLeft
    const valueIdx = Math.round(scrollLeft / VALUE_WIDTH)
    const newValue = this.values[valueIdx]

    this.value = newValue

    this.scrollTimer = setTimeout(() => {
      this.normalizeScrollValue()
      this.scrollTimer = null
    }, MAX_SCROLL_EVENTS_DIFFERENCE)
  }

  private get values() {
    const { minValue, maxValue, step } = this.props
    if (minValue > maxValue) {
      return []
    }
    const result = []
    let value = minValue
    while (value <= maxValue) {
      result.push(value)
      value += step
    }
    return result
  }

  @action.bound
  private openSelector(event?: React.MouseEvent<HTMLDivElement>) {
    if (event) {
      event.stopPropagation()
    }
    this.isOpened = true
    this.isLoading = false
  }

  @action.bound
  private closeSelector(event?: React.MouseEvent<HTMLDivElement>) {
    if (event) {
      event.stopPropagation()
    }
    this.isOpened = false
    this.isLoading = false
    this.value = this.props.value
  }

  @action.bound
  private applyNewValue(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation()
    this.props.onChange(this.value)
    this.isLoading = true
  }
}
