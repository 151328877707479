import Colors from '~/client/src/shared/theme.module.scss'

export enum SitemapItemColorName {
  Orange = 'Orange',
  Purple = 'Purple',
  Dark_Green = 'Dark green',
  Pink = 'Pink',
  Coral = 'Coral',
  Violet = 'Violet',
  Light_Green = 'Light green',
  Dark_Orange = 'Dark orange',
  Turquoise = 'Turquoise',
  Slate = 'Slate',
}

export const SITEMAP_ITEMS_COLORS = [
  {
    name: SitemapItemColorName.Orange,
    color: Colors.secondary50,
  },
  {
    name: SitemapItemColorName.Purple,
    color: Colors.purple40,
  },
  {
    name: SitemapItemColorName.Dark_Green,
    color: Colors.green20,
  },
  {
    name: SitemapItemColorName.Pink,
    color: Colors.magenta50,
  },
  {
    name: SitemapItemColorName.Coral,
    color: Colors.error70,
  },
  {
    name: SitemapItemColorName.Violet,
    color: Colors.violet50,
  },
  {
    name: SitemapItemColorName.Light_Green,
    color: Colors.green60,
  },
  {
    name: SitemapItemColorName.Dark_Orange,
    color: Colors.secondary40,
  },
  {
    name: SitemapItemColorName.Turquoise,
    color: Colors.turqoise50,
  },
  {
    name: SitemapItemColorName.Slate,
    color: Colors.slate50,
  },
]

export const DEFAULT_SITEMAP_ITEM_COLOR = Colors.slate50

export const SITEMAP_ITEMS_COLORS_VALUES = SITEMAP_ITEMS_COLORS.map(
  ({ color }) => color,
)
