import { action, computed, observable } from 'mobx'

import { LocationType } from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import BaseListStore from '~/client/src/desktop/stores/ui/BaseList.store'
import {
  ILWFCCategory,
  ILWFCRow,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import IDateTimeFilter from '~/client/src/shared/interfaces/IDateTimeFilter'
import Announcement from '~/client/src/shared/models/Announcement'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import BuildingsStore from '~/client/src/shared/stores/domain/Buildings.store'
import ClosuresStore from '~/client/src/shared/stores/domain/Closures.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import IMsDateInterval from '~/client/src/shared/types/IMsDateInterval'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'

const CATEGORY_SEPARATOR = '*'
const CATEGORY_ROW_HEIGHT = 30
export const SITE_BAND = 'Site'
export const SITE_ROW_ID = 'site-row-id'

export enum ColumnKey {
  name = 'name',
  status = 'status',
  levelsToClose = 'levelsToClose',
  permittedCompanies = 'permittedCompanies',
  operatingIntervals = 'operatingIntervals',
  closedIntervals = 'closedIntervals',
  associatedPermits = 'associatedPermits',
  associatedAnnouncements = 'associatedAnnouncements',
  maxBookingDuration = 'maxBookingDuration',
  maxOverlappingDeliveries = 'maxOverlappingDeliveries',
}

export default class ObjectsListStore extends BaseListStore<LocationBase> {
  @observable public selectedAttribute: LocationBase
  @observable public isDeleteConfirmationDialogShown: boolean = false
  @observable public displayedPermitId: string = ''
  @observable public displayedAnnouncement: Announcement = null
  @observable public isSiteCollapsed: boolean = false

  @observable public announcementLocation: LocationAttributeBase
  @observable public isAnnouncementEditingDialogDisplayed = false
  @observable public isAnnouncementViewDisplayed = false
  @observable public isDeleteAnnouncementDialogDisplayed = false
  public get columns() {
    return []
  }

  public constructor(
    private readonly state: DesktopInitialState,
    private readonly tagsStore: TagsStore,
    objects: () => LocationBase[],
    private readonly buildingsStore: BuildingsStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly sitePermitsStore: SitePermitsStore,
    private readonly companiesStore: CompaniesStore,
    private readonly closuresStore: ClosuresStore,
    public readonly objectTypeId: LocationType,
  ) {
    super(state.objectFilters, objects)
  }

  public get columnKeys() {
    const isVerticalObject = this.objectTypeId === LocationType.VerticalObject
    const isSiteLocationObject =
      this.objectTypeId === LocationType.LogisticsObject
    const isVerticalOrSiteObject = isVerticalObject || isSiteLocationObject

    return [
      ColumnKey.name,
      isVerticalObject && ColumnKey.levelsToClose,
      ColumnKey.status,
      isVerticalObject && ColumnKey.closedIntervals,
      ColumnKey.permittedCompanies,
      ColumnKey.operatingIntervals,
      !isVerticalObject && ColumnKey.closedIntervals,
      ColumnKey.associatedPermits,
      ColumnKey.associatedAnnouncements,
      !isVerticalOrSiteObject && ColumnKey.maxBookingDuration,
      !isVerticalOrSiteObject && ColumnKey.maxOverlappingDeliveries,
    ].filter(c => c)
  }

  public getColumnKeyByIndex = (index: number) => {
    return this.columnKeys[index]
  }

  @computed
  public get categoryToInstancesMap(): {
    [categoryKey: string]: LocationBase[]
  } {
    const map = {
      [UNASSIGNED]: [],
    }

    this.filteredCollection.forEach(object => {
      const chainCategories = object.getHierarchyChainsObjects(
        this.tagsStore.tagStoreByTagTypeMap,
      )
      const categoryIds = []
      if (chainCategories.length) {
        const buildingCategory = chainCategories.find(
          category => category.type === LocationType.Building,
        )
        if (buildingCategory) {
          categoryIds.push(buildingCategory.id)
        }
      }
      const formattedCategoryIds = this.formatCategoryIds(categoryIds)

      if (map[formattedCategoryIds]) {
        map[formattedCategoryIds].push(object)
      } else {
        map[formattedCategoryIds] = [object]
      }
    })

    return map
  }

  @action.bound
  public toggleSite() {
    this.isSiteCollapsed = !this.isSiteCollapsed
  }

  @computed
  public get tableRowList(): ILWFCRow[] {
    if (this.rows.length === 1) {
      return this.rows
    }

    const firstEmptyRow = { data: {} }
    const realRows = this.rows.slice(1)
    const unassignedRowIndex = realRows.findIndex(
      ({ category }) => category && category.categoryId === UNASSIGNED,
    )

    if (unassignedRowIndex > -1) {
      realRows.splice(unassignedRowIndex, 1)
    }
    realRows.forEach((row, index) => {
      if (!row.category) {
        row.level = index < unassignedRowIndex ? 2 : 1
      }
    })

    return [
      firstEmptyRow,
      this.siteRow,
      ...(!this.isSiteCollapsed ? realRows : []),
    ]
  }

  @action.bound
  public hideAddAnnouncementDialog() {
    this.isAnnouncementEditingDialogDisplayed = false
    this.displayedAnnouncement = null
  }

  @action.bound
  public showAddAnnouncementDialog = (location: LocationAttributeBase) => {
    this.announcementLocation = location
    this.isAnnouncementEditingDialogDisplayed = true
  }

  public showAnnouncementDeleteConfirmDialog = () => {
    this.isDeleteAnnouncementDialogDisplayed = true
  }

  public hideAnnouncementDeleteDialog = () => {
    this.isDeleteAnnouncementDialogDisplayed = false
  }

  public announcementDeleteDialogCallback = () => {
    this.isDeleteAnnouncementDialogDisplayed = false
    this.displayedAnnouncement = null
  }

  public get objStore(): any {
    return this.tagsStore.tagStoreByTagTypeMap[this.selectedAttribute.type]
  }

  public get selectedAttributeName(): string {
    return this.selectedAttribute && this.selectedAttribute.name
  }

  public get selectedAttributeType(): string {
    return this.selectedAttribute && this.selectedAttribute.type
  }

  @action.bound
  public onApply = () => {
    this.objStore.removeItems([this.selectedAttribute.id])

    this.onHide()
    this.selectedAttribute = null
  }

  @action.bound
  public onObjectDuplicate = (obj: LocationBase) => {
    this.selectedAttribute = obj
    this.selectedAttribute.id = null
    this.objStore.saveItem(this.selectedAttribute)
  }

  @action.bound
  public onObjectDelete(obj: LocationBase) {
    this.isDeleteConfirmationDialogShown = true
    this.selectedAttribute = obj
  }

  @action.bound
  public onHide() {
    this.isDeleteConfirmationDialogShown = false
  }

  public setDisplayedPermitId = (id: string) => {
    if (this.displayedPermitId === id) {
      this.displayedPermitId = ''
      return
    }
    this.displayedPermitId = id
  }

  public setDisplayedAnnouncement = (announcement: Announcement) => {
    if (this.displayedAnnouncement?.id === announcement.id) {
      this.displayedAnnouncement = null
      this.isAnnouncementViewDisplayed = false
      return
    }

    this.isAnnouncementViewDisplayed = true
    this.displayedAnnouncement = announcement
  }

  protected toRows(objects: LocationBase[]) {
    return objects.map(object => {
      return { data: object }
    })
  }

  protected createCategoryRow(categoryId: string, count: number) {
    const building = this.buildingsStore.getInstanceById(categoryId)
    const category: ILWFCCategory = {
      categoryId,
      categoryLabel: this.getCategoryLabel(categoryId, count),
      isChecked: false,
    }

    return { category, data: building, height: CATEGORY_ROW_HEIGHT }
  }

  protected compareRows = (a: ILWFCRow, b: ILWFCRow): number => {
    const aObject = a.data as LocationBase
    const bObject = b.data as LocationBase
    if (!this.shouldSort) {
      return aObject.name.localeCompare(bObject.name)
    }
    const { columnKey, order } = this.sortState
    const aValue = aObject[columnKey]
    const bValue = bObject[columnKey]
    switch (columnKey) {
      case ColumnKey.name:
        return aValue.localeCompare(bValue) * order
      case ColumnKey.maxOverlappingDeliveries:
      case ColumnKey.maxBookingDuration:
        return (bValue - aValue) * order
      case ColumnKey.operatingIntervals:
      case ColumnKey.closedIntervals:
      case ColumnKey.permittedCompanies:
        return (bValue.length - aValue.length) * order
      case ColumnKey.status:
        return (
          (this.getObjectStatus(bObject) - this.getObjectStatus(aObject)) *
          order
        )
      case ColumnKey.associatedPermits:
        return (
          (this.sitePermitsStore.getClosedIntervals(bObject.id).length -
            this.sitePermitsStore.getClosedIntervals(aObject.id).length) *
          order
        )
    }
  }

  protected searchFiltering = (model: LocationBase<any>): boolean => {
    const { searchKey, timeKey, dateKey, iconKey } = this.state.objectFilters
    const key: string = searchKey.toLowerCase()
    const upToDateClosures = this.closuresStore
      .getLocationActiveClosures(model.id)
      .map(closure => closure.closureInterval)

    if (
      key &&
      !this.isInNameFilter(model, key) &&
      !this.isInBreadcrumbFilter(model, key) &&
      !this.isInCompaniesFilter(model, key)
    ) {
      return false
    }
    if (
      timeKey.start &&
      !this.isInDateFilters([], model.operatingIntervals, timeKey)
    ) {
      return false
    }
    if (dateKey.start && !this.isInDateFilters(upToDateClosures, [], dateKey)) {
      return false
    }

    if (
      iconKey &&
      this.objectTypeId === LocationType.LogisticsObject &&
      model.iconName !== iconKey
    ) {
      return false
    }
    return true
  }

  private getObjectStatus(obj: LocationBase): number {
    return Number(this.closuresStore.isLocationOpen(obj.id))
  }

  private getCategoryLabel(categoryIds: string, count: number): string {
    if (categoryIds === UNASSIGNED) {
      return `${UNASSIGNED}(${count})`
    }

    const buildings = categoryIds
      .split(CATEGORY_SEPARATOR)
      .map(building => this.buildingsStore.getInstanceById(building))
    return `${buildings.map(building =>
      building ? building.name : UNASSIGNED,
    )}(${count})`
  }

  private formatCategoryIds(categoryIds: string[]): string {
    return categoryIds.length
      ? categoryIds.join(CATEGORY_SEPARATOR)
      : UNASSIGNED
  }

  private isInNameFilter({ name }: LocationBase<any>, key: string): boolean {
    return name && name.toLowerCase().includes(key)
  }

  private isInCompaniesFilter(
    {
      hasAnyCompanyRestriction,
      getAllPermittedCompaniesNames,
    }: LocationBase<any>,
    key: string,
  ): boolean {
    if (!hasAnyCompanyRestriction) {
      return false
    }

    return getAllPermittedCompaniesNames(this.companiesStore).some(cName =>
      cName.toLowerCase().includes(key),
    )
  }

  private isInDateFilters(
    closedIntervals: IMsDateInterval[],
    operatingIntervals: IMsDateInterval[],
    { start, end }: IDateTimeFilter,
  ): boolean {
    return !this.projectDateStore.isClosedBetween(
      start,
      end,
      operatingIntervals,
      closedIntervals,
      'partially',
    )
  }

  private get siteRow(): ILWFCRow {
    const category: ILWFCCategory = {
      categoryId: SITE_ROW_ID,
      categoryLabel: SITE_BAND,
      isChecked: false,
    }

    return { category, data: {}, height: CATEGORY_ROW_HEIGHT }
  }

  private isInBreadcrumbFilter = (
    attribute: LocationBase<any>,
    key: string,
  ): boolean => {
    const hierarchyChains = attribute.getHierarchyChains(
      this.tagsStore.tagStoreByTagTypeMap,
    )

    return (
      hierarchyChains.some(
        value => value && value.toLowerCase().includes(key),
      ) || hierarchyChains.reverse().join('/').toLowerCase().includes(key)
    )
  }
}
