import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { LocationType } from '~/client/graph'
import EntityIconFilter from '~/client/src/desktop/components/Filters/EntityIconFilter/EntityIconFilter'
import EntityNameFilter from '~/client/src/desktop/components/Filters/EntityNameFilter/EntityNameFilter'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'

import ObjectPropertiesSetupStore from '../../ObjectPropertiesSetup.store'
import ObjectPropertiesFilterPanelStore from './ObjectPropertiesFilterPanel.store'
import ObjectPropertiesFilter from './components/ObjectPropertiesFilter/ObjectPropertiesFilter'
import ObjectPropertiesFilterStore from './components/ObjectPropertiesFilter/ObjectPropertiesFilter.store'

interface IProps {
  objectPropertiesSetupStore: ObjectPropertiesSetupStore

  eventsStore?: DesktopEventStore
  companiesStore?: CompaniesStore
}

@inject('eventsStore', 'companiesStore')
@observer
export default class ObjectPropertiesFilterPanel extends React.Component<IProps> {
  private readonly filterStore: ObjectPropertiesFilterStore = null
  private readonly store: ObjectPropertiesFilterPanelStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new ObjectPropertiesFilterPanelStore(
      props.eventsStore.appState,
    )

    this.filterStore = new ObjectPropertiesFilterStore(
      props.eventsStore,
      props.objectPropertiesSetupStore,
      props.companiesStore,
      this.store.onShowChanged,
    )
  }

  public render() {
    const { objectPropertiesSetupStore, eventsStore } = this.props
    const { objectFilters } = eventsStore.appState
    const { activeObjectTypeId, iconsByObjectType } = objectPropertiesSetupStore
    const {
      forceCloseMap,
      shouldCloseSearchPopup,
      shouldCloseIconPopup,
      onShowSearchChanged,
      onShowIconChanged,
    } = this.store

    return (
      <div className="row x-start objects-properties-filter-panel no-grow my10 relative">
        <EntityNameFilter
          filters={objectFilters}
          forceClose={shouldCloseSearchPopup}
          onShowChanged={onShowSearchChanged}
        />
        <ObjectPropertiesFilter
          eventsStore={eventsStore}
          forceCloseMap={forceCloseMap}
          store={this.filterStore}
        />
        {activeObjectTypeId === LocationType.LogisticsObject && (
          <EntityIconFilter
            filters={objectFilters}
            forceClose={shouldCloseIconPopup}
            options={iconsByObjectType}
            onShowChanged={onShowIconChanged}
          />
        )}
      </div>
    )
  }
}
