import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import AvatarType from '../../../enums/AvatarType'
import ChatService from '../../../services/ChatService/Chat.service'
import { IChat } from '../../../services/ChatService/types'
import CompaniesStore from '../../../stores/domain/Companies.store'
import ProjectMembersStore from '../../../stores/domain/ProjectMembers.store'
import ProjectRolesStore from '../../../stores/domain/ProjectRoles.store'
import UserProjectsStore from '../../../stores/domain/UserProjects.store'
import ProjectDateStore from '../../../stores/ui/ProjectDate.store'
import { NOOP } from '../../../utils/noop'
import Avatar, { AvatarSize } from '../../Avatar/Avatar'
import BaseActionButton from '../../BaseActionButton/BaseActionButton'
import { Loader } from '../../Loader'
import SearchBar from '../../UsersDirectory/components/SearchBar/SearchBar'
import InboxStore from './Inbox.store'

import './Inbox.scss'

export interface IProps {
  onlineContacts: Set<string>
  selectInboxItem: (inboxItem: IChat) => void
  onNewChat: () => void

  chatService?: ChatService
  projectDateStore?: ProjectDateStore
  projectMembersStore?: ProjectMembersStore
  userProjectsStore?: UserProjectsStore
  companiesStore?: CompaniesStore
  projectRolesStore?: ProjectRolesStore
}

@inject(
  'chatService',
  'projectDateStore',
  'projectMembersStore',
  'userProjectsStore',
  'companiesStore',
  'projectRolesStore',
)
@observer
export default class Inbox extends React.Component<IProps> {
  private readonly store: InboxStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new InboxStore(
      props.chatService,
      props.onlineContacts,
      props.projectMembersStore,
      props.userProjectsStore,
      props.companiesStore,
      props.projectRolesStore,
    )
  }

  public componentDidMount() {
    this.store.init()
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.onlineContacts.size !== this.props.onlineContacts.size) {
      this.store.onlineContacts = this.props.onlineContacts
    }
  }

  public render() {
    if (this.store.isLoading) {
      return <Loader size={40} hint="Inbox loading..." />
    }

    const { sortedAndFilteredChats, searchKey } = this.store

    return (
      <div className="col overflow-hidden full-height inbox-container">
        <SearchBar
          className="mx15 mt10"
          value={searchKey}
          onChange={v => (this.store.searchKey = v)}
          onReset={() => (this.store.searchKey = '')}
          onTagClick={NOOP}
        />
        <div
          className="no-grow text large blue bold right pointer px15 mt20 mb10"
          onClick={this.props.onNewChat}
        >
          <Icon icon={IconNames.PLUS}></Icon> New chat
        </div>
        <div className="overflow-y-auto full-height px15">
          {sortedAndFilteredChats.length ? (
            sortedAndFilteredChats.map(this.renderInboxItem)
          ) : (
            <div className="col x-center full-height y-center">
              <span className="text bold mb10">Inbox is empty</span>
              <BaseActionButton
                className="scale-blue-theme"
                title="Let's start!"
                isEnabled={true}
                onClick={this.props.onNewChat}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  public componentWillUnmount() {
    this.store.removeListeners()
  }

  private renderInboxItem = (chat: IChat) => {
    const { selectInboxItem } = this.props
    const lastMessage = this.store.lastMessageByChannel.get(chat.id)

    return (
      <div
        key={chat.id}
        className="row inbox-item my10 pointer"
        onClick={selectInboxItem.bind(null, chat)}
      >
        <Avatar
          src={chat.avatar}
          size={AvatarSize.Small}
          avatarType={chat.isGroup ? AvatarType.Company : AvatarType.User}
          isOnline={this.getInboxItemOnlineStatus(chat)}
        />

        <div className="col bb-light-input-border ml8">
          <div className="row">
            <div className="col">
              <div className="text bold extra-large">{chat.name}</div>
              <div className="text large light">
                {this.store.subtitleByChatId[chat.id] || ''}
              </div>
            </div>
            <div className="col no-grow x-end as-start">
              <div
                className={classList({
                  'text no-white-space-wrap no-grow pb4': true,
                  'bold blue': !!chat.unreadMessagesCount,
                  light: !chat.unreadMessagesCount,
                })}
              >
                {!!lastMessage &&
                  this.getDatetimeToDisplay(lastMessage.createdAt)}
              </div>
              {!!chat.unreadMessagesCount && (
                <div className="row x-center y-center w18 h18 br-rounded bg-red text white bold">
                  {chat.unreadMessagesCount}
                </div>
              )}
            </div>
          </div>

          <div className="inbox-item-text text large my8 overflow-hidden">
            {this.getInboxItemMessageText(chat) || this.noMessage}
          </div>
        </div>
      </div>
    )
  }

  private getDatetimeToDisplay(timestamp: number): string {
    const { projectDateStore } = this.props

    return projectDateStore.isToday(timestamp)
      ? projectDateStore.getTimeToDisplay(timestamp)
      : projectDateStore.getPronounOrMonthAndDayString(timestamp)
  }

  private getInboxItemOnlineStatus({ isGroup, memberIds }: IChat): boolean {
    if (isGroup) {
      return null
    }

    const recipientId = this.store.getDirectChatRecipientId(memberIds)
    return this.store.isContactOnline(recipientId)
  }

  private getInboxItemMessageText(chat: IChat) {
    const lastMessage = this.store.lastMessageByChannel.get(chat.id)
    const messageText = lastMessage?.text || this.noMessage

    if (!chat.isGroup) {
      return messageText
    }

    const authorId = lastMessage?.authorId

    return (
      authorId && (
        <>
          <span className="text large bold">
            {this.store.contactName.get(authorId) || 'User'}:
          </span>
          <span className="ml4 text extra-large">{messageText}</span>
        </>
      )
    )
  }

  private get noMessage(): JSX.Element {
    return <span className="text large light">No message</span>
  }
}
