import { action, observable } from 'mobx'
import { classList } from 'react-classlist-helper'

import CalendarEvent, {
  CalendarEventEntityType,
} from '../../models/CalendarEvent'
import CompaniesStore from '../domain/Companies.store'
import ProjectDateStore, { isAfter, isBefore } from './ProjectDate.store'

export const MINUTES_IN_HOUR = 60
export const DEFAULT_TIME_INTERVAL = 60
export const HOURS_IN_DAY = 24

export const ONE_MINUTE_HEIGHT_PX = 1
const PADDING_RIGHT_SPACE = 12
export const PREVIEW_START_HOUR = 6
export const HOUR_HEIGHT_PX = ONE_MINUTE_HEIGHT_PX * MINUTES_IN_HOUR

enum CalendarViewMode {
  DEFAULT,
  NEW_EVENT,
}

export default abstract class CalendarViewStore {
  public readonly dayHours = Array.from(Array(25).keys())
  @observable public editableEventStartDate: Date
  @observable public editableEventEndDate: Date = null
  @observable public editableEventDataId: string
  @observable public mode: CalendarViewMode = CalendarViewMode.DEFAULT
  public positionedEvents: { [eventId: string]: number } = {}

  public constructor(
    protected readonly projectDateStore: ProjectDateStore,
    protected readonly companiesStore: CompaniesStore,
    protected readonly isPreviewMode?: boolean,
  ) {}

  // TODO: Investigate it deeply
  // "positionedEvents" and its restoring before render method was added by Valerii in scope of PR #2508
  // I had to add this to reset it also on "CalendarDayView.tsx"
  // Without this reset, the "getEventSizeAndPosition" method returns incorrect event positions when the component is re-render
  public restorePositionedEvents() {
    this.positionedEvents = {}
  }

  @action.bound
  public setNewEventMode() {
    this.mode = CalendarViewMode.NEW_EVENT
  }

  @action.bound
  public setDefaultMode() {
    this.editableEventDataId = null
    this.mode = CalendarViewMode.DEFAULT
  }

  public get timeInterval() {
    return DEFAULT_TIME_INTERVAL
  }

  protected get minStartTimeMs() {
    const { getTimeMs, startOfDay } = this.projectDateStore
    return getTimeMs(startOfDay(new Date()))
  }

  protected get maxStartTimeMs() {
    const { getTimeMs, endOfDay, addMinutes } = this.projectDateStore
    return getTimeMs(addMinutes(endOfDay(new Date()), -this.timeInterval))
  }

  protected get maxEndTimeMs() {
    const { getTimeMs, endOfDay } = this.projectDateStore
    return getTimeMs(endOfDay(new Date()))
  }

  public get rowsNumber() {
    return Math.round(MINUTES_IN_HOUR / this.timeInterval)
  }

  public getEndDate(startDate: Date) {
    const { addMinutes, isSameDay } = this.projectDateStore
    let endDate = addMinutes(startDate, this.timeInterval)
    if (!isSameDay(startDate, endDate)) {
      endDate = addMinutes(endDate, -1)
    }

    return endDate
  }

  public getNewEvent(entityType: CalendarEventEntityType): CalendarEvent {
    const startDate = this.editableEventStartDate
    const endDate = this.editableEventEndDate || this.getEndDate(startDate)

    return CalendarEvent.createNewEvent(
      startDate,
      endDate,
      this.projectDateStore,
      entityType,
    )
  }

  public getEventSizeAndPosition = (
    event: CalendarEvent,
    allEvents: CalendarEvent[],
    currentDay: Date,
  ) => {
    let { startDate, endDate } = event
    const { isSameDay, getTimeMs, setTimeMs } = this.projectDateStore
    if (getTimeMs(startDate) < this.minStartTimeMs) {
      startDate = setTimeMs(startDate, this.minStartTimeMs)
    }

    if (getTimeMs(endDate) > this.maxEndTimeMs) {
      endDate = setTimeMs(startDate, this.maxEndTimeMs)
    }

    let startOffset = this.getOffsetByDate(startDate, currentDay)
    let endOffset = this.getOffsetByDate(endDate, currentDay)

    let blockHeight
    if (endOffset - startOffset < 0) {
      blockHeight = `calc(100% - ${startOffset}px)`
      if (isSameDay(endDate, currentDay)) {
        endOffset = startOffset
        startOffset = 0
      }
    } else {
      blockHeight = endOffset - startOffset
    }

    const sles = this.getSameLineEventsStrict(event, allEvents)
    const saveLineEvents = this.getSameBlockEvents(sles, allEvents)

    const maxLineEvents = this.getSameBlockEvents(
      this.getMaxSameLineEvents(event, allEvents),
      allEvents,
    )

    const saveLineEventsCount = this.getMaxBlockEvents(event, allEvents)

    const saveIndex = saveLineEvents.indexOf(event)
    const maxIndex = maxLineEvents.indexOf(event)

    this.positionedEvents[event.dataId] = saveIndex

    const isLastLineEvent = maxIndex === maxLineEvents.length - 1
    const singleEventWidth = 100 / saveLineEventsCount

    const percentageSizePart = isLastLineEvent
      ? singleEventWidth * (saveLineEventsCount - maxIndex)
      : singleEventWidth
    const widthCorrection = PADDING_RIGHT_SPACE / saveLineEventsCount
    const blockWidth = `calc(${percentageSizePart}% - ${widthCorrection}px)`

    const percentagePositionPart = singleEventWidth * saveIndex
    const pxPosition = widthCorrection * saveIndex
    const leftOffset = `calc(${percentagePositionPart}% - ${pxPosition}px)`

    const style = Object.assign(
      {
        height: blockHeight,
        top: startOffset,
        left: leftOffset,
        width: blockWidth,
      },
      event.styles,
    )

    return {
      style,
      className: classList({
        'dashed-bottom': !isSameDay(endDate, currentDay),
        'dashed-top': !isSameDay(startDate, currentDay),
      }),
    }
  }

  public get isNewEventMode() {
    return this.mode === CalendarViewMode.NEW_EVENT
  }

  public getOffsetByDate(date: Date, currentDay: Date) {
    let [hours, minutes] = this.projectDateStore.getHoursMinutes(date)
    if (!this.projectDateStore.isSameDay(date, currentDay)) {
      if (isBefore(date, currentDay)) {
        hours = 0
        minutes = 0
      } else if (isAfter(date, currentDay)) {
        hours = 23
        minutes = 59
      }
    }

    if (this.isPreviewMode) {
      hours = hours - PREVIEW_START_HOUR
    }

    return (hours * MINUTES_IN_HOUR + minutes) * ONE_MINUTE_HEIGHT_PX
  }

  protected getSameBlockEvents(
    iteratedEvents: CalendarEvent[],
    allEvents: CalendarEvent[],
  ) {
    let oldEvents: CalendarEvent[] = []
    const newEvents: CalendarEvent[] = []

    allEvents
      .filter(e => iteratedEvents.includes(e))
      .forEach(e => {
        const definedIndex = this.positionedEvents[e.dataId]
        if (Number.isInteger(definedIndex)) {
          oldEvents[definedIndex] = e // keep order!
        } else {
          newEvents.push(e)
        }
      })

    oldEvents = Object.values(oldEvents) // from sparse array to a dense one

    newEvents.sort((a, b) => {
      const dataA = a.data
      const dataB = b.data

      if (a.startDate.getTime() !== b.startDate.getTime()) {
        return a.startDate.getTime() - b.startDate.getTime()
      }

      const durationA = a.endDate.getTime() - a.startDate.getTime()
      const durationB = b.endDate.getTime() - b.startDate.getTime()

      if (durationA !== durationB) {
        return durationB - durationA
      }

      const nameA = dataA?.codeToDisplay(this.companiesStore)
      const nameB = dataB?.codeToDisplay(this.companiesStore)

      if (nameA && !nameB) {
        return 1
      } else if (!nameA && nameB) {
        return -1
      } else if (!nameA && !nameB) {
        return 1
      } else {
        return nameA.localeCompare(nameB)
      }
    })

    let oldIndex = 0
    let newIndex = 0
    const resultArray: CalendarEvent[] = []
    for (let index = 0; index < newEvents.length + oldEvents.length; index++) {
      if (
        !!oldEvents[oldIndex] &&
        this.positionedEvents[oldEvents[oldIndex].dataId] === index
      ) {
        resultArray.push(oldEvents[oldIndex++])
      } else {
        resultArray.push(newEvents[newIndex++])
      }
    }

    return resultArray
  }

  protected getSameLineEvents(
    event: CalendarEvent,
    allEvents: CalendarEvent[],
    eventsToExclude: CalendarEvent[] = [],
  ) {
    return allEvents.filter(e => {
      return (
        !eventsToExclude.includes(e) &&
        (e.isEqual(event) || e.isIntersect(event))
      )
    })
  }

  protected validateEditableEventStartDate(baseDate: Date) {
    const editableDate = this.editableEventStartDate
    const { getTimeMs, setTimeMs, isSameDay } = this.projectDateStore
    const startTimeMs = getTimeMs(editableDate)

    const isEditableDateBeforeBaseDate =
      !isSameDay(baseDate, editableDate) && isBefore(editableDate, baseDate)
    if (startTimeMs < this.minStartTimeMs || isEditableDateBeforeBaseDate) {
      this.editableEventStartDate = setTimeMs(baseDate, this.minStartTimeMs)
    }

    const isEditableDateAfterBaseDate =
      !isSameDay(baseDate, editableDate) && isAfter(editableDate, baseDate)
    if (startTimeMs > this.maxStartTimeMs || isEditableDateAfterBaseDate) {
      this.editableEventStartDate = setTimeMs(baseDate, this.maxStartTimeMs)
    }
  }

  private getSameLineEventsStrict(
    event: CalendarEvent,
    allEvents: CalendarEvent[],
  ) {
    const eventsToCheck = [event]
    allEvents.forEach(e => {
      const shouldAdd = eventsToCheck.every(
        eventToCheck => e.isIntersect(eventToCheck) && !e.isEqual(eventToCheck),
      )
      if (shouldAdd) {
        eventsToCheck.push(e)
      }
    })

    return eventsToCheck
  }

  private getMaxSameLineEvents(
    event: CalendarEvent,
    allEvents: CalendarEvent[],
  ) {
    let maxeventsToCheck = [event]
    const ev = allEvents.filter(e => e.isIntersect(event) && !e.isEqual(event))
    ev.forEach(e => {
      const eventsToCheck = [event, e]
      allEvents.forEach(e2 => {
        const shouldAdd = eventsToCheck.every(
          eventToCheck =>
            e2.isIntersect(eventToCheck) && !e2.isEqual(eventToCheck),
        )
        if (shouldAdd) {
          eventsToCheck.push(e2)
        }
      })
      if (maxeventsToCheck.length < eventsToCheck.length) {
        maxeventsToCheck = eventsToCheck.slice()
      }
    })

    return maxeventsToCheck
  }

  private getMaxBlockEvents(
    event: CalendarEvent,
    allEvents: CalendarEvent[],
  ): number {
    const eventsToIterate = this.getSameLineEvents(event, allEvents)
    let max = 1

    eventsToIterate.forEach(e => {
      const localMaxEvents = this.getMaxSameLineEvents(e, allEvents)
      if (localMaxEvents.length > max) {
        max = localMaxEvents.length
      }
    })

    return max
  }
}
