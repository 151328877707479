import * as React from 'react'

import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import { IBaseStepDiagramProps } from './BaseStepDiagram'
import SimpleStepDiagram from './SimpleStepDiagram'

// localization: translated

@observer
export default class ApprovalStepDiagram extends React.Component<IBaseStepDiagramProps> {
  public render() {
    return (
      <SimpleStepDiagram
        {...this.props}
        includeChangeAction
        intermediateStateText={this.intermediateStateText}
        bottomBlockActionText={Localization.translator.deny_verb}
        bottomBlockTerminationText={Localization.translator.denied}
        actionStateText={Localization.translator.approve_verb}
      />
    )
  }

  private get intermediateStateText(): string {
    const { hasStartStep, isAutoActivationEnabled } = this.props
    return hasStartStep || !isAutoActivationEnabled
      ? Localization.translator.approved
      : Localization.translator.active
  }
}
