import { action, computed } from 'mobx'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import MaterialsFilterType, {
  getMaterialsFilterTypeCaption,
} from '~/client/src/shared/enums/MaterialsFilterType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import { IMaterialViewModel } from '~/client/src/shared/models/IMaterialViewModel'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { RESET_ALL_FILTERS } from '~/client/src/shared/stores/EventStore/eventConstants'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'
import SuperFilterStore, {
  IOption,
  IRootOption,
} from '~/client/src/shared/stores/ui/SuperFilter.store'
import Guard from '~/client/src/shared/utils/Guard'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import { NO_SPECIFIED } from '~/client/src/shared/utils/usefulStrings'

import MaterialsListStore from '../../../MaterialsList/MaterialsList.store'

export type ISourceMap = { [optionId: string]: string[] }

const unassignedValues = [UNASSIGNED, NO_SPECIFIED]

export default class BaseMaterialsFilterStore extends SuperFilterStore {
  private readonly collator = new Intl.Collator([], {
    numeric: true,
    sensitivity: 'accent',
  })

  public get totalHint(): string {
    return Localization.translator.totalMaterials
  }

  public constructor(
    public readonly type: MaterialsFilterType,
    protected readonly state: DesktopInitialState,
    protected readonly sourceMap: ISourceMap,
    protected readonly materialsListStore: MaterialsListStore,
    protected readonly onShowChanged: (isShown: boolean, type: string) => void,
    protected readonly fieldsMap: { [filterType: string]: UIFilterInfo },
    private readonly getOptionName?: (
      optionId: string,
      filterType: string,
    ) => string,
    protected readonly onClickHandler?: () => void,
  ) {
    super(
      fieldsMap[type],
      type,
      state,
      KnownTranslatorKeys.seeXMaterials,
      getMaterialsFilterTypeCaption,
      onShowChanged,
      false,
      onClickHandler,
    )

    Guard.requireAll({ type, state, sourceMap, materialsListStore })
  }

  @action.bound
  public onFilterActionRequest(eventContext: EventContext) {
    const [eventType] = eventContext.event
    switch (eventType) {
      case RESET_ALL_FILTERS:
        return this.setInitialFilterValues()
    }
  }

  @computed
  public get optionsTree(): IRootOption[] {
    const options = Object.keys(this.sourceMap)
    return [
      {
        id: this.type,
        name: this.typeCaption,
        options: options
          .map(option => ({
            id: option,
            name: this.getOptionName?.(option, this.type) || option,
            instancesIds: this.sourceMap[option],
          }))
          .sort(this.sortFilterOptions),
      },
    ]
  }

  @action.bound
  public clickOnApply() {
    this.handleApply()
  }

  public filterOptionInstances = (ids: string[]) => {
    return (this.allFilteredInstances as Array<IMaterialViewModel>).filter(c =>
      ids.includes(c.id),
    )
  }

  @computed
  private get allFilteredInstances(): IMaterialViewModel[] {
    const { getFilteredCollectionExcludeFilter } = this.materialsListStore
    return getFilteredCollectionExcludeFilter([this.type])
  }

  private sortFilterOptions = (a: IOption, b: IOption) => {
    if (unassignedValues.includes(a.name)) {
      return 1
    }
    if (unassignedValues.includes(b.name)) {
      return -1
    }

    return this.collator.compare(a.name, b.name)
  }
}
