import { UNASSIGNED } from './ZoneLevelLocationConstants'

const SEPARATOR = '|'

export const groupingAttributeMapper = option => {
  return {
    name: option.name || UNASSIGNED,
    id: option.id + SEPARATOR + option.type || UNASSIGNED,
    object: option,
  }
}

export const groupingCommonMapper = (option: string) => {
  return {
    name: option || UNASSIGNED,
    id: option || UNASSIGNED,
  }
}

export const commonIdMapper = option => {
  return typeof option === 'string' ? UNASSIGNED : option.id
}

export const commonById = (id: string) => {
  return option => (typeof option === 'string' ? !id : option.id === id)
}

export const commonByIds = (ids: string[]) => {
  return option =>
    typeof option === 'string' ? !ids.length : ids.includes(option.id)
}

export const commonAttributeMapper = option => {
  if (typeof option === 'string') {
    return {
      name: option,
      id: UNASSIGNED,
      object: option,
    }
  }

  return {
    name: option.name || UNASSIGNED,
    id: option.id || UNASSIGNED,
    object: option,
  }
}
