import { observer } from 'mobx-react'

import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import Announcement from '~/client/src/shared/models/Announcement'

import ConfirmItemsDialog from '../../ProjectSetUp/components/ConfirmItemDialog/ConfirmItemDialog'

@observer
export default class ConfirmAnnouncementDialog extends ConfirmItemsDialog<Announcement> {
  public constructor(props) {
    super(props)

    this.setUpTitles()

    this.tableInfo = {
      columnTitleKeys: [KnownTranslatorKeys.name, KnownTranslatorKeys.dates],
      columnProps: [
        (announcement: Announcement) => announcement.getDisplayedName(true),
        (announcement: Announcement) => announcement.datesLabel,
      ],
    }
  }

  private setUpTitles() {
    this.questionKey =
      KnownTranslatorKeys.confirmDeletionOfTheFollowingXAnnouncements
    this.dialogDoneBtnTitleKey = KnownTranslatorKeys.delete
    this.dialogTitleKey = KnownTranslatorKeys.confirmDeletion
  }
}
