import { IActivityCodeType } from '~/client/graph'
import BaseModel from '~/client/src/shared/models/BaseModel'
import Guard from '~/client/src/shared/utils/Guard'

export default class ActivityCodeType extends BaseModel<IActivityCodeType> {
  public static fromInstance(instance: ActivityCodeType): ActivityCodeType {
    return new ActivityCodeType(
      instance.id,
      instance.name,
      instance.scope,
      instance.createdAt,
      instance.updatedAt,
      instance.scheduleId,
    )
  }

  public static fromDto(dto: IActivityCodeType) {
    return new ActivityCodeType(
      dto.id,
      dto.name,
      dto.scope,
      dto.createdAt,
      dto.updatedAt,
      dto.scheduleId,
    )
  }

  public constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly scope: string,
    public readonly createdAt: number,
    public readonly updatedAt: number,
    public readonly scheduleId: string,
  ) {
    super(id)

    Guard.againstUndefined(id, 'id')
  }

  public get asJson(): IActivityCodeType {
    return {
      createdAt: this.createdAt,
      id: this.id,
      name: this.name,
      scope: this.scope,
      updatedAt: this.updatedAt,
      scheduleId: this.scheduleId,
    }
  }
}
