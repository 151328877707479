import { observable } from 'mobx'

import { NotificationType } from '~/client/graph'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'

import EventsStore from '../../stores/EventStore/Events.store'
import { GET_PDF_BLOB_URL } from '../../stores/EventStore/eventConstants'

export interface IFullscreenPreviewComment {
  authorId: string
  text: string
  companyName: string
  timestamp: number
  deliveryStatus?: DeliveryStatus
  photoDetails?: string[]
  activityEntityType?: NotificationType
}
export const MAX_SCALE = 10
export const MIN_SCALE = 0.05
export const SCALE_STEP = 0.05
const TOOLBAR_DEFAULT_WIDTH = 360

export default class FileFullscreenPreviewStore {
  @observable public scale: number = 1
  @observable public url: string = ''
  @observable public blobUrl: string = ''
  @observable public pageNumber: number = 1
  public documentHolderRef

  @observable private totalPages: number = 1
  @observable private pageWidth: number = 1
  @observable private pageHeight: number = 1

  public constructor(private readonly eventsStore: EventsStore) {}

  public onPageLoad = page => {
    this.pageWidth = page.originalWidth
    this.pageHeight = page.originalHeight
    if (this.scale !== this.pageScale) {
      this.onScaleChanged(this.pageScale)
    }
  }

  public get toolbarWidth(): number {
    if (!this.documentHolderRef) {
      return TOOLBAR_DEFAULT_WIDTH
    }
    return Math.min(this.documentHolderRef.clientWidth, TOOLBAR_DEFAULT_WIDTH)
  }

  public openNextPage = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (this.pageNumber === this.totalPages) {
      this.pageNumber = 1
    } else {
      this.pageNumber++
    }
    this.scale = null
  }

  public openPrevPage = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (this.pageNumber === 1) {
      this.pageNumber = this.totalPages
    } else {
      this.pageNumber--
    }
    this.scale = null
  }

  public scaleIn = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (this.scale + SCALE_STEP > MAX_SCALE) {
      this.scale = MAX_SCALE
    } else {
      this.scale += SCALE_STEP
    }
  }

  public scaleOut = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (this.scale - SCALE_STEP < MIN_SCALE) {
      this.scale = MIN_SCALE
    } else {
      this.scale -= SCALE_STEP
    }
  }

  public scaleToFit = (event: React.MouseEvent) => {
    event.stopPropagation()
    this.onScaleChanged(this.pageScale)
  }

  public onScaleChanged = (scale: number) => {
    this.scale = scale
  }

  public setBlobUrl = (blob: Blob) => {
    this.blobUrl = URL.createObjectURL(blob)
  }

  public updateFileUrls(url: string) {
    this.url = url
    this.eventsStore.dispatch(GET_PDF_BLOB_URL, this.url, this.setBlobUrl)
  }

  public onDocumentLoadSuccess = doc => {
    this.totalPages = doc.numPages
  }

  public setHolderRef = (ref: HTMLIFrameElement) => {
    this.documentHolderRef = ref
  }

  public get pagesLabel(): string {
    return `${this.pageNumber}/${this.totalPages}`
  }

  private get pageScale(): number {
    const pageXScale = this.documentHolderRef.clientWidth / this.pageWidth
    const pageYScale = this.documentHolderRef.clientHeight / this.pageHeight
    return Math.max(pageXScale, pageYScale)
  }
}
