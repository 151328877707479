import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { IUpdaterInfo } from '~/client/graph'
import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import getUserName from '~/client/src/shared/utils/GetUserName'

import ProjectScheduleUploadStore from '../../ProjectScheduleUpload.store'
import ScheduleUploadHistoryStore from './ScheduleUploadHistory.store'
import HiddenActivityCodesInfo from './components/HiddenActivityCodesInfo'
import ScheduleDescription from './components/ScheduleDescription'

import './ScheduleUploadHistory.scss'

interface IScheduleUploadHistory {
  scheduleUploadHistoryStore: ScheduleUploadHistoryStore
  projectScheduleUploadStore: ProjectScheduleUploadStore
  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
class ScheduleUploadHistory extends React.Component<IScheduleUploadHistory> {
  public get store() {
    return this.props.scheduleUploadHistoryStore
  }

  public render() {
    const isScheduleUploaded = this.store.lastScheduleUpdate
    return isScheduleUploaded ? this.scheduleUploadHistory : null
  }

  private get scheduleUploadHistory() {
    return (
      <div className="schedule-history">
        {this.lastUploadSection}
        {this.historyUploadSection}
      </div>
    )
  }

  private get lastUploadSection() {
    const { by, updatedAt, fileName, schedule } = this.store.lastScheduleUpdate

    return (
      <div className="schedule-history-section">
        <div className="schedule-history-section-header">
          {Localization.translator.lastUpload}
        </div>
        <div className="line-container history-line history-line-info line">
          <div className="title">{this.getTitle(updatedAt, by)}</div>
          <div className="description">
            <ScheduleDescription filename={fileName} schedule={schedule} />
          </div>
          <HiddenActivityCodesInfo
            projectScheduleUploadStore={this.props.projectScheduleUploadStore}
          />
        </div>
      </div>
    )
  }

  private get historyUploadSection() {
    const { scheduleHistory } = this.store

    if (!scheduleHistory?.length) {
      return null
    }

    const { showRestoringScheduleConfirm } = this.store

    return (
      <div className="schedule-history-section">
        {this.renderRestoreConfirmModal()}
        <div className="schedule-history-section-header">
          {Localization.translator.uploadHistory}
        </div>
        <div className="schedule-history-section-lines-container bb-light-grey mb20">
          {scheduleHistory.map(
            ({ updatedAt, fileName, by, schedule }, index) => {
              return (
                <div className="history-line row" key={index}>
                  <div className="history-line-info no-flex">
                    <div className="title">{this.getTitle(updatedAt, by)}</div>
                    <div className="description">
                      <ScheduleDescription
                        filename={fileName}
                        schedule={schedule}
                      />
                    </div>
                  </div>
                  <div className="history-line-restore">
                    <button
                      className="restore-btn"
                      type="button"
                      onClick={showRestoringScheduleConfirm.bind(this, index)}
                    >
                      {Localization.translator.restore}
                    </button>
                  </div>
                </div>
              )
            },
          )}
        </div>
      </div>
    )
  }

  private renderRestoreConfirmModal() {
    const {
      shouldShowRestoreConfirmModal,
      hideRestoringScheduleConfirm,
      restoreOldSchedule,
      selectedBackup,
    } = this.store

    if (!selectedBackup) {
      return null
    }

    const { fileName, updatedAt } = selectedBackup
    const date =
      this.props.projectDateStore.getMonthDayYearAndTimeToDisplay(updatedAt)
    return (
      <ConfirmDialog
        isOpen={shouldShowRestoreConfirmModal}
        onCancelClicked={hideRestoringScheduleConfirm}
        onDoneClicked={restoreOldSchedule}
        title={Localization.translator.scheduleRestoringConfirmation.toUpperCase()}
      >
        <div className="text large">
          {...Localization.translator.doYouConfirmRestoringFileFromDate(
            fileName,
            <span key={fileName} className="text large bold">
              {date}
            </span>,
          )}
        </div>
      </ConfirmDialog>
    )
  }

  private getTitle(timestamp: number, by: IUpdaterInfo): string {
    const { projectDateStore } = this.props
    const date = projectDateStore.getMonthDayYearAndTimeToDisplay(timestamp)

    if (by) {
      const userName = getUserName(by)
      return Localization.translator.dateByName(date, userName)
    }

    return date
  }
}

export default ScheduleUploadHistory
