import * as React from 'react'

import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import * as Icons from '~/client/src/shared/components/Icons'
import SelectButton from '~/client/src/shared/components/SelectButton/SelectButton'
import SelectionPopUp, {
  SelectionPopUpOption,
} from '~/client/src/shared/components/SelectionPopUp/SelectionPopUp'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ActivityFollowingsStore from '~/client/src/shared/stores/domain/ActivityFollowings.store'
import ActivityPresetsStore from '~/client/src/shared/stores/domain/ActivityPresets.store'

import ActivityGanttOrListViewStore from '../../ActivityGanttOrListView.store'
import TableOffsetsColumnCollapseStore from '../../TableOffsetsColumnCollapse.store'
import ActivitiesCount from './components/ActivitiesCount'
import NewSetModal from './components/NewSetModal'
import SectionButton from './components/SectionButton'

import './BulkActionBar.scss'

interface IBulkActionBarProps {
  activityListStore: DesktopActivityListStore
  activityFollowingsStore?: ActivityFollowingsStore
  state?: DesktopInitialState
  activityPresetsStore?: ActivityPresetsStore
  store: ActivityGanttOrListViewStore
  tableOffsetsColumnCollapseStore: TableOffsetsColumnCollapseStore
}

interface IBulkActionBarSection {
  label: string
  icon: JSX.Element
  onClick: () => void
  isActive: boolean
  isDisabled: boolean
}

const ADDITIONAL_OFFSET_WIDTH = 30

const followSectionName = 'Follow'
const assignSectionName = 'Assign'
const saveSectionName = 'Save'
const editSectionName = 'Edit'
const deleteSectionName = 'Delete'

@inject('activityFollowingsStore', 'activityPresetsStore', 'state')
@observer
export default class BulkActionBar extends React.Component<IBulkActionBarProps> {
  @observable private isSelectionPopUpDisplayed: boolean = false
  @observable private shouldShowActivitiesSetNameInput = false

  @computed
  private get selectionPopUpOptions(): SelectionPopUpOption[] {
    return [
      { label: this.selectAllTitle, onClick: this.selectAll },
      {
        label: Localization.translator.clear,
        onClick: this.deselectAll,
      },
    ]
  }

  public render() {
    const { activityPresetsStore, activityListStore, state } = this.props
    const { filteredActivities, selectedActivitiesCount } = this.props.store

    const {
      toggleProgressBarCollapseState,
      shouldUseDefaultCollapseStateForProgress,
      assigneeArrowOffset,
      durationArrowOffset,
      plannedScheduleArrowOffset,
      actualScheduleArrowOffset,
      commentsArrowOffset,
      toggleAssigneeBarCollapseState,
      toggleDurationBarCollapseState,
      togglePlannedScheduleBarCollapseState,
      toggleActualScheduleBarCollapseState,
      toggleCommentsCollapseState,
      shouldCollapseAssignee,
      shouldCollapseDuration,
      shouldCollapsePlannedSchedule,
      shouldCollapseActualSchedule,
      shouldCollapseComments,
      activityNameSectionWidth,
    } = this.props.tableOffsetsColumnCollapseStore
    const {
      userActiveProjectSettings,
      activityList: { viewMode },
    } = state
    const { editablePresetName, onNameChange, onNameReset } =
      activityPresetsStore
    const filteredActivitiesCount = filteredActivities.length

    return (
      <div
        className={classList({
          'row x-start bulk-action-bar-wrapper no-outline-container': true,
          'inactive-element': userActiveProjectSettings?.isPresentationUser,
        })}
      >
        <ConfirmDialog
          isOpen={activityListStore.isRemoveActivityDialogShown}
          title={Localization.translator.confirmDeletion.toUpperCase()}
          doneTitle={Localization.translator.delete}
          question={Localization.translator.confirmDeletionOfActivities(
            selectedActivitiesCount,
          )}
          onDoneClicked={this.deleteActivities}
          onCancelClicked={activityListStore.toggleRemoveActivitiesDialog}
        />
        <SelectionPopUp
          isDisplayed={this.isSelectionPopUpDisplayed}
          options={this.selectionPopUpOptions}
        />
        <SelectButton onClick={this.toggleSelectionPopUp} />
        <ActivitiesCount
          selectedActivitiesCount={selectedActivitiesCount}
          filteredActivitiesCount={filteredActivitiesCount}
        />
        {!!selectedActivitiesCount &&
          this.availableSections.map((section, idx) => (
            <SectionButton key={idx} {...section} />
          ))}
        {this.shouldShowActivitiesSetNameInput && (
          <NewSetModal
            newSetName={editablePresetName}
            selectedActivitiesCount={selectedActivitiesCount}
            clickOnApply={this.clickOnSaveActivitySet}
            clickOnCancel={this.toggleActivitiesSetNameInput}
            onNameChange={onNameChange}
            onNameReset={onNameReset}
          />
        )}
        <div
          className={classList({
            'arrows-holder': true,
            'default-progress': shouldUseDefaultCollapseStateForProgress,
            'not-default-progress': !shouldUseDefaultCollapseStateForProgress,
          })}
          style={{ left: activityNameSectionWidth - ADDITIONAL_OFFSET_WIDTH }}
        >
          <div>
            <div
              className={classList({
                'collapse-menu-progress': true,
                'default-progress': shouldUseDefaultCollapseStateForProgress,
                'not-default-progress':
                  !shouldUseDefaultCollapseStateForProgress,
              })}
              onClick={toggleProgressBarCollapseState}
            >
              <div className="arrow-right">
                <Icons.OffloadingEquipmentMenuRight />
              </div>
              <div className="arrow-left">
                <Icons.OffloadingEquipmentMenuLeft />
              </div>
            </div>
            {viewMode === ViewModes.List && (
              <>
                <div
                  className={classList({
                    'collapse-menu-assignee': true,
                    'collapsed-assignee': shouldCollapseAssignee,
                  })}
                  onClick={toggleAssigneeBarCollapseState}
                  style={{ left: assigneeArrowOffset }}
                >
                  <div className="arrow-right">
                    <Icons.OffloadingEquipmentMenuRight />
                  </div>
                  <div className="arrow-left">
                    <Icons.OffloadingEquipmentMenuLeft />
                  </div>
                </div>
                <div
                  className={classList({
                    'collapse-menu-duration': true,
                    'collapsed-duration': shouldCollapseDuration,
                  })}
                  onClick={toggleDurationBarCollapseState}
                  style={{ left: durationArrowOffset }}
                >
                  <div className="arrow-right">
                    <Icons.OffloadingEquipmentMenuRight />
                  </div>
                  <div className="arrow-left">
                    <Icons.OffloadingEquipmentMenuLeft />
                  </div>
                </div>
                <div
                  className={classList({
                    'collapse-menu-planned-schedule': true,
                    'collapsed-planned-schedule': shouldCollapsePlannedSchedule,
                  })}
                  onClick={togglePlannedScheduleBarCollapseState}
                  style={{ left: plannedScheduleArrowOffset }}
                >
                  <div className="arrow-right">
                    <Icons.OffloadingEquipmentMenuRight />
                  </div>
                  <div className="arrow-left">
                    <Icons.OffloadingEquipmentMenuLeft />
                  </div>
                </div>
                <div
                  className={classList({
                    'collapse-menu-actual-schedule': true,
                    'collapsed-actual-schedule': shouldCollapseActualSchedule,
                  })}
                  onClick={toggleActualScheduleBarCollapseState}
                  style={{ left: actualScheduleArrowOffset }}
                >
                  <div className="arrow-right">
                    <Icons.OffloadingEquipmentMenuRight />
                  </div>
                  <div className="arrow-left">
                    <Icons.OffloadingEquipmentMenuLeft />
                  </div>
                </div>
                <div
                  className={classList({
                    'collapse-menu-comments': true,
                    'collapsed-comments': shouldCollapseComments,
                  })}
                  onClick={toggleCommentsCollapseState}
                  style={{ left: commentsArrowOffset }}
                >
                  <div className="arrow-right">
                    <Icons.OffloadingEquipmentMenuRight />
                  </div>
                  <div className="arrow-left">
                    <Icons.OffloadingEquipmentMenuLeft />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }

  private get selectAllTitle(): string {
    const { filteredActivities } = this.props.store
    const filteredActivitiesCount = filteredActivities.length

    return `Select All (${filteredActivitiesCount})`
  }

  private get availableSections(): IBulkActionBarSection[] {
    const { userActiveProjectSettings } = this.props.state

    const { isAdmin, isActivityUpdateAvailable } = userActiveProjectSettings

    const sections = [
      {
        label: followSectionName,
        icon: <Icons.UnfilledStar className="stroke-icon" />,
        onClick: this.clickOnFollow,
        isActive: false,
        isDisabled: false,
      },
      {
        label: assignSectionName,
        icon: <Icons.FilledStar className="fill-icon" />,
        onClick: this.showResponsibilityPanel,
        isActive: false,
        isDisabled: false,
      },
      {
        label: saveSectionName,
        icon: <Icons.SaveMenu className="fill-icon" />,
        onClick: this.clickOnSave,
        isActive: false,
        isDisabled: false,
      },
      {
        label: editSectionName,
        icon: <Icons.Edit className="fill-icon" />,
        onClick: this.clickOnEdit,
        isActive: false,
        isDisabled: !isActivityUpdateAvailable,
      },
      {
        label: deleteSectionName,
        icon: <Icons.Delete className="fill-icon" />,
        onClick: this.clickOnDelete,
        isActive: false,
        isDisabled: !isAdmin,
      },
    ]

    return sections.filter(section => !section.isDisabled)
  }

  @action.bound
  private toggleSelectionPopUp() {
    this.isSelectionPopUpDisplayed = !this.isSelectionPopUpDisplayed
  }

  @action.bound
  private deselectAll() {
    this.props.store.deselectAll()
    this.isSelectionPopUpDisplayed = false
  }

  @action.bound
  private selectAll() {
    this.props.store.selectAll()
    this.isSelectionPopUpDisplayed = false
  }

  @action.bound
  private clickOnFollow() {
    const { activityFollowingsStore, store } = this.props
    activityFollowingsStore.toggleBulkFollowings(store.selectedActivitiesIds)
  }

  @action.bound
  private showResponsibilityPanel() {
    const { activityListStore } = this.props
    activityListStore.toggleResponsibilitiesPanel()
  }

  @action.bound
  private clickOnSave() {
    const { resetActivitiesSetName } = this.props.activityPresetsStore
    resetActivitiesSetName()
    this.toggleActivitiesSetNameInput()
  }

  @action.bound
  private clickOnEdit() {
    const { activityListStore } = this.props
    activityListStore.toggleBulkEditPanel()
  }

  @action.bound
  private clickOnDelete() {
    this.props.activityListStore.toggleRemoveActivitiesDialog()
  }

  @action.bound
  private deleteActivities() {
    this.props.store.removeSelectedActivities()
    this.props.activityListStore.toggleRemoveActivitiesDialog()
  }

  @action.bound
  private toggleActivitiesSetNameInput() {
    this.shouldShowActivitiesSetNameInput =
      !this.shouldShowActivitiesSetNameInput
    const section = this.availableSections.find(
      s => s.label === saveSectionName,
    )

    section.isActive = this.shouldShowActivitiesSetNameInput
  }

  @action.bound
  private clickOnSaveActivitySet() {
    const { editablePresetName, saveOne } = this.props.activityPresetsStore
    const { selectedActivitiesIds } = this.props.store
    if (!editablePresetName) {
      return
    }
    saveOne(selectedActivitiesIds)
    this.toggleActivitiesSetNameInput()
  }
}
