import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { CategoryName } from '~/client/graph'
import Checkbox from '~/client/src/shared/components/Checkbox'

import CategoryFilterStore from '../CategoryFilter.store'

// localization: no display text to translate

interface ICategoryOption {
  id: CategoryName
  displayName: string
  icon: JSX.Element
  store: CategoryFilterStore
}

@observer
export default class FilterItem extends React.Component<ICategoryOption> {
  public render() {
    const {
      onCheckboxClick,
      selectedCategories,
      categoryToActivitiesCountMap,
    } = this.props.store
    const { id, displayName, icon } = this.props

    return (
      <div
        key={id}
        className={classList({
          'option-row my10 row': true,
          'bb-light-input-border pb7': id === CategoryName.UserAssociated,
        })}
      >
        <Checkbox
          isChecked={selectedCategories.includes(id)}
          onClick={onCheckboxClick.bind(this, id)}
        />
        <div className="row">
          <div className="icon-cell col x-center pt5">
            <div
              className={classList({
                'late col x-center brada10 mb5': id === CategoryName.Late,
              })}
            >
              {icon}
            </div>
          </div>
          <div className="name-cell text large light">{displayName}</div>
          <div className="amount-cell text large primary-blue right">
            {categoryToActivitiesCountMap[id]}
          </div>
        </div>
      </div>
    )
  }
}
