import { enumToList } from '~/client/src/shared/utils/converters'

enum LogisticsListTabs {
  CLOSURES = 'Closures',
  SENSORS = 'Sensors',
}

export const logisticsListTabsList = enumToList(LogisticsListTabs)

export default LogisticsListTabs
