import * as React from 'react'

import { classList } from 'react-classlist-helper'

interface IProps {
  hierarchyChains: string[]
  className?: string
}

export const HIERARCHY_SEPARATOR = ' / '

export default class HierarchyChains extends React.PureComponent<IProps> {
  public static defaultProps = {
    className: '',
  }

  private static renderHierarchyChain(chain: string[]): JSX.Element {
    if (!chain) {
      return null
    }

    const chainToDisplay = chain.reverse().join(HIERARCHY_SEPARATOR)

    return (
      <div
        key={chainToDisplay}
        title={chainToDisplay}
        className="text-ellipsis"
      >
        {chainToDisplay}
      </div>
    )
  }

  public render() {
    const { hierarchyChains, className } = this.props

    return (
      <div
        className={classList({
          'col hierarchy-chains-container': true,
          [className]: true,
        })}
      >
        {HierarchyChains.renderHierarchyChain(hierarchyChains)}
      </div>
    )
  }
}
