import {
  CheckUserByPhoneNumberDocument,
  ResendInviteKeyDocument,
} from '~/client/graph/operations/generated/Auth.generated'
import Config from '~/client/src/shared/Config'
import InfoCode from '~/client/src/shared/enums/InfoCode'
import ApiHelper, { IResponseData } from '~/client/src/shared/utils/ApiHelper'

import AuthEndpoints from '../enums/AuthEndpoints'
import Localization from '../localization/LocalizationManager'
import InitialState from '../stores/InitialState'
import GraphExecutor from '../utils/GraphExecutor'

export default class ApiAuthService {
  public constructor(
    private readonly state: InitialState,
    private readonly graphExecutor: GraphExecutor,
  ) {}

  public async loginWithInviteKey(inviteKey?: string): Promise<IResponseData> {
    if (!inviteKey) {
      throw new Error(InfoCode.INVALID_INVITE_TOKEN.toString())
    }

    const response = await fetch(
      Config.AUTH_EXTERNAL_URL + AuthEndpoints.INVITE_KEY,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Tenant-Name': Config.TENANT_ID,
        },
        body: JSON.stringify({ inviteKey }),
      },
    )

    if (response.ok) {
      return ApiHelper.toResponseData(response)
    }

    throw new Error(response.status.toString())
  }

  public async getInviteKey(accessToken: string): Promise<string> {
    const response = await fetch(
      Config.AUTH_EXTERNAL_URL + AuthEndpoints.INVITE_KEY,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Tenant-Name': Config.TENANT_ID,
        },
      },
    )

    if (response.ok) {
      return (await ApiHelper.toResponseData(response)).data
    }

    throw new Error(response.status.toString())
  }

  public async checkUserByPhoneNumber(phoneNumber: string): Promise<boolean> {
    const res = await this.graphExecutor.executeQuery(
      CheckUserByPhoneNumberDocument,
      { phoneNumber },
    )

    return !!res.data?.checkPhoneNumber
  }

  public async resendInviteKey(expiredInviteKey: string): Promise<void> {
    const res = await this.graphExecutor.executeMutation(
      ResendInviteKeyDocument,
      { inviteKey: expiredInviteKey, projectCode: this.state.initProjectCode },
    )

    if (!res.data?.resendInviteKey) {
      throw new Error(
        Localization.translator.somethingWentWrongDuringAPIInteraction,
      )
    }
  }
}
