import { action, computed, observable } from 'mobx'
import { Socket } from 'socket.io-client'

import UserProject from '../../../models/UserProject'
import ChatService from '../../../services/ChatService/Chat.service'
import ChatEvent from '../../../services/ChatService/events'
import {
  IChat,
  IMessage,
  IMessageReceivedEvent,
} from '../../../services/ChatService/types'
import CompaniesStore from '../../../stores/domain/Companies.store'
import ProjectMembersStore from '../../../stores/domain/ProjectMembers.store'
import ProjectRolesStore from '../../../stores/domain/ProjectRoles.store'
import UserProjectsStore from '../../../stores/domain/UserProjects.store'
import { mapFiltered } from '../../../utils/util'

export default class InboxStore {
  @observable public chats: IChat[] = []
  @observable public onlineContacts: Set<string> = null
  @observable public lastMessageByChannel: Map<string, IMessage> = new Map()

  @observable public contactName: Map<string, string> = new Map()
  @observable public isLoading: boolean = false

  @observable public searchKey: string = ''

  public constructor(
    private readonly chatService: ChatService,
    onlineContacts: Set<string>,
    private projectMembersStore: ProjectMembersStore,
    private userProjectsStore: UserProjectsStore,
    private companiesStore: CompaniesStore,
    private projectRolesStore: ProjectRolesStore,
  ) {
    this.onlineContacts = onlineContacts

    this.registerListeners()
  }

  public async init() {
    this.isLoading = true

    try {
      this.chats = await this.chatService.getInbox()
      await this.loadLastMessageByChannels()
    } catch (e) {
      alert(e)
    } finally {
      this.isLoading = false
    }

    this.loadAdditionalDataInBackground()
  }

  @computed
  public get sortedAndFilteredChats(): IChat[] {
    const searchKey = this.searchKey.toLowerCase()

    return this.chats
      .slice()
      .filter(c => c.name.toLowerCase().includes(searchKey))
      .sort(
        (c1, c2) =>
          (this.lastMessageByChannel.get(c2.id)?.createdAt || 0) -
            (this.lastMessageByChannel.get(c1.id)?.createdAt || 0) ||
          c2.createdAt - c1.createdAt,
      )
  }

  public getDirectChatRecipientId(memberIds: string[]) {
    return memberIds.find(id => id !== this.chatService.userId)
  }

  public isContactOnline(contactId: string): boolean {
    return this.onlineContacts.has(contactId)
  }

  @computed
  public get subtitleByChatId(): { [key: string]: string } {
    return this.chats.reduce((acc, chat) => {
      let subtitle = ''

      if (chat.isGroup) {
        const involvedCompanyNames = [
          ...new Set(
            chat.memberIds
              .map(mid => {
                const user = this.projectMembersStore.getById(mid)
                if (!user) {
                  return ''
                }

                return UserProject.getCompanyName(
                  user,
                  this.userProjectsStore,
                  this.companiesStore,
                  '',
                )
              })
              .filter(m => !!m),
          ),
        ]

        const memberPortion = `${chat.memberIds.length} members`

        if (!involvedCompanyNames.length) {
          subtitle = memberPortion
        } else if (involvedCompanyNames.length === 1) {
          subtitle = `${memberPortion}, (${involvedCompanyNames[0]})`
        } else {
          subtitle = `${memberPortion}, (${involvedCompanyNames.length} companies)`
        }
      } else {
        const recipientId = this.getDirectChatRecipientId(chat.memberIds)
        const recipientAsProjectMember =
          this.projectMembersStore.getById(recipientId)

        if (recipientAsProjectMember) {
          const companyName = UserProject.getCompanyName(
            recipientAsProjectMember,
            this.userProjectsStore,
            this.companiesStore,
          )

          const role = UserProject.getAllRolesAsString(
            recipientAsProjectMember,
            this.userProjectsStore,
            this.projectRolesStore,
          )

          subtitle = [companyName, role].filter(i => !!i).join(', ')
        }
      }

      acc[chat.id] = subtitle
      return acc
    }, {})
  }

  private onMessageReceived = ({ message }: IMessageReceivedEvent) => {
    const chat = this.chats.find(chat => chat.id === message.channelId)

    if (chat) {
      this.lastMessageByChannel.set(message.channelId, message)

      if (message.authorId !== this.chatService.userId) {
        chat.unreadMessagesCount = (chat.unreadMessagesCount || 0) + 1
      }

      if (chat.isGroup && !this.contactName.has(message.authorId)) {
        this.chatService
          .getContactById(message.authorId)
          .then(({ id, firstName }) => this.contactName.set(id, firstName))
      }
    }
  }

  private onChatCreated = (chat: IChat) => {
    this.chats.push(chat)
  }

  private onChatUpdated = (chat: IChat) => {
    const isChatAvailableForActiveUser = chat.memberIds.includes(
      this.chatService.userId,
    )

    const updatingChatIndex = this.chats.findIndex(c => c.id === chat.id)
    if (updatingChatIndex !== -1) {
      if (isChatAvailableForActiveUser) {
        Object.assign(this.chats[updatingChatIndex], chat)
      } else {
        this.chats.splice(updatingChatIndex, 1)
      }
    } else if (isChatAvailableForActiveUser) {
      this.chats.push(chat)
    }
  }

  private get socket(): Socket {
    return this.chatService.socket
  }

  public removeListeners() {
    this.listenersMatrix.forEach(listener => this.socket.off(...listener))
  }

  private registerListeners() {
    this.listenersMatrix.forEach(listener => this.socket.on(...listener))
  }

  private get listenersMatrix(): [string, (value: any) => void][] {
    return [
      [ChatEvent.MessageReceived, this.onMessageReceived],
      [ChatEvent.ChatCreated, this.onChatCreated],
      [ChatEvent.ChatUpdated, this.onChatUpdated],
    ]
  }

  @action
  private async loadLastMessageByChannels() {
    const lastMessageIds = mapFiltered(this.chats, chat => chat.lastMessageId)

    this.lastMessageByChannel = new Map(
      (await this.chatService.getMessagesByIds(lastMessageIds)).map(m => [
        m.channelId,
        m,
      ]),
    )
  }

  private loadAdditionalDataInBackground() {
    this.chats.forEach(chat => {
      const message = this.lastMessageByChannel.get(chat.id)

      if (message && chat.isGroup && !this.contactName.has(message.authorId)) {
        this.contactName.set(message.authorId, '')

        this.chatService
          .getContactById(message.authorId)
          .then(({ id, firstName }) => this.contactName.set(id, firstName))
      }

      this.chatService
        .getUnreadMessagesCountByChannel(chat.id)
        .then(count => (chat.unreadMessagesCount = count))
    })
  }
}
