import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import ChatService from '../../services/ChatService/Chat.service'
import InitialState from '../../stores/InitialState'
import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'
import TagsStore from '../../stores/domain/Tags.store'
import BaseCompactPopup from '../BaseCompactPopup/BaseCompactPopup'
import { View } from '../Layout'
import ChatStore from './Chat.store'
import ChatCreationMenu from './components/ChatCreationMenu'
import ChatCreationMenuStore from './components/ChatCreationMenu.store'
import ChatLog from './components/ChatLog'
import Inbox from './components/Inbox'

// localization: untranslated due to V0 (designs still are being changed)

export interface IProps {
  onClose?: () => void
  onChatLogMount?: () => void
  onChatLogUnmount?: () => void
  onChatCreationMenuMount?: () => void
  onChatCreationMenuUnmount?: () => void

  shouldMessageBeSentOnEnter?: boolean
  recipientIdForInit?: string

  state?: InitialState
  chatService?: ChatService
  projectMembersStore?: ProjectMembersStore
  tagsStore?: TagsStore
}

@inject('state', 'chatService', 'projectMembersStore', 'tagsStore')
@observer
export default class Chat extends React.Component<IProps> {
  private readonly store: ChatStore = null
  private readonly creationMenuStore: ChatCreationMenuStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new ChatStore(props.chatService)
    this.creationMenuStore = new ChatCreationMenuStore(
      props.chatService,
      this.store.handleChatOpenCb,
      props.projectMembersStore,
      props.tagsStore,
    )
  }

  public async componentDidMount() {
    await this.store.init()

    if (this.props.recipientIdForInit) {
      this.initializeChatWithRecipient(this.props.recipientIdForInit)
    }
  }

  public componentDidUpdate() {
    if (this.props.recipientIdForInit) {
      this.initializeChatWithRecipient(this.props.recipientIdForInit)
    }
  }

  public render() {
    return (
      <View>
        <BaseCompactPopup
          isShown={this.store.shouldShowChatCreationMenu}
          title={this.creationMenuStore.headerTitle}
          subtitle={this.creationMenuStore.headerSubtitle}
          onHide={this.store.hideChatCreationMenu}
          customLeftIcon={
            this.creationMenuStore.isGroupMembersSelectionCompleted && (
              <Icon
                icon={IconNames.CHEVRON_LEFT}
                className="text blue"
                onClick={this.creationMenuStore.cancelGroupMembersSelection}
              />
            )
          }
          isActionButtonEnabled={this.creationMenuStore.isNextStepAvailable}
          onActionButtonClick={this.creationMenuStore.goToNextStep}
          actionButtonTitle={this.creationMenuStore.actionButtonTitle}
          onSearchValueChange={v => (this.creationMenuStore.searchKey = v)}
          searchValue={this.creationMenuStore.searchKey}
          shouldIncludeSearch={
            !this.creationMenuStore.isGroupMembersSelectionCompleted
          }
          onSearchValueClear={() => (this.creationMenuStore.searchKey = '')}
          searchPlaceholder="Search users"
          childrenClassName="col full-height"
          isLoading={this.creationMenuStore.isGroupChatBeingCreated}
        >
          {this.store.shouldShowChatCreationMenu && (
            <ChatCreationMenu
              store={this.creationMenuStore}
              onlineContacts={this.store.onlineContacts}
              onMount={this.props.onChatCreationMenuMount}
              onUnmount={this.props.onChatCreationMenuUnmount}
            />
          )}
        </BaseCompactPopup>
        {this.store.selectedInboxItem ? (
          <ChatLog
            shouldBeSentOnEnter={this.props.shouldMessageBeSentOnEnter}
            onlineContacts={this.store.onlineContacts}
            chat={this.store.selectedInboxItem}
            onBackClick={this.store.resetChannel}
            onMount={this.props.onChatLogMount}
            onUnmount={this.props.onChatLogUnmount}
          />
        ) : (
          <div className="col full-height overflow-hidden">
            {!!this.props.onClose && (
              <span className="no-grow px15 pb15">
                <Icon
                  className="pointer text blue"
                  icon={IconNames.CROSS}
                  onClick={this.props.onClose}
                />
              </span>
            )}
            <Inbox
              onNewChat={this.store.showChatCreationMenu}
              onlineContacts={this.store.onlineContacts}
              selectInboxItem={this.store.selectInboxItem}
            />
          </div>
        )}
      </View>
    )
  }

  public componentWillUnmount() {
    this.store.removeListeners()
  }

  @action.bound
  private initializeChatWithRecipient(recipientId: string) {
    const recipient = this.props.projectMembersStore.getById(recipientId)

    this.store.showChatCreationMenu()
    this.creationMenuStore.openDirectChannel(recipient)

    this.props.state.resetRecipientIdForChatInit()
  }
}
