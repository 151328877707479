import { action } from 'mobx'

import Activity from '~/client/src/shared/models/Activity'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import Guard from '~/client/src/shared/utils/Guard'

import StatusUpdatesStore from '../../stores/domain/StatusUpdates.store'

export default class StatusUpdateViewStore {
  public constructor(
    public statusUpdatesStore: StatusUpdatesStore,
    public activitiesStore: ActivitiesStore,
  ) {
    Guard.requireAll({
      statusUpdatesStore,
      activitiesStore,
    })
  }

  public get selectedActivity(): Activity {
    return this.activitiesStore.selectedActivity || Activity.none
  }

  @action.bound
  public getPercentCompleteByActivityAndCompany(companyName: string) {
    const statusUpdate =
      this.statusUpdatesStore.getLastStatusUpdateByActivityAndCompany(
        this.selectedActivity,
        companyName,
      )
    return statusUpdate.plannedPercentComplete
  }
}
