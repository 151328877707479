import { computed } from 'mobx'

import { LocationType } from '~/client/graph'
import {
  SaveBuildingsDocument,
  SaveLevelsDocument,
} from '~/client/graph/operations/generated/LocationType.generated'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import Building from '~/client/src/shared/models/LocationObjects/Building'
import ILocationAttributeDto from '~/client/src/shared/types/ILocationAttributeDto'

import Level from '../../models/LocationObjects/Level'
import LocationBase from '../../models/LocationObjects/LocationBase'
import LocationAttributesStoreBase from './LocationAttributesBase.store'

export default class BuildingsStore extends LocationAttributesStoreBase<Building> {
  protected saveMutation = SaveBuildingsDocument
  protected graphName = 'buildings'
  protected defaultIconName = MapViewLocationIcon.Building

  // for sitemap LBS hierarhy purposes
  @computed
  public get firstBuilding(): Building {
    return this.list.sort((a, b) => a?.name?.localeCompare(b?.name))[0] || null
  }

  // should save at least 1 level to building
  public async additionalSaveAction(
    item: Building,
    oldItem: Building,
  ): Promise<void> {
    const { levels: allLevels } = this.eventsStore.appState
    const levels = Array.from(allLevels.values()).filter(
      l => !l.isDeleted && l.isParent(item),
    )
    if (oldItem?.id || levels?.length) {
      return
    }

    const { id: projectId } = this.eventsStore.appState.activeProject
    const name = `${item.levelBaseName} ${1}`
    const level = new Level(
      null,
      name,
      item.color,
      MapViewLocationIcon.Level,
      projectId,
      LocationBase.generateCodeFromName(name),
      [],
      {
        parentType: LocationType.Building,
        parentId: item.id,
      },
    )

    level.isNameImplicit = true

    const {
      data: { saveManyLevels },
    } = await this.graphExecutorStore.mutate(SaveLevelsDocument, {
      levels: [level.getDto()],
    })

    const [savedLevel] = saveManyLevels

    item.levelsOrder = [savedLevel.id]
    item.groundLevel = savedLevel.id

    await this.graphExecutorStore.mutate(SaveBuildingsDocument, {
      buildings: [item.getDto()],
    })
  }

  public get byId() {
    const { buildings } = this.eventsStore.appState
    return buildings
  }

  protected fromDto(dto: ILocationAttributeDto): Building {
    return Building.fromDto(dto)
  }
}
