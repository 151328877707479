import { computed } from 'mobx'

import { ISitePermitInspectionChange } from '~/client/graph'
import PermitInspectionChange from '~/client/src/shared/models/PermitInspectionChange'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'

export default class PermitInspectionChangesStore {
  public constructor(private eventsStore: EventsStore) {}

  @computed
  public get list() {
    return Array.from(this.byId.values())
  }

  public get byId() {
    return this.eventsStore.appState.permitInspectionChanges
  }

  public clearList() {
    this.byId.clear()
  }

  public receiveList(list: ISitePermitInspectionChange[]) {
    this.clearList()
    this.updateFromList(list)
  }

  public updateFromList(list: ISitePermitInspectionChange[]) {
    list.forEach(dto => this.receiveOne(dto.id, dto))
  }

  public receiveOne(id: string, dto: ISitePermitInspectionChange) {
    if (dto) {
      const inspectionChange = PermitInspectionChange.fromDto(dto)
      this.byId.set(inspectionChange.id, inspectionChange)
    } else {
      this.byId.delete(id)
    }
  }

  public getChangesForInspections = (inspectionIds: string[]) => {
    return this.list
      .filter(sc => inspectionIds.includes(sc.inspectionId))
      .sort((a, b) => a.createdAt - b.createdAt)
  }

  public getLatestChangeForInspection = (inspectionId: string) => {
    const changes = this.list
      .filter(sc => sc.inspectionId === inspectionId)
      .sort((a, b) => a.createdAt - b.createdAt)
    return changes[changes.length - 1]
  }
}
