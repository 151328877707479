import * as React from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

export interface IProps {
  onClick: () => void
  onLongSelect: () => void
}

@observer
export default class LongPressWrapper extends React.Component<IProps> {
  private timerId: number
  @observable private isLongPress: boolean = false
  public render() {
    return (
      <div
        onClick={this.handleOnClick}
        onMouseDown={this.handleOnMouseDown}
        onMouseUp={this.handleOnMouseUp}
        onTouchStart={this.handleOnTouchStart}
        onTouchEnd={this.handleOnTouchEnd}
      >
        {this.props.children}
      </div>
    )
  }

  private handleOnMouseDown = () => {
    this.startPressTimer()
  }

  private handleOnMouseUp = () => {
    window.clearTimeout(this.timerId)
  }

  private handleOnTouchStart = () => {
    this.startPressTimer()
  }

  private handleOnTouchEnd = () => {
    window.clearTimeout(this.timerId)
  }

  private startPressTimer = () => {
    if (!this.props?.onLongSelect) {
      return
    }

    this.isLongPress = false
    this.timerId = window.setTimeout(() => {
      this.isLongPress = true
      this.props.onLongSelect()
    }, 500)
  }

  private handleOnClick = () => {
    if (!this.isLongPress) {
      this.props.onClick()
    }
  }
}
