enum ConcreteDirectPayloadStatus {
  Ticketed = 'ticketed',
  Scheduled = 'scheduled',
  Recalled = 'recalled',
  Cancelled = 'cancelled',
  EnRoute = 'en_route',
  OnSite = 'on_site',
  Loading = 'loading',
  Pouring = 'pouring',
  Delivered = 'delivered',
}

export default ConcreteDirectPayloadStatus
