import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

import FileAttachment from '../WorkflowCard/FileAttachment'
import AnnouncementEditionFormStore from './AnnouncementEditionForm.store'
import AnnouncementTextEditor from './components/AnnouncementTextEditor'
import TextEditorToolbar from './components/TextEditorToolbar'

import './AnnouncementEditionFormContent.scss'

interface IProps {
  store: AnnouncementEditionFormStore
  isReadOnly?: boolean
  isScrollable?: boolean
}

const addTitle = 'Add title'

@observer
export default class AnnouncementEditionFormContent extends React.Component<IProps> {
  private titleTextArea: HTMLElement

  public componentDidMount() {
    this.autoResizeTextArea(this.titleTextArea)
  }

  public componentDidUpdate() {
    this.autoResizeTextArea(this.titleTextArea)
  }

  public render() {
    const { addAttachment } = this.props.store

    return (
      <div className="col full-height announcement-content y-start">
        {this.renderContentInput()}
        <TextEditorToolbar addAttachment={addAttachment} />
      </div>
    )
  }

  private renderContentInput(): JSX.Element {
    const { isReadOnly, isScrollable } = this.props
    const {
      applyContent,
      editableAnnouncement,
      removeAttachment,
      addAttachment,
    } = this.props.store
    const { attachments, content, title } = editableAnnouncement

    return (
      <div
        className={classList({
          'announcement-text-content full-height': true,
          'scrollable ios-scrolling-container': isScrollable,
        })}
      >
        <div className="row title-row">
          <Icons.Announcement
            className={classList({
              'announcement-title-icon no-grow col': true,
              'empty-title': !title,
            })}
          />
          <textarea
            className={classList({
              'announcement-title ba-none col': true,
            })}
            onChange={this.onTitleChange}
            placeholder={addTitle}
            value={title}
            ref={ref => (this.titleTextArea = ref)}
            rows={1}
            readOnly={isReadOnly}
          />
        </div>
        <div className="col x-center">
          <AnnouncementTextEditor
            content={content}
            applyContent={applyContent}
            addAttachment={addAttachment}
            isReadOnly={isReadOnly}
          />
          {attachments.map((attachment, idx) => (
            <FileAttachment
              key={idx}
              attachment={attachment}
              removeAttachment={!isReadOnly ? removeAttachment : null}
              shouldShowPdfPreview={true}
            />
          ))}
        </div>
      </div>
    )
  }

  private onTitleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.store.editableAnnouncement.title = event.target.value
  }

  private autoResizeTextArea = (target: HTMLElement) => {
    target.style.height = 'auto'
    target.style.height = `${target.scrollHeight + 8}px`
  }
}
