import { IDeliveryFilter, IDeliveryListCustomFilter } from '~/client/graph'
import {
  DeleteDeliveryCustomFilterDocument,
  SaveDeliveryCustomFilterDocument,
} from '~/client/graph/operations/generated/CustomDeliveryFilters.generated'
import CustomDeliveryListFilter from '~/client/src/shared/types/CustomDeliveryListFilter'

import BaseCustomListFiltersStore from '../BaseCustomListFilters.store'

export default class CustomDeliveryListFiltersStore extends BaseCustomListFiltersStore<
  CustomDeliveryListFilter,
  IDeliveryListCustomFilter
> {
  protected readonly saveMutationDoc = SaveDeliveryCustomFilterDocument
  protected readonly deleteMutationDoc = DeleteDeliveryCustomFilterDocument

  public get byId(): Map<string, CustomDeliveryListFilter> {
    return this.eventsStore.appState.customDeliveryListFilters
  }

  public getInstanceFromDto(
    dto: IDeliveryListCustomFilter,
  ): CustomDeliveryListFilter {
    return CustomDeliveryListFilter.fromDto(dto)
  }

  public createFromValues(
    filtersByFilterType: IDeliveryFilter[],
    isPublic: boolean,
    defaultForUsers: string[],
  ): CustomDeliveryListFilter {
    return new CustomDeliveryListFilter(
      null,
      '',
      isPublic,
      this.activeProjectId,
      this.activeUserId,
      defaultForUsers,
      filtersByFilterType,
    )
  }
}
