import * as React from 'react'

import { computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Checkbox from '~/client/src/shared/components/Checkbox'

import Building from '../../models/LocationObjects/Building'
import Level from '../../models/LocationObjects/Level'
import LevelsStore from '../../stores/domain/Levels.store'
import { sortLevels } from '../../utils/sortingFunctions'
import MenuCloser from '../MenuCloser'
import Location from '../SitemapAttributeSelector/Location'

import './BuildingLevelsSelector.scss'

const selectN = (n: number) => `Select ${n}`

interface IProps {
  building: Building
  selectedLevels: string[]
  allowedLevelIds?: string[]
  title?: string
  levelsStore?: LevelsStore
  onApply(newLevels: string[]): void
  onHide(): void
}

@inject('levelsStore')
@observer
export default class BuildingLevelsSelector extends React.Component<IProps> {
  @observable private selectedLevelIds: string[] = []

  public constructor(props: IProps) {
    super(props)

    this.selectedLevelIds = props.selectedLevels
  }

  public render() {
    const { title, onHide } = this.props

    return (
      <MenuCloser closeMenu={onHide}>
        <div className="building-levels-selector col">
          <div className="building-levels-selector-header no-grow row pa12">
            <div className="bold large text center">{title}</div>
            <button
              onClick={this.applySelector}
              className="ba-none brada4 no-grow pa10 building-levels-selector-submit pointer nowrap"
            >
              {selectN(this.selectedLevels.length)}
            </button>
          </div>
          <div className="scrollable mt5 pl12 pb12 full-height">
            {this.allowedLevels.map(dto => {
              const isItemSelected = this.isAttributeSelected(dto)
              return (
                <div className="y-center row px12 py8" key={dto.id}>
                  <div className="no-grow">
                    <Checkbox
                      className={classList({ checked: isItemSelected })}
                      isChecked={isItemSelected}
                      onClick={this.toggleCheckbox.bind(this, dto)}
                    />
                  </div>
                  <div>
                    <Location dto={dto} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </MenuCloser>
    )
  }

  @computed
  private get allowedLevels() {
    const { building, levelsStore, allowedLevelIds } = this.props

    let levels = levelsStore.list.filter(l => l.isParent(building))
    if (allowedLevelIds) {
      levels = levels.filter(l => allowedLevelIds.includes(l.id))
    }
    return (
      building?.sortLevelsByCustomOrder(levels) ||
      levels.sort((a, b) => sortLevels(b, a))
    )
  }

  private get selectedLevels() {
    return this.allowedLevels.filter(this.isAttributeSelected).map(l => l.id)
  }

  private isAttributeSelected = (dto: Level) => {
    return this.selectedLevelIds.includes(dto.id)
  }

  private toggleCheckbox(dto: Level) {
    let newValue = this.selectedLevels
    if (newValue.includes(dto.id)) {
      newValue = newValue.filter(id => id !== dto.id)
    } else {
      newValue.push(dto.id)
    }

    this.selectedLevelIds = newValue
  }

  private applySelector = () => {
    this.props.onApply(this.selectedLevelIds)
  }
}
