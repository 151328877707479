import {
  IWorkflowsCustomFilter,
  IWorkflowsCustomFilterInput,
  IWorkflowsFilter,
} from '~/client/graph'

export default class CustomWorkflowsFilter implements IWorkflowsCustomFilter {
  public static fromDto(dto: IWorkflowsCustomFilter) {
    const filtersByFilterType = dto.filtersByFilterType.map(
      ({ type, values }) => ({
        type,
        values: values || [],
      }),
    )

    return new CustomWorkflowsFilter(
      dto.id,
      dto.name,
      dto.isPublic,
      dto.projectId,
      dto.userId,
      dto.defaultForUsers,
      filtersByFilterType,
    )
  }

  public constructor(
    public id: string,
    public name: string,
    public isPublic: boolean,
    public readonly projectId: string,
    public readonly userId: string,
    public readonly defaultForUsers: string[],
    public readonly filtersByFilterType: IWorkflowsFilter[] = [],
  ) {}

  public toInput(): IWorkflowsCustomFilterInput {
    return this
  }
}
