import React from 'react'

import { observer } from 'mobx-react'

import ThemeMode from '../../../../utils/ThemeModeManager'
import PermitTypeIcon from '../../../PermitTypeIcon/PermitTypeIcon'
import GlobeViewBasePin from './GlobeViewBasePin'

import Colors from '~/client/src/shared/theme.module.scss'

interface IProps {
  itemsCount: number
  isDone: boolean
  shouldRenderCircle: boolean
  permitType: string
  isAssigned: boolean
  isSelected: boolean
  isCanceled: boolean
  onClick?: () => void
  left?: number
  right?: number
}

@observer
export default class GlobeViewPermitMapPin extends React.Component<IProps> {
  public render() {
    const { itemsCount, onClick, isSelected, left, right } = this.props
    const fillColor = ThemeMode.getHEXColor(this.pinFillColor)

    return (
      <GlobeViewBasePin
        onClick={onClick}
        itemsCount={itemsCount}
        color={this.iconColor}
        fillColor={fillColor}
        icon={this.iconByPermitType}
        isSelected={isSelected}
        left={left}
        right={right}
      />
    )
  }

  private get pinFillColor(): string {
    const { isDone, isAssigned, isCanceled, isSelected } = this.props

    if (isSelected) {
      return Colors.error50
    }
    if (isDone) {
      return Colors.neutral70
    }

    if (isCanceled) {
      return Colors.neutral50_4
    }

    if (isAssigned) {
      return Colors.warning70
    }

    return Colors.neutral0
  }

  private get iconColor(): string {
    const { isAssigned, isSelected, isDone, isCanceled } = this.props

    return ThemeMode.getHEXColor(
      isAssigned && !isSelected && !isDone && !isCanceled
        ? Colors.neutral0
        : Colors.neutral100,
    )
  }

  private get permitIconClassName(): string {
    const { isAssigned, isSelected, isDone, isCanceled } = this.props

    return isAssigned && !isSelected && !isDone && !isCanceled
      ? 'permit-white-icon'
      : 'permit-black-icon'
  }

  private get iconByPermitType(): JSX.Element {
    const { shouldRenderCircle, permitType } = this.props

    return (
      <PermitTypeIcon
        permitType={shouldRenderCircle ? null : permitType}
        className={`globe-pin-image row x-center y-center ${this.permitIconClassName}`}
      />
    )
  }
}
