import { IWorkBreakdownStructure } from '~/client/graph'
import Activity from '~/client/src/shared/models/Activity'
import ActivityOwnedEntity from '~/client/src/shared/models/ActivityOwnedEntity'

import BaseModel from './BaseModel'

export default class Wbs extends ActivityOwnedEntity<IWorkBreakdownStructure> {
  public static createPlaceholder(): Wbs {
    const w = new Wbs()
    w.name = '...'
    w.id = ''
    return w
  }

  public name: string
  public parentId: string
  public scheduleId: string

  public updateFromJson(json: IWorkBreakdownStructure) {
    this.name = json.name
    this.id = json.id
    this.parentId = json.parentId
    this.scheduleId = json.scheduleId
  }

  public isSameAs(other: BaseModel<IWorkBreakdownStructure>) {
    if (!other) {
      return false
    }
    return this.id === other.id
  }

  public belongsTo(a: Activity) {
    return a.wbs === this.id
  }

  public save() {
    return Promise.resolve()
  }

  public get asJson(): IWorkBreakdownStructure {
    return {
      name: this.name,
      scheduleId: this.scheduleId,
      id: this.id || null,
      parentId: this.parentId || null,
    }
  }
}
