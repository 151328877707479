import { action, computed, observable } from 'mobx'

import {
  IMutation,
  ISheetData,
  ISubscription,
  IUploadScheduleProgress,
  IXerProjectInfo,
  UploadScheduleStatus,
  UploadingType,
} from '~/client/graph'
import {
  GetExcelSheetsDocument,
  GetXerProjectsDocument,
  ListenToScheduleUploadDocument,
} from '~/client/graph/operations/generated/Schedule.generated'
import desktopRoutes from '~/client/src/desktop/constants/desktopRoutes'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import ProjectSetUpPageStore from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPage.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import {
  ACTIVATE_PROJECT,
  GET_SCHEDULE,
  RESTORE_SCHEDULE,
  UPDATE_ACTIVITY_FILTERS_SETTINGS,
  UPLOAD_EXCEL_SCHEDULE,
  UPLOAD_SCHEDULE,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivityCodesStore from '~/client/src/shared/stores/domain/ActivityCodes.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import { FileUploadingStore } from '~/client/src/shared/stores/domain/FileUploading.store'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import Guard from '~/client/src/shared/utils/Guard'
import { readFileAsTextAsync } from '~/client/src/shared/utils/util'

import ActivityFilterSetupDialogStore, {
  ActivityFilterSetupDialogType,
} from '../ActivityFilters/components/ActivityFilterSetupDialog.store'
import ScheduleUploadHistoryStore from './components/ScheduleUploadHistory/ScheduleUploadHistory.store'

const EXCEL_FILE_PATTERN = /\.xls[xm]$/
const WELL_KNOW_SHEET_NAME = 'StruxHub_Export'

export enum ScheduleStatus {
  NotUploaded,
  GettingXerProjects,
  GettingExcelSheets,
  UploadingSelectedProject,
  Failed,
  Ready,
}

const SCHEDULE_UPLOAD_KEY = 'schedule-upload'

type FileData = {
  name: string
  path: string
  content?: string
}

export default class ProjectScheduleUploadStore {
  @observable
  public ganttViewStatus = ScheduleStatus.NotUploaded

  @observable public isChooseProjectDialogShown: boolean = false
  @observable public uploadedXerProjects: IXerProjectInfo[] = []
  @observable public chosenXerProjectId: string = null
  @observable public isChooseSheetDialogShown: boolean = false
  @observable public mergeWithExistingData: boolean = true
  @observable public uploadedSheets: ISheetData[] = []
  @observable public chosenSheetName: string = null
  @observable public isMapFieldsDialogShown: boolean = false
  @observable public sheetColumns: string[] = []
  @observable public columnMap: any = {}

  public fields = [
    {
      name: 'Code',
      required: true,
      caption: () => Localization.translator.activityId,
    },
    {
      name: 'Name',
      required: true,
      caption: () => Localization.translator.activityName,
    },
    {
      name: 'PlannedStart',
      caption: () => Localization.translator.plannedActivityStart,
    },
    {
      name: 'PlannedFinish',
      caption: () => Localization.translator.plannedActivityFinish,
    },
    {
      name: 'ActualStart',
      caption: () => Localization.translator.actualActivityStart,
    },
    {
      name: 'ActualFinish',
      caption: () => Localization.translator.actualActivityFinish,
    },
    {
      name: 'Status',
      caption: () => Localization.translator.activityStatus,
    },
    {
      name: 'PercentComplete',
      caption: () => Localization.translator.activityPercentComplete,
    },
    {
      name: 'Resource',
      caption: () => Localization.translator.resourceName,
    },
    {
      name: 'Building',
      caption: () => Localization.translator.building,
    },
    { name: 'Level', caption: () => Localization.translator.level },
    { name: 'Area', caption: () => Localization.translator.area },
    { name: 'Zone', caption: () => Localization.translator.zone },
    { name: 'Trade', caption: () => Localization.translator.trade },
  ]

  @observable public isNoProjectsErrorDialogShown: boolean = false
  @observable private scheduleUploadProgress: IUploadScheduleProgress

  private fileData: FileData = null

  public get uploadScheduleResult() {
    return this.scheduleUploadHistoryStore.uploadScheduleResult
  }

  public constructor(
    private common: DesktopCommonStore,
    private activityFiltersStore: ActivityFiltersStore,
    private eventsStore: DesktopEventStore,
    private projectSetUpPageStore: ProjectSetUpPageStore,
    private scheduleUploadHistoryStore: ScheduleUploadHistoryStore,
    private filterSetupDialogStore: ActivityFilterSetupDialogStore,
    private activityCodesStore: ActivityCodesStore,
    private graphExecutorStore: GraphExecutorStore,
    private fileUploadingStore: FileUploadingStore,
  ) {
    Guard.requireAll({
      common,
      eventsStore,
      projectSetUpPageStore,
      scheduleUploadHistoryStore,
      graphExecutorStore,
      fileUploadingStore,
    })
  }

  @action.bound
  public hideErrorDialog() {
    this.isNoProjectsErrorDialogShown = false
  }

  @action.bound
  public resetInitialValue() {
    this.ganttViewStatus = ScheduleStatus.NotUploaded
    this.uploadedXerProjects = []
    this.chosenXerProjectId = null
    this.isChooseSheetDialogShown = false
    this.isMapFieldsDialogShown = false
    this.isChooseProjectDialogShown = false
    this.scheduleUploadHistoryStore.uploadScheduleResult = null
    this.scheduleUploadProgress = null
  }

  @action.bound
  public showChooseProjectDialog(projects: IXerProjectInfo[]) {
    this.uploadedXerProjects = projects
    this.chosenXerProjectId = null
    this.isChooseProjectDialogShown = true
  }

  @action.bound
  public applyChooseProjectDialog() {
    if (!this.chosenXerProjectId) {
      return
    }
    this.isChooseProjectDialogShown = false
    this.dispatchUploadSchedule(this.chosenXerProjectId)
  }

  @action.bound
  public chooseXerProjectId(xerProjectId: string) {
    this.chosenXerProjectId = xerProjectId
  }

  @action.bound
  public showChooseSheetDialog(sheets: ISheetData[]) {
    const wellKnownSheet = sheets.find(s => s.name === WELL_KNOW_SHEET_NAME)
    this.uploadedSheets = wellKnownSheet ? [wellKnownSheet] : sheets

    if (this.uploadedSheets.length === 1) {
      this.chosenSheetName = this.uploadedSheets[0].name
    }

    this.isChooseSheetDialogShown = true
  }

  @action.bound
  public applyChooseSheetDialog() {
    if (!this.chosenSheetName) {
      return
    }

    this.sheetColumns = this.uploadedSheets.find(
      s => s.name === this.chosenSheetName,
    ).columns

    this.columnMap = this.fields.reduce((map, { name }) => {
      if (this.sheetColumns.includes(name)) {
        map[name] = name
      }
      return map
    }, {})

    this.isChooseSheetDialogShown = false
    this.isMapFieldsDialogShown = true
  }

  @action.bound
  public addColumnMap(fieldName: string, fieldNameToMap: string) {
    if (fieldName) {
      this.columnMap[fieldName] = fieldNameToMap
    } else {
      delete this.columnMap[fieldName]
    }
  }

  @action.bound
  public async applyMapFieldsDialog() {
    this.isMapFieldsDialogShown = false

    this.ganttViewStatus = ScheduleStatus.UploadingSelectedProject
    await this.listenToScheduleUpload()
    this.eventsStore.dispatch(
      UPLOAD_EXCEL_SCHEDULE,
      this.fileData.name,
      this.fileData.path,
      {
        name: this.chosenSheetName,
        columns: Object.values(this.columnMap),
        columnsToMapTo: Object.keys(this.columnMap),
      },
      this.mergeWithExistingData,
      this.onFileSent,
    )
  }

  @action.bound
  public chooseSheetName(name: string) {
    this.chosenSheetName = name
  }

  @action.bound
  public setMergeWithExistingData(value: boolean) {
    this.mergeWithExistingData = value
  }

  public uploadFile = async (file: File) => {
    this.resetInitialValue()

    const [result] = await this.fileUploadingStore.uploadFile(
      file,
      UploadingType.Schedule,
      file.name,
    )
    this.handleUploadedFile(file, result.filePath)
  }

  public async handleUploadedFile(file: File, filePath: string) {
    this.fileData = { path: filePath, name: file.name }

    if (this.fileData.name.endsWith('.xer')) {
      this.ganttViewStatus = ScheduleStatus.GettingXerProjects
      this.fileData.content = await readFileAsTextAsync(file)

      try {
        const xerProjects = await this.getXerFileProjects()
        if (xerProjects.length < 1) {
          this.ganttViewStatus = ScheduleStatus.Failed
          throw new Error('No projects received.')
        }

        if (xerProjects.length === 1) {
          return this.dispatchUploadSchedule(xerProjects[0].id)
        }

        this.showChooseProjectDialog(xerProjects)
      } catch {
        this.isNoProjectsErrorDialogShown = true
        return this.resetInitialValue()
      }

      return
    }

    if (EXCEL_FILE_PATTERN.test(this.fileData.name)) {
      this.ganttViewStatus = ScheduleStatus.GettingExcelSheets
      try {
        const sheets = await this.getExcelFileSheet()
        this.showChooseSheetDialog(sheets)
      } catch {
        this.isNoProjectsErrorDialogShown = true
        return this.resetInitialValue()
      }
    }
  }

  public async getXerFileProjects(): Promise<IXerProjectInfo[]> {
    const result = await this.graphExecutorStore.query(GetXerProjectsDocument, {
      file: this.fileData.content,
    })

    if (result.error) {
      this.ganttViewStatus = ScheduleStatus.Failed
      return
    }

    return result.data.xerProjects
  }

  public async getExcelFileSheet(): Promise<ISheetData[]> {
    const result = await this.graphExecutorStore.query(GetExcelSheetsDocument, {
      fullFilePath: this.fileData.path,
    })

    if (result.error) {
      this.ganttViewStatus = ScheduleStatus.Failed
      return
    }

    return result.data.excelSheets
  }

  public get submitButtonTitle() {
    switch (this.ganttViewStatus) {
      case ScheduleStatus.NotUploaded:
        return Localization.translator.scheduleIsNotUploaded
      case ScheduleStatus.GettingXerProjects:
        return Localization.translator.gettingXerProjects
      case ScheduleStatus.GettingExcelSheets:
        return Localization.translator.gettingExcelSheets
      case ScheduleStatus.UploadingSelectedProject:
      case ScheduleStatus.Failed:
        if (!this.scheduleUploadProgress) {
          return this.ganttViewStatus === ScheduleStatus.Failed
            ? Localization.translator.noActivitiesFound
            : Localization.translator.parsingTheScheduleFileStarted
        }

        const { status, failedStep, itemsCount, savedItemsCount, failReason } =
          this.scheduleUploadProgress
        if (!status) {
          return failReason
        }

        return this.getUploadProgress(
          status,
          failedStep,
          itemsCount,
          savedItemsCount,
          failReason,
        )

      case ScheduleStatus.Ready:
        return Localization.translator.seeGanttInStruxHub
      default:
        return Localization.translator.scheduleIsNotUploaded
    }
  }

  @action.bound
  public navigateToGantt() {
    if (this.ganttViewStatus !== ScheduleStatus.Ready) {
      return
    }

    localStorage.setItem('ganttViewMode', 'onBoarding')
    this.common._displayView(desktopRoutes.ACTIVITIES)
  }

  @computed
  public get canNavigateNextStep() {
    return this.projectSetUpPageStore.isAllowedToNavigate(
      this.projectSetUpPageStore.nextPage,
    )
  }

  @action.bound
  public navigateToNextStep() {
    this.projectSetUpPageStore.navigateToNextStep()
  }

  @action.bound
  public async onHiddenActivityCodesClosed() {
    if (!this.fileData) {
      return this.eventsStore.dispatch(UPDATE_ACTIVITY_FILTERS_SETTINGS)
    }

    this.scheduleUploadHistoryStore.showUploadedScheduleSummaryDialog()

    this.activityFiltersStore.setDefaultActivityCodeFilters()
    this.activityFiltersStore.setDefaultActivityLocationRelationships()

    this.fileData = null
  }

  @computed
  public get configuredHiddenActivityCodeNames(): string[] {
    const { hiddenActivityCodeIds } = this.activityFiltersStore.settings

    return this.activityCodesStore
      .getShortNamesFromIds(hiddenActivityCodeIds)
      .filter(name => name)
  }

  @action.bound
  public showHiddenActivityCodesConfiguration() {
    const { hiddenActivityCodeTypesIds, hiddenActivityCodeIds } =
      this.activityFiltersStore.settings

    this.filterSetupDialogStore.selectedFilterDialogCategoryIds =
      hiddenActivityCodeTypesIds.slice()
    this.filterSetupDialogStore.selectedFilterDialogItemIds =
      hiddenActivityCodeIds.slice()

    this.filterSetupDialogStore.activityFilterSetupDialogType =
      ActivityFilterSetupDialogType.HiddenActivityCodes
    this.filterSetupDialogStore.isSingleSelectMode = false
    this.filterSetupDialogStore.isActivityFilterSetupDialogShown = true
  }

  public stopListeningToScheduleUpload() {
    this.graphExecutorStore.terminateSubscription(SCHEDULE_UPLOAD_KEY)
    this.scheduleUploadProgress = null
  }

  private getUploadProgress(
    status: UploadScheduleStatus,
    failedStep?: UploadScheduleStatus,
    allItemsCount?: number,
    savedItemsCount?: number,
    failReason?: string,
  ) {
    switch (status) {
      case UploadScheduleStatus.ReadingFile:
        return Localization.translator.readingScheduleFile
      case UploadScheduleStatus.ExtractingData:
        return (
          Localization.translator.extractingScheduleData +
          (allItemsCount
            ? ': ' + Localization.translator.xEntriesInTheFile(allItemsCount)
            : '')
        )
      case UploadScheduleStatus.SavingActivities:
        return (
          Localization.translator.savingScheduleDataCollections +
          (allItemsCount
            ? ': ' +
              Localization.translator.xOfYEntriesSaved(
                savedItemsCount,
                allItemsCount,
              )
            : '')
        )
      case UploadScheduleStatus.RecalculateStatusUpdates:
        return Localization.translator.recalculatingStatusUpdates
      case UploadScheduleStatus.SavingSchedule:
        return Localization.translator.savingSchedule
      case UploadScheduleStatus.Failed:
        console.error(failReason)
        return (
          Localization.translator.uploadingScheduleFailedAt +
          ': ' +
          this.getUploadProgress(failedStep)
        )
      case UploadScheduleStatus.Done:
        return Localization.translator.uploadingScheduleDone
    }
  }

  private async dispatchUploadSchedule(xerProjectId: string) {
    this.ganttViewStatus = ScheduleStatus.UploadingSelectedProject
    await this.listenToScheduleUpload()
    this.eventsStore.dispatch(
      UPLOAD_SCHEDULE,
      this.fileData.name,
      xerProjectId,
      this.fileData.content,
      this.onFileSent,
    )
  }

  @action.bound
  private onFileSent({ uploadSchedule, uploadExcelSchedule }: IMutation) {
    const errorMessage = uploadSchedule || uploadExcelSchedule
    if (!errorMessage) {
      return
    }

    this.graphExecutorStore.terminateSubscription(SCHEDULE_UPLOAD_KEY)
    this.scheduleUploadProgress = {
      failReason: errorMessage,
    } as IUploadScheduleProgress
    this.ganttViewStatus = ScheduleStatus.Failed
  }

  @action.bound
  private listenToScheduleUpload() {
    return this.graphExecutorStore.subscribe(
      ListenToScheduleUploadDocument,
      { projectId: this.projectId },
      false,
      SCHEDULE_UPLOAD_KEY,
      ({ uploadScheduleProgress: { item } }: ISubscription) => {
        this.scheduleUploadProgress = item
        const { status } = this.scheduleUploadProgress
        if (
          status !== UploadScheduleStatus.Done &&
          status !== UploadScheduleStatus.Failed
        ) {
          return
        }

        this.graphExecutorStore.terminateSubscription(SCHEDULE_UPLOAD_KEY)
        if (
          status === UploadScheduleStatus.Done &&
          this.scheduleUploadProgress.uploadResult
        ) {
          this.onScheduleUploaded()
        } else {
          this.ganttViewStatus = ScheduleStatus.Failed
        }
      },
    )
  }

  @action.bound
  private async onScheduleUploaded() {
    const result = this.scheduleUploadProgress.uploadResult
    this.scheduleUploadHistoryStore.uploadScheduleResult = result
    this.scheduleUploadProgress = null

    this.ganttViewStatus =
      result.schedule.activityQuantity <= 0
        ? ScheduleStatus.Failed
        : ScheduleStatus.Ready
    if (this.ganttViewStatus === ScheduleStatus.Ready) {
      const { loading } = this.eventsStore.appState
      loading.set(GET_SCHEDULE, true)
      this.showHiddenActivityCodesConfiguration()
    }
  }

  public get isScheduleRestoring() {
    const { loading } = this.eventsStore.appState
    return loading.get(RESTORE_SCHEDULE)
  }

  public get isProjectLoadingOrUpdating() {
    const { loading } = this.eventsStore.appState
    return (
      loading.get(ACTIVATE_PROJECT) ||
      loading.get(GET_SCHEDULE) ||
      this.projectSetUpPageStore.isHistoryLoading
    )
  }

  private get projectId(): string {
    return this.eventsStore.appState.activeProject.id
  }
}
