import * as React from 'react'

import { action } from 'mobx'
import { observer } from 'mobx-react'

import DeliveryRequestSetUpStore from '~/client/src/desktop/views/ProjectSetUp/components/ProjectWorkflowsSetUp/components/DeliveryRequestSetUp/DeliveryRequestSetUp.store'
import * as Icons from '~/client/src/shared/components/Icons'
import IDeliveryAttributeStore from '~/client/src/shared/stores/domain/interfaces/IDeliveryAttributeStore'

interface IAttributeNameEditorProps {
  newAttributeName?: string
  attributeId?: string
  deliveryRequestSetUpStore: DeliveryRequestSetUpStore
  clearNewAttributeName?: () => void
  store: IDeliveryAttributeStore
}

@observer
export default class AttributeNameEditor extends React.Component<IAttributeNameEditorProps> {
  public render() {
    return (
      <div className="inline-block mx10 pt7" onClick={this.onEditIconClicked}>
        {this.isAttributeBeingUpdated ? (
          <Icons.EditActive />
        ) : (
          <Icons.EditInactive />
        )}
      </div>
    )
  }

  @action.bound
  private onEditIconClicked() {
    const {
      attributeId,
      deliveryRequestSetUpStore,
      clearNewAttributeName,
      newAttributeName,
      store,
    } = this.props
    deliveryRequestSetUpStore.editingElementId = this.isAttributeBeingUpdated
      ? ''
      : attributeId
    if (!this.isAttributeBeingUpdated && newAttributeName) {
      store.updateName(attributeId, newAttributeName)
    }
    clearNewAttributeName()
  }

  private get isAttributeBeingUpdated(): boolean {
    const { attributeId, deliveryRequestSetUpStore } = this.props
    return deliveryRequestSetUpStore.editingElementId === attributeId
  }
}
