import * as React from 'react'

import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

interface IProps {
  isActive: boolean
  onClick: (event?) => void
  className?: string
  text?: string
}

export default class MentionTriggerButton extends React.PureComponent<IProps> {
  public render() {
    const { onClick, isActive, className = '', text } = this.props

    return (
      <div
        onClick={onClick}
        className={classList({
          'mention-action-button': true,
          'active unclickable-element': isActive,
          [className]: true,
        })}
      >
        <Icons.MentionButton className="no-grow bottom-bar-trigger-button-icon" />
        {text && <span className="text large">{text}</span>}
      </div>
    )
  }
}
