import React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import AnalyticsSettingsStore from '~/client/src/desktop/stores/domain/AnalyticsSettings.store'
import { Loader } from '~/client/src/shared/components/Loader'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'

import { Header } from '../SetupPageLayout/SetupPageLayout'
import AnalyticsSetupStore from './AnalyticsSetup.store'
import AnalyticsSetupList from './components/AnalyticsSetupList'
import ConfigureColumnModal from './components/ConfigureColumnModal/ConfigureColumnModal'

import './AnalyticsSetup.scss'

interface IProps {
  eventsStore?: DesktopEventStore
  permitTypesStore?: PermitTypesStore
  analyticsSettingsStore?: AnalyticsSettingsStore
}

@inject('eventsStore', 'permitTypesStore', 'analyticsSettingsStore')
@observer
export default class AnalyticsSetup extends React.Component<IProps> {
  private store: AnalyticsSetupStore = null

  public constructor(props: IProps) {
    super(props)
    this.store = new AnalyticsSetupStore(
      props.eventsStore,
      props.permitTypesStore,
      props.analyticsSettingsStore,
    )
  }

  public render() {
    const { isConfigureModalOpened, closeConfigureModal } = this.store

    return (
      <div className="relative project-details analytics-setting">
        <Header title={Localization.translator.analyticsSetting} />
        {!this.store.loading ? (
          <div className="setup-form-body">
            <div className="setup-form-parameter project-type-setup relative">
              {this.store.updating && !isConfigureModalOpened && (
                <div className="absolute full-height full-width">
                  <div className="row y-center full-height full-width absolute modal-overlay">
                    <Loader
                      className="fixed-loader col x-center"
                      hint={`${Localization.translator.updating}...`}
                    />
                  </div>
                </div>
              )}
              <AnalyticsSetupList store={this.store} />
            </div>
            {isConfigureModalOpened && (
              <MenuCloser closeMenu={closeConfigureModal}>
                <ConfigureColumnModal
                  onClose={closeConfigureModal}
                  analyticsSetupStore={this.store}
                />
              </MenuCloser>
            )}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    )
  }
}
