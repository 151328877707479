import * as React from 'react'

import { IPermitTypeField } from '~/client/graph'

interface IProps {
  typeField: IPermitTypeField
}

export default function PermitFormDividerField({
  typeField,
}: IProps): JSX.Element {
  return (
    <div className="row section-separator py20 overflow-hidden">
      <hr className="ba-none bt-light-input-border min-width30" />
      {typeField?.caption && (
        <span className="text small light no-white-space-wrap bold lp15 uppercase w-fit-content mx10 ellipsis flex-grow3">
          {typeField.caption}
        </span>
      )}
      <hr className="ba-none bt-light-input-border min-width30" />
    </div>
  )
}
