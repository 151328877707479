import * as React from 'react'

import * as Icons from '~/client/src/shared/components/Icons'
import { DefaultPermitType } from '~/client/src/shared/models/PermitType'

interface IProps {
  permitType: string
  className?: string
}

export default function PermitTypeIcon({ permitType, className = '' }: IProps) {
  if (!permitType) {
    return <Icons.GeneralForm className={className} />
  }

  switch (true) {
    case permitType.includes(DefaultPermitType.Hotwork):
      return <Icons.HotWorkForm className={className} />
    case permitType.includes(DefaultPermitType.Ladder):
      return <Icons.LadderForm className={className} />
    case permitType.includes(DefaultPermitType.EnergizedRoom):
      return <Icons.EnergizedForm className={className} />
    case permitType.includes(DefaultPermitType.Aerial):
      return <Icons.AerialInspectionForm className={className} />
    case permitType.includes(DefaultPermitType.FireExtinguisherInspection):
      return <Icons.FireExtinguisherForm className={className} />
    case permitType.includes(DefaultPermitType.SurfacePenetration):
    case permitType.includes(DefaultPermitType.InteriorConcretePenetration):
      return <Icons.PenetrationForm className={className} />
    case permitType.includes(DefaultPermitType.OffRoadHeavyEquipment):
      return <Icons.HeavyEquipmentForm className={className} />
    case permitType.includes(DefaultPermitType.EmissionForm):
      return <Icons.AirMitigationForm className={className} />
    case permitType.includes(DefaultPermitType.MaterialTransfer):
      return <Icons.MaterialTransferForm className={className} />
    default:
      return <Icons.GeneralForm className={className} />
  }
}
