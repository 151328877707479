import { ObservableMap, computed, observable } from 'mobx'

import {
  ContentObjectType,
  IContentObject,
  IContentObjectInput,
} from '~/client/graph'
import { ISaveContentObjectsMutation } from '~/client/graph/operations/generated/ContentObjects.generated'
import Activity from '~/client/src/shared/models/Activity'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'

import ContentObjectModel from '../../models/ContentObjectModel'
import EventsStore from '../EventStore/Events.store'
import { SAVE_CONTENT_OBJECTS } from '../EventStore/eventConstants'

interface ICreateAttrs<T> {
  activity: Activity
  threadId: string
  type?: T
  contentObjectType: ContentObjectType
}

export default abstract class ContentObjectsStore<
  Type,
  COType extends ContentObjectModel<Type>,
> {
  @observable public isDataReceived = false

  public constructor(
    private messagesStore: MessagesStore,
    protected eventsStore: EventsStore,
  ) {}

  public close(coModel: COType) {
    coModel.close()
    return this.save(coModel)
  }

  public toggle(coModel: COType) {
    coModel.toggle()
    return this.save(coModel)
  }

  public async create(attrs: ICreateAttrs<Type>) {
    if (!attrs.activity) {
      return Promise.reject(new Error('ContentObject: activity is required'))
    }

    const coModel = this.createAnInstance('')
    coModel.activityObjectId = attrs.activity.code
    coModel.author = this.currentUser.id
    coModel.threadId = attrs.threadId
    coModel.projectId = this.eventsStore.appState.activeProject.id
    coModel.contentObjectType = attrs.contentObjectType
    if (attrs.type) {
      coModel.type = attrs.type
    }
    return this.save(coModel)
  }

  public doesActivityHaveContentObject(activity: Activity) {
    return this.list.some(
      co => co.isOpen && co.activityObjectId === activity.code,
    )
  }

  public replyWithText(coModel: COType, text: string) {
    return this.messagesStore.post(text, coModel.threadId)
  }

  public receiveOne(id: string, dto: IContentObject) {
    if (dto) {
      const co = this.createAnInstance(dto.id, dto)
      this.byId.set(co.id, co)
    } else {
      this.byId.delete(id)
    }
  }

  public save(item: COType): Promise<string> {
    const contentObject: IContentObjectInput = {
      author: item.author,
      activityObjectId: item.activityObjectId,
      id: item.id || null,
      status: item.status,
      projectId: item.projectId,
      threadId: item.threadId,
      contentObjectType: item.contentObjectType,
    }

    if (item.type) {
      contentObject.type = item.type.toString()
    }

    return new Promise((resolve, reject) => {
      this.eventsStore.dispatch(
        SAVE_CONTENT_OBJECTS,
        [contentObject],
        (data: ISaveContentObjectsMutation) =>
          resolve(data.saveManyContentObjects[0].id),
        reject,
      )
    })
  }

  private get currentUser() {
    return this.eventsStore.appState.user
  }

  @computed
  public get list(): COType[] {
    return Array.from(this.byId.values())
  }

  public abstract get byId(): ObservableMap<string, COType>

  public clearList() {
    this.isDataReceived = false
    this.byId.clear()
  }

  public receiveList(list: IContentObject[]) {
    this.isDataReceived = false
    this.byId.clear()

    list.forEach(dto => {
      this.receiveOne(dto.id, dto)
    })

    this.isDataReceived = true
  }

  protected abstract createAnInstance(id: string, dto?: IContentObject): COType
}
