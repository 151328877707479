import { IContentObject } from '~/client/graph'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'

import ScheduleComment from '../../models/ScheduleComment'
import EventsStore from '../EventStore/Events.store'
import ContentObjectsStore from './ContentObjects.store'

export default class ScheduleCommentsStore extends ContentObjectsStore<
  null,
  ScheduleComment
> {
  public constructor(messagesStore: MessagesStore, eventsStore: EventsStore) {
    super(messagesStore, eventsStore)
  }

  public get byId() {
    return this.eventsStore.appState.scheduleComments
  }

  protected createAnInstance(id: string, dto?: IContentObject) {
    return new ScheduleComment(id, dto)
  }
}
