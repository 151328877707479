import * as React from 'react'

import { inject, observer } from 'mobx-react'

import ChatService from '../../../services/ChatService/Chat.service'
import { IChat, IContact } from '../../../services/ChatService/types'
import ProjectMembersStore from '../../../stores/domain/ProjectMembers.store'
import TagsStore from '../../../stores/domain/Tags.store'
import BaseCompactPopup from '../../BaseCompactPopup/BaseCompactPopup'
import ChatEditor from './ChatEditor'
import ChatEditorStore from './ChatEditor.store'

interface IProps {
  isShown: boolean
  onHide: () => void
  onEdit: (updatedChat: IChat) => void
  chat: IChat
  onlineContacts: Set<string>
  externalContactsMap: Map<string, IContact>

  areMembersBeingLoaded: boolean

  chatService?: ChatService
  projectMembersStore?: ProjectMembersStore
  tagsStore?: TagsStore
}

@inject('projectMembersStore', 'chatService', 'tagsStore')
@observer
export default class ChatEditorPopup extends React.Component<IProps> {
  private store: ChatEditorStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new ChatEditorStore(
      props.chat,
      props.onEdit,
      props.projectMembersStore,
      props.chatService,
      props.externalContactsMap,
      props.tagsStore,
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.externalContactsMap !== this.props.externalContactsMap) {
      this.store.externalContactsMap = this.props.externalContactsMap
    }
  }

  public render() {
    return (
      <BaseCompactPopup
        isShown={this.props.isShown}
        title="Group details"
        subtitle={this.store.headerSubtitle}
        onHide={this.handleClose}
        isActionButtonEnabled={this.store.isActionButtonEnabled}
        onActionButtonClick={this.store.performNextAction}
        actionButtonTitle={this.store.actionButtonTitle}
        searchPlaceholder="Search users"
        childrenClassName="col"
        isLoading={this.store.isUpdating}
      >
        {this.props.isShown && (
          <ChatEditor
            store={this.store}
            areMembersBeingLoaded={this.props.areMembersBeingLoaded}
            onlineContacts={this.props.onlineContacts}
          />
        )}
      </BaseCompactPopup>
    )
  }

  private handleClose = () => {
    this.store.unsetMemberSelectionMode()
    this.props.onHide()
  }
}
