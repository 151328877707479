import * as React from 'react'

import { ActivitiesTreeNodeTypes } from '~/client/src/shared/enums/ActivitiesTreeNodeTypes'

import '~/client/src/shared/components/ActivitiesTree/components/CustomNodeWrapper/CustomNodeWrapper.scss'

interface IProps {
  nodeType: ActivitiesTreeNodeTypes
  style: React.CSSProperties
  level?: number
  defaultClassName?: string
}

const {
  ACTIVITY,
  COMPANY_CONTAINER,
  NEXT_ACTIVITY_CONTAINER,
  COMPLETED_ACTIVITY_CONTAINER,
  SHOW_MORE_CONTROL,
  ZONE_CONTAINER,
  LEVEL_CONTAINER,
  ACTIVITY_CODE_CONTAINER,
  INFO,
} = ActivitiesTreeNodeTypes

const classNameMap = {
  [ACTIVITY]: 'activity-tree-node',
  [COMPANY_CONTAINER]: 'bb-light-grey company-tree-node',
  [NEXT_ACTIVITY_CONTAINER]: 'bb-light-grey sub-tree-node',
  [COMPLETED_ACTIVITY_CONTAINER]: 'bb-light-grey sub-tree-node',
  [SHOW_MORE_CONTROL]: 'bb-light-grey show-more-control-node',
  [ZONE_CONTAINER]: 'bb-light-grey zone-tree-node',
  [ACTIVITY_CODE_CONTAINER]: 'bb-light-grey activity-code-tree-node',
  [LEVEL_CONTAINER]: 'bb-light-grey level-tree-node',
  [INFO]: 'bb-light-grey sub-tree-node',
}

export default class CompactCustomNodeWrapper extends React.Component<IProps> {
  public render() {
    const { style, nodeType, children, level, defaultClassName } = this.props

    style.marginLeft = 0 // force reset default library indent
    const className = defaultClassName || classNameMap[nodeType] + ' mobile'

    return (
      <div style={style} className={`row level-${level} ${className}`}>
        {children}
      </div>
    )
  }
}
