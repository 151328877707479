import { computed, observable } from 'mobx'

import { ICalendar } from '~/client/graph'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import SimpleCalendarDto from '~/client/src/shared/types/SimpleCalendarDto'

import LocationAttributeBase from '../../models/LocationObjects/LocationAttributeBase'

export interface ICollectionItem {
  item: LocationAttributeBase
  isHidden: boolean
}

export default class CalendarsStore {
  @observable public isDataReceived = false

  public constructor(private eventsStore: EventsStore) {}

  @computed
  public get list(): SimpleCalendarDto[] {
    const { calendars } = this.eventsStore.appState
    return Array.from(calendars.values())
  }

  public get byId() {
    const { calendars } = this.eventsStore.appState
    return calendars
  }

  public get defaultCalendar(): SimpleCalendarDto {
    return this.list.find(c => c.defaultFlag)
  }

  @computed
  public get calendarWorkingDaysMap() {
    const map: { [key: string]: { [key: string]: boolean } } = {}

    this.list.forEach(calendar => {
      map[calendar.id] = {}
      calendar.calendarData.days.forEach(day => {
        map[calendar.id][day.weekDay] = true
      })
    })

    return map
  }

  @computed
  public get calendarExceptionsMap() {
    const map: { [key: string]: { [key: string]: boolean } } = {}

    this.list.forEach(calendar => {
      map[calendar.id] = {}
      calendar.calendarData.exceptions.forEach(exception => {
        map[calendar.id][exception] = true
      })
    })

    return map
  }

  public clearList() {
    this.isDataReceived = false
    const { calendars } = this.eventsStore.appState
    calendars.clear()
  }

  public receiveList(list: ICalendar[]) {
    this.isDataReceived = false
    const { calendars } = this.eventsStore.appState
    calendars.clear()

    list.forEach(dto => {
      const calendar = SimpleCalendarDto.fromDto(dto)
      calendars.set(calendar.id, calendar)
    })

    this.isDataReceived = true
  }
}
