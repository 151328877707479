import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ClosuresStore from '~/client/src/shared/stores/domain/Closures.store'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'
import { NOOP } from '~/client/src/shared/utils/noop'

import * as Paths from '../LocationPaths'

import Colors from '~/client/src/shared/theme.module.scss'

interface IProps {
  item: MapViewItemBase
  onClick: () => void
  isHighlighted?: boolean
  closuresStore?: ClosuresStore
  onDblClick?: () => void
}

const ICON_BY_TYPE = {
  [MapViewLocationIcon.Gate]: {
    path: Paths.GATE_PATH,
    viewBox: '0 1 16.5 16',
  },
  [MapViewLocationIcon.Zone]: {
    path: Paths.ZONE_PATH,
    viewBox: '2.2 1.5 17 17',
  },
  [MapViewLocationIcon.Building]: {
    path: Paths.BUILDING_PATH,
    viewBox: '0 0 16 16',
  },
  [MapViewLocationIcon.Route]: {
    path: Paths.ROUTE_PATH,
    viewBox: '0 0 16 16',
  },
  [MapViewLocationIcon.Equipment]: {
    path: Paths.EQUIPMENT_PATH,
    viewBox: '0 2 24 20',
  },
  [MapViewLocationIcon.Level]: {
    path: Paths.LEVEL_PATH,
    viewBox: '0 0 16 16',
  },
  [MapViewLocationIcon.Area]: {
    path: Paths.AREA_PATH,
    viewBox: '0 0 16 16',
  },
  [MapViewLocationIcon.Logistics]: {
    path: Paths.LOGISTICS_PATH,
    viewBox: '0 2 24 20',
  },
  [MapViewLocationIcon.Bathroom]: {
    path: Paths.BATHROOM_PATH,
    viewBox: '0 0 16 16',
  },
  [MapViewLocationIcon.Canteen]: {
    path: Paths.CANTEEN_PATH,
    viewBox: '0.3 0 11 11.3',
  },
  [MapViewLocationIcon.Break]: {
    path: Paths.BREAK_PATH,
    viewBox: '0 0 16 16',
  },
  [MapViewLocationIcon.Dumpster]: {
    path: Paths.DUMPSTER_PATH,
    viewBox: '0 0 16 16',
  },
  [MapViewLocationIcon.Elevator]: {
    path: Paths.ELEVATOR_PATH,
    viewBox: '3 3 18 18',
  },
  [MapViewLocationIcon.Entrance]: {
    path: Paths.ENTRANCE_PATH,
    viewBox: '0.5 0 11 11',
  },
  [MapViewLocationIcon.HandWash]: {
    path: Paths.HAND_WASH_PATH,
    viewBox: '0 0 16 16',
  },
  [MapViewLocationIcon.Medical]: {
    path: Paths.MEDICAL_PATH,
    viewBox: '0 0 16 16',
  },
  [MapViewLocationIcon.MeetingPoint]: {
    path: Paths.MEETING_POINT_PATH,
    viewBox: '0 0 16 16',
  },
  [MapViewLocationIcon.Parking]: {
    path: Paths.PARKING_PATH,
    viewBox: '0 0 16 16',
  },
  [MapViewLocationIcon.Smoking]: {
    path: Paths.SMOKING_PATH,
    viewBox: '0 0 16 16',
  },
  [MapViewLocationIcon.Stairs]: {
    path: Paths.STAIRS_PATH,
    viewBox: '3 3 18 18',
  },
  [MapViewLocationIcon.Shaft]: {
    path: Paths.SHAFT_PATH,
    viewBox: '2 2 20 20',
  },
  [MapViewLocationIcon.Temperature]: {
    path: Paths.TEMPERATURE_PATH,
    viewBox: '0 0 16 16',
  },
  [MapViewLocationIcon.Tent]: {
    path: Paths.TENT_PATH,
    viewBox: '0 0 16 16',
  },
  [MapViewLocationIcon.Toilet]: {
    path: Paths.TOILET_PATH,
    viewBox: '4 4 12 12',
  },
  [MapViewLocationIcon.Walkway]: {
    path: Paths.WALKWAY_PATH,
    viewBox: '0 0 16 16',
  },
  [MapViewLocationIcon.ElectricalRoom]: {
    path: Paths.ELECTRICAL_ROOM_PATH,
    viewBox: '2.2 2 9.1 9.1',
  },
  [MapViewLocationIcon.Trailer]: {
    path: Paths.TRAILER_PATH,
    viewBox: '0.5 -2 17 17',
  },
  [MapViewLocationIcon.Crane]: {
    path: Paths.CRANE_PATH,
    viewBox: '2.5 2.5 19 19',
  },
  [MapViewLocationIcon.Hoist]: {
    path: Paths.HOIST_PATH,
    viewBox: '5.5 4.5 12 12',
  },
  [MapViewLocationIcon.AerialLift]: {
    path: Paths.AERIAL_LIFT_PATH,
    viewBox: '2.8 2.6 18.5 18.5',
  },
  [MapViewLocationIcon.Gradall]: {
    path: Paths.GRADALL_PATH,
    viewBox: '1 3 21 21',
  },
  [MapViewLocationIcon.ForkLift]: {
    path: Paths.FORK_LIFT_PATH,
    viewBox: '2.5 5 14 14',
  },
  [MapViewLocationIcon.Monitoring]: {
    path: Paths.MATURIX_PATH,
    viewBox: '0.95 -1.5 20 20',
  },
  [MapViewLocationIcon.Staging]: {
    path: Paths.STAGING_PATH,
    viewBox: '1.5 1.5 17 17',
  },
  [MapViewLocationIcon.InteriorDoor]: {
    path: Paths.INTERIOR_DOOR_PATH,
    viewBox: '1.5 1.5 17 17',
  },
  [MapViewLocationIcon.InteriorPath]: {
    path: Paths.INTERIOR_PATH_PATH,
    viewBox: '2 2.5 17 17',
  },
}

const ICON_PATH =
  'M10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 Z M16.110243,5.6498533 L5.64886767,16.1095399 C6.87589786,16.9849839 8.3778047,17.5 10,17.5 C14.1421356,17.5 17.5,14.1421356 17.5,10 C17.5,8.37823903 16.9852597,6.8767021 16.110243,5.6498533 Z M10,2.5 C5.85786438,2.5 2.5,5.85786438 2.5,10 C2.5,11.6178521 3.012262,13.1160589 3.88343614,14.3412708 L14.3412708,3.88343614 C13.1160589,3.012262 11.6178521,2.5 10,2.5 Z'

@inject('closuresStore')
@observer
export default class GlobeIcon extends React.Component<IProps & any> {
  public render() {
    const { item, onClick, isHighlighted, onDblClick } = this.props
    const {
      globeViewItemProperties: { iconProperties },
      color,
      iconName,
      isDisplayed,
    } = item as MapViewItemBase
    const path = ICON_BY_TYPE[iconName]?.path
    const viewBox = ICON_BY_TYPE[iconName]?.viewBox
    if (
      !iconProperties ||
      !iconProperties.isDisplayed ||
      !path ||
      !isDisplayed
    ) {
      return null
    }

    return (
      <div
        className={classList({
          'globe-location marker row x-center y-center': true,
          highlighted: isHighlighted,
        })}
        style={{
          backgroundColor: color,
        }}
        onClick={onClick}
        onDoubleClick={onDblClick || NOOP}
      >
        <svg
          className={classList({
            'location-icon': true,
            highlighted: isHighlighted,
          })}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={viewBox}
        >
          <g fill="white" fillRule="evenodd">
            <path d={path} />
          </g>
        </svg>
        {this.isObjectClosed && (
          <svg
            className={classList({
              'closed-location': true,
              highlighted: isHighlighted,
            })}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            style={{ position: 'absolute' }}
          >
            <g fill={ThemeMode.getHEXColor(Colors.error50)} fillRule="evenodd">
              <path d={ICON_PATH} />
            </g>
          </svg>
        )}
      </div>
    )
  }

  private get isObjectClosed(): boolean {
    const {
      item: { dataObject },
      closuresStore,
    } = this.props

    return (
      dataObject &&
      closuresStore &&
      !closuresStore.isLocationOpen(dataObject.id)
    )
  }
}
