import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'

interface IProps {
  isExpanded: boolean
  visibleElements: JSX.Element[]
  overflowElements: JSX.Element[]

  containerRefSetter(elementRef: HTMLElement): void
  measureSizeFn(): void
  setExpandedState(): void
}

@observer
export default class ExpandableCardAttributeValues extends React.Component<IProps> {
  public componentDidMount() {
    this.props.measureSizeFn?.()
  }

  public componentDidUpdate() {
    this.props.measureSizeFn?.()
  }

  public render() {
    const { containerRefSetter, visibleElements, overflowElements } = this.props

    return (
      <div className="row y-end">
        <div
          className="row wrap no-flex-children overflow-hidden relative"
          ref={containerRefSetter}
        >
          {visibleElements}
          {this.renderExpandableArrow(overflowElements)}
        </div>
      </div>
    )
  }

  private renderExpandableArrow = (
    overflowElements: JSX.Element[],
  ): JSX.Element => {
    const { isExpanded, setExpandedState } = this.props

    if (!isExpanded && !overflowElements?.length) {
      return null
    }

    return (
      <div
        className="absolute bg-white row no-flex expandable-arrow"
        onClick={this.stopPropagation}
      >
        <Icon
          className="pointer text blue-highlight br-rounded"
          icon={isExpanded ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
          onClick={setExpandedState}
        />
      </div>
    )
  }

  private stopPropagation = (ev: React.SyntheticEvent) => {
    ev.stopPropagation()
  }
}
