import * as React from 'react'

import { observer } from 'mobx-react'

import { ContentObjectStatus } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import SafetyHazard from '~/client/src/shared/models/SafetyHazard'

interface IProps {
  safetyHazard: SafetyHazard
}

@observer
export default class SafetyHazardIcon extends React.Component<IProps> {
  public render() {
    const { safetyHazard = { status: '' } } = this.props
    const { status } = safetyHazard
    let icon

    switch (status) {
      case ContentObjectStatus.Open:
        icon = <Icons.Safety className="safety-hazard-icon" />
        break
      case ContentObjectStatus.Closed:
      default:
        icon = <Icons.Safety className="safety-hazard-icon inactive" />
        break
    }

    return <div style={{ marginTop: '-2px' }}>{icon}</div>
  }
}
