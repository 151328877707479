import React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Keys from '~/client/src/shared/enums/Keys'
import { EMPTY_STRING, NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import './EditableTextColumn.scss'

const NUMBER_TYPE = 'number'

interface IProps {
  value: any

  onSave(value: string | number, rowId?: string): void

  isEditable?: boolean
  type?: string
  step?: number
  rowId?: string
}

@observer
export default class EditableTextColumn extends React.Component<IProps> {
  @observable private isEditingMode: boolean = false
  @observable private newValue: string = EMPTY_STRING

  public componentDidMount(): void {
    this.resetValue()
  }

  public render() {
    const { value } = this.props
    return this.isEditingMode ? this.renderInput() : this.renderString(value)
  }

  private renderInput = (): JSX.Element => {
    const { type, step } = this.props

    return (
      <div className="row editable-text-column">
        <StruxhubInput
          isMinimalisticMode
          value={this.newValue}
          type={type}
          step={step}
          onChange={this.onChange}
          onKeyDown={this.handleOnKeyDown}
          onBlur={this.toggleEditMode}
          autoFocus
          selectOnFocus
        />
      </div>
    )
  }

  private renderString = (value: string): JSX.Element => {
    return (
      <div title={value || NO_VALUE} className="row editable-text-column">
        <span className="text large ellipsis mr10">{value || NO_VALUE}</span>
        {this.props.isEditable && (
          <Icons.EditFilled
            className="edit-icon pointer no-flex"
            onClick={this.toggleEditMode}
          />
        )}
      </div>
    )
  }

  private handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === Keys.Enter) {
      this.onSave()
    } else if (event.key === Keys.Escape) {
      this.toggleEditMode()
    }
  }

  private onSave = () => {
    const { onSave, type, rowId } = this.props

    onSave(
      type === NUMBER_TYPE ? parseInt(this.newValue) : this.newValue?.trim(),
      rowId,
    )
    this.toggleEditMode()
  }

  @action.bound
  private onChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.newValue = e.currentTarget.value
  }

  @action.bound
  private toggleEditMode() {
    this.isEditingMode = !this.isEditingMode
    this.resetValue()
  }

  @action.bound
  private resetValue() {
    this.newValue = this.props.value || EMPTY_STRING
  }
}
