import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IUserRolesFieldsFragment = Pick<
  Types.IUserRole,
  'id' | 'createdAt' | 'updatedAt' | 'projectId' | 'name' | 'color'
>

export type IUserRolesByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IUserRolesByProjectIdQuery = {
  userRoles?: Types.Maybe<{ data: Array<IUserRolesFieldsFragment> }>
}

export type IListenUserRolesByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenUserRolesByProjectIdSubscription = {
  userRole?: Types.Maybe<
    Pick<Types.IUserRoleChangeEvent, 'id'> & {
      item?: Types.Maybe<IUserRolesFieldsFragment>
    }
  >
}

export type ISaveUserRoleMutationVariables = Types.Exact<{
  userRole: Types.IUserRoleInput
}>

export type ISaveUserRoleMutation = {
  saveUserRole?: Types.Maybe<Pick<Types.IUserRole, 'id'>>
}

export type IDeleteUserRoleMutationVariables = Types.Exact<{
  userRoleId: Types.Scalars['ObjectId']
}>

export type IDeleteUserRoleMutation = Pick<Types.IMutation, 'deleteUserRole'>

export const UserRolesFieldsFragmentDoc = gql`
  fragment UserRolesFields on UserRole {
    id
    createdAt
    updatedAt
    projectId
    name
    color
  }
`
export const UserRolesByProjectIdDocument = gql`
  query UserRolesByProjectId($projectId: ObjectId!) {
    userRoles(projectId: $projectId, limit: 1000000) {
      data {
        ...UserRolesFields
      }
    }
  }
  ${UserRolesFieldsFragmentDoc}
`

/**
 * __useUserRolesByProjectIdQuery__
 *
 * To run a query within a React component, call `useUserRolesByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRolesByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRolesByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUserRolesByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IUserRolesByProjectIdQuery,
    IUserRolesByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IUserRolesByProjectIdQuery,
    IUserRolesByProjectIdQueryVariables
  >(UserRolesByProjectIdDocument, options)
}
export function useUserRolesByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IUserRolesByProjectIdQuery,
    IUserRolesByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IUserRolesByProjectIdQuery,
    IUserRolesByProjectIdQueryVariables
  >(UserRolesByProjectIdDocument, options)
}
export type UserRolesByProjectIdQueryHookResult = ReturnType<
  typeof useUserRolesByProjectIdQuery
>
export type UserRolesByProjectIdLazyQueryHookResult = ReturnType<
  typeof useUserRolesByProjectIdLazyQuery
>
export type UserRolesByProjectIdQueryResult = Apollo.QueryResult<
  IUserRolesByProjectIdQuery,
  IUserRolesByProjectIdQueryVariables
>
export const ListenUserRolesByProjectIdDocument = gql`
  subscription ListenUserRolesByProjectId($projectId: ObjectId!) {
    userRole(projectId: $projectId) {
      id
      item {
        ...UserRolesFields
      }
    }
  }
  ${UserRolesFieldsFragmentDoc}
`

/**
 * __useListenUserRolesByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenUserRolesByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenUserRolesByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenUserRolesByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenUserRolesByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenUserRolesByProjectIdSubscription,
    IListenUserRolesByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenUserRolesByProjectIdSubscription,
    IListenUserRolesByProjectIdSubscriptionVariables
  >(ListenUserRolesByProjectIdDocument, options)
}
export type ListenUserRolesByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenUserRolesByProjectIdSubscription
>
export type ListenUserRolesByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenUserRolesByProjectIdSubscription>
export const SaveUserRoleDocument = gql`
  mutation SaveUserRole($userRole: UserRoleInput!) {
    saveUserRole(userRole: $userRole) {
      id
    }
  }
`
export type ISaveUserRoleMutationFn = Apollo.MutationFunction<
  ISaveUserRoleMutation,
  ISaveUserRoleMutationVariables
>

/**
 * __useSaveUserRoleMutation__
 *
 * To run a mutation, you first call `useSaveUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserRoleMutation, { data, loading, error }] = useSaveUserRoleMutation({
 *   variables: {
 *      userRole: // value for 'userRole'
 *   },
 * });
 */
export function useSaveUserRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveUserRoleMutation,
    ISaveUserRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveUserRoleMutation,
    ISaveUserRoleMutationVariables
  >(SaveUserRoleDocument, options)
}
export type SaveUserRoleMutationHookResult = ReturnType<
  typeof useSaveUserRoleMutation
>
export type SaveUserRoleMutationResult =
  Apollo.MutationResult<ISaveUserRoleMutation>
export type SaveUserRoleMutationOptions = Apollo.BaseMutationOptions<
  ISaveUserRoleMutation,
  ISaveUserRoleMutationVariables
>
export const DeleteUserRoleDocument = gql`
  mutation DeleteUserRole($userRoleId: ObjectId!) {
    deleteUserRole(id: $userRoleId)
  }
`
export type IDeleteUserRoleMutationFn = Apollo.MutationFunction<
  IDeleteUserRoleMutation,
  IDeleteUserRoleMutationVariables
>

/**
 * __useDeleteUserRoleMutation__
 *
 * To run a mutation, you first call `useDeleteUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserRoleMutation, { data, loading, error }] = useDeleteUserRoleMutation({
 *   variables: {
 *      userRoleId: // value for 'userRoleId'
 *   },
 * });
 */
export function useDeleteUserRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteUserRoleMutation,
    IDeleteUserRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteUserRoleMutation,
    IDeleteUserRoleMutationVariables
  >(DeleteUserRoleDocument, options)
}
export type DeleteUserRoleMutationHookResult = ReturnType<
  typeof useDeleteUserRoleMutation
>
export type DeleteUserRoleMutationResult =
  Apollo.MutationResult<IDeleteUserRoleMutation>
export type DeleteUserRoleMutationOptions = Apollo.BaseMutationOptions<
  IDeleteUserRoleMutation,
  IDeleteUserRoleMutationVariables
>
