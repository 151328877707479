import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { Loader } from '~/client/src/shared/components/Loader'
import User from '~/client/src/shared/models/User'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import ResetPasswordStore from './ResetPassword.store'

interface IProps {
  onInvite: () => void
  isAddMode: boolean
  user: User
  eventsStore?: DesktopEventStore
  userProjectsStore?: UserProjectsStore
}

@inject('eventsStore', 'userProjectsStore')
@observer
export default class ResetPasswordRow extends React.Component<IProps> {
  private readonly store: ResetPasswordStore = null

  public constructor(props: IProps) {
    super(props)

    const { eventsStore, userProjectsStore } = this.props
    this.store = new ResetPasswordStore(
      eventsStore,
      userProjectsStore,
      props.onInvite,
    )
  }

  public render() {
    const { isAddMode, user } = this.props
    if (isAddMode) {
      return null
    }

    const { isLoading, resetMessage } = this.store

    if (isLoading) {
      return <Loader className="no-grow" />
    }

    const { isActionActiveForUser, getActionTitle } = this.store

    return (
      <div
        className={classList({
          'text bold pointer': true,
          'primary-blue underline': !resetMessage,
          'inactive-element': !isActionActiveForUser(user),
        })}
        onClick={this.store.resetPasswordForUserOrInvite.bind(this, user)}
      >
        {resetMessage || getActionTitle(user)}
      </div>
    )
  }
}
