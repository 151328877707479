import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized'

import Company from '~/client/src/shared/models/Company'
import { IDeliveryControlOption } from '~/client/src/shared/models/IDeliveryControl'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import { NOOP } from '~/client/src/shared/utils/noop'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import CompactPreviewMenu from '../../../../CompactPreviewMenu/CompactPreviewMenu'
import CompanyProfilePreview from '../../../../CompanyProfilePreview/CompanyProfilePreview'
import HierarchyChains from '../../../../HierarchyChains'
import Location from '../../../../SitemapAttributeSelector/Location'
import SitemapAttributeTag from '../../../../SitemapAttributeTag/SitemapAttributeTag'
import UserProfilePreview from '../../../../UserProfilePreview/UserProfilePreview'

interface IProps {
  selectedFieldOptions: IDeliveryControlOption[]
  cellMeasurerCache: CellMeasurerCache
  scrollToIndex: number

  getCompanyById: (companyId: string) => Company
  isOptionSelected: (option: IDeliveryControlOption) => boolean
  onOptionClick: (newValue: string) => void
  onCategoryCollapse: (categoryId: string) => void

  isGroupedOptions?: boolean
  areCategoriesCollapsible?: boolean
  getOptionsIcon?: (props: object) => JSX.Element
  getCategoryLabelIcon?: (props: object) => JSX.Element

  projectMembersStore?: ProjectMembersStore
}

const OVERSCAN_ROW_COUNT = 6

const groupingIconProps = {
  className: 'grouping-label-icon no-grow mr5',
}
const optionIconProps = {
  className: 'option-icon no-grow mr15',
}

const noElements = 'No elements'

const LOCATION_ICON_SIZE = 16

@inject('projectMembersStore')
@observer
export default class DeliverySelectModalOptionsList extends React.Component<IProps> {
  private static renderNoElementsMessage(): JSX.Element {
    return <div className="text center extra-large pa20">{noElements}</div>
  }

  private listRef: List = null

  @observable private previewEmail: string = null
  @observable private previewPhoneNumber: string = null

  private get selectedFieldOptions(): IDeliveryControlOption[] {
    return this.props.selectedFieldOptions
  }

  public componentDidMount() {
    const { scrollToIndex } = this.props

    if (scrollToIndex !== -1) {
      Promise.resolve().then(() => this.listRef?.scrollToRow(scrollToIndex))
    }
  }

  public render() {
    const { cellMeasurerCache } = this.props

    const shouldShowPreviewMenu =
      !!this.previewEmail || !!this.previewPhoneNumber

    return (
      <div className="col">
        <div className="virtualized-list-smart-wrapper">
          <AutoSizer>
            {({ width, height }) => (
              <List
                ref={this.setListRef}
                deferredMeasurementCache={cellMeasurerCache}
                width={width}
                height={height}
                rowCount={this.selectedFieldOptions.length}
                overscanRowCount={OVERSCAN_ROW_COUNT}
                scrollToAlignment="start"
                rowHeight={cellMeasurerCache.rowHeight}
                rowRenderer={this.renderRow}
                noRowsRenderer={
                  DeliverySelectModalOptionsList.renderNoElementsMessage
                }
              />
            )}
          </AutoSizer>
        </div>
        {shouldShowPreviewMenu && (
          <CompactPreviewMenu
            emailValue={this.previewEmail}
            phoneNumber={this.previewPhoneNumber}
            onHide={this.clearPreviewEmailAndPhone}
            shouldHideDirectMessage
          />
        )}
      </div>
    )
  }

  private renderRow = ({ key, style, parent, index }: any): JSX.Element => {
    const option = this.selectedFieldOptions[index]

    if (!option) {
      return null
    }

    return (
      <CellMeasurer
        cache={this.props.cellMeasurerCache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <div ref={registerChild} style={style} className="col">
            {this.renderModalOption(option)}
          </div>
        )}
      </CellMeasurer>
    )
  }

  private renderModalOption = (option: IDeliveryControlOption): JSX.Element => {
    if (this.props.isGroupedOptions && option.value) {
      return this.renderGroupedOption(option)
    }
    if (option.cardValues?.length) {
      return this.renderCardOption(option)
    }
    return this.renderDefaultOption(option)
  }

  private renderGroupedOption = (
    option: IDeliveryControlOption,
  ): JSX.Element => {
    if (option.isCategoryLabel) {
      const { getCategoryLabelIcon, areCategoriesCollapsible } = this.props

      return (
        <div className="row band-row bg-grey-scale-light bb-palette-grey bt-palette-grey px12 py8">
          {areCategoriesCollapsible && (
            <Icon
              className="px5 pointer"
              onClick={this.toggleCategory.bind(null, option.categoryId)}
              icon={
                option.isCategoryCollapsed
                  ? IconNames.CHEVRON_RIGHT
                  : IconNames.CHEVRON_DOWN
              }
            />
          )}
          {getCategoryLabelIcon?.(groupingIconProps)}
          <span className="text large white ellipsis">
            {option.title || NO_VALUE}
          </span>
          <span className="text white no-grow nowrap">({option.value})</span>
        </div>
      )
    }

    const {
      getCompanyById,
      isOptionSelected,
      onOptionClick,
      projectMembersStore,
    } = this.props

    const optionClickHandler = option.isDisabled
      ? NOOP
      : onOptionClick.bind(null, option.value)

    return (
      <div
        onClick={optionClickHandler}
        className={classList({
          'select-option row pa12 bt-palette-brand-lighter pointer': true,
          selected: isOptionSelected(option),
        })}
      >
        {option.isCompanyValue && (
          <CompanyProfilePreview
            company={getCompanyById(option.value)}
            onShowMenu={this.setPreviewEmailAndPhone}
            onHideMenu={this.clearPreviewEmailAndPhone}
          />
        )}
        {option.isUserValue && (
          <UserProfilePreview
            user={projectMembersStore.getById(option.value)}
          />
        )}
        {option.relatedAttribute && (
          <span className="ellipsis">
            <SitemapAttributeTag
              shouldShowAsTag={true}
              contentContainerClassName="text-ellipsis py2"
              dataObject={option.relatedAttribute}
            >
              <span title={option.title} className="text large">
                {option.title}
              </span>
            </SitemapAttributeTag>
            <HierarchyChains
              className="text light"
              hierarchyChains={option.hierarchyChains}
            />
          </span>
        )}
      </div>
    )
  }

  private renderDefaultOption = (
    option: IDeliveryControlOption,
  ): JSX.Element => {
    const { isOptionSelected, getOptionsIcon, onOptionClick } = this.props

    const optionClickHandler = option.isDisabled
      ? NOOP
      : onOptionClick.bind(null, option.value)

    return (
      <div
        onClick={optionClickHandler}
        className={classList({
          'select-option col y-center bb-light-input-border px16 py4 pointer':
            true,
          selected: isOptionSelected(option),
        })}
      >
        <div className="row">
          {getOptionsIcon?.(optionIconProps)}
          {
            <span
              className={classList({
                ellipsis: true,
                'inactive-element': !!option.isDisabled,
              })}
              title={option.title || NO_VALUE}
            >
              {option.relatedAttribute ? (
                <>
                  <SitemapAttributeTag
                    shouldShowAsTag={true}
                    contentContainerClassName="text-ellipsis py2"
                    dataObject={option.relatedAttribute}
                  >
                    <span title={option.title} className="text large">
                      {option.title}
                    </span>
                  </SitemapAttributeTag>
                  <HierarchyChains
                    className="text light"
                    hierarchyChains={option.hierarchyChains}
                  />
                </>
              ) : (
                option.title || NO_VALUE
              )}
            </span>
          }
        </div>
        {option.onlyOpenLabels &&
          option.onlyOpenLabels.map((label, labelIndex) => {
            return (
              <span key={labelIndex} className="text large orange">
                {label}
              </span>
            )
          })}
        {option.closedLabels &&
          option.closedLabels.map((label, labelIndex) => {
            return (
              <span key={labelIndex} className="text large orange">
                {label}
              </span>
            )
          })}
        {option.timeLimitLabel && (
          <span className="text large orange">{option.timeLimitLabel}</span>
        )}
        {option.maximumDeliveriesLabel && (
          <span className="text large orange">
            {option.maximumDeliveriesLabel}
          </span>
        )}
      </div>
    )
  }

  private renderCardOption = (option: IDeliveryControlOption): JSX.Element => {
    const { isOptionSelected, onOptionClick } = this.props

    const optionClickHandler = option.isDisabled
      ? NOOP
      : onOptionClick.bind(null, option.value)

    return (
      <div
        onClick={optionClickHandler}
        className={classList({
          'select-option col y-center bb-light-input-border px16 py4 pointer':
            true,
          selected: isOptionSelected(option),
        })}
      >
        {option.cardValues.map((v, idx) => (
          <div key={`${v.value}|${idx}`} className="row x-between y-start py4">
            <span
              className="card-title text large light ellipsis nowrap no-flex"
              title={v.title}
            >
              {v.title}
            </span>
            <span
              className={classList({
                row: !!v.location,
                'text large ellipsis flex-unset two-line-text-ellipsis': true,
                bold: v.hasBoldFont,
              })}
              title={v.value}
            >
              {v.location ? (
                <Location
                  tagClassName="card-location"
                  contentClassName="text large two-line-text-ellipsis"
                  dto={v.location}
                  iconSize={LOCATION_ICON_SIZE}
                  isParentChainHidden
                />
              ) : (
                v.value || NO_VALUE
              )}
            </span>
          </div>
        ))}
      </div>
    )
  }

  @action.bound
  private setPreviewEmailAndPhone(email: string, phoneNumber: string) {
    this.previewEmail = email
    this.previewPhoneNumber = phoneNumber
  }

  @action.bound
  private clearPreviewEmailAndPhone() {
    this.previewEmail = null
    this.previewPhoneNumber = null
  }

  @action.bound
  private toggleCategory(
    categoryId: string,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) {
    e.stopPropagation()
    this.props.onCategoryCollapse(categoryId)
  }

  private setListRef = (listElement: List) => {
    this.listRef = listElement
  }
}
