import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'

import * as Icons from '~/client/src/shared/components/Icons'
import { ILWFCColumn } from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import SearchBar from '~/client/src/shared/components/UsersDirectory/components/SearchBar/SearchBar'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import { NOOP } from '~/client/src/shared/utils/noop'

import './ColumnsConfigurationMenu.scss'

enum ConfigSections {
  Freezed = 'freezed',
  Hidden = 'hidden',
  None = 'none',
}

const none = 'None'
interface IProps {
  columns?: ILWFCColumn[]
  searchKey: string
  excludedFromSort?: string[]
  restoreDefaults: () => void
  updateColumnsConfiguration?: (collection: ILWFCColumn[]) => void
  changeSearchKey: (newSearchKey: string) => void
}

const SortableItem = SortableElement(({ item }) => (
  <div
    className={classList({
      'sortable-item full': true,
      hidden: !item.props.children,
    })}
  >
    {item}
  </div>
))

const SortableList = SortableContainer(({ items, setHoveringSection }) => {
  const { hiddenItems, shownItems, freezedItems } = items
  return (
    <div
      className={classList({
        col: true,
      })}
    >
      {/* Freezed items */}
      <div onMouseEnter={() => setHoveringSection(ConfigSections.Freezed)}>
        {freezedItems.map((item, index) => (
          <SortableItem
            key={`freezed-item-${index}`}
            index={index}
            item={item}
          />
        ))}

        <div className="row section-separator pt10 px24 overflow-hidden">
          <hr className="ba-none bt-light-input-border min-width30" />
          <span className="text large light no-white-space-wrap lpMinus01 line-extra-large w-fit-content mx10 ellipsis flex-grow3">
            {Localization.translator.freezeColumns}
          </span>
          <hr className="ba-none bt-light-input-border min-width30" />
        </div>
      </div>

      {/* Shown items */}
      <div
        className="pt10"
        onMouseEnter={() => setHoveringSection(ConfigSections.None)}
      >
        {shownItems.map((item, index) => (
          <SortableItem
            key={`item-${index}`}
            index={freezedItems?.length + index}
            item={item}
          />
        ))}
      </div>

      {/* Hidden items */}
      {hiddenItems?.length > 0 && (
        <div onMouseEnter={() => setHoveringSection(ConfigSections.Hidden)}>
          <div className="row py10 px16">
            <div className="text large medium-bold lpMinus01 line-extra-large">
              {Localization.translator.hiddenInTable}
            </div>
          </div>
          {hiddenItems.map((item, index) => (
            <SortableItem
              key={`hidden-item-${index}`}
              index={freezedItems?.length + shownItems.length + index}
              item={item}
            />
          ))}
        </div>
      )}
    </div>
  )
})

@inject('projectDateStore')
@observer
export default class ColumnsConfigurationMenu extends React.Component<IProps> {
  @observable private hoveringSection: ConfigSections = ConfigSections.None

  public render() {
    const { searchKey, restoreDefaults, changeSearchKey } = this.props

    return (
      <div className="col pt12 columns-config-menu fixed modal-shadow">
        <SearchBar
          className="mx12 ba-light-grey"
          value={searchKey}
          onChange={changeSearchKey}
          onReset={() => changeSearchKey('')}
          onTagClick={NOOP}
          customPlaceholder={Localization.translator.searchProperties}
        />
        <div className="row py10 px12">
          <div className="text large medium-bold lpMinus01 line-extra-large pl16">
            {Localization.translator.shownInTable}
          </div>
          <div
            className={
              'row text large mr6 mw40 grey pointer bp3-align-right no-outline-container'
            }
            onClick={restoreDefaults}
            title={Localization.translator.restoreDefaults}
          >
            <Icon icon={IconNames.REPEAT} className="no-grow mx12" />
          </div>
        </div>
        {this.renderColumnsList()}
      </div>
    )
  }

  private setHoveringSection = (section: ConfigSections) => {
    this.hoveringSection = section
  }

  private renderColumnsList() {
    return (
      <div className="col pb12 px12 columns-list no-select">
        <div className="col overflow-auto full-height sortable-list-holder">
          <SortableList
            items={this.items}
            onSortEnd={this.onSortEnd}
            setHoveringSection={this.setHoveringSection}
            axis="y"
            distance={2}
          />
        </div>
      </div>
    )
  }

  @computed
  private get items() {
    const { columns = [], searchKey } = this.props

    const items = columns
      .slice()
      .filter(
        column =>
          !column.label || column.label?.toLowerCase().includes(searchKey),
      )
      .reduce(
        (map, item) => {
          switch (true) {
            case item.isHidden:
              map.hiddenItems.push(item)
              break
            case item.isFixed:
              map.freezedItems.push(item)
              break
            default:
              map.shownItems.push(item)
          }

          return map
        },
        { freezedItems: [], hiddenItems: [], shownItems: [] },
      )

    return {
      freezedItems: this.renderItems(items.freezedItems),
      hiddenItems: this.renderItems(items.hiddenItems),
      shownItems: this.renderItems(items.shownItems),
    }
  }

  private renderItems(items: ILWFCColumn[]) {
    const { excludedFromSort } = this.props
    return items.map(item => {
      return (
        <>
          {!excludedFromSort.includes(item.dataKey) && (
            <div key={item.dataKey || UNASSIGNED} className="row py10 pl16">
              <Icon
                className="dragging-trigger no-grow"
                icon={IconNames.DRAG_HANDLE_VERTICAL}
              />
              <div>
                {item && item.label ? (
                  <div className="text large ellipsis px8">{item.label}</div>
                ) : (
                  <div className="text large ellipsis px8">{none}</div>
                )}
              </div>
              <div
                className="icon-wrapper no-grow pointer mw40 mr6"
                onClick={this.toggleItemVisibility.bind(null, item.dataKey)}
              >
                {item.isHidden ? (
                  <Icons.EyeHide />
                ) : (
                  <Icons.EyeView className="icon-grey mx12" />
                )}
              </div>
            </div>
          )}
        </>
      )
    })
  }

  private onSortEnd = ({ oldIndex, newIndex }) => {
    const { columns, updateColumnsConfiguration } = this.props
    let collection = columns.slice()

    collection = arrayMove(collection, oldIndex, newIndex)

    switch (this.hoveringSection) {
      case ConfigSections.Freezed:
        collection[newIndex].isFixed = true
        break
      case ConfigSections.Hidden:
        collection[newIndex].isHidden = true
        break
      case ConfigSections.None:
        collection[newIndex].isFixed = collection[newIndex].isHidden = false
        break
    }

    updateColumnsConfiguration(collection)
  }

  private toggleItemVisibility = (dataKey: string) => {
    const { columns, updateColumnsConfiguration } = this.props

    let collection = columns.slice()
    const hiddenIndex = collection.map(_ => _.isHidden).indexOf(true)
    const newIndex = hiddenIndex != -1 ? hiddenIndex : collection.length - 1

    collection = arrayMove(
      collection,
      collection.findIndex(_ => _.dataKey == dataKey),
      newIndex,
    )

    collection[newIndex] = {
      ...collection[newIndex],
      isHidden: !collection[newIndex].isHidden,
      isFixed: false,
    }
    updateColumnsConfiguration(collection)
  }
}
