import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IDeliveryFieldsConfigurationsFieldsFragment = Pick<
  Types.IDeliveryFieldsConfigurations,
  'id' | 'hiddenFields' | 'mandatoryFields' | 'projectId'
> & {
  fieldNames?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IDeliveryFieldName, 'id' | 'name'>>>
  >
}

export type IGetDeliveryFieldsConfigurationsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetDeliveryFieldsConfigurationsQuery = {
  deliveryFieldsConfigurations?: Types.Maybe<{
    data: Array<IDeliveryFieldsConfigurationsFieldsFragment>
  }>
}

export type ISaveDeliveryFieldsConfigurationsMutationVariables = Types.Exact<{
  deliveryFieldsConfigurations: Types.IDeliveryFieldsConfigurationsInput
}>

export type ISaveDeliveryFieldsConfigurationsMutation = {
  saveDeliveryFieldsConfigurations?: Types.Maybe<IDeliveryFieldsConfigurationsFieldsFragment>
}

export type IListenToDeliveryFieldsConfigurationsSubscriptionVariables =
  Types.Exact<{
    projectId: Types.Scalars['ObjectId']
  }>

export type IListenToDeliveryFieldsConfigurationsSubscription = {
  deliveryFieldsConfigurations?: Types.Maybe<
    Pick<Types.IDeliveryFieldsConfigurationsChangeEvent, 'id'> & {
      item?: Types.Maybe<IDeliveryFieldsConfigurationsFieldsFragment>
    }
  >
}

export const DeliveryFieldsConfigurationsFieldsFragmentDoc = gql`
  fragment DeliveryFieldsConfigurationsFields on DeliveryFieldsConfigurations {
    id
    fieldNames {
      id
      name
    }
    hiddenFields
    mandatoryFields
    projectId
  }
`
export const GetDeliveryFieldsConfigurationsDocument = gql`
  query GetDeliveryFieldsConfigurations($projectId: ObjectId!) {
    deliveryFieldsConfigurations(projectId: $projectId, limit: 1) {
      data {
        ...DeliveryFieldsConfigurationsFields
      }
    }
  }
  ${DeliveryFieldsConfigurationsFieldsFragmentDoc}
`

/**
 * __useGetDeliveryFieldsConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetDeliveryFieldsConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryFieldsConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryFieldsConfigurationsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetDeliveryFieldsConfigurationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetDeliveryFieldsConfigurationsQuery,
    IGetDeliveryFieldsConfigurationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetDeliveryFieldsConfigurationsQuery,
    IGetDeliveryFieldsConfigurationsQueryVariables
  >(GetDeliveryFieldsConfigurationsDocument, options)
}
export function useGetDeliveryFieldsConfigurationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetDeliveryFieldsConfigurationsQuery,
    IGetDeliveryFieldsConfigurationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetDeliveryFieldsConfigurationsQuery,
    IGetDeliveryFieldsConfigurationsQueryVariables
  >(GetDeliveryFieldsConfigurationsDocument, options)
}
export type GetDeliveryFieldsConfigurationsQueryHookResult = ReturnType<
  typeof useGetDeliveryFieldsConfigurationsQuery
>
export type GetDeliveryFieldsConfigurationsLazyQueryHookResult = ReturnType<
  typeof useGetDeliveryFieldsConfigurationsLazyQuery
>
export type GetDeliveryFieldsConfigurationsQueryResult = Apollo.QueryResult<
  IGetDeliveryFieldsConfigurationsQuery,
  IGetDeliveryFieldsConfigurationsQueryVariables
>
export const SaveDeliveryFieldsConfigurationsDocument = gql`
  mutation SaveDeliveryFieldsConfigurations(
    $deliveryFieldsConfigurations: DeliveryFieldsConfigurationsInput!
  ) {
    saveDeliveryFieldsConfigurations(
      deliveryFieldsConfigurations: $deliveryFieldsConfigurations
    ) {
      ...DeliveryFieldsConfigurationsFields
    }
  }
  ${DeliveryFieldsConfigurationsFieldsFragmentDoc}
`
export type ISaveDeliveryFieldsConfigurationsMutationFn =
  Apollo.MutationFunction<
    ISaveDeliveryFieldsConfigurationsMutation,
    ISaveDeliveryFieldsConfigurationsMutationVariables
  >

/**
 * __useSaveDeliveryFieldsConfigurationsMutation__
 *
 * To run a mutation, you first call `useSaveDeliveryFieldsConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDeliveryFieldsConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDeliveryFieldsConfigurationsMutation, { data, loading, error }] = useSaveDeliveryFieldsConfigurationsMutation({
 *   variables: {
 *      deliveryFieldsConfigurations: // value for 'deliveryFieldsConfigurations'
 *   },
 * });
 */
export function useSaveDeliveryFieldsConfigurationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveDeliveryFieldsConfigurationsMutation,
    ISaveDeliveryFieldsConfigurationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveDeliveryFieldsConfigurationsMutation,
    ISaveDeliveryFieldsConfigurationsMutationVariables
  >(SaveDeliveryFieldsConfigurationsDocument, options)
}
export type SaveDeliveryFieldsConfigurationsMutationHookResult = ReturnType<
  typeof useSaveDeliveryFieldsConfigurationsMutation
>
export type SaveDeliveryFieldsConfigurationsMutationResult =
  Apollo.MutationResult<ISaveDeliveryFieldsConfigurationsMutation>
export type SaveDeliveryFieldsConfigurationsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveDeliveryFieldsConfigurationsMutation,
    ISaveDeliveryFieldsConfigurationsMutationVariables
  >
export const ListenToDeliveryFieldsConfigurationsDocument = gql`
  subscription ListenToDeliveryFieldsConfigurations($projectId: ObjectId!) {
    deliveryFieldsConfigurations(projectId: $projectId) {
      id
      item {
        ...DeliveryFieldsConfigurationsFields
      }
    }
  }
  ${DeliveryFieldsConfigurationsFieldsFragmentDoc}
`

/**
 * __useListenToDeliveryFieldsConfigurationsSubscription__
 *
 * To run a query within a React component, call `useListenToDeliveryFieldsConfigurationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToDeliveryFieldsConfigurationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToDeliveryFieldsConfigurationsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToDeliveryFieldsConfigurationsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToDeliveryFieldsConfigurationsSubscription,
    IListenToDeliveryFieldsConfigurationsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToDeliveryFieldsConfigurationsSubscription,
    IListenToDeliveryFieldsConfigurationsSubscriptionVariables
  >(ListenToDeliveryFieldsConfigurationsDocument, options)
}
export type ListenToDeliveryFieldsConfigurationsSubscriptionHookResult =
  ReturnType<typeof useListenToDeliveryFieldsConfigurationsSubscription>
export type ListenToDeliveryFieldsConfigurationsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToDeliveryFieldsConfigurationsSubscription>
