import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IGetMaturixMonitoringDataQueryVariables = Types.Exact<{
  struxHubProjectId: Types.Scalars['ObjectId']
  monitoringId: Types.Scalars['String']
}>

export type IGetMaturixMonitoringDataQuery = {
  getMaturixMonitoringData?: Types.Maybe<{
    monitoring?: Types.Maybe<
      Pick<
        Types.IMonitoringInfo,
        'monitoringId' | 'monitoringName' | 'status' | 'deviceId'
      >
    >
    data?: Types.Maybe<
      Array<
        Types.Maybe<
          Pick<
            Types.IMaturixMonitoringData,
            | 'timestamp'
            | 'temperature'
            | 'temperatureUnit'
            | 'maturity'
            | 'strength'
            | 'strengthUnit'
          >
        >
      >
    >
  }>
}

export const GetMaturixMonitoringDataDocument = gql`
  query GetMaturixMonitoringData(
    $struxHubProjectId: ObjectId!
    $monitoringId: String!
  ) {
    getMaturixMonitoringData(
      struxHubProjectId: $struxHubProjectId
      monitoringId: $monitoringId
    ) {
      monitoring {
        monitoringId
        monitoringName
        status
        deviceId
      }
      data {
        timestamp
        temperature
        temperatureUnit
        maturity
        strength
        strengthUnit
      }
    }
  }
`

/**
 * __useGetMaturixMonitoringDataQuery__
 *
 * To run a query within a React component, call `useGetMaturixMonitoringDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaturixMonitoringDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaturixMonitoringDataQuery({
 *   variables: {
 *      struxHubProjectId: // value for 'struxHubProjectId'
 *      monitoringId: // value for 'monitoringId'
 *   },
 * });
 */
export function useGetMaturixMonitoringDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetMaturixMonitoringDataQuery,
    IGetMaturixMonitoringDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetMaturixMonitoringDataQuery,
    IGetMaturixMonitoringDataQueryVariables
  >(GetMaturixMonitoringDataDocument, options)
}
export function useGetMaturixMonitoringDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetMaturixMonitoringDataQuery,
    IGetMaturixMonitoringDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetMaturixMonitoringDataQuery,
    IGetMaturixMonitoringDataQueryVariables
  >(GetMaturixMonitoringDataDocument, options)
}
export type GetMaturixMonitoringDataQueryHookResult = ReturnType<
  typeof useGetMaturixMonitoringDataQuery
>
export type GetMaturixMonitoringDataLazyQueryHookResult = ReturnType<
  typeof useGetMaturixMonitoringDataLazyQuery
>
export type GetMaturixMonitoringDataQueryResult = Apollo.QueryResult<
  IGetMaturixMonitoringDataQuery,
  IGetMaturixMonitoringDataQueryVariables
>
