import { computed } from 'mobx'

import { LocationType } from '~/client/graph'
import DesktopCalendarStore from '~/client/src/desktop/components/DesktopCalendar/DesktopCalendar.store'
import DesktopCalendarColumn from '~/client/src/desktop/components/DesktopCalendar/DesktopCalendarColumn'
import DesktopCalendarTotalBarItem from '~/client/src/desktop/components/DesktopCalendar/DesktopCalendarTotalBarItem'
import DeliveryViewPeriodMode from '~/client/src/desktop/enums/DeliveryViewPeriodMode'
import CalendarPlaceholderStore from '~/client/src/shared/components/CalendarDayView/components/CalendarPlaceholder.store'
import CalendarEvent, {
  CalendarEventEntityType,
  CalendarEventLabelType,
} from '~/client/src/shared/models/CalendarEvent'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import { ICollectionItem } from '~/client/src/shared/stores/domain/Calendars.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import { DEFAULT_TIME_INTERVAL } from '~/client/src/shared/stores/ui/CalendarView.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import EventStyleCssAdapter from '~/client/src/shared/utils/EventStyleCssAdapter'

import DesktopDeliveryViewStore from '../../Deliveries.store'

// localization: no display text to translate

const HIGHLIGHTED_EVENT_CLASS_NAME = 'highlighted-event'
const EMPTY_CLASS_NAME = ''

export default class DesktopDeliveriesCalendarStore extends DesktopCalendarStore {
  public readonly eventEntityType = CalendarEventEntityType.Delivery

  public constructor(
    private desktopDeliveryViewStore: DesktopDeliveryViewStore,
    calendarPlaceholderStore: CalendarPlaceholderStore,
    projectDateStore: ProjectDateStore,
    companiesStore: CompaniesStore,
  ) {
    super(calendarPlaceholderStore, projectDateStore, companiesStore)
    this.setCollection()
  }

  public get startDate(): Date {
    return this.desktopDeliveryViewStore.startDate
  }

  @computed
  public get events(): CalendarEvent[] {
    const { availableDeliveries, deliveryCardColor, activeDelivery } =
      this.desktopDeliveryViewStore

    return availableDeliveries
      .filter(d => this.desktopDeliveryViewStore.deliveryPredicate(d))
      .map(delivery => {
        const { startDate, endDate } = delivery

        const color = deliveryCardColor(delivery)
        const eventStyle = delivery.getEventStyle(color)
        const cssAdapter = new EventStyleCssAdapter(eventStyle)

        const isDeliveryHighlighted =
          activeDelivery && activeDelivery.id === delivery.id

        return CalendarEvent.createEvent({
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          dataId: delivery.id,
          projectDateStore: this.projectDateStore,
          entityType: this.eventEntityType,
          labelType: CalendarEventLabelType.Default,
          styles: cssAdapter.getStyles(),
          className: isDeliveryHighlighted
            ? HIGHLIGHTED_EVENT_CLASS_NAME
            : EMPTY_CLASS_NAME,
          data: delivery,
          label: delivery.codeToDisplay(this.companiesStore),
          isActive: isDeliveryHighlighted,
        })
      })
  }

  @computed
  public get calendarColumns(): DesktopCalendarColumn[] {
    const { startDate, endDate, isDeliveryAttributeUnassigned, calendarMode } =
      this.desktopDeliveryViewStore

    switch (calendarMode) {
      case DeliveryViewPeriodMode.DayPeriod:
      case DeliveryViewPeriodMode.TodayPeriod:
        const columns = this.filteredCollection.map(attribute =>
          attribute.item
            ? DesktopCalendarColumn.createAttributeColumn(
                attribute.item,
                startDate,
                this.projectDateStore,
                this.selectedType,
              )
            : DesktopCalendarColumn.createUnassignedZoneColumn(
                (event: CalendarEvent) =>
                  event.data && isDeliveryAttributeUnassigned(event.data),
                startDate,
                this.projectDateStore,
              ),
        )

        return columns

      case DeliveryViewPeriodMode.WeekPeriod:
      case DeliveryViewPeriodMode.MonthPeriod:
        const visibleDays = this.projectDateStore.getDaysInInterval(
          startDate,
          endDate,
        )

        return visibleDays.map(date => {
          return DesktopCalendarColumn.createDateColumn(
            date,
            this.projectDateStore,
          )
        })
    }
  }

  @computed
  public get timeInterval(): number {
    const { deliveryDuration } = this.desktopDeliveryViewStore
    return deliveryDuration || DEFAULT_TIME_INTERVAL
  }

  @computed
  public get filteredAttributes(): LocationAttributeBase[] {
    const filteredAttributesByType = {
      [LocationType.Zone]: this.desktopDeliveryViewStore.filteredZones,
      [LocationType.Area]: this.desktopDeliveryViewStore.filteredAreas,
      [LocationType.Building]: this.desktopDeliveryViewStore.filteredBuildings,
      [LocationType.Gate]: this.desktopDeliveryViewStore.filteredGates,
      [LocationType.Level]: this.desktopDeliveryViewStore.filteredLevels,
      [LocationType.OffloadingEquipment]:
        this.desktopDeliveryViewStore.filteredOffloadingEquipment,
      [LocationType.Route]: this.desktopDeliveryViewStore.filteredRoutes,
    }

    return filteredAttributesByType[this.selectedType] || []
  }

  @computed
  protected get filteredCollection(): ICollectionItem[] {
    return this.collection.filter(({ item, isHidden }) =>
      item
        ? !isHidden
        : !isHidden &&
          this.desktopDeliveryViewStore.shouldAddUnassignedAttributeColumn,
    )
  }

  protected getTotalBarItems(
    limit?: number,
    skip: number = 0,
  ): DesktopCalendarTotalBarItem[] {
    const { offloadingEquipments } = this.desktopDeliveryViewStore

    const totalBarItems = offloadingEquipments.map(equipment => {
      return DesktopCalendarTotalBarItem.createTotalBarItemForDeliveryEquipment(
        equipment,
      )
    })

    const endIdx = limit ? skip + limit : totalBarItems.length
    return totalBarItems.slice(skip, endIdx)
  }

  public setShowingOfAllZoneColumns = (
    shouldShowAllAttributesColumns: boolean,
  ) => {
    this.desktopDeliveryViewStore.setShowingOfAllZoneColumns(
      shouldShowAllAttributesColumns,
    )
    this.setCollection()
  }

  protected isNewEventDraggingInRangeOfColumn(column: DesktopCalendarColumn) {
    if (!this.isNewEventMode) return false

    if (column.isEqual(this.editableEventColumn)) return true

    if (column.isEqual(this.initialEventColumn)) {
      return ![
        DeliveryViewPeriodMode.DayPeriod,
        DeliveryViewPeriodMode.TodayPeriod,
      ].includes(this.desktopDeliveryViewStore.calendarMode)
    }
  }
}
