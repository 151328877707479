import * as React from 'react'

import { observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'

import Localization from '../../../localization/LocalizationManager'
import Activity from '../../../models/Activity'
import ActivityStatusIndicator from '../../ActivityItemContentWithStatusUpdate/componets/ActivityStatusIndicator'
import CompactHeaderBar from '../CompactHeaderBar'

interface IProps {
  activity: Activity
  sequenceNumber: number
  onCloseClick: () => void
}

// localization: translated

@observer
export default class ActivityCompactHeaderBar extends React.Component<IProps> {
  public render() {
    const { activity, onCloseClick } = this.props

    return (
      <CompactHeaderBar
        titleCaption={this.activityTitleCaption}
        startDate={activity?.startDate}
        endDate={activity?.actualEndDate || activity?.plannedEndDate}
        titleIconEl={<Icons.ScheduleRounded />}
        isCreationMode={!activity}
        renderStatus={this.activityStatusRenderer}
        onCloseClick={onCloseClick}
      />
    )
  }

  private activityStatusRenderer = (className: string): JSX.Element => {
    const { activity } = this.props

    return (
      <ActivityStatusIndicator
        containerClassName={`${className} line-extra-large activity-status ml5`}
        activity={activity}
        isActualStatus={true}
      />
    )
  }

  public get activityTitleCaption(): string {
    const { activity, sequenceNumber } = this.props

    return activity
      ? `${activity.formattedId(sequenceNumber)} ${activity.name}` ||
          `${Localization.translator.notSpecified}`
      : Localization.translator.newActivity
  }
}
