import * as React from 'react'

import { classList } from 'react-classlist-helper'

import StruxhubBareEditableInput from '~/client/src/shared/components/StruxhubInputs/StruxhubBareEditableInput/StruxhubBareEditableInput'

interface IProps {
  header: string
  isSubHeader?: boolean
  isEditable?: boolean
  onEditChange?(newValue: string): void
  className?: string
  iconElement?: JSX.Element
}

export default function SectionHeader({
  header,
  isSubHeader = false,
  isEditable,
  onEditChange,
  className,
  iconElement,
}: IProps) {
  return (
    <div
      className={classList({
        'row text huge bold lp015 py3 brada4 px16': true,
        'bg-grey-scale-tooltip white mb24': !isSubHeader,
        'bg-palette-brand-lighter my12': isSubHeader,
        [className]: !!className,
      })}
    >
      {iconElement}
      {isEditable ? (
        <StruxhubBareEditableInput
          className="text huge bold lp015"
          inputClassName="ba2-group-highlight brada3"
          value={header}
          onChange={onEditChange}
        />
      ) : (
        header
      )}
    </div>
  )
}
