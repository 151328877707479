import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import ProjectSetUpPageStore from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPage.store'
import { Loader } from '~/client/src/shared/components/Loader'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ClosuresStore from '~/client/src/shared/stores/domain/Closures.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'

import { Header, Wrapper } from '../SetupPageLayout/SetupPageLayout'
import ObjectPropertiesSetupStore from './ObjectPropertiesSetup.store'
import NewObjectCreation from './components/NewObjectCreation'
import ObjectProperties from './components/ObjectProperties/ObjectProperties'
import ObjectPropertiesFilterPanel from './components/ObjectPropertiesFilterPanel/ObjectPropertiesFilterPanel'
import ObjectTypesTabs from './components/ObjectTypesTabs/ObjectTypesTabs'

import './ObjectPropertiesSetup.scss'

interface IProps {
  projectSetUpPageStore: ProjectSetUpPageStore

  eventsStore?: DesktopEventStore
  locationAttributesStore?: LocationAttributesStore
  companiesStore?: CompaniesStore
  closuresStore?: ClosuresStore
}

const LOADER_SIZE = 40
const loading = 'Loading'

@inject(
  'eventsStore',
  'locationAttributesStore',
  'companiesStore',
  'closuresStore',
)
@observer
export default class ObjectPropertiesSetup extends React.Component<IProps> {
  private readonly store: ObjectPropertiesSetupStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new ObjectPropertiesSetupStore(
      props.eventsStore,
      props.companiesStore,
      props.locationAttributesStore,
      props.closuresStore,
    )
  }

  public render() {
    return (
      <Wrapper className="object-properties-setup">
        <Header title={Localization.translator.locationControls} />
        {this.renderContent()}
      </Wrapper>
    )
  }

  private renderContent() {
    const {
      objectTypes,
      activeObjectTypeId,
      activateObjectTypeTab,
      isDataReceived,
    } = this.store

    if (!isDataReceived) {
      return <Loader size={LOADER_SIZE} hint={loading} />
    }

    return (
      <>
        <ObjectTypesTabs
          objectTypes={objectTypes}
          activeObjectTypeId={activeObjectTypeId}
          onTabClick={activateObjectTypeTab}
        />
        <ObjectPropertiesFilterPanel objectPropertiesSetupStore={this.store} />
        <ObjectProperties objectTypeId={activeObjectTypeId} />
        <NewObjectCreation store={this.store} />
      </>
    )
  }
}
