import * as React from 'react'

import { observer } from 'mobx-react'

import { IPermitTypeField, PermitFieldType } from '~/client/graph'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import { VALID_EMAIL_PATTERN } from '~/client/src/shared/utils/regExpPatterns'

import PermitBaseFormField from './PermitBaseFormField'
import PermitFieldRemoveIcon from './PermitFieldRemoveIcon'

interface IProps {
  typeField: IPermitTypeField
  isViewMode: boolean

  fieldsStore: IPermitFieldsStore

  tableId?: string
  tableRowIndex?: number
}

const EMAIL_TYPE = 'email'
const NUMBER_TYPE = 'number'
const TEXT_TYPE = 'text'
const TIME_TYPE = 'time'

const NUMBER_STEP = 0.01

@observer
export default class PermitFormInputField extends React.Component<IProps> {
  public render() {
    const { isViewMode, typeField, fieldsStore, tableId, tableRowIndex } =
      this.props
    const { caption, isMandatory, isMultiple } = typeField
    const { isFieldChanged, isFieldValid } = fieldsStore

    return (
      <PermitBaseFormField
        typeField={typeField}
        isViewMode={isViewMode}
        viewModeElements={this.viewModeElements}
        onAddFieldClick={this.addNewFieldValue}
      >
        {this.fieldValues.map((value, index) => {
          const shouldShowRemoveBtn = !!index || !!value

          return (
            <div key={index} className="row y-center">
              <StruxhubInput
                id={`${typeField.id}_${index}_${tableRowIndex || 0}`}
                className="overflow-hidden"
                label={caption}
                type={this.inputType}
                step={this.isNumberType ? NUMBER_STEP : null}
                negativeOrDecimal={this.isNumberType}
                isRequired={isMandatory}
                isChanged={isFieldChanged(
                  typeField.id,
                  index,
                  tableId,
                  tableRowIndex,
                )}
                isValid={isFieldValid(typeField, index, tableId, tableRowIndex)}
                isLabelHidden={!!index}
                isHelperTextCondensed={isMultiple}
                value={value || ''}
                onChange={this.onValueChange.bind(this, index)}
                onValueReset={
                  !isMultiple &&
                  !this.isTableField &&
                  this.removeValue.bind(this, index)
                }
                validationMessage={this.validationMessage}
              />
              {isMultiple && shouldShowRemoveBtn && (
                <PermitFieldRemoveIcon
                  index={index}
                  onClick={this.removeValue}
                />
              )}
            </div>
          )
        })}
      </PermitBaseFormField>
    )
  }

  private get viewModeElements(): JSX.Element[] {
    return this.fieldValues
      .filter(p => p)
      .map((value, idx) => (
        <div key={idx} className="text large word-break-all">
          {value}
        </div>
      ))
  }

  private get fieldValues(): string[] {
    const { typeField, fieldsStore, tableId, tableRowIndex } = this.props
    const values = fieldsStore.getFieldValues<string>(
      typeField.id,
      tableId,
      tableRowIndex,
    )

    return values?.length ? values : ['']
  }

  private get isEmailType(): boolean {
    return this.props.typeField.type === PermitFieldType.Email
  }

  private get isNumberType(): boolean {
    return this.props.typeField.type === PermitFieldType.InputNumber
  }

  private get isTimeType(): boolean {
    return this.props.typeField.type === PermitFieldType.Time
  }

  private get inputType(): string {
    if (this.isEmailType) {
      return EMAIL_TYPE
    }
    if (this.isNumberType) {
      return NUMBER_TYPE
    }
    if (this.isTimeType) {
      return TIME_TYPE
    }
    return TEXT_TYPE
  }

  private get validationMessage(): string {
    if (this.isEmailType) {
      return Localization.translator.invalidEmailError
    }
  }

  private isEmailValid(value: string): boolean {
    return !value || VALID_EMAIL_PATTERN.test(value.toLowerCase())
  }

  private onValueChange(
    fieldIndex: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { fieldsStore, typeField, tableId, tableRowIndex } = this.props
    const { changeValidationState, changeFieldValue, changeTableFieldValue } =
      fieldsStore
    const { value } = event.target

    if (this.isEmailType) {
      changeValidationState(
        typeField.id,
        fieldIndex,
        this.isEmailValid(value),
        tableRowIndex,
      )
    }
    if (this.isTableField) {
      changeTableFieldValue(
        tableId,
        tableRowIndex,
        typeField,
        fieldIndex,
        value,
      )
      return
    }
    changeFieldValue(typeField, fieldIndex, value)
  }

  private addNewFieldValue = () => {
    if (!this.isTableField) {
      const { fieldsStore, typeField } = this.props
      fieldsStore.addNewFieldValue(typeField, '')
    }
  }

  private removeValue = (index: number) => {
    if (!this.isTableField) {
      const { fieldsStore, typeField } = this.props
      const { rearrangeInvalidState, removeFieldValue } = fieldsStore

      rearrangeInvalidState(typeField.id, index)
      removeFieldValue(typeField.id, index)
    }
  }

  private get isTableField(): boolean {
    return !!this.props.tableId
  }
}
