import * as React from 'react'

import { computed, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Location from '~/client/src/shared/components/SitemapAttributeSelector/Location'
import Area from '~/client/src/shared/models/LocationObjects/Area'
import Level from '~/client/src/shared/models/LocationObjects/Level'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'

interface ITreeNode {
  dto: LocationBase
  children?: LocationBase[]
}

interface IProps {
  levels: Level[]
  areas: Area[]
}

@observer
export default class LevelAreaTree extends React.Component<IProps> {
  @observable private isCollapsedMap: { [key: string]: boolean } = {}

  public render() {
    return this.tree.map(node => {
      const isCollapsed = this.isCollapsedMap[node.dto.id]
      const hasChildren = node.children && !!node.children.length

      return (
        <div key={node.dto.id}>
          <div
            className="row y-start no-select"
            onClick={this.toggleItem.bind(this, node.dto.id)}
          >
            <div className="no-grow pr5">
              <div
                className={classList({
                  'caret-down mt5': hasChildren && !isCollapsed,
                  'caret-right mt5': hasChildren && isCollapsed,
                  dot: !hasChildren,
                })}
              />
            </div>
            <div className="full-width">
              <Location dto={node.dto} />
            </div>
          </div>
          {hasChildren && !isCollapsed && (
            <div className="bl-light-input-border pl12 py8">
              {node.children.map(area => (
                <div key={area.id} className="row y-start">
                  <div className="no-grow pr5">
                    <div className="dot" />
                  </div>
                  <div className="full-width">
                    <Location dto={area} />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )
    })
  }

  @computed
  private get tree(): ITreeNode[] {
    const { levels, areas } = this.props

    const tree: ITreeNode[] = levels.map(dto => ({
      dto,
      children: [],
    }))

    areas.forEach(area => {
      const parent = tree.find(n => area.isParent(n.dto))
      if (parent) {
        parent.children.push(area)
      } else {
        tree.push({
          dto: area,
          children: [],
        })
      }
    })

    return tree
  }

  private toggleItem(id: string) {
    this.isCollapsedMap[id] = !this.isCollapsedMap[id]
  }
}
