import * as React from 'react'

import { observer } from 'mobx-react'

import * as WeatherIcon from '~/client/src/shared/components/WeatherIcon'

interface IProps {
  openWeatherIconCode: string
  className?: string
}

const iconsMap = {
  '01d': WeatherIcon.Sunny,
  '02d': WeatherIcon.Cloudy,
  '03d': WeatherIcon.Cloudy,
  '04d': WeatherIcon.Cloudy,
  '09d': WeatherIcon.Shower,
  '10d': WeatherIcon.Rain,
  '11d': WeatherIcon.Thunderstorm,
  '13d': WeatherIcon.WindySnow,
  '50d': WeatherIcon.Fog,
  '01n': WeatherIcon.Sunny,
  '02n': WeatherIcon.Cloudy,
  '03n': WeatherIcon.Cloudy,
  '04n': WeatherIcon.Cloudy,
  '09n': WeatherIcon.Shower,
  '10n': WeatherIcon.Rain,
  '11n': WeatherIcon.Thunderstorm,
  '13n': WeatherIcon.WindySnow,
  '50n': WeatherIcon.Fog,
}

@observer
export default class OpenWeatherIcon extends React.Component<IProps> {
  public render() {
    const { openWeatherIconCode, className } = this.props

    const iconRenderer: (props: any) => JSX.Element =
      iconsMap[openWeatherIconCode]

    if (!iconRenderer) {
      return null
    }

    return iconRenderer({ className })
  }
}
