import * as React from 'react'

import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import { IBaseStepDiagramProps } from './BaseStepDiagram'
import SimpleStepDiagram from './SimpleStepDiagram'

// localization: translated

@observer
export default class InspectionStepDiagram extends React.Component<IBaseStepDiagramProps> {
  public render() {
    return (
      <SimpleStepDiagram
        {...this.props}
        intermediateStateText={
          Localization.translator.passed + '/' + Localization.translator.failed
        }
        actionStateText={Localization.translator.inspection}
      />
    )
  }
}
