import * as React from 'react'

import { observer } from 'mobx-react'

import IBasicProps from '~/client/src/shared/interfaces/IBasicProps'

import './PageActionHeader.scss'

interface IProps extends IBasicProps {
  title: string
  customLeftComponent?: JSX.Element
  customRightComponent?: JSX.Element
}

@observer
export default class PageActionHeader extends React.Component<IProps> {
  public render() {
    const { title, customLeftComponent, customRightComponent } = this.props

    return (
      <div className={'page-header-container row'}>
        {customLeftComponent}
        <div className="text header center bold">{title}</div>
        {customRightComponent}
      </div>
    )
  }
}
