import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import UnsafeNotificationsList from '~/client/src/desktop/views/Notifications/components/NotificationList/NotificationsList'
import NotificationsSideBar from '~/client/src/desktop/views/Notifications/components/NotificationsSideBar'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'
import { Loader } from '~/client/src/shared/components/Loader'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import {
  MARK_NOTIFICATIONS_AS_ARCHIVED,
  MARK_NOTIFICATIONS_AS_READ,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import { NotificationFilters } from '~/client/src/shared/stores/InitialState'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryStatusChangesStore from '~/client/src/shared/stores/domain/DeliveryStatusChanges.store'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import NotificationsStore from '~/client/src/shared/stores/domain/Notifications.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { isAnnouncementType } from '~/client/src/shared/types/NotificationTypes'

import {
  Box,
  Content,
  HBox,
  Header,
  View,
} from '../../../shared/components/Layout'
import NavBar from '../../components/NavBar/NavBar'
import DesktopInitialState from '../../stores/DesktopInitialState'
import DesktopEventStore from '../../stores/EventStore/DesktopEvents.store'
import NotificationDetailsView from '../NotificationDetails/NotificationDetailsView'
import NotificationDetailsAnnouncement from '../NotificationDetails/components/NotificationDetailsAnnouncement'
import NotificationSelectorDropdown from './components/NotificationSelectorDropdown'

const NotificationsList = withErrorBoundary(UnsafeNotificationsList)

// translated

export interface IProps {
  eventsStore?: DesktopEventStore
  deliveriesStore?: DeliveriesStore
  deliveryStatusChangesStore?: DeliveryStatusChangesStore
  companiesStore?: CompaniesStore
  graphExecutorStore?: GraphExecutorStore
  state: DesktopInitialState
  projectDateStore?: ProjectDateStore
}

@inject(
  'eventsStore',
  'deliveriesStore',
  'deliveryStatusChangesStore',
  'companiesStore',
  'graphExecutorStore',
  'state',
  'projectDateStore',
)
@observer
export default class NotificationsView extends React.Component<IProps> {
  private store: NotificationsStore

  public constructor(props: IProps) {
    super(props)

    this.store = new NotificationsStore(
      props.eventsStore,
      props.deliveriesStore,
      props.deliveryStatusChangesStore,
      props.companiesStore,
      props.graphExecutorStore,
      props.projectDateStore,
      props.state.notificationsStatusFilters,
    )

    props.deliveryStatusChangesStore.loadDeliveriesStatusChanges()
  }

  public componentDidMount(): void {
    const { deliveryStatusChangesStore } = this.props
    deliveryStatusChangesStore.listenToDeliveryStatusChanges()
  }

  public componentWillUnmount() {
    this.store.disposeNotifications()
    this.props.deliveryStatusChangesStore.dontListenToDeliveryStatusChanges()
  }

  public render() {
    const { displayedNotification, isLoading, rows } = this.store

    return (
      <View>
        <Header>
          <NavBar />
        </Header>
        <Content className="relative">
          <HBox style={{ height: '100%' }}>
            <Box size={1} className="notifications-left-nav">
              <NotificationsSideBar notificationsStore={this.store} />
            </Box>
            {isLoading ? (
              <Loader />
            ) : (
              <Box size={3} className="notifications-content-area">
                <View>
                  <Header>
                    <div className="notifications-header col y-center">
                      <NotificationsListHeader
                        notificationsStore={this.store}
                      />
                    </div>
                  </Header>
                  <Content scrollable={true}>
                    <NotificationsList
                      rows={rows}
                      selectedNotificationsIds={this.selectedNotificationsIds}
                      notificationsStore={this.store}
                    />
                  </Content>
                </View>
                {displayedNotification && (
                  <div className="notification-details-holder overflow-hidden">
                    <NotificationDetailsView
                      displayedNotification={displayedNotification}
                      notificationsStore={this.store}
                    />
                  </div>
                )}
                {isAnnouncementType(displayedNotification?.type) && (
                  <NotificationDetailsAnnouncement
                    backClicked={this.backClicked}
                    announcementId={displayedNotification.entityId}
                  />
                )}
              </Box>
            )}
          </HBox>
        </Content>
      </View>
    )
  }

  private backClicked = () => {
    this.store.displayedNotification = null
  }

  private get selectedNotificationsIds(): string[] {
    const { all, selectedNotifications } = this.store
    return all.filter(n => selectedNotifications.get(n.id)).map(n => n.id)
  }
}

interface INotificationsListHeaderProps {
  eventsStore?: DesktopEventStore
  state?: DesktopInitialState
  notificationsStore: NotificationsStore
}
@inject('state', 'eventsStore')
@observer
class NotificationsListHeader extends React.Component<INotificationsListHeaderProps> {
  public render() {
    return (
      <div className="row relative">
        <NotificationSelectorDropdown
          notificationsStore={this.props.notificationsStore}
        />
        <div
          className="col x-start large text primary-blue pointer no-grow w-fit-content w-max-content"
          style={{ paddingLeft: '10px' }}
        >
          <div style={{ margin: 0 }} onClick={this.handleArchiveAction}>
            {this.archiveActionButtonCaption}
          </div>
        </div>
        <div
          className="col x-start large text primary-blue pointer no-grow"
          style={{ paddingLeft: '10px', maxWidth: '100px' }}
        >
          <div
            style={{ margin: 0 }}
            onClick={this.markAsReadSelectedNotifications}
          >
            {Localization.translator.read}
          </div>
        </div>
        <div
          className="col x-start large text primary-blue pointer no-grow"
          style={{ paddingLeft: '10px', maxWidth: '100px' }}
        >
          <div
            style={{ margin: 0 }}
            className="nowrap"
            onClick={this.markAsUnreadSelectedNotifications}
          >
            {Localization.translator.unread}
          </div>
        </div>
      </div>
    )
  }

  private get isArchivedFilterActive() {
    const { notificationsStatusFilters } = this.props.state
    return (
      notificationsStatusFilters.length === 1 &&
      notificationsStatusFilters[0] === NotificationFilters.ARCHIVED
    )
  }

  private get selectedNotifications() {
    const { all, selectedNotifications } = this.props.notificationsStore
    return all.filter(n => selectedNotifications.get(n.id))
  }

  private clearSelected = () => {
    this.props.notificationsStore.selectedNotifications.clear()
  }

  private archiveSelectedNotifications = () => {
    this.props.eventsStore.dispatch(
      MARK_NOTIFICATIONS_AS_ARCHIVED,
      this.selectedNotifications,
      this.clearSelected,
    )
  }

  private unArchiveSelectedNotifications = () => {
    this.props.eventsStore.dispatch(
      MARK_NOTIFICATIONS_AS_ARCHIVED,
      this.selectedNotifications,
      this.clearSelected,
      false,
    )
  }

  private markAsReadSelectedNotifications = () => {
    this.props.eventsStore.dispatch(
      MARK_NOTIFICATIONS_AS_READ,
      this.selectedNotifications,
      this.clearSelected,
    )
  }

  private markAsUnreadSelectedNotifications = () => {
    this.props.eventsStore.dispatch(
      MARK_NOTIFICATIONS_AS_READ,
      this.selectedNotifications,
      this.clearSelected,
      false,
    )
  }

  private get archiveActionButtonCaption() {
    return this.isArchivedFilterActive
      ? Localization.translator.unArchive
      : Localization.translator.archive
  }

  @action.bound
  private handleArchiveAction() {
    if (this.isArchivedFilterActive) {
      this.unArchiveSelectedNotifications()
    } else {
      this.archiveSelectedNotifications()
    }
  }
}
