import * as React from 'react'

import { action } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'

import Keys from '../../../enums/Keys'
import { TagIconType } from '../../../enums/TagIcon'
import BaseStruxhubInput, { ISharedProps } from '../BaseStruxhubInput'
import BaseStruxhubTagsInputWrapper from '../BaseStruxhubTagsInputWrapper/BaseStruxhubTagsInputWrapper'
import StruxhubTagsInputStore from './StruxhubTagsAsTextInput.store'

import Colors from '~/client/src/shared/theme.module.scss'

interface IProps extends ISharedProps {
  values: string[]
  onChange: (newValues: string[]) => void
  onInputChange?: (inputValue: string) => string

  iconName?: TagIconType
  tagColor?: string
  isSingleSelectionMode?: boolean
  searchKey?: string
}

@observer
export default class StruxhubTagsAsTextInput extends React.Component<IProps> {
  public static defaultProps = {
    values: [],
    isSingleSelectionMode: false,
    iconName: null,
    tagColor: Colors.neutral0,
    searchKey: '',
    onInputChange: (value: string) => value,
  }

  private readonly store: StruxhubTagsInputStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new StruxhubTagsInputStore(
      props.onChange,
      props.isSingleSelectionMode,
      props.values,
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.values.length !== this.props.values.length) {
      this.store.init(this.props.values)
    }
  }

  public render() {
    const { iconName, tagColor, searchKey } = this.props

    const { values, deleteValue, inputValue, shouldShowInput } = this.store

    return (
      <BaseStruxhubInput {...this.props} value={inputValue}>
        {(isValueInvalid, isInFocus, onFocus, onBlur) => (
          <div className="tags-input-wrapper">
            <BaseStruxhubTagsInputWrapper
              isInFocus={isInFocus}
              isValueInvalid={isValueInvalid}
            >
              <div className="row h-auto">
                <div className="row wrap row-gap-4">
                  {values.map((value, index) => (
                    <span
                      key={value + index}
                      title={value}
                      className={classList({
                        'tag-wrapper col no-grow mr4': true,
                        outlined: searchKey && value?.includes(searchKey),
                      })}
                    >
                      <SitemapAttributeTag
                        className="no-flex"
                        contentContainerClassName="text extra-large text-ellipsis line-24"
                        dataObject={
                          {
                            type: null,
                            iconName,
                            color: tagColor,
                          } as LocationAttributeBase
                        }
                        shouldShowAsTag={true}
                        isRemovable={true}
                        onRemove={deleteValue.bind(null, value)}
                      >
                        {value}
                      </SitemapAttributeTag>
                    </span>
                  ))}
                  {shouldShowInput && (
                    <input
                      type="text"
                      className="bare-input full-width no-outline"
                      onFocus={onFocus}
                      onBlur={this.handleBlur.bind(null, onBlur)}
                      onKeyPress={this.handleKeyPress.bind(null, onBlur)}
                      value={inputValue}
                      onChange={this.handleInputChange}
                    />
                  )}
                </div>
              </div>
            </BaseStruxhubTagsInputWrapper>
          </div>
        )}
      </BaseStruxhubInput>
    )
  }

  @action.bound
  private handleBlur(onBlur: () => void) {
    this.store.setCurrentValues()
    onBlur()
  }

  @action.bound
  private handleInputChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const newInputValue = this.props.onInputChange(evt.target.value)
    this.store.changeInputValue(newInputValue)
  }

  @action.bound
  private handleKeyPress(
    onBlur: () => void,
    evt: React.KeyboardEvent<HTMLInputElement>,
  ) {
    if (evt.key !== Keys.Enter) {
      return
    }

    this.store.setCurrentValues()

    if (this.props.isSingleSelectionMode) {
      onBlur()
    }
  }
}
