import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import { ITag } from '~/client/src/shared/models/Tag'
import InitialState from '~/client/src/shared/stores/InitialState'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

import ColorPickerWrapper from '../../ColorPicker/ColorPickerWrapper'
import TagsList, { TAG_ROW_HEIGHT } from '../../TagsList/TagsList'
import BaseStruxhubInput, { ISharedProps } from '../BaseStruxhubInput'
import BaseStruxhubTagsInputWrapper from '../BaseStruxhubTagsInputWrapper/BaseStruxhubTagsInputWrapper'
import StruxhubTagsInputStore from './StruxhubTagsInput.store'

interface IProps extends ISharedProps {
  values: string[]
  onChange: (newValues: string[]) => void
  tagType: TagType
  isTagCreationAllowed?: boolean
  isSingleSelectionMode?: boolean
  restrictedValues?: string[]

  state?: InitialState
  tagsStore?: TagsStore
}

@inject('state', 'tagsStore')
@observer
export default class StruxhubTagsInput extends React.Component<IProps> {
  public static defaultProps = {
    values: [],
    isTagCreationAllowed: false,
    isSingleSelectionMode: false,
    restrictedValues: [],
  }

  private readonly store: StruxhubTagsInputStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new StruxhubTagsInputStore(
      props.state,
      props.tagsStore,
      props.onChange,
      props.tagType,
      props.isSingleSelectionMode,
      props.restrictedValues,
      props.values,
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.values !== this.props.values) {
      this.store.init(this.props.values)
    }
  }

  public render() {
    const {
      values,
      isActive,
      activate,
      deactivate,
      deleteTag,
      inputValue,
      sourceStore,
      shouldShowPlusIcon,
      shouldShowInput,
    } = this.store

    return (
      <BaseStruxhubInput {...this.props}>
        {(isValueInvalid, isInFocus, onFocus, onBlur) => (
          <MenuCloser
            className="tags-input-wrapper"
            closeMenu={deactivate}
            isOpen={isActive}
          >
            <BaseStruxhubTagsInputWrapper
              isInFocus={isInFocus}
              isValueInvalid={isValueInvalid}
              onClick={activate}
            >
              <div className="row h-auto">
                <div className="row wrap row-gap-4">
                  {values.map(tagId => {
                    const tag = sourceStore.getInstanceById(tagId)

                    if (!tag) {
                      return null
                    }

                    return (
                      <span
                        key={tag.id}
                        title={tag.name}
                        className="tag-wrapper col no-grow mr4"
                      >
                        <SitemapAttributeTag
                          className="no-flex"
                          contentContainerClassName="text extra-large text-ellipsis line-24"
                          dataObject={tag as LocationAttributeBase}
                          shouldShowAsTag={true}
                          isRemovable={true}
                          onRemove={deleteTag}
                        >
                          {tag.name}
                        </SitemapAttributeTag>
                      </span>
                    )
                  })}
                  {shouldShowInput && (
                    <input
                      type="text"
                      className="bare-input full-width no-outline"
                      autoFocus={isActive}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      value={inputValue}
                      onChange={this.handleInputChange}
                    />
                  )}
                </div>
                <div
                  className="row no-grow text light pointer caret-wrapper"
                  onClick={this.handleToggleActivation}
                >
                  {shouldShowPlusIcon ? (
                    <Icons.PlusCircle />
                  ) : (
                    <Icon icon={IconNames.CARET_DOWN} />
                  )}
                </div>
              </div>
            </BaseStruxhubTagsInputWrapper>
            {this.renderSuggestion()}
          </MenuCloser>
        )}
      </BaseStruxhubInput>
    )
  }

  private renderSuggestion() {
    if (!this.store.isActive) {
      return
    }
    const { isTagCreationAllowed } = this.props
    const { suggestedTags, isLoading } = this.store

    let content
    if (isLoading) {
      content = <Loader hint={`${Localization.translator.tagCreation}...`} />
    } else {
      content = suggestedTags.length ? (
        <TagsList tags={suggestedTags} onTagClick={this.handleAddTag} />
      ) : isTagCreationAllowed ? (
        this.renderTagAdditor()
      ) : (
        TagsList.noRowsRenderer()
      )
    }

    return (
      <div
        className="tags-suggestions col absolute full-width beautiful-shadow no-outline no-outline-container"
        style={{
          height: Math.max(
            suggestedTags.length * TAG_ROW_HEIGHT,
            TAG_ROW_HEIGHT,
          ),
        }}
      >
        {content}
      </div>
    )
  }

  private renderTagAdditor() {
    const {
      selectedColor,
      allColors,
      changeColor,
      createNewTag,
      shouldShowColorPicker,
    } = this.store

    return (
      <div className="row full-height pa10">
        {shouldShowColorPicker && (
          <ColorPickerWrapper
            className="float-left"
            value={selectedColor}
            colors={allColors}
            onChange={changeColor}
          />
        )}
        <BaseActionButton
          isEnabled={!!this.store.inputValue}
          className="primary-theme no-grow"
          title={Localization.translator.createNewTag}
          onClick={createNewTag}
          icon={<Icon icon={IconNames.SMALL_PLUS} />}
        />
      </div>
    )
  }

  private handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.store.changeInputValue(evt.target.value)
  }

  private handleToggleActivation = evt => {
    evt.stopPropagation()
    this.store.toggleActivation()
  }

  private handleAddTag = (tag: ITag) => {
    this.store.addTag(tag.id)
  }
}
