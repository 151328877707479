import { AuthErrorCodes } from 'firebase/auth'
import { action, observable } from 'mobx'

import Localization from '../../localization/LocalizationManager'
import InitialState from '../../stores/InitialState'
import AuthenticationStore from '../../stores/domain/Authentication.store'
import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'
import { IServiceUserDto } from '../../types/UserDto'
import Guard from '../../utils/Guard'
import isWeakPassword from '../../utils/isWeakPassword'

export default class EmailLinkerStore {
  @observable public errorMessage: string = ''
  @observable public shouldShowLoader: boolean = false

  @observable public email: string = ''
  @observable public password: string = ''
  @observable public confirmationPassword: string = ''

  public constructor(
    private readonly auth: AuthenticationStore,
    private readonly appState: InitialState,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly onComplete: (linkedEmail: string) => void,
  ) {
    this.email = auth.authEmail
    Guard.requireAll({ auth, projectMembersStore })
  }

  @action.bound
  public resetError() {
    this.errorMessage = ''
  }

  public setErrorMessage(e: any) {
    if (e.code === AuthErrorCodes.EMAIL_EXISTS) {
      this.errorMessage =
        Localization.translator.userValidationErrors.emailIsTaken
    } else {
      this.errorMessage = e.message
    }
  }

  @action.bound
  public async linkEmailProvider() {
    if (!this.validateForm()) {
      return false
    }

    this.shouldShowLoader = true

    try {
      await this.auth.linkEmailProvider(this.email, this.password)
      await this.updateEmailPerCurrentUserDBModel()
    } catch (e) {
      return this.setErrorMessage(e)
    } finally {
      this.shouldShowLoader = false
    }

    this.onComplete(this.email)
  }

  private validateForm(): boolean {
    if (isWeakPassword(this.password)) {
      this.setErrorMessage(
        new Error(Localization.translator.userEmailLinking.weakPassword),
      )
      return false
    }

    if (this.password !== this.confirmationPassword) {
      this.setErrorMessage(
        new Error(Localization.translator.passwordErrors.doNotMatch),
      )
      return false
    }

    return true
  }

  // TODO 3058: provide some graph field for change Email only (TBD)
  private updateEmailPerCurrentUserDBModel() {
    const editingUserJson = {
      ...this.appState.user.getCopy(),
      email: this.email,
    } as IServiceUserDto
    const userProjectSettings = this.appState.userActiveProjectSettings.toDto()
    editingUserJson.userProjectSettings = userProjectSettings

    return this.projectMembersStore.saveMembers([editingUserJson])
  }
}
