import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import StatusUpdateInputBase from '~/client/src/shared/components/ActivityItemContentWithStatusUpdate/componets/StatusUpdateInputBase'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Activity from '~/client/src/shared/models/Activity'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { UPDATE_ACTIVITY_COMPANIES_STATUSES } from '~/client/src/shared/stores/EventStore/eventConstants'

import ProjectDateStore from '../../../stores/ui/ProjectDate.store'
import BulkStatusUpdateStore, { ALL_COMPANIES } from '../BulkStatusUpdate.store'

// translated

export interface IProps {
  commonStatusUpdate: StatusUpdate
  date: Date
  company: string
  activity: Activity
  eventsStore?: EventsStore
  projectDateStore?: ProjectDateStore
  store: BulkStatusUpdateStore
}

@inject('eventsStore', 'projectDateStore')
@observer
export default class BulkManpowerUpdate extends React.Component<IProps> {
  private get openStatusMap(): Map<string, boolean> {
    return this.props.store.manpowerInputOpenStatus
  }

  private get loadingStatusMap(): Map<string, boolean> {
    return this.props.store.manpowerInputLoadingStatus
  }

  private get valueStatusMap(): Map<string, number> {
    return this.props.store.manpowerInputValueStatus
  }

  private get activityKey(): string {
    const { activity, company, date, projectDateStore } = this.props
    return `${activity.code}-${company}-${projectDateStore
      .startOfDay(date)
      .getTime()}`
  }

  @computed
  private get didValueUpdate(): boolean {
    const { commonStatusUpdate, company, date, projectDateStore } = this.props
    const { allCompanies } = this.props.store
    const { dateFor, didUpdateByCompany } = commonStatusUpdate

    if (!projectDateStore.isSameDay(new Date(+dateFor), date)) {
      return false
    }

    if (company === ALL_COMPANIES) {
      return allCompanies.some(companyName =>
        didUpdateByCompany(
          companyName,
          projectDateStore.isSameDay,
          false,
          true,
        ),
      )
    }

    return didUpdateByCompany(company, projectDateStore.isSameDay, false, true)
  }

  private get leftCaption(): JSX.Element {
    const { manpowerInputMessage, areDaysWithoutManpowerUpdate } =
      this.props.store
    if (manpowerInputMessage) {
      return (
        <div className="row y-center">
          <Icons.CheckFillBlue className="no-grow" />
          <div className="text large primary-blue pl5">
            {manpowerInputMessage}
          </div>
        </div>
      )
    }
    if (this.didValueUpdate && areDaysWithoutManpowerUpdate) {
      return (
        <div className="text red large" onClick={this.bulkUpdateManpower}>
          {Localization.translator.copyToMissing}
        </div>
      )
    }

    return (
      <div className="text red extra-large">
        {Localization.translator.manpower}
      </div>
    )
  }

  private get icon(): JSX.Element {
    if (this.didValueUpdate) {
      return <Icons.ResourcesBlue className="col" />
    }
    return <Icons.ResourcesGray className="col" />
  }

  public render() {
    return (
      <StatusUpdateInputBase
        value={this.props.store.currentManpowerValue}
        didUpdate={this.didValueUpdate}
        leftCaption={this.leftCaption}
        icon={this.icon}
        onChange={this.updateManpower}
        openStatusMap={this.openStatusMap}
        loadingStatusMap={this.loadingStatusMap}
        valueStatusMap={this.valueStatusMap}
        activityKey={this.activityKey}
      />
    )
  }

  private updateManpower = (manpower: number) => {
    const { company, activity, eventsStore, date, store, projectDateStore } =
      this.props

    const companies = company === ALL_COMPANIES ? store.allCompanies : [company]

    eventsStore.dispatch(
      UPDATE_ACTIVITY_COMPANIES_STATUSES,
      activity.code,
      Math.min(projectDateStore.endOfDay(date).getTime(), Date.now()),
      companies,
      store.currentPercentCompleteValue,
      manpower,
    )
  }

  private bulkUpdateManpower = (event: React.SyntheticEvent) => {
    event.stopPropagation()
    this.props.store.bulkUpdateManpower()
  }
}
