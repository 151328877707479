import * as React from 'react'

import { observer } from 'mobx-react'

// localization: no display text to translate

interface IProps {
  caption?: string
  backClicked?: any
}

@observer
export default class DesktopDetailsHeader extends React.Component<IProps> {
  public render() {
    const rightArrowIcon = '/static/icons/right.svg'

    const { caption, backClicked } = this.props

    return (
      <div className="col">
        <div className="row pa20 bb-light-grey bg-white">
          <div className="col no-grow pr20">
            <img src={rightArrowIcon} onClick={backClicked} />
          </div>
          <div className="col" onClick={backClicked}>
            <div className="text large bold">{caption}</div>
          </div>
        </div>
      </div>
    )
  }
}
