import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

import Localization from '../localization/LocalizationManager'
import Announcement from '../models/Announcement'
import InitialState from '../stores/InitialState'
import ProjectDateStore from '../stores/ui/ProjectDate.store'
import AnnouncementParsedContent from './AnnouncementEditionForm/components/AnnouncementParsedContent'
import MenuCloser from './MenuCloser'
import FileAttachment from './WorkflowCard/FileAttachment'
import WorkflowCardLocationLabel from './WorkflowCard/LocationLabel'

import './WorkflowCard/WorkflowCard.scss'
import './AnnouncementListItem.scss'

interface IProps {
  announcement: Announcement

  isSelected?: boolean
  className?: string
  isContentExpanded?: boolean
  onClick?: (announcement: Announcement) => void
  onDelete?: (announcement: Announcement) => void
  onEdit?: (announcement: Announcement) => void
  projectDateStore?: ProjectDateStore
  state?: InitialState
  isMapCard?: boolean
  shouldShowPdfPreview?: boolean
  shouldRenderImagesPreviewLine?: boolean
}

const IMAGES_IN_PREVIEW_SECTION = 3

@inject('projectDateStore', 'state')
@observer
export default class AnnouncementListItem extends React.Component<IProps> {
  @observable private shouldShowMenu: boolean = false

  public render() {
    const {
      className = 'pa12 bb-light-grey workflow-card text large overflow-hidden',
      announcement,
      projectDateStore: { getPronounWithMonthDayAndTimeString },
      isSelected,
      isMapCard,
      shouldShowPdfPreview,
      isContentExpanded,
    } = this.props
    const {
      getDisplayedName,
      title,
      location,
      attachments,
      dateToAppear,
      startDate,
    } = announcement

    const locations = location ? [location] : []

    return (
      <div
        className={classList({
          [className]: true,
          'announcement-list-item': true,
          'selected-card': isSelected,
        })}
        onClick={this.onClick}
      >
        <div className="row pt5 title-row pb10">
          <Icons.Announcement
            className={classList({
              'no-grow mr4 main-icon announcement-icon': true,
              mt10: isMapCard,
            })}
          />
          <span className="bold extra-large inline-block text title-text vertical-align-text-bottom">
            {isMapCard ? title : getDisplayedName(true)}
          </span>

          {!isMapCard && (
            <>
              <span className="text grey vertical-align-text-bottom ml10 no-grow no-white-space-wrap">
                {getPronounWithMonthDayAndTimeString(dateToAppear || startDate)}
              </span>
              <div className="relative no-grow">
                {this.renderActionButton()}
              </div>
            </>
          )}
        </div>
        <AnnouncementParsedContent
          announcement={announcement}
          isContentExpanded={isContentExpanded}
        />
        {this.renderImagePreviewSection()}
        <div className="pt5 no-wrap pl12">
          {!!attachments.length &&
            attachments.map((attachment, attachmentIdx) => (
              <FileAttachment
                key={attachmentIdx}
                attachment={attachment}
                shouldShowPdfPreview={shouldShowPdfPreview}
                isPdfPreviewSmall={true}
              />
            ))}
        </div>
        <div className="pt5 nowrap pl20">
          <WorkflowCardLocationLabel
            locations={locations}
            isOneColor={false}
            shouldShowAsTag={!isMapCard}
          />
        </div>
      </div>
    )
  }

  private renderImagePreviewSection() {
    const {
      shouldRenderImagesPreviewLine,
      announcement: { contentImages },
    } = this.props
    if (!shouldRenderImagesPreviewLine || !contentImages.length) {
      return
    }
    const itemsOverLimit = contentImages.length - IMAGES_IN_PREVIEW_SECTION

    return (
      <div className="image-previews-section mt15 no-wrap row pl24">
        {contentImages
          .slice(0, IMAGES_IN_PREVIEW_SECTION)
          .map((image, imageIdx) => {
            if (
              imageIdx === IMAGES_IN_PREVIEW_SECTION - 1 &&
              itemsOverLimit > 0
            ) {
              return (
                <div key={imageIdx} className="last-image relative">
                  <img src={image.fileUrl} className="mr8 absolute" />
                  <span className="text large bold white absolute">
                    +{itemsOverLimit}
                  </span>
                </div>
              )
            }

            return <img key={imageIdx} src={image.fileUrl} className="mr8" />
          })}
      </div>
    )
  }

  private renderActionButton() {
    const { onDelete, onEdit } = this.props

    switch (true) {
      case !this.isAdmin || !onDelete:
        return null
      case !!onDelete && !onEdit:
        return (
          <div
            className="float-right primary-blue pointer text"
            onClick={this.onDeleteAnnouncement}
          >
            <Icon icon={IconNames.CROSS} iconSize={20} />
          </div>
        )
      case !!onDelete && !!onEdit:
        return (
          <MenuCloser closeMenu={this.closeMenu} isOpen={this.shouldShowMenu}>
            <div className="float-right pointer text" onClick={this.toggleMenu}>
              <img
                className="no-grow ml8"
                src="/static/icons/single-verticle-dots.svg"
              />
            </div>
            {this.shouldShowMenu && this.renderActionMenu()}
          </MenuCloser>
        )
    }
  }

  private onClick = () => {
    const { announcement, onClick } = this.props
    if (onClick) {
      onClick(announcement)
    }
  }

  private onDeleteAnnouncement = (event: React.MouseEvent) => {
    event.stopPropagation()
    const { onDelete, announcement } = this.props
    onDelete(announcement)
    this.toggleMenu()
  }

  private onEditAnnouncement = (event: React.MouseEvent) => {
    event.stopPropagation()
    const { onEdit, announcement } = this.props
    onEdit(announcement)
    this.toggleMenu()
  }

  private renderActionMenu() {
    return (
      <div className="action-menu absolute col brada10 pa10 bg-white text bold">
        <div className="row pointer" onClick={this.onEditAnnouncement}>
          <Icons.Edit className="no-grow option mr10" />
          {Localization.translator.edit_verb}
        </div>
        <div className="row pointer" onClick={this.onDeleteAnnouncement}>
          <Icons.Delete className="no-grow option mr10" />
          {Localization.translator.delete}
        </div>
      </div>
    )
  }

  private toggleMenu = (event?: React.MouseEvent) => {
    event?.stopPropagation()
    this.shouldShowMenu = !this.shouldShowMenu
  }

  private closeMenu = () => {
    this.shouldShowMenu = false
  }

  private get isAdmin() {
    return this.props.state.userActiveProjectSettings?.isAdmin
  }
}
