import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ICastFollowingFieldsFragment = Pick<
  Types.ICastFollowing,
  'id' | 'projectId' | 'entityId' | 'userId' | 'createdAt' | 'updatedAt'
>

export type IGetCastFollowingListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetCastFollowingListQuery = {
  castFollowings?: Types.Maybe<{ data: Array<ICastFollowingFieldsFragment> }>
}

export type ISaveManyCastFollowingsMutationVariables = Types.Exact<{
  castFollowings:
    | Array<Types.Maybe<Types.ICastFollowingInput>>
    | Types.Maybe<Types.ICastFollowingInput>
}>

export type ISaveManyCastFollowingsMutation = {
  saveManyCastFollowings?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.ICastFollowing, 'id'>>>
  >
}

export type IDeleteManyCastFollowingsMutationVariables = Types.Exact<{
  castFollowingIds:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteManyCastFollowingsMutation = Pick<
  Types.IMutation,
  'deleteManyCastFollowings'
>

export const CastFollowingFieldsFragmentDoc = gql`
  fragment CastFollowingFields on CastFollowing {
    id
    projectId
    entityId
    userId
    createdAt
    updatedAt
  }
`
export const GetCastFollowingListDocument = gql`
  query GetCastFollowingList($projectId: ObjectId!) {
    castFollowings(limit: 1000000, projectId: $projectId) {
      data {
        ...CastFollowingFields
      }
    }
  }
  ${CastFollowingFieldsFragmentDoc}
`

/**
 * __useGetCastFollowingListQuery__
 *
 * To run a query within a React component, call `useGetCastFollowingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCastFollowingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCastFollowingListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetCastFollowingListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetCastFollowingListQuery,
    IGetCastFollowingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetCastFollowingListQuery,
    IGetCastFollowingListQueryVariables
  >(GetCastFollowingListDocument, options)
}
export function useGetCastFollowingListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetCastFollowingListQuery,
    IGetCastFollowingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetCastFollowingListQuery,
    IGetCastFollowingListQueryVariables
  >(GetCastFollowingListDocument, options)
}
export type GetCastFollowingListQueryHookResult = ReturnType<
  typeof useGetCastFollowingListQuery
>
export type GetCastFollowingListLazyQueryHookResult = ReturnType<
  typeof useGetCastFollowingListLazyQuery
>
export type GetCastFollowingListQueryResult = Apollo.QueryResult<
  IGetCastFollowingListQuery,
  IGetCastFollowingListQueryVariables
>
export const SaveManyCastFollowingsDocument = gql`
  mutation SaveManyCastFollowings($castFollowings: [CastFollowingInput]!) {
    saveManyCastFollowings(castFollowings: $castFollowings) {
      id
    }
  }
`
export type ISaveManyCastFollowingsMutationFn = Apollo.MutationFunction<
  ISaveManyCastFollowingsMutation,
  ISaveManyCastFollowingsMutationVariables
>

/**
 * __useSaveManyCastFollowingsMutation__
 *
 * To run a mutation, you first call `useSaveManyCastFollowingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyCastFollowingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyCastFollowingsMutation, { data, loading, error }] = useSaveManyCastFollowingsMutation({
 *   variables: {
 *      castFollowings: // value for 'castFollowings'
 *   },
 * });
 */
export function useSaveManyCastFollowingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyCastFollowingsMutation,
    ISaveManyCastFollowingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyCastFollowingsMutation,
    ISaveManyCastFollowingsMutationVariables
  >(SaveManyCastFollowingsDocument, options)
}
export type SaveManyCastFollowingsMutationHookResult = ReturnType<
  typeof useSaveManyCastFollowingsMutation
>
export type SaveManyCastFollowingsMutationResult =
  Apollo.MutationResult<ISaveManyCastFollowingsMutation>
export type SaveManyCastFollowingsMutationOptions = Apollo.BaseMutationOptions<
  ISaveManyCastFollowingsMutation,
  ISaveManyCastFollowingsMutationVariables
>
export const DeleteManyCastFollowingsDocument = gql`
  mutation DeleteManyCastFollowings($castFollowingIds: [ObjectId]!) {
    deleteManyCastFollowings(ids: $castFollowingIds)
  }
`
export type IDeleteManyCastFollowingsMutationFn = Apollo.MutationFunction<
  IDeleteManyCastFollowingsMutation,
  IDeleteManyCastFollowingsMutationVariables
>

/**
 * __useDeleteManyCastFollowingsMutation__
 *
 * To run a mutation, you first call `useDeleteManyCastFollowingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyCastFollowingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyCastFollowingsMutation, { data, loading, error }] = useDeleteManyCastFollowingsMutation({
 *   variables: {
 *      castFollowingIds: // value for 'castFollowingIds'
 *   },
 * });
 */
export function useDeleteManyCastFollowingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteManyCastFollowingsMutation,
    IDeleteManyCastFollowingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteManyCastFollowingsMutation,
    IDeleteManyCastFollowingsMutationVariables
  >(DeleteManyCastFollowingsDocument, options)
}
export type DeleteManyCastFollowingsMutationHookResult = ReturnType<
  typeof useDeleteManyCastFollowingsMutation
>
export type DeleteManyCastFollowingsMutationResult =
  Apollo.MutationResult<IDeleteManyCastFollowingsMutation>
export type DeleteManyCastFollowingsMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteManyCastFollowingsMutation,
    IDeleteManyCastFollowingsMutationVariables
  >
