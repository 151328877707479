import { DocumentNode } from 'graphql'
import { action, observable } from 'mobx'

import { IQuery, ISubscription } from '~/client/graph'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import IDeliveryAttributeStore from '~/client/src/shared/stores/domain/interfaces/IDeliveryAttributeStore'
import CommonDeliveryAttributeDto from '~/client/src/shared/types/CommonDeliveryAttributeDto'
import IDeliveryAttributeDto from '~/client/src/shared/types/IDeliveryAttributeDto'

import GraphExecutor from '../../utils/GraphExecutor'
import Guard from '../../utils/Guard'
import GraphExecutorStore from './GraphExecutor.store'

export default abstract class CommonDeliveryAttributesStore
  implements IDeliveryAttributeStore
{
  @observable public isDataReceived = false

  protected readonly loadQuery: DocumentNode = null
  protected readonly subscriptionQuery: DocumentNode = null
  protected readonly saveMutation: DocumentNode = null
  protected readonly deleteMutation: DocumentNode = null

  protected readonly collectionKey: string = ''

  public constructor(
    protected readonly eventsStore: EventsStore,
    protected readonly graphExecutorStore: GraphExecutorStore,
  ) {
    Guard.requireAll({ eventsStore, graphExecutorStore })
  }

  public abstract get list(): CommonDeliveryAttributeDto[]
  public abstract get byId(): Map<string, CommonDeliveryAttributeDto>

  public clearList() {
    this.byId.clear()
    this.isDataReceived = false
  }

  public receiveList(list: any[]) {
    this.clearList()

    list.forEach(dto => {
      this.byId.set(dto.id, CommonDeliveryAttributeDto.fromDto(dto))
    })

    this.isDataReceived = true
  }

  public receiveOne(id: string, dto: IDeliveryAttributeDto) {
    if (dto) {
      this.byId.set(id, CommonDeliveryAttributeDto.fromDto(dto))
    } else {
      this.byId.delete(id)
    }
  }

  public createFromName(name: string, cb?: (attrId: string) => void) {
    const value = new CommonDeliveryAttributeDto(
      null,
      name,
      this.eventsStore.appState.activeProject.id,
      false,
    )
    this.saveItem(value, cb)
  }

  public getInstanceById = (
    id: string,
    shouldFindDeleted?: boolean,
  ): CommonDeliveryAttributeDto => {
    if (!this.byId.has(id)) {
      return
    }

    const instance = this.byId.get(id)
    if (instance && (!instance.isDeleted || shouldFindDeleted)) {
      return instance
    }
  }

  public getAttributeString(
    attributeId: string,
    shouldShowDeletedUnit: boolean,
  ) {
    return this.getInstanceById(attributeId, shouldShowDeletedUnit)?.name || '—'
  }

  public removeItems(ids: string[]) {
    this.eventsStore.dispatch(
      e.DELETE_DELIVERY_ATTRIBUTES,
      ids,
      this.deleteMutation,
    )
  }

  public saveItem(
    value: CommonDeliveryAttributeDto,
    cb?: (attrId: string) => void,
  ) {
    this.eventsStore.dispatch(
      e.SAVE_DELIVERY_ATTRIBUTES,
      [value.toDto()],
      this.saveMutation,
      cb,
    )
  }

  public updateName(id: string, name: string) {
    const dto = this.byId.get(id)
    dto.name = name
    this.saveItem(dto as any)
  }

  public loadAndListenTo() {
    this.eventsStore.dispatch(
      e.LOAD_DELIVERY_ATTRIBUTES,
      this.collectionKey,
      this.loadQuery,
      this.handleDataReceiving,
    )

    this.graphExecutorStore.subscribe(
      this.subscriptionQuery,
      { projectId: this.eventsStore.appState.activeProject.id },
      false,
      this.collectionKey,
      this.handleDataUpdating,
    )
  }

  @action.bound
  private handleDataReceiving(res: IQuery) {
    const list = res[this.collectionKey]?.data || []
    this.receiveList(list)
  }

  @action.bound
  private handleDataUpdating(res: ISubscription) {
    const attr = GraphExecutor.flattenResponse(res)
    this.receiveOne(attr.id, attr.item)
  }
}
