import * as React from 'react'

import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import BuildingLevelsSelector from '~/client/src/shared/components/BuildingLevelsSelector/BuildingLevelsSelector'
import Location from '~/client/src/shared/components/SitemapAttributeSelector/Location'
import Level from '~/client/src/shared/models/LocationObjects/Level'
import OffloadingEquipment from '~/client/src/shared/models/LocationObjects/OffloadingEquipment'
import VerticalObject from '~/client/src/shared/models/LocationObjects/VerticalObject'
import BuildingsStore from '~/client/src/shared/stores/domain/Buildings.store'
import LevelsStore from '~/client/src/shared/stores/domain/Levels.store'
import { getBandTitleByTagType } from '~/client/src/shared/utils/TagHelper'
import { sortLevels } from '~/client/src/shared/utils/sortingFunctions'

const accessibleLevelsProperties = {
  properties: 'Properties',
  accessibleLevels: 'Accessible levels',
  editAccessibleLevels: 'Edit accessible levels',
  selectAccessibleLevels: 'Select accessible levels',
}

interface IProps {
  dataObject: VerticalObject | OffloadingEquipment

  className?: string
  onChange?: () => void

  levelsStore?: LevelsStore
  buildingsStore?: BuildingsStore
}

@inject('levelsStore', 'buildingsStore')
@observer
export default class ObjectAccessibleLevelsProperties extends React.Component<IProps> {
  @observable private isLevelsSelectorDisplayed: boolean = false

  public render() {
    if (!this.allowedLevels.length || !this.buildingParent) {
      return null
    }

    const { className, dataObject } = this.props
    const { type, accessibleLevels } = dataObject

    return (
      <div
        className={classList({
          'object-accessible-levels-properties properties-section pa12': true,
          [className]: !!className,
        })}
      >
        <div className="text title uppercase pb12">
          {`${getBandTitleByTagType(type)} ${
            accessibleLevelsProperties.properties
          }`}
        </div>
        <div className="relative">
          <div className="text pb4">
            {accessibleLevelsProperties.accessibleLevels}
          </div>
          {this.accessibleLevels.map(l => (
            <div className="y-center row py6" key={l.id}>
              <div>
                <Location dto={l} />
              </div>
              <div className="no-grow">
                <div
                  className="cross-icon"
                  onClick={this.removeLevel.bind(this, l)}
                >
                  ✖
                </div>
              </div>
            </div>
          ))}
          <div
            className="pt12 text blue-highlight bold large pointer"
            onClick={this.openLevelsSelector}
          >
            {accessibleLevelsProperties.editAccessibleLevels}
          </div>
        </div>
        {this.isLevelsSelectorDisplayed && (
          <BuildingLevelsSelector
            building={this.buildingParent}
            selectedLevels={accessibleLevels}
            title={accessibleLevelsProperties.selectAccessibleLevels}
            onApply={this.onAccessibleLevelsChange}
            onHide={this.closeLevelsSelector}
          />
        )}
      </div>
    )
  }

  @computed
  private get allowedLevels(): Level[] {
    const { dataObject, levelsStore: levelsStore } = this.props
    if (!dataObject.hasParent) {
      return []
    }
    return levelsStore.list.filter(l => l.hasCommonParent(dataObject))
  }

  @computed
  private get buildingParent() {
    const { dataObject, buildingsStore: buildingsStore } = this.props
    const { parent } = dataObject
    return parent && buildingsStore.byId.get(parent.parentId)
  }

  private get accessibleLevels() {
    const { accessibleLevels } = this.props.dataObject

    const levels = accessibleLevels
      .map(id => this.allowedLevels.find(l => l.id === id))
      .filter(l => l)

    return (
      this.buildingParent?.sortLevelsByCustomOrder(levels) ||
      levels.sort((a, b) => sortLevels(b, a))
    )
  }

  @action.bound
  private openLevelsSelector() {
    this.isLevelsSelectorDisplayed = true
  }

  @action.bound
  private closeLevelsSelector() {
    this.isLevelsSelectorDisplayed = false
  }

  private onAccessibleLevelsChange = (newLevels: string[]) => {
    const { dataObject, onChange } = this.props

    dataObject.accessibleLevels = newLevels
    this.closeLevelsSelector()

    onChange?.()
  }

  private removeLevel(level: Level) {
    const { dataObject, onChange } = this.props

    dataObject.accessibleLevels = this.accessibleLevels
      .map(l => l.id)
      .filter(id => id !== level.id)

    onChange?.()
  }
}
