import * as React from 'react'

import { observer } from 'mobx-react'

import { TagType } from '../../enums/TagType'
import { ISharedProps } from './BaseStruxhubInput'
import StruxhubTagsInput from './StruxhubTagsInput/StruxhubTagsInput'

export interface ITagInputProps extends ISharedProps {
  value: string
  onChange: (newValue: string) => void
  tagType: TagType
  isTagCreationAllowed?: boolean
  restrictedValues?: string[]
}

@observer
export default class StruxhubTagInput extends React.Component<ITagInputProps> {
  public render() {
    const { value, tagType, restrictedValues, isTagCreationAllowed } =
      this.props

    return (
      <StruxhubTagsInput
        {...this.props}
        tagType={tagType}
        values={[value]}
        isSingleSelectionMode={true}
        onChange={this.handleChange}
        restrictedValues={restrictedValues}
        isTagCreationAllowed={isTagCreationAllowed}
      />
    )
  }

  private handleChange = ([value]: string[]) => {
    this.props.onChange(value)
  }
}
