import Localization from '~/client/src/shared/localization/LocalizationManager'

import { Frequency, Period } from './Enums'

interface INameValuePair {
  value: number | string
  name: string
}

const optionsPeriod: INameValuePair[] = [
  { value: Period.Week, name: Localization.translator.last7Days },
  { value: Period.Month, name: Localization.translator.last30Days },
  {
    value: Period.Month4,
    name: Localization.translator.last120Days,
  },
  {
    value: Period.Year,
    name: Localization.translator.last365Days,
  },
  {
    value: Period.EntireProject,
    name: Localization.translator.entireProject,
  },
]

const optionsFrequency: INameValuePair[] = [
  { value: Frequency.WEEKLY, name: Localization.translator.weekly },
  { value: Frequency.MONTHLY, name: Localization.translator.monthly },
]

export { optionsPeriod, optionsFrequency }
