import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ISitePermitFollowingFieldsFragment = Pick<
  Types.ISitePermitFollowing,
  'id' | 'projectId' | 'entityId' | 'userId' | 'createdAt' | 'updatedAt'
>

export type IGetSitePermitFollowingListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetSitePermitFollowingListQuery = {
  sitePermitFollowings?: Types.Maybe<{
    data: Array<ISitePermitFollowingFieldsFragment>
  }>
}

export type IListenToSitePermitFollowingSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToSitePermitFollowingSubscription = {
  sitePermitFollowing?: Types.Maybe<
    Pick<Types.ISitePermitFollowingChangeEvent, 'id'> & {
      item?: Types.Maybe<ISitePermitFollowingFieldsFragment>
    }
  >
}

export type ISaveManySitePermitFollowingsMutationVariables = Types.Exact<{
  sitePermitFollowings:
    | Array<Types.Maybe<Types.ISitePermitFollowingInput>>
    | Types.Maybe<Types.ISitePermitFollowingInput>
}>

export type ISaveManySitePermitFollowingsMutation = {
  saveManySitePermitFollowings?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.ISitePermitFollowing, 'id'>>>
  >
}

export type IDeleteManySitePermitFollowingsMutationVariables = Types.Exact<{
  sitePermitFollowingIds:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteManySitePermitFollowingsMutation = Pick<
  Types.IMutation,
  'deleteManySitePermitFollowings'
>

export const SitePermitFollowingFieldsFragmentDoc = gql`
  fragment SitePermitFollowingFields on SitePermitFollowing {
    id
    projectId
    entityId
    userId
    createdAt
    updatedAt
  }
`
export const GetSitePermitFollowingListDocument = gql`
  query GetSitePermitFollowingList($projectId: ObjectId!) {
    sitePermitFollowings(limit: 1000000, projectId: $projectId) {
      data {
        ...SitePermitFollowingFields
      }
    }
  }
  ${SitePermitFollowingFieldsFragmentDoc}
`

/**
 * __useGetSitePermitFollowingListQuery__
 *
 * To run a query within a React component, call `useGetSitePermitFollowingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitePermitFollowingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitePermitFollowingListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetSitePermitFollowingListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetSitePermitFollowingListQuery,
    IGetSitePermitFollowingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetSitePermitFollowingListQuery,
    IGetSitePermitFollowingListQueryVariables
  >(GetSitePermitFollowingListDocument, options)
}
export function useGetSitePermitFollowingListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetSitePermitFollowingListQuery,
    IGetSitePermitFollowingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetSitePermitFollowingListQuery,
    IGetSitePermitFollowingListQueryVariables
  >(GetSitePermitFollowingListDocument, options)
}
export type GetSitePermitFollowingListQueryHookResult = ReturnType<
  typeof useGetSitePermitFollowingListQuery
>
export type GetSitePermitFollowingListLazyQueryHookResult = ReturnType<
  typeof useGetSitePermitFollowingListLazyQuery
>
export type GetSitePermitFollowingListQueryResult = Apollo.QueryResult<
  IGetSitePermitFollowingListQuery,
  IGetSitePermitFollowingListQueryVariables
>
export const ListenToSitePermitFollowingDocument = gql`
  subscription ListenToSitePermitFollowing($projectId: ObjectId!) {
    sitePermitFollowing(projectId: $projectId) {
      id
      item {
        ...SitePermitFollowingFields
      }
    }
  }
  ${SitePermitFollowingFieldsFragmentDoc}
`

/**
 * __useListenToSitePermitFollowingSubscription__
 *
 * To run a query within a React component, call `useListenToSitePermitFollowingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToSitePermitFollowingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToSitePermitFollowingSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToSitePermitFollowingSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToSitePermitFollowingSubscription,
    IListenToSitePermitFollowingSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToSitePermitFollowingSubscription,
    IListenToSitePermitFollowingSubscriptionVariables
  >(ListenToSitePermitFollowingDocument, options)
}
export type ListenToSitePermitFollowingSubscriptionHookResult = ReturnType<
  typeof useListenToSitePermitFollowingSubscription
>
export type ListenToSitePermitFollowingSubscriptionResult =
  Apollo.SubscriptionResult<IListenToSitePermitFollowingSubscription>
export const SaveManySitePermitFollowingsDocument = gql`
  mutation SaveManySitePermitFollowings(
    $sitePermitFollowings: [SitePermitFollowingInput]!
  ) {
    saveManySitePermitFollowings(sitePermitFollowings: $sitePermitFollowings) {
      id
    }
  }
`
export type ISaveManySitePermitFollowingsMutationFn = Apollo.MutationFunction<
  ISaveManySitePermitFollowingsMutation,
  ISaveManySitePermitFollowingsMutationVariables
>

/**
 * __useSaveManySitePermitFollowingsMutation__
 *
 * To run a mutation, you first call `useSaveManySitePermitFollowingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManySitePermitFollowingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManySitePermitFollowingsMutation, { data, loading, error }] = useSaveManySitePermitFollowingsMutation({
 *   variables: {
 *      sitePermitFollowings: // value for 'sitePermitFollowings'
 *   },
 * });
 */
export function useSaveManySitePermitFollowingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManySitePermitFollowingsMutation,
    ISaveManySitePermitFollowingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManySitePermitFollowingsMutation,
    ISaveManySitePermitFollowingsMutationVariables
  >(SaveManySitePermitFollowingsDocument, options)
}
export type SaveManySitePermitFollowingsMutationHookResult = ReturnType<
  typeof useSaveManySitePermitFollowingsMutation
>
export type SaveManySitePermitFollowingsMutationResult =
  Apollo.MutationResult<ISaveManySitePermitFollowingsMutation>
export type SaveManySitePermitFollowingsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveManySitePermitFollowingsMutation,
    ISaveManySitePermitFollowingsMutationVariables
  >
export const DeleteManySitePermitFollowingsDocument = gql`
  mutation DeleteManySitePermitFollowings(
    $sitePermitFollowingIds: [ObjectId]!
  ) {
    deleteManySitePermitFollowings(ids: $sitePermitFollowingIds)
  }
`
export type IDeleteManySitePermitFollowingsMutationFn = Apollo.MutationFunction<
  IDeleteManySitePermitFollowingsMutation,
  IDeleteManySitePermitFollowingsMutationVariables
>

/**
 * __useDeleteManySitePermitFollowingsMutation__
 *
 * To run a mutation, you first call `useDeleteManySitePermitFollowingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManySitePermitFollowingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManySitePermitFollowingsMutation, { data, loading, error }] = useDeleteManySitePermitFollowingsMutation({
 *   variables: {
 *      sitePermitFollowingIds: // value for 'sitePermitFollowingIds'
 *   },
 * });
 */
export function useDeleteManySitePermitFollowingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteManySitePermitFollowingsMutation,
    IDeleteManySitePermitFollowingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteManySitePermitFollowingsMutation,
    IDeleteManySitePermitFollowingsMutationVariables
  >(DeleteManySitePermitFollowingsDocument, options)
}
export type DeleteManySitePermitFollowingsMutationHookResult = ReturnType<
  typeof useDeleteManySitePermitFollowingsMutation
>
export type DeleteManySitePermitFollowingsMutationResult =
  Apollo.MutationResult<IDeleteManySitePermitFollowingsMutation>
export type DeleteManySitePermitFollowingsMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteManySitePermitFollowingsMutation,
    IDeleteManySitePermitFollowingsMutationVariables
  >
