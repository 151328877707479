import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IBasemapFieldsFragment = Pick<
  Types.IBasemap,
  | 'id'
  | 'name'
  | 'projectId'
  | 'fileName'
  | 'source'
  | 'createdAt'
  | 'updatedAt'
>

export type IBasemapsByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IBasemapsByProjectIdQuery = {
  basemaps?: Types.Maybe<{ data: Array<IBasemapFieldsFragment> }>
}

export type IListenBasemapsByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenBasemapsByProjectIdSubscription = {
  basemap?: Types.Maybe<
    Pick<Types.IBasemapChangeEvent, 'id'> & {
      item?: Types.Maybe<IBasemapFieldsFragment>
    }
  >
}

export type ISaveBasemapMutationVariables = Types.Exact<{
  basemap: Types.IBasemapInput
}>

export type ISaveBasemapMutation = {
  saveBasemap?: Types.Maybe<Pick<Types.IBasemap, 'id'>>
}

export const BasemapFieldsFragmentDoc = gql`
  fragment BasemapFields on Basemap {
    id
    name
    projectId
    fileName
    source
    createdAt
    updatedAt
  }
`
export const BasemapsByProjectIdDocument = gql`
  query BasemapsByProjectId($projectId: ObjectId!) {
    basemaps(projectId: $projectId, limit: 1000000) {
      data {
        ...BasemapFields
      }
    }
  }
  ${BasemapFieldsFragmentDoc}
`

/**
 * __useBasemapsByProjectIdQuery__
 *
 * To run a query within a React component, call `useBasemapsByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasemapsByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasemapsByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useBasemapsByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IBasemapsByProjectIdQuery,
    IBasemapsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IBasemapsByProjectIdQuery,
    IBasemapsByProjectIdQueryVariables
  >(BasemapsByProjectIdDocument, options)
}
export function useBasemapsByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IBasemapsByProjectIdQuery,
    IBasemapsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IBasemapsByProjectIdQuery,
    IBasemapsByProjectIdQueryVariables
  >(BasemapsByProjectIdDocument, options)
}
export type BasemapsByProjectIdQueryHookResult = ReturnType<
  typeof useBasemapsByProjectIdQuery
>
export type BasemapsByProjectIdLazyQueryHookResult = ReturnType<
  typeof useBasemapsByProjectIdLazyQuery
>
export type BasemapsByProjectIdQueryResult = Apollo.QueryResult<
  IBasemapsByProjectIdQuery,
  IBasemapsByProjectIdQueryVariables
>
export const ListenBasemapsByProjectIdDocument = gql`
  subscription ListenBasemapsByProjectId($projectId: ObjectId!) {
    basemap(projectId: $projectId) {
      id
      item {
        ...BasemapFields
      }
    }
  }
  ${BasemapFieldsFragmentDoc}
`

/**
 * __useListenBasemapsByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenBasemapsByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenBasemapsByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenBasemapsByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenBasemapsByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenBasemapsByProjectIdSubscription,
    IListenBasemapsByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenBasemapsByProjectIdSubscription,
    IListenBasemapsByProjectIdSubscriptionVariables
  >(ListenBasemapsByProjectIdDocument, options)
}
export type ListenBasemapsByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenBasemapsByProjectIdSubscription
>
export type ListenBasemapsByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenBasemapsByProjectIdSubscription>
export const SaveBasemapDocument = gql`
  mutation SaveBasemap($basemap: BasemapInput!) {
    saveBasemap(basemap: $basemap) {
      id
    }
  }
`
export type ISaveBasemapMutationFn = Apollo.MutationFunction<
  ISaveBasemapMutation,
  ISaveBasemapMutationVariables
>

/**
 * __useSaveBasemapMutation__
 *
 * To run a mutation, you first call `useSaveBasemapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBasemapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBasemapMutation, { data, loading, error }] = useSaveBasemapMutation({
 *   variables: {
 *      basemap: // value for 'basemap'
 *   },
 * });
 */
export function useSaveBasemapMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveBasemapMutation,
    ISaveBasemapMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveBasemapMutation,
    ISaveBasemapMutationVariables
  >(SaveBasemapDocument, options)
}
export type SaveBasemapMutationHookResult = ReturnType<
  typeof useSaveBasemapMutation
>
export type SaveBasemapMutationResult =
  Apollo.MutationResult<ISaveBasemapMutation>
export type SaveBasemapMutationOptions = Apollo.BaseMutationOptions<
  ISaveBasemapMutation,
  ISaveBasemapMutationVariables
>
