import KnownTranslatorKeys from '../localization/knownTranslatorKeys'
import { enumToList } from '../utils/converters'

export enum MaterialsGroupingOption {
  NONE = 'None',

  MATERIAL_CATEGORY = 'MaterialCategory',
  MATERIAL = 'Material',
  MATERIAL_DESCRIPTION = 'MaterialDescription',

  COMPANY = 'Company',
  VENDOR = 'Vendor',
  DATE = 'Date',
  STATUS = 'Status',

  PLANNED_INSTALL_LOCATION = 'PlannedInstallLocation',
  PLANNED_DELIVERY_LOCATION = 'PlannedDeliveryLocation',

  BUILDING = 'Building',
  LEVEL = 'Level',
  ZONE = 'Zone',
  AREA = 'Area',
  GATE = 'Gate',
  ROUTE = 'Route',
  EQUIPMENT = 'OffloadingEquipment',
  LOGISTICS_OBJECT = 'LogisticsObject',
  VERTICAL_OBJECT = 'VerticalObject',
  INTERIOR_PATH = 'InteriorPath',
  INTERIOR_DOOR = 'InteriorDoor',
  STAGING = 'Staging',

  CURRENT_LOCATION = 'CurrentLocation',
}

const excludedLocationOptions = [
  MaterialsGroupingOption.BUILDING,
  MaterialsGroupingOption.ZONE,
  MaterialsGroupingOption.AREA,
  MaterialsGroupingOption.LEVEL,
  MaterialsGroupingOption.GATE,
  MaterialsGroupingOption.ROUTE,
  MaterialsGroupingOption.EQUIPMENT,
  MaterialsGroupingOption.LOGISTICS_OBJECT,
  MaterialsGroupingOption.VERTICAL_OBJECT,
  MaterialsGroupingOption.INTERIOR_PATH,
  MaterialsGroupingOption.INTERIOR_DOOR,
  MaterialsGroupingOption.STAGING,
]

export const materialsGroupingOptionList = enumToList(
  MaterialsGroupingOption,
).filter(o => !excludedLocationOptions.includes(o))

export const getMaterialsGroupingOptionTranslatorKey = (
  id: string,
): KnownTranslatorKeys => {
  switch (id) {
    case MaterialsGroupingOption.NONE:
      return KnownTranslatorKeys.none
    case MaterialsGroupingOption.STATUS:
      return KnownTranslatorKeys.status
    case MaterialsGroupingOption.COMPANY:
      return KnownTranslatorKeys.company
    case MaterialsGroupingOption.DATE:
      return KnownTranslatorKeys.date

    case MaterialsGroupingOption.MATERIAL_CATEGORY:
      return KnownTranslatorKeys.materialsCategory
    case MaterialsGroupingOption.MATERIAL:
      return KnownTranslatorKeys.material
    case MaterialsGroupingOption.MATERIAL_DESCRIPTION:
      return KnownTranslatorKeys.materialDescription

    case MaterialsGroupingOption.PLANNED_INSTALL_LOCATION:
      return KnownTranslatorKeys.plannedInstallLocation
    case MaterialsGroupingOption.PLANNED_DELIVERY_LOCATION:
      return KnownTranslatorKeys.plannedDeliveryLocation

    case MaterialsGroupingOption.BUILDING:
      return KnownTranslatorKeys.building
    case MaterialsGroupingOption.LEVEL:
      return KnownTranslatorKeys.level
    case MaterialsGroupingOption.ZONE:
      return KnownTranslatorKeys.zone
    case MaterialsGroupingOption.AREA:
      return KnownTranslatorKeys.area
    case MaterialsGroupingOption.GATE:
      return KnownTranslatorKeys.gate
    case MaterialsGroupingOption.ROUTE:
      return KnownTranslatorKeys.route
    case MaterialsGroupingOption.EQUIPMENT:
      return KnownTranslatorKeys.equipment
    case MaterialsGroupingOption.LOGISTICS_OBJECT:
      return KnownTranslatorKeys.logisticObject
    case MaterialsGroupingOption.VERTICAL_OBJECT:
      return KnownTranslatorKeys.verticalObject
    case MaterialsGroupingOption.INTERIOR_PATH:
      return KnownTranslatorKeys.interiorPath
    case MaterialsGroupingOption.INTERIOR_DOOR:
      return KnownTranslatorKeys.interiorDoor
    case MaterialsGroupingOption.STAGING:
      return KnownTranslatorKeys.staging

    case MaterialsGroupingOption.CURRENT_LOCATION:
      return KnownTranslatorKeys.currentLocation
    case MaterialsGroupingOption.VENDOR:
      return KnownTranslatorKeys.vendor
    default:
      throw new Error(id + ' is unhandled')
  }
}

export default MaterialsGroupingOption
