import HeaderBarStore from '~/client/src/desktop/components/HeaderBar/HeaderBar.store'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'
import {
  DELIVERIES_REPORT_TEMPLATE_ID,
  FORMS_REPORT_TEMPLATE_ID,
} from '~/client/src/shared/utils/usefulStrings'

import ReportsStore from '../../Reports.store'
import DeliveryReportListStore from '../ReportsList/DeliveryReport/DeliveryReportList.store'
import FormReportListStore from '../ReportsList/FormReportList/FormReportList.store'
import ScanStationListStore from '../ReportsList/ScanStation/ScanStationList.store'

const FORM_REPORT_TITLE = 'Form Report'
const DELIVERY_REPORT_TITLE = 'Delivery Report'
const SCAN_STATION_REPORT_TITLE = 'Scan Station Report'

const SCAN_STATION_REPORT_TEMPLATE_ID = 'scan_station_list_report'

export default class ReportsHeaderStore extends HeaderBarStore {
  public constructor(
    protected readonly state: DesktopInitialState,
    private readonly reportsStore: ReportsStore,
    private readonly scanStationListStore: ScanStationListStore,
    private readonly formReportListStore: FormReportListStore,
    private readonly deliveryReportListStore: DeliveryReportListStore,
  ) {
    super(state)

    this.viewState = state.reportsList
  }

  public get forceCloseMap() {
    const { scanStationFilters, formReportFilters, deliveryReportFilters } =
      this.state
    const { isScanStation, isFormReport, isDeliveryReport } = this.reportsStore

    let fieldsMap: { [x: string]: UIFilterInfo }

    if (isScanStation) fieldsMap = scanStationFilters.fieldsMap
    if (isFormReport) fieldsMap = formReportFilters.fieldsMap
    if (isDeliveryReport) fieldsMap = deliveryReportFilters.fieldsMap

    return this.getForceCloseMap(fieldsMap)
  }

  public get reportTitle(): string {
    const { isScanStation, isFormReport, isDeliveryReport } = this.reportsStore

    if (isScanStation) {
      return SCAN_STATION_REPORT_TITLE
    } else if (isFormReport) {
      return FORM_REPORT_TITLE
    } else if (isDeliveryReport) {
      return DELIVERY_REPORT_TITLE
    }
  }

  public get reportTemplate(): string {
    const { isScanStation, isFormReport, isDeliveryReport } = this.reportsStore

    if (isScanStation) {
      return SCAN_STATION_REPORT_TEMPLATE_ID
    } else if (isFormReport) {
      return FORMS_REPORT_TEMPLATE_ID
    } else if (isDeliveryReport) {
      return DELIVERIES_REPORT_TEMPLATE_ID
    }
  }

  public get reportData() {
    const { isScanStation, isFormReport, isDeliveryReport } = this.reportsStore

    if (isScanStation) {
      return this.scanStationListStore.reportInfo
    } else if (isFormReport) {
      return this.formReportListStore.reportInfo
    } else if (isDeliveryReport) {
      return this.deliveryReportListStore.reportInfo
    }
  }

  private getForceCloseMap(fieldsMap: { [filterType: string]: UIFilterInfo }) {
    return Object.keys(fieldsMap).reduce((map, filterType) => {
      map[filterType] = this.openedPopup && this.openedPopup !== filterType
      return map
    }, {})
  }
}
