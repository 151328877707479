import * as React from 'react'

import { inject, observer } from 'mobx-react'

import CalendarEventComponent from '~/client/src/shared/components/CalendarDayView/components/CalendarEvent'
import CalendarHoursLabels from '~/client/src/shared/components/CalendarDayView/components/CalendarHoursLabels'
import HourSpace from '~/client/src/shared/components/CalendarDayView/components/HourSpace'
import DeliveryWorkflowCard from '~/client/src/shared/components/ExpandableWorkflowCard/components/DeliveryWorkflowCard'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import DeliveryCardPreviewStore, {
  previewViewHours,
} from './DeliveryCardPreview.store'

interface IProps {
  timeInterval: number
  hourRowsNumber: number
  projectDateStore?: ProjectDateStore
  companiesStore?: CompaniesStore
}

const deliveryCardPreview = 'Delivery Card Preview'
const calendarView = 'Calendar view'
const mapView = 'Map view'

@inject('projectDateStore', 'companiesStore')
@observer
export default class DeliveryCardPreview extends React.Component<IProps> {
  private readonly store: DeliveryCardPreviewStore

  public constructor(props: IProps) {
    super(props)

    this.store = new DeliveryCardPreviewStore(
      props.projectDateStore,
      props.companiesStore,
    )
  }

  public render() {
    return (
      <div className="col delivery-card-preview bg-palette-brand-lightest full-height overflow-hidden">
        <div className="pa20 text center extra-large lp015 line-extra-large bold">
          {deliveryCardPreview}
        </div>
        <div className="row y-start overflow-auto px20 pb20">
          {this.calendarPreviewColumn}
          <div className="mr20 no-grow" />
          {this.mapPreviewColumn}
        </div>
      </div>
    )
  }

  private get calendarPreviewColumn(): JSX.Element {
    const { timeInterval, hourRowsNumber } = this.props
    const { previewCalendarEvents, getEventSizeAndPosition } = this.store
    const currentDay = new Date()

    return (
      <div className="col">
        <span className="calendar-preview-title text uppercase center small bold lp15 line-large py12 bg-palette-brand-lightest sticky">
          {calendarView}
        </span>
        <div className="row y-start py10 pl5 overflow-hidden bg-white calendar-preview-column">
          <CalendarHoursLabels
            dayHours={previewViewHours}
            shouldHideTimezone={true}
          />
          <div className="col">
            <div className="relative bt-light-grey mt10">
              {previewViewHours.map(hour => (
                <HourSpace
                  key={hour}
                  hour={hour}
                  timeInterval={timeInterval}
                  rowsNumber={hourRowsNumber}
                  isNonWorkingDay={false}
                />
              ))}
              {previewCalendarEvents.map((event, idx) => {
                const { style, className } = getEventSizeAndPosition(
                  event,
                  previewCalendarEvents,
                  currentDay,
                )

                return (
                  <CalendarEventComponent
                    key={idx}
                    event={event}
                    className={className}
                    style={style}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  private get mapPreviewColumn(): JSX.Element {
    return (
      <div className="col map-preview-column">
        <span className="map-preview-title text uppercase center small bold lp15 line-large py12 bg-palette-brand-lightest sticky">
          {mapView}
        </span>
        <div className="overflow-hidden bg-white brada8 beautiful-shadow mx5">
          <DeliveryWorkflowCard
            delivery={this.store.previewMapDelivery}
            shouldUseMockValues={true}
          />
        </div>
      </div>
    )
  }
}
