import * as React from 'react'

import { Arc, Group } from 'react-konva'

const KonvaCircle = ({
  color,
  radius,
  lineWidth,
  fillColor,
  fillOpacity,
  angle,
  rotation,
  ...rest
}) => {
  const innerRadius = radius - lineWidth / 2 > 0 ? radius - lineWidth / 2 : 0
  return (
    <Group {...rest}>
      <Arc
        outerRadius={radius}
        innerRadius={0}
        angle={angle}
        rotation={rotation}
        fill={fillColor}
        opacity={fillOpacity}
      />
      <Arc
        outerRadius={radius + lineWidth / 2}
        innerRadius={innerRadius}
        angle={angle}
        rotation={rotation}
        fill={color}
      />
    </Group>
  )
}

export default KonvaCircle
