import { action, computed, observable } from 'mobx'

import { ILWFCColumn } from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'

// localization: no display text to translate

export const COLLAPSED_SECTION_WIDTH = 19
export const DEFAULT_COLUMN_WIDTH = 100

export default class DeliveriesCollapsibleColumnsTableStore {
  @observable public shouldCollapseDuration: boolean = false
  @observable public shouldCollapseLocations: boolean = false
  @observable public shouldCollapseEquipment: boolean = false
  @observable public shouldCollapseMaterials: boolean = false
  @observable public shouldCollapseVendor: boolean = false
  @observable public shouldShowSelectionPopup: boolean = false

  @action.bound
  public toggleSelectionPopup() {
    this.shouldShowSelectionPopup = !this.shouldShowSelectionPopup
  }

  @action
  public hideSelectionPopup() {
    this.shouldShowSelectionPopup = false
  }

  @action.bound
  public toggleDurationCollapseState() {
    this.shouldCollapseDuration = !this.shouldCollapseDuration
  }

  @action.bound
  public toggleLocationsCollapseState() {
    this.shouldCollapseLocations = !this.shouldCollapseLocations
  }

  @action.bound
  public toggleEquipmentCollapseState() {
    this.shouldCollapseEquipment = !this.shouldCollapseEquipment
  }

  @action.bound
  public toggleMaterialsCollapseState() {
    this.shouldCollapseMaterials = !this.shouldCollapseMaterials
  }

  @action.bound
  public toggleVendorCollapseState() {
    this.shouldCollapseVendor = !this.shouldCollapseVendor
  }

  @computed
  public get collapsedSectionsWidth(): number {
    const sections = [
      this.shouldCollapseDuration,
      this.shouldCollapseLocations,
      this.shouldCollapseEquipment,
      this.shouldCollapseMaterials,
      this.shouldCollapseVendor,
    ]

    return sections.reduce((sum, isCollapsed, currentIndex) => {
      if (isCollapsed) {
        sum += COLLAPSED_SECTION_WIDTH
      }
      if (currentIndex === sections.length - 1 && isCollapsed) {
        sum += COLLAPSED_SECTION_WIDTH
      }
      return sum
    }, 0)
  }

  public getSectionWidth(sectionColumns: ILWFCColumn[]): number {
    return sectionColumns.reduce((sum, { width }) => {
      sum += width || DEFAULT_COLUMN_WIDTH
      return sum
    }, 0)
  }
}
