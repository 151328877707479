import * as React from 'react'

import { HeaderBar } from '~/client/src/shared/components/HeaderBar'
import { StatusUpdate as StatusUpdateIcon } from '~/client/src/shared/components/Icons'
import ImagePreview from '~/client/src/shared/components/ImagePreview/ImagePreview'
import Activity from '~/client/src/shared/models/Activity'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

import Localization from '../localization/LocalizationManager'
import StatusUpdateMessagesActionBar from './StatusUpdateMessagesActionBar'

// translated

interface IProps {
  statusUpdatesStore?: StatusUpdatesStore
  activitiesStore?: ActivitiesStore
  slackBarStore?: SlackBarStore
}

export default class StatusUpdateMessagesViewBase<
  T extends IProps,
> extends React.Component<T> {
  protected statusUpdateId: string

  public get statusUpdate(): StatusUpdate {
    const { statusUpdatesStore } = this.props
    return statusUpdatesStore.byId.get(this.statusUpdateId) || StatusUpdate.none
  }

  public get activity() {
    return (
      this.props.activitiesStore.byId.get(this.statusUpdate.activityP6Code) ||
      Activity.none
    )
  }

  public goBack() {
    throw new Error('goBack() should be overwriten')
  }

  public goToActivityDetails() {
    throw new Error('goToActivityDetails() should be overwriten')
  }

  public get header() {
    return (
      <HeaderBar>
        <img
          className="no-grow pr20"
          src="/static/icons/back-arrow.svg"
          alt="BACK"
          onClick={this.goBack}
        />
        <div
          className="text large bold text-ellipsis half-flex-basis"
          onClick={this.goToActivityDetails}
        >
          {Localization.translator.activityUpdate}: {this.activity.name} |{' '}
          {this.statusUpdate.activityP6Code}
        </div>
        <div className="text right">
          <StatusUpdateIcon />
        </div>
      </HeaderBar>
    )
  }

  public get imagePreview() {
    const { slackBarStore } = this.props

    if (slackBarStore.shouldShowPreview) {
      return (
        <ImagePreview>
          <img src={slackBarStore.previewImage} className="center" />
        </ImagePreview>
      )
    }
  }

  public get footer() {
    return (
      <StatusUpdateMessagesActionBar
        statusUpdate={this.statusUpdate}
        activity={this.activity}
        goBack={this.goBack}
      />
    )
  }
}
