import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import BaseStruxhubInput, { ISharedProps } from '../BaseStruxhubInput'
import CaretDownSelectorIcon from './CaretDownSelectorIcon'

// localization: no text to translate

interface IProps extends ISharedProps {
  onClick?: () => void

  selectorClassName?: string
}

@observer
export default class BaseStruxhubSelector extends React.Component<IProps> {
  public render() {
    const { selectorClassName, onClick, children } = this.props

    return (
      <BaseStruxhubInput {...this.props}>
        {isValueInvalid => {
          return (
            <div
              className={classList({
                'pointer row y-center bg-palette-brand-lightest no-outline brada4 py7 pl8 pr24 text extra-large full-width':
                  true,
                'ba-palette-brand-lighter': !isValueInvalid,
                'ba-red': isValueInvalid,
                [selectorClassName]: !!selectorClassName,
              })}
              onClick={onClick && this.onClick}
            >
              <div className="row overflow-hidden">{children}</div>
              <CaretDownSelectorIcon />
            </div>
          )
        }}
      </BaseStruxhubInput>
    )
  }

  private onClick = () => {
    const { onClick, isDisabled, isReadOnly } = this.props
    if (!isReadOnly && !isDisabled) {
      onClick?.()
    }
  }
}
