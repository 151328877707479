import { action, computed, observable } from 'mobx'

import { IStruxHubCompany } from '~/client/graph'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Company from '~/client/src/shared/models/Company'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'

export default class STXCompanyLinkerStore {
  @observable public isLinkedSTXCompanyBeingFetched: boolean = false
  @observable public isLinkedSTXCompanyBeingUnlinked: boolean = false
  @observable public isModalLoading: boolean = false

  @observable public isLinkingModalShown: boolean = false

  @observable public linkedSTXCompany: IStruxHubCompany = null

  @observable public searchKey: string = ''

  @observable private _stxCompanies: IStruxHubCompany[] = []

  private stxCompanyByIdCache: { [key: string]: IStruxHubCompany } = {}

  public constructor(
    private companyId: string,
    private readonly companiesStore: CompaniesStore,
  ) {}

  public get company(): Company {
    return this.companiesStore.getCompanyById(this.companyId)
  }

  public get isCompanyLinkedWithSTXCompany(): boolean {
    return !!this.company?.isLinked
  }

  public setCompanyId(companyId: string) {
    this.companyId = companyId
  }

  @action.bound
  public async init() {
    this.linkedSTXCompany = null

    if (!this.isCompanyLinkedWithSTXCompany) return

    const company = this.companiesStore.getCompanyById(this.companyId)

    this.isLinkedSTXCompanyBeingFetched = true

    try {
      const stxCompanyFromCache =
        this.stxCompanyByIdCache[company.struxHubCompanyId]

      this.linkedSTXCompany =
        stxCompanyFromCache ||
        (await this.companiesStore.fetchSTXCompanyById(
          company.struxHubCompanyId,
        ))

      if (!stxCompanyFromCache && this.linkedSTXCompany) {
        this.stxCompanyByIdCache[this.linkedSTXCompany.id] =
          this.linkedSTXCompany
      }
    } finally {
      this.isLinkedSTXCompanyBeingFetched = false
    }
  }

  @action.bound
  public async handleLinkClick() {
    this.showLinkingModal()

    // take from local cache
    if (this._stxCompanies.length) return

    this.isModalLoading = true
    try {
      this._stxCompanies = await this.companiesStore.fetchAllSTXCompanies()
    } catch {
      alert(
        Localization.translator.companyLinkingErrors
          .anErrorOccurredWhileFetchingGlobalStorage,
      )
    } finally {
      this.isModalLoading = false
    }
  }

  @action.bound
  public showLinkingModal() {
    this.isLinkingModalShown = true
  }

  @action.bound
  public hideLinkingModal() {
    this.isLinkingModalShown = false
  }

  @action.bound
  public async link(struxHubCompanyId: string) {
    if (this.company.struxHubCompanyId === struxHubCompanyId) return

    this.isModalLoading = true

    try {
      await this.companiesStore.linkStruxHubCompany(
        this.companyId,
        struxHubCompanyId,
      )
      this.linkedSTXCompany = this._stxCompanies.find(
        c => c.id === struxHubCompanyId,
      )

      this.hideLinkingModal()
    } catch {
      alert(
        Localization.translator.companyLinkingErrors
          .anErrorOccurredWhileLinking,
      )
    } finally {
      this.isModalLoading = false
    }
  }

  @action.bound
  public async unlink() {
    if (!this.company.isLinked) return

    this.isLinkedSTXCompanyBeingUnlinked = true

    try {
      await this.companiesStore.unlinkStruxHubCompany(this.companyId)
      this.linkedSTXCompany = null
    } catch {
      alert(
        Localization.translator.companyLinkingErrors
          .anErrorOccurredWhileUnlinking,
      )
    } finally {
      this.isLinkedSTXCompanyBeingUnlinked = false
    }
  }

  @action.bound
  public setSearchKey(newSearchKey: string) {
    this.searchKey = newSearchKey
  }

  @action.bound
  public resetSearchKey() {
    this.searchKey = ''
  }

  @computed
  private get stxCompanies(): IStruxHubCompany[] {
    return this._stxCompanies.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    )
  }

  @computed
  public get stxCompaniesToDisplay(): IStruxHubCompany[] {
    const searchKey = this.searchKey.toLowerCase().trim()
    return this.stxCompanies.filter(c =>
      c.name.toLowerCase().includes(searchKey),
    )
  }

  @computed
  public get projectCompanyByStx() {
    return this.companiesStore.allCompanies.reduce((acc, c) => {
      if (c.struxHubCompanyId) {
        acc[c.struxHubCompanyId] = c.id
      }

      return acc
    }, {})
  }
}
