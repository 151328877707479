import * as React from 'react'

export interface IProps {
  value: number
  style: string
}

export default class ProgressColoredValue extends React.PureComponent<IProps> {
  public render() {
    const { style, value } = this.props
    const coloredStyle = `colored-val ${style}`
    return (
      <span className={coloredStyle}>
        {this.mark}
        {value}
      </span>
    )
  }

  private get mark() {
    const { value } = this.props
    if (value > 0) {
      return '+'
    }
    return ''
  }
}
