import * as React from 'react'

import { Classes, Dialog } from '@blueprintjs/core'

import Localization from '../../localization/LocalizationManager'
import EmailLinkerForm from '../EmailLinkerForm/EmailLinkerForm'

interface IProps {
  isOpen: boolean
  onClose: () => void
  onComplete: (linkedEmail?: string) => void
}

export default class EmailLinkerDialog extends React.PureComponent<IProps> {
  public render() {
    const { isOpen, onClose, onComplete } = this.props

    return (
      <Dialog isOpen={isOpen} onClose={onClose} className="bg-white brada4">
        <>
          <div className={`${Classes.DIALOG_HEADER} row text header px24`}>
            <div>
              {
                Localization.translator.userEmailLinking
                  .linkYourEmailAndSetPassword
              }
            </div>
            <button
              aria-label="Close"
              onClick={onClose}
              className="bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross no-grow"
            />
          </div>
          <div className={`${Classes.DIALOG_BODY} col text center`}>
            <EmailLinkerForm onComplete={onComplete} />
          </div>
        </>
      </Dialog>
    )
  }
}
