import * as React from 'react'

import { observer } from 'mobx-react'

import FilterHandleButton from '~/client/src/shared/components/Filters/components/FilterHandleButton/FilterHandleButton'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { IFilters } from '~/client/src/shared/stores/InitialState'

import EntityIconFilterStore from './EntityIconFilter.store'

import Colors from '~/client/src/shared/theme.module.scss'

import './EntityIconFilter.scss'

// localization: translated

interface IEntityIconFilter {
  filters: IFilters
  options: MapViewLocationIcon[]
  onShowChanged?: (isShown: boolean) => void
  forceClose?: boolean
  className?: string
}

const ICON_SIZE = 24

const ICON_COMPONENT_BY_TYPE = {
  [MapViewLocationIcon.Logistics]: TagIcon.LogisticsObject,
  [MapViewLocationIcon.Bathroom]: TagIcon.Bathroom,
  [MapViewLocationIcon.Break]: TagIcon.Break,
  [MapViewLocationIcon.Dumpster]: TagIcon.Dumpster,
  [MapViewLocationIcon.Elevator]: TagIcon.Elevator,
  [MapViewLocationIcon.Entrance]: TagIcon.Entrance,
  [MapViewLocationIcon.HandWash]: TagIcon.HandWash,
  [MapViewLocationIcon.Medical]: TagIcon.Medical,
  [MapViewLocationIcon.MeetingPoint]: TagIcon.MeetingPoint,
  [MapViewLocationIcon.Parking]: TagIcon.Parking,
  [MapViewLocationIcon.Smoking]: TagIcon.Smoking,
  [MapViewLocationIcon.Stairs]: TagIcon.Stairs,
  [MapViewLocationIcon.Shaft]: TagIcon.Shaft,
  [MapViewLocationIcon.Temperature]: TagIcon.Temperature,
  [MapViewLocationIcon.Tent]: TagIcon.Tent,
  [MapViewLocationIcon.Walkway]: TagIcon.Walkway,
  [MapViewLocationIcon.ElectricalRoom]: TagIcon.ElectricalRoom,
  [MapViewLocationIcon.Crane]: TagIcon.Crane,
  [MapViewLocationIcon.Hoist]: TagIcon.Hoist,
  [MapViewLocationIcon.AerialLift]: TagIcon.AerialLift,
  [MapViewLocationIcon.Gradall]: TagIcon.Gradall,
  [MapViewLocationIcon.ForkLift]: TagIcon.ForkLift,
  [MapViewLocationIcon.Monitoring]: TagIcon.MaturixStation,
  [MapViewLocationIcon.Trailer]: TagIcon.Trailer,
}

@observer
export default class EntityIconFilter extends React.Component<IEntityIconFilter> {
  public static defaultProps = {
    className: '',
  }

  private readonly store: EntityIconFilterStore

  public constructor(props: IEntityIconFilter) {
    super(props)

    this.store = new EntityIconFilterStore(props.filters, props.onShowChanged)
  }

  public componentDidUpdate() {
    if (this.props.forceClose) {
      this.store.isShown = false
    }
  }

  public render() {
    const { options } = this.props
    const { isShown, isActive, iconKey } = this.store

    return (
      <div className="icon-filter">
        <FilterHandleButton
          onClick={this.store.toggle}
          className="icon-filter-handle-button"
          isActive={isActive}
          isSelected={isShown}
          hasIndent={false}
        >
          {this.getIconOptionText(
            iconKey as MapViewLocationIcon,
            Colors.neutral100,
          )}
        </FilterHandleButton>
        {isShown && (
          <div className="icon-options-list ba-light-grey brada4">
            {options.map(icon => (
              <div
                className="row x-center py4 bb-light-grey"
                key={icon}
                onClick={this.applyIcon.bind(this, icon)}
              >
                {this.getIconOptionText(icon)}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  private applyIcon(iconKey: string) {
    this.store.applyIcon(iconKey)
  }

  private getIconOptionText(icon: MapViewLocationIcon, color?: string) {
    if (!icon) {
      return (
        <div className="text large light center px12 py4">
          {Localization.translator.allIcons}
        </div>
      )
    }
    const Icon = ICON_COMPONENT_BY_TYPE[icon]
    if (Icon) {
      return <Icon color={color || Colors.neutral60} size={ICON_SIZE} />
    }
  }
}
