import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ICompanyRelationshipFieldsFragment = Pick<
  Types.ICompanyRelationship,
  | 'id'
  | 'externalCompanyId'
  | 'struxHubCompanyId'
  | 'scheduleId'
  | 'xerEntityId'
  | 'createdAt'
  | 'updatedAt'
>

export type ICompanyRelationshipsByScheduleIdQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars['ObjectId']
}>

export type ICompanyRelationshipsByScheduleIdQuery = {
  companyRelationships?: Types.Maybe<{
    data: Array<ICompanyRelationshipFieldsFragment>
  }>
}

export type ISaveCompanyRelationshipMutationVariables = Types.Exact<{
  companyRelationship: Types.ICompanyRelationshipInput
}>

export type ISaveCompanyRelationshipMutation = {
  saveCompanyRelationship?: Types.Maybe<ICompanyRelationshipFieldsFragment>
}

export type IDeleteCompanyRelationshipMutationVariables = Types.Exact<{
  companyRelationshipId: Types.Scalars['ObjectId']
}>

export type IDeleteCompanyRelationshipMutation = Pick<
  Types.IMutation,
  'deleteCompanyRelationship'
>

export type IListenCompanyRelationshipsByScheduleIdSubscriptionVariables =
  Types.Exact<{
    scheduleId: Types.Scalars['ObjectId']
  }>

export type IListenCompanyRelationshipsByScheduleIdSubscription = {
  companyRelationship?: Types.Maybe<
    Pick<Types.ICompanyRelationshipChangeEvent, 'id'> & {
      item?: Types.Maybe<ICompanyRelationshipFieldsFragment>
    }
  >
}

export const CompanyRelationshipFieldsFragmentDoc = gql`
  fragment CompanyRelationshipFields on CompanyRelationship {
    id
    externalCompanyId
    struxHubCompanyId
    scheduleId
    xerEntityId
    createdAt
    updatedAt
  }
`
export const CompanyRelationshipsByScheduleIdDocument = gql`
  query CompanyRelationshipsByScheduleId($scheduleId: ObjectId!) {
    companyRelationships(
      scheduleId: $scheduleId
      offset: 0
      limit: 1000
      sort: { by: "id", order: ASCENDING }
    ) {
      data {
        ...CompanyRelationshipFields
      }
    }
  }
  ${CompanyRelationshipFieldsFragmentDoc}
`

/**
 * __useCompanyRelationshipsByScheduleIdQuery__
 *
 * To run a query within a React component, call `useCompanyRelationshipsByScheduleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRelationshipsByScheduleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRelationshipsByScheduleIdQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useCompanyRelationshipsByScheduleIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ICompanyRelationshipsByScheduleIdQuery,
    ICompanyRelationshipsByScheduleIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ICompanyRelationshipsByScheduleIdQuery,
    ICompanyRelationshipsByScheduleIdQueryVariables
  >(CompanyRelationshipsByScheduleIdDocument, options)
}
export function useCompanyRelationshipsByScheduleIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICompanyRelationshipsByScheduleIdQuery,
    ICompanyRelationshipsByScheduleIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ICompanyRelationshipsByScheduleIdQuery,
    ICompanyRelationshipsByScheduleIdQueryVariables
  >(CompanyRelationshipsByScheduleIdDocument, options)
}
export type CompanyRelationshipsByScheduleIdQueryHookResult = ReturnType<
  typeof useCompanyRelationshipsByScheduleIdQuery
>
export type CompanyRelationshipsByScheduleIdLazyQueryHookResult = ReturnType<
  typeof useCompanyRelationshipsByScheduleIdLazyQuery
>
export type CompanyRelationshipsByScheduleIdQueryResult = Apollo.QueryResult<
  ICompanyRelationshipsByScheduleIdQuery,
  ICompanyRelationshipsByScheduleIdQueryVariables
>
export const SaveCompanyRelationshipDocument = gql`
  mutation SaveCompanyRelationship(
    $companyRelationship: CompanyRelationshipInput!
  ) {
    saveCompanyRelationship(companyRelationship: $companyRelationship) {
      ...CompanyRelationshipFields
    }
  }
  ${CompanyRelationshipFieldsFragmentDoc}
`
export type ISaveCompanyRelationshipMutationFn = Apollo.MutationFunction<
  ISaveCompanyRelationshipMutation,
  ISaveCompanyRelationshipMutationVariables
>

/**
 * __useSaveCompanyRelationshipMutation__
 *
 * To run a mutation, you first call `useSaveCompanyRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCompanyRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCompanyRelationshipMutation, { data, loading, error }] = useSaveCompanyRelationshipMutation({
 *   variables: {
 *      companyRelationship: // value for 'companyRelationship'
 *   },
 * });
 */
export function useSaveCompanyRelationshipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveCompanyRelationshipMutation,
    ISaveCompanyRelationshipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveCompanyRelationshipMutation,
    ISaveCompanyRelationshipMutationVariables
  >(SaveCompanyRelationshipDocument, options)
}
export type SaveCompanyRelationshipMutationHookResult = ReturnType<
  typeof useSaveCompanyRelationshipMutation
>
export type SaveCompanyRelationshipMutationResult =
  Apollo.MutationResult<ISaveCompanyRelationshipMutation>
export type SaveCompanyRelationshipMutationOptions = Apollo.BaseMutationOptions<
  ISaveCompanyRelationshipMutation,
  ISaveCompanyRelationshipMutationVariables
>
export const DeleteCompanyRelationshipDocument = gql`
  mutation DeleteCompanyRelationship($companyRelationshipId: ObjectId!) {
    deleteCompanyRelationship(id: $companyRelationshipId)
  }
`
export type IDeleteCompanyRelationshipMutationFn = Apollo.MutationFunction<
  IDeleteCompanyRelationshipMutation,
  IDeleteCompanyRelationshipMutationVariables
>

/**
 * __useDeleteCompanyRelationshipMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyRelationshipMutation, { data, loading, error }] = useDeleteCompanyRelationshipMutation({
 *   variables: {
 *      companyRelationshipId: // value for 'companyRelationshipId'
 *   },
 * });
 */
export function useDeleteCompanyRelationshipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteCompanyRelationshipMutation,
    IDeleteCompanyRelationshipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteCompanyRelationshipMutation,
    IDeleteCompanyRelationshipMutationVariables
  >(DeleteCompanyRelationshipDocument, options)
}
export type DeleteCompanyRelationshipMutationHookResult = ReturnType<
  typeof useDeleteCompanyRelationshipMutation
>
export type DeleteCompanyRelationshipMutationResult =
  Apollo.MutationResult<IDeleteCompanyRelationshipMutation>
export type DeleteCompanyRelationshipMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteCompanyRelationshipMutation,
    IDeleteCompanyRelationshipMutationVariables
  >
export const ListenCompanyRelationshipsByScheduleIdDocument = gql`
  subscription ListenCompanyRelationshipsByScheduleId($scheduleId: ObjectId!) {
    companyRelationship(scheduleId: $scheduleId) {
      id
      item {
        ...CompanyRelationshipFields
      }
    }
  }
  ${CompanyRelationshipFieldsFragmentDoc}
`

/**
 * __useListenCompanyRelationshipsByScheduleIdSubscription__
 *
 * To run a query within a React component, call `useListenCompanyRelationshipsByScheduleIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenCompanyRelationshipsByScheduleIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenCompanyRelationshipsByScheduleIdSubscription({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useListenCompanyRelationshipsByScheduleIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenCompanyRelationshipsByScheduleIdSubscription,
    IListenCompanyRelationshipsByScheduleIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenCompanyRelationshipsByScheduleIdSubscription,
    IListenCompanyRelationshipsByScheduleIdSubscriptionVariables
  >(ListenCompanyRelationshipsByScheduleIdDocument, options)
}
export type ListenCompanyRelationshipsByScheduleIdSubscriptionHookResult =
  ReturnType<typeof useListenCompanyRelationshipsByScheduleIdSubscription>
export type ListenCompanyRelationshipsByScheduleIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenCompanyRelationshipsByScheduleIdSubscription>
