import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import { getDefaultStartAndFinishDates } from '../CommonDatePicker/CommonDatePickerHelper'
import InfinityCalendar from '../CommonDatePicker/InfinityCalendar/InfinityCalendar'

import './CompactDatePicker.scss'

// translated

interface IProps {
  initStartDate?: Date
  initEndDate?: Date
  minDate?: Date
  maxDate?: Date
  projectDateStore?: ProjectDateStore
  onHide: () => void
  onDateChange?: (startDate: Date, endDate?: Date) => void
  isOneDayMode?: boolean
  hasItemsOnDate?: (date: Date | number) => boolean
}
const DISPLAYED_MONTH_COUNT = 13
const MONTH_HEIGHT = 350

@inject('projectDateStore')
@observer
export default class CompactDatePicker extends React.Component<IProps> {
  @observable private startDate: Date = null
  @observable private endDate: Date = null

  public componentDidMount() {
    const { initStartDate, initEndDate } = this.props
    this.updateDates(initStartDate, initEndDate)
  }

  public render() {
    const { onHide, isOneDayMode, hasItemsOnDate } = this.props

    return (
      <>
        <div onClick={onHide} className="dimmer shown" />
        <div className="date-picker-calendar col shown">
          <div className="row pa10 pl20">
            <div
              className={classList({
                'calendar-cross-icon': true,
                'no-grow': !hasItemsOnDate,
              })}
            >
              <Icon icon={IconNames.CROSS} onClick={onHide} />
            </div>
            <div className="col x-center text large bold">
              {Localization.translator.jumpToDate}
            </div>
            {hasItemsOnDate && (
              <div className="row text light no-white-space-wrap right">
                <div className="row relative has-delivery" />
                <div>{Localization.translator.hasDelivery}</div>
              </div>
            )}
          </div>
          <InfinityCalendar
            months={this.displayedMonths}
            monthDate={this.startDate}
            dateClickHandler={this.onDateClick}
            monthHeight={MONTH_HEIGHT}
            isOneDayMode={isOneDayMode}
            startDate={this.startDate}
            endDate={this.endDate}
          />
          <div className="bt-light-grey row y-center py12 px24">
            {this.renderDateFields(
              'br-palette-brand-lighter pr20',
              Localization.translator.start_noun,
              this.startDate,
            )}
            {this.renderDateFields(
              'px20',
              Localization.translator.finish_noun,
              isOneDayMode ? this.startDate : this.endDate,
            )}
            <div
              onClick={this.selectDate}
              className={classList({
                'apply-button row x-center y-center text white extra-large bold':
                  true,
                'inactive-element': !this.areDatesValid,
              })}
            >
              {Localization.translator.select}
            </div>
          </div>
        </div>
      </>
    )
  }

  private get areDatesValid() {
    return this.startDate && (this.props.isOneDayMode || this.endDate)
  }

  @action.bound
  public onDateClick(event: Event, date: Date) {
    this.setDates(date)
  }

  @action.bound
  private setDates(date: Date) {
    const newDates = getDefaultStartAndFinishDates(
      date,
      this.startDate,
      this.endDate,
      this.props.isOneDayMode,
    )
    this.startDate = newDates.startDate
    this.endDate = newDates.endDate
  }

  private renderDateFields(className: string, caption: string, date: Date) {
    const { getWeekdayMonthAndDayToDisplay } = this.props.projectDateStore

    return (
      <div className={'col ' + className}>
        <div className="date-caption text">{caption.toUpperCase()}</div>
        <div className="blue-highlight text large bold pb10 pt5">
          {getWeekdayMonthAndDayToDisplay(date) || '-'}
        </div>
      </div>
    )
  }

  private get displayedMonths(): Date[] {
    const { addMonths } = this.props.projectDateStore
    const now = new Date()
    const monthFrom = addMonths(now, -1)

    const month = []
    for (let i = 0; i <= DISPLAYED_MONTH_COUNT; i++) {
      month.push(addMonths(monthFrom, i))
    }

    return month
  }

  private selectDate = () => {
    const {
      onDateChange,
      initStartDate,
      initEndDate,
      projectDateStore: { isSameDay, startOfDay, endOfDay },
      onHide,
    } = this.props

    if (
      isSameDay(this.startDate, initStartDate) &&
      isSameDay(this.endDate, initEndDate)
    ) {
      return onHide()
    }

    if (onDateChange && this.startDate && !this.endDate) {
      onDateChange(startOfDay(this.startDate), endOfDay(this.startDate))
    }
    if (onDateChange && this.startDate && this.endDate) {
      onDateChange(startOfDay(this.startDate), endOfDay(this.endDate))
    }
  }

  private updateDates(startDate: Date, endDate: Date) {
    if (startDate && startDate !== this.startDate) {
      this.startDate = startDate
    }
    if (endDate && endDate !== this.endDate) {
      this.endDate = endDate
    }
  }
}
