export default function formatBytes(bytes: number) {
  if (bytes === 0) {
    return '0 Bytes'
  }
  const k = 1024
  const sizes = ['Bytes', 'kB', 'mB', 'gB', 'tB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return Math.round(bytes / Math.pow(k, i)) + sizes[i]
}
