import { action, computed, observable } from 'mobx'

import { OperationSubjectType, OperationType } from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import { getDeliveryStatusesAsTags } from '~/client/src/shared/constants/deliveryStatusesTags'
import { getFormsStatusesAsTags } from '~/client/src/shared/constants/formStatusesTags'
import { TagType } from '~/client/src/shared/enums/TagType'
import OperationRule from '~/client/src/shared/models/OperationRule'
import Tag from '~/client/src/shared/models/Tag'
import {
  DELETE_MANY_OPERATION_RULES,
  SAVE_MANY_OPERATION_RULES,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import OperationRulesStore from '~/client/src/shared/stores/domain/OperationRules.store'
import TagsStore, {
  userRelateTagTypesWithoutGlobalRoles,
} from '~/client/src/shared/stores/domain/Tags.store'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

const { ASSIGNMENT_PLACEHOLDER } = OperationRule

export default class BaseNotificationRulesStore {
  @observable public shouldShowNewRuleRow: boolean = false
  @observable public shouldDeletionConfirmModalShow: boolean = false

  private removingRuleId: string = null

  public constructor(
    private readonly state: DesktopInitialState,
    private readonly operationRulesStore: OperationRulesStore,
    private readonly tagsStore: TagsStore,
    private tags: TagType[],
    private operationSubjectType: OperationSubjectType,
  ) {}

  public get isLoading(): boolean {
    const { loading } = this.state

    return (
      loading.get(SAVE_MANY_OPERATION_RULES) ||
      loading.get(DELETE_MANY_OPERATION_RULES)
    )
  }

  @computed
  public get rules(): OperationRule[] {
    const rules = this.operationRulesStore.list.filter(
      rule => rule.operationSubject === this.operationSubjectType,
    )
    return this.shouldShowNewRuleRow ? [this.newRule, ...rules] : rules
  }

  @computed
  public get userRelatedTags(): Tag[] {
    const lists = userRelateTagTypesWithoutGlobalRoles.map(
      tagType => this.tagsStore.tagListsByTagTypeMap[tagType],
    )

    return [].concat(...lists).sort(this.sortTagsAlphabetically)
  }

  @action.bound
  public showDeletionConfirm() {
    this.shouldDeletionConfirmModalShow = true
  }

  @action.bound
  public hideDeletionConfirm() {
    this.shouldDeletionConfirmModalShow = false
  }

  @action.bound
  public showNewRuleRow() {
    this.shouldShowNewRuleRow = true
  }

  @action.bound
  public hideNewRuleRow() {
    this.shouldShowNewRuleRow = false
  }

  @action.bound
  public removeRule({ id }: OperationRule) {
    this.removingRuleId = id
    this.showDeletionConfirm()
  }

  @action.bound
  public performRemoveRule() {
    if (!this.removingRuleId) {
      return
    }

    if (this.removingRuleId === OperationRule.NEW_ID) {
      this.hideNewRuleRow()
    } else {
      this.operationRulesStore.removeMany([this.removingRuleId])
    }

    this.hideDeletionConfirm()
    this.resetRemovingRuleId()
  }

  @action.bound
  public addAssignmentPlaceholderRow({
    assignmentsAsSubexpressions,
  }: OperationRule) {
    if (!assignmentsAsSubexpressions.includes(ASSIGNMENT_PLACEHOLDER)) {
      assignmentsAsSubexpressions.push(ASSIGNMENT_PLACEHOLDER)
    }
  }

  @action.bound
  public saveRuleChanges(rule: OperationRule) {
    this.operationRulesStore.saveMany([rule])

    this.hideNewRuleRow()
    this.clearServiceRows()
  }

  @action.bound
  public clearServiceRows() {
    this.rules.forEach(({ assignmentsAsSubexpressions }) => {
      if (
        assignmentsAsSubexpressions.length > 1 &&
        assignmentsAsSubexpressions.includes(ASSIGNMENT_PLACEHOLDER)
      ) {
        assignmentsAsSubexpressions.splice(
          assignmentsAsSubexpressions.indexOf(ASSIGNMENT_PLACEHOLDER),
          1,
        )
      }
    })
  }

  public getAvailableTags(subject: OperationSubjectType): Tag[] {
    const lists = this.tags.map(tagType => {
      if (tagType === TagType.Status) {
        switch (subject) {
          case OperationSubjectType.Delivery:
            return getDeliveryStatusesAsTags()
          case OperationSubjectType.Permit:
            return getFormsStatusesAsTags()
        }
      }
      return this.tagsStore.tagListsByTagTypeMap[tagType]
    })
    return [].concat(...lists).sort(this.sortTagsAlphabetically)
  }

  private resetRemovingRuleId() {
    this.removingRuleId = null
  }

  private get newRule(): OperationRule {
    return new OperationRule(
      EMPTY_STRING,
      OperationType.Notification,
      this.operationSubjectType,
      this.state.activeProject.id,
      ASSIGNMENT_PLACEHOLDER,
      OperationRule.NEW_ID,
      null,
      Date.now(),
    )
  }

  private sortTagsAlphabetically = (tagA, tagB) =>
    (tagA.name || EMPTY_STRING)
      .toLowerCase()
      .localeCompare((tagB.name || EMPTY_STRING).toLowerCase())
}
