import { action, computed } from 'mobx'

import { ProjectAccessType } from '~/client/graph'
import { getAccountTypeTranslate } from '~/client/src/shared/constants/ProjectRoles'
import {
  InviteStatusCaption,
  getInviteStatusTranslate,
} from '~/client/src/shared/enums/InviteStatusCaption'
import MemberFilterType from '~/client/src/shared/enums/MemberFilterType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { getMemberFilterTypeDisplayName } from '~/client/src/shared/localization/enumDisplayTexts'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import User from '~/client/src/shared/models/User'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import SuperFilterStore, {
  IRootOption,
} from '~/client/src/shared/stores/ui/SuperFilter.store'

import DesktopInitialState from '../../stores/DesktopInitialState'

export default class BaseMemberFilterStore extends SuperFilterStore {
  public constructor(
    public readonly type: MemberFilterType,
    protected readonly state: DesktopInitialState,
    protected readonly sourceMap: { [optionId: string]: string[] },
    protected readonly getFilteredCollectionExcludeFilter: (
      excludedFilters?: string[],
    ) => User[],
    protected readonly onClickHandler?: () => void,
  ) {
    super(
      state.teamMembersFilters.fieldsMap[type],
      type,
      state,
      KnownTranslatorKeys.seeXUsers,
      getMemberFilterTypeDisplayName,
      undefined,
      false,
      onClickHandler,
    )
  }

  public get totalHint(): string {
    return Localization.translator.totalUsers
  }

  public filterOptionInstances = (membersIds: string[]) => {
    return this.allFilteredMembers.filter(m => membersIds.includes(m.id))
  }

  @computed
  private get allFilteredMembers() {
    return this.getFilteredCollectionExcludeFilter
      ? this.getFilteredCollectionExcludeFilter([this.type])
      : []
  }

  @computed
  public get optionsTree(): IRootOption[] {
    const options = Object.keys(this.sourceMap)

    return [
      {
        id: this.type,
        name: this.typeCaption,
        options: options.map(option => ({
          id: option,
          name: this.formatOptionKey(option, this.type),
          instancesIds: this.sourceMap[option],
        })),
      },
    ]
  }

  @action.bound
  public onFilterActionRequest(eventContext: EventContext) {
    const [eventType] = eventContext.event

    switch (eventType) {
      case e.RESET_ALL_FILTERS:
        return this.setInitialFilterValues()
    }
  }

  @action.bound
  public clickOnApply() {
    this.handleApply()
    this.onClickHandler?.()
  }

  private formatOptionKey(optionKey: string, filterType: string): string {
    switch (filterType) {
      case MemberFilterType.InviteStatus:
        return getInviteStatusTranslate(optionKey as InviteStatusCaption)
      case MemberFilterType.AccountType:
        return getAccountTypeTranslate(optionKey as ProjectAccessType)

      default:
        return optionKey
    }
  }
}
