import IIntegrationConfig from '../models/IIntegrationConfig'

export default function isIntegrationConfigValid(
  config: any,
): config is IIntegrationConfig {
  return (
    !!config?.AppName &&
    typeof config.AppName === 'string' &&
    typeof config.IsEnabled === 'boolean'
  )
}
