import { action, observable } from 'mobx'

import { IAnalyticSettingsField } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import AnalyticsSettingsStore from '~/client/src/desktop/stores/domain/AnalyticsSettings.store'
import AnalyticsReportsType, {
  analyticsReportsTypeLabel,
} from '~/client/src/shared/enums/AnalyticReportsType'
import IAnalyticsReportType from '~/client/src/shared/models/IAnalyticsReportType'
import { SAVE_ANALYTICS_SETTING } from '~/client/src/shared/stores/EventStore/eventConstants'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'

export default class AnalyticsSetupStore {
  @observable public analyticsSettingFields: Map<
    string,
    IAnalyticSettingsField
  > = new Map()
  @observable public isConfigureModalOpened: boolean = false
  @observable public selectedReportType: IAnalyticsReportType = null

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly permitTypesStore: PermitTypesStore,
    private readonly analyticsSettingsStore: AnalyticsSettingsStore,
  ) {}

  @action.bound
  public toggleAvailableReports(reportId: string) {
    const reportField = this.analyticsSettingFields.get(reportId)
    reportField.isEnabled = !reportField.isEnabled
    this.save()
  }

  @action.bound
  public updateAnalyticsSettings() {
    this.analyticsSettingsStore.mySettingFields.forEach(field => {
      this.analyticsSettingFields.set(
        field.type === AnalyticsReportsType.FORM_REPORT ? field.id : field.type,
        {
          ...field,
          summaryFields: field.summaryFields || [],
          permitTypeFields: field.permitTypeFields || [],
        },
      )
    })

    this.reportTypes.forEach(reportType => {
      const reportField = this.analyticsSettingFields.get(reportType.id)

      if (reportField) return

      this.analyticsSettingFields.set(
        reportType.type === AnalyticsReportsType.FORM_REPORT
          ? reportType.id
          : reportType.type,
        {
          ...reportType,
          id:
            reportType.type === AnalyticsReportsType.FORM_REPORT
              ? reportType.id
              : undefined,
          isEnabled: false,
          summaryFields: [],
          permitTypeFields: [],
        },
      )
    })
  }

  @action.bound
  public openConfigureModal(reportType: IAnalyticsReportType) {
    this.isConfigureModalOpened = true
    this.selectedReportType = reportType
  }

  @action.bound
  public closeConfigureModal() {
    this.isConfigureModalOpened = false
    this.selectedReportType = null
  }

  public save = () => {
    const fields = Array.from(this.analyticsSettingFields.values())

    this.analyticsSettingsStore.updateSetting(fields)
  }

  public get selectedReportTypeFields() {
    return this.analyticsSettingFields.get(this.selectedReportType.id)
  }

  private get state() {
    return this.eventsStore.appState
  }

  public get loading(): boolean {
    return !this.analyticsSettingsStore.isDataReceived
  }

  public get updating(): boolean {
    return this.state.loading.get(SAVE_ANALYTICS_SETTING)
  }

  public get reportTypes(): IAnalyticsReportType[] {
    return [
      ...this.permitTypesStore.actualTypes.map(type => ({
        id: type.id,
        name: type.name,
        type: AnalyticsReportsType.FORM_REPORT,
      })),
      {
        id: AnalyticsReportsType.SCAN_STATION_REPORT,
        type: AnalyticsReportsType.SCAN_STATION_REPORT,
        name: analyticsReportsTypeLabel[
          AnalyticsReportsType.SCAN_STATION_REPORT
        ],
      },
      {
        id: AnalyticsReportsType.DELIVERY_REPORT,
        type: AnalyticsReportsType.DELIVERY_REPORT,
        name: analyticsReportsTypeLabel[AnalyticsReportsType.DELIVERY_REPORT],
      },
      {
        id: AnalyticsReportsType.MATERIAL_TRACKING_REPORT,
        type: AnalyticsReportsType.MATERIAL_TRACKING_REPORT,
        name: analyticsReportsTypeLabel[
          AnalyticsReportsType.MATERIAL_TRACKING_REPORT
        ],
      },
    ]
  }

  public isAvailableReport = (reportId: string): boolean => {
    return this.analyticsSettingFields.get(reportId).isEnabled
  }
}
