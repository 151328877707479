import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { NOOP } from '../../../utils/noop'

import './BaseStruxhubTagsInputWrapper.scss'

interface IProps {
  isInFocus: boolean
  isValueInvalid: boolean

  onClick?: () => void
}

@observer
export default class BaseStruxhubTagsInputWrapper extends React.Component<IProps> {
  public render() {
    const { isInFocus, isValueInvalid, onClick = NOOP, children } = this.props

    return (
      <div
        className={classList({
          'row y-center tags-input-field full-width bg-palette-brand-lightest no-outline brada4 px8 py7 text extra-large line-24':
            true,
          'ba-light-blue': isInFocus && !isValueInvalid,
          'ba-palette-brand-lighter': !isInFocus && !isValueInvalid,
          'ba-red': isValueInvalid,
        })}
        tabIndex={0}
        onClick={onClick}
      >
        {children}
      </div>
    )
  }
}
