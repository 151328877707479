import * as React from 'react'

import { ObservableMap, computed, observable } from 'mobx'

import ContentObjectActionBar from '~/client/src/shared/components/ContentObject/ContentObjectActionBar'
import { HeaderBar } from '~/client/src/shared/components/HeaderBar'
import ImagePreview from '~/client/src/shared/components/ImagePreview/ImagePreview'
import Activity from '~/client/src/shared/models/Activity'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'
import MobXMap from '~/client/src/shared/types/MobXMap'

// translated

interface IProps {
  contentObjectsStore?: { byId: MobXMap<any> | ObservableMap }
  commonStore?: CommonStore
  activitiesStore?: ActivitiesStore
  slackBarStore?: SlackBarStore
  contentObjectId?: string
}

export default class ContentObjectViewBase<
  T extends IProps,
> extends React.Component<T> {
  @observable protected contentObjectId: string
  protected className: string
  protected caption: () => string
  protected replyToCaption: () => string

  @computed
  public get contentObject() {
    const { contentObjectsStore } = this.props
    return (
      contentObjectsStore.byId.get(this.contentObjectId) ||
      this.createNullContentObject()
    )
  }

  public get activity() {
    return (
      this.props.activitiesStore.byId.get(
        this.contentObject.activityObjectId,
      ) || Activity.none
    )
  }

  public goBack() {
    throw new Error('goBack() should be overwriten')
  }

  public goToActivityDetails() {
    throw new Error('goToActivityDetails() should be overwriten')
  }

  public get header() {
    return (
      <HeaderBar>
        <img
          className="no-grow pr20"
          src="/static/icons/back-arrow.svg"
          alt="BACK"
          onClick={this.goBack}
        />
        <div
          className="text large bold text-ellipsis half-flex-basis"
          onClick={this.goToActivityDetails}
        >
          {this.caption()}: {this.activity.name} | {this.activity.code}
        </div>
        <div className="text right row">{this.getIcon()}</div>
      </HeaderBar>
    )
  }

  public get imagePreview() {
    const { slackBarStore } = this.props

    if (slackBarStore.shouldShowPreview) {
      return (
        <ImagePreview>
          <img src={slackBarStore.previewImage} className="center" />
        </ImagePreview>
      )
    }
  }

  public UNSAFE_componentWillReceiveProps(newProps: IProps) {
    if (this.contentObjectId !== newProps.contentObjectId) {
      this.setContentObject(newProps.contentObjectId)
    }
  }

  public componentDidMount() {
    this.setContentObject(this.props.contentObjectId)
  }

  public get footer() {
    return (
      <ContentObjectActionBar
        model={this.contentObject}
        className={this.className}
        placeholder={this.replyToCaption()}
      />
    )
  }

  protected createNullContentObject() {
    return {}
  }

  protected getIcon(): React.ReactNode {
    throw new Error('getIcon() should be overwriten')
  }

  private setContentObject = (id: string) => {
    this.contentObjectId = id
  }
}
