import * as React from 'react'

import { inject, observer } from 'mobx-react'

import ProjectMembersStore from '../../../stores/domain/ProjectMembers.store'
import UserProfilePreview from '../../UserProfilePreview/UserProfilePreview'
import { ISharedProps } from '../BaseStruxhubInput'
import BaseStruxhubSelector from './BaseStruxhubSelector'

// localization: no text to translate

interface IProps extends ISharedProps {
  onClick?: () => void

  projectMembersStore?: ProjectMembersStore
}

@inject('projectMembersStore')
@observer
export default class StruxhubUserSelector extends React.Component<IProps> {
  public render() {
    const { projectMembersStore, value } = this.props
    const user = projectMembersStore.getById(value)

    return (
      <BaseStruxhubSelector {...this.props} selectorClassName="h56">
        {user && (
          <UserProfilePreview
            user={user}
            shouldUseExtraLargeFont={true}
            briefInfoClassName="medium"
          />
        )}
      </BaseStruxhubSelector>
    )
  }
}
