import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { DeliveryPageMode } from '~/client/src/shared/components/Deliveries/DeliveriesView.store'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import DeliveryIconLabel from '~/client/src/shared/components/DeliveryIconLabel'
import * as Icons from '~/client/src/shared/components/Icons'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import getDeliveryCopyWithNewStatus from '~/client/src/shared/utils/getDeliveryCopyWithNewStatus'

import CommonStore from '../../stores/ui/Common.store'

import './DeliveryActions.scss'

// translated

interface IProps {
  deliveryId: string
  hideChangeButton?: boolean
  hideViewButton?: boolean
  hideActionButton?: boolean
  className?: string
  onDeliveryChange?: (event: React.MouseEvent<HTMLElement>) => void
  eventsStore?: EventsStore
  deliveriesStore?: DeliveriesStore
  deliveryDetailsStore?: DeliveryDetailsStore
  common?: CommonStore
}

const ACTION_BUTTON_HIGHLIGHTING_DURATION = 2000

@inject('common', 'eventsStore', 'deliveriesStore', 'deliveryDetailsStore')
@observer
export default class DeliveryActions extends React.Component<IProps> {
  @observable private isActionButtonActive: boolean = false

  private get delivery(): Delivery {
    return this.props.deliveriesStore.byId.get(this.props.deliveryId)
  }

  public render() {
    const { className = '' } = this.props

    return (
      <div className={`delivery-actions row bt-light-grey ${className}`}>
        {this.renderViewButton()}
        {this.renderChangeButton()}
        {this.renderActionButton()}
      </div>
    )
  }

  private renderChangeButton() {
    return (
      this.delivery &&
      this.delivery.canChange &&
      !this.props.hideChangeButton && (
        <div
          onClick={this.onDeliveryChange}
          className="pa15 row x-center text bold extra-large red"
        >
          {Localization.translator.change_verb}
        </div>
      )
    )
  }

  @action.bound
  private onDeliveryChange(event: React.MouseEvent<HTMLElement>) {
    const { onDeliveryChange, deliveryDetailsStore, common } = this.props

    if (onDeliveryChange) {
      onDeliveryChange(event)
      return
    }

    deliveryDetailsStore.setChangeMode(this.delivery)
    common.displayDeliveryDetailsView(this.delivery.id)
    this.props.eventsStore.appState.resetToDefaultCompactDeliveryView()
  }

  private renderViewButton() {
    return (
      this.delivery &&
      !this.delivery.isDone &&
      !this.props.hideViewButton && (
        <div
          onClick={this.viewDelivery}
          className="pa15 row no-flex-children x-center text bold extra-large primary-blue"
        >
          <div className="mr5">{Localization.translator.view_verb}</div>
          <Icons.CalendarGrey />
        </div>
      )
    )
  }

  @action.bound
  private viewDelivery() {
    const { compactView } = this.props.eventsStore.appState.delivery
    compactView.highlightedDelivery = this.delivery
    compactView.mode = DeliveryPageMode.VIEW_WITH_HIGHLIGHTED_DELIVERY
    compactView.resetViewMode()
    this.props.common.openDeliveryView()
  }

  private get actionButtonCaption() {
    switch (this.delivery.nextStatus) {
      case DeliveryStatus.Scheduled:
        return Localization.translator.accept
      case DeliveryStatus.PassedInspection:
        return Localization.translator.pass_verb
      case DeliveryStatus.OnSite:
        return <DeliveryIconLabel label={Localization.translator.onSite} />
      case DeliveryStatus.IncompleteDone:
      case DeliveryStatus.Done:
        return <DeliveryIconLabel label={Localization.translator.done} />
    }
  }

  private get userProject() {
    return this.props.eventsStore.appState.userActiveProjectSettings
  }

  private renderActionButton() {
    return (
      this.delivery &&
      this.delivery.canUserChangeStatus(
        this.delivery.nextStatus,
        this.userProject,
      ) &&
      !this.props.hideActionButton && (
        <div
          onClick={this.changeDeliveryStatus}
          className={classList({
            'pa15 row no-flex-children x-center text bold extra-large primary-blue':
              true,
            highlight: this.isActionButtonActive,
          })}
        >
          {this.actionButtonCaption}
        </div>
      )
    )
  }

  @action.bound
  private changeDeliveryStatus() {
    this.highlightActionButton()
    if (
      this.delivery &&
      this.delivery.canUserChangeStatus(
        this.delivery.nextStatus,
        this.userProject,
      )
    ) {
      const updatedDelivery = getDeliveryCopyWithNewStatus(
        this.delivery,
        this.delivery.nextStatus,
      )

      this.props.deliveriesStore.updateDelivery(updatedDelivery, null, true)
    }
  }

  @action.bound
  private highlightActionButton() {
    this.isActionButtonActive = true
    setTimeout(() => {
      this.isActionButtonActive = false
    }, ACTION_BUTTON_HIGHLIGHTING_DURATION)
  }
}
