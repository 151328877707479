import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import { InviteStatus } from '~/client/graph'
import { GetProjectNameDocument } from '~/client/graph/operations/generated/Project.generated'
import { RequestAccessToProjectDocument } from '~/client/graph/operations/generated/UserAccess.generated'

import InfoCode from '../enums/InfoCode'
import EventsStore from '../stores/EventStore/Events.store'
import {
  GO_TO_AVAILABLE_PROJECT,
  LOAD_PROJECTS,
} from '../stores/EventStore/eventConstants'
import GraphExecutorStore from '../stores/domain/GraphExecutor.store'
import CommonStore from '../stores/ui/Common.store'
import BaseActionButton from './BaseActionButton/BaseActionButton'

interface IProps {
  common?: CommonStore
  eventsStore?: EventsStore
  graphExecutorStore?: GraphExecutorStore
}

const requestAccessToTheProject = 'Request Access'
const goToAvailableProject = 'Go to available project'

@inject('common', 'eventsStore', 'graphExecutorStore')
@observer
export default class AccessRequester extends React.Component<IProps> {
  @observable private isLoading: boolean = false
  @observable private projectName: string = ''

  public componentDidMount() {
    this.getProjectName()
    this.props.common.hideNavBar()
  }

  public render() {
    return (
      <>
        <div className="col bg-light-cool-grey bb-light-input-border px15 py10">
          <div className="row">
            <div className="text large mr15">
              🛑 {`You do not have access to `}
              <span>
                {this.projectName ? (
                  <span className="text large bold">{this.projectName}</span>
                ) : (
                  'this project'
                )}
              </span>
            </div>

            <div className="col" style={{ maxWidth: '180px' }}>
              <BaseActionButton
                className="scale-blue-theme"
                title={requestAccessToTheProject}
                onClick={this.requestAccess}
                isEnabled={true}
                isLoading={this.isLoading}
                isGrow={true}
              />
            </div>
          </div>
          <a
            onClick={this.goToAvailableProject}
            className="text large blue-brand underline pt20"
          >
            {goToAvailableProject}
          </a>
        </div>
        {this.props.children || this.dummyProjectContent}
      </>
    )
  }

  @action.bound
  private async requestAccess() {
    this.isLoading = true

    const { graphExecutorStore } = this.props
    const res = await graphExecutorStore.mutate(
      RequestAccessToProjectDocument,
      {
        projectCode: this.initProjectCode,
      },
    )

    this.isLoading = false

    if (!res.data) {
      return alert(res.error.message)
    }

    const { requestProjectAccess: inviteStatus } = res.data
    if (inviteStatus === InviteStatus.Pending) {
      // dispatch e.LOAD_PROJECTS in order to fetch updated list of project and proceed initialization chain
      // see e.INIT_APP flow
      this.props.eventsStore.dispatch(LOAD_PROJECTS)
    } else {
      this.props.common.displayInfoPage(
        InfoCode.ACCESS_TO_PROJECT_REQUESTED,
        '',
      )
    }
  }

  @action.bound
  private async getProjectName() {
    const res = await this.props.graphExecutorStore.query(
      GetProjectNameDocument,
      {
        projectCode: this.initProjectCode,
      },
    )

    if (res.data) {
      this.projectName = res.data.getProjectName
    }
  }

  private goToAvailableProject = () => {
    this.props.eventsStore.dispatch(GO_TO_AVAILABLE_PROJECT)
  }

  private get initProjectCode(): string {
    return this.props.eventsStore.appState.initProjectCode
  }

  // TODO: Design TBD
  private get dummyProjectContent(): JSX.Element {
    return (
      <img
        src="/static/images/deliveries-screen.png"
        style={{
          width: '100%',
          height: '100%',
          filter: 'blur(20px)',
          opacity: '0.4',
        }}
      />
    )
  }
}
