import * as React from 'react'

import { IPermitTypeField } from '~/client/graph'

interface IProps {
  typeField: IPermitTypeField
}

export default function PermitFormTextField({
  typeField,
}: IProps): JSX.Element {
  return <div className="ml5 my12 text large">{typeField.caption}</div>
}
