import { action, observable } from 'mobx'

import { IFilters } from '~/client/src/shared/stores/InitialState'

// localization: no display text to translate

export default class EntityIconFilterStore {
  @observable public isShown: boolean = false

  public get isActive(): boolean {
    return !!this.iconKey
  }

  public get iconKey() {
    return this.filters.iconKey
  }

  public constructor(
    protected readonly filters: IFilters,
    private readonly onShowChanged?: (isShown: boolean) => void,
  ) {}

  @action.bound
  public toggle() {
    this.isShown = !this.isShown
    if (this.onShowChanged) {
      this.onShowChanged(this.isShown)
    }
  }

  @action.bound
  public applyIcon(iconKey: string) {
    this.filters.iconKey = iconKey
    this.isShown = false
    if (this.onShowChanged) {
      this.onShowChanged(this.isShown)
    }
  }
}
