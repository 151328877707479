import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ISitePermitAssignmentFieldsFragment = Pick<
  Types.ISitePermitAssignment,
  'id' | 'projectId' | 'entityId' | 'createdAt' | 'updatedAt'
> & { recipients: Array<Types.Maybe<Pick<Types.IRecipients, 'ids' | 'type'>>> }

export type IGetSitePermitAssignmentListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetSitePermitAssignmentListQuery = {
  sitePermitAssignments?: Types.Maybe<{
    data: Array<ISitePermitAssignmentFieldsFragment>
  }>
}

export type ISaveManySitePermitAssignmentsMutationVariables = Types.Exact<{
  assignments:
    | Array<Types.Maybe<Types.ISitePermitAssignmentInput>>
    | Types.Maybe<Types.ISitePermitAssignmentInput>
}>

export type ISaveManySitePermitAssignmentsMutation = {
  saveManySitePermitAssignments?: Types.Maybe<
    Array<Types.Maybe<ISitePermitAssignmentFieldsFragment>>
  >
}

export type IDeleteManySitePermitAssignmentsMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteManySitePermitAssignmentsMutation = Pick<
  Types.IMutation,
  'deleteManySitePermitAssignments'
>

export type IListenToSitePermitAssignmentsSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToSitePermitAssignmentsSubscription = {
  sitePermitAssignment?: Types.Maybe<
    Pick<Types.ISitePermitAssignmentChangeEvent, 'id'> & {
      item?: Types.Maybe<ISitePermitAssignmentFieldsFragment>
    }
  >
}

export const SitePermitAssignmentFieldsFragmentDoc = gql`
  fragment SitePermitAssignmentFields on SitePermitAssignment {
    id
    projectId
    entityId
    recipients {
      ids
      type
    }
    createdAt
    updatedAt
  }
`
export const GetSitePermitAssignmentListDocument = gql`
  query GetSitePermitAssignmentList($projectId: ObjectId!) {
    sitePermitAssignments(limit: 1000000, projectId: $projectId) {
      data {
        ...SitePermitAssignmentFields
      }
    }
  }
  ${SitePermitAssignmentFieldsFragmentDoc}
`

/**
 * __useGetSitePermitAssignmentListQuery__
 *
 * To run a query within a React component, call `useGetSitePermitAssignmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitePermitAssignmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitePermitAssignmentListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetSitePermitAssignmentListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetSitePermitAssignmentListQuery,
    IGetSitePermitAssignmentListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetSitePermitAssignmentListQuery,
    IGetSitePermitAssignmentListQueryVariables
  >(GetSitePermitAssignmentListDocument, options)
}
export function useGetSitePermitAssignmentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetSitePermitAssignmentListQuery,
    IGetSitePermitAssignmentListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetSitePermitAssignmentListQuery,
    IGetSitePermitAssignmentListQueryVariables
  >(GetSitePermitAssignmentListDocument, options)
}
export type GetSitePermitAssignmentListQueryHookResult = ReturnType<
  typeof useGetSitePermitAssignmentListQuery
>
export type GetSitePermitAssignmentListLazyQueryHookResult = ReturnType<
  typeof useGetSitePermitAssignmentListLazyQuery
>
export type GetSitePermitAssignmentListQueryResult = Apollo.QueryResult<
  IGetSitePermitAssignmentListQuery,
  IGetSitePermitAssignmentListQueryVariables
>
export const SaveManySitePermitAssignmentsDocument = gql`
  mutation SaveManySitePermitAssignments(
    $assignments: [SitePermitAssignmentInput]!
  ) {
    saveManySitePermitAssignments(sitePermitAssignments: $assignments) {
      ...SitePermitAssignmentFields
    }
  }
  ${SitePermitAssignmentFieldsFragmentDoc}
`
export type ISaveManySitePermitAssignmentsMutationFn = Apollo.MutationFunction<
  ISaveManySitePermitAssignmentsMutation,
  ISaveManySitePermitAssignmentsMutationVariables
>

/**
 * __useSaveManySitePermitAssignmentsMutation__
 *
 * To run a mutation, you first call `useSaveManySitePermitAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManySitePermitAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManySitePermitAssignmentsMutation, { data, loading, error }] = useSaveManySitePermitAssignmentsMutation({
 *   variables: {
 *      assignments: // value for 'assignments'
 *   },
 * });
 */
export function useSaveManySitePermitAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManySitePermitAssignmentsMutation,
    ISaveManySitePermitAssignmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManySitePermitAssignmentsMutation,
    ISaveManySitePermitAssignmentsMutationVariables
  >(SaveManySitePermitAssignmentsDocument, options)
}
export type SaveManySitePermitAssignmentsMutationHookResult = ReturnType<
  typeof useSaveManySitePermitAssignmentsMutation
>
export type SaveManySitePermitAssignmentsMutationResult =
  Apollo.MutationResult<ISaveManySitePermitAssignmentsMutation>
export type SaveManySitePermitAssignmentsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveManySitePermitAssignmentsMutation,
    ISaveManySitePermitAssignmentsMutationVariables
  >
export const DeleteManySitePermitAssignmentsDocument = gql`
  mutation DeleteManySitePermitAssignments($ids: [ObjectId]!) {
    deleteManySitePermitAssignments(ids: $ids)
  }
`
export type IDeleteManySitePermitAssignmentsMutationFn =
  Apollo.MutationFunction<
    IDeleteManySitePermitAssignmentsMutation,
    IDeleteManySitePermitAssignmentsMutationVariables
  >

/**
 * __useDeleteManySitePermitAssignmentsMutation__
 *
 * To run a mutation, you first call `useDeleteManySitePermitAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManySitePermitAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManySitePermitAssignmentsMutation, { data, loading, error }] = useDeleteManySitePermitAssignmentsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteManySitePermitAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteManySitePermitAssignmentsMutation,
    IDeleteManySitePermitAssignmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteManySitePermitAssignmentsMutation,
    IDeleteManySitePermitAssignmentsMutationVariables
  >(DeleteManySitePermitAssignmentsDocument, options)
}
export type DeleteManySitePermitAssignmentsMutationHookResult = ReturnType<
  typeof useDeleteManySitePermitAssignmentsMutation
>
export type DeleteManySitePermitAssignmentsMutationResult =
  Apollo.MutationResult<IDeleteManySitePermitAssignmentsMutation>
export type DeleteManySitePermitAssignmentsMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteManySitePermitAssignmentsMutation,
    IDeleteManySitePermitAssignmentsMutationVariables
  >
export const ListenToSitePermitAssignmentsDocument = gql`
  subscription ListenToSitePermitAssignments($projectId: ObjectId!) {
    sitePermitAssignment(projectId: $projectId) {
      id
      item {
        ...SitePermitAssignmentFields
      }
    }
  }
  ${SitePermitAssignmentFieldsFragmentDoc}
`

/**
 * __useListenToSitePermitAssignmentsSubscription__
 *
 * To run a query within a React component, call `useListenToSitePermitAssignmentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToSitePermitAssignmentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToSitePermitAssignmentsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToSitePermitAssignmentsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToSitePermitAssignmentsSubscription,
    IListenToSitePermitAssignmentsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToSitePermitAssignmentsSubscription,
    IListenToSitePermitAssignmentsSubscriptionVariables
  >(ListenToSitePermitAssignmentsDocument, options)
}
export type ListenToSitePermitAssignmentsSubscriptionHookResult = ReturnType<
  typeof useListenToSitePermitAssignmentsSubscription
>
export type ListenToSitePermitAssignmentsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToSitePermitAssignmentsSubscription>
