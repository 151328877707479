import { isBefore } from '~/client/src/shared/stores/ui/ProjectDate.store'

import IWbsRow from '../../../interfaces/IWbsRow'

export function sortByNumber(
  isProgressColumn: boolean,
  activityRowA: IWbsRow,
  activityRowB: IWbsRow,
  aValue: number,
  bValue: number,
  sortingCoef: number,
) {
  if (isProgressColumn) {
    const activityA = activityRowA.activity
    const activityB = activityRowB.activity
    const isAValueEmpty = !activityA.didStart || !aValue
    const isBValueEmpty = !activityB.didStart || !bValue
    if (isAValueEmpty && isBValueEmpty) {
      if (isBefore(activityA.startDate, activityB.startDate)) {
        return sortingCoef
      }
      return -sortingCoef
    }
  }
  return (aValue - bValue) * sortingCoef
}

export function sortByDate(
  aValue: string | number,
  bValue: string | number,
  sortingCoef: number,
) {
  const aDate = new Date(aValue).getTime()
  const bDate = new Date(bValue).getTime()
  if (isNaN(aDate) && !isNaN(bDate)) {
    return -sortingCoef
  }
  if (isNaN(bDate) && !isNaN(aDate)) {
    return sortingCoef
  }
  return (aDate - bDate) * sortingCoef
}

export function sortByCommonRules(
  activityRowB: IWbsRow,
  aValue: string | number,
  sortingCoef: number,
) {
  let compareValue = 0
  if (aValue !== null && activityRowB === null) {
    compareValue = 1
  }
  if (aValue === null && activityRowB !== null) {
    compareValue = -1
  }

  return compareValue * sortingCoef
}
