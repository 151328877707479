import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import { ITag } from '~/client/src/shared/models/Tag'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import './SearchBar.scss'

interface IProps {
  value: string
  onChange: (newValue: string) => void
  onReset: () => void
  onTagClick: () => void
  pivotalTag?: ITag
  customPlaceholder?: string
  className?: string
}

// translated

@observer
export default class SearchBar extends React.Component<IProps> {
  public render() {
    const { value, onReset, className } = this.props

    return (
      <div
        className={classList({
          'row search-bar-container bg-palette-brand-lightest no-outline-container brada24 pa12 text large grey-light':
            true,
          [className]: !!className,
        })}
      >
        <Icon
          icon={IconNames.SEARCH}
          className={classList({
            mr8: true,
            'text blue': !!value,
          })}
        />
        {this.renderInputArea()}
        {!!value && (
          <Icon
            icon={IconNames.CROSS}
            className="row no-grow ml4"
            onClick={onReset}
          />
        )}
      </div>
    )
  }

  private renderInputArea() {
    const { pivotalTag, value, onTagClick } = this.props

    return (
      <>
        {pivotalTag && (
          <span
            className="row text no-flex large text-ellipsis mr8"
            onClick={onTagClick}
          >
            <SitemapAttributeTag
              isRemovable={true}
              dataObject={pivotalTag as LocationAttributeBase}
              shouldShowAsTag={true}
              onRemove={onTagClick}
              contentContainerClassName="text-ellipsis"
            >
              <span>{pivotalTag.name}</span>
            </SitemapAttributeTag>
          </span>
        )}
        <input
          className="bare-input text large grey-light full-width"
          value={value}
          autoFocus={!!pivotalTag}
          placeholder={this.inputPlaceholder}
          onChange={this.handleChangeQuery}
        />
      </>
    )
  }

  private handleChangeQuery = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(target.value)
  }

  private get inputPlaceholder(): string {
    const { pivotalTag, customPlaceholder } = this.props

    return pivotalTag
      ? EMPTY_STRING
      : customPlaceholder || Localization.translator.searchUserOrGroup
  }
}
