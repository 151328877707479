import * as React from 'react'

import { Icon, MenuItem } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { ItemRenderer, Select } from '@blueprintjs/select'
import { observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Company from '~/client/src/shared/models/Company'

const NONE_VALUE = null
const NONE_OPTION = 'None'

interface IProps {
  value: Company
  options: Company[]
  applyCompany: (newValue: Company, oldValue?: Company) => void
}

@observer
export default class MappingCompanySelector extends React.Component<IProps> {
  private get objects() {
    const { options } = this.props
    const sortedObjects = options.sort((a, b) => a.name.localeCompare(b.name))

    return [NONE_VALUE, ...sortedObjects]
  }

  public render() {
    return (
      <div className="mapping-selector full-width">
        <Select
          items={this.objects}
          itemRenderer={this.renderOption}
          onItemSelect={this.onItemSelect}
          className="mapping-select"
          popoverProps={{
            popoverClassName: 'mapping-selector-popover',
            minimal: true,
          }}
          filterable={false}
        >
          <div className="mapping-selector-input row y-center">
            <div className="pl8">{this.renderTag(this.props.value)}</div>
            <Icon icon={IconNames.CARET_DOWN} className="px8" />
          </div>
        </Select>
      </div>
    )
  }

  private renderTag(dto: Company) {
    if (!dto) {
      return (
        <div className="text light extra-large">
          {Localization.translator.selectObject}
        </div>
      )
    }
    return (
      <>
        <div className="row ml10">
          <Icons.CompanyCompact className="row no-grow mr8 company-icon" />
          <div className="text extra-large ellipsis parent-tag">{dto.name}</div>
        </div>
      </>
    )
  }

  private renderOption: ItemRenderer<Company> = (
    company,
    { handleClick, modifiers },
  ) => {
    if (!company) {
      return (
        <MenuItem
          active={modifiers.active}
          key={NONE_OPTION}
          onClick={handleClick}
          text={NONE_OPTION}
        />
      )
    }

    return (
      <MenuItem
        active={modifiers.active}
        key={company.id}
        onClick={handleClick}
        text={this.renderTag(company)}
      />
    )
  }

  private onItemSelect = (company: Company) => {
    this.props.applyCompany(company, this.props.value)
  }
}
