import { observable } from 'mobx'

import { IGlobeViewTextBox, ISitemapTextBox } from '~/client/graph'

import { areObjectsEqual } from '../../../utils/util'

import Colors from '~/client/src/shared/theme.module.scss'

export const DEFAULT_MATURIX_FONT_SIZE = 12
const DEFAULT_FONT_SIZE = 16
const DEFAULT_IS_TEXT_BOX_DISPLAYED = true
const DEFAULT_IS_DISPLAYED = true

export default class GlobeViewLabelProperties {
  @observable public fontSize: number
  @observable public isTextBoxDisplayed: boolean
  @observable public isDisplayed: boolean
  @observable public color: string

  public constructor(
    fontSize?: number,
    isTextBoxDisplayed?: boolean,
    isDisplayed?: boolean,
    color?: string,
  ) {
    this.fontSize = fontSize || DEFAULT_FONT_SIZE
    this.isTextBoxDisplayed =
      typeof isTextBoxDisplayed === 'boolean'
        ? isTextBoxDisplayed
        : DEFAULT_IS_TEXT_BOX_DISPLAYED
    this.isDisplayed =
      typeof isDisplayed === 'boolean' ? isDisplayed : DEFAULT_IS_DISPLAYED
    this.color = color || Colors.paletteBrandDark
  }

  public isEqual(properties: GlobeViewLabelProperties) {
    return areObjectsEqual(this, properties)
  }

  public setFontSize(fontSize: number) {
    this.fontSize = fontSize
  }

  public toggleTextBoxVisibility() {
    this.isTextBoxDisplayed = !this.isTextBoxDisplayed
  }

  public toggleIsDisplayed() {
    this.isDisplayed = !this.isDisplayed
  }

  public isValid(): boolean {
    return this.fontSize > 0
  }

  public isDisplayDataEqual(textBox: IGlobeViewTextBox) {
    return (
      this.fontSize === textBox.fontSize &&
      this.isTextBoxDisplayed === textBox.isTextBoxDisplayed &&
      !textBox.isHidden === this.isDisplayed &&
      this.color === textBox.color
    )
  }

  public getDisplayData(): ISitemapTextBox {
    return {
      fontSize: this.fontSize,
      isTextBoxDisplayed: this.isTextBoxDisplayed,
      isHidden: !this.isDisplayed,
      color: this.color,
    }
  }
  public copy(): GlobeViewLabelProperties {
    return new GlobeViewLabelProperties(
      this.fontSize,
      this.isTextBoxDisplayed,
      this.isDisplayed,
      this.color,
    )
  }
}
