import * as React from 'react'

import { observer } from 'mobx-react'

import { CalendricalType } from '~/client/graph'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import WorkflowDiagramChangeLine from '../../WorkflowDiagramWrapper/components/WorkflowDiagramChangeLine'
import WorkflowDiagramLine, {
  DiagramLineType,
} from '../../WorkflowDiagramWrapper/components/WorkflowDiagramLine'
import { ActionState } from '../../WorkflowDiagramWrapper/components/WorkflowDiagramState'
import BaseStepDiagram, { IBaseStepDiagramProps } from './BaseStepDiagram'

// localization: translated

interface IProps extends IBaseStepDiagramProps {
  inspectionFrequency: number
  inspectionFrequencyType: CalendricalType
}

const LINE_TOP_OFFSET = -14
const LINE_LEFT_OFFSET = 0

@observer
export default class RecurringInspectionStepDiagram extends React.Component<IProps> {
  public render() {
    return (
      <BaseStepDiagram {...this.props} shouldSkipState>
        <WorkflowDiagramChangeLine />
        <div className="col x-center y-center">
          <div className="absolute text bold uppercase pa8 daily-inspection-label">
            {this.inspectionFrequencyDisplayText}
          </div>

          <div className="row x-center y-center px15 relative">
            <div className="relative row x-center">
              <WorkflowDiagramLine
                diagramLineType={DiagramLineType.VerticalLeftLineLong}
                className="absolute"
                top={LINE_TOP_OFFSET}
                left={LINE_LEFT_OFFSET}
              />
              <ActionState content={Localization.translator.change_verb} />
            </div>
            <ActionState
              content={Localization.translator.notInUse}
              className="mx15"
            />
            <ActionState content={Localization.translator.addInspection} />
            <div className="absolute brada8 full-width daily-inspection-block" />
          </div>
        </div>
        <WorkflowDiagramLine />
      </BaseStepDiagram>
    )
  }

  private get inspectionFrequencyDisplayText(): string {
    const { inspectionFrequency, inspectionFrequencyType } = this.props

    switch (inspectionFrequencyType) {
      case CalendricalType.Day:
        return Localization.translator.everyXDaysInspection(inspectionFrequency)
      case CalendricalType.Week:
        return Localization.translator.everyXWeeksInspection(
          inspectionFrequency,
        )
      case CalendricalType.Month:
        return Localization.translator.everyXMonthsInspection(
          inspectionFrequency,
        )
    }
  }
}
