import { action, computed, observable } from 'mobx'

import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import ActivityCode from '~/client/src/shared/models/ActivityCode'
import ActivityCodeType from '~/client/src/shared/models/ActivityCodeType'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import {
  RESET_ALL_FILTERS,
  RESET_FILTER,
  SET_FILTER_SELECTION,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivityAssignmentsStore from '~/client/src/shared/stores/domain/ActivityAssignments.store'
import ActivityCodeTypesStore from '~/client/src/shared/stores/domain/ActivityCodeTypes.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import { FilterSourceType } from '~/client/src/shared/stores/substates/ActivityFilterInfo'
import SuperFilterStore, {
  IOption,
  IRootOption,
} from '~/client/src/shared/stores/ui/SuperFilter.store'
import Guard from '~/client/src/shared/utils/Guard'
import { UNASSIGNED_FILTER_VALUE } from '~/client/src/shared/utils/ZoneLevelLocationConstants'

// localization: translated

export default class BaseActivityFilterStore extends SuperFilterStore {
  @observable public selectedZoneMapItemName: string

  public get totalHint(): string {
    return Localization.translator.totalActivities
  }

  public get filterCaption() {
    const { filterInfoMap } = this.state.activityFiltersSettings
    return filterInfoMap[this.type].getCaption()
  }

  public get filterSourceType() {
    const { filterInfoMap } = this.state.activityFiltersSettings
    return filterInfoMap[this.type].sourceType
  }

  public get shortNameCaption(): string {
    if (this.filterSourceType === FilterSourceType.ActivityCode) {
      return Localization.translator.code
    }

    return ''
  }

  public get nameCaption(): string {
    if (this.filterSourceType === FilterSourceType.ActivityCode) {
      return Localization.translator.description
    }

    if (this.filterSourceType === FilterSourceType.ProjectMember) {
      return Localization.translator.projectMember
    }

    return ''
  }

  public constructor(
    public readonly type: string,
    protected readonly state: DesktopInitialState,
    protected readonly desktopActivityListStore: DesktopActivityListStore,
    protected readonly activityCodeTypesStore: ActivityCodeTypesStore,
    protected readonly activityFiltersStore: ActivityFiltersStore,
    protected readonly activityAssignmentsStore: ActivityAssignmentsStore,
    protected readonly companiesStore: CompaniesStore,
    private readonly userProjectsStore: UserProjectsStore,
    protected readonly onShowChanged: (
      isShown: boolean,
      filterType: string,
    ) => void,
    protected readonly onClickHandler?: () => void,
  ) {
    super(
      state.filters.locationsMap[type],
      type,
      state,
      KnownTranslatorKeys.seeXActivities,
      (t: string) =>
        this.state.activityFiltersSettings.filterInfoMap[t].getCaption(),
      onShowChanged,
      false,
      onClickHandler,
    )

    Guard.requireAll({
      state,
      desktopActivityListStore,
      activityCodeTypesStore,
    })
  }

  @computed
  private get allFilteredActivities() {
    const store = this.desktopActivityListStore
    const activities = store.filteredActivitiesExcludeFilters([this.type])
    const matrix = store.buildCategoryFilteringMatrix(
      this.state.appliedCategoryFilters,
    )
    return store.filterActivitiesWithCategoryFilters(activities, matrix)
  }

  public filterOptionInstances = (instancesIds: string[]) => {
    return this.allFilteredActivities.filter(a => instancesIds.includes(a.code))
  }

  public get optionsTree(): IRootOption[] {
    switch (this.filterSourceType) {
      case FilterSourceType.ActivityCode:
        return this.codeOptionsTree

      case FilterSourceType.ProjectMember:
        return this.projectMembersOptionsTree
    }
  }

  @computed
  public get codeOptionsTree(): IRootOption[] {
    const optionsTree: IRootOption[] = []
    const allAssignedOptionIds = []

    this.activityCodeTypesStore.list.forEach(codeType => {
      if (this.shouldExcludeCodeType(codeType)) {
        return
      }

      const rootOptionChildren = this.activityCodeTypesStore.tree[codeType.id]
      const assignedOptionIds = []
      const options = rootOptionChildren
        .filter(({ code }) => !this.shouldExcludeCode(code))
        .map(({ code, activitiesIds }) => {
          assignedOptionIds.push(...activitiesIds)
          return {
            id: code.id,
            name: code.name,
            shortName: code.shortName,
            instancesIds: activitiesIds,
          }
        })

      allAssignedOptionIds.push(...assignedOptionIds)

      if (options.length) {
        optionsTree.push({ id: codeType.id, name: codeType.name, options })
      }
    })

    this.addUnassignedOption(optionsTree, allAssignedOptionIds)
    return optionsTree
  }

  @computed
  public get projectMembersOptionsTree(): IRootOption[] {
    const optionsTree: IRootOption[] = []
    const allAssignedOptionIds = []

    const availableProjectMembers =
      this.activityFiltersStore.getProjectMembersByFilterType(this.type)

    const projectMemberCompanyMap = availableProjectMembers.reduce(
      (companyMap, user) => {
        const companyName = UserProject.getCompanyName(
          user,
          this.userProjectsStore,
          this.companiesStore,
        )

        if (!companyMap[companyName]) {
          companyMap[companyName] = []
        }

        companyMap[companyName].push(user)
        return companyMap
      },
      {},
    )

    Object.keys(projectMemberCompanyMap).forEach(company => {
      const users = projectMemberCompanyMap[company]
      const assignedOptionIds = []
      const options: IOption[] = users.map(user => {
        const activitiesIds =
          this.activityAssignmentsStore.getAssignedEntitiesByUserId(user.id)

        assignedOptionIds.push(...activitiesIds)

        const option: IOption = {
          id: user.id,
          name: User.getFullNameToDisplay(user, this.userProjectsStore),
          instancesIds: activitiesIds,
        }

        return option
      })

      allAssignedOptionIds.push(...assignedOptionIds)

      if (options.length) {
        optionsTree.push({ id: company, name: company, options })
      }
    })

    this.addUnassignedOption(optionsTree, allAssignedOptionIds)
    return optionsTree
  }

  public get handleButtonCaption(): string {
    if (this.state.activityList.viewMode === ViewModes.Map) {
      return this.selectedZoneMapItemName || this.filterCaption
    }

    return this.getHandleButtonCaption()
  }

  public clearNotPresentOptions() {
    for (const id of this.selectedOptions.keys()) {
      const isOptionAvailable = this.optionsTree.some(ro =>
        ro.options.some(o => o.id === id),
      )

      if (!isOptionAvailable) {
        this.selectedOptions.delete(id)
      }
    }

    this.clickOnApply()
  }

  @action.bound
  public clickOnApply() {
    this.state.appliedActivityPresetId = null
    this.state.filters.appliedCustomFilterId = null

    this.handleApply()
  }

  @action.bound
  public onFilterActionRequest(eventContext: EventContext) {
    const [eventType] = eventContext.event
    if (eventType === RESET_ALL_FILTERS) {
      this.setInitialFilterValues()
    } else if (eventType === RESET_FILTER) {
      const [, filterType] = eventContext.event
      if (filterType === this.type) {
        this.setInitialFilterValues()
      }
    } else if (eventType === SET_FILTER_SELECTION) {
      const [, filterType, codeIds, selectedName] = eventContext.event
      if (filterType === this.type) {
        this.setZoneMapSelection(codeIds, selectedName)
      }
    }
  }

  public setZoneMapSelection(
    codeIds: string[],
    selectedZoneMapItemName: string,
  ) {
    this.selectedZoneMapItemName = selectedZoneMapItemName
    this.selectedOptions.clear()
    codeIds.forEach(codeId => {
      const optionId =
        codeId === UNASSIGNED_FILTER_VALUE ? this.unassignedOptionId : codeId

      let option: IOption
      this.optionsTree.find(
        ro => !!(option = ro.options.find(o => o.id === optionId)),
      )

      if (option) {
        this.selectedOptions.set(optionId, option.instancesIds)
      }
    })

    this.clickOnApply()
  }

  protected addUnassignedOption(
    optionsTree: IRootOption[],
    allAssignedOptionIds: string[],
  ) {
    const unassignedOptionIds =
      this.createUnassignedOptionIds(allAssignedOptionIds)

    optionsTree.push({
      id: 'unassigned-' + this.type + '-code-type',
      name: Localization.translator.unassigned,
      options: [
        {
          id: this.unassignedOptionId,
          name: Localization.translator.unassigned,
          instancesIds: unassignedOptionIds,
        },
      ],
    })
  }

  private shouldExcludeCodeType(codeType: ActivityCodeType): boolean {
    const codesByTypeIdMap =
      this.activityFiltersStore.getCodesByTypeIdMapByFilter(this.type)
    return (
      !codesByTypeIdMap[codeType.id] || !codesByTypeIdMap[codeType.id].length
    )
  }

  private shouldExcludeCode(code: ActivityCode): boolean {
    const codes = this.activityFiltersStore.getCodesByFilterType(this.type)
    return !codes.some(c => c.id === code.id)
  }

  private createUnassignedOptionIds(assignedOptionIds: string[]) {
    const { allActivities } = this.desktopActivityListStore
    return allActivities
      .filter(a => !assignedOptionIds.includes(a.code))
      .map(a => a.code)
  }
}
