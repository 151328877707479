import * as React from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import { LocationType } from '~/client/graph'
import LocationPicker from '~/client/src/shared/components/LocationPicker/LocationPicker'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import Sitemap from '~/client/src/shared/models/Sitemap'

import MapViewItemsSetupStore from '../../stores/MapViewItemsSetup.store'
import SitemapsSetupStore from '../../stores/SitemapsSetup.store'

const selectAssociatedLocation = 'Select associated location'

interface IProps {
  mapViewItemsSetupStore: MapViewItemsSetupStore
  sitemapsSetupStore: SitemapsSetupStore
  sitemap?: Sitemap
  onClose(): void
  postUpdateAction?(): void
}

const restrictedObjectTypes = [
  LocationType.LogisticsObject,
  LocationType.VerticalObject,
]

@observer
export default class LocationAssociationModal extends React.Component<IProps> {
  @observable private assignedId: string = null
  @observable private isProjectOverviewMap: boolean = false

  public constructor(props: IProps) {
    super(props)
    if (props.sitemap) {
      this.assignedId = props.mapViewItemsSetupStore.sitemapAssignedItems(
        props.sitemap,
      )[0]?.id
      this.isProjectOverviewMap = props.sitemap.isProjectOverviewMap
    } else {
      this.assignedId =
        props.mapViewItemsSetupStore.selectedSitemapAssignedItems[0]?.id
      this.isProjectOverviewMap =
        props.sitemapsSetupStore.selectedSitemap.isProjectOverviewMap
    }
  }

  public render() {
    const { onClose } = this.props

    return (
      <MenuCloser closeMenu={onClose} className="absolute">
        <LocationPicker
          restrictedObjectTypes={restrictedObjectTypes}
          className="location-association-modal"
          title={selectAssociatedLocation}
          onClose={onClose}
          selectedIds={this.assignedId ? [this.assignedId] : []}
          onChange={this.onAssignedIdsChanged}
          onApplyChanges={this.updateAssociations}
          onSingleSelect={this.onSingleAssignedIdsChanged}
          isLocationViewFirst={true}
          isSingleSelectionMode={true}
          firstRowText="Project Overview Maps"
          firstRowValue={this.isProjectOverviewMap}
          firstRowAction={this.firstRowAction}
        />
      </MenuCloser>
    )
  }

  private onSingleAssignedIdsChanged = (attr: LocationBase) => {
    this.assignedId = attr.id
    this.isProjectOverviewMap = false
  }

  private onAssignedIdsChanged = (assignedIds: string[]) => {
    this.assignedId = assignedIds[0]
    this.isProjectOverviewMap = false
  }

  private firstRowAction = () => {
    this.assignedId = null
    this.isProjectOverviewMap = !this.isProjectOverviewMap
  }

  private updateAssociations = async () => {
    const {
      mapViewItemsSetupStore: { updateSitemapAssignedItems },
      sitemapsSetupStore: { saveSitemapWithLoader },
      onClose,
      postUpdateAction,
    } = this.props
    onClose()
    if (this.sitemap.isProjectOverviewMap !== this.isProjectOverviewMap) {
      this.sitemap.isProjectOverviewMap = this.isProjectOverviewMap
      await saveSitemapWithLoader(this.sitemap, true)
    }
    if (this.assignedId) {
      await updateSitemapAssignedItems([this.assignedId], this.sitemap)
    } else {
      await updateSitemapAssignedItems([], this.sitemap)
    }
    if (postUpdateAction) {
      postUpdateAction()
    }
  }

  private get sitemap() {
    const {
      sitemapsSetupStore: { selectedSitemap },
      sitemap,
    } = this.props
    return sitemap || selectedSitemap
  }
}
