import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { IPermitTypeField } from '~/client/graph'
import StruxhubTextValueSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import SitePermitCreationFormStore from '../../SitePermitCreationForm.store'
import PermitBaseFormField from './PermitBaseFormField'
import PermitFieldRemoveIcon from './PermitFieldRemoveIcon'

interface IProps {
  typeField: IPermitTypeField
  isViewMode: boolean

  store: SitePermitCreationFormStore
  fieldsStore: IPermitFieldsStore

  tableId?: string
  tableRowIndex?: number

  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
export default class PermitFormAdditionalDateField extends React.Component<IProps> {
  public render() {
    const { isViewMode, typeField, fieldsStore, tableId, tableRowIndex } =
      this.props
    const { caption, isMandatory, isMultiple } = typeField
    const { isFieldChanged } = fieldsStore

    return (
      <PermitBaseFormField
        typeField={typeField}
        isViewMode={isViewMode}
        viewModeElements={this.viewModeDates}
        onAddFieldClick={this.addNewFieldValue}
      >
        {this.fieldValues.map((dateValue, index) => {
          const shouldShowRemoveBtn = !!index || !!dateValue

          return (
            <div key={index} className="row y-center">
              <StruxhubTextValueSelector
                className="overflow-hidden"
                label={caption}
                isRequired={isMandatory}
                isChanged={isFieldChanged(
                  typeField.id,
                  index,
                  tableId,
                  tableRowIndex,
                )}
                isLabelHidden={!!index}
                isHelperTextCondensed={isMultiple}
                value={dateValue}
                onClick={this.openDatePicker.bind(this, index)}
              />
              {isMultiple && shouldShowRemoveBtn && (
                <PermitFieldRemoveIcon
                  index={index}
                  onClick={this.removeValue}
                />
              )}
            </div>
          )
        })}
      </PermitBaseFormField>
    )
  }

  private get viewModeDates(): JSX.Element[] {
    return this.fieldValues
      .filter(d => d)
      .map((dateString, idx) => (
        <div key={idx} className="text large word-break-all">
          {dateString || NO_VALUE}
        </div>
      ))
  }

  private get fieldValues(): string[] {
    const {
      typeField,
      projectDateStore: { getWeekdayMonthDayAndYearToDisplay },
      fieldsStore,
      tableId,
      tableRowIndex,
    } = this.props
    const values = fieldsStore.getFieldValues<number>(
      typeField.id,
      tableId,
      tableRowIndex,
    )

    return values?.length
      ? values.map(v => (v ? getWeekdayMonthDayAndYearToDisplay(v) : ''))
      : ['']
  }

  private openDatePicker(fieldIndex: number) {
    const { store, typeField, tableId, tableRowIndex } = this.props
    store.setSelectedField(typeField, fieldIndex, tableId, tableRowIndex)
  }

  private addNewFieldValue = () => {
    if (!this.isTableField) {
      const { fieldsStore, typeField } = this.props
      fieldsStore.addNewFieldValue(typeField, 0)
    }
  }

  private removeValue = (index: number) => {
    if (!this.isTableField) {
      const { fieldsStore, typeField } = this.props
      fieldsStore.removeFieldValue(typeField.id, index)
    }
  }

  private get isTableField(): boolean {
    return !!this.props.tableId
  }
}
