import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { Redirect, Route } from 'react-router'

import ErrorBoundary from '~/client/src/shared/components/ErrorBoundary'
import FeatureFlag from '~/client/src/shared/components/FeatureFlag'
import FlaggedFeatures from '~/client/src/shared/enums/FlaggedFeatures'
import isTestEnvironment from '~/client/src/shared/utils/environment'

import { isProjectBasedPath } from '../constants/commonRoutes'
import AuthenticationStore from '../stores/domain/Authentication.store'
import ProjectsStore from '../stores/domain/Projects.store'
import { NOOP } from '../utils/noop'
import AccessRequester from './AccessRequester'

export interface IRoute {
  path: string
  component: any
  unprotectedAltComponent?: any

  exact?: boolean
  isProtected?: boolean
  isAdminOnly?: boolean

  isDeliveriesRelated?: boolean
  isQRCodeRelated?: boolean
  isLogisticsRelated?: boolean
  isFormsRelated?: boolean
  isTrackerRelated?: boolean
  isMaterialsRelated?: boolean
  isPhotosRelated?: boolean
  isAnalyticsRelated?: boolean
  flaggedFeature?: FlaggedFeatures
}

export interface IProps extends IRoute {
  defaultViewToDisplay?: string

  auth?: AuthenticationStore
  projectsStore?: ProjectsStore
}

@inject('auth', 'projectsStore')
@observer
export default class WrappedRoute extends React.Component<IProps> {
  public render() {
    return <ErrorBoundary>{this.renderComponent()}</ErrorBoundary>
  }

  private renderComponent() {
    const { flaggedFeature } = this.props

    if (!flaggedFeature) {
      return <Route {...this.props} component={this.component} />
    }

    return (
      <FeatureFlag
        features={[flaggedFeature]}
        defaultValues={[isTestEnvironment()]}
        render={this.renderFlaggedContent}
      />
    )
  }

  private renderFlaggedContent = ([isEnable]: boolean[]) => {
    const { defaultViewToDisplay = '/' } = this.props
    return isEnable ? (
      <Route {...this.props} component={this.component} />
    ) : (
      <Redirect to={defaultViewToDisplay} />
    )
  }

  private get component() {
    const {
      path: route,
      component,
      auth,
      projectsStore,
      isProtected,
      unprotectedAltComponent: UnprotectedAltComponent,
    } = this.props

    if (auth.isAuthenticated) {
      if (isProjectBasedPath(route)) {
        return projectsStore.hasActiveUserAccessToTargetProject
          ? component
          : () => (
              <AccessRequester>
                {!!UnprotectedAltComponent && <UnprotectedAltComponent />}
              </AccessRequester>
            )
      }

      return component
    } else if (isProtected) {
      return UnprotectedAltComponent || NOOP
    }

    return component
  }
}
