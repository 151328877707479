import * as React from 'react'

import { observer } from 'mobx-react'

import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'

export interface IProps {
  isShown: boolean
  name: string
  onApply(): void
  onHide(): void
}

@observer
export default class DeleteGlobeConfirmDialog extends React.Component<IProps> {
  public render() {
    const { isShown, onHide, onApply, name } = this.props

    return (
      <ConfirmDialog
        isOpen={isShown}
        onCancelClicked={onHide}
        onDoneClicked={onApply}
        title={this.title}
        doneTitle="Delete"
      >
        <div>
          <div className="text large mb10">
            Confirm deletion of the{' '}
            <span className="text bold large">{name}</span> ?
          </div>
        </div>
      </ConfirmDialog>
    )
  }

  private get title() {
    return `CONFIRM DELETION OF GLOBE VIEW`
  }
}
