import * as React from 'react'

import { observer } from 'mobx-react'

import IBasicProps from '~/client/src/shared/interfaces/IBasicProps'

import './WrappedSidebar.scss'

@observer
export default class WrappedSidebar extends React.Component<IBasicProps> {
  public render() {
    const { children } = this.props

    return (
      <aside className="wrapped-sidebar absolute-right bl-palette-brand-lighter bg-white pt12">
        {children}
      </aside>
    )
  }
}
