import * as React from 'react'

import { action, computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { RouteComponentProps, withRouter } from 'react-router'

import { ContentObjectStatus } from '~/client/graph'
import CommonThread from '~/client/src/shared/components/CommonThread/CommonThread'
import InitialState from '~/client/src/shared/stores/InitialState'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'

import CategoryOfVariance from '../../models/CategoryOfVariance'
import CommonStore from '../../stores/ui/Common.store'
import CategoryOfVarianceIcon from '../CategoryOfVarianceIcon'
import CloseContentObjectButton from '../ContentObject/CloseContentObjectButton'
import ReplyToContentObjectButton from '../ContentObject/ReplyToContentObjectButton'
import FileInputBase from '../FileInput/FileInput'

interface ICategoryOfVarianceEntryProps {
  categoryOfVariance: CategoryOfVariance
  messagesStore?: MessagesStore
  hideButtons?: boolean
  state?: InitialState
  common?: CommonStore
  FileInputType: typeof FileInputBase
}

@inject('messagesStore', 'state', 'common')
@observer
class CategoryOfVarianceThread extends React.Component<
  ICategoryOfVarianceEntryProps & RouteComponentProps<any>
> {
  private renderButtons(categoryOfVariance: CategoryOfVariance) {
    const { userActiveProjectSettings } = this.props.state
    return (
      <div
        className={classList({
          'row item-buttons-container': true,
          'inactive-element': userActiveProjectSettings?.isPresentationUser,
        })}
      >
        <ReplyToContentObjectButton
          contentObject={categoryOfVariance}
          onClick={this.displayView}
        />
        <CloseContentObjectButton contentObject={categoryOfVariance} />
      </div>
    )
  }

  @computed
  private get messages() {
    const { messagesStore, categoryOfVariance } = this.props
    return messagesStore.list.filter(
      m => m.threadId === categoryOfVariance.threadId,
    )
  }

  public render() {
    const { categoryOfVariance, hideButtons, FileInputType } = this.props
    const isCategoryOfVarianceClosed =
      categoryOfVariance.status === ContentObjectStatus.Closed
    const buttonsElement = hideButtons
      ? null
      : this.renderButtons(categoryOfVariance)

    return (
      <CommonThread
        showImages={true}
        hideThread={false}
        showFooterLine={true}
        messages={this.messages}
        buttonsElement={buttonsElement}
        threadIcon={
          <CategoryOfVarianceIcon categoryOfVariance={categoryOfVariance} />
        }
        threadClass={
          isCategoryOfVarianceClosed ? '' : ' category-of-variance-thread'
        }
        caption={CategoryOfVariance.getCategoryOfVarianceTypeCaption(
          categoryOfVariance.type,
        )}
        model={categoryOfVariance}
        customPhotoClick={this.displayView}
        FileInputType={FileInputType}
      />
    )
  }

  @action.bound
  private displayView = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    const { common, categoryOfVariance } = this.props
    common.displayCategoryOfVarianceView(categoryOfVariance)
  }
}

export default withRouter(CategoryOfVarianceThread)
