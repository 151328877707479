import ILocationTransferPair from '../models/ILocationTransferPair'
import LocationBase from '../models/LocationObjects/LocationBase'
import SitePermit from '../models/Permit'
import { getFixedNum } from '../utils/util'

export default class MaterialLocationMap extends Map<
  string,
  ILocationTransferPair
> {
  public addForm(form: SitePermit, quantity: number, location: LocationBase) {
    if (!location?.id) {
      return
    }

    const existingValue = this.get(location.id)
    if (!existingValue) {
      this.set(location.id, {
        location,
        quantity: getFixedNum(quantity),
        materialTransfers: [form],
      })
      return
    }

    if (!existingValue.materialTransfers.some(tr => tr.id === form.id)) {
      existingValue.materialTransfers.push(form)
    }
  }

  public increaseQuantity(location: LocationBase, quantity: number) {
    if (!location?.id) {
      return
    }

    const existingValue = this.get(location.id)
    if (existingValue) {
      existingValue.quantity = getFixedNum(
        existingValue.quantity + quantity || 0,
      )
      return
    }

    this.set(location.id, {
      location,
      quantity: getFixedNum(quantity || 0),
      materialTransfers: [],
    })
  }

  public decreaseQuantity(locationId: string, quantity: number) {
    if (!this.has(locationId)) {
      return
    }

    const existingValue = this.get(locationId)
    if (getFixedNum(quantity) >= getFixedNum(existingValue.quantity)) {
      this.delete(locationId)
      return
    }

    existingValue.quantity = getFixedNum(existingValue.quantity - quantity)
  }
}
