import * as React from 'react'

import { computed } from 'mobx'
import { observer } from 'mobx-react'
import { AutoSizer, Column, Table } from 'react-virtualized'

import Checkbox from '~/client/src/shared/components/Checkbox'
import {
  BasicDataKeys,
  ILWFCCategory,
  ILWFCColumn,
  ILWFCRow,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import TableHeaderCell from '~/client/src/shared/components/ListWithFixedColumns/components/TableHeaderCell'
import SortOrder from '~/client/src/shared/enums/SortOrder'
import { NOOP } from '~/client/src/shared/utils/noop'

import ReportsTableDataCell from './components/ReportsTableDataCell'

import './ReportsListTable.scss'

interface IProps {
  rows: ILWFCRow[]
  columns: ILWFCColumn[]
  groupingKey: string
  columnKeyToCellRenderer: {
    [columnKey: string]: (...attr: any[]) => JSX.Element
  }
  columnsWidthState?: Map<string, number>
  collapsedCategories?: Map<string, boolean>
  sortedColumnKey?: string
  sortingOrder?: SortOrder
  onColumnSort?: (columnKey: string) => void
  onCellClick?: (rowData: LWFCRowData, columnKey: string) => void
  onCategoryCheckboxToggle?: (category: ILWFCCategory) => void
  onCategoryCollapsingToggle?: (categoryId: string) => void
  selectAll?: () => void
  resetAll?: () => void
  rowHeightGetter?: ({ index }: { index: number }) => number
  selectAllTitle?: string
}

const selectAll = 'Select All'

const DEFAULT_ROW_HEIGHT = 54
const CATEGORY_ROW_HEIGHT = 32
const DEFAULT_COLUMN_WIDTH = 100

@observer
export default class ReportsListTable extends React.Component<IProps> {
  public static defaultProps = {
    onColumnSort: NOOP,
    onCellClick: NOOP,
    onCategoryCheckboxToggle: NOOP,
    onCategoryCollapsingToggle: NOOP,
    selectAll: NOOP,
    resetAll: NOOP,
    selectAllTitle: selectAll,
  }

  private listRef = null

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (
      prevProps.rows.length !== this.props.rows.length ||
      prevProps.groupingKey !== this.props.groupingKey ||
      prevProps.sortedColumnKey !== this.props.sortedColumnKey ||
      prevProps.sortingOrder !== this.props.sortingOrder
    ) {
      this.recomputeGridSize()
    }
  }

  public render() {
    const { rows, columns, rowHeightGetter } = this.props
    return (
      <div className="reports-list-table relative full-height">
        <AutoSizer disableWidth={true} className="full-height">
          {({ height }) => (
            <Table
              width={this.tableWidth}
              height={height}
              headerHeight={DEFAULT_ROW_HEIGHT}
              ref={this.setListRef}
              rowHeight={rowHeightGetter || this.getRowHeight}
              rowCount={rows.length}
              rowStyle={{ alignItems: 'stretch' }}
              rowGetter={this.rowGetter}
              rowClassName="no-outline"
            >
              {columns.map((column, index) => {
                const width = column.width || DEFAULT_COLUMN_WIDTH
                return (
                  <Column
                    width={width}
                    minWidth={width}
                    maxWidth={width}
                    key={index}
                    {...column}
                    cellRenderer={this.renderDataCell}
                    headerRenderer={headerProps =>
                      this.renderHeaderCell({
                        ...headerProps,
                        columnIndex: index,
                      })
                    }
                  />
                )
              })}
            </Table>
          )}
        </AutoSizer>
      </div>
    )
  }

  private rowGetter = ({ index }): ILWFCRow => {
    return this.props.rows[index]
  }

  private getRowHeight = ({ index }): number => {
    const row = this.props.rows[index]
    return row && row.category ? CATEGORY_ROW_HEIGHT : DEFAULT_ROW_HEIGHT
  }

  private renderDataCell = ({ columnIndex, rowIndex, style }): JSX.Element => {
    const {
      rows,
      columns,
      collapsedCategories,
      columnKeyToCellRenderer,
      onCategoryCollapsingToggle,
      onCellClick,
      onCategoryCheckboxToggle,
    } = this.props

    return (
      <ReportsTableDataCell
        style={style}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        rows={rows}
        columns={columns}
        collapsedCategories={collapsedCategories}
        onCategoryCollapsingToggle={onCategoryCollapsingToggle}
        onCellClick={onCellClick}
        onCategoryCheckboxToggle={onCategoryCheckboxToggle}
        columnKeyToCellRenderer={columnKeyToCellRenderer}
        columnKeyToDefaultRenderer={this.columnKeyToDefaultRenderer}
      />
    )
  }

  private renderHeaderCell = ({ dataKey, label, columnIndex }): JSX.Element => {
    const { onColumnSort, sortedColumnKey, sortingOrder } = this.props

    return (
      <TableHeaderCell
        dataKey={dataKey}
        label={label}
        columnIndex={columnIndex}
        toggleSelectionPopup={NOOP}
        onColumnSort={onColumnSort}
        sortedColumnKey={sortedColumnKey}
        sortingOrder={sortingOrder}
        isResizable={false}
      />
    )
  }

  private setListRef = (ref: any) => {
    this.listRef = ref
  }

  private recomputeGridSize = () => {
    this.listRef.recomputeGridSize()
  }

  private get columnKeyToDefaultRenderer(): {
    [columnKey: string]: (value: any) => JSX.Element
  } {
    return {
      [BasicDataKeys.CHECKBOX]: value => (
        <Checkbox className="center" isChecked={value} />
      ),
    }
  }

  @computed
  private get tableWidth(): number {
    return this.props.columns.reduce((sum, column) => {
      sum += column.width || DEFAULT_COLUMN_WIDTH
      return sum
    }, 0)
  }
}
