import { computed } from 'mobx'

import { IActivityCode } from '~/client/graph'
import ActivityCode from '~/client/src/shared/models/ActivityCode'
import ActivityCodeRelationshipsStore from '~/client/src/shared/stores/domain/ActivityCodeRelationships.store'

import EventsStore from '../EventStore/Events.store'
import BaseStoreWithById from '../baseStores/BaseWithById.store'

export default class ActivityCodesStore extends BaseStoreWithById<
  ActivityCode,
  IActivityCode
> {
  public constructor(
    private eventsStore: EventsStore,
    private activityCodeRelationshipsStore: ActivityCodeRelationshipsStore,
  ) {
    super(ActivityCode)
  }

  public get byId() {
    return this.eventsStore.appState.activityCodes
  }

  @computed
  public get codesToActivitiesMap(): {
    [key: string]: string[]
  } {
    const map = {}
    this.activityCodeRelationshipsStore.availableRelationships.forEach(rel => {
      if (!map[rel.activityTypeId]) {
        map[rel.activityTypeId] = []
      }
      map[rel.activityTypeId].push(rel.activityId)
    })

    return map
  }

  public getShortNamesFromIds(ids: string[]): string[] {
    return this.list
      .filter(code => ids.includes(code.id))
      .map(code => code.shortName)
  }
}
