import { enumToList } from '~/client/src/shared/utils/converters'

import Localization from '../localization/LocalizationManager'

enum ObjectFilterType {
  Company = 'Company',
}

export const objectFilterTypes = enumToList(ObjectFilterType)

export const getObjectFilterTypeDisplayName = (type: ObjectFilterType) => {
  switch (type) {
    case ObjectFilterType.Company:
      return Localization.translator.company
    default:
      throw new Error(type + ' is unhandled')
  }
}

export default ObjectFilterType
