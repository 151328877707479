import * as React from 'react'

import {
  ITooltipProps,
  PopoverInteractionKind,
  Position,
  Tooltip,
} from '@blueprintjs/core'

const DEFAULT_OPEN_DELAY = 150
const DEFAULT_CLOSE_DELAY = 75

export default class TooltipWrapper extends React.Component<ITooltipProps> {
  public static defaultProps = {
    hoverOpenDelay: DEFAULT_OPEN_DELAY,
    hoverCloseDelay: DEFAULT_CLOSE_DELAY,
    interactionKind: PopoverInteractionKind.HOVER_TARGET_ONLY,
  }

  public render() {
    const {
      children,
      hoverCloseDelay,
      hoverOpenDelay,
      content,
      className,
      interactionKind,
      modifiers,
      disabled,
    } = this.props

    return (
      <Tooltip
        content={content}
        className={className}
        hoverOpenDelay={hoverOpenDelay}
        hoverCloseDelay={hoverCloseDelay}
        position={Position.BOTTOM}
        interactionKind={interactionKind}
        disabled={disabled}
        modifiers={modifiers}
      >
        {children}
      </Tooltip>
    )
  }
}
