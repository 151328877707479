import * as React from 'react'

import { Circle, Group, Path } from 'react-konva'

import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import { GATE_PATH } from '../LocationPaths'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_SIZE = 20
const PATH_SCALE = 0.7
const SHADOW_BLUR = 2
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3

const ICON_OFFSET_X = 3
const ICON_OFFSET_Y = 3

const CIRCLE_STROKE_WIDTH = 1
const { getHEXColor } = ThemeMode

const KonvaGateIcon = ({ color, children, ...rest }) => {
  const halfSize = ICON_SIZE / 2

  return (
    <Group {...rest} offsetX={halfSize} offsetY={halfSize}>
      {children}
      <Circle
        radius={halfSize}
        x={halfSize}
        y={halfSize}
        fill={getHEXColor(color)}
        stroke={getHEXColor(Colors.neutral100)}
        strokeWidth={CIRCLE_STROKE_WIDTH}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
      <Path
        data={GATE_PATH}
        fill={getHEXColor(Colors.neutral100)}
        x={ICON_OFFSET_X}
        y={ICON_OFFSET_Y}
        scaleX={PATH_SCALE}
        scaleY={PATH_SCALE}
      />
    </Group>
  )
}

export default KonvaGateIcon
