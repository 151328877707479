import { action, computed, observable } from 'mobx'

import Delivery from '~/client/src/shared/models/Delivery'
import GlobeView from '~/client/src/shared/models/GlobeView'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import Sitemap from '~/client/src/shared/models/Sitemap'
import InitialState from '~/client/src/shared/stores/InitialState'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import DeliveriesViewStore from '../../DeliveriesView.store'

interface IMapImage {
  original: string
  thumbnail: string
  globeId?: string
  sitemapId?: string
}

export default class DeliveriesMapViewStore {
  @observable public displayedDeliveryIdx: number = 0
  @observable public isGallerySwipingDisabled: boolean = false
  @observable public selectedDeliveryId: string = null

  public constructor(
    private readonly deliveriesViewStore: DeliveriesViewStore,
    private readonly state: InitialState,
    private readonly sitemapsStore: SitemapsStore,
    private readonly globeViewsStore: GlobeViewsStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly sitemapItemsStore: SitemapItemsStore,
  ) {}

  public get currentSitemapId(): string {
    return this.state.delivery.selectedSitemapId
  }

  public set currentSitemapId(sitemapId: string) {
    this.state.delivery.selectedSitemapId = sitemapId
  }

  @action.bound
  public setSitemapId(sitemapId: string): void {
    this.currentSitemapId = sitemapId
    this.resetDisplayedDelivery()
  }

  @action.bound
  public deselectSitemap(): void {
    this.currentSitemapId = null
    this.resetDisplayedDelivery()
  }

  @action.bound
  public resetDisplayedDelivery(): void {
    this.displayedDeliveryIdx = 0
    this.resetSelectedDelivery()
  }

  @action.bound
  public setSwipingAbility(isDisabled: boolean): void {
    this.isGallerySwipingDisabled = isDisabled
  }

  @action.bound
  public openDeliveryDetails(delivery: Delivery): void {
    this.deliveriesViewStore.openDeliveryDetails(delivery)
  }

  public get displayedMap(): Sitemap | GlobeView {
    const sitemapId = this.getSitemapIdByIndex(this.currentSitemapIndex)
    return (
      this.sitemapsStore.byId.get(sitemapId) ||
      this.globeViewsStore.byId.get(sitemapId)
    )
  }

  public get mapImages(): IMapImage[] {
    const { deliveriesSitemaps, deliveriesGlobeViews } =
      this.deliveriesViewStore

    const sitemaps = deliveriesSitemaps.map(s => ({
      original: s.filledImage,
      thumbnail: s.filledImage,
      sitemapId: s.id,
    }))
    const globes = deliveriesGlobeViews.map(s => ({
      original: s.filledImage,
      thumbnail: s.filledImage,
      globeId: s.id,
    }))

    return [...globes, ...sitemaps]
  }

  @computed
  public get attributesBySitemapItems(): LocationBase[] {
    if (!this.displayedMap) {
      return []
    }

    const { items } = this.displayedMap
    const { allAttributes } = this.locationAttributesStore

    const attributesBySitemap: LocationBase[] = []

    const sitemapItems = Object.values(items).filter(item => !item.isHidden)
    sitemapItems.forEach(itemData => {
      const item = this.sitemapItemsStore.byId.get(itemData.sitemapItemId)

      if (!item) {
        return
      }

      const attribute = allAttributes.find(a => item.isAssignedTo(a))

      if (attribute) {
        attributesBySitemap.push(attribute)
      }
    })

    return attributesBySitemap
  }

  @computed
  public get attributesBySelectedSitemap(): LocationBase[] {
    if (!this.displayedMap) {
      return []
    }

    return this.locationAttributesStore.allAttributes.filter(a =>
      a.isSitemapAssigned(this.displayedMap.id),
    )
  }

  @computed
  public get displayedSitemapDeliveries(): Delivery[] {
    if (!this.displayedMap) {
      return []
    }

    const { id } = this.displayedMap
    const { maps } = this.state.delivery.configurations

    if (maps.some(m => m.sitemapId === id || m.globeViewId === id)) {
      return this.deliveriesBySitemapItems
    }

    return this.deliveriesByLowestChildrenTags
  }

  @computed
  public get availableDeliveries(): Delivery[] {
    return this.deliveriesViewStore.currentViewDeliveries.sort(
      this.sortDeliveriesFunc,
    )
  }

  @computed
  public get deliveriesBySitemapItems(): Delivery[] {
    return this.deliveriesViewStore.currentViewDeliveries
      .filter(
        d =>
          this.attributesBySitemapItems.length &&
          this.attributesBySitemapItems.some(a => d.isRelatedAttr(a.id)),
      )
      .sort(this.sortDeliveriesFunc)
  }

  @computed
  public get deliveriesByLowestChildrenTags(): Delivery[] {
    const lowestChildrenAttributes = this.attributesBySelectedSitemap.filter(
      a => !this.attributesBySelectedSitemap.some(at => at.isParent(a)),
    )

    return this.deliveriesViewStore.currentViewDeliveries
      .filter(
        d =>
          lowestChildrenAttributes.length &&
          lowestChildrenAttributes.some(a => d.isRelatedAttr(a.id)),
      )
      .sort(this.sortDeliveriesFunc)
  }

  public showPreviousDelivery = (): void => {
    if (this.displayedDeliveryIdx) {
      this.displayedDeliveryIdx--
      this.setSelectedDeliveryId(
        this.displayedSitemapDeliveries?.[this.displayedDeliveryIdx]?.id,
      )
    }
  }

  public showNextDelivery = (): void => {
    if (
      this.displayedDeliveryIdx + 1 <
      this.displayedSitemapDeliveries.length
    ) {
      this.displayedDeliveryIdx++
      this.setSelectedDeliveryId(
        this.displayedSitemapDeliveries?.[this.displayedDeliveryIdx]?.id,
      )
    }
  }

  @action.bound
  public setDisplayedDeliveryIdx(index: number): void {
    this.displayedDeliveryIdx = index < 0 ? 0 : index
  }

  @action.bound
  public setSelectedDeliveryId(id: string): void {
    this.selectedDeliveryId = id
  }

  @action.bound
  public resetSelectedDelivery(): void {
    this.selectedDeliveryId = null
  }

  public get currentSitemapIndex(): number {
    const index = this.getSitemapIndexById(this.currentSitemapId)
    return index !== -1 ? index : 0
  }

  private getSitemapIdByIndex(index: number): string {
    const image = this.mapImages[index]

    if (image?.globeId) {
      return image.globeId
    }
    return image?.sitemapId
  }

  private getSitemapIndexById(sitemapId: string): number {
    return this.mapImages.findIndex(
      s => s.sitemapId === sitemapId || s.globeId === sitemapId,
    )
  }

  @action.bound
  private getDeliveryAttributeById(id: string): LocationBase {
    return this.locationAttributesStore.getById(id)
  }

  private sortDeliveriesFunc = (a: Delivery, b: Delivery): number => {
    const aBuilding = this.getDeliveryAttributeById(a.building)
    const bBuilding = this.getDeliveryAttributeById(b.building)
    const aBuildingName = (aBuilding && aBuilding.name) || EMPTY_STRING
    const bBuildingName = (bBuilding && bBuilding.name) || EMPTY_STRING

    return aBuildingName.localeCompare(bBuildingName)
  }
}
