import { action, observable } from 'mobx'

import BaseModel from '~/client/src/shared/models/BaseModel'
import Guard from '~/client/src/shared/utils/Guard'

export default abstract class BaseStore<
  T extends BaseModel<TDto>,
  TDto extends { id?: string },
> {
  @observable public isDataReceived = false

  public abstract get list(): T[]

  public constructor(protected Model: new (...args: any[]) => T) {
    Guard.requireAll({
      Model,
    })
  }

  @action
  public clearList() {
    this.internalClearList()
  }

  public updateOne(id: string, dto: TDto, isFreshList?: boolean): T {
    let localInstance = !isFreshList && this.list.find(m => m.id === id)

    if (!localInstance) {
      localInstance = this.createAnInstance(id, dto)
      this.list.push(localInstance)
    } else {
      localInstance.updateFromJson(dto)
    }

    return localInstance
  }

  @action.bound
  public receiveJson = (
    json: { [id: string]: TDto },
    isFreshList?: boolean,
  ) => {
    if (!json) {
      this.isDataReceived = true
      return
    }

    Object.keys(json).forEach(key => {
      const value = json[key]
      this.updateOne(key, value, isFreshList)
    })

    this.isDataReceived = true
  }

  @action.bound
  public receiveList(list: TDto[] = [], isFreshList?: boolean) {
    this.clearList()

    list?.forEach(value => this.updateOne(value.id, value, isFreshList))

    this.isDataReceived = true
  }

  @action
  public clearStoreAndReceiveJson(json: { [id: string]: TDto }) {
    this.internalClearStoreAndReceiveJson(json)
  }

  protected createAnInstance(id: string, dto: any) {
    const model = new this.Model(id)
    model.updateFromJson(dto)
    return model
  }

  protected internalClearStoreAndReceiveJson(json: { [id: string]: TDto }) {
    this.clearList()
    this.receiveJson(json, true)
  }

  protected internalClearList() {
    this.isDataReceived = false
    this.list.length = 0
  }
}
