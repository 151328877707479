import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ISitemapItemFieldsFragment = Pick<
  Types.ISitemapItem,
  | 'id'
  | 'name'
  | 'color'
  | 'type'
  | 'isReferenced'
  | 'iconName'
  | 'projectId'
  | 'assignedTo'
  | 'assignedId'
  | 'createdAt'
  | 'updatedAt'
> & {
  coordinates?: Types.Maybe<
    Pick<Types.IGeoJson2DGeographicCoordinates, 'latitude' | 'longitude'>
  >
  shapeCoordinates?: Types.Maybe<
    Pick<
      Types.ISitemapItemShapeCoordinates,
      'type' | 'isClosed' | 'divisionEndAngle' | 'divisionStartAngle'
    > & {
      coordinates?: Types.Maybe<
        Array<
          Types.Maybe<
            Pick<
              Types.IGeoJson2DGeographicCoordinates,
              'latitude' | 'longitude'
            >
          >
        >
      >
    }
  >
  parent?: Types.Maybe<
    Pick<Types.IDeliveryHierarchyParent, 'parentId' | 'parentType'>
  >
}

export type ISitemapItemsByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type ISitemapItemsByProjectIdQuery = {
  sitemapItems?: Types.Maybe<{ data: Array<ISitemapItemFieldsFragment> }>
}

export type IListenSitemapItemsByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenSitemapItemsByProjectIdSubscription = {
  sitemapItem?: Types.Maybe<
    Pick<Types.ISitemapItemChangeEvent, 'id'> & {
      item?: Types.Maybe<ISitemapItemFieldsFragment>
    }
  >
}

export type ISaveSitemapItemMutationVariables = Types.Exact<{
  sitemapItem: Types.ISitemapItemInput
}>

export type ISaveSitemapItemMutation = {
  saveSitemapItem?: Types.Maybe<Pick<Types.ISitemapItem, 'id'>>
}

export type IDeleteManySitemapItemsMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteManySitemapItemsMutation = Pick<
  Types.IMutation,
  'deleteManySitemapItems'
>

export const SitemapItemFieldsFragmentDoc = gql`
  fragment SitemapItemFields on SitemapItem {
    id
    name
    color
    coordinates {
      latitude
      longitude
    }
    type
    shapeCoordinates {
      type
      isClosed
      coordinates {
        latitude
        longitude
      }
      divisionEndAngle
      divisionStartAngle
    }
    isReferenced
    iconName
    projectId
    assignedTo
    assignedId
    parent {
      parentId
      parentType
    }
    createdAt
    updatedAt
  }
`
export const SitemapItemsByProjectIdDocument = gql`
  query SitemapItemsByProjectId($projectId: ObjectId!) {
    sitemapItems(projectId: $projectId, limit: 1000000) {
      data {
        ...SitemapItemFields
      }
    }
  }
  ${SitemapItemFieldsFragmentDoc}
`

/**
 * __useSitemapItemsByProjectIdQuery__
 *
 * To run a query within a React component, call `useSitemapItemsByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitemapItemsByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitemapItemsByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSitemapItemsByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ISitemapItemsByProjectIdQuery,
    ISitemapItemsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ISitemapItemsByProjectIdQuery,
    ISitemapItemsByProjectIdQueryVariables
  >(SitemapItemsByProjectIdDocument, options)
}
export function useSitemapItemsByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ISitemapItemsByProjectIdQuery,
    ISitemapItemsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ISitemapItemsByProjectIdQuery,
    ISitemapItemsByProjectIdQueryVariables
  >(SitemapItemsByProjectIdDocument, options)
}
export type SitemapItemsByProjectIdQueryHookResult = ReturnType<
  typeof useSitemapItemsByProjectIdQuery
>
export type SitemapItemsByProjectIdLazyQueryHookResult = ReturnType<
  typeof useSitemapItemsByProjectIdLazyQuery
>
export type SitemapItemsByProjectIdQueryResult = Apollo.QueryResult<
  ISitemapItemsByProjectIdQuery,
  ISitemapItemsByProjectIdQueryVariables
>
export const ListenSitemapItemsByProjectIdDocument = gql`
  subscription ListenSitemapItemsByProjectId($projectId: ObjectId!) {
    sitemapItem(projectId: $projectId) {
      id
      item {
        ...SitemapItemFields
      }
    }
  }
  ${SitemapItemFieldsFragmentDoc}
`

/**
 * __useListenSitemapItemsByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenSitemapItemsByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenSitemapItemsByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenSitemapItemsByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenSitemapItemsByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenSitemapItemsByProjectIdSubscription,
    IListenSitemapItemsByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenSitemapItemsByProjectIdSubscription,
    IListenSitemapItemsByProjectIdSubscriptionVariables
  >(ListenSitemapItemsByProjectIdDocument, options)
}
export type ListenSitemapItemsByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenSitemapItemsByProjectIdSubscription
>
export type ListenSitemapItemsByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenSitemapItemsByProjectIdSubscription>
export const SaveSitemapItemDocument = gql`
  mutation SaveSitemapItem($sitemapItem: SitemapItemInput!) {
    saveSitemapItem(sitemapItem: $sitemapItem) {
      id
    }
  }
`
export type ISaveSitemapItemMutationFn = Apollo.MutationFunction<
  ISaveSitemapItemMutation,
  ISaveSitemapItemMutationVariables
>

/**
 * __useSaveSitemapItemMutation__
 *
 * To run a mutation, you first call `useSaveSitemapItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSitemapItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSitemapItemMutation, { data, loading, error }] = useSaveSitemapItemMutation({
 *   variables: {
 *      sitemapItem: // value for 'sitemapItem'
 *   },
 * });
 */
export function useSaveSitemapItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveSitemapItemMutation,
    ISaveSitemapItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveSitemapItemMutation,
    ISaveSitemapItemMutationVariables
  >(SaveSitemapItemDocument, options)
}
export type SaveSitemapItemMutationHookResult = ReturnType<
  typeof useSaveSitemapItemMutation
>
export type SaveSitemapItemMutationResult =
  Apollo.MutationResult<ISaveSitemapItemMutation>
export type SaveSitemapItemMutationOptions = Apollo.BaseMutationOptions<
  ISaveSitemapItemMutation,
  ISaveSitemapItemMutationVariables
>
export const DeleteManySitemapItemsDocument = gql`
  mutation DeleteManySitemapItems($ids: [ObjectId]!) {
    deleteManySitemapItems(ids: $ids)
  }
`
export type IDeleteManySitemapItemsMutationFn = Apollo.MutationFunction<
  IDeleteManySitemapItemsMutation,
  IDeleteManySitemapItemsMutationVariables
>

/**
 * __useDeleteManySitemapItemsMutation__
 *
 * To run a mutation, you first call `useDeleteManySitemapItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManySitemapItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManySitemapItemsMutation, { data, loading, error }] = useDeleteManySitemapItemsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteManySitemapItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteManySitemapItemsMutation,
    IDeleteManySitemapItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteManySitemapItemsMutation,
    IDeleteManySitemapItemsMutationVariables
  >(DeleteManySitemapItemsDocument, options)
}
export type DeleteManySitemapItemsMutationHookResult = ReturnType<
  typeof useDeleteManySitemapItemsMutation
>
export type DeleteManySitemapItemsMutationResult =
  Apollo.MutationResult<IDeleteManySitemapItemsMutation>
export type DeleteManySitemapItemsMutationOptions = Apollo.BaseMutationOptions<
  IDeleteManySitemapItemsMutation,
  IDeleteManySitemapItemsMutationVariables
>
