import React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { PermitFieldType } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import * as TagIcon from '~/client/src/shared/components/TagIcon'

import './PermitFieldIcon.scss'

const classNameIcon = 'row permit-field-icon brada4 pa4 no-grow mr15'
const workflowIconClassName = classNameIcon + ' bg-secondary-80'
const ICON_SIZE = 24

const permitFieldToIconMap = {
  [PermitFieldType.StartFinishDates]: (
    <Icon icon={IconNames.CALENDAR} className={workflowIconClassName} />
  ),

  [PermitFieldType.Company]: <Icons.Team className={workflowIconClassName} />,
  [PermitFieldType.Requester]: (
    <Icons.StarHalf className={workflowIconClassName} />
  ),
  [PermitFieldType.Assignee]: (
    <Icons.FilledStar className={workflowIconClassName} />
  ),
  [PermitFieldType.Subscriber]: (
    <Icons.UnfilledStar className={workflowIconClassName + ' unfilled'} />
  ),
  [PermitFieldType.User]: (
    <Icon icon={IconNames.USER} className={classNameIcon} />
  ),

  [PermitFieldType.Location]: (
    <Icons.Location className={workflowIconClassName} />
  ),
  [PermitFieldType.Equipment]: (
    <TagIcon.Equipment className={workflowIconClassName} size={ICON_SIZE} />
  ),
  [PermitFieldType.RequestToCloseLocations]: (
    <Icon icon={IconNames.DISABLE} className={workflowIconClassName} />
  ),

  [PermitFieldType.File]: (
    <Icon icon={IconNames.DOCUMENT} className={classNameIcon} />
  ),

  [PermitFieldType.Material]: (
    <Icon icon={IconNames.CUBE} className={workflowIconClassName} />
  ),

  [PermitFieldType.InputText]: (
    <Icon icon={IconNames.TEXT_HIGHLIGHT} className={classNameIcon} />
  ),
  [PermitFieldType.InputTextArea]: (
    <Icon icon={IconNames.MANUALLY_ENTERED_DATA} className={classNameIcon} />
  ),
  [PermitFieldType.InputNumber]: (
    <Icon icon={IconNames.NUMERICAL} className={classNameIcon} />
  ),
  [PermitFieldType.AdditionalDate]: (
    <Icons.CalendarGrey className={classNameIcon} />
  ),
  [PermitFieldType.Time]: (
    <Icon icon={IconNames.TIME} className={classNameIcon} />
  ),
  [PermitFieldType.Select]: (
    <Icon icon={IconNames.MULTI_SELECT} className={classNameIcon} />
  ),
  [PermitFieldType.Measure]: <Icons.Measure className={classNameIcon} />,
  [PermitFieldType.Email]: (
    <Icon icon={IconNames.ENVELOPE} className={classNameIcon} />
  ),
  [PermitFieldType.PhoneNumber]: (
    <Icon icon={IconNames.MOBILE_PHONE} className={classNameIcon} />
  ),
  [PermitFieldType.Instructions]: (
    <Icon icon={IconNames.PROPERTIES} className={classNameIcon} />
  ),
  [PermitFieldType.Checklist]: (
    <Icon icon={IconNames.FORM} className={classNameIcon} />
  ),
  [PermitFieldType.Question]: (
    <Icon icon={IconNames.HELP} className={classNameIcon} />
  ),
  [PermitFieldType.Divider]: (
    <Icon icon={IconNames.MINUS} className={classNameIcon} />
  ),
  [PermitFieldType.Section]: (
    <Icon icon={IconNames.HEADER_ONE} className={classNameIcon} />
  ),
  [PermitFieldType.Subsection]: (
    <Icon icon={IconNames.HEADER_TWO} className={classNameIcon} />
  ),
  [PermitFieldType.Text]: (
    <Icon icon={IconNames.BOLD} className={classNameIcon} />
  ),
  [PermitFieldType.Table]: (
    <Icon icon={IconNames.PANEL_TABLE} className={classNameIcon} />
  ),
  [PermitFieldType.LocationInformational]: (
    <Icons.Location className={classNameIcon} />
  ),
}

export default function PermitFieldIcon({
  fieldName,
}: {
  fieldName: string
}): JSX.Element {
  return permitFieldToIconMap[fieldName]
}
