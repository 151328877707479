import { action, computed, observable } from 'mobx'

import { FilterType } from '~/client/graph'
import MobXMap from '~/client/src/shared/types/MobXMap'

import { ActivitiesTreeNodeTypes } from '../../enums/ActivitiesTreeNodeTypes'
import {
  UNASSIGNED,
  UNASSIGNED_FILTER_VALUE,
} from '../../utils/ZoneLevelLocationConstants'
import EventContext from '../EventStore/EventContext'
import EventsStore from '../EventStore/Events.store'
import { RESET_INPUT_STATUSES } from '../EventStore/eventConstants'
import ActivityFiltersStore from '../domain/ActivityFilters.store'
import CompaniesStore from '../domain/Companies.store'
import LocationsStore from '../domain/Locations.store'
import { ICodesFilter, ITreeContainer } from './BaseActivityList.store'
import ProjectDateStore from './ProjectDate.store'

const { ZONE_CONTAINER, COMPANY_CONTAINER, LEVEL_CONTAINER } =
  ActivitiesTreeNodeTypes

export default abstract class CompactActivityListStore {
  @observable public expandType: ActivitiesTreeNodeTypes = null
  @observable public shouldCollapseAllNodes = null
  public defaultStateMap = {
    [LEVEL_CONTAINER]: false,
    [ZONE_CONTAINER]: false,
    [COMPANY_CONTAINER]: false,
  }
  public requestNodesState = new MobXMap<boolean>()
  public showMoreControlsState = new MobXMap<number>()
  public nodesState = new MobXMap<boolean>()
  // This flag should be overridden in child stores to enable loading next/completed activities by API
  protected areActivitiesLoadedByApi: boolean = false

  public constructor(
    protected readonly activityFiltersStore: ActivityFiltersStore,
    protected readonly eventsStore: EventsStore,
    protected readonly locationsStore: LocationsStore,
    protected readonly companiesStore: CompaniesStore,
    protected readonly projectDateStore: ProjectDateStore,
  ) {}

  public onResetRequest = (eventContext: EventContext) => {
    const [eventType] = eventContext.event
    if (eventType === RESET_INPUT_STATUSES) {
      this.resetTreeStates()
    }
  }

  public getContainersByType = (type): ITreeContainer[] => {
    switch (type) {
      case FilterType.Level:
        const { realLevels } = this.locationsStore
        return realLevels.map(level => ({
          name: level.levelName,
          type,
          codeIds: level.codeIds,
        }))
      case FilterType.Zone:
        const { realZones } = this.locationsStore
        return realZones.map(zone => ({
          name: zone.zoneName,
          type,
          codeIds: zone.codeIds,
        }))
      case FilterType.Company:
        return this.treeCompanies.map(company => ({
          name: company.name,
          type,
          companyId: company.id,
        }))
      default:
        const codes = this.activityFiltersStore.getCodesByFilterType(type)
        return [
          ...codes.map(code => ({
            name: code.name,
            type,
            codeIds: [code.id],
          })),
          {
            name: UNASSIGNED,
            type,
            codeIds: [UNASSIGNED_FILTER_VALUE],
          },
        ]
    }
  }

  public setExpandType = (
    expandType: ActivitiesTreeNodeTypes,
    collapseAll?: boolean,
  ) => {
    this.expandType = expandType
    if (collapseAll !== undefined) {
      this.shouldCollapseAllNodes = collapseAll
    }
  }

  @computed
  private get treeCompanies(): any[] {
    return [
      ...this.companiesStore.availableSortedCompanies,
      { name: UNASSIGNED, id: null },
    ]
  }

  public isNodeExpanded = (
    nodeId: string,
    nodeType: ActivitiesTreeNodeTypes,
  ) => {
    switch (this.expandType) {
      case LEVEL_CONTAINER:
        return nodeType === LEVEL_CONTAINER
      case ZONE_CONTAINER:
        return nodeType === LEVEL_CONTAINER || nodeType === ZONE_CONTAINER
      case COMPANY_CONTAINER:
        return (
          nodeType === LEVEL_CONTAINER ||
          nodeType === ZONE_CONTAINER ||
          nodeType === COMPANY_CONTAINER
        )
      default:
        break
    }

    const forceMode = this.shouldCollapseAllNodes !== null
    if (forceMode) {
      return !this.shouldCollapseAllNodes
    }

    const userInteraction = this.nodesState.get(nodeId)
    return userInteraction !== undefined
      ? userInteraction
      : this.defaultStateMap[nodeType]
  }

  @action.bound
  public resetForceCollapse() {
    this.shouldCollapseAllNodes = null
    this.expandType = null
  }

  protected getCurrentActivityFilters(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parents: ITreeContainer[],
  ): ICodesFilter {
    return {}
  }

  @action.bound
  private resetTreeStates() {
    this.resetForceCollapse()
    this.showMoreControlsState.clear()
    this.nodesState.clear()
    this.requestNodesState.clear()
  }
}
