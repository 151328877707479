import { TagIconType } from '~/client/src/shared/enums/TagIcon'
import { TagType } from '~/client/src/shared/enums/TagType'
import BaseTagsStore from '~/client/src/shared/stores/BaseTags.store'

import { ITag } from '../../models/Tag'

export default class ProjectDefaultTeamsStore extends BaseTagsStore {
  protected readonly iconName: TagIconType = TagIconType.DefaultTeam
  protected readonly tagType: TagType = TagType.DefaultTeam

  public get byId(): Map<string, ITag> {
    return this.eventsStore.appState.defaultProjectTeams
  }
}
