import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'

import PdfDocument from '~/client/src/shared/components/PdfDocument/PdfDocument'

import PdfPageSelectorDialog from './dialogs/PdfPageSelectorDialog'

const IMAGE_WIDTH = 1920
const IMAGE_HEIGHT = 1080

interface IProps {
  pdfFile: File
  uploadImage: (img: Blob) => Promise<void>
}

@observer
export default class PdfToImageConverter extends React.Component<IProps> {
  @observable private isDocumentLoaded: boolean = false
  @observable private isPageSelectorDialogShown: boolean = false
  @observable private pageNumber: number = 1

  private pdfInput: HTMLDivElement

  public render() {
    const { pdfFile } = this.props

    return (
      <>
        <PdfDocument
          file={pdfFile}
          className="hidden"
          pageNumber={this.pageNumber}
          pageWidth={IMAGE_WIDTH}
          pageHeight={IMAGE_HEIGHT}
          isPageHidden={this.isPageSelectorDialogShown}
          setPageInputRef={this.setInputRef}
          onPageRenderSuccess={this.convertPdfToImage}
          onLoadSuccess={this.onDocumentLoadSuccess}
        />
        {this.pdfPageSelector}
      </>
    )
  }

  private get pdfPageSelector(): JSX.Element {
    if (!this.isDocumentLoaded || !this.isPageSelectorDialogShown) {
      return null
    }

    return (
      <PdfPageSelectorDialog
        pdfFile={this.props.pdfFile}
        onDialogSubmit={this.onDialogSubmit}
      />
    )
  }

  @action.bound
  private onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    if (numPages > 1) {
      this.showPageSelectorDialog()
    }

    this.isDocumentLoaded = true
  }

  @action.bound
  private onDialogSubmit(pageNumberToConvert: number) {
    this.pageNumber = pageNumberToConvert
    this.hidePageSelectorDialog()
  }

  @action.bound
  private showPageSelectorDialog() {
    this.isPageSelectorDialogShown = true
  }

  @action.bound
  private hidePageSelectorDialog() {
    this.isPageSelectorDialogShown = false
  }

  private convertPdfToImage = async () => {
    if (!this.pdfInput) {
      return
    }

    const imgFile = await new Promise<Blob>(resolve =>
      (this.pdfInput.children[0] as HTMLCanvasElement).toBlob(blob =>
        resolve(blob),
      ),
    )

    await this.props.uploadImage(imgFile)
  }

  private setInputRef = (ref: HTMLDivElement) => {
    this.pdfInput = ref
  }
}
