import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IMaterialCategoryFieldsFragment = Pick<
  Types.IProjectMaterialCategory,
  | 'id'
  | 'name'
  | 'createdAt'
  | 'updatedAt'
  | 'projectId'
  | 'description'
  | 'plannedQuantity'
  | 'materialsUploadId'
> & {
  fieldDefinitions?: Types.Maybe<
    Array<
      Types.Maybe<Pick<Types.IMaterialCategoryFieldDefinition, 'name' | 'type'>>
    >
  >
  metadata?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IStringPair, 'key' | 'value'>>>
  >
  procurementDataList?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<
          Types.IMaterialProcurementData,
          | 'procurementId'
          | 'installLocationId'
          | 'deliveryLocationId'
          | 'description'
          | 'plannedQuantity'
        >
      >
    >
  >
}

export type IMaterialFieldsFragment = Pick<
  Types.IProjectMaterial,
  | 'id'
  | 'categoryId'
  | 'productName'
  | 'createdAt'
  | 'updatedAt'
  | 'projectId'
  | 'description'
  | 'plannedQuantity'
  | 'materialsUploadId'
> & {
  metadata?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IStringPair, 'key' | 'value'>>>
  >
  procurementDataList?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<
          Types.IMaterialProcurementData,
          | 'procurementId'
          | 'installLocationId'
          | 'deliveryLocationId'
          | 'description'
          | 'plannedQuantity'
        >
      >
    >
  >
}

export type IGetMaterialCategoryListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  materialsUploadId?: Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IGetMaterialCategoryListQuery = {
  projectMaterialCategories?: Types.Maybe<{
    data: Array<IMaterialCategoryFieldsFragment>
  }>
}

export type IGetMaterialListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  materialsUploadId?: Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IGetMaterialListQuery = {
  projectMaterials?: Types.Maybe<{ data: Array<IMaterialFieldsFragment> }>
}

export type IGetMaterialsExcelSheetsQueryVariables = Types.Exact<{
  fullFilePath: Types.Scalars['String']
}>

export type IGetMaterialsExcelSheetsQuery = {
  getMaterialsExcelSheets?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.ISheetData, 'name' | 'columns'>>>
  >
}

export type IListenToMaterialCategorySubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  materialsUploadId?: Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IListenToMaterialCategorySubscription = {
  projectMaterialCategory?: Types.Maybe<
    Pick<Types.IProjectMaterialCategoryChangeEvent, 'id'> & {
      item?: Types.Maybe<IMaterialCategoryFieldsFragment>
    }
  >
}

export type IListenToMaterialSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  materialsUploadId?: Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IListenToMaterialSubscription = {
  projectMaterial?: Types.Maybe<
    Pick<Types.IProjectMaterialChangeEvent, 'id'> & {
      item?: Types.Maybe<IMaterialFieldsFragment>
    }
  >
}

export type ISaveMaterialMutationVariables = Types.Exact<{
  projectMaterial: Types.IProjectMaterialInput
}>

export type ISaveMaterialMutation = {
  saveProjectMaterial?: Types.Maybe<IMaterialFieldsFragment>
}

export type IFillProjectWithDefaultMaterialsMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IFillProjectWithDefaultMaterialsMutation = Pick<
  Types.IMutation,
  'fillupProjectMaterialsData'
>

export type IUploadMaterialsExcelMutationVariables = Types.Exact<{
  data: Types.IUploadMaterialsExcelInput
}>

export type IUploadMaterialsExcelMutation = Pick<
  Types.IMutation,
  'uploadMaterialsExcel'
>

export type IRestoreMaterialsMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  materialsUploadId?: Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IRestoreMaterialsMutation = Pick<
  Types.IMutation,
  'restoreMaterials'
>

export const MaterialCategoryFieldsFragmentDoc = gql`
  fragment MaterialCategoryFields on ProjectMaterialCategory {
    id
    name
    fieldDefinitions {
      name
      type
    }
    createdAt
    updatedAt
    metadata {
      key
      value
    }
    projectId
    description
    plannedQuantity
    materialsUploadId
    procurementDataList {
      procurementId
      installLocationId
      deliveryLocationId
      description
      plannedQuantity
    }
  }
`
export const MaterialFieldsFragmentDoc = gql`
  fragment MaterialFields on ProjectMaterial {
    id
    categoryId
    productName
    createdAt
    updatedAt
    metadata {
      key
      value
    }
    projectId
    description
    plannedQuantity
    materialsUploadId
    procurementDataList {
      procurementId
      installLocationId
      deliveryLocationId
      description
      plannedQuantity
    }
  }
`
export const GetMaterialCategoryListDocument = gql`
  query GetMaterialCategoryList(
    $projectId: ObjectId!
    $materialsUploadId: ObjectId
  ) {
    projectMaterialCategories(
      limit: 1000000
      projectId: $projectId
      materialsUploadId: $materialsUploadId
    ) {
      data {
        ...MaterialCategoryFields
      }
    }
  }
  ${MaterialCategoryFieldsFragmentDoc}
`

/**
 * __useGetMaterialCategoryListQuery__
 *
 * To run a query within a React component, call `useGetMaterialCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaterialCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaterialCategoryListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      materialsUploadId: // value for 'materialsUploadId'
 *   },
 * });
 */
export function useGetMaterialCategoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetMaterialCategoryListQuery,
    IGetMaterialCategoryListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetMaterialCategoryListQuery,
    IGetMaterialCategoryListQueryVariables
  >(GetMaterialCategoryListDocument, options)
}
export function useGetMaterialCategoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetMaterialCategoryListQuery,
    IGetMaterialCategoryListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetMaterialCategoryListQuery,
    IGetMaterialCategoryListQueryVariables
  >(GetMaterialCategoryListDocument, options)
}
export type GetMaterialCategoryListQueryHookResult = ReturnType<
  typeof useGetMaterialCategoryListQuery
>
export type GetMaterialCategoryListLazyQueryHookResult = ReturnType<
  typeof useGetMaterialCategoryListLazyQuery
>
export type GetMaterialCategoryListQueryResult = Apollo.QueryResult<
  IGetMaterialCategoryListQuery,
  IGetMaterialCategoryListQueryVariables
>
export const GetMaterialListDocument = gql`
  query GetMaterialList($projectId: ObjectId!, $materialsUploadId: ObjectId) {
    projectMaterials(
      limit: 1000000
      projectId: $projectId
      materialsUploadId: $materialsUploadId
    ) {
      data {
        ...MaterialFields
      }
    }
  }
  ${MaterialFieldsFragmentDoc}
`

/**
 * __useGetMaterialListQuery__
 *
 * To run a query within a React component, call `useGetMaterialListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaterialListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaterialListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      materialsUploadId: // value for 'materialsUploadId'
 *   },
 * });
 */
export function useGetMaterialListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetMaterialListQuery,
    IGetMaterialListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetMaterialListQuery, IGetMaterialListQueryVariables>(
    GetMaterialListDocument,
    options,
  )
}
export function useGetMaterialListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetMaterialListQuery,
    IGetMaterialListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetMaterialListQuery,
    IGetMaterialListQueryVariables
  >(GetMaterialListDocument, options)
}
export type GetMaterialListQueryHookResult = ReturnType<
  typeof useGetMaterialListQuery
>
export type GetMaterialListLazyQueryHookResult = ReturnType<
  typeof useGetMaterialListLazyQuery
>
export type GetMaterialListQueryResult = Apollo.QueryResult<
  IGetMaterialListQuery,
  IGetMaterialListQueryVariables
>
export const GetMaterialsExcelSheetsDocument = gql`
  query GetMaterialsExcelSheets($fullFilePath: String!) {
    getMaterialsExcelSheets(fullFilePath: $fullFilePath) {
      name
      columns
    }
  }
`

/**
 * __useGetMaterialsExcelSheetsQuery__
 *
 * To run a query within a React component, call `useGetMaterialsExcelSheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaterialsExcelSheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaterialsExcelSheetsQuery({
 *   variables: {
 *      fullFilePath: // value for 'fullFilePath'
 *   },
 * });
 */
export function useGetMaterialsExcelSheetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetMaterialsExcelSheetsQuery,
    IGetMaterialsExcelSheetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetMaterialsExcelSheetsQuery,
    IGetMaterialsExcelSheetsQueryVariables
  >(GetMaterialsExcelSheetsDocument, options)
}
export function useGetMaterialsExcelSheetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetMaterialsExcelSheetsQuery,
    IGetMaterialsExcelSheetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetMaterialsExcelSheetsQuery,
    IGetMaterialsExcelSheetsQueryVariables
  >(GetMaterialsExcelSheetsDocument, options)
}
export type GetMaterialsExcelSheetsQueryHookResult = ReturnType<
  typeof useGetMaterialsExcelSheetsQuery
>
export type GetMaterialsExcelSheetsLazyQueryHookResult = ReturnType<
  typeof useGetMaterialsExcelSheetsLazyQuery
>
export type GetMaterialsExcelSheetsQueryResult = Apollo.QueryResult<
  IGetMaterialsExcelSheetsQuery,
  IGetMaterialsExcelSheetsQueryVariables
>
export const ListenToMaterialCategoryDocument = gql`
  subscription ListenToMaterialCategory(
    $projectId: ObjectId!
    $materialsUploadId: ObjectId
  ) {
    projectMaterialCategory(
      projectId: $projectId
      materialsUploadId: $materialsUploadId
    ) {
      id
      item {
        ...MaterialCategoryFields
      }
    }
  }
  ${MaterialCategoryFieldsFragmentDoc}
`

/**
 * __useListenToMaterialCategorySubscription__
 *
 * To run a query within a React component, call `useListenToMaterialCategorySubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToMaterialCategorySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToMaterialCategorySubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      materialsUploadId: // value for 'materialsUploadId'
 *   },
 * });
 */
export function useListenToMaterialCategorySubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToMaterialCategorySubscription,
    IListenToMaterialCategorySubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToMaterialCategorySubscription,
    IListenToMaterialCategorySubscriptionVariables
  >(ListenToMaterialCategoryDocument, options)
}
export type ListenToMaterialCategorySubscriptionHookResult = ReturnType<
  typeof useListenToMaterialCategorySubscription
>
export type ListenToMaterialCategorySubscriptionResult =
  Apollo.SubscriptionResult<IListenToMaterialCategorySubscription>
export const ListenToMaterialDocument = gql`
  subscription ListenToMaterial(
    $projectId: ObjectId!
    $materialsUploadId: ObjectId
  ) {
    projectMaterial(
      projectId: $projectId
      materialsUploadId: $materialsUploadId
    ) {
      id
      item {
        ...MaterialFields
      }
    }
  }
  ${MaterialFieldsFragmentDoc}
`

/**
 * __useListenToMaterialSubscription__
 *
 * To run a query within a React component, call `useListenToMaterialSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToMaterialSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToMaterialSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      materialsUploadId: // value for 'materialsUploadId'
 *   },
 * });
 */
export function useListenToMaterialSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToMaterialSubscription,
    IListenToMaterialSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToMaterialSubscription,
    IListenToMaterialSubscriptionVariables
  >(ListenToMaterialDocument, options)
}
export type ListenToMaterialSubscriptionHookResult = ReturnType<
  typeof useListenToMaterialSubscription
>
export type ListenToMaterialSubscriptionResult =
  Apollo.SubscriptionResult<IListenToMaterialSubscription>
export const SaveMaterialDocument = gql`
  mutation SaveMaterial($projectMaterial: ProjectMaterialInput!) {
    saveProjectMaterial(projectMaterial: $projectMaterial) {
      ...MaterialFields
    }
  }
  ${MaterialFieldsFragmentDoc}
`
export type ISaveMaterialMutationFn = Apollo.MutationFunction<
  ISaveMaterialMutation,
  ISaveMaterialMutationVariables
>

/**
 * __useSaveMaterialMutation__
 *
 * To run a mutation, you first call `useSaveMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMaterialMutation, { data, loading, error }] = useSaveMaterialMutation({
 *   variables: {
 *      projectMaterial: // value for 'projectMaterial'
 *   },
 * });
 */
export function useSaveMaterialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveMaterialMutation,
    ISaveMaterialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveMaterialMutation,
    ISaveMaterialMutationVariables
  >(SaveMaterialDocument, options)
}
export type SaveMaterialMutationHookResult = ReturnType<
  typeof useSaveMaterialMutation
>
export type SaveMaterialMutationResult =
  Apollo.MutationResult<ISaveMaterialMutation>
export type SaveMaterialMutationOptions = Apollo.BaseMutationOptions<
  ISaveMaterialMutation,
  ISaveMaterialMutationVariables
>
export const FillProjectWithDefaultMaterialsDocument = gql`
  mutation FillProjectWithDefaultMaterials($projectId: ObjectId!) {
    fillupProjectMaterialsData(projectId: $projectId)
  }
`
export type IFillProjectWithDefaultMaterialsMutationFn =
  Apollo.MutationFunction<
    IFillProjectWithDefaultMaterialsMutation,
    IFillProjectWithDefaultMaterialsMutationVariables
  >

/**
 * __useFillProjectWithDefaultMaterialsMutation__
 *
 * To run a mutation, you first call `useFillProjectWithDefaultMaterialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFillProjectWithDefaultMaterialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fillProjectWithDefaultMaterialsMutation, { data, loading, error }] = useFillProjectWithDefaultMaterialsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useFillProjectWithDefaultMaterialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IFillProjectWithDefaultMaterialsMutation,
    IFillProjectWithDefaultMaterialsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IFillProjectWithDefaultMaterialsMutation,
    IFillProjectWithDefaultMaterialsMutationVariables
  >(FillProjectWithDefaultMaterialsDocument, options)
}
export type FillProjectWithDefaultMaterialsMutationHookResult = ReturnType<
  typeof useFillProjectWithDefaultMaterialsMutation
>
export type FillProjectWithDefaultMaterialsMutationResult =
  Apollo.MutationResult<IFillProjectWithDefaultMaterialsMutation>
export type FillProjectWithDefaultMaterialsMutationOptions =
  Apollo.BaseMutationOptions<
    IFillProjectWithDefaultMaterialsMutation,
    IFillProjectWithDefaultMaterialsMutationVariables
  >
export const UploadMaterialsExcelDocument = gql`
  mutation UploadMaterialsExcel($data: UploadMaterialsExcelInput!) {
    uploadMaterialsExcel(data: $data)
  }
`
export type IUploadMaterialsExcelMutationFn = Apollo.MutationFunction<
  IUploadMaterialsExcelMutation,
  IUploadMaterialsExcelMutationVariables
>

/**
 * __useUploadMaterialsExcelMutation__
 *
 * To run a mutation, you first call `useUploadMaterialsExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMaterialsExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMaterialsExcelMutation, { data, loading, error }] = useUploadMaterialsExcelMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUploadMaterialsExcelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUploadMaterialsExcelMutation,
    IUploadMaterialsExcelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUploadMaterialsExcelMutation,
    IUploadMaterialsExcelMutationVariables
  >(UploadMaterialsExcelDocument, options)
}
export type UploadMaterialsExcelMutationHookResult = ReturnType<
  typeof useUploadMaterialsExcelMutation
>
export type UploadMaterialsExcelMutationResult =
  Apollo.MutationResult<IUploadMaterialsExcelMutation>
export type UploadMaterialsExcelMutationOptions = Apollo.BaseMutationOptions<
  IUploadMaterialsExcelMutation,
  IUploadMaterialsExcelMutationVariables
>
export const RestoreMaterialsDocument = gql`
  mutation RestoreMaterials(
    $projectId: ObjectId!
    $materialsUploadId: ObjectId
  ) {
    restoreMaterials(
      projectId: $projectId
      materialsUploadId: $materialsUploadId
    )
  }
`
export type IRestoreMaterialsMutationFn = Apollo.MutationFunction<
  IRestoreMaterialsMutation,
  IRestoreMaterialsMutationVariables
>

/**
 * __useRestoreMaterialsMutation__
 *
 * To run a mutation, you first call `useRestoreMaterialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreMaterialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreMaterialsMutation, { data, loading, error }] = useRestoreMaterialsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      materialsUploadId: // value for 'materialsUploadId'
 *   },
 * });
 */
export function useRestoreMaterialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IRestoreMaterialsMutation,
    IRestoreMaterialsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IRestoreMaterialsMutation,
    IRestoreMaterialsMutationVariables
  >(RestoreMaterialsDocument, options)
}
export type RestoreMaterialsMutationHookResult = ReturnType<
  typeof useRestoreMaterialsMutation
>
export type RestoreMaterialsMutationResult =
  Apollo.MutationResult<IRestoreMaterialsMutation>
export type RestoreMaterialsMutationOptions = Apollo.BaseMutationOptions<
  IRestoreMaterialsMutation,
  IRestoreMaterialsMutationVariables
>
