import * as React from 'react'

import { observer } from 'mobx-react'

import { IPermitTypeField } from '~/client/graph'
import StruxhubPhoneInput from '~/client/src/shared/components/StruxhubInputs/StruxhubPhoneInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import {
  ICountry,
  getFormattedPhoneNumberForSubmission,
  isPhoneNumberValidForSubmission,
} from '~/client/src/shared/utils/phoneNumberHelpers'

import PermitBaseFormField from './PermitBaseFormField'
import PermitFieldRemoveIcon from './PermitFieldRemoveIcon'

interface IProps {
  typeField: IPermitTypeField
  isViewMode: boolean

  fieldsStore: IPermitFieldsStore

  tableId?: string
  tableRowIndex?: number
}

@observer
export default class PermitFormPhoneNumberField extends React.Component<IProps> {
  public render() {
    const { isViewMode, typeField, fieldsStore, tableId, tableRowIndex } =
      this.props
    const { caption, isMandatory, isMultiple } = typeField
    const { isFieldChanged, isFieldValid } = fieldsStore

    return (
      <PermitBaseFormField
        typeField={typeField}
        isViewMode={isViewMode}
        viewModeElements={this.viewModeElements}
        onAddFieldClick={this.addNewFieldValue}
      >
        {this.fieldValues.map((phone, index) => {
          const shouldShowRemoveBtn = !!index || !!phone

          return (
            <div key={index} className="row y-center">
              <StruxhubPhoneInput
                id={`${typeField.id}_${index}_${tableRowIndex || 0}`}
                label={caption}
                isRequired={isMandatory}
                isChanged={isFieldChanged(
                  typeField.id,
                  index,
                  tableId,
                  tableRowIndex,
                )}
                isValid={isFieldValid(typeField, index, tableId, tableRowIndex)}
                isLabelHidden={!!index}
                isHelperTextCondensed={isMultiple}
                value={phone}
                onChange={this.onValueChange.bind(this, index)}
                validationMessage={
                  Localization.translator.wrongPhoneNumberFormat
                }
              />
              {isMultiple && shouldShowRemoveBtn && (
                <PermitFieldRemoveIcon
                  index={index}
                  onClick={this.removeValue}
                />
              )}
            </div>
          )
        })}
      </PermitBaseFormField>
    )
  }

  private get viewModeElements(): JSX.Element[] {
    return this.fieldValues
      .filter(p => p)
      .map((pn, idx) => (
        <div key={idx} className="text large word-break-all">
          {pn}
        </div>
      ))
  }

  private get fieldValues(): string[] {
    const { typeField, fieldsStore, tableId, tableRowIndex } = this.props
    const values = fieldsStore.getFieldValues<string>(
      typeField.id,
      tableId,
      tableRowIndex,
    )

    return values?.length ? values : ['']
  }

  private onValueChange(
    fieldIndex: number,
    phoneNumber: string,
    country: ICountry,
  ) {
    const { fieldsStore, typeField, tableId, tableRowIndex } = this.props
    const { changeFieldValue, changeTableFieldValue, changeValidationState } =
      fieldsStore

    changeValidationState(
      typeField.id,
      fieldIndex,
      isPhoneNumberValidForSubmission(phoneNumber, country),
      tableRowIndex,
    )

    const formattedValue = getFormattedPhoneNumberForSubmission(phoneNumber)

    if (this.isTableField) {
      changeTableFieldValue(
        tableId,
        tableRowIndex,
        typeField,
        fieldIndex,
        formattedValue,
      )
      return
    }

    changeFieldValue(typeField, fieldIndex, formattedValue)
  }

  private addNewFieldValue = () => {
    if (!this.isTableField) {
      const { fieldsStore, typeField } = this.props
      fieldsStore.addNewFieldValue(typeField, '')
    }
  }

  private removeValue = (index: number) => {
    if (!this.isTableField) {
      const { fieldsStore, typeField } = this.props
      const { rearrangeInvalidState, removeFieldValue } = fieldsStore

      rearrangeInvalidState(typeField.id, index)
      removeFieldValue(typeField.id, index)
    }
  }

  private get isTableField(): boolean {
    return !!this.props.tableId
  }
}
