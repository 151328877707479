import { computed, observable } from 'mobx'
import { ViewState } from 'react-map-gl'

import {
  IAddressBounds,
  IGeoJson2DGeographicCoordinates,
  IGeoJson2DGeographicCoordinatesInput,
  ILatLng,
  ISitemap,
  ISitemapCircle,
  ISitemapCircleInput,
  ISitemapItemShapeInterface,
  ISitemapPin,
  ISitemapPinInput,
  ISitemapPolyline,
  ISitemapPolylineInput,
  ISitemapRectangle,
  ISitemapRectangleInput,
  ISitemapSpecificItemData,
  ISitemapSpecificItemDataCircleInput,
  ISitemapSpecificItemDataPolylineInput,
  ISitemapSpecificItemDataRectangleInput,
  ISitemapTextBox,
  ISitemapTextBoxInput,
  SitemapItemShapeType,
} from '~/client/graph'
import SitemapType from '~/client/src/shared/enums/SitemapType'
import Guard from '~/client/src/shared/utils/Guard'

import { copyObjectArray } from '../utils/util'
import BaseModel from './BaseModel'

export default class Sitemap extends BaseModel<ISitemap> implements ISitemap {
  public static fromDto(dto: ISitemap) {
    return new Sitemap(
      dto.id,
      dto.name,
      dto.projectId,
      (dto.type as SitemapType) || SitemapType.Default,
      dto.basemapId,
      dto.filledImage,
      dto.itemsFilledImage,
      dto.isLabelsShown,
      dto.items || [],
      dto.isProjectOverviewMap,
      dto.createdAt,
      dto.updatedAt,
      dto.bounds,
      dto.center,
      dto.zoom,
      dto.altitude,
      dto.pitch,
      dto.bearing,
      dto.width,
      dto.height,
      dto.geoCorners,
    )
  }

  @observable public items: ISitemapSpecificItemData[] = []

  public constructor(
    id: string,
    public name: string,
    public projectId: string,
    public type: SitemapType,
    public basemapId: string,
    public filledImage: string,
    public itemsFilledImage: string,
    public isLabelsShown: boolean = true,
    items: ISitemapSpecificItemData[] = [],
    public isProjectOverviewMap: boolean = false,
    createdAt: number = 0,
    updatedAt: number = 0,
    public bounds?: IAddressBounds,
    public center?: ILatLng,
    public zoom?: number,
    public altitude?: number,
    public pitch?: number,
    public bearing?: number,
    public width?: number,
    public height?: number,
    public geoCorners?: IGeoJson2DGeographicCoordinates[],
  ) {
    super(id)

    this.items = copyObjectArray(items)
    this.setCreatedAt(createdAt)
    this.setUpdatedAt(updatedAt)
    Guard.requireAll({ name, projectId, basemapId })
  }

  public get isReferenced(): boolean {
    return !!this.center?.lat && !!this.bounds
  }

  @computed
  public get itemsMap(): { [key: string]: boolean } {
    return (
      this.items?.reduce((acc, item) => {
        acc[item.sitemapItemId] = true
        return acc
      }, {} as { [key: string]: boolean }) || {}
    )
  }

  public getItemDisplayData = (
    sitemapItemId: string,
  ): ISitemapSpecificItemData => {
    return (
      this.findItemBySitemapItemId(sitemapItemId) ||
      ({} as ISitemapSpecificItemData)
    )
  }

  public hasDisplayData = (sitemapItemId: string): boolean => {
    return !!this.findItemBySitemapItemId(sitemapItemId)
  }

  public setIsProjectOverviewMap = (value: boolean) => {
    this.isProjectOverviewMap = value
  }

  public isItemDisplayed = (sitemapItemId: string): boolean => {
    const item = this.getItemDisplayData(sitemapItemId)
    return this.hasDisplayData(sitemapItemId) && !item.isHidden
  }

  public setItemDisplayData(
    sitemapItemId: string,
    data: ISitemapSpecificItemData,
  ) {
    const objIndex = this.findItemIndexBySitemapItemId(sitemapItemId)

    if (objIndex === -1) {
      this.items.push(data)
      return
    }

    this.items.splice(objIndex, 1, data)
  }

  public deleteItemDisplayData(sitemapItemId: string) {
    const objIndex = this.findItemIndexBySitemapItemId(sitemapItemId)

    if (objIndex !== -1) {
      this.items.splice(objIndex, 1)
    }
  }

  public toggleLabelsShown() {
    this.isLabelsShown = !this.isLabelsShown
  }

  public setGeoposition(
    geoposition?: ViewState,
    width?: number,
    height?: number,
    bounds?: IGeoJson2DGeographicCoordinatesInput[],
  ) {
    if (geoposition) {
      this.bounds = {
        ne: {
          lat: bounds[0].latitude,
          lng: bounds[0].longitude,
        },
        sw: {
          lat: bounds[3].latitude,
          lng: bounds[3].longitude,
        },
      }
      this.center = {
        lat: geoposition.latitude,
        lng: geoposition.longitude,
      }
      this.zoom = geoposition.zoom
      this.altitude = 0
      this.pitch = geoposition.pitch
      this.bearing = geoposition.bearing
    }
    this.width = width
    this.height = height
    this.geoCorners = bounds
  }

  public setFilledImage(filledImage: string) {
    this.filledImage = filledImage
  }

  public setItemsFilledImage(itemsFilledImage: string) {
    this.itemsFilledImage = itemsFilledImage
  }

  public isNameEqual(name: string) {
    return this.name === name
  }

  public setName(name: string) {
    this.name = name
  }

  public getCopy() {
    return new Sitemap(
      this.id,
      this.name,
      this.projectId,
      this.type,
      this.basemapId,
      this.filledImage,
      this.itemsFilledImage,
      true,
      [],
      this.isProjectOverviewMap,
      null,
      null,
      this.bounds,
      this.center,
      this.zoom,
      this.altitude,
      this.pitch,
      this.bearing,
      this.width,
      this.height,
      this.geoCorners?.slice(),
    )
  }

  public getFullCopy(): Sitemap {
    const copy = this.getCopy()
    copy.updateFromJson(this.asJson)
    return copy
  }

  public static getPolylineItems(
    items: ISitemapSpecificItemData[],
  ): ISitemapSpecificItemData[] {
    return this.getItemsByShapeType(items, SitemapItemShapeType.Polyline)
  }

  public static getRectangleItems(
    items: ISitemapSpecificItemData[],
  ): ISitemapSpecificItemData[] {
    return Sitemap.getItemsByShapeType(items, SitemapItemShapeType.Rectangle)
  }

  public static getCircleItems(
    items: ISitemapSpecificItemData[],
  ): ISitemapSpecificItemData[] {
    return Sitemap.getItemsByShapeType(items, SitemapItemShapeType.Circle)
  }

  public static getItemsWithoutShape(
    items: ISitemapSpecificItemData[],
  ): ISitemapSpecificItemData[] {
    return items?.filter(i => !i.shape)
  }

  public static getPolylineItemDtos(
    items: ISitemapSpecificItemData[],
  ): ISitemapSpecificItemDataPolylineInput[] {
    return Sitemap.convertItemsToDtos<ISitemapPolylineInput>(
      [
        ...Sitemap.getPolylineItems(items),
        ...Sitemap.getItemsWithoutShape(items),
      ],
      Sitemap.convertShapeToPolylineDto,
    )
  }

  public static getRectangleItemDtos(
    items: ISitemapSpecificItemData[],
  ): ISitemapSpecificItemDataRectangleInput[] {
    return Sitemap.convertItemsToDtos<ISitemapRectangleInput>(
      Sitemap.getRectangleItems(items),
      Sitemap.convertShapeToRectangleDto,
    )
  }

  public static getCircleItemDtos(
    items: ISitemapSpecificItemData[],
  ): ISitemapSpecificItemDataCircleInput[] {
    return Sitemap.convertItemsToDtos<ISitemapCircleInput>(
      Sitemap.getCircleItems(items),
      Sitemap.convertShapeToCircleDto,
    )
  }

  public static getItemsByShapeType(
    items: ISitemapSpecificItemData[],
    shapeType: SitemapItemShapeType,
  ): ISitemapSpecificItemData[] {
    return items?.filter(({ shape }) => shape?.type === shapeType)
  }

  private findItemBySitemapItemId(itemId: string): ISitemapSpecificItemData {
    if (!itemId) {
      return null
    }

    return this.items?.find(({ sitemapItemId }) => sitemapItemId === itemId)
  }

  public removeItemDisplayData(id: string) {
    const objIndex = this.findItemIndexBySitemapItemDataId(id)
    if (objIndex === -1) {
      return
    }
    this.items.splice(objIndex, 1)
  }

  private findItemIndexBySitemapItemId(itemId: string): number {
    return this.items?.findIndex(
      ({ sitemapItemId }) => sitemapItemId === itemId,
    )
  }

  private findItemIndexBySitemapItemDataId(itemId: string): number {
    return this.items?.findIndex(({ id }) => id === itemId)
  }

  private static convertItemsToDtos<ShapeInputType>(
    items: ISitemapSpecificItemData[],
    convertShapeFn: (shape: ISitemapItemShapeInterface) => ShapeInputType,
  ): any[] {
    return items.map(i => {
      return {
        id: i.id,
        sitemapItemId: i.sitemapItemId,
        sitemapId: i.sitemapId,
        projectId: i.projectId,
        isHidden: i.isHidden,
        icon: Sitemap.convertToSitemapPinDto(i.icon),
        label: Sitemap.convertToTextBoxDto(i.label),
        shape: convertShapeFn(i.shape as ISitemapItemShapeInterface),
      }
    })
  }

  private static convertToSitemapPinDto(
    sitemapPin: ISitemapPin,
  ): ISitemapPinInput {
    if (!sitemapPin) {
      return null
    }

    return {
      position: sitemapPin.position && {
        x: sitemapPin.position.x,
        y: sitemapPin.position.y,
      },
      isHidden: sitemapPin.isHidden,
    }
  }

  private static convertToTextBoxDto(
    textBox: ISitemapTextBox,
  ): ISitemapTextBoxInput {
    if (!textBox) {
      return null
    }

    return {
      fontSize: textBox.fontSize,
      isTextBoxDisplayed: textBox.isTextBoxDisplayed,
      position: textBox.position && {
        x: textBox.position.x,
        y: textBox.position.y,
      },
      isHidden: textBox.isHidden,
      color: textBox.color,
    }
  }

  private static convertShapeToPolylineDto(
    shape: ISitemapPolyline,
  ): ISitemapPolylineInput {
    if (!shape) {
      return null
    }

    return {
      lineWidth: shape.lineWidth,
      lineColor: shape.lineColor,
      fillColor: shape.fillColor,
      fillOpacity: shape.fillOpacity,
      type: shape.type,
      isClosed: shape.isClosed,
      points: shape.points?.map(p => {
        return { x: p.x, y: p.y }
      }),
      arrowPosition: shape.arrowPosition,
      isDisplayed: shape.isDisplayed,
    }
  }

  private static convertShapeToRectangleDto(
    shape: ISitemapRectangle,
  ): ISitemapRectangleInput {
    if (!shape) {
      return null
    }

    return {
      lineWidth: shape.lineWidth,
      lineColor: shape.lineColor,
      fillColor: shape.fillColor,
      fillOpacity: shape.fillOpacity,
      type: shape.type,
      position: shape.position && {
        x: shape.position.x,
        y: shape.position.y,
      },
      width: shape.width,
      height: shape.height,
      rotation: shape.rotation,
      isDisplayed: shape.isDisplayed,
    }
  }

  private static convertShapeToCircleDto(
    shape: ISitemapCircle,
  ): ISitemapCircleInput {
    if (!shape) {
      return null
    }

    return {
      lineWidth: shape.lineWidth,
      lineColor: shape.lineColor,
      fillColor: shape.fillColor,
      fillOpacity: shape.fillOpacity,
      type: shape.type,
      radius: shape.radius,
      position: shape.position && {
        x: shape.position.x,
        y: shape.position.y,
      },
      isDivided: shape.isDivided,
      divisionStartAngle: shape.divisionStartAngle,
      divisionEndAngle: shape.divisionEndAngle,
      isDisplayed: shape.isDisplayed,
    }
  }
}
