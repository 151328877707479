import {
  ApolloClient,
  ApolloQueryResult,
  DocumentNode,
  NormalizedCacheObject,
} from '@apollo/client'
import { ObservableSubscription } from '@apollo/client/utilities'

import { IMutation, IQuery, ISubscription } from '~/client/graph'

import Guard from './Guard'

const MULTIPLE_DATA_TIMEOUT_MS = 500
export const EMPTY_OBJECT_ID = '000000000000000000000000'
const DEFAULT_FETCH_POLICY = 'no-cache'

export interface IQueryResult {
  data?: IQuery
  error?: Error
}

export interface IMutationResult {
  data?: IMutation
  error?: Error
}

interface ISubscriptionInfo {
  subscriptionId: string
  subscription: ObservableSubscription
}

export default class GraphExecutor {
  public constructor(
    private readonly graphClient: ApolloClient<NormalizedCacheObject>,
  ) {
    Guard.requireAll({ graphClient })
  }

  public static flattenResponse(obj: IMutation | ISubscription | IQuery) {
    return (obj && obj[Object.keys(obj)[0]]) || {}
  }

  private subscriptions: ISubscriptionInfo[] = []
  private receivedDataQueueMap: {
    [subscriptionId: string]: {
      data: ISubscription[]
      onData?: (data: ISubscription[]) => void
    }
  } = {}
  private multipleDataTimer

  public async executeQuery(
    query: DocumentNode,
    variables: any,
  ): Promise<IQueryResult> {
    try {
      const resp = await this.graphClient
        .query<IQuery>({
          query,
          variables,
          fetchPolicy: DEFAULT_FETCH_POLICY,
        })
        .catch(async error => ({ error } as ApolloQueryResult<IQuery>))

      if (resp.error || resp.errors) {
        const error = resp.error || resp.errors[0]
        return { error }
      }

      return {
        data: resp.data,
      }
    } catch (error) {
      return { error }
    }
  }

  public subscribe(
    query: DocumentNode,
    variables: any,
    listenMany: boolean,
    subscriptionId: string,
    onDataCallback: (data: ISubscription | ISubscription[]) => void,
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.terminateSubscription(subscriptionId)

      try {
        const queryObservable = this.graphClient.subscribe<ISubscription>({
          query,
          variables,
          fetchPolicy: DEFAULT_FETCH_POLICY,
        })

        const subscription = queryObservable.subscribe(data => {
          if (this.isAcknowledgmentData(data.data)) {
            return resolve()
          }

          if (!listenMany) {
            return onDataCallback(data.data)
          }

          clearTimeout(this.multipleDataTimer)

          this.receivedDataQueueMap[subscriptionId].data.push(data.data)
          this.multipleDataTimer = setTimeout(
            this.receiveAllDataAtOnce,
            MULTIPLE_DATA_TIMEOUT_MS,
          )
        })

        this.subscriptions.push({ subscriptionId, subscription })
        if (listenMany) {
          this.receivedDataQueueMap[subscriptionId] = {
            data: [],
            onData: onDataCallback,
          }
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  public async executeMutation(
    mutation: DocumentNode,
    variables: any,
  ): Promise<IMutationResult> {
    try {
      const resp = await this.graphClient.mutate<IMutation>({
        mutation,
        variables,
        fetchPolicy: DEFAULT_FETCH_POLICY,
      })

      if (resp.errors) {
        const error = resp.errors[0]
        return { error }
      }

      return {
        data: resp.data,
      }
    } catch (error) {
      return { error }
    }
  }

  public terminateAllSubscriptions() {
    this.subscriptions.forEach(info => {
      info.subscription.unsubscribe()
    })
    this.subscriptions = []
    this.receivedDataQueueMap = {}
  }

  public terminateSubscription(subscriptionId: string) {
    this.subscriptions = this.subscriptions.filter(info => {
      if (info.subscriptionId === subscriptionId) {
        info.subscription.unsubscribe()
        delete this.receivedDataQueueMap[subscriptionId]
        return false
      }

      return true
    })
  }

  private receiveAllDataAtOnce = () => {
    Object.values(this.receivedDataQueueMap).forEach(entry => {
      if (entry.data.length) {
        entry.onData(entry.data)
      }
      entry.data = []
    })
  }

  private isAcknowledgmentData(data: ISubscription): boolean {
    return data
      ? GraphExecutor.flattenResponse(data)?.id === EMPTY_OBJECT_ID
      : false
  }
}
