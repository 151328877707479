import { action, computed, observable } from 'mobx'

import { IOperationRule } from '~/client/graph'
import OperationRule from '~/client/src/shared/models/OperationRule'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import {
  DELETE_MANY_OPERATION_RULES,
  SAVE_MANY_OPERATION_RULES,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import Guard from '~/client/src/shared/utils/Guard'

export default class OperationRulesStore {
  @observable public isDataReceived: boolean = false

  public constructor(private eventsStore: EventsStore) {
    Guard.requireAll({
      eventsStore,
    })
  }

  @computed
  public get list(): OperationRule[] {
    return Array.from(this.byId.values()).sort(
      (r1, r2) => r2.createdAt - r1.createdAt,
    )
  }

  @action.bound
  public receiveList(dtos: IOperationRule[]) {
    this.clearList()

    dtos.forEach((dto: IOperationRule) =>
      this.byId.set(dto.id, OperationRule.fromDto(dto)),
    )

    this.isDataReceived = true
  }

  @action.bound
  public receiveOne(id: string, dto: IOperationRule) {
    if (dto) {
      this.removeRuleWithNewId()
      this.byId.set(dto.id, OperationRule.fromDto(dto))
    } else {
      this.byId.delete(id)
    }
  }

  @action.bound
  public saveMany(
    operationRules: OperationRule[],
    callbackFn?: (rules: IOperationRule[]) => void,
  ) {
    if (!operationRules?.length) {
      return
    }

    if (operationRules.length === 1 && !operationRules[0].isExisting) {
      this.byId.set(OperationRule.NEW_ID, operationRules[0])
    }

    const dtos = operationRules.map(r => OperationRule.toDto(r))

    this.eventsStore.dispatch(SAVE_MANY_OPERATION_RULES, dtos, callbackFn)
  }

  @action.bound
  public removeMany(ruleIds: string[]) {
    if (!ruleIds?.length) {
      return
    }

    this.eventsStore.dispatch(DELETE_MANY_OPERATION_RULES, ruleIds)
  }

  @action.bound
  public clearList() {
    this.byId.clear()
    this.isDataReceived = false
  }

  @action.bound
  public removeRuleWithNewId() {
    this.byId.delete(OperationRule.NEW_ID)
  }

  public isRuleExisting = (id: string): boolean => {
    return this.byId.has(id)
  }

  public getRuleById = (id: string): OperationRule => {
    return this.isRuleExisting(id) && this.byId.get(id)
  }

  private get byId() {
    return this.eventsStore.appState.operationRules
  }
}
