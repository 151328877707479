import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList, toggleClass } from 'react-classlist-helper'

import { Loader } from '~/client/src/shared/components/Loader'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import OperationRule from '~/client/src/shared/models/OperationRule'
import User from '~/client/src/shared/models/User'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

import BallInCourtRulesTableRow from './components/BallInCourtRulesTableRow/BallInCourtRulesTableRow'

import './BallInCourtRulesTable.scss'

// localization: translated

interface IProps {
  bicOperationRules: OperationRule[]
  className?: string
  tableClassName?: string

  getUsersFromAssignment: (assignmentExpressions: string[]) => User[]
  onRemoveRuleClick: (ruleId: string) => void
  onDuplicateRuleClick: (ruleId: string) => void
  onChangeUserForRule: (
    rule: OperationRule,
    userId: string,
    isNew?: boolean,
  ) => void
  onRuleExpressionChange: (
    rule: OperationRule,
    locationAndCompExpression: string,
  ) => void
  onAddNewRuleClick: () => void

  tagsStore?: TagsStore
}

@inject('tagsStore')
@observer
export default class BallInCourtRulesTable extends React.Component<IProps> {
  public render() {
    const { className } = this.props

    return (
      <div
        className={classList({
          'col py10 ball-in-court-rules-table': true,
          [className]: !!className,
        })}
      >
        {this.tableContent}
        {this.addRuleButton}
      </div>
    )
  }

  private get tableContent(): JSX.Element {
    const {
      bicOperationRules,
      getUsersFromAssignment,
      onDuplicateRuleClick,
      onRemoveRuleClick,
      onChangeUserForRule,
      onRuleExpressionChange,
      tagsStore,
      tableClassName,
    } = this.props

    if (!tagsStore.isDataReceived) {
      return <Loader className="row x-center pa30 full-width" />
    }

    if (!bicOperationRules?.length) {
      return (
        <span className="text center large bold uppercase py20">
          {Localization.translator.noRules}
        </span>
      )
    }

    return (
      <div className={tableClassName}>
        {bicOperationRules.map((bicRule, index) => (
          <BallInCourtRulesTableRow
            className={toggleClass('bt-palette-brand-lighter', !!index)}
            key={bicRule.id}
            bicRule={bicRule}
            ruleIndex={index + 1}
            getUsersFromAssignment={getUsersFromAssignment}
            onDuplicateRuleClick={onDuplicateRuleClick}
            onRemoveRuleClick={onRemoveRuleClick}
            onChangeUserForRule={onChangeUserForRule}
            onRuleExpressionChange={onRuleExpressionChange}
            tagsStore={tagsStore}
          />
        ))}
      </div>
    )
  }

  private get addRuleButton(): JSX.Element {
    if (!this.props.tagsStore.isDataReceived) {
      return null
    }

    return (
      <div
        className="row text large line-extra-large grey-30 pointer pt10 pb5 px20 mw120"
        onClick={this.props.onAddNewRuleClick}
      >
        <Icon icon={IconNames.PLUS} className="no-grow" />
        <span className="no-grow nowrap ml3">
          {Localization.translator.add}
        </span>
      </div>
    )
  }
}
