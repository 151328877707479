import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import AvatarType from '../../../enums/AvatarType'
import { TagType } from '../../../enums/TagType'
import User from '../../../models/User'
import CompaniesStore from '../../../stores/domain/Companies.store'
import TagsStore from '../../../stores/domain/Tags.store'
import Avatar from '../../Avatar/Avatar'
import AvatarInput from '../../AvatarInput/AvatarInput'
import { Loader } from '../../Loader'
import ChatMembers from './ChatMembers'

interface IProps {
  chatName: string
  chatAvatarUrl: string
  members: User[]
  isMemberOnline: (memberId: string) => boolean

  creatorId: string

  onChatNameChange?: (newChatName: string) => void
  onChatAvatarUrlChange?: (newChatAvatarUrl: string) => void
  onMemberExclude?: (userId: string) => void

  isReadonlyMode?: boolean
  onAddMemberButtonClick?: () => void

  areMembersBeingLoaded?: boolean

  tagsStore?: TagsStore
  companiesStore?: CompaniesStore
}

@inject('tagsStore', 'companiesStore')
@observer
export default class ChatDetails extends React.Component<IProps> {
  public render() {
    if (this.props.areMembersBeingLoaded) {
      return <Loader />
    }

    const { chatAvatarUrl, chatName, onChatAvatarUrlChange, isReadonlyMode } =
      this.props

    return (
      <div className="col full-height overflow-y-auto">
        <div className="row px15 mt10 mb30">
          {isReadonlyMode ? (
            <div className="col">
              <div className="row mb5">
                <Avatar
                  className="no-grow mr8"
                  avatarType={AvatarType.Company}
                  src={chatAvatarUrl}
                />
                <span className="text extra-large">{chatName}</span>
              </div>
              <div className="row">
                <span className="text lp05 large no-grow no-white-space-wrap light mr8">
                  Created by:
                </span>
                <span>{this.creator.fullName}</span>
              </div>
            </div>
          ) : (
            <>
              <div style={{ minWidth: '100px' }} className="no-grow mr4">
                <AvatarInput
                  data={{ url: chatAvatarUrl }}
                  avatarType={AvatarType.Company}
                  onChange={onChatAvatarUrlChange}
                />
              </div>
              <div className="col">
                <label
                  htmlFor="group-name-input"
                  className="ml8 text bold light lp05"
                >
                  Group name
                </label>
                <div
                  className={classList({
                    'bg-palette-brand-lightest pa8 text extra-large brada4':
                      true,
                    'inactive-element': isReadonlyMode,
                  })}
                >
                  <input
                    disabled={isReadonlyMode}
                    id="group-name-input"
                    type="text"
                    value={chatName}
                    className="bare-input full-width no-outline"
                    onChange={this.handleChangeName}
                  />
                </div>

                <div className="text light mt20 center">
                  Please add group name &amp; optional group icon
                </div>
              </div>
            </>
          )}
        </div>

        <div className="row px15 text bold bg-palette-brand-lightest py8 lp15 light">
          Group Members ({this.props.members.length})
          {!!this.props.onAddMemberButtonClick && (
            <span
              className="text blue right blue bold pointer"
              onClick={this.props.onAddMemberButtonClick}
            >
              Add
            </span>
          )}
        </div>

        <div className="col full-height overflow-y-auto px15">
          {Object.keys(this.membersByCompanies).map(this.renderCompanyMembers)}
        </div>
      </div>
    )
  }

  private renderCompanyMembers = (companyId: string) => {
    const companyName = this.props.companiesStore.getCompanyNameById(companyId)
    const members = this.membersByCompanies[companyId] || []

    return (
      <React.Fragment key={companyId}>
        <div className="row py8 bb-light-cool-grey">
          <span className="text light bold lp15">{companyName}</span>
          <span className="text light bold lp15 right">{members.length}</span>
        </div>

        <ChatMembers
          className="py10"
          shouldWrap={true}
          selectedMembers={members}
          onExclude={this.props.onMemberExclude}
          nonExcludableUserIds={[this.props.creatorId]}
          isMemberOnline={this.props.isMemberOnline}
        />
      </React.Fragment>
    )
  }

  private handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChatNameChange(e.target.value)
  }

  @computed
  private get membersByCompanies(): { [key: string]: User[] } {
    return this.props.tagsStore.mapUsersByTagType(
      this.props.members,
      TagType.Company,
      false,
    )
  }

  private get creator(): User {
    return this.props.members.find(m => m?.id === this.props.creatorId)
  }
}
