import { computed } from 'mobx'

import { IActivityCodeRelationship } from '~/client/graph'
import ActivityCodeRelationship from '~/client/src/shared/models/ActivityCodeRelationship'

import EventsStore from '../EventStore/Events.store'
import BaseStore from '../baseStores/Base.store'

export default class ActivityCodeRelationshipsStore extends BaseStore<
  ActivityCodeRelationship,
  IActivityCodeRelationship
> {
  public constructor(private eventsStore: EventsStore) {
    super(ActivityCodeRelationship)
  }

  public get list() {
    return this.eventsStore.appState.activityCodeRelationships
  }

  @computed
  public get availableRelationships() {
    return this.list.filter(r => !r.isDeleted)
  }

  protected createAnInstance(id: string, dto: IActivityCodeRelationship) {
    return ActivityCodeRelationship.fromDto(dto)
  }
}
