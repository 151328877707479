import * as React from 'react'

import { classList } from 'react-classlist-helper'

import './FilterFooter.scss'

const cancel = 'Cancel'

interface IFilterFooter {
  clickOnCancel: () => void
  clickOnApply: () => void
  applyButtonText?: string
  isApplyButtonInactive?: boolean
}

class FilterFooter extends React.Component<IFilterFooter> {
  public render() {
    const {
      clickOnCancel,
      clickOnApply,
      applyButtonText = 'Apply',
      isApplyButtonInactive = false,
    } = this.props

    return (
      <div className="filter-footer">
        <div className="filter-footer-btn-cancel" onClick={clickOnCancel}>
          {cancel}
        </div>
        <button
          className={classList({
            'filter-footer-btn-apply large': true,
            'inactive default-cursor': isApplyButtonInactive,
          })}
          onClick={clickOnApply}
        >
          {applyButtonText}
        </button>
      </div>
    )
  }
}

export default FilterFooter
