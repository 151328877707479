import * as React from 'react'

import { Popover, PopoverPosition, PopperModifiers } from '@blueprintjs/core'
import { observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

interface IProps {
  locations: LocationBase[]
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: {
    enabled: true,
    padding: 5,
    boundariesElement: 'window',
  },
  flip: {
    boundariesElement: 'window',
  },
  hide: { enabled: true },
  arrow: { enabled: false },
  computeStyle: { gpuAcceleration: false },
}

const ICON_SIZE = 16

@observer
export default class FormReportListLocationCell extends React.Component<IProps> {
  public render() {
    const { locations } = this.props

    if (!locations?.length) {
      return <div className="pt20 pb7">{NO_VALUE}</div>
    }

    if (locations.length === 1) {
      return (
        <div className="text-ellipsis">
          {this.renderLocationTag(locations[0])}
        </div>
      )
    }

    return (
      <div onClick={this.stopPropagation}>
        <Popover
          popoverClassName="beautiful-shadow report-forms-locations-modal"
          targetClassName="full-width"
          position={PopoverPosition.BOTTOM}
          modifiers={popoverPopperModifiers}
          content={this.modalContent}
        >
          <div className="row location-cell-value pointer h48 no-select underline-hover">
            <div className="text-ellipsis">
              {Localization.translator.xLocations(locations.length)}
            </div>
            <Icons.DownArrow
              className="bp3-icon ml5 icon-w11 icon-grey"
              element="span"
            />
          </div>
        </Popover>
      </div>
    )
  }

  private renderLocationTag = (
    locationObj: LocationBase,
    title?: string,
  ): JSX.Element => {
    const locationName = title || locationObj.name

    return (
      <SitemapAttributeTag
        dataObject={locationObj}
        shouldShowAsTag
        iconSize={ICON_SIZE}
        contentContainerClassName="text-ellipsis py2"
      >
        <span title={locationName} className="text extra-large">
          {locationName}
        </span>
      </SitemapAttributeTag>
    )
  }

  private get modalContent(): JSX.Element {
    return (
      <div className="report-forms-locations-content col scrollable">
        {this.props.locations.map((loc, idx) => (
          <div className="row py10 px16" key={loc?.id + idx}>
            <div className="mr5 text large bold no-grow">{idx + 1}.</div>
            <div className="row text-ellipsis">
              {this.renderLocationTag(loc)}
            </div>
          </div>
        ))}
      </div>
    )
  }

  private stopPropagation = (event: React.SyntheticEvent) => {
    event?.stopPropagation()
  }
}
