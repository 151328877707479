import ActivityLinkingSettings from '../enums/ActivityLinkingSettings'
import Localization from '../localization/LocalizationManager'

const activityLinkingSettingToCaptionMap = {
  [ActivityLinkingSettings.isBuildingLinkingActive]:
    Localization.translator.building,
  [ActivityLinkingSettings.isLevelLinkingActive]: Localization.translator.level,
  [ActivityLinkingSettings.isZoneLinkingActive]: Localization.translator.zone,
  [ActivityLinkingSettings.isCompanyLinkingActive]:
    Localization.translator.company,
}

export default activityLinkingSettingToCaptionMap
