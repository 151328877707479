import React from 'react'

import { inject, observer } from 'mobx-react'

import { IDeliveryMaterial } from '~/client/graph'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'
import InitialState from '~/client/src/shared/stores/InitialState'
import DeliveryUnitsStore from '~/client/src/shared/stores/domain/DeliveryUnits.store'
import DeliveryVehicleTypesStore from '~/client/src/shared/stores/domain/DeliveryVehicleTypes.store'
import GatesStore from '~/client/src/shared/stores/domain/Gates.store'
import LocationStoreBase from '~/client/src/shared/stores/domain/LocationBase.store'
import OffloadingEquipmentsStore from '~/client/src/shared/stores/domain/OffloadingEquipments.store'
import ZonesStore from '~/client/src/shared/stores/domain/Zones.store'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import AreasStore from '../../stores/domain/Areas.store'
import BuildingsStore from '../../stores/domain/Buildings.store'
import CompaniesStore from '../../stores/domain/Companies.store'
import LevelsStore from '../../stores/domain/Levels.store'
import LocationAttributesStore from '../../stores/domain/LocationAttributes.store'
import MaterialCategoryStore from '../../stores/domain/MaterialCategories.store'
import MaterialsStore from '../../stores/domain/Materials.store'
import RoutesStore from '../../stores/domain/Routes.store'
import SitemapsStore from '../../stores/domain/Sitemaps.store'
import ProjectDateStore, {
  differenceInMinutes,
} from '../../stores/ui/ProjectDate.store'
import DeliveryDetailsStore from '../DeliveryDetails/DeliveryDetails.store'
import FileInputBase from '../FileInput/FileInput'
import Linkifier from '../Linkifier/Linkifier'
import UsernameFromUid from '../UsernameFromUid'

// translated

interface IDeliverySummary {
  delivery: Delivery
  FileInputType: typeof FileInputBase

  offloadingEquipmentsStore?: OffloadingEquipmentsStore
  deliveryUnitsStore?: DeliveryUnitsStore
  zonesStore?: ZonesStore
  buildingsStore?: BuildingsStore
  routesStore?: RoutesStore
  gatesStore?: GatesStore
  deliveryVehicleTypesStore?: DeliveryVehicleTypesStore
  sitemapsStore?: SitemapsStore
  state?: InitialState
  projectDateStore?: ProjectDateStore
  materialsStore?: MaterialsStore
  materialCategoryStore?: MaterialCategoryStore
  levelsStore?: LevelsStore
  areasStore?: AreasStore
  companiesStore?: CompaniesStore
  deliveryDetailsStore?: DeliveryDetailsStore
  locationAttributesStore?: LocationAttributesStore
}

interface IDeliverySummaryField {
  header?: string
  value?: string | string[]
  label?: string
  isLinkified?: boolean
  isSpaceAfter?: boolean
  isUserField?: boolean
}

@inject(
  'state',
  'zonesStore',
  'gatesStore',
  'deliveryUnitsStore',
  'buildingsStore',
  'routesStore',
  'deliveryVehicleTypesStore',
  'offloadingEquipmentsStore',
  'projectDateStore',
  'sitemapsStore',
  'materialsStore',
  'materialCategoryStore',
  'levelsStore',
  'areasStore',
  'companiesStore',
  'deliveryDetailsStore',
  'locationAttributesStore',
)
@observer
export default class DeliverySummary extends React.Component<IDeliverySummary> {
  public render() {
    const { delivery, FileInputType, deliveryDetailsStore } = this.props

    const { wasAlreadyMarkedAsNonWorkTime } = deliveryDetailsStore

    const { sitemapUrls, isLateRequest, isFromConcreteDirect } = delivery

    const sitemapWithNames = sitemapUrls.map(url => ({
      url,
      name: this.getSitemapName(Localization.translator.sitemap, url),
    }))

    return (
      <div className="delivery-text">
        {this.fields.map(
          (
            { label, value, header, isLinkified, isSpaceAfter, isUserField },
            i,
          ) => {
            const formattedValue = value || NO_VALUE

            if (isSpaceAfter) {
              return <div key={i} className="mb10" />
            }

            if (header) {
              return (
                <div className="text light uppercase" key={i}>
                  {header}
                </div>
              )
            }

            return (
              <div key={i}>
                <span className="large primary-blue text">{label}:</span>{' '}
                {isLinkified ? (
                  <Linkifier
                    value={formattedValue as string}
                    linkClassName="word-break-all"
                  />
                ) : isUserField ? (
                  this.renderUserFieldValue(value as string[])
                ) : (
                  formattedValue
                )}
              </div>
            )
          },
        )}
        {!isFromConcreteDirect && isLateRequest && (
          <span className="large primary-blue text">
            {Localization.translator.thisDeliveryWasNotScheduledInAdvance}
          </span>
        )}
        {wasAlreadyMarkedAsNonWorkTime && (
          <div className="text large red">
            {Localization.translator.thisDeliveryWasScheduledForNonWorkTime}
          </div>
        )}
        {sitemapWithNames.map(({ name, url }, idx) => (
          <div className="mt10" key={idx}>
            <FileInputType
              id={`Sitemap${idx}`}
              name={name}
              value={url}
              isReadonly={true}
              textClassName="primary-blue text"
              shouldHideIconAndOutline={true}
              shouldUseFullSize={true}
            />
          </div>
        ))}
      </div>
    )
  }

  private renderUserFieldValue(userIds: string[]) {
    if (!userIds?.length) {
      return NO_VALUE
    }

    return userIds.map((userId, i) =>
      userId ? (
        <>
          <UsernameFromUid userId={userId} />
          {i !== userIds.length - 1 && ', '}
        </>
      ) : null,
    )
  }

  private get fields(): IDeliverySummaryField[] {
    const {
      delivery,
      state,
      zonesStore,
      levelsStore,
      areasStore,
      projectDateStore,
      gatesStore,
      routesStore,
      buildingsStore,
      companiesStore,
    } = this.props

    const { hiddenFields } = state.delivery

    const {
      building,
      startDate,
      endDate,
      vendor: vendorId,
      userId,
      isInspectionRequired,
      truckNumber,
      truckLicensePlate,
      vendorEmails,
      driverPhoneNumbers,
      company: companyId,
      installationZone,
      onSiteContactPersonIds,
      note,
      zone,
      gate,
      route,
      level,
      area,
      numberOfEquipmentPicks,
    } = delivery

    const date = projectDateStore.getMonthAndDayToDisplay(startDate)
    const { getTimeToDisplay } = projectDateStore
    const time = getTimeToDisplay(startDate) + ' - ' + getTimeToDisplay(endDate)

    const companyName = companiesStore.getCompanyNameById(companyId)
    const vendorName = companiesStore.getCompanyNameById(vendorId)

    const shouldShowVehicleSection =
      !hiddenFields[FieldIds.TRUCK_SIZE] ||
      !hiddenFields[FieldIds.TRUCK_NUMBER] ||
      !hiddenFields[FieldIds.TRUCK_LICENSE_PLATE]
    const shouldShowSupplierSection =
      !hiddenFields[FieldIds.VENDOR] ||
      !hiddenFields[FieldIds.VENDOR_EMAILS] ||
      !hiddenFields[FieldIds.DRIVER_PHONE_NUMBERS]
    const shouldShowLocationSection =
      !hiddenFields[FieldIds.BUILDING] ||
      !hiddenFields[FieldIds.ZONE] ||
      !hiddenFields[FieldIds.LEVEL] ||
      !hiddenFields[FieldIds.AREA] ||
      !hiddenFields[FieldIds.GATE] ||
      !hiddenFields[FieldIds.ROUTE] ||
      !hiddenFields[FieldIds.INSTALLATION_ZONE]
    const shouldShowEquipmentSection =
      !hiddenFields[FieldIds.OFFLOADING_EQUIPMENT] ||
      !hiddenFields[FieldIds.NUMBER_OF_EQUIPMENT_PICKS]

    return [
      { header: Localization.translator.when },
      { label: Localization.translator.date, value: date },
      {
        label: Localization.translator.time,
        value: `${time} (${this.durationLabel})`,
      },
      { isSpaceAfter: true },

      ...(shouldShowLocationSection
        ? [
            { header: Localization.translator.where },
            ...(!hiddenFields[FieldIds.BUILDING]
              ? [
                  {
                    label: this.getFieldName(FieldIds.BUILDING),
                    value: this.getAttrString(building, buildingsStore),
                  },
                ]
              : []),
            ...(!hiddenFields[FieldIds.ZONE]
              ? [
                  {
                    label: this.getFieldName(FieldIds.ZONE),
                    value: this.getAttrString(zone, zonesStore),
                  },
                ]
              : []),
            ...(!hiddenFields[FieldIds.LEVEL]
              ? [
                  {
                    label: this.getFieldName(FieldIds.LEVEL),
                    value: this.getAttrString(level, levelsStore),
                  },
                ]
              : []),
            ...(!hiddenFields[FieldIds.AREA]
              ? [
                  {
                    label: this.getFieldName(FieldIds.AREA),
                    value: this.getAttrString(area, areasStore),
                  },
                ]
              : []),
            ...(!hiddenFields[FieldIds.GATE]
              ? [
                  {
                    label: this.getFieldName(FieldIds.GATE),
                    value: this.getAttrString(gate, gatesStore),
                  },
                ]
              : []),
            ...(!hiddenFields[FieldIds.ROUTE]
              ? [
                  {
                    label: this.getFieldName(FieldIds.ROUTE),
                    value: this.getAttrString(route, routesStore),
                  },
                ]
              : []),
            ...(!hiddenFields[FieldIds.INSTALLATION_ZONE]
              ? [
                  {
                    label: this.getFieldName(FieldIds.INSTALLATION_ZONE),
                    value: installationZone,
                  },
                ]
              : []),
            { isSpaceAfter: true },
          ]
        : []),

      { header: Localization.translator.contact },
      {
        label: Localization.translator.requester,
        value: [userId],
        isUserField: true,
      },
      ...(!hiddenFields[FieldIds.COMPANY]
        ? [
            {
              label: Localization.translator.requesterCompany,
              value: companyName,
            },
          ]
        : []),
      ...(!hiddenFields[FieldIds.ON_SITE_CONTACTS]
        ? [
            {
              label: Localization.translator.onsiteContactPerson,
              value: onSiteContactPersonIds || [],
              isUserField: true,
            },
          ]
        : []),
      { isSpaceAfter: true },

      ...(shouldShowEquipmentSection
        ? [
            { header: this.getFieldName(FieldIds.OFFLOADING_EQUIPMENT) },
            ...(!hiddenFields[FieldIds.OFFLOADING_EQUIPMENT]
              ? [
                  {
                    label: this.getFieldName(FieldIds.OFFLOADING_EQUIPMENT),
                    value: this.equipmentString,
                  },
                ]
              : []),
            ...(!hiddenFields[FieldIds.NUMBER_OF_EQUIPMENT_PICKS]
              ? [
                  {
                    label: this.getFieldName(
                      FieldIds.NUMBER_OF_EQUIPMENT_PICKS,
                    ),
                    value: numberOfEquipmentPicks,
                  },
                ]
              : []),
            { isSpaceAfter: true },
          ]
        : []),

      ...(shouldShowVehicleSection
        ? [
            {
              header: Localization.translator.vehicle,
            },
            ...(!hiddenFields[FieldIds.TRUCK_SIZE]
              ? [
                  {
                    label: this.getFieldName(FieldIds.TRUCK_SIZE),
                    value: this.truckString,
                  },
                ]
              : []),
            ...(!hiddenFields[FieldIds.TRUCK_LICENSE_PLATE]
              ? [
                  {
                    label: this.getFieldName(FieldIds.TRUCK_LICENSE_PLATE),
                    value: truckLicensePlate || '',
                  },
                ]
              : []),
            ...(!hiddenFields[FieldIds.TRUCK_NUMBER]
              ? [
                  {
                    label: this.getFieldName(FieldIds.TRUCK_NUMBER),
                    value: truckNumber || '1',
                  },
                ]
              : []),
            { isSpaceAfter: true },
          ]
        : []),

      ...(!hiddenFields[FieldIds.MATERIALS_SECTION] &&
      !!this.materialFields.length
        ? [
            { header: Localization.translator.materials },
            ...this.materialFields,
            { isSpaceAfter: true },
          ]
        : []),

      ...(!hiddenFields[FieldIds.INSPECTION_SECTION]
        ? [
            { header: Localization.translator.inspection },
            {
              label: this.getFieldName(FieldIds.IS_INSPECTION_REQUIRED),
              value: isInspectionRequired
                ? Localization.translator.yes
                : Localization.translator.no,
            },
            {
              label: this.getFieldName(FieldIds.INSPECTOR),
              value: [delivery.inspector],
              isUserField: true,
            },
            { isSpaceAfter: true },
          ]
        : []),

      ...(shouldShowSupplierSection
        ? [
            {
              header: Localization.translator.supplierAndDriver,
            },
            ...(!hiddenFields[FieldIds.VENDOR]
              ? [
                  {
                    label: this.getFieldName(FieldIds.VENDOR),
                    value: vendorName,
                  },
                ]
              : []),
            ...(!hiddenFields[FieldIds.VENDOR_EMAILS]
              ? [
                  {
                    label: this.getFieldName(FieldIds.VENDOR_EMAILS),
                    value: (vendorEmails || []).join(', '),
                  },
                ]
              : []),
            ...(!hiddenFields[FieldIds.DRIVER_PHONE_NUMBERS]
              ? [
                  {
                    label: this.getFieldName(FieldIds.DRIVER_PHONE_NUMBERS),
                    value: (driverPhoneNumbers || []).join(', '),
                  },
                ]
              : []),
            { isSpaceAfter: true },
          ]
        : []),

      ...(!hiddenFields[FieldIds.NOTE]
        ? [
            { header: Localization.translator.specialInstructions },
            { label: this.getFieldName(FieldIds.NOTE), value: note },
          ]
        : []),
    ]
  }

  private get materialFields(): IDeliverySummaryField[] {
    const { state, delivery, locationAttributesStore } = this.props

    if (!delivery?.materials?.length) {
      return []
    }

    const { hiddenFields } = state.delivery
    const { isCSIDisabled, isCSISubCategoriesDisabled } =
      state.projectMaterialOptions
    const areCategoriesShown = !isCSIDisabled || !isCSISubCategoriesDisabled

    const shouldIncludeSubHeader = delivery.materials.length > 1

    return delivery.materials.reduce((fields, mat, idx) => {
      const materialFields = [
        !isCSIDisabled && {
          label: Localization.translator.category,
          value: this.getMaterialCategoryString(mat.materialId),
        },
        !isCSISubCategoriesDisabled && {
          label: this.getFieldName(FieldIds.MATERIAL),
          value: this.getMaterialSubCategoryString(mat.materialId),
        },
        ...(areCategoriesShown && mat.procurementId
          ? [
              {
                label: Localization.translator.procurementID,
                value: mat.procurementId || NO_VALUE,
              },
              {
                label: Localization.translator.plannedInstallLocation,
                value: this.getPlannedInstallLocationString(mat),
              },
            ]
          : []),
        !hiddenFields[FieldIds.MATERIAL_QUANTITY] && {
          label: Localization.translator.quantity,
          value: `${mat.quantity || ''} ${this.getUnitString(
            mat.unitId,
          )}`.trim(),
        },
        !hiddenFields[FieldIds.MATERIAL_LOCATION] && {
          label: this.getFieldName(FieldIds.MATERIAL_LOCATION),
          value:
            locationAttributesStore.getById(mat?.locationId)?.name || NO_VALUE,
        },
        !hiddenFields[FieldIds.MATERIAL_NOTE] && {
          label: this.getFieldName(FieldIds.MATERIAL_NOTE),
          value: mat.note || NO_VALUE,
          isLinkified: true,
        },
      ].filter(f => f)

      if (materialFields.length) {
        const titleField = shouldIncludeSubHeader
          ? { header: Localization.translator.xMaterial(idx + 1) }
          : []
        fields = fields.concat(titleField, materialFields)
      }
      return fields
    }, [] as IDeliverySummaryField[])
  }

  private getFieldName(id: FieldIds) {
    return this.props.state.getDeliveryFieldName(id)
  }

  private getAttrString(attrId: string, store: LocationStoreBase<any>): string {
    const {
      projectDateStore,
      delivery: { startDate, isDone },
    } = this.props

    const shouldShowDeletedAttributes =
      isDone || projectDateStore.isBeforeToday(startDate)

    return store.getString(attrId, shouldShowDeletedAttributes)
  }

  private getMaterialCategoryString = (materialId: string): string => {
    const { materialsStore, materialCategoryStore } = this.props
    const material = materialsStore.getInstanceById(materialId)
    const categoryName = materialCategoryStore.getCategoryNameById(
      material?.categoryId,
    )
    return categoryName || NO_VALUE
  }

  private getMaterialSubCategoryString = (materialId: string): string => {
    const { materialsStore } = this.props
    const materialProductName = materialsStore.getProductNameById(materialId)
    return materialProductName || NO_VALUE
  }

  private getPlannedInstallLocationString = (
    material: IDeliveryMaterial,
  ): string => {
    const { materialsStore, locationAttributesStore } = this.props
    const locationId = materialsStore
      .getInstanceById(material.materialId)
      ?.getProcurementDataById(material.procurementId)?.installLocationId
    return locationAttributesStore.getById(locationId)?.name || NO_VALUE
  }

  private getUnitString = (unitId: string): string => {
    const {
      delivery: { startDate, isDone },
      deliveryUnitsStore,
      projectDateStore,
    } = this.props
    const shouldShowDeletedAttributes =
      isDone || projectDateStore.isBeforeToday(startDate)
    const unit = deliveryUnitsStore.getInstanceById(
      unitId,
      shouldShowDeletedAttributes,
    )

    return unit?.name ? `(${unit.name})` : ''
  }

  private get truckString() {
    const { delivery, deliveryVehicleTypesStore, projectDateStore } = this.props
    const { startDate, truckSize, isDone } = delivery
    const shouldShowDeletedAttributes =
      isDone || projectDateStore.isBeforeToday(startDate)

    return deliveryVehicleTypesStore.getAttributeString(
      truckSize,
      shouldShowDeletedAttributes,
    )
  }

  private get equipmentString() {
    const {
      delivery,
      offloadingEquipmentsStore: equipmentStore,
      projectDateStore,
    } = this.props
    const { startDate, offloadingEquipments, isDone } = delivery
    const shouldShowDeletedAttributes =
      isDone || projectDateStore.isBeforeToday(startDate)

    return equipmentStore.getString(
      offloadingEquipments,
      shouldShowDeletedAttributes,
    )
  }

  private getSitemapName(defaultName: string, sitemapUrl: string) {
    const { sitemapsStore } = this.props
    const sitemap = sitemapsStore.list.find(s => s.filledImage === sitemapUrl)

    return sitemap?.name || defaultName
  }

  private get durationLabel(): string {
    const { startDate, endDate } = this.props.delivery
    const minutes = differenceInMinutes(new Date(endDate), new Date(startDate))
    return minutes < 60
      ? `${minutes} ${Localization.translator.min_shortMinutes}`
      : `${Math.ceil(minutes / 60)} ${Localization.translator.hr_shortHours}`
  }
}
