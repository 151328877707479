import html2canvas from 'html2canvas'

import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import Awaiter from '~/client/src/shared/utils/Awaiter'

import Colors from '~/client/src/shared/theme.module.scss'

const BORDER_WIDTH = 2

const TEXT_PADDING_X = 4
const TEXT_PADDING_Y = 2

export interface ITextboxImageProps {
  text: string
  fontSize: number
  isTextBoxShown: boolean
  isRichTextBox: boolean
  textboxesAwaiter?: Awaiter
  textboxesCache?: ICanvasImageCache
  color?: string
  textColor?: string
  cornerRadius?: number
}

export default class ImageRenderer {
  private div: HTMLDivElement = null

  public renderText(
    props: ITextboxImageProps,
    setImage?: (img: HTMLCanvasElement) => void,
  ) {
    const { textboxesCache } = props
    if (textboxesCache) {
      const key = this.generateKey(props)
      const image = textboxesCache[key]
      if (image) {
        if (setImage) {
          setImage(image)
        }
        return
      }
    }

    // convert DOM into image
    const promise = html2canvas(this.getHtmlText(props), {
      backgroundColor: null,
      scale: 1,
    }).then(canvas => {
      // show it inside Konva.Image
      if (setImage) {
        setImage(canvas)
      }
      if (this.div) {
        document.body.removeChild(this.div)
        this.div = null
      }

      if (textboxesCache) {
        const key = this.generateKey(props)
        textboxesCache[key] = canvas
      }
    })

    if (props.textboxesAwaiter) {
      props.textboxesAwaiter.add(promise)
    }
  }

  public generateKey(props: ITextboxImageProps) {
    return [
      props.text,
      props.isTextBoxShown,
      props.color,
      props.cornerRadius,
      props.fontSize,
      props.textColor,
    ].join(',')
  }

  private getHtmlText({
    text,
    isTextBoxShown,
    color,
    cornerRadius,
    fontSize,
    isRichTextBox,
    textColor,
  }: ITextboxImageProps) {
    this.div = document.createElement('div')
    this.div.innerHTML = text
    const { style } = this.div
    style.padding = `${TEXT_PADDING_Y}px ${TEXT_PADDING_X}px`
    style.fontSize = `${fontSize}px`
    style.position = 'absolute'
    style.textShadow = this.getTextStroke()
    if (isRichTextBox) {
      style.color = textColor || Colors.neutral0
    } else {
      style.color = color || Colors.neutral0
    }

    if (isTextBoxShown) {
      style.backgroundColor = Colors.neutral100
      style.border = `${BORDER_WIDTH}px solid ${color || Colors.neutral0}`
      style.borderRadius = `${cornerRadius}px`
    }

    document.body.append(this.div)

    return this.div
  }

  private getTextStroke(color: string = '#fff') {
    return (
      `1px 0 0 ${color}, -1px 0 0 ${color}, 0 1px 0 ${color}, 0 -1px 0 ${color}, ` +
      `1px 1px ${color}, -1px -1px 0 ${color}, 1px -1px 0 ${color}, -1px 1px 0 ${color}`
    )
  }
}
