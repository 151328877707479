import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import CategoryOfVariance, {
  CategoryOfVarianceTypesList,
} from '~/client/src/shared/models/CategoryOfVariance'
import Flag, { flagTypes } from '~/client/src/shared/models/Flag'
import Rfi, { rfiTypes } from '~/client/src/shared/models/Rfi'
import SafetyHazard, {
  SafetyHazardTypes,
} from '~/client/src/shared/models/SafetyHazard'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

// translated

interface IThreadTypes {
  slackBarStore?: SlackBarStore
}

enum ThreadModes {
  RFI = 'RFI',
  FLAG = 'Flag',
  SCHEDULE_COMMENT = 'Schedule Comment',
  CATEGORY_OF_VARIANCE = 'Category of Variance',
  SAFETY_HAZARD = 'Safety Hazard',
}
@inject('slackBarStore')
@observer
export default class ThreadTypes extends React.Component<IThreadTypes> {
  private threadMode: ThreadModes
  private doesThreadRequireType: boolean
  private typeCaption: string
  private threadTypeOptions: string[] = []

  private readonly rfiTypeOptions = [
    rfiTypes.CLARIFICATION_NEEDED,
    rfiTypes.INCORRECT_SPECIFICATION,
    rfiTypes.SUPERINTENDENT_NEEDED,
    rfiTypes.MATERIAL_QUESTION,
  ]

  private readonly flagTypeOptions = [
    flagTypes.SITE_NOT_READY,
    flagTypes.MATERIAL_PROBLEM,
    flagTypes.LABOR_ISSUE,
    flagTypes.QUALITY_ISSUE,
  ]

  private readonly safetyHazardTypeOptions = [
    SafetyHazardTypes.ELEVATED_WORK,
    SafetyHazardTypes.MATERIAL_HANDLING,
    SafetyHazardTypes.GROUND_DISTRIBUTION,
    SafetyHazardTypes.CRANE_OR_HEAVY_LIFT,
    SafetyHazardTypes.CONFINED_SPACE,
    SafetyHazardTypes.MOBILE_EQUIPMENT,
    SafetyHazardTypes.DELIVER,
    SafetyHazardTypes.LOTO,
    SafetyHazardTypes.HOT_WORK,
    SafetyHazardTypes.HAZARDOUS_MATERIAL,
    SafetyHazardTypes.HIGH_VOLTAGE,
    SafetyHazardTypes.HIGH_DECIBELS,
    SafetyHazardTypes.LIFTING,
    SafetyHazardTypes.CLOSE_QUARTERS,
  ]

  public render() {
    this.setThreadMode()

    const hideThreadTypeOptions =
      !this.threadMode || !this.doesThreadRequireType
    const hideThreadType =
      !this.threadMode || (this.threadMode && this.doesThreadRequireType)
    return (
      <div>
        <ul
          className={classList({
            'slack-bar-thread-type-list': true,
            hidden: hideThreadTypeOptions,
          })}
        >
          {this.getThreadHeader()}
          {this.getThreadTypeOptionElements()}
        </ul>
        <div
          className={classList({
            hidden: hideThreadType,
            pt10: true,
            pb10: true,
            'slack-bar-thread-selected-type': true,
          })}
        >
          {this.typeCaption}
        </div>
      </div>
    )
  }

  private setThreadMode() {
    const {
      isRfiMode,
      doesRfiRequireType,
      selectedRfiType,

      isFlagMode,
      doesFlagRequireType,
      selectedFlagType,

      isCategoryOfVarianceMode,
      doesSafetyHazardRequireType,
      selectedCategoryOfVarianceType,

      isSafetyHazardMode,
      doesCategoryOfVarianceRequireType,
      selectedSafetyHazardType,

      isScheduleCommentMode,
    } = this.props.slackBarStore

    this.threadMode = null
    switch (true) {
      case isRfiMode:
        this.threadMode = ThreadModes.RFI
        this.threadTypeOptions = this.rfiTypeOptions
        this.doesThreadRequireType = doesRfiRequireType
        this.typeCaption = Rfi.getRfiTypeCaption(selectedRfiType)
        break

      case isFlagMode:
        this.threadMode = ThreadModes.FLAG
        this.threadTypeOptions = this.flagTypeOptions
        this.doesThreadRequireType = doesFlagRequireType
        this.typeCaption = Flag.getFlagTypeCaption(selectedFlagType)
        break

      case isScheduleCommentMode:
        this.threadMode = ThreadModes.SCHEDULE_COMMENT
        this.doesThreadRequireType = false
        this.typeCaption = Localization.translator.scheduleComment.toUpperCase()
        break

      case isCategoryOfVarianceMode:
        this.threadMode = ThreadModes.CATEGORY_OF_VARIANCE
        this.threadTypeOptions = CategoryOfVarianceTypesList
        this.doesThreadRequireType = doesCategoryOfVarianceRequireType
        this.typeCaption = CategoryOfVariance.getCategoryOfVarianceTypeCaption(
          selectedCategoryOfVarianceType,
        )
        break

      case isSafetyHazardMode:
        this.threadMode = ThreadModes.SAFETY_HAZARD
        this.threadTypeOptions = this.safetyHazardTypeOptions
        this.doesThreadRequireType = doesSafetyHazardRequireType
        this.typeCaption = SafetyHazard.getSafetyHazardTypeCaption(
          selectedSafetyHazardType,
        )
        break
    }
  }

  private handleClick = type => {
    switch (this.threadMode) {
      case ThreadModes.RFI:
        this.props.slackBarStore.setRfiType(type)
        break
      case ThreadModes.FLAG:
        this.props.slackBarStore.setFlagType(type)
        break
      case ThreadModes.CATEGORY_OF_VARIANCE:
        this.props.slackBarStore.setCategoryOfVarianceType(type)
        break
      case ThreadModes.SAFETY_HAZARD:
        this.props.slackBarStore.setSafetyHazardType(type)
        break
    }
  }

  private getThreadTypeOptionElements() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let hintGetter = (option: string) => ''

    switch (this.threadMode) {
      case ThreadModes.RFI:
        hintGetter = Rfi.getDisplayType
        break
      case ThreadModes.FLAG:
        hintGetter = Flag.getDisplayType
        break
      case ThreadModes.CATEGORY_OF_VARIANCE:
        hintGetter = CategoryOfVariance.getDisplayType
        break
      case ThreadModes.SAFETY_HAZARD:
        hintGetter = SafetyHazard.getDisplayType
        break
    }
    return this.threadTypeOptions.map((option, index) => {
      return (
        <li
          className="slack-bar-thread-type"
          key={index}
          onClick={() => this.handleClick(option)}
        >
          <div
            className={classList({
              pa10: true,
            })}
          >
            <b>{this.threadModeToDisplay}</b>: {hintGetter(option)}
          </div>
        </li>
      )
    })
  }

  private get threadModeToDisplay() {
    switch (this.threadMode) {
      case ThreadModes.FLAG:
        return Localization.translator.flag
      case ThreadModes.RFI:
        return Localization.translator.rfi
      case ThreadModes.SCHEDULE_COMMENT:
        return Localization.translator.scheduleComment
      case ThreadModes.CATEGORY_OF_VARIANCE:
        return Localization.translator.categoryOfVariance
      case ThreadModes.SAFETY_HAZARD:
        return Localization.translator.safetyHazard
    }
  }

  private getThreadHeader() {
    const {
      isRfiMode,
      isFlagMode,
      isCategoryOfVarianceMode,
      isSafetyHazardMode,
    } = this.props.slackBarStore

    return (
      <li className="pa10 row text primary-blue extra-large bold uppercase slack-bar-thread-type thread-type-first">
        <div className="col no-grow pr20 thread-type-first-svg">
          {isFlagMode && <Icons.Flag className="icon-red" />}
          {isRfiMode && <Icons.Rfi />}
          {isCategoryOfVarianceMode && <Icons.Variance />}
          {isSafetyHazardMode && <Icons.Safety />}
        </div>
        <div className="col">
          {isFlagMode && Localization.translator.addReasonForFlag}
          {isRfiMode && Localization.translator.addReasonForRfi}
          {isCategoryOfVarianceMode &&
            Localization.translator.addReasonForCategoryOfVariance}
          {isSafetyHazardMode &&
            Localization.translator.addReasonForSafetyHazard}
        </div>
      </li>
    )
  }
}
