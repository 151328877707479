import * as React from 'react'

import { inject, observer } from 'mobx-react'

import CompaniesStore from '../../../stores/domain/Companies.store'
import CompanyProfilePreview from '../../CompanyProfilePreview/CompanyProfilePreview'
import { ISharedProps } from '../BaseStruxhubInput'
import BaseStruxhubSelector from './BaseStruxhubSelector'

// localization: no text to translate

interface IProps extends ISharedProps {
  onClick?: () => void

  companiesStore?: CompaniesStore
}

@inject('companiesStore')
@observer
export default class StruxhubCompanySelector extends React.Component<IProps> {
  public render() {
    const { companiesStore, value } = this.props
    const company = companiesStore.getCompanyById(value)

    return (
      <BaseStruxhubSelector {...this.props} selectorClassName="h56">
        {company && (
          <CompanyProfilePreview
            company={company}
            isPhoneHidden={true}
            isMenuHidden={true}
            shouldUseExtraLargeFont={true}
          />
        )}
      </BaseStruxhubSelector>
    )
  }
}
