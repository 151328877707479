import * as React from 'react'

import { inject, observer } from 'mobx-react'

import ActivityDetails from '~/client/src/desktop/components/ActivityDetails/ActivityDetails'
import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DesktopPhotoDetails from '~/client/src/desktop/components/PhotoDetails/DesktopPhotoDetails'
import ResponsibilitiesPanel from '~/client/src/desktop/components/ResponsibilitiesPanel/ResponsibilitiesPanel'
import BarState from '~/client/src/desktop/enums/SideBarState'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import DesktopFlagView from '~/client/src/desktop/views/SimpleGanttView/components/SideBar/views/DesktopFlagView'
import DesktopRfiView from '~/client/src/desktop/views/SimpleGanttView/components/SideBar/views/DesktopRfiView'
import DesktopStatusUpdateMessagesView from '~/client/src/desktop/views/SimpleGanttView/components/SideBar/views/DesktopStatusUpdateMessagesView'
import BulkStatusUpdateComponent from '~/client/src/shared/components/BulkStatusUpdate/BulkStatusUpdate'
import CompactDeliveriesView from '~/client/src/shared/components/Deliveries/DeliveriesView'
import DeliveryDetails from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import ErrorBoundary from '~/client/src/shared/components/ErrorBoundary'
import Spinner from '~/client/src/shared/components/Spinner/Spinner'
import ActivityAssignmentsStore from '~/client/src/shared/stores/domain/ActivityAssignments.store'

import ActivityBulkEditor from '../ActivityGanttOrListView/components/BulkEditor/ActivityBulkEditor'
import DesktopCategoryOfVarianceView from './views/DesktopCategoryOfVarianceView'
import DesktopSafetyHazardView from './views/DesktopSafetyHazardView'
import DesktopScheduleCommentView from './views/DesktopScheduleCommentView'

import './SideBar.scss'

interface ISideBarProps {
  activityListStore: DesktopActivityListStore
  inline?: boolean
  shouldPreventScrollToBottom?: boolean

  state?: DesktopInitialState
  common?: DesktopCommonStore
  activityAssignmentsStore?: ActivityAssignmentsStore
  deliveryDetailsStore?: DeliveryDetailsStore
}

@inject('state', 'common', 'activityAssignmentsStore', 'deliveryDetailsStore')
@observer
export default class SideBar extends React.Component<ISideBarProps> {
  public render() {
    const { state, activityListStore, inline, activityAssignmentsStore } =
      this.props

    const {
      shouldShowResponibilitiesPanel,
      shouldShowBulkEditPanel,
      isActivityDetailsPanelDisplayed,
    } = state.activityList
    const {
      multiSelection,
      selectedActivities,
      isAddActivityDialogShown,
      toggleResponsibilitiesPanel,
      toggleBulkEditPanel,
      hideAddActivityDialog,
    } = activityListStore

    const sideBarContent =
      (shouldShowResponibilitiesPanel && (
        <ResponsibilitiesPanel
          selectedEntitiesIds={multiSelection}
          onClose={toggleResponsibilitiesPanel}
          domainStore={activityAssignmentsStore}
        />
      )) ||
      (shouldShowBulkEditPanel && (
        <ActivityBulkEditor
          selectedActivities={selectedActivities}
          onClose={toggleBulkEditPanel}
        />
      )) ||
      (isActivityDetailsPanelDisplayed && this.renderActivityDetailsBar()) ||
      (isAddActivityDialogShown && (
        <ActivityDetails backClicked={hideAddActivityDialog} />
      ))

    const openingClass = sideBarContent ? 'opened' : 'closed'
    const inlineClass = inline ? 'inline' : ''

    return (
      // classList wasnt behaving as expected, hence strange classList
      <div className={`gant-side-bar ${openingClass} ${inlineClass}`}>
        <ErrorBoundary>
          <>
            {this.renderOpacityBackground()}
            {sideBarContent}
          </>
        </ErrorBoundary>
      </div>
    )
  }

  public renderOpacityBackground() {
    const { isLoading, sideBarState } = this.props.state
    // DeliveryDetails has its own loader so this one can be omitted
    if (isLoading && sideBarState !== BarState.DeliveryDetails) {
      return (
        <div className="loader-holder full-height full-width">
          <Spinner className="loader" size={50} />
        </div>
      )
    }
  }

  public renderActivityDetailsBar() {
    const {
      common,
      state,
      shouldPreventScrollToBottom,
      deliveryDetailsStore: { displayedDelivery },
    } = this.props
    const { toActivityDetails, selectedActivity } = common
    const { displayedContentObjectId, sideBarState, activeProject } = state

    if (sideBarState === BarState.Rfi) {
      return (
        <DesktopRfiView
          contentObjectId={displayedContentObjectId}
          goBack={toActivityDetails}
        />
      )
    }

    if (sideBarState === BarState.Flag) {
      return (
        <DesktopFlagView
          contentObjectId={displayedContentObjectId}
          goBack={toActivityDetails}
        />
      )
    }

    if (sideBarState === BarState.CategoryOfVariance) {
      return (
        <DesktopCategoryOfVarianceView
          contentObjectId={displayedContentObjectId}
          goBack={toActivityDetails}
        />
      )
    }

    if (sideBarState === BarState.SafetyHazard) {
      return (
        <DesktopSafetyHazardView
          contentObjectId={displayedContentObjectId}
          goBack={toActivityDetails}
        />
      )
    }

    if (sideBarState === BarState.Photo) {
      return <DesktopPhotoDetails photoId={displayedContentObjectId} />
    }

    if (sideBarState === BarState.StatusUpdateMessages) {
      return (
        <DesktopStatusUpdateMessagesView
          statusUpdateId={displayedContentObjectId}
          goBack={toActivityDetails}
        />
      )
    }

    if (sideBarState === BarState.ScheduleComment) {
      return (
        <DesktopScheduleCommentView
          contentObjectId={displayedContentObjectId}
          goBack={toActivityDetails}
        />
      )
    }

    if (sideBarState === BarState.DeliveryCalendar) {
      return (
        <CompactDeliveriesView
          className="no-select"
          shouldUseSavedFilters={true}
          FileInputType={DesktopFileInput}
          shouldHandleMouse={true}
        />
      )
    }

    if (sideBarState === BarState.DeliveryDetails) {
      return (
        <DeliveryDetails
          FileInputType={DesktopFileInput}
          initProjectId={activeProject.id}
          displayedDeliveryId={displayedDelivery?.id}
        />
      )
    }

    const selectedActivityId = selectedActivity && selectedActivity.id
    const { selectedCompany } = state.activityList

    if (sideBarState === BarState.BulkStatusUpdate) {
      return (
        <BulkStatusUpdateComponent
          activityId={selectedActivityId}
          company={selectedCompany}
        />
      )
    }

    return (
      (selectedActivityId && (
        <ActivityDetails
          activityId={selectedActivityId}
          shouldPreventScrollToBottom={shouldPreventScrollToBottom}
        />
      )) ||
      false
    )
  }
}
