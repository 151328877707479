import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import DeliveryForm from '~/client/src/shared/components/DeliveryDetails/components/DeliveryForm'
import DeliverySelectModal from '~/client/src/shared/components/DeliveryDetails/components/DeliverySelectModal/DeliverySelectModal'
import ConstraintsStore from '~/client/src/shared/stores/domain/Constraints.store'

const deliveryFormPreview = 'Delivery form preview'

interface IProps {
  deliveryDetailsStore?: DeliveryDetailsStore
  shouldListenToConstaints?: boolean
  constraintsStore?: ConstraintsStore
}

@inject('deliveryDetailsStore', 'constraintsStore')
@observer
export default class DeliveryDetailsPreview extends React.Component<IProps> {
  private readonly formId: string = 'delivery-form-preview'

  public constructor(props: IProps) {
    super(props)

    props.deliveryDetailsStore.setNewDeliveryMode()
    if (props.shouldListenToConstaints) {
      props.constraintsStore
        .loadConstraints()
        .then(() => props.deliveryDetailsStore.checkIfDeliveryAttributesValid)
    }
  }

  public componentDidMount(): void {
    const { shouldListenToConstaints, deliveryDetailsStore, constraintsStore } =
      this.props
    if (shouldListenToConstaints) {
      constraintsStore.listenToConstraints(
        deliveryDetailsStore.checkIfDeliveryAttributesValid,
      )
    }
  }

  public componentWillUnmount() {
    if (this.props.shouldListenToConstaints) {
      this.props.constraintsStore.dontListenToConstraints()
    }
  }

  public render() {
    const { deliveryDetailsStore } = this.props

    return (
      <div className="delivery-details-preview no-grow col overflow-hidden">
        <div className="py30 text white center small-header bold">
          {deliveryFormPreview}
        </div>
        <div className="delivery-details-content fields-container full-height mx24 relative overflow-hidden">
          <DeliverySelectModal store={deliveryDetailsStore} />
          <div className="full-height overflow-auto">
            <DeliveryForm
              formId={this.formId}
              store={deliveryDetailsStore}
              FileInputType={DesktopFileInput}
              shouldDisableInnerForm={false}
            />
          </div>
        </div>
      </div>
    )
  }
}
