import { computed, observable } from 'mobx'

import {
  IPermitFieldsInput,
  ISitePermitInspection,
  ISitePermitInspectionInput,
} from '~/client/graph'

import getPermitFieldsDto from '../utils/getPermitFieldsDto'
import { copyObjectDeep } from '../utils/util'
import IPermitFieldDto from './IPermitFieldDto'
import PermitField from './PermitField'

export default class PermitInspection {
  public static fromDto(dto: ISitePermitInspection) {
    return new PermitInspection(
      dto.id,
      dto.permitId,
      dto.projectId,
      dto.userId,
      dto.createdAt,
      dto.fields,
      dto.inspectionDate,
    )
  }

  @observable public permitId: string
  @observable public projectId: string
  @observable public userId: string
  @observable public fields: PermitField[]
  @observable public createdAt?: number
  @observable public inspectionDate?: number

  public constructor(
    public id: string,
    permitId: string,
    projectId: string,
    userId: string,
    createdAt?: number,
    fields?: IPermitFieldDto[],
    inspectionDate?: number,
  ) {
    this.permitId = permitId
    this.projectId = projectId
    this.userId = userId

    this.createdAt = createdAt
    this.inspectionDate = inspectionDate

    this.fields = fields?.map(field => PermitField.fromDto(field)) || []
  }

  @computed
  public get fieldsByIdMap(): { [fieldId: string]: PermitField } {
    return this.fields.reduce((map, field) => {
      map[field.fieldId] = field
      return map
    }, {})
  }

  public copy(): PermitInspection {
    const newObj = new PermitInspection(
      this.id,
      this.permitId,
      this.projectId,
      this.userId,
      this.createdAt,
      null,
      this.inspectionDate,
    )
    newObj.fields = this.fields?.map(f => copyObjectDeep(f)) || []

    return newObj
  }

  public getDto(): ISitePermitInspectionInput {
    return {
      id: this.id,
      permitId: this.permitId,
      projectId: this.projectId,
      userId: this.userId,
      inspectionDate: this.inspectionDate,
    }
  }

  public getFieldsDto(): IPermitFieldsInput {
    return getPermitFieldsDto(this.fields)
  }
}
