import * as React from 'react'

import { classList } from 'react-classlist-helper'

import { NOOP } from '~/client/src/shared/utils/noop'

import './ActionHandleButton.scss'

// localization: no display text to translate

interface IProps {
  title: string
  onClick: () => void
  isEnabled: boolean
}

export default class ActionHandleButton extends React.PureComponent<IProps> {
  public static defaultProps = {
    onClick: NOOP,
  }

  public render() {
    const { title, onClick, isEnabled } = this.props

    return (
      <button
        className={classList({
          'action-handle-button brada5 ba-light-cool-grey text': true,
          'light large no-outline pointer': true,
          enabled: isEnabled,
          'not-allowed': !isEnabled,
        })}
        onClick={onClick}
      >
        {title}
      </button>
    )
  }
}
