import { IBasemap } from '~/client/graph'

import BaseModel from './BaseModel'

export default class Basemap extends BaseModel<IBasemap> implements IBasemap {
  public static fromDto(dto: IBasemap) {
    return new Basemap(
      dto.id,
      dto.name,
      dto.projectId,
      dto.fileName,
      dto.source,
      dto.createdAt,
      dto.updatedAt,
    )
  }

  public constructor(
    id: string,
    public name: string,
    public readonly projectId: string,
    public fileName: string,
    public source: string,
    createdAt?: number,
    updatedAt?: number,
  ) {
    super(id)
    this.setCreatedAt(createdAt)
    this.setUpdatedAt(updatedAt)
  }

  public setSource(source: string) {
    this.source = source
  }

  public setFileName(fileName: string) {
    this.fileName = fileName
  }

  public setName(name: string) {
    this.name = name
  }
}
