import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import ActivityGanttOrListViewStore from '../../ActivityGanttOrListView.store'
import ActivityDaysCell from './ActivityDaysCell'
import SimpleGanttChartViewStore from './SimpleGanttChartViewStore'

interface IGanttChartHeaderProps {
  simpleGanttChartViewStore: SimpleGanttChartViewStore
  store: ActivityGanttOrListViewStore
  activityListStore: DesktopActivityListStore

  state?: DesktopInitialState
}

const PPC = 'PPC'

@inject('state')
@observer
export default class GanttChartScrollableHeader extends React.Component<IGanttChartHeaderProps> {
  public render() {
    const { simpleGanttChartViewStore, store, activityListStore } = this.props
    const { days } = simpleGanttChartViewStore

    return (
      <div className="col gantt-header-container stretched">
        <div className="header-row row">
          <div className="full-width gant-activity-col">
            {days.map(
              (
                { isWeekend, dayName, monthAndDay, isToday, isSaturday, ppc },
                i,
              ) => {
                return (
                  <div
                    key={i}
                    className={classList({
                      'gant-header-day flex-item text center gantt-cell': true,
                      weekend: isWeekend,
                    })}
                  >
                    {dayName}
                    <br />
                    {monthAndDay}
                    <br />
                    {isSaturday && this.renderPPC(ppc)}
                    {isToday && (
                      <div className="gant-header-day-circle">&#x25CF;</div>
                    )}
                  </div>
                )
              },
            )}
          </div>
        </div>

        <div className="bulk-action-row row">
          <div className="full-width gant-activity-col">
            <ActivityDaysCell
              activityListStore={activityListStore}
              simpleGanttChartViewStore={simpleGanttChartViewStore}
              store={store}
            />
          </div>
        </div>
      </div>
    )
  }

  private renderPPC(ppc: number): JSX.Element {
    return (
      !this.props.state.isWWPColoringDisabled && (
        <span className="text bold blue-highlight">
          {PPC} {ppc === null ? NO_VALUE : `${ppc}%`}
        </span>
      )
    )
  }
}
