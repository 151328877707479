import * as React from 'react'

import { action, observable } from 'mobx'
import { MultiGrid } from 'react-virtualized'

import NavigationArrows from './components/NavigationArrows/NavigationArrows'

import './ListWithFixedColumns.scss'

export interface IBaseListWithFixedColumns {
  fixedColumnsCount: number
  columnsCount: number
}

export const SCROLLING_RESET_TIME_INTERVAL = 10
export const NO_SCROLL = -1
const NESTED_GRIDS_COUNT = 4
const SCROLL_STOP_INTERVAL = SCROLLING_RESET_TIME_INTERVAL * NESTED_GRIDS_COUNT

export default abstract class BaseListWithFixedColumns<
  T extends IBaseListWithFixedColumns,
> extends React.Component<T> {
  @observable protected scrollToColumn: number = NO_SCROLL
  @observable protected scrollToRow: number = NO_SCROLL
  @observable protected shouldShowScrollToStartArrow: boolean = false
  @observable protected shouldShowSelectionPopup: boolean = false
  @observable protected shouldRenderArrow: boolean = true

  protected grid: MultiGrid = null
  protected scrollTop: number = 0
  protected scrollingTimeout: any = null
  protected isHorizontalScroll: boolean = false

  public render() {
    return (
      <>
        {this.renderSelectionPopUp()}
        {this.shouldRenderArrow && this.scrollToColumn > 0 && (
          <NavigationArrows
            shouldShowLeftArrow={this.shouldShowScrollToStartArrow}
            scrollToStart={this.scrollToStart}
            scrollToEnd={this.scrollToEnd}
          />
        )}
        {this.renderGrid()}
      </>
    )
  }

  protected onScrollbarPresenceChange = ({ horizontal }) => {
    this.shouldRenderArrow = horizontal
  }

  protected renderSelectionPopUp(): JSX.Element {
    return
  }

  protected renderGrid(): JSX.Element {
    return
  }

  @action.bound
  protected onScroll = ({
    scrollTop,
    scrollLeft,
    clientWidth,
    scrollWidth,
  }: any) => {
    if (!clientWidth) {
      this.scrollToRow = NO_SCROLL
      return
    }

    const prevState = this.shouldShowScrollToStartArrow
    this.shouldShowScrollToStartArrow =
      scrollLeft > (scrollWidth - clientWidth) / 2

    if (prevState !== this.shouldShowScrollToStartArrow) {
      this.scrollToColumn = NO_SCROLL
    }

    if (scrollTop === this.scrollTop) {
      this.isHorizontalScroll = true
      return
    }

    this.scrollTop = scrollTop
    this.isHorizontalScroll = false
    clearTimeout(this.scrollingTimeout)
    this.scrollingTimeout = setTimeout(this.onScrollStop, SCROLL_STOP_INTERVAL)
  }

  protected onScrollStop = () => {
    if (!this.isScrolling) {
      return this.recomputeGridSize()
    }

    this.scrollingTimeout = setTimeout(this.onScrollStop, SCROLL_STOP_INTERVAL)
  }

  @action.bound
  protected toggleSelectionPopup() {
    this.shouldShowSelectionPopup = !this.shouldShowSelectionPopup
  }

  @action.bound
  protected scrollToEnd() {
    this.scrollToColumn = this.props.columnsCount - 1
  }

  @action.bound
  protected scrollToStart() {
    this.scrollToColumn = this.props.fixedColumnsCount
  }

  /**
   * Override react-virtualized MultiGrid scrolling
   * to detect scrolling of whole MultiGrid rather than active component part
   */
  protected get isScrolling() {
    const {
      _bottomLeftGrid: bottomLeftGrid,
      _topLeftGrid: topLeftGrid,
      _bottomRightGrid: bottomRightGrid,
      _topRightGrid: topRightGrid,
    } = this.grid

    return (
      (bottomLeftGrid && bottomLeftGrid._isScrolling()) ||
      (topLeftGrid && topLeftGrid._isScrolling()) ||
      (bottomRightGrid && bottomRightGrid._isScrolling()) ||
      (topRightGrid && topRightGrid._isScrolling())
    )
  }

  /**
   * To force updating nested not-observer react-virtualized components
   */
  protected updateGrid() {
    this.grid.forceUpdateGrids()
  }

  /**
   * To force rows' height recalculation
   */
  protected recomputeGridSize = () => {
    this.grid.recomputeGridSize()
  }
}
