import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IReactionDisposer } from 'mobx'
import { inject, observer } from 'mobx-react'

import DaySeparator from '~/client/src/shared/components/DaySeparator'
import * as Icons from '~/client/src/shared/components/Icons'
import * as Layout from '~/client/src/shared/components/Layout'
import { Loader } from '~/client/src/shared/components/Loader'
import DeliveryStatus, {
  formatStatusToDisplay,
} from '~/client/src/shared/constants/DeliveryStatus'
import Delivery from '~/client/src/shared/models/Delivery'
import Photo from '~/client/src/shared/models/Photo'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import BuildingsStore from '~/client/src/shared/stores/domain/Buildings.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryStatusChangesStore from '~/client/src/shared/stores/domain/DeliveryStatusChanges.store'
import GatesStore from '~/client/src/shared/stores/domain/Gates.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import PhotosStore from '~/client/src/shared/stores/domain/Photos.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import RoutesStore from '~/client/src/shared/stores/domain/Routes.store'
import ZonesStore from '~/client/src/shared/stores/domain/Zones.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import NavBar from '../../components/NavBar/NavBar'
import DesktopInitialState from '../../stores/DesktopInitialState'
import PhotosPageStore from './PhotosPageStore.store'
import PhotosHeaderBar from './components/PhotosHeaderBar'
import DeliveriesPhotosSideBar from './components/PhotosSideBar'

import './Photos.scss'

export const PHOTOS_PER_PAGE = 10

const thereIsNoPhotoPassingFilters = 'There is no photo passing filters'

export interface IProps {
  state?: DesktopInitialState
  projectsStore?: ProjectsStore
  gatesStore?: GatesStore
  zonesStore?: ZonesStore
  photosStore?: PhotosStore
  messagesStore?: MessagesStore
  deliveriesStore?: DeliveriesStore
  eventsStore?: EventsStore
  deliveryStatusChangesStore?: DeliveryStatusChangesStore
  projectDateStore?: ProjectDateStore
  routesStore?: RoutesStore
  buildingsStore?: BuildingsStore
  companiesStore?: CompaniesStore
  projectMembersStore?: ProjectMembersStore
}

@inject(
  'state',
  'projectsStore',
  'gatesStore',
  'zonesStore',
  'photosStore',
  'messagesStore',
  'deliveriesStore',
  'eventsStore',
  'deliveryStatusChangesStore',
  'projectDateStore',
  'routesStore',
  'buildingsStore',
  'companiesStore',
  'projectMembersStore',
)
@observer
export default class Photos extends React.Component<IProps> {
  private disposePresentationReaction: IReactionDisposer
  private readonly photoPageStore: PhotosPageStore

  public constructor(props: IProps) {
    super(props)
    this.photoPageStore = new PhotosPageStore(
      props.projectsStore,
      props.state,
      props.gatesStore,
      props.routesStore,
      props.buildingsStore,
      props.companiesStore,
      props.zonesStore,
      props.photosStore,
      props.messagesStore,
      props.deliveriesStore,
      props.eventsStore,
      props.deliveryStatusChangesStore,
      props.projectDateStore,
      props.projectMembersStore,
    )

    props.deliveryStatusChangesStore.loadDeliveriesStatusChanges()
  }

  public componentDidMount(): void {
    const { deliveryStatusChangesStore } = this.props
    deliveryStatusChangesStore.listenToDeliveryStatusChanges()
  }

  public componentWillUnmount() {
    if (this.disposePresentationReaction) {
      this.disposePresentationReaction()
    }
    this.props.deliveryStatusChangesStore.dontListenToDeliveryStatusChanges()
  }

  public render() {
    const { isLoading } = this.props.state
    const { isDeliveriesModeActive } = this.photoPageStore

    return (
      <Layout.View>
        <Layout.Header className="layout-navigation">
          <NavBar />
        </Layout.Header>
        <Layout.Content>
          <div className="relative-block">
            <div className="relative-block overflow-hidden">
              <div className="photos-holder">
                {isLoading ? (
                  <Loader size={25} hint="Loading" />
                ) : (
                  <>
                    <PhotosHeaderBar photosStore={this.photoPageStore} />
                    <div className="photos-content full-width">
                      {isDeliveriesModeActive && (
                        <div className="deliveries-photos row full-height">
                          <DeliveriesPhotosSideBar
                            store={this.photoPageStore}
                          />
                          <div className="full-height">
                            <div className="deliveries-photos-content pl20 mr5 overflow-auto">
                              {this.renderDeliveryPhotos()}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Layout.Content>
        <Layout.Footer />
      </Layout.View>
    )
  }

  private shouldShowPhoto(index: number) {
    return (
      index >= this.firstPhotoIndexOnPage && index <= this.lastPhotoIndexOnPage
    )
  }

  private shouldShowAnyPhotoFromInterval(indexMin: number, indexMax: number) {
    return (
      (indexMax >= this.firstPhotoIndexOnPage &&
        indexMin <= this.lastPhotoIndexOnPage) ||
      (this.lastPhotoIndexOnPage >= indexMin &&
        this.firstPhotoIndexOnPage <= indexMax)
    )
  }

  private renderDeliveryPhotos() {
    const { filteredPhotosByDays, photosCount } = this.photoPageStore

    if (!photosCount || !filteredPhotosByDays.length) {
      return this.renderNoPhotosMessage()
    }

    const { isDateFilterActive } = this.photoPageStore.dateFilters
    const { getWeekdayMonthDayAndYearToDisplay } = this.props.projectDateStore
    let photoIndex = -1
    return filteredPhotosByDays.map((messageByDay, i) => {
      const { day, deliveriesPhotos } = messageByDay

      if (!deliveriesPhotos.length) {
        return
      }

      // At that moment 'photoIndex' is the index of the last photo from the previous day
      // so 'photoIndex + 1' is the first photo from the new day
      const dayFirstPhotoIndex = photoIndex + 1
      let dayLastPhotoIndex = photoIndex
      deliveriesPhotos.forEach(deliveryPhotos => {
        dayLastPhotoIndex += deliveryPhotos.photos.length
      })
      if (
        !isDateFilterActive &&
        !this.shouldShowAnyPhotoFromInterval(
          dayFirstPhotoIndex,
          dayLastPhotoIndex,
        )
      ) {
        photoIndex = dayLastPhotoIndex
        return
      }

      return (
        <div key={i}>
          <DaySeparator
            date={day}
            customDateTimeFormatter={getWeekdayMonthDayAndYearToDisplay}
            leftOffset="0"
            bottomOffset="0"
            shouldHideBorder={true}
            shouldUseBlueColor={true}
          />
          {deliveriesPhotos.map(deliveryPhotos => {
            const { delivery, photos } = deliveryPhotos

            const deliveryFirstPhotoIndex = photoIndex + 1
            const deliveryLastPhotoIndex = photoIndex + photos.length
            if (
              !isDateFilterActive &&
              !this.shouldShowAnyPhotoFromInterval(
                deliveryFirstPhotoIndex,
                deliveryLastPhotoIndex,
              )
            ) {
              photoIndex = deliveryLastPhotoIndex
              return
            }
            return (
              <div className="col" key={delivery.id}>
                {this.renderDeliveryInformation(delivery)}
                <div className="row pl10 w-fit-content photo-row wrap">
                  {photos.map((photo, index) => {
                    photoIndex += 1
                    return isDateFilterActive ||
                      this.shouldShowPhoto(photoIndex)
                      ? this.renderPhoto(photos, delivery, index)
                      : null
                  })}
                </div>
              </div>
            )
          })}
        </div>
      )
    })
  }

  private renderNoPhotosMessage() {
    return (
      <div className="col x-center y-center pt10 bt-light-grey full-height">
        <div className="row">
          <Icon
            icon="warning-sign"
            iconSize={20}
            className="warning-icon mr5"
          />
          {thereIsNoPhotoPassingFilters}
        </div>
      </div>
    )
  }

  private renderDeliveryInformation(delivery: Delivery) {
    const { company, startDate, gate, zone } = delivery
    const { getMonthDayAndTimeToDisplay } = this.props.projectDateStore
    const { getCompanyNameById } = this.props.companiesStore
    const companyName = getCompanyNameById(company)
    return (
      <div className="text large bold pb10 row w-fit-content">
        <div className="no-white-space-wrap pr20">{companyName}</div>
        <div className="no-white-space-wrap pr20">
          {getMonthDayAndTimeToDisplay(startDate)}
        </div>
        <div className="no-white-space-wrap pr20">{this.getGateName(gate)}</div>
        <div className="no-white-space-wrap pr20">{this.getZoneName(zone)}</div>
      </div>
    )
  }

  private renderPhoto(photos: Photo[], delivery: Delivery, index: number) {
    const { getCompanyNameById } = this.props.companiesStore
    const { messageId, url, thumbUrl, id: photoId } = photos[index]
    const { company, gate, zone } = delivery

    const messagesIds = photos.map(p => p.messageId)
    const companyName = getCompanyNameById(company)

    const status = this.photoPageStore.getStatus(delivery, messageId)
    return (
      <div className="col px5 full-height item" key={photoId}>
        <div className="photo-holder relative row x-center y-center">
          <img
            onClick={this.onImageClick.bind(
              this,
              messagesIds,
              index,
              status,
              companyName,
              gate,
              zone,
            )}
            src={url || thumbUrl}
          />
        </div>
        <div className="row x-between">
          {this.getIcon(status)}
          {this.photoPageStore.shouldAddCommentIcon(messageId) && (
            <div className="w-fit-content">
              <Icons.Note />
            </div>
          )}
        </div>
      </div>
    )
  }

  private onImageClick(
    messagesIds: string[],
    index: number,
    status: DeliveryStatus,
    company: string,
    gateId: string,
    zoneId: string,
  ) {
    this.photoPageStore.openPhoto(
      messagesIds,
      index,
      status,
      company,
      this.getGateName(gateId),
      this.getZoneName(zoneId),
    )
  }

  private getZoneName(zoneId: string): string {
    const { getString } = this.props.zonesStore
    return getString(zoneId, true)
  }

  private getGateName(gateId: string): string {
    const { getString } = this.props.gatesStore
    return getString(gateId, true)
  }

  private getIcon(status: DeliveryStatus) {
    if (!status) {
      return null
    }

    let icon: JSX.Element
    switch (status) {
      case DeliveryStatus.IncompleteDone:
      case DeliveryStatus.Done:
        icon = <Icons.DeliveryDone />
        break
      case DeliveryStatus.Delivering:
        icon = <Icons.DeliveryOnsite />
        break
      case DeliveryStatus.OnHold:
        icon = <Icons.DeliveryOnsite />
        break
      case DeliveryStatus.OnSite:
        icon = <Icons.DeliveryOnsite />
        break
      case DeliveryStatus.PassedInspection:
        icon = <Icons.DeliveryPassedInspection />
        break
      case DeliveryStatus.Requested:
        icon = <Icons.DeliveryRequested />
        break
      case DeliveryStatus.Scheduled:
      case DeliveryStatus.Changed:
        icon = <Icons.Delivery />
        break
      case DeliveryStatus.FailedInspection:
      case DeliveryStatus.Denied:
        icon = <Icons.DeliveryFailedInspection />
        break
      case DeliveryStatus.Paused:
        icon = <Icons.DeliveryFailedInspection />
        break
      case DeliveryStatus.Canceled:
        icon = <Icons.Delivery />
        break
      default:
        return null
    }
    return this.getStatus(icon, formatStatusToDisplay(status))
  }

  private getStatus(icon: JSX.Element, text: string) {
    return (
      <div className="row w-fit-content py5 photo-description">
        {icon}
        <div className="pl5 no-white-space-wrap">{text}</div>
      </div>
    )
  }

  private get firstPhotoIndexOnPage(): number {
    return this.photoPageStore.selectedPage * PHOTOS_PER_PAGE
  }

  private get lastPhotoIndexOnPage(): number {
    return (this.photoPageStore.selectedPage + 1) * PHOTOS_PER_PAGE - 1
  }
}
