import Localization from '../localization/LocalizationManager'
import { LogisticItemApp } from '../models/ILogisticItem'

export const appTypeToSortIndexMap = {
  [LogisticItemApp.ANNOUNCEMENT]: 1,
  [LogisticItemApp.DELIVERY]: 2,
  [LogisticItemApp.FORM]: 3,
  [LogisticItemApp.SCHEDULE]: 4,
  [LogisticItemApp.CLOSURE]: 5,
  [LogisticItemApp.SENSOR]: 6,
}

export function getAppSectionName(app: LogisticItemApp) {
  switch (app) {
    case LogisticItemApp.SCHEDULE:
      return Localization.translator.schedule
    case LogisticItemApp.ANNOUNCEMENT:
      return Localization.translator.announcements
    case LogisticItemApp.FORM:
      return Localization.translator.forms
    case LogisticItemApp.DELIVERY:
      return Localization.translator.deliveries
    case LogisticItemApp.CLOSURE:
      return Localization.translator.closures
    case LogisticItemApp.SENSOR:
      return Localization.translator.sensors
    default:
      throw new Error('Unsupported app')
  }
}
