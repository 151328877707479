import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { Loader } from '~/client/src/shared/components/Loader'

import SitePermitsStore from '../../../stores/domain/SitePermits.store'
import PermitLogActionBarStore from '../../PermitLogActionBar.store'
import SitePermitCreationFormStore from '../SitePermitCreationForm.store'

const updating = 'Updating'
const uploadingAttachment = 'Uploading attachment'
const downloadingReport = 'Downloading report'
const deletingTheForm = 'Deleting the form'

const LOADER_SIZE = 40

interface IProps {
  permitCreationStore: SitePermitCreationFormStore
  permitActionBarStore?: PermitLogActionBarStore

  sitePermitsStore?: SitePermitsStore
}

@inject('sitePermitsStore')
@observer
export default class PermitFormLoader extends React.Component<IProps> {
  public static defaultProps = {
    permitActionBarStore: {},
  }

  public render() {
    if (!this.shouldShowLoader) {
      return null
    }

    return (
      <div className="full-height full-width absolute modal-overlay">
        <Loader
          className="col x-center y-center full-height full-width"
          size={LOADER_SIZE}
          hint={`${this.hint}...`}
        />
      </div>
    )
  }

  private get shouldShowLoader(): boolean {
    const { permitCreationStore, permitActionBarStore, sitePermitsStore } =
      this.props
    return (
      permitCreationStore.isLoading ||
      permitActionBarStore.isFileUploading ||
      sitePermitsStore.isDeleting
    )
  }

  private get hint(): string {
    if (!this.shouldShowLoader) return null

    const { permitCreationStore, permitActionBarStore, sitePermitsStore } =
      this.props
    const { isPermitUpdating, isAttachmentUploading, isReportDownloading } =
      permitCreationStore

    switch (true) {
      case isReportDownloading:
        return downloadingReport
      case sitePermitsStore.isDeleting:
        return deletingTheForm
      case isPermitUpdating &&
        (isAttachmentUploading || permitActionBarStore.isFileUploading):
        return uploadingAttachment
      default:
        return updating
    }
  }
}
