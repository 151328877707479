import Localization from '../../shared/localization/LocalizationManager'

enum LogisticActionTypes {
  ASSIGN = 'ASSIGN',
  SUBSCRIBE = 'SUBSCRIBE',
  DELETE = 'DELETE',
}

export const getLogisticActionTypeDisplayName = (type: LogisticActionTypes) => {
  switch (type) {
    case LogisticActionTypes.ASSIGN:
      return Localization.translator.assign_verb
    case LogisticActionTypes.SUBSCRIBE:
      return Localization.translator.subscribe_verb
    case LogisticActionTypes.DELETE:
      return Localization.translator.delete
    default:
      throw new Error(type + ' is unhandled')
  }
}

export default LogisticActionTypes
