import React from 'react'

import { inject, observer } from 'mobx-react'

import { IConcreteDirectPayloadEvent } from '~/client/graph'
import ConcreteDirectPayloadStatus from '~/client/src/shared/enums/ConcreteDirectPayloadStatus'
import ProjectDateStore, {
  differenceInMinutes,
} from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import PayloadProjectedArrivalTimeLabel from './PayloadProjectedArrivalTimeLabel'

interface IProps {
  payloadEvent: IConcreteDirectPayloadEvent
  eta: number
  status: string

  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
export default class PayloadOnSiteTimeLabel extends React.Component<IProps> {
  public render() {
    const {
      payloadEvent,
      eta,
      status,
      projectDateStore: { getTimeToDisplay },
    } = this.props

    if (payloadEvent?.arrivalTime) {
      return getTimeToDisplay(payloadEvent?.arrivalTime)
    }

    if (status === ConcreteDirectPayloadStatus.EnRoute && !!eta) {
      const minutesToArrival = differenceInMinutes(eta, Date.now())
      return (
        <PayloadProjectedArrivalTimeLabel minutesToArrival={minutesToArrival} />
      )
    }

    if (eta) {
      return `${getTimeToDisplay(eta)} ETA`
    }

    return NO_VALUE
  }
}
