import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import * as Icons from '~/client/src/shared/components/Icons'
import Tabs from '~/client/src/shared/components/Tabs/Tabs'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import PermitType from '~/client/src/shared/models/PermitType'
import PermitBallInCourtStore from '~/client/src/shared/stores/ui/PermitBallInCourt.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import FormConfigurator from '../FormConfigurator/FormConfigurator'
import FormTypeConfiguratorStore from './FormTypeConfigurator.store'
import Breadcrumb from './components/Breadcrumb'
import FormWorkflowSteps from './components/FormWorkflowSteps'
import GeneralFormConfigurator from './components/GeneralFormConfigurator'
import UndoRedoAction from './components/UndoRedoAction'
import Workflow from './components/Workflow/Workflow'

// localization: translated

interface IProps {
  formType: PermitType
  isUpdating: boolean

  onBackClick(): void
  saveFormType(updatedFormType: PermitType): void

  permitBicStore: PermitBallInCourtStore

  projectDateStore?: ProjectDateStore
}

const Z_KEY_CODE = 'KeyZ'
const Y_KEY_CODE = 'KeyY'
const MAC = 'MAC'
const isMac = navigator.platform.toUpperCase().includes(MAC)

@inject('projectDateStore')
@observer
export default class FormTypeConfigurator extends React.Component<IProps> {
  private readonly store: FormTypeConfiguratorStore

  public constructor(props: IProps) {
    super(props)

    this.store = new FormTypeConfiguratorStore(
      props.formType,
      props.saveFormType,
    )
  }

  public componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown)
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { formType } = this.props
    if (
      formType?.id !== prevProps.formType?.id &&
      formType?.type !== prevProps.formType?.type
    ) {
      this.store.showToastErrorIfNeed()
    }
    if (formType !== prevProps.formType) {
      this.store.init(formType)
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown)

    this.store.showToastErrorIfNeed()
  }

  public render() {
    const {
      isGeneralTabSelected,
      isStepsTabSelected,
      isFormsTabSelected,
      formConfiguratorTabs,
      activeFormTab,
      changeActiveFormTab,
    } = this.store

    return (
      <div className="col full-height full-width relative">
        {this.actionBar}
        <div className="forms-tabs-container row x-between y-center mx20 overflow-hidden no-select">
          <Tabs
            tabObjects={formConfiguratorTabs}
            activeTabId={activeFormTab}
            onTabClick={changeActiveFormTab}
            className="bb-light-grey full-height"
            tabTextClassName="px20"
            tabClassName="forms-tab-item text large center line-extra-large pointer"
          />
        </div>
        {isGeneralTabSelected && this.generalTabContent}
        {isStepsTabSelected && this.workflowTabContent}
        {isFormsTabSelected && this.configuratorTabContent}
      </div>
    )
  }

  private get actionBar(): JSX.Element {
    const { isUpdating, onBackClick } = this.props
    const {
      formType,
      isAllowedToSave,
      hasUndo,
      hasRedo,
      warningMessage,
      activeTabTitle,
      undoChangesToInitial,
      undoChanges,
      redoChanges,
      saveChanges,
    } = this.store

    return (
      <div className="row y-center py12 px20 overflow-hidden no-select">
        <div className="row nowrap overflow-hidden mr20">
          <Breadcrumb
            label={Localization.translator.workflows}
            onClick={onBackClick}
          />
          <Breadcrumb label={formType.name} />
          <Breadcrumb label={activeTabTitle} isLast />
        </div>

        <div
          className={classList({
            'row relative no-grow': true,
            'inactive-element': isUpdating,
          })}
        >
          <UndoRedoAction
            iconRenderer={Icons.Restore}
            isEnabled={hasUndo}
            tooltip={Localization.translator.restoreToLastSavedState}
            onClick={undoChangesToInitial}
          />
          <UndoRedoAction
            iconRenderer={Icons.Undo}
            isEnabled={hasUndo}
            tooltip={this.getUndoRedoTooltip(true)}
            onClick={undoChanges}
          />
          <UndoRedoAction
            iconRenderer={Icons.Redo}
            isEnabled={hasRedo}
            tooltip={this.getUndoRedoTooltip()}
            onClick={redoChanges}
          />
          <BaseActionButton
            title={Localization.translator.save}
            className={classList({
              'mw120 ml10 brada24 no-select beautiful-shadow-hover': true,
              'scale-blue-theme pointer': hasUndo,
              'gray-theme': !hasUndo,
            })}
            isEnabled={hasUndo && isAllowedToSave}
            isLoading={isUpdating}
            onClick={saveChanges}
          />
        </div>
        {warningMessage && (
          <div className="absolute full-width bg-red text large bold center white mt5 py10 z-index-100 bottom-0 left-0">
            <span>
              {Localization.translator.error}: {warningMessage}
            </span>
          </div>
        )}
      </div>
    )
  }

  private get generalTabContent(): JSX.Element {
    const { formType, changeGeneralProps } = this.store

    return (
      <div className="col full-height pt10 px16 overflow-auto">
        <GeneralFormConfigurator
          formTypeName={formType.name}
          formTypeType={formType.type}
          workflowCategoryToDisplay={formType.workflowCategoryToDisplay}
          shouldBlockOnNonWorkTimes={formType.shouldBlockOnNonWorkTimes}
          isShownInApp={formType.isEnabled}
          lastUpdatedAtLabel={formType.getFormattedUpdatedAt(
            this.props.projectDateStore.getMonthDayYearAndTimeToDisplay,
          )}
          onChange={changeGeneralProps}
        />
      </div>
    )
  }

  private get workflowTabContent(): JSX.Element {
    const {
      formType,
      workflowSteps,
      hasUndo,
      changeWorkflowSettings,
      selectStepAndGoToForms,
    } = this.store

    return (
      <div className="col full-height pt10 px20 overflow-auto">
        <Workflow
          formTypeId={formType.type}
          workflowSteps={workflowSteps}
          inspectionOptions={formType.inspectionOptions}
          isAutoActivationEnabled={formType.isAutoActivationEnabled}
          isUpdating={this.props.isUpdating}
          isWorkflowChanged={hasUndo}
          permitBallInCourtStore={this.props.permitBicStore}
          onWorkflowSettingsChange={changeWorkflowSettings}
          onStepClick={selectStepAndGoToForms}
        />
      </div>
    )
  }

  private get configuratorTabContent(): JSX.Element {
    const {
      formType: { workflowSteps, isMaterialTransfer },
      selectedWorkflowStepId,
      selectedWorkflowStep,
      isInitialStepSelected,
      changePermitTypeWorkflowStep,
      setSelectedWorkflowStepId,
    } = this.store

    return (
      <>
        <span className="px50 py10 text large bold">
          {Localization.translator.workflowConfDescr.selectStepMsg}
        </span>
        <div className="row overflow-auto">
          <div className="row x-center">
            <FormWorkflowSteps
              selectedStepId={selectedWorkflowStepId}
              workflowSteps={workflowSteps}
              onStepClick={setSelectedWorkflowStepId}
            />
          </div>
        </div>
        <div className="px20 bt-light-grey col full-height overflow-auto">
          <FormConfigurator
            workflowStep={selectedWorkflowStep}
            isInitialFormStep={isInitialStepSelected}
            onWorkflowStepChange={changePermitTypeWorkflowStep}
            isMaterialTransfer={isMaterialTransfer}
          />
        </div>
      </>
    )
  }

  private getUndoRedoTooltip(isUndo?: boolean): JSX.Element {
    return (
      <div className="row text white large line-extra-large">
        {isUndo ? Localization.translator.undo : Localization.translator.redo}
        <span className="ba-grey-scale-light brada4 px4 ml8 mr4">Ctrl</span>
        <span className="ba-grey-scale-light brada4 px4">
          {isUndo ? 'Z' : 'Y'}
        </span>
      </div>
    )
  }

  private onKeyDown = (event: KeyboardEvent) => {
    const isCtrlPressed = event.ctrlKey || (isMac && event.metaKey)
    const isShiftPressed = event.shiftKey

    if (isMac && isCtrlPressed) {
      event.preventDefault()
    }

    switch (event.code) {
      case Z_KEY_CODE:
        if (isShiftPressed && isCtrlPressed) {
          this.store.redoChanges()
        } else if (isCtrlPressed) {
          this.store.undoChanges()
        }
        break
      case Y_KEY_CODE:
        if (isCtrlPressed) {
          this.store.redoChanges()
        }
        break
    }
  }
}
