import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList, toggleClass } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import { NOOP } from '~/client/src/shared/utils/noop'

import { EMPTY_STRING } from '../../utils/usefulStrings'
import BaseActionButton from '../BaseActionButton/BaseActionButton'
import Dimmer from '../Dimmer'

import './BaseCompactPopup.scss'

interface IProps {
  title: string
  subtitle?: string
  isShown: boolean
  onHide: (event?: React.SyntheticEvent<HTMLElement>) => void
  actionButtonTitle?: string
  isActionButtonEnabled?: boolean
  customLeftIcon?: JSX.Element
  onActionButtonClick?: () => void

  containerClassName?: string
  titleClassName?: string
  childrenClassName?: string

  shouldIncludeSearch?: boolean
  searchPlaceholder?: string
  searchValue?: string
  onSearchValueChange?: (newValue: string) => void
  onSearchEnterPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onSearchValueClear?: () => void

  isLoading?: boolean
}

@observer
export default class BaseCompactPopup extends React.Component<IProps> {
  public static defaultProps = {
    onActionButtonClick: NOOP,
    onSearchValueChange: NOOP,
    onSearchEnterPress: NOOP,
    onSearchValueClear: NOOP,

    titleClassName: 'text center large bold',
  }

  public render() {
    const {
      isShown,
      title,
      subtitle,
      onHide,
      children,
      titleClassName,
      actionButtonTitle,
      isActionButtonEnabled,
      onActionButtonClick,
      childrenClassName,
      shouldIncludeSearch,
      searchPlaceholder,
      searchValue,
      containerClassName,
    } = this.props

    return (
      <>
        {isShown && <Dimmer onClick={onHide} shown={isShown} />}
        <div
          className={classList({
            'compact-popup-modal col': true,
            shown: isShown,
            [containerClassName]: !!containerClassName,
          })}
        >
          <div className="row py15">
            {this.renderIcon()}
            <div className="col x-center title-container">
              <div className={titleClassName}>{title}</div>
              {!!subtitle && <div className="text light">{subtitle}</div>}
            </div>
            {actionButtonTitle && (
              <BaseActionButton
                onClick={onActionButtonClick}
                isEnabled={isActionButtonEnabled}
                title={actionButtonTitle}
                className="scale-blue-theme absolute"
                isLoading={this.props.isLoading}
              />
            )}
          </div>
          {shouldIncludeSearch && (
            <div className="row search-input bg-palette-brand-lightest px16 by-light-input-border">
              <Icon
                icon={IconNames.SEARCH}
                className={classList({
                  mr15: true,
                  'text blue': !!searchValue,
                })}
              />
              <input
                type="text"
                onChange={this.onSearchValueChange}
                onKeyPress={this.handleEnterPress}
                className="full-width full-height bare-input no-outline"
                placeholder={searchPlaceholder}
                value={searchValue || EMPTY_STRING}
              />
              <Icon
                onClick={this.clearSearchValue}
                icon={IconNames.CROSS}
                className={toggleClass('transparent', !searchValue)}
              />
            </div>
          )}
          <div
            className={classList({
              'overflow-y-auto': true,
              [childrenClassName]: !!childrenClassName,
            })}
          >
            {children}
          </div>
        </div>
      </>
    )
  }

  private renderIcon(): JSX.Element {
    const { customLeftIcon, onHide } = this.props

    const icon = customLeftIcon ? (
      customLeftIcon
    ) : (
      <Icons.Cross className="row cross-icon" onClick={onHide} />
    )

    return (
      <span className="row icon-wrapper no-grow absolute pointer">{icon}</span>
    )
  }

  private onSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.props.onSearchValueChange(event.target.value)
  }

  private handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    this.props.onSearchEnterPress(event)
  }

  private clearSearchValue = () => {
    this.props.onSearchValueClear()
  }
}
