import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import Dimmer from '~/client/src/shared/components/Dimmer'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import TimePicker from '../DeliverySelectDateModal/components/TimePicker'

import './DeliverySelectTimeModal.scss'

// localization: translated

interface IProps {
  shown: boolean
  onHide: () => void

  initStartDate?: number | Date
  initEndDate?: number | Date

  projectDateStore?: ProjectDateStore
  deliveryDetailsStore: DeliveryDetailsStore
}

@inject('projectDateStore')
@observer
export default class DeliverySelectTimeModal extends React.Component<IProps> {
  public render() {
    const { selectedTimeControl, selectedTimes } =
      this.props.deliveryDetailsStore
    const { onHide, shown } = this.props

    const { startTime, endTime } = selectedTimes || {}

    const isStartTime = selectedTimeControl === FieldIds.START_TIME
    const defaultTime = isStartTime ? startTime : endTime
    const title = isStartTime
      ? Localization.translator.startTime
      : Localization.translator.endTime

    return (
      <>
        <Dimmer shown={shown} onClick={onHide} />
        <div
          className={classList({
            'select-time-modal absolute-block z-index-100 bg-white bradt8':
              true,
            shown,
          })}
        >
          {shown && (
            <TimePicker
              title={title}
              defaultTime={defaultTime}
              onApply={this.onApply}
              onHide={this.props.onHide}
            />
          )}
        </div>
      </>
    )
  }

  @action.bound
  private onApply(timeString: string) {
    const { selectedTimeControl, onFieldValueChange } =
      this.props.deliveryDetailsStore

    onFieldValueChange(selectedTimeControl, timeString)
    this.props.onHide()
  }
}
