import React from 'react'

import { inject, observer } from 'mobx-react'

import CompactHeaderBar from '~/client/src/shared/components/CompactHeaderBar/CompactHeaderBar'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import UserProject from '~/client/src/shared/models/UserProject'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import ReportsStore from '../../Reports.store'

interface IProps {
  store: ReportsStore
  projectMembersStore?: ProjectMembersStore
  userProjectsStore?: UserProjectsStore
  companiesStore?: CompaniesStore
  projectDateStore?: ProjectDateStore
}

@inject(
  'projectMembersStore',
  'userProjectsStore',
  'companiesStore',
  'projectDateStore',
)
@observer
export default class ScanStationReportsDialog extends React.Component<IProps> {
  public render() {
    const { activeReportView, closeScanStationReports: closeReports } =
      this.props.store
    return (
      <>
        <CompactHeaderBar
          titleCaption={activeReportView.name}
          titleIconEl={<Icons.Print className="row no-grow" />}
          isCreationMode={false}
          onCloseClick={closeReports}
        />
        <div className="col">
          {this.fields.map(field => (
            <div key={field.label} className="row x-between py10 px15">
              <span className="text large">{field.label}</span>
              <span className="text extra-large right">{field.value}</span>
            </div>
          ))}
        </div>
      </>
    )
  }

  private get fields() {
    const { selectedScanStation } = this.props.store
    const { getMonthDayYearAndTimeToDisplay } = this.props.projectDateStore
    const user = this.props.projectMembersStore.getById(
      selectedScanStation.history.userId,
    )
    const companyName = user
      ? UserProject.getCompanyName(
          user,
          this.props.userProjectsStore,
          this.props.companiesStore,
        )
      : NO_VALUE

    return [
      {
        label: Localization.translator.scannerName,
        value: selectedScanStation.scanner?.name,
      },
      {
        label: Localization.translator.user,
        value: user?.fullName || NO_VALUE,
      },
      {
        label: Localization.translator.company,
        value: companyName,
      },
      {
        label: Localization.translator.status,
        value: '',
      },
      {
        label: Localization.translator.startTime,
        value: getMonthDayYearAndTimeToDisplay(
          selectedScanStation.history.date,
        ),
      },
      {
        label: Localization.translator.endTime,
        value: getMonthDayYearAndTimeToDisplay(
          selectedScanStation.history.endDate,
        ),
      },
      {
        label: Localization.translator.scanMaster,
        value: '',
      },
    ]
  }
}
