import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject } from 'mobx-react'

import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import WeatherUnits, { windSpeedUnits } from '../../enums/WeatherUnits'
import WeatherForecastInfo from '../../models/WeatherInfo'
import OpenWeatherIcon from '../OpenWeatherIcon'

import './WeatherHourlyWidget.scss'

// localization: no display text to translate

const ICON_SIZE = 10
const DEGREE_SYMBOL = '°'

interface IProps {
  forecast: WeatherForecastInfo
  projectWeatherUnits: WeatherUnits
  height: number

  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
export default class WeatherBar extends React.PureComponent<IProps> {
  public render() {
    const { projectWeatherUnits, forecast, height } = this.props
    const {
      weatherIconCode,
      probabilityOfPrecipitation,
      currentTemp,
      windSpeed,
    } = forecast

    return (
      <div className="col x-center y-end mr8">
        <div
          className="weather-bar col x-center y-end no-grow ba-light-grey brada8 w48 py8"
          style={{ minHeight: height }}
        >
          {!!probabilityOfPrecipitation && (
            <span className="text bold lp05 no-grow ml2 blue-brand">
              {Math.round(probabilityOfPrecipitation)}%
            </span>
          )}
          <OpenWeatherIcon
            openWeatherIconCode={weatherIconCode}
            className="icon text grey-light"
          />
          <span className="text extra-large bold my8">
            {Math.round(currentTemp)}
            {DEGREE_SYMBOL}
          </span>
          {this.renderTimeSection()}
        </div>
        <div className="row my8">
          <Icon
            icon={IconNames.ARROW_UP}
            iconSize={ICON_SIZE}
            className="text grey-light"
            style={{ transform: `rotate(${forecast.windDegree}deg)` }}
          />
          <span className="text bold lp05 ml2">{Math.round(windSpeed)}</span>
          <span className="text small grey-light lp05">
            {windSpeedUnits(projectWeatherUnits)}
          </span>
        </div>
      </div>
    )
  }

  private renderTimeSection() {
    const [time, format] = this.props.projectDateStore
      .getTimeToDisplay(this.props.forecast.date)
      .split(' ')

    return (
      <>
        <span className="text bold">{time}</span>
        <span className="text opacity5 lowercase">{format}</span>
      </>
    )
  }
}
