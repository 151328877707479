import React from 'react'

import { IReactionDisposer, action, reaction } from 'mobx'
import { inject, observer } from 'mobx-react'

import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import ActivitiesMapView from '~/client/src/shared/components/ActivitiesMapView/ActivitiesMapView'
import ActivitiesMapViewSetUpStore from '~/client/src/shared/components/ActivitiesMapView/ActivitiesMapViewSetUp.store'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import MapViewsGallery from '~/client/src/shared/components/SitemapsGallery/MapViewsGallery'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import GlobeView from '~/client/src/shared/models/GlobeView'
import Sitemap from '~/client/src/shared/models/Sitemap'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { SAVE_ACTIVITIES_CONFIGURATIONS } from '~/client/src/shared/stores/EventStore/eventConstants'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import DesktopActivityListStore from '../DesktopActivityList.store'

import './ActivitiesMap.scss'

interface IProps {
  store: DesktopActivityListStore

  state?: DesktopInitialState
  sitemapsStore?: SitemapsStore
  basemapsStore?: BasemapsStore
  sitemapItemsStore?: SitemapItemsStore
  locationAttributesStore?: LocationAttributesStore
  tagsStore?: TagsStore
  deliveryDetailsStore?: DeliveryDetailsStore
  globeViewsStore?: GlobeViewsStore
  eventsStore?: EventsStore
  userProjectsStore?: UserProjectsStore
}

@inject(
  'state',
  'sitemapsStore',
  'basemapsStore',
  'sitemapItemsStore',
  'locationAttributesStore',
  'tagsStore',
  'deliveryDetailsStore',
  'globeViewsStore',
  'eventsStore',
  'userProjectsStore',
)
@observer
export default class ActivitiesMap extends React.Component<IProps> {
  private readonly store: ActivitiesMapViewSetUpStore = null
  private textboxesCache: ICanvasImageCache = {}
  private disposeDisplayedActivityIdReaction: IReactionDisposer

  public constructor(props: IProps) {
    super(props)

    this.store = new ActivitiesMapViewSetUpStore(
      props.sitemapsStore,
      props.basemapsStore,
      props.sitemapItemsStore,
      props.locationAttributesStore,
      props.tagsStore,
      props.state,
      props.eventsStore,
      props.globeViewsStore,
      props.userProjectsStore,
    )

    const config = props.state.activitiesMapIdsList
    const isGlobeMode = !!config?.[0]?.globeViewId
    if (isGlobeMode) {
      const globe = props.globeViewsStore.byId.get(config?.[0]?.globeViewId)
      this.store.globeViewControlStore.selectGlobe(globe)
    } else {
      const whiteboard = props.sitemapsStore.byId.get(config?.[0]?.sitemapId)
      this.store.selectSitemap(whiteboard)
      this.store.mapBoxViewerStore.setViewportFromAddress()
    }
  }

  public componentDidMount() {
    this.disposeDisplayedActivityIdReaction = reaction(
      () => this.props.store.selectedActivity,
      selectedActivity => {
        if (!selectedActivity) {
          this.store.deselectAll()
        }
      },
      { fireImmediately: true },
    )
  }

  public componentWillUnmount() {
    this.textboxesCache = {}
    this.disposeDisplayedActivityIdReaction?.()
  }

  public render() {
    const { state } = this.props

    return (
      <div className="activities-map full-height">
        <MapViewsGallery
          isHideButtonDisplayed={false}
          mapIds={state.activitiesMapIdsList}
          selectedGlobeViewId={
            this.store.globeViewControlStore.selectedGlobeView?.id
          }
          selectedWhiteboardId={this.store.sitemap?.id}
          renderMapViewComponent={this.renderMapView}
          eventName={SAVE_ACTIVITIES_CONFIGURATIONS}
          selectGlobe={this.selectGlobeAndCloseModals}
          selectSitemap={this.selectSitemapAndCloseModals}
          areArrowsLeftSided={true}
          shouldUseFullHeight={true}
          shouldDisableZoom={true}
          FileInputType={DesktopFileInput}
          mapBoxViewerStore={this.store.mapBoxViewerStore}
        />
      </div>
    )
  }

  private renderMapView = (): JSX.Element => {
    return (
      <div className="sitemap-container full-width full-height">
        <ActivitiesMapView
          activityListStore={this.props.store}
          store={this.store}
          textboxesCache={this.textboxesCache}
          openActivity={this.openActivity}
          globe={this.store.globeViewControlStore.selectedGlobeView}
          sitemap={this.store.sitemap}
        />
      </div>
    )
  }

  private openActivity = (code: string) => {
    const { setSelection, showActivityDetailsPanel } = this.props.store

    setSelection([code])
    showActivityDetailsPanel()
  }

  @action.bound
  private selectGlobeAndCloseModals(globe: GlobeView) {
    this.store.deselectSitemap()
    this.store.globeViewControlStore.selectGlobe(globe)

    this.store.mapBoxViewerStore.setViewportFromAddress()

    this.store.deselectAll()
  }

  @action.bound
  private selectSitemapAndCloseModals(sitemap: Sitemap) {
    this.store.globeViewControlStore.deselectGlobe()
    this.store.selectSitemap(sitemap)

    this.store.mapBoxViewerStore.setViewportFromAddress()

    this.store.deselectAll()
  }
}
