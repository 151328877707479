import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ISaveConcreteDirectDataForProjectMutationVariables = Types.Exact<{
  concreteDirectIntegration?: Types.Maybe<Types.IConcreteDirectIntegrationInput>
}>

export type ISaveConcreteDirectDataForProjectMutation = Pick<
  Types.IMutation,
  'saveConcreteDirectDataForProject'
>

export const SaveConcreteDirectDataForProjectDocument = gql`
  mutation SaveConcreteDirectDataForProject(
    $concreteDirectIntegration: ConcreteDirectIntegrationInput
  ) {
    saveConcreteDirectDataForProject(
      concreteDirectIntegration: $concreteDirectIntegration
    )
  }
`
export type ISaveConcreteDirectDataForProjectMutationFn =
  Apollo.MutationFunction<
    ISaveConcreteDirectDataForProjectMutation,
    ISaveConcreteDirectDataForProjectMutationVariables
  >

/**
 * __useSaveConcreteDirectDataForProjectMutation__
 *
 * To run a mutation, you first call `useSaveConcreteDirectDataForProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveConcreteDirectDataForProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveConcreteDirectDataForProjectMutation, { data, loading, error }] = useSaveConcreteDirectDataForProjectMutation({
 *   variables: {
 *      concreteDirectIntegration: // value for 'concreteDirectIntegration'
 *   },
 * });
 */
export function useSaveConcreteDirectDataForProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveConcreteDirectDataForProjectMutation,
    ISaveConcreteDirectDataForProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveConcreteDirectDataForProjectMutation,
    ISaveConcreteDirectDataForProjectMutationVariables
  >(SaveConcreteDirectDataForProjectDocument, options)
}
export type SaveConcreteDirectDataForProjectMutationHookResult = ReturnType<
  typeof useSaveConcreteDirectDataForProjectMutation
>
export type SaveConcreteDirectDataForProjectMutationResult =
  Apollo.MutationResult<ISaveConcreteDirectDataForProjectMutation>
export type SaveConcreteDirectDataForProjectMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveConcreteDirectDataForProjectMutation,
    ISaveConcreteDirectDataForProjectMutationVariables
  >
