import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import { BULLET } from '../../utils/usefulStrings'

import './CompactHeaderBar.scss'

interface IProps {
  titleCaption: string
  titleIconEl: JSX.Element
  onCloseClick: () => void

  startDate?: number | Date
  endDate?: number | Date
  isCreationMode?: boolean
  isMenuDisabled?: boolean
  renderStatus?: (className: string) => JSX.Element
  onMenuClick?(): void

  projectDateStore?: ProjectDateStore
}

const ICON_SIZE = 20

const newRequest = 'New request'

@inject('projectDateStore')
@observer
export default class CompactHeaderBar extends React.Component<IProps> {
  public render() {
    const { titleCaption, titleIconEl, onCloseClick } = this.props

    return (
      <div className="compact-header-bar row y-center bg-palette-brand-lightest bb-palette-brand-lighter px16 no-grow">
        <Icon
          className="row y-center pointer no-grow compact-header-bar-close-icon text dark-gray"
          iconSize={ICON_SIZE}
          icon={IconNames.CROSS}
          onClick={onCloseClick}
        />
        <div className="col y-center overflow-hidden">
          <div className="row">
            <div className="row y-center ml10 no-grow compact-header-bar-icon">
              {titleIconEl}
            </div>
            <div
              className="ml5 text bold large lpMinus018 line-extra-large ellipsis"
              title={titleCaption}
            >
              {titleCaption}
            </div>
          </div>
          {this.renderDateAndStatusRow()}
        </div>
        {this.menuButton}
        {this.renderNewRequestStatus()}
      </div>
    )
  }

  private renderDateAndStatusRow(): JSX.Element {
    const { isCreationMode, renderStatus, startDate, endDate } = this.props

    if (isCreationMode || (!startDate && !endDate)) {
      return null
    }

    return (
      <div className="compact-header-bar-date-status-row">
        <div
          className="ml10 text light large line-extra-large ellipsis"
          title={this.dateRangeCaption}
        >
          {this.dateRangeCaption}
        </div>
        <div className="row text small light ml5">{BULLET}</div>
        <div className="row compact-header-bar-status text-ellipsis">
          {renderStatus?.('inline-block text medium line-large ellipsis')}
        </div>
      </div>
    )
  }

  private renderNewRequestStatus(): JSX.Element {
    if (!this.props.isCreationMode) {
      return null
    }

    return (
      <div className="compact-header-bar-new-request row no-flex ml10 px10 ba-palette-brand-lighter brada24 bg-white text">
        {newRequest}
      </div>
    )
  }

  private get menuButton(): JSX.Element {
    const { isCreationMode, onMenuClick, isMenuDisabled } = this.props
    if (isCreationMode || !onMenuClick) return null

    return (
      <Icon
        id="form-menu-button"
        icon={IconNames.MORE}
        className={classList({
          'no-grow pointer full-height row y-center pl8 text dark-gray': true,
          'inactive-element': isMenuDisabled,
        })}
        onClick={this.onMenuClick}
      />
    )
  }

  private get dateRangeCaption(): string {
    const { startDate, endDate, projectDateStore } = this.props

    const {
      getWeekdayMonthAndDayToDisplay,
      getMonthAndDayToDisplay,
      getShortTimeIntervalPerDay,
      isSameDay,
    } = projectDateStore

    const isTheSameDay = isSameDay(startDate, endDate)

    if (isTheSameDay) {
      return `${getMonthAndDayToDisplay(
        startDate,
      )}, ${getShortTimeIntervalPerDay(startDate, endDate)}`
    }

    return `${getWeekdayMonthAndDayToDisplay(
      startDate,
    )} - ${getWeekdayMonthAndDayToDisplay(endDate)}`
  }

  private onMenuClick = () => {
    const { onMenuClick, isMenuDisabled } = this.props
    if (!isMenuDisabled) {
      onMenuClick()
    }
  }
}
