import * as React from 'react'

import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import BuildingsStore from '../../stores/domain/Buildings.store'
import LevelsStore from '../../stores/domain/Levels.store'
import VerticalObjectsStore from '../../stores/domain/VerticalObjects.store'
import BuildingLevelsSelector from '../BuildingLevelsSelector/BuildingLevelsSelector'
import SitemapAttributeIconComponent from '../SitemapAttributeTag/SitemapAttributeIcon'
import StruxhubTextValueSelector from '../StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'

const selectStairLevels = 'Select stair levels'

const ICON_SIZE = 18

interface IProps {
  verticalObjectId: string
  selectedLevelIds: string[]
  isMandatory?: boolean
  label?: string

  buildingsStore?: BuildingsStore
  levelsStore?: LevelsStore
  verticalObjectsStore?: VerticalObjectsStore
  onChange(levels: string[]): void
}

@inject('buildingsStore', 'levelsStore', 'verticalObjectsStore')
@observer
export default class VerticalObjectLevelsSelector extends React.Component<IProps> {
  public static defaultProps = {
    selectedLevelIds: [],
  }
  @observable private isLevelSelectorDisplayed: boolean = false

  public render() {
    if (!this.verticalObject || !this.verticalObject.accessibleLevels.length) {
      return null
    }

    const {
      label = selectStairLevels,
      isMandatory,
      selectedLevelIds,
    } = this.props

    return (
      <>
        <StruxhubTextValueSelector
          label={label}
          isRequired={isMandatory}
          onClick={this.toggleLevelsSelector}
          customIconElement={
            <SitemapAttributeIconComponent
              dataObject={this.selectedLevels?.[0]}
              customProps={{ size: ICON_SIZE, className: 'no-grow mr5' }}
            />
          }
          isHelperTextCondensed={true}
          value={this.selectedLevels?.map(lvl => lvl.name).join(', ')}
        />
        {this.isLevelSelectorDisplayed && (
          <BuildingLevelsSelector
            building={this.buildingParent}
            selectedLevels={selectedLevelIds}
            allowedLevelIds={this.verticalObject.accessibleLevels}
            onApply={this.onApplyClosedLevels}
            onHide={this.hideLevelSelector}
          />
        )}
      </>
    )
  }

  private toggleLevelsSelector = () => {
    this.isLevelSelectorDisplayed = !this.isLevelSelectorDisplayed
  }

  private hideLevelSelector = () => {
    this.isLevelSelectorDisplayed = false
  }

  private onApplyClosedLevels = (levelIds: string[]) => {
    this.isLevelSelectorDisplayed = false
    this.props.onChange(levelIds)
  }
  private get buildingParent() {
    const { buildingsStore: buildingsStore } = this.props
    const { parent } = this.verticalObject
    return parent && buildingsStore.byId.get(parent.parentId)
  }

  private get verticalObject() {
    const { verticalObjectsStore: verticalObjectsStore, verticalObjectId } =
      this.props
    return verticalObjectsStore.byId.get(verticalObjectId)
  }

  private get selectedLevels() {
    return this.props.levelsStore.list.filter(l =>
      this.props.selectedLevelIds.includes(l.id),
    )
  }
}
