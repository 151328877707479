import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IAnnouncementAssignmentFieldsFragment = Pick<
  Types.IAnnouncementAssignment,
  'id' | 'all' | 'entityId' | 'projectId'
> & { recipients: Array<Types.Maybe<Pick<Types.IRecipients, 'ids' | 'type'>>> }

export type IGetAnnouncementAssignmentsListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetAnnouncementAssignmentsListQuery = {
  announcementAssignments?: Types.Maybe<{
    data: Array<IAnnouncementAssignmentFieldsFragment>
  }>
}

export type ISaveManyAnnouncementAssignmentsMutationVariables = Types.Exact<{
  assignments:
    | Array<Types.Maybe<Types.IAnnouncementAssignmentInput>>
    | Types.Maybe<Types.IAnnouncementAssignmentInput>
}>

export type ISaveManyAnnouncementAssignmentsMutation = {
  saveManyAnnouncementAssignments?: Types.Maybe<
    Array<Types.Maybe<IAnnouncementAssignmentFieldsFragment>>
  >
}

export type IDeleteManyAnnouncementAssignmentsMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteManyAnnouncementAssignmentsMutation = Pick<
  Types.IMutation,
  'deleteManyAnnouncementAssignments'
>

export type IListenToAnnouncementAssignmentsSubscriptionVariables =
  Types.Exact<{
    projectId: Types.Scalars['ObjectId']
  }>

export type IListenToAnnouncementAssignmentsSubscription = {
  announcementAssignment?: Types.Maybe<
    Pick<Types.IAnnouncementAssignmentChangeEvent, 'id'> & {
      item?: Types.Maybe<IAnnouncementAssignmentFieldsFragment>
    }
  >
}

export const AnnouncementAssignmentFieldsFragmentDoc = gql`
  fragment AnnouncementAssignmentFields on AnnouncementAssignment {
    id
    all
    entityId
    projectId
    recipients {
      ids
      type
    }
  }
`
export const GetAnnouncementAssignmentsListDocument = gql`
  query GetAnnouncementAssignmentsList($projectId: ObjectId!) {
    announcementAssignments(limit: 1000000, projectId: $projectId) {
      data {
        ...AnnouncementAssignmentFields
      }
    }
  }
  ${AnnouncementAssignmentFieldsFragmentDoc}
`

/**
 * __useGetAnnouncementAssignmentsListQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementAssignmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementAssignmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementAssignmentsListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetAnnouncementAssignmentsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetAnnouncementAssignmentsListQuery,
    IGetAnnouncementAssignmentsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetAnnouncementAssignmentsListQuery,
    IGetAnnouncementAssignmentsListQueryVariables
  >(GetAnnouncementAssignmentsListDocument, options)
}
export function useGetAnnouncementAssignmentsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetAnnouncementAssignmentsListQuery,
    IGetAnnouncementAssignmentsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetAnnouncementAssignmentsListQuery,
    IGetAnnouncementAssignmentsListQueryVariables
  >(GetAnnouncementAssignmentsListDocument, options)
}
export type GetAnnouncementAssignmentsListQueryHookResult = ReturnType<
  typeof useGetAnnouncementAssignmentsListQuery
>
export type GetAnnouncementAssignmentsListLazyQueryHookResult = ReturnType<
  typeof useGetAnnouncementAssignmentsListLazyQuery
>
export type GetAnnouncementAssignmentsListQueryResult = Apollo.QueryResult<
  IGetAnnouncementAssignmentsListQuery,
  IGetAnnouncementAssignmentsListQueryVariables
>
export const SaveManyAnnouncementAssignmentsDocument = gql`
  mutation SaveManyAnnouncementAssignments(
    $assignments: [AnnouncementAssignmentInput]!
  ) {
    saveManyAnnouncementAssignments(announcementAssignments: $assignments) {
      ...AnnouncementAssignmentFields
    }
  }
  ${AnnouncementAssignmentFieldsFragmentDoc}
`
export type ISaveManyAnnouncementAssignmentsMutationFn =
  Apollo.MutationFunction<
    ISaveManyAnnouncementAssignmentsMutation,
    ISaveManyAnnouncementAssignmentsMutationVariables
  >

/**
 * __useSaveManyAnnouncementAssignmentsMutation__
 *
 * To run a mutation, you first call `useSaveManyAnnouncementAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyAnnouncementAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyAnnouncementAssignmentsMutation, { data, loading, error }] = useSaveManyAnnouncementAssignmentsMutation({
 *   variables: {
 *      assignments: // value for 'assignments'
 *   },
 * });
 */
export function useSaveManyAnnouncementAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyAnnouncementAssignmentsMutation,
    ISaveManyAnnouncementAssignmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyAnnouncementAssignmentsMutation,
    ISaveManyAnnouncementAssignmentsMutationVariables
  >(SaveManyAnnouncementAssignmentsDocument, options)
}
export type SaveManyAnnouncementAssignmentsMutationHookResult = ReturnType<
  typeof useSaveManyAnnouncementAssignmentsMutation
>
export type SaveManyAnnouncementAssignmentsMutationResult =
  Apollo.MutationResult<ISaveManyAnnouncementAssignmentsMutation>
export type SaveManyAnnouncementAssignmentsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveManyAnnouncementAssignmentsMutation,
    ISaveManyAnnouncementAssignmentsMutationVariables
  >
export const DeleteManyAnnouncementAssignmentsDocument = gql`
  mutation DeleteManyAnnouncementAssignments($ids: [ObjectId]!) {
    deleteManyAnnouncementAssignments(ids: $ids)
  }
`
export type IDeleteManyAnnouncementAssignmentsMutationFn =
  Apollo.MutationFunction<
    IDeleteManyAnnouncementAssignmentsMutation,
    IDeleteManyAnnouncementAssignmentsMutationVariables
  >

/**
 * __useDeleteManyAnnouncementAssignmentsMutation__
 *
 * To run a mutation, you first call `useDeleteManyAnnouncementAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyAnnouncementAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyAnnouncementAssignmentsMutation, { data, loading, error }] = useDeleteManyAnnouncementAssignmentsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteManyAnnouncementAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteManyAnnouncementAssignmentsMutation,
    IDeleteManyAnnouncementAssignmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteManyAnnouncementAssignmentsMutation,
    IDeleteManyAnnouncementAssignmentsMutationVariables
  >(DeleteManyAnnouncementAssignmentsDocument, options)
}
export type DeleteManyAnnouncementAssignmentsMutationHookResult = ReturnType<
  typeof useDeleteManyAnnouncementAssignmentsMutation
>
export type DeleteManyAnnouncementAssignmentsMutationResult =
  Apollo.MutationResult<IDeleteManyAnnouncementAssignmentsMutation>
export type DeleteManyAnnouncementAssignmentsMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteManyAnnouncementAssignmentsMutation,
    IDeleteManyAnnouncementAssignmentsMutationVariables
  >
export const ListenToAnnouncementAssignmentsDocument = gql`
  subscription ListenToAnnouncementAssignments($projectId: ObjectId!) {
    announcementAssignment(projectId: $projectId) {
      item {
        ...AnnouncementAssignmentFields
      }
      id
    }
  }
  ${AnnouncementAssignmentFieldsFragmentDoc}
`

/**
 * __useListenToAnnouncementAssignmentsSubscription__
 *
 * To run a query within a React component, call `useListenToAnnouncementAssignmentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToAnnouncementAssignmentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToAnnouncementAssignmentsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToAnnouncementAssignmentsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToAnnouncementAssignmentsSubscription,
    IListenToAnnouncementAssignmentsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToAnnouncementAssignmentsSubscription,
    IListenToAnnouncementAssignmentsSubscriptionVariables
  >(ListenToAnnouncementAssignmentsDocument, options)
}
export type ListenToAnnouncementAssignmentsSubscriptionHookResult = ReturnType<
  typeof useListenToAnnouncementAssignmentsSubscription
>
export type ListenToAnnouncementAssignmentsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToAnnouncementAssignmentsSubscription>
