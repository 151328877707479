import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import {
  AutoSizer,
  Column,
  Table,
  defaultTableRowRenderer,
} from 'react-virtualized'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import Checkbox from '~/client/src/shared/components/Checkbox'
import * as Icons from '~/client/src/shared/components/Icons'
import {
  ILWFCCategory,
  ILWFCColumn,
  ILWFCRow,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import PermitTypeIcon from '~/client/src/shared/components/PermitTypeIcon/PermitTypeIcon'
import SelectButton from '~/client/src/shared/components/SelectButton/SelectButton'
import SortOrder from '~/client/src/shared/enums/SortOrder'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { NOOP } from '~/client/src/shared/utils/noop'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import ShowInAppModal from '../../ShowInAppModal/ShowInAppModal'
import { WorkflowDataKeys } from '../FormTypesList.store'

// localization: translated

interface IProps {
  rows: ILWFCRow[]
  columns: ILWFCColumn[]
  minTableWidth: number
  collapsedCategories: Map<string, boolean>
  sortedColumnKey: string
  sortingOrder: SortOrder

  selectOrDeselectAll(): void
  collapseCategory(categoryId: string): void
  selectCategory(category: ILWFCCategory): void
  selectFormType(formTypeId: string): void
  onSelectType(id: string): void
  onColumnSort(dataKey: string): void
  onShowInAppChange(formTypeId: string): void
}

const OVERSCAN_ROW_COUNT = 8
const TYPE_ROW_HEIGHT = 40
const NAME_COLUMN_INDEX = 2
const SORTING_COLUMN_KEYS = [
  WorkflowDataKeys.NAME,
  WorkflowDataKeys.CATEGORY,
  WorkflowDataKeys.SHOW_IN_APP,
  WorkflowDataKeys.LAST_UPDATED,
]

function TextCell({ text }: { text: string }): JSX.Element {
  return (
    <span className="text ellipsis large line-extra-large nowrap" title={text}>
      {text || NO_VALUE}
    </span>
  )
}

@observer
export default class FormTypesTable extends React.Component<IProps> {
  public render() {
    const { rows, columns, minTableWidth } = this.props

    return (
      <div
        className="virtualized-list-smart-wrapper"
        style={{ minWidth: minTableWidth }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <Table
              width={width}
              height={height}
              headerHeight={TYPE_ROW_HEIGHT}
              rowHeight={TYPE_ROW_HEIGHT}
              rowCount={rows.length}
              rowClassName="row y-stretch no-outline no-select"
              rowGetter={this.rowGetter}
              rowRenderer={this.rowRenderer}
              overscanRowCount={OVERSCAN_ROW_COUNT}
            >
              {columns.map(column => (
                <Column
                  key={column.dataKey}
                  className="ma0 highlighted-hover-light overflow-hidden lpMinus01"
                  headerClassName="ma0 highlighted-hover-light overflow-hidden lpMinus01"
                  width={column.width}
                  minWidth={column.width}
                  maxWidth={column.flexGrow ? null : column.width}
                  flexGrow={column.flexGrow}
                  dataKey={column.dataKey}
                  label={column.label}
                  cellRenderer={this.renderDataCell}
                  headerRenderer={this.renderHeaderCell}
                />
              ))}
            </Table>
          )}
        </AutoSizer>
      </div>
    )
  }

  private rowGetter = ({ index }): ILWFCRow => {
    return this.props.rows[index]
  }

  private rowRenderer = (rowProps: {
    columns: JSX.Element[]
    rowData: ILWFCRow
  }): JSX.Element => {
    if (rowProps.rowData.category) {
      return defaultTableRowRenderer({
        ...rowProps,
        columns: [rowProps.columns[NAME_COLUMN_INDEX]],
      })
    }
    return defaultTableRowRenderer(rowProps)
  }

  private renderHeaderCell = ({ dataKey, label }): JSX.Element => {
    const { onColumnSort, sortedColumnKey, sortingOrder } = this.props

    const isCheckboxColumn = dataKey === WorkflowDataKeys.CHECKBOX
    const isIconColumn = dataKey === WorkflowDataKeys.ICON
    const hasSorting = SORTING_COLUMN_KEYS.includes(dataKey)
    const isColumnSorted = sortedColumnKey === dataKey

    const handler = !hasSorting ? NOOP : onColumnSort.bind(null, dataKey)
    const iconRenderer =
      sortingOrder === SortOrder.ASC ? Icons.SortAsc : Icons.SortDesc

    return (
      <div
        className={classList({
          'row full-height bt-light-cool-grey bb-light-cool-grey br-light-cool-grey':
            true,
          'full-width x-center': isCheckboxColumn,
          'display-on-hover-container': hasSorting,
          px10: !isCheckboxColumn,
        })}
        title={label}
      >
        {isCheckboxColumn ? (
          <SelectButton onClick={this.props.selectOrDeselectAll} />
        ) : (
          <>
            <span
              className={classList({
                'text large grey-30 bold line-extra-large transform-none': true,
                'word-break-all': isIconColumn,
              })}
            >
              {label}
            </span>
            {hasSorting &&
              iconRenderer({
                className: classList({
                  'text grey-30 ml4 display-on-hover br-rounded highlighted-hover highlighted-active pointer row no-grow pa8':
                    true,
                  'visible-always': isColumnSorted,
                }),
                onClick: handler,
              })}
          </>
        )}
      </div>
    )
  }

  private renderDataCell = ({ style, dataKey, rowData }): JSX.Element => {
    if (rowData.category) {
      return this.renderCategory(rowData.category, dataKey, style)
    }

    const id = rowData.data[WorkflowDataKeys.ID]
    const isSelected = rowData.data[WorkflowDataKeys.CHECKBOX]
    const value = rowData.data[dataKey]
    const renderer = this.columnKeyToCellRenderer[dataKey]

    return (
      <div
        className={classList({
          'row full-height full-width no-select bb-light-cool-grey br-light-cool-grey':
            true,
          px10: dataKey !== WorkflowDataKeys.CHECKBOX,
          'bg-blue-active': isSelected,
        })}
        style={style}
      >
        {renderer?.(value, id) || <TextCell text={value} />}
      </div>
    )
  }

  private renderCategory = (
    category: ILWFCCategory,
    dataKey: string,
    style: any,
  ): JSX.Element => {
    if (dataKey !== WorkflowDataKeys.NAME) return null

    const { categoryId, categoryLabel, shortCategoryLabel, isChecked } =
      category
    const { collapsedCategories, collapseCategory } = this.props
    const chevronIcon = collapsedCategories.get(categoryId)
      ? Icons.RightArrow
      : Icons.DownArrow

    return (
      <div
        style={style}
        onClick={collapseCategory.bind(null, categoryId)}
        className="row full-height full-width bg-palette-brand-lightest bb-light-cool-grey"
      >
        <Checkbox
          className="full-height mw40 grey-checkbox"
          isCentered
          isChecked={isChecked}
          onClick={this.selectCategory.bind(this, category)}
        />
        <div className="ml5 row full-height text grey-30 large medium-bold line-extra-large pointer">
          {chevronIcon({
            className: 'row no-flex category-collapse-icon mr10',
          })}
          {categoryLabel}
          <span className="ml4 text grey-30 medium line-16">{`(${
            shortCategoryLabel || 0
          })`}</span>
        </div>
      </div>
    )
  }

  private get columnKeyToCellRenderer(): {
    [key: string]: (value: any, id: string) => JSX.Element
  } {
    return {
      [WorkflowDataKeys.CHECKBOX]: (isSelected: boolean, id: string) => (
        <Checkbox
          className="full-height grey-checkbox"
          isCentered
          isChecked={isSelected}
          onClick={this.props.selectFormType.bind(null, id)}
        />
      ),
      [WorkflowDataKeys.ICON]: (type: string) => (
        <div className="row x-center y-center">
          <PermitTypeIcon permitType={type} className="row no-grow" />
        </div>
      ),
      [WorkflowDataKeys.NAME]: (name: string, id: string) => (
        <>
          <TextCell text={name} />
          <BaseActionButton
            className="mx4 inverse-scale-blue-theme px15 brada24 beautiful-shadow-hover"
            isEnabled
            title={Localization.translator.edit_verb}
            onClick={this.props.onSelectType.bind(null, id)}
          />
        </>
      ),
      [WorkflowDataKeys.SHOW_IN_APP]: (isEnabled: boolean, id: string) => (
        <ShowInAppModal
          modalClassName="mw300"
          formTypeId={id}
          isShown={isEnabled}
          onEnableChange={this.props.onShowInAppChange}
        />
      ),
    }
  }

  private selectCategory(
    category: ILWFCCategory,
    e: React.MouseEvent<HTMLElement>,
  ) {
    e.stopPropagation()
    this.props.selectCategory(category)
  }
}
