import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { CellMeasurerCache } from 'react-virtualized'

import BaseCompactPopup from '~/client/src/shared/components/BaseCompactPopup/BaseCompactPopup'
import { Loader } from '~/client/src/shared/components/Loader'
import DeliveryControlTypes from '~/client/src/shared/enums/DeliveryControlTypes'
import Keys from '~/client/src/shared/enums/Keys'
import IDeliveryControl, {
  IDeliveryControlOption,
} from '~/client/src/shared/models/IDeliveryControl'
import InitialState from '~/client/src/shared/stores/InitialState'
import { NOOP } from '~/client/src/shared/utils/noop'

import MenuCloser from '../../../MenuCloser'
import DeliveryDetailsStore from '../../DeliveryDetails.store'
import DeliverySelectModalOptionsList from './components/DeliverySelectModalOptionsList'

import './DeliverySelectModal.scss'

interface IProps {
  store: DeliveryDetailsStore

  state?: InitialState
}

const DEFAULT_ROW_HEIGHT = 48

const select = 'Select'
const search = 'Search'
const or = 'or'
const add = 'add'
const loading = 'Loading'

@inject('state')
@observer
export default class DeliverySelectModal extends React.Component<IProps> {
  private readonly cellMeasurerCache: CellMeasurerCache = null

  public constructor(props: IProps) {
    super(props)

    this.cellMeasurerCache = new CellMeasurerCache({
      defaultHeight: DEFAULT_ROW_HEIGHT,
      fixedWidth: true,
    })
  }

  public render() {
    const { state } = this.props
    const isShown = this.shouldShow()

    const onOutsideClickHandler = isShown ? this.onModalClose : NOOP

    return (
      <MenuCloser
        className={classList({
          'delivery-select-modal': true,
          'is-shown': isShown,
        })}
        closeMenu={onOutsideClickHandler}
      >
        <BaseCompactPopup
          childrenClassName="full-height overflow-hidden"
          title={this.title}
          isShown={isShown}
          onHide={this.onModalClose}
          shouldIncludeSearch={true}
          searchPlaceholder={this.searchPlaceholder}
          searchValue={this.selectedField?.newOptionName}
          onSearchValueChange={this.onSearchValueChange}
          onSearchEnterPress={this.handleEnterPress}
          onSearchValueClear={this.clearSearchValue}
        >
          <div className="col full-height">
            {state.isLoading ? <Loader /> : this.renderOptions()}
          </div>
        </BaseCompactPopup>
      </MenuCloser>
    )
  }

  private renderOptions(): JSX.Element | JSX.Element[] {
    if (!this.selectedField) {
      return null
    }

    if (this.shouldExtend) {
      const { newOptionName } = this.selectedField

      return (
        <div onClick={this.handleAddNewOption} className="row full-width">
          <div className="row new-option pointer m15 pa15">
            <Icon icon={IconNames.PLUS} iconSize={15} />
            <span className="ml10">{newOptionName}</span>
          </div>
        </div>
      )
    }

    const {
      isGroupedOptions,
      getOptionsIcon,
      getCategoryLabelIcon,
      areCategoriesCollapsible,
    } = this.selectedField

    const { getCompanyById } = this.props.store

    return (
      <DeliverySelectModalOptionsList
        cellMeasurerCache={this.cellMeasurerCache}
        selectedFieldOptions={this.sortedSelectedFieldOptions}
        scrollToIndex={this.selectedOptionIndex}
        getCompanyById={getCompanyById}
        isOptionSelected={this.isOptionSelected}
        onOptionClick={this.handleOptionChange}
        isGroupedOptions={isGroupedOptions}
        areCategoriesCollapsible={areCategoriesCollapsible}
        getOptionsIcon={getOptionsIcon}
        getCategoryLabelIcon={getCategoryLabelIcon}
        onCategoryCollapse={this.toggleCategory}
      />
    )
  }

  private isOptionSelected = (option: IDeliveryControlOption): boolean => {
    const { value, index, isMultiValueInput } = this.selectedField
    return isMultiValueInput
      ? value?.[index] === option.value
      : value === option.value
  }

  private onModalClose = () => {
    this.props.store.clearSelectedField()

    this.clearCellMeasurerCache()
  }

  private onSearchValueChange = (newValue: string) => {
    const { onNewOptionChange } = this.props.store
    onNewOptionChange(this.selectedField.id, newValue, this.selectedField.index)

    this.clearCellMeasurerCache()
  }

  private clearSearchValue = () => {
    const { onNewOptionClear } = this.props.store
    onNewOptionClear(this.selectedField.id, this.selectedField.index)

    this.clearCellMeasurerCache()
  }

  private handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === Keys.Enter && this.shouldExtend) {
      this.handleAddNewOption(event)
    }
  }

  private handleAddNewOption = (event: React.SyntheticEvent) => {
    event.stopPropagation()

    const { addNewFieldOption } = this.props.store

    if (!this.selectedField.newOptionName) {
      return
    }

    this.clearCellMeasurerCache()

    addNewFieldOption(this.selectedField)
  }

  private handleOptionChange = (optionValue: string) => {
    const { onFieldValueChange, clearSelectedField } = this.props.store

    onFieldValueChange(
      this.selectedField.id,
      optionValue,
      this.selectedField.index,
    )
    this.clearSearchValue()

    this.clearCellMeasurerCache()

    clearSelectedField()
  }

  private clearCellMeasurerCache = () => {
    this.cellMeasurerCache.clearAll()
  }

  private get selectedFieldName(): string {
    return this.selectedField?.hints[0].toLowerCase()
  }

  private get selectedFieldSearchHints(): string {
    const { searchHints, hints } = this.selectedField || {}
    const existingHints = searchHints?.length ? searchHints : hints
    return existingHints?.join(` ${or} `)
  }

  private get title(): string {
    if (!this.selectedField) {
      return null
    }

    return `${select} ${this.selectedFieldName}`
  }

  private get searchPlaceholder(): string {
    if (this.props.state.isLoading) {
      return `${loading}...`
    }

    if (!this.selectedField) {
      return null
    }

    return this.isExtendable
      ? `${search} ${or} ${add} ${this.selectedFieldSearchHints}`
      : `${search} ${this.selectedFieldSearchHints}`
  }

  private get sortedSelectedFieldOptions(): IDeliveryControlOption[] {
    return this.props.store.sortedSelectedFieldOptions
  }

  private get shouldExtend(): boolean {
    return (
      this.isExtendable &&
      !this.sortedSelectedFieldOptions.length &&
      !!this.selectedField.newOptionName?.trim()
    )
  }

  private get isExtendable(): boolean {
    return this.selectedField?.canBeExtended
  }

  private get selectedOptionIndex(): number {
    if (this.selectedField?.isMultiValueInput) {
      const selectedSubfieldValue =
        this.selectedField.value[this.selectedField.index]
      return this.sortedSelectedFieldOptions.findIndex(
        o => !o.hidden && o.value === selectedSubfieldValue,
      )
    }

    return this.sortedSelectedFieldOptions.findIndex(
      o => !o.hidden && o.value === this.selectedField?.value,
    )
  }

  private get selectedField(): IDeliveryControl {
    return this.props.store.selectedField
  }

  private shouldShow(): boolean {
    if (!this.selectedField) return false

    switch (this.selectedField.type) {
      case DeliveryControlTypes.USER_SELECT:
      case DeliveryControlTypes.SELECT:
      case DeliveryControlTypes.COMPANY_SELECT:
      case DeliveryControlTypes.MULTI_COMBOBOX:
        return true

      default:
        return false
    }
  }

  @action.bound
  private toggleCategory(categoryId: string) {
    this.props.store.toggleFieldCategory(this.selectedField.id, categoryId)
    this.clearCellMeasurerCache()
  }
}
