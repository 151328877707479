import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ActivityDayBar from '~/client/src/desktop/views/SimpleGanttView/components/ActivityGanttOrListView/components/SimpleGantChart/ActivityDayBar'
import SimpleGanttChartViewStore from '~/client/src/desktop/views/SimpleGanttView/components/ActivityGanttOrListView/components/SimpleGantChart/SimpleGanttChartViewStore'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import Activity from '~/client/src/shared/models/Activity'
import { ChartDay } from '~/client/src/shared/models/ChartDay'

import ActivityGanttOrListViewStore from '../../ActivityGanttOrListView.store'

interface IActivityDaysCellProps {
  activity?: Activity
  selected?: boolean
  company?: string
  simpleGanttChartViewStore: SimpleGanttChartViewStore
  store: ActivityGanttOrListViewStore
  activityListStore: DesktopActivityListStore
  isActivityRow?: boolean
}

@observer
export default class ActivityDaysCell extends React.Component<IActivityDaysCellProps> {
  public render() {
    const {
      isActivityRow,
      simpleGanttChartViewStore: { days },
    } = this.props

    return days.map((day, index) => {
      return (
        <div
          key={index}
          className={classList({
            'gant-day gantt-cell gantt-row': true,
            weekend: day.isWeekend,
            'activity-row': isActivityRow,
          })}
        >
          {day.isToday && <div className="gant-day line" />}
          {this.renderDayBar(day, index)}
        </div>
      )
    })
  }

  private renderDayBar(day: ChartDay, index: number) {
    const {
      activity,
      simpleGanttChartViewStore,
      company,
      store,
      activityListStore,
    } = this.props
    if (!activity) {
      return
    }

    const { days } = simpleGanttChartViewStore
    return (
      <ActivityDayBar
        activity={activity}
        day={day}
        isLastDayOnChart={index === days.length - 1}
        isFirstDayOnChart={index === 0}
        company={company}
        store={store}
        activityListStore={activityListStore}
        isHoliday={!activity.isWorkingOnDay(day.date)}
      />
    )
  }
}
