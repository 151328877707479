enum ActivityDataFieldId {
  ID = 'id',
  CODE = 'code',
  NAME = 'name',
  PLANNED_START_DATE = 'plannedStartDate',
  PLANNED_FINISH_DATE = 'plannedFinishDate',
  ACTUAL_START_DATE = 'actualStartDate',
  ACTUAL_FINISH_DATE = 'actualFinishDate',
  PERCENT_COMPLETE = 'percentComplete',
  COMPANY_ID = 'companyId',
  REQUESTER_ID = 'requesterId',
  EQUIPMENT_ID = 'equipmentId',
  VERTICAL_OBJECT_ID = 'verticalObjectId',
  BUILDING_ID = 'buildingId',
  LEVEL_ID = 'levelId',
  AREA_ID = 'areaId',
  ZONE_ID = 'zoneId',
  GATE_ID = 'gateId',
  ROUTE_ID = 'routeId',
  TRADE = 'trade',
  LINKING_SETTINGS = 'activityLinkingSettings',
}

export default ActivityDataFieldId
