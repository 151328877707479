import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import { TwoMonthsDatePickerMode } from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import OpacityBackground from '../../SimpleGanttView/components/OpacityBackground/OpacityBackground'
import PhotosPageStore from '../PhotosPageStore.store'
import PhotoDateSelector from './PhotoDateSelector'
import PhotosModeToggle from './PhotosModeToggle'
import PhotosSecondaryFilters from './PhotosSecondaryFilters'
import PhotosViewToggle from './PhotosViewToggle'

import '../Photos.scss'

interface IPhotosHeaderBarProps {
  photosStore: PhotosPageStore
  projectDateStore?: ProjectDateStore
  projectsStore?: ProjectsStore
}

@inject('projectsStore', 'projectDateStore')
@observer
export default class PhotosHeaderBar extends React.Component<IPhotosHeaderBarProps> {
  public constructor(props: IPhotosHeaderBarProps) {
    super(props)
    props.photosStore.setInitialDate()
  }

  public render() {
    const { photosStore } = this.props

    return (
      <>
        <div className="photos-header-bar full-width row">
          <div className="filters-row row">
            <PhotosModeToggle photosStore={photosStore} />
            <PhotosViewToggle photosStore={photosStore} />
            <PhotosSecondaryFilters photosStore={photosStore} />
            <div
              className="row text large primary-blue ml10 mw20 pointer"
              onClick={photosStore.resetAllFilters}
              title="Reset Filters"
            >
              <Icon icon={IconNames.REPEAT} />
            </div>
          </div>
          {this.renderDatePicker()}
        </div>
        <OpacityBackground />
      </>
    )
  }

  private renderDatePicker() {
    const { photosStore, projectsStore, projectDateStore } = this.props
    return (
      <div className="row x-end mr15">
        <PhotoDateSelector
          filters={photosStore.dateFilters}
          shouldEnableFullProjectMode={true}
          mode={TwoMonthsDatePickerMode.FIXED_THREE_WEEKS}
          photosStore={photosStore}
          projectsStore={projectsStore}
          projectDateStore={projectDateStore}
          shouldUseOtherDatesForFullProjectMode={
            photosStore.isDeliveriesModeActive
          }
        />
      </div>
    )
  }
}
