import * as React from 'react'

import { inject, observer } from 'mobx-react'

import WeatherForecastsStore from '~/client/src/shared/stores/domain/WeatherForecasts.store'

import WeatherBar from './WeatherBar'

import './WeatherHourlyWidget.scss'

// localization: no display text to translate

const MIN_BAR_HEIGHT = 124
const MAX_BAR_HEIGHT = 160

interface IProps {
  startDate: number | Date
  startWithDate?: number | Date
  className?: string

  weatherForecastsStore?: WeatherForecastsStore
}

@inject('weatherForecastsStore')
@observer
export default class WeatherHourlyWidget extends React.Component<IProps> {
  public render() {
    const { startDate, weatherForecastsStore } = this.props

    const forecast = weatherForecastsStore.getHourlyForecastForDay(startDate)
    const minTemp = Math.min(...forecast.map(item => item.currentTemp))
    const maxTemp = Math.max(...forecast.map(item => item.currentTemp))

    const delta = (MAX_BAR_HEIGHT - MIN_BAR_HEIGHT) / (maxTemp - minTemp)

    return (
      <div className="weather-hourly-widget py8">
        {forecast.map((x, i) => {
          const height = Math.round(
            MAX_BAR_HEIGHT - delta * (maxTemp - x.currentTemp),
          )

          return (
            <WeatherBar
              height={height}
              key={i}
              forecast={x}
              projectWeatherUnits={weatherForecastsStore.weatherUnits}
            />
          )
        })}
      </div>
    )
  }
}
