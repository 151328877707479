import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'

import * as Icons from '~/client/src/shared/components/Icons'

import ProjectDateStore from '../../../stores/ui/ProjectDate.store'
import BaseActionButton from '../../BaseActionButton/BaseActionButton'
import SwipableDatePicker from '../../SwipableDatePicker/SwipableDatePicker'
import AnnouncementEditionFormStore from '../AnnouncementEditionForm.store'

const SortableItem = SortableElement(({ item }) => item)

const SortableList = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((item, index) => (
        <SortableItem key={item.key} index={index} item={item} />
      ))}
    </div>
  )
})

interface IProps {
  store: AnnouncementEditionFormStore
  initialDate: Date

  projectDateStore?: ProjectDateStore
}

const setAnnouncementOrder = 'Set announcement order'
const resetOrder = 'Reset order'
const done = 'Done'
const current = 'current'

@inject('projectDateStore')
@observer
export default class AnnouncementOrderSetUp extends React.Component<IProps> {
  @observable private selectedDate: Date = null

  public constructor(props: IProps) {
    super(props)

    this.onSelectedDateChanged(props.initialDate)
  }

  public componentDidUpdate(prevProps: IProps) {
    const { initialDate } = this.props
    if (initialDate !== prevProps.initialDate) {
      this.onSelectedDateChanged(initialDate)
    }
  }

  public render() {
    const {
      store,
      projectDateStore: { getWeekdayMonthDayAndYearToDisplay },
    } = this.props
    const {
      hideOrderSetUp,
      resetAnnouncementOrdersToDefault,
      areAnnouncementsOrdersChanged,
    } = store

    return (
      <div className="announcement-order-set-up">
        <div className="row y-center mb10 py4 px12" onClick={this.onBackClick}>
          <div className="no-grow px8">
            <Icon icon={IconNames.CHEVRON_LEFT} />
          </div>
          <div className="text bold small-header center">
            {setAnnouncementOrder}
          </div>
        </div>
        <div className="text center light large pb4">
          {getWeekdayMonthDayAndYearToDisplay(this.selectedDate)}
        </div>
        <SwipableDatePicker
          selectedDate={this.selectedDate}
          onDateChanged={this.onSelectedDateChanged}
        />
        <div className="bt-light-grey px12 py24">
          <SortableList
            items={this.announcementComponents}
            onSortEnd={this.onAnnouncementOrderChanged}
            lockAxis="y"
          />
        </div>
        <div className="bt-light-grey row y-center py12 px24">
          <BaseActionButton
            isActive={false}
            isEnabled={areAnnouncementsOrdersChanged}
            className="mr10 ellipsis gray-theme"
            isGrow={true}
            title={resetOrder}
            onClick={resetAnnouncementOrdersToDefault}
          />
          <BaseActionButton
            isActive={false}
            isEnabled={true}
            className="ellipsis primary-theme"
            isGrow={true}
            title={done}
            onClick={hideOrderSetUp}
          />
        </div>
      </div>
    )
  }

  @action.bound
  private onSelectedDateChanged(date: Date) {
    this.selectedDate = date
  }

  @computed
  private get announcements() {
    return this.props.store.getExistingAndEditableAnnouncementsForDay(
      this.selectedDate,
    )
  }

  private get announcementComponents() {
    const { editableAnnouncement } = this.props.store

    return this.announcements.map((announcement, idx) => (
      <div
        className="announcement-list-item row y-center py15 z-index-120"
        key={announcement.id}
      >
        <div className="no-grow text extra-large red pr24">{idx + 1}.</div>
        <div className="text extra-large">
          {announcement.getDisplayedName(true)}{' '}
          {editableAnnouncement.id === announcement.id && `(${current})`}
        </div>
        <Icons.DoubleVerticalDots className="no-grow" />
      </div>
    ))
  }

  @action.bound
  private onAnnouncementOrderChanged({ oldIndex, newIndex }) {
    const { store, projectDateStore } = this.props
    const { editableAnnouncement, announcementOrderMap } = store

    const announcementIds = this.announcements.map(({ id }) => id)
    const orderedScreenKeys = arrayMove(announcementIds, oldIndex, newIndex)

    orderedScreenKeys.forEach((id: string, idx: number) => {
      const orders =
        !id && editableAnnouncement.id === id
          ? editableAnnouncement.orders
          : announcementOrderMap[id]
      const selectedDateOrder = orders.find(o =>
        projectDateStore.isSameDay(o.date, this.selectedDate),
      )
      if (!selectedDateOrder) {
        orders.push({
          date: this.selectedDate.getTime(),
          order: idx + 1,
        })
        return
      }
      selectedDateOrder.order = idx + 1
    })
  }

  private onBackClick = () => {
    const { hideOrderSetUp, resetAnnouncementOrdersToDefault } =
      this.props.store

    resetAnnouncementOrdersToDefault()
    hideOrderSetUp()
  }
}
