import { observable } from 'mobx'

import { LocationType } from '~/client/graph'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ILocationDto from '~/client/src/shared/types/ILocationDto'

import FieldIds from '../../enums/DeliveryFieldIds'
import LocationAttributeBase from './LocationAttributeBase'

interface ILevelDto extends ILocationDto {
  isNameImplicit?: boolean
}

export default class Level extends LocationAttributeBase<Level> {
  public static fromDto(dto: ILevelDto) {
    const item = LocationAttributeBase.factory(
      Level,
      dto,
      MapViewLocationIcon.Level,
    )

    item.isNameImplicit = !!dto.isNameImplicit

    return item
  }

  @observable public isNameImplicit: boolean

  public type = LocationType.Level
  protected relatedFieldId = FieldIds.LEVEL
  protected model = Level

  public isEqual(dto: Level) {
    return super.isEqual(dto) && this.isNameImplicit === dto.isNameImplicit
  }

  public copy(): Level {
    const item = super.copy()
    item.isNameImplicit = this.isNameImplicit
    return item
  }

  public getDto(): ILevelDto {
    const dto: ILevelDto = super.getDto()
    dto.isNameImplicit = !!this.isNameImplicit
    return dto
  }
}
