import * as React from 'react'

import { action, computed, observable } from 'mobx'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { ProjectResponsibilityTypes } from '~/client/src/shared/constants/ProjectRoles'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import IDeliveryAttributeStore from '~/client/src/shared/stores/domain/interfaces/IDeliveryAttributeStore'
import Guard from '~/client/src/shared/utils/Guard'

import ProjectSetUpPageStore from '../../../../ProjectSetUpPage.store'

class DeliveryRequestSetUpStore {
  @observable public isDeleteConfirmationDialogShown: boolean
  @observable public deletableDeliveryAttributeId: string
  @observable public deletableDeliveryAttributeType: string
  @observable public deletableDeliveryAttributeName: string
  public deletableDeliveryAttributeStore: IDeliveryAttributeStore

  @observable public keyWord = ''
  @observable public resultMembersList: User[] = []
  @observable public editingElementId: string = ''

  private get state() {
    return this.eventsStore.appState
  }

  public constructor(
    private eventsStore: DesktopEventStore,
    private projectSetUpPageStore: ProjectSetUpPageStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly projectMembersStore: ProjectMembersStore,
  ) {
    Guard.requireAll({ eventsStore })
  }

  @action.bound
  public showDeleteConfirmationDialog(
    id: string,
    type: string,
    store: IDeliveryAttributeStore,
  ) {
    this.deletableDeliveryAttributeId = id
    this.deletableDeliveryAttributeType = type
    const dto = store && store.byId.get(id)
    this.deletableDeliveryAttributeName = dto && dto.name
    this.deletableDeliveryAttributeStore = store
    this.isDeleteConfirmationDialogShown = true
  }

  public get isLoading(): boolean {
    return [
      e.ACTIVATE_PROJECT,
      e.SAVE_PROJECT,
      e.GET_PROJECT_MEMBERS,
      e.LOAD_AND_LISTEN_TO_DELIVERY_FIELDS_CONFIGURATIONS,
    ].some(e => this.state.loading.get(e))
  }

  @action.bound
  public hideDeleteConfirmationDialog() {
    this.isDeleteConfirmationDialogShown = false
    this.deletableDeliveryAttributeId = null
    this.deletableDeliveryAttributeType = null
    this.deletableDeliveryAttributeName = null
    this.deletableDeliveryAttributeStore = null
  }

  @action.bound
  public applyDeleteConfirmationDialog() {
    this.deletableDeliveryAttributeStore.removeItems([
      this.deletableDeliveryAttributeId,
    ])
    this.hideDeleteConfirmationDialog()
  }

  @computed
  public get projectMembers(): User[] {
    return this.projectMembersStore.list
  }

  @computed
  public get docMasters() {
    return this.projectMembers.filter(member =>
      UserProject.isDocMaster(member, this.userProjectsStore),
    )
  }

  @computed
  public get inspectors() {
    return this.projectMembers.filter(member =>
      UserProject.isInspector(member, this.userProjectsStore),
    )
  }

  @action.bound
  public handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value

    if (!value) {
      this.clear()
      return
    }

    this.keyWord = value
    this.filtering()
  }

  @action.bound
  public handleClear() {
    this.clear()
  }

  @action.bound
  public handleApply(member: User) {
    if (!member || this.docMasters.find(dm => dm.id === member.id)) {
      return
    }

    this.promoteToDocMaster(member)
  }

  @action.bound
  public handleCancel(member: User) {
    if (!member) {
      return
    }

    this.revokeFromDocMaster(member)
  }

  @action.bound
  public promoteToInspector(member: User) {
    if (!member || this.inspectors.find(dm => dm.id === member.id)) {
      return
    }

    const userProject = this.userProjectsStore.getByUser(member)

    userProject.addResponsibility(ProjectResponsibilityTypes.Inspector)

    this.userProjectsStore.save([userProject.toDto()])
  }

  @action.bound
  public revokeFromInspector(member: User) {
    if (!member) {
      return
    }

    const userProject = this.userProjectsStore.getByUser(member)

    userProject.removeResponsibility(ProjectResponsibilityTypes.Inspector)

    this.userProjectsStore.save([userProject.toDto()])
  }

  private clear() {
    this.keyWord = ''
    this.resultMembersList.length = 0
  }

  private filtering() {
    this.resultMembersList = this.projectMembers.filter(this.filterPredicate)
  }

  private filterPredicate = (member: User) => {
    const keyWord = this.keyWord.toLowerCase().trim()
    const { email, firstName, lastName } = member

    const filterCriteria: string[] = [email || '']
    switch (true) {
      case !!firstName && !!lastName:
        filterCriteria.push(
          `${firstName.toLowerCase()} ${lastName.toLowerCase()}`,
        )
        break
      case !!firstName:
        filterCriteria.push(firstName.toLowerCase())
        break
      case !!lastName:
        filterCriteria.push(lastName.toLowerCase())
    }

    return filterCriteria.some(criterion => criterion.includes(keyWord))
  }

  private async promoteToDocMaster(member: User) {
    const userProject = this.userProjectsStore.getByUser(member)

    userProject.addResponsibility(ProjectResponsibilityTypes.DocMaster)

    this.userProjectsStore.save([userProject.toDto()]).then(this.updateHistory)
  }

  private revokeFromDocMaster(member: User) {
    const userProject = this.userProjectsStore.getByUser(member)

    userProject.removeResponsibility(ProjectResponsibilityTypes.DocMaster)

    this.userProjectsStore.save([userProject.toDto()]).then(this.updateHistory)
  }

  private updateHistory = () => {
    const { id, email, firstName = null, lastName = null } = this.state.user

    this.projectSetUpPageStore.setPageLastUpdated({
      pageName: 'workflows',
      updateInfo: {
        updatedAt: Date.now(),
        by: { id, email, firstName, lastName },
      },
    })
    return this.projectSetUpPageStore.saveCurrentProjectHistory()
  }
}

export default DeliveryRequestSetUpStore
