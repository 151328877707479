import { computed, observable } from 'mobx'

import {
  ICompanyStatusUpdate,
  IStatusUpdate,
  IStatusUpdateInput,
  IThread,
  StatusUpdateType,
} from '~/client/graph'
import Thread from '~/client/src/shared/models/Thread'
import {
  UNASSIGNED,
  UNASSIGNED_FILTER_VALUE,
} from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import { sortCaseInsensitive } from '~/client/src/shared/utils/collections'

import Localization from '../../shared/localization/LocalizationManager'
import ProjectDateStore from '../stores/ui/ProjectDate.store'
import BaseModel from './BaseModel'
import IStatusUpdateToUpdateDates from './IStatusUpdateToUpdateDates'

export default class StatusUpdate extends BaseModel<IStatusUpdate> {
  public static readonly MAX_PERCENT = 100
  public static readonly MIN_PERCENT = 0
  public static readonly UNASSIGNED_COMPANY_VALUE = ''
  public static readonly UNASSIGNED_COMPANY_LABEL = 'Unassigned'
  public static readonly DEFAULT_ACTIVITY_ID = '-1'
  public static readonly DEFAULT_COMPANIES: ICompanyStatusUpdate[] = []

  public static readonly SCHEDULE_UPDATE = 'Schedule Update'
  public static readonly ACTIVITY_UPDATE = 'Activity Update'
  public static readonly BULK_UPDATE = 'Bulk Update'

  public static none: StatusUpdate

  public static createWithDefaults(percentComplete?: number): StatusUpdate {
    const s = new StatusUpdate(null)
    s.updateFromJson({
      id: null,
      activityP6Code: StatusUpdate.DEFAULT_ACTIVITY_ID,
      authorId: '',
      createdAt: 0,
      dateFor: 0,
      manpower: 0,
      percentComplete: percentComplete || 0,
      updatedAt: 0,
      threadId: null,
      companyName: StatusUpdate.UNASSIGNED_COMPANY_VALUE,
      plannedPercentComplete: 0,
      isInherited: false,
      type: StatusUpdateType.Active,
      companies: StatusUpdate.DEFAULT_COMPANIES,
      projectId: null,
    })

    return s
  }

  public static fromDto(dto: IStatusUpdate): StatusUpdate {
    const statusUpdate = new StatusUpdate(dto.id)
    statusUpdate.updateFromJson(dto)
    return statusUpdate
  }

  public manpower = 0
  public percentComplete = 0
  public activityP6Code = ''
  public authorId = null
  public threadId
  public companyName = StatusUpdate.UNASSIGNED_COMPANY_VALUE
  public plannedPercentComplete = 0
  public isInherited = false
  public type = StatusUpdateType.Active
  public companies: ICompanyStatusUpdate[] = StatusUpdate.DEFAULT_COMPANIES
  public manpowerUpdatedAt: number = 0
  public percentCompleteUpdatedAt: number = 0
  public updateVersion: number = 0
  public dateFor: number = 0
  public actualStartDate: number = 0
  public actualFinishDate: number = 0
  public isDeleted: boolean = false
  public projectId: string

  public getValidCompanies(companies: ICompanyStatusUpdate[]) {
    return (
      companies?.map(company => {
        const companyStatus = Object.assign(new CompanyStatusUpdate(), company)
        if (!company.hasOwnProperty('manpowerUpdatedAt')) {
          companyStatus.manpowerUpdatedAt = company.updatedAt
        }
        if (!company.hasOwnProperty('percentCompleteUpdatedAt')) {
          companyStatus.percentCompleteUpdatedAt = company.updatedAt
        }
        return companyStatus
      }) || StatusUpdate.DEFAULT_COMPANIES
    )
  }

  // TODO: add author from json
  public updateFromJson(json: IStatusUpdate) {
    this.validateJson(json)
    const {
      id,
      authorId,
      manpower,
      percentComplete,
      activityP6Code,
      threadId,
      createdAt,
      updatedAt,
      companyName,
      plannedPercentComplete,
      dateFor,
      percentCompleteUpdatedAt,
      manpowerUpdatedAt,
      isInherited,
      companies,
      updateVersion,
      actualStartDate,
      actualFinishDate,
      isDeleted,
      type,
      projectId,
    } = json

    this.id = id
    this.authorId = authorId
    this.manpower = manpower
    this.percentComplete = percentComplete
    this.threadId = threadId
    this.activityP6Code = activityP6Code
    this.companyName = companyName || StatusUpdate.UNASSIGNED_COMPANY_VALUE
    this.plannedPercentComplete = plannedPercentComplete || 0
    this.setCreatedAt(createdAt)
    this.setUpdatedAt(updatedAt)
    this.manpowerUpdatedAt = manpowerUpdatedAt
    this.percentCompleteUpdatedAt = percentCompleteUpdatedAt
    this.dateFor = dateFor
    this.actualStartDate = actualStartDate || 0
    this.actualFinishDate = actualFinishDate || 0
    this.isDeleted = isDeleted || false
    this.isInherited = isInherited || false
    this.companies = this.getValidCompanies(companies)
    this.updateVersion = updateVersion || 0
    this.type = type || StatusUpdateType.Active
    this.projectId = projectId
  }

  public validateJson(json: IStatusUpdate) {
    const { manpower, percentComplete, activityP6Code } = json
    if (manpower < 0) {
      throw new RangeError(`Invalid manpower value: ${manpower}`)
    }

    if (percentComplete < 0 || percentComplete > StatusUpdate.MAX_PERCENT) {
      throw new RangeError(`Invalid percent complete value: ${percentComplete}`)
    }

    if (!activityP6Code) {
      throw new Error(`Invalid activity code: ${activityP6Code}`)
    }

    return true
  }

  public setThread(thread: Thread | IThread): void {
    if (thread) {
      this.threadId = thread.id
    }
  }

  public getCompanyStatus(
    companyName: string,
    isToday: (date: Date | number) => boolean,
  ): ICompanyStatusUpdate {
    if (!this.isMultiCompany) {
      return {
        percentComplete: this.percentComplete,
        manpower: this.manpower,
        updatedAt: this.dateFor,
        didUpdate: isToday(this.updatedAt),
        manpowerUpdatedAt: this.manpowerUpdatedAt,
        percentCompleteUpdatedAt: this.percentCompleteUpdatedAt,
        userId: this.authorId,
        isDeleted: this.isDeleted,
      }
    }

    return (
      this.findCompanyStatus(companyName) || {
        percentComplete: 0,
        manpower: 0,
        updatedAt: 0,
        didUpdate: false,
        manpowerUpdatedAt: 0,
        percentCompleteUpdatedAt: 0,
        userId: null,
      }
    )
  }

  public findCompanyStatus(companyName: string): ICompanyStatusUpdate {
    return this.companies.find(c => c.companyName === companyName)
  }

  public get asJson(): IStatusUpdateInput {
    return {
      id: this.id || null,
      activityP6Code: this.activityP6Code,
      authorId: this.authorId,
      manpower: this.manpower,
      percentComplete: this.percentComplete,
      dateFor: this.dateFor,
      threadId: this.threadId,
      companyName: this.companyName,
      plannedPercentComplete: this.plannedPercentComplete,
      isInherited: this.isInherited,
      companies: this.companies,
      manpowerUpdatedAt: this.manpowerUpdatedAt,
      percentCompleteUpdatedAt: this.percentCompleteUpdatedAt,
      updateVersion: this.updateVersion,
      type: this.type,
      actualStartDate: this.actualStartDate,
      actualFinishDate: this.actualFinishDate,
      isDeleted: this.isDeleted,
      projectId: this.projectId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    }
  }

  public getStatusUpdateToUpdateDates(): IStatusUpdateToUpdateDates {
    return {
      dateFor: this.dateFor,
      isFinished: this.isFinished,
      type: this.type,
      companiesCount: this.companyNamesList.length,
      actualFinishDate: this.actualFinishDate,
      actualStartDate: this.actualStartDate,
      isUpdateDeleted: this.isUpdateDeleted,
    }
  }

  public get typeCaption() {
    switch (this.type) {
      case StatusUpdateType.Active:
        return Localization.translator.activityUpdate
      case StatusUpdateType.P6:
        return Localization.translator.scheduleUpdate
      case StatusUpdateType.Bulk:
        return Localization.translator.bulkUpdate

      default:
        return Localization.translator.activityUpdate
    }
  }

  public get isFromP6(): boolean {
    return this.type === StatusUpdateType.P6
  }

  @computed
  public get companyNamesList(): string[] {
    return sortCaseInsensitive(this.companies.map(c => c.companyName))
  }

  public get isOldOrUnassigned() {
    return !this.companyNamesList.length
  }

  public get isFinished(): boolean {
    return this.percentComplete === StatusUpdate.MAX_PERCENT
  }

  public get isUnassigned(): boolean {
    return this.companyName === StatusUpdate.UNASSIGNED_COMPANY_VALUE
  }

  public get isUnassignedFromDb(): boolean {
    return this.isUnassigned && !this.isDefault
  }

  public get isAssigned(): boolean {
    return this.companyName !== StatusUpdate.UNASSIGNED_COMPANY_VALUE
  }

  @computed
  public get isMultiCompany(): boolean {
    return !!this.companies.length
  }

  public get isDefault(): boolean {
    return this.activityP6Code === StatusUpdate.DEFAULT_ACTIVITY_ID
  }

  public isContainsCompany(companyName: string): boolean {
    const company =
      companyName === UNASSIGNED ? UNASSIGNED_FILTER_VALUE : companyName
    if (!this.isMultiCompany) {
      return this.companyName === company
    }

    return !!this.findCompanyStatus(company)
  }

  public isLater(statusUpdate: StatusUpdate): boolean {
    return (
      (this.dateFor - statusUpdate.dateFor ||
        this.updatedAt - statusUpdate.updatedAt ||
        this.updateVersion - statusUpdate.updateVersion) > 0
    )
  }

  public get isUpdateDeleted() {
    if (this.isUnassigned) {
      return this.isDeleted
    }
    return this.companies.every(cs => cs.isDeleted)
  }

  public didUpdateByAllCompanies = (
    companies: string[],
    projectDateStore: ProjectDateStore,
    ignoreManpower?: boolean,
    ignorePercentComplete?: boolean,
  ): boolean => {
    return companies.every(company =>
      this.didUpdateByCompany(
        company,
        projectDateStore.isSameDay,
        ignoreManpower,
        ignorePercentComplete,
      ),
    )
  }

  public didUpdateByCompany = (
    companyName: string,
    isSameDay: (date1: Date | number, date2: Date | number) => boolean,
    ignoreManpower?: boolean,
    ignorePercentComplete?: boolean,
  ): boolean => {
    const company =
      companyName === UNASSIGNED ? UNASSIGNED_FILTER_VALUE : companyName
    if (!this.isContainsCompany(company)) {
      return false
    }

    const isToday = date => isSameDay(date, new Date())

    const {
      manpowerUpdatedAt,
      percentCompleteUpdatedAt,
      updatedAt,
      isDeleted,
    } = this.getCompanyStatus(company, isToday)

    const statusUpdateDate = new Date(+updatedAt)

    if (!ignoreManpower && !isSameDay(statusUpdateDate, manpowerUpdatedAt)) {
      return false
    }
    if (
      !ignorePercentComplete &&
      !isSameDay(statusUpdateDate, percentCompleteUpdatedAt)
    ) {
      return false
    }
    return !isDeleted && !!updatedAt
  }
}

StatusUpdate.none = StatusUpdate.createWithDefaults()

class CompanyStatusUpdate implements ICompanyStatusUpdate {
  @observable public percentComplete: number = 0
  @observable public manpower: number = 0
  @observable public didUpdate: boolean = false
  @observable public updatedAt: number = 0
  @observable public manpowerUpdatedAt: number = 0
  @observable public percentCompleteUpdatedAt: number = 0
  @observable public isDeleted: boolean
}
