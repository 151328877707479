import Colors from '~/client/src/shared/theme.module.scss'

const PRE_POPULATED_COLORS = [
  Colors.primary60,
  Colors.primary20,
  Colors.green70,
  Colors.green20,
  Colors.secondary60,
  Colors.secondary50,
  Colors.error50,
  Colors.magenta40,
  Colors.purple30,
]

export default PRE_POPULATED_COLORS
