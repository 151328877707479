import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import EventsStore from '../../stores/EventStore/Events.store'
import PermitTypesStore from '../../stores/domain/PermitTypes.store'
import CommonStore from '../../stores/ui/Common.store'
import BaseActionButton from '../BaseActionButton/BaseActionButton'
import { Content, Footer, Header, View } from '../Layout'
import { Loader } from '../Loader'
import PermitTypeIcon from '../PermitTypeIcon/PermitTypeIcon'
import PermitTypeSelectorStore from './PermitTypeSelector.store'

import './PermitTypeSelector.scss'

// localization: translated

interface IProps {
  eventsStore?: EventsStore
  permitTypesStore?: PermitTypesStore
  common?: CommonStore
}

@inject('eventsStore', 'permitTypesStore', 'common')
@observer
export default class PermitTypeSelector extends React.Component<IProps> {
  private readonly store: PermitTypeSelectorStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new PermitTypeSelectorStore(
      props.eventsStore,
      props.permitTypesStore,
      props.common,
    )
  }

  public render() {
    if (this.store.isLoading) {
      return <Loader />
    }

    const {
      permitTypes,
      applySelection,
      selectPermitType,
      selectedPermitTypeId,
    } = this.store

    return (
      <View className="permit-type-selector-container">
        <Header>
          <div className="text header center bold py30">
            {Localization.translator.selectWorkForm}
          </div>
        </Header>
        <Content scrollable={true}>
          {permitTypes.map((permitType, index) => {
            const { id, name } = permitType

            return (
              <div
                key={id}
                className={classList({
                  'row pa24 bb-light-input-border pointer': true,
                  'bt-light-input-border': index === 0,
                  'active-type': selectedPermitTypeId === id,
                })}
                onClick={selectPermitType.bind(null, id)}
              >
                <PermitTypeIcon
                  permitType={permitType?.type}
                  className="type-icon row mr12 no-grow"
                />

                <span className="text extra-large">{name}</span>
                {id === selectedPermitTypeId && (
                  <Icon icon={IconNames.TICK} className="no-grow" />
                )}
              </div>
            )
          })}
        </Content>
        <Footer className="pa10">
          <div className="col">
            <BaseActionButton
              onClick={applySelection}
              title={Localization.translator.select}
              isEnabled={!!selectedPermitTypeId}
              className="scale-blue-theme py20"
            />
          </div>
        </Footer>
      </View>
    )
  }
}
