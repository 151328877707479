import Localization from '~/client/src/shared/localization/LocalizationManager'

import QuestionnaireType from '../enums/QuestionnaireType'
import { InstructionListType } from '../models/PermitType'
import { BULLET, EMPTY_STRING, PERIOD } from './usefulStrings'

export type QuestionnaireOption = { value: boolean; title: string; key: string }

const NA_KEY = 'N/A'
const YES_KEY = 'Yes'
const NO_KEY = 'No'
const FAIL_KEY = 'Fail'
const PASS_KEY = 'Pass'

export function getBullet(listType: string, i: number): string {
  switch (listType) {
    case InstructionListType.Bulleted:
      return BULLET
    case InstructionListType.Enumerated:
      return i + PERIOD

    default:
      return EMPTY_STRING
  }
}

export function getQuestionnaireOptionsByType(
  type: QuestionnaireType,
): QuestionnaireOption[] {
  switch (type) {
    case QuestionnaireType.YesNo:
      return [
        {
          value: false,
          title: Localization.translator.no,
          key: NO_KEY,
        },
        {
          value: true,
          title: Localization.translator.yes,
          key: YES_KEY,
        },
      ]

    case QuestionnaireType.YesNoNA:
      return [
        {
          value: null,
          title: Localization.translator.NA,
          key: NA_KEY,
        },
        {
          value: false,
          title: Localization.translator.no,
          key: NO_KEY,
        },
        {
          value: true,
          title: Localization.translator.yes,
          key: YES_KEY,
        },
      ]

    case QuestionnaireType.PassFail:
      return [
        {
          value: false,
          title: Localization.translator.fail,
          key: FAIL_KEY,
        },
        {
          value: true,
          title: Localization.translator.pass_verb,
          key: PASS_KEY,
        },
      ]

    default:
      return []
  }
}

export function getQuestionnaireTypeToDisplayName(
  type: QuestionnaireType,
): string {
  const options = getQuestionnaireOptionsByType(type)
  const optionsNames = options.map(({ title }) => title).join(', ')

  return `${Localization.translator.xOptions(options.length)} (${optionsNames})`
}
