import { observable } from 'mobx'

import { ISitemapPin } from '~/client/graph'

import { areObjectsEqual } from '../../../utils/util'

const DEFAULT_IS_DISPLAYED = true

export default class GlobeViewIconProperties {
  @observable public isDisplayed: boolean

  public constructor(isDisplayed?: boolean) {
    this.isDisplayed =
      typeof isDisplayed === 'boolean' ? isDisplayed : DEFAULT_IS_DISPLAYED
  }

  public isEqual(properties: GlobeViewIconProperties) {
    return areObjectsEqual(this, properties)
  }

  public toggleIsDisplayed() {
    this.isDisplayed = !this.isDisplayed
  }

  public isDisplayDataEqual(pin: ISitemapPin) {
    return !pin.isHidden === this.isDisplayed
  }

  public isValid() {
    return true
  }

  public getDisplayData(): ISitemapPin {
    return {
      isHidden: !this.isDisplayed,
    }
  }

  public copy(): GlobeViewIconProperties {
    return new GlobeViewIconProperties(this.isDisplayed)
  }
}
