import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import { FilterType, LocationType } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import ActivityCode from '~/client/src/shared/models/ActivityCode'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import ActivityCodeLocationRelationshipsStore from '~/client/src/shared/stores/domain/ActivityCodeLocationRelationships.store'
import BuildingsStore from '~/client/src/shared/stores/domain/Buildings.store'
import LevelsStore from '~/client/src/shared/stores/domain/Levels.store'
import ZonesStore from '~/client/src/shared/stores/domain/Zones.store'

import MappingLocationSelector from './MappingLocationSelector'

const NONE_VALUE = null

const LOCATION_TYPE_BY_FILTER_TYPE = {
  [FilterType.Building]: LocationType.Building,
  [FilterType.Level]: LocationType.Level,
  [FilterType.Zone]: LocationType.Zone,
}

interface IProps {
  code: ActivityCode
  filterType: FilterType
  buildingsStore?: BuildingsStore
  levelsStore?: LevelsStore
  zonesStore?: ZonesStore
  activityCodeLocationRelationshipsStore?: ActivityCodeLocationRelationshipsStore
}

@inject(
  'buildingsStore',
  'levelsStore',
  'zonesStore',
  'activityCodeLocationRelationshipsStore',
)
@observer
export default class LocationObjectsSelector extends React.Component<IProps> {
  public render() {
    if (!this.locationObjects.length) {
      return null
    }

    return (
      <div>
        {this.existingRelationships.length ? (
          this.existingRelationships.map(rel => (
            <div key={rel.id} className="row y-center">
              <Icons.HorizontalArrows className="row blue-icon pr12 no-grow" />
              {this.renderLocationSelector(rel.locationObjectId)}
            </div>
          ))
        ) : (
          <div className="pl40">{this.renderLocationSelector(NONE_VALUE)}</div>
        )}
      </div>
    )
  }

  private renderLocationSelector(valueId: string) {
    const options = this.getAvailableOptions(valueId)
    if (!options.length) {
      return null
    }
    const value = options.find(o => o.id === valueId)

    return (
      <MappingLocationSelector
        value={value}
        options={options}
        applyLocation={this.applyLocation}
      />
    )
  }

  private applyLocation = (
    newLocation: LocationBase,
    prevLocation: LocationBase,
  ) => {
    const prevRel =
      prevLocation &&
      this.existingRelationships.find(
        r => r.locationObjectId === prevLocation.id,
      )

    if (prevRel) {
      if (newLocation) {
        prevRel.locationObjectId = newLocation.id
        prevRel.locationObjectType = newLocation.type
        this.props.activityCodeLocationRelationshipsStore.saveRelationship(
          prevRel,
        )
      } else {
        this.props.activityCodeLocationRelationshipsStore.removeRelationship(
          prevRel,
        )
      }
    } else {
      if (newLocation) {
        this.props.activityCodeLocationRelationshipsStore.createRelationship(
          this.code.id,
          newLocation.id,
          newLocation.type,
        )
      }
    }
  }

  private get locationType(): LocationType {
    const { filterType } = this.props
    return LOCATION_TYPE_BY_FILTER_TYPE[filterType]
  }

  private get code(): ActivityCode {
    return this.props.code
  }

  @computed
  private get existingRelationships() {
    return this.props.activityCodeLocationRelationshipsStore.list.filter(
      rel =>
        rel.locationObjectType === this.locationType &&
        rel.activityCodeId === this.code.id,
    )
  }

  @computed
  private get hasRelationshipMap() {
    const res = {}
    this.existingRelationships.forEach(rel => {
      res[rel.locationObjectId] = true
    })
    return res
  }

  @computed
  private get locationObjects(): LocationBase[] {
    const { filterType } = this.props
    switch (filterType) {
      case FilterType.Building:
        return this.props.buildingsStore.list
      case FilterType.Level:
        return this.props.levelsStore.list
      case FilterType.Zone:
        return this.props.zonesStore.list
      default:
        return []
    }
  }

  private getAvailableOptions(currentValue: string): LocationBase[] {
    return this.locationObjects.filter(
      ({ id }) => !this.hasRelationshipMap[id] || currentValue === id,
    )
  }
}
