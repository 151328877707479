import { computed, observable } from 'mobx'

import { ICompanyRelationship } from '~/client/graph/types.generated'

import ActivityCompanyRelationship from '../../models/ActivityCompanyRelationship'
import EventsStore from '../EventStore/Events.store'
import {
  DELETE_ACTIVITY_COMPANY_RELATIONSHIP,
  SAVE_ACTIVITY_COMPANY_RELATIONSHIP,
} from '../EventStore/eventConstants'

export default class ActivityCompanyRelationshipsStore {
  @observable public isDataReceived = false

  public constructor(private eventStore: EventsStore) {}

  @computed
  public get list(): ActivityCompanyRelationship[] {
    const { activityCompanyRelationships } = this.eventStore.appState
    return Array.from(activityCompanyRelationships.values())
  }

  public get byId() {
    const { activityCompanyRelationships } = this.eventStore.appState
    return activityCompanyRelationships
  }

  public clearList() {
    this.isDataReceived = false
    const { activityCompanyRelationships } = this.eventStore.appState
    activityCompanyRelationships.clear()
  }

  public receiveList(list: ICompanyRelationship[]) {
    this.isDataReceived = false
    const { activityCompanyRelationships } = this.eventStore.appState
    activityCompanyRelationships.clear()

    list.forEach(dto => {
      const rel = ActivityCompanyRelationship.fromDto(dto)
      activityCompanyRelationships.set(rel.id, rel)
    })

    this.isDataReceived = true
  }

  public createRelationship(stxCompanyId: string, externalCompanyId: string) {
    const dto = this.getRelationshipDto(stxCompanyId, externalCompanyId)
    this.saveRelationship(dto)
  }

  public getRelationshipDto(stxCompanyId: string, externalCompanyId: string) {
    return new ActivityCompanyRelationship(
      null,
      this.eventStore.appState.activeScheduleId,
      stxCompanyId,
      externalCompanyId,
    )
  }

  public getCompanyIdByResourceId(id: string) {
    return this.list.find(relationship => relationship.externalCompanyId === id)
      ?.struxHubCompanyId
  }

  public receiveOne(id: string, dto: ICompanyRelationship) {
    const { activityCompanyRelationships } = this.eventStore.appState
    if (dto) {
      const rel = ActivityCompanyRelationship.fromDto(dto)
      activityCompanyRelationships.set(rel.id, rel)
    } else {
      activityCompanyRelationships.delete(id)
    }
  }

  public saveRelationship(dto: ActivityCompanyRelationship) {
    this.eventStore.dispatch(SAVE_ACTIVITY_COMPANY_RELATIONSHIP, dto)
  }

  public removeRelationship(rel: ActivityCompanyRelationship) {
    this.eventStore.dispatch(DELETE_ACTIVITY_COMPANY_RELATIONSHIP, rel.id)
  }
}
