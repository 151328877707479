import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import StruxhubTextArea from '~/client/src/shared/components/StruxhubInputs/StruxhubTextArea'
import Keys from '~/client/src/shared/enums/Keys'

import EditableTextFieldStore from './EditableTextField.store'

interface IProps {
  value: string

  onChange(updatedValue: string): void

  className?: string
  inputClassName?: string
}

@observer
export default class EditableTextField extends React.Component<IProps> {
  private readonly store: EditableTextFieldStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new EditableTextFieldStore(props.value, props.onChange)
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { value } = this.props
    if (value !== prevProps.value) {
      this.store.setDraftValue(value)
    }
  }

  public render() {
    const { className, inputClassName } = this.props
    const { draftValue, isInEditingMode, setToEditingMode, applyChange } =
      this.store

    return isInEditingMode ? (
      <StruxhubTextArea
        className={classList({
          [className]: !!className && !inputClassName,
          [inputClassName]: !!inputClassName,
        })}
        isMinimalisticMode={true}
        onChange={this.handleUpdate}
        onKeyPress={this.handleKeyPress}
        onBlur={applyChange}
        defaultValue={draftValue}
        autoFocus={true}
      />
    ) : (
      <span className={className} onDoubleClick={setToEditingMode}>
        {draftValue}
      </span>
    )
  }

  private handleUpdate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.store.updateDraftValue(event.target.value)
  }

  private handleKeyPress = (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (event.key === Keys.Enter && !event.shiftKey) {
      this.store.applyChange()
    }
  }
}
