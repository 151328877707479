import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import { ITag } from '~/client/src/shared/models/Tag'

import TagCellEditorStore from './TagCellEditor.store'

import './TagCellEditor.scss'

interface IProps {
  tag: ITag

  onSave(tag: ITag): void
}

const ICON_SIZE = 16
const tagColor = 'Tag Color'
const enterTagName = 'Enter a tag name'

@observer
export default class TagCellEditor extends React.PureComponent<IProps> {
  private readonly store: TagCellEditorStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new TagCellEditorStore(props.tag, props.onSave)
  }

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.tag !== this.props.tag) {
      this.store.init(this.props.tag)
    }
  }

  public componentWillUnmount() {
    this.store.saveTag()
  }

  public render() {
    const { draftInputValue } = this.store

    return (
      <div
        className="tag-cell-editor col absolute full-width bg-white beautiful-shadow brada4"
        onClick={this.stopPropagation}
      >
        <div className="row relative full-width px20 tag-input-row">
          <input
            type="text"
            className="text large bare-input full-height full-width no-outline"
            autoFocus={true}
            placeholder={enterTagName}
            value={draftInputValue}
            onChange={this.changeDraftInputValue}
          />
        </div>
        <div className="bb-light-input-border" />
        {this.renderTagColorSelector()}
      </div>
    )
  }

  private renderTagColorSelector(): JSX.Element {
    const { selectedColor, tagColorObjs, changeColor } = this.store

    return (
      <>
        <div className="tag-color-text row text grey-light uppercase px20">
          {tagColor}
        </div>
        <div className="overflow-auto colors-column">
          {tagColorObjs.map((tagObj, idx) => {
            const isSelected = selectedColor === tagObj.color

            return (
              <div
                key={idx}
                className={classList({
                  'tag-color-row row x-between px20': true,
                  'highlighted-hover': !isSelected,
                })}
                onClick={changeColor.bind(this, tagObj.color)}
              >
                <div className="row no-flex-children">
                  <SitemapAttributeTag
                    dataObject={tagObj as any}
                    shouldShowAsTag={true}
                    contentContainerClassName="text-ellipsis py2"
                    iconSize={ICON_SIZE}
                  />
                  <span className="pl12 text large">{tagObj.title}</span>
                </div>
                {isSelected && (
                  <Icons.CheckPaletteBlue className="tag-color-selected no-grow" />
                )}
              </div>
            )
          })}
        </div>
      </>
    )
  }

  private changeDraftInputValue = (
    evt: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.store.changeDraftInputValue(evt?.target?.value)
  }

  private stopPropagation = (evt: React.SyntheticEvent) => {
    evt?.stopPropagation()
  }
}
