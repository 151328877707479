export enum UserFieldId {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  Company = 'companyId',
  Roles = 'roles',
  Trades = 'trades',
  Teams = 'teams',
  DefaultTeams = 'defaultTeams',
  AccountType = 'accountType',
  AvatarUrl = 'avatarUrl',
  Initials = 'initials',
  GlobalRole = 'globalRole',
  AccountPosition = 'accountPosition',
  AssignedCodes = 'assignedCodes',
  EmployeeId = 'employeeId',
}

export default UserFieldId
