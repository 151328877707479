import React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { Html5QrcodeScanner } from 'html5-qrcode'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { QRCode } from 'react-qrcode-logo'

import { IAssociatedCode, ISiteLocation } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import Scanner, {
  SCAN_CODE_SEPARATOR,
  ScanCodeTypes,
} from '../../../models/Scanner'
import User from '../../../models/User'
import InitialState from '../../../stores/InitialState'
import ScannersStore from '../../../stores/domain/Scanners.store'
import UserProjectsStore from '../../../stores/domain/UserProjects.store'
import { EMPTY_STRING, QR_CODE_LOGO_SRC } from '../../../utils/usefulStrings'
import BaseActionButton from '../../BaseActionButton/BaseActionButton'
import StruxhubSelect from '../../StruxhubInputs/StruxhubSelect'
import WorkflowCardLocationLabel from '../../WorkflowCard/LocationLabel'
import dynamicQrBoxPercent from '../QRCodesHelper'

import Colors from '~/client/src/shared/theme.module.scss'

const qrbox = dynamicQrBoxPercent()
const QR_CODE_TO_PRINT_SIZE = 500
const selectBadgeToAdd = 'Select Badge to Add'
const assignAccessOrBadge = 'Assign Access or Badge'
const assignAdditionalQRCodesToUser = 'Assign additional QR codes to user'
const badgesList = 'Badges List'
const userAssociatedCodes = 'User associated codes'
const scanCodeToAssignItToTheUser = 'Scan code to assign it to the user'

interface IProps {
  user: User

  printSize?: number
  scannersStore?: ScannersStore
  userProjectsStore?: UserProjectsStore
  state?: InitialState

  shouldShowQRCode?: boolean
  className?: string
}

@inject('scannersStore', 'userProjectsStore', 'state')
@observer
export default class QRCodeAccess extends React.Component<IProps> {
  @observable private selectedScanner: Scanner = null
  private html5QrcodeScanner: Html5QrcodeScanner = null

  public render(): JSX.Element {
    const {
      user,
      userProjectsStore,
      scannersStore,
      printSize = QR_CODE_TO_PRINT_SIZE,
      shouldShowQRCode,
      className = '',
    } = this.props
    const { getByUser } = userProjectsStore
    const { userBadgesMap, getScannerById, scannersList } = scannersStore
    const { id: userId } = user

    const userProject = getByUser(user)

    const scannersIds = userBadgesMap[userId]
    const freeScanners = scannersList.filter(
      sc => !scannersIds?.includes(sc.id),
    )

    return (
      <div className={className}>
        <div className="text bold uppercase light lp05 py12">
          {assignAccessOrBadge}
        </div>
        {scannersIds && (
          <div className="col px30">
            <div className="text hude bold py10">{badgesList}</div>
            {scannersIds.map(scannerId => {
              const scanner = getScannerById(scannerId)
              return this.renderScannerListItem(scanner)
            })}
          </div>
        )}
        <div className="col px10">
          <StruxhubSelect
            label={selectBadgeToAdd}
            value={this.selectedScanner?.id || EMPTY_STRING}
            onChange={this.onScannerSelect}
            isRequiredTextHidden={true}
            className="mw270"
          >
            <option value={EMPTY_STRING}>-</option>
            {freeScanners.map(sc => {
              return (
                <option className="row text large" key={sc.id} value={sc.id}>
                  {sc.name}
                </option>
              )
            })}
          </StruxhubSelect>
        </div>
        <>
          <div className="col pb20">
            <div className="text bold uppercase light lp05 py12 pl220">
              {assignAdditionalQRCodesToUser}
            </div>
            {!!userProject.associatedCodes?.length && (
              <>
                <div className="text hude bold pa10">{userAssociatedCodes}</div>
                {userProject.associatedCodes.map(userCode =>
                  this.renderAssociatedCode(userCode),
                )}
              </>
            )}
          </div>
          <div className="col pl30 no-grow w-fit-content mb20">
            <div className="text hude bold py10">
              {scanCodeToAssignItToTheUser}
            </div>
            <BaseActionButton
              title={'Activate scanner'}
              className="secondary-blue-theme bold"
              isEnabled={true}
              onClick={this.initiateScanner}
            />
            <div id="reader" className="full-width" />
          </div>
        </>
        {shouldShowQRCode && (
          <div className="col x-center my20">
            <QRCode
              value={this.userQRValue}
              logoImage={QR_CODE_LOGO_SRC}
              size={printSize}
              fgColor={ThemeMode.getHEXColor(Colors.neutral0)}
            />
            <div className="row x-center text bold size24">
              <Icons.User className="no-grow" />
              {this.props.user.fullName}
            </div>
          </div>
        )}
      </div>
    )
  }

  private get userQRValue(): string {
    return `${ScanCodeTypes.user}${SCAN_CODE_SEPARATOR}${this.props.user.id}`
  }

  private assignScannerToUser = (): void => {
    const { id: userId } = this.props.user
    this.selectedScanner.allowedUsers = [
      ...this.selectedScanner.allowedUsers,
      userId,
    ]
    const scanner = this.selectedScanner.copy()
    this.selectedScanner = null
    this.props.scannersStore.save(scanner.getDto())
  }

  private assignCodeToUser = (code: IAssociatedCode): void => {
    const { user } = this.props
    const userProject = this.props.userProjectsStore.getByUser(user)

    this.props.userProjectsStore.setCode(userProject, code)
  }

  private unAssignCodeToUser = (code: IAssociatedCode): void => {
    const { user } = this.props
    const userProject = this.props.userProjectsStore.getByUser(user)

    this.props.userProjectsStore.setCode(userProject, code)
  }

  private unAssignScannerToUser = (scannerId: string): void => {
    const { id: userId } = this.props.user
    const scanner = this.props.scannersStore.getScannerById(scannerId)
    scanner.allowedUsers = scanner.allowedUsers.filter(user => user !== userId)

    this.props.scannersStore.save(scanner.getDto())
  }

  private onScannerSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.selectedScanner = this.props.scannersStore.getScannerById(
      e.target.value,
    )
    this.assignScannerToUser()
  }

  private renderScannerListItem(scanner: Scanner): JSX.Element {
    const locations: ISiteLocation = scanner.isRealLocationRelated &&
      scanner.location && {
        id: scanner.location.id,
        type: scanner.location.type,
      }

    return (
      <div className="pr10 pl10 py5 ba-light-grey qr-small-holder text large overflow-hidden brada10 my5 w-fit-content">
        <div className="col">
          <div className="row pt5 title-row pb10">
            <Icon
              icon={IconNames.SEARCH_TEMPLATE}
              className="no-grow mr4 main-icon announcement-icon"
            />
            <span className="bold extra-large inline-block text title-text vertical-align-text-bottom w-fit-content">
              {scanner.name}
            </span>
            {locations && (
              <div className="nowrap no-grow">
                <WorkflowCardLocationLabel
                  locations={[locations]}
                  isOneColor={false}
                  shouldShowAsTag={true}
                />
              </div>
            )}
            <Icon
              icon={IconNames.CROSS}
              onClick={this.unAssignScannerToUser.bind(null, scanner.id)}
              className="no-grow ml10"
            />
          </div>
          <span className="large inline-block text title-text vertical-align-text-bottom">
            {scanner.badgeName}
          </span>
        </div>
      </div>
    )
  }

  private renderAssociatedCode(code: IAssociatedCode): JSX.Element {
    return (
      <div
        className="mr10 mb5 w-fit-content px30 py5 ba-light-grey qr-small-holder text large overflow-hidden brada10"
        key={code.id}
      >
        <div className="col">
          <div className="row pt5 title-row pb10">
            <Icon
              icon={IconNames.HELPER_MANAGEMENT}
              className="no-grow mr4 main-icon announcement-icon"
            />
            <span className="bold extra-large inline-block text title-text vertical-align-text-bottom overflow-auto">
              {code.id}
            </span>
            <Icon
              icon={IconNames.CROSS}
              onClick={this.unAssignCodeToUser.bind(null, code)}
              className="no-grow ml10"
            />
          </div>
          <span className="large inline-block text title-text vertical-align-text-bottom">
            {code.name}
          </span>
        </div>
      </div>
    )
  }

  private initiateScanner = (): void => {
    this.html5QrcodeScanner = new Html5QrcodeScanner(
      'reader',
      { fps: 10, qrbox },
      true,
    )
    this.html5QrcodeScanner.render(this.onScanSuccess, this.onScanError)
  }

  private onScanError(): void {
    console.log(`Scan error`)
  }

  private onScanSuccess = (decodedText): void => {
    this.html5QrcodeScanner.clear()
    this.html5QrcodeScanner = null
    const code: IAssociatedCode = {
      name: 'Helmet Code',
      id: decodedText,
    }
    this.assignCodeToUser(code)
  }
}
