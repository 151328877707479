import { observable } from 'mobx'

import { LocationType } from '~/client/graph'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import InitialState from '~/client/src/shared/stores/InitialState'
import IHierarchyParent from '~/client/src/shared/types/IHierarchyParent'
import ILocationDto from '~/client/src/shared/types/ILocationDto'
import IMsDateInterval from '~/client/src/shared/types/IMsDateInterval'
import Guard from '~/client/src/shared/utils/Guard'
import { DEFAULT_SITEMAP_ITEM_COLOR } from '~/client/src/shared/utils/SitemapItemsColors'

import FieldIds from '../../enums/DeliveryFieldIds'
import CompaniesStore from '../../stores/domain/Companies.store'
import {
  areArraysEqual,
  areObjectArraysEqual,
  areObjectsEqual,
  copyObject,
  copyObjectArray,
} from '../../utils/util'
import { LocationIntegrationType } from './LocationIntegration'

export default abstract class LocationBase<T extends LocationBase<T> = any>
  implements ILocationDto
{
  public static factory<U>(
    model: new (...args: any) => U,
    dto: ILocationDto,
    defaultIcon: MapViewLocationIcon,
  ): U {
    return new model(
      dto.id,
      dto.name,
      dto.color || DEFAULT_SITEMAP_ITEM_COLOR,
      dto.iconName || defaultIcon,
      dto.projectId,
      dto.code,
      Object.values(dto.operatingIntervals || {}),
      dto.parent,
      Object.values(dto.permittedCompanies || {}),
      dto.isDeleted,
      Object.values(dto.assignedSitemaps || {}),
      Object.values(dto.assignedGlobes || {}),
    )
  }

  public static generateCodeFromName(name: string) {
    return name
      .split(' ')
      .map(part => part.slice(0, 3))
      .filter(l => l)
      .slice(0, 3)
      .join('')
      .toUpperCase()
  }

  @observable public id: string
  @observable public name: string
  @observable public code: string
  @observable public color: string
  @observable public iconName: MapViewLocationIcon
  public projectId: string
  @observable public operatingIntervals: IMsDateInterval[] = []
  @observable public permittedCompanies: string[] = []
  @observable public parent: IHierarchyParent = null
  @observable public isDeleted: boolean = false
  @observable public assignedSitemaps: string[] = []
  @observable public assignedGlobes: string[] = []

  public abstract type: LocationType
  protected abstract relatedFieldId: FieldIds
  protected abstract model: new (...args: any) => T

  public constructor(
    id: string,
    name: string,
    color: string,
    iconName: MapViewLocationIcon,
    projectId: string,
    code?: string,
    operatingIntervals: IMsDateInterval[] = [],
    parent?: IHierarchyParent,
    permittedCompanies: string[] = [],
    isDeleted: boolean = false,
    assignedSitemaps: string[] = [],
    assignedGlobes: string[] = [],
  ) {
    Guard.requireAll({ name, color, projectId, iconName })
    this.id = id
    this.name = name
    this.code = code || LocationBase.generateCodeFromName(name)
    this.color = color
    this.iconName = iconName
    this.projectId = projectId
    this.operatingIntervals = operatingIntervals
    this.parent = parent
    this.permittedCompanies = permittedCompanies
    this.isDeleted = isDeleted
    this.assignedSitemaps = assignedSitemaps
    this.assignedGlobes = assignedGlobes
  }

  public is(type: LocationType | LocationIntegrationType): boolean {
    return this.type === type
  }

  public get hasParent(): boolean {
    return !!this.parent
  }

  public get hasAnyCompanyRestriction(): boolean {
    return !!this.permittedCompanies.length
  }

  public getAllPermittedCompaniesNames = (
    companiesStore: CompaniesStore,
  ): string[] =>
    this.permittedCompanies.map(companyId =>
      companiesStore.getCompanyNameById(companyId),
    ) || []

  public hasRestrictionForCompany = (companyId: string): boolean => {
    return this.hasRestrictionForCompanies([companyId])
  }

  public hasRestrictionForCompanies = (companyIds: string[]): boolean => {
    if (!this.hasAnyCompanyRestriction) {
      return false
    }

    return (
      !companyIds?.length ||
      companyIds.some(id => !this.permittedCompanies.includes(id))
    )
  }

  public isParent(dto: LocationBase): boolean {
    return (
      dto &&
      this.parent &&
      this.parent.parentId === dto.id &&
      this.parent.parentType === dto.type
    )
  }

  public hasCommonParent(dto: LocationBase): boolean {
    return (
      dto &&
      dto.parent &&
      this.parent &&
      this.parent.parentId === dto.parent.parentId &&
      this.parent.parentType === dto.parent.parentType
    )
  }

  public isGlobeAssigned(globeId: string) {
    return this.assignedGlobes?.includes(globeId)
  }

  public assignGlobe(globeId: string) {
    if (!this.isGlobeAssigned(globeId)) {
      this.assignedGlobes.push(globeId)
    }
  }

  public deassignGlobe(globeId: string) {
    if (this.isGlobeAssigned(globeId)) {
      this.assignedGlobes = this.assignedGlobes.filter(id => id !== globeId)
    }
  }

  public isSitemapAssigned(sitemapId: string) {
    return this.assignedSitemaps && this.assignedSitemaps.includes(sitemapId)
  }

  public assignSitemap(sitemapId: string) {
    if (!this.isSitemapAssigned(sitemapId)) {
      this.assignedSitemaps.push(sitemapId)
    }
  }

  public deassignSitemap(sitemapId: string) {
    if (this.isSitemapAssigned(sitemapId)) {
      this.assignedSitemaps = this.assignedSitemaps.filter(
        id => id !== sitemapId,
      )
    }
  }

  public isEqual(dto: T) {
    return (
      this.id === dto.id &&
      this.name === dto.name &&
      this.code === dto.code &&
      this.iconName === dto.iconName &&
      this.color === dto.color &&
      this.projectId === dto.projectId &&
      areObjectArraysEqual(this.operatingIntervals, dto.operatingIntervals) &&
      areObjectsEqual(this.parent || {}, dto.parent || {}) &&
      areArraysEqual(this.permittedCompanies, dto.permittedCompanies) &&
      this.isDeleted === dto.isDeleted &&
      areArraysEqual(this.assignedSitemaps || [], dto.assignedSitemaps || []) &&
      areArraysEqual(this.assignedGlobes || [], dto.assignedGlobes || [])
    )
  }

  public copy(): T {
    return new this.model(
      this.id,
      this.name,
      this.color,
      this.iconName,
      this.projectId,
      this.code,
      copyObjectArray(this.operatingIntervals),
      this.parent && copyObject(this.parent),
      this.permittedCompanies,
      this.isDeleted,
      this.assignedSitemaps,
      this.assignedGlobes,
    )
  }

  public getDto(): ILocationDto {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
      color: this.color,
      iconName: this.iconName,
      projectId: this.projectId,
      operatingIntervals: this.operatingIntervals.map(i => ({
        startDate: i.startDate,
        endDate: i.endDate,
      })),
      parent: this.parent && {
        parentId: this.parent.parentId,
        parentType: this.parent.parentType,
      },
      permittedCompanies: this.permittedCompanies,
      assignedSitemaps: this.assignedSitemaps,
      assignedGlobes: this.assignedGlobes,
    }
  }

  public getFieldName(state: InitialState): string {
    return state.getDeliveryFieldName(this.relatedFieldId)
  }

  public getHierarchyChains(tagStoreByTagTypeMap: any): string[] {
    const chain = this.getHierarchyChainObjs(tagStoreByTagTypeMap)

    return chain.map(obj => obj.name)
  }

  public getHierarchyChainsObjects(tagStoreByTagTypeMap: any): LocationBase[] {
    return this.getHierarchyChainObjs(tagStoreByTagTypeMap)
  }

  public getHierarchyChainObjs(tagStoreByTagTypeMap: any): LocationBase[] {
    return this.retrieveHierarchyChains(this, [], tagStoreByTagTypeMap)
  }

  private retrieveHierarchyChains(
    obj: LocationBase,
    path: LocationBase[],
    tagStoreByTagTypeMap: any,
  ): LocationBase[] {
    if (!obj.hasParent) {
      return path
    }

    const { parentId, parentType } = obj.parent
    const objStore = tagStoreByTagTypeMap[parentType]
    if (!objStore) {
      return []
    }

    const parentObj = objStore.getInstanceById(parentId) as LocationBase
    if (!parentObj) {
      return []
    }

    return this.retrieveHierarchyChains(
      parentObj,
      path.concat(parentObj),
      tagStoreByTagTypeMap,
    )
  }
}
