import * as React from 'react'

import { Circle, Group, Path } from 'react-konva'

import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import * as Paths from '../LocationPaths'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_SIZE = 20

const SHADOW_BLUR = 2
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3

const { Crane, Hoist, AerialLift, Gradall, ForkLift } = MapViewLocationIcon

const ICON_BY_TYPE = {
  [Crane]: {
    PATH: Paths.CRANE_PATH,
    SCALE: 0.75,
  },
  [Hoist]: {
    PATH: Paths.HOIST_PATH,
    SCALE: 0.75,
    OFFSET_Y: 1,
  },
  [AerialLift]: {
    PATH: Paths.AERIAL_LIFT_PATH,
    SCALE: 0.7,
  },
  [Gradall]: {
    PATH: Paths.GRADALL_PATH,
    SCALE: 0.72,
  },
  [ForkLift]: {
    PATH: Paths.FORK_LIFT_PATH,
    SCALE: 0.78,
    OFFSET_X: 1.5,
  },
}

const STROKE_WIDTH = 1
const { getHEXColor } = ThemeMode

const KonvaEquipmentTypeIcon = ({ color, type, ...rest }) => {
  const path = ICON_BY_TYPE[type].PATH
  const SCALE = ICON_BY_TYPE[type].SCALE || 0.9
  const OFFSET_X = ICON_BY_TYPE[type].OFFSET_X || 2
  const OFFSET_Y = ICON_BY_TYPE[type].OFFSET_Y || 2
  const halfSize = ICON_SIZE / 2

  const isFilled = [Crane, AerialLift].includes(type)

  if (!path) {
    return null
  }

  return (
    <Group {...rest} offsetX={halfSize} offsetY={halfSize}>
      <Circle
        radius={halfSize}
        x={halfSize}
        y={halfSize}
        fill={getHEXColor(color)}
        stroke={getHEXColor(Colors.neutral100)}
        strokeWidth={STROKE_WIDTH}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
      <Path
        data={path}
        fill={getHEXColor(isFilled ? Colors.neutral100 : null)}
        stroke={getHEXColor(isFilled ? null : Colors.neutral100)}
        scaleX={SCALE}
        scaleY={SCALE}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
        x={OFFSET_X}
        y={OFFSET_Y}
      />
    </Group>
  )
}

export default KonvaEquipmentTypeIcon
