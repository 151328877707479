import * as React from 'react'

import { Classes, Icon, PopoverPosition } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { ItemRenderer, Select } from '@blueprintjs/select'
import { computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { Loader } from '~/client/src/shared/components/Loader'
import StruxhubTextValueSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'
import InitialState from '~/client/src/shared/stores/InitialState'

import './LevelCombobox.scss'

// translated

const MAX_VALUE = 190
const MIN_VALUE = 0
const MAX_OPTION_SELECT_VALUE = 30

interface IProps {
  state?: InitialState
  label: string
  value: number
  onChange: (amount: number) => void
}

@inject('state')
@observer
export default class LevelCombobox extends React.Component<IProps> {
  @observable public customOption: number = 0
  private readonly options = [
    ...new Array(MAX_OPTION_SELECT_VALUE).fill(0),
  ].map((n, idx) => ({
    label: idx,
    value: idx,
  }))

  private inputRef: HTMLInputElement = null

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.value !== prevProps.value) {
      this.forceUpdate()
      this.customOption = this.props.value
    }
  }

  public componentDidMount() {
    this.customOption = this.props.value
  }

  public render() {
    const { state, value, label } = this.props

    const options = state.isLoading
      ? [this.renderLoader()]
      : [this.renderCustomOptionInput()].concat(this.optionElements)

    return (
      <Select
        items={options}
        disabled={state.isLoading}
        itemRenderer={this.renderItem}
        onItemSelect={null}
        popoverProps={{
          popoverClassName: 'level-combobox-modal scrollable mw120',
          position: PopoverPosition.TOP,
          minimal: true,
          onClosed: this.handleOnClose,
        }}
        filterable={false}
      >
        <StruxhubTextValueSelector
          label={label}
          value={value.toString()}
          isRequiredTextHidden={true}
        />
      </Select>
    )
  }

  private handleOnClick = () => {
    this.inputRef?.focus()
  }

  private handleChange = (optionValue: number) => {
    const { onChange } = this.props
    onChange(optionValue)
  }

  private isOptionSelected(option: any): boolean {
    const { value } = this.props
    return value === option.value
  }

  private renderItem: ItemRenderer<JSX.Element> = (item, { index }) => {
    return (
      <div key={index} className="mw100">
        {item}
      </div>
    )
  }

  private get optionElements(): JSX.Element[] {
    if (this.customOption >= MAX_OPTION_SELECT_VALUE) {
      return [this.customOptionInput]
    }

    return this.sortedOptions.map((option, index) => {
      return (
        <div
          className={classList({
            'level-selector-option py8 px16': true,
            [Classes.POPOVER_DISMISS]: true,
            'bg-grey': this.isOptionSelected(option),
          })}
          key={index}
        >
          <div
            onClick={this.handleChange.bind(this, option.value)}
            className="row y-center full-width full-height"
          >
            <span className="ellipsis">{option.value}</span>
          </div>
        </div>
      )
    })
  }

  private get customOptionInput(): JSX.Element {
    return (
      <div
        onClick={this.setNewOpt}
        className={`row full-width full-height py8 px16 ${Classes.POPOVER_DISMISS}`}
      >
        <div className="row new-option-tag pointer">
          <Icon icon={IconNames.PLUS} iconSize={15} />
          <span className="ml10">{this.customOption}</span>
        </div>
      </div>
    )
  }

  private renderLoader(): JSX.Element {
    return (
      <div className="loader-container full-height full-width absolute-block unclickable-element">
        <Loader />
      </div>
    )
  }

  private renderCustomOptionInput(): JSX.Element {
    return (
      <div className="service-option-adding-new" onClick={this.handleOnClick}>
        <div className="new-option-container row no-outline-container">
          <input
            ref={this.setInputRef}
            max={MAX_VALUE}
            min={MIN_VALUE}
            type="number"
            autoFocus={true}
            onChange={this.onOptionChange}
            className="new-option-input input-box py10 px16 brada5 mw100"
            value={this.customOption.toString()}
          />
        </div>
      </div>
    )
  }

  private onOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.customOption = Number(event.target.value)
  }

  private setNewOpt = () => {
    this.props.onChange(this.customOption)
  }

  private setInputRef = (ref: HTMLInputElement) => {
    this.inputRef = ref
  }

  private handleOnClose = () => {
    this.customOption = this.props.value
  }

  @computed
  private get sortedOptions() {
    if (this.customOption === this.props.value) {
      return this.options
    }

    return this.options
      .filter(({ label }) =>
        label.toString().includes(this.customOption.toString()),
      )
      .sort((o1, o2) => o1.label - o2.label)
  }
}
