import { computed } from 'mobx'

import Announcement from '../../models/Announcement'
import ILogisticItem, { LogisticItemApp } from '../../models/ILogisticItem'
import { IFilters } from '../InitialState'
import ActivitiesStore from '../domain/Activities.store'
import CompaniesStore from '../domain/Companies.store'
import DeliveriesStore from '../domain/Deliveries.store'
import SitePermitsStore from '../domain/SitePermits.store'
import BaseLogisticsStore from './BaseLogistics.store'

export default abstract class BaseLogisticsMapStore {
  public constructor(
    protected readonly store: BaseLogisticsStore,
    protected readonly companiesStore: CompaniesStore,
    protected readonly deliveriesStore: DeliveriesStore,
    protected readonly sitePermitsStore: SitePermitsStore,
    protected readonly activitiesStore: ActivitiesStore,
  ) {}

  protected abstract get filter(): IFilters

  @computed
  public get filteredCollection() {
    return this.getFilteredCollectionExcludeFilter()
  }

  public get allLogistics(): ILogisticItem[] {
    const {
      convertEntitiesToLogistics,
      convertFormsToLogistics,
      allAnnouncements,
      allDeliveries,
      allActivities,
    } = this.store

    return [
      ...convertFormsToLogistics(this.sitePermitsStore.list),
      ...convertEntitiesToLogistics(
        [],
        [],
        [],
        allAnnouncements,
        allDeliveries,
        allActivities,
      ),
    ]
  }

  public get instancesInPeriodInterval(): ILogisticItem[] {
    const {
      convertEntitiesToLogistics,
      convertFormsToLogistics,
      formsByCreationDate,
      announcementsInPeriodInterval,
      deliveriesInPeriodInterval,
      activitiesInPeriodInterval,
    } = this.store

    return [
      ...convertFormsToLogistics(formsByCreationDate),
      ...convertEntitiesToLogistics(
        [],
        [],
        [],
        announcementsInPeriodInterval,
        deliveriesInPeriodInterval,
        activitiesInPeriodInterval,
      ),
    ]
  }

  public getFilteredCollectionExcludeFilter = (
    excludedFilters: Array<string> = [],
  ) => {
    let filteredCollection = this.isSearchFilterActive
      ? this.instancesInPeriodInterval.filter(this.searchFiltering)
      : this.instancesInPeriodInterval

    this.activeFilterTypes.forEach(filterType => {
      const shouldUseFilter = !excludedFilters.includes(filterType)

      if (!shouldUseFilter) {
        return
      }
      const filter = this.filter.fieldsMap[filterType]
      const { appliedFilterOptions } = filter

      filteredCollection = filteredCollection.filter(instance =>
        appliedFilterOptions.includes(instance.id),
      )
    })

    return filteredCollection
  }

  public get orderedAnnouncements(): Announcement[] {
    return this.store.orderedAnnouncementsOnSelectedDate
  }

  @computed
  public get filteredAndOrderedAnnouncements(): Announcement[] {
    const filteredAnnouncements = this.filteredCollection.filter(
      logistic => logistic.app === LogisticItemApp.ANNOUNCEMENT,
    )

    return this.orderedAnnouncements.filter(an =>
      filteredAnnouncements.some(i => i.entityId === an.id),
    )
  }

  @computed
  public get filteredPermits() {
    return this.filteredCollection
      .filter(logistic => logistic.app === LogisticItemApp.FORM)
      .map(logistic => this.sitePermitsStore.getFormById(logistic.id))
  }

  @computed
  public get filteredDeliveries() {
    return this.filteredCollection
      .filter(logistic => logistic.app === LogisticItemApp.DELIVERY)
      .map(logistic => this.deliveriesStore.byId.get(logistic.id))
  }

  @computed
  public get filteredActivities() {
    return this.filteredCollection
      .filter(logistic => logistic.app === LogisticItemApp.SCHEDULE)
      .map(logistic => this.activitiesStore.byId.get(logistic.id))
  }

  @computed
  protected get activeFilterTypes(): string[] {
    const { fieldsMap = {} } = this.filter
    return Object.keys(fieldsMap).filter(
      filterType => fieldsMap[filterType].isFilterActive,
    )
  }

  protected searchFiltering = (logistic: ILogisticItem) => {
    const key = this.filter.searchKey.toLowerCase()
    const entityValues: string[] = [
      ...logistic.locations.map(l => l.name),
      logistic.name,
      logistic.status,
      ...logistic.companyIds.map(id =>
        this.companiesStore.getCompanyNameById(id),
      ),
    ]
    return entityValues.some(value => value?.toLowerCase().includes(key))
  }

  protected get isSearchFilterActive(): boolean {
    return !!this.filter.searchKey
  }
}
