import { action, observable } from 'mobx'

import ActivityPreset from '~/client/src/shared/models/ActivityPreset'
import BaseEventStore from '~/client/src/shared/stores/EventStore/BaseEvents.store'
import {
  DELETE_ACTIVITY_PRESET,
  SAVE_ACTIVITY_PRESET,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import Guard from '~/client/src/shared/utils/Guard'

export default class ActivityPresetsStore {
  @observable public editablePresetName = ''

  public constructor(private eventsStore: BaseEventStore) {
    Guard.requireAll({ eventsStore })
  }

  public get list(): ActivityPreset[] {
    return this.eventsStore.appState.activityPresets
  }

  public getById(id: string): ActivityPreset {
    return id ? this.list.find(preset => preset.id === id) : null
  }

  @action.bound
  public saveOne(activities: string[]) {
    const name = this.editablePresetName
    this.eventsStore.dispatch(SAVE_ACTIVITY_PRESET, null, name, activities)
  }

  @action.bound
  public editOne(id: string, name: string, activities: string[]) {
    this.eventsStore.dispatch(SAVE_ACTIVITY_PRESET, id, name, activities)
  }

  @action.bound
  public removeOne(presetId: string) {
    this.eventsStore.dispatch(DELETE_ACTIVITY_PRESET, presetId)
  }

  @action.bound
  public resetActivitiesSetName() {
    this.editablePresetName = ''
  }

  @action.bound
  public onNameChange(event) {
    this.editablePresetName = event.target.value || ''
  }

  @action.bound
  public onNameReset() {
    this.editablePresetName = ''
  }
}
