import React, { PureComponent } from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'

import { formatTopMetricsValues, getClassNameByDelta } from '../Utils/Utils'

interface IProps {
  value: string | number
  delta: number
  islessIsGood?: boolean
}

@observer
export default class TopLevelMetric extends PureComponent<IProps> {
  private renderArrow() {
    const { delta, islessIsGood } = this.props
    const colorClassName = getClassNameByDelta(delta, islessIsGood)

    if (colorClassName === 'red') {
      return islessIsGood ? (
        <Icon icon={IconNames.CARET_UP} className="text red" />
      ) : (
        <Icon icon={IconNames.CARET_DOWN} className="text red" />
      )
    } else if (colorClassName === 'blue') {
      return islessIsGood ? (
        <Icon icon={IconNames.CARET_DOWN} className="text blue" />
      ) : (
        <Icon icon={IconNames.CARET_UP} className="text blue" />
      )
    }

    return null
  }

  public render() {
    const { value, delta } = this.props
    const islessIsGood = this.props.islessIsGood || false
    const colorClassName = getClassNameByDelta(delta, islessIsGood)

    return (
      <div className="full-width row">
        <span className="text extra-large no-grow bold">
          {formatTopMetricsValues(value)}
        </span>
        {!isNaN(delta) && (
          <span
            className={`${colorClassName} text medium pl12 row y-end y-center bold`}
          >
            {`${formatTopMetricsValues(delta * 100)}%`}
            {this.renderArrow()}
          </span>
        )}
      </div>
    )
  }
}
