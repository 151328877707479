import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'

// localization: translated

interface IProps {
  onReset: () => void
  color?: string
}

@observer
export default class FilterResetter extends React.PureComponent<IProps> {
  public static defaultProps = {
    color: 'primary-blue',
  }

  public render() {
    const { color, onReset } = this.props

    return (
      <div
        className={classList({
          'row text large ml10 mw20 pointer': true,
          [color]: !!color,
        })}
        onClick={onReset}
        title={Localization.translator.resetFilters}
      >
        <Icon icon={IconNames.REPEAT} />
      </div>
    )
  }
}
