import { RecipientType } from '~/client/graph'
import {
  DeleteManyAnnouncementAssignmentsDocument,
  SaveManyAnnouncementAssignmentsDocument,
} from '~/client/graph/operations/generated/AnnouncementAssignments.generated'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'

import Assignment from '../../models/Assignment/Assignment'
import User from '../../models/User'
import BaseAssignmentsStore from '../BaseAssignments.store'
import InitialState from '../InitialState'
import ProjectMembersStore from './ProjectMembers.store'
import TagsStore from './Tags.store'

export default class AnnouncementAssignmentsStore extends BaseAssignmentsStore {
  public constructor(
    eventsStore: EventsStore,
    tagsStore: TagsStore,
    private projectMembersStore: ProjectMembersStore,
    private state: InitialState,
  ) {
    super(
      eventsStore,
      tagsStore,
      SaveManyAnnouncementAssignmentsDocument,
      DeleteManyAnnouncementAssignmentsDocument,
    )
  }

  public createAssignmentForUsers(
    assignmentId: string = Assignment.draftId,
    usersIds: string[],
    announcementId: string,
    isAllMode: boolean,
  ): Assignment {
    return new Assignment(
      assignmentId,
      announcementId,
      this.state.activeProject.id,
      [
        {
          type: RecipientType.User,
          ids: usersIds,
        },
      ],
      isAllMode,
    )
  }

  public extractUsersFromAssignment(assignment: Assignment): User[] {
    if (!assignment) return []

    if (assignment.all) return this.allProjectMembers

    const users = new Set<User>()

    assignment.recipients.map(recipient => {
      if (recipient.type === RecipientType.User) {
        recipient.ids.forEach(userId => {
          const user = this.projectMembersStore.getById(userId)
          if (user) users.add(user)
        })
      } else {
        recipient.ids.forEach(tagId => {
          const tagUsers = this.tagsStore.allUsersByRelatedTagIdMap[tagId]
          tagUsers?.forEach(user => users.add(user))
        })
      }
    })

    return [...users]
  }

  public get allProjectMembers(): User[] {
    return this.projectMembersStore.list
  }

  public get byId() {
    return this.eventsStore.appState.announcementAssignments
  }
}
