import * as React from 'react'

import { inject, observer } from 'mobx-react'

import WeatherLabel from '~/client/src/shared/components/WeatherLabel/WeatherLabel'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import WeatherForecastsStore from '~/client/src/shared/stores/domain/WeatherForecasts.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

interface IProps {
  date: Date
  className?: string

  weatherForecastsStore?: WeatherForecastsStore
  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore', 'weatherForecastsStore')
@observer
export default class WeatherForecastBar extends React.Component<IProps> {
  public componentDidMount() {
    this.props.weatherForecastsStore.fetchWeeklyForecast(this.props.date)
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.date !== prevProps.date) {
      this.props.weatherForecastsStore.fetchWeeklyForecast(this.props.date)
    }
  }

  public render() {
    const {
      date,
      className,
      weatherForecastsStore: { weatherByDaysList, weatherUnits },
      projectDateStore,
    } = this.props

    const weatherForecast = weatherByDaysList.find(w =>
      projectDateStore.isSameDay(w.date, date),
    )

    return (
      <div className={className}>
        <span className="text light small bold uppercase lp15 pl12">
          {Localization.translator.forecast}:
        </span>
        <WeatherLabel
          className="px8 no-grow"
          forecast={weatherForecast}
          projectWeatherUnits={weatherUnits}
        />
      </div>
    )
  }
}
