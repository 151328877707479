import { action, observable } from 'mobx'

import { IRecurringDeliveriesSettings } from '~/client/graph'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'

import RecurringDeliveriesSettings from '../../models/RecurringDeliveriesSettings'
import { customDebounce } from '../../utils/util'
import EventsStore from '../EventStore/Events.store'

const FETCH_DEBOUNCE_TIME = 300

export default class RecurringDeliveriesSettingsStore {
  @observable private recurringSettingsMap = new Map<
    string,
    RecurringDeliveriesSettings
  >()

  public constructor(private readonly eventsStore: EventsStore) {
    this.loadRecurringSetting = customDebounce(
      this.loadRecurringSetting,
      FETCH_DEBOUNCE_TIME,
    )
  }

  public loadRecurringSetting = (id: string) => {
    if (!id || this.recurringSettingsMap.has(id)) {
      return
    }

    this.eventsStore.dispatch(e.GET_RECURRING_DELIVERIES_SETTING, id)
  }

  public getById = (id: string): RecurringDeliveriesSettings => {
    return this.recurringSettingsMap.get(id)
  }

  @action.bound
  public receiveOne(id: string, dto: IRecurringDeliveriesSettings) {
    if (dto) {
      this.recurringSettingsMap.set(
        id,
        RecurringDeliveriesSettings.fromDto(dto),
      )
    } else {
      this.recurringSettingsMap.delete(id)
    }
  }

  @action.bound
  public clearData() {
    this.recurringSettingsMap.clear()
  }
}
