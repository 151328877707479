import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized'

import { Loader } from '~/client/src/shared/components/Loader'
import { TagType } from '~/client/src/shared/enums/TagType'
import { ITag } from '~/client/src/shared/models/Tag'
import { NOOP } from '~/client/src/shared/utils/noop'

import TagsDirectoryStore, {
  ITagData,
  TagDataKey,
} from '../TagsDirectory.store'
import TagsDirectoryListRow from './components/TagsDirectoryListRow/TagsDirectoryListRow'

interface IProps {
  activeTab: TagType
  cellMeasurerCache: CellMeasurerCache

  tagsDirectoryStore: TagsDirectoryStore
}

const OVERSCAN_ROW_COUNT = 6

const members = 'Members'
const loading = 'Loading'
const noTags = 'No tags'

@observer
export default class TagsDirectoryList extends React.Component<IProps> {
  private list: List = null

  public componentDidUpdate(prevProps: IProps) {
    if (
      prevProps.activeTab !== this.props.activeTab ||
      this.store.shouldShowNewTagRow
    ) {
      this.scrollToFirstRow()
    }
  }

  public render() {
    const { tagsData, isLoading } = this.store
    const { cellMeasurerCache } = this.props

    return (
      <>
        {this.renderTagListHeader()}
        {isLoading ? (
          <Loader hint={loading} />
        ) : (
          <div className="virtualized-list-smart-wrapper mr30">
            <AutoSizer>
              {({ width, height }) => (
                <List
                  ref={this.setList}
                  deferredMeasurementCache={cellMeasurerCache}
                  data={tagsData}
                  width={width}
                  height={height}
                  rowCount={tagsData.length}
                  overscanRowCount={OVERSCAN_ROW_COUNT}
                  scrollToAlignment="start"
                  rowHeight={cellMeasurerCache.rowHeight}
                  rowRenderer={this.renderRow}
                  noRowsRenderer={this.renderNoTagsMessage}
                  className="tags-directory-list"
                />
              )}
            </AutoSizer>
          </div>
        )}
      </>
    )
  }

  private renderTagListHeader(): JSX.Element {
    const { selectedTabTitle } = this.store

    return (
      <header className="row bb-brand-dark lp05 py8 mx30 tags-directory-list-header">
        <div className="text large bold uppercase tag-header-column pl20">
          {selectedTabTitle}
        </div>
        <div className="text large bold uppercase members-header-column ml10 pr20">
          {members}
        </div>
      </header>
    )
  }

  private renderRow = ({ key, style, index, parent }: any): JSX.Element => {
    const { cellMeasurerCache } = this.props

    const tagData = this.store.tagsData[index]

    if (!tagData) {
      return null
    }

    return (
      <CellMeasurer
        cache={cellMeasurerCache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <div style={style} ref={registerChild} className="col">
            {this.renderTagDataByKey(tagData, index === 0)}
          </div>
        )}
      </CellMeasurer>
    )
  }

  private renderTagDataByKey = (
    tagData: ITagData,
    isFirstElement: boolean,
  ): JSX.Element => {
    if (!tagData) {
      return null
    }

    const { dataKey, data, isDefaultTag } = tagData

    switch (dataKey) {
      case TagDataKey.STRING:
        return this.renderStringRow(data, isFirstElement)
      case TagDataKey.TAG:
        return this.renderTagDirectoryRow(data, isDefaultTag)
      default:
        return null
    }
  }

  private renderStringRow = (
    data: string,
    isFirstElement: boolean,
  ): JSX.Element => {
    if (!data) {
      return null
    }

    return (
      <div
        className={classList({
          'm20 pa5 text extra-large bold': true,
          'bt-light-cool-grey': !isFirstElement,
        })}
      >
        {data}
      </div>
    )
  }

  private renderTagDirectoryRow = (data: ITag, isDefaultTag: boolean) => {
    if (!data) {
      return null
    }

    const {
      getUsersByTagId,
      saveTag,
      setTagToRemoveAndOpenModal,
      changeTagForUser,
      showUserEditDialog,
    } = this.store

    const users = getUsersByTagId(data.id)

    return (
      <TagsDirectoryListRow
        tag={data}
        users={users}
        shouldDisableTagEditing={isDefaultTag}
        onTagSave={isDefaultTag ? NOOP : saveTag}
        onTagDelete={isDefaultTag ? NOOP : setTagToRemoveAndOpenModal}
        onChangeTagForUser={changeTagForUser}
        onUserProfileClick={showUserEditDialog}
        saveUsers={this.saveUsers}
      />
    )
  }

  private saveUsers = async () => {
    await this.store.performUsersUpdate()
    this.list?.recomputeGridSize()
  }

  private renderNoTagsMessage = (): JSX.Element => {
    return <div className="text extra-large pa20">{noTags}</div>
  }

  private setList = (listElement: List) => {
    this.list = listElement
  }

  private scrollToFirstRow = () => {
    Promise.resolve().then(() => this.list?.scrollToRow(0))
  }

  private get store(): TagsDirectoryStore {
    return this.props.tagsDirectoryStore
  }
}
