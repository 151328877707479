import { action, computed } from 'mobx'

import { DeliveryFilterType } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DeliveryStatus, {
  formatStatusToDisplay,
} from '~/client/src/shared/constants/DeliveryStatus'
import { deliveryFilterTypes } from '~/client/src/shared/enums/DeliveryFilterType'
import { ISourceMap } from '~/client/src/shared/stores/BaseDeliveryFilter.store'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import {
  DELIVERY_RECEIVED,
  LOAD_DELIVERIES_SUCCESS,
  RESET_ALL_FILTERS,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import { enumToList } from '~/client/src/shared/utils/converters'

import DeliveriesBySitemapListStore from '../../../DeliveriesMap/DeliveriesBySitemapList.store'
import BaseDeliveryMapFilterStore from './BaseDeliveryMapFilter.store'

// localization: no display text to translate

const EXCLUDED_STATUSES = [DeliveryStatus.Deleted]

export default class DeliveryMapFilterStore {
  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly deliveriesStore: DeliveriesStore,
    private readonly deliveriesBySitemapListStore: DeliveriesBySitemapListStore,
    private readonly companiesStore: CompaniesStore,
    protected readonly onShowChanged: (
      isShown: boolean,
      filterType: string,
    ) => void,
  ) {}

  public get settings() {
    return this.eventsStore.appState.deliveryFiltersSettings
  }

  @computed
  public get enabledFilterTypes() {
    const { filterInfoMap } = this.settings
    return Object.keys(filterInfoMap)
  }

  private get availableDeliveries() {
    return this.deliveriesStore.availableDeliveries
  }

  @computed
  public get filterStoresByTypeMap(): {
    [filterType: string]: BaseDeliveryMapFilterStore
  } {
    const map: { [filterType: string]: BaseDeliveryMapFilterStore } = {}

    deliveryFilterTypes.forEach(filterType => {
      map[filterType] = new BaseDeliveryMapFilterStore(
        filterType,
        this.eventsStore.appState,
        this.sourceMapByFilterTypeMap[filterType],
        this.deliveriesBySitemapListStore,
        this.onShowChanged,
        this.formatOptionKey,
      )
    })

    return map
  }

  @computed
  private get sourceMapByFilterTypeMap(): { [filterType: string]: ISourceMap } {
    const maps = deliveryFilterTypes.reduce((acc, filterType) => {
      acc[filterType] = this.getDefaultSourceMapByType(filterType)
      return acc
    }, {})

    this.availableDeliveries.forEach(delivery => {
      Object.keys(maps).forEach(filterType => {
        const map = maps[filterType]
        const optionId = delivery[filterType] || UNASSIGNED

        map[optionId]?.push(delivery.id)
      })
    })

    return maps
  }

  @action.bound
  public resetAllFilters() {
    this.eventsStore.dispatch(RESET_ALL_FILTERS)
  }

  @action.bound
  public onDeliveriesReceived(eventContext: EventContext) {
    const [eventType] = eventContext.event

    if ([DELIVERY_RECEIVED, LOAD_DELIVERIES_SUCCESS].includes(eventType)) {
      this.syncFilters()
    }
  }

  @action
  public syncFilters() {
    const { fieldsMap } = this.eventsStore.appState.deliveryFilters

    Object.keys(fieldsMap)
      .filter(filterType =>
        deliveryFilterTypes.includes(filterType as DeliveryFilterType),
      )
      .forEach(filterType => {
        const filter = fieldsMap[filterType]
        const sourceMap = this.sourceMapByFilterTypeMap[filterType]
        return this.updateCommonFilterState(filter, sourceMap)
      })
  }

  private updateCommonFilterState(filter: UIFilterInfo, sourceMap: ISourceMap) {
    let appliedFilterOptions = []

    filter.initialFilterOptions.forEach((value, optionKey) => {
      const deliveriesIds = sourceMap[optionKey]

      filter.selectedFilterOptions.set(optionKey, deliveriesIds)
      filter.initialFilterOptions.set(optionKey, deliveriesIds)

      appliedFilterOptions = appliedFilterOptions.concat(deliveriesIds)
    })

    filter.appliedFilterOptions = appliedFilterOptions
  }

  private getDefaultSourceMapByType(type: DeliveryFilterType) {
    let sourceList: string[] = []

    switch (type) {
      case DeliveryFilterType.Status:
        sourceList = enumToList(DeliveryStatus).filter(
          s => !EXCLUDED_STATUSES.includes(s),
        )
        break
      case DeliveryFilterType.Company:
        sourceList = [...this.companiesStore.allCompaniesIds, UNASSIGNED]
        break
    }

    return sourceList.reduce((acc, optionId) => {
      acc[optionId] = []
      return acc
    }, {})
  }

  private formatOptionKey = (optionKey: string, filterType: string) => {
    switch (filterType) {
      case DeliveryFilterType.Status:
        return formatStatusToDisplay(optionKey)
      case DeliveryFilterType.Company:
        return this.companiesStore.getCompanyNameById(optionKey, UNASSIGNED)
      default:
        return optionKey
    }
  }
}
