import React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

interface IProps {
  isEditable: boolean
  fieldName: string
  text: string
  isKeySection: boolean
  update: (fieldName: string, newText: string) => void
}

@observer
export default class EditableField extends React.Component<IProps> {
  @observable private isEditing: boolean = false
  @observable private draftText: string

  private input: HTMLInputElement

  public componentDidUpdate() {
    if (this.input) {
      this.input.focus()
    }
  }

  public render() {
    const { isKeySection, text, isEditable } = this.props
    return (
      <div
        className={classList({
          'text bold y-center': true,
          'extra-large': !isKeySection,
          'small-header': isKeySection,
        })}
      >
        {this.isEditing ? (
          <input
            type="text"
            onBlur={this.exitEditMode}
            value={this.draftText}
            ref={this.setInput}
            onChange={this.updateDraftText}
          />
        ) : (
          <span>{text}</span>
        )}
        {isEditable &&
          (this.isEditing ? (
            <Icons.EditActive className="pl10" onClick={this.exitEditMode} />
          ) : (
            <Icons.EditInactive className="pl10" onClick={this.enterEditMode} />
          ))}
      </div>
    )
  }

  private setInput = (input: HTMLInputElement) => {
    this.input = input
  }

  @action.bound
  private updateDraftText(event: React.SyntheticEvent<HTMLInputElement>) {
    this.draftText = event.currentTarget.value
  }

  @action.bound
  private enterEditMode() {
    this.draftText = this.props.text
    this.isEditing = true
  }

  @action.bound
  private exitEditMode() {
    const { fieldName, text, update } = this.props
    const newText = this.draftText.trim()
    if (newText !== text) {
      update(fieldName, newText)
    }
    this.isEditing = false
  }
}
