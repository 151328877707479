import {
  URL_TO_MSEXCEL_ON_STORAGE_PATTERN,
  URL_TO_MSPPTX_ON_STORAGE_PATTERN,
  URL_TO_MSWORD_ON_STORAGE_PATTERN,
  URL_TO_PDF_ON_STORAGE_PATTERN,
} from './regExpPatterns'

export function isUrlToPdfOnStorage(value: string): boolean {
  return URL_TO_PDF_ON_STORAGE_PATTERN.test(value)
}

export function isUrlToMSWordOnStorage(value: string): boolean {
  return URL_TO_MSWORD_ON_STORAGE_PATTERN.test(value)
}

export function isUrlToMSExcelOnStorage(value: string): boolean {
  return URL_TO_MSEXCEL_ON_STORAGE_PATTERN.test(value)
}

export function isUrlToMSPPTXOnStorage(value: string): boolean {
  return URL_TO_MSPPTX_ON_STORAGE_PATTERN.test(value)
}

export function isUrlToMSFile(value: string) {
  return (
    URL_TO_MSWORD_ON_STORAGE_PATTERN.test(value) ||
    URL_TO_MSEXCEL_ON_STORAGE_PATTERN.test(value) ||
    URL_TO_MSPPTX_ON_STORAGE_PATTERN.test(value)
  )
}

export function isUrlToNotImageOnStorage(value: string): boolean {
  return (
    URL_TO_PDF_ON_STORAGE_PATTERN.test(value) ||
    URL_TO_MSWORD_ON_STORAGE_PATTERN.test(value) ||
    URL_TO_MSEXCEL_ON_STORAGE_PATTERN.test(value) ||
    URL_TO_MSPPTX_ON_STORAGE_PATTERN.test(value)
  )
}
