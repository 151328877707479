import * as React from 'react'

import { Icon, MenuItem } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { ItemRenderer, Select } from '@blueprintjs/select'
import { inject, observer } from 'mobx-react'

import HierarchyChains from '~/client/src/shared/components/HierarchyChains'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

const NONE_VALUE = null
const NONE_OPTION = 'None'

interface IProps {
  value: LocationBase
  options: LocationBase[]
  applyLocation: (newValue: LocationBase, oldValue?: LocationBase) => void
  tagsStore?: TagsStore
}

@inject('tagsStore')
@observer
export default class MappingLocationSelector extends React.Component<IProps> {
  private get objects() {
    const { options } = this.props
    const sortedObjects = options.sort((a, b) => a.name.localeCompare(b.name))

    return [NONE_VALUE, ...sortedObjects]
  }

  public render() {
    return (
      <div className="mapping-selector full-width">
        <Select
          items={this.objects}
          itemRenderer={this.renderOption}
          onItemSelect={this.onItemSelect}
          className="mapping-select"
          popoverProps={{
            popoverClassName: 'mapping-selector-popover',
            minimal: true,
          }}
          filterable={false}
        >
          <div className="mapping-selector-input row y-center">
            <div className="pl8">{this.renderTag(this.props.value)}</div>
            <Icon icon={IconNames.CARET_DOWN} className="px8" />
          </div>
        </Select>
      </div>
    )
  }

  private renderTag(dto: LocationBase) {
    if (!dto) {
      return (
        <div className="text light extra-large">
          {Localization.translator.selectObject}
        </div>
      )
    }
    const hierarchyChains = dto.getHierarchyChains(
      this.props.tagsStore.tagStoreByTagTypeMap,
    )
    return (
      <>
        <SitemapAttributeTag shouldShowAsTag={false} dataObject={dto}>
          <div className="text extra-large ellipsis parent-tag">{dto.name}</div>
        </SitemapAttributeTag>
        <HierarchyChains
          className="text light ml20"
          hierarchyChains={hierarchyChains}
        />
      </>
    )
  }

  private renderOption: ItemRenderer<LocationBase> = (
    attribute,
    { handleClick, modifiers },
  ) => {
    if (!attribute) {
      return (
        <MenuItem
          active={modifiers.active}
          key={NONE_OPTION}
          onClick={handleClick}
          text={NONE_OPTION}
        />
      )
    }

    return (
      <MenuItem
        active={modifiers.active}
        key={attribute.id}
        onClick={handleClick}
        text={this.renderTag(attribute)}
      />
    )
  }

  private onItemSelect = (attribute: LocationBase) => {
    this.props.applyLocation(attribute, this.props.value)
  }
}
