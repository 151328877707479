import Localization from '~/client/src/shared/localization/LocalizationManager'

import ConcreteDirectOrderStatus from '../enums/ConcreteDirectOrderStatus'

export default function formatOrderStatusToDisplay(
  status: ConcreteDirectOrderStatus,
): string {
  switch (status) {
    case ConcreteDirectOrderStatus.Requested:
      return Localization.translator.requested
    case ConcreteDirectOrderStatus.Unconfirmed:
      return Localization.translator.unconfirmed
    case ConcreteDirectOrderStatus.Cancelled:
      return Localization.translator.canceled
    case ConcreteDirectOrderStatus.Confirmed:
      return Localization.translator.confirmed
    case ConcreteDirectOrderStatus.Delivering:
      return Localization.translator.delivering
    case ConcreteDirectOrderStatus.Paused:
      return Localization.translator.paused
    case ConcreteDirectOrderStatus.OnHold:
      return Localization.translator.onHold
    case ConcreteDirectOrderStatus.Completed:
      return Localization.translator.completed
  }
}
