import { action, observable } from 'mobx'

import BarState from '~/client/src/desktop/enums/SideBarState'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import Activity from '~/client/src/shared/models/Activity'
import BaseNotification from '~/client/src/shared/models/Notification'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import {
  isDeliveryType,
  isFlagType,
  isRFIType,
  isScheduleCommentType,
  isStatusUpdateType,
} from '~/client/src/shared/types/NotificationTypes'

export default class NotificationDetailsViewStore {
  @observable private displayedDetailsType: BarState = BarState.Default
  private displayedContentObjectId: string

  public constructor(
    private readonly activitiesStore: ActivitiesStore,
    private readonly state: DesktopInitialState,
  ) {}

  public get flagId() {
    return this.displayedDetailsType === BarState.Flag
      ? this.displayedContentObjectId
      : null
  }

  public get rfiId() {
    return this.displayedDetailsType === BarState.Rfi
      ? this.displayedContentObjectId
      : null
  }

  public get scheduleCommentId() {
    return this.displayedDetailsType === BarState.ScheduleComment
      ? this.displayedContentObjectId
      : null
  }

  public get deliveryId() {
    return this.displayedDetailsType === BarState.DeliveryDetails
      ? this.displayedContentObjectId
      : null
  }

  public get statusUpdateId() {
    return this.displayedDetailsType === BarState.StatusUpdateMessages
      ? this.displayedContentObjectId
      : null
  }

  public get selectedActivity(): Activity {
    return this.activitiesStore.selectedActivity
  }

  public setNotification = (notification: BaseNotification) => {
    if (!notification) {
      return
    }

    const { activityObjectId, entityId, type } = notification
    const activity = this.activitiesStore.getByDbId(activityObjectId)
    this.activitiesStore.select(activity?.code)

    this.state.displayedContentObjectId = entityId
    this.displayedContentObjectId = entityId
    switch (true) {
      case isFlagType(type):
        this.displayedDetailsType = BarState.Flag
        break
      case isRFIType(type):
        this.displayedDetailsType = BarState.Rfi
        break
      case isDeliveryType(type):
        this.displayedDetailsType = BarState.DeliveryDetails
        break
      case isStatusUpdateType(type):
        this.displayedDetailsType = BarState.StatusUpdateMessages
        break
      case isScheduleCommentType(type):
        this.displayedDetailsType = BarState.ScheduleComment
        break
    }
  }

  @action.bound
  public navigateToActivity() {
    if (!this.selectedActivity) {
      return
    }

    this.displayedContentObjectId = null
    this.displayedDetailsType = BarState.Default
  }
}
