import * as React from 'react'

import { observer } from 'mobx-react'

import { Content, Header, View } from '~/client/src/shared/components/Layout'
import PermitTypeSelector from '~/client/src/shared/components/PermitTypeSelector/PermitTypeSelector'

import NavBar from '../../components/NavBar/NavBar'

// translated

@observer
export default class PermitTypesView extends React.Component {
  public render() {
    return (
      <View>
        <Header>
          <NavBar />
        </Header>
        <Content scrollable={true} className="w500 ma-x-auto py30">
          <PermitTypeSelector />
        </Content>
      </View>
    )
  }
}
