import * as React from 'react'

import { Position, Tooltip } from '@blueprintjs/core'
import { inject, observer } from 'mobx-react'
import { isMacOs } from 'react-device-detect'

import * as Icons from '~/client/src/shared/components/Icons'

import Keys from '../../../enums/Keys'
import StruxhubInput, { IStruxhubInputProps } from '../StruxhubInput'

import './StruxhubNumberWithControlsInput.scss'

interface IProps extends IStruxhubInputProps {
  stepMultiplier?: number
  step: number
  min: number
  max: number
  onValueChange: (value: number) => void
}

const DEFAULT_STEP_MULTIPLIER = 10
const clickForCoarser = 'click for coarser'
const clickForFiner = 'click for finer'

@inject('state')
@observer
export default class StruxhubNumberWithControlsInput extends React.Component<IProps> {
  public render() {
    return (
      <>
        <StruxhubInput
          {...this.props}
          customRightIcon={this.renderCustomRightIcon()}
        />
      </>
    )
  }

  private renderCustomRightIcon = () => {
    return (
      <Tooltip
        popoverClassName="number-input-tooltip"
        content={this.renderTooltipContent()}
        position={Position.BOTTOM}
      >
        <div className="number-input-group row x-end">
          <div
            className="input-icon remove pointer"
            onClick={this.onRemoveButtonClick}
          >
            <Icons.Remove />
          </div>
          <div
            className="input-icon add pointer"
            onClick={this.onAddButtonClick}
          >
            <Icons.Add />
          </div>
        </div>
      </Tooltip>
    )
  }

  private renderTooltipContent = () => {
    const ctrlButtonText = isMacOs ? 'CMD ⌘' : Keys.Ctrl
    return (
      <div className="col brada4 tooltip">
        <div className="row">
          <div className="button-label text white brada4 no-grow pa3">
            {Keys.Shift}
          </div>
          <div className="text white">
            -{clickForCoarser} {this.props.label.toLowerCase()}
          </div>
        </div>
        <div className="row">
          <div className="button-label text white brada4 no-grow pa3 no-white-space-wrap">
            {ctrlButtonText}
          </div>
          <div className="text white">
            -{clickForFiner} {this.props.label.toLowerCase()}
          </div>
        </div>
      </div>
    )
  }

  private onRemoveButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    this.props.onValueChange(
      this.getValidatedValue(
        +this.props.value - this.props.step * this.getMultiplier(e),
      ),
    )
    e.preventDefault()
  }

  private onAddButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    this.props.onValueChange(
      this.getValidatedValue(
        +this.props.value + this.props.step * this.getMultiplier(e),
      ),
    )
    e.preventDefault()
  }

  private getValidatedValue = (value: number) => {
    const { min, max } = this.props
    if (value > max) {
      return max
    }
    if (value < min) {
      return min
    }
    return value
  }

  private getMultiplier(e: React.MouseEvent<HTMLElement>): number {
    const isCtrlPressed = e.ctrlKey || (isMacOs && e.metaKey)
    if (isCtrlPressed) {
      return 1 / (this.props.stepMultiplier || DEFAULT_STEP_MULTIPLIER)
    }
    if (e.shiftKey) {
      return this.props.stepMultiplier || DEFAULT_STEP_MULTIPLIER
    }

    return 1
  }
}
