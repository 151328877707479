import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import WeatherUnits, { windSpeedUnits } from '../../enums/WeatherUnits'
import WeatherForecastInfo from '../../models/WeatherInfo'
import OpenWeatherIcon from '../OpenWeatherIcon'

import './WeatherLabel.scss'

interface IProps {
  forecast: WeatherForecastInfo
  projectWeatherUnits: WeatherUnits
  className?: string

  shouldHideWind?: boolean
  shouldHideProbabilityOfPrecipitation?: boolean
}

const ICON_SIZE = 12
const EMPTY_WEATHER_VALUE = '--'
const DEGREE_SYMBOL = '°'

@observer
export default class WeatherLabel extends React.Component<IProps> {
  public render() {
    const {
      forecast,
      className,
      projectWeatherUnits,
      shouldHideWind,
      shouldHideProbabilityOfPrecipitation,
    } = this.props

    return (
      <div
        className={classList({
          'weather-label row': true,
          [className]: !!className,
        })}
      >
        <div className="no-grow nowrap">
          <span className="text bold lp05">
            {!forecast
              ? EMPTY_WEATHER_VALUE
              : `${forecast.maxTemp}${DEGREE_SYMBOL}`}
          </span>
          <span className="text large lp035">/</span>
          <span className="text bold lp05">
            {!forecast
              ? EMPTY_WEATHER_VALUE
              : `${forecast.minTemp}${DEGREE_SYMBOL}`}
          </span>
        </div>
        <div className="row x-center">
          <OpenWeatherIcon
            openWeatherIconCode={forecast?.weatherIconCode}
            className="weather-icon no-grow"
          />
          {!shouldHideProbabilityOfPrecipitation &&
            !!forecast?.probabilityOfPrecipitation && (
              <span className="precipitation-label text bold lp05 no-grow ml2">
                {forecast.probabilityOfPrecipitation}%
              </span>
            )}
        </div>
        {!shouldHideWind && (
          <div className="row no-grow">
            {!!forecast && (
              <Icon
                icon={IconNames.ARROW_UP}
                iconSize={ICON_SIZE}
                className="text grey-light"
                style={{
                  transform: `rotate(${forecast.windDegree}deg)`,
                }}
              />
            )}
            <span className="text bold lp05 ml2">
              {!forecast ? EMPTY_WEATHER_VALUE : forecast.windSpeed}
            </span>
            <span className="text small grey-light lp05">
              {windSpeedUnits(projectWeatherUnits)}
            </span>
          </div>
        )}
      </div>
    )
  }
}
