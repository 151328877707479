enum ActivityPlannedStatus {
  StartsToday = 'Starts Today',
  StartsTomorrow = 'Starts Tomorrow',
  StartedYesterday = 'Started Yesterday',

  DueDayOfCurrentWeek = 'Due [dayName]',
  DueMonthDay = 'Due [date]',
  DueTomorrow = 'Due Tomorrow',

  DueToday = 'Due Today',
  DueYesterday = 'Due Yesterday',
  DueXDaysAgo = 'Due [x] days ago',

  None = '-',
}

export default ActivityPlannedStatus
