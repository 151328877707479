import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IGetMaturixCastsQueryVariables = Types.Exact<{
  struxHubProjectId: Types.Scalars['ObjectId']
}>

export type IGetMaturixCastsQuery = {
  getMaturixCasts?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<
          Types.IMaturixCast,
          'castId' | 'castName' | 'status' | 'maturixProjectId' | 'projectName'
        > & {
          monitorings?: Types.Maybe<
            Array<
              Types.Maybe<{
                monitoring?: Types.Maybe<
                  Pick<
                    Types.IMonitoringInfo,
                    'monitoringId' | 'monitoringName' | 'status' | 'deviceId'
                  >
                >
                latestData?: Types.Maybe<
                  Pick<
                    Types.IMonitoringData,
                    | 'timestamp'
                    | 'temperature'
                    | 'temperatureUnit'
                    | 'maturity'
                    | 'strength'
                    | 'strengthUnit'
                  >
                >
              }>
            >
          >
        }
      >
    >
  >
}

export const GetMaturixCastsDocument = gql`
  query GetMaturixCasts($struxHubProjectId: ObjectId!) {
    getMaturixCasts(struxHubProjectId: $struxHubProjectId) {
      castId
      castName
      status
      maturixProjectId
      projectName
      monitorings {
        monitoring {
          monitoringId
          monitoringName
          status
          deviceId
        }
        latestData {
          timestamp
          temperature
          temperatureUnit
          maturity
          strength
          strengthUnit
        }
      }
    }
  }
`

/**
 * __useGetMaturixCastsQuery__
 *
 * To run a query within a React component, call `useGetMaturixCastsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaturixCastsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaturixCastsQuery({
 *   variables: {
 *      struxHubProjectId: // value for 'struxHubProjectId'
 *   },
 * });
 */
export function useGetMaturixCastsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetMaturixCastsQuery,
    IGetMaturixCastsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetMaturixCastsQuery, IGetMaturixCastsQueryVariables>(
    GetMaturixCastsDocument,
    options,
  )
}
export function useGetMaturixCastsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetMaturixCastsQuery,
    IGetMaturixCastsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetMaturixCastsQuery,
    IGetMaturixCastsQueryVariables
  >(GetMaturixCastsDocument, options)
}
export type GetMaturixCastsQueryHookResult = ReturnType<
  typeof useGetMaturixCastsQuery
>
export type GetMaturixCastsLazyQueryHookResult = ReturnType<
  typeof useGetMaturixCastsLazyQuery
>
export type GetMaturixCastsQueryResult = Apollo.QueryResult<
  IGetMaturixCastsQuery,
  IGetMaturixCastsQueryVariables
>
