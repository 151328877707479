import * as React from 'react'

import {
  Classes,
  Icon,
  Popover,
  PopoverPosition,
  PopperModifiers,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'

import { WorkflowStepType } from '~/client/graph'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { getWorkflowStepDisplayName } from '~/client/src/shared/localization/enumDisplayTexts'

// localization: translated

interface IProps {
  stepType: WorkflowStepType
  stepIdx: number
  isRequestOrSubmitStep: boolean

  isAutomaticStep?: boolean

  onReplaceStep(index: number, stepType: WorkflowStepType): void
  onRemoveStep(index: number): void

  onAddStep?(index: number, stepType: WorkflowStepType): void
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: { enabled: false },
  hide: { enabled: false },
  arrow: { enabled: false },
  computeStyle: { gpuAcceleration: false },
}

@observer
export default class WorkflowStepMenuModal extends React.Component<IProps> {
  public render() {
    return (
      <Popover
        className="no-grow step-menu-btn"
        popoverClassName="brada8 beautiful-shadow overflow-hidden"
        usePortal={false}
        canEscapeKeyClose={false}
        position={PopoverPosition.BOTTOM_LEFT}
        modifiers={popoverPopperModifiers}
        content={this.moreMenuModalContent}
      >
        <Icon
          icon={IconNames.MORE}
          className="no-grow pointer full-height row y-center pa8 brada24"
        />
      </Popover>
    )
  }

  private get moreMenuModalContent(): JSX.Element {
    const { isAutomaticStep, isRequestOrSubmitStep } = this.props
    const isNonDeletable = isAutomaticStep || isRequestOrSubmitStep

    return (
      <div className={`col px16 h40 pointer nowrap ${Classes.POPOVER_DISMISS}`}>
        {isNonDeletable && (
          <div className="row full-height" onClick={this.onReplaceStepClick}>
            <Icon icon={IconNames.REFRESH} className="no-grow mr10" />
            <span>{this.replaceStepText}</span>
          </div>
        )}
        {!isNonDeletable && (
          <div
            className="row text large red full-height"
            onClick={this.onRemoveStepClick}
          >
            <Icon icon={IconNames.TRASH} className="no-grow mr10" />
            <span>
              {Localization.translator.workflowConfDescr.deleteWorkflowStep}
            </span>
          </div>
        )}
      </div>
    )
  }

  private get replaceStepText(): string {
    const { isAutomaticStep } = this.props
    const { replaceWithStep, replaceWithStart } =
      Localization.translator.workflowConfDescr

    return isAutomaticStep
      ? replaceWithStart
      : replaceWithStep(getWorkflowStepDisplayName(this.replaceStepType))
  }

  private get replaceStepType(): WorkflowStepType {
    return this.props.stepType === WorkflowStepType.Request
      ? WorkflowStepType.Submission
      : WorkflowStepType.Request
  }

  private onReplaceStepClick = () => {
    const { stepIdx, onReplaceStep, isAutomaticStep, onAddStep } = this.props

    if (isAutomaticStep) {
      return onAddStep(stepIdx, WorkflowStepType.Start)
    }

    onReplaceStep(stepIdx, this.replaceStepType)
  }

  private onRemoveStepClick = () => {
    this.props.onRemoveStep(this.props.stepIdx)
  }
}
