import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Checkbox from '~/client/src/shared/components/Checkbox'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import MapBoxViewerStore from '../../MapBoxEditor/MapBoxViewer.store'

import './MapLayerSelector.scss'

// translated

interface IMapLayerSelectorProps {
  store: MapBoxViewerStore

  toggleAnnouncementsHiddenState?: () => void
  toggleDeliveriesHiddenState?: () => void
  togglePermitsHiddenState?: () => void
  toggleMonitoringsHiddenState?: () => void
  isLogisticsView?: boolean
  isExtendedView?: boolean
  arePermitsHidden?: boolean
  areAnnouncementsHidden?: boolean
  areDeliveriesHidden?: boolean
  areMonitoringsHidden?: boolean

  shouldHideDeliveriesToggling?: boolean

  className?: string
  onClose?: () => void
  areAdditionalMarkersAvailable?: boolean
}

const MAP_3D = '3d'

@observer
export default class MapLayersMenu extends React.Component<IMapLayerSelectorProps> {
  public render() {
    const {
      store: {
        isLabelsHidden,
        isTerrainShown,
        is3DModeActive,
        isSatelliteActive,
        isStreetsActive,
        isTrafficShown,
        shouldUseMapAnimations,
        shouldShowAdditionalProjectMarkers,
        toggleMapStyle,
        toggleTraffic,
        toggleMoreMenu,
        toggleAdditionalMarkers,
        toggleMapAnimations,
        toggle3DMode,
        toggleTerrain,
        toggleLabels,
      },
      isExtendedView,
      arePermitsHidden,
      shouldHideDeliveriesToggling,
      areAnnouncementsHidden,
      areDeliveriesHidden,
      areMonitoringsHidden,
      className,
      areAdditionalMarkersAvailable,
      onClose,
      toggleAnnouncementsHiddenState,
      toggleDeliveriesHiddenState,
      togglePermitsHiddenState,
      toggleMonitoringsHiddenState,
    } = this.props

    const onCrossClick = onClose || toggleMoreMenu

    const menuClassName = `col brada10 bg-white extended-view ${
      className && className
    }`

    return (
      <div className={menuClassName}>
        <div className="col bb-light-cool-grey mx20 pb20">
          <div onClick={onCrossClick} className="row x-between py10">
            <div className="text lp15 small uppercase primary bold">
              {Localization.translator.mapType}
            </div>
            <Icon className="pointer" icon={IconNames.CROSS} />
          </div>

          <div className="row x-around">
            <div
              className={classList({
                'col x-center pointer': true,
                'unclickable-element': isStreetsActive,
              })}
              onClick={toggleMapStyle}
            >
              <Icons.MapStreetSmall
                className={classList({
                  'map-type-icon no-grow': true,
                  active: isStreetsActive,
                })}
              />
              <div className="row x-center text small">
                {Localization.translator.street}
              </div>
            </div>
            <div
              className={classList({
                'col x-center pointer': true,
                'unclickable-element': isSatelliteActive,
              })}
              onClick={toggleMapStyle}
            >
              <Icons.MapSatelliteSmall
                className={classList({
                  'map-type-icon no-grow': true,
                  active: isSatelliteActive,
                })}
              />
              <div className="row x-center text small">
                {Localization.translator.satellite}
              </div>
            </div>
          </div>
        </div>

        <div className="col bb-light-cool-grey mx20 py10">
          <div className="row x-start text lp15 small uppercase primary bold">
            {Localization.translator.mapDetails}
          </div>
          <div className="col">
            <div className="row x-around">
              <div
                className={classList({
                  'col x-center mx20': true,
                  'inactive-element': isSatelliteActive,
                })}
                onClick={toggle3DMode}
              >
                <Icon
                  icon={IconNames.CUBE}
                  className={classList({
                    'map-type-icon no-grow pointer': true,
                    active: is3DModeActive,
                  })}
                />
                <div className="row x-center text small mode-text">
                  {MAP_3D}
                </div>
              </div>
            </div>
            <div className="row x-around">
              <div
                className={classList({
                  'col x-center mx20': true,
                })}
                onClick={toggleTraffic}
              >
                <Icons.MapLayerTraffic
                  className={classList({
                    'map-type-icon no-grow pointer': true,
                    active: isTrafficShown,
                  })}
                />
                <div className="row x-center text small mode-text">
                  {Localization.translator.traffic}
                </div>
              </div>
              <div
                className={classList({
                  'col x-center mx20': true,
                })}
                onClick={toggleTerrain}
              >
                <Icons.Sitemap
                  className={classList({
                    'map-type-icon no-grow pointer': true,
                    active: isTerrainShown,
                  })}
                />
                <div className="row x-center text small">
                  {Localization.translator.terrain}
                </div>
              </div>
            </div>
          </div>
        </div>

        {areAdditionalMarkersAvailable && (
          <div className="col bb-light-cool-grey mx20 mt10 pb10">
            <div className="row x-between text lp15 small uppercase primary bold">
              {Localization.translator.showAllProjectsMarkers}
              <Checkbox
                className="pointer reverted-colors"
                isChecked={shouldShowAdditionalProjectMarkers}
                onClick={toggleAdditionalMarkers}
              />
            </div>
          </div>
        )}
        <div className="col bb-light-cool-grey mx20 mt10 pb10">
          <div className="row x-between text lp15 small uppercase primary bold">
            {Localization.translator.useMapLabels}
            <Checkbox
              className="pointer reverted-colors"
              isChecked={!isLabelsHidden}
              onClick={toggleLabels}
            />
          </div>
        </div>
        <div className="col bb-light-cool-grey mx20 mt10 pb10">
          <div className="row x-between text lp15 small uppercase primary bold">
            {Localization.translator.useMapAnimations}
            <Checkbox
              className="pointer reverted-colors"
              isChecked={shouldUseMapAnimations}
              onClick={toggleMapAnimations}
            />
          </div>
        </div>
        {isExtendedView && (
          <div className="col bb-light-cool-grey px20">
            <div className="row x-start text lp15 small uppercase primary bold mb10">
              {Localization.translator.showWorkflows}
            </div>

            {!shouldHideDeliveriesToggling && (
              <div className="row x-between text large my10 lp035">
                <div className="row">
                  <Icons.TruckSmall className="no-grow mr10" />
                  <div>{Localization.translator.deliveries}</div>
                </div>
                <Checkbox
                  className="pointer reverted-colors"
                  isChecked={!areDeliveriesHidden}
                  onClick={toggleDeliveriesHiddenState}
                />
              </div>
            )}

            <div className="row x-between text large my10 lp035">
              <div className="row">
                <Icons.Alert className="no-grow mr10" />
                <div>{Localization.translator.deliveries}</div>
              </div>
              <Checkbox
                className="pointer reverted-colors"
                isChecked={!areMonitoringsHidden}
                onClick={toggleMonitoringsHiddenState}
              />
            </div>

            <div className="row x-between text large my10 lp035">
              <div className="row">
                <Icons.GeneralForm className="no-grow mr10 selector-app-toggle" />
                <div>{Localization.translator.forms}</div>
              </div>
              <Checkbox
                className="pointer reverted-colors"
                isChecked={!arePermitsHidden}
                onClick={togglePermitsHiddenState}
              />
            </div>

            <div className="row x-between text large my1s0 lp035">
              <div className="row">
                <Icons.MegaphoneFilled className="no-grow mr10" />
                <div>{Localization.translator.announcements}</div>
              </div>
              <Checkbox
                className="pointer reverted-colors"
                isChecked={!areAnnouncementsHidden}
                onClick={toggleAnnouncementsHiddenState}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}
