import * as React from 'react'

import { observer } from 'mobx-react'

import './WorkflowBanner.scss'

interface IWorkflowBanner {
  title: string
  description: string
}

@observer
export default class WorkflowBanner extends React.Component<IWorkflowBanner> {
  public render() {
    const { title, description, children } = this.props

    return (
      <div className="banner">
        <div className="banner-title">
          <div className="banner-header text extra-large bold-font uppercase">
            {title}
          </div>
          <div className="banner-description no-grow">{description}</div>
        </div>

        <div className="row">{children}</div>
      </div>
    )
  }
}
