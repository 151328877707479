import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import CompactDialog from '~/client/src/shared/components/CompactDialog/CompactDialog'

import './CompactAlert.scss'

export interface IProps {
  isShown: boolean
  onHide: () => void
}

const WARNING_ICON_SIZE = 30
const warning = 'Warning'
const ok = 'Ok'

export default class CompactAlert extends React.PureComponent<IProps> {
  public render() {
    const { isShown, onHide, children } = this.props

    return (
      <CompactDialog
        title={warning}
        isShown={isShown}
        onHide={onHide}
        canOutsideClickClose={true}
        className="compact-alert brada4"
      >
        <div className="col">
          <div className="row pt20">
            <Icon
              className="mr10"
              icon={IconNames.WARNING_SIGN}
              iconSize={WARNING_ICON_SIZE}
            />
            <div className="text row large">{children}</div>
          </div>
          <button
            onClick={onHide}
            className="handle-button my20 brada4 mx20 py5 pointer"
          >
            {ok}
          </button>
        </div>
      </CompactDialog>
    )
  }
}
