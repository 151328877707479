import * as React from 'react'

import {
  Classes,
  Icon,
  Popover,
  PopoverPosition,
  PopperModifiers,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { WorkflowStepType } from '~/client/graph'
import {
  WorkflowStepCategory,
  workflowStepCategoriesMap,
} from '~/client/src/shared/enums/WorkflowStepCategory'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import {
  getWorkflowCategoryDisplayName,
  getWorkflowStepDisplayName,
} from '~/client/src/shared/localization/enumDisplayTexts'

import WorkflowStepConfiguratorStore from '../WorkflowStepConfigurator.store'

// localization: translated

interface IProps {
  stepType: WorkflowStepType
  stepIdx: number

  store: WorkflowStepConfiguratorStore

  onAddStep(index: number, stepType: WorkflowStepType): void
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: { enabled: false },
  hide: { enabled: false },
  arrow: { enabled: false },
  computeStyle: { gpuAcceleration: false },
}

const isApprovalCategory = (category: string): boolean =>
  category === WorkflowStepCategory.APPROVAL

@observer
export default class WorkflowAddStepModal extends React.Component<IProps> {
  public render() {
    return (
      <div className="absolute-top m5 add-step-btn">
        <Popover
          className="no-grow"
          popoverClassName="add-step-modal brada8 beautiful-shadow overflow-hidden mt5"
          canEscapeKeyClose={false}
          position={PopoverPosition.BOTTOM_LEFT}
          modifiers={popoverPopperModifiers}
          content={this.stepModalContent}
        >
          <div className="row text large line-extra-large grey-30 pointer py6 px10 brada24">
            <Icon icon={IconNames.PLUS} className="no-grow" />
            <span className="no-grow nowrap ml3">
              {Localization.translator.addStep}
            </span>
          </div>
        </Popover>
      </div>
    )
  }

  private get stepModalContent(): JSX.Element {
    const { stepType, stepIdx, store } = this.props

    return (
      <div className={`col nowrap mw240 py8 ${Classes.POPOVER_DISMISS}`}>
        {Object.keys(workflowStepCategoriesMap).map(category => {
          const steps: WorkflowStepType[] = store.getModalStepOptions(
            stepType,
            stepIdx,
            workflowStepCategoriesMap[category],
          )
          if (!steps.length) return null

          return (
            <div
              key={category}
              className={classList({
                col: true,
                'bt-palette-brand-lighter': !isApprovalCategory(category),
              })}
            >
              <span className="text grey-30 small uppercase bold line-16 py4 px8">
                {getWorkflowCategoryDisplayName(
                  category as WorkflowStepCategory,
                )}
              </span>
              {steps.map(step => (
                <div
                  key={step}
                  className="row py10 px16 text large line-extra-large pointer highlighted-hover"
                  onClick={this.onStepClick.bind(this, step)}
                >
                  {getWorkflowStepDisplayName(step)}
                </div>
              ))}
            </div>
          )
        })}
      </div>
    )
  }

  private onStepClick(stepType: WorkflowStepType) {
    const { stepIdx, onAddStep } = this.props
    onAddStep(stepIdx, stepType)
  }
}
