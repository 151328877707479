import { action, computed, observable } from 'mobx'

import { IProjectMaterialCategory } from '~/client/graph'
import MaterialCategory from '~/client/src/shared/models/MaterialCategory'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'

export default class MaterialCategoriesStore {
  @observable public isDataReceived = false
  private readonly categoriesMap = observable(
    new Map<string, MaterialCategory>(),
  )

  public constructor(private eventsStore: EventsStore) {}

  @computed
  public get list(): MaterialCategory[] {
    return this.allCategories.filter(mc => !mc.isFromConcreteDirect)
  }

  @computed
  public get allCategories(): MaterialCategory[] {
    return Array.from(this.categoriesMap.values())
  }

  @computed
  public get selectedCategoriesWithCd(): MaterialCategory[] {
    const { projectMaterialOptions } = this.eventsStore.appState
    if (!projectMaterialOptions) {
      return []
    }

    const { hiddenCategoryIds } = projectMaterialOptions
    return this.allCategories.filter(c => !hiddenCategoryIds.includes(c.id))
  }

  @computed
  public get selectedCategoriesWithoutCd(): MaterialCategory[] {
    return this.selectedCategoriesWithCd.filter(c => !c.isFromConcreteDirect)
  }

  @action.bound
  public clearList() {
    this.categoriesMap.clear()
    this.isDataReceived = false
  }

  @action.bound
  public receiveList(list: IProjectMaterialCategory[]) {
    this.clearList()

    list.forEach(dto => {
      this.categoriesMap.set(dto.id, MaterialCategory.fromDto(dto))
    })

    this.isDataReceived = true
  }

  @action.bound
  public receiveOne(id: string, dto: IProjectMaterialCategory) {
    if (dto) {
      this.categoriesMap.set(id, MaterialCategory.fromDto(dto))
    } else {
      this.categoriesMap.delete(id)
    }
  }

  public hasInstanceById = (id: string): boolean => {
    return this.categoriesMap.has(id)
  }

  public getInstanceById = (id: string): MaterialCategory => {
    if (this.hasInstanceById(id)) {
      return this.categoriesMap.get(id)
    }
  }

  public getCategoryNameById = (id: string): string => {
    return this.getInstanceById(id)?.name
  }
}
