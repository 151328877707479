import React from 'react'

import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import Announcement from '~/client/src/shared/models/Announcement'
import AnnouncementsStore from '~/client/src/shared/stores/domain/Announcements.store'

import AnnouncementEditionForm from '../../../components/AnnouncementEditionForm/AnnouncementEditionForm'

interface IProps {
  backClicked: () => void
  announcementId: string
  announcementsStore?: AnnouncementsStore
}

@inject('announcementsStore')
@observer
export default class NotificationDetailsAnnouncement extends React.Component<IProps> {
  @observable private displayedAnnouncement: Announcement = null

  public componentDidMount(): void {
    this.displayedAnnouncement = this.props.announcementsStore.byId.get(
      this.props.announcementId,
    )
  }

  public render() {
    return (
      <div className="announcement-creation-form">
        <AnnouncementEditionForm
          onClose={this.props.backClicked}
          displayedAnnouncement={this.displayedAnnouncement}
        />
      </div>
    )
  }
}
