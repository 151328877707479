import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import {
  IChecklistItem,
  IPermitTypeChecklist,
  IPermitTypeField,
} from '~/client/graph'
import QuestionnaireType from '~/client/src/shared/enums/QuestionnaireType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { getBullet } from '~/client/src/shared/utils/PermitInstructionsHelper'

import BaseInstructionsConfigurator from '../BaseInstructionsConfigurator/BaseInstructionsConfigurator'
import InstructionTextHolder from '../BaseInstructionsConfigurator/InstructionTextHolder'
import { FieldState } from '../FieldConfigurator/FieldConfigurator.store'
import FieldStateSelector from '../FieldStateSelector'
import TypeSelector from '../TypeSelector'
import ChecklistStore from './Checklist.store'

interface IProps {
  field: IPermitTypeField
  onChange(checklist: IPermitTypeChecklist): void
  onFieldChange(field: IPermitTypeField): void
  className?: string
  isSingleMode?: boolean
}

@observer
export default class Checklist extends React.Component<IProps> {
  private readonly store: ChecklistStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new ChecklistStore(
      props.field,
      props.onChange,
      props.onFieldChange,
    )
  }

  public componentDidUpdate(prevProps: IProps) {
    const { field, onFieldChange } = this.props
    if (field !== prevProps.field) {
      this.store.setField(field)
    }
    if (onFieldChange !== prevProps.onFieldChange) {
      this.store.setOnFieldChangeHandler(onFieldChange)
    }
  }

  public render() {
    const { field, onChange, className } = this.props

    return (
      <BaseInstructionsConfigurator<IChecklistItem>
        className={className}
        instructions={field.checklist}
        onChange={onChange}
        store={this.store}
        instructionItemRenderer={this.renderItem}
        singleModeElement={this.questionDescriptionElement}
        fieldStateElement={this.fieldStateElement}
        fieldType={field.type}
      />
    )
  }

  private renderItem = (
    { id, text, questionnaireType }: IChecklistItem,
    index: number,
  ) => {
    const {
      handleMouseEnter,
      handleMouseLeave,
      instructions: { listType },
      hoveredInstructionIndex,
      tryToRemoveInstruction,
      questionnaireTypeAsOptions,
    } = this.store
    const { isSingleMode } = this.props

    return (
      <div
        key={id}
        className={classList({
          'row y-start relative instruction-item pr20': true,
          mb15: !isSingleMode,
        })}
        onMouseEnter={handleMouseEnter.bind(null, index)}
        onMouseLeave={handleMouseLeave}
      >
        {!isSingleMode && (
          <span className="text large bold blue-highlight no-grow mr12">
            {getBullet(listType, index + 1)}
          </span>
        )}
        <InstructionTextHolder<IChecklistItem>
          instructionText={text}
          instructionIndex={index}
          store={this.store}
        />
        <TypeSelector
          selectedValue={questionnaireType}
          options={questionnaireTypeAsOptions}
          onChange={this.handleQuestionnaireTypeChange.bind(this, id)}
        />
        {!isSingleMode && hoveredInstructionIndex === index && (
          <Icon
            icon={IconNames.TRASH}
            className="text blue-highlight pointer absolute del-icon mt5"
            onClick={tryToRemoveInstruction.bind(null, index)}
          />
        )}
      </div>
    )
  }

  private get fieldStateElement(): JSX.Element {
    return (
      <div className="row x-end">
        <FieldStateSelector
          className="brada4 bg-palette-brand-lightest"
          fieldState={this.store.fieldState}
          onChange={this.handleFieldStateChange}
        />
      </div>
    )
  }

  private get questionDescriptionElement(): JSX.Element {
    if (!this.props.isSingleMode) {
      return null
    }

    return (
      <span className="text large bold mr15 no-white-space-wrap">
        {Localization.translator.isQuestionMandatory}
      </span>
    )
  }

  private handleQuestionnaireTypeChange = (
    checklistItemId: string,
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    this.store.changeQuestionnaireTypeForChecklistItem(
      checklistItemId,
      event.target.value as QuestionnaireType,
    )
  }

  private handleFieldStateChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    this.store.changeFieldState(event.target.value as FieldState)
  }
}
