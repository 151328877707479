import { action, computed, observable } from 'mobx'

import { FilterType } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ActivityCode from '~/client/src/shared/models/ActivityCode'
import Resource from '~/client/src/shared/models/Resource'
import User from '~/client/src/shared/models/User'
import {
  SAVE_ACTIVITIES_CONFIGURATIONS,
  UPDATE_ACTIVITY_FILTERS_SETTINGS,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import ResourcesStore from '~/client/src/shared/stores/domain/Resources.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ActivityFilterInfo, {
  FilterSourceType,
} from '~/client/src/shared/stores/substates/ActivityFilterInfo'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'

import ActivityFilterSetupDialogStore, {
  ActivityFilterSetupDialogType,
} from './components/ActivityFilterSetupDialog.store'

const CUSTOM_FILTER_BASE_NAME = 'customfilter'

export default class ActivityFiltersViewStore {
  @observable public activeTabFilterName: string = FilterType.Company

  @observable public isConfirmDeleteFilterDialogShown: boolean = false
  public customFilterTypeToRemove: string = null
  public customFilterCaptionToRemove: string = null

  public get isLoading(): boolean {
    return this.eventsStore.appState.isLoading
  }

  public constructor(
    private activityFiltersStore: ActivityFiltersStore,
    private eventsStore: DesktopEventStore,
    private dialogStore: ActivityFilterSetupDialogStore,
    private readonly userProjectsStore: UserProjectsStore,
    private resourcesStore: ResourcesStore,
  ) {}

  @computed
  public get filterTabNames() {
    const { filterInfoMap } = this.activityFiltersStore.settings
    return Object.keys(filterInfoMap)
  }

  @action.bound
  public showConfirmDeleteFilterDialog(name: string) {
    this.customFilterTypeToRemove = name
    this.customFilterCaptionToRemove = this.getFilter(name).getCaption()
    this.isConfirmDeleteFilterDialogShown = true
  }

  @action.bound
  public hideConfirmDeleteFilterDialog() {
    this.customFilterTypeToRemove = null
    this.customFilterCaptionToRemove = null
    this.isConfirmDeleteFilterDialogShown = false
  }

  @action.bound
  public getFilter(name: string) {
    const { filterInfoMap } = this.activityFiltersStore.settings
    return filterInfoMap[name]
  }

  public getFilterDescription(name: string) {
    switch (name) {
      case FilterType.Company:
        return Localization.translator.activityFiltersDescriptions.company

      case FilterType.Level:
        return Localization.translator.activityFiltersDescriptions.level

      case FilterType.Zone:
        return Localization.translator.activityFiltersDescriptions.zone

      default:
        const filter = this.getFilter(name)
        switch (filter.sourceType) {
          case FilterSourceType.ActivityCode:
            return Localization.translator.activityFiltersDescriptions
              .activityCode

          case FilterSourceType.ProjectMember:
            return Localization.translator.activityFiltersDescriptions
              .projectMember

          default:
            throw new Error(filter.sourceType + ' source type is unhandled')
        }
    }
  }

  public getNewCaptionError(filterName: string, newCaption: string): string {
    const trimmedLowerCaseCaption = newCaption.trim().toLowerCase()
    if (!trimmedLowerCaseCaption) {
      return Localization.translator.filterNameCannotBeEmpty
    }

    const hasAnotherFilterWithSuchName =
      this.activityFiltersStore.filterInfoList.some(
        ({ name, getCaption }) =>
          name !== filterName &&
          getCaption().toLowerCase() === trimmedLowerCaseCaption,
      )

    if (hasAnotherFilterWithSuchName) {
      return Localization.translator.suchFilterNameIsAlreadyTaken
    }
  }

  public renameFilter(filterName: string, newCaption: string) {
    const filter = this.getFilter(filterName)
    newCaption = newCaption.trim()
    filter.caption = filter.getKnownCaption() === newCaption ? '' : newCaption
    this.eventsStore.dispatch(UPDATE_ACTIVITY_FILTERS_SETTINGS)
  }

  public toggleFilter(filterName: string) {
    const filter = this.getFilter(filterName)
    if (filter.canBeDisabled) {
      filter.isDisabled = !filter.isDisabled
      this.eventsStore.dispatch(UPDATE_ACTIVITY_FILTERS_SETTINGS)
      if (filter.isDisabled) {
        this.removeUIFilter(filterName)
      } else {
        this.addUpdateUIFilter(filterName)
      }
    }
  }

  public changeFilterSource(filterName: string, newSourceType: string) {
    const filter = this.getFilter(filterName)
    filter.sourceType = newSourceType
    this.eventsStore.dispatch(UPDATE_ACTIVITY_FILTERS_SETTINGS)
    this.addUpdateUIFilter(filterName)
  }

  public addCustomFilter(): string {
    const index = this.getNewCustomFilterIndex()
    const newFilterName = CUSTOM_FILTER_BASE_NAME + index
    const newFilterCaption = this.getNewCustomFilterCaption()
    const newFilter = new ActivityFilterInfo(
      newFilterName,
      true,
      newFilterCaption,
      index,
    )

    this.activityFiltersStore.settings.filterInfoMap[newFilterName] = newFilter
    this.eventsStore.dispatch(UPDATE_ACTIVITY_FILTERS_SETTINGS)
    this.addUpdateUIFilter(newFilterName)

    return newFilterName
  }

  public removeCustomFilter(filterType: string) {
    if (filterType === this.activeTabFilterName) {
      this.activeTabFilterName = FilterType.Company
    }

    this.activityFiltersStore.removeCustomFilter(filterType)
    this.removeUIFilter(filterType)
    this.removeSavedBands(filterType)
  }

  @action.bound
  public isFilterSetupCompleted(name: string) {
    const filter = this.getFilter(name)

    if (name === FilterType.Company) {
      return true
    }

    switch (filter.sourceType) {
      case FilterSourceType.ActivityCode:
        return this.activityFiltersStore.getCodesByFilterType(name).length > 0

      case FilterSourceType.ProjectMember:
        return (
          this.activityFiltersStore.getProjectMembersByFilterType(name).length >
          0
        )

      default:
        throw new Error(filter.sourceType + ' source type is not handled')
    }
  }

  @action.bound
  public getConfiguredFilterDialogItemNames(name: string): string[] {
    const filter = this.getFilter(name)

    switch (filter.sourceType) {
      case FilterSourceType.ActivityCode:
        const codes = this.getActivityCodesByFilter(name as FilterType)
        return codes.map(c => c.shortName || c.name)

      case FilterSourceType.ProjectMember:
        return this.activityFiltersStore
          .getProjectMembersByFilterType(name)
          .map(user => User.getFullNameToDisplay(user, this.userProjectsStore))
    }
  }

  public getActivityCodesByFilter = (name: FilterType): ActivityCode[] => {
    return this.activityFiltersStore.getCodesByFilterType(name)
  }

  public getActivityResources = (): Resource[] => {
    return this.resourcesStore.list
  }

  public get companiesFromScheduleCount() {
    return this.activityFiltersStore.companiesFromScheduleCount
  }

  @action.bound
  public showFilterConfiguration(name: string) {
    const filter = this.getFilter(name)

    switch (filter.sourceType) {
      case FilterSourceType.ActivityCode:
        const codes = this.activityFiltersStore.getCodesByFilterType(name)
        this.dialogStore.selectedFilterDialogItemIds = codes.map(
          code => code.id,
        )

        this.dialogStore.activityFilterSetupDialogType =
          ActivityFilterSetupDialogType.ActivityCodes
        break

      case FilterSourceType.ProjectMember:
        const members =
          this.activityFiltersStore.getProjectMembersByFilterType(name)
        this.dialogStore.selectedFilterDialogItemIds = members.map(u => u.id)
        this.dialogStore.activityFilterSetupDialogType =
          ActivityFilterSetupDialogType.ProjectMembers
        break

      default:
        throw new Error(filter.sourceType + ' source type is unhandled')
    }

    this.dialogStore.selectedFilterDialogCategoryIds = filter.codeTypeIds
    this.dialogStore.activityFilterSetupDialogSubtype = name
    this.dialogStore.isSingleSelectMode = name === FilterType.Company
    this.dialogStore.isActivityFilterSetupDialogShown = true
    this.dialogStore.onApply = () => this.addUpdateUIFilter(name)
  }

  private getNewCustomFilterIndex() {
    let index = 1
    this.activityFiltersStore.filterInfoList.forEach(fi => {
      if (fi.isRemovable && index <= fi.index) {
        index = fi.index + 1
      }
    })

    return index
  }

  private getNewCustomFilterCaption() {
    let index = 0
    let isCaptionTaken: boolean
    const customFilterBaseCaption = Localization.translator.customFilter
    do {
      index++
      const lowerCaseCaption = customFilterBaseCaption.toLowerCase() + index
      isCaptionTaken = this.activityFiltersStore.filterInfoList.some(fi => {
        return fi.getCaption().toLowerCase() === lowerCaseCaption
      })
    } while (isCaptionTaken)

    return customFilterBaseCaption + index
  }

  private addUpdateUIFilter(filterType: string) {
    this.eventsStore.appState.filters.locationsMap[filterType] =
      new UIFilterInfo()
  }

  private removeUIFilter(filterType: string) {
    delete this.eventsStore.appState.filters.locationsMap[filterType]
  }

  private removeSavedBands(filterType: string) {
    const { maps, savedActivityGroups = [] } =
      this.eventsStore.appState.activitiesSettings.configurations

    const groupsToSave = savedActivityGroups?.filter(
      group => !group.filterNames.includes(filterType),
    )
    const configurations = {
      maps,
      savedActivityGroups: groupsToSave,
    }

    if (savedActivityGroups?.length !== groupsToSave?.length) {
      this.eventsStore.dispatch(SAVE_ACTIVITIES_CONFIGURATIONS, configurations)
    }
  }
}
