import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { ISiteLocation } from '~/client/graph'

import LocationBase from '../../models/LocationObjects/LocationBase'
import LocationAttributesStore from '../../stores/domain/LocationAttributes.store'
import TagsStore from '../../stores/domain/Tags.store'
import { HIERARCHY_SEPARATOR } from '../HierarchyChains'
import SitemapAttributeTag from '../SitemapAttributeTag/SitemapAttributeTag'

import './LocationLabel.scss'

interface IProps {
  locations: ISiteLocation[]
  isOneColor: boolean
  shouldShowAsTag?: boolean
  shouldAddPadding?: boolean

  locationAttributesStore?: LocationAttributesStore
  tagsStore?: TagsStore
}

@inject('locationAttributesStore', 'tagsStore')
@observer
export default class WorkflowCardLocationLabel extends React.Component<IProps> {
  public render() {
    const {
      locations,
      isOneColor,
      tagsStore: { tagStoreByTagTypeMap },
      shouldShowAsTag,
      shouldAddPadding,
    } = this.props

    const dtos = locations.map(l => this.getById(l.id)).filter(dto => dto)

    return dtos.map((dto, index) => {
      const hierarchyChains = dto.getHierarchyChainObjs(tagStoreByTagTypeMap)
      const locationPath = hierarchyChains
        .reverse()
        .map(obj => obj.name)
        .join(HIERARCHY_SEPARATOR)

      return (
        <div
          key={index}
          className={classList({
            'workflow-card-location-row row no-flex-children pt4': true,
            'one-color': isOneColor,
            pt4: !!shouldAddPadding && !!index,
          })}
        >
          <SitemapAttributeTag
            shouldShowAsTag={shouldShowAsTag}
            dataObject={dto}
          >
            <div className="text large ellipsis parent-tag">{dto.name}</div>
          </SitemapAttributeTag>
          {locationPath && (
            <span
              className="location-row-chain text ellipsis"
              title={locationPath}
            >
              ({locationPath})
            </span>
          )}
        </div>
      )
    })
  }

  private getById = (id: string): LocationBase => {
    return this.props.locationAttributesStore?.getById(id)
  }
}
