import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { formatStatusToDisplay } from '../../constants/DeliveryStatus'
import Delivery from '../../models/Delivery'

interface IProps {
  delivery: Delivery
  className?: string
  shouldAlignStatusToEnd?: boolean
}

@observer
export default class DeliveryStatusText extends React.Component<IProps> {
  public render() {
    const {
      delivery: {
        status,
        cancellationReason,
        isDenied,
        isDelivering,
        isPaused,
        isOnSite,
        isPending,
        isOnHold,
      },
      className,
      shouldAlignStatusToEnd,
    } = this.props

    const deliveryStatus = formatStatusToDisplay(status, cancellationReason)
    const shouldInheritTextColor = !isDenied && !isPending && !isOnSite

    return (
      <div
        className={classList({
          [className]: !!className,
          'inherit-color': shouldInheritTextColor,
          'light-blue': isPending,
          yellow: isOnHold,
          orange: isOnSite || isDelivering,
          red: isDenied || isPaused,
          end: shouldAlignStatusToEnd,
        })}
        title={deliveryStatus}
      >
        {deliveryStatus}
      </div>
    )
  }
}
