import * as React from 'react'

import { observer } from 'mobx-react'

import UsersDirectory from '../../UsersDirectory/UsersDirectory'
import ChatDetails from './ChatDetails'
import ChatEditorStore from './ChatEditor.store'

interface IProps {
  store: ChatEditorStore
  onlineContacts: Set<string>
  areMembersBeingLoaded: boolean
}

@observer
export default class ChatEditor extends React.Component<IProps> {
  private get store() {
    return this.props.store
  }

  public render() {
    return this.store.isMembersSelectionMode ? (
      <UsersDirectory
        selectedUsers={this.store.selectedMembers}
        isSelectionMode={true}
        onUserAddClick={this.store.toggleContactSelection}
        onUserRemoveClick={this.store.toggleContactSelection}
        userPredicate={this.store.isNotChatCreator}
      />
    ) : (
      <ChatDetails
        areMembersBeingLoaded={this.props.areMembersBeingLoaded}
        chatAvatarUrl={this.store.chatAvatarUrl}
        chatName={this.store.chatName}
        members={this.store.selectedMembers}
        onAddMemberButtonClick={this.store.setMemberSelectionMode}
        onChatAvatarUrlChange={this.store.changeAvatarUrl}
        onChatNameChange={this.store.changeGroupName}
        onMemberExclude={this.store.toggleContactSelection}
        creatorId={this.store.chatCreatorId}
        isMemberOnline={userId => this.props.onlineContacts.has(userId)}
      />
    )
  }
}
