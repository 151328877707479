enum WeatherUnits {
  Metric = 'metric',
  Imperial = 'imperial',
}

const fahrenheit = '°F'
const celsius = '°C'
const milesPerHour = 'mph'
const metersPerSec = 'm/s'

export const temperatureUnits = (units: WeatherUnits): string => {
  return units === WeatherUnits.Imperial ? fahrenheit : celsius
}

export const windSpeedUnits = (units: WeatherUnits): string => {
  return units === WeatherUnits.Imperial ? milesPerHour : metersPerSec
}

export default WeatherUnits
