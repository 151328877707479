import * as React from 'react'

import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import Localization from '../localization/LocalizationManager'
import ProjectDateStore from '../stores/ui/ProjectDate.store'
import { capitalizeEachWord } from '../utils/capitalizeText'
import { getTimezoneShortLabel } from '../utils/timezones'

interface IProps {
  projectDateStore?: ProjectDateStore
  format: string
  timezoneId?: string
}

const UPDATE_PERIOD_MS = 5000

@inject('projectDateStore')
@observer
export default class ClockComponent extends React.Component<IProps> {
  @observable private time: string = null
  private interval: any

  public constructor(props: IProps) {
    super(props)
    this.interval = setInterval(this.updateTime, UPDATE_PERIOD_MS)
  }

  public componentDidMount(): void {
    this.updateTime()
  }

  public componentWillUnmount() {
    clearInterval(this.interval)
  }

  public render() {
    if (!this.time) {
      return null
    }

    return (
      this.time + (this.props.timezoneId ? ` ${this.timeZoneShortLabel}` : '')
    )
  }

  private get timeZoneShortLabel() {
    return getTimezoneShortLabel(this.props.timezoneId)
  }

  private updateTime = () => {
    const { format } = this.props.projectDateStore
    this.time = capitalizeEachWord(
      format(
        new Date(),
        this.props.format,
        Localization.dateLocale,
        this.props.timezoneId,
      ),
    )
  }
}
