import { action, computed, observable } from 'mobx'

import { TagType } from '../../../enums/TagType'
import User from '../../../models/User'
import ChatService from '../../../services/ChatService/Chat.service'
import { IChat, IContact } from '../../../services/ChatService/types'
import ProjectMembersStore from '../../../stores/domain/ProjectMembers.store'
import TagsStore from '../../../stores/domain/Tags.store'

export default class ChatEditorStore {
  @observable public isMembersSelectionMode: boolean = false
  @observable public isUpdating: boolean = false

  @observable public chatName: string = ''
  @observable public chatAvatarUrl: string = ''

  @observable public selectedMemberIds: Set<string> = new Set()

  public readonly chatCreatorId: string = ''

  public constructor(
    private initialChat: IChat,
    private readonly onEdit: (updatedChat: IChat) => void,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly chatService: ChatService,
    public externalContactsMap: Map<string, IContact>,
    private readonly tagsStore: TagsStore,
  ) {
    this.chatName = initialChat.name
    this.chatAvatarUrl = initialChat.avatar

    this.chatCreatorId = initialChat.createdBy

    this.selectedMemberIds = new Set(initialChat.memberIds)
  }

  @action.bound
  public async edit() {
    const updatedChat = {
      ...this.initialChat,
      name: this.chatName,
      avatar: this.chatAvatarUrl,
      memberIds: [...this.selectedMemberIds],
    }

    this.isUpdating = true

    try {
      await this.chatService.updateChat(updatedChat)
    } catch (e) {
      return alert(e)
    } finally {
      this.isUpdating = false
    }

    this.onEdit(updatedChat)
  }

  public performNextAction = () => {
    if (this.isMembersSelectionMode) {
      this.unsetMemberSelectionMode()
    } else {
      this.edit()
    }
  }

  public get actionButtonTitle(): string {
    return this.isMembersSelectionMode ? 'Next' : 'Update'
  }

  public get headerSubtitle(): string {
    return this.isMembersSelectionMode
      ? `members ${this.selectedMemberIds.size}, companies ${this.involvedCompaniesCount}`
      : ''
  }

  public get isActionButtonEnabled(): boolean {
    const isAtLeastOneMemberExceptCreatorSelected =
      this.selectedMemberIds.size > 1

    if (this.isMembersSelectionMode) {
      return isAtLeastOneMemberExceptCreatorSelected
    }

    return (
      !!this.chatName &&
      isAtLeastOneMemberExceptCreatorSelected &&
      this.hasSomethingChanged
    )
  }

  @computed
  public get selectedMembers(): User[] {
    return [...this.selectedMemberIds.values()]
      .map(
        mid =>
          this.projectMembersStore.getById(mid) ||
          (this.externalContactsMap.get(mid) as User),
      )
      .filter(m => !!m)
  }

  @action.bound
  public toggleContactSelection(userId: string) {
    if (this.selectedMemberIds.has(userId)) {
      this.selectedMemberIds.delete(userId)
    } else {
      this.selectedMemberIds.add(userId)
    }
  }

  @action.bound
  public setMemberSelectionMode() {
    this.isMembersSelectionMode = true
  }

  @action.bound
  public unsetMemberSelectionMode() {
    this.isMembersSelectionMode = false
  }

  @action.bound
  public changeAvatarUrl(avatarUrl: string) {
    this.chatAvatarUrl = avatarUrl
  }

  @action.bound
  public changeGroupName(name: string) {
    this.chatName = name
  }

  public isNotChatCreator = (user: User): boolean => {
    return user.id !== this.chatCreatorId
  }

  @computed
  private get hasSomethingChanged(): boolean {
    return (
      this.chatName !== this.initialChat.name ||
      this.chatAvatarUrl !== this.initialChat.avatar ||
      this.selectedMemberIds.size !== this.initialChat.memberIds.length ||
      ![...this.selectedMemberIds].every(memberId =>
        this.initialChat.memberIds.includes(memberId),
      )
    )
  }

  @computed
  public get involvedCompaniesCount(): number {
    const usersByCompanies = this.tagsStore.mapUsersByTagType(
      this.selectedMembers,
      TagType.Company,
      false,
    )

    return Object.keys(usersByCompanies).length
  }
}
