import * as React from 'react'

import { inject, observer } from 'mobx-react'

import ActivityTeamDetails from '~/client/src/shared/components/ActivityTeamDetails/ActivityTeamDetails'
import { Loader } from '~/client/src/shared/components/Loader'
import Activity from '~/client/src/shared/models/Activity'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import ActivityAssignmentsStore from '~/client/src/shared/stores/domain/ActivityAssignments.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ProjectRolesStore from '~/client/src/shared/stores/domain/ProjectRoles.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import WbsStore from '~/client/src/shared/stores/domain/WbsStore/Wbs.store'

import CompanyDetails from '../CompanyDetails/CompanyDetails'
import DatesDetails from '../DatesDetails/DatesDetails'
import LocationDetails from '../LocationDetails/LocationDetails'
import ScheduleDetails from '../ScheduleDetails/ScheduleDetails'
import StatusDetails from '../StatusDetails/StatusDetails'
import ActivityInfoStore from './ActivityInfo.store'

interface IProps {
  selectedActivity: Activity
  className?: string
  activityAssignmentsStore?: ActivityAssignmentsStore
  projectRolesStore?: ProjectRolesStore
  companiesStore?: CompaniesStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  graphExecutorStore?: GraphExecutorStore
  eventsStore?: EventsStore
  wbsStore?: WbsStore
}

@inject(
  'activityAssignmentsStore',
  'projectRolesStore',
  'companiesStore',
  'userProjectsStore',
  'projectMembersStore',
  'graphExecutorStore',
  'eventsStore',
  'wbsStore',
)
@observer
export default class ActivityInfoSection extends React.Component<IProps> {
  private readonly store: ActivityInfoStore

  public constructor(props: IProps) {
    super(props)

    this.store = new ActivityInfoStore(
      props.activityAssignmentsStore,
      props.projectRolesStore,
      props.companiesStore,
      props.userProjectsStore,
      props.projectMembersStore,
      props.graphExecutorStore,
      props.eventsStore,
      props.wbsStore,
      props.selectedActivity,
    )
  }

  public componentDidUpdate(prevProps: IProps) {
    const { selectedActivity } = this.props

    if (selectedActivity?.id !== prevProps.selectedActivity?.id) {
      this.store.init(selectedActivity)
    }
  }

  public render() {
    if (this.store.isLoading) {
      return <Loader />
    }

    const { selectedActivity, className } = this.props

    return (
      <div className={className || 'px10'}>
        <LocationDetails store={this.store} />
        <CompanyDetails store={this.store} />
        <ScheduleDetails store={this.store} />
        <ActivityTeamDetails store={this.store} />
        <DatesDetails activity={selectedActivity} />
        <StatusDetails activity={selectedActivity} />
      </div>
    )
  }
}
