import * as React from 'react'

import { classList } from 'react-classlist-helper'

import SelectWrapper from '~/client/src/shared/components/SelectWrapper/SelectWrapper'
import IBasicProps from '~/client/src/shared/interfaces/IBasicProps'

import {
  FieldState,
  getFieldStateTranslate,
} from './FieldConfigurator/FieldConfigurator.store'

interface IProps extends IBasicProps {
  fieldState: FieldState
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  isDisabled?: boolean
}

// translated

export default function FieldStateSelector({
  fieldState,
  onChange,
  className = '',
  isDisabled,
}: IProps) {
  const classNames = classList({
    [className]: true,
    'pl12 pr25 py6 ba-none bg-inherit': true,
    'text large no-grow pointer bold': true,
    'blue-highlight': fieldState === FieldState.Mandatory,
    light: fieldState === FieldState.Hidden,
  })

  if (isDisabled) {
    return (
      <div className={classNames}>{getFieldStateTranslate(fieldState)}</div>
    )
  }
  return (
    <SelectWrapper className="no-grow">
      <select value={fieldState} onChange={onChange} className={classNames}>
        {Object.values(FieldState).map(fState => (
          <option key={fState} value={fState}>
            {getFieldStateTranslate(fState)}
          </option>
        ))}
      </select>
    </SelectWrapper>
  )
}
