import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import { getDeliveryStatusAsTag } from '~/client/src/shared/constants/deliveryStatusesTags'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'

interface IProps {
  status: DeliveryStatus
  cancellationReason?: string
  className?: string
  showMenuPopup?: () => void
}

export default class DeliveryStatusLabel extends React.PureComponent<IProps> {
  public static defaultProps = {
    className: '',
  }

  public render() {
    const { showMenuPopup, className } = this.props
    const dataObject = this.getStatusAsObjectData()

    return (
      <span className={className}>
        <SitemapAttributeTag
          dataObject={dataObject}
          shouldShowAsTag={true}
          contentContainerClassName="text-ellipsis py2"
        >
          <span title={dataObject.name} className="text large">
            {dataObject.name}
          </span>
          {showMenuPopup && (
            <div onClick={showMenuPopup}>
              <Icon icon={IconNames.CARET_DOWN} />
            </div>
          )}
        </SitemapAttributeTag>
      </span>
    )
  }

  private getStatusAsObjectData(): LocationAttributeBase {
    return getDeliveryStatusAsTag(
      this.props.status,
      this.props.cancellationReason,
    ) as LocationAttributeBase
  }
}
