import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DeliveryDetails from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'

import NotificationDetailsViewStore from '../NotificationDetailsView.store'
import NotificationDetailsBase from './NotificationDetailsBase/NotificationDetailsBase'

interface IProps {
  deliveryId: string
  backClicked: () => void
  store: NotificationDetailsViewStore
  deliveriesStore?: DeliveriesStore
  deliveryDetailsStore?: DeliveryDetailsStore
  state?: DesktopInitialState
}

@inject('deliveriesStore', 'deliveryDetailsStore', 'state')
@observer
export default class NotificationDetailsDelivery extends NotificationDetailsBase<IProps> {
  public UNSAFE_componentWillReceiveProps(newProps: IProps) {
    if (this.props.deliveryId !== newProps.deliveryId) {
      this.setDelivery(newProps.deliveryId)
    }
  }
  public UNSAFE_componentWillMount() {
    this.setDelivery(this.props.deliveryId)
  }

  protected get title() {
    return null
  }

  protected get content() {
    const { backClicked, deliveryId } = this.props
    const { id } = this.props.state.activeProject

    return (
      <DeliveryDetails
        FileInputType={DesktopFileInput}
        initProjectId={id}
        backClicked={backClicked}
        displayedDeliveryId={deliveryId}
      />
    )
  }

  protected get footer() {
    return null
  }

  private setDelivery = (deliveryId: string) => {
    const { deliveriesStore, deliveryDetailsStore } = this.props
    const delivery = deliveriesStore.byId.get(deliveryId)

    if (delivery) {
      deliveryDetailsStore.setViewMode(delivery)
      deliveryDetailsStore.prepareView()
    }
  }
}
