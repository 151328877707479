import { action, observable } from 'mobx'
import { arrayMove } from 'react-sortable-hoc'

import { IScreen, PresentationScreenKey } from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import PresentationModeStore from '~/client/src/desktop/stores/ui/PresentationMode.store'
import {
  GET_PRESENTATION_USER_TOKEN,
  LOAD_AND_LISTEN_TO_PRESENTATION_SETTINGS,
  SAVE_PRESENTATION_SETTINGS,
} from '~/client/src/shared/stores/EventStore/eventConstants'

export default class PresentationSetUpStore {
  @observable public isConfirmationPresentationDialogShown: boolean = false

  public constructor(
    private readonly state: DesktopInitialState,
    private readonly eventsStore: DesktopEventStore,
    private readonly presentationModeStore: PresentationModeStore,
  ) {}

  @action.bound
  public showConfirmationPresentationDialog() {
    this.isConfirmationPresentationDialogShown = true
  }

  @action.bound
  public hideConfirmationPresentationDialog() {
    this.isConfirmationPresentationDialogShown = false
  }

  public get isLoading() {
    return this.state.loading.get(LOAD_AND_LISTEN_TO_PRESENTATION_SETTINGS)
  }

  public get availableScreens(): IScreen[] {
    return this.presentationModeStore.availableScreens
  }

  public get availableScreensCount(): number {
    return this.availableScreens.length
  }

  @action.bound
  public onItemOrderChanged(oldIndex: number, newIndex: number) {
    const screenKeys = this.presentationModeStore.availableScreens.map(
      ({ key }) => key,
    )
    const orderedScreenKeys = arrayMove(screenKeys, oldIndex, newIndex)

    const { screens } = this.state.presentationSettings

    const updatedScreens = screens.map(screen => {
      screen.order = orderedScreenKeys.indexOf(screen.key) + 1
      return screen
    })

    this.eventsStore.dispatch(SAVE_PRESENTATION_SETTINGS, {
      screens: updatedScreens,
    })
  }

  @action.bound
  public onItemDurationChanged(
    key: PresentationScreenKey,
    newDuration: number,
  ) {
    const { screens } = this.eventsStore.appState.presentationSettings

    const updatedScreens = screens.map(screen => {
      if (screen.key === key) {
        screen.duration = newDuration
      }
      return screen
    })

    this.eventsStore.dispatch(SAVE_PRESENTATION_SETTINGS, {
      screens: updatedScreens,
    })
  }

  @action.bound
  public onItemEntityDurationChanged(
    key: PresentationScreenKey,
    newDuration: number,
  ) {
    const { screens } = this.eventsStore.appState.presentationSettings

    const updatedScreens = screens.map(screen => {
      if (screen.key === key) {
        screen.entityDuration = newDuration
      }
      return screen
    })

    this.eventsStore.dispatch(SAVE_PRESENTATION_SETTINGS, {
      screens: updatedScreens,
    })
  }

  @action.bound
  public onItemVisibilityChanged(
    key: PresentationScreenKey,
    newValue: boolean,
  ) {
    const { screens } = this.eventsStore.appState.presentationSettings

    const updatedScreens = screens.map(screen => {
      if (screen.key === key) {
        screen.isShown = newValue
      }
      return screen
    })

    this.eventsStore.dispatch(SAVE_PRESENTATION_SETTINGS, {
      screens: updatedScreens,
    })
  }

  @action.bound
  public onItemEntityVisibilityChanged(
    key: PresentationScreenKey,
    newValue: boolean,
  ) {
    const { screens } = this.eventsStore.appState.presentationSettings

    const updatedScreens = screens.map(screen => {
      if (screen.key === key) {
        screen.shouldShowEntity = newValue
      }
      return screen
    })

    this.eventsStore.dispatch(SAVE_PRESENTATION_SETTINGS, {
      screens: updatedScreens,
    })
  }

  @action.bound
  public onInactivityDurationChanged(inactivityDuration: number) {
    this.eventsStore.dispatch(SAVE_PRESENTATION_SETTINGS, {
      inactivityDuration,
    })
  }

  @action.bound
  public startPresentation() {
    this.hideConfirmationPresentationDialog()
    this.eventsStore.dispatch(GET_PRESENTATION_USER_TOKEN)
  }
}
