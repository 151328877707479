import SitemapType from '~/client/src/shared/enums/SitemapType'

export const ACTION_KEY = 'action'
export const NEW_SITEMAP_TYPE_KEY = 'new-sitemap-type'
export const DTO_ID_KEY = 'dto-id'
export const SITEMAP_ID_KEY = 'sitemap-id'

export enum DelayedActionType {
  SetDefault = 'set-default',
  AssignToGate = 'assign-to-gate',
  AssignToZone = 'assign-to-zone',
  SelectSitemap = 'select-sitemap',
}

export default interface ISitemapDelayedAction {
  action: DelayedActionType
  newSitemapType: SitemapType
  dtoId?: string
  sitemapId?: string
}
