import { DocumentNode } from 'graphql'
import { computed } from 'mobx'

import {
  DeleteDeliveryUnitsDocument,
  GetDeliveryUnitsDocument,
  ListenToDeliveryUnitsDocument,
  SaveDeliveryUnitsDocument,
} from '~/client/graph/operations/generated/DelliveryUnits.generated'

import CommonDeliveryAttributeDto from '../../types/CommonDeliveryAttributeDto'
import CommonDeliveryAttributesStore from './CommonDeliveryAttributes.store'

export default class DeliveryUnitsStore extends CommonDeliveryAttributesStore {
  protected readonly collectionKey: string = 'deliveryUnits'

  protected readonly loadQuery: DocumentNode = GetDeliveryUnitsDocument
  protected readonly subscriptionQuery: DocumentNode =
    ListenToDeliveryUnitsDocument
  protected readonly saveMutation: DocumentNode = SaveDeliveryUnitsDocument
  protected readonly deleteMutation: DocumentNode = DeleteDeliveryUnitsDocument

  @computed
  public get list(): CommonDeliveryAttributeDto[] {
    return Array.from(this.byId.values()).filter(
      entry => !entry.isDeleted && !entry.isFromConcreteDirect,
    )
  }

  public get byId(): Map<string, CommonDeliveryAttributeDto> {
    return this.eventsStore.appState.deliveryUnits
  }
}
