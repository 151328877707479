import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import ActivityDetailsStore from '~/client/src/shared/components/ActivityDetails/ActivityDetails.store'
import { DeliveryPageMode } from '~/client/src/shared/components/Deliveries/DeliveriesView.store'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

import InitialState from '../../stores/InitialState'
import CommonStore from '../../stores/ui/Common.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'

// translated

const ICON_STYLE = { minWidth: '48px' }

export interface IProps {
  isMobile?: boolean
  store?: ActivityDetailsStore
  hideEditButton?: boolean

  slackBarStore?: SlackBarStore
  common?: CommonStore
  state?: InitialState
  projectDateStore?: ProjectDateStore
}

@inject('slackBarStore', 'common', 'state', 'projectDateStore')
@observer
class WorkflowsBottomMenu extends React.Component<IProps> {
  public render() {
    const { isDeliveriesDisabled, userActiveProjectSettings } = this.props.state

    const { isActivityUpdateAvailable } = userActiveProjectSettings

    return (
      <div>
        {this.renderHeader()}
        {!this.props.isMobile &&
          isActivityUpdateAvailable &&
          !this.props.hideEditButton &&
          this.renderEditButton()}
        {this.renderScheduleCommentRow()}
        {this.renderFlagRow()}
        {this.renderRfiRow()}
        {this.renderSafetyHazard()}
        {this.renderCategoryOfVariance()}
        {!isDeliveriesDisabled && this.renderDeliveryRow()}
      </div>
    )
  }

  private renderHeader() {
    const { hideMenuPopup } = this.props.slackBarStore
    return (
      <div className="row pa10 pl20 y-center bb-light-grey">
        <div className="col text primary-blue extra-large bold uppercase">
          {Localization.translator.workflows}
        </div>
        <div className="col no-grow">
          <Icons.Cross onClick={hideMenuPopup} />
        </div>
      </div>
    )
  }

  private renderEditButton() {
    const { hideMenuPopup } = this.props.slackBarStore
    const { setEditActivityMode } = this.props.store

    const handleClick = () => {
      setEditActivityMode()
      hideMenuPopup()
    }

    return (
      <div
        className="row pa15 pl20 y-center bb-light-grey"
        onClick={handleClick}
      >
        <div className="col no-grow pr20 text center" style={ICON_STYLE}>
          <Icons.Edit className="icon-red-g" />
        </div>
        <div className="col text large">
          {Localization.translator.edit_verb}
        </div>
      </div>
    )
  }

  private renderScheduleCommentRow() {
    const { enableScheduleCommentMode } = this.props.slackBarStore

    return (
      <div
        className="row pa15 pl20 y-center bb-light-grey"
        onClick={enableScheduleCommentMode}
      >
        <div className="col no-grow pr20 text center" style={ICON_STYLE}>
          <Icons.DateChangeRed />
        </div>
        <div className="col text large ">
          {Localization.translator.scheduleComment}
        </div>
      </div>
    )
  }

  private renderFlagRow() {
    const { enableFlagMode } = this.props.slackBarStore

    return (
      <div
        className="row pa15 pl20 y-center bb-light-grey"
        onClick={enableFlagMode}
      >
        <div className="col no-grow pr20">
          <Icons.Flag className="icon-red" />
        </div>
        <div className="col text large ">
          {Localization.translator.flagAnIssue}
        </div>
      </div>
    )
  }

  private renderRfiRow() {
    const { enableRfiMode } = this.props.slackBarStore

    return (
      <div
        className="row pa15 pl20 y-center bb-light-grey"
        onClick={enableRfiMode}
      >
        <div className="col no-grow pr20">
          <Icons.Rfi />
        </div>
        <div className="col text large ">
          {Localization.translator.openAnRfi}
        </div>
      </div>
    )
  }

  private renderSafetyHazard() {
    const { enableSafetyHazardMode } = this.props.slackBarStore

    return (
      <div
        className="row pa15 pl20 y-center bb-light-grey"
        onClick={enableSafetyHazardMode}
      >
        <div className="col no-grow pr20 text center" style={ICON_STYLE}>
          <Icons.Safety />
        </div>
        <div className="col text large ">
          {Localization.translator.safetyHazard}
        </div>
      </div>
    )
  }

  private renderCategoryOfVariance() {
    const { enableCategoryOfVarianceMode } = this.props.slackBarStore

    return (
      <div
        className="row pa15 pl20 y-center bb-light-grey"
        onClick={enableCategoryOfVarianceMode}
      >
        <div className="col no-grow pr20 text center" style={ICON_STYLE}>
          <Icons.Variance />
        </div>
        <div className="col text large ">
          {Localization.translator.categoryOfVariance}
        </div>
      </div>
    )
  }

  private renderDeliveryRow() {
    return (
      <div
        className="row pa15 pl20 y-center bb-light-grey"
        onClick={this.requestDelivery}
      >
        <div style={ICON_STYLE} className="col no-grow pr20 text center">
          <Icons.Delivery />
        </div>
        <div className="col text large ">
          {Localization.translator.requestADelivery}
        </div>
      </div>
    )
  }

  @action.bound
  private requestDelivery() {
    const { common, slackBarStore, state } = this.props

    slackBarStore.hideMenuPopup()
    state.delivery.compactView.highlightedDelivery = null
    state.delivery.compactView.mode = DeliveryPageMode.REQUEST_FROM_ACTIVITY
    const { startOfDay } = this.props.projectDateStore
    state.delivery.compactView.activeDate = startOfDay(new Date())
    state.delivery.compactView.resetViewMode()
    common.openDeliveryView()
  }
}

export default WorkflowsBottomMenu
