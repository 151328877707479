import { action, observable } from 'mobx'

import { IReportTemplate } from '~/client/graph'
import HeaderBarStore from '~/client/src/desktop/components/HeaderBar/HeaderBar.store'
import {
  IModeSettings,
  getModeSettings,
} from '~/client/src/desktop/components/HeaderBar/components/ViewModeToggle/ViewModeToggle'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { getReportFileTypeLabel } from '~/client/src/shared/enums/ReportFileTypes'
import { LOAD_REPORT_TEMPLATE } from '~/client/src/shared/stores/EventStore/eventConstants'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'

import { MATERIALS_REPORT_TEMPLATE_ID } from './components/MaterialReportButton/MaterialReportButton'

export default class MaterialsHeaderBarStore extends HeaderBarStore {
  @observable public searchKey: string = ''

  public constructor(private readonly eventsStore: DesktopEventStore) {
    super(eventsStore.appState)

    this.viewState = eventsStore.appState.materialsList
  }

  public get forceCloseMap() {
    const { fieldsMap } = this.state.materialFilters
    return this.getForceCloseMap(fieldsMap)
  }

  private getForceCloseMap(fieldsMap: { [filterType: string]: UIFilterInfo }) {
    return Object.keys(fieldsMap).reduce((map, filterType) => {
      map[filterType] = this.openedPopup && this.openedPopup !== filterType
      return map
    }, {})
  }

  public get viewModes(): IModeSettings[] {
    return [
      getModeSettings(ViewModes.Gantt, true),
      getModeSettings(ViewModes.List),
      getModeSettings(ViewModes.Map, true),
    ]
  }

  public loadReportTemplate = () => {
    this.eventsStore.dispatch(
      LOAD_REPORT_TEMPLATE,
      MATERIALS_REPORT_TEMPLATE_ID,
      this.reportTemplateReceived,
    )
  }

  @action.bound
  public changeSearchKey(newSearchKey: string) {
    this.searchKey = newSearchKey
  }

  private reportTemplateReceived = (reportTemplate: IReportTemplate) => {
    this.additionalReportFiles = reportTemplate.files.map((file, i) => ({
      value: file.fileId,
      format: file.fileType,
      name: file.name,
      label: getReportFileTypeLabel(file.fileId) || file.name,
      isChecked: i === 0,
    }))
  }
}
