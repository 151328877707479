import React from 'react'

import * as Icons from '~/client/src/shared/components/Icons'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'

interface IProps {
  status: DeliveryStatus
}

export default class DeliveryStatusIcon extends React.Component<IProps> {
  public render() {
    const { status } = this.props
    switch (status) {
      case DeliveryStatus.IncompleteDone:
      case DeliveryStatus.Done:
        return <Icons.DeliveryNotificationDone />
      case DeliveryStatus.Delivering:
      case DeliveryStatus.OnSite:
      case DeliveryStatus.OnHold:
        return <Icons.DeliveryNotificationOnsite />
      case DeliveryStatus.PassedInspection:
        return <Icons.DeliveryNotificationInspectionAccepted />
      case DeliveryStatus.Requested:
      case DeliveryStatus.Canceled:
        return <Icons.DeliveryNotificationRequested />
      case DeliveryStatus.Scheduled:
        return <Icons.DeliveryNotificationScheduled />
      case DeliveryStatus.Changed:
        return <Icons.DeliveryNotificationChanged />
      case DeliveryStatus.FailedInspection:
      case DeliveryStatus.Paused:
      case DeliveryStatus.Denied:
        return <Icons.DeliveryNotificationInspectionRejected />
      default:
        return null
    }
  }
}
