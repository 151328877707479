import ActivityGroupingType from '../enums/ActivityGroupingType'
import Localization from '../localization/LocalizationManager'

const activityGroupingTypeToCaptionMap = {
  [ActivityGroupingType.Equipment]: Localization.translator.equipment,
  [ActivityGroupingType.VerticalObject]: Localization.translator.verticalObject,
  [ActivityGroupingType.Gate]: Localization.translator.gate,
  [ActivityGroupingType.Route]: Localization.translator.route,
}

export default activityGroupingTypeToCaptionMap
