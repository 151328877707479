import { DocumentNode } from 'graphql'
import { computed } from 'mobx'

import GraphExecutor from '../utils/GraphExecutor'
import Guard from '../utils/Guard'
import EventsStore from './EventStore/Events.store'
import GraphExecutorStore from './domain/GraphExecutor.store'

interface GenericDto {
  id?: string
  filtersByFilterType?: any
  userId?: string
  isPublic?: boolean
}

export default abstract class BaseCustomListFiltersStore<
  T extends { toInput(): IDto },
  IDto extends GenericDto,
> {
  protected abstract readonly saveMutationDoc: DocumentNode
  protected abstract readonly deleteMutationDoc: DocumentNode

  public abstract byId: Map<string, T>
  public abstract getInstanceFromDto(dto: IDto): T

  public constructor(
    protected readonly eventsStore: EventsStore,
    protected readonly graphExecutorStore: GraphExecutorStore,
  ) {
    Guard.requireAll({ eventsStore, graphExecutorStore })
  }

  @computed
  public get list(): T[] {
    return Array.from(this.byId.values())
  }

  public receiveOne(dto: IDto) {
    if (dto.filtersByFilterType && this.isFilterAvailableForActiveUser(dto)) {
      this.byId.set(dto.id, this.getInstanceFromDto(dto))
    }
  }

  public receiveList(dtos: IDto[]) {
    this.byId.clear()
    dtos.forEach(dto => this.receiveOne(dto))
  }

  public async saveOne(filter: T) {
    const { data, error } = await this.graphExecutorStore.mutate(
      this.saveMutationDoc,
      { filter: filter.toInput() },
    )

    if (!error) {
      this.receiveOne(GraphExecutor.flattenResponse(data))
    }
  }

  public async removeOne(filterId: string) {
    const { data, error } = await this.graphExecutorStore.mutate(
      this.deleteMutationDoc,
      { filterId },
    )

    if (!error && !!GraphExecutor.flattenResponse(data)) {
      this.byId.delete(filterId)
    }
  }

  protected get activeUserId(): string {
    return this.eventsStore.appState.user.id
  }

  protected get activeProjectId(): string {
    return this.eventsStore.appState.activeProject.id
  }

  private isFilterAvailableForActiveUser({ userId, isPublic }: IDto): boolean {
    return isPublic || this.activeUserId === userId
  }
}
