import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Checkbox from '~/client/src/shared/components/Checkbox'
import {
  BasicDataKeys,
  ILWFCCategory,
  ILWFCColumn,
  ILWFCRow,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import TableSeparators from '~/client/src/shared/components/TableSeparators'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import { logisticsDataKeys } from '~/client/src/shared/types/LogisticsDataKeys'
import {
  getBackgroundColor,
  getTextColor,
} from '~/client/src/shared/utils/tableColoring'

interface IProps {
  style: any
  columnIndex: number
  rowIndex: number
  rows: ILWFCRow[]
  columns: ILWFCColumn[]
  columnKeyToCellRenderer: {
    [columnKey: string]: (value: any, level?: number) => JSX.Element
  }
  columnKeyToDefaultRenderer: {
    [columnKey: string]: (value: any, level?: number) => JSX.Element
  }
  collapsedCategories?: Map<string, boolean>
  onCategoryCollapsingToggle?: (categoryId: string) => void
  onCellClick?: (rowData: LWFCRowData, columnKey: string) => void
  onCategoryCheckboxToggle?: (category: ILWFCCategory) => void
}

const { CARET_DOWN, CARET_RIGHT } = IconNames
const CATEGORY_LABEL_COLUMN_INDEX = 1

@observer
export default class LogisticsTableDataCell extends React.Component<IProps> {
  public render() {
    const { style, columnIndex, rowIndex, rows, columns } = this.props
    const column = columns[columnIndex]
    const { category, data, level } = rows[rowIndex]
    const cellProps = { style }

    return category
      ? this.renderCategoryCell(
          category,
          column,
          columnIndex,
          cellProps,
          level,
          data,
        )
      : this.renderDataCell(data, column, cellProps)
  }

  private renderCategoryCell = (
    category: ILWFCCategory,
    { dataKey }: ILWFCColumn,
    columnIndex: number,
    cellProps: any,
    level: number,
    data: LWFCRowData,
  ): JSX.Element => {
    const { collapsedCategories, onCategoryCollapsingToggle } = this.props
    const { categoryId, categoryLabel, isChecked } = category

    const deliveryAttribute = data as LocationAttributeBase
    const shouldShowCategoryLabel = columnIndex === CATEGORY_LABEL_COLUMN_INDEX
    const isCheckboxCell = dataKey === BasicDataKeys.CHECKBOX

    const backgroundColor = getBackgroundColor(categoryId, deliveryAttribute)
    const textColor = getTextColor(categoryId)
    let cellContent = null

    if (shouldShowCategoryLabel) {
      const caretIcon = collapsedCategories.get(categoryId)
        ? CARET_RIGHT
        : CARET_DOWN

      cellContent = (
        <>
          <TableSeparators
            level={level}
            className="no-grow full-height br-palette-grey mr10"
          />
          <div className="row no-flex-children text-ellipsis logistics-list-category-label">
            <Icon className="pointer mr5" icon={caretIcon} />
            <SitemapAttributeTag
              shouldShowAsTag={false}
              contentContainerClassName="text-ellipsis py2 row name-cell"
              dataObject={deliveryAttribute}
            >
              {categoryLabel}
            </SitemapAttributeTag>
          </div>
        </>
      )
    } else if (isCheckboxCell) {
      cellContent = (
        <Checkbox
          isChecked={isChecked}
          onClick={this.handleCategoryCheckboxToggle.bind(null, category)}
        />
      )
    }

    return (
      <div
        onClick={onCategoryCollapsingToggle.bind(null, categoryId)}
        className={classList({
          'full-height full-width row bb-light-cool-grey text large bold': true,
          'cell no-select bg-grey-scale-dark white': true,
          pointer: !isCheckboxCell,
          'checkbox-no-right-border x-center': isCheckboxCell,
          'category-cell-name': shouldShowCategoryLabel,
        })}
        style={{ ...cellProps.style, backgroundColor, color: textColor }}
      >
        {cellContent}
      </div>
    )
  }

  private renderDataCell = (
    data: LWFCRowData,
    column: ILWFCColumn,
    cellProps: any,
  ): JSX.Element => {
    const { columnKeyToCellRenderer, columnKeyToDefaultRenderer } = this.props
    const { dataKey: columnKey, isMonospace } = column
    const value = data[columnKey]

    const isCheckboxCell = columnKey === BasicDataKeys.CHECKBOX
    const isCompanyCell = columnKey === logisticsDataKeys.LOGISTIC_COMPANY

    const renderer =
      columnKeyToCellRenderer[columnKey] ||
      columnKeyToDefaultRenderer[columnKey] ||
      (() => value)

    const element = renderer(value)

    return (
      <div
        {...cellProps}
        onClick={this.onCellClick.bind(this, data, columnKey)}
        className={classList({
          'full-height full-width row bb-light-cool-grey cell text large': true,
          'gant-selection': data[BasicDataKeys.CHECKBOX],
          'x-center': isCheckboxCell,
          monospace: isMonospace,
        })}
      >
        <div
          className={classList({
            'text-ellipsis': !isCompanyCell,
            'wrap row full-height full-width relative': isCompanyCell,
          })}
        >
          {element}
        </div>
      </div>
    )
  }

  @action.bound
  private handleCategoryCheckboxToggle(
    category: ILWFCCategory,
    e: React.MouseEvent<HTMLElement>,
  ) {
    e.stopPropagation()
    this.props.onCategoryCheckboxToggle(category)
  }

  private onCellClick = (
    rowData: LWFCRowData,
    columnKey: string,
    e: React.MouseEvent<HTMLElement>,
  ) => {
    this.props.onCellClick(rowData, columnKey)
    this.stopPropagation(e)
  }

  private stopPropagation(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()
  }
}
