import { action, computed, observable } from 'mobx'

import { IBasemap, IBasemapInput } from '~/client/graph'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'

import Basemap from '../../models/Basemap'
import { SAVE_BASEMAP } from '../EventStore/eventConstants'

export default class BasemapsStore {
  @observable public isDataReceived = false

  public constructor(private eventsStore: EventsStore) {}

  @computed
  public get list(): Basemap[] {
    return Array.from(this.byId.values())
  }

  public get byId() {
    const { basemaps } = this.eventsStore.appState
    return basemaps
  }

  @action.bound
  public clearList() {
    this.isDataReceived = false
    this.byId.clear()
  }

  @action.bound
  public receiveList(list: IBasemap[]) {
    this.clearList()

    list.forEach(dto => {
      const basemap = Basemap.fromDto(dto)
      this.byId.set(basemap.id, basemap)
    })

    this.isDataReceived = true
  }

  @action.bound
  public receiveOne(id: string, dto: IBasemap) {
    if (dto) {
      this.byId.set(dto.id, Basemap.fromDto(dto))
    } else {
      this.byId.delete(id)
    }
  }

  @action.bound
  public save(basemap: Basemap, callbackFn: (id: string) => void) {
    const { id: projectId } = this.eventsStore.appState.activeProject

    const basemapInput: IBasemapInput = {
      id: basemap.id,
      name: basemap.name,
      source: basemap.source,
      fileName: basemap.fileName,
      projectId: basemap.projectId || projectId,
    }

    this.eventsStore.dispatch(SAVE_BASEMAP, basemapInput, callbackFn)
  }
}
