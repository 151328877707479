import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IProjectFieldsFragment = Pick<
  Types.IProject,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'name'
  | 'code'
  | 'logoUrl'
  | 'scheduleId'
  | 'materialsUploadId'
  | 'dateTimeFormat'
  | 'defaultLandingPage'
  | 'timezoneId'
  | 'setUpFinished'
  | 'workingDays'
> & {
  closedIntervals?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.IProjectClosedInterval, 'name' | 'startDate' | 'endDate'>
      >
    >
  >
  workingHours?: Types.Maybe<Pick<Types.IWorkingHours, 'startTime' | 'endTime'>>
}

export type IGetAvailableProjectsQueryVariables = Types.Exact<{
  userId: Types.Scalars['ObjectId']
}>

export type IGetAvailableProjectsQuery = {
  projects?: Types.Maybe<{ data: Array<IProjectFieldsFragment> }>
}

export type IGetProjectNameQueryVariables = Types.Exact<{
  projectCode: Types.Scalars['String']
}>

export type IGetProjectNameQuery = Pick<Types.IQuery, 'getProjectName'>

export type ICheckIsProjectCodeUniqueQueryVariables = Types.Exact<{
  projectCode: Types.Scalars['String']
}>

export type ICheckIsProjectCodeUniqueQuery = Pick<
  Types.IQuery,
  'checkIsProjectCodeUnique'
>

export type ISaveProjectsMutationVariables = Types.Exact<{
  projects:
    | Array<Types.Maybe<Types.IProjectInput>>
    | Types.Maybe<Types.IProjectInput>
}>

export type ISaveProjectsMutation = {
  saveManyProjects?: Types.Maybe<Array<Types.Maybe<IProjectFieldsFragment>>>
}

export type IListenToProjectSubscriptionVariables = Types.Exact<{
  id: Types.Scalars['ObjectId']
}>

export type IListenToProjectSubscription = {
  project?: Types.Maybe<
    Pick<Types.IProjectChangeEvent, 'id'> & {
      item?: Types.Maybe<IProjectFieldsFragment>
    }
  >
}

export const ProjectFieldsFragmentDoc = gql`
  fragment ProjectFields on Project {
    id
    createdAt
    updatedAt
    name
    code
    logoUrl
    scheduleId
    materialsUploadId
    dateTimeFormat
    defaultLandingPage
    timezoneId
    closedIntervals {
      name
      startDate
      endDate
    }
    setUpFinished
    workingDays
    workingHours {
      startTime
      endTime
    }
  }
`
export const GetAvailableProjectsDocument = gql`
  query GetAvailableProjects($userId: ObjectId!) {
    projects(userId: $userId, limit: 1000000) {
      data {
        ...ProjectFields
      }
    }
  }
  ${ProjectFieldsFragmentDoc}
`

/**
 * __useGetAvailableProjectsQuery__
 *
 * To run a query within a React component, call `useGetAvailableProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableProjectsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAvailableProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetAvailableProjectsQuery,
    IGetAvailableProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetAvailableProjectsQuery,
    IGetAvailableProjectsQueryVariables
  >(GetAvailableProjectsDocument, options)
}
export function useGetAvailableProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetAvailableProjectsQuery,
    IGetAvailableProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetAvailableProjectsQuery,
    IGetAvailableProjectsQueryVariables
  >(GetAvailableProjectsDocument, options)
}
export type GetAvailableProjectsQueryHookResult = ReturnType<
  typeof useGetAvailableProjectsQuery
>
export type GetAvailableProjectsLazyQueryHookResult = ReturnType<
  typeof useGetAvailableProjectsLazyQuery
>
export type GetAvailableProjectsQueryResult = Apollo.QueryResult<
  IGetAvailableProjectsQuery,
  IGetAvailableProjectsQueryVariables
>
export const GetProjectNameDocument = gql`
  query GetProjectName($projectCode: String!) {
    getProjectName(projectCode: $projectCode)
  }
`

/**
 * __useGetProjectNameQuery__
 *
 * To run a query within a React component, call `useGetProjectNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectNameQuery({
 *   variables: {
 *      projectCode: // value for 'projectCode'
 *   },
 * });
 */
export function useGetProjectNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetProjectNameQuery,
    IGetProjectNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetProjectNameQuery, IGetProjectNameQueryVariables>(
    GetProjectNameDocument,
    options,
  )
}
export function useGetProjectNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetProjectNameQuery,
    IGetProjectNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetProjectNameQuery,
    IGetProjectNameQueryVariables
  >(GetProjectNameDocument, options)
}
export type GetProjectNameQueryHookResult = ReturnType<
  typeof useGetProjectNameQuery
>
export type GetProjectNameLazyQueryHookResult = ReturnType<
  typeof useGetProjectNameLazyQuery
>
export type GetProjectNameQueryResult = Apollo.QueryResult<
  IGetProjectNameQuery,
  IGetProjectNameQueryVariables
>
export const CheckIsProjectCodeUniqueDocument = gql`
  query CheckIsProjectCodeUnique($projectCode: String!) {
    checkIsProjectCodeUnique(projectCode: $projectCode)
  }
`

/**
 * __useCheckIsProjectCodeUniqueQuery__
 *
 * To run a query within a React component, call `useCheckIsProjectCodeUniqueQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIsProjectCodeUniqueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIsProjectCodeUniqueQuery({
 *   variables: {
 *      projectCode: // value for 'projectCode'
 *   },
 * });
 */
export function useCheckIsProjectCodeUniqueQuery(
  baseOptions: Apollo.QueryHookOptions<
    ICheckIsProjectCodeUniqueQuery,
    ICheckIsProjectCodeUniqueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ICheckIsProjectCodeUniqueQuery,
    ICheckIsProjectCodeUniqueQueryVariables
  >(CheckIsProjectCodeUniqueDocument, options)
}
export function useCheckIsProjectCodeUniqueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICheckIsProjectCodeUniqueQuery,
    ICheckIsProjectCodeUniqueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ICheckIsProjectCodeUniqueQuery,
    ICheckIsProjectCodeUniqueQueryVariables
  >(CheckIsProjectCodeUniqueDocument, options)
}
export type CheckIsProjectCodeUniqueQueryHookResult = ReturnType<
  typeof useCheckIsProjectCodeUniqueQuery
>
export type CheckIsProjectCodeUniqueLazyQueryHookResult = ReturnType<
  typeof useCheckIsProjectCodeUniqueLazyQuery
>
export type CheckIsProjectCodeUniqueQueryResult = Apollo.QueryResult<
  ICheckIsProjectCodeUniqueQuery,
  ICheckIsProjectCodeUniqueQueryVariables
>
export const SaveProjectsDocument = gql`
  mutation SaveProjects($projects: [ProjectInput]!) {
    saveManyProjects(projects: $projects) {
      ...ProjectFields
    }
  }
  ${ProjectFieldsFragmentDoc}
`
export type ISaveProjectsMutationFn = Apollo.MutationFunction<
  ISaveProjectsMutation,
  ISaveProjectsMutationVariables
>

/**
 * __useSaveProjectsMutation__
 *
 * To run a mutation, you first call `useSaveProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProjectsMutation, { data, loading, error }] = useSaveProjectsMutation({
 *   variables: {
 *      projects: // value for 'projects'
 *   },
 * });
 */
export function useSaveProjectsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveProjectsMutation,
    ISaveProjectsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveProjectsMutation,
    ISaveProjectsMutationVariables
  >(SaveProjectsDocument, options)
}
export type SaveProjectsMutationHookResult = ReturnType<
  typeof useSaveProjectsMutation
>
export type SaveProjectsMutationResult =
  Apollo.MutationResult<ISaveProjectsMutation>
export type SaveProjectsMutationOptions = Apollo.BaseMutationOptions<
  ISaveProjectsMutation,
  ISaveProjectsMutationVariables
>
export const ListenToProjectDocument = gql`
  subscription ListenToProject($id: ObjectId!) {
    project(id: $id) {
      id
      item {
        ...ProjectFields
      }
    }
  }
  ${ProjectFieldsFragmentDoc}
`

/**
 * __useListenToProjectSubscription__
 *
 * To run a query within a React component, call `useListenToProjectSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToProjectSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToProjectSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListenToProjectSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToProjectSubscription,
    IListenToProjectSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToProjectSubscription,
    IListenToProjectSubscriptionVariables
  >(ListenToProjectDocument, options)
}
export type ListenToProjectSubscriptionHookResult = ReturnType<
  typeof useListenToProjectSubscription
>
export type ListenToProjectSubscriptionResult =
  Apollo.SubscriptionResult<IListenToProjectSubscription>
