import * as React from 'react'

import { Icon, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { SitePermitStatus } from '~/client/graph'

import WorkflowCardStatus from '../../WorkflowCard/Status'

// localization: no text to translate

interface IProps {
  status: SitePermitStatus
  workflowStepLevel: number
  workflowStepId: string
  setStatusAndStep(status: SitePermitStatus, stepId: string): void

  isSelected?: boolean
  isDisabled?: boolean
}

@observer
export default class FormStatusSelectItem extends React.Component<IProps> {
  public render() {
    const { status, workflowStepLevel, isSelected, isDisabled } = this.props

    return (
      <div
        className={classList({
          'common-row row pointer': true,
          'inactive-element': isDisabled,
        })}
        onClick={this.setStatusAndStep}
      >
        <div className="row no-flex-children x-between full-height">
          <WorkflowCardStatus
            status={status}
            workflowStepLevel={workflowStepLevel}
            isLate={false}
          />
          {isSelected && <Icon icon={IconNames.TICK} intent={Intent.PRIMARY} />}
        </div>
      </div>
    )
  }

  private setStatusAndStep = () => {
    const { status, workflowStepId, isDisabled, setStatusAndStep } = this.props
    if (!isDisabled) setStatusAndStep(status, workflowStepId)
  }
}
