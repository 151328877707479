import { ObservableMap, action, computed, observable } from 'mobx'

import { IConcreteDirectPayload, ISubscription } from '~/client/graph'
import {
  GetConcreteDirectPayloadsByOrderIdDocument,
  ListenCdPayloadByOrderIdDocument,
} from '~/client/graph/operations/generated/ConcreteDirectPayloads.generated'

import Guard from '../../../utils/Guard'
import EventsStore from '../../EventStore/Events.store'
import InitialState from '../../InitialState'
import GraphExecutorStore from '../GraphExecutor.store'
import ConcreteDirectIntegrationStore from './ConcreteDirectIntegration.store'

const GET_CD_PAYLOADS = 'get-cd-payloads'
const CD_PAYLOADS_SUBSCRIPTION_ID = 'concreteDirectPayloads'

export default class ConcreteDirectPayloadsStore {
  @observable private readonly payloadsMap = new Map<
    string,
    IConcreteDirectPayload
  >()

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly graphExecutorStore: GraphExecutorStore,
    private readonly concreteDirectIntegrationStore: ConcreteDirectIntegrationStore,
  ) {
    Guard.requireAll({
      eventsStore,
      graphExecutorStore,
      concreteDirectIntegrationStore,
    })
  }

  @computed
  public get payloadsSortedByQuantity(): IConcreteDirectPayload[] {
    return Array.from(this.payloadsMap.values()).sort(
      (a, b) => a.cumulativeQuantity - b.cumulativeQuantity,
    )
  }

  public getPayloadsByOrderId = (orderId: string): IConcreteDirectPayload[] => {
    if (this.payloadsByOrderIdMap.has(orderId)) {
      return this.payloadsByOrderIdMap
        .get(orderId)
        .sort((a, b) => a.cumulativeQuantity - b.cumulativeQuantity)
    }
  }

  @action.bound
  public async loadAndListenPayloads(orderId: string) {
    if (!orderId) {
      return
    }

    this.loadingMap.set(GET_CD_PAYLOADS, true)
    this.clearData()

    const { data } = await this.graphExecutorStore.query(
      GetConcreteDirectPayloadsByOrderIdDocument,
      { cdOrderId: orderId },
    )

    if (data?.concreteDirectPayloads?.data) {
      data.concreteDirectPayloads.data.forEach(dto =>
        this.receiveOne(dto.id, dto),
      )
      this.listenToCdPayloadsUpdate(orderId)
    }

    this.loadingMap.set(GET_CD_PAYLOADS, false)
  }

  @action.bound
  public clearData() {
    this.payloadsMap.clear()
  }

  public terminateSubscription = () => {
    this.graphExecutorStore.terminateSubscription(CD_PAYLOADS_SUBSCRIPTION_ID)
  }

  private listenToCdPayloadsUpdate = (orderId: string) => {
    if (this.concreteDirectIntegrationStore.isIntegrationEnabled) {
      this.graphExecutorStore.subscribe(
        ListenCdPayloadByOrderIdDocument,
        { cdOrderId: orderId },
        true,
        CD_PAYLOADS_SUBSCRIPTION_ID,
        this.handleDataUpdating,
      )
    }
  }

  private handleDataUpdating = (res: ISubscription[]) => {
    res?.forEach(({ concreteDirectPayload: data }) =>
      this.receiveOne(data.id, data.item),
    )
  }

  @action.bound
  private receiveOne(id: string, dto: IConcreteDirectPayload) {
    if (dto) {
      this.payloadsMap.set(id, dto)
      return
    }

    this.payloadsMap.delete(id)
  }

  private get state(): InitialState {
    return this.eventsStore.appState
  }

  private get loadingMap(): ObservableMap<string, boolean> {
    return this.state.loading
  }

  @computed
  private get payloadsByOrderIdMap(): Map<string, IConcreteDirectPayload[]> {
    return Array.from(this.payloadsMap.values()).reduce(
      (map, pl) => map.set(pl.orderId, [...(map.get(pl.orderId) || []), pl]),
      new Map<string, IConcreteDirectPayload[]>(),
    )
  }
}
