import { DeliveryStatus } from '~/client/graph'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import { CancelationReasonNames } from '../enums/CancelationReason'
import { DeliveryStatusListView } from '../enums/DeliveryStatusListView'

const formatReasonToDisplay = (reason?: string, truncateCount = 10) => {
  if (!reason) {
    return ''
  }

  return reason.length <= truncateCount
    ? ` (${reason})`
    : ` (${reason.slice(0, truncateCount)}...)`
}

export function formatStatusToDisplay(status: string, reason?: string): string {
  switch (status) {
    case DeliveryStatus.Requested:
      return Localization.translator.requested
    case DeliveryStatus.Changed:
      return Localization.translator.changed
    case DeliveryStatus.Scheduled:
      return Localization.translator.scheduled
    case DeliveryStatus.OnSite:
      return Localization.translator.onSite
    case DeliveryStatusListView.Inspection:
      return Localization.translator.inspection
    case DeliveryStatus.PassedInspection:
      return Localization.translator.passed
    case DeliveryStatus.FailedInspection:
      return Localization.translator.failed
    case DeliveryStatus.IncompleteDone:
      return Localization.translator.incompletelyDone
    case DeliveryStatus.Done:
      return Localization.translator.done
    case DeliveryStatus.Denied:
      return Localization.translator.denied
    case DeliveryStatus.Deleted:
      return Localization.translator.deleted
    case DeliveryStatus.Canceled:
      return Localization.translator.canceled + formatReasonToDisplay(reason)
    case DeliveryStatus.Delivering:
      return Localization.translator.delivering
    case DeliveryStatus.Paused:
      return Localization.translator.paused
    case DeliveryStatus.OnHold:
      return Localization.translator.onHold
  }
}

export const deliveryStatusToSortIndexMap = {
  [DeliveryStatus.Requested]: 0,
  [DeliveryStatus.Changed]: 1,
  [DeliveryStatus.Scheduled]: 2,
  [DeliveryStatus.Delivering]: 3,
  [DeliveryStatus.Paused]: 4,
  [DeliveryStatus.OnHold]: 5,
  [DeliveryStatus.OnSite]: 6,
  [DeliveryStatus.PassedInspection]: 7,
  [DeliveryStatus.FailedInspection]: 8,
  [DeliveryStatus.Done]: 9,
  [DeliveryStatus.IncompleteDone]: 10,
  [DeliveryStatus.Denied]: 11,
  [DeliveryStatus.Canceled]: 12,
  [CancelationReasonNames.NoShow]: 13,
  [CancelationReasonNames.Moved]: 14,
  [CancelationReasonNames.Other]: 15,
}

export default DeliveryStatus
