import * as React from 'react'

import { inject, observer } from 'mobx-react'

import WeatherLabel from '~/client/src/shared/components/WeatherLabel/WeatherLabel'
import WeatherUnits from '~/client/src/shared/enums/WeatherUnits'
import WeatherForecastInfo from '~/client/src/shared/models/WeatherInfo'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import DesktopCalendarColumn from '../DesktopCalendarColumn'

// localization: no display text to translate

interface IProps {
  calendarColumns: DesktopCalendarColumn[]
  weatherByDaysList: WeatherForecastInfo[]
  projectWeatherUnits: WeatherUnits

  projectDateStore?: ProjectDateStore

  setRef(ref: HTMLDivElement): void
}

@inject('projectDateStore')
@observer
export default class CalendarWeatherBar extends React.Component<IProps> {
  public render() {
    const {
      setRef,
      calendarColumns,
      weatherByDaysList,
      projectWeatherUnits,
      projectDateStore,
    } = this.props

    return (
      <div className="weather-labels-container row nowrap" ref={setRef}>
        {calendarColumns.map((column, idx) => {
          const forecast = weatherByDaysList.find(w =>
            projectDateStore.isSameDay(w.date, column.date),
          )

          return (
            <WeatherLabel
              key={idx}
              className="bt-light-grey bl-light-grey bb-light-grey x-axis-column-width px8"
              forecast={forecast}
              projectWeatherUnits={projectWeatherUnits}
            />
          )
        })}
      </div>
    )
  }
}
