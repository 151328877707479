enum ChatEvent {
  Connection = 'connection',
  Disconnect = 'disconnect',
  ConnectError = 'connect_error',

  ContactJoined = 'contact_joined',
  ContactLeft = 'contact_left',
  MessageReceived = 'message_received',
  ChatCreated = 'chat_created',
  ChatUpdated = 'chat_updated',
}

export default ChatEvent
