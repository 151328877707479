import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { OnTimeStatus } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import Activity from '~/client/src/shared/models/Activity'

import StatusUpdatesStore from '../../../stores/domain/StatusUpdates.store'
import ProjectDateStore from '../../../stores/ui/ProjectDate.store'

export interface IProps {
  activity: Activity
  company?: string
  isActualStatus?: boolean
  projectDateStore?: ProjectDateStore
  containerClassName?: string
  statusUpdatesStore?: StatusUpdatesStore
}

@inject('projectDateStore', 'statusUpdatesStore')
@observer
export default class ActivityStatusIndicator extends React.Component<IProps> {
  public render() {
    const {
      containerClassName = 'x-center y-center col mw160',
      isActualStatus,
    } = this.props

    return (
      <div className={containerClassName}>
        <span className={this.className}>
          {this.status}
          {isActualStatus && this.renderStatusIcon()}
        </span>
        {this.actualStatusNotice && (
          <span className="text red">{this.actualStatusNotice}</span>
        )}
      </div>
    )
  }

  private renderStatusIcon(): JSX.Element {
    const className = 'on-time-status-icon'

    switch (this.props.activity?.onTimeStatus) {
      case OnTimeStatus.Overdue:
      case OnTimeStatus.No:
        return <Icons.ActivityLateCheckmark className={className} />
      case OnTimeStatus.LateStart:
        return <Icons.ActivityWarningCheckmark className={className} />
      case OnTimeStatus.Yes:
        return <Icons.ActivityOntimeCheckmark className={className} />
      default:
        return null
    }
  }

  private get className(): string {
    const { activity, isActualStatus } = this.props
    let className = 'relative text activity-status-cell px4 py2 pt1 brada4'
    if (!isActualStatus) {
      return className
    }
    const { didFinish, didStart } = activity
    switch (true) {
      case activity.onTimeStatus === OnTimeStatus.No:
        className += ' incomplete'
        break
      case !didStart:
        className += ' not-started'
        break
      case didFinish:
        className += ' done'
        break
      default:
        className += ' in-progress'
        break
    }
    return className
  }

  private get actualStatusNotice(): string {
    const { activity, isActualStatus } = this.props
    const { didFinish } = activity
    if (didFinish || !isActualStatus) {
      return null
    }

    switch (this.onTimeStatus) {
      case OnTimeStatus.LateStart:
      case OnTimeStatus.Overdue:
        return this.onTimeStatus
      default:
        return null
    }
  }

  private get status(): string {
    const { activity, projectDateStore, isActualStatus, statusUpdatesStore } =
      this.props

    return isActualStatus
      ? activity.actualStatus
      : activity.getPlannedStatus(projectDateStore, statusUpdatesStore)
  }

  private get onTimeStatus(): OnTimeStatus {
    const { activity, projectDateStore, company } = this.props
    const deltaDays = activity.getDeltaDays(projectDateStore, company)
    return activity.getOnTimeStatus(projectDateStore, deltaDays, true)
  }
}
