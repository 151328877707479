import { observable } from 'mobx'

export default class Pointer {
  @observable public isActive: boolean = true
  @observable public value: string = ''

  public index: number = null

  public constructor(public isLogical: boolean = false) {}

  public setIndex(index: number) {
    this.index = index
  }

  public activate() {
    this.isActive = true
  }

  public deactivate() {
    this.isActive = false
  }
}
