import { IAttachment, IMessage, IThread } from '~/client/graph'
import { ISaveMessageMutation } from '~/client/graph/operations/generated/Messages.generated'
import Message from '~/client/src/shared/models/Message'
import Thread from '~/client/src/shared/models/Thread'

import MessageDto from '../../../types/MessageDto'
import EventsStore from '../../EventStore/Events.store'
import { SAVE_MESSAGE } from '../../EventStore/eventConstants'
import BaseStoreWithById from '../../baseStores/BaseWithById.store'
import ActivitiesStore from '../Activities.store'

export default class MessagesStore extends BaseStoreWithById<
  Message,
  IMessage
> {
  public constructor(
    private eventsStore: EventsStore,
    private activitiesStore: ActivitiesStore,
  ) {
    super(Message)
  }

  public get byId() {
    return this.eventsStore.appState.messages
  }

  public post = (
    text: string,
    thread: Thread | IThread | string,
    attachments?: IAttachment[],
  ): Promise<MessageDto> => {
    if (!thread) {
      return
    }

    let threadId: string

    if (typeof thread === 'string') {
      threadId = thread
    } else {
      threadId = thread.id
    }

    if (!threadId) {
      throw new Error('Invalid thread type received')
    }

    try {
      const {
        user,
        activeProject: { id: projectId },
      } = this.eventsStore.appState
      const m = new Message()
      m.text = text || ''
      m.threadId = threadId
      m.markAsReadByUser(user)
      m.userId = user.id
      m.projectId = projectId
      m.attachments = attachments

      const { selectedActivity } = this.activitiesStore
      if (selectedActivity) {
        m.activityId = selectedActivity.code
      }

      return this.save(m)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  public getMessagesByThreadId(threadId: string): Message[] {
    if (!threadId) {
      return []
    }

    return this.list.filter(message => message.threadId === threadId)
  }

  public save = (item: Message): Promise<MessageDto> => {
    return new Promise((resolve, reject) => {
      this.eventsStore.dispatch(
        SAVE_MESSAGE,
        item,
        (data: ISaveMessageMutation) => {
          resolve({
            ...data.saveMessage,
          } as MessageDto)
        },
        reject,
      )
    })
  }
}
