import * as React from 'react'

import { Button, Classes, Dialog } from '@blueprintjs/core'
import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import './AlertDialog.scss'

interface IProps {
  isOpen: boolean
  title?: string
  onClose: () => void
  buttonTitle?: string
  className?: string
}

@observer
export default class AlertDialog extends React.Component<IProps> {
  public render() {
    const {
      isOpen,
      onClose,
      buttonTitle,
      title,
      children,
      className = '',
    } = this.props

    return (
      <Dialog
        isOpen={isOpen}
        title={title}
        // should be omitted (set undefined) when title is omitted to avoid warn
        isCloseButtonShown={title ? false : undefined}
        canOutsideClickClose={false}
        onClose={onClose}
        className={`alert-dialog ${className}`}
      >
        <div className={Classes.DIALOG_BODY}>
          <div>{children}</div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text={buttonTitle || Localization.translator.ok}
              className="alert-dialog-btn"
              onClick={onClose}
            />
          </div>
        </div>
      </Dialog>
    )
  }
}
