import * as React from 'react'

import { observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'

import ObjectPropertiesSetupStore from '../ObjectPropertiesSetup.store'

const addNew = 'Add new'

interface IProps {
  store: ObjectPropertiesSetupStore
}

@observer
export default class NewObjectCreation extends React.Component<IProps> {
  public render() {
    const { createNewItem } = this.props.store

    return (
      <div
        className="row w-fit-content px12 pb15 text blue-highlight bold large pointer"
        onClick={createNewItem}
      >
        <Icons.PlusCircle className="pr8 row no-grow" />
        {this.caption}
      </div>
    )
  }

  private get caption() {
    const { activeObjectTypeId, objectTypes } = this.props.store
    const type = objectTypes.find(o => o.id === activeObjectTypeId)

    return addNew + ' ' + type.name
  }
}
