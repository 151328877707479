import { computed, observable } from 'mobx'

import { IThread } from '~/client/graph'
import { ISaveThreadMutation } from '~/client/graph/operations/generated/ContentObjects.generated'
import Activity from '~/client/src/shared/models/Activity'
import Thread from '~/client/src/shared/models/Thread'

import EventsStore from '../../EventStore/Events.store'
import { SAVE_THREAD } from '../../EventStore/eventConstants'

export default class ThreadsStore {
  @observable public isDataReceived = false

  public constructor(private eventsStore: EventsStore) {}

  public create(activity?: Activity) {
    const thread = new Thread()
    if (activity) {
      thread.setActivity(activity)
    }
    return this.save(thread)
  }

  public receiveOne(id: string, dto: IThread) {
    if (dto) {
      const thread = Object.assign(new Thread(dto.id), dto) as Thread
      this.byId.set(thread.id, thread)
    } else {
      this.byId.delete(id)
    }
  }

  public async findOrCreateActivityThread(
    activity: Activity,
  ): Promise<IThread | Thread> {
    if (!activity) {
      return
    }

    const thread = this.list.find(t => t.activityObjectId === activity.code)
    if (!thread) {
      return await this.create(activity)
    }

    return thread
  }

  @computed
  public get list(): Thread[] {
    return Array.from(this.byId.values())
  }

  public get byId() {
    return this.eventsStore.appState.threads
  }

  public clearList() {
    this.isDataReceived = false
    this.byId.clear()
  }

  public receiveList(list: IThread[]) {
    this.isDataReceived = false
    this.byId.clear()

    list.forEach(dto => {
      this.receiveOne(dto.id, dto)
    })

    this.isDataReceived = true
  }

  private save(item: Thread): Promise<IThread> {
    return new Promise((resolve, reject) => {
      this.eventsStore.dispatch(
        SAVE_THREAD,
        {
          activityObjectId: item.activityObjectId,
          id: item.id || null,
          projectId: this.eventsStore.appState.activeProject.id,
        },
        (data: ISaveThreadMutation) => resolve(data.saveThread as IThread),
        reject,
      )
    })
  }
}
