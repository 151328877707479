import { action } from 'mobx'

import { ThresholdUnit } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import {
  ACTIVATE_PROJECT,
  SAVE_PROJECT_COLORING_OPTIONS,
  SAVE_PROJECT_SU_OPTIONS,
  SAVE_ZONEMAP_THRESHOLDS,
} from '~/client/src/shared/stores/EventStore/eventConstants'

export default class SitemapsSettingsStore {
  public constructor(private readonly eventsStore: DesktopEventStore) {}

  @action.bound
  public toggleManpowerEnabling() {
    const options = this.state.projectStatusUpdateOptions
    options.isManpowerDisabled = !options.isManpowerDisabled
    this.eventsStore.dispatch(SAVE_PROJECT_SU_OPTIONS, options)
  }

  @action.bound
  public toggleWWPColoringEnabling() {
    const options = this.state.projectColoringOptions
    options.isWWPColoringDisabled = !options.isWWPColoringDisabled
    this.eventsStore.dispatch(SAVE_PROJECT_COLORING_OPTIONS, options)
  }

  private get state() {
    return this.eventsStore.appState
  }

  public get deltaDaysValue(): number {
    const { zoneMapThresholds } = this.state
    return zoneMapThresholds.deltaDaysValue || 0
  }

  public get deltaDaysBehindValue(): number {
    const { zoneMapThresholds } = this.state
    return zoneMapThresholds.deltaDaysBehindValue || 0
  }

  public get deltaDaysUnit(): ThresholdUnit {
    const { zoneMapThresholds } = this.state
    return zoneMapThresholds.deltaDaysUnit || ThresholdUnit.Number
  }

  public get manpowerValue(): number {
    const { zoneMapThresholds } = this.state
    return zoneMapThresholds.manpowerValue || 0
  }

  public get isManpowerDisabled(): boolean {
    const { zoneMapThresholds } = this.state
    return zoneMapThresholds.isManpowerDisabled || false
  }

  public get manpowerUnit(): ThresholdUnit {
    const { zoneMapThresholds } = this.state
    return zoneMapThresholds.manpowerUnit || ThresholdUnit.Number
  }

  public get deltaDaysChartLabels() {
    if (this.deltaDaysUnit === ThresholdUnit.Number) {
      return {
        behind: Localization.translator.daysBehind,
        ahead: Localization.translator.daysAhead,
      }
    }

    return {
      behind: Localization.translator.XBehind,
      ahead: Localization.translator.XAhead,
    }
  }

  public get manpowerChartLabels() {
    if (this.manpowerUnit === ThresholdUnit.Number) {
      return {
        under: Localization.translator.under,
        over: Localization.translator.over,
      }
    }

    return {
      under: Localization.translator.XUnder,
      over: Localization.translator.XOver,
    }
  }

  @action.bound
  public toggleIsManpowerDisabled() {
    const { zoneMapThresholds } = this.state
    zoneMapThresholds.isManpowerDisabled = !zoneMapThresholds.isManpowerDisabled
    this.updateZonemapThresholds()
  }

  @action.bound
  public updateDeltaDaysThresholdValue(value: number) {
    const { zoneMapThresholds } = this.state
    zoneMapThresholds.deltaDaysValue = value
    if (value < this.deltaDaysBehindValue) {
      zoneMapThresholds.deltaDaysBehindValue = value
    }
    this.updateZonemapThresholds()
  }

  @action.bound
  public updateDeltaDaysBehindThresholdValue(value: number) {
    const { zoneMapThresholds } = this.state
    zoneMapThresholds.deltaDaysBehindValue = value
    if (value > this.deltaDaysValue) {
      zoneMapThresholds.deltaDaysValue = value
    }
    this.updateZonemapThresholds()
  }

  @action.bound
  public updateDeltaDaysThresholdUnit(unit: ThresholdUnit) {
    this.state.zoneMapThresholds.deltaDaysUnit = unit
    this.updateZonemapThresholds()
  }

  @action.bound
  public updateManpowerThresholdValue(value: number) {
    this.state.zoneMapThresholds.manpowerValue = value
    this.updateZonemapThresholds()
  }

  public get isLoading() {
    return this.state.loading.get(ACTIVATE_PROJECT)
  }

  @action.bound
  public updateManpowerThresholdUnit(unit: ThresholdUnit) {
    this.state.zoneMapThresholds.manpowerUnit = unit
    this.updateZonemapThresholds()
  }

  private updateZonemapThresholds() {
    this.eventsStore.dispatch(SAVE_ZONEMAP_THRESHOLDS)
  }
}
