import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

import Activity from '../../../models/Activity'
import SitemapAttributeIconComponent from '../../SitemapAttributeTag/SitemapAttributeIcon'
import BaseExpandableWorkflowCard, {
  IBaseWorkflowCardProps,
  workflowCardInjects,
} from '../BaseExpandableWorkflowCard'

import Colors from '~/client/src/shared/theme.module.scss'

interface IProps extends IBaseWorkflowCardProps {
  activity: Activity

  shouldUseMockValues?: boolean

  onClick?: (code: string) => void
}
const NO_COMPANY = ' - '

@inject(...workflowCardInjects)
@observer
export default class ActivityWorkflowCard extends BaseExpandableWorkflowCard<IProps> {
  public constructor(props: IProps) {
    super(props)
  }

  public componentDidUpdate(prevProps: IProps) {
    const { activity } = this.props

    if (activity !== prevProps.activity) {
      this.store.resetIsExpanded()
    }
  }

  protected get icon(): JSX.Element {
    return (
      <div className="delivery-workflow-card-icon bg-primary-blue br-rounded text white row x-center y-center no-flex">
        <Icons.ActivitiesNoColor
          style={{ fill: Colors.neutral100 }}
          className="no-grow"
        />
      </div>
    )
  }

  protected get titleValues() {
    return <>{this.nameField}</>
  }

  protected onClick = (ev: React.SyntheticEvent) => {
    ev.stopPropagation()

    const { activity, onClick } = this.props
    onClick?.(activity.code)
  }

  protected get attributeElements(): JSX.Element[] {
    const { activity, locationAttributesStore } = this.props
    return activity.locations.map((l, index) => {
      const location = locationAttributesStore.getById(l.id)
      return (
        <div className="row no-flex-children my2 text large">
          <div
            className={classList({
              h16: true,
              'no-fill': !!location.color,
            })}
            style={{ color: location.color }}
          >
            <SitemapAttributeIconComponent dataObject={location} />
          </div>
          <span style={{ color: location.color }}>{location.name}</span>
          {index !== activity.locations.length - 1 && (
            <span className="text light mx4">•</span>
          )}
        </div>
      )
    })
  }

  private get nameField(): JSX.Element {
    const { name, company } = this.props.activity

    return (
      <div className="row y-stretch x-between no-flex-children mb4 overflow-hidden">
        <span className="col row delivery-company-title text extra-large bold ellipsis">
          {name}:{company?.name || NO_COMPANY}
        </span>
      </div>
    )
  }
}
