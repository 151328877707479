import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import UnsafeActivityItemContentWithStatusUpdate, {
  IActivityItemContentProps,
} from '~/client/src/shared/components/ActivityItemContentWithStatusUpdate/ActivityItemContentWithStatusUpdate'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'
import * as Icons from '~/client/src/shared/components/Icons'
import TouchableOpacity from '~/client/src/shared/components/TouchableOpacity'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Activity from '~/client/src/shared/models/Activity'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'
import ElementSwiper from '~/client/src/shared/utils/elementSwipeHelper'

import commonRoutes from '../../constants/commonRoutes'
import CommonStore from '../../stores/ui/Common.store'
import ActivitiesTreeStore from '../ActivitiesTree/ActivitiesTree.store'

import './ActivityItem.scss'

interface IActivityItemBaseProps {
  activity: Activity
  currentViewDate: Date
  sectionCompanyName?: string
  customHandleClick?: () => void
  customHandleTopBarClick: () => void

  slackBarStore?: SlackBarStore
  activitiesStore?: ActivitiesStore
  common?: CommonStore
  activitiesTreeStore: ActivitiesTreeStore
}

const ActivityContent = withErrorBoundary(
  UnsafeActivityItemContentWithStatusUpdate,
)

@inject('slackBarStore', 'activitiesStore', 'common')
@observer
export default class ActivityItemBase extends React.Component<IActivityItemBaseProps> {
  protected ref: any
  protected elementSwiper: ElementSwiper

  public componentDidMount() {
    this.elementSwiper = new ElementSwiper(this.ref)
  }

  public componentWillUnmount() {
    this.elementSwiper.disconnectElement()
  }

  // TODO: it should not be on shared component, because there is no ACTIVITY_DETAIL route on desktop
  private goToDetails = () => {
    const { code } = this.props.activity

    this.props.activitiesStore.select(code)
    this.props.common._displayView(
      commonRoutes.ACTIVITY_DETAIL.replace(':code', code),
    )
  }

  public cameraOptionClicked = () => {
    this.props.slackBarStore.promptForImageRequested = true
    this.goToDetails()
  }

  public handleClick = () => {
    if (this.elementSwiper.isOpened) {
      return
    }

    this.goToDetails()
  }

  public setRef = ref => {
    this.ref = ref
  }

  @action.bound
  public openBulkUpdateView() {
    const { activity, sectionCompanyName, common } = this.props
    common.displayBulkStatusUpdateView(activity, sectionCompanyName)
  }

  public render() {
    const {
      activity,
      currentViewDate,
      customHandleClick,
      customHandleTopBarClick,
      sectionCompanyName,
      activitiesTreeStore,
    } = this.props

    const contentProps: IActivityItemContentProps = {
      activity,
      currentViewDate,
      sectionCompanyName,
      displayStatusUpdateModal: customHandleTopBarClick,
      displayActivityDetails: customHandleClick || this.handleClick,
      activitiesTreeStore,
    }

    return (
      <div className="activity-item bb-light-grey" ref={this.setRef}>
        <div className="activity-item-main-col full-width">
          <div className="activity-item-data-col" onClick={null}>
            <ActivityContent {...contentProps} />
          </div>
        </div>
        <div className="activity-item-options-col">
          <TouchableOpacity>
            <div
              className="activity-item-option"
              onTouchEnd={this.cameraOptionClicked}
            >
              <Icons.CameraWhite />
              <div>{Localization.translator.photo}</div>
            </div>
          </TouchableOpacity>
          <TouchableOpacity>
            <div
              className="activity-item-option"
              onTouchEnd={this.openBulkUpdateView}
            >
              <Icons.CalendarWhite />
              <div>{Localization.translator.update_verb}</div>
            </div>
          </TouchableOpacity>
        </div>
      </div>
    )
  }
}
