import { LocationType } from '~/client/graph'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'

import FieldIds from '../../enums/DeliveryFieldIds'
import LocationBase from './LocationBase'
import LocationBaseWithDimensions, {
  ILocationBaseWithDimensions,
} from './LocationBaseWithDimensions'

export default class InteriorDoor extends LocationBaseWithDimensions<InteriorDoor> {
  public static fromDto(dto: ILocationBaseWithDimensions) {
    const item = LocationBase.factory(
      InteriorDoor,
      dto,
      MapViewLocationIcon.InteriorDoor,
    )
    item.maxHeight = dto?.maxHeight
    item.maxWidth = dto?.maxWidth
    item.maxWeight = dto?.maxWeight

    return item
  }
  public type = LocationType.InteriorDoor
  protected relatedFieldId = FieldIds.INTERIOR_DOOR
  protected model = InteriorDoor
}
