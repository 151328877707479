import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { LocationType } from '~/client/graph'
import { AdditionalReportFile } from '~/client/src/desktop/components/HeaderBar/HeaderBar.store'
import BaseReportButton, {
  IReportInfo,
} from '~/client/src/desktop/components/HeaderBar/components/BaseReportButton/BaseReportButton'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import { IAppConfig } from '~/client/src/shared/Config'
import { LogisticsFilterType } from '~/client/src/shared/enums/LogisticsFilterType'
import SortOrder from '~/client/src/shared/enums/SortOrder'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { LogisticItemApp } from '~/client/src/shared/models/ILogisticItem'
import SitePermit from '~/client/src/shared/models/Permit'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import SuperFilterStore from '~/client/src/shared/stores/ui/SuperFilter.store'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import {
  FORMS_REPORT_TEMPLATE_ID,
  LOCATION_SEPARATOR,
} from '~/client/src/shared/utils/usefulStrings'

import LogisticsStore from '../../../Logistics.store'
import LogisticsListStore from '../../LogisticsList/LogisticsList.store'

const FORM_REPORT_TITLE = 'Form list report'
const DEFAULT_SORT_KEY = 'permit-name'

interface IProps {
  title: string
  isEnabled?: boolean
  logisticsStore: LogisticsStore
  logisticsListStore: LogisticsListStore
  getItemNameByLocationTypeAndId: (type: LocationType, id: string) => string
  filterStoresByTypeMap: {
    [filterType: string]: SuperFilterStore
  }
  additionalReportFiles?: AdditionalReportFile[]
  className?: string
  state?: DesktopInitialState
  projectDateStore?: ProjectDateStore
  configuration?: IAppConfig
  toggleModal?: () => void
  shouldPreventCreation?: boolean
  shouldHideIcon?: boolean
  shouldInvertColors?: boolean
  companiesStore?: CompaniesStore
  permitTypesStore?: PermitTypesStore
}

@inject(
  'state',
  'projectDateStore',
  'configuration',
  'companiesStore',
  'permitTypesStore',
)
@observer
export default class PermitReportButton extends React.Component<IProps> {
  public render() {
    const {
      className,
      title,
      toggleModal,
      shouldPreventCreation,
      shouldHideIcon,
      shouldInvertColors,
      isEnabled,
    } = this.props

    return (
      <BaseReportButton
        className={className}
        getReportInfo={this.getReportInfo}
        title={title}
        onClick={toggleModal}
        shouldPreventCreation={shouldPreventCreation}
        shouldHideIcon={shouldHideIcon}
        shouldInvertColors={shouldInvertColors}
        isEnabled={isEnabled}
      />
    )
  }

  private getReportInfo = (): IReportInfo => {
    const {
      logisticsStore: { startDate, endDate, isProjectCalendarModeActive },
      state,
      logisticsListStore: { groupingKey, sortState, filteredCollection },
      projectDateStore: { getMonthAndDayToDisplay, getClientTimezoneId },
      configuration: { TENANT_ID: tenantName },
      additionalReportFiles = [],
    } = this.props

    const {
      logisticsFilters: { fieldsMap },
      activeProject: {
        name: projectName,
        id: projectId,
        materialsUploadId,
        dateTimeFormat,
        logoUrl,
        code: projectCode,
      },
      user: { id: userId },
    } = state

    const startProjectDate = getMonthAndDayToDisplay(startDate)
    const endProjectDate = getMonthAndDayToDisplay(endDate)
    const isFullTime = isProjectCalendarModeActive
    const dateValue = isFullTime
      ? Localization.translator.fullProject
      : `${startProjectDate}-${endProjectDate}`

    const filename = `${tenantName}_${projectName}_${dateValue}`
    const filteredPermitIds = filteredCollection
      .filter(logistic => logistic.app === LogisticItemApp.FORM)
      .map(pair => pair.entityId)

    const filters = this.getPermitFilters(fieldsMap)
    const data = {
      projectId,
      materialsUploadId,
      logoUrl,
      userId,
      timezoneId: getClientTimezoneId(),
      dateTimeFormat,
      permitsFrom: startDate.getTime(),
      permitsTo: endDate.getTime(),
      filteredIds: {
        permits: filteredPermitIds,
      },
      filters,
      grouping: {
        permits: groupingKey,
      },
      sorting: {
        permits: [
          {
            ascending: sortState.order !== SortOrder.DESC,
            key: sortState.columnKey || DEFAULT_SORT_KEY,
          },
        ],
      },
      qrCodeLinks: filteredPermitIds.map(permitId =>
        SitePermit.getDirectLinkToInstance(projectCode, permitId),
      ),
    }

    return {
      projectId,
      dateFrom: startDate.getTime(),
      dateTo: endDate.getTime(),
      isFullTime,
      timezoneId: getClientTimezoneId(),
      templateId: FORMS_REPORT_TEMPLATE_ID,
      data,
      files: [
        ...additionalReportFiles
          .filter(file => file.isChecked)
          .map(file => ({
            fileId: file.value,
            fileName: `${filename}_${file.name}.${file.format.toLowerCase()}`,
          })),
      ],
      title: FORM_REPORT_TITLE,
    }
  }

  private getPermitFilters(fieldsMap: { [filterType: string]: UIFilterInfo }): {
    [filterType: string]: string[]
  } {
    const {
      getItemNameByLocationTypeAndId,
      companiesStore: { getCompanyById },
      filterStoresByTypeMap,
      permitTypesStore: { getLastUpdatedTypeByType },
    } = this.props
    const result: { [filterType: string]: string[] } = {}

    Object.values(LocationType).forEach(type => {
      result[type] = []
    })

    Object.entries(fieldsMap).forEach(([filterType, filter]) => {
      if (filterStoresByTypeMap[filterType]?.areAllOptionsSelected) {
        return
      }
      switch (filterType) {
        case LogisticsFilterType.Company:
          result[filterType] = Array.from(
            filter.selectedFilterOptions.keys(),
          ).map(id => getCompanyById(id)?.name)
          break
        case LogisticsFilterType.Status:
          result[filterType] = Array.from(filter.selectedFilterOptions.keys())
          break
        case LogisticsFilterType.Type:
          result[filterType] = Array.from(
            filter.selectedFilterOptions.keys(),
          ).map(type => getLastUpdatedTypeByType(type)?.name || type)
          break
        case LogisticsFilterType.Location:
        case LogisticsFilterType.Equipment:
          const keys: string[] = Array.from(
            filter.selectedFilterOptions.keys(),
          ).filter(key => key !== UNASSIGNED)
          keys.forEach(key => {
            const [type, id] = key.split(LOCATION_SEPARATOR)
            const itemName = getItemNameByLocationTypeAndId(
              type as LocationType,
              id,
            )
            result[type].push(itemName)
          })
          break
      }
    })

    return result
  }
}
