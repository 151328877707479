import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IAttachment } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import FileType from '~/client/src/shared/enums/FileType'
import IFilePreviewProperties from '~/client/src/shared/interfaces/IFilePreviewProperties'

import EventsStore from '../../stores/EventStore/Events.store'
import { SHOW_FULLSCREEN_PREVIEW } from '../../stores/EventStore/eventConstants'
import {
  isUrlToMSExcelOnStorage,
  isUrlToMSFile,
  isUrlToMSPPTXOnStorage,
  isUrlToMSWordOnStorage,
  isUrlToNotImageOnStorage,
  isUrlToPdfOnStorage,
} from '../../utils/isUrlToPdfOnStorage'
import { downloadFile } from '../../utils/util'
import PdfDocument from '../PdfDocument/PdfDocument'

import './FileAttachment.scss'

interface IProps {
  attachment: IAttachment
  className?: string
  removeAttachment?: (attachment: IAttachment) => void
  customOpenFileHandler?: (
    files: IFilePreviewProperties[],
    currentIndex: number,
  ) => void
  shouldShowPdfPreview?: boolean
  isPdfPreviewSmall?: boolean
  eventsStore?: EventsStore
}

const PAGE_HEIGHT = 150

@inject('eventsStore')
@observer
export default class FileAttachment extends React.Component<IProps> {
  public render() {
    const {
      attachment,
      className,
      removeAttachment,
      shouldShowPdfPreview,
      isPdfPreviewSmall,
    } = this.props

    const isFile = isUrlToNotImageOnStorage(attachment.url)
    const isPDF = isUrlToPdfOnStorage(attachment.url)

    return (
      <div
        className={classList({
          'file-attachment pointer brada4 px12 py4 col y-center ma12 bg-white':
            true,
          [className]: !!className,
          'pdf-preview': isFile && shouldShowPdfPreview && !isPdfPreviewSmall,
          'small-pdf-preview':
            isFile && shouldShowPdfPreview && isPdfPreviewSmall,
        })}
        onClick={this.openFile}
      >
        <div className="row">
          {this.icon}
          <div className="col">
            <div className="file-name text text-ellipsis large bold row">
              {attachment.fileName}
            </div>
            <div className="text light row">
              {attachment.size} {this.text}
            </div>
          </div>
          {removeAttachment && (
            <Icon
              onClick={this.removeAttachment}
              className="brada4 no-grow ml12 pa5"
              icon={IconNames.CROSS}
            />
          )}
        </div>
        {!isFile && <img className="preview-img" src={attachment.url} />}
        {shouldShowPdfPreview && isPDF && (
          <PdfDocument
            className="pdf-document row"
            file={attachment.url}
            pageClassName="pdf-page-view"
            pageHeight={PAGE_HEIGHT}
          />
        )}
      </div>
    )
  }

  private get icon(): JSX.Element {
    const { url } = this.props.attachment
    switch (true) {
      case isUrlToMSWordOnStorage(url):
        return <Icons.WordApp className="col no-grow pr12" />
      case isUrlToMSExcelOnStorage(url):
        return <Icons.ExcelApp className="col no-grow pr12" />
      case isUrlToMSPPTXOnStorage(url):
        return <Icons.PPTXApp className="col no-grow pr12" />
      case isUrlToPdfOnStorage(url):
        return <Icons.PDFApp className="col no-grow pr12" />

      default:
        return (
          <Icon
            className="col no-grow pr12"
            icon={IconNames.MEDIA}
            iconSize={24}
          />
        )
    }
  }

  private get text(): string {
    const { url } = this.props.attachment
    switch (true) {
      case isUrlToMSWordOnStorage(url):
        return 'MS Word'
      case isUrlToMSExcelOnStorage(url):
        return 'MS Excel'
      case isUrlToMSPPTXOnStorage(url):
        return 'MS PowerPoint'
      case isUrlToPdfOnStorage(url):
        return 'PDF'
      default:
        return null
    }
  }

  @action.bound
  private removeAttachment = (event: React.MouseEvent) => {
    event.stopPropagation()
    const { attachment, removeAttachment } = this.props
    removeAttachment(attachment)
  }

  private openFile = (event: React.MouseEvent) => {
    event.stopPropagation()

    const {
      customOpenFileHandler,
      attachment: { url, fileName },
    } = this.props

    if (isUrlToPdfOnStorage(url) || isUrlToMSFile(url)) {
      this.handleForceDownload()
      return
    }
    const type = isUrlToPdfOnStorage(url) ? FileType.Pdf : FileType.Image

    if (customOpenFileHandler) {
      return customOpenFileHandler(
        [{ fileUrl: url, fileType: type, fileName }],
        0,
      )
    }

    this.openFilePreview(url, type, fileName)
  }

  private handleForceDownload = () => {
    const {
      attachment: { url, fileName },
    } = this.props

    downloadFile(fileName, url)
  }

  @action.bound
  private openFilePreview(url: string, type: FileType, name?: string) {
    const attachment = {
      fileUrl: url,
      fileType: type,
      fileName: name,
    }

    this.props.eventsStore.dispatch(SHOW_FULLSCREEN_PREVIEW, [attachment], 0)
  }
}
