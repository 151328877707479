import * as React from 'react'

import {
  Icon,
  Popover,
  PopoverPosition,
  PopperModifiers,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'

import PRE_POPULATED_COLORS from '../../utils/deliveryPrepopulatedColors'
import ColorPicker from './ColorPicker'

import './ColorPickerWrapper.scss'

interface IProps {
  value: string
  isAlwaysOpened?: boolean
  onChange(color: string): void
  colors?: string[]
  className?: string
  customColorPickerButton?: JSX.Element
  canAddNewColors?: boolean
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: {
    enabled: true,
    boundariesElement: 'scrollParent',
  },
  hide: { enabled: false },
  computeStyle: { gpuAcceleration: false },
  arrow: { enabled: false },
}

@observer
export default class ColorPickerWrapper extends React.Component<IProps> {
  public static defaultProps = {
    colors: PRE_POPULATED_COLORS,
    className: '',
  }

  @observable private arePickerExpanded: boolean = false

  public render() {
    const { value, className, customColorPickerButton } = this.props
    return (
      <div className={className}>
        <div className="color-picker">
          <Popover
            className="inline-block"
            popoverClassName="color-picker-wrapper"
            position={PopoverPosition.LEFT}
            minimal={true}
            usePortal={false}
            isOpen={this.arePickerExpanded}
            canEscapeKeyClose={false}
            modifiers={popoverPopperModifiers}
            content={this.renderColorPickerSelector()}
          >
            <div onClick={this.toggleColorPicker}>
              {customColorPickerButton || (
                <div className={`color-picker-button`}>
                  <div
                    className="color-option"
                    style={{ backgroundColor: value }}
                  />
                  <Icon
                    className="chevron-right mr10"
                    icon={IconNames.CHEVRON_RIGHT}
                  />
                </div>
              )}
            </div>
          </Popover>
        </div>
      </div>
    )
  }

  private renderColorPickerSelector = () => {
    const { value, colors, isAlwaysOpened, canAddNewColors } = this.props
    const isExpanded = isAlwaysOpened || this.arePickerExpanded

    return (
      isExpanded && (
        <ColorPicker
          value={value}
          colors={colors}
          arePickerExpanded={this.arePickerExpanded}
          canAddNewColors={canAddNewColors}
          onChange={this.onColorClicked}
        />
      )
    )
  }

  @action.bound
  private onColorClicked(color: string) {
    this.props.onChange(color)

    this.collapseColorPicker()
  }

  @action.bound
  private toggleColorPicker() {
    this.arePickerExpanded = !this.arePickerExpanded
  }

  @action.bound
  private collapseColorPicker() {
    this.arePickerExpanded = false
  }
}
