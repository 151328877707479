import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IGetCurrentMaterialsUploadProgressQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetCurrentMaterialsUploadProgressQuery = {
  getCurrentMaterialUploadProgress?: Types.Maybe<
    Pick<
      Types.IUploadMaterialsProgress,
      'status' | 'failedStep' | 'failReason' | 'itemsCount' | 'savedItemsCount'
    >
  >
}

export type IListenToMaterialsUploadProgressSubscriptionVariables =
  Types.Exact<{
    projectId: Types.Scalars['ObjectId']
  }>

export type IListenToMaterialsUploadProgressSubscription = {
  uploadMaterialsProgress?: Types.Maybe<
    Pick<Types.IUploadMaterialsProgressChangeEvent, 'id'> & {
      item?: Types.Maybe<
        Pick<
          Types.IUploadMaterialsProgress,
          | 'status'
          | 'failedStep'
          | 'failReason'
          | 'itemsCount'
          | 'savedItemsCount'
        >
      >
    }
  >
}

export const GetCurrentMaterialsUploadProgressDocument = gql`
  query GetCurrentMaterialsUploadProgress($projectId: ObjectId!) {
    getCurrentMaterialUploadProgress(projectId: $projectId) {
      status
      failedStep
      failReason
      itemsCount
      savedItemsCount
    }
  }
`

/**
 * __useGetCurrentMaterialsUploadProgressQuery__
 *
 * To run a query within a React component, call `useGetCurrentMaterialsUploadProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentMaterialsUploadProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentMaterialsUploadProgressQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetCurrentMaterialsUploadProgressQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetCurrentMaterialsUploadProgressQuery,
    IGetCurrentMaterialsUploadProgressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetCurrentMaterialsUploadProgressQuery,
    IGetCurrentMaterialsUploadProgressQueryVariables
  >(GetCurrentMaterialsUploadProgressDocument, options)
}
export function useGetCurrentMaterialsUploadProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetCurrentMaterialsUploadProgressQuery,
    IGetCurrentMaterialsUploadProgressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetCurrentMaterialsUploadProgressQuery,
    IGetCurrentMaterialsUploadProgressQueryVariables
  >(GetCurrentMaterialsUploadProgressDocument, options)
}
export type GetCurrentMaterialsUploadProgressQueryHookResult = ReturnType<
  typeof useGetCurrentMaterialsUploadProgressQuery
>
export type GetCurrentMaterialsUploadProgressLazyQueryHookResult = ReturnType<
  typeof useGetCurrentMaterialsUploadProgressLazyQuery
>
export type GetCurrentMaterialsUploadProgressQueryResult = Apollo.QueryResult<
  IGetCurrentMaterialsUploadProgressQuery,
  IGetCurrentMaterialsUploadProgressQueryVariables
>
export const ListenToMaterialsUploadProgressDocument = gql`
  subscription ListenToMaterialsUploadProgress($projectId: ObjectId!) {
    uploadMaterialsProgress(projectId: $projectId) {
      id
      item {
        status
        failedStep
        failReason
        itemsCount
        savedItemsCount
      }
    }
  }
`

/**
 * __useListenToMaterialsUploadProgressSubscription__
 *
 * To run a query within a React component, call `useListenToMaterialsUploadProgressSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToMaterialsUploadProgressSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToMaterialsUploadProgressSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToMaterialsUploadProgressSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToMaterialsUploadProgressSubscription,
    IListenToMaterialsUploadProgressSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToMaterialsUploadProgressSubscription,
    IListenToMaterialsUploadProgressSubscriptionVariables
  >(ListenToMaterialsUploadProgressDocument, options)
}
export type ListenToMaterialsUploadProgressSubscriptionHookResult = ReturnType<
  typeof useListenToMaterialsUploadProgressSubscription
>
export type ListenToMaterialsUploadProgressSubscriptionResult =
  Apollo.SubscriptionResult<IListenToMaterialsUploadProgressSubscription>
