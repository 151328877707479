import * as React from 'react'

import { Button } from '@blueprintjs/core'
import { observer } from 'mobx-react'

interface ISubmitButton {
  text: string
  width: number
  loading?: boolean
  clickHandler: (e) => void
  disabled?: boolean
  isHidden?: boolean
  className?: string
}

@observer
export default class SubmitButton extends React.Component<ISubmitButton> {
  public render() {
    const {
      disabled,
      loading,
      width,
      text,
      clickHandler,
      isHidden,
      className = 'setup-form-controls submit-button-container',
    } = this.props

    return (
      <div className={className}>
        <Button
          className="primary-blue-btn"
          style={{ width, visibility: isHidden ? 'hidden' : 'visible' }}
          text={text}
          onClick={clickHandler}
          disabled={disabled}
          loading={loading}
        />
      </div>
    )
  }
}
