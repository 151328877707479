import * as React from 'react'

import { inject, observer } from 'mobx-react'

import {
  CalendricalType,
  IPermitTypeField,
  PermitFieldType,
} from '~/client/graph'
import PermitFieldIcon from '~/client/src/shared/components/PermitFieldIcon/PermitFieldIcon'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { calendricalTypes } from '~/client/src/shared/utils/CalendricalTypeUtils'
import { truncateText } from '~/client/src/shared/utils/textUtils'
import { EMPTY_STRING, NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import Checkbox from '../../../Checkbox'
import StruxhubInput from '../../../StruxhubInputs/StruxhubInput'
import StruxhubSelect from '../../../StruxhubInputs/StruxhubSelect'
import StruxhubTextValueSelector from '../../../StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'
import WeekdaysSelector from '../../../WeekdaysSelector/WeekdaysSelector'
import SitePermitCreationFormStore from '../../SitePermitCreationForm.store'
import PermitBaseFormField from './PermitBaseFormField'

interface IProps {
  typeField: IPermitTypeField
  isViewMode: boolean

  store: SitePermitCreationFormStore

  projectDateStore?: ProjectDateStore
}

const MIN_FREQUENCY = 1
const MAX_INSPECTIONS_LABEL_LENGTH = 36

const nonWorkDayOrDays = 'Non-work day/s'
const nonWorkHours = 'Non-work hours'
const inspectionFrequencyFieldCaption = 'Inspection frequency'
const useLogFrequencyCaption = 'Use log frequency'
const availableOnlyOnWorkingDays = '(Available only on working days)'
const nextInspections = (nextInspectionsString: string): string =>
  `Next: ${truncateText(nextInspectionsString, MAX_INSPECTIONS_LABEL_LENGTH)}`
const formWasRequestedForNonWorkTime =
  'This form was requested for a non-work time'

@inject('projectDateStore')
@observer
export default class PermitFormStartEndDatesField extends React.Component<IProps> {
  public render() {
    const { isViewMode, store, typeField } = this.props
    const { caption, isMandatory } = typeField
    const { datesField } = store.workflowFieldsStore

    return (
      <>
        <PermitBaseFormField
          typeField={typeField}
          isViewMode={isViewMode}
          viewModeElements={[this.viewModeElement]}
        >
          <StruxhubTextValueSelector
            label={caption}
            isRequired={isMandatory}
            isChanged={datesField.isChanged}
            isValid={datesField.isValid}
            value={this.formattedDatesValue}
            onClick={this.openDatePicker}
            validationMessage={this.datesNonWorkLabel}
          />
          {this.renderAllDayCheckbox()}
          {this.nonWorkTimeLabel}
        </PermitBaseFormField>
        {this.inspectionFrequency}
      </>
    )
  }

  private renderAllDayCheckbox(): JSX.Element {
    const { store } = this.props
    return (
      <div className="row y-center pl4 pointer" onClick={store.toggleAllDay}>
        <Checkbox isChecked={store.editablePermit.isAllDay} />
        <span className="text large">{Localization.translator.allDay}</span>
      </div>
    )
  }

  private get viewModeElement(): JSX.Element {
    return (
      <div key={0} className="col">
        <div className="text large word-break-all">
          {this.formattedDatesValue}
        </div>
        {this.nonWorkTimeLabel}
      </div>
    )
  }

  private get formattedDatesValue(): string {
    const { store, projectDateStore } = this.props
    const {
      editablePermit: { startDate, endDate },
    } = store
    const {
      getYearToDisplay,
      isSameYear,
      getMonthDayYearAndTimeToDisplay,
      isThisYear,
    } = projectDateStore

    if (!isSameYear(startDate, endDate)) {
      return `${getMonthDayYearAndTimeToDisplay(
        startDate,
      )} - ${getMonthDayYearAndTimeToDisplay(endDate)}`
    }

    if (!isThisYear(startDate)) {
      return `${getYearToDisplay(startDate)} ${this.monthDayAndTimeDatesRange}`
    }

    return this.monthDayAndTimeDatesRange
  }

  private get monthDayAndTimeDatesRange(): string {
    const { store, projectDateStore } = this.props
    const {
      editablePermit: { startDate, endDate },
    } = store
    const {
      getWeekdayMonthAndDayToDisplay,
      getMonthDayAndTimeToDisplay,
      getTimeToDisplay,
      isSameDay,
    } = projectDateStore

    if (isSameDay(startDate, endDate)) {
      return `${getWeekdayMonthAndDayToDisplay(startDate)}, ${getTimeToDisplay(
        startDate,
      )} - ${getTimeToDisplay(endDate)}`
    }

    return `${getMonthDayAndTimeToDisplay(
      startDate,
    )} - ${getMonthDayAndTimeToDisplay(endDate)}`
  }

  private get nonWorkTimeLabel(): JSX.Element {
    if (!this.props.store.shouldShowNonWorkLabel) {
      return null
    }

    return (
      <div className="row x-center pa12 text extra-large red">
        {formWasRequestedForNonWorkTime}
      </div>
    )
  }

  private get inspectionFrequency(): JSX.Element {
    const {
      isViewMode,
      store,
      projectDateStore: {
        getSlashedMonthDayAndYear,
        getWeekdayNumber,
        hasWorkingDays,
      },
    } = this.props
    const {
      isInspectionPermit,
      inspectionFrequency,
      inspectionFrequencyType,
      shouldShowDaysToRepeat,
      selectedDaysToRepeat,
      inspectionMaxNumber,
      nextInspectionDates,
      changeDayToRepeat,
      editablePermit: { startDate },
      isEmissionFormType,
      isAdminOrFormsMaster,
    } = store

    if (!isInspectionPermit) {
      return null
    }

    const frequencyCaption = isEmissionFormType
      ? useLogFrequencyCaption
      : inspectionFrequencyFieldCaption
    const workingDaysLabel = hasWorkingDays
      ? availableOnlyOnWorkingDays
      : EMPTY_STRING

    const inspectionDatesString = nextInspectionDates
      ?.map(dt => getSlashedMonthDayAndYear(dt))
      .join(', ')

    if (isViewMode || !isAdminOrFormsMaster) {
      const element = (
        <div className="col">
          <div className="text large capitalize w-max-content">
            {this.inspectionLabel}
          </div>
          {inspectionDatesString && (
            <div className="text light large ellipsis pt10">
              {nextInspections(inspectionDatesString)}
            </div>
          )}
        </div>
      )

      return (
        <div className="row no-grow y-start py12">
          <PermitFieldIcon fieldName={PermitFieldType.AdditionalDate} />
          <div className="col pr6 overflow-hidden">
            <div className="text light large pb4 w-max-content">
              {frequencyCaption}
            </div>
            <div className="text light large pb4 w-max-content">
              {workingDaysLabel}
            </div>
            <div>{element || NO_VALUE}</div>
          </div>
        </div>
      )
    }

    const daysToRepeat = selectedDaysToRepeat?.length
      ? selectedDaysToRepeat
      : [+getWeekdayNumber(startDate)]

    return (
      <div className="row y-center no-grow">
        <div className="col">
          <div className="text light large my10">
            {frequencyCaption} {workingDaysLabel}
          </div>
          <div className="mb4 row x-between y-center no-outline-container">
            <span className="mr5 text light large capitalize">
              {Localization.translator.every_plural}
            </span>
            <div className="row">
              <StruxhubInput
                type="number"
                max={inspectionMaxNumber}
                min={MIN_FREQUENCY}
                value={inspectionFrequency?.toString()}
                onChange={this.changeInspectionFrequency}
                isMinimalisticMode={true}
              />
              <div className="mr8 no-grow" />
              <StruxhubSelect
                isMinimalisticMode={true}
                value={inspectionFrequencyType}
                onChange={this.changeInspectionFrequencyType}
              >
                {calendricalTypes.map(value => (
                  <option key={value} value={value}>
                    {this.getFrequencyTypeLabel(value)}
                  </option>
                ))}
              </StruxhubSelect>
            </div>
          </div>
          {shouldShowDaysToRepeat && (
            <div className="row x-between">
              <div className="text light large">
                {Localization.translator.repeatOn}
              </div>
              <WeekdaysSelector
                className="py10"
                selectedDayNumbers={daysToRepeat}
                onClick={changeDayToRepeat}
              />
            </div>
          )}
          {inspectionDatesString && (
            <div className="text light large ellipsis pt10">
              {nextInspections(inspectionDatesString)}
            </div>
          )}
        </div>
      </div>
    )
  }

  private getFrequencyTypeLabel = (frequencyType: CalendricalType): string => {
    const { inspectionFrequency } = this.props.store

    switch (frequencyType) {
      case CalendricalType.Day:
        return Localization.translator.xDays(inspectionFrequency)
      case CalendricalType.Week:
        return Localization.translator.xWeeks(inspectionFrequency)
      case CalendricalType.Month:
        return Localization.translator.xMonths(inspectionFrequency)
    }
  }

  private get inspectionLabel(): string {
    const { inspectionFrequency, inspectionFrequencyType } = this.props.store

    switch (inspectionFrequencyType) {
      case CalendricalType.Day:
        return Localization.translator.everyXDays(inspectionFrequency)
      case CalendricalType.Week:
        return Localization.translator.everyXWeeks(inspectionFrequency)
      case CalendricalType.Month:
        return Localization.translator.everyXMonths(inspectionFrequency)
    }
  }

  private get datesNonWorkLabel(): string {
    const { isViewMode, store, projectDateStore } = this.props
    const { startDate, endDate } = store.editablePermit
    const { isWorkingDay, areTimesInsideWorkingHours } = projectDateStore

    if (isViewMode || !startDate || !endDate) {
      return null
    }

    if (!isWorkingDay(startDate) || !isWorkingDay(endDate)) {
      return nonWorkDayOrDays
    }

    return !areTimesInsideWorkingHours(startDate, endDate) && nonWorkHours
  }

  private changeInspectionFrequency = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.props.store.applySpecificInspectionFrequency(
      event.target.valueAsNumber || 1,
    )
  }

  private changeInspectionFrequencyType = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    this.props.store.applySpecificInspectionFrequencyType(
      event.target.value as CalendricalType,
    )
  }

  private openDatePicker = () => {
    const { store, typeField } = this.props
    store.setSelectedField(typeField)
  }
}
