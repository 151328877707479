import * as React from 'react'

import { observer } from 'mobx-react'

import NotificationsSettingsControls from '~/client/src/shared/components/NotificationsSettingsControls/NotificationsSettingsControls'
import Localization from '~/client/src/shared/localization/LocalizationManager'

// localization: translated

@observer
export default class NotificationsSettings extends React.Component {
  public render() {
    return (
      <div style={{ width: '600px' }}>
        <div className="setup-form-header bb-light-grey mb15 text bold uppercase">
          {Localization.translator.notificationSettings}
        </div>
        <div className="pb20">
          {Localization.translator.userProfileDescriptions.notifications}
        </div>
        <NotificationsSettingsControls />
      </div>
    )
  }
}
