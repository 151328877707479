import * as React from 'react'

import { action, computed } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import { FilterSourceType } from '~/client/src/shared/stores/substates/ActivityFilterInfo'

import ActivityFiltersViewStore from '../ActivityFiltersView.store'
import FilterSourceTypeSelector from './FilterSourceTypeSelector'

export interface IProps {
  filterName: string
  store: ActivityFiltersViewStore
  content?
}

@observer
export default class FilterTabContent extends React.Component<IProps> {
  @computed
  private get filter() {
    const { filterName, store } = this.props
    return store.getFilter(filterName)
  }

  @computed
  private get sourceTitles() {
    const { sourceType } = this.filter
    switch (sourceType) {
      case FilterSourceType.ActivityCode:
        return {
          description:
            Localization.translator.activityFiltersDescriptions
              .selectActivityCodes,
          selected: Localization.translator.activityCodesSelected,
          selectButtonCaption: Localization.translator.addScheduleData,
          editButtonCaption: Localization.translator.editScheduleData,
          listHeader: Localization.translator.scheduleData,
        }

      case FilterSourceType.ProjectMember:
        return {
          description:
            Localization.translator.activityFiltersDescriptions
              .selectProjectMembers,
          selected: Localization.translator.projectMembersSelected,
          selectButtonCaption: Localization.translator.addScheduleData,
          editButtonCaption: Localization.translator.editScheduleData,
          listHeader: Localization.translator.scheduleData,
        }

      default:
        throw new Error(sourceType + ' is not added to translator')
    }
  }

  public renderTabContent() {
    const { filterName, store } = this.props
    const { isFilterSetupCompleted } = store

    const isSetupCompleted = isFilterSetupCompleted(filterName)

    return (
      <>
        <FilterSourceTypeSelector filterName={filterName} store={store} />
        <div className="text large light mt20">
          {this.sourceTitles.description}
        </div>
        <div className="pt24 mappable-filter-tab-content">
          <div className="row y-center bb-brand-dark">
            <div className="py8 px12 text bold brand-dark uppercase">
              {this.sourceTitles.listHeader}
            </div>
          </div>
          {isSetupCompleted && this.renderDataList()}
          <div
            onClick={this.showFilterConfiguration}
            className="text large blue-highlight pa12 pointer"
          >
            {isSetupCompleted
              ? this.sourceTitles.editButtonCaption
              : this.sourceTitles.selectButtonCaption}
          </div>
        </div>
      </>
    )
  }

  public renderDataList() {
    const { filterName, store } = this.props
    const { getConfiguredFilterDialogItemNames } = store

    const names = getConfiguredFilterDialogItemNames(filterName)
    return names.map(name => (
      <div key={name} className="row bb-light-grey y-start">
        <div className="pa12 text extra-large brand-dark">{name}</div>
      </div>
    ))
  }

  public render() {
    const { store, filterName, content } = this.props
    return (
      <div
        className={classList({ 'inactive-element': this.filter.isDisabled })}
      >
        <div className="text large light mt15">
          {store.getFilterDescription(filterName)}
        </div>
        {content || this.renderTabContent()}
      </div>
    )
  }

  @action.bound
  private showFilterConfiguration() {
    const { store, filterName } = this.props
    store.showFilterConfiguration(filterName)
  }
}
