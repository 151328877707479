import * as React from 'react'

import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

interface IProps {
  hasRfi?: boolean
  hasScheduleComment?: boolean
  hasCategoryOfVariance?: boolean
  hasSafetyHazard?: boolean
  hasFlag?: boolean
  hasDelivery?: boolean
  hasCriticalPath?: boolean
  totalAmount?: number
  actualAmount?: number
  manpower?: number
  onlyStatusUpdate?: boolean
  onlyIcons?: boolean
  className?: string
  updatedActivitiesAmountWithManpower?: number
}

export default class ThreadIndicators extends React.Component<IProps> {
  public static defaultProps = {
    totalAmount: 0,
    actualAmount: 0,
    className: 'pl5 no-flex',
  }

  public render() {
    return <div className={this.props.className}>{this.content}</div>
  }

  private get content(): JSX.Element {
    const {
      hasRfi,
      hasFlag,
      hasDelivery,
      hasCriticalPath,
      totalAmount,
      actualAmount,
      onlyStatusUpdate,
      onlyIcons,
      manpower,
      hasScheduleComment,
      hasCategoryOfVariance,
      hasSafetyHazard,
      updatedActivitiesAmountWithManpower,
    } = this.props

    const didUpdate = actualAmount && totalAmount === actualAmount
    const didUpdateManpower =
      updatedActivitiesAmountWithManpower &&
      totalAmount === updatedActivitiesAmountWithManpower
    const statusUpdateIconClassName = 'row pl5 no-grow'
    const statusUpdateIcon = didUpdate ? (
      <Icons.StatusUpdateStateBlue className={statusUpdateIconClassName} />
    ) : (
      <Icons.StatusUpdateStateGray className={statusUpdateIconClassName} />
    )

    const manpowerIcon = didUpdateManpower ? (
      <Icons.ResourcesBlue className="col" />
    ) : (
      <Icons.ResourcesGray className="col" />
    )
    const shouldShowStatusUpdateInfo = totalAmount > 0
    const statusUpdate = (
      <div className="row x-end pl5 pr20">
        {!onlyIcons && (
          <>
            {typeof manpower === 'number' && (
              <div className="no-grow row x-center">
                <div className="text extra-large inherit-color pr5">
                  {manpower}
                </div>
                {manpowerIcon}
              </div>
            )}
            <span className="text large inherit-color right no-grow mw35">
              {actualAmount}/{totalAmount}
            </span>
          </>
        )}
        {statusUpdateIcon}
      </div>
    )

    if (onlyStatusUpdate && shouldShowStatusUpdateInfo) {
      return statusUpdate
    }

    return (
      <div className="row x-end">
        <Icons.Critical
          className={classList({
            'row px5-right no-grow': true,
            hidden: !hasCriticalPath,
          })}
        />
        <Icons.Rfi
          className={classList({
            'row px5-right no-grow': true,
            hidden: !hasRfi,
          })}
        />
        <Icons.Flag
          className={classList({
            'row icon-red px5-right no-grow': true,
            hidden: !hasFlag,
          })}
        />
        <Icons.DateChangeBlue
          className={classList({
            'row px5-right no-grow': true,
            hidden: !hasScheduleComment,
          })}
        />
        <Icons.Variance
          className={classList({
            'row px5-right no-grow': true,
            hidden: !hasCategoryOfVariance,
          })}
        />
        <Icons.Safety
          className={classList({
            'row px5-right no-grow': true,
            hidden: !hasSafetyHazard,
          })}
        />
        <Icons.Delivery
          className={classList({
            'row px5-right no-grow delivery-icon': true,
            hidden: !hasDelivery,
          })}
        />
        {shouldShowStatusUpdateInfo && statusUpdate}
      </div>
    )
  }
}
