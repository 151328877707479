import * as turf from '@turf/turf'
import { computed, observable } from 'mobx'
import { LngLat } from 'react-map-gl'

import {
  IGeoJson2DGeographicCoordinates,
  ISitemapItemShapeCoordinates,
  SitemapItemShapeType,
} from '~/client/graph'

export default class PolyLineShapeCoordinates
  implements ISitemapItemShapeCoordinates
{
  public type: SitemapItemShapeType = SitemapItemShapeType.Polyline
  @observable public coordinates: IGeoJson2DGeographicCoordinates[]
  @observable public isClosed: boolean

  public constructor(
    coordinates: IGeoJson2DGeographicCoordinates[],
    isClosed: boolean,
  ) {
    this.coordinates = coordinates
    this.isClosed = isClosed
  }

  public getBoundingBoxFeature(
    properties: GeoJSON.GeoJsonProperties,
  ): GeoJSON.Feature {
    return {
      type: 'Feature',
      properties,
      geometry: {
        type: 'Polygon',
        coordinates: [this.boundingBoxFeatureCoords],
      },
    }
  }

  public getBoundingBoxPointsFeatures(
    properties: GeoJSON.GeoJsonProperties,
  ): GeoJSON.Feature[] {
    return this.boundingBoxFeatureCoords.map((c, idx) => ({
      type: 'Feature',
      properties: {
        idx,
        ...properties,
      },
      geometry: {
        type: 'Point',
        coordinates: c,
      },
    }))
  }

  @computed
  private get boundingBoxFeatureCoords(): number[][] {
    const { minLng, minLat, maxLng, maxLat } = this.minMaxValues
    return [
      [minLng, maxLat],
      [maxLng, maxLat],
      [maxLng, minLat],
      [minLng, minLat],
      [minLng, maxLat],
    ]
  }

  @computed
  private get minMaxValues() {
    let maxLng = this.shapeFeatureCoordinates[0][0]
    let maxLat = this.shapeFeatureCoordinates[0][1]
    let minLng = this.shapeFeatureCoordinates[0][0]
    let minLat = this.shapeFeatureCoordinates[0][1]
    this.shapeFeatureCoordinates.forEach(c => {
      if (c[0] > maxLng) {
        maxLng = c[0]
      }
      if (c[1] > maxLat) {
        maxLat = c[1]
      }
      if (c[0] < minLng) {
        minLng = c[0]
      }
      if (c[1] < minLat) {
        minLat = c[1]
      }
    })
    return { minLng, minLat, maxLng, maxLat }
  }

  @computed
  public get centerPoint() {
    const { minLng, minLat, maxLng, maxLat } = this.minMaxValues
    return [minLng + (maxLng - minLng) / 2, minLat + (maxLat - minLat) / 2]
  }

  public getShapePolygonFeature(
    properties: GeoJSON.GeoJsonProperties,
  ): GeoJSON.Feature<GeoJSON.Polygon> {
    return {
      type: 'Feature',
      properties,
      geometry: {
        type: 'Polygon',
        coordinates: [this.shapeFeatureCoordinates],
      },
    }
  }

  public getShapeLineStringFeature(
    properties: GeoJSON.GeoJsonProperties,
  ): GeoJSON.Feature<GeoJSON.LineString> {
    return {
      type: 'Feature',
      properties,
      geometry: {
        type: 'LineString',
        coordinates: this.shapeFeatureCoordinates,
      },
    }
  }

  public moveShapePoint(idx: number, moveCoords: LngLat) {
    this.coordinates[idx] = {
      latitude: moveCoords.lat,
      longitude: moveCoords.lng,
    }
  }

  public rotate(startPoint: LngLat, endPoint: LngLat) {
    const normPoint = [this.centerPoint[0], this.centerPoint[1] - 0.01]
    const startAngle = turf.angle(
      [startPoint.lng, startPoint.lat],
      this.centerPoint,
      normPoint,
    )
    const endAngle = turf.angle(
      [endPoint.lng, endPoint.lat],
      this.centerPoint,
      normPoint,
    )
    const newShape = turf.transformRotate(
      this.getShapePolygonFeature({}),
      endAngle - startAngle,
      { pivot: this.centerPoint, mutate: true },
    )
    this.coordinates = (newShape.geometry.coordinates as number[][][])[0].map(
      c => ({ latitude: c[1], longitude: c[0] }),
    )
  }

  @computed
  public get shapeFeatureCoordinates() {
    return (
      this.isClosed
        ? [...this.coordinates, this.coordinates[0]]
        : this.coordinates
    )?.map(c => [c.longitude, c.latitude])
  }

  @computed
  public get shapeEditorPointCoordinates() {
    return this.coordinates?.map(c => [c.longitude, c.latitude])
  }

  @computed
  public get shapeAdditionalEditorPointCoordinates() {
    const res = []
    this.shapeFeatureCoordinates.forEach((c, idx) => {
      if (this.shapeFeatureCoordinates.length !== idx + 1) {
        const lngDif = this.shapeFeatureCoordinates[idx + 1][0] - c[0]
        const latDif = this.shapeFeatureCoordinates[idx + 1][1] - c[1]
        res.push([c[0] + lngDif / 2, c[1] + latDif / 2])
      }
    })
    return res
  }

  public copy() {
    return new PolyLineShapeCoordinates(this.coordinates, this.isClosed)
  }
}
