import * as React from 'react'

import { Switch } from '@blueprintjs/core'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'

interface IHierarchyConfiguratorSwitch {
  isChecked?: boolean
  onChange?: () => any
  className?: string
  isDisabled?: boolean
}

@observer
export default class BandConfigurationSwitch extends React.Component<IHierarchyConfiguratorSwitch> {
  public static defaultProps = {
    className: 'text center',
    isDisabled: false,
    isChecked: false,
  }

  public render() {
    const { isChecked, onChange, className, isDisabled } = this.props
    const title = isChecked
      ? Localization.translator.activityUpdatesOn
      : Localization.translator.activityUpdatesOff

    return (
      <div className={className}>
        <Switch
          className="primary-blue-switch bp3-align-right no-outline-container"
          checked={isChecked}
          onChange={onChange}
          disabled={isDisabled}
        />
        <div
          className={classList({
            'text center': true,
            'primary-blue': isChecked,
            light: !isChecked,
          })}
        >
          {title}
        </div>
      </div>
    )
  }
}
