import { action, computed, observable } from 'mobx'

import { IUserProject } from '~/client/graph'
import BaseTagSelectionStore from '~/client/src/desktop/stores/ui/BaseTagSelection.store'
import { CheckState } from '~/client/src/shared/components/Checkbox'
import { TagType } from '~/client/src/shared/enums/TagType'
import { ITag } from '~/client/src/shared/models/Tag'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

export default class BulkEditMembersSideBarStore extends BaseTagSelectionStore {
  @observable public selectedProjectMembers: User[] = []

  public constructor(
    private readonly tagsStore: TagsStore,
    selectedProjectMembers: User[] = [],
    private readonly bulkEditTags: (items: IUserProject[]) => void,
    private readonly availableCategories: TagType[],
    private readonly userProjectsStore: UserProjectsStore,
  ) {
    super()
    this.init(selectedProjectMembers)
  }

  public init(selectedProjectMembers: User[] = []) {
    this.selectedProjectMembers = selectedProjectMembers
  }

  @computed
  public get srcAsTags(): ITag[] {
    const allTagIds = []

    this.selectedProjectMembers.forEach(u => {
      this.availableCategories.forEach(type => {
        allTagIds.push(...this.getTagsByUserAndType(u, type))

        if (type === TagType.Team) {
          allTagIds.push(...this.getTagsByUserAndType(u, TagType.DefaultTeam))
        }
      })
    })

    return [...new Set(allTagIds)]
  }

  public getInstanceCheckState = ({
    type: tagType,
    id: tagId,
  }: ITag): CheckState => {
    const doEveryInstancesHaveTag = this.selectedProjectMembers.every(m => {
      const ids = UserProject.getUserTagsIdsByType(
        m,
        tagType,
        this.userProjectsStore,
      )
      return ids && ids.includes(tagId)
    })

    return doEveryInstancesHaveTag
      ? CheckState.CHECKED
      : CheckState.PARTIALLY_CHECKED
  }

  @action.bound
  public handleApply() {
    this.selectedProjectMembers.forEach(m => {
      this.tagsForAdding.forEach(t =>
        UserProject.setTagToUser(m, t, this.userProjectsStore),
      )
      this.tagsForDeletion.forEach(t =>
        UserProject.resetTagFromUser(m, t, this.userProjectsStore),
      )
    })

    this.bulkEditTags(
      this.selectedProjectMembers.map(u =>
        this.userProjectsStore.getByUser(u).toDto(),
      ),
    )

    super.handleApply()
  }

  private getTagsByUserAndType = (user: User, tagType: TagType): ITag[] => {
    const ids = UserProject.getUserTagsIdsByType(
      user,
      tagType,
      this.userProjectsStore,
    )
    return this.tagsStore.getTagsByIds(tagType, ids)
  }
}
