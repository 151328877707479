import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import BaseCompactPopup from '../BaseCompactPopup/BaseCompactPopup'
import Checkbox from '../Checkbox'

import './CompactGroupByFilter.scss'

interface IProps {
  isShown: boolean
  selectedMode: any
  onHide: (event?: React.SyntheticEvent<HTMLDivElement>) => void
  onGroupByChange: (bandType: any) => void
  groupByOptions: IGroupByOption[]
}

export interface IGroupByOption {
  title: string
  groupByType: any
  icon?: JSX.Element
  shouldRenderSeparator?: boolean
}

@observer
export default class CompactGroupByPicker extends React.Component<IProps> {
  public render() {
    const { isShown, onHide } = this.props

    return (
      <BaseCompactPopup
        title={Localization.translator.groupBy}
        isShown={isShown}
        onHide={onHide}
      >
        {this.renderContent()}
      </BaseCompactPopup>
    )
  }

  private renderContent(): JSX.Element {
    const { groupByOptions, selectedMode, onGroupByChange } = this.props

    return (
      <div className="col compact-group-by-container theme-radio-group px10">
        {groupByOptions.map(
          ({ title, groupByType, shouldRenderSeparator, icon }, index) => {
            const isOptionSelected = groupByType === selectedMode
            return (
              <div key={index}>
                <div
                  className={classList({
                    'group-by-option row y-center': true,
                    active: isOptionSelected,
                  })}
                  onClick={onGroupByChange.bind(this, groupByType)}
                >
                  <Checkbox
                    className="bp3-radio no-grow mb5"
                    shouldDisplayAsRadio={true}
                    isChecked={isOptionSelected}
                  />
                  <div className="text large bold row">
                    {icon}
                    {title}
                  </div>
                </div>
                {shouldRenderSeparator && (
                  <div className="bb-light-input-border" />
                )}
              </div>
            )
          },
        )}
      </div>
    )
  }
}
