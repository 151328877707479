import * as React from 'react'

import { classList } from 'react-classlist-helper'

export default function EntryTitle({ text = '', uppercase = true }) {
  return (
    <div
      style={{ height: '16px', whiteSpace: 'nowrap' }}
      className={classList({
        'text large bold primary-blue': true,
        uppercase: uppercase,
      })}
    >
      {text}
    </div>
  )
}
