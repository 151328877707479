import * as React from 'react'

import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { LocationType } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import SitemapAttributeIconComponent from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeIcon'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import {
  SAVE_DELIVERY_FIELDS_CONFIGURATIONS,
  UPDATE_ACTIVITY_FILTERS_SETTINGS,
} from '~/client/src/shared/stores/EventStore/eventConstants'

import { IObjectType } from '../../ObjectPropertiesSetup.store'

import Colors from '~/client/src/shared/theme.module.scss'

import './ObjectTypesTabs.scss'

interface IProps {
  eventsStore?: DesktopEventStore
  objectTypes: IObjectType[]
  activeObjectTypeId: LocationType
  onTabClick: (activeObjectTypeId: LocationType) => void
}

const ICON_SIZE = 16

@inject('eventsStore')
@observer
export default class ObjectTypesTabs extends React.PureComponent<IProps> {
  private static renderObjectTypeIcon(iconName: string, isActive: boolean) {
    const dataObject = {
      iconName,
      color: isActive ? Colors.primary20 : Colors.neutral60,
    } as LocationAttributeBase

    return (
      <SitemapAttributeIconComponent
        dataObject={dataObject}
        customProps={{ size: ICON_SIZE }}
      />
    )
  }

  @observable private tabDraftName: string = ''
  @observable private shownNameInputType: LocationType = null

  public render() {
    const { activeObjectTypeId, objectTypes } = this.props

    return (
      <div className="row tabs-container">
        {objectTypes.map(item => {
          return (
            <nav
              key={item.id}
              className={classList({
                'row tab ba-palette-brand-light px15 pointer no-grow': true,
                active: item.id === activeObjectTypeId,
              })}
              onClick={this.onTabClick.bind(this, item)}
            >
              <span className="no-grow mr4">
                {ObjectTypesTabs.renderObjectTypeIcon(
                  item.iconName,
                  item.id === activeObjectTypeId,
                )}
              </span>
              {this.shownNameInputType === item.id ? (
                <input
                  value={this.tabDraftName}
                  onChange={this.onDraftNameChange}
                  onBlur={this.updateFieldName.bind(this, item.id)}
                  autoFocus={true}
                />
              ) : (
                <span className="text light small-header text-ellipsis">
                  {item.name}
                </span>
              )}
            </nav>
          )
        })}
      </div>
    )
  }

  private onTabClick(item: IObjectType) {
    const { activeObjectTypeId, onTabClick } = this.props
    const isActive = item.id === activeObjectTypeId
    if (!isActive) {
      onTabClick(item.id)
      return
    }

    this.tabDraftName = item.name
    this.shownNameInputType = item.id
  }

  private onDraftNameChange = e => {
    this.tabDraftName = e.target.value
  }

  private updateFieldName(fieldId: FieldIds) {
    if (!this.tabDraftName) {
      this.shownNameInputType = null
    }
    const { eventsStore } = this.props
    const { delivery, getActivityFilterByDeliveryFieldId } =
      eventsStore.appState

    const { customFieldNames } = delivery
    customFieldNames[fieldId] = this.tabDraftName
    eventsStore.dispatch(SAVE_DELIVERY_FIELDS_CONFIGURATIONS)

    const activityFilter = getActivityFilterByDeliveryFieldId(fieldId)
    if (activityFilter) {
      activityFilter.caption =
        activityFilter.getKnownCaption() === this.tabDraftName
          ? ''
          : this.tabDraftName
      eventsStore.dispatch(UPDATE_ACTIVITY_FILTERS_SETTINGS)
    }

    this.shownNameInputType = null
    this.tabDraftName = ''
  }
}
