export const defaultLogisticsPreferences: ILogisticsViewPreferences = {
  areAnnouncementsHidden: false,
  arePermitsHidden: false,
  areDeliveriesHidden: false,
  areActivitiesHidden: false,
  areMonitoringsHidden: false,
}

export default interface ILogisticsViewPreferences {
  areAnnouncementsHidden: boolean
  arePermitsHidden: boolean
  areDeliveriesHidden: boolean
  areActivitiesHidden: boolean
  areMonitoringsHidden: boolean
}
