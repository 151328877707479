import * as React from 'react'

import { Button, Classes, Dialog, Icon, Spinner } from '@blueprintjs/core'
import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import ProjectScheduleUploadStore, {
  ScheduleStatus,
} from '../../ProjectScheduleUpload.store'
import ScheduleUploadHistoryStore from '../ScheduleUploadHistory/ScheduleUploadHistory.store'

import './ScheduleUpdatesSummary.scss'

interface IScheduleUpdatesSummary {
  scheduleUploadHistoryStore: ScheduleUploadHistoryStore
  projectScheduleUploadStore: ProjectScheduleUploadStore
  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
export default class ScheduleUpdatesSummary extends React.Component<IScheduleUpdatesSummary> {
  public get store() {
    return this.props.scheduleUploadHistoryStore
  }

  @computed
  public get summaryCompare() {
    const { previousSchedule, schedule } = this.store.uploadScheduleResult

    const isWarning =
      previousSchedule?.activityQuantity > schedule.activityQuantity

    const { getMonthDayYearAndTimeToDisplay } = this.props.projectDateStore
    return (
      <>
        <div className="row compare-header-line">
          <div className="col">{Localization.translator.current}</div>
          <div className="col" />
          <div className="col">{Localization.translator.upload_noun}</div>
        </div>
        <div className="row compare-line">
          <div className="col current">
            {previousSchedule?.fileName || ' - '}
          </div>
          <div className="col criterion">
            {Localization.translator.fileName}
          </div>
          <div className="col uploaded">{schedule.fileName}</div>
        </div>
        <div className="row compare-line">
          <div className="col current">
            {previousSchedule
              ? getMonthDayYearAndTimeToDisplay(previousSchedule.updatedAt)
              : ' - '}
          </div>
          <div className="col criterion">
            {Localization.translator.dateUploaded}
          </div>
          <div className="col uploaded">
            {getMonthDayYearAndTimeToDisplay(schedule.updatedAt)}
          </div>
        </div>
        <div className="row compare-line">
          <div className="col current">
            {previousSchedule?.activityQuantity || 0}
          </div>
          <div className="col criterion">
            {Localization.translator.totalActivities}
          </div>
          <div className="row uploaded">
            {schedule.activityQuantity}
            {isWarning && this.warningIcon}
          </div>
        </div>
        {isWarning && this.warning}
      </>
    )
  }

  @computed
  public get activityCodeTypesCompare() {
    const { uploadScheduleResult } = this.store
    if (!uploadScheduleResult?.compareData.activityCodeTypesForCompare) {
      return this.loader
    }

    return (
      <>
        <div className="row compare-header-line">
          <div className="col criterion">
            {Localization.translator.activityCodeTypes}
          </div>
        </div>
        <div className="compare-act-type-codes-section">
          {uploadScheduleResult.compareData.activityCodeTypesForCompare.map(
            ({ previousCount, categoryName, uploadCount }, index) => {
              return (
                <div className="row compare-line" key={index}>
                  <div className="col current">{previousCount}</div>
                  <div className="col criterion">{categoryName}</div>
                  <div className="col uploaded">{uploadCount}</div>
                </div>
              )
            },
          )}
        </div>
      </>
    )
  }

  @computed
  public get resourceCompare() {
    const { uploadScheduleResult } = this.store
    if (!uploadScheduleResult?.compareData.resourceForCompare) {
      return
    }

    const { categoryName, previousCount, uploadCount } =
      uploadScheduleResult.compareData.resourceForCompare

    return (
      <>
        <div className="row compare-header-line">
          <div className="col criterion">
            {Localization.translator.resources}
          </div>
        </div>
        <div className="compare-act-type-codes-section">
          <div className="row compare-line">
            <div className="col current">{previousCount || ' - '}</div>
            <div className="col criterion">{categoryName}</div>
            <div className="col uploaded">{uploadCount || ' - '}</div>
          </div>
        </div>
      </>
    )
  }

  public get content() {
    return (
      <div className="compare-container">
        {this.summaryCompare}
        {this.activityCodeTypesCompare}
        {this.resourceCompare}
      </div>
    )
  }

  @computed
  public get footer() {
    const { handleViewOnGanttClick, handleOkClick } = this.store

    return (
      <>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            text={Localization.translator.uploadAnother}
            className="schedule-update-summary-btn-cancel"
            onClick={this.onCancelClick}
          />
          <Button
            text={Localization.translator.ok}
            className="schedule-update-summary-btn"
            onClick={handleOkClick}
          />
        </div>
        <div className="row">
          <button
            type="button"
            className="view-gantt-btn"
            onClick={handleViewOnGanttClick}
          >
            {Localization.translator.saveAndViewUpdatedGantt}
          </button>
        </div>
      </>
    )
  }

  private onCancelClick = () => {
    this.props.projectScheduleUploadStore.ganttViewStatus =
      ScheduleStatus.NotUploaded
    this.store.handleCancelUploadClick()
  }

  private get warningIcon() {
    return <Icon icon="warning-sign" iconSize={20} className="warning-icon" />
  }

  private get warning() {
    const { previousSchedule, schedule } = this.store.uploadScheduleResult

    const delta = previousSchedule?.activityQuantity - schedule.activityQuantity

    return (
      <div className="row warning">
        {this.warningIcon}
        <div>
          {Localization.translator.uploadScheduleDescriptions.warning(delta)}
        </div>
      </div>
    )
  }

  private get loader() {
    return (
      <div className="row loading-area">
        <Spinner size={50} />
      </div>
    )
  }

  public render() {
    const {
      uploadScheduleResult,
      shouldShowUploadedScheduleSummary,
      closeUploadedScheduleSummaryDialog,
    } = this.store

    if (!uploadScheduleResult) {
      return null
    }

    return (
      <Dialog
        isOpen={shouldShowUploadedScheduleSummary}
        title={Localization.translator.scheduleUpdateSummary.toUpperCase()}
        isCloseButtonShown={true}
        canOutsideClickClose={true}
        className="schedule-update-summary"
        onClose={closeUploadedScheduleSummaryDialog}
      >
        <div className={Classes.DIALOG_BODY}>{this.content}</div>
        <div className={Classes.DIALOG_FOOTER}>{this.footer}</div>
      </Dialog>
    )
  }
}
