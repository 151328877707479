import * as React from 'react'

import { NOOP } from '../../utils/noop'

export default function ActivityLogEntry({
  children,
  customClass = '',
  paddingBottom = 18,
  _onClick = null,
}) {
  return (
    <li
      className={'row y-start pr10 ' + customClass}
      style={{ paddingBottom }}
      onClick={_onClick || NOOP}
    >
      {children}
    </li>
  )
}
