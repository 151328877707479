import React from 'react'

import { observer } from 'mobx-react'

import BaseGroupingOptions from '~/client/src/desktop/components/BaseGroupingOptions/BaseGroupingOptions'

import DeliveryReportGroupingOptionsStore from './DeliveryReportGroupingOptions.store'
import ReportsGroupingOptionsStore from './ReportsGroupingOptions.store'

interface IProps {
  store: ReportsGroupingOptionsStore | DeliveryReportGroupingOptionsStore
  forceClose: boolean
  CustomHandleButton?
}

@observer
export default class ReportsGroupingOptions extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props)
  }

  public render() {
    const { forceClose, CustomHandleButton, store } = this.props

    return (
      <BaseGroupingOptions
        store={store}
        forceClose={forceClose}
        CustomHandleButton={CustomHandleButton}
        shouldHideBandCreation={true}
      />
    )
  }
}
