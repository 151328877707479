import { computed, observable } from 'mobx'

import {
  CalendricalType,
  DeliveryType,
  IDelivery,
  IDeliveryAttribute,
  IDeliveryInput,
  IDeliveryMaterial,
  IMaterialProcurementData,
  ISiteLocation,
  IStringPair,
  IThread,
  LocationType,
} from '~/client/graph'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import metadataKeys from '~/client/src/shared/constants/metadataKeys'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import BaseModel from '~/client/src/shared/models/BaseModel'
import Thread from '~/client/src/shared/models/Thread'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'

import CompaniesStore from '../stores/domain/Companies.store'
import ProjectDateStore, {
  areIntervalTimesIntersects,
  isAfter,
} from '../stores/ui/ProjectDate.store'
import EventStyle from '../types/EventStyle'
import IMsDateInterval from '../types/IMsDateInterval'
import { mapGroupedToArray, objectToMap } from '../utils/converters'
import { reduceByCategories } from '../utils/util'
import RecurringDeliveriesSettings from './RecurringDeliveriesSettings'

import Colors from '~/client/src/shared/theme.module.scss'

const DELIVERY_MARKER = 'D'
const allowedRecurringFrequencies = [CalendricalType.Day, CalendricalType.Week]

export default class Delivery
  extends BaseModel<IDelivery>
  implements IDelivery
{
  public static fromDto(deliveryDto: IDelivery): Delivery {
    const deliveryObject = new Delivery(deliveryDto.id)
    deliveryObject.updateFromJson(deliveryDto, Date.now())
    return deliveryObject
  }

  public static updateMaterials(
    delivery: IDelivery | Delivery,
    materials: IDeliveryMaterial[],
  ): void {
    delivery.materials = materials
  }

  public static getStatusDisplayName(status: string): string {
    switch (status) {
      case DeliveryStatus.Requested:
        return Localization.translator.deliveryRequested
      case DeliveryStatus.Changed:
        return Localization.translator.deliveryUpdated
      case DeliveryStatus.Scheduled:
        return Localization.translator.deliveryScheduled
      case DeliveryStatus.OnSite:
        return Localization.translator.deliveryOnSite
      case DeliveryStatus.PassedInspection:
        return Localization.translator.deliveryPassedInspection
      case DeliveryStatus.FailedInspection:
        return Localization.translator.deliveryFailedInspection
      case DeliveryStatus.IncompleteDone:
        return Localization.translator.deliveryIncompletelyDone
      case DeliveryStatus.Done:
        return Localization.translator.deliveryDone
      case DeliveryStatus.Deleted:
        return Localization.translator.deliveryDeleted
      case DeliveryStatus.Denied:
        return Localization.translator.deliveryDenied
      case DeliveryStatus.Canceled:
        return Localization.translator.deliveryCanceled
      case DeliveryStatus.Delivering:
        return Localization.translator.delivering
      case DeliveryStatus.Paused:
        return Localization.translator.paused
      case DeliveryStatus.OnHold:
        return Localization.translator.onHold
    }
  }

  public static getRecurringDates = (
    startDate: number,
    endDate: number,
    recurringSettings: RecurringDeliveriesSettings,
    projectDateStore: ProjectDateStore,
    shouldIgnoreNonWorkingTimes: boolean,
  ): IMsDateInterval[] => {
    const {
      endDate: endRepeatDate,
      frequencyType,
      frequency,
      selectedDays,
    } = recurringSettings
    const {
      addDays,
      addWeeks,
      isSameWeek,
      getWeekdayNumber,
      hasWorkingDays,
      isInsideWorkingDaysAndHours,
    } = projectDateStore

    const dates: IMsDateInterval[] = []

    if (
      !shouldIgnoreNonWorkingTimes &&
      (!hasWorkingDays || !isInsideWorkingDaysAndHours(startDate, endDate))
    ) {
      return dates
    }

    const isDailyFrequency = frequencyType === CalendricalType.Day
    const isWeeklyFrequency = frequencyType === CalendricalType.Week

    while (
      !isAfter(endDate, endRepeatDate) ||
      (isWeeklyFrequency && isSameWeek(startDate, endRepeatDate))
    ) {
      if (!allowedRecurringFrequencies.includes(frequencyType)) {
        break
      }

      if (isDailyFrequency) {
        dates.push({ startDate, endDate })

        for (let freq = 0; freq < frequency; ) {
          startDate = addDays(startDate, 1).getTime()
          endDate = addDays(endDate, 1).getTime()

          if (
            shouldIgnoreNonWorkingTimes ||
            isInsideWorkingDaysAndHours(startDate, endDate)
          ) {
            freq++
          }
        }
        continue
      }

      if (!selectedDays?.length) {
        break
      }

      for (const dayOfWeek of selectedDays) {
        const startDayOfWeek = +getWeekdayNumber(startDate)
        const endDateOfWeek = +getWeekdayNumber(endDate)

        const dayStart = addDays(
          startDate,
          dayOfWeek - startDayOfWeek,
        ).getTime()
        const dayEnd = addDays(endDate, dayOfWeek - endDateOfWeek).getTime()

        if (
          dayStart < startDate ||
          dayEnd > endRepeatDate ||
          (!shouldIgnoreNonWorkingTimes &&
            !isInsideWorkingDaysAndHours(startDate, endDate))
        ) {
          continue
        }

        dates.push({ startDate: dayStart, endDate: dayEnd })
      }

      startDate = addWeeks(startDate, frequency).getTime()
      endDate = addWeeks(endDate, frequency).getTime()
    }

    return dates
  }

  public userId: string = null // requesterId
  public projectId: string = null

  @observable public bookingId?: string
  @observable public startDate?: number
  @observable public endDate?: number
  @observable public company?: string
  @observable public truckSize?: string
  @observable public locationsFromByType?: Map<LocationType, ISiteLocation[]>
  @observable public locationsToByType?: Map<LocationType, ISiteLocation[]>
  @observable public offloadingEquipments: IDeliveryAttribute[]
  @observable public vendor?: string
  @observable public vendorEmails?: string[]
  @observable public driverPhoneNumbers?: string[]
  @observable public onSiteContactPersonIds?: string[]
  @observable public isInspectionRequired?: boolean
  @observable public numberOfEquipmentPicks?: string
  @observable public activityId?: string
  @observable public status?: DeliveryStatus
  @observable public threadId?: string
  @observable public truckNumber?: string // TODO: According to up-to-date info this field – number
  @observable public truckLicensePlate?: string
  @observable public sitemapUrls?: string[] = []
  @observable public globeViewUrls?: string[] = []
  @observable public isLateRequest?: boolean
  @observable public installationZone?: string
  @observable public fromInstallationZone?: string
  @observable public materials: IDeliveryMaterial[] = []
  @observable public inspector?: string
  @observable public note?: string
  @observable public isUnscheduledRequest?: boolean
  @observable public actualStartDate?: number
  @observable public actualEndDate?: number
  @observable public recurringSettingId?: string
  @observable public cancellationReason?: string
  @observable public type: DeliveryType
  @observable private readonly metadataMap = new Map<string, string>()

  /**
   * startDate, endDate - UTC milliseconds;
   * We need to consider the timezone offset when formatting ones on the server
   */
  public constructor(
    id: string,
    userId: string = null,
    projectId: string = null,

    bookingId?: string,
    startDate?: number,
    endDate?: number,
    company?: string,
    truckSize?: string,
    locationsFrom?: ISiteLocation[],
    locationsTo?: ISiteLocation[],
    offloadingEquipments: IDeliveryAttribute[] = [],
    vendor?: string,
    vendorEmails?: string[],
    driverPhoneNumbers?: string[],
    onSiteContactPersonIds?: string[],
    isInspectionRequired?: boolean,
    activityId?: string,
    status?: DeliveryStatus,
    threadId?: string,
    truckNumber?: string, // TODO: According to up-to-date info this field – number
    truckLicensePlate?: string,
    sitemapUrls: string[] = [],
    globeViewUrls: string[] = [],
    isLateRequest?: boolean,
    installationZone?: string,
    fromInstallationZone?: string,
    materials: IDeliveryMaterial[] = [],
    inspector?: string,
    note?: string,
    isUnscheduledRequest?: boolean,
    actualStartDate?: number,
    actualEndDate?: number,
    numberOfEquipmentPicks: string = '',
    recurringSettingId?: string,
    cancellationReason?: string,
    metadata?: IStringPair[],
    type: DeliveryType = DeliveryType.Regular,
  ) {
    super(id)

    this.userId = userId || null
    this.projectId = projectId || null

    this.bookingId = bookingId
    this.startDate = startDate
    this.endDate = endDate
    this.company = company
    this.truckSize = truckSize

    this.locationsFromByType = objectToMap(
      reduceByCategories(locationsFrom, l => [l.type, l]),
    ) as Map<LocationType, ISiteLocation[]>
    this.installationZone = installationZone

    this.locationsToByType = objectToMap(
      reduceByCategories(locationsTo, l => [l.type, l]),
    ) as Map<LocationType, ISiteLocation[]>
    this.fromInstallationZone = fromInstallationZone

    this.vendor = vendor
    this.vendorEmails = vendorEmails
    this.driverPhoneNumbers = driverPhoneNumbers
    this.onSiteContactPersonIds = onSiteContactPersonIds
    this.isInspectionRequired = isInspectionRequired
    this.offloadingEquipments = offloadingEquipments
    this.activityId = activityId
    this.status = status
    this.threadId = threadId
    this.truckNumber = truckNumber
    this.truckLicensePlate = truckLicensePlate
    this.sitemapUrls = sitemapUrls || []
    this.globeViewUrls = globeViewUrls || []
    this.isLateRequest = isLateRequest

    this.materials = materials
    this.inspector = inspector
    this.note = note
    this.isUnscheduledRequest = isUnscheduledRequest
    this.actualStartDate = actualStartDate
    this.actualEndDate = actualEndDate
    this.numberOfEquipmentPicks = numberOfEquipmentPicks
    this.recurringSettingId = recurringSettingId
    this.cancellationReason = cancellationReason
    this.type = type

    this.fillMetadataMap(metadata || [])
  }

  @computed
  public get materialsMap(): { [materialId: string]: IDeliveryMaterial[] } {
    return this.materials.reduce((acc, m) => {
      if (!acc[m.materialId]) {
        acc[m.materialId] = []
      }
      acc[m.materialId].push(m)
      return acc
    }, {})
  }

  @computed
  public get materialProcurementsMap(): {
    [procurementId: string]: IDeliveryMaterial[]
  } {
    return this.materials.reduce((acc, m) => {
      if (!acc[m.procurementId]) {
        acc[m.procurementId] = []
      }
      acc[m.procurementId].push(m)
      return acc
    }, {})
  }

  @computed
  public get materialQuantitiesMap(): { [materialId: string]: number } {
    return this.materials.reduce((acc, { materialId, quantity }) => {
      acc[materialId] = (acc[materialId] ?? 0) + (quantity || 0)
      return acc
    }, {})
  }

  @computed
  public get procurementQuantitiesMap(): { [procurementId: string]: number } {
    return this.materials.reduce((acc, { procurementId, quantity }) => {
      acc[procurementId] = (acc[procurementId] ?? 0) + (quantity || 0)
      return acc
    }, {})
  }

  public getMaterialsByIds = (
    materialId: string,
    procurementId: string,
  ): IDeliveryMaterial[] => {
    return (
      (procurementId
        ? this.materialProcurementsMap[procurementId]
        : this.materialsMap[materialId]) || []
    )
  }

  public getMaterialLocationIds = (
    materialId: string,
    procurementId: string,
  ): string[] => {
    const set = this.getMaterialsByIds(materialId, procurementId).reduce(
      (set, material) =>
        material.locationId ? set.add(material.locationId) : set,
      new Set<string>(),
    )
    return Array.from(set.values())
  }

  public codeToDisplay = (companiesStore: CompaniesStore): string => {
    return `#${
      this.formattedCompanyCode(companiesStore) ||
      Localization.translator.notSpecified
    }`
  }

  public get isPending(): boolean {
    return (
      this.status === DeliveryStatus.Requested ||
      this.status === DeliveryStatus.Changed
    )
  }

  public get isAccepted(): boolean {
    return (
      this.status === DeliveryStatus.Scheduled ||
      this.status === DeliveryStatus.Delivering ||
      this.status === DeliveryStatus.Paused ||
      this.status === DeliveryStatus.OnHold ||
      this.status === DeliveryStatus.OnSite ||
      this.status === DeliveryStatus.PassedInspection ||
      this.status === DeliveryStatus.FailedInspection ||
      this.status === DeliveryStatus.IncompleteDone ||
      this.status === DeliveryStatus.Done
    )
  }

  public get isInspectionPassed(): boolean {
    return (
      this.status === DeliveryStatus.PassedInspection ||
      this.status === DeliveryStatus.Done
    )
  }

  public get isInspectionFailed(): boolean {
    return (
      this.status === DeliveryStatus.FailedInspection ||
      this.status === DeliveryStatus.IncompleteDone
    )
  }

  public get isDone(): boolean {
    return (
      this.status === DeliveryStatus.Done ||
      this.status === DeliveryStatus.IncompleteDone
    )
  }

  public get isDenied(): boolean {
    return this.status === DeliveryStatus.Denied
  }

  public get isDeleted(): boolean {
    return this.status === DeliveryStatus.Deleted
  }

  public get isCanceled(): boolean {
    return this.status === DeliveryStatus.Canceled
  }

  public get isDelivering(): boolean {
    return this.status === DeliveryStatus.Delivering
  }

  public get isPaused(): boolean {
    return this.status === DeliveryStatus.Paused
  }

  public get isOnHold(): boolean {
    return this.status === DeliveryStatus.OnHold
  }

  public get isOnSite(): boolean {
    return this.status === DeliveryStatus.OnSite
  }

  public get isOnSiteOrInspected(): boolean {
    return this.isOnSite || this.isInspectionPassed || this.isInspectionFailed
  }

  public isActiveForToday({ isToday }: ProjectDateStore): boolean {
    return !this.isDone && (isToday(this.startDate) || isToday(this.endDate))
  }

  public setThread(thread: Thread | IThread): void {
    if (thread) {
      this.threadId = thread.id
    }
  }

  public get route() {
    return this.locationsToByType.get(LocationType.Route)?.[0]?.id
  }

  public get zone() {
    return this.locationsToByType.get(LocationType.Zone)?.[0]?.id
  }

  public get gate() {
    return this.locationsToByType.get(LocationType.Gate)?.[0]?.id
  }

  public get level() {
    return this.locationsToByType.get(LocationType.Level)?.[0]?.id
  }

  public get area() {
    return this.locationsToByType.get(LocationType.Area)?.[0]?.id
  }

  public get building() {
    return this.locationsToByType.get(LocationType.Building)?.[0]?.id
  }

  public get interiorPath() {
    return this.locationsToByType.get(LocationType.InteriorPath)?.[0]?.id
  }

  public get interiorDoor() {
    return this.locationsToByType.get(LocationType.InteriorDoor)?.[0]?.id
  }

  public get staging() {
    return this.locationsToByType.get(LocationType.Staging)?.[0]?.id
  }

  public get fromZone() {
    return this.locationsFromByType.get(LocationType.Zone)?.[0]?.id
  }

  public get fromLevel() {
    return this.locationsFromByType.get(LocationType.Level)?.[0]?.id
  }

  public get fromArea() {
    return this.locationsFromByType.get(LocationType.Area)?.[0]?.id
  }

  public get fromBuilding() {
    return this.locationsFromByType.get(LocationType.Building)?.[0]?.id
  }

  public get fromStaging() {
    return this.locationsFromByType.get(LocationType.Staging)?.[0]?.id
  }

  public toFields(projectDateStore: ProjectDateStore) {
    return {
      [FieldIds.DATE]: projectDateStore.getDashedFormattedDate(
        new Date(this.startDate),
      ),
      [FieldIds.START_TIME]: projectDateStore.getISOTime(
        new Date(this.startDate),
      ),
      [FieldIds.END_DATE]: projectDateStore.getDashedFormattedDate(
        new Date(this.endDate),
      ),
      [FieldIds.END_TIME]: projectDateStore.getISOTime(new Date(this.endDate)),
      [FieldIds.REQUESTER]: this.userId,
      [FieldIds.COMPANY]: this.company,
      [FieldIds.ACTIVITY_ID]: this.activityId,
      [FieldIds.IS_INSPECTION_REQUIRED]: this.isInspectionRequired ? '1' : '0',
      [FieldIds.DRIVER_PHONE_NUMBERS]: this.driverPhoneNumbers,
      [FieldIds.VENDOR]: this.vendor,
      [FieldIds.VENDOR_EMAILS]: this.vendorEmails,
      [FieldIds.TRUCK_SIZE]: this.truckSize,
      [FieldIds.TRUCK_NUMBER]: this.truckNumber,
      [FieldIds.TRUCK_LICENSE_PLATE]: this.truckLicensePlate,
      [FieldIds.OFFLOADING_EQUIPMENT]:
        this.offloadingEquipments?.map(eq => eq.id) || [],
      [FieldIds.NUMBER_OF_EQUIPMENT_PICKS]: this.numberOfEquipmentPicks,
      [FieldIds.SITE_MAPS]: this.sitemapUrls,
      [FieldIds.GLOBES]: this.globeViewUrls,

      [FieldIds.ON_SITE_CONTACTS]: this.onSiteContactPersonIds,
      [FieldIds.LATE_REQUEST]: this.isLateRequest,
      [FieldIds.UNSCHEDULED_REQUEST]: this.isUnscheduledRequest,

      [FieldIds.INSPECTOR]: this.inspector,
      [FieldIds.NOTE]: this.note,

      [FieldIds.FROM_TO_SWITCH]: this.type === DeliveryType.FromTo,

      // LOCATIONS
      [FieldIds.ZONE]: this.zone,
      [FieldIds.FROM_ZONE]: this.fromZone,

      [FieldIds.GATE]: this.gate,

      [FieldIds.LEVEL]: this.level,
      [FieldIds.FROM_LEVEL]: this.fromLevel,

      [FieldIds.AREA]: this.area,
      [FieldIds.FROM_AREA]: this.fromArea,

      [FieldIds.ROUTE]: this.route,

      [FieldIds.BUILDING]: this.building,
      [FieldIds.FROM_BUILDING]: this.fromBuilding,

      [FieldIds.INTERIOR_PATH]: this.interiorPath,

      [FieldIds.INTERIOR_DOOR]: this.interiorDoor,

      [FieldIds.STAGING]: this.staging,
      [FieldIds.FROM_STAGING]: this.fromStaging,

      [FieldIds.INSTALLATION_ZONE]: this.installationZone,
      [FieldIds.FROM_INSTALLATION_ZONE]: this.fromInstallationZone,
    }
  }

  public get materialIds(): string[] {
    return Object.keys(this.materialsMap)
  }

  public getDeliveryCaption(companiesStore: CompaniesStore): string {
    return `${Delivery.getStatusDisplayName(
      this.status,
    )}: ${companiesStore.getCompanyNameById(this.company)}`
  }

  public get canChange(): boolean {
    return [
      DeliveryStatus.Requested,
      DeliveryStatus.Changed,
      DeliveryStatus.Scheduled,
      DeliveryStatus.Denied,
    ].includes(this.status)
  }

  public get nextStatus(): DeliveryStatus {
    switch (this.status) {
      case DeliveryStatus.Requested:
      case DeliveryStatus.Changed:
      case DeliveryStatus.Denied:
        return DeliveryStatus.Scheduled
      case DeliveryStatus.Scheduled:
        return DeliveryStatus.OnSite
      case DeliveryStatus.OnSite:
        return DeliveryStatus.PassedInspection
      case DeliveryStatus.FailedInspection:
        return DeliveryStatus.IncompleteDone
      case DeliveryStatus.PassedInspection:
        return DeliveryStatus.Done
    }
  }

  public isRouteId(route: string): boolean {
    return this.route === route
  }

  public isBuildingId(building: string): boolean {
    return this.building === building
  }

  public isLevelId(levelId: string): boolean {
    return this.level === levelId
  }

  public isArealId(areaId: string): boolean {
    return this.area === areaId
  }

  public isZoneId(zoneId: string): boolean {
    return this.zone === zoneId
  }

  public isGateId(gateId: string): boolean {
    return this.gate === gateId
  }

  public isStagingId(stagingId: string): boolean {
    return this.staging === stagingId
  }

  public isInteriorPathId(interiorDoorId: string): boolean {
    return this.interiorDoor === interiorDoorId
  }

  public isInteriorDoorId(interiorPathId: string): boolean {
    return this.interiorPath === interiorPathId
  }

  public isVehicleTypeId(typeId: string): boolean {
    return this.truckSize === typeId
  }

  public isInspectorId(typeId: string): boolean {
    return this.inspector === typeId
  }

  public isScheduledWithinRange(
    startDate: Date | number,
    endDate: Date | number,
  ): boolean {
    return areIntervalTimesIntersects(
      { startDate: new Date(this.startDate), endDate: new Date(this.endDate) },
      { startDate: new Date(startDate), endDate: new Date(endDate) },
    )
  }

  @computed
  public get offloadingEquipmentIds(): string[] {
    return this.offloadingEquipments.map(equipment => equipment.id)
  }

  public isOffloadingEquipmentId(equipmentId: string): boolean {
    return this.offloadingEquipmentIds.includes(equipmentId)
  }

  public canUserChangeStatus(
    status: DeliveryStatus,
    userProject: UserProject,
  ): boolean {
    if (this.status === status) {
      return false
    }

    const isSuperUser = userProject.isSuperUser
    const isRequester = this.isRequester(userProject.userId)

    switch (status) {
      case DeliveryStatus.Requested:
        return !this.isPending && isSuperUser
      case DeliveryStatus.Changed:
        return isSuperUser || isRequester
      case DeliveryStatus.Scheduled:
        return isSuperUser
      case DeliveryStatus.Denied:
        return isSuperUser && !this.isDone && !this.isFromConcreteDirect
      case DeliveryStatus.OnSite:
        return (
          (isSuperUser ||
            (isRequester &&
              !this.isLateRequest &&
              this.status === DeliveryStatus.Scheduled)) &&
          !this.isFromConcreteDirect
        )
      case DeliveryStatus.FailedInspection:
        return (
          (isSuperUser ||
            (isRequester &&
              [DeliveryStatus.OnSite, DeliveryStatus.PassedInspection].includes(
                this.status,
              ))) &&
          !this.isFromConcreteDirect
        )
      case DeliveryStatus.PassedInspection:
        return (
          (isSuperUser ||
            (isRequester &&
              [DeliveryStatus.OnSite, DeliveryStatus.FailedInspection].includes(
                this.status,
              ))) &&
          !this.isFromConcreteDirect
        )
      case DeliveryStatus.IncompleteDone:
        return (
          (isSuperUser ||
            (isRequester && this.status === DeliveryStatus.FailedInspection)) &&
          !this.isFromConcreteDirect
        )
      case DeliveryStatus.Done:
        return (
          (isSuperUser ||
            (isRequester && this.status === DeliveryStatus.PassedInspection)) &&
          !this.isFromConcreteDirect
        )
      case DeliveryStatus.Canceled:
        return (isSuperUser || isRequester) && !this.isFromConcreteDirect
    }

    return false
  }

  public isStatusPassed(status: DeliveryStatus): boolean {
    switch (status) {
      case DeliveryStatus.Requested:
      case DeliveryStatus.Changed:
      case DeliveryStatus.Denied:
        return !this.isPending
      case DeliveryStatus.Scheduled:
        return this.isAccepted
      case DeliveryStatus.OnSite:
        return [
          DeliveryStatus.OnSite,
          DeliveryStatus.FailedInspection,
          DeliveryStatus.PassedInspection,
          DeliveryStatus.IncompleteDone,
          DeliveryStatus.Done,
        ].includes(this.status)
      case DeliveryStatus.PassedInspection:
        return this.isInspectionPassed
      case DeliveryStatus.FailedInspection:
        return this.isInspectionFailed
      case DeliveryStatus.IncompleteDone:
      case DeliveryStatus.Done:
        return this.isDone
      case DeliveryStatus.Canceled:
        return this.isCanceled
    }

    return false
  }

  public getEventStyle(
    attributeColor: string,
    isHighlighted?: boolean,
  ): EventStyle {
    const color = attributeColor || Colors.primary20
    return this.getEventStyleByColor(color, isHighlighted)
  }

  public getEventStyleByColor(
    color: string,
    isHighlighted?: boolean,
  ): EventStyle {
    const style = new EventStyle({
      textColor: Colors.black,
      backgroundColor: Colors.neutral100,
      outline: {
        thickness: 0,
        strokeStyle: 'solid',
        color: Colors.neutral100,
      },
      border: {
        thickness: 0,
        strokeStyle: 'solid',
        color: Colors.neutral100,
      },
    })

    if (this.isPending) {
      style.withTextColor(color).withBorder(1, 'solid', color)
    } else {
      style
        .withBorder(1, 'solid', color)
        .withBackgroundColor(color)
        .withTextColor(color)
    }

    if (isHighlighted) {
      style.withOutline(2, 'solid', Colors.error50)
    }

    return style
  }

  public get durationMs(): number {
    return Math.max(this.endDate - this.startDate, 0)
  }

  public get actualDurationMs(): number {
    return Math.max(this.actualEndDate - this.actualStartDate, 0)
  }

  public get isFromConcreteDirect(): boolean {
    return this.metadataMap.has(metadataKeys.cdOrderKey)
  }

  public get concreteDirectOrderId(): string {
    if (this.isFromConcreteDirect) {
      return this.metadataMap.get(metadataKeys.cdOrderKey)
    }
  }

  public isRelatedAttr = (attrId: string): boolean => {
    return (
      this.isGateId(attrId) ||
      this.isZoneId(attrId) ||
      this.isRouteId(attrId) ||
      this.isBuildingId(attrId) ||
      this.isOffloadingEquipmentId(attrId) ||
      this.isArealId(attrId) ||
      this.isLevelId(attrId) ||
      this.isStagingId(attrId) ||
      this.isInteriorDoorId(attrId) ||
      this.isInteriorPathId(attrId)
    )
  }

  public isAssociatedWithUser = ({ id }: User) => {
    return (
      this.userId === id ||
      this.inspector === id ||
      this.onSiteContactPersonIds.includes(id)
    )
  }

  public get asJson(): IDelivery {
    return this.getDto(this.projectId) as any
  }

  public updateFromJson(json: any, timestamp: number = null): void {
    this.bookingId = json.bookingId || ''
    this.startDate = json.startDate
    this.endDate = json.endDate
    this.actualStartDate = json.actualStartDate
    this.actualEndDate = json.actualEndDate
    this.company = json.company
    this.materials = json.materials || []
    this.truckSize = json.truckSize
    this.locationsFromByType = objectToMap(
      reduceByCategories(json.locationsFrom, (l: ISiteLocation) => [l.type, l]),
    ) as Map<LocationType, ISiteLocation[]>
    this.locationsToByType = objectToMap(
      reduceByCategories(json.locationsTo, (l: ISiteLocation) => [l.type, l]),
    ) as Map<LocationType, ISiteLocation[]>
    this.vendor = json.vendor || null
    this.vendorEmails = json.vendorEmails || []
    this.driverPhoneNumbers = json.driverPhoneNumbers || []
    this.onSiteContactPersonIds = json.onSiteContactPersonIds || []
    this.isInspectionRequired = json.isInspectionRequired || false
    this.offloadingEquipments = Object.values(json.offloadingEquipments || {})
    this.activityId = json.activityId
    this.status = json.status || DeliveryStatus.Scheduled
    this.cancellationReason = json.cancellationReason
    this.projectId = json.projectId
    this.threadId = json.threadId || null
    this.userId = json.userId
    this.truckNumber = json.truckNumber
    this.truckLicensePlate = json.truckLicensePlate
    this.sitemapUrls = json.sitemapUrls || []
    this.globeViewUrls = json.globeViewUrls || []
    this.isLateRequest = json.isLateRequest
    this.isUnscheduledRequest = json.isUnscheduledRequest || false

    this.installationZone = json.installationZone || ''
    this.fromInstallationZone = json.fromInstallationZone || ''

    this.inspector = json.inspector || ''
    this.note = json.note || ''
    this.type = json.type || DeliveryType.Regular

    this.numberOfEquipmentPicks = json.numberOfEquipmentPicks
    this.recurringSettingId = json.recurringSettingId

    this.fillMetadataMap(json.metadata || [])

    this.setCreatedAt(json.createdAt || timestamp)
    this.setUpdatedAt(json.updatedAt || timestamp)
  }

  public getDto(projectId: string): IDeliveryInput {
    return {
      id: this.id || null,
      userId: this.userId || null,
      projectId,
      activityId: this.activityId,
      company: this.company || null,
      driverPhoneNumbers: this.driverPhoneNumbers,
      endDate: this.endDate,
      locationsFrom: mapGroupedToArray(this.locationsFromByType),
      locationsTo: mapGroupedToArray(this.locationsToByType),
      isInspectionRequired: this.isInspectionRequired,
      isUnscheduledRequest: this.isUnscheduledRequest,
      offloadingEquipments: this.offloadingEquipments,
      numberOfEquipmentPicks: this.numberOfEquipmentPicks,
      onSiteContactPersonIds: this.onSiteContactPersonIds,
      sitemapUrls: this.sitemapUrls,
      globeViewUrls: this.globeViewUrls,
      startDate: this.startDate,
      status: this.status,
      truckNumber: this.truckNumber,
      truckLicensePlate: this.truckLicensePlate,
      truckSize: this.truckSize,
      vendor: this.vendor || null,
      vendorEmails: this.vendorEmails,
      installationZone: this.installationZone,
      fromInstallationZone: this.fromInstallationZone,
      note: this.note,
      inspector: this.inspector || null,
      threadId: this.threadId,
      materials: this.materials,
      recurringSettingId: this.recurringSettingId || null,
      createdAt: this.createdAt || null,
      updatedAt: this.updatedAt || null,
      type: this.type,
    }
  }

  public isRequester = (requesterId: string): boolean => {
    return this.userId === requesterId
  }

  public hasMaterialByProcData = (
    procurementId: string,
    locationId: string,
    materialProcurements: IMaterialProcurementData[],
  ): boolean => {
    if (procurementId) {
      return procurementId in this.materialProcurementsMap
    }
    return (
      this.isRelatedAttr(locationId) ||
      !materialProcurements.some(
        pr => pr.installLocationId && this.isRelatedAttr(pr.installLocationId),
      )
    )
  }

  private formattedCompanyCode = (companiesStore: CompaniesStore): string => {
    if (!this.bookingId) {
      return ''
    }

    const company = companiesStore.getCompanyById(this.company)
    const companyPrefix = company?.code ? `${company.code}-` : ''
    const sequenceNumber = Number(this.bookingId.split('-')[1])

    return `${companyPrefix}${DELIVERY_MARKER}${sequenceNumber}`
  }

  private fillMetadataMap(metadataList: IStringPair[]) {
    this.metadataMap.clear()
    metadataList.forEach(m => this.metadataMap.set(m.key, m.value))
  }
}
