import { action, observable } from 'mobx'

import { IScheduleComparingResult, IUploadScheduleResult } from '~/client/graph'
import desktopRoutes from '~/client/src/desktop/constants/desktopRoutes'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'
import ProjectSetUpPageStore from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPage.store'
import { RESTORE_SCHEDULE } from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivityCodeTypesStore from '~/client/src/shared/stores/domain/ActivityCodeTypes.store'
import Guard from '~/client/src/shared/utils/Guard'

export default class ScheduleUploadHistoryStore {
  @observable
  public dataForComparing: IScheduleComparingResult = null
  @observable public shouldShowRestoreConfirmModal = false
  @observable public selectedBackupIndex: number = null
  @observable public uploadScheduleResult: IUploadScheduleResult = null
  @observable public shouldShowUploadedScheduleSummary: boolean = false

  public constructor(
    private projectSetUpPageStore: ProjectSetUpPageStore,
    private eventsStore: DesktopEventStore,
    private common: DesktopCommonStore,
    private activityCodeTypesStore: ActivityCodeTypesStore,
  ) {
    Guard.requireAll({
      projectSetUpPageStore,
      eventsStore,
      common,
    })
  }

  public get currentProject() {
    return this.projectSetUpPageStore.currentProject
  }

  public get scheduleHistory() {
    return this.projectSetUpPageStore.scheduleHistory
  }

  public get lastScheduleUpdate() {
    return this.projectSetUpPageStore.getLastPageUpdate('schedule')
  }

  @action.bound
  public closeUploadedScheduleSummaryDialog() {
    this.shouldShowUploadedScheduleSummary = false
  }

  @action.bound
  public showUploadedScheduleSummaryDialog() {
    this.shouldShowUploadedScheduleSummary = true
  }

  @action.bound
  public handleViewOnGanttClick() {
    this.closeUploadedScheduleSummaryDialog()
    localStorage.setItem('ganttViewMode', 'onBoarding')
    this.common._displayView(desktopRoutes.ACTIVITIES)
  }

  @action.bound
  public handleOkClick() {
    this.closeUploadedScheduleSummaryDialog()
  }

  @action.bound
  public async handleCancelUploadClick() {
    this.closeUploadedScheduleSummaryDialog()
    await this.restoreSchedule(0, true)
    this.uploadScheduleResult = null
  }

  @action.bound
  public async restoreOldSchedule() {
    const index = this.selectedBackupIndex
    this.hideRestoringScheduleConfirm()

    const previousSchedule = this.lastScheduleUpdate.schedule
    const schedule = this.scheduleHistory[index].schedule
    const previousSummary = this.activityCodeTypesStore.summary

    await this.restoreSchedule(index, false)

    const { summary } = this.activityCodeTypesStore
    const activityCodeTypesForCompare = Array.from(
      new Set(Object.keys(previousSummary).concat(Object.keys(summary))),
    ).map(codeTypeName => ({
      categoryName: codeTypeName,
      previousCount: previousSummary[codeTypeName] || 0,
      uploadCount: summary[codeTypeName] || 0,
    }))

    this.uploadScheduleResult = {
      previousSchedule,
      schedule,
      compareData: {
        resourceForCompare: {
          categoryName: 'Companies',
          previousCount: previousSchedule.companyQuantity,
          uploadCount: schedule.companyQuantity,
        },
        activityCodeTypesForCompare,
      },
    }
    this.showUploadedScheduleSummaryDialog()
  }

  @action.bound
  public showRestoringScheduleConfirm(backupIndex: number) {
    this.shouldShowRestoreConfirmModal = true
    this.selectedBackupIndex = backupIndex
  }

  @action.bound
  public hideRestoringScheduleConfirm() {
    this.shouldShowRestoreConfirmModal = false
    this.selectedBackupIndex = null
  }

  public get selectedBackup() {
    return this.scheduleHistory[this.selectedBackupIndex]
  }

  public moveCurrentScheduleToHistory() {
    const historyItem = Object.assign({}, this.lastScheduleUpdate)
    if (this.scheduleHistory.length) {
      this.scheduleHistory.unshift(historyItem)
    } else {
      this.eventsStore.appState.projectHistory.scheduleHistory = [historyItem]
    }
  }

  private async restoreSchedule(index: number, isRollback: boolean) {
    const { loading, user } = this.eventsStore.appState
    loading.set(RESTORE_SCHEDULE, true)
    if (this.scheduleHistory.length) {
      const { scheduleId, fileName, updatedAt } = this.scheduleHistory.splice(
        index,
        1,
      )[0]
      if (!isRollback) {
        this.moveCurrentScheduleToHistory()
      }

      const { id, email, firstName, lastName } = user
      Object.assign(this.lastScheduleUpdate, {
        updatedAt: isRollback ? updatedAt : Date.now(),
        scheduleId,
        fileName: fileName || null,
        by: { id, email, firstName, lastName },
      })

      this.currentProject.scheduleId = scheduleId
    } else {
      this.projectSetUpPageStore.setPageLastUpdated({
        pageName: 'schedule',
        updateInfo: null,
      })
      this.currentProject.scheduleId = null
    }

    await this.projectSetUpPageStore.saveCurrentProject()
    await this.projectSetUpPageStore.saveCurrentProjectHistory()
    loading.set(RESTORE_SCHEDULE, false)
  }
}
