import * as React from 'react'

import { Icon, Popover, PopperModifiers, Position } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList, toggleClass } from 'react-classlist-helper'

import DynamicOverflowList from '~/client/src/shared/components/DynamicOverflowList/DynamicOverflowList'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import BuildingsStore from '~/client/src/shared/stores/domain/Buildings.store'
import GatesStore from '~/client/src/shared/stores/domain/Gates.store'
import OffloadingEquipmentsStore from '~/client/src/shared/stores/domain/OffloadingEquipments.store'
import ZonesStore from '~/client/src/shared/stores/domain/Zones.store'

import GlobeView from '../../models/GlobeView'
import AreasStore from '../../stores/domain/Areas.store'
import LevelsStore from '../../stores/domain/Levels.store'
import { NOOP } from '../../utils/noop'

import './SitemapDeliveryAttributes.scss'

import Area from '../../models/LocationObjects/Area'
import Building from '../../models/LocationObjects/Building'
import Gate from '../../models/LocationObjects/Gate'
import Level from '../../models/LocationObjects/Level'
import LocationBase from '../../models/LocationObjects/LocationBase'
import OffloadingEquipment from '../../models/LocationObjects/OffloadingEquipment'
import Route from '../../models/LocationObjects/Route'
import Zone from '../../models/LocationObjects/Zone'
import RoutesStore from '../../stores/domain/Routes.store'

const OVERFLOW_LIST_ROWS_COUNT = 2
const OVERFLOW_ITEMS_LABEL_WIDTH = 141
const MIN_CONTAINER_WIDTH = 300

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: { enabled: false },
  hide: { enabled: false },
  computeStyle: { gpuAcceleration: false },
}

const xTags = (x: number) => `${x} tag${x === 1 ? '' : 's'}`

export interface IProps {
  globe: GlobeView

  className?: string
  gatesStore?: GatesStore
  buildingsStore?: BuildingsStore
  zonesStore?: ZonesStore
  routesStore?: RoutesStore
  offloadingEquipmentsStore?: OffloadingEquipmentsStore
  levelsStore?: LevelsStore
  areasStore?: AreasStore
  shouldHideCrossIcons?: boolean
  areTagsBordered?: boolean
  shouldRenderAsOverflowList?: boolean
  addTagButtonRenderer?: () => JSX.Element
  isSingleMode?: boolean
}

@inject(
  'gatesStore',
  'zonesStore',
  'buildingsStore',
  'routesStore',
  'offloadingEquipmentsStore',
  'levelsStore',
  'areasStore',
)
@observer
export default class GlobeDeliveryAttributes extends React.Component<IProps> {
  @computed
  private get globeAssignedGates(): Gate[] {
    const { globe, gatesStore } = this.props
    return gatesStore.list.filter(g => g.isGlobeAssigned(globe.id))
  }

  @computed
  private get globeAssignedRoutes(): Route[] {
    const { globe, routesStore } = this.props
    return routesStore.list.filter(r => r.isGlobeAssigned(globe.id))
  }

  @computed
  private get globeAssignedEquipment(): OffloadingEquipment[] {
    const { globe, offloadingEquipmentsStore } = this.props
    return offloadingEquipmentsStore.list.filter(g =>
      g.isGlobeAssigned(globe.id),
    )
  }

  @computed
  private get globeAssignedBuildings(): Building[] {
    const { globe, buildingsStore: buildingsStore } = this.props
    return buildingsStore.list.filter(b => b.isGlobeAssigned(globe.id))
  }

  @computed
  private get globeAssignedZones(): Zone[] {
    const { globe, zonesStore } = this.props
    return zonesStore.list.filter(z => z.isGlobeAssigned(globe.id))
  }

  @computed
  private get globeAssignedLevels(): Level[] {
    const { globe, levelsStore } = this.props
    return levelsStore.list.filter(l => l.isGlobeAssigned(globe.id))
  }

  @computed
  private get globeAssignedAreas(): Area[] {
    const { globe, areasStore } = this.props
    return areasStore.list.filter(a => a.isGlobeAssigned(globe.id))
  }

  public render() {
    const { className, shouldRenderAsOverflowList } = this.props

    if (shouldRenderAsOverflowList) {
      return this.renderAsOverflowList()
    }

    return (
      <div className={className}>
        {this.deliveryAttributes.map(item => item)}
      </div>
    )
  }

  private renderAsOverflowList = () => {
    const { addTagButtonRenderer, className } = this.props

    return (
      <DynamicOverflowList
        items={this.deliveryAttributes}
        rowsCount={OVERFLOW_LIST_ROWS_COUNT}
        additionalWidth={OVERFLOW_ITEMS_LABEL_WIDTH}
        minContainerWidthConstraint={MIN_CONTAINER_WIDTH}
      >
        {({ visibleElements, overflowElements, containerRefSetter }) => {
          return (
            <div className={className} ref={containerRefSetter}>
              {visibleElements}
              <div className="inline-block">
                {this.renderOverflowLabel(overflowElements)}
                {addTagButtonRenderer && addTagButtonRenderer()}
              </div>
            </div>
          )
        }}
      </DynamicOverflowList>
    )
  }

  private renderAttributeTag(
    text: string,
    onClick?: () => void,
    key?: React.Key,
    dataObject?: LocationBase,
    shouldUseEllipsisClass?: boolean,
  ) {
    const { shouldHideCrossIcons, areTagsBordered } = this.props

    return (
      <div
        className="inline-block vertical-align-top pr8 attribute-tag-holder"
        key={key}
      >
        <SitemapAttributeTag
          shouldShowAsTag={true}
          isBordered={areTagsBordered}
          dataObject={dataObject}
          contentContainerClassName={toggleClass(
            'overflow-hidden',
            !!shouldUseEllipsisClass,
          )}
        >
          <div className="row y-center">
            <div
              className={classList({
                'text large': true,
                ellipsis: shouldUseEllipsisClass,
              })}
            >
              {text}
            </div>
            {!shouldHideCrossIcons && onClick && (
              <Icon
                onClick={onClick}
                className="attribute-icon no-grow"
                icon={IconNames.CROSS}
              />
            )}
          </div>
        </SitemapAttributeTag>
      </div>
    )
  }

  private renderOverflowLabel = (hiddenElements: JSX.Element[]) => {
    if (!hiddenElements || !hiddenElements.length) {
      return null
    }

    return (
      <Popover
        className="vertical-align-middle"
        position={Position.BOTTOM_LEFT}
        autoFocus={false}
        enforceFocus={false}
        usePortal={false}
        modifiers={popoverPopperModifiers}
        content={
          <div className="col pa10 overflow-tags overflow-auto">
            {hiddenElements.map(item => item)}
          </div>
        }
      >
        <div className="overflow-tags-btn text center large bold white pointer px10 inline-flex nowrap">
          +{xTags(hiddenElements.length)}
        </div>
      </Popover>
    )
  }

  @computed
  private get deliveryAttributes(): JSX.Element[] {
    const elements = this.getDeliveryAttributesElements(
      this.props.shouldRenderAsOverflowList,
    )

    if (!elements.length) {
      return [this.renderDefaultTag()]
    }

    if (!this.props.isSingleMode) {
      return elements
    }

    return [elements[0]]
  }

  private getDeliveryAttributesElements(
    shouldUseEllipsisClass?: boolean,
  ): JSX.Element[] {
    return [
      ...this.globeAssignedBuildings.map(building =>
        this.renderAttributeTag(
          building.name,
          this.clearBuildingGlobe.bind(this, building),
          building.id,
          building,
          shouldUseEllipsisClass,
        ),
      ),
      ...this.globeAssignedGates.map(gate =>
        this.renderAttributeTag(
          gate.name,
          this.clearGateGlobe.bind(this, gate),
          gate.id,
          gate,
          shouldUseEllipsisClass,
        ),
      ),
      ...this.globeAssignedZones.map(area =>
        this.renderAttributeTag(
          area.name,
          this.clearZoneGlobe.bind(this, area),
          area.id,
          area,
          shouldUseEllipsisClass,
        ),
      ),
      ...this.globeAssignedRoutes.map(route =>
        this.renderAttributeTag(
          route.name,
          this.clearRouteGlobe.bind(this, route),
          route.id,
          route,
          shouldUseEllipsisClass,
        ),
      ),
      ...this.globeAssignedEquipment.map(equipment =>
        this.renderAttributeTag(
          equipment.name,
          this.clearEquipmentGlobe.bind(this, equipment),
          equipment.id,
          equipment,
          shouldUseEllipsisClass,
        ),
      ),
      ...this.globeAssignedLevels.map(level =>
        this.renderAttributeTag(
          level.name,
          this.clearLevelGlobe.bind(this, level),
          level.id,
          level,
          shouldUseEllipsisClass,
        ),
      ),
      ...this.globeAssignedAreas.map(area =>
        this.renderAttributeTag(
          area.name,
          this.clearAreaGlobe.bind(this, area),
          area.id,
          area,
          shouldUseEllipsisClass,
        ),
      ),
    ]
  }

  private clearGateGlobe(dto: Gate) {
    const { globe, gatesStore: gatesStore } = this.props
    dto.deassignGlobe(globe.id)
    gatesStore.saveItem(dto)
  }

  private clearZoneGlobe(dto: Zone) {
    const { globe, zonesStore } = this.props
    dto.deassignGlobe(globe.id)
    zonesStore.saveItem(dto)
  }

  private clearEquipmentGlobe(dto: OffloadingEquipment) {
    const { globe, offloadingEquipmentsStore } = this.props
    dto.deassignGlobe(globe.id)
    offloadingEquipmentsStore.saveItem(dto)
  }

  private clearLevelGlobe(dto: Level) {
    const { globe, levelsStore: levelsStore } = this.props
    dto.deassignGlobe(globe.id)
    levelsStore.saveItem(dto)
  }

  private clearAreaGlobe(dto: Area) {
    const { globe, areasStore: areasStore } = this.props
    dto.deassignGlobe(globe.id)
    areasStore.saveItem(dto)
  }

  private clearRouteGlobe(dto: Route) {
    const { globe, routesStore: routesStore } = this.props
    dto.deassignGlobe(globe.id)
    routesStore.saveItem(dto)
  }

  private clearBuildingGlobe(dto: Building) {
    const { globe, buildingsStore: buildingsStore } = this.props
    dto.deassignGlobe(globe.id)
    buildingsStore.saveItem(dto)
  }

  private renderDefaultTag() {
    const { isProjectOverviewGlobe } = this.props.globe
    if (isProjectOverviewGlobe) {
      return this.renderAttributeTag(
        'Project Overview Globe',
        NOOP,
        this.props.globe.id,
      )
    }

    const { firstBuilding } = this.props.buildingsStore
    if (firstBuilding) {
      return this.renderAttributeTag(
        firstBuilding.name,
        NOOP,
        firstBuilding.name,
        firstBuilding,
      )
    }

    return this.renderAttributeTag('Site', NOOP, this.props.globe.id)
  }
}
