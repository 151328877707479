import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import {
  IChecklistAnswerItem,
  IPermitTypeField,
  PermitFieldType,
} from '~/client/graph'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import QuestionnaireType from '~/client/src/shared/enums/QuestionnaireType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import {
  getBullet,
  getQuestionnaireOptionsByType,
} from '~/client/src/shared/utils/PermitInstructionsHelper'

interface IOverallAnswers {
  positiveAnswers: number
  negativeAnswers: number
  naAnswers: number
}

interface IProps {
  typeField: IPermitTypeField
  isViewMode: boolean

  fieldsStore: IPermitFieldsStore
}

const sumSymbol = 'Σ'

@observer
export default class PermitFormChecklistField extends React.Component<IProps> {
  public render() {
    const { isViewMode, typeField } = this.props
    const { checklist, isMandatory } = typeField
    const { list, listType } = checklist

    const isQuestion = typeField.type === PermitFieldType.Question
    const mandatoryText = isMandatory
      ? Localization.translator.required
      : Localization.translator.optional

    return (
      <div
        className={classList({
          'col pl12': true,
          'inactive-element': isViewMode,
        })}
      >
        <span className="text light bold italic line-16 no-grow mb5">
          {mandatoryText}
        </span>
        {(list || []).map(({ id, text, questionnaireType }, i) => (
          <div key={id} className="mb15 col">
            <div className="mb10">
              {!isQuestion && (
                <span className="text large bold blue-highlight no-grow mr12">
                  {getBullet(listType, i + 1)}
                </span>
              )}
              <span className="text large">{text}</span>
            </div>
            {this.renderQuestionnaire(
              id,
              questionnaireType as QuestionnaireType,
              isViewMode,
            )}
          </div>
        ))}
        {!isQuestion && (
          <div className="row pl12 pt10 mb15 bt-light-input-border">
            <div className="row no-grow">
              <span className="text large bold blue-highlight no-grow mr12">
                {sumSymbol}
              </span>
            </div>
            <div className="row">
              {!!this.overallAnswers.naAnswers && (
                <span className="text large bold capitalize center grey">
                  {this.overallAnswers.naAnswers}
                </span>
              )}
              <span className="text large bold capitalize center red">
                {this.overallAnswers.negativeAnswers}
              </span>
              <span className="text large bold capitalize center success-green">
                {this.overallAnswers.positiveAnswers}
              </span>
            </div>
          </div>
        )}
      </div>
    )
  }

  private renderQuestionnaire = (
    checklistItemId: string,
    questionnaireType: QuestionnaireType,
    isReadonly: boolean = false,
  ): JSX.Element => {
    const { typeField, fieldsStore } = this.props
    const { getAnswerByChecklistItemId, setChecklistAnswer } = fieldsStore

    const options = getQuestionnaireOptionsByType(questionnaireType)
    const aItem = getAnswerByChecklistItemId(typeField, checklistItemId)

    return (
      <div className="row no-grow x-center questionnaire-items">
        {options.map(({ value, title }) => (
          <BaseActionButton
            key={title}
            isActive={aItem?.answer === value}
            isEnabled={!isReadonly}
            className={classList({
              'mx5 ellipsis': true,
              'green-theme': value,
              'red-theme': value === false,
              'gray-theme': value === null,
            })}
            isGrow={true}
            title={title}
            onClick={setChecklistAnswer.bind(
              null,
              typeField,
              checklistItemId,
              value,
            )}
          />
        ))}
      </div>
    )
  }

  private get fieldValues(): IChecklistAnswerItem[] {
    const { typeField, fieldsStore } = this.props
    const values = fieldsStore.getFieldValues<IChecklistAnswerItem>(
      typeField.id,
    )

    return values?.length ? values : []
  }

  private get overallAnswers(): IOverallAnswers {
    let positiveAnswers = 0
    let negativeAnswers = 0
    let naAnswers = 0

    this.fieldValues.forEach(answerItem => {
      switch (true) {
        case answerItem.answer === null:
          naAnswers++
          break
        case answerItem.answer:
          positiveAnswers++
          break
        case !answerItem.answer:
          negativeAnswers++
          break
      }
    })

    return {
      naAnswers,
      positiveAnswers,
      negativeAnswers,
    } as IOverallAnswers
  }
}
