import { IconNames } from '@blueprintjs/icons'

import { ToastTheme, showErrorToast, showToast } from './toaster'

export function handleSuccessChangeAssociation(message: string) {
  showToast(message, ToastTheme.SUCCESS, IconNames.STAR_EMPTY)
}

export function handleErrorChangeAssociation(message: string) {
  showErrorToast(message)
}
