import * as React from 'react'

import Konva from 'konva'
import { observer } from 'mobx-react'

import KonvaWorkflowCommonMapPin from './KonvaWorkflowCommonMapPin'

const ICON_PATH_DATA =
  'M12 3.989c0-.13-.036-.259-.11-.37l-.835-1.385c-.128-.221-.364-.35-.6-.35H8.4v-1.7C8.4.075 8.327 0 8.218 0H.182C.072 0 0 .074 0 .185v8.07c0 .11.073.184.182.184h.636v.037c0 .646.527 1.182 1.164 1.182.654 0 1.163-.536 1.163-1.182v-.037h.328v.037c0 .646.527 1.182 1.163 1.182.637 0 1.164-.536 1.164-1.182v-.037h3.09v.037c0 .646.528 1.182 1.165 1.182.654 0 1.163-.536 1.163-1.182v-.037h.546c.109 0 .181-.074.181-.185C12 7.054 12 6.906 12 3.99zm-1.236-1.551l.836 1.384c.036.056.055.111.055.185v1.348H8.4V2.271h2.073c.109 0 .218.056.29.167zM.366.369H8.04v6.002H.366V.369zM2 9.27c-.436 0-.8-.368-.8-.812 0-.443.364-.812.8-.812.436 0 .8.37.8.812 0 .444-.364.813-.8.813zm2.655 0c-.437 0-.8-.368-.8-.812 0-.443.363-.812.8-.812.436 0 .8.37.8.812.018.444-.346.813-.8.813zm5.436 0c-.436 0-.8-.368-.8-.812 0-.443.364-.812.8-.812.436 0 .8.37.8.812.018.444-.346.813-.8.813z'

const ICON_OFFSET = { x: 4, y: 4 }
const ICON_STROKE_WIDTH = 0.4

interface IProps {
  text: string
  isDone: boolean
  isAssigned: boolean
  isSelected: boolean
  isCanceled: boolean
  shouldRenderCircle?: boolean

  onClick: () => void
  onTouchEnd: () => void
}

@observer
export default class KonvaWorkflowDeliveriesPin extends React.Component<
  IProps & Konva.NodeConfig
> {
  public render() {
    const {
      shouldRenderCircle,
      text,
      isDone,
      isAssigned,
      isSelected,
      x,
      y,
      ...rest
    } = this.props

    return (
      <KonvaWorkflowCommonMapPin
        {...rest}
        text={text}
        isDone={isDone}
        isAssigned={isAssigned}
        isSelected={isSelected}
        shouldRenderCircle={shouldRenderCircle}
        x={x}
        y={y}
        iconData={ICON_PATH_DATA}
        iconPositionX={ICON_OFFSET.x}
        iconPositionY={ICON_OFFSET.y}
        strokeWidth={ICON_STROKE_WIDTH}
      />
    )
  }
}
