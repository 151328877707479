import {
  ISitePermitBicInspection,
  ISitePermitBicInspectionInput,
} from '~/client/graph'
import Guard from '~/client/src/shared/utils/Guard'

export default class PermitBicInspection {
  public static fromDto(dto: ISitePermitBicInspection): PermitBicInspection {
    return new PermitBicInspection(
      dto.id,
      dto.permitId,
      dto.projectId,
      dto.userId,
      dto.createdAt,
      dto.isPassed,
    )
  }

  public static toDto(
    permitBicInspection: PermitBicInspection,
  ): ISitePermitBicInspectionInput {
    return {
      id: null,
      permitId: permitBicInspection.permitId,
      projectId: permitBicInspection.projectId,
      userId: permitBicInspection.userId,
      isPassed: permitBicInspection.isPassed,
    }
  }

  public constructor(
    public id: string,
    public readonly permitId: string,
    public readonly projectId: string,
    public readonly userId: string,
    public readonly createdAt: number,
    public readonly isPassed: boolean,
  ) {
    Guard.requireAll({ permitId, projectId, createdAt })
  }
}
