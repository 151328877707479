import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import TagsSelector from '~/client/src/desktop/components/TagsSelector/TagsSelector'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import { Content, Header, View } from '~/client/src/shared/components/Layout'
import { Loader } from '~/client/src/shared/components/Loader'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ILogisticItem from '~/client/src/shared/models/ILogisticItem'
import { IAssignmentsStore } from '~/client/src/shared/stores/BaseAssignments.store'
import {
  DELETE_ASSIGNMENTS,
  SAVE_ASSIGNMENTS,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import InitialState from '~/client/src/shared/stores/InitialState'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

import LogisticsResponsibilitiesPanelStore from './LogisticsResponsibilitiesPanel.store'

import Colors from '~/client/src/shared/theme.module.scss'

// localization: translated

const availableTypes = [
  TagType.User,
  TagType.Team,
  TagType.Role,
  TagType.Company,
]

interface IProps {
  selectedEntities: ILogisticItem[]
  onClose: () => void
  domainsStores: { [k: string]: IAssignmentsStore }

  eventsStore?: DesktopEventStore
  tagsStore?: TagsStore
  state?: InitialState
}

@inject('eventsStore', 'tagsStore', 'state')
@observer
export default class LogisticsResponsibilitiesPanel extends React.Component<IProps> {
  private readonly clearPostEventCallback: () => void
  private readonly store: LogisticsResponsibilitiesPanelStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new LogisticsResponsibilitiesPanelStore(
      props.tagsStore,
      props.domainsStores,
      props.state,
      props.onClose,
      props.selectedEntities,
    )

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.store.handlePostEventCallback,
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { onClose, selectedEntities } = this.props

    if (!selectedEntities.length) {
      onClose()
    }

    if (selectedEntities !== prevProps.selectedEntities) {
      this.store.init(selectedEntities)
    }
  }

  public componentWillUnmount() {
    this.clearPostEventCallback()
  }

  public render() {
    const { loading } = this.props.eventsStore.appState
    if (loading.get(SAVE_ASSIGNMENTS) || loading.get(DELETE_ASSIGNMENTS)) {
      return <Loader />
    }

    const { onClose } = this.props
    const {
      inputValue,
      clearInput,
      handleApply,
      defaultSelection,
      changeSelection,
      canApply,
    } = this.store

    return (
      <View className="px12">
        <Header className="pb3">
          <div className="row py12">
            <Icon
              icon={IconNames.CROSS}
              className="no-grow pointer mr12"
              color={Colors.neutral60}
              onClick={onClose}
            />
            <span className="text bold small-header">
              {Localization.translator.assignUsersOrCategories}
            </span>
            <BaseActionButton
              title={Localization.translator.apply}
              className="secondary-blue-theme bold"
              isEnabled={canApply}
              onClick={handleApply}
            />
          </div>
          <StruxhubInput
            label={Localization.translator.searchUserCompanyTeamOrRole}
            value={inputValue}
            isRequiredTextHidden={true}
            onChange={this.handleInputChange}
            onValueReset={clearInput}
          />
        </Header>
        <Content>
          <TagsSelector
            onChange={changeSelection}
            availableTypes={availableTypes}
            initSelection={defaultSelection}
            searchKey={inputValue}
            separatedCategories={[TagType.Company]}
          />
        </Content>
      </View>
    )
  }

  private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.store.changeInput(event.target.value)
  }
}
