import {
  DeleteManyCastAssignmentsDocument,
  SaveManyCastAssignmentsDocument,
} from '~/client/graph/operations/generated/CastAssignments.generated'

import BaseAssignmentsStore from '../BaseAssignments.store'
import EventsStore from '../EventStore/Events.store'
import TagsStore from './Tags.store'

export default class CastAssignmentsStore extends BaseAssignmentsStore {
  public constructor(eventsStore: EventsStore, tagsStore: TagsStore) {
    super(
      eventsStore,
      tagsStore,
      SaveManyCastAssignmentsDocument,
      DeleteManyCastAssignmentsDocument,
    )
  }

  public get byId() {
    return this.eventsStore.appState.castAssignments
  }
}
