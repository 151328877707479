import * as React from 'react'

import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { QRCode } from 'react-qrcode-logo'
import ReactToPrint from 'react-to-print'

import * as Icons from '~/client/src/shared/components/Icons'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import Localization from '../../localization/LocalizationManager'
import { ToastTheme, showToast } from '../../utils/toaster'

import Colors from '~/client/src/shared/theme.module.scss'

const QR_CODE_LOGO_SRC = '/static/icons/strux-hub-dark-with-background.svg'

interface IProps {
  name: string
  openQR: () => void
  getPrintContent: () => HTMLDivElement
  url: string
  icon: JSX.Element
}

@observer
export default class QRCodeHolder extends React.Component<IProps> {
  public render(): JSX.Element {
    const { name, getPrintContent, openQR, url, icon } = this.props

    return (
      <div className="no-grow col qr-code-holder relative x-center">
        <div className="absolute qr-code-download beautiful-shadow brada4 ba-palette-brand-lighter pa5 pointer">
          <ReactToPrint
            trigger={this.renderPrintTrigger}
            content={getPrintContent}
          />
          <Icons.CopyLink
            className="no-grow mt5"
            onClick={this.copyLinkToClipboard}
          />
        </div>
        <div className="col" onClick={openQR}>
          <QRCode
            value={url}
            logoImage={QR_CODE_LOGO_SRC}
            size={240}
            fgColor={ThemeMode.getHEXColor(Colors.neutral0)}
          />
          <div className="row x-center text bold large">
            {icon}
            {name}
          </div>
        </div>
      </div>
    )
  }

  private renderPrintTrigger(): JSX.Element {
    return <Icons.CloudDownload />
  }

  private copyLinkToClipboard = (): void => {
    window.navigator.clipboard.writeText(this.props.url)

    showToast(
      Localization.translator.copiedToClipboard,
      ToastTheme.SUCCESS,
      IconNames.TICK,
    )
  }
}
