import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import { Loader } from '~/client/src/shared/components/Loader'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ConstraintsStore from '~/client/src/shared/stores/domain/Constraints.store'
import DeliveryRestrictionsStore from '~/client/src/shared/stores/domain/DeliveryRestrictions.store'

import ProjectSetUpPageStore from '../../ProjectSetUpPage.store'
import DeliveryDetailsPreview from '../ProjectWorkflowsSetUp/components/DeliveryDetailsPreview'
import { Header, Wrapper } from '../SetupPageLayout/SetupPageLayout'
import DeliveryRestrictionsContent from './components/DeliveryRestrictionsContent'

import './DeliveryRestrictions.scss'

interface IProps {
  projectSetUpPageStore: ProjectSetUpPageStore

  constraintsStore?: ConstraintsStore
  deliveryDetailsStore?: DeliveryDetailsStore
}

@inject('constraintsStore', 'deliveryDetailsStore')
@observer
export default class DeliveryRestrictions extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props)

    props.constraintsStore.loadConstraints()
  }

  public componentDidMount(): void {
    const { constraintsStore } = this.props
    constraintsStore.listenToConstraints()
  }

  public componentWillUnmount() {
    this.props.constraintsStore.dontListenToConstraints()
  }

  public render() {
    return (
      <div className="row full-height">
        <Wrapper className="overflow-auto">
          <Header title={Localization.translator.deliveryLocations} />
          {this.renderContent()}
        </Wrapper>
        <DeliveryDetailsPreview />
      </div>
    )
  }

  private renderContent(): JSX.Element {
    if (this.store.isLoaderShown) {
      return <Loader hint={Localization.translator.loading} />
    }

    return <DeliveryRestrictionsContent store={this.store} />
  }

  private get store(): DeliveryRestrictionsStore {
    return this.props.deliveryDetailsStore.deliveryRestrictionsStore
  }
}
