import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import formatOrderStatusToDisplay from '../../constants/formatOrderStatusToDisplay'
import formatPayloadStatusToDisplay from '../../constants/formatPayloadStatusToDisplay'
import ConcreteDirectOrderStatus from '../../enums/ConcreteDirectOrderStatus'
import ConcreteDirectPayloadStatus from '../../enums/ConcreteDirectPayloadStatus'

import './ConcreteDirectStatusLabel.scss'

interface IStatusProps {
  className: string
  children: React.ReactText
}

const ConcreteDirectStatusLabel = ({ children, className }: IStatusProps) => (
  <span
    className={classList({
      'nowrap py2 px4 brada4 text large bold line-extra-large': true,
      [className]: !!className,
    })}
  >
    {children}
  </span>
)

export const ConcreteDirectOrderStatusLabel = observer(
  ({
    status,
    className,
  }: {
    status: ConcreteDirectOrderStatus
    className?: string
  }) => (
    <ConcreteDirectStatusLabel
      className={classList({
        [`concrete-direct-order-status-${status}`]: !!status,
        [className]: !!className,
      })}
    >
      {formatOrderStatusToDisplay(status)}
    </ConcreteDirectStatusLabel>
  ),
)

export const ConcreteDirectPayloadStatusLabel = observer(
  ({
    status,
    className,
  }: {
    status: ConcreteDirectPayloadStatus
    className?: string
  }) => (
    <ConcreteDirectStatusLabel
      className={classList({
        [`concrete-direct-payload-status-${status}`]: !!status,
        [className]: !!className,
      })}
    >
      {formatPayloadStatusToDisplay(status)}
    </ConcreteDirectStatusLabel>
  ),
)
