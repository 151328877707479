import * as React from 'react'

import { Circle, Group, Path } from 'react-konva'

import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import * as Paths from '../LocationPaths'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_SIZE = 20

const SHADOW_BLUR = 2
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3

const {
  Bathroom,
  Break,
  Canteen,
  Dumpster,
  Elevator,
  Entrance,
  HandWash,
  Medical,
  MeetingPoint,
  Parking,
  Smoking,
  Temperature,
  Tent,
  Toilet,
  Walkway,
  Stairs,
  Shaft,
  ElectricalRoom,
  Trailer,
} = MapViewLocationIcon

const ICON_BY_TYPE = {
  [Bathroom]: {
    PATH: Paths.BATHROOM_PATH,
    SCALE: 0.8,
    OFFSET_X: 3,
    OFFSET_Y: 3,
  },
  [Break]: {
    PATH: Paths.BREAK_PATH,
  },
  [Canteen]: {
    PATH: Paths.CANTEEN_PATH,
    SCALE: 1,
    OFFSET_X: 4,
    OFFSET_Y: 4,
  },
  [Dumpster]: {
    PATH: Paths.DUMPSTER_PATH,
    SCALE: 0.7,
    OFFSET_X: 4,
    OFFSET_Y: 4,
  },
  [Entrance]: {
    PATH: Paths.ENTRANCE_PATH,
    SCALE: 1,
    OFFSET_X: 4,
    OFFSET_Y: 4,
  },
  [HandWash]: {
    PATH: Paths.HAND_WASH_PATH,
  },
  [Medical]: {
    PATH: Paths.HAND_WASH_PATH,
  },
  [MeetingPoint]: {
    PATH: Paths.MEETING_POINT_PATH,
    SCALE: 0.7,
    OFFSET_X: 4,
    OFFSET_Y: 4,
  },
  [Parking]: {
    PATH: Paths.PARKING_PATH,
  },
  [Smoking]: {
    PATH: Paths.SMOKING_PATH,
  },
  [Temperature]: {
    PATH: Paths.TEMPERATURE_PATH,
    OFFSET_X: 3,
  },
  [Tent]: {
    PATH: Paths.TENT_PATH,
  },
  [Toilet]: {
    PATH: Paths.TOILET_PATH,
    OFFSET_X: 1,
    OFFSET_Y: 1,
    SCALE: 1,
  },
  [Walkway]: {
    PATH: Paths.WALKWAY_PATH,
    OFFSET_X: 2,
    OFFSET_Y: 2,
  },
  [Elevator]: {
    PATH: Paths.ELEVATOR_PATH,
  },
  [Stairs]: {
    PATH: Paths.STAIRS_PATH,
  },
  [Shaft]: {
    PATH: Paths.SHAFT_PATH,
  },
  [ElectricalRoom]: {
    PATH: Paths.ELECTRICAL_ROOM_PATH,
    SCALE: 1.5,
    OFFSET_X: -0.1,
    OFFSET_Y: 0.2,
  },
  [Trailer]: {
    PATH: Paths.TRAILER_PATH,
    OFFSET_Y: 4.6,
  },
}
const { getHEXColor } = ThemeMode

const STROKE_WIDTH = 1

const KonvaLogisticsObjectPathIcon = ({ color, type, ...rest }) => {
  const path = ICON_BY_TYPE[type].PATH
  const SCALE = ICON_BY_TYPE[type].SCALE || 0.9
  const OFFSET_X = ICON_BY_TYPE[type].OFFSET_X || 2
  const OFFSET_Y = ICON_BY_TYPE[type].OFFSET_Y || 2
  const halfSize = ICON_SIZE / 2

  const isCircleHidden = [Elevator, Stairs, Shaft].includes(type)

  if (!path) {
    return null
  }

  return (
    <Group {...rest} offsetX={halfSize} offsetY={halfSize}>
      {!isCircleHidden && (
        <Circle
          radius={halfSize}
          x={halfSize}
          y={halfSize}
          fill={getHEXColor(color)}
          stroke={getHEXColor(Colors.neutral100)}
          strokeWidth={STROKE_WIDTH}
          shadowColor={getHEXColor(Colors.neutral0)}
          shadowBlur={SHADOW_BLUR}
          shadowOffset={SHADOW_OFFSET}
          shadowOpacity={SHADOW_OPACITY}
        />
      )}
      <Path
        data={path}
        fill={getHEXColor(isCircleHidden ? color : Colors.neutral100)}
        stroke={getHEXColor(isCircleHidden ? Colors.neutral100 : null)}
        scaleX={SCALE}
        scaleY={SCALE}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
        x={OFFSET_X}
        y={OFFSET_Y}
      />
    </Group>
  )
}

export default KonvaLogisticsObjectPathIcon
