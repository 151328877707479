export default class SizeMeasureStore {
  private isMeasureAllowed = false

  public constructor(private readonly measure: () => void) {}

  public enableMeasure() {
    this.isMeasureAllowed = true
  }

  public disableMeasure() {
    this.isMeasureAllowed = false
  }

  public measureSize() {
    if (this.isMeasureAllowed) {
      this.disableMeasure()
      this.measure()
    }
  }
}
