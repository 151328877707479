import { observable } from 'mobx'

import { areObjectsEqual } from '../../../utils/util'
import GlobeViewCircleProperties from './GlobeViewCircleProperties'
import GlobeViewIconProperties from './GlobeViewIconProperties'
import GlobeViewLabelProperties, {
  DEFAULT_MATURIX_FONT_SIZE,
} from './GlobeViewLabelProperties'
import GlobeViewPolyLineProperties from './GlobeViewPolyLineProperties'
import GlobeViewRectangleProperties from './GlobeViewRectangleProperties'
import MapViewItemDrawnPart from './SitemapItemDrawnPart'

export type GlobeViewShapeProperties =
  | GlobeViewPolyLineProperties
  | GlobeViewCircleProperties
  | GlobeViewRectangleProperties

export default class GlobeViewItemProperties {
  @observable public labelProperties: GlobeViewLabelProperties
  @observable public iconProperties: GlobeViewIconProperties
  @observable public shapeProperties: GlobeViewShapeProperties

  public constructor(
    iconProperties?: GlobeViewIconProperties,
    labelProperties?: GlobeViewLabelProperties,
    shapeProperties?: GlobeViewShapeProperties,
  ) {
    this.iconProperties = iconProperties || null
    this.labelProperties = labelProperties || null
    this.shapeProperties = shapeProperties || null
  }

  public isEqual(properties: GlobeViewItemProperties) {
    return (
      areObjectsEqual(
        this.iconProperties || {},
        properties.iconProperties || {},
      ) &&
      areObjectsEqual(
        this.labelProperties || {},
        properties.labelProperties || {},
      ) &&
      areObjectsEqual(
        this.shapeProperties || {},
        properties.shapeProperties || {},
      )
    )
  }

  public createRequiredProperties(isMaturixStation?: boolean) {
    if (!this.iconProperties) {
      this.iconProperties = new GlobeViewIconProperties()
    }

    let fontSize = null
    let isDisplayed = true

    if (isMaturixStation) {
      fontSize = DEFAULT_MATURIX_FONT_SIZE
      isDisplayed = false
    }
    if (!this.labelProperties) {
      this.labelProperties = new GlobeViewLabelProperties(
        fontSize,
        false,
        isDisplayed,
      )
    }
  }

  public get hasMultipleParts() {
    return (
      [this.iconProperties, this.labelProperties, this.shapeProperties].filter(
        p => p,
      ).length > 1
    )
  }

  public hasDrawnPart(part: MapViewItemDrawnPart) {
    switch (part) {
      case MapViewItemDrawnPart.Icon:
        return !!this.iconProperties
      case MapViewItemDrawnPart.Label:
        return !!this.labelProperties
      case MapViewItemDrawnPart.Shape:
        return !!this.shapeProperties
    }
  }

  public copy(): GlobeViewItemProperties {
    return new GlobeViewItemProperties(
      this.iconProperties && this.iconProperties.copy(),
      this.labelProperties && this.labelProperties.copy(),
      this.shapeProperties && this.shapeProperties.copy(),
    )
  }
}
