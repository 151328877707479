import * as React from 'react'

import {
  Icon,
  Popover,
  PopoverPosition,
  PopperModifiers,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'

import { IPermitTypeField } from '~/client/graph/types.generated'
import Checkbox from '~/client/src/shared/components/Checkbox'
import PermitFieldIcon from '~/client/src/shared/components/PermitFieldIcon/PermitFieldIcon'
import Localization from '~/client/src/shared/localization/LocalizationManager'

// localization: translated

interface IProps {
  stepId: string
  stepNotificationFieldIds: string[]
  availableNotificationFields: IPermitTypeField[]
  isUpdating: boolean

  addToNotifications(stepId: string, fieldId: string): void
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: { enabled: false },
  hide: { enabled: false },
  arrow: { enabled: false },
  computeStyle: { gpuAcceleration: false },
}

@observer
export default class WorkflowNotifiedUsersModal extends React.Component<IProps> {
  public render() {
    return (
      <Popover
        className="row"
        popoverClassName="workflow-notified-users-modal brada8 beautiful-shadow overflow-hidden"
        canEscapeKeyClose={false}
        position={PopoverPosition.BOTTOM_LEFT}
        modifiers={popoverPopperModifiers}
        content={this.fieldsList}
      >
        <div className="row">
          <span className="text large">{this.title}</span>
          <Icon icon={IconNames.SETTINGS} className="text grey-30" />
        </div>
      </Popover>
    )
  }

  private get fieldsList(): JSX.Element {
    const {
      isUpdating,
      availableNotificationFields,
      stepNotificationFieldIds,
    } = this.props

    return (
      <div className="col">
        {isUpdating && (
          <div className="full-height full-width absolute modal-overlay" />
        )}
        <span className="text large line-extra-large pt20 px24">
          {Localization.translator.selectNotificationUsers}:
        </span>
        <div className="notified-list pt10 pb20 overflow-auto px24">
          {!availableNotificationFields?.length && (
            <div className="text center large bold py20">
              {Localization.translator.none}
            </div>
          )}
          {availableNotificationFields?.map(field => (
            <div
              key={field.id}
              className="row y-center bb-light-grey h40 px24 text-ellipsis"
            >
              <PermitFieldIcon fieldName={field.type} />
              <span
                className="text large bold ellipsis mr5"
                title={field.caption}
              >
                {field.caption}
              </span>
              <Checkbox
                isChecked={stepNotificationFieldIds.includes(field.id)}
                onClick={this.addToNotifications.bind(this, field.id)}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }

  private get title(): string {
    const { stepNotificationFieldIds } = this.props
    if (!stepNotificationFieldIds?.length) {
      return Localization.translator.none
    }
    return `${
      stepNotificationFieldIds.length
    } ${Localization.translator.selected.toLowerCase()}`
  }

  private addToNotifications(fieldId: string) {
    this.props.addToNotifications(this.props.stepId, fieldId)
  }
}
