import { action, observable } from 'mobx'

import { UploadingType } from '~/client/graph'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import { FileUploadingStore } from '../../stores/domain/FileUploading.store'
import { EMPTY_STRING } from '../../utils/usefulStrings'

const TIMEOUT = 3000

export default class ImageInputStore {
  @observable public errorMessage: string = EMPTY_STRING
  @observable public isLoading: boolean = false

  public constructor(
    protected readonly onChange: (newUrl: string) => void,
    private readonly fileUploadingStore: FileUploadingStore,
  ) {}

  private async uploadAvatar(file: File) {
    try {
      this.isLoading = true
      const [result] = await this.fileUploadingStore.uploadFile(
        file,
        UploadingType.Image,
        file.name,
      )
      this.handleSuccess(result.fileURL)
    } catch (error) {
      this.handleError()
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  public change(file: File) {
    this.uploadAvatar(file)
  }

  @action.bound
  protected handleSuccess(url: string) {
    this.onChange(url)
  }

  @action.bound
  protected handleError() {
    this.errorMessage =
      Localization.translator.somethingWentWrongDuringAPIInteraction

    setTimeout(() => {
      this.clearError()
    }, TIMEOUT)
  }

  @action.bound
  protected clearError() {
    this.errorMessage = EMPTY_STRING
  }
}
