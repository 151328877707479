import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'

import GlobeDeliveryAttributes from '~/client/src/shared/components/SitemapDeliveryAttributes/GlobeAttributes'
import GlobeView from '~/client/src/shared/models/GlobeView'
import GlobeViewSetupStore from '~/client/src/shared/stores/GlobeViewSetup.store'

import GlobeLocationAssociationModal from './components/dialogs/GlobeLocationAssociationModal'
import MapViewItemsSetupStore from './stores/MapViewItemsSetup.store'

interface IProps {
  mapViewItemsSetupStore: MapViewItemsSetupStore
  globeViewSetupStore: GlobeViewSetupStore
  shouldHideModal?: boolean
  globe?: GlobeView
  onToggle?: (event) => void
}

@observer
export default class GlobeAssociationControl extends React.Component<IProps> {
  public render() {
    const {
      globe,
      shouldHideModal,
      globeViewSetupStore: { selectedGlobeView, toggleAssignGlobeDialog },
      onToggle,
    } = this.props

    if (!globe && !selectedGlobeView) {
      return <div />
    }

    return (
      <div className="row full-width">
        <div className="delivery-attributes-container no-grow">
          <GlobeDeliveryAttributes
            globe={globe || selectedGlobeView}
            shouldRenderAsOverflowList={true}
            isSingleMode={true}
            addTagButtonRenderer={!shouldHideModal && this.renderAddTagButton}
            className="delivery-attributes overflow-hidden full-width"
            shouldHideCrossIcons={true}
          />
        </div>
        <Icon
          icon={IconNames.CARET_DOWN}
          className="no-grow pointer"
          onClick={onToggle || toggleAssignGlobeDialog}
        />
      </div>
    )
  }

  private renderAddTagButton = () => {
    const { globeViewSetupStore, mapViewItemsSetupStore } = this.props
    const { isAssignGlobeDialogShown, hideAssignGlobeDialog } =
      globeViewSetupStore

    return (
      <div className="inline-block">
        {isAssignGlobeDialogShown && (
          <GlobeLocationAssociationModal
            onClose={hideAssignGlobeDialog}
            mapViewItemsSetupStore={mapViewItemsSetupStore}
            globeViewSetupStore={globeViewSetupStore}
          />
        )}
      </div>
    )
  }
}
