import {
  IConcreteDirectAddress,
  IConcreteDirectOrder,
  IConcreteDirectOrderCostEstimation,
  IConcreteDirectOrderItem,
  IGeoJson2DGeographicCoordinates,
} from '~/client/graph'
import ConcreteDirectOrderStatus from '~/client/src/shared/enums/ConcreteDirectOrderStatus'

import Localization from '../localization/LocalizationManager'

const ADDITIVE_ITEM_TYPE = 'ADDITIVE'
const EXTRA_CHARGE_ITEM_TYPE = 'EXTRA_CHARGE'

export default class ConcreteDirectOrder {
  public static fromDto(dto: IConcreteDirectOrder) {
    return new ConcreteDirectOrder(
      dto.id,
      dto.projectId,
      dto.concreteDirectId,
      dto.startDate,
      dto.externalId,
      dto.timeZone,
      dto.orderReference,
      dto.status as ConcreteDirectOrderStatus,
      dto.progressPercent,
      dto.jobsiteId,
      dto.timeWindow,
      dto.sentToExternalBackend,
      dto.systemId,
      dto.costEstimationCalculated,
      dto.pricingPlantId,
      dto.printBatchWeights,
      dto.isPaperless,
      dto.isReviewNeeded,
      dto.isReservation,
      dto.accountingReference,
      dto.isManualDispatchAllowed,
      dto.isWaitingForDispatchSystem,
      dto.location,
      dto.address,
      dto.orderItems,
      dto.orderByPhone,
      dto.orderByName,
      dto.orderCostEstimations,
      dto.deliveryInstructions,
    )
  }

  public constructor(
    public id: string,
    public projectId: string,
    public concreteDirectId: string,
    public startDate: number,
    public externalId: string,
    public timeZone: string,
    public orderReference: string,
    public status: ConcreteDirectOrderStatus,
    public progressPercent: number,
    public jobsiteId: string,
    public timeWindow: number,
    public sentToExternalBackend: boolean,
    public systemId: string,
    public costEstimationCalculated: boolean,
    public pricingPlantId: string,
    public printBatchWeights: boolean,
    public isPaperless: boolean,
    public isReviewNeeded: boolean,
    public isReservation: boolean,
    public accountingReference: string,
    public isManualDispatchAllowed: boolean,
    public isWaitingForDispatchSystem: boolean,
    public location?: IGeoJson2DGeographicCoordinates,
    public address?: IConcreteDirectAddress,
    public orderItems?: IConcreteDirectOrderItem[],
    public orderByPhone?: string,
    public orderByName?: string,
    public orderCostEstimations?: IConcreteDirectOrderCostEstimation[],
    public deliveryInstructions?: string,
  ) {}

  public get isRequested(): boolean {
    return this.status === ConcreteDirectOrderStatus.Requested
  }

  public get isUnconfirmed(): boolean {
    return this.status === ConcreteDirectOrderStatus.Unconfirmed
  }

  public get isPending(): boolean {
    return this.isRequested || this.isUnconfirmed
  }

  public get isCancelled(): boolean {
    return this.status === ConcreteDirectOrderStatus.Cancelled
  }

  public get isConfirmed(): boolean {
    return this.status === ConcreteDirectOrderStatus.Confirmed
  }

  public get isDelivering(): boolean {
    return this.status === ConcreteDirectOrderStatus.Delivering
  }

  public get isPaused(): boolean {
    return this.status === ConcreteDirectOrderStatus.Paused
  }

  public get isOnHold(): boolean {
    return this.status === ConcreteDirectOrderStatus.OnHold
  }

  public get isDone(): boolean {
    return this.status === ConcreteDirectOrderStatus.Completed
  }

  public get allMixTypes(): string {
    return this.orderItems?.map(item => item?.description).join('; ')
  }

  public get allExtraCharges(): string {
    const extraCharges =
      this.orderItems?.flatMap(i =>
        i?.orderItemAdditions
          ?.filter(a => a.item?.type === EXTRA_CHARGE_ITEM_TYPE)
          .map(a => a.item?.description),
      ) || []

    return extraCharges
      .filter((a, index) => a.indexOf(a) === index)
      .join(', ')
      .toLowerCase()
  }

  public get totalVolume(): string {
    return this.orderItems
      ?.map(item => `${item.quantity} ${item.item?.unityOfMeasure}`)
      .join(', ')
      .toLowerCase()
  }

  public get allSpacingInMinutes(): string {
    return this.orderItems
      ?.map(
        i =>
          `${i.spacingInMinutes} ${Localization.translator.min_shortMinutes}`,
      )
      .join(', ')
      .toLowerCase()
  }

  public get allSlumps(): string {
    return this.orderItems
      ?.map(i => i.slump)
      .join(', ')
      .toLowerCase()
  }

  public get allPourMethods(): string {
    return this.orderItems
      ?.map(i => i.pourMethod)
      .join(', ')
      .toLowerCase()
  }

  public get allPourElements(): string {
    return this.orderItems
      ?.map(i => i.pourElement)
      .join(', ')
      .toLowerCase()
  }

  public getMixTypeById = (orderItemId: string): string => {
    return this.orderItems?.find(item => item.id === orderItemId)?.item
      ?.description
  }

  public getAdditivesById = (orderItemId: string): string => {
    return this.orderItems
      ?.find(item => item.id === orderItemId)
      ?.orderItemAdditions?.filter(ad => ad.item?.type === ADDITIVE_ITEM_TYPE)
      .map(a => a.item?.description)
      .join(', ')
      .toLowerCase()
  }

  public getExtraChargesById = (orderItemId: string): string => {
    return this.orderItems
      ?.find(item => item.id === orderItemId)
      ?.orderItemAdditions?.filter(
        ad => ad.item?.type === EXTRA_CHARGE_ITEM_TYPE,
      )
      .map(a => a.item?.description)
      .join(', ')
      .toLowerCase()
  }
}
