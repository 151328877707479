import { observable } from 'mobx'

import { IPosition } from '~/client/graph'

import MapViewLocationIcon from '../../../enums/SitemapAttributeIcon'
import { areObjectsEqual } from '../../../utils/util'
import { IBoundingBox } from './MapViewItemBase'
import SitemapCircleProperties from './SitemapCircleProperties'
import SitemapIconProperties from './SitemapIconProperties'
import MapViewItemDrawnPart from './SitemapItemDrawnPart'
import SitemapLabelProperties, {
  DEFAULT_MATURIX_FONT_SIZE,
} from './SitemapLabelProperties'
import SitemapPolyLineProperties from './SitemapPolyLineProperties'
import SitemapRectangleProperties from './SitemapRectangleProperties'

export type SitemapShapeProperties =
  | SitemapPolyLineProperties
  | SitemapCircleProperties
  | SitemapRectangleProperties

export default class SitemapItemProperties {
  @observable public labelProperties: SitemapLabelProperties
  @observable public iconProperties: SitemapIconProperties
  @observable public shapeProperties: SitemapShapeProperties

  public constructor(
    iconProperties?: SitemapIconProperties,
    labelProperties?: SitemapLabelProperties,
    shapeProperties?: SitemapShapeProperties,
  ) {
    this.iconProperties = iconProperties || null
    this.labelProperties = labelProperties || null
    this.shapeProperties = shapeProperties || null
  }

  public isEqual(properties: SitemapItemProperties) {
    return (
      areObjectsEqual(
        this.iconProperties || {},
        properties.iconProperties || {},
      ) &&
      areObjectsEqual(
        this.labelProperties || {},
        properties.labelProperties || {},
      ) &&
      areObjectsEqual(
        this.shapeProperties || {},
        properties.shapeProperties || {},
      )
    )
  }

  public createRequiredProperties(isMaturixStation?: boolean) {
    if (!this.iconProperties) {
      this.iconProperties = new SitemapIconProperties()
    }

    let fontSize = null
    let isDisplayed = true

    if (isMaturixStation) {
      fontSize = DEFAULT_MATURIX_FONT_SIZE
      isDisplayed = false
    }
    if (!this.labelProperties) {
      this.labelProperties = new SitemapLabelProperties(
        fontSize,
        false,
        isDisplayed,
        this.iconProperties.calculateLabelPosition(),
      )
    }
  }

  public getBoundingBox(
    cWidth: number,
    cHeight: number,
    iconName: MapViewLocationIcon,
    name: string,
  ): IBoundingBox {
    const partBoundingBoxes = [
      this.iconProperties &&
        this.iconProperties.isDisplayed &&
        this.iconProperties.getBoundingBox(iconName, cWidth, cHeight),
      this.labelProperties &&
        this.labelProperties.isDisplayed &&
        this.labelProperties.getBoundingBox(name, cWidth, cHeight),
      this.shapeProperties &&
        this.shapeProperties.isDisplayed &&
        this.shapeProperties.getBoundingBox(cWidth, cHeight),
    ].filter(b => !!b)

    if (!partBoundingBoxes.length) {
      return null
    }

    const minX = Math.min(...partBoundingBoxes.map(b => b.x))
    const minY = Math.min(...partBoundingBoxes.map(b => b.y))
    const maxX = Math.max(...partBoundingBoxes.map(b => b.x + b.width))
    const maxY = Math.max(...partBoundingBoxes.map(b => b.y + b.height))

    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    }
  }

  public setPosition(x: number, y: number) {
    if (this.iconProperties) {
      this.iconProperties.setPosition({ x, y })
    }
    if (this.labelProperties) {
      this.labelProperties.setPosition({ x, y })
    }
  }

  public move(x: number, y: number): IPosition[] {
    if (this.iconProperties) {
      this.iconProperties.move(x, y)
    }
    if (this.labelProperties) {
      this.labelProperties.move(x, y)
    }
    if (this.shapeProperties) {
      this.shapeProperties.move(x, y)
    }
    return [this.iconProperties?.position || this.labelProperties?.position]
  }

  public get hasMultipleParts() {
    return (
      [this.iconProperties, this.labelProperties, this.shapeProperties].filter(
        p => p,
      ).length > 1
    )
  }

  public hasDrawnPart(part: MapViewItemDrawnPart) {
    switch (part) {
      case MapViewItemDrawnPart.Icon:
        return !!this.iconProperties
      case MapViewItemDrawnPart.Label:
        return !!this.labelProperties
      case MapViewItemDrawnPart.Shape:
        return !!this.shapeProperties
    }
  }

  public scale(originPoint: IPosition, scaleX: number, scaleY: number) {
    if (this.iconProperties) {
      this.iconProperties.scale(originPoint, scaleX, scaleY)
    }
    if (this.labelProperties) {
      this.labelProperties.scale(originPoint, scaleX, scaleY)
    }
    if (this.shapeProperties) {
      this.shapeProperties.scale(originPoint, scaleX, scaleY)
    }
    return this
  }

  public rotate(center: IPosition, angle: number) {
    if (this.iconProperties) {
      this.iconProperties.rotate(center, angle)
    }
    if (this.labelProperties) {
      this.labelProperties.rotate(center, angle)
    }
    if (this.shapeProperties) {
      this.shapeProperties.rotate(center, angle)
    }
    return this
  }

  public copy(): SitemapItemProperties {
    return new SitemapItemProperties(
      this.iconProperties && this.iconProperties.copy(),
      this.labelProperties && this.labelProperties.copy(),
      this.shapeProperties && this.shapeProperties.copy(),
    )
  }
}
