import * as React from 'react'

import { observer } from 'mobx-react'

import DetailListItem from '~/client/src/shared/components/DetailListItem'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Activity from '~/client/src/shared/models/Activity'

import ActivityStatusIndicator from '../../../ActivityItemContentWithStatusUpdate/componets/ActivityStatusIndicator'

// translated

interface IProps {
  activity: Activity
}

@observer
export default class StatusDetails extends React.Component<IProps> {
  public render() {
    return (
      <DetailListItem category={Localization.translator.status}>
        <ActivityStatusIndicator
          activity={this.props.activity}
          containerClassName="row w-fit-content"
        />
      </DetailListItem>
    )
  }
}
