import { action, observable } from 'mobx'
import socketClient, { Socket } from 'socket.io-client'

import { isNotificationSupportedInEnv } from '../../utils/util'
import ChatEvent from './events'
import { IChat, IContact, IMessage, IMessageReceivedEvent } from './types'

const chatBase = '/chat-service'

export default class ChatService {
  @observable public allUnreadMessagesCount: number = 0

  public socket: Socket = null
  public userId: string = null

  public constructor(
    private readonly getValidAccessToken: () => Promise<string>,
  ) {}

  public async init(userId: string) {
    this.userId = userId

    this.socket = socketClient('', {
      path: `${chatBase}/socket.io/`,
      query: { userId },
      auth: async cb => {
        cb({ token: await this.getValidAccessToken() })
      },
    })

    this.socket.on(ChatEvent.ConnectError, err => {
      console.warn(`Chat [${ChatEvent.ConnectError}] due to ${err.message}`)
    })

    this.socket.on(
      ChatEvent.MessageReceived,
      ({ message, channelName }: IMessageReceivedEvent) => {
        if (message.authorId === this.userId) {
          return
        }

        this.allUnreadMessagesCount++

        if (isNotificationSupportedInEnv()) {
          Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
              this.getContactById(message.authorId).then(contact => {
                navigator.serviceWorker.ready.then(registration => {
                  registration.showNotification(
                    channelName || contact.fullName,
                    { body: message.text, icon: contact.avatarUrl },
                  )
                })
              })
            }
          })
        }
      },
    )

    this.allUnreadMessagesCount = await this.getAllUnreadMessagesCount()
  }

  public async getUnreadMessagesCountByChannel(
    channelId: string,
  ): Promise<number> {
    const res = await this.fetchChatApi(
      `messages/unread-count/${channelId}`,
      'POST',
      {
        'Content-Type': 'application/json',
      },
      JSON.stringify({
        userId: this.userId,
      }),
    )

    return res.ok ? Number(await res.json()) : 0
  }

  public async getAllUnreadMessagesCount(): Promise<number> {
    const res = await this.fetchChatApi(`messages/unread-count/${this.userId}`)
    return res.ok ? Number(await res.json()) : 0
  }

  public async getMessagesByChannel(channelId: string): Promise<IMessage[]> {
    const res = await this.fetchChatApi(`messages/channel/${channelId}`)
    return res.ok ? res.json() : []
  }

  public async getMessagesByIds(messageIds: string[]): Promise<IMessage[]> {
    const res = await this.fetchChatApi(
      'messages',
      'POST',
      { 'Content-Type': 'application/json' },
      JSON.stringify({ messageIds }),
    )
    return res.ok ? res.json() : []
  }

  public async getMessageById(messageId: string): Promise<IMessage> {
    const res = await this.fetchChatApi(`messages/${messageId}`)
    return res.ok ? res.json() : null
  }

  public async getContactById(contactId: string): Promise<IContact> {
    const res = await this.fetchChatApi(`contacts/${contactId}`)
    return res.ok ? await res.json() : null
  }

  public async getContactByIds(contactIds: string[]): Promise<IContact[]> {
    const res = await this.fetchChatApi(
      'contacts',
      'POST',
      { 'Content-Type': 'application/json' },
      JSON.stringify({
        contactIds,
      }),
    )

    return res.ok ? res.json() : []
  }

  public async getInbox(): Promise<IChat[]> {
    const res = await this.fetchChatApi(`chats/${this.userId}`)
    return res.ok ? res.json() : []
  }

  public async getChannelIdByRecipient(recipientId: string): Promise<string> {
    const res = await this.fetchChatApi(`chats/${this.userId}/${recipientId}`)
    return res.ok ? res.json() : null
  }

  public async getOnlineContacts(): Promise<string[]> {
    const res = await this.fetchChatApi('online-contacts')
    return res.ok ? res.json() : []
  }

  public async sendMessageToChannel(
    channelId: string,
    text: string,
    fakeId: string,
  ): Promise<string> {
    const res = await this.fetchChatApi(
      `messages/channel/${channelId}`,
      'POST',
      {
        'Content-Type': 'application/json',
      },
      JSON.stringify({
        text,
        authorId: this.userId,
        fakeId,
      }),
    )

    return res.ok ? await res.json() : null
  }

  public async setLastReadMessageOnChannel(
    message: IMessage,
    unreadCount: number = 1,
  ): Promise<void> {
    if (!message || message.authorId === this.userId) {
      return
    }

    const res = await this.fetchChatApi(
      `chats/set-last-read-message`,
      'POST',
      {
        'Content-Type': 'application/json',
      },
      JSON.stringify({
        channelId: message.channelId,
        messageId: message.id,
        userId: this.userId,
      }),
    )

    if (res.ok) {
      this.subtractUnreadCount(unreadCount)
    }
  }

  public async createChat(
    name: string,
    isGroup: boolean,
    memberIds: string[],
    avatarUrl?: string,
  ): Promise<string> {
    const res = await this.fetchChatApi(
      'chats',
      'POST',
      {
        'Content-Type': 'application/json',
      },
      JSON.stringify({
        name,
        isGroup,
        memberIds,
        avatarUrl,
        creatorId: this.userId,
      }),
    )

    if (res.ok) {
      return res.json()
    }

    throw new Error()
  }

  public async updateChat(chat: IChat): Promise<void> {
    const res = await this.fetchChatApi(
      'chats',
      'PUT',
      {
        'Content-Type': 'application/json',
      },
      JSON.stringify({
        chat,
      }),
    )

    if (!res.ok) {
      throw new Error('Error')
    }
  }

  @action.bound
  public subtractUnreadCount(justReadMessagesCount: number) {
    if (!justReadMessagesCount) {
      return
    }

    const newCount = this.allUnreadMessagesCount - justReadMessagesCount
    this.allUnreadMessagesCount = Math.max(newCount, 0)
  }

  public disconnect() {
    this.socket?.close()
  }

  private async fetchChatApi(
    route: string,
    method: string = 'GET',
    headers = {},
    body = undefined,
  ): Promise<Response> {
    const accessToken = await this.getValidAccessToken()

    return fetch(`${chatBase}/${route}`, {
      method,
      body,
      headers: { ...headers, authorization: `Bearer ${accessToken}` },
    })
  }
}
