import * as React from 'react'

import { observer } from 'mobx-react'

import CommonThreadEntry from '~/client/src/shared/components/CommonThread/components/CommonThreadEntry'
import EntryTitle from '~/client/src/shared/components/CommonThread/components/EntryTitle'
import ActivityLogEntry from '~/client/src/shared/components/Entry/Entry'
import EntryLeftCol from '~/client/src/shared/components/Entry/EntryLeftCol'
import IMessage from '~/client/src/shared/models/Message/IMessage'

import FileInputBase from '../FileInput/FileInput'

import './CommonThread.scss'

interface IModel {
  id: string
}

interface IThread {
  isPhotoThread?: boolean
  showImages?: boolean
  hideThread?: boolean
  showFooterLine?: boolean
  shouldHideFooterCircle?: boolean
  shouldSetBottomPaddingToZero?: boolean
  messages: IMessage[]
  buttonsElement?: JSX.Element
  threadIcon: JSX.Element
  threadClass: string
  caption: string
  subCaption?: string
  replyCaption?: string
  model: IModel
  rightAlign?: boolean
  shouldHideFooter?: boolean
  isDeliveryColumn?: boolean
  isActivityColumn?: boolean
  customPhotoClick?: (e: React.MouseEvent<HTMLElement>, url?: string) => void
  isLastThreadInLog?: boolean
  FileInputType: typeof FileInputBase
}

@observer
export default class CommonThread extends React.Component<IThread> {
  public render() {
    const {
      threadClass,
      model,
      caption,
      subCaption,
      hideThread,
      rightAlign,
      customPhotoClick,
      shouldSetBottomPaddingToZero,
      shouldHideFooter,
      isDeliveryColumn,
    } = this.props

    return (
      <>
        {!hideThread && !isDeliveryColumn && (
          <ActivityLogEntry
            key={model.id + '-header'}
            customClass={'y-stretch ' + threadClass}
            paddingBottom={+0}
            _onClick={customPhotoClick}
          >
            {!rightAlign && (
              <EntryLeftCol>{this.renderLeftColumnHeader()}</EntryLeftCol>
            )}
            <div className="col">
              <EntryTitle text={caption} />
              {subCaption && <EntryTitle text={subCaption} uppercase={false} />}
            </div>
            {rightAlign && (
              <EntryLeftCol>{this.renderLeftColumnHeader()}</EntryLeftCol>
            )}
          </ActivityLogEntry>
        )}
        {this.renderThreadEntries(model)}
        {!shouldHideFooter && !isDeliveryColumn && (
          <ActivityLogEntry
            key={model.id + '-footer'}
            customClass={
              'y-stretch ' +
              (hideThread ? 'invisible-thread' : threadClass) +
              (shouldSetBottomPaddingToZero ? ' thread-without-padding' : '')
            }
            _onClick={customPhotoClick}
          >
            {!rightAlign && (
              <EntryLeftCol>{this.renderLeftColumnFooter()}</EntryLeftCol>
            )}
            <div className="col">{this.renderRightColumnFooter()}</div>
            {rightAlign && (
              <EntryLeftCol>{this.renderLeftColumnFooter()}</EntryLeftCol>
            )}
          </ActivityLogEntry>
        )}
      </>
    )
  }

  private renderThreadEntries(model: IModel) {
    const {
      showImages,
      hideThread,
      threadClass,
      customPhotoClick,
      messages,
      rightAlign,
      replyCaption,
      isPhotoThread,
      FileInputType,
      isLastThreadInLog,
      isDeliveryColumn,
      isActivityColumn,
    } = this.props

    return messages.map((message, index) => {
      const isLastEntry = index === messages.length - 1

      return (
        <CommonThreadEntry
          message={message}
          isPhotoThread={isPhotoThread}
          showImages={showImages}
          hideThread={hideThread}
          rightAlign={rightAlign}
          replyCaption={index > 0 ? replyCaption : null}
          threadClass={threadClass}
          customPhotoClick={customPhotoClick}
          key={model.id + '-' + message.id + '-' + index}
          FileInputType={FileInputType}
          isLastEntryInLog={isLastEntry && isLastThreadInLog}
          isDeliveryColumn={isDeliveryColumn}
          isActivityColumn={isActivityColumn}
        />
      )
    })
  }

  private renderLeftColumnHeader() {
    return (
      <div className="relative-block thread-line-centred">
        <div className="text center absolute-block">
          {this.props.threadIcon}
        </div>
        <div className="thread-vertical-line" />
      </div>
    )
  }

  private renderLeftColumnFooter() {
    const { showFooterLine, buttonsElement, shouldHideFooterCircle } =
      this.props
    return (
      <div className="relative-block thread-line-centred">
        {!buttonsElement && showFooterLine && (
          <div className="thread-horizontal-line-cross" />
        )}
        {!shouldHideFooterCircle && (
          <div className="absolute-block circle-container">
            <div className="circle" />
          </div>
        )}
        <div className="thread-vertical-line thread-vertical-line-semi-height" />
      </div>
    )
  }

  private renderRightColumnFooter() {
    const { showFooterLine, buttonsElement } = this.props
    return (
      <>
        {buttonsElement ||
          (showFooterLine && (
            <div className="thread-horizontal-line-container">
              <div className="thread-horizontal-line" />
            </div>
          ))}
      </>
    )
  }
}
