import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import {
  AndOrOperator,
  IsNotOperator,
  LogicalOperator,
  isNotOperatorValues,
} from '~/client/src/shared/models/LogicOperation'
import Tag, { ITag } from '~/client/src/shared/models/Tag'
import ExpressionParser, {
  ExpressionElement,
} from '~/client/src/shared/utils/ExpressionParser/ExpressionParser'

import CategoryLogicalOperator from './CategoryLogicalOperator'

// localization: translated

interface IProps {
  className?: string
  categoryOperator?: LogicalOperator
  isNotOperatorValue: IsNotOperator
  categoryNameAndIconElement: JSX.Element
  categoryElements: ExpressionElement[]
  noElementsTitle?: string

  onAddNewElement?: () => void
  onNotOperatorChange?: (operator: IsNotOperator) => void
  onTagRemove?: (tagId: string) => void
}

@observer
export default class CategorySelectorRow extends React.Component<IProps> {
  public render() {
    const {
      className,
      categoryOperator,
      categoryNameAndIconElement,
      isNotOperatorValue,
      categoryElements,
      onNotOperatorChange,
    } = this.props

    const shouldAllowChanging = !!categoryElements?.length

    return (
      <div
        className={classList({
          'row y-start ml5 mb10 bic-rule-category-row': true,
          [className]: !!className,
        })}
      >
        <div className="row no-flex y-center full-height bic-rule-category-col">
          <CategoryLogicalOperator
            className="ml8"
            selectedOperator={categoryOperator}
          />
          <div className="row no-grow nowrap ml8 px5 h28 brada4 ba-light-input-border">
            {categoryNameAndIconElement}
          </div>
        </div>

        <div className="row no-flex y-center full-height ml8">
          <CategoryLogicalOperator
            selectedOperator={isNotOperatorValue}
            logicOperatorsList={isNotOperatorValues}
            isSelectable={shouldAllowChanging}
            onChange={onNotOperatorChange}
          />
        </div>

        <div className="row y-center full-height">
          <div className="row wrap ml3 px5 text large nowrap">
            {this.renderCategoryElements()}
            {this.renderPlusButton()}
          </div>
        </div>
      </div>
    )
  }

  private renderCategoryElements(): JSX.Element | JSX.Element[] {
    const { categoryElements, noElementsTitle, onAddNewElement } = this.props

    if (!categoryElements?.length) {
      return (
        <div className="row pointer no-flex h28" onClick={onAddNewElement}>
          <span className="mr5">{noElementsTitle}</span>
          <Icon icon={IconNames.CARET_DOWN} className="text light" />
        </div>
      )
    }

    return categoryElements.map((element, index) => {
      let node: any = element

      switch (true) {
        case ExpressionParser.isTagElement(element):
          node = this.renderTag(element as Tag)
          break

        case ExpressionParser.isLogicalOperationElement(element):
          node = (
            <CategoryLogicalOperator
              selectedOperator={element as AndOrOperator}
            />
          )
          break
      }

      return (
        <span
          key={index}
          className="inline-flex row x-center y-center no-grow mr4 expr-element h28"
        >
          {node}
        </span>
      )
    })
  }

  private renderTag(tag: ITag): JSX.Element {
    const tagName = tag.name || Localization.translator.unknownDeletedObject

    return (
      <SitemapAttributeTag
        dataObject={tag as LocationBase}
        shouldShowAsTag={true}
        isRemovable={true}
        onRemove={this.props.onTagRemove}
        contentContainerClassName="text-ellipsis py2"
      >
        <span title={tagName} className="text large">
          {tagName}
        </span>
      </SitemapAttributeTag>
    )
  }

  private renderPlusButton(): JSX.Element {
    return (
      <Icon
        className="row no-grow bg-palette-blue-scale br-rounded pointer text white ml10"
        icon={IconNames.SMALL_PLUS}
        onClick={this.props.onAddNewElement}
      />
    )
  }
}
