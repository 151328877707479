import { IOpenWeatherForecast } from '~/client/graph'

export default class WeatherForecastInfo {
  public static fromDto(dto: IOpenWeatherForecast) {
    return new WeatherForecastInfo(
      dto.forecastTime * 1000,
      dto.mainParams.temperature,
      dto.mainParams.minTemperature,
      dto.mainParams.maxTemperature,
      dto.windParams.speed,
      dto.windParams.degrees,
      dto.conditionParams?.[0].iconId,
      dto.conditionParams?.[0].description,
      dto.probabilityOfPrecipitation * 100,
      dto.mainParams.humidity,
    )
  }

  public static convertToDailyForecast(
    date: number,
    hourlyForecast: WeatherForecastInfo[],
  ): WeatherForecastInfo {
    const middleElement = Math.floor(hourlyForecast.length / 2)

    return new WeatherForecastInfo(
      date,

      Math.round(
        hourlyForecast.reduce((a, b) => a + b.currentTemp, 0) /
          hourlyForecast.length,
      ),

      Math.min(...hourlyForecast.map(f => Math.round(f.minTemp))),

      Math.max(...hourlyForecast.map(f => Math.round(f.maxTemp))),

      Math.round(
        hourlyForecast.reduce((a, b) => a + b.windSpeed, 0) /
          hourlyForecast.length,
      ),

      Math.round(
        hourlyForecast.reduce((a, b) => a + b.windDegree, 0) /
          hourlyForecast.length,
      ),

      hourlyForecast[middleElement].weatherIconCode,

      hourlyForecast[middleElement].weatherDescription,

      Math.round(
        hourlyForecast.reduce((a, b) => a + b.probabilityOfPrecipitation, 0) /
          hourlyForecast.length,
      ),

      Math.round(
        hourlyForecast.reduce((a, b) => a + b.humidity, 0) /
          hourlyForecast.length,
      ),
    )
  }

  public constructor(
    public date: number,
    public currentTemp: number,
    public minTemp: number,
    public maxTemp: number,
    public windSpeed: number,
    public windDegree: number,
    public weatherIconCode: string,
    public weatherDescription: string,
    public probabilityOfPrecipitation: number,
    public humidity: number,
  ) {}
}
