import * as React from 'react'

import { Dialog } from '@blueprintjs/core'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import { Loader } from '~/client/src/shared/components/Loader'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import { DELETE_SITEMAP } from '~/client/src/shared/stores/EventStore/eventConstants'

import MapViewSetUpStore from '../../MapViewSetUp.store'
import { site } from '../../stores/SitemapViewsSetup.store'

import './DeleteSitemapConfirmDialog.scss'

export interface IProps {
  store: MapViewSetUpStore
  state?: DesktopInitialState
}

const areYouSureYouWantToDeleteThisMap =
  'Are you sure you want to delete this map?'

@inject('state')
@observer
export default class DeleteSitemapConfirmDialog extends React.Component<IProps> {
  public render() {
    const { sitemapsSetupStore, sitemapViewsSetupStore } = this.props.store
    const { deletableSitemap, hideSitemapDeleteConfirmDialog } =
      sitemapsSetupStore

    if (!deletableSitemap) {
      return null
    }

    const location =
      sitemapViewsSetupStore.sitemapToLocation[deletableSitemap?.id]
    return (
      <Dialog
        isOpen={!!deletableSitemap}
        title=""
        isCloseButtonShown={true}
        canOutsideClickClose={false}
        onClose={hideSitemapDeleteConfirmDialog}
        className="confirm-dialog delete-sitemap hidden-header"
      >
        <div className="col">
          <div className="text bold extra-large pa20">
            {areYouSureYouWantToDeleteThisMap}
          </div>
          <div className="row px20 mb30">
            <div className="col full-height sitemap-image-holder x-center no-grow">
              <DesktopFileInput
                id={deletableSitemap?.id}
                name=""
                value={deletableSitemap.filledImage}
                isReadonly={true}
                textClassName="hint"
                shouldHideIconAndOutline={true}
              />
            </div>
            <div className="col full-height ml20 mw270">
              <div className="text extra-large bold text-ellipsis">
                {deletableSitemap.name}
              </div>
              <div>
                <SitemapAttributeTag
                  dataObject={(location || site) as LocationBase}
                  shouldShowAsTag={true}
                  contentContainerClassName="text-ellipsis py2"
                >
                  <span title={location?.name || site}>
                    {location?.name || site}
                  </span>
                </SitemapAttributeTag>
              </div>
            </div>
          </div>
          {this.renderFooter()}
        </div>
      </Dialog>
    )
  }

  private renderFooter() {
    const {
      store: {
        sitemapsSetupStore: {
          applySitemapDeleteConfirmDialog,
          hideSitemapDeleteConfirmDialog,
        },
      },
      state: { loading },
    } = this.props

    const isSitemapBeingDeleted = loading.get(DELETE_SITEMAP)
    return (
      <div className="bt-input-border row x-around dialog-footer">
        <div
          className="text large ml20 mr10 row x-center y-center pointer action-button mt5 mb5"
          onClick={hideSitemapDeleteConfirmDialog}
        >
          {Localization.translator.cancel}
        </div>
        <div
          className={classList({
            'bg-blue-bright brada5 text white large mr20 ml10 row x-center y-center pointer action-button mt5 mb5':
              true,
            'inactive-element': isSitemapBeingDeleted,
          })}
          onClick={applySitemapDeleteConfirmDialog}
        >
          {isSitemapBeingDeleted ? <Loader /> : Localization.translator.delete}
        </div>
      </div>
    )
  }
}
