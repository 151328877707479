import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import SortOrder from '~/client/src/shared/enums/SortOrder'

import ViewModes from '../../../enums/ViewModes'
import DesktopInitialState from '../../../stores/DesktopInitialState'
import SortingCell from './ActivityGanttOrListView/components/ActivityListView/components/SortingCell'

interface IProps {
  className?: string
  sortKey?: string
  isSortingDesc?: boolean
  onSort?: (sortKey: string) => void
  hideCompany: boolean
  currentSortingOrder: SortOrder
  state?: DesktopInitialState
}

const ACTIVITY_ID_SORT_KEY = 'activity.id'
const ACTIVITY_NAME_SORT_KEY = 'activity.name'
const COMPANY_SORT_KEY = 'company'

@inject('state')
@observer
export default class ActivityNameHeader extends React.PureComponent<IProps> {
  public render() {
    const {
      sortKey,
      isSortingDesc,
      onSort,
      className,
      hideCompany,
      currentSortingOrder,
      state,
    } = this.props
    const headerClassName =
      'activity-name-header text row text bb-light-cool-grey pl35 br-light-grey ' +
      (className || '')
    const isActive = SortOrder.DEFAULT !== currentSortingOrder
    const isGanttViewMode = state.activityList.viewMode === ViewModes.Gantt

    return (
      <div className={headerClassName}>
        <div
          className={classList({
            'row text uppercase no-outline-container cell cell-header': true,
            'pointer lp05 no-select full-height activity-id-caption': true,
          })}
        >
          <SortingCell
            isActive={isActive && sortKey === ACTIVITY_ID_SORT_KEY}
            isDesc={isSortingDesc}
            sortKey={ACTIVITY_ID_SORT_KEY}
            onSort={onSort}
          >
            <div className="pl20 activity-id-caption-item row">ACTIVITY ID</div>
          </SortingCell>
        </div>
        <div
          className={classList({
            'mw400 row text uppercase no-outline-container cell cell-header':
              true,
            'pointer lp05 no-select full-height activity-name-caption': true,
          })}
        >
          <SortingCell
            isActive={isActive && sortKey === ACTIVITY_NAME_SORT_KEY}
            isDesc={isSortingDesc}
            sortKey={ACTIVITY_NAME_SORT_KEY}
            onSort={onSort}
          >
            <div className="pl30 activity-name-header-item row">
              ACTIVITY NAME
            </div>
          </SortingCell>
        </div>

        {!hideCompany && !isGanttViewMode && (
          <div
            className={classList({
              'mw160 row text uppercase no-outline-container cell cell-header':
                true,
              'pointer lp05 no-select full-height activity-company-caption':
                true,
            })}
          >
            <SortingCell
              isActive={isActive && sortKey === COMPANY_SORT_KEY}
              isDesc={isSortingDesc}
              sortKey={COMPANY_SORT_KEY}
              onSort={onSort}
            >
              <div className="activity-name-header-item row">COMPANY</div>
            </SortingCell>
          </div>
        )}
      </div>
    )
  }
}
