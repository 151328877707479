import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { classList } from 'react-classlist-helper'

import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import SitemapItem from '~/client/src/shared/models/SitemapItem'
import { NOOP } from '~/client/src/shared/utils/noop'

import SitemapAttributeIconComponent from './SitemapAttributeIcon'

import Colors from '~/client/src/shared/theme.module.scss'

import './SitemapAttributeTag.scss'

export interface IProps {
  dataObject?: LocationBase
  sitemapItem?: SitemapItem
  className?: string
  contentContainerClassName?: string
  shouldShowAsTag: boolean
  isBordered?: boolean
  isRemovable?: boolean
  onRemove?: (tagId: string) => void
  iconSize?: number
}

export default class SitemapAttributeTag extends React.Component<IProps> {
  public static defaultProps = {
    contentContainerClassName: '',
    onRemove: NOOP,
  }

  public render() {
    const {
      dataObject,
      sitemapItem,
      shouldShowAsTag,
      children,
      className,
      contentContainerClassName,
      isBordered,
      isRemovable,
      iconSize,
    } = this.props

    const dataSource = dataObject || sitemapItem
    const color = dataSource?.color || Colors.neutral0

    const tagStyles: React.CSSProperties = {}
    if (isBordered) {
      tagStyles.border = `1px solid ${color}`
    }

    return (
      <div
        className={classList({
          'hierarchy-delivery-attribute-tag px4 brada3 relative': true,
          [className]: !!className,
        })}
        style={tagStyles}
      >
        {!!dataSource && (
          <div className="hierarchy-delivery-attribute-tag-icon no-grow row y-center pr4">
            <SitemapAttributeIconComponent
              dataObject={dataObject}
              sitemapItem={sitemapItem}
              customProps={{ size: iconSize }}
            />
          </div>
        )}
        <div
          className={classList({
            'hierarchy-delivery-attribute-tag-content': true,
            [contentContainerClassName]: true,
          })}
        >
          {children}
        </div>
        {isRemovable && (
          <div
            className="row hierarchy-delivery-attribute-tag-remove-icon pointer"
            onClick={this.handleRemove.bind(this, dataSource.id)}
          >
            <Icon icon={IconNames.SMALL_CROSS} />
          </div>
        )}
        {shouldShowAsTag && (
          <div className="tag-background" style={{ background: color }} />
        )}
      </div>
    )
  }

  private handleRemove(tagId: string, e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()
    this.props.onRemove(tagId)
  }
}
