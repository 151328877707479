import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ActionBarInput from '~/client/src/shared/components/ActionBarInput/ActionBarInput'
import ActionBarInputWithMentions from '~/client/src/shared/components/ActionBarInputWIthMentions/ActionBarInputWithMentions'
import MentionTriggerButton from '~/client/src/shared/components/ActionBarInputWIthMentions/components/MentionTriggerButton'
import ThreadTypes from '~/client/src/shared/components/ActivityDetails/components/SlackBar/components/ThreadTypes'
import CameraUploadButton from '~/client/src/shared/components/CameraUploadButton/CameraUploadButton'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Activity from '~/client/src/shared/models/Activity'
import UserProject from '~/client/src/shared/models/UserProject'
import ActivityAssignmentsStore from '~/client/src/shared/stores/domain/ActivityAssignments.store'
import ActivityFollowingsStore from '~/client/src/shared/stores/domain/ActivityFollowings.store'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

import './SlackBar.scss'

// translated

interface IActionBar {
  activity: Activity

  selectedCompany?: string
  userActiveProjectSettings: UserProject

  slackBarStore?: SlackBarStore
  common?: CommonStore
  activityAssignmentsStore?: ActivityAssignmentsStore
  activityFollowingsStore?: ActivityFollowingsStore
}

@inject(
  'common',
  'slackBarStore',
  'activityAssignmentsStore',
  'activityFollowingsStore',
)
@observer
export default class SlackBarComponent extends React.Component<IActionBar> {
  public isTextInputFocused: boolean = false
  public actionBarInput: ActionBarInput
  public store: SlackBarStore
  public fileInput: HTMLInputElement

  public constructor(props: IActionBar) {
    super(props)
    this.store = props.slackBarStore
  }

  public componentWillUnmount() {
    this.store.reset()
  }

  public sendClicked = async (evt: React.SyntheticEvent) => {
    evt.preventDefault()
    await this.store.send()
  }

  @action.bound
  public draftNoteInputChanged(evt: React.ChangeEvent<HTMLInputElement>) {
    this.store.draftMessage = evt.target.value
  }

  @action.bound
  public handleImageInputChange() {
    this.store.setPreviewImageFromInput(this.fileInput)
  }

  @action.bound
  public updateOptionClicked() {
    const { activity, selectedCompany, common } = this.props
    common.displayBulkStatusUpdateView(activity, selectedCompany)
  }

  @action.bound
  public cacheInputFocusedState() {
    const { textarea } = this.actionBarInput
    this.isTextInputFocused = document.activeElement === textarea
  }

  public render() {
    const { isActivityUpdateAvailable, isActivityNotesAvailable } =
      this.props.userActiveProjectSettings

    return (
      <div
        className={classList({
          'slack-bar pa10': true,
          pa20: isActivityUpdateAvailable && !isActivityNotesAvailable,
        })}
      >
        <ThreadTypes />
        <form onSubmit={this.sendClicked} ref={this.ref('slackBar')}>
          {isActivityNotesAvailable && this.renderInput()}
          <div
            className={classList({
              'row slack-bar-btns relative': true,
              'x-between':
                !isActivityUpdateAvailable && isActivityNotesAvailable,
            })}
          >
            {isActivityNotesAvailable && this.renderLeftButtons()}
            {isActivityUpdateAvailable && this.renderCenterButtons()}
            {isActivityNotesAvailable && this.renderRightButtons()}
          </div>
        </form>
      </div>
    )
  }

  private ref(name: string) {
    return (ref: any) => {
      this[name] = ref
    }
  }

  private renderInput() {
    const { activity, activityAssignmentsStore, activityFollowingsStore } =
      this.props
    const {
      setDraftMessage,
      draftMessage,
      decodedDraftMessage,
      activateMentionAction,
      deactivateMentionAction,
      mentionTags,
    } = this.store

    return (
      <ActionBarInputWithMentions
        ref={this.ref('actionBarInput')}
        value={draftMessage}
        plainText={decodedDraftMessage}
        mentions={mentionTags}
        onChange={setDraftMessage}
        placeholder={this.actionBarInputPlaceholder}
        entityId={activity.code}
        assignmentsStore={activityAssignmentsStore}
        followingsStore={activityFollowingsStore}
        onSuggestionsPopupShow={activateMentionAction}
        onSuggestionsPopupHide={deactivateMentionAction}
      />
    )
  }

  private renderLeftButtons() {
    const { shouldShowPreview, showMenuPopup } = this.store
    const { triggerMentionActionActivation, isMentionActionActive } =
      this.props.slackBarStore

    const hiddenOnPreview = {
      hidden: shouldShowPreview,
    }

    return (
      <div className="row no-grow">
        <button
          type="button"
          className={classList(hiddenOnPreview)}
          onTouchStart={this.cacheInputFocusedState}
          onClick={showMenuPopup}
        >
          <Icons.WorkflowsBadge />
        </button>
        <MentionTriggerButton
          isActive={isMentionActionActive}
          onClick={triggerMentionActionActivation}
        />
      </div>
    )
  }

  private renderCenterButtons() {
    return (
      <div className="row">
        <button
          type="button"
          className={classList({
            'center-action-button absolute ma0': true,
            hidden: this.store.shouldShowPreview,
          })}
          onTouchStart={this.cacheInputFocusedState}
          onClick={this.updateOptionClicked}
        >
          <Icons.StatusBadge />
        </button>
      </div>
    )
  }

  private renderRightButtons() {
    const { isSending } = this.store

    return (
      <div className="row no-grow">
        <CameraUploadButton store={this.props.slackBarStore} />
        <button
          className={classList({
            'slack-bar-send-btn': true,
            disabled: isSending,
          })}
          type="submit"
          disabled={isSending}
        >
          {Localization.translator.send}
        </button>
      </div>
    )
  }

  private get actionBarInputPlaceholder() {
    return this.store.isImageUploading
      ? Localization.translator.uploadingImage +
          `... ${this.store.uploadProgress}%`
      : Localization.translator.addANoteToThisActivityLog
  }
}
