import * as React from 'react'

import './ActionBarBottomMenu.scss'

export interface IProps {
  isOpen: boolean
}

export default class ActionBarBottomMenu extends React.Component<IProps> {
  public render() {
    return (
      <div
        className="action-bar-bottom-menu"
        style={{ height: this.props.isOpen ? 'auto' : 0 }}
      >
        {this.props.children}
      </div>
    )
  }
}
