import React from 'react'

import { Switch } from '@blueprintjs/core'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { PermitFieldType } from '~/client/graph'
import CompactHeaderBar from '~/client/src/shared/components/CompactHeaderBar/CompactHeaderBar'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import StruxhubSelect from '~/client/src/shared/components/StruxhubInputs/StruxhubSelect'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'

import SubmitButton from '../../../SubmitButton'
import AnalyticsSetupStore from '../../AnalyticsSetup.store'
import ConfigureColumnModalStore, {
  SummarizedFormFieldType,
} from './ConfigureColumnModal.store'

interface IProps {
  permitTypesStore?: PermitTypesStore
  analyticsSetupStore: AnalyticsSetupStore
  onClose?: () => void
}

@inject('permitTypesStore')
@observer
export default class ConfigureColumnModal extends React.Component<IProps> {
  private store: ConfigureColumnModalStore = null

  public constructor(props: IProps) {
    super(props)
    this.store = new ConfigureColumnModalStore(
      props.permitTypesStore,
      props.analyticsSetupStore,
    )
  }

  public render() {
    const {
      activePermitType,
      fields,
      isSummarizedFormOpened,
      numberFields,
      openSummarizedForm,
      closeSummarizedForm,
      getFieldLabelById,
      isPermitTypeFieldAvailable,
      togglePermitTypeField,
      editSummaryField,
      addEditSummaryField,
      deleteSummaryField,
      summarizedFormFields,
    } = this.store
    const { selectedReportTypeFields } = this.props.analyticsSetupStore

    return (
      <div className="analytics-setting-sidebar absolute scrollable bg-white">
        <CompactHeaderBar
          titleCaption={activePermitType.name}
          titleIconEl={<Icons.GeneralForm className="row no-grow" />}
          onCloseClick={this.props.onClose}
        />
        <div className="relative pb15">
          {this.props.analyticsSetupStore.updating && (
            <div className="absolute full-height full-width">
              <div className="row y-center full-height full-width absolute modal-overlay">
                <Loader
                  className="fixed-loader col x-center"
                  hint={`${Localization.translator.updating}...`}
                />
              </div>
            </div>
          )}
          <div className="col px15">
            {fields.map(field => (
              <div
                className="row y-center mb10"
                key={field.id + field.checkListItemId}
              >
                {field.type === PermitFieldType.Section ? (
                  <div className="row section-separator overflow-hidden py15">
                    <hr className="ba-none bt-light-input-border min-width30" />
                    <span className="text small light no-white-space-wrap bold lp15 uppercase w-fit-content mx10 ellipsis flex-grow3">
                      {field.label}
                    </span>
                    <hr className="ba-none bt-light-input-border min-width30" />
                  </div>
                ) : (
                  <span className="pr20">{field.label}</span>
                )}
                {field.type !== PermitFieldType.Section && (
                  <Switch
                    className="primary-blue-switch no-outline-container mb0"
                    alignIndicator="center"
                    checked={isPermitTypeFieldAvailable(
                      field.id,
                      field.checkListItemId,
                    )}
                    onChange={togglePermitTypeField.bind(
                      null,
                      field.id,
                      field.checkListItemId,
                    )}
                  />
                )}
              </div>
            ))}
          </div>
          {!!selectedReportTypeFields.summaryFields.length && (
            <div className="col pl15">
              <h4>{Localization.translator.summaryColumns}</h4>
              <div className="col">
                {selectedReportTypeFields.summaryFields.map(
                  (summaryField, i) => (
                    <div
                      key={summaryField.name + summaryField.fieldId}
                      className="row y-center x-between"
                    >
                      <div className="text">{summaryField.name}</div>
                      <div className="text">
                        {getFieldLabelById(summaryField.fieldId)}
                      </div>
                      <div className="row no-flex summary-actions x-end y-center">
                        <Icons.Edit
                          onClick={editSummaryField.bind(null, summaryField, i)}
                          className="pr10 pointer"
                        />
                        <Icons.Delete
                          className="pointer pr10"
                          onClick={deleteSummaryField.bind(null, i)}
                        />
                      </div>
                    </div>
                  ),
                )}
              </div>
            </div>
          )}
          {isSummarizedFormOpened && (
            <div className="summarized-column-form px15 bt-input-border pt10 mt15">
              {summarizedFormFields.map(summarizedFormField => {
                if (summarizedFormField.type === SummarizedFormFieldType.TEXT) {
                  return (
                    <div
                      className="row y-center full-width"
                      key={summarizedFormField.id}
                    >
                      <StruxhubInput
                        isHelperTextHidden
                        label={summarizedFormField.getCaption()}
                        isRequired={summarizedFormField.required}
                        isValid={summarizedFormField.isValid()}
                        value={summarizedFormField.value}
                        onChange={summarizedFormField.onChange}
                      />
                    </div>
                  )
                } else if (
                  summarizedFormField.type === SummarizedFormFieldType.SELECT
                ) {
                  return (
                    <StruxhubSelect
                      key={summarizedFormField.id}
                      isHelperTextHidden
                      label={summarizedFormField.getCaption()}
                      isRequired={summarizedFormField.required}
                      isValid={summarizedFormField.isValid()}
                      value={summarizedFormField.value}
                      onChange={summarizedFormField.onChange}
                    >
                      <option hidden value={null}>
                        {Localization.translator.selectField}
                      </option>
                      {summarizedFormField.options?.map(field => (
                        <option value={field.value} key={field.value}>
                          {field.name}
                        </option>
                      ))}
                    </StruxhubSelect>
                  )
                }
              })}

              <div className="row y-center mt10">
                <SubmitButton
                  text={Localization.translator.submit_verb}
                  clickHandler={addEditSummaryField}
                  width={100}
                />
                <div
                  className="pointer text extra-large primary-blue"
                  onClick={closeSummarizedForm}
                >
                  {Localization.translator.cancel}
                </div>
              </div>
            </div>
          )}
          {!isSummarizedFormOpened && (
            <div className="mt15 ml15 row x-center y-center">
              <div
                className={classList({
                  'row x-center y-center btn-filter gant-header-btn no-flex gant-header-btn-outline':
                    true,
                  'inactive-element': !numberFields.length,
                })}
                onClick={openSummarizedForm}
              >
                <span>{Localization.translator.addSummaryColumn}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
