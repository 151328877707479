import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopCustomNodeWrapper from '~/client/src/desktop/components/ActivitiesTreeNode/components/DesktopCustomNodeWrapper'
import DesktopLocationContainerNode from '~/client/src/desktop/components/ActivitiesTreeNode/components/DesktopLocationContainerNode'
import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import ActivityListPreviewStore from '~/client/src/desktop/views/ProjectSetUp/components/TrackingHierarchy/components/ActivitiesTreePreview/ActivityListPreviewStore'
import ActivitiesTree from '~/client/src/shared/components/ActivitiesTree/ActivitiesTree'
import { Content } from '~/client/src/shared/components/Layout'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import HierarchyConfigurationStore from '~/client/src/shared/stores/domain/HierarchyConfiguration.store'
import LocationsStore from '~/client/src/shared/stores/domain/Locations.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

interface IProps {
  activitiesStore?: ActivitiesStore
  eventsStore?: DesktopEventStore
  activityFiltersStore?: ActivityFiltersStore
  hierarchyConfigurationStore?: HierarchyConfigurationStore
  locationStore?: LocationsStore
  companiesStore?: CompaniesStore
  projectDateStore?: ProjectDateStore
}

@inject(
  'activitiesStore',
  'eventsStore',
  'activityFiltersStore',
  'hierarchyConfigurationStore',
  'companiesStore',
  'locationStore',
  'projectDateStore',
)
@observer
export default class ActivitiesTreePreview extends React.Component<IProps> {
  private readonly activityListPreviewStore: ActivityListPreviewStore

  public constructor(props: IProps) {
    super(props)

    this.activityListPreviewStore = new ActivityListPreviewStore(
      props.activitiesStore,
      props.eventsStore,
      props.activityFiltersStore,
      props.hierarchyConfigurationStore,
      props.locationStore,
      props.projectDateStore,
      props.companiesStore,
    )
  }

  public render() {
    const { activitiesPreviewTree } = this.activityListPreviewStore

    return (
      <div className="daily-mobile-component">
        {activitiesPreviewTree.length ? (
          <Content scrollable={true}>
            <ActivitiesTree
              nodes={activitiesPreviewTree}
              viewDate={new Date()}
              CustomNodeWrapperType={DesktopCustomNodeWrapper}
              LocationContainerNodeType={DesktopLocationContainerNode}
              FileInputType={DesktopFileInput}
              compactActivityListStore={this.activityListPreviewStore}
            />
          </Content>
        ) : (
          <div className="pa5">
            [Mobile list view dynamically updates based on the bands chosen at
            left]
          </div>
        )}
      </div>
    )
  }
}
