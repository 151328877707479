import { action, computed, observable } from 'mobx'

import { IWeatherForecast } from '~/client/graph'
import WeatherUnits from '~/client/src/shared/enums/WeatherUnits'
import WeatherForecastInfo from '~/client/src/shared/models/WeatherInfo'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import InitialState from '~/client/src/shared/stores/InitialState'
import ProjectDateStore, {
  isAfter,
} from '~/client/src/shared/stores/ui/ProjectDate.store'
import { customDebounce } from '~/client/src/shared/utils/util'

import EventsStore from '../EventStore/Events.store'

const UNITED_STATES = 'United States'
const MAX_FORECAST_DAYS = 6
const FETCH_DEBOUNCE_TIME = 300

export default class WeatherForecastsStore {
  @observable private isListening = false
  @observable private readonly hourlyWeatherMap = new Map<
    number,
    WeatherForecastInfo[]
  >()

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly projectDateStore: ProjectDateStore,
  ) {
    this.fetchWeeklyForecast = customDebounce(
      this.fetchWeeklyForecast,
      FETCH_DEBOUNCE_TIME,
    )
  }

  @action.bound
  public fetchWeeklyForecast(date: number | Date) {
    if (!date) return

    const { startOfWeek, endOfWeek, startOfDay, addDays } =
      this.projectDateStore

    const dateFrom = startOfDay(startOfWeek(date)).getTime()
    const dateTo = startOfDay(endOfWeek(date)).getTime()

    const lastForecastDate = addDays(startOfDay(Date.now()), MAX_FORECAST_DAYS)

    if (
      (this.hourlyWeatherMap.has(dateFrom) &&
        this.hourlyWeatherMap.has(dateTo)) ||
      isAfter(dateFrom, lastForecastDate)
    ) {
      return
    }

    this.hourlyWeatherMap.set(dateFrom, [])
    this.hourlyWeatherMap.set(dateTo, [])

    this.eventsStore.dispatch(e.GET_WEATHER_FORECASTS, dateFrom, dateTo)

    if (!this.isListening) {
      this.eventsStore.dispatch(e.LISTEN_TO_WEATHER_FORECASTS)

      this.setIsListening()
    }
  }

  @action.bound
  public receiveWeather(data: IWeatherForecast[]) {
    data.forEach(forecast => {
      this.receiveOne(forecast)
    })
  }

  @action.bound
  public receiveOne(forecast: IWeatherForecast) {
    if (!forecast) {
      return
    }

    const { convertToProjectDate, startOfDay } = this.projectDateStore

    const forecastDate = startOfDay(
      convertToProjectDate(forecast.forecastDate),
    ).getTime()

    const weatherModel = WeatherForecastInfo.fromDto(
      forecast.openWeatherForecast,
    )

    if (!this.hourlyWeatherMap.has(forecastDate)) {
      this.hourlyWeatherMap.set(forecastDate, [weatherModel])
      return
    }

    const hourForecast = this.hourlyWeatherMap.get(forecastDate)
    const index = hourForecast.findIndex(w => w.date === weatherModel.date)

    if (index === -1) {
      hourForecast.push(weatherModel)
    } else {
      hourForecast.splice(index, 1, weatherModel)
    }
  }

  @action.bound
  public clearData() {
    this.hourlyWeatherMap.clear()
    this.unsetIsListening()
  }

  public getForecastForDay = (date: Date | number): WeatherForecastInfo => {
    return this.weatherByDaysList.find(w =>
      this.projectDateStore.isSameDay(w.date, date),
    )
  }

  @action.bound
  private setIsListening() {
    this.isListening = true
  }

  @action.bound
  private unsetIsListening() {
    this.isListening = false
  }

  @computed
  public get isLoading() {
    return this.state.loading.get(e.GET_WEATHER_FORECASTS)
  }

  @computed
  public get weatherByDaysList(): WeatherForecastInfo[] {
    return Array.from(this.hourlyWeatherMap.keys()).reduce((list, date) => {
      const hourlyForecast = this.hourlyWeatherMap.get(date)

      if (!hourlyForecast?.length) {
        return list
      }

      const dailyForecast = WeatherForecastInfo.convertToDailyForecast(
        date,
        hourlyForecast,
      )

      list.push(dailyForecast)

      return list
    }, [] as WeatherForecastInfo[])
  }

  @computed
  public get todayForecast(): WeatherForecastInfo {
    return this.weatherByDaysList.find(w =>
      this.projectDateStore.isToday(w.date),
    )
  }

  public getHourlyForecastForDay = (date: Date | number) => {
    const dateFrom = this.projectDateStore.startOfDay(date).getTime()

    return this.hourlyWeatherMap.get(dateFrom).sort((a, b) => a.date - b.date)
  }

  public get hasProjectLocation(): boolean {
    const { projectAddress } = this.state
    return !!projectAddress?.center
  }

  public get weatherUnits(): WeatherUnits {
    const { projectAddress } = this.state
    const { Imperial, Metric } = WeatherUnits

    if (!projectAddress) {
      return Imperial
    }

    // request to set imperial metric as default for US only and add project setting in the future
    return projectAddress.country === UNITED_STATES ? Imperial : Metric
  }

  private get state(): InitialState {
    return this.eventsStore.appState
  }
}
