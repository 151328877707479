import * as React from 'react'

import { Radio, RadioGroup } from '@blueprintjs/core'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DeliveriesListStore from '~/client/src/desktop/views/Deliveries/components/DeliveriesList/DeliveriesList.store'
import DeliveryStatusLabel from '~/client/src/shared/components/DeliveryStatusLabel/DeliveryStatusLabel'
import { Content, Footer, View } from '~/client/src/shared/components/Layout'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import ActionHandleButton from '../ActionHandleButton/ActionHandleButton'
import DeliveryBulkUpdaterStore from './DeliveryBulkUpdater.store'

import './DeliveryBulkUpdater.scss'

// localization: translated

interface IProps {
  deliveriesListStore: DeliveriesListStore
  onUpdated: () => void
  eventsStore?: DesktopEventStore
}

const DISPLAYED_STATUSES = [
  DeliveryStatus.Requested,
  DeliveryStatus.Scheduled,
  DeliveryStatus.OnSite,
  DeliveryStatus.PassedInspection,
  DeliveryStatus.FailedInspection,
  DeliveryStatus.IncompleteDone,
  DeliveryStatus.Done,
  DeliveryStatus.Denied,
  DeliveryStatus.Canceled,
]

@inject('eventsStore')
@observer
export default class DeliveryBulkUpdater extends React.Component<IProps> {
  private readonly store: DeliveryBulkUpdaterStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new DeliveryBulkUpdaterStore(
      props.eventsStore,
      props.deliveriesListStore,
      props.onUpdated,
    )
  }

  public render() {
    const { handleUpdate, selectedStatus, canUpdateSelectedBulk } = this.store
    const canUpdate = canUpdateSelectedBulk(selectedStatus)

    return (
      <View className="delivery-bulk-updater">
        <Content className="relative pa20 no-outline-container">
          <RadioGroup
            className="theme-radio-group"
            selectedValue={selectedStatus}
            onChange={this.handleChange}
          >
            {this.renderRadios()}
          </RadioGroup>
        </Content>
        <Footer className="row pb10 pt12 px12">
          <ActionHandleButton
            title={this.actionHandleButtonTitle}
            onClick={handleUpdate}
            isEnabled={canUpdate}
          />
        </Footer>
      </View>
    )
  }

  private get actionHandleButtonTitle(): string {
    const { filteredDeliveries } = this.store
    return Localization.translator.updateXDeliveries(filteredDeliveries.length)
  }

  private renderRadios() {
    const { canUpdateSelectedBulk } = this.store

    return DISPLAYED_STATUSES.map((status, key) => {
      return (
        <Radio
          key={key}
          className={classList({
            row: true,
            'inactive-element': !canUpdateSelectedBulk(status),
          })}
          value={status}
        >
          <DeliveryStatusLabel status={status} />
        </Radio>
      )
    })
  }

  @action.bound
  private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.store.selectedStatus = event.target.value as DeliveryStatus
  }
}
