import { CancelationReason } from '../enums/CancelationReason'
import Localization from '../localization/LocalizationManager'

export default function getCancelationReasonTitle(reason: CancelationReason) {
  switch (reason) {
    case CancelationReason.NoShow:
      return Localization.translator.noShow
    case CancelationReason.Moved:
      return Localization.translator.moved
    case CancelationReason.Other:
      return Localization.translator.other
    default:
      throw new Error(reason + ' is unhandled')
  }
}
