import { ChartOptions, ChartData as Dataset } from 'chart.js'
import { action, computed, observable } from 'mobx'
import { ChartData } from 'react-chartjs-2'

import { GetMaturixMonitoringDataDocument } from '~/client/graph/operations/generated/MaturixMonitoring.generated'

import Localization from '../../../localization/LocalizationManager'
import { IMaturixMonitoringData } from '../../../models/IMaturixMonitoringData'
import { MaturixData } from '../../../models/MaturixData'
import { MaturixMonitoring } from '../../../models/MaturixMonitoring'
import EventsStore from '../../../stores/EventStore/Events.store'
import GraphExecutorStore from '../../../stores/domain/GraphExecutor.store'
import ProjectDateStore from '../../../stores/ui/ProjectDate.store'
import Guard from '../../../utils/Guard'

export const MATURIX_CHART_OPTIONS: ChartOptions<'line'> = {
  responsive: true,
  elements: {
    point: {
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4,
      borderWidth: 1,
      hoverBorderWidth: 5,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
}

export default class SitemapMaturixStationDraggableModalStore {
  @observable private monitoringData: IMaturixMonitoringData
  @observable public isLoading = false

  public constructor(
    private readonly eventsStore: EventsStore,
    private graphExecutorStore: GraphExecutorStore,
    private projectDateStore: ProjectDateStore,
  ) {
    Guard.requireAll({ graphExecutorStore, eventsStore })
  }

  @action.bound
  public async loadData(monitoringId?: string) {
    this.isLoading = true
    this.monitoringData = undefined
    const { activeProject } = this.eventsStore.appState

    const res = await this.graphExecutorStore.query(
      GetMaturixMonitoringDataDocument,
      { struxHubProjectId: activeProject.id, monitoringId: monitoringId },
    )
    if (res.data?.getMaturixMonitoringData?.monitoring) {
      const { monitoring, data } = res.data.getMaturixMonitoringData
      this.monitoringData = {
        monitoring: MaturixMonitoring.fromDto(monitoring),
        data: data ? data.map(d => MaturixData.fromDto(d)) : [],
      }
    }
    this.isLoading = false
  }

  @computed
  public get monitoring() {
    return this.monitoringData?.monitoring
  }

  @computed
  public get temperatureDataSet(): ChartData<Dataset> | null {
    if (!this.monitoringData?.data?.length) {
      return null
    }
    const filteredData = this.monitoringData.data.filter(i => !!i.temperature)
    if (!filteredData.length) {
      return null
    }
    return {
      labels: filteredData.map(i =>
        this.projectDateStore.getMonthAndDayToDisplay(i.timestamp),
      ),
      datasets: this.getLineChartDataSet(
        filteredData.map(i => i.temperature),
        Localization.translator.temperatureWithUnit(
          filteredData[0].temperatureUnit,
        ),
      ),
    }
  }

  @computed
  public get strengthDataSet(): ChartData<Dataset> | null {
    if (!this.monitoringData?.data?.length) {
      return null
    }
    const filteredData = this.monitoringData.data.filter(i => !!i.temperature)
    if (!filteredData.length) {
      return null
    }
    return {
      labels: filteredData.map(i =>
        this.projectDateStore.getMonthAndDayToDisplay(i.timestamp),
      ),
      datasets: this.getLineChartDataSet(
        filteredData.map(i => i.strength),
        Localization.translator.strengthWithUnit(filteredData[0].strengthUnit),
      ),
    }
  }

  // TODO: Remove hardcoded colours
  private getLineChartDataSet(data: any[], label: string) {
    return [
      {
        fill: false,
        backgroundColor: '#00b5ec',
        borderColor: '#00b5ec',
        label,
        data,
      },
    ]
  }
}
