export enum DeliveryTreeNodeTypes {
  DELIVERY = 'DELIVERY',
  ZONE_CONTAINER = 'ZONE_CONTAINER',
  GATE_CONTAINER = 'GATE_CONTAINER',
  BUILDING_CONTAINER = 'BUILDING_CONTAINER',
  COMPANY_CONTAINER = 'COMPANY_CONTAINER',
  ON_SITE_CONTACT_CONTAINER = 'ON_SITE_CONTACT_CONTAINER',
  ROUTE_CONTAINER = 'ROUTE_CONTAINER',
  STATUS_CONTAINER = 'STATUS_CONTAINER',
  VENDOR_CONTAINER = 'VENDOR_CONTAINER',
  MATERIAL_CATEGORY_CONTAINER = 'MATERIAL_CATEGORY_CONTAINER',
  VEHICLE_TYPE_CONTAINER = 'VEHICLE_TYPE_CONTAINER',
}
