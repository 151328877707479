import * as React from 'react'

import { observer } from 'mobx-react'

import { ContentObjectStatus } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import Flag from '~/client/src/shared/models/Flag'

interface IProps {
  flag: Flag
}

@observer
export default class FlagIcon extends React.Component<IProps> {
  public render() {
    const { flag = { type: '', status: '' } } = this.props
    const { status } = flag

    let icon

    switch (status) {
      case ContentObjectStatus.Open:
        icon = <Icons.Flag className="icon-red" />
        break
      case ContentObjectStatus.Closed:
        icon = <Icons.Flag className="icon-grey" />
        break
      default:
        icon = <Icons.Flag className="icon-grey" />
        break
    }

    return <div>{icon}</div>
  }
}
