import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import ActionBarInput from '~/client/src/shared/components/ActionBarInput/ActionBarInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Photo from '~/client/src/shared/models/Photo'
import RootStore from '~/client/src/shared/stores/Root.store'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

import './ActionBar.scss'

// translated

interface IProps {
  photo: Photo
  store?: SlackBarStore
}

@inject((store: RootStore) => {
  return {
    store: store.slackBarStore,
  }
})
@observer
export default class ActionBar extends React.Component<IProps> {
  private isSending: boolean

  public render() {
    return (
      <div className="pa10 bt-light-grey slack-bar">
        <form onSubmit={this.send}>
          <div className="col">
            <ActionBarInput
              value={this.props.store.draftMessage}
              onChange={this.setDraftMessage}
              placeholder={Localization.translator.addANote}
            />
            <div className="row x-end">
              <button
                type="submit"
                disabled={this.isSending}
                className="item-button action-bar-send-btn"
              >
                {Localization.translator.send}
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }

  @action.bound
  private async send(evt: React.FormEvent<HTMLFormElement>) {
    this.isSending = true
    evt.preventDefault()
    evt.stopPropagation()
    await this.props.store.replyToPhoto(this.props.photo)
    this.isSending = false
  }

  private setDraftMessage = (evt: React.FormEvent<HTMLInputElement>) => {
    this.props.store.draftMessage = evt.currentTarget.value
  }
}
