import * as React from 'react'

import { Button, Classes, Dialog } from '@blueprintjs/core'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import ProjectScheduleUploadStore from '../../ProjectScheduleUpload.store'
import SheetColumn from './SheetColumn'

import './MapFieldsDialog.scss'

export interface IProps {
  store: ProjectScheduleUploadStore
}

@observer
export default class MapFieldsDialog extends React.Component<IProps> {
  private get areRequiredFieldsMapped() {
    const { fields, columnMap } = this.props.store

    const requiredFields = fields.filter(f => f.required)
    return requiredFields.every(f => columnMap[f.name])
  }

  public render() {
    const {
      isMapFieldsDialogShown,
      resetInitialValue,
      uploadedSheets,
      chosenSheetName,
      applyMapFieldsDialog,
      fields,
    } = this.props.store
    const sheet = uploadedSheets.find(s => s.name === chosenSheetName)
    const disabled = !sheet || !this.areRequiredFieldsMapped
    return (
      <Dialog
        isOpen={isMapFieldsDialogShown}
        title={Localization.translator.mapFields}
        isCloseButtonShown={false}
        canOutsideClickClose={false}
        className="map-fields-dialog"
      >
        <div className={Classes.DIALOG_BODY}>
          <div className="text large light mb20">
            {Localization.translator.uploadScheduleDescriptions.mapFields}
          </div>
          {sheet &&
            fields.map(({ name, caption, required }) =>
              name ? (
                <SheetColumn
                  key={name}
                  fieldName={name}
                  fieldDescription={caption()}
                  isRequired={required}
                  fileColumns={sheet.columns}
                  store={this.props.store}
                />
              ) : null,
            )}
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <Button
            text={Localization.translator.cancelUpload}
            className="choose-project-dialog-btn white-btn"
            onClick={resetInitialValue}
          />
          <Button
            text={Localization.translator.done}
            className={classList({
              'choose-project-dialog-btn blue-btn': true,
              disabled,
            })}
            disabled={disabled}
            onClick={applyMapFieldsDialog}
          />
        </div>
      </Dialog>
    )
  }
}
