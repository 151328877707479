import { DeliveryListFilterType, IDeliveryFilter } from '~/client/graph'

export interface IUserNotificationsSettings {
  inApp: boolean
  sms: boolean
  email: boolean
}

export interface IDeliveriesSettings {
  isFollowingFilterActive: boolean
  isMyCompanyFilterActive: boolean
  selectedFilters?: IDeliveryFilter[]
  selectedGroupByOption?: DeliveryListFilterType
}

export interface ISitemapsSetupSettings {
  lastEditedSitemapId?: string
}

export interface IGlobesSetupSettings {
  lastEditedGlobeId?: string
}

export function getDefaultUserNotificationsSettings(): IUserNotificationsSettings {
  return {
    inApp: true,
    sms: true,
    email: true,
  }
}

export function getDefaultDeliveriesSettings(): IDeliveriesSettings {
  return {
    isFollowingFilterActive: false,
    isMyCompanyFilterActive: false,
    selectedFilters: [],
    selectedGroupByOption: DeliveryListFilterType.None,
  }
}
