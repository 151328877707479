import {
  DeleteManySitePermitAssignmentsDocument,
  SaveManySitePermitAssignmentsDocument,
} from '~/client/graph/operations/generated/SitePermitAssignment.generated'

import BaseAssignmentsStore from '../BaseAssignments.store'
import EventsStore from '../EventStore/Events.store'
import TagsStore from './Tags.store'

export default class SitePermitAssignmentsStore extends BaseAssignmentsStore {
  public constructor(eventsStore: EventsStore, tagsStore: TagsStore) {
    super(
      eventsStore,
      tagsStore,
      SaveManySitePermitAssignmentsDocument,
      DeleteManySitePermitAssignmentsDocument,
    )
  }

  public get byId() {
    return this.eventsStore.appState.sitePermitAssignments
  }
}
