import {
  ApplicationVerifier,
  Auth,
  ConfirmationResult,
  EmailAuthProvider,
  Unsubscribe,
  User,
  UserCredential,
  confirmPasswordReset,
  linkWithCredential,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  unlink,
  updatePassword,
  verifyPasswordResetCode,
} from 'firebase/auth'

import Guard from '~/client/src/shared/utils/Guard'

import { isTokenAboutToExpire } from '../utils/JwtHelper'

export const FIREBASE_PASSWORD_PROVIDER_ID = 'password'

export default class FirebaseAuthService {
  private updatingToken: Promise<string> = null
  private accessToken: string = null
  private unsubscribeOnAuthStateChanged: Unsubscribe = null

  public constructor(public readonly auth: Auth) {
    Guard.require(auth, 'auth')
  }

  public get authUser(): User {
    return this.auth.currentUser
  }

  public get wasPasswordSetByAuthUser(): boolean {
    return !!this.authUser?.providerData.find(
      ({ providerId }) => providerId === FIREBASE_PASSWORD_PROVIDER_ID,
    )
  }

  public get hasAuthUserLinkedEmail(): boolean {
    return !!this.authUser?.email
  }

  public async loginWithInviteKey(inviteKey: string): Promise<UserCredential> {
    return signInWithCustomToken(this.auth, inviteKey)
  }

  public async getFreshAccessToken(): Promise<string> {
    return this.authUser.getIdToken(true)
  }

  public login(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, email, password)
  }

  public async loginWithPhoneNumber(
    phoneNumber: string,
    applicationVerifier: ApplicationVerifier,
  ): Promise<ConfirmationResult> {
    return signInWithPhoneNumber(this.auth, phoneNumber, applicationVerifier)
  }

  public logout(): Promise<void> {
    return this.auth.signOut()
  }

  public onAuthStateChanged(cb: (user: User) => void): any {
    this.unsubscribeOnAuthStateChanged?.() // only one active subscriber available to avoid conflicts
    this.unsubscribeOnAuthStateChanged = onAuthStateChanged(this.auth, cb)
  }

  public verifyPasswordResetCode(resetCode) {
    return verifyPasswordResetCode(this.auth, resetCode)
  }

  public updatePassword(newPassword: string) {
    return updatePassword(this.authUser, newPassword)
  }

  public linkEmailProvider(
    email: string,
    password: string,
  ): Promise<UserCredential> {
    const credential = EmailAuthProvider.credential(email, password)
    return linkWithCredential(this.authUser, credential)
  }

  public unlinkProvider(providerId: string): Promise<User> {
    return unlink(this.authUser, providerId)
  }

  public confirmPasswordReset(resetCode, newPassword) {
    return confirmPasswordReset(this.auth, resetCode, newPassword)
  }

  public resetPassword(email) {
    return sendPasswordResetEmail(this.auth, email)
  }

  public updateAccessToken(): Promise<string> {
    if (this.updatingToken) {
      return this.updatingToken
    }

    return (this.updatingToken = this.getFreshAccessToken().then(
      freshAccessToken => {
        this.updatingToken = null
        return (this.accessToken = freshAccessToken)
      },
    ))
  }

  public getValidAccessToken = async (): Promise<string> => {
    if (this.accessToken && isTokenAboutToExpire(this.accessToken)) {
      return this.updateAccessToken()
    }

    return this.accessToken
  }

  public setAccessToken(token: string) {
    this.accessToken = token
  }

  public resetAccessToken() {
    this.accessToken = null
  }
}
