import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import SitePermit from '~/client/src/shared/models/Permit'

import Localization from '../../../localization/LocalizationManager'
import SitePermitCreationFormStore from '../SitePermitCreationForm.store'

// localization: translated

interface IProps {
  store: SitePermitCreationFormStore
  onReportSubmitClick(
    form: SitePermit,
    additionalRecipientIds?: string[],
    callbackFn?: () => void,
  ): void

  className?: string
  iconSize?: number
}

@observer
export default class PermitPdfReportButton extends React.Component<IProps> {
  public render() {
    const { className, iconSize } = this.props
    return (
      <div
        className={classList({
          'row pointer': true,
          [className]: !!className,
        })}
        onClick={this.submitReport}
      >
        <Icon
          icon={IconNames.PRINT}
          size={iconSize}
          className="text dark-gray no-grow mr10"
        />
        <span className="text extra-large">
          {Localization.translator.emailPdfReport}
        </span>
      </div>
    )
  }

  private submitReport = () => {
    const { editablePermit, setIsReportDownloading, resetIsReportDownloading } =
      this.props.store

    setIsReportDownloading()

    this.props.onReportSubmitClick(editablePermit, [], resetIsReportDownloading)
  }
}
