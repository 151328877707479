import { WorkflowStepType } from '~/client/graph'

export enum WorkflowStepCategory {
  INITIATION = 'Initiation',
  APPROVAL = 'Approval',
  START = 'Start',
  INSPECTION = 'Inspection',
  FINISH = 'Finish',
}

export const workflowStepCategoriesMap: {
  [K in WorkflowStepCategory]: WorkflowStepType[]
} = {
  [WorkflowStepCategory.INITIATION]: [],
  [WorkflowStepCategory.APPROVAL]: [
    WorkflowStepType.Review,
    WorkflowStepType.Approval,
  ],
  [WorkflowStepCategory.START]: [
    WorkflowStepType.Start,
    WorkflowStepType.OnSite,
  ],
  [WorkflowStepCategory.INSPECTION]: [
    WorkflowStepType.ReadyToInspect,
    WorkflowStepType.Inspection,
    WorkflowStepType.BicInspection,
    WorkflowStepType.RecurringInspection,
  ],
  [WorkflowStepCategory.FINISH]: [WorkflowStepType.Finish],
}
