import { observable } from 'mobx'

import { IPosition, ISitemapTextBox } from '~/client/graph'
import { getTextSize } from '~/client/src/shared/utils/textUtils'

import { areObjectsEqual } from '../../../utils/util'
import { REFERENCED_Y_OFFSET } from '../components/konvaElements/KonvaTextBox'
import { IBoundingBox } from './MapViewItemBase'

import Colors from '~/client/src/shared/theme.module.scss'

export const DEFAULT_MATURIX_FONT_SIZE = 12
const DEFAULT_FONT_SIZE = 16
const DEFAULT_IS_TEXT_BOX_DISPLAYED = true
const DEFAULT_IS_DISPLAYED = true
const DEFAULT_POSITION = {
  x: 50,
  y: 50,
}

const MAX_PERCENT = 100
const TEXT_PADDING_X = 20
const TEXT_PADDING_Y = 14

const DEGREE_IN_RADIANS = Math.PI / 180

export default class SitemapLabelProperties {
  @observable public fontSize: number
  @observable public isTextBoxDisplayed: boolean
  @observable public isDisplayed: boolean
  @observable public position: IPosition
  @observable public color: string

  public constructor(
    fontSize?: number,
    isTextBoxDisplayed?: boolean,
    isDisplayed?: boolean,
    position?: IPosition,
    color?: string,
  ) {
    this.fontSize = fontSize || DEFAULT_FONT_SIZE
    this.isTextBoxDisplayed =
      typeof isTextBoxDisplayed === 'boolean'
        ? isTextBoxDisplayed
        : DEFAULT_IS_TEXT_BOX_DISPLAYED
    this.isDisplayed =
      typeof isDisplayed === 'boolean' ? isDisplayed : DEFAULT_IS_DISPLAYED
    this.position = position || DEFAULT_POSITION
    this.color = color || Colors.paletteBrandDark
  }

  public isEqual(properties: SitemapLabelProperties) {
    return areObjectsEqual(this, properties)
  }

  public getBoundingBox(
    text: string,
    containerWidth: number,
    containerHeight: number,
  ): IBoundingBox {
    const x = (containerWidth * this.position.x) / MAX_PERCENT
    const y =
      (containerHeight * this.position.y) / MAX_PERCENT + REFERENCED_Y_OFFSET

    let { width, height } = getTextSize(text, this.fontSize)

    if (this.isTextBoxDisplayed) {
      width += TEXT_PADDING_X
      height += TEXT_PADDING_Y
    }
    return {
      x: x - width / 2,
      y: y - height / 2,
      width,
      height,
    }
  }

  public isValid(): boolean {
    return !!this.position && this.fontSize > 0
  }

  public move(x: number, y: number): IPosition[] {
    this.position = {
      x: this.position.x + x,
      y: this.position.y + y,
    }
    return [this.position]
  }

  public setFontSize(fontSize: number) {
    this.fontSize = fontSize
  }

  public toggleTextBoxVisibility() {
    this.isTextBoxDisplayed = !this.isTextBoxDisplayed
  }

  public toggleIsDisplayed() {
    this.isDisplayed = !this.isDisplayed
  }

  public setPosition(position: IPosition) {
    this.position = position
  }

  public isPositionEqual(position: IPosition) {
    if (!this.position) {
      return !position
    }
    return (
      position &&
      this.position.x === position.x &&
      this.position.y === position.y
    )
  }

  public isDisplayDataEqual(textBox: ISitemapTextBox) {
    return (
      this.fontSize === textBox.fontSize &&
      this.isTextBoxDisplayed === textBox.isTextBoxDisplayed &&
      !textBox.isHidden === this.isDisplayed &&
      this.isPositionEqual(textBox.position) &&
      this.color === textBox.color
    )
  }

  public getDisplayData(): ISitemapTextBox {
    return {
      fontSize: this.fontSize,
      isTextBoxDisplayed: this.isTextBoxDisplayed,
      isHidden: !this.isDisplayed,
      position: {
        x: this.position.x,
        y: this.position.y,
      },
      color: this.color,
    }
  }

  public scale(originPoint: IPosition, scaleX: number, scaleY: number) {
    this.position.x = scaleX * (this.position.x - originPoint.x) + originPoint.x
    this.position.y = scaleY * (this.position.y - originPoint.y) + originPoint.y
  }

  public rotate(center: IPosition, angle: number) {
    let { x, y } = this.position

    x -= center.x
    y -= center.y

    const cos = Math.cos(angle * DEGREE_IN_RADIANS)
    const sin = Math.sin(angle * DEGREE_IN_RADIANS)

    const newX = x * cos - y * sin
    const newY = x * sin + y * cos

    this.position = {
      x: newX + center.x,
      y: newY + center.y,
    }
  }

  public copy(): SitemapLabelProperties {
    return new SitemapLabelProperties(
      this.fontSize,
      this.isTextBoxDisplayed,
      this.isDisplayed,
      {
        x: this.position.x,
        y: this.position.y,
      },
      this.color,
    )
  }
}
