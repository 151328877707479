import * as React from 'react'

import { Arrow } from 'react-konva'

import { IPosition, SitemapLineArrowPosition } from '~/client/graph'

const MIN_POINTS_COUNT_TO_CREATE_ARROW = 2

const MIN_ARROW_RAY_LENGTH = 15
const MAX_ARROW_RAY_LENGTH = 300

interface IRay {
  from: IPosition
  to: IPosition
}

interface IProps {
  points: number[]
  width: number
  color: string
  arrowPosition: SitemapLineArrowPosition
}

export default class KonvaLineArrows extends React.Component<IProps> {
  public render() {
    const { color, width } = this.props
    const arrowRays = this.getArrowRays()
    return arrowRays.map((ray, idx) => (
      <Arrow
        key={idx}
        points={[ray.from.x, ray.from.y, ray.to.x, ray.to.y]}
        strokeWidth={width}
        stroke={color}
        fill={color}
      />
    ))
  }

  private getArrowRays(): IRay[] {
    const { arrowPosition } = this.props
    const points = this.getPointObjects()
    const pointsCount = points.length
    if (pointsCount < MIN_POINTS_COUNT_TO_CREATE_ARROW) {
      return []
    }

    switch (arrowPosition) {
      case SitemapLineArrowPosition.StartEnd:
        return [
          {
            from: points[pointsCount - 2],
            to: points[pointsCount - 1],
          },
        ]
      case SitemapLineArrowPosition.StartEndReversed:
        return [
          {
            from: points[1],
            to: points[0],
          },
        ]
      case SitemapLineArrowPosition.StartMiddleEnd:
        return [].concat(
          ...points.map((point, idx) => {
            const nextPoint = points[idx + 1]
            return this.splitRayIntoPieces(point, nextPoint)
          }),
        )
      case SitemapLineArrowPosition.StartMiddleEndReversed:
        return [].concat(
          ...points.reverse().map((point, idx) => {
            const nextPoint = points[idx + 1]
            return this.splitRayIntoPieces(point, nextPoint)
          }),
        )
      default:
        return []
    }
  }

  private splitRayIntoPieces(from: IPosition, to: IPosition): IRay[] {
    if (!from || !to) {
      return []
    }
    const rayLength = Math.sqrt(
      Math.pow(to.x - from.x, 2) + Math.pow(to.y - from.y, 2),
    )

    if (rayLength < MIN_ARROW_RAY_LENGTH) {
      return []
    }
    if (rayLength < MAX_ARROW_RAY_LENGTH) {
      return [{ from, to }]
    }

    const subRaysCount = Math.ceil(rayLength / MAX_ARROW_RAY_LENGTH)

    const stepX = (to.x - from.x) / subRaysCount
    const stepY = (to.y - from.y) / subRaysCount

    const result = []
    for (let i = 0; i < subRaysCount; i++) {
      result.push({
        from: {
          x: from.x + stepX * i,
          y: from.y + stepY * i,
        },
        to: {
          x: from.x + stepX * (i + 1),
          y: from.y + stepY * (i + 1),
        },
      })
    }
    return result
  }

  private getPointObjects() {
    let points = this.props.points.slice()
    const result: IPosition[] = []
    while (points.length) {
      const [x, y, ...rest] = points
      result.push({ x, y })
      points = rest
    }
    return result
  }
}
