import ActivityDataFieldId from '../enums/ActivityDataFieldId'
import ActivityLinkingSettings from '../enums/ActivityLinkingSettings'

const activityFieldIdToLinkingSettingNameMap = {
  [ActivityDataFieldId.BUILDING_ID]:
    ActivityLinkingSettings.isBuildingLinkingActive,
  [ActivityDataFieldId.LEVEL_ID]: ActivityLinkingSettings.isLevelLinkingActive,
  [ActivityDataFieldId.ZONE_ID]: ActivityLinkingSettings.isZoneLinkingActive,
  [ActivityDataFieldId.COMPANY_ID]:
    ActivityLinkingSettings.isCompanyLinkingActive,
}

export default activityFieldIdToLinkingSettingNameMap
