import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IScheduleFieldsFragment = Pick<
  Types.ISchedule,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'projectId'
  | 'userId'
  | 'fileName'
  | 'activityQuantity'
  | 'companyQuantity'
>

export type IActivityFieldsFragment = Pick<
  Types.IActivity,
  | 'id'
  | 'scheduleId'
  | 'name'
  | 'code'
  | 'plannedStartDate'
  | 'plannedFinishDate'
  | 'actualStartDate'
  | 'actualFinishDate'
  | 'remainingEarlyStartDate'
  | 'remainingEarlyFinishDate'
  | 'percentComplete'
  | 'totalFloat'
  | 'status'
  | 'onTimeStatus'
  | 'workBreakdownStructureId'
  | 'resourceId'
  | 'calendarId'
  | 'companyId'
  | 'requesterId'
  | 'isDeleted'
> & {
  activityLinkingSettings?: Types.Maybe<
    Pick<
      Types.IActivityLinkingSettings,
      | 'isLevelLinkingActive'
      | 'isBuildingLinkingActive'
      | 'isCompanyLinkingActive'
      | 'isZoneLinkingActive'
    >
  >
  locations?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.ISiteLocation, 'id' | 'type' | 'levels'>>>
  >
}

export type IActivityCodeTypeFieldsFragment = Pick<
  Types.IActivityCodeType,
  'id' | 'scheduleId' | 'name' | 'scope'
>

export type IActivityCodeFieldsFragment = Pick<
  Types.IActivityCode,
  'id' | 'scheduleId' | 'name' | 'shortName' | 'count' | 'parentId' | 'typeId'
>

export type IActivityCodeRelationshipFieldsFragment = Pick<
  Types.IActivityCodeRelationship,
  | 'id'
  | 'scheduleId'
  | 'activityCodeTypeId'
  | 'activityCodeId'
  | 'activityP6Code'
  | 'isDeleted'
>

export type ICalendarFieldsFragment = Pick<
  Types.ICalendar,
  | 'id'
  | 'scheduleId'
  | 'name'
  | 'type'
  | 'isDefault'
  | 'isPersonalCalendar'
  | 'parentId'
  | 'dateLastChanged'
  | 'workHoursPerDay'
  | 'workHoursPerWeek'
  | 'workHoursPerMonth'
  | 'workHoursPerYear'
> & {
  calendarData?: Types.Maybe<
    Pick<Types.ICalendarData, 'exceptions'> & {
      days?: Types.Maybe<
        Array<
          Types.Maybe<
            Pick<Types.ICalendarDataDay, 'weekDay' | 'start' | 'finish'>
          >
        >
      >
    }
  >
}

export type IResourceFieldsFragment = Pick<
  Types.IResource,
  'id' | 'scheduleId' | 'name' | 'shortName' | 'guid' | 'parentId'
>

export type IWbsFieldsFragment = Pick<
  Types.IWorkBreakdownStructure,
  'id' | 'scheduleId' | 'name' | 'parentId'
>

export type IActivityResourceRelationshipFieldsFragment = Pick<
  Types.IActivityResourceRelationship,
  'id' | 'scheduleId' | 'activityP6Code' | 'resourceId' | 'isDeleted'
>

export type IGetSchedulesQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetSchedulesQuery = {
  schedules?: Types.Maybe<{ data: Array<IScheduleFieldsFragment> }>
}

export type IGetScheduleQueryVariables = Types.Exact<{
  id: Types.Scalars['ObjectId']
}>

export type IGetScheduleQuery = {
  schedule?: Types.Maybe<IScheduleFieldsFragment>
}

export type IGetActivitiesQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars['ObjectId']
  timezoneId?: Types.Maybe<Types.Scalars['String']>
  date?: Types.Maybe<Types.Scalars['MillisecondsDate']>
}>

export type IGetActivitiesQuery = {
  activities?: Types.Maybe<Array<Types.Maybe<IActivityFieldsFragment>>>
}

export type IGetActivityByCodeQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars['ObjectId']
  activityCode: Types.Scalars['String']
}>

export type IGetActivityByCodeQuery = {
  getActivity?: Types.Maybe<IActivityFieldsFragment>
}

export type IGetActivityCodeTypesQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars['ObjectId']
}>

export type IGetActivityCodeTypesQuery = {
  activityCodeTypes?: Types.Maybe<{
    data: Array<IActivityCodeTypeFieldsFragment>
  }>
}

export type IGetActivityCodesQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars['ObjectId']
}>

export type IGetActivityCodesQuery = {
  activityCodes?: Types.Maybe<{ data: Array<IActivityCodeFieldsFragment> }>
}

export type IGetCalendarsQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars['ObjectId']
}>

export type IGetCalendarsQuery = {
  calendars?: Types.Maybe<{ data: Array<ICalendarFieldsFragment> }>
}

export type IGetResourcesQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars['ObjectId']
}>

export type IGetResourcesQuery = {
  resources?: Types.Maybe<{ data: Array<IResourceFieldsFragment> }>
}

export type IGetWbsListQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars['ObjectId']
}>

export type IGetWbsListQuery = {
  workBreakdownStructures?: Types.Maybe<{ data: Array<IWbsFieldsFragment> }>
}

export type IGetActivityCodeRelationshipsQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars['ObjectId']
}>

export type IGetActivityCodeRelationshipsQuery = {
  activityCodeRelationships?: Types.Maybe<{
    data: Array<IActivityCodeRelationshipFieldsFragment>
  }>
}

export type IGetActivityResourceRelationshipsQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars['ObjectId']
}>

export type IGetActivityResourceRelationshipsQuery = {
  activityResourceRelationships?: Types.Maybe<{
    data: Array<IActivityResourceRelationshipFieldsFragment>
  }>
}

export type IGetRelativeActivitiesQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars['ObjectId']
  activityP6Code: Types.Scalars['String']
}>

export type IGetRelativeActivitiesQuery = {
  relativeActivities?: Types.Maybe<{
    predecessors: Array<Types.Maybe<Pick<Types.IActivity, 'name' | 'code'>>>
    successors: Array<Types.Maybe<Pick<Types.IActivity, 'name' | 'code'>>>
  }>
}

export type IGetWbsQueryVariables = Types.Exact<{
  id: Types.Scalars['ObjectId']
}>

export type IGetWbsQuery = {
  workBreakdownStructure?: Types.Maybe<IWbsFieldsFragment>
}

export type IGetXerProjectsQueryVariables = Types.Exact<{
  file: Types.Scalars['String']
}>

export type IGetXerProjectsQuery = {
  xerProjects?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.IXerProjectInfo, 'id' | 'name' | 'activitiesCount'>
      >
    >
  >
}

export type IGetExcelSheetsQueryVariables = Types.Exact<{
  fullFilePath: Types.Scalars['String']
}>

export type IGetExcelSheetsQuery = {
  excelSheets?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.ISheetData, 'name' | 'columns'>>>
  >
}

export type ICheckIsActivityCodeUniqueQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars['ObjectId']
  code: Types.Scalars['String']
}>

export type ICheckIsActivityCodeUniqueQuery = Pick<
  Types.IQuery,
  'isActivityCodeClaimed'
>

export type IUploadScheduleMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  userId: Types.Scalars['ObjectId']
  fileName: Types.Scalars['String']
  file: Types.Scalars['String']
  xerProjectId: Types.Scalars['String']
  timezoneId: Types.Scalars['String']
}>

export type IUploadScheduleMutation = Pick<Types.IMutation, 'uploadSchedule'>

export type IUploadExcelScheduleMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  userId: Types.Scalars['ObjectId']
  fileName: Types.Scalars['String']
  fullFilePath: Types.Scalars['String']
  sheetData: Types.ISheetDataInput
  timezoneId: Types.Scalars['String']
  shouldUpdateCurrentSchedule: Types.Scalars['Boolean']
}>

export type IUploadExcelScheduleMutation = Pick<
  Types.IMutation,
  'uploadExcelSchedule'
>

export type ISaveActivityMutationVariables = Types.Exact<{
  activity: Types.IActivityInput
}>

export type ISaveActivityMutation = {
  saveActivity?: Types.Maybe<IActivityFieldsFragment>
}

export type IActivityWithDataMutationVariables = Types.Exact<{
  data: Types.IActivityDataInput
}>

export type IActivityWithDataMutation = {
  activityWithData?: Types.Maybe<IActivityFieldsFragment>
}

export type ISaveActivityCodeRelationshipsMutationVariables = Types.Exact<{
  activityCodeRelationships:
    | Array<Types.Maybe<Types.IActivityCodeRelationshipInput>>
    | Types.Maybe<Types.IActivityCodeRelationshipInput>
}>

export type ISaveActivityCodeRelationshipsMutation = {
  saveManyActivityCodeRelationships?: Types.Maybe<
    Array<Types.Maybe<IActivityCodeRelationshipFieldsFragment>>
  >
}

export type IUpdateActivitiesFieldsMutationVariables = Types.Exact<{
  activities:
    | Array<Types.Maybe<Types.IUpdateActivitiesFieldsInput>>
    | Types.Maybe<Types.IUpdateActivitiesFieldsInput>
  projectId: Types.Scalars['ObjectId']
}>

export type IUpdateActivitiesFieldsMutation = Pick<
  Types.IMutation,
  'updateActivityFields'
>

export type IDeleteActivitiesMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  activitiesCodes:
    | Array<Types.Maybe<Types.Scalars['String']>>
    | Types.Maybe<Types.Scalars['String']>
}>

export type IDeleteActivitiesMutation = Pick<
  Types.IMutation,
  'softDeleteManyActivities'
>

export type IListenToActivitySubscriptionVariables = Types.Exact<{
  scheduleId: Types.Scalars['ObjectId']
}>

export type IListenToActivitySubscription = {
  activity?: Types.Maybe<
    Pick<Types.IActivityChangeEvent, 'id'> & {
      item?: Types.Maybe<IActivityFieldsFragment>
    }
  >
}

export type IListenToActivityCodeTypeSubscriptionVariables = Types.Exact<{
  scheduleId: Types.Scalars['ObjectId']
}>

export type IListenToActivityCodeTypeSubscription = {
  activityCodeType?: Types.Maybe<
    Pick<Types.IActivityCodeTypeChangeEvent, 'id'> & {
      item?: Types.Maybe<IActivityCodeTypeFieldsFragment>
    }
  >
}

export type IListenToActivityCodeSubscriptionVariables = Types.Exact<{
  scheduleId: Types.Scalars['ObjectId']
}>

export type IListenToActivityCodeSubscription = {
  activityCode?: Types.Maybe<
    Pick<Types.IActivityCodeChangeEvent, 'id'> & {
      item?: Types.Maybe<IActivityCodeFieldsFragment>
    }
  >
}

export type IListenToResourceSubscriptionVariables = Types.Exact<{
  scheduleId: Types.Scalars['ObjectId']
}>

export type IListenToResourceSubscription = {
  resource?: Types.Maybe<
    Pick<Types.IResourceChangeEvent, 'id'> & {
      item?: Types.Maybe<IResourceFieldsFragment>
    }
  >
}

export type IListenToActivityResourceRelationshipSubscriptionVariables =
  Types.Exact<{
    scheduleId: Types.Scalars['ObjectId']
  }>

export type IListenToActivityResourceRelationshipSubscription = {
  activityResourceRelationship?: Types.Maybe<
    Pick<Types.IActivityResourceRelationshipChangeEvent, 'id'> & {
      item?: Types.Maybe<IActivityResourceRelationshipFieldsFragment>
    }
  >
}

export type IListenToActivityCodeRelationshipSubscriptionVariables =
  Types.Exact<{
    scheduleId: Types.Scalars['ObjectId']
  }>

export type IListenToActivityCodeRelationshipSubscription = {
  activityCodeRelationship?: Types.Maybe<
    Pick<Types.IActivityCodeRelationshipChangeEvent, 'id'> & {
      item?: Types.Maybe<IActivityCodeRelationshipFieldsFragment>
    }
  >
}

export type IListenToScheduleSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToScheduleSubscription = {
  schedule?: Types.Maybe<
    Pick<Types.IScheduleChangeEvent, 'id'> & {
      item?: Types.Maybe<IScheduleFieldsFragment>
    }
  >
}

export type IListenToScheduleUploadSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToScheduleUploadSubscription = {
  uploadScheduleProgress?: Types.Maybe<
    Pick<Types.IUploadScheduleProgressChangeEvent, 'id'> & {
      item?: Types.Maybe<
        Pick<
          Types.IUploadScheduleProgress,
          | 'scheduleId'
          | 'previousScheduleId'
          | 'status'
          | 'failedStep'
          | 'failReason'
          | 'itemsCount'
          | 'savedItemsCount'
        > & {
          uploadResult?: Types.Maybe<{
            previousSchedule?: Types.Maybe<IScheduleFieldsFragment>
            schedule: IScheduleFieldsFragment
            compareData: {
              resourceForCompare: Pick<
                Types.ICategoryForCompare,
                'categoryName' | 'previousCount' | 'uploadCount'
              >
              activityCodeTypesForCompare: Array<
                Types.Maybe<
                  Pick<
                    Types.ICategoryForCompare,
                    'categoryName' | 'previousCount' | 'uploadCount'
                  >
                >
              >
            }
          }>
        }
      >
    }
  >
}

export const ScheduleFieldsFragmentDoc = gql`
  fragment ScheduleFields on Schedule {
    id
    createdAt
    updatedAt
    projectId
    userId
    fileName
    activityQuantity
    companyQuantity
  }
`
export const ActivityFieldsFragmentDoc = gql`
  fragment ActivityFields on Activity {
    id
    scheduleId
    name
    code
    plannedStartDate
    plannedFinishDate
    actualStartDate
    actualFinishDate
    remainingEarlyStartDate
    remainingEarlyFinishDate
    percentComplete
    totalFloat
    status
    onTimeStatus
    workBreakdownStructureId
    resourceId
    calendarId
    companyId
    requesterId
    activityLinkingSettings {
      isLevelLinkingActive
      isBuildingLinkingActive
      isCompanyLinkingActive
      isZoneLinkingActive
    }
    isDeleted
    locations {
      id
      type
      levels
    }
  }
`
export const ActivityCodeTypeFieldsFragmentDoc = gql`
  fragment ActivityCodeTypeFields on ActivityCodeType {
    id
    scheduleId
    name
    scope
  }
`
export const ActivityCodeFieldsFragmentDoc = gql`
  fragment ActivityCodeFields on ActivityCode {
    id
    scheduleId
    name
    shortName
    count
    parentId
    typeId
  }
`
export const ActivityCodeRelationshipFieldsFragmentDoc = gql`
  fragment ActivityCodeRelationshipFields on ActivityCodeRelationship {
    id
    scheduleId
    activityCodeTypeId
    activityCodeId
    activityP6Code
    isDeleted
  }
`
export const CalendarFieldsFragmentDoc = gql`
  fragment CalendarFields on Calendar {
    id
    scheduleId
    name
    type
    isDefault
    isPersonalCalendar
    parentId
    dateLastChanged
    workHoursPerDay
    workHoursPerWeek
    workHoursPerMonth
    workHoursPerYear
    calendarData {
      days {
        weekDay
        start
        finish
      }
      exceptions
    }
  }
`
export const ResourceFieldsFragmentDoc = gql`
  fragment ResourceFields on Resource {
    id
    scheduleId
    name
    shortName
    guid
    parentId
  }
`
export const WbsFieldsFragmentDoc = gql`
  fragment WbsFields on WorkBreakdownStructure {
    id
    scheduleId
    name
    parentId
  }
`
export const ActivityResourceRelationshipFieldsFragmentDoc = gql`
  fragment ActivityResourceRelationshipFields on ActivityResourceRelationship {
    id
    scheduleId
    activityP6Code
    resourceId
    isDeleted
  }
`
export const GetSchedulesDocument = gql`
  query GetSchedules($projectId: ObjectId!) {
    schedules(projectId: $projectId, limit: 1000000) {
      data {
        ...ScheduleFields
      }
    }
  }
  ${ScheduleFieldsFragmentDoc}
`

/**
 * __useGetSchedulesQuery__
 *
 * To run a query within a React component, call `useGetSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchedulesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetSchedulesQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetSchedulesQuery,
    IGetSchedulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetSchedulesQuery, IGetSchedulesQueryVariables>(
    GetSchedulesDocument,
    options,
  )
}
export function useGetSchedulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetSchedulesQuery,
    IGetSchedulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetSchedulesQuery, IGetSchedulesQueryVariables>(
    GetSchedulesDocument,
    options,
  )
}
export type GetSchedulesQueryHookResult = ReturnType<
  typeof useGetSchedulesQuery
>
export type GetSchedulesLazyQueryHookResult = ReturnType<
  typeof useGetSchedulesLazyQuery
>
export type GetSchedulesQueryResult = Apollo.QueryResult<
  IGetSchedulesQuery,
  IGetSchedulesQueryVariables
>
export const GetScheduleDocument = gql`
  query GetSchedule($id: ObjectId!) {
    schedule(id: $id) {
      ...ScheduleFields
    }
  }
  ${ScheduleFieldsFragmentDoc}
`

/**
 * __useGetScheduleQuery__
 *
 * To run a query within a React component, call `useGetScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScheduleQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetScheduleQuery,
    IGetScheduleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetScheduleQuery, IGetScheduleQueryVariables>(
    GetScheduleDocument,
    options,
  )
}
export function useGetScheduleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetScheduleQuery,
    IGetScheduleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetScheduleQuery, IGetScheduleQueryVariables>(
    GetScheduleDocument,
    options,
  )
}
export type GetScheduleQueryHookResult = ReturnType<typeof useGetScheduleQuery>
export type GetScheduleLazyQueryHookResult = ReturnType<
  typeof useGetScheduleLazyQuery
>
export type GetScheduleQueryResult = Apollo.QueryResult<
  IGetScheduleQuery,
  IGetScheduleQueryVariables
>
export const GetActivitiesDocument = gql`
  query GetActivities(
    $scheduleId: ObjectId!
    $timezoneId: String
    $date: MillisecondsDate
  ) {
    activities(scheduleId: $scheduleId, timezoneId: $timezoneId, date: $date) {
      ...ActivityFields
    }
  }
  ${ActivityFieldsFragmentDoc}
`

/**
 * __useGetActivitiesQuery__
 *
 * To run a query within a React component, call `useGetActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitiesQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *      timezoneId: // value for 'timezoneId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetActivitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetActivitiesQuery,
    IGetActivitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetActivitiesQuery, IGetActivitiesQueryVariables>(
    GetActivitiesDocument,
    options,
  )
}
export function useGetActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetActivitiesQuery,
    IGetActivitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetActivitiesQuery, IGetActivitiesQueryVariables>(
    GetActivitiesDocument,
    options,
  )
}
export type GetActivitiesQueryHookResult = ReturnType<
  typeof useGetActivitiesQuery
>
export type GetActivitiesLazyQueryHookResult = ReturnType<
  typeof useGetActivitiesLazyQuery
>
export type GetActivitiesQueryResult = Apollo.QueryResult<
  IGetActivitiesQuery,
  IGetActivitiesQueryVariables
>
export const GetActivityByCodeDocument = gql`
  query GetActivityByCode($scheduleId: ObjectId!, $activityCode: String!) {
    getActivity(scheduleId: $scheduleId, activityCode: $activityCode) {
      ...ActivityFields
    }
  }
  ${ActivityFieldsFragmentDoc}
`

/**
 * __useGetActivityByCodeQuery__
 *
 * To run a query within a React component, call `useGetActivityByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityByCodeQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *      activityCode: // value for 'activityCode'
 *   },
 * });
 */
export function useGetActivityByCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetActivityByCodeQuery,
    IGetActivityByCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetActivityByCodeQuery,
    IGetActivityByCodeQueryVariables
  >(GetActivityByCodeDocument, options)
}
export function useGetActivityByCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetActivityByCodeQuery,
    IGetActivityByCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetActivityByCodeQuery,
    IGetActivityByCodeQueryVariables
  >(GetActivityByCodeDocument, options)
}
export type GetActivityByCodeQueryHookResult = ReturnType<
  typeof useGetActivityByCodeQuery
>
export type GetActivityByCodeLazyQueryHookResult = ReturnType<
  typeof useGetActivityByCodeLazyQuery
>
export type GetActivityByCodeQueryResult = Apollo.QueryResult<
  IGetActivityByCodeQuery,
  IGetActivityByCodeQueryVariables
>
export const GetActivityCodeTypesDocument = gql`
  query GetActivityCodeTypes($scheduleId: ObjectId!) {
    activityCodeTypes(scheduleId: $scheduleId, limit: 30000) {
      data {
        ...ActivityCodeTypeFields
      }
    }
  }
  ${ActivityCodeTypeFieldsFragmentDoc}
`

/**
 * __useGetActivityCodeTypesQuery__
 *
 * To run a query within a React component, call `useGetActivityCodeTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityCodeTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityCodeTypesQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useGetActivityCodeTypesQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetActivityCodeTypesQuery,
    IGetActivityCodeTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetActivityCodeTypesQuery,
    IGetActivityCodeTypesQueryVariables
  >(GetActivityCodeTypesDocument, options)
}
export function useGetActivityCodeTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetActivityCodeTypesQuery,
    IGetActivityCodeTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetActivityCodeTypesQuery,
    IGetActivityCodeTypesQueryVariables
  >(GetActivityCodeTypesDocument, options)
}
export type GetActivityCodeTypesQueryHookResult = ReturnType<
  typeof useGetActivityCodeTypesQuery
>
export type GetActivityCodeTypesLazyQueryHookResult = ReturnType<
  typeof useGetActivityCodeTypesLazyQuery
>
export type GetActivityCodeTypesQueryResult = Apollo.QueryResult<
  IGetActivityCodeTypesQuery,
  IGetActivityCodeTypesQueryVariables
>
export const GetActivityCodesDocument = gql`
  query GetActivityCodes($scheduleId: ObjectId!) {
    activityCodes(scheduleId: $scheduleId, limit: 30000) {
      data {
        ...ActivityCodeFields
      }
    }
  }
  ${ActivityCodeFieldsFragmentDoc}
`

/**
 * __useGetActivityCodesQuery__
 *
 * To run a query within a React component, call `useGetActivityCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityCodesQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useGetActivityCodesQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetActivityCodesQuery,
    IGetActivityCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetActivityCodesQuery,
    IGetActivityCodesQueryVariables
  >(GetActivityCodesDocument, options)
}
export function useGetActivityCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetActivityCodesQuery,
    IGetActivityCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetActivityCodesQuery,
    IGetActivityCodesQueryVariables
  >(GetActivityCodesDocument, options)
}
export type GetActivityCodesQueryHookResult = ReturnType<
  typeof useGetActivityCodesQuery
>
export type GetActivityCodesLazyQueryHookResult = ReturnType<
  typeof useGetActivityCodesLazyQuery
>
export type GetActivityCodesQueryResult = Apollo.QueryResult<
  IGetActivityCodesQuery,
  IGetActivityCodesQueryVariables
>
export const GetCalendarsDocument = gql`
  query GetCalendars($scheduleId: ObjectId!) {
    calendars(scheduleId: $scheduleId, limit: 30000) {
      data {
        ...CalendarFields
      }
    }
  }
  ${CalendarFieldsFragmentDoc}
`

/**
 * __useGetCalendarsQuery__
 *
 * To run a query within a React component, call `useGetCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarsQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useGetCalendarsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetCalendarsQuery,
    IGetCalendarsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetCalendarsQuery, IGetCalendarsQueryVariables>(
    GetCalendarsDocument,
    options,
  )
}
export function useGetCalendarsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetCalendarsQuery,
    IGetCalendarsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetCalendarsQuery, IGetCalendarsQueryVariables>(
    GetCalendarsDocument,
    options,
  )
}
export type GetCalendarsQueryHookResult = ReturnType<
  typeof useGetCalendarsQuery
>
export type GetCalendarsLazyQueryHookResult = ReturnType<
  typeof useGetCalendarsLazyQuery
>
export type GetCalendarsQueryResult = Apollo.QueryResult<
  IGetCalendarsQuery,
  IGetCalendarsQueryVariables
>
export const GetResourcesDocument = gql`
  query GetResources($scheduleId: ObjectId!) {
    resources(scheduleId: $scheduleId, limit: 30000) {
      data {
        ...ResourceFields
      }
    }
  }
  ${ResourceFieldsFragmentDoc}
`

/**
 * __useGetResourcesQuery__
 *
 * To run a query within a React component, call `useGetResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourcesQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useGetResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetResourcesQuery,
    IGetResourcesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetResourcesQuery, IGetResourcesQueryVariables>(
    GetResourcesDocument,
    options,
  )
}
export function useGetResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetResourcesQuery,
    IGetResourcesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetResourcesQuery, IGetResourcesQueryVariables>(
    GetResourcesDocument,
    options,
  )
}
export type GetResourcesQueryHookResult = ReturnType<
  typeof useGetResourcesQuery
>
export type GetResourcesLazyQueryHookResult = ReturnType<
  typeof useGetResourcesLazyQuery
>
export type GetResourcesQueryResult = Apollo.QueryResult<
  IGetResourcesQuery,
  IGetResourcesQueryVariables
>
export const GetWbsListDocument = gql`
  query GetWBSList($scheduleId: ObjectId!) {
    workBreakdownStructures(scheduleId: $scheduleId, limit: 30000) {
      data {
        ...WbsFields
      }
    }
  }
  ${WbsFieldsFragmentDoc}
`

/**
 * __useGetWbsListQuery__
 *
 * To run a query within a React component, call `useGetWbsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsListQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useGetWbsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetWbsListQuery,
    IGetWbsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetWbsListQuery, IGetWbsListQueryVariables>(
    GetWbsListDocument,
    options,
  )
}
export function useGetWbsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetWbsListQuery,
    IGetWbsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetWbsListQuery, IGetWbsListQueryVariables>(
    GetWbsListDocument,
    options,
  )
}
export type GetWbsListQueryHookResult = ReturnType<typeof useGetWbsListQuery>
export type GetWbsListLazyQueryHookResult = ReturnType<
  typeof useGetWbsListLazyQuery
>
export type GetWbsListQueryResult = Apollo.QueryResult<
  IGetWbsListQuery,
  IGetWbsListQueryVariables
>
export const GetActivityCodeRelationshipsDocument = gql`
  query GetActivityCodeRelationships($scheduleId: ObjectId!) {
    activityCodeRelationships(scheduleId: $scheduleId, limit: 30000) {
      data {
        ...ActivityCodeRelationshipFields
      }
    }
  }
  ${ActivityCodeRelationshipFieldsFragmentDoc}
`

/**
 * __useGetActivityCodeRelationshipsQuery__
 *
 * To run a query within a React component, call `useGetActivityCodeRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityCodeRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityCodeRelationshipsQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useGetActivityCodeRelationshipsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetActivityCodeRelationshipsQuery,
    IGetActivityCodeRelationshipsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetActivityCodeRelationshipsQuery,
    IGetActivityCodeRelationshipsQueryVariables
  >(GetActivityCodeRelationshipsDocument, options)
}
export function useGetActivityCodeRelationshipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetActivityCodeRelationshipsQuery,
    IGetActivityCodeRelationshipsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetActivityCodeRelationshipsQuery,
    IGetActivityCodeRelationshipsQueryVariables
  >(GetActivityCodeRelationshipsDocument, options)
}
export type GetActivityCodeRelationshipsQueryHookResult = ReturnType<
  typeof useGetActivityCodeRelationshipsQuery
>
export type GetActivityCodeRelationshipsLazyQueryHookResult = ReturnType<
  typeof useGetActivityCodeRelationshipsLazyQuery
>
export type GetActivityCodeRelationshipsQueryResult = Apollo.QueryResult<
  IGetActivityCodeRelationshipsQuery,
  IGetActivityCodeRelationshipsQueryVariables
>
export const GetActivityResourceRelationshipsDocument = gql`
  query GetActivityResourceRelationships($scheduleId: ObjectId!) {
    activityResourceRelationships(scheduleId: $scheduleId, limit: 30000) {
      data {
        ...ActivityResourceRelationshipFields
      }
    }
  }
  ${ActivityResourceRelationshipFieldsFragmentDoc}
`

/**
 * __useGetActivityResourceRelationshipsQuery__
 *
 * To run a query within a React component, call `useGetActivityResourceRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityResourceRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityResourceRelationshipsQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useGetActivityResourceRelationshipsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetActivityResourceRelationshipsQuery,
    IGetActivityResourceRelationshipsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetActivityResourceRelationshipsQuery,
    IGetActivityResourceRelationshipsQueryVariables
  >(GetActivityResourceRelationshipsDocument, options)
}
export function useGetActivityResourceRelationshipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetActivityResourceRelationshipsQuery,
    IGetActivityResourceRelationshipsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetActivityResourceRelationshipsQuery,
    IGetActivityResourceRelationshipsQueryVariables
  >(GetActivityResourceRelationshipsDocument, options)
}
export type GetActivityResourceRelationshipsQueryHookResult = ReturnType<
  typeof useGetActivityResourceRelationshipsQuery
>
export type GetActivityResourceRelationshipsLazyQueryHookResult = ReturnType<
  typeof useGetActivityResourceRelationshipsLazyQuery
>
export type GetActivityResourceRelationshipsQueryResult = Apollo.QueryResult<
  IGetActivityResourceRelationshipsQuery,
  IGetActivityResourceRelationshipsQueryVariables
>
export const GetRelativeActivitiesDocument = gql`
  query GetRelativeActivities(
    $scheduleId: ObjectId!
    $activityP6Code: String!
  ) {
    relativeActivities(
      scheduleId: $scheduleId
      activityP6Code: $activityP6Code
    ) {
      predecessors {
        name
        code
      }
      successors {
        name
        code
      }
    }
  }
`

/**
 * __useGetRelativeActivitiesQuery__
 *
 * To run a query within a React component, call `useGetRelativeActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelativeActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelativeActivitiesQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *      activityP6Code: // value for 'activityP6Code'
 *   },
 * });
 */
export function useGetRelativeActivitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetRelativeActivitiesQuery,
    IGetRelativeActivitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetRelativeActivitiesQuery,
    IGetRelativeActivitiesQueryVariables
  >(GetRelativeActivitiesDocument, options)
}
export function useGetRelativeActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetRelativeActivitiesQuery,
    IGetRelativeActivitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetRelativeActivitiesQuery,
    IGetRelativeActivitiesQueryVariables
  >(GetRelativeActivitiesDocument, options)
}
export type GetRelativeActivitiesQueryHookResult = ReturnType<
  typeof useGetRelativeActivitiesQuery
>
export type GetRelativeActivitiesLazyQueryHookResult = ReturnType<
  typeof useGetRelativeActivitiesLazyQuery
>
export type GetRelativeActivitiesQueryResult = Apollo.QueryResult<
  IGetRelativeActivitiesQuery,
  IGetRelativeActivitiesQueryVariables
>
export const GetWbsDocument = gql`
  query GetWbs($id: ObjectId!) {
    workBreakdownStructure(id: $id) {
      ...WbsFields
    }
  }
  ${WbsFieldsFragmentDoc}
`

/**
 * __useGetWbsQuery__
 *
 * To run a query within a React component, call `useGetWbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWbsQuery(
  baseOptions: Apollo.QueryHookOptions<IGetWbsQuery, IGetWbsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetWbsQuery, IGetWbsQueryVariables>(
    GetWbsDocument,
    options,
  )
}
export function useGetWbsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetWbsQuery,
    IGetWbsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetWbsQuery, IGetWbsQueryVariables>(
    GetWbsDocument,
    options,
  )
}
export type GetWbsQueryHookResult = ReturnType<typeof useGetWbsQuery>
export type GetWbsLazyQueryHookResult = ReturnType<typeof useGetWbsLazyQuery>
export type GetWbsQueryResult = Apollo.QueryResult<
  IGetWbsQuery,
  IGetWbsQueryVariables
>
export const GetXerProjectsDocument = gql`
  query GetXerProjects($file: String!) {
    xerProjects(file: $file) {
      id
      name
      activitiesCount
    }
  }
`

/**
 * __useGetXerProjectsQuery__
 *
 * To run a query within a React component, call `useGetXerProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetXerProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetXerProjectsQuery({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useGetXerProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetXerProjectsQuery,
    IGetXerProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetXerProjectsQuery, IGetXerProjectsQueryVariables>(
    GetXerProjectsDocument,
    options,
  )
}
export function useGetXerProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetXerProjectsQuery,
    IGetXerProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetXerProjectsQuery,
    IGetXerProjectsQueryVariables
  >(GetXerProjectsDocument, options)
}
export type GetXerProjectsQueryHookResult = ReturnType<
  typeof useGetXerProjectsQuery
>
export type GetXerProjectsLazyQueryHookResult = ReturnType<
  typeof useGetXerProjectsLazyQuery
>
export type GetXerProjectsQueryResult = Apollo.QueryResult<
  IGetXerProjectsQuery,
  IGetXerProjectsQueryVariables
>
export const GetExcelSheetsDocument = gql`
  query GetExcelSheets($fullFilePath: String!) {
    excelSheets(fullFilePath: $fullFilePath) {
      name
      columns
    }
  }
`

/**
 * __useGetExcelSheetsQuery__
 *
 * To run a query within a React component, call `useGetExcelSheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExcelSheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExcelSheetsQuery({
 *   variables: {
 *      fullFilePath: // value for 'fullFilePath'
 *   },
 * });
 */
export function useGetExcelSheetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetExcelSheetsQuery,
    IGetExcelSheetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetExcelSheetsQuery, IGetExcelSheetsQueryVariables>(
    GetExcelSheetsDocument,
    options,
  )
}
export function useGetExcelSheetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetExcelSheetsQuery,
    IGetExcelSheetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetExcelSheetsQuery,
    IGetExcelSheetsQueryVariables
  >(GetExcelSheetsDocument, options)
}
export type GetExcelSheetsQueryHookResult = ReturnType<
  typeof useGetExcelSheetsQuery
>
export type GetExcelSheetsLazyQueryHookResult = ReturnType<
  typeof useGetExcelSheetsLazyQuery
>
export type GetExcelSheetsQueryResult = Apollo.QueryResult<
  IGetExcelSheetsQuery,
  IGetExcelSheetsQueryVariables
>
export const CheckIsActivityCodeUniqueDocument = gql`
  query CheckIsActivityCodeUnique($scheduleId: ObjectId!, $code: String!) {
    isActivityCodeClaimed(scheduleId: $scheduleId, code: $code)
  }
`

/**
 * __useCheckIsActivityCodeUniqueQuery__
 *
 * To run a query within a React component, call `useCheckIsActivityCodeUniqueQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIsActivityCodeUniqueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIsActivityCodeUniqueQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCheckIsActivityCodeUniqueQuery(
  baseOptions: Apollo.QueryHookOptions<
    ICheckIsActivityCodeUniqueQuery,
    ICheckIsActivityCodeUniqueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ICheckIsActivityCodeUniqueQuery,
    ICheckIsActivityCodeUniqueQueryVariables
  >(CheckIsActivityCodeUniqueDocument, options)
}
export function useCheckIsActivityCodeUniqueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICheckIsActivityCodeUniqueQuery,
    ICheckIsActivityCodeUniqueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ICheckIsActivityCodeUniqueQuery,
    ICheckIsActivityCodeUniqueQueryVariables
  >(CheckIsActivityCodeUniqueDocument, options)
}
export type CheckIsActivityCodeUniqueQueryHookResult = ReturnType<
  typeof useCheckIsActivityCodeUniqueQuery
>
export type CheckIsActivityCodeUniqueLazyQueryHookResult = ReturnType<
  typeof useCheckIsActivityCodeUniqueLazyQuery
>
export type CheckIsActivityCodeUniqueQueryResult = Apollo.QueryResult<
  ICheckIsActivityCodeUniqueQuery,
  ICheckIsActivityCodeUniqueQueryVariables
>
export const UploadScheduleDocument = gql`
  mutation UploadSchedule(
    $projectId: ObjectId!
    $userId: ObjectId!
    $fileName: String!
    $file: String!
    $xerProjectId: String!
    $timezoneId: String!
  ) {
    uploadSchedule(
      projectId: $projectId
      userId: $userId
      fileName: $fileName
      file: $file
      xerProjectId: $xerProjectId
      timezoneId: $timezoneId
    )
  }
`
export type IUploadScheduleMutationFn = Apollo.MutationFunction<
  IUploadScheduleMutation,
  IUploadScheduleMutationVariables
>

/**
 * __useUploadScheduleMutation__
 *
 * To run a mutation, you first call `useUploadScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadScheduleMutation, { data, loading, error }] = useUploadScheduleMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *      fileName: // value for 'fileName'
 *      file: // value for 'file'
 *      xerProjectId: // value for 'xerProjectId'
 *      timezoneId: // value for 'timezoneId'
 *   },
 * });
 */
export function useUploadScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUploadScheduleMutation,
    IUploadScheduleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUploadScheduleMutation,
    IUploadScheduleMutationVariables
  >(UploadScheduleDocument, options)
}
export type UploadScheduleMutationHookResult = ReturnType<
  typeof useUploadScheduleMutation
>
export type UploadScheduleMutationResult =
  Apollo.MutationResult<IUploadScheduleMutation>
export type UploadScheduleMutationOptions = Apollo.BaseMutationOptions<
  IUploadScheduleMutation,
  IUploadScheduleMutationVariables
>
export const UploadExcelScheduleDocument = gql`
  mutation UploadExcelSchedule(
    $projectId: ObjectId!
    $userId: ObjectId!
    $fileName: String!
    $fullFilePath: String!
    $sheetData: SheetDataInput!
    $timezoneId: String!
    $shouldUpdateCurrentSchedule: Boolean!
  ) {
    uploadExcelSchedule(
      projectId: $projectId
      userId: $userId
      fileName: $fileName
      fullFilePath: $fullFilePath
      sheetData: $sheetData
      timezoneId: $timezoneId
      shouldUpdateCurrentSchedule: $shouldUpdateCurrentSchedule
    )
  }
`
export type IUploadExcelScheduleMutationFn = Apollo.MutationFunction<
  IUploadExcelScheduleMutation,
  IUploadExcelScheduleMutationVariables
>

/**
 * __useUploadExcelScheduleMutation__
 *
 * To run a mutation, you first call `useUploadExcelScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadExcelScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadExcelScheduleMutation, { data, loading, error }] = useUploadExcelScheduleMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *      fileName: // value for 'fileName'
 *      fullFilePath: // value for 'fullFilePath'
 *      sheetData: // value for 'sheetData'
 *      timezoneId: // value for 'timezoneId'
 *      shouldUpdateCurrentSchedule: // value for 'shouldUpdateCurrentSchedule'
 *   },
 * });
 */
export function useUploadExcelScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUploadExcelScheduleMutation,
    IUploadExcelScheduleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUploadExcelScheduleMutation,
    IUploadExcelScheduleMutationVariables
  >(UploadExcelScheduleDocument, options)
}
export type UploadExcelScheduleMutationHookResult = ReturnType<
  typeof useUploadExcelScheduleMutation
>
export type UploadExcelScheduleMutationResult =
  Apollo.MutationResult<IUploadExcelScheduleMutation>
export type UploadExcelScheduleMutationOptions = Apollo.BaseMutationOptions<
  IUploadExcelScheduleMutation,
  IUploadExcelScheduleMutationVariables
>
export const SaveActivityDocument = gql`
  mutation SaveActivity($activity: ActivityInput!) {
    saveActivity(activity: $activity) {
      ...ActivityFields
    }
  }
  ${ActivityFieldsFragmentDoc}
`
export type ISaveActivityMutationFn = Apollo.MutationFunction<
  ISaveActivityMutation,
  ISaveActivityMutationVariables
>

/**
 * __useSaveActivityMutation__
 *
 * To run a mutation, you first call `useSaveActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveActivityMutation, { data, loading, error }] = useSaveActivityMutation({
 *   variables: {
 *      activity: // value for 'activity'
 *   },
 * });
 */
export function useSaveActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveActivityMutation,
    ISaveActivityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveActivityMutation,
    ISaveActivityMutationVariables
  >(SaveActivityDocument, options)
}
export type SaveActivityMutationHookResult = ReturnType<
  typeof useSaveActivityMutation
>
export type SaveActivityMutationResult =
  Apollo.MutationResult<ISaveActivityMutation>
export type SaveActivityMutationOptions = Apollo.BaseMutationOptions<
  ISaveActivityMutation,
  ISaveActivityMutationVariables
>
export const ActivityWithDataDocument = gql`
  mutation ActivityWithData($data: ActivityDataInput!) {
    activityWithData(data: $data) {
      ...ActivityFields
    }
  }
  ${ActivityFieldsFragmentDoc}
`
export type IActivityWithDataMutationFn = Apollo.MutationFunction<
  IActivityWithDataMutation,
  IActivityWithDataMutationVariables
>

/**
 * __useActivityWithDataMutation__
 *
 * To run a mutation, you first call `useActivityWithDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivityWithDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activityWithDataMutation, { data, loading, error }] = useActivityWithDataMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useActivityWithDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IActivityWithDataMutation,
    IActivityWithDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IActivityWithDataMutation,
    IActivityWithDataMutationVariables
  >(ActivityWithDataDocument, options)
}
export type ActivityWithDataMutationHookResult = ReturnType<
  typeof useActivityWithDataMutation
>
export type ActivityWithDataMutationResult =
  Apollo.MutationResult<IActivityWithDataMutation>
export type ActivityWithDataMutationOptions = Apollo.BaseMutationOptions<
  IActivityWithDataMutation,
  IActivityWithDataMutationVariables
>
export const SaveActivityCodeRelationshipsDocument = gql`
  mutation SaveActivityCodeRelationships(
    $activityCodeRelationships: [ActivityCodeRelationshipInput]!
  ) {
    saveManyActivityCodeRelationships(
      activityCodeRelationships: $activityCodeRelationships
    ) {
      ...ActivityCodeRelationshipFields
    }
  }
  ${ActivityCodeRelationshipFieldsFragmentDoc}
`
export type ISaveActivityCodeRelationshipsMutationFn = Apollo.MutationFunction<
  ISaveActivityCodeRelationshipsMutation,
  ISaveActivityCodeRelationshipsMutationVariables
>

/**
 * __useSaveActivityCodeRelationshipsMutation__
 *
 * To run a mutation, you first call `useSaveActivityCodeRelationshipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveActivityCodeRelationshipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveActivityCodeRelationshipsMutation, { data, loading, error }] = useSaveActivityCodeRelationshipsMutation({
 *   variables: {
 *      activityCodeRelationships: // value for 'activityCodeRelationships'
 *   },
 * });
 */
export function useSaveActivityCodeRelationshipsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveActivityCodeRelationshipsMutation,
    ISaveActivityCodeRelationshipsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveActivityCodeRelationshipsMutation,
    ISaveActivityCodeRelationshipsMutationVariables
  >(SaveActivityCodeRelationshipsDocument, options)
}
export type SaveActivityCodeRelationshipsMutationHookResult = ReturnType<
  typeof useSaveActivityCodeRelationshipsMutation
>
export type SaveActivityCodeRelationshipsMutationResult =
  Apollo.MutationResult<ISaveActivityCodeRelationshipsMutation>
export type SaveActivityCodeRelationshipsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveActivityCodeRelationshipsMutation,
    ISaveActivityCodeRelationshipsMutationVariables
  >
export const UpdateActivitiesFieldsDocument = gql`
  mutation updateActivitiesFields(
    $activities: [UpdateActivitiesFieldsInput]!
    $projectId: ObjectId!
  ) {
    updateActivityFields(activities: $activities, projectId: $projectId)
  }
`
export type IUpdateActivitiesFieldsMutationFn = Apollo.MutationFunction<
  IUpdateActivitiesFieldsMutation,
  IUpdateActivitiesFieldsMutationVariables
>

/**
 * __useUpdateActivitiesFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateActivitiesFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivitiesFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivitiesFieldsMutation, { data, loading, error }] = useUpdateActivitiesFieldsMutation({
 *   variables: {
 *      activities: // value for 'activities'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUpdateActivitiesFieldsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateActivitiesFieldsMutation,
    IUpdateActivitiesFieldsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateActivitiesFieldsMutation,
    IUpdateActivitiesFieldsMutationVariables
  >(UpdateActivitiesFieldsDocument, options)
}
export type UpdateActivitiesFieldsMutationHookResult = ReturnType<
  typeof useUpdateActivitiesFieldsMutation
>
export type UpdateActivitiesFieldsMutationResult =
  Apollo.MutationResult<IUpdateActivitiesFieldsMutation>
export type UpdateActivitiesFieldsMutationOptions = Apollo.BaseMutationOptions<
  IUpdateActivitiesFieldsMutation,
  IUpdateActivitiesFieldsMutationVariables
>
export const DeleteActivitiesDocument = gql`
  mutation DeleteActivities(
    $projectId: ObjectId!
    $activitiesCodes: [String]!
  ) {
    softDeleteManyActivities(
      projectId: $projectId
      activitiesCodes: $activitiesCodes
    )
  }
`
export type IDeleteActivitiesMutationFn = Apollo.MutationFunction<
  IDeleteActivitiesMutation,
  IDeleteActivitiesMutationVariables
>

/**
 * __useDeleteActivitiesMutation__
 *
 * To run a mutation, you first call `useDeleteActivitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivitiesMutation, { data, loading, error }] = useDeleteActivitiesMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      activitiesCodes: // value for 'activitiesCodes'
 *   },
 * });
 */
export function useDeleteActivitiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteActivitiesMutation,
    IDeleteActivitiesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteActivitiesMutation,
    IDeleteActivitiesMutationVariables
  >(DeleteActivitiesDocument, options)
}
export type DeleteActivitiesMutationHookResult = ReturnType<
  typeof useDeleteActivitiesMutation
>
export type DeleteActivitiesMutationResult =
  Apollo.MutationResult<IDeleteActivitiesMutation>
export type DeleteActivitiesMutationOptions = Apollo.BaseMutationOptions<
  IDeleteActivitiesMutation,
  IDeleteActivitiesMutationVariables
>
export const ListenToActivityDocument = gql`
  subscription ListenToActivity($scheduleId: ObjectId!) {
    activity(scheduleId: $scheduleId) {
      id
      item {
        ...ActivityFields
      }
    }
  }
  ${ActivityFieldsFragmentDoc}
`

/**
 * __useListenToActivitySubscription__
 *
 * To run a query within a React component, call `useListenToActivitySubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToActivitySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToActivitySubscription({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useListenToActivitySubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToActivitySubscription,
    IListenToActivitySubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToActivitySubscription,
    IListenToActivitySubscriptionVariables
  >(ListenToActivityDocument, options)
}
export type ListenToActivitySubscriptionHookResult = ReturnType<
  typeof useListenToActivitySubscription
>
export type ListenToActivitySubscriptionResult =
  Apollo.SubscriptionResult<IListenToActivitySubscription>
export const ListenToActivityCodeTypeDocument = gql`
  subscription ListenToActivityCodeType($scheduleId: ObjectId!) {
    activityCodeType(scheduleId: $scheduleId) {
      id
      item {
        ...ActivityCodeTypeFields
      }
    }
  }
  ${ActivityCodeTypeFieldsFragmentDoc}
`

/**
 * __useListenToActivityCodeTypeSubscription__
 *
 * To run a query within a React component, call `useListenToActivityCodeTypeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToActivityCodeTypeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToActivityCodeTypeSubscription({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useListenToActivityCodeTypeSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToActivityCodeTypeSubscription,
    IListenToActivityCodeTypeSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToActivityCodeTypeSubscription,
    IListenToActivityCodeTypeSubscriptionVariables
  >(ListenToActivityCodeTypeDocument, options)
}
export type ListenToActivityCodeTypeSubscriptionHookResult = ReturnType<
  typeof useListenToActivityCodeTypeSubscription
>
export type ListenToActivityCodeTypeSubscriptionResult =
  Apollo.SubscriptionResult<IListenToActivityCodeTypeSubscription>
export const ListenToActivityCodeDocument = gql`
  subscription ListenToActivityCode($scheduleId: ObjectId!) {
    activityCode(scheduleId: $scheduleId) {
      id
      item {
        ...ActivityCodeFields
      }
    }
  }
  ${ActivityCodeFieldsFragmentDoc}
`

/**
 * __useListenToActivityCodeSubscription__
 *
 * To run a query within a React component, call `useListenToActivityCodeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToActivityCodeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToActivityCodeSubscription({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useListenToActivityCodeSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToActivityCodeSubscription,
    IListenToActivityCodeSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToActivityCodeSubscription,
    IListenToActivityCodeSubscriptionVariables
  >(ListenToActivityCodeDocument, options)
}
export type ListenToActivityCodeSubscriptionHookResult = ReturnType<
  typeof useListenToActivityCodeSubscription
>
export type ListenToActivityCodeSubscriptionResult =
  Apollo.SubscriptionResult<IListenToActivityCodeSubscription>
export const ListenToResourceDocument = gql`
  subscription ListenToResource($scheduleId: ObjectId!) {
    resource(scheduleId: $scheduleId) {
      id
      item {
        ...ResourceFields
      }
    }
  }
  ${ResourceFieldsFragmentDoc}
`

/**
 * __useListenToResourceSubscription__
 *
 * To run a query within a React component, call `useListenToResourceSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToResourceSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToResourceSubscription({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useListenToResourceSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToResourceSubscription,
    IListenToResourceSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToResourceSubscription,
    IListenToResourceSubscriptionVariables
  >(ListenToResourceDocument, options)
}
export type ListenToResourceSubscriptionHookResult = ReturnType<
  typeof useListenToResourceSubscription
>
export type ListenToResourceSubscriptionResult =
  Apollo.SubscriptionResult<IListenToResourceSubscription>
export const ListenToActivityResourceRelationshipDocument = gql`
  subscription ListenToActivityResourceRelationship($scheduleId: ObjectId!) {
    activityResourceRelationship(scheduleId: $scheduleId) {
      id
      item {
        ...ActivityResourceRelationshipFields
      }
    }
  }
  ${ActivityResourceRelationshipFieldsFragmentDoc}
`

/**
 * __useListenToActivityResourceRelationshipSubscription__
 *
 * To run a query within a React component, call `useListenToActivityResourceRelationshipSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToActivityResourceRelationshipSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToActivityResourceRelationshipSubscription({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useListenToActivityResourceRelationshipSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToActivityResourceRelationshipSubscription,
    IListenToActivityResourceRelationshipSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToActivityResourceRelationshipSubscription,
    IListenToActivityResourceRelationshipSubscriptionVariables
  >(ListenToActivityResourceRelationshipDocument, options)
}
export type ListenToActivityResourceRelationshipSubscriptionHookResult =
  ReturnType<typeof useListenToActivityResourceRelationshipSubscription>
export type ListenToActivityResourceRelationshipSubscriptionResult =
  Apollo.SubscriptionResult<IListenToActivityResourceRelationshipSubscription>
export const ListenToActivityCodeRelationshipDocument = gql`
  subscription ListenToActivityCodeRelationship($scheduleId: ObjectId!) {
    activityCodeRelationship(scheduleId: $scheduleId) {
      id
      item {
        ...ActivityCodeRelationshipFields
      }
    }
  }
  ${ActivityCodeRelationshipFieldsFragmentDoc}
`

/**
 * __useListenToActivityCodeRelationshipSubscription__
 *
 * To run a query within a React component, call `useListenToActivityCodeRelationshipSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToActivityCodeRelationshipSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToActivityCodeRelationshipSubscription({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useListenToActivityCodeRelationshipSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToActivityCodeRelationshipSubscription,
    IListenToActivityCodeRelationshipSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToActivityCodeRelationshipSubscription,
    IListenToActivityCodeRelationshipSubscriptionVariables
  >(ListenToActivityCodeRelationshipDocument, options)
}
export type ListenToActivityCodeRelationshipSubscriptionHookResult = ReturnType<
  typeof useListenToActivityCodeRelationshipSubscription
>
export type ListenToActivityCodeRelationshipSubscriptionResult =
  Apollo.SubscriptionResult<IListenToActivityCodeRelationshipSubscription>
export const ListenToScheduleDocument = gql`
  subscription ListenToSchedule($projectId: ObjectId!) {
    schedule(projectId: $projectId) {
      id
      item {
        ...ScheduleFields
      }
    }
  }
  ${ScheduleFieldsFragmentDoc}
`

/**
 * __useListenToScheduleSubscription__
 *
 * To run a query within a React component, call `useListenToScheduleSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToScheduleSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToScheduleSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToScheduleSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToScheduleSubscription,
    IListenToScheduleSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToScheduleSubscription,
    IListenToScheduleSubscriptionVariables
  >(ListenToScheduleDocument, options)
}
export type ListenToScheduleSubscriptionHookResult = ReturnType<
  typeof useListenToScheduleSubscription
>
export type ListenToScheduleSubscriptionResult =
  Apollo.SubscriptionResult<IListenToScheduleSubscription>
export const ListenToScheduleUploadDocument = gql`
  subscription ListenToScheduleUpload($projectId: ObjectId!) {
    uploadScheduleProgress(projectId: $projectId) {
      id
      item {
        scheduleId
        previousScheduleId
        status
        failedStep
        failReason
        itemsCount
        savedItemsCount
        uploadResult {
          previousSchedule {
            ...ScheduleFields
          }
          schedule {
            ...ScheduleFields
          }
          compareData {
            resourceForCompare {
              categoryName
              previousCount
              uploadCount
            }
            activityCodeTypesForCompare {
              categoryName
              previousCount
              uploadCount
            }
          }
        }
      }
    }
  }
  ${ScheduleFieldsFragmentDoc}
`

/**
 * __useListenToScheduleUploadSubscription__
 *
 * To run a query within a React component, call `useListenToScheduleUploadSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToScheduleUploadSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToScheduleUploadSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToScheduleUploadSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToScheduleUploadSubscription,
    IListenToScheduleUploadSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToScheduleUploadSubscription,
    IListenToScheduleUploadSubscriptionVariables
  >(ListenToScheduleUploadDocument, options)
}
export type ListenToScheduleUploadSubscriptionHookResult = ReturnType<
  typeof useListenToScheduleUploadSubscription
>
export type ListenToScheduleUploadSubscriptionResult =
  Apollo.SubscriptionResult<IListenToScheduleUploadSubscription>
