import React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IAnalyticSettingsField } from '~/client/graph'
import MenuCloser from '~/client/src/shared/components/MenuCloser'

import ReportsStore from '../../Reports.store'

import './ReportsDropdown.scss'

interface IProps {
  store: ReportsStore
}

@observer
export default class ReportsDropdown extends React.Component<IProps> {
  @observable private open: boolean = false

  public componentDidMount(): void {
    if (this.props.store.isDataPrepared) {
      this.props.store.setDefaultReportView()
    }
  }

  public render() {
    const { activeReportView, dropdownFields } = this.props.store
    return (
      <div className="row y-center px24 pt10 relative">
        <div
          className={classList({
            'row y-center btn-filter gant-header-btn gant-header-btn-outline pl15 x-between':
              true,
            'unclickable-element': this.open,
          })}
          onClick={this.toggle}
        >
          <div className="text-ellipsis text left large">
            {activeReportView?.name}
          </div>
          <Icon icon={IconNames.CHEVRON_DOWN} iconSize={11} />
        </div>
        {this.open && (
          <MenuCloser closeMenu={this.toggle}>
            <div className="dropdown absolute bg-white ba-light-input-border">
              {dropdownFields.map(field => (
                <div
                  key={field.id}
                  onClick={this.onChange.bind(this, field)}
                  className={classList({
                    'dropdown__item row y-center py10 px10 text large pointer':
                      true,
                    'unclickable-element': field.id === activeReportView?.id,
                  })}
                >
                  {field.name}
                </div>
              ))}
            </div>
          </MenuCloser>
        )}
      </div>
    )
  }

  private onChange = (reportView: IAnalyticSettingsField) => {
    this.props.store.activeReportViewChange(reportView)
    this.toggle()
  }

  @action.bound
  private toggle() {
    this.open = !this.open
  }
}
