import { action, computed } from 'mobx'

import {
  IScreen,
  PresentationScreenEntityKey,
  PresentationScreenKey,
} from '~/client/graph'
import {
  deliveryRelatedScreens,
  formsRelatedScreens,
  logisticsRelatedScreens,
  trackerRelatedScreens,
} from '~/client/src/desktop/interfaces/IPresentationScreen'
import DesktopCommonStore from '~/client/src/desktop/stores/ui/DesktopCommon.store'

import IPresentationDisplayedPage from '../../interfaces/IPresentationDisplayedPage'
import DesktopInitialState from '../DesktopInitialState'

// localization: no display text to translate

const MS = {
  SECOND: 1000,
  MINUTE: 1000 * 60,
}

const MINIMUM_DURATION = MS.SECOND * 15

export default class PresentationModeStore {
  private currentPresentationScreenIndex: number = 0
  private currentPresentationScreenEntityIndex: number = 0
  private nextScreenTimer = null
  private nextEntityTimer = null
  private inactivityRestartTimer = null

  public constructor(
    private readonly state: DesktopInitialState,
    private readonly common: DesktopCommonStore,
  ) {}

  @computed
  public get availableScreens(): IScreen[] {
    const {
      isTrackerDisabled,
      isDeliveriesDisabled,
      isFormsDisabled,
      isLogisticsDisabled,
    } = this.state

    return this.state.presentationSettings.screens
      .filter(
        screen =>
          (!isDeliveriesDisabled || !deliveryRelatedScreens.has(screen.key)) &&
          (!isFormsDisabled || !formsRelatedScreens.has(screen.key)) &&
          (!isLogisticsDisabled || !logisticsRelatedScreens.has(screen.key)) &&
          (!isTrackerDisabled || !trackerRelatedScreens.has(screen.key)),
      )
      .sort((s1, s2) => s1.order - s2.order)
  }

  public canShowPage = (key: PresentationScreenKey) => {
    return (
      !this.state.userActiveProjectSettings?.isPresentationUser ||
      this.availableScreens.find(screen => screen.key === key)?.isShown
    )
  }

  @action.bound
  public cancelPresentation() {
    document.removeEventListener('click', this.pausePresentation)
    if (this.nextScreenTimer) {
      clearTimeout(this.nextScreenTimer)
      this.nextScreenTimer = null
    }
    if (this.nextEntityTimer) {
      clearTimeout(this.nextEntityTimer)
      this.nextEntityTimer = null
    }
    if (this.inactivityRestartTimer) {
      clearTimeout(this.inactivityRestartTimer)
      this.inactivityRestartTimer = null
    }
  }

  @action.bound
  public cancelPresentationEntity() {
    if (this.nextEntityTimer) {
      clearTimeout(this.nextEntityTimer)
      this.nextEntityTimer = null
    }
  }

  @action.bound
  public playPresentation() {
    this.currentPresentationScreenIndex = 0
    this.showCurrentScreen()
    document.addEventListener('click', this.pausePresentation)
  }

  @action.bound
  private showCurrentScreen() {
    const page = this.displayedPages[this.currentPresentationScreenIndex]
    this.state.currentPresentationPage = page
    this.openPresentationPage(page)
    this.nextScreenTimer = setTimeout(this.showNextScreen, page.durationMs)
    if (page.entityKey) {
      this.nextEntityTimer = setTimeout(
        this.showNextEntity,
        page.entityDuration || MINIMUM_DURATION,
      )
    } else {
      this.state.currentPresentationPage.entityKey = null
      this.state.currentPresentationPage.entityOrder = 0
      this.nextEntityTimer = null
    }
  }

  private pausePresentation = () => {
    if (this.nextScreenTimer) {
      clearTimeout(this.nextScreenTimer)
      this.nextScreenTimer = null
    }
    if (this.nextEntityTimer) {
      clearTimeout(this.nextEntityTimer)
      this.nextEntityTimer = null
    }
    if (this.inactivityRestartTimer) {
      clearTimeout(this.inactivityRestartTimer)
    }

    this.state.currentPresentationPage = null

    const { inactivityDuration } = this.state.presentationSettings
    const inactivityDurationMs = inactivityDuration * MS.MINUTE

    this.inactivityRestartTimer = setTimeout(() => {
      this.currentPresentationScreenIndex = 0
      this.showCurrentScreen()
    }, inactivityDurationMs)
  }

  private showNextEntity = () => {
    this.currentPresentationScreenEntityIndex++
    const page = this.displayedPages[this.currentPresentationScreenIndex]
    this.openPresentationPageEntity(page)

    if (!page.entityKey) {
      this.currentPresentationScreenEntityIndex = 0
      clearTimeout(this.nextEntityTimer)
      this.nextEntityTimer = null
      return
    }

    this.nextEntityTimer = setTimeout(
      this.showNextEntity,
      page.entityDuration || MINIMUM_DURATION,
    )
  }

  private showNextScreen = () => {
    const prevIndex = this.currentPresentationScreenIndex++
    if (this.currentPresentationScreenIndex > this.displayedPages.length - 1) {
      this.currentPresentationScreenIndex = 0
    }
    if (prevIndex !== this.currentPresentationScreenIndex) {
      this.showCurrentScreen()
    }
  }

  private openPresentationPage(page: IPresentationDisplayedPage) {
    switch (page.type) {
      case PresentationScreenKey.Deliveries:
        return this.common.displayDeliveriesView()
      case PresentationScreenKey.Logistics:
        return this.common.displayHomeView()
      case PresentationScreenKey.Forms:
        return this.common.displayFormsView()
      default:
        this.common.displayActivitiesView()
    }
  }

  private openPresentationPageEntity(page: IPresentationDisplayedPage) {
    // switch here for the future additions
    switch (page.entityKey) {
      case PresentationScreenEntityKey.Announcement:
        this.state.currentPresentationPage.entityKey =
          PresentationScreenEntityKey.Announcement
        this.state.currentPresentationPage.entityOrder =
          this.currentPresentationScreenEntityIndex
        break
      default:
        return
    }
  }

  @computed
  private get displayedPages(): IPresentationDisplayedPage[] {
    const displayedScreens = []
    this.availableScreens.forEach(screen => {
      if (!screen.isShown) {
        return
      }
      switch (screen.key) {
        case PresentationScreenKey.Gantt:
        case PresentationScreenKey.Deliveries:
        case PresentationScreenKey.Logistics:
        case PresentationScreenKey.Forms:
          const page = this.getSinglePresentationPage(screen)
          displayedScreens.push(page)
          return
        default:
          throw new Error('No such screen available: ' + screen.key)
      }
    })
    return displayedScreens
  }

  private getSinglePresentationPage({
    duration,
    key: type,
    entityDuration,
    entityKey,
    shouldShowEntity,
  }: IScreen): IPresentationDisplayedPage {
    if (shouldShowEntity) {
      return {
        durationMs: MS.SECOND * duration,
        type,
        entityDuration: MS.SECOND * entityDuration,
        entityKey,
        entityOrder: 0,
      }
    }

    return {
      durationMs: MS.SECOND * duration,
      type,
    }
  }
}
