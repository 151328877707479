import * as React from 'react'

import { Circle, Group, Path } from 'react-konva'

import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import { BUILDING_PATH } from '../LocationPaths'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_WIDTH = 20
const ICON_HEIGHT = 20
const ICON_OFFSET_X = 4
const ICON_OFFSET_Y = 4

const STROKE_WIDTH = 1

const SHADOW_BLUR = 2
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3
const { getHEXColor } = ThemeMode

const KonvaBuildingIcon = ({ color, children, ...rest }) => {
  const halfSize = ICON_WIDTH / 2

  return (
    <Group {...rest} offsetX={ICON_WIDTH / 2} offsetY={ICON_HEIGHT / 2}>
      {children}
      <Circle
        radius={halfSize}
        x={ICON_WIDTH / 2}
        y={ICON_HEIGHT / 2}
        fill={getHEXColor(color)}
        stroke={getHEXColor(Colors.neutral100)}
        strokeWidth={STROKE_WIDTH}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
      <Path
        data={BUILDING_PATH}
        fill={getHEXColor(Colors.neutral100)}
        x={ICON_OFFSET_X}
        y={ICON_OFFSET_Y}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
        scaleX={0.75}
        scaleY={0.75}
      />
    </Group>
  )
}

export default KonvaBuildingIcon
