import * as React from 'react'

import { Popover, PopoverPosition, PopperModifiers } from '@blueprintjs/core'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

import ColorPalette from './ColorPalette'

import './ColorPicker.scss'

interface IProps {
  colors?: string[]
  value: string
  arePickerExpanded: boolean
  canAddNewColors?: boolean

  onChange(color: string): void
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: {
    enabled: true,
    boundariesElement: 'scrollParent',
  },
  hide: { enabled: false },
  computeStyle: { gpuAcceleration: false },
  arrow: { enabled: false },
}

@observer
export default class ColorPicker extends React.Component<IProps> {
  @observable private areColorPaletteExpanded = false
  @observable private currentColor = null
  @observable private newColors: string[] = []

  public componentDidUpdate(props: IProps) {
    if (this.props.value !== props.value) {
      this.currentColor = props.value
    }
  }

  private get colors() {
    const { colors, value } = this.props
    const fullColors = [...colors]
    if (value && !colors.includes(value)) {
      fullColors.push(value)
    }
    fullColors.push(...this.newColors)
    if (this.currentColor && !colors.includes(this.currentColor)) {
      fullColors.push(this.currentColor)
    }

    return fullColors
  }

  public render() {
    const {
      value,
      arePickerExpanded,
      canAddNewColors = true,
      onChange,
    } = this.props
    return (
      <div className="colors-container">
        {this.colors.map((color, idx) => (
          <div
            key={idx}
            className={classList({
              'color-option': true,
              selected: color === value,
            })}
            style={{ backgroundColor: color }}
            onClick={onChange.bind(this, color)}
          />
        ))}
        {canAddNewColors && (
          <Popover
            className="inline-block"
            position={PopoverPosition.BOTTOM}
            minimal={true}
            usePortal={false}
            isOpen={arePickerExpanded}
            canEscapeKeyClose={false}
            modifiers={popoverPopperModifiers}
            content={this.renderColorPaletteSelector()}
          >
            <div
              className="color-option pointer"
              key="add-button"
              onClick={this.onPlusButtonClick}
            >
              <Icons.AddBordered />
            </div>
          </Popover>
        )}
      </div>
    )
  }

  private renderColorPaletteSelector = () => {
    return (
      this.areColorPaletteExpanded && (
        <ColorPalette
          value={this.currentColor || this.props.value}
          onChange={this.onChangeComplete}
          onClose={this.collapseColorPalette}
        />
      )
    )
  }

  @action.bound
  private collapseColorPalette() {
    this.setNewColor()
    this.areColorPaletteExpanded = false
  }

  @action.bound
  private onPlusButtonClick() {
    this.setNewColor()
    this.areColorPaletteExpanded = true
  }

  @action.bound
  private onChangeComplete(color: string) {
    if (!this.colors.includes(color)) {
      this.currentColor = color
    } else {
      this.currentColor = null
    }
  }

  @action.bound
  private setNewColor() {
    if (this.currentColor) {
      this.newColors.push(this.currentColor)
      this.currentColor = null
    }
  }
}
