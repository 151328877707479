import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IReportTemplateFieldsFragment = Pick<
  Types.IReportTemplate,
  'id' | 'createdAt' | 'updatedAt' | 'name' | 'templateId' | 'isDeleted'
> & {
  files?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.IReportTemplateFile, 'name' | 'fileId' | 'fileType'>
      >
    >
  >
}

export type IGenerateReportMutationVariables = Types.Exact<{
  generateReportInput: Types.IGenerateReportCommandInput
}>

export type IGenerateReportMutation = Pick<Types.IMutation, 'generateReport'>

export type IGetReportTemplateQueryVariables = Types.Exact<{
  templateId: Types.Scalars['String']
}>

export type IGetReportTemplateQuery = {
  reportTemplate?: Types.Maybe<IReportTemplateFieldsFragment>
}

export type IListenGeneratedReportSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenGeneratedReportSubscription = {
  report?: Types.Maybe<
    Pick<Types.IReportChangeEvent, 'id'> & {
      item?: Types.Maybe<
        Pick<
          Types.IReport,
          'failReason' | 'failedStep' | 'id' | 'reportUrls' | 'status'
        >
      >
    }
  >
}

export const ReportTemplateFieldsFragmentDoc = gql`
  fragment ReportTemplateFields on ReportTemplate {
    id
    createdAt
    updatedAt
    name
    templateId
    isDeleted
    files {
      name
      fileId
      fileType
    }
  }
`
export const GenerateReportDocument = gql`
  mutation GenerateReport($generateReportInput: GenerateReportCommandInput!) {
    generateReport(generateReport: $generateReportInput)
  }
`
export type IGenerateReportMutationFn = Apollo.MutationFunction<
  IGenerateReportMutation,
  IGenerateReportMutationVariables
>

/**
 * __useGenerateReportMutation__
 *
 * To run a mutation, you first call `useGenerateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateReportMutation, { data, loading, error }] = useGenerateReportMutation({
 *   variables: {
 *      generateReportInput: // value for 'generateReportInput'
 *   },
 * });
 */
export function useGenerateReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IGenerateReportMutation,
    IGenerateReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IGenerateReportMutation,
    IGenerateReportMutationVariables
  >(GenerateReportDocument, options)
}
export type GenerateReportMutationHookResult = ReturnType<
  typeof useGenerateReportMutation
>
export type GenerateReportMutationResult =
  Apollo.MutationResult<IGenerateReportMutation>
export type GenerateReportMutationOptions = Apollo.BaseMutationOptions<
  IGenerateReportMutation,
  IGenerateReportMutationVariables
>
export const GetReportTemplateDocument = gql`
  query GetReportTemplate($templateId: String!) {
    reportTemplate(templateId: $templateId) {
      ...ReportTemplateFields
    }
  }
  ${ReportTemplateFieldsFragmentDoc}
`

/**
 * __useGetReportTemplateQuery__
 *
 * To run a query within a React component, call `useGetReportTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportTemplateQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGetReportTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetReportTemplateQuery,
    IGetReportTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetReportTemplateQuery,
    IGetReportTemplateQueryVariables
  >(GetReportTemplateDocument, options)
}
export function useGetReportTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetReportTemplateQuery,
    IGetReportTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetReportTemplateQuery,
    IGetReportTemplateQueryVariables
  >(GetReportTemplateDocument, options)
}
export type GetReportTemplateQueryHookResult = ReturnType<
  typeof useGetReportTemplateQuery
>
export type GetReportTemplateLazyQueryHookResult = ReturnType<
  typeof useGetReportTemplateLazyQuery
>
export type GetReportTemplateQueryResult = Apollo.QueryResult<
  IGetReportTemplateQuery,
  IGetReportTemplateQueryVariables
>
export const ListenGeneratedReportDocument = gql`
  subscription ListenGeneratedReport($projectId: ObjectId!) {
    report(projectId: $projectId) {
      id
      item {
        failReason
        failedStep
        id
        reportUrls
        status
      }
    }
  }
`

/**
 * __useListenGeneratedReportSubscription__
 *
 * To run a query within a React component, call `useListenGeneratedReportSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenGeneratedReportSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenGeneratedReportSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenGeneratedReportSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenGeneratedReportSubscription,
    IListenGeneratedReportSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenGeneratedReportSubscription,
    IListenGeneratedReportSubscriptionVariables
  >(ListenGeneratedReportDocument, options)
}
export type ListenGeneratedReportSubscriptionHookResult = ReturnType<
  typeof useListenGeneratedReportSubscription
>
export type ListenGeneratedReportSubscriptionResult =
  Apollo.SubscriptionResult<IListenGeneratedReportSubscription>
