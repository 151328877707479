import { action, observable } from 'mobx'

import { ActivityStatus } from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import Guard from '~/client/src/shared/utils/Guard'

// localization: no display text to translate

export default class StatusFilterStore {
  @observable public shouldExpandStatusSelector: boolean = false
  public constructor(
    private readonly state: DesktopInitialState,
    private readonly forceUpdateCategoriesFilter: () => void,
  ) {
    Guard.requireAll({
      state,
      forceUpdateCategoriesFilter,
    })
  }

  public get selectedMode(): ActivityStatus {
    return this.state.filters.selectedActivityStatusMode
  }

  @action.bound
  public toggleStatusSelector(): void {
    this.shouldExpandStatusSelector = !this.shouldExpandStatusSelector
  }

  public isModeSelected(mode: ActivityStatus): boolean {
    return mode === this.selectedMode
  }

  @action.bound
  public setSelectedSelectorStatusMode(mode: ActivityStatus): void {
    this.state.filters.selectedActivityStatusMode = mode
    this.forceUpdateCategoriesFilter()
  }
}
