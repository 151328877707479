import { IContentObject } from '~/client/graph'
import CategoryOfVariance, {
  CategoryOfVarianceTypes,
} from '~/client/src/shared/models/CategoryOfVariance'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'

import EventsStore from '../EventStore/Events.store'
import ContentObjectsStore from './ContentObjects.store'

export default class CategoriesOfVarianceStore extends ContentObjectsStore<
  CategoryOfVarianceTypes,
  CategoryOfVariance
> {
  public constructor(messagesStore: MessagesStore, eventsStore: EventsStore) {
    super(messagesStore, eventsStore)
  }

  public get byId() {
    return this.eventsStore.appState.categoryOfVariances
  }

  protected createAnInstance(id: string, dto?: IContentObject) {
    return new CategoryOfVariance(id, dto)
  }
}
