import { ChartColors } from '../../shared/enums/ChartColors'
import DesktopInitialState from './DesktopInitialState'

export function getChartColor(
  deltaDaysValue: number,
  manpowerValue: number,
  state: DesktopInitialState,
) {
  const { zoneMapThresholds } = state
  const {
    deltaDaysValue: deltaDaysThreshold,
    manpowerValue: manpowerThreshold,
    isManpowerDisabled,
    deltaDaysBehindValue: deltaDaysBehindThreshold,
  } = zoneMapThresholds

  if (isManpowerDisabled) {
    if (deltaDaysValue >= deltaDaysThreshold) {
      return ChartColors.GREEN
    }

    if (deltaDaysValue <= deltaDaysBehindThreshold) {
      return ChartColors.RED
    }

    return ChartColors.YELLOW
  }

  if (deltaDaysValue < deltaDaysThreshold) {
    return ChartColors.RED
  }

  if (
    deltaDaysValue >= deltaDaysThreshold &&
    manpowerValue > manpowerThreshold
  ) {
    return ChartColors.YELLOW
  }

  if (
    deltaDaysValue >= deltaDaysThreshold &&
    manpowerValue <= manpowerThreshold
  ) {
    return ChartColors.GREEN
  }

  return ChartColors.DEFAULT
}
