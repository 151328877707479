/**
 * * Simplified copy of the ObjectId generation
 * * from the 'bson' library
 */

let PROCESS_UNIQUE: Uint8Array | null = null

function isReactNative() {
  const { navigator } = globalThis as { navigator?: { product?: string } }
  return typeof navigator === 'object' && navigator.product === 'ReactNative'
}

function mathRandomBytes(byteLength: number) {
  if (byteLength < 0) {
    throw new RangeError(
      `The argument 'byteLength' is invalid. Received ${byteLength}`,
    )
  }
  return Uint8Array.from(
    Array.from({ length: byteLength }, () => Math.floor(Math.random() * 256)),
  )
}

const randomBytes: (byteLength: number) => Uint8Array = (() => {
  const { crypto } = globalThis as {
    crypto?: { getRandomValues?: (space: Uint8Array) => Uint8Array }
  }
  if (crypto != null && typeof crypto.getRandomValues === 'function') {
    return (byteLength: number) => {
      // You cannot separate getRandomValues from crypto (need to have this === crypto)
      return crypto.getRandomValues(new Uint8Array(byteLength))
    }
  } else {
    if (isReactNative()) {
      const { console } = globalThis as {
        console?: { warn?: (message: string) => void }
      }
      console?.warn?.(
        'BSON: For React Native please polyfill crypto.getRandomValues, e.g. using: https://www.npmjs.com/package/react-native-get-random-values.',
      )
    }
    return mathRandomBytes
  }
})()

/** Writes a big-endian 32-bit integer to destination, can be signed or unsigned */
function setInt32BE(destination: Uint8Array, offset: number, value: number): 4 {
  destination[offset + 3] = value
  value >>>= 8
  destination[offset + 2] = value
  value >>>= 8
  destination[offset + 1] = value
  value >>>= 8
  destination[offset] = value
  return 4
}

function toHex(uint8array: Uint8Array): string {
  return Array.from(uint8array, byte =>
    byte.toString(16).padStart(2, '0'),
  ).join('')
}

export default class BsonObjectId {
  private static index = Math.floor(Math.random() * 0xffffff)
  /** ObjectId hexString cache @internal */
  private __id?: string

  public constructor() {
    this.__id = BsonObjectId.generate()
  }

  public static generate(): string {
    const inc = BsonObjectId.getInc()
    const buffer = new Uint8Array(12)

    // 4-byte timestamp
    setInt32BE(buffer, 0, Math.floor(Date.now() / 1000))

    // set PROCESS_UNIQUE if yet not initialized
    if (PROCESS_UNIQUE === null) {
      PROCESS_UNIQUE = randomBytes(5)
    }

    // 5-byte process unique
    buffer[4] = PROCESS_UNIQUE[0]
    buffer[5] = PROCESS_UNIQUE[1]
    buffer[6] = PROCESS_UNIQUE[2]
    buffer[7] = PROCESS_UNIQUE[3]
    buffer[8] = PROCESS_UNIQUE[4]

    // 3-byte counter
    buffer[11] = inc & 0xff
    buffer[10] = (inc >> 8) & 0xff
    buffer[9] = (inc >> 16) & 0xff

    return toHex(buffer)
  }

  private static getInc(): number {
    return (BsonObjectId.index = (BsonObjectId.index + 1) % 0xffffff)
  }

  public toString(): string {
    if (!this.__id) {
      this.__id = BsonObjectId.generate()
    }
    return this.__id
  }
}
