import { SAVE_SITE_PERMITS } from '~/client/src/shared/stores/EventStore/eventConstants'
import BaseActionBarStore from '~/client/src/shared/stores/ui/BaseActionBar.store'

import SitePermit from '../models/Permit'

export default class PermitLogActionBarStore extends BaseActionBarStore {
  protected updateThreadEntity(threadEntity: SitePermit) {
    this.eventsStore.dispatch(SAVE_SITE_PERMITS, [threadEntity])
  }
}
