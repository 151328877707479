import { FirebaseError } from 'firebase/app'
import { AuthErrorCodes } from 'firebase/auth'
import { action, computed, observable } from 'mobx'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { INameValuePair } from '~/client/src/shared/interfaces/INameValuePair'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import { IServiceUserDto } from '~/client/src/shared/types/UserDto'
import Guard from '~/client/src/shared/utils/Guard'
import isWeakPassword from '~/client/src/shared/utils/isWeakPassword'
import {
  ICountry,
  formatPhoneNumber,
  getFormattedPhoneNumberForSubmission,
  isPhoneNumberValidForSubmission,
} from '~/client/src/shared/utils/phoneNumberHelpers'
import { ToastTheme, showToast } from '~/client/src/shared/utils/toaster'

export enum UserDetailsSections {
  PROFILE = 'PROFILE',
  PASSWORD_CHANGER = 'PASSWORD_CHANGER',
}

export interface IUserDetailsField {
  id: FieldIds
  value?: string | string[]
  defaultValue?: string
  type: FieldTypes
  section: UserDetailsSections
  getOptions?: () => INameValuePair[]
  onChange?: (event: any) => void
  onSelectChange?: (event: any) => void
  onButtonClick?: (event: any) => void
  additionalTextTranslatorKey?: KnownTranslatorKeys
  nameTranslatorKey?: KnownTranslatorKeys
  isValid?: boolean
  isRequired?: boolean
}

export enum FieldIds {
  FIRST_NAME = 'first-name',
  AVATAR = 'avatar',
  LAST_NAME = 'last-name',
  PHONE_NUMBER = 'phone-number',
  ROLES = 'roles',
  COMPANY = 'company',
  PASSWORD = 'password',
  NEW_PASSWORD = 'new-password',
  CONFIRM_NEW_PASSWORD = 'confirm-new-password',
  EMAIL = 'email',
}
export enum FieldTypes {
  INPUT = 'input',
  AVATAR = 'avatar',
  PASSWORD = 'password',
  SELECT = 'select',
  EMAIL = 'email',
  PHONE = 'phone',
  TAGS = 'tags',
}

export default class ProfileStore {
  @observable public fields: IUserDetailsField[] = []
  @observable public shouldDisableSubmitButton: boolean = true
  @observable public shouldShowModal: boolean = false
  @observable public warningMessage: string = null

  @observable public isEmailLinkingDialogOpen: boolean = false

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly projectMembersStore: ProjectMembersStore,
    companiesStore: CompaniesStore,
  ) {
    Guard.requireAll({
      eventsStore,
      companiesStore,
      projectMembersStore,
    })

    const firstNameField: IUserDetailsField = {
      id: FieldIds.FIRST_NAME,
      value: '',
      type: FieldTypes.INPUT,
      nameTranslatorKey: KnownTranslatorKeys.name,
      section: UserDetailsSections.PROFILE,
    }

    const avatarField: IUserDetailsField = {
      id: FieldIds.AVATAR,
      value: '',
      type: FieldTypes.AVATAR,
      nameTranslatorKey: KnownTranslatorKeys.name,
      section: UserDetailsSections.PROFILE,
    }

    const lastNameField: IUserDetailsField = {
      id: FieldIds.LAST_NAME,
      value: '',
      type: FieldTypes.INPUT,
      additionalTextTranslatorKey:
        KnownTranslatorKeys.userProfileDescriptions_name,
      section: UserDetailsSections.PROFILE,
    }
    const companyField: IUserDetailsField = {
      id: FieldIds.COMPANY,
      value: '',
      type: FieldTypes.SELECT,
      isRequired: true,
      nameTranslatorKey: KnownTranslatorKeys.company,
      section: UserDetailsSections.PROFILE,
      getOptions: () =>
        companiesStore.getCompaniesAsOptions(
          this.userActiveProjectSettings.companyId,
        ),
    }
    const rolesField: IUserDetailsField = {
      id: FieldIds.ROLES,
      value: [],
      type: FieldTypes.TAGS,
      nameTranslatorKey: KnownTranslatorKeys.projectRoles,
      additionalTextTranslatorKey:
        KnownTranslatorKeys.userProfileDescriptions_projectRoles,
      section: UserDetailsSections.PROFILE,
    }
    const emailField: IUserDetailsField = {
      id: FieldIds.EMAIL,
      value: '',
      type: FieldTypes.EMAIL,
      nameTranslatorKey: KnownTranslatorKeys.email_noun,
      section: UserDetailsSections.PROFILE,
      onButtonClick: this.showEmailLinkingDialog,
    }
    const phoneNumberField: IUserDetailsField = {
      id: FieldIds.PHONE_NUMBER,
      value: '',
      type: FieldTypes.PHONE,
      nameTranslatorKey: KnownTranslatorKeys.phone,
      isValid: true,
      section: UserDetailsSections.PROFILE,
      defaultValue: this.appState.countryCode,
    }
    const passwordField: IUserDetailsField = {
      id: FieldIds.PASSWORD,
      value: '',
      type: FieldTypes.PASSWORD,
      nameTranslatorKey: KnownTranslatorKeys.currentPassword,
      isValid: true,
      section: UserDetailsSections.PASSWORD_CHANGER,
    }
    const newPasswordField: IUserDetailsField = {
      id: FieldIds.NEW_PASSWORD,
      value: '',
      type: FieldTypes.PASSWORD,
      nameTranslatorKey: KnownTranslatorKeys.newPassword,
      isValid: true,
      section: UserDetailsSections.PASSWORD_CHANGER,
    }
    const confirmNewPasswordField: IUserDetailsField = {
      id: FieldIds.CONFIRM_NEW_PASSWORD,
      value: '',
      type: FieldTypes.PASSWORD,
      nameTranslatorKey: KnownTranslatorKeys.confirmNewPassword,
      isValid: true,
      section: UserDetailsSections.PASSWORD_CHANGER,
    }

    this.fields = [
      firstNameField,
      lastNameField,
      companyField,
      rolesField,
      emailField,
      phoneNumberField,
      passwordField,
      newPasswordField,
      confirmNewPasswordField,
      avatarField,
    ]

    this.fields.forEach(field => {
      switch (field.id) {
        case FieldIds.PHONE_NUMBER:
          field.onChange = this.onPhoneNumberChange.bind(this, field)
          break
        case FieldIds.EMAIL:
          break
        default:
          field.onChange = this.onChange.bind(this, field)
          break
      }
    })
  }

  public updateAvatarField = (url: string) => {
    if (this.avatarField.value !== url) {
      this.shouldDisableSubmitButton = false
    }
    this.avatarField.value = url
  }

  public get avatarField(): IUserDetailsField {
    return this.fields.find(field => field.id === FieldIds.AVATAR)
  }

  private get appState(): DesktopInitialState {
    return this.eventsStore.appState
  }

  public get isUpdating(): boolean {
    return this.appState.loading.get(e.SAVE_MEMBERS)
  }

  public get isPasswordChanging() {
    return this.appState.loading.get(e.CHANGE_PASSWORD)
  }

  public get currentUser(): User {
    return this.appState.user
  }

  public get userActiveProjectSettings(): UserProject {
    return this.appState.userActiveProjectSettings
  }

  public get passwordChangeError(): string {
    const { PASSWORD, NEW_PASSWORD, CONFIRM_NEW_PASSWORD } = FieldIds

    const oldPassword = this.getFieldValue(PASSWORD)
    const newPassword = this.getFieldValue(NEW_PASSWORD) as string
    const confirmedPassword = this.getFieldValue(CONFIRM_NEW_PASSWORD)

    if (!oldPassword || !newPassword) {
      return ' '
    }

    if (isWeakPassword(newPassword)) {
      return Localization.translator.passwordErrors.weakPassword
    }

    if (newPassword !== confirmedPassword) {
      return Localization.translator.passwordErrors.doNotMatch
    }

    return null
  }

  public getFieldById(id: FieldIds): IUserDetailsField {
    return this.fields.find(field => field.id === id)
  }

  @action.bound
  public assignDefaultValuesToFields() {
    const { phoneNumber, firstName, lastName, email, avatarUrl } =
      this.currentUser

    this.getFieldById(FieldIds.PHONE_NUMBER).value = phoneNumber || ''
    this.avatarField.value = avatarUrl

    this.getFieldById(FieldIds.FIRST_NAME).value = firstName
    this.getFieldById(FieldIds.LAST_NAME).value = lastName
    this.getFieldById(FieldIds.EMAIL).value = email

    const { companyId, roles } = this.userActiveProjectSettings

    this.getFieldById(FieldIds.COMPANY).value = companyId
    this.getFieldById(FieldIds.ROLES).value = roles

    this.shouldDisableSubmitButton = true
    this.setAllPasswordFieldsValid()
  }

  @action.bound
  public async updateProfile() {
    this.resetWarningMessage()

    this.shouldDisableSubmitButton = true

    const editingUserJson = this.currentUser.getCopy() as IServiceUserDto

    editingUserJson.avatarUrl = this.avatarField.value as string
    editingUserJson.phoneNumber = getFormattedPhoneNumberForSubmission(
      this.getFieldValue(FieldIds.PHONE_NUMBER) as string,
    )
    editingUserJson.firstName = this.getFieldValue(
      FieldIds.FIRST_NAME,
    ) as string
    editingUserJson.lastName = this.getFieldValue(FieldIds.LAST_NAME) as string

    // update userProject section
    const userProjectSettings = this.userActiveProjectSettings.toDto()

    userProjectSettings.companyId = this.getFieldValue(FieldIds.COMPANY) || null
    userProjectSettings.roles = this.getFieldValue(FieldIds.ROLES) as string[]
    editingUserJson.userProjectSettings = userProjectSettings

    try {
      await this.projectMembersStore.saveMembers([editingUserJson])
    } catch (e) {
      console.warn(e)
      this.setWarningMessage(e.message)
    } finally {
      this.openAlertModal()
    }
  }

  @action.bound
  public changePassword() {
    this.setAllPasswordFieldsValid()
    this.resetWarningMessage()

    this.eventsStore.dispatch(
      e.CHANGE_PASSWORD,
      this.getFieldValue(FieldIds.PASSWORD),
      this.getFieldValue(FieldIds.NEW_PASSWORD),
      this.handlePasswordChangeSuccess,
      this.handlePasswordChangeError,
    )
  }

  @action.bound
  public showEmailLinkingDialog() {
    this.isEmailLinkingDialogOpen = true
  }

  @action.bound
  public hideEmailLinkingDialog() {
    this.isEmailLinkingDialogOpen = false
  }

  @action.bound
  public closeAlertModal() {
    this.shouldShowModal = false
  }

  @action.bound
  public openAlertModal() {
    this.shouldShowModal = true
  }

  @action.bound
  public onEmailLinkingComplete(linkedEmail: string) {
    this.hideEmailLinkingDialog()
    showToast(
      Localization.translator.userEmailLinking.emailHasBeenLinkedSuccessfully,
      ToastTheme.SUCCESS,
    )
    this.getFieldById(FieldIds.EMAIL).value = linkedEmail
  }

  @computed
  public get areAllRequiredFieldsFilled(): boolean {
    return this.fields.filter(f => f.isRequired).every(field => !!field.value)
  }

  public get isSubmitBtnDisabled(): boolean {
    return this.shouldDisableSubmitButton || !this.areAllRequiredFieldsFilled
  }

  @action.bound
  private handlePasswordChangeError({ code, message }: FirebaseError) {
    this.setWarningMessage(message)

    if (code === AuthErrorCodes.INVALID_PASSWORD) {
      this.setOldPasswordFieldInvalid()
    } else {
      this.setNewPasswordFieldsInvalid()
    }

    this.resetPasswordFields(false)
    this.openAlertModal()
  }

  @action.bound
  private handlePasswordChangeSuccess() {
    this.resetPasswordFields()
    this.openAlertModal()
  }

  private onPhoneNumberChange(
    field: IUserDetailsField,
    phoneNumber: string,
    country: ICountry,
  ) {
    field.value = formatPhoneNumber(phoneNumber)

    field.isValid = isPhoneNumberValidForSubmission(phoneNumber, country)
    this.shouldDisableSubmitButton = !field.isValid
  }

  private onChange(field: IUserDetailsField, newValue: string) {
    if (
      field.value !== newValue &&
      field.section === UserDetailsSections.PROFILE
    ) {
      this.shouldDisableSubmitButton = false
    }
    field.value = newValue
  }

  private resetPasswordFields(shouldResetOldPwd: boolean = true) {
    const { PASSWORD, NEW_PASSWORD, CONFIRM_NEW_PASSWORD } = FieldIds

    if (shouldResetOldPwd) {
      this.getFieldById(PASSWORD).value = ''
    }

    this.getFieldById(NEW_PASSWORD).value = ''
    this.getFieldById(CONFIRM_NEW_PASSWORD).value = ''
  }

  private setWarningMessage(message: string) {
    this.warningMessage = message
  }

  private resetWarningMessage() {
    this.warningMessage = null
  }

  private setOldPasswordFieldValid() {
    this.getFieldById(FieldIds.PASSWORD).isValid = true
  }

  private setOldPasswordFieldInvalid() {
    this.getFieldById(FieldIds.PASSWORD).isValid = false
  }

  private setNewPasswordFieldsValid() {
    this.getFieldById(FieldIds.NEW_PASSWORD).isValid = true
    this.getFieldById(FieldIds.CONFIRM_NEW_PASSWORD).isValid = true
  }

  private setNewPasswordFieldsInvalid() {
    this.getFieldById(FieldIds.NEW_PASSWORD).isValid = false
    this.getFieldById(FieldIds.CONFIRM_NEW_PASSWORD).isValid = false
  }

  private setAllPasswordFieldsValid() {
    this.setOldPasswordFieldValid()
    this.setNewPasswordFieldsValid()
  }

  private getFieldValue(id: FieldIds) {
    return this.getFieldById(id).value
  }
}
