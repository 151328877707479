import { FilterType } from '~/client/graph'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'

const deliveryFieldIdToActivityFilterTypeMap = {
  [FieldIds.BUILDING]: [FilterType.Building],
  [FieldIds.ZONE]: [FilterType.Zone],
  [FieldIds.LEVEL]: [FilterType.Level],
}

export default deliveryFieldIdToActivityFilterTypeMap
