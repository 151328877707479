import CalendarEvent from '~/client/src/shared/models/CalendarEvent'
import OffloadingEquipment from '~/client/src/shared/models/LocationObjects/OffloadingEquipment'
import Guard from '~/client/src/shared/utils/Guard'

// localization: no display text to translate

export default class DesktopCalendarTotalBarItem {
  public static createTotalBarItemForDeliveryEquipment(
    equipment: OffloadingEquipment,
  ) {
    return new DesktopCalendarTotalBarItem(
      equipment.name,
      (event: CalendarEvent) =>
        event.data?.offloadingEquipmentIds?.includes(equipment.id),
    )
  }

  public constructor(
    public title: string,
    public isEventRelatedTo: (event: CalendarEvent) => boolean,
  ) {
    Guard.requireAll({ title, isEventRelatedTo })
  }
}
