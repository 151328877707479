import * as React from 'react'

import * as Icons from '~/client/src/shared/components/Icons'

interface IProps {
  label: string
  onClick?: () => void
  isLast?: boolean
}

export default function Breadcrumb({
  label: text,
  onClick,
  isLast,
}: IProps): JSX.Element {
  return (
    <>
      <span
        className="w-fit-content text size22 line-28 ellipsis pointer brada24 px12 py6 highlighted-hover highlighted-active"
        onClick={onClick}
      >
        {text}
      </span>
      {!isLast && (
        <Icons.RightArrow className="row mx4 no-grow text grey-30 breadcrumb-icon" />
      )}
    </>
  )
}
