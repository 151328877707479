import * as React from 'react'

import { Button, MenuItem } from '@blueprintjs/core'
import { ItemRenderer, Select } from '@blueprintjs/select'
import { inject, observer } from 'mobx-react'
import { toggleClass } from 'react-classlist-helper'

import { IProject } from '~/client/graph'
import ClockComponent from '~/client/src/shared/components/Clock'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Project from '~/client/src/shared/models/Project'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NOOP } from '~/client/src/shared/utils/noop'

import './ProjectSelector.scss'

// localization: translated

interface IProjectSelectorProps {
  isDisabled: boolean
  isLoading: boolean
  shouldShowProjectClock?: boolean
  shouldShowNewProjectItem?: boolean
  currentProject: Project
  projects: IProject[]
  projectDateStore?: ProjectDateStore
  projectsStore?: ProjectsStore
  onItemSelect: (item: Project) => void
  onNewProjectSelected?: () => void
}

const NEW_PROJECT_KEY = 'new-project'

@inject('projectsStore', 'projectDateStore')
@observer
export default class ProjectSelector extends React.Component<IProjectSelectorProps> {
  public render() {
    const {
      isLoading = false,
      shouldShowNewProjectItem = false,
      isDisabled = false,
      projects = [],
    } = this.props

    let projectItems: Array<IProject | string> = shouldShowNewProjectItem
      ? [Localization.translator.newProject]
      : []
    projectItems = projectItems.concat(...projects)

    const isSelectingDisabled = projects.length === 0 || isLoading || isDisabled

    return (
      <Select
        items={projectItems}
        disabled={isSelectingDisabled}
        itemRenderer={this.renderProjectItem}
        onItemSelect={this.onItemSelect}
        className="project-select"
        popoverProps={{
          popoverClassName: 'project-select-popover',
          minimal: true,
        }}
        filterable={false}
        scrollToActiveItem={true}
      >
        <Button
          text={this.projectNameAndClock}
          rightIcon={<Icons.DownArrow className="bp3-icon" />}
          disabled={isSelectingDisabled}
          loading={isLoading}
        />
      </Select>
    )
  }

  private onItemSelect = (project: Project | string) => {
    const {
      onItemSelect = NOOP,
      onNewProjectSelected = NOOP,
      shouldShowNewProjectItem = false,
    } = this.props

    if (shouldShowNewProjectItem && typeof project === 'string') {
      return onNewProjectSelected()
    }

    onItemSelect(project as Project)
  }

  private renderProjectItem: ItemRenderer<Project | string> = (
    project,
    { handleClick, modifiers },
  ) => {
    if (typeof project === 'string') {
      return (
        <MenuItem
          active={modifiers.active}
          key={NEW_PROJECT_KEY}
          onClick={handleClick}
          text={this.renderProjectName(project)}
        />
      )
    }

    return (
      <MenuItem
        active={modifiers.active}
        key={project.id}
        onClick={handleClick}
        text={this.renderProjectNameAndAddress(project)}
        className={toggleClass(
          'active-project',
          this.props.currentProject.id === project.id,
        )}
      />
    )
  }

  private renderProjectNameAndAddress = ({
    name,
    id,
    dateTimeFormat,
    timezoneId,
  }: Project) => {
    const { projectsStore, projectDateStore, currentProject } = this.props
    const address = projectsStore.getAddressString(id)

    return (
      <div className="row">
        <div className="col">
          {this.renderProjectName(name)}
          <div className="project-address">
            <div>{address}</div>
            <div className="project-time">
              <ClockComponent
                format={projectDateStore.getDateFormat(dateTimeFormat)?.time}
                timezoneId={timezoneId || projectDateStore.userTimezone}
              />
            </div>
          </div>
        </div>
        {currentProject.id === id && (
          <div className="no-grow pr15 active-check-icon">
            <Icons.CheckSolidGrey />
          </div>
        )}
      </div>
    )
  }

  private renderProjectName(projectName: string) {
    return <div className="project-name">{projectName}</div>
  }

  private get projectNameAndClock() {
    const { shouldShowProjectClock, projectDateStore } = this.props
    return (
      <>
        {shouldShowProjectClock && (
          <span className="pr20">
            <ClockComponent format={projectDateStore.currentFormat.time} />
          </span>
        )}
        <span>
          {this.props.currentProject?.name ||
            Localization.translator.newProject}
        </span>
      </>
    )
  }
}
