import * as React from 'react'

import { classList } from 'react-classlist-helper'

interface IProps {
  isActive: boolean
  isDisabled: boolean
  onClick(): void
}

export default class ActualDateButton extends React.PureComponent<IProps> {
  public render() {
    const { children, isActive, isDisabled, onClick } = this.props
    return (
      <div
        onClick={onClick}
        className={classList({
          'actual-date-button text center': true,
          active: isActive,
          'disabled inactive-element': isDisabled,
        })}
      >
        {children}
      </div>
    )
  }
}
