import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconName, IconNames } from '@blueprintjs/icons'
import { classList } from 'react-classlist-helper'

import Localization from '../../localization/LocalizationManager'

import './CompactPreviewMenu.scss'

interface IProps {
  phoneNumber: string
  emailValue: string
  onHide: () => void

  shouldHideDirectMessage?: boolean
}

export default class CompactPreviewMenu extends React.Component<IProps> {
  public render() {
    const { phoneNumber, emailValue, shouldHideDirectMessage } = this.props

    return (
      <>
        <div className="dimmer shown" onClick={this.hideCompactPreviewMenu} />
        <div className="bg-white absolute text extra-large brada4 compact-preview-menu">
          {!!phoneNumber && (
            <div
              className="pa15 row pointer"
              onClick={this.hideCompactPreviewMenu}
            >
              {this.renderMoreMenuIcon(IconNames.PHONE)}
              {this.renderOptionAnchor(
                'tel:',
                Localization.translator.call_verb,
                phoneNumber,
              )}
            </div>
          )}
          {!!emailValue && (
            <div
              className={classList({
                'pa15 row pointer': true,
                'bt-palette-brand-lighter': !!phoneNumber,
              })}
              onClick={this.hideCompactPreviewMenu}
            >
              {this.renderMoreMenuIcon(IconNames.ENVELOPE)}
              {this.renderOptionAnchor(
                'mailto:',
                Localization.translator.email_verb,
                emailValue,
              )}
            </div>
          )}
          {!shouldHideDirectMessage && (
            <div
              className={classList({
                'pa15 row inactive-element': true,
                'bt-palette-brand-lighter': !!phoneNumber || !!emailValue,
              })}
            >
              {this.renderMoreMenuIcon(IconNames.COMMENT)}
              <span>{Localization.translator.sendDirectMessageInStruxHub}</span>
            </div>
          )}
        </div>
      </>
    )
  }

  private renderMoreMenuIcon(icon: IconName): JSX.Element {
    return (
      <Icon icon={icon} iconSize={Icon.SIZE_LARGE} className="no-grow mr15" />
    )
  }

  private renderOptionAnchor(
    hrefType: string,
    label: string,
    value: string,
  ): JSX.Element {
    return (
      <a href={hrefType + value} className="text-ellipsis">
        <span className="mr5 text extra-large">{label}</span>
        {value}
      </a>
    )
  }

  private hideCompactPreviewMenu = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation()

    this.props.onHide()
  }
}
