import PinchZoomPanDefault from 'react-responsive-pinch-zoom-pan'

class PinchZoomPanCustom extends PinchZoomPanDefault {
  public zoom(requestedScale: number, ...args: any[]) {
    // @ts-ignore: use props of ancestor
    const defaultZoom = this.props.shouldPreventDefaultZoom ? 1 : requestedScale
    super.zoom(defaultZoom, ...args)
    // @ts-ignore: use props of ancestor
    this.props.onScaleChanged(requestedScale)
  }
}

const PinchZoomPan: any = PinchZoomPanCustom
export default PinchZoomPan
