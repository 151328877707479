import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import FilterHandleButton from '~/client/src/shared/components/Filters/components/FilterHandleButton/FilterHandleButton'

import PhotosPageStore, {
  IPhotosFilterOption,
  PhotoFilterTypes,
} from '../PhotosPageStore.store'
import PhotosFilterContent from './PhotoFilterContent'

import './PhotosFilter.scss'

interface ICategoryFilterProps {
  options: IPhotosFilterOption[]
  photoFilterType: PhotoFilterTypes
  values: string[]
  store: PhotosPageStore
  state?: DesktopInitialState
}

@inject('state')
@observer
export default class PhotosFilter extends React.Component<ICategoryFilterProps> {
  public UNSAFE_componentWillMount() {
    // set all options checked by default
    this.onSelectAllClicked()
  }

  public UNSAFE_componentWillReceiveProps(newProps: ICategoryFilterProps) {
    // set new options checked
    const { values, options, photoFilterType, store } = newProps
    const newOptionsValues = []
    options.forEach(option => {
      if (!this.props.options.some(({ id }) => id === option.id)) {
        newOptionsValues.push(option.id)
      }
    })
    if (newOptionsValues.length) {
      store.onApplyClick(values.concat(newOptionsValues), photoFilterType)
    }
  }

  public render() {
    const { photoFilterType, store, values, options } = this.props
    const { activeFilterType, toggle } = store

    const shouldShow = activeFilterType === photoFilterType

    return (
      <div className="photo-filter relative">
        <FilterHandleButton
          isSelected={shouldShow}
          isActive={shouldShow}
          onClick={toggle.bind(this, photoFilterType)}
        >
          <span>{photoFilterType}</span>
        </FilterHandleButton>
        {shouldShow && (
          <div className="filter-container">
            <PhotosFilterContent
              options={options}
              values={values}
              store={store}
              photoFilterType={photoFilterType}
            />
          </div>
        )}
      </div>
    )
  }

  protected onSelectAllClicked = () => {
    const { store, options, photoFilterType } = this.props
    const allValues = options.map(option => option.id)
    store.onApplyClick(allValues, photoFilterType)
  }
}
