import * as React from 'react'

import { Classes } from '@blueprintjs/core'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Checkbox from '~/client/src/shared/components/Checkbox'
import { ILWFCColumn } from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import WorkflowCardStatus from '~/client/src/shared/components/WorkflowCard/Status'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ILocationTransferPair from '~/client/src/shared/models/ILocationTransferPair'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import { NOOP } from '~/client/src/shared/utils/noop'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import { DataKeys } from '../MaterialsList.store'
import BaseDataCellTable from './BaseDataCell/BaseDataCellTable'
import CurrentLocationStatusIcon from './CurrentLocationStatusIcon'

// localization: translated

interface IProps {
  isTransferEnabled: boolean
  plannedDeliveryLocationId: string
  plannedInstallLocationId: string
  transferPairs: ILocationTransferPair[]
  selectedLocationsMap: Map<string, ILocationTransferPair>
  onTransferIdClick(transferId: string): void

  locationRenderer?(location: LocationBase): JSX.Element
}

const MULTIPLE_TRANSFERS_ROW_HEIGHT = 32
const DEFAULT_ROW_HEIGHT = 40

@observer
export default class CurrentLocationCellTable extends React.Component<IProps> {
  public render() {
    return (
      <BaseDataCellTable<ILocationTransferPair>
        items={this.props.transferPairs}
        columns={this.columns}
        getRowHeightByItem={this.getRowHeightByTransferPair}
        getCellContentByDataKey={this.getCellContentByDataKey}
        isFooterHidden
        getFooterTitleByDataKey={NOOP}
        onIdClick={NOOP}
        onSelectAll={this.selectAllLocations}
        onClearAll={this.clearAllLocations}
      />
    )
  }

  private getCellContentByDataKey = (
    dataKey: string,
    transferPair: ILocationTransferPair,
  ): JSX.Element => {
    switch (dataKey) {
      case DataKeys.CHECKBOX:
        return (
          <Checkbox
            className={classList({
              'text large no-bold material-location-checkbox': true,
              checked: transferPair.isSelected,
            })}
            isCentered={true}
            isChecked={transferPair.isSelected}
            onClick={this.toggleSelectedLocation.bind(this, transferPair)}
          />
        )
      case DataKeys.PLANNED_QUANTITY:
        return (
          <span className="text large end ellipsis">
            {transferPair.quantity}
          </span>
        )
      case DataKeys.CURRENT_MATERIAL_LOCATION:
        return this.renderLocation(transferPair)
      case DataKeys.MATERIAL_TRANSFER_ID:
        return this.renderTransferIds(transferPair)
      case DataKeys.STATUS:
        return this.renderTransferStatuses(transferPair)
      default:
        return null
    }
  }

  private renderLocation = (
    transferPair: ILocationTransferPair,
  ): JSX.Element => {
    const {
      locationRenderer,
      plannedDeliveryLocationId,
      plannedInstallLocationId,
    } = this.props

    if (!transferPair.location?.id) {
      return locationRenderer(null)
    }

    return (
      <div className="col overflow-hidden">
        <div className="row py2">
          {locationRenderer(transferPair.location)}
          <CurrentLocationStatusIcon
            transferPairs={[transferPair]}
            plannedDeliveryLocationId={plannedDeliveryLocationId}
            plannedInstallLocationId={plannedInstallLocationId}
          />
        </div>
      </div>
    )
  }

  private renderTransferIds = (
    transferPair: ILocationTransferPair,
  ): JSX.Element => {
    if (!transferPair.materialTransfers?.length) {
      return <span className="text large end ellipsis">{NO_VALUE}</span>
    }

    return (
      <div className="col overflow-hidden">
        {transferPair.materialTransfers.map(form => (
          <span
            key={form.id}
            className={`row x-end py4 text large ellipsis pointer form-id-link underline-hover ${Classes.POPOVER_DISMISS}`}
            onClick={this.onTransferIdClick.bind(null, form.id)}
          >
            {form.code}
          </span>
        ))}
      </div>
    )
  }

  private renderTransferStatuses = (
    transferPair: ILocationTransferPair,
  ): JSX.Element => {
    if (!transferPair.materialTransfers?.length) {
      return <span className="text large end ellipsis">{NO_VALUE}</span>
    }

    return (
      <div className="col overflow-hidden">
        {transferPair.materialTransfers.map(form => (
          <div key={form.id} className="row x-end py4">
            <WorkflowCardStatus
              className="no-grow"
              status={form.status}
              workflowStepLevel={form.workflowStepLevel}
              isLate={form.isLate}
            />
          </div>
        ))}
      </div>
    )
  }

  private get columns(): ILWFCColumn[] {
    return [
      ...(this.props.isTransferEnabled
        ? [
            {
              dataKey: DataKeys.CHECKBOX,
              width: 30,
            },
          ]
        : []),
      {
        label: Localization.translator.currentLocation,
        dataKey: DataKeys.CURRENT_MATERIAL_LOCATION,
        width: 180,
      },
      {
        label: Localization.translator.quantity,
        dataKey: DataKeys.PLANNED_QUANTITY,
        width: 80,
      },
      {
        label: Localization.translator.materialTransferId,
        dataKey: DataKeys.MATERIAL_TRANSFER_ID,
        width: 140,
      },
      {
        label: Localization.translator.status,
        dataKey: DataKeys.STATUS,
        width: 125,
      },
    ]
  }

  private getRowHeightByTransferPair = (
    transferPair: ILocationTransferPair,
  ): number => {
    if (!transferPair) {
      return 0
    }
    if (
      !transferPair.materialTransfers?.length ||
      transferPair.materialTransfers?.length === 1
    ) {
      return DEFAULT_ROW_HEIGHT
    }
    return transferPair.materialTransfers.length * MULTIPLE_TRANSFERS_ROW_HEIGHT
  }

  @action
  private toggleSelectedLocation(transferPair: ILocationTransferPair) {
    const { selectedLocationsMap } = this.props
    if (selectedLocationsMap.has(transferPair.location.id)) {
      selectedLocationsMap.delete(transferPair.location.id)
    } else {
      selectedLocationsMap.set(transferPair.location.id, transferPair)
    }
  }

  @action.bound
  private selectAllLocations() {
    const { transferPairs, selectedLocationsMap } = this.props
    transferPairs.forEach(tp => selectedLocationsMap.set(tp.location.id, tp))
  }

  @action.bound
  private clearAllLocations() {
    this.props.selectedLocationsMap.clear()
  }

  private onTransferIdClick = (transferId: string) => {
    this.props.onTransferIdClick(transferId)
  }
}
