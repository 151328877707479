import * as React from 'react'

import { action, computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { RouteComponentProps, withRouter } from 'react-router'

import { ContentObjectStatus } from '~/client/graph'
import CommonThread from '~/client/src/shared/components/CommonThread/CommonThread'
import InitialState from '~/client/src/shared/stores/InitialState'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'

import SafetyHazard from '../../models/SafetyHazard'
import CommonStore from '../../stores/ui/Common.store'
import CloseContentObjectButton from '../ContentObject/CloseContentObjectButton'
import ReplyToContentObjectButton from '../ContentObject/ReplyToContentObjectButton'
import FileInputBase from '../FileInput/FileInput'
import SafetyHazardIcon from '../SafetyHazardIcon'

interface ISafetyHazardEntryProps {
  safetyHazard: SafetyHazard
  messagesStore?: MessagesStore
  hideButtons?: boolean
  state?: InitialState
  common?: CommonStore
  FileInputType: typeof FileInputBase
}

@inject('messagesStore', 'state', 'common')
@observer
class SafetyHazardThread extends React.Component<
  ISafetyHazardEntryProps & RouteComponentProps<any>
> {
  private renderButtons(safetyHazard: SafetyHazard) {
    const { userActiveProjectSettings } = this.props.state
    return (
      <div
        className={classList({
          'row item-buttons-container': true,
          'inactive-element': userActiveProjectSettings?.isPresentationUser,
        })}
      >
        <ReplyToContentObjectButton
          contentObject={safetyHazard}
          onClick={this.displayView}
        />
        <CloseContentObjectButton contentObject={safetyHazard} />
      </div>
    )
  }

  @computed
  private get messages() {
    return this.props.messagesStore.list.filter(
      m => m.threadId === this.props.safetyHazard.threadId,
    )
  }

  public render() {
    const { safetyHazard, hideButtons, FileInputType } = this.props
    const isSafetyHazardClosed =
      safetyHazard.status === ContentObjectStatus.Closed
    const buttonsElement = hideButtons ? null : this.renderButtons(safetyHazard)

    return (
      <CommonThread
        showImages={true}
        hideThread={false}
        showFooterLine={true}
        messages={this.messages}
        buttonsElement={buttonsElement}
        threadIcon={<SafetyHazardIcon safetyHazard={safetyHazard} />}
        threadClass={isSafetyHazardClosed ? '' : ' safety-hazard-thread'}
        caption={SafetyHazard.getSafetyHazardTypeCaption(safetyHazard.type)}
        model={safetyHazard}
        customPhotoClick={this.displayView}
        FileInputType={FileInputType}
      />
    )
  }

  @action.bound
  private displayView = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    const { common, safetyHazard } = this.props
    common.displaySafetyHazardView(safetyHazard)
  }
}

export default withRouter(SafetyHazardThread)
