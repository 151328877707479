import * as React from 'react'

import { Classes } from '@blueprintjs/core'

import Checkbox from '~/client/src/shared/components/Checkbox'

import ShowInAppTag from './ShowInAppTag'

interface IProps {
  label: string
  isShown: boolean
  onClick(): void
}

export default function ShowInAppModalRow({
  label,
  isShown,
  onClick,
}: IProps): JSX.Element {
  return (
    <div
      className={`row x-between h40 highlighted-hover-light m5 pl10 pointer ${Classes.POPOVER_DISMISS}`}
      onClick={onClick}
    >
      <ShowInAppTag label={label} />
      <Checkbox
        className="mw40 grey-checkbox"
        isCentered
        isChecked={isShown || false}
      />
    </div>
  )
}
