import { action, computed, observable } from 'mobx'

import { IActivityLinkingSettings, LocationType } from '~/client/graph'
import locationTypesList from '~/client/src/shared/constants/locationTypesList'
import ActivityDataFieldId from '~/client/src/shared/enums/ActivityDataFieldId'
import INewActivityData from '~/client/src/shared/interfaces/INewActivityData'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectDateStore, {
  isAfter,
} from '~/client/src/shared/stores/ui/ProjectDate.store'

import activityFieldIdToLinkingSettingNameMap from '../../../constants/activityFieldIdToLinkingSettingNameMap'
import activityLinkingSettingToCaptionMap from '../../../constants/activityLinkingSettingToCaptionMap'
import ActivityLinkingSettings from '../../../enums/ActivityLinkingSettings'
import CompaniesStore from '../../../stores/domain/Companies.store'
import ProjectMembersStore from '../../../stores/domain/ProjectMembers.store'
import TagsStore from '../../../stores/domain/Tags.store'
import {
  FIELDS_AND_NOTE_SEPARATOR,
  FIELD_LINES_SEPARATOR,
  FIELD_VALUES_SEPARATOR,
} from '../../DeliveryDetails/DeliveryDetails.store'

export enum ActivityFieldType {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  CompanySelect = 'CompanySelect',
  UserSelect = 'UserSelect',
  LocationSelect = 'LocationSelect',
  LinkingSettings = 'LinkingSettings',
}

export interface IActivityField {
  fieldId: string
  required?: boolean
  type: ActivityFieldType
  getCaption?: () => string
  isValid?: () => boolean
  validationMessage?: string
  isActivity?: boolean
  locationType?: LocationType
  isChanged?: boolean
  min?: number
  max?: number
}

const plannedFinishDateCantBeBeforeStart =
  "Planned finish date can't be before planned start date"
const actualFinishDateCantBeBeforeStart =
  "Actual finish date can't be before actual start date"

const dateFieldIds: string[] = [
  ActivityDataFieldId.PLANNED_START_DATE,
  ActivityDataFieldId.PLANNED_FINISH_DATE,
  ActivityDataFieldId.ACTUAL_START_DATE,
  ActivityDataFieldId.ACTUAL_FINISH_DATE,
]
const selectorFields: string[] = [
  ActivityFieldType.CompanySelect,
  ActivityFieldType.UserSelect,
  ActivityFieldType.LocationSelect,
]

export default class ActivityFormStore {
  // editing these fields occurs in activity status updates
  public readonly hiddenFieldsInEditMode: string[] = [
    ActivityDataFieldId.ACTUAL_START_DATE,
    ActivityDataFieldId.ACTUAL_FINISH_DATE,
    ActivityDataFieldId.PERCENT_COMPLETE,
    ActivityDataFieldId.LINKING_SETTINGS,
  ]

  public readonly hiddenFieldsInCreateMode: string[] = [
    ActivityDataFieldId.LINKING_SETTINGS,
  ]

  @observable public readonly fields: IActivityField[] = [
    {
      fieldId: ActivityDataFieldId.CODE,
      required: true,
      type: ActivityFieldType.Text,
      getCaption: () => Localization.translator.activityId,
      isValid: () => !!this.newActivityData.code?.trim(),
      isActivity: true,
    },
    {
      fieldId: ActivityDataFieldId.NAME,
      required: true,
      type: ActivityFieldType.Text,
      getCaption: () => Localization.translator.activityName,
      isValid: () => !!this.newActivityData.name?.trim(),
      isActivity: true,
    },
    {
      fieldId: ActivityDataFieldId.PLANNED_START_DATE,
      required: true,
      type: ActivityFieldType.Date,
      getCaption: () => Localization.translator.plannedActivityStart,
      isValid: () => !!this.newActivityData.plannedStartDate,
      isActivity: true,
    },
    {
      fieldId: ActivityDataFieldId.PLANNED_FINISH_DATE,
      required: true,
      type: ActivityFieldType.Date,
      getCaption: () => Localization.translator.plannedActivityFinish,
      isValid: () => this.isPlannedFinishDateValid,
      validationMessage: plannedFinishDateCantBeBeforeStart,
      isActivity: true,
    },
    {
      fieldId: ActivityDataFieldId.ACTUAL_START_DATE,
      type: ActivityFieldType.Date,
      getCaption: () => Localization.translator.actualActivityStart,
      isActivity: true,
    },
    {
      fieldId: ActivityDataFieldId.ACTUAL_FINISH_DATE,
      type: ActivityFieldType.Date,
      getCaption: () => Localization.translator.actualActivityFinish,
      isValid: () => this.isActualFinishDateValid,
      validationMessage: actualFinishDateCantBeBeforeStart,
      isActivity: true,
    },
    {
      fieldId: ActivityDataFieldId.PERCENT_COMPLETE,
      type: ActivityFieldType.Number,
      getCaption: () => Localization.translator.activityPercentComplete,
      isActivity: true,
      min: 0,
      max: 100,
    },
    {
      fieldId: ActivityDataFieldId.COMPANY_ID,
      type: ActivityFieldType.CompanySelect,
      getCaption: () => Localization.translator.company,
      isActivity: true,
    },
    {
      fieldId: ActivityDataFieldId.REQUESTER_ID,
      type: ActivityFieldType.UserSelect,
      getCaption: () => Localization.translator.requester,
      isActivity: true,
    },
    {
      fieldId: ActivityDataFieldId.EQUIPMENT_ID,
      type: ActivityFieldType.LocationSelect,
      getCaption: () => Localization.translator.equipment,
      locationType: LocationType.OffloadingEquipment,
    },
    {
      fieldId: ActivityDataFieldId.VERTICAL_OBJECT_ID,
      type: ActivityFieldType.LocationSelect,
      getCaption: () => Localization.translator.verticalObject,
      locationType: LocationType.VerticalObject,
    },
    {
      fieldId: ActivityDataFieldId.BUILDING_ID,
      type: ActivityFieldType.LocationSelect,
      getCaption: () => Localization.translator.building,
      locationType: LocationType.Building,
    },
    {
      fieldId: ActivityDataFieldId.ZONE_ID,
      type: ActivityFieldType.LocationSelect,
      getCaption: () => Localization.translator.zone,
      locationType: LocationType.Zone,
    },
    {
      fieldId: ActivityDataFieldId.LEVEL_ID,
      type: ActivityFieldType.LocationSelect,
      getCaption: () => Localization.translator.level,
      locationType: LocationType.Level,
    },
    {
      fieldId: ActivityDataFieldId.AREA_ID,
      type: ActivityFieldType.LocationSelect,
      getCaption: () => Localization.translator.area,
      locationType: LocationType.Area,
    },
    {
      fieldId: ActivityDataFieldId.GATE_ID,
      type: ActivityFieldType.LocationSelect,
      getCaption: () => Localization.translator.gate,
      locationType: LocationType.Gate,
    },
    {
      fieldId: ActivityDataFieldId.ROUTE_ID,
      type: ActivityFieldType.LocationSelect,
      getCaption: () => Localization.translator.route,
      locationType: LocationType.Route,
    },
    {
      fieldId: ActivityDataFieldId.LINKING_SETTINGS,
      type: ActivityFieldType.LinkingSettings,
      isActivity: true,
    },
  ]

  @observable public newActivityData: INewActivityData
  @observable public selectedField: IActivityField = null
  private initialActivityData: INewActivityData

  public constructor(
    private readonly projectDateStore: ProjectDateStore,
    private readonly companiesStore: CompaniesStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly tagsStore: TagsStore,
  ) {}

  @action.bound
  public initDraftData(draftActivityData?: INewActivityData) {
    const draftData = draftActivityData || {
      id: null,
      code: '',
      name: '',
      activityLinkingSettings: {},
    }

    this.newActivityData = draftData
    this.initialActivityData = draftData
    this.resetIsChangedState()
  }

  @action.bound
  public setSelectedField(field: IActivityField) {
    this.selectedField = field
  }

  @action.bound
  public unsetSelectedField() {
    this.selectedField = null
  }

  @action.bound
  public changeFieldValue(fieldId: string, fieldValue: string | number) {
    if (!fieldId) {
      return
    }

    this.newActivityData[fieldId] = fieldValue
    this.defineIsChangedStateForField(fieldId)
  }

  @action.bound
  public changeSelectedFieldValue(newValue: string) {
    this.newActivityData[this.selectedField?.fieldId] = newValue || ''
    this.defineIsChangedStateForField(this.selectedField?.fieldId)
  }

  @action.bound
  public changeFieldLinkingSetting(fieldId: string, value: boolean) {
    const linkingSetting = activityFieldIdToLinkingSettingNameMap[fieldId]
    this.newActivityData[ActivityDataFieldId.LINKING_SETTINGS][linkingSetting] =
      value
    this.defineIsChangedStateForField(ActivityDataFieldId.LINKING_SETTINGS)
  }

  @action.bound
  public resetIsChangedState() {
    this.fields.forEach((field, index) => {
      this.fields[index].isChanged = false
    })
  }

  @action.bound
  public resetActivityCode() {
    this.newActivityData[ActivityDataFieldId.CODE] = ''
  }

  @action.bound
  public resetFieldValue(fieldId: string) {
    this.newActivityData[fieldId] = this.initialActivityData[fieldId]
    this.defineIsChangedStateForField(fieldId)
  }

  @computed
  public get areFieldsValid(): boolean {
    return this.fields.every(f => !f.isValid || f.isValid())
  }

  @computed
  public get areFieldsChanged(): boolean {
    if (!this.initialActivityData.id) {
      return true
    }
    return this.fields.some(f => f.isChanged)
  }

  @computed
  public get changedFieldsMessage(): string {
    const changedFieldLines = []

    this.fields
      .filter(f => f.isChanged)
      .forEach(field => {
        const oldFieldValue = this.initialActivityData[field.fieldId]
        const newFieldValue = this.newActivityData[field.fieldId]

        if (field.fieldId === ActivityDataFieldId.LINKING_SETTINGS) {
          changedFieldLines.push(
            ...this.getChangedLinkingSettingsMessages(
              oldFieldValue,
              newFieldValue,
            ),
          )
        } else {
          changedFieldLines.push(
            this.getChangedFieldMessage(oldFieldValue, newFieldValue, field),
          )
        }
      })

    const changedFieldsMessage = changedFieldLines.join(FIELD_LINES_SEPARATOR)
    return changedFieldsMessage + FIELDS_AND_NOTE_SEPARATOR
  }

  @computed
  public get isLocationFieldSelected(): boolean {
    return !!this.selectedField?.locationType
  }

  @computed
  public get isCompanyFieldSelected(): boolean {
    return this.selectedField?.type === ActivityFieldType.CompanySelect
  }

  @computed
  public get isUserFieldSelected(): boolean {
    return this.selectedField?.type === ActivityFieldType.UserSelect
  }

  @computed
  public get restrictedLocationTypes(): LocationType[] {
    return locationTypesList.filter(t => t !== this.selectedField?.locationType)
  }

  @computed
  public get selectedLocationFieldValue(): string[] {
    const locationFieldValue = this.newActivityData[this.selectedField?.fieldId]
    return this.isLocationFieldSelected && locationFieldValue
      ? [locationFieldValue]
      : []
  }

  public get activityCode(): string {
    return this.newActivityData.code
  }

  private get isPlannedFinishDateValid(): boolean {
    const { plannedStartDate, plannedFinishDate } = this.newActivityData

    return !!plannedFinishDate && !isAfter(plannedStartDate, plannedFinishDate)
  }

  private get isActualFinishDateValid(): boolean {
    const { actualStartDate, actualFinishDate } = this.newActivityData

    if (!actualStartDate || !actualFinishDate) {
      return true
    }

    return !isAfter(actualStartDate, actualFinishDate)
  }

  public getChangedFieldMessage = (
    oldValue: IActivityField,
    newValue: IActivityField,
    field: IActivityField,
  ): string => {
    const fieldName = field.getCaption()
    const oldValueCaption = this.getFieldCaptionValue(oldValue, field)
    const newValueCaption = this.getFieldCaptionValue(newValue, field)

    return `${fieldName}${FIELD_VALUES_SEPARATOR}${oldValueCaption}${FIELD_VALUES_SEPARATOR}${newValueCaption}`
  }

  public getChangedLinkingSettingsMessages = (
    oldValue: IActivityLinkingSettings,
    newValue: IActivityLinkingSettings,
  ): string[] => {
    const messages = []

    Object.values(ActivityLinkingSettings).forEach(linkingSetting => {
      if (oldValue[linkingSetting] !== newValue[linkingSetting]) {
        const fieldName = activityLinkingSettingToCaptionMap[linkingSetting]
        const isFieldLinked = newValue[linkingSetting]

        const text = isFieldLinked
          ? Localization.translator.activityDataLinking.linkingEnabled
          : Localization.translator.activityDataLinking.linkingDisabled

        messages.push(
          `${fieldName}${FIELD_VALUES_SEPARATOR}${null}${FIELD_VALUES_SEPARATOR}${text}`,
        )
      }
    })
    return messages
  }

  private defineIsChangedStateForField = (fieldId: string) => {
    const index = this.fields.findIndex(f => f.fieldId === fieldId)

    if (index === -1) {
      return
    }

    if (fieldId === ActivityDataFieldId.LINKING_SETTINGS) {
      let isChanged = false
      Object.values(ActivityLinkingSettings).forEach(linkingSetting => {
        if (
          this.initialActivityData[fieldId][linkingSetting] !==
          this.newActivityData[fieldId][linkingSetting]
        ) {
          isChanged = true
        }
      })
      this.fields[index].isChanged = isChanged
      return
    }

    this.initialActivityData[fieldId] != this.newActivityData[fieldId]
      ? (this.fields[index].isChanged = true)
      : (this.fields[index].isChanged = false)
  }

  private getFieldCaptionValue = (
    fieldValue: any,
    field: IActivityField,
  ): string => {
    const { getMonthDayAndYearToDisplay } = this.projectDateStore

    if (dateFieldIds.includes(field.fieldId)) {
      return getMonthDayAndYearToDisplay(new Date(fieldValue))
    } else if (selectorFields.includes(field.type)) {
      switch (field.type) {
        case ActivityFieldType.CompanySelect:
          return (
            fieldValue && this.companiesStore.getCompanyById(fieldValue).name
          )
        case ActivityFieldType.UserSelect:
          return (
            fieldValue && this.projectMembersStore.getById(fieldValue).fullName
          )
        case ActivityFieldType.LocationSelect:
          return fieldValue && this.tagsStore.getTagById(fieldValue).name
      }
    }

    return fieldValue
  }
}
