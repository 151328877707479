import React from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { createPortal } from 'react-dom'

interface IProps {
  content: React.ReactElement
  className?: string
  portalClassName?: string
}

const LEFT_OFFSET_FROM_MOUSE = 6
const TOP_OFFSET_FROM_MOUSE = 26

@observer
export default class CursorFollowTooltip extends React.Component<IProps> {
  @observable private portalStyle: React.CSSProperties = null

  private portalContainer: HTMLDivElement = document.createElement('div')
  private containerRef = React.createRef<HTMLDivElement>()

  public constructor(props) {
    super(props)
    this.portalContainer.classList.add('cursor-follow-tooltip')
  }

  public render() {
    const { content, children, className, portalClassName } = this.props

    return (
      <span
        className={className}
        onMouseMove={this.handleMouseMove}
        onMouseLeave={this.handleMouseLeave}
        onMouseEnter={this.handleMouseEnter}
      >
        {children}
        {this.portalStyle &&
          createPortal(
            <div
              className={classList({
                'fixed bg-white normalized-shadow brada4 z-index-1000': true,
                [portalClassName]: !!portalClassName,
              })}
              style={{ ...this.portalStyle }}
              ref={this.containerRef}
            >
              {content}
            </div>,
            this.portalContainer,
          )}
      </span>
    )
  }

  public componentWillUnmount() {
    if (document.body.contains(this.portalContainer)) {
      document.body.removeChild(this.portalContainer)
    }
  }

  private handleMouseMove = (e: React.MouseEvent) => {
    const left = e.clientX + LEFT_OFFSET_FROM_MOUSE
    const top = e.clientY + TOP_OFFSET_FROM_MOUSE

    const contentWidth = this.containerRef.current?.children[0].clientWidth
    const contentHeight = this.containerRef.current?.children[0].clientHeight

    const isEmptyOnLeft = contentWidth
      ? e.clientX + contentWidth < window.innerWidth
      : true
    const isEmptyOnBottom = contentHeight
      ? e.clientY + contentHeight < window.innerHeight
      : true

    this.portalStyle = {
      left: isEmptyOnLeft ? left : left - contentWidth - LEFT_OFFSET_FROM_MOUSE,
      top: isEmptyOnBottom ? top : top - contentHeight - TOP_OFFSET_FROM_MOUSE,
    }
  }

  private handleMouseEnter = () => {
    document.body.appendChild(this.portalContainer)
  }

  private handleMouseLeave = () => {
    this.portalStyle = null
  }
}
