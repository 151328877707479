import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { OperationSubjectType } from '~/client/graph'
import TagsSelector from '~/client/src/desktop/components/TagsSelector/TagsSelector'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import * as Icons from '~/client/src/shared/components/Icons'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import TagsList, {
  TAG_ROW_HEIGHT,
} from '~/client/src/shared/components/TagsList/TagsList'
import UsersDirectory from '~/client/src/shared/components/UsersDirectory/UsersDirectory'
import { RoundBracket } from '~/client/src/shared/enums/Brackets'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import {
  AndOrOperator,
  getOperatorDisplayText,
} from '~/client/src/shared/models/LogicOperation'
import Tag, { ITag } from '~/client/src/shared/models/Tag'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import ExpressionParser from '~/client/src/shared/utils/ExpressionParser/ExpressionParser'
import Pointer from '~/client/src/shared/utils/ExpressionParser/components/Pointer'
import { NOOP } from '~/client/src/shared/utils/noop'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import { NotificationSetupModes } from '../../../../NotificationsSetup'
import ExpressionControlStore from './ExpressionControl.store'
import PlusControl from './components/PlusControl/PlusControl'
import TagsByGroupsModal from './components/TagsByGroupsModal/TagsByGroupsModal'

import './ExpressionControl.scss'

// translated

interface IProps {
  expression: string
  onChange: (expression: string) => void
  subject?: OperationSubjectType

  mode?: NotificationSetupModes
  isElementsRemovable?: boolean
  availableTags?: Tag[]
  shouldContainsTagsSelectorHandler?: boolean
  shouldContainsDeleteIcon?: boolean
  shouldUseTagsSelector?: boolean

  onDeleteExpression?: (deletedExpression: string) => void
  onPlusControlCustomClick?: (
    operation: AndOrOperator,
    defaultHandler: (operation: AndOrOperator) => void,
  ) => void
  measureSize: () => void

  tagsStore?: TagsStore
}

const availableTagTypes = [
  TagType.User,
  TagType.Role,
  TagType.Team,
  TagType.Company,
]

const { CARET_DOWN } = IconNames
const I_SIZE = 12

@inject('tagsStore')
@observer
export default class ExpressionControl extends React.Component<IProps> {
  public static defaultProps = {
    availableTags: [],
  }

  private static renderTag(tag: ITag) {
    const tagName = tag.name || Localization.translator.unknownDeletedObject

    return (
      <SitemapAttributeTag
        dataObject={tag as LocationAttributeBase}
        shouldShowAsTag={true}
        contentContainerClassName="text-ellipsis py2"
      >
        <span title={tagName} className="text large">
          {tagName}
        </span>
      </SitemapAttributeTag>
    )
  }

  private readonly store: ExpressionControlStore = null
  private activeInputRef: HTMLInputElement = null

  public constructor(props: IProps) {
    super(props)

    this.store = new ExpressionControlStore(
      props.tagsStore,
      props.onChange,
      props.onPlusControlCustomClick,
      props.measureSize,
      props.availableTags,
      props.expression,
      props.subject,
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { expression, subject } = this.props

    if (prevProps.expression !== expression) {
      this.store.init(expression, subject)
      return
    }

    this.store.measureSize()
  }

  public componentDidMount() {
    if (this.props.measureSize) {
      this.props.measureSize()
    }
  }

  public render() {
    const { shouldContainsDeleteIcon, onDeleteExpression } = this.props
    const {
      isHovering,
      resetHovering,
      hideUserTagsModals,
      isActivePointerFilled,
      shouldShowUsersDirectory,
      shouldShowTagsSelector,
    } = this.store

    return (
      <div className="expression-control-container">
        <div
          className={classList({
            'row brada4 pa10 ba2-transparent tag-list': true,
            'ba2-palette-brand-primary': isActivePointerFilled,
            'beautiful-shadow': isHovering,
          })}
          onMouseEnter={this.setHovering}
          onMouseLeave={resetHovering}
        >
          <MenuCloser
            closeMenu={hideUserTagsModals}
            isOpen={shouldShowUsersDirectory || shouldShowTagsSelector}
          >
            <div
              className="row wrap no-flex-children-direct y-center"
              onClick={this.stopPropagation}
            >
              {this.renderExpressionElements()}
              {this.renderTagsSelector()}
              {this.renderUsersDirectoryModal()}
            </div>
          </MenuCloser>
          {this.renderAddTagButton()}
          {this.renderTagsListPullDown()}
          {shouldContainsDeleteIcon && isHovering && (
            <Icons.Delete
              className="no-grow pointer"
              onClick={onDeleteExpression.bind(null, this.props.expression)}
            />
          )}
        </div>
        {this.renderTagsByGroupsPullDown()}
      </div>
    )
  }

  private renderExpressionElements(): JSX.Element[] {
    const { isElementsRemovable } = this.props
    const { expressionElements, removeElement } = this.store

    return expressionElements.map((element, index) => {
      let node: any = element

      switch (true) {
        case ExpressionParser.isPointerElement(element):
          node = this.renderPointerElement(element as Pointer)
          break

        case ExpressionParser.isTagElement(element):
          node = ExpressionControl.renderTag(element as Tag)
          break

        case ExpressionParser.isLogicalOperationElement(element):
          node = getOperatorDisplayText(element as AndOrOperator)
          break
      }

      const isNodeRemovable =
        isElementsRemovable && !ExpressionParser.isPointerElement(element)

      const containerClickHandler = isNodeRemovable
        ? removeElement.bind(this, element, index)
        : NOOP
      const isBracketElement =
        node === RoundBracket.LEFT || node === RoundBracket.RIGHT

      return (
        <span
          key={index}
          className={classList({
            'inline-flex row x-center y-center no-grow mr4 expr-element':
              !!node,
            'cross-cursor': isNodeRemovable,
            'w32 text center monospace large':
              ExpressionParser.isLogicalOperationElement(element),
            'text extra-large bold': isBracketElement,
          })}
          onClick={containerClickHandler}
        >
          {node}
        </span>
      )
    })
  }

  private handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.store.setActivePointerValue(evt.target.value)
  }

  private setHovering = () => {
    this.store.setHovering()
    this.focusActiveInput()
  }

  @action.bound
  private handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    switch (e.key) {
      case RoundBracket.LEFT:
      case RoundBracket.RIGHT:
        e.preventDefault()
        this.store.startExpressionGroup()
    }
  }

  private setActiveInputRef = (ref: HTMLInputElement) => {
    this.activeInputRef = ref
  }

  private focusActiveInput() {
    if (this.activeInputRef) {
      this.activeInputRef.focus()
    }
  }

  private renderPointerElement(pointer: Pointer): JSX.Element {
    const {
      isHovering,
      shouldShowAddUserTagsBtn,
      activatePointer,
      expressionElements,
      clickOnPlusControl,
    } = this.store

    if (!isHovering && expressionElements.length > 1) {
      return null
    }

    const activationHandler = activatePointer.bind(null, pointer)

    if (pointer.isLogical) {
      return (
        <span onMouseDown={activationHandler}>
          <PlusControl className="inline-flex" onSelect={clickOnPlusControl} />
        </span>
      )
    }

    const { value } = pointer
    const shouldShowPlaceholder = expressionElements.length === 1
    const placeholder = shouldShowPlaceholder
      ? Localization.translator.searchForOrSelectTag
      : EMPTY_STRING

    if (this.props.shouldUseTagsSelector && shouldShowAddUserTagsBtn) {
      return this.renderAddUserTagsButton()
    }

    return (
      <input
        type="text"
        value={value}
        className={classList({
          'bare-input': true,
          'full-width': shouldShowPlaceholder,
        })}
        autoFocus={isHovering}
        ref={this.setActiveInputRef}
        onKeyDown={this.handleKeyDown}
        onChange={this.handleInputChange}
        onMouseDown={activationHandler}
        size={shouldShowPlaceholder ? undefined : value.length || 1}
        placeholder={placeholder}
      />
    )
  }

  private renderTagsByGroupsPullDown(): JSX.Element {
    if (!this.props.shouldContainsTagsSelectorHandler) {
      return null
    }

    const {
      shouldShowPullDown,
      shouldShowTagsByGroupsModal,
      selectTag,
      hideTagsByGroupsModal,
    } = this.store

    if (!shouldShowPullDown) {
      return null
    }

    return (
      <TagsByGroupsModal
        shouldShownExtendedSection={true}
        onOptionClick={selectTag}
        shouldShown={shouldShowTagsByGroupsModal}
        onClose={hideTagsByGroupsModal}
        shouldUseScrollOnContent={true}
        mode={this.props.mode}
      />
    )
  }

  private renderAddTagButton(): JSX.Element {
    if (!this.props.shouldContainsTagsSelectorHandler) {
      return null
    }

    const {
      shouldShowPullDown,
      shouldShowTagsByGroupsModal,
      toggleTagsByGroupsModal,
    } = this.store

    if (!shouldShowPullDown) {
      return null
    }

    const addTagBtnIcon = (
      <Icons.TagRight style={{ width: I_SIZE, height: I_SIZE }} />
    )

    return this.renderAddButton(
      Localization.translator.addTag,
      addTagBtnIcon,
      shouldShowTagsByGroupsModal,
      toggleTagsByGroupsModal,
      true,
    )
  }

  private renderTagsListPullDown(): JSX.Element {
    const { isActivePointerFilled, suggestedTags, selectTag } = this.store

    if (this.props.shouldUseTagsSelector || !isActivePointerFilled) {
      return null
    }

    return (
      <div
        style={{
          height: Math.max(
            suggestedTags.length * TAG_ROW_HEIGHT,
            TAG_ROW_HEIGHT,
          ),
        }}
        className="pull-wrapper col absolute full-width ba-light-cool-grey overflow-hidden beautiful-shadow"
      >
        <TagsList tags={suggestedTags} onTagClick={selectTag} />
      </div>
    )
  }

  private renderAddUserTagsButton(): JSX.Element {
    const { shouldShowPullDown, shouldShowTagsSelector, toggleTagsSelector } =
      this.store

    if (!shouldShowPullDown) {
      return null
    }

    const userTagsBtnIcon = <Icons.User className="user-icon" />

    return this.renderAddButton(
      Localization.translator.addUserTeamCompany,
      userTagsBtnIcon,
      shouldShowTagsSelector,
      toggleTagsSelector,
    )
  }

  private renderAddButton(
    title: string,
    icon: JSX.Element,
    isActive: boolean,
    onClick: () => void,
    shouldIncludeRightIcon?: boolean,
  ): JSX.Element {
    const { shouldShowPullDown } = this.store

    if (!shouldShowPullDown) {
      return null
    }

    const rightIcon = <Icon icon={CARET_DOWN} iconSize={I_SIZE} />

    return (
      <BaseActionButton
        isEnabled={true}
        className="add-tag-pull-down-handle"
        title={title}
        isActive={isActive}
        icon={icon}
        rightIcon={shouldIncludeRightIcon && rightIcon}
        onClick={onClick}
      />
    )
  }

  private renderTagsSelector(): JSX.Element {
    const {
      shouldShowTagsSelector,
      shouldShowUsersDirectory,
      activePointerValue,
      selectTag,
      toggleUsersDirectory,
    } = this.store

    if (
      !this.props.shouldUseTagsSelector ||
      !shouldShowTagsSelector ||
      shouldShowUsersDirectory
    ) {
      return null
    }

    return (
      <div className="users-tags-selector col absolute full-width ba-light-cool-grey overflow-hidden">
        <div className="row x-center my10">
          <span
            className="no-grow nowrap text blue-highlight large bold pointer"
            onClick={toggleUsersDirectory}
          >
            {Localization.translator.gotToDirectory}
          </span>
        </div>
        <TagsSelector
          onChange={NOOP}
          onTagSelected={selectTag}
          className="px20 pb10 overflow-auto"
          availableTypes={availableTagTypes}
          separatedCategories={[TagType.Company]}
          radioTagTypes={availableTagTypes}
          searchKey={activePointerValue}
        />
      </div>
    )
  }

  private renderUsersDirectoryModal(): JSX.Element {
    const { shouldShowUsersDirectory, selectUser } = this.store

    if (!this.props.shouldUseTagsSelector || !shouldShowUsersDirectory) {
      return null
    }

    return (
      <div className="users-directory-selector col absolute">
        <UsersDirectory
          shouldHideFilters={true}
          shouldUseGroupByModal={true}
          onUserRowClick={selectUser}
          className="scrollable beautiful-shadow pt15 brada4"
        />
      </div>
    )
  }

  private stopPropagation = (e: React.SyntheticEvent) => {
    e?.stopPropagation()
  }
}
