import { SaveRoutesDocument } from '~/client/graph/operations/generated/LocationType.generated'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import Route from '~/client/src/shared/models/LocationObjects/Route'
import ILocationAttributeDto from '~/client/src/shared/types/ILocationAttributeDto'

import LocationAttributesStoreBase from './LocationAttributesBase.store'

export default class RoutesStore extends LocationAttributesStoreBase<Route> {
  protected saveMutation = SaveRoutesDocument
  protected graphName = 'routes'
  protected defaultIconName = MapViewLocationIcon.Route

  public get byId() {
    const { routes } = this.eventsStore.appState
    return routes
  }

  protected fromDto(dto: ILocationAttributeDto): Route {
    return Route.fromDto(dto)
  }
}
