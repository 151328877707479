import { action, observable } from 'mobx'

export default class TagsDirectoryListRowStore {
  @observable public isHovering: boolean = false
  @observable public isTagEditModeEnabled: boolean = false
  @observable public shouldShowMemberSelector: boolean = false

  @action.bound
  public handleMouseEnter() {
    this.isHovering = true
  }

  @action.bound
  public handleMouseLeave() {
    this.isHovering = false
  }

  @action.bound
  public toggleTagEditingMode() {
    this.isTagEditModeEnabled = !this.isTagEditModeEnabled
  }

  @action.bound
  public disableTagEditingMode() {
    this.isTagEditModeEnabled = false
  }

  @action.bound
  public toggleMemberSelector() {
    this.shouldShowMemberSelector = !this.shouldShowMemberSelector
  }

  @action.bound
  public hideMemberSelector() {
    this.shouldShowMemberSelector = false
  }
}
