export function getTextSize(text: string, fontSize: number) {
  const span = document.createElement('span')

  span.style.fontSize = fontSize + 'px'
  span.style.height = 'auto'
  span.style.width = 'auto'
  span.style.position = 'absolute'
  span.style.left = '-200%'
  span.style.whiteSpace = 'no-wrap'
  span.innerHTML = text

  document.body.appendChild(span)

  const width = Math.ceil(span.clientWidth)
  const height = Math.ceil(span.clientHeight)

  document.body.removeChild(span)

  return { width, height }
}

export function truncateText(
  text: string | string[],
  limit: number,
  separator = ', ',
): string {
  const input = Array.isArray(text) ? text.join(separator) : text

  if (!input?.length) {
    return null
  }

  const output =
    input.length <= limit ? input : input.substring(0, limit) + '...'
  return output
}

export function preventLastWordWrap(text: string) {
  return text?.replace(/ (\S*)$/, `${String.fromCharCode(160)}$1`)
}
