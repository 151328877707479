import { action, observable } from 'mobx'

import { IActivitiesTreeNode } from '../../models/IActivitiesTreeNode'
import CompactActivityListStore from '../../stores/ui/CompactActivityList.store'

export default class ActivitiesTreeStore {
  @observable public percentCompleteInputOpenStatus = new Map<string, boolean>()
  @observable
  public percentCompleteInputLoadingStatus = new Map<string, boolean>()
  @observable public percentCompleteInputValueStatus = new Map<string, number>()
  @observable public remainingDurationInputOpenStatus = new Map<
    string,
    boolean
  >()
  @observable public remainingDurationInputLoadingStatus = new Map<
    string,
    boolean
  >()
  @observable public remainingDurationInputValueStatus = new Map<
    string,
    number
  >()
  @observable public manpowerInputOpenStatus = new Map<string, boolean>()
  @observable public manpowerInputLoadingStatus = new Map<string, boolean>()
  @observable public manpowerInputValueStatus = new Map<string, number>()

  public constructor(
    private readonly compactActivityListStore: CompactActivityListStore,
  ) {}

  public resetInputStatuses() {
    this.percentCompleteInputOpenStatus.clear()
    this.percentCompleteInputLoadingStatus.clear()
    this.percentCompleteInputValueStatus.clear()
    this.remainingDurationInputOpenStatus.clear()
    this.remainingDurationInputLoadingStatus.clear()
    this.remainingDurationInputValueStatus.clear()
    this.manpowerInputOpenStatus.clear()
    this.manpowerInputLoadingStatus.clear()
    this.manpowerInputValueStatus.clear()
  }

  @action.bound
  public handleChangeTree(tree: IActivitiesTreeNode[]) {
    Object.values(tree).forEach((node: IActivitiesTreeNode) => {
      this.changeNode(node)
    })
    this.compactActivityListStore.resetForceCollapse()
  }

  private changeNode(node: IActivitiesTreeNode) {
    const {
      id,
      children,
      state: { expanded: isExpanded },
    } = node

    if (!children || !children.length) {
      return
    }
    const stringId = id.toString()
    const currentValue =
      this.compactActivityListStore.nodesState.get(stringId) || false
    if (currentValue !== isExpanded) {
      this.compactActivityListStore.nodesState.set(stringId, isExpanded)
    }
    Object.values(children).forEach((subNode: IActivitiesTreeNode) => {
      this.changeNode(subNode)
    })
  }
}
