import React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import {
  MILLISECONDS_IN_MINUTE,
  MINUTES_IN_HOUR,
} from '~/client/src/shared/stores/ui/ProjectDate.store'

interface IProps {
  minutesToArrival: number
}

@observer
export default class PayloadProjectedArrivalTimeLabel extends React.Component<IProps> {
  private intervalId: number

  @observable private minutesLeftToArrival: number = 0

  public constructor(props: IProps) {
    super(props)

    this.initMinutes()
  }

  public componentDidMount() {
    this.intervalId = window.setInterval(() => {
      this.setMinutes()
    }, MILLISECONDS_IN_MINUTE)
  }

  public componentDidUpdate(prevProps: IProps) {
    const { minutesToArrival } = this.props
    if (prevProps.minutesToArrival !== minutesToArrival) {
      this.initMinutes()
    }
  }

  public componentWillUnmount() {
    window.clearInterval(this.intervalId)
  }

  public render() {
    const hours = Math.floor(this.minutesLeftToArrival / MINUTES_IN_HOUR)
    const minutes = this.minutesLeftToArrival % MINUTES_IN_HOUR

    if (this.minutesLeftToArrival <= 0) {
      return Localization.translator.isAboutToArrive
    }

    return this.getInTimeMessage(hours, minutes)
  }

  private getInTimeMessage = (hours: number, minutes: number): string => {
    const { inXHoursAndXMin, inXMin } = Localization.translator
    return hours !== 0 ? inXHoursAndXMin(hours, minutes) : inXMin(minutes)
  }

  @action.bound
  private initMinutes() {
    this.minutesLeftToArrival = this.props.minutesToArrival
  }

  @action.bound
  private setMinutes() {
    this.minutesLeftToArrival = this.minutesLeftToArrival - 1

    if (this.minutesLeftToArrival <= 0) {
      window.clearInterval(this.intervalId)
    }
  }
}
