import { SaveLogisticsObjectsDocument } from '~/client/graph/operations/generated/LocationType.generated'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import LogisticsObject from '~/client/src/shared/models/LocationObjects/LogisticsObject'
import ILocationDto from '~/client/src/shared/types/ILocationDto'

import LocationStoreBase from './LocationBase.store'

export default class LogisticsObjectsStore extends LocationStoreBase<LogisticsObject> {
  protected saveMutation = SaveLogisticsObjectsDocument
  protected graphName = 'logisticsObjects'
  protected defaultIconName = MapViewLocationIcon.Logistics

  public get byId() {
    const { logisticsObjects } = this.eventsStore.appState
    return logisticsObjects
  }

  protected fromDto(dto: ILocationDto): LogisticsObject {
    return LogisticsObject.fromDto(dto)
  }
}
