import { LocationType } from '~/client/graph'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ILocationDto from '~/client/src/shared/types/ILocationDto'

import FieldIds from '../../enums/DeliveryFieldIds'
import LocationAttributeBase from './LocationAttributeBase'

export default class Area extends LocationAttributeBase<Area> {
  public static fromDto(dto: ILocationDto) {
    return LocationAttributeBase.factory(Area, dto, MapViewLocationIcon.Area)
  }

  public type = LocationType.Area
  protected relatedFieldId = FieldIds.AREA
  protected model = Area
}
