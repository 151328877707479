import { DocumentNode, OperationDefinitionNode } from 'graphql'

import { ISubscription } from '~/client/graph'

import GraphExecutor, {
  IMutationResult,
  IQueryResult,
} from '../../utils/GraphExecutor'
import EventsStore from '../EventStore/Events.store'
import { REPORT_ERROR } from '../EventStore/eventConstants'

const QUERY = 'QUERY'
const MUTATION = 'MUTATION'
const SUBSCRIPTION = 'SUBSCRIPTION'

export default class GraphExecutorStore {
  public constructor(
    private executor: GraphExecutor,
    private eventsStore: EventsStore,
    private readonly isTestEnv: boolean,
  ) {}

  public async query(
    query: DocumentNode,
    variables: any,
  ): Promise<IQueryResult> {
    const eventId = this.getEventId(query)
    this.logEvent(QUERY, eventId)
    const result = await this.executor.executeQuery(query, variables)

    if (result.error) {
      this.eventsStore.dispatch(REPORT_ERROR, eventId, result.error)
    }

    return result
  }

  public async mutate(
    mutation: DocumentNode,
    variables: any,
  ): Promise<IMutationResult> {
    const eventId = this.getEventId(mutation)
    this.logEvent(MUTATION, eventId)
    const result = await this.executor.executeMutation(mutation, variables)

    if (result.error) {
      this.eventsStore.dispatch(REPORT_ERROR, eventId, result.error)
    }

    return result
  }

  public subscribe(
    query: DocumentNode,
    variables: any,
    listenMany: boolean,
    subscriptionId: string,
    onDataCallback: (data: ISubscription | ISubscription[]) => void,
  ): Promise<void> {
    this.logEvent(SUBSCRIPTION, subscriptionId)
    return this.executor.subscribe(
      query,
      variables,
      listenMany,
      subscriptionId,
      onDataCallback,
    )
  }

  public terminateSubscription(subscriptionId: string) {
    this.executor.terminateSubscription(subscriptionId)
  }

  private getEventId(node: DocumentNode) {
    return (
      node.definitions.find(
        d => d.kind === 'OperationDefinition',
      ) as OperationDefinitionNode
    )?.name.value
  }

  private logEvent(actionType: string, event: string) {
    if (!this.isTestEnv) {
      return
    }

    const message = actionType + ': ' + event

    console.log(message)
  }
}
