import * as React from 'react'

import { observer } from 'mobx-react'

import CompactConfirmDialog from '../../CompactConfirmDialog/CompactConfirmDialog'

export interface IProps {
  title: string
  applyButtonText: string
  cancelButtonText?: string
  isShown: boolean
  onHide: () => void
  onApply: () => void
  isApplyDisabled?: boolean
}

@observer
export default class EndRideConfirm extends React.Component<IProps> {
  public render() {
    const {
      title,
      applyButtonText,
      cancelButtonText,
      isShown,
      onHide,
      onApply,
      isApplyDisabled,
    } = this.props

    return (
      <CompactConfirmDialog
        title={title}
        isShown={isShown}
        onHide={onHide}
        onApply={onApply}
        applyButtonText={applyButtonText}
        cancelButtonText={cancelButtonText}
        canOutsideClickClose={false}
        isApplyDisabled={isApplyDisabled}
      />
    )
  }
}
