import * as React from 'react'

import { Checkbox } from '@blueprintjs/core'
import { observer } from 'mobx-react'

import SavedFiltersHeader from '~/client/src/desktop/components/Filters/SavedFilters/SavedFiltersHeader'
import FilterFooter from '~/client/src/shared/components/Filters/FilterUtils/FilterFooter/FilterFooter'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'

import DesktopActivitiesSavedFiltersStore from '../../ActivitiesSavedFilters/DesktopActivitiesSavedFilters.store'
import DesktopCustomWorkflowsFiltersStore from '../../CustomWorkflowsFilters/DesktopCustomWorkflowsFilters.store'
import DesktopDeliverySavedFiltersStore from '../../DeliverySavedFilters/DesktopDeliverySavedFilters.store'

import '../SavedFilters.scss'

// localization: translated

interface ISaveFilterProps {
  filterName: string
  isPublic: boolean
  entitiesCountByFilter?: number
  filtersCount?: number
  filtersDescriptions: string[]
  toggleIsPublic: () => void
  clickOnApply: () => void
  clickOnCancel: () => void
  onNameChange: (event) => void
  desktopSavedFiltersStore:
    | DesktopActivitiesSavedFiltersStore
    | DesktopDeliverySavedFiltersStore
    | DesktopCustomWorkflowsFiltersStore
  xEntitiesTranslatorKey: KnownTranslatorKeys
}

@observer
export default class SaveFilterDialog extends React.Component<ISaveFilterProps> {
  public render() {
    const {
      filterName: value,
      entitiesCountByFilter,
      filtersCount,
      filtersDescriptions,
      clickOnApply,
      clickOnCancel,
      onNameChange,
      toggleIsPublic,
      isPublic,
      xEntitiesTranslatorKey,
    } = this.props

    return (
      <div className="filter-container saved">
        <SavedFiltersHeader
          desktopSavedFiltersStore={this.props.desktopSavedFiltersStore}
        />
        <div className="filter-container-content mx15">
          <div className="row name y-center">
            <StruxhubInput
              label={Localization.translator.filterName}
              isRequired={true}
              isRequiredTextHidden={true}
              value={value}
              onChange={onNameChange}
            />
            <div className="mt20 ml40 row y-start text large">
              <Checkbox
                className="no-grow"
                checked={isPublic}
                onClick={toggleIsPublic}
              />
              {Localization.translator.makePublic}
            </div>
          </div>
          <div>
            <span className="text extra-large bold mr10">
              {Localization.translator.selectedFilters}
            </span>
            <span className="text extra-large light">
              ({Localization.translator.xFilters(filtersCount)})
            </span>
          </div>
          {filtersDescriptions.map((label, idx) => (
            <div className="row py5" key={idx}>
              <div className="no-grow px10 text primary-blue">•</div>
              <div className="text large ellipsis">{label}</div>
            </div>
          ))}
          <div className="no-grow text extra-large light nowrap pt5">
            {Localization.getText(
              xEntitiesTranslatorKey,
              entitiesCountByFilter,
            )}
          </div>
        </div>
        <div className="bt-light-grey mt10">
          <FilterFooter
            clickOnApply={clickOnApply}
            clickOnCancel={clickOnCancel}
            isApplyButtonInactive={!value}
            applyButtonText={Localization.translator.save}
          />
        </div>
      </div>
    )
  }
}
