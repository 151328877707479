import * as React from 'react'

import { inject, observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

// translated

interface IProps {
  contentObject: any
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void
  slackBarStore?: SlackBarStore
}

@inject('slackBarStore')
@observer
export default class ReplyToContentObjectButton extends React.Component<IProps> {
  public render() {
    const { onClick } = this.props
    const handleClick = onClick || this.close

    return (
      <button className="item-button reply-btn" onClick={handleClick}>
        {Localization.translator.reply}
      </button>
    )
  }

  private close = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    const { slackBarStore, contentObject } = this.props
    slackBarStore.closeContentObject(contentObject)
  }
}
