import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ActivitiesSavedFilters from '~/client/src/desktop/components/Filters/ActivitiesSavedFilters/ActivitiesSavedFilters'
import CategoryFilter from '~/client/src/desktop/components/Filters/CategoryFilter/CategoryFilter'
import CategoryFilterStore from '~/client/src/desktop/components/Filters/CategoryFilter/CategoryFilter.store'
import RecentlyUpdatedStore from '~/client/src/desktop/components/Filters/CategoryFilter/components/RecentlyUpdated/RecentlyUpdated.store'
import StatusFilterStore from '~/client/src/desktop/components/Filters/CategoryFilter/components/StatusFilter/StatusFilter.store'
import DateSelector from '~/client/src/desktop/components/Filters/DateSelector/DateSelector'
import EntityNameFilter from '~/client/src/desktop/components/Filters/EntityNameFilter/EntityNameFilter'
import LocationFilter from '~/client/src/desktop/components/Filters/LocationFilter/LocationFilter'
import BaseHeaderBar from '~/client/src/desktop/components/HeaderBar/HeaderBar'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import DynamicOverflowListStore from '~/client/src/shared/components/DynamicOverflowList/DynamicOverflowList.store'
import * as Icons from '~/client/src/shared/components/Icons'
import ActivitiesDatePickerModes from '~/client/src/shared/enums/ActivitiesDatePickerModes'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import ActivitiesHeaderBarStore from './ActivitiesHeaderBar.store'
import ActivityGroupingOptions from './components/ActivityGroupingOptions/ActivityGroupingOptions'

import './ActivitiesHeaderBar.scss'

interface IProps {
  activityListStore: DesktopActivityListStore
  state?: DesktopInitialState
  projectDateStore?: ProjectDateStore
  projectsStore?: ProjectsStore
  eventsStore?: DesktopEventStore
  userProjectsStore?: UserProjectsStore
}

@inject(
  'state',
  'projectsStore',
  'projectDateStore',
  'eventsStore',
  'userProjectsStore',
)
@observer
export default class ActivitiesHeaderBar extends BaseHeaderBar<IProps> {
  protected store: ActivitiesHeaderBarStore = null
  private readonly categoryFilterStore: CategoryFilterStore
  private readonly recentlyUpdatedStore: RecentlyUpdatedStore
  private readonly statusFilterStore: StatusFilterStore
  private readonly dynamicOverflowListStore: DynamicOverflowListStore = null

  public constructor(props: IProps) {
    super(props)

    this.dynamicOverflowListStore = new DynamicOverflowListStore()

    this.store = new ActivitiesHeaderBarStore(
      props.state,
      props.activityListStore,
      props.eventsStore,
      props.userProjectsStore,
    )
    this.categoryFilterStore = new CategoryFilterStore(
      props.activityListStore,
      props.state,
      this.store.onShowCategoryChanged,
    )

    this.recentlyUpdatedStore = new RecentlyUpdatedStore(
      props.state,
      this.categoryFilterStore.forceUpdate,
      props.projectDateStore,
    )

    this.statusFilterStore = new StatusFilterStore(
      props.state,
      this.categoryFilterStore.forceUpdate,
    )
  }

  public componentDidMount() {
    this.store.setInitialDateValue()
    this.props.activityListStore.recalculateDaysToAdd()
  }

  protected renderLeftSection() {
    const {
      forceSearchClose,
      onShowSearchChanged,
      forceCategoryClose,
      onShowCategoryChanged,
      forceSavedClose,
      onShowSavedChanged,
      forceGroupByClose,
      onShowGroupByChanged,
    } = this.store

    return (
      <div className="row x-start full-height left-bar no-grow">
        <EntityNameFilter
          forceClose={forceSearchClose}
          onShowChanged={onShowSearchChanged}
          filters={this.props.state.filters}
        />
        <CategoryFilter
          activityListStore={this.props.activityListStore}
          categoryFilterStore={this.categoryFilterStore}
          recentlyUpdatedStore={this.recentlyUpdatedStore}
          statusFilterStore={this.statusFilterStore}
          forceClose={forceCategoryClose}
          onShowChanged={onShowCategoryChanged}
        />
        <ActivitiesSavedFilters
          activityListStore={this.props.activityListStore}
          categoryFilterStore={this.categoryFilterStore}
          recentlyUpdatedStore={this.recentlyUpdatedStore}
          forceClose={forceSavedClose}
          onShowChanged={onShowSavedChanged}
        />
        <ActivityGroupingOptions
          forceClose={forceGroupByClose}
          onShowChanged={onShowGroupByChanged}
          onApplyClick={this.dynamicOverflowListStore.enableRecalculation}
        />
      </div>
    )
  }

  protected renderCenterSection() {
    const { activityListStore } = this.props
    const { onShowChanged, forceLocationCloseMap } = this.store

    return (
      <LocationFilter
        activityListStore={activityListStore}
        forceCloseMap={forceLocationCloseMap}
        onShowChanged={onShowChanged}
        dynamicOverflowListStore={this.dynamicOverflowListStore}
      />
    )
  }

  protected renderRightSection() {
    const { activityListStore, projectsStore, projectDateStore } = this.props
    const { dateFilters } = activityListStore
    const {
      twoMonthsDatePickerMode,
      forceDatePickerClose,
      onShowDatePickerChanged,
      shouldEnableFullProjectMode,
    } = this.store

    return (
      <>
        <DateSelector
          filters={dateFilters}
          mode={twoMonthsDatePickerMode}
          forceClose={forceDatePickerClose}
          onShowChanged={onShowDatePickerChanged}
          shouldEnableFullProjectMode={shouldEnableFullProjectMode}
          projectsStore={projectsStore}
          projectDateStore={projectDateStore}
          shouldShowTodayButton={true}
        />
        {this.datePickerModeSelector}
        {this.newActivityButton}
      </>
    )
  }

  private get datePickerModeSelector(): JSX.Element {
    const {
      isDatePickerModeSelectorOpen,
      selectedDatePickerMode,
      toggleDatePickerModeSelector,
      setActivitiesDatePickerMode,
    } = this.store

    const icon = isDatePickerModeSelectorOpen
      ? IconNames.CARET_UP
      : IconNames.CARET_DOWN

    return (
      <div
        className={classList({
          'mw130 large pl10 ml5 text capitalize relative row x-center y-center':
            true,
          'gant-header-btn no-flex gant-header-btn-outline ba-palette-grey':
            true,
        })}
        onClick={toggleDatePickerModeSelector}
      >
        {selectedDatePickerMode}
        <Icon icon={icon} iconSize={16} className="mx5" />

        {isDatePickerModeSelectorOpen && (
          <div className="mw130 absolute-block gantt-options-container ba-palette-grey brada4">
            {Object.values(ActivitiesDatePickerModes).map(mode => {
              return (
                <div
                  key={mode}
                  className={classList({
                    'pointer row x-center y-center text large bg-white option':
                      true,
                    selected: selectedDatePickerMode === mode,
                  })}
                  onClick={setActivitiesDatePickerMode.bind(this, mode)}
                >
                  {mode}
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }

  private get newActivityButton(): JSX.Element {
    return (
      <BaseActionButton
        onClick={this.props.activityListStore.showAddActivityDialog}
        isEnabled={true}
        title={Localization.translator.newActivity}
        icon={<Icons.Gantt />}
        className="new-activity-btn ml30"
      />
    )
  }
}
