import * as React from 'react'

import { observer } from 'mobx-react'

import { ISharedProps } from '../BaseStruxhubInput'
import BaseStruxhubSelector from './BaseStruxhubSelector'

// localization: no text to translate

interface IProps extends ISharedProps {
  onClick?: () => void

  customIconElement?: JSX.Element
}

@observer
export default class StruxhubTextValueSelector extends React.Component<IProps> {
  public render() {
    const { value, customIconElement } = this.props

    return (
      <BaseStruxhubSelector {...this.props} selectorClassName="h40">
        <>
          {customIconElement}
          <span className="text extra-large text-ellipsis" title={value}>
            {value}
          </span>
        </>
      </BaseStruxhubSelector>
    )
  }
}
