import * as React from 'react'

import { inject } from 'mobx-react'

import { IContact } from '../../../services/ChatService/types'
import ProjectMembersStore from '../../../stores/domain/ProjectMembers.store'
import UsernameFromUid from '../../UsernameFromUid'

interface IProps {
  contact: IContact

  projectMembersStore?: ProjectMembersStore
}

@inject('projectMembersStore')
export default class ContactName extends React.Component<IProps> {
  public render() {
    const { contact, projectMembersStore } = this.props
    const isContactProjectMember = projectMembersStore.hasById(contact.id)

    return isContactProjectMember ? (
      <UsernameFromUid userId={contact.id} />
    ) : (
      contact.fullName
    )
  }
}
