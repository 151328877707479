import * as React from 'react'

import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'

interface IProps {
  label: string
  className?: string
}

export default function ShowInAppTag({
  label,
  className,
}: IProps): JSX.Element {
  return (
    <SitemapAttributeTag
      shouldShowAsTag
      className={className}
      contentContainerClassName="text-ellipsis py2 row"
    >
      {label}
    </SitemapAttributeTag>
  )
}
