import { computed } from 'mobx'

import { FilterType } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { ActivitiesTreeNodeTypes } from '~/client/src/shared/enums/ActivitiesTreeNodeTypes'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Activity from '~/client/src/shared/models/Activity'
import ActivitiesTreeNode from '~/client/src/shared/models/ActvitiesTreeNode'
import { IActivitiesTreeNode } from '~/client/src/shared/models/IActivitiesTreeNode'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import HierarchyConfigurationStore from '~/client/src/shared/stores/domain/HierarchyConfiguration.store'
import LocationsStore from '~/client/src/shared/stores/domain/Locations.store'
import {
  ITreeContainer,
  NODE_TYPE_MAP,
} from '~/client/src/shared/stores/ui/BaseActivityList.store'
import CompactActivityListStore from '~/client/src/shared/stores/ui/CompactActivityList.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import {
  UNASSIGNED,
  UNASSIGNED_FILTER_VALUE,
} from '~/client/src/shared/utils/ZoneLevelLocationConstants'

const { ACTIVITY, ACTIVITY_CODE_CONTAINER } = ActivitiesTreeNodeTypes

export default class ActivityListPreviewStore extends CompactActivityListStore {
  public constructor(
    protected activitiesStore: ActivitiesStore,
    protected eventsStore: DesktopEventStore,
    protected activityFiltersStore: ActivityFiltersStore,
    protected hierarchyConfigurationStore: HierarchyConfigurationStore,
    protected locationStore: LocationsStore,
    protected projectDateStore: ProjectDateStore,
    protected companiesStore: CompaniesStore,
  ) {
    super(
      activityFiltersStore,
      eventsStore,
      locationStore,
      companiesStore,
      projectDateStore,
    )
  }

  @computed
  public get activitiesPreviewTree(): IActivitiesTreeNode[] {
    const activities = this.activitiesStore.list

    if (!activities.length) {
      return []
    }

    return this.getTreeNodesPreview(
      this.hierarchyConfigurationStore.orderedAppliedBands,
      activities,
    )
  }

  public getTreeNodesPreview(
    orderedTreeContainers: string[],
    activities: Activity[],
    parents: ITreeContainer[] = [],
    shouldPreventDefaultClick: boolean = true,
  ): IActivitiesTreeNode[] {
    const [containerType, childContainerType] = orderedTreeContainers
    const containers = this.getContainersByType(containerType)

    const nodeType = NODE_TYPE_MAP[containerType] || ACTIVITY_CODE_CONTAINER

    const nodes = containers
      .map(container => {
        const containerActivities = this.filterActivities(activities, container)
        const path = [...parents, container]
        const containerChildren = childContainerType
          ? this.getTreeNodesPreview(
              orderedTreeContainers.slice(1),
              containerActivities,
              path,
            )
          : this.activitiesToNodes(
              path,
              containerActivities,
              shouldPreventDefaultClick,
            )

        if (!containerChildren.length) {
          return null
        }

        const id = path.map(c => c.name).join('-')
        const nodeName =
          container.name === UNASSIGNED
            ? Localization.translator.unassigned
            : container.name
        return new ActivitiesTreeNode(id, nodeName, nodeType, {
          children: containerChildren,
          nodeType,
          level: path.length,
          state: {
            expanded: this.isNodeExpanded(id, nodeType),
          },
        })
      })
      .filter(node => !!node)

    if (nodes.length === 1) {
      return nodes[0].children
    }
    return nodes
  }

  public activitiesToNodes(
    parents: ITreeContainer[],
    activities: Activity[],
    shouldPreventDefaultClick: boolean,
  ): IActivitiesTreeNode[] {
    if (!activities.length) {
      return []
    }

    const companyContainer = parents.find(c => c.type === FilterType.Company)
    const company = companyContainer
      ? companyContainer.name
      : UNASSIGNED_FILTER_VALUE

    return activities.map(activity => {
      return new ActivitiesTreeNode(activity.code, activity.name, ACTIVITY, {
        entity: activity,
        sectionCompanyName: company,
        hasRfi: false,
        hasFlag: false,
        hasScheduleComment: false,
        hasCategoryOfVaiance: false,
        hasSafetyHazard: false,
        hasDelivery: false,
        hasCriticalPath: false,
        totalEntitiesAmount: 1,
        updatedActivitiesAmount: 0,
        totalManpower: 0,
        onClick: shouldPreventDefaultClick ? () => null : null,
      })
    })
  }

  private filterActivities(
    activities: Activity[],
    container: ITreeContainer,
  ): Activity[] {
    let filterFn
    const { type, name, codeIds } = container
    if (type === FilterType.Company) {
      filterFn = (a: Activity) => {
        return name === UNASSIGNED
          ? !a.companies.length
          : a.companies.includes(name)
      }
    } else {
      const allContainersCodeIds = this.activityFiltersStore
        .getCodesByFilterType(type)
        .map(({ id }) => id)

      filterFn = (a: Activity) => {
        return name === UNASSIGNED
          ? !allContainersCodeIds.some(codeId => a.codeIds.includes(codeId))
          : codeIds.some(codeId => a.codeIds.includes(codeId))
      }
    }

    return activities.filter(filterFn)
  }
}
