import * as React from 'react'

import * as Icons from '~/client/src/shared/components/Icons'
import AssociationStatuses from '~/client/src/shared/enums/AssociationStatuses'

interface IStar {
  associationStatus: AssociationStatuses
  onClick: any

  isBigStarMode?: boolean
}

export default class Star extends React.PureComponent<IStar> {
  public render() {
    const { associationStatus, onClick, isBigStarMode } = this.props

    let star
    switch (associationStatus) {
      case AssociationStatuses.ASSIGNED:
        star = isBigStarMode ? <Icons.StarFilledBig /> : <Icons.FilledStar />
        break
      case AssociationStatuses.FOLLOWED:
        star = isBigStarMode ? (
          <Icons.StarUnfilledBig />
        ) : (
          <Icons.UnfilledStar />
        )
        break
      default:
        star = isBigStarMode ? (
          <Icons.StarUnfilledInactiveBig />
        ) : (
          <Icons.UnfilledStarInactive />
        )
    }

    return (
      <span className="no-grow pointer row" onClick={onClick}>
        {star}
      </span>
    )
  }
}
