import React from 'react'

import { observer } from 'mobx-react'

import NavBar from '~/client/src/desktop/components/NavBar/NavBar'
import * as Layout from '~/client/src/shared/components/Layout'
import Tabs from '~/client/src/shared/components/Tabs/Tabs'

import Reports from '../Reports/Reports'
import AnalyticsStore, { Tab } from './Analytics.store'
import Dashboard from './components/Dashboard/Dashboard'

import './Analytics.scss'

@observer
export default class Analytics extends React.Component {
  private store: AnalyticsStore = new AnalyticsStore()

  public render() {
    return (
      <Layout.View className="overflow-hidden analytics-view">
        <Layout.Header className="layout-navigation">
          <NavBar />

          <Tabs
            tabObjects={this.store.tabs}
            activeTabId={this.store.activeTab}
            onTabClick={this.store.changeActiveTab}
            className="bb-light-grey mt15"
            tabClassName="row x-center y-center pointer analytics-view-tab"
            tabTextClassName="text center extra-large ellipsis px5"
          />
        </Layout.Header>

        <Layout.Content>{this.content}</Layout.Content>
      </Layout.View>
    )
  }

  private get content() {
    switch (this.store.activeTab) {
      case Tab.Dashboard:
        return <Dashboard />
      case Tab.Reports:
        return <Reports />
    }
  }
}
