import Activity from '~/client/src/shared/models/Activity'
import BaseModel from '~/client/src/shared/models/BaseModel'

export default abstract class ActivityOwnedEntity<
  TDto,
> extends BaseModel<TDto> {
  public activityObjectId = ''

  public constructor(id?: string) {
    super(id)
  }

  public setActivity(a: Activity) {
    if (!a) {
      return
    }

    this.activityObjectId = a.code
  }

  public getActivityObjectId(): string {
    return this.activityObjectId
  }

  public belongsToActivity(a: Activity) {
    if (!a) {
      return false
    }

    return this.activityObjectId === a.code || this.activityObjectId === a.code
  }
}
