enum FileType {
  ProjectMembers = 'Project Members',
  ProjectSchedule = 'Project Schedule',
  ProjectMaterials = 'Project Materials',
  Image = 'Image',
  Pdf = 'Pdf',
  Excel = 'Xlsx',
  Word = 'Docx',
  PowerPoint = 'Pptx',
}

export default FileType
