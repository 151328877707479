import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { ISiteLocation } from '~/client/graph/types.generated'
import * as Icons from '~/client/src/shared/components/Icons'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'

import SitePermit from '../models/Permit'
import CompaniesStore from '../stores/domain/Companies.store'
import ProjectDateStore from '../stores/ui/ProjectDate.store'
import PermitTypeIcon from './PermitTypeIcon/PermitTypeIcon'
import WorkflowCardLocationLabel from './WorkflowCard/LocationLabel'
import WorkflowCardStatus from './WorkflowCard/Status'

import './WorkflowCard/WorkflowCard.scss'

interface IProps {
  permit: SitePermit
  onClick?: (permit: SitePermit) => void
  projectDateStore?: ProjectDateStore
  permitTypesStore?: PermitTypesStore
  isSelected?: boolean
  companiesStore?: CompaniesStore
}

@inject('projectDateStore', 'permitTypesStore', 'companiesStore')
@observer
export default class PermitListItem extends React.Component<IProps> {
  public render() {
    const {
      permit: {
        getTypeOfPermitType,
        status,
        getCaption,
        companyIds,
        locations,
        equipment,
        isLate,
        workflowStepLevel,
      },
      permitTypesStore,
      isSelected,
      companiesStore,
    } = this.props

    const { getCompanyNameById } = companiesStore

    const companyNames = companyIds
      ?.map(id => getCompanyNameById(id))
      .join(', ')

    return (
      <div
        className={classList({
          'pa12 bb-light-grey workflow-card text large overflow-hidden': true,
          'selected-card': isSelected,
        })}
        onClick={this.onClick}
      >
        <div>
          <Icons.FilledStar className="no-grow mr4 main-icon vertical-align-middle" />
          <span className="inline-block">{this.renderDates()}</span>
          <WorkflowCardStatus
            status={status}
            workflowStepLevel={workflowStepLevel}
            isLate={isLate}
            alignRight={true}
          />
        </div>
        <div className="bold extra-large pt5 text">
          <PermitTypeIcon
            permitType={getTypeOfPermitType(permitTypesStore)}
            className="no-grow mr4 main-icon"
          />
          <span className="inline-block text-ellipsis title-text">
            {getCaption(permitTypesStore)}
          </span>
        </div>
        {companyNames && (
          <div className="pt5 row team-row pl25">
            <Icons.Team className="no-grow mr10 secondary-icon" />
            {companyNames}
          </div>
        )}
        {this.renderLocationsRow(locations)}
        {this.renderLocationsRow(equipment)}
      </div>
    )
  }

  private renderDates() {
    const { startDate, endDate } = this.props.permit
    const { getTimeToDisplay, getMonthDayAndTimeToDisplay, isSameDay } =
      this.props.projectDateStore
    const startDateStr = getMonthDayAndTimeToDisplay(startDate)
    let endDateStr = getMonthDayAndTimeToDisplay(endDate)
    if (isSameDay(startDate, endDate)) {
      endDateStr = getTimeToDisplay(endDate)
    }

    return (
      <>
        <span>{startDateStr}</span>
        <span className="px5">-</span>
        <span>{endDateStr}</span>
      </>
    )
  }

  private renderLocationsRow(locations: ISiteLocation[]): JSX.Element {
    return (
      <div className="pt5 nowrap pl20">
        <WorkflowCardLocationLabel
          locations={locations}
          isOneColor={false}
          shouldShowAsTag
          shouldAddPadding
        />
      </div>
    )
  }

  private onClick = () => {
    const { permit, onClick } = this.props
    if (onClick) {
      onClick(permit)
    }
  }
}
