import * as React from 'react'

import { FileInput, Icon } from '@blueprintjs/core'
import { IconName, IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'

import { IAttachment, IPermitTypeField } from '~/client/graph'
import BaseStruxhubInput from '~/client/src/shared/components/StruxhubInputs/BaseStruxhubInput'
import FileAttachment from '~/client/src/shared/components/WorkflowCard/FileAttachment'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'

import SitePermitCreationFormStore from '../../SitePermitCreationForm.store'
import PermitBaseFormField from './PermitBaseFormField'

interface IProps {
  typeField: IPermitTypeField
  isViewMode: boolean

  store: SitePermitCreationFormStore
  fieldsStore: IPermitFieldsStore
}

const UPLOAD_ICON_SIZE = 18

const files = 'Files'
const photo = 'Photo'
const camera = 'Camera'

@observer
export default class PermitFormFileUploadField extends React.Component<IProps> {
  public render() {
    const { isViewMode, typeField } = this.props
    const { caption, isMandatory } = typeField

    return (
      <PermitBaseFormField
        typeField={typeField}
        isViewMode={isViewMode}
        viewModeElements={this.attachmentElements}
      >
        <BaseStruxhubInput
          className="overflow-hidden"
          label={caption}
          isRequired={isMandatory}
          isValid={true}
          value={!!this.fieldValues?.length && '1'}
        >
          {() => (
            <div className="row y-end permit-form-fields-upload">
              {this.renderUploadButton(
                'mr8',
                '.pdf',
                IconNames.PAPERCLIP,
                files,
              )}
              {this.renderUploadButton(
                'mx8',
                'image/*',
                IconNames.MEDIA,
                photo,
              )}
              {this.renderUploadButton(
                'ml8',
                'image/*',
                IconNames.CAMERA,
                camera,
              )}
            </div>
          )}
        </BaseStruxhubInput>
        {this.attachmentElements}
      </PermitBaseFormField>
    )
  }

  private get attachmentElements(): JSX.Element[] {
    const { isViewMode } = this.props

    return this.fieldValues?.map((attachment, idx) => (
      <FileAttachment
        key={idx}
        attachment={attachment}
        removeAttachment={!isViewMode && this.removeAttachment.bind(this, idx)}
        className="iconless-row permit-form-attachment"
      />
    ))
  }

  private renderUploadButton = (
    className: string,
    acceptPattern: string,
    iconName: IconName,
    label: string,
  ): JSX.Element => {
    const textElement = (
      <div className="pointer row x-center y-center ba-palette-brand-lighter no-outline brada4 pa8 text extra-large full-width h40">
        <Icon
          className="text light"
          icon={iconName}
          iconSize={UPLOAD_ICON_SIZE}
        />
        <span className="no-flex ml5 text light large">{label}</span>
      </div>
    )

    return (
      <FileInput
        disabled={false}
        className={`attachment-upload-button full-height ${className}`}
        inputProps={{
          onChange: this.uploadAttachment,
          accept: acceptPattern,
          className: 'bare-file-input',
        }}
        text={textElement}
      />
    )
  }

  private get fieldValues(): IAttachment[] {
    const { typeField, fieldsStore } = this.props
    return fieldsStore.getFieldValues<IAttachment>(typeField.id)
  }

  private uploadAttachment = async ({ target }: any) => {
    const fileToUpload: File = target.files[0]
    target.value = ''
    const { store, fieldsStore, typeField } = this.props
    return store.uploadAttachment(fileToUpload, typeField, fieldsStore)
  }

  private removeAttachment(fieldIndex: number) {
    const { typeField, fieldsStore } = this.props
    fieldsStore.removeFieldValue(typeField.id, fieldIndex)
  }
}
