import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { Path } from 'react-konva'

import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import ClosuresStore from '~/client/src/shared/stores/domain/Closures.store'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import { MAX_PERCENT } from '../SitemapItems'
import KonvaAreaIcon from '../konvaElements/KonvaArea'
import KonvaBuildingIcon from '../konvaElements/KonvaBuildingIcon'
import KonvaEquipmentIcon from '../konvaElements/KonvaEquipmentIcon'
import KonvaEquipmentTypeIcon from '../konvaElements/KonvaEquipmentTypeIcon'
import KonvaGateIcon from '../konvaElements/KonvaGateIcon'
import KonvaInteriorDoorIcon from '../konvaElements/KonvaInteriorDoor'
import KonvaInteriorPathIcon from '../konvaElements/KonvaInteriorPath'
import KonvaLevelIcon from '../konvaElements/KonvaLevel'
import KonvaLogisticsObject from '../konvaElements/KonvaLogisticsObject'
import KonvaLogisticsObjectPathIcon from '../konvaElements/KonvaLogisticsObjectPathIcon'
import KonvaMaturixStationIcon from '../konvaElements/KonvaMaturixStationIcon'
import KonvaRouteIcon from '../konvaElements/KonvaRouteIcon'
import KonvaStagingIcon from '../konvaElements/KonvaStaging'
import KonvaZoneIcon from '../konvaElements/KonvaZoneIcon'

import Colors from '~/client/src/shared/theme.module.scss'

interface IProps {
  item: MapViewItemBase
  containerWidth: number
  containerHeight: number

  closuresStore?: ClosuresStore
}

const ICON_PATH =
  'M10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 Z M16.110243,5.6498533 L5.64886767,16.1095399 C6.87589786,16.9849839 8.3778047,17.5 10,17.5 C14.1421356,17.5 17.5,14.1421356 17.5,10 C17.5,8.37823903 16.9852597,6.8767021 16.110243,5.6498533 Z M10,2.5 C5.85786438,2.5 2.5,5.85786438 2.5,10 C2.5,11.6178521 3.012262,13.1160589 3.88343614,14.3412708 L14.3412708,3.88343614 C13.1160589,3.012262 11.6178521,2.5 10,2.5 Z'
const ICON_OFFSET = 10
const ICON_SCALE = 1.4

const ICON_BY_TYPE = {
  [MapViewLocationIcon.Gate]: KonvaGateIcon,
  [MapViewLocationIcon.Zone]: KonvaZoneIcon,
  [MapViewLocationIcon.Building]: KonvaBuildingIcon,
  [MapViewLocationIcon.Route]: KonvaRouteIcon,
  [MapViewLocationIcon.Equipment]: KonvaEquipmentIcon,
  [MapViewLocationIcon.Level]: KonvaLevelIcon,
  [MapViewLocationIcon.Area]: KonvaAreaIcon,
  [MapViewLocationIcon.Logistics]: KonvaLogisticsObject,
  [MapViewLocationIcon.Bathroom]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.Canteen]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.Break]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.Dumpster]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.Elevator]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.Entrance]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.HandWash]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.Medical]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.MeetingPoint]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.Parking]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.Smoking]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.Stairs]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.Shaft]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.Temperature]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.Tent]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.Toilet]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.Walkway]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.ElectricalRoom]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.Trailer]: KonvaLogisticsObjectPathIcon,
  [MapViewLocationIcon.Crane]: KonvaEquipmentTypeIcon,
  [MapViewLocationIcon.Hoist]: KonvaEquipmentTypeIcon,
  [MapViewLocationIcon.AerialLift]: KonvaEquipmentTypeIcon,
  [MapViewLocationIcon.Gradall]: KonvaEquipmentTypeIcon,
  [MapViewLocationIcon.ForkLift]: KonvaEquipmentTypeIcon,
  [MapViewLocationIcon.Monitoring]: KonvaMaturixStationIcon,
  [MapViewLocationIcon.Staging]: KonvaStagingIcon,
  [MapViewLocationIcon.InteriorDoor]: KonvaInteriorDoorIcon,
  [MapViewLocationIcon.InteriorPath]: KonvaInteriorPathIcon,
}

@inject('closuresStore')
@observer
export default class SitemapIcon extends React.Component<IProps & any> {
  public render() {
    const { containerWidth, containerHeight, item, bearing, ...rest } =
      this.props
    const {
      sitemapItemProperties: { iconProperties },
      color,
      iconName,
      isDisplayed,
    } = item
    const Icon = ICON_BY_TYPE[iconName]
    if (
      !iconProperties ||
      !iconProperties.isDisplayed ||
      !Icon ||
      !isDisplayed
    ) {
      return null
    }

    const x = (containerWidth * iconProperties.position.x) / MAX_PERCENT
    const y = (containerHeight * iconProperties.position.y) / MAX_PERCENT
    return (
      <>
        <Icon
          color={color}
          x={x}
          y={y}
          type={iconName}
          isObjectClosed={this.isObjectClosed}
          rotation={bearing}
          {...rest}
        />
        {this.isObjectClosed && (
          <Path
            fill={ThemeMode.getHEXColor(Colors.error50)}
            scaleY={ICON_SCALE}
            scaleX={ICON_SCALE}
            x={x}
            y={y}
            offsetX={ICON_OFFSET}
            offsetY={ICON_OFFSET}
            data={ICON_PATH}
            rotation={bearing}
            isClosedIcon={true}
          />
        )}
      </>
    )
  }

  private get isObjectClosed(): boolean {
    const {
      item: { dataObject },
      closuresStore,
    } = this.props

    return (
      dataObject &&
      closuresStore &&
      !closuresStore.isLocationOpen(dataObject.id)
    )
  }
}
