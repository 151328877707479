import { action, computed, observable } from 'mobx'

import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { ITag } from '~/client/src/shared/models/Tag'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { getBandTitleByTagType } from '~/client/src/shared/utils/TagHelper'

export default class TagFilterStore {
  @observable public selectedCategoryId: TagType = null
  @observable public selectedFilters: ITag[] = []

  public appliedFilters: ITag[] = []

  public constructor(
    private readonly tagsStore: TagsStore,
    private readonly onApply: (filters: ITag[]) => void,
  ) {}

  public get tagsBySelectedCategories(): ITag[] {
    return (
      this.tagsStore.tagListsWithDefaultsTagsMap[this.selectedCategoryId] || []
    )
  }

  @computed
  public get selectedFiltersByTagType(): { [tagType: string]: ITag[] } {
    return this.selectedFilters.reduce((acc, tag) => {
      const type = this.getCorrectTagType(tag.type)

      if (acc[type]) {
        acc[type].push(tag)
      } else {
        acc[type] = [tag]
      }

      return acc
    }, {})
  }

  @computed
  public get isFilteringButtonEnabled(): boolean {
    if (this.appliedFilters.length !== this.selectedFilters.length) {
      return true
    }

    const appliedFiltersIds = this.appliedFilters.map(({ id }) => id)
    return this.selectedFilters.some(
      ({ id }) => !appliedFiltersIds.includes(id),
    )
  }

  public get shouldShowClearAll(): boolean {
    return !!this.appliedFilters.length || !!this.selectedFilters.length
  }

  public setAppliedFilters(filters: ITag[]) {
    this.appliedFilters = [...filters]
  }

  public setSelectedFilters(filters: ITag[]) {
    this.selectedFilters = [...filters]
  }

  public setFilters(appliedFilters: ITag[]) {
    this.setAppliedFilters(appliedFilters)
    this.setSelectedFilters(appliedFilters)
  }

  public isTagApplied = (tagId: string) => {
    return this.appliedFilters.some(({ id }) => id === tagId)
  }

  public isFilterSelected = (tagId: string) => {
    return this.selectedFilters.some(({ id }) => id === tagId)
  }

  @action.bound
  public selectCategory(categoryId: TagType) {
    this.selectedCategoryId = categoryId
  }

  @action.bound
  public resetSelectedCategory() {
    this.selectedCategoryId = null
  }

  @action.bound
  public reset() {
    this.resetSelectedCategory()
    this.selectedFilters = [...this.appliedFilters]
  }

  @action.bound
  public toggleTag(tag: ITag) {
    const index = this.selectedFilters.findIndex(({ id }) => id === tag.id)

    if (index === -1) {
      this.selectedFilters.push(tag)
    } else {
      this.selectedFilters.splice(index, 1)
    }
  }

  public get headerTitle(): string {
    const { filters, filterBy } = Localization.translator

    return this.selectedCategoryId
      ? `${filterBy} ${getBandTitleByTagType(this.selectedCategoryId)}`
      : filters
  }

  @action.bound
  public applyFilters() {
    this.resetSelectedCategory()
    this.onApply(this.selectedFilters)
  }

  @action.bound
  public clearAll() {
    this.setFilters([])
    this.applyFilters()
  }

  private getCorrectTagType = (tagType: TagType): TagType => {
    const { GlobalAndProjectSpecificRole, Role, DefaultTeam, Team } = TagType

    switch (tagType) {
      case Role:
        return GlobalAndProjectSpecificRole
      case DefaultTeam:
        return Team
      default:
        return tagType
    }
  }
}
