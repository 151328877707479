import { action, computed, observable } from 'mobx'

import { FormFieldType } from '~/client/src/shared/enums/FormFieldType'
import {
  ICountry,
  formatPhoneNumber,
  isPhoneNumberValidForSubmission,
} from '~/client/src/shared/utils/phoneNumberHelpers'

export default class FormStore<T> {
  public static isFieldValueEmpty(value: any): boolean {
    return !value?.toString().trim()
  }

  public name: string = ''
  public isPhoneValid: boolean = true
  public validator: (form: FormStore<T>) => void

  @observable public error = ''
  @observable public requiredFields = []
  public invalidFields = observable(new Map<string, boolean>())
  private internalFields = observable.map({})

  private original: T

  @computed
  public get fields() {
    return this.internalFields.toJSON()
  }

  @computed
  public get hasChanges(): boolean {
    return Object.keys(this.fields).some(fieldId => {
      return (
        JSON.stringify(this.fields[fieldId]) !==
        JSON.stringify(this.original[fieldId])
      )
    })
  }

  public constructor(
    name: string,
    fields: T,
    validator: (form: FormStore<T>) => void,
    private resetErrorMessage: () => void,
    requiredFields: string[] = [],
  ) {
    this.name = name
    this.original = fields
    this.internalFields.replace(fields)
    this.validator = validator

    this.setRequiredFields(requiredFields)
  }

  @action.bound
  public setRequiredFields(requiredFields: string[] = []) {
    this.requiredFields = requiredFields
  }

  @action.bound
  public handleCustomPropChange = (propName: string, value: any) => {
    this.resetErrorMessage()

    this.setFieldValue(propName, value)
    this.validate()
  }

  @action.bound
  public handlePhoneNumberChange = (
    phoneNumberPropName: string,
    phoneNumber: string,
    country: ICountry,
  ) => {
    this.resetErrorMessage()

    this.setFieldValue(phoneNumberPropName, formatPhoneNumber(phoneNumber))
    this.isPhoneValid = isPhoneNumberValidForSubmission(phoneNumber, country)
    this.validate()
  }

  @action.bound
  public handleChange = (
    e: React.SyntheticEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    this.resetErrorMessage()

    const target = e.currentTarget
    const input = target as HTMLInputElement
    switch (target.type) {
      case FormFieldType.Text:
      case FormFieldType.Avatar:
      case FormFieldType.Image:
      case FormFieldType.SelectOne:
      case FormFieldType.Date:
        this.setFieldValue(target.name, target.value)
        break
      case FormFieldType.Number:
        this.setFieldValue(target.name, +target.value)
        break
      case FormFieldType.File:
        this.setFieldValue(target.name, input.files)
        break
      case FormFieldType.Checkbox:
      case FormFieldType.Switch:
        this.setFieldValue(target.name, input.checked)
        break
      default:
        console.warn('input types other than text not yet implemented!')
    }

    this.validate()
  }

  @action.bound
  public handleFieldValueReset = (name: string) => {
    this.setFieldValue(name, '')
    this.validate()
  }

  public getFieldValue(name: string) {
    return this.internalFields.get(name)
  }

  public setFieldValue(name: string, value: any) {
    this.internalFields.set(name, value)
  }

  public reset(defaultFields?) {
    this.original = defaultFields
    this.internalFields.replace(this.original)
  }

  public clear() {
    this.internalFields.replace({})
  }

  @action.bound
  public validate() {
    this.error = ''
    this.validator(this)
  }
}
