enum LogisticsListKeys {
  LOGISTIC_APP = 'logistic-app',
  LOGISTIC_NAME = 'logistic-name',
  LOGISTIC_STATUS = 'logistic-status',
  LOGISTIC_LOCATION = 'logistic-location',
  LOGISTIC_COMPANY = 'logistic-company',
  LOGISTIC_DATE = 'logistic-date',
  LOGISTIC_RESPONSIBLE = 'logistic-responsible',
}

export default LogisticsListKeys
