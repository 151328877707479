import * as React from 'react'

import * as Icons from '~/client/src/shared/components/Icons'
import TooltipWrapper from '~/client/src/shared/components/TooltipWrapper/TooltipWrapper'

interface IProps {
  tooltipContent: string | JSX.Element
}

export default function InfoIconWithTooltip(props: IProps) {
  return (
    <TooltipWrapper className="row no-grow" content={props.tooltipContent}>
      <Icons.Info className="row no-grow" />
    </TooltipWrapper>
  )
}
