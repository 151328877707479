import { action, computed, observable } from 'mobx'

import BaseTagSelectionStore from '~/client/src/desktop/stores/ui/BaseTagSelection.store'
import { CheckState } from '~/client/src/shared/components/Checkbox'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Assignment from '~/client/src/shared/models/Assignment/Assignment'
import ILogisticItem, {
  LogisticItemApp,
} from '~/client/src/shared/models/ILogisticItem'
import { ITag } from '~/client/src/shared/models/Tag'
import { IAssignmentsStore } from '~/client/src/shared/stores/BaseAssignments.store'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { ACTIVATE_PROJECT } from '~/client/src/shared/stores/EventStore/eventConstants'
import InitialState from '~/client/src/shared/stores/InitialState'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { handleSuccessChangeAssociation } from '~/client/src/shared/utils/entityAssociationHelper'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

// localization: translated

export default class LogisticsResponsibilitiesPanelStore extends BaseTagSelectionStore {
  @observable public inputValue: string = EMPTY_STRING
  @observable public selectedEntities: ILogisticItem[] = []

  public constructor(
    private readonly tagsStore: TagsStore,
    private readonly domainsStores: { [k: string]: IAssignmentsStore },
    private readonly state: InitialState,
    private onClose: () => void,
    selectedEntities: ILogisticItem[] = [],
  ) {
    super()
    this.init(selectedEntities)
  }

  public init(selectedEntities: ILogisticItem[] = []) {
    this.selectedEntities = selectedEntities
  }

  @computed
  public get entitiesAssignmentsMap(): { [appKey: string]: Assignment[] } {
    const map = {}

    this.selectedEntities.forEach(logistic => {
      if (!map[logistic.app]) map[logistic.app] = []
      const assignment = this.domainsStores[
        logistic.app
      ]?.getAssignmentByEntityId(logistic.entityId)
      if (assignment) {
        map[logistic.app].push(assignment)
      }
    })

    Object.keys(map).forEach(key => {
      if (
        map[key]?.length !==
        this.selectedEntities.filter(l => l.app === key).length
      ) {
        map[key].push(Assignment.getDraft())
      }
    })

    return map
  }

  @computed
  public get srcAsTags(): ITag[] {
    const commonRecipientsByType = Object.values(this.entitiesAssignmentsMap)
      .flat()
      .reduce((acc, { recipients }) => {
        recipients.forEach(({ type, ids }) => {
          if (acc[type]) {
            acc[type] = [...new Set([...acc[type], ...ids])]
          } else {
            acc[type] = ids
          }
        })

        return acc
      }, {})

    const tags = []

    Object.keys(commonRecipientsByType).forEach(type => {
      const ids = commonRecipientsByType[type]
      tags.push(...this.tagsStore.getTagsByIds(type, ids))
    })

    return tags
  }

  public getInstanceCheckState = ({
    type: tagType,
    id: tagId,
  }: ITag): CheckState => {
    const doEveryInstancesHaveTag = Object.values(this.entitiesAssignmentsMap)
      .flat()
      .every(assignment => {
        const assignmentRecipient = assignment.recipients.find(
          ({ type }) => type === tagType,
        )

        return assignmentRecipient && assignmentRecipient.ids.includes(tagId)
      })

    return doEveryInstancesHaveTag
      ? CheckState.CHECKED
      : CheckState.PARTIALLY_CHECKED
  }

  @action.bound
  public changeInput(newInputValue: string) {
    this.inputValue = newInputValue
  }

  @action.bound
  public clearInput() {
    this.inputValue = EMPTY_STRING
  }

  @action.bound
  public handleApply() {
    Object.values(this.entitiesAssignmentsMap).forEach(assignments => {
      assignments.forEach(assignment => {
        this.tagsForAdding.forEach(({ type, id: recipientId }) => {
          const recipientsByType = assignment.recipients.find(
            r => r.type === type,
          )

          if (!recipientsByType) {
            assignment.recipients.push({ type, ids: [recipientId] })
          } else if (!recipientsByType.ids.includes(recipientId)) {
            recipientsByType.ids.push(recipientId)
          }
        })

        this.tagsForDeletion.forEach(({ type: type, id: tagId }) => {
          const recipientsByType = assignment.recipients.find(
            r => r.type === type,
          )
          if (!recipientsByType) {
            return
          }

          const index = recipientsByType.ids.findIndex(id => id === tagId)
          if (index !== -1) {
            recipientsByType.ids.splice(index, 1)
          }
        })

        assignment.recipients = assignment.recipients.filter(r => r.ids.length)
      })
    })

    const deleteAssignmentsMap = Object.keys(
      this.entitiesAssignmentsMap,
    ).reduce((acc, key) => {
      acc[key] = this.entitiesAssignmentsMap[key].filter(
        a => !a.isDraft && !a.recipients.length,
      )
      return acc
    }, {})
    Object.keys(deleteAssignmentsMap).forEach(key => {
      if (deleteAssignmentsMap[key]?.length) {
        this.domainsStores[key].delete(
          deleteAssignmentsMap[key].map(a => a.id),
          this.handleAssignmentsDeletionSuccess.bind(this, key),
        )
      }
    })

    const updateAssignmentsMap = Object.keys(
      this.entitiesAssignmentsMap,
    ).reduce((acc, key) => {
      const updatedAssignments = this.entitiesAssignmentsMap[key]
        .filter(a => !a.isDraft && a.recipients.length)
        .map(a => a.getDto())

      let drafts = []

      const draft = this.entitiesAssignmentsMap[key].find(
        a => a.isDraft && a.recipients.length,
      )

      if (draft) {
        const eWithAssignmentIds = [
          ...updatedAssignments,
          ...deleteAssignmentsMap[key],
        ].map(({ entityId }) => entityId)

        const eWithoutAssignments = this.selectedEntities.filter(
          l => l.app === key && !eWithAssignmentIds.includes(l.id),
        )

        draft.projectId = this.state.activeProject.id
        if (key === LogisticItemApp.ANNOUNCEMENT) {
          draft.all = false
        }
        drafts = eWithoutAssignments.map(l => draft.getDto(l.entityId))
      }

      acc[key] = [...drafts, ...updatedAssignments]
      return acc
    }, {})

    Object.keys(updateAssignmentsMap).forEach(key => {
      if (updateAssignmentsMap[key]?.length) {
        this.domainsStores[key].save(
          updateAssignmentsMap[key],
          this.handleAssignmentsUpdateSuccess.bind(this, key),
        )
      }
    })

    super.handleApply()
  }

  @action.bound
  public handlePostEventCallback(eventContext: EventContext) {
    const [eventType] = eventContext.event

    if (eventType === ACTIVATE_PROJECT) {
      this.onClose()
    }
  }

  private handleAssignmentsDeletionSuccess = (app: string) => {
    handleSuccessChangeAssociation(
      Localization.translator.successfullyDeletedAssignmentsByApp(app),
    )
    this.onClose()
  }

  private handleAssignmentsUpdateSuccess = (app: string) => {
    handleSuccessChangeAssociation(
      Localization.translator.successfullyUpdatedExistingAssignmentsByApp(app),
    )
    this.onClose()
  }
}
