import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ITradesFieldsFragment = Pick<
  Types.ITrade,
  'id' | 'createdAt' | 'updatedAt' | 'projectId' | 'color' | 'name'
>

export type ITradesByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type ITradesByProjectIdQuery = {
  trades?: Types.Maybe<{ data: Array<ITradesFieldsFragment> }>
}

export type IListenTradesByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenTradesByProjectIdSubscription = {
  trade?: Types.Maybe<
    Pick<Types.ITradeChangeEvent, 'id'> & {
      item?: Types.Maybe<ITradesFieldsFragment>
    }
  >
}

export type ISaveTradeMutationVariables = Types.Exact<{
  trade: Types.ITradeInput
}>

export type ISaveTradeMutation = {
  saveTrade?: Types.Maybe<Pick<Types.ITrade, 'id'>>
}

export type IDeleteTradeMutationVariables = Types.Exact<{
  tradeId: Types.Scalars['ObjectId']
}>

export type IDeleteTradeMutation = Pick<Types.IMutation, 'deleteTrade'>

export const TradesFieldsFragmentDoc = gql`
  fragment TradesFields on Trade {
    id
    createdAt
    updatedAt
    projectId
    color
    name
  }
`
export const TradesByProjectIdDocument = gql`
  query TradesByProjectId($projectId: ObjectId!) {
    trades(projectId: $projectId, limit: 1000000) {
      data {
        ...TradesFields
      }
    }
  }
  ${TradesFieldsFragmentDoc}
`

/**
 * __useTradesByProjectIdQuery__
 *
 * To run a query within a React component, call `useTradesByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradesByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradesByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useTradesByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ITradesByProjectIdQuery,
    ITradesByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ITradesByProjectIdQuery,
    ITradesByProjectIdQueryVariables
  >(TradesByProjectIdDocument, options)
}
export function useTradesByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ITradesByProjectIdQuery,
    ITradesByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ITradesByProjectIdQuery,
    ITradesByProjectIdQueryVariables
  >(TradesByProjectIdDocument, options)
}
export type TradesByProjectIdQueryHookResult = ReturnType<
  typeof useTradesByProjectIdQuery
>
export type TradesByProjectIdLazyQueryHookResult = ReturnType<
  typeof useTradesByProjectIdLazyQuery
>
export type TradesByProjectIdQueryResult = Apollo.QueryResult<
  ITradesByProjectIdQuery,
  ITradesByProjectIdQueryVariables
>
export const ListenTradesByProjectIdDocument = gql`
  subscription ListenTradesByProjectId($projectId: ObjectId!) {
    trade(projectId: $projectId) {
      id
      item {
        ...TradesFields
      }
    }
  }
  ${TradesFieldsFragmentDoc}
`

/**
 * __useListenTradesByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenTradesByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenTradesByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenTradesByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenTradesByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenTradesByProjectIdSubscription,
    IListenTradesByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenTradesByProjectIdSubscription,
    IListenTradesByProjectIdSubscriptionVariables
  >(ListenTradesByProjectIdDocument, options)
}
export type ListenTradesByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenTradesByProjectIdSubscription
>
export type ListenTradesByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenTradesByProjectIdSubscription>
export const SaveTradeDocument = gql`
  mutation SaveTrade($trade: TradeInput!) {
    saveTrade(trade: $trade) {
      id
    }
  }
`
export type ISaveTradeMutationFn = Apollo.MutationFunction<
  ISaveTradeMutation,
  ISaveTradeMutationVariables
>

/**
 * __useSaveTradeMutation__
 *
 * To run a mutation, you first call `useSaveTradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTradeMutation, { data, loading, error }] = useSaveTradeMutation({
 *   variables: {
 *      trade: // value for 'trade'
 *   },
 * });
 */
export function useSaveTradeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveTradeMutation,
    ISaveTradeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISaveTradeMutation, ISaveTradeMutationVariables>(
    SaveTradeDocument,
    options,
  )
}
export type SaveTradeMutationHookResult = ReturnType<
  typeof useSaveTradeMutation
>
export type SaveTradeMutationResult = Apollo.MutationResult<ISaveTradeMutation>
export type SaveTradeMutationOptions = Apollo.BaseMutationOptions<
  ISaveTradeMutation,
  ISaveTradeMutationVariables
>
export const DeleteTradeDocument = gql`
  mutation DeleteTrade($tradeId: ObjectId!) {
    deleteTrade(id: $tradeId)
  }
`
export type IDeleteTradeMutationFn = Apollo.MutationFunction<
  IDeleteTradeMutation,
  IDeleteTradeMutationVariables
>

/**
 * __useDeleteTradeMutation__
 *
 * To run a mutation, you first call `useDeleteTradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTradeMutation, { data, loading, error }] = useDeleteTradeMutation({
 *   variables: {
 *      tradeId: // value for 'tradeId'
 *   },
 * });
 */
export function useDeleteTradeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteTradeMutation,
    IDeleteTradeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteTradeMutation,
    IDeleteTradeMutationVariables
  >(DeleteTradeDocument, options)
}
export type DeleteTradeMutationHookResult = ReturnType<
  typeof useDeleteTradeMutation
>
export type DeleteTradeMutationResult =
  Apollo.MutationResult<IDeleteTradeMutation>
export type DeleteTradeMutationOptions = Apollo.BaseMutationOptions<
  IDeleteTradeMutation,
  IDeleteTradeMutationVariables
>
