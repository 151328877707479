import { action, computed, observable } from 'mobx'

import {
  InstructionListType,
  getInstructionListTypeTranslate,
} from '~/client/src/shared/models/PermitType'

export type BaseInstructions<T> = {
  list?: T[]
  listType?: string
}

export default abstract class BaseInstructionsConfiguratorStore<T> {
  @observable public shouldShowTextarea: boolean = false

  @observable public shouldShowRemovingConfirmDialog: boolean = false
  @observable public removingInstructionIndex: number = null

  @observable public hoveredInstructionIndex: number = null
  @observable public editingInstructionIndex: number = null
  @observable public newInstructionText: string = ''

  @observable public instructions: BaseInstructions<T> = null

  public constructor(
    instructions: BaseInstructions<T>,
    private onChange: (instructions: BaseInstructions<T>) => void,
  ) {
    this.setInstructions(instructions)
  }

  @action.bound
  public setOnChangeHandler(
    onChange: (instructions: BaseInstructions<T>) => void,
  ) {
    this.onChange = onChange
  }

  @computed
  public get listTypeAsOptions() {
    return Object.values(InstructionListType).map(type => ({
      value: type,
      name: getInstructionListTypeTranslate(type),
    }))
  }

  public abstract setInstructions(instructions: BaseInstructions<T>)

  public abstract getNewInstructionItem(): T

  public abstract updateInstructionTextByIndex(text: string, index: number)

  @action.bound
  public showTextarea() {
    this.shouldShowTextarea = true
    this.resetEditingInstruction()
  }

  @action.bound
  public hideTextarea() {
    this.shouldShowTextarea = false
  }

  @action.bound
  public addNewInstruction() {
    if (this.newInstructionText) {
      const newInstruction = this.getNewInstructionItem()
      this.instructions.list.push(newInstruction)
      this.changeInstructions()
    }

    this.newInstructionText = ''
    this.hideTextarea()
  }

  @action.bound
  public changeNewInstruction(newInstruction: string) {
    this.newInstructionText = newInstruction
  }

  @action.bound
  public removeInstruction() {
    if (Number.isInteger(this.removingInstructionIndex)) {
      this.instructions.list.splice(this.removingInstructionIndex, 1)
      this.changeInstructions()
      this.hideRemovingConfirmModal()
      this.resetEditingInstruction()
    }
  }

  @action.bound
  public changeListType(listType: string) {
    this.instructions.listType = listType
    this.changeInstructions()
  }

  @action.bound
  public handleMouseEnter(index: number) {
    this.hoveredInstructionIndex = index
  }

  @action.bound
  public handleMouseLeave() {
    this.hoveredInstructionIndex = null
  }

  @action.bound
  public showRemovingConfirmModal() {
    this.shouldShowRemovingConfirmDialog = true
  }

  @action.bound
  public hideRemovingConfirmModal() {
    this.shouldShowRemovingConfirmDialog = false
    this.resetRemovingInstructionIndex()
  }

  @action.bound
  public tryToRemoveInstruction(index: number) {
    this.removingInstructionIndex = index
    this.showRemovingConfirmModal()
  }

  @action.bound
  public setEditingInstruction(index: number) {
    this.editingInstructionIndex = index
  }

  @action.bound
  public resetEditingInstruction() {
    this.editingInstructionIndex = null
  }

  @action.bound
  public applyInstructionTextChange() {
    this.changeInstructions()
    this.resetEditingInstruction()
  }

  @action.bound
  protected changeInstructions() {
    this.onChange(this.instructions)
  }

  @action.bound
  private resetRemovingInstructionIndex() {
    this.removingInstructionIndex = null
  }
}
