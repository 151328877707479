import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'

import { IInstructions, PermitFieldType } from '~/client/graph'
import { getBullet } from '~/client/src/shared/utils/PermitInstructionsHelper'

import BaseInstructionsConfigurator from '../BaseInstructionsConfigurator/BaseInstructionsConfigurator'
import InstructionTextHolder from '../BaseInstructionsConfigurator/InstructionTextHolder'
import InstructionsStore from './Instructions.store'

interface IProps {
  instructions: IInstructions
  onChange: (instruction: IInstructions) => void
  className?: string
}

@observer
export default class Instructions extends React.Component<IProps> {
  private readonly store: InstructionsStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new InstructionsStore(props.instructions, props.onChange)
  }

  public render() {
    const { instructions, onChange, className } = this.props

    return (
      <BaseInstructionsConfigurator<string>
        className={className}
        instructions={instructions}
        onChange={onChange}
        store={this.store}
        instructionItemRenderer={this.renderInstructionItem}
        fieldType={PermitFieldType.Instructions}
      />
    )
  }

  private renderInstructionItem = (item: string, index: number) => {
    const {
      handleMouseEnter,
      handleMouseLeave,
      instructions: { listType },
      hoveredInstructionIndex,
      tryToRemoveInstruction,
    } = this.store

    return (
      <div
        key={index}
        className="row y-start relative instruction-item mb15 pr20 "
        onMouseEnter={handleMouseEnter.bind(null, index)}
        onMouseLeave={handleMouseLeave}
      >
        <span className="text large bold blue-highlight no-grow mr12">
          {getBullet(listType, index + 1)}
        </span>
        <InstructionTextHolder<string>
          instructionText={item}
          instructionIndex={index}
          store={this.store}
        />
        {hoveredInstructionIndex === index && (
          <Icon
            icon={IconNames.TRASH}
            className="text blue-highlight pointer absolute del-icon"
            onClick={tryToRemoveInstruction.bind(null, index)}
          />
        )}
      </div>
    )
  }
}
