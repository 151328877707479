import * as React from 'react'

import { observer } from 'mobx-react'

import { SitePermitStatus } from '~/client/graph'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import InitialState from '~/client/src/shared/stores/InitialState'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import PermitApprovalStore from '../../../PermitApproval.store'
import SitePermitCreationFormStore from '../../../SitePermitCreationForm.store'
import CommonPermitButtons from './CommonPermitButtons'

interface IProps {
  actionBarButtonsRenderer: (
    btnText: string,
    isPermitValid: boolean,
    status: SitePermitStatus,
    isDeny?: boolean,
    customBtnHandler?: () => void,
  ) => JSX.Element

  store: SitePermitCreationFormStore
  permitApprovalStore: PermitApprovalStore
  projectDateStore: ProjectDateStore
  state: InitialState
}

const addInspection = 'Add inspection'
const meterReadings = 'Meter Readings'
const notInUseToday = 'Not in use today'
const deadlineTimeEnded = 'Deadline time ended'
const nextInspectionDateIs = (dateString: string): string =>
  `Next inspection date - ${dateString}`
const inspectionAddedOrUpdated = (
  inspectionNumber: number,
  isEmissionForm: boolean,
  isAdded: boolean,
) =>
  `${isEmissionForm ? 'Use log' : 'Inspection'} #${inspectionNumber} ${
    isAdded ? 'added' : 'updated'
  }`

@observer
export default class InspectionPermitButtons extends React.Component<IProps> {
  public render() {
    const { actionBarButtonsRenderer, store, permitApprovalStore } = this.props
    const {
      getLatestInspectionChangeByDate,
      isNewInspectionAllowed,
      isLastInspectionDay,
      existingPermit,
      isEmissionFormType,
      inspectionFieldsStore,
      addNotInUseInspection,
      template,
      nextInspectionDates,
    } = store
    const { selectedInspection } = inspectionFieldsStore
    const { isDoneOrDenied, isActive } = existingPermit

    if (isNewInspectionAllowed && !!selectedInspection && !isDoneOrDenied) {
      return null
    }

    const isInActiveStatus = isActive(template)

    if (
      isInActiveStatus &&
      isNewInspectionAllowed &&
      !getLatestInspectionChangeByDate(new Date())
    ) {
      return (
        <div className="row x-center y-center">
          <BaseActionButton
            isActive={false}
            isEnabled
            className="m5 ellipsis gray-theme"
            isGrow
            title={notInUseToday}
            onClick={addNotInUseInspection}
          />
          <BaseActionButton
            isActive={false}
            isEnabled
            className="m5 ellipsis primary-theme-inverted"
            isGrow
            title={isEmissionFormType ? meterReadings : addInspection}
            onClick={this.openNewInspection}
          />
        </div>
      )
    }

    if (
      isInActiveStatus &&
      !isLastInspectionDay &&
      nextInspectionDates?.length
    ) {
      return this.renderAdditionalStatus()
    }

    return (
      <CommonPermitButtons
        actionBarButtonsRenderer={actionBarButtonsRenderer}
        store={store}
        permitApprovalStore={permitApprovalStore}
      />
    )
  }

  private renderAdditionalStatus(): JSX.Element {
    const { store } = this.props
    const {
      getLatestInspectionChangeByDate,
      isLastInspectionDay,
      permitInspectionData,
      isInspectionDeadlineEnded,
      isEmissionFormType,
    } = store

    if (isLastInspectionDay) {
      return null
    }

    const inspectionForToday = getLatestInspectionChangeByDate(new Date())
    const isInspectionAdded = !!inspectionForToday?.fields?.length

    if (isInspectionAdded) {
      return (
        <div className="col">
          <div className="row x-center">
            <span className="text large bold grey-light w-fit-content mr5">
              {inspectionAddedOrUpdated(
                permitInspectionData.length,
                isEmissionFormType,
                !!inspectionForToday.permitId,
              )}
            </span>
          </div>
          {this.nextInspectionText}
        </div>
      )
    }

    if (!!inspectionForToday && !isInspectionAdded) {
      return (
        <div className="col">
          <div className="row x-center">
            <span className="text center large bold lp0155 grey-light">
              {notInUseToday}
            </span>
          </div>
          {this.nextInspectionText}
        </div>
      )
    }

    if (isInspectionDeadlineEnded) {
      return (
        <div className="col">
          <div className="row x-center">
            <span className="text center large bold lp0155 red">
              {deadlineTimeEnded}
            </span>
          </div>
          {this.nextInspectionText}
        </div>
      )
    }

    return this.nextInspectionText
  }

  private get nextInspectionText(): JSX.Element {
    const {
      store,
      projectDateStore: { getWeekdayMonthDayAndYearToDisplay },
    } = this.props
    const { nextInspectionDates } = store

    const nextInspectionDate = getWeekdayMonthDayAndYearToDisplay(
      nextInspectionDates[0],
    )

    return (
      <div className="row x-center py5">
        <span className="text center large bold lp0155 red">
          {nextInspectionDateIs(nextInspectionDate)}
        </span>
      </div>
    )
  }

  private openNewInspection = () => {
    this.props.store.inspectionFieldsStore.selectInspection(null, new Date())
  }
}
