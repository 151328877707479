import {
  IMaterialProcurementData,
  IProjectMaterialCategory,
  IStringPair,
} from '~/client/graph'
import metadataKeys from '~/client/src/shared/constants/metadataKeys'
import Guard from '~/client/src/shared/utils/Guard'

export default class MaterialCategory {
  public static fromDto(dto: IProjectMaterialCategory) {
    return new MaterialCategory(
      dto.id,
      dto.name,
      dto.projectId,
      dto.materialsUploadId,
      dto.description,
      dto.plannedQuantity,
      dto.procurementDataList,
      dto.createdAt,
      dto.updatedAt,
      dto.metadata,
    )
  }

  private readonly metadataMap = new Map<string, string>()

  public constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly projectId: string,
    public readonly materialsUploadId: string,
    public readonly description: string,
    public readonly plannedQuantity: number,
    public readonly procurementDataList: IMaterialProcurementData[],
    public readonly createdAt: number,
    public readonly updatedAt: number,
    metadata?: IStringPair[],
  ) {
    Guard.requireAll({ id })

    metadata?.forEach(m => this.metadataMap.set(m.key, m.value))
  }

  public get isFromConcreteDirect(): boolean {
    const { integrationKey, cdIntegrationValue } = metadataKeys

    if (this.metadataMap.has(integrationKey)) {
      return this.metadataMap.get(integrationKey) === cdIntegrationValue
    }
  }

  public get hasProcurementIds(): boolean {
    return this.procurementDataList?.some(pr => pr.procurementId)
  }
}
