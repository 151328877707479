import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import DesktopActivityPresetsSelector from '~/client/src/desktop/components/Filters/SavedFilters/ActivityPresetSelector/DesktopActivityPresetsSelector'
import SavedFiltersActionBar from '~/client/src/desktop/components/Filters/SavedFilters/SavedFiltersActionBar'
import SavedFiltersContainer from '~/client/src/desktop/components/Filters/SavedFilters/SavedFiltersSelector/SavedFiltersContainer'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import FilterFooter from '~/client/src/shared/components/Filters/FilterUtils/FilterFooter/FilterFooter'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ActivityPresetsStore from '~/client/src/shared/stores/domain/ActivityPresets.store'

import DesktopActivitiesSavedFiltersStore from '../../ActivitiesSavedFilters/DesktopActivitiesSavedFilters.store'

import '../SavedFilters.scss'

// localization: translated

interface IProps {
  activityPresetsStore?: ActivityPresetsStore
  state?: DesktopInitialState
  desktopSavedFiltersStore: DesktopActivitiesSavedFiltersStore
  activityListStore: DesktopActivityListStore
}

@inject('activityPresetsStore', 'state')
@observer
export default class SavedFiltersModal extends React.Component<IProps> {
  public render() {
    const {
      activityPresetsStore: { list },
      state: { appliedActivityPresetId },
      desktopSavedFiltersStore: store,
      activityListStore,
    } = this.props

    const { getActivityCountByCustomFilter } = activityListStore

    const {
      selectedCustomFilterId,
      selectedActivityPresetId,
      appliedCustomFilterId,
      deleteCustomFilter,
      deleteActivityPreset,
      onSaveFiltersClicked,
      editCustomFilter,
      editActivityPreset,
      selectCustomFilterById,
      selectActivityPresetById,
      customFilters,
      selectCustomFilter,
    } = store

    const activeFilterId =
      selectedCustomFilterId ||
      (!selectedActivityPresetId && appliedCustomFilterId)
    const activePresetId =
      selectedActivityPresetId ||
      (!selectedCustomFilterId && appliedActivityPresetId)

    return (
      <div className="filter-container saved">
        <SavedFiltersActionBar
          selectedCustomFilterId={selectedCustomFilterId}
          appliedCustomFilterId={appliedCustomFilterId}
          selectedActivityPresetId={selectedActivityPresetId}
          appliedActivityPresetId={appliedActivityPresetId}
          deleteCustomFilter={deleteCustomFilter}
          deleteActivityPreset={deleteActivityPreset}
          onSaveFiltersClicked={onSaveFiltersClicked}
          editCustomFilter={editCustomFilter}
          editActivityPreset={editActivityPreset}
        />
        <div className="filter-container-content mx15">
          <SavedFiltersContainer
            activeFilterId={activeFilterId}
            editCustomFilter={editCustomFilter}
            getEntitiesCountByCustomFilter={getActivityCountByCustomFilter}
            selectCustomFilterById={selectCustomFilterById}
            customFilters={customFilters}
            selectCustomFilter={selectCustomFilter}
            numberOfEntitiesTitle={Localization.translator.numberOfActivities}
          />
          <DesktopActivityPresetsSelector
            onChange={store.selectActivityPreset}
            selectedPresetId={activePresetId}
            editActivityPreset={editActivityPreset}
            selectActivityPresetById={selectActivityPresetById}
            onReset={store.resetActivityPreset}
            presets={list}
          />
        </div>
        <div className="bt-light-grey mt10">
          <FilterFooter
            clickOnApply={this.applyClicked}
            clickOnCancel={store.closeSavedFilters}
          />
        </div>
      </div>
    )
  }

  public componentWillUnmount() {
    this.props.desktopSavedFiltersStore.clearSavedFilters()
  }

  @action.bound
  private applyClicked() {
    const {
      applyCustomFilter,
      applyActivityPreset,
      selectedCustomFilterId,
      selectedActivityPresetId,
    } = this.props.desktopSavedFiltersStore
    if (selectedActivityPresetId) {
      applyActivityPreset()
      return
    }

    if (selectedCustomFilterId) {
      applyCustomFilter()
    }
  }
}
