import * as React from 'react'

import { action, computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import AssociationStatuses from '~/client/src/shared/enums/AssociationStatuses'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IFollowingsStore from '~/client/src/shared/models/IFollowingsStore'
import InitialState from '~/client/src/shared/stores/InitialState'

import { IAssignmentsStore } from '../../stores/BaseAssignments.store'
import Star from './components/Star/Star'

// translated

interface IBaseEntityAssociationStatus {
  entityId: string
  domainAssignmentsStore?: IAssignmentsStore
  domainFollowingsStore?: IFollowingsStore
  className?: string
  shouldHintShow?: boolean

  isBigStarMode?: boolean
  state?: InitialState
}

@inject('state')
@observer
export default class BaseEntityAssociationStatus extends React.Component<IBaseEntityAssociationStatus> {
  public static defaultProps = {
    className: '',
    shouldHintShow: false,
  }

  public render() {
    const { className, shouldHintShow, isBigStarMode } = this.props

    let associationStatus: AssociationStatuses
    let hint = ''

    switch (true) {
      case this.isAssigned:
        hint = Localization.translator.assigned
        associationStatus = AssociationStatuses.ASSIGNED
        break
      case this.isFollowed:
        hint = Localization.translator.followed
        associationStatus = AssociationStatuses.FOLLOWED
        break
      default:
        hint = Localization.translator.notFollowing
        associationStatus = AssociationStatuses.DEFAULT
    }

    return (
      <div className={`row ${className}`}>
        {shouldHintShow && (
          <label className="row no-grow text large nowrap light h20 pr5">
            {hint}
          </label>
        )}
        <Star
          isBigStarMode={isBigStarMode}
          associationStatus={associationStatus}
          onClick={this.clickStar}
        />
      </div>
    )
  }

  @computed
  private get isAssigned(): boolean {
    const { state, entityId, domainAssignmentsStore } = this.props

    return domainAssignmentsStore
      ?.getAssignedEntitiesByUserId?.(state.user.id)
      .includes(entityId)
  }

  @computed
  private get isFollowed(): boolean {
    const { domainFollowingsStore, entityId } = this.props
    return domainFollowingsStore?.isEntityFollowed?.(entityId)
  }

  @action.bound
  private clickStar(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()

    if (this.isAssigned) {
      return
    }

    const { domainFollowingsStore, entityId } = this.props
    domainFollowingsStore.toggleEntityFollowing(entityId)
  }
}
