import { action, computed, observable } from 'mobx'
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils'

import { ISiteLocation } from '~/client/graph'
import {
  GetRelativeActivitiesDocument,
  GetWbsDocument,
} from '~/client/graph/operations/generated/Schedule.generated'
import { TagType } from '~/client/src/shared/enums/TagType'
import Wbs from '~/client/src/shared/models/Wbs'
import ActivityAssignmentsStore from '~/client/src/shared/stores/domain/ActivityAssignments.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectRolesStore from '~/client/src/shared/stores/domain/ProjectRoles.store'
import Guard from '~/client/src/shared/utils/Guard'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import Activity from '../../../../models/Activity'
import User from '../../../../models/User'
import UserProject from '../../../../models/UserProject'
import EventsStore from '../../../../stores/EventStore/Events.store'
import GraphExecutorStore from '../../../../stores/domain/GraphExecutor.store'
import ProjectMembersStore from '../../../../stores/domain/ProjectMembers.store'
import UserProjectsStore from '../../../../stores/domain/UserProjects.store'
import WbsStore from '../../../../stores/domain/WbsStore/Wbs.store'

const SUPERINTENDENTS_REGEX = /superintendent/i
const FOREMEN_REGEX = /forem(a|e)n/i

export default class ActivityInfoStore {
  private loadedDataValue = {
    predecessors: [],
    successors: [],
    wbs: Wbs.createPlaceholder(),
  }
  @observable private selectedActivity: Activity = null
  @observable private wbsAndRelativesObservable: IPromiseBasedObservable<void>

  @computed
  private get loadedData() {
    return this.wbsAndRelativesObservable.case({
      pending: () => this.loadedDataValue,
      rejected: () => this.loadedDataValue,
      fulfilled: () => this.loadedDataValue,
    })
  }

  @computed
  public get isLoading() {
    return this.wbsAndRelativesObservable.case({
      pending: () => true,
      rejected: () => false,
      fulfilled: () => false,
    })
  }

  public get predecessors() {
    return this.loadedData.predecessors
  }

  public get successors() {
    return this.loadedData.successors
  }

  public get wbs() {
    return this.loadedData.wbs
  }

  public constructor(
    private readonly activityAssignmentsStore: ActivityAssignmentsStore,
    private readonly projectRolesStore: ProjectRolesStore,
    private readonly companiesStore: CompaniesStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly graphExecutorStore: GraphExecutorStore,
    private readonly eventsStore: EventsStore,
    private readonly wbsStore: WbsStore,
    selectedActivity: Activity,
  ) {
    Guard.requireAll({
      activityAssignmentsStore,
      graphExecutorStore,
      eventsStore,
    })

    this.init(selectedActivity)
  }

  @action.bound
  public init(activity: Activity) {
    this.selectedActivity = activity

    this.loadWbsAndRelatedActivities()
  }

  public get locations(): ISiteLocation[] {
    return this.selectedActivity.locations
  }

  public get companies(): string {
    return this.selectedActivity.companies.join(', ') || NO_VALUE
  }

  @computed
  public get owners(): { [companyName: string]: User[] } {
    const { list: projectMembers } = this.projectMembersStore

    const assignment = this.activityAssignmentsStore.getAssignmentByEntityId(
      this.selectedActivity.code,
    )

    const assignedUserIds = assignment
      ? assignment.getRecipientsByType(TagType.User)
      : []

    const activityMembers = projectMembers
      .filter(user => assignedUserIds.includes(user.id))
      .sort(user => {
        const rolesAsString = UserProject.getAllRolesAsString(
          user,
          this.userProjectsStore,
          this.projectRolesStore,
        )

        switch (true) {
          case !rolesAsString:
            return 1
          case SUPERINTENDENTS_REGEX.test(rolesAsString):
            return -1
          case FOREMEN_REGEX.test(rolesAsString):
            return 0
          default:
            return 1
        }
      })

    return this.groupUsersByCompany(activityMembers)
  }

  @action.bound
  private async loadWbsAndRelatedActivities() {
    if (this.selectedActivity) {
      this.wbsAndRelativesObservable = fromPromise(
        this.loadWbsAndRelationships(),
      )
    }
  }

  private groupUsersByCompany(users: User[]): {
    [companyName: string]: User[]
  } {
    if (!users.length) {
      return null
    }

    return users.reduce((companiesMap, user) => {
      const companyName = UserProject.getCompanyName(
        user,
        this.userProjectsStore,
        this.companiesStore,
      )

      if (!companiesMap[companyName]) {
        companiesMap[companyName] = []
      }

      companiesMap[companyName].push(user)
      return companiesMap
    }, {} as any)
  }

  private async loadWbsAndRelationships() {
    let result = await this.graphExecutorStore.query(
      GetRelativeActivitiesDocument,
      {
        scheduleId: this.eventsStore.appState.activeScheduleId,
        activityP6Code: this.selectedActivity.code,
      },
    )

    if (result.error) {
      throw result.error
    }

    Object.assign(this.loadedData, result.data.relativeActivities)

    if (!this.selectedActivity.wbs) {
      this.loadedData.wbs.updateFromJson(Wbs.createPlaceholder())
      return
    }

    const existingWbs = this.wbsStore.list.find(
      w => w.id === this.selectedActivity.wbs,
    )

    if (existingWbs) {
      this.loadedData.wbs.updateFromJson(existingWbs)
      return
    }

    result = await this.graphExecutorStore.query(GetWbsDocument, {
      id: this.selectedActivity.wbs,
    })

    if (result.error) {
      throw result.error
    }

    this.loadedData.wbs.updateFromJson(result.data.workBreakdownStructure)
  }
}
