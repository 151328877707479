import { IContentObject } from '~/client/graph'
import SafetyHazard, {
  SafetyHazardTypes,
} from '~/client/src/shared/models/SafetyHazard'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'

import EventsStore from '../EventStore/Events.store'
import ContentObjectsStore from './ContentObjects.store'

export default class SafetyHazardsStore extends ContentObjectsStore<
  SafetyHazardTypes,
  SafetyHazard
> {
  public constructor(messagesStore: MessagesStore, eventsStore: EventsStore) {
    super(messagesStore, eventsStore)
  }

  public get byId() {
    return this.eventsStore.appState.safetyHazards
  }

  protected createAnInstance(id: string, dto?: IContentObject) {
    return new SafetyHazard(id, dto)
  }
}
