import { action, computed } from 'mobx'

import { DeliveryFilterType } from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import FormReportFilterType from '~/client/src/shared/enums/FormReportFilterType'
import ScanStationFilterType from '~/client/src/shared/enums/ScanStationFilterType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import Delivery from '~/client/src/shared/models/Delivery'
import IScannerHistoryPair from '~/client/src/shared/models/IScannerHistoryPair'
import SitePermit from '~/client/src/shared/models/Permit'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { RESET_ALL_FILTERS } from '~/client/src/shared/stores/EventStore/eventConstants'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'
import SuperFilterStore, {
  IRootOption,
} from '~/client/src/shared/stores/ui/SuperFilter.store'
import Guard from '~/client/src/shared/utils/Guard'

import DeliveryReportListStore from '../../../ReportsList/DeliveryReport/DeliveryReportList.store'
import FormReportListStore from '../../../ReportsList/FormReportList/FormReportList.store'
import ScanStationListStore from '../../../ReportsList/ScanStation/ScanStationList.store'

export type ISourceMap = { [optionId: string]: string[] }

export default class BaseReportsFilterStore extends SuperFilterStore {
  public get totalHint(): string {
    return Localization.translator.totalForms
  }

  public constructor(
    public readonly type:
      | ScanStationFilterType
      | FormReportFilterType
      | DeliveryFilterType,
    protected readonly state: DesktopInitialState,
    protected readonly sourceMap: ISourceMap,
    protected readonly reportsListStore:
      | ScanStationListStore
      | FormReportListStore
      | DeliveryReportListStore,
    protected readonly onShowChanged: (isShown: boolean, type: string) => void,
    protected readonly fieldsMap: { [filterType: string]: UIFilterInfo },
    getTypeCaption: (type: string) => string,
    private readonly getOptionName?: (
      optionId: string,
      filterType: string,
    ) => string,
    private readonly filterKeyOnStorage?: string,
  ) {
    super(
      fieldsMap[type],
      type,
      state,
      KnownTranslatorKeys.seeXReports,
      getTypeCaption,
      onShowChanged,
    )

    Guard.requireAll({ type, state, sourceMap, reportsListStore })
  }

  @action.bound
  public onFilterActionRequest(eventContext: EventContext) {
    const [eventType] = eventContext.event
    switch (eventType) {
      case RESET_ALL_FILTERS:
        return this.setInitialFilterValues()
    }
  }

  @computed
  public get optionsTree(): IRootOption[] {
    const options = Object.keys(this.sourceMap)
    return [
      {
        id: this.type,
        name: this.typeCaption,
        options: options.map(option => ({
          id: option,
          name: this.getOptionName?.(option, this.type) || option,
          instancesIds: this.sourceMap[option],
        })),
      },
    ]
  }

  @action
  public setInitialFilterValues() {
    if (!this.filter) {
      return
    }

    const savedFilter = localStorage.getItem(this.filterStorageFullKeyName)

    if (savedFilter) {
      const savedFilterKeys = Object.keys(JSON.parse(savedFilter))
      this.selectOptionsByIds(savedFilterKeys || [])
    } else {
      this.clickOnSelectAll()
    }

    this.clickOnApply()
  }

  private get filterStorageFullKeyName() {
    return `${this.state.activeProject.id}_${this.filterKeyOnStorage}_${this.type}`
  }

  @action.bound
  public clickOnApply() {
    localStorage.setItem(
      this.filterStorageFullKeyName,
      JSON.stringify(this.filter.selectedFilterOptions),
    )
    this.handleApply()
  }

  public filterOptionInstances = (ids: string[]) => {
    return (
      this.allFilteredInstances as Array<
        IScannerHistoryPair | SitePermit | Delivery
      >
    ).filter(i => ids.includes(i.id))
  }

  @computed
  private get allFilteredInstances():
    | IScannerHistoryPair[]
    | SitePermit[]
    | Delivery[] {
    const { getFilteredCollectionExcludeFilter } = this.reportsListStore
    return getFilteredCollectionExcludeFilter([this.type])
  }
}
