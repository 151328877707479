import BaseModel from '~/client/src/shared/models/BaseModel'
import IAssociationDto from '~/client/src/shared/types/IAssociationDto'

export default class AssociationItem extends BaseModel<IAssociationDto> {
  public static fromDto(dto: IAssociationDto) {
    return new AssociationItem(dto.id, dto.userId, dto.entityId, dto.projectId)
  }

  public constructor(
    public readonly id: string,
    public readonly userId: string,
    public readonly entityId: string,
    public readonly projectId: string,
  ) {
    super(id)
  }

  public get asJson(): IAssociationDto {
    return {
      id: this.id,
      userId: this.userId,
      entityId: this.entityId,
      projectId: this.projectId,
    }
  }
}
