import { action, computed, observable } from 'mobx'

import { ISitePermitBicInspection } from '~/client/graph'
import PermitBicInspection from '~/client/src/shared/models/PermitBicInspection'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'

export default class PermitBicInspectionsStore {
  @observable public isDataReceived = false

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly graphExecutorStore: GraphExecutorStore,
  ) {}

  @computed
  public get list() {
    return Array.from(this.byId.values())
  }

  @computed
  public get byId() {
    return this.eventsStore.appState.permitBicInspections
  }

  public loadAndListenBicInspectionsByFormId = (
    formId: string,
  ): Promise<void> => {
    return new Promise(resolve => {
      this.eventsStore.dispatch(
        e.LOAD_AND_LISTEN_TO_SITE_PERMIT_BIC_INSPECTIONS,
        formId,
        resolve,
      )
    })
  }

  public dontListenToBicInspections = () => {
    this.graphExecutorStore.terminateSubscription(
      e.LISTEN_TO_SITE_PERMIT_BIC_INSPECTIONS,
    )
    this.clearList()
  }

  @action.bound
  public clearList() {
    this.isDataReceived = false
    this.byId.clear()
  }

  @action.bound
  public receiveList(list: ISitePermitBicInspection[]) {
    this.clearList()

    this.updateFromList(list)

    this.isDataReceived = true
  }

  private updateFromList(list: ISitePermitBicInspection[]) {
    list.forEach(dto => this.receiveOne(dto.id, dto))
  }

  @action.bound
  public receiveOne(id: string, dto: ISitePermitBicInspection) {
    if (dto) {
      const permitBicInspection = PermitBicInspection.fromDto(dto)
      this.byId.set(permitBicInspection.id, permitBicInspection)
    } else {
      this.byId.delete(id)
    }
  }

  public saveBicInspection(permitBicInspection: PermitBicInspection) {
    const inputDto = PermitBicInspection.toDto(permitBicInspection)

    this.eventsStore.dispatch(e.SAVE_SITE_PERMIT_BIC_INSPECTION, inputDto)
  }

  public getBicInspectionsForPermit = (permitId: string) => {
    return this.list
      .filter(bic => bic.permitId === permitId)
      .sort((a, b) => a.createdAt - b.createdAt)
  }
}
