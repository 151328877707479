import * as React from 'react'

import { classList } from 'react-classlist-helper'

import TooltipWrapper from '~/client/src/shared/components/TooltipWrapper/TooltipWrapper'

interface IconProps {
  className: string
  onClick: () => void
}

interface IProps {
  iconRenderer: (props: IconProps) => JSX.Element
  isEnabled: boolean
  tooltip: string | JSX.Element
  onClick: () => void
}

export default function UndoRedoAction({
  iconRenderer,
  isEnabled,
  tooltip,
  onClick,
}: IProps): JSX.Element {
  return (
    <TooltipWrapper className="ml4 no-select" content={tooltip}>
      {iconRenderer({
        className: classList({
          'text br-rounded highlighted-hover highlighted-active row pa8': true,
          'pointer blue-highlight': isEnabled,
          'light unclickable-element': !isEnabled,
        }),
        onClick,
      })}
    </TooltipWrapper>
  )
}
