import * as React from 'react'

import { MenuItem } from '@blueprintjs/core'
import { ItemRenderer, Select } from '@blueprintjs/select'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import Location from '~/client/src/shared/components/SitemapAttributeSelector/Location'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import Building from '~/client/src/shared/models/LocationObjects/Building'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import DeliveryRestrictionsStore, {
  IRestrictionViewModel,
} from '~/client/src/shared/stores/domain/DeliveryRestrictions.store'

import LevelAreaTree from './LevelAreaTree'
import RestrictionModal from './RestrictionModal'

const restrictionsLegend = {
  whereToOffload: 'Where to offload',
  howToAccess: 'How to access',
  availableEquipment: 'Available equipment',
  accessibleInteriors: 'Accessible interiors',
}

const siteObjects = 'Site Objects'
const restrictOptions = 'Restrict Options'
const addNewZone = 'Add new zone'

const LOCATION_ICON_SIZE = 20

interface IProps {
  store: DeliveryRestrictionsStore
}

@observer
export default class DeliveryRestrictionsContent extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props)

    props.store.displayFirstBuilding()
  }

  public render() {
    return (
      <div className="delivery-restriction-content full-width col pl35">
        {this.renderBuildingSelector()}
        {this.renderRestrictionTable()}
      </div>
    )
  }

  private renderBuildingSelector() {
    const { buildings, displayedBuilding } = this.props.store

    return (
      <div className="row no-flex-children pb20">
        {buildings.map((building, idx) => {
          const isActive =
            displayedBuilding && displayedBuilding.id === building.id

          return (
            <div
              key={building.id}
              className={classList({
                'inline-block px12 pointer no-select py4': true,
                'bl-light-grey': !!idx,
              })}
              onClick={this.selectBuilding.bind(this, building)}
            >
              <SitemapAttributeTag
                shouldShowAsTag={isActive}
                isBordered={isActive}
                contentContainerClassName="py4"
                dataObject={building}
                iconSize={LOCATION_ICON_SIZE}
              >
                <span className="text extra-large">{building.name}</span>
              </SitemapAttributeTag>
            </div>
          )
        })}
        {
          <div
            key={siteObjects}
            className={classList({
              'inline-block px12 pointer no-select py4': true,
              'bl-light-grey': !!buildings.length,
            })}
            onClick={this.selectBuilding.bind(this, null)}
          >
            <SitemapAttributeTag
              shouldShowAsTag={!displayedBuilding}
              isBordered={!displayedBuilding}
              contentContainerClassName="py4"
              iconSize={LOCATION_ICON_SIZE}
            >
              <span className="text extra-large">{siteObjects}</span>
            </SitemapAttributeTag>
          </div>
        }
      </div>
    )
  }

  private selectBuilding(building: Building) {
    this.props.store.displayBuilding(building)
  }

  private renderRestrictionTable() {
    return (
      <div className="full-width" style={{ height: `calc(100% - 58px)` }}>
        <div className="restriction-table full-height col">
          <div className="no-grow row">{this.renderTableHeader()}</div>
          <div className="restriction-table-content full-height col overflow-y-auto">
            {this.renderTableContent()}
          </div>
          <div className="no-grow row">{this.renderTableFooter()}</div>
        </div>
      </div>
    )
  }

  private renderTableHeader() {
    const { displayedBuildingRestrictions } = this.props.store

    return (
      <>
        {this.renderLegendCell(restrictionsLegend.whereToOffload)}
        {displayedBuildingRestrictions.map(r => (
          <div className="no-grow cell row y-center relative" key={r.zone.id}>
            {this.renderSitemapItem(r.zone)}
            {this.renderModal(r)}
            <Icons.Cross
              className="remove-icon pointer no-grow"
              onClick={this.removeRestriction.bind(this, r)}
            />
          </div>
        ))}
        {this.renderAddZoneSelector()}
      </>
    )
  }

  private removeRestriction(r: IRestrictionViewModel) {
    this.props.store.removeRestriction(r)
  }

  private renderAddZoneSelector() {
    const { displayedBuildingNotPresentedZones, addNewZoneRestriction } =
      this.props.store

    return (
      !!displayedBuildingNotPresentedZones.length && (
        <div className="no-grow cell-width full-height row y-center pa12 bt-light-input-border">
          <Select
            items={displayedBuildingNotPresentedZones}
            itemRenderer={this.renderZoneOption}
            onItemSelect={addNewZoneRestriction}
            className="area-select pa8"
            popoverProps={{
              popoverClassName: `area-select-popover`,
              minimal: true,
            }}
            filterable={false}
          >
            <div className="text blue-highlight bold large pointer row y-center">
              <Icons.PlusCircle className="h28 pr8" />
              {addNewZone}
            </div>
          </Select>
        </div>
      )
    )
  }

  private renderZoneOption: ItemRenderer<LocationBase> = (
    option,
    { handleClick, modifiers },
  ) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={option.id}
        onClick={handleClick}
        text={<Location dto={option} />}
      />
    )
  }

  private renderTableContent() {
    const { displayedBuildingRestrictions } = this.props.store

    return (
      <>
        <div className="no-grow row">
          {this.renderLegendCell(restrictionsLegend.howToAccess)}
          {displayedBuildingRestrictions.map(r => (
            <div className="no-grow cell" key={r.zone.id}>
              {r.routes.map(this.renderSitemapItem)}
              {r.gates.map(this.renderSitemapItem)}
            </div>
          ))}
        </div>
        <div className="no-grow row">
          {this.renderLegendCell(restrictionsLegend.availableEquipment)}
          {displayedBuildingRestrictions.map(r => (
            <div className="no-grow cell" key={r.zone.id}>
              {r.equipment.map(this.renderSitemapItem)}
            </div>
          ))}
        </div>
        <div className="no-grow row levels-hierarchy">
          {this.renderLegendCell(restrictionsLegend.accessibleInteriors)}
          {displayedBuildingRestrictions.map(r => (
            <div className="no-grow cell" key={r.zone.id}>
              <LevelAreaTree levels={r.levels} areas={r.areas} />
            </div>
          ))}
        </div>
        <div className="row full-height">
          <div className="full-height no-grow cell-width legend br-light-input-border" />
          {displayedBuildingRestrictions.map(r => (
            <div
              className="full-height no-grow cell-width br-light-input-border"
              key={r.zone.id}
            />
          ))}
        </div>
      </>
    )
  }

  private renderTableFooter() {
    const { displayedBuildingRestrictions } = this.props.store

    return (
      <>
        <div className="full-height no-grow cell-width legend br-light-input-border" />
        {displayedBuildingRestrictions.map(r => (
          <div className="no-grow cell row y-center" key={r.zone.id}>
            <div
              className="text blue-highlight bold large pointer center"
              onClick={this.openRestrictionModal.bind(this, r)}
            >
              {restrictOptions}
            </div>
          </div>
        ))}
      </>
    )
  }

  private openRestrictionModal(r: IRestrictionViewModel) {
    this.props.store.openModalForRestriction(r)
  }

  private renderLegendCell(title: string) {
    return (
      <div className="cell legend no-grow">
        <div className="text large uppercase bold">{title}</div>
      </div>
    )
  }

  private renderSitemapItem = (dto: LocationBase, key?: number) => {
    return (
      <div key={key} className="row y-start">
        <div className="no-grow pr5">
          <div className="dot" />
        </div>
        <div className="full-width">
          <Location dto={dto} />
        </div>
      </div>
    )
  }

  private renderModal(r: IRestrictionViewModel) {
    const { isRestrictionModalDisplayed } = this.props.store

    if (!isRestrictionModalDisplayed(r)) {
      return null
    }

    return <RestrictionModal store={this.props.store} restriction={r} />
  }
}
