import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IAnnouncementFieldsFragment = Pick<
  Types.IAnnouncement,
  | 'id'
  | 'startDate'
  | 'endDate'
  | 'isClosure'
  | 'content'
  | 'projectId'
  | 'title'
  | 'dateToAppear'
> & {
  location?: Types.Maybe<Pick<Types.ISiteLocation, 'id' | 'type' | 'levels'>>
  attachments?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IAttachment, 'fileName' | 'size' | 'url'>>>
  >
  orders?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IAnnouncementOrder, 'order' | 'date'>>>
  >
  relationship?: Types.Maybe<
    Pick<Types.IAnnouncementRelationship, 'foreignKeyId' | 'type'>
  >
}

export type IGetAnnouncementsListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetAnnouncementsListQuery = {
  announcements?: Types.Maybe<{ data: Array<IAnnouncementFieldsFragment> }>
}

export type IDeleteAnnouncementsMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteAnnouncementsMutation = Pick<
  Types.IMutation,
  'deleteManyAnnouncements'
>

export type ISaveAnnouncementsMutationVariables = Types.Exact<{
  announcementsWithAssignments:
    | Array<Types.Maybe<Types.IAnnouncementWithAssignmentInput>>
    | Types.Maybe<Types.IAnnouncementWithAssignmentInput>
}>

export type ISaveAnnouncementsMutation = {
  saveManyAnnouncements?: Types.Maybe<
    Array<Types.Maybe<IAnnouncementFieldsFragment>>
  >
}

export type IListenToAnnouncementsSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToAnnouncementsSubscription = {
  announcement?: Types.Maybe<
    Pick<Types.IAnnouncementChangeEvent, 'id'> & {
      item?: Types.Maybe<IAnnouncementFieldsFragment>
    }
  >
}

export const AnnouncementFieldsFragmentDoc = gql`
  fragment AnnouncementFields on Announcement {
    id
    startDate
    endDate
    location {
      id
      type
      levels
    }
    attachments {
      fileName
      size
      url
    }
    orders {
      order
      date
    }
    isClosure
    content
    projectId
    title
    dateToAppear
    relationship {
      foreignKeyId
      type
    }
  }
`
export const GetAnnouncementsListDocument = gql`
  query GetAnnouncementsList($projectId: ObjectId!) {
    announcements(limit: 1000000, projectId: $projectId) {
      data {
        ...AnnouncementFields
      }
    }
  }
  ${AnnouncementFieldsFragmentDoc}
`

/**
 * __useGetAnnouncementsListQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementsListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetAnnouncementsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetAnnouncementsListQuery,
    IGetAnnouncementsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetAnnouncementsListQuery,
    IGetAnnouncementsListQueryVariables
  >(GetAnnouncementsListDocument, options)
}
export function useGetAnnouncementsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetAnnouncementsListQuery,
    IGetAnnouncementsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetAnnouncementsListQuery,
    IGetAnnouncementsListQueryVariables
  >(GetAnnouncementsListDocument, options)
}
export type GetAnnouncementsListQueryHookResult = ReturnType<
  typeof useGetAnnouncementsListQuery
>
export type GetAnnouncementsListLazyQueryHookResult = ReturnType<
  typeof useGetAnnouncementsListLazyQuery
>
export type GetAnnouncementsListQueryResult = Apollo.QueryResult<
  IGetAnnouncementsListQuery,
  IGetAnnouncementsListQueryVariables
>
export const DeleteAnnouncementsDocument = gql`
  mutation DeleteAnnouncements($ids: [ObjectId]!) {
    deleteManyAnnouncements(ids: $ids)
  }
`
export type IDeleteAnnouncementsMutationFn = Apollo.MutationFunction<
  IDeleteAnnouncementsMutation,
  IDeleteAnnouncementsMutationVariables
>

/**
 * __useDeleteAnnouncementsMutation__
 *
 * To run a mutation, you first call `useDeleteAnnouncementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnnouncementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnnouncementsMutation, { data, loading, error }] = useDeleteAnnouncementsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteAnnouncementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteAnnouncementsMutation,
    IDeleteAnnouncementsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteAnnouncementsMutation,
    IDeleteAnnouncementsMutationVariables
  >(DeleteAnnouncementsDocument, options)
}
export type DeleteAnnouncementsMutationHookResult = ReturnType<
  typeof useDeleteAnnouncementsMutation
>
export type DeleteAnnouncementsMutationResult =
  Apollo.MutationResult<IDeleteAnnouncementsMutation>
export type DeleteAnnouncementsMutationOptions = Apollo.BaseMutationOptions<
  IDeleteAnnouncementsMutation,
  IDeleteAnnouncementsMutationVariables
>
export const SaveAnnouncementsDocument = gql`
  mutation SaveAnnouncements(
    $announcementsWithAssignments: [AnnouncementWithAssignmentInput]!
  ) {
    saveManyAnnouncements(
      announcementsWithAssignments: $announcementsWithAssignments
    ) {
      ...AnnouncementFields
    }
  }
  ${AnnouncementFieldsFragmentDoc}
`
export type ISaveAnnouncementsMutationFn = Apollo.MutationFunction<
  ISaveAnnouncementsMutation,
  ISaveAnnouncementsMutationVariables
>

/**
 * __useSaveAnnouncementsMutation__
 *
 * To run a mutation, you first call `useSaveAnnouncementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAnnouncementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAnnouncementsMutation, { data, loading, error }] = useSaveAnnouncementsMutation({
 *   variables: {
 *      announcementsWithAssignments: // value for 'announcementsWithAssignments'
 *   },
 * });
 */
export function useSaveAnnouncementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveAnnouncementsMutation,
    ISaveAnnouncementsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveAnnouncementsMutation,
    ISaveAnnouncementsMutationVariables
  >(SaveAnnouncementsDocument, options)
}
export type SaveAnnouncementsMutationHookResult = ReturnType<
  typeof useSaveAnnouncementsMutation
>
export type SaveAnnouncementsMutationResult =
  Apollo.MutationResult<ISaveAnnouncementsMutation>
export type SaveAnnouncementsMutationOptions = Apollo.BaseMutationOptions<
  ISaveAnnouncementsMutation,
  ISaveAnnouncementsMutationVariables
>
export const ListenToAnnouncementsDocument = gql`
  subscription ListenToAnnouncements($projectId: ObjectId!) {
    announcement(projectId: $projectId) {
      item {
        ...AnnouncementFields
      }
      id
    }
  }
  ${AnnouncementFieldsFragmentDoc}
`

/**
 * __useListenToAnnouncementsSubscription__
 *
 * To run a query within a React component, call `useListenToAnnouncementsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToAnnouncementsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToAnnouncementsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToAnnouncementsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToAnnouncementsSubscription,
    IListenToAnnouncementsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToAnnouncementsSubscription,
    IListenToAnnouncementsSubscriptionVariables
  >(ListenToAnnouncementsDocument, options)
}
export type ListenToAnnouncementsSubscriptionHookResult = ReturnType<
  typeof useListenToAnnouncementsSubscription
>
export type ListenToAnnouncementsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToAnnouncementsSubscription>
