import * as React from 'react'

import { inject } from 'mobx-react'
import MagicUrl from 'quill-magic-url'
import ReactDOM from 'react-dom'
import ReactQuill, { Quill } from 'react-quill'

import FileType from '../../../enums/FileType'
import IFilePreviewProperties from '../../../interfaces/IFilePreviewProperties'
import EventsStore from '../../../stores/EventStore/Events.store'
import { SHOW_FULLSCREEN_PREVIEW } from '../../../stores/EventStore/eventConstants'
import QuillVideoParser from '../../../utils/QuillVideoParser'
import formatBytes from '../../../utils/formatBytes'
import VideoPlayer from '../../VideoPlayer/VideoPlayer'

import Colors from '~/client/src/shared/theme.module.scss'

import 'react-quill/dist/quill.snow.css'

import { IAttachment, UploadingType } from '~/client/graph'

import { FileUploadingStore } from '../../../stores/domain/FileUploading.store'

const Embed = Quill.import('blots/block/embed')
const Header = Quill.import('formats/header')
const Image = Quill.import('formats/image')
const Video = Quill.import('formats/video')

const addTextHere = 'Add text here'
const imageRegex = /\.(gif|jpe?g|tiff?|png|webp|bmp|jfif)$/i

const DIVIDER_BLOT_NAME = 'hr'
export const VIDEO_BLOT_NAME = 'video'
export const IMAGE_GALLERY_BLOT_NAME = 'gallery'

interface IProps {
  content: string
  isReadOnly?: boolean
  fileUploadingStore?: FileUploadingStore
  eventsStore?: EventsStore

  applyContent(content: string): void
  addAttachment(attachment: IAttachment): void
}

@inject('eventsStore', 'fileUploadingStore')
export default class AnnouncementTextEditor extends React.Component<IProps> {
  private modules = {
    toolbar: {
      container: '#toolbar',
      handlers: {
        image: this.imageHandler.bind(this),
        hr: this.hrHandler.bind(this),
      },
    },
    magicUrl: true,
    quillVideoParser: true,
    clipboard: {
      matchVisual: false,
    },
  }

  private formats = [
    'image',
    'link',
    'bold',
    'underline',
    'list',
    'bullet',
    DIVIDER_BLOT_NAME,
    'header',
    IMAGE_GALLERY_BLOT_NAME,
    'video',
  ]
  private quill: Quill

  public constructor(props: IProps) {
    super(props)
    Hr.blotName = DIVIDER_BLOT_NAME
    Hr.className = 'announcement-divider'
    Hr.tagName = 'hr'
    ImageGallery.blotName = IMAGE_GALLERY_BLOT_NAME
    ImageGallery.className = IMAGE_GALLERY_BLOT_NAME
    ImageGallery.tagName = 'div'
    VideoPreview.blotName = VIDEO_BLOT_NAME
    VideoPreview.className = VIDEO_BLOT_NAME
    VideoPreview.tagName = 'div'

    Quill.register({
      [`formats/${DIVIDER_BLOT_NAME}`]: Hr,
      'formats/header': AnnouncementHeader,
      'formats/image': AnnouncementImage,
      [`formats/${IMAGE_GALLERY_BLOT_NAME}`]: ImageGallery,
      'formats/video': VideoPreview,
      'modules/magicUrl': MagicUrl,
      'modules/quillVideoParser': QuillVideoParser,
    })
  }

  public render() {
    const { applyContent, content, isReadOnly } = this.props
    return (
      <ReactQuill
        value={content}
        onChange={applyContent}
        placeholder={addTextHere}
        modules={this.modules}
        formats={this.formats}
        bounds=".text-editor"
        ref={this.setQuillRef}
        readOnly={isReadOnly}
        className="announcement-text-editor"
      />
    )
  }

  private setQuillRef = (ref: ReactQuill) => {
    if (ref) {
      this.quill = ref.getEditor()
      this.quill.root.addEventListener('drop', this.handleDrop, false)
      this.quill.root.addEventListener('click', this.handleClick)
    }
  }

  private handleDrop = e => {
    if (e.dataTransfer?.files?.length) {
      e.preventDefault()
      const images = []

      // Set quill cursor to the pointer position.
      if (document.caretRangeFromPoint) {
        const selection = document.getSelection()
        const range = document.caretRangeFromPoint(e.clientX, e.clientY)
        if (selection && range) {
          selection.setBaseAndExtent(
            range.startContainer,
            range.startOffset,
            range.startContainer,
            range.startOffset,
          )
        }
      }

      Array.from(e.dataTransfer.files).forEach(async (file: File) => {
        if (file.type === 'application/pdf') {
          const [result] = await this.uploadFile(
            file as File,
            UploadingType.Pdf,
            file.name,
          )

          this.props.addAttachment({
            fileName: file.name,
            size: formatBytes(file.size),
            url: result.fileURL,
          })
          return
        }

        if (imageRegex.test(file.name)) {
          images.push(file)
        }
      })

      if (images.length === 1) {
        this.insertImage(images[0])
      } else if (images.length > 1) {
        this.insertImageGallery(images)
      }
    }
  }

  private handleClick = e => {
    if (e.target?.tagName?.toLowerCase() === 'img') {
      e.stopPropagation()
      const images = this.getAllImagesInEditor()
      const index = images.findIndex(
        im => im.fileUrl === e.target.getAttribute('src'),
      )

      this.props.eventsStore.dispatch(SHOW_FULLSCREEN_PREVIEW, images, index)
    }
  }

  private imageHandler() {
    const input = document.createElement('input')

    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = () => {
      this.insertImage(input.files[0])
    }
  }

  private async insertImage(file: File) {
    const [result] = await this.uploadFile(file, UploadingType.Image, file.name)

    const range = this.quill.getSelection(true)
    this.quill.insertEmbed(range.index, 'image', {
      src: result.fileURL,
      alt: file.name,
    })
    this.quill.insertText(range.index + 1, '\n')
    this.quill.setSelection(range.index + 2, range.length)
  }

  private async insertImageGallery(files: File[]) {
    const images = []
    for (const file of files) {
      const [result] = await this.uploadFile(
        file,
        UploadingType.Image,
        file.name,
      )
      images.push({ url: result.fileURL, name: file.name })
    }

    const range = this.quill.getSelection(true)
    this.quill.insertEmbed(range.index, IMAGE_GALLERY_BLOT_NAME, images)
    this.quill.insertText(range.index + 1, '\n')
    this.quill.setSelection(range.index + 2, range.length)
  }

  private hrHandler() {
    const range = this.quill.getSelection()
    if (range) {
      this.quill.insertEmbed(range.index, DIVIDER_BLOT_NAME, 'null')
      this.quill.setSelection(range.index + 2, range.length)
    }
  }

  private getAllImagesInEditor = (): IFilePreviewProperties[] => {
    const imageElements = this.quill.root.getElementsByTagName('img')
    return Array.from(imageElements).map(image => ({
      fileUrl: image.getAttribute('src'),
      fileName: image.getAttribute('alt'),
      fileType: FileType.Image,
    }))
  }

  private uploadFile(file: File, uploadingType: UploadingType, fileName) {
    return this.props.fileUploadingStore.uploadFile(
      file,
      uploadingType,
      fileName,
    )
  }
}

class Hr extends Embed {
  public static create() {
    const node = super.create()

    node.setAttribute(
      'style',
      'height: 0px; margin: 10px auto; width: 200px; line-height: 0;',
    )
    return node
  }
}

class ImageGallery extends Embed {
  public static create(images: any) {
    if (Array.isArray(images)) {
      const node = super.create()

      images.forEach(image => {
        const img = document.createElement('img')
        img.setAttribute('src', image.url)
        img.setAttribute('alt', image.name)
        img.setAttribute(
          'style',
          'padding: 4px; border-radius: 4px; max-height: 230px; cursor: pointer;',
        )
        node.appendChild(img)
      })

      node.setAttribute('style', 'margin: 22px 0;')
      node.contentEditable = false

      return node
    }

    // On redo images is the value from static value(domNode), that is already a created element.
    return images
  }

  // Needed to enable correct redo functionality.
  public static value(domNode: any) {
    return domNode
  }
}

class AnnouncementImage extends Image {
  public static create(image: any) {
    const node = super.create(image.src)

    node.setAttribute('alt', image.alt)
    node.setAttribute(
      'style',
      'max-height: 385px; margin-top: 23px; margin-bottom: 23px; ' +
        'object-fit: contain; border-radius: 2px; cursor: pointer;',
    )

    return node
  }

  public static value(domNode: any) {
    return domNode
  }
}

class AnnouncementHeader extends Header {
  public static create(headerValue: any) {
    const node = super.create(headerValue)

    if (headerValue === '0') {
      node.removeAttribute('style')
      return node
    }

    const headerToFontSizeMap = {
      '1': {
        fontSize: 32,
        marginTop: 35,
        marginBottom: 35,
      },
      '2': {
        fontSize: 24,
        marginTop: 35,
        marginBottom: 23,
      },
      '3': {
        fontSize: 20,
        marginTop: 23,
        marginBottom: 12,
      },
    }
    const headerStyle = headerToFontSizeMap[headerValue]

    node.setAttribute(
      'style',
      `color: ${Colors.error40}; ` +
        `font-size: ${headerStyle.fontSize}px; ` +
        `margin-top: ${headerStyle.marginTop}px; ` +
        `margin-bottom: ${headerStyle.marginBottom}px;`,
    )

    return node
  }
}

class VideoPreview extends Video {
  public static create(src) {
    const node = super.create()

    if (typeof src === 'object') {
      src = src.dataset.videoSrc
    }

    node.dataset.videoSrc = src
    ReactDOM.render(<VideoPlayer src={src} />, node)
    return node
  }

  public static value(domNode: any) {
    return domNode
  }
}
