import { NotificationType } from '~/client/graph'
import SideBarState from '~/client/src/desktop/enums/SideBarState'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import BaseNotification from '~/client/src/shared/models/Notification'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import NotificationsStore from '~/client/src/shared/stores/domain/Notifications.store'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'
import {
  isDeliveryType,
  isFlagType,
  isRFIType,
  isScheduleCommentType,
  isStatusUpdateType,
} from '~/client/src/shared/types/NotificationTypes'

export default class NotificationListStore {
  public constructor(
    private readonly notificationsStore: NotificationsStore,
    private readonly common: CommonStore,
    private readonly eventsStore: EventsStore,
    private readonly state: DesktopInitialState,
    private readonly activitiesStore: ActivitiesStore,
  ) {}

  public toggleNotification = (id: string): void => {
    const { selectedNotifications } = this.notificationsStore
    selectedNotifications.set(id, !selectedNotifications.get(id))
  }

  public openContentObject = (route: string): void => {
    this.common._displayView(route)
  }

  public viewNotification = (notification: BaseNotification): void => {
    const { displayedNotification, selectedNotifications } =
      this.notificationsStore

    selectedNotifications.clear()

    if (displayedNotification?.id !== notification.id) {
      selectedNotifications.set(notification.id, true)

      if (displayedNotification) {
        this.eventsStore.dispatch(
          e.MARK_NOTIFICATION_AS_READ,
          displayedNotification,
        )
      }

      this.notificationsStore.displayedNotification = notification
    } else {
      this.notificationsStore.displayedNotification = null
      this.state.displayedContentObjectId = null
      this.eventsStore.dispatch(e.MARK_NOTIFICATION_AS_READ, notification)
    }
  }

  public openActivity = (
    entityId: string,
    type: NotificationType,
    activityId: string,
  ): void => {
    const activity = this.activitiesStore.getByDbId(activityId)
    this.activitiesStore.select(activity?.code)
    this.state.displayedContentObjectId = entityId

    if (type) {
      switch (true) {
        case isFlagType(type):
          this.state.sideBarState = SideBarState.Flag
          break
        case isRFIType(type):
          this.state.sideBarState = SideBarState.Rfi
          break
        case isDeliveryType(type):
          this.state.sideBarState = SideBarState.DeliveryDetails
          break
        case isStatusUpdateType(type):
          this.state.sideBarState = SideBarState.StatusUpdateMessages
          break
        case isScheduleCommentType(type):
          this.state.sideBarState = SideBarState.ScheduleComment
          break
      }
    }
  }
}
