import { ISitePermitInspectionChange } from '~/client/graph'
import Guard from '~/client/src/shared/utils/Guard'

import { copyObjectDeep } from '../utils/util'
import IPermitFieldDto from './IPermitFieldDto'
import PermitField from './PermitField'
import PermitInspection from './PermitInspection'

export default class PermitInspectionChange {
  public static fromDto(dto: ISitePermitInspectionChange) {
    return new PermitInspectionChange(
      dto.id,
      dto.inspectionId,
      dto.projectId,
      dto.userId,
      dto.createdAt,
      dto.fields,
    )
  }

  public fields: PermitField[]

  public constructor(
    public id: string,
    public readonly inspectionId: string,
    public readonly projectId: string,
    public readonly userId: string,
    public readonly createdAt?: number,
    fields?: IPermitFieldDto[],
  ) {
    Guard.requireAll({ inspectionId, projectId, userId })

    this.fields = fields?.map(field => PermitField.fromDto(field)) || []
  }

  public toInspection(permitId: string): PermitInspection {
    const inspection = new PermitInspection(
      this.inspectionId,
      permitId,
      this.projectId,
      this.userId,
      this.createdAt,
      null,
      this.createdAt,
    )
    inspection.fields = this.fields?.map(f => copyObjectDeep(f)) || []

    return inspection
  }
}
