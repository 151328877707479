import BaseEventStore, { IEvent } from '../../BaseEvents.store'
import EventContext from '../../EventContext'
import EventTypes from '../../eventTypes'
import Flow from './Flow'
import FlowRule, { AllRule, AnyRule, OnRule } from './FlowRule'

export default class FlowProcessor {
  // To distinguish this from other classes with similar interface
  public key = 'flow'

  public process(store: BaseEventStore, flow: Flow): void {
    if (!store) {
      return
    }
    if (!flow) {
      return
    }
    const unsubscribe = store.addPostEventCallback(
      ({ event }: EventContext) => {
        this.handleEvent(store, event, flow, unsubscribe)
      },
    )
    this.startFlow(store, flow)
  }

  private handleEvent(
    store: BaseEventStore,
    event: IEvent,
    flow: Flow,
    unsubscribe: () => void,
  ) {
    flow.rules.forEach(rule => {
      const test = this.getPredicateForRule(rule)
      if (test(rule, event)) {
        this.terminateFlowIfNeeded(rule, unsubscribe)
        const [, ...eventArgs] = event
        const { dispatch, dispatchN } = rule
        const events = [dispatch, ...(dispatchN || [])].filter(evt => !!evt)

        events.forEach(evt =>
          this.handleDispatch(
            store,
            evt,
            rule.when === 'all' ? rule.successArgs : eventArgs,
          ),
        )
      }
    })
  }

  private startFlow(store: BaseEventStore, flow: Flow) {
    flow.startWith.forEach(([type, ...args]) => store.dispatch(type, ...args))
  }

  private terminateFlowIfNeeded(rule: FlowRule, unsubscribe: () => void) {
    if (rule.shouldTerminate || rule.when === 'all') {
      unsubscribe()
    }
  }

  private getPredicateForRule(
    rule: FlowRule,
  ): (rule: FlowRule, event: IEvent) => boolean {
    switch (rule.when) {
      case 'on':
        return ({ event }: OnRule, [type]: IEvent) => type === event
      case 'any':
        return ({ events }: AnyRule, [type]: IEvent) => events.includes(type)
      case 'all':
        return ({ events, successArgs }: AllRule, [type, ...args]: IEvent) => {
          const index = events.indexOf(type)
          if (index === -1) {
            return false
          }

          if (successArgs) {
            successArgs.push(args)
          }
          events.splice(index, 1)
          return !events.length
        }
    }
  }

  private handleDispatch(
    store: BaseEventStore,
    event: EventTypes[],
    eventArgs: any[],
  ) {
    const [type, ...args] = event
    store.dispatch(type, ...args, ...eventArgs)
  }
}
