import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IGetThreadListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetThreadListQuery = {
  threads?: Types.Maybe<{
    data: Array<Pick<Types.IThread, 'id' | 'activityObjectId' | 'projectId'>>
  }>
}

export type ISaveThreadMutationVariables = Types.Exact<{
  thread: Types.IThreadInput
}>

export type ISaveThreadMutation = {
  saveThread?: Types.Maybe<
    Pick<Types.IThread, 'id' | 'activityObjectId' | 'projectId'>
  >
}

export type IListenThreadSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenThreadSubscription = {
  thread?: Types.Maybe<
    Pick<Types.IThreadChangeEvent, 'id'> & {
      item?: Types.Maybe<
        Pick<Types.IThread, 'id' | 'activityObjectId' | 'projectId'>
      >
    }
  >
}

export type IGetContentObjectListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetContentObjectListQuery = {
  contentObjects?: Types.Maybe<{
    data: Array<
      Pick<
        Types.IContentObject,
        | 'activityObjectId'
        | 'author'
        | 'contentObjectType'
        | 'createdAt'
        | 'id'
        | 'projectId'
        | 'status'
        | 'threadId'
        | 'type'
        | 'updatedAt'
      >
    >
  }>
}

export type ISaveContentObjectsMutationVariables = Types.Exact<{
  cos:
    | Array<Types.Maybe<Types.IContentObjectInput>>
    | Types.Maybe<Types.IContentObjectInput>
}>

export type ISaveContentObjectsMutation = {
  saveManyContentObjects?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<
          Types.IContentObject,
          | 'activityObjectId'
          | 'author'
          | 'contentObjectType'
          | 'createdAt'
          | 'id'
          | 'projectId'
          | 'status'
          | 'threadId'
          | 'updatedAt'
          | 'type'
        >
      >
    >
  >
}

export type IListenToContentObjectSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToContentObjectSubscription = {
  contentObject?: Types.Maybe<
    Pick<Types.IContentObjectChangeEvent, 'id'> & {
      item?: Types.Maybe<
        Pick<
          Types.IContentObject,
          | 'activityObjectId'
          | 'author'
          | 'contentObjectType'
          | 'createdAt'
          | 'id'
          | 'projectId'
          | 'status'
          | 'threadId'
          | 'updatedAt'
          | 'type'
        >
      >
    }
  >
}

export const GetThreadListDocument = gql`
  query GetThreadList($projectId: ObjectId!) {
    threads(projectId: $projectId, limit: 1000000) {
      data {
        id
        activityObjectId
        projectId
      }
    }
  }
`

/**
 * __useGetThreadListQuery__
 *
 * To run a query within a React component, call `useGetThreadListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetThreadListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetThreadListQuery,
    IGetThreadListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetThreadListQuery, IGetThreadListQueryVariables>(
    GetThreadListDocument,
    options,
  )
}
export function useGetThreadListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetThreadListQuery,
    IGetThreadListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetThreadListQuery, IGetThreadListQueryVariables>(
    GetThreadListDocument,
    options,
  )
}
export type GetThreadListQueryHookResult = ReturnType<
  typeof useGetThreadListQuery
>
export type GetThreadListLazyQueryHookResult = ReturnType<
  typeof useGetThreadListLazyQuery
>
export type GetThreadListQueryResult = Apollo.QueryResult<
  IGetThreadListQuery,
  IGetThreadListQueryVariables
>
export const SaveThreadDocument = gql`
  mutation SaveThread($thread: ThreadInput!) {
    saveThread(thread: $thread) {
      id
      activityObjectId
      projectId
    }
  }
`
export type ISaveThreadMutationFn = Apollo.MutationFunction<
  ISaveThreadMutation,
  ISaveThreadMutationVariables
>

/**
 * __useSaveThreadMutation__
 *
 * To run a mutation, you first call `useSaveThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveThreadMutation, { data, loading, error }] = useSaveThreadMutation({
 *   variables: {
 *      thread: // value for 'thread'
 *   },
 * });
 */
export function useSaveThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveThreadMutation,
    ISaveThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISaveThreadMutation, ISaveThreadMutationVariables>(
    SaveThreadDocument,
    options,
  )
}
export type SaveThreadMutationHookResult = ReturnType<
  typeof useSaveThreadMutation
>
export type SaveThreadMutationResult =
  Apollo.MutationResult<ISaveThreadMutation>
export type SaveThreadMutationOptions = Apollo.BaseMutationOptions<
  ISaveThreadMutation,
  ISaveThreadMutationVariables
>
export const ListenThreadDocument = gql`
  subscription ListenThread($projectId: ObjectId!) {
    thread(projectId: $projectId) {
      id
      item {
        id
        activityObjectId
        projectId
      }
    }
  }
`

/**
 * __useListenThreadSubscription__
 *
 * To run a query within a React component, call `useListenThreadSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenThreadSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenThreadSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenThreadSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenThreadSubscription,
    IListenThreadSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenThreadSubscription,
    IListenThreadSubscriptionVariables
  >(ListenThreadDocument, options)
}
export type ListenThreadSubscriptionHookResult = ReturnType<
  typeof useListenThreadSubscription
>
export type ListenThreadSubscriptionResult =
  Apollo.SubscriptionResult<IListenThreadSubscription>
export const GetContentObjectListDocument = gql`
  query GetContentObjectList($projectId: ObjectId!) {
    contentObjects(limit: 1000000, projectId: $projectId) {
      data {
        activityObjectId
        author
        contentObjectType
        createdAt
        id
        projectId
        status
        threadId
        type
        updatedAt
      }
    }
  }
`

/**
 * __useGetContentObjectListQuery__
 *
 * To run a query within a React component, call `useGetContentObjectListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentObjectListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentObjectListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetContentObjectListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetContentObjectListQuery,
    IGetContentObjectListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetContentObjectListQuery,
    IGetContentObjectListQueryVariables
  >(GetContentObjectListDocument, options)
}
export function useGetContentObjectListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetContentObjectListQuery,
    IGetContentObjectListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetContentObjectListQuery,
    IGetContentObjectListQueryVariables
  >(GetContentObjectListDocument, options)
}
export type GetContentObjectListQueryHookResult = ReturnType<
  typeof useGetContentObjectListQuery
>
export type GetContentObjectListLazyQueryHookResult = ReturnType<
  typeof useGetContentObjectListLazyQuery
>
export type GetContentObjectListQueryResult = Apollo.QueryResult<
  IGetContentObjectListQuery,
  IGetContentObjectListQueryVariables
>
export const SaveContentObjectsDocument = gql`
  mutation SaveContentObjects($cos: [ContentObjectInput]!) {
    saveManyContentObjects(contentObjects: $cos) {
      activityObjectId
      author
      contentObjectType
      createdAt
      id
      projectId
      status
      threadId
      updatedAt
      type
    }
  }
`
export type ISaveContentObjectsMutationFn = Apollo.MutationFunction<
  ISaveContentObjectsMutation,
  ISaveContentObjectsMutationVariables
>

/**
 * __useSaveContentObjectsMutation__
 *
 * To run a mutation, you first call `useSaveContentObjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveContentObjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveContentObjectsMutation, { data, loading, error }] = useSaveContentObjectsMutation({
 *   variables: {
 *      cos: // value for 'cos'
 *   },
 * });
 */
export function useSaveContentObjectsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveContentObjectsMutation,
    ISaveContentObjectsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveContentObjectsMutation,
    ISaveContentObjectsMutationVariables
  >(SaveContentObjectsDocument, options)
}
export type SaveContentObjectsMutationHookResult = ReturnType<
  typeof useSaveContentObjectsMutation
>
export type SaveContentObjectsMutationResult =
  Apollo.MutationResult<ISaveContentObjectsMutation>
export type SaveContentObjectsMutationOptions = Apollo.BaseMutationOptions<
  ISaveContentObjectsMutation,
  ISaveContentObjectsMutationVariables
>
export const ListenToContentObjectDocument = gql`
  subscription ListenToContentObject($projectId: ObjectId!) {
    contentObject(projectId: $projectId) {
      id
      item {
        activityObjectId
        author
        contentObjectType
        createdAt
        id
        projectId
        status
        threadId
        updatedAt
        type
      }
    }
  }
`

/**
 * __useListenToContentObjectSubscription__
 *
 * To run a query within a React component, call `useListenToContentObjectSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToContentObjectSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToContentObjectSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToContentObjectSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToContentObjectSubscription,
    IListenToContentObjectSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToContentObjectSubscription,
    IListenToContentObjectSubscriptionVariables
  >(ListenToContentObjectDocument, options)
}
export type ListenToContentObjectSubscriptionHookResult = ReturnType<
  typeof useListenToContentObjectSubscription
>
export type ListenToContentObjectSubscriptionResult =
  Apollo.SubscriptionResult<IListenToContentObjectSubscription>
