// The JWT payload
// https://tools.ietf.org/html/rfc7519
interface IJwtPayload {
  // "iss" (Issuer) Claim
  iss: string
  // "sub" (Subject) Claim
  sub: string
  // "aud" (Audience) Claim
  aud: string
  // "iat" (Issued At) Claim
  iat: number
  // "exp" (Expiration Time) Claim
  exp: number
  email: string
}

export function getTokenPayload(token: string): IJwtPayload {
  if (!token) {
    return null
  }

  try {
    const payloadJson = window.atob(token.split('.')[1])
    const payload = JSON.parse(payloadJson)
    return payload
  } catch {
    return null
  }
}

export function isTokenAboutToExpire(token: string) {
  const payload = getTokenPayload(token)

  if (!payload) {
    return true
  }

  // firebase token has a 1h expire time, 5m is a reasonable time to update it
  // check if time is less than 5m (300s) to token expire date
  return Date.now() / 1000 > payload.exp - 300
}
