import * as React from 'react'

import { FileInput, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { FileUploadingStore } from '../../stores/domain/FileUploading.store'
import { Loader } from '../Loader'
import { ISharedProps } from '../StruxhubInputs/BaseStruxhubInput'
import ImageInputStore from './ImageInput.store'

const UPLOAD_ICON_SIZE = 18
const ACCEPT_PATTERN = 'image/*'

interface IProps extends ISharedProps {
  onChange: (newUrl: string) => void
  fileUploadingStore?: FileUploadingStore
}

@inject('fileUploadingStore')
@observer
export default class ImageInput extends React.Component<IProps> {
  private readonly store: ImageInputStore = null

  public constructor(props: IProps) {
    super(props)
    this.store = new ImageInputStore(props.onChange, props.fileUploadingStore)
  }

  public render() {
    if (this.store.isLoading) {
      return (
        <div className="pointer row x-center y-center ba-palette-brand-lighter no-outline brada4 pa8 text extra-large full-width h40">
          <Loader />
        </div>
      )
    }

    const { label, className } = this.props

    const textElement = (
      <div className="pointer row x-center y-center ba-palette-brand-lighter no-outline brada4 pa8 text extra-large full-width h40">
        <Icon
          className="text light"
          icon={IconNames.MEDIA}
          iconSize={UPLOAD_ICON_SIZE}
        />
        <span className="no-flex ml5 text light large">{label}</span>
      </div>
    )

    return (
      <FileInput
        disabled={false}
        className={classList([
          'attachment-upload-button full-height',
          className,
        ])}
        inputProps={{
          onChange: this.uploadImage,
          accept: ACCEPT_PATTERN,
          className: 'bare-file-input',
        }}
        text={textElement}
      />
    )
  }

  private uploadImage = async ({ target }: any) => {
    const fileToUpload: File = target.files[0]
    target.value = ''
    return this.store.change(fileToUpload)
  }
}
