import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'

import SitemapDeliveryAttributes from '~/client/src/shared/components/SitemapDeliveryAttributes/SitemapDeliveryAttributes'
import Sitemap from '~/client/src/shared/models/Sitemap'

import MapViewItemsSetupStore from '../stores/MapViewItemsSetup.store'
import SitemapsSetupStore from '../stores/SitemapsSetup.store'
import LocationAssociationModal from './dialogs/LocationAssociationModal'

interface IProps {
  mapViewItemsSetupStore: MapViewItemsSetupStore
  sitemapsSetupStore: SitemapsSetupStore
  shouldHideModal?: boolean
  sitemap?: Sitemap
  onToggle?: (event) => void
}

@observer
export default class SitemapAssociationControl extends React.Component<IProps> {
  public render() {
    const {
      sitemap,
      shouldHideModal,
      sitemapsSetupStore: { selectedSitemap, toggleAssignSitemapDialog },
      onToggle,
    } = this.props

    if (!sitemap && !selectedSitemap) {
      return <div />
    }

    return (
      <div className="row full-width">
        <div className="delivery-attributes-container no-grow">
          <SitemapDeliveryAttributes
            sitemap={sitemap || selectedSitemap}
            shouldRenderAsOverflowList={true}
            isSingleMode={true}
            addTagButtonRenderer={!shouldHideModal && this.renderAddTagButton}
            className="delivery-attributes overflow-hidden full-width"
            shouldHideCrossIcons={true}
          />
        </div>
        <Icon
          icon={IconNames.CARET_DOWN}
          className="no-grow pointer"
          onClick={onToggle || toggleAssignSitemapDialog}
        />
      </div>
    )
  }

  private renderAddTagButton = () => {
    const { sitemapsSetupStore, mapViewItemsSetupStore } = this.props
    const { isAssignSitemapDialogShown, hideAssignSitemapDialog } =
      sitemapsSetupStore

    return (
      <div className="inline-block">
        {isAssignSitemapDialogShown && (
          <LocationAssociationModal
            onClose={hideAssignSitemapDialog}
            mapViewItemsSetupStore={mapViewItemsSetupStore}
            sitemapsSetupStore={sitemapsSetupStore}
          />
        )}
      </div>
    )
  }
}
