import { action, observable } from 'mobx'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import ProjectDateStore, {
  DAYS_IN_WEEK,
} from '~/client/src/shared/stores/ui/ProjectDate.store'

// localization: translated

export enum TwoMonthsDatePickerMode {
  DEFAULT = 'Custom',
  ONE_DAY = 'Day',
  ONE_DAY_DEFAULT_TODAY = 'One Day Default Today',
  WEEK = 'Week',
  FIXED_WEEK = 'Fixed Week',
  FIXED_TWO_WEEKS = 'Fixed Two Weeks',
  FIXED_THREE_WEEKS = 'Fixed Three Weeks',
  FLOATING_THREE_WEEKS = 'Three Weeks',
  FOUR_WEEKS = 'Four Weeks',
  FIXED_FOUR_WEEKS = 'Fixed Four Weeks',
  MONTH = 'Month',
  FIXED_SIX_WEEKS = 'Fixed Six Weeks',
  SIX_WEEKS = 'Six Weeks',
  THREE_MONTHS = '3 Months',
  YEAR = 'Year',
  PROJECT = 'Project',
  CUSTOM = 'Other',
}

export const getTwoMonthsDatePickerModeDisplayName = (
  mode: TwoMonthsDatePickerMode,
) => {
  // TODO: The display name of some modes can now be confusing
  // It needs to be figured out if we need them all
  switch (mode) {
    case TwoMonthsDatePickerMode.DEFAULT:
      return Localization.translator.custom
    case TwoMonthsDatePickerMode.ONE_DAY_DEFAULT_TODAY:
      // return Localization.translator.oneDayDefaultToday
      return Localization.translator.oneDay
    case TwoMonthsDatePickerMode.ONE_DAY:
      return Localization.translator.oneDay
    case TwoMonthsDatePickerMode.WEEK:
      return Localization.translator.week
    case TwoMonthsDatePickerMode.FIXED_WEEK:
      // return Localization.translator.fixedWeek
      return Localization.translator.week
    case TwoMonthsDatePickerMode.FIXED_TWO_WEEKS:
      return Localization.translator.fixedTwoWeeks
    case TwoMonthsDatePickerMode.FIXED_THREE_WEEKS:
      return Localization.translator.fixedThreeWeeks
    case TwoMonthsDatePickerMode.FLOATING_THREE_WEEKS:
      return Localization.translator.threeWeeks
    case TwoMonthsDatePickerMode.FOUR_WEEKS:
      return Localization.translator.fourWeeks
    case TwoMonthsDatePickerMode.FIXED_FOUR_WEEKS:
      return Localization.translator.fixedFourWeeks
    case TwoMonthsDatePickerMode.MONTH:
      return Localization.translator.month
    case TwoMonthsDatePickerMode.FIXED_SIX_WEEKS:
      return Localization.translator.fixedSixWeeks
    case TwoMonthsDatePickerMode.SIX_WEEKS:
      return Localization.translator.sixWeeks
    case TwoMonthsDatePickerMode.THREE_MONTHS:
      return Localization.translator.threeMonth
    case TwoMonthsDatePickerMode.YEAR:
      return Localization.translator.year
    case TwoMonthsDatePickerMode.PROJECT:
      return Localization.translator.project
    case TwoMonthsDatePickerMode.CUSTOM:
      return Localization.translator.other
  }
}

const START_DATE_DEFAULT_HOUR = 6
const END_DATE_DEFAULT_HOUR = 18

export interface ITwoMonthsDatePickerOptions {
  titleTranslatorKey?: KnownTranslatorKeys
  handler?: (startDate: Date, endDate: Date) => void
  onCustomDateClick?: (date: Date) => { startDate: Date; endDate: Date }
  initialRange?: { startDate: Date; endDate: Date }
  isSeparatedMonths?: boolean
  shouldHideCalendar?: boolean
  isTimeSensitive?: boolean
}

export default class TwoMonthsDatePickerStore {
  @observable public shouldHideCalendar: boolean = false
  @observable public isShown: boolean = false
  @observable public titleTranslatorKey: KnownTranslatorKeys
  @observable public startDate: Date = new Date()
  @observable public endDate: Date = new Date()
  @observable public isSeparatedMonths: boolean
  @observable public isTimeSensitive: boolean
  @observable public daysToAdd: number
  @observable
  public mode: TwoMonthsDatePickerMode = TwoMonthsDatePickerMode.DEFAULT

  protected onApplyHandler: (startDate: Date, endDate: Date) => void
  protected onCustomDateClick: (date: Date) => {
    startDate: Date
    endDate: Date
  }
  @observable private firstMonth: Date = new Date()
  @observable private secondMonth: Date = new Date()

  public get currentMode() {
    return this.mode
  }

  public constructor(
    private readonly projectDateStore: ProjectDateStore,
    private readonly onShowChanged?: (isShown: boolean) => void,
  ) {}

  public setMode(mode: TwoMonthsDatePickerMode) {
    let startDateToSet: Date = null

    if (this.currentMode === TwoMonthsDatePickerMode.PROJECT) {
      startDateToSet = new Date()
    }

    this.mode = mode
    this.setDates(startDateToSet)
  }

  @action.bound
  public show() {
    this.isShown = true
    if (this.onShowChanged) {
      this.onShowChanged(this.isShown)
    }
  }

  @action.bound
  public hide() {
    this.shouldHideCalendar = false
    this.isShown = false
    if (this.onShowChanged) {
      this.onShowChanged(this.isShown)
    }
  }

  @action.bound
  public switchFirstMonthToPrev() {
    this.firstMonth = this.projectDateStore.addMonths(this.firstMonth, -1)
  }

  @action.bound
  public switchFirstMonthToNext() {
    this.firstMonth = this.projectDateStore.addMonths(this.firstMonth, 1)
  }

  @action.bound
  public switchSecondMonthToPrev() {
    this.secondMonth = this.projectDateStore.addMonths(this.secondMonth, -1)
  }

  @action.bound
  public switchSecondMonthToNext() {
    this.secondMonth = this.projectDateStore.addMonths(this.secondMonth, 1)
  }

  @action.bound
  public switchToCurrentMonth() {
    this.firstMonth = new Date()
  }

  public get displayedMonths() {
    if (this.isSeparatedMonths) {
      return [this.firstMonth, this.secondMonth]
    }
    return [
      this.firstMonth,
      this.projectDateStore.addMonths(this.firstMonth, 1),
    ]
  }

  public showWithOptions(options: ITwoMonthsDatePickerOptions) {
    const {
      titleTranslatorKey,
      handler,
      initialRange,
      onCustomDateClick,
      isSeparatedMonths,
      shouldHideCalendar,
      isTimeSensitive,
    } = options
    this.titleTranslatorKey = titleTranslatorKey
    this.onApplyHandler = handler || null
    this.startDate = (initialRange && initialRange.startDate) || new Date()
    this.isTimeSensitive = isTimeSensitive || false
    this.setEndDate(initialRange && initialRange.endDate)
    this.onCustomDateClick = onCustomDateClick || null
    this.isSeparatedMonths = isSeparatedMonths
    this.shouldHideCalendar = shouldHideCalendar || false

    this.show()
  }

  public get isDateRangeChosen() {
    return this.startDate && this.endDate
  }

  @action.bound
  public setDates(date?: Date) {
    switch (this.mode) {
      case TwoMonthsDatePickerMode.DEFAULT:
        if (date) {
          this.defaultModeDateClick(date)
        }
        return
      case TwoMonthsDatePickerMode.ONE_DAY_DEFAULT_TODAY:
        this.oneDayTodayModeDateClick(date)
        return
      case TwoMonthsDatePickerMode.ONE_DAY:
        this.oneDayModeDateClick(date)
        return
      case TwoMonthsDatePickerMode.WEEK:
        this.weekModeDateClick(date)
        return
      case TwoMonthsDatePickerMode.FIXED_TWO_WEEKS:
        this.setXWeeksModeDateClick(2, date)
        return
      case TwoMonthsDatePickerMode.FIXED_WEEK:
        this.setXWeeksModeDateClick(1, date)
        return
      case TwoMonthsDatePickerMode.FIXED_SIX_WEEKS:
        this.setXWeeksModeDateClick(6, date)
        return
      case TwoMonthsDatePickerMode.FIXED_THREE_WEEKS:
        this.setXWeeksModeDateClick(3, date)
        return
      case TwoMonthsDatePickerMode.FLOATING_THREE_WEEKS:
        this.floatingThreeWeeksModeDateClick(date)
        return
      case TwoMonthsDatePickerMode.FIXED_FOUR_WEEKS:
        this.setXWeeksModeDateClick(4, date)
        return
      case TwoMonthsDatePickerMode.FOUR_WEEKS:
        this.fourWeeksModeDateClick(date)
        return
      case TwoMonthsDatePickerMode.MONTH:
        this.monthModeDateClick(date)
        return
      case TwoMonthsDatePickerMode.SIX_WEEKS:
        this.sixWeeksModeDateClick(date)
        return
      case TwoMonthsDatePickerMode.THREE_MONTHS:
        this.threeMonthsModeDateClick(date)
        return
      case TwoMonthsDatePickerMode.YEAR:
        this.yearModeDateClick(date)
        return
      case TwoMonthsDatePickerMode.PROJECT:
        this.projectModeDateClick()
        return
      case TwoMonthsDatePickerMode.CUSTOM:
        this.customModeDateClick(date)
        return
    }
  }

  @action.bound
  public onDateClick(event: Event, date: Date) {
    this.setDates(date)
  }

  @action.bound
  public onApplyClick() {
    this.applySelectedDates()
    this.hide()
  }

  public applySelectedDates() {
    if (this.onApplyHandler) {
      this.onApplyHandler(this.startDate, this.endDate)
    }
  }

  protected setEndDate(date: Date) {
    if (!date) {
      this.endDate = null
      return
    }
    if (!this.isTimeSensitive) {
      this.endDate = this.projectDateStore.endOfDay(date)
    } else {
      this.endDate = new Date(date)
    }
  }

  @action.bound
  private defaultModeDateClick(dateToSet?: Date) {
    const date = dateToSet || this.startDate
    if (this.isDateRangeChosen) {
      this.startDate = null
      this.setEndDate(null)
    }

    const { setHours } = this.projectDateStore
    if (!this.startDate) {
      this.startDate = setHours(date, START_DATE_DEFAULT_HOUR, 0)
      return
    }

    this.endDate = new Date(Math.max(this.startDate.getTime(), date.getTime()))
    this.startDate = new Date(
      Math.min(this.startDate.getTime(), date.getTime()),
    )

    this.startDate = setHours(this.startDate, START_DATE_DEFAULT_HOUR, 0)
    this.endDate = setHours(this.endDate, END_DATE_DEFAULT_HOUR, 0)
  }

  @action.bound
  private setXWeeksModeDateClick(weeksToAdd: number, dateToSet?: Date) {
    const date = dateToSet || this.startDate
    this.startDate = this.projectDateStore.startOfWeek(date)
    this.setEndDate(
      this.projectDateStore.addDays(
        this.projectDateStore.addWeeks(this.startDate, weeksToAdd),
        -1,
      ),
    )
    this.daysToAdd = weeksToAdd * DAYS_IN_WEEK - 1
  }

  @action.bound
  private floatingThreeWeeksModeDateClick(dateToSet?: Date) {
    const date = dateToSet || this.endDate
    this.setEndDate(date)
    this.daysToAdd = 3 * DAYS_IN_WEEK - 1
    this.startDate = this.projectDateStore.startOfDay(
      this.projectDateStore.addDays(this.endDate, -this.daysToAdd),
    )
  }

  @action.bound
  private monthModeDateClick(dateToSet?: Date) {
    const date = dateToSet || this.endDate
    this.setEndDate(date)
    const { getDaysInMonth } = this.projectDateStore
    this.daysToAdd = getDaysInMonth(date) - 1
    this.startDate = this.projectDateStore.startOfDay(
      this.projectDateStore.addDays(this.endDate, -this.daysToAdd),
    )
  }

  @action.bound
  private fourWeeksModeDateClick(dateToSet?: Date) {
    const date = dateToSet || this.endDate
    this.startDate = this.projectDateStore.startOfWeek(date)
    this.setEndDate(
      this.projectDateStore.addDays(
        this.projectDateStore.addWeeks(this.startDate, 4),
        -1,
      ),
    )
    this.daysToAdd = 4 * DAYS_IN_WEEK - 1
  }

  @action.bound
  private threeMonthsModeDateClick(dateToSet?: Date) {
    const date = dateToSet || this.endDate
    this.setEndDate(date)
    const { getDaysInMonth } = this.projectDateStore
    this.daysToAdd = getDaysInMonth(date) * 3 - 1
    this.startDate = this.projectDateStore.startOfDay(
      this.projectDateStore.addDays(this.endDate, -this.daysToAdd),
    )
  }

  @action.bound
  private yearModeDateClick(dateToSet?: Date) {
    const date = dateToSet || this.endDate
    this.setEndDate(date)
    const { getDaysInYear } = this.projectDateStore
    this.daysToAdd = getDaysInYear(date) - 1
    this.startDate = this.projectDateStore.startOfDay(
      this.projectDateStore.addDays(this.endDate, -this.daysToAdd),
    )
  }

  @action.bound
  private sixWeeksModeDateClick(dateToSet?: Date) {
    const date = dateToSet || this.endDate
    this.setEndDate(date)
    this.daysToAdd = 6 * DAYS_IN_WEEK - 1
    this.startDate = this.projectDateStore.startOfDay(
      this.projectDateStore.addDays(this.endDate, -this.daysToAdd),
    )
  }

  @action.bound
  private projectModeDateClick() {
    this.daysToAdd =
      this.projectDateStore.countDaysToDate(this.startDate, this.endDate) - 1
  }

  @action.bound
  private weekModeDateClick(dateToSet?: Date) {
    const date = dateToSet || this.endDate
    this.setEndDate(date)
    this.daysToAdd = DAYS_IN_WEEK - 1
    this.startDate = this.projectDateStore.startOfDay(
      this.projectDateStore.addDays(this.endDate, -this.daysToAdd),
    )
  }

  @action.bound
  private oneDayModeDateClick(dateToSet?: Date) {
    const date = dateToSet || this.endDate
    this.startDate = this.projectDateStore.startOfDay(date)
    this.setEndDate(date)
    this.daysToAdd = 0
  }

  @action.bound
  private oneDayTodayModeDateClick(dateToSet?: Date) {
    const date = dateToSet || new Date()
    this.startDate = this.projectDateStore.startOfDay(date)
    this.setEndDate(date)
    this.daysToAdd = 0
  }

  @action.bound
  private customModeDateClick(dateToSet?: Date) {
    const date = dateToSet || this.startDate
    if (!this.onCustomDateClick) {
      return
    }
    const { startDate, endDate } = this.onCustomDateClick(date)
    this.startDate = startDate
    this.setEndDate(endDate)
  }
}
