import * as React from 'react'

import { action } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ProjectScheduleUploadStore from '../../ProjectScheduleUpload.store'

export interface IProps {
  fileColumns: string[]
  fieldName: string
  fieldDescription: string
  isRequired?: boolean
  store: ProjectScheduleUploadStore
}

@observer
export default class SheetColumn extends React.Component<IProps> {
  public render() {
    const {
      store: { columnMap },
      fileColumns,
      fieldName,
      fieldDescription,
      isRequired,
    } = this.props

    return (
      <div className="row mb5 pointer">
        <div
          className={classList({
            'row y-center no-flex-children text large': true,
            bold: !!isRequired,
          })}
        >
          {`${fieldDescription} (${fieldName})${isRequired ? '*' : ''}: `}
        </div>
        <div className="text large light">
          <select
            value={columnMap[fieldName] || ''}
            onChange={this.onSelectChanged}
          >
            <option value="">-</option>
            {fileColumns.map(f => (
              <option key={f} value={f}>
                {f}
              </option>
            ))}
          </select>
        </div>
      </div>
    )
  }

  @action.bound
  private onSelectChanged(e: React.ChangeEvent<HTMLSelectElement>) {
    const { store: projectScheduleUploadStore, fieldName } = this.props
    projectScheduleUploadStore.addColumnMap(fieldName, e.target.value)
  }
}
