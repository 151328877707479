import * as React from 'react'

import { observer } from 'mobx-react'
import { Document, Page } from 'react-pdf'

import { Loader } from '../Loader'

interface IProps {
  file: string | File

  className?: string
  loaderSize?: number
  onLoadSuccess?(pdf: any): void

  pageNumber?: number
  pageClassName?: string
  isPageHidden?: boolean
  isTextSelectable?: boolean
  scale?: number
  pageWidth?: number
  pageHeight?: number
  onPageLoad?(page: any): void
  onPageRenderSuccess?(): void
  setPageInputRef?(ref: HTMLDivElement): void
}

const DEFAULT_LOADER_SIZE = 25

@observer
export default class PdfDocument extends React.Component<IProps> {
  public static defaultProps = {
    isTextSelectable: false,
  }

  public render() {
    const {
      file,
      pageNumber,
      scale,
      pageWidth,
      pageHeight,
      className,
      pageClassName,
      isTextSelectable,
      isPageHidden,
      onLoadSuccess,
      onPageLoad,
      onPageRenderSuccess,
      setPageInputRef,
      children,
    } = this.props

    return (
      <Document
        file={file}
        className={className}
        loading={this.loader}
        onLoadSuccess={onLoadSuccess}
        options={{
          cMapUrl: 'cmaps/',
          cMapPacked: true,
        }}
      >
        {!isPageHidden && (
          <Page
            className={pageClassName}
            inputRef={setPageInputRef}
            loading={this.loader}
            onLoadSuccess={onPageLoad}
            onRenderSuccess={onPageRenderSuccess}
            pageNumber={pageNumber || 1}
            scale={scale}
            width={pageWidth}
            height={pageHeight}
            renderAnnotationLayer={isTextSelectable}
            renderTextLayer={isTextSelectable}
          />
        )}
        {children}
      </Document>
    )
  }

  private get loader(): JSX.Element {
    return (
      <div className="pdf-page-loader relative row x-center y-center full-height full-width pa30">
        <Loader size={this.props.loaderSize || DEFAULT_LOADER_SIZE} />
      </div>
    )
  }
}
