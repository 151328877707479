import { CategoryName } from '~/client/graph'
import { enumToList } from '~/client/src/shared/utils/converters'

export const categoryNamesList = enumToList(CategoryName)

const OR_CATEGORIES1 = [CategoryName.UserAssociated]
const OR_CATEGORIES2 = [
  ...categoryNamesList.filter(category => !OR_CATEGORIES1.includes(category)),
]

export const AND_CATEGORIES = [OR_CATEGORIES1, OR_CATEGORIES2]
