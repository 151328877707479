export enum ActivitiesTreeNodeTypes {
  ACTIVITY = 'ACTIVITY',
  NEXT_ACTIVITY_CONTAINER = 'NEXT_ACTIVITY_CONTAINER',
  COMPLETED_ACTIVITY_CONTAINER = 'COMPLETED_ACTIVITY_CONTAINER',
  COMPANY_CONTAINER = 'COMPANY_CONTAINER',
  SHOW_MORE_CONTROL = 'SHOW_MORE_CONTROL',
  ZONE_CONTAINER = 'ZONE_CONTAINER',
  LEVEL_CONTAINER = 'LEVEL_CONTAINER',
  ACTIVITY_CODE_CONTAINER = 'ACTIVITY_CODE_CONTAINER',
  INFO = 'INFO',
  LOADING = 'LOADING',
}
