import * as React from 'react'

interface IProps {
  paddingY: number
  onClick?: () => void
  className?: string
  style?: any
  children: any
}

export default function CellWrapper(props: IProps) {
  const {
    paddingY,
    children,
    style = {},
    className = 'col full-height px12',
    onClick,
  } = props

  return (
    <div
      style={{ ...style, paddingTop: paddingY, paddingBottom: paddingY }}
      className={className}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
