export enum FormFieldType {
  Tags = 'tags',
  Tag = 'tag',
  TextAsTag = 'textAsTag',
  Text = 'text',
  Phone = 'phone',
  Checkbox = 'checkbox',
  SelectOne = 'select-one',
  File = 'file',
  Avatar = 'avatar',
  Image = 'image',
  Email = 'email',
  Number = 'number',
  Switch = 'switch',
  Date = 'date',
}
