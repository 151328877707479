import * as React from 'react'

import { inject, observer } from 'mobx-react'

import FilterHandleButton from '~/client/src/shared/components/Filters/components/FilterHandleButton/FilterHandleButton'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import SuperFilterStore from '~/client/src/shared/stores/ui/SuperFilter.store'

interface IBaseFilter {
  filterStore: SuperFilterStore
  eventsStore?: EventsStore
  className?: string
  captionClassName?: string
  onToggle?: () => void
}

@inject('eventsStore')
@observer
export default class SuperFilterHandleButton extends React.Component<IBaseFilter> {
  public static defaultProps = {
    className: '',
  }

  private readonly clearPostEventCallback: () => void

  public constructor(props: IBaseFilter) {
    super(props)

    if (!this.store.filter.isInitialized) {
      this.store.setInitialFilterValues()
      this.store.filter.isInitialized = true
    }

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.store.onFilterActionRequest,
    )
  }

  public componentWillUnmount() {
    if (this.clearPostEventCallback) {
      this.clearPostEventCallback()
    }
  }

  public onToggle = () => {
    const handler = this.props.onToggle || this.store.toggle
    handler()
  }

  public render() {
    const { className, children, captionClassName } = this.props
    const { isShown, isActive, handleButtonCaption } = this.store

    return (
      <div className={`filter-wrapper ${className}`}>
        <FilterHandleButton
          isSelected={isShown}
          isActive={isActive}
          onClick={this.onToggle}
        >
          {children || (
            <span className={captionClassName}>{handleButtonCaption}</span>
          )}
        </FilterHandleButton>
      </div>
    )
  }

  private get store(): SuperFilterStore {
    return this.props.filterStore
  }
}
