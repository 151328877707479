import { IResource } from '~/client/graph'
import BaseModel from '~/client/src/shared/models/BaseModel'
import Guard from '~/client/src/shared/utils/Guard'

export default class Resource
  extends BaseModel<IResource>
  implements IResource
{
  public static fromDto(dto: IResource) {
    return Object.assign(new Resource(dto.id), dto)
  }

  public name: string
  public parentId: string
  public guid: string
  public shortName: string
  public scheduleId: string

  public constructor(public readonly id: string) {
    super(id)

    Guard.requireAll({ id })
  }

  public get asJson(): IResource {
    return {
      guid: this.guid,
      name: this.name,
      shortName: this.shortName,
      id: this.id || null,
      scheduleId: this.scheduleId,
    }
  }
}
