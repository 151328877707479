import {
  DeleteManyActivityAssignmentsDocument,
  SaveManyActivityAssignmentsDocument,
} from '~/client/graph/operations/generated/ActivityAssignments.generated'

import BaseAssignmentsStore from '../BaseAssignments.store'
import EventsStore from '../EventStore/Events.store'
import TagsStore from './Tags.store'

export default class ActivityAssignmentsStore extends BaseAssignmentsStore {
  public constructor(eventsStore: EventsStore, tagsStore: TagsStore) {
    super(
      eventsStore,
      tagsStore,
      SaveManyActivityAssignmentsDocument,
      DeleteManyActivityAssignmentsDocument,
    )
  }

  public get byId() {
    return this.eventsStore.appState.activityAssignments
  }
}
