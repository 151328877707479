import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import TwoMonthsDatePicker from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker'
import TwoMonthsDatePickerStore from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import ProjectDateStore, {
  isWithinRange,
} from '~/client/src/shared/stores/ui/ProjectDate.store'

import './GeneralDatePicker.scss'

// localization: translated

interface ITwoMonthsDatePicker {
  projectsStore?: ProjectsStore
  datePickerStore: TwoMonthsDatePickerStore
  projectDateStore?: ProjectDateStore
  shouldEnableFullProjectMode?: boolean
  shouldUseOtherDatesForFullProjectMode?: boolean
  shouldHideTopButtons?: boolean
}

@inject('projectsStore', 'projectDateStore')
@observer
export default class GeneralDatePicker extends React.Component<ITwoMonthsDatePicker> {
  private get store() {
    return this.props.datePickerStore
  }

  @action.bound
  public clickOnToday() {
    const today = new Date()
    this.store.switchToCurrentMonth()
    this.store.setDates(today)
  }

  @action.bound
  public clickOnFullProject() {
    const {
      shouldUseOtherDatesForFullProjectMode,
      projectsStore: { currentProjectStartDate, currentProjectEndDate },
      shouldEnableFullProjectMode,
      projectDateStore,
    } = this.props

    if (shouldEnableFullProjectMode) {
      this.store.startDate = shouldUseOtherDatesForFullProjectMode
        ? projectDateStore.getMinAvailableDate()
        : currentProjectStartDate
      this.store.endDate = shouldUseOtherDatesForFullProjectMode
        ? projectDateStore.getMaxAvailableDate()
        : currentProjectEndDate
    }
  }

  public topButtons() {
    const { startDate } = this.store
    const endDate = this.store.endDate || startDate

    const { currentProjectStartDate, currentProjectEndDate } =
      this.props.projectsStore

    const { isSameDay, getMinAvailableDate, getMaxAvailableDate } =
      this.props.projectDateStore
    const isFullProjectActive =
      isSameDay(startDate, currentProjectStartDate) &&
      isSameDay(endDate, currentProjectEndDate)
    const isFullPeriod =
      isSameDay(startDate, getMinAvailableDate()) &&
      isSameDay(endDate, getMaxAvailableDate())

    const today = new Date()
    const isCurrentActive =
      !isFullProjectActive &&
      !isFullPeriod &&
      isWithinRange(today, startDate, endDate)

    return (
      <div className="gantt-date-picker-top-btn-container">
        <div
          className={classList({
            'gantt-date-picker-top-btn': true,
            active: isCurrentActive,
          })}
          onClick={this.clickOnToday}
        >
          {Localization.translator.currentLookahead}
          <span className="gantt-date-picker-btn-icon" />
        </div>
        <div
          className={classList({
            'gantt-date-picker-top-btn': true,
            active: isFullProjectActive || isFullPeriod,
            disabled: !this.props.shouldEnableFullProjectMode,
          })}
          onClick={this.clickOnFullProject}
        >
          {Localization.translator.fullProject}
          <span className="gantt-date-picker-top-btn-icon" />
        </div>
      </div>
    )
  }

  public render() {
    const { shouldHideTopButtons } = this.props
    return (
      <TwoMonthsDatePicker store={this.store} className="gantt-date-picker">
        {!shouldHideTopButtons && this.topButtons()}
      </TwoMonthsDatePicker>
    )
  }
}
