import { action, observable } from 'mobx'

import { IAnalyticSettingsField, IAnalyticsSettings } from '~/client/graph'

import AnalyticsReportsType, {
  analyticsReportsTypeLabel,
} from '../../../shared/enums/AnalyticReportsType'
import EventsStore from '../../../shared/stores/EventStore/Events.store'
import { SAVE_ANALYTICS_SETTING } from '../../../shared/stores/EventStore/eventConstants'

export default class AnalyticsSettingsStore {
  @observable private settings: IAnalyticsSettings

  @observable public isDataReceived: boolean = false

  public constructor(private readonly eventsStore: EventsStore) {}

  public get mySettingFields() {
    return this.settings?.fields || []
  }

  private get defaultSettings(): IAnalyticsSettings {
    return {
      projectId: this.eventsStore.appState.activeProject.id,
      fields: [
        {
          type: AnalyticsReportsType.DELIVERY_REPORT,
          name: analyticsReportsTypeLabel[AnalyticsReportsType.DELIVERY_REPORT],
          isEnabled: true,
        },
      ],
    }
  }

  @action.bound
  public receiveData(settings: IAnalyticsSettings) {
    this.settings = settings || this.defaultSettings
    this.isDataReceived = true
  }

  @action.bound
  public clearData() {
    this.settings = null
    this.isDataReceived = false
  }

  public updateSetting = (fields: IAnalyticSettingsField[]) => {
    this.eventsStore.dispatch(
      SAVE_ANALYTICS_SETTING,
      {
        ...this.settings,
        fields,
      },
      this.receiveData,
    )
  }
}
