import * as React from 'react'

import CompactConfirmDialog from '../CompactConfirmDialog/CompactConfirmDialog'

interface IProps {
  isShown: boolean
  onHide: () => void
  onApply: () => void
  shouldBlockOnNonWorkTimes: boolean
  entityName: string

  title?: string
  message?: string
}

const changeDateTimeToWorkday = 'Change date-time to a workday'
const entityForNonWorkDayTime = (entityName: string) =>
  `${entityName} for non-work day-time`
const ok = 'OK'
const requestAnyway = 'Request anyway'
const pleaseMoveThisRequest = 'Please move this Request to a work day-time'
const thisRequestIsForNonWorkdayOrTime =
  'This Request is for a non-workday or time'

export default class NonWorkTimeConfirmDialog extends React.Component<IProps> {
  public render() {
    const { isShown, onHide, onApply } = this.props

    return (
      <CompactConfirmDialog
        title={this.dialogTitle}
        isShown={isShown}
        onHide={onHide}
        onApply={onApply}
        applyButtonText={this.dialogApplyButtonText}
        canOutsideClickClose={false}
      >
        <>
          <div className="text extra-large pb5">{this.dialogMessage}</div>
        </>
      </CompactConfirmDialog>
    )
  }

  private get dialogTitle(): string {
    const { shouldBlockOnNonWorkTimes, entityName, title } = this.props

    if (title) {
      return title
    }

    return shouldBlockOnNonWorkTimes
      ? changeDateTimeToWorkday
      : entityForNonWorkDayTime(entityName)
  }

  private get dialogMessage(): string {
    const { shouldBlockOnNonWorkTimes, message } = this.props

    if (message) {
      return message
    }

    return shouldBlockOnNonWorkTimes
      ? pleaseMoveThisRequest
      : thisRequestIsForNonWorkdayOrTime
  }

  private get dialogApplyButtonText(): string {
    return this.props.shouldBlockOnNonWorkTimes ? ok : requestAnyway
  }
}
