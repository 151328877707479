import React from 'react'

import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import './CalendarTimeline.scss'

interface IProps {
  shouldRenderDot?: boolean
  projectDateStore?: ProjectDateStore
}

const SECONDS_IN_MIN = 60
const SECONDS_IN_DAY = 86400

@inject('projectDateStore')
@observer
export default class CalendarTimeline extends React.Component<IProps> {
  private intervalId: number

  @observable private topOffset: string
  @observable private currentTime: number = 0

  public constructor(props: IProps) {
    super(props)
    this.setCurrentTime()
  }

  public componentDidMount() {
    this.intervalId = window.setInterval(() => {
      this.setCurrentTime()
    }, SECONDS_IN_MIN * 1000)
  }

  public componentWillUnmount() {
    window.clearInterval(this.intervalId)
  }

  public render() {
    const { shouldRenderDot } = this.props
    return (
      <div
        style={{ top: this.topOffset }}
        className="full-width absolute timeline-container"
      >
        {shouldRenderDot && <div className="absolute timeline-dot brada10" />}
        <div className="absolute full-width timeline" />
      </div>
    )
  }

  private setTop = () => {
    this.topOffset = `${((this.currentTime * 100) / SECONDS_IN_DAY).toFixed(
      2,
    )}%`
  }

  private setCurrentTime = () => {
    this.currentTime = this.props.projectDateStore.getDaySeconds(new Date())
    this.setTop()
  }
}
