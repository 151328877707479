import * as React from 'react'

import Konva from 'konva'
import { observer } from 'mobx-react'
import { Path } from 'react-konva'

import ThemeMode from '../../utils/ThemeModeManager'
import KonvaWorkflowBasePin from './KonvaWorkflowBasePin'

import Colors from '~/client/src/shared/theme.module.scss'

interface IProps {
  text: string
  isDone: boolean
  isAssigned: boolean
  isSelected: boolean
  isCanceled: boolean
  shouldRenderCircle: boolean
  x: number
  y: number
  iconData: string
  iconPositionX: number
  iconPositionY: number
  iconScale?: number
  strokeWidth?: number

  onClick: () => void
  onTouchEnd: () => void
}

@observer
export default class KonvaWorkflowCommonMapPin extends React.Component<
  IProps & Konva.NodeConfig
> {
  public static defaultProps = {
    iconScale: 1,
    strokeWidth: 0,
  }

  public render() {
    const {
      shouldRenderCircle,
      text,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isDone,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isAssigned,
      isSelected,
      x,
      y,
      iconData,
      iconPositionX,
      iconPositionY,
      iconScale,
      strokeWidth,
      onClick,
      onTouchEnd,
      ...rest
    } = this.props

    return (
      <KonvaWorkflowBasePin
        {...rest}
        color={ThemeMode.getHEXColor(this.pinFillColor)}
        text={text}
        textColor={this.textAndIconColor}
        isSelected={isSelected}
        shouldRenderCircle={shouldRenderCircle}
        x={x}
        y={y}
        onClick={onClick}
        onTouchEnd={onTouchEnd}
      >
        <Path
          data={iconData}
          fill={this.textAndIconColor}
          stroke={strokeWidth ? this.textAndIconColor : null}
          strokeWidth={strokeWidth}
          x={iconPositionX}
          y={iconPositionY}
          scaleX={iconScale}
          scaleY={iconScale}
        />
      </KonvaWorkflowBasePin>
    )
  }

  private get pinFillColor(): string {
    const { isDone, isAssigned, isCanceled } = this.props

    if (isDone) {
      return Colors.neutral70
    }

    if (isCanceled) {
      return Colors.neutral50_4
    }

    if (isAssigned) {
      return Colors.warning70
    }

    return Colors.neutral0
  }

  private get textAndIconColor(): string {
    const { isAssigned, isSelected, isDone, isCanceled } = this.props

    return ThemeMode.getHEXColor(
      isAssigned && !isSelected && !isDone && !isCanceled
        ? Colors.neutral0
        : Colors.neutral100,
    )
  }
}
