import * as React from 'react'

import { inject, observer } from 'mobx-react'

import Text from '~/client/src/shared/components/Typography/Text'
import Activity from '~/client/src/shared/models/Activity'
import Delivery from '~/client/src/shared/models/Delivery'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import { isDeliveryType } from '~/client/src/shared/types/NotificationTypes'

import BaseNotification from '../models/Notification'
import CompaniesStore from '../stores/domain/Companies.store'

interface IProps {
  notification: BaseNotification
  activitiesStore?: ActivitiesStore
  deliveriesStore?: DeliveriesStore
  companiesStore?: CompaniesStore
}

@inject('activitiesStore', 'deliveriesStore', 'companiesStore')
@observer
export default class NotificationEntityName extends React.Component<IProps> {
  private get activity(): Activity {
    const { activitiesStore, notification } = this.props
    return activitiesStore.byId.get(notification.activityObjectId)
  }

  private get delivery(): Delivery {
    const { deliveriesStore, notification } = this.props
    return deliveriesStore.byId.get(notification.entityId)
  }

  private get displayName(): string {
    const { listItemIdToNumberMap } = this.props.activitiesStore
    const { activityObjectId, type } = this.props.notification

    if (isDeliveryType(type)) {
      return this.delivery?.codeToDisplay(this.props.companiesStore)
    }

    if (this.activity) {
      const sequenceNumber = listItemIdToNumberMap[this.activity.code]
      const activityId = this.activity.formattedId(sequenceNumber)

      return `${activityId} ${this.activity.name}`
    }

    return activityObjectId
  }

  public render() {
    const { wasRead } = this.props.notification
    const name = this.displayName

    return (
      !!name && (
        <Text bold={!wasRead} className="large">
          {name}
        </Text>
      )
    )
  }
}
