import { enumToList } from '../utils/converters'

export enum CancelationReason {
  NoShow = 'no-show',
  Moved = 'moved',
  Other = 'other',
}

export enum CancelationReasonNames {
  NoShow = 'No Show',
  Moved = 'Moved',
  Other = 'Other',
}

export const cancelationReasonsList = enumToList(CancelationReason)
