import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IUpsertMaturixDataMutationVariables = Types.Exact<{
  struxHubProjectId: Types.Scalars['ObjectId']
  maturixProjectId: Types.Scalars['String']
  strengthUnit: Types.Scalars['String']
  temperatureUnit: Types.Scalars['String']
}>

export type IUpsertMaturixDataMutation = Pick<
  Types.IMutation,
  'upsertMaturixData'
>

export type IUpsertMaturixTokenForProjectMutationVariables = Types.Exact<{
  struxHubProjectId: Types.Scalars['ObjectId']
  maturixToken: Types.Scalars['String']
}>

export type IUpsertMaturixTokenForProjectMutation = Pick<
  Types.IMutation,
  'upsertMaturixTokenForProject'
>

export type IDeleteMaturixTokenForProjectMutationVariables = Types.Exact<{
  struxHubProjectId: Types.Scalars['ObjectId']
}>

export type IDeleteMaturixTokenForProjectMutation = Pick<
  Types.IMutation,
  'deleteMaturixTokenForProject'
>

export type IDeleteMaturixFromProjectMutationVariables = Types.Exact<{
  struxHubProjectId: Types.Scalars['ObjectId']
}>

export type IDeleteMaturixFromProjectMutation = Pick<
  Types.IMutation,
  'deleteMaturixFromProject'
>

export const UpsertMaturixDataDocument = gql`
  mutation UpsertMaturixData(
    $struxHubProjectId: ObjectId!
    $maturixProjectId: String!
    $strengthUnit: String!
    $temperatureUnit: String!
  ) {
    upsertMaturixData(
      struxHubProjectId: $struxHubProjectId
      maturixProjectId: $maturixProjectId
      strengthUnit: $strengthUnit
      temperatureUnit: $temperatureUnit
    )
  }
`
export type IUpsertMaturixDataMutationFn = Apollo.MutationFunction<
  IUpsertMaturixDataMutation,
  IUpsertMaturixDataMutationVariables
>

/**
 * __useUpsertMaturixDataMutation__
 *
 * To run a mutation, you first call `useUpsertMaturixDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMaturixDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMaturixDataMutation, { data, loading, error }] = useUpsertMaturixDataMutation({
 *   variables: {
 *      struxHubProjectId: // value for 'struxHubProjectId'
 *      maturixProjectId: // value for 'maturixProjectId'
 *      strengthUnit: // value for 'strengthUnit'
 *      temperatureUnit: // value for 'temperatureUnit'
 *   },
 * });
 */
export function useUpsertMaturixDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpsertMaturixDataMutation,
    IUpsertMaturixDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpsertMaturixDataMutation,
    IUpsertMaturixDataMutationVariables
  >(UpsertMaturixDataDocument, options)
}
export type UpsertMaturixDataMutationHookResult = ReturnType<
  typeof useUpsertMaturixDataMutation
>
export type UpsertMaturixDataMutationResult =
  Apollo.MutationResult<IUpsertMaturixDataMutation>
export type UpsertMaturixDataMutationOptions = Apollo.BaseMutationOptions<
  IUpsertMaturixDataMutation,
  IUpsertMaturixDataMutationVariables
>
export const UpsertMaturixTokenForProjectDocument = gql`
  mutation UpsertMaturixTokenForProject(
    $struxHubProjectId: ObjectId!
    $maturixToken: String!
  ) {
    upsertMaturixTokenForProject(
      struxHubProjectId: $struxHubProjectId
      maturixToken: $maturixToken
    )
  }
`
export type IUpsertMaturixTokenForProjectMutationFn = Apollo.MutationFunction<
  IUpsertMaturixTokenForProjectMutation,
  IUpsertMaturixTokenForProjectMutationVariables
>

/**
 * __useUpsertMaturixTokenForProjectMutation__
 *
 * To run a mutation, you first call `useUpsertMaturixTokenForProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMaturixTokenForProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMaturixTokenForProjectMutation, { data, loading, error }] = useUpsertMaturixTokenForProjectMutation({
 *   variables: {
 *      struxHubProjectId: // value for 'struxHubProjectId'
 *      maturixToken: // value for 'maturixToken'
 *   },
 * });
 */
export function useUpsertMaturixTokenForProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpsertMaturixTokenForProjectMutation,
    IUpsertMaturixTokenForProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpsertMaturixTokenForProjectMutation,
    IUpsertMaturixTokenForProjectMutationVariables
  >(UpsertMaturixTokenForProjectDocument, options)
}
export type UpsertMaturixTokenForProjectMutationHookResult = ReturnType<
  typeof useUpsertMaturixTokenForProjectMutation
>
export type UpsertMaturixTokenForProjectMutationResult =
  Apollo.MutationResult<IUpsertMaturixTokenForProjectMutation>
export type UpsertMaturixTokenForProjectMutationOptions =
  Apollo.BaseMutationOptions<
    IUpsertMaturixTokenForProjectMutation,
    IUpsertMaturixTokenForProjectMutationVariables
  >
export const DeleteMaturixTokenForProjectDocument = gql`
  mutation DeleteMaturixTokenForProject($struxHubProjectId: ObjectId!) {
    deleteMaturixTokenForProject(struxHubProjectId: $struxHubProjectId)
  }
`
export type IDeleteMaturixTokenForProjectMutationFn = Apollo.MutationFunction<
  IDeleteMaturixTokenForProjectMutation,
  IDeleteMaturixTokenForProjectMutationVariables
>

/**
 * __useDeleteMaturixTokenForProjectMutation__
 *
 * To run a mutation, you first call `useDeleteMaturixTokenForProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMaturixTokenForProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMaturixTokenForProjectMutation, { data, loading, error }] = useDeleteMaturixTokenForProjectMutation({
 *   variables: {
 *      struxHubProjectId: // value for 'struxHubProjectId'
 *   },
 * });
 */
export function useDeleteMaturixTokenForProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteMaturixTokenForProjectMutation,
    IDeleteMaturixTokenForProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteMaturixTokenForProjectMutation,
    IDeleteMaturixTokenForProjectMutationVariables
  >(DeleteMaturixTokenForProjectDocument, options)
}
export type DeleteMaturixTokenForProjectMutationHookResult = ReturnType<
  typeof useDeleteMaturixTokenForProjectMutation
>
export type DeleteMaturixTokenForProjectMutationResult =
  Apollo.MutationResult<IDeleteMaturixTokenForProjectMutation>
export type DeleteMaturixTokenForProjectMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteMaturixTokenForProjectMutation,
    IDeleteMaturixTokenForProjectMutationVariables
  >
export const DeleteMaturixFromProjectDocument = gql`
  mutation DeleteMaturixFromProject($struxHubProjectId: ObjectId!) {
    deleteMaturixFromProject(struxHubProjectId: $struxHubProjectId)
  }
`
export type IDeleteMaturixFromProjectMutationFn = Apollo.MutationFunction<
  IDeleteMaturixFromProjectMutation,
  IDeleteMaturixFromProjectMutationVariables
>

/**
 * __useDeleteMaturixFromProjectMutation__
 *
 * To run a mutation, you first call `useDeleteMaturixFromProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMaturixFromProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMaturixFromProjectMutation, { data, loading, error }] = useDeleteMaturixFromProjectMutation({
 *   variables: {
 *      struxHubProjectId: // value for 'struxHubProjectId'
 *   },
 * });
 */
export function useDeleteMaturixFromProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteMaturixFromProjectMutation,
    IDeleteMaturixFromProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteMaturixFromProjectMutation,
    IDeleteMaturixFromProjectMutationVariables
  >(DeleteMaturixFromProjectDocument, options)
}
export type DeleteMaturixFromProjectMutationHookResult = ReturnType<
  typeof useDeleteMaturixFromProjectMutation
>
export type DeleteMaturixFromProjectMutationResult =
  Apollo.MutationResult<IDeleteMaturixFromProjectMutation>
export type DeleteMaturixFromProjectMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteMaturixFromProjectMutation,
    IDeleteMaturixFromProjectMutationVariables
  >
