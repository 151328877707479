import { FilterType } from '~/client/graph'

import ActivityDataFieldId from '../enums/ActivityDataFieldId'

const activityFilterTypeToFieldIdMap = {
  [FilterType.Building]: ActivityDataFieldId.BUILDING_ID,
  [FilterType.Level]: ActivityDataFieldId.LEVEL_ID,
  [FilterType.Zone]: ActivityDataFieldId.ZONE_ID,
  [FilterType.Company]: ActivityDataFieldId.COMPANY_ID,
}

export default activityFilterTypeToFieldIdMap
