import { IActivityCode } from '~/client/graph'
import BaseModel from '~/client/src/shared/models/BaseModel'
import Guard from '~/client/src/shared/utils/Guard'

import ActivityCodeType from './ActivityCodeType'

const EMPTY_CELL_VALUE = ''

export default class ActivityCode extends BaseModel<IActivityCode> {
  public static fromInstance(instance: ActivityCode): ActivityCode {
    return new ActivityCode(
      instance.id,
      instance.parentId,
      instance.name,
      instance.shortName,
      instance.count,
      instance.activityCodeTypeId,
      instance.createdAt,
      instance.updatedAt,
      instance.scheduleId,
    )
  }

  public static fromDto(dto: IActivityCode) {
    return new ActivityCode(
      dto.id,
      dto.parentId,
      dto.name,
      dto.shortName,
      dto.count,
      dto.typeId,
      dto.createdAt,
      dto.updatedAt,
      dto.scheduleId,
    )
  }

  public static getLocationTagLabel(locationTagCodes: ActivityCode[]) {
    const locationTagLabel = locationTagCodes
      .map(locationTagCode => {
        return (locationTagCode.shortName || '').replace(/Zone/g, '')
      })
      .join(', ')
    return locationTagLabel || EMPTY_CELL_VALUE
  }

  public constructor(
    public readonly id: string,
    public readonly parentId: string,
    public readonly name: string,
    public readonly shortName: string,
    public readonly count: number,
    public readonly typeId: string,
    public readonly createdAt: number,
    public readonly updatedAt: number,
    public readonly scheduleId: string,
  ) {
    super(id)

    Guard.againstUndefined(id, 'id')
  }

  public get activityCodeTypeId() {
    return this.typeId
  }

  public get asJson(): IActivityCode {
    return {
      typeId: this.activityCodeTypeId,
      createdAt: this.createdAt,
      id: this.id || null,
      parentId: this.parentId || null,
      name: this.name,
      shortName: this.shortName,
      count: this.count,
      updatedAt: this.updatedAt,
      scheduleId: this.scheduleId,
    }
  }

  public isType(codeType: ActivityCodeType): boolean {
    return codeType && this.activityCodeTypeId === codeType.id
  }
}
