import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'

import { IUpdateDeliveriesFieldsInput } from '~/client/graph'
import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DeliveryDetailsStore, {
  DeliveryDetailsActions,
} from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import { deliveryFieldIdToModelPropertyNameMap } from '~/client/src/shared/constants/fieldIdToDeliveryPropertyMap'
import DeliveryControlTypes from '~/client/src/shared/enums/DeliveryControlTypes'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IDeliveryControl from '~/client/src/shared/models/IDeliveryControl'
import { EDIT_BULK_DELIVERIES } from '~/client/src/shared/stores/EventStore/eventConstants'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import {
  ToastTheme,
  showErrorToast,
  showToast,
} from '~/client/src/shared/utils/toaster'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

// localization: translated

export const DATE_RELATED_FIELD_IDS = [
  FieldIds.START_TIME,
  FieldIds.END_TIME,
  FieldIds.DURATION,
]

export const MATERIAL_RELATED_FIELD_IDS = [
  FieldIds.MATERIALS_SECTION,
  FieldIds.MATERIAL_CATEGORY,
  FieldIds.MATERIAL_PROCUREMENT_ID,
  FieldIds.MATERIAL_INSTALL_LOCATION,
  FieldIds.MATERIAL_LOCATION,
  FieldIds.MATERIAL_NOTE,
  FieldIds.MATERIAL_UNITS,
  FieldIds.MATERIAL_QUANTITY,
]

const EXCLUDED_FIELD_IDS = [
  ...DATE_RELATED_FIELD_IDS,
  ...MATERIAL_RELATED_FIELD_IDS,
  FieldIds.WEATHER_FORECAST,
  FieldIds.UNSCHEDULED_REQUEST,
  FieldIds.LATE_REQUEST,
  FieldIds.REQUESTER,
  FieldIds.NON_WORK_TIMES,
  FieldIds.RECURRING_OPTIONS,
]

const EMPTY_OPTION: IDeliveryControl = {
  id: '' as FieldIds,
  type: '' as DeliveryControlTypes,
  value: '',
  hints: [],
}

const NOT_EDIT_DELIVERY_PROPS = [
  'deliveryIds',
  'projectId',
  'timezoneId',
  'message',
]

export default class DeliveryBulkEditorStore {
  @observable public shouldShowConfirmModal: boolean = false
  @observable public shouldShowAlertModal: boolean = false
  @observable public editingFieldIds: string[] = [EMPTY_STRING]
  @observable private selectedDeliveriesIds: string[] = []

  private editingProps = {
    deliveryIds: [],
  } as IUpdateDeliveriesFieldsInput

  private get editingPropsCount() {
    return Object.keys(this.editingProps).filter(
      p => !NOT_EDIT_DELIVERY_PROPS.includes(p),
    ).length
  }

  public constructor(
    private readonly onUpdated: () => void,
    private readonly eventsStore: DesktopEventStore,
    private readonly deliveriesStore: DeliveriesStore,
    private readonly deliveryDetailsStore: DeliveryDetailsStore,
    selectedDeliveriesIds: string[] = [],
  ) {
    this.selectedDeliveriesIds = selectedDeliveriesIds
  }

  @computed
  public get fieldOptions(): IDeliveryControl[] {
    const filteredFields = this.allFields.filter(
      f =>
        !EXCLUDED_FIELD_IDS.includes(f.id) &&
        !(f.index > 1) &&
        !this.editingFieldIds.includes(f.id),
    )

    return [EMPTY_OPTION, ...filteredFields]
  }

  @computed
  public get editingFields(): IDeliveryControl[] {
    const fieldsIds = this.editingFieldIds.slice()

    if (this.isDateFieldSelected) {
      fieldsIds.push(...DATE_RELATED_FIELD_IDS)
    }

    if (this.isMaterialFieldSelected) {
      fieldsIds.push(...MATERIAL_RELATED_FIELD_IDS)
    }

    return this.allFields.filter(f => fieldsIds.includes(f.id))
  }

  public reinit = (selectedDeliveriesIds: string[]) => {
    this.selectedDeliveriesIds = selectedDeliveriesIds.slice()
  }

  public setSelectedFieldId = (id: FieldIds) => {
    this.deliveryDetailsStore.setSelectedFieldId(id)
  }

  @computed
  public get canEdit(): boolean {
    return (
      this.isSomeFieldSelected &&
      this.areAllRequiredEditingFieldsFilled &&
      this.areAllEditingFieldsValid
    )
  }

  public get isSomeFieldSelected(): boolean {
    return this.editingFieldIds[0] !== EMPTY_STRING
  }

  public get allFields(): IDeliveryControl[] {
    return this.deliveryDetailsStore.fields
  }

  public get shouldShowNewHandleButton(): boolean {
    return (
      this.editingFieldIds[this.editingFieldIds.length - 1] !== EMPTY_STRING
    )
  }

  public isEditingFieldSelectedByIndex = (index: number) => {
    return this.editingFieldIds[index] !== EMPTY_STRING
  }

  public get actionHandleButtonTitle(): string {
    return Localization.translator.editXFieldsOnYDeliveries(
      this.editingFieldIds.length,
      this.selectedDeliveriesIds.length,
    )
  }

  public get confirmMessage(): string {
    const selectedCount = this.selectedDeliveriesIds.length
    const editingCount = this.editingProps.deliveryIds.length

    return selectedCount === editingCount
      ? Localization.translator.editXDeliveries_question(editingCount)
      : Localization.translator.onlyXOfYSelectedDeliveriesCanBeChanged(
          editingCount,
          selectedCount,
        )
  }

  public get commonCtrlProps() {
    return {
      onChange: this.deliveryDetailsStore.onFieldValueChange,
      FileInputType: DesktopFileInput,
    }
  }

  @computed
  private get areAllEditingFieldsValid() {
    return this.editingFields.every(f => f.isValid)
  }

  @computed
  private get areAllRequiredEditingFieldsFilled() {
    return this.editingFields
      .filter(field => field.isRequired)
      .every(field => field.value && Object.keys(field.value).length !== 0)
  }

  private get isDateFieldSelected(): boolean {
    return this.editingFieldIds.includes(FieldIds.DATE)
  }

  private get isMaterialFieldSelected(): boolean {
    return this.editingFieldIds.includes(FieldIds.MATERIAL)
  }

  @action.bound
  public handleAddNewEditingField() {
    this.editingFieldIds.push(EMPTY_STRING)
  }

  @action.bound
  public handleSelectEditedField(index: number, fieldId: string) {
    this.editingFieldIds[index] = fieldId
  }

  @action.bound
  public removeEditedField(index: number) {
    this.editingFieldIds.splice(index, 1)
  }

  @action.bound
  public async prepareEditingData() {
    if (!this.canEdit) {
      return
    }

    const {
      hasUserPermissions,
      setMaterialFromFields,
      getDeliveryDtoFromFields,
    } = this.deliveryDetailsStore

    const deliveryDto = getDeliveryDtoFromFields()
    await setMaterialFromFields(deliveryDto)

    const selectedPropsNames = this.editingFields
      .map(({ id }) => deliveryFieldIdToModelPropertyNameMap[id])
      .filter(propName => !!propName)

    const changedFieldsMessage = this.getChangedFieldsMessage()

    this.editingProps = {
      projectId: this.eventsStore.appState.activeProject.id,
      timezoneId: '',
      deliveryIds: this.selectedDeliveriesIds.filter(deliveryId => {
        const delivery = this.deliveriesStore.byId.get(deliveryId)
        return hasUserPermissions(DeliveryDetailsActions.SHOW_FORM, delivery)
      }),
      message: changedFieldsMessage ? { text: changedFieldsMessage } : null,
    }

    Object.keys(deliveryDto).forEach(propName => {
      if (selectedPropsNames.includes(propName)) {
        this.editingProps[propName] = deliveryDto[propName]
      }
    })

    if (this.editingProps.deliveryIds.length) {
      return this.openConfirm()
    }

    this.openAlert()
  }

  @action.bound
  public handleEdit() {
    if (!this.canEdit) {
      return
    }

    this.eventsStore.dispatch(
      EDIT_BULK_DELIVERIES,
      this.editingProps,
      this.handleSuccessEditing,
      this.handleErrorSubscription,
    )

    this.onUpdated()
  }

  @action.bound
  public openConfirm() {
    this.shouldShowConfirmModal = true
  }

  @action.bound
  public closeConfirm() {
    this.shouldShowConfirmModal = false
  }

  @action.bound
  public openAlert() {
    this.shouldShowAlertModal = true
  }

  @action.bound
  public closeAlert() {
    this.shouldShowAlertModal = false
  }

  private handleSuccessEditing = () => {
    showToast(
      Localization.translator.editedXFieldsOnYDeliveries(
        this.editingPropsCount,
        this.editingProps.deliveryIds.length,
      ),
      ToastTheme.SUCCESS,
      IconNames.EDIT,
    )
  }

  private handleErrorSubscription = () => {
    showErrorToast(Localization.translator.failedToEdit)
  }

  private getChangedFieldsMessage = (): string => {
    const changedFieldsMessage =
      this.deliveryDetailsStore.getChangedFieldsMessage(
        EMPTY_STRING,
        this.editingFields,
      )

    if (changedFieldsMessage) {
      return changedFieldsMessage + Localization.translator.bulkUpdate
    }
  }
}
