import { action, computed, observable } from 'mobx'

import { FilterType, IActivity, LocationType } from '~/client/graph'
import { GetActivityByCodeDocument } from '~/client/graph/operations/generated/Schedule.generated'
import Activity from '~/client/src/shared/models/Activity'
import { IActivitiesStore } from '~/client/src/shared/stores/domain/interfaces/IActivitiesStore'

import activityFieldIdToLinkingSettingNameMap from '../../constants/activityFieldIdToLinkingSettingNameMap'
import activityFilterTypeToFieldIdMap from '../../constants/activityFilterTypeToFieldIdMap'
import locationTypeToActivityLocationFieldIdMap from '../../constants/locationTypeToActivityLocationFieldIdMap'
import ActivityDataFieldId from '../../enums/ActivityDataFieldId'
import ActivityLinkingSettings from '../../enums/ActivityLinkingSettings'
import INewActivityData from '../../interfaces/INewActivityData'
import Day from '../../models/Day'
import StatusUpdate from '../../models/StatusUpdate'
import MobXMap from '../../types/MobXMap'
import SimpleCalendarDto from '../../types/SimpleCalendarDto'
import Guard from '../../utils/Guard'
import RootStore from '../Root.store'
import BaseStoreWithById from '../baseStores/BaseWithById.store'
import ProjectDateStore, {
  MILLISECONDS_IN_DAY,
  isAfter,
  isBefore,
} from '../ui/ProjectDate.store'
import GraphExecutorStore from './GraphExecutor.store'

const ACTIVITY_LOAD = 'activity-load'

export function getActivityLoadEventName(activityCode: string): string {
  return `${ACTIVITY_LOAD}_${activityCode}`
}

export interface IActivityWithCompanies {
  activityCode: string
  companiesNames: string[]
}

export default class ActivitiesStore
  extends BaseStoreWithById<Activity, IActivity>
  implements IActivitiesStore
{
  @observable public selection = ''

  // for multiselecting in ActivityList View
  @observable public multiSelection: string[] = []
  @observable public draftNewActivityData: INewActivityData

  public constructor(
    private rootStore: RootStore,
    private readonly graphExecutorStore: GraphExecutorStore,
  ) {
    super(Activity)

    Guard.require(graphExecutorStore, 'graphExecutorStore')
  }

  public get byId(): MobXMap<Activity> {
    return this.rootStore.eventsStore.appState.activities
  }

  public getByDbId(dbId: string): Activity {
    return this.rootStore.eventsStore.appState.activities.values.find(
      a => a.databaseId == dbId,
    )
  }

  @action.bound
  public removeOne(activityCode: string) {
    if (activityCode) {
      this.byId.delete(activityCode)
    }
  }

  @computed
  public get listItemIdToNumberMap(): { [listItemId: string]: number } {
    return this.list.reduce((acc, item, index) => {
      acc[item.code] = index
      return acc
    }, {})
  }

  public get projectDateStore(): ProjectDateStore {
    return this.rootStore.projectDateStore
  }

  public get selectedActivity(): Activity {
    return this.byId.get(this.selection)
  }

  @computed
  public get excludedActivityIds(): string[] {
    const { hiddenActivityCodeIds } =
      this.rootStore.state.activityFiltersSettings

    return (hiddenActivityCodeIds || []).reduce((value, codeId) => {
      const codeActivityIds =
        this.rootStore.activityCodesStore.codesToActivitiesMap[codeId]
      return value.concat(codeActivityIds)
    }, [] as string[])
  }

  public getActivitiesToCalculateDates(activityIds?: string[]): Activity[] {
    const { allStatusesUpdatesByActivityMap } =
      this.rootStore.statusUpdatesStore
    const activitiesToUpdateDates: Activity[] = []
    const sourceList = activityIds?.map(id => this.byId.get(id)) || this.list
    return sourceList.reduce((activities, a) => {
      if (allStatusesUpdatesByActivityMap[a.code]) {
        activities.push(a)
      }

      return activities
    }, activitiesToUpdateDates)
  }

  @computed
  public get list(): Activity[] {
    if (!this.excludedActivityIds.length) {
      return this.fullList.filter(a => !a.isDeleted)
    }

    return this.fullList.filter(
      a => !this.excludedActivityIds.includes(a.code) && !a.isDeleted,
    )
  }

  @computed
  public get fullList(): Activity[] {
    return Array.from(this.byId.values)
  }

  public get allDoneInP6Activities(): Activity[] {
    return this.list.filter(activity => activity.isActivityMarkedAsDoneInP6)
  }

  @computed
  public get activeActivities(): Activity[] {
    return this.list.filter(
      activity => activity.didStart && !activity.didFinish,
    )
  }

  @computed
  public get futureActivities(): Activity[] {
    return this.list.filter(activity => !activity.didStart)
  }

  @computed
  public get completedActivities(): Activity[] {
    return this.list.filter(activity => activity.didFinish)
  }

  @action.bound
  public async requestActivityByCode(scheduleId: string, activityCode: string) {
    const eventName = getActivityLoadEventName(activityCode)

    this.rootStore.state.loading.set(eventName, true)

    const res = await this.graphExecutorStore.query(GetActivityByCodeDocument, {
      scheduleId,
      activityCode,
    })

    this.rootStore.state.loading.set(eventName, false)

    if (res.data?.getActivity) {
      this.updateOne(activityCode, res.data.getActivity)
    }
  }

  @action.bound
  public calculateActivityDurations() {
    const { projectDateStore } = this.rootStore
    this.list.forEach(activity => activity.setDurations(projectDateStore))
  }

  @action.bound
  public updateActivitiesActualDates(datesMap: {
    [key: string]: { start: number; end: number }
  }) {
    const activityCodes = Object.keys(datesMap)
    activityCodes.forEach(code => {
      const activity: Activity = this.byId.get(code)
      if (activity) {
        const dates = datesMap[code]
        activity.updateActualDates(
          this.rootStore.projectDateStore,
          dates.start,
          dates.end,
        )
      }
    })
  }

  @action.bound
  public select(code: string) {
    this.selection = code
    this.multiSelection = code ? [code] : []
  }

  // for multiselecting in ActivityList View
  @action.bound
  public multiSelect(ids: string[]) {
    this.multiSelection = ids
    this.selection = ids[0]
  }

  @action.bound
  public getActivitiesActiveOn(date: Date): Activity[] {
    return this.list.filter(a =>
      a.isActiveOn(date, this.rootStore.projectDateStore),
    )
  }

  @action.bound
  public getActivitiesFinishingAfter(date: Date): Activity[] {
    const ms = date.getTime()

    return this.list.filter(a => {
      if (a.didFinish) {
        return a.dates.actual.finish.ms >= ms
      } else {
        return a.dates.planned.finish.ms >= ms
      }
    })
  }

  @action.bound
  public setDraftNewActivity(draftData?: INewActivityData) {
    this.draftNewActivityData = draftData
  }

  public getActivityParsedDays(json: IActivity) {
    const plannedStartDay = this.toDay(json.plannedStartDate)
    const plannedFinishDay = this.toDay(json.plannedFinishDate)

    const actualStartDay = this.toDay(json.actualStartDate)
    const actualFinishDay = this.toDay(json.actualFinishDate)

    const remainingEarlyStartDay = this.toDay(json.remainingEarlyStartDate)
    const remainingEarlyFinishDay = this.toDay(json.remainingEarlyFinishDate)
    const { countDaysToDate } = this.projectDateStore
    return {
      plannedStartDay,
      plannedFinishDay,
      plannedDuration:
        countDaysToDate(plannedStartDay.ms, plannedFinishDay.ms, true) || 0,
      actualStartDay,
      actualFinishDay,
      actualDuration:
        countDaysToDate(actualStartDay.ms, actualFinishDay.ms, true) || 0,
      remainingEarlyStartDay,
      remainingEarlyFinishDay,
    }
  }

  public getCommonStatusUpdateByActivity(activity: Activity): StatusUpdate {
    return this.rootStore.statusUpdatesStore.getCommonStatusUpdateByActivity(
      activity,
    )
  }

  public getActivityCalendar(activity: Activity): SimpleCalendarDto {
    const { calendarStore } = this.rootStore
    const calendar = calendarStore.byId.get(activity.calendarId)
    return calendar || calendarStore.defaultCalendar
  }

  public isActivityWorkingOnDay(activity: Activity, date: Date): boolean {
    const { calendar } = activity
    const { closedIntervals, projectWorkingDaysMap } =
      this.rootStore.state.activeProject
    const { calendarWorkingDaysMap, calendarExceptionsMap } =
      this.rootStore.calendarStore
    const { getWeekdayNumber, startOfDay, endOfDay } = this.projectDateStore
    if (
      closedIntervals &&
      closedIntervals.some(
        interval =>
          !isBefore(date, startOfDay(interval.startDate)) &&
          !isAfter(date, endOfDay(interval.endDate)),
      )
    ) {
      return false
    }
    if (!calendar) {
      return true
    }
    const weekDay = getWeekdayNumber(date)
    const dayNumber = Math.round(
      startOfDay(date).getTime() / MILLISECONDS_IN_DAY,
    )

    return (
      (calendarWorkingDaysMap[calendar.id][weekDay] ||
        projectWorkingDaysMap[weekDay]) &&
      !calendarExceptionsMap[calendar.id][dayNumber]
    )
  }

  public checkIsFieldLinkable = (fieldId: string): boolean => {
    const linkingSettingName = activityFieldIdToLinkingSettingNameMap[fieldId]
    return Object.keys(ActivityLinkingSettings || {}).includes(
      linkingSettingName,
    )
  }

  public checkIsFieldCanBeLinked = (
    fieldId: string,
    activity: Activity,
  ): ActivityDataFieldId => {
    return this.checkIsFieldLinkable(fieldId) && activity?.externalData[fieldId]
  }

  private setExternalData(activity: Activity): void {
    const filterTypes = this.rootStore.activityFiltersStore.allFilterTypes
    const externalData = {}

    filterTypes.forEach((filterType: FilterType) => {
      const fieldId = activityFilterTypeToFieldIdMap[filterType]
      const isCompanyFilterType = filterType === FilterType.Company

      const data = isCompanyFilterType
        ? this.rootStore.resourcesStore.getResourceByActivityId(activity.code)
        : activity.codes.find(code =>
            this.rootStore.activityFiltersStore
              .getCodesByFilterType(filterType)
              .includes(code),
          )

      if (data) {
        externalData[fieldId] = data
      }
    })

    activity.externalData = externalData
  }

  private setCompany(activity: Activity): void {
    const { activityFiltersStore, companiesStore } = this.rootStore

    const linkedCompany = activityFiltersStore.getActivityLinkedCompany(
      activity.code,
    )
    const assignedCompany = companiesStore.getCompanyById(
      activity.assignedCompanyId,
    )

    activity.linkedData[ActivityDataFieldId.COMPANY_ID] = linkedCompany?.id
    activity.company = activity.activityLinkingSettings[
      ActivityLinkingSettings.isCompanyLinkingActive
    ]
      ? linkedCompany
      : assignedCompany
  }

  private setLocations(activity: Activity): void {
    const allLocationCodeIds = []
    this.rootStore.activityFiltersStore.activityCodeFilterTypes.forEach(
      filterType => {
        allLocationCodeIds.push(
          ...this.rootStore.activityFiltersStore
            .getCodesByFilterType(filterType)
            .map(({ id }) => id),
        )
      },
    )

    const externalLocationCodes = activity.codes.filter(code =>
      allLocationCodeIds.includes(code.id),
    )

    let locationsLinkedToExternalData = []

    if (externalLocationCodes.length) {
      locationsLinkedToExternalData = externalLocationCodes
        .map(code =>
          this.rootStore.activityCodeLocationRelationshipsStore.list.find(
            r => r.activityCodeId === code.id,
          ),
        )
        .filter(r => r)
        .map(r => ({
          id: r.locationObjectId,
          type: r.locationObjectType,
        }))
    }

    const locationsData = []

    Object.values(LocationType).forEach(locationType => {
      const fieldId = locationTypeToActivityLocationFieldIdMap[locationType]
      const linkingSettingName = activityFieldIdToLinkingSettingNameMap[fieldId]
      const isFieldCanBeLinked = this.checkIsFieldCanBeLinked(fieldId, activity)
      const isFieldLinkingEnabled =
        activity.activityLinkingSettings[linkingSettingName]

      const linkedLocation = locationsLinkedToExternalData.find(
        r => r.type === locationType,
      )
      const assignedLocation = activity.assignedLocations?.find(
        r => r.type === locationType,
      )

      const activityLocation =
        isFieldCanBeLinked && isFieldLinkingEnabled
          ? linkedLocation
          : assignedLocation

      locationsData.push(activityLocation)
      activity.linkedData[fieldId] = linkedLocation?.id
    })

    activity.locations = locationsData.filter(r => r)
  }

  @action.bound
  public setActivityData(activityId?: string) {
    const activityCodesMap = this.getActivityCodesMap()
    if (activityId) {
      const activity = this.byId.get(activityId)
      activity.codes = activityCodesMap[activity.code] || []
      this.setExternalData(activity)
      this.setLocations(activity)
      this.setCompany(activity)
    } else {
      this.list.forEach(activity => {
        activity.codes = activityCodesMap[activity.code] || []
        this.setExternalData(activity)
        this.setLocations(activity)
        this.setCompany(activity)
      })
    }
  }

  protected createAnInstance(id: string, dto: any): Activity {
    const model = new Activity(id, this)
    model.updateFromJson(dto)
    return model
  }

  private toDay(dateMs: number): Day {
    const date = dateMs ? new Date(dateMs) : null
    return new Day(date, this.projectDateStore)
  }

  private getActivityCodesMap() {
    const { activityCodeRelationshipsStore, activityCodesStore } =
      this.rootStore
    return activityCodeRelationshipsStore.availableRelationships.reduce(
      (map, rel) => {
        const code = activityCodesStore.byId.get(rel.activityTypeId)
        if (code) {
          // eslint-disable-next-line @typescript-eslint/no-extra-semi
          ;(map[rel.activityId] || (map[rel.activityId] = [])).push(code)
        }

        return map
      },
      {},
    )
  }
}
