import * as React from 'react'

import { Radio } from '@blueprintjs/core'
import { action } from 'mobx'
import { observer } from 'mobx-react'

import { IViewTypes } from '~/client/graph'
import HierarchyModes, {
  hierarchyModesList,
} from '~/client/src/shared/enums/HierarchyModes'
import ViewTypes from '~/client/src/shared/enums/ViewTypes'
import Localization from '~/client/src/shared/localization/LocalizationManager'

interface IConfigurationRow {
  viewType: ViewTypes
  configuration: IViewTypes
  onChange: (viewType: ViewTypes, hierarchyMode: HierarchyModes) => void
  isDisabled?: boolean
}

@observer
export default class ViewConfigurationRow extends React.Component<IConfigurationRow> {
  public render() {
    const { viewType, configuration, isDisabled } = this.props

    return (
      <div className="row bb-light-grey theme-radio-group configurator-row no-hover">
        <div className="text large center">{this.translatedViewName}</div>
        {hierarchyModesList.map((hierarchyMode, index) => (
          <div className="text large center" key={index}>
            <Radio
              value={hierarchyMode}
              checked={configuration[viewType] === hierarchyMode}
              onClick={this.onChange}
              readOnly={true}
              disabled={isDisabled}
            />
          </div>
        ))}
      </div>
    )
  }

  private get translatedViewName() {
    const { viewType } = this.props
    switch (viewType) {
      case ViewTypes.webGantt:
        return Localization.translator.webGantt

      case ViewTypes.webSitemap:
        return Localization.translator.webSitemap

      case ViewTypes.mobile:
        return Localization.translator.mobile

      case ViewTypes.tablet:
        return Localization.translator.tablet

      default:
        throw new Error(viewType + ' is not added to translator')
    }
  }

  @action.bound
  private onChange(e: React.MouseEvent<HTMLInputElement>) {
    const { onChange, viewType } = this.props

    const hierarchyMode = (e.target as HTMLInputElement).value
    onChange(viewType, hierarchyMode as HierarchyModes)
  }
}
