import * as React from 'react'

import { inject, observer } from 'mobx-react'

import ActivityInfoStore from '~/client/src/shared/components/ActivityDetails/components/ActivityInfoSection/ActivityInfo.store'
import DetailListItem from '~/client/src/shared/components/DetailListItem'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Activity from '~/client/src/shared/models/Activity'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

// translated

interface IProps {
  store: ActivityInfoStore
  activitiesStore?: ActivitiesStore
  common?: CommonStore
}

@inject('activitiesStore', 'common')
@observer
export default class ScheduleDetails extends React.Component<IProps> {
  public render() {
    const { wbs, predecessors, successors } = this.props.store

    return (
      <DetailListItem category={Localization.translator.schedule_noun}>
        <div className="col y-start">
          <span className="text large primary-blue">
            {Localization.translator.wbs}
          </span>
          <span className="text large">{wbs.name}</span>
        </div>
        <div className="col y-start pt10">
          <span className="col text large primary-blue">
            {Localization.translator.predecessors + ': '}
          </span>
          <span className="col">
            {predecessors.length
              ? predecessors.map((p, i) => (
                  <div
                    className="text large link"
                    key={i}
                    onClick={this.navigateToActivity.bind(this, p)}
                  >
                    {p.name}
                  </div>
                ))
              : NO_VALUE}
          </span>
        </div>
        <div className="col y-start pt10">
          <span className="col text large primary-blue">
            {Localization.translator.successors + ': '}
          </span>
          <span className="col">
            {successors.length
              ? successors.map((s, i) => {
                  return (
                    <div
                      className="text large link"
                      key={i}
                      onClick={this.navigateToActivity.bind(this, s)}
                    >
                      {s.name}
                    </div>
                  )
                })
              : NO_VALUE}
          </span>
        </div>
      </DetailListItem>
    )
  }

  private navigateToActivity(a: Activity) {
    const { common, activitiesStore } = this.props
    activitiesStore.select(a.code)
    common.toActivityDetails()
  }
}
