import * as React from 'react'

import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ActivityAssociationStatus from '~/client/src/shared/components/ActivityAssociationStatus/ActivityAssociationStatus'
import { VGap } from '~/client/src/shared/components/Layout'
import Activity from '~/client/src/shared/models/Activity'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import InitialState from '~/client/src/shared/stores/InitialState'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'

import ActivitiesTreeStore from '../ActivitiesTree/ActivitiesTree.store'
import ExtendedActivityInfo from '../ActivityItemContent/ExtendedActivityInfo/ExtendedActivityInfo'
import WorkflowCardLocationLabel from '../WorkflowCard/LocationLabel'
import ActivityStatusIndicator from './componets/ActivityStatusIndicator'
import ActivityThreadIndicators from './componets/ActivityThreadIndicators'
import DatesInfo from './componets/DatesInfo'
import ManpowerStatusUpdate from './componets/ManpowerStatusUpdate'
import PercentCompleteStatusUpdate from './componets/PercentCompleteStatusUpdate'

import './ActivityItemContentWithStatusUpdate.scss'

export interface IActivityItemContentProps {
  activity: Activity
  currentViewDate: Date
  sectionCompanyName?: string
  activitiesTreeStore: ActivitiesTreeStore

  statusUpdatesStore?: StatusUpdatesStore
  state?: InitialState

  displayActivityDetails(): void
  displayStatusUpdateModal(): void
}

@inject('statusUpdatesStore', 'state')
@observer
export default class ActivityItemContentWithStatusUpdate extends React.Component<IActivityItemContentProps> {
  @observable public isUnread: boolean
  private statusUpdateContainer: HTMLDivElement

  public get commonActivityStatusUpdate(): StatusUpdate {
    const { statusUpdatesStore, activity } = this.props
    return (
      (
        statusUpdatesStore.allStatusesUpdatesByActivityMap[activity.code] || []
      ).filter(su => !su.isUpdateDeleted)[0] ||
      StatusUpdate.createWithDefaults()
    )
  }

  public render() {
    const {
      activity,
      currentViewDate,
      sectionCompanyName,
      displayActivityDetails,
      displayStatusUpdateModal,
      activitiesTreeStore,
      state: { isStatusUpdateManpowerDisabled },
    } = this.props
    const { name } = activity

    const listItemTitleClassList = classList({
      'text extra-large bold ellipsis pr20': true,
      'unread-activity': this.isUnread,
    })

    return (
      <div className="activity-item-content-with-status-update col pl20 py10">
        <div className="row">
          <ActivityAssociationStatus
            className="no-grow pr10"
            activityId={activity.code}
          />
          <div className="row" onClick={displayStatusUpdateModal}>
            <DatesInfo activity={activity} />
            <ActivityStatusIndicator
              activity={activity}
              company={sectionCompanyName}
              containerClassName="row w-fit-content"
            />
            <div className="row x-end w-fit-content">
              <ActivityThreadIndicators
                activity={activity}
                currentViewDate={currentViewDate}
                commonStatusUpdate={this.commonActivityStatusUpdate}
                sectionCompanyName={sectionCompanyName}
              />
            </div>
          </div>
        </div>
        <div
          className={listItemTitleClassList}
          onClick={displayActivityDetails}
        >
          {name}
        </div>
        <div className="col y-center pr20" ref={this.setScrollContainerRef}>
          <ExtendedActivityInfo activity={activity} />
          <VGap size="10" />

          {!isStatusUpdateManpowerDisabled && !!activitiesTreeStore && (
            <ManpowerStatusUpdate
              commonStatusUpdate={this.commonActivityStatusUpdate}
              company={sectionCompanyName}
              currentViewDate={currentViewDate}
              activity={activity}
              activitiesTreeStore={activitiesTreeStore}
            />
          )}
          {!!activitiesTreeStore && (
            <PercentCompleteStatusUpdate
              commonStatusUpdate={this.commonActivityStatusUpdate}
              company={sectionCompanyName}
              currentViewDate={currentViewDate}
              activity={activity}
              activitiesTreeStore={activitiesTreeStore}
            />
          )}
          <div className="activity-location-tags">
            <WorkflowCardLocationLabel
              locations={activity.locations}
              isOneColor={false}
              shouldShowAsTag={true}
            />
          </div>
        </div>
      </div>
    )
  }

  private setScrollContainerRef = (ref: HTMLDivElement) => {
    if (this.statusUpdateContainer) {
      this.statusUpdateContainer.removeEventListener(
        'touchstart',
        this.onTouchEvent,
      )
      this.statusUpdateContainer.removeEventListener(
        'touchmove',
        this.onTouchEvent,
      )
      this.statusUpdateContainer.removeEventListener(
        'touchend',
        this.onTouchEvent,
      )
    }
    this.statusUpdateContainer = ref
    if (ref) {
      ref.addEventListener('touchstart', this.onTouchEvent, { passive: true })
      ref.addEventListener('touchmove', this.onTouchEvent, { passive: true })
      ref.addEventListener('touchend', this.onTouchEvent, { passive: true })
    }
  }

  private onTouchEvent(event: TouchEvent) {
    event.stopPropagation()
  }
}
