import { action, computed, observable } from 'mobx'

import TwoMonthsDatePickerStore, {
  TwoMonthsDatePickerMode,
} from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import ProjectDateStore, {
  DAYS_IN_WEEK,
  isBetween,
} from '~/client/src/shared/stores/ui/ProjectDate.store'

// localization: no display text to translate

export interface IDateFilters {
  startDate: Date
  daysToAdd: number
  isDateFilterActive: boolean
}

export default class DateSelectorStore {
  public readonly datePickerStates = [
    TwoMonthsDatePickerMode.ONE_DAY,
    TwoMonthsDatePickerMode.WEEK,
    TwoMonthsDatePickerMode.FLOATING_THREE_WEEKS,
    TwoMonthsDatePickerMode.MONTH,
    TwoMonthsDatePickerMode.SIX_WEEKS,
    TwoMonthsDatePickerMode.THREE_MONTHS,
    TwoMonthsDatePickerMode.YEAR,
    TwoMonthsDatePickerMode.PROJECT,
    TwoMonthsDatePickerMode.DEFAULT,
  ]

  @observable public shouldShowDatePickerOptions: boolean = false
  private today = new Date()
  private initialMode: TwoMonthsDatePickerMode

  private get endDate() {
    return this.projectDateStore.getEndDate(this.filters)
  }

  private get projectStartDate() {
    return this.shouldUseOtherDatesForFullProjectMode
      ? this.projectDateStore.getMinAvailableDate()
      : this.projectsStore.currentProjectStartDate
  }

  private get projectEndDate() {
    return this.shouldUseOtherDatesForFullProjectMode
      ? this.projectDateStore.getMaxAvailableDate()
      : this.projectsStore.currentProjectEndDate
  }

  public get datePickerMode(): TwoMonthsDatePickerMode {
    return this.datePickerStore.currentMode
  }

  public get isCurrentActive(): boolean {
    const { startDate, isDateFilterActive } = this.filters
    return isDateFilterActive && isBetween(startDate, this.endDate, this.today)
  }

  private get isOneDayInterval() {
    const { startDate } = this.filters
    return this.projectDateStore.isSameDay(startDate, this.endDate)
  }

  @computed
  public get datesButtonCaption(): string {
    const { startDate, isDateFilterActive } = this.filters
    const { isShown } = this.datePickerStore
    const {
      getDateIntervalToDisplay,
      isSameDay,
      getWeekdayMonthAndDayToDisplay,
    } = this.projectDateStore

    if (isShown || !startDate || !isDateFilterActive) {
      return Localization.translator.dates
    }

    if (isSameDay(startDate, this.endDate)) {
      return getWeekdayMonthAndDayToDisplay(startDate)
    }

    return getDateIntervalToDisplay(startDate, this.endDate)
  }

  public get canChangeDates() {
    // we might want to have read-only date again,
    // so leave this for now
    return true // ganttListState !== GanttListState.Map
  }

  public constructor(
    private readonly datePickerStore: TwoMonthsDatePickerStore,
    private readonly filters: IDateFilters,
    private readonly projectsStore: ProjectsStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly shouldUseOtherDatesForFullProjectMode: boolean,
  ) {}

  public setInitialMode(mode: TwoMonthsDatePickerMode) {
    if (this.initialMode !== mode) {
      this.setDatePickerMode(mode)
      this.initialMode = mode
    }
  }

  @action.bound
  public setDateToToday() {
    this.datePickerStore.setDates(this.today)
    const { startDate, endDate } = this.datePickerStore
    this.updateDateFilter(startDate, endDate)
  }

  @action.bound
  public setDatePickerMode(mode: TwoMonthsDatePickerMode) {
    if (!mode || this.datePickerStore.currentMode === mode) {
      return
    }

    const { PROJECT } = TwoMonthsDatePickerMode

    if (mode === PROJECT) {
      this.datePickerStore.startDate = this.projectStartDate
      this.datePickerStore.endDate = this.projectEndDate
    }
    this.datePickerStore.setMode(mode)
    const { startDate, endDate } = this.datePickerStore
    this.updateDateFilter(startDate, endDate)
    this.hideDatePickerOptions()
  }

  @action.bound
  public hideDatePickerOptions() {
    this.shouldShowDatePickerOptions = false
  }

  @action.bound
  public toggleDatePickerModesList() {
    this.shouldShowDatePickerOptions = !this.shouldShowDatePickerOptions
  }

  @action.bound
  public toggleDatePicker() {
    if (!this.canChangeDates) {
      return
    }

    const { isShown, hide } = this.datePickerStore
    if (isShown) {
      return hide()
    }

    const { isDateFilterActive, startDate } = this.filters

    const initialRange = {
      startDate: isDateFilterActive ? startDate : this.projectStartDate,
      endDate: isDateFilterActive ? this.endDate : this.projectEndDate,
    }

    this.datePickerStore.showWithOptions({
      handler: this.updateDateFilter,
      initialRange,
    })
  }

  @action.bound
  public moveDatesBack(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation()
    if (this.isOneDayInterval) {
      this.moveOneDayBack()
    } else {
      this.moveOneWeekBack()
    }
  }

  @action.bound
  public moveDatesForward(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation()
    if (this.isOneDayInterval) {
      this.moveOneDayForward()
    } else {
      this.moveOneWeekForward()
    }
  }

  private updateDateFilter = (startDate: Date, endDate: Date) => {
    if (
      !this.projectDateStore.isValidDate(startDate) ||
      !this.projectDateStore.isValidDate(endDate)
    ) {
      return
    }

    const { isSameDay, startOfDay } = this.projectDateStore
    const isFullProject =
      isSameDay(startDate, this.projectStartDate) &&
      isSameDay(endDate, this.projectEndDate)

    if (isFullProject) {
      this.filters.isDateFilterActive = false
      return
    }

    this.filters.startDate = startOfDay(startDate)
    const { mode } = this.datePickerStore
    if (mode === TwoMonthsDatePickerMode.DEFAULT) {
      this.datePickerStore.daysToAdd =
        this.projectDateStore.countDaysToDate(startDate, endDate) - 1
    }

    this.filters.daysToAdd = this.datePickerStore.daysToAdd
    this.filters.isDateFilterActive = true
  }

  private moveOneDayBack() {
    this.filters.startDate = this.projectDateStore.addDays(
      this.filters.startDate,
      -1,
    )
    this.enableDateFilter()
  }

  private moveOneWeekBack() {
    this.filters.startDate = this.projectDateStore.addDays(
      this.filters.startDate,
      -DAYS_IN_WEEK,
    )
    this.enableDateFilter()
  }

  private enableDateFilter() {
    this.filters.isDateFilterActive = true
    if (this.datePickerStore.isShown) {
      this.datePickerStore.startDate = this.filters.startDate
      this.datePickerStore.endDate = this.endDate
    }
  }

  private moveOneWeekForward() {
    this.filters.startDate = this.projectDateStore.addDays(
      this.filters.startDate,
      DAYS_IN_WEEK,
    )
    this.enableDateFilter()
  }

  private moveOneDayForward() {
    this.filters.startDate = this.projectDateStore.addDays(
      this.filters.startDate,
      1,
    )
    this.enableDateFilter()
  }
}
