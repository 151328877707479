import * as React from 'react'

import { classList } from 'react-classlist-helper'

export default function Dimmer(props) {
  const { shown, onClick, style } = props
  return (
    <div
      onClick={onClick}
      className={classList({
        dimmer: true,
        shown,
      })}
      style={style}
    />
  )
}
