import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import AvatarType from '~/client/src/shared/enums/AvatarType'
import User from '~/client/src/shared/models/User'
import ProjectRolesStore from '~/client/src/shared/stores/domain/ProjectRoles.store'
import { INTERPUNCT, NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import UserProject from '../../models/UserProject'
import CompaniesStore from '../../stores/domain/Companies.store'
import UserProjectsStore from '../../stores/domain/UserProjects.store'
import Avatar, { AvatarSize } from '../Avatar/Avatar'
import AvatarInput from '../AvatarInput/AvatarInput'
import UsernameFromUid from '../UsernameFromUid'

import './UserProfilePreview.scss'

interface IProps {
  user: User

  className?: string

  briefInfoClassName?: string
  isBriefInfoHidden?: boolean

  avatarSize?: AvatarSize
  isAvatarUpdateAllowed?: boolean
  onAvatarChange?: (newUrl: string) => void
  newAvatarUrl?: string

  shouldSplitToRows?: boolean
  shouldUseExtraLargeFont?: boolean

  isOnline?: boolean

  isUserNameClickable?: boolean

  projectRolesStore?: ProjectRolesStore
  companiesStore?: CompaniesStore
  userProjectsStore?: UserProjectsStore
  isCompact?: boolean
  withEmployeeId?: boolean
}

@inject('projectRolesStore', 'companiesStore', 'userProjectsStore')
@observer
export default class UserProfilePreview extends React.Component<IProps> {
  public static defaultProps = {
    isUserNameClickable: true,
  }

  private get user(): User {
    const usr = this.props.user || ({} as User)
    return usr.isHidden ? User.service : usr
  }

  public render() {
    const {
      avatarSize,
      className,
      isBriefInfoHidden: isInfoHidden,
      isAvatarUpdateAllowed,
      newAvatarUrl,
      onAvatarChange,
      isOnline,
      shouldUseExtraLargeFont,
      isUserNameClickable,
      shouldSplitToRows,
      withEmployeeId,
      isCompact,
    } = this.props

    const { avatarUrl, id: userId } = this.user

    const initials = User.getInitialsToDisplay(this.user)
    const displayedAvatarUrl = isAvatarUpdateAllowed ? newAvatarUrl : avatarUrl
    const avatar = isAvatarUpdateAllowed ? (
      <AvatarInput
        data={{ initials, url: displayedAvatarUrl }}
        onChange={onAvatarChange}
        avatarType={AvatarType.User}
        withEditor={true}
      />
    ) : (
      <Avatar
        src={displayedAvatarUrl}
        size={avatarSize}
        initials={initials}
        isOnline={shouldSplitToRows ? null : isOnline}
      />
    )

    if (shouldSplitToRows) {
      return this.renderProfileAsSeparateRows(avatar, this.user, isOnline)
    }

    return (
      <div
        className={classList({
          'user-profile-preview row overflow-hidden': true,
          [className]: !!className,
        })}
      >
        <div className="no-grow">{avatar}</div>
        {!isCompact && (
          <div className="user-profile-preview-additional-info overflow-hidden">
            <div className="col pl8 overflow-hidden">
              <div
                className={classList({
                  'text name-row ellipsis': true,
                  large: !shouldUseExtraLargeFont,
                  'extra-large': shouldUseExtraLargeFont,
                })}
              >
                <UsernameFromUid
                  userId={userId}
                  isClickable={isUserNameClickable}
                />
              </div>
              {!isInfoHidden && this.renderBriefInfo()}
              {withEmployeeId && this.renderEmployeeId()}
            </div>
          </div>
        )}
      </div>
    )
  }

  private renderBriefInfo(): JSX.Element {
    if (this.user.isService) return

    const { briefInfoClassName } = this.props

    return (
      <div
        className={classList({
          'text large light ellipsis brief-info-row': true,
          [briefInfoClassName]: !!briefInfoClassName,
        })}
      >
        {this.company} {INTERPUNCT} {this.roles}
      </div>
    )
  }

  private renderEmployeeId(): JSX.Element {
    return (
      this.user.employeeId && (
        <div className="text monospace light lp05">{this.user.employeeId}</div>
      )
    )
  }

  private get userProjectSettings(): UserProject {
    return this.props.userProjectsStore.getByUser(this.user)
  }

  private get company(): string {
    return this.props.companiesStore.getCompanyNameById(
      this.userProjectSettings.companyId,
    )
  }

  private get roles(): string {
    return (
      this.props.projectRolesStore.getInstancesAsStringByIds(
        this.userProjectSettings.roles,
      ) || NO_VALUE
    )
  }

  private renderProfileAsSeparateRows(
    Avatar: JSX.Element,
    user: User,
    isOnline: boolean,
  ): JSX.Element {
    return (
      <>
        {Avatar}
        <div className="user-profile-preview container">
          <span className="user-name">
            <UsernameFromUid userId={user.id} isClickable={false} />
            <span
              className={classList({
                dot: isOnline !== null,
                online: isOnline,
              })}
            ></span>
          </span>
          <div className="text brief-info">{this.company}</div>
          <div className="text brief-info">{this.roles}</div>
        </div>
      </>
    )
  }
}
