import * as React from 'react'

import { observer } from 'mobx-react'

import BaseGroupingOptions from '~/client/src/desktop/components/BaseGroupingOptions/BaseGroupingOptions'

import MaterialsGroupingOptionsStore from './MaterialsGroupingOptions.store'

interface IProps {
  store: MaterialsGroupingOptionsStore
  forceClose: boolean
  CustomHandleButton?
}

@observer
export default class MaterialsGroupingOptions extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props)
  }

  public render() {
    const { forceClose, CustomHandleButton, store } = this.props

    return (
      <BaseGroupingOptions
        store={store}
        forceClose={forceClose}
        CustomHandleButton={CustomHandleButton}
      />
    )
  }
}
