import * as React from 'react'

import { inject, observer } from 'mobx-react'

import ActivityAssignmentsStore from '~/client/src/shared/stores/domain/ActivityAssignments.store'
import ActivityFollowingsStore from '~/client/src/shared/stores/domain/ActivityFollowings.store'

import BaseEntityAssociationStatus from '../BaseEntityAssociationStatus/BaseEntityAssociationStatus'

// translated

interface IActivityAssociationStatus {
  activityId: string
  className?: string
  shouldHintShow?: boolean

  activityAssignmentsStore?: ActivityAssignmentsStore
  activityFollowingsStore?: ActivityFollowingsStore
}

@inject('activityAssignmentsStore', 'activityFollowingsStore')
@observer
export default class ActivityAssociationStatus extends React.Component<IActivityAssociationStatus> {
  public render() {
    const {
      className,
      shouldHintShow,
      activityId,
      activityAssignmentsStore,
      activityFollowingsStore,
    } = this.props

    return (
      <BaseEntityAssociationStatus
        className={className}
        entityId={activityId}
        domainAssignmentsStore={activityAssignmentsStore}
        domainFollowingsStore={activityFollowingsStore}
        shouldHintShow={shouldHintShow}
      />
    )
  }
}
