import * as React from 'react'

import { action, computed } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { AutoSizer, Column, Table } from 'react-virtualized'

import Checkbox from '~/client/src/shared/components/Checkbox'
import {
  BasicDataKeys,
  ILWFCCategory,
  ILWFCColumn,
  ILWFCRow,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import TableHeaderCell from '~/client/src/shared/components/ListWithFixedColumns/components/TableHeaderCell'
import SelectionPopUp, {
  SelectionPopUpOption,
} from '~/client/src/shared/components/SelectionPopUp/SelectionPopUp'
import SortOrder from '~/client/src/shared/enums/SortOrder'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'

import {
  BOOKING_SECTION_NAME,
  DURATION_SECTION_NAME,
  EQUIPMENT_SECTION_NAME,
  LOCATIONS_SECTION_NAME,
  MATERIALS_SECTION_NAME,
  VENDOR_SECTION_NAME,
} from '../../DeliveriesList.store'
import DeliveriesCollapsibleColumnsTableStore, {
  COLLAPSED_SECTION_WIDTH,
  DEFAULT_COLUMN_WIDTH,
} from './DeliveriesCollapsibleColumnsTable.store'
import DeliveriesCollapsedSectionsHeader from './components/DeliveriesCollapsedSectionsHeader'
import DeliveriesCollapsibleButtonsBar from './components/DeliveriesCollapsibleButtonsBar'
import DeliveriesTableDataCell from './components/DeliveriesTableDataCell'

import './DeliveriesCollapsibleColumnsTable.scss'

// localization: translated

interface IProps {
  rows: ILWFCRow[]
  columns: ILWFCColumn[]
  groupingKey: string
  columnKeyToCellRenderer: {
    [columnKey: string]: (
      value: any,
      companiesStore?: CompaniesStore,
    ) => JSX.Element
  }
  columnsWidthState?: Map<string, number>
  collapsedCategories?: Map<string, boolean>
  sortedColumnKey?: string
  sortingOrder?: SortOrder
  onColumnSort?: (columnKey: string) => void
  onCellClick?: (rowData: LWFCRowData, columnKey: string) => void
  onCategoryCheckboxToggle?: (category: ILWFCCategory) => void
  onCategoryCollapsingToggle?: (categoryId: string) => void
  getCellClassNames?: (
    columnKey: string,
    data?: LWFCRowData,
  ) => { [className: string]: boolean }
  selectAll?: () => void
  resetAll?: () => void
  selectAllTitle?: string
  scrollToRow?: number
}

const DEFAULT_ROW_HEIGHT = 47
const CATEGORY_ROW_HEIGHT = 31

@observer
export default class DeliveriesCollapsibleColumnsTable extends React.Component<IProps> {
  private readonly deliveriesTableStore: DeliveriesCollapsibleColumnsTableStore
  private listRef = null

  public constructor(props: IProps) {
    super(props)
    this.deliveriesTableStore = new DeliveriesCollapsibleColumnsTableStore()
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (
      prevProps.rows.length !== this.props.rows.length ||
      prevProps.groupingKey !== this.props.groupingKey
    ) {
      this.recomputeGridSize()
    }
  }

  public render() {
    const { rows, columns, scrollToRow } = this.props

    const {
      shouldCollapseDuration,
      shouldCollapseLocations,
      shouldCollapseEquipment,
      shouldCollapseMaterials,
      shouldCollapseVendor,
    } = this.deliveriesTableStore

    const bookingSectionClassName = `booking-section-count-${this.bookingSectionCount}`
    const locationsCountClassName = `locations-count-${this.locationsCount}`
    const equipmentsCountClassName = `equipments-count-${this.equipmentsCount}`
    const materialsCountClassName = `materials-count-${this.materialColumnsCount}`
    const shouldHideLocationSectionToggle = this.locationsCount === 0
    const shouldHideEquipmentSectionToggle = this.equipmentsCount === 0
    const shouldHideMaterialSectionToggle = this.materialColumnsCount === 0
    const shouldHideVendorSectionToggle = this.vendorColumnsCount === 0

    return (
      <>
        {this.renderSelectionPopUp()}
        <div
          className={classList({
            'deliveries-collapsible-columns-table relative full-height': true,
            [bookingSectionClassName]: true,
            [locationsCountClassName]: true,
            [equipmentsCountClassName]: true,
            [materialsCountClassName]: true,
            'collapsed-duration': shouldCollapseDuration,
            'collapsed-locations': shouldCollapseLocations,
            'collapsed-equipment': shouldCollapseEquipment,
            'collapsed-materials': shouldCollapseMaterials,
            'collapsed-vendor': shouldCollapseVendor,
          })}
        >
          <DeliveriesCollapsibleButtonsBar
            store={this.deliveriesTableStore}
            tableWidth={this.tableWidth}
            bookingSectionLeftOffset={this.bookingSectionLeftOffset}
            durationSectionLeftOffset={this.durationSectionLeftOffset}
            locationsSectionLeftOffset={this.locationsSectionLeftOffset}
            equipmentSectionLeftOffset={this.equipmentSectionLeftOffset}
            materialsSectionLeftOffset={this.materialsSectionLeftOffset}
            vendorSectionLeftOffset={this.vendorSectionLeftOffset}
            shouldHideLocationSectionToggle={shouldHideLocationSectionToggle}
            shouldHideEquipmentSectionToggle={shouldHideEquipmentSectionToggle}
            shouldHideMaterialSectionToggle={shouldHideMaterialSectionToggle}
            shouldHideVendorSectionToggle={shouldHideVendorSectionToggle}
          />
          <DeliveriesCollapsedSectionsHeader
            store={this.deliveriesTableStore}
            bookingSectionLeftOffset={this.bookingSectionLeftOffset}
            durationSectionLeftOffset={this.durationSectionLeftOffset}
            locationsSectionLeftOffset={this.locationsSectionLeftOffset}
            equipmentSectionLeftOffset={this.equipmentSectionLeftOffset}
            materialsSectionLeftOffset={this.materialsSectionLeftOffset}
            vendorSectionLeftOffset={this.vendorSectionLeftOffset}
          />
          <AutoSizer disableWidth={true} className="full-height">
            {({ height }) => (
              <Table
                scrollToIndex={scrollToRow}
                width={this.tableWidth}
                height={height}
                ref={this.setListRef}
                headerHeight={DEFAULT_ROW_HEIGHT}
                rowHeight={this.getRowHeight}
                rowCount={rows.length}
                rowStyle={{ alignItems: 'stretch' }}
                rowGetter={this.rowGetter}
                rowClassName="no-outline"
              >
                {columns.map((column, index) => {
                  if (this.disabledColumnKeys.includes(column.dataKey)) {
                    return (
                      <Column
                        width={0}
                        minWidth={0}
                        maxWidth={0}
                        key={index}
                        dataKey={column.dataKey}
                        cellRenderer={this.emptyRenderer}
                      />
                    )
                  }

                  const width = column.width || DEFAULT_COLUMN_WIDTH
                  return (
                    <Column
                      width={width}
                      minWidth={width}
                      maxWidth={width}
                      key={index}
                      {...column}
                      cellRenderer={this.renderDataCell}
                      headerRenderer={headerProps =>
                        this.renderHeaderCell({
                          ...headerProps,
                          columnIndex: index,
                        })
                      }
                    />
                  )
                })}
              </Table>
            )}
          </AutoSizer>
        </div>
      </>
    )
  }

  private emptyRenderer(): JSX.Element {
    return null
  }

  private rowGetter = ({ index }): ILWFCRow => {
    return this.props.rows[index]
  }

  private getRowHeight = ({ index }): number => {
    const row = this.props.rows[index]
    return row?.category ? CATEGORY_ROW_HEIGHT : DEFAULT_ROW_HEIGHT
  }

  private renderDataCell = ({ columnIndex, rowIndex, style }): JSX.Element => {
    const {
      rows,
      columns,
      collapsedCategories,
      columnKeyToCellRenderer,
      getCellClassNames,
      onCategoryCollapsingToggle,
      onCellClick,
      onCategoryCheckboxToggle,
    } = this.props

    return (
      <DeliveriesTableDataCell
        style={style}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        rows={rows}
        columns={columns}
        collapsedCategories={collapsedCategories}
        getCellClassNames={getCellClassNames}
        onCategoryCollapsingToggle={onCategoryCollapsingToggle}
        onCellClick={onCellClick}
        onCategoryCheckboxToggle={onCategoryCheckboxToggle}
        columnKeyToCellRenderer={columnKeyToCellRenderer}
        columnKeyToDefaultRenderer={this.columnKeyToDefaultRenderer}
      />
    )
  }

  private renderHeaderCell = ({ dataKey, label, columnIndex }): JSX.Element => {
    const { onColumnSort, sortedColumnKey, sortingOrder } = this.props
    const { toggleSelectionPopup } = this.deliveriesTableStore

    return (
      <TableHeaderCell
        dataKey={dataKey}
        label={label}
        columnIndex={columnIndex}
        toggleSelectionPopup={toggleSelectionPopup}
        onColumnSort={onColumnSort}
        sortedColumnKey={sortedColumnKey}
        sortingOrder={sortingOrder}
        isResizable={false}
      />
    )
  }

  @computed
  private get selectionPopUpOptions(): SelectionPopUpOption[] {
    return [
      {
        label: this.props.selectAllTitle,
        onClick: this.handleSelectAll,
      },
      {
        label: Localization.translator.clear,
        onClick: this.handleResetAll,
      },
    ]
  }

  private renderSelectionPopUp = (): JSX.Element => {
    return (
      <SelectionPopUp
        isDisplayed={this.deliveriesTableStore.shouldShowSelectionPopup}
        options={this.selectionPopUpOptions}
      />
    )
  }

  @action.bound
  private handleSelectAll() {
    this.props.selectAll()
    this.deliveriesTableStore.hideSelectionPopup()
  }

  @action.bound
  private handleResetAll() {
    this.props.resetAll()
    this.deliveriesTableStore.hideSelectionPopup()
  }

  private setListRef = (ref: any) => {
    this.listRef = ref
  }

  private recomputeGridSize = () => {
    this.listRef.recomputeGridSize()
  }

  private get columnKeyToDefaultRenderer(): {
    [columnKey: string]: (value: any) => JSX.Element
  } {
    return {
      [BasicDataKeys.CHECKBOX]: value => (
        <Checkbox
          className="checkbox-selector text large no-bold"
          isCentered={true}
          isChecked={value}
        />
      ),
    }
  }

  private get bookingSectionColumns(): ILWFCColumn[] {
    const { columns } = this.props
    return columns.filter(col => col.sectionName === BOOKING_SECTION_NAME)
  }

  private get durationSectionColumns(): ILWFCColumn[] {
    const { columns } = this.props
    return columns.filter(col => col.sectionName === DURATION_SECTION_NAME)
  }

  private get locationsSectionColumns(): ILWFCColumn[] {
    const { columns } = this.props
    return columns.filter(col => col.sectionName === LOCATIONS_SECTION_NAME)
  }

  private get equipmentSectionColumns(): ILWFCColumn[] {
    const { columns } = this.props
    return columns.filter(col => col.sectionName === EQUIPMENT_SECTION_NAME)
  }

  private get materialsSectionColumns(): ILWFCColumn[] {
    const { columns } = this.props
    return columns.filter(col => col.sectionName === MATERIALS_SECTION_NAME)
  }

  private get vendorSectionColumns(): ILWFCColumn[] {
    const { columns } = this.props
    return columns.filter(col => col.sectionName === VENDOR_SECTION_NAME)
  }

  private get bookingSectionCount(): number {
    return this.bookingSectionColumns.length
  }

  private get locationsCount(): number {
    return this.locationsSectionColumns.length
  }

  private get equipmentsCount(): number {
    return this.equipmentSectionColumns.length
  }

  private get materialColumnsCount(): number {
    return this.materialsSectionColumns.length
  }

  private get vendorColumnsCount(): number {
    return this.vendorSectionColumns.length
  }

  @computed
  private get tableWidth(): number {
    const columnsWidth = this.props.columns.reduce((sum, column) => {
      if (!this.disabledColumnKeys.includes(column.dataKey)) {
        sum += column.width || DEFAULT_COLUMN_WIDTH
      }
      return sum
    }, 0)

    return (
      columnsWidth +
      this.deliveriesTableStore.collapsedSectionsWidth +
      COLLAPSED_SECTION_WIDTH
    )
  }

  @computed
  private get disabledColumnKeys(): string[] {
    const {
      shouldCollapseDuration,
      shouldCollapseLocations,
      shouldCollapseEquipment,
      shouldCollapseMaterials,
      shouldCollapseVendor,
    } = this.deliveriesTableStore

    const disabledKeys: string[] = []

    if (shouldCollapseDuration) {
      const durationSectionKeys = this.durationSectionColumns.map(
        col => col.dataKey,
      )
      disabledKeys.push(...durationSectionKeys)
    }
    if (shouldCollapseLocations) {
      const locationsSectionKeys = this.locationsSectionColumns.map(
        col => col.dataKey,
      )
      disabledKeys.push(...locationsSectionKeys)
    }
    if (shouldCollapseEquipment) {
      const equipmentSectionKeys = this.equipmentSectionColumns.map(
        col => col.dataKey,
      )
      disabledKeys.push(...equipmentSectionKeys)
    }
    if (shouldCollapseMaterials) {
      const materialsSectionKeys = this.materialsSectionColumns.map(
        col => col.dataKey,
      )
      disabledKeys.push(...materialsSectionKeys)
    }
    if (shouldCollapseVendor) {
      const vendorSectionKeys = this.vendorSectionColumns.map(
        col => col.dataKey,
      )
      disabledKeys.push(...vendorSectionKeys)
    }

    return disabledKeys
  }

  private get bookingSectionLeftOffset(): number {
    return this.deliveriesTableStore.getSectionWidth(this.bookingSectionColumns)
  }

  private get durationSectionLeftOffset(): number {
    const { shouldCollapseDuration, getSectionWidth } =
      this.deliveriesTableStore

    const durationSectionWidth =
      getSectionWidth(this.durationSectionColumns) - COLLAPSED_SECTION_WIDTH
    return shouldCollapseDuration ? 0 : durationSectionWidth
  }

  private get locationsSectionLeftOffset(): number {
    const { shouldCollapseLocations, getSectionWidth } =
      this.deliveriesTableStore

    const locationsSectionWidth = getSectionWidth(this.locationsSectionColumns)
    let locationsSectionOffset = this.durationSectionLeftOffset
    locationsSectionOffset += shouldCollapseLocations
      ? COLLAPSED_SECTION_WIDTH
      : locationsSectionWidth

    return locationsSectionOffset
  }

  private get equipmentSectionLeftOffset(): number {
    const { shouldCollapseEquipment, getSectionWidth } =
      this.deliveriesTableStore

    const equipmentSectionWidth = getSectionWidth(this.equipmentSectionColumns)
    let equipmentSectionOffset = this.locationsSectionLeftOffset
    equipmentSectionOffset += shouldCollapseEquipment
      ? COLLAPSED_SECTION_WIDTH
      : equipmentSectionWidth

    return equipmentSectionOffset
  }

  private get materialsSectionLeftOffset(): number {
    const { shouldCollapseMaterials, getSectionWidth } =
      this.deliveriesTableStore

    const materialsSectionWidth = getSectionWidth(this.materialsSectionColumns)
    let materialsSectionOffset = this.equipmentSectionLeftOffset
    materialsSectionOffset += shouldCollapseMaterials
      ? COLLAPSED_SECTION_WIDTH
      : materialsSectionWidth

    return materialsSectionOffset
  }

  private get vendorSectionLeftOffset(): number {
    const { shouldCollapseVendor, getSectionWidth } = this.deliveriesTableStore

    const vendorSectionWidth = getSectionWidth(this.vendorSectionColumns)
    let vendorSectionOffset = this.materialsSectionLeftOffset
    vendorSectionOffset += shouldCollapseVendor
      ? COLLAPSED_SECTION_WIDTH
      : vendorSectionWidth

    return vendorSectionOffset
  }
}
