import { computed, observable } from 'mobx'

import {
  ContentObjectStatus,
  ContentObjectType,
  IContentObject,
  IThread,
} from '~/client/graph'
import Thread from '~/client/src/shared/models/Thread'

import Activity from './Activity'

export default class ContentObjectModel<T> {
  public id: string
  public createdAt = 0
  public updatedAt = 0
  public activityObjectId = ''
  public author: string
  @observable public status = ContentObjectStatus.Open
  public threadId: string
  public contentObjectType: ContentObjectType
  public projectId: string
  public type: T

  @computed
  public get isOpen() {
    return this.status === ContentObjectStatus.Open
  }

  public constructor(id: string, dto?: IContentObject) {
    this.id = id
    Object.assign(this, dto)
    if (dto) {
      this.createdAt = new Date(dto.createdAt).getTime()
      this.updatedAt = new Date(dto.updatedAt).getTime()
    }
  }

  public close(): void {
    this.status = ContentObjectStatus.Closed
  }

  public toggle(): void {
    this.status =
      this.status === ContentObjectStatus.Open
        ? ContentObjectStatus.Closed
        : ContentObjectStatus.Open
  }

  public setThread(thread: Thread | IThread): void {
    if (!thread) {
      return
    }
    this.threadId = thread.id
  }

  public belongsToActivity(a: Activity) {
    if (!a) {
      return false
    }

    return this.activityObjectId === a.code
  }
}
