import * as React from 'react'

import { observer } from 'mobx-react'

import { IPermitTypeField } from '~/client/graph'
import StruxhubTextValueSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import SitePermitCreationFormStore, {
  FormDropdownType,
} from '../../SitePermitCreationForm.store'
import PermitBaseFormField from './PermitBaseFormField'

interface IProps {
  typeField: IPermitTypeField
  isViewMode: boolean
  store: SitePermitCreationFormStore

  fieldsStore: IPermitFieldsStore

  tableId?: string
  tableRowIndex?: number
}

@observer
export default class PermitFormSelectField extends React.Component<IProps> {
  public render() {
    const { isViewMode, typeField, fieldsStore, tableId, tableRowIndex } =
      this.props
    const { caption, isMandatory } = typeField

    const isChanged = fieldsStore.isFieldChanged(
      typeField.id,
      null,
      tableId,
      tableRowIndex,
    )

    return (
      <PermitBaseFormField
        typeField={typeField}
        isViewMode={isViewMode}
        viewModeElements={this.viewModeElements}
      >
        <StruxhubTextValueSelector
          label={caption}
          isRequired={isMandatory}
          isChanged={isChanged}
          value={this.fieldValues?.join(', ') || EMPTY_STRING}
          onClick={this.onFieldClick}
        />
      </PermitBaseFormField>
    )
  }

  private get viewModeElements(): JSX.Element[] {
    return this.fieldValues
      ?.filter(p => p)
      .map((val, idx) => (
        <div key={idx} className="text large word-break-all">
          {val}
        </div>
      ))
  }

  private get fieldValues(): string[] {
    const { typeField, fieldsStore, tableId, tableRowIndex } = this.props
    const values = fieldsStore.getFieldValues<string>(
      typeField.id,
      tableId,
      tableRowIndex,
    )

    return values?.length ? values : ['']
  }

  private onFieldClick = () => {
    const { store, typeField, tableId, tableRowIndex } = this.props
    const {
      setSelectedField,
      setSelectedFieldDropdownType,
      setSelectedOnChangeHandler,
    } = store

    setSelectedField(typeField, 0, tableId, tableRowIndex)
    setSelectedFieldDropdownType(FormDropdownType.DEFAULT)
    setSelectedOnChangeHandler(this.onChange.bind(null))
  }

  private onChange = (newValue: string) => {
    const { typeField, fieldsStore, tableId, tableRowIndex } = this.props
    const { changeFieldValue, changeTableFieldValue } = fieldsStore

    if (typeField.isMultiple) {
      return this.onMultiSelectChange(newValue)
    }
    if (!this.isTableField) {
      return changeFieldValue(typeField, 0, newValue)
    }
    changeTableFieldValue(tableId, tableRowIndex, typeField, 0, newValue)
  }

  private onMultiSelectChange(newValue: string) {
    if (!this.isTableField) {
      const { typeField, fieldsStore } = this.props
      fieldsStore.changeMultiSelectorValue(typeField, newValue)
    }
  }

  private get isTableField(): boolean {
    return !!this.props.tableId
  }
}
