import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DetailListItem from '~/client/src/shared/components/DetailListItem'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Activity from '~/client/src/shared/models/Activity'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

// translated

interface IProps {
  activity: Activity
  projectDateStore?: ProjectDateStore
  statusUpdatesStore?: StatusUpdatesStore
}

@inject('projectDateStore', 'statusUpdatesStore')
@observer
export default class DatesDetails extends React.Component<IProps> {
  public render() {
    const { activity, projectDateStore, statusUpdatesStore } = this.props
    const { didFinish, didStart, startDate, finishDate } = activity
    const { getMonthDayAndYearToDisplay } = projectDateStore

    const start = getMonthDayAndYearToDisplay(startDate)
    const finish = getMonthDayAndYearToDisplay(
      finishDate(projectDateStore, statusUpdatesStore),
    )

    return (
      <DetailListItem category={Localization.translator.dates}>
        <div className="row y-start">
          <div className="col no-grow">
            <div style={{ width: 'max-content' }} className="text large">
              <div>{Localization.translator.start_noun}:</div>
              <div>{Localization.translator.finish_noun}:</div>
            </div>
          </div>
          <div className="col text large px5">
            <>
              <div>
                {start}&nbsp;
                {didStart && (
                  <span className="text large primary-blue ">A</span>
                )}
              </div>
              <div>
                {finish}&nbsp;
                {didFinish && (
                  <span className="text large primary-blue">A</span>
                )}
              </div>
            </>
          </div>
        </div>
      </DetailListItem>
    )
  }
}
