import * as React from 'react'

import { inject, observer } from 'mobx-react'

import BaseGroupingOptions from '~/client/src/desktop/components/BaseGroupingOptions/BaseGroupingOptions'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import HierarchyConfigurationStore from '~/client/src/shared/stores/domain/HierarchyConfiguration.store'

import ActivityGroupingOptionsStore from './ActivityGroupingOptions.store'

interface IGroupingOptions {
  onShowChanged: (isShown: boolean) => void
  forceClose: boolean

  onApplyClick?: () => void
  eventsStore?: DesktopEventStore
  hierarchyConfigurationStore?: HierarchyConfigurationStore
}

@inject('eventsStore', 'hierarchyConfigurationStore')
@observer
export default class ActivityGroupingOptions extends React.Component<IGroupingOptions> {
  private readonly store: ActivityGroupingOptionsStore

  public constructor(props: IGroupingOptions) {
    super(props)

    this.store = new ActivityGroupingOptionsStore(
      props.eventsStore,
      props.hierarchyConfigurationStore,
      props.onShowChanged,
      props.onApplyClick,
    )
  }

  public render() {
    return (
      <BaseGroupingOptions
        store={this.store}
        forceClose={this.props.forceClose}
      />
    )
  }
}
