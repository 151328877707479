import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import * as Icons from '~/client/src/shared/components/Icons'
import SitemapDeliveryAttributes from '~/client/src/shared/components/SitemapDeliveryAttributes/SitemapDeliveryAttributes'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import SitemapType from '~/client/src/shared/enums/SitemapType'
import Sitemap from '~/client/src/shared/models/Sitemap'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'

interface IProps {
  sitemapsStore?: SitemapsStore
  basemapsStore?: BasemapsStore
  state?: DesktopInitialState
  sitemapId: string
  title?: string
  isDeletingDisabled?: boolean
  isExtraSmall?: boolean
  onChange(sitemapId: string): void
  onNameClick(): void
  onNewSitemapUpload(type: SitemapType): void
}

const assignMap = 'Assign Map'
const deliveryMaps = 'DELIVERY MAPS'
const close = 'Close'
const siteMaps = 'Site Maps'
const active = 'Active'
const inactive = 'Inactive'
const uploadNewDeliverySiteMap = 'Upload new delivery site map'
const accessMap = (name: string) => `${name} Access Maps`
const uploadNewAccessMap = (name: string) =>
  `Upload new ${name?.toLowerCase()} access map`

@inject('sitemapsStore', 'basemapsStore', 'state')
@observer
export default class SitemapSelector extends React.Component<IProps> {
  @observable private isModalShown: boolean = false

  @computed
  private get selectedSitemap() {
    const { sitemapId, sitemapsStore } = this.props
    return sitemapsStore.byId.get(sitemapId)
  }

  @computed
  private get selectedBasemap() {
    return this.selectedSitemap && this.getBasemap(this.selectedSitemap)
  }

  public render() {
    const { title, isDeletingDisabled, isExtraSmall, onNameClick } = this.props

    return (
      <div className="sitemap-selector ml10">
        <div className="row y-start">
          <div className="col main-block no-grow">
            {!!title && (
              <div className="text large bb-light-grey pb3 mb10">{title}</div>
            )}
            {this.selectedBasemap && (
              <div className="row y-start">
                <div className="text large" onClick={onNameClick}>
                  {this.selectedBasemap.fileName}
                </div>
                <div>
                  <img
                    src={this.selectedSitemap.filledImage}
                    className={classList({
                      'file-preview small ba-light-grey': true,
                      'extra-small': isExtraSmall,
                    })}
                    onClick={onNameClick}
                  />
                </div>
                {!isDeletingDisabled && (
                  <Icon
                    className="bg-grey-scale-light br-rounded pointer text white"
                    icon={IconNames.SMALL_CROSS}
                    onClick={this.deselectSitemap}
                  />
                )}
              </div>
            )}
          </div>
          <div
            className="no-grow text blue-highlight large nowrap ml15"
            onClick={this.openModal}
          >
            {assignMap}
          </div>
        </div>
        {this.renderModal()}
      </div>
    )
  }

  private renderModal() {
    return (
      this.isModalShown && (
        <div className="sitemap-selector-modal-dimmer full-height full-width">
          <div className="sitemap-selector-modal px30 py20">
            <div className="row y-top">
              <div className="text bold extra-large">{deliveryMaps}</div>
              <Icons.Cross
                className="no-grow pointer"
                onClick={this.hideModal}
              />
            </div>
            {this.renderDefaultSitemapsBlock()}
            {this.renderGateSitemapsBlock()}
            {this.renderZoneSitemapsBlock()}
            <div className="row x-end pt20">
              <div
                onClick={this.hideModal}
                className="text bold primary-blue extra-large no-grow pointer"
              >
                {close}
              </div>
            </div>
          </div>
        </div>
      )
    )
  }

  private renderDefaultSitemapsBlock() {
    const { defaultSitemaps } = this.props.sitemapsStore
    const { maps } = this.props.state.delivery.configurations

    return (
      <>
        <div className="pb3 bb-light-grey">{siteMaps}</div>
        {defaultSitemaps.map(sitemap => {
          const isDeliveriesSitemap = maps.some(s => s.sitemapId === sitemap.id)
          return (
            <div key={sitemap.id} className="row py3">
              <div
                className="text large light underline pointer"
                onClick={this.onSitemapNameClick.bind(this, sitemap)}
              >
                {sitemap.name}
              </div>
              <div className="text large light">
                {this.getBasemap(sitemap).fileName}
              </div>
              <div
                className={classList({
                  'text large right underline': true,
                  light: !isDeliveriesSitemap,
                  'primary-blue': isDeliveriesSitemap,
                })}
              >
                {isDeliveriesSitemap ? active : inactive}
              </div>
            </div>
          )
        })}
        <div
          className="text large light underline pointer inline-block"
          onClick={this.onNewSitemapClick.bind(this, SitemapType.Default)}
        >
          {uploadNewDeliverySiteMap}
        </div>
      </>
    )
  }

  private renderGateSitemapsBlock() {
    const { gateSitemaps } = this.props.sitemapsStore
    const gateName = this.props.state.getDeliveryFieldName(FieldIds.GATE)

    return (
      <>
        <div className="pb3 mt20 bb-light-grey">{accessMap(gateName)}</div>
        {this.renderDeliveryDtoSitemapsBlock(gateSitemaps)}
        <div
          className="text large light underline pointer inline-block"
          onClick={this.onNewSitemapClick.bind(this, SitemapType.Gate)}
        >
          {uploadNewAccessMap(gateName)}
        </div>
      </>
    )
  }

  private renderZoneSitemapsBlock() {
    const { zoneSitemaps } = this.props.sitemapsStore
    const zoneName = this.props.state.getDeliveryFieldName(FieldIds.ZONE)

    return (
      <>
        <div className="pb3 mt20 bb-light-grey">{accessMap(zoneName)}</div>
        {this.renderDeliveryDtoSitemapsBlock(zoneSitemaps)}
        <div
          className="text large light underline pointer inline-block"
          onClick={this.onNewSitemapClick.bind(this, SitemapType.Zone)}
        >
          {uploadNewAccessMap(zoneName)}
        </div>
      </>
    )
  }

  private renderDeliveryDtoSitemapsBlock(sitemaps: Sitemap[]) {
    return sitemaps.map(sitemap => (
      <div key={sitemap.id} className="row py3">
        <div
          className="text large light underline pointer"
          onClick={this.onSitemapNameClick.bind(this, sitemap)}
        >
          {sitemap.name}
        </div>
        <div className="text large light">
          {this.getBasemap(sitemap).fileName}
        </div>
        <div>
          <SitemapDeliveryAttributes
            sitemap={sitemap}
            className="text large right"
          />
        </div>
      </div>
    ))
  }

  private getBasemap(sitemap: Sitemap) {
    const { basemapsStore } = this.props
    return basemapsStore.byId.get(sitemap.basemapId)
  }

  private onNewSitemapClick(type: SitemapType) {
    this.props.onNewSitemapUpload(type)
    this.hideModal()
  }

  private onSitemapNameClick(sitemap: Sitemap) {
    this.props.onChange(sitemap.id)
    this.hideModal()
  }

  private deselectSitemap = () => {
    this.props.onChange(null)
  }

  @action.bound
  private openModal() {
    this.isModalShown = true
  }

  @action.bound
  private hideModal() {
    this.isModalShown = false
  }
}
