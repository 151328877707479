import * as React from 'react'

import * as TagIcon from '~/client/src/shared/components/TagIcon'
import { TagType } from '~/client/src/shared/enums/TagType'

export default function TagIconByTagType(props: {
  tagType: TagType
  className?: string
  size?: number
}): JSX.Element {
  const { tagType, className = '', size } = props

  switch (tagType) {
    case TagType.Role:
    case TagType.GlobalAndProjectSpecificRole:
      return <TagIcon.Role className={className} size={size} />
    case TagType.Team:
      return <TagIcon.Team className={className} size={size} />
    case TagType.DefaultTeam:
      return <TagIcon.DefaultTeam className={className} size={size} />
    case TagType.Trade:
      return <TagIcon.Trade className={className} size={size} />
    case TagType.Company:
      return <TagIcon.Company className={className} size={size} />
    case TagType.User:
      return <TagIcon.User className={className} size={size} />
    case TagType.AccountType:
      return (
        <TagIcon.User
          className={`br-rounded bg-palette-brand-lighter pa3 ${className}`}
          size={size}
        />
      )
    default:
      return null
  }
}
