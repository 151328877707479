import ProjectSetUpPage from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPages'

const setUpPagesNames = {
  [ProjectSetUpPage.PROJECT_DETAILS]: 'project',
  [ProjectSetUpPage.SCHEDULE_UPLOAD]: 'schedule',
  [ProjectSetUpPage.SCHEDULE_COLUMNS]: 'activityFilters',
  [ProjectSetUpPage.MOBILE_CONFIGURATION]: 'tracking',
  [ProjectSetUpPage.USER_DIRECTORY]: 'teamList',
  [ProjectSetUpPage.PRESENTATION_MODE]: 'presentation-mode',
  [ProjectSetUpPage.DELIVERY_WORKFLOWS]: 'workflow',
  [ProjectSetUpPage.ON_TIME_THRESHOLDS]: 'sitemaps',
  [ProjectSetUpPage.QR_SCANNERS_SETUP]: 'qrScannersSetup',
  [ProjectSetUpPage.TEAM_ASSIGNMENT]: 'teamAssignment',
  [ProjectSetUpPage.APPS_CONNECTION]: 'connectApps',
  [ProjectSetUpPage.INTEGRATIONS]: 'integrations',
}

export default setUpPagesNames
