import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'

import STXCompanyLinkIndicator from './STXCompanyLinkIndicator'
import STXCompanyLinkerStore from './STXCompanyLinker.store'

interface IProps {
  companyId: string

  companiesStore?: CompaniesStore
}

// localization: translated

@inject('companiesStore')
@observer
export default class STXCompanyLinker extends React.Component<IProps> {
  private readonly store: STXCompanyLinkerStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new STXCompanyLinkerStore(
      props.companyId,
      props.companiesStore,
    )
  }

  public componentDidMount() {
    this.store.init()
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (this.props.companyId !== prevProps.companyId) {
      this.store.setCompanyId(this.props.companyId)
      this.store.init()
    }
  }

  public render() {
    if (
      this.store.isLinkedSTXCompanyBeingFetched ||
      this.store.isLinkedSTXCompanyBeingUnlinked
    ) {
      return this.renderLoader()
    }

    const { companyId } = this.props
    const {
      unlink,
      isLinkingModalShown,
      linkedSTXCompany,
      isCompanyLinkedWithSTXCompany,
    } = this.store

    return (
      <>
        {isLinkingModalShown && this.renderLinkingModal()}
        <STXCompanyLinkIndicator companyId={companyId} />
        <div className="row ml8">
          {isCompanyLinkedWithSTXCompany && (
            <span className="text large pr8">
              {linkedSTXCompany?.name ||
                Localization.translator.unknownDeletedObject}
            </span>
          )}
          {this.renderLinkControl()}
          {isCompanyLinkedWithSTXCompany && (
            <Icons.Unlink
              title={Localization.translator.unlink_verb}
              className="row no-grow w16 ml8 pointer"
              onClick={unlink}
            />
          )}
        </div>
      </>
    )
  }

  private renderLinkingModal(): JSX.Element {
    const {
      isModalLoading: isLoading,
      hideLinkingModal,
      isLinkingModalShown,
    } = this.store

    const content = isLoading ? (
      <Loader hint={Localization.translator.loading} />
    ) : (
      this.renderSTXCompaniesList()
    )

    return (
      <MenuCloser
        className="no-grow"
        closeMenu={hideLinkingModal}
        isOpen={isLinkingModalShown}
      >
        <div className="absolute col min-height130 max-height360 mw240 bg-white ba-light-cool-grey brada4 beautiful-shadow z-index-1">
          {content}
        </div>
      </MenuCloser>
    )
  }

  private renderSTXCompaniesList(): JSX.Element {
    const {
      link,
      linkedSTXCompany,
      projectCompanyByStx,
      stxCompaniesToDisplay,
    } = this.store

    const { _brandShortName: brandShortName_text, companies: companies_text } =
      Localization.translator

    return (
      <>
        <div className="pa5 text large bold bb-light-input-border">
          {`${brandShortName_text} ${companies_text} `}
        </div>

        {this.renderSearchBar()}

        <div className="scrollable">
          {stxCompaniesToDisplay.map(stxCompany => (
            <div
              key={stxCompany.id}
              className={classList({
                'row px10 py5 highlighted-hover': true,
                'inactive-element': !!projectCompanyByStx[stxCompany.id],
              })}
              onClick={link.bind(null, stxCompany.id)}
            >
              <span
                className={classList({
                  'text large nowrap text-ellipsis default-cursor': true,
                  'blue bold': linkedSTXCompany?.id === stxCompany.id,
                })}
              >
                {stxCompany.name}
              </span>
            </div>
          ))}
        </div>
      </>
    )
  }

  private renderSearchBar(): JSX.Element {
    const { searchKey, resetSearchKey } = this.store

    return (
      <div className="px5 pb5 bb-light-input-border">
        <StruxhubInput
          isMinimalisticMode={true}
          value={searchKey}
          placeholder={Localization.translator.search}
          onChange={this.onSearch}
          customRightIcon={
            <Icons.CrossGrey
              className="pointer row no-grow"
              onClick={resetSearchKey}
            />
          }
        />
      </div>
    )
  }

  private renderLinkControl(): JSX.Element {
    const { isCompanyLinkedWithSTXCompany } = this.store

    return (
      <span
        className="text large underline pointer blue no-white-space-wrap no-grow"
        onClick={this.store.handleLinkClick}
      >
        {isCompanyLinkedWithSTXCompany
          ? Localization.translator.re_link_verb
          : Localization.translator.link_verb}
      </span>
    )
  }

  private renderLoader(): JSX.Element {
    return (
      <Loader
        className="row no-grow"
        spinnerClassName="mr8"
        hint={`${
          this.store.isLinkedSTXCompanyBeingUnlinked
            ? Localization.translator.unlinking
            : Localization.translator.loading
        }...`}
      />
    )
  }

  @action.bound
  private onSearch(event: React.ChangeEvent<HTMLInputElement>) {
    this.store.setSearchKey(event.target.value)
  }
}
