import { action, computed } from 'mobx'

import DesktopCalendarStore from '~/client/src/desktop/components/DesktopCalendar/DesktopCalendar.store'
import DesktopCalendarColumn from '~/client/src/desktop/components/DesktopCalendar/DesktopCalendarColumn'
import DesktopCalendarTotalBarItem from '~/client/src/desktop/components/DesktopCalendar/DesktopCalendarTotalBarItem'
import { TwoMonthsDatePickerMode } from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import CalendarPlaceholderStore from '~/client/src/shared/components/CalendarDayView/components/CalendarPlaceholder.store'
import CalendarEvent, {
  CalendarEventEntityType,
  CalendarEventLabelType,
  ICalendarDates,
} from '~/client/src/shared/models/CalendarEvent'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import { ICollectionItem } from '~/client/src/shared/stores/domain/Calendars.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import { DEFAULT_TIME_INTERVAL } from '~/client/src/shared/stores/ui/CalendarView.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import EventStyleCssAdapter from '~/client/src/shared/utils/EventStyleCssAdapter'

import LogisticsStore from '../../Logistics.store'
import LogisticsListStore from '../LogisticsList/LogisticsList.store'

import Colors from '~/client/src/shared/theme.module.scss'

const HIGHLIGHTED_EVENT_CLASS_NAME = 'highlighted-event'
const EMPTY_CLASS_NAME = ''

export default class DesktopLogisticsCalendarStore extends DesktopCalendarStore {
  public readonly eventEntityType = CalendarEventEntityType.Form

  public constructor(
    private readonly logisticsStore: LogisticsStore,
    private readonly logisticsListStore: LogisticsListStore,
    private readonly sitePermitsStore: SitePermitsStore,
    protected readonly calendarPlaceholderStore: CalendarPlaceholderStore,
    protected readonly projectDateStore: ProjectDateStore,
    protected readonly companiesStore: CompaniesStore,
    protected readonly permitTypesStore: PermitTypesStore,
    protected readonly locationAttributesStore: LocationAttributesStore,
    protected readonly isPermitOnly?: boolean,
  ) {
    super(calendarPlaceholderStore, projectDateStore, companiesStore)
    this.setCollection()
  }

  public get startDate(): Date {
    return this.logisticsStore.startDate
  }

  public get endDate(): Date {
    return this.logisticsStore.endDate
  }

  public get calendarEventDates(): ICalendarDates {
    return {
      startDate: this.editableEventStartDate,
      endDate: this.editableEventEndDate,
    }
  }

  @computed
  public get events(): CalendarEvent[] {
    if (!this.isPermitOnly) {
      return []
    }

    const { displayedPermitId } = this.logisticsStore

    return this._filteredCollection.map(permit => {
      const location =
        this.locationAttributesStore.getLocationBySiteLocationObj(
          permit.locations[0],
        )

      const eventStyle = permit.getEventStyleByColor(
        location?.color || Colors.primary20,
      )
      const cssAdapter = new EventStyleCssAdapter(eventStyle)
      const isEventHighlighted = displayedPermitId === permit.id

      return CalendarEvent.createEvent({
        startDate: new Date(permit.startDate),
        endDate: new Date(permit.endDate),
        dataId: permit.id,
        projectDateStore: this.projectDateStore,
        entityType: this.eventEntityType,
        labelType: CalendarEventLabelType.Default,
        styles: cssAdapter.getStyles(),
        className: isEventHighlighted
          ? HIGHLIGHTED_EVENT_CLASS_NAME
          : EMPTY_CLASS_NAME,
        data: permit,
        label: permit.getCaption(this.permitTypesStore),
        isActive: isEventHighlighted,
        isAllDay: permit.isAllDay,
      })
    })
  }

  @computed
  public get calendarColumns(): DesktopCalendarColumn[] {
    const { startDate, endDate, selectedDatePickerMode } = this.logisticsStore

    switch (selectedDatePickerMode) {
      case TwoMonthsDatePickerMode.ONE_DAY:
      case TwoMonthsDatePickerMode.WEEK:
      case TwoMonthsDatePickerMode.FIXED_WEEK:
      case TwoMonthsDatePickerMode.MONTH:
        const visibleDays = this.projectDateStore.getDaysInInterval(
          startDate,
          endDate,
        )

        return visibleDays.map(date =>
          DesktopCalendarColumn.createDateColumn(date, this.projectDateStore),
        )
    }
  }

  public get timeInterval(): number {
    return DEFAULT_TIME_INTERVAL
  }

  public get filteredAttributes(): LocationAttributeBase[] {
    return []
  }

  protected get filteredCollection(): ICollectionItem[] {
    return []
  }

  // take advantage of list filters rather than implement individual ones for this particular view
  @computed
  private get _filteredCollection() {
    return this.logisticsListStore.filteredCollection
      .map(lItem => this.sitePermitsStore.getFormById(lItem.entityId))
      .filter(f => !!f)
  }

  protected getTotalBarItems(): DesktopCalendarTotalBarItem[] {
    return []
  }

  public setShowingOfAllZoneColumns = () => {
    return
  }

  @action.bound
  public setCalendarEventDates(startDate: Date, endDate: Date) {
    this.editableEventStartDate = startDate
    this.editableEventEndDate = endDate
  }

  @action.bound
  public onEventDateChange(
    event: CalendarEvent,
    startDate: Date,
    endDate: Date,
  ) {
    this.setCalendarEventDates(startDate, endDate)

    if (event.isNew) {
      const newEvent = this.getNewEvent(this.eventEntityType)

      event.dateInterval.startDate = newEvent.startDate
      event.dateInterval.endDate = newEvent.endDate
      event.isAllDay = newEvent.isAllDay

      this.calendarPlaceholderStore.setEventPlaceholder(newEvent)
    } else {
      this.logisticsStore.openDateMoveConfirmModal()

      if (this.calendarPlaceholderStore.eventPlaceholder) {
        this.calendarPlaceholderStore.resetEventPlaceholder()
      }
    }
  }

  @action.bound
  public onNewEventCreate(startDate?: Date, endDate?: Date) {
    this.setCalendarEventDates(startDate, endDate)

    this.logisticsStore.hidePermitDialog()
    this.logisticsStore.openPermitCreationForm()
  }
}
