import React from 'react'

import { action } from 'mobx'
import { observer } from 'mobx-react'
import { classList, toggleClass } from 'react-classlist-helper'

import { DeliveryListFilterType } from '~/client/graph'
import CompactGroupByPicker, {
  IGroupByOption,
} from '~/client/src/shared/components/CompactGroupByPicker/CompactGroupByFilter'
import DayBar from '~/client/src/shared/components/DayBar'
import DeliveriesViewStore, {
  DeliveryPageViewMode,
} from '~/client/src/shared/components/Deliveries/DeliveriesView.store'
import CompactDeliveryFilter from '~/client/src/shared/components/Deliveries/components/CompactDeliveryFilter/CompactDeliveryFilter'
import * as Icons from '~/client/src/shared/components/Icons'
import { Header } from '~/client/src/shared/components/Layout'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import ViewSelector from '~/client/src/shared/components/ViewSelector/ViewSelector'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { getDeliveryGroupingDisplayName } from '~/client/src/shared/localization/enumDisplayTexts'
import WeatherForecastsStore from '~/client/src/shared/stores/domain/WeatherForecasts.store'
import DeliveryFilterStore from '~/client/src/shared/stores/ui/DeliveryFilter.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import WeatherForecastBar from '../../WeatherForecastBar/WeatherForecastBar'

const ARROW_HEIGHT = 15

interface IProps {
  currentStartDate: Date

  deliveriesViewStore: DeliveriesViewStore
  deliveryFilterStore: DeliveryFilterStore
  weatherForecastsStore: WeatherForecastsStore
  projectDateStore: ProjectDateStore

  clickOnMenuIcon?: () => void
}

@observer
export default class DeliveryHeader extends React.Component<IProps> {
  private get viewModes() {
    return [
      {
        mode: DeliveryPageViewMode.Calendar,
        Icon: Icons.CalendarGrey,
      },
      {
        mode: DeliveryPageViewMode.List,
        Icon: Icons.List,
      },
      {
        mode: DeliveryPageViewMode.Map,
        Icon: Icons.Sitemap,
        isDisabled:
          !this.props.deliveriesViewStore.hasDeliveriesSitemaps &&
          !this.props.deliveriesViewStore.hasDeliveriesGlobeViews,
      },
    ]
  }

  public render() {
    const {
      prevDate,
      nextDate,
      currentStartDate,
      currentEndDate,
      setDate,
      isActivityMode,
      isListMode,
      setViewMode,
      selectedViewMode,
      isCalendarMode,
    } = this.props.deliveriesViewStore

    return (
      <Header className="bb-light-grey">
        <div className="row bb-light-grey px20 py5">
          <DayBar
            className={toggleClass('text large center', isActivityMode)}
            leftClicked={prevDate}
            rightClicked={nextDate}
            isArrowsHidden={true}
            shouldIconRender={false}
            arrowsHeight={ARROW_HEIGHT}
            currentStartDate={currentStartDate}
            currentEndDate={currentEndDate}
            setDate={setDate}
            isOneDayMode={!isListMode}
          />
          {!isActivityMode && (
            <ViewSelector
              viewModes={this.viewModes}
              selectViewMode={setViewMode}
              selectedViewMode={selectedViewMode}
            />
          )}
        </div>
        {isCalendarMode && (
          <WeatherForecastBar
            className="row nowrap bg-palette-brand-lightest bb-light-grey"
            date={currentStartDate}
          />
        )}
        {!isActivityMode && this.deliveriesFiltersHeader}
      </Header>
    )
  }

  private get deliveriesFiltersHeader() {
    const { deliveriesViewStore, deliveryFilterStore } = this.props
    const {
      selectedGroupByOption,
      isFiltersModalOpen,
      isGroupByFilterShown,
      isFollowingFilterActive,
      isMyCompanyFilterActive,
      selectGroupBy,
      toggleFilters,
      toggleFollowing,
      toggleMyCompany,
      updateFilters,
      deliveryFilterCollection,
    } = deliveriesViewStore

    const iconClassName = 'mx5 deliveries-filters-header-filter-icon'
    const filterClassName =
      'deliveries-filters-header-filter bg-white ba-grey-scale-light inline-flex vertical-align-middle pa5 brada5 mw-fit-content'

    return (
      <div className="deliveries-filters-header">
        <CompactGroupByPicker
          isShown={isGroupByFilterShown}
          selectedMode={selectedGroupByOption}
          onHide={this.toggleGroupBy}
          onGroupByChange={selectGroupBy}
          groupByOptions={this.groupByOptions}
        />
        <CompactDeliveryFilter
          store={deliveryFilterStore}
          onClose={toggleFilters}
          isShown={isFiltersModalOpen}
          onApply={updateFilters}
          selectedOptionsCount={this.selectedOptionsCount}
          isMyCompanyFilterActive={isMyCompanyFilterActive}
          toggleMyCompany={toggleMyCompany}
          toggleFollowing={toggleFollowing}
          isFollowingFilterActive={isFollowingFilterActive}
          filteredCollection={deliveryFilterCollection}
        />
        <div className="deliveries-filters-header-inner-scroller full-height full-width nowrap relative bg-palette-brand-lightest py6">
          <div
            className={`${filterClassName} ml12 mr5`}
            onClick={toggleFilters}
          >
            {this.renderFilterIcon()}
            {Localization.translator.filters}
          </div>
          <div
            className={classList({
              [filterClassName]: true,
              mx5: true,
              active: isFollowingFilterActive,
            })}
            onClick={toggleFollowing}
          >
            <Icons.UnfilledStarInactive className={iconClassName} />
            {Localization.translator.following}
          </div>
          <div
            className={classList({
              [filterClassName]: true,
              mx5: true,
              active: isMyCompanyFilterActive,
            })}
            onClick={toggleMyCompany}
          >
            <Icons.CompanyCompact className={`${iconClassName} company-icon`} />
            {Localization.translator.myCompany}
          </div>
          <div className="deliveries-filters-header-separator inline-flex vertical-align-middle mx5 br-palette-grey" />
          <div
            className={`${filterClassName} ml5 mr12`}
            onClick={this.toggleGroupBy}
          >
            {getDeliveryGroupingDisplayName(selectedGroupByOption)}
          </div>
        </div>
      </div>
    )
  }

  private renderFilterIcon = () => {
    return this.selectedOptionsCount ? (
      <div className="delivery-filter-count-label brada4 row x-center px4 mx5">
        {this.selectedOptionsCount}
      </div>
    ) : (
      <Icons.Filter className="filters-icon mx5 deliveries-filters-header-filter-icon" />
    )
  }

  private get selectedOptionsCount(): number {
    let fitlersCount = 0
    this.props.deliveryFilterStore.filtersGroups.forEach(filtersGroup =>
      filtersGroup.forEach(filter => {
        fitlersCount += filter.selectedOptions.size
      }),
    )
    return fitlersCount
  }

  private get groupByOptions(): IGroupByOption[] {
    const noneOption: IGroupByOption = {
      groupByType: DeliveryListFilterType.None,
      shouldRenderSeparator: true,
      title: getDeliveryGroupingDisplayName(DeliveryListFilterType.None),
    }

    const contactOptions: IGroupByOption[] = [
      {
        title: getDeliveryGroupingDisplayName(DeliveryListFilterType.Company),
        groupByType: DeliveryListFilterType.Company,
        icon: (
          <Icons.CompanyCompact className="no-grow mr10 icon-holder company-icon" />
        ),
      },
      {
        title: getDeliveryGroupingDisplayName(
          DeliveryListFilterType.OnSiteContact,
        ),
        groupByType: DeliveryListFilterType.OnSiteContact,
        icon: <Icons.User className="no-grow mr10 icon-holder person-icon" />,
        shouldRenderSeparator: true,
      },
    ]

    const locationOptions: IGroupByOption[] = [
      {
        title: getDeliveryGroupingDisplayName(DeliveryListFilterType.Lbs),
        groupByType: DeliveryListFilterType.Lbs,
        icon: (
          <Icons.Hierarchy className="no-grow mr10 icon-holder hierarchy-icon" />
        ),
      },
      {
        title: getDeliveryGroupingDisplayName(DeliveryListFilterType.Building),
        groupByType: DeliveryListFilterType.Building,
        icon: (
          <Icons.CompactBuilding className="no-grow mr10 icon-holder building-icon" />
        ),
      },
      {
        title: getDeliveryGroupingDisplayName(DeliveryListFilterType.Zone),
        groupByType: DeliveryListFilterType.Zone,
        icon: <TagIcon.Zone className="no-grow mr10 icon-holder common-icon" />,
      },
      {
        title: getDeliveryGroupingDisplayName(DeliveryListFilterType.Route),
        groupByType: DeliveryListFilterType.Route,
        icon: (
          <TagIcon.Route className="no-grow mr10 icon-holder common-icon" />
        ),
      },
      {
        title: getDeliveryGroupingDisplayName(DeliveryListFilterType.Gate),
        groupByType: DeliveryListFilterType.Gate,
        icon: <TagIcon.Gate className="no-grow mr10 icon-holder gate-icon" />,
        shouldRenderSeparator: true,
      },
    ]

    const statusOption: IGroupByOption = {
      title: getDeliveryGroupingDisplayName(DeliveryListFilterType.Status),
      groupByType: DeliveryListFilterType.Status,
      icon: (
        <Icons.CheckFillBlue className="no-grow mr10 icon-holder checkmark-icon" />
      ),
      shouldRenderSeparator: true,
    }

    const vendorOptions: IGroupByOption[] = [
      {
        title: getDeliveryGroupingDisplayName(DeliveryListFilterType.Vendor),
        groupByType: DeliveryListFilterType.Vendor,
        icon: (
          <Icons.Vendor className="no-grow mr10 icon-holder checkmark-icon" />
        ),
      },
      {
        title: getDeliveryGroupingDisplayName(
          DeliveryListFilterType.MaterialsCategory,
        ),
        groupByType: DeliveryListFilterType.MaterialsCategory,
        icon: (
          <Icons.Material className="no-grow mr10 icon-holder material-icon" />
        ),
      },
      {
        title: getDeliveryGroupingDisplayName(
          DeliveryListFilterType.VehicleType,
        ),
        groupByType: DeliveryListFilterType.VehicleType,
        icon: (
          <Icons.Delivery className="no-grow mr10 icon-holder delivery-icon" />
        ),
        shouldRenderSeparator: true,
      },
    ]

    return [
      noneOption,
      ...contactOptions,
      ...locationOptions,
      statusOption,
      ...vendorOptions,
    ]
  }

  @action.bound
  private clickOnMenuIcon(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    this.props.clickOnMenuIcon?.()
  }

  private toggleGroupBy = (event: React.SyntheticEvent<HTMLDivElement>) => {
    event.stopPropagation()
    this.props.deliveriesViewStore.toggleGroupBy()
  }
}
