import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { classList } from 'react-classlist-helper'

import './SelectButton.scss'

interface IProps {
  onClick: () => void
  isEnabled?: boolean
}

export default class SelectButton extends React.PureComponent<IProps> {
  public static defaultProps = {
    isEnabled: true,
  }

  public render() {
    const { isEnabled, onClick } = this.props

    return (
      <button
        onClick={onClick}
        className={classList({
          'unclickable-element': !isEnabled,
          'row select-button brada2 ba-primary-blue pointer no-grow': true,
        })}
      >
        <Icon
          iconSize={12}
          className="full-width"
          icon={IconNames.CHEVRON_DOWN}
        />
      </button>
    )
  }
}
