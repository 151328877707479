import { observable } from 'mobx'

import { IGlobeViewCircle, SitemapItemShapeType } from '~/client/graph'

import { areObjectsEqual } from '../../../utils/util'
import { GlobeViewShapeProperties } from './GlobeViewItemProperties'

const DEFAULT_IS_DISPLAYED = true

const DEFAULT_LINE_WIDTH = 5
const DEFAULT_FILL_OPACITY = 0.2

export default class GlobeViewCircleProperties implements IGlobeViewCircle {
  public type: SitemapItemShapeType = SitemapItemShapeType.Circle
  @observable public lineWidth: number
  @observable public fillColor: string
  @observable public fillOpacity: number
  @observable public lineColor: string
  @observable public isDisplayed: boolean

  public constructor(
    lineWidth?: number,
    lineColor?: string,
    fillColor?: string,
    fillOpacity?: number,
    isDisplayed?: boolean,
  ) {
    this.lineWidth = lineWidth || DEFAULT_LINE_WIDTH
    this.lineColor = lineColor
    this.fillColor = fillColor
    this.fillOpacity =
      typeof fillOpacity === 'number' ? fillOpacity : DEFAULT_FILL_OPACITY
    this.isDisplayed =
      typeof isDisplayed === 'boolean' ? isDisplayed : DEFAULT_IS_DISPLAYED
  }

  public isEqual(properties: GlobeViewShapeProperties) {
    return areObjectsEqual(this, properties)
  }

  public isValid() {
    return true
  }

  public toggleIsDisplayed() {
    this.isDisplayed = !this.isDisplayed
  }

  public setLineWidth(lineWidth: number) {
    this.lineWidth = lineWidth
  }

  public setLineColor(color: string) {
    this.lineColor = color
  }

  public setFillColor(color: string) {
    this.fillColor = color
  }

  public setFillOpacity(opacity: number) {
    this.fillOpacity = opacity
  }

  public isDisplayDataEqual(circle: IGlobeViewCircle) {
    return (
      this.isDisplayed ===
        (typeof circle.isDisplayed === 'boolean'
          ? circle.isDisplayed
          : DEFAULT_IS_DISPLAYED) &&
      this.type === circle.type &&
      this.lineWidth === circle.lineWidth &&
      this.lineColor === circle.lineColor &&
      this.fillColor === circle.fillColor &&
      this.fillOpacity === circle.fillOpacity
    )
  }

  public getDisplayData(): IGlobeViewCircle {
    return {
      type: this.type,
      lineWidth: this.lineWidth,
      lineColor: this.lineColor,
      fillColor: this.fillColor,
      fillOpacity: this.fillOpacity,
      isDisplayed: this.isDisplayed,
    }
  }

  public copy(): GlobeViewCircleProperties {
    return new GlobeViewCircleProperties(
      this.lineWidth,
      this.lineColor,
      this.fillColor,
      this.fillOpacity,
      this.isDisplayed,
    )
  }
}
