/**
 * Dynamically set the size of the QR code box to a percentage of the viewfinder
 * @param percentage (optional) The percentage of the viewfinder to use for the QR code box. Default is `0.9` (90%)
 * @param square (optional) If `true`, the QR code box will be a square proportional to the viewfinder's smallest dimension. Default is `false`
 * @returns A function that takes the viewfinder width and height and returns the width and height of the QR code box.
 *          Pass it directly to the `Html5QrcodeScanner` constructor as the `qrbox` option.
 */
const dynamicQrBoxPercent = (percentage = 0.9, square = false) => {
  const qrboxFunction = function (viewfinderWidth, viewfinderHeight) {
    const minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight)
    const scaledSize = Math.floor(minEdgeSize * percentage)
    if (square) {
      return {
        width: scaledSize,
        height: scaledSize,
      }
    } else {
      // Apply the same pixel difference to both width and height. Less accurate, but more pleasing to the eye.
      const percentDifferenceInPixels = minEdgeSize - scaledSize
      return {
        width: viewfinderWidth - percentDifferenceInPixels,
        height: viewfinderHeight - percentDifferenceInPixels,
      }
    }
  }

  return qrboxFunction
}

export default dynamicQrBoxPercent
