import * as React from 'react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import DeliveryConfirm from './DeliveryConfirm'

// translated

export interface IProps {
  isShown: boolean
  onHide: () => void
  onApply: () => void
}

export default class DeliveryDeleteConfirm extends React.Component<IProps> {
  public render() {
    const { isShown, onHide, onApply } = this.props

    return (
      <DeliveryConfirm
        title={Localization.translator.deleteDelivery}
        isShown={isShown}
        onHide={onHide}
        onApply={onApply}
        applyButtonText={Localization.translator.yesDeleteDelivery}
      >
        {Localization.translator.deliveryDescriptions.confirmDelete}
        <br />
        {Localization.translator.deliveryDescriptions.confirmDelete2}
      </DeliveryConfirm>
    )
  }
}
