import React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import { IDeliveryConfigurations } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import StruxhubTextArea from '~/client/src/shared/components/StruxhubInputs/StruxhubTextArea'
import { SAVE_DELIVERY_CONFIGURATIONS } from '~/client/src/shared/stores/EventStore/eventConstants'

import './CustomActionConfiguration.scss'

export interface IProps {
  customActionButtonText: string
  customActionText: string
  customActionUrl: string
  eventsStore?: DesktopEventStore
}

const noticeAddCustomNotificationText = 'NOTICE: Add Custom Notification text'
const limit3Lines = 'Limit 3 lines'
const addButtonText = 'Add Button Text'
const limit32Characters = 'Limit 32 characters'
const addLinkUrlForButtonAction = 'Add link URL for button action'

const buttonSymbolsLimit = 32
const textSymbolsLimit = 100

@inject('eventsStore')
@observer
export default class CustomActionConfiguration extends React.Component<IProps> {
  @observable private customActionButtonText: string
  @observable private customActionText: string
  @observable private customActionUrl: string

  public constructor(props: IProps) {
    super(props)

    this.customActionButtonText = props.customActionButtonText
    this.customActionText = props.customActionText
    this.customActionUrl = props.customActionUrl
  }

  @action
  public UNSAFE_componentWillReceiveProps(props: IProps) {
    if (this.props !== props) {
      this.customActionButtonText = props.customActionButtonText
      this.customActionText = props.customActionText
      this.customActionUrl = props.customActionUrl
    }
  }

  public render() {
    const { customActionButtonText, customActionText, customActionUrl } =
      this.props
    return (
      <div className="section-wrapper delivery-attribute-editor custom-action mt10 row no-flex-children wrap">
        <div className="col mr20 relative">
          <StruxhubTextArea
            label={noticeAddCustomNotificationText}
            value={this.customActionText}
            isRequiredTextHidden={true}
            maxLength={textSymbolsLimit}
            onChange={this.onTextChange}
            onBlur={this.updateConfig}
            additionalHelperText={limit3Lines}
          />
        </div>
        <div className="col mr20 mb30">
          <StruxhubInput
            label={addButtonText}
            isRequiredTextHidden={true}
            maxLength={buttonSymbolsLimit}
            value={this.customActionButtonText}
            onChange={this.onButtonTextChange}
            onBlur={this.updateConfig}
            additionalHelperText={limit32Characters}
          />
          <StruxhubInput
            label={addLinkUrlForButtonAction}
            isRequiredTextHidden={true}
            value={this.customActionUrl}
            onChange={this.onUrlChange}
            onBlur={this.updateConfig}
          />
        </div>
        <div className="custom-action-preview-col col mb30">
          <span className="text extra-large orange center pb15">
            {customActionText || noticeAddCustomNotificationText}
          </span>
          <a
            href={customActionUrl}
            className="ba-primary-blue brada4 large ma-x-auto pa10 primary-blue text"
            target="_blank"
          >
            {customActionButtonText || addButtonText}
          </a>
        </div>
      </div>
    )
  }

  private onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.customActionText = event.target.value
  }

  private onButtonTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.customActionButtonText = event.target.value
  }

  private onUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.customActionUrl = value && value.trim()
  }

  @action.bound
  private updateConfig() {
    const {
      eventsStore,
      customActionButtonText,
      customActionText,
      customActionUrl,
    } = this.props

    const text = this.customActionText && this.customActionText.trim()
    const buttonText =
      this.customActionButtonText && this.customActionButtonText.trim()
    if (
      text !== customActionText ||
      this.customActionUrl !== customActionUrl ||
      buttonText !== customActionButtonText
    ) {
      eventsStore.dispatch(SAVE_DELIVERY_CONFIGURATIONS, {
        customActionButtonText: buttonText,
        customActionText: text,
        customActionUrl: this.customActionUrl,
      } as IDeliveryConfigurations)
    }
  }
}
