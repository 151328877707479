import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import ActivityDetailsStore from '~/client/src/shared/components/ActivityDetails/ActivityDetails.store'
import UsernameFromUid from '~/client/src/shared/components/UsernameFromUid'
import Activity from '~/client/src/shared/models/Activity'

import ActivityGanttOrListViewStore from '../../../ActivityGanttOrListView.store'

interface IProps {
  activity: Activity
  store: ActivityGanttOrListViewStore

  state?: DesktopInitialState
  activityDetailsStore?: ActivityDetailsStore
}

const EMPTY_CELL = '-'

@inject('state', 'activityDetailsStore')
@observer
export default class ActivityCommentCell extends React.Component<IProps> {
  public render() {
    return (
      <div
        onClick={this.onToggle}
        className="text cell-wrapper px10 bb-cool-grey"
      >
        <div className="hidable-content full-width">
          {this.renderLastMessage()}
        </div>
      </div>
    )
  }

  @action.bound
  private onToggle(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    this.props.store.openActivityLog(this.props.activity)
    this.props.activityDetailsStore?.setLogVisibility(true)
  }

  private renderLastMessage() {
    const { activity } = this.props
    const { user } = this.props.state
    const lastMessage = this.props.store.getLastMessage(activity)

    if (!lastMessage) {
      return EMPTY_CELL
    }

    const isRead = lastMessage.readBy?.some(
      readByUser => readByUser.userId === user.id && readByUser.isRead,
    )

    return (
      <div className="comment full-width">
        {!isRead && <div className="message-status-unread" />}
        <div className="comment mr5">
          <UsernameFromUid showCompany={false} userId={lastMessage.userId} />
          <div>{':'}</div>
        </div>
        <div className="text-ellipsis">{lastMessage.text}</div>
      </div>
    )
  }
}
