import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { CellMeasurerCache } from 'react-virtualized'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import { NOOP } from '~/client/src/shared/utils/noop'

import ProjectSetUpPageStore from '../../ProjectSetUpPage.store'
import AddEditMemberDialog from '../ProjectMembersUpload/ProjectMembers/components/AddEditMemberDialog'
import { Header, Wrapper } from '../SetupPageLayout/SetupPageLayout'
import TagsDirectoryStore from './TagsDirectory.store'
import TagsDirectoryList from './components/TagsDirectoryList'

import './TagsDirectory.scss'

interface IProps {
  projectSetUpPageStore: ProjectSetUpPageStore

  eventsStore?: DesktopEventStore
  tagsStore?: TagsStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
}

const iconProps = { size: 16, className: 'mr4 tag-tab-icon' }
const DEFAULT_ROW_HEIGHT = 48

const addTag = 'Add tag'
const shouldDeleteTag = (tagName: string): string =>
  `Should delete ${tagName?.toLowerCase()}?`

@inject('eventsStore', 'tagsStore', 'userProjectsStore', 'projectMembersStore')
@observer
export default class TagsDirectory extends React.Component<IProps> {
  private readonly store: TagsDirectoryStore = null
  private readonly cellMeasurerCache: CellMeasurerCache = null

  private readonly clearPostEventCallback: () => void = NOOP

  public constructor(props: IProps) {
    super(props)

    this.cellMeasurerCache = new CellMeasurerCache({
      defaultHeight: DEFAULT_ROW_HEIGHT,
      minHeight: DEFAULT_ROW_HEIGHT,
      fixedWidth: true,
    })

    this.store = new TagsDirectoryStore(
      props.eventsStore,
      props.tagsStore,
      this.clearMeasurerCache,
      props.userProjectsStore,
      props.projectMembersStore,
    )

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.store.onProjectChange,
    )
  }

  public componentDidMount() {
    window.addEventListener('resize', this.clearMeasurerCache)
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.clearMeasurerCache)
    this.store.hideMemberDialog()
    this.clearPostEventCallback()
  }

  public render() {
    const { activeTabId } = this.store

    return (
      <Wrapper>
        <Header title={Localization.translator.userTags} />
        <div className="col tags-directory">
          {this.renderDeletionConfirmDialog()}
          {this.renderAddEditMemberDialog()}
          {this.renderTabs()}
          {this.renderAddTagBtn()}
          <TagsDirectoryList
            activeTab={activeTabId}
            cellMeasurerCache={this.cellMeasurerCache}
            tagsDirectoryStore={this.store}
          />
        </div>
      </Wrapper>
    )
  }

  private renderDeletionConfirmDialog(): JSX.Element {
    const {
      selectedTabTitle,
      shouldShowDeleteConfirmModal,
      isLoading,
      performTagDeletion,
      hideDeletionConfirm,
    } = this.store

    return (
      <ConfirmDialog
        isOpen={shouldShowDeleteConfirmModal}
        onCancelClicked={hideDeletionConfirm}
        onDoneClicked={performTagDeletion}
        doneTitle={Localization.translator.delete}
        loading={isLoading}
      >
        <div className="text large pre-line">
          {shouldDeleteTag(selectedTabTitle)}
        </div>
      </ConfirmDialog>
    )
  }

  private renderTabs(): JSX.Element {
    const { tabs, activeTabId, switchActiveTab } = this.store

    return (
      <div className="row pb20 mx30">
        {tabs.map(({ tabType, title, isDisabled, getIcon }, idx) => {
          const isTabSelected = tabType === activeTabId
          const onTabClick = isDisabled
            ? NOOP
            : switchActiveTab.bind(this, tabType)

          return (
            <div
              key={idx}
              className={classList({
                'row no-grow nowrap px10 tags-directory-tab': true,
                'bl-light-grey': !!idx,
                'inactive-element': isDisabled,
              })}
            >
              <div
                className={classList({
                  'row no-grow px10 nowrap text extra-large full-height ba-transparent pointer':
                    true,
                  'bg-palette-brand-lighter ba-light-input-border brada4':
                    isTabSelected,
                })}
                onClick={onTabClick}
              >
                {getIcon && getIcon(iconProps)}
                <span>{title}</span>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  private renderAddTagBtn(): JSX.Element {
    const { isLoading, showNewTagRow } = this.store
    const onAddTagClick = isLoading ? NOOP : showNewTagRow

    return (
      <div className="row ml40 pl10 my10">
        <span
          className={classList({
            'no-grow nowrap text blue-highlight large bold pointer': true,
            'inactive-element': isLoading,
          })}
          onClick={onAddTagClick}
        >
          {addTag}
        </span>
      </div>
    )
  }

  private renderAddEditMemberDialog(): JSX.Element {
    const { isProjectLoading, selectedProjectMember } = this.store
    if (isProjectLoading || !selectedProjectMember) {
      return null
    }

    const { projectMembersStore } = this.props

    const {
      areMembersBeingUpdated,
      errorMessage,
      resetErrorMessage,
      hideMemberDialog: hideAddMemberDialog,
      updateMembers,
    } = this.store

    return (
      <AddEditMemberDialog
        isOpen={true}
        closeDialog={hideAddMemberDialog}
        loading={areMembersBeingUpdated}
        onSubmit={updateMembers}
        itemsToEdit={[selectedProjectMember]}
        allItems={projectMembersStore.list}
        errorMessage={errorMessage}
        resetErrorMessage={resetErrorMessage}
      />
    )
  }

  private clearMeasurerCache = () => {
    this.cellMeasurerCache.clearAll()
  }
}
