import { action, computed, observable } from 'mobx'

import { IViewState } from '~/client/src/desktop/interfaces/IViewState'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import { PopupFilterType } from '~/client/src/shared/types/OpenedFilterType'

import { IModeSettings } from './components/ViewModeToggle/ViewModeToggle'

// localization: no display text to translate

type OpenedPopupType = PopupFilterType | string // predefined and custom filters

export type AdditionalReportFile = {
  label: string
  value: string
  name: string
  format: string
  isChecked: boolean
}

export default class HeaderBarStore {
  public viewState: IViewState = null
  @observable protected openedPopup: OpenedPopupType = null
  @observable public additionalReportFiles: AdditionalReportFile[] = []

  public constructor(protected readonly state: DesktopInitialState) {}

  @action.bound
  public toggleAdditionalReportFiles(value: string) {
    const file = this.additionalReportFiles.find(f => f.value === value)

    if (file) {
      file.isChecked = !file.isChecked
    }
  }

  @computed
  public get isAnyFileTypeChecked() {
    return this.additionalReportFiles.some(reportFile => reportFile.isChecked)
  }

  public get viewMode() {
    return this.viewState.viewMode
  }

  public get viewModes(): IModeSettings[] {
    return []
  }

  public get isAnyPopupOpened(): boolean {
    return !!this.openedPopup
  }

  public get shouldShowOpacityBackground(): boolean {
    return (
      this.isAnyPopupOpened && this.openedPopup !== PopupFilterType.EntityName
    )
  }

  public get forceDatePickerClose() {
    return this.openedPopup && this.openedPopup !== PopupFilterType.Calendar
  }

  @action.bound
  public onShowDatePickerChanged(isShown: boolean) {
    this.onShowChanged(isShown, PopupFilterType.Calendar)
  }

  public get forceCategoryClose() {
    return this.openedPopup && this.openedPopup !== PopupFilterType.Category
  }

  @action.bound
  public onShowCategoryChanged(isShown: boolean) {
    this.onShowChanged(isShown, PopupFilterType.Category)
  }

  public get forceGroupByClose() {
    return (
      this.openedPopup && this.openedPopup !== PopupFilterType.GroupingOptions
    )
  }

  @action.bound
  public onShowGroupByChanged(isShown: boolean) {
    this.onShowChanged(isShown, PopupFilterType.GroupingOptions)
  }

  public get forceSavedClose() {
    return this.openedPopup && this.openedPopup !== PopupFilterType.Saved
  }

  @action.bound
  public onShowSavedChanged(isShown: boolean) {
    this.onShowChanged(isShown, PopupFilterType.Saved)
  }

  @action.bound
  public onShowSearchChanged(isShown: boolean) {
    this.onShowChanged(isShown, PopupFilterType.EntityName)
  }

  @action.bound
  public onShowChanged(isShown: boolean, popupType: OpenedPopupType) {
    if (isShown) {
      this.openedPopup = popupType
    } else {
      this.openedPopup = null
    }
  }

  public get forceSearchClose() {
    return this.openedPopup && this.openedPopup !== PopupFilterType.EntityName
  }
}
