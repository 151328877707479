export interface IResponseData {
  status: number
  isSuccess: boolean
  data?: any
}

export default class ApiHelper {
  public static async toResponseData(
    response: Response,
  ): Promise<IResponseData> {
    let data = null

    try {
      const responseText = await response.text()

      try {
        data = JSON.parse(responseText)
      } catch (e) {
        data = responseText || {}
      }
    } catch (e) {
      /* empty */
    }

    return {
      data,
      isSuccess: response.ok,
      status: response.status,
    }
  }

  public static async fetchJson(url: RequestInfo, options: RequestInit) {
    const response = await fetch(url, options)

    const { data, isSuccess } = await ApiHelper.toResponseData(response)
    if (isSuccess) {
      return data
    }

    throw new Error(data)
  }
}
