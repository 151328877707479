import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IClosureFieldsFragment = Pick<
  Types.IClosure,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'locationId'
  | 'projectId'
  | 'associatedPermitId'
  | 'associatedAnnouncementId'
> & {
  closureInterval?: Types.Maybe<
    Pick<Types.ILocationClosureInterval, 'startDate' | 'endDate' | 'isDaily'>
  >
}

export type IGetClosuresListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetClosuresListQuery = {
  closures?: Types.Maybe<{ data: Array<IClosureFieldsFragment> }>
}

export type ISaveClosuresMutationVariables = Types.Exact<{
  closures:
    | Array<Types.Maybe<Types.IClosureInput>>
    | Types.Maybe<Types.IClosureInput>
}>

export type ISaveClosuresMutation = {
  saveManyClosures?: Types.Maybe<Array<Types.Maybe<IClosureFieldsFragment>>>
}

export type IDeleteClosuresMutationVariables = Types.Exact<{
  closureIds:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteClosuresMutation = Pick<
  Types.IMutation,
  'deleteManyClosures'
>

export type IListenToClosuresSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToClosuresSubscription = {
  closure?: Types.Maybe<
    Pick<Types.IClosureChangeEvent, 'id'> & {
      item?: Types.Maybe<IClosureFieldsFragment>
    }
  >
}

export const ClosureFieldsFragmentDoc = gql`
  fragment ClosureFields on Closure {
    id
    createdAt
    updatedAt
    locationId
    projectId
    closureInterval {
      startDate
      endDate
      isDaily
    }
    associatedPermitId
    associatedAnnouncementId
  }
`
export const GetClosuresListDocument = gql`
  query GetClosuresList($projectId: ObjectId!) {
    closures(limit: 1000000, projectId: $projectId) {
      data {
        ...ClosureFields
      }
    }
  }
  ${ClosureFieldsFragmentDoc}
`

/**
 * __useGetClosuresListQuery__
 *
 * To run a query within a React component, call `useGetClosuresListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClosuresListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClosuresListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetClosuresListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetClosuresListQuery,
    IGetClosuresListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetClosuresListQuery, IGetClosuresListQueryVariables>(
    GetClosuresListDocument,
    options,
  )
}
export function useGetClosuresListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetClosuresListQuery,
    IGetClosuresListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetClosuresListQuery,
    IGetClosuresListQueryVariables
  >(GetClosuresListDocument, options)
}
export type GetClosuresListQueryHookResult = ReturnType<
  typeof useGetClosuresListQuery
>
export type GetClosuresListLazyQueryHookResult = ReturnType<
  typeof useGetClosuresListLazyQuery
>
export type GetClosuresListQueryResult = Apollo.QueryResult<
  IGetClosuresListQuery,
  IGetClosuresListQueryVariables
>
export const SaveClosuresDocument = gql`
  mutation SaveClosures($closures: [ClosureInput]!) {
    saveManyClosures(closures: $closures) {
      ...ClosureFields
    }
  }
  ${ClosureFieldsFragmentDoc}
`
export type ISaveClosuresMutationFn = Apollo.MutationFunction<
  ISaveClosuresMutation,
  ISaveClosuresMutationVariables
>

/**
 * __useSaveClosuresMutation__
 *
 * To run a mutation, you first call `useSaveClosuresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveClosuresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveClosuresMutation, { data, loading, error }] = useSaveClosuresMutation({
 *   variables: {
 *      closures: // value for 'closures'
 *   },
 * });
 */
export function useSaveClosuresMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveClosuresMutation,
    ISaveClosuresMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveClosuresMutation,
    ISaveClosuresMutationVariables
  >(SaveClosuresDocument, options)
}
export type SaveClosuresMutationHookResult = ReturnType<
  typeof useSaveClosuresMutation
>
export type SaveClosuresMutationResult =
  Apollo.MutationResult<ISaveClosuresMutation>
export type SaveClosuresMutationOptions = Apollo.BaseMutationOptions<
  ISaveClosuresMutation,
  ISaveClosuresMutationVariables
>
export const DeleteClosuresDocument = gql`
  mutation DeleteClosures($closureIds: [ObjectId]!) {
    deleteManyClosures(ids: $closureIds)
  }
`
export type IDeleteClosuresMutationFn = Apollo.MutationFunction<
  IDeleteClosuresMutation,
  IDeleteClosuresMutationVariables
>

/**
 * __useDeleteClosuresMutation__
 *
 * To run a mutation, you first call `useDeleteClosuresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClosuresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClosuresMutation, { data, loading, error }] = useDeleteClosuresMutation({
 *   variables: {
 *      closureIds: // value for 'closureIds'
 *   },
 * });
 */
export function useDeleteClosuresMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteClosuresMutation,
    IDeleteClosuresMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteClosuresMutation,
    IDeleteClosuresMutationVariables
  >(DeleteClosuresDocument, options)
}
export type DeleteClosuresMutationHookResult = ReturnType<
  typeof useDeleteClosuresMutation
>
export type DeleteClosuresMutationResult =
  Apollo.MutationResult<IDeleteClosuresMutation>
export type DeleteClosuresMutationOptions = Apollo.BaseMutationOptions<
  IDeleteClosuresMutation,
  IDeleteClosuresMutationVariables
>
export const ListenToClosuresDocument = gql`
  subscription ListenToClosures($projectId: ObjectId!) {
    closure(projectId: $projectId) {
      item {
        ...ClosureFields
      }
      id
    }
  }
  ${ClosureFieldsFragmentDoc}
`

/**
 * __useListenToClosuresSubscription__
 *
 * To run a query within a React component, call `useListenToClosuresSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToClosuresSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToClosuresSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToClosuresSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToClosuresSubscription,
    IListenToClosuresSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToClosuresSubscription,
    IListenToClosuresSubscriptionVariables
  >(ListenToClosuresDocument, options)
}
export type ListenToClosuresSubscriptionHookResult = ReturnType<
  typeof useListenToClosuresSubscription
>
export type ListenToClosuresSubscriptionResult =
  Apollo.SubscriptionResult<IListenToClosuresSubscription>
