import * as React from 'react'

import {
  Icon,
  Popover,
  PopoverPosition,
  PopperModifiers,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { computed } from 'mobx'
import { observer } from 'mobx-react'

import { IWorkflowStep } from '~/client/graph'
import BallInCourtRulesTable from '~/client/src/desktop/components/BallInCourtRulesTable/BallInCourtRulesTable'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import OperationRule from '~/client/src/shared/models/OperationRule'
import User from '~/client/src/shared/models/User'
import PermitBallInCourtStore from '~/client/src/shared/stores/ui/PermitBallInCourt.store'

// localization: translated

interface IProps {
  workflowStep: IWorkflowStep
  formTypeId: string
  isUpdating: boolean

  getUsersFromAssignment(assignmentExpressions: string[]): User[]
  onRemoveRuleClick(ruleId: string): void
  onDuplicateRuleClick(ruleId: string): void
  onChangeUserForRule(
    rule: OperationRule,
    userId: string,
    isNew?: boolean,
  ): void
  onRuleExpressionChange(
    rule: OperationRule,
    locationAndCompExpression: string,
  ): void
  onAddNewRuleClick(stepId: string): void

  permitBallInCourtStore: PermitBallInCourtStore
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: { enabled: false },
  hide: { enabled: false },
  arrow: { enabled: false },
  computeStyle: { gpuAcceleration: false },
}

@observer
export default class WorkflowStepRulesModal extends React.Component<IProps> {
  public render() {
    return (
      <Popover
        className="row"
        popoverClassName="bic-step-rules-modal brada8 beautiful-shadow overflow-hidden"
        canEscapeKeyClose={false}
        position={PopoverPosition.BOTTOM_LEFT}
        modifiers={popoverPopperModifiers}
        content={this.bicTable}
      >
        <div className="row">
          <span className="text large">{this.title}</span>
          <Icon icon={IconNames.SETTINGS} className="text grey-30" />
        </div>
      </Popover>
    )
  }

  private get bicTable(): JSX.Element {
    const {
      getUsersFromAssignment,
      onRemoveRuleClick,
      onDuplicateRuleClick,
      onChangeUserForRule,
      onRuleExpressionChange,
      isUpdating,
    } = this.props

    return (
      <div className="col">
        {isUpdating && (
          <div className="full-height full-width absolute modal-overlay" />
        )}
        <span className="text large bold line-extra-large px20 pt15">
          {Localization.translator.thisWorkflowStepCanBePerformed}:
        </span>
        <BallInCourtRulesTable
          tableClassName="bic-step-rules overflow-auto"
          bicOperationRules={this.bicRules}
          getUsersFromAssignment={getUsersFromAssignment}
          onRemoveRuleClick={onRemoveRuleClick}
          onDuplicateRuleClick={onDuplicateRuleClick}
          onChangeUserForRule={onChangeUserForRule}
          onRuleExpressionChange={onRuleExpressionChange}
          onAddNewRuleClick={this.onAddNewRuleClick}
        />
      </div>
    )
  }

  private get title(): string {
    const { workflowRuleIds } = this.props.workflowStep
    if (!workflowRuleIds?.length) {
      return Localization.translator.none
    }
    return Localization.translator.xRules(workflowRuleIds.length)
  }

  @computed
  private get bicRules(): OperationRule[] {
    const { workflowStep, formTypeId, permitBallInCourtStore } = this.props
    const { getPermitBicRulesByIds, getNewOperationRule } =
      permitBallInCourtStore

    const rules = getPermitBicRulesByIds(workflowStep.workflowRuleIds)
    const isNewRuleShown =
      workflowStep.workflowRuleIds?.includes(OperationRule.NEW_ID) &&
      !rules.some(r => !r.isExisting)

    return (
      isNewRuleShown ? [...rules, getNewOperationRule(formTypeId)] : rules
    ).sort((a, b) => a.createdAt - b.createdAt)
  }

  private onAddNewRuleClick = () => {
    const { onAddNewRuleClick, workflowStep } = this.props
    onAddNewRuleClick(workflowStep.id)
  }
}
