import * as React from 'react'

import { observer } from 'mobx-react'

import { SitePermitStatus, WorkflowStepType } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import WorkflowCardStatus from '~/client/src/shared/components/WorkflowCard/Status'
import {
  WorkflowStepAction,
  formStatusByWorkflowActionMap,
  workflowActionsByStepsMap,
} from '~/client/src/shared/enums/WorkflowStepAction'
import { getWorkflowActionDisplayName } from '~/client/src/shared/localization/enumDisplayTexts'

// localization: no text to translate

interface IProps {
  stepType: WorkflowStepType
  workflowStepLevel: number
  isLastStep?: boolean
}

const ignoredActions = [WorkflowStepAction.CHANGE, WorkflowStepAction.DENY]
const getStatusByAction = (
  action: WorkflowStepAction,
  isLastStep: boolean,
): SitePermitStatus =>
  isLastStep && !ignoredActions.includes(action)
    ? SitePermitStatus.Done
    : formStatusByWorkflowActionMap[action]

@observer
export default class WorkflowStepActions extends React.Component<IProps> {
  public render() {
    const { stepType, workflowStepLevel, isLastStep } = this.props
    const actions = workflowActionsByStepsMap[stepType]

    return (
      <div className="col pt5">
        {actions.map(action => {
          const status = getStatusByAction(action, isLastStep)
          const isStepLevelShown =
            !isLastStep && !ignoredActions.includes(action)

          return (
            <div key={action} className="row mb5 wrap">
              <div className="ba-light-grey brada10 h20 px10 no-grow nowrap">
                {getWorkflowActionDisplayName(action)}
              </div>
              {!!status && (
                <>
                  <Icons.ArrowNext className="row mx4 no-grow" />
                  <WorkflowCardStatus
                    className="no-grow"
                    status={status}
                    workflowStepLevel={isStepLevelShown && workflowStepLevel}
                    isLate={false}
                  />
                </>
              )}
            </div>
          )
        })}
      </div>
    )
  }
}
