import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { ScrollSyncPane } from 'react-scroll-sync'

import SortOrder from '~/client/src/shared/enums/SortOrder'

import ViewModes from '../../../enums/ViewModes'
import DesktopInitialState from '../../../stores/DesktopInitialState'
import SortingCell from './ActivityGanttOrListView/components/ActivityListView/components/SortingCell'

interface IProps {
  sortKey?: string
  isSortingDesc?: boolean
  onSort?: (sortKey: string) => void
  currentSortingOrder: SortOrder
  isManpowerEnabled: boolean
  headerWidth?: number
  state?: DesktopInitialState
}

const DEFAULT_WIDTH = 160
const NAME_CELL_WIDTH = 580

const ORIGINAL_DURATION_KEY = 'activity.plannedDuration'
const REMAINING_DURATION_KEY = 'activity.remainingDuration'
const START_DAY_KEY = 'activity.startDayFormatted'
const FINISH_DAY_KEY = 'activity.finishDayFormatted'
const MANPOWER_SORT_KEY = 'manpower'
const PERCENT_COMPLETE_SORT_KEY = 'complete'
const ACTUAL_STATUS_SORT_KEY = 'actual-status'
const PLANNED_STATUS_SORT_KEY = 'planned-status'
const ON_TIME_SORT_KEY = 'on-time'
const ON_TIME = 'ON-TIME?'
const CATEGORIES_OF_VARIANCE_SORT_KEY = 'categories-of-variance'

const originalDuration = 'Original Dur.'
const remainingDuration = 'Remaining Dur.'
const startDay = 'Start Day'
const finishDay = 'Finish Day'
const manpower = 'Manpower'
const complete = 'Complete'
const categoriesOfVariance = 'Categories of Variance'
const plannedStatus = 'Planned status'
const actualStatus = 'Actual status'
const prog = 'Prog.'
const sche = 'Sche.'

@inject('state')
@observer
export default class ActivityStatusHeader extends React.PureComponent<IProps> {
  public render() {
    const {
      sortKey,
      isSortingDesc,
      onSort,
      currentSortingOrder,
      isManpowerEnabled,
      headerWidth,
      state,
    } = this.props

    const holderClassName =
      'activity-status-header row text uppercase no-outline-container cell cell-header pointer lp05 no-select full-height bb-light-cool-grey'
    const textClassName = 'full-height col y-end text center pr5'
    const isActive = SortOrder.DEFAULT !== currentSortingOrder
    const scrollableWidth = headerWidth
      ? headerWidth - NAME_CELL_WIDTH
      : DEFAULT_WIDTH
    const isGanttViewMode = state.activityList.viewMode === ViewModes.Gantt

    return (
      <ScrollSyncPane>
        <div
          className="overflow-hidden row relative activity-scrollable-header"
          style={{
            minWidth: DEFAULT_WIDTH,
            maxWidth: scrollableWidth,
          }}
        >
          <div
            className={classList({
              'activity-status-header col full-height full-height': true,
              mw590: isGanttViewMode,
              mw160: !isGanttViewMode,
            })}
          >
            <span className="rotated-text text small primary-blue left no-bold no-grow pl5">
              {isGanttViewMode ? sche.toUpperCase() : prog.toUpperCase()}
            </span>
            <div className="row full-height">
              {isGanttViewMode && (
                <>
                  <div className={holderClassName + ' mw75'}>
                    <SortingCell
                      isActive={isActive && sortKey === ORIGINAL_DURATION_KEY}
                      isDesc={isSortingDesc}
                      sortKey={ORIGINAL_DURATION_KEY}
                      onSort={onSort}
                    >
                      <div className={textClassName}>
                        {originalDuration.toUpperCase()}
                      </div>
                    </SortingCell>
                  </div>
                  <div className={holderClassName + ' mw75'}>
                    <SortingCell
                      isActive={isActive && sortKey === REMAINING_DURATION_KEY}
                      isDesc={isSortingDesc}
                      sortKey={REMAINING_DURATION_KEY}
                      onSort={onSort}
                    >
                      <div className={textClassName}>
                        {remainingDuration.toUpperCase()}
                      </div>
                    </SortingCell>
                  </div>
                  <div className={holderClassName + ' mw140'}>
                    <SortingCell
                      isActive={isActive && sortKey === START_DAY_KEY}
                      isDesc={isSortingDesc}
                      sortKey={START_DAY_KEY}
                      onSort={onSort}
                    >
                      <div className={textClassName}>
                        {startDay.toUpperCase()}
                      </div>
                    </SortingCell>
                  </div>
                  <div className={holderClassName + ' mw140'}>
                    <SortingCell
                      isActive={isActive && sortKey === FINISH_DAY_KEY}
                      isDesc={isSortingDesc}
                      sortKey={FINISH_DAY_KEY}
                      onSort={onSort}
                    >
                      <div className={textClassName}>
                        {finishDay.toUpperCase()}
                      </div>
                    </SortingCell>
                  </div>
                </>
              )}
              {isManpowerEnabled && (
                <div className={holderClassName + ' mw80'}>
                  <SortingCell
                    isActive={isActive && sortKey === MANPOWER_SORT_KEY}
                    isDesc={isSortingDesc}
                    sortKey={MANPOWER_SORT_KEY}
                    onSort={onSort}
                  >
                    <div className={textClassName}>{manpower}</div>
                  </SortingCell>
                </div>
              )}
              {isGanttViewMode && (
                <div className={holderClassName + ' mw80'}>
                  <SortingCell
                    isActive={isActive && sortKey === PERCENT_COMPLETE_SORT_KEY}
                    isDesc={isSortingDesc}
                    sortKey={PERCENT_COMPLETE_SORT_KEY}
                    onSort={onSort}
                  >
                    <div className={textClassName}>%{complete}</div>
                  </SortingCell>
                </div>
              )}
            </div>
          </div>
          <div className={holderClassName + ' mw160'}>
            <SortingCell
              isActive={isActive && sortKey === ACTUAL_STATUS_SORT_KEY}
              isDesc={isSortingDesc}
              sortKey={ACTUAL_STATUS_SORT_KEY}
              onSort={onSort}
            >
              <div className={textClassName}>{actualStatus.toUpperCase()}</div>
            </SortingCell>
          </div>
          <div className={holderClassName + ' mw160'}>
            <SortingCell
              isActive={isActive && sortKey === PLANNED_STATUS_SORT_KEY}
              isDesc={isSortingDesc}
              sortKey={PLANNED_STATUS_SORT_KEY}
              onSort={onSort}
            >
              <div className={textClassName}>{plannedStatus.toUpperCase()}</div>
            </SortingCell>
          </div>
          <div className={holderClassName + ' mw160'}>
            <SortingCell
              isActive={isActive && sortKey === ON_TIME_SORT_KEY}
              isDesc={isSortingDesc}
              sortKey={ON_TIME_SORT_KEY}
              onSort={onSort}
            >
              <div className={textClassName}>{ON_TIME}</div>
            </SortingCell>
          </div>
          <div className={holderClassName + ' mw300'}>
            <SortingCell
              isActive={isActive && sortKey === CATEGORIES_OF_VARIANCE_SORT_KEY}
              isDesc={isSortingDesc}
              sortKey={CATEGORIES_OF_VARIANCE_SORT_KEY}
              onSort={onSort}
            >
              <div className={textClassName}>
                {categoriesOfVariance.toUpperCase()}
              </div>
            </SortingCell>
          </div>
        </div>
      </ScrollSyncPane>
    )
  }
}
