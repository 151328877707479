import * as React from 'react'

import { Classes } from '@blueprintjs/core'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { Column, Table } from 'react-virtualized'

import { ILWFCColumn } from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import SelectButton from '~/client/src/shared/components/SelectButton/SelectButton'
import SelectionPopUp, {
  SelectionPopUpOption,
} from '~/client/src/shared/components/SelectionPopUp/SelectionPopUp'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import { DataKeys } from '../../MaterialsList.store'

// localization: translated

interface IProps<T> {
  items: T[]
  columns: ILWFCColumn[]
  getRowHeightByItem(item: T): number
  getCellContentByDataKey(dataKey: string, item: T): JSX.Element
  getFooterTitleByDataKey(dataKey: string): React.ReactText
  onIdClick(item: T): void

  iconElement?: JSX.Element
  isHeaderHidden?: boolean
  isFooterHidden?: boolean

  onSelectAll?(): void
  onClearAll?(): void
}

const MAX_TABLE_HEIGHT = 200
const ADDITIONAL_TABLE_WIDTH = 70

function isPlacedAtTheEnd(dataKey: string): boolean {
  return (
    dataKey === DataKeys.PLANNED_QUANTITY ||
    dataKey === DataKeys.MATERIAL_TRANSFER_ID ||
    dataKey === DataKeys.STATUS
  )
}

@observer
export default class BaseDataCellTable<T> extends React.Component<IProps<T>> {
  @observable private isSelectorShown = false
  private tableRef: Table = null

  public componentDidUpdate(prevProps: IProps<T>) {
    const { items } = this.props

    if (items !== prevProps.items || items.length !== prevProps.items.length) {
      this.tableRef.recomputeRowHeights()
      this.tableRef.forceUpdateGrid()
    }
  }

  public render() {
    return (
      <>
        {this.tableHeader}
        <Table
          width={this.tableWidth}
          height={this.tableHeight}
          ref={this.setTableRef}
          headerHeight={0}
          rowHeight={this.getRowHeight}
          rowCount={this.props.items.length}
          rowStyle={{ alignItems: 'stretch' }}
          rowGetter={this.getItemByIndex}
          rowClassName="no-outline"
          gridClassName="no-outline"
        >
          {this.columns.map((column, index) => (
            <Column
              key={index}
              dataKey={column.dataKey}
              width={column.width}
              minWidth={column.width}
              maxWidth={column.width}
              cellRenderer={this.renderCell}
            />
          ))}
        </Table>
        {this.tableFooter}
      </>
    )
  }

  private get tableHeader(): JSX.Element {
    if (this.props.isHeaderHidden) return null

    return (
      <div className="h36 row y-center no-flex-children bt-cool-grey bb-cool-grey">
        {this.columns.map(({ dataKey, label, width }, index) => (
          <div
            key={index}
            className={classList({
              'mr10 text large bold left dark-gray': true,
              ml10: !index,
              end: isPlacedAtTheEnd(dataKey),
              'row x-center': dataKey === DataKeys.CHECKBOX,
            })}
            style={{ width }}
          >
            {dataKey === DataKeys.CHECKBOX ? (
              <>
                <SelectButton onClick={this.toggleCheckboxSelector} />
                <SelectionPopUp
                  className="beautiful-shadow"
                  isDisplayed={this.isSelectorShown}
                  options={this.selectionPopUpOptions}
                />
              </>
            ) : (
              label
            )}
          </div>
        ))}
      </div>
    )
  }

  private get tableFooter(): JSX.Element {
    const { items, isFooterHidden, getFooterTitleByDataKey } = this.props

    if (isFooterHidden || !items.length) return null

    return (
      <div className="h36 row y-center no-flex-children bt-cool-grey">
        {this.columns.map(({ dataKey, width }, index) => (
          <div
            key={index}
            className={classList({
              'mr10 text large left': true,
              ml10: !index,
              end: isPlacedAtTheEnd(dataKey),
            })}
            style={{ width }}
          >
            {getFooterTitleByDataKey(dataKey)}
          </div>
        ))}
      </div>
    )
  }

  private renderCell = ({ dataKey, rowData, style }) => {
    const item = rowData as T
    if (!item) return null

    return (
      <div style={style} className="row full-width text large text-ellipsis">
        {this.getCellContentByDataKey(dataKey, item)}
      </div>
    )
  }

  private getCellContentByDataKey = (dataKey: string, item: T): JSX.Element => {
    const { iconElement, getCellContentByDataKey } = this.props

    if (dataKey === DataKeys.ID) {
      return (
        <>
          {iconElement}
          <span
            className={`text large mr5 ellipsis pointer form-id-link underline-hover ${Classes.POPOVER_DISMISS}`}
            onClick={this.onIdClick.bind(null, item)}
          >
            {getCellContentByDataKey(dataKey, item)}
          </span>
        </>
      )
    }

    return getCellContentByDataKey(dataKey, item)
  }

  private get columns(): ILWFCColumn[] {
    return this.props.columns
  }

  private getItemByIndex = ({ index }): T => {
    return this.props.items[index]
  }

  private getRowHeight = ({ index }: any): number => {
    return this.props.getRowHeightByItem(this.props.items[index])
  }

  private get tableHeight(): number {
    const height = this.props.items.reduce(
      (height, item) => (height += this.props.getRowHeightByItem(item)),
      0,
    )
    return height > MAX_TABLE_HEIGHT ? MAX_TABLE_HEIGHT : height
  }

  private get tableWidth(): number {
    return (
      this.columns.reduce((sum, col) => (sum += col.width), 0) +
      ADDITIONAL_TABLE_WIDTH
    )
  }

  private get selectionPopUpOptions(): SelectionPopUpOption[] {
    return [
      {
        label: Localization.translator.selectAll_items,
        onClick: this.selectAll,
      },
      {
        label: Localization.translator.clear,
        onClick: this.clearAll,
      },
    ]
  }

  private setTableRef = (tableRef: Table) => {
    this.tableRef = tableRef
  }

  @action.bound
  private toggleCheckboxSelector() {
    this.isSelectorShown = !this.isSelectorShown
  }

  @action.bound
  private selectAll() {
    this.props.onSelectAll?.()
    this.isSelectorShown = false
  }

  @action.bound
  private clearAll() {
    this.props.onClearAll?.()
    this.isSelectorShown = false
  }

  private onIdClick = (item: T) => {
    this.props.onIdClick(item)
  }
}
