import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import Delivery from '~/client/src/shared/models/Delivery'

export default function getDeliveryCopyWithNewStatus(
  delivery: Delivery,
  status: DeliveryStatus,
): Delivery {
  return Object.assign(delivery.getCopy(), {
    status,
    updatedAt: Date.now(),
  }) as Delivery
}
