import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { IWorkflowStep, SitePermitStatus } from '~/client/graph'
import CompactActionsMenuModal from '~/client/src/shared/components/CompactActionsMenuModal/CompactActionsMenuModal'
import formStatusesByStepsMap from '~/client/src/shared/constants/formStatusesByStepsMap'
import { getWorkflowStepLevel } from '~/client/src/shared/constants/formStatusesTags'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import InitialState from '~/client/src/shared/stores/InitialState'

import FormStatusSelectItem from './components/FormStatusSelectItem'

// localization: translated

interface IProps {
  isShown: boolean
  currentStatus: SitePermitStatus
  currentWorkflowStepLevel: number
  workflowSteps: IWorkflowStep[]

  onHide(event?: React.SyntheticEvent<HTMLElement>): void
  setStatusAndStep(status: SitePermitStatus, stepId: string): void

  state?: InitialState
}

@inject('state')
@observer
export default class PermitStatusSelectMenu extends React.Component<IProps> {
  public render() {
    if (!this.isAdminOrMaster) {
      return null
    }

    const {
      isShown,
      onHide,
      currentStatus,
      currentWorkflowStepLevel,
      workflowSteps,
    } = this.props

    return (
      <CompactActionsMenuModal
        isShown={isShown}
        onHide={onHide}
        headerText={Localization.translator.updateStatus}
      >
        {workflowSteps.map((step, idx) => {
          const statuses =
            workflowSteps.length - 1 === idx
              ? [SitePermitStatus.Done]
              : formStatusesByStepsMap[step.type]

          return statuses.map(status => {
            const workflowStepLevel = getWorkflowStepLevel(
              step.id,
              workflowSteps,
            )
            const isSelected =
              currentStatus === status &&
              currentWorkflowStepLevel === workflowStepLevel

            return (
              <FormStatusSelectItem
                key={step.id + status}
                status={status}
                workflowStepLevel={workflowStepLevel}
                workflowStepId={step.id}
                setStatusAndStep={this.setStatusAndStep}
                isSelected={isSelected}
                isDisabled={isSelected || !this.isAdminOrMaster}
              />
            )
          })
        })}
      </CompactActionsMenuModal>
    )
  }

  private get isAdminOrMaster(): boolean {
    return this.props.state.userActiveProjectSettings.isAdminOrFormsMaster
  }

  private setStatusAndStep = (status: SitePermitStatus, stepId: string) => {
    if (this.isAdminOrMaster) {
      this.props.setStatusAndStep(status, stepId)
    }
  }
}
