import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import CompactAlert from '~/client/src/shared/components/CompactAlert/CompactAlert'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import DeliveryFormFieldWrapper from '~/client/src/shared/components/DeliveryDetails/components/DeliveryFormFieldWrapper/DeliveryFormFieldWrapper'
import DeliveryMaterialControls from '~/client/src/shared/components/DeliveryDetails/components/DeliveryMaterialControls/DeliveryMaterialControls'
import DeliverySelectDateModal from '~/client/src/shared/components/DeliveryDetails/components/DeliverySelectDateModal/DeliverySelectDateModal'
import DeliverySelectModal from '~/client/src/shared/components/DeliveryDetails/components/DeliverySelectModal/DeliverySelectModal'
import { Content, Footer, View } from '~/client/src/shared/components/Layout'
import StruxhubSelect from '~/client/src/shared/components/StruxhubInputs/StruxhubSelect'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import { EMPTY_STRING, NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import ActionHandleButton from '../ActionHandleButton/ActionHandleButton'
import DeliveryBulkEditorStore, {
  DATE_RELATED_FIELD_IDS,
  MATERIAL_RELATED_FIELD_IDS,
} from './DeliveryBulkEditor.store'
import EditingConfirm from './components/EditingConfirm/EditingConfirm'

import './DeliveryBulkEditor.scss'

// localization: translated

interface IProps {
  selectedDeliveriesIds: string[]
  onUpdated: () => void
  eventsStore?: DesktopEventStore
  deliveriesStore?: DeliveriesStore
  deliveryDetailsStore?: DeliveryDetailsStore
}

const CROSS_ICON_SIZE = 14

@inject('eventsStore', 'deliveryDetailsStore', 'deliveriesStore')
@observer
export default class DeliveryBulkEditor extends React.Component<IProps> {
  private readonly store: DeliveryBulkEditorStore = null
  private containerRef: any = null

  public constructor(props: IProps) {
    super(props)

    this.store = new DeliveryBulkEditorStore(
      props.onUpdated,
      props.eventsStore,
      props.deliveriesStore,
      props.deliveryDetailsStore,
      props.selectedDeliveriesIds,
    )
  }

  public componentDidMount() {
    const { clearFieldsState, resetMaterialsSectionsCount } =
      this.props.deliveryDetailsStore

    clearFieldsState()
    resetMaterialsSectionsCount()
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      selectedDeliveriesIds,
      deliveryDetailsStore: { resetMaterialsSectionsCount },
    } = this.props

    if (!selectedDeliveriesIds.length) {
      return this.props.onUpdated()
    }

    if (
      prevProps.selectedDeliveriesIds.length !== selectedDeliveriesIds.length
    ) {
      this.store.reinit(selectedDeliveriesIds)
      resetMaterialsSectionsCount()
    }
  }

  public componentWillUnmount() {
    this.props.deliveryDetailsStore.clearFieldsState()
  }

  public render() {
    const {
      canEdit,
      handleEdit,
      closeConfirm,
      closeAlert,
      confirmMessage,
      prepareEditingData,
      actionHandleButtonTitle,
      shouldShowConfirmModal,
      shouldShowAlertModal,
    } = this.store

    const { deliveryDetailsStore } = this.props

    return (
      <View className="delivery-details-content delivery-bulk-editor ">
        <EditingConfirm
          isShown={shouldShowConfirmModal}
          onHide={closeConfirm}
          onApply={handleEdit}
        >
          {confirmMessage}
        </EditingConfirm>
        <CompactAlert isShown={shouldShowAlertModal} onHide={closeAlert}>
          {this.alertTemplate}
        </CompactAlert>
        <DeliverySelectModal store={this.props.deliveryDetailsStore} />
        <DeliverySelectDateModal
          isShown={deliveryDetailsStore.isSelectDateModalOpen}
          onHide={deliveryDetailsStore.closeSelectDateModal}
          deliveryDetailsStore={deliveryDetailsStore}
        />
        <Content
          scrollable={true}
          className="relative pa10 no-outline-container inline-block"
          setRef={this.setRef}
        >
          {this.renderEditingFields()}
          {this.renderAddNewFieldHandleButton()}
        </Content>
        <Footer className="row pb10 pt12 px12">
          <ActionHandleButton
            title={actionHandleButtonTitle}
            onClick={prepareEditingData}
            isEnabled={canEdit}
          />
        </Footer>
      </View>
    )
  }

  private renderFieldSelector(index: number) {
    const { allFields, editingFieldIds, fieldOptions } = this.store

    const fieldId = editingFieldIds[index]
    const alreadySelectedField = allFields.find(f => f.id === fieldId)

    return (
      <div className="row px8 py10 relative">
        <StruxhubSelect
          label={Localization.translator.selectDataColumn}
          isRequiredTextHidden={true}
          value={fieldId}
          onChange={this.handleSelectEditedField.bind(null, index)}
        >
          <>
            {fieldOptions.map(field => (
              <option key={field.id} value={field.id}>
                {field.hints[0]}
              </option>
            ))}
            {alreadySelectedField && (
              <>
                <option key={NO_VALUE} value={EMPTY_STRING}>
                  {EMPTY_STRING}
                </option>
                <option
                  key={alreadySelectedField.id}
                  value={alreadySelectedField.id}
                >
                  {alreadySelectedField.hints[0]}
                </option>
              </>
            )}
          </>
        </StruxhubSelect>

        <span className="badge index-label text large center bold">
          {index + 1}
        </span>

        {index > 0 && (
          <span
            className="badge remove-field row x-center pointer"
            onClick={this.removeEditedField.bind(null, index)}
          >
            <Icon icon={IconNames.CROSS} iconSize={CROSS_ICON_SIZE} />
          </span>
        )}
      </div>
    )
  }

  private renderEditingFields() {
    const { isEditingFieldSelectedByIndex } = this.store

    return this.store.editingFieldIds.map((fieldId, i) => (
      <div className="ba-palette-brand-lightest brada4 px8 mb20" key={fieldId}>
        {this.renderFieldSelector(i)}
        {isEditingFieldSelectedByIndex(i) && this.renderControl(fieldId)}
      </div>
    ))
  }

  private renderControl(fieldId: string) {
    const { deliveryDetailsStore } = this.props
    const { allFields, commonCtrlProps, setSelectedFieldId } = this.store

    const controlFieldsIds = [fieldId]

    if (fieldId === FieldIds.DATE) {
      controlFieldsIds.push(...DATE_RELATED_FIELD_IDS)
    }

    if (fieldId === FieldIds.MATERIAL) {
      controlFieldsIds.push(...MATERIAL_RELATED_FIELD_IDS)
      return (
        <DeliveryMaterialControls
          store={deliveryDetailsStore}
          FileInputType={commonCtrlProps.FileInputType}
          materialFields={allFields.filter(f =>
            controlFieldsIds.includes(f.id),
          )}
        />
      )
    }

    return allFields
      .filter(f => controlFieldsIds.includes(f.id))
      .map(f => (
        <DeliveryFormFieldWrapper
          onSelectClick={setSelectedFieldId}
          key={f.id}
          {...f}
          {...commonCtrlProps}
        />
      ))
  }

  private renderAddNewFieldHandleButton() {
    if (!this.store.shouldShowNewHandleButton) {
      return null
    }

    return (
      <div
        className="row py10 px20 mt10 test center large add-field pointer brada4"
        onClick={this.handleAddNewEditingField}
      >
        {Localization.translator.addFieldForEditing}
        <Icon icon={IconNames.ADD} className="pl5 no-grow" />
      </div>
    )
  }

  private get alertTemplate() {
    const { warningMessagePart1, warningMessagePart2 } =
      Localization.translator.deliveryBulkEditorDescriptions

    return (
      <div className="col">
        <div>{warningMessagePart1}</div>
        <div className="mt5">{warningMessagePart2}</div>
      </div>
    )
  }

  private handleAddNewEditingField = () => {
    this.store.handleAddNewEditingField()
    this.scrollBottom()
  }

  private removeEditedField = (index: number) => {
    this.store.removeEditedField(index)
    this.scrollBottom()
  }

  private handleSelectEditedField = (index: number, event: any) => {
    this.store.handleSelectEditedField(index, event.target.value)
    this.scrollBottom()
  }

  private scrollBottom() {
    Promise.resolve().then(() => {
      this.containerRef.scrollTop =
        this.containerRef.scrollHeight - this.containerRef.clientHeight
    })
  }

  private setRef = ref => (this.containerRef = ref)
}
