import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import ActionBarInput from '~/client/src/shared/components/ActionBarInput/ActionBarInput'
import CameraUploadButton from '~/client/src/shared/components/CameraUploadButton/CameraUploadButton'
import * as Icons from '~/client/src/shared/components/Icons'
import Activity from '~/client/src/shared/models/Activity'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

import Localization from '../localization/LocalizationManager'
import CommonStore from '../stores/ui/Common.store'

// translated

interface IProps {
  statusUpdate: StatusUpdate
  activity: Activity
  goBack: () => void
  slackBarStore?: SlackBarStore
  common?: CommonStore
  selectedCompany?: string
}

@inject('slackBarStore', 'common')
@observer
export default class StatusUpdateMessagesActionBar extends React.Component<IProps> {
  private isSending: boolean

  public render() {
    const { statusUpdate, slackBarStore } = this.props

    return (
      <div className="pa10 bt-light-grey slack-bar">
        <form onSubmit={this.send}>
          <div className="col">
            <ActionBarInput
              value={slackBarStore.draftMessage}
              onChange={this.setDraftMessage}
              placeholder={Localization.translator.addANoteToThisStatusUpdate}
            />

            <div className="row slack-bar-btns">
              <div className="col" />

              <div className="col y-center">
                <div className="row x-center no-flex-children">
                  <button
                    type="button"
                    className="mr20"
                    disabled={this.isSending}
                    onClick={this.updateOptionClick}
                  >
                    <Icons.StatusBadge />
                  </button>
                </div>
              </div>

              <div className="col y-center">
                <div className="row x-end no-flex-children">
                  {statusUpdate && <CameraUploadButton store={slackBarStore} />}
                  <button
                    className="slack-bar-send-btn"
                    type="submit"
                    disabled={this.isSending}
                  >
                    {Localization.translator.send}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }

  @action.bound
  public updateOptionClick = () => {
    const { activity, common, selectedCompany } = this.props
    common.displayBulkStatusUpdateView(activity, selectedCompany)
  }

  @action.bound
  private async send(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault()
    evt.stopPropagation()

    const { slackBarStore } = this.props

    this.isSending = true
    await slackBarStore.replyToThread(this.encodedStatusUpdate)
    this.isSending = false

    slackBarStore.closeImagePreview()
  }

  private setDraftMessage = evt => {
    this.props.slackBarStore.draftMessage = evt.target.value
  }

  private get encodedStatusUpdate(): StatusUpdate {
    const { statusUpdate } = this.props
    const encodedStatusUpdate: StatusUpdate = StatusUpdate.createWithDefaults()

    encodedStatusUpdate.updateFromJson(statusUpdate)
    encodedStatusUpdate.companies = statusUpdate.companies.slice()
    return encodedStatusUpdate
  }
}
