import * as React from 'react'

interface IConfigurationHeader {
  columnTitles: string[]
}

export default class ConfiguratorHeader extends React.Component<IConfigurationHeader> {
  public render() {
    const { columnTitles } = this.props

    return (
      <div className="row bb-brand-dark pb5">
        {columnTitles.map((columnTitle, index) => (
          <div key={index} className="text center uppercase bold">
            {columnTitle}
          </div>
        ))}
      </div>
    )
  }
}
