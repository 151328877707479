import * as React from 'react'

import { observer } from 'mobx-react'

import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'

export interface IProps {
  isShown: boolean
  isSubTextHidden?: boolean
  type: string
  name: string
  onApply(): void
  onHide(): void
}

@observer
export default class DeleteDeliveryAttributeConfirmDialog extends React.Component<IProps> {
  public render() {
    const { isShown, onHide, onApply, type, name, isSubTextHidden } = this.props

    const lowerCaseType = type && type.toLowerCase()
    return (
      <ConfirmDialog
        isOpen={isShown}
        onCancelClicked={onHide}
        onDoneClicked={onApply}
        title={this.title}
        doneTitle="Delete"
      >
        <div>
          <div className="text large mb10">
            Confirm deletion of the{' '}
            <span className="text bold large">{name}</span> ?
          </div>
          {!isSubTextHidden && (
            <div className="text large">
              By deleting this {lowerCaseType}, all future deliveries to this{' '}
              {lowerCaseType} will be marked as “Requested” and will require the
              input of a valid {lowerCaseType}.
            </div>
          )}
        </div>
      </ConfirmDialog>
    )
  }

  private get title() {
    const { type } = this.props
    const upperCaseType = type && type.toUpperCase()
    return `CONFIRM DELETION OF ${upperCaseType}`
  }
}
