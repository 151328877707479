import tinycolor from 'tinycolor2'

import LocationBase from '../models/LocationObjects/LocationBase'
import ThemeMode from './ThemeModeManager'

import Colors from '~/client/src/shared/theme.module.scss'

const site = 'Site'

const { neutral0, neutral100, neutral60_1 } = Colors

export function getBackgroundColor(
  categoryId: string,
  location: LocationBase,
): string {
  if (categoryId === site) {
    return neutral0
  }

  const hasOwnColor = !!location?.color

  if (!hasOwnColor) {
    return neutral60_1
  }

  return tinycolor(ThemeMode.getHEXColor(location.color))
    .setAlpha(0.1)
    .toString()
}

export function getTextColor(categoryId: string): string {
  const shouldUseWhiteColor = !categoryId || categoryId === site

  return shouldUseWhiteColor ? neutral100 : neutral0
}
