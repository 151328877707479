enum ProjectSetUpPage {
  DEFAULT = 'default',

  EDIT_PROFILE = 'edit-profile',
  NOTIFICATION_SETTINGS = 'notification-settings',

  USER_DIRECTORY = '6',
  COMPANY_DIRECTORY = 'company-directory',
  USER_TAGS = 'user-tags',

  PROJECT_DETAILS = '1',
  PROJECT_CREATION = 'project-creation',
  MAP_SETUP = 'map-setup',
  LOCATION_CONTROLS = 'location-controls',
  PRESENTATION_MODE = '7',
  INTEGRATIONS = 'integrations',
  ANALYTICS = 'analytics',
  MATERIALS_UPLOAD = 'materials-upload',

  FORM_WORKFLOWS = 'form-workflows',
  FORM_NOTIFICATIONS = 'form-notifications',

  DELIVERY_WORKFLOWS = '8',
  DELIVERY_LOCATIONS = 'delivery-locations',
  DELIVERY_CARDS = 'delivery-cards',
  DELIVERY_NOTIFICATIONS = 'delivery-notifications',

  SCHEDULE_UPLOAD = '2',
  SCHEDULE_COLUMNS = '3',

  MOBILE_CONFIGURATION = '4',
  ON_TIME_THRESHOLDS = '10',
  QR_SCANNERS_SETUP = 'qr-scanners-setup',

  TEAM_ASSIGNMENT = '11',
  APPS_CONNECTION = '12',
}

export default ProjectSetUpPage
