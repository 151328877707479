import * as React from 'react'

import ActivityItemBase from '~/client/src/shared/components/ActivityItem/ActivityItem'
import { Loader } from '~/client/src/shared/components/Loader'
import ThreadIndicators from '~/client/src/shared/components/ThreadIndicators/ThreadIndicators'
import { ActivitiesTreeNodeTypes } from '~/client/src/shared/enums/ActivitiesTreeNodeTypes'
import { IActivitiesTreeNode } from '~/client/src/shared/models/IActivitiesTreeNode'

import ActivitiesTreeStore from '../../ActivitiesTree.store'

interface IProps {
  node: IActivitiesTreeNode
  currentViewDate: Date
  activitiesTreeStore: ActivitiesTreeStore
  LocationContainerNodeType
}

const {
  COMPANY_CONTAINER,
  ACTIVITY,
  COMPLETED_ACTIVITY_CONTAINER,
  NEXT_ACTIVITY_CONTAINER,
  SHOW_MORE_CONTROL,
  ZONE_CONTAINER,
  ACTIVITY_CODE_CONTAINER,
  LEVEL_CONTAINER,
  INFO,
  LOADING,
} = ActivitiesTreeNodeTypes

export default class ActivitiesTreeNode extends React.Component<IProps> {
  public render() {
    const { node, LocationContainerNodeType } = this.props
    switch (node.nodeType) {
      case ACTIVITY:
        return this.renderActivityNode()
      case COMPANY_CONTAINER:
        return this.renderCompanyContainerNode()
      case COMPLETED_ACTIVITY_CONTAINER:
      case NEXT_ACTIVITY_CONTAINER:
        return this.renderSubContainerNode()
      case SHOW_MORE_CONTROL:
        return this.renderShowMoreControl()
      case INFO:
        return this.renderInfoNode()
      case LOADING:
        return this.renderLoaderNode()
      case ACTIVITY_CODE_CONTAINER:
      case ZONE_CONTAINER:
      case LEVEL_CONTAINER:
        return <LocationContainerNodeType node={node} />
      default:
        return null
    }
  }

  private renderActivityNode() {
    const {
      currentViewDate,
      node: { entity, onClick, onTopBarClick, sectionCompanyName },
      activitiesTreeStore,
    } = this.props

    return (
      <ActivityItemBase
        key={entity.code}
        currentViewDate={currentViewDate}
        activity={entity}
        customHandleClick={onClick}
        customHandleTopBarClick={onTopBarClick}
        sectionCompanyName={sectionCompanyName}
        activitiesTreeStore={activitiesTreeStore}
      />
    )
  }

  private renderCompanyContainerNode() {
    const {
      name,
      state: { expanded: isExpanded },
      totalEntitiesAmount,
      updatedActivitiesAmount,
      totalManpower,
      updatedActivitiesAmountWithManpower,
      ...rest
    } = this.props.node

    return (
      <>
        <div className="text bold large ellipsis inherit-color">{name}</div>
        <ThreadIndicators
          totalAmount={totalEntitiesAmount}
          actualAmount={updatedActivitiesAmount}
          manpower={totalManpower}
          onlyStatusUpdate={isExpanded}
          updatedActivitiesAmountWithManpower={
            updatedActivitiesAmountWithManpower
          }
          {...rest}
        />
      </>
    )
  }

  private renderSubContainerNode() {
    const { name } = this.props.node

    return <div className="text large light line-large">{name}</div>
  }

  private renderInfoNode() {
    const { name } = this.props.node

    return <div className="text large light line-large pl20">{name}</div>
  }

  private renderLoaderNode() {
    return (
      <div className="text center large light line-large pa5">
        <Loader size={15} />
      </div>
    )
  }

  private renderShowMoreControl() {
    const { name, onClick, id } = this.props.node

    return (
      <div
        className="text primary-blue uppercase pl20"
        onClick={onClick.bind(this, id)}
      >
        {name}
      </div>
    )
  }
}
