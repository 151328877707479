import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'
import { Loader } from '~/client/src/shared/components/Loader'
import UnsafeSitePermitCreationFormContent from '~/client/src/shared/components/SitePermitCreationForm/SitePermitCreationFormContent'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { PERMIT_TYPES_RECEIVED } from '~/client/src/shared/stores/EventStore/eventConstants'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaterialCategoriesStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import OperationRulesStore from '~/client/src/shared/stores/domain/OperationRules.store'
import PermitBicInspectionsStore from '~/client/src/shared/stores/domain/PermitBicInspections.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import PermitBallInCourtStore from '~/client/src/shared/stores/ui/PermitBallInCourt.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NOOP } from '~/client/src/shared/utils/noop'

import { Wrapper } from '../SetupPageLayout/SetupPageLayout'
import PermitsSetUpStore from './PermitsSetUp.store'
import PreviewPermitFormStore from './PreviewPermitForm.store'
import FormTypeConfigurator from './components/FormTypeConfigurator/FormTypeConfigurator'
import FormTypesList from './components/FormTypesList/FormTypesList'
import UnsafeNewFormTypeDialog from './components/NewFormTypeDialog'

import './PermitsSetUp.scss'

// localization: translated

const SitePermitCreationFormContent = withErrorBoundary(
  UnsafeSitePermitCreationFormContent,
)
const NewFormTypeDialog = withErrorBoundary(UnsafeNewFormTypeDialog)

interface IProps {
  eventsStore?: DesktopEventStore
  projectDateStore?: ProjectDateStore
  companiesStore?: CompaniesStore
  permitTypesStore?: PermitTypesStore
  operationRulesStore?: OperationRulesStore
  permitBicInspectionsStore?: PermitBicInspectionsStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  locationAttributesStore?: LocationAttributesStore
  materialsStore?: MaterialsStore
  materialCategoryStore?: MaterialCategoriesStore
}

const loaderSize = 40

@inject(
  'eventsStore',
  'projectDateStore',
  'companiesStore',
  'permitTypesStore',
  'operationRulesStore',
  'permitBicInspectionsStore',
  'userProjectsStore',
  'projectMembersStore',
  'locationAttributesStore',
  'materialsStore',
  'materialCategoryStore',
)
@observer
export default class PermitsSetUp extends React.Component<IProps> {
  private readonly store: PermitsSetUpStore
  private readonly previewPermitStore: PreviewPermitFormStore
  private readonly permitBicStore: PermitBallInCourtStore

  private readonly clearPostEventCallback: () => void = NOOP

  public constructor(props: IProps) {
    super(props)

    this.previewPermitStore = new PreviewPermitFormStore(
      props.eventsStore,
      props.projectDateStore,
      null,
      props.permitTypesStore,
      props.permitBicInspectionsStore,
      props.userProjectsStore,
      props.projectMembersStore,
      props.companiesStore,
      props.locationAttributesStore,
      props.materialsStore,
      props.materialCategoryStore,
    )

    this.store = new PermitsSetUpStore(
      props.eventsStore,
      props.permitTypesStore,
      this.previewPermitStore,
    )

    this.permitBicStore = new PermitBallInCourtStore(
      props.eventsStore,
      props.operationRulesStore,
      props.projectMembersStore,
    )

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.onPermitTypesReceived,
    )
  }

  public componentWillUnmount() {
    this.clearPostEventCallback()
  }

  public render() {
    if (!this.store.isReceived) {
      return (
        <Loader
          className="full-height col x-center y-center"
          hint={Localization.translator.loading}
        />
      )
    }

    return (
      <div className="row full-height permit-settings">
        <Wrapper className="overflow-hidden">
          <div className="row full-height y-start relative">
            <div className="absolute full-height full-width">
              {this.store.isUpdating && (
                <div className="full-height full-width absolute modal-overlay">
                  <Loader
                    className="col x-center y-center full-height"
                    size={loaderSize}
                    hint={`${Localization.translator.templateUpdating}...`}
                  />
                </div>
              )}
            </div>

            <div className="col full-height full-width relative">
              {this.content}
            </div>
          </div>
        </Wrapper>
        {this.formPreview}
      </div>
    )
  }

  private get content(): JSX.Element {
    const {
      activePermitType,
      isUpdating,
      isNewFormTypeDialogShown,
      changeShowInApp,
      showNewFormTypeDialog,
      removeCustomPermitTypes,
      setActivePermitTypeId,
      saveFormType,
      resetSelectedFormType,
    } = this.store

    if (!activePermitType) {
      return (
        <>
          {isNewFormTypeDialogShown && <NewFormTypeDialog store={this.store} />}
          <div className="col px16">
            <FormTypesList
              isUpdating={isUpdating}
              onSelectType={setActivePermitTypeId}
              onShowInAppChange={changeShowInApp}
              onAddNewFormClick={showNewFormTypeDialog}
              onRemoveTypes={removeCustomPermitTypes}
            />
          </div>
        </>
      )
    }

    return (
      <FormTypeConfigurator
        formType={activePermitType}
        isUpdating={isUpdating}
        onBackClick={resetSelectedFormType}
        saveFormType={saveFormType}
        permitBicStore={this.permitBicStore}
      />
    )
  }

  private get formPreview(): JSX.Element {
    if (!this.store.activePermitType) return null

    return (
      <div className="no-grow permit-form-preview full-height px20 pt20 col">
        <div className="text white extra-large center mb20">
          {Localization.translator.formPreview}
        </div>
        <SitePermitCreationFormContent
          store={this.previewPermitStore}
          isReadonly={false}
          isTypeReadOnly
          FileInputType={DesktopFileInput}
        />
      </div>
    )
  }

  private onPermitTypesReceived = (eventContext: EventContext) => {
    const [eventType] = eventContext.event

    if (eventType === PERMIT_TYPES_RECEIVED) {
      this.store.resetSelectedFormIfNeed()
    }
  }
}
