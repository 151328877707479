import * as React from 'react'

import { Popover, PopoverPosition, PopperModifiers } from '@blueprintjs/core'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import StruxhubTagValueSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubTagValueSelector'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import ShowInAppModalRow from './components/ShowInAppModalRow'
import ShowInAppTag from './components/ShowInAppTag'

// localization: translated

interface IProps {
  formTypeId: string
  isShown: boolean

  isDisplayedAsSelector?: boolean
  modalClassName?: string

  onEnableChange(formTypeId: string): void
}

const popoverPopperModifiers: PopperModifiers = {
  preventOverflow: {
    enabled: true,
    padding: 5,
    boundariesElement: 'window',
  },
  hide: { enabled: true },
  arrow: { enabled: false },
  computeStyle: { gpuAcceleration: false },
}

@observer
export default class ShowInAppModal extends React.Component<IProps> {
  public render() {
    const { isDisplayedAsSelector } = this.props

    return (
      <Popover
        popoverClassName="beautiful-shadow"
        targetClassName={classList({
          'full-width': true,
          h40: !isDisplayedAsSelector,
          pointer: isDisplayedAsSelector,
        })}
        position={PopoverPosition.BOTTOM}
        modifiers={popoverPopperModifiers}
        content={this.modalItems}
      >
        {isDisplayedAsSelector ? this.selectorElement : this.standartElement}
      </Popover>
    )
  }

  private get selectorElement(): JSX.Element {
    const { isShown } = this.props
    const values = isShown
      ? [
          { name: Localization.translator.home },
          { name: Localization.translator.forms },
        ]
      : [{ name: NO_VALUE }]

    return (
      <StruxhubTagValueSelector
        className="overflow-hidden no-flex pb10"
        label={Localization.translator.showInApp}
        isReadOnly
        isHelperTextCondensed
        shouldUseDefaultColor
        isShownAsTag={isShown}
        values={values}
      />
    )
  }

  private get standartElement(): JSX.Element {
    return (
      <div className="row full-width full-height pointer">
        {this.props.isShown ? (
          <div className="row">
            <ShowInAppTag label={Localization.translator.home} />
            <ShowInAppTag
              label={Localization.translator.forms}
              className="ml4"
            />
          </div>
        ) : (
          <div className="text grey-30 large line-extra-large">{NO_VALUE}</div>
        )}
        <Icons.DownArrow className="row no-flex pa12 ml10 br-rounded highlighted-hover highlighted-active show-in-app-icon" />
      </div>
    )
  }

  private get modalItems(): JSX.Element {
    const { isShown, modalClassName } = this.props

    return (
      <div
        className={classList({
          'py10 px10 col': true,
          [modalClassName]: !!modalClassName,
        })}
      >
        <ShowInAppModalRow
          label={Localization.translator.home}
          isShown={isShown}
          onClick={this.enableOrDisableShown}
        />
        <ShowInAppModalRow
          label={Localization.translator.forms}
          isShown={isShown}
          onClick={this.enableOrDisableShown}
        />
      </div>
    )
  }

  private enableOrDisableShown = () => {
    const { formTypeId, onEnableChange } = this.props
    onEnableChange(formTypeId)
  }
}
