import * as React from 'react'

import { observer } from 'mobx-react'

import WorkflowDiagramLine from '../../WorkflowDiagramWrapper/components/WorkflowDiagramLine'
import { ActionState } from '../../WorkflowDiagramWrapper/components/WorkflowDiagramState'
import BaseStepDiagram, { IBaseStepDiagramProps } from './BaseStepDiagram'

// localization: translated

interface IProps extends IBaseStepDiagramProps {
  actionStateText?: string
}

@observer
export default class SimpleStepDiagram extends React.Component<IProps> {
  public render() {
    const { actionStateText, ...restProps } = this.props

    return (
      <BaseStepDiagram {...restProps} includeChangeAction>
        <ActionState content={actionStateText} />
        <WorkflowDiagramLine shouldIncludeArrow />
      </BaseStepDiagram>
    )
  }
}
