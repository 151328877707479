import { IActivitiesTreeNode } from '~/client/src/shared/models/IActivitiesTreeNode'

import { DeliveryTreeNodeTypes } from '../enums/DeliveryTreeNodeTypes'
import Delivery from './Delivery'
import IDeliveriesTreeNodeSource from './IDeliveriesTreeNodeSource'
import { IDeliveryTreeNode } from './IDeliveryTreeNode'

export default class DeliveriesTreeNode implements IDeliveryTreeNode {
  public static fromSource(source: IDeliveriesTreeNodeSource, stateData: any) {
    const node = new DeliveriesTreeNode(source.id, source.name, source.nodeType)
    return Object.assign(node, source, stateData)
  }

  public deepness = null
  public parents = null

  public state?: any
  public entity?: Delivery
  public totalEntitiesAmount?: number
  public sectionCompanyName?: string
  public children?: IActivitiesTreeNode[]
  public level?: number = 1
  public startDate?: Date = null
  public endDate?: Date = null
  public container?: { [key: string]: string } = null
  public onClick?: () => void
  public onTopBarClick?: () => void

  public constructor(
    public id: number | string,
    public name: string,
    public nodeType: DeliveryTreeNodeTypes,
    dto: any = {},
  ) {
    this.entity = dto.delivery || null
    this.state = dto.state || {}
    this.totalEntitiesAmount = dto.totalEntitiesAmount || 0
    this.children = dto.children || []
    this.container = dto.container
    this.level = dto.level
    this.startDate = dto.startDate || null
    this.endDate = dto.endDate || null
    this.onClick = dto.onClick
    this.sectionCompanyName = dto.sectionCompanyName
    this.onTopBarClick = dto.onTopBarClick
  }
}
