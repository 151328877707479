import * as React from 'react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

export interface IProps {
  names?: string[]
  title?: string
  namesByCategories?: {
    [category: string]: string[]
  }
}

export default class SelectedFilterItemsList extends React.Component<IProps> {
  public render() {
    const { names, title, namesByCategories } = this.props
    return (
      <>
        <div>
          {title || Localization.translator.activityCodesSelected + ':'}
        </div>
        {names &&
          names.map((name, idx) => (
            <div className="pl20 text large primary-blue" key={idx}>
              {name}
            </div>
          ))}
        {namesByCategories &&
          Object.keys(namesByCategories).map(category => {
            return (
              <div className="pl10" key={category}>
                <div className="text large bold primary-blue">{category}:</div>
                {namesByCategories[category].map(item => {
                  return (
                    <div className="pl20 text large primary-blue" key={item}>
                      {item}
                    </div>
                  )
                })}
              </div>
            )
          })}
      </>
    )
  }
}
