export default class Guard {
  public static againstUndefined(argument: any, argumentName: string): void {
    if (typeof argument === 'undefined') {
      const message = Guard.missingArgumentMessage(argumentName)
      Guard.assert(argument, message)
    }
  }

  public static require(argument: any, name: string): void {
    const message = Guard.missingArgumentMessage(name)
    Guard.assert(Guard.isDefined(argument), message)
  }

  public static requireAll(argsMap: any = {}): void {
    Object.keys(argsMap).forEach(key => Guard.require(argsMap[key], key))
  }

  public static isDefined(value) {
    return !Guard.isUndefined(value)
  }

  public static isUndefined(value) {
    return typeof value === 'undefined'
  }

  public static assert(value, message) {
    if (value) {
      return
    }

    const stackTraceLimit = Error.stackTraceLimit
    Error.stackTraceLimit = Infinity
    const error = new Error(message)
    error.stack = Guard.cleanStack(error.stack)
    Error.stackTraceLimit = stackTraceLimit

    throw error
  }

  private static cleanStack(stack) {
    return stack
      .split('\n')
      .filter(part => !part.includes('Guard'))
      .join('\n')
  }

  private static missingArgumentMessage(name) {
    return `Expected ${name} to be defined`
  }
}
