import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import FilterHandleButton from '~/client/src/shared/components/Filters/components/FilterHandleButton/FilterHandleButton'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import ActivityCodesStore from '~/client/src/shared/stores/domain/ActivityCodes.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import ActivityPresetsStore from '~/client/src/shared/stores/domain/ActivityPresets.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import CustomActivityListFiltersStore from '~/client/src/shared/stores/domain/CustomActivityListFilters.store'

import DesktopEventStore from '../../../stores/EventStore/DesktopEvents.store'
import CategoryFilterStore from '../CategoryFilter/CategoryFilter.store'
import RecentlyUpdatedStore from '../CategoryFilter/components/RecentlyUpdated/RecentlyUpdated.store'
import ActivityPresetDialog from '../SavedFilters/ActivityPresetSelector/components/ActivityPresetDialog'
import SaveFilterDialog from '../SavedFilters/components/SaveFilterDialog'
import SavedFiltersModal from '../SavedFilters/components/SavedFiltersModal'
import DesktopActivitiesSavedFiltersStore from './DesktopActivitiesSavedFilters.store'

import '../SavedFilters/SavedFilters.scss'

// localization: translated

interface IProps {
  activityListStore: DesktopActivityListStore
  categoryFilterStore: CategoryFilterStore
  recentlyUpdatedStore: RecentlyUpdatedStore
  eventsStore?: DesktopEventStore
  companiesStore?: CompaniesStore
  customActivityListFiltersStore?: CustomActivityListFiltersStore
  activityFiltersStore?: ActivityFiltersStore
  activityCodesStore?: ActivityCodesStore
  activityPresetsStore?: ActivityPresetsStore
  onShowChanged?: (isShown: boolean) => void
  forceClose?: boolean
}

@inject(
  'eventsStore',
  'companiesStore',
  'customActivityListFiltersStore',
  'activityFiltersStore',
  'activityCodesStore',
  'activityPresetsStore',
)
@observer
export default class ActivitiesSavedFilters extends React.Component<IProps> {
  private desktopActivitiesSavedFiltersStore: DesktopActivitiesSavedFiltersStore
  public constructor(props: IProps) {
    super(props)

    const {
      customActivityListFiltersStore,
      activityFiltersStore,
      onShowChanged,
      eventsStore,
      companiesStore,
      activityCodesStore,
      activityPresetsStore,
    } = this.props

    this.desktopActivitiesSavedFiltersStore =
      new DesktopActivitiesSavedFiltersStore(
        customActivityListFiltersStore,
        activityFiltersStore,
        eventsStore,
        activityCodesStore,
        activityPresetsStore,
        companiesStore,
        props.categoryFilterStore,
        props.recentlyUpdatedStore,
        onShowChanged,
      )
  }

  public componentDidUpdate() {
    if (this.props.forceClose) {
      this.desktopActivitiesSavedFiltersStore.isShown = false
      this.desktopActivitiesSavedFiltersStore.shouldSaveFilterDialogShow = false
      this.desktopActivitiesSavedFiltersStore.shouldActivityPresetDialogShow =
        false
    }
  }

  public render() {
    const { getActivityCountByCustomFilter } = this.props.activityListStore
    const {
      editableFilterName,
      editableFilterIsPublic,
      saveCustomFilter,
      isShown,
      shouldSaveFilterDialogShow,
      shouldActivityPresetDialogShow,
      toggleSavedFilters,
      closeSavedFilters,
      onFilterNameChange,
      onFilterIsPublicToggle,
      filtersCount,
      filtersDescriptions,
      customFilterInstance,
    } = this.desktopActivitiesSavedFiltersStore

    const activityCountByFilter =
      getActivityCountByCustomFilter(customFilterInstance)

    const { appliedActivityPresetId } = this.props.eventsStore.appState
    return (
      <div className="saved-filters">
        <FilterHandleButton
          className="saved-filters-handle-button"
          onClick={toggleSavedFilters}
          isSelected={isShown}
          isActive={!!appliedActivityPresetId}
        >
          <span>{Localization.translator.saved_activities}</span>
        </FilterHandleButton>

        {isShown && (
          <SavedFiltersModal
            activityListStore={this.props.activityListStore}
            desktopSavedFiltersStore={this.desktopActivitiesSavedFiltersStore}
          />
        )}
        {shouldSaveFilterDialogShow && (
          <SaveFilterDialog
            filterName={editableFilterName}
            isPublic={editableFilterIsPublic}
            toggleIsPublic={onFilterIsPublicToggle}
            entitiesCountByFilter={activityCountByFilter}
            filtersCount={filtersCount}
            filtersDescriptions={filtersDescriptions}
            clickOnApply={saveCustomFilter}
            clickOnCancel={closeSavedFilters}
            onNameChange={onFilterNameChange}
            desktopSavedFiltersStore={this.desktopActivitiesSavedFiltersStore}
            xEntitiesTranslatorKey={KnownTranslatorKeys.xActivities}
          />
        )}
        {shouldActivityPresetDialogShow && (
          <ActivityPresetDialog
            desktopSavedFiltersStore={this.desktopActivitiesSavedFiltersStore}
          />
        )}
      </div>
    )
  }
}
