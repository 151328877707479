import * as React from 'react'

import { FileInput, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Avatar from '~/client/src/shared/components/Avatar/Avatar'
import { Loader } from '~/client/src/shared/components/Loader'
import AvatarType from '~/client/src/shared/enums/AvatarType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { FileUploadingStore } from '~/client/src/shared/stores/domain/FileUploading.store'

import AvatarEditModal from './AvatarEditModal'
import AvatarInputStore from './AvatarInput.store'

import './AvatarInput.scss'

export type AvatarInputData = { url: string; initials?: string }

interface IProps {
  data: AvatarInputData
  onChange: (newUrl: string) => void
  withEditor?: boolean
  avatarType: AvatarType
  fileUploadingStore?: FileUploadingStore
}

@inject('fileUploadingStore')
@observer
export default class AvatarInput extends React.Component<IProps> {
  private readonly store: AvatarInputStore = null
  private temporaryImageUrl: string = ''

  public constructor(props: IProps) {
    super(props)

    this.store = new AvatarInputStore(props.onChange, props.fileUploadingStore)
  }

  public render() {
    const { errorMessage, isLoading } = this.store
    const { withEditor: isCompact } = this.props

    if (isLoading) {
      return (
        <div className={classList({ 'avatar-loader-container': isCompact })}>
          <Loader />
        </div>
      )
    }

    if (errorMessage) {
      return <span className="text large red">{errorMessage}</span>
    }
    const {
      avatarType,
      data: { url, initials },
    } = this.props
    const { remove } = this.store

    return (
      <div
        className={classList({
          'row avatar-input-container': true,
          'compact-avatar': isCompact,
        })}
      >
        {!isCompact && (
          <Avatar src={url} initials={initials} avatarType={avatarType} />
        )}

        <FileInput
          inputProps={{
            onChange: isCompact
              ? this.displayAvatarEditor
              : this.handleChangeAvatar,
            accept: 'image/*',
            className: 'bare-file-input',
          }}
          text={
            <>
              {isCompact && <Avatar src={url} initials={initials} />}
              <span
                className={classList({
                  'avatar-caption': isCompact,
                  'text large blue-highlight': !isCompact,
                })}
              >
                {Localization.translator.change_verb}
              </span>
            </>
          }
        />
        {!!url && (
          <Icon
            className="no-grow pointer remove-icon"
            icon={IconNames.REMOVE}
            onClick={remove}
          />
        )}
        {this.store.isAvatarEditModalShown && this.renderAvatarEditPopUp()}
      </div>
    )
  }

  private handleChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.store.change(e.target.files[0])
  }

  private displayAvatarEditor = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.store.isAvatarEditModalShown = true
    this.temporaryImageUrl = URL.createObjectURL(e.target.files[0])
    // allow opening the same file twice in a row
    e.target.value = ''
  }

  private renderAvatarEditPopUp = () => {
    return (
      <div className="avatar-edit-modal absolute-block">
        <AvatarEditModal store={this.store} image={this.temporaryImageUrl} />
      </div>
    )
  }
}
