import * as React from 'react'

import { observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'
import LoginView from '~/client/src/shared/components/Login/LoginView'

@observer
export default class DesktopLoginView extends React.Component {
  public render() {
    return (
      <div
        className="col x-center y-center full-height"
        style={{ width: 375, margin: '0 auto' }}
      >
        <Icons.StruxHubFull className="py20" />
        <LoginView />
      </div>
    )
  }
}
