import { isAfter, isSameDay } from 'date-fns'

export function getDefaultStartAndFinishDates(
  date: Date,
  startDate: Date,
  endDate: Date,
  isOneDayMode: boolean,
): { startDate: Date; endDate: Date } {
  let newStartDate = startDate
  let newEndDate = endDate
  if (isOneDayMode) {
    newStartDate = newEndDate = date
  } else {
    if (startDate) {
      if (
        !endDate &&
        (isAfter(date, startDate) || isSameDay(date, startDate))
      ) {
        newEndDate = date
      } else {
        newEndDate = null
        newStartDate = date
      }
    } else {
      newEndDate = null
      newStartDate = date
    }
  }
  return { startDate: newStartDate, endDate: newEndDate }
}
