import * as React from 'react'

import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

// localization: translated

interface IProps {
  isChanged: boolean

  validationMessage?: string
  additionalHelperText?: string
  isValid?: boolean
  isCondensed?: boolean
}

@observer
export default class StruxhubInputHelperText extends React.PureComponent<IProps> {
  public render() {
    const {
      isChanged,
      isValid,
      validationMessage,
      additionalHelperText,
      isCondensed = false,
    } = this.props

    const helperText = []
    const validationText = []

    if (isChanged) {
      validationText.push(Localization.translator.changed)
    }
    if (!isValid && validationMessage) {
      validationText.push(validationMessage)
    }
    if (additionalHelperText) {
      helperText.push(additionalHelperText)
    }

    if (validationText.length) {
      return (
        <div
          className={`px8 ${!isCondensed && 'min-height16 line-16'} text red`}
        >
          {validationText.join(' + ')}
        </div>
      )
    }

    return (
      <div
        className={`px8 ${!isCondensed && 'min-height16 line-16'} text light`}
      >
        {helperText.join(' + ')}
      </div>
    )
  }
}
