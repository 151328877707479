import * as React from 'react'

import { inject, observer } from 'mobx-react'

import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import Localization from '../../localization/LocalizationManager'
import AuthenticationStore from '../../stores/domain/Authentication.store'
import CommonStore from '../../stores/ui/Common.store'
import BaseActionButton from '../BaseActionButton/BaseActionButton'
import { Loader } from '../Loader'
import ExpiredInviteKeyInfoStore from './ExpiredInviteKeyInfo.store'

interface IProps {
  expiredInviteKey: string

  auth?: AuthenticationStore
  common?: CommonStore
  projectDateStore?: ProjectDateStore
}

@inject('auth', 'common', 'projectDateStore')
@observer
export default class ExpiredInviteKeyInfo extends React.Component<IProps> {
  private readonly store: ExpiredInviteKeyInfoStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new ExpiredInviteKeyInfoStore(
      props.expiredInviteKey,
      props.auth,
      props.common,
    )
  }

  public render() {
    const { letsGetLoggedIntoStruxHub, inviteResendingConfirmation } =
      Localization.translator.loginInfoPageDescriptions

    const { wasInviteResent, isLoading } = this.store

    return (
      <>
        <h1 className="ma0">
          {wasInviteResent
            ? inviteResendingConfirmation
            : letsGetLoggedIntoStruxHub}
        </h1>
        <label className="text light uppercase lp15">
          {this.props.projectDateStore.getMonthDayAndYearToDisplay(Date.now())}
        </label>
        <main className="pt20">
          {isLoading ? <Loader /> : this.renderMainContent()}
        </main>
      </>
    )
  }

  private renderMainContent(): JSX.Element {
    if (this.store.wasInviteResent) {
      return (
        <>
          <p className="text header line-26 pb24 ma0">
            {Localization.translator.loginInfoPageDescriptions.sorryForSecurity}
          </p>
          <BaseActionButton
            className="inverse-scale-blue-theme bold ma-x-auto"
            isAnimated={true}
            title={Localization.translator.close}
            onClick={this.store.handleClose}
            isEnabled={true}
          />
        </>
      )
    }

    const { expiredInvitation, newInvitationInfo, resendMyInvitationLink } =
      Localization.translator.loginInfoPageDescriptions

    const { durationOfInviteKeyInHours, handleResendInvitation } = this.store

    return (
      <>
        <p className="text header line-26 ma0">{expiredInvitation}</p>
        <p className="text header line-26 pb24 ma0">
          {newInvitationInfo(durationOfInviteKeyInHours)}
        </p>
        <BaseActionButton
          className="scale-blue-theme bold ma-x-auto"
          isAnimated={true}
          title={resendMyInvitationLink}
          onClick={handleResendInvitation}
          isEnabled={true}
        />
      </>
    )
  }
}
