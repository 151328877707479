import { computed } from 'mobx'

import { LocationType } from '~/client/graph'
import {
  getAccountTypeTranslate,
  projectAccessTypesList,
} from '~/client/src/shared/constants/ProjectRoles'
import {
  getDeliveryStatusAsTag,
  getDeliveryStatusesAsTags,
} from '~/client/src/shared/constants/deliveryStatusesTags'
import {
  accountPositionsList,
  getAccountPositionTranslate,
} from '~/client/src/shared/enums/AccountPosition'
import { TagIconType } from '~/client/src/shared/enums/TagIcon'
import { TagType } from '~/client/src/shared/enums/TagType'
import Tag, { ITag } from '~/client/src/shared/models/Tag'
import User from '~/client/src/shared/models/User'
import { IBaseTagsStore } from '~/client/src/shared/stores/BaseTags.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectRolesStore from '~/client/src/shared/stores/domain/ProjectRoles.store'
import ProjectTeamsStore from '~/client/src/shared/stores/domain/ProjectTeams.store'
import { getBandIconNameByTagType } from '~/client/src/shared/utils/TagHelper'
import { NO_SPECIFIED } from '~/client/src/shared/utils/usefulStrings'

import Zone from '../../models/LocationObjects/Zone'
import UserProject from '../../models/UserProject'
import getCompanyTypeTranslate from '../../utils/getCompanyTypeTranslate'
import LocationAttributesStore from './LocationAttributes.store'
import ProjectDefaultTeamsStore from './ProjectDefaultTeams.store'
import ProjectMembersStore from './ProjectMembers.store'
import ProjectTradesStore from './ProjectTrades.store'
import UserProjectsStore from './UserProjects.store'

import Colors from '~/client/src/shared/theme.module.scss'

export const userRelateTagTypes: TagType[] = [
  TagType.User,
  TagType.Company,
  TagType.GlobalAndProjectSpecificRole,
  TagType.Team,
  TagType.Trade,
  TagType.DefaultTeam,
  TagType.AccountPosition,
  TagType.AccountType,
]

export const userRelateTagTypesWithoutGlobalRoles = [
  TagType.Role,
  ...userRelateTagTypes.filter(
    type => type !== TagType.GlobalAndProjectSpecificRole,
  ),
]

export const deliveryRelatedLocationTagTypes = [
  TagType.Building,
  TagType.Zone,
  TagType.Level,
  TagType.Area,
  TagType.Gate,
  TagType.Route,
  TagType.Staging,
  TagType.OffloadingEquipment,
]

export const deliveryRelatedTagTypes = [
  ...deliveryRelatedLocationTagTypes,
  TagType.Company,
  TagType.Status,
]

export const permitRelatedTagTypes = [
  ...deliveryRelatedTagTypes,

  TagType.VerticalObject,
  TagType.LogisticsObject,
]

const NO = () => false

export default class TagsStore {
  public static getUnspecifiedTagIdByTagType(tagType: TagType) {
    return `${NO_SPECIFIED}_${tagType}`
  }

  public static getHasUserTagPredicate = (
    tagType: TagType,
  ): ((
    user: User,
    tagId: string,
    userProjectsStore?: UserProjectsStore,
  ) => boolean) => {
    switch (tagType) {
      case TagType.Company:
        return UserProject.hasCompanyTag
      case TagType.Role:
      case TagType.GlobalAndProjectSpecificRole:
        return UserProject.hasRoleTag
      case TagType.Team:
        return UserProject.hasTeamTag
      case TagType.DefaultTeam:
        return UserProject.hasDefaultTeamTag
      case TagType.AccountPosition:
        return User.hasAccountPositionTag
      case TagType.AccountType:
        return UserProject.hasAccountTypeTag
      case TagType.Trade:
        return UserProject.hasTradeTag

      default:
        return NO
    }
  }

  public constructor(
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly companiesStore: CompaniesStore,
    private readonly projectTeamsStore: ProjectTeamsStore,
    private readonly projectDefaultTeamsStore: ProjectDefaultTeamsStore,
    private readonly projectRolesStore: ProjectRolesStore,
    private readonly projectTradesStore: ProjectTradesStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly projectMembersStore: ProjectMembersStore,
  ) {}

  @computed
  public get allTags(): Tag[] {
    const lists = Object.values(this.tagListsByTagTypeMap)
    return [].concat(...lists)
  }

  @computed
  public get tagListsWithDefaultsTagsMap(): {
    [tagType in TagType]: ITag[]
  } {
    return Object.assign({}, this.tagListsByTagTypeMap, {
      [TagType.Team]: this.concatTagsWithDefaultTags(TagType.Team),
    })
  }

  @computed
  public get zonesWithNoBuildingParent(): { [zoneId: string]: Zone } {
    return this.locationAttributesStore.zonesStore.list.reduce((map, zone) => {
      const chains = zone.getHierarchyChainObjs(this.tagStoreByTagTypeMap)
      const buldingInChain = chains.find(
        chain => chain.type === LocationType.Building,
      )

      if (!buldingInChain) {
        map[zone.id] = true
      }

      return map
    }, {})
  }

  @computed
  public get tagListsByTagTypeMap(): {
    [tagType in TagType]: ITag[]
  } {
    return {
      [TagType.LogisticsObject]:
        this.locationAttributesStore.logisticsObjectsStore.list,
      [TagType.VerticalObject]:
        this.locationAttributesStore.verticalObjectsStore.list,
      [TagType.Level]: this.locationAttributesStore.levelsStore.list,
      [TagType.Area]: this.locationAttributesStore.areasStore.list,
      [TagType.Building]: this.locationAttributesStore.buildingsStore.list,
      [TagType.Zone]: this.locationAttributesStore.zonesStore.list,
      [TagType.Gate]: this.locationAttributesStore.gatesStore.list,
      [TagType.Route]: this.locationAttributesStore.routesStore.list,
      [TagType.OffloadingEquipment]:
        this.locationAttributesStore.offloadingEquipmentsStore.list,
      [TagType.Company]: this.companiesStore.availableCompaniesAsTags,
      [TagType.Status]: getDeliveryStatusesAsTags(),
      [TagType.Team]: this.projectTeamsStore.list,
      [TagType.Trade]: this.projectTradesStore.list,
      [TagType.DefaultTeam]: this.projectDefaultTeamsStore.list,
      [TagType.Role]: this.projectRolesStore.list,
      [TagType.GlobalAndProjectSpecificRole]: [
        ...this.projectRolesStore.list,
        ...this.globalRolesAsTags,
      ],
      [TagType.User]: this.membersAsTagsList,
      [TagType.AccountPosition]: this.accountPositionAsTagsList,
      [TagType.AccountType]: this.accountTypeAsTagsList,
      [TagType.CompanyType]: this.companyTypeAsTagsList,
      [TagType.Integration]:
        this.locationAttributesStore.locationIntegrationsStore.list,
      [TagType.Staging]: this.locationAttributesStore.stagingsStore.list,
      [TagType.InteriorDoor]:
        this.locationAttributesStore.interiorDoorsStore.list,
      [TagType.InteriorPath]:
        this.locationAttributesStore.interiorPathsStore.list,
    }
  }

  @computed
  public get tagStoreByTagTypeMap(): {
    [tagType in TagType]: IBaseTagsStore
  } {
    return {
      [TagType.Level]: this.locationAttributesStore.levelsStore,
      [TagType.Area]: this.locationAttributesStore.areasStore,
      [TagType.LogisticsObject]:
        this.locationAttributesStore.logisticsObjectsStore,
      [TagType.VerticalObject]:
        this.locationAttributesStore.verticalObjectsStore,
      [TagType.Building]: this.locationAttributesStore.buildingsStore,
      [TagType.Zone]: this.locationAttributesStore.zonesStore,
      [TagType.Gate]: this.locationAttributesStore.gatesStore,
      [TagType.Route]: this.locationAttributesStore.routesStore,
      [TagType.OffloadingEquipment]:
        this.locationAttributesStore.offloadingEquipmentsStore,
      [TagType.Company]: this.companiesStore,
      [TagType.Status]: { getInstanceById: getDeliveryStatusAsTag },
      [TagType.Team]: this.projectTeamsStore,
      [TagType.DefaultTeam]: this.projectDefaultTeamsStore,
      [TagType.Role]: this.projectRolesStore,
      [TagType.User]: {
        getInstanceById: this.getUserTagInstanceById,
      },
      [TagType.AccountPosition]: {
        getInstanceById: instanceId =>
          this.tagListsByTagTypeMap[TagType.AccountPosition].find(
            ({ id }) => id === instanceId,
          ),
      },
      [TagType.GlobalAndProjectSpecificRole]: {
        getInstanceById: instanceId =>
          this.tagListsByTagTypeMap[TagType.GlobalAndProjectSpecificRole].find(
            ({ id }) => id === instanceId,
          ),
      },
      [TagType.AccountType]: {
        getInstanceById: instanceId =>
          this.tagListsByTagTypeMap[TagType.AccountType].find(
            ({ id }) => id === instanceId,
          ),
      },
      [TagType.CompanyType]: {
        getInstanceById: instanceId =>
          this.tagListsByTagTypeMap[TagType.CompanyType].find(
            ({ id }) => id === instanceId,
          ),
      },
      [TagType.Trade]: this.projectTradesStore,
      [TagType.Staging]: this.locationAttributesStore.stagingsStore,
      [TagType.InteriorDoor]: this.locationAttributesStore.interiorDoorsStore,
      [TagType.InteriorPath]: this.locationAttributesStore.interiorPathsStore,
      [TagType.Integration]:
        this.locationAttributesStore.locationIntegrationsStore,
    }
  }

  @computed
  public get defaultTagListsByTagTypeMap(): {
    [tagType: string]: ITag[]
  } {
    return {
      [TagType.Team]: this.projectDefaultTeamsStore.list,
    }
  }

  @computed
  public get defaultTagStoreByTagTypeMap(): {
    [tagType: string]: IBaseTagsStore
  } {
    return {
      [TagType.Team]: this.projectDefaultTeamsStore,
    }
  }

  public get isDataReceived(): boolean {
    return (
      this.locationAttributesStore.isDataReceived &&
      this.companiesStore.isDataReceived &&
      this.projectRolesStore.isDataReceived &&
      this.projectTeamsStore.isDataReceived &&
      this.projectDefaultTeamsStore.isDataReceived &&
      this.userProjectsStore.isDataReceived
    )
  }

  @computed
  public get membersAsTagsList(): Tag[] {
    return this.projectMembersStore.list.map(
      member =>
        new Tag(
          member.id,
          User.getFullNameToDisplay(member, this.userProjectsStore),
          null,
          TagIconType.User,
          TagType.User,
        ),
    )
  }

  @computed
  public get accountPositionAsTagsList(): Tag[] {
    return accountPositionsList.map(
      position =>
        new Tag(
          position,
          getAccountPositionTranslate(position),
          Colors.neutral0,
          null,
          TagType.AccountPosition,
        ),
    )
  }

  @computed
  public get accountTypeAsTagsList(): Tag[] {
    return projectAccessTypesList.map(
      accountType =>
        new Tag(
          accountType,
          getAccountTypeTranslate(accountType),
          null,
          null,
          TagType.AccountType,
        ),
    )
  }

  @computed
  public get companyTypeAsTagsList(): Tag[] {
    return Object.values(this.companiesStore.allCompanyTypeTags).map(
      cType =>
        new Tag(
          cType.id,
          getCompanyTypeTranslate(cType.value),
          cType.color || Colors.neutral60,
          null,
          TagType.CompanyType,
        ),
    )
  }

  @computed
  public get allUsersByRelatedTagIdMap(): { [key: string]: User[] } {
    return this.getUsersByRelatedTagIdMap(this.projectMembersStore.list)
  }

  @computed
  public get usersByRelatedTagIdMap(): { [key: string]: User[] } {
    return this.getUsersByRelatedTagIdMap(this.projectMembersStore.limitedList)
  }

  @computed
  public get globalRolesAsTags(): ITag[] {
    const globalRoles = Array.from(
      new Set(
        this.projectMembersStore.list.map(({ globalRole }) => globalRole),
      ),
    ).filter(role => !!role)

    return globalRoles.map(
      role =>
        new Tag(
          role,
          role,
          Colors.neutral60,
          getBandIconNameByTagType(TagType.Role),
          TagType.Role,
        ),
    )
  }

  public getUsersIdsByTagIds = (tagsIds: string[]): string[] => {
    return tagsIds.reduce((acc, tagId) => {
      const usersIds = (this.usersByRelatedTagIdMap[tagId] || []).map(u => u.id)

      acc.push(...usersIds)
      return acc
    }, [])
  }

  public getTag = (tagType: TagType | string, tagId: string): ITag => {
    const tagStore = this.tagStoreByTagTypeMap[tagType]
    return tagStore && tagStore.getInstanceById(tagId)
  }

  public getTagsByIds = (
    categoryId: string,
    instancesIds: string[],
  ): ITag[] => {
    return (
      this.tagListsByTagTypeMap[categoryId]?.filter(tag =>
        instancesIds?.includes(tag.id),
      ) || []
    )
  }

  public getTagById = (tagId: string): ITag => {
    return this.allTags.find(({ id }) => id === tagId)
  }

  public mapUsersByTagType = (
    users: User[],
    tagType: TagType,
    withEmptyCategory: boolean = true,
  ): { [key: string]: User[] } => {
    const tagIds = (this.tagListsByTagTypeMap[tagType] || []).map(
      ({ id }) => id,
    )

    const distributedUsersByTagTypeIds = []

    const map = tagIds.reduce((a, tagId) => {
      const _users = this.getUsersByTag(tagType, tagId, users)

      if (_users.length) {
        a[tagId] = _users
        distributedUsersByTagTypeIds.push(..._users.map(u => u.id))
      } else if (withEmptyCategory) {
        a[tagId] = []
      }

      return a
    }, {})

    const unspecifiedTagId = TagsStore.getUnspecifiedTagIdByTagType(tagType)

    const undistributedUsers = users.filter(
      u => !distributedUsersByTagTypeIds.includes(u.id),
    )

    if (undistributedUsers.length) {
      map[unspecifiedTagId] = undistributedUsers
    } else if (withEmptyCategory) {
      map[unspecifiedTagId] = []
    }

    return map
  }

  private getUsersByTag(
    tagType: TagType,
    tagId: string,
    projectMembers: User[],
  ): User[] {
    const hasUserTag = TagsStore.getHasUserTagPredicate(tagType)

    return projectMembers.filter(member =>
      hasUserTag(member, tagId, this.userProjectsStore),
    )
  }

  private concatTagsWithDefaultTags = (tagType: TagType): ITag[] => {
    return (
      this.tagListsByTagTypeMap[tagType]
        ?.concat(this.defaultTagListsByTagTypeMap[tagType])
        ?.filter(t => !!t) || []
    )
  }

  private getUsersByRelatedTagIdMap = (
    projectMembers: User[],
  ): { [key: string]: User[] } => {
    return userRelateTagTypes.reduce((acc, tagType) => {
      if (tagType === TagType.User) {
        return acc
      }

      const map = this.mapUsersByTagType(projectMembers, tagType)

      return { ...acc, ...map }
    }, {})
  }

  public getUserTagInstanceById = (instanceId: string): ITag => {
    const tag = this.tagListsByTagTypeMap[TagType.User].find(
      ({ id }) => id === instanceId,
    )

    if (tag) {
      return tag
    }

    // for deleted members
    const member = this.projectMembersStore.getById(instanceId)

    if (!member) {
      return null
    }

    return new Tag(
      member.id,
      User.getFullNameToDisplay(member, this.userProjectsStore),
      null,
      TagIconType.User,
      TagType.User,
    )
  }
}
