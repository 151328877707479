import { action, computed, observable } from 'mobx'

import { IPosition, ISiteLocation } from '~/client/graph'
import BaseMapViewSetUpStore from '~/client/src/shared/components/SitemapHelpers/BaseMapViewSetUp.store'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import Announcement from '~/client/src/shared/models/Announcement'
import SitePermit from '~/client/src/shared/models/Permit'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import { lbsBands } from '~/client/src/shared/types/IHierarchyParent'
import { areArraysEqual } from '~/client/src/shared/utils/util'

import Activity from '../../models/Activity'
import Delivery from '../../models/Delivery'
import LocationBase from '../../models/LocationObjects/LocationBase'
import EventsStore from '../../stores/EventStore/Events.store'
import GlobeViewControlStore from '../../stores/GlobeViewControl.store'
import InitialState from '../../stores/InitialState'
import GlobeViewsStore from '../../stores/domain/GlobeViews.store'
import LocationAttributesStore from '../../stores/domain/LocationAttributes.store'
import SitemapsStore from '../../stores/domain/Sitemaps.store'
import TagsStore from '../../stores/domain/Tags.store'
import DeliverySitemapPinStore from '../../stores/ui/DeliverySitemapPin.store'
import MapBoxViewerStore from '../MapBoxEditor/MapBoxViewer.store'

const MIN_TOP_OFFSET = 70
export default class LogisticsMapViewSetUpStore extends BaseMapViewSetUpStore {
  @observable public topOffset: number = 0
  @observable public leftOffset: number = 0
  @observable public selectedAnnouncements: Announcement[] = []
  @observable public selectedPermits: SitePermit[] = []
  @observable public selectedDeliveries: Delivery[] = []
  @observable public selectedActivities: Activity[] = []
  @observable public selectedAttribute: MapViewItemBase = null
  public readonly mapBoxViewerStore: MapBoxViewerStore
  public readonly globeViewControlStore: GlobeViewControlStore
  private assignedDeliveriesMap: { [attrId: string]: boolean } = {}
  private assignedActivitiesMap: { [attrId: string]: boolean } = {}

  public constructor(
    public readonly sitemapsStore: SitemapsStore,
    public readonly globeViewsStore: GlobeViewsStore,
    protected readonly basemapsStore: BasemapsStore,
    protected readonly sitemapItemsStore: SitemapItemsStore,
    protected readonly locationAttributesStore: LocationAttributesStore,
    private readonly tagsStore: TagsStore,
    state: InitialState,
    eventsStore?: EventsStore,
    private readonly permitId?: () => string,
  ) {
    super(
      sitemapsStore,
      globeViewsStore,
      basemapsStore,
      sitemapItemsStore,
      locationAttributesStore,
    )

    this.globeViewControlStore = new GlobeViewControlStore(
      eventsStore,
      globeViewsStore,
      sitemapItemsStore,
      locationAttributesStore,
    )

    this.mapBoxViewerStore = new MapBoxViewerStore(
      sitemapsStore,
      locationAttributesStore,
      state,
      this.globeViewControlStore,
      basemapsStore,
      eventsStore,
      false,
      () => this.selectedAttribute,
      this.selectItem,
    )
  }

  @computed
  public get hierarchyAttributes(): LocationBase[] {
    return this.locationAttributesStore.allAttributes
  }

  public get displayedPermitId(): string {
    return this.permitId?.()
  }

  public setAnnouncementCardOffset = (
    containerRef: HTMLElement,
    pinStore: DeliverySitemapPinStore,
  ) => {
    const announcementAttribute = this.sortedSitemapAttributes.find(
      attr => attr.id === this.selectedAnnouncements[0].location.id,
    )

    const { clientWidth, clientHeight } = containerRef

    if (!announcementAttribute) {
      this.topOffset = MIN_TOP_OFFSET
      this.leftOffset = 0
      return
    }

    const position = pinStore.getPinPosition(
      announcementAttribute,
      clientWidth,
      clientHeight,
    )

    this.topOffset = position.y
    this.leftOffset = position.x
  }

  public get isGlobeMode() {
    return !!this.globeViewControlStore.selectedGlobeView
  }

  @action.bound
  public selectAnnouncements(
    announcements?: Announcement[],
    top?: number,
    left?: number,
    item?: MapViewItemBase,
    shouldSetViewport?: boolean,
  ) {
    this.selectedAttribute = item !== this.selectedAttribute ? item : null
    this.selectedPermits = []
    this.selectedActivities = []
    this.selectedDeliveries = []

    this.selectedAnnouncements = areArraysEqual(
      this.selectedAnnouncements,
      announcements,
    )
      ? []
      : [...announcements]
    this.topOffset = top
    this.leftOffset = left
    if (shouldSetViewport && this.selectedAttribute && this.isGlobeMode) {
      this.mapBoxViewerStore.setViewportFromItem(this.selectedAttribute)
    }
  }

  @action.bound
  public selectPermits(
    permits?: SitePermit[],
    item?: MapViewItemBase,
    top?: number,
    left?: number,
    shouldSetViewport?: boolean,
  ) {
    if (!this.arePermitsSelected) {
      this.deselectAll()
    }

    this.selectedAttribute = item !== this.selectedAttribute ? item : null

    this.selectedPermits = areArraysEqual(this.selectedPermits, permits)
      ? []
      : [...permits]
    this.topOffset = top
    this.leftOffset = left
    if (shouldSetViewport && this.selectedAttribute && this.isGlobeMode) {
      this.mapBoxViewerStore.setViewportFromItem(this.selectedAttribute)
    }
  }

  @action.bound
  public selectActivities(
    activities?: Activity[],
    item?: MapViewItemBase,
    top?: number,
    left?: number,
    shouldSetViewport?: boolean,
  ) {
    if (!this.areActivitiesSelected) {
      this.deselectAll()
    }

    this.selectedAttribute = item !== this.selectedAttribute ? item : null

    this.selectedActivities = areArraysEqual(
      this.selectedActivities,
      activities,
    )
      ? []
      : [...activities]
    this.topOffset = top
    this.leftOffset = left
    if (shouldSetViewport && this.selectedAttribute && this.isGlobeMode) {
      this.mapBoxViewerStore.setViewportFromItem(this.selectedAttribute)
    }
  }

  @action.bound
  public selectDeliveries(
    deliveries?: Delivery[],
    selectedMapViewItem?: MapViewItemBase,
    top?: number,
    left?: number,
    shouldSetViewport?: boolean,
  ) {
    if (!this.areDeliveriesSelected) {
      this.deselectAll()
    }

    this.selectedAttribute =
      selectedMapViewItem !== this.selectedAttribute
        ? selectedMapViewItem
        : null

    this.selectedDeliveries = areArraysEqual(
      this.selectedDeliveries,
      deliveries,
    )
      ? []
      : [...deliveries]
    this.topOffset = top
    this.leftOffset = left
    if (shouldSetViewport && this.selectedAttribute && this.isGlobeMode) {
      this.mapBoxViewerStore.setViewportFromItem(this.selectedAttribute)
    }
  }

  public selectItem = (item: MapViewItemBase, top?: number, left?: number) => {
    if (
      this.areActivitiesSelected ||
      this.areDeliveriesSelected ||
      this.arePermitsSelected ||
      this.shouldShowAnnouncementsList
    ) {
      this.deselectAll()
    }

    this.topOffset = top
    this.leftOffset = left

    this.selectedAttribute = item === this.selectedAttribute ? null : item
  }

  @action.bound
  public deselectAll() {
    this.selectedPermits = []
    this.selectedAnnouncements = []
    this.selectedDeliveries = []
    this.selectedActivities = []

    this.selectedAttribute = null
    this.topOffset = 0
    this.leftOffset = 0
  }

  public deselectPermits = () => {
    if (this.arePermitsSelected) {
      this.deselectAll()
    }
  }

  public deselectActivities = () => {
    if (this.selectedActivities.length) {
      this.deselectAll()
    }
  }

  public deselectDeliveries = () => {
    if (this.areDeliveriesSelected) {
      this.deselectAll()
    }
  }

  public isAnnouncementDisplayed = (id: string): boolean => {
    return this.selectedAnnouncements.some(
      announcement => announcement.id === id,
    )
  }

  public isPermitDisplayed = (id: string): boolean => {
    return this.selectedPermits.some(permit => permit.id === id)
  }

  public isActivityDisplayed = (code: string): boolean => {
    return this.selectedActivities.some(a => a.code === code)
  }

  public getAnnouncementLocationPosition = (
    announcement: Announcement,
  ): IPosition => {
    if (!announcement?.location) {
      return null
    }

    const relatedItem =
      this.getDisplayedItemById(announcement.location.id) ||
      this.findParentItemByAttrId(announcement.location.id)

    return relatedItem?.sitemapItemProperties?.iconProperties?.position
  }

  public getPermitLocationPositions = (permit: SitePermit): IPosition[] => {
    return this.getLocationPositions(permit?.locationsWithEquipment)
  }

  public getRelatedItems = (locations: ISiteLocation[]): MapViewItemBase[] => {
    if (!locations?.length) {
      return []
    }

    return locations.reduce((list, loc) => {
      const relatedItem =
        this.getDisplayedItemById(loc.id) || this.findParentItemByAttrId(loc.id)

      if (relatedItem?.sitemapItemProperties?.iconProperties?.position) {
        list.push(relatedItem)
      }

      return list
    }, [] as MapViewItemBase[])
  }

  public getLocationPositions = (locations: ISiteLocation[]): IPosition[] => {
    if (!locations?.length) {
      return []
    }

    return locations
      .map(({ id }) => {
        const relatedItem =
          this.getDisplayedItemById(id) || this.findParentItemByAttrId(id)

        return relatedItem?.sitemapItemProperties?.iconProperties?.position
      })
      .filter(pos => pos)
  }

  public findParentItemByAttrId = (id: string): MapViewItemBase => {
    const attribute = this.hierarchyAttributes.find(a => a.id === id)

    const parents = attribute?.getHierarchyChainsObjects(
      this.tagsStore.tagStoreByTagTypeMap,
    )

    let closestParent: MapViewItemBase = null

    parents?.some(
      a => (closestParent = this.getDisplayedItemById(a.id)) && !!closestParent,
    )

    return closestParent
  }

  public getClosestAttrIdToDeliveriesMap = (
    availableDeliveries: Delivery[],
    displayedDeliverySitemapItems: MapViewItemBase[],
  ): { [attrId: string]: Delivery[] } => {
    this.deAssignDeliveries()

    const displayedAttrsIds = displayedDeliverySitemapItems.map(si => si.id)

    return displayedAttrsIds.reduce((acc, attrId) => {
      acc[attrId] = availableDeliveries.filter(
        d => d.isRelatedAttr(attrId) && !this.assignedDeliveriesMap[d.id],
      )
      this.assignDeliveriesToAttrs(acc[attrId].map(d => d.id))
      return acc
    }, {})
  }

  public getClosestAttrIdToActivitiesMap = (
    availableActivities: Activity[],
    displayedActivitySitemapItems: MapViewItemBase[],
  ): { [attrId: string]: Activity[] } => {
    this.deAssignActivities()

    const displayedAttrsIds = displayedActivitySitemapItems.map(si => si.id)

    return displayedAttrsIds.reduce((acc, attrId) => {
      acc[attrId] = availableActivities.filter(
        a =>
          a.locations.some(l => l.id === attrId) &&
          !this.assignedActivitiesMap[a.id],
      )
      this.assignActivitiesToAttrs(acc[attrId].map(d => d.id))
      return acc
    }, {})
  }

  private getDisplayedItemById = (id: string): MapViewItemBase => {
    return this.displayedSitemapItems?.find(item => item.id === id)
  }

  public get shouldShowSelectedAttribute(): boolean {
    return (
      !this.areDeliveriesSelected &&
      !this.areActivitiesSelected &&
      !this.arePermitsSelected &&
      !!this.selectedAttribute?.dataObject
    )
  }

  public get shouldShowAnnouncementsList(): boolean {
    return !!this.selectedAnnouncements.length
  }

  public get arePermitsSelected(): boolean {
    return !!this.selectedPermits.length
  }

  public get areActivitiesSelected(): boolean {
    return !!this.selectedActivities.length
  }

  public get areDeliveriesSelected(): boolean {
    return !!this.selectedDeliveries.length
  }

  public get areAnnouncementsSelected(): boolean {
    return !!this.selectedAnnouncements.length
  }

  @computed
  public get sortedSitemapAttributes(): MapViewItemBase[] {
    return (this.displayedSitemapItems || [])
      .filter(item => item.dataObject)
      .sort((a, b) => {
        const aNodeType = a.dataObject.type
        const bNodeType = b.dataObject.type

        const aIndex = lbsBands.indexOf(aNodeType)
        const bIndex = lbsBands.indexOf(bNodeType)

        return bIndex - aIndex
      })
  }

  private deAssignActivities = () => {
    this.assignedActivitiesMap = {}
  }

  private assignActivitiesToAttrs = (activitiesIds: string[]) => {
    activitiesIds.forEach(id => {
      this.assignedActivitiesMap[id] = true
    })
  }

  private deAssignDeliveries = () => {
    this.assignedDeliveriesMap = {}
  }

  private assignDeliveriesToAttrs = (deliveriesIds: string[]) => {
    deliveriesIds.forEach(id => {
      this.assignedDeliveriesMap[id] = true
    })
  }
}
