import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IDeliveryUnitFieldsFragment = Pick<
  Types.IDeliveryUnit,
  'id' | 'projectId' | 'name' | 'isDeleted'
> & {
  metadata?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IStringPair, 'key' | 'value'>>>
  >
}

export type IGetDeliveryUnitsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetDeliveryUnitsQuery = {
  deliveryUnits?: Types.Maybe<{ data: Array<IDeliveryUnitFieldsFragment> }>
}

export type IListenToDeliveryUnitsSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToDeliveryUnitsSubscription = {
  deliveryUnit?: Types.Maybe<
    Pick<Types.IDeliveryUnitChangeEvent, 'id'> & {
      item?: Types.Maybe<IDeliveryUnitFieldsFragment>
    }
  >
}

export type ISaveDeliveryUnitsMutationVariables = Types.Exact<{
  attrs:
    | Array<Types.Maybe<Types.IDeliveryUnitInput>>
    | Types.Maybe<Types.IDeliveryUnitInput>
}>

export type ISaveDeliveryUnitsMutation = {
  saveManyDeliveryUnits?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IDeliveryUnit, 'id'>>>
  >
}

export type IDeleteDeliveryUnitsMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteDeliveryUnitsMutation = Pick<
  Types.IMutation,
  'softDeleteManyDeliveryUnits'
>

export const DeliveryUnitFieldsFragmentDoc = gql`
  fragment DeliveryUnitFields on DeliveryUnit {
    id
    projectId
    name
    isDeleted
    metadata {
      key
      value
    }
  }
`
export const GetDeliveryUnitsDocument = gql`
  query GetDeliveryUnits($projectId: ObjectId!) {
    deliveryUnits(limit: 1000000, projectId: $projectId) {
      data {
        ...DeliveryUnitFields
      }
    }
  }
  ${DeliveryUnitFieldsFragmentDoc}
`

/**
 * __useGetDeliveryUnitsQuery__
 *
 * To run a query within a React component, call `useGetDeliveryUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryUnitsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetDeliveryUnitsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetDeliveryUnitsQuery,
    IGetDeliveryUnitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetDeliveryUnitsQuery,
    IGetDeliveryUnitsQueryVariables
  >(GetDeliveryUnitsDocument, options)
}
export function useGetDeliveryUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetDeliveryUnitsQuery,
    IGetDeliveryUnitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetDeliveryUnitsQuery,
    IGetDeliveryUnitsQueryVariables
  >(GetDeliveryUnitsDocument, options)
}
export type GetDeliveryUnitsQueryHookResult = ReturnType<
  typeof useGetDeliveryUnitsQuery
>
export type GetDeliveryUnitsLazyQueryHookResult = ReturnType<
  typeof useGetDeliveryUnitsLazyQuery
>
export type GetDeliveryUnitsQueryResult = Apollo.QueryResult<
  IGetDeliveryUnitsQuery,
  IGetDeliveryUnitsQueryVariables
>
export const ListenToDeliveryUnitsDocument = gql`
  subscription ListenToDeliveryUnits($projectId: ObjectId!) {
    deliveryUnit(projectId: $projectId) {
      id
      item {
        ...DeliveryUnitFields
      }
    }
  }
  ${DeliveryUnitFieldsFragmentDoc}
`

/**
 * __useListenToDeliveryUnitsSubscription__
 *
 * To run a query within a React component, call `useListenToDeliveryUnitsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToDeliveryUnitsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToDeliveryUnitsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToDeliveryUnitsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToDeliveryUnitsSubscription,
    IListenToDeliveryUnitsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToDeliveryUnitsSubscription,
    IListenToDeliveryUnitsSubscriptionVariables
  >(ListenToDeliveryUnitsDocument, options)
}
export type ListenToDeliveryUnitsSubscriptionHookResult = ReturnType<
  typeof useListenToDeliveryUnitsSubscription
>
export type ListenToDeliveryUnitsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToDeliveryUnitsSubscription>
export const SaveDeliveryUnitsDocument = gql`
  mutation saveDeliveryUnits($attrs: [DeliveryUnitInput]!) {
    saveManyDeliveryUnits(deliveryUnits: $attrs) {
      id
    }
  }
`
export type ISaveDeliveryUnitsMutationFn = Apollo.MutationFunction<
  ISaveDeliveryUnitsMutation,
  ISaveDeliveryUnitsMutationVariables
>

/**
 * __useSaveDeliveryUnitsMutation__
 *
 * To run a mutation, you first call `useSaveDeliveryUnitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDeliveryUnitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDeliveryUnitsMutation, { data, loading, error }] = useSaveDeliveryUnitsMutation({
 *   variables: {
 *      attrs: // value for 'attrs'
 *   },
 * });
 */
export function useSaveDeliveryUnitsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveDeliveryUnitsMutation,
    ISaveDeliveryUnitsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveDeliveryUnitsMutation,
    ISaveDeliveryUnitsMutationVariables
  >(SaveDeliveryUnitsDocument, options)
}
export type SaveDeliveryUnitsMutationHookResult = ReturnType<
  typeof useSaveDeliveryUnitsMutation
>
export type SaveDeliveryUnitsMutationResult =
  Apollo.MutationResult<ISaveDeliveryUnitsMutation>
export type SaveDeliveryUnitsMutationOptions = Apollo.BaseMutationOptions<
  ISaveDeliveryUnitsMutation,
  ISaveDeliveryUnitsMutationVariables
>
export const DeleteDeliveryUnitsDocument = gql`
  mutation DeleteDeliveryUnits($ids: [ObjectId]!) {
    softDeleteManyDeliveryUnits(ids: $ids)
  }
`
export type IDeleteDeliveryUnitsMutationFn = Apollo.MutationFunction<
  IDeleteDeliveryUnitsMutation,
  IDeleteDeliveryUnitsMutationVariables
>

/**
 * __useDeleteDeliveryUnitsMutation__
 *
 * To run a mutation, you first call `useDeleteDeliveryUnitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeliveryUnitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeliveryUnitsMutation, { data, loading, error }] = useDeleteDeliveryUnitsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteDeliveryUnitsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteDeliveryUnitsMutation,
    IDeleteDeliveryUnitsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteDeliveryUnitsMutation,
    IDeleteDeliveryUnitsMutationVariables
  >(DeleteDeliveryUnitsDocument, options)
}
export type DeleteDeliveryUnitsMutationHookResult = ReturnType<
  typeof useDeleteDeliveryUnitsMutation
>
export type DeleteDeliveryUnitsMutationResult =
  Apollo.MutationResult<IDeleteDeliveryUnitsMutation>
export type DeleteDeliveryUnitsMutationOptions = Apollo.BaseMutationOptions<
  IDeleteDeliveryUnitsMutation,
  IDeleteDeliveryUnitsMutationVariables
>
