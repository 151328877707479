import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IProjectAddressFieldsFragment = Pick<
  Types.IProjectAddress,
  | 'address'
  | 'bearing'
  | 'pitch'
  | 'zoom'
  | 'city'
  | 'country'
  | 'createdAt'
  | 'id'
  | 'state'
  | 'updatedAt'
  | 'zipcode'
  | 'projectId'
> & {
  bounds?: Types.Maybe<{
    ne?: Types.Maybe<Pick<Types.ILatLng, 'lat' | 'lng'>>
    sw?: Types.Maybe<Pick<Types.ILatLng, 'lat' | 'lng'>>
  }>
  center?: Types.Maybe<Pick<Types.ILatLng, 'lat' | 'lng'>>
}

export type IGetProjectAddressQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetProjectAddressQuery = {
  projectAddresses?: Types.Maybe<{ data: Array<IProjectAddressFieldsFragment> }>
}

export type ISaveProjectAddressesMutationVariables = Types.Exact<{
  projectAddresses:
    | Array<Types.Maybe<Types.IProjectAddressInput>>
    | Types.Maybe<Types.IProjectAddressInput>
}>

export type ISaveProjectAddressesMutation = {
  saveManyProjectAddresses?: Types.Maybe<
    Array<Types.Maybe<IProjectAddressFieldsFragment>>
  >
}

export type IListenToProjectAddressSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToProjectAddressSubscription = {
  projectAddress?: Types.Maybe<
    Pick<Types.IProjectAddressChangeEvent, 'id'> & {
      item?: Types.Maybe<IProjectAddressFieldsFragment>
    }
  >
}

export const ProjectAddressFieldsFragmentDoc = gql`
  fragment ProjectAddressFields on ProjectAddress {
    address
    bearing
    bounds {
      ne {
        lat
        lng
      }
      sw {
        lat
        lng
      }
    }
    pitch
    zoom
    center {
      lat
      lng
    }
    city
    country
    createdAt
    id
    state
    updatedAt
    zipcode
    projectId
  }
`
export const GetProjectAddressDocument = gql`
  query GetProjectAddress($projectId: ObjectId!) {
    projectAddresses(projectId: $projectId, limit: 1) {
      data {
        ...ProjectAddressFields
      }
    }
  }
  ${ProjectAddressFieldsFragmentDoc}
`

/**
 * __useGetProjectAddressQuery__
 *
 * To run a query within a React component, call `useGetProjectAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectAddressQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetProjectAddressQuery,
    IGetProjectAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetProjectAddressQuery,
    IGetProjectAddressQueryVariables
  >(GetProjectAddressDocument, options)
}
export function useGetProjectAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetProjectAddressQuery,
    IGetProjectAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetProjectAddressQuery,
    IGetProjectAddressQueryVariables
  >(GetProjectAddressDocument, options)
}
export type GetProjectAddressQueryHookResult = ReturnType<
  typeof useGetProjectAddressQuery
>
export type GetProjectAddressLazyQueryHookResult = ReturnType<
  typeof useGetProjectAddressLazyQuery
>
export type GetProjectAddressQueryResult = Apollo.QueryResult<
  IGetProjectAddressQuery,
  IGetProjectAddressQueryVariables
>
export const SaveProjectAddressesDocument = gql`
  mutation SaveProjectAddresses($projectAddresses: [ProjectAddressInput]!) {
    saveManyProjectAddresses(projectAddresses: $projectAddresses) {
      ...ProjectAddressFields
    }
  }
  ${ProjectAddressFieldsFragmentDoc}
`
export type ISaveProjectAddressesMutationFn = Apollo.MutationFunction<
  ISaveProjectAddressesMutation,
  ISaveProjectAddressesMutationVariables
>

/**
 * __useSaveProjectAddressesMutation__
 *
 * To run a mutation, you first call `useSaveProjectAddressesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProjectAddressesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProjectAddressesMutation, { data, loading, error }] = useSaveProjectAddressesMutation({
 *   variables: {
 *      projectAddresses: // value for 'projectAddresses'
 *   },
 * });
 */
export function useSaveProjectAddressesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveProjectAddressesMutation,
    ISaveProjectAddressesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveProjectAddressesMutation,
    ISaveProjectAddressesMutationVariables
  >(SaveProjectAddressesDocument, options)
}
export type SaveProjectAddressesMutationHookResult = ReturnType<
  typeof useSaveProjectAddressesMutation
>
export type SaveProjectAddressesMutationResult =
  Apollo.MutationResult<ISaveProjectAddressesMutation>
export type SaveProjectAddressesMutationOptions = Apollo.BaseMutationOptions<
  ISaveProjectAddressesMutation,
  ISaveProjectAddressesMutationVariables
>
export const ListenToProjectAddressDocument = gql`
  subscription ListenToProjectAddress($projectId: ObjectId!) {
    projectAddress(projectId: $projectId) {
      id
      item {
        ...ProjectAddressFields
      }
    }
  }
  ${ProjectAddressFieldsFragmentDoc}
`

/**
 * __useListenToProjectAddressSubscription__
 *
 * To run a query within a React component, call `useListenToProjectAddressSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToProjectAddressSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToProjectAddressSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToProjectAddressSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToProjectAddressSubscription,
    IListenToProjectAddressSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToProjectAddressSubscription,
    IListenToProjectAddressSubscriptionVariables
  >(ListenToProjectAddressDocument, options)
}
export type ListenToProjectAddressSubscriptionHookResult = ReturnType<
  typeof useListenToProjectAddressSubscription
>
export type ListenToProjectAddressSubscriptionResult =
  Apollo.SubscriptionResult<IListenToProjectAddressSubscription>
