import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IHistoryItem, IUpdaterInfo } from '~/client/graph'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import getUserName from '~/client/src/shared/utils/GetUserName'

import MaterialsUploadHistoryStore from './MaterialsUploadHistory.store'

// localization: translated

interface IProps {
  isUploadingInProcess: boolean
  materialsUploadHistoryStore: MaterialsUploadHistoryStore

  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
export default class MaterialsUploadHistory extends React.Component<IProps> {
  public render() {
    const {
      latestMaterialsUpdate,
      currentMaterialsItem,
      showRestoreMaterialsModal,
    } = this.store

    const isLatestEqualsCurrent =
      currentMaterialsItem?.materialsUploadId ===
      latestMaterialsUpdate?.materialsUploadId

    return (
      <div>
        {this.renderRestoreConfirmModal()}
        {currentMaterialsItem && (
          <div className="row x-center my20">
            <BaseActionButton
              className="gray-theme"
              isEnabled={!this.props.isUploadingInProcess}
              isAnimated={true}
              title={
                Localization.translator.materialsUploadHistory
                  .restoreDefaultMaterials
              }
              onClick={showRestoreMaterialsModal.bind(null, null)}
            />
          </div>
        )}
        {!isLatestEqualsCurrent && (
          <div className="mt50">
            <div className="text large bold">
              {Localization.translator.materialsUploadHistory.currentMaterials}
            </div>
            <div className="px20 py15 row">
              {!currentMaterialsItem ? (
                <div className="text large grey">
                  {
                    Localization.translator.materialsUploadHistory
                      .projectUsesDefaultMaterials
                  }
                </div>
              ) : (
                this.renderDescription(currentMaterialsItem)
              )}
            </div>
          </div>
        )}
        {!!latestMaterialsUpdate && (
          <div className="mt40">
            <div className="text large bold">
              {Localization.translator.materialsUploadHistory.latestUpload}
            </div>
            <div className="px20 py15 row">
              {this.renderDescription(latestMaterialsUpdate)}
              {this.renderRestoreBtn(
                latestMaterialsUpdate,
                isLatestEqualsCurrent,
              )}
            </div>
          </div>
        )}
        {this.historyUploadSection}
      </div>
    )
  }

  private get historyUploadSection(): JSX.Element {
    const { materialsHistory, currentMaterialsUploadId } = this.store

    if (!materialsHistory?.length) {
      return null
    }

    return (
      <div className="mt40">
        <div className="text large bold">
          {Localization.translator.uploadHistory}
        </div>
        <div className="px20 bb-light-grey mb20">
          {materialsHistory.map((historyItem, index) => (
            <div className="py15 row" key={index}>
              {this.renderDescription(historyItem)}
              {this.renderRestoreBtn(
                historyItem,
                currentMaterialsUploadId === historyItem.materialsUploadId,
              )}
            </div>
          ))}
        </div>
      </div>
    )
  }

  private renderRestoreConfirmModal(): JSX.Element {
    if (!this.store.shouldShowRestoreModal) {
      return null
    }

    const { getMonthDayYearAndTimeToDisplay } = this.props.projectDateStore
    const {
      shouldShowRestoreModal,
      hideRestoreMaterialsModal,
      restorePreviousMaterials,
      historyItemToRestore,
    } = this.store

    return (
      <ConfirmDialog
        isOpen={shouldShowRestoreModal}
        onCancelClicked={hideRestoreMaterialsModal}
        onDoneClicked={restorePreviousMaterials}
        title={
          Localization.translator.materialsUploadHistory
            .materialsRestoringConfirmation
        }
      >
        <div className="text large">
          {!historyItemToRestore
            ? Localization.translator.materialsUploadHistory
                .doYouConfirmRestoreToDefaults
            : [
                ...Localization.translator.doYouConfirmRestoringFileFromDate(
                  historyItemToRestore.fileName,
                  <span
                    key={historyItemToRestore.fileName}
                    className="text large bold"
                  >
                    {getMonthDayYearAndTimeToDisplay(
                      historyItemToRestore.updatedAt,
                    )}
                  </span>,
                ),
              ]}
        </div>
      </ConfirmDialog>
    )
  }

  private renderDescription({
    updatedAt,
    by,
    fileName,
    totalCount,
  }: IHistoryItem): JSX.Element {
    return (
      <div className="text large grey">
        <div className="text large bold grey">
          {this.getDescriptionTitle(updatedAt, by)}
        </div>
        <div>
          <div>{fileName}</div>
          <div>
            {
              Localization.translator.materialsUploadHistory
                .totalMaterialCategoriesAndMaterials
            }
            : {totalCount}
          </div>
        </div>
      </div>
    )
  }

  private renderRestoreBtn = (
    item: IHistoryItem,
    isCurrent: boolean,
  ): JSX.Element => {
    return (
      <BaseActionButton
        className={classList({
          'primary-theme-inverted': !isCurrent,
          'green-theme': isCurrent,
        })}
        isEnabled={!isCurrent && !this.props.isUploadingInProcess}
        isAnimated={true}
        title={
          isCurrent
            ? Localization.translator.current
            : Localization.translator.restore
        }
        onClick={this.store.showRestoreMaterialsModal.bind(
          null,
          item.materialsUploadId,
        )}
      />
    )
  }

  private getDescriptionTitle(timestamp: number, by: IUpdaterInfo): string {
    const { getMonthDayYearAndTimeToDisplay } = this.props.projectDateStore
    const date = getMonthDayYearAndTimeToDisplay(timestamp)

    if (by) {
      const userName = getUserName(by)
      return Localization.translator.dateByName(date, userName)
    }

    return date
  }

  private get store(): MaterialsUploadHistoryStore {
    return this.props.materialsUploadHistoryStore
  }
}
