import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

// localization: no text to translate

const RIGHT_OFFSET = 8

export default function CaretDownSelectorIcon(): JSX.Element {
  return (
    <div
      className="absolute no-grow text light unclickable-element"
      style={{ right: RIGHT_OFFSET }}
    >
      <Icon icon={IconNames.CARET_DOWN} />
    </div>
  )
}
