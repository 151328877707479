import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { IAppConfig } from '~/client/src/shared/Config'
import Localization from '~/client/src/shared/localization/LocalizationManager'

interface IProps {
  configuration?: IAppConfig
  shouldShow: boolean
}

@inject('configuration')
@observer
export default class ClientInfo extends React.Component<IProps> {
  public render() {
    if (!this.props.shouldShow) {
      return null
    }

    const { VERSION, REVISION, BUILD } = this.props.configuration
    return (
      <>
        <div className="text extra small faded">
          {Localization.translator.version}: {VERSION}
        </div>
        <div className="text extra small faded">
          {Localization.translator.revision}: {REVISION}
        </div>
        <div className="text extra small faded">
          {Localization.translator.build}: {BUILD}
        </div>
      </>
    )
  }
}
