import { action, computed } from 'mobx'
import { arrayMove } from 'react-sortable-hoc'

import {
  IDeliveryCardField,
  IDeliveryConfigurations,
  LocationType,
} from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import { MINUTES_IN_HOUR } from '~/client/src/shared/stores/ui/ProjectDate.store'
import DEFAULT_DELIVERY_CARD_CONFIG from '~/client/src/shared/utils/defaultDeliveryCardConfig'
import {
  areObjectArraysEqual,
  customDebounce,
} from '~/client/src/shared/utils/util'

const LOADING_EVENT_NAMES = [
  e.LOAD_AND_LISTEN_TO_DELIVERY_CONFIGURATIONS,
  e.LOAD_AND_LISTEN_TO_DELIVERY_FIELDS_CONFIGURATIONS,
  e.LOAD_AND_LISTEN_TO_MATERIAL_OPTIONS,
]

const nonSortableIds: string[] = [
  FieldIds.COMPANY,
  FieldIds.BOOKING_TIME,
  FieldIds.STATUS,
]

const DEBOUNCE_DELAY = 600

const hidden = 'Hidden'
const mandatory = 'Mandatory'
const optional = 'Optional'

export default class DeliveryCardsSetUpStore {
  private readonly saveConfigurationWithDelay: () => void
  private readonly saveConfigurationColorWithDelay: () => void

  public constructor(private readonly eventsStore: DesktopEventStore) {
    this.saveConfigurationWithDelay = customDebounce(
      this.saveDeliveryConfiguration,
      DEBOUNCE_DELAY,
    )
    this.saveConfigurationColorWithDelay = customDebounce(
      this.saveDeliveryConfigurationColor,
      DEBOUNCE_DELAY,
    )
  }

  @action.bound
  public resetToDefaultConfiguration() {
    if (this.isDefaultConfig) {
      return
    }

    this.deliveryConfiguration.cardFieldsConfig = DEFAULT_DELIVERY_CARD_CONFIG

    this.saveDeliveryConfiguration()
  }

  @action.bound
  public changeDeliveryCardOrder({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number
    newIndex: number
  }) {
    const changedArray = arrayMove(this.sortableFields, oldIndex, newIndex)
    const newConfig = this.nonSortableFields.concat(changedArray)

    const areConfigsEqual = areObjectArraysEqual(
      newConfig || [],
      this.deliveryConfiguration.cardFieldsConfig,
    )

    if (!newConfig?.length || areConfigsEqual) {
      return
    }

    this.deliveryConfiguration.cardFieldsConfig = newConfig

    this.saveDeliveryConfiguration()
  }

  @action.bound
  public toggleFieldVisibility(field: IDeliveryCardField) {
    const isFieldHidden = this.deliverySettings.hiddenFields[field.fieldId]

    if (isFieldHidden) {
      return
    }

    field.isHidden = !field.isHidden

    this.saveConfigurationWithDelay()
  }

  @action.bound
  public setFieldAsMainColor(field: IDeliveryCardField) {
    this.deliveryConfiguration.cardColoringLocationType =
      field.fieldId as LocationType

    this.saveConfigurationColorWithDelay()
  }

  public getDeliveryFieldName = (fieldId: string) => {
    return this.state.getDeliveryFieldName(fieldId)
  }

  public getFieldStatusString = (fieldId: string): string => {
    const isFieldMandatory = this.deliverySettings.mandatoryFields[fieldId]

    return this.isDeliveryFieldHidden(fieldId)
      ? hidden
      : isFieldMandatory
      ? mandatory
      : optional
  }

  public isDeliveryFieldHidden = (fieldId: string): boolean => {
    const { hiddenFields } = this.deliverySettings

    if (fieldId === FieldIds.MATERIAL_CATEGORY) {
      return this.isMaterialSectionHidden || this.areMaterialsCategoryDisabled
    }

    if (fieldId === FieldIds.MATERIAL_NOTE) {
      return this.isMaterialSectionHidden || hiddenFields[fieldId]
    }

    return hiddenFields[fieldId]
  }

  private saveDeliveryConfiguration = () => {
    this.eventsStore.dispatch(e.SAVE_DELIVERY_CONFIGURATIONS, {
      cardFieldsConfig: this.deliveryConfiguration.cardFieldsConfig,
    } as IDeliveryConfigurations)
  }

  private saveDeliveryConfigurationColor = () => {
    this.eventsStore.dispatch(e.SAVE_DELIVERY_CONFIGURATIONS, {
      cardColoringLocationType:
        this.deliveryConfiguration.cardColoringLocationType,
    } as IDeliveryConfigurations)
  }

  @computed
  public get sortableFields(): IDeliveryCardField[] {
    return this.deliveryConfiguration.cardFieldsConfig.filter(
      ({ fieldId }) =>
        !nonSortableIds.includes(fieldId) && fieldId !== FieldIds.ID,
    )
  }

  @computed
  public get nonSortableFields(): IDeliveryCardField[] {
    return this.deliveryConfiguration.cardFieldsConfig.filter(({ fieldId }) =>
      nonSortableIds.includes(fieldId),
    )
  }

  public get timeInterval() {
    return this.deliveryConfiguration.deliveryDuration || MINUTES_IN_HOUR
  }

  public get hourRowsNumber() {
    return Math.round(MINUTES_IN_HOUR / this.timeInterval)
  }

  public get isDefaultConfig(): boolean {
    return areObjectArraysEqual(
      DEFAULT_DELIVERY_CARD_CONFIG,
      this.deliveryConfiguration.cardFieldsConfig,
    )
  }

  public get mainColoringFieldId(): LocationType {
    return (
      this.deliveryConfiguration.cardColoringLocationType || LocationType.Zone
    )
  }

  public get isUpdating(): boolean {
    return this.state.loading.get(e.SAVE_DELIVERY_CONFIGURATIONS)
  }

  public get isLoading(): boolean {
    const { loading } = this.state

    return LOADING_EVENT_NAMES.some(ev => loading.get(ev))
  }

  private get deliveryConfiguration(): IDeliveryConfigurations {
    return this.deliverySettings.configurations
  }

  private get isMaterialSectionHidden(): boolean {
    return this.deliverySettings.hiddenFields[FieldIds.MATERIALS_SECTION]
  }

  private get areMaterialsCategoryDisabled(): boolean {
    const { isCSIDisabled, isCSISubCategoriesDisabled } =
      this.state.projectMaterialOptions
    return isCSIDisabled && isCSISubCategoriesDisabled
  }

  private get deliverySettings() {
    return this.state.delivery
  }

  private get state(): DesktopInitialState {
    return this.eventsStore.appState
  }
}
