import { action, observable } from 'mobx'

import { ITabObject } from '~/client/src/shared/components/Tabs/Tabs'
import Localization from '~/client/src/shared/localization/LocalizationManager'

export enum Tab {
  Dashboard = 'Dashboard',
  Reports = 'Reports',
}

export default class AnalyticsStore {
  @observable public activeTab: Tab = Tab.Reports

  public get tabs(): Array<ITabObject<Tab>> {
    return [
      {
        title: Localization.translator.dashboards,
        page: Tab.Dashboard,
      },
      {
        title: Localization.translator.reports,
        page: Tab.Reports,
      },
    ]
  }

  @action.bound
  public changeActiveTab(tab: Tab) {
    this.activeTab = tab
  }
}
