import { computed, observable } from 'mobx'

import {
  ActivityStatus,
  IActivity,
  IActivityInput,
  IActivityLinkingSettings,
  ISiteLocation,
  OnTimeStatus,
} from '~/client/graph'
import ActivityPlannedStatus from '~/client/src/shared/enums/ActivityPlannedStatus'
import DailyStatus from '~/client/src/shared/enums/DailyStatus'
import BaseModel from '~/client/src/shared/models/BaseModel'
import Day from '~/client/src/shared/models/Day'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'

import locationTypeToActivityLocationFieldIdMap from '../constants/locationTypeToActivityLocationFieldIdMap'
import ActivitiesStore from '../stores/domain/Activities.store'
import StatusUpdatesStore from '../stores/domain/StatusUpdates.store'
import ProjectDateStore, {
  HOURS_IN_DAY,
  MILLISECONDS_IN_HOUR,
  areIntervalTimesIntersects,
  isAfter,
  isBefore,
  isLateDay,
} from '../stores/ui/ProjectDate.store'
import ActivityCode from './ActivityCode'
import CategoryOfVariance from './CategoryOfVariance'
import Company from './Company'
import IActivityToUpdateDates from './IActivityToUpdateDates'
import IActivityTreeItemData from './IActivityTreeItemData'

const ACTIVITY_SHORT = 'ACT'
const ACTIVITY_MARKER = 'A'

export interface IDailyActivity extends Activity {
  dailyStatus: DailyStatus
}

export default class Activity extends BaseModel<IActivity> {
  public static none: Activity
  public static createPlaceholder() {
    const a = new Activity()
    a.updateFromJson({
      actualFinishDate: new Date(),
      actualStartDate: new Date(),
      id: '',
      name: '...',
      code: '',
      plannedFinishDate: new Date(),
      plannedStartDate: new Date(),
      remainingEarlyStartDate: new Date(),
      remainingEarlyFinishDate: new Date(),
      status: null,
      workBreakdownStructureId: null,
      percentComplete: 0,
      scheduleId: null,
      totalFloat: 0,
      isDeleted: false,
    })

    return a
  }

  public status: ActivityStatus
  public name: string
  public wbs: string
  public resourceId: string
  public onTimeStatus?: OnTimeStatus
  @observable public didStart: boolean
  @observable public didFinish: boolean
  public p6ActualDates: {
    start: number
    end: number
  } = {
    start: null,
    end: null,
  }
  @observable
  public dates: {
    planned: {
      duration: {
        ms: number
        days: number
      }
      start: Day
      finish: Day
    }
    actual: {
      start: Day
      finish: Day
      duration: {
        ms: number
        days: number
      }
    }
    remaining: {
      earlyStart: Day
      earlyFinish: Day
    }
  }
  public percentComplete: number = 0
  @observable public codes: ActivityCode[] = []
  @observable public locations: ISiteLocation[] = []
  @observable public company: Company
  @observable public assignedLocations: ISiteLocation[] = []
  @observable public assignedCompanyId: string
  @observable public externalData = {}
  @observable public linkedData = {}
  @observable public requesterId: string
  public activityLinkingSettings: IActivityLinkingSettings
  public location: string
  public isOnCriticalPath: boolean
  public code: string
  public calendarId: string
  public id: string
  public scheduleId: string
  public isDeleted: boolean
  // db id, id itself is used as code
  // TODO: use code everywhere instead of id, so the id field holds db id
  private _id: string
  private totalFloat: number
  private xerEntityId: string

  public constructor(id?: string, private activitiesStore?: ActivitiesStore) {
    super(id)
  }

  public get activityTreeItemData(): IActivityTreeItemData {
    return {
      id: this.id,
      name: this.name,
      didStart: this.didStart,
      didFinish: this.didFinish,
      dates: this.dates,
      isOnCriticalPath: this.isOnCriticalPath,
      companyNames: this.companies,
      companyIds: this.companiesIds,
      activityCodeIds: this.codeIds,
    }
  }

  public formattedId = (sequenceNumber: number): string => {
    const companyFirst3Letters = this.companies
      .join('')
      .split(' ')
      .join('')
      .slice(0, 3)
      .toUpperCase()

    return `${
      companyFirst3Letters || ACTIVITY_SHORT
    }-${ACTIVITY_MARKER}${sequenceNumber}`
  }

  public getActivityToUpdateDates(): IActivityToUpdateDates {
    return {
      id: this.id,
      actualDates: this.dates.actual,
      didStart: this.didStart,
      companiesCount: this.companies.length,
    }
  }

  public setDurations(projectDateStore: ProjectDateStore) {
    const { actual, planned } = this.dates
    const plannedDuration = this.getIntervalWorkingDaysCount(
      planned.start.date,
      planned.finish.date,
      projectDateStore,
      false,
    )
    planned.duration = {
      days: plannedDuration,
      ms: plannedDuration * MILLISECONDS_IN_HOUR * HOURS_IN_DAY,
    }

    if (this.didStart) {
      const finishDate = this.didFinish
        ? actual.finish.date
        : projectDateStore.startOfDay(new Date())
      const actualDuration = this.getIntervalWorkingDaysCount(
        actual.start.date,
        finishDate,
        projectDateStore,
        false,
      )
      actual.duration = {
        days: actualDuration,
        ms: actualDuration * MILLISECONDS_IN_HOUR * HOURS_IN_DAY,
      }
    } else {
      actual.duration = {
        days: 0,
        ms: 0,
      }
    }
  }

  public setActualDates(
    actualStartDateMs: number,
    actualEndDateMs: number,
    projectDateStore: ProjectDateStore,
  ) {
    const { actual } = this.dates

    if (actualStartDateMs && actual.start.ms !== actualStartDateMs) {
      actual.start = Day.fromMs(actualStartDateMs, projectDateStore)
      this.didStart = true
      this.status = ActivityStatus.InProgress
    } else if (!actualStartDateMs) {
      actual.finish = actual.start = Day.Empty
      this.didFinish = this.didStart = false
      this.status = ActivityStatus.NotStarted
      return
    }
    if (
      actualEndDateMs &&
      actual.finish.ms !== actualEndDateMs &&
      actual.start.ms <= actualEndDateMs
    ) {
      actual.finish = Day.fromMs(actualEndDateMs, projectDateStore)
      this.didFinish = true
      this.status = ActivityStatus.Completed
    } else if (!actualEndDateMs) {
      actual.finish = Day.Empty
      this.didFinish = false
      this.status = ActivityStatus.InProgress
    }
    if (this.isCompletedByP6) {
      this.p6ActualDates.end = actualEndDateMs
    }
  }

  public getDailyStatus(
    currentDate: Date,
    projectDateStore: ProjectDateStore,
    statusUpdatesStore?: StatusUpdatesStore,
  ): DailyStatus {
    const finishDate = this.finishDate(projectDateStore, statusUpdatesStore)
    const { isSameDay } = projectDateStore
    switch (true) {
      case isSameDay(currentDate, finishDate):
        return DailyStatus.END_TODAY
      case isSameDay(currentDate, this.startDate):
        return DailyStatus.START_TODAY
      case isLateDay(currentDate, finishDate):
        return DailyStatus.LATE
      default:
        return DailyStatus.DEFAULT
    }
  }

  public updateActualDates(
    projectDateStore: ProjectDateStore,
    startDateMs?: number,
    endDateMs?: number,
  ) {
    this.setActualDates(
      this.p6ActualDates.start || startDateMs,
      this.p6ActualDates.end || endDateMs,
      projectDateStore,
    )
  }

  public updateFromJson(json: IActivity) {
    if (!json) {
      return
    }

    let plannedStartDay = Day.Empty
    let plannedFinishDay = Day.Empty
    let actualStartDay = Day.Empty
    let actualFinishDay = Day.Empty
    let remainingEarlyStartDay = Day.Empty
    let remainingEarlyFinishDay = Day.Empty
    let plannedDuration = 0
    let actualDuration = 0

    if (this.activitiesStore) {
      const daysObj = this.activitiesStore.getActivityParsedDays(json)
      plannedStartDay = daysObj.plannedStartDay
      plannedFinishDay = daysObj.plannedFinishDay
      plannedDuration = daysObj.plannedDuration
      actualStartDay = daysObj.actualStartDay
      actualFinishDay = daysObj.actualFinishDay
      actualDuration = daysObj.actualDuration
      remainingEarlyStartDay = daysObj.remainingEarlyStartDay
      remainingEarlyFinishDay = daysObj.remainingEarlyFinishDay
    }

    this.onTimeStatus = json.onTimeStatus
    this.p6ActualDates.start = actualStartDay.ms
    this.p6ActualDates.end = actualFinishDay.ms

    const activityCode = json.code // stable identifier
    this.id = this.code = activityCode
    this._id = json.id

    this.name = json.name
    this.wbs = json.workBreakdownStructureId
    this.percentComplete = json.percentComplete
    this.didStart = !isNaN(actualStartDay.ms)
    this.didFinish = !isNaN(actualFinishDay.ms)
    this.resourceId = json.resourceId
    this.calendarId = json.calendarId
    this.scheduleId = json.scheduleId
    this.status = json.status
    this.xerEntityId = json.xerEntityId
    this.isDeleted = json.isDeleted

    this.dates = {
      actual: {
        duration: {
          days: actualDuration,
          ms: actualFinishDay.ms - actualStartDay.ms,
        },
        finish: actualFinishDay,
        start: actualStartDay,
      },
      planned: {
        duration: {
          days: plannedDuration,
          ms: plannedFinishDay.ms - plannedStartDay.ms,
        },
        finish: plannedFinishDay,
        start: plannedStartDay,
      },
      remaining: {
        earlyFinish: remainingEarlyFinishDay,
        earlyStart: remainingEarlyStartDay,
      },
    }

    this.p6ActualDates = {
      end: actualFinishDay.ms,
      start: actualStartDay.ms,
    }

    this.requesterId = json.requesterId
    this.assignedLocations = json.locations
    this.assignedCompanyId = json.companyId
    this.activityLinkingSettings = json.activityLinkingSettings

    // totalFloat is empty string when activity is completed
    // we are only interested in zero or negative values to determine the cp
    this.totalFloat = json.totalFloat
    this.isOnCriticalPath = this.totalFloat <= 0

    // mock section
    this.location = `MB-${this.code}, FL-${this.code}`
  }

  public toInput(spreadLocations: boolean = false): IActivityInput {
    return {
      id: this._id || null,
      code: this.code,
      name: this.name,
      percentComplete: this.percentComplete,
      scheduleId: this.scheduleId,
      status: this.status,
      totalFloat: this.totalFloat,
      actualFinishDate: this.p6ActualDates.end,
      actualStartDate: this.p6ActualDates.start,
      calendarId: this.calendarId || null,
      onTimeStatus: this.onTimeStatus,
      plannedFinishDate: this.dates.planned.finish.ms,
      plannedStartDate: this.dates.planned.start.ms,
      remainingEarlyFinishDate: this.dates.remaining.earlyFinish,
      remainingEarlyStartDate: this.dates.remaining.earlyStart,
      resourceId: this.resourceId || null,
      workBreakdownStructureId: this.wbs || null,
      xerEntityId: this.xerEntityId,
      companyId: this.assignedCompanyId,
      requesterId: this.requesterId,
      locations: this.assignedLocations,
      activityLinkingSettings: this.activityLinkingSettings,
      ...(spreadLocations ? this.assignedLocationsByFieldId : {}),
    }
  }

  public isActiveOn(day: Date, projectDateStore: ProjectDateStore) {
    const dayStart = projectDateStore.startOfDay(day).getTime()
    const dayEnd = projectDateStore.endOfDay(day).getTime()

    const start = this.didStart
      ? this.dates.actual.start.ms
      : this.dates.planned.start.ms
    const finish = this.didFinish
      ? this.dates.actual.finish.ms
      : this.dates.planned.finish.ms

    return dayEnd > start && (dayStart < finish || !this.didFinish)
  }

  public isActiveBetween(start: Date, end: Date) {
    const startMs = start.getTime()
    const endMs = end.getTime()
    const activityStartMs = this.plannedStartDate.getTime()
    const activityFinishMs = this.plannedEndDate.getTime()

    const finishesWithinInterval =
      activityFinishMs >= startMs && activityFinishMs <= endMs

    const startsWithinInterval =
      activityStartMs >= startMs && activityStartMs <= endMs

    const durationExceedsInterval =
      activityStartMs <= startMs && activityFinishMs >= endMs

    return (
      finishesWithinInterval || startsWithinInterval || durationExceedsInterval
    )
  }

  public isActiveOrPlannedBetween(
    start: Date,
    end: Date,
    projectDateStore: ProjectDateStore,
    statusUpdatesStore: StatusUpdatesStore,
  ) {
    const startMs = start.getTime()
    const endMs = end.getTime()
    const plannedStartMs =
      this.plannedStartDate &&
      projectDateStore.startOfDay(this.plannedStartDate).getTime()
    const plannedFinishMs =
      this.plannedEndDate &&
      projectDateStore.endOfDay(this.plannedEndDate).getTime()
    const arePlannedDatesExist: boolean = !!plannedFinishMs && !!plannedStartMs
    const plannedDurationMs = arePlannedDatesExist
      ? plannedFinishMs - plannedStartMs
      : 0

    const startDateMs = this.startDate ? this.startDay.ms : 0

    const finishDateMs = this.finishDate
      ? this.finishDay(projectDateStore, statusUpdatesStore).ms
      : startDateMs + plannedDurationMs

    const plannedStartsWithinInterval =
      arePlannedDatesExist &&
      plannedStartMs >= startMs &&
      plannedStartMs <= endMs
    const plannedFinishesWithinInterval =
      arePlannedDatesExist &&
      plannedFinishMs >= startMs &&
      plannedFinishMs <= endMs
    const plannedDurationExceedsInterval =
      arePlannedDatesExist &&
      plannedStartMs <= startMs &&
      plannedFinishMs >= endMs

    const actualStartsWithinInterval =
      startDateMs >= startMs && startDateMs <= endMs
    const actualFinishesWithinInterval =
      finishDateMs >= startMs && finishDateMs <= endMs
    const actualDurationExceedsInterval =
      startDateMs <= startMs && finishDateMs >= endMs

    return (
      plannedStartsWithinInterval ||
      plannedFinishesWithinInterval ||
      plannedDurationExceedsInterval ||
      actualStartsWithinInterval ||
      actualFinishesWithinInterval ||
      actualDurationExceedsInterval
    )
  }

  public get isActivityMarkedAsDoneInP6() {
    if (
      !this.actualEndDate &&
      this.percentComplete === StatusUpdate.MAX_PERCENT
    ) {
      return false
    }
    return true
  }

  @computed
  public get commonStatusUpdate() {
    if (this.activitiesStore) {
      return this.activitiesStore.getCommonStatusUpdateByActivity(this)
    }

    return StatusUpdate.none
  }

  @computed
  public get isDone() {
    return (
      this.didFinish &&
      this.commonStatusUpdate.percentComplete === StatusUpdate.MAX_PERCENT
    )
  }

  public get codeIds(): string[] {
    return this.codes.map(code => code.id)
  }

  @computed
  private get commonStatusUpdateByCompaniesMap() {
    if (!this.companies.length) {
      return {}
    }

    return this.companies.reduce((map, company) => {
      map[company] = this.commonStatusUpdate.getCompanyStatus(
        company,
        this.activitiesStore.projectDateStore.isToday,
      )
      return map
    }, {})
  }

  @computed
  public get assignedLocationsByFieldId() {
    const locationFields = {}

    this.assignedLocations?.forEach(location => {
      locationFields[locationTypeToActivityLocationFieldIdMap[location.type]] =
        location.id
    })

    return locationFields
  }

  @computed
  public get companies() {
    return this.company ? [this.company.name] : []
  }

  @computed
  public get companiesIds() {
    return this.company ? [this.company.id] : []
  }

  public getStatusUpdateForCompany(company: string) {
    return (
      this.commonStatusUpdateByCompaniesMap[company] || this.commonStatusUpdate
    )
  }

  public getDeltaDays(
    projectDateStore: ProjectDateStore,
    company?: string,
  ): number {
    if (!this.didStartOrDidPlanToStart(projectDateStore)) {
      return 0
    }

    if (this.didFinish) {
      return this.getIntervalWorkingDaysCount(
        this.actualEndDate,
        this.plannedEndDate,
        projectDateStore,
      )
    }

    const { percentComplete, isUpdatedToday } =
      this.getStatusUpdateForCompany(company)

    const plannedDuration = this.getWorkingDaysPlannedDuration(projectDateStore)
    const earnedDuration = Math.round(plannedDuration * (percentComplete / 100))

    const today = new Date()
    const yesterday = projectDateStore.addDays(new Date(), -1)
    const baseDate =
      isUpdatedToday && isUpdatedToday(projectDateStore) ? today : yesterday

    const countDaysToBaseDate = this.getIntervalWorkingDaysCount(
      this.plannedStartDate,
      baseDate,
      projectDateStore,
    )

    // expectedDuration cant be negative
    const expectedDuration = Math.max(countDaysToBaseDate, 0)

    return Math.round(earnedDuration - expectedDuration)
  }

  public getIntervalWorkingDaysCount(
    from: Date,
    to: Date,
    projectDateStore: ProjectDateStore,
    shouldExcludeStartDay: boolean = true,
  ): number {
    if (!from || !to) {
      return 0
    }

    const { getFilteredDaysCount } = projectDateStore
    if (isBefore(to, from)) {
      return -getFilteredDaysCount(
        to,
        from,
        this.isWorkingOnDay,
        shouldExcludeStartDay,
      )
    }
    return getFilteredDaysCount(
      from,
      to,
      this.isWorkingOnDay,
      shouldExcludeStartDay,
    )
  }

  @computed
  public get calendar() {
    if (this.activitiesStore) {
      return this.activitiesStore.getActivityCalendar(this)
    }
    return null
  }

  public isWorkingOnDay = (date: Date): boolean => {
    if (this.activitiesStore) {
      return this.activitiesStore.isActivityWorkingOnDay(this, date)
    }
    return true
  }

  public didStartOrDidPlanToStart = (
    projectDateStore: ProjectDateStore,
  ): boolean => {
    const today = projectDateStore.endOfDay(new Date())
    return this.didStart || isBefore(this.plannedStartDate, today)
  }

  private get isCompletedByP6(): boolean {
    return this.percentComplete === StatusUpdate.MAX_PERCENT
  }

  public get asJson(): any {
    return {
      dates: this.dates,
      didFinish: this.didFinish,
      didStart: this.didStart,
      id: this.id,
      name: this.name,
      code: this.code,
      status: this.status,
      wbs: this.wbs,
    }
  }

  public get startDay() {
    if (this.didStart || this.didFinish) {
      return this.dates.actual.start
    }
    const { earlyStart } = this.dates.remaining
    return earlyStart.date ? earlyStart : this.dates.planned.start
  }

  public finishDay = (
    projectDateStore: ProjectDateStore,
    statusUpdatesStore: StatusUpdatesStore,
  ): Day => {
    if (this.didFinish) {
      return this.dates.actual.finish
    }
    if (!this.didStart) {
      return this.dates.planned.finish
    }

    const { earlyFinish } = this.dates.remaining
    const activityStatusUpdates =
      statusUpdatesStore.getLastCompaniesStatusUpdateByActivity(this)
    const lastActivityStatusUpdateCreatedAt =
      activityStatusUpdates.length &&
      Math.max(
        ...activityStatusUpdates.map(statusUpdate => statusUpdate.dateFor),
      )

    // corner case when schedule is not updated so earlyFinish might be earlier than actual start
    // or earlyFinish was not pulled into project (schedule uploaded before this story)
    // finish = last Status update provided that last Status update is after expectedFinishDate
    if (
      !earlyFinish.date ||
      isBefore(earlyFinish.ms, this.dates.actual.start.ms) ||
      isBefore(earlyFinish.ms, lastActivityStatusUpdateCreatedAt)
    ) {
      const expectedFinishDate = projectDateStore.addDays(
        this.dates.actual.start.ms,
        this.plannedDuration - 1,
      )

      if (
        lastActivityStatusUpdateCreatedAt &&
        isAfter(lastActivityStatusUpdateCreatedAt, expectedFinishDate)
      ) {
        return Day.fromMs(lastActivityStatusUpdateCreatedAt, projectDateStore)
      }
      return Day.fromMs(expectedFinishDate.getTime(), projectDateStore)
    }
    return earlyFinish
  }

  public getExpandedStatus({
    isToday,
    addDays,
    endOfDay,
  }: ProjectDateStore): string {
    switch (true) {
      case this.didFinish:
        return ActivityStatus.Completed
      case !this.didStart && isToday(this.plannedStartDate):
        return ActivityStatus.StartsToday
      case isToday(this.plannedEndDate):
        return ActivityStatus.DueToday
      case isToday(addDays(this.plannedEndDate, -1)):
        return ActivityStatus.DueTomorrow
      case !this.didStart &&
        isAfter(endOfDay(new Date()), endOfDay(this.plannedStartDate)):
        return ActivityStatus.LateStart
      case !this.didStart:
        return ActivityStatus.NotStarted
      case isAfter(endOfDay(new Date()), endOfDay(this.plannedEndDate)):
        return ActivityStatus.LateFinish
      default:
        return ActivityStatus.InProgress
    }
  }

  public getOnTimeStatus(
    { endOfDay }: ProjectDateStore,
    deltaDays: number,
    extendedStatus?: boolean,
    categoryOfVariance?: CategoryOfVariance,
  ) {
    const areWorkingDaysNegative = deltaDays < 0
    switch (true) {
      case !!this.onTimeStatus:
        return this.onTimeStatus
      case (this.didFinish && areWorkingDaysNegative) || !!categoryOfVariance:
        return OnTimeStatus.No
      case this.didFinish:
        return OnTimeStatus.Yes
      case extendedStatus &&
        isAfter(endOfDay(new Date()), endOfDay(this.plannedEndDate)):
        return OnTimeStatus.Overdue
      case extendedStatus &&
        !isAfter(endOfDay(new Date()), endOfDay(this.plannedEndDate)) &&
        isAfter(endOfDay(new Date()), endOfDay(this.plannedStartDate)):
        return OnTimeStatus.LateStart
      default:
        return OnTimeStatus.None
    }
  }

  public hasDoneOnTimeStatus = (
    projectDateStore: ProjectDateStore,
  ): boolean => {
    return (
      this.getOnTimeStatus(
        projectDateStore,
        this.getDeltaDays(projectDateStore),
      ) === OnTimeStatus.Yes
    )
  }

  public getPlannedStatus(
    projectDateStore: ProjectDateStore,
    statusUpdatesStore: StatusUpdatesStore,
  ): string {
    const {
      isToday,
      addDays,
      endOfDay,
      isSameWeek,
      getWeekdayToDisplay,
      getMonthAndDayToDisplay,
      countDaysToDate,
    } = projectDateStore

    const finishDate = this.finishDate(projectDateStore, statusUpdatesStore)

    if (!finishDate) {
      return ActivityPlannedStatus.None
    }

    switch (true) {
      case this.didFinish:
        return ActivityPlannedStatus.None

      case !this.didStart &&
        this.plannedStartDate &&
        isToday(this.plannedStartDate):
        return ActivityPlannedStatus.StartsToday
      case !this.didStart &&
        this.plannedStartDate &&
        isToday(addDays(this.plannedStartDate, 1)):
        return ActivityPlannedStatus.StartedYesterday
      case !this.didStart &&
        this.plannedStartDate &&
        isToday(addDays(this.plannedStartDate, -1)):
        return ActivityPlannedStatus.StartsTomorrow

      case isToday(finishDate):
        return ActivityPlannedStatus.DueToday
      case isToday(addDays(finishDate, -1)):
        return ActivityPlannedStatus.DueTomorrow
      case !isAfter(endOfDay(finishDate), endOfDay(new Date())) &&
        isSameWeek(new Date(), finishDate):
        return ActivityPlannedStatus.DueDayOfCurrentWeek.replace(
          '[dayName]',
          getWeekdayToDisplay(finishDate),
        )
      case !isAfter(endOfDay(new Date()), endOfDay(finishDate)):
        return ActivityPlannedStatus.DueMonthDay.replace(
          '[date]',
          getMonthAndDayToDisplay(finishDate),
        )

      case isToday(addDays(finishDate, 1)):
        return ActivityPlannedStatus.DueYesterday
      default:
        return ActivityPlannedStatus.DueXDaysAgo.replace(
          '[x]',
          (
            -1 * countDaysToDate(endOfDay(new Date()), endOfDay(finishDate))
          ).toString(),
        )
    }
  }

  public get actualStatus(): string {
    switch (true) {
      case this.onTimeStatus === OnTimeStatus.No:
        return ActivityStatus.Incomplete
      case !this.didStart:
        return ActivityStatus.NotStarted
      case this.didFinish:
        return ActivityStatus.Done
      default:
        return ActivityStatus.InProgress
    }
  }

  public get databaseId(): string {
    return this._id
  }

  public get startDate(): Date {
    return this.startDay.date
  }

  public finishDate = (
    projectDateStore: ProjectDateStore,
    statusUpdatesStore: StatusUpdatesStore,
  ): Date => {
    return this.finishDay(projectDateStore, statusUpdatesStore).date
  }

  public startDayFormatted(projectDateStore: ProjectDateStore): string {
    const date = projectDateStore.getWeekdayMonthDayAndYearToDisplay(
      this.startDay.date,
    )
    return date ? `${date}${this.didStart ? ' A' : ''}` : null
  }

  public finishDayFormatted(
    projectDateStore: ProjectDateStore,
    statusUpdatesStore: StatusUpdatesStore,
  ): string {
    const date = projectDateStore.getWeekdayMonthDayAndYearToDisplay(
      this.finishDay(projectDateStore, statusUpdatesStore).date,
    )
    return date ? `${date}${this.didFinish ? ' A' : ''}` : null
  }

  public plannedStartDayFormatted(projectDateStore: ProjectDateStore): string {
    const date = projectDateStore.getWeekdayMonthDayAndYearToDisplay(
      this.plannedStartDate,
    )
    return date
  }

  public plannedFinishDayFormatted(projectDateStore: ProjectDateStore): string {
    const date = projectDateStore.getWeekdayMonthDayAndYearToDisplay(
      this.plannedEndDate,
    )
    return date
  }

  public actualStartDayFormatted(projectDateStore: ProjectDateStore): string {
    const date = projectDateStore.getWeekdayMonthDayAndYearToDisplay(
      this.actualStartDate,
    )
    return date
  }

  public actualFinishDayFormatted(projectDateStore: ProjectDateStore): string {
    const date = projectDateStore.getWeekdayMonthDayAndYearToDisplay(
      this.actualEndDate,
    )
    return date
  }

  public get plannedStartDay(): string {
    return this.dates.planned.start.formatedDate
  }

  public get plannedEndDay(): string {
    return this.dates.planned.finish.formatedDate
  }

  public get plannedStartDate(): Date {
    return this.dates.planned.start.date
  }

  public get plannedEndDate(): Date {
    return this.dates.planned.finish.date
  }

  public get actualStartDate(): Date {
    return this.didStart && this.dates.actual.start.date
  }

  public get actualEndDate(): Date {
    return this.didFinish && this.dates.actual.finish.date
  }

  public get plannedDuration(): number {
    return this.dates.planned.duration.days
  }

  public getWorkingDaysPlannedDuration(
    projectDateStore: ProjectDateStore,
  ): number {
    const { start, finish } = this.dates.planned
    return this.getIntervalWorkingDaysCount(
      start.date,
      finish.date,
      projectDateStore,
    )
  }

  public get actualStartDay(): string {
    return this.didStart ? this.dates.actual.start.formatedDate : ' - '
  }

  public get actualEndDay(): string {
    return this.didFinish ? this.dates.actual.finish.formatedDate : ' - '
  }

  public get actualDuration(): number {
    return this.dates.actual.duration.days
  }

  public getTodayPercentPosition = (projectDateStore: ProjectDateStore) => {
    return this.getPlannedPercentCompleteOnDate(Date.now(), projectDateStore)
  }

  public getPlannedPercentComplete = (projectDateStore: ProjectDateStore) => {
    return Math.round(this.getTodayPercentPosition(projectDateStore))
  }

  public getRemainingDuration(company: string): number {
    const { percentComplete } = this.getStatusUpdateForCompany(company)
    const earnedDuration = Math.round(
      this.plannedDuration * (percentComplete / 100),
    )
    const remainingDuration = Math.round(this.plannedDuration - earnedDuration)

    return this.actualStartDate ? remainingDuration : this.plannedDuration
  }

  public getPlannedPercentCompleteOnDate = (
    timestamp: number,
    projectDateStore: ProjectDateStore,
  ) => {
    const {
      start,
      duration: { days: duration },
    } = this.dates.planned
    let todayPercentPosition
    const passedDays = projectDateStore.countDaysToDate(start.ms, timestamp)
    switch (true) {
      case passedDays <= 0:
        todayPercentPosition = 0
        break
      case passedDays <= duration:
        todayPercentPosition = (passedDays / duration) * 100
        break
      case passedDays > duration:
        todayPercentPosition = 100
        break
    }

    return todayPercentPosition
  }

  public isPlannedWithinRange = (startDate: Date, endDate: Date): boolean => {
    return areIntervalTimesIntersects(
      {
        startDate: new Date(this.plannedStartDate),
        endDate: new Date(this.plannedEndDate),
      },
      {
        startDate: startDate,
        endDate: endDate,
      },
    )
  }
}

Activity.none = Activity.createPlaceholder()
