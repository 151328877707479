import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import Activity from '~/client/src/shared/models/Activity'
import { ChartDay } from '~/client/src/shared/models/ChartDay'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import ProjectDateStore, {
  isWithinRange,
} from '~/client/src/shared/stores/ui/ProjectDate.store'

import ActivityGanttOrListViewStore from '../../ActivityGanttOrListView.store'

const DAY_IN_MS = 86400000

interface IActivityDayBarProps {
  activity: Activity
  day: ChartDay
  isFirstDayOnChart: boolean
  isHoliday: boolean
  isLastDayOnChart: boolean
  company?: string
  activityListStore: DesktopActivityListStore
  store: ActivityGanttOrListViewStore
  projectDateStore?: ProjectDateStore
  statusUpdatesStore?: StatusUpdatesStore
  state?: DesktopInitialState
}

@inject('projectDateStore', 'statusUpdatesStore', 'state')
@observer
export default class ActivityDayBar extends React.Component<IActivityDayBarProps> {
  public render() {
    const {
      activity,
      day,
      isLastDayOnChart,
      isFirstDayOnChart,
      company,
      isHoliday,
      projectDateStore,
      statusUpdatesStore,
      state,
    } = this.props

    const { isWWPColoringDisabled } = state
    const { start: plannedStart, finish: plannedFinish } =
      activity.dates.planned

    const { startOfDay, endOfDay, isSameDay } = this.props.projectDateStore
    const plannedStartMs = plannedStart?.ms
      ? startOfDay(plannedStart.ms).getTime()
      : 0
    const plannedFinishMs = plannedFinish?.ms
      ? endOfDay(plannedFinish.ms).getTime()
      : 0

    const finishDayMs = activity.finishDay(
      projectDateStore,
      statusUpdatesStore,
    ).ms
    const startMs = activity.startDay.ms
      ? startOfDay(activity.startDay.ms).getTime()
      : 0
    const finishMs = finishDayMs ? endOfDay(finishDayMs).getTime() : 0
    const durationMs = finishMs - startMs

    const arePlannedDatesValid =
      !!plannedStartMs && !!plannedFinishMs && plannedStartMs < plannedFinishMs
    const isPlannedActive = arePlannedDatesValid
      ? isWithinRange(day.ms, plannedStartMs, plannedFinishMs)
      : false

    const areDatesValid = !!startMs && !!finishMs && startMs < finishMs
    const isActive =
      activity.didStart && areDatesValid
        ? activity.didStart && isWithinRange(day.ms, startMs, finishMs)
        : false

    if (!isPlannedActive && !isActive) {
      return null
    }
    const { percentComplete } = activity.getStatusUpdateForCompany(company)

    const durationCompletedMs = startMs + (durationMs * percentComplete) / 100

    const dayEnd = endOfDay(day.ms)

    const isDone =
      !!percentComplete && !!startMs && !!durationCompletedMs
        ? isWithinRange(dayEnd, startMs, durationCompletedMs)
        : false

    const isLeftValue =
      !isDone && isWithinRange(durationCompletedMs, day.ms, dayEnd)

    const leftValueMs = Math.round(durationCompletedMs) - day.ms

    const leftPercent = (leftValueMs / DAY_IN_MS) * 100

    const isEndDate = isSameDay(finishMs, day.date)
    const isPlannedGreaterThenActual = plannedFinishMs > finishDayMs
    const isStartDate = isSameDay(startMs, day.date)
    const isPlannedStartDate =
      plannedStart.year === day.year &&
      plannedStart.month === day.month &&
      plannedStart.day === day.day

    const isPlannedFinishDate =
      plannedFinish.year === day.year &&
      plannedFinish.month === day.month &&
      plannedFinish.day === day.day

    const isDayBeforePlannedFinish =
      plannedFinish.ms &&
      isSameDay(day.ms, projectDateStore.addDays(plannedFinish.ms, -1))

    const isDefaultColoring = !isDayBeforePlannedFinish && !isPlannedFinishDate
    const shouldShowLeftArrow =
      isFirstDayOnChart && (day.ms > plannedStartMs || day.ms > startMs)
    const dayEndMs = dayEnd.getTime()
    const shouldShowRightArrow =
      isLastDayOnChart && (dayEndMs < plannedFinishMs || dayEndMs < finishMs)

    const shouldRenderCompanyName =
      (activity.didStart && isStartDate) ||
      (!activity.didStart && isPlannedStartDate)

    return (
      <div className="mt20">
        {(shouldShowLeftArrow || shouldShowRightArrow) &&
          this.renderArrow(shouldShowLeftArrow, isDone)}
        {shouldRenderCompanyName && (
          <div className="text bold days-company-label top">{company}</div>
        )}
        {isActive && (
          <div
            className={classList({
              'gant-activity-bar gantt-cell': true,
              inactive: !activity.didStart,
              cp: activity.isOnCriticalPath,
              'start-day': isStartDate,
              'end-day': isEndDate,
              'completed-day': isDone,
            })}
          >
            {isLeftValue && (
              <div
                className="half-completed-day"
                style={{ width: `${leftPercent}%` }}
              />
            )}
            {!percentComplete && isStartDate && (
              <>
                <div className="half-completed-day no-percent" />
                <div className="no-percent-started-day" />
              </>
            )}
            {isHoliday && (
              <Icon icon={IconNames.CROSS} className="holiday-icon" />
            )}
            {!isPlannedGreaterThenActual && isEndDate && (
              <div className="text bold left activity-name-label">
                {activity.name}
              </div>
            )}
          </div>
        )}
        {isPlannedActive && (
          <>
            {!activity.didStart && (
              <div
                className={classList({
                  'gant-activity-bar gantt-cell inactive': true,
                  cp: activity.isOnCriticalPath,
                  'start-day': isPlannedStartDate,
                  'end-day': isPlannedFinishDate,
                })}
              />
            )}
            <div
              className={classList({
                'gant-planned-bar': true,
                cp: activity.isOnCriticalPath && isWWPColoringDisabled,
                mt15: activity.didStart && !isActive,
                'start-day': isPlannedStartDate,
                'end-day': isPlannedFinishDate,
                'end-day-wwp-coloring':
                  isPlannedFinishDate && !isWWPColoringDisabled,
                'day-before-finish-wwp-coloring':
                  isDayBeforePlannedFinish && !isWWPColoringDisabled,
                'default-wwp-coloring':
                  isDefaultColoring && !isWWPColoringDisabled,
              })}
            />
            {isPlannedGreaterThenActual && isPlannedFinishDate && (
              <div className="text bold left activity-name-label">
                {activity.name}
              </div>
            )}
          </>
        )}
      </div>
    )
  }

  private renderArrow(isLeftArrow: boolean, isDone: boolean) {
    const onClick = isLeftArrow
      ? this.handleLeftArrowClick
      : this.handleRightArrowClick
    return (
      <div
        className={classList({
          'move-to-activity-holder': true,
          left: isLeftArrow,
          right: !isLeftArrow,
        })}
        onClick={onClick}
      >
        <div
          className={classList({
            arrow: true,
            left: isLeftArrow,
            right: !isLeftArrow,
            blue: !isDone,
            white: isDone,
          })}
        />
      </div>
    )
  }

  @action.bound
  private handleLeftArrowClick(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    const { activity, activityListStore, company, store } = this.props
    store.selectCompany(company)
    activityListStore.moveToActivityStart(activity)
  }

  @action.bound
  private handleRightArrowClick(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    const { activity, activityListStore, company, store } = this.props
    store.selectCompany(company)
    activityListStore.moveToActivityFinish(activity)
  }
}
