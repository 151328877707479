import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IZoneMapThresholdsFieldsFragment = Pick<
  Types.IZoneMapThresholds,
  | 'id'
  | 'deltaDaysValue'
  | 'deltaDaysBehindValue'
  | 'deltaDaysUnit'
  | 'manpowerValue'
  | 'manpowerUnit'
  | 'isManpowerDisabled'
  | 'projectId'
>

export type IGetZoneMapThresholdsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetZoneMapThresholdsQuery = {
  zoneMapThresholds?: Types.Maybe<{
    data: Array<IZoneMapThresholdsFieldsFragment>
  }>
}

export type ISaveZoneMapThresholdsMutationVariables = Types.Exact<{
  zoneMapThresholds: Types.IZoneMapThresholdsInput
}>

export type ISaveZoneMapThresholdsMutation = {
  saveZoneMapThresholds?: Types.Maybe<IZoneMapThresholdsFieldsFragment>
}

export type IListenToZoneMapThresholdsSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToZoneMapThresholdsSubscription = {
  zoneMapThresholds?: Types.Maybe<
    Pick<Types.IZoneMapThresholdsChangeEvent, 'id'> & {
      item?: Types.Maybe<IZoneMapThresholdsFieldsFragment>
    }
  >
}

export const ZoneMapThresholdsFieldsFragmentDoc = gql`
  fragment ZoneMapThresholdsFields on ZoneMapThresholds {
    id
    deltaDaysValue
    deltaDaysBehindValue
    deltaDaysUnit
    manpowerValue
    manpowerUnit
    isManpowerDisabled
    projectId
  }
`
export const GetZoneMapThresholdsDocument = gql`
  query GetZoneMapThresholds($projectId: ObjectId!) {
    zoneMapThresholds(projectId: $projectId, limit: 1) {
      data {
        ...ZoneMapThresholdsFields
      }
    }
  }
  ${ZoneMapThresholdsFieldsFragmentDoc}
`

/**
 * __useGetZoneMapThresholdsQuery__
 *
 * To run a query within a React component, call `useGetZoneMapThresholdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZoneMapThresholdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZoneMapThresholdsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetZoneMapThresholdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetZoneMapThresholdsQuery,
    IGetZoneMapThresholdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetZoneMapThresholdsQuery,
    IGetZoneMapThresholdsQueryVariables
  >(GetZoneMapThresholdsDocument, options)
}
export function useGetZoneMapThresholdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetZoneMapThresholdsQuery,
    IGetZoneMapThresholdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetZoneMapThresholdsQuery,
    IGetZoneMapThresholdsQueryVariables
  >(GetZoneMapThresholdsDocument, options)
}
export type GetZoneMapThresholdsQueryHookResult = ReturnType<
  typeof useGetZoneMapThresholdsQuery
>
export type GetZoneMapThresholdsLazyQueryHookResult = ReturnType<
  typeof useGetZoneMapThresholdsLazyQuery
>
export type GetZoneMapThresholdsQueryResult = Apollo.QueryResult<
  IGetZoneMapThresholdsQuery,
  IGetZoneMapThresholdsQueryVariables
>
export const SaveZoneMapThresholdsDocument = gql`
  mutation SaveZoneMapThresholds($zoneMapThresholds: ZoneMapThresholdsInput!) {
    saveZoneMapThresholds(zoneMapThresholds: $zoneMapThresholds) {
      ...ZoneMapThresholdsFields
    }
  }
  ${ZoneMapThresholdsFieldsFragmentDoc}
`
export type ISaveZoneMapThresholdsMutationFn = Apollo.MutationFunction<
  ISaveZoneMapThresholdsMutation,
  ISaveZoneMapThresholdsMutationVariables
>

/**
 * __useSaveZoneMapThresholdsMutation__
 *
 * To run a mutation, you first call `useSaveZoneMapThresholdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveZoneMapThresholdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveZoneMapThresholdsMutation, { data, loading, error }] = useSaveZoneMapThresholdsMutation({
 *   variables: {
 *      zoneMapThresholds: // value for 'zoneMapThresholds'
 *   },
 * });
 */
export function useSaveZoneMapThresholdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveZoneMapThresholdsMutation,
    ISaveZoneMapThresholdsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveZoneMapThresholdsMutation,
    ISaveZoneMapThresholdsMutationVariables
  >(SaveZoneMapThresholdsDocument, options)
}
export type SaveZoneMapThresholdsMutationHookResult = ReturnType<
  typeof useSaveZoneMapThresholdsMutation
>
export type SaveZoneMapThresholdsMutationResult =
  Apollo.MutationResult<ISaveZoneMapThresholdsMutation>
export type SaveZoneMapThresholdsMutationOptions = Apollo.BaseMutationOptions<
  ISaveZoneMapThresholdsMutation,
  ISaveZoneMapThresholdsMutationVariables
>
export const ListenToZoneMapThresholdsDocument = gql`
  subscription ListenToZoneMapThresholds($projectId: ObjectId!) {
    zoneMapThresholds(projectId: $projectId) {
      id
      item {
        ...ZoneMapThresholdsFields
      }
    }
  }
  ${ZoneMapThresholdsFieldsFragmentDoc}
`

/**
 * __useListenToZoneMapThresholdsSubscription__
 *
 * To run a query within a React component, call `useListenToZoneMapThresholdsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToZoneMapThresholdsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToZoneMapThresholdsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToZoneMapThresholdsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToZoneMapThresholdsSubscription,
    IListenToZoneMapThresholdsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToZoneMapThresholdsSubscription,
    IListenToZoneMapThresholdsSubscriptionVariables
  >(ListenToZoneMapThresholdsDocument, options)
}
export type ListenToZoneMapThresholdsSubscriptionHookResult = ReturnType<
  typeof useListenToZoneMapThresholdsSubscription
>
export type ListenToZoneMapThresholdsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToZoneMapThresholdsSubscription>
