import { LocalStorageKey } from '../enums/LocalStorageKey'

export default class LocalStorageHelper {
  public static setUserPreferences(
    userId: string,
    preferences: { [key in LocalStorageKey]?: any },
  ) {
    const userPreferences = JSON.parse(localStorage.getItem(userId)) || {}
    Object.assign(userPreferences, preferences)
    localStorage.setItem(userId, JSON.stringify(userPreferences))
  }

  public static getUserPreferenceByKey(
    userId: string,
    preferenceKey: LocalStorageKey,
  ): string {
    const userPreferences = JSON.parse(localStorage.getItem(userId)) || {}
    return userPreferences[preferenceKey] || ''
  }
}
