import * as React from 'react'

import { FileInput, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import EntityNameFilter from '~/client/src/desktop/components/Filters/EntityNameFilter/EntityNameFilter'
import MemberFilters from '~/client/src/desktop/components/MemberFilters/MemberFilters'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { MemberFiltersStore } from '~/client/src/desktop/stores/ui/MemberFilters.store'
import ProjectSetUpPageStore from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPage.store'
import ProjectMembers from '~/client/src/desktop/views/ProjectSetUp/components/ProjectMembersUpload/ProjectMembers/ProjectMembersList'
import AlertDialog from '~/client/src/shared/components/AlertDialog/AlertDialog'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import ConfirmDialogTypes from '~/client/src/shared/enums/ConfirmDialogTypes'
import FileType from '~/client/src/shared/enums/FileType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import AuthenticationStore from '~/client/src/shared/stores/domain/Authentication.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ProjectRolesStore from '~/client/src/shared/stores/domain/ProjectRoles.store'
import ProjectTeamsStore from '~/client/src/shared/stores/domain/ProjectTeams.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import DndArea from '../../../../../shared/components/DndArea/DndArea'
import UploadButton from '../../../../../shared/components/UploadButton/UploadButton'
import { Header, Wrapper } from '../SetupPageLayout/SetupPageLayout'
import DesktopUserDirectoryStore, {
  UploadingState,
} from './DesktopUserDirectory.store'
import ProjectMembersListStore from './ProjectMembers/ProjectMembersList.store'
import AddEditMemberDialog from './ProjectMembers/components/AddEditMemberDialog'
import BulkEditMembersSideBar from './ProjectMembers/components/BulkEditMembersSideBar/BulkEditMembersSideBar'
import ConfirmUsersDialog from './ProjectMembers/components/ConfirmUsersDialog/ConfirmUsersDialog'
import ProcoreDirectoryDialog from './ProjectMembers/components/ProcoreDirectoryDialog/ProcoreDirectoryDialog'

import './ProjectMembersUpload.scss'

interface IProps {
  projectSetUpPageStore: ProjectSetUpPageStore

  auth?: AuthenticationStore
  state?: DesktopInitialState
  eventsStore?: DesktopEventStore
  projectRolesStore?: ProjectRolesStore
  projectTeamsStore?: ProjectTeamsStore
  companiesStore?: CompaniesStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
}

enum DropdownStates {
  downloadTeamMembers = 'Download team members .csv',
  uploadTeamMembers = 'Upload team members .csv',
  viewCompanyDirectory = 'View company directory',
  connectToProcore = 'Connect to Procore team directory',
}

@inject(
  'auth',
  'state',
  'eventsStore',
  'projectRolesStore',
  'projectTeamsStore',
  'companiesStore',
  'userProjectsStore',
  'projectMembersStore',
)
@observer
export default class ProjectMembersUpload extends React.Component<IProps> {
  private dndArea: DndArea

  private readonly store: DesktopUserDirectoryStore = null
  private readonly membersListStore: ProjectMembersListStore = null
  private readonly memberFilterStore: MemberFiltersStore = null

  public constructor(props: IProps) {
    super(props)

    this.membersListStore = new ProjectMembersListStore(
      props.state,
      props.companiesStore,
      props.userProjectsStore,
      props.projectMembersStore,
    )

    this.store = new DesktopUserDirectoryStore(
      props.eventsStore,
      props.projectSetUpPageStore,
      props.projectRolesStore,
      props.projectTeamsStore,
      props.companiesStore,
      this.membersListStore,
      props.userProjectsStore,
      props.projectMembersStore,
    )

    this.memberFilterStore = new MemberFiltersStore(
      props.eventsStore,
      props.projectMembersStore,
      props.userProjectsStore,
      this.membersListStore.getFilteredCollectionExcludeFilter,
    )
  }

  public componentDidMount() {
    if (localStorage.getItem('continueProjectSetup')) {
      localStorage.removeItem('continueProjectSetup')
    }
  }

  public render() {
    return (
      <Wrapper>
        <Header className="row" title={Localization.translator.userDirectory} />

        <div className="col scrollable mx30 project-members-upload-content">
          {this.renderAddEditMemberDialog()}
          {this.renderUserTabContent()}
        </div>
      </Wrapper>
    )
  }

  public renderUserTabContent(): JSX.Element {
    const { loading } = this.props.state

    const isProjectLoading = [
      e.ACTIVATE_PROJECT,
      e.GET_PROJECT_MEMBERS,
      e.GET_SCHEDULE,
      e.GET_USERS_FROM_PROCORE,
      e.LOAD_AND_LISTEN_TO_COMPANIES,
      e.LOAD_AND_LISTEN_TO_USER_PROJECTS,
    ].some(event => loading.get(event))

    if (isProjectLoading) {
      return <Loader hint={Localization.translator.loadingTeamMembers} />
    }

    const {
      errorMessage,
      isAlertOpen,
      membersToInvite,
      membersToInviteFromHiddenSelection,
      membersToSetAsNotInvited,
      procoreCompanies,
      lastUpdatedUserId,
      closeProcoreDirectory,
      updateMembersSettings,
      addMembersFromProcore,
      handleInviteMembersFromList,
      setMembersAsNotInvited,
      deleteMembers,
      closeAlert,
      toggleConfirmInviteDialog,
      hideBulkEditMembersSideBar,
      toggleConfirmSetAsNotInvitedDialog,
      toggleConfirmDeleteDialog,
      selectedMembers,
      showAddMemberDialog,
      handleEditMember,
      showConfirmInviteDialog,
      dialogs: {
        shouldShowMemberDetails,
        shouldShowBulkEditMembers,
        shouldShowProcoreDirectory,
        shouldShowConfirmInviteDialog,
        shouldShowConfirmDeleteDialog,
        shouldShowConfirmSetAsNotInvitedDialog,
      },
    } = this.store

    const areMembersBeingInvited = loading.get(e.INVITE_PROJECT_MEMBERS)
    const areMembersBeingSetAsNotInvited = loading.get(
      e.SET_PROJECT_MEMBERS_AS_NOT_INVITED,
    )
    const areMembersBeingDeleted = loading.get(e.SAVE_USER_PROJECTS)

    const membersToSetAsInvited = membersToInviteFromHiddenSelection.length
      ? membersToInviteFromHiddenSelection
      : membersToInvite

    return (
      <>
        {this.renderTopControls()}
        <div className="project-members-upload-table-container">
          <ProjectMembers
            isMemberDetailsDialogOpen={shouldShowMemberDetails}
            onInviteMember={showConfirmInviteDialog}
            onSetMemberAsNotInvited={toggleConfirmSetAsNotInvitedDialog.bind(
              null,
              true,
            )}
            store={this.membersListStore}
            initialScrollToId={lastUpdatedUserId}
            onAddNewMember={showAddMemberDialog}
            onEditMember={handleEditMember}
          />
          {this.renderUploadFileBlock(false)}
        </div>
        {shouldShowBulkEditMembers && (
          <BulkEditMembersSideBar
            onClose={hideBulkEditMembersSideBar}
            selectedProjectMembers={selectedMembers}
            bulkEditTags={updateMembersSettings}
            loading={this.areUserProjectsBeingUpdated}
            errorMessage={errorMessage}
            toggleInviteDialog={toggleConfirmInviteDialog}
            toggleDeleteDialog={toggleConfirmDeleteDialog}
            hasProjectMembersToInvite={this.hasProjectMembersToInvite}
          />
        )}
        <ConfirmUsersDialog
          type={ConfirmDialogTypes.INVITE}
          isOpen={shouldShowConfirmInviteDialog}
          loading={areMembersBeingInvited}
          closeDialog={toggleConfirmInviteDialog.bind(this, false)}
          performItems={handleInviteMembersFromList}
          itemsToPerform={membersToSetAsInvited}
        />
        <ConfirmUsersDialog
          type={ConfirmDialogTypes.SET_NOT_INVITED}
          isOpen={shouldShowConfirmSetAsNotInvitedDialog}
          loading={areMembersBeingSetAsNotInvited}
          closeDialog={toggleConfirmSetAsNotInvitedDialog.bind(this, false)}
          performItems={setMembersAsNotInvited}
          itemsToPerform={membersToSetAsNotInvited}
        />
        <ConfirmUsersDialog
          type={ConfirmDialogTypes.DELETE}
          isOpen={shouldShowConfirmDeleteDialog}
          loading={areMembersBeingDeleted}
          closeDialog={toggleConfirmDeleteDialog.bind(this, false)}
          performItems={deleteMembers}
          itemsToPerform={selectedMembers}
        />
        <AlertDialog
          title={Localization.translator.error}
          isOpen={isAlertOpen}
          onClose={closeAlert}
        >
          <span className="text red bold">{errorMessage}</span>
        </AlertDialog>
        <ProcoreDirectoryDialog
          isOpen={shouldShowProcoreDirectory}
          procoreCompanies={procoreCompanies}
          addToProject={addMembersFromProcore}
          closeDialog={closeProcoreDirectory}
          loading={this.areMembersBeingUpdatedOrCreated}
        />
      </>
    )
  }

  private renderAddEditMemberDialog(): JSX.Element {
    const { projectMembersStore, state } = this.props
    const { loading } = state

    const isProjectLoading =
      loading.get(e.ACTIVATE_PROJECT) ||
      loading.get(e.GET_PROJECT_MEMBERS) ||
      loading.get(e.GET_SCHEDULE) ||
      loading.get(e.GET_USERS_FROM_PROCORE)

    if (isProjectLoading) {
      return null
    }

    const {
      errorMessage,
      resetErrorMessage,
      hideAddMemberDialog,
      saveMembers,
      usersToEdit,
      handleInviteMemberFromEditDialog,
      dialogs: { shouldShowMemberDetails },
    } = this.store

    return (
      <AddEditMemberDialog
        onInvite={handleInviteMemberFromEditDialog}
        isOpen={shouldShowMemberDetails}
        closeDialog={hideAddMemberDialog}
        loading={this.areMembersBeingUpdatedOrCreated}
        onSubmit={saveMembers}
        itemsToEdit={usersToEdit}
        allItems={projectMembersStore.list}
        errorMessage={errorMessage}
        resetErrorMessage={resetErrorMessage}
      />
    )
  }

  public get areMembersBeingUpdatedOrCreated(): boolean {
    return this.props.state.loading.get(e.SAVE_MEMBERS)
  }

  public get areUserProjectsBeingUpdated(): boolean {
    return this.props.state.loading.get(e.SAVE_USER_PROJECTS)
  }

  public get isUsersCsvDownloading(): boolean {
    return this.props.state.loading.get(e.DOWNLOAD_PROJECT_MEMBERS)
  }

  private renderDropdownContent(state: DropdownStates): JSX.Element {
    switch (state) {
      case DropdownStates.downloadTeamMembers:
        return this.isUsersCsvDownloading ? (
          <Loader />
        ) : (
          this.renderDownloadButton()
        )
      case DropdownStates.uploadTeamMembers:
        return this.renderUploadButton()
      case DropdownStates.connectToProcore:
        return this.renderProcoreButton()
      case DropdownStates.viewCompanyDirectory:
        return (
          <div className="no-icon-text">
            {
              Localization.translator.teamMembersDropdownStates
                .viewCompanyDirectory
            }
          </div>
        )
      default:
        throw new Error(state + ' is not added to dictionary')
    }
  }

  private renderUploadButton(): JSX.Element {
    return (
      <FileInput
        className="top-controls-upload-csv-button"
        disabled={false}
        inputProps={{
          accept: this.uploadButton.accept,
          onChange: this.uploadButton.onFileChange,
          className: 'bare-file-input',
        }}
        text={
          this.store.isUploadingState ? (
            <div className="row full-height">
              <span className="top-controls-loader active mw40 mr25" />
              {this.uploadButton.name}
            </div>
          ) : (
            <UploadButton
              text={this.uploadButton.name}
              withLeftMargin={false}
            />
          )
        }
      />
    )
  }

  private renderDownloadButton(): JSX.Element {
    return (
      <button
        className="row no-flex-children text large bare-input pointer"
        onClick={this.store.downloadProjectMembers}
      >
        <div className="top-controls-download-template-icon option-icon" />
        {Localization.translator.teamMembersDropdownStates.downloadTeamMembers}
      </button>
    )
  }

  private renderProcoreButton(): JSX.Element {
    return (
      <div
        className="pointer pr20 row procore-btn no-flex-children"
        onClick={this.store.getUsersFromProcore}
      >
        <img src="/static/icons/procore-icon.svg" className="option-icon" />
        {Localization.translator.teamMembersDropdownStates.connectToProcore}
      </div>
    )
  }

  private renderTopControls(): JSX.Element {
    const { isDropdownOpen, toggleDropdown, showAddMemberDialog } = this.store

    const dropdownIcon = isDropdownOpen
      ? IconNames.CHEVRON_UP
      : IconNames.CHEVRON_DOWN

    return (
      <>
        <div className="row x-end pb20">
          <BaseActionButton
            className="no-grow scale-blue-theme"
            isEnabled={true}
            title={Localization.translator.addNewUser}
            onClick={showAddMemberDialog.bind(null, null)}
          />
        </div>
        {this.renderFilterBar()}
        <div className="row bb-light-grey mb5 h40">
          <div className="row x-start full-height">
            <div
              className="text large primary row x-center br-light-grey py5"
              style={{ maxWidth: '220px' }}
            >
              {this.selectedMembersLabel}
            </div>
            {this.renderActionForSelectedUsers()}
          </div>
          <div
            className={classList({
              'row x-end w-fit-content pointer': true,
              'unclickable-element': isDropdownOpen,
            })}
          >
            <div onClick={toggleDropdown}>
              <span className="mr10 w-max-content">
                {Localization.translator.importExportTeamMembers}
              </span>
              <Icon className="arrow-icon mr15" icon={dropdownIcon} />
            </div>
          </div>
          {isDropdownOpen && (
            <MenuCloser className="dropdown-menu" closeMenu={toggleDropdown}>
              <div className="options-holder top-controls-dropdown">
                {Object.values(DropdownStates).map(state => {
                  return (
                    <div
                      className={classList({
                        'option pointer row': true,
                        'not-allowed':
                          state === DropdownStates.viewCompanyDirectory,
                      })}
                      key={state}
                    >
                      {this.renderDropdownContent(state)}
                    </div>
                  )
                })}
              </div>
            </MenuCloser>
          )}
        </div>
      </>
    )
  }

  private renderFilterBar(): JSX.Element {
    return (
      <div className="row pb10 relative">
        <div className="no-grow ml4">
          <EntityNameFilter filters={this.props.state.teamMembersFilters} />
        </div>

        <MemberFilters store={this.memberFilterStore} />
      </div>
    )
  }

  private renderActionForSelectedUsers(): JSX.Element {
    return (
      <div className="px10 row full-height">
        {this.topControlButtons.map(button => {
          return (
            <BaseActionButton
              icon={button.icon}
              className="inverse-scale-blue-theme mx4"
              key={button.name}
              isEnabled={!button.disabled}
              isActive={button.isActive}
              shouldShowBorder={false}
              title={button.name}
              onClick={button.onClick}
            />
          )
        })}
      </div>
    )
  }

  private renderUploadFileBlock(isUploadBlockVisible: boolean): JSX.Element {
    return (
      <DndArea
        ref={ref => (this.dndArea = ref)}
        fileType={FileType.ProjectMembers}
        accept=".csv"
        title={this.renderDndTitle()}
        isHidden={!isUploadBlockVisible}
        onDrop={this.store.uploadProjectMembers}
      />
    )
  }

  private renderDndTitle(): JSX.Element {
    const { uploadYourUpdatedProjectMemberListCsvFile, orDragYourFileHere } =
      Localization.translator

    return (
      <>
        <UploadButton text={uploadYourUpdatedProjectMemberListCsvFile} />
        <span>{orDragYourFileHere}</span>
      </>
    )
  }

  private onFileChanged = (event: any) => {
    this.store.changeUploadingState(UploadingState.Uploading)

    this.dndArea.onDrop(event.target.files)
    event.target.value = null
  }

  @computed
  private get topControlButtons() {
    const {
      showBulkEditMembersSideBar,
      toggleConfirmInviteDialog,
      dialogs: { shouldShowConfirmInviteDialog, shouldShowBulkEditMembers },
    } = this.store

    const { selectedInstances } = this.membersListStore

    const hasSelectedProjectMembers = selectedInstances.length

    return [
      {
        name: Localization.translator.edit_verb,
        disabled: !hasSelectedProjectMembers,
        onClick: showBulkEditMembersSideBar,
        icon: <Icons.Edit />,
        isActive: shouldShowBulkEditMembers,
      },
      {
        name: Localization.translator.invite,
        disabled: !this.hasProjectMembersToInvite,
        onClick: toggleConfirmInviteDialog.bind(this, true),
        icon: <Icon icon={IconNames.ADD} />,
        isActive: shouldShowConfirmInviteDialog,
      },
    ]
  }

  private get selectedMembersLabel(): string {
    return Localization.translator.xTeamMembersSelected(
      this.store.selectedMembers.length,
    )
  }

  private get uploadButton() {
    return {
      name: Localization.translator.teamMembersDropdownStates.uploadTeamMembers,
      accept: '.csv',
      onFileChange: this.onFileChanged,
    }
  }

  private get hasProjectMembersToInvite(): boolean {
    return !!this.store.membersToInvite.length
  }
}
