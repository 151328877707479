import * as React from 'react'

import { inject, observer } from 'mobx-react'

import LevelsList from '~/client/src/shared/components/LevelsList'
import OffloadingEquipment from '~/client/src/shared/models/LocationObjects/OffloadingEquipment'

import ObjectAccessibleLevelsProperties from '../../../../../../../../AppsSitemap/components/PropertiesPanel/ObjectAccessibleLevelsProperties'
import { ObjectCellBase, objectCellInjects } from '../ObjectCell'

const accessibleLevels = 'Accessible levels'
const edit = 'Edit'

@inject(...objectCellInjects)
@observer
export default class EquipmentCell extends ObjectCellBase {
  protected renderNameCellContent() {
    const levelsCount = this.accessibleLevels.length
    return (
      <>
        {super.renderNameCellContent()}
        <div className="pl12 pt12">
          <div className="text grey-light uppercase bold pb5">
            {accessibleLevels}
            <span className="text grey-lighter pl5">({levelsCount})</span>
          </div>
          <LevelsList levels={this.accessibleLevels} />
          <div className="pt5 text blue-highlight bold large">{edit}</div>
        </div>
      </>
    )
  }

  private get accessibleLevels() {
    const { levelsStore } = this.props

    if (!this.equipmentDto.hasParent) {
      return []
    }
    return levelsStore.list.filter(
      l =>
        l.hasCommonParent(this.equipmentDto) &&
        this.equipmentDto.accessibleLevels.includes(l.id),
    )
  }

  private get equipmentDto(): OffloadingEquipment {
    return this.props.obj as OffloadingEquipment
  }

  protected renderAdditionalEditControls() {
    const { saveChanges } = this.store
    return (
      <ObjectAccessibleLevelsProperties
        dataObject={this.equipmentDto}
        onChange={saveChanges}
      />
    )
  }
}
