import React from 'react'

import { IActivitiesTreeNode } from '~/client/src/shared/models/IActivitiesTreeNode'

interface IProps {
  node: IActivitiesTreeNode
  shouldHideAmount?: boolean
}

export default class CompactLocationContainerNode extends React.Component<IProps> {
  public render() {
    const {
      shouldHideAmount,
      node: { name, totalEntitiesAmount },
    } = this.props
    const title = totalEntitiesAmount ? totalEntitiesAmount + ' Active' : ''

    return (
      <>
        <div className="text extra large white bold">{name}</div>
        {!shouldHideAmount && (
          <div className="text large white right pr20">{title}</div>
        )}
      </>
    )
  }
}
