import { action, computed, observable } from 'mobx'

import { TagType } from '../../../enums/TagType'
import User from '../../../models/User'
import ChatService from '../../../services/ChatService/Chat.service'
import { IChat } from '../../../services/ChatService/types'
import ProjectMembersStore from '../../../stores/domain/ProjectMembers.store'
import TagsStore from '../../../stores/domain/Tags.store'

export default class ChatCreationMenuStore {
  @observable public isDirectChatBeingOpened: boolean = false
  @observable public isGroupChatBeingCreated: boolean = false

  @observable public isGroupMode: boolean = false
  @observable public memberSelectedForNewGroupIds: Set<string> = new Set()

  @observable public isGroupMembersSelectionCompleted: boolean = false

  @observable public groupName: string = ''
  @observable public avatarUrl: string = ''

  @observable public searchKey: string = ''

  public constructor(
    private readonly chatService: ChatService,
    private readonly onChannelOpen: (chat: IChat) => void,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly tagsStore: TagsStore,
  ) {}

  public get isNextStepAvailable(): boolean {
    if (this.isGroupMode && !this.isGroupMembersSelectionCompleted) {
      return !!this.memberSelectedForNewGroupIds.size
    }

    if (this.isGroupMode && this.isGroupMembersSelectionCompleted) {
      return !!this.groupName
    }
  }

  @action.bound
  public completeGroupMembersSelection() {
    this.isGroupMembersSelectionCompleted = true
  }

  @action.bound
  public cancelGroupMembersSelection() {
    this.isGroupMembersSelectionCompleted = false
  }

  public get canGroupChatBeCreated(): boolean {
    return !!this.memberSelectedForNewGroupIds.size && !!this.groupName
  }

  public get headerTitle(): string {
    if (this.isGroupMembersSelectionCompleted) {
      return 'New Group'
    }

    return this.isGroupMode ? 'Add members' : 'New Chat'
  }

  public get headerSubtitle(): string {
    return this.isGroupMode
      ? `${this.memberSelectedForNewGroupIds.size} members, ${this.involvedCompaniesCount} companies`
      : ''
  }

  public get actionButtonTitle(): string {
    if (this.isGroupMembersSelectionCompleted) {
      return 'Create'
    }

    return this.isGroupMode ? 'Next' : null
  }

  @action.bound
  public async goToNextStep() {
    if (this.isGroupMembersSelectionCompleted) {
      this.createGroupChat()
    } else {
      this.completeGroupMembersSelection()
    }
  }

  @computed
  public get selectedMembers(): User[] {
    return [...this.memberSelectedForNewGroupIds.values()].map(mid =>
      this.projectMembersStore.getById(mid),
    )
  }

  @action.bound
  public setGroupMode() {
    this.isGroupMode = true
  }

  @action.bound
  public unsetGroupMode() {
    this.isGroupMode = false

    this.groupName = ''
    this.memberSelectedForNewGroupIds.clear()
  }

  @action.bound
  public clear() {
    this.searchKey = ''
    this.unsetGroupMode()
    this.cancelGroupMembersSelection()
  }

  @action.bound
  public changeAvatarUrl(avatarUrl: string) {
    this.avatarUrl = avatarUrl
  }

  @action.bound
  public changeGroupName(name: string) {
    this.groupName = name
  }

  @action.bound
  public toggleContactSelection(userId: string) {
    if (this.memberSelectedForNewGroupIds.has(userId)) {
      this.memberSelectedForNewGroupIds.delete(userId)
    } else {
      this.memberSelectedForNewGroupIds.add(userId)
    }
  }

  public handleUserRowClick = (recipient: User) => {
    if (this.isGroupMode) {
      this.toggleContactSelection(recipient.id)
    } else {
      this.openDirectChannel(recipient)
    }
  }

  public openDirectChannel = async (recipient: User) => {
    this.isDirectChatBeingOpened = true

    try {
      const channelId = await this.chatService.getChannelIdByRecipient(
        recipient.id,
      )

      const directChat: IChat = {
        id: channelId,
        isGroup: false,
        name: recipient.fullName,
        avatar: recipient.avatarUrl,
        memberIds: [this.activeUserId, recipient.id],
        createdBy: this.activeUserId,
      }

      this.onChannelOpen(directChat)
    } finally {
      this.isDirectChatBeingOpened = false
    }
  }

  public get activeUserId(): string {
    return this.chatService.userId
  }

  @computed
  public get involvedCompaniesCount(): number {
    const usersByCompanies = this.tagsStore.mapUsersByTagType(
      this.selectedMembers,
      TagType.Company,
      false,
    )

    return Object.keys(usersByCompanies).length
  }

  private async createGroupChat() {
    const recipientIds = [...this.memberSelectedForNewGroupIds.values()]

    const memberIds = [...recipientIds, this.activeUserId]

    this.isGroupChatBeingCreated = true

    try {
      const channelId = await this.chatService.createChat(
        this.groupName,
        true,
        memberIds,
        this.avatarUrl,
      )

      this.onChannelOpen({
        id: channelId,
        name: this.groupName,
        avatar: this.avatarUrl,
        isGroup: true,
        memberIds,
        createdBy: this.activeUserId,
      })

      this.clear()
    } catch (e) {
      alert(e)
    } finally {
      this.isGroupChatBeingCreated = false
    }
  }
}
