import { action } from 'mobx'

const OPEN_EVENT_NAME = 'open'
const CLOSED_EVENT_NAME = 'close'

export default class ElementSwiper {
  public isOpened: boolean = false
  public element

  private scrollMax: number
  private touchStartX: number
  private touchStartY: number
  private scrollStart: number
  private touchDeltaX: number
  private touchDeltaY: number
  private touchAngle: number
  private wasCanceling: boolean
  private moveStart: number
  private moveStartDelta: number

  private handlers: { [action: string]: Array<() => void> } = {}

  public constructor(element) {
    this.element = element
    this.scrollMax = element.scrollWidth - element.clientWidth

    element.addEventListener('touchstart', this.onTouchStart, { passive: true })

    element.addEventListener('touchmove', this.onTouchMove, { passive: true })

    element.addEventListener('touchend', this.onTouchEnd)
  }

  public disconnectElement() {
    this.element.removeEventListener('touchstart', this.onTouchStart)

    this.element.removeEventListener('touchmove', this.onTouchMove)

    this.element.removeEventListener('touchend', this.onTouchEnd)

    this.handlers = {}
    delete this.element
  }

  public animateClosed(step = 20) {
    const frameRequestCb = () => {
      this.element.scrollLeft = Math.max(this.element.scrollLeft - step, 0)
      if (this.element.scrollLeft > 0) {
        window.requestAnimationFrame(frameRequestCb)
        return
      }
      this.setClosedStatus()
    }

    window.requestAnimationFrame(frameRequestCb)
  }

  public animateOpen(step = 20) {
    if (!this.element) {
      return // Warning: kludge
    }

    const clamp = this.element.scrollWidth - this.element.clientWidth
    const frameRequestCb = () => {
      this.element.scrollLeft = Math.min(clamp, this.element.scrollLeft + step)
      if (this.element.scrollLeft < clamp) {
        window.requestAnimationFrame(frameRequestCb)
        return
      }
      this.element.scrollLeft = clamp
      this.setOpenedStatus()
    }

    window.requestAnimationFrame(frameRequestCb)
  }

  public on(event: 'open' | 'close', handler: () => void) {
    if (!this.handlers[event]) {
      this.handlers[event] = []
    }
    this.handlers[event].push(handler)
  }

  @action.bound
  private onTouchStart(e) {
    this.touchStartX = e.touches[0].clientX
    this.touchStartY = e.touches[0].clientY
    this.scrollStart = this.element.scrollLeft
  }

  @action.bound
  private onTouchMove(e) {
    this.touchDeltaX = this.touchStartX - e.touches[0].clientX
    this.touchDeltaY = this.touchStartY - e.touches[0].clientY
    this.touchAngle =
      (Math.atan(this.touchDeltaY / this.touchDeltaX) * 180) / Math.PI +
      (this.touchDeltaX < 0 ? 180 : this.touchDeltaY < 0 ? 360 : 0)

    if (this._isVertical(this.touchAngle, 45)) {
      this.wasCanceling = true
      return
    } else if (this.wasCanceling || this.moveStart === undefined) {
      this.wasCanceling = false
      this.moveStart = e.touches[0].clientX
    }

    this.moveStartDelta = this.moveStart - e.touches[0].clientX

    const { min, max } = Math
    this.element.scrollLeft = min(
      this.scrollMax,
      max(this.scrollStart + this.moveStartDelta, 0),
    )
  }

  @action.bound
  private onTouchEnd() {
    switch (this.element.scrollLeft) {
      case 0:
        this.setClosedStatus()
        return
      case this.scrollMax:
        this.setOpenedStatus()
        return
    }

    const middlePoint = this.scrollMax / 2

    if (this.element.scrollLeft >= middlePoint) {
      this.animateOpen()
    } else {
      this.animateClosed()
    }
  }

  private _isVertical(angle, maxAngleDelta) {
    return !!(
      Math.abs(270 - angle) <= maxAngleDelta ||
      Math.abs(90 - angle) <= maxAngleDelta
    )
  }

  @action.bound
  private setOpenedStatus() {
    this.isOpened = true
    if (this.handlers[OPEN_EVENT_NAME]) {
      this.handlers[OPEN_EVENT_NAME].forEach(handler => {
        handler()
      })
    }
    this.moveStart = undefined
  }

  @action.bound
  private setClosedStatus() {
    this.isOpened = false
    if (this.handlers[CLOSED_EVENT_NAME]) {
      this.handlers[CLOSED_EVENT_NAME].forEach(handler => {
        handler()
      })
    }
    this.moveStart = undefined
  }
}
