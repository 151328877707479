import { action, get, has, observable, remove, set } from 'mobx'

export default class MobXMap<T> {
  private storage = observable.object({}, null, { deep: false })

  public get(key: string): T {
    return get(this.storage, key)
  }

  public has(key: string): boolean {
    return has(this.storage, key)
  }

  @action
  public set(key: string, value: T) {
    set(this.storage, { [key]: value })
  }

  @action
  public merge(source: { [key: string]: T }) {
    set(this.storage, source)
  }

  @action
  public delete(key: string) {
    remove(this.storage, key)
  }

  @action
  public clear() {
    this.forEach((_, key) => remove(this.storage, key))
  }

  public forEach(cb: (value: T, key?: string) => void) {
    const keys = Object.keys(this.storage)
    keys.forEach(key => {
      cb(this.get(key), key)
    })
  }

  public get values(): T[] {
    return Object.values(this.storage)
  }
}
