import * as React from 'react'

import { Checkbox } from '@blueprintjs/core'
import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import './MultiSelectFilter.scss'

// translated

export interface IMultiSelectOption {
  label: React.ReactElement<any> | string
  value: string
  disabled?: boolean
}

interface IProps {
  options: IMultiSelectOption[]
  filterType?: string
  hasIcon?: boolean
  values: string[]
  title: string
  onChange(values: string[], filterType?: string): void
}

export default class MultiSelectFilter<
  T extends IProps,
> extends React.Component<T> {
  protected isOnlyButtonsShown: boolean = true

  protected get options() {
    const { options, values, hasIcon } = this.props

    return options.map(({ label, value, disabled }) => (
      <div
        className="multiselect-checkbox-item row no-flex-children y-center"
        key={value}
      >
        <Checkbox
          className={classList({
            'primary-blue-checkbox full-width text-ellipsis': true,
            row: hasIcon,
            disabled,
          })}
          checked={values.includes(value)}
          label={label as any}
          onChange={this.onCheckboxChange.bind(this, value)}
          value={value || ''}
        />
        {this.isOnlyButtonsShown && !disabled && (
          <div
            className="only-button"
            onClick={this.onOnlyClicked.bind(this, value)}
          >
            {Localization.translator.only}
          </div>
        )}
      </div>
    ))
  }

  protected get title() {
    const { title } = this.props
    return <div className="text extra-large bold">{title}</div>
  }

  protected get selectAllButton() {
    return (
      <div
        className={classList({
          'text primary-blue pointer pr5': true,
        })}
        onClick={
          this.areAllOptionsSelected
            ? this.onClearAllClicked
            : this.onSelectAllClicked
        }
      >
        {this.areAllOptionsSelected
          ? Localization.translator.clearAll_items
          : Localization.translator.selectAll_items}
      </div>
    )
  }

  protected onCheckboxChange(value: string) {
    let { values } = this.props
    const { filterType, onChange } = this.props
    if (values.includes(value)) {
      values = values.filter(v => v !== value)
    } else {
      values.push(value)
    }

    onChange(values, filterType)
  }

  protected onOnlyClicked(value: string) {
    const { filterType, onChange } = this.props
    onChange([value], filterType)
  }

  protected onSelectAllClicked = () => {
    const { onChange, options, filterType } = this.props
    const allValues = options.map(option => option.value)
    onChange(allValues, filterType)
  }

  protected onClearAllClicked = () => {
    const { onChange, filterType } = this.props
    onChange([], filterType)
  }

  protected get areAllOptionsSelected(): boolean {
    const { options, values } = this.props
    return options.every(({ value }) => values.includes(value))
  }
}
