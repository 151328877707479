import { observer } from 'mobx-react'

import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import Company from '~/client/src/shared/models/Company'

import ConfirmItemsDialog from '../../ConfirmItemDialog/ConfirmItemDialog'

import '../../ConfirmItemDialog/ConfirmItemDialog.scss'

interface ICompaniesConfirmDialog {
  isActivationMode: boolean
}

@observer
export default class ConfirmCompaniesDialog extends ConfirmItemsDialog<
  Company,
  ICompaniesConfirmDialog
> {
  public constructor(props) {
    super(props)

    this.setUpTitles()

    this.tableInfo = {
      columnTitleKeys: [KnownTranslatorKeys.name, KnownTranslatorKeys.code],
      columnProps: [(company: Company) => company.name, 'code'],
    }
  }

  public componentDidUpdate(prevProps): void {
    if (prevProps.isActivationMode !== this.props.isActivationMode) {
      this.setUpTitles()
    }
  }

  private setUpTitles() {
    const { isActivationMode } = this.props

    this.questionKey = isActivationMode
      ? KnownTranslatorKeys.confirmActivationOfTheFollowingXCompanies
      : KnownTranslatorKeys.confirmDeactivationOfTheFollowingXCompanies
    this.dialogDoneBtnTitleKey = isActivationMode
      ? KnownTranslatorKeys.activate
      : KnownTranslatorKeys.deactivate
    this.dialogTitleKey = isActivationMode
      ? KnownTranslatorKeys.confirmActivation
      : KnownTranslatorKeys.confirmDeactivation
  }
}
