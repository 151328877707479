import * as React from 'react'

import { observer } from 'mobx-react'

import FilterFooter from '~/client/src/shared/components/Filters/FilterUtils/FilterFooter/FilterFooter'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import DesktopActivitiesSavedFiltersStore from '../../../ActivitiesSavedFilters/DesktopActivitiesSavedFilters.store'
import ActivityPresetHeader from './ActivityPresetHeader'

// localization: translated

interface ISaveFilterProps {
  desktopSavedFiltersStore: DesktopActivitiesSavedFiltersStore
}

@observer
export default class ActivityPresetDialog extends React.Component<ISaveFilterProps> {
  public render() {
    const {
      presetInstance,
      editablePresetName,
      saveActivityPreset,
      closeSavedFilters,
      onActivityPresetNameChange,
    } = this.props.desktopSavedFiltersStore

    return (
      <div className="filter-container">
        <ActivityPresetHeader
          desktopSavedFiltersStore={this.props.desktopSavedFiltersStore}
        />
        <div className="filter-container-content mx15">
          <div className="row name">
            <StruxhubInput
              id="save-preset-name-input"
              label={Localization.translator.activitySetName}
              isRequired={true}
              isRequiredTextHidden={true}
              value={editablePresetName}
              onChange={onActivityPresetNameChange}
            />
            <span className="row x-end text large light mr20">
              {Localization.translator.xActivities(
                presetInstance.activities.length,
              )}
            </span>
          </div>
        </div>
        <div className="bt-light-grey mt10">
          <FilterFooter
            clickOnApply={saveActivityPreset}
            clickOnCancel={closeSavedFilters}
            isApplyButtonInactive={!editablePresetName}
            applyButtonText={Localization.translator.save}
          />
        </div>
      </div>
    )
  }
}
