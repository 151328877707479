import HeaderBarStore from '~/client/src/desktop/components/HeaderBar/HeaderBar.store'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'

export default class FormTypesHeaderBarStore extends HeaderBarStore {
  public constructor(eventsStore: DesktopEventStore) {
    super(eventsStore.appState)
  }

  public get forceCloseMap() {
    const { fieldsMap } = this.state.formTypesFilters
    return this.getForceCloseMap(fieldsMap)
  }

  private getForceCloseMap(fieldsMap: { [filterType: string]: UIFilterInfo }) {
    return Object.keys(fieldsMap).reduce((map, filterType) => {
      map[filterType] = this.openedPopup && this.openedPopup !== filterType
      return map
    }, {})
  }
}
