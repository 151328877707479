import { ICompanyRelationship } from '~/client/graph'

export default class ActivityCompanyRelationship {
  public static fromDto(dto: ICompanyRelationship) {
    return new ActivityCompanyRelationship(
      dto.id,
      dto.scheduleId,
      dto.struxHubCompanyId,
      dto.externalCompanyId,
    )
  }

  public constructor(
    public readonly id: string,
    public readonly scheduleId: string,
    public struxHubCompanyId: string,
    public externalCompanyId: string,
  ) {}
}
