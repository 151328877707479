import * as React from 'react'

import CompactConfirmDialog from '~/client/src/shared/components/CompactConfirmDialog/CompactConfirmDialog'
import Localization from '~/client/src/shared/localization/LocalizationManager'

// localization: translated

export interface IProps {
  isShown: boolean
  onHide: () => void
  onApply: () => void
}

export default class EditingConfirm extends React.PureComponent<IProps> {
  public render() {
    const { isShown, onHide, onApply, children } = this.props

    return (
      <CompactConfirmDialog
        title={Localization.translator.editingConfirmation}
        isShown={isShown}
        onHide={onHide}
        onApply={onApply}
        applyButtonText={Localization.translator.confirm}
        canOutsideClickClose={false}
        className="blue-theme"
      >
        {children}
      </CompactConfirmDialog>
    )
  }
}
