import * as React from 'react'

import { Switch } from '@blueprintjs/core'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'

import { IDeliveryCardField, LocationType } from '~/client/graph'
import DeliveryFieldIcon from '~/client/src/shared/components/DeliveryFieldIcon/DeliveryFieldIcon'
import * as Icons from '~/client/src/shared/components/Icons'
import { NOOP } from '~/client/src/shared/utils/noop'

import DeliveryCardsSetUpStore from '../../DeliveryCardsSetUp.store'

interface IProps {
  store: DeliveryCardsSetUpStore
}

const ATTRIBUTES_TO_SHOW = [
  LocationType.Zone,
  LocationType.Building,
  LocationType.Area,
  LocationType.Gate,
  LocationType.Level,
  LocationType.Route,
]

interface ISortableItem {
  element: JSX.Element
  key: React.Key
  isHidden?: boolean
}

const property = 'Property'
const show = 'Show'
const cardColor = 'Card color'

const DragHandle = SortableHandle(() => (
  <Icons.DoubleVerticalDots className="card-vertical-dots mr15 pointer" />
))

const SortableItem = SortableElement(({ item }) => item)

const SortableItemsContainer = SortableContainer(
  ({ items }: { items: ISortableItem[] }) => {
    return (
      <div>
        {items.map((item, index) => (
          <SortableItem
            item={item.element}
            index={index}
            key={item.key}
            disabled={item.isHidden}
          />
        ))}
      </div>
    )
  },
)

@observer
export default class CardFieldsConfiguration extends React.Component<IProps> {
  public render() {
    const {
      changeDeliveryCardOrder,
      nonSortableFields,
      isDeliveryFieldHidden,
    } = this.store

    return (
      <div className="col card-fields-configuration">
        <div className="pt12 row relative">
          <div className="row bb-brand-dark pb5 px20 text small bold uppercase brand-dark mr15">
            <span className="property-header">{property}</span>
            <span className="mr35 text right small">{cardColor}</span>
            <span className="no-grow">{show}</span>
          </div>
        </div>
        {nonSortableFields.map(field => {
          const isFieldHidden = isDeliveryFieldHidden(field.fieldId)
          return (
            <div key={field.fieldId}>
              {this.renderConfigurationRow(field, isFieldHidden, true)}
            </div>
          )
        })}
        <SortableItemsContainer
          useDragHandle={true}
          lockAxis="y"
          onSortEnd={changeDeliveryCardOrder}
          items={this.sortableItems}
          lockToContainerEdges={true}
        />
      </div>
    )
  }

  private renderConfigurationRow = (
    deliveryCardField: IDeliveryCardField,
    isFieldHidden: boolean,
    shouldHideDragIcon: boolean = false,
  ): JSX.Element => {
    if (!deliveryCardField?.fieldId) {
      return null
    }

    const {
      toggleFieldVisibility,
      getFieldStatusString,
      getDeliveryFieldName,
      mainColoringFieldId,
      setFieldAsMainColor,
    } = this.store

    const fieldName = getDeliveryFieldName(deliveryCardField.fieldId)
    const isHidden = isFieldHidden || deliveryCardField?.isHidden
    const shouldShowField = ATTRIBUTES_TO_SHOW.includes(
      deliveryCardField.fieldId as LocationType,
    )
    const isFieldColorSelectedAsMain =
      mainColoringFieldId === deliveryCardField.fieldId
    const isColorSelectDisabled =
      deliveryCardField.fieldId === LocationType.Zone &&
      mainColoringFieldId === LocationType.Zone

    return (
      <div
        className={classList({
          'row y-center card-config-row highlighted-hover pl10': true,
          'inactive-element': isFieldHidden,
        })}
      >
        <div
          className={classList({
            'no-grow row y-center': true,
            'not-visible': shouldHideDragIcon,
          })}
        >
          <DragHandle />
        </div>
        <div className="row px4 text large light line-extra-large lp035 overflow-hidden">
          <DeliveryFieldIcon fieldId={deliveryCardField.fieldId} />
          <div className="full-width ml10 overflow-hidden config-field-name">
            <span
              className="text large black ellipsis text-ellipsis"
              title={fieldName}
            >
              {fieldName}
            </span>
            <span className="px4 text small light lp05">
              ({getFieldStatusString(deliveryCardField.fieldId)})
            </span>
          </div>
          {shouldShowField && (
            <div className="no-grow px10">
              <Switch
                className="primary-blue-switch bp3-align-right no-outline-container mr25"
                disabled={isColorSelectDisabled}
                checked={isFieldColorSelectedAsMain}
                readOnly={isFieldHidden}
                onChange={
                  isFieldColorSelectedAsMain
                    ? NOOP
                    : setFieldAsMainColor.bind(null, deliveryCardField)
                }
              />
            </div>
          )}
        </div>
        <div className="no-grow px10">
          <Switch
            className="primary-blue-switch bp3-align-right no-outline-container mr25"
            disabled={isFieldHidden}
            checked={!isHidden}
            readOnly={isFieldHidden}
            onChange={toggleFieldVisibility.bind(null, deliveryCardField)}
          />
        </div>
      </div>
    )
  }

  private get sortableItems(): ISortableItem[] {
    const { sortableFields, isDeliveryFieldHidden } = this.store

    return sortableFields.map(field => {
      const isFieldHidden = isDeliveryFieldHidden(field.fieldId)
      return {
        element: this.renderConfigurationRow(field, isFieldHidden),
        key: field.fieldId,
        isHidden: isFieldHidden,
      }
    })
  }

  private get store(): DeliveryCardsSetUpStore {
    return this.props.store
  }
}
