import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import FilterHandleButton from '~/client/src/shared/components/Filters/components/FilterHandleButton/FilterHandleButton'
import * as Icons from '~/client/src/shared/components/Icons'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { IFilters } from '~/client/src/shared/stores/InitialState'

import EntityNameFilterStore from './EntityNameFilter.store'

import './EntityNameFilter.scss'

// localization: translated

const doneTypingInterval = 300

interface IEntityNameFilter {
  filters: IFilters
  onShowChanged?: (isShown: boolean) => void
  placeholder?: string
  className?: string
  forceClose?: boolean
  openOnLeft?: boolean
}

@observer
export default class EntityNameFilter extends React.Component<IEntityNameFilter> {
  public static defaultProps = {
    className: '',
  }

  @observable private typingTimer: number
  private readonly store: EntityNameFilterStore

  public constructor(props: IEntityNameFilter) {
    super(props)

    this.store = new EntityNameFilterStore(props.filters, props.onShowChanged)
  }

  public componentDidUpdate() {
    if (this.props.forceClose) {
      this.store.isShown = false
    }
  }

  public render() {
    const { placeholder, className } = this.props
    const { isShown, isActive } = this.store

    return (
      <div className="activity-name-filter">
        <FilterHandleButton
          onClick={this.store.toggle}
          className="activity-name-filter-handle-button"
          isActive={isActive}
          isSelected={isShown}
          hasIndent={false}
        >
          <Icon icon={IconNames.SEARCH} iconSize={Icon.SIZE_LARGE} />
        </FilterHandleButton>
        <div
          className={classList({
            'row x-start y-center px5': true,
            'search-input no-outline-container': true,
            'left-oriented': this.props.openOnLeft,
            [className]: true,
            hide: !isShown,
          })}
        >
          {isShown && (
            <StruxhubInput
              placeholder={placeholder || Localization.translator.search}
              autoFocus={true}
              isMinimalisticMode={true}
              value={this.store.searchKey}
              onKeyDown={this.keyDownAction}
              onKeyUp={this.keyUpAction}
              onChange={this.updateSearchKey}
              customRightIcon={
                <Icons.CrossGrey
                  className="pointer row no-grow"
                  onMouseDown={this.clearAndClose}
                  onClick={this.clearAndClose}
                />
              }
            />
          )}
        </div>
      </div>
    )
  }

  @action.bound
  private updateSearchKey(event: React.SyntheticEvent<HTMLInputElement>) {
    this.store.searchKey = event.currentTarget.value
  }

  @action.bound
  private clearAndClose(event: React.SyntheticEvent<HTMLImageElement>) {
    event.stopPropagation()
    this.store.clearAndClose()
  }

  private keyUpAction = () => {
    clearTimeout(this.typingTimer)

    this.typingTimer = window.setTimeout(this.store.update, doneTypingInterval)
  }

  private keyDownAction = () => {
    clearTimeout(this.typingTimer)
  }
}
