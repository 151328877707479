import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import { Loader } from '~/client/src/shared/components/Loader'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import ActivitiesTreePreview from './components/ActivitiesTreePreview/ActivitiesTreePreview'
import BandsConfigurator from './components/BandsConfigurator/BandsConfigurator'
import HierarchyModeForViewsConfigurator from './components/HierarchyModeForViewsConfigurator/HierarchyModeForViewsConfigurator'

import './TrackingHierarchy.scss'

interface ITrackingHierarchy {
  state?: DesktopInitialState
}

@inject('state')
@observer
export default class TrackingHierarchy extends React.Component<ITrackingHierarchy> {
  public render() {
    const { isLoading } = this.props.state

    return (
      <form className="setup-form tracking-form px30">
        <div className="setup-form-header">
          {Localization.translator.hierarchies.toUpperCase()}
        </div>
        <div className="bb-light-grey large light pb15 text">
          {Localization.translator.trackingHierarchyDescriptions.title}
        </div>
        {isLoading ? this.loader : this.content}
      </form>
    )
  }

  private get content(): JSX.Element {
    return (
      <div className="row y-start pt20 no-outline-container">
        <div className="col">
          <div className="bands-configurator">
            <div className="text extra-large bold default-cursor pb20">
              {Localization.translator.trackingHierarchy}
            </div>
            <div className="row">
              <div className="text large light configurator-description">
                {
                  Localization.translator
                    .defineBandsEnPermissionsForYourTrackingHierarchy
                }
              </div>
              <BandsConfigurator />
            </div>
          </div>
          <div className="views-modes-configurator">
            <div className="text extra-large bold default-cursor py20">
              {Localization.translator.defaultHierarchies}
            </div>
            <div className="row">
              <div className="text large light configurator-description">
                {
                  Localization.translator
                    .setupDefaultHierarchiesForDifferentViews
                }
              </div>
              <HierarchyModeForViewsConfigurator />
            </div>
          </div>
        </div>

        <div className="mobile-preview">
          <div className="text extra-large bold default-cursor pb20">
            {Localization.translator.hierarchyPreview}
          </div>
          <ActivitiesTreePreview />
        </div>
      </div>
    )
  }

  private get loader(): JSX.Element {
    return (
      <Loader
        className="full-height col x-center y-center m20"
        hint={Localization.translator.loading}
      />
    )
  }
}
