import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DeliveryActionTypes from '~/client/src/desktop/enums/DeliveryActionTypes'
import { Loader } from '~/client/src/shared/components/Loader'
import MapViewsGallery from '~/client/src/shared/components/SitemapsGallery/MapViewsGallery'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import GlobeView from '~/client/src/shared/models/GlobeView'
import Sitemap from '~/client/src/shared/models/Sitemap'
import { SAVE_DELIVERY_CONFIGURATIONS } from '~/client/src/shared/stores/EventStore/eventConstants'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'

import DeliveriesMapStore from './DeliveriesMap.store'
import DeliveriesMapSidebar from './components/DeliveriesMapSidebar/DeliveriesMapSidebar'
import DeliveryMapView from './components/DeliveryMapView/DeliveryMapView'

// localization: translated
interface IProps {
  store: DeliveriesMapStore

  activeActionType: DeliveryActionTypes
  onNewDeliveryCreate: (
    columnFields?: any,
    startDate?: Date,
    endDate?: Date,
    zoneId?: string,
  ) => void
  globeViewsStore?: GlobeViewsStore
  sitemapsStore?: SitemapsStore
}

@inject('globeViewsStore', 'sitemapsStore')
@observer
export default class DeliveriesMap extends React.Component<IProps> {
  private textboxesCache: ICanvasImageCache = {}

  public constructor(props: IProps) {
    super(props)

    props.store.setGroupingFilter()

    const config = props.store.appState.deliveriesMapIdsList
    const isGlobeMode = !!config?.[0]?.globeViewId
    if (isGlobeMode) {
      const globe = props.globeViewsStore.byId.get(config?.[0]?.globeViewId)
      this.props.store.globeViewControlStore.selectGlobe(globe)
    } else {
      const whiteboard = props.sitemapsStore.byId.get(config?.[0]?.sitemapId)
      this.props.store.selectSitemap(whiteboard)
      this.props.store.mapBoxViewerStore.setViewportFromAddress()
    }
  }

  public render() {
    const { store } = this.props

    return (
      <div className="row full-height bt-light-cool-grey">
        <DeliveriesMapSidebar
          listStore={store.deliveriesBySitemapListStore}
          mapStore={store}
        />
        {this.renderContent()}
      </div>
    )
  }

  public componentDidMount() {
    this.props.store.mount()
  }

  public componentWillUnmount() {
    this.props.store.unmount()
    this.textboxesCache = {}
  }

  public renderContent() {
    const {
      store,
      store: { appState },
    } = this.props
    const { isInitialLoaderShown } = store

    if (isInitialLoaderShown) {
      return (
        <Loader hint={Localization.translator.sitemapsDataIsLoading + '...'} />
      )
    }

    return (
      <div className="col full-height">
        <div className="full-height relative">
          <MapViewsGallery
            mapIds={appState.deliveriesMapIdsList}
            selectedGlobeViewId={
              store.globeViewControlStore.selectedGlobeView?.id
            }
            selectedWhiteboardId={store.baseSitemapSetUpStore.sitemap?.id}
            renderMapViewComponent={this.renderMapView}
            areArrowsLeftSided={true}
            shouldUseFullHeight={true}
            FileInputType={DesktopFileInput}
            mapBoxViewerStore={store.mapBoxViewerStore}
            eventName={SAVE_DELIVERY_CONFIGURATIONS}
            selectGlobe={this.selectGlobeAndCloseModals}
            selectSitemap={this.selectSitemapAndCloseModals}
            shouldDisableZoom={true}
          />
        </div>
      </div>
    )
  }

  private renderMapView = (): JSX.Element => {
    const { store } = this.props
    return (
      <DeliveryMapView
        globe={this.props.store.globeViewControlStore.selectedGlobeView}
        sitemap={this.props.store.baseSitemapSetUpStore.sitemap}
        deliveriesMapStore={store}
        textboxesCache={this.textboxesCache}
      />
    )
  }

  @action.bound
  private selectGlobeAndCloseModals(globe: GlobeView) {
    this.props.store.deselectSitemap()
    this.props.store.selectGlobe(globe)

    this.props.store.mapBoxViewerStore.setViewportFromAddress()
  }

  @action.bound
  private selectSitemapAndCloseModals(sitemap: Sitemap) {
    this.props.store.deselectGlobe()
    this.props.store.selectSitemap(sitemap)

    this.props.store.mapBoxViewerStore.setViewportFromAddress()
  }
}
