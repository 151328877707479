import * as React from 'react'

import { inject, observer } from 'mobx-react'

import LocationBase from '../../../models/LocationObjects/LocationBase'
import TagsStore from '../../../stores/domain/Tags.store'
import SitemapAttributeTag from '../../SitemapAttributeTag/SitemapAttributeTag'
import { ISharedProps } from '../BaseStruxhubInput'
import BaseStruxhubSelector from './BaseStruxhubSelector'

// localization: no text to translate

interface IProps extends ISharedProps {
  onClick?: () => void

  tagsStore?: TagsStore
}

const HIERARCHICAL_SEPARATOR = '/'

@inject('tagsStore')
@observer
export default class StruxhubAttributeSelector extends React.Component<IProps> {
  public render() {
    const { value, tagsStore } = this.props

    const attribute = tagsStore.getTagById(value) as LocationBase

    const hierarchyChains = attribute
      ?.getHierarchyChains(tagsStore.tagStoreByTagTypeMap)
      ?.join(HIERARCHICAL_SEPARATOR)

    return (
      <BaseStruxhubSelector {...this.props} selectorClassName="h40">
        {attribute && (
          <>
            <SitemapAttributeTag
              shouldShowAsTag={true}
              className="no-flex"
              contentContainerClassName="text extra-large text-ellipsis line-24"
              dataObject={attribute}
            >
              {attribute.name}
            </SitemapAttributeTag>
            {hierarchyChains && (
              <span
                className="text text-ellipsis lp05 ml4"
                title={hierarchyChains}
              >
                {hierarchyChains}
              </span>
            )}
          </>
        )}
      </BaseStruxhubSelector>
    )
  }
}
