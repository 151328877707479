import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IUserProjectsFieldsFragment = Pick<
  Types.IUserProject,
  | 'id'
  | 'projectId'
  | 'userId'
  | 'accessType'
  | 'inviteStatus'
  | 'isDeleted'
  | 'companyId'
  | 'roles'
  | 'teams'
  | 'trades'
  | 'defaultTeams'
  | 'responsibilities'
  | 'activitiesDatePickerMode'
> & {
  lastMobileActivityFilters?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.ILastMobileActivityFilter, 'filterType' | 'values'>
      >
    >
  >
  associatedCodes?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IAssociatedCode, 'name' | 'id'>>>
  >
  sitemapsSetupSettings?: Types.Maybe<
    Pick<Types.ISitemapsSetupSettings, 'lastEditedSitemapId'>
  >
  globesSetupSettings?: Types.Maybe<
    Pick<Types.IGlobesSetupSettings, 'lastEditedGlobeId'>
  >
  deliveriesSettings?: Types.Maybe<
    Pick<
      Types.IDeliveryListSettings,
      | 'isFollowingFilterActive'
      | 'isMyCompanyFilterActive'
      | 'selectedGroupByOption'
    > & {
      selectedFilters?: Types.Maybe<
        Array<Types.Maybe<Pick<Types.IDeliveryFilter, 'type' | 'values'>>>
      >
    }
  >
  deliveryNotifications?: Types.Maybe<
    Pick<Types.IUserNotification, 'email' | 'inApp' | 'sms'>
  >
  activityNotifications?: Types.Maybe<
    Pick<Types.IUserNotification, 'email' | 'inApp' | 'sms'>
  >
  announcementNotifications?: Types.Maybe<
    Pick<Types.IUserNotification, 'email' | 'inApp' | 'sms'>
  >
  scannerNotifications?: Types.Maybe<
    Pick<Types.IUserNotification, 'email' | 'inApp' | 'sms'>
  >
  permitNotifications?: Types.Maybe<
    Pick<Types.IUserNotification, 'email' | 'inApp' | 'sms'>
  >
}

export type IUserProjectForActiveUserQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  userId: Types.Scalars['ObjectId']
}>

export type IUserProjectForActiveUserQuery = {
  userProject?: Types.Maybe<IUserProjectsFieldsFragment>
}

export type IUserProjectsByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IUserProjectsByProjectIdQuery = {
  userProjects?: Types.Maybe<{ data: Array<IUserProjectsFieldsFragment> }>
}

export type IListenUserProjectsByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenUserProjectsByProjectIdSubscription = {
  userProject?: Types.Maybe<
    Pick<Types.IUserProjectChangeEvent, 'id'> & {
      item?: Types.Maybe<IUserProjectsFieldsFragment>
    }
  >
}

export type ISaveManyUserProjectsMutationVariables = Types.Exact<{
  userProjects:
    | Array<Types.Maybe<Types.IUserProjectInput>>
    | Types.Maybe<Types.IUserProjectInput>
}>

export type ISaveManyUserProjectsMutation = {
  saveManyUserProjects?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IUserProject, 'id'>>>
  >
}

export const UserProjectsFieldsFragmentDoc = gql`
  fragment UserProjectsFields on UserProject {
    id
    projectId
    userId
    accessType
    inviteStatus
    isDeleted
    companyId
    roles
    teams
    trades
    defaultTeams
    responsibilities
    lastMobileActivityFilters {
      filterType
      values
    }
    associatedCodes {
      name
      id
    }
    activitiesDatePickerMode
    sitemapsSetupSettings {
      lastEditedSitemapId
    }
    globesSetupSettings {
      lastEditedGlobeId
    }
    deliveriesSettings {
      isFollowingFilterActive
      isMyCompanyFilterActive
      selectedGroupByOption
      selectedFilters {
        type
        values
      }
    }
    deliveryNotifications {
      email
      inApp
      sms
    }
    activityNotifications {
      email
      inApp
      sms
    }
    announcementNotifications {
      email
      inApp
      sms
    }
    scannerNotifications {
      email
      inApp
      sms
    }
    permitNotifications {
      email
      inApp
      sms
    }
  }
`
export const UserProjectForActiveUserDocument = gql`
  query UserProjectForActiveUser($projectId: ObjectId!, $userId: ObjectId!) {
    userProject(userId: $userId, projectId: $projectId) {
      ...UserProjectsFields
    }
  }
  ${UserProjectsFieldsFragmentDoc}
`

/**
 * __useUserProjectForActiveUserQuery__
 *
 * To run a query within a React component, call `useUserProjectForActiveUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProjectForActiveUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProjectForActiveUserQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserProjectForActiveUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    IUserProjectForActiveUserQuery,
    IUserProjectForActiveUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IUserProjectForActiveUserQuery,
    IUserProjectForActiveUserQueryVariables
  >(UserProjectForActiveUserDocument, options)
}
export function useUserProjectForActiveUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IUserProjectForActiveUserQuery,
    IUserProjectForActiveUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IUserProjectForActiveUserQuery,
    IUserProjectForActiveUserQueryVariables
  >(UserProjectForActiveUserDocument, options)
}
export type UserProjectForActiveUserQueryHookResult = ReturnType<
  typeof useUserProjectForActiveUserQuery
>
export type UserProjectForActiveUserLazyQueryHookResult = ReturnType<
  typeof useUserProjectForActiveUserLazyQuery
>
export type UserProjectForActiveUserQueryResult = Apollo.QueryResult<
  IUserProjectForActiveUserQuery,
  IUserProjectForActiveUserQueryVariables
>
export const UserProjectsByProjectIdDocument = gql`
  query UserProjectsByProjectId($projectId: ObjectId!) {
    userProjects(projectId: $projectId, limit: 1000000, getDeleted: true) {
      data {
        ...UserProjectsFields
      }
    }
  }
  ${UserProjectsFieldsFragmentDoc}
`

/**
 * __useUserProjectsByProjectIdQuery__
 *
 * To run a query within a React component, call `useUserProjectsByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProjectsByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProjectsByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUserProjectsByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IUserProjectsByProjectIdQuery,
    IUserProjectsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IUserProjectsByProjectIdQuery,
    IUserProjectsByProjectIdQueryVariables
  >(UserProjectsByProjectIdDocument, options)
}
export function useUserProjectsByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IUserProjectsByProjectIdQuery,
    IUserProjectsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IUserProjectsByProjectIdQuery,
    IUserProjectsByProjectIdQueryVariables
  >(UserProjectsByProjectIdDocument, options)
}
export type UserProjectsByProjectIdQueryHookResult = ReturnType<
  typeof useUserProjectsByProjectIdQuery
>
export type UserProjectsByProjectIdLazyQueryHookResult = ReturnType<
  typeof useUserProjectsByProjectIdLazyQuery
>
export type UserProjectsByProjectIdQueryResult = Apollo.QueryResult<
  IUserProjectsByProjectIdQuery,
  IUserProjectsByProjectIdQueryVariables
>
export const ListenUserProjectsByProjectIdDocument = gql`
  subscription ListenUserProjectsByProjectId($projectId: ObjectId!) {
    userProject(projectId: $projectId) {
      id
      item {
        ...UserProjectsFields
      }
    }
  }
  ${UserProjectsFieldsFragmentDoc}
`

/**
 * __useListenUserProjectsByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenUserProjectsByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenUserProjectsByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenUserProjectsByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenUserProjectsByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenUserProjectsByProjectIdSubscription,
    IListenUserProjectsByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenUserProjectsByProjectIdSubscription,
    IListenUserProjectsByProjectIdSubscriptionVariables
  >(ListenUserProjectsByProjectIdDocument, options)
}
export type ListenUserProjectsByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenUserProjectsByProjectIdSubscription
>
export type ListenUserProjectsByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenUserProjectsByProjectIdSubscription>
export const SaveManyUserProjectsDocument = gql`
  mutation saveManyUserProjects($userProjects: [UserProjectInput]!) {
    saveManyUserProjects(userProjects: $userProjects) {
      id
    }
  }
`
export type ISaveManyUserProjectsMutationFn = Apollo.MutationFunction<
  ISaveManyUserProjectsMutation,
  ISaveManyUserProjectsMutationVariables
>

/**
 * __useSaveManyUserProjectsMutation__
 *
 * To run a mutation, you first call `useSaveManyUserProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyUserProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyUserProjectsMutation, { data, loading, error }] = useSaveManyUserProjectsMutation({
 *   variables: {
 *      userProjects: // value for 'userProjects'
 *   },
 * });
 */
export function useSaveManyUserProjectsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyUserProjectsMutation,
    ISaveManyUserProjectsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyUserProjectsMutation,
    ISaveManyUserProjectsMutationVariables
  >(SaveManyUserProjectsDocument, options)
}
export type SaveManyUserProjectsMutationHookResult = ReturnType<
  typeof useSaveManyUserProjectsMutation
>
export type SaveManyUserProjectsMutationResult =
  Apollo.MutationResult<ISaveManyUserProjectsMutation>
export type SaveManyUserProjectsMutationOptions = Apollo.BaseMutationOptions<
  ISaveManyUserProjectsMutation,
  ISaveManyUserProjectsMutationVariables
>
