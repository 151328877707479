import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IScanHistoryFieldsFragment = Pick<
  Types.IScanHistory,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'projectId'
  | 'scannerId'
  | 'userId'
  | 'isAllowed'
  | 'date'
  | 'endDate'
>

export type IScanHistoriesByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IScanHistoriesByProjectIdQuery = {
  scanHistories?: Types.Maybe<{ data: Array<IScanHistoryFieldsFragment> }>
}

export type IListenScanHistoriesByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenScanHistoriesByProjectIdSubscription = {
  scanHistory?: Types.Maybe<
    Pick<Types.IScanHistoryChangeEvent, 'id'> & {
      item?: Types.Maybe<IScanHistoryFieldsFragment>
    }
  >
}

export type ISaveScanHistoryMutationVariables = Types.Exact<{
  scanHistory: Types.IScanHistoryInput
}>

export type ISaveScanHistoryMutation = {
  saveScanHistory?: Types.Maybe<IScanHistoryFieldsFragment>
}

export type ISaveManyScanHistoriesMutationVariables = Types.Exact<{
  scanHistories:
    | Array<Types.Maybe<Types.IScanHistoryInput>>
    | Types.Maybe<Types.IScanHistoryInput>
}>

export type ISaveManyScanHistoriesMutation = {
  saveManyScanHistories?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IScanHistory, 'id'>>>
  >
}

export const ScanHistoryFieldsFragmentDoc = gql`
  fragment ScanHistoryFields on ScanHistory {
    id
    createdAt
    updatedAt
    projectId
    scannerId
    userId
    isAllowed
    date
    endDate
  }
`
export const ScanHistoriesByProjectIdDocument = gql`
  query ScanHistoriesByProjectId($projectId: ObjectId!) {
    scanHistories(limit: 1000000, projectId: $projectId) {
      data {
        ...ScanHistoryFields
      }
    }
  }
  ${ScanHistoryFieldsFragmentDoc}
`

/**
 * __useScanHistoriesByProjectIdQuery__
 *
 * To run a query within a React component, call `useScanHistoriesByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useScanHistoriesByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScanHistoriesByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useScanHistoriesByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IScanHistoriesByProjectIdQuery,
    IScanHistoriesByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IScanHistoriesByProjectIdQuery,
    IScanHistoriesByProjectIdQueryVariables
  >(ScanHistoriesByProjectIdDocument, options)
}
export function useScanHistoriesByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IScanHistoriesByProjectIdQuery,
    IScanHistoriesByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IScanHistoriesByProjectIdQuery,
    IScanHistoriesByProjectIdQueryVariables
  >(ScanHistoriesByProjectIdDocument, options)
}
export type ScanHistoriesByProjectIdQueryHookResult = ReturnType<
  typeof useScanHistoriesByProjectIdQuery
>
export type ScanHistoriesByProjectIdLazyQueryHookResult = ReturnType<
  typeof useScanHistoriesByProjectIdLazyQuery
>
export type ScanHistoriesByProjectIdQueryResult = Apollo.QueryResult<
  IScanHistoriesByProjectIdQuery,
  IScanHistoriesByProjectIdQueryVariables
>
export const ListenScanHistoriesByProjectIdDocument = gql`
  subscription ListenScanHistoriesByProjectId($projectId: ObjectId!) {
    scanHistory(projectId: $projectId) {
      id
      item {
        ...ScanHistoryFields
      }
    }
  }
  ${ScanHistoryFieldsFragmentDoc}
`

/**
 * __useListenScanHistoriesByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenScanHistoriesByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenScanHistoriesByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenScanHistoriesByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenScanHistoriesByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenScanHistoriesByProjectIdSubscription,
    IListenScanHistoriesByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenScanHistoriesByProjectIdSubscription,
    IListenScanHistoriesByProjectIdSubscriptionVariables
  >(ListenScanHistoriesByProjectIdDocument, options)
}
export type ListenScanHistoriesByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenScanHistoriesByProjectIdSubscription
>
export type ListenScanHistoriesByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenScanHistoriesByProjectIdSubscription>
export const SaveScanHistoryDocument = gql`
  mutation SaveScanHistory($scanHistory: ScanHistoryInput!) {
    saveScanHistory(scanHistory: $scanHistory) {
      ...ScanHistoryFields
    }
  }
  ${ScanHistoryFieldsFragmentDoc}
`
export type ISaveScanHistoryMutationFn = Apollo.MutationFunction<
  ISaveScanHistoryMutation,
  ISaveScanHistoryMutationVariables
>

/**
 * __useSaveScanHistoryMutation__
 *
 * To run a mutation, you first call `useSaveScanHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveScanHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveScanHistoryMutation, { data, loading, error }] = useSaveScanHistoryMutation({
 *   variables: {
 *      scanHistory: // value for 'scanHistory'
 *   },
 * });
 */
export function useSaveScanHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveScanHistoryMutation,
    ISaveScanHistoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveScanHistoryMutation,
    ISaveScanHistoryMutationVariables
  >(SaveScanHistoryDocument, options)
}
export type SaveScanHistoryMutationHookResult = ReturnType<
  typeof useSaveScanHistoryMutation
>
export type SaveScanHistoryMutationResult =
  Apollo.MutationResult<ISaveScanHistoryMutation>
export type SaveScanHistoryMutationOptions = Apollo.BaseMutationOptions<
  ISaveScanHistoryMutation,
  ISaveScanHistoryMutationVariables
>
export const SaveManyScanHistoriesDocument = gql`
  mutation SaveManyScanHistories($scanHistories: [ScanHistoryInput]!) {
    saveManyScanHistories(scanHistories: $scanHistories) {
      id
    }
  }
`
export type ISaveManyScanHistoriesMutationFn = Apollo.MutationFunction<
  ISaveManyScanHistoriesMutation,
  ISaveManyScanHistoriesMutationVariables
>

/**
 * __useSaveManyScanHistoriesMutation__
 *
 * To run a mutation, you first call `useSaveManyScanHistoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyScanHistoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyScanHistoriesMutation, { data, loading, error }] = useSaveManyScanHistoriesMutation({
 *   variables: {
 *      scanHistories: // value for 'scanHistories'
 *   },
 * });
 */
export function useSaveManyScanHistoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyScanHistoriesMutation,
    ISaveManyScanHistoriesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyScanHistoriesMutation,
    ISaveManyScanHistoriesMutationVariables
  >(SaveManyScanHistoriesDocument, options)
}
export type SaveManyScanHistoriesMutationHookResult = ReturnType<
  typeof useSaveManyScanHistoriesMutation
>
export type SaveManyScanHistoriesMutationResult =
  Apollo.MutationResult<ISaveManyScanHistoriesMutation>
export type SaveManyScanHistoriesMutationOptions = Apollo.BaseMutationOptions<
  ISaveManyScanHistoriesMutation,
  ISaveManyScanHistoriesMutationVariables
>
