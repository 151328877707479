import * as React from 'react'

import { observer } from 'mobx-react'

import FilterHandleButton from '~/client/src/shared/components/Filters/components/FilterHandleButton/FilterHandleButton'

interface IProps {
  isActive: boolean
  isHidden?: boolean

  toggleShowingOfCDOrders(): void
}

const concreteDirect = 'Concrete Direct'

@observer
export default class ConcreteDirectFilterButton extends React.Component<IProps> {
  public render() {
    const { isActive, isHidden, toggleShowingOfCDOrders } = this.props

    if (isHidden) {
      return null
    }

    return (
      <FilterHandleButton onClick={toggleShowingOfCDOrders} isActive={isActive}>
        <span>{concreteDirect}</span>
      </FilterHandleButton>
    )
  }
}
