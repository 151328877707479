import * as React from 'react'

import { Switch } from '@blueprintjs/core'
import { observer } from 'mobx-react'

import PermitTypeIcon from '~/client/src/shared/components/PermitTypeIcon/PermitTypeIcon'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import StruxhubTextValueSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import ShowInAppModal from '../../ShowInAppModal/ShowInAppModal'

// localization: translated

interface IProps {
  formTypeName: string
  formTypeType: string
  isShownInApp: boolean
  workflowCategoryToDisplay: string
  shouldBlockOnNonWorkTimes: boolean
  lastUpdatedAtLabel: string

  onChange(
    formTypeName: string,
    isShownInApp: boolean,
    shouldBlockOnNonWorkTimes: boolean,
  ): void
}

@observer
export default class GeneralFormConfigurator extends React.Component<IProps> {
  public render() {
    const {
      formTypeName,
      formTypeType,
      isShownInApp,
      shouldBlockOnNonWorkTimes,
      lastUpdatedAtLabel,
      workflowCategoryToDisplay,
    } = this.props

    return (
      <div className="col mw400 pl10">
        <StruxhubInput
          className="overflow-hidden no-flex pb10"
          label={Localization.translator.workflowName}
          isRequired
          isValid
          isHelperTextCondensed
          value={formTypeName || EMPTY_STRING}
          onChange={this.changeFormName}
        />
        <StruxhubTextValueSelector
          className="overflow-hidden no-flex pb10"
          label={Localization.translator.workflowCategory}
          isRequired
          isReadOnly
          isHelperTextCondensed
          value={workflowCategoryToDisplay}
        />
        <div className="row pt8 pb10">
          <span className="text large line-extra-large">
            {Localization.translator.icon}
          </span>
          <StruxhubTextValueSelector
            className="overflow-hidden no-flex pb10"
            isMinimalisticMode
            isHelperTextCondensed
            isReadOnly
            customIconElement={
              <PermitTypeIcon
                className="row no-grow mr5"
                permitType={formTypeType}
              />
            }
          />
        </div>
        <ShowInAppModal
          formTypeId={null}
          modalClassName="mw400"
          isShown={isShownInApp}
          isDisplayedAsSelector
          onEnableChange={this.changeShowInApp}
        />
        <div className="row non-work-times-configurator py10">
          <div className="text large line-extra-large full-flex-basis">
            {Localization.translator.blockRequestsForNonWorkTimes}
          </div>
          <Switch
            className="primary-blue-switch bp3-align-right no-outline-container"
            checked={shouldBlockOnNonWorkTimes}
            onChange={this.changeBlockNonWorkTime}
          />
        </div>
        <div className="row py10 text large line-extra-large">
          <span>{Localization.translator.lastUpdated}</span>
          <span className="no-grow nowrap">{lastUpdatedAtLabel}</span>
        </div>
      </div>
    )
  }

  private changeFormName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(
      event.target.value,
      this.props.isShownInApp,
      this.props.shouldBlockOnNonWorkTimes,
    )
  }

  private changeShowInApp = () => {
    this.props.onChange(
      this.props.formTypeName,
      !this.props.isShownInApp,
      this.props.shouldBlockOnNonWorkTimes,
    )
  }

  private changeBlockNonWorkTime = () => {
    this.props.onChange(
      this.props.formTypeName,
      this.props.isShownInApp,
      !this.props.shouldBlockOnNonWorkTimes,
    )
  }
}
