import * as React from 'react'

import { observer } from 'mobx-react'

import DeliveryActionTypes from '~/client/src/desktop/enums/DeliveryActionTypes'
import DesktopDeliveryViewStore from '~/client/src/desktop/views/Deliveries/Deliveries.store'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import DeliveryGroupingOptions from '../DeliveryGroupingOptions/DeliveryGroupingOptions'
import NewDeliveryActionButton from '../NewDeliveryActionButton/NewDeliveryActionButton'

import './DeliveriesMapActionBar.scss'

// localization: translated

interface IProps {
  onNewDeliveryCreate: () => void
  setActionType: (actionType: DeliveryActionTypes) => void
  activeActionType: DeliveryActionTypes
  completedToTotalCountLabel: string
  desktopDeliveryViewStore: DesktopDeliveryViewStore
  onGropingChanged: () => any
  shouldForceCloseGroupingOptions: boolean
  onShowGroupingOptionsChanged: (isShown: boolean) => void
}

@observer
export default class DeliveriesMapActionBar extends React.Component<IProps> {
  public render() {
    const {
      onGropingChanged,
      onNewDeliveryCreate,
      desktopDeliveryViewStore,
      completedToTotalCountLabel,
      onShowGroupingOptionsChanged,
      shouldForceCloseGroupingOptions,
    } = this.props

    return (
      <div className="row bt-light-cool-grey deliveries-map-action-bar no-outline-container px24 absolute full-width">
        <div className="row no-grow br-light-cool-grey sidebar full-height">
          <DeliveryGroupingOptions
            onChanged={onGropingChanged}
            CustomHandleButton={BaseActionButton}
            onShowChanged={onShowGroupingOptionsChanged}
            forceClose={shouldForceCloseGroupingOptions}
            desktopDeliveryViewStore={desktopDeliveryViewStore}
            shouldHideBandCreation={true}
          />
          <div
            className="text right ellipsis ml4 mr10"
            title={`${Localization.translator.completedTotal} ${completedToTotalCountLabel}`}
          >
            <span className="text large light mr5">
              {Localization.translator.completedTotal}
            </span>
            <span className="text bold">{completedToTotalCountLabel}</span>
          </div>
        </div>
        <div className="row x-end full-height">
          <div className="absolute new-delivery-btn">
            <NewDeliveryActionButton
              isActive={this.props.activeActionType === DeliveryActionTypes.NEW}
              onClick={onNewDeliveryCreate}
            />
          </div>
        </div>
      </div>
    )
  }
}
