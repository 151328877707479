import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { IPermitTypeField, ISiteLocation, LocationType } from '~/client/graph'
import HierarchyChains from '~/client/src/shared/components/HierarchyChains'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import StruxhubAttributeSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubAttributeSelector'
import routingLocationStructureList from '~/client/src/shared/constants/routingLocationStructureList'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { getLocationTypeDisplayName } from '~/client/src/shared/types/IHierarchyParent'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'
import { isEqualDeep } from '~/client/src/shared/utils/util'

import SitePermitCreationFormStore from '../../SitePermitCreationForm.store'
import PermitBaseFormField from './PermitBaseFormField'

// localization: no text to translate

interface IProps {
  typeField: IPermitTypeField
  isViewMode: boolean

  store: SitePermitCreationFormStore
  fieldsStore: IPermitFieldsStore

  tagsStore?: TagsStore
}

// doesn't support tables
@inject('tagsStore')
@observer
export default class PermitFormRoutingLocationField extends React.Component<IProps> {
  public componentDidMount(): void {
    if (this.shouldPopulateOnMount) {
      this.fillAdditionalRoutingValues()
    }
  }

  public render() {
    const { isViewMode, typeField, fieldsStore } = this.props

    return (
      <PermitBaseFormField
        typeField={typeField}
        isViewMode={isViewMode}
        viewModeElements={this.viewModeElements}
      >
        <StruxhubAttributeSelector
          className="overflow-hidden"
          label={this.mainRoutingFieldCaption}
          isRequired={typeField.isMandatory}
          isChanged={fieldsStore.isFieldChanged(typeField.id, 0)}
          onClick={this.openMainRoutingLocPicker}
          value={this.mainRoutingValue?.id || EMPTY_STRING}
        />
        {this.routingLocations.map(locType => (
          <React.Fragment key={locType}>
            <StruxhubAttributeSelector
              className="overflow-hidden"
              label={getLocationTypeDisplayName(locType)}
              isRequired={false}
              isChanged={this.isRoutingLocChanged(locType)}
              onClick={this.openAdditionalLocPicker.bind(this, locType)}
              value={this.getValueByType(locType).id}
            />
          </React.Fragment>
        ))}
      </PermitBaseFormField>
    )
  }

  private isRoutingLocChanged(locType: LocationType): boolean {
    if (!this.props.fieldsStore.editableEntity?.id) {
      return false
    }

    const existingValue = this.getValueByType(locType, true)
    const editedValue = this.getValueByType(locType)

    return !isEqualDeep(existingValue, editedValue)
  }

  private getValueByType = (
    locType: LocationType,
    isExisting?: boolean,
  ): ISiteLocation => {
    const values = isExisting ? this.existingValues : this.fieldValues
    return values.find(l => l.type === locType) || { id: null }
  }

  private get routingLocations(): LocationType[] {
    if (!this.mainRoutingValue?.id) return []

    const selectedTypeIdx = routingLocationStructureList.findIndex(
      t => t === this.mainRoutingValue.type,
    )
    return routingLocationStructureList.slice(selectedTypeIdx + 1)
  }

  private get mainRoutingValue(): ISiteLocation {
    return this.fieldValues[0]
  }

  private get mainRoutingFieldCaption(): string {
    const { caption } = this.props.typeField

    return this.mainRoutingValue?.type
      ? `${caption} (${getLocationTypeDisplayName(this.mainRoutingValue.type)})`
      : caption
  }

  private get viewModeElements(): JSX.Element[] {
    const { tagsStore } = this.props

    return this.fieldValues.reduce((list, loc, idx) => {
      const location = tagsStore.getTag(loc?.type, loc?.id) as LocationBase

      if (location) {
        list.push(
          <div key={`${location.id}_${idx}`} className="overflow-hidden">
            <SitemapAttributeTag
              shouldShowAsTag={false}
              dataObject={location}
              className="row"
              contentContainerClassName="text large ellipsis"
            >
              {location.name}
            </SitemapAttributeTag>
            <HierarchyChains
              className="text light ml20"
              hierarchyChains={location.getHierarchyChains(
                tagsStore.tagStoreByTagTypeMap,
              )}
            />
          </div>,
        )
      }

      return list
    }, [])
  }

  private get fieldValues(): ISiteLocation[] {
    const { typeField, fieldsStore } = this.props

    const values = fieldsStore.getFieldValues<ISiteLocation>(typeField.id)
    return values?.length ? values : [{ id: null }]
  }

  private get existingValues(): ISiteLocation[] {
    const { typeField, fieldsStore } = this.props

    const values = fieldsStore.getFieldValues<ISiteLocation>(
      typeField.id,
      null,
      null,
      true,
    )
    return values?.length ? values : [{ id: null }]
  }

  private get shouldPopulateOnMount(): boolean {
    return (
      !this.props.store.editablePermit?.id &&
      this.mainRoutingValue?.id &&
      this.fieldValues.length <= 1
    )
  }

  private openMainRoutingLocPicker = () => {
    const { store, typeField } = this.props

    store.setSelectedField(typeField, 0)
    store.setSelectedOnChangeHandler(this.changeRoutingLocation)
  }

  private openAdditionalLocPicker(locType: LocationType) {
    const { store, typeField } = this.props

    store.setSelectedField(typeField)
    store.setSelectedLocationType(locType)
    store.setSelectedOnChangeHandler(
      this.changeAdditionalLocation.bind(this, locType),
    )
  }

  private changeRoutingLocation = <ISiteLocation,>(newValue: ISiteLocation) => {
    const { fieldsStore, typeField } = this.props

    fieldsStore.clearFieldValues(typeField.id)
    fieldsStore.changeFieldValue(typeField, 0, newValue)
    this.fillAdditionalRoutingValues()
  }

  private changeAdditionalLocation(
    locationType: LocationType,
    newValue: ISiteLocation,
  ) {
    const { fieldsStore, typeField } = this.props

    const idx = this.fieldValues.findIndex(l => l.type === locationType)

    if (idx > 0) {
      return fieldsStore.changeFieldValue(typeField, idx, newValue)
    }

    fieldsStore.addNewFieldValue(typeField, newValue)

    fieldsStore.sortFieldValues<ISiteLocation>(
      typeField.id,
      (a, b) =>
        routingLocationStructureList.indexOf(a.type) -
        routingLocationStructureList.indexOf(b.type),
    )
  }

  private fillAdditionalRoutingValues = () => {
    const { fieldsStore, store, typeField, tagsStore } = this.props

    this.routingLocations.forEach(locType => {
      const list = tagsStore.tagListsByTagTypeMap[locType]
      if (list.length !== 1) return

      fieldsStore.addNewFieldValue(typeField, {
        id: list[0].id,
        type: list[0].type,
      })
    })

    store.resetLocationsAndEquipmentIfNeed()
  }
}
