import * as React from 'react'

import { Button } from '@blueprintjs/core'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { REPORT_ERROR } from '~/client/src/shared/stores/EventStore/eventConstants'

interface IProps {
  eventsStore?: EventsStore
}

@inject('eventsStore')
@observer
export default class ErrorBoundary extends React.Component<IProps> {
  @observable private hasError: boolean = false

  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.hasError = true
    this.props.eventsStore.dispatch(
      REPORT_ERROR,
      info.componentStack,
      error,
      'error',
    )
  }

  public UNSAFE_componentWillReceiveProps() {
    this.hasError = false
  }

  public render() {
    if (this.hasError) {
      return this.renderErrorMessage()
    }

    return this.props.children
  }

  private renderErrorMessage() {
    return (
      <div>
        <div className="text large center">Something went wrong.</div>
        <div className="row x-center mt30">
          <Button
            className="primary-blue-btn no-grow"
            style={{ flexBasis: 100 }}
            onClick={this.reloadPage}
          >
            Reload
          </Button>
        </div>
      </div>
    )
  }

  private reloadPage() {
    const { location } = window
    location.pathname = ''
  }
}

export function withErrorBoundary<T extends object>(
  Component: React.ComponentType<T>,
) {
  return (props: T) => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  )
}
