import * as React from 'react'

import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { Loader } from '~/client/src/shared/components/Loader'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import WhiteListItemsStore from '~/client/src/shared/stores/domain/WhiteListItems.store'
import { reduceByCategories } from '~/client/src/shared/utils/util'

import CompanyDomainsInput from '../CompanyDomainsInput/CompanyDomainsInput'

const projectLevelDomainsDescription = `Project Level Domains are not specific to any company but act as white listed domains. 
It is best to NOT white list generic domains like @gmail.com, @outlook.com, @iCloud.com. 
Doing this would allow unverified users access to your project`

const companyDomainsDescription =
  'You can add or remove domains for any of your existing Subcontractors below or on each subcontractors Company Card on Company Directory page'

interface IProps {
  whiteListItemsStore?: WhiteListItemsStore
  companiesStore?: CompaniesStore
}

const projectLevelKey = 'null'

const projectLevelDomains = 'Project level domains'

@inject('whiteListItemsStore', 'companiesStore')
@observer
export default class WhiteList extends React.Component<IProps> {
  @observable private searchValue: string = ''
  @observable private isLoading: boolean = false

  public componentDidMount() {
    this.requestDomains()
  }

  public render() {
    const { companiesStore } = this.props

    if (this.isLoading) {
      return <Loader hint={Localization.translator.loading}></Loader>
    }

    return (
      <div className="col scrollable px15">
        <StruxhubInput
          className="no-grow"
          value={this.searchValue}
          isRequiredTextHidden={true}
          onChange={this.handleChangeSearchValue}
          onValueReset={this.handleResetSearchValue}
          placeholder={Localization.translator.search}
        />

        <div className="overflow-y-auto">
          {this.filteredCompaniesIds.map((companyId, index) => {
            const companyName = companiesStore.getCompanyNameById(
              companyId,
              projectLevelDomains,
            )

            return (
              <div key={companyId} className="py10">
                {this.renderHintIfNeed(index)}
                <div
                  className={classList({
                    'text large bold': true,
                    'gold underline uppercase': companyId === projectLevelKey,
                  })}
                >
                  {companyName}
                </div>
                <CompanyDomainsInput
                  isRequiredTextHidden={true}
                  companyId={companyId === projectLevelKey ? null : companyId}
                  searchKey={this.searchKey}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  public componentWillUnmount() {
    this.props.whiteListItemsStore.clear()
  }

  @computed
  private get searchKey(): string {
    return this.searchValue.toLocaleLowerCase().trim()
  }

  @computed
  private get domainNamesByCompanyId(): {
    [companyId: string]: string[]
  } {
    const { byCompanies } = this.props.whiteListItemsStore
    return reduceByCategories(this.sortedCompaniesIds, companyId => [
      companyId,
      ...(byCompanies[companyId]?.map(i => i.domainName) || []),
    ])
  }

  @computed
  private get filteredCompaniesIds(): string[] {
    return this.searchKey
      ? this.sortedCompaniesIds.filter(cId => {
          const domains = this.domainNamesByCompanyId[cId]
          return domains.some(domain =>
            domain?.toLowerCase().includes(this.searchKey),
          )
        })
      : this.sortedCompaniesIds
  }

  @computed
  private get sortedCompaniesIds(): string[] {
    const companiesIds = Object.keys(this.props.whiteListItemsStore.byCompanies)
    return [
      projectLevelKey,
      ...companiesIds.filter(cId => cId !== projectLevelKey),
    ]
  }

  @action.bound
  private async requestDomains() {
    this.isLoading = true
    await this.props.whiteListItemsStore.requestAllItems()
    this.isLoading = false
  }

  @action.bound
  private handleChangeSearchValue(event: React.ChangeEvent<HTMLInputElement>) {
    this.searchValue = event.target.value
  }

  @action.bound
  private handleResetSearchValue() {
    this.searchValue = ''
  }

  private renderHintIfNeed(index: number): JSX.Element {
    if (index > 1) {
      return null
    }

    return (
      <label className="inline-block text large pb10">
        {index ? companyDomainsDescription : projectLevelDomainsDescription}
      </label>
    )
  }
}
