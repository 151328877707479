import {
  DefaultLandingPage,
  IProject,
  IProjectClosedInterval,
  IProjectInput,
  IWorkingHours,
} from '~/client/graph'

import DateTimeFormat from '../enums/DateTimeFormat'
import { defaultTimezoneId } from '../utils/timezones'
import BaseModel from './BaseModel'

export const DEFAULT_WORK_START_HOUR = 6
export const DEFAULT_WORK_FINISH_HOUR = 18

export default class Project extends BaseModel<IProject> {
  public static none: Project

  public static fromDto(dto: IProject): Project {
    const project = new Project(dto.id)

    return Object.assign(project, dto, {
      dateTimeFormat: dto.dateTimeFormat || DateTimeFormat.Default,
      timezoneId: dto.timezoneId || defaultTimezoneId,
      workingDays: dto.workingDays || [],
    })
  }

  public static toInput(project: Project | IProject): IProjectInput {
    return {
      id: project.id || null,
      name: project.name,
      code: project.code,
      logoUrl: project.logoUrl,
      scheduleId: project.scheduleId || null,
      materialsUploadId: project.materialsUploadId || null,
      dateTimeFormat: project.dateTimeFormat,
      timezoneId: project.timezoneId,
      closedIntervals: project.closedIntervals,
      setUpFinished: project.setUpFinished,
      workingDays: project.workingDays,
      workingHours: project.workingHours,
      defaultLandingPage: project.defaultLandingPage,
    }
  }

  public id: string
  public code: string
  public logoUrl: string
  public name: string
  public scheduleId: string
  public materialsUploadId: string
  public timezoneId: string
  public dateTimeFormat: DateTimeFormat = DateTimeFormat.Default
  public setUpFinished: string[]
  public closedIntervals?: IProjectClosedInterval[]
  public workingHours?: IWorkingHours
  public workingDays?: number[]
  public defaultLandingPage?: DefaultLandingPage

  public toInput(): IProjectInput {
    return Project.toInput(this)
  }

  public get isNone() {
    return this.isSameAs(Project.none)
  }

  public areIdsEquals(project: { id?: string }) {
    return this.id === project.id
  }

  public get projectWorkingDaysMap() {
    const map: { [key: string]: boolean } = {}

    if (!this.workingDays) {
      return map
    }
    this.workingDays.forEach(day => {
      map[day] = true
    })

    return map
  }
}

Project.none = Object.assign(new Project(), {
  name: '',
  code: '',
  logoUrl: '',
  scheduleId: '',
  addressId: null,
  timezoneId: '',
})
