import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Checkbox from '~/client/src/shared/components/Checkbox'

import FilterFooter from '../../../../shared/components/Filters/FilterUtils/FilterFooter/FilterFooter'
import FilterHeader from '../../../../shared/components/Filters/FilterUtils/FilterHeader/FilterHeader'
import PhotosPageStore, {
  IPhotosFilterOption,
  PhotoFilterTypes,
} from '../PhotosPageStore.store'

import './PhotosFilter.scss'

interface ICategoryFilterProps {
  options: IPhotosFilterOption[]
  values: string[]
  store: PhotosPageStore
  photoFilterType: PhotoFilterTypes
}

@inject('state')
@observer
export default class PhotosFilterContent extends React.Component<ICategoryFilterProps> {
  @observable private currentOptions: string[]

  public UNSAFE_componentWillMount() {
    this.currentOptions = this.props.values.slice()
  }

  public render() {
    const { photoFilterType, store } = this.props

    return (
      <>
        <FilterHeader
          clickOnClearAll={this.onClearAllClick}
          clickOnSelectAll={this.onSelectAllClick}
        >
          <div className="row bb-light-input-border mt25 pb10">
            <span className="text bold large primary-blue">
              {photoFilterType}
            </span>
            <span className="text bold large right">Number of photos</span>
          </div>
        </FilterHeader>

        <div className={'bb-light-input-border filter-content'}>
          {this.content}
        </div>

        <FilterFooter
          clickOnCancel={store.toggle.bind(this, photoFilterType)}
          clickOnApply={store.onApplyClick.bind(
            this,
            this.currentOptions,
            photoFilterType,
          )}
          applyButtonText="See Photos"
        />
      </>
    )
  }

  private get content() {
    return (
      <div className="mx15">
        {this.props.options.map((option, index) => {
          return (
            <div
              key={index}
              className={classList({
                'option-row my10 row': true,
                disabled: !option.count,
              })}
            >
              {this.renderRow(option)}
            </div>
          )
        })}
      </div>
    )
  }

  private renderRow(option: IPhotosFilterOption) {
    const { count, name, id } = option
    return (
      <>
        <Checkbox
          isChecked={this.isOptionSelected(id)}
          onClick={this.selectOption.bind(this, id)}
        />
        <div className="row">
          <div className="name-cell text large light">{name}</div>
          <div className="amount-cell text large primary-blue right">
            {count}
          </div>
        </div>
      </>
    )
  }

  private isOptionSelected = (option: string): boolean => {
    return this.currentOptions.includes(option)
  }

  private selectOption(option: string) {
    if (this.currentOptions.includes(option)) {
      const index = this.currentOptions.findIndex(item => item === option)
      this.currentOptions.splice(index, 1)
      return
    }
    this.currentOptions.push(option)
    return
  }

  @action.bound
  private onClearAllClick() {
    this.currentOptions = []
  }

  @action.bound
  private onSelectAllClick() {
    this.currentOptions = this.props.options.map(o => o.id)
  }
}
