import { action, computed, observable } from 'mobx'

import { ISitePermitInspection } from '~/client/graph'
import PermitInspection from '~/client/src/shared/models/PermitInspection'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'

export default class PermitInspectionsStore {
  @observable public isDataReceived = false

  public constructor(private eventsStore: EventsStore) {}

  @computed
  public get list() {
    return Array.from(this.byId.values())
  }

  private get byId() {
    return this.eventsStore.appState.permitInspections
  }

  @action.bound
  public clearList() {
    this.isDataReceived = false
    this.byId.clear()
  }

  @action.bound
  public receiveList(list: ISitePermitInspection[]) {
    this.clearList()
    this.updateFromList(list)
    this.isDataReceived = true
  }

  private updateFromList(list: ISitePermitInspection[]) {
    list.forEach(dto => this.receiveOne(dto.id, dto))
  }

  @action.bound
  public receiveOne(id: string, dto: ISitePermitInspection) {
    if (dto) {
      const inspection = PermitInspection.fromDto(dto)
      this.byId.set(inspection.id, inspection)
    } else {
      this.byId.delete(id)
    }
  }

  public getInspectionsForPermit = (permitId: string): PermitInspection[] => {
    return this.list
      .filter(sc => sc.permitId === permitId)
      .sort((a, b) => a.createdAt - b.createdAt)
  }
}
