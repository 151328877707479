import React from 'react'

import { Switch } from '@blueprintjs/core'

import AnalyticsReportsType from '~/client/src/shared/enums/AnalyticReportsType'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import AnalyticsSetupStore from '../AnalyticsSetup.store'

interface IProps {
  store: AnalyticsSetupStore
}

export default class AnalyticsSetupList extends React.Component<IProps> {
  public componentWillMount(): void {
    this.props.store.updateAnalyticsSettings()
  }

  public render() {
    const {
      reportTypes,
      toggleAvailableReports,
      isAvailableReport,
      openConfigureModal,
    } = this.props.store
    return (
      <div className="col">
        <div className="row">
          <div className="parameter-value pt7">
            <div className="row mx30 mb20 bb2-brand-dark">
              <div>{Localization.translator.type}</div>
              <div className="mw120 row text large x-center">
                <div>{Localization.translator.showInReports}</div>
              </div>
              <div className="row mw160 large x-center">Configure</div>
            </div>
            {reportTypes.map(type => (
              <div key={type.id} className="row mx30 mb10 pb10 bb-light-grey">
                <div className="row">
                  <span className="text extra-large bold w-fit-content ml5">
                    {type.name}
                  </span>
                </div>
                <div className="mw120 row">
                  <Switch
                    className="row x-center no-flex-children primary-blue-switch no-outline-container"
                    checked={isAvailableReport(type.id)}
                    onChange={toggleAvailableReports.bind(null, type.id)}
                    alignIndicator="center"
                  />
                </div>
                <div className="mw160">
                  {type.type === AnalyticsReportsType.FORM_REPORT && (
                    <div
                      className="row x-center y-center btn-filter gant-header-btn no-flex gant-header-btn-outline"
                      onClick={openConfigureModal.bind(null, type)}
                    >
                      <span>{Localization.translator.configureColumns}</span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
