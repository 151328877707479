import * as React from 'react'

import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import StruxhubInput from '../../../StruxhubInputs/StruxhubInput'
import { PasswordResetInputNames } from '../../PasswordResetView.store'

interface IProps {
  className?: string
  inputValueReset: (name: PasswordResetInputNames) => void
  onRequestPasswordReset: (e) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  getValue: (name: PasswordResetInputNames) => string
}

@observer
export default class RequestResetPasswordForm extends React.Component<IProps> {
  public render() {
    const { onRequestPasswordReset, inputValueReset, onChange, getValue } =
      this.props

    const { EMAIL } = PasswordResetInputNames

    return (
      <form onSubmit={onRequestPasswordReset}>
        <div className="text large primary-blue py20">
          {
            Localization.translator
              .enterYourEmailBelowAndStruxHubWillSendAResetLink
          }
        </div>
        <StruxhubInput
          type="email"
          name={EMAIL}
          label={Localization.translator.email_noun}
          isRequiredTextHidden={true}
          isRequired={true}
          value={getValue(EMAIL)}
          onChange={onChange}
          onValueReset={inputValueReset.bind(null, EMAIL)}
          autoComplete="username email"
        />
        <button className="full-width bg-blue ba-none brada4 pa10 mt10">
          <span className="text white extra-large">
            {Localization.translator.resetPassword}
          </span>
        </button>
      </form>
    )
  }
}
