import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ILocationTransferPair from '~/client/src/shared/models/ILocationTransferPair'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import SitePermit from '~/client/src/shared/models/Permit'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import { DataKeys, IFormCellData } from '../MaterialsList.store'
import BaseDataCell from './BaseDataCell/BaseDataCell'
import MaterialTransferCellTable from './MaterialTransferCellTable'

// localization: translated

interface IProps {
  dataKey: string
  formCellData: IFormCellData
  isTransferEnabled: boolean
  onNewTransferClick(
    viewModelId: string,
    selectedLocationsMap: Map<string, ILocationTransferPair>,
  ): void
  onTransferIdClick(transferId: string): void
  locationRenderer(location: LocationBase): JSX.Element

  projectDateStore?: ProjectDateStore
}

const transferCellColumnsIds = [
  DataKeys.MATERIAL_TRANSFER_DATE,
  DataKeys.PLANNED_QUANTITY,
  DataKeys.STATUS,
  DataKeys.CURRENT_MATERIAL_LOCATION,
  DataKeys.PLANNED_INSTALL_LOCATION,
]

const isFormDone = (form: SitePermit): boolean => form.isDone
const isFormInRange = (
  form: SitePermit,
  startDate: Date,
  endDate: Date,
): boolean => form.isScheduledWithinRange(startDate, endDate)

@inject('projectDateStore')
@observer
export default class MaterialTransferCell extends React.Component<IProps> {
  public static defaultProps = {
    formCellData: {},
  }

  public render() {
    const { formCellData, isTransferEnabled } = this.props
    const { transferForms, itemsCount } = formCellData

    return (
      <BaseDataCell<SitePermit>
        items={transferForms}
        newItemIcon={
          <Icons.MaterialTransferForm className="row relative no-flex mr5" />
        }
        newItemText={Localization.translator.newTransfer}
        onNewItemClick={this.onNewTransferClick}
        renderActiveItems={this.renderActiveFormsTable}
        renderDoneItems={this.renderDoneFormsTable}
        doneFilter={isFormDone}
        dateRangeFilter={isFormInRange}
        isNewItemBtnHidden={!isTransferEnabled}
        isNewValueShown={this.isTransferIdCell && isTransferEnabled}
        isTotalDoneValueShown={this.isTransferIdCell}
        isDateRangeShown
        doneTableText={Localization.translator.doneTransfers}
        getTotalDoneValue={this.getTotalQuantity}
      >
        <div
          className={classList({
            'row pointer h48 no-select': true,
            'underline-hover': !!itemsCount,
          })}
        >
          <div className="text-ellipsis">{this.cellLabel}</div>
          <Icons.DownArrow
            className="bp3-icon ml5 icon-w11 icon-grey"
            element="span"
          />
        </div>
      </BaseDataCell>
    )
  }

  private get cellLabel(): string | JSX.Element {
    const { itemsCount } = this.props.formCellData

    if (!itemsCount) {
      return NO_VALUE
    }
    return itemsCount > 1 ? this.xItemsLabel : this.oneItemLabel
  }

  private get xItemsLabel(): string {
    const {
      formCellData: { itemsCount },
      dataKey,
    } = this.props
    switch (dataKey) {
      case DataKeys.MATERIAL_TRANSFER_ID:
        return Localization.translator.xTransfers(itemsCount)
      case DataKeys.MATERIAL_TRANSFER_DATE:
        return Localization.translator.xDates(itemsCount)
      default:
        return null
    }
  }

  private get oneItemLabel(): JSX.Element | string {
    const {
      projectDateStore: { getMonthDayYearAndTimeToDisplay },
      dataKey,
    } = this.props

    const form = this.allActiveForms[0]
    switch (dataKey) {
      case DataKeys.MATERIAL_TRANSFER_ID:
        return form.code
      case DataKeys.MATERIAL_TRANSFER_DATE:
        return getMonthDayYearAndTimeToDisplay(form.startDate)
      default:
        return null
    }
  }

  private renderActiveFormsTable = (activeForms: SitePermit[]): JSX.Element => {
    const { formCellData, locationRenderer, onTransferIdClick } = this.props
    return (
      <MaterialTransferCellTable
        isFooterHidden={!this.isTransferIdCell}
        shownColumnIds={this.shownColumnIds}
        forms={activeForms}
        procurementId={formCellData.procurementId}
        materialId={formCellData.materialId}
        footerTitle={Localization.translator.totalBooked}
        locationRenderer={locationRenderer}
        onIdClick={onTransferIdClick}
      />
    )
  }

  private renderDoneFormsTable = (doneForms: SitePermit[]): JSX.Element => {
    const { formCellData, locationRenderer, onTransferIdClick } = this.props

    return (
      <MaterialTransferCellTable
        isHeaderHidden
        isFooterHidden={!this.isTransferIdCell}
        shownColumnIds={this.shownColumnIds}
        forms={doneForms}
        procurementId={formCellData.procurementId}
        materialId={formCellData.materialId}
        footerTitle={Localization.translator.totalTransferred}
        locationRenderer={locationRenderer}
        onIdClick={onTransferIdClick}
      />
    )
  }

  private get allActiveForms(): SitePermit[] {
    return this.props.formCellData.transferForms.filter(f => !f.isDone)
  }

  private getTotalQuantity = (doneForms: SitePermit[]): React.ReactText => {
    const { procurementId, materialId } = this.props.formCellData
    return doneForms.reduce(
      (sum, form) =>
        (sum +=
          (procurementId
            ? form.procurementQuantitiesMap[procurementId]
            : form.materialQuantitiesMap[materialId]) || 0),
      0,
    )
  }

  private get shownColumnIds(): string[] {
    return this.isTransferIdCell ? transferCellColumnsIds : [this.props.dataKey]
  }

  private get isTransferIdCell(): boolean {
    return this.props.dataKey === DataKeys.MATERIAL_TRANSFER_ID
  }

  private onNewTransferClick = () => {
    const { onNewTransferClick, formCellData } = this.props
    const pair: ILocationTransferPair = { location: null, quantity: null }
    onNewTransferClick(
      formCellData.id,
      new Map<string, ILocationTransferPair>([[null, pair]]),
    )
  }
}
