import * as React from 'react'

import { inject, observer } from 'mobx-react'

import HierarchyChains from '~/client/src/shared/components/HierarchyChains'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

const DEFAULT_ICON_SIZE = 20

interface IProps {
  dto: LocationBase

  iconSize?: number
  isParentChainHidden?: boolean
  tagClassName?: string
  contentContainerClassName?: string
  contentClassName?: string
  chainClassName?: string

  tagsStore?: TagsStore
}

@inject('tagsStore')
@observer
export default class Location extends React.Component<IProps> {
  public static defaultProps = {
    contentClassName: 'text extra-large parent-tag',
    chainClassName: 'text light ml30',
    contentContainerClassName: 'text-ellipsis',
  }

  public render() {
    const {
      dto,
      iconSize,
      isParentChainHidden,
      contentContainerClassName,
      tagClassName,
      contentClassName,
      chainClassName,
      tagsStore,
    } = this.props
    const hierarchyChains = dto.getHierarchyChains(
      tagsStore.tagStoreByTagTypeMap,
    )

    return (
      <>
        <SitemapAttributeTag
          shouldShowAsTag={false}
          dataObject={dto}
          iconSize={iconSize || DEFAULT_ICON_SIZE}
          className={tagClassName}
          contentContainerClassName={contentContainerClassName}
        >
          <span className={contentClassName} title={dto.name}>
            {dto.name}
          </span>
        </SitemapAttributeTag>
        {!isParentChainHidden && (
          <HierarchyChains
            className={chainClassName}
            hierarchyChains={hierarchyChains}
          />
        )}
      </>
    )
  }
}
