import BaseEventsStore, { EventHandler } from './BaseEvents.store'
import EventTypes from './eventTypes'

// TODO: Do we even need this layer?
// Why not just use BaseEventsStore directly?
export default class EventsStore extends BaseEventsStore {
  public dispatch(type: EventTypes, ...args: any[]) {
    return super.dispatch.call(this, type, ...args)
  }

  public on(type: EventTypes, handler: EventHandler) {
    return super.on.call(this, type, handler)
  }
}
