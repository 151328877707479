import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import { IHistoryItem } from '~/client/graph'
import ProjectSetUpPageStore from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPage.store'
import { Loader } from '~/client/src/shared/components/Loader'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import getUserName from '~/client/src/shared/utils/GetUserName'

import ProjectSetUpPage from '../../ProjectSetUpPages'

export interface IAdminSummaryProps {
  projectSetUpPageStore: ProjectSetUpPageStore
  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
export default class AdminSummary extends React.Component<IAdminSummaryProps> {
  public render() {
    if (this.props.projectSetUpPageStore.isHistoryLoading) {
      return <Loader />
    }

    return (
      <div className="admin-summary-container">
        {this.renderHeaderBlock()}
        {this.renderProjectBlock()}
        {this.renderScheduleBlock()}
        {this.renderTeamListBlock()}
      </div>
    )
  }

  public renderHeaderBlock() {
    return (
      <div className="admin-summary-header text uppercase">
        {Localization.translator.projectAdminSummary}
      </div>
    )
  }

  public renderProjectBlock() {
    const projectUpdate =
      this.props.projectSetUpPageStore.getLastPageUpdate('project')
    const { PROJECT_DETAILS } = ProjectSetUpPage
    return (
      <div className="admin-summary-content-block">
        <div
          className="admin-summary-content-block-icon"
          onClick={this.navigateTo.bind(this, PROJECT_DETAILS)}
        >
          <span className="project-icon" />
        </div>

        <div className="admin-summary-content-block-info">
          <div
            className="admin-summary-content-block-info-header text uppercase"
            onClick={this.navigateTo.bind(this, PROJECT_DETAILS)}
          >
            {Localization.translator.projectDetails}
          </div>
          <p className="admin-summary-content-block-info-paragraph">
            {Localization.translator.adminSummaryDescriptions.projectDetails}
          </p>
          <p className="admin-summary-content-block-info-paragraph-header">
            {Localization.translator.lastUpdated}:
          </p>
          {projectUpdate ? (
            <p className="admin-summary-content-block-info-paragraph">
              {this.getUpdatedBy(projectUpdate)}
            </p>
          ) : (
            ' - '
          )}
        </div>
      </div>
    )
  }

  public renderScheduleBlock() {
    const scheduleUpdate =
      this.props.projectSetUpPageStore.getLastPageUpdate('schedule')

    const { activeSchedule } = this.props.projectSetUpPageStore.state
    const { SCHEDULE_UPLOAD } = ProjectSetUpPage
    return (
      <div className="admin-summary-content-block">
        <div
          className="admin-summary-content-block-icon"
          onClick={this.navigateTo.bind(this, SCHEDULE_UPLOAD)}
        >
          <span className="schedule-icon" />
        </div>

        <div className="admin-summary-content-block-info">
          <div
            className="admin-summary-content-block-info-header text uppercase"
            onClick={this.navigateTo.bind(this, SCHEDULE_UPLOAD)}
          >
            {Localization.translator.uploadProjectSchedule}
          </div>
          <p className="admin-summary-content-block-info-paragraph">
            {Localization.translator.adminSummaryDescriptions.projectSchedule}
          </p>
          <p className="admin-summary-content-block-info-paragraph-header">
            {Localization.translator.lastUpdated}:
          </p>
          {scheduleUpdate ? (
            <p className="admin-summary-content-block-info-paragraph">
              {this.getUpdatedBy(scheduleUpdate)}&#10;&#13;
              {Localization.translator.fileUploaded}
              {scheduleUpdate.fileName} (
              {Localization.translator.totalActivities + ': '}
              {activeSchedule?.activityQuantity})
            </p>
          ) : (
            ' - '
          )}
        </div>
      </div>
    )
  }

  public renderTeamListBlock() {
    const { projectSetUpPageStore } = this.props
    const { projectMembersQuantity } = projectSetUpPageStore
    const teamUpdate = projectSetUpPageStore.getLastPageUpdate('teamList')
    const { USER_DIRECTORY } = ProjectSetUpPage
    return (
      <div className="admin-summary-content-block">
        <div
          className="admin-summary-content-block-icon"
          onClick={this.navigateTo.bind(this, USER_DIRECTORY)}
        >
          <span className="team-list-icon" />
        </div>

        <div className="admin-summary-content-block-info">
          <div
            className="admin-summary-content-block-info-header text uppercase"
            onClick={this.navigateTo.bind(this, USER_DIRECTORY)}
          >
            {Localization.translator.uploadTeamList}
          </div>
          <p className="admin-summary-content-block-info-paragraph">
            {Localization.translator.adminSummaryDescriptions.teamList}
          </p>
          <p className="admin-summary-content-block-info-paragraph-header">
            {Localization.translator.lastUpdated}:
          </p>
          {teamUpdate ? (
            <p className="admin-summary-content-block-info-paragraph">
              {this.getUpdatedBy(teamUpdate)}&#10;&#13;
              {Localization.translator.currentTeamMembers(
                projectMembersQuantity,
              )}
              &#10;&#13;
              {...Localization.translator
                .downloadCurrentValuesAsCsv(text => <a>{text}</a>)
                .map((element, index) => <span key={index}>{element}</span>)}
            </p>
          ) : (
            ' - '
          )}
        </div>
      </div>
    )
  }

  private getUpdatedBy(update: IHistoryItem) {
    const date = this.format(update.updatedAt)

    if (update.by) {
      const formattedUser = getUserName(update.by)
      return Localization.translator.dateByName(date, formattedUser)
    }

    return date
  }

  @action.bound
  private navigateTo(page) {
    const { projectSetUpPageStore } = this.props
    projectSetUpPageStore.navigateTo(page)
  }

  private format(timestamp: number) {
    return this.props.projectDateStore.getMonthDayYearAndTimeToDisplay(
      timestamp,
    )
  }
}
