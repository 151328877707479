import * as React from 'react'

import { Button, Classes, Dialog } from '@blueprintjs/core'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import ProjectScheduleUploadStore from '../../ProjectScheduleUpload.store'
import XerProjectRow from './XerProjectRow'

import './ChooseProjectDialog.scss'

export interface IProps {
  projectScheduleUploadStore: ProjectScheduleUploadStore
}

@observer
export default class ChooseProjectDialog extends React.Component<IProps> {
  public render() {
    const store = this.props.projectScheduleUploadStore
    const {
      isChooseProjectDialogShown,
      resetInitialValue,
      uploadedXerProjects,
      chosenXerProjectId,
      applyChooseProjectDialog,
    } = store
    return (
      <Dialog
        isOpen={isChooseProjectDialogShown}
        title={Localization.translator.chooseProject}
        isCloseButtonShown={false}
        canOutsideClickClose={false}
        className="choose-project-dialog"
      >
        <div className={Classes.DIALOG_BODY}>
          <div className="text large light mb20">
            {Localization.translator.uploadScheduleDescriptions.chooseProject}
          </div>
          {uploadedXerProjects.map(project => (
            <XerProjectRow
              key={project.id}
              xerProject={project}
              projectScheduleUploadStore={store}
            />
          ))}
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <Button
            text={Localization.translator.cancelUpload}
            className="choose-project-dialog-btn white-btn"
            onClick={resetInitialValue}
          />
          <Button
            text={Localization.translator.done}
            className={classList({
              'choose-project-dialog-btn blue-btn': true,
              disabled: !chosenXerProjectId,
            })}
            onClick={applyChooseProjectDialog}
          />
        </div>
      </Dialog>
    )
  }
}
