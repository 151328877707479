import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import DeliveryActionTypes, {
  getDeliveryActionTypeDisplayName,
} from '~/client/src/desktop/enums/DeliveryActionTypes'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import * as Icons from '~/client/src/shared/components/Icons'

import NewDeliveryActionButton from '../NewDeliveryActionButton/NewDeliveryActionButton'

// localization: translated

const { STAR_EMPTY, STAR } = IconNames

interface IProps {
  shouldShowActionButtons: boolean
  statusTitle: string
  onNewDeliveryCreate: () => void
  setActionType: (actionType: DeliveryActionTypes) => void
  activeActionType: DeliveryActionTypes
  isAdminMode: boolean
  isCdDeliverySelected: boolean
}

type IAction = {
  actionType: DeliveryActionTypes
  icon: any
  isEnabled: (isAdminMode?: boolean, isCdDeliverySelected?: boolean) => boolean
}

const YES = () => true

const secondaryActions: IAction[] = [
  {
    actionType: DeliveryActionTypes.SUBSCRIBE,
    icon: <Icon icon={STAR_EMPTY} />,
    isEnabled: YES,
  },
  {
    actionType: DeliveryActionTypes.ASSIGN,
    icon: <Icon icon={STAR} />,
    isEnabled: isAdminMode => isAdminMode,
  },
  {
    actionType: DeliveryActionTypes.UPDATE,
    icon: <Icons.DeliveryUpdate />,
    isEnabled: (_, isCdDeliverySelected) => !isCdDeliverySelected,
  },
  {
    actionType: DeliveryActionTypes.EDIT,
    icon: <Icons.Edit />,
    isEnabled: (isAdminMode, isCdDeliverySelected) =>
      !isCdDeliverySelected && isAdminMode,
  },
]

export default class DeliveriesListActionBar extends React.PureComponent<IProps> {
  public render() {
    const { statusTitle, onNewDeliveryCreate, shouldShowActionButtons } =
      this.props

    return (
      <div className="row pb10 px24 bb-light-grey">
        <div className="row text large light">
          <div className="pr10 no-grow nowrap">{statusTitle}</div>
          {shouldShowActionButtons && this.renderActionButtons()}
        </div>
        <div className="row x-end no-grow">
          <NewDeliveryActionButton
            isActive={this.isActive(DeliveryActionTypes.NEW)}
            onClick={onNewDeliveryCreate}
          />
        </div>
      </div>
    )
  }

  private renderActionButtons() {
    const { isAdminMode, setActionType, isCdDeliverySelected } = this.props

    return (
      <div className="row bl-light-cool-grey actions-container pl6 pr10">
        {secondaryActions.map(
          ({ actionType, icon, isEnabled }: IAction) =>
            isEnabled(isAdminMode, isCdDeliverySelected) && (
              <BaseActionButton
                icon={icon}
                className="mx4 gray-theme"
                key={actionType}
                isEnabled={true}
                shouldShowBorder={false}
                title={getDeliveryActionTypeDisplayName(actionType)}
                isActive={this.isActive(actionType)}
                onClick={setActionType.bind(null, actionType)}
              />
            ),
        )}
      </div>
    )
  }

  private isActive(actionType: DeliveryActionTypes) {
    return actionType === this.props.activeActionType
  }
}
