import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ISitemapFieldsFragment = Pick<
  Types.ISitemap,
  | 'id'
  | 'name'
  | 'projectId'
  | 'basemapId'
  | 'bearing'
  | 'width'
  | 'height'
  | 'pitch'
  | 'zoom'
  | 'filledImage'
  | 'isLabelsShown'
  | 'itemsFilledImage'
  | 'isProjectOverviewMap'
  | 'type'
  | 'createdAt'
  | 'updatedAt'
> & {
  center?: Types.Maybe<Pick<Types.ILatLng, 'lat' | 'lng'>>
  bounds?: Types.Maybe<{
    ne?: Types.Maybe<Pick<Types.ILatLng, 'lat' | 'lng'>>
    sw?: Types.Maybe<Pick<Types.ILatLng, 'lat' | 'lng'>>
  }>
  geoCorners?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.IGeoJson2DGeographicCoordinates, 'latitude' | 'longitude'>
      >
    >
  >
}

export type ISitemapItemsFieldsFragment = Pick<
  Types.ISitemapSpecificItemData,
  'id' | 'sitemapItemId' | 'sitemapId' | 'projectId' | 'isHidden'
> & {
  icon?: Types.Maybe<
    Pick<Types.ISitemapPin, 'isHidden'> & {
      position?: Types.Maybe<Pick<Types.IPosition, 'x' | 'y'>>
    }
  >
  label?: Types.Maybe<
    Pick<
      Types.ISitemapTextBox,
      'fontSize' | 'isHidden' | 'isTextBoxDisplayed' | 'color'
    > & { position?: Types.Maybe<Pick<Types.IPosition, 'x' | 'y'>> }
  >
  shape?: Types.Maybe<
    | (Pick<
        Types.ISitemapCircle,
        | 'divisionEndAngle'
        | 'divisionStartAngle'
        | 'isDivided'
        | 'radius'
        | 'fillColor'
        | 'fillOpacity'
        | 'lineColor'
        | 'lineWidth'
        | 'type'
        | 'isDisplayed'
      > & { position?: Types.Maybe<Pick<Types.IPosition, 'x' | 'y'>> })
    | (Pick<
        Types.ISitemapPolyline,
        | 'arrowPosition'
        | 'isClosed'
        | 'fillColor'
        | 'fillOpacity'
        | 'lineColor'
        | 'lineWidth'
        | 'type'
        | 'isDisplayed'
      > & {
        points?: Types.Maybe<
          Array<Types.Maybe<Pick<Types.IPosition, 'x' | 'y'>>>
        >
      })
    | (Pick<
        Types.ISitemapRectangle,
        | 'height'
        | 'rotation'
        | 'width'
        | 'fillColor'
        | 'fillOpacity'
        | 'lineColor'
        | 'lineWidth'
        | 'type'
        | 'isDisplayed'
      > & { position?: Types.Maybe<Pick<Types.IPosition, 'x' | 'y'>> })
  >
}

export type ISitemapsByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type ISitemapsByProjectIdQuery = {
  sitemaps?: Types.Maybe<{
    data: Array<
      {
        items?: Types.Maybe<Array<Types.Maybe<ISitemapItemsFieldsFragment>>>
      } & ISitemapFieldsFragment
    >
  }>
}

export type IListenSitemapsByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenSitemapsByProjectIdSubscription = {
  sitemap?: Types.Maybe<
    Pick<Types.ISitemapChangeEvent, 'id'> & {
      item?: Types.Maybe<ISitemapFieldsFragment>
    }
  >
}

export type IListenSitemapItemDataByProjectIdSubscriptionVariables =
  Types.Exact<{
    projectId: Types.Scalars['ObjectId']
  }>

export type IListenSitemapItemDataByProjectIdSubscription = {
  sitemapSpecificItemData?: Types.Maybe<
    Pick<Types.ISitemapSpecificItemDataChangeEvent, 'id'> & {
      item?: Types.Maybe<ISitemapItemsFieldsFragment>
    }
  >
}

export type ISaveSitemapMutationVariables = Types.Exact<{
  sitemap: Types.ISitemapInput
}>

export type ISaveSitemapMutation = {
  saveSitemap?: Types.Maybe<Pick<Types.ISitemap, 'id'>>
}

export type ISaveSitemapSpecificItemDataMutationVariables = Types.Exact<{
  polylines?: Types.Maybe<
    | Array<Types.Maybe<Types.ISitemapSpecificItemDataPolylineInput>>
    | Types.Maybe<Types.ISitemapSpecificItemDataPolylineInput>
  >
  rectangles?: Types.Maybe<
    | Array<Types.Maybe<Types.ISitemapSpecificItemDataRectangleInput>>
    | Types.Maybe<Types.ISitemapSpecificItemDataRectangleInput>
  >
  circles?: Types.Maybe<
    | Array<Types.Maybe<Types.ISitemapSpecificItemDataCircleInput>>
    | Types.Maybe<Types.ISitemapSpecificItemDataCircleInput>
  >
}>

export type ISaveSitemapSpecificItemDataMutation = {
  saveSitemapSpecificItemData?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.ISitemapSpecificItemData, 'id'>>>
  >
}

export type IDeleteSitemapSpecificItemDataMutationVariables = Types.Exact<{
  sitemapItemId: Types.Scalars['ObjectId']
}>

export type IDeleteSitemapSpecificItemDataMutation = Pick<
  Types.IMutation,
  'deleteSitemapSpecificItemData'
>

export type IDeleteSitemapMutationVariables = Types.Exact<{
  id: Types.Scalars['ObjectId']
}>

export type IDeleteSitemapMutation = Pick<Types.IMutation, 'deleteSitemap'>

export const SitemapFieldsFragmentDoc = gql`
  fragment SitemapFields on Sitemap {
    id
    name
    projectId
    basemapId
    center {
      lat
      lng
    }
    bearing
    bounds {
      ne {
        lat
        lng
      }
      sw {
        lat
        lng
      }
    }
    width
    height
    pitch
    zoom
    filledImage
    isLabelsShown
    geoCorners {
      latitude
      longitude
    }
    itemsFilledImage
    isProjectOverviewMap
    type
    createdAt
    updatedAt
  }
`
export const SitemapItemsFieldsFragmentDoc = gql`
  fragment SitemapItemsFields on SitemapSpecificItemData {
    id
    sitemapItemId
    sitemapId
    projectId
    isHidden
    icon {
      isHidden
      position {
        x
        y
      }
    }
    label {
      fontSize
      isHidden
      isTextBoxDisplayed
      position {
        x
        y
      }
      color
    }
    shape {
      fillColor
      fillOpacity
      lineColor
      lineWidth
      type
      isDisplayed
      ... on SitemapPolyline {
        arrowPosition
        isClosed
        points {
          x
          y
        }
      }
      ... on SitemapRectangle {
        height
        position {
          x
          y
        }
        rotation
        width
      }
      ... on SitemapCircle {
        divisionEndAngle
        divisionStartAngle
        isDivided
        position {
          x
          y
        }
        radius
      }
    }
  }
`
export const SitemapsByProjectIdDocument = gql`
  query SitemapsByProjectId($projectId: ObjectId!) {
    sitemaps(projectId: $projectId, limit: 1000000) {
      data {
        ...SitemapFields
        items {
          ...SitemapItemsFields
        }
      }
    }
  }
  ${SitemapFieldsFragmentDoc}
  ${SitemapItemsFieldsFragmentDoc}
`

/**
 * __useSitemapsByProjectIdQuery__
 *
 * To run a query within a React component, call `useSitemapsByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitemapsByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitemapsByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSitemapsByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ISitemapsByProjectIdQuery,
    ISitemapsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ISitemapsByProjectIdQuery,
    ISitemapsByProjectIdQueryVariables
  >(SitemapsByProjectIdDocument, options)
}
export function useSitemapsByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ISitemapsByProjectIdQuery,
    ISitemapsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ISitemapsByProjectIdQuery,
    ISitemapsByProjectIdQueryVariables
  >(SitemapsByProjectIdDocument, options)
}
export type SitemapsByProjectIdQueryHookResult = ReturnType<
  typeof useSitemapsByProjectIdQuery
>
export type SitemapsByProjectIdLazyQueryHookResult = ReturnType<
  typeof useSitemapsByProjectIdLazyQuery
>
export type SitemapsByProjectIdQueryResult = Apollo.QueryResult<
  ISitemapsByProjectIdQuery,
  ISitemapsByProjectIdQueryVariables
>
export const ListenSitemapsByProjectIdDocument = gql`
  subscription ListenSitemapsByProjectId($projectId: ObjectId!) {
    sitemap(projectId: $projectId) {
      id
      item {
        ...SitemapFields
      }
    }
  }
  ${SitemapFieldsFragmentDoc}
`

/**
 * __useListenSitemapsByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenSitemapsByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenSitemapsByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenSitemapsByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenSitemapsByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenSitemapsByProjectIdSubscription,
    IListenSitemapsByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenSitemapsByProjectIdSubscription,
    IListenSitemapsByProjectIdSubscriptionVariables
  >(ListenSitemapsByProjectIdDocument, options)
}
export type ListenSitemapsByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenSitemapsByProjectIdSubscription
>
export type ListenSitemapsByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenSitemapsByProjectIdSubscription>
export const ListenSitemapItemDataByProjectIdDocument = gql`
  subscription ListenSitemapItemDataByProjectId($projectId: ObjectId!) {
    sitemapSpecificItemData(projectId: $projectId) {
      id
      item {
        ...SitemapItemsFields
      }
    }
  }
  ${SitemapItemsFieldsFragmentDoc}
`

/**
 * __useListenSitemapItemDataByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenSitemapItemDataByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenSitemapItemDataByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenSitemapItemDataByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenSitemapItemDataByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenSitemapItemDataByProjectIdSubscription,
    IListenSitemapItemDataByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenSitemapItemDataByProjectIdSubscription,
    IListenSitemapItemDataByProjectIdSubscriptionVariables
  >(ListenSitemapItemDataByProjectIdDocument, options)
}
export type ListenSitemapItemDataByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenSitemapItemDataByProjectIdSubscription
>
export type ListenSitemapItemDataByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenSitemapItemDataByProjectIdSubscription>
export const SaveSitemapDocument = gql`
  mutation SaveSitemap($sitemap: SitemapInput!) {
    saveSitemap(sitemap: $sitemap) {
      id
    }
  }
`
export type ISaveSitemapMutationFn = Apollo.MutationFunction<
  ISaveSitemapMutation,
  ISaveSitemapMutationVariables
>

/**
 * __useSaveSitemapMutation__
 *
 * To run a mutation, you first call `useSaveSitemapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSitemapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSitemapMutation, { data, loading, error }] = useSaveSitemapMutation({
 *   variables: {
 *      sitemap: // value for 'sitemap'
 *   },
 * });
 */
export function useSaveSitemapMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveSitemapMutation,
    ISaveSitemapMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveSitemapMutation,
    ISaveSitemapMutationVariables
  >(SaveSitemapDocument, options)
}
export type SaveSitemapMutationHookResult = ReturnType<
  typeof useSaveSitemapMutation
>
export type SaveSitemapMutationResult =
  Apollo.MutationResult<ISaveSitemapMutation>
export type SaveSitemapMutationOptions = Apollo.BaseMutationOptions<
  ISaveSitemapMutation,
  ISaveSitemapMutationVariables
>
export const SaveSitemapSpecificItemDataDocument = gql`
  mutation SaveSitemapSpecificItemData(
    $polylines: [SitemapSpecificItemDataPolylineInput]
    $rectangles: [SitemapSpecificItemDataRectangleInput]
    $circles: [SitemapSpecificItemDataCircleInput]
  ) {
    saveSitemapSpecificItemData(
      polylines: $polylines
      rectangles: $rectangles
      circles: $circles
    ) {
      id
    }
  }
`
export type ISaveSitemapSpecificItemDataMutationFn = Apollo.MutationFunction<
  ISaveSitemapSpecificItemDataMutation,
  ISaveSitemapSpecificItemDataMutationVariables
>

/**
 * __useSaveSitemapSpecificItemDataMutation__
 *
 * To run a mutation, you first call `useSaveSitemapSpecificItemDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSitemapSpecificItemDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSitemapSpecificItemDataMutation, { data, loading, error }] = useSaveSitemapSpecificItemDataMutation({
 *   variables: {
 *      polylines: // value for 'polylines'
 *      rectangles: // value for 'rectangles'
 *      circles: // value for 'circles'
 *   },
 * });
 */
export function useSaveSitemapSpecificItemDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveSitemapSpecificItemDataMutation,
    ISaveSitemapSpecificItemDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveSitemapSpecificItemDataMutation,
    ISaveSitemapSpecificItemDataMutationVariables
  >(SaveSitemapSpecificItemDataDocument, options)
}
export type SaveSitemapSpecificItemDataMutationHookResult = ReturnType<
  typeof useSaveSitemapSpecificItemDataMutation
>
export type SaveSitemapSpecificItemDataMutationResult =
  Apollo.MutationResult<ISaveSitemapSpecificItemDataMutation>
export type SaveSitemapSpecificItemDataMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveSitemapSpecificItemDataMutation,
    ISaveSitemapSpecificItemDataMutationVariables
  >
export const DeleteSitemapSpecificItemDataDocument = gql`
  mutation DeleteSitemapSpecificItemData($sitemapItemId: ObjectId!) {
    deleteSitemapSpecificItemData(id: $sitemapItemId)
  }
`
export type IDeleteSitemapSpecificItemDataMutationFn = Apollo.MutationFunction<
  IDeleteSitemapSpecificItemDataMutation,
  IDeleteSitemapSpecificItemDataMutationVariables
>

/**
 * __useDeleteSitemapSpecificItemDataMutation__
 *
 * To run a mutation, you first call `useDeleteSitemapSpecificItemDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSitemapSpecificItemDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSitemapSpecificItemDataMutation, { data, loading, error }] = useDeleteSitemapSpecificItemDataMutation({
 *   variables: {
 *      sitemapItemId: // value for 'sitemapItemId'
 *   },
 * });
 */
export function useDeleteSitemapSpecificItemDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteSitemapSpecificItemDataMutation,
    IDeleteSitemapSpecificItemDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteSitemapSpecificItemDataMutation,
    IDeleteSitemapSpecificItemDataMutationVariables
  >(DeleteSitemapSpecificItemDataDocument, options)
}
export type DeleteSitemapSpecificItemDataMutationHookResult = ReturnType<
  typeof useDeleteSitemapSpecificItemDataMutation
>
export type DeleteSitemapSpecificItemDataMutationResult =
  Apollo.MutationResult<IDeleteSitemapSpecificItemDataMutation>
export type DeleteSitemapSpecificItemDataMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteSitemapSpecificItemDataMutation,
    IDeleteSitemapSpecificItemDataMutationVariables
  >
export const DeleteSitemapDocument = gql`
  mutation DeleteSitemap($id: ObjectId!) {
    deleteSitemap(id: $id)
  }
`
export type IDeleteSitemapMutationFn = Apollo.MutationFunction<
  IDeleteSitemapMutation,
  IDeleteSitemapMutationVariables
>

/**
 * __useDeleteSitemapMutation__
 *
 * To run a mutation, you first call `useDeleteSitemapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSitemapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSitemapMutation, { data, loading, error }] = useDeleteSitemapMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSitemapMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteSitemapMutation,
    IDeleteSitemapMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteSitemapMutation,
    IDeleteSitemapMutationVariables
  >(DeleteSitemapDocument, options)
}
export type DeleteSitemapMutationHookResult = ReturnType<
  typeof useDeleteSitemapMutation
>
export type DeleteSitemapMutationResult =
  Apollo.MutationResult<IDeleteSitemapMutation>
export type DeleteSitemapMutationOptions = Apollo.BaseMutationOptions<
  IDeleteSitemapMutation,
  IDeleteSitemapMutationVariables
>
