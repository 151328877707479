import * as React from 'react'

import { action, computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { RouteComponentProps, withRouter } from 'react-router'

import { ContentObjectStatus } from '~/client/graph'
import CommonThread from '~/client/src/shared/components/CommonThread/CommonThread'
import CloseContentObjectButton from '~/client/src/shared/components/ContentObject/CloseContentObjectButton'
import ReplyToContentObjectButton from '~/client/src/shared/components/ContentObject/ReplyToContentObjectButton'
import RfiIcon from '~/client/src/shared/components/RfiIcon/RfiIcon'
import Message from '~/client/src/shared/models/Message'
import Rfi from '~/client/src/shared/models/Rfi'
import InitialState from '~/client/src/shared/stores/InitialState'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'

import CommonStore from '../../stores/ui/Common.store'
import FileInputBase from '../FileInput/FileInput'

interface IRfiThread {
  rfi: Rfi
  hideButtons?: boolean
  messagesStore?: MessagesStore
  state?: InitialState
  common?: CommonStore
  FileInputType: typeof FileInputBase
}

@inject('messagesStore', 'state', 'common')
@observer
class RfiThread extends React.Component<IRfiThread & RouteComponentProps<any>> {
  @computed
  private get messages(): Message[] {
    const { rfi } = this.props
    return this.props.messagesStore.list.filter(
      m => m.threadId === rfi.threadId,
    )
  }

  private renderButtons(rfi: Rfi) {
    const { userActiveProjectSettings } = this.props.state
    return (
      <div
        className={classList({
          'row item-buttons-container': true,
          'inactive-element': userActiveProjectSettings?.isPresentationUser,
        })}
      >
        <ReplyToContentObjectButton
          contentObject={rfi}
          onClick={this.displayRfiView}
        />
        <CloseContentObjectButton contentObject={rfi} />
      </div>
    )
  }

  public render() {
    const { rfi, hideButtons, FileInputType } = this.props
    const isRfiClosed = rfi.status === ContentObjectStatus.Closed
    const buttonsElement = hideButtons ? null : this.renderButtons(rfi)
    return (
      <CommonThread
        showImages={true}
        hideThread={false}
        showFooterLine={true}
        messages={this.messages}
        buttonsElement={buttonsElement}
        threadIcon={<RfiIcon rfi={rfi} />}
        threadClass={isRfiClosed ? '' : ' rfi-thread'}
        caption={Rfi.getRfiTypeCaption(rfi.type)}
        model={rfi}
        customPhotoClick={this.displayRfiView}
        FileInputType={FileInputType}
      />
    )
  }

  @action.bound
  private displayRfiView = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const { common, rfi } = this.props
    common.displayRfiView(rfi)
  }
}

export default withRouter(RfiThread)
