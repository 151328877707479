import * as React from 'react'

import { observer } from 'mobx-react'

import FilterFooter from '~/client/src/shared/components/Filters/FilterUtils/FilterFooter/FilterFooter'
import * as Icons from '~/client/src/shared/components/Icons'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'

import '../BulkActionBar.scss'

interface INewSetModalProps {
  title?: string
  newSetName: string
  selectedActivitiesCount: number
  clickOnApply: () => void
  clickOnCancel: () => void
  onNameChange: (event) => void
  onNameReset: () => void
}

const selectedActivities = (selectedActivitiesCount: number): string =>
  `${selectedActivitiesCount} activities`
const saveNewActivitySet = 'Save a new Activity Set'
const activitySetName = 'Activity Set name'

@observer
export default class NewSetModal extends React.Component<INewSetModalProps> {
  public render() {
    const {
      title,
      newSetName,
      selectedActivitiesCount,
      clickOnApply,
      clickOnCancel,
      onNameChange,
      onNameReset,
    } = this.props

    return (
      <div className="set-name-container ba-grey bg-white">
        <div className="row x-between  ml30 mr15 mt5">
          <div className="text bold extra-large">
            {title || saveNewActivitySet}
          </div>
          <Icons.Cross onClick={clickOnCancel} className="no-grow" />
        </div>
        <div className="row content bb-dark-grey-border">
          <StruxhubInput
            id="save-preset-name-input"
            label={activitySetName}
            isRequired={true}
            isRequiredTextHidden={true}
            value={newSetName}
            onChange={onNameChange}
            onValueReset={onNameReset}
          />
          <span className="row x-end text large light mr20">
            {selectedActivities(selectedActivitiesCount)}
          </span>
        </div>
        <div>
          <FilterFooter
            clickOnApply={clickOnApply}
            clickOnCancel={clickOnCancel}
            applyButtonText={'Save'}
            isApplyButtonInactive={!newSetName}
          />
        </div>
      </div>
    )
  }
}
