import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

import { IGroupByOption } from '../../../CompactGroupByPicker/CompactGroupByFilter'
import MenuCloser from '../../../MenuCloser'

interface IProps {
  selectedMode: any
  onHide: () => void
  onGroupByChange: (bandType: any) => void
  groupByOptions: IGroupByOption[]
}

const groupBy = 'Group by'

@observer
export default class GroupByModal extends React.Component<IProps> {
  public render() {
    const { onHide } = this.props

    return (
      <MenuCloser
        closeMenu={onHide}
        className="absolute users-group-by beautiful-shadow brada4 bg-white"
      >
        <div className="row text grey-light uppercase pa10">{groupBy}</div>
        {this.renderContent()}
      </MenuCloser>
    )
  }

  private renderContent(): JSX.Element {
    const { groupByOptions, selectedMode, onGroupByChange } = this.props

    return (
      <div className="col mw160">
        {groupByOptions.map(({ title, groupByType, icon }, index) => {
          const isOptionSelected = groupByType === selectedMode

          return (
            <div
              key={index}
              className={classList({
                'row y-center pa10': true,
                'highlighted-hover': !isOptionSelected,
              })}
              onClick={onGroupByChange.bind(this, groupByType)}
            >
              <div className="text large row">
                {icon}
                {title}
              </div>
              {isOptionSelected && (
                <Icons.CheckPaletteBlue className="no-grow" />
              )}
            </div>
          )
        })}
      </div>
    )
  }
}
