import { PermitFieldType } from '~/client/graph'

export const workflowFieldTypes = [
  PermitFieldType.Company,
  PermitFieldType.Requester,
  PermitFieldType.Assignee,
  PermitFieldType.Subscriber,
  PermitFieldType.Location,
  PermitFieldType.Equipment,
  PermitFieldType.RequestToCloseLocations,
  PermitFieldType.StartFinishDates,
  PermitFieldType.Material,
]

export const singleInstancesPermitFields = [
  ...workflowFieldTypes,
  PermitFieldType.File,
]

export const multiplePermitFieldTypes = [
  PermitFieldType.InputText,
  PermitFieldType.InputTextArea,
  PermitFieldType.InputNumber,
  PermitFieldType.AdditionalDate,
  PermitFieldType.Company,
  PermitFieldType.Requester,
  PermitFieldType.Assignee,
  PermitFieldType.Subscriber,
  PermitFieldType.User,
  PermitFieldType.Location,
  PermitFieldType.Equipment,
  PermitFieldType.Select,
  PermitFieldType.Email,
  PermitFieldType.PhoneNumber,
  PermitFieldType.Material,
  PermitFieldType.LocationInformational,
]

export const nestablePermitFields = [
  PermitFieldType.Section,
  PermitFieldType.Subsection,
]

export const conditionalPermitFields = [
  PermitFieldType.Select,
  PermitFieldType.Question,
  PermitFieldType.Checklist,
]

export const nonHiddeablePermitFieldTypes = [
  PermitFieldType.Section,
  PermitFieldType.Subsection,
  PermitFieldType.Text,
  PermitFieldType.Divider,
]

export const selectOptionsPermitFieldTypes = [
  PermitFieldType.Select,
  PermitFieldType.Measure,
]

export const locationPermitFieldTypes = [
  PermitFieldType.Location,
  PermitFieldType.Equipment,
  PermitFieldType.LocationInformational,
]

export const nonConditionalPermitFieldTypes = [
  ...workflowFieldTypes,
  PermitFieldType.Instructions,
  PermitFieldType.Divider,
  PermitFieldType.File,
  ...nestablePermitFields,
]

export const restrictedTablePermitFieldTypes = [
  ...nonConditionalPermitFieldTypes,
  PermitFieldType.Checklist,
  PermitFieldType.Question,
  PermitFieldType.Table,
  PermitFieldType.Text,
]

export const nonRemovableTransferFieldTypes = [
  PermitFieldType.Location,
  PermitFieldType.StartFinishDates,
  PermitFieldType.Material,
]

export const companyRestrictionFieldTypes = [
  PermitFieldType.Location,
  PermitFieldType.Equipment,
]

export const defaultNotificationPermitFieldTypes = [
  PermitFieldType.Requester,
  PermitFieldType.Assignee,
  PermitFieldType.Subscriber,
]
