import { action, computed, observable } from 'mobx'

import { ICompany, ProjectAccessType } from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import { ProjectResponsibilityTypes } from '~/client/src/shared/constants/ProjectRoles'
import AccountPosition from '~/client/src/shared/enums/AccountPosition'
import { FormFieldType } from '~/client/src/shared/enums/FormFieldType'
import InfoSectionId from '~/client/src/shared/enums/InfoSectionId'
import { TagIconType } from '~/client/src/shared/enums/TagIcon'
import { TagType } from '~/client/src/shared/enums/TagType'
import UserFieldId from '~/client/src/shared/enums/UserFieldId'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import Company from '~/client/src/shared/models/Company'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import { SAVE_COMPANIES } from '~/client/src/shared/stores/EventStore/eventConstants'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import { IAddEditDialogField } from '~/client/src/shared/stores/ui/AddEditItemDialog.store'
import BaseAddEditMemberDialogStore from '~/client/src/shared/stores/ui/BaseAddEditMemberDialog.store'
import FormStore from '~/client/src/shared/stores/ui/Form.store'
import { IServiceUserDto } from '~/client/src/shared/types/UserDto'
import { ToastTheme, showToast } from '~/client/src/shared/utils/toaster'

import { Permission, Workflow } from './PermissionsGrid/PermissionsGrid'

const workflowToResponsibilityTypeMap = {
  [Workflow.DeliveryBooking]: ProjectResponsibilityTypes.DocMaster,
  [Workflow.DeliveryInspection]: ProjectResponsibilityTypes.Inspector,
  [Workflow.Forms]: ProjectResponsibilityTypes.FormsMaster,
  [Workflow.ScannerApp]: ProjectResponsibilityTypes.ScanMaster,
  [Workflow.ActivityViewing]: ProjectResponsibilityTypes.ActivityView,
  [Workflow.ActivityUpdating]: ProjectResponsibilityTypes.ActivityUpdate,
  [Workflow.ActivityUploading]: ProjectResponsibilityTypes.ActivityUpload,
  [Workflow.ActivityNotes]: ProjectResponsibilityTypes.ActivityNote,
}

export interface IFormUserFields {
  id: string
  firstName: string
  lastName: string
  [UserFieldId.GlobalRole]: string
  [UserFieldId.EmployeeId]: string
  [UserFieldId.AccountPosition]: AccountPosition
  [UserFieldId.Initials]: string
  companyId: string
  teams: string[]
  roles: string[]
  [UserFieldId.Trades]: string[]
  [UserFieldId.AccountType]: string
  [UserFieldId.AvatarUrl]: string
  [Workflow.DeliveryBooking]: boolean
  [Workflow.DeliveryInspection]: boolean
  [Workflow.Forms]: boolean
  [Workflow.ScannerApp]: boolean
  [Workflow.ActivityViewing]: boolean
  [Workflow.ActivityUpdating]: boolean
  [Workflow.ActivityUploading]: boolean
  [Workflow.ActivityNotes]: boolean
  email: string
  phoneNumber: string
}

const requiredFieldIds = [
  UserFieldId.FirstName,
  UserFieldId.LastName,
  UserFieldId.Company,
  UserFieldId.AccountType,
  UserFieldId.AccountPosition,
]

const restrictedAccountTypes = [
  ProjectAccessType.Owner, // Owner could be set only in Struxhub admin dashboard
]

export default class AddEditMemberDialogStore extends BaseAddEditMemberDialogStore<IFormUserFields> {
  @observable public shouldShowCompanyModal: boolean = false
  @observable public shouldShowConfirmInviteDialog: boolean = false

  public constructor(
    itemsToEdit: User[],
    items: User[],
    resetErrorMessage: () => void,
    private readonly companiesStore: CompaniesStore,
    userProjectsStore: UserProjectsStore,
    public updateItems: (
      items: IServiceUserDto[],
      lastUpdatedItemId?: string,
    ) => void,

    protected readonly closeDialog: () => void,
    private readonly state: DesktopInitialState,
  ) {
    super(
      userProjectsStore,
      itemsToEdit,
      items,
      resetErrorMessage,
      KnownTranslatorKeys.addUser,
    )

    this.idPropName = 'id'
  }

  @computed
  public get fields(): IAddEditDialogField[] {
    const fields: IAddEditDialogField[] = [
      {
        id: UserFieldId.FirstName,
        label: Localization.translator.firstName,
        value: this.getFieldValueById(UserFieldId.FirstName),
        sectionId: InfoSectionId.Personal,
        type: FormFieldType.Text,
        onChange: this.handleChange,
        onValueReset: this.handleValueReset.bind(this, UserFieldId.FirstName),
        inline: 2,
      },
      {
        id: UserFieldId.LastName,
        label: Localization.translator.lastName,
        value: this.getFieldValueById(UserFieldId.LastName),
        sectionId: InfoSectionId.Personal,
        type: FormFieldType.Text,
        onChange: this.handleChange,
        onValueReset: this.handleValueReset.bind(this, UserFieldId.LastName),
        inline: 2,
      },
      {
        id: UserFieldId.Initials,
        label: Localization.translator.initials,
        value: this.getFieldValueById(UserFieldId.Initials),
        sectionId: InfoSectionId.Personal,
        type: FormFieldType.Text,
        onChange: this.handleChange,
        onValueReset: this.handleValueReset.bind(this, UserFieldId.Initials),
        maxlength: 2,
        inline: 2,
      },
      {
        id: UserFieldId.AvatarUrl,
        label: Localization.translator.avatar,
        value: this.avatarUrlFieldValue,
        sectionId: InfoSectionId.Personal,
        type: FormFieldType.Avatar,
        onChange: this.handleAvatarChange,
        inline: 2,
      },
      {
        id: UserFieldId.Email,
        label: Localization.translator.email_noun,
        value: this.getFieldValueById(UserFieldId.Email),
        type: FormFieldType.Text,
        onChange: this.handleChange,
        onValueReset: this.handleValueReset.bind(this, UserFieldId.Email),
        sectionId: InfoSectionId.Personal,
      },
      {
        id: UserFieldId.PhoneNumber,
        label: Localization.translator.phoneNumber,
        value: this.getFieldValueById(UserFieldId.PhoneNumber),
        type: FormFieldType.Phone,
        onChange: this.handlePhoneNumberChange.bind(
          null,
          UserFieldId.PhoneNumber,
        ),
        sectionId: InfoSectionId.Personal,
      },
      {
        id: UserFieldId.GlobalRole,
        label: Localization.translator.globalRole,
        value: this.getFieldValueById(UserFieldId.GlobalRole),
        type: FormFieldType.TextAsTag,
        onChange: this.handleTagChange.bind(this, UserFieldId.GlobalRole),
        onValueReset: this.handleValueReset.bind(this, UserFieldId.GlobalRole),
        sectionId: InfoSectionId.Personal,
        serviceInfo: TagIconType.Role,
      },
      {
        id: UserFieldId.EmployeeId,
        label: Localization.translator.employeeIDNumber,
        value: this.getFieldValueById(UserFieldId.EmployeeId),
        type: FormFieldType.Text,
        onChange: this.handleChange,
        onValueReset: this.handleValueReset.bind(this, UserFieldId.EmployeeId),
        sectionId: InfoSectionId.Personal,
      },
      {
        id: UserFieldId.Company,
        label: Localization.translator.company,
        value: this.getFieldValueById(UserFieldId.Company),
        type: FormFieldType.Tag,
        serviceInfo: TagType.Company,
        onChange: this.handleTagChange.bind(null, UserFieldId.Company),
        sectionId: InfoSectionId.Company,
        isItemCreationAllowed: true,
      },
      {
        id: UserFieldId.Roles,
        label: Localization.translator.projectRoles,
        value: this.getFieldValueById(UserFieldId.Roles),
        type: FormFieldType.Tags,
        serviceInfo: TagType.Role,
        onChange: this.handleTagsChange.bind(null, UserFieldId.Roles),
        sectionId: InfoSectionId.Project,
        isItemCreationAllowed: true,
      },
      {
        id: UserFieldId.Teams,
        label: Localization.translator.teams,
        value: this.getFieldValueById(UserFieldId.Teams),
        type: FormFieldType.Tags,
        serviceInfo: TagType.Team,
        onChange: this.handleTagsChange.bind(null, UserFieldId.Teams),
        sectionId: InfoSectionId.Project,
        isItemCreationAllowed: true,
      },
      {
        id: UserFieldId.DefaultTeams,
        label: Localization.translator.defaultTeams,
        value: this.getFieldValueById(UserFieldId.DefaultTeams),
        type: FormFieldType.Tags,
        serviceInfo: TagType.DefaultTeam,
        onChange: this.handleTagsChange.bind(null, UserFieldId.DefaultTeams),
        sectionId: InfoSectionId.Project,
      },
      {
        id: UserFieldId.Trades,
        label: Localization.translator.projectTrades,
        value: this.getFieldValueById(UserFieldId.Trades),
        type: FormFieldType.Tags,
        serviceInfo: TagType.Trade,
        onChange: this.handleTagsChange.bind(null, UserFieldId.Trades),
        sectionId: InfoSectionId.Project,
        isItemCreationAllowed: true,
      },
      {
        id: UserFieldId.AccountType,
        label: Localization.translator.accountType,
        value: this.getFieldValueById(UserFieldId.AccountType),
        type: FormFieldType.Tag,
        serviceInfo: TagType.AccountType,
        sectionId: InfoSectionId.Project,
        onChange: this.handleTagsChange.bind(null, UserFieldId.AccountType),
        restrictedValues: this.restrictedAccountTypes,
      },
      {
        id: UserFieldId.AccountPosition,
        label: Localization.translator.accountPosition,
        value: this.getFieldValueById(UserFieldId.AccountPosition),
        type: FormFieldType.Tag,
        serviceInfo: TagType.AccountPosition,
        sectionId: InfoSectionId.Additional,
        onChange: this.handleTagChange.bind(null, UserFieldId.AccountPosition),
      },
    ]

    const { requiredFields } = this.activeFormStore

    fields.forEach(field => {
      field.isValid = this.isFieldValid(field)
      field.isRequired = requiredFields && requiredFields.includes(field.id)
    })

    return fields
  }

  @computed
  public get restrictedAccountTypes(): ProjectAccessType[] {
    const userAccountType = UserProject.getAccessType(
      this.editingItem,
      this.userProjectsStore,
    )

    return restrictedAccountTypes.filter(aType => aType !== userAccountType)
  }

  public get relatedCompany(): ICompany {
    const id = this.getFieldValueById(UserFieldId.Company)
    const company = this.companiesStore.getCompanyById(id)

    return company
      ? company.asJson
      : Company.getNewCompanyDto(this.state.activeProject.id)
  }

  public get allCompanies(): Company[] {
    return this.companiesStore.allCompanies
  }

  private get successMessage(): string {
    return Localization.translator.companiesUpdated
  }

  public get areCompaniesBeingUpdated(): boolean {
    return this.state.loading.get(SAVE_COMPANIES)
  }

  public getPermissionConfig = (): any => {
    const { Standard, Master } = Permission

    return Object.values(Workflow).reduce((acc, workflow) => {
      acc[workflow] = this.getFieldValueById(workflow) ? Master : Standard
      return acc
    }, {})
  }

  @action.bound
  public handlePermissionChange(workflow: Workflow, permission: Permission) {
    const isChecked = permission === Permission.Master
    const event = {
      currentTarget: {
        name: workflow,
        type: FormFieldType.Checkbox,
        checked: isChecked,
        value: isChecked,
      },
    }

    this.handleChange(event)
  }

  @action.bound
  public showCompanyModal() {
    this.shouldShowCompanyModal = true
  }

  @action.bound
  public hideCompanyModal() {
    this.shouldShowCompanyModal = false
  }

  @action.bound
  public async saveRelatedCompany(companies: ICompany[]) {
    const [company] = await this.companiesStore.saveMany(companies)
    this.handleSuccessSaving(company?.id)
  }

  @action.bound
  public handleSuccessSaving(companyId: string) {
    showToast(this.successMessage, ToastTheme.SUCCESS, null)
    this.handleTagChange(UserFieldId.Company, companyId)

    this.hideCompanyModal()
  }

  @action.bound
  public showConfirmInviteDialog() {
    this.shouldShowConfirmInviteDialog = true
  }

  @action.bound
  public hideConfirmInviteDialog() {
    this.shouldShowConfirmInviteDialog = false
  }

  protected getUpdatedProjectSettings(
    user: IServiceUserDto,
    fields: any,
  ): UserProject {
    const userProjectSettings = super.getUpdatedProjectSettings(user, fields)

    userProjectSettings.accessType = fields[UserFieldId.AccountType]

    this.setUserProjectResponsibilities(userProjectSettings, fields)

    return userProjectSettings
  }

  protected updateFormStoreItem(item: IServiceUserDto) {
    const userProjectSettings =
      UserProject.fromDto(item.userProjectSettings) ||
      this.userProjectsStore.getByUser(item)

    Object.assign(item, {
      [UserFieldId.AccountType]: userProjectSettings?.accessType,
      [Workflow.DeliveryBooking]: userProjectSettings?.isDocMaster,
      [Workflow.DeliveryInspection]: userProjectSettings?.isInspector,
      [Workflow.Forms]: userProjectSettings?.isFormsMaster,
      [Workflow.ActivityViewing]:
        userProjectSettings?.hasActivityViewResponsibility,
      [Workflow.ScannerApp]: userProjectSettings?.isScanMaster,
      [Workflow.ActivityUpdating]:
        userProjectSettings?.hasActivityUpdateResponsibility,
      [Workflow.ActivityUploading]:
        userProjectSettings?.hasActivityUploadResponsibility,
      [Workflow.ActivityNotes]:
        userProjectSettings?.hasActivityNotesResponsibility,
      [UserFieldId.Company]: userProjectSettings?.companyId || '',
      [UserFieldId.Roles]: userProjectSettings?.roles || [],
      [UserFieldId.DefaultTeams]: userProjectSettings?.defaultTeams || [],
      [UserFieldId.Trades]: userProjectSettings?.trades || [],
      [UserFieldId.Teams]: userProjectSettings?.teams || [],
    })
  }

  protected getRequiredFields(item: User): string[] {
    const canEmailBeEmpty = !item.id || !item.email
    return canEmailBeEmpty
      ? requiredFieldIds
      : [...requiredFieldIds, UserFieldId.Email]
  }

  protected createFormItemFields(): IFormUserFields {
    return {
      id: null,
      [UserFieldId.FirstName]: '',
      [UserFieldId.LastName]: '',
      [UserFieldId.Initials]: '',
      [UserFieldId.Company]: '',
      [UserFieldId.AccountType]: ProjectAccessType.Member,
      [UserFieldId.AvatarUrl]: '',
      [Workflow.DeliveryBooking]: false,
      [Workflow.DeliveryInspection]: false,
      [Workflow.Forms]: false,
      [Workflow.ScannerApp]: false,
      [Workflow.ActivityViewing]: false,
      [Workflow.ActivityUpdating]: false,
      [Workflow.ActivityUploading]: false,
      [Workflow.ActivityNotes]: false,
      [UserFieldId.Teams]: [],
      [UserFieldId.Roles]: [],
      [UserFieldId.Trades]: [],
      [UserFieldId.Email]: '',
      [UserFieldId.PhoneNumber]: '',
      [UserFieldId.GlobalRole]: '',
      [UserFieldId.AccountPosition]: AccountPosition.Field,
      [UserFieldId.EmployeeId]: '',
    }
  }

  protected formValidate(form: FormStore<IFormUserFields>) {
    super.formValidate(form)

    if (!this.checkUniqueness(UserFieldId.Email, form)) {
      form.invalidFields.set(UserFieldId.Email, true)
      form.error = this.isAddMode
        ? Localization.translator.userValidationErrors
            .memberWithSuchEmailIsAlreadyAdded
        : Localization.translator.userValidationErrors.emailIsTaken
    }

    if (!this.checkUniqueness(UserFieldId.PhoneNumber, form)) {
      form.invalidFields.set(UserFieldId.PhoneNumber, true)
      form.error = this.isAddMode
        ? Localization.translator.userValidationErrors
            .memberWithSuchPhoneNumberIsAlreadyAdded
        : Localization.translator.userValidationErrors.phoneIsTaken
    }
  }

  private checkUniqueness(
    fieldId: UserFieldId,
    form: FormStore<IFormUserFields>,
  ): boolean {
    if (
      form.invalidFields.get(fieldId) ||
      FormStore.isFieldValueEmpty(form.fields[fieldId])
    ) {
      return true
    }

    const trimmedFieldValue = (form.fields[fieldId] || '').trim()
    return this.allItems.every(user => {
      if (user.id === form.fields.id) {
        return true
      }

      const beingEditedUserIndex = this.itemsToEdit.findIndex(
        u => u.id === user.id,
      )
      if (beingEditedUserIndex === -1) {
        return (user[fieldId] || '').trim() !== trimmedFieldValue
      }

      const beingEditedUserForm = this.formStores[beingEditedUserIndex]
      const beingEditedUserFieldValue = (
        beingEditedUserForm.getFieldValue(fieldId) || ''
      ).trim()

      return (
        (user[fieldId] || '').trim() !== trimmedFieldValue &&
        beingEditedUserFieldValue !== trimmedFieldValue
      )
    })
  }

  private setUserProjectResponsibilities(
    userProject: UserProject,
    fields: any,
  ) {
    Object.values(Workflow).forEach(workflow => {
      const responsibilityType = workflowToResponsibilityTypeMap[workflow]

      if (fields[workflow]) {
        userProject.addResponsibility(responsibilityType)
      } else {
        userProject.removeResponsibility(responsibilityType)
      }
    })
  }
}
