import { action, computed, observable } from 'mobx'

import { IStatusUpdateDateInput, StatusUpdateType } from '~/client/graph'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import Guard from '~/client/src/shared/utils/Guard'

import Activity from '../../models/Activity'
import StatusUpdate from '../../models/StatusUpdate'
import EventsStore from '../../stores/EventStore/Events.store'
import {
  ACTIVATE_PROJECT,
  BULK_UPDATE_ACTIVITY_COMPANY_STATUS,
  GET_HIERARCHY_CONFIGURATION,
  GET_SCHEDULE,
  UPDATE_ACTIVITY_COMPANIES_STATUSES,
} from '../../stores/EventStore/eventConstants'
import StatusUpdatesStore from '../../stores/domain/StatusUpdates.store'
import ProjectDateStore, {
  isAfter,
  isBefore,
} from '../../stores/ui/ProjectDate.store'

export enum StatusUpdateDayStatus {
  Updated = 'updated',
  Partial = 'partial',
  Missing = 'missing',
}

export enum SuccessMessageType {
  Manpower = 'manpower',
  PercentComplete = 'percent-complete',
}

export const ALL_COMPANIES = 'ALL'
const PERCENT_COMPLETE_STEP = 5
const SUCCESS_MESSAGE_DURATION = 4500

const { UNASSIGNED_COMPANY_VALUE, UNASSIGNED_COMPANY_LABEL } = StatusUpdate

export default class BulkStatusUpdateStore {
  @observable public selectedDate = new Date()
  public readonly percentCompleteInputOpenStatus = observable(
    new Map<string, boolean>(),
  )
  public readonly percentCompleteInputLoadingStatus = observable(
    new Map<string, boolean>(),
  )
  public readonly percentCompleteInputValueStatus = observable(
    new Map<string, number>(),
  )
  public readonly remainingDurationInputOpenStatus = observable(
    new Map<string, boolean>(),
  )
  public readonly remainingDurationInputLoadingStatus = observable(
    new Map<string, boolean>(),
  )
  public readonly remainingDurationInputValueStatus = observable(
    new Map<string, number>(),
  )
  public readonly manpowerInputOpenStatus = observable(
    new Map<string, boolean>(),
  )
  public readonly manpowerInputLoadingStatus = observable(
    new Map<string, boolean>(),
  )
  public readonly manpowerInputValueStatus = observable(
    new Map<string, number>(),
  )
  @observable public manpowerInputMessage: string = null
  @observable public percentCompleteInputMessage: string = null

  @observable public isActualStartSettingSelected: boolean = false
  @observable public isActualFinishSettingSelected: boolean = false

  @observable public isConfirmModalShown: boolean = false
  public applyConfirmAction: () => void = null

  @observable private activityId: string = null
  @observable private company: string = UNASSIGNED_COMPANY_VALUE
  private initialActivityId: string = null
  private _initialCompany: string = UNASSIGNED_COMPANY_VALUE

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly activitiesStore: ActivitiesStore,
    private readonly statusUpdatesStore: StatusUpdatesStore,
    private readonly projectsStore: ProjectsStore,
    private readonly projectDateStore: ProjectDateStore,
  ) {
    Guard.requireAll({
      eventsStore,
      activitiesStore,
      statusUpdatesStore,
      projectsStore,
      projectDateStore,
    })
  }

  public reinit(
    activityId: string = null,
    company: string = UNASSIGNED_COMPANY_VALUE,
  ) {
    if (
      this.initialActivityId !== activityId ||
      this.initialCompany !== company
    ) {
      this.clearActualButtonsState()
    }
    if (this.initialActivityId !== activityId) {
      this.activityId = this.initialActivityId = activityId
    }

    const companyName = this.allCompanies.includes(company)
      ? company
      : this.allCompanies[0]
    if (this.initialCompany !== companyName) {
      this.companyName = this.initialCompany = companyName
    }
  }

  public get companiesToPerform(): string[] {
    return this.companyName === ALL_COMPANIES
      ? this.allCompanies
      : [this.companyName]
  }

  @action.bound
  public selectCompany(companyName: string) {
    this.companyName = companyName
    this.selectDate(new Date())
  }

  @computed
  public get allCompanies(): string[] {
    return this.activityCompanies.filter(
      companyName => companyName !== ALL_COMPANIES,
    )
  }

  @computed
  public get activityCompanies(): string[] {
    const { companies } = this.activity
    if (!companies.length) {
      return [Localization.translator.unassigned]
    }

    return companies.length > 1 ? [...companies, ALL_COMPANIES] : companies
  }

  @computed
  public get selectedCompanyIndex(): number {
    return this.activityCompanies.findIndex(company =>
      this.isCompanyActive(company),
    )
  }

  public isCompanyActive = (companyName: string): boolean => {
    return this.company ? this.company === companyName : true
  }

  public get companyName(): string {
    return this.isCompanyNullOrUnassigned(this.company)
      ? UNASSIGNED_COMPANY_VALUE
      : this.company
  }

  public set companyName(company: string) {
    if (!this.activity) {
      return
    }

    const { companies } = this.activity
    if (
      !this.isCompanyNullOrUnassigned(companies[0]) &&
      company === ALL_COMPANIES
    ) {
      this.company = company
      return
    }

    let companyToSelect = company
    if (!companies.includes(company)) {
      companyToSelect = this.isCompanyNullOrUnassigned(companies[0])
        ? UNASSIGNED_COMPANY_VALUE
        : companies[0]
    }

    this.company = companyToSelect
  }

  public isDateApproved(date: Date): boolean {
    const { start, end } = this.activity.p6ActualDates
    const { isSameDay } = this.projectDateStore
    return isSameDay(date, new Date(start)) || isSameDay(date, new Date(end))
  }

  public getStatusOnDate = (date: Date): StatusUpdateDayStatus => {
    const status = this.statusUpdates.filter(su =>
      this.projectDateStore.isSameDay(new Date(su.dateFor), date),
    )[0]
    const { companies } = this.activity
    if (!status) {
      return StatusUpdateDayStatus.Missing
    }

    if (this.companyName === ALL_COMPANIES) {
      let didPartlyManpowerUpdate = false
      let didPartlyPercentCompleteUpdate = false
      const didAllManpowerUpdate = status.didUpdateByAllCompanies(
        companies,
        this.projectDateStore,
        false,
        true,
      )
      const didAllPercentCompleteUpdate = status.didUpdateByAllCompanies(
        companies,
        this.projectDateStore,
        true,
        false,
      )
      if (didAllManpowerUpdate && didAllPercentCompleteUpdate) {
        return StatusUpdateDayStatus.Updated
      }
      companies.forEach(companyName => {
        if (
          status.didUpdateByCompany(
            companyName,
            this.projectDateStore.isSameDay,
            false,
            true,
          )
        ) {
          didPartlyManpowerUpdate = true
        }
        if (
          status.didUpdateByCompany(
            companyName,
            this.projectDateStore.isSameDay,
            true,
            false,
          )
        ) {
          didPartlyPercentCompleteUpdate = true
        }
      })
      if (didPartlyManpowerUpdate || didPartlyPercentCompleteUpdate) {
        return StatusUpdateDayStatus.Partial
      }
      return StatusUpdateDayStatus.Missing
    }
    const didManpowerUpdate = status.didUpdateByCompany(
      this.companyName,
      this.projectDateStore.isSameDay,
      false,
      true,
    )
    const didPercentCompleteUpdate = status.didUpdateByCompany(
      this.companyName,
      this.projectDateStore.isSameDay,
      true,
      false,
    )

    if (didManpowerUpdate && didPercentCompleteUpdate) {
      return StatusUpdateDayStatus.Updated
    }
    if (didManpowerUpdate || didPercentCompleteUpdate) {
      return StatusUpdateDayStatus.Partial
    }
    return StatusUpdateDayStatus.Missing
  }

  public isCompanyNullOrUnassigned(company: string) {
    return !company || company === UNASSIGNED_COMPANY_LABEL
  }

  @action.bound
  public selectDate(date: Date) {
    this.selectedDate = date
    this.clearActualButtonsState()
  }

  @action.bound
  public bulkUpdateManpower() {
    let date = this.statusInfoStartDate
    const timezoneId = this.projectDateStore.getClientTimezoneId()
    const updates: IStatusUpdateDateInput[] = []

    const manpower = this.currentManpowerValue

    this.companiesToPerform.forEach(companyName => {
      while (isBefore(date, this.statusInfoEndDate)) {
        const status = this.getLastStatusOnDate(date)
        const companyStatus = status.getCompanyStatus(
          companyName,
          this.projectDateStore.isToday,
        )

        const updateDate = new Date(companyStatus.manpowerUpdatedAt)
        if (
          !this.projectDateStore.isSameDay(updateDate, date) ||
          !companyStatus.manpowerUpdatedAt
        ) {
          updates.push({
            unixTime: Math.min(
              this.projectDateStore.endOfDay(date).getTime(),
              Date.now(),
            ),
            timezoneId,
            manpower,
            percentComplete: companyStatus.percentComplete,
          })
        }

        date = this.projectDateStore.addDays(date, 1)
      }

      const successMessage = Localization.translator.xDaysUpdated(
        updates.length,
      )

      this.eventsStore.dispatch(
        BULK_UPDATE_ACTIVITY_COMPANY_STATUS,
        this.activity.code,
        companyName,
        updates,
        SuccessMessageType.Manpower,
        successMessage,
        this.setSuccessMessage,
      )
    })
  }

  @action.bound
  public setSuccessMessage(type: SuccessMessageType, successMessage: string) {
    switch (type) {
      case SuccessMessageType.Manpower:
        this.manpowerInputMessage = successMessage
        setTimeout(() => {
          this.manpowerInputMessage = null
        }, SUCCESS_MESSAGE_DURATION)

        break
      case SuccessMessageType.PercentComplete:
        this.percentCompleteInputMessage = successMessage
        setTimeout(() => {
          this.percentCompleteInputMessage = null
        }, SUCCESS_MESSAGE_DURATION)

        break
    }
  }

  @action.bound
  public bulkUpdatePercentComplete() {
    const missingRanges = []
    let rangeDates = []
    let rangeStartValue = 0
    let rangeEndValue = 0
    let loopDate = this.statusInfoStartDate

    this.companiesToPerform.forEach(companyName => {
      while (isBefore(loopDate, this.statusInfoEndDate)) {
        const status = this.getLastStatusOnDate(loopDate)
        const companyStatus = status.getCompanyStatus(
          companyName,
          this.projectDateStore.isToday,
        )

        const updateDate = new Date(companyStatus.percentCompleteUpdatedAt)

        if (
          !this.projectDateStore.isSameDay(updateDate, loopDate) ||
          !companyStatus.percentCompleteUpdatedAt
        ) {
          rangeDates.push(loopDate)
          loopDate = this.projectDateStore.addDays(loopDate, 1)
          continue
        }

        rangeEndValue = companyStatus.percentComplete
        if (rangeDates.length) {
          missingRanges.push({
            dates: rangeDates,
            startValue: rangeStartValue,
            endValue: rangeEndValue,
          })
        }
        rangeStartValue = companyStatus.percentComplete
        rangeDates = []

        loopDate = this.projectDateStore.addDays(loopDate, 1)
      }

      const updates: IStatusUpdateDateInput[] = []
      const timezoneId = this.projectDateStore.getClientTimezoneId()
      missingRanges.forEach(range => {
        const { dates, startValue, endValue } = range
        const dateIncrement = (endValue - startValue) / (dates.length + 1)

        dates.forEach((date, idx) => {
          const actualPercentCompleteOnDate =
            startValue + (idx + 1) * dateIncrement
          const percentComplete =
            Math.round(actualPercentCompleteOnDate / PERCENT_COMPLETE_STEP) *
            PERCENT_COMPLETE_STEP
          updates.push({
            unixTime: Math.min(
              this.projectDateStore.endOfDay(date).getTime(),
              Date.now(),
            ),
            timezoneId,
            percentComplete,
          })
        })
      })

      const successMessage = Localization.translator.xDaysUpdated(
        updates.length,
      )

      this.eventsStore.dispatch(
        BULK_UPDATE_ACTIVITY_COMPANY_STATUS,
        this.activity.code,
        companyName,
        updates,
        SuccessMessageType.PercentComplete,
        successMessage,
        this.setSuccessMessage,
      )
    })
  }

  public get isActualStartButtonActive(): boolean {
    return (
      this.isActualStartSettingSelected ||
      (this.actualStartDate &&
        this.projectDateStore.isSameDay(
          this.actualStartDate,
          this.selectedDate,
        ))
    )
  }

  public get isActualStartButtonDisabled(): boolean {
    return !!this.actualStartDate && this.hasActualStartDateFromP6
  }

  public get isActualFinishButtonActive(): boolean {
    return (
      this.isActualFinishSettingSelected ||
      (this.actualEndDate &&
        this.projectDateStore.isSameDay(this.actualEndDate, this.selectedDate))
    )
  }

  public get isActualFinishButtonDisabled(): boolean {
    return !!this.actualEndDate && this.hasActualFinishDateFromP6
  }

  @action.bound
  public onActualStartButtonClicked() {
    if (
      (this.actualStartDate &&
        this.projectDateStore.isSameDay(
          this.actualStartDate,
          this.selectedDate,
        )) ||
      (this.actualEndDate && isBefore(this.actualEndDate, this.selectedDate))
    ) {
      return
    }
    this.isActualStartSettingSelected = true
    this.isActualFinishSettingSelected = false
    this.openPercentCompleteInputAndSetValue(StatusUpdate.MIN_PERCENT)
  }

  @action.bound
  public onActualFinishButtonClicked() {
    if (
      (this.actualEndDate &&
        this.projectDateStore.isSameDay(
          this.actualEndDate,
          this.selectedDate,
        )) ||
      (this.actualStartDate &&
        isBefore(this.selectedDate, this.actualStartDate))
    ) {
      return
    }
    this.isActualStartSettingSelected = false
    this.isActualFinishSettingSelected = true
    this.openPercentCompleteInputAndSetValue(StatusUpdate.MAX_PERCENT)
  }

  @action.bound
  public openPercentCompleteInputAndSetValue(value: number) {
    this.percentCompleteInputOpenStatus.set(this.currentActivityKey, true)
    this.percentCompleteInputValueStatus.set(this.currentActivityKey, value)
  }

  @action.bound
  public openRemainingDurationInputAndSetValue(value: number) {
    this.remainingDurationInputOpenStatus.set(this.currentActivityKey, true)
    this.remainingDurationInputValueStatus.set(this.currentActivityKey, value)
  }

  @action.bound
  public clearActualButtonsState() {
    this.isActualStartSettingSelected = false
    this.isActualFinishSettingSelected = false
  }

  public get currentActivityKey(): string {
    return `${this.activity.code}-${
      this.companyName
    }-${this.selectedDate.getTime()}`
  }

  @action.bound
  public updatePercentCompleteForSelection(percentComplete: number) {
    if (
      (this.isActualStartSettingSelected && this.actualStartDate) ||
      (this.isActualFinishSettingSelected && this.actualEndDate)
    ) {
      this.openConfirmModal(
        this.createStatusUpdateForSelection.bind(this, percentComplete),
      )
      return
    }
    this.createStatusUpdateForSelection(percentComplete)
  }

  @action.bound
  public openConfirmModal(onConfirmedAction: () => void) {
    this.isConfirmModalShown = true
    this.applyConfirmAction = onConfirmedAction
  }

  @action.bound
  public closeConfirmModal() {
    this.isConfirmModalShown = false
    this.applyConfirmAction = null
    this.percentCompleteInputLoadingStatus.set(this.currentActivityKey, false)
  }

  public createStatusUpdateForSelection(percentComplete: number) {
    this.isConfirmModalShown = false
    this.applyConfirmAction = null
    const date = Math.min(
      this.projectDateStore.endOfDay(this.selectedDate).getTime(),
      Date.now(),
    )

    const companies =
      this.companyName === ALL_COMPANIES
        ? this.allCompanies
        : [this.companyName]

    let type = StatusUpdateType.Active
    let actualStartDate = null
    let actualEndDate = null

    if (this.isActualStartSettingSelected) {
      type = StatusUpdateType.SetDate
      actualStartDate = date
    }

    if (this.isActualFinishSettingSelected) {
      type = StatusUpdateType.SetDate
      actualEndDate = date
    }

    this.eventsStore.dispatch(
      UPDATE_ACTIVITY_COMPANIES_STATUSES,
      this.activity.code,
      date,
      companies,
      percentComplete,
      this.currentManpowerValue,
      type,
      actualStartDate,
      actualEndDate,
    )
  }

  @computed
  public get currentManpowerValue(): number {
    if (this.companyName === ALL_COMPANIES) {
      return this.getManpowerForAll(this.commonStatusUpdateOnSelectedDate)
    }
    const companyStatus =
      this.commonStatusUpdateOnSelectedDate.getCompanyStatus(
        this.companyName,
        this.projectDateStore.isToday,
      )
    return companyStatus.manpower || 0
  }

  @computed
  public get currentPercentCompleteValue(): number {
    if (this.companyName === ALL_COMPANIES) {
      return this.getPercentCompleteForAll(
        this.commonStatusUpdateOnSelectedDate,
      )
    }
    const companyStatus =
      this.commonStatusUpdateOnSelectedDate.getCompanyStatus(
        this.companyName,
        this.projectDateStore.isToday,
      )
    const value = companyStatus.percentComplete || 0
    return Math.round(value / PERCENT_COMPLETE_STEP) * PERCENT_COMPLETE_STEP
  }

  @computed
  public get areDaysWithoutManpowerUpdate(): boolean {
    let date = this.statusInfoStartDate
    while (isBefore(date, this.statusInfoEndDate)) {
      const status = this.getLastStatusOnDate(date)

      const companyStatus = status.getCompanyStatus(
        this.companyName !== ALL_COMPANIES
          ? this.companyName
          : this.allCompanies[0],
        this.projectDateStore.isToday,
      )

      const updateDate = new Date(companyStatus.manpowerUpdatedAt)
      if (
        !this.projectDateStore.isSameDay(updateDate, date) ||
        !companyStatus.manpowerUpdatedAt
      ) {
        return true
      }
      date = this.projectDateStore.addDays(date, 1)
    }
    return false
  }

  public getPercentCompleteForAll = (statusUpdate): number => {
    const percentCompleteSum = this.allCompanies.reduce((sum, companyName) => {
      const status = statusUpdate.getCompanyStatus(companyName)
      sum += status.percentComplete
      return sum
    }, 0)
    return (
      Math.round(
        Math.ceil(percentCompleteSum / this.allCompanies.length) /
          PERCENT_COMPLETE_STEP,
      ) * PERCENT_COMPLETE_STEP
    )
  }

  public getManpowerForAll = (statusUpdate): number => {
    return (
      (statusUpdate.manpower &&
        Math.ceil(statusUpdate.manpower / this.allCompanies.length)) ||
      0
    )
  }

  @computed
  public get areDaysWithoutPercentCompleteUpdate(): boolean {
    const rangeDates = []
    let loopDate = this.statusInfoStartDate
    while (isBefore(loopDate, this.statusInfoEndDate)) {
      const status = this.getLastStatusOnDate(loopDate)
      const companyStatus = status.getCompanyStatus(
        this.companyName !== ALL_COMPANIES
          ? this.companyName
          : this.allCompanies[0],
        this.projectDateStore.isToday,
      )

      const updateDate = new Date(companyStatus.percentCompleteUpdatedAt)

      if (
        !this.projectDateStore.isSameDay(updateDate, loopDate) ||
        !companyStatus.percentCompleteUpdatedAt
      ) {
        rangeDates.push(loopDate)
        loopDate = this.projectDateStore.addDays(loopDate, 1)
        continue
      }

      if (rangeDates.length) {
        return true
      }

      loopDate = this.projectDateStore.addDays(loopDate, 1)
    }

    return false
  }

  public get isLoading(): boolean {
    const { loading } = this.eventsStore.appState
    return (
      (loading.get(ACTIVATE_PROJECT) ||
        loading.get(GET_SCHEDULE) ||
        loading.get(GET_HIERARCHY_CONFIGURATION)) !== false
    )
  }

  public get activity(): Activity {
    return (
      this.activitiesStore.byId.get(this.activityId) ||
      Activity.createPlaceholder()
    )
  }

  @computed
  public get commonStatusUpdateOnSelectedDate(): StatusUpdate {
    return this.getLastStatusOnDate(
      this.selectedDate,
      this.activity?.percentComplete,
    )
  }

  public get calendarStartDate(): Date {
    return (
      this.projectsStore.currentProjectStartDate ||
      this.calendarStatusStartDate ||
      new Date()
    )
  }

  public get calendarEndDate(): Date {
    const { finish } = this.activity.dates.planned
    return finish.ms > new Date().getTime()
      ? this.projectDateStore.endOfDay(finish.date)
      : this.projectDateStore.endOfDay(new Date())
  }

  public get plannedStartDate(): Date {
    return this.projectDateStore.startOfDay(this.activity.plannedStartDate)
  }

  public get plannedEndDate(): Date {
    return this.projectDateStore.endOfDay(this.activity.plannedEndDate)
  }

  public get actualStartDate(): Date {
    const { actualStartDate } = this.activity
    return actualStartDate && this.projectDateStore.startOfDay(actualStartDate)
  }

  public get hasActualStartDateFromP6(): boolean {
    const { start } = this.activity.p6ActualDates
    return !isNaN(start)
  }

  public get hasActualFinishDateFromP6(): boolean {
    const { end } = this.activity.p6ActualDates
    return !isNaN(end)
  }

  public get actualEndDate(): Date {
    const { actualEndDate } = this.activity
    return actualEndDate && this.projectDateStore.startOfDay(actualEndDate)
  }

  public get statusInfoStartDate(): Date {
    return this.actualStartDate || this.plannedStartDate
  }

  public get calendarStatusStartDate(): Date {
    if (
      this.actualStartDate &&
      isAfter(this.plannedStartDate, this.actualStartDate)
    ) {
      return this.actualStartDate
    }
    return this.plannedStartDate
  }

  public get statusInfoEndDate(): Date {
    return this.actualEndDate || this.projectDateStore.endOfDay(new Date())
  }

  @computed
  private get statusUpdates(): StatusUpdate[] {
    if (!this.activity) {
      return []
    }
    return this.statusUpdatesStore.getAllStatusesUpdatesByActivity(
      this.activity,
      true,
    )
  }

  private getLastStatusOnDate(
    date: Date,
    percentComplete?: number,
  ): StatusUpdate {
    const previousStatuses = this.statusUpdates.filter(
      su =>
        !isAfter(
          this.projectDateStore.startOfDay(su.dateFor),
          this.projectDateStore.endOfDay(date),
        ),
    )
    return (
      previousStatuses[0] || StatusUpdate.createWithDefaults(percentComplete)
    )
  }

  private get initialCompany() {
    return this._initialCompany
  }

  private set initialCompany(company: string) {
    this._initialCompany = this.isCompanyNullOrUnassigned(company)
      ? UNASSIGNED_COMPANY_VALUE
      : company
  }
}
