import * as React from 'react'

import { Classes, Dialog } from '@blueprintjs/core'
import { observer } from 'mobx-react'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import * as Icons from '~/client/src/shared/components/Icons'
import StruxhubSelect from '~/client/src/shared/components/StruxhubInputs/StruxhubSelect'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import MaterialsUploadStore from '../MaterialsUpload.store'

// localization: translated

interface IProps {
  store: MaterialsUploadStore
  titleRenderer(title: string): JSX.Element
}

@observer
export default class MapMaterialsFieldsDialog extends React.Component<IProps> {
  public render() {
    const { store, titleRenderer } = this.props
    const {
      isMapFieldsDialogShown,
      resetInitialValues,
      openUploadConfigDialog,
      fields,
      columnMap,
      isMapDialogBtnEnabled,
      getSheetColumnsAsOptions,
    } = store

    const bodyClassName = `${Classes.DIALOG_BODY} relative px16`
    const footerClassName = `${Classes.DIALOG_FOOTER} ma0 py10 px30 bt-light-input-border row x-center`

    return (
      <Dialog
        isOpen={isMapFieldsDialogShown}
        title={titleRenderer(Localization.translator.mapFields)}
        isCloseButtonShown={false}
        canOutsideClickClose={false}
        className="bg-white ba-light-input-border pb5"
      >
        <div className={bodyClassName}>
          <div className="text large light mb20">
            {Localization.translator.uploadScheduleDescriptions.mapFields}
          </div>
          {fields.map(({ name, caption, isRequired, isFirstLocation }) => (
            <div key={name} className="col">
              {isFirstLocation && this.locationInfo}
              <div className="row">
                <StruxhubSelect
                  name={name}
                  className="overflow-hidden"
                  label={caption}
                  isRequired={isRequired}
                  value={columnMap[name] || ''}
                  onChange={this.onSelectChanged.bind(this, name)}
                >
                  <option value=""></option>
                  {getSheetColumnsAsOptions(name).map(opt => (
                    <option key={opt} value={opt}>
                      {opt}
                    </option>
                  ))}
                </StruxhubSelect>
              </div>
            </div>
          ))}
        </div>

        <div className={footerClassName}>
          <BaseActionButton
            title={Localization.translator.cancel}
            className="gray-theme mx10"
            isEnabled
            isGrow
            isAnimated
            onClick={resetInitialValues}
          />
          <BaseActionButton
            title={Localization.translator.next}
            className="primary-theme-inverted mx10"
            isEnabled={isMapDialogBtnEnabled}
            isGrow
            isAnimated
            onClick={openUploadConfigDialog}
          />
        </div>
      </Dialog>
    )
  }

  private get locationInfo(): JSX.Element {
    return (
      <div className="row text small bold pl5 no-outline">
        {Localization.translator.materialsUploading.makeSureLocationNamesUnique}
        <Icons.Info className="ml5 row no-grow" />
      </div>
    )
  }

  private onSelectChanged(
    fieldName: string,
    e: React.ChangeEvent<HTMLSelectElement>,
  ) {
    this.props.store.addColumnMap(fieldName, e.target.value)
  }
}
