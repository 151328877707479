import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { DEFAULT_ROW_HEIGHT } from '~/client/src/desktop/components/BaseObservableTable/BaseObservableTable'
import { ILWFCRow } from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'

interface ICustomRowRender {
  readonly store: { isRowSelected: (row: any) => boolean; rows?: ILWFCRow[] }
  style: any
  rowKey: string
  rowData: any
  columns: any[]
  index: number
  className: string
  onRowClick?: (obj: any) => any
  onRowDoubleClick?: (obj: any) => any
  onRowMouseOver?: (obj: any) => any
  onRowMouseOut?: (obj: any) => any
  onRowRightClick?: (obj: any) => any
  customClassName?: string
}

const SELECTED_CLASS_NAME = 'gant-selection'

@observer
export default class CustomRowRender extends React.Component<ICustomRowRender> {
  public render() {
    const {
      className,
      columns,
      index,
      rowKey,
      onRowClick,
      onRowDoubleClick,
      onRowMouseOut,
      onRowMouseOver,
      onRowRightClick,
      rowData,
      style,
      customClassName = '',
      store,
    } = this.props

    const nativeProps: any = { 'aria-rowindex': index + 1 }

    if (
      onRowClick ||
      onRowDoubleClick ||
      onRowMouseOut ||
      onRowMouseOver ||
      onRowRightClick
    ) {
      nativeProps['aria-label'] = 'row'
      nativeProps.tabIndex = 0

      if (onRowClick) {
        nativeProps.onClick = event => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        nativeProps.onDoubleClick = event =>
          onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        nativeProps.onMouseOut = event =>
          onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        nativeProps.onMouseOver = event =>
          onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        nativeProps.onContextMenu = event =>
          onRowRightClick({ event, index, rowData })
      }
    }

    const isRowSelected = store.isRowSelected(rowData)
    return (
      <div
        key={rowKey}
        role="row"
        style={{ ...style, top: this.top }}
        className={classList({
          [className]: true,
          [customClassName]: true,
          [SELECTED_CLASS_NAME]: isRowSelected,
        })}
        {...nativeProps}
      >
        {columns}
      </div>
    )
  }

  private get top(): number {
    const {
      index,
      store: { rows },
      style,
    } = this.props

    return (
      rows?.reduce((sum, row, rowIndex) => {
        if (rowIndex !== 0 && rowIndex < index) {
          sum += row.height || DEFAULT_ROW_HEIGHT
        }
        return sum
      }, 0) || style.top
    )
  }
}
