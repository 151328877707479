import { IMaturixMonitoringData } from '~/client/graph'

import MaturixStrengthUnit, {
  MaturixStrengthUnitResponse,
  convertStrengthUnitFromResponce,
} from '../enums/MaturixStrengthUnit'
import MaturixTemperatureUnit from '../enums/MaturixTemperatureUnit'

export class MaturixData {
  public static fromDto(dto: IMaturixMonitoringData): MaturixData {
    return new MaturixData(
      dto.timestamp * 1000,
      dto.temperature,
      dto.strength,
      dto.temperatureUnit as MaturixTemperatureUnit,
      convertStrengthUnitFromResponce(
        dto.strengthUnit as MaturixStrengthUnitResponse,
      ),
      dto.maturity,
    )
  }

  public constructor(
    public timestamp: number,
    public temperature: number,
    public strength?: number,
    public temperatureUnit?: MaturixTemperatureUnit,
    public strengthUnit?: MaturixStrengthUnit,
    public maturity?: number,
  ) {}
}
