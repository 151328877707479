import { action, observable } from 'mobx'

import { GetProjectNameDocument } from '~/client/graph/operations/generated/Project.generated'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import { FormFieldType } from '../../enums/FormFieldType'
import UserFieldId from '../../enums/UserFieldId'
import { Field } from '../../types/CustomFieldType'
import {
  ICountry,
  getFormattedPhoneNumberForSubmission,
  isPhoneNumberValidForSubmission,
} from '../../utils/phoneNumberHelpers'
import {
  NON_EMPTY_VALUE_PATTERN,
  VALID_EMAIL_PATTERN,
} from '../../utils/regExpPatterns'
import EventsStore from '../EventStore/Events.store'
import InitialState from '../InitialState'
import GraphExecutorStore from '../domain/GraphExecutor.store'

export const projectCodeKey = 'projectCode'

export default abstract class BaseSignUpStore {
  @observable public initProjectName = ''

  @observable public errorMessage = ''

  @observable private fieldValuesMap: Map<UserFieldId, string> = null
  @observable private invalidFieldsMap = new Map<UserFieldId, boolean>()

  public constructor(
    protected readonly eventsStore: EventsStore,
    protected readonly graphExecutorStore: GraphExecutorStore,
    private readonly optionFieldsIds: UserFieldId[] = [],
  ) {
    const fieldValuesMap: any = Object.entries(this.initFieldValuesMap || {})
    this.fieldValuesMap = new Map<UserFieldId, string>(fieldValuesMap)
  }

  public get appState(): InitialState {
    return this.eventsStore.appState
  }

  public get isLoading(): boolean {
    return false
  }

  public get initFieldValuesMap(): { [key: string]: string } {
    return {}
  }

  public get fields(): Field[] {
    const fields: Field[] = [
      {
        id: UserFieldId.FirstName,
        type: FormFieldType.Text,
        value: this.getFieldValueById(UserFieldId.FirstName),
        label: Localization.translator.firstName,
        onChange: this.handleFieldChange.bind(this, UserFieldId.FirstName),
        onValueReset: this.handleValueReset.bind(this, UserFieldId.FirstName),
        pattern: NON_EMPTY_VALUE_PATTERN.source,
      },
      {
        id: UserFieldId.LastName,
        type: FormFieldType.Text,
        value: this.getFieldValueById(UserFieldId.LastName),
        label: Localization.translator.lastName,
        onChange: this.handleFieldChange.bind(this, UserFieldId.LastName),
        onValueReset: this.handleValueReset.bind(this, UserFieldId.LastName),
        pattern: NON_EMPTY_VALUE_PATTERN.source,
      },

      {
        id: UserFieldId.Email,
        type: FormFieldType.Email,
        value: this.getFieldValueById(UserFieldId.Email),
        label: Localization.translator.email_noun,
        onChange: this.handleFieldChange.bind(this, UserFieldId.Email),
        onValueReset: this.handleValueReset.bind(this, UserFieldId.Email),
        pattern: VALID_EMAIL_PATTERN.source,
      },
      {
        id: UserFieldId.PhoneNumber,
        type: FormFieldType.Phone,
        value: this.getFieldValueById(UserFieldId.PhoneNumber),
        label: Localization.translator.phoneNumber,
        onChange: this.handlePhoneFieldChange.bind(
          this,
          UserFieldId.PhoneNumber,
        ),
      },
    ]

    fields.forEach(field => {
      field.isValid = this.isFieldValid(field.id)
      field.isRequired = this.isFieldRequired(field.id)
    })

    return fields
  }

  @action.bound
  public handleSubmit() {
    if (this.canSubmit) {
      this.submit()
    } else {
      this.showError()
    }
  }

  @action.bound
  public async requestProjectName() {
    const res = await this.graphExecutorStore.query(GetProjectNameDocument, {
      projectCode: this.appState.initProjectCode,
    })

    if (res.data) {
      this.initProjectName = res.data.getProjectName
    }
  }

  protected submit() {
    throw new Error('[submit] should be overridden in derived classes')
  }

  protected getFieldValueById(fieldId: UserFieldId): string {
    return this.fieldValuesMap.get(fieldId) || ''
  }

  @action
  protected handleFieldChange(fieldId: UserFieldId, { target }: any) {
    this.clearError()
    this.setFieldValueById(fieldId, target.value)
    this.invalidFieldsMap.set(fieldId, !target.checkValidity())
  }

  @action
  protected handleValueReset(fieldId: UserFieldId) {
    this.clearError()
    this.setFieldValueById(fieldId, '')
    this.invalidFieldsMap.set(fieldId, true)
  }

  @action.bound
  protected handlePhoneFieldChange(
    fieldId: UserFieldId,
    phoneNumber: string,
    country: ICountry,
  ) {
    this.clearError()
    this.setFieldValueById(fieldId, phoneNumber)

    const isRequired = this.isFieldRequired(fieldId)
    const isValid = isPhoneNumberValidForSubmission(
      phoneNumber,
      country,
      isRequired,
    )

    this.invalidFieldsMap.set(fieldId, !isValid)
  }

  @action.bound
  protected setFieldValueById(fieldId: UserFieldId, value: string) {
    this.fieldValuesMap.set(fieldId, value)
  }

  @action.bound
  protected setError(errorMessage: string) {
    this.errorMessage =
      errorMessage ||
      Localization.translator.somethingWentWrongDuringAPIInteraction
  }

  @action.bound
  private clearError() {
    this.errorMessage = ''
  }

  protected isFieldValid(fieldId: UserFieldId): boolean {
    return !this.invalidFieldsMap.get(fieldId)
  }

  protected isFieldRequired(fieldId: UserFieldId): boolean {
    return !this.optionFieldsIds.includes(fieldId)
  }

  protected get formattedFieldMapForSubmission(): { [key: string]: string } {
    return this.fields.reduce(
      (acc, { id, value }) =>
        Object.assign(acc, {
          [id]: (id === UserFieldId.PhoneNumber
            ? getFormattedPhoneNumberForSubmission(value)
            : value
          ).trim(),
        }),
      {},
    )
  }

  private get invalidFields(): Field[] {
    return this.fields.filter(({ isValid }) => !isValid)
  }

  private get unfilledRequiredFields(): Field[] {
    return this.fields.filter(
      ({ isRequired, value }) => isRequired && !value.trim(),
    )
  }

  protected get canSubmit(): boolean {
    return !this.invalidFields.length && !this.unfilledRequiredFields.length
  }

  private showError() {
    let errorMessage = Localization.translator.pleaseCorrectErrors
    let labels = ''

    if (this.unfilledRequiredFields.length) {
      errorMessage = Localization.translator.requiredFieldCannotBeEmpty
      labels = this.unfilledRequiredFields
        .map(({ label }) => `[${label}]`)
        .join(',')
    } else if (this.invalidFields.length) {
      labels = this.invalidFields.map(({ label }) => `[${label}]`).join(',')
    }

    this.setError(`${errorMessage} : ${labels}`)
  }
}
