import Delivery from '~/client/src/shared/models/Delivery'
import Flag from '~/client/src/shared/models/Flag'
import Message from '~/client/src/shared/models/Message/index'
import Photo from '~/client/src/shared/models/Photo'
import Rfi from '~/client/src/shared/models/Rfi'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'

import CategoryOfVariance from './CategoryOfVariance'
import SafetyHazard from './SafetyHazard'
import ScheduleComment from './ScheduleComment'

export enum LogEntryTypes {
  MESSAGE = 'MESSAGE',
  STATUS_UPDATE = 'STATUS_UPDATE',
  FLAG = 'FLAG',
  IMAGE = 'IMAGE',
  RFI = 'RFI',
  CATEGORY_OF_VARIANCE = 'CATEGORY_OF_VARIANCE',
  SAFETY_HAZARD = 'SAFETY_HAZARD',
  DELIVERY = 'DELIVERY',
  SCHEDULE_COMMENT = 'SCHEDULE_COMMENT',
}

export default class LogEntry {
  public static fromFlag(flag: Flag): LogEntry {
    return new LogEntry(LogEntryTypes.FLAG, flag, flag.updatedAt)
  }
  public static fromStatusUpdate(statusUpdate: StatusUpdate): LogEntry {
    return new LogEntry(
      LogEntryTypes.STATUS_UPDATE,
      statusUpdate,
      statusUpdate.createdAt,
    )
  }

  public static fromMessage(message: Message): LogEntry {
    return new LogEntry(LogEntryTypes.MESSAGE, message, message.createdAt)
  }

  public static fromRfi(rfi: Rfi): LogEntry {
    return new LogEntry(LogEntryTypes.RFI, rfi, rfi.updatedAt)
  }

  public static fromCategoryOfVariance(
    categoryOfVariance: CategoryOfVariance,
  ): LogEntry {
    return new LogEntry(
      LogEntryTypes.CATEGORY_OF_VARIANCE,
      categoryOfVariance,
      categoryOfVariance.updatedAt,
    )
  }

  public static fromSafetyHazard(safetyHazard: SafetyHazard): LogEntry {
    return new LogEntry(
      LogEntryTypes.SAFETY_HAZARD,
      safetyHazard,
      safetyHazard.updatedAt,
    )
  }

  public static fromScheduleComment(sc: ScheduleComment): LogEntry {
    return new LogEntry(LogEntryTypes.SCHEDULE_COMMENT, sc, sc.updatedAt)
  }

  public static fromDelivery(delivery: Delivery): LogEntry {
    return new LogEntry(LogEntryTypes.DELIVERY, delivery, delivery.updatedAt)
  }

  public static fromPhoto(photo: Photo): LogEntry {
    return new LogEntry(LogEntryTypes.IMAGE, photo, photo.updatedAt)
  }

  public constructor(
    public type: LogEntryTypes,
    public data: any,
    public timestamp: number,
  ) {}
}
