import React from 'react'

import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import DeliveriesTableWithCollapsibleColumnsStore from '../DeliveriesCollapsibleColumnsTable.store'

// localization: translated

interface IProps {
  store: DeliveriesTableWithCollapsibleColumnsStore
  bookingSectionLeftOffset: number
  durationSectionLeftOffset: number
  locationsSectionLeftOffset: number
  equipmentSectionLeftOffset: number
  materialsSectionLeftOffset: number
  vendorSectionLeftOffset: number
}

const BOOKING_SECTION_ADDITIONAL_OFFSET = 2
const EQUIPMENT_SECTION_ADDITIONAL_OFFSET = 5
const VENDOR_SECTION_ADDITIONAL_OFFSET = 3

@observer
export default class DeliveriesCollapsedSectionsHeader extends React.Component<IProps> {
  public render() {
    const {
      store,
      bookingSectionLeftOffset,
      durationSectionLeftOffset,
      locationsSectionLeftOffset,
      equipmentSectionLeftOffset,
      materialsSectionLeftOffset,
      vendorSectionLeftOffset,
    } = this.props
    const {
      shouldCollapseDuration,
      shouldCollapseLocations,
      shouldCollapseEquipment,
      shouldCollapseMaterials,
      shouldCollapseVendor,
    } = store

    const bookingSectionOffset =
      bookingSectionLeftOffset - BOOKING_SECTION_ADDITIONAL_OFFSET
    const equipmentTitleOffset =
      equipmentSectionLeftOffset - EQUIPMENT_SECTION_ADDITIONAL_OFFSET
    const vendorTitleOffset =
      vendorSectionLeftOffset - VENDOR_SECTION_ADDITIONAL_OFFSET

    return (
      <div
        className="collapsed-sections-holder"
        style={{
          left: bookingSectionOffset,
        }}
      >
        {shouldCollapseDuration && (
          <span
            className="section-title absolute text small uppercase no-bold"
            style={{ left: durationSectionLeftOffset }}
          >
            {Localization.translator.dur_duration}
          </span>
        )}
        {shouldCollapseLocations && (
          <span
            className="section-title absolute text small uppercase no-bold"
            style={{ left: locationsSectionLeftOffset }}
          >
            {Localization.translator.loc_locations}
          </span>
        )}
        {shouldCollapseEquipment && (
          <span
            className="section-title absolute text small uppercase no-bold"
            style={{ left: equipmentTitleOffset }}
          >
            {Localization.translator.equip_equipment}
          </span>
        )}
        {shouldCollapseMaterials && (
          <span
            className="section-title absolute text small uppercase no-bold"
            style={{ left: materialsSectionLeftOffset }}
          >
            {Localization.translator.mat_materials}
          </span>
        )}
        {shouldCollapseVendor && (
          <span
            className="section-title absolute text small uppercase no-bold"
            style={{ left: vendorTitleOffset }}
          >
            {Localization.translator.vend_vendor}
          </span>
        )}
      </div>
    )
  }
}
