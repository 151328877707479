import * as React from 'react'

import Konva from 'konva'
import { Group, Rect } from 'react-konva'

interface IProps {
  lineWidth: number
  color: string
  fillColor: string
  fillOpacity: number
  width: number
  height: number
  mapBearing?: number
  rotation?: number
}

export default class KonvaRectangle extends React.Component<
  IProps & Konva.NodeConfig
> {
  public render() {
    const {
      color,
      lineWidth,
      fillColor,
      fillOpacity,
      width,
      height,
      rotation = 0,
      mapBearing = 0,
      ...rest
    } = this.props
    return (
      <Group {...rest}>
        <Rect
          width={width}
          height={height}
          fill={fillColor}
          opacity={fillOpacity}
          rotation={rotation - mapBearing}
        />
        <Rect
          width={width}
          height={height}
          strokeWidth={lineWidth}
          stroke={color}
          rotation={rotation - mapBearing}
        />
      </Group>
    )
  }
}
