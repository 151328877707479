import { computed, observable } from 'mobx'

import {
  IAddressBounds,
  IGeoJson2DGeographicCoordinates,
  IGeoJson2DGeographicCoordinatesInput,
  IGlobeView,
  IGlobeViewCircle,
  IGlobeViewCircleInput,
  IGlobeViewItemShapeInterface,
  IGlobeViewPin,
  IGlobeViewPinInput,
  IGlobeViewPolyline,
  IGlobeViewPolylineInput,
  IGlobeViewRectangle,
  IGlobeViewRectangleInput,
  IGlobeViewSitemapData,
  IGlobeViewSpecificItemData,
  IGlobeViewSpecificItemDataCircleInput,
  IGlobeViewSpecificItemDataPolylineInput,
  IGlobeViewSpecificItemDataRectangleInput,
  IGlobeViewTextBox,
  IGlobeViewTextBoxInput,
  ILatLng,
  SitemapItemShapeType,
} from '~/client/graph'
import Guard from '~/client/src/shared/utils/Guard'

import { copyObjectArray } from '../utils/util'
import BaseModel from './BaseModel'

export interface ISitemapGeoPosition {
  latitude: number
  longitude: number
  zoom: number
  bearing?: number
  pitch?: number
  altitude?: number
  bounds?
}

export default class GlobeView
  extends BaseModel<IGlobeView>
  implements IGlobeView
{
  public static fromDto(dto: IGlobeView) {
    return new GlobeView(
      dto.id,
      dto.name,
      dto.projectId,
      dto.items || [],
      dto.sitemaps || [],
      dto.createdAt,
      dto.updatedAt,
      dto.bounds,
      dto.center,
      dto.zoom,
      dto.altitude,
      dto.pitch,
      dto.bearing,
      dto.geoCorners,
      true,
      dto.isSatelliteMode,
      dto.isTrafficShown,
      dto.isProjectOverviewGlobe,
      dto.filledImage,
    )
  }

  @observable public items: IGlobeViewSpecificItemData[] = []
  @observable public sitemaps: IGlobeViewSitemapData[] = []

  public constructor(
    id: string,
    public name: string,
    public projectId: string,
    items: IGlobeViewSpecificItemData[] = [],
    sitemaps: IGlobeViewSitemapData[] = [],
    createdAt: number = 0,
    updatedAt: number = 0,
    public bounds?: IAddressBounds,
    public center?: ILatLng,
    public zoom?: number,
    public altitude?: number,
    public pitch?: number,
    public bearing?: number,
    public geoCorners?: IGeoJson2DGeographicCoordinates[],
    public isOrientationLocked?: boolean,
    public isSatelliteMode?: boolean,
    public isTrafficShown?: boolean,
    public isProjectOverviewGlobe?: boolean,
    public filledImage?: string,
  ) {
    super(id)

    this.items = copyObjectArray(items)
    this.sitemaps = copyObjectArray(sitemaps)
    this.setCreatedAt(createdAt)
    this.setUpdatedAt(updatedAt)
    this.filledImage = filledImage
    Guard.requireAll({ name, projectId })
  }

  public setGeoposition(
    geoposition: ISitemapGeoPosition,
    bounds?: IGeoJson2DGeographicCoordinatesInput[],
  ) {
    // TODO: remove with back changes
    this.bounds = {
      ne: {
        lat: 0,
        lng: 0,
      },
      sw: {
        lat: 0,
        lng: 0,
      },
    }
    this.center = {
      lat: geoposition.latitude,
      lng: geoposition.longitude,
    }
    this.zoom = geoposition.zoom
    this.altitude = 0
    this.pitch = 0
    this.bearing = geoposition.bearing
    this.geoCorners = bounds
  }

  public isNameEqual(name: string) {
    return this.name === name
  }

  public setName(name: string) {
    this.name = name
  }

  @computed
  public get itemsMap(): { [key: string]: boolean } {
    return (
      this.items?.reduce((acc, item) => {
        acc[item.sitemapItemId] = true
        return acc
      }, {} as { [key: string]: boolean }) || {}
    )
  }

  @computed
  public get displayDataMap(): {
    [key: string]: { item: IGlobeViewSpecificItemData; idx: number }
  } {
    return (
      this.items?.reduce((acc, item, idx) => {
        acc[item.sitemapItemId] = {
          item: {
            id: item.id,
            sitemapItemId: item.sitemapItemId,
            isHidden: item.isHidden,
            label: item.label,
            shape: item.shape,
            icon: item.icon,
            projectId: item.projectId,
            globeViewId: item.globeViewId,
          },
          idx,
        }
        return acc
      }, {} as { [key: string]: { item: IGlobeViewSpecificItemData; idx: number } }) ||
      {}
    )
  }

  @computed
  public get sitemapsMap(): { [key: string]: boolean } {
    return (
      this.sitemaps?.reduce((acc, item) => {
        acc[item.sitemapId] = true
        return acc
      }, {} as { [key: string]: boolean }) || {}
    )
  }

  public isItemDisplayed = (sitemapItemId: string): boolean => {
    const item = this.displayDataMap?.[sitemapItemId]?.item
    return !!item && !item.isHidden
  }

  public static getItemsByShapeType(
    items: IGlobeViewSpecificItemData[],
    shapeType: SitemapItemShapeType,
  ): IGlobeViewSpecificItemData[] {
    return items?.filter(({ shape }) => shape?.type === shapeType)
  }

  public static getPolylineItems(
    items: IGlobeViewSpecificItemData[],
  ): IGlobeViewSpecificItemData[] {
    return GlobeView.getItemsByShapeType(items, SitemapItemShapeType.Polyline)
  }

  public static getRectangleItems(
    items: IGlobeViewSpecificItemData[],
  ): IGlobeViewSpecificItemData[] {
    return GlobeView.getItemsByShapeType(items, SitemapItemShapeType.Rectangle)
  }

  public static getCircleItems(
    items: IGlobeViewSpecificItemData[],
  ): IGlobeViewSpecificItemData[] {
    return GlobeView.getItemsByShapeType(items, SitemapItemShapeType.Circle)
  }

  public static getItemsWithoutShape(
    items: IGlobeViewSpecificItemData[],
  ): IGlobeViewSpecificItemData[] {
    return items?.filter(i => !i.shape)
  }

  public static getPolylineItemDtos(
    items: IGlobeViewSpecificItemData[],
  ): IGlobeViewSpecificItemDataPolylineInput[] {
    return GlobeView.convertItemsToDtos<IGlobeViewPolylineInput>(
      [
        ...GlobeView.getPolylineItems(items),
        ...GlobeView.getItemsWithoutShape(items),
      ],
      GlobeView.convertShapeToPolylineDto,
    )
  }

  public static getRectangleItemDtos(
    items: IGlobeViewSpecificItemData[],
  ): IGlobeViewSpecificItemDataRectangleInput[] {
    return GlobeView.convertItemsToDtos<IGlobeViewRectangleInput>(
      GlobeView.getRectangleItems(items),
      GlobeView.convertShapeToRectangleDto,
    )
  }

  public static getCircleItemDtos(
    items: IGlobeViewSpecificItemData[],
  ): IGlobeViewSpecificItemDataCircleInput[] {
    return GlobeView.convertItemsToDtos<IGlobeViewCircleInput>(
      GlobeView.getCircleItems(items),
      GlobeView.convertShapeToCircleDto,
    )
  }

  public hasDisplayData = (sitemapItemId: string): boolean => {
    return !!this.displayDataMap?.[sitemapItemId]?.item
  }

  public getItemDisplayData = (
    sitemapItemId: string,
  ): IGlobeViewSpecificItemData => {
    return (
      this.displayDataMap?.[sitemapItemId]?.item ||
      ({} as IGlobeViewSpecificItemData)
    )
  }

  private findItemBySitemapItemId(itemId: string): IGlobeViewSpecificItemData {
    if (!itemId) {
      return null
    }

    return this.displayDataMap?.[itemId]?.item
  }

  public setItemDisplayData(
    sitemapItemId: string,
    data: IGlobeViewSpecificItemData,
  ) {
    const objIndex = this.findItemIndexBySitemapItemId(sitemapItemId)

    if (objIndex === -1) {
      this.items.push(data)
      return
    }

    this.items.splice(objIndex, 1, data)
  }

  public removeItemDisplayData(id: string) {
    const objIndex = this.findItemIndexByGlobeViewItemDataId(id)
    if (objIndex === -1) {
      return
    }
    this.items.splice(objIndex, 1)
  }

  private findItemIndexBySitemapItemId(itemId: string): number {
    return this.items?.findIndex(
      ({ sitemapItemId }) => sitemapItemId === itemId,
    )
  }

  private findItemIndexByGlobeViewItemDataId(itemId: string): number {
    return this.items?.findIndex(({ id }) => id === itemId)
  }

  private static convertShapeToPolylineDto(
    shape: IGlobeViewPolyline,
  ): IGlobeViewPolylineInput {
    if (!shape) {
      return null
    }

    return {
      lineWidth: shape.lineWidth,
      lineColor: shape.lineColor,
      fillColor: shape.fillColor,
      fillOpacity: shape.fillOpacity,
      type: shape.type,
      isClosed: shape.isClosed,
      arrowPosition: shape.arrowPosition,
      isDisplayed: shape.isDisplayed,
    }
  }

  private static convertShapeToRectangleDto(
    shape: IGlobeViewRectangle,
  ): IGlobeViewRectangleInput {
    if (!shape) {
      return null
    }

    return {
      lineWidth: shape.lineWidth,
      lineColor: shape.lineColor,
      fillColor: shape.fillColor,
      fillOpacity: shape.fillOpacity,
      type: shape.type,
      isDisplayed: shape.isDisplayed,
    }
  }

  private static convertShapeToCircleDto(
    shape: IGlobeViewCircle,
  ): IGlobeViewCircleInput {
    if (!shape) {
      return null
    }

    return {
      lineWidth: shape.lineWidth,
      lineColor: shape.lineColor,
      fillColor: shape.fillColor,
      fillOpacity: shape.fillOpacity,
      type: shape.type,
      isDisplayed: shape.isDisplayed,
    }
  }

  private static convertItemsToDtos<ShapeInputType>(
    items: IGlobeViewSpecificItemData[],
    convertShapeFn: (shape: IGlobeViewItemShapeInterface) => ShapeInputType,
  ): any[] {
    return items.map(i => {
      return {
        id: i.id,
        sitemapItemId: i.sitemapItemId,
        projectId: i.projectId,
        globeViewId: i.globeViewId,
        isHidden: i.isHidden,
        icon: GlobeView.convertToSitemapPinDto(i.icon),
        label: GlobeView.convertToTextBoxDto(i.label),
        shape: convertShapeFn(i.shape as IGlobeViewItemShapeInterface),
      }
    })
  }

  private static convertToSitemapPinDto(
    globeViewPin: IGlobeViewPin,
  ): IGlobeViewPinInput {
    if (!globeViewPin) {
      return null
    }

    return {
      isHidden: globeViewPin.isHidden,
    }
  }

  private static convertToTextBoxDto(
    textBox: IGlobeViewTextBox,
  ): IGlobeViewTextBoxInput {
    if (!textBox) {
      return null
    }

    return {
      fontSize: textBox.fontSize,
      isTextBoxDisplayed: textBox.isTextBoxDisplayed,
      isHidden: textBox.isHidden,
      color: textBox.color,
    }
  }

  public getCopy() {
    return new GlobeView(
      this.id,
      this.name,
      this.projectId,
      this.items || [],
      this.sitemaps || [],
      this.createdAt,
      this.updatedAt,
      this.bounds,
      this.center,
      this.zoom,
      this.altitude,
      this.pitch,
      this.bearing,
      this.geoCorners?.slice(),
      this.isOrientationLocked,
      this.isSatelliteMode,
      this.isTrafficShown,
      this.isProjectOverviewGlobe,
      this.filledImage,
    )
  }

  public getFullCopy(): GlobeView {
    const copy = this.getCopy()
    copy.updateFromJson(this.asJson)
    return copy
  }
}
