import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import ActionHandleButton from '~/client/src/desktop/views/Deliveries/components/DeliveryActionSideWrapper/components/ActionHandleButton/ActionHandleButton'
import EditingConfirm from '~/client/src/desktop/views/Deliveries/components/DeliveryActionSideWrapper/components/DeliveryBulkEditor/components/EditingConfirm/EditingConfirm'
import ActivityDetailsStore from '~/client/src/shared/components/ActivityDetails/ActivityDetails.store'
import {
  ActivityFieldType,
  IActivityField,
} from '~/client/src/shared/components/ActivityDetails/components/ActivityForm.store'
import CompactCompaniesDirectory from '~/client/src/shared/components/CompactCompaniesDirectory/CompactCompaniesDirectory'
import * as Icons from '~/client/src/shared/components/Icons'
import { Content, Footer, View } from '~/client/src/shared/components/Layout'
import LocationPicker from '~/client/src/shared/components/LocationPicker/LocationPicker'
import StruxhubSelect from '~/client/src/shared/components/StruxhubInputs/StruxhubSelect'
import StruxhubAttributeSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubAttributeSelector'
import StruxhubCompanySelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubCompanySelector'
import StruxhubUserSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubUserSelector'
import UsersDirectory from '~/client/src/shared/components/UsersDirectory/UsersDirectory'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Activity from '~/client/src/shared/models/Activity'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import { NOOP } from '~/client/src/shared/utils/noop'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import ActivityBulkEditorStore from './ActivityBulkEditor.store'

import './ActivityBulkEditor.scss'

interface IProps {
  selectedActivities: Activity[]
  onClose: () => void

  eventsStore?: DesktopEventStore
  activityDetailsStore?: ActivityDetailsStore
  activitiesStore?: ActivitiesStore
}

const CROSS_ICON_SIZE = 14
const userModalSearchTagTypes = [TagType.User, TagType.Company]

@inject('eventsStore', 'activityDetailsStore', 'activitiesStore')
@observer
export default class ActivityBulkEditor extends React.Component<IProps> {
  private readonly store: ActivityBulkEditorStore = null
  private containerRef: any = null

  public constructor(props: IProps) {
    super(props)

    this.store = new ActivityBulkEditorStore(
      props.onClose,
      props.eventsStore,
      props.activityDetailsStore,
      props.activitiesStore,
      props.selectedActivities,
    )
  }

  public componentDidMount() {
    this.store.resetAllFields()
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { selectedActivities } = this.props

    if (!selectedActivities.length) {
      return this.props.onClose()
    }

    if (prevProps.selectedActivities.length !== selectedActivities.length) {
      this.store.reinit(selectedActivities)
    }
  }

  public componentWillUnmount() {
    this.store.resetAllFields()
  }

  public render() {
    const { onClose } = this.props

    const {
      canEdit,
      confirmMessage,
      shouldShowConfirmModal,
      actionHandleButtonTitle,
      shouldShowDataLinkingWarning,
      handleEdit,
      closeConfirm,
      prepareEditingData,
    } = this.store

    return (
      <div className="activity-bulk-container col">
        <div className="row pa10 pl20 bb-light-grey">
          <div className="no-grow col y-center" onClick={onClose}>
            <Icons.RightBlueArrow className="row pointer" />
          </div>
          <div className="col ml10">
            <div className="text primary-blue extra-large bold uppercase">
              {Localization.translator.bulkEdit}
            </div>
          </div>
        </div>
        <div className="full-height overflow-hidden">
          <View className="activity-bulk-editor">
            <EditingConfirm
              isShown={shouldShowConfirmModal}
              onHide={closeConfirm}
              onApply={handleEdit}
            >
              {confirmMessage}
              {shouldShowDataLinkingWarning && this.renderDataLinkingWarning()}
            </EditingConfirm>
            {this.renderFieldPickers()}
            <Content
              scrollable={true}
              className="relative pa10 no-outline-container inline-block"
              setRef={this.setRef}
            >
              {this.renderEditingFields()}
              {this.renderAddNewFieldHandleButton()}
            </Content>
            <Footer className="row pb10 pt12 px12">
              <ActionHandleButton
                title={actionHandleButtonTitle}
                onClick={prepareEditingData}
                isEnabled={canEdit}
              />
            </Footer>
          </View>
        </div>
      </div>
    )
  }

  private renderDataLinkingWarning() {
    return (
      <div className="row text large light pt12">
        <Icon icon="warning-sign" iconSize={16} className="warning-icon mr10" />
        {Localization.translator.activityDataLinking.bulkEditWarning}
      </div>
    )
  }

  private renderFieldSelector(index: number) {
    const { fieldOptions, editingFieldIds, getBulkEditField } = this.store

    const fieldId = editingFieldIds[index]
    const alreadySelectedField = getBulkEditField(fieldId)

    return (
      <div className="row px8 py10 relative">
        <StruxhubSelect
          label={Localization.translator.selectDataColumn}
          isRequiredTextHidden={true}
          value={fieldId}
          onChange={this.handleSelectEditedField.bind(null, index)}
        >
          <>
            <option className="hidden">{EMPTY_STRING}</option>
            {fieldOptions.map(field => (
              <option key={field.fieldId} value={field.fieldId}>
                {field.getCaption()}
              </option>
            ))}
            {alreadySelectedField && (
              <option
                key={alreadySelectedField.fieldId}
                value={alreadySelectedField.fieldId}
              >
                {alreadySelectedField.getCaption()}
              </option>
            )}
          </>
        </StruxhubSelect>
        <span className="badge index-label text large center bold">
          {index + 1}
        </span>
        {index > 0 && (
          <span
            className="badge remove-field row x-center pointer"
            onClick={this.removeEditedField.bind(null, index)}
          >
            <Icon icon={IconNames.CROSS} iconSize={CROSS_ICON_SIZE} />
          </span>
        )}
      </div>
    )
  }

  private renderFieldPickers(): JSX.Element {
    const { allowedLocationIds, activityFormStore } =
      this.props.activityDetailsStore

    const {
      selectedField,
      selectedLocationFieldValue,
      isLocationFieldSelected,
      isCompanyFieldSelected,
      isUserFieldSelected,
      restrictedLocationTypes,
      unsetSelectedField,
    } = activityFormStore

    return (
      <>
        {isLocationFieldSelected && (
          <LocationPicker
            className="absolute full-width full-height bg-white z-index-100"
            title={`${
              Localization.translator.select
            } ${selectedField.getCaption()}`}
            applyButtonTitle={Localization.translator.select}
            isSingleSelectionMode={true}
            selectedIds={selectedLocationFieldValue}
            restrictedObjectTypes={restrictedLocationTypes}
            allowedObjectIds={allowedLocationIds}
            onSingleSelect={this.changeFieldValue}
            onChange={NOOP}
            onApplyChanges={unsetSelectedField}
            onClose={unsetSelectedField}
          />
        )}
        {isCompanyFieldSelected && (
          <CompactCompaniesDirectory
            className="absolute full-width full-height bg-white z-index-100"
            isPhoneHidden={true}
            onCompanyRowClick={this.changeFieldValue}
            onHeaderCloseClick={unsetSelectedField}
          />
        )}
        {isUserFieldSelected && (
          <UsersDirectory
            className="absolute full-width full-height bg-white z-index-100"
            shouldSelectOnlyUsers={true}
            shouldHideFilterBar={true}
            shouldUseAllProjectMembers={true}
            onUserRowClick={this.changeFieldValue}
            onHeaderCloseClick={unsetSelectedField}
            searchTypes={userModalSearchTagTypes}
          />
        )}
      </>
    )
  }

  private changeFieldValue = ({ id }: { id: string }) => {
    this.props.activityDetailsStore.changeSelectedFieldValue(id)
  }

  private onSelectClick = (field: IActivityField) => () => {
    const { activityFormStore } = this.props.activityDetailsStore
    activityFormStore.setSelectedField(field)
  }

  private renderEditingFields() {
    const { isEditingFieldSelectedByIndex } = this.store

    return this.store.editingFieldIds.map((fieldId, i) => (
      <div className="ba-palette-brand-lightest brada4 px8 mb20" key={fieldId}>
        {this.renderFieldSelector(i)}
        {isEditingFieldSelectedByIndex(i) && this.renderControl(fieldId)}
      </div>
    ))
  }

  private renderControl(fieldId: string) {
    const { getBulkEditField } = this.store
    const { activityFormStore } = this.props.activityDetailsStore
    const { newActivityData } = activityFormStore

    const field = getBulkEditField(fieldId)
    const value = newActivityData[field.fieldId]

    if (field.type === ActivityFieldType.CompanySelect) {
      return (
        <div className="px8">
          <StruxhubCompanySelector
            label={field.getCaption()}
            isRequired={field.required}
            onClick={this.onSelectClick(field)}
            value={value}
          />
        </div>
      )
    }

    if (field.type === ActivityFieldType.UserSelect) {
      return (
        <div className="px8">
          <StruxhubUserSelector
            label={field.getCaption()}
            isRequired={field.required}
            onClick={this.onSelectClick(field)}
            value={value}
          />
        </div>
      )
    }

    return (
      <div className="px8">
        <StruxhubAttributeSelector
          label={field.getCaption()}
          isRequired={field.required}
          onClick={this.onSelectClick(field)}
          value={value}
        />
      </div>
    )
  }

  private renderAddNewFieldHandleButton() {
    if (!this.store.shouldShowNewHandleButton) {
      return null
    }

    return (
      <div
        className="row py10 px20 mt10 test center large add-field pointer brada4"
        onClick={this.handleAddNewEditingField}
      >
        {Localization.translator.addFieldForEditing}
        <Icon icon={IconNames.ADD} className="pl5 no-grow" />
      </div>
    )
  }

  private handleAddNewEditingField = () => {
    this.store.handleAddNewEditingField()
    this.scrollBottom()
  }

  private removeEditedField = (index: number) => {
    this.store.resetEditedField(index)
    this.scrollBottom()
  }

  private handleSelectEditedField = (index: number, event: any) => {
    this.store.resetEditedField(index)
    this.store.handleSelectEditedField(index, event.target.value)
    this.scrollBottom()
  }

  private scrollBottom() {
    Promise.resolve().then(() => {
      this.containerRef.scrollTop =
        this.containerRef.scrollHeight - this.containerRef.clientHeight
    })
  }

  private setRef = ref => (this.containerRef = ref)
}
