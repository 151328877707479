import * as React from 'react'

import './FilterHeader.scss'

interface IFilterHeader {
  clickOnClearAll?: () => void
  clickOnSelectAll: () => void
  className?: string
}

class FilterHeader extends React.Component<IFilterHeader> {
  public render() {
    const { clickOnClearAll, clickOnSelectAll, children, className } =
      this.props

    return (
      <div className={'filter-header ' + (className || '')}>
        {clickOnClearAll && (
          <button className="filter-header-btn" onClick={clickOnClearAll}>
            <span className="bp3-icon-standard bp3-icon-cross" />
            Clear All
          </button>
        )}
        <button className="filter-header-btn" onClick={clickOnSelectAll}>
          Select All
        </button>
        {children}
      </div>
    )
  }
}

export default FilterHeader
