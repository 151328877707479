import { CountriesType } from '../LanguageTreeType'

const countries: CountriesType = {
  Afghanistan: 'Afghanistan',
  'Åland Islands': 'Îles Åland',
  Albania: 'Albanie',
  Algeria: 'Algérie',
  'American Samoa': 'Samoa américaines',
  Andorra: 'Andorre',
  Angola: 'Angola',
  Anguilla: 'Anguilla',
  Antarctica: 'Antarctique',
  'Antigua and Barbuda': 'Antigua-et-Barbuda',
  Argentina: 'Argentine',
  Armenia: 'Arménie',
  Aruba: 'Aruba',
  Australia: 'Australie',
  Austria: 'Autriche',
  Azerbaijan: 'Azerbaïdjan',
  Bahamas: 'Bahamas',
  Bahrain: 'Bahreïn',
  Bangladesh: 'Bangladesh',
  Barbados: 'Barbade',
  Belarus: 'Biélorussie',
  Belgium: 'Belgique',
  Belize: 'Belize',
  Benin: 'Bénin',
  Bermuda: 'Bermudes',
  Bhutan: 'Bhoutan',
  Bolivia: 'Bolivie',
  'Bosnia and Herzegovina': 'Bosnie-Herzégovine',
  Botswana: 'Botswana',
  'Bouvet Island': 'Île Bouvet',
  Brazil: 'Brésil',
  'British Indian Ocean Territory': "Territoire britannique de l'océan Indien",
  'Brunei Darussalam': 'Brunei',
  Bulgaria: 'Bulgarie',
  'Burkina Faso': 'Burkina Faso',
  Burundi: 'Burundi',
  Cambodia: 'Cambodge',
  Cameroon: 'Cameroun',
  Canada: 'Canada',
  'Cape Verde': 'Cap-Vert',
  'Cayman Islands': 'Îles Caïmans',
  'Central African Republic': 'République centrafricaine',
  Chad: 'Tchad',
  Chile: 'Chili',
  China: 'Chine',
  'Christmas Island': 'Île Christmas',
  'Cocos (Keeling) Islands': 'Îles Cocos',
  Colombia: 'Colombie',
  Comoros: 'Comores',
  Congo: 'Congo',
  'Congo, Democratic Republic': 'République démocratique du Congo',
  'Cook Islands': 'Îles Cook',
  'Costa Rica': 'Costa Rica',
  "Cote D'Ivoire": "Côte d'Ivoire",
  Croatia: 'Croatie',
  Cuba: 'Cuba',
  Cyprus: 'Chypre',
  'Czech Republic': 'République tchèque',
  Denmark: 'Danemark',
  Djibouti: 'Djibouti',
  Dominica: 'Dominique',
  'Dominican Republic': 'République dominicaine',
  Ecuador: 'Équateur',
  Egypt: 'Égypte',
  'El Salvador': 'El Salvador',
  'Equatorial Guinea': 'Guinée équatoriale',
  Eritrea: 'Érythrée',
  Estonia: 'Estonie',
  Ethiopia: 'Éthiopie',
  'Falkland Islands (Malvinas)': 'Îles Malouines',
  'Faroe Islands': 'Îles Féroé',
  Fiji: 'Fidji',
  Finland: 'Finlande',
  France: 'France',
  'French Guiana': 'Guyane française',
  'French Polynesia': 'Polynésie française',
  'French Southern Territories': 'Terres australes et antarctiques françaises',
  Gabon: 'Gabon',
  Gambia: 'Gambie',
  Georgia: 'Géorgie',
  Germany: 'Allemagne',
  Ghana: 'Ghana',
  Gibraltar: 'Gibraltar',
  Greece: 'Grèce',
  Greenland: 'Groenland',
  Grenada: 'Grenade',
  Guadeloupe: 'Guadeloupe',
  Guam: 'Guam',
  Guatemala: 'Guatemala',
  Guernsey: 'Guernesey',
  Guinea: 'Guinée',
  'Guinea-Bissau': 'Guinée-Bissau',
  Guyana: 'Guyana',
  Haiti: 'Haïti',
  'Heard Island and Mcdonald Islands': 'Îles Heard et McDonald',
  'Holy See (Vatican City State)': 'Saint-Siège (État de la Cité du Vatican)',
  Honduras: 'Honduras',
  'Hong Kong': 'Hong Kong',
  Hungary: 'Hongrie',
  Iceland: 'Islande',
  India: 'Inde',
  Indonesia: 'Indonésie',
  Iran: 'Iran',
  Iraq: 'Irak',
  Ireland: 'Irlande',
  'Isle of Man': 'Île de Man',
  Israel: 'Israël',
  Italy: 'Italie',
  Jamaica: 'Jamaïque',
  Japan: 'Japon',
  Jersey: 'Jersey',
  Jordan: 'Jordanie',
  Kazakhstan: 'Kazakhstan',
  Kenya: 'Kenya',
  Kiribati: 'Kiribati',
  'Korea (North)': 'Corée du Nord',
  'Korea (South)': 'Corée du Sud',
  Kosovo: 'Kosovo',
  Kuwait: 'Koweït',
  Kyrgyzstan: 'Kirghizistan',
  Laos: 'Laos',
  Latvia: 'Lettonie',
  Lebanon: 'Liban',
  Lesotho: 'Lesotho',
  Liberia: 'Liberia',
  'Libyan Arab Jamahiriya': 'Libye',
  Liechtenstein: 'Liechtenstein',
  Lithuania: 'Lituanie',
  Luxembourg: 'Luxembourg',
  Macao: 'Macao',
  Macedonia: 'Macédoine',
  Madagascar: 'Madagascar',
  Malawi: 'Malawi',
  Malaysia: 'Malaisie',
  Maldives: 'Maldives',
  Mali: 'Mali',
  Malta: 'Malte',
  'Marshall Islands': 'Îles Marshall',
  Martinique: 'Martinique',
  Mauritania: 'Mauritanie',
  Mauritius: 'Maurice',
  Mayotte: 'Mayotte',
  Mexico: 'Mexique',
  Micronesia: 'Micronésie',
  Moldova: 'Moldavie',
  Monaco: 'Monaco',
  Mongolia: 'Mongolie',
  Montserrat: 'Montserrat',
  Morocco: 'Maroc',
  Mozambique: 'Mozambique',
  Myanmar: 'Myanmar',
  Namibia: 'Namibie',
  Nauru: 'Nauru',
  Nepal: 'Népal',
  Netherlands: 'Pays-Bas',
  'Netherlands Antilles': 'Antilles néerlandaises',
  'New Caledonia': 'Nouvelle-Calédonie',
  'New Zealand': 'Nouvelle-Zélande',
  Nicaragua: 'Nicaragua',
  Niger: 'Niger',
  Nigeria: 'Nigéria',
  Niue: 'Niue',
  'Norfolk Island': 'Île Norfolk',
  'Northern Mariana Islands': 'Îles Mariannes du Nord',
  Norway: 'Norvège',
  Oman: 'Oman',
  Pakistan: 'Pakistan',
  Palau: 'Palaos',
  'Palestinian Territory, Occupied': 'Territoire palestinien',
  Panama: 'Panama',
  'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
  Paraguay: 'Paraguay',
  Peru: 'Pérou',
  Philippines: 'Philippines',
  Pitcairn: 'Îles Pitcairn',
  Poland: 'Pologne',
  Portugal: 'Portugal',
  'Puerto Rico': 'Porto Rico',
  Qatar: 'Qatar',
  Reunion: 'Réunion',
  Romania: 'Roumanie',
  'Russian Federation': 'Fédération de Russie',
  Rwanda: 'Rwanda',
  'Saint Helena': 'Sainte-Hélène',
  'Saint Kitts and Nevis': 'Saint-Kitts-et-Nevis',
  'Saint Lucia': 'Sainte-Lucie',
  'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
  'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
  Samoa: 'Samoa',
  'San Marino': 'Saint-Marin',
  'Sao Tome and Principe': 'Sao Tomé-et-Principe',
  'Saudi Arabia': 'Arabie Saoudite',
  Senegal: 'Sénégal',
  Serbia: 'Serbie',
  Montenegro: 'Monténégro',
  Seychelles: 'Seychelles',
  'Sierra Leone': 'Sierra Leone',
  Singapore: 'Singapour',
  Slovakia: 'Slovaquie',
  Slovenia: 'Slovénie',
  'Solomon Islands': 'Îles Salomon',
  Somalia: 'Somalie',
  'South Africa': 'Afrique du Sud',
  'South Georgia and the South Sandwich Islands':
    'Géorgie du Sud et les îles Sandwich du Sud',
  Spain: 'Espagne',
  'Sri Lanka': 'Sri Lanka',
  Sudan: 'Soudan',
  Suriname: 'Suriname',
  'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
  Swaziland: 'Eswatini',
  Sweden: 'Suède',
  Switzerland: 'Suisse',
  'Syrian Arab Republic': 'République arabe syrienne',
  'Taiwan, Province of China': 'Taïwan',
  Tajikistan: 'Tadjikistan',
  Tanzania: 'Tanzanie',
  Thailand: 'Thaïlande',
  'Timor-Leste': 'Timor oriental',
  Togo: 'Togo',
  Tokelau: 'Tokelau',
  Tonga: 'Tonga',
  'Trinidad and Tobago': 'Trinité-et-Tobago',
  Tunisia: 'Tunisie',
  Turkey: 'Turquie',
  Turkmenistan: 'Turkménistan',
  'Turks and Caicos Islands': 'Îles Turques-et-Caïques',
  Tuvalu: 'Tuvalu',
  Uganda: 'Ouganda',
  Ukraine: 'Ukraine',
  'United Arab Emirates': 'Émirats arabes unis',
  'United Kingdom': 'Royaume-Uni',
  'United States': 'États-Unis',
  'United States Minor Outlying Islands':
    'Îles mineures éloignées des États-Unis',
  Uruguay: 'Uruguay',
  Uzbekistan: 'Ouzbékistan',
  Vanuatu: 'Vanuatu',
  Venezuela: 'Venezuela',
  'Viet Nam': 'Vietnam',
  'Virgin Islands, British': 'Îles Vierges britanniques',
  'Virgin Islands, U.S.': 'Îles Vierges des États-Unis',
  'Wallis and Futuna': 'Wallis-et-Futuna',
  'Western Sahara': 'Sahara Occidental',
  Yemen: 'Yémen',
  Zambia: 'Zambie',
  Zimbabwe: 'Zimbabwe',
}

export default countries
