import * as React from 'react'

import { inject, observer } from 'mobx-react'

import FilterHandleButton from '~/client/src/shared/components/Filters/components/FilterHandleButton/FilterHandleButton'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'

import DesktopInitialState from '../../../stores/DesktopInitialState'
import SaveFilterDialog from '../SavedFilters/components/SaveFilterDialog'
import CustomWofkflowFilterModal from './CustomWofkflowFilterModal'
import DesktopCustomWorkflowsFiltersStore from './DesktopCustomWorkflowsFilters.store'

import '../SavedFilters/SavedFilters.scss'

// localization: translated

interface IProps {
  store: DesktopCustomWorkflowsFiltersStore
  state?: DesktopInitialState
  forceClose?: boolean
}

@inject('state')
@observer
export default class CustomWorkflows extends React.Component<IProps> {
  public componentDidUpdate() {
    if (this.props.forceClose) {
      this.props.store.isShown = false
      this.props.store.shouldSaveFilterDialogShow = false
    }
  }

  public render() {
    const {
      editableFilterName,
      editableFilterIsPublic,
      saveCustomFilter,
      isShown,
      shouldSaveFilterDialogShow,
      toggleSavedFilters,
      closeSavedFilters,
      onFilterNameChange,
      onFilterIsPublicToggle,
      filtersCount,
      filtersDescriptions,
      editCustomFilter,
      selectCustomFilterById,
      customFilters,
      selectCustomFilter,
      getWorkflowsCountByCustomFilter,
    } = this.props.store

    const { appliedActivityPresetId } = this.props.state
    return (
      <div className="saved-filters no-grow">
        <FilterHandleButton
          className="saved-filters-handle-button"
          onClick={toggleSavedFilters}
          isSelected={isShown}
          isActive={!!appliedActivityPresetId}
        >
          <span>{Localization.translator.saved_deliveries}</span>
        </FilterHandleButton>

        {isShown && (
          <CustomWofkflowFilterModal
            desktopCustomWorkflowsFiltersStore={this.props.store}
            editCustomFilter={editCustomFilter}
            getWorkflowsCountByCustomFilter={getWorkflowsCountByCustomFilter}
            selectCustomFilterById={selectCustomFilterById}
            customFilters={customFilters}
            selectCustomFilter={selectCustomFilter}
          />
        )}
        {shouldSaveFilterDialogShow && (
          <SaveFilterDialog
            filterName={editableFilterName}
            isPublic={editableFilterIsPublic}
            toggleIsPublic={onFilterIsPublicToggle}
            entitiesCountByFilter={getWorkflowsCountByCustomFilter()}
            filtersCount={filtersCount}
            filtersDescriptions={filtersDescriptions}
            clickOnApply={saveCustomFilter}
            clickOnCancel={closeSavedFilters}
            onNameChange={onFilterNameChange}
            desktopSavedFiltersStore={this.props.store}
            xEntitiesTranslatorKey={KnownTranslatorKeys.xWorkflows}
          />
        )}
      </div>
    )
  }
}
