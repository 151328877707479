import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import StruxhubTextArea from '~/client/src/shared/components/StruxhubInputs/StruxhubTextArea'
import Keys from '~/client/src/shared/enums/Keys'

import BaseInstructionsConfiguratorStore from './BaseInstructionsConfigurator.store'

interface IProps<T> {
  instructionText: string
  instructionIndex: number
  store: BaseInstructionsConfiguratorStore<T>
}

const ICON_SIZE = 16

@observer
export default class InstructionTextHolder<T> extends React.Component<
  IProps<T>
> {
  private get store(): BaseInstructionsConfiguratorStore<T> {
    return this.props.store
  }

  public render() {
    const { instructionText, instructionIndex } = this.props
    const {
      editingInstructionIndex,
      setEditingInstruction,
      applyInstructionTextChange,
    } = this.store
    const isInEditingMode = editingInstructionIndex === instructionIndex

    return (
      <div className="row y-start" onBlur={applyInstructionTextChange}>
        <Icon
          icon={IconNames.EDIT}
          size={ICON_SIZE}
          className={classList({
            'mr10 no-grow vertical-align-middle': true,
            'text blue pt5': isInEditingMode,
          })}
          onMouseDownCapture={this.onEditClick}
        />
        {isInEditingMode ? (
          <StruxhubTextArea
            className="mr5"
            isMinimalisticMode={true}
            isHelperTextCondensed={true}
            onChange={this.handleInstructionTextUpdate}
            onKeyPress={this.handleTextareaKeyPress}
            defaultValue={instructionText}
            autoFocus={true}
          />
        ) : (
          <span
            onDoubleClick={setEditingInstruction.bind(null, instructionIndex)}
            className="text large mr5"
          >
            {instructionText}
          </span>
        )}
      </div>
    )
  }

  private handleInstructionTextUpdate = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    this.store.updateInstructionTextByIndex(
      event.target.value,
      this.props.instructionIndex,
    )
  }

  private handleTextareaKeyPress = (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (event.key === Keys.Enter && !event.shiftKey) {
      this.store.applyInstructionTextChange()
    }
  }

  private onEditClick = (event: React.SyntheticEvent) => {
    event.stopPropagation()
    event.preventDefault()

    const { instructionIndex } = this.props
    const {
      editingInstructionIndex,
      setEditingInstruction,
      applyInstructionTextChange,
    } = this.store

    if (editingInstructionIndex === instructionIndex) {
      applyInstructionTextChange()
    } else {
      setEditingInstruction(instructionIndex)
    }
  }
}
