import { TagType } from '../enums/TagType'
import UserFieldId from '../enums/UserFieldId'

const fieldIdToTagTypeMap = {
  [UserFieldId.Company]: TagType.Company,
  [UserFieldId.Teams]: TagType.Team,
  [UserFieldId.Trades]: TagType.Trade,
  [UserFieldId.DefaultTeams]: TagType.DefaultTeam,
  [UserFieldId.Roles]: TagType.Role,
  [UserFieldId.AccountPosition]: TagType.AccountPosition,
}

export default fieldIdToTagTypeMap
