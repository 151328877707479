import * as React from 'react'

import { Circle, Group, Path } from 'react-konva'

import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import { LOGISTICS_PATH } from '../LocationPaths'

import Colors from '~/client/src/shared/theme.module.scss'

const ICON_SIZE = 20

const SHADOW_BLUR = 2
const SHADOW_OFFSET = { x: 0, y: 0 }
const SHADOW_OPACITY = 0.3

const STROKE_WIDTH = 1

const OFFSET_X = -3
const OFFSET_Y = -2
const PATH_SCALE = 0.6
const { getHEXColor } = ThemeMode

const KonvaLogisticsObject = ({ color, children, ...rest }) => {
  const halfSize = ICON_SIZE / 2
  return (
    <Group {...rest} offsetX={halfSize} offsetY={halfSize}>
      {children}
      <Circle
        radius={halfSize}
        x={halfSize}
        y={halfSize}
        fill={getHEXColor(color)}
        stroke={getHEXColor(Colors.neutral100)}
        strokeWidth={STROKE_WIDTH}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
      <Path
        data={LOGISTICS_PATH}
        fill={getHEXColor(Colors.neutral100)}
        scaleX={PATH_SCALE}
        scaleY={PATH_SCALE}
        offsetX={OFFSET_X}
        offsetY={OFFSET_Y}
        shadowColor={getHEXColor(Colors.neutral0)}
        shadowBlur={SHADOW_BLUR}
        shadowOffset={SHADOW_OFFSET}
        shadowOpacity={SHADOW_OPACITY}
      />
    </Group>
  )
}

export default KonvaLogisticsObject
