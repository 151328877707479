import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IActivityFilterFieldsFragment = Pick<
  Types.IActivityListCustomFilter,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'version'
  | 'projectId'
  | 'name'
  | 'userId'
  | 'isPublic'
  | 'appliedCategories'
  | 'statusFilterMode'
> & {
  filtersByFilterType?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IActivityFilter, 'type' | 'values'>>>
  >
  recentlyUpdatedSavedFilter?: Types.Maybe<
    Pick<Types.IRecentlyUpdatedSavedFilter, 'mode' | 'startDate' | 'endDate'>
  >
}

export type IGetActivityCustomFiltersByProjectQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetActivityCustomFiltersByProjectQuery = {
  activityListCustomFilters?: Types.Maybe<{
    data: Array<IActivityFilterFieldsFragment>
  }>
}

export type ISaveActivityCustomFilterMutationVariables = Types.Exact<{
  filter: Types.IActivityListCustomFilterInput
}>

export type ISaveActivityCustomFilterMutation = {
  saveActivityListCustomFilter?: Types.Maybe<IActivityFilterFieldsFragment>
}

export type IDeleteActivityCustomFilterMutationVariables = Types.Exact<{
  filterId: Types.Scalars['ObjectId']
}>

export type IDeleteActivityCustomFilterMutation = Pick<
  Types.IMutation,
  'deleteActivityListCustomFilter'
>

export const ActivityFilterFieldsFragmentDoc = gql`
  fragment ActivityFilterFields on ActivityListCustomFilter {
    id
    createdAt
    updatedAt
    version
    projectId
    name
    userId
    isPublic
    filtersByFilterType {
      type
      values
    }
    appliedCategories
    recentlyUpdatedSavedFilter {
      mode
      startDate
      endDate
    }
    statusFilterMode
  }
`
export const GetActivityCustomFiltersByProjectDocument = gql`
  query GetActivityCustomFiltersByProject($projectId: ObjectId!) {
    activityListCustomFilters(projectId: $projectId) {
      data {
        ...ActivityFilterFields
      }
    }
  }
  ${ActivityFilterFieldsFragmentDoc}
`

/**
 * __useGetActivityCustomFiltersByProjectQuery__
 *
 * To run a query within a React component, call `useGetActivityCustomFiltersByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityCustomFiltersByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityCustomFiltersByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetActivityCustomFiltersByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetActivityCustomFiltersByProjectQuery,
    IGetActivityCustomFiltersByProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetActivityCustomFiltersByProjectQuery,
    IGetActivityCustomFiltersByProjectQueryVariables
  >(GetActivityCustomFiltersByProjectDocument, options)
}
export function useGetActivityCustomFiltersByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetActivityCustomFiltersByProjectQuery,
    IGetActivityCustomFiltersByProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetActivityCustomFiltersByProjectQuery,
    IGetActivityCustomFiltersByProjectQueryVariables
  >(GetActivityCustomFiltersByProjectDocument, options)
}
export type GetActivityCustomFiltersByProjectQueryHookResult = ReturnType<
  typeof useGetActivityCustomFiltersByProjectQuery
>
export type GetActivityCustomFiltersByProjectLazyQueryHookResult = ReturnType<
  typeof useGetActivityCustomFiltersByProjectLazyQuery
>
export type GetActivityCustomFiltersByProjectQueryResult = Apollo.QueryResult<
  IGetActivityCustomFiltersByProjectQuery,
  IGetActivityCustomFiltersByProjectQueryVariables
>
export const SaveActivityCustomFilterDocument = gql`
  mutation SaveActivityCustomFilter($filter: ActivityListCustomFilterInput!) {
    saveActivityListCustomFilter(activityListCustomFilter: $filter) {
      ...ActivityFilterFields
    }
  }
  ${ActivityFilterFieldsFragmentDoc}
`
export type ISaveActivityCustomFilterMutationFn = Apollo.MutationFunction<
  ISaveActivityCustomFilterMutation,
  ISaveActivityCustomFilterMutationVariables
>

/**
 * __useSaveActivityCustomFilterMutation__
 *
 * To run a mutation, you first call `useSaveActivityCustomFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveActivityCustomFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveActivityCustomFilterMutation, { data, loading, error }] = useSaveActivityCustomFilterMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSaveActivityCustomFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveActivityCustomFilterMutation,
    ISaveActivityCustomFilterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveActivityCustomFilterMutation,
    ISaveActivityCustomFilterMutationVariables
  >(SaveActivityCustomFilterDocument, options)
}
export type SaveActivityCustomFilterMutationHookResult = ReturnType<
  typeof useSaveActivityCustomFilterMutation
>
export type SaveActivityCustomFilterMutationResult =
  Apollo.MutationResult<ISaveActivityCustomFilterMutation>
export type SaveActivityCustomFilterMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveActivityCustomFilterMutation,
    ISaveActivityCustomFilterMutationVariables
  >
export const DeleteActivityCustomFilterDocument = gql`
  mutation DeleteActivityCustomFilter($filterId: ObjectId!) {
    deleteActivityListCustomFilter(id: $filterId)
  }
`
export type IDeleteActivityCustomFilterMutationFn = Apollo.MutationFunction<
  IDeleteActivityCustomFilterMutation,
  IDeleteActivityCustomFilterMutationVariables
>

/**
 * __useDeleteActivityCustomFilterMutation__
 *
 * To run a mutation, you first call `useDeleteActivityCustomFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityCustomFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityCustomFilterMutation, { data, loading, error }] = useDeleteActivityCustomFilterMutation({
 *   variables: {
 *      filterId: // value for 'filterId'
 *   },
 * });
 */
export function useDeleteActivityCustomFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteActivityCustomFilterMutation,
    IDeleteActivityCustomFilterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteActivityCustomFilterMutation,
    IDeleteActivityCustomFilterMutationVariables
  >(DeleteActivityCustomFilterDocument, options)
}
export type DeleteActivityCustomFilterMutationHookResult = ReturnType<
  typeof useDeleteActivityCustomFilterMutation
>
export type DeleteActivityCustomFilterMutationResult =
  Apollo.MutationResult<IDeleteActivityCustomFilterMutation>
export type DeleteActivityCustomFilterMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteActivityCustomFilterMutation,
    IDeleteActivityCustomFilterMutationVariables
  >
