import * as React from 'react'

import { Position, Tooltip } from '@blueprintjs/core'
import { IReactionDisposer, action, reaction } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList, toggleClass } from 'react-classlist-helper'

import { DeliveryStatus } from '~/client/graph'
import DeliveryLog from '~/client/src/shared/components/DeliveryLog/DeliveryLog'
import Dimmer from '~/client/src/shared/components/Dimmer'
import * as Icons from '~/client/src/shared/components/Icons'
import {
  Content,
  Footer,
  Header,
  View,
} from '~/client/src/shared/components/Layout'
import Spinner from '~/client/src/shared/components/Spinner/Spinner'
import Tabs, { ITabObject } from '~/client/src/shared/components/Tabs/Tabs'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import InitialState from '~/client/src/shared/stores/InitialState'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import PhotosStore from '~/client/src/shared/stores/domain/Photos.store'
import ThreadsStore from '~/client/src/shared/stores/domain/ThreadsStore/Threads.store'

import ConstraintsStore from '../../stores/domain/Constraints.store'
import DeliveriesStore from '../../stores/domain/Deliveries.store'
import DeliveryAssignmentsStore from '../../stores/domain/DeliveryAssignments.store'
import DeliveryFollowingsStore from '../../stores/domain/DeliveryFollowings.store'
import DeliveryStatusChangesStore from '../../stores/domain/DeliveryStatusChanges.store'
import RecurringDeliveriesSettingsStore from '../../stores/domain/RecurringDeliveriesSettings.store'
import WeatherForecastsStore from '../../stores/domain/WeatherForecasts.store'
import CommonStore from '../../stores/ui/Common.store'
import DeliveryActionBarStore from '../../stores/ui/DeliveryActionBar.store'
import { NOOP } from '../../utils/noop'
import { NEW_DELIVERY_PATH_KEYWORD } from '../../utils/usefulStrings'
import ActionBar from '../ActionBar/ActionBar'
import CompactActionsMenuModal from '../CompactActionsMenuModal/CompactActionsMenuModal'
import DeliveryCompactHeaderBar from '../CompactHeaderBar/components/DeliveryCompactHeaderBar'
import ConcreteDirectOrderSummary from '../ConcreteDirectSummary/ConcreteDirectOrderSummary/ConcreteDirectOrderSummary'
import ConcreteDirectPayloadSummary from '../ConcreteDirectSummary/ConcreteDirectPayloadSummary/ConcreteDirectPayloadSummary'
import DeliveryStatusSelector from '../DeliveryStatusSelector/DeliveryStatusSelector'
import EntityDateMoveConfirm from '../EntityDateMoveConfirm/EntityDateMoveConfirm'
import FileInputBase from '../FileInput/FileInput'
import NonWorkTimeConfirmDialog from '../NonWorkTimeConfirmDialog/NonWorkTimeConfirmDialog'
import MapViewsGallery from '../SitemapsGallery/MapViewsGallery'
import DeliveryDetailsStore, {
  DeliveryDetailsTab,
} from './DeliveryDetails.store'
import DeliveryActionButtons from './components/DeliveryActionButtons'
import DeliveryChangeDateConfirm from './components/DeliveryChangeDateConfirm/DeliveryChangeDateConfirm'
import DeliveryAddVendorTagConfirm from './components/DeliveryConfirm/DeliveryAddVendorTagConfirm'
import DeliveryCancelConfirm from './components/DeliveryConfirm/DeliveryCancelConfirm'
import DeliveryDeleteConfirm from './components/DeliveryConfirm/DeliveryDeleteConfirm'
import DeliveryRecurringEditConfirm from './components/DeliveryConfirm/DeliveryRecurringEditConfirm'
import DeliveryForm from './components/DeliveryForm'
import DeliverySelectDateModal from './components/DeliverySelectDateModal/DeliverySelectDateModal'
import DeliverySelectModal from './components/DeliverySelectModal/DeliverySelectModal'
import DeliverySelectTimeModal from './components/DeliverySelectTimeModal/DeliverySelectTimeModal'
import DeliveryWorkflowMoreMenu from './components/DeliveryWorkflowMoreMenu/DeliveryWorkflowMoreMenu'

import './DeliveryDetails.scss'

// translated

interface IDeliveryDetails {
  hideHeader?: boolean
  backClicked?: () => void
  deliveryDetailsStore?: DeliveryDetailsStore
  onDidMount?: () => void
  initProjectId: string
  FileInputType: typeof FileInputBase
  displayedDeliveryId?: string
  onCreationCb?: (deliveryId: string) => void
  deliveriesStore?: DeliveriesStore

  common?: CommonStore
  threadsStore?: ThreadsStore
  eventsStore?: EventsStore
  messagesStore?: MessagesStore
  photosStore?: PhotosStore
  constraintsStore?: ConstraintsStore
  deliveryStatusChangesStore?: DeliveryStatusChangesStore
  weatherForecastsStore?: WeatherForecastsStore
  recurringDeliveriesSettingsStore?: RecurringDeliveriesSettingsStore
  deliveryFollowingsStore?: DeliveryFollowingsStore
  deliveryAssignmentsStore?: DeliveryAssignmentsStore
  isMobileMode?: boolean
}

@inject(
  'deliveriesStore',
  'deliveryDetailsStore',
  'eventsStore',
  'common',
  'eventsStore',
  'messagesStore',
  'threadsStore',
  'photosStore',
  'constraintsStore',
  'deliveryStatusChangesStore',
  'weatherForecastsStore',
  'recurringDeliveriesSettingsStore',
  'deliveryFollowingsStore',
  'deliveryAssignmentsStore',
)
@observer
export default class DeliveryDetails extends React.Component<IDeliveryDetails> {
  private readonly formId = 'delivery-details-form'
  private containerRef: HTMLDivElement
  private disposeRecurringSettingUpdateReaction: IReactionDisposer

  private get store(): DeliveryDetailsStore {
    return this.props.deliveryDetailsStore
  }

  private get deliveryActionBarStore(): DeliveryActionBarStore {
    return this.store.deliveryActionBarStore
  }

  private get isActivityMode() {
    return this.props.eventsStore.appState.delivery.compactView.isActivityMode
  }

  private get appState(): InitialState {
    return this.props.eventsStore.appState
  }

  public componentDidMount() {
    this.ensureDisplayedDeliveryIsSetCorrectly()

    const { deliveryStatusChangesStore, constraintsStore } = this.props
    const { listenToDeliveryStatusChanges } = deliveryStatusChangesStore
    const { loadConstraints, listenToConstraints } = constraintsStore
    const {
      displayedDelivery,
      checkIfDeliveryAttributesValid,
      updateRecurringOptions,
      loadRecurringDeliveriesSettings,
      loadAndListenCdData,
      isCreateModeActive,
    } = this.store

    this.resetRecurringData()

    loadConstraints().then(() =>
      checkIfDeliveryAttributesValid(isCreateModeActive),
    )
    if (displayedDelivery) {
      loadRecurringDeliveriesSettings()
      this.loadDeliveriesStatusChanges(displayedDelivery?.id)
      loadAndListenCdData()
    }

    listenToConstraints(checkIfDeliveryAttributesValid)
    listenToDeliveryStatusChanges()

    this.disposeRecurringSettingUpdateReaction = reaction(
      () => this.store.existingRecurringSetting,
      updateRecurringOptions,
      { fireImmediately: true },
    )

    this.reinitFieldCategoryCollapsingMap()
  }

  public componentDidUpdate(prevProps: IDeliveryDetails) {
    const { constraintsStore, displayedDeliveryId } = this.props

    // force re-listen delivery stuff if project has been switched
    if (prevProps.initProjectId !== this.appState.activeProject.id) {
      const { loadConstraints, listenToConstraints } = constraintsStore
      loadConstraints().then(() =>
        this.store.checkIfDeliveryAttributesValid(
          this.store.isCreateModeActive,
        ),
      )
      listenToConstraints(this.store.checkIfDeliveryAttributesValid)

      this.resetRecurringData()
      this.reinitFieldCategoryCollapsingMap()
    }

    const isDeliveryChanged =
      prevProps.displayedDeliveryId !== displayedDeliveryId

    if (isDeliveryChanged) {
      this.resetRecurringData()
      this.store.terminateCdData()

      this.store.loadRecurringDeliveriesSettings()
      this.store.hideWorkflowModal()
      this.loadDeliveriesStatusChanges(displayedDeliveryId)
      this.store.loadAndListenCdData()
    } else if (this.store.shouldScrollToBottom) {
      this.prepareUI()
    }
  }

  public componentWillUnmount() {
    const { deliveryStatusChangesStore, constraintsStore } = this.props

    constraintsStore.dontListenToConstraints()
    this.store.resetToDefaultMode()
    deliveryStatusChangesStore.dontListenToDeliveryStatusChanges()
    this.store.terminateCdData()

    this.disposeRecurringSettingUpdateReaction?.()
  }

  public get shouldRenderLoader() {
    const { isLoading, loading } = this.appState

    return (
      isLoading &&
      !loading.get(e.LOAD_DELIVERY_STATUS_CHANGES) &&
      !loading.get(e.SAVE_DELIVERY_ATTRIBUTES) &&
      !loading.get(e.SAVE_MATERIAL) &&
      !loading.get(e.GET_RECURRING_DELIVERIES_SETTING)
    )
  }

  public render() {
    const {
      isDateChangeConfirmModalOpen,
      isDateMoveConfirmModalOpen,
      toggleDeleteConfirmModal,
      toggleCancelConfirmModal,
      toggleWorkflowModal,
      isWorkflowModalOpen,
      deliveryConfigurations,
      isDeleteConfirmModalOpen,
      isCancelConfirmModalOpen,
      deleteDelivery,
      closeDateChangeConfirmModal,
      closeNonWorkTimeModal,
      closeSelectDateModal,
      isNonWorkTimesModalOpened,
      isAddVendorTagModalOpened,
      nonWorkTimeAndDeadlineTitle,
      nonWorkTimeAndDeadlineMessage,
      shouldBlockOnNonWorkTimes,
      shouldBlockLateRequesting,
      isEditRecurringDialogShown,
      recurringDialogApplyFn,
      recurringDialogTitle,
      isSelectDateModalOpen,
      isRecurringDateModalOpened,
      selectedTimeControl,
      disableTimeControlSelection,
      confirmAddVendorTag,
      closeAddVendorTagModal,
      displayedDelivery,
      isCancelationReasonHidden,
      isCancelationReasonMandatory,
      dateSelectorStartDate,
      dateSelectorEndDate,
      getCompanyById,
      selectedVendor,
    } = this.store

    const { hideMenuPopup, isMenuPopupShown } = this.deliveryActionBarStore
    const shouldShowChangeDateModal =
      !isNonWorkTimesModalOpened && isDateChangeConfirmModalOpen

    return (
      <View className="delivery-details-view">
        <Header className="relative">
          <Dimmer shown={isMenuPopupShown} onClick={hideMenuPopup} />
          {this.renderHeader()}
        </Header>
        {this.globesGallery}
        <DeliveryCancelConfirm
          isShown={isCancelConfirmModalOpen}
          isReasonHidden={isCancelationReasonHidden}
          isReasonMandatory={isCancelationReasonMandatory}
          onHide={toggleCancelConfirmModal}
          onApply={this.cancelDelivery}
        />
        <DeliveryDeleteConfirm
          isShown={isDeleteConfirmModalOpen}
          onHide={toggleDeleteConfirmModal}
          onApply={deleteDelivery}
        />
        <DeliveryAddVendorTagConfirm
          isShown={isAddVendorTagModalOpened}
          onApply={confirmAddVendorTag}
          onHide={closeAddVendorTagModal}
          vendorId={selectedVendor}
          getCompanyById={getCompanyById}
        />
        {displayedDelivery && (
          <DeliveryWorkflowMoreMenu
            isShown={isWorkflowModalOpen}
            onHide={toggleWorkflowModal}
            deliveryDetailsStore={this.store}
          />
        )}
        <NonWorkTimeConfirmDialog
          isShown={isNonWorkTimesModalOpened}
          onHide={this.onHideModal.bind(this, closeNonWorkTimeModal)}
          onApply={this.onApplyNonWorkTimesModal}
          shouldBlockOnNonWorkTimes={
            shouldBlockOnNonWorkTimes || shouldBlockLateRequesting
          }
          entityName={Localization.translator.delivery}
          title={nonWorkTimeAndDeadlineTitle}
          message={nonWorkTimeAndDeadlineMessage}
        />
        <DeliveryChangeDateConfirm
          isShown={shouldShowChangeDateModal}
          onHide={this.onHideModal.bind(this, closeDateChangeConfirmModal)}
          onApply={this.onApplyConfirmModal}
          deliveryConfigurations={deliveryConfigurations}
        />
        <EntityDateMoveConfirm
          isShown={isDateMoveConfirmModalOpen}
          title={Localization.translator.updateXDeliveries(1)}
          onHide={this.hideMoveDeliveryDatesModal}
          onApply={this.modeDeliveryDates}
        />
        <DeliveryRecurringEditConfirm
          title={recurringDialogTitle}
          isShown={isEditRecurringDialogShown}
          onApply={recurringDialogApplyFn}
          store={this.store}
        />
        <DeliverySelectDateModal
          isShown={isSelectDateModalOpen}
          isRecurringDateMode={isRecurringDateModalOpened}
          onHide={closeSelectDateModal}
          initStartDate={dateSelectorStartDate}
          initEndDate={dateSelectorEndDate}
          deliveryDetailsStore={this.store}
        />
        <DeliverySelectTimeModal
          shown={!!selectedTimeControl}
          onHide={disableTimeControlSelection}
          deliveryDetailsStore={this.store}
        />
        <DeliverySelectModal store={this.store} />
        <Content className="relative">
          {this.renderOpacityBackground(this.shouldRenderLoader)}
          <Content className="y-scroll-hidden">{this.renderContent()}</Content>
        </Content>
        {!isMenuPopupShown && (
          <Footer>
            {this.renderOpacityBackground(
              this.appState.loading.get(e.LOAD_DELIVERY_STATUS_CHANGES),
            )}
            {this.renderFooter()}
          </Footer>
        )}
      </View>
    )
  }

  @action.bound
  public backClicked() {
    if (this.props.backClicked) {
      return this.props.backClicked()
    }

    const { selectedActivity, toActivityDetails } = this.props.common

    if (selectedActivity && this.isActivityMode) {
      toActivityDetails()
      return
    }

    this.props.common.openDeliveryView()
  }

  public renderHeader() {
    const { companiesStore, displayedDelivery, isFromConcreteDirect } =
      this.store
    const { showMenuPopup } = this.deliveryActionBarStore

    return (
      !this.props.hideHeader && (
        <DeliveryCompactHeaderBar
          delivery={displayedDelivery}
          isConcreteDirectOrder={!!isFromConcreteDirect}
          companiesStore={companiesStore}
          onCloseClick={this.backClicked}
          showMenuPopup={!isFromConcreteDirect && showMenuPopup}
        />
      )
    )
  }

  public renderContent() {
    const { isDeliveryDeleted, activeDetailsTab } = this.store
    const { isMenuPopupShown } = this.deliveryActionBarStore

    return (
      <div
        className={classList({
          'delivery-details-content full-height': true,
          'inactive-element': isDeliveryDeleted,
        })}
      >
        <Tabs
          tabObjects={this.deliveryDetailTabs}
          activeTabId={activeDetailsTab}
          onTabClick={this.switchActiveTab}
          isFlexible={true}
          className="delivery-details-tabs y-end bg-palette-brand-lightest"
          tabClassName="tab relative bg-palette-brand-lightest bb-palette-brand-lighter"
          tabTextClassName="tab-text text light large bold lp015 center"
        />

        {activeDetailsTab === DeliveryDetailsTab.Form
          ? this.renderDeliveryForm()
          : this.renderDeliveryLog()}
        {isMenuPopupShown && this.renderMenuPopUp()}
      </div>
    )
  }

  private get deliveryDetailTabs(): Array<ITabObject<DeliveryDetailsTab>> {
    const { isCreateModeActive } = this.store

    return [
      {
        title: Localization.translator.form,
        page: DeliveryDetailsTab.Form,
      },
      {
        title: Localization.translator.log,
        page: DeliveryDetailsTab.Log,
        className: isCreateModeActive && 'inactive-element',
      },
    ]
  }

  public renderDeliveryForm() {
    const {
      canDuplicateDelivery,
      canCreateFromToDelivery,
      duplicateDelivery,
      createFromToDelivery,
      toggleWorkflowModal,
      displayedDelivery,
      canChangeDeliveryInBookingDeadline,
      canUserInteractWithFields,
      shouldShowMoreMenu,
    } = this.store

    return (
      <Content
        className="relative scrollable full-height"
        setRef={this.setContainerRef}
      >
        <div className="bb-cool-grey">
          <div className="row x-between pl16 bb-light-cool-grey z-index-5 top0 sticky bg-white">
            {displayedDelivery?.recurringSettingId && (
              <div className="text bold large row y-center">
                <Icons.Repeat className="inline-block no-outline no-grow mr8 pt4" />
                {Localization.translator.recurringDelivery}
              </div>
            )}
            <div className="row x-end py2">
              {canDuplicateDelivery && (
                <Tooltip
                  className="duplicate-delivery-wrapper inline-block no-grow mr10 my3"
                  targetClassName="duplicate-delivery-btn brada3"
                  content={Localization.translator.duplicate_verb}
                  position={Position.BOTTOM}
                >
                  <Icons.CopyFile
                    className="inline-block pointer no-outline"
                    onClick={duplicateDelivery}
                  />
                </Tooltip>
              )}
              {canCreateFromToDelivery && (
                <Tooltip
                  className="duplicate-delivery-wrapper inline-block no-grow mr10 my3"
                  targetClassName="duplicate-delivery-btn brada3"
                  content={Localization.translator.createFromToDelivery}
                  position={Position.BOTTOM}
                >
                  <Icons.FromToDelivery
                    className="inline-block pointer no-outline"
                    onClick={createFromToDelivery}
                  />
                </Tooltip>
              )}
              {shouldShowMoreMenu && (
                <Tooltip
                  className="inline-block no-grow mr10 my3"
                  targetClassName="brada3"
                  content={Localization.translator.workflowMenu}
                  position={Position.BOTTOM}
                >
                  <Icons.ThreeDots
                    className="no-grow pointer"
                    onClick={toggleWorkflowModal}
                  />
                </Tooltip>
              )}
            </div>
          </div>
          <div
            className={toggleClass(
              'unclickable-element opacity7',
              !canUserInteractWithFields,
            )}
          >
            <DeliveryForm
              formId={this.formId}
              store={this.store}
              FileInputType={this.props.FileInputType}
              shouldDisableInnerForm={!canChangeDeliveryInBookingDeadline}
            />
          </div>
        </div>
      </Content>
    )
  }

  public renderFooter() {
    const { deliveryFollowingsStore, deliveryAssignmentsStore, isMobileMode } =
      this.props
    const {
      displayedDelivery,
      isDeliveryDeleted,
      areFieldsChanged,
      isFromConcreteDirect,
    } = this.store

    if (isDeliveryDeleted) {
      return null
    }

    const { userActiveProjectSettings } = this.appState
    const shouldShowActionBtns =
      !isFromConcreteDirect ||
      (areFieldsChanged && displayedDelivery?.canChange) ||
      displayedDelivery?.isPending

    return (
      <div
        className={classList({
          'delivery-details-footer': true,
          'inactive-element': userActiveProjectSettings?.isPresentationUser,
        })}
      >
        {displayedDelivery ? (
          <ActionBar
            renderStatusSelectMenu={this.renderMenuPopUp}
            applyStatusAndStep={this.setSelectedStatus}
            store={this.deliveryActionBarStore}
            entity={displayedDelivery}
            toggleStatusSelectMenu={this.deliveryActionBarStore.showMenuPopup}
            renderButtons={this.renderButtons}
            isReadOnly={!shouldShowActionBtns}
            isMobileMode={isMobileMode}
            assignmentsStore={deliveryAssignmentsStore}
            followingsStore={deliveryFollowingsStore}
            shouldShowStatusSelector={
              !!displayedDelivery && !isFromConcreteDirect
            }
            applyMessage={this.applyMessage}
          />
        ) : (
          <DeliveryActionButtons
            onCreationCb={this.props.onCreationCb}
            deliveryActionBarStore={this.deliveryActionBarStore}
            className="bt-light-grey px10 py15"
          />
        )}
      </div>
    )
  }
  private renderButtons = (
    render: (
      btnText: string,
      isValid: boolean,
      status: DeliveryStatus,
      isDeny?: boolean,
      customBtnHandler?: () => void,
    ) => JSX.Element,
  ) => {
    return (
      <DeliveryActionButtons
        onCreationCb={this.props.onCreationCb}
        deliveryActionBarStore={this.deliveryActionBarStore}
        buttonRenderer={render}
      />
    )
  }

  private renderDeliveryLog(): JSX.Element {
    const { FileInputType, weatherForecastsStore } = this.props
    const { displayedDelivery } = this.store

    const isDeliveryThreadShown =
      !!displayedDelivery && !weatherForecastsStore.isLoading

    return (
      <Content
        className="relative scrollable full-height"
        setRef={this.setContainerRef}
      >
        {this.concreteDirectSummaryInfo}
        <div className="delivery-log-section activity-log">
          {isDeliveryThreadShown && (
            <DeliveryLog
              delivery={displayedDelivery}
              FileInputType={FileInputType}
            />
          )}
        </div>
      </Content>
    )
  }

  private renderOpacityBackground(shouldRenderLoader?: boolean) {
    if (this.appState.isLoading) {
      return (
        <div className="loader-holder full-height full-width">
          {shouldRenderLoader && <Spinner className="loader" size={50} />}
        </div>
      )
    }
  }

  private prepareUI() {
    const { onDidMount } = this.props
    const {
      openNonWorkTimeModalIfNeed,
      openDateChangeConfirmModalIfNeed,
      isViewModeActive,
      shouldScrollToBottom,
      activeDetailsTab,
    } = this.store

    const shouldScrollDown =
      (isViewModeActive && activeDetailsTab === DeliveryDetailsTab.Log) ||
      shouldScrollToBottom

    if (onDidMount) {
      onDidMount()
    }

    openNonWorkTimeModalIfNeed()
    openDateChangeConfirmModalIfNeed()

    Promise.resolve().then(() => {
      this.setScroll(shouldScrollDown)
    })
  }

  private renderMenuPopUp = () => {
    const { isMenuPopupShown, hideMenuPopup } = this.deliveryActionBarStore

    return (
      <>
        <CompactActionsMenuModal
          isShown={isMenuPopupShown}
          onHide={hideMenuPopup}
          headerText={Localization.translator.updateStatus}
        >
          <DeliveryStatusSelector
            onClose={hideMenuPopup}
            deliveryDetailsStore={this.store}
            displayedDelivery={this.store.displayedDelivery}
            isMenuPopupShown={isMenuPopupShown}
            setSelectedStatus={this.setSelectedStatus}
            selectedStatus={this.store.displayedDelivery.status}
          />
        </CompactActionsMenuModal>
      </>
    )
  }

  private get globesGallery() {
    const { FileInputType } = this.props
    const { isGlobesGalleryShown, hideSitemapsGallery } = this.store

    return (
      isGlobesGalleryShown && (
        <MapViewsGallery
          selectedGlobeViewId=""
          selectedWhiteboardId=""
          mapIds={this.appState.deliveriesMapIdsList}
          isHideButtonDisplayed={true}
          onHide={hideSitemapsGallery}
          eventName={e.SAVE_DELIVERY_CONFIGURATIONS}
          shouldUseFullHeight={true}
          FileInputType={FileInputType}
          selectGlobe={NOOP}
          selectSitemap={NOOP}
        />
      )
    )
  }

  private get concreteDirectSummaryInfo(): JSX.Element {
    const { isFromConcreteDirect, selectedConcreteDirectOrder, orderPayloads } =
      this.store

    if (!isFromConcreteDirect || !selectedConcreteDirectOrder) {
      return null
    }

    return (
      <>
        <div className="px16">
          <div className="text header line-24 bold pt20 pb8">
            {Localization.translator.orderSummary}
          </div>
          <ConcreteDirectOrderSummary
            className="bt-light-grey py12"
            concreteDirectOrder={selectedConcreteDirectOrder}
          />
        </div>
        {!!orderPayloads?.length && (
          <div className="px16">
            <div className="text header line-24 bold pt20 pb8">
              {Localization.translator.payloadSummary}
            </div>
            <ConcreteDirectPayloadSummary
              className="bt-light-grey py12"
              orderPayloads={orderPayloads}
              concreteDirectOrder={selectedConcreteDirectOrder}
            />
          </div>
        )}
        <div className="text header line-24 bold px16 pt20 pb8">
          {Localization.translator.log}
        </div>
      </>
    )
  }

  @action.bound
  private onHideModal(closeModalFn: () => void) {
    const {
      isCreateModeActive,
      areNonWorkAndDeadlineModalsOpened,
      resetChangedDateAndTime,
      closeNonWorkTimeModal,
      closeDateChangeConfirmModal,
    } = this.store

    if (areNonWorkAndDeadlineModalsOpened) {
      closeNonWorkTimeModal()
      closeDateChangeConfirmModal()
    }

    closeModalFn()

    if (isCreateModeActive) {
      return this.backClicked()
    }

    resetChangedDateAndTime()
  }

  @action.bound
  private onApplyConfirmModal() {
    const {
      showLateRequestLabel,
      moveDateForward,
      closeDateChangeConfirmModal,
      shouldBlockLateRequesting,
    } = this.store
    closeDateChangeConfirmModal()

    if (shouldBlockLateRequesting) {
      return moveDateForward()
    }

    showLateRequestLabel()
  }

  private switchActiveTab = (newActiveTab: DeliveryDetailsTab) => {
    this.store.switchActiveTab(newActiveTab)
    this.setScroll(newActiveTab === DeliveryDetailsTab.Log)
  }

  private setScroll = (shouldScrollDown: boolean) => {
    if (!this.containerRef) {
      return
    }
    this.store.shouldScrollToBottom = false

    if (shouldScrollDown) {
      this.containerRef.scrollTop =
        this.containerRef.scrollHeight - this.containerRef.clientHeight
    } else {
      this.containerRef.scrollTop = 0
    }
  }

  private onApplyNonWorkTimesModal = () => {
    const {
      showNonWorkTimeLabel,
      blockSubmitButton,
      blockSubmitAndShowNonWorkLabel,
      closeNonWorkTimeModal,
      closeDateChangeConfirmModal,
      showLateRequestLabel,
      areNonWorkAndDeadlineModalsOpened,
      shouldBlockOnNonWorkTimes,
      shouldBlockLateRequesting,
    } = this.store

    if (areNonWorkAndDeadlineModalsOpened) {
      closeDateChangeConfirmModal()
      showLateRequestLabel()

      if (shouldBlockLateRequesting) {
        blockSubmitButton()
      }
    }

    closeNonWorkTimeModal()

    if (shouldBlockOnNonWorkTimes) {
      return blockSubmitAndShowNonWorkLabel()
    }

    showNonWorkTimeLabel()
  }

  private resetRecurringData = () => {
    const { hideEditRecurringDialog, clearRecurringRelatedIds } = this.store

    hideEditRecurringDialog()
    clearRecurringRelatedIds()
  }

  private setSelectedStatus = (status: DeliveryStatus) => {
    this.store.setNewDeliveryStatus(status)
    this.switchActiveTab(DeliveryDetailsTab.Log)
  }

  @action.bound
  private applyMessage() {
    this.store.shouldScrollToBottom = true
    this.switchActiveTab(DeliveryDetailsTab.Log)
  }

  private cancelDelivery = (reason?: string) => {
    this.setSelectedStatus(DeliveryStatus.Canceled)
    this.store.cancelDelivery(reason)
  }

  private loadDeliveriesStatusChanges = async (id: string) => {
    await this.props.deliveryStatusChangesStore.loadDeliveriesStatusChanges(id)
    this.prepareUI()
  }

  @action.bound
  private setContainerRef(ref: HTMLDivElement) {
    this.containerRef = ref
  }

  private modeDeliveryDates = () => {
    this.store.moveDeliveryDates(this.props.backClicked)
  }

  private hideMoveDeliveryDatesModal = () => {
    this.store.hideMoveDeliveryDates()
    this.props.backClicked()
  }

  @action.bound
  private ensureDisplayedDeliveryIsSetCorrectly() {
    if (
      !this.store.displayedDelivery &&
      this.props.displayedDeliveryId &&
      this.props.displayedDeliveryId !== NEW_DELIVERY_PATH_KEYWORD
    ) {
      const delivery = this.props.deliveriesStore.byId.get(
        this.props.displayedDeliveryId,
      )
      // This case is possible only in View mode (when we switch deliveries)
      this.store.setViewMode(delivery)
    }
  }

  @action.bound
  private reinitFieldCategoryCollapsingMap() {
    const { user, activeProject } = this.props.eventsStore.appState

    try {
      const userPref = JSON.parse(localStorage.getItem(user.id)) || {}

      this.store.reinitFieldCategoryCollapsingMap(
        userPref?.projectSettings?.[activeProject.id]
          ?.deliveryFormFieldCollapsedCategories,
      )
    } catch {
      /* empty */
    }
  }
}
