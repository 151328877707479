import * as React from 'react'

import { Switch } from '@blueprintjs/core'
import { classList } from 'react-classlist-helper'

import AvatarType from '~/client/src/shared/enums/AvatarType'

import { FormFieldType } from '../../enums/FormFieldType'
import { TagIconType } from '../../enums/TagIcon'
import { TagType } from '../../enums/TagType'
import { Field } from '../../types/CustomFieldType'
import { EMPTY_STRING } from '../../utils/usefulStrings'
import AvatarInput from '../AvatarInput/AvatarInput'
import ImageInput from '../ImageInput/ImageInput'
import StruxhubInput from '../StruxhubInputs/StruxhubInput'
import StruxhubPhoneInput from '../StruxhubInputs/StruxhubPhoneInput'
import StruxhubSelect from '../StruxhubInputs/StruxhubSelect'
import StruxhubTagAsTextInput from '../StruxhubInputs/StruxhubTagAsTextInput'
import StruxhubTagInput from '../StruxhubInputs/StruxhubTagInput'
import StruxhubTagsInput from '../StruxhubInputs/StruxhubTagsInput/StruxhubTagsInput'
import StruxhubInputLabel from '../StruxhubInputs/components/StruxhubInputLabel'

import './FormFieldWrapper.scss'

interface IProps {
  field: Field
  avatarType?: AvatarType
  className?: string
}

export default class FormFieldWrapper extends React.Component<IProps> {
  private static renderInput(
    {
      type,
      options,
      onChange,
      onValueReset,
      id,
      value,
      minlength,
      maxlength,
      minNumber,
      maxNumber,
      serviceInfo,
      isItemCreationAllowed,
      isRequired,
      isValid,
      pattern,
      inline,
      isReadonly,
      restrictedValues,
      label,
      negativeOrDecimal,
    }: Field,
    avatarType?: AvatarType,
  ) {
    switch (type) {
      case FormFieldType.Tag:
        return (
          <StruxhubTagInput
            label={label}
            isValid={isValid}
            isRequired={isRequired}
            isReadOnly={isReadonly}
            hasShortWidth={!!inline}
            onChange={onChange}
            tagType={serviceInfo as TagType}
            value={value}
            isTagCreationAllowed={isItemCreationAllowed}
            restrictedValues={restrictedValues}
          />
        )

      case FormFieldType.Tags:
        return (
          <StruxhubTagsInput
            label={label}
            isValid={isValid}
            isRequired={isRequired}
            isReadOnly={isReadonly}
            hasShortWidth={!!inline}
            onChange={onChange}
            tagType={serviceInfo as TagType}
            values={value}
            isTagCreationAllowed={isItemCreationAllowed}
            restrictedValues={restrictedValues}
          />
        )

      case FormFieldType.TextAsTag:
        return (
          <StruxhubTagAsTextInput
            label={label}
            iconName={serviceInfo as TagIconType}
            value={value || EMPTY_STRING}
            isRequired={isRequired}
            isReadOnly={isReadonly}
            isValid={isValid}
            hasShortWidth={!!inline}
            onChange={onChange}
            onValueReset={onValueReset}
          />
        )

      case FormFieldType.SelectOne:
        return (
          <StruxhubSelect
            name={id}
            label={label}
            isValid={isValid}
            isRequired={isRequired}
            isReadOnly={isReadonly}
            hasShortWidth={!!inline}
            value={value}
            onChange={onChange}
          >
            {(options || []).map(o => (
              <option key={o.value} value={o.value} disabled={o.disabled}>
                {o.name}
              </option>
            ))}
          </StruxhubSelect>
        )

      case FormFieldType.Phone:
        return (
          <StruxhubPhoneInput
            label={label}
            value={value || EMPTY_STRING}
            isRequired={isRequired}
            isValid={isValid}
            isReadOnly={isReadonly}
            hasShortWidth={!!inline}
            onChange={onChange}
          />
        )

      case FormFieldType.Avatar:
        return (
          <AvatarInput
            data={value}
            onChange={onChange}
            avatarType={avatarType}
          />
        )

      case FormFieldType.Switch:
        return (
          <>
            <StruxhubInputLabel
              label={label}
              value={value}
              isRequired={isRequired}
              isValid={isValid}
            />
            <Switch
              checked={value}
              onChange={onChange}
              name={id}
              disabled={isReadonly}
              className="no-grow primary-blue-switch no-outline-container mt10"
            />
          </>
        )
      case FormFieldType.Image:
        return <ImageInput value={value} label={label} onChange={onChange} />

      default:
        return (
          <StruxhubInput
            label={label}
            name={id}
            type={type}
            value={value || EMPTY_STRING}
            isRequired={isRequired}
            isReadOnly={isReadonly}
            isValid={isValid}
            hasShortWidth={!!inline}
            onChange={onChange}
            onValueReset={onValueReset}
            min={minNumber}
            max={maxNumber}
            minLength={minlength}
            maxLength={maxlength}
            negativeOrDecimal={negativeOrDecimal}
            pattern={pattern}
          />
        )
    }
  }

  public render() {
    const { field, avatarType, className } = this.props
    const { inline, isHidden } = field

    return (
      <div
        className={classList({
          [className]: !!className,
          'col px8 form-field-wrapper-input': true,
          [`inline-${inline} vertical-align-middle inline-flex`]: !!inline,
          hidden: isHidden,
        })}
      >
        {FormFieldWrapper.renderInput(field, avatarType)}
      </div>
    )
  }
}
