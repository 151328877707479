import { action, computed, observable } from 'mobx'

const COLLAPSED_ROWS_COUNT = 2
const EXPANDED_ROWS_COUNT = 10

export default class BaseExpandableWorkflowCardStore {
  @observable public isExpanded: boolean = false

  @action.bound
  public setExpandedState() {
    this.isExpanded = !this.isExpanded
  }

  @action.bound
  public resetIsExpanded() {
    this.isExpanded = false
  }

  @computed
  public get rowsCount(): number {
    return this.isExpanded ? EXPANDED_ROWS_COUNT : COLLAPSED_ROWS_COUNT
  }
}
