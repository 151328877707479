import { action, computed, observable } from 'mobx'

import { LocationType } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import MapViewItemFactory from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemFactory'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import Area from '~/client/src/shared/models/LocationObjects/Area'
import Building from '~/client/src/shared/models/LocationObjects/Building'
import Gate from '~/client/src/shared/models/LocationObjects/Gate'
import InteriorDoor from '~/client/src/shared/models/LocationObjects/InteriorDoor'
import InteriorPath from '~/client/src/shared/models/LocationObjects/InteriorPath'
import Level from '~/client/src/shared/models/LocationObjects/Level'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import LogisticsObject from '~/client/src/shared/models/LocationObjects/LogisticsObject'
import OffloadingEquipment from '~/client/src/shared/models/LocationObjects/OffloadingEquipment'
import Route from '~/client/src/shared/models/LocationObjects/Route'
import Staging from '~/client/src/shared/models/LocationObjects/Staging'
import VerticalObject from '~/client/src/shared/models/LocationObjects/VerticalObject'
import Zone from '~/client/src/shared/models/LocationObjects/Zone'
import ClosuresStore from '~/client/src/shared/stores/domain/Closures.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'

export interface IObjectType {
  id: LocationType
  name: string
  iconName: MapViewLocationIcon
  fieldId: FieldIds
}

export default class ObjectPropertiesSetupStore {
  @observable public activeObjectTypeId: LocationType = LocationType.Building

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly companiesStore: CompaniesStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly closuresStore: ClosuresStore,
  ) {}

  @action.bound
  public activateObjectTypeTab(newActiveObjectTypeId: LocationType) {
    this.activeObjectTypeId = newActiveObjectTypeId
  }

  @computed
  public get isLoading(): boolean {
    return this.eventsStore.appState.isLoading
  }

  @computed
  public get isDataReceived(): boolean {
    return (
      this.locationAttributesStore.isDataReceived &&
      this.companiesStore.isDataReceived &&
      this.closuresStore.isDataReceived
    )
  }

  @computed
  public get sitemapAttributes(): LocationBase[] {
    return this.locationAttributesStore.allAttributes
  }

  @computed
  public get objectTypes(): IObjectType[] {
    return [
      {
        id: LocationType.Building,
        name: this.getObjectTypeName(FieldIds.BUILDING),
        iconName: MapViewLocationIcon.Building,
        fieldId: FieldIds.BUILDING,
      },
      {
        id: LocationType.Zone,
        name: this.getObjectTypeName(FieldIds.ZONE),
        iconName: MapViewLocationIcon.Zone,
        fieldId: FieldIds.ZONE,
      },
      {
        id: LocationType.Level,
        name: this.getObjectTypeName(FieldIds.LEVEL),
        iconName: MapViewLocationIcon.Level,
        fieldId: FieldIds.LEVEL,
      },
      {
        id: LocationType.Area,
        name: this.getObjectTypeName(FieldIds.AREA),
        iconName: MapViewLocationIcon.Area,
        fieldId: FieldIds.AREA,
      },
      {
        id: LocationType.Route,
        name: this.getObjectTypeName(FieldIds.ROUTE),
        iconName: MapViewLocationIcon.Route,
        fieldId: FieldIds.ROUTE,
      },
      {
        id: LocationType.Gate,
        name: this.getObjectTypeName(FieldIds.GATE),
        iconName: MapViewLocationIcon.Gate,
        fieldId: FieldIds.GATE,
      },
      {
        id: LocationType.LogisticsObject,
        name: this.getObjectTypeName(FieldIds.LOGISTICS_OBJECT),
        iconName: MapViewLocationIcon.Logistics,
        fieldId: FieldIds.LOGISTICS_OBJECT,
      },
      {
        id: LocationType.VerticalObject,
        name: this.getObjectTypeName(FieldIds.VERTICAL_OBJECT),
        iconName: MapViewLocationIcon.Stairs,
        fieldId: FieldIds.VERTICAL_OBJECT,
      },
      {
        id: LocationType.OffloadingEquipment,
        name: this.getObjectTypeName(FieldIds.OFFLOADING_EQUIPMENT),
        iconName: MapViewLocationIcon.Equipment,
        fieldId: FieldIds.OFFLOADING_EQUIPMENT,
      },
      {
        id: LocationType.Staging,
        name: this.getObjectTypeName(FieldIds.STAGING),
        iconName: MapViewLocationIcon.Staging,
        fieldId: FieldIds.STAGING,
      },
      {
        id: LocationType.InteriorDoor,
        name: this.getObjectTypeName(FieldIds.INTERIOR_DOOR),
        iconName: MapViewLocationIcon.InteriorDoor,
        fieldId: FieldIds.INTERIOR_DOOR,
      },
      {
        id: LocationType.InteriorPath,
        name: this.getObjectTypeName(FieldIds.INTERIOR_PATH),
        iconName: MapViewLocationIcon.InteriorPath,
        fieldId: FieldIds.INTERIOR_PATH,
      },
    ]
  }

  @computed
  public get iconsByObjectType(): MapViewLocationIcon[] {
    switch (this.activeObjectTypeId) {
      case LocationType.LogisticsObject:
        return [
          '' as MapViewLocationIcon,
          MapViewLocationIcon.Logistics,
          MapViewLocationIcon.Bathroom,
          MapViewLocationIcon.Break,
          MapViewLocationIcon.Dumpster,
          MapViewLocationIcon.Entrance,
          MapViewLocationIcon.HandWash,
          MapViewLocationIcon.Medical,
          MapViewLocationIcon.MeetingPoint,
          MapViewLocationIcon.Parking,
          MapViewLocationIcon.Smoking,
          MapViewLocationIcon.Temperature,
          MapViewLocationIcon.Tent,
          MapViewLocationIcon.Walkway,
          MapViewLocationIcon.ElectricalRoom,
          MapViewLocationIcon.Trailer,
        ]
      case LocationType.VerticalObject:
        return [
          '' as MapViewLocationIcon,
          MapViewLocationIcon.Elevator,
          MapViewLocationIcon.Stairs,
          MapViewLocationIcon.Shaft,
        ]
      default:
        return []
    }
  }

  @action.bound
  public createNewItem() {
    const type = this.objectTypes.find(o => o.id === this.activeObjectTypeId)

    const { activeProject } = this.eventsStore.appState
    const dto = MapViewItemFactory.createDataAttribute(
      this.activeObjectTypeId,
      activeProject.id,
      null,
      type.name,
    )
    const existingNames = this.sitemapAttributes.map(a => a.name)
    dto.name = MapViewItemFactory.getValidatedName(dto.name, existingNames)
    this.saveSitemapAttribute(dto)
  }

  public saveSitemapAttribute(dataObject: LocationBase) {
    switch (dataObject.type) {
      case LocationType.Building:
        return this.locationAttributesStore.buildingsStore.saveItem(
          dataObject as Building,
        )
      case LocationType.Zone:
        return this.locationAttributesStore.zonesStore.saveItem(
          dataObject as Zone,
        )
      case LocationType.Gate:
        return this.locationAttributesStore.gatesStore.saveItem(
          dataObject as Gate,
        )
      case LocationType.Route:
        return this.locationAttributesStore.routesStore.saveItem(
          dataObject as Route,
        )
      case LocationType.OffloadingEquipment:
        return this.locationAttributesStore.offloadingEquipmentsStore.saveItem(
          dataObject as OffloadingEquipment,
        )
      case LocationType.LogisticsObject:
        return this.locationAttributesStore.logisticsObjectsStore.saveItem(
          dataObject as LogisticsObject,
        )
      case LocationType.VerticalObject:
        return this.locationAttributesStore.verticalObjectsStore.saveItem(
          dataObject as VerticalObject,
        )
      case LocationType.Level:
        return this.locationAttributesStore.levelsStore.saveItem(
          dataObject as Level,
        )
      case LocationType.Area:
        return this.locationAttributesStore.areasStore.saveItem(
          dataObject as Area,
        )
      case LocationType.Staging:
        return this.locationAttributesStore.stagingsStore.saveItem(
          dataObject as Staging,
        )
      case LocationType.InteriorDoor:
        return this.locationAttributesStore.interiorDoorsStore.saveItem(
          dataObject as InteriorDoor,
        )
      case LocationType.InteriorPath:
        return this.locationAttributesStore.interiorPathsStore.saveItem(
          dataObject as InteriorPath,
        )
    }
  }

  private getObjectTypeName(associatedFieldId: FieldIds) {
    return this.eventsStore.appState.getDeliveryFieldName(associatedFieldId)
  }
}
