import { action, computed, observable } from 'mobx'

import { IPhoto, UploadingType } from '~/client/graph'
import { ISavePhotoMutation } from '~/client/graph/operations/generated/Photos.generated'
import Activity from '~/client/src/shared/models/Activity'
import Message from '~/client/src/shared/models/Message'
import Photo from '~/client/src/shared/models/Photo'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'

import PhotoSortingTypes from '../../enums/PhotoSortingTypes'
import MessageDto from '../../types/MessageDto'
import EventsStore from '../EventStore/Events.store'
import { SAVE_PHOTO } from '../EventStore/eventConstants'
import BaseStoreWithById from '../baseStores/BaseWithById.store'
import ProjectDateStore from '../ui/ProjectDate.store'
import { FileUploadingStore } from './FileUploading.store'

export default class PhotosStore extends BaseStoreWithById<Photo, IPhoto> {
  @observable public selectedSorting: PhotoSortingTypes = PhotoSortingTypes.DATE

  public constructor(
    private eventsStore: EventsStore,
    private fileUploadingStore: FileUploadingStore = null,
    private messagesStore: MessagesStore,
    private projectDateStore: ProjectDateStore,
  ) {
    super(Photo)
  }

  public get byId() {
    return this.eventsStore.appState.photos
  }

  private get currentUser() {
    return this.eventsStore.appState.user
  }

  @action.bound
  public changeSelectedSorting(selectedSortType: PhotoSortingTypes) {
    this.selectedSorting = selectedSortType
  }

  @computed
  public get photosByDate(): Array<{ date: Date; photos: Photo[] }> {
    const photosByDay = this.list.reduce((map, photo: Photo) => {
      if (!photo.url) {
        return map
      }

      const day = this.projectDateStore.startOfDay(photo.createdAt).getTime()

      map[day] = map[day] || []
      map[day].push(photo)

      return map
    }, {} as { [key: string]: Photo[] })

    return Object.keys(photosByDay).map(day => {
      return {
        date: new Date(+day),
        photos: photosByDay[day],
      }
    })
  }

  public async post(
    activity: Activity,
    message: Message,
    file: any,
    projectId: string,
  ): Promise<string> {
    try {
      const photo = new Photo()

      if (message) {
        photo.messageId = message.id
      }

      photo.setAuthor(this.currentUser)
      const relationship: any = {
        author: this.currentUser.email,
        collection: 'activity',
      }

      if (activity) {
        photo.setActivity(activity)
        relationship.id = activity.code
      }

      const [fullFileUrl, thumbFileUrl] =
        await this.fileUploadingStore.uploadFile(
          file,
          UploadingType.Image,
          file.name,
        )

      photo.projectId = projectId
      photo.url = fullFileUrl.fileURL
      photo.thumbUrl = thumbFileUrl.fileURL

      return await this.save(photo)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  public replyWithText(photo: Photo, text: string): Promise<MessageDto> {
    const photoMessage = this.messagesStore.list.find(
      message => message.photoId === photo.id,
    )
    return this.messagesStore.post(text, photoMessage.threadId)
  }

  @computed
  public get photosActivityIdMap(): { [activityId: string]: any } {
    return this.list.reduce((a, photo) => {
      const photosOfActivity = a[photo.getActivityObjectId()] || []
      photosOfActivity.push(photo)
      return Object.assign(a, {
        [photo.getActivityObjectId()]: photosOfActivity,
      })
    }, {} as { [key: string]: any })
  }

  public save(item: Photo): Promise<string> {
    return new Promise((resolve, reject) => {
      this.eventsStore.dispatch(
        SAVE_PHOTO,
        item,
        (data: ISavePhotoMutation) => {
          resolve(data.savePhoto.id)
        },
        reject,
      )
    })
  }
}
