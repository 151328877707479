import { observable } from 'mobx'

import { ILevelsClosure, LocationType } from '~/client/graph'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'
import IMsDateInterval from '~/client/src/shared/types/IMsDateInterval'

import FieldIds from '../../enums/DeliveryFieldIds'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import {
  areArraysEqual,
  areObjectArraysEqual,
  copyArray,
  copyObjectArray,
} from '../../utils/util'
import IAccessibleLevelsAttribute from '../IAccessibleLevelsAttribute'
import LocationBase from './LocationBase'

interface IVerticalObjectDto extends IAccessibleLevelsAttribute {
  levelsClosures?: ILevelsClosure[]
}

export default class VerticalObject extends LocationBase<VerticalObject> {
  public static fromDto(dto: IVerticalObjectDto) {
    const item = LocationBase.factory(
      VerticalObject,
      dto,
      MapViewLocationIcon.Stairs,
    )

    item.accessibleLevels = dto.accessibleLevels || []
    item.levelsClosures = dto.levelsClosures || []

    return item
  }

  @observable public accessibleLevels: string[] = []
  @observable public levelsClosures: ILevelsClosure[] = []

  public type = LocationType.VerticalObject
  public relatedFieldId = FieldIds.VERTICAL_OBJECT
  protected model = VerticalObject

  public isEqual(dto: VerticalObject) {
    return (
      super.isEqual(dto) &&
      areArraysEqual(this.accessibleLevels, dto.accessibleLevels) &&
      areObjectArraysEqual(this.levelsClosures, dto.levelsClosures)
    )
  }

  public copy(): VerticalObject {
    const item = super.copy()
    item.accessibleLevels = copyArray(this.accessibleLevels || [])
    item.levelsClosures = copyObjectArray(this.levelsClosures || [])
    return item
  }

  public getDto(): IVerticalObjectDto {
    const dto: IVerticalObjectDto = super.getDto()
    dto.accessibleLevels = this.accessibleLevels || []
    dto.levelsClosures = this.levelsClosures.map(c => ({
      levels: c.levels,
      interval: {
        startDate: c.interval.startDate,
        endDate: c.interval.endDate,
      },
    }))
    return dto
  }

  public isOpenForInterval = (
    projectDateStore: ProjectDateStore,
    interval: IMsDateInterval,
  ): boolean => {
    const now = Date.now()

    return !projectDateStore.isClosedBetween(
      new Date(now),
      new Date(now + 1),
      [],
      [interval],
    )
  }
}
