import * as React from 'react'

import { action, computed } from 'mobx'
import { observer } from 'mobx-react'

import { EMPTY_STRING } from '../../utils/usefulStrings'
import DynamicOverflowListStore from './DynamicOverflowList.store'

interface IChildrenObject {
  visibleElements: JSX.Element[]
  overflowElements: JSX.Element[]
  containerRefSetter: (elementRef: HTMLElement) => void
}

interface IProps {
  items: JSX.Element[]

  shouldIgnoreScrollBarWidth?: boolean
  shouldRecalculateRowsCount?: boolean
  allowRecalculationForItems?: boolean
  rowsCount?: number
  additionalWidth?: number
  minContainerWidthConstraint?: number
  paddingWidth?: number
  elementsClassName?: string

  store?: DynamicOverflowListStore

  children(childrenObject: IChildrenObject): JSX.Element
}

const SCROLL_BAR_WIDTH = 20
const DEFAULT_ROWS_COUNT = 1
const MIN_ALLOWED_ELEMENTS = 1

@observer
export default class DynamicOverflowList extends React.Component<IProps> {
  public static defaultProps = {
    rowsCount: DEFAULT_ROWS_COUNT,
    additionalWidth: 0,
    minContainerWidthConstraint: 0,
    paddingWidth: 0,
  }

  private readonly localStore: DynamicOverflowListStore = null

  private containerRef: HTMLElement
  private listElementsRefs: HTMLDivElement[] = []

  public constructor(props: IProps) {
    super(props)

    if (!props.store) {
      this.localStore = new DynamicOverflowListStore()
    }
  }

  private get store(): DynamicOverflowListStore {
    return this.props.store || this.localStore
  }

  public componentDidMount() {
    this.store.disableRecalculation()

    window.addEventListener('resize', this.onComponentResized)

    this.calculateNumberOfVisibleElements()
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      items,
      rowsCount,
      shouldRecalculateRowsCount,
      allowRecalculationForItems,
    } = this.props

    const { shouldRecalculate, enableRecalculation, disableRecalculation } =
      this.store

    const shouldRecalculateRows =
      shouldRecalculateRowsCount && prevProps.rowsCount !== rowsCount
    const shouldRecalculateItems =
      allowRecalculationForItems && prevProps.items !== items

    if (shouldRecalculate) {
      this.clearNotDisplayedElementsRefs()
      this.calculateNumberOfVisibleElements()
      disableRecalculation()
    } else if (
      prevProps.items.length !== items.length ||
      shouldRecalculateRows ||
      shouldRecalculateItems
    ) {
      enableRecalculation()
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.onComponentResized)
  }

  public render() {
    return this.props.children({
      visibleElements: this.visibleElements,
      overflowElements: this.overflowElements,
      containerRefSetter: this.setContainerRef,
    })
  }

  @action.bound
  private calculateNumberOfVisibleElements() {
    const { items, rowsCount, additionalWidth, minContainerWidthConstraint } =
      this.props

    const { setNumberOfVisibleElements, resetNumberOfVisibleElements } =
      this.store

    const containerWidth = this.getContainerWidth()

    if (containerWidth <= minContainerWidthConstraint) {
      setNumberOfVisibleElements(MIN_ALLOWED_ELEMENTS)
      return
    }

    const maxElementsAllowed = this.getMaxAllowedElementsCount(
      rowsCount,
      containerWidth,
      additionalWidth,
    )

    if (maxElementsAllowed > items.length) {
      resetNumberOfVisibleElements()
      return
    }

    setNumberOfVisibleElements(
      Math.max(maxElementsAllowed, MIN_ALLOWED_ELEMENTS),
    )
  }

  @action.bound
  private onComponentResized() {
    this.calculateNumberOfVisibleElements()
    this.store.enableRecalculation()
  }

  private getMaxAllowedElementsCount(
    rowsCount: number,
    containerWidth: number,
    additionalWidth: number,
  ): number {
    let maxElementsAllowed = 0

    Array.from(Array(rowsCount).keys()).forEach(rowIndex => {
      const lastIndex = rowsCount - 1
      const isLastRow = rowIndex === lastIndex

      const selectedRefs = this.listElementsRefs.slice(maxElementsAllowed)

      if (!selectedRefs?.length) {
        return
      }

      const elementsCountInRow = this.getElementsCountInRow(
        selectedRefs,
        containerWidth,
        additionalWidth,
        isLastRow,
      )

      maxElementsAllowed += elementsCountInRow
    })

    return maxElementsAllowed
  }

  private getElementsCountInRow(
    elementsRefs: HTMLDivElement[],
    containerWidth: number,
    additionalWidth: number,
    isLastRow: boolean,
  ): number {
    let elementsWidth = isLastRow ? additionalWidth : 0
    let elementsInRowCount = 0

    elementsRefs.forEach(elementRef => {
      elementsWidth += Math.floor(elementRef.offsetWidth)

      if (elementsWidth < containerWidth) {
        elementsInRowCount += 1
      }
    })

    return elementsInRowCount
  }

  private getContainerWidth = (): number => {
    if (!this.containerRef || !this.containerRef.offsetWidth) {
      return 0
    }

    const { paddingWidth, shouldIgnoreScrollBarWidth } = this.props
    const scrollBarWidth = shouldIgnoreScrollBarWidth ? 0 : SCROLL_BAR_WIDTH

    return this.containerRef.offsetWidth - scrollBarWidth - paddingWidth
  }

  private setContainerRef = (elementRef: HTMLElement) => {
    this.containerRef = elementRef
  }

  private addListElementRef = (elementRef: HTMLDivElement) => {
    if (elementRef) {
      this.listElementsRefs.push(elementRef)
    }
  }

  private clearNotDisplayedElementsRefs = () => {
    this.listElementsRefs = this.listElementsRefs.filter(
      node => !!node && !!node.offsetWidth,
    )
  }

  @computed
  private get visibleElements(): JSX.Element[] {
    return this.itemsList.slice(0, this.store.numberOfVisibleElements)
  }

  @computed
  private get overflowElements(): JSX.Element[] {
    return this.itemsList.slice(this.store.numberOfVisibleElements)
  }

  private get itemsList(): JSX.Element[] {
    const { items, elementsClassName } = this.props

    const className = `inline-block ${elementsClassName || EMPTY_STRING}`

    return items.map((item, index) => {
      return (
        <div className={className} key={index} ref={this.addListElementRef}>
          {item}
        </div>
      )
    })
  }
}
