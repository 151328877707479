import { action, computed } from 'mobx'

import { DeliveryFilterType } from '~/client/graph'
import { formatStatusToDisplay } from '~/client/src/shared/constants/DeliveryStatus'
import Delivery from '~/client/src/shared/models/Delivery'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { RESET_ALL_FILTERS } from '~/client/src/shared/stores/EventStore/eventConstants'
import SuperFilterStore, {
  IRootOption,
} from '~/client/src/shared/stores/ui/SuperFilter.store'
import Guard from '~/client/src/shared/utils/Guard'

import Localization from '../localization/LocalizationManager'
import { getDeliveryFilterTypeDisplayName } from '../localization/enumDisplayTexts'
import KnownTranslatorKeys from '../localization/knownTranslatorKeys'
import InitialState from './InitialState'

export type ISourceMap = { [optionId: string]: string[] }

export default class BaseDeliveryFilterStore extends SuperFilterStore {
  public get totalHint(): string {
    return Localization.translator.totalDeliveries
  }

  public constructor(
    public readonly type: DeliveryFilterType,
    protected readonly state: InitialState,
    protected readonly sourceMap: ISourceMap,
    protected readonly getFilteredCollectionExcludeFilter: (
      excludedFilters?: string[],
    ) => Delivery[],
    protected readonly onShowChanged: (isShown: boolean, type: string) => void,
    protected readonly customFormatOptionKey?: (
      optionKey: string,
      filterType: string,
    ) => string,
    protected readonly isInitiallyInitialized?: boolean,
    protected readonly onClickHandler?: () => void,
  ) {
    super(
      state.deliveryFilters.fieldsMap[type],
      type,
      state,
      KnownTranslatorKeys.seeXDeliveries,
      getDeliveryFilterTypeDisplayName,
      onShowChanged,
      false,
      onClickHandler,
    )

    this.filter.isInitialized = isInitiallyInitialized
    Guard.requireAll({ type, state, sourceMap })
  }

  @action.bound
  public onFilterActionRequest(eventContext: EventContext) {
    const [eventType] = eventContext.event

    switch (eventType) {
      case RESET_ALL_FILTERS:
        return this.setInitialFilterValues()
    }
  }

  @computed
  public get optionsTree(): IRootOption[] {
    const options = Object.keys(this.sourceMap)

    return [
      {
        id: this.type,
        name: this.typeCaption,
        options: options.map(option => ({
          id: option,
          name: this.formatOptionKey(option, this.type),
          instancesIds: this.sourceMap[option],
        })),
      },
    ]
  }

  @action.bound
  public clickOnApply() {
    this.handleApply()
    this.onClickHandler?.()
  }

  public filterOptionInstances = (deliveriesIds: string[]) => {
    return this.allFilteredDeliveries.filter(d => deliveriesIds.includes(d.id))
  }

  @computed
  private get allFilteredDeliveries() {
    return this.getFilteredCollectionExcludeFilter
      ? this.getFilteredCollectionExcludeFilter([this.type])
      : []
  }

  private formatOptionKey(optionKey: string, filterType: string): string {
    if (this.customFormatOptionKey) {
      return this.customFormatOptionKey(optionKey, filterType)
    }
    return filterType === DeliveryFilterType.Status
      ? formatStatusToDisplay(optionKey)
      : optionKey
  }
}
