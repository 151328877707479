import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { ConcreteDirectOrderStatusLabel } from '~/client/src/shared/components/ConcreteDirectStatusLabel/ConcreteDirectStatusLabel'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ConcreteDirectOrder from '~/client/src/shared/models/ConcreteDirectOrder'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import ConcreteDirectOrderStatus from '../../../enums/ConcreteDirectOrderStatus'

// localization: translated

interface IProps {
  concreteDirectOrder: ConcreteDirectOrder

  className?: string
  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
export default class ConcreteDirectOrderSummary extends React.Component<IProps> {
  public static defaultProps = {
    className: '',
  }

  public render() {
    const { concreteDirectOrder, className } = this.props

    if (!concreteDirectOrder) {
      return null
    }

    const {
      address,
      orderReference,
      orderByName,
      status,
      totalVolume,
      allSpacingInMinutes,
      allSlumps,
      allPourMethods,
      allPourElements,
      allExtraCharges,
      allMixTypes,
    } = concreteDirectOrder

    const orderReferenceString = orderReference && `#${orderReference}`
    const concreteDirectIcon = <Icons.ConcreteDirect className="no-grow mr4" />

    return (
      <div className={className}>
        {this.renderFieldInfoRow(
          `${Localization.translator.order_noun} #`,
          orderReferenceString,
          concreteDirectIcon,
        )}
        {this.renderOrderStatus(status)}
        {this.renderFieldInfoRow(
          Localization.translator.date,
          this.dateAndTimeCaption,
        )}
        {this.renderFieldInfoRow(
          Localization.translator.totalVolume,
          totalVolume,
        )}
        {this.renderFieldInfoRow(
          Localization.translator.deliveryLocation,
          address?.line?.toLowerCase(),
        )}
        {this.renderFieldInfoRow(Localization.translator.mixType, allMixTypes)}
        {this.renderFieldInfoRow(Localization.translator.slump, allSlumps)}
        {this.renderFieldInfoRow(
          Localization.translator.spacingOrUnloadTime,
          allSpacingInMinutes,
        )}
        {this.renderFieldInfoRow(
          Localization.translator.pouringMode,
          allPourMethods,
        )}
        {this.renderFieldInfoRow(
          Localization.translator.elementsToPour,
          allPourElements,
        )}
        {this.renderFieldInfoRow(
          Localization.translator.extraCharge,
          allExtraCharges,
        )}
        {this.renderFieldInfoRow(
          Localization.translator.orderedBy,
          orderByName,
        )}
      </div>
    )
  }

  private renderFieldInfoRow(
    label: string,
    value: string,
    iconElement?: JSX.Element,
  ): JSX.Element {
    return (
      <div className="row y-start x-between py6">
        {iconElement}
        <span className="nowrap no-grow text bold large grey-light line-extra-large full-width">
          {label}
        </span>
        <span className="word-break-all ml8 text end capitalize bold extra-large line-extra-large full-width">
          {value || NO_VALUE}
        </span>
      </div>
    )
  }

  private renderOrderStatus(status: ConcreteDirectOrderStatus): JSX.Element {
    return (
      <div className="row y-start x-between py6">
        <span className="nowrap no-grow text bold large grey-light line-extra-large full-width">
          {Localization.translator.orderStatus}
        </span>
        <span className="ml8 full-width no-grow">
          <ConcreteDirectOrderStatusLabel status={status} />
        </span>
      </div>
    )
  }

  private get dateAndTimeCaption(): string {
    const {
      concreteDirectOrder: { startDate },
      projectDateStore,
    } = this.props
    const {
      getWeekdayMonthAndDayToDisplay,
      getPronoun,
      isValidDate,
      getTimeToDisplay,
    } = projectDateStore

    if (!isValidDate(startDate)) {
      return null
    }

    const dateString =
      getPronoun(startDate) || getWeekdayMonthAndDayToDisplay(startDate)

    return `${dateString}, ${getTimeToDisplay(startDate)}`
  }
}
