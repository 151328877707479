import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IProjectTypeOptionsFieldsFragment = Pick<
  Types.IProjectTypeOptions,
  | 'createdAt'
  | 'id'
  | 'isDeliveriesDisabled'
  | 'isLogisticsDisabled'
  | 'isMaterialsDisabled'
  | 'isTrackerDisabled'
  | 'isFormsDisabled'
  | 'isPhotosDisabled'
  | 'isAnalyticsDisabled'
  | 'updatedAt'
  | 'projectId'
>

export type IGetProjectTypeOptionsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetProjectTypeOptionsQuery = {
  projectTypeOptions?: Types.Maybe<{
    data: Array<IProjectTypeOptionsFieldsFragment>
  }>
}

export type ISaveProjectTypeOptionsMutationVariables = Types.Exact<{
  projectTypeOptions: Types.IProjectTypeOptionsInput
}>

export type ISaveProjectTypeOptionsMutation = {
  saveProjectTypeOptions?: Types.Maybe<IProjectTypeOptionsFieldsFragment>
}

export type IListenToProjectTypeOptionsSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToProjectTypeOptionsSubscription = {
  projectTypeOptions?: Types.Maybe<
    Pick<Types.IProjectTypeOptionsChangeEvent, 'id'> & {
      item?: Types.Maybe<IProjectTypeOptionsFieldsFragment>
    }
  >
}

export const ProjectTypeOptionsFieldsFragmentDoc = gql`
  fragment ProjectTypeOptionsFields on ProjectTypeOptions {
    createdAt
    id
    isDeliveriesDisabled
    isLogisticsDisabled
    isMaterialsDisabled
    isTrackerDisabled
    isFormsDisabled
    isPhotosDisabled
    isAnalyticsDisabled
    updatedAt
    projectId
  }
`
export const GetProjectTypeOptionsDocument = gql`
  query GetProjectTypeOptions($projectId: ObjectId!) {
    projectTypeOptions(projectId: $projectId, limit: 1) {
      data {
        ...ProjectTypeOptionsFields
      }
    }
  }
  ${ProjectTypeOptionsFieldsFragmentDoc}
`

/**
 * __useGetProjectTypeOptionsQuery__
 *
 * To run a query within a React component, call `useGetProjectTypeOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTypeOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTypeOptionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectTypeOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetProjectTypeOptionsQuery,
    IGetProjectTypeOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetProjectTypeOptionsQuery,
    IGetProjectTypeOptionsQueryVariables
  >(GetProjectTypeOptionsDocument, options)
}
export function useGetProjectTypeOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetProjectTypeOptionsQuery,
    IGetProjectTypeOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetProjectTypeOptionsQuery,
    IGetProjectTypeOptionsQueryVariables
  >(GetProjectTypeOptionsDocument, options)
}
export type GetProjectTypeOptionsQueryHookResult = ReturnType<
  typeof useGetProjectTypeOptionsQuery
>
export type GetProjectTypeOptionsLazyQueryHookResult = ReturnType<
  typeof useGetProjectTypeOptionsLazyQuery
>
export type GetProjectTypeOptionsQueryResult = Apollo.QueryResult<
  IGetProjectTypeOptionsQuery,
  IGetProjectTypeOptionsQueryVariables
>
export const SaveProjectTypeOptionsDocument = gql`
  mutation SaveProjectTypeOptions(
    $projectTypeOptions: ProjectTypeOptionsInput!
  ) {
    saveProjectTypeOptions(projectTypeOptions: $projectTypeOptions) {
      ...ProjectTypeOptionsFields
    }
  }
  ${ProjectTypeOptionsFieldsFragmentDoc}
`
export type ISaveProjectTypeOptionsMutationFn = Apollo.MutationFunction<
  ISaveProjectTypeOptionsMutation,
  ISaveProjectTypeOptionsMutationVariables
>

/**
 * __useSaveProjectTypeOptionsMutation__
 *
 * To run a mutation, you first call `useSaveProjectTypeOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProjectTypeOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProjectTypeOptionsMutation, { data, loading, error }] = useSaveProjectTypeOptionsMutation({
 *   variables: {
 *      projectTypeOptions: // value for 'projectTypeOptions'
 *   },
 * });
 */
export function useSaveProjectTypeOptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveProjectTypeOptionsMutation,
    ISaveProjectTypeOptionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveProjectTypeOptionsMutation,
    ISaveProjectTypeOptionsMutationVariables
  >(SaveProjectTypeOptionsDocument, options)
}
export type SaveProjectTypeOptionsMutationHookResult = ReturnType<
  typeof useSaveProjectTypeOptionsMutation
>
export type SaveProjectTypeOptionsMutationResult =
  Apollo.MutationResult<ISaveProjectTypeOptionsMutation>
export type SaveProjectTypeOptionsMutationOptions = Apollo.BaseMutationOptions<
  ISaveProjectTypeOptionsMutation,
  ISaveProjectTypeOptionsMutationVariables
>
export const ListenToProjectTypeOptionsDocument = gql`
  subscription ListenToProjectTypeOptions($projectId: ObjectId!) {
    projectTypeOptions(projectId: $projectId) {
      id
      item {
        ...ProjectTypeOptionsFields
      }
    }
  }
  ${ProjectTypeOptionsFieldsFragmentDoc}
`

/**
 * __useListenToProjectTypeOptionsSubscription__
 *
 * To run a query within a React component, call `useListenToProjectTypeOptionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToProjectTypeOptionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToProjectTypeOptionsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToProjectTypeOptionsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToProjectTypeOptionsSubscription,
    IListenToProjectTypeOptionsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToProjectTypeOptionsSubscription,
    IListenToProjectTypeOptionsSubscriptionVariables
  >(ListenToProjectTypeOptionsDocument, options)
}
export type ListenToProjectTypeOptionsSubscriptionHookResult = ReturnType<
  typeof useListenToProjectTypeOptionsSubscription
>
export type ListenToProjectTypeOptionsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToProjectTypeOptionsSubscription>
