import * as React from 'react'

import { Icon, Position, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import { NOOP } from '~/client/src/shared/utils/noop'

import './Ribbons.scss'

export interface IProps {
  createNewGlobe: () => Promise<void>
  saveSelectedGlobe: () => Promise<void>
  isSavingGlobe: boolean
  isResetDisabled: boolean
  resetViewport: () => void

  state?: DesktopInitialState
}

const saveAsNewView = 'Save as new view'
const restoreZoom = 'Restore zoom'
const saveZoom = 'Save zoom'

@inject('state')
@observer
export default class GlobeLimitedRibbon extends React.Component<IProps> {
  @observable private isOptionsShown: boolean = false

  public render(): JSX.Element {
    const {
      saveSelectedGlobe,
      state,
      isSavingGlobe,
      resetViewport,
      isResetDisabled,
    } = this.props
    const isLoading = isSavingGlobe || state.loading.get(e.SAVE_GLOBE_VIEW)

    return (
      <div className="globe-limited-ribbon no-grow row absolute">
        <div className="row">
          <Tooltip
            className="bp3-dark no-grow"
            content={restoreZoom}
            position={Position.BOTTOM}
          >
            <div
              className={classList({
                'no-grow pointer pa5 bg-white reset-viewport-button row x-center y-center mr5':
                  true,
                disabled: isResetDisabled,
              })}
              onClick={resetViewport}
            >
              <Icons.ResetViewport className="no-grow" />
            </div>
          </Tooltip>
          <div
            className={classList({
              'create-new-holder row': true,
              disabled: isResetDisabled,
            })}
          >
            <div
              className={classList({
                'save-current-view-btn text center white large row x-center y-center full-height':
                  true,
                'disabled primary': isResetDisabled,
              })}
              onClick={isLoading ? NOOP : saveSelectedGlobe}
            >
              {isLoading ? <Loader /> : saveZoom}
            </div>
            <div className="divider no-grow" />
            <div
              onClick={this.isOptionsShown ? NOOP : this.toggleOptionsList}
              className={classList({
                'chevron-holder row x-center full-height pointer': true,
                disabled: isResetDisabled,
              })}
            >
              <Icon icon={IconNames.CHEVRON_DOWN} />
            </div>
            {this.isOptionsShown && (
              <MenuCloser closeMenu={this.toggleOptionsList}>
                <div
                  className="view-create-model bg-white py5 px10 row x-between brada4 pointer text large medium-bold"
                  onClick={this.createNewView}
                >
                  {saveAsNewView}
                </div>
              </MenuCloser>
            )}
          </div>
        </div>
      </div>
    )
  }

  @action.bound
  private createNewView(): void {
    this.props.createNewGlobe()
    this.toggleOptionsList()
  }

  @action.bound
  private toggleOptionsList(): void {
    this.isOptionsShown = !this.isOptionsShown
  }
}
