import { action, observable } from 'mobx'

import {
  CalendricalType,
  IInspectionOptions,
  IPermitTypeField,
  IWorkflowStep,
  SitePermitStatus,
  WorkflowStepType,
} from '~/client/graph'
import { defaultNotificationPermitFieldTypes } from '~/client/src/shared/constants/PermitFieldTypeConstants'
import formStatusesByStepsMap from '~/client/src/shared/constants/formStatusesByStepsMap'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { getCalendricalTypeDisplayName } from '~/client/src/shared/localization/enumDisplayTexts'
import ProjectDateStore, {
  HOURS_IN_DAY,
} from '~/client/src/shared/stores/ui/ProjectDate.store'
import { calendricalTypes } from '~/client/src/shared/utils/CalendricalTypeUtils'

const activeSteps = [WorkflowStepType.Approval, WorkflowStepType.Start]
const requestAndSubmissionSteps = [
  WorkflowStepType.Request,
  WorkflowStepType.Submission,
]
const allowedPrecededApprovalSteps = [
  WorkflowStepType.Request,
  WorkflowStepType.Submission,
  WorkflowStepType.Review,
  WorkflowStepType.OnSite,
]
const uniqueSteps = [
  WorkflowStepType.Approval,
  WorkflowStepType.BicInspection,
  WorkflowStepType.Finish,
  WorkflowStepType.Start,
  WorkflowStepType.RecurringInspection,
]

const isOnSiteStep = (stepType: WorkflowStepType): boolean =>
  stepType === WorkflowStepType.OnSite
const isReviewStep = (stepType: WorkflowStepType): boolean =>
  stepType === WorkflowStepType.Review
const isStartStep = (stepType: WorkflowStepType): boolean =>
  stepType === WorkflowStepType.Start
const isApprovalStep = (stepType: WorkflowStepType): boolean =>
  stepType === WorkflowStepType.Approval
const isRecurringStep = (stepType: WorkflowStepType): boolean =>
  stepType === WorkflowStepType.RecurringInspection
const isFinishStep = (stepType: WorkflowStepType): boolean =>
  stepType === WorkflowStepType.Finish

const MAX_MONTHS_VALUE = 12
const MAX_WEEKS_VALUE = 52
const MAX_DAYS_VALUE = 365
const DEADLINE_HOURS = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]

interface ISelectorOptions {
  name: string
  value: string | number
}

export default class WorkflowStepConfiguratorStore {
  @observable public workflowSteps: IWorkflowStep[]
  @observable public inspectionDeadlineTime: number =
    DEADLINE_HOURS[DEADLINE_HOURS.length - 1]
  @observable public inspectionFrequency: number = 1
  @observable public inspectionFrequencyType: CalendricalType =
    CalendricalType.Day
  @observable public selectedDaysToRepeat: number[] = []
  @observable public isAutoActivationEnabled: boolean = false

  public constructor(
    workflowSteps: IWorkflowStep[],
    inspectionOptions: IInspectionOptions,
    isAutoActivationEnabled: boolean,
    private readonly projectDateStore: ProjectDateStore,
    private readonly saveInspectionOptions: (
      options: IInspectionOptions,
    ) => void,
  ) {
    this.init(workflowSteps, inspectionOptions, isAutoActivationEnabled)
  }

  @action.bound
  public init(
    workflowSteps: IWorkflowStep[],
    inspectionOptions: IInspectionOptions,
    isAutoActivationEnabled: boolean,
  ) {
    this.workflowSteps = workflowSteps
    this.isAutoActivationEnabled = isAutoActivationEnabled

    if (inspectionOptions) {
      this.inspectionDeadlineTime = inspectionOptions.deadlineTime
      this.inspectionFrequency = inspectionOptions.inspectionFrequency
      this.inspectionFrequencyType = inspectionOptions.inspectionFrequencyType
      this.selectedDaysToRepeat = inspectionOptions.selectedDaysToRepeat
    }
  }

  @action.bound
  public changeDeadlineTime(deadlineTime: number) {
    this.inspectionDeadlineTime = deadlineTime

    this.updateInspectionOptions()
  }

  @action.bound
  public changeFrequency(newFrequency: number) {
    this.inspectionFrequency =
      newFrequency > this.inspectionMaxNumber
        ? this.inspectionMaxNumber
        : newFrequency

    this.updateInspectionOptions()
  }

  @action.bound
  public changeFrequencyType(newFrequencyType: CalendricalType) {
    this.inspectionFrequencyType = newFrequencyType

    if (this.inspectionFrequency > this.inspectionMaxNumber) {
      return this.changeFrequency(this.inspectionMaxNumber)
    }

    this.updateInspectionOptions()
  }

  @action.bound
  public changeDayToRepeat(dayNumber: number) {
    if (!this.selectedDaysToRepeat) {
      this.selectedDaysToRepeat = []
    }

    const index = this.selectedDaysToRepeat.findIndex(day => day === dayNumber)
    if (index === -1) {
      this.selectedDaysToRepeat.push(dayNumber)
    } else {
      this.selectedDaysToRepeat.splice(index, 1)
    }

    this.updateInspectionOptions()
  }

  public isLastStep = (stepIdx: number): boolean => {
    return this.workflowSteps.length - 1 === stepIdx
  }

  public isAutomaticStartShown = (
    stepIdx: number,
    stepType: WorkflowStepType,
  ): boolean => {
    return (
      !this.isLastStep(stepIdx) &&
      !this.hasStartStep &&
      isApprovalStep(stepType)
    )
  }

  public isAutoStartToggleShown = (stepType: WorkflowStepType): boolean => {
    return this.hasStartStep
      ? isStartStep(stepType)
      : activeSteps.includes(stepType)
  }

  public isRequestOrSubmissionStep = (stepType: WorkflowStepType): boolean => {
    return requestAndSubmissionSteps.includes(stepType)
  }

  public isAddStepBtnShown = (stepType: WorkflowStepType): boolean => {
    return (
      !isFinishStep(stepType) &&
      (!this.hasRecurringStep || !this.canHaveRecurringStep(stepType))
    )
  }

  public get notificationFields(): IPermitTypeField[] {
    return this.workflowSteps
      .flatMap(s => s.fields)
      .filter(f => defaultNotificationPermitFieldTypes.includes(f.type))
  }

  public get hasStartStep(): boolean {
    return this.workflowSteps.some(s => isStartStep(s.type))
  }

  public get hasApprovalStep(): boolean {
    return this.workflowSteps.some(s => isApprovalStep(s.type))
  }

  public get hasRecurringStep(): boolean {
    return this.workflowSteps.some(s => isRecurringStep(s.type))
  }

  public get startStepIdx(): number {
    return this.workflowSteps.findIndex(s => isStartStep(s.type))
  }

  public get approvalStepIdx(): number {
    return this.workflowSteps.findIndex(s => isApprovalStep(s.type))
  }

  public get inspectionMaxNumber(): number {
    switch (this.inspectionFrequencyType) {
      case CalendricalType.Day:
        return MAX_DAYS_VALUE
      case CalendricalType.Week:
        return MAX_WEEKS_VALUE
      case CalendricalType.Month:
        return MAX_MONTHS_VALUE
    }
  }

  public get deadlineTimeOptions(): ISelectorOptions[] {
    const { getFullHourLabel } = this.projectDateStore
    return DEADLINE_HOURS.map(option => ({
      name:
        option === HOURS_IN_DAY
          ? Localization.translator.endOfTheDay
          : getFullHourLabel(option),
      value: option,
    }))
  }

  public get inspectionFrequencyTypeOptions(): ISelectorOptions[] {
    return calendricalTypes.map(option => ({
      name: getCalendricalTypeDisplayName(option, this.inspectionFrequency),
      value: option,
    }))
  }

  public get shouldShowRepeatOnDays(): boolean {
    return this.inspectionFrequencyType === CalendricalType.Week
  }

  public getStepResultStatus = (
    isLastStep: boolean,
    stepType: WorkflowStepType,
  ): SitePermitStatus => {
    if (isLastStep) return SitePermitStatus.Done

    const allStatuses = formStatusesByStepsMap[stepType].filter(
      s => s !== SitePermitStatus.Changed,
    )
    return allStatuses[allStatuses.length - 1]
  }

  public getModalStepOptions(
    stepType: WorkflowStepType,
    stepIdx: number,
    categorySteps: WorkflowStepType[],
  ): WorkflowStepType[] {
    const isApprovalBehind = this.approvalStepIdx <= stepIdx
    const isStartBehind = this.startStepIdx <= stepIdx
    const isLastStep = this.isLastStep(stepIdx)
    const canHaveRecurring = this.canHaveRecurringStep(stepType, true)
    const isApprovalHidden =
      (this.hasStartStep && isStartBehind) ||
      this.isApprovalHiddenInOptions(stepIdx)
    const isApprovalNotBehind = this.hasApprovalStep && !isApprovalBehind

    return categorySteps.reduce((list, step) => {
      if (
        (uniqueSteps.includes(step) &&
          this.workflowSteps.some(ws => step === ws.type)) ||
        (!isReviewStep(step) && !isOnSiteStep(step) && isApprovalNotBehind) ||
        (isApprovalStep(step) && isApprovalHidden) ||
        (isFinishStep(step) && !isLastStep) ||
        (isRecurringStep(step) && !canHaveRecurring)
      ) {
        return list
      }

      list.push(step)

      return list
    }, [] as WorkflowStepType[])
  }

  private canHaveRecurringStep = (
    stepType: WorkflowStepType,
    shouldCheckToggle?: boolean,
  ): boolean => {
    return this.hasStartStep
      ? isStartStep(stepType)
      : isApprovalStep(stepType) &&
          (!shouldCheckToggle || this.isAutoActivationEnabled)
  }

  private isApprovalHiddenInOptions = (stepIdx: number): boolean => {
    return this.workflowSteps
      .slice(0, stepIdx + 1)
      .some(s => !allowedPrecededApprovalSteps.includes(s.type))
  }

  private updateInspectionOptions = () => {
    this.saveInspectionOptions({
      deadlineTime: this.inspectionDeadlineTime,
      inspectionFrequency: this.inspectionFrequency,
      inspectionFrequencyType: this.inspectionFrequencyType,
      selectedDaysToRepeat: this.selectedDaysToRepeat,
    })
  }
}
