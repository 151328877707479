import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { DeliveryTreeNodeTypes } from '../../enums/DeliveryTreeNodeTypes'
import Delivery from '../../models/Delivery'
import { IDeliveryTreeNode } from '../../models/IDeliveryTreeNode'
import DeliveriesStore from '../../stores/domain/Deliveries.store'
import CommonStore from '../../stores/ui/Common.store'
import DeliveryDetailsStore from '../DeliveryDetails/DeliveryDetails.store'
import DeliveryWorkflowCard from '../ExpandableWorkflowCard/components/DeliveryWorkflowCard'

interface IProps {
  node: IDeliveryTreeNode
  LocationContainerNodeType

  measureSizeFn: () => void

  deliveriesStore?: DeliveriesStore
  common?: CommonStore
  deliveryDetailsStore?: DeliveryDetailsStore
}

@inject('deliveriesStore', 'common', 'deliveryDetailsStore')
@observer
export default class DeliveriesTreeNode extends React.Component<IProps> {
  public render() {
    const { node, LocationContainerNodeType } = this.props
    if (node.nodeType === DeliveryTreeNodeTypes.DELIVERY) {
      return this.renderDeliveryNode()
    }
    return <LocationContainerNodeType node={node} shouldHideAmount={true} />
  }

  private renderDeliveryNode() {
    return (
      <div className="delivery-item bb-light-grey full-height full-width">
        <div className="delivery-item-main-col full-width full-height">
          <div className="delivery-item-data-col full-height full-width nowrap">
            <DeliveryWorkflowCard
              delivery={this.delivery}
              onClick={this.goToDetails}
              measureSizeFn={this.props.measureSizeFn}
            />
          </div>
        </div>
      </div>
    )
  }

  private goToDetails = () => {
    const { deliveryDetailsStore, common } = this.props
    deliveryDetailsStore.setViewMode(this.delivery)
    common.displayDeliveryDetailsView(this.delivery.id)
  }

  private get delivery(): Delivery {
    const {
      node: { id },
      deliveriesStore,
    } = this.props
    return deliveriesStore.byId.get(id as string)
  }
}
