import * as React from 'react'

import { Dialog } from '@blueprintjs/core'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IRestrictedOption, LocationType } from '~/client/graph'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { Loader } from '~/client/src/shared/components/Loader'
import LocationPicker from '~/client/src/shared/components/LocationPicker/LocationPicker'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { LOAD_LOCATIONS_AND_LISTEN_CHANGES } from '~/client/src/shared/stores/EventStore/eventConstants'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

import LocationRestrictionsStore from './LocationRestrictions.store'

import './LocationRestrictions.scss'

interface IProps {
  restrictedLocations: IRestrictedOption[]
  restrictedTypes: LocationType[]
  isEquipmentOnly: boolean
  onChange: (restrictedLocations: IRestrictedOption[]) => void

  eventsStore?: DesktopEventStore
  tagsStore?: TagsStore
}

// translated

@inject('eventsStore', 'tagsStore')
@observer
export default class LocationRestrictions extends React.Component<IProps> {
  private readonly store: LocationRestrictionsStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new LocationRestrictionsStore(
      props.tagsStore,
      props.restrictedLocations,
      props.onChange,
    )
  }

  public get isLoading(): boolean {
    const { loading } = this.props.eventsStore.appState
    return loading.get(LOAD_LOCATIONS_AND_LISTEN_CHANGES)
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { restrictedLocations, onChange } = this.props

    if (restrictedLocations !== prevProps.restrictedLocations) {
      this.store.initRestrictedLocations(restrictedLocations)
    }
    if (onChange !== prevProps.onChange) {
      this.store.initOnChange(onChange)
    }
  }

  public render() {
    if (this.isLoading) {
      return <Loader />
    }

    const {
      hideSelector,
      handleChange,
      showSelector,
      shouldShowSelector,
      handleApplyChanges,
      handleResetSelection,
      restrictedLocationsIds,
    } = this.store
    const { isEquipmentOnly, restrictedTypes } = this.props

    return (
      <>
        <Dialog
          isOpen={shouldShowSelector}
          onClose={hideSelector}
          className="restricted-locations-dialog bg-white"
        >
          <LocationPicker
            title={
              isEquipmentOnly
                ? Localization.translator.selectRestrictedEquipments
                : Localization.translator.selectRestrictedLocations
            }
            onChange={handleChange}
            onApplyChanges={handleApplyChanges}
            onClose={hideSelector}
            applyButtonTitle={Localization.translator.restrict}
            selectedIds={restrictedLocationsIds}
            restrictedObjectTypes={restrictedTypes}
            onResetSelection={handleResetSelection}
          />
        </Dialog>

        <div className="mb10">{this.restrictedLocationsInfo}</div>

        <span
          className="text large bold blue-highlight pointer"
          onClick={showSelector}
        >
          {isEquipmentOnly
            ? Localization.translator.restrictEquipments
            : Localization.translator.restrictLocations}
        </span>
      </>
    )
  }

  private get restrictedLocationsInfo(): JSX.Element {
    return (
      <span
        className={classList({
          'text large': true,
          light: !this.store.restrictedLocations?.length,
        })}
      >
        {this.restrictedNote}
      </span>
    )
  }

  private get restrictedNote(): string {
    const count = this.store.restrictedLocations?.length

    if (count) {
      return this.props.isEquipmentOnly
        ? Localization.translator.restrictedXEquipments(count)
        : Localization.translator.restrictedXLocations(count)
    }

    return this.props.isEquipmentOnly
      ? Localization.translator.allEquipmentObjectsDisplayOnForm
      : Localization.translator.allLocationObjectsDisplayOnForm
  }
}
