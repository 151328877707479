import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import MultiSelectFilter, {
  IMultiSelectOption,
} from '~/client/src/shared/components/MultiSelectFilter/MultiSelectFilter'

import Localization from '../../shared/localization/LocalizationManager'

// translated

interface IProps {
  options: IMultiSelectOption[]
  additionalTitleStyle?: string
  filterType?: string
  hasIcon?: boolean
  values: string[]
  title: string
  onChange(values: string[], filterType?: string): void
}

@observer
export default class DeliveriesMultiSelectFilter extends MultiSelectFilter<IProps> {
  public UNSAFE_componentWillMount() {
    // set all options checked by default
    this.onSelectAllClicked()
  }

  public UNSAFE_componentWillReceiveProps(newProps: IProps) {
    // set new options checked
    const { values, options } = newProps
    const newOptionsValues = []
    options.forEach(option => {
      if (!this.props.options.some(({ value }) => value === option.value)) {
        newOptionsValues.push(option.value)
      }
    })
    if (newOptionsValues.length) {
      newProps.onChange(values.concat(newOptionsValues))
    }
  }

  public render() {
    const titleClassName =
      'row no-flex-children x-between y-end ' + this.props.additionalTitleStyle

    return (
      <div>
        <div className={titleClassName}>
          {this.title}
          {this.selectAllButton}
        </div>
        <div className="pt10">{this.options}</div>
      </div>
    )
  }

  protected get selectAllButton() {
    const { options, values } = this.props
    const isAllSelected = options.every(({ value }) => values.includes(value))

    const clickHandler = isAllSelected
      ? this.onClearAllClicked
      : this.onSelectAllClicked
    const caption = isAllSelected
      ? Localization.translator.clearAll_items
      : Localization.translator.selectAll_items

    return (
      <div
        className={classList({
          'select-all-button': true,
          'blue-btn': !isAllSelected,
          'white-btn': isAllSelected,
        })}
        onClick={clickHandler}
      >
        {caption}
      </div>
    )
  }
}
