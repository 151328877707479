import { computed, observable } from 'mobx'

import { TagIconType } from '~/client/src/shared/enums/TagIcon'
import { TagType } from '~/client/src/shared/enums/TagType'
import Tag, { ITag } from '~/client/src/shared/models/Tag'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'

export interface IBaseTagsStore {
  getInstanceById: (instanceId: string) => ITag
  byId?: Map<string, ITag>
  save?: (instance: any, callback?: (id: string) => void) => void
  saveMany?: (instances: any[], callback?: () => void) => void
  delete?: (instanceId: string, callback?: () => void) => void
}

export default abstract class BaseTagsStore implements IBaseTagsStore {
  @observable public isDataReceived: boolean = false

  public abstract readonly byId: Map<string, ITag>
  protected abstract readonly iconName: TagIconType
  protected abstract readonly tagType: TagType

  public constructor(protected readonly eventsStore: EventsStore) {}

  @computed
  public get list(): ITag[] {
    return Array.from(this.byId.values())
  }

  public receiveList(dtos: any[]) {
    this.clearList()

    dtos.forEach(dto => {
      this.byId.set(
        dto.id,
        new Tag(dto.id, dto.name, dto.color, this.iconName, this.tagType),
      )
    })

    this.isDataReceived = true
  }

  public receiveOne(id: string, dto: any) {
    if (dto) {
      this.byId.set(
        dto.id,
        new Tag(dto.id, dto.name, dto.color, this.iconName, this.tagType),
      )
    } else {
      this.byId.delete(id)
    }
  }

  public clearList() {
    this.byId.clear()
    this.isDataReceived = false
  }

  public getInstanceById = (instanceId: string): ITag => {
    return this.byId.get(instanceId)
  }

  public getInstancesAsStringByIds = (
    instancesIds: string[],
    joinChar = ', ',
  ) => {
    return (instancesIds || [])
      .map(id => (this.byId.get(id) || {}).name)
      .filter(instance => !!instance)
      .join(joinChar)
  }

  public toCsvCell(instancesIds: string[]): string {
    return (instancesIds || [])
      .map(tagId => {
        const tag = this.getInstanceById(tagId)
        return tag ? `[${tag.name}]` : ''
      })
      .filter(t => !!t)
      .join(' ')
  }
}
