import * as React from 'react'

import { action } from 'mobx'
import { observer } from 'mobx-react'

import { ISheetData } from '~/client/graph'
import Checkbox from '~/client/src/shared/components/Checkbox'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import ProjectScheduleUploadStore from '../../ProjectScheduleUpload.store'

export interface IProps {
  sheet: ISheetData
  projectScheduleUploadStore: ProjectScheduleUploadStore
}

@observer
export default class SheetRow extends React.Component<IProps> {
  public render() {
    const { name, columns } = this.props.sheet

    return (
      <div className="row mb5 pointer" onClick={this.onCheckboxClick}>
        <div className="row y-center no-flex-children">
          <Checkbox isChecked={this.isSheetChecked} />
          {name}
        </div>
        <div className="text large light">
          {Localization.translator.xColumns(columns.length)}
        </div>
      </div>
    )
  }

  private get isSheetChecked(): boolean {
    const { chosenSheetName } = this.props.projectScheduleUploadStore

    return chosenSheetName === this.props.sheet.name
  }

  @action.bound
  private onCheckboxClick() {
    const { projectScheduleUploadStore, sheet } = this.props

    projectScheduleUploadStore.chooseSheetName(sheet.name)
  }
}
