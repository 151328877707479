import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IProjectSuOptionsFieldsFragment = Pick<
  Types.IProjectStatusUpdateOptions,
  | 'createdAt'
  | 'id'
  | 'isManpowerDisabled'
  | 'isPercentCompleteDisabled'
  | 'updatedAt'
  | 'projectId'
>

export type IGetProjectStatusUpdateOptionsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetProjectStatusUpdateOptionsQuery = {
  projectStatusUpdateOptions?: Types.Maybe<{
    data: Array<IProjectSuOptionsFieldsFragment>
  }>
}

export type ISaveProjectStatusUpdateOptionsMutationVariables = Types.Exact<{
  projectStatusUpdateOptions: Types.IProjectStatusUpdateOptionsInput
}>

export type ISaveProjectStatusUpdateOptionsMutation = {
  saveProjectStatusUpdateOptions?: Types.Maybe<IProjectSuOptionsFieldsFragment>
}

export type IListenToProjectStatusUpdateOptionsSubscriptionVariables =
  Types.Exact<{
    projectId: Types.Scalars['ObjectId']
  }>

export type IListenToProjectStatusUpdateOptionsSubscription = {
  projectStatusUpdateOptions?: Types.Maybe<
    Pick<Types.IProjectStatusUpdateOptionsChangeEvent, 'id'> & {
      item?: Types.Maybe<IProjectSuOptionsFieldsFragment>
    }
  >
}

export const ProjectSuOptionsFieldsFragmentDoc = gql`
  fragment ProjectSUOptionsFields on ProjectStatusUpdateOptions {
    createdAt
    id
    isManpowerDisabled
    isPercentCompleteDisabled
    updatedAt
    projectId
  }
`
export const GetProjectStatusUpdateOptionsDocument = gql`
  query GetProjectStatusUpdateOptions($projectId: ObjectId!) {
    projectStatusUpdateOptions(projectId: $projectId, limit: 1) {
      data {
        ...ProjectSUOptionsFields
      }
    }
  }
  ${ProjectSuOptionsFieldsFragmentDoc}
`

/**
 * __useGetProjectStatusUpdateOptionsQuery__
 *
 * To run a query within a React component, call `useGetProjectStatusUpdateOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectStatusUpdateOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectStatusUpdateOptionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectStatusUpdateOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetProjectStatusUpdateOptionsQuery,
    IGetProjectStatusUpdateOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetProjectStatusUpdateOptionsQuery,
    IGetProjectStatusUpdateOptionsQueryVariables
  >(GetProjectStatusUpdateOptionsDocument, options)
}
export function useGetProjectStatusUpdateOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetProjectStatusUpdateOptionsQuery,
    IGetProjectStatusUpdateOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetProjectStatusUpdateOptionsQuery,
    IGetProjectStatusUpdateOptionsQueryVariables
  >(GetProjectStatusUpdateOptionsDocument, options)
}
export type GetProjectStatusUpdateOptionsQueryHookResult = ReturnType<
  typeof useGetProjectStatusUpdateOptionsQuery
>
export type GetProjectStatusUpdateOptionsLazyQueryHookResult = ReturnType<
  typeof useGetProjectStatusUpdateOptionsLazyQuery
>
export type GetProjectStatusUpdateOptionsQueryResult = Apollo.QueryResult<
  IGetProjectStatusUpdateOptionsQuery,
  IGetProjectStatusUpdateOptionsQueryVariables
>
export const SaveProjectStatusUpdateOptionsDocument = gql`
  mutation SaveProjectStatusUpdateOptions(
    $projectStatusUpdateOptions: ProjectStatusUpdateOptionsInput!
  ) {
    saveProjectStatusUpdateOptions(
      projectStatusUpdateOptions: $projectStatusUpdateOptions
    ) {
      ...ProjectSUOptionsFields
    }
  }
  ${ProjectSuOptionsFieldsFragmentDoc}
`
export type ISaveProjectStatusUpdateOptionsMutationFn = Apollo.MutationFunction<
  ISaveProjectStatusUpdateOptionsMutation,
  ISaveProjectStatusUpdateOptionsMutationVariables
>

/**
 * __useSaveProjectStatusUpdateOptionsMutation__
 *
 * To run a mutation, you first call `useSaveProjectStatusUpdateOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProjectStatusUpdateOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProjectStatusUpdateOptionsMutation, { data, loading, error }] = useSaveProjectStatusUpdateOptionsMutation({
 *   variables: {
 *      projectStatusUpdateOptions: // value for 'projectStatusUpdateOptions'
 *   },
 * });
 */
export function useSaveProjectStatusUpdateOptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveProjectStatusUpdateOptionsMutation,
    ISaveProjectStatusUpdateOptionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveProjectStatusUpdateOptionsMutation,
    ISaveProjectStatusUpdateOptionsMutationVariables
  >(SaveProjectStatusUpdateOptionsDocument, options)
}
export type SaveProjectStatusUpdateOptionsMutationHookResult = ReturnType<
  typeof useSaveProjectStatusUpdateOptionsMutation
>
export type SaveProjectStatusUpdateOptionsMutationResult =
  Apollo.MutationResult<ISaveProjectStatusUpdateOptionsMutation>
export type SaveProjectStatusUpdateOptionsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveProjectStatusUpdateOptionsMutation,
    ISaveProjectStatusUpdateOptionsMutationVariables
  >
export const ListenToProjectStatusUpdateOptionsDocument = gql`
  subscription ListenToProjectStatusUpdateOptions($projectId: ObjectId!) {
    projectStatusUpdateOptions(projectId: $projectId) {
      id
      item {
        ...ProjectSUOptionsFields
      }
    }
  }
  ${ProjectSuOptionsFieldsFragmentDoc}
`

/**
 * __useListenToProjectStatusUpdateOptionsSubscription__
 *
 * To run a query within a React component, call `useListenToProjectStatusUpdateOptionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToProjectStatusUpdateOptionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToProjectStatusUpdateOptionsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToProjectStatusUpdateOptionsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToProjectStatusUpdateOptionsSubscription,
    IListenToProjectStatusUpdateOptionsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToProjectStatusUpdateOptionsSubscription,
    IListenToProjectStatusUpdateOptionsSubscriptionVariables
  >(ListenToProjectStatusUpdateOptionsDocument, options)
}
export type ListenToProjectStatusUpdateOptionsSubscriptionHookResult =
  ReturnType<typeof useListenToProjectStatusUpdateOptionsSubscription>
export type ListenToProjectStatusUpdateOptionsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToProjectStatusUpdateOptionsSubscription>
