import * as React from 'react'

import { Classes, Dialog } from '@blueprintjs/core'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { Loader } from '~/client/src/shared/components/Loader'
import PdfDocument from '~/client/src/shared/components/PdfDocument/PdfDocument'
import StruxhubSelect from '~/client/src/shared/components/StruxhubInputs/StruxhubSelect'

const LOADER_SIZE = 35

const selectPdfPage = 'Select PDF page'
const submit = 'Submit'
const page = 'Page'
const pageOf = (currPage: number, numOfAllPages: number) =>
  `${currPage} OF ${numOfAllPages}`

interface IProps {
  pdfFile: File
  onDialogSubmit: (selectedPage: number) => void
}

@observer
export default class PdfPageSelectorDialog extends React.Component<IProps> {
  @observable private pagesCount: number
  @observable private currentPageNumber: number = 1
  @observable private isLoading: boolean = true

  public render() {
    const { pdfFile } = this.props

    const bodyClassName = `${Classes.DIALOG_BODY} overflow-auto relative`
    const footerClassName = `${Classes.DIALOG_FOOTER} ma0 py10 px30 bt-light-input-border`

    return (
      <Dialog
        className="pdf-selector-dialog bg-white ba-light-input-border"
        isOpen={true}
        title={selectPdfPage}
        isCloseButtonShown={false}
        canOutsideClickClose={false}
        canEscapeKeyClose={false}
      >
        <div className={bodyClassName}>
          {this.isLoading && this.loaderElement}
          <PdfDocument
            file={pdfFile}
            className="pdf-document"
            pageClassName={classList({
              'pdf-page-view': true,
              hidden: this.isLoading,
            })}
            loaderSize={LOADER_SIZE}
            pageNumber={this.currentPageNumber}
            onLoadSuccess={this.onDocumentLoadSuccess}
            onPageRenderSuccess={this.setLoadingDone}
          />
        </div>
        <div className={footerClassName}>
          <div className="col">
            <div className="row x-center y-center py5">
              <div className="mw300">
                <StruxhubSelect
                  label={page}
                  isDisabled={this.isLoading}
                  isRequired={true}
                  isRequiredTextHidden={true}
                  value={this.currentPageNumber.toString()}
                  onChange={this.onSelectPage}
                >
                  {this.allPages.map(page => (
                    <option key={page} value={page} className="text large">
                      {pageOf(page, this.pagesCount)}
                    </option>
                  ))}
                </StruxhubSelect>
              </div>
            </div>
            <div className="row x-center y-center py5 no-flex-children">
              <div
                className={classList({
                  'pointer text extra-large primary-blue bold px8 py6 brada4 submit-btn':
                    true,
                  'inactive-element': this.isLoading,
                })}
                onClick={this.onDialogSubmit}
              >
                {submit}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }

  private get loaderElement(): JSX.Element {
    return (
      <div className="relative row x-center y-center full-height full-width pa30">
        <Loader size={LOADER_SIZE} />
      </div>
    )
  }

  @action.bound
  private setLoadingActive() {
    this.isLoading = true
  }

  @action.bound
  private setLoadingDone() {
    this.isLoading = false
  }

  @action.bound
  private onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    this.pagesCount = numPages

    this.setCurrentPageNumber(1)
  }

  @action.bound
  private setCurrentPageNumber(pageNumber: number) {
    this.currentPageNumber = pageNumber
  }

  @action.bound
  private onSelectPage(event: any) {
    this.setLoadingActive()

    this.setCurrentPageNumber(Number(event?.target?.value))
  }

  @action.bound
  private onDialogSubmit() {
    this.props.onDialogSubmit(this.currentPageNumber)
  }

  private get allPages(): number[] {
    return [...Array(this.pagesCount).keys()]?.map(p => ++p)
  }
}
