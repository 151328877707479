import * as React from 'react'

import { Icon, IconSize } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import FilterHandleButton from '../../../../shared/components/Filters/components/FilterHandleButton/FilterHandleButton'
import DateSelector, {
  IDateSelectorProps,
} from '../../../components/Filters/DateSelector/DateSelector'
import DateSelectorStore from '../../../components/Filters/DateSelector/DateSelector.store'
import TwoMonthsDatePickerStore from '../../../components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import { PHOTOS_PER_PAGE } from '../Photos'
import PhotosPageStore from '../PhotosPageStore.store'

interface IProps extends IDateSelectorProps {
  photosStore: PhotosPageStore
}

@observer
export default class PhotoDateSelector extends React.Component<IProps> {
  public render() {
    return (
      <DateSelector
        {...this.props}
        customDatePickerViewRenderer={this.renderDatePickerView}
      />
    )
  }

  protected renderDatePickerView = (
    store: DateSelectorStore,
    datePickerStore: TwoMonthsDatePickerStore,
  ) => {
    const getCaption = () => {
      const { photosCount, selectedPage } = this.props.photosStore
      const pageCount = Math.ceil(photosCount / PHOTOS_PER_PAGE)

      if (this.isFullProject && !pageCount) {
        return '0'
      }

      return this.isFullProject
        ? `${selectedPage + 1} of ${pageCount}`
        : store.datesButtonCaption
    }

    const applyNextPeriod = event => {
      if (this.isFullProject) {
        this.selectNextPage()
        return
      }
      store.moveDatesForward(event)
    }

    const applyPrevPeriod = event => {
      if (this.isFullProject) {
        this.selectPrevPage()
        return
      }
      store.moveDatesBack(event)
    }

    const {
      photosStore: { photosCount, selectedPage },
      filters: { isDateFilterActive },
    } = this.props
    const { isShown } = datePickerStore
    const { isCurrentActive, toggleDatePicker, canChangeDates } = store

    const pageCount = Math.ceil(photosCount / PHOTOS_PER_PAGE)
    const shouldDisableNextButton =
      this.isFullProject && (selectedPage === pageCount - 1 || !pageCount)
    const shouldDisablePrevButton =
      this.isFullProject && (selectedPage === 0 || !pageCount)

    return (
      <div className="row x-end">
        <div className="no-grow pr5 pl15">
          <Icon
            className={classList({
              'gant-left-navigation left-navigation': true,
              'inactive-element': shouldDisablePrevButton,
            })}
            size={IconSize.LARGE}
            icon={IconNames.CARET_LEFT}
            onClick={applyPrevPeriod}
          />
        </div>

        <FilterHandleButton
          className={classList({
            'row no-grow no-flex-children date-handle-button text grey-light large':
              true,
            'zone-map-view-calendar default-cursor': !canChangeDates,
            'ba-palette-grey': !isCurrentActive,
            'current-lookahead ba-palette-secondary-orange secondary-orange':
              isCurrentActive,
          })}
          onClick={toggleDatePicker}
          isSelected={isShown}
          isActive={isDateFilterActive}
          hasIndent={false}
        >
          <span>{getCaption()}</span>
        </FilterHandleButton>

        <div className="no-grow pl5 pr15">
          <Icon
            className={classList({
              'right-navigation': true,
              'inactive-element': shouldDisableNextButton,
            })}
            size={IconSize.LARGE}
            icon={IconNames.CARET_RIGHT}
            onClick={applyNextPeriod}
          />
        </div>
      </div>
    )
  }

  private get isFullProject(): boolean {
    const { isDateFilterActive } = this.props.photosStore.dateFilters
    return !isDateFilterActive
  }

  private selectNextPage = () => {
    this.props.photosStore.selectedPage += 1
  }

  private selectPrevPage = () => {
    this.props.photosStore.selectedPage -= 1
  }
}
