import * as React from 'react'

import { Switch } from '@blueprintjs/core'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import { HGap } from '~/client/src/shared/components/Layout'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import SitemapsSettingsStore from '../SitemapsSettings.store'
import ThresholdsChart from './ThresholdsChart'
import ThresholdsSelector from './ThresholdsSelector'

interface IProps {
  store?: SitemapsSettingsStore
  state?: DesktopInitialState
}

@inject('state')
@observer
export default class ThresholdsSetup extends React.Component<IProps> {
  public render() {
    return (
      <div className="thresholds-setup">
        <div className="text extra-large bold mb30">
          {Localization.translator.sitemapsDescriptions.configure}
        </div>
        <div className="col">
          {this.renderHeader()}
          {this.renderOnTrackRow()}
          {this.renderWatchRow()}
          {this.renderBehindRow()}
          {this.renderToggles()}
        </div>
      </div>
    )
  }

  private get store(): SitemapsSettingsStore {
    return this.props.store
  }

  private renderToggles() {
    const { projectStatusUpdateOptions, projectColoringOptions } =
      this.props.state
    const { toggleManpowerEnabling, toggleWWPColoringEnabling } = this.store
    return (
      <>
        {this.renderSubAppToggle(
          Localization.translator.manpower,
          projectStatusUpdateOptions.isManpowerDisabled,
          toggleManpowerEnabling,
        )}
        {this.renderSubAppToggle(
          Localization.translator.wwpColoring,
          projectColoringOptions.isWWPColoringDisabled,
          toggleWWPColoringEnabling,
        )}
      </>
    )
  }

  private renderSubAppToggle(
    label: string,
    isEnabled: boolean,
    toggle: () => void,
  ) {
    return (
      <div className="parameter-value pt7 no-grow">
        <div className="row no-grow pr20 pt10">
          <div className="text large no-grow nowrap">{label}</div>
          <Switch
            className="no-grow primary-blue-switch bp3-align-right no-outline-container"
            checked={isEnabled}
            onChange={toggle}
          />
        </div>
      </div>
    )
  }

  private renderHeader() {
    const { toggleIsManpowerDisabled, isManpowerDisabled } = this.store

    return (
      <div className="row mb10">
        <div className="text large bold bb-light-grey pb5 small-col mr30">
          {Localization.translator.status}
        </div>
        <div className="text large bold bb-light-grey pb5">
          {Localization.translator.setThresholdForDDays}
        </div>
        <HGap size="90" />
        <div className="text large bold bb-light-grey pb5">
          {Localization.translator.setThresholdForDManpower}
          <div
            className={classList({
              'toggle-manpower': true,
              'ba-primary-blue bg-blue': !isManpowerDisabled,
              'ba-grey bg-grey manpower-disabled': isManpowerDisabled,
            })}
            onClick={toggleIsManpowerDisabled}
          />
        </div>
      </div>
    )
  }

  private renderOnTrackRow() {
    const {
      updateDeltaDaysThresholdUnit,
      updateDeltaDaysThresholdValue,
      updateManpowerThresholdValue,
      updateManpowerThresholdUnit,
      deltaDaysValue,
      deltaDaysBehindValue,
      deltaDaysUnit,
      manpowerValue,
      manpowerUnit,
      deltaDaysChartLabels: { behind, ahead },
      manpowerChartLabels: { under, over },
      isManpowerDisabled,
    } = this.store

    return (
      <div className="row y-start mb30">
        <div className="text extra-large bold green small-col mr30">
          {Localization.translator.onTrack}
        </div>
        <div className="col">
          <ThresholdsSelector
            value={deltaDaysValue}
            unit={deltaDaysUnit}
            onValueChange={updateDeltaDaysThresholdValue}
            onUnitChange={updateDeltaDaysThresholdUnit}
            inputLabel={Localization.translator.dDays + ' ≥'}
            numberUnitLabel={Localization.translator.days}
          />
          <ThresholdsChart
            value={deltaDaysValue}
            smallerValue={isManpowerDisabled ? deltaDaysBehindValue : 0}
            negativeLabel={behind}
            positiveLabel={ahead}
            isPositiveArrowShown={true}
          />
        </div>
        <div
          className={classList({
            'small-col': true,
            'inactive-element': isManpowerDisabled,
          })}
        >
          <div className="text center extra-large center">
            {Localization.translator.operatorValues.and}
          </div>
        </div>
        <div
          className={classList({
            col: true,
            'inactive-element': isManpowerDisabled,
          })}
        >
          <ThresholdsSelector
            value={manpowerValue}
            unit={manpowerUnit}
            onValueChange={updateManpowerThresholdValue}
            onUnitChange={updateManpowerThresholdUnit}
            inputLabel={Localization.translator.dManpower + ' ≤'}
          />
          <ThresholdsChart
            value={manpowerValue}
            negativeLabel={under}
            positiveLabel={over}
            isNegativeArrowShown={true}
            areColorsInverted={true}
          />
        </div>
      </div>
    )
  }

  private renderWatchRow() {
    const {
      deltaDaysValue,
      manpowerValue,
      deltaDaysChartLabels: { behind, ahead },
      manpowerChartLabels: { under, over },
      isManpowerDisabled,
      deltaDaysBehindValue,
    } = this.store

    return (
      <div className="row y-start mb30">
        <div className="text extra-large bold gold small-col mr30">
          {Localization.translator.watch}
        </div>
        <div className="col">
          <div className="text extra-large">
            {isManpowerDisabled
              ? `${deltaDaysBehindValue} < ${Localization.translator.dDays} < ${deltaDaysValue}`
              : Localization.translator.dDays + ' ≥ ' + deltaDaysValue}
          </div>
          <ThresholdsChart
            value={deltaDaysValue}
            smallerValue={isManpowerDisabled ? deltaDaysBehindValue : 0}
            negativeLabel={behind}
            positiveLabel={ahead}
            isMidArrowShown={isManpowerDisabled}
            isPositiveArrowShown={!isManpowerDisabled}
          />
        </div>
        <div
          className={classList({
            'small-col': true,
            'inactive-element': isManpowerDisabled,
          })}
        >
          <div className="text center extra-large center">
            {Localization.translator.operatorValues.and}
          </div>
        </div>
        <div
          className={classList({
            col: true,
            'inactive-element': isManpowerDisabled,
          })}
        >
          <div className="text extra-large">
            {Localization.translator.dManpower} &gt; {manpowerValue}
          </div>
          <ThresholdsChart
            value={manpowerValue}
            negativeLabel={under}
            positiveLabel={over}
            isPositiveArrowShown={true}
            areColorsInverted={true}
          />
        </div>
      </div>
    )
  }

  private renderBehindRow() {
    const {
      manpowerValue,
      deltaDaysChartLabels: { behind, ahead },
      manpowerChartLabels: { under, over },
      isManpowerDisabled,
      deltaDaysValue,
      deltaDaysBehindValue,
      deltaDaysUnit,
      updateDeltaDaysBehindThresholdValue,
    } = this.store

    const manpowerLabel = [
      Localization.translator.dManpower,
      '≤',
      manpowerValue,
      Localization.translator.operatorValues.or,
      Localization.translator.dManpower,
      '>',
      manpowerValue,
    ].join(' ')

    return (
      <div className="row y-start mb30">
        <div className="text extra-large bold red small-col mr30">
          {Localization.translator.behind}
        </div>
        <div className="col">
          {isManpowerDisabled ? (
            <ThresholdsSelector
              value={deltaDaysBehindValue}
              unit={deltaDaysUnit}
              onValueChange={updateDeltaDaysBehindThresholdValue}
              inputLabel={Localization.translator.dDays + ' ≤'}
              numberUnitLabel={Localization.translator.days}
              hideUnitSelector={true}
            />
          ) : (
            <div>{Localization.translator.dDays + ' < ' + deltaDaysValue}</div>
          )}
          <ThresholdsChart
            value={isManpowerDisabled ? deltaDaysBehindValue : deltaDaysValue}
            smallerValue={isManpowerDisabled ? deltaDaysValue : 0}
            negativeLabel={behind}
            positiveLabel={ahead}
            isNegativeArrowShown={true}
          />
        </div>
        <div
          className={classList({
            'small-col': true,
            'inactive-element': isManpowerDisabled,
          })}
        >
          <div className="text center extra-large center">
            {Localization.translator.operatorValues.and}
          </div>
        </div>
        <div
          className={classList({
            col: true,
            'inactive-element': isManpowerDisabled,
          })}
        >
          <div className="text extra-large">{manpowerLabel}</div>
          <ThresholdsChart
            value={manpowerValue}
            negativeLabel={under}
            positiveLabel={over}
            isPositiveArrowShown={true}
            isNegativeArrowShown={true}
            areColorsInverted={true}
          />
        </div>
      </div>
    )
  }
}
