import { ReactNode } from 'react'

import { action, computed, observable } from 'mobx'

import { ICompany, IMergeResult } from '~/client/graph'
import CompaniesListStore from '~/client/src/desktop/components/CompaniesList/CompaniesList.store'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Company from '~/client/src/shared/models/Company'
import {
  ACTIVATE_COMPANIES,
  DELETE_COMPANIES,
  LOAD_AND_LISTEN_TO_COMPANIES,
  MERGE_COMPANIES,
  SAVE_COMPANIES,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import { IFilters } from '~/client/src/shared/stores/InitialState'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import { ToastTheme, showToast } from '~/client/src/shared/utils/toaster'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

export enum ActionType {
  GroupBy = 'groupBy',
  CompanyFilter = 'company',
  AddCompany = 'addCompany',
}

function getActionTypeTranslate(value: ActionType) {
  switch (value) {
    case ActionType.AddCompany:
      return Localization.translator.addCompany
    case ActionType.CompanyFilter:
      return Localization.translator.company
    case ActionType.GroupBy:
      return Localization.translator.groupBy
    default:
      throw new Error(value + ' is unhandled')
  }
}

export type IAction = {
  type: ActionType
  title: string
  isActive: boolean
  isEnabled: boolean
  onClick: (actionType: ActionType) => void
}

export default class CompaniesDirectoryStore {
  @observable public activeActionType: ActionType = null
  @observable public shouldShowCompanyModal: boolean = false
  @observable public shouldShowBulkModal: boolean = false
  @observable public shouldShowConfirmDeleteDialog: boolean = false
  @observable public lastUpdatedCompanyId: string = null
  @observable public errorMessage: string = ''

  private isAddingMode: boolean = false
  public isMergingMode: boolean = false

  public constructor(
    private readonly state: DesktopInitialState,
    private readonly companiesStore: CompaniesStore,
    private readonly companiesListStore: CompaniesListStore,
    private readonly mergeResultRenderer: (
      title: string,
      mergeResult: IMergeResult,
    ) => ReactNode,
  ) {}

  public get isRelatedDataLoading(): boolean {
    return this.state.loading.get(LOAD_AND_LISTEN_TO_COMPANIES)
  }

  public get areCompaniesBeingUpdated(): boolean {
    return (
      this.state.loading.get(SAVE_COMPANIES) ||
      this.state.loading.get(DELETE_COMPANIES) ||
      this.state.loading.get(ACTIVATE_COMPANIES) ||
      this.state.loading.get(MERGE_COMPANIES)
    )
  }

  public get actions(): IAction[] {
    return Object.values(ActionType).map((type: ActionType) => ({
      type,
      title: getActionTypeTranslate(type),
      isActive: this.activeActionType === type,
      isEnabled: type === ActionType.AddCompany,
      onClick: this.handleActionClick.bind(this, type),
    }))
  }

  @action.bound
  public toggleConfirmDeleteDialog(isShow: boolean) {
    this.shouldShowConfirmDeleteDialog = isShow
    if (!isShow) {
      this.resetErrorMessage()
    }
  }

  public get allCompanies(): Company[] {
    return this.companiesStore.allCompanies
  }

  @action.bound
  public async updateCompaniesSettings(items: Company[]) {
    const company = this.companiesStore.getCompanyById(
      items[items.length - 1]?.id,
    )
    this.setLastUpdatedCompanyId(company.id)

    try {
      await this.companiesStore.saveMany(items)
    } catch (e) {
      this.errorMessage = e
    }
  }

  @action.bound
  public resetErrorMessage() {
    this.errorMessage = EMPTY_STRING
  }

  @computed
  // todo: should return ICompany - update usages
  public get selectedCompanies(): Company[] {
    return this.companiesListStore.selectedInstancesIds
      .map(id => this.companiesStore.getCompanyById(id)?.asJson as Company)
      .filter(c => !!c)
  }

  @action.bound
  public async deleteCompanies(companies: Company[]) {
    await this.companiesStore.deleteMany(companies.map(c => c.id))

    this.shouldShowConfirmDeleteDialog = false
    this.companiesListStore.resetSelection()
  }

  @action.bound
  public async activateCompanies(companies: Company[]) {
    await this.companiesStore.activateMany(companies.map(c => c.id))

    this.shouldShowConfirmDeleteDialog = false
    this.companiesListStore.resetSelection()
  }

  @action.bound
  public showBulkEditSideBar() {
    this.shouldShowBulkModal = true
  }

  @action.bound
  public hideBulkEditSideBar() {
    this.resetErrorMessage()
    this.shouldShowBulkModal = false
  }

  @computed
  // todo: should return ICompany - update usages
  public get selectedCompaniesToEdit(): Company[] {
    return this.companiesListStore.hiddenSelectedInstancesIds
      .map(id => this.companiesStore.getCompanyById(id)?.asJson as Company)
      .filter(c => !!c)
  }

  public get itemsToEdit(): ICompany[] {
    switch (true) {
      case this.isAddingMode:
        return [this.newCompany]
      case !!this.selectedCompaniesToEdit.length:
        return this.selectedCompaniesToEdit.slice().reverse()
      default:
        return this.selectedCompanies.slice().reverse()
    }
  }

  public get newCompany(): ICompany {
    return Company.getNewCompanyDto(this.state.activeProject.id)
  }

  public get companiesFilters(): IFilters {
    return this.state.companiesFilters
  }

  public get searchKey(): string {
    return this.companiesFilters.searchKey.toLowerCase().trim()
  }

  @action.bound
  public setLastUpdatedCompanyId(lastUpdatedCompanyId: string) {
    this.lastUpdatedCompanyId = lastUpdatedCompanyId || null
  }

  @action.bound
  public showEditModal() {
    this.setLastUpdatedCompanyId(null)
    this.showCompanyModal()
  }

  @action.bound
  public showCompanyModal() {
    this.shouldShowCompanyModal = true
  }

  @action.bound
  public hideCompanyModal() {
    this.resetErrorMessage()

    this.shouldShowCompanyModal = false

    this.resetAddingMode()
    this.resetMergingMode()

    this.companiesListStore.resetHiddenSelection()
  }

  @action.bound
  public handleActionClick(actionType: ActionType) {
    this.activeActionType = actionType

    switch (actionType) {
      case ActionType.AddCompany:
        this.handleAddCompany()
        break
    }
  }

  @action.bound
  public handleAddCompany() {
    this.setAddingMode()
    this.showCompanyModal()
  }

  @action.bound
  private async mergeCompanies(
    companies: ICompany[],
    lastUpdatedCompanyId: string,
  ) {
    this.setLastUpdatedCompanyId(lastUpdatedCompanyId)

    const targetCompany = companies.find(c => c.id === lastUpdatedCompanyId)

    try {
      const mergeResult = await this.companiesStore.mergeMany(
        [...companies.map(c => c.id)],
        targetCompany,
      )

      this.handleSuccessMerging(
        companies.length - 1,
        targetCompany.name,
        mergeResult,
      )
    } catch (e) {
      this.errorMessage = e.message
    }
  }

  @action.bound
  public async submitForm(companies: ICompany[], lastUpdatedCompanyId: string) {
    if (this.isMergingMode) {
      this.mergeCompanies(companies, lastUpdatedCompanyId)
    } else {
      this.saveCompanies(companies, lastUpdatedCompanyId)
    }
  }

  @action.bound
  private async saveCompanies(
    companies: ICompany[],
    lastUpdatedCompanyId: string,
  ) {
    this.setLastUpdatedCompanyId(lastUpdatedCompanyId)

    try {
      await this.companiesStore.saveMany(companies)
    } catch (e) {
      this.errorMessage = e.message
      return
    }

    this.handleSuccessSaving()
  }

  @action.bound
  public handleSuccessSaving() {
    showToast(this.successMessage, ToastTheme.SUCCESS, null)

    this.hideCompanyModal()
  }

  @action.bound
  public handleSuccessMerging(
    count: number,
    targetCompanyName: string,
    mergeResult: IMergeResult,
  ) {
    this.companiesListStore.resetSelection()

    // TODO: Localize
    const title = `${Localization.translator.xCompanies(count)} ${
      count > 1 ? 'have' : 'has'
    } been merged into [${targetCompanyName}]`

    showToast(
      this.mergeResultRenderer(title, mergeResult),
      ToastTheme.SUCCESS,
      null,
      null,
    )

    this.hideCompanyModal()
  }

  @action.bound
  public async showMergingModal() {
    this.setMergingMode()
    this.showCompanyModal()
  }

  private get successMessage(): string {
    const { companyAdded, companiesUpdated } = Localization.translator
    return this.isAddingMode ? companyAdded : companiesUpdated
  }

  private setAddingMode() {
    this.isAddingMode = true
  }

  private resetAddingMode() {
    this.isAddingMode = false
  }

  private setMergingMode() {
    this.isMergingMode = true
  }

  private resetMergingMode() {
    this.isMergingMode = false
  }
}
