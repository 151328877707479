export default {
  samoa: 'Hora Estándar de Samoa: SST',
  niue: 'Hora de Niue: NUT',

  hawaiiAleuntian: 'Hora Estándar de Hawái-Aleutiano: HST',
  cookIsland: 'Hora de la Isla Cook: CKT',
  tahiti: 'Hora de Tahití: TAHT',

  marquesas: 'Hora de Marquesas: MART',

  alaska: 'Hora Estándar de Alaska: AKST',
  gambier: 'Hora de Gambier: GAMT',

  pacific: 'Hora Estándar del Pacífico: PST',
  pitcairn: 'Hora Estándar de Pitcairn: PST',

  mountain: 'Hora Estándar de la Montaña: MST',
  mexicanPacific: 'Hora Estándar del Pacífico Mexicano',

  centralStandard: 'Hora Estándar Central: CST',
  centralMexicoStandard: 'Hora Estándar Central (México): CST',
  galapagos: 'Hora de Galápagos: GALT',
  easterIsland: 'Hora Estándar de la Isla de Pascua: EAST',

  acre: 'Hora de Acre: ACT',
  ecuador: 'Hora de Ecuador: ECT',
  peru: 'Hora de Perú: PET',
  easternStandard: 'Hora Estándar del Este: EST',
  colombia: 'Hora de Colombia: COT',
  cuba: 'Hora Estándar de Cuba: CST',

  guyana: 'Hora de Guyana: GYT',
  bolivia: 'Hora de Bolivia: BOT',
  amazon: 'Hora de Amazonas: AMT',
  venezuela: 'Hora Estándar de Venezuela: VET',
  atlantic: 'Hora Estándar del Atlántico: AST',
  chile: 'Hora Estándar de Chile: CLT',

  newfoundland: 'Hora Estándar de Terranova: NST',

  pierre: 'Hora Estándar de St. Pierre & Miquelon: PMST',
  uruguay: 'Hora de Uruguay: UYT',
  falkland: 'Hora de las Islas Malvinas: FKT',
  paraguay: 'Hora de Paraguay: PYT',
  brasilia: 'Hora de Brasilia: BRT',
  suriname: 'Hora de Surinam: SRT',
  argentina: 'Hora de Argentina: ART',
  frenchGuiana: 'Hora de la Guayana Francesa: GFT',
  westGreenland: 'Hora de Groenlandia Occidental: WGT(GMT -3)',
  rothera: 'Hora de Rothera: ROTT',
  palmer: 'Hora de Palmer',
  puntaArenas: 'Hora de Punta Arenas',

  southGeorgia: 'Hora de Georgia del Sur: GST',
  fernandoDeNoronha: 'Hora de Fernando de Noronha: FNT',

  capeVerde: 'Hora de Cabo Verde: CVT',
  azores: 'Hora Estándar de las Azores: AZOT',
  eastGreenland: 'Hora de Groenlandia Oriental: EGT',

  coordinatedUniversal: 'Hora Universal Coordinada: UTC',
  greenwichMean: 'Hora del Meridiano de Greenwich: GMT',
  greenwichMeanSaoTome: 'Hora del Meridiano de Greenwich (São Tomé): GMT',
  westernEuropean: 'Hora de Europa Occidental: WET',
  westernEuropeanAfrica: 'Hora de Europa Occidental (África): WET',

  westAfrica: 'Hora de África Occidental: WAT',
  centralEuropean: 'Hora de Europa Central: CET',

  centralAfricaTime: 'Hora de África Central: CAT',
  southAfrica: 'Hora Estándar de Sudáfrica',
  easternEuropean: 'Hora de Europa Oriental: EET',
  easternEuropeanBeirutGaza: 'Hora de Europa Oriental (Beirut, Gaza): EET',
  easternEuropeanDamascusAmman: 'Hora de Europa Oriental (Damasco, Amán): EET',
  israel: 'Hora Estándar de Israel: IST',

  eastAfrica: 'Hora de África Oriental: EAT',
  turkey: 'Hora de Turquía: TRT',
  arabian: 'Hora Estándar Árabe: AST',
  moscow: 'Hora Estándar de Moscú: MSK',
  syowa: 'Tiempo Syowa',
  volgograd: 'Hora estándar de Volgogrado',

  iran: 'Hora estándar de Irán: IRST',

  gulf: 'Hora estándar del Golfo: GST',
  azerbaijan: 'Hora de Azerbaiyán: AZT',
  armenia: 'Hora de Armenia: AMT',
  astrakhan: 'Hora de Astrakhan',
  ulyanovsk: 'Hora de Ulyanovsk',
  samara: 'Hora de Samara: SAMT',
  georgia: 'Hora estándar de Georgia',
  mauritius: 'Hora de Mauricio: MUT',
  seychelles: 'Hora de Seychelles: SCT',
  reunion: 'Hora de Reunión: RET',

  afghanistan: 'Hora de Afganistán: AFT',

  yekaterinburg: 'Hora de Yekaterimburgo: YEKT',
  pakistan: 'Hora estándar de Pakistán: PKT',
  tajikistan: 'Hora de Tayikistán',
  uzbekistan: 'Hora estándar de Uzbekistán',
  westKazakhstan: 'Hora de Kazajistán Occidental',
  turkmenistan: 'Hora de Turkmenistán: TMT',
  frenchSouthern: 'Hora de Territorios Australes y Antárticas Francesas: TFT',
  maldives: 'Hora de Maldivas: MVT',
  mawson: 'Hora de Mawson: MAWT',

  india: 'Hora estándar de India: IST',

  nepal: 'Hora de Nepal: NPT',

  bhutan: 'Hora de Bután: BTT',
  omsk: 'Hora estándar de Omsk: OMST',
  kyrgyzstan: 'Hora de Kirguistán: KGT',
  bangladesh: 'Hora estándar de Bangladés: BST',
  indianOcean: 'Hora del Océano Índico: IOT',
  vostok: 'Hora de Vostok: VOST',
  urumqiKashgar: 'Hora de Urumqi/Kashgar',
  eastKazakhstan: 'Hora de Kazajistán Oriental',

  myanmar: 'Hora de Myanmar: MMT',
  cocosIsland: 'Hora de las Islas Cocos: CCT',

  westernIndonesia: 'Hora de Indonesia Occidental: WIB',
  indochina: 'Hora de Indochina: ICT',
  hovd: 'Hora de Hovd: HOVT',
  krasnoyarsk: 'Hora de Krasnoyarsk: KRAT',
  novosibirsk: 'Hora estándar de Novosibirsk',
  christmasIsland: 'Hora de la Isla Christmas: CXT',
  davis: 'Hora de Davis: DAVT',
  barnaulTomsk: 'Hora de Barnaul/Tomsk',

  irkutsk: 'Hora de Irkutsk: IRKT',
  hongKong: 'Hora de Hong Kong: HKT',
  singapore: 'Hora de Singapur: SGT',
  malaysia: 'Hora de Malasia: MYT',
  australianWestern: 'Hora estándar de Australia Occidental: AWST',
  chinaStandard: 'Hora estándar de China: CST',
  centralIndonesia: 'Hora de Indonesia Central: WITA',
  philippine: 'Hora de Filipinas: PHT',
  bruneiDarussalam: 'Hora de Brunéi Darussalam: BNT',
  taipei: 'Hora estándar de Taipéi',
  ulaanbaatar: 'Hora estándar de Ulán Bator',

  pyongyang: 'Hora de Pyongyang: PYT',

  australianCentralWestern:
    'Hora estándar de Australia Central Occidental: ACWST',

  easternIndonesia: 'Hora de Indonesia Oriental: WIT',
  japan: 'Hora estándar de Japón: JST',
  korea: 'Hora estándar de Corea: KST',
  eastTimor: 'Hora de Timor Oriental: TLT',
  yakutsk: 'Hora de Yakutsk: YAKT',
  palau: 'Hora de Palau: PWT',

  australianCentralStandard: 'Hora estándar de Australia Central: ACST',

  australianEasternStandard: 'Hora estándar de Australia Oriental: AEST',
  vladivostok: 'Hora de Vladivostok: VLAT',
  chamorro: 'Hora estándar de Chamorro: ChST',
  papuaNewGuinea: 'Hora de Papúa Nueva Guinea: PGT',
  chuuk: 'Hora de Chuuk',
  dumontdUrville: 'Hora de Dumont-d’Urville',

  lordHowe: 'Hora estándar de Lord Howe: LHST',

  magadan: 'Hora de Magadán: MAGT',
  solomonIslands: 'Hora de las Islas Salomón: SBT',
  kosrae: 'Hora de Kosrae: KOST',
  ponhpei: 'Hora estándar de Pohnpei: PONT',
  newCaledonia: 'Hora de Nueva Caledonia: NCT',
  norfolk: 'Hora de Norfolk: NFT',
  vanuatu: 'Hora de Vanuatu: VUT',
  casey: 'Hora de Casey: CAST',
  srednekolymskBougainville: 'Hora de Srednekolymsk/Bougainville',
  sakhalin: 'Hora estándar de Sajalín',

  marshallIslands: 'Hora de las Islas Marshall: MHT',
  petropavlovskKamchatski: 'Hora de Petropavlovsk-Kamchatski: PETT',
  tuvalu: 'Hora de Tuvalu: TVT',
  gilbertIslands: 'Hora de las Islas Gilbert: GILT',
  nauru: 'Hora de Nauru: NRT',
  wakeIsland: 'Hora de la Isla Wake: WAKT',
  wallisAndFutuna: 'Hora de Wallis y Futuna: WFT',
  anadyr: 'Hora estándar de Anadyr',
  fiji: 'Hora estándar de Fiyi: FJT',
  newZealand: 'Hora estándar de Nueva Zelanda: NZST',

  chatham: 'Hora estándar de Chatham: CHAST',

  phoenixIslands: 'Hora de las Islas Fénix: PHOT',
  tokelau: 'Hora de Tokelau: TKT',
  tonga: 'Hora de Tonga: TOT',
  apia: 'Hora estándar de Apia',

  lineIslands: 'Hora de las Islas de la Línea: LINT',
}
