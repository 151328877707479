import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DeliveriesListStore from '~/client/src/desktop/views/Deliveries/components/DeliveriesList/DeliveriesList.store'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'
import { UPDATE_BULK_DELIVERIES_STATUS } from '~/client/src/shared/stores/EventStore/eventConstants'
import {
  ToastTheme,
  showErrorToast,
  showToast,
} from '~/client/src/shared/utils/toaster'

// localization: translated

export default class DeliveryBulkUpdaterStore {
  @observable public selectedStatus: DeliveryStatus = null

  public get userActiveProjectSettings() {
    return this.eventsStore.appState.userActiveProjectSettings
  }

  public get activeProject() {
    return this.eventsStore.appState.activeProject
  }

  public get selectedDeliveries(): Delivery[] {
    return this.deliveriesListStore.selectedInstances
  }

  public get filteredDeliveries(): Delivery[] {
    if (this.isPendingStatus(this.selectedStatus)) {
      return this.deliveriesListStore.selectedInstances.filter(
        d => !this.isPendingStatus(d.status),
      )
    }

    return this.deliveriesListStore.selectedInstances.filter(
      d => d.status !== this.selectedStatus,
    )
  }

  public get selectedDeliveriesCount(): number {
    return this.selectedDeliveries.length
  }

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly deliveriesListStore: DeliveriesListStore,
    private readonly onUpdated: () => void,
  ) {}

  @action.bound
  public handleUpdate() {
    if (!this.canUpdateSelectedBulk(this.selectedStatus)) {
      return
    }

    this.eventsStore.dispatch(
      UPDATE_BULK_DELIVERIES_STATUS,
      this.filteredDeliveries.map(d => d.id),
      this.selectedStatus,
      this.handleSuccess,
      this.handleError,
    )

    this.onUpdated()
  }

  public canUpdateSelectedBulk = (targetStatus: DeliveryStatus): boolean => {
    if (!this.selectedDeliveriesCount) {
      return false
    }

    if (this.areDeliveriesHaveSameStatus()) {
      return this.selectedDeliveries.every(delivery =>
        delivery.canUserChangeStatus(
          targetStatus,
          this.userActiveProjectSettings,
        ),
      )
    }

    return this.selectedDeliveries.every(
      delivery =>
        !delivery.isFromConcreteDirect &&
        this.canChangeDeliveryStatus(
          delivery.status,
          targetStatus,
          delivery.isLateRequest,
        ),
    )
  }

  private areDeliveriesHaveSameStatus = (): boolean => {
    return (
      this.selectedDeliveries.every(delivery =>
        this.isPendingStatus(delivery.status),
      ) ||
      this.selectedDeliveries.every(delivery =>
        this.selectedDeliveries.every(del => del.status === delivery.status),
      )
    )
  }

  private canChangeDeliveryStatus = (
    deliveryStatus: DeliveryStatus,
    targetStatus: DeliveryStatus,
    isLateRequest: boolean,
  ): boolean => {
    const isSuperUser = this.userActiveProjectSettings?.isSuperUser

    switch (targetStatus) {
      case DeliveryStatus.Requested:
      case DeliveryStatus.Changed:
        return isSuperUser
      case DeliveryStatus.Scheduled:
      case DeliveryStatus.Denied:
        return isSuperUser
      case DeliveryStatus.OnSite:
        return (
          isSuperUser ||
          (deliveryStatus === DeliveryStatus.Scheduled && !isLateRequest)
        )
      case DeliveryStatus.FailedInspection:
        return (
          isSuperUser ||
          [DeliveryStatus.OnSite, DeliveryStatus.PassedInspection].includes(
            deliveryStatus,
          )
        )
      case DeliveryStatus.PassedInspection:
        return (
          isSuperUser ||
          [DeliveryStatus.OnSite, DeliveryStatus.FailedInspection].includes(
            deliveryStatus,
          )
        )
      case DeliveryStatus.IncompleteDone:
        return isSuperUser || deliveryStatus === DeliveryStatus.FailedInspection
      case DeliveryStatus.Done:
        return isSuperUser || deliveryStatus === DeliveryStatus.PassedInspection
      case DeliveryStatus.Canceled:
        return (
          isSuperUser ||
          [DeliveryStatus.Requested, DeliveryStatus.Scheduled].includes(
            deliveryStatus,
          )
        )
    }
  }

  private isPendingStatus = (status: DeliveryStatus): boolean => {
    return (
      status === DeliveryStatus.Changed || status === DeliveryStatus.Requested
    )
  }

  private handleSuccess = (count: number) => {
    showToast(
      Localization.translator.statusUpdatedOnXDeliveries(count),
      ToastTheme.SUCCESS,
      IconNames.UPDATED,
    )
  }

  private handleError = () => {
    showErrorToast(Localization.translator.failedToUpdate)
  }
}
