import { LocationType } from '~/client/graph/types.generated'

const routingLocationStructureList = [
  LocationType.Building,
  LocationType.Zone,
  LocationType.Staging,
  LocationType.Level,
  LocationType.Area,
  LocationType.Route,
  LocationType.Gate,
  LocationType.InteriorPath,
  LocationType.InteriorDoor,
]

export default routingLocationStructureList
