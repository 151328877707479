import { DeliveryFilterType } from '~/client/graph'

export const groupedExtendedDeliveryFilterTypes = [
  DeliveryFilterType.Company,
  DeliveryFilterType.Locations,
  DeliveryFilterType.Status,
  DeliveryFilterType.Assignee,
]

export const extendedDeliveryFilterTypes = [
  DeliveryFilterType.Company,
  DeliveryFilterType.Building,
  DeliveryFilterType.Zone,
  DeliveryFilterType.Level,
  DeliveryFilterType.Area,
  DeliveryFilterType.Gate,
  DeliveryFilterType.Route,
  DeliveryFilterType.Status,
  DeliveryFilterType.Equipment,
  DeliveryFilterType.Assigners,
  DeliveryFilterType.Followers,
]

export const deliveryFilterTypes = [
  DeliveryFilterType.Company,
  DeliveryFilterType.Status,
]
