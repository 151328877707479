import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'

import { LocationType } from '~/client/graph'
import LocationPicker from '~/client/src/shared/components/LocationPicker/LocationPicker'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import GlobeView from '~/client/src/shared/models/GlobeView'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import GlobeViewSetupStore from '~/client/src/shared/stores/GlobeViewSetup.store'

import MapViewItemsSetupStore from '../../stores/MapViewItemsSetup.store'

const selectAssociatedLocation = 'Select associated location'

interface IProps {
  globe?: GlobeView
  mapViewItemsSetupStore: MapViewItemsSetupStore
  globeViewSetupStore: GlobeViewSetupStore
  onClose(): void
  postUpdateAction?(): void
  className?: string
}

const restrictedObjectTypes = [
  LocationType.LogisticsObject,
  LocationType.VerticalObject,
]

@observer
export default class GlobeLocationAssociationModal extends React.Component<IProps> {
  @observable private assignedId: string = null
  @observable private isProjectOverviewGlobe: boolean = false

  public constructor(props: IProps) {
    super(props)
    if (props.globe) {
      this.assignedId = props.mapViewItemsSetupStore.globeAssignedItems(
        props.globe,
      )[0]?.id
      this.isProjectOverviewGlobe = props.globe.isProjectOverviewGlobe
    } else {
      this.assignedId =
        props.mapViewItemsSetupStore.selectedSitemapAssignedItems[0]?.id
      this.isProjectOverviewGlobe =
        props.globeViewSetupStore.selectedGlobeView.isProjectOverviewGlobe
    }
  }

  public render() {
    const { onClose, className } = this.props

    return (
      <MenuCloser
        isOpen
        closeMenu={onClose}
        className={className || 'absolute'}
      >
        <LocationPicker
          restrictedObjectTypes={restrictedObjectTypes}
          className="location-association-modal"
          title={selectAssociatedLocation}
          onClose={onClose}
          selectedIds={this.assignedId ? [this.assignedId] : []}
          onChange={this.onAssignedIdsChanged}
          onApplyChanges={this.updateAssociations}
          onSingleSelect={this.onSingleAssignedIdsChanged}
          isLocationViewFirst={true}
          isSingleSelectionMode={true}
          firstRowText="Project Overview Globes"
          firstRowValue={this.isProjectOverviewGlobe}
          firstRowAction={this.firstRowAction}
        />
      </MenuCloser>
    )
  }

  @action.bound
  private onSingleAssignedIdsChanged(attr: LocationBase) {
    this.assignedId = attr.id
    this.isProjectOverviewGlobe = false
  }

  @action.bound
  private onAssignedIdsChanged(assignedIds: string[]) {
    this.assignedId = assignedIds[0]
    this.isProjectOverviewGlobe = false
  }

  @action.bound
  private firstRowAction() {
    this.assignedId = null
    this.isProjectOverviewGlobe = !this.isProjectOverviewGlobe
  }

  private updateAssociations = async (): Promise<void> => {
    const {
      mapViewItemsSetupStore: { updateGlobeAssignedItems },
      globeViewSetupStore: { saveGlobeView },
      onClose,
      postUpdateAction,
    } = this.props
    onClose()
    if (this.globe.isProjectOverviewGlobe !== this.isProjectOverviewGlobe) {
      this.globe.isProjectOverviewGlobe = this.isProjectOverviewGlobe
      await saveGlobeView(this.globe)
    }
    if (this.assignedId) {
      await updateGlobeAssignedItems([this.assignedId], this.globe)
    } else {
      await updateGlobeAssignedItems([], this.globe)
    }
    if (postUpdateAction) {
      postUpdateAction()
    }
  }

  private get globe(): GlobeView {
    const {
      globeViewSetupStore: { selectedGlobeView },
      globe,
    } = this.props
    return globe || selectedGlobeView
  }
}
