import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IDeliveryFieldsFragment = Pick<
  Types.IDelivery,
  | 'id'
  | 'projectId'
  | 'bookingId'
  | 'activityId'
  | 'actualEndDate'
  | 'actualStartDate'
  | 'fromInstallationZone'
  | 'installationZone'
  | 'company'
  | 'createdAt'
  | 'driverPhoneNumbers'
  | 'endDate'
  | 'inspector'
  | 'isInspectionRequired'
  | 'isLateRequest'
  | 'isSchedulingInAdvancePrevented'
  | 'isUnscheduledRequest'
  | 'note'
  | 'numberOfEquipmentPicks'
  | 'onSiteContactPersonIds'
  | 'sitemapUrls'
  | 'globeViewUrls'
  | 'startDate'
  | 'status'
  | 'threadId'
  | 'truckLicensePlate'
  | 'truckNumber'
  | 'truckSize'
  | 'updatedAt'
  | 'userId'
  | 'vendor'
  | 'vendorEmails'
  | 'recurringSettingId'
  | 'cancellationReason'
  | 'type'
> & {
  locationsFrom?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.ISiteLocation, 'id' | 'type'>>>
  >
  locationsTo?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.ISiteLocation, 'id' | 'type'>>>
  >
  materials?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<
          Types.IDeliveryMaterial,
          | 'procurementId'
          | 'materialId'
          | 'note'
          | 'quantity'
          | 'unitId'
          | 'locationId'
        >
      >
    >
  >
  offloadingEquipments?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IDeliveryAttribute, 'id'>>>
  >
  metadata?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IStringPair, 'key' | 'value'>>>
  >
}

export type IDeliveriesByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  fromDate?: Types.Maybe<Types.Scalars['Long']>
  toDate?: Types.Maybe<Types.Scalars['Long']>
  limit?: Types.Maybe<Types.Scalars['Int']>
}>

export type IDeliveriesByProjectIdQuery = {
  deliveries?: Types.Maybe<{ data: Array<IDeliveryFieldsFragment> }>
}

export type IListenDeliveriesByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenDeliveriesByProjectIdSubscription = {
  delivery?: Types.Maybe<
    Pick<Types.IDeliveryChangeEvent, 'id'> & {
      item?: Types.Maybe<IDeliveryFieldsFragment>
    }
  >
}

export type ICreateDeliveryMutationVariables = Types.Exact<{
  delivery: Types.IDeliveryInput
  timezoneId: Types.Scalars['String']
}>

export type ICreateDeliveryMutation = {
  createDelivery?: Types.Maybe<Pick<Types.IDelivery, 'id'>>
}

export type ICreateRecurringDeliveriesMutationVariables = Types.Exact<{
  delivery: Types.IDeliveryInput
  recurringSettings: Types.IRecurringDeliveriesSettingsInput
  timezoneId: Types.Scalars['String']
}>

export type ICreateRecurringDeliveriesMutation = {
  createRecurringDeliveries?: Types.Maybe<
    Array<Types.Maybe<IDeliveryFieldsFragment>>
  >
}

export type IUpdateDeliveryMutationVariables = Types.Exact<{
  delivery: Types.IDeliveryInput
  message?: Types.Maybe<Types.ISaveMessageInput>
  timezoneId: Types.Scalars['String']
}>

export type IUpdateDeliveryMutation = {
  updateDelivery?: Types.Maybe<IDeliveryFieldsFragment>
}

export type IUpdateRecurringDeliveriesMutationVariables = Types.Exact<{
  delivery: Types.IDeliveryInput
  message?: Types.Maybe<Types.ISaveMessageInput>
  recurringSettings: Types.IRecurringDeliveriesSettingsInput
  relatedRecurringIds:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
  timezoneId: Types.Scalars['String']
}>

export type IUpdateRecurringDeliveriesMutation = {
  updateRecurringDeliveries?: Types.Maybe<
    Array<Types.Maybe<IDeliveryFieldsFragment>>
  >
}

export type IDeleteManyDeliveriesMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteManyDeliveriesMutation = Pick<
  Types.IMutation,
  'softDeleteManyDeliveries'
>

export type IChangeDeliveriesStatusMutationVariables = Types.Exact<{
  statusData: Types.IChangeDeliveriesStatusCommandInput
}>

export type IChangeDeliveriesStatusMutation = Pick<
  Types.IMutation,
  'changeDeliveriesStatus'
>

export type IUpdateDeliveriesFieldsMutationVariables = Types.Exact<{
  fieldsData?: Types.Maybe<Types.IUpdateDeliveriesFieldsInput>
}>

export type IUpdateDeliveriesFieldsMutation = Pick<
  Types.IMutation,
  'updateDeliveriesFields'
>

export const DeliveryFieldsFragmentDoc = gql`
  fragment DeliveryFields on Delivery {
    id
    projectId
    bookingId
    activityId
    actualEndDate
    actualStartDate
    locationsFrom {
      id
      type
    }
    fromInstallationZone
    locationsTo {
      id
      type
    }
    installationZone
    bookingId
    company
    createdAt
    driverPhoneNumbers
    endDate
    inspector
    isInspectionRequired
    isLateRequest
    isSchedulingInAdvancePrevented
    isUnscheduledRequest
    materials {
      procurementId
      materialId
      note
      quantity
      unitId
      locationId
    }
    note
    offloadingEquipments {
      id
    }
    numberOfEquipmentPicks
    onSiteContactPersonIds
    projectId
    sitemapUrls
    globeViewUrls
    startDate
    status
    threadId
    truckLicensePlate
    truckNumber
    truckSize
    updatedAt
    userId
    vendor
    vendorEmails
    recurringSettingId
    cancellationReason
    metadata {
      key
      value
    }
    type
  }
`
export const DeliveriesByProjectIdDocument = gql`
  query DeliveriesByProjectId(
    $projectId: ObjectId!
    $fromDate: Long
    $toDate: Long
    $limit: Int = 1000000
  ) {
    deliveries(
      projectId: $projectId
      fromDate: $fromDate
      toDate: $toDate
      limit: $limit
    ) {
      data {
        ...DeliveryFields
      }
    }
  }
  ${DeliveryFieldsFragmentDoc}
`

/**
 * __useDeliveriesByProjectIdQuery__
 *
 * To run a query within a React component, call `useDeliveriesByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveriesByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveriesByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDeliveriesByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IDeliveriesByProjectIdQuery,
    IDeliveriesByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IDeliveriesByProjectIdQuery,
    IDeliveriesByProjectIdQueryVariables
  >(DeliveriesByProjectIdDocument, options)
}
export function useDeliveriesByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IDeliveriesByProjectIdQuery,
    IDeliveriesByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IDeliveriesByProjectIdQuery,
    IDeliveriesByProjectIdQueryVariables
  >(DeliveriesByProjectIdDocument, options)
}
export type DeliveriesByProjectIdQueryHookResult = ReturnType<
  typeof useDeliveriesByProjectIdQuery
>
export type DeliveriesByProjectIdLazyQueryHookResult = ReturnType<
  typeof useDeliveriesByProjectIdLazyQuery
>
export type DeliveriesByProjectIdQueryResult = Apollo.QueryResult<
  IDeliveriesByProjectIdQuery,
  IDeliveriesByProjectIdQueryVariables
>
export const ListenDeliveriesByProjectIdDocument = gql`
  subscription ListenDeliveriesByProjectId($projectId: ObjectId!) {
    delivery(projectId: $projectId) {
      id
      item {
        ...DeliveryFields
      }
    }
  }
  ${DeliveryFieldsFragmentDoc}
`

/**
 * __useListenDeliveriesByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenDeliveriesByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenDeliveriesByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenDeliveriesByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenDeliveriesByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenDeliveriesByProjectIdSubscription,
    IListenDeliveriesByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenDeliveriesByProjectIdSubscription,
    IListenDeliveriesByProjectIdSubscriptionVariables
  >(ListenDeliveriesByProjectIdDocument, options)
}
export type ListenDeliveriesByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenDeliveriesByProjectIdSubscription
>
export type ListenDeliveriesByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenDeliveriesByProjectIdSubscription>
export const CreateDeliveryDocument = gql`
  mutation CreateDelivery($delivery: DeliveryInput!, $timezoneId: String!) {
    createDelivery(delivery: $delivery, timezoneId: $timezoneId) {
      id
    }
  }
`
export type ICreateDeliveryMutationFn = Apollo.MutationFunction<
  ICreateDeliveryMutation,
  ICreateDeliveryMutationVariables
>

/**
 * __useCreateDeliveryMutation__
 *
 * To run a mutation, you first call `useCreateDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliveryMutation, { data, loading, error }] = useCreateDeliveryMutation({
 *   variables: {
 *      delivery: // value for 'delivery'
 *      timezoneId: // value for 'timezoneId'
 *   },
 * });
 */
export function useCreateDeliveryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateDeliveryMutation,
    ICreateDeliveryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ICreateDeliveryMutation,
    ICreateDeliveryMutationVariables
  >(CreateDeliveryDocument, options)
}
export type CreateDeliveryMutationHookResult = ReturnType<
  typeof useCreateDeliveryMutation
>
export type CreateDeliveryMutationResult =
  Apollo.MutationResult<ICreateDeliveryMutation>
export type CreateDeliveryMutationOptions = Apollo.BaseMutationOptions<
  ICreateDeliveryMutation,
  ICreateDeliveryMutationVariables
>
export const CreateRecurringDeliveriesDocument = gql`
  mutation CreateRecurringDeliveries(
    $delivery: DeliveryInput!
    $recurringSettings: RecurringDeliveriesSettingsInput!
    $timezoneId: String!
  ) {
    createRecurringDeliveries(
      delivery: $delivery
      recurringSettings: $recurringSettings
      timezoneId: $timezoneId
    ) {
      ...DeliveryFields
    }
  }
  ${DeliveryFieldsFragmentDoc}
`
export type ICreateRecurringDeliveriesMutationFn = Apollo.MutationFunction<
  ICreateRecurringDeliveriesMutation,
  ICreateRecurringDeliveriesMutationVariables
>

/**
 * __useCreateRecurringDeliveriesMutation__
 *
 * To run a mutation, you first call `useCreateRecurringDeliveriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringDeliveriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringDeliveriesMutation, { data, loading, error }] = useCreateRecurringDeliveriesMutation({
 *   variables: {
 *      delivery: // value for 'delivery'
 *      recurringSettings: // value for 'recurringSettings'
 *      timezoneId: // value for 'timezoneId'
 *   },
 * });
 */
export function useCreateRecurringDeliveriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateRecurringDeliveriesMutation,
    ICreateRecurringDeliveriesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ICreateRecurringDeliveriesMutation,
    ICreateRecurringDeliveriesMutationVariables
  >(CreateRecurringDeliveriesDocument, options)
}
export type CreateRecurringDeliveriesMutationHookResult = ReturnType<
  typeof useCreateRecurringDeliveriesMutation
>
export type CreateRecurringDeliveriesMutationResult =
  Apollo.MutationResult<ICreateRecurringDeliveriesMutation>
export type CreateRecurringDeliveriesMutationOptions =
  Apollo.BaseMutationOptions<
    ICreateRecurringDeliveriesMutation,
    ICreateRecurringDeliveriesMutationVariables
  >
export const UpdateDeliveryDocument = gql`
  mutation UpdateDelivery(
    $delivery: DeliveryInput!
    $message: SaveMessageInput
    $timezoneId: String!
  ) {
    updateDelivery(
      delivery: $delivery
      message: $message
      timezoneId: $timezoneId
    ) {
      ...DeliveryFields
    }
  }
  ${DeliveryFieldsFragmentDoc}
`
export type IUpdateDeliveryMutationFn = Apollo.MutationFunction<
  IUpdateDeliveryMutation,
  IUpdateDeliveryMutationVariables
>

/**
 * __useUpdateDeliveryMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryMutation, { data, loading, error }] = useUpdateDeliveryMutation({
 *   variables: {
 *      delivery: // value for 'delivery'
 *      message: // value for 'message'
 *      timezoneId: // value for 'timezoneId'
 *   },
 * });
 */
export function useUpdateDeliveryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateDeliveryMutation,
    IUpdateDeliveryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateDeliveryMutation,
    IUpdateDeliveryMutationVariables
  >(UpdateDeliveryDocument, options)
}
export type UpdateDeliveryMutationHookResult = ReturnType<
  typeof useUpdateDeliveryMutation
>
export type UpdateDeliveryMutationResult =
  Apollo.MutationResult<IUpdateDeliveryMutation>
export type UpdateDeliveryMutationOptions = Apollo.BaseMutationOptions<
  IUpdateDeliveryMutation,
  IUpdateDeliveryMutationVariables
>
export const UpdateRecurringDeliveriesDocument = gql`
  mutation UpdateRecurringDeliveries(
    $delivery: DeliveryInput!
    $message: SaveMessageInput
    $recurringSettings: RecurringDeliveriesSettingsInput!
    $relatedRecurringIds: [ObjectId]!
    $timezoneId: String!
  ) {
    updateRecurringDeliveries(
      delivery: $delivery
      message: $message
      recurringSettings: $recurringSettings
      relatedRecurringIds: $relatedRecurringIds
      timezoneId: $timezoneId
    ) {
      ...DeliveryFields
    }
  }
  ${DeliveryFieldsFragmentDoc}
`
export type IUpdateRecurringDeliveriesMutationFn = Apollo.MutationFunction<
  IUpdateRecurringDeliveriesMutation,
  IUpdateRecurringDeliveriesMutationVariables
>

/**
 * __useUpdateRecurringDeliveriesMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringDeliveriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringDeliveriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringDeliveriesMutation, { data, loading, error }] = useUpdateRecurringDeliveriesMutation({
 *   variables: {
 *      delivery: // value for 'delivery'
 *      message: // value for 'message'
 *      recurringSettings: // value for 'recurringSettings'
 *      relatedRecurringIds: // value for 'relatedRecurringIds'
 *      timezoneId: // value for 'timezoneId'
 *   },
 * });
 */
export function useUpdateRecurringDeliveriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateRecurringDeliveriesMutation,
    IUpdateRecurringDeliveriesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateRecurringDeliveriesMutation,
    IUpdateRecurringDeliveriesMutationVariables
  >(UpdateRecurringDeliveriesDocument, options)
}
export type UpdateRecurringDeliveriesMutationHookResult = ReturnType<
  typeof useUpdateRecurringDeliveriesMutation
>
export type UpdateRecurringDeliveriesMutationResult =
  Apollo.MutationResult<IUpdateRecurringDeliveriesMutation>
export type UpdateRecurringDeliveriesMutationOptions =
  Apollo.BaseMutationOptions<
    IUpdateRecurringDeliveriesMutation,
    IUpdateRecurringDeliveriesMutationVariables
  >
export const DeleteManyDeliveriesDocument = gql`
  mutation DeleteManyDeliveries($ids: [ObjectId]!) {
    softDeleteManyDeliveries(ids: $ids)
  }
`
export type IDeleteManyDeliveriesMutationFn = Apollo.MutationFunction<
  IDeleteManyDeliveriesMutation,
  IDeleteManyDeliveriesMutationVariables
>

/**
 * __useDeleteManyDeliveriesMutation__
 *
 * To run a mutation, you first call `useDeleteManyDeliveriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyDeliveriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyDeliveriesMutation, { data, loading, error }] = useDeleteManyDeliveriesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteManyDeliveriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteManyDeliveriesMutation,
    IDeleteManyDeliveriesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteManyDeliveriesMutation,
    IDeleteManyDeliveriesMutationVariables
  >(DeleteManyDeliveriesDocument, options)
}
export type DeleteManyDeliveriesMutationHookResult = ReturnType<
  typeof useDeleteManyDeliveriesMutation
>
export type DeleteManyDeliveriesMutationResult =
  Apollo.MutationResult<IDeleteManyDeliveriesMutation>
export type DeleteManyDeliveriesMutationOptions = Apollo.BaseMutationOptions<
  IDeleteManyDeliveriesMutation,
  IDeleteManyDeliveriesMutationVariables
>
export const ChangeDeliveriesStatusDocument = gql`
  mutation ChangeDeliveriesStatus(
    $statusData: ChangeDeliveriesStatusCommandInput!
  ) {
    changeDeliveriesStatus(changeDeliveriesStatus: $statusData)
  }
`
export type IChangeDeliveriesStatusMutationFn = Apollo.MutationFunction<
  IChangeDeliveriesStatusMutation,
  IChangeDeliveriesStatusMutationVariables
>

/**
 * __useChangeDeliveriesStatusMutation__
 *
 * To run a mutation, you first call `useChangeDeliveriesStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeDeliveriesStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeDeliveriesStatusMutation, { data, loading, error }] = useChangeDeliveriesStatusMutation({
 *   variables: {
 *      statusData: // value for 'statusData'
 *   },
 * });
 */
export function useChangeDeliveriesStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IChangeDeliveriesStatusMutation,
    IChangeDeliveriesStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IChangeDeliveriesStatusMutation,
    IChangeDeliveriesStatusMutationVariables
  >(ChangeDeliveriesStatusDocument, options)
}
export type ChangeDeliveriesStatusMutationHookResult = ReturnType<
  typeof useChangeDeliveriesStatusMutation
>
export type ChangeDeliveriesStatusMutationResult =
  Apollo.MutationResult<IChangeDeliveriesStatusMutation>
export type ChangeDeliveriesStatusMutationOptions = Apollo.BaseMutationOptions<
  IChangeDeliveriesStatusMutation,
  IChangeDeliveriesStatusMutationVariables
>
export const UpdateDeliveriesFieldsDocument = gql`
  mutation UpdateDeliveriesFields($fieldsData: UpdateDeliveriesFieldsInput) {
    updateDeliveriesFields(updateDeliveriesFields: $fieldsData)
  }
`
export type IUpdateDeliveriesFieldsMutationFn = Apollo.MutationFunction<
  IUpdateDeliveriesFieldsMutation,
  IUpdateDeliveriesFieldsMutationVariables
>

/**
 * __useUpdateDeliveriesFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveriesFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveriesFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveriesFieldsMutation, { data, loading, error }] = useUpdateDeliveriesFieldsMutation({
 *   variables: {
 *      fieldsData: // value for 'fieldsData'
 *   },
 * });
 */
export function useUpdateDeliveriesFieldsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateDeliveriesFieldsMutation,
    IUpdateDeliveriesFieldsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateDeliveriesFieldsMutation,
    IUpdateDeliveriesFieldsMutationVariables
  >(UpdateDeliveriesFieldsDocument, options)
}
export type UpdateDeliveriesFieldsMutationHookResult = ReturnType<
  typeof useUpdateDeliveriesFieldsMutation
>
export type UpdateDeliveriesFieldsMutationResult =
  Apollo.MutationResult<IUpdateDeliveriesFieldsMutation>
export type UpdateDeliveriesFieldsMutationOptions = Apollo.BaseMutationOptions<
  IUpdateDeliveriesFieldsMutation,
  IUpdateDeliveriesFieldsMutationVariables
>
