import { action, computed, observable } from 'mobx'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import * as Icons from '~/client/src/shared/components/Icons'
import * as TagIcons from '~/client/src/shared/components/TagIcon'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { ITag } from '~/client/src/shared/models/Tag'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import { IBaseTagsStore } from '~/client/src/shared/stores/BaseTags.store'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import { IServiceUserDto } from '~/client/src/shared/types/UserDto'
import {
  getBandIconNameByTagType,
  getBandTitleByTagType,
} from '~/client/src/shared/utils/TagHelper'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import Colors from '~/client/src/shared/theme.module.scss'

interface ITab {
  title: string
  tabType?: TagType
  isDisabled?: boolean
  getIcon({ className }: { className: string }): JSX.Element
}

export enum TagDataKey {
  TAG = 'tag',
  STRING = 'string',
}

export interface ITagData {
  dataKey: TagDataKey
  isDefaultTag?: boolean
  data: any
}

export const NEW_TAG_ID = 'new-tag-id'

const defaultTag = (tagName: string) => `Default ${tagName}s`

const mutationEvents = [
  e.SAVE_PROJECT_TEAM,
  e.DELETE_PROJECT_TEAM,
  e.SAVE_PROJECT_ROLE,
  e.DELETE_PROJECT_ROLE,
  e.SAVE_PROJECT_TRADE,
  e.DELETE_PROJECT_TRADE,
  e.SAVE_USER_PROJECTS,
]

export default class TagsDirectoryStore {
  @observable public activeTabId: TagType = TagType.Trade
  @observable public shouldShowNewTagRow: boolean = false
  @observable public shouldShowDeleteConfirmModal: boolean = false
  @observable public errorMessage: string = null
  @observable public selectedProjectMember: IServiceUserDto = null

  private usersToUpdate = new Map<string, User>()
  private removingTagId: string = null

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly tagsStore: TagsStore,
    private readonly clearCacheFn: () => void,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly projectMembersStore: ProjectMembersStore,
  ) {}

  @action.bound
  public switchActiveTab(newActiveTabId: TagType) {
    this.activeTabId = newActiveTabId
    this.clearCacheFn()
  }

  @action.bound
  public showNewTagRow() {
    this.shouldShowNewTagRow = true
    this.clearCacheFn()
  }

  @action.bound
  public hideNewTagRow() {
    this.shouldShowNewTagRow = false
  }

  @action.bound
  public showDeletionConfirm() {
    this.shouldShowDeleteConfirmModal = true
  }

  @action.bound
  public hideDeletionConfirm() {
    this.shouldShowDeleteConfirmModal = false
  }

  @action.bound
  public saveTag(tag: ITag) {
    tag.id = tag.id === NEW_TAG_ID ? null : tag.id

    this.activeTagStore.save(tag, this.hideAllModalsAndClearCache)
  }

  @action.bound
  public setTagToRemoveAndOpenModal(tagId: string) {
    this.removingTagId = tagId
    this.showDeletionConfirm()
  }

  @action.bound
  public async performTagDeletion() {
    if (!this.removingTagId) {
      return
    }

    if (this.removingTagId === NEW_TAG_ID) {
      return this.hideAllModalsAndClearCache()
    }

    const tag = this.tagsStore.getTag(this.activeTabId, this.removingTagId)
    const usersByTag = this.getUsersByTagId(this.removingTagId)

    if (!tag || !usersByTag?.length) {
      return this.deleteTag()
    }

    usersByTag.forEach(u =>
      UserProject.resetTagFromUser(u, tag, this.userProjectsStore),
    )

    const userProjectDtos = usersByTag.map(u =>
      this.userProjectsStore.getByUser(u).toDto(),
    )

    try {
      await this.userProjectsStore.save(userProjectDtos)
      this.deleteTag()
    } catch {
      this.hideAllModalsAndClearCache()
    }
  }

  @action.bound
  public changeTagForUser(
    userId: string,
    tag: ITag,
    shouldRemoveTagFromUser: boolean = false,
  ) {
    const user = this.projectMembersStore.getById(userId)

    if (!user) {
      return
    }

    const ids = UserProject.getUserTagsIdsByType(
      user,
      tag.type,
      this.userProjectsStore,
    )

    if (shouldRemoveTagFromUser) {
      const index = ids.findIndex(id => id === tag.id)

      if (index === -1) {
        return
      }

      UserProject.resetTagFromUser(user, tag, this.userProjectsStore)
    } else {
      if (ids.includes(tag.id)) {
        return
      }

      UserProject.setTagToUser(user, tag, this.userProjectsStore)
    }

    this.usersToUpdate.set(user.id, user)
  }

  @action.bound
  public async performUsersUpdate() {
    if (!this.usersToUpdate.size) {
      return
    }

    const userProjectDtos = [...this.usersToUpdate.values()].map(u =>
      this.userProjectsStore.getByUser(u).toDto(),
    )

    await this.userProjectsStore.save(userProjectDtos)

    this.hideAllModalsAndClearCache()
  }

  @action.bound
  public showUserEditDialog(userId: string) {
    const user = this.projectMembersStore.getById(userId)

    if (!user) {
      return this.hideMemberDialog()
    }

    this.setSelectedProjectMember(user)
  }

  @action.bound
  public setSelectedProjectMember(user: User) {
    this.selectedProjectMember = user?.asJson as IServiceUserDto
  }

  @action.bound
  public setErrorMessage(errorMessage: string) {
    this.errorMessage =
      errorMessage ||
      Localization.translator.somethingWentWrongDuringAPIInteraction
  }

  @action.bound
  public resetErrorMessage() {
    this.errorMessage = EMPTY_STRING
  }

  @action.bound
  public hideMemberDialog() {
    this.resetErrorMessage()
    this.setSelectedProjectMember(null)
  }

  @action.bound
  public async updateMembers(items: IServiceUserDto[]) {
    try {
      await this.projectMembersStore.saveMembers(items)
      this.hideMemberDialog()
    } catch (e) {
      this.setErrorMessage(e)
    }
  }

  public onProjectChange = (eventContext: EventContext) => {
    const [eventType] = eventContext.event

    if (eventType === e.ACTIVATE_PROJECT_SUCCESS) {
      this.clearCacheFn()
    }
  }

  public getUsersByTagId = (tagId: string): User[] => {
    return this.tagsStore.usersByRelatedTagIdMap[tagId]
  }

  @action.bound
  private deleteTag() {
    if (!this.removingTagId) {
      return
    }

    this.clearCacheFn()

    this.activeTagStore.delete(
      this.removingTagId,
      this.hideAllModalsAndClearCache,
    )
  }

  @action.bound
  private hideAllModalsAndClearCache() {
    this.clearUsersToUpdate()
    this.hideNewTagRow()
    this.hideDeletionConfirm()
    this.resetRemovingTagId()
    this.clearCacheFn()
  }

  private resetRemovingTagId = () => {
    this.removingTagId = null
  }

  private clearUsersToUpdate = () => {
    this.usersToUpdate.clear()
  }

  private createTagDataModel = (
    data: ITag,
    isDefaultTag?: boolean,
  ): ITagData => {
    return { dataKey: TagDataKey.TAG, data, isDefaultTag }
  }

  private createStringTagData = (data: string): ITagData => {
    return { dataKey: TagDataKey.STRING, data }
  }

  @computed
  public get selectedTabTitle(): string {
    return getBandTitleByTagType(this.activeTabId)
  }

  @computed
  public get isLoading(): boolean {
    const { loading } = this.state

    return (
      !this.tagsStore.isDataReceived ||
      this.areMembersBeingUpdated ||
      this.isProjectLoading ||
      mutationEvents.some(e => loading.get(e))
    )
  }

  @computed
  public get areMembersBeingUpdated(): boolean {
    return this.state.loading.get(e.SAVE_MEMBERS)
  }

  @computed
  public get isProjectLoading(): boolean {
    const { loading } = this.state

    return (
      loading.get(e.ACTIVATE_PROJECT) ||
      loading.get(e.GET_PROJECT_MEMBERS) ||
      loading.get(e.GET_SCHEDULE) ||
      loading.get(e.GET_USERS_FROM_PROCORE)
    )
  }

  @computed
  public get tabs(): ITab[] {
    const { trade, team, projectRole } = Localization.translator

    return [
      {
        title: trade,
        getIcon: Icons.Trade,
        tabType: TagType.Trade,
      },
      {
        tabType: TagType.Team,
        title: team,
        getIcon: TagIcons.Team,
      },
      {
        tabType: TagType.Role,
        title: projectRole,
        getIcon: TagIcons.Role,
      },
    ]
  }

  @computed
  public get tagList(): ITag[] {
    const tagList = this.tagsStore.tagListsByTagTypeMap[this.activeTabId]

    return this.shouldShowNewTagRow ? [this.newTag, ...tagList] : tagList
  }

  @computed
  public get tagsData(): ITagData[] {
    const data: ITagData[] = this.tagList.map(tag =>
      this.createTagDataModel(tag),
    )

    if (this.defaultTagList?.length) {
      data.push(
        this.createStringTagData(defaultTag(this.selectedTabTitle)),
        ...this.defaultTagList.map(tag => this.createTagDataModel(tag, true)),
      )
    }

    return data
  }

  @computed
  public get defaultTagList(): ITag[] {
    return this.tagsStore.defaultTagListsByTagTypeMap[this.activeTabId]
  }

  @computed
  public get activeTagStore(): IBaseTagsStore {
    return this.tagsStore.tagStoreByTagTypeMap[this.activeTabId]
  }

  private get newTag(): ITag {
    return {
      id: NEW_TAG_ID,
      name: EMPTY_STRING,
      iconName: getBandIconNameByTagType(this.activeTabId),
      color: Colors.neutral0,
      type: this.activeTabId,
    }
  }

  private get state(): DesktopInitialState {
    return this.eventsStore.appState
  }
}
