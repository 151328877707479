import { TagIconType } from '~/client/src/shared/enums/TagIcon'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'

export function getBandTitleByTagType(tagType: TagType): string {
  switch (tagType) {
    case TagType.Role:
      return Localization.translator.projectRole
    case TagType.Team:
    case TagType.DefaultTeam:
      return Localization.translator.team
    case TagType.GlobalAndProjectSpecificRole:
      return Localization.translator.role
    case TagType.LogisticsObject:
      return Localization.translator.logisticObject
    case TagType.VerticalObject:
      return Localization.translator.verticalObject
    case TagType.Trade:
      return Localization.translator.trade

    case TagType.AccountPosition:
      return Localization.translator.accountPosition
    case TagType.AccountType:
      return Localization.translator.accountType
    case TagType.Area:
      return Localization.translator.area
    case TagType.Building:
      return Localization.translator.building
    case TagType.Company:
      return Localization.translator.company
    case TagType.CompanyType:
      return Localization.translator.companyType
    case TagType.OffloadingEquipment:
      return Localization.translator.equipment
    case TagType.Gate:
      return Localization.translator.gate
    case TagType.Level:
      return Localization.translator.level
    case TagType.Route:
      return Localization.translator.route
    case TagType.Status:
      return Localization.translator.status
    case TagType.User:
      return Localization.translator.user
    case TagType.Zone:
      return Localization.translator.zone
    case TagType.Staging:
      return Localization.translator.staging

    default:
      throw new Error(tagType + ' is unhandled')
  }
}

export function getBandIconNameByTagType(tagType: TagType) {
  switch (tagType) {
    case TagType.Role:
    case TagType.GlobalAndProjectSpecificRole:
      return TagIconType.Role
    case TagType.Team:
      return TagIconType.Team
    case TagType.DefaultTeam:
      return TagIconType.DefaultTeam
    case TagType.Trade:
      return TagIconType.Trade

    default:
      return tagType as any
  }
}
