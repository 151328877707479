import { IStringPair } from '~/client/graph'
import metadataKeys from '~/client/src/shared/constants/metadataKeys'

import Guard from '../utils/Guard'
import { copyObject } from '../utils/util'
import IDeliveryAttributeDto from './IDeliveryAttributeDto'

export default class CommonDeliveryAttributeDto
  implements IDeliveryAttributeDto
{
  private metadataMap = new Map<string, string>()

  public constructor(
    public id: string,
    public name: string,
    public readonly projectId: string,
    public readonly isDeleted?: boolean,
    metadata?: IStringPair[],
  ) {
    Guard.requireAll({ name, projectId })

    metadata?.forEach(m => this.metadataMap.set(m.key, m.value))
  }

  public static fromDto(
    dto: IDeliveryAttributeDto,
  ): CommonDeliveryAttributeDto {
    return new CommonDeliveryAttributeDto(
      dto.id,
      dto.name,
      dto.projectId,
      dto.isDeleted,
      dto.metadata,
    )
  }

  public toDto() {
    const dto = copyObject<CommonDeliveryAttributeDto>(this)
    delete dto.metadataMap
    return dto
  }

  public get isFromConcreteDirect(): boolean {
    const { integrationKey, cdIntegrationValue } = metadataKeys

    if (this.metadataMap.has(integrationKey)) {
      return this.metadataMap.get(integrationKey) === cdIntegrationValue
    }
  }
}
