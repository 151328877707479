import * as React from 'react'

import * as Icons from '~/client/src/shared/components/Icons'
import IBasicProps from '~/client/src/shared/interfaces/IBasicProps'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

// localization: no display text to translate

interface IProps {
  content: React.ReactText
  className?: string
}

export function TerminationState({ content, className }: IProps) {
  return (
    <div
      className={`termination-state no-grow py6 px8 bg-grey-scale-tooltip text large bold white ${
        className || EMPTY_STRING
      }`}
    >
      {content}
    </div>
  )
}

export function IntermediateState({ content, className }: IProps) {
  return (
    <div
      className={`intermediate-state no-grow py6 px8 text large bold ${
        className || EMPTY_STRING
      }`}
    >
      {content}
    </div>
  )
}

export function ActionState({ content, className }: IProps) {
  return (
    <div
      className={`action-state no-grow bg-white pa8 brada4 text bold uppercase ${
        className || EMPTY_STRING
      }`}
    >
      {content}
    </div>
  )
}

export function BallInCourtState({ className }: IBasicProps) {
  return (
    <div className={`row ${className || EMPTY_STRING}`}>
      <div className="ball-in-court-state col x-center y-center">
        <span className="text white bold">
          <Icons.BallInCourt className="row bic-state-icon" />
        </span>
      </div>
    </div>
  )
}
