import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Keys from '~/client/src/shared/enums/Keys'

import StruxhubBareEditableInputStore from './StruxhubBareEditableInput.store'

interface IProps {
  value: string

  onChange(updatedValue: string): void

  className?: string
  inputClassName?: string
}

@observer
export default class StruxhubBareEditableInput extends React.Component<IProps> {
  private readonly store: StruxhubBareEditableInputStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new StruxhubBareEditableInputStore(props.value, props.onChange)
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { value } = this.props
    if (value !== prevProps.value) {
      this.store.setDraftValue(value)
    }
  }

  public render() {
    const { className, inputClassName } = this.props
    const { draftValue, isInEditingMode, setToEditingMode, applyChange } =
      this.store

    return (
      <div className={className} onBlur={applyChange}>
        <Icon
          icon={IconNames.EDIT}
          size={16}
          className={classList({
            'mr5 no-grow vertical-align-middle': true,
            'text blue': isInEditingMode,
          })}
          onMouseDownCapture={this.onEditClick}
        />
        {isInEditingMode ? (
          <input
            type="text"
            className={classList({
              'bg-transparent': true,
              [className]: !!className && !inputClassName,
              [inputClassName]: !!inputClassName,
            })}
            onChange={this.handleUpdate}
            onKeyPress={this.handleKeyPress}
            defaultValue={draftValue}
            autoFocus={true}
          />
        ) : (
          <span className="text-ellipsis" onDoubleClick={setToEditingMode}>
            {draftValue}
          </span>
        )}
      </div>
    )
  }

  private handleUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.store.updateDraftValue(event.target.value)
  }

  private handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === Keys.Enter && !event.shiftKey) {
      this.store.applyChange()
    }
  }

  private onEditClick = (event: React.SyntheticEvent) => {
    event.stopPropagation()
    event.preventDefault()

    const { isInEditingMode, setToEditingMode, applyChange } = this.store

    if (isInEditingMode) {
      applyChange()
    } else {
      setToEditingMode()
    }
  }
}
