import * as React from 'react'

import { computed } from 'mobx'
import { observer } from 'mobx-react'
import { Layer, Source } from 'react-map-gl'

import { SitemapItemShapeType, SitemapLineArrowPosition } from '~/client/graph'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'

import ThemeMode from '../../../utils/ThemeModeManager'
import {
  SHAPE_ARROW_LINE_LAYER,
  SHAPE_FILL_LAYER,
  SHAPE_LINE_LAYER,
} from '../../MapBoxEditor/MapBoxViewer'
import MapBoxViewerStore, {
  MAX_ITEM_ZOOM_LEVEL,
  PROJECT_MARKER_ZOOM_LEVEL,
} from '../../MapBoxEditor/MapBoxViewer.store'
import MapViewItemDrawnPart from '../models/SitemapItemDrawnPart'
import SitemapPolyLineProperties from '../models/SitemapPolyLineProperties'
import GlobeViewMarker from './GlobeViewMarker'

import './GlobeViewItems.scss'

interface IProps {
  items: MapViewItemBase[]
  mapBoxViewerStore: MapBoxViewerStore
  onSelectItem?: (item: MapViewItemBase) => void
  renderMarkerPin?: (item: MapViewItemBase) => JSX.Element
  selectSitemapItemDrawnPart?: (part: MapViewItemDrawnPart) => void

  className?: string

  isDisabled?: boolean
}

@observer
export default class GlobeViewItems extends React.Component<IProps> {
  public render() {
    const { items } = this.props
    return (
      <>
        {this.renderShapes()}
        {items.map(this.renderIcon)}
      </>
    )
  }

  private renderIcon = (item: MapViewItemBase, idx: number) => {
    return (
      <GlobeViewMarker
        item={item}
        key={`${idx}-${item?.id}`}
        onMarkerClick={this.selectItem}
        onMarkerDblClick={this.selectDrawnPartEditing}
        renderMarkerPin={this.props.renderMarkerPin}
        isHighlighted={this.props.mapBoxViewerStore.isMarkerHighlighted(
          item.id,
        )}
        onMarkerHover={(id: string) =>
          (this.props.mapBoxViewerStore.hoveredMarkerItemId = id)
        }
      />
    )
  }

  private renderShapes = () => {
    return (
      <>
        {this.renderFillLayer()}
        {this.renderLineLayer()}
        {this.renderArrowsLayer()}
      </>
    )
  }

  private renderFillLayer = () => {
    return (
      <Source id="fill_shape_data" type="geojson" data={this.fillShapesGeoJson}>
        <Layer
          id={SHAPE_FILL_LAYER}
          type="fill"
          paint={{
            'fill-color': ['get', 'fill'],
            'fill-opacity': ['get', 'fill-opacity'],
          }}
          maxzoom={MAX_ITEM_ZOOM_LEVEL}
          minzoom={PROJECT_MARKER_ZOOM_LEVEL}
        />
      </Source>
    )
  }

  private renderLineLayer = () => {
    return (
      <Source id="line_shape_data" type="geojson" data={this.lineShapesGeoJson}>
        <Layer
          id={SHAPE_LINE_LAYER}
          type="line"
          paint={{
            'line-color': ['get', 'stroke'],
            'line-width': ['get', 'stroke-width'],
          }}
          maxzoom={MAX_ITEM_ZOOM_LEVEL}
          minzoom={PROJECT_MARKER_ZOOM_LEVEL}
        />
      </Source>
    )
  }

  private renderArrowsLayer = () => {
    return (
      <Source
        id="arrows_shape_data"
        type="geojson"
        data={this.arrowsShapeGeoJson}
      >
        <Layer
          id={SHAPE_ARROW_LINE_LAYER}
          type="symbol"
          layout={{
            'text-field': ['get', 'text-field'],
            'text-size': ['get', 'text-width'],
            'symbol-placement': 'line',
            'symbol-avoid-edges': true,
            'symbol-spacing': [
              'interpolate',
              // Set the exponential rate of change to 0.5
              ['linear'],
              ['zoom'],
              // When zoom is 10, spacing will be 1px.
              10,
              1,
              // When zoom is 20 or higher, spacing will be 60px.
              20,
              60,
            ],
            'text-rotation-alignment': 'map',
            'text-keep-upright': false,
          }}
          paint={{
            'text-color': ['get', 'text-color'],
          }}
          maxzoom={MAX_ITEM_ZOOM_LEVEL}
          minzoom={PROJECT_MARKER_ZOOM_LEVEL}
        />
      </Source>
    )
  }

  @computed
  private get arrowsShapeGeoJson():
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string {
    return {
      type: 'FeatureCollection',
      features: this.props.items
        .filter(
          i =>
            i.sitemapItem.shapeCoordinates?.coordinates?.length > 1 &&
            i.globeViewItemProperties.shapeProperties?.type ===
              SitemapItemShapeType.Polyline &&
            (
              i.globeViewItemProperties
                .shapeProperties as SitemapPolyLineProperties
            ).arrowPosition !== SitemapLineArrowPosition.None &&
            !i.sitemapItem.shapeCoordinates.isClosed,
        )
        .map(i => ({
          type: 'Feature',
          properties: {
            stroke: ThemeMode.getHEXColor(
              i.globeViewItemProperties.shapeProperties?.lineColor,
            ),
            'text-width':
              25 + i.globeViewItemProperties.shapeProperties?.lineWidth * 5,
            'text-field': [
              SitemapLineArrowPosition.StartEnd,
              SitemapLineArrowPosition.StartMiddleEnd,
            ].includes(
              (
                i.globeViewItemProperties
                  .shapeProperties as SitemapPolyLineProperties
              ).arrowPosition,
            )
              ? '►'
              : '◄',
            'text-color': ThemeMode.getHEXColor(
              i.globeViewItemProperties.shapeProperties?.lineColor,
            ),
            itemId: i.id,
          },
          geometry: {
            type: 'LineString',
            coordinates: i.sitemapItem.shapeCoordinates.shapeFeatureCoordinates,
          },
        })),
    }
  }

  private selectItem = (item: MapViewItemBase) => {
    const { onSelectItem, isDisabled, mapBoxViewerStore } = this.props

    if (!onSelectItem || isDisabled) {
      return
    }
    mapBoxViewerStore.selectedMarkerItemId = item.id
    onSelectItem(item)
  }

  @computed
  private get fillShapesGeoJson():
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string {
    return {
      type: 'FeatureCollection',
      features: this.props.items
        .filter(
          i =>
            !!i.sitemapItem.shapeCoordinates?.coordinates?.length &&
            (i.sitemapItem.shapeCoordinates.type !==
              SitemapItemShapeType.Polyline ||
              i.sitemapItem.shapeCoordinates.isClosed),
        )
        .map(i =>
          i.sitemapItem.shapeCoordinates.getShapePolygonFeature({
            fill: ThemeMode.getHEXColor(
              i.globeViewItemProperties.shapeProperties?.fillColor,
            ),
            'fill-opacity':
              i.globeViewItemProperties.shapeProperties?.fillOpacity,
            itemId: i.id,
          }),
        ),
    }
  }

  @computed
  private get lineShapesGeoJson():
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string {
    return {
      type: 'FeatureCollection',
      features: this.props.items
        .filter(i => !!i.sitemapItem.shapeCoordinates?.coordinates?.length)
        .map(i =>
          i.sitemapItem.shapeCoordinates.getShapeLineStringFeature({
            stroke: ThemeMode.getHEXColor(
              i.globeViewItemProperties.shapeProperties?.lineColor,
            ),
            'stroke-width':
              i.globeViewItemProperties.shapeProperties?.lineWidth,
            itemId: i.id,
          }),
        ),
    }
  }

  private selectDrawnPartEditing = (part: MapViewItemDrawnPart) => {
    this.props.selectSitemapItemDrawnPart?.(part)
  }
}
