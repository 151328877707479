import { IActivityPreset } from '~/client/graph'
import Guard from '~/client/src/shared/utils/Guard'

export default class ActivityPreset implements IActivityPreset {
  public static fromDto(dto: IActivityPreset) {
    return new ActivityPreset(
      dto.id,
      dto.name,
      dto.activities,
      dto.userId,
      dto.projectId,
    )
  }

  public constructor(
    public readonly id: string,
    public name: string,
    public readonly activities: string[],
    public readonly userId: string,
    public readonly projectId: string,
  ) {
    Guard.requireAll({ name, activities, projectId, userId })
  }
}
