import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observable } from 'mobx'
import { observer } from 'mobx-react'

import Keys from '../../../enums/Keys'
import ActionBarInput from '../../ActionBarInput/ActionBarInput'

interface IProps {
  onSubmit: (text: string) => void
  shouldBeSentOnEnter?: boolean
}

@observer
export default class ChatActionBar extends React.Component<IProps> {
  @observable private text: string = ''

  public render() {
    return (
      <div className="row y-end bg-palette-brand-lightest bt-light-grey pt10 pb20 px15 no-outline-container">
        <ActionBarInput
          value={this.text}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          placeholder="Message"
          className="channel-action-bar ba-light-input-border overflow-hidden no-resize"
        />
        <button
          className="row x-center no-grow chat-send-btn indication-click ml15 pointer"
          onClick={this.handleSubmit}
        >
          <Icon className="row" icon={IconNames.ARROW_UP} />
        </button>
      </div>
    )
  }

  private handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.text = e.target.value
  }

  private handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!this.props.shouldBeSentOnEnter) {
      return
    }

    if (e.key === Keys.Enter && !e.shiftKey) {
      e.stopPropagation()
      e.preventDefault()

      this.handleSubmit()
    }
  }

  private handleSubmit = () => {
    this.props.onSubmit(this.text)
    this.text = ''
  }
}
