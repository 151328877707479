import * as React from 'react'

import { observer } from 'mobx-react'

import SitePermit from '~/client/src/shared/models/Permit'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'

import PermitTypeIcon from '../../PermitTypeIcon/PermitTypeIcon'
import WorkflowCardStatus from '../../WorkflowCard/Status'
import CompactHeaderBar from '../CompactHeaderBar'

interface IProps {
  permit: SitePermit
  onCloseClick: () => void
  permitTypesStore: PermitTypesStore

  isMenuDisabled?: boolean
  onMenuClick?(): void
}

@observer
export default class PermitCompactHeaderBar extends React.Component<IProps> {
  public render() {
    const { permit, onCloseClick, onMenuClick, isMenuDisabled } = this.props

    return (
      <CompactHeaderBar
        titleCaption={this.permitTitleCaption}
        startDate={permit?.startDate}
        endDate={permit?.endDate}
        titleIconEl={this.titleIcon}
        isCreationMode={!permit?.id}
        renderStatus={this.permitWorkflowStatusRenderer}
        onCloseClick={onCloseClick}
        onMenuClick={onMenuClick}
        isMenuDisabled={isMenuDisabled}
      />
    )
  }

  private permitWorkflowStatusRenderer = (className: string): JSX.Element => {
    const { permit } = this.props

    return (
      <WorkflowCardStatus
        className={className}
        status={permit?.status}
        workflowStepLevel={permit?.workflowStepLevel}
        isLate={permit?.isLate}
      />
    )
  }

  private get permitTitleCaption(): string {
    const { permit, permitTypesStore } = this.props

    if (!permit?.id) {
      return permitTypesStore.getPermitTypeById(permit?.typeId)?.name
    }

    return permit?.getCaption(permitTypesStore)
  }

  private get titleIcon(): JSX.Element {
    const { permit, permitTypesStore } = this.props

    if (permit.typeId) {
      return (
        <PermitTypeIcon
          permitType={permit?.getTypeOfPermitType(permitTypesStore)}
          className="row no-grow"
        />
      )
    }
  }
}
