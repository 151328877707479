import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IDeliveryControl from '~/client/src/shared/models/IDeliveryControl'

const ICON = <Icons.CheckSolidGrey className="no-grow icon-w13" />

// translated

interface IProps {
  className?: string
  onChange?: (fieldId: FieldIds, value: any, fieldIndex?: number) => void
}

@observer
export default class ButtonToggle extends React.Component<
  IDeliveryControl & IProps
> {
  public render() {
    const { value: rawValue, isDisabled, isReadOnly, className } = this.props
    const value = Number(rawValue)

    return (
      <div
        className={classList({
          'row y-start py8': true,
          'unclickable-element': isReadOnly || (isDisabled && !!value),
          [className]: !!className,
        })}
      >
        {this.renderButton()}
        <div className="no-grow mx8" />
        {this.renderButton(true)}
      </div>
    )
  }

  private renderButton(isNoBtn?: boolean) {
    const { isRequired, value: rawValue } = this.props
    const value = Number(rawValue)

    const color = isNoBtn ? !!value || isRequired : !value
    const shouldRenderIcon = isNoBtn ? !value : !!value

    return (
      <div
        className={classList({
          'pointer h40 row x-center y-center bg-palette-brand-lightest ba-palette-brand-lighter no-outline brada4 pa8':
            true,
          'unclickable-element': isNoBtn && isRequired,
        })}
        onClick={this.handleChange.bind(this, isNoBtn ? '0' : '1')}
      >
        <span
          className={classList({
            'text extra-large': true,
            'grey-lighter': color,
          })}
        >
          {isNoBtn ? Localization.translator.no : Localization.translator.yes}
        </span>
        {shouldRenderIcon && ICON}
      </div>
    )
  }

  private handleChange(value: '1' | '0') {
    const { id, index, onChange, isDisabled, isReadOnly } = this.props
    if (!isDisabled && !isReadOnly) {
      onChange(id, value, index)
    }
  }
}
