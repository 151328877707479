import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { toggleClass } from 'react-classlist-helper'

import DesktopFileInput from '~/client/src/desktop/components/FileInput/DesktopFileInput'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import ContentObjectViewBase from '~/client/src/shared/components/ContentObject/ContentObjectViewBase'
import DaySeparator from '~/client/src/shared/components/DaySeparator'
import {
  Content,
  Footer,
  Header,
  View,
} from '~/client/src/shared/components/Layout'
import SafetyHazardThread from '~/client/src/shared/components/SafetyHazardEntry/SafetyHazardThread'
import SafetyHazardIcon from '~/client/src/shared/components/SafetyHazardIcon'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import SafetyHazardsStore from '~/client/src/shared/stores/domain/SafetyHazards.store'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

interface IProps {
  contentObjectsStore?: SafetyHazardsStore
  activitiesStore?: ActivitiesStore
  slackBarStore?: SlackBarStore
  state?: DesktopInitialState
  contentObjectId?: string
  goBack: () => void
}

@inject(({ state, activitiesStore, safetyHazardsStore, slackBarStore }) => {
  return {
    activitiesStore,
    contentObjectsStore: safetyHazardsStore,
    slackBarStore,
    state,
  }
})
@observer
export default class DesktopSafetyHazardView extends ContentObjectViewBase<IProps> {
  protected className: string = 'safety-hazard'

  public render() {
    const { userActiveProjectSettings } = this.props.state
    return (
      <View>
        <Header>{this.header}</Header>
        {this.imagePreview}
        <Content scrollable={true}>{this.content}</Content>
        <Footer
          className={toggleClass(
            'inactive-element',
            userActiveProjectSettings?.isPresentationUser,
          )}
        >
          {this.footer}
        </Footer>
      </View>
    )
  }

  public get content() {
    const currEntryDate = new Date(this.contentObject.createdAt)

    return (
      <div className="activity-log">
        <DaySeparator date={currEntryDate} />
        <SafetyHazardThread
          safetyHazard={this.contentObject}
          hideButtons={true}
          FileInputType={DesktopFileInput}
        />
      </div>
    )
  }

  @action.bound
  public goBack() {
    this.props.goBack()
  }

  @action.bound
  public goToActivityDetails() {
    this.props.goBack()
  }

  protected caption = () => Localization.translator.safetyHazard
  protected replyToCaption = () => Localization.translator.replyToSafetyHazard

  protected getIcon(): React.ReactNode {
    return <SafetyHazardIcon safetyHazard={this.contentObject} />
  }
}
