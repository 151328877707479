import * as React from 'react'

import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { LocationType } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import {
  HOURS_IN_DAY,
  HOUR_HEIGHT_PX,
} from '~/client/src/shared/stores/ui/CalendarView.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import Localization from '../../../localization/LocalizationManager'
import { CalendarEventEntityType } from '../../../models/CalendarEvent'
import { ICollectionItem } from '../../../stores/domain/Calendars.store'
import MenuCloser from '../../MenuCloser'
import CalendarGroupByMenu from './CalendarGroupByMenu'

interface IProps {
  dayHours: number[]

  selectedType?: LocationType
  entityType?: CalendarEventEntityType
  onTypeChange?: (type: LocationType) => void

  shouldUseLargeText?: boolean
  shouldHideTimezone?: boolean
  shouldHideProjectHours?: boolean
  projectDateStore?: ProjectDateStore
  filteredAttributes?: ICollectionItem[]
  updateSortedCollection?: (collection: ICollectionItem[]) => void
  isDayCalendarModeActive?: boolean
  setShowingOfAllZoneColumns?: (shouldShowAllAttributesColumns: boolean) => void
  setRef?(ref: HTMLDivElement): void
}

interface IProjectHourStyle {
  marginTop: number
}

const HOURS_MARGIN_DIFFERENCE = 11
const MAX_PROJECT_HOUR_MARGIN_HEIGHT = 40

@inject('projectDateStore')
@observer
export default class CalendarHoursLabels extends React.Component<IProps> {
  @observable private isMenuShown: boolean = false
  @observable private areEmptyGroupsHidden: boolean = true

  public render() {
    const {
      shouldUseLargeText,
      shouldHideTimezone,
      setRef,
      dayHours,
      projectDateStore: { getFullHourLabel },
    } = this.props

    return (
      <>
        {!shouldHideTimezone && this.renderTopLeftSelector()}
        <div className="hour-labels-container no-grow relative" ref={setRef}>
          {dayHours.map(hour => {
            return (
              <div
                key={hour}
                className="col"
                style={{ height: HOUR_HEIGHT_PX }}
              >
                <div
                  className={classList({
                    'text right pr10 full-height': true,
                    large: shouldUseLargeText,
                  })}
                >
                  {getFullHourLabel(hour % HOURS_IN_DAY)}
                </div>
                {this.renderProjectDates(hour)}
              </div>
            )
          })}
        </div>
      </>
    )
  }

  private renderTopLeftSelector() {
    const {
      projectDateStore: { getClientTimezoneOffsetAsString },
      selectedType,
      entityType,
      onTypeChange,
      filteredAttributes,
      updateSortedCollection,
      isDayCalendarModeActive,
    } = this.props

    if (
      isDayCalendarModeActive &&
      entityType === CalendarEventEntityType.Delivery
    ) {
      return (
        <div className="gmt-timezone top5">
          <div
            className={classList({
              'py10 text blue row large pointer brada4': true,
              'bg-blue-light unclickable-element': this.isMenuShown,
            })}
            onClick={this.toggleMenu}
          >
            <Icons.GroupBy className="no-grow mr5" />
            <span>{Localization.translator.groupBy}</span>
          </div>
          {this.isMenuShown && (
            <MenuCloser closeMenu={this.toggleMenu}>
              <CalendarGroupByMenu
                selectedType={selectedType}
                onTypeChange={onTypeChange}
                areEmptyGroupsHidden={this.areEmptyGroupsHidden}
                toggleHideEmpty={this.toggleEmptyGroups}
                filteredAttributes={filteredAttributes}
                updateSortedCollection={updateSortedCollection}
              />
            </MenuCloser>
          )}
        </div>
      )
    }

    return (
      <div className="gmt-timezone text bold">
        {getClientTimezoneOffsetAsString()}
      </div>
    )
  }

  private toggleMenu = () => {
    this.isMenuShown = !this.isMenuShown
  }

  private toggleEmptyGroups = () => {
    this.areEmptyGroupsHidden = !this.areEmptyGroupsHidden
    this.props.setShowingOfAllZoneColumns(!this.areEmptyGroupsHidden)
  }

  private renderProjectDates(hour: number): JSX.Element {
    const {
      shouldUseLargeText,
      shouldHideProjectHours,
      projectDateStore: { areProjectStartEndTimesTheSame, getTimeToDisplay },
    } = this.props

    if (shouldHideProjectHours) {
      return null
    }

    const shouldRenderTwoDates = this.shouldShowSecondProjectDate(hour)
    const projectDate = this.getProjectDate(hour, shouldRenderTwoDates)

    if (areProjectStartEndTimesTheSame || !projectDate) {
      return null
    }

    return (
      <div
        className={classList({
          'absolute col text full-width pr10 opacity5 mt10': true,
          small: !shouldUseLargeText,
        })}
      >
        <div
          className="row x-end"
          style={this.getProjectTimeStyle(projectDate, shouldRenderTwoDates)}
        >
          {getTimeToDisplay(projectDate)}
        </div>
        {shouldRenderTwoDates && (
          <div className="row x-end mt25">
            {getTimeToDisplay(this.secondProjectDate)}
          </div>
        )}
      </div>
    )
  }

  private shouldShowSecondProjectDate = (hour: number): boolean => {
    const { projectStartHourMinutes, projectEndHourMinutes } =
      this.props.projectDateStore

    const [projectStartHour, projectStartMinutes] = projectStartHourMinutes
    const [projectEndHour, projectEndMinutes] = projectEndHourMinutes

    return (
      hour === projectStartHour &&
      hour === projectEndHour &&
      !!projectStartMinutes &&
      !!projectEndMinutes
    )
  }

  private getProjectDate = (hour: number, areTwoDatesShown: boolean): Date => {
    const {
      projectWorkingHours,
      projectStartHourMinutes,
      projectEndHourMinutes,
    } = this.props.projectDateStore

    if (areTwoDatesShown) {
      return projectWorkingHours.startTime > projectWorkingHours.endTime
        ? projectWorkingHours.endTime
        : projectWorkingHours.startTime
    }

    const [projectStartHour, projectStartMinutes] = projectStartHourMinutes
    const [projectEndHour, projectEndMinutes] = projectEndHourMinutes

    if (hour === projectStartHour && !!projectStartMinutes) {
      return projectWorkingHours.startTime
    }

    if (hour === projectEndHour && !!projectEndMinutes) {
      return projectWorkingHours.endTime
    }

    return null
  }

  private get secondProjectDate(): Date {
    const { projectWorkingHours } = this.props.projectDateStore

    return projectWorkingHours.startTime > projectWorkingHours.endTime
      ? projectWorkingHours.startTime
      : projectWorkingHours.endTime
  }

  private getProjectTimeStyle = (
    projectDate: Date,
    areTwoDatesShown: boolean,
  ): IProjectHourStyle => {
    if (areTwoDatesShown) {
      return null
    }

    const { getHoursMinutes } = this.props.projectDateStore
    const [, minutes] = getHoursMinutes(projectDate)

    return this.getStyle(minutes)
  }

  private getStyle = (minutes: number): IProjectHourStyle => {
    let marginTop = 0

    if (minutes > HOURS_MARGIN_DIFFERENCE) {
      marginTop = minutes - HOURS_MARGIN_DIFFERENCE
    }

    if (marginTop > MAX_PROJECT_HOUR_MARGIN_HEIGHT) {
      marginTop = MAX_PROJECT_HOUR_MARGIN_HEIGHT
    }

    return { marginTop }
  }
}
