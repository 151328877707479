import { ObservableMap, action, computed, observable } from 'mobx'

import { IConcreteDirectOrder, ISubscription } from '~/client/graph'
import {
  GetConcreteDirectOrderByIdDocument,
  ListenCdOrderByIdDocument,
} from '~/client/graph/operations/generated/ConcreteDirectOrders.generated'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'

import ConcreteDirectOrder from '../../../models/ConcreteDirectOrder'
import Guard from '../../../utils/Guard'
import InitialState from '../../InitialState'
import GraphExecutorStore from '../GraphExecutor.store'
import ConcreteDirectIntegrationStore from './ConcreteDirectIntegration.store'

const GET_CD_ORDER = 'get-cd-order'
const CD_ORDER_SUBSCRIPTION_ID = 'concreteDirectOrder'

export default class ConcreteDirectOrdersStore {
  @observable private readonly ordersMap = new Map<
    string,
    ConcreteDirectOrder
  >()

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly graphExecutorStore: GraphExecutorStore,
    private readonly concreteDirectIntegrationStore: ConcreteDirectIntegrationStore,
  ) {
    Guard.requireAll({
      eventsStore,
      graphExecutorStore,
      concreteDirectIntegrationStore,
    })
  }

  public getOrderById = (orderId: string): ConcreteDirectOrder => {
    if (this.cdOrderIdMap.has(orderId)) {
      return this.cdOrderIdMap.get(orderId)
    }
  }

  @action.bound
  public async loadAndListenOrder(orderId: string) {
    if (!orderId) {
      return
    }

    this.loadingMap.set(GET_CD_ORDER, true)
    this.clearData()

    const { data } = await this.graphExecutorStore.query(
      GetConcreteDirectOrderByIdDocument,
      { cdId: orderId },
    )

    if (data?.concreteDirectOrder) {
      this.receiveOne(data.concreteDirectOrder.id, data.concreteDirectOrder)
      this.listenCdOrderUpdate(data.concreteDirectOrder.id)
    }

    this.loadingMap.set(GET_CD_ORDER, false)
  }

  @action.bound
  public clearData() {
    this.ordersMap.clear()
  }

  public terminateSubscription = () => {
    this.graphExecutorStore.terminateSubscription(CD_ORDER_SUBSCRIPTION_ID)
  }

  private listenCdOrderUpdate = (id: string) => {
    if (this.concreteDirectIntegrationStore.isIntegrationEnabled) {
      this.graphExecutorStore.subscribe(
        ListenCdOrderByIdDocument,
        { id },
        false,
        CD_ORDER_SUBSCRIPTION_ID,
        this.handleDataUpdating,
      )
    }
  }

  private handleDataUpdating = (res: ISubscription) => {
    const { id, item } = res?.concreteDirectOrder || {}

    this.receiveOne(id, item)
  }

  @action.bound
  private receiveOne(id: string, dto: IConcreteDirectOrder) {
    if (dto) {
      this.ordersMap.set(id, ConcreteDirectOrder.fromDto(dto))
      return
    }

    this.ordersMap.delete(id)
  }

  private get state(): InitialState {
    return this.eventsStore.appState
  }

  private get loadingMap(): ObservableMap<string, boolean> {
    return this.state.loading
  }

  @computed
  private get cdOrderIdMap(): Map<string, ConcreteDirectOrder> {
    return Array.from(this.ordersMap.values()).reduce((map, order) => {
      map.set(order.concreteDirectId, order)
      return map
    }, new Map<string, ConcreteDirectOrder>())
  }
}
