import * as React from 'react'

// translated

export default function DetailListItem({
  category,
  children,
}: {
  category: string
  children: any
}) {
  return (
    <div className="row y-start pb15">
      <div className="col no-grow pr15 pt2 flex-basis108">
        <div className="text small right bold uppercase primary-blue">
          {category}
        </div>
      </div>
      <div className="col">{children}</div>
    </div>
  )
}
