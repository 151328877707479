import ProjectDateStore from '../stores/ui/ProjectDate.store'
import Day from './Day'

export class ChartDay extends Day {
  public readonly dayName: string
  public readonly isWeekend: boolean
  public readonly isToday: boolean
  public readonly isSaturday: boolean

  public constructor(
    date: Date,
    projectDateStore: ProjectDateStore,
    public readonly ppc: number = null,
  ) {
    super(date, projectDateStore)
    this.dayName = projectDateStore.getWeekdayToDisplay(date)
    this.isWeekend = projectDateStore.isWeekend(date)
    this.isToday = projectDateStore.isToday(date)
    this.isSaturday = projectDateStore.isSaturday(date)
  }
}
