import { computed, observable } from 'mobx'

import { IWorkBreakdownStructure } from '~/client/graph'
import Activity from '~/client/src/shared/models/Activity'
import Wbs from '~/client/src/shared/models/Wbs'
import WbsTreeNode from '~/client/src/shared/models/WbsTreeNode'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'

import { UNASSIGNED } from '../../../utils/ZoneLevelLocationConstants'
import BaseStore from '../../baseStores/Base.store'

export default class WbsStore extends BaseStore<Wbs, IWorkBreakdownStructure> {
  public filteredActivitiesWbsIdMap: { [key: string]: Activity[] } = {}

  private wbs = observable.array<Wbs>([])

  public constructor(private activitiesStore: ActivitiesStore) {
    super(Wbs)
  }

  public get list() {
    return this.wbs
  }

  @computed
  public get wbsTree(): { [key: string]: WbsTreeNode } {
    const treeNodesCache: { [key: string]: WbsTreeNode } = {}
    const parentlessNodes: { [key: string]: WbsTreeNode } = {}
    this.list.forEach(wbs => {
      treeNodesCache[wbs.id] = new WbsTreeNode(wbs)
    })
    Object.keys(treeNodesCache).forEach(wbsId => {
      const treeNode = treeNodesCache[wbsId]
      const wbs = treeNode.wbs
      if (!wbs.parentId || !treeNodesCache[wbs.parentId]) {
        parentlessNodes[wbs.id] = treeNode
      } else {
        treeNodesCache[wbs.parentId].children.push(treeNode)
      }
    })
    return parentlessNodes
  }

  public getActivitiesWbsIdMap(optionalActivities: Activity[] = null) {
    if (!optionalActivities) {
      return this.allActivitiesWbsIdMap
    }

    return this.calculateActivitiesWbsIdMap(optionalActivities)
  }

  public sortWbsTreeByActivitiesDateDesc(
    wbsTree: WbsTreeNode[],
    activitiesWbsIdMap: any,
  ): WbsTreeNode[] {
    wbsTree.forEach(wbsNode => {
      wbsNode.startDate = new Date(32503683600000) // 3000 year
      if (wbsNode.children?.length) {
        const sortedChildren = this.sortWbsTreeByActivitiesDateDesc(
          wbsNode.children,
          activitiesWbsIdMap,
        )
        wbsNode.children.splice(0, wbsNode.children.length, ...sortedChildren)
        wbsNode.startDate = sortedChildren[0].startDate
      }
      const startDate =
        activitiesWbsIdMap[wbsNode.wbs.id]?.[0].dates.planned.start.date
      if (startDate && wbsNode.startDate > startDate) {
        wbsNode.startDate = startDate
      }
    })

    return wbsTree.sort(
      (nodeLeft, nodeRight) =>
        nodeLeft.startDate?.getTime() - nodeRight.startDate?.getTime(),
    )
  }

  public filterWbsWithActivities(
    wbsTree: WbsTreeNode[],
    filteredActivities: Activity[] = null,
  ): WbsTreeNode[] {
    if (filteredActivities) {
      this.filteredActivitiesWbsIdMap =
        this.getActivitiesWbsIdMap(filteredActivities)
    }

    const filteredTree: WbsTreeNode[] = []

    wbsTree.forEach(wbsNode => {
      let filteredChildren: WbsTreeNode[] = []
      if (wbsNode.children?.length) {
        filteredChildren = this.filterWbsWithActivities(wbsNode.children)
      }

      if (
        filteredChildren.length ||
        this.filteredActivitiesWbsIdMap[wbsNode.wbs.id]
      ) {
        filteredTree.push(new WbsTreeNode(wbsNode.wbs, filteredChildren))
      }
    })

    return filteredTree
  }

  @computed
  private get allActivitiesWbsIdMap() {
    return this.calculateActivitiesWbsIdMap(this.activitiesStore.list)
  }

  private calculateActivitiesWbsIdMap(activities: Activity[]) {
    const activitiesWbsIdMap = {}
    activities.forEach(activity => {
      const wbs = activity.wbs || UNASSIGNED + ' wbs'

      if (activitiesWbsIdMap[wbs]) {
        activitiesWbsIdMap[wbs].push(activity)
      } else {
        activitiesWbsIdMap[wbs] = [activity]
      }
    })

    Object.keys(activitiesWbsIdMap).forEach(wbsId => {
      activitiesWbsIdMap[wbsId].sort(
        (actLeft: Activity, actRight: Activity) =>
          actLeft.dates.planned.start.date?.getTime() -
          actRight.dates.planned.start.date?.getTime(),
      )
    })

    return activitiesWbsIdMap
  }
}
