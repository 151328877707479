import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IWhiteListItemFieldsFragment = Pick<
  Types.IWhiteListItem,
  'id' | 'companyId' | 'domainName'
>

export type IGetWhiteListItemsByProjectQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetWhiteListItemsByProjectQuery = {
  whiteListItems?: Types.Maybe<{ data: Array<IWhiteListItemFieldsFragment> }>
}

export type IGetWhiteListItemsByCompanyQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ObjectId']
}>

export type IGetWhiteListItemsByCompanyQuery = {
  whiteListItems?: Types.Maybe<{ data: Array<IWhiteListItemFieldsFragment> }>
}

export type IDeleteWhiteListItemsMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteWhiteListItemsMutation = Pick<
  Types.IMutation,
  'deleteManyWhiteListItems'
>

export type ISaveWhiteListItemMutationVariables = Types.Exact<{
  item: Types.IWhiteListItemInput
}>

export type ISaveWhiteListItemMutation = {
  saveWhiteListItem?: Types.Maybe<IWhiteListItemFieldsFragment>
}

export const WhiteListItemFieldsFragmentDoc = gql`
  fragment WhiteListItemFields on WhiteListItem {
    id
    companyId
    domainName
  }
`
export const GetWhiteListItemsByProjectDocument = gql`
  query GetWhiteListItemsByProject($projectId: ObjectId!) {
    whiteListItems(projectId: $projectId, limit: 1000000) {
      data {
        ...WhiteListItemFields
      }
    }
  }
  ${WhiteListItemFieldsFragmentDoc}
`

/**
 * __useGetWhiteListItemsByProjectQuery__
 *
 * To run a query within a React component, call `useGetWhiteListItemsByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWhiteListItemsByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWhiteListItemsByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetWhiteListItemsByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetWhiteListItemsByProjectQuery,
    IGetWhiteListItemsByProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetWhiteListItemsByProjectQuery,
    IGetWhiteListItemsByProjectQueryVariables
  >(GetWhiteListItemsByProjectDocument, options)
}
export function useGetWhiteListItemsByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetWhiteListItemsByProjectQuery,
    IGetWhiteListItemsByProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetWhiteListItemsByProjectQuery,
    IGetWhiteListItemsByProjectQueryVariables
  >(GetWhiteListItemsByProjectDocument, options)
}
export type GetWhiteListItemsByProjectQueryHookResult = ReturnType<
  typeof useGetWhiteListItemsByProjectQuery
>
export type GetWhiteListItemsByProjectLazyQueryHookResult = ReturnType<
  typeof useGetWhiteListItemsByProjectLazyQuery
>
export type GetWhiteListItemsByProjectQueryResult = Apollo.QueryResult<
  IGetWhiteListItemsByProjectQuery,
  IGetWhiteListItemsByProjectQueryVariables
>
export const GetWhiteListItemsByCompanyDocument = gql`
  query GetWhiteListItemsByCompany($companyId: ObjectId!) {
    whiteListItems(companyId: $companyId, limit: 1000000) {
      data {
        ...WhiteListItemFields
      }
    }
  }
  ${WhiteListItemFieldsFragmentDoc}
`

/**
 * __useGetWhiteListItemsByCompanyQuery__
 *
 * To run a query within a React component, call `useGetWhiteListItemsByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWhiteListItemsByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWhiteListItemsByCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetWhiteListItemsByCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetWhiteListItemsByCompanyQuery,
    IGetWhiteListItemsByCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetWhiteListItemsByCompanyQuery,
    IGetWhiteListItemsByCompanyQueryVariables
  >(GetWhiteListItemsByCompanyDocument, options)
}
export function useGetWhiteListItemsByCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetWhiteListItemsByCompanyQuery,
    IGetWhiteListItemsByCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetWhiteListItemsByCompanyQuery,
    IGetWhiteListItemsByCompanyQueryVariables
  >(GetWhiteListItemsByCompanyDocument, options)
}
export type GetWhiteListItemsByCompanyQueryHookResult = ReturnType<
  typeof useGetWhiteListItemsByCompanyQuery
>
export type GetWhiteListItemsByCompanyLazyQueryHookResult = ReturnType<
  typeof useGetWhiteListItemsByCompanyLazyQuery
>
export type GetWhiteListItemsByCompanyQueryResult = Apollo.QueryResult<
  IGetWhiteListItemsByCompanyQuery,
  IGetWhiteListItemsByCompanyQueryVariables
>
export const DeleteWhiteListItemsDocument = gql`
  mutation DeleteWhiteListItems($ids: [ObjectId]!) {
    deleteManyWhiteListItems(ids: $ids)
  }
`
export type IDeleteWhiteListItemsMutationFn = Apollo.MutationFunction<
  IDeleteWhiteListItemsMutation,
  IDeleteWhiteListItemsMutationVariables
>

/**
 * __useDeleteWhiteListItemsMutation__
 *
 * To run a mutation, you first call `useDeleteWhiteListItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWhiteListItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWhiteListItemsMutation, { data, loading, error }] = useDeleteWhiteListItemsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteWhiteListItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteWhiteListItemsMutation,
    IDeleteWhiteListItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteWhiteListItemsMutation,
    IDeleteWhiteListItemsMutationVariables
  >(DeleteWhiteListItemsDocument, options)
}
export type DeleteWhiteListItemsMutationHookResult = ReturnType<
  typeof useDeleteWhiteListItemsMutation
>
export type DeleteWhiteListItemsMutationResult =
  Apollo.MutationResult<IDeleteWhiteListItemsMutation>
export type DeleteWhiteListItemsMutationOptions = Apollo.BaseMutationOptions<
  IDeleteWhiteListItemsMutation,
  IDeleteWhiteListItemsMutationVariables
>
export const SaveWhiteListItemDocument = gql`
  mutation SaveWhiteListItem($item: WhiteListItemInput!) {
    saveWhiteListItem(whiteListItem: $item) {
      ...WhiteListItemFields
    }
  }
  ${WhiteListItemFieldsFragmentDoc}
`
export type ISaveWhiteListItemMutationFn = Apollo.MutationFunction<
  ISaveWhiteListItemMutation,
  ISaveWhiteListItemMutationVariables
>

/**
 * __useSaveWhiteListItemMutation__
 *
 * To run a mutation, you first call `useSaveWhiteListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWhiteListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWhiteListItemMutation, { data, loading, error }] = useSaveWhiteListItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *   },
 * });
 */
export function useSaveWhiteListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveWhiteListItemMutation,
    ISaveWhiteListItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveWhiteListItemMutation,
    ISaveWhiteListItemMutationVariables
  >(SaveWhiteListItemDocument, options)
}
export type SaveWhiteListItemMutationHookResult = ReturnType<
  typeof useSaveWhiteListItemMutation
>
export type SaveWhiteListItemMutationResult =
  Apollo.MutationResult<ISaveWhiteListItemMutation>
export type SaveWhiteListItemMutationOptions = Apollo.BaseMutationOptions<
  ISaveWhiteListItemMutation,
  ISaveWhiteListItemMutationVariables
>
