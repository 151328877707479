import React from 'react'

import { observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'

import ThemeMode from '../../../../utils/ThemeModeManager'
import GlobeViewBasePin from './GlobeViewBasePin'

import Colors from '~/client/src/shared/theme.module.scss'

interface IProps {
  itemsCount: number
  isDone: boolean
  isAssigned: boolean
  isSelected: boolean
  isCanceled: boolean
  onClick?: () => void
  left?: number
  right?: number
}

@observer
export default class GlobeViewActivityMapPin extends React.Component<IProps> {
  public render() {
    const { itemsCount, isSelected, onClick, left, right } = this.props
    const fillColor = ThemeMode.getHEXColor(this.pinFillColor)
    return (
      <GlobeViewBasePin
        onClick={onClick}
        itemsCount={itemsCount}
        color={this.iconColor}
        fillColor={fillColor}
        isSelected={isSelected}
        icon={
          <Icons.ActivitiesNoColor
            style={{ fill: this.iconColor }}
            className="globe-pin-image row x-center y-center"
          />
        }
        left={left}
        right={right}
      />
    )
  }

  private get pinFillColor(): string {
    const { isSelected } = this.props
    return isSelected ? Colors.error50 : Colors.primary40
  }

  private get iconColor(): string {
    const { isAssigned, isSelected, isDone, isCanceled } = this.props

    return ThemeMode.getHEXColor(
      isAssigned && isSelected && !isDone && !isCanceled
        ? Colors.neutral0
        : Colors.neutral100,
    )
  }
}
