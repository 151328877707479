enum ConcreteDirectOrderStatus {
  Requested = 'requested',
  Unconfirmed = 'unconfirmed',
  Cancelled = 'cancelled',
  Confirmed = 'confirmed',
  Delivering = 'delivering',
  Paused = 'paused',
  OnHold = 'on_hold',
  Completed = 'completed',
}

export default ConcreteDirectOrderStatus
