import * as React from 'react'

import { inject, observer } from 'mobx-react'

import {
  IFormMaterial,
  IPermitTypeField,
  ISiteLocation,
  PermitFieldType,
} from '~/client/graph'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { EMPTY_STRING, NO_VALUE } from '~/client/src/shared/utils/usefulStrings'
import { copyObject } from '~/client/src/shared/utils/util'

import SitemapAttributeTag from '../../../SitemapAttributeTag/SitemapAttributeTag'
import StruxhubAttributeSelector from '../../../StruxhubInputs/StruxhubSelector/StruxhubAttributeSelector'
import StruxhubTextValueSelector from '../../../StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'
import SitePermitCreationFormStore, {
  FormDropdownType,
} from '../../SitePermitCreationForm.store'
import PermitBaseFormField from './PermitBaseFormField'
import PermitFieldRemoveIcon from './PermitFieldRemoveIcon'

interface IProps {
  typeField: IPermitTypeField
  isViewMode: boolean
  store: SitePermitCreationFormStore

  fieldsStore: IPermitFieldsStore

  materialsStore?: MaterialsStore
  tagsStore?: TagsStore
}

type FormMaterialKey = keyof IFormMaterial
const FormMaterialKey: { [K in FormMaterialKey]: K } = {
  materialId: 'materialId',
  procurementId: 'procurementId',
  quantity: 'quantity',
  locationId: 'locationId',
}

@inject('materialsStore', 'tagsStore')
@observer
export default class PermitFormMaterialField extends React.Component<IProps> {
  public render() {
    const { isViewMode, typeField, materialsStore } = this.props
    const { caption, isMandatory, isMultiple } = typeField
    const { hasProcurementIds, getInstanceById: getMaterialById } =
      materialsStore
    const shouldShowTitle = isMultiple && this.fieldValues.length > 1

    return (
      <PermitBaseFormField
        typeField={typeField}
        isViewMode={isViewMode}
        viewModeElements={this.viewModeElements}
        onAddFieldClick={this.addNewFieldValue}
      >
        {this.fieldValues.map((formMaterial, index) => {
          const material = getMaterialById(formMaterial?.materialId)
          const procurementValue =
            material?.includesProcurementId(formMaterial?.procurementId) &&
            formMaterial?.procurementId

          return (
            <div key={`${material?.id}_${index}`} className="col">
              {shouldShowTitle && (
                <div className="x-between pa10 row">
                  <div className="text huge bold">
                    {Localization.translator.xMaterial(index + 1)}
                  </div>
                  <PermitFieldRemoveIcon
                    index={index}
                    onClick={this.removeValue}
                  />
                </div>
              )}
              <StruxhubTextValueSelector
                label={caption}
                isRequired={isMandatory}
                isChanged={this.isFieldChanged(
                  FormMaterialKey.materialId,
                  index,
                )}
                value={material?.productName || EMPTY_STRING}
                onClick={this.onDropdownFieldClick.bind(
                  this,
                  index,
                  FormDropdownType.MATERIAL,
                  this.changeMaterialId,
                )}
              />
              {hasProcurementIds && (
                <>
                  <StruxhubTextValueSelector
                    label={Localization.translator.procurementID}
                    isRequired={material?.hasProcurementIds}
                    isChanged={this.isFieldChanged(
                      FormMaterialKey.procurementId,
                      index,
                    )}
                    value={procurementValue || EMPTY_STRING}
                    onClick={this.onDropdownFieldClick.bind(
                      this,
                      index,
                      FormDropdownType.PROCUREMENT_ID,
                      this.changeProcurementId,
                    )}
                  />
                </>
              )}
              {!!material && !!this.quantitySubField && (
                <StruxhubInput
                  id={`${typeField.id}_${index}`}
                  className="overflow-hidden"
                  label={this.quantitySubField.caption}
                  type="number"
                  step={1}
                  isRequired={this.quantitySubField.isMandatory}
                  isChanged={this.isFieldChanged(
                    FormMaterialKey.quantity,
                    index,
                  )}
                  value={formMaterial?.quantity || EMPTY_STRING}
                  onChange={this.changeQuantity.bind(this, index)}
                  onValueReset={this.resetValue.bind(
                    this,
                    FormMaterialKey.quantity,
                    index,
                  )}
                />
              )}
              {!!material && !!this.locationSubField && (
                <StruxhubAttributeSelector
                  className="overflow-hidden"
                  label={this.locationSubField.caption}
                  isRequired={this.locationSubField.isMandatory}
                  isChanged={this.isFieldChanged(
                    FormMaterialKey.locationId,
                    index,
                  )}
                  value={formMaterial?.locationId || ''}
                  onClick={this.onDropdownFieldClick.bind(
                    this,
                    index,
                    FormDropdownType.NONE,
                    this.changeLocation,
                    true,
                  )}
                />
              )}
            </div>
          )
        })}
      </PermitBaseFormField>
    )
  }

  private get viewModeElements(): JSX.Element[] {
    const { materialsStore, typeField } = this.props
    return this.fieldValues
      .filter(p => p)
      .map((formMaterial, idx) => {
        const material = materialsStore.getInstanceById(
          formMaterial?.materialId,
        )
        return (
          <React.Fragment key={idx}>
            {!!idx && (
              <div className="text large grey word-break-all py4">
                {typeField.caption}
              </div>
            )}
            <div className="text large word-break-all">
              {material?.productName || NO_VALUE}
            </div>
            {!!material && (
              <>
                {material.includesProcurementId(formMaterial.procurementId) &&
                  this.renderViewModeElement(
                    Localization.translator.procurementID,
                    formMaterial.procurementId,
                  )}
                {!!this.quantitySubField &&
                  this.renderViewModeElement(
                    this.quantitySubField.caption,
                    formMaterial.quantity || NO_VALUE,
                  )}
                {!!this.locationSubField &&
                  this.renderViewModeElement(
                    this.locationSubField.caption,
                    this.getLocationViewModel(formMaterial.locationId),
                  )}
              </>
            )}
          </React.Fragment>
        )
      })
  }

  private renderViewModeElement(
    caption: string,
    value: string | JSX.Element,
  ): JSX.Element {
    return (
      <>
        <div className="text large grey word-break-all py4">{caption}</div>
        <div className="text large word-break-all">{value}</div>
      </>
    )
  }

  private getLocationViewModel(locationId: string): JSX.Element {
    const location = this.props.tagsStore.getTagById(locationId) as LocationBase

    if (!location) {
      return <span>{NO_VALUE}</span>
    }

    return (
      <SitemapAttributeTag
        shouldShowAsTag={false}
        dataObject={location}
        className="row"
        contentContainerClassName="text large ellipsis"
      >
        {location.name}
      </SitemapAttributeTag>
    )
  }

  private get fieldValues(): IFormMaterial[] {
    const { typeField, fieldsStore } = this.props
    const values = fieldsStore.getFieldValues<IFormMaterial>(typeField.id)

    return values?.length ? values : [{ materialId: null }]
  }

  private get existingValues(): IFormMaterial[] {
    const { typeField, fieldsStore } = this.props
    const values = fieldsStore.getFieldValues<IFormMaterial>(
      typeField.id,
      null,
      null,
      true,
    )

    return values?.length ? values : [{ materialId: null }]
  }

  private get materialSubFields(): IPermitTypeField[] {
    const { fieldsStore, typeField } = this.props
    return fieldsStore.getMaterialSubFields(typeField.id)
  }

  private get quantitySubField(): IPermitTypeField {
    return this.materialSubFields.find(
      field => field.type === PermitFieldType.InputNumber,
    )
  }

  private get locationSubField(): IPermitTypeField {
    return this.materialSubFields.find(
      field => field.type === PermitFieldType.LocationInformational,
    )
  }

  private isFieldChanged = (key: FormMaterialKey, index: number): boolean => {
    const { fieldsStore, typeField } = this.props
    const { editableEntity, isFieldChanged } = fieldsStore

    if (!editableEntity?.id) {
      return false
    }

    return (
      isFieldChanged(typeField.id, index) &&
      this.fieldValues[index]?.[key] !== this.existingValues[index]?.[key]
    )
  }

  private onDropdownFieldClick(
    fieldIndex: number,
    dropdownType: FormDropdownType,
    onChange: (fieldIndex: number, newValue: string) => void,
    isLocationSubField?: boolean,
  ) {
    const { store, typeField } = this.props
    const {
      setSelectedField,
      setSelectedSubField,
      setSelectedFieldDropdownType,
      setSelectedOnChangeHandler,
    } = store

    setSelectedField(typeField, fieldIndex)
    setSelectedFieldDropdownType(dropdownType)
    setSelectedOnChangeHandler(onChange.bind(this, fieldIndex))

    if (isLocationSubField) {
      setSelectedSubField(this.locationSubField)
    }
  }

  private changeMaterialId(fieldIndex: number, newValue: string) {
    if (!newValue) {
      this.setMaterialValue(null, fieldIndex)
      return
    }

    const formMaterial = copyObject<IFormMaterial>(this.fieldValues[fieldIndex])
    const material = this.props.materialsStore.getInstanceById(newValue)
    const singleProcurement =
      material?.procurementDataList?.length === 1 &&
      material.procurementDataList[0]

    formMaterial.materialId = newValue
    formMaterial.procurementId = singleProcurement
      ? singleProcurement.procurementId
      : null
    formMaterial.locationId =
      this.locationSubField && singleProcurement?.deliveryLocationId
        ? singleProcurement.deliveryLocationId
        : null

    this.setMaterialValue(formMaterial, fieldIndex)
  }

  private changeProcurementId(fieldIndex: number, newValue: string) {
    if (!newValue) {
      this.setMaterialValue(null, fieldIndex)
      return
    }

    const formMaterial = copyObject<IFormMaterial>(this.fieldValues[fieldIndex])

    formMaterial.procurementId = newValue

    const material = this.props.materialsStore.list.find(m =>
      m.includesProcurementId(newValue),
    )
    formMaterial.materialId = material?.id

    const procurementData = material?.getProcurementDataById(newValue)
    if (this.locationSubField && procurementData?.deliveryLocationId) {
      formMaterial.locationId = procurementData.deliveryLocationId
    }

    this.setMaterialValue(formMaterial, fieldIndex)
  }

  private changeQuantity(
    fieldIndex: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    this.changeValue(FormMaterialKey.quantity, fieldIndex, event.target.value)
  }

  private changeLocation(fieldIndex: number, location: ISiteLocation) {
    this.changeValue(FormMaterialKey.locationId, fieldIndex, location?.id)
  }

  private changeValue(
    key: FormMaterialKey,
    fieldIndex: number,
    newValue: string,
  ) {
    const formMaterial = copyObject<IFormMaterial>(this.fieldValues[fieldIndex])

    formMaterial[key] = newValue

    this.setMaterialValue(formMaterial, fieldIndex)
  }

  private resetValue(key: FormMaterialKey, fieldIndex: number) {
    const material = copyObject<IFormMaterial>(this.fieldValues[fieldIndex])

    material[key] = null

    this.setMaterialValue(material, fieldIndex)
  }

  private setMaterialValue = (material: IFormMaterial, fieldIndex: number) => {
    const { typeField, fieldsStore } = this.props
    const { changeFieldValue, changeValidationState } = fieldsStore

    changeValidationState(
      typeField.id,
      fieldIndex,
      this.isMaterialValid(material),
    )

    changeFieldValue(typeField, fieldIndex, material)
  }

  private isMaterialValid = (material: IFormMaterial): boolean => {
    const { typeField } = this.props

    return (
      !material ||
      ((!typeField.isMandatory || !!material.materialId) &&
        (!this.isMaterialHasProcurementIds(material?.materialId) ||
          !!material.procurementId) &&
        (!this.quantitySubField?.isMandatory || !!material.quantity) &&
        (!this.locationSubField?.isMandatory || !!material.locationId))
    )
  }

  private isMaterialHasProcurementIds(materialId: string): boolean {
    return this.props.materialsStore.getInstanceById(materialId)
      ?.hasProcurementIds
  }

  private addNewFieldValue = () => {
    const { fieldsStore, typeField } = this.props
    fieldsStore.addNewFieldValue(typeField, { materialId: null })
  }

  private removeValue = (index: number) => {
    const { fieldsStore, typeField } = this.props
    const { rearrangeInvalidState, removeFieldValue } = fieldsStore

    rearrangeInvalidState(typeField.id, index)
    removeFieldValue(typeField.id, index)
  }
}
