import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IClosureAssignmentFieldsFragment = Pick<
  Types.IClosureAssignment,
  'id' | 'projectId' | 'entityId' | 'createdAt' | 'updatedAt'
> & { recipients: Array<Types.Maybe<Pick<Types.IRecipients, 'ids' | 'type'>>> }

export type IGetClosureAssignmentsListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetClosureAssignmentsListQuery = {
  closureAssignments?: Types.Maybe<{
    data: Array<IClosureAssignmentFieldsFragment>
  }>
}

export type ISaveManyClosureAssignmentsMutationVariables = Types.Exact<{
  assignments:
    | Array<Types.Maybe<Types.IClosureAssignmentInput>>
    | Types.Maybe<Types.IClosureAssignmentInput>
}>

export type ISaveManyClosureAssignmentsMutation = {
  saveManyClosureAssignments?: Types.Maybe<
    Array<Types.Maybe<IClosureAssignmentFieldsFragment>>
  >
}

export type IDeleteManyClosureAssignmentsMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteManyClosureAssignmentsMutation = Pick<
  Types.IMutation,
  'deleteManyClosureAssignments'
>

export type IListenToClosureAssignmentsSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToClosureAssignmentsSubscription = {
  closureAssignment?: Types.Maybe<
    Pick<Types.IClosureAssignmentChangeEvent, 'id'> & {
      item?: Types.Maybe<IClosureAssignmentFieldsFragment>
    }
  >
}

export const ClosureAssignmentFieldsFragmentDoc = gql`
  fragment ClosureAssignmentFields on ClosureAssignment {
    id
    projectId
    entityId
    recipients {
      ids
      type
    }
    createdAt
    updatedAt
  }
`
export const GetClosureAssignmentsListDocument = gql`
  query GetClosureAssignmentsList($projectId: ObjectId!) {
    closureAssignments(limit: 1000000, projectId: $projectId) {
      data {
        ...ClosureAssignmentFields
      }
    }
  }
  ${ClosureAssignmentFieldsFragmentDoc}
`

/**
 * __useGetClosureAssignmentsListQuery__
 *
 * To run a query within a React component, call `useGetClosureAssignmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClosureAssignmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClosureAssignmentsListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetClosureAssignmentsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetClosureAssignmentsListQuery,
    IGetClosureAssignmentsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetClosureAssignmentsListQuery,
    IGetClosureAssignmentsListQueryVariables
  >(GetClosureAssignmentsListDocument, options)
}
export function useGetClosureAssignmentsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetClosureAssignmentsListQuery,
    IGetClosureAssignmentsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetClosureAssignmentsListQuery,
    IGetClosureAssignmentsListQueryVariables
  >(GetClosureAssignmentsListDocument, options)
}
export type GetClosureAssignmentsListQueryHookResult = ReturnType<
  typeof useGetClosureAssignmentsListQuery
>
export type GetClosureAssignmentsListLazyQueryHookResult = ReturnType<
  typeof useGetClosureAssignmentsListLazyQuery
>
export type GetClosureAssignmentsListQueryResult = Apollo.QueryResult<
  IGetClosureAssignmentsListQuery,
  IGetClosureAssignmentsListQueryVariables
>
export const SaveManyClosureAssignmentsDocument = gql`
  mutation SaveManyClosureAssignments($assignments: [ClosureAssignmentInput]!) {
    saveManyClosureAssignments(closureAssignments: $assignments) {
      ...ClosureAssignmentFields
    }
  }
  ${ClosureAssignmentFieldsFragmentDoc}
`
export type ISaveManyClosureAssignmentsMutationFn = Apollo.MutationFunction<
  ISaveManyClosureAssignmentsMutation,
  ISaveManyClosureAssignmentsMutationVariables
>

/**
 * __useSaveManyClosureAssignmentsMutation__
 *
 * To run a mutation, you first call `useSaveManyClosureAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyClosureAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyClosureAssignmentsMutation, { data, loading, error }] = useSaveManyClosureAssignmentsMutation({
 *   variables: {
 *      assignments: // value for 'assignments'
 *   },
 * });
 */
export function useSaveManyClosureAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyClosureAssignmentsMutation,
    ISaveManyClosureAssignmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyClosureAssignmentsMutation,
    ISaveManyClosureAssignmentsMutationVariables
  >(SaveManyClosureAssignmentsDocument, options)
}
export type SaveManyClosureAssignmentsMutationHookResult = ReturnType<
  typeof useSaveManyClosureAssignmentsMutation
>
export type SaveManyClosureAssignmentsMutationResult =
  Apollo.MutationResult<ISaveManyClosureAssignmentsMutation>
export type SaveManyClosureAssignmentsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveManyClosureAssignmentsMutation,
    ISaveManyClosureAssignmentsMutationVariables
  >
export const DeleteManyClosureAssignmentsDocument = gql`
  mutation DeleteManyClosureAssignments($ids: [ObjectId]!) {
    deleteManyClosureAssignments(ids: $ids)
  }
`
export type IDeleteManyClosureAssignmentsMutationFn = Apollo.MutationFunction<
  IDeleteManyClosureAssignmentsMutation,
  IDeleteManyClosureAssignmentsMutationVariables
>

/**
 * __useDeleteManyClosureAssignmentsMutation__
 *
 * To run a mutation, you first call `useDeleteManyClosureAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyClosureAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyClosureAssignmentsMutation, { data, loading, error }] = useDeleteManyClosureAssignmentsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteManyClosureAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteManyClosureAssignmentsMutation,
    IDeleteManyClosureAssignmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteManyClosureAssignmentsMutation,
    IDeleteManyClosureAssignmentsMutationVariables
  >(DeleteManyClosureAssignmentsDocument, options)
}
export type DeleteManyClosureAssignmentsMutationHookResult = ReturnType<
  typeof useDeleteManyClosureAssignmentsMutation
>
export type DeleteManyClosureAssignmentsMutationResult =
  Apollo.MutationResult<IDeleteManyClosureAssignmentsMutation>
export type DeleteManyClosureAssignmentsMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteManyClosureAssignmentsMutation,
    IDeleteManyClosureAssignmentsMutationVariables
  >
export const ListenToClosureAssignmentsDocument = gql`
  subscription ListenToClosureAssignments($projectId: ObjectId!) {
    closureAssignment(projectId: $projectId) {
      item {
        ...ClosureAssignmentFields
      }
      id
    }
  }
  ${ClosureAssignmentFieldsFragmentDoc}
`

/**
 * __useListenToClosureAssignmentsSubscription__
 *
 * To run a query within a React component, call `useListenToClosureAssignmentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToClosureAssignmentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToClosureAssignmentsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToClosureAssignmentsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToClosureAssignmentsSubscription,
    IListenToClosureAssignmentsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToClosureAssignmentsSubscription,
    IListenToClosureAssignmentsSubscriptionVariables
  >(ListenToClosureAssignmentsDocument, options)
}
export type ListenToClosureAssignmentsSubscriptionHookResult = ReturnType<
  typeof useListenToClosureAssignmentsSubscription
>
export type ListenToClosureAssignmentsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToClosureAssignmentsSubscription>
