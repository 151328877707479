import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import WeatherHourlyWidget from '~/client/src/shared/components/WeatherHourlyWidget/WeatherHourlyWidget'
import WeatherLabel from '~/client/src/shared/components/WeatherLabel/WeatherLabel'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IDeliveryControl from '~/client/src/shared/models/IDeliveryControl'
import WeatherForecastsStore from '~/client/src/shared/stores/domain/WeatherForecasts.store'

// localization: translated

interface IProps extends IDeliveryControl {
  weatherForecastsStore?: WeatherForecastsStore
  startDate: Date
}

@inject('weatherForecastsStore')
@observer
export default class ForecastField extends React.Component<IProps> {
  @observable private isExpandedMode: boolean = false

  public componentDidMount() {
    const { startDate } = this.props
    if (startDate) {
      this.props.weatherForecastsStore.fetchWeeklyForecast(startDate)
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { startDate } = this.props
    if (startDate !== prevProps.startDate) {
      this.props.weatherForecastsStore.fetchWeeklyForecast(startDate)
    }
  }

  public render() {
    const { startDate } = this.props

    const { getForecastForDay, weatherUnits } = this.props.weatherForecastsStore

    const forecast = startDate ? getForecastForDay(startDate) : null

    return (
      <div className="weather col y-around ba-light-input-border brada4 px16 py3">
        <div className="row">
          <span className="text brand-dark opacity5 uppercase lp15 bold">
            {Localization.translator.forecast}:
          </span>
          {forecast && (
            <div className="col">
              <div
                className="row flex-grow2 pointer"
                onClick={this.toggleExpandedMode}
              >
                <WeatherLabel
                  className="no-flex-children x-around"
                  forecast={forecast}
                  projectWeatherUnits={weatherUnits}
                />
                {this.renderForecastArrow()}
              </div>
            </div>
          )}
        </div>
        {forecast && this.isExpandedMode && (
          <WeatherHourlyWidget startDate={startDate} />
        )}
      </div>
    )
  }

  private renderForecastArrow() {
    return (
      <Icon
        icon={IconNames.CARET_DOWN}
        className={classList({
          'row no-width text light ml8': true,
          rotate180: this.isExpandedMode,
        })}
      />
    )
  }

  @action.bound
  private toggleExpandedMode() {
    this.isExpandedMode = !this.isExpandedMode
  }
}
