import * as React from 'react'

import { Icon, IconSize } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IConcreteDirectPayload } from '~/client/graph'
import { ConcreteDirectPayloadStatusLabel } from '~/client/src/shared/components/ConcreteDirectStatusLabel/ConcreteDirectStatusLabel'
import ConcreteDirectPayloadStatus from '~/client/src/shared/enums/ConcreteDirectPayloadStatus'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ConcreteDirectOrder from '~/client/src/shared/models/ConcreteDirectOrder'
import ProjectDateStore, {
  MILLISECONDS_IN_MINUTE,
  millisecondsToTime,
} from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import PayloadOnSiteTimeLabel from './PayloadOnSiteTimeLabel'

import './ConcreteDirectPayloadTicket.scss'

// localization: translated

interface IProps {
  payload: IConcreteDirectPayload
  payloadNumber: number
  concreteDirectOrder: ConcreteDirectOrder

  className?: string
  isDetailsHidden?: boolean

  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
export default class ConcreteDirectPayloadTicket extends React.Component<IProps> {
  @observable private isDetailedViewShown = false

  public render() {
    const { payload, payloadNumber, isDetailsHidden } = this.props

    if (!payload) {
      return null
    }

    const { payloadEvent, eta, status } = payload

    return (
      <>
        <div className="row text large line-extra-large">
          <div className="py8 pr8 w32 no-flex">{payloadNumber}</div>
          <div className="pa8 w48 no-flex">{payload.quantity}</div>
          <div className="pa8 w48 no-flex">{payload.cumulativeQuantity}</div>
          <div className="pa8 mw100">
            <ConcreteDirectPayloadStatusLabel
              status={payload.status as ConcreteDirectPayloadStatus}
            />
          </div>
          <div className="row x-between y-center py8 pl8">
            <PayloadOnSiteTimeLabel
              payloadEvent={payloadEvent}
              eta={eta}
              status={status}
            />
            {!isDetailsHidden && (
              <Icon
                className="text light pointer no-select"
                size={IconSize.LARGE}
                icon={
                  this.isDetailedViewShown
                    ? IconNames.CHEVRON_DOWN
                    : IconNames.CHEVRON_RIGHT
                }
                onClick={this.toggleDetailedView}
              />
            )}
          </div>
        </div>
        {this.isDetailedViewShown && this.detailedPayloadInfo}
      </>
    )
  }

  private get detailedPayloadInfo(): JSX.Element {
    const { concreteDirectOrder, payload } = this.props
    const { getMixTypeById, getAdditivesById, getExtraChargesById } =
      concreteDirectOrder
    const { payloadEvent, orderItemId } = payload

    const mixType = getMixTypeById(orderItemId)
    const additives = getAdditivesById(orderItemId)
    const extraCharges = getExtraChargesById(orderItemId)

    return (
      <div className="col px16 py4 bt-light-grey cd-payload-ticket-detailed-info">
        <div className="row y-start x-between text header line-24 bold pt10 pb8 bb-light-grey">
          <span className="nowrap no-grow full-width">
            {Localization.translator.ticket}
          </span>
          <span className="ml8 full-width no-grow">
            #{payload.ticketReference}
          </span>
        </div>
        {this.renderTicketInfo(
          Localization.translator.volumeLoaded,
          payload.quantity,
        )}
        {this.renderTicketInfo(
          Localization.translator.totalOrderVolume,
          payload.cumulativeQuantity,
        )}
        {this.renderTicketInfo(Localization.translator.mixType, mixType)}
        {this.renderTicketInfo(Localization.translator.slump, payload.slump)}
        {this.renderTicketInfo(Localization.translator.additive, additives)}
        {this.renderTicketInfo(
          Localization.translator.extraCharge,
          extraCharges,
        )}
        <div className="row y-start x-between text header line-24 bold pt10 pb8 bb-light-grey">
          {Localization.translator.jobTimes}
        </div>
        {this.renderPayloadEventRow(
          Localization.translator.startLoad,
          payloadEvent?.batchStartTime,
          !!payloadEvent?.departureTime,
          true,
        )}
        {this.renderPayloadEventRow(
          Localization.translator.enRoute,
          payloadEvent?.departureTime,
          !!payloadEvent?.arrivalTime,
        )}
        {this.renderPayloadEventRow(
          Localization.translator.onSite,
          payloadEvent?.arrivalTime,
          !!payloadEvent?.pouringStartTime,
        )}
        {this.renderPayloadEventRow(
          Localization.translator.beginPouring,
          payloadEvent?.pouringStartTime,
          !!payloadEvent?.washTime,
        )}
        {this.renderPayloadEventRow(
          Localization.translator.washing,
          payloadEvent?.washTime,
          false,
          false,
          true,
        )}

        <div className="row y-center x-between h40">
          <span className="nowrap no-grow text bold large grey-light line-extra-large full-width">
            {Localization.translator.totalTimeOnSite}
          </span>
          <span className="ml8 text end bold extra-large line-extra-large full-width">
            {payload.timeOnSite
              ? millisecondsToTime(payload.timeOnSite * MILLISECONDS_IN_MINUTE)
              : NO_VALUE}
          </span>
        </div>
      </div>
    )
  }

  private renderTicketInfo = (
    label: string,
    value: React.ReactText,
  ): JSX.Element => {
    return (
      <div className="row y-start x-between py6">
        <span className="nowrap no-grow text bold large grey-light line-extra-large full-width">
          {label}
        </span>
        <span className="word-break-all ml8 text end capitalize bold extra-large line-extra-large full-width">
          {value || NO_VALUE}
        </span>
      </div>
    )
  }

  private renderPayloadEventRow(
    label: string,
    value?: number | Date,
    isNextFilled?: boolean,
    shouldSkipTopLvl?: boolean,
    shouldSkipBottomLvl?: boolean,
  ): JSX.Element {
    const { getTimeToDisplay } = this.props.projectDateStore

    return (
      <div className="row y-center x-between h40">
        <div className="col x-center y-center no-grow pr15 full-height relative">
          {!shouldSkipTopLvl && (
            <div
              className={classList({
                'absolute job-times-indicator-top': true,
                filled: !!value,
              })}
            />
          )}
          <div
            className={classList({
              'job-times-indicator-center': true,
              filled: !!value,
            })}
          />
          {!shouldSkipBottomLvl && (
            <div
              className={classList({
                'absolute job-times-indicator-bottom': true,
                filled: isNextFilled,
              })}
            />
          )}
        </div>
        <span className="nowrap no-grow text bold large grey-light line-extra-large full-width">
          {label}
        </span>
        <span className="ml8 text end bold extra-large line-extra-large full-width">
          {(value && getTimeToDisplay(value)) || NO_VALUE}
        </span>
      </div>
    )
  }

  @action.bound
  private toggleDetailedView() {
    this.isDetailedViewShown = !this.isDetailedViewShown
  }
}
