import { action, computed, observable } from 'mobx'

import { ITag } from '~/client/src/shared/models/Tag'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import Colors from '~/client/src/shared/theme.module.scss'

export default class TagCellEditorStore {
  @observable public tag: ITag = null
  @observable public draftInputValue: string = EMPTY_STRING
  @observable public selectedColor: string = Colors.neutral0

  public constructor(tag: ITag, private readonly onSave: (tag: ITag) => void) {
    this.init(tag)
  }

  @action.bound
  public init(tag: ITag) {
    this.tag = tag
    this.changeColor(tag.color)
    this.changeDraftInputValue(tag.name)
  }

  @action.bound
  public changeDraftInputValue(value: string) {
    this.draftInputValue = value || EMPTY_STRING
  }

  @action.bound
  public changeColor(newColor: string) {
    this.selectedColor = newColor || Colors.neutral0
  }

  @action.bound
  public saveTag() {
    if (!this.draftInputValue || !this.selectedColor) {
      return
    }

    if (
      this.tag.name !== this.draftInputValue ||
      this.tag.color !== this.selectedColor
    ) {
      this.tag.name = this.draftInputValue
      this.tag.color = this.selectedColor

      this.onSave(this.tag)
    }
  }

  @computed
  public get tagColorObjs() {
    const { iconName } = this.tag

    return [
      {
        title: 'Grey (default)',
        color: Colors.neutral0,
        iconName,
      },
      {
        title: 'Slate grey',
        color: Colors.slate50,
        iconName,
      },
      {
        title: 'Purple',
        color: Colors.purple40,
        iconName,
      },
      {
        title: 'Orange',
        color: Colors.secondary40,
        iconName,
      },
      {
        title: 'Green',
        color: Colors.success40,
        iconName,
      },
      {
        title: 'Yellow',
        color: Colors.warning40,
        iconName,
      },
      {
        title: 'Blue',
        color: Colors.primary60,
        iconName,
      },
      {
        title: 'Red',
        color: Colors.error70,
        iconName,
      },
      {
        title: 'Pink',
        color: Colors.magenta50,
        iconName,
      },
    ]
  }
}
