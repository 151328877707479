import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import WorkflowDiagramChangeLine from '../../WorkflowDiagramWrapper/components/WorkflowDiagramChangeLine'
import WorkflowDiagramLine, {
  DiagramLineType,
} from '../../WorkflowDiagramWrapper/components/WorkflowDiagramLine'
import {
  ActionState,
  IntermediateState,
  TerminationState,
} from '../../WorkflowDiagramWrapper/components/WorkflowDiagramState'
import BaseStepDiagram, { IBaseStepDiagramProps } from './BaseStepDiagram'

// localization: translated

const CHANGE_LINE_WIDTH = 35
const CHANGE_LINE_RIGHT_OFFSET = 0

@observer
export default class RequestStepDiagram extends React.Component<IBaseStepDiagramProps> {
  public render() {
    const { isLastStep, intermediateStateText, ...restProps } = this.props

    return (
      <BaseStepDiagram
        {...restProps}
        isLastStep={isLastStep}
        intermediateStateText={
          intermediateStateText || Localization.translator.requested
        }
      >
        <TerminationState content={Localization.translator.newRequest} />
        <WorkflowDiagramLine />

        <ActionState content={Localization.translator.submit_verb} />
        <WorkflowDiagramLine shouldIncludeArrow />

        {!isLastStep && (
          <>
            <div className="col x-start">
              <div className="col absolute x-center changed-block">
                <div className="row y-center x-end">
                  <IntermediateState
                    content={Localization.translator.changed}
                  />
                  <Icon
                    className="absolute arrow-left"
                    icon={IconNames.CARET_RIGHT}
                  />
                </div>
                <WorkflowDiagramLine
                  diagramLineType={DiagramLineType.VerticalLong}
                />
              </div>
            </div>

            <WorkflowDiagramChangeLine
              width={CHANGE_LINE_WIDTH}
              rightOffset={CHANGE_LINE_RIGHT_OFFSET}
            />
          </>
        )}
      </BaseStepDiagram>
    )
  }
}
