import * as React from 'react'

import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'

import InitialState from '../../stores/InitialState'
import BaseActionButton from '../BaseActionButton/BaseActionButton'
import MenuCloser from '../MenuCloser'
import PermitTypeIcon from '../PermitTypeIcon/PermitTypeIcon'

interface IProps {
  openAnnouncementCreationForm?: () => void
  openPermitCreationForm?: () => void
  permitTypesStore?: PermitTypesStore
  state?: InitialState

  isPermitOnlyMode?: boolean
  className?: string
}
const newPermitRequest = (type: string) => `New ${type} request`
const newAnnouncement = 'New announcement'
const createNew = 'Create new'

@inject('permitTypesStore', 'state')
@observer
export default class LogisticsCreationListMenu extends React.Component<IProps> {
  @observable private shouldShowCreateOptionsMenu: boolean = false

  public render() {
    const { className } = this.props

    return (
      <MenuCloser
        className="logistics-creation-options-container"
        closeMenu={this.hideCreateOptionsMenu}
      >
        <div
          className={classList({
            'absolute create-new-button-container brada10 row': true,
            [className]: !!className,
          })}
        >
          {this.renderCreateNewButton()}
        </div>
        <div
          className={classList({
            'absolute create-options-container nowrap': true,
            [className]: !!className,
          })}
        >
          {this.renderListMenu()}
        </div>
      </MenuCloser>
    )
  }

  private renderListMenu(): JSX.Element {
    if (!this.shouldShowCreateOptionsMenu) {
      return
    }

    const { permitTypesStore, isPermitOnlyMode } = this.props

    return (
      <div className="ba-light-grey bg-white brada4 creation-modal-popup ma-auto scrollable">
        {!isPermitOnlyMode && this.isAdmin && (
          <div
            className="create-option extra-large px20 py10 row text y-center"
            onClick={this.openAnnouncementCreationForm}
          >
            <Icons.Megaphone className="no-grow pr8" />
            {newAnnouncement}
          </div>
        )}

        {permitTypesStore.enabledTypes.map(type => {
          const permitRequestTitle = newPermitRequest(type.name)
          return (
            <div
              key={type.id}
              title={permitRequestTitle}
              className="create-option extra-large px20 py10 row text y-center"
              onClick={this.openSpecificPermitCreationForm.bind(this, type.id)}
            >
              <PermitTypeIcon
                permitType={type?.type}
                className="row no-grow pr8"
              />
              <span className="text-ellipsis">{permitRequestTitle}</span>
            </div>
          )
        })}
      </div>
    )
  }

  private renderCreateNewButton(): JSX.Element {
    return (
      <BaseActionButton
        className="create-new-button row"
        isEnabled={true}
        title={createNew}
        icon={<Icons.Plus className="row" />}
        onClick={this.toggleCreateOptionsMenu}
      />
    )
  }

  private openSpecificPermitCreationForm = (permitTypeId: string) => {
    const { state, openPermitCreationForm } = this.props

    state.preSelectedPermitTypeId = permitTypeId

    this.hideCreateOptionsMenu()

    openPermitCreationForm?.()
  }

  private get isAdmin(): boolean {
    return this.props.state.userActiveProjectSettings?.isAdmin
  }

  private openAnnouncementCreationForm = () => {
    if (!this.isAdmin) {
      return
    }

    this.hideCreateOptionsMenu()

    this.props.openAnnouncementCreationForm?.()
  }

  private toggleCreateOptionsMenu = () => {
    this.shouldShowCreateOptionsMenu = !this.shouldShowCreateOptionsMenu
  }

  private hideCreateOptionsMenu = () => {
    this.shouldShowCreateOptionsMenu = false
  }
}
