import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'

import { NOOP } from '../../utils/noop'

interface IActionBarInput {
  value: string
  onChange: (evt: any) => any
  placeholder?: string
  className?: string
  onKeyPress?: (evt: React.KeyboardEvent<HTMLTextAreaElement>) => void
}

@observer
export default class ActionBarInput extends React.Component<IActionBarInput> {
  public static defaultProps = {
    className: 'slack-bar-input mb10 no-resize full-width',
    onKeyPress: NOOP,
  }
  @observable private isFocused: boolean = false

  public textarea: HTMLInputElement

  public render() {
    const { value, placeholder, onChange, onKeyPress } = this.props

    return (
      <textarea
        rows={1}
        onFocus={() => (this.isFocused = true)}
        onBlur={() => (this.isFocused = false)}
        ref={this.setInputRef}
        className={this.props.className}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        onKeyDown={this.autoResize}
        onKeyPress={onKeyPress}
      />
    )
  }

  public componentDidUpdate() {
    const { value } = this.props

    if (!value) {
      this.textarea.style.height = 'auto'
    }
  }

  private autoResize = ({ target }) => {
    target.style.height = 'auto'
    target.style.height = `${target.scrollHeight}px`
  }

  @action.bound
  private setInputRef(ref) {
    this.textarea = ref
  }
}
