import EventsStore from './stores/EventStore/Events.store'
import {
  LISTEN_TO_DEPLOYMENT_VERSION,
  REPORT_ERROR,
} from './stores/EventStore/eventConstants'

export default class CommonContainer {
  protected eventsStore: EventsStore

  protected registerGlobalEventListeners() {
    window.addEventListener('error', (event: ErrorEvent) => {
      this.eventsStore.dispatch(REPORT_ERROR, 'uncaught', event.error, 'error')
    })

    window.addEventListener(
      'unhandledrejection',
      (event: PromiseRejectionEvent) => {
        if (event.reason instanceof Error) {
          this.eventsStore.dispatch(
            REPORT_ERROR,
            'uncaught',
            event.reason,
            'error',
          )
        }

        if (typeof event.reason === 'string') {
          this.eventsStore.dispatch(
            REPORT_ERROR,
            'uncaught',
            new Error(event.reason),
            'error',
          )
        }
      },
    )

    window.addEventListener('online', this.listenToDeploymentVersion)

    document.addEventListener('resume', this.listenToDeploymentVersion)

    document.addEventListener('visibilitychange', () => {
      if (!window.RT) {
        setTimeout(this.listenToDeploymentVersion, 1000)
      }
    })
  }

  private listenToDeploymentVersion = () => {
    if (!window.RT) {
      this.eventsStore.dispatch(LISTEN_TO_DEPLOYMENT_VERSION)
    }
  }
}
