import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IStatusUpdateFieldsFragment = Pick<
  Types.IStatusUpdate,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'dateFor'
  | 'projectId'
  | 'activityP6Code'
  | 'actualFinishDate'
  | 'actualStartDate'
  | 'authorId'
  | 'threadId'
  | 'companyName'
  | 'isDeleted'
  | 'manpower'
  | 'manpowerUpdatedAt'
  | 'percentComplete'
  | 'percentCompleteUpdatedAt'
  | 'plannedPercentComplete'
  | 'type'
  | 'isInherited'
  | 'updateVersion'
> & {
  companies?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<
          Types.ICompanyStatusUpdate,
          | 'companyName'
          | 'didUpdate'
          | 'isDeleted'
          | 'manpower'
          | 'manpowerUpdatedAt'
          | 'percentComplete'
          | 'percentCompleteUpdatedAt'
          | 'updatedAt'
          | 'userId'
        >
      >
    >
  >
}

export type IGetStatusUpdatesQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  activityP6Codes:
    | Array<Types.Maybe<Types.Scalars['String']>>
    | Types.Maybe<Types.Scalars['String']>
}>

export type IGetStatusUpdatesQuery = {
  statusUpdates?: Types.Maybe<{ data: Array<IStatusUpdateFieldsFragment> }>
}

export type ISaveStatusUpdateMutationVariables = Types.Exact<{
  statusUpdate: Types.IStatusUpdateInput
}>

export type ISaveStatusUpdateMutation = {
  saveStatusUpdate?: Types.Maybe<IStatusUpdateFieldsFragment>
}

export type IUpdateStatusUpdatesMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  userId: Types.Scalars['ObjectId']
  activityCode: Types.Scalars['String']
  dates:
    | Array<Types.Maybe<Types.IStatusUpdateMultiCompaniesInput>>
    | Types.Maybe<Types.IStatusUpdateMultiCompaniesInput>
  timezoneId: Types.Scalars['String']
}>

export type IUpdateStatusUpdatesMutation = Pick<
  Types.IMutation,
  'updateStatusUpdatesMultiDates'
>

export type IUpdateStatusUpdatesForMissedDatesMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  userId: Types.Scalars['ObjectId']
  activityCode: Types.Scalars['String']
  company?: Types.Maybe<Types.Scalars['String']>
  updates:
    | Array<Types.Maybe<Types.IStatusUpdateDateInput>>
    | Types.Maybe<Types.IStatusUpdateDateInput>
}>

export type IUpdateStatusUpdatesForMissedDatesMutation = Pick<
  Types.IMutation,
  'updateStatusUpdatesMissedStatuses'
>

export type IDeleteStatusUpdatesMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
  userId: Types.Scalars['ObjectId']
  activityCode: Types.Scalars['String']
  company?: Types.Maybe<Types.Scalars['String']>
  unixTime: Types.Scalars['MillisecondsDate']
  timezoneId: Types.Scalars['String']
}>

export type IDeleteStatusUpdatesMutation = Pick<
  Types.IMutation,
  'softDeleteManyStatusUpdates'
>

export type IListenToStatusUpdateSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToStatusUpdateSubscription = {
  statusUpdate?: Types.Maybe<
    Pick<Types.IStatusUpdateChangeEvent, 'id'> & {
      item?: Types.Maybe<IStatusUpdateFieldsFragment>
    }
  >
}

export const StatusUpdateFieldsFragmentDoc = gql`
  fragment StatusUpdateFields on StatusUpdate {
    id
    createdAt
    updatedAt
    dateFor
    projectId
    activityP6Code
    actualFinishDate
    actualStartDate
    authorId
    threadId
    companies {
      companyName
      didUpdate
      isDeleted
      manpower
      manpowerUpdatedAt
      percentComplete
      percentCompleteUpdatedAt
      updatedAt
      userId
    }
    companyName
    isDeleted
    manpower
    manpowerUpdatedAt
    percentComplete
    percentCompleteUpdatedAt
    plannedPercentComplete
    type
    isInherited
    updateVersion
  }
`
export const GetStatusUpdatesDocument = gql`
  query GetStatusUpdates($projectId: ObjectId!, $activityP6Codes: [String]!) {
    statusUpdates(
      projectId: $projectId
      activityP6Codes: $activityP6Codes
      limit: 27000
    ) {
      data {
        ...StatusUpdateFields
      }
    }
  }
  ${StatusUpdateFieldsFragmentDoc}
`

/**
 * __useGetStatusUpdatesQuery__
 *
 * To run a query within a React component, call `useGetStatusUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusUpdatesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      activityP6Codes: // value for 'activityP6Codes'
 *   },
 * });
 */
export function useGetStatusUpdatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetStatusUpdatesQuery,
    IGetStatusUpdatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetStatusUpdatesQuery,
    IGetStatusUpdatesQueryVariables
  >(GetStatusUpdatesDocument, options)
}
export function useGetStatusUpdatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetStatusUpdatesQuery,
    IGetStatusUpdatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetStatusUpdatesQuery,
    IGetStatusUpdatesQueryVariables
  >(GetStatusUpdatesDocument, options)
}
export type GetStatusUpdatesQueryHookResult = ReturnType<
  typeof useGetStatusUpdatesQuery
>
export type GetStatusUpdatesLazyQueryHookResult = ReturnType<
  typeof useGetStatusUpdatesLazyQuery
>
export type GetStatusUpdatesQueryResult = Apollo.QueryResult<
  IGetStatusUpdatesQuery,
  IGetStatusUpdatesQueryVariables
>
export const SaveStatusUpdateDocument = gql`
  mutation SaveStatusUpdate($statusUpdate: StatusUpdateInput!) {
    saveStatusUpdate(statusUpdate: $statusUpdate) {
      ...StatusUpdateFields
    }
  }
  ${StatusUpdateFieldsFragmentDoc}
`
export type ISaveStatusUpdateMutationFn = Apollo.MutationFunction<
  ISaveStatusUpdateMutation,
  ISaveStatusUpdateMutationVariables
>

/**
 * __useSaveStatusUpdateMutation__
 *
 * To run a mutation, you first call `useSaveStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveStatusUpdateMutation, { data, loading, error }] = useSaveStatusUpdateMutation({
 *   variables: {
 *      statusUpdate: // value for 'statusUpdate'
 *   },
 * });
 */
export function useSaveStatusUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveStatusUpdateMutation,
    ISaveStatusUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveStatusUpdateMutation,
    ISaveStatusUpdateMutationVariables
  >(SaveStatusUpdateDocument, options)
}
export type SaveStatusUpdateMutationHookResult = ReturnType<
  typeof useSaveStatusUpdateMutation
>
export type SaveStatusUpdateMutationResult =
  Apollo.MutationResult<ISaveStatusUpdateMutation>
export type SaveStatusUpdateMutationOptions = Apollo.BaseMutationOptions<
  ISaveStatusUpdateMutation,
  ISaveStatusUpdateMutationVariables
>
export const UpdateStatusUpdatesDocument = gql`
  mutation UpdateStatusUpdates(
    $projectId: ObjectId!
    $userId: ObjectId!
    $activityCode: String!
    $dates: [StatusUpdateMultiCompaniesInput]!
    $timezoneId: String!
  ) {
    updateStatusUpdatesMultiDates(
      projectId: $projectId
      userId: $userId
      activityCode: $activityCode
      dates: $dates
      timezoneId: $timezoneId
    )
  }
`
export type IUpdateStatusUpdatesMutationFn = Apollo.MutationFunction<
  IUpdateStatusUpdatesMutation,
  IUpdateStatusUpdatesMutationVariables
>

/**
 * __useUpdateStatusUpdatesMutation__
 *
 * To run a mutation, you first call `useUpdateStatusUpdatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatusUpdatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatusUpdatesMutation, { data, loading, error }] = useUpdateStatusUpdatesMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *      activityCode: // value for 'activityCode'
 *      dates: // value for 'dates'
 *      timezoneId: // value for 'timezoneId'
 *   },
 * });
 */
export function useUpdateStatusUpdatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateStatusUpdatesMutation,
    IUpdateStatusUpdatesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateStatusUpdatesMutation,
    IUpdateStatusUpdatesMutationVariables
  >(UpdateStatusUpdatesDocument, options)
}
export type UpdateStatusUpdatesMutationHookResult = ReturnType<
  typeof useUpdateStatusUpdatesMutation
>
export type UpdateStatusUpdatesMutationResult =
  Apollo.MutationResult<IUpdateStatusUpdatesMutation>
export type UpdateStatusUpdatesMutationOptions = Apollo.BaseMutationOptions<
  IUpdateStatusUpdatesMutation,
  IUpdateStatusUpdatesMutationVariables
>
export const UpdateStatusUpdatesForMissedDatesDocument = gql`
  mutation UpdateStatusUpdatesForMissedDates(
    $projectId: ObjectId!
    $userId: ObjectId!
    $activityCode: String!
    $company: String
    $updates: [StatusUpdateDateInput]!
  ) {
    updateStatusUpdatesMissedStatuses(
      projectId: $projectId
      userId: $userId
      activityCode: $activityCode
      company: $company
      updates: $updates
    )
  }
`
export type IUpdateStatusUpdatesForMissedDatesMutationFn =
  Apollo.MutationFunction<
    IUpdateStatusUpdatesForMissedDatesMutation,
    IUpdateStatusUpdatesForMissedDatesMutationVariables
  >

/**
 * __useUpdateStatusUpdatesForMissedDatesMutation__
 *
 * To run a mutation, you first call `useUpdateStatusUpdatesForMissedDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatusUpdatesForMissedDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatusUpdatesForMissedDatesMutation, { data, loading, error }] = useUpdateStatusUpdatesForMissedDatesMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *      activityCode: // value for 'activityCode'
 *      company: // value for 'company'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateStatusUpdatesForMissedDatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateStatusUpdatesForMissedDatesMutation,
    IUpdateStatusUpdatesForMissedDatesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateStatusUpdatesForMissedDatesMutation,
    IUpdateStatusUpdatesForMissedDatesMutationVariables
  >(UpdateStatusUpdatesForMissedDatesDocument, options)
}
export type UpdateStatusUpdatesForMissedDatesMutationHookResult = ReturnType<
  typeof useUpdateStatusUpdatesForMissedDatesMutation
>
export type UpdateStatusUpdatesForMissedDatesMutationResult =
  Apollo.MutationResult<IUpdateStatusUpdatesForMissedDatesMutation>
export type UpdateStatusUpdatesForMissedDatesMutationOptions =
  Apollo.BaseMutationOptions<
    IUpdateStatusUpdatesForMissedDatesMutation,
    IUpdateStatusUpdatesForMissedDatesMutationVariables
  >
export const DeleteStatusUpdatesDocument = gql`
  mutation DeleteStatusUpdates(
    $projectId: ObjectId!
    $userId: ObjectId!
    $activityCode: String!
    $company: String
    $unixTime: MillisecondsDate!
    $timezoneId: String!
  ) {
    softDeleteManyStatusUpdates(
      projectId: $projectId
      userId: $userId
      activityCode: $activityCode
      company: $company
      unixTime: $unixTime
      timezoneId: $timezoneId
    )
  }
`
export type IDeleteStatusUpdatesMutationFn = Apollo.MutationFunction<
  IDeleteStatusUpdatesMutation,
  IDeleteStatusUpdatesMutationVariables
>

/**
 * __useDeleteStatusUpdatesMutation__
 *
 * To run a mutation, you first call `useDeleteStatusUpdatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStatusUpdatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStatusUpdatesMutation, { data, loading, error }] = useDeleteStatusUpdatesMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *      activityCode: // value for 'activityCode'
 *      company: // value for 'company'
 *      unixTime: // value for 'unixTime'
 *      timezoneId: // value for 'timezoneId'
 *   },
 * });
 */
export function useDeleteStatusUpdatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteStatusUpdatesMutation,
    IDeleteStatusUpdatesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteStatusUpdatesMutation,
    IDeleteStatusUpdatesMutationVariables
  >(DeleteStatusUpdatesDocument, options)
}
export type DeleteStatusUpdatesMutationHookResult = ReturnType<
  typeof useDeleteStatusUpdatesMutation
>
export type DeleteStatusUpdatesMutationResult =
  Apollo.MutationResult<IDeleteStatusUpdatesMutation>
export type DeleteStatusUpdatesMutationOptions = Apollo.BaseMutationOptions<
  IDeleteStatusUpdatesMutation,
  IDeleteStatusUpdatesMutationVariables
>
export const ListenToStatusUpdateDocument = gql`
  subscription ListenToStatusUpdate($projectId: ObjectId!) {
    statusUpdate(projectId: $projectId) {
      id
      item {
        ...StatusUpdateFields
      }
    }
  }
  ${StatusUpdateFieldsFragmentDoc}
`

/**
 * __useListenToStatusUpdateSubscription__
 *
 * To run a query within a React component, call `useListenToStatusUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToStatusUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToStatusUpdateSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToStatusUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToStatusUpdateSubscription,
    IListenToStatusUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToStatusUpdateSubscription,
    IListenToStatusUpdateSubscriptionVariables
  >(ListenToStatusUpdateDocument, options)
}
export type ListenToStatusUpdateSubscriptionHookResult = ReturnType<
  typeof useListenToStatusUpdateSubscription
>
export type ListenToStatusUpdateSubscriptionResult =
  Apollo.SubscriptionResult<IListenToStatusUpdateSubscription>
