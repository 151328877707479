import { action } from 'mobx'

import { ITeamInput } from '~/client/graph'
import { TagIconType } from '~/client/src/shared/enums/TagIcon'
import { TagType } from '~/client/src/shared/enums/TagType'
import BaseTagsStore from '~/client/src/shared/stores/BaseTags.store'

import { ITag } from '../../models/Tag'
import {
  DELETE_PROJECT_TEAM,
  SAVE_PROJECT_TEAM,
} from '../EventStore/eventConstants'

export default class ProjectTeamsStore extends BaseTagsStore {
  protected readonly iconName: TagIconType = TagIconType.Team
  protected readonly tagType: TagType = TagType.Team

  public get byId(): Map<string, ITag> {
    return this.eventsStore.appState.projectTeams
  }

  @action.bound
  public save(tag: ITag, callback?: (id: string) => void) {
    const team: ITeamInput = {
      id: tag.id,
      name: tag.name,
      color: tag.color,
      projectId: this.eventsStore.appState.activeProject.id,
    }

    this.eventsStore.dispatch(SAVE_PROJECT_TEAM, team, callback)
  }

  @action.bound
  public delete(teamId: string, callback?: () => void) {
    this.eventsStore.dispatch(DELETE_PROJECT_TEAM, teamId, callback)
  }
}
