import Localization from '../../shared/localization/LocalizationManager'

enum DeliveryActionTypes {
  ASSIGN = 'ASSIGN',
  SUBSCRIBE = 'SUBSCRIBE',
  UPDATE = 'UPDATE',
  EDIT = 'EDIT',
  DUPLICATE = 'DUPLICATE',
  NEW = 'NEW',
  VIEW = 'VIEW',
}

export const getDeliveryActionTypeDisplayName = (type: DeliveryActionTypes) => {
  switch (type) {
    case DeliveryActionTypes.ASSIGN:
      return Localization.translator.assign_verb
    case DeliveryActionTypes.SUBSCRIBE:
      return Localization.translator.subscribe_verb
    case DeliveryActionTypes.UPDATE:
      return Localization.translator.update_verb
    case DeliveryActionTypes.EDIT:
      return Localization.translator.edit_verb
    case DeliveryActionTypes.DUPLICATE:
      return Localization.translator.duplicate_verb
    case DeliveryActionTypes.NEW:
      return Localization.translator.new
    case DeliveryActionTypes.VIEW:
      return Localization.translator.view_verb
    default:
      throw new Error(type + ' is unhandled')
  }
}

export default DeliveryActionTypes
