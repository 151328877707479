import { action, computed, observable } from 'mobx'

import {
  IMaterialProcurementData,
  IProjectMaterial,
  IProjectMaterialInput,
  ISheetDataInput,
  IUploadMaterialsExcelInput,
} from '~/client/graph'
import {
  ISaveMaterialMutation,
  RestoreMaterialsDocument,
  UploadMaterialsExcelDocument,
} from '~/client/graph/operations/generated/Materials.generated'
import Material from '~/client/src/shared/models/Material'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'

import { MaterialStatus } from '../../enums/MaterialStatus'
import { IMaterialViewModel } from '../../models/IMaterialViewModel'
import SitePermit from '../../models/Permit'
import InitialState from '../InitialState'
import GraphExecutorStore from './GraphExecutor.store'
import LocationAttributesStore from './LocationAttributes.store'
import MaterialCategoriesStore from './MaterialCategories.store'
import PermitTypesStore from './PermitTypes.store'
import SitePermitsStore from './SitePermits.store'

export default class MaterialsStore {
  @observable public isDataReceived = false
  @observable public isRestoring = false
  private readonly materialsMap = observable(new Map<string, Material>())

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly graphExecutorStore: GraphExecutorStore,
    private readonly deliveriesStore: DeliveriesStore,
    private readonly materialCategoryStore: MaterialCategoriesStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly permitsStore: SitePermitsStore,
    private readonly permitTypesStore: PermitTypesStore,
  ) {}

  @computed
  public get list(): Material[] {
    return this.allMaterials.filter(m => !m.isFromConcreteDirect)
  }

  @computed
  public get allMaterials(): Material[] {
    return Array.from(this.materialsMap.values())
  }

  @computed
  public get viewModels(): IMaterialViewModel[] {
    return this.list.reduce((list, m) => {
      if (!m.categoryId) {
        return list
      }
      const procurementDataList = this.getProcurementList(m)

      if (!procurementDataList.length) {
        list.push({
          id: m.id,
          material: m,
          status: m.status,
          relatedDeliveries: m.relatedDeliveries,
          transferForms: this.getTransferForms(m.id),
          description: m.description,
          quantity: m.plannedQuantity,
        })
        return list
      }

      procurementDataList.forEach(data => {
        const installLocation = this.locationAttributesStore.getById(
          data.installLocationId,
        )

        if (!data.procurementId && !installLocation) {
          return
        }

        const relatedDeliveries = m.relatedDeliveries.filter(delivery =>
          delivery.hasMaterialByProcData(
            data.procurementId,
            installLocation?.id,
            procurementDataList,
          ),
        )

        list.push({
          id: data.procurementId
            ? m.id + data.procurementId
            : m.id + installLocation.id,
          material: m,
          status: relatedDeliveries[0]
            ? relatedDeliveries[0].status
            : MaterialStatus.IN_FABRICATION,
          relatedDeliveries,
          transferForms: this.getTransferForms(m.id, data.procurementId),
          procurementId: data.procurementId,
          installLocation,
          deliveryLocation: this.locationAttributesStore.getById(
            data.deliveryLocationId,
          ),
          description: data.description,
          quantity: data.plannedQuantity,
        })
      })
      return list
    }, [] as IMaterialViewModel[])
  }

  @computed
  public get hasProcurementIds(): boolean {
    return this.viewModels.some(vm => vm.procurementId)
  }

  @computed
  public get selectedMaterials(): Material[] {
    const { projectMaterialOptions } = this.state
    if (!projectMaterialOptions) {
      return []
    }

    const { hiddenSubCategoryIds } = projectMaterialOptions
    return this.list.filter(m => !hiddenSubCategoryIds.includes(m.id))
  }

  public async createMaterialIfNotExists(
    materialId: string,
    categoryId: string,
  ): Promise<IProjectMaterial> {
    const existingMaterial =
      categoryId && materialId
        ? this.materialsMap.get(materialId)
        : this.list.find(
            m => (categoryId || '') === (m.categoryId || '') && !m.productName,
          )

    if (existingMaterial) {
      return Promise.resolve(existingMaterial)
    }

    const { id, materialsUploadId } = this.state.activeProject

    return this.save({
      categoryId,
      productName: '',
      projectId: id,
      materialsUploadId: materialsUploadId,
    })
  }

  @action.bound
  public clearList() {
    this.materialsMap.clear()
  }

  public getByIds = (materialIds: string[] = []): Material[] => {
    return materialIds
      .map(materialId => this.materialsMap.get(materialId))
      .filter(m => !!m)
  }

  @action.bound
  public receiveList(list: IProjectMaterial[]) {
    this.clearList()

    list.forEach(dto => {
      this.materialsMap.set(dto.id, Material.fromDto(dto, this.deliveriesStore))
    })
    this.isDataReceived = true
  }

  @action.bound
  public receiveOne(id: string, dto: IProjectMaterial) {
    if (dto) {
      this.materialsMap.set(id, Material.fromDto(dto, this.deliveriesStore))
    } else {
      this.materialsMap.delete(id)
    }
  }

  public async save(
    {
      categoryId,
      productName,
      projectId,
      materialsUploadId,
      description,
      plannedQuantity,
      procurementDataList,
      id,
    }: IProjectMaterial | Material,
    onSuccessCb?: () => void,
    onErrorCb?: () => void,
  ): Promise<IProjectMaterial> {
    const materialInput: IProjectMaterialInput = {
      id: id || null,
      categoryId: categoryId || null,
      projectId,
      materialsUploadId,
      description,
      plannedQuantity,
      productName,
      procurementDataList,
    }
    return new Promise((resolve, reject) => {
      this.eventsStore.dispatch(
        e.SAVE_MATERIAL,
        materialInput,
        (result: ISaveMaterialMutation) => {
          resolve(result?.saveProjectMaterial)
          onSuccessCb?.()
        },
        () => {
          reject()
          onErrorCb?.()
        },
      )
    })
  }

  public hasInstanceById = (materialId: string): boolean => {
    return this.materialsMap.has(materialId)
  }

  public getInstanceById = (materialId: string): Material => {
    if (this.hasInstanceById(materialId)) {
      return this.materialsMap.get(materialId)
    }
  }

  public getProductNameById = (materialId: string): string => {
    return this.getInstanceById(materialId)?.productName
  }

  public async uploadMaterialsExcel(
    fullFilePath: string,
    fileName: string,
    sheetData: ISheetDataInput,
    shouldMergeWithExistingData: boolean,
  ) {
    const { activeProject, user } = this.state

    const data: IUploadMaterialsExcelInput = {
      fullFilePath,
      fileName,
      sheetData,
      shouldMergeWithExistingData,

      projectId: activeProject.id,
      userId: user.id,
    }

    return await this.graphExecutorStore.mutate(UploadMaterialsExcelDocument, {
      data,
    })
  }

  @action.bound
  public async restoreMaterials(materialsUploadId: string): Promise<boolean> {
    this.isRestoring = true

    const mutationResult = await this.graphExecutorStore.mutate(
      RestoreMaterialsDocument,
      {
        projectId: this.state.activeProject.id,
        materialsUploadId,
      },
    )

    this.isRestoring = false

    return mutationResult?.data?.restoreMaterials
  }

  private getProcurementList = (
    material: Material,
  ): IMaterialProcurementData[] => {
    if (material.procurementDataList?.length) {
      return material.procurementDataList
    }
    return (
      this.materialCategoryStore.getInstanceById(material.categoryId)
        ?.procurementDataList || []
    )
  }

  private get state(): InitialState {
    return this.eventsStore.appState
  }

  private getTransferForms = (
    materialId: string,
    procurementId?: string,
  ): SitePermit[] => {
    return this.permitsStore.list.filter(
      permit =>
        !permit.isDenied &&
        this.permitTypesStore.getPermitTypeById(permit.typeId)
          ?.isMaterialTransfer &&
        !!permit.getMaterialsByIds(materialId, procurementId).length,
    )
  }
}
