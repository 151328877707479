import { action, computed, observable } from 'mobx'

import {
  IChecklistItem,
  IPermitTypeChecklist,
  IPermitTypeField,
} from '~/client/graph'
import QuestionnaireType from '~/client/src/shared/enums/QuestionnaireType'
import { InstructionListType } from '~/client/src/shared/models/PermitType'
import BsonObjectId from '~/client/src/shared/utils/BsonObjectId'
import { getQuestionnaireTypeToDisplayName } from '~/client/src/shared/utils/PermitInstructionsHelper'
import { copyObjectArray } from '~/client/src/shared/utils/util'

import BaseInstructionsConfiguratorStore from '../BaseInstructionsConfigurator/BaseInstructionsConfigurator.store'
import FieldConfiguratorStore, {
  FieldState,
} from '../FieldConfigurator/FieldConfigurator.store'

export default class ChecklistStore extends BaseInstructionsConfiguratorStore<IChecklistItem> {
  @observable private field: IPermitTypeField

  public constructor(
    field: IPermitTypeField,
    onChange: (checklist: IPermitTypeChecklist) => void,
    private onFieldChange: (field: IPermitTypeField) => void,
  ) {
    super(field.checklist, onChange)

    this.setField(field)
  }

  @action.bound
  public setField(field: IPermitTypeField) {
    this.field = field
  }

  @action.bound
  public setOnFieldChangeHandler(
    onFieldChange: (field: IPermitTypeField) => void,
  ) {
    this.onFieldChange = onFieldChange
  }

  public setInstructions({ list, listType }: IPermitTypeChecklist) {
    this.instructions = {
      listType: listType || InstructionListType.Enumerated,
      list: copyObjectArray(list || []),
    }
  }

  public get fieldState(): FieldState {
    return FieldConfiguratorStore.getStateFromSettings(this.field)
  }

  @computed
  public get questionnaireTypeAsOptions() {
    return Object.values(QuestionnaireType).map(type => ({
      value: type,
      name: getQuestionnaireTypeToDisplayName(type),
    }))
  }

  public getNewInstructionItem(): IChecklistItem {
    return {
      id: BsonObjectId.generate(),
      text: this.newInstructionText,
      questionnaireType: QuestionnaireType.YesNo,
    }
  }

  @action.bound
  public changeQuestionnaireTypeForChecklistItem(
    checklistItemId: string,
    questionnaireType: QuestionnaireType,
  ) {
    const checkListItem = this.instructions.list.find(
      ({ id }) => id === checklistItemId,
    )

    checkListItem.questionnaireType = questionnaireType

    this.changeInstructions()
  }

  @action.bound
  public updateInstructionTextByIndex(text: string, index: number) {
    this.instructions.list[index].text = text
  }

  @action.bound
  public changeFieldState(newFieldState: FieldState) {
    this.onFieldChange(
      Object.assign(
        this.field,
        FieldConfiguratorStore.getSettingsFromState(newFieldState),
      ),
    )
  }
}
