import * as React from 'react'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import Company from '~/client/src/shared/models/Company'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import CompanyProfilePreview from '../../../CompanyProfilePreview/CompanyProfilePreview'
import DeliveryConfirm from './DeliveryConfirm'

// translated

export interface IProps {
  isShown: boolean
  onHide: () => void
  onApply: () => void
  vendorId: string
  getCompanyById: (id: string) => Company
}

const vendorQuestion =
  'Selected company does not have the "Vendor" type tag. Do you want to add "Vendor" company type tag to selected company?'
const title = 'Add Vendor Tag'

export default class DeliveryAddVendorTagConfirm extends React.Component<IProps> {
  public render() {
    const { isShown, onHide, onApply, getCompanyById, vendorId } = this.props
    const vendorCompany = getCompanyById(vendorId)

    return (
      <DeliveryConfirm
        title={title}
        isShown={isShown}
        onHide={onHide}
        onApply={onApply}
        applyButtonText={Localization.translator.yes}
      >
        {vendorQuestion}
        <br />
        {this.renderCompanyProfile(vendorCompany)}
      </DeliveryConfirm>
    )
  }

  private renderCompanyProfile(company: Company): JSX.Element {
    if (!company) {
      return <div>{NO_VALUE}</div>
    }

    return (
      <CompanyProfilePreview
        company={company}
        isMenuHidden={true}
        isPhoneHidden={true}
      />
    )
  }
}
