import { action, computed, observable } from 'mobx'

import { CalendricalType } from '~/client/graph'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import RecurringDeliveriesSettings from '~/client/src/shared/models/RecurringDeliveriesSettings'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import DeliveryDetailsStore, {
  MAX_RECURRING_DAYS_FREQUENCY,
  MAX_RECURRING_WEEKS_FREQUENCY,
} from '../../DeliveryDetails.store'

export default class DeliveryRecurringOptionsStore {
  @observable public recurringSetting: RecurringDeliveriesSettings = null

  public constructor(
    private readonly projectDateStore: ProjectDateStore,
    private readonly deliveryDetailsStore: DeliveryDetailsStore,
    private readonly onChange: (
      id: FieldIds,
      setting: RecurringDeliveriesSettings,
    ) => void,
  ) {}

  @action.bound
  public init(setting: RecurringDeliveriesSettings) {
    this.recurringSetting = setting?.getCopy() as RecurringDeliveriesSettings
  }

  public toggleRecurring = () => {
    const { projectId, selectedDates } = this.deliveryDetailsStore

    const newSetting = RecurringDeliveriesSettings.createNew(
      projectId,
      this.projectDateStore,
      selectedDates?.endDate,
    )

    newSetting.selectedDays = [
      Number(this.projectDateStore.getWeekdayNumber(selectedDates.startDate)),
    ]

    this.onChange(
      FieldIds.RECURRING_OPTIONS,
      this.recurringSetting ? null : newSetting,
    )
  }

  @action.bound
  public changeFrequency(newFrequency: number) {
    if (newFrequency <= 0) {
      return
    }

    this.recurringSetting.frequency =
      newFrequency > this.maxFrequency ? this.maxFrequency : newFrequency

    this.saveChanges()
  }

  @action.bound
  public changeFrequencyType(newFrequencyType: CalendricalType) {
    if (!newFrequencyType || newFrequencyType === CalendricalType.Month) {
      return
    }

    this.recurringSetting.frequencyType = newFrequencyType

    if (this.recurringSetting.frequency > this.maxFrequency) {
      return this.changeFrequency(this.maxFrequency)
    }

    this.saveChanges()
  }

  @action.bound
  public changeDayToRepeat(dayNumber: number) {
    const { userProject, shouldBlockOnNonWorkTimes } = this.deliveryDetailsStore
    const { workingDaysMap } = this.projectDateStore

    if (
      !userProject.isSuperUser &&
      shouldBlockOnNonWorkTimes &&
      !workingDaysMap[dayNumber]
    ) {
      return
    }

    if (!this.recurringSetting.selectedDays) {
      this.recurringSetting.selectedDays = []
    }

    const index = this.recurringSetting.selectedDays.findIndex(
      day => day === dayNumber,
    )
    if (index === -1) {
      this.recurringSetting.selectedDays.push(dayNumber)
    } else {
      this.recurringSetting.selectedDays.splice(index, 1)
    }

    this.saveChanges()
  }

  private saveChanges = () => {
    this.onChange(FieldIds.RECURRING_OPTIONS, this.recurringSetting)
  }

  @computed
  public get maxFrequency(): number {
    switch (this.recurringSetting?.frequencyType) {
      case CalendricalType.Day:
        return MAX_RECURRING_DAYS_FREQUENCY
      case CalendricalType.Week:
        return MAX_RECURRING_WEEKS_FREQUENCY
    }
  }

  @computed
  public get daysToRepeat(): number[] {
    const { selectedDays } = this.recurringSetting || {}
    return selectedDays?.length ? selectedDays : []
  }

  @computed
  public get endDateValue(): string {
    const { getMonthDayAndYearToDisplay, endOfDay } = this.projectDateStore

    const endDay = endOfDay(this.recurringSetting?.endDate || Date.now())
    return getMonthDayAndYearToDisplay(endDay)
  }
}
