import * as React from 'react'

import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { Swipeable } from 'react-swipeable'

import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import WeekRow from './WeekRow'

import './SwipableDatePicker.scss'

const DEFAULT_TRANSLATE_X = -100
const TRANSLATE_X_STEP = 100
const ANIMATION_DURATION = 500

export interface IProps {
  projectDateStore?: ProjectDateStore
  selectedDate: Date
  onDateChanged: (date: Date) => void
}

@inject('projectDateStore')
@observer
export default class SwipableDatePicker extends React.Component<IProps> {
  @observable private displayedWeek: Date = null
  @observable private translateX: number = DEFAULT_TRANSLATE_X
  @observable private isAnimating: boolean = false

  public UNSAFE_componentWillMount() {
    this.displayedWeek = this.props.selectedDate
  }

  public render() {
    const { projectDateStore, selectedDate, onDateChanged } = this.props
    const prevWeek = projectDateStore.addWeeks(this.displayedWeek, -1)
    const nextWeek = projectDateStore.addWeeks(this.displayedWeek, 1)
    return (
      <div className="swipable-date-picker row no-grow">
        <Swipeable
          onSwipedRight={this.showPreviousWeek}
          onSwipedLeft={this.showNextWeek}
          trackMouse={true}
          className="full-width overflow-hidden"
        >
          <div
            className={classList({
              'week-rows-container': true,
              animation: this.isAnimating,
            })}
            style={{ transform: `translateX(${this.translateX}%)` }}
          >
            {[prevWeek, this.displayedWeek, nextWeek].map(week => (
              <WeekRow
                key={+week}
                weekDefinition={week}
                selectedDate={selectedDate}
                onDateChanged={onDateChanged}
                store={projectDateStore}
              />
            ))}
          </div>
        </Swipeable>
      </div>
    )
  }

  private showPreviousWeek = () => {
    this.isAnimating = true
    this.translateX += TRANSLATE_X_STEP

    setTimeout(() => {
      this.isAnimating = false

      const { projectDateStore } = this.props
      this.displayedWeek = projectDateStore.addWeeks(this.displayedWeek, -1)

      this.translateX = DEFAULT_TRANSLATE_X
    }, ANIMATION_DURATION)
  }

  private showNextWeek = () => {
    this.isAnimating = true
    this.translateX -= TRANSLATE_X_STEP

    setTimeout(() => {
      this.isAnimating = false

      const { projectDateStore } = this.props
      this.displayedWeek = projectDateStore.addWeeks(this.displayedWeek, 1)

      this.translateX = DEFAULT_TRANSLATE_X
    }, ANIMATION_DURATION)
  }
}
