import tinycolor from 'tinycolor2'

import ThemeMode from '../utils/ThemeModeManager'

const { getHEXColor } = ThemeMode

type EventStyleType = {
  textColor?: string
  backgroundColor?: string
  border?: {
    thickness: number
    strokeStyle: string
    color: string
  }
  outline?: {
    thickness: number
    strokeStyle: string
    color: string
  }
}

export default class EventStyle {
  public constructor(public styles: EventStyleType = {}) {}

  public withBorder(
    thickness: number,
    strokeStyle: 'solid' | 'dashed',
    color: string,
  ): EventStyle {
    this.styles.border = {
      thickness,
      strokeStyle,
      color: tinycolor(getHEXColor(color)).setAlpha(0.5).toString(),
    }
    return this
  }

  public withTextColor(color: string): EventStyle {
    this.styles.textColor = color
    return this
  }

  public withBackgroundColor(color: string): EventStyle {
    this.styles.backgroundColor = tinycolor(getHEXColor(color))
      .setAlpha(0.13)
      .toString()
    return this
  }

  public withOutline(
    thickness: number,
    strokeStyle: 'solid' | 'dashed',
    color: string,
  ): EventStyle {
    this.styles.outline = { thickness, strokeStyle, color }
    return this
  }
}
