import * as React from 'react'

import { inject, observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'
import { ILWFCColumn } from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import WorkflowCardStatus from '~/client/src/shared/components/WorkflowCard/Status'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import SitePermit from '~/client/src/shared/models/Permit'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import { DataKeys } from '../MaterialsList.store'
import BaseDataCellTable from './BaseDataCell/BaseDataCellTable'

// localization: translated

interface IProps {
  procurementId: string
  materialId: string
  forms: SitePermit[]
  onIdClick(formId: string): void

  footerTitle?: string
  shownColumnIds?: string[]
  isHeaderHidden?: boolean
  isFooterHidden?: boolean
  locationRenderer?(location: LocationBase): JSX.Element

  projectDateStore?: ProjectDateStore
  locationAttributesStore?: LocationAttributesStore
}

const ROW_HEIGHT = 40

@inject('projectDateStore', 'locationAttributesStore')
@observer
export default class MaterialTransferCellTable extends React.Component<IProps> {
  public render() {
    const { isHeaderHidden, isFooterHidden } = this.props
    return (
      <BaseDataCellTable<SitePermit>
        items={this.props.forms}
        columns={this.columns}
        getRowHeightByItem={this.getRowHeightByForm}
        getCellContentByDataKey={this.getCellContentByDataKey}
        getFooterTitleByDataKey={this.getFooterTitleByDataKey}
        onIdClick={this.onIdClick}
        iconElement={
          <Icons.MaterialTransferForm className="form-id-icon-large relative no-flex mr5" />
        }
        isHeaderHidden={isHeaderHidden}
        isFooterHidden={isFooterHidden}
      />
    )
  }

  private getCellContentByDataKey = (
    dataKey: string,
    form: SitePermit,
  ): JSX.Element => {
    const {
      procurementId,
      materialId,
      projectDateStore: { getMonthDayYearAndTimeToDisplay },
    } = this.props
    switch (dataKey) {
      case DataKeys.ID:
        return <>{form.code}</>
      case DataKeys.MATERIAL_TRANSFER_DATE:
        return (
          <span className="text-ellipsis">
            {getMonthDayYearAndTimeToDisplay(form.startDate)}
          </span>
        )
      case DataKeys.PLANNED_QUANTITY:
        return (
          <span className="text large end ellipsis">
            {(procurementId
              ? form.procurementQuantitiesMap[procurementId]
              : form.materialQuantitiesMap[materialId]) || 0}
          </span>
        )
      case DataKeys.STATUS:
        return (
          <WorkflowCardStatus
            className="no-grow"
            status={form.status}
            workflowStepLevel={form.workflowStepLevel}
            isLate={form.isLate}
          />
        )
      case DataKeys.CURRENT_MATERIAL_LOCATION:
        return this.renderLocations(
          form.getMaterialLocationIds(materialId, procurementId),
        )
      case DataKeys.PLANNED_INSTALL_LOCATION:
        return this.renderLocations([form.locations[0]?.id])
      default:
        return null
    }
  }

  private renderLocations = (locationIds: string[]): JSX.Element => {
    const {
      locationAttributesStore: { getById },
      locationRenderer,
    } = this.props

    if (!locationIds?.length) {
      return locationRenderer(null)
    }

    return (
      <div className="col overflow-hidden">
        {locationIds.map(id => (
          <div key={id} className="row py2">
            {locationRenderer(getById(id))}
          </div>
        ))}
      </div>
    )
  }

  private getFooterTitleByDataKey = (dataKey: string) => {
    const { footerTitle, forms, materialId, procurementId } = this.props

    switch (dataKey) {
      case DataKeys.ID:
        return footerTitle
      case DataKeys.PLANNED_QUANTITY:
        return forms.reduce(
          (sum, form) =>
            (sum +=
              (procurementId
                ? form.procurementQuantitiesMap[procurementId]
                : form.materialQuantitiesMap[materialId]) || 0),
          0,
        )
      default:
        return null
    }
  }

  private get columns(): ILWFCColumn[] {
    return [
      {
        label: Localization.translator.id_short,
        dataKey: DataKeys.ID,
        width: 140,
      },
      {
        label: Localization.translator.bookingDate,
        dataKey: DataKeys.MATERIAL_TRANSFER_DATE,
        width: 180,
      },
      {
        label: Localization.translator.quantity,
        dataKey: DataKeys.PLANNED_QUANTITY,
        width: 80,
      },
      {
        label: Localization.translator.status,
        dataKey: DataKeys.STATUS,
        width: 125,
      },
      {
        label: Localization.translator.locationFrom,
        dataKey: DataKeys.CURRENT_MATERIAL_LOCATION,
        width: 160,
      },
      {
        label: Localization.translator.locationTo,
        dataKey: DataKeys.PLANNED_INSTALL_LOCATION,
        width: 160,
      },
    ].filter(
      ({ dataKey }) =>
        dataKey === DataKeys.ID || this.props.shownColumnIds.includes(dataKey),
    )
  }

  private getRowHeightByForm = (form: SitePermit): number => {
    if (!form) {
      return 0
    }

    const { procurementId, materialId, shownColumnIds } = this.props
    if (!shownColumnIds.includes(DataKeys.CURRENT_MATERIAL_LOCATION)) {
      return ROW_HEIGHT
    }

    const rowNumbers = form.getMaterialLocationIds(
      materialId,
      procurementId,
    ).length

    return (rowNumbers || 1) * ROW_HEIGHT
  }

  private onIdClick = (form: SitePermit) => {
    this.props.onIdClick(form.id)
  }
}
