import * as React from 'react'

import { Classes } from '@blueprintjs/core'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IDeliveryMaterial } from '~/client/graph'
import Linkifier from '~/client/src/shared/components/Linkifier/Linkifier'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import DeliveryMaterialsCellStore from '../DeliveryMaterialsCell.store'

// localization: translated

interface IProps {
  deliveryId: string
  materials: IDeliveryMaterial[]
  onAddMaterialClick: (deliveryId: string, shouldAddMaterial?: boolean) => void

  store: DeliveryMaterialsCellStore
}

@observer
export default class DeliveryMaterialsCellModal extends React.Component<IProps> {
  private get store(): DeliveryMaterialsCellStore {
    return this.props.store
  }

  public render() {
    return (
      <div className="col">
        {this.header}
        {this.content}
        {this.footer}
      </div>
    )
  }

  private get header(): JSX.Element {
    const {
      isAnyCategoryEnabled,
      isMaterialNoteHidden,
      isMaterialQuantityHidden,
      getFieldName,
    } = this.store

    return (
      <div className="row py15 px10 text large bold bb-light-input-border">
        <div className="mw35" />
        {isAnyCategoryEnabled && (
          <div className="mw160 text-ellipsis mx5">
            {Localization.translator.materialsCategory}
          </div>
        )}
        {!isMaterialNoteHidden && (
          <div className="mw270 text-ellipsis mx5">
            {getFieldName(FieldIds.MATERIAL_NOTE)}
          </div>
        )}
        {!isMaterialQuantityHidden && (
          <>
            <div className="text-ellipsis mw55 mx5">
              {Localization.translator.quantity}
            </div>
            <div className="text-ellipsis mw90 mx5">
              {getFieldName(FieldIds.MATERIAL_UNITS)}
            </div>
          </>
        )}
      </div>
    )
  }

  private get content(): JSX.Element {
    return (
      <div className="scrollable materials-modal-content">
        {this.props.materials.map((mat, idx) => (
          <div
            key={idx}
            className={classList({
              'row pa10': true,
              'bt-light-input-border': !!idx,
            })}
          >
            <div className="text center large mw35">{idx + 1}</div>
            {this.renderCategory(mat.materialId)}
            {this.renderNote(mat.note)}
            {this.renderUnits(mat)}
          </div>
        ))}
      </div>
    )
  }

  private get footer(): JSX.Element {
    const { isAddBtnEnabled } = this.store

    return (
      <div className="text end bold blue-highlight large py15 px30 bt-light-input-border">
        <span
          className={classList({
            'inactive-element': !isAddBtnEnabled,
            pointer: isAddBtnEnabled,
            [Classes.POPOVER_DISMISS]: isAddBtnEnabled,
          })}
          onClick={this.onAddClick}
        >
          + {Localization.translator.addMaterial}
        </span>
      </div>
    )
  }

  private renderCategory(materialId: string): JSX.Element {
    const { isAnyCategoryEnabled, getCategoryLabel } = this.store

    if (!isAnyCategoryEnabled) {
      return null
    }

    const categoryLabel = getCategoryLabel(materialId)

    return (
      <div className="mw160 mx5">
        {categoryLabel ? (
          <SitemapAttributeTag
            shouldShowAsTag={true}
            className="material-tag"
            contentContainerClassName="text-ellipsis py2"
          >
            <span title={categoryLabel} className="text large">
              {categoryLabel}
            </span>
          </SitemapAttributeTag>
        ) : (
          NO_VALUE
        )}
      </div>
    )
  }

  private renderNote(note: string): JSX.Element {
    if (this.store.isMaterialNoteHidden) {
      return null
    }

    return (
      <Linkifier>
        <div className="mw270 text-ellipsis mx5">{note || NO_VALUE}</div>
      </Linkifier>
    )
  }

  private renderUnits(material: IDeliveryMaterial): JSX.Element {
    const { isMaterialQuantityHidden, getMaterialUnitName } = this.store

    if (isMaterialQuantityHidden) {
      return null
    }

    return (
      <>
        <div className="text large end ellipsis mw55 mx5">
          {material.quantity || 0}
        </div>
        <div className="text large ellipsis mx5 mw90">
          {getMaterialUnitName(material.unitId) || NO_VALUE}
        </div>
      </>
    )
  }

  private onAddClick = () => {
    if (!this.store.isAddBtnEnabled) {
      return
    }

    const { onAddMaterialClick, deliveryId } = this.props
    onAddMaterialClick(deliveryId, true)
  }
}
