import { ContentObjectStatus, IContentObject } from '~/client/graph'

import Localization from '../../shared/localization/LocalizationManager'
import ContentObjectModel from './ContentObjectModel'

export enum rfiTypes {
  NULL = 'NULL',
  CLARIFICATION_NEEDED = 'CLARIFICATION_NEEDED',
  INCORRECT_SPECIFICATION = 'INCORRECT_SPECIFICATION',
  SUPERINTENDENT_NEEDED = 'SUPERINTENDENT_NEEDED',
  MATERIAL_QUESTION = 'MATERIAL_QUESTION',
}

export default class Rfi extends ContentObjectModel<rfiTypes> {
  public static none: Rfi
  public static getRfiTypeCaption(type: rfiTypes): string {
    const caption =
      Localization.translator.rfi + ': ' + Rfi.getDisplayType(type)
    return caption.toUpperCase()
  }

  public static getOpenedCaption(type: rfiTypes): string {
    const caption =
      Localization.translator.rfiOpened + ': ' + Rfi.getDisplayType(type)
    return caption.toUpperCase()
  }

  public static getDisplayType(type: rfiTypes) {
    switch (type) {
      case rfiTypes.NULL:
        return Localization.translator.unspecifiedType
      case rfiTypes.CLARIFICATION_NEEDED:
        return Localization.translator.clarificationNeeded
      case rfiTypes.INCORRECT_SPECIFICATION:
        return Localization.translator.incorrectSpecification
      case rfiTypes.MATERIAL_QUESTION:
        return Localization.translator.materialQuestion
      case rfiTypes.SUPERINTENDENT_NEEDED:
        return Localization.translator.superintendentNeeded
    }
  }

  public static CreateNullRfi(): Rfi {
    const f = new Rfi('')
    f.author = ''
    f.status = ContentObjectStatus.Open
    f.threadId = ''

    return f
  }

  public constructor(id: string, dto?: IContentObject) {
    super(id, dto)
    this.type = dto ? (dto.type as rfiTypes) : rfiTypes.NULL
  }
}

Rfi.none = Rfi.CreateNullRfi()
