import { IFileRequisites, UploadingType } from '~/client/graph'
import { GetSignedUploadUrlDocument } from '~/client/graph/operations/generated/FileUploader.generated'
import Command from '~/client/src/shared/commands/Command'

import GraphExecutorStore from '../../stores/domain/GraphExecutor.store'

export default class UploadFileCommand implements Command {
  public constructor(
    private readonly file: Blob,
    private readonly graphExecutorStore: GraphExecutorStore,
    private readonly fileName: string,
    private readonly uploadingType: UploadingType,
    private readonly isPublic: boolean,
  ) {}

  public async execute(): Promise<IFileRequisites[]> {
    const requisites = await this.getUploadingRequisites()
    for (const requisite of requisites) {
      await this.uploadOnSignedUrl(requisite.signedUrl, requisite.contentType)
    }
    return requisites
  }

  private async getUploadingRequisites(): Promise<IFileRequisites[]> {
    const result = await this.graphExecutorStore.query(
      GetSignedUploadUrlDocument,
      {
        getSignedUploadUrl: {
          fileName: this.fileName,
          uploadingType: this.uploadingType,
          isPublic: this.isPublic,
        },
      },
    )

    return result.data.getSignedUploadUrl
  }

  private uploadOnSignedUrl(
    signedUrl: string,
    contentType: string,
  ): Promise<Response> {
    return fetch(signedUrl, {
      method: 'PUT',
      headers: { 'Content-Type': contentType },
      body: this.file,
    })
  }
}
