import * as React from 'react'

import { observer } from 'mobx-react'

import BaseGroupingOptions from '~/client/src/desktop/components/BaseGroupingOptions/BaseGroupingOptions'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'

import LogisticsGroupingOptionsStore from './LogisticsGroupingOptions.store'

// localization: no display text to translate

interface IProps {
  store: LogisticsGroupingOptionsStore
  forceClose: boolean
  CustomHandleButton?: typeof BaseActionButton
}

@observer
export default class LogisticsGroupingOptions extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props)
  }

  public render() {
    const { forceClose, CustomHandleButton, store } = this.props

    return (
      <BaseGroupingOptions
        store={store}
        forceClose={forceClose}
        CustomHandleButton={CustomHandleButton}
        shouldHideBandCreation
      />
    )
  }
}
