import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'

import Colors from '~/client/src/shared/theme.module.scss'

interface IProps {
  companyId: string

  companiesStore?: CompaniesStore
}

// localization: no display text to translate

@inject('companiesStore')
@observer
export default class STXCompanyLinkIndicator extends React.Component<IProps> {
  public render() {
    const { companyId, companiesStore } = this.props

    const company = companiesStore.getCompanyById(companyId)

    return (
      <Icon
        color={company?.isLinked ? Colors.primary40 : ''}
        icon={IconNames.GLOBE_NETWORK}
        className={classList({
          'row no-grow': true,
        })}
      />
    )
  }
}
