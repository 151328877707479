export const MAX_FIELDS_DEPTH = 2
export const MAX_FIELDS_NUMBER = 200
export const MAX_SELECT_OPTIONS_COUNT = 200

export const MAX_CONDITIONAL_DEPTH_LEVEL = 3
export const MAX_CONDITIONAL_FIELDS = 25

export const PERMIT_TABLE_FIELDS_KEY = 'Table fields'
export const MAX_PERMIT_TABLE_COLUMNS = 15
export const MAX_PERMIT_TABLE_ROWS = 50

export const FORM_MATERIAL_FIELDS_KEY = 'MaterialRelatedFields'
