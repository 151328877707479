import * as React from 'react'

interface IProps {
  count: number | string
}

export default class FilterCounter extends React.PureComponent<IProps> {
  public render() {
    return (
      <span className="row text large white bold bg-palette-blue-scale px4 brada4 no-grow">
        {this.props.count}
      </span>
    )
  }
}
