import * as React from 'react'

import { inject, observer } from 'mobx-react'

import Photo from '~/client/src/shared/models/Photo'

import CommonStore from '../../../stores/ui/Common.store'
import FileInputBase from '../../FileInput/FileInput'

interface IPhotoContainerProps {
  photo: Photo
  caption: string
  customPhotoClick?: (
    e: React.MouseEvent<HTMLImageElement>,
    url: string,
  ) => void
  isPhotoThread?: boolean
  FileInputType: typeof FileInputBase
  common?: CommonStore
}

@inject('common')
@observer
export default class PhotoContainer extends React.Component<IPhotoContainerProps> {
  public handleClick = (e: React.MouseEvent<HTMLImageElement>) => {
    const { customPhotoClick, common, photo } = this.props

    if (customPhotoClick) {
      return customPhotoClick(e, photo.url)
    }

    common.displayPhotoView(photo)
  }

  public render() {
    const { caption, photo, isPhotoThread, FileInputType } = this.props

    if (!photo) {
      return null
    }

    const onClickHandler = isPhotoThread ? this.handleClick : null

    return (
      <div className="mt10">
        <FileInputType
          id="Sitemap"
          name={caption}
          value={photo.url}
          thumbUrl={photo.thumbUrl}
          isReadonly={true}
          textClassName="text large"
          shouldHideIconAndOutline={true}
          onClickHandler={onClickHandler}
        />
      </div>
    )
  }
}
