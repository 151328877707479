import Flag, { flagTypes } from '~/client/src/shared/models/Flag'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'

import EventsStore from '../EventStore/Events.store'
import ContentObjectsStore from './ContentObjects.store'

export default class FlagsStore extends ContentObjectsStore<flagTypes, Flag> {
  public constructor(messagesStore: MessagesStore, eventsStore: EventsStore) {
    super(messagesStore, eventsStore)
  }

  public get byId() {
    return this.eventsStore.appState.flags
  }

  protected createAnInstance(id: string, dto?: any) {
    return new Flag(id, dto)
  }
}
