import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ActionBarInput from '~/client/src/shared/components/ActionBarInput/ActionBarInput'
import CameraUploadButton from '~/client/src/shared/components/CameraUploadButton/CameraUploadButton'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

import ContentObjectModel from '../../models/ContentObjectModel'

// translated

interface IProps {
  model: ContentObjectModel<any>
  className: string
  placeholder: string
  slackBarStore?: SlackBarStore
}

@inject('slackBarStore')
@observer
export default class ContentObjectActionBar extends React.Component<IProps> {
  private isSending: boolean

  private get closeBtnClass() {
    const { slackBarStore, className } = this.props
    return classList({
      ['item-button close-btn action-bar ' + className]: true,
      hidden: slackBarStore.shouldShowPreview,
    })
  }

  public render() {
    const { isOpen } = this.props.model
    const { slackBarStore, placeholder } = this.props

    const { open, close, send } = Localization.translator

    return (
      <div className="pa10 bt-light-grey slack-bar">
        <form onSubmit={this.send}>
          <div className="col">
            <ActionBarInput
              value={slackBarStore.draftMessage}
              onChange={this.setDraftMessage}
              placeholder={placeholder}
            />
            <div className="row x-end">
              <div className="row x-start no-flex-children">
                <button
                  onClick={this.toggle}
                  type="button"
                  className={this.closeBtnClass}
                >
                  {isOpen ? close : open}
                  <Icons.CloseFlag />
                </button>
              </div>

              <div className="row x-end no-flex-children">
                <CameraUploadButton store={slackBarStore} />
                <button
                  className="slack-bar-send-btn"
                  type="submit"
                  disabled={this.isSending}
                >
                  {send}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }

  @action.bound
  private async send(evt: React.FormEvent<HTMLFormElement>) {
    this.isSending = true
    evt.preventDefault()
    evt.stopPropagation()
    const { slackBarStore, model } = this.props
    await slackBarStore.replyToThread(model)
    this.isSending = false
    slackBarStore.closeImagePreview()
  }

  private toggle = () => {
    const { slackBarStore, model } = this.props
    slackBarStore.toggleContentObject(model)
  }

  private setDraftMessage = (evt: React.FormEvent<HTMLInputElement>) => {
    this.props.slackBarStore.draftMessage = evt.currentTarget.value
  }
}
