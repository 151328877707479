import * as React from 'react'

import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { ScrollSyncPane } from 'react-scroll-sync'

import { OnTimeStatus } from '~/client/graph'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import CompanyBubble from '~/client/src/desktop/views/SimpleGanttView/components/ActivityGanttOrListView/components/ActivityNameCell/CompanyBubble'
import ActivityAssociationStatus from '~/client/src/shared/components/ActivityAssociationStatus/ActivityAssociationStatus'
import ActivityStatusIndicator from '~/client/src/shared/components/ActivityItemContentWithStatusUpdate/componets/ActivityStatusIndicator'
import Checkbox from '~/client/src/shared/components/Checkbox'
import * as Icons from '~/client/src/shared/components/Icons'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import NotificationsIndicators from '~/client/src/shared/components/NotificationsIndicators/NotificationsIndicators'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import TableSeparators from '~/client/src/shared/components/TableSeparators'
import ActivityDatesType from '~/client/src/shared/enums/ActivityDatesType'
import Activity from '~/client/src/shared/models/Activity'
import CategoryOfVariance, {
  CategoryOfVarianceTypesList,
} from '~/client/src/shared/models/CategoryOfVariance'
import { SAVE_ACTIVITY } from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import CategoriesOfVarianceStore from '~/client/src/shared/stores/domain/CategoriesOfVariance.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

import ActivityGanttOrListViewStore from '../../ActivityGanttOrListView.store'
import ActivityDatesCell from '../ActivityListView/components/ActivityDatesCell'
import ActivityPercentCompleteCell from '../ActivityListView/components/ActivityPercentCompleteCell'

interface IActivityNameCellProps {
  activity: Activity
  level: number
  company: string
  state?: DesktopInitialState
  activityFiltersStore?: ActivityFiltersStore
  store: ActivityGanttOrListViewStore
  projectDateStore?: ProjectDateStore
  firstCellWidth?: number
  categoriesOfVarianceStore?: CategoriesOfVarianceStore
  slackBarStore?: SlackBarStore
  eventsStore?: DesktopEventStore
  activitiesStore?: ActivitiesStore
}

const DEFAULT_WIDTH = 160
const ACTIVITY_LEVEL_OFFSET = 12
const FULL_BLOCK_WIDTH = 755
const SCROLLABLE_SECTION_MAX_WIDTH = 430
const ACTIVITY_CODE_MAX_WIDTH = 396
const ACTIVITY_CODE_NAME_FULL_WIDTH = 580
const ON_TIME_CELL_OFFSET = 480
const COV_CELL_OFFSET = 640
const MISSING_COV = 'Missing'

@inject(
  'state',
  'activityFiltersStore',
  'projectDateStore',
  'categoriesOfVarianceStore',
  'slackBarStore',
  'eventsStore',
  'activitiesStore',
)
@observer
export default class ActivityNameCell extends React.Component<IActivityNameCellProps> {
  @observable private searchKey: string = ''
  @observable private isOnTimeMenuOpen: boolean = false
  @observable private isCOVTimeMenuOpen: boolean = false
  private ref: HTMLDivElement = null
  @computed
  private get deltaDays(): number {
    const { activity, company, projectDateStore } = this.props
    return activity.getDeltaDays(projectDateStore, company)
  }

  public render() {
    const {
      activity,
      activityFiltersStore,
      store,
      level,
      company,
      projectDateStore,
      state,
      firstCellWidth,
      categoriesOfVarianceStore,
      activitiesStore,
    } = this.props

    const {
      userActiveProjectSettings,
      activityList: { viewMode },
      isStatusUpdateManpowerDisabled,
    } = state

    const { code, name, plannedDuration, formattedId } = activity

    const { manpower } = activity.getStatusUpdateForCompany(company)

    let { companies = [] } = activity
    if (company) {
      companies = [company, ...companies.filter(c => c !== company)]
    }

    const isGanttViewMode = viewMode === ViewModes.Gantt

    const activityCodeNameWidth =
      ACTIVITY_CODE_MAX_WIDTH - ACTIVITY_LEVEL_OFFSET * (level + 1)
    const scrollableWidth = firstCellWidth
      ? firstCellWidth - ACTIVITY_CODE_NAME_FULL_WIDTH
      : FULL_BLOCK_WIDTH
    const scrollableInnerWidth = isGanttViewMode
      ? SCROLLABLE_SECTION_MAX_WIDTH
      : DEFAULT_WIDTH

    const categoryOfVariance = categoriesOfVarianceStore.list.find(
      cov => cov.isOpen && cov.activityObjectId === code,
    )
    const onTimeStatus = activity.getOnTimeStatus(
      projectDateStore,
      this.deltaDays,
      false,
      categoryOfVariance,
    )
    const isCOVMissing = this.categoryOfVarianceDisplayValue === MISSING_COV
    const sequenceNumber = activitiesStore.listItemIdToNumberMap[code]
    const activityId = formattedId(sequenceNumber)

    return (
      <div className="row full-height">
        <div
          onClick={this.onToggle}
          className="row pl10 no-grow bb-light-grey full-height checkbox-container"
        >
          <Checkbox isChecked={store.isActivitySelected(code)} onClick={null} />
        </div>
        <TableSeparators
          level={level + 1}
          className="pl10 no-grow bb-light-grey full-height br-palette-grey"
        />
        <div
          className="pl10 gant-activity-col bb-light-grey gantt-row gant-left-coll-cell"
          onClick={this.onNameClick}
        >
          <div className="activity-name-header row br-light-grey">
            <div className="row full-height activity-id-caption br-light-grey">
              <div className="flex-item row text-ellipsis px5">
                <span className="text ellipsis">{code}</span>
              </div>
            </div>
            <div
              className="row full-height pl10"
              style={{
                minWidth: activityCodeNameWidth,
                maxWidth: activityCodeNameWidth,
              }}
            >
              <ActivityAssociationStatus
                activityId={activity.code}
                className={classList({
                  'mb4 no-grow': true,
                  'inactive-element':
                    userActiveProjectSettings?.isPresentationUser,
                })}
              />
              <div className="no-grow row text uppercase no-outline-container cell cell-header pointer lp05 no-select full-height">
                {activityId}
              </div>
              <div className="flex-item row text-ellipsis px5">
                <span className="text ellipsis">{name}</span>
              </div>
            </div>
            {activityFiltersStore.areCompaniesEnabled && !isGanttViewMode && (
              <div
                className={classList({
                  'row px5 mw160': true,
                })}
              >
                <CompanyBubble companyNames={companies} />
                <div className="no-grow gant-activity-icons row px5">
                  <NotificationsIndicators
                    activity={activity}
                    hideCriticalPath={true}
                  />
                </div>
              </div>
            )}
          </div>
          <ScrollSyncPane>
            <div
              className="scrollable-gantt-cell"
              ref={ref => (this.ref = ref)}
              style={{ minWidth: DEFAULT_WIDTH, maxWidth: scrollableWidth }}
            >
              <div
                className="full-height row"
                style={{
                  minWidth: scrollableInnerWidth,
                  maxWidth: scrollableInnerWidth,
                }}
              >
                {isGanttViewMode && (
                  <>
                    <div className="text medium x-center y-center col mw75">
                      {plannedDuration}
                    </div>
                    <div className="text medium x-center y-center col mw75">
                      {activity.getRemainingDuration(company)}
                    </div>
                    <ActivityDatesCell
                      activity={activity}
                      dateType={ActivityDatesType.Start}
                    />
                    <ActivityDatesCell
                      activity={activity}
                      dateType={ActivityDatesType.Finish}
                    />
                  </>
                )}
                <div
                  className={classList({
                    'manpower-complete-coll row empty-coll text': true,
                  })}
                >
                  {!isStatusUpdateManpowerDisabled &&
                    this.getValueElement(manpower)}
                  {isGanttViewMode && (
                    <ActivityPercentCompleteCell
                      activity={activity}
                      company={company}
                    />
                  )}
                </div>
                <ActivityStatusIndicator
                  activity={activity}
                  isActualStatus={true}
                  company={company}
                />
                <ActivityStatusIndicator
                  activity={activity}
                  company={company}
                />
                <div
                  className={classList({
                    'row x-center mw160 full-height selectable-option-holder on-time relative':
                      true,
                    open: this.isOnTimeMenuOpen,
                  })}
                >
                  <div
                    className={classList({
                      'no-grow text activity-status-cell pa4 brada4 large':
                        true,
                      'done-on-time': onTimeStatus === OnTimeStatus.Yes,
                      'done-not-on-time': onTimeStatus === OnTimeStatus.No,
                    })}
                    onClick={this.toggleOnTimeMenu}
                  >
                    {this.renderStatusIcon()}
                    {onTimeStatus}
                  </div>
                  {this.isOnTimeMenuOpen &&
                    this.renderOptionsMenu(
                      [OnTimeStatus.Yes, OnTimeStatus.No],
                      this.toggleOnTimeMenu,
                      this.onTimeOffset,
                      this.handleOnTimeClick,
                      false,
                      this.renderOnTimeOption,
                    )}
                </div>
                <div
                  className={classList({
                    'row x-center mw300 relative full-height selectable-option-holder cov relative':
                      true,
                    open: this.isCOVTimeMenuOpen,
                  })}
                >
                  <div
                    className={classList({
                      'text add-button-text pa10 brada10 w-fit-content pointer':
                        true,
                      'blue-highlight bold': isCOVMissing,
                    })}
                    onClick={this.toggleCOVMenu}
                  >
                    {this.categoryOfVarianceDisplayValue}
                  </div>
                  {this.isCOVTimeMenuOpen &&
                    this.renderOptionsMenu(
                      CategoryOfVarianceTypesList,
                      this.toggleCOVMenu,
                      this.COVOffset,
                      this.handleCOVClick,
                      true,
                      CategoryOfVariance.getDisplayType,
                    )}
                </div>
              </div>
            </div>
          </ScrollSyncPane>
        </div>
      </div>
    )
  }

  private get categoryOfVarianceDisplayValue() {
    const { categoriesOfVarianceStore, activity, projectDateStore } = this.props
    const onTimeStatus = activity.getOnTimeStatus(
      projectDateStore,
      this.deltaDays,
    )
    const categoryOfVariance = categoriesOfVarianceStore.list.find(
      cov => cov.isOpen && cov.activityObjectId === activity.code,
    )

    if (categoryOfVariance) {
      return CategoryOfVariance.getDisplayType(categoryOfVariance.type)
    }

    if (
      onTimeStatus === OnTimeStatus.Yes ||
      onTimeStatus === OnTimeStatus.None
    ) {
      return '-'
    }

    return MISSING_COV
  }

  private renderStatusIcon(): JSX.Element {
    const { activity, projectDateStore, categoriesOfVarianceStore } = this.props
    const categoryOfVariance = categoriesOfVarianceStore.list.find(
      cov => cov.isOpen && cov.activityObjectId === activity.code,
    )
    const status = activity.getOnTimeStatus(
      projectDateStore,
      this.deltaDays,
      false,
      categoryOfVariance,
    )

    switch (true) {
      case activity?.onTimeStatus === OnTimeStatus.No:
        return <Icons.Incomplete className="mr5" />
      case status === OnTimeStatus.No:
        return <Icons.ActivityLateCheckmark className="mr5" />
      case status === OnTimeStatus.Yes:
        return <Icons.ActivityOntimeCheckmark className="mr5" />
    }
  }

  private renderOnTimeOption(onTimeStatus: OnTimeStatus) {
    let className = 'no-grow text activity-status-cell pa4 brada4 large'
    let icon
    switch (onTimeStatus) {
      case OnTimeStatus.Yes:
        className += ' done-on-time'
        icon = <Icons.ActivityOntimeCheckmark className="mr5" />
        break
      case OnTimeStatus.No:
        className += ' done-not-on-time'
        icon = <Icons.ActivityLateCheckmark className="mr5" />
    }
    return (
      <div className={className}>
        {icon}
        {onTimeStatus}
      </div>
    )
  }

  private getValueElement(mainValue: number | string) {
    const { activity, projectDateStore } = this.props
    if (!activity.didStartOrDidPlanToStart(projectDateStore)) {
      return
    }

    return (
      <div className="row x-center">
        <div className="row colored-val">{mainValue}</div>
      </div>
    )
  }

  private renderOptionsMenu(
    options: string[],
    toggleMenu: () => void,
    left: number,
    onClick: (option: string) => void,
    shouldRenderIndex: boolean,
    mapper?: (option: string) => string | JSX.Element,
  ) {
    return (
      <MenuCloser closeMenu={toggleMenu}>
        <div className="fixed" style={{ zIndex: 2, left }}>
          <div className="absolute options-list bg-white text no-bold">
            <StruxhubInput
              isMinimalisticMode={true}
              onChange={this.updateSearchKey}
            />
            {options
              .filter(option =>
                option.toLowerCase().includes(this.searchKey.toLowerCase()),
              )
              .map((option, index) => {
                return (
                  <div
                    className="option row bb-light-grey pa10"
                    key={option}
                    onClick={onClick.bind(this, option)}
                  >
                    {shouldRenderIndex && (
                      <span className="text large blue-highlight mr10 no-grow">
                        {index + 1}
                      </span>
                    )}
                    <span className="text large row">
                      {mapper ? mapper(option) : option}
                    </span>
                  </div>
                )
              })}
          </div>
        </div>
      </MenuCloser>
    )
  }

  private updateSearchKey = (event: React.SyntheticEvent<HTMLInputElement>) => {
    event.stopPropagation()
    this.searchKey = event.currentTarget.value
  }

  private toggleOnTimeMenu = () => {
    this.isOnTimeMenuOpen = !this.isOnTimeMenuOpen
    this.searchKey = ''
  }

  private toggleCOVMenu = () => {
    this.isCOVTimeMenuOpen = !this.isCOVTimeMenuOpen
    this.searchKey = ''
  }

  @action.bound
  private onNameClick(event: React.MouseEvent<HTMLElement>) {
    const { activity, store, company } = this.props

    event.stopPropagation()
    store.selectCompany(company)
    store.openActivityLog(activity)
  }

  @action.bound
  private onToggle(event: React.MouseEvent<HTMLElement>) {
    const { activity, store, company } = this.props

    event.stopPropagation()
    store.selectCompany(company)
    store.toggleActivitySelection(activity.code)
  }

  private handleCOVClick = type => {
    const { slackBarStore } = this.props
    this.toggleCOVMenu()
    slackBarStore.setCategoryOfVarianceType(type)
    slackBarStore.createCategoryOfVariance()
  }

  private handleOnTimeClick = (type: OnTimeStatus) => {
    const { eventsStore, activity } = this.props
    this.toggleOnTimeMenu()
    const activityInput = activity.toInput()
    activityInput.onTimeStatus = type
    eventsStore.dispatch(SAVE_ACTIVITY, activityInput)
  }

  private get onTimeOffset(): number {
    return (
      ACTIVITY_CODE_NAME_FULL_WIDTH + ON_TIME_CELL_OFFSET - this.ref.scrollLeft
    )
  }

  private get COVOffset(): number {
    return ACTIVITY_CODE_NAME_FULL_WIDTH + COV_CELL_OFFSET - this.ref.scrollLeft
  }
}
