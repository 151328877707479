import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'
import Company from '~/client/src/shared/models/Company'
import Resource from '~/client/src/shared/models/Resource'
import ActivityCompanyRelationshipsStore from '~/client/src/shared/stores/domain/ActivityCompanyRelationships.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'

import MappingCompanySelector from './MappingCompanySelector'

const NONE_VALUE = null

interface IProps {
  resource: Resource
  companiesStore?: CompaniesStore
  activityCompanyRelationshipsStore?: ActivityCompanyRelationshipsStore
}

@inject('companiesStore', 'activityCompanyRelationshipsStore')
@observer
export default class CompanyObjectsSelector extends React.Component<IProps> {
  public render() {
    if (!this.companyObjects.length) {
      return null
    }

    return (
      <div>
        {this.existingRelationships.length ? (
          this.existingRelationships.map(rel => (
            <div key={rel.id} className="row y-center">
              <Icons.HorizontalArrows className="row blue-icon pr12 no-grow" />
              {this.renderCompanySelector(rel.struxHubCompanyId)}
            </div>
          ))
        ) : (
          <div className="pl40">{this.renderCompanySelector(NONE_VALUE)}</div>
        )}
      </div>
    )
  }

  private renderCompanySelector(valueId: string) {
    const options = this.getAvailableOptions(valueId)
    if (!options.length) {
      return null
    }
    const value = options.find(o => o.id === valueId)

    return (
      <MappingCompanySelector
        value={value}
        options={options}
        applyCompany={this.applyCompany}
      />
    )
  }

  private applyCompany = (newCompany: Company, prevCompany: Company) => {
    const { activityCompanyRelationshipsStore } = this.props

    const prevRel =
      prevCompany &&
      this.existingRelationships.find(
        r => r.struxHubCompanyId === prevCompany.id,
      )

    if (prevRel) {
      if (newCompany) {
        prevRel.struxHubCompanyId = newCompany.id
        activityCompanyRelationshipsStore.saveRelationship(prevRel)
      } else {
        activityCompanyRelationshipsStore.removeRelationship(prevRel)
      }
    } else {
      if (newCompany) {
        activityCompanyRelationshipsStore.createRelationship(
          newCompany.id,
          this.resource.id,
        )
      }
    }
  }

  private get resource(): Resource {
    return this.props.resource
  }

  @computed
  private get existingRelationships() {
    return this.props.activityCompanyRelationshipsStore.list.filter(
      rel => rel.externalCompanyId === this.resource.id,
    )
  }

  @computed
  private get hasRelationshipMap() {
    const res = {}
    this.existingRelationships.forEach(rel => {
      res[rel.struxHubCompanyId] = true
    })
    return res
  }

  @computed
  private get companyObjects(): Company[] {
    return this.props.companiesStore.availableSortedCompanies
  }

  private getAvailableOptions(currentValue: string): Company[] {
    return this.companyObjects.filter(
      ({ id }) => !this.hasRelationshipMap[id] || currentValue === id,
    )
  }
}
