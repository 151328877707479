import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IFormsConfigurationsFieldsFragment = Pick<
  Types.IFormsConfigurations,
  'id' | 'projectId'
> & {
  maps?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.IOrderedSitemap, 'globeViewId' | 'sitemapId' | 'order'>
      >
    >
  >
}

export type IGetFormsConfigurationsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetFormsConfigurationsQuery = {
  formsConfigurations?: Types.Maybe<{
    data: Array<IFormsConfigurationsFieldsFragment>
  }>
}

export type ISaveFormsConfigurationsMutationVariables = Types.Exact<{
  formsConfigurations: Types.IFormsConfigurationsInput
}>

export type ISaveFormsConfigurationsMutation = {
  saveFormsConfigurations?: Types.Maybe<IFormsConfigurationsFieldsFragment>
}

export type IListenToFormsConfigurationsSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToFormsConfigurationsSubscription = {
  formsConfigurations?: Types.Maybe<
    Pick<Types.IFormsConfigurationsChangeEvent, 'id'> & {
      item?: Types.Maybe<IFormsConfigurationsFieldsFragment>
    }
  >
}

export const FormsConfigurationsFieldsFragmentDoc = gql`
  fragment FormsConfigurationsFields on FormsConfigurations {
    id
    maps {
      globeViewId
      sitemapId
      order
    }
    projectId
  }
`
export const GetFormsConfigurationsDocument = gql`
  query GetFormsConfigurations($projectId: ObjectId!) {
    formsConfigurations(projectId: $projectId, limit: 1) {
      data {
        ...FormsConfigurationsFields
      }
    }
  }
  ${FormsConfigurationsFieldsFragmentDoc}
`

/**
 * __useGetFormsConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetFormsConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormsConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormsConfigurationsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetFormsConfigurationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetFormsConfigurationsQuery,
    IGetFormsConfigurationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetFormsConfigurationsQuery,
    IGetFormsConfigurationsQueryVariables
  >(GetFormsConfigurationsDocument, options)
}
export function useGetFormsConfigurationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetFormsConfigurationsQuery,
    IGetFormsConfigurationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetFormsConfigurationsQuery,
    IGetFormsConfigurationsQueryVariables
  >(GetFormsConfigurationsDocument, options)
}
export type GetFormsConfigurationsQueryHookResult = ReturnType<
  typeof useGetFormsConfigurationsQuery
>
export type GetFormsConfigurationsLazyQueryHookResult = ReturnType<
  typeof useGetFormsConfigurationsLazyQuery
>
export type GetFormsConfigurationsQueryResult = Apollo.QueryResult<
  IGetFormsConfigurationsQuery,
  IGetFormsConfigurationsQueryVariables
>
export const SaveFormsConfigurationsDocument = gql`
  mutation SaveFormsConfigurations(
    $formsConfigurations: FormsConfigurationsInput!
  ) {
    saveFormsConfigurations(formsConfigurations: $formsConfigurations) {
      ...FormsConfigurationsFields
    }
  }
  ${FormsConfigurationsFieldsFragmentDoc}
`
export type ISaveFormsConfigurationsMutationFn = Apollo.MutationFunction<
  ISaveFormsConfigurationsMutation,
  ISaveFormsConfigurationsMutationVariables
>

/**
 * __useSaveFormsConfigurationsMutation__
 *
 * To run a mutation, you first call `useSaveFormsConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFormsConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFormsConfigurationsMutation, { data, loading, error }] = useSaveFormsConfigurationsMutation({
 *   variables: {
 *      formsConfigurations: // value for 'formsConfigurations'
 *   },
 * });
 */
export function useSaveFormsConfigurationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveFormsConfigurationsMutation,
    ISaveFormsConfigurationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveFormsConfigurationsMutation,
    ISaveFormsConfigurationsMutationVariables
  >(SaveFormsConfigurationsDocument, options)
}
export type SaveFormsConfigurationsMutationHookResult = ReturnType<
  typeof useSaveFormsConfigurationsMutation
>
export type SaveFormsConfigurationsMutationResult =
  Apollo.MutationResult<ISaveFormsConfigurationsMutation>
export type SaveFormsConfigurationsMutationOptions = Apollo.BaseMutationOptions<
  ISaveFormsConfigurationsMutation,
  ISaveFormsConfigurationsMutationVariables
>
export const ListenToFormsConfigurationsDocument = gql`
  subscription ListenToFormsConfigurations($projectId: ObjectId!) {
    formsConfigurations(projectId: $projectId) {
      id
      item {
        ...FormsConfigurationsFields
      }
    }
  }
  ${FormsConfigurationsFieldsFragmentDoc}
`

/**
 * __useListenToFormsConfigurationsSubscription__
 *
 * To run a query within a React component, call `useListenToFormsConfigurationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToFormsConfigurationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToFormsConfigurationsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToFormsConfigurationsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToFormsConfigurationsSubscription,
    IListenToFormsConfigurationsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToFormsConfigurationsSubscription,
    IListenToFormsConfigurationsSubscriptionVariables
  >(ListenToFormsConfigurationsDocument, options)
}
export type ListenToFormsConfigurationsSubscriptionHookResult = ReturnType<
  typeof useListenToFormsConfigurationsSubscription
>
export type ListenToFormsConfigurationsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToFormsConfigurationsSubscription>
