import { action, computed, observable } from 'mobx'

import { CategoryName } from '~/client/graph'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopActivityListStore from '~/client/src/desktop/views/SimpleGanttView/components/DesktopActivityList.store'
import {
  AND_CATEGORIES,
  categoryNamesList,
} from '~/client/src/shared/enums/CategoryNames'
import Activity from '~/client/src/shared/models/Activity'
import Guard from '~/client/src/shared/utils/Guard'

// localization: no display text to translate

export default class CategoryFilterStore {
  @observable public selectedCategories: CategoryName[] = []
  @observable public isShown: boolean = false

  public constructor(
    private readonly desktopActivityListStore: DesktopActivityListStore,
    private readonly state: DesktopInitialState,
    private readonly onShowChanged?: (isShown: boolean) => void,
  ) {
    Guard.requireAll({
      state,
      desktopActivityListStore,
    })
  }

  @computed
  public get activitiesCountBySelectedCategories(): number {
    return this.getActivitiesCountByCategoriesMatrix(
      this.desktopActivityListStore.buildCategoryFilteringMatrix(
        this.selectedCategories,
      ),
    )
  }

  @computed
  public get categoryToActivitiesCountMap() {
    const map: { [key: string]: number } = {}

    categoryNamesList.forEach(category => {
      map[category] = this.getActivitiesCountByCategoriesMatrix(
        this.getCategoriesMatrixForSpecificCategory(category),
      )
    })

    return map
  }

  public getCategoriesMatrixForSpecificCategory(
    category: CategoryName,
  ): CategoryName[][] {
    const orCategories = AND_CATEGORIES.find(categories =>
      categories.includes(category),
    )

    const matrix = this.desktopActivityListStore.buildCategoryFilteringMatrix(
      this.selectedCategories,
    )

    const orCategoriesIndex = matrix.findIndex(categories =>
      categories.some(c => orCategories.includes(c)),
    )

    if (orCategoriesIndex !== -1) {
      matrix[orCategoriesIndex] = [category]
    } else {
      matrix.push([category])
    }

    return matrix
  }

  @action.bound
  public onCheckboxClick(category: CategoryName) {
    const index = this.selectedCategories.indexOf(category)

    if (index === -1) {
      this.selectedCategories.push(category)
    } else {
      this.selectedCategories.splice(index, 1)
    }
  }

  @action.bound
  public onSelectAllClick() {
    this.selectedCategories = categoryNamesList.slice()
  }

  @action.bound
  public onApplyClick() {
    if (!this.selectedCategories) {
      this.selectedCategories = []
    }
    this.state.appliedCategoryFilters = this.selectedCategories.slice()
    this.isShown = false
    if (this.onShowChanged) {
      this.onShowChanged(this.isShown)
    }
  }

  @action.bound
  public toggle() {
    this.selectedCategories = this.state.appliedCategoryFilters.slice()
    this.isShown = !this.isShown
    if (this.onShowChanged) {
      this.onShowChanged(this.isShown)
    }
  }

  public get isActive(): boolean {
    return !!this.state.appliedCategoryFilters.length
  }

  public get isDisabled(): boolean {
    return this.state.activityList.viewMode === ViewModes.Map
  }

  @action.bound
  public onClearAllClick() {
    this.selectedCategories = []
  }

  @action.bound
  public setInitialFilterValues() {
    this.state.appliedCategoryFilters = []
  }

  @action.bound
  public forceUpdate() {
    this.selectedCategories = this.selectedCategories.slice()
  }

  private getActivitiesCountByCategoriesMatrix(
    categories: CategoryName[][],
  ): number {
    const { filterActivitiesWithCategoryFilters } =
      this.desktopActivityListStore

    return filterActivitiesWithCategoryFilters(
      this.filteredActivitiesExcludeCategoryFilter,
      categories,
    ).length
  }

  @computed
  private get filteredActivitiesExcludeCategoryFilter(): Activity[] {
    if (this.desktopActivityListStore.appliedPreset) {
      return this.desktopActivityListStore.appliedPresetActivities
    }

    return this.desktopActivityListStore.filteredActivitiesExcludeFilters()
  }
}
