import * as React from 'react'

import { observer } from 'mobx-react'

import { SitemapItemShapeType } from '~/client/graph'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import SitemapCircleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapCircleProperties'
import SitemapPolyLineProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapPolyLineProperties'
import SitemapRectangleProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapRectangleProperties'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import KonvaCircle from '../konvaElements/KonvaCircle'
import KonvaPolyLine from '../konvaElements/KonvaPolyLine'
import KonvaRectangle from '../konvaElements/KonvaRectangle'

interface IProps {
  item?: MapViewItemBase
  shapeProperties?: SitemapRectangleProperties
  containerWidth: number
  containerHeight: number
}
const { getHEXColor } = ThemeMode
const MAX_PERCENT = 100

@observer
export default class SitemapShape extends React.Component<IProps & any> {
  public render() {
    const { item } = this.props
    const isDisplayed = this.props.shapeProperties || item?.isDisplayed
    const shapeProperties =
      this.props.shapeProperties || item?.sitemapItemProperties?.shapeProperties
    if (!shapeProperties || !isDisplayed || !shapeProperties.isDisplayed) {
      return null
    }
    switch (shapeProperties.type) {
      case SitemapItemShapeType.Circle:
        return this.renderCircle()
      case SitemapItemShapeType.Polyline:
        return this.renderPolyLine()
      case SitemapItemShapeType.Rectangle:
        return this.renderRectangle()
    }
    return null
  }

  private renderCircle() {
    const { containerHeight, containerWidth, item, ...rest } = this.props
    if (!containerHeight || !containerWidth) {
      return null
    }

    const circle = item.sitemapItemProperties
      .shapeProperties as SitemapCircleProperties
    const centerX = (containerWidth * circle.position.x) / MAX_PERCENT
    const centerY = (containerHeight * circle.position.y) / MAX_PERCENT
    const radius = (containerHeight * circle.radius) / MAX_PERCENT

    let angle = 360
    let rotation = 0
    if (circle.isDividedAndDivisionAnglesValid) {
      rotation = circle.divisionStartAngle
      angle = circle.divisionEndAngle - circle.divisionStartAngle
    }

    return (
      <KonvaCircle
        color={getHEXColor(circle.lineColor || item.color)}
        radius={radius}
        lineWidth={circle.lineWidth}
        fillColor={getHEXColor(circle.fillColor || item.color)}
        fillOpacity={circle.fillOpacity}
        angle={angle}
        rotation={rotation}
        x={centerX}
        y={centerY}
        {...rest}
      />
    )
  }

  private renderPolyLine() {
    const { containerHeight, containerWidth, item, ...rest } = this.props

    const polyline = item.sitemapItemProperties
      .shapeProperties as SitemapPolyLineProperties
    const konvaPoints = [].concat(
      ...polyline.points.map(point => [
        (point.x * containerWidth) / MAX_PERCENT,
        (point.y * containerHeight) / MAX_PERCENT,
      ]),
    )

    return (
      <KonvaPolyLine
        points={konvaPoints}
        color={getHEXColor(polyline.lineColor || item.color)}
        lineWidth={polyline.lineWidth}
        fillColor={getHEXColor(polyline.fillColor || item.color)}
        fillOpacity={polyline.fillOpacity}
        isClosed={polyline.isClosed}
        arrowPosition={polyline.arrowPosition}
        {...rest}
      />
    )
  }

  private renderRectangle() {
    const {
      containerHeight,
      containerWidth,
      shapeProperties,
      item,
      mapBearing,
      ...rest
    } = this.props
    const rectangle: SitemapRectangleProperties =
      shapeProperties ||
      (item?.sitemapItemProperties
        ?.shapeProperties as SitemapRectangleProperties)

    const { x, y, width, height } = rectangle.getBoundingBox(
      containerWidth,
      containerHeight,
    )

    return (
      <KonvaRectangle
        x={x}
        y={y}
        width={width}
        height={height}
        color={getHEXColor(rectangle.lineColor || item.color)}
        lineWidth={rectangle.lineWidth}
        fillColor={getHEXColor(rectangle.fillColor || item.color)}
        fillOpacity={rectangle.fillOpacity}
        mapBearing={mapBearing}
        rotation={rectangle.rotation}
        {...rest}
      />
    )
  }
}
