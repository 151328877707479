import * as React from 'react'

import { action, computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList, toggleClass } from 'react-classlist-helper'

import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import SitePermit from '~/client/src/shared/models/Permit'
import InitialState from '~/client/src/shared/stores/InitialState'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import PermitCardFieldsConfigStore, {
  ICardAttribute,
  MAX_NAME_LENGTH,
} from '~/client/src/shared/stores/ui/PermitCardFieldsConfig.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { truncateText } from '~/client/src/shared/utils/textUtils'

import PermitTypeIcon from '../../../PermitTypeIcon/PermitTypeIcon'
import PermitCalendarCardStore, {
  CARD_ROW_HEIGHT,
  MIN_CARD_TITLE_ROW_HEIGHT,
  MIN_CARD_WIDTH,
} from './PermitCalendarCard.store'

import './PermitCalendarCard.scss'

import FormStatusText from '../../../FormStatusText/FormStatusText'

interface IProps {
  permit: SitePermit
  backgroundColor: string
  color: string
  border: string
  isRotated: boolean
  containerHeight: number
  containerWidth: number

  className?: string
  shouldUseMockValues?: boolean

  state?: InitialState
  projectDateStore?: ProjectDateStore
  companiesStore?: CompaniesStore
  permitTypesStore?: PermitTypesStore
  locationAttributesStore?: LocationAttributesStore
}

@inject(
  'state',
  'projectDateStore',
  'companiesStore',
  'permitTypesStore',
  'locationAttributesStore',
)
@observer
export default class PermitCalendarCard extends React.Component<IProps> {
  private permitTitleContainer: HTMLDivElement
  private readonly store: PermitCalendarCardStore
  private readonly cardFieldsStore: PermitCardFieldsConfigStore

  public constructor(props: IProps) {
    super(props)

    this.store = new PermitCalendarCardStore()

    this.cardFieldsStore = new PermitCardFieldsConfigStore(
      props.permit,
      props.state,
      props.projectDateStore,
      props.companiesStore,
      props.permitTypesStore,
    )
  }

  public componentDidUpdate(prevProps: IProps) {
    const { permit } = this.props

    if (permit !== prevProps.permit) {
      this.cardFieldsStore.init(permit)
    }

    this.changeStatusRowAlignment()
  }

  public render() {
    const { permit, backgroundColor, border, isRotated, className } = this.props

    return (
      <div
        className={classList({
          'form-calendar-card full-height full-width px5 brada4': true,
          [className]: !!className,
          opacity7: permit.isDone,
          opacity6: permit.isDenied,
        })}
        style={{ border }}
      >
        <div
          className="event-card-background full-width full-height"
          style={{ backgroundColor }}
        />
        <div
          className={toggleClass('rotated-event-label relative pl4', isRotated)}
        >
          <div className="row" ref={this.setPermitTitleContainerRef}>
            <div
              className={classList({
                'relative row full-width': true,
                wrap: !isRotated,
              })}
            >
              {this.title}
            </div>
          </div>
          <div
            className={classList({
              'no-grow text large grey-light': true,
              'white-space-normal': this.shouldCombineInfoIntoRow,
              col: !this.shouldCombineInfoIntoRow && !isRotated,
            })}
          >
            {!isRotated && this.renderLocationRow()}
            {!isRotated && this.equipmentRow}
            {this.attributeValueElements}
          </div>
        </div>
      </div>
    )
  }

  private get title(): JSX.Element[] {
    const { shouldUseMockValues, permit, permitTypesStore } = this.props
    const { shouldAlignStatusToEnd } = this.store

    const { titleCardFields, getFieldValueById } = this.cardFieldsStore

    return titleCardFields.map((f, index) => {
      const fieldValue = getFieldValueById(f.fieldId, shouldUseMockValues)
      const displayValue = Array.isArray(fieldValue)
        ? fieldValue.join(', ')
        : fieldValue
      const truncatedFieldValue = truncateText(displayValue, MAX_NAME_LENGTH)
      const isStatusField = f.fieldId === FieldIds.STATUS
      const isCompanyField = f.fieldId === FieldIds.COMPANY
      const shouldUseNoGrowClass =
        !isStatusField || !index || (isStatusField && !shouldAlignStatusToEnd)

      return (
        <React.Fragment key={f.fieldId}>
          <div
            className={classList({
              row: true,
              'no-grow': shouldUseNoGrowClass,
            })}
          >
            {!index && this.formTypeIconElement}
            {isStatusField ? (
              <FormStatusText
                status={permit.status}
                workflowStepLevel={permit.workflowStepLevel}
                className="relative text large bold capitalize mr4"
                shouldAlignStatusToEnd={shouldAlignStatusToEnd}
              />
            ) : (
              <>
                <span
                  title={displayValue}
                  className="mr5 text large bold brand-dark"
                >
                  {truncatedFieldValue}
                </span>
              </>
            )}
          </div>
          {isCompanyField && (
            <div
              style={{ minWidth: '120px' }}
              className="text large light mr4 text-ellipsis"
            >
              {permit.getCaption(permitTypesStore)}
            </div>
          )}
        </React.Fragment>
      )
    })
  }

  private get attributeValueElements(): JSX.Element[] {
    const { titleCardFields } = this.cardFieldsStore
    const isTitleRowEmpty = !titleCardFields?.length

    return this.cardAttributeValues.map((value, index) => (
      <React.Fragment key={value.fieldId}>
        {this.renderAttributeField(value, !!index, isTitleRowEmpty && !index)}
      </React.Fragment>
    ))
  }

  private renderLocationRow() {
    const { permit, locationAttributesStore } = this.props

    const dtos = permit.locations
      .map(l => locationAttributesStore.getLocationBySiteLocationObj(l))
      .filter(dto => dto)

    const isTitleRowEmpty = !this.cardFieldsStore.titleCardFields?.length
    return dtos.map((value, index) => (
      <React.Fragment key={value.id}>
        {this.renderAttributeField(
          { fieldValue: value.name, fieldId: value.id, isBold: false },
          !!index,
          isTitleRowEmpty && !index,
        )}
      </React.Fragment>
    ))
  }

  private get equipmentRow(): JSX.Element[] {
    const { permit, locationAttributesStore } = this.props

    const dtos = permit.equipment
      .map(l => locationAttributesStore.getLocationBySiteLocationObj(l))
      .filter(dto => dto)

    return dtos.map((value, index) => (
      <React.Fragment key={value.id}>
        {this.renderAttributeField(
          { fieldValue: value.name, fieldId: value.id, isBold: false },
          !!index,
          false,
        )}
      </React.Fragment>
    ))
  }

  private renderAttributeField = (
    field: ICardAttribute,
    shouldAddDot: boolean,
    shouldAddIcon: boolean,
  ): JSX.Element => {
    const iconContent = shouldAddIcon && this.formTypeIconElement

    if (!this.shouldCombineInfoIntoRow && !this.props.isRotated) {
      return (
        <span
          title={field.fieldValue}
          className={classList({
            'text large grey-light': true,
            bold: field.isBold,
          })}
        >
          {iconContent}
          {field.fieldValue}
        </span>
      )
    }

    return (
      <>
        {iconContent}
        {shouldAddDot && ' • '}
        {field.isBold ? <b>{field.fieldValue}</b> : field.fieldValue}
      </>
    )
  }

  private get formTypeIconElement(): JSX.Element {
    return (
      <PermitTypeIcon
        permitType={this.props.permit.getTypeOfPermitType(
          this.props.permitTypesStore,
        )}
        className="row no-grow mr4 permit-type-icon"
      />
    )
  }

  @action.bound
  private changeStatusRowAlignment() {
    if (this.permitTitleContainer) {
      const { scrollHeight } = this.permitTitleContainer
      this.store.setStatusAlignment(scrollHeight < MIN_CARD_TITLE_ROW_HEIGHT)
    }
  }

  private setPermitTitleContainerRef = (ref: HTMLDivElement) => {
    this.permitTitleContainer = ref
  }

  @computed
  private get cardAttributeValues(): ICardAttribute[] {
    return this.cardFieldsStore.getCardAttributeValues(
      this.props.shouldUseMockValues,
    )
  }

  @computed
  private get shouldCombineInfoIntoRow(): boolean {
    const { containerWidth = 0, containerHeight = 0, permit } = this.props
    const scrollHeight = this.permitTitleContainer?.scrollHeight || 0

    const arrtibutesLength =
      permit.locationsWithEquipment.length + this.cardAttributeValues.length
    const attributesHeight = arrtibutesLength * CARD_ROW_HEIGHT + scrollHeight

    return attributesHeight > containerHeight && containerWidth > MIN_CARD_WIDTH
  }
}
