import { UserAccountPosition } from '~/client/graph'

import Localization from '../localization/LocalizationManager'

export const accountPositionsList = Object.values(UserAccountPosition)

export function getAccountPositionTranslate(value: UserAccountPosition) {
  switch (value) {
    case UserAccountPosition.Field:
      return Localization.translator.accountPositions.field
    case UserAccountPosition.Management:
      return Localization.translator.accountPositions.management
    case UserAccountPosition.Office:
      return Localization.translator.accountPositions.office
    default:
      throw new Error(value + ' is unhandled')
  }
}

export default UserAccountPosition
