import * as React from 'react'

import { inject, observer } from 'mobx-react'

import User from '../../models/User'
import InitialState from '../../stores/InitialState'
import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'
import BaseCompactPopup from '../BaseCompactPopup/BaseCompactPopup'
import CompactUserProfile from '../CompactUserProfile/CompactUserProfile'

interface IProps {
  userId: string

  projectMembersStore?: ProjectMembersStore
  state?: InitialState
}

@inject('projectMembersStore', 'state')
@observer
export default class GlobalUserCard extends React.Component<IProps> {
  public render() {
    const { userId, state } = this.props

    const user = this.props.projectMembersStore.getById(userId)

    return user ? (
      <BaseCompactPopup
        isShown={true}
        title="User Details"
        onHide={state.resetIdOfUserDisplayedOnGlobalCard}
      >
        <CompactUserProfile
          user={user.asJson as User}
          allUsers={[]}
          isPreviewMode={true}
        />
      </BaseCompactPopup>
    ) : null
  }
}
