import { FilterType, LocationType } from '~/client/graph'

import ActivityGroupingType from '../enums/ActivityGroupingType'

const filterTypeToLocationTypeMap = {
  [FilterType.Building]: LocationType.Building,
  [FilterType.Zone]: LocationType.Zone,
  [FilterType.Level]: LocationType.Level,
  [ActivityGroupingType.Equipment]: LocationType.OffloadingEquipment,
  [ActivityGroupingType.VerticalObject]: LocationType.VerticalObject,
  [ActivityGroupingType.Gate]: LocationType.Gate,
  [ActivityGroupingType.Route]: LocationType.Route,
}

export default filterTypeToLocationTypeMap
