import { Stage } from 'konva/types/Stage'
import { LngLatLike, MapRef } from 'react-map-gl'

import { IPosition } from '~/client/graph'

import MapViewItemBase from '../components/SitemapHelpers/models/MapViewItemBase'

export const MAX_RELATIVE_POSITION = 100
export const MIN_RELATIVE_POSITION = 0
const MAP_EDGE_OFFSET = 2

export function sitemapMousePointTo(
  stage: Stage,
  containerWidth: number,
  containerHeight: number,
  pointerPosition?: IPosition,
  scale?: number,
) {
  const currentPointerPosition = pointerPosition || stage.getPointerPosition()
  const stageScale = scale || stage.scaleX()

  const stageWidth = stage.width()
  const stageHeight = stage.height()
  const containerOffsetX = (stageWidth - containerWidth) / 2
  const containerOffsetY = (stageHeight - containerHeight) / 2

  return {
    x: (currentPointerPosition.x - stage.x()) / stageScale - containerOffsetX,
    y: (currentPointerPosition.y - stage.y()) / stageScale - containerOffsetY,
  }
}

export function validatePosition(pos: IPosition): IPosition {
  return {
    x: Math.min(Math.max(MIN_RELATIVE_POSITION, pos.x), MAX_RELATIVE_POSITION),
    y: Math.min(Math.max(MIN_RELATIVE_POSITION, pos.y), MAX_RELATIVE_POSITION),
  }
}

export function clickPosition(
  width: number,
  height: number,
  clickCoords: LngLatLike,
  mapRef: MapRef,
  itemPosition?: IPosition,
  item?: MapViewItemBase,
): IPosition {
  let positionToUse = null
  if (itemPosition) {
    positionToUse = itemPosition
  } else {
    const { position } =
      item.sitemapItemProperties.iconProperties ||
      item.sitemapItemProperties.labelProperties
    positionToUse = position
  }

  const clickPosition = clickCoords && {
    x: mapRef?.getMap?.()?.project(clickCoords).x,
    y: mapRef?.getMap?.()?.project(clickCoords).y,
  }
  const x =
    clickPosition?.x || (width * positionToUse.x) / MAX_RELATIVE_POSITION
  const y =
    clickPosition?.y || (height * positionToUse.y) / MAX_RELATIVE_POSITION

  return {
    x,
    y,
  }
}

export function getCorrectModalOffsetPosition(
  newLeft: number,
  newTop: number,
  maxX: number,
  maxY: number,
  modalWidth: number,
  modalHeight: number,
): IPosition {
  const newRight = newLeft + modalWidth
  const newBottom = newTop + modalHeight

  let left = newLeft
  if (newRight > maxX) {
    left = left - (newRight - maxX) - MAP_EDGE_OFFSET
  }

  let top = newTop
  if (newBottom > maxY) {
    top = top - (newBottom - maxY) - MAP_EDGE_OFFSET
  }

  return {
    x: Math.max(MIN_RELATIVE_POSITION, left),
    y: Math.max(MIN_RELATIVE_POSITION, top),
  }
}
