import { IActivity } from '~/client/graph'
import Day from '~/client/src/shared/models/Day'

import ActivitiesStore from '../stores/domain/Activities.store'

export default class ProjectStatus {
  public static fromActivities(
    activities: IActivity[],
    activitiesStore: ActivitiesStore,
  ) {
    let startDate: Day = null
    let endDate: Day = null

    activities.forEach(activity => {
      const {
        plannedStartDay,
        plannedFinishDay,
        actualStartDay,
        actualFinishDay,
      } = activitiesStore.getActivityParsedDays(activity)

      const didStart = !isNaN(actualStartDay.ms)

      const activityStartDate = didStart ? actualStartDay : plannedStartDay
      const activityEndDate = didStart ? actualFinishDay : plannedFinishDay

      if (!startDate || activityStartDate.ms < startDate.ms) {
        startDate = activityStartDate
      }
      if (!endDate || activityEndDate.ms > endDate.ms) {
        endDate = activityEndDate
      }
    })

    return new ProjectStatus(startDate.date, endDate.date)
  }

  public constructor(public startDate: Date, public endDate: Date) {}
}
