export default {
  samoa: 'Heure standard des Samoa : SST',
  niue: 'Heure de Niue : NUT',
  hawaiiAleuntian: "Heure standard d'Hawaii-Aleutian : HST",
  cookIsland: 'Heure des îles Cook : CKT',
  tahiti: 'Heure de Tahiti : TAHT',
  marquesas: 'Heure des Marquises : MART',
  alaska: "Heure standard de l'Alaska : AKST",
  gambier: 'Heure de Gambier : GAMT',
  pacific: 'Heure standard du Pacifique : PST',
  pitcairn: 'Heure standard de Pitcairn : PST',
  mountain: 'Heure standard des montagnes : MST',
  mexicanPacific: 'Heure standard du Pacifique mexicain',
  centralStandard: 'Heure standard centrale : CST',
  centralMexicoStandard: 'Heure standard centrale (Mexique) : CST',
  galapagos: 'Heure des Galapagos : GALT',
  easterIsland: "Heure standard de l'île de Pâques : EAST",
  acre: "Heure de l'Acre : ACT",
  ecuador: "Heure de l'Équateur : ECT",
  peru: 'Heure du Pérou : PET',
  easternStandard: "Heure standard de l'Est : EST",
  colombia: 'Heure de Colombie : COT',
  cuba: 'Heure standard de Cuba : CST',
  guyana: 'Heure du Guyana : GYT',
  bolivia: 'Heure de Bolivie : BOT',
  amazon: "Heure de l'Amazonie : AMT",
  venezuela: 'Heure standard du Venezuela : VET',
  atlantic: "Heure standard de l'Atlantique : AST",
  chile: 'Heure standard du Chili : CLT',
  newfoundland: 'Heure standard de Terre-Neuve : NST',
  pierre: 'Heure standard de Saint-Pierre-et-Miquelon : PMST',
  uruguay: "Heure de l'Uruguay : UYT",
  falkland: 'Heure des îles Falkland : FKT',
  paraguay: 'Heure du Paraguay : PYT',
  brasilia: 'Heure de Brasília : BRT',
  suriname: 'Heure du Suriname : SRT',
  argentina: "Heure de l'Argentine : ART",
  frenchGuiana: 'Heure de la Guyane française : GFT',
  westGreenland: 'Heure du Groenland occidental : WGT(GMT -3)',
  rothera: 'Heure de Rothera : ROTT',
  palmer: 'Heure de Palmer',
  puntaArenas: 'Heure de Punta Arenas',
  southGeorgia: 'Heure de la Géorgie du Sud : GST',
  fernandoDeNoronha: 'Heure de Fernando de Noronha : FNT',
  capeVerde: 'Heure du Cap-Vert : CVT',
  azores: 'Heure standard des Açores : AZOT',
  eastGreenland: 'Heure du Groenland oriental : EGT',
  coordinatedUniversal: 'Temps universel coordonné : UTC',
  greenwichMean: 'Temps moyen de Greenwich : GMT',
  greenwichMeanSaoTome: 'Temps moyen de Greenwich (Sao Tomé) : GMT',
  westernEuropean: "Heure de l'Europe de l'Ouest : WET",
  westernEuropeanAfrica: "Heure de l'Europe de l'Ouest (Afrique) : WET",
  westAfrica: "Heure de l'Afrique de l'Ouest : WAT",
  centralEuropean: "Heure de l'Europe centrale : CET",
  centralAfricaTime: "Heure de l'Afrique centrale : CAT",
  southAfrica: "Heure standard de l'Afrique du Sud",
  easternEuropean: "Heure de l'Europe de l'Est : EET",
  easternEuropeanBeirutGaza:
    "Heure de l'Europe de l'Est (Beyrouth, Gaza) : EET",
  easternEuropeanDamascusAmman:
    "Heure de l'Europe de l'Est (Damas, Amman) : EET",
  israel: "Heure standard d'Israël : IST",
  eastAfrica: "Heure de l'Afrique de l'Est : EAT",
  turkey: 'Heure de la Turquie : TRT',
  arabian: 'Heure standard arabe : AST',
  moscow: 'Heure standard de Moscou : MSK',
  syowa: 'Heure de Syowa',
  volgograd: 'Heure standard de Volgograd',
  iran: "Heure standard de l'Iran : IRST",
  gulf: 'Heure standard du Golfe : GST',
  azerbaijan: "Heure de l'Azerbaïdjan : AZT",
  armenia: "Heure de l'Arménie : AMT",
  astrakhan: "Heure d'Astrakhan",
  ulyanovsk: "Heure d'Oulianovsk",
  samara: 'Heure de Samara : SAMT',
  georgia: 'Heure standard de la Géorgie',
  mauritius: 'Heure de Maurice : MUT',
  seychelles: 'Heure des Seychelles : SCT',
  reunion: 'Heure de la Réunion : RET',
  afghanistan: "Heure de l'Afghanistan : AFT",
  yekaterinburg: 'Heure de Iekaterinbourg : YEKT',
  pakistan: 'Heure standard du Pakistan : PKT',
  tajikistan: 'Heure du Tadjikistan',
  uzbekistan: "Heure standard de l'Ouzbékistan",
  westKazakhstan: 'Heure du Kazakhstan occidental',
  turkmenistan: 'Heure du Turkménistan : TMT',
  frenchSouthern: 'Heure des Terres australes et antarctiques françaises : TFT',
  maldives: 'Heure des Maldives : MVT',
  mawson: 'Heure de Mawson : MAWT',
  india: "Heure standard de l'Inde : IST",
  nepal: 'Heure du Népal : NPT',
  bhutan: 'Heure du Bhoutan : BTT',
  omsk: "Heure standard d'Omsk : OMST",
  kyrgyzstan: 'Heure du Kirghizistan : KGT',
  bangladesh: 'Heure standard du Bangladesh : BST',
  indianOcean: "Heure de l'océan Indien : IOT",
  vostok: 'Heure de Vostok : VOST',
  urumqiKashgar: "Heure d'Urumqi/Kashgar",
  eastKazakhstan: 'Heure du Kazakhstan oriental',
  myanmar: 'Heure du Myanmar : MMT',
  cocosIsland: 'Heure des îles Cocos : CCT',
  westernIndonesia: "Heure de l'Indonésie occidentale : WIB",
  indochina: "Heure de l'Indochine : ICT",
  hovd: 'Heure de Hovd : HOVT',
  krasnoyarsk: 'Heure de Krasnoïarsk : KRAT',
  novosibirsk: 'Heure standard de Novossibirsk',
  christmasIsland: "Heure de l'île Christmas : CXT",
  davis: 'Heure de Davis : DAVT',
  barnaulTomsk: 'Heure de Barnaul/Tomsk',
  irkutsk: "Heure d'Irkoutsk : IRKT",
  hongKong: 'Heure de Hong Kong : HKT',
  singapore: 'Heure de Singapour : SGT',
  malaysia: 'Heure de la Malaisie : MYT',
  australianWestern: "Heure standard de l'Ouest australien : AWST",
  chinaStandard: 'Heure standard de la Chine : CST',
  centralIndonesia: "Heure de l'Indonésie centrale : WITA",
  philippine: 'Heure des Philippines : PHT',
  bruneiDarussalam: 'Heure du Brunei Darussalam : BNT',
  taipei: 'Heure standard de Taipei',
  ulaanbaatar: "Heure standard d'Oulan-Bator",
  pyongyang: 'Heure de Pyongyang : PYT',
  australianCentralWestern: 'Heure standard du centre-ouest australien : ACWST',
  easternIndonesia: "Heure de l'Indonésie orientale : WIT",
  japan: 'Heure standard du Japon : JST',
  korea: 'Heure standard de la Corée : KST',
  eastTimor: 'Heure du Timor oriental : TLT',
  yakutsk: 'Heure de Iakoutsk : YAKT',
  palau: 'Heure de Palau : PWT',
  australianCentralStandard: 'Heure standard du centre australien : ACST',
  australianEasternStandard: "Heure standard de l'Est australien : AEST",
  vladivostok: 'Heure de Vladivostok : VLAT',
  chamorro: 'Heure standard de Chamorro : ChST',
  papuaNewGuinea: 'Heure de la Papouasie-Nouvelle-Guinée : PGT',
  chuuk: 'Heure de Chuuk',
  dumontdUrville: "Heure de Dumont-d'Urville",
  lordHowe: 'Heure standard de Lord Howe : LHST',
  magadan: 'Heure de Magadan : MAGT',
  solomonIslands: 'Heure des îles Salomon : SBT',
  kosrae: 'Heure de Kosrae : KOST',
  ponhpei: 'Heure standard de Pohnpei : PONT',
  newCaledonia: 'Heure de la Nouvelle-Calédonie : NCT',
  norfolk: 'Heure de Norfolk : NFT',
  vanuatu: 'Heure du Vanuatu : VUT',
  casey: 'Heure de Casey : CAST',
  srednekolymskBougainville: 'Heure de Srednekolymsk/Bougainville',
  sakhalin: 'Heure standard du Sakhaline',
  marshallIslands: 'Heure des îles Marshall : MHT',
  petropavlovskKamchatski: 'Heure de Petropavlovsk-Kamtchatski : PETT',
  tuvalu: 'Heure des Tuvalu : TVT',
  gilbertIslands: 'Heure des îles Gilbert : GILT',
  nauru: 'Heure de Nauru : NRT',
  wakeIsland: "Heure de l'île Wake : WAKT",
  wallisAndFutuna: 'Heure de Wallis-et-Futuna : WFT',
  anadyr: "Heure standard d'Anadyr",
  fiji: 'Heure standard des Fidji : FJT',
  newZealand: 'Heure standard de la Nouvelle-Zélande : NZST',
  chatham: 'Heure standard de Chatham : CHAST',
  phoenixIslands: 'Heure des îles Phoenix : PHOT',
  tokelau: 'Heure de Tokelau : TKT',
  tonga: 'Heure de Tonga : TOT',
  apia: "Heure standard d'Apia",
  lineIslands: 'Heure des îles de la Ligne : LINT',
}
