import { action, computed, observable } from 'mobx'

import {
  IAddressBounds,
  ILatLng,
  ISitemapSpecificItemData,
} from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import BaseListStore, {
  DEFAULT_ID_KEY,
} from '~/client/src/desktop/stores/ui/BaseList.store'
import {
  ILWFCCategory,
  ILWFCColumn,
  ILWFCRow,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import HierarchyNode from '~/client/src/shared/models/HierarchyNode'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import { sortCaseInsensitive } from '~/client/src/shared/utils/collections'

import MapViewItemsSetupStore, {
  maturixStations,
  projectOverviewMaps,
  siteLogistics,
} from './MapViewItemsSetup.store'

const SEPARATOR = '|'

export enum DataKeys {
  CHECKBOX = 'checkbox',
  id = 'id',
  View = 'view',
  PublishTo = 'publishTo',
  Tags = 'tags',
  Objects = 'objects',
  None = 'none',
}

export enum MapViewType {
  GlobeView = 'globeView',
  Whiteboard = 'whiteboard',
}

export interface MapView {
  id: string
  name: string
  type: MapViewType
  items: ISitemapSpecificItemData[]
  filledImage: string
  bounds?: IAddressBounds
  center?: ILatLng
  zoom?: number
  pitch?: number
  bearing?: number
  isSatelliteMode?: boolean
  isProjectOverviewMap?: boolean
  isGlobe: boolean
}

export enum MenuViewMode {
  globes = 'globes',
  whiteboards = 'whiteboards',
}
export const site = 'Site'

const DATA_ROW_HEIGHT = 90
const FILTER_KEYS = ['name']

export default class MapViewsListStore extends BaseListStore<MapView> {
  @observable public isSecondaryMode: boolean = false
  @observable public selectedViewMode: MenuViewMode = MenuViewMode.globes
  @observable public selectedGlobeId: string = null
  @observable public isDialogOpened: boolean = false
  @observable public tagFilters: string[] = []
  @observable public objectFilters: string[] = []
  @observable public isSiteCollapsed: boolean = false
  @observable public scrollToRow: number = -1

  public readonly columns: ILWFCColumn[] = [
    {
      translatorKey: KnownTranslatorKeys.viewName,
      dataKey: DataKeys.View,
      width: 500,
    },
    {
      translatorKey: KnownTranslatorKeys.publishTo,
      dataKey: DataKeys.PublishTo,
      width: 150,
    },
    {
      translatorKey: KnownTranslatorKeys.numObjects,
      dataKey: DataKeys.Objects,
      width: 90,
    },
    {
      translatorKey: KnownTranslatorKeys.none,
      dataKey: DataKeys.None,
      width: 160,
    },
  ]

  public constructor(
    private state: DesktopInitialState,
    private readonly globeViewsStore: GlobeViewsStore,
    private readonly sitemapsStore: SitemapsStore,
    private readonly sitemapItemsStore: SitemapItemsStore,
    private readonly mapViewItemsSetupStore: MapViewItemsSetupStore,
    private readonly locationAttributesStore: LocationAttributesStore,
  ) {
    super(
      state.globeFilters,
      () => [...this.globeMapViews, ...this.whiteboardMapViews],
      FILTER_KEYS,
      DEFAULT_ID_KEY,
      true,
      true,
    )
  }

  @computed
  private get globeMapViews(): MapView[] {
    return this.globeViewsStore.list.map(
      g =>
        ({
          id: g.id,
          name: g.name,
          items: g.items,
          filledImage: g.filledImage,
          bounds: g.bounds,
          center: g.center,
          zoom: g.zoom,
          pitc: g.pitch,
          bearing: g.bearing,
          isSatelliteMode: g.isSatelliteMode,
          isProjectOverviewMap: g.isProjectOverviewGlobe,
          type: MapViewType.GlobeView,
          isGlobe: true,
        } as MapView),
    )
  }

  @computed
  private get whiteboardMapViews(): MapView[] {
    return this.sitemapsStore.whiteboards.map(
      s =>
        ({
          id: s.id,
          name: s.name,
          items: s.items,
          filledImage: s.filledImage,
          bounds: s.bounds,
          center: s.center,
          zoom: s.zoom,
          pitch: s.pitch,
          bearing: s.bearing,
          isSatelliteMode: false,
          isProjectOverviewMap: s.isProjectOverviewMap,
          type: MapViewType.Whiteboard,
          isGlobe: false,
        } as MapView),
    )
  }

  @computed
  public get tableWidth(): number {
    const columnsWidth = this.columns.reduce((sum, column) => {
      return sum + column.width
    }, 0)

    return columnsWidth
  }

  public selectGlobe = (id: string) => {
    this.resetSelection()
    if (this.selectedGlobeId === id) {
      this.selectedGlobeId = null
    } else {
      this.selectedGlobeId = id
      this.setInstanceSelection(id, true)
    }
  }

  public get selectedGlobe() {
    return (
      this.selectedGlobeId &&
      this.globeViewsStore.byId.get(this.selectedGlobeId)
    )
  }

  @computed
  public get filteredCollection() {
    return this.getFilteredCollectionExcludeFilter().sort((a, b) =>
      a.name.localeCompare(b.name),
    )
  }

  @action.bound
  public toggleRowCheckbox(objectId: string) {
    this.toggleInstance(objectId)
  }

  @action.bound
  public toggleIsSiteCollapsed() {
    this.isSiteCollapsed = !this.isSiteCollapsed
  }

  public isRowSelected(row: ILWFCRow) {
    return (
      row.data?.[DataKeys.View] && this.selection.get(row.data[DataKeys.id])
    )
  }

  public isInstanceSelected = (instanceId: string) => {
    return this.selection.get(instanceId)
  }

  public toggleDialog = () => {
    this.isDialogOpened = !this.isDialogOpened
  }

  public getTableWidth = (): number => {
    return this.columns.reduce((acc, { width }) => acc + width, 0)
  }

  protected formatCategoryId(categoryId: any, view: MapView) {
    return Object.keys(this.categoryToInstancesMap).find(basemap => {
      return this.categoryToInstancesMap[basemap].includes(view)
    })
  }

  @computed
  protected get categoryToInstancesMap() {
    return {
      [MapViewType.GlobeView]: this.filteredCollection.filter(
        i => i.type === MapViewType.GlobeView,
      ),
      [MapViewType.Whiteboard]: this.filteredCollection.filter(
        i => i.type === MapViewType.Whiteboard,
      ),
    }
  }

  protected sortCategories(keys: string[]): string[] {
    return sortCaseInsensitive(keys).sort((a: string, b: string) => {
      return a.toLowerCase().localeCompare(b.toLowerCase())
    })
  }

  protected createCategoryRow(categoryId: string, count: number): ILWFCRow {
    const categoryInstances = this.categoryToInstancesMap[categoryId]

    const total = categoryInstances.length
    const completed = categoryInstances.filter(d => d.isDone).length

    const category: ILWFCCategory = {
      categoryId,
      shortCategoryLabel: this.getCategoryLabelById(categoryId),
      categoryLabel: `${this.getCategoryLabelById(categoryId)} (${count})`,
      isChecked: categoryInstances.every(
        inst =>
          !this.canSelectInstance(inst) || this.selection.get(inst[this.idKey]),
      ),
      instancesInfo: `${completed}/${total}`,
    }

    return { category, data: {}, height: 40, level: 0 }
  }

  @computed
  public get rows(): ILWFCRow[] {
    return this.selectedViewMode === MenuViewMode.globes
      ? this.primaryRows
      : this.secondaryRows
  }

  @computed
  public get primaryRows(): ILWFCRow[] {
    return this.toBandTreeNodeRows(true)
  }

  @computed
  public get secondaryRows(): ILWFCRow[] {
    return this.toBandTreeNodeRows(false)
  }

  // sitemaps with no LBS tag should be placed after first building OR if there're no building under 'site'
  protected toBandTreeNodeRows(isPrimaryRows: boolean): ILWFCRow[] {
    const { name: activeProjectName } = this.state.activeProject
    const rows: ILWFCRow[] = [{ data: {} }]
    let band
    if (isPrimaryRows) {
      band = this.createTreeNodeCategoryRow(
        site,
        activeProjectName,
        null,
        0,
        this.filteredCollection.filter(m => m.isGlobe),
      )
    } else {
      band = this.createTreeNodeCategoryRow(
        site,
        activeProjectName,
        null,
        0,
        this.filteredCollection.filter(m => !m.isGlobe),
      )
    }
    rows.push(band)

    if (!this.collapsedCategories.get(site)) {
      this.mapViewItemsSetupStore.sitemapHierarchyTree.forEach(node => {
        if (
          node.nodeId === projectOverviewMaps &&
          !this.collapsedCategories.get(projectOverviewMaps)
        ) {
          rows.push(...this.setNodes(isPrimaryRows, node, 1, ''))
        }
        if (
          node.nodeId === siteLogistics &&
          !this.collapsedCategories.get(siteLogistics)
        ) {
          rows.push(...this.setNodes(isPrimaryRows, node, 0, ''))
        }
        if (
          node.nodeId === maturixStations &&
          !this.collapsedCategories.get(maturixStations)
        ) {
          rows.push(...this.setNodes(isPrimaryRows, node, 0, ''))
        }
        if (
          node.nodeId !== projectOverviewMaps &&
          node.nodeId !== siteLogistics &&
          node.nodeId !== maturixStations
        ) {
          rows.push(...this.setNodes(isPrimaryRows, node, 1, ''))
        }
      })
    }

    return rows
  }

  // as we have 7 different types in sitemap lbs structure it would be more performant to traverse through already created hierarchy
  private setNodes(
    isPrimaryRows: boolean,
    node: HierarchyNode,
    level: number,
    parentName?: string,
  ) {
    const dataObject: LocationBase = node.item?.dataObject
    const mapViewId = isPrimaryRows
      ? MapViewType.GlobeView
      : MapViewType.Whiteboard
    const locationCode: string = dataObject
      ? `${dataObject.id}${SEPARATOR}${dataObject.type}${SEPARATOR}${mapViewId}`
      : `${mapViewId}${SEPARATOR}${node.nodeId}`

    const treeNodeObjs: MapView[] = this.locationMapView[locationCode] || []

    const categoryId: string = parentName + locationCode
    const isExpanded: boolean = !this.collapsedCategories.get(categoryId)

    const rows: ILWFCRow[] = []
    const children: ILWFCRow[] = []
    let bandItems: ILWFCRow[] = []

    if (node.hasChildren) {
      node.children.forEach(child => {
        children.push(
          ...this.setNodes(isPrimaryRows, child, level + 1, categoryId),
        )
      })
    }

    if (treeNodeObjs?.length || children.length > 0) {
      if (dataObject) {
        const childrenData: MapView[] = children
          .filter(child => !child.category)
          .map(child => child.data[DataKeys.View])

        const band = this.createTreeNodeCategoryRow(
          categoryId,
          dataObject.name,
          dataObject,
          level,
          [...treeNodeObjs, ...childrenData],
        )
        rows.push(band)
      }

      if (treeNodeObjs?.length) {
        bandItems = this.toRows(
          treeNodeObjs,
          null,
          dataObject ? level + 1 : level,
        )
      }

      if (isExpanded) {
        rows.push(...children)
        rows.push(...bandItems)
      }
    }

    return rows
  }

  @computed
  private get locationMapView(): { [locationCode: string]: MapView[] } {
    const map = {
      [`${MapViewType.GlobeView}${SEPARATOR}${projectOverviewMaps}`]: [],
      [`${MapViewType.Whiteboard}${SEPARATOR}${projectOverviewMaps}`]: [],
      [`${MapViewType.GlobeView}${SEPARATOR}${siteLogistics}`]: [],
      [`${MapViewType.Whiteboard}${SEPARATOR}${siteLogistics}`]: [],
      [maturixStations]: [],
    }

    this.filteredCollection.forEach(mapView => {
      const locationPrefix = mapView.isGlobe
        ? MapViewType.GlobeView
        : MapViewType.Whiteboard
      if (mapView.isProjectOverviewMap) {
        map[`${locationPrefix}${SEPARATOR}${projectOverviewMaps}`].push(mapView)
        return
      }
      const location =
        this.allLocations.find(l =>
          mapView.isGlobe
            ? l.isGlobeAssigned(mapView.id)
            : l.isSitemapAssigned(mapView.id),
        ) || this.locationAttributesStore.buildingsStore.firstBuilding
      if (location) {
        const locationCode = `${location.id}${SEPARATOR}${location.type}${SEPARATOR}${mapView.type}`
        if (map[locationCode]) {
          map[locationCode].push(mapView)
        } else {
          map[locationCode] = [mapView]
        }
      } else {
        map[`${locationPrefix}${SEPARATOR}${siteLogistics}`].push(mapView)
      }
    })

    return map
  }

  @computed
  private get allLocations() {
    return [
      ...this.locationAttributesStore.buildingsStore.list.filter(
        b => b.assignedSitemaps?.length || b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.zonesStore.list.filter(
        b => b.assignedSitemaps?.length || b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.gatesStore.list.filter(
        b => b.assignedSitemaps?.length || b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.routesStore.list.filter(
        b => b.assignedSitemaps?.length || b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.offloadingEquipmentsStore.list.filter(
        b => b.assignedSitemaps?.length || b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.levelsStore.list.filter(
        b => b.assignedSitemaps?.length || b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.areasStore.list.filter(
        b => b.assignedSitemaps?.length || b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.stagingsStore.list.filter(
        b => b.assignedSitemaps?.length || b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.interiorDoorsStore.list.filter(
        b => b.assignedSitemaps?.length || b.assignedGlobes?.length,
      ),
      ...this.locationAttributesStore.interiorPathsStore.list.filter(
        b => b.assignedSitemaps?.length || b.assignedGlobes?.length,
      ),
    ]
  }

  protected toRows(
    views: MapView[],
    _category?: string,
    level: number = 0,
  ): ILWFCRow[] {
    return views.map(view => {
      const { id, items } = view
      const displayedItems = Object.values(items).filter(
        item => !item.isHidden && this.isItemVisible(item.sitemapItemId),
      )

      return {
        data: {
          [DataKeys.id]: id,
          [DataKeys.CHECKBOX]: this.selection.get(id),
          [DataKeys.PublishTo]: view,
          [DataKeys.View]: view,
          [DataKeys.Tags]: view,
          [DataKeys.Objects]: {
            view,
            items: displayedItems,
          },
          [DataKeys.None]: view,
        },
        level,
        height: DATA_ROW_HEIGHT,
      }
    })
  }

  protected getCategoryLabelById(categoryId: string): string {
    switch (categoryId) {
      case MapViewType.GlobeView:
        return Localization.translator.globeViews
      case MapViewType.Whiteboard:
        return Localization.translator.whiteboards
      default:
        return categoryId
    }
  }

  private isItemVisible(itemId: string) {
    return !!this.sitemapItemsStore.byId.get(itemId)
  }

  @action.bound
  public setViewMode(mode: MenuViewMode): void {
    this.selectedViewMode = mode
  }
}
