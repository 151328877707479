import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { Loader } from '~/client/src/shared/components/Loader'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import SitemapsSettingsStore from './SitemapsSettings.store'
import ThresholdsSetup from './components/ThresholdsSetup'

import './SitemapsSettings.scss'

interface ISitemapsSettings {
  eventsStore?: DesktopEventStore
}

@inject('eventsStore')
@observer
export default class SitemapsSettings extends React.Component<ISitemapsSettings> {
  private readonly store: SitemapsSettingsStore

  public constructor(props: ISitemapsSettings) {
    super(props)

    this.store = new SitemapsSettingsStore(this.props.eventsStore)
  }

  public render() {
    const { isLoading } = this.store

    return (
      <form className="setup-form px30">
        <div className="setup-form-header bb-light-grey mb15">
          <div>{Localization.translator.sitemaps.toUpperCase()}</div>
          <div className="text light large mt5">
            {Localization.translator.sitemapsDescriptions.title}
          </div>
        </div>
        {isLoading ? (
          <Loader hint={Localization.translator.loading} />
        ) : (
          <div className="setup-form-body">
            <ThresholdsSetup store={this.store} />
          </div>
        )}
      </form>
    )
  }
}
