import * as React from 'react'

import { inject, observer } from 'mobx-react'

import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import ProjectSetUpPageStore from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPage.store'
import QrCodesStore from '~/client/src/shared/components/QRCodes/QRCodes.store'
import QRCodesContent from '~/client/src/shared/components/QRCodes/components/QRCodesContent'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ScannersStore from '~/client/src/shared/stores/domain/Scanners.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import { Header, Wrapper } from '../SetupPageLayout/SetupPageLayout'

interface IProps {
  projectSetUpPageStore: ProjectSetUpPageStore

  eventsStore?: DesktopEventStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  scannersStore?: ScannersStore
}

@inject(
  'eventsStore',
  'userProjectsStore',
  'projectMembersStore',
  'scannersStore',
)
@observer
export default class QRCodes extends React.Component<IProps> {
  private qrCodesStore: QrCodesStore = null

  public constructor(props: IProps) {
    super(props)

    this.qrCodesStore = new QrCodesStore(
      props.eventsStore,
      props.scannersStore,
      props.projectMembersStore,
      props.userProjectsStore,
    )
  }

  public render() {
    const containerClassName = `col scrollable qr-codes ${this.qrCodesStore.className}`
    return (
      <Wrapper>
        <Header className="row" title={Localization.translator.scanStations} />

        <div className={containerClassName}>
          <QRCodesContent
            store={this.qrCodesStore}
            isFullscreenAllowed={false}
          />
        </div>
      </Wrapper>
    )
  }
}
