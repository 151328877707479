import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'

import { IPermitTypeField } from '~/client/graph'

import IPermitFieldsStore from '../../../../models/IPermitFieldsStore'

interface IProps {
  typeField: IPermitTypeField

  fieldsStore: IPermitFieldsStore
}

const ICON_SIZE = 18

@observer
export default class PermitFormSectionField extends React.Component<IProps> {
  public render() {
    const { typeField, fieldsStore } = this.props
    const icon = fieldsStore.isSectionHidden(typeField?.id)
      ? IconNames.CARET_RIGHT
      : IconNames.CARET_DOWN

    return (
      <div
        className="pointer row text huge bold lp015 py3 brada4 px16 bg-palette-brand-lighter my12 no-select"
        onClick={this.onSectionClick}
      >
        <Icon icon={icon} size={ICON_SIZE} className="no-grow mr5" />
        {typeField.caption}
      </div>
    )
  }

  private onSectionClick = () => {
    const { fieldsStore, typeField } = this.props
    fieldsStore.toggleSection(typeField.id)
  }
}
