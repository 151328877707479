import { AuthErrorCodes } from 'firebase/auth'
import { action, observable } from 'mobx'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import AuthenticationStore from '~/client/src/shared/stores/domain/Authentication.store'

import EventsStore from '../../stores/EventStore/Events.store'
import { SAVE_PASSWORD_SUCCESS } from '../../stores/EventStore/eventConstants'
import isWeakPassword from '../../utils/isWeakPassword'

export default class SavePasswordStore {
  @observable public shouldShowLoader: boolean = false
  @observable public password: string
  @observable public confirmationPassword: string
  @observable private passwordErrorMessage: string

  public constructor(
    private readonly authStore: AuthenticationStore,
    private readonly eventsStore: EventsStore,
  ) {}

  private async savePassword() {
    try {
      await this.authStore.savePassword(this.password)
    } catch (error) {
      console.error('Error occurred: ', error.message)

      if (error.code === AuthErrorCodes.WEAK_PASSWORD) {
        this.setError(Localization.translator.passwordErrors.weakPassword)
        return
      }

      throw error
    }
  }

  public get passwordChangeError() {
    if (this.passwordErrorMessage) {
      return this.passwordErrorMessage
    }

    if (!this.password) {
      return Localization.translator.passwordErrors.empty
    }

    if (isWeakPassword(this.password)) {
      return Localization.translator.passwordErrors.weakPassword
    }

    if (this.password !== this.confirmationPassword) {
      return Localization.translator.passwordErrors.doNotMatch
    }

    return null
  }

  @action.bound
  public async handleSetPassword() {
    if (this.passwordChangeError) {
      return
    }

    this.shouldShowLoader = true

    try {
      await this.savePassword()
    } catch (e) {
      this.setError(e.message)
      return
    } finally {
      this.shouldShowLoader = false
    }

    this.eventsStore.dispatch(SAVE_PASSWORD_SUCCESS)
  }

  public setPasswords(password: string, confirmationPassword: string) {
    this.password = password
    this.confirmationPassword = confirmationPassword
  }

  public resetError() {
    this.passwordErrorMessage = null
  }

  private setError(message: string) {
    this.passwordErrorMessage =
      message || Localization.translator.somethingWentWrongDuringAPIInteraction
  }
}
