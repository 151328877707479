import { action, observable } from 'mobx'

import { IFullscreenPreviewComment } from '~/client/src/shared/components/FileFullscreenPreview/FileFullscreenPreview.store'
import IFilePreviewProperties from '~/client/src/shared/interfaces/IFilePreviewProperties'

// localization: no display text to translate

export default class DesktopFullscreenPreviewStore {
  @observable public shouldShowFullscreenImagePreview: boolean = false
  @observable public qrCodeLink: string
  @observable public allFiles: IFilePreviewProperties[] = []
  @observable public allComments: IFullscreenPreviewComment[] = []
  @observable public currentFileIndex: number = null

  @action.bound
  public showFullscreenPreview(
    files?: IFilePreviewProperties[],
    currentFileIndex?: number,
    comments?: IFullscreenPreviewComment[],
    qrCodeLink?: string,
  ) {
    this.currentFileIndex = currentFileIndex
    this.allFiles = files
    this.allComments = comments
    this.qrCodeLink = qrCodeLink
    this.shouldShowFullscreenImagePreview = true
  }

  @action.bound
  public setFileIndex(newIndex: number) {
    this.currentFileIndex = newIndex
  }

  @action.bound
  public hideFullscreenPreview() {
    this.shouldShowFullscreenImagePreview = false
  }
}
