import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IActivityLocationFieldsFragment = Pick<
  Types.IActivityLocation,
  | 'id'
  | 'activityCodeId'
  | 'projectId'
  | 'locationObjectId'
  | 'locationObjectType'
  | 'createdAt'
  | 'updatedAt'
>

export type IActivityLocationsByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IActivityLocationsByProjectIdQuery = {
  activityLocations?: Types.Maybe<{
    data: Array<IActivityLocationFieldsFragment>
  }>
}

export type ISaveActivityLocationMutationVariables = Types.Exact<{
  activityLocation: Types.IActivityLocationInput
}>

export type ISaveActivityLocationMutation = {
  saveActivityLocation?: Types.Maybe<IActivityLocationFieldsFragment>
}

export type ISaveManyActivityLocationMutationVariables = Types.Exact<{
  activityLocations:
    | Array<Types.Maybe<Types.IActivityLocationInput>>
    | Types.Maybe<Types.IActivityLocationInput>
}>

export type ISaveManyActivityLocationMutation = {
  saveManyActivityLocations?: Types.Maybe<
    Array<Types.Maybe<IActivityLocationFieldsFragment>>
  >
}

export type IDeleteActivityLocationMutationVariables = Types.Exact<{
  activityLocationId: Types.Scalars['ObjectId']
}>

export type IDeleteActivityLocationMutation = Pick<
  Types.IMutation,
  'deleteActivityLocation'
>

export type IListenActivityLocationsByProjectIdSubscriptionVariables =
  Types.Exact<{
    projectId: Types.Scalars['ObjectId']
  }>

export type IListenActivityLocationsByProjectIdSubscription = {
  activityLocation?: Types.Maybe<
    Pick<Types.IActivityLocationChangeEvent, 'id'> & {
      item?: Types.Maybe<IActivityLocationFieldsFragment>
    }
  >
}

export const ActivityLocationFieldsFragmentDoc = gql`
  fragment ActivityLocationFields on ActivityLocation {
    id
    activityCodeId
    projectId
    locationObjectId
    locationObjectType
    createdAt
    updatedAt
  }
`
export const ActivityLocationsByProjectIdDocument = gql`
  query ActivityLocationsByProjectId($projectId: ObjectId!) {
    activityLocations(
      projectId: $projectId
      offset: 0
      limit: 1000
      sort: { by: "id", order: ASCENDING }
    ) {
      data {
        ...ActivityLocationFields
      }
    }
  }
  ${ActivityLocationFieldsFragmentDoc}
`

/**
 * __useActivityLocationsByProjectIdQuery__
 *
 * To run a query within a React component, call `useActivityLocationsByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityLocationsByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityLocationsByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useActivityLocationsByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IActivityLocationsByProjectIdQuery,
    IActivityLocationsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IActivityLocationsByProjectIdQuery,
    IActivityLocationsByProjectIdQueryVariables
  >(ActivityLocationsByProjectIdDocument, options)
}
export function useActivityLocationsByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IActivityLocationsByProjectIdQuery,
    IActivityLocationsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IActivityLocationsByProjectIdQuery,
    IActivityLocationsByProjectIdQueryVariables
  >(ActivityLocationsByProjectIdDocument, options)
}
export type ActivityLocationsByProjectIdQueryHookResult = ReturnType<
  typeof useActivityLocationsByProjectIdQuery
>
export type ActivityLocationsByProjectIdLazyQueryHookResult = ReturnType<
  typeof useActivityLocationsByProjectIdLazyQuery
>
export type ActivityLocationsByProjectIdQueryResult = Apollo.QueryResult<
  IActivityLocationsByProjectIdQuery,
  IActivityLocationsByProjectIdQueryVariables
>
export const SaveActivityLocationDocument = gql`
  mutation SaveActivityLocation($activityLocation: ActivityLocationInput!) {
    saveActivityLocation(activityLocation: $activityLocation) {
      ...ActivityLocationFields
    }
  }
  ${ActivityLocationFieldsFragmentDoc}
`
export type ISaveActivityLocationMutationFn = Apollo.MutationFunction<
  ISaveActivityLocationMutation,
  ISaveActivityLocationMutationVariables
>

/**
 * __useSaveActivityLocationMutation__
 *
 * To run a mutation, you first call `useSaveActivityLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveActivityLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveActivityLocationMutation, { data, loading, error }] = useSaveActivityLocationMutation({
 *   variables: {
 *      activityLocation: // value for 'activityLocation'
 *   },
 * });
 */
export function useSaveActivityLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveActivityLocationMutation,
    ISaveActivityLocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveActivityLocationMutation,
    ISaveActivityLocationMutationVariables
  >(SaveActivityLocationDocument, options)
}
export type SaveActivityLocationMutationHookResult = ReturnType<
  typeof useSaveActivityLocationMutation
>
export type SaveActivityLocationMutationResult =
  Apollo.MutationResult<ISaveActivityLocationMutation>
export type SaveActivityLocationMutationOptions = Apollo.BaseMutationOptions<
  ISaveActivityLocationMutation,
  ISaveActivityLocationMutationVariables
>
export const SaveManyActivityLocationDocument = gql`
  mutation SaveManyActivityLocation(
    $activityLocations: [ActivityLocationInput]!
  ) {
    saveManyActivityLocations(activityLocations: $activityLocations) {
      ...ActivityLocationFields
    }
  }
  ${ActivityLocationFieldsFragmentDoc}
`
export type ISaveManyActivityLocationMutationFn = Apollo.MutationFunction<
  ISaveManyActivityLocationMutation,
  ISaveManyActivityLocationMutationVariables
>

/**
 * __useSaveManyActivityLocationMutation__
 *
 * To run a mutation, you first call `useSaveManyActivityLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyActivityLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyActivityLocationMutation, { data, loading, error }] = useSaveManyActivityLocationMutation({
 *   variables: {
 *      activityLocations: // value for 'activityLocations'
 *   },
 * });
 */
export function useSaveManyActivityLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyActivityLocationMutation,
    ISaveManyActivityLocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyActivityLocationMutation,
    ISaveManyActivityLocationMutationVariables
  >(SaveManyActivityLocationDocument, options)
}
export type SaveManyActivityLocationMutationHookResult = ReturnType<
  typeof useSaveManyActivityLocationMutation
>
export type SaveManyActivityLocationMutationResult =
  Apollo.MutationResult<ISaveManyActivityLocationMutation>
export type SaveManyActivityLocationMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveManyActivityLocationMutation,
    ISaveManyActivityLocationMutationVariables
  >
export const DeleteActivityLocationDocument = gql`
  mutation DeleteActivityLocation($activityLocationId: ObjectId!) {
    deleteActivityLocation(id: $activityLocationId)
  }
`
export type IDeleteActivityLocationMutationFn = Apollo.MutationFunction<
  IDeleteActivityLocationMutation,
  IDeleteActivityLocationMutationVariables
>

/**
 * __useDeleteActivityLocationMutation__
 *
 * To run a mutation, you first call `useDeleteActivityLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityLocationMutation, { data, loading, error }] = useDeleteActivityLocationMutation({
 *   variables: {
 *      activityLocationId: // value for 'activityLocationId'
 *   },
 * });
 */
export function useDeleteActivityLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteActivityLocationMutation,
    IDeleteActivityLocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteActivityLocationMutation,
    IDeleteActivityLocationMutationVariables
  >(DeleteActivityLocationDocument, options)
}
export type DeleteActivityLocationMutationHookResult = ReturnType<
  typeof useDeleteActivityLocationMutation
>
export type DeleteActivityLocationMutationResult =
  Apollo.MutationResult<IDeleteActivityLocationMutation>
export type DeleteActivityLocationMutationOptions = Apollo.BaseMutationOptions<
  IDeleteActivityLocationMutation,
  IDeleteActivityLocationMutationVariables
>
export const ListenActivityLocationsByProjectIdDocument = gql`
  subscription ListenActivityLocationsByProjectId($projectId: ObjectId!) {
    activityLocation(projectId: $projectId) {
      id
      item {
        ...ActivityLocationFields
      }
    }
  }
  ${ActivityLocationFieldsFragmentDoc}
`

/**
 * __useListenActivityLocationsByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenActivityLocationsByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenActivityLocationsByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenActivityLocationsByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenActivityLocationsByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenActivityLocationsByProjectIdSubscription,
    IListenActivityLocationsByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenActivityLocationsByProjectIdSubscription,
    IListenActivityLocationsByProjectIdSubscriptionVariables
  >(ListenActivityLocationsByProjectIdDocument, options)
}
export type ListenActivityLocationsByProjectIdSubscriptionHookResult =
  ReturnType<typeof useListenActivityLocationsByProjectIdSubscription>
export type ListenActivityLocationsByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenActivityLocationsByProjectIdSubscription>
