export default function smoothScrollTo(
  scrollContainerElement: HTMLElement,
  y?: number,
  x?: number,
  duration: number = 0.5,
  onDone?: () => void,
) {
  const startTop = scrollContainerElement.scrollTop
  const startLeft = scrollContainerElement.scrollLeft

  const verticalScrollPoint = Math.ceil(y || 0)
  const horizontalScrollPoint = Math.ceil(x || 0)

  const changeTop = verticalScrollPoint - startTop
  const changeLeft = horizontalScrollPoint - startLeft

  const startTime = Date.now()

  function scroll() {
    const elapsed = (Date.now() - startTime) / 1000
    const progress = elapsed / duration

    scrollContainerElement.scrollTop = startTop + changeTop * progress
    scrollContainerElement.scrollLeft = startLeft + changeLeft * progress

    if (progress < 1) {
      requestAnimationFrame(scroll)
    } else {
      scrollContainerElement.scrollTop = verticalScrollPoint
      scrollContainerElement.scrollLeft = horizontalScrollPoint

      onDone?.()
    }
  }

  scroll()
}
