import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DesktopCalendarViewStore from '~/client/src/desktop/components/DesktopCalendar/DesktopCalendar.store'
import DesktopCalendarColumn from '~/client/src/desktop/components/DesktopCalendar/DesktopCalendarColumn'
import CalendarEventComponent, {
  IEditableCalendarEvent,
} from '~/client/src/shared/components/CalendarDayView/components/CalendarEvent'
import HourSpace from '~/client/src/shared/components/CalendarDayView/components/HourSpace'
import CalendarEvent from '~/client/src/shared/models/CalendarEvent'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import CalendarTimeline from '../../../../shared/components/CalendarTimeline/CalendarTimeline'

// localization: no display text to translate

export interface IProps {
  calendarColumn: DesktopCalendarColumn
  store: DesktopCalendarViewStore
  onEventClicked?: (event: CalendarEvent) => void
  initialDate?: Date
  isEventCreatingDisabled?: boolean
  isWorkingDay?: boolean
  isDayCalendarModeActive?: boolean
  isToday?: boolean
  shouldRenderDot?: boolean
  onEventMoved?: (e: CalendarEvent, x: number, y: number) => void
  onEventTimeChange?: (
    e: CalendarEvent,
    startDate: number,
    endDate: number,
  ) => void
  onLongSelect?: (event: CalendarEvent) => void
  selectedEvent?: IEditableCalendarEvent
  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
export default class DesktopCalendarColumnComponent extends React.Component<IProps> {
  public render() {
    const { isDayCalendarModeActive, isToday, shouldRenderDot } = this.props

    return (
      <div className="relative x-axis-column-width">
        {isToday && <CalendarTimeline shouldRenderDot={shouldRenderDot} />}
        <div
          className={classList({
            'bl-light-grey relative': true,
            'bt-light-grey': isDayCalendarModeActive,
          })}
        >
          {this.renderHourSpaces()}
          {this.renderDayEvents()}
        </div>
      </div>
    )
  }

  private renderHourSpaces() {
    const { store, initialDate, isWorkingDay } = this.props

    return store.dayHours.map(hour => {
      return (
        hour < 24 && (
          <HourSpace
            key={hour}
            hour={hour}
            timeInterval={store.timeInterval}
            rowsNumber={store.rowsNumber}
            onEmptyCellTouchStart={this.onHourSpaceTouchStart}
            shouldHandleMouseClick={true}
            activeDate={initialDate}
            isNonWorkingDay={!isWorkingDay}
          />
        )
      )
    })
  }

  private renderDayEvents() {
    const {
      store,
      calendarColumn,
      onEventMoved,
      onEventTimeChange,
      onEventClicked,
      onLongSelect,
      selectedEvent,
      projectDateStore: { isSameDay },
    } = this.props
    const columnEvents = store
      .getEventsByColumn(calendarColumn)
      .filter(event => isSameDay(event.startDate, event.endDate))

    store.restorePositionedEvents()
    return columnEvents.map((event, idx) => {
      const { style, className } = store.getEventSizeAndPosition(
        event,
        columnEvents,
        calendarColumn.date,
      )

      return (
        <CalendarEventComponent
          key={idx}
          event={event}
          onEventClicked={onEventClicked}
          style={style}
          className={className}
          onLongSelect={onLongSelect}
          onEventMoved={onEventMoved}
          onEventTimeChange={onEventTimeChange}
          isSelected={selectedEvent?.event?.dataId === event.dataId}
        />
      )
    })
  }

  @action.bound
  private onHourSpaceTouchStart(date: Date) {
    const { calendarColumn, isEventCreatingDisabled, store } = this.props
    if (!isEventCreatingDisabled) {
      store.activateNewEventMode(calendarColumn, date)
    }
  }
}
