import { action, computed } from 'mobx'

import { DeliveryFilterType } from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import { formatStatusToDisplay } from '~/client/src/shared/constants/DeliveryStatus'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { getDeliveryFilterTypeDisplayName } from '~/client/src/shared/localization/enumDisplayTexts'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { RESET_ALL_FILTERS } from '~/client/src/shared/stores/EventStore/eventConstants'
import SuperFilterStore, {
  IRootOption,
} from '~/client/src/shared/stores/ui/SuperFilter.store'
import Guard from '~/client/src/shared/utils/Guard'

import DeliveriesBySitemapListStore from '../../../DeliveriesMap/DeliveriesBySitemapList.store'

// localization: translated

export type ISourceMap = { [optionId: string]: string[] }

export default class BaseDeliveryMapFilterStore extends SuperFilterStore {
  public get totalHint(): string {
    return Localization.translator.totalDeliveries
  }

  public constructor(
    public readonly type: DeliveryFilterType,
    protected readonly state: DesktopInitialState,
    protected readonly sourceMap: ISourceMap,
    protected readonly deliveriesBySitemapListStore: DeliveriesBySitemapListStore,
    protected readonly onShowChanged: (isShown: boolean, type: string) => void,
    protected readonly customFormatOptionKey?: (
      optionKey: string,
      filterType: string,
    ) => string,
  ) {
    super(
      state.deliveryFilters.fieldsMap[type],
      type,
      state,
      KnownTranslatorKeys.seeXDeliveries,
      getDeliveryFilterTypeDisplayName,
      onShowChanged,
    )

    Guard.requireAll({ type, state, sourceMap, deliveriesBySitemapListStore })
  }

  @action.bound
  public onFilterActionRequest(eventContext: EventContext) {
    const [eventType] = eventContext.event

    switch (eventType) {
      case RESET_ALL_FILTERS:
        return this.setInitialFilterValues()
    }
  }

  @computed
  public get optionsTree(): IRootOption[] {
    const options = Object.keys(this.sourceMap)

    return [
      {
        id: this.type,
        name: this.typeCaption,
        options: options.map(option => ({
          id: option,
          name: this.formatOptionKey(option, this.type),
          instancesIds: this.sourceMap[option],
        })),
      },
    ]
  }

  @action.bound
  public clickOnApply() {
    this.handleApply()
  }

  public filterOptionInstances = (deliveriesIds: string[]) => {
    return this.allFilteredDeliveries.filter(d => deliveriesIds.includes(d.id))
  }

  @computed
  private get allFilteredDeliveries() {
    const { getFilteredCollectionExcludeFilter } =
      this.deliveriesBySitemapListStore
    return getFilteredCollectionExcludeFilter([this.type])
  }

  private formatOptionKey(optionKey: string, filterType: string): string {
    if (this.customFormatOptionKey) {
      return this.customFormatOptionKey(optionKey, filterType)
    }
    return filterType === DeliveryFilterType.Status
      ? formatStatusToDisplay(optionKey)
      : optionKey
  }
}
