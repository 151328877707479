import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { IPermitTypeField } from '~/client/graph'
import CompanyProfilePreview from '~/client/src/shared/components/CompanyProfilePreview/CompanyProfilePreview'
import StruxhubCompanySelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubCompanySelector'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'

import SitePermitCreationFormStore from '../../SitePermitCreationForm.store'
import PermitBaseFormField from './PermitBaseFormField'
import PermitFieldRemoveIcon from './PermitFieldRemoveIcon'

interface IProps {
  typeField: IPermitTypeField
  isViewMode: boolean

  store: SitePermitCreationFormStore
  fieldsStore: IPermitFieldsStore

  companiesStore?: CompaniesStore
}

@inject('companiesStore')
@observer
export default class PermitFormCompanyField extends React.Component<IProps> {
  public render() {
    const { isViewMode, typeField, fieldsStore, companiesStore } = this.props
    const { caption, isMandatory, isMultiple } = typeField
    const { isFieldChanged } = fieldsStore

    return (
      <PermitBaseFormField
        typeField={typeField}
        isViewMode={isViewMode}
        viewModeElements={this.companyProfilePreviews}
        onAddFieldClick={this.addNewFieldValue}
      >
        {this.fieldValues.map((id, index) => {
          const value = !companiesStore.getCompanyById(id) ? '' : id
          const shouldShowRemoveBtn = !!index || !!value

          return (
            <div key={`${id}_${index}`} className="row y-center">
              <StruxhubCompanySelector
                className="overflow-hidden"
                label={caption}
                isRequired={isMandatory}
                isChanged={isFieldChanged(typeField.id, index)}
                isLabelHidden={!!index}
                isHelperTextCondensed={isMultiple}
                value={id}
                onClick={this.openCompanyPicker.bind(this, index)}
              />
              {isMultiple && shouldShowRemoveBtn && (
                <PermitFieldRemoveIcon
                  index={index}
                  onClick={this.removeValue}
                />
              )}
            </div>
          )
        })}
      </PermitBaseFormField>
    )
  }

  private get companyProfilePreviews(): JSX.Element[] {
    const { companiesStore } = this.props

    return this.fieldValues
      .map(id => {
        const company = companiesStore.getCompanyById(id)
        return (
          company && (
            <CompanyProfilePreview
              key={company.id}
              company={company}
              isMenuHidden={true}
              isPhoneHidden={true}
            />
          )
        )
      })
      .filter(c => c)
  }

  private get fieldValues(): string[] {
    const { typeField, fieldsStore } = this.props
    const values = fieldsStore.getFieldValues<string>(typeField.id)
    return values?.length ? values : ['']
  }

  private openCompanyPicker(fieldIndex: number) {
    const { store, typeField } = this.props
    store.setSelectedField(typeField, fieldIndex)
  }

  private addNewFieldValue = () => {
    const { fieldsStore, typeField } = this.props
    fieldsStore.addNewFieldValue(typeField, '')
  }

  private removeValue = (index: number) => {
    const { fieldsStore, typeField } = this.props
    fieldsStore.removeFieldValue(typeField.id, index)
  }
}
